import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Json: any;
  Placeholder: any;
};

export type AcceptUserInvitationInput = {
  invitationCode: Scalars['String'];
};

export type AcceptUserInvitationResponse = {
  __typename?: 'AcceptUserInvitationResponse';
  token?: Maybe<Scalars['String']>;
};

export type ActiveIngredient = {
  __typename?: 'ActiveIngredient';
  chemicalCompound: ChemicalCompound;
  chemicalCompoundId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: Product;
  productId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ActiveIngredientAvgAggregateOutputType = {
  __typename?: 'ActiveIngredientAvgAggregateOutputType';
  massPerDose?: Maybe<Scalars['Float']>;
};

export type ActiveIngredientAvgOrderByAggregateInput = {
  massPerDose?: Maybe<SortOrder>;
};

export type ActiveIngredientCountAggregateOutputType = {
  __typename?: 'ActiveIngredientCountAggregateOutputType';
  _all: Scalars['Int'];
  chemicalCompoundId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  massPerDose: Scalars['Int'];
  massPerDoseUnit: Scalars['Int'];
  productId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ActiveIngredientCountOrderByAggregateInput = {
  chemicalCompoundId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ActiveIngredientCreateInput = {
  chemicalCompound: ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutActiveIngredientsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientCreateManyChemicalCompoundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientCreateManyChemicalCompoundInputEnvelope = {
  data: Array<ActiveIngredientCreateManyChemicalCompoundInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ActiveIngredientCreateManyInput = {
  chemicalCompoundId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientCreateManyProductInput = {
  chemicalCompoundId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientCreateManyProductInputEnvelope = {
  data: Array<ActiveIngredientCreateManyProductInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ActiveIngredientCreateNestedManyWithoutChemicalCompoundInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
};

export type ActiveIngredientCreateNestedManyWithoutProductInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
};

export type ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput = {
  create: ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientCreateOrConnectWithoutProductInput = {
  create: ActiveIngredientUncheckedCreateWithoutProductInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientCreateWithoutChemicalCompoundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product: ProductCreateNestedOneWithoutActiveIngredientsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientCreateWithoutProductInput = {
  chemicalCompound: ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientListRelationFilter = {
  every?: Maybe<ActiveIngredientWhereInput>;
  none?: Maybe<ActiveIngredientWhereInput>;
  some?: Maybe<ActiveIngredientWhereInput>;
};

export type ActiveIngredientMaxAggregateOutputType = {
  __typename?: 'ActiveIngredientMaxAggregateOutputType';
  chemicalCompoundId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientMaxOrderByAggregateInput = {
  chemicalCompoundId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ActiveIngredientMinAggregateOutputType = {
  __typename?: 'ActiveIngredientMinAggregateOutputType';
  chemicalCompoundId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientMinOrderByAggregateInput = {
  chemicalCompoundId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ActiveIngredientOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ActiveIngredientOrderByRelevanceFieldEnum {
  ChemicalCompoundId = 'chemicalCompoundId',
  Id = 'id',
  MassPerDoseUnit = 'massPerDoseUnit',
  ProductId = 'productId'
}

export type ActiveIngredientOrderByRelevanceInput = {
  fields: Array<ActiveIngredientOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ActiveIngredientOrderByWithAggregationInput = {
  _avg?: Maybe<ActiveIngredientAvgOrderByAggregateInput>;
  _count?: Maybe<ActiveIngredientCountOrderByAggregateInput>;
  _max?: Maybe<ActiveIngredientMaxOrderByAggregateInput>;
  _min?: Maybe<ActiveIngredientMinOrderByAggregateInput>;
  _sum?: Maybe<ActiveIngredientSumOrderByAggregateInput>;
  chemicalCompoundId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ActiveIngredientOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ActiveIngredientOrderByRelevanceInput>;
  chemicalCompound?: Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>;
  chemicalCompoundId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massPerDose?: Maybe<SortOrder>;
  massPerDoseUnit?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  productId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ActiveIngredientScalarFieldEnum {
  ChemicalCompoundId = 'chemicalCompoundId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MassPerDose = 'massPerDose',
  MassPerDoseUnit = 'massPerDoseUnit',
  ProductId = 'productId',
  UpdatedAt = 'updatedAt'
}

export type ActiveIngredientScalarWhereInput = {
  AND?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  NOT?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  OR?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  chemicalCompoundId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massPerDose?: Maybe<FloatNullableFilter>;
  massPerDoseUnit?: Maybe<StringNullableFilter>;
  productId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ActiveIngredientScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ActiveIngredientScalarWhereWithAggregatesInput>>;
  chemicalCompoundId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  massPerDose?: Maybe<FloatNullableWithAggregatesFilter>;
  massPerDoseUnit?: Maybe<StringNullableWithAggregatesFilter>;
  productId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ActiveIngredientSumAggregateOutputType = {
  __typename?: 'ActiveIngredientSumAggregateOutputType';
  massPerDose?: Maybe<Scalars['Float']>;
};

export type ActiveIngredientSumOrderByAggregateInput = {
  massPerDose?: Maybe<SortOrder>;
};

export type ActiveIngredientUncheckedCreateInput = {
  chemicalCompoundId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedCreateNestedManyWithoutChemicalCompoundInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
};

export type ActiveIngredientUncheckedCreateNestedManyWithoutProductInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
};

export type ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedCreateWithoutProductInput = {
  chemicalCompoundId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedUpdateInput = {
  chemicalCompoundId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedUpdateManyInput = {
  chemicalCompoundId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutChemicalCompoundNestedInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput>>;
};

export type ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutProductInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUncheckedUpdateWithoutProductInput = {
  chemicalCompoundId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUpdateInput = {
  chemicalCompound?: Maybe<ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput = {
  data: ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput;
  where: ActiveIngredientScalarWhereInput;
};

export type ActiveIngredientUpdateManyWithWhereWithoutProductInput = {
  data: ActiveIngredientUncheckedUpdateManyWithoutActiveIngredientsInput;
  where: ActiveIngredientScalarWhereInput;
};

export type ActiveIngredientUpdateManyWithoutChemicalCompoundNestedInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutChemicalCompoundInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutChemicalCompoundInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyChemicalCompoundInputEnvelope>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutChemicalCompoundInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput>>;
};

export type ActiveIngredientUpdateManyWithoutProductNestedInput = {
  connect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ActiveIngredientCreateOrConnectWithoutProductInput>>;
  create?: Maybe<Array<ActiveIngredientCreateWithoutProductInput>>;
  createMany?: Maybe<ActiveIngredientCreateManyProductInputEnvelope>;
  delete?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ActiveIngredientScalarWhereInput>>;
  disconnect?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  set?: Maybe<Array<ActiveIngredientWhereUniqueInput>>;
  update?: Maybe<Array<ActiveIngredientUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: Maybe<Array<ActiveIngredientUpdateManyWithWhereWithoutProductInput>>;
  upsert?: Maybe<Array<ActiveIngredientUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ActiveIngredientUpdateWithWhereUniqueWithoutChemicalCompoundInput = {
  data: ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientUpdateWithWhereUniqueWithoutProductInput = {
  data: ActiveIngredientUncheckedUpdateWithoutProductInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientUpdateWithoutChemicalCompoundInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUpdateWithoutProductInput = {
  chemicalCompound?: Maybe<ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massPerDose?: Maybe<Scalars['Float']>;
  massPerDoseUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveIngredientUpsertWithWhereUniqueWithoutChemicalCompoundInput = {
  create: ActiveIngredientUncheckedCreateWithoutChemicalCompoundInput;
  update: ActiveIngredientUncheckedUpdateWithoutChemicalCompoundInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientUpsertWithWhereUniqueWithoutProductInput = {
  create: ActiveIngredientUncheckedCreateWithoutProductInput;
  update: ActiveIngredientUncheckedUpdateWithoutProductInput;
  where: ActiveIngredientWhereUniqueInput;
};

export type ActiveIngredientWhereInput = {
  AND?: Maybe<Array<ActiveIngredientWhereInput>>;
  NOT?: Maybe<Array<ActiveIngredientWhereInput>>;
  OR?: Maybe<Array<ActiveIngredientWhereInput>>;
  chemicalCompound?: Maybe<ChemicalCompoundWhereInput>;
  chemicalCompoundId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massPerDose?: Maybe<FloatNullableFilter>;
  massPerDoseUnit?: Maybe<StringNullableFilter>;
  product?: Maybe<ProductWhereInput>;
  productId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ActiveIngredientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AggregateActiveIngredient = {
  __typename?: 'AggregateActiveIngredient';
  _avg?: Maybe<ActiveIngredientAvgAggregateOutputType>;
  _count?: Maybe<ActiveIngredientCountAggregateOutputType>;
  _max?: Maybe<ActiveIngredientMaxAggregateOutputType>;
  _min?: Maybe<ActiveIngredientMinAggregateOutputType>;
  _sum?: Maybe<ActiveIngredientSumAggregateOutputType>;
};

export type AggregateApiKey = {
  __typename?: 'AggregateApiKey';
  _count?: Maybe<ApiKeyCountAggregateOutputType>;
  _max?: Maybe<ApiKeyMaxAggregateOutputType>;
  _min?: Maybe<ApiKeyMinAggregateOutputType>;
};

export type AggregateAppointment = {
  __typename?: 'AggregateAppointment';
  _avg?: Maybe<AppointmentAvgAggregateOutputType>;
  _count?: Maybe<AppointmentCountAggregateOutputType>;
  _max?: Maybe<AppointmentMaxAggregateOutputType>;
  _min?: Maybe<AppointmentMinAggregateOutputType>;
  _sum?: Maybe<AppointmentSumAggregateOutputType>;
};

export type AggregateAppointmentAvailability = {
  __typename?: 'AggregateAppointmentAvailability';
  _count?: Maybe<AppointmentAvailabilityCountAggregateOutputType>;
  _max?: Maybe<AppointmentAvailabilityMaxAggregateOutputType>;
  _min?: Maybe<AppointmentAvailabilityMinAggregateOutputType>;
};

export type AggregateAppointmentType = {
  __typename?: 'AggregateAppointmentType';
  _avg?: Maybe<AppointmentTypeAvgAggregateOutputType>;
  _count?: Maybe<AppointmentTypeCountAggregateOutputType>;
  _max?: Maybe<AppointmentTypeMaxAggregateOutputType>;
  _min?: Maybe<AppointmentTypeMinAggregateOutputType>;
  _sum?: Maybe<AppointmentTypeSumAggregateOutputType>;
};

export type AggregateAutomationRun = {
  __typename?: 'AggregateAutomationRun';
  _count?: Maybe<AutomationRunCountAggregateOutputType>;
  _max?: Maybe<AutomationRunMaxAggregateOutputType>;
  _min?: Maybe<AutomationRunMinAggregateOutputType>;
};

export type AggregateAutomationRunAction = {
  __typename?: 'AggregateAutomationRunAction';
  _avg?: Maybe<AutomationRunActionAvgAggregateOutputType>;
  _count?: Maybe<AutomationRunActionCountAggregateOutputType>;
  _max?: Maybe<AutomationRunActionMaxAggregateOutputType>;
  _min?: Maybe<AutomationRunActionMinAggregateOutputType>;
  _sum?: Maybe<AutomationRunActionSumAggregateOutputType>;
};

export type AggregateBitwerxHealthcheck = {
  __typename?: 'AggregateBitwerxHealthcheck';
  _count?: Maybe<BitwerxHealthcheckCountAggregateOutputType>;
  _max?: Maybe<BitwerxHealthcheckMaxAggregateOutputType>;
  _min?: Maybe<BitwerxHealthcheckMinAggregateOutputType>;
};

export type AggregateBitwerxIntegration = {
  __typename?: 'AggregateBitwerxIntegration';
  _count?: Maybe<BitwerxIntegrationCountAggregateOutputType>;
  _max?: Maybe<BitwerxIntegrationMaxAggregateOutputType>;
  _min?: Maybe<BitwerxIntegrationMinAggregateOutputType>;
};

export type AggregateBitwerxPaymentHealthcheckResult = {
  __typename?: 'AggregateBitwerxPaymentHealthcheckResult';
  _avg?: Maybe<BitwerxPaymentHealthcheckResultAvgAggregateOutputType>;
  _count?: Maybe<BitwerxPaymentHealthcheckResultCountAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentHealthcheckResultMaxAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentHealthcheckResultMinAggregateOutputType>;
  _sum?: Maybe<BitwerxPaymentHealthcheckResultSumAggregateOutputType>;
};

export type AggregateBitwerxPaymentHealthcheckRun = {
  __typename?: 'AggregateBitwerxPaymentHealthcheckRun';
  _avg?: Maybe<BitwerxPaymentHealthcheckRunAvgAggregateOutputType>;
  _count?: Maybe<BitwerxPaymentHealthcheckRunCountAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentHealthcheckRunMaxAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentHealthcheckRunMinAggregateOutputType>;
  _sum?: Maybe<BitwerxPaymentHealthcheckRunSumAggregateOutputType>;
};

export type AggregateBitwerxPaymentWritebackConfiguration = {
  __typename?: 'AggregateBitwerxPaymentWritebackConfiguration';
  _count?: Maybe<BitwerxPaymentWritebackConfigurationCountAggregateOutputType>;
  _max?: Maybe<BitwerxPaymentWritebackConfigurationMaxAggregateOutputType>;
  _min?: Maybe<BitwerxPaymentWritebackConfigurationMinAggregateOutputType>;
};

export type AggregateBoardFilterColumn = {
  __typename?: 'AggregateBoardFilterColumn';
  _count?: Maybe<BoardFilterColumnCountAggregateOutputType>;
  _max?: Maybe<BoardFilterColumnMaxAggregateOutputType>;
  _min?: Maybe<BoardFilterColumnMinAggregateOutputType>;
};

export type AggregateBoardFilterSpecificDateRange = {
  __typename?: 'AggregateBoardFilterSpecificDateRange';
  _count?: Maybe<BoardFilterSpecificDateRangeCountAggregateOutputType>;
  _max?: Maybe<BoardFilterSpecificDateRangeMaxAggregateOutputType>;
  _min?: Maybe<BoardFilterSpecificDateRangeMinAggregateOutputType>;
};

export type AggregateBoehringerIngelheimCustomerAlignment = {
  __typename?: 'AggregateBoehringerIngelheimCustomerAlignment';
  _avg?: Maybe<BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType>;
  _count?: Maybe<BoehringerIngelheimCustomerAlignmentCountAggregateOutputType>;
  _max?: Maybe<BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType>;
  _min?: Maybe<BoehringerIngelheimCustomerAlignmentMinAggregateOutputType>;
  _sum?: Maybe<BoehringerIngelheimCustomerAlignmentSumAggregateOutputType>;
};

export type AggregateBoehringerIngelheimTerritory = {
  __typename?: 'AggregateBoehringerIngelheimTerritory';
  _count?: Maybe<BoehringerIngelheimTerritoryCountAggregateOutputType>;
  _max?: Maybe<BoehringerIngelheimTerritoryMaxAggregateOutputType>;
  _min?: Maybe<BoehringerIngelheimTerritoryMinAggregateOutputType>;
};

export type AggregateBreed = {
  __typename?: 'AggregateBreed';
  _count?: Maybe<BreedCountAggregateOutputType>;
  _max?: Maybe<BreedMaxAggregateOutputType>;
  _min?: Maybe<BreedMinAggregateOutputType>;
};

export type AggregateCallHistory = {
  __typename?: 'AggregateCallHistory';
  _avg?: Maybe<CallHistoryAvgAggregateOutputType>;
  _count?: Maybe<CallHistoryCountAggregateOutputType>;
  _max?: Maybe<CallHistoryMaxAggregateOutputType>;
  _min?: Maybe<CallHistoryMinAggregateOutputType>;
  _sum?: Maybe<CallHistorySumAggregateOutputType>;
};

export type AggregateCallParticipant = {
  __typename?: 'AggregateCallParticipant';
  _count?: Maybe<CallParticipantCountAggregateOutputType>;
  _max?: Maybe<CallParticipantMaxAggregateOutputType>;
  _min?: Maybe<CallParticipantMinAggregateOutputType>;
};

export type AggregateCallRecording = {
  __typename?: 'AggregateCallRecording';
  _avg?: Maybe<CallRecordingAvgAggregateOutputType>;
  _count?: Maybe<CallRecordingCountAggregateOutputType>;
  _max?: Maybe<CallRecordingMaxAggregateOutputType>;
  _min?: Maybe<CallRecordingMinAggregateOutputType>;
  _sum?: Maybe<CallRecordingSumAggregateOutputType>;
};

export type AggregateCampaignRegistryCampaign = {
  __typename?: 'AggregateCampaignRegistryCampaign';
  _count?: Maybe<CampaignRegistryCampaignCountAggregateOutputType>;
  _max?: Maybe<CampaignRegistryCampaignMaxAggregateOutputType>;
  _min?: Maybe<CampaignRegistryCampaignMinAggregateOutputType>;
};

export type AggregateCanonicalBreed = {
  __typename?: 'AggregateCanonicalBreed';
  _avg?: Maybe<CanonicalBreedAvgAggregateOutputType>;
  _count?: Maybe<CanonicalBreedCountAggregateOutputType>;
  _max?: Maybe<CanonicalBreedMaxAggregateOutputType>;
  _min?: Maybe<CanonicalBreedMinAggregateOutputType>;
  _sum?: Maybe<CanonicalBreedSumAggregateOutputType>;
};

export type AggregateCanonicalCanineBreedProfile = {
  __typename?: 'AggregateCanonicalCanineBreedProfile';
  _avg?: Maybe<CanonicalCanineBreedProfileAvgAggregateOutputType>;
  _count?: Maybe<CanonicalCanineBreedProfileCountAggregateOutputType>;
  _max?: Maybe<CanonicalCanineBreedProfileMaxAggregateOutputType>;
  _min?: Maybe<CanonicalCanineBreedProfileMinAggregateOutputType>;
  _sum?: Maybe<CanonicalCanineBreedProfileSumAggregateOutputType>;
};

export type AggregateCanonicalService = {
  __typename?: 'AggregateCanonicalService';
  _count?: Maybe<CanonicalServiceCountAggregateOutputType>;
  _max?: Maybe<CanonicalServiceMaxAggregateOutputType>;
  _min?: Maybe<CanonicalServiceMinAggregateOutputType>;
};

export type AggregateCanonicalSpecies = {
  __typename?: 'AggregateCanonicalSpecies';
  _count?: Maybe<CanonicalSpeciesCountAggregateOutputType>;
  _max?: Maybe<CanonicalSpeciesMaxAggregateOutputType>;
  _min?: Maybe<CanonicalSpeciesMinAggregateOutputType>;
};

export type AggregateCareAccountCreditIntent = {
  __typename?: 'AggregateCareAccountCreditIntent';
  _avg?: Maybe<CareAccountCreditIntentAvgAggregateOutputType>;
  _count?: Maybe<CareAccountCreditIntentCountAggregateOutputType>;
  _max?: Maybe<CareAccountCreditIntentMaxAggregateOutputType>;
  _min?: Maybe<CareAccountCreditIntentMinAggregateOutputType>;
  _sum?: Maybe<CareAccountCreditIntentSumAggregateOutputType>;
};

export type AggregateCareAccountCreditPimsWritebackLogEntry = {
  __typename?: 'AggregateCareAccountCreditPimsWritebackLogEntry';
  _count?: Maybe<CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType>;
  _max?: Maybe<CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType>;
  _min?: Maybe<CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType>;
};

export type AggregateCareAddonEnrollment = {
  __typename?: 'AggregateCareAddonEnrollment';
  _count?: Maybe<CareAddonEnrollmentCountAggregateOutputType>;
  _max?: Maybe<CareAddonEnrollmentMaxAggregateOutputType>;
  _min?: Maybe<CareAddonEnrollmentMinAggregateOutputType>;
};

export type AggregateCareAddonPackage = {
  __typename?: 'AggregateCareAddonPackage';
  _avg?: Maybe<CareAddonPackageAvgAggregateOutputType>;
  _count?: Maybe<CareAddonPackageCountAggregateOutputType>;
  _max?: Maybe<CareAddonPackageMaxAggregateOutputType>;
  _min?: Maybe<CareAddonPackageMinAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageSumAggregateOutputType>;
};

export type AggregateCareAddonPackageBenefit = {
  __typename?: 'AggregateCareAddonPackageBenefit';
  _avg?: Maybe<CareAddonPackageBenefitAvgAggregateOutputType>;
  _count?: Maybe<CareAddonPackageBenefitCountAggregateOutputType>;
  _max?: Maybe<CareAddonPackageBenefitMaxAggregateOutputType>;
  _min?: Maybe<CareAddonPackageBenefitMinAggregateOutputType>;
  _sum?: Maybe<CareAddonPackageBenefitSumAggregateOutputType>;
};

export type AggregateCareAuthorizationRequest = {
  __typename?: 'AggregateCareAuthorizationRequest';
  _count?: Maybe<CareAuthorizationRequestCountAggregateOutputType>;
  _max?: Maybe<CareAuthorizationRequestMaxAggregateOutputType>;
  _min?: Maybe<CareAuthorizationRequestMinAggregateOutputType>;
};

export type AggregateCareBenefit = {
  __typename?: 'AggregateCareBenefit';
  _avg?: Maybe<CareBenefitAvgAggregateOutputType>;
  _count?: Maybe<CareBenefitCountAggregateOutputType>;
  _max?: Maybe<CareBenefitMaxAggregateOutputType>;
  _min?: Maybe<CareBenefitMinAggregateOutputType>;
  _sum?: Maybe<CareBenefitSumAggregateOutputType>;
};

export type AggregateCareBenefitUsage = {
  __typename?: 'AggregateCareBenefitUsage';
  _avg?: Maybe<CareBenefitUsageAvgAggregateOutputType>;
  _count?: Maybe<CareBenefitUsageCountAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageMaxAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageMinAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageSumAggregateOutputType>;
};

export type AggregateCareBenefitUsageFollowup = {
  __typename?: 'AggregateCareBenefitUsageFollowup';
  _avg?: Maybe<CareBenefitUsageFollowupAvgAggregateOutputType>;
  _count?: Maybe<CareBenefitUsageFollowupCountAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageFollowupMaxAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageFollowupMinAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageFollowupSumAggregateOutputType>;
};

export type AggregateCareBenefitUsageFollowupStep = {
  __typename?: 'AggregateCareBenefitUsageFollowupStep';
  _avg?: Maybe<CareBenefitUsageFollowupStepAvgAggregateOutputType>;
  _count?: Maybe<CareBenefitUsageFollowupStepCountAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageFollowupStepMaxAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageFollowupStepMinAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageFollowupStepSumAggregateOutputType>;
};

export type AggregateCareBenefitUsageToInvoiceLineItem = {
  __typename?: 'AggregateCareBenefitUsageToInvoiceLineItem';
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType>;
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountAggregateOutputType>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinAggregateOutputType>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumAggregateOutputType>;
};

export type AggregateCareEnrollmentPayment = {
  __typename?: 'AggregateCareEnrollmentPayment';
  _avg?: Maybe<CareEnrollmentPaymentAvgAggregateOutputType>;
  _count?: Maybe<CareEnrollmentPaymentCountAggregateOutputType>;
  _max?: Maybe<CareEnrollmentPaymentMaxAggregateOutputType>;
  _min?: Maybe<CareEnrollmentPaymentMinAggregateOutputType>;
  _sum?: Maybe<CareEnrollmentPaymentSumAggregateOutputType>;
};

export type AggregateCareEnrollmentToStripeInvoiceItem = {
  __typename?: 'AggregateCareEnrollmentToStripeInvoiceItem';
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountAggregateOutputType>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinAggregateOutputType>;
};

export type AggregateCareLapsedPayment = {
  __typename?: 'AggregateCareLapsedPayment';
  _count?: Maybe<CareLapsedPaymentCountAggregateOutputType>;
  _max?: Maybe<CareLapsedPaymentMaxAggregateOutputType>;
  _min?: Maybe<CareLapsedPaymentMinAggregateOutputType>;
};

export type AggregateCarePlan = {
  __typename?: 'AggregateCarePlan';
  _avg?: Maybe<CarePlanAvgAggregateOutputType>;
  _count?: Maybe<CarePlanCountAggregateOutputType>;
  _max?: Maybe<CarePlanMaxAggregateOutputType>;
  _min?: Maybe<CarePlanMinAggregateOutputType>;
  _sum?: Maybe<CarePlanSumAggregateOutputType>;
};

export type AggregateCarePlanBenefit = {
  __typename?: 'AggregateCarePlanBenefit';
  _avg?: Maybe<CarePlanBenefitAvgAggregateOutputType>;
  _count?: Maybe<CarePlanBenefitCountAggregateOutputType>;
  _max?: Maybe<CarePlanBenefitMaxAggregateOutputType>;
  _min?: Maybe<CarePlanBenefitMinAggregateOutputType>;
  _sum?: Maybe<CarePlanBenefitSumAggregateOutputType>;
};

export type AggregateCarePlanBenefitEnrollmentCarryover = {
  __typename?: 'AggregateCarePlanBenefitEnrollmentCarryover';
  _avg?: Maybe<CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType>;
  _count?: Maybe<CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType>;
  _max?: Maybe<CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType>;
  _min?: Maybe<CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType>;
  _sum?: Maybe<CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType>;
};

export type AggregateCarePlanEnrollment = {
  __typename?: 'AggregateCarePlanEnrollment';
  _count?: Maybe<CarePlanEnrollmentCountAggregateOutputType>;
  _max?: Maybe<CarePlanEnrollmentMaxAggregateOutputType>;
  _min?: Maybe<CarePlanEnrollmentMinAggregateOutputType>;
};

export type AggregateCarePlanProviderGroup = {
  __typename?: 'AggregateCarePlanProviderGroup';
  _count?: Maybe<CarePlanProviderGroupCountAggregateOutputType>;
  _max?: Maybe<CarePlanProviderGroupMaxAggregateOutputType>;
  _min?: Maybe<CarePlanProviderGroupMinAggregateOutputType>;
};

export type AggregateCareStripeSubscription = {
  __typename?: 'AggregateCareStripeSubscription';
  _avg?: Maybe<CareStripeSubscriptionAvgAggregateOutputType>;
  _count?: Maybe<CareStripeSubscriptionCountAggregateOutputType>;
  _max?: Maybe<CareStripeSubscriptionMaxAggregateOutputType>;
  _min?: Maybe<CareStripeSubscriptionMinAggregateOutputType>;
  _sum?: Maybe<CareStripeSubscriptionSumAggregateOutputType>;
};

export type AggregateCareSubscriptionTrueUp = {
  __typename?: 'AggregateCareSubscriptionTrueUp';
  _avg?: Maybe<CareSubscriptionTrueUpAvgAggregateOutputType>;
  _count?: Maybe<CareSubscriptionTrueUpCountAggregateOutputType>;
  _max?: Maybe<CareSubscriptionTrueUpMaxAggregateOutputType>;
  _min?: Maybe<CareSubscriptionTrueUpMinAggregateOutputType>;
  _sum?: Maybe<CareSubscriptionTrueUpSumAggregateOutputType>;
};

export type AggregateCareSubscriptionTrueUpLogEntry = {
  __typename?: 'AggregateCareSubscriptionTrueUpLogEntry';
  _avg?: Maybe<CareSubscriptionTrueUpLogEntryAvgAggregateOutputType>;
  _count?: Maybe<CareSubscriptionTrueUpLogEntryCountAggregateOutputType>;
  _max?: Maybe<CareSubscriptionTrueUpLogEntryMaxAggregateOutputType>;
  _min?: Maybe<CareSubscriptionTrueUpLogEntryMinAggregateOutputType>;
  _sum?: Maybe<CareSubscriptionTrueUpLogEntrySumAggregateOutputType>;
};

export type AggregateChannel = {
  __typename?: 'AggregateChannel';
  _count?: Maybe<ChannelCountAggregateOutputType>;
  _max?: Maybe<ChannelMaxAggregateOutputType>;
  _min?: Maybe<ChannelMinAggregateOutputType>;
};

export type AggregateChannelAutomationStatus = {
  __typename?: 'AggregateChannelAutomationStatus';
  _count?: Maybe<ChannelAutomationStatusCountAggregateOutputType>;
  _max?: Maybe<ChannelAutomationStatusMaxAggregateOutputType>;
  _min?: Maybe<ChannelAutomationStatusMinAggregateOutputType>;
};

export type AggregateChannelCreationSource = {
  __typename?: 'AggregateChannelCreationSource';
  _count?: Maybe<ChannelCreationSourceCountAggregateOutputType>;
  _max?: Maybe<ChannelCreationSourceMaxAggregateOutputType>;
  _min?: Maybe<ChannelCreationSourceMinAggregateOutputType>;
};

export type AggregateChannelFilterSelection = {
  __typename?: 'AggregateChannelFilterSelection';
  _count?: Maybe<ChannelFilterSelectionCountAggregateOutputType>;
  _max?: Maybe<ChannelFilterSelectionMaxAggregateOutputType>;
  _min?: Maybe<ChannelFilterSelectionMinAggregateOutputType>;
};

export type AggregateChannelMember = {
  __typename?: 'AggregateChannelMember';
  _avg?: Maybe<ChannelMemberAvgAggregateOutputType>;
  _count?: Maybe<ChannelMemberCountAggregateOutputType>;
  _max?: Maybe<ChannelMemberMaxAggregateOutputType>;
  _min?: Maybe<ChannelMemberMinAggregateOutputType>;
  _sum?: Maybe<ChannelMemberSumAggregateOutputType>;
};

export type AggregateChannelMessage = {
  __typename?: 'AggregateChannelMessage';
  _avg?: Maybe<ChannelMessageAvgAggregateOutputType>;
  _count?: Maybe<ChannelMessageCountAggregateOutputType>;
  _max?: Maybe<ChannelMessageMaxAggregateOutputType>;
  _min?: Maybe<ChannelMessageMinAggregateOutputType>;
  _sum?: Maybe<ChannelMessageSumAggregateOutputType>;
};

export type AggregateChannelMessageAttachment = {
  __typename?: 'AggregateChannelMessageAttachment';
  _count?: Maybe<ChannelMessageAttachmentCountAggregateOutputType>;
  _max?: Maybe<ChannelMessageAttachmentMaxAggregateOutputType>;
  _min?: Maybe<ChannelMessageAttachmentMinAggregateOutputType>;
};

export type AggregateChannelMessageAutoResponse = {
  __typename?: 'AggregateChannelMessageAutoResponse';
  _count?: Maybe<ChannelMessageAutoResponseCountAggregateOutputType>;
  _max?: Maybe<ChannelMessageAutoResponseMaxAggregateOutputType>;
  _min?: Maybe<ChannelMessageAutoResponseMinAggregateOutputType>;
};

export type AggregateChannelMessageReaction = {
  __typename?: 'AggregateChannelMessageReaction';
  _count?: Maybe<ChannelMessageReactionCountAggregateOutputType>;
  _max?: Maybe<ChannelMessageReactionMaxAggregateOutputType>;
  _min?: Maybe<ChannelMessageReactionMinAggregateOutputType>;
};

export type AggregateChannelPin = {
  __typename?: 'AggregateChannelPin';
  _count?: Maybe<ChannelPinCountAggregateOutputType>;
  _max?: Maybe<ChannelPinMaxAggregateOutputType>;
  _min?: Maybe<ChannelPinMinAggregateOutputType>;
};

export type AggregateChannelSelection = {
  __typename?: 'AggregateChannelSelection';
  _count?: Maybe<ChannelSelectionCountAggregateOutputType>;
  _max?: Maybe<ChannelSelectionMaxAggregateOutputType>;
  _min?: Maybe<ChannelSelectionMinAggregateOutputType>;
};

export type AggregateChannelStatus = {
  __typename?: 'AggregateChannelStatus';
  _avg?: Maybe<ChannelStatusAvgAggregateOutputType>;
  _count?: Maybe<ChannelStatusCountAggregateOutputType>;
  _max?: Maybe<ChannelStatusMaxAggregateOutputType>;
  _min?: Maybe<ChannelStatusMinAggregateOutputType>;
  _sum?: Maybe<ChannelStatusSumAggregateOutputType>;
};

export type AggregateChannelStatusChannelAssignee = {
  __typename?: 'AggregateChannelStatusChannelAssignee';
  _count?: Maybe<ChannelStatusChannelAssigneeCountAggregateOutputType>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxAggregateOutputType>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinAggregateOutputType>;
};

export type AggregateChemicalCompound = {
  __typename?: 'AggregateChemicalCompound';
  _count?: Maybe<ChemicalCompoundCountAggregateOutputType>;
  _max?: Maybe<ChemicalCompoundMaxAggregateOutputType>;
  _min?: Maybe<ChemicalCompoundMinAggregateOutputType>;
};

export type AggregateClientConnectIntegration = {
  __typename?: 'AggregateClientConnectIntegration';
  _count?: Maybe<ClientConnectIntegrationCountAggregateOutputType>;
  _max?: Maybe<ClientConnectIntegrationMaxAggregateOutputType>;
  _min?: Maybe<ClientConnectIntegrationMinAggregateOutputType>;
};

export type AggregateClinic = {
  __typename?: 'AggregateClinic';
  _avg?: Maybe<ClinicAvgAggregateOutputType>;
  _count?: Maybe<ClinicCountAggregateOutputType>;
  _max?: Maybe<ClinicMaxAggregateOutputType>;
  _min?: Maybe<ClinicMinAggregateOutputType>;
  _sum?: Maybe<ClinicSumAggregateOutputType>;
};

export type AggregateClinicBlockedPhoneNumber = {
  __typename?: 'AggregateClinicBlockedPhoneNumber';
  _count?: Maybe<ClinicBlockedPhoneNumberCountAggregateOutputType>;
  _max?: Maybe<ClinicBlockedPhoneNumberMaxAggregateOutputType>;
  _min?: Maybe<ClinicBlockedPhoneNumberMinAggregateOutputType>;
};

export type AggregateClinicDirectBookingSetting = {
  __typename?: 'AggregateClinicDirectBookingSetting';
  _avg?: Maybe<ClinicDirectBookingSettingAvgAggregateOutputType>;
  _count?: Maybe<ClinicDirectBookingSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicDirectBookingSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicDirectBookingSettingMinAggregateOutputType>;
  _sum?: Maybe<ClinicDirectBookingSettingSumAggregateOutputType>;
};

export type AggregateClinicEmailCampaignSetting = {
  __typename?: 'AggregateClinicEmailCampaignSetting';
  _count?: Maybe<ClinicEmailCampaignSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicEmailCampaignSettingMinAggregateOutputType>;
};

export type AggregateClinicEmployee = {
  __typename?: 'AggregateClinicEmployee';
  _count?: Maybe<ClinicEmployeeCountAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeMaxAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeMinAggregateOutputType>;
};

export type AggregateClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'AggregateClinicEmployeeAppointmentTypeSetting';
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType>;
};

export type AggregateClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'AggregateClinicEmployeeToWorkflowEventSetting';
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType>;
};

export type AggregateClinicEntityPhoneNumber = {
  __typename?: 'AggregateClinicEntityPhoneNumber';
  _count?: Maybe<ClinicEntityPhoneNumberCountAggregateOutputType>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxAggregateOutputType>;
  _min?: Maybe<ClinicEntityPhoneNumberMinAggregateOutputType>;
};

export type AggregateClinicOfficeHour = {
  __typename?: 'AggregateClinicOfficeHour';
  _avg?: Maybe<ClinicOfficeHourAvgAggregateOutputType>;
  _count?: Maybe<ClinicOfficeHourCountAggregateOutputType>;
  _max?: Maybe<ClinicOfficeHourMaxAggregateOutputType>;
  _min?: Maybe<ClinicOfficeHourMinAggregateOutputType>;
  _sum?: Maybe<ClinicOfficeHourSumAggregateOutputType>;
};

export type AggregateClinicOnboarding = {
  __typename?: 'AggregateClinicOnboarding';
  _count?: Maybe<ClinicOnboardingCountAggregateOutputType>;
  _max?: Maybe<ClinicOnboardingMaxAggregateOutputType>;
  _min?: Maybe<ClinicOnboardingMinAggregateOutputType>;
};

export type AggregateClinicPaymentFeeConfiguration = {
  __typename?: 'AggregateClinicPaymentFeeConfiguration';
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgAggregateOutputType>;
  _count?: Maybe<ClinicPaymentFeeConfigurationCountAggregateOutputType>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxAggregateOutputType>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinAggregateOutputType>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumAggregateOutputType>;
};

export type AggregateClinicPet = {
  __typename?: 'AggregateClinicPet';
  _avg?: Maybe<ClinicPetAvgAggregateOutputType>;
  _count?: Maybe<ClinicPetCountAggregateOutputType>;
  _max?: Maybe<ClinicPetMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetMinAggregateOutputType>;
  _sum?: Maybe<ClinicPetSumAggregateOutputType>;
};

export type AggregateClinicPetAlert = {
  __typename?: 'AggregateClinicPetAlert';
  _count?: Maybe<ClinicPetAlertCountAggregateOutputType>;
  _max?: Maybe<ClinicPetAlertMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetAlertMinAggregateOutputType>;
};

export type AggregateClinicPetCustomFieldValue = {
  __typename?: 'AggregateClinicPetCustomFieldValue';
  _count?: Maybe<ClinicPetCustomFieldValueCountAggregateOutputType>;
  _max?: Maybe<ClinicPetCustomFieldValueMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetCustomFieldValueMinAggregateOutputType>;
};

export type AggregateClinicPetParent = {
  __typename?: 'AggregateClinicPetParent';
  _count?: Maybe<ClinicPetParentCountAggregateOutputType>;
  _max?: Maybe<ClinicPetParentMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetParentMinAggregateOutputType>;
};

export type AggregateClinicPetParentAddress = {
  __typename?: 'AggregateClinicPetParentAddress';
  _count?: Maybe<ClinicPetParentAddressCountAggregateOutputType>;
  _max?: Maybe<ClinicPetParentAddressMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetParentAddressMinAggregateOutputType>;
};

export type AggregateClinicPetParentCustomFieldValue = {
  __typename?: 'AggregateClinicPetParentCustomFieldValue';
  _count?: Maybe<ClinicPetParentCustomFieldValueCountAggregateOutputType>;
  _max?: Maybe<ClinicPetParentCustomFieldValueMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetParentCustomFieldValueMinAggregateOutputType>;
};

export type AggregateClinicPetParentDevice = {
  __typename?: 'AggregateClinicPetParentDevice';
  _count?: Maybe<ClinicPetParentDeviceCountAggregateOutputType>;
  _max?: Maybe<ClinicPetParentDeviceMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetParentDeviceMinAggregateOutputType>;
};

export type AggregateClinicPetParentPimsSyncStatus = {
  __typename?: 'AggregateClinicPetParentPimsSyncStatus';
  _avg?: Maybe<ClinicPetParentPimsSyncStatusAvgAggregateOutputType>;
  _count?: Maybe<ClinicPetParentPimsSyncStatusCountAggregateOutputType>;
  _max?: Maybe<ClinicPetParentPimsSyncStatusMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetParentPimsSyncStatusMinAggregateOutputType>;
  _sum?: Maybe<ClinicPetParentPimsSyncStatusSumAggregateOutputType>;
};

export type AggregateClinicPetPimsSyncStatus = {
  __typename?: 'AggregateClinicPetPimsSyncStatus';
  _avg?: Maybe<ClinicPetPimsSyncStatusAvgAggregateOutputType>;
  _count?: Maybe<ClinicPetPimsSyncStatusCountAggregateOutputType>;
  _max?: Maybe<ClinicPetPimsSyncStatusMaxAggregateOutputType>;
  _min?: Maybe<ClinicPetPimsSyncStatusMinAggregateOutputType>;
  _sum?: Maybe<ClinicPetPimsSyncStatusSumAggregateOutputType>;
};

export type AggregateClinicPhoneNumber = {
  __typename?: 'AggregateClinicPhoneNumber';
  _count?: Maybe<ClinicPhoneNumberCountAggregateOutputType>;
  _max?: Maybe<ClinicPhoneNumberMaxAggregateOutputType>;
  _min?: Maybe<ClinicPhoneNumberMinAggregateOutputType>;
};

export type AggregateClinicPimsIntegration = {
  __typename?: 'AggregateClinicPimsIntegration';
  _count?: Maybe<ClinicPimsIntegrationCountAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationMaxAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationMinAggregateOutputType>;
};

export type AggregateClinicPimsIntegrationCapability = {
  __typename?: 'AggregateClinicPimsIntegrationCapability';
  _avg?: Maybe<ClinicPimsIntegrationCapabilityAvgAggregateOutputType>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinAggregateOutputType>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilitySumAggregateOutputType>;
};

export type AggregateClinicPimsIntegrationCapabilityHistoryEntry = {
  __typename?: 'AggregateClinicPimsIntegrationCapabilityHistoryEntry';
  _avg?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType>;
};

export type AggregateClinicPostcardSetting = {
  __typename?: 'AggregateClinicPostcardSetting';
  _count?: Maybe<ClinicPostcardSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicPostcardSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicPostcardSettingMinAggregateOutputType>;
};

export type AggregateClinicRoom = {
  __typename?: 'AggregateClinicRoom';
  _count?: Maybe<ClinicRoomCountAggregateOutputType>;
  _max?: Maybe<ClinicRoomMaxAggregateOutputType>;
  _min?: Maybe<ClinicRoomMinAggregateOutputType>;
};

export type AggregateClinicRoomToWorkflowEventSetting = {
  __typename?: 'AggregateClinicRoomToWorkflowEventSetting';
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinAggregateOutputType>;
};

export type AggregateClinicSetting = {
  __typename?: 'AggregateClinicSetting';
  _avg?: Maybe<ClinicSettingAvgAggregateOutputType>;
  _count?: Maybe<ClinicSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicSettingMinAggregateOutputType>;
  _sum?: Maybe<ClinicSettingSumAggregateOutputType>;
};

export type AggregateClinicWidgetRequest = {
  __typename?: 'AggregateClinicWidgetRequest';
  _count?: Maybe<ClinicWidgetRequestCountAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestMaxAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestMinAggregateOutputType>;
};

export type AggregateClinicWidgetRequestType = {
  __typename?: 'AggregateClinicWidgetRequestType';
  _avg?: Maybe<ClinicWidgetRequestTypeAvgAggregateOutputType>;
  _count?: Maybe<ClinicWidgetRequestTypeCountAggregateOutputType>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxAggregateOutputType>;
  _min?: Maybe<ClinicWidgetRequestTypeMinAggregateOutputType>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumAggregateOutputType>;
};

export type AggregateClinicWidgetSetting = {
  __typename?: 'AggregateClinicWidgetSetting';
  _count?: Maybe<ClinicWidgetSettingCountAggregateOutputType>;
  _max?: Maybe<ClinicWidgetSettingMaxAggregateOutputType>;
  _min?: Maybe<ClinicWidgetSettingMinAggregateOutputType>;
};

export type AggregateClinicWorkflowType = {
  __typename?: 'AggregateClinicWorkflowType';
  _count?: Maybe<ClinicWorkflowTypeCountAggregateOutputType>;
  _max?: Maybe<ClinicWorkflowTypeMaxAggregateOutputType>;
  _min?: Maybe<ClinicWorkflowTypeMinAggregateOutputType>;
};

export type AggregateColor = {
  __typename?: 'AggregateColor';
  _count?: Maybe<ColorCountAggregateOutputType>;
  _max?: Maybe<ColorMaxAggregateOutputType>;
  _min?: Maybe<ColorMinAggregateOutputType>;
};

export type AggregateCondition = {
  __typename?: 'AggregateCondition';
  _count?: Maybe<ConditionCountAggregateOutputType>;
  _max?: Maybe<ConditionMaxAggregateOutputType>;
  _min?: Maybe<ConditionMinAggregateOutputType>;
};

export type AggregateConditionSet = {
  __typename?: 'AggregateConditionSet';
  _count?: Maybe<ConditionSetCountAggregateOutputType>;
  _max?: Maybe<ConditionSetMaxAggregateOutputType>;
  _min?: Maybe<ConditionSetMinAggregateOutputType>;
};

export type AggregateConditionalEntityEvaluation = {
  __typename?: 'AggregateConditionalEntityEvaluation';
  _count?: Maybe<ConditionalEntityEvaluationCountAggregateOutputType>;
  _max?: Maybe<ConditionalEntityEvaluationMaxAggregateOutputType>;
  _min?: Maybe<ConditionalEntityEvaluationMinAggregateOutputType>;
};

export type AggregateConsultation = {
  __typename?: 'AggregateConsultation';
  _avg?: Maybe<ConsultationAvgAggregateOutputType>;
  _count?: Maybe<ConsultationCountAggregateOutputType>;
  _max?: Maybe<ConsultationMaxAggregateOutputType>;
  _min?: Maybe<ConsultationMinAggregateOutputType>;
  _sum?: Maybe<ConsultationSumAggregateOutputType>;
};

export type AggregateConsultationChat = {
  __typename?: 'AggregateConsultationChat';
  _count?: Maybe<ConsultationChatCountAggregateOutputType>;
  _max?: Maybe<ConsultationChatMaxAggregateOutputType>;
  _min?: Maybe<ConsultationChatMinAggregateOutputType>;
};

export type AggregateConsultationRequest = {
  __typename?: 'AggregateConsultationRequest';
  _count?: Maybe<ConsultationRequestCountAggregateOutputType>;
  _max?: Maybe<ConsultationRequestMaxAggregateOutputType>;
  _min?: Maybe<ConsultationRequestMinAggregateOutputType>;
};

export type AggregateCustomFieldDefinition = {
  __typename?: 'AggregateCustomFieldDefinition';
  _count?: Maybe<CustomFieldDefinitionCountAggregateOutputType>;
  _max?: Maybe<CustomFieldDefinitionMaxAggregateOutputType>;
  _min?: Maybe<CustomFieldDefinitionMinAggregateOutputType>;
};

export type AggregateCustomFieldPimsWritebackLogEntry = {
  __typename?: 'AggregateCustomFieldPimsWritebackLogEntry';
  _count?: Maybe<CustomFieldPimsWritebackLogEntryCountAggregateOutputType>;
  _max?: Maybe<CustomFieldPimsWritebackLogEntryMaxAggregateOutputType>;
  _min?: Maybe<CustomFieldPimsWritebackLogEntryMinAggregateOutputType>;
};

export type AggregateDataPointIntegration = {
  __typename?: 'AggregateDataPointIntegration';
  _count?: Maybe<DataPointIntegrationCountAggregateOutputType>;
  _max?: Maybe<DataPointIntegrationMaxAggregateOutputType>;
  _min?: Maybe<DataPointIntegrationMinAggregateOutputType>;
};

export type AggregateDirectBookingAppointmentTypeSetting = {
  __typename?: 'AggregateDirectBookingAppointmentTypeSetting';
  _avg?: Maybe<DirectBookingAppointmentTypeSettingAvgAggregateOutputType>;
  _count?: Maybe<DirectBookingAppointmentTypeSettingCountAggregateOutputType>;
  _max?: Maybe<DirectBookingAppointmentTypeSettingMaxAggregateOutputType>;
  _min?: Maybe<DirectBookingAppointmentTypeSettingMinAggregateOutputType>;
  _sum?: Maybe<DirectBookingAppointmentTypeSettingSumAggregateOutputType>;
};

export type AggregateDirectBookingExclusionRule = {
  __typename?: 'AggregateDirectBookingExclusionRule';
  _count?: Maybe<DirectBookingExclusionRuleCountAggregateOutputType>;
  _max?: Maybe<DirectBookingExclusionRuleMaxAggregateOutputType>;
  _min?: Maybe<DirectBookingExclusionRuleMinAggregateOutputType>;
};

export type AggregateDirectBookingExclusionTimeSlot = {
  __typename?: 'AggregateDirectBookingExclusionTimeSlot';
  _avg?: Maybe<DirectBookingExclusionTimeSlotAvgAggregateOutputType>;
  _count?: Maybe<DirectBookingExclusionTimeSlotCountAggregateOutputType>;
  _max?: Maybe<DirectBookingExclusionTimeSlotMaxAggregateOutputType>;
  _min?: Maybe<DirectBookingExclusionTimeSlotMinAggregateOutputType>;
  _sum?: Maybe<DirectBookingExclusionTimeSlotSumAggregateOutputType>;
};

export type AggregateDisbursement = {
  __typename?: 'AggregateDisbursement';
  _avg?: Maybe<DisbursementAvgAggregateOutputType>;
  _count?: Maybe<DisbursementCountAggregateOutputType>;
  _max?: Maybe<DisbursementMaxAggregateOutputType>;
  _min?: Maybe<DisbursementMinAggregateOutputType>;
  _sum?: Maybe<DisbursementSumAggregateOutputType>;
};

export type AggregateDocumentation = {
  __typename?: 'AggregateDocumentation';
  _count?: Maybe<DocumentationCountAggregateOutputType>;
  _max?: Maybe<DocumentationMaxAggregateOutputType>;
  _min?: Maybe<DocumentationMinAggregateOutputType>;
};

export type AggregateEmailCampaign = {
  __typename?: 'AggregateEmailCampaign';
  _avg?: Maybe<EmailCampaignAvgAggregateOutputType>;
  _count?: Maybe<EmailCampaignCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignMinAggregateOutputType>;
  _sum?: Maybe<EmailCampaignSumAggregateOutputType>;
};

export type AggregateEmailCampaignCustomList = {
  __typename?: 'AggregateEmailCampaignCustomList';
  _avg?: Maybe<EmailCampaignCustomListAvgAggregateOutputType>;
  _count?: Maybe<EmailCampaignCustomListCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignCustomListMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignCustomListMinAggregateOutputType>;
  _sum?: Maybe<EmailCampaignCustomListSumAggregateOutputType>;
};

export type AggregateEmailCampaignCustomListRecipient = {
  __typename?: 'AggregateEmailCampaignCustomListRecipient';
  _count?: Maybe<EmailCampaignCustomListRecipientCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignCustomListRecipientMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignCustomListRecipientMinAggregateOutputType>;
};

export type AggregateEmailCampaignCustomListUploadHistory = {
  __typename?: 'AggregateEmailCampaignCustomListUploadHistory';
  _count?: Maybe<EmailCampaignCustomListUploadHistoryCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignCustomListUploadHistoryMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignCustomListUploadHistoryMinAggregateOutputType>;
};

export type AggregateEmailCampaignLog = {
  __typename?: 'AggregateEmailCampaignLog';
  _count?: Maybe<EmailCampaignLogCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignLogMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignLogMinAggregateOutputType>;
};

export type AggregateEmailCampaignTemplate = {
  __typename?: 'AggregateEmailCampaignTemplate';
  _count?: Maybe<EmailCampaignTemplateCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignTemplateMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignTemplateMinAggregateOutputType>;
};

export type AggregateEmailCampaignUnsubscribe = {
  __typename?: 'AggregateEmailCampaignUnsubscribe';
  _count?: Maybe<EmailCampaignUnsubscribeCountAggregateOutputType>;
  _max?: Maybe<EmailCampaignUnsubscribeMaxAggregateOutputType>;
  _min?: Maybe<EmailCampaignUnsubscribeMinAggregateOutputType>;
};

export type AggregateEmailProviderLog = {
  __typename?: 'AggregateEmailProviderLog';
  _count?: Maybe<EmailProviderLogCountAggregateOutputType>;
  _max?: Maybe<EmailProviderLogMaxAggregateOutputType>;
  _min?: Maybe<EmailProviderLogMinAggregateOutputType>;
};

export type AggregateEmailTemplate = {
  __typename?: 'AggregateEmailTemplate';
  _avg?: Maybe<EmailTemplateAvgAggregateOutputType>;
  _count?: Maybe<EmailTemplateCountAggregateOutputType>;
  _max?: Maybe<EmailTemplateMaxAggregateOutputType>;
  _min?: Maybe<EmailTemplateMinAggregateOutputType>;
  _sum?: Maybe<EmailTemplateSumAggregateOutputType>;
};

export type AggregateEmailTemplateVersion = {
  __typename?: 'AggregateEmailTemplateVersion';
  _avg?: Maybe<EmailTemplateVersionAvgAggregateOutputType>;
  _count?: Maybe<EmailTemplateVersionCountAggregateOutputType>;
  _max?: Maybe<EmailTemplateVersionMaxAggregateOutputType>;
  _min?: Maybe<EmailTemplateVersionMinAggregateOutputType>;
  _sum?: Maybe<EmailTemplateVersionSumAggregateOutputType>;
};

export type AggregateEmergencyClinics = {
  __typename?: 'AggregateEmergencyClinics';
  _avg?: Maybe<EmergencyClinicsAvgAggregateOutputType>;
  _count?: Maybe<EmergencyClinicsCountAggregateOutputType>;
  _max?: Maybe<EmergencyClinicsMaxAggregateOutputType>;
  _min?: Maybe<EmergencyClinicsMinAggregateOutputType>;
  _sum?: Maybe<EmergencyClinicsSumAggregateOutputType>;
};

export type AggregateEnrollmentSupportEvent = {
  __typename?: 'AggregateEnrollmentSupportEvent';
  _avg?: Maybe<EnrollmentSupportEventAvgAggregateOutputType>;
  _count?: Maybe<EnrollmentSupportEventCountAggregateOutputType>;
  _max?: Maybe<EnrollmentSupportEventMaxAggregateOutputType>;
  _min?: Maybe<EnrollmentSupportEventMinAggregateOutputType>;
  _sum?: Maybe<EnrollmentSupportEventSumAggregateOutputType>;
};

export type AggregateEzyVetIntegration = {
  __typename?: 'AggregateEzyVetIntegration';
  _count?: Maybe<EzyVetIntegrationCountAggregateOutputType>;
  _max?: Maybe<EzyVetIntegrationMaxAggregateOutputType>;
  _min?: Maybe<EzyVetIntegrationMinAggregateOutputType>;
};

export type AggregateFeatureFlag = {
  __typename?: 'AggregateFeatureFlag';
  _count?: Maybe<FeatureFlagCountAggregateOutputType>;
  _max?: Maybe<FeatureFlagMaxAggregateOutputType>;
  _min?: Maybe<FeatureFlagMinAggregateOutputType>;
};

export type AggregateFinancialAdjustment = {
  __typename?: 'AggregateFinancialAdjustment';
  _avg?: Maybe<FinancialAdjustmentAvgAggregateOutputType>;
  _count?: Maybe<FinancialAdjustmentCountAggregateOutputType>;
  _max?: Maybe<FinancialAdjustmentMaxAggregateOutputType>;
  _min?: Maybe<FinancialAdjustmentMinAggregateOutputType>;
  _sum?: Maybe<FinancialAdjustmentSumAggregateOutputType>;
};

export type AggregateFinancialTransaction = {
  __typename?: 'AggregateFinancialTransaction';
  _count?: Maybe<FinancialTransactionCountAggregateOutputType>;
  _max?: Maybe<FinancialTransactionMaxAggregateOutputType>;
  _min?: Maybe<FinancialTransactionMinAggregateOutputType>;
};

export type AggregateFirebaseStructure = {
  __typename?: 'AggregateFirebaseStructure';
  _count?: Maybe<FirebaseStructureCountAggregateOutputType>;
  _max?: Maybe<FirebaseStructureMaxAggregateOutputType>;
  _min?: Maybe<FirebaseStructureMinAggregateOutputType>;
};

export type AggregateFormSubmission = {
  __typename?: 'AggregateFormSubmission';
  _count?: Maybe<FormSubmissionCountAggregateOutputType>;
  _max?: Maybe<FormSubmissionMaxAggregateOutputType>;
  _min?: Maybe<FormSubmissionMinAggregateOutputType>;
};

export type AggregateFormTemplate = {
  __typename?: 'AggregateFormTemplate';
  _count?: Maybe<FormTemplateCountAggregateOutputType>;
  _max?: Maybe<FormTemplateMaxAggregateOutputType>;
  _min?: Maybe<FormTemplateMinAggregateOutputType>;
};

export type AggregateGlobalPetParent = {
  __typename?: 'AggregateGlobalPetParent';
  _count?: Maybe<GlobalPetParentCountAggregateOutputType>;
  _max?: Maybe<GlobalPetParentMaxAggregateOutputType>;
  _min?: Maybe<GlobalPetParentMinAggregateOutputType>;
};

export type AggregateHillsToHomeApiLog = {
  __typename?: 'AggregateHillsToHomeAPILog';
  _count?: Maybe<HillsToHomeApiLogCountAggregateOutputType>;
  _max?: Maybe<HillsToHomeApiLogMaxAggregateOutputType>;
  _min?: Maybe<HillsToHomeApiLogMinAggregateOutputType>;
};

export type AggregateImage = {
  __typename?: 'AggregateImage';
  _count?: Maybe<ImageCountAggregateOutputType>;
  _max?: Maybe<ImageMaxAggregateOutputType>;
  _min?: Maybe<ImageMinAggregateOutputType>;
};

export type AggregateInformAudienceDefinition = {
  __typename?: 'AggregateInformAudienceDefinition';
  _count?: Maybe<InformAudienceDefinitionCountAggregateOutputType>;
  _max?: Maybe<InformAudienceDefinitionMaxAggregateOutputType>;
  _min?: Maybe<InformAudienceDefinitionMinAggregateOutputType>;
};

export type AggregateInformAutomationCampaign = {
  __typename?: 'AggregateInformAutomationCampaign';
  _count?: Maybe<InformAutomationCampaignCountAggregateOutputType>;
  _max?: Maybe<InformAutomationCampaignMaxAggregateOutputType>;
  _min?: Maybe<InformAutomationCampaignMinAggregateOutputType>;
};

export type AggregateInformCampaign = {
  __typename?: 'AggregateInformCampaign';
  _count?: Maybe<InformCampaignCountAggregateOutputType>;
  _max?: Maybe<InformCampaignMaxAggregateOutputType>;
  _min?: Maybe<InformCampaignMinAggregateOutputType>;
};

export type AggregateInformCampaignEnrollment = {
  __typename?: 'AggregateInformCampaignEnrollment';
  _count?: Maybe<InformCampaignEnrollmentCountAggregateOutputType>;
  _max?: Maybe<InformCampaignEnrollmentMaxAggregateOutputType>;
  _min?: Maybe<InformCampaignEnrollmentMinAggregateOutputType>;
};

export type AggregateInformCampaignEnrollmentCanonicalService = {
  __typename?: 'AggregateInformCampaignEnrollmentCanonicalService';
  _count?: Maybe<InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType>;
  _max?: Maybe<InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType>;
  _min?: Maybe<InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType>;
};

export type AggregateInformCampaignExecution = {
  __typename?: 'AggregateInformCampaignExecution';
  _count?: Maybe<InformCampaignExecutionCountAggregateOutputType>;
  _max?: Maybe<InformCampaignExecutionMaxAggregateOutputType>;
  _min?: Maybe<InformCampaignExecutionMinAggregateOutputType>;
};

export type AggregateInformCampaignGroup = {
  __typename?: 'AggregateInformCampaignGroup';
  _count?: Maybe<InformCampaignGroupCountAggregateOutputType>;
  _max?: Maybe<InformCampaignGroupMaxAggregateOutputType>;
  _min?: Maybe<InformCampaignGroupMinAggregateOutputType>;
};

export type AggregateInformControlGroupParticipant = {
  __typename?: 'AggregateInformControlGroupParticipant';
  _count?: Maybe<InformControlGroupParticipantCountAggregateOutputType>;
  _max?: Maybe<InformControlGroupParticipantMaxAggregateOutputType>;
  _min?: Maybe<InformControlGroupParticipantMinAggregateOutputType>;
};

export type AggregateInformEmailTemplate = {
  __typename?: 'AggregateInformEmailTemplate';
  _count?: Maybe<InformEmailTemplateCountAggregateOutputType>;
  _max?: Maybe<InformEmailTemplateMaxAggregateOutputType>;
  _min?: Maybe<InformEmailTemplateMinAggregateOutputType>;
};

export type AggregateInformEntityHistory = {
  __typename?: 'AggregateInformEntityHistory';
  _count?: Maybe<InformEntityHistoryCountAggregateOutputType>;
  _max?: Maybe<InformEntityHistoryMaxAggregateOutputType>;
  _min?: Maybe<InformEntityHistoryMinAggregateOutputType>;
};

export type AggregateInformPartner = {
  __typename?: 'AggregateInformPartner';
  _count?: Maybe<InformPartnerCountAggregateOutputType>;
  _max?: Maybe<InformPartnerMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerMinAggregateOutputType>;
};

export type AggregateInformPartnerCustomer = {
  __typename?: 'AggregateInformPartnerCustomer';
  _count?: Maybe<InformPartnerCustomerCountAggregateOutputType>;
  _max?: Maybe<InformPartnerCustomerMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerCustomerMinAggregateOutputType>;
};

export type AggregateInformPartnerCustomerPharmacyProvider = {
  __typename?: 'AggregateInformPartnerCustomerPharmacyProvider';
  _count?: Maybe<InformPartnerCustomerPharmacyProviderCountAggregateOutputType>;
  _max?: Maybe<InformPartnerCustomerPharmacyProviderMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerCustomerPharmacyProviderMinAggregateOutputType>;
};

export type AggregateInformPartnerIntegration = {
  __typename?: 'AggregateInformPartnerIntegration';
  _count?: Maybe<InformPartnerIntegrationCountAggregateOutputType>;
  _max?: Maybe<InformPartnerIntegrationMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerIntegrationMinAggregateOutputType>;
};

export type AggregateInformPartnerIntegrationModel = {
  __typename?: 'AggregateInformPartnerIntegrationModel';
  _avg?: Maybe<InformPartnerIntegrationModelAvgAggregateOutputType>;
  _count?: Maybe<InformPartnerIntegrationModelCountAggregateOutputType>;
  _max?: Maybe<InformPartnerIntegrationModelMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerIntegrationModelMinAggregateOutputType>;
  _sum?: Maybe<InformPartnerIntegrationModelSumAggregateOutputType>;
};

export type AggregateInformPartnerProgram = {
  __typename?: 'AggregateInformPartnerProgram';
  _count?: Maybe<InformPartnerProgramCountAggregateOutputType>;
  _max?: Maybe<InformPartnerProgramMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerProgramMinAggregateOutputType>;
};

export type AggregateInformPartnerProgramEnrollment = {
  __typename?: 'AggregateInformPartnerProgramEnrollment';
  _count?: Maybe<InformPartnerProgramEnrollmentCountAggregateOutputType>;
  _max?: Maybe<InformPartnerProgramEnrollmentMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerProgramEnrollmentMinAggregateOutputType>;
};

export type AggregateInformPartnerUser = {
  __typename?: 'AggregateInformPartnerUser';
  _count?: Maybe<InformPartnerUserCountAggregateOutputType>;
  _max?: Maybe<InformPartnerUserMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerUserMinAggregateOutputType>;
};

export type AggregateInformPartnerUserRole = {
  __typename?: 'AggregateInformPartnerUserRole';
  _count?: Maybe<InformPartnerUserRoleCountAggregateOutputType>;
  _max?: Maybe<InformPartnerUserRoleMaxAggregateOutputType>;
  _min?: Maybe<InformPartnerUserRoleMinAggregateOutputType>;
};

export type AggregateInformScheduledCampaign = {
  __typename?: 'AggregateInformScheduledCampaign';
  _count?: Maybe<InformScheduledCampaignCountAggregateOutputType>;
  _max?: Maybe<InformScheduledCampaignMaxAggregateOutputType>;
  _min?: Maybe<InformScheduledCampaignMinAggregateOutputType>;
};

export type AggregateInstinctIntegration = {
  __typename?: 'AggregateInstinctIntegration';
  _count?: Maybe<InstinctIntegrationCountAggregateOutputType>;
  _max?: Maybe<InstinctIntegrationMaxAggregateOutputType>;
  _min?: Maybe<InstinctIntegrationMinAggregateOutputType>;
};

export type AggregateInsuranceIntegration = {
  __typename?: 'AggregateInsuranceIntegration';
  _count?: Maybe<InsuranceIntegrationCountAggregateOutputType>;
  _max?: Maybe<InsuranceIntegrationMaxAggregateOutputType>;
  _min?: Maybe<InsuranceIntegrationMinAggregateOutputType>;
};

export type AggregateIntegrationOutageHistoryEntry = {
  __typename?: 'AggregateIntegrationOutageHistoryEntry';
  _count?: Maybe<IntegrationOutageHistoryEntryCountAggregateOutputType>;
  _max?: Maybe<IntegrationOutageHistoryEntryMaxAggregateOutputType>;
  _min?: Maybe<IntegrationOutageHistoryEntryMinAggregateOutputType>;
};

export type AggregateInterchangeRate = {
  __typename?: 'AggregateInterchangeRate';
  _avg?: Maybe<InterchangeRateAvgAggregateOutputType>;
  _count?: Maybe<InterchangeRateCountAggregateOutputType>;
  _max?: Maybe<InterchangeRateMaxAggregateOutputType>;
  _min?: Maybe<InterchangeRateMinAggregateOutputType>;
  _sum?: Maybe<InterchangeRateSumAggregateOutputType>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  _avg?: Maybe<InvoiceAvgAggregateOutputType>;
  _count?: Maybe<InvoiceCountAggregateOutputType>;
  _max?: Maybe<InvoiceMaxAggregateOutputType>;
  _min?: Maybe<InvoiceMinAggregateOutputType>;
  _sum?: Maybe<InvoiceSumAggregateOutputType>;
};

export type AggregateInvoiceLineItem = {
  __typename?: 'AggregateInvoiceLineItem';
  _avg?: Maybe<InvoiceLineItemAvgAggregateOutputType>;
  _count?: Maybe<InvoiceLineItemCountAggregateOutputType>;
  _max?: Maybe<InvoiceLineItemMaxAggregateOutputType>;
  _min?: Maybe<InvoiceLineItemMinAggregateOutputType>;
  _sum?: Maybe<InvoiceLineItemSumAggregateOutputType>;
};

export type AggregateInvoicePimsWritebackLogEntry = {
  __typename?: 'AggregateInvoicePimsWritebackLogEntry';
  _count?: Maybe<InvoicePimsWritebackLogEntryCountAggregateOutputType>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxAggregateOutputType>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinAggregateOutputType>;
};

export type AggregateLapsedPetParentTriggers = {
  __typename?: 'AggregateLapsedPetParentTriggers';
  _count?: Maybe<LapsedPetParentTriggersCountAggregateOutputType>;
  _max?: Maybe<LapsedPetParentTriggersMaxAggregateOutputType>;
  _min?: Maybe<LapsedPetParentTriggersMinAggregateOutputType>;
};

export type AggregateLegalEntity = {
  __typename?: 'AggregateLegalEntity';
  _count?: Maybe<LegalEntityCountAggregateOutputType>;
  _max?: Maybe<LegalEntityMaxAggregateOutputType>;
  _min?: Maybe<LegalEntityMinAggregateOutputType>;
};

export type AggregateLoyaltyAccount = {
  __typename?: 'AggregateLoyaltyAccount';
  _avg?: Maybe<LoyaltyAccountAvgAggregateOutputType>;
  _count?: Maybe<LoyaltyAccountCountAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountMinAggregateOutputType>;
  _sum?: Maybe<LoyaltyAccountSumAggregateOutputType>;
};

export type AggregateLoyaltyAccountHolder = {
  __typename?: 'AggregateLoyaltyAccountHolder';
  _count?: Maybe<LoyaltyAccountHolderCountAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountHolderMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountHolderMinAggregateOutputType>;
};

export type AggregateLoyaltyAccountMerger = {
  __typename?: 'AggregateLoyaltyAccountMerger';
  _count?: Maybe<LoyaltyAccountMergerCountAggregateOutputType>;
  _max?: Maybe<LoyaltyAccountMergerMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyAccountMergerMinAggregateOutputType>;
};

export type AggregateLoyaltyInvoicePointGranter = {
  __typename?: 'AggregateLoyaltyInvoicePointGranter';
  _count?: Maybe<LoyaltyInvoicePointGranterCountAggregateOutputType>;
  _max?: Maybe<LoyaltyInvoicePointGranterMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyInvoicePointGranterMinAggregateOutputType>;
};

export type AggregateLoyaltyPointDelta = {
  __typename?: 'AggregateLoyaltyPointDelta';
  _avg?: Maybe<LoyaltyPointDeltaAvgAggregateOutputType>;
  _count?: Maybe<LoyaltyPointDeltaCountAggregateOutputType>;
  _max?: Maybe<LoyaltyPointDeltaMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyPointDeltaMinAggregateOutputType>;
  _sum?: Maybe<LoyaltyPointDeltaSumAggregateOutputType>;
};

export type AggregateLoyaltyPointGrantingService = {
  __typename?: 'AggregateLoyaltyPointGrantingService';
  _count?: Maybe<LoyaltyPointGrantingServiceCountAggregateOutputType>;
  _max?: Maybe<LoyaltyPointGrantingServiceMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyPointGrantingServiceMinAggregateOutputType>;
};

export type AggregateLoyaltyPointGrantingServiceCategory = {
  __typename?: 'AggregateLoyaltyPointGrantingServiceCategory';
  _count?: Maybe<LoyaltyPointGrantingServiceCategoryCountAggregateOutputType>;
  _max?: Maybe<LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyPointGrantingServiceCategoryMinAggregateOutputType>;
};

export type AggregateLoyaltyPointUserGrant = {
  __typename?: 'AggregateLoyaltyPointUserGrant';
  _avg?: Maybe<LoyaltyPointUserGrantAvgAggregateOutputType>;
  _count?: Maybe<LoyaltyPointUserGrantCountAggregateOutputType>;
  _max?: Maybe<LoyaltyPointUserGrantMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyPointUserGrantMinAggregateOutputType>;
  _sum?: Maybe<LoyaltyPointUserGrantSumAggregateOutputType>;
};

export type AggregateLoyaltyProgram = {
  __typename?: 'AggregateLoyaltyProgram';
  _avg?: Maybe<LoyaltyProgramAvgAggregateOutputType>;
  _count?: Maybe<LoyaltyProgramCountAggregateOutputType>;
  _max?: Maybe<LoyaltyProgramMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyProgramMinAggregateOutputType>;
  _sum?: Maybe<LoyaltyProgramSumAggregateOutputType>;
};

export type AggregateLoyaltyProgramStatusHistory = {
  __typename?: 'AggregateLoyaltyProgramStatusHistory';
  _count?: Maybe<LoyaltyProgramStatusHistoryCountAggregateOutputType>;
  _max?: Maybe<LoyaltyProgramStatusHistoryMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyProgramStatusHistoryMinAggregateOutputType>;
};

export type AggregateLoyaltyReward = {
  __typename?: 'AggregateLoyaltyReward';
  _avg?: Maybe<LoyaltyRewardAvgAggregateOutputType>;
  _count?: Maybe<LoyaltyRewardCountAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardMinAggregateOutputType>;
  _sum?: Maybe<LoyaltyRewardSumAggregateOutputType>;
};

export type AggregateLoyaltyRewardRedemption = {
  __typename?: 'AggregateLoyaltyRewardRedemption';
  _count?: Maybe<LoyaltyRewardRedemptionCountAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardRedemptionMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardRedemptionMinAggregateOutputType>;
};

export type AggregateLoyaltyRewardRedemptionHistoryEntry = {
  __typename?: 'AggregateLoyaltyRewardRedemptionHistoryEntry';
  _count?: Maybe<LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType>;
  _max?: Maybe<LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType>;
  _min?: Maybe<LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType>;
};

export type AggregateMassTextAlert = {
  __typename?: 'AggregateMassTextAlert';
  _count?: Maybe<MassTextAlertCountAggregateOutputType>;
  _max?: Maybe<MassTextAlertMaxAggregateOutputType>;
  _min?: Maybe<MassTextAlertMinAggregateOutputType>;
};

export type AggregateMassTextAlertEntry = {
  __typename?: 'AggregateMassTextAlertEntry';
  _count?: Maybe<MassTextAlertEntryCountAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryMaxAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryMinAggregateOutputType>;
};

export type AggregateMassTextAlertEntryAppointment = {
  __typename?: 'AggregateMassTextAlertEntryAppointment';
  _count?: Maybe<MassTextAlertEntryAppointmentCountAggregateOutputType>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxAggregateOutputType>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinAggregateOutputType>;
};

export type AggregateMedia = {
  __typename?: 'AggregateMedia';
  _avg?: Maybe<MediaAvgAggregateOutputType>;
  _count?: Maybe<MediaCountAggregateOutputType>;
  _max?: Maybe<MediaMaxAggregateOutputType>;
  _min?: Maybe<MediaMinAggregateOutputType>;
  _sum?: Maybe<MediaSumAggregateOutputType>;
};

export type AggregateMessageTemplate = {
  __typename?: 'AggregateMessageTemplate';
  _count?: Maybe<MessageTemplateCountAggregateOutputType>;
  _max?: Maybe<MessageTemplateMaxAggregateOutputType>;
  _min?: Maybe<MessageTemplateMinAggregateOutputType>;
};

export type AggregateMessagingCampaign = {
  __typename?: 'AggregateMessagingCampaign';
  _count?: Maybe<MessagingCampaignCountAggregateOutputType>;
  _max?: Maybe<MessagingCampaignMaxAggregateOutputType>;
  _min?: Maybe<MessagingCampaignMinAggregateOutputType>;
};

export type AggregateMessagingPartner = {
  __typename?: 'AggregateMessagingPartner';
  _count?: Maybe<MessagingPartnerCountAggregateOutputType>;
  _max?: Maybe<MessagingPartnerMaxAggregateOutputType>;
  _min?: Maybe<MessagingPartnerMinAggregateOutputType>;
};

export type AggregateNeoIntegration = {
  __typename?: 'AggregateNeoIntegration';
  _count?: Maybe<NeoIntegrationCountAggregateOutputType>;
  _max?: Maybe<NeoIntegrationMaxAggregateOutputType>;
  _min?: Maybe<NeoIntegrationMinAggregateOutputType>;
};

export type AggregateNonPrismaSchemaScriptRan = {
  __typename?: 'AggregateNonPrismaSchemaScriptRan';
  _count?: Maybe<NonPrismaSchemaScriptRanCountAggregateOutputType>;
  _max?: Maybe<NonPrismaSchemaScriptRanMaxAggregateOutputType>;
  _min?: Maybe<NonPrismaSchemaScriptRanMinAggregateOutputType>;
};

export type AggregateNotificationEventToMessage = {
  __typename?: 'AggregateNotificationEventToMessage';
  _count?: Maybe<NotificationEventToMessageCountAggregateOutputType>;
  _max?: Maybe<NotificationEventToMessageMaxAggregateOutputType>;
  _min?: Maybe<NotificationEventToMessageMinAggregateOutputType>;
};

export type AggregateOrganization = {
  __typename?: 'AggregateOrganization';
  _count?: Maybe<OrganizationCountAggregateOutputType>;
  _max?: Maybe<OrganizationMaxAggregateOutputType>;
  _min?: Maybe<OrganizationMinAggregateOutputType>;
};

export type AggregateOrganizationCareBenefitToClinicService = {
  __typename?: 'AggregateOrganizationCareBenefitToClinicService';
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountAggregateOutputType>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxAggregateOutputType>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinAggregateOutputType>;
};

export type AggregateOrganizationCareBenefitToClinicServiceCategory = {
  __typename?: 'AggregateOrganizationCareBenefitToClinicServiceCategory';
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType>;
};

export type AggregateOrganizationPet = {
  __typename?: 'AggregateOrganizationPet';
  _count?: Maybe<OrganizationPetCountAggregateOutputType>;
  _max?: Maybe<OrganizationPetMaxAggregateOutputType>;
  _min?: Maybe<OrganizationPetMinAggregateOutputType>;
};

export type AggregateOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetParent';
  _count?: Maybe<OrganizationPetParentCountAggregateOutputType>;
  _max?: Maybe<OrganizationPetParentMaxAggregateOutputType>;
  _min?: Maybe<OrganizationPetParentMinAggregateOutputType>;
};

export type AggregateOrganizationPetToOrganizationPetParent = {
  __typename?: 'AggregateOrganizationPetToOrganizationPetParent';
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountAggregateOutputType>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxAggregateOutputType>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinAggregateOutputType>;
};

export type AggregateOwnerInfo = {
  __typename?: 'AggregateOwnerInfo';
  _count?: Maybe<OwnerInfoCountAggregateOutputType>;
  _max?: Maybe<OwnerInfoMaxAggregateOutputType>;
  _min?: Maybe<OwnerInfoMinAggregateOutputType>;
};

export type AggregatePpcIntegration = {
  __typename?: 'AggregatePPCIntegration';
  _count?: Maybe<PpcIntegrationCountAggregateOutputType>;
  _max?: Maybe<PpcIntegrationMaxAggregateOutputType>;
  _min?: Maybe<PpcIntegrationMinAggregateOutputType>;
};

export type AggregatePaymentPimsWritebackLogEntry = {
  __typename?: 'AggregatePaymentPimsWritebackLogEntry';
  _count?: Maybe<PaymentPimsWritebackLogEntryCountAggregateOutputType>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxAggregateOutputType>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinAggregateOutputType>;
};

export type AggregatePayoutBatchingPeriod = {
  __typename?: 'AggregatePayoutBatchingPeriod';
  _count?: Maybe<PayoutBatchingPeriodCountAggregateOutputType>;
  _max?: Maybe<PayoutBatchingPeriodMaxAggregateOutputType>;
  _min?: Maybe<PayoutBatchingPeriodMinAggregateOutputType>;
};

export type AggregatePermission = {
  __typename?: 'AggregatePermission';
  _count?: Maybe<PermissionCountAggregateOutputType>;
  _max?: Maybe<PermissionMaxAggregateOutputType>;
  _min?: Maybe<PermissionMinAggregateOutputType>;
};

export type AggregatePet = {
  __typename?: 'AggregatePet';
  _avg?: Maybe<PetAvgAggregateOutputType>;
  _count?: Maybe<PetCountAggregateOutputType>;
  _max?: Maybe<PetMaxAggregateOutputType>;
  _min?: Maybe<PetMinAggregateOutputType>;
  _sum?: Maybe<PetSumAggregateOutputType>;
};

export type AggregatePetParentSetting = {
  __typename?: 'AggregatePetParentSetting';
  _count?: Maybe<PetParentSettingCountAggregateOutputType>;
  _max?: Maybe<PetParentSettingMaxAggregateOutputType>;
  _min?: Maybe<PetParentSettingMinAggregateOutputType>;
};

export type AggregatePetPortalSetting = {
  __typename?: 'AggregatePetPortalSetting';
  _count?: Maybe<PetPortalSettingCountAggregateOutputType>;
  _max?: Maybe<PetPortalSettingMaxAggregateOutputType>;
  _min?: Maybe<PetPortalSettingMinAggregateOutputType>;
};

export type AggregatePhoneNumberLookupRequest = {
  __typename?: 'AggregatePhoneNumberLookupRequest';
  _count?: Maybe<PhoneNumberLookupRequestCountAggregateOutputType>;
  _max?: Maybe<PhoneNumberLookupRequestMaxAggregateOutputType>;
  _min?: Maybe<PhoneNumberLookupRequestMinAggregateOutputType>;
};

export type AggregatePhoneNumberLookupResult = {
  __typename?: 'AggregatePhoneNumberLookupResult';
  _avg?: Maybe<PhoneNumberLookupResultAvgAggregateOutputType>;
  _count?: Maybe<PhoneNumberLookupResultCountAggregateOutputType>;
  _max?: Maybe<PhoneNumberLookupResultMaxAggregateOutputType>;
  _min?: Maybe<PhoneNumberLookupResultMinAggregateOutputType>;
  _sum?: Maybe<PhoneNumberLookupResultSumAggregateOutputType>;
};

export type AggregatePimsInvoice = {
  __typename?: 'AggregatePimsInvoice';
  _count?: Maybe<PimsInvoiceCountAggregateOutputType>;
  _max?: Maybe<PimsInvoiceMaxAggregateOutputType>;
  _min?: Maybe<PimsInvoiceMinAggregateOutputType>;
};

export type AggregatePimsInvoiceLineItem = {
  __typename?: 'AggregatePimsInvoiceLineItem';
  _count?: Maybe<PimsInvoiceLineItemCountAggregateOutputType>;
  _max?: Maybe<PimsInvoiceLineItemMaxAggregateOutputType>;
  _min?: Maybe<PimsInvoiceLineItemMinAggregateOutputType>;
};

export type AggregatePimsPaymentType = {
  __typename?: 'AggregatePimsPaymentType';
  _count?: Maybe<PimsPaymentTypeCountAggregateOutputType>;
  _max?: Maybe<PimsPaymentTypeMaxAggregateOutputType>;
  _min?: Maybe<PimsPaymentTypeMinAggregateOutputType>;
};

export type AggregatePimsWritebackLogEntry = {
  __typename?: 'AggregatePimsWritebackLogEntry';
  _avg?: Maybe<PimsWritebackLogEntryAvgAggregateOutputType>;
  _count?: Maybe<PimsWritebackLogEntryCountAggregateOutputType>;
  _max?: Maybe<PimsWritebackLogEntryMaxAggregateOutputType>;
  _min?: Maybe<PimsWritebackLogEntryMinAggregateOutputType>;
  _sum?: Maybe<PimsWritebackLogEntrySumAggregateOutputType>;
};

export type AggregatePrescription = {
  __typename?: 'AggregatePrescription';
  _avg?: Maybe<PrescriptionAvgAggregateOutputType>;
  _count?: Maybe<PrescriptionCountAggregateOutputType>;
  _max?: Maybe<PrescriptionMaxAggregateOutputType>;
  _min?: Maybe<PrescriptionMinAggregateOutputType>;
  _sum?: Maybe<PrescriptionSumAggregateOutputType>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _avg?: Maybe<ProductAvgAggregateOutputType>;
  _count?: Maybe<ProductCountAggregateOutputType>;
  _max?: Maybe<ProductMaxAggregateOutputType>;
  _min?: Maybe<ProductMinAggregateOutputType>;
  _sum?: Maybe<ProductSumAggregateOutputType>;
};

export type AggregatePromoCode = {
  __typename?: 'AggregatePromoCode';
  _avg?: Maybe<PromoCodeAvgAggregateOutputType>;
  _count?: Maybe<PromoCodeCountAggregateOutputType>;
  _max?: Maybe<PromoCodeMaxAggregateOutputType>;
  _min?: Maybe<PromoCodeMinAggregateOutputType>;
  _sum?: Maybe<PromoCodeSumAggregateOutputType>;
};

export type AggregateProviderAppointmentTypeRoomMap = {
  __typename?: 'AggregateProviderAppointmentTypeRoomMap';
  _count?: Maybe<ProviderAppointmentTypeRoomMapCountAggregateOutputType>;
  _max?: Maybe<ProviderAppointmentTypeRoomMapMaxAggregateOutputType>;
  _min?: Maybe<ProviderAppointmentTypeRoomMapMinAggregateOutputType>;
};

export type AggregatePushNotificationLog = {
  __typename?: 'AggregatePushNotificationLog';
  _count?: Maybe<PushNotificationLogCountAggregateOutputType>;
  _max?: Maybe<PushNotificationLogMaxAggregateOutputType>;
  _min?: Maybe<PushNotificationLogMinAggregateOutputType>;
};

export type AggregateReview = {
  __typename?: 'AggregateReview';
  _avg?: Maybe<ReviewAvgAggregateOutputType>;
  _count?: Maybe<ReviewCountAggregateOutputType>;
  _max?: Maybe<ReviewMaxAggregateOutputType>;
  _min?: Maybe<ReviewMinAggregateOutputType>;
  _sum?: Maybe<ReviewSumAggregateOutputType>;
};

export type AggregateReviewTag = {
  __typename?: 'AggregateReviewTag';
  _count?: Maybe<ReviewTagCountAggregateOutputType>;
  _max?: Maybe<ReviewTagMaxAggregateOutputType>;
  _min?: Maybe<ReviewTagMinAggregateOutputType>;
};

export type AggregateRules = {
  __typename?: 'AggregateRules';
  _count?: Maybe<RulesCountAggregateOutputType>;
  _max?: Maybe<RulesMaxAggregateOutputType>;
  _min?: Maybe<RulesMinAggregateOutputType>;
};

export type AggregateSegment = {
  __typename?: 'AggregateSegment';
  _avg?: Maybe<SegmentAvgAggregateOutputType>;
  _count?: Maybe<SegmentCountAggregateOutputType>;
  _max?: Maybe<SegmentMaxAggregateOutputType>;
  _min?: Maybe<SegmentMinAggregateOutputType>;
  _sum?: Maybe<SegmentSumAggregateOutputType>;
};

export type AggregateSentTrupanionExamDayOffer = {
  __typename?: 'AggregateSentTrupanionExamDayOffer';
  _count?: Maybe<SentTrupanionExamDayOfferCountAggregateOutputType>;
  _max?: Maybe<SentTrupanionExamDayOfferMaxAggregateOutputType>;
  _min?: Maybe<SentTrupanionExamDayOfferMinAggregateOutputType>;
};

export type AggregateService = {
  __typename?: 'AggregateService';
  _count?: Maybe<ServiceCountAggregateOutputType>;
  _max?: Maybe<ServiceMaxAggregateOutputType>;
  _min?: Maybe<ServiceMinAggregateOutputType>;
};

export type AggregateServiceCategory = {
  __typename?: 'AggregateServiceCategory';
  _count?: Maybe<ServiceCategoryCountAggregateOutputType>;
  _max?: Maybe<ServiceCategoryMaxAggregateOutputType>;
  _min?: Maybe<ServiceCategoryMinAggregateOutputType>;
};

export type AggregateServiceReminder = {
  __typename?: 'AggregateServiceReminder';
  _count?: Maybe<ServiceReminderCountAggregateOutputType>;
  _max?: Maybe<ServiceReminderMaxAggregateOutputType>;
  _min?: Maybe<ServiceReminderMinAggregateOutputType>;
};

export type AggregateServiceReminderNotification = {
  __typename?: 'AggregateServiceReminderNotification';
  _count?: Maybe<ServiceReminderNotificationCountAggregateOutputType>;
  _max?: Maybe<ServiceReminderNotificationMaxAggregateOutputType>;
  _min?: Maybe<ServiceReminderNotificationMinAggregateOutputType>;
};

export type AggregateServiceReminderTiming = {
  __typename?: 'AggregateServiceReminderTiming';
  _avg?: Maybe<ServiceReminderTimingAvgAggregateOutputType>;
  _count?: Maybe<ServiceReminderTimingCountAggregateOutputType>;
  _max?: Maybe<ServiceReminderTimingMaxAggregateOutputType>;
  _min?: Maybe<ServiceReminderTimingMinAggregateOutputType>;
  _sum?: Maybe<ServiceReminderTimingSumAggregateOutputType>;
};

export type AggregateSex = {
  __typename?: 'AggregateSex';
  _count?: Maybe<SexCountAggregateOutputType>;
  _max?: Maybe<SexMaxAggregateOutputType>;
  _min?: Maybe<SexMinAggregateOutputType>;
};

export type AggregateSmsNotificationStatus = {
  __typename?: 'AggregateSmsNotificationStatus';
  _count?: Maybe<SmsNotificationStatusCountAggregateOutputType>;
  _max?: Maybe<SmsNotificationStatusMaxAggregateOutputType>;
  _min?: Maybe<SmsNotificationStatusMinAggregateOutputType>;
};

export type AggregateSpecies = {
  __typename?: 'AggregateSpecies';
  _count?: Maybe<SpeciesCountAggregateOutputType>;
  _max?: Maybe<SpeciesMaxAggregateOutputType>;
  _min?: Maybe<SpeciesMinAggregateOutputType>;
};

export type AggregateStaffRole = {
  __typename?: 'AggregateStaffRole';
  _count?: Maybe<StaffRoleCountAggregateOutputType>;
  _max?: Maybe<StaffRoleMaxAggregateOutputType>;
  _min?: Maybe<StaffRoleMinAggregateOutputType>;
};

export type AggregateStripeCustomer = {
  __typename?: 'AggregateStripeCustomer';
  _count?: Maybe<StripeCustomerCountAggregateOutputType>;
  _max?: Maybe<StripeCustomerMaxAggregateOutputType>;
  _min?: Maybe<StripeCustomerMinAggregateOutputType>;
};

export type AggregateStripeDispute = {
  __typename?: 'AggregateStripeDispute';
  _avg?: Maybe<StripeDisputeAvgAggregateOutputType>;
  _count?: Maybe<StripeDisputeCountAggregateOutputType>;
  _max?: Maybe<StripeDisputeMaxAggregateOutputType>;
  _min?: Maybe<StripeDisputeMinAggregateOutputType>;
  _sum?: Maybe<StripeDisputeSumAggregateOutputType>;
};

export type AggregateStripeInvoice = {
  __typename?: 'AggregateStripeInvoice';
  _avg?: Maybe<StripeInvoiceAvgAggregateOutputType>;
  _count?: Maybe<StripeInvoiceCountAggregateOutputType>;
  _max?: Maybe<StripeInvoiceMaxAggregateOutputType>;
  _min?: Maybe<StripeInvoiceMinAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceSumAggregateOutputType>;
};

export type AggregateStripeInvoiceItem = {
  __typename?: 'AggregateStripeInvoiceItem';
  _avg?: Maybe<StripeInvoiceItemAvgAggregateOutputType>;
  _count?: Maybe<StripeInvoiceItemCountAggregateOutputType>;
  _max?: Maybe<StripeInvoiceItemMaxAggregateOutputType>;
  _min?: Maybe<StripeInvoiceItemMinAggregateOutputType>;
  _sum?: Maybe<StripeInvoiceItemSumAggregateOutputType>;
};

export type AggregateStripePaymentIntent = {
  __typename?: 'AggregateStripePaymentIntent';
  _avg?: Maybe<StripePaymentIntentAvgAggregateOutputType>;
  _count?: Maybe<StripePaymentIntentCountAggregateOutputType>;
  _max?: Maybe<StripePaymentIntentMaxAggregateOutputType>;
  _min?: Maybe<StripePaymentIntentMinAggregateOutputType>;
  _sum?: Maybe<StripePaymentIntentSumAggregateOutputType>;
};

export type AggregateStripePaymentMethod = {
  __typename?: 'AggregateStripePaymentMethod';
  _avg?: Maybe<StripePaymentMethodAvgAggregateOutputType>;
  _count?: Maybe<StripePaymentMethodCountAggregateOutputType>;
  _max?: Maybe<StripePaymentMethodMaxAggregateOutputType>;
  _min?: Maybe<StripePaymentMethodMinAggregateOutputType>;
  _sum?: Maybe<StripePaymentMethodSumAggregateOutputType>;
};

export type AggregateStripePayout = {
  __typename?: 'AggregateStripePayout';
  _avg?: Maybe<StripePayoutAvgAggregateOutputType>;
  _count?: Maybe<StripePayoutCountAggregateOutputType>;
  _max?: Maybe<StripePayoutMaxAggregateOutputType>;
  _min?: Maybe<StripePayoutMinAggregateOutputType>;
  _sum?: Maybe<StripePayoutSumAggregateOutputType>;
};

export type AggregateStripePayoutAccount = {
  __typename?: 'AggregateStripePayoutAccount';
  _count?: Maybe<StripePayoutAccountCountAggregateOutputType>;
  _max?: Maybe<StripePayoutAccountMaxAggregateOutputType>;
  _min?: Maybe<StripePayoutAccountMinAggregateOutputType>;
};

export type AggregateStripeReceipt = {
  __typename?: 'AggregateStripeReceipt';
  _count?: Maybe<StripeReceiptCountAggregateOutputType>;
  _max?: Maybe<StripeReceiptMaxAggregateOutputType>;
  _min?: Maybe<StripeReceiptMinAggregateOutputType>;
};

export type AggregateStripeReceiptHistory = {
  __typename?: 'AggregateStripeReceiptHistory';
  _count?: Maybe<StripeReceiptHistoryCountAggregateOutputType>;
  _max?: Maybe<StripeReceiptHistoryMaxAggregateOutputType>;
  _min?: Maybe<StripeReceiptHistoryMinAggregateOutputType>;
};

export type AggregateStripeRefund = {
  __typename?: 'AggregateStripeRefund';
  _avg?: Maybe<StripeRefundAvgAggregateOutputType>;
  _count?: Maybe<StripeRefundCountAggregateOutputType>;
  _max?: Maybe<StripeRefundMaxAggregateOutputType>;
  _min?: Maybe<StripeRefundMinAggregateOutputType>;
  _sum?: Maybe<StripeRefundSumAggregateOutputType>;
};

export type AggregateStripeTerminal = {
  __typename?: 'AggregateStripeTerminal';
  _count?: Maybe<StripeTerminalCountAggregateOutputType>;
  _max?: Maybe<StripeTerminalMaxAggregateOutputType>;
  _min?: Maybe<StripeTerminalMinAggregateOutputType>;
};

export type AggregateStripeTerminalHealthcheck = {
  __typename?: 'AggregateStripeTerminalHealthcheck';
  _count?: Maybe<StripeTerminalHealthcheckCountAggregateOutputType>;
  _max?: Maybe<StripeTerminalHealthcheckMaxAggregateOutputType>;
  _min?: Maybe<StripeTerminalHealthcheckMinAggregateOutputType>;
};

export type AggregateStripeTerminalLocation = {
  __typename?: 'AggregateStripeTerminalLocation';
  _count?: Maybe<StripeTerminalLocationCountAggregateOutputType>;
  _max?: Maybe<StripeTerminalLocationMaxAggregateOutputType>;
  _min?: Maybe<StripeTerminalLocationMinAggregateOutputType>;
};

export type AggregateSurveyAppointmentTypeSetting = {
  __typename?: 'AggregateSurveyAppointmentTypeSetting';
  _avg?: Maybe<SurveyAppointmentTypeSettingAvgAggregateOutputType>;
  _count?: Maybe<SurveyAppointmentTypeSettingCountAggregateOutputType>;
  _max?: Maybe<SurveyAppointmentTypeSettingMaxAggregateOutputType>;
  _min?: Maybe<SurveyAppointmentTypeSettingMinAggregateOutputType>;
  _sum?: Maybe<SurveyAppointmentTypeSettingSumAggregateOutputType>;
};

export type AggregateSurveyResult = {
  __typename?: 'AggregateSurveyResult';
  _avg?: Maybe<SurveyResultAvgAggregateOutputType>;
  _count?: Maybe<SurveyResultCountAggregateOutputType>;
  _max?: Maybe<SurveyResultMaxAggregateOutputType>;
  _min?: Maybe<SurveyResultMinAggregateOutputType>;
  _sum?: Maybe<SurveyResultSumAggregateOutputType>;
};

export type AggregateSurveySetting = {
  __typename?: 'AggregateSurveySetting';
  _avg?: Maybe<SurveySettingAvgAggregateOutputType>;
  _count?: Maybe<SurveySettingCountAggregateOutputType>;
  _max?: Maybe<SurveySettingMaxAggregateOutputType>;
  _min?: Maybe<SurveySettingMinAggregateOutputType>;
  _sum?: Maybe<SurveySettingSumAggregateOutputType>;
};

export type AggregateSyncVetIntegration = {
  __typename?: 'AggregateSyncVetIntegration';
  _count?: Maybe<SyncVetIntegrationCountAggregateOutputType>;
  _max?: Maybe<SyncVetIntegrationMaxAggregateOutputType>;
  _min?: Maybe<SyncVetIntegrationMinAggregateOutputType>;
};

export type AggregateTinyUrl = {
  __typename?: 'AggregateTinyUrl';
  _count?: Maybe<TinyUrlCountAggregateOutputType>;
  _max?: Maybe<TinyUrlMaxAggregateOutputType>;
  _min?: Maybe<TinyUrlMinAggregateOutputType>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _avg?: Maybe<TransactionAvgAggregateOutputType>;
  _count?: Maybe<TransactionCountAggregateOutputType>;
  _max?: Maybe<TransactionMaxAggregateOutputType>;
  _min?: Maybe<TransactionMinAggregateOutputType>;
  _sum?: Maybe<TransactionSumAggregateOutputType>;
};

export type AggregateTrupanionCertificateAvailability = {
  __typename?: 'AggregateTrupanionCertificateAvailability';
  _avg?: Maybe<TrupanionCertificateAvailabilityAvgAggregateOutputType>;
  _count?: Maybe<TrupanionCertificateAvailabilityCountAggregateOutputType>;
  _max?: Maybe<TrupanionCertificateAvailabilityMaxAggregateOutputType>;
  _min?: Maybe<TrupanionCertificateAvailabilityMinAggregateOutputType>;
  _sum?: Maybe<TrupanionCertificateAvailabilitySumAggregateOutputType>;
};

export type AggregateTrupanionIntegratedAppointmentType = {
  __typename?: 'AggregateTrupanionIntegratedAppointmentType';
  _count?: Maybe<TrupanionIntegratedAppointmentTypeCountAggregateOutputType>;
  _max?: Maybe<TrupanionIntegratedAppointmentTypeMaxAggregateOutputType>;
  _min?: Maybe<TrupanionIntegratedAppointmentTypeMinAggregateOutputType>;
};

export type AggregateTrupanionIntegration = {
  __typename?: 'AggregateTrupanionIntegration';
  _count?: Maybe<TrupanionIntegrationCountAggregateOutputType>;
  _max?: Maybe<TrupanionIntegrationMaxAggregateOutputType>;
  _min?: Maybe<TrupanionIntegrationMinAggregateOutputType>;
};

export type AggregateTwilioCallRecording = {
  __typename?: 'AggregateTwilioCallRecording';
  _avg?: Maybe<TwilioCallRecordingAvgAggregateOutputType>;
  _count?: Maybe<TwilioCallRecordingCountAggregateOutputType>;
  _max?: Maybe<TwilioCallRecordingMaxAggregateOutputType>;
  _min?: Maybe<TwilioCallRecordingMinAggregateOutputType>;
  _sum?: Maybe<TwilioCallRecordingSumAggregateOutputType>;
};

export type AggregateTwilioConfiguration = {
  __typename?: 'AggregateTwilioConfiguration';
  _count?: Maybe<TwilioConfigurationCountAggregateOutputType>;
  _max?: Maybe<TwilioConfigurationMaxAggregateOutputType>;
  _min?: Maybe<TwilioConfigurationMinAggregateOutputType>;
};

export type AggregateTwilioVerification = {
  __typename?: 'AggregateTwilioVerification';
  _count?: Maybe<TwilioVerificationCountAggregateOutputType>;
  _max?: Maybe<TwilioVerificationMaxAggregateOutputType>;
  _min?: Maybe<TwilioVerificationMinAggregateOutputType>;
};

export type AggregateUnlayerSetting = {
  __typename?: 'AggregateUnlayerSetting';
  _avg?: Maybe<UnlayerSettingAvgAggregateOutputType>;
  _count?: Maybe<UnlayerSettingCountAggregateOutputType>;
  _max?: Maybe<UnlayerSettingMaxAggregateOutputType>;
  _min?: Maybe<UnlayerSettingMinAggregateOutputType>;
  _sum?: Maybe<UnlayerSettingSumAggregateOutputType>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _count?: Maybe<UserCountAggregateOutputType>;
  _max?: Maybe<UserMaxAggregateOutputType>;
  _min?: Maybe<UserMinAggregateOutputType>;
};

export type AggregateUserAppointmentGrouping = {
  __typename?: 'AggregateUserAppointmentGrouping';
  _count?: Maybe<UserAppointmentGroupingCountAggregateOutputType>;
  _max?: Maybe<UserAppointmentGroupingMaxAggregateOutputType>;
  _min?: Maybe<UserAppointmentGroupingMinAggregateOutputType>;
};

export type AggregateUserBoardFilter = {
  __typename?: 'AggregateUserBoardFilter';
  _count?: Maybe<UserBoardFilterCountAggregateOutputType>;
  _max?: Maybe<UserBoardFilterMaxAggregateOutputType>;
  _min?: Maybe<UserBoardFilterMinAggregateOutputType>;
};

export type AggregateUserChannelFilterSelection = {
  __typename?: 'AggregateUserChannelFilterSelection';
  _count?: Maybe<UserChannelFilterSelectionCountAggregateOutputType>;
  _max?: Maybe<UserChannelFilterSelectionMaxAggregateOutputType>;
  _min?: Maybe<UserChannelFilterSelectionMinAggregateOutputType>;
};

export type AggregateUserImpersonation = {
  __typename?: 'AggregateUserImpersonation';
  _count?: Maybe<UserImpersonationCountAggregateOutputType>;
  _max?: Maybe<UserImpersonationMaxAggregateOutputType>;
  _min?: Maybe<UserImpersonationMinAggregateOutputType>;
};

export type AggregateUserNotificationSetting = {
  __typename?: 'AggregateUserNotificationSetting';
  _count?: Maybe<UserNotificationSettingCountAggregateOutputType>;
  _max?: Maybe<UserNotificationSettingMaxAggregateOutputType>;
  _min?: Maybe<UserNotificationSettingMinAggregateOutputType>;
};

export type AggregateUserSetting = {
  __typename?: 'AggregateUserSetting';
  _count?: Maybe<UserSettingCountAggregateOutputType>;
  _max?: Maybe<UserSettingMaxAggregateOutputType>;
  _min?: Maybe<UserSettingMinAggregateOutputType>;
};

export type AggregateVaccination = {
  __typename?: 'AggregateVaccination';
  _count?: Maybe<VaccinationCountAggregateOutputType>;
  _max?: Maybe<VaccinationMaxAggregateOutputType>;
  _min?: Maybe<VaccinationMinAggregateOutputType>;
};

export type AggregateVetAvailability = {
  __typename?: 'AggregateVetAvailability';
  _count?: Maybe<VetAvailabilityCountAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMaxAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMinAggregateOutputType>;
};

export type AggregateVetAvailabilityClinicRoomMap = {
  __typename?: 'AggregateVetAvailabilityClinicRoomMap';
  _count?: Maybe<VetAvailabilityClinicRoomMapCountAggregateOutputType>;
  _max?: Maybe<VetAvailabilityClinicRoomMapMaxAggregateOutputType>;
  _min?: Maybe<VetAvailabilityClinicRoomMapMinAggregateOutputType>;
};

export type AggregateVetAvailabilityMap = {
  __typename?: 'AggregateVetAvailabilityMap';
  _count?: Maybe<VetAvailabilityMapCountAggregateOutputType>;
  _max?: Maybe<VetAvailabilityMapMaxAggregateOutputType>;
  _min?: Maybe<VetAvailabilityMapMinAggregateOutputType>;
};

export type AggregateVetDataIntegration = {
  __typename?: 'AggregateVetDataIntegration';
  _count?: Maybe<VetDataIntegrationCountAggregateOutputType>;
  _max?: Maybe<VetDataIntegrationMaxAggregateOutputType>;
  _min?: Maybe<VetDataIntegrationMinAggregateOutputType>;
};

export type AggregateVetInfo = {
  __typename?: 'AggregateVetInfo';
  _count?: Maybe<VetInfoCountAggregateOutputType>;
  _max?: Maybe<VetInfoMaxAggregateOutputType>;
  _min?: Maybe<VetInfoMinAggregateOutputType>;
};

export type AggregateVetLicense = {
  __typename?: 'AggregateVetLicense';
  _count?: Maybe<VetLicenseCountAggregateOutputType>;
  _max?: Maybe<VetLicenseMaxAggregateOutputType>;
  _min?: Maybe<VetLicenseMinAggregateOutputType>;
};

export type AggregateVetsourceCodeTag = {
  __typename?: 'AggregateVetsourceCodeTag';
  _avg?: Maybe<VetsourceCodeTagAvgAggregateOutputType>;
  _count?: Maybe<VetsourceCodeTagCountAggregateOutputType>;
  _max?: Maybe<VetsourceCodeTagMaxAggregateOutputType>;
  _min?: Maybe<VetsourceCodeTagMinAggregateOutputType>;
  _sum?: Maybe<VetsourceCodeTagSumAggregateOutputType>;
};

export type AggregateVetsourceInstallation = {
  __typename?: 'AggregateVetsourceInstallation';
  _count?: Maybe<VetsourceInstallationCountAggregateOutputType>;
  _max?: Maybe<VetsourceInstallationMaxAggregateOutputType>;
  _min?: Maybe<VetsourceInstallationMinAggregateOutputType>;
};

export type AggregateVetsourceRevenueCategory = {
  __typename?: 'AggregateVetsourceRevenueCategory';
  _avg?: Maybe<VetsourceRevenueCategoryAvgAggregateOutputType>;
  _count?: Maybe<VetsourceRevenueCategoryCountAggregateOutputType>;
  _max?: Maybe<VetsourceRevenueCategoryMaxAggregateOutputType>;
  _min?: Maybe<VetsourceRevenueCategoryMinAggregateOutputType>;
  _sum?: Maybe<VetsourceRevenueCategorySumAggregateOutputType>;
};

export type AggregateVetterIntegration = {
  __typename?: 'AggregateVetterIntegration';
  _count?: Maybe<VetterIntegrationCountAggregateOutputType>;
  _max?: Maybe<VetterIntegrationMaxAggregateOutputType>;
  _min?: Maybe<VetterIntegrationMinAggregateOutputType>;
};

export type AggregateWorkflowEventAction = {
  __typename?: 'AggregateWorkflowEventAction';
  _avg?: Maybe<WorkflowEventActionAvgAggregateOutputType>;
  _count?: Maybe<WorkflowEventActionCountAggregateOutputType>;
  _max?: Maybe<WorkflowEventActionMaxAggregateOutputType>;
  _min?: Maybe<WorkflowEventActionMinAggregateOutputType>;
  _sum?: Maybe<WorkflowEventActionSumAggregateOutputType>;
};

export type AggregateWorkflowEventSetting = {
  __typename?: 'AggregateWorkflowEventSetting';
  _avg?: Maybe<WorkflowEventSettingAvgAggregateOutputType>;
  _count?: Maybe<WorkflowEventSettingCountAggregateOutputType>;
  _max?: Maybe<WorkflowEventSettingMaxAggregateOutputType>;
  _min?: Maybe<WorkflowEventSettingMinAggregateOutputType>;
  _sum?: Maybe<WorkflowEventSettingSumAggregateOutputType>;
};

export type AggregateWorkflowTrigger = {
  __typename?: 'AggregateWorkflowTrigger';
  _count?: Maybe<WorkflowTriggerCountAggregateOutputType>;
  _max?: Maybe<WorkflowTriggerMaxAggregateOutputType>;
  _min?: Maybe<WorkflowTriggerMinAggregateOutputType>;
};

export enum AiConverseRole {
  Assistant = 'assistant',
  User = 'user'
}

export enum AmericanKennelClubBreedGroup {
  FoundationStockService = 'FOUNDATION_STOCK_SERVICE',
  HerdingGroup = 'HERDING_GROUP',
  HoundGroup = 'HOUND_GROUP',
  MiscellaneousClass = 'MISCELLANEOUS_CLASS',
  NonSportingGroup = 'NON_SPORTING_GROUP',
  SportingGroup = 'SPORTING_GROUP',
  TerrierGroup = 'TERRIER_GROUP',
  WorkingGroup = 'WORKING_GROUP'
}

export enum AmericanKennelClubBreedTemperament {
  Active = 'ACTIVE',
  Adaptable = 'ADAPTABLE',
  Adventurous = 'ADVENTUROUS',
  Affectionate = 'AFFECTIONATE',
  Agile = 'AGILE',
  Alert = 'ALERT',
  AlertAndIntelligent = 'ALERT_AND_INTELLIGENT',
  Amiable = 'AMIABLE',
  Amusing = 'AMUSING',
  Aristocratic = 'ARISTOCRATIC',
  Athletic = 'ATHLETIC',
  Attentive = 'ATTENTIVE',
  Boisterous = 'BOISTEROUS',
  Bold = 'BOLD',
  Bouncy = 'BOUNCY',
  Brave = 'BRAVE',
  Bright = 'BRIGHT',
  Busy = 'BUSY',
  Calm = 'CALM',
  Canny = 'CANNY',
  Charismatic = 'CHARISMATIC',
  Charming = 'CHARMING',
  Cheerful = 'CHEERFUL',
  Clever = 'CLEVER',
  Comical = 'COMICAL',
  Confident = 'CONFIDENT',
  ConfidentGuardian = 'CONFIDENT_GUARDIAN',
  Courageous = 'COURAGEOUS',
  Courteous = 'COURTEOUS',
  Curious = 'CURIOUS',
  Dashing = 'DASHING',
  DeeplyAffectionate = 'DEEPLY_AFFECTIONATE',
  DeeplyDevoted = 'DEEPLY_DEVOTED',
  Dependable = 'DEPENDABLE',
  Determined = 'DETERMINED',
  Devoted = 'DEVOTED',
  Dignified = 'DIGNIFIED',
  Docile = 'DOCILE',
  Eager = 'EAGER',
  EagerToPlease = 'EAGER_TO_PLEASE',
  EasyGoing = 'EASY_GOING',
  Energetic = 'ENERGETIC',
  Entertaining = 'ENTERTAINING',
  Enthusiastic = 'ENTHUSIASTIC',
  EvenTempered = 'EVEN_TEMPERED',
  Exuberant = 'EXUBERANT',
  Faithful = 'FAITHFUL',
  FamilyOriented = 'FAMILY_ORIENTED',
  FamouslyFunny = 'FAMOUSLY_FUNNY',
  Fearless = 'FEARLESS',
  Friendly = 'FRIENDLY',
  Frollicking = 'FROLLICKING',
  Funny = 'FUNNY',
  FunLoving = 'FUN_LOVING',
  Gentle = 'GENTLE',
  Gentlemanly = 'GENTLEMANLY',
  GoodHumored = 'GOOD_HUMORED',
  GoodNatured = 'GOOD_NATURED',
  GoodTempered = 'GOOD_TEMPERED',
  Graceful = 'GRACEFUL',
  Gregarious = 'GREGARIOUS',
  Happy = 'HAPPY',
  HappyGoLucky = 'HAPPY_GO_LUCKY',
  Hardworking = 'HARDWORKING',
  HomeLoving = 'HOME_LOVING',
  Humble = 'HUMBLE',
  Independent = 'INDEPENDENT',
  IndependentMinded = 'INDEPENDENT_MINDED',
  Inquisitive = 'INQUISITIVE',
  Intelligent = 'INTELLIGENT',
  Keen = 'KEEN',
  KeenlyAlert = 'KEENLY_ALERT',
  KeenlyObservant = 'KEENLY_OBSERVANT',
  Kind = 'KIND',
  Lively = 'LIVELY',
  Lovable = 'LOVABLE',
  Loving = 'LOVING',
  LowKey = 'LOW_KEY',
  Loyal = 'LOYAL',
  Majestic = 'MAJESTIC',
  Mellow = 'MELLOW',
  Merry = 'MERRY',
  Mischievous = 'MISCHIEVOUS',
  Noble = 'NOBLE',
  Obedient = 'OBEDIENT',
  Observant = 'OBSERVANT',
  Optimistic = 'OPTIMISTIC',
  Outgoing = 'OUTGOING',
  Patient = 'PATIENT',
  PeopleOriented = 'PEOPLE_ORIENTED',
  Peppy = 'PEPPY',
  Perceptive = 'PERCEPTIVE',
  Perky = 'PERKY',
  Playful = 'PLAYFUL',
  PlayfulButAlsoWorkOrientedVeryActiveAndUpbeat = 'PLAYFUL_BUT_ALSO_WORK_ORIENTED_VERY_ACTIVE_AND_UPBEAT',
  Pleasant = 'PLEASANT',
  Plucky = 'PLUCKY',
  Poised = 'POISED',
  Polite = 'POLITE',
  Positive = 'POSITIVE',
  Powerful = 'POWERFUL',
  ProfoundlyLoyal = 'PROFOUNDLY_LOYAL',
  Proud = 'PROUD',
  Quick = 'QUICK',
  ReadyToWork = 'READY_TO_WORK',
  Regal = 'REGAL',
  RegallyDignified = 'REGALLY_DIGNIFIED',
  RegalInManner = 'REGAL_IN_MANNER',
  Reserved = 'RESERVED',
  ReservedWithStrangers = 'RESERVED_WITH_STRANGERS',
  Responsive = 'RESPONSIVE',
  Sassy = 'SASSY',
  SelfConfident = 'SELF_CONFIDENT',
  SenseOfHumor = 'SENSE_OF_HUMOR',
  Sensitive = 'SENSITIVE',
  SeriousMinded = 'SERIOUS_MINDED',
  Smart = 'SMART',
  Sociable = 'SOCIABLE',
  Spirited = 'SPIRITED',
  Sprightly = 'SPRIGHTLY',
  Spunky = 'SPUNKY',
  Strong = 'STRONG',
  StrongWilled = 'STRONG_WILLED',
  Sweet = 'SWEET',
  SweetNatured = 'SWEET_NATURED',
  SweetTempered = 'SWEET_TEMPERED',
  Tenacious = 'TENACIOUS',
  Tenderhearted = 'TENDERHEARTED',
  Tomboyish = 'TOMBOYISH',
  Trainable = 'TRAINABLE',
  Undemanding = 'UNDEMANDING',
  Upbeat = 'UPBEAT',
  Versatile = 'VERSATILE',
  VerySmart = 'VERY_SMART',
  Vigilant = 'VIGILANT',
  Vivacious = 'VIVACIOUS',
  Watchful = 'WATCHFUL',
  WickedlySmart = 'WICKEDLY_SMART',
  WillingToPlease = 'WILLING_TO_PLEASE',
  WorkOriented = 'WORK_ORIENTED'
}

export enum AmericanKennelClubDemeanorCategory {
  AlertResponsive = 'ALERT_RESPONSIVE',
  AloofWary = 'ALOOF_WARY',
  Friendly = 'FRIENDLY',
  Outgoing = 'OUTGOING',
  ReservedWithStrangers = 'RESERVED_WITH_STRANGERS'
}

export enum AmericanKennelClubEnergyLevelCategory {
  Calm = 'CALM',
  CouchPotato = 'COUCH_POTATO',
  Energetic = 'ENERGETIC',
  NeedsLotsOfActivity = 'NEEDS_LOTS_OF_ACTIVITY',
  RegularExercise = 'REGULAR_EXERCISE'
}

export enum AmericanKennelClubGroomingFrequencyCategory {
  DailyBrushing = 'DAILY_BRUSHING',
  OccasionalBathBrush = 'OCCASIONAL_BATH_BRUSH',
  SpecialtyProfessional = 'SPECIALTY_PROFESSIONAL',
  TwoToThreeTimesPerWeekBrushing = 'TWO_TO_THREE_TIMES_PER_WEEK_BRUSHING',
  WeeklyBrushing = 'WEEKLY_BRUSHING'
}

export enum AmericanKennelClubSheddingCategory {
  Frequent = 'FREQUENT',
  Infrequent = 'INFREQUENT',
  Occasional = 'OCCASIONAL',
  Regularly = 'REGULARLY',
  Seasonal = 'SEASONAL'
}

export enum AmericanKennelClubTrainabilityCategory {
  Agreeable = 'AGREEABLE',
  EagerToPlease = 'EAGER_TO_PLEASE',
  EasyTraining = 'EASY_TRAINING',
  Independent = 'INDEPENDENT',
  MayBeStubborn = 'MAY_BE_STUBBORN'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integrationType?: Maybe<IntegrationType>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ApiKeyCountAggregateOutputType = {
  __typename?: 'ApiKeyCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationType: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ApiKeyCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ApiKeyCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyMaxAggregateOutputType = {
  __typename?: 'ApiKeyMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ApiKeyMinAggregateOutputType = {
  __typename?: 'ApiKeyMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ApiKeyOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type ApiKeyOrderByRelevanceInput = {
  fields: Array<ApiKeyOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ApiKeyOrderByWithAggregationInput = {
  _count?: Maybe<ApiKeyCountOrderByAggregateInput>;
  _max?: Maybe<ApiKeyMaxOrderByAggregateInput>;
  _min?: Maybe<ApiKeyMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ApiKeyOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ApiKeyOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ApiKeyScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationType = 'integrationType',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ApiKeyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ApiKeyScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ApiKeyUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationType?: Maybe<IntegrationType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyWhereInput = {
  AND?: Maybe<Array<ApiKeyWhereInput>>;
  NOT?: Maybe<Array<ApiKeyWhereInput>>;
  OR?: Maybe<Array<ApiKeyWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationType?: Maybe<EnumIntegrationTypeNullableFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ApiKeyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  _count: AppointmentCountOutputType;
  appointmentType?: Maybe<AppointmentType>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns: Array<AutomationRun>;
  channel?: Maybe<Channel>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents: Array<ClinicPetParent>;
  /** Deprecated: use pimsExportConsultationId for Conversation Exports */
  consultation?: Maybe<Consultation>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequest>;
  durationInMinutes: Scalars['Int'];
  formSubmissions: Array<FormSubmission>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  /** Replacement for consultation_id for Conversation Exports */
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs: Array<PushNotificationLog>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoom>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status: AppointmentStatus;
  surveyResults: Array<SurveyResult>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workflow?: Maybe<WorkflowType>;
  workflowTriggers: Array<WorkflowTrigger>;
  workflows: Array<WorkflowEventSetting>;
};


export type AppointmentAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type AppointmentChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type AppointmentClinicEmployeesArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type AppointmentClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type AppointmentFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type AppointmentMassTextAlertEntryAppointmentsArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type AppointmentPushNotificationLogsArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type AppointmentSurveyResultsArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type AppointmentWorkflowTriggersArgs = {
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowTriggerWhereInput>;
};


export type AppointmentWorkflowsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type AppointmentAppointmentPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type AppointmentAvailability = {
  __typename?: 'AppointmentAvailability';
  availabilities: Array<Scalars['DateTime']>;
  clinic?: Maybe<Clinic>;
  clinicEmployee?: Maybe<ClinicEmployee>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoom>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AppointmentAvailabilityAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type AppointmentAvailabilityCountAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityCountAggregateOutputType';
  _all: Scalars['Int'];
  availabilities: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isProvider: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  roomId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AppointmentAvailabilityCountOrderByAggregateInput = {
  availabilities?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityCreateInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyClinicEmployeeInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyClinicInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyClinicInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyIntegrationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateManyRoomInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateManyRoomInputEnvelope = {
  data: Array<AppointmentAvailabilityCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
};

export type AppointmentAvailabilityCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
};

export type AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutClinicInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityCreateOrConnectWithoutRoomInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutRoomInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityCreateWithoutClinicEmployeeInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateWithoutClinicInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateWithoutIntegrationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateWithoutRoomInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  clinicEmployee?: Maybe<ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityCreateavailabilitiesInput = {
  set: Array<Scalars['DateTime']>;
};

export type AppointmentAvailabilityListRelationFilter = {
  every?: Maybe<AppointmentAvailabilityWhereInput>;
  none?: Maybe<AppointmentAvailabilityWhereInput>;
  some?: Maybe<AppointmentAvailabilityWhereInput>;
};

export type AppointmentAvailabilityMaxAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityMaxAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityMaxOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityMinAggregateOutputType = {
  __typename?: 'AppointmentAvailabilityMinAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityMinOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentAvailabilityOrderByRelevanceFieldEnum {
  ClinicEmployeeId = 'clinicEmployeeId',
  ClinicId = 'clinicId',
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  RoomId = 'roomId'
}

export type AppointmentAvailabilityOrderByRelevanceInput = {
  fields: Array<AppointmentAvailabilityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AppointmentAvailabilityOrderByWithAggregationInput = {
  _count?: Maybe<AppointmentAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<AppointmentAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentAvailabilityMinOrderByAggregateInput>;
  availabilities?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<AppointmentAvailabilityOrderByRelevanceInput>;
  availabilities?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isProvider?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum AppointmentAvailabilityScalarFieldEnum {
  Availabilities = 'availabilities',
  ClinicEmployeeId = 'clinicEmployeeId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsDeleted = 'isDeleted',
  IsProvider = 'isProvider',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  RoomId = 'roomId',
  UpdatedAt = 'updatedAt'
}

export type AppointmentAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  clinicEmployeeId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isProvider?: Maybe<BoolNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AppointmentAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityScalarWhereWithAggregatesInput>>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  clinicEmployeeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isProvider?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  roomId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type AppointmentAvailabilityUncheckedCreateInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
};

export type AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutClinicInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedCreateWithoutRoomInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilityInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutAvailabilitiesInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutClinicInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUncheckedUpdateWithoutRoomInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateManyMutationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput = {
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput;
  where: AppointmentAvailabilityScalarWhereInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput = {
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilitiesInput;
  where: AppointmentAvailabilityScalarWhereInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput = {
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAvailabilitiesInput;
  where: AppointmentAvailabilityScalarWhereInput;
};

export type AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput = {
  data: AppointmentAvailabilityUncheckedUpdateManyWithoutAppointmentAvailabilityInput;
  where: AppointmentAvailabilityScalarWhereInput;
};

export type AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentAvailabilityUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentAvailabilityCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentAvailabilityCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentAvailabilityCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentAvailabilityUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  data: AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutClinicInput = {
  data: AppointmentAvailabilityUncheckedUpdateWithoutClinicInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpdateWithWhereUniqueWithoutRoomInput = {
  data: AppointmentAvailabilityUncheckedUpdateWithoutRoomInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpdateWithoutClinicEmployeeInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateWithoutClinicInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateWithoutIntegrationInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateWithoutRoomInput = {
  availabilities?: Maybe<Array<Scalars['DateTime']>>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentAvailabilityUpdateavailabilitiesInput = {
  push?: Maybe<Array<Scalars['DateTime']>>;
  set?: Maybe<Array<Scalars['DateTime']>>;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicEmployeeInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutClinicEmployeeInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutClinicInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutClinicInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutClinicInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutIntegrationInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityUpsertWithWhereUniqueWithoutRoomInput = {
  create: AppointmentAvailabilityUncheckedCreateWithoutRoomInput;
  update: AppointmentAvailabilityUncheckedUpdateWithoutRoomInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};

export type AppointmentAvailabilityWhereInput = {
  AND?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  NOT?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  OR?: Maybe<Array<AppointmentAvailabilityWhereInput>>;
  availabilities?: Maybe<DateTimeNullableListFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  clinicEmployeeId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isProvider?: Maybe<BoolNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  room?: Maybe<ClinicRoomWhereInput>;
  roomId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AppointmentAvailabilityWhereUniqueInput = {
  availabilityPimsIdAndIntegrationId?: Maybe<AppointmentAvailabilityAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AppointmentAvgAggregateOutputType = {
  __typename?: 'AppointmentAvgAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

export type AppointmentAvgOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentCountAggregateOutputType = {
  __typename?: 'AppointmentCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  consultationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  durationInMinutes: Scalars['Int'];
  hash: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  pimsExportConsultationId: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsStatus: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  roomId: Scalars['Int'];
  startAt: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflow: Scalars['Int'];
};

export type AppointmentCountOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
};

export type AppointmentCountOutputType = {
  __typename?: 'AppointmentCountOutputType';
  automationRuns: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  massTextAlertEntryAppointments: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
  surveyResults: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type AppointmentCreateInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateManyAppointmentTypeInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyAppointmentTypeInputEnvelope = {
  data: Array<AppointmentCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyChannelInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyChannelInputEnvelope = {
  data: Array<AppointmentCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyClinicInputEnvelope = {
  data: Array<AppointmentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyClinicPetInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyClinicPetInputEnvelope = {
  data: Array<AppointmentCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyConsultationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyConsultationInputEnvelope = {
  data: Array<AppointmentCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyIntegrationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyRoomInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentCreateManyRoomInputEnvelope = {
  data: Array<AppointmentCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutClinicEmployeesInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
};

export type AppointmentCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutClinicPetParentsInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
};

export type AppointmentCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
};

export type AppointmentCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
};

export type AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput>;
};

export type AppointmentCreateNestedOneWithoutFormSubmissionsInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
};

export type AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
};

export type AppointmentCreateNestedOneWithoutPushNotificationLogsInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutPushNotificationLogsInput>;
};

export type AppointmentCreateNestedOneWithoutSurveyResultsInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutSurveyResultsInput>;
};

export type AppointmentCreateNestedOneWithoutWorkflowTriggersInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
};

export type AppointmentCreateOrConnectWithoutAppointmentTypeInput = {
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutAutomationRunsInput = {
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutChannelInput = {
  create: AppointmentUncheckedCreateWithoutChannelInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutClinicEmployeesInput = {
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutClinicInput = {
  create: AppointmentUncheckedCreateWithoutClinicInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetInput = {
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutClinicPetParentsInput = {
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutConsultationInput = {
  create: AppointmentUncheckedCreateWithoutConsultationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput = {
  create: AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutFormSubmissionsInput = {
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutIntegrationInput = {
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput = {
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutPushNotificationLogsInput = {
  create: AppointmentUncheckedCreateWithoutPushNotificationLogsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutRoomInput = {
  create: AppointmentUncheckedCreateWithoutRoomInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutSurveyResultsInput = {
  create: AppointmentUncheckedCreateWithoutSurveyResultsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowTriggersInput = {
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutWorkflowsInput = {
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateWithoutAppointmentTypeInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutAutomationRunsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutChannelAutomationStatusesInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutChannelInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutClinicEmployeesInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutClinicInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutClinicPetInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutClinicPetParentsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutConsultationInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutDirectBookingWidgetRequestInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutFormSubmissionsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutIntegrationInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutMassTextAlertEntryAppointmentsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutPushNotificationLogsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutRoomInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutSurveyResultsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutWorkflowTriggersInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentCreateWithoutWorkflowsInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutAppointmentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutAppointmentInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutAppointmentsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppointmentsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutAppointmentsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAppointmentsInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutAppointmentsInput>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomCreateNestedOneWithoutAppointmentsInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutAppointmentInput>;
};

export enum AppointmentDateRangeType {
  Custom = 'Custom',
  NextWeek = 'Next_Week',
  ThisMonth = 'This_Month',
  ThisWeek = 'This_Week',
  Today = 'Today',
  Tomorrow = 'Tomorrow'
}

export type AppointmentFilter = {
  __typename?: 'AppointmentFilter';
  currentDay: Scalars['Date'];
  daysAheadInterval: Scalars['Int'];
  defaultAppointmentDurationInMinutes: Scalars['Int'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type AppointmentListRelationFilter = {
  every?: Maybe<AppointmentWhereInput>;
  none?: Maybe<AppointmentWhereInput>;
  some?: Maybe<AppointmentWhereInput>;
};

export type AppointmentMaxAggregateOutputType = {
  __typename?: 'AppointmentMaxAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentMaxOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
};

export type AppointmentMinAggregateOutputType = {
  __typename?: 'AppointmentMinAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentMinOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
};

export type AppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ConsultationId = 'consultationId',
  Description = 'description',
  Hash = 'hash',
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsExportConsultationId = 'pimsExportConsultationId',
  PimsId = 'pimsId',
  PimsStatus = 'pimsStatus',
  RoomId = 'roomId',
  Title = 'title'
}

export type AppointmentOrderByRelevanceInput = {
  fields: Array<AppointmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AppointmentOrderByWithAggregationInput = {
  _avg?: Maybe<AppointmentAvgOrderByAggregateInput>;
  _count?: Maybe<AppointmentCountOrderByAggregateInput>;
  _max?: Maybe<AppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentSumOrderByAggregateInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  durationInMinutes?: Maybe<SortOrder>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
};

export type AppointmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<AppointmentOrderByRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  durationInMinutes?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  pimsExportConsultationId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsStatus?: Maybe<SortOrder>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  roomId?: Maybe<SortOrder>;
  startAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<SortOrder>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type AppointmentRelationFilter = {
  is?: Maybe<AppointmentWhereInput>;
  isNot?: Maybe<AppointmentWhereInput>;
};

export enum AppointmentScalarFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ConsultationId = 'consultationId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DurationInMinutes = 'durationInMinutes',
  Hash = 'hash',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  PimsExportConsultationId = 'pimsExportConsultationId',
  PimsId = 'pimsId',
  PimsStatus = 'pimsStatus',
  RawPimsValue = 'rawPimsValue',
  RoomId = 'roomId',
  StartAt = 'startAt',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Workflow = 'workflow'
}

export type AppointmentScalarWhereInput = {
  AND?: Maybe<Array<AppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereInput>>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  durationInMinutes?: Maybe<IntFilter>;
  hash?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  roomId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  title?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
};

export type AppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereWithAggregatesInput>>;
  appointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  durationInMinutes?: Maybe<IntWithAggregatesFilter>;
  hash?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsExportConsultationId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsStatus?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  roomId?: Maybe<StringNullableWithAggregatesFilter>;
  startAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumAppointmentStatusWithAggregatesFilter>;
  title?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableWithAggregatesFilter>;
};

export enum AppointmentStatus {
  ArrivalConfirmed = 'ArrivalConfirmed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Reschedule = 'Reschedule'
}

export type AppointmentSumAggregateOutputType = {
  __typename?: 'AppointmentSumAggregateOutputType';
  durationInMinutes?: Maybe<Scalars['Int']>;
};

export type AppointmentSumOrderByAggregateInput = {
  durationInMinutes?: Maybe<SortOrder>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  _count: AppointmentTypeCountOutputType;
  appointmentTypeVisibility: AppointmentTypeVisibility;
  appointments: Array<Appointment>;
  clinic?: Maybe<Clinic>;
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  createdAt: Scalars['DateTime'];
  defaultDurationInMinutes: Scalars['Int'];
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  showInPetPortal: Scalars['Boolean'];
  surveyAppointmentTypeSettings: Array<SurveyAppointmentTypeSetting>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  updatedAt: Scalars['DateTime'];
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  workflows: Array<WorkflowEventSetting>;
};


export type AppointmentTypeProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type AppointmentTypeAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type AppointmentTypeClinicEmployeeAppointmentTypeSettingsArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type AppointmentTypeClinicWorkflowTypesArgs = {
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
};


export type AppointmentTypeDirectBookingExclusionRulesArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type AppointmentTypeRelatedTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type AppointmentTypeSurveyAppointmentTypeSettingsArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>;
  orderBy?: Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type AppointmentTypeUserAppointmentGroupingsArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type AppointmentTypeWorkflowsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type AppointmentTypeAvgAggregateOutputType = {
  __typename?: 'AppointmentTypeAvgAggregateOutputType';
  defaultDurationInMinutes?: Maybe<Scalars['Float']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Float']>;
  delayedStartTime?: Maybe<Scalars['Float']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type AppointmentTypeAvgOrderByAggregateInput = {
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type AppointmentTypeClinicEmployee = {
  __typename?: 'AppointmentTypeClinicEmployee';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type AppointmentTypeClinicEmployeeMapping = {
  __typename?: 'AppointmentTypeClinicEmployeeMapping';
  clinicEmployee: AppointmentTypeClinicEmployee;
};

export type AppointmentTypeCountAggregateOutputType = {
  __typename?: 'AppointmentTypeCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentTypeVisibility: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultDurationInMinutes: Scalars['Int'];
  defaultSoonestAvailableBookingInHours: Scalars['Int'];
  delayedStartTime: Scalars['Int'];
  description: Scalars['Int'];
  displayName: Scalars['Int'];
  displayOrder: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isAvailableForDirectBooking: Scalars['Int'];
  isVisible: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  showInPetPortal: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AppointmentTypeCountOrderByAggregateInput = {
  appointmentTypeVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentTypeCountOutputType = {
  __typename?: 'AppointmentTypeCountOutputType';
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
  appointments: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  relatedTrupanionExamDayOffers: Scalars['Int'];
  surveyAppointmentTypeSettings: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type AppointmentTypeCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateManyClinicInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeCreateManyClinicInputEnvelope = {
  data: Array<AppointmentTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateManyInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeCreateManyIntegrationInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeCreateManyIntegrationInputEnvelope = {
  data: Array<AppointmentTypeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentTypeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
};

export type AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
};

export type AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
};

export type AppointmentTypeCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
};

export type AppointmentTypeCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
};

export type AppointmentTypeCreateNestedOneWithoutDirectBookingAppointmentTypeSettingInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput>;
};

export type AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput>;
};

export type AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput>;
};

export type AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput>;
};

export type AppointmentTypeCreateOrConnectWithoutAppointmentsInput = {
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput = {
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutIntegrationInput = {
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  create: AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput = {
  create: AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput = {
  create: AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateOrConnectWithoutWorkflowsInput = {
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutClinicWorkflowTypesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutDirectBookingAppointmentTypeSettingInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutRelatedTrupanionExamDayOffersInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutSurveyAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutTrupanionIntegratedAppointmentTypeInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentTypeInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutAppoinmentTypesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeListRelationFilter = {
  every?: Maybe<AppointmentTypeWhereInput>;
  none?: Maybe<AppointmentTypeWhereInput>;
  some?: Maybe<AppointmentTypeWhereInput>;
};

export type AppointmentTypeMaxAggregateOutputType = {
  __typename?: 'AppointmentTypeMaxAggregateOutputType';
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeMaxOrderByAggregateInput = {
  appointmentTypeVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentTypeMinAggregateOutputType = {
  __typename?: 'AppointmentTypeMinAggregateOutputType';
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeMinOrderByAggregateInput = {
  appointmentTypeVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AppointmentTypeOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId'
}

export type AppointmentTypeOrderByRelevanceInput = {
  fields: Array<AppointmentTypeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AppointmentTypeOrderByWithAggregationInput = {
  _avg?: Maybe<AppointmentTypeAvgOrderByAggregateInput>;
  _count?: Maybe<AppointmentTypeCountOrderByAggregateInput>;
  _max?: Maybe<AppointmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<AppointmentTypeMinOrderByAggregateInput>;
  _sum?: Maybe<AppointmentTypeSumOrderByAggregateInput>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  showInPetPortal?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AppointmentTypeOrderByWithRelationAndSearchRelevanceInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<AppointmentTypeOrderByRelevanceInput>;
  appointmentTypeVisibility?: Maybe<SortOrder>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  displayName?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isAvailableForDirectBooking?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  showInPetPortal?: Maybe<SortOrder>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingOrderByRelationAggregateInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  updatedAt?: Maybe<SortOrder>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type AppointmentTypeRelationFilter = {
  is?: Maybe<AppointmentTypeWhereInput>;
  isNot?: Maybe<AppointmentTypeWhereInput>;
};

export enum AppointmentTypeScalarFieldEnum {
  AppointmentTypeVisibility = 'appointmentTypeVisibility',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DefaultDurationInMinutes = 'defaultDurationInMinutes',
  DefaultSoonestAvailableBookingInHours = 'defaultSoonestAvailableBookingInHours',
  DelayedStartTime = 'delayedStartTime',
  Description = 'description',
  DisplayName = 'displayName',
  DisplayOrder = 'displayOrder',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsAvailableForDirectBooking = 'isAvailableForDirectBooking',
  IsVisible = 'isVisible',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  ShowInPetPortal = 'showInPetPortal',
  UpdatedAt = 'updatedAt'
}

export type AppointmentTypeScalarWhereInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  delayedStartTime?: Maybe<IntNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  displayName?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  isVisible?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AppointmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AppointmentTypeScalarWhereWithAggregatesInput>>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultDurationInMinutes?: Maybe<IntWithAggregatesFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableWithAggregatesFilter>;
  delayedStartTime?: Maybe<IntNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isAvailableForDirectBooking?: Maybe<BoolWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  showInPetPortal?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type AppointmentTypeSumAggregateOutputType = {
  __typename?: 'AppointmentTypeSumAggregateOutputType';
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
};

export type AppointmentTypeSumOrderByAggregateInput = {
  defaultDurationInMinutes?: Maybe<SortOrder>;
  defaultSoonestAvailableBookingInHours?: Maybe<SortOrder>;
  delayedStartTime?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
};

export type AppointmentTypeUncheckedCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
};

export type AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
};

export type AppointmentTypeUncheckedCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput>;
};

export type AppointmentTypeUncheckedUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateManyInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type AppointmentTypeUncheckedUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUncheckedUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateManyMutationInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppointmentTypeUpdateManyWithWhereWithoutClinicInput = {
  data: AppointmentTypeUncheckedUpdateManyWithoutAppoinmentTypesInput;
  where: AppointmentTypeScalarWhereInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput = {
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
  where: AppointmentTypeScalarWhereInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput = {
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
  where: AppointmentTypeScalarWhereInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
  where: AppointmentTypeScalarWhereInput;
};

export type AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  data: AppointmentTypeUncheckedUpdateManyWithoutAppointmentTypesInput;
  where: AppointmentTypeScalarWhereInput;
};

export type AppointmentTypeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutDirectBookingExclusionRulesInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
};

export type AppointmentTypeUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentTypeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type AppointmentTypeUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentTypeCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutDirectBookingAppointmentTypeSettingNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutDirectBookingAppointmentTypeSettingInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutDirectBookingAppointmentTypeSettingInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutRelatedTrupanionExamDayOffersInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutRelatedTrupanionExamDayOffersInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutSurveyAppointmentTypeSettingsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutSurveyAppointmentTypeSettingsInput>;
};

export type AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutTrupanionIntegratedAppointmentTypeInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutTrupanionIntegratedAppointmentTypeInput>;
};

export type AppointmentTypeUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutAppointmentsInput>;
};

export type AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutClinicWorkflowTypesInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutClinicWorkflowTypesInput>;
};

export type AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput = {
  connect?: Maybe<AppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentTypeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<AppointmentTypeUpsertWithoutProviderAppointmentTypeRoomMapInput>;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutClinicInput = {
  data: AppointmentTypeUncheckedUpdateWithoutClinicInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  data: AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  data: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  data: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutClinicWorkflowTypesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutDirectBookingAppointmentTypeSettingInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutRelatedTrupanionExamDayOffersInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutSurveyAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutTrupanionIntegratedAppointmentTypeInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput>;
  appointmentTypeVisibility?: Maybe<AppointmentTypeVisibility>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentTypeNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppoinmentTypesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput>;
  displayName?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput>;
  isAvailableForDirectBooking?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput>;
  showInPetPortal?: Maybe<Scalars['Boolean']>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput>;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutClinicInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicInput;
  update: AppointmentTypeUncheckedUpdateWithoutClinicInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  update: AppointmentTypeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: AppointmentTypeUncheckedCreateWithoutIntegrationInput;
  update: AppointmentTypeUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutUserAppointmentGroupingsInput;
  update: AppointmentTypeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  create: AppointmentTypeUncheckedCreateWithoutWorkflowsInput;
  update: AppointmentTypeUncheckedUpdateWithoutWorkflowsInput;
  where: AppointmentTypeWhereUniqueInput;
};

export type AppointmentTypeUpsertWithoutAppointmentsInput = {
  create: AppointmentTypeUncheckedCreateWithoutAppointmentsInput;
  update: AppointmentTypeUncheckedUpdateWithoutAppointmentsInput;
};

export type AppointmentTypeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  update: AppointmentTypeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type AppointmentTypeUpsertWithoutClinicWorkflowTypesInput = {
  create: AppointmentTypeUncheckedCreateWithoutClinicWorkflowTypesInput;
  update: AppointmentTypeUncheckedUpdateWithoutClinicWorkflowTypesInput;
};

export type AppointmentTypeUpsertWithoutDirectBookingAppointmentTypeSettingInput = {
  create: AppointmentTypeUncheckedCreateWithoutDirectBookingAppointmentTypeSettingInput;
  update: AppointmentTypeUncheckedUpdateWithoutDirectBookingAppointmentTypeSettingInput;
};

export type AppointmentTypeUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  create: AppointmentTypeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  update: AppointmentTypeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
};

export type AppointmentTypeUpsertWithoutRelatedTrupanionExamDayOffersInput = {
  create: AppointmentTypeUncheckedCreateWithoutRelatedTrupanionExamDayOffersInput;
  update: AppointmentTypeUncheckedUpdateWithoutRelatedTrupanionExamDayOffersInput;
};

export type AppointmentTypeUpsertWithoutSurveyAppointmentTypeSettingsInput = {
  create: AppointmentTypeUncheckedCreateWithoutSurveyAppointmentTypeSettingsInput;
  update: AppointmentTypeUncheckedUpdateWithoutSurveyAppointmentTypeSettingsInput;
};

export type AppointmentTypeUpsertWithoutTrupanionIntegratedAppointmentTypeInput = {
  create: AppointmentTypeUncheckedCreateWithoutTrupanionIntegratedAppointmentTypeInput;
  update: AppointmentTypeUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypeInput;
};

export enum AppointmentTypeVisibility {
  HideAll = 'HideAll',
  HideFromCalendarOnly = 'HideFromCalendarOnly',
  HideFromDropDownOnly = 'HideFromDropDownOnly',
  ShowAll = 'ShowAll'
}

export type AppointmentTypeWhereInput = {
  AND?: Maybe<Array<AppointmentTypeWhereInput>>;
  NOT?: Maybe<Array<AppointmentTypeWhereInput>>;
  OR?: Maybe<Array<AppointmentTypeWhereInput>>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
  appointmentTypeVisibility?: Maybe<EnumAppointmentTypeVisibilityFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultDurationInMinutes?: Maybe<IntFilter>;
  defaultSoonestAvailableBookingInHours?: Maybe<IntNullableFilter>;
  delayedStartTime?: Maybe<IntNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  displayName?: Maybe<StringNullableFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isAvailableForDirectBooking?: Maybe<BoolFilter>;
  isVisible?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  relatedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  showInPetPortal?: Maybe<BoolFilter>;
  surveyAppointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingListRelationFilter>;
  trupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  updatedAt?: Maybe<DateTimeFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type AppointmentTypeWhereUniqueInput = {
  appointmentTypePimsIdAndIntegrationId?: Maybe<AppointmentTypeAppointmentTypePimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AppointmentTypeWithOrder = {
  displayOrder: Scalars['Int'];
  id: Scalars['String'];
};

export type AppointmentTypeWithOrderResponse = {
  __typename?: 'AppointmentTypeWithOrderResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AppointmentUncheckedCreateInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
};

export type AppointmentUncheckedCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
};

export type AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
};

export type AppointmentUncheckedCreateWithoutAppointmentTypeInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutAutomationRunsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutChannelInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutClinicEmployeesInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutClinicInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutClinicPetParentsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutConsultationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutFormSubmissionsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutIntegrationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutPushNotificationLogsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutRoomInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutSurveyResultsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowTriggersInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput>;
};

export type AppointmentUncheckedCreateWithoutWorkflowsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput>;
  durationInMinutes: Scalars['Int'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput>;
};

export type AppointmentUncheckedUpdateInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateManyInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutAppointmentsInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type AppointmentUncheckedUpdateWithoutAppointmentTypeInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutAutomationRunsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutChannelInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicEmployeesInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutClinicPetParentsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutConsultationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutFormSubmissionsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutIntegrationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutPushNotificationLogsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutRoomInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutSurveyResultsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowTriggersInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUncheckedUpdateWithoutWorkflowsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput>;
  channelId?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  roomId?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
};

export type AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutChannelInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutConsultationInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutIntegrationInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutRoomInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutWorkflowsInput = {
  data: AppointmentUncheckedUpdateManyWithoutAppointmentsInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type AppointmentUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutChannelInput>>;
  createMany?: Maybe<AppointmentCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type AppointmentUpdateManyWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicEmployeesInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
};

export type AppointmentUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetInput>>;
  createMany?: Maybe<AppointmentCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type AppointmentUpdateManyWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutClinicPetParentsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutClinicPetParentsInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutClinicPetParentsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput>>;
};

export type AppointmentUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutConsultationInput>>;
  createMany?: Maybe<AppointmentCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type AppointmentUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<AppointmentCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type AppointmentUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutRoomInput>>;
  createMany?: Maybe<AppointmentCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type AppointmentUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutMassTextAlertEntryAppointmentsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput>;
};

export type AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutSurveyResultsInput>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutSurveyResultsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutSurveyResultsInput>;
};

export type AppointmentUpdateOneWithoutAutomationRunsNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutAutomationRunsInput>;
};

export type AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<AppointmentUpsertWithoutChannelAutomationStatusesInput>;
};

export type AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutDirectBookingWidgetRequestInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput>;
  upsert?: Maybe<AppointmentUpsertWithoutDirectBookingWidgetRequestInput>;
};

export type AppointmentUpdateOneWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutFormSubmissionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutFormSubmissionsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutFormSubmissionsInput>;
};

export type AppointmentUpdateOneWithoutPushNotificationLogsNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutPushNotificationLogsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutPushNotificationLogsInput>;
  upsert?: Maybe<AppointmentUpsertWithoutPushNotificationLogsInput>;
};

export type AppointmentUpdateOneWithoutWorkflowTriggersNestedInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutWorkflowTriggersInput>;
  create?: Maybe<AppointmentUncheckedCreateWithoutWorkflowTriggersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUncheckedUpdateWithoutWorkflowTriggersInput>;
  upsert?: Maybe<AppointmentUpsertWithoutWorkflowTriggersInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutChannelInput = {
  data: AppointmentUncheckedUpdateWithoutChannelInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  data: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicInput = {
  data: AppointmentUncheckedUpdateWithoutClinicInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: AppointmentUncheckedUpdateWithoutClinicPetInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutClinicPetParentsInput = {
  data: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutConsultationInput = {
  data: AppointmentUncheckedUpdateWithoutConsultationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: AppointmentUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutRoomInput = {
  data: AppointmentUncheckedUpdateWithoutRoomInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutWorkflowsInput = {
  data: AppointmentUncheckedUpdateWithoutWorkflowsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithoutAppointmentTypeInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutAutomationRunsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutChannelAutomationStatusesInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutChannelInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutClinicEmployeesInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutClinicInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutClinicPetInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutClinicPetParentsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutConsultationInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutDirectBookingWidgetRequestInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutFormSubmissionsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutIntegrationInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutMassTextAlertEntryAppointmentsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutPushNotificationLogsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutRoomInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutSurveyResultsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutWorkflowTriggersInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput>;
};

export type AppointmentUpdateWithoutWorkflowsInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutAppointmentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutAppointmentNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutAppointmentsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutAppointmentsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAppointmentsNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutAppointmentsNestedInput>;
  consultation?: Maybe<ConsultationUpdateOneWithoutAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutAppointmentNestedInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput>;
  pimsExportConsultationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsStatus?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutAppointmentNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  room?: Maybe<ClinicRoomUpdateOneWithoutAppointmentsNestedInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAppointmentNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowType>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutAppointmentNestedInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: AppointmentUncheckedCreateWithoutAppointmentTypeInput;
  update: AppointmentUncheckedUpdateWithoutAppointmentTypeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutChannelInput = {
  create: AppointmentUncheckedCreateWithoutChannelInput;
  update: AppointmentUncheckedUpdateWithoutChannelInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  create: AppointmentUncheckedCreateWithoutClinicEmployeesInput;
  update: AppointmentUncheckedUpdateWithoutClinicEmployeesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicInput = {
  create: AppointmentUncheckedCreateWithoutClinicInput;
  update: AppointmentUncheckedUpdateWithoutClinicInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: AppointmentUncheckedCreateWithoutClinicPetInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutClinicPetParentsInput = {
  create: AppointmentUncheckedCreateWithoutClinicPetParentsInput;
  update: AppointmentUncheckedUpdateWithoutClinicPetParentsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutConsultationInput = {
  create: AppointmentUncheckedCreateWithoutConsultationInput;
  update: AppointmentUncheckedUpdateWithoutConsultationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: AppointmentUncheckedCreateWithoutIntegrationInput;
  update: AppointmentUncheckedUpdateWithoutIntegrationInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutRoomInput = {
  create: AppointmentUncheckedCreateWithoutRoomInput;
  update: AppointmentUncheckedUpdateWithoutRoomInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutWorkflowsInput = {
  create: AppointmentUncheckedCreateWithoutWorkflowsInput;
  update: AppointmentUncheckedUpdateWithoutWorkflowsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithoutAutomationRunsInput = {
  create: AppointmentUncheckedCreateWithoutAutomationRunsInput;
  update: AppointmentUncheckedUpdateWithoutAutomationRunsInput;
};

export type AppointmentUpsertWithoutChannelAutomationStatusesInput = {
  create: AppointmentUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: AppointmentUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type AppointmentUpsertWithoutDirectBookingWidgetRequestInput = {
  create: AppointmentUncheckedCreateWithoutDirectBookingWidgetRequestInput;
  update: AppointmentUncheckedUpdateWithoutDirectBookingWidgetRequestInput;
};

export type AppointmentUpsertWithoutFormSubmissionsInput = {
  create: AppointmentUncheckedCreateWithoutFormSubmissionsInput;
  update: AppointmentUncheckedUpdateWithoutFormSubmissionsInput;
};

export type AppointmentUpsertWithoutMassTextAlertEntryAppointmentsInput = {
  create: AppointmentUncheckedCreateWithoutMassTextAlertEntryAppointmentsInput;
  update: AppointmentUncheckedUpdateWithoutMassTextAlertEntryAppointmentsInput;
};

export type AppointmentUpsertWithoutPushNotificationLogsInput = {
  create: AppointmentUncheckedCreateWithoutPushNotificationLogsInput;
  update: AppointmentUncheckedUpdateWithoutPushNotificationLogsInput;
};

export type AppointmentUpsertWithoutSurveyResultsInput = {
  create: AppointmentUncheckedCreateWithoutSurveyResultsInput;
  update: AppointmentUncheckedUpdateWithoutSurveyResultsInput;
};

export type AppointmentUpsertWithoutWorkflowTriggersInput = {
  create: AppointmentUncheckedCreateWithoutWorkflowTriggersInput;
  update: AppointmentUncheckedUpdateWithoutWorkflowTriggersInput;
};

export type AppointmentWhereInput = {
  AND?: Maybe<Array<AppointmentWhereInput>>;
  NOT?: Maybe<Array<AppointmentWhereInput>>;
  OR?: Maybe<Array<AppointmentWhereInput>>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringNullableFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  directBookingWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  durationInMinutes?: Maybe<IntFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  hash?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  massTextAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
  pimsExportConsultationId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsStatus?: Maybe<StringNullableFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  room?: Maybe<ClinicRoomWhereInput>;
  roomId?: Maybe<StringNullableFilter>;
  startAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  title?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflow?: Maybe<EnumWorkflowTypeNullableFilter>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type AppointmentWhereUniqueInput = {
  appointmentPimsIdAndIntegrationId?: Maybe<AppointmentAppointmentPimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AuthLinkEmailMatch = {
  __typename?: 'AuthLinkEmailMatch';
  id: Scalars['String'];
  redacted: Scalars['String'];
};

export type AuthLinkRequestInput = {
  clinicId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['Json']>;
};

export type AuthLinkRequestResult = {
  __typename?: 'AuthLinkRequestResult';
  emails?: Maybe<Array<Maybe<AuthLinkEmailMatch>>>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export enum AutoResponseSuggestionStatus {
  Discarded = 'Discarded',
  Edited = 'Edited',
  Generated = 'Generated',
  Used = 'Used'
}

export enum AutomatedTriggerType {
  After = 'After',
  Before = 'Before'
}

export type AutomationRun = {
  __typename?: 'AutomationRun';
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: AutomationRunCountOutputType;
  actions: Array<AutomationRunAction>;
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessage;
  channelMessageId: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invioceLineItem?: Maybe<InvoiceLineItem>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentBy?: Maybe<User>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomation: WorkflowEventSetting;
  originAutomationId: Scalars['String'];
  returnToAutomationAction?: Maybe<WorkflowEventAction>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType: AutomationRunType;
  serviceReminder?: Maybe<ServiceReminder>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status: AutomationRunStatus;
  surveyResults: Array<SurveyResult>;
  updatedAt: Scalars['DateTime'];
};


export type AutomationRunHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type AutomationRunActionsArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type AutomationRunSurveyResultsArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};

export type AutomationRunAction = {
  __typename?: 'AutomationRunAction';
  actionType: AutomationRunActionType;
  attachments: Scalars['Json'];
  automationAction?: Maybe<WorkflowEventAction>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRun: AutomationRun;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons: Scalars['Json'];
  channelStatusChange?: Maybe<ChannelStatus>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Int'];
  paymentIntent?: Maybe<StripePaymentIntent>;
  paymentIntentId?: Maybe<Scalars['String']>;
  pet?: Maybe<ClinicPet>;
  petId?: Maybe<Scalars['String']>;
  petParent?: Maybe<ClinicPetParent>;
  petParentId?: Maybe<Scalars['String']>;
  promptType: AutomationRunActionPromptType;
  updatedAt: Scalars['DateTime'];
};

export type AutomationRunActionAvgAggregateOutputType = {
  __typename?: 'AutomationRunActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type AutomationRunActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type AutomationRunActionCountAggregateOutputType = {
  __typename?: 'AutomationRunActionCountAggregateOutputType';
  _all: Scalars['Int'];
  actionType: Scalars['Int'];
  attachments: Scalars['Int'];
  automationActionId: Scalars['Int'];
  automationRunId: Scalars['Int'];
  body: Scalars['Int'];
  buttons: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  paymentIntentId: Scalars['Int'];
  petId: Scalars['Int'];
  petParentId: Scalars['Int'];
  promptType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AutomationRunActionCountOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunActionCreateInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyAutomationActionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyAutomationActionInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyAutomationRunInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyAutomationRunInputEnvelope = {
  data: Array<AutomationRunActionCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<AutomationRunActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyPetInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyPetInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateManyPetParentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateManyPetParentInputEnvelope = {
  data: Array<AutomationRunActionCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationActionInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
};

export type AutomationRunActionCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
};

export type AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
};

export type AutomationRunActionCreateNestedManyWithoutPetInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
};

export type AutomationRunActionCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
};

export type AutomationRunActionCreateNestedOneWithoutFormSubmissionInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
};

export type AutomationRunActionCreateNestedOneWithoutPaymentIntentInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationActionInput = {
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutAutomationRunInput = {
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput = {
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutFormSubmissionInput = {
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutPaymentIntentInput = {
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetInput = {
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateOrConnectWithoutPetParentInput = {
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionCreateWithoutAutomationActionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutAutomationRunInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutFormSubmissionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutPaymentIntentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutPetInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  petParent?: Maybe<ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionCreateWithoutPetParentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput>;
  automationRun: AutomationRunCreateNestedOneWithoutActionsInput;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunActionInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput>;
  pet?: Maybe<ClinicPetCreateNestedOneWithoutAutomationRunActionsInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionListRelationFilter = {
  every?: Maybe<AutomationRunActionWhereInput>;
  none?: Maybe<AutomationRunActionWhereInput>;
  some?: Maybe<AutomationRunActionWhereInput>;
};

export type AutomationRunActionMaxAggregateOutputType = {
  __typename?: 'AutomationRunActionMaxAggregateOutputType';
  actionType?: Maybe<AutomationRunActionType>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionMaxOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunActionMinAggregateOutputType = {
  __typename?: 'AutomationRunActionMinAggregateOutputType';
  actionType?: Maybe<AutomationRunActionType>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionMinOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunActionOrderByRelevanceFieldEnum {
  AutomationActionId = 'automationActionId',
  AutomationRunId = 'automationRunId',
  Body = 'body',
  ChannelStatusChangeId = 'channelStatusChangeId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  PaymentIntentId = 'paymentIntentId',
  PetId = 'petId',
  PetParentId = 'petParentId'
}

export type AutomationRunActionOrderByRelevanceInput = {
  fields: Array<AutomationRunActionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AutomationRunActionOrderByWithAggregationInput = {
  _avg?: Maybe<AutomationRunActionAvgOrderByAggregateInput>;
  _count?: Maybe<AutomationRunActionCountOrderByAggregateInput>;
  _max?: Maybe<AutomationRunActionMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunActionMinOrderByAggregateInput>;
  _sum?: Maybe<AutomationRunActionSumOrderByAggregateInput>;
  actionType?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  automationActionId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  paymentIntentId?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunActionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<AutomationRunActionOrderByRelevanceInput>;
  actionType?: Maybe<SortOrder>;
  attachments?: Maybe<SortOrder>;
  automationAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  automationActionId?: Maybe<SortOrder>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  automationRunId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  buttons?: Maybe<SortOrder>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  paymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  paymentIntentId?: Maybe<SortOrder>;
  pet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  petId?: Maybe<SortOrder>;
  petParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  petParentId?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum AutomationRunActionPromptType {
  Buttons = 'Buttons',
  Form = 'Form',
  Payment = 'Payment',
  Prompt = 'Prompt',
  Statement = 'Statement'
}

export type AutomationRunActionRelationFilter = {
  is?: Maybe<AutomationRunActionWhereInput>;
  isNot?: Maybe<AutomationRunActionWhereInput>;
};

export enum AutomationRunActionScalarFieldEnum {
  ActionType = 'actionType',
  Attachments = 'attachments',
  AutomationActionId = 'automationActionId',
  AutomationRunId = 'automationRunId',
  Body = 'body',
  Buttons = 'buttons',
  ChannelStatusChangeId = 'channelStatusChangeId',
  CreatedAt = 'createdAt',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  Order = 'order',
  PaymentIntentId = 'paymentIntentId',
  PetId = 'petId',
  PetParentId = 'petParentId',
  PromptType = 'promptType',
  UpdatedAt = 'updatedAt'
}

export type AutomationRunActionScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  attachments?: Maybe<JsonFilter>;
  automationActionId?: Maybe<StringNullableFilter>;
  automationRunId?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AutomationRunActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunActionScalarWhereWithAggregatesInput>>;
  actionType?: Maybe<EnumAutomationRunActionTypeWithAggregatesFilter>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  automationActionId?: Maybe<StringNullableWithAggregatesFilter>;
  automationRunId?: Maybe<StringWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  buttons?: Maybe<JsonWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  paymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type AutomationRunActionSubscription = {
  __typename?: 'AutomationRunActionSubscription';
  mutation: MutationType;
  node: AutomationRunAction;
};

export type AutomationRunActionSumAggregateOutputType = {
  __typename?: 'AutomationRunActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type AutomationRunActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export enum AutomationRunActionType {
  Automated = 'Automated',
  PetParent = 'PetParent',
  System = 'System'
}

export type AutomationRunActionUncheckedCreateInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
};

export type AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
};

export type AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationActionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutAutomationRunInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutFormSubmissionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutPaymentIntentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedCreateWithoutPetParentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId: Scalars['String'];
  body: Scalars['String'];
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateManyInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutActionsInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
};

export type AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
};

export type AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationActionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutAutomationRunInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUncheckedUpdateWithoutPetParentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationActionId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  petId?: Maybe<Scalars['String']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateManyMutationInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput = {
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
  where: AutomationRunActionScalarWhereInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput = {
  data: AutomationRunActionUncheckedUpdateManyWithoutActionsInput;
  where: AutomationRunActionScalarWhereInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
  where: AutomationRunActionScalarWhereInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetInput = {
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
  where: AutomationRunActionScalarWhereInput;
};

export type AutomationRunActionUpdateManyWithWhereWithoutPetParentInput = {
  data: AutomationRunActionUncheckedUpdateManyWithoutAutomationRunActionsInput;
  where: AutomationRunActionScalarWhereInput;
};

export type AutomationRunActionUpdateManyWithoutAutomationActionNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationActionInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationActionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput>>;
};

export type AutomationRunActionUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetInput>>;
};

export type AutomationRunActionUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunActionCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<AutomationRunActionCreateWithoutPetParentInput>>;
  createMany?: Maybe<AutomationRunActionCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunActionScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunActionWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunActionUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutFormSubmissionInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutFormSubmissionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutFormSubmissionInput>;
};

export type AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput = {
  connect?: Maybe<AutomationRunActionWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunActionCreateOrConnectWithoutPaymentIntentInput>;
  create?: Maybe<AutomationRunActionUncheckedCreateWithoutPaymentIntentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput>;
  upsert?: Maybe<AutomationRunActionUpsertWithoutPaymentIntentInput>;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationActionInput = {
  data: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutAutomationRunInput = {
  data: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  data: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetInput = {
  data: AutomationRunActionUncheckedUpdateWithoutPetInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpdateWithWhereUniqueWithoutPetParentInput = {
  data: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpdateWithoutAutomationActionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutAutomationRunInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutFormSubmissionInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutPaymentIntentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutPetInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  petParent?: Maybe<ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpdateWithoutPetParentInput = {
  actionType?: Maybe<AutomationRunActionType>;
  attachments?: Maybe<Scalars['Json']>;
  automationAction?: Maybe<WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneRequiredWithoutActionsNestedInput>;
  body?: Maybe<Scalars['String']>;
  buttons?: Maybe<Scalars['Json']>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  paymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput>;
  pet?: Maybe<ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationActionInput = {
  create: AutomationRunActionUncheckedCreateWithoutAutomationActionInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationActionInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutAutomationRunInput = {
  create: AutomationRunActionUncheckedCreateWithoutAutomationRunInput;
  update: AutomationRunActionUncheckedUpdateWithoutAutomationRunInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  create: AutomationRunActionUncheckedCreateWithoutChannelStatusChangeInput;
  update: AutomationRunActionUncheckedUpdateWithoutChannelStatusChangeInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetInput = {
  create: AutomationRunActionUncheckedCreateWithoutPetInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpsertWithWhereUniqueWithoutPetParentInput = {
  create: AutomationRunActionUncheckedCreateWithoutPetParentInput;
  update: AutomationRunActionUncheckedUpdateWithoutPetParentInput;
  where: AutomationRunActionWhereUniqueInput;
};

export type AutomationRunActionUpsertWithoutFormSubmissionInput = {
  create: AutomationRunActionUncheckedCreateWithoutFormSubmissionInput;
  update: AutomationRunActionUncheckedUpdateWithoutFormSubmissionInput;
};

export type AutomationRunActionUpsertWithoutPaymentIntentInput = {
  create: AutomationRunActionUncheckedCreateWithoutPaymentIntentInput;
  update: AutomationRunActionUncheckedUpdateWithoutPaymentIntentInput;
};

export type AutomationRunActionWhereInput = {
  AND?: Maybe<Array<AutomationRunActionWhereInput>>;
  NOT?: Maybe<Array<AutomationRunActionWhereInput>>;
  OR?: Maybe<Array<AutomationRunActionWhereInput>>;
  actionType?: Maybe<EnumAutomationRunActionTypeFilter>;
  attachments?: Maybe<JsonFilter>;
  automationAction?: Maybe<WorkflowEventActionWhereInput>;
  automationActionId?: Maybe<StringNullableFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  automationRunId?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  buttons?: Maybe<JsonFilter>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  paymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  paymentIntentId?: Maybe<StringNullableFilter>;
  pet?: Maybe<ClinicPetWhereInput>;
  petId?: Maybe<StringNullableFilter>;
  petParent?: Maybe<ClinicPetParentWhereInput>;
  petParentId?: Maybe<StringNullableFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AutomationRunActionWhereUniqueInput = {
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type AutomationRunCountAggregateOutputType = {
  __typename?: 'AutomationRunCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  automationEvent: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  completedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  id: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  manuallySentById: Scalars['Int'];
  originAutomationId: Scalars['Int'];
  returnToAutomationActionId: Scalars['Int'];
  runType: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  startedAt: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AutomationRunCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunCountOutputType = {
  __typename?: 'AutomationRunCountOutputType';
  HillsToHomeAPILog: Scalars['Int'];
  actions: Scalars['Int'];
  surveyResults: Scalars['Int'];
};

export type AutomationRunCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyAppointmentInput = {
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyAppointmentInputEnvelope = {
  data: Array<AutomationRunCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyClinicInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicPetParentInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyClinicPetParentInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<AutomationRunCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyFormSubmissionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyFormSubmissionInputEnvelope = {
  data: Array<AutomationRunCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyInvioceLineItemInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyInvioceLineItemInputEnvelope = {
  data: Array<AutomationRunCreateManyInvioceLineItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyManuallySentByInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyManuallySentByInputEnvelope = {
  data: Array<AutomationRunCreateManyManuallySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyOriginAutomationInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyOriginAutomationInputEnvelope = {
  data: Array<AutomationRunCreateManyOriginAutomationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyReturnToAutomationActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyReturnToAutomationActionInputEnvelope = {
  data: Array<AutomationRunCreateManyReturnToAutomationActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateManyServiceReminderInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateManyServiceReminderInputEnvelope = {
  data: Array<AutomationRunCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AutomationRunCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutInvioceLineItemInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutManuallySentByInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutOriginAutomationInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
};

export type AutomationRunCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
};

export type AutomationRunCreateNestedOneWithoutActionsInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
};

export type AutomationRunCreateNestedOneWithoutChannelMessageInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
};

export type AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput>;
};

export type AutomationRunCreateNestedOneWithoutSurveyResultsInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutSurveyResultsInput>;
};

export type AutomationRunCreateOrConnectWithoutActionsInput = {
  create: AutomationRunUncheckedCreateWithoutActionsInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutAppointmentInput = {
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutChannelMessageInput = {
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutClinicInput = {
  create: AutomationRunUncheckedCreateWithoutClinicInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutClinicPetParentInput = {
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput = {
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutFormSubmissionInput = {
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput = {
  create: AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutInvioceLineItemInput = {
  create: AutomationRunUncheckedCreateWithoutInvioceLineItemInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutManuallySentByInput = {
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutOriginAutomationInput = {
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput = {
  create: AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutServiceReminderInput = {
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateOrConnectWithoutSurveyResultsInput = {
  create: AutomationRunUncheckedCreateWithoutSurveyResultsInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunCreateWithoutActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutAppointmentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutChannelMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutClinicPetParentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutClinicWidgetRequestInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutFormSubmissionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutHillsToHomeApiLogInput = {
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutInvioceLineItemInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutManuallySentByInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutOriginAutomationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutReturnToAutomationActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutServiceReminderInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunCreateWithoutSurveyResultsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationRunInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutAutomationRunsInput>;
  automationEvent: WorkflowEvent;
  channelMessage: ChannelMessageCreateNestedOneWithoutAutomationRunInput;
  clinic: ClinicCreateNestedOneWithoutAutomationRunsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAutomationRunsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutAutomationRunsInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutAutomationRunInput>;
  manuallySentBy?: Maybe<UserCreateNestedOneWithoutManuallySentAutomationRunsInput>;
  originAutomation: WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput;
  returnToAutomationAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutAutomationRunsInput>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunListRelationFilter = {
  every?: Maybe<AutomationRunWhereInput>;
  none?: Maybe<AutomationRunWhereInput>;
  some?: Maybe<AutomationRunWhereInput>;
};

export type AutomationRunMaxAggregateOutputType = {
  __typename?: 'AutomationRunMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunMinAggregateOutputType = {
  __typename?: 'AutomationRunMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AutomationRunOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  ChannelMessageId = 'channelMessageId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  InvoiceLineItemId = 'invoiceLineItemId',
  ManuallySentById = 'manuallySentById',
  OriginAutomationId = 'originAutomationId',
  ReturnToAutomationActionId = 'returnToAutomationActionId',
  ServiceReminderId = 'serviceReminderId'
}

export type AutomationRunOrderByRelevanceInput = {
  fields: Array<AutomationRunOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type AutomationRunOrderByWithAggregationInput = {
  _count?: Maybe<AutomationRunCountOrderByAggregateInput>;
  _max?: Maybe<AutomationRunMaxOrderByAggregateInput>;
  _min?: Maybe<AutomationRunMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  manuallySentById?: Maybe<SortOrder>;
  originAutomationId?: Maybe<SortOrder>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunOrderByWithRelationAndSearchRelevanceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<AutomationRunOrderByRelevanceInput>;
  actions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  automationEvent?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  channelMessageId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invioceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemId?: Maybe<SortOrder>;
  manuallySentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  manuallySentById?: Maybe<SortOrder>;
  originAutomation?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  originAutomationId?: Maybe<SortOrder>;
  returnToAutomationAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  returnToAutomationActionId?: Maybe<SortOrder>;
  runType?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type AutomationRunRelationFilter = {
  is?: Maybe<AutomationRunWhereInput>;
  isNot?: Maybe<AutomationRunWhereInput>;
};

export enum AutomationRunScalarFieldEnum {
  AppointmentId = 'appointmentId',
  AutomationEvent = 'automationEvent',
  ChannelMessageId = 'channelMessageId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  InvoiceLineItemId = 'invoiceLineItemId',
  ManuallySentById = 'manuallySentById',
  OriginAutomationId = 'originAutomationId',
  ReturnToAutomationActionId = 'returnToAutomationActionId',
  RunType = 'runType',
  ServiceReminderId = 'serviceReminderId',
  StartedAt = 'startedAt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type AutomationRunScalarWhereInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  channelMessageId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  manuallySentById?: Maybe<StringNullableFilter>;
  originAutomationId?: Maybe<StringFilter>;
  returnToAutomationActionId?: Maybe<StringNullableFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AutomationRunScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<AutomationRunScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  automationEvent?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringNullableWithAggregatesFilter>;
  manuallySentById?: Maybe<StringNullableWithAggregatesFilter>;
  originAutomationId?: Maybe<StringWithAggregatesFilter>;
  returnToAutomationActionId?: Maybe<StringNullableWithAggregatesFilter>;
  runType?: Maybe<EnumAutomationRunTypeWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumAutomationRunStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum AutomationRunStatus {
  Completed = 'Completed',
  Sent = 'Sent',
  Started = 'Started'
}

export type AutomationRunSubscription = {
  __typename?: 'AutomationRunSubscription';
  mutation: MutationType;
  node: AutomationRun;
};

export enum AutomationRunType {
  Automated = 'Automated',
  Manual = 'Manual'
}

export type AutomationRunUncheckedCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
};

export type AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
};

export type AutomationRunUncheckedCreateWithoutActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutAppointmentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutChannelMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutClinicPetParentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutFormSubmissionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput = {
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutInvioceLineItemInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutManuallySentByInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutOriginAutomationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutServiceReminderInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedCreateWithoutSurveyResultsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput>;
  actions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationRunInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent: WorkflowEvent;
  channelMessageId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId: Scalars['String'];
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutAutomationRunInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput = {
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput>>;
};

export type AutomationRunUncheckedUpdateManyWithoutReturnedAutomationRunsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
};

export type AutomationRunUncheckedUpdateWithoutActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutAppointmentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutChannelMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutClinicPetParentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutFormSubmissionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutInvioceLineItemInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutManuallySentByInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutOriginAutomationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutServiceReminderInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUncheckedUpdateWithoutSurveyResultsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationRunNestedInput>;
  appointmentId?: Maybe<Scalars['String']>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessageId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  manuallySentById?: Maybe<Scalars['String']>;
  originAutomationId?: Maybe<Scalars['String']>;
  returnToAutomationActionId?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateManyMutationInput = {
  automationEvent?: Maybe<WorkflowEvent>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  runType?: Maybe<AutomationRunType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateManyWithWhereWithoutAppointmentInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutManuallySentByInput = {
  data: AutomationRunUncheckedUpdateManyWithoutManuallySentAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput = {
  data: AutomationRunUncheckedUpdateManyWithoutReturnedAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithWhereWithoutServiceReminderInput = {
  data: AutomationRunUncheckedUpdateManyWithoutAutomationRunsInput;
  where: AutomationRunScalarWhereInput;
};

export type AutomationRunUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutAppointmentInput>>;
  createMany?: Maybe<AutomationRunCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type AutomationRunUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type AutomationRunUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<AutomationRunCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type AutomationRunUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<AutomationRunCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type AutomationRunUpdateManyWithoutInvioceLineItemNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutInvioceLineItemInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutInvioceLineItemInput>>;
  createMany?: Maybe<AutomationRunCreateManyInvioceLineItemInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutInvioceLineItemInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput>>;
};

export type AutomationRunUpdateManyWithoutManuallySentByNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutManuallySentByInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutManuallySentByInput>>;
  createMany?: Maybe<AutomationRunCreateManyManuallySentByInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutManuallySentByInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput>>;
};

export type AutomationRunUpdateManyWithoutOriginAutomationNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutOriginAutomationInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutOriginAutomationInput>>;
  createMany?: Maybe<AutomationRunCreateManyOriginAutomationInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutOriginAutomationInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput>>;
};

export type AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutReturnToAutomationActionInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutReturnToAutomationActionInput>>;
  createMany?: Maybe<AutomationRunCreateManyReturnToAutomationActionInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutReturnToAutomationActionInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput>>;
};

export type AutomationRunUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AutomationRunCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<AutomationRunCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<AutomationRunCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AutomationRunScalarWhereInput>>;
  disconnect?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  set?: Maybe<Array<AutomationRunWhereUniqueInput>>;
  update?: Maybe<Array<AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<AutomationRunUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type AutomationRunUpdateOneRequiredWithoutActionsNestedInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutActionsInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutActionsInput>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutActionsInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutActionsInput>;
};

export type AutomationRunUpdateOneWithoutChannelMessageNestedInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutChannelMessageInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutChannelMessageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutChannelMessageInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutChannelMessageInput>;
};

export type AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutHillsToHomeApiLogInput>;
};

export type AutomationRunUpdateOneWithoutSurveyResultsNestedInput = {
  connect?: Maybe<AutomationRunWhereUniqueInput>;
  connectOrCreate?: Maybe<AutomationRunCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<AutomationRunUncheckedCreateWithoutSurveyResultsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AutomationRunUncheckedUpdateWithoutSurveyResultsInput>;
  upsert?: Maybe<AutomationRunUpsertWithoutSurveyResultsInput>;
};

export type AutomationRunUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: AutomationRunUncheckedUpdateWithoutAppointmentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicInput = {
  data: AutomationRunUncheckedUpdateWithoutClinicInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  data: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  data: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutInvioceLineItemInput = {
  data: AutomationRunUncheckedUpdateWithoutInvioceLineItemInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutManuallySentByInput = {
  data: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutOriginAutomationInput = {
  data: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutReturnToAutomationActionInput = {
  data: AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithWhereUniqueWithoutServiceReminderInput = {
  data: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpdateWithoutActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutAppointmentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutChannelMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutClinicPetParentInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutClinicWidgetRequestInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutFormSubmissionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutHillsToHomeApiLogInput = {
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutInvioceLineItemInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutManuallySentByInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutOriginAutomationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutReturnToAutomationActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutServiceReminderInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutAutomationRunNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpdateWithoutSurveyResultsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput>;
  actions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationRunNestedInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutAutomationRunsNestedInput>;
  automationEvent?: Maybe<WorkflowEvent>;
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutAutomationRunsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invioceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput>;
  manuallySentBy?: Maybe<UserUpdateOneWithoutManuallySentAutomationRunsNestedInput>;
  originAutomation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput>;
  returnToAutomationAction?: Maybe<WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput>;
  runType?: Maybe<AutomationRunType>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutAutomationRunsNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationRunStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AutomationRunUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: AutomationRunUncheckedCreateWithoutAppointmentInput;
  update: AutomationRunUncheckedUpdateWithoutAppointmentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicInput = {
  create: AutomationRunUncheckedCreateWithoutClinicInput;
  update: AutomationRunUncheckedUpdateWithoutClinicInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: AutomationRunUncheckedCreateWithoutClinicPetParentInput;
  update: AutomationRunUncheckedUpdateWithoutClinicPetParentInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  create: AutomationRunUncheckedCreateWithoutClinicWidgetRequestInput;
  update: AutomationRunUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  create: AutomationRunUncheckedCreateWithoutFormSubmissionInput;
  update: AutomationRunUncheckedUpdateWithoutFormSubmissionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutInvioceLineItemInput = {
  create: AutomationRunUncheckedCreateWithoutInvioceLineItemInput;
  update: AutomationRunUncheckedUpdateWithoutInvioceLineItemInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutManuallySentByInput = {
  create: AutomationRunUncheckedCreateWithoutManuallySentByInput;
  update: AutomationRunUncheckedUpdateWithoutManuallySentByInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutOriginAutomationInput = {
  create: AutomationRunUncheckedCreateWithoutOriginAutomationInput;
  update: AutomationRunUncheckedUpdateWithoutOriginAutomationInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutReturnToAutomationActionInput = {
  create: AutomationRunUncheckedCreateWithoutReturnToAutomationActionInput;
  update: AutomationRunUncheckedUpdateWithoutReturnToAutomationActionInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithWhereUniqueWithoutServiceReminderInput = {
  create: AutomationRunUncheckedCreateWithoutServiceReminderInput;
  update: AutomationRunUncheckedUpdateWithoutServiceReminderInput;
  where: AutomationRunWhereUniqueInput;
};

export type AutomationRunUpsertWithoutActionsInput = {
  create: AutomationRunUncheckedCreateWithoutActionsInput;
  update: AutomationRunUncheckedUpdateWithoutActionsInput;
};

export type AutomationRunUpsertWithoutChannelMessageInput = {
  create: AutomationRunUncheckedCreateWithoutChannelMessageInput;
  update: AutomationRunUncheckedUpdateWithoutChannelMessageInput;
};

export type AutomationRunUpsertWithoutHillsToHomeApiLogInput = {
  create: AutomationRunUncheckedCreateWithoutHillsToHomeApiLogInput;
  update: AutomationRunUncheckedUpdateWithoutHillsToHomeApiLogInput;
};

export type AutomationRunUpsertWithoutSurveyResultsInput = {
  create: AutomationRunUncheckedCreateWithoutSurveyResultsInput;
  update: AutomationRunUncheckedUpdateWithoutSurveyResultsInput;
};

export type AutomationRunWhereInput = {
  AND?: Maybe<Array<AutomationRunWhereInput>>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  NOT?: Maybe<Array<AutomationRunWhereInput>>;
  OR?: Maybe<Array<AutomationRunWhereInput>>;
  actions?: Maybe<AutomationRunActionListRelationFilter>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  automationEvent?: Maybe<EnumWorkflowEventFilter>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  channelMessageId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invioceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
  manuallySentBy?: Maybe<UserWhereInput>;
  manuallySentById?: Maybe<StringNullableFilter>;
  originAutomation?: Maybe<WorkflowEventSettingWhereInput>;
  originAutomationId?: Maybe<StringFilter>;
  returnToAutomationAction?: Maybe<WorkflowEventActionWhereInput>;
  returnToAutomationActionId?: Maybe<StringNullableFilter>;
  runType?: Maybe<EnumAutomationRunTypeFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAutomationRunStatusFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AutomationRunWhereUniqueInput = {
  channelMessageId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum AutomationStatus {
  Cancelled = 'Cancelled',
  ClientVisitAlreadyInitiated = 'ClientVisitAlreadyInitiated',
  Completed = 'Completed',
  Expired = 'Expired',
  Paused = 'Paused',
  Queued = 'Queued',
  Started = 'Started'
}

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};

export type BenefitSource = {
  __typename?: 'BenefitSource';
  addonIds: Array<Scalars['String']>;
  planIds: Array<Scalars['String']>;
};

export type BitwerxClientBalance = {
  __typename?: 'BitwerxClientBalance';
  aging30D?: Maybe<Scalars['Float']>;
  aging60D?: Maybe<Scalars['Float']>;
  aging90D?: Maybe<Scalars['Float']>;
  aging120D?: Maybe<Scalars['Float']>;
  agingCurrent?: Maybe<Scalars['Float']>;
  currentBalance?: Maybe<Scalars['Float']>;
};

export type BitwerxHealthcheck = {
  __typename?: 'BitwerxHealthcheck';
  _count: BitwerxHealthcheckCountOutputType;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  paymentHealthcheckRuns: Array<BitwerxPaymentHealthcheckRun>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt: Scalars['DateTime'];
};


export type BitwerxHealthcheckPaymentHealthcheckRunsArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};

export type BitwerxHealthcheckCountAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  endsAt: Scalars['Int'];
  id: Scalars['Int'];
  isEnabled: Scalars['Int'];
  startsAt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BitwerxHealthcheckCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckCountOutputType = {
  __typename?: 'BitwerxHealthcheckCountOutputType';
  paymentHealthcheckRuns: Scalars['Int'];
};

export type BitwerxHealthcheckCreateInput = {
  clinic: ClinicCreateNestedOneWithoutBitwerxHealthchecksInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckCreateManyClinicInputEnvelope = {
  data: Array<BitwerxHealthcheckCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxHealthcheckCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
};

export type BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput = {
  connect?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput>;
  create?: Maybe<BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput>;
};

export type BitwerxHealthcheckCreateOrConnectWithoutClinicInput = {
  create: BitwerxHealthcheckUncheckedCreateWithoutClinicInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};

export type BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput = {
  create: BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};

export type BitwerxHealthcheckCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckCreateWithoutPaymentHealthcheckRunsInput = {
  clinic: ClinicCreateNestedOneWithoutBitwerxHealthchecksInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckListRelationFilter = {
  every?: Maybe<BitwerxHealthcheckWhereInput>;
  none?: Maybe<BitwerxHealthcheckWhereInput>;
  some?: Maybe<BitwerxHealthcheckWhereInput>;
};

export type BitwerxHealthcheckMaxAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckMinAggregateOutputType = {
  __typename?: 'BitwerxHealthcheckMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxHealthcheckOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type BitwerxHealthcheckOrderByRelevanceInput = {
  fields: Array<BitwerxHealthcheckOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BitwerxHealthcheckOrderByWithAggregationInput = {
  _count?: Maybe<BitwerxHealthcheckCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxHealthcheckMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxHealthcheckMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BitwerxHealthcheckOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunOrderByRelationAggregateInput>;
  startsAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxHealthcheckRelationFilter = {
  is?: Maybe<BitwerxHealthcheckWhereInput>;
  isNot?: Maybe<BitwerxHealthcheckWhereInput>;
};

export enum BitwerxHealthcheckRunStatus {
  Healthy = 'Healthy',
  Mixed = 'Mixed',
  NoResults = 'NoResults',
  Unhealthy = 'Unhealthy'
}

export enum BitwerxHealthcheckScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  EndsAt = 'endsAt',
  Id = 'id',
  IsEnabled = 'isEnabled',
  StartsAt = 'startsAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type BitwerxHealthcheckScalarWhereInput = {
  AND?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxHealthcheckScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum BitwerxHealthcheckType {
  Payment = 'Payment'
}

export type BitwerxHealthcheckUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
};

export type BitwerxHealthcheckUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt: Scalars['DateTime'];
  type: BitwerxHealthcheckType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedUpdateManyWithoutBitwerxHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type BitwerxHealthcheckUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput = {
  data: BitwerxHealthcheckUncheckedUpdateManyWithoutBitwerxHealthchecksInput;
  where: BitwerxHealthcheckScalarWhereInput;
};

export type BitwerxHealthcheckUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxHealthcheckCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BitwerxHealthcheckCreateWithoutClinicInput>>;
  createMany?: Maybe<BitwerxHealthcheckCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxHealthcheckScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BitwerxHealthcheckUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput = {
  connect?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxHealthcheckCreateOrConnectWithoutPaymentHealthcheckRunsInput>;
  create?: Maybe<BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput>;
  update?: Maybe<BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput>;
  upsert?: Maybe<BitwerxHealthcheckUpsertWithoutPaymentHealthcheckRunsInput>;
};

export type BitwerxHealthcheckUpdateWithWhereUniqueWithoutClinicInput = {
  data: BitwerxHealthcheckUncheckedUpdateWithoutClinicInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};

export type BitwerxHealthcheckUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUpdateWithoutPaymentHealthcheckRunsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<BitwerxHealthcheckType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxHealthcheckUpsertWithWhereUniqueWithoutClinicInput = {
  create: BitwerxHealthcheckUncheckedCreateWithoutClinicInput;
  update: BitwerxHealthcheckUncheckedUpdateWithoutClinicInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};

export type BitwerxHealthcheckUpsertWithoutPaymentHealthcheckRunsInput = {
  create: BitwerxHealthcheckUncheckedCreateWithoutPaymentHealthcheckRunsInput;
  update: BitwerxHealthcheckUncheckedUpdateWithoutPaymentHealthcheckRunsInput;
};

export type BitwerxHealthcheckWhereInput = {
  AND?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  NOT?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  OR?: Maybe<Array<BitwerxHealthcheckWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  paymentHealthcheckRuns?: Maybe<BitwerxPaymentHealthcheckRunListRelationFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumBitwerxHealthcheckTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxHealthcheckWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxIntegration = {
  __typename?: 'BitwerxIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isMultiSite: Scalars['Boolean'];
  /** This property is the same as siteId on Vetdata/Datapoint, for multi-location practices */
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt: Scalars['DateTime'];
  /** This property references the PIMS PaymentType ID for writebacks */
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCountAggregateOutputType = {
  __typename?: 'BitwerxIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  locationId: Scalars['Int'];
  practiceId: Scalars['Int'];
  system: Scalars['Int'];
  updatedAt: Scalars['Int'];
  writebackPaymentTypeId: Scalars['Int'];
};

export type BitwerxIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: BitwerxIntegrationWhereUniqueInput;
};

export type BitwerxIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationMinAggregateOutputType = {
  __typename?: 'BitwerxIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export enum BitwerxIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  LocationId = 'locationId',
  PracticeId = 'practiceId',
  WritebackPaymentTypeId = 'writebackPaymentTypeId'
}

export type BitwerxIntegrationOrderByRelevanceInput = {
  fields: Array<BitwerxIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BitwerxIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<BitwerxIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BitwerxIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackPaymentTypeId?: Maybe<SortOrder>;
};

export type BitwerxIntegrationRelationFilter = {
  is?: Maybe<BitwerxIntegrationWhereInput>;
  isNot?: Maybe<BitwerxIntegrationWhereInput>;
};

export enum BitwerxIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsMultiSite = 'isMultiSite',
  LocationId = 'locationId',
  PracticeId = 'practiceId',
  System = 'system',
  UpdatedAt = 'updatedAt',
  WritebackPaymentTypeId = 'writebackPaymentTypeId'
}

export type BitwerxIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  practiceId?: Maybe<StringWithAggregatesFilter>;
  system?: Maybe<EnumBitwerxSystemWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  writebackPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type BitwerxIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId: Scalars['String'];
  system: BitwerxSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutBitwerxNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type BitwerxIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  system?: Maybe<BitwerxSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackPaymentTypeId?: Maybe<Scalars['String']>;
};

export type BitwerxIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: BitwerxIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: BitwerxIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type BitwerxIntegrationWhereInput = {
  AND?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  NOT?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  OR?: Maybe<Array<BitwerxIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  locationId?: Maybe<StringNullableFilter>;
  practiceId?: Maybe<StringFilter>;
  system?: Maybe<EnumBitwerxSystemFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  writebackPaymentTypeId?: Maybe<StringNullableFilter>;
};

export type BitwerxIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BitwerxPaymentEndpoint {
  GetBalanceForClient = 'GetBalanceForClient',
  GetInvoicesForClient = 'GetInvoicesForClient'
}

export type BitwerxPaymentHealthcheckResult = {
  __typename?: 'BitwerxPaymentHealthcheckResult';
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  healthcheckRun: BitwerxPaymentHealthcheckRun;
  httpCode?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  responseTime: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BitwerxPaymentHealthcheckResultAvgAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultAvgAggregateOutputType';
  httpCode?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckResultAvgOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultCountAggregateOutputType';
  _all: Scalars['Int'];
  bitwerxPaymentHealthcheckRunId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  endpoint: Scalars['Int'];
  error: Scalars['Int'];
  httpCode: Scalars['Int'];
  id: Scalars['Int'];
  responseTime: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckResultCountOrderByAggregateInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultCreateInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  healthcheckRun: BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInput = {
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckResultCreateManyInput = {
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
};

export type BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
};

export type BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput = {
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput = {
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  healthcheckRun: BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultListRelationFilter = {
  every?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  none?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
  some?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};

export type BitwerxPaymentHealthcheckResultMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultMaxAggregateOutputType';
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultMaxOrderByAggregateInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultMinAggregateOutputType';
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultMinOrderByAggregateInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentHealthcheckResultOrderByRelevanceFieldEnum {
  BitwerxPaymentHealthcheckRunId = 'bitwerxPaymentHealthcheckRunId',
  ClinicPetParentId = 'clinicPetParentId',
  Error = 'error',
  Id = 'id'
}

export type BitwerxPaymentHealthcheckResultOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentHealthcheckResultOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BitwerxPaymentHealthcheckResultOrderByWithAggregationInput = {
  _avg?: Maybe<BitwerxPaymentHealthcheckResultAvgOrderByAggregateInput>;
  _count?: Maybe<BitwerxPaymentHealthcheckResultCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentHealthcheckResultMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentHealthcheckResultMinOrderByAggregateInput>;
  _sum?: Maybe<BitwerxPaymentHealthcheckResultSumOrderByAggregateInput>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelevanceInput>;
  bitwerxPaymentHealthcheckRunId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endpoint?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum BitwerxPaymentHealthcheckResultScalarFieldEnum {
  BitwerxPaymentHealthcheckRunId = 'bitwerxPaymentHealthcheckRunId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Endpoint = 'endpoint',
  Error = 'error',
  HttpCode = 'httpCode',
  Id = 'id',
  ResponseTime = 'responseTime',
  UpdatedAt = 'updatedAt'
}

export type BitwerxPaymentHealthcheckResultScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointFilter>;
  error?: Maybe<StringNullableFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  responseTime?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereWithAggregatesInput>>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointWithAggregatesFilter>;
  error?: Maybe<StringNullableWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  responseTime?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BitwerxPaymentHealthcheckResultSumAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckResultSumAggregateOutputType';
  httpCode?: Maybe<Scalars['Int']>;
  responseTime?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckResultSumOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateInput = {
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput = {
  bitwerxPaymentHealthcheckRunId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint: BitwerxPaymentEndpoint;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutBitwerxPaymentHealthcheckResultsInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckResultsInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput>>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput = {
  bitwerxPaymentHealthcheckRunId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutBitwerxPaymentHealthcheckResultsInput;
  where: BitwerxPaymentHealthcheckResultScalarWhereInput;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput = {
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckResultsInput;
  where: BitwerxPaymentHealthcheckResultScalarWhereInput;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateOrConnectWithoutHealthcheckRunInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckResultCreateWithoutHealthcheckRunInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckResultCreateManyHealthcheckRunInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckResultScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckResultUpdateManyWithWhereWithoutHealthcheckRunInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput>>;
};

export type BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultUpdateWithWhereUniqueWithoutHealthcheckRunInput = {
  data: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUpdateWithoutHealthcheckRunInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endpoint?: Maybe<BitwerxPaymentEndpoint>;
  error?: Maybe<Scalars['String']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutClinicPetParentInput;
  update: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutClinicPetParentInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultUpsertWithWhereUniqueWithoutHealthcheckRunInput = {
  create: BitwerxPaymentHealthcheckResultUncheckedCreateWithoutHealthcheckRunInput;
  update: BitwerxPaymentHealthcheckResultUncheckedUpdateWithoutHealthcheckRunInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckResultWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckResultWhereInput>>;
  bitwerxPaymentHealthcheckRunId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endpoint?: Maybe<EnumBitwerxPaymentEndpointFilter>;
  error?: Maybe<StringNullableFilter>;
  healthcheckRun?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  responseTime?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxPaymentHealthcheckResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxPaymentHealthcheckRun = {
  __typename?: 'BitwerxPaymentHealthcheckRun';
  _count: BitwerxPaymentHealthcheckRunCountOutputType;
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheck;
  bitwerxHealthcheckId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  healthcheckResults: Array<BitwerxPaymentHealthcheckResult>;
  historicWeeksBack: Scalars['Int'];
  id: Scalars['String'];
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type BitwerxPaymentHealthcheckRunHealthcheckResultsArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};

export type BitwerxPaymentHealthcheckRunAvgAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunAvgAggregateOutputType';
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  historicWeeksBack?: Maybe<Scalars['Float']>;
};

export type BitwerxPaymentHealthcheckRunAvgOrderByAggregateInput = {
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunCountAggregateOutputType';
  _all: Scalars['Int'];
  averageRequestCount: Scalars['Int'];
  averageResponseTime: Scalars['Int'];
  bitwerxHealthcheckId: Scalars['Int'];
  createdAt: Scalars['Int'];
  historicWeeksBack: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  triggeredAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckRunCountOrderByAggregateInput = {
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunCountOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunCountOutputType';
  healthcheckResults: Scalars['Int'];
};

export type BitwerxPaymentHealthcheckRunCreateInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope = {
  data: Array<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentHealthcheckRunCreateManyInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunCreateNestedManyWithoutBitwerxHealthcheckInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
};

export type BitwerxPaymentHealthcheckRunCreateNestedOneWithoutHealthcheckResultsInput = {
  connect?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput>;
  create?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput>;
};

export type BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput = {
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput = {
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutHealthcheckRunInput>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunCreateWithoutHealthcheckResultsInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck: BitwerxHealthcheckCreateNestedOneWithoutPaymentHealthcheckRunsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunListRelationFilter = {
  every?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  none?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  some?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};

export type BitwerxPaymentHealthcheckRunMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunMaxAggregateOutputType';
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunMaxOrderByAggregateInput = {
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunMinAggregateOutputType';
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunMinOrderByAggregateInput = {
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentHealthcheckRunOrderByRelevanceFieldEnum {
  BitwerxHealthcheckId = 'bitwerxHealthcheckId',
  Id = 'id'
}

export type BitwerxPaymentHealthcheckRunOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentHealthcheckRunOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BitwerxPaymentHealthcheckRunOrderByWithAggregationInput = {
  _avg?: Maybe<BitwerxPaymentHealthcheckRunAvgOrderByAggregateInput>;
  _count?: Maybe<BitwerxPaymentHealthcheckRunCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentHealthcheckRunMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentHealthcheckRunMinOrderByAggregateInput>;
  _sum?: Maybe<BitwerxPaymentHealthcheckRunSumOrderByAggregateInput>;
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BitwerxPaymentHealthcheckRunOrderByRelevanceInput>;
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  bitwerxHealthcheckId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput>;
  historicWeeksBack?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunRelationFilter = {
  is?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
  isNot?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};

export enum BitwerxPaymentHealthcheckRunScalarFieldEnum {
  AverageRequestCount = 'averageRequestCount',
  AverageResponseTime = 'averageResponseTime',
  BitwerxHealthcheckId = 'bitwerxHealthcheckId',
  CreatedAt = 'createdAt',
  HistoricWeeksBack = 'historicWeeksBack',
  Id = 'id',
  Status = 'status',
  TriggeredAt = 'triggeredAt',
  UpdatedAt = 'updatedAt'
}

export type BitwerxPaymentHealthcheckRunScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  averageRequestCount?: Maybe<FloatNullableFilter>;
  averageResponseTime?: Maybe<FloatNullableFilter>;
  bitwerxHealthcheckId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  historicWeeksBack?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableFilter>;
  triggeredAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereWithAggregatesInput>>;
  averageRequestCount?: Maybe<FloatNullableWithAggregatesFilter>;
  averageResponseTime?: Maybe<FloatNullableWithAggregatesFilter>;
  bitwerxHealthcheckId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  historicWeeksBack?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  triggeredAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BitwerxPaymentHealthcheckRunSumAggregateOutputType = {
  __typename?: 'BitwerxPaymentHealthcheckRunSumAggregateOutputType';
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
};

export type BitwerxPaymentHealthcheckRunSumOrderByAggregateInput = {
  averageRequestCount?: Maybe<SortOrder>;
  averageResponseTime?: Maybe<SortOrder>;
  historicWeeksBack?: Maybe<SortOrder>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateNestedManyWithoutBitwerxHealthcheckInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutHealthcheckRunInput>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutBitwerxHealthcheckNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutPaymentHealthcheckRunsInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutHealthcheckRunNestedInput>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheckId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUpdateInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUpdateManyMutationInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput = {
  data: BitwerxPaymentHealthcheckRunUncheckedUpdateManyWithoutPaymentHealthcheckRunsInput;
  where: BitwerxPaymentHealthcheckRunScalarWhereInput;
};

export type BitwerxPaymentHealthcheckRunUpdateManyWithoutBitwerxHealthcheckNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutBitwerxHealthcheckInput>>;
  create?: Maybe<Array<BitwerxPaymentHealthcheckRunCreateWithoutBitwerxHealthcheckInput>>;
  createMany?: Maybe<BitwerxPaymentHealthcheckRunCreateManyBitwerxHealthcheckInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentHealthcheckRunScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentHealthcheckRunUpdateManyWithWhereWithoutBitwerxHealthcheckInput>>;
  upsert?: Maybe<Array<BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput>>;
};

export type BitwerxPaymentHealthcheckRunUpdateOneRequiredWithoutHealthcheckResultsNestedInput = {
  connect?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  connectOrCreate?: Maybe<BitwerxPaymentHealthcheckRunCreateOrConnectWithoutHealthcheckResultsInput>;
  create?: Maybe<BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput>;
  update?: Maybe<BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput>;
  upsert?: Maybe<BitwerxPaymentHealthcheckRunUpsertWithoutHealthcheckResultsInput>;
};

export type BitwerxPaymentHealthcheckRunUpdateWithWhereUniqueWithoutBitwerxHealthcheckInput = {
  data: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckRunUpdateWithoutBitwerxHealthcheckInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutHealthcheckRunNestedInput>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUpdateWithoutHealthcheckResultsInput = {
  averageRequestCount?: Maybe<Scalars['Float']>;
  averageResponseTime?: Maybe<Scalars['Float']>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckUpdateOneRequiredWithoutPaymentHealthcheckRunsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  historicWeeksBack?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BitwerxHealthcheckRunStatus>;
  triggeredAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BitwerxPaymentHealthcheckRunUpsertWithWhereUniqueWithoutBitwerxHealthcheckInput = {
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutBitwerxHealthcheckInput;
  update: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutBitwerxHealthcheckInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};

export type BitwerxPaymentHealthcheckRunUpsertWithoutHealthcheckResultsInput = {
  create: BitwerxPaymentHealthcheckRunUncheckedCreateWithoutHealthcheckResultsInput;
  update: BitwerxPaymentHealthcheckRunUncheckedUpdateWithoutHealthcheckResultsInput;
};

export type BitwerxPaymentHealthcheckRunWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentHealthcheckRunWhereInput>>;
  averageRequestCount?: Maybe<FloatNullableFilter>;
  averageResponseTime?: Maybe<FloatNullableFilter>;
  bitwerxHealthcheck?: Maybe<BitwerxHealthcheckWhereInput>;
  bitwerxHealthcheckId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  healthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultListRelationFilter>;
  historicWeeksBack?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumBitwerxHealthcheckRunStatusNullableFilter>;
  triggeredAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BitwerxPaymentHealthcheckRunWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BitwerxPaymentWritebackConfiguration = {
  __typename?: 'BitwerxPaymentWritebackConfiguration';
  amexPimsPaymentType?: Maybe<PimsPaymentType>;
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  /** enablement of retries for balance writebacks */
  balanceRetriesEnabled: Scalars['Boolean'];
  careDiscountPimsPaymentType?: Maybe<PimsPaymentType>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  /** enablement of Care discount writebacks */
  careEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultPimsPaymentType?: Maybe<PimsPaymentType>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentType?: Maybe<PimsPaymentType>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredBy?: Maybe<ClinicEmployee>;
  enteredById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  /** global enablement of writebacks */
  isEnabled: Scalars['Boolean'];
  mastercardPimsPaymentType?: Maybe<PimsPaymentType>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  /** enablement of payment writebacks */
  paymentsEnabled: Scalars['Boolean'];
  /** enablement of retries */
  retriesEnabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  visaPimsPaymentType?: Maybe<PimsPaymentType>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  /** are we writing back payments for amounts that are manually entered (replacing "Add credit to account" input) */
  writebackManualPayment: Scalars['Boolean'];
};

export type BitwerxPaymentWritebackConfigurationCountAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationCountAggregateOutputType';
  _all: Scalars['Int'];
  amexPimsPaymentTypeId: Scalars['Int'];
  balanceRetriesEnabled: Scalars['Int'];
  careDiscountPimsPaymentTypeId: Scalars['Int'];
  careEnabled: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultPimsPaymentTypeId: Scalars['Int'];
  discoverPimsPaymentTypeId: Scalars['Int'];
  enteredById: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isEnabled: Scalars['Int'];
  mastercardPimsPaymentTypeId: Scalars['Int'];
  paymentsEnabled: Scalars['Int'];
  retriesEnabled: Scalars['Int'];
  updatedAt: Scalars['Int'];
  visaPimsPaymentTypeId: Scalars['Int'];
  writebackManualPayment: Scalars['Int'];
};

export type BitwerxPaymentWritebackConfigurationCountOrderByAggregateInput = {
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationCreateInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyEnteredByInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyIntegrationInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope = {
  data: Array<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationListRelationFilter = {
  every?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  none?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
  some?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};

export type BitwerxPaymentWritebackConfigurationMaxAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationMaxAggregateOutputType';
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationMaxOrderByAggregateInput = {
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationMinAggregateOutputType = {
  __typename?: 'BitwerxPaymentWritebackConfigurationMinAggregateOutputType';
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationMinOrderByAggregateInput = {
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BitwerxPaymentWritebackConfigurationOrderByRelevanceFieldEnum {
  AmexPimsPaymentTypeId = 'amexPimsPaymentTypeId',
  CareDiscountPimsPaymentTypeId = 'careDiscountPimsPaymentTypeId',
  DefaultPimsPaymentTypeId = 'defaultPimsPaymentTypeId',
  DiscoverPimsPaymentTypeId = 'discoverPimsPaymentTypeId',
  EnteredById = 'enteredById',
  Id = 'id',
  IntegrationId = 'integrationId',
  MastercardPimsPaymentTypeId = 'mastercardPimsPaymentTypeId',
  VisaPimsPaymentTypeId = 'visaPimsPaymentTypeId'
}

export type BitwerxPaymentWritebackConfigurationOrderByRelevanceInput = {
  fields: Array<BitwerxPaymentWritebackConfigurationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BitwerxPaymentWritebackConfigurationOrderByWithAggregationInput = {
  _count?: Maybe<BitwerxPaymentWritebackConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<BitwerxPaymentWritebackConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<BitwerxPaymentWritebackConfigurationMinOrderByAggregateInput>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
};

export type BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelevanceInput>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  amexPimsPaymentTypeId?: Maybe<SortOrder>;
  balanceRetriesEnabled?: Maybe<SortOrder>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  careDiscountPimsPaymentTypeId?: Maybe<SortOrder>;
  careEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  defaultPimsPaymentTypeId?: Maybe<SortOrder>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  discoverPimsPaymentTypeId?: Maybe<SortOrder>;
  enteredBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  enteredById?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  mastercardPimsPaymentTypeId?: Maybe<SortOrder>;
  paymentsEnabled?: Maybe<SortOrder>;
  retriesEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  visaPimsPaymentTypeId?: Maybe<SortOrder>;
  writebackManualPayment?: Maybe<SortOrder>;
};

export enum BitwerxPaymentWritebackConfigurationScalarFieldEnum {
  AmexPimsPaymentTypeId = 'amexPimsPaymentTypeId',
  BalanceRetriesEnabled = 'balanceRetriesEnabled',
  CareDiscountPimsPaymentTypeId = 'careDiscountPimsPaymentTypeId',
  CareEnabled = 'careEnabled',
  CreatedAt = 'createdAt',
  DefaultPimsPaymentTypeId = 'defaultPimsPaymentTypeId',
  DiscoverPimsPaymentTypeId = 'discoverPimsPaymentTypeId',
  EnteredById = 'enteredById',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsEnabled = 'isEnabled',
  MastercardPimsPaymentTypeId = 'mastercardPimsPaymentTypeId',
  PaymentsEnabled = 'paymentsEnabled',
  RetriesEnabled = 'retriesEnabled',
  UpdatedAt = 'updatedAt',
  VisaPimsPaymentTypeId = 'visaPimsPaymentTypeId',
  WritebackManualPayment = 'writebackManualPayment'
}

export type BitwerxPaymentWritebackConfigurationScalarWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  amexPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  balanceRetriesEnabled?: Maybe<BoolFilter>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  careEnabled?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  paymentsEnabled?: Maybe<BoolFilter>;
  retriesEnabled?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visaPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  writebackManualPayment?: Maybe<BoolFilter>;
};

export type BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereWithAggregatesInput>>;
  amexPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  balanceRetriesEnabled?: Maybe<BoolWithAggregatesFilter>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  careEnabled?: Maybe<BoolWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  enteredById?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentsEnabled?: Maybe<BoolWithAggregatesFilter>;
  retriesEnabled?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  visaPimsPaymentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  writebackManualPayment?: Maybe<BoolWithAggregatesFilter>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentTypeId?: Maybe<Scalars['String']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput = {
  amexPimsPaymentTypeId?: Maybe<Scalars['String']>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentTypeId?: Maybe<Scalars['String']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentTypeId?: Maybe<Scalars['String']>;
  discoverPimsPaymentTypeId?: Maybe<Scalars['String']>;
  enteredById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentTypeId?: Maybe<Scalars['String']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyMutationInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
  where: BitwerxPaymentWritebackConfigurationScalarWhereInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutAmexPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutAmexPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyAmexPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutAmexPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutCareDiscountPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutCareDiscountPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyCareDiscountPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutCareDiscountPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDefaultPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDefaultPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDefaultPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDefaultPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutDiscoverPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutDiscoverPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyDiscoverPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutDiscoverPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutEnteredByInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyEnteredByInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutEnteredByInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutMastercardPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutMastercardPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyMastercardPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutMastercardPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput = {
  connect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateOrConnectWithoutVisaPimsPaymentTypeInput>>;
  create?: Maybe<Array<BitwerxPaymentWritebackConfigurationCreateWithoutVisaPimsPaymentTypeInput>>;
  createMany?: Maybe<BitwerxPaymentWritebackConfigurationCreateManyVisaPimsPaymentTypeInputEnvelope>;
  delete?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
  updateMany?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpdateManyWithWhereWithoutVisaPimsPaymentTypeInput>>;
  upsert?: Maybe<Array<BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput>>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutAmexPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDefaultPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutEnteredByInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutMastercardPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithWhereUniqueWithoutVisaPimsPaymentTypeInput = {
  data: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutAmexPimsPaymentTypeInput = {
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutCareDiscountPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutDefaultPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutDiscoverPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutEnteredByInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutIntegrationInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutMastercardPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpdateWithoutVisaPimsPaymentTypeInput = {
  amexPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput>;
  balanceRetriesEnabled?: Maybe<Scalars['Boolean']>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput>;
  careEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput>;
  paymentsEnabled?: Maybe<Scalars['Boolean']>;
  retriesEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackManualPayment?: Maybe<Scalars['Boolean']>;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutAmexPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutAmexPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutAmexPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutCareDiscountPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutCareDiscountPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutCareDiscountPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDefaultPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDefaultPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDefaultPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutDiscoverPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutDiscoverPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutDiscoverPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutEnteredByInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutEnteredByInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutEnteredByInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutIntegrationInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutIntegrationInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutMastercardPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutMastercardPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutMastercardPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationUpsertWithWhereUniqueWithoutVisaPimsPaymentTypeInput = {
  create: BitwerxPaymentWritebackConfigurationUncheckedCreateWithoutVisaPimsPaymentTypeInput;
  update: BitwerxPaymentWritebackConfigurationUncheckedUpdateWithoutVisaPimsPaymentTypeInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};

export type BitwerxPaymentWritebackConfigurationWhereInput = {
  AND?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  NOT?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  OR?: Maybe<Array<BitwerxPaymentWritebackConfigurationWhereInput>>;
  amexPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  amexPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  balanceRetriesEnabled?: Maybe<BoolFilter>;
  careDiscountPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  careDiscountPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  careEnabled?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  defaultPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  discoverPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  discoverPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  enteredBy?: Maybe<ClinicEmployeeWhereInput>;
  enteredById?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  mastercardPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  mastercardPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  paymentsEnabled?: Maybe<BoolFilter>;
  retriesEnabled?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visaPimsPaymentType?: Maybe<PimsPaymentTypeWhereInput>;
  visaPimsPaymentTypeId?: Maybe<StringNullableFilter>;
  writebackManualPayment?: Maybe<BoolFilter>;
};

export type BitwerxPaymentWritebackConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
};

export enum BitwerxSystem {
  Avimark = 'Avimark',
  Cornerstone = 'Cornerstone',
  Impromed = 'Impromed'
}

export type BoardFilterColumn = {
  __typename?: 'BoardFilterColumn';
  channelStatus?: Maybe<ChannelStatus>;
  channelStatusId: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  id: Scalars['String'];
  type: BoardFilterColumnType;
  userBoardFilter?: Maybe<UserBoardFilter>;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnCountAggregateOutputType = {
  __typename?: 'BoardFilterColumnCountAggregateOutputType';
  _all: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  clinicId: Scalars['Int'];
  id: Scalars['Int'];
  type: Scalars['Int'];
  userBoardFilterId: Scalars['Int'];
};

export type BoardFilterColumnCountOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterColumnCreateInput = {
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
};

export type BoardFilterColumnCreateManyChannelStatusInput = {
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnCreateManyChannelStatusInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateManyClinicInput = {
  channelStatusId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnCreateManyClinicInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateManyInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnCreateManyUserBoardFilterInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
};

export type BoardFilterColumnCreateManyUserBoardFilterInputEnvelope = {
  data: Array<BoardFilterColumnCreateManyUserBoardFilterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoardFilterColumnCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
};

export type BoardFilterColumnCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
};

export type BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
};

export type BoardFilterColumnCreateOrConnectWithoutChannelStatusInput = {
  create: BoardFilterColumnUncheckedCreateWithoutChannelStatusInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnCreateOrConnectWithoutClinicInput = {
  create: BoardFilterColumnUncheckedCreateWithoutClinicInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput = {
  create: BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnCreateWithoutChannelStatusInput = {
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
};

export type BoardFilterColumnCreateWithoutClinicInput = {
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutColumnsInput>;
};

export type BoardFilterColumnCreateWithoutUserBoardFilterInput = {
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput>;
  clinic: ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput;
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
};

export type BoardFilterColumnListRelationFilter = {
  every?: Maybe<BoardFilterColumnWhereInput>;
  none?: Maybe<BoardFilterColumnWhereInput>;
  some?: Maybe<BoardFilterColumnWhereInput>;
};

export type BoardFilterColumnMaxAggregateOutputType = {
  __typename?: 'BoardFilterColumnMaxAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnMaxOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterColumnMinAggregateOutputType = {
  __typename?: 'BoardFilterColumnMinAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnMinOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterColumnOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoardFilterColumnOrderByRelevanceFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId',
  Id = 'id',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterColumnOrderByRelevanceInput = {
  fields: Array<BoardFilterColumnOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BoardFilterColumnOrderByWithAggregationInput = {
  _count?: Maybe<BoardFilterColumnCountOrderByAggregateInput>;
  _max?: Maybe<BoardFilterColumnMaxOrderByAggregateInput>;
  _min?: Maybe<BoardFilterColumnMinOrderByAggregateInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BoardFilterColumnOrderByRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  userBoardFilter?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export enum BoardFilterColumnScalarFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId',
  Id = 'id',
  Type = 'type',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterColumnScalarWhereInput = {
  AND?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  NOT?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  OR?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  channelStatusId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeFilter>;
  userBoardFilterId?: Maybe<StringFilter>;
};

export type BoardFilterColumnScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoardFilterColumnScalarWhereWithAggregatesInput>>;
  channelStatusId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeWithAggregatesFilter>;
  userBoardFilterId?: Maybe<StringWithAggregatesFilter>;
};

export enum BoardFilterColumnType {
  Collapsed = 'Collapsed',
  Selected = 'Selected'
}

export type BoardFilterColumnUncheckedCreateInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
};

export type BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
};

export type BoardFilterColumnUncheckedCreateWithoutChannelStatusInput = {
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateWithoutClinicInput = {
  channelStatusId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: BoardFilterColumnType;
};

export type BoardFilterColumnUncheckedUpdateInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutBoardFilterColumnsInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutColumnsInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput>>;
};

export type BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFiltersColumnsInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutClinicInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
};

export type BoardFilterColumnUpdateInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
};

export type BoardFilterColumnUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput = {
  data: BoardFilterColumnUncheckedUpdateManyWithoutBoardFilterColumnsInput;
  where: BoardFilterColumnScalarWhereInput;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutClinicInput = {
  data: BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFiltersColumnsInput;
  where: BoardFilterColumnScalarWhereInput;
};

export type BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput = {
  data: BoardFilterColumnUncheckedUpdateManyWithoutColumnsInput;
  where: BoardFilterColumnScalarWhereInput;
};

export type BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type BoardFilterColumnUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutClinicInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput = {
  connect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoardFilterColumnCreateOrConnectWithoutUserBoardFilterInput>>;
  create?: Maybe<Array<BoardFilterColumnCreateWithoutUserBoardFilterInput>>;
  createMany?: Maybe<BoardFilterColumnCreateManyUserBoardFilterInputEnvelope>;
  delete?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoardFilterColumnScalarWhereInput>>;
  disconnect?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  set?: Maybe<Array<BoardFilterColumnWhereUniqueInput>>;
  update?: Maybe<Array<BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput>>;
  updateMany?: Maybe<Array<BoardFilterColumnUpdateManyWithWhereWithoutUserBoardFilterInput>>;
  upsert?: Maybe<Array<BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput>>;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutChannelStatusInput = {
  data: BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutClinicInput = {
  data: BoardFilterColumnUncheckedUpdateWithoutClinicInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnUpdateWithWhereUniqueWithoutUserBoardFilterInput = {
  data: BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnUpdateWithoutChannelStatusInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
};

export type BoardFilterColumnUpdateWithoutClinicInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutColumnsNestedInput>;
};

export type BoardFilterColumnUpdateWithoutUserBoardFilterInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BoardFilterColumnType>;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutChannelStatusInput = {
  create: BoardFilterColumnUncheckedCreateWithoutChannelStatusInput;
  update: BoardFilterColumnUncheckedUpdateWithoutChannelStatusInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutClinicInput = {
  create: BoardFilterColumnUncheckedCreateWithoutClinicInput;
  update: BoardFilterColumnUncheckedUpdateWithoutClinicInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnUpsertWithWhereUniqueWithoutUserBoardFilterInput = {
  create: BoardFilterColumnUncheckedCreateWithoutUserBoardFilterInput;
  update: BoardFilterColumnUncheckedUpdateWithoutUserBoardFilterInput;
  where: BoardFilterColumnWhereUniqueInput;
};

export type BoardFilterColumnWhereInput = {
  AND?: Maybe<Array<BoardFilterColumnWhereInput>>;
  NOT?: Maybe<Array<BoardFilterColumnWhereInput>>;
  OR?: Maybe<Array<BoardFilterColumnWhereInput>>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  channelStatusId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumBoardFilterColumnTypeFilter>;
  userBoardFilter?: Maybe<UserBoardFilterWhereInput>;
  userBoardFilterId?: Maybe<StringFilter>;
};

export type BoardFilterColumnWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BoardFilterDateRange {
  ThisWeek = 'thisWeek',
  Today = 'today',
  Tomorrow = 'tomorrow',
  Yesterday = 'yesterday'
}

export type BoardFilterSpecificDateRange = {
  __typename?: 'BoardFilterSpecificDateRange';
  gte: Scalars['DateTime'];
  id: Scalars['String'];
  lte: Scalars['DateTime'];
  userBoardFilter?: Maybe<UserBoardFilter>;
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterSpecificDateRangeCountAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeCountAggregateOutputType';
  _all: Scalars['Int'];
  gte: Scalars['Int'];
  id: Scalars['Int'];
  lte: Scalars['Int'];
  userBoardFilterId: Scalars['Int'];
};

export type BoardFilterSpecificDateRangeCountOrderByAggregateInput = {
  gte?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeCreateInput = {
  gte: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  lte: Scalars['DateTime'];
  userBoardFilter?: Maybe<UserBoardFilterCreateNestedOneWithoutSpecificDateRangeInput>;
};

export type BoardFilterSpecificDateRangeCreateManyInput = {
  gte: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  lte: Scalars['DateTime'];
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput = {
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput = {
  create: BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput;
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};

export type BoardFilterSpecificDateRangeCreateWithoutUserBoardFilterInput = {
  gte: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  lte: Scalars['DateTime'];
};

export type BoardFilterSpecificDateRangeMaxAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeMaxAggregateOutputType';
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeMaxOrderByAggregateInput = {
  gte?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeMinAggregateOutputType = {
  __typename?: 'BoardFilterSpecificDateRangeMinAggregateOutputType';
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeMinOrderByAggregateInput = {
  gte?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export enum BoardFilterSpecificDateRangeOrderByRelevanceFieldEnum {
  Id = 'id',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterSpecificDateRangeOrderByRelevanceInput = {
  fields: Array<BoardFilterSpecificDateRangeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BoardFilterSpecificDateRangeOrderByWithAggregationInput = {
  _count?: Maybe<BoardFilterSpecificDateRangeCountOrderByAggregateInput>;
  _max?: Maybe<BoardFilterSpecificDateRangeMaxOrderByAggregateInput>;
  _min?: Maybe<BoardFilterSpecificDateRangeMinOrderByAggregateInput>;
  gte?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BoardFilterSpecificDateRangeOrderByRelevanceInput>;
  gte?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lte?: Maybe<SortOrder>;
  userBoardFilter?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  userBoardFilterId?: Maybe<SortOrder>;
};

export type BoardFilterSpecificDateRangeRelationFilter = {
  is?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  isNot?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};

export enum BoardFilterSpecificDateRangeScalarFieldEnum {
  Gte = 'gte',
  Id = 'id',
  Lte = 'lte',
  UserBoardFilterId = 'userBoardFilterId'
}

export type BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoardFilterSpecificDateRangeScalarWhereWithAggregatesInput>>;
  gte?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lte?: Maybe<DateTimeWithAggregatesFilter>;
  userBoardFilterId?: Maybe<StringWithAggregatesFilter>;
};

export type BoardFilterSpecificDateRangeUncheckedCreateInput = {
  gte: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  lte: Scalars['DateTime'];
  userBoardFilterId: Scalars['String'];
};

export type BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput = {
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput = {
  gte: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  lte: Scalars['DateTime'];
};

export type BoardFilterSpecificDateRangeUncheckedUpdateInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateManyInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput = {
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput>;
  upsert?: Maybe<BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpdateInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
  userBoardFilter?: Maybe<UserBoardFilterUpdateOneWithoutSpecificDateRangeNestedInput>;
};

export type BoardFilterSpecificDateRangeUpdateManyMutationInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput = {
  connect?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  connectOrCreate?: Maybe<BoardFilterSpecificDateRangeCreateOrConnectWithoutUserBoardFilterInput>;
  create?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput>;
  upsert?: Maybe<BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput>;
};

export type BoardFilterSpecificDateRangeUpdateWithoutUserBoardFilterInput = {
  gte?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['DateTime']>;
};

export type BoardFilterSpecificDateRangeUpsertWithoutUserBoardFilterInput = {
  create: BoardFilterSpecificDateRangeUncheckedCreateWithoutUserBoardFilterInput;
  update: BoardFilterSpecificDateRangeUncheckedUpdateWithoutUserBoardFilterInput;
};

export type BoardFilterSpecificDateRangeWhereInput = {
  AND?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  NOT?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  OR?: Maybe<Array<BoardFilterSpecificDateRangeWhereInput>>;
  gte?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  lte?: Maybe<DateTimeFilter>;
  userBoardFilter?: Maybe<UserBoardFilterWhereInput>;
  userBoardFilterId?: Maybe<StringFilter>;
};

export type BoardFilterSpecificDateRangeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userBoardFilterId?: Maybe<Scalars['String']>;
};

export enum BoardFilterView {
  Appointments = 'appointments',
  Automations = 'automations',
  Conversations = 'conversations'
}

export type BoehringerIngelheimCustomerAlignment = {
  __typename?: 'BoehringerIngelheimCustomerAlignment';
  boehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  boehringerIngelheimTerritoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  informPartnerCustomer: InformPartnerCustomer;
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentAvgAggregateOutputType';
  soldToId?: Maybe<Scalars['Float']>;
};

export type BoehringerIngelheimCustomerAlignmentAvgOrderByAggregateInput = {
  soldToId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentCountAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentCountAggregateOutputType';
  _all: Scalars['Int'];
  boehringerIngelheimTerritoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  rawData: Scalars['Int'];
  soldToId: Scalars['Int'];
  territoryName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BoehringerIngelheimCustomerAlignmentCountOrderByAggregateInput = {
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentCreateInput = {
  boehringerIngelheimTerritory: BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope = {
  data: Array<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInput = {
  boehringerIngelheimTerritoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateManyInput = {
  boehringerIngelheimTerritoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
};

export type BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
};

export type BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput = {
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput = {
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput = {
  boehringerIngelheimTerritory: BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentListRelationFilter = {
  every?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  none?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
  some?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};

export type BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentMaxAggregateOutputType';
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentMaxOrderByAggregateInput = {
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentMinAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentMinAggregateOutputType';
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentMinOrderByAggregateInput = {
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoehringerIngelheimCustomerAlignmentOrderByRelevanceFieldEnum {
  BoehringerIngelheimTerritoryId = 'boehringerIngelheimTerritoryId',
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  TerritoryName = 'territoryName'
}

export type BoehringerIngelheimCustomerAlignmentOrderByRelevanceInput = {
  fields: Array<BoehringerIngelheimCustomerAlignmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BoehringerIngelheimCustomerAlignmentOrderByWithAggregationInput = {
  _avg?: Maybe<BoehringerIngelheimCustomerAlignmentAvgOrderByAggregateInput>;
  _count?: Maybe<BoehringerIngelheimCustomerAlignmentCountOrderByAggregateInput>;
  _max?: Maybe<BoehringerIngelheimCustomerAlignmentMaxOrderByAggregateInput>;
  _min?: Maybe<BoehringerIngelheimCustomerAlignmentMinOrderByAggregateInput>;
  _sum?: Maybe<BoehringerIngelheimCustomerAlignmentSumOrderByAggregateInput>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelevanceInput>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  boehringerIngelheimTerritoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  soldToId?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum BoehringerIngelheimCustomerAlignmentScalarFieldEnum {
  BoehringerIngelheimTerritoryId = 'boehringerIngelheimTerritoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  RawData = 'rawData',
  SoldToId = 'soldToId',
  TerritoryName = 'territoryName',
  UpdatedAt = 'updatedAt'
}

export type BoehringerIngelheimCustomerAlignmentScalarWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  boehringerIngelheimTerritoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  soldToId?: Maybe<IntFilter>;
  territoryName?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereWithAggregatesInput>>;
  boehringerIngelheimTerritoryId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
  soldToId?: Maybe<IntWithAggregatesFilter>;
  territoryName?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BoehringerIngelheimCustomerAlignmentSoldToIdTerritoryNameCompoundUniqueInput = {
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
};

export type BoehringerIngelheimCustomerAlignmentSumAggregateOutputType = {
  __typename?: 'BoehringerIngelheimCustomerAlignmentSumAggregateOutputType';
  soldToId?: Maybe<Scalars['Int']>;
};

export type BoehringerIngelheimCustomerAlignmentSumOrderByAggregateInput = {
  soldToId?: Maybe<SortOrder>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateInput = {
  boehringerIngelheimTerritoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput = {
  boehringerIngelheimTerritoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId: Scalars['Int'];
  territoryName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateInput = {
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyInput = {
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput = {
  boehringerIngelheimTerritoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateInput = {
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput = {
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput;
  where: BoehringerIngelheimCustomerAlignmentScalarWhereInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimCustomerAlignmentsInput;
  where: BoehringerIngelheimCustomerAlignmentScalarWhereInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutBoehringerIngelheimTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutBoehringerIngelheimTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyBoehringerIngelheimTerritoryInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutBoehringerIngelheimTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<BoehringerIngelheimCustomerAlignmentCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<BoehringerIngelheimCustomerAlignmentCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput = {
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  data: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithoutBoehringerIngelheimTerritoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUpdateWithoutInformPartnerCustomerInput = {
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  soldToId?: Maybe<Scalars['Int']>;
  territoryName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoryInput = {
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutBoehringerIngelheimTerritoryInput;
  update: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutBoehringerIngelheimTerritoryInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  create: BoehringerIngelheimCustomerAlignmentUncheckedCreateWithoutInformPartnerCustomerInput;
  update: BoehringerIngelheimCustomerAlignmentUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};

export type BoehringerIngelheimCustomerAlignmentWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimCustomerAlignmentWhereInput>>;
  boehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  boehringerIngelheimTerritoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  soldToId?: Maybe<IntFilter>;
  territoryName?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BoehringerIngelheimCustomerAlignmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  soldToId_territoryName?: Maybe<BoehringerIngelheimCustomerAlignmentSoldToIdTerritoryNameCompoundUniqueInput>;
};

export type BoehringerIngelheimTerritory = {
  __typename?: 'BoehringerIngelheimTerritory';
  _count: BoehringerIngelheimTerritoryCountOutputType;
  boehringerIngelheimCustomerAlignments: Array<BoehringerIngelheimCustomerAlignment>;
  childTerritories: Array<BoehringerIngelheimTerritory>;
  countryCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informPartnerUsers: Array<InformPartnerUser>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritory>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type BoehringerIngelheimTerritoryBoehringerIngelheimCustomerAlignmentsArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  distinct?: Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>;
  orderBy?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type BoehringerIngelheimTerritoryChildTerritoriesArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  distinct?: Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>;
  orderBy?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type BoehringerIngelheimTerritoryInformPartnerUsersArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};

export type BoehringerIngelheimTerritoryCountAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryCountAggregateOutputType';
  _all: Scalars['Int'];
  countryCode: Scalars['Int'];
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  enterpriseSegment: Scalars['Int'];
  id: Scalars['Int'];
  parentTerritoryId: Scalars['Int'];
  parentTerritoryName: Scalars['Int'];
  startDate: Scalars['Int'];
  territoryDescription: Scalars['Int'];
  territoryName: Scalars['Int'];
  territoryType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BoehringerIngelheimTerritoryCountOrderByAggregateInput = {
  countryCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryCountOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryCountOutputType';
  boehringerIngelheimCustomerAlignments: Scalars['Int'];
  childTerritories: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
};

export type BoehringerIngelheimTerritoryCreateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateManyInput = {
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateManyParentTerritoryInput = {
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope = {
  data: Array<BoehringerIngelheimTerritoryCreateManyParentTerritoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
};

export type BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
};

export type BoehringerIngelheimTerritoryCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput = {
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput>;
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput>;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateWithoutChildTerritoriesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryCreateNestedOneWithoutChildTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryListRelationFilter = {
  every?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  none?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  some?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};

export type BoehringerIngelheimTerritoryMaxAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryMaxAggregateOutputType';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryMaxOrderByAggregateInput = {
  countryCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryMinAggregateOutputType = {
  __typename?: 'BoehringerIngelheimTerritoryMinAggregateOutputType';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryMinOrderByAggregateInput = {
  countryCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BoehringerIngelheimTerritoryOrderByRelevanceFieldEnum {
  CountryCode = 'countryCode',
  EnterpriseSegment = 'enterpriseSegment',
  Id = 'id',
  ParentTerritoryId = 'parentTerritoryId',
  ParentTerritoryName = 'parentTerritoryName',
  TerritoryDescription = 'territoryDescription',
  TerritoryName = 'territoryName',
  TerritoryType = 'territoryType'
}

export type BoehringerIngelheimTerritoryOrderByRelevanceInput = {
  fields: Array<BoehringerIngelheimTerritoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BoehringerIngelheimTerritoryOrderByWithAggregationInput = {
  _count?: Maybe<BoehringerIngelheimTerritoryCountOrderByAggregateInput>;
  _max?: Maybe<BoehringerIngelheimTerritoryMaxOrderByAggregateInput>;
  _min?: Maybe<BoehringerIngelheimTerritoryMinOrderByAggregateInput>;
  countryCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentTerritoryId?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BoehringerIngelheimTerritoryOrderByRelevanceInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryOrderByRelationAggregateInput>;
  countryCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  enterpriseSegment?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  parentTerritoryId?: Maybe<SortOrder>;
  parentTerritoryName?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  territoryDescription?: Maybe<SortOrder>;
  territoryName?: Maybe<SortOrder>;
  territoryType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BoehringerIngelheimTerritoryRelationFilter = {
  is?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  isNot?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};

export enum BoehringerIngelheimTerritoryScalarFieldEnum {
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  EnterpriseSegment = 'enterpriseSegment',
  Id = 'id',
  ParentTerritoryId = 'parentTerritoryId',
  ParentTerritoryName = 'parentTerritoryName',
  StartDate = 'startDate',
  TerritoryDescription = 'territoryDescription',
  TerritoryName = 'territoryName',
  TerritoryType = 'territoryType',
  UpdatedAt = 'updatedAt'
}

export type BoehringerIngelheimTerritoryScalarWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  countryCode?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  enterpriseSegment?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  parentTerritoryId?: Maybe<StringNullableFilter>;
  parentTerritoryName?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  territoryDescription?: Maybe<StringNullableFilter>;
  territoryName?: Maybe<StringFilter>;
  territoryType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereWithAggregatesInput>>;
  countryCode?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  endDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  enterpriseSegment?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  parentTerritoryId?: Maybe<StringNullableWithAggregatesFilter>;
  parentTerritoryName?: Maybe<StringNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  territoryDescription?: Maybe<StringNullableWithAggregatesFilter>;
  territoryName?: Maybe<StringWithAggregatesFilter>;
  territoryType?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoryInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutParentTerritoryInput>;
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName: Scalars['String'];
  territoryType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyInput = {
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesInput = {
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutChildTerritoriesInput = {
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput>>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryId?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateManyMutationInput = {
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput = {
  data: BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesInput;
  where: BoehringerIngelheimTerritoryScalarWhereInput;
};

export type BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput = {
  data: BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutChildTerritoriesInput;
  where: BoehringerIngelheimTerritoryScalarWhereInput;
};

export type BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutInformPartnerUsersInput>>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
};

export type BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput = {
  connect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BoehringerIngelheimTerritoryCreateOrConnectWithoutParentTerritoryInput>>;
  create?: Maybe<Array<BoehringerIngelheimTerritoryCreateWithoutParentTerritoryInput>>;
  createMany?: Maybe<BoehringerIngelheimTerritoryCreateManyParentTerritoryInputEnvelope>;
  delete?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BoehringerIngelheimTerritoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  set?: Maybe<Array<BoehringerIngelheimTerritoryWhereUniqueInput>>;
  update?: Maybe<Array<BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput>>;
  updateMany?: Maybe<Array<BoehringerIngelheimTerritoryUpdateManyWithWhereWithoutParentTerritoryInput>>;
  upsert?: Maybe<Array<BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput>>;
};

export type BoehringerIngelheimTerritoryUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput = {
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  update?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  upsert?: Maybe<BoehringerIngelheimTerritoryUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput = {
  connect?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BoehringerIngelheimTerritoryCreateOrConnectWithoutChildTerritoriesInput>;
  create?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput>;
  upsert?: Maybe<BoehringerIngelheimTerritoryUpsertWithoutChildTerritoriesInput>;
};

export type BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutInformPartnerUsersInput = {
  data: BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryUpdateWithWhereUniqueWithoutParentTerritoryInput = {
  data: BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutChildTerritoriesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryUpdateOneWithoutChildTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpdateWithoutParentTerritoryInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutBoehringerIngelheimTerritoryNestedInput>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutParentTerritoryNestedInput>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enterpriseSegment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput>;
  parentTerritoryName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  territoryDescription?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
  territoryType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutInformPartnerUsersInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutInformPartnerUsersInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutInformPartnerUsersInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryUpsertWithWhereUniqueWithoutParentTerritoryInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutParentTerritoryInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutParentTerritoryInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};

export type BoehringerIngelheimTerritoryUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type BoehringerIngelheimTerritoryUpsertWithoutChildTerritoriesInput = {
  create: BoehringerIngelheimTerritoryUncheckedCreateWithoutChildTerritoriesInput;
  update: BoehringerIngelheimTerritoryUncheckedUpdateWithoutChildTerritoriesInput;
};

export type BoehringerIngelheimTerritoryWhereInput = {
  AND?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  NOT?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  OR?: Maybe<Array<BoehringerIngelheimTerritoryWhereInput>>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentListRelationFilter>;
  childTerritories?: Maybe<BoehringerIngelheimTerritoryListRelationFilter>;
  countryCode?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeNullableFilter>;
  enterpriseSegment?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  parentTerritory?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
  parentTerritoryId?: Maybe<StringNullableFilter>;
  parentTerritoryName?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  territoryDescription?: Maybe<StringNullableFilter>;
  territoryName?: Maybe<StringFilter>;
  territoryType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BoehringerIngelheimTerritoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  territoryName?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};

export type Breed = {
  __typename?: 'Breed';
  _count: BreedCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  pets: Array<ClinicPet>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<Species>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type BreedPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};

export type BreedCountAggregateOutputType = {
  __typename?: 'BreedCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  speciesId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BreedCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BreedCountOutputType = {
  __typename?: 'BreedCountOutputType';
  pets: Scalars['Int'];
};

export type BreedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateManyIntegrationInputEnvelope = {
  data: Array<BreedCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BreedCreateManySpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateManySpeciesInputEnvelope = {
  data: Array<BreedCreateManySpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BreedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
};

export type BreedCreateNestedManyWithoutSpeciesInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
};

export type BreedCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<BreedWhereUniqueInput>;
  connectOrCreate?: Maybe<BreedCreateOrConnectWithoutPetsInput>;
  create?: Maybe<BreedUncheckedCreateWithoutPetsInput>;
};

export type BreedCreateOrConnectWithoutIntegrationInput = {
  create: BreedUncheckedCreateWithoutIntegrationInput;
  where: BreedWhereUniqueInput;
};

export type BreedCreateOrConnectWithoutPetsInput = {
  create: BreedUncheckedCreateWithoutPetsInput;
  where: BreedWhereUniqueInput;
};

export type BreedCreateOrConnectWithoutSpeciesInput = {
  create: BreedUncheckedCreateWithoutSpeciesInput;
  where: BreedWhereUniqueInput;
};

export type BreedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutBreedsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedCreateWithoutSpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type BreedListRelationFilter = {
  every?: Maybe<BreedWhereInput>;
  none?: Maybe<BreedWhereInput>;
  some?: Maybe<BreedWhereInput>;
};

export type BreedMaxAggregateOutputType = {
  __typename?: 'BreedMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BreedMinAggregateOutputType = {
  __typename?: 'BreedMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BreedOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum BreedOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId',
  SpeciesId = 'speciesId'
}

export type BreedOrderByRelevanceInput = {
  fields: Array<BreedOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type BreedOrderByWithAggregationInput = {
  _count?: Maybe<BreedCountOrderByAggregateInput>;
  _max?: Maybe<BreedMaxOrderByAggregateInput>;
  _min?: Maybe<BreedMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BreedOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<BreedOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  species?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BreedRelationFilter = {
  is?: Maybe<BreedWhereInput>;
  isNot?: Maybe<BreedWhereInput>;
};

export enum BreedScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  SpeciesId = 'speciesId',
  UpdatedAt = 'updatedAt'
}

export type BreedScalarWhereInput = {
  AND?: Maybe<Array<BreedScalarWhereInput>>;
  NOT?: Maybe<Array<BreedScalarWhereInput>>;
  OR?: Maybe<Array<BreedScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BreedScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<BreedScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type BreedUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
};

export type BreedUncheckedCreateNestedManyWithoutSpeciesInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
};

export type BreedUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedCreateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedCreateWithoutSpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateManyWithoutBreedsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type BreedUncheckedUpdateManyWithoutSpeciesNestedInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutSpeciesInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutSpeciesInput>>;
};

export type BreedUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUncheckedUpdateWithoutSpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpdateManyWithWhereWithoutIntegrationInput = {
  data: BreedUncheckedUpdateManyWithoutBreedsInput;
  where: BreedScalarWhereInput;
};

export type BreedUpdateManyWithWhereWithoutSpeciesInput = {
  data: BreedUncheckedUpdateManyWithoutBreedsInput;
  where: BreedScalarWhereInput;
};

export type BreedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<BreedCreateWithoutIntegrationInput>>;
  createMany?: Maybe<BreedCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type BreedUpdateManyWithoutSpeciesNestedInput = {
  connect?: Maybe<Array<BreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BreedCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<BreedCreateWithoutSpeciesInput>>;
  createMany?: Maybe<BreedCreateManySpeciesInputEnvelope>;
  delete?: Maybe<Array<BreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BreedScalarWhereInput>>;
  disconnect?: Maybe<Array<BreedWhereUniqueInput>>;
  set?: Maybe<Array<BreedWhereUniqueInput>>;
  update?: Maybe<Array<BreedUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<BreedUpdateManyWithWhereWithoutSpeciesInput>>;
  upsert?: Maybe<Array<BreedUpsertWithWhereUniqueWithoutSpeciesInput>>;
};

export type BreedUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<BreedWhereUniqueInput>;
  connectOrCreate?: Maybe<BreedCreateOrConnectWithoutPetsInput>;
  create?: Maybe<BreedUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BreedUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<BreedUpsertWithoutPetsInput>;
};

export type BreedUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: BreedUncheckedUpdateWithoutIntegrationInput;
  where: BreedWhereUniqueInput;
};

export type BreedUpdateWithWhereUniqueWithoutSpeciesInput = {
  data: BreedUncheckedUpdateWithoutSpeciesInput;
  where: BreedWhereUniqueInput;
};

export type BreedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpdateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutBreedsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpdateWithoutSpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutBreedModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BreedUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: BreedUncheckedCreateWithoutIntegrationInput;
  update: BreedUncheckedUpdateWithoutIntegrationInput;
  where: BreedWhereUniqueInput;
};

export type BreedUpsertWithWhereUniqueWithoutSpeciesInput = {
  create: BreedUncheckedCreateWithoutSpeciesInput;
  update: BreedUncheckedUpdateWithoutSpeciesInput;
  where: BreedWhereUniqueInput;
};

export type BreedUpsertWithoutPetsInput = {
  create: BreedUncheckedCreateWithoutPetsInput;
  update: BreedUncheckedUpdateWithoutPetsInput;
};

export type BreedWhereInput = {
  AND?: Maybe<Array<BreedWhereInput>>;
  NOT?: Maybe<Array<BreedWhereInput>>;
  OR?: Maybe<Array<BreedWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  species?: Maybe<SpeciesWhereInput>;
  speciesId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BreedWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<BreedIntegrationIdPimsIdCompoundUniqueInput>;
};

export type BulkPublishWorkflowEventSettingsInput = {
  isPublished: Scalars['Boolean'];
  workflowEventSettingIds: Array<Scalars['String']>;
};

export type BulkPublishWorkflowEventSettingsResponse = {
  __typename?: 'BulkPublishWorkflowEventSettingsResponse';
  count: Scalars['Int'];
};

export type CalculateMultiplePetsBenefitUsageInput = {
  organizationPetIds: Array<Scalars['String']>;
};

export type CalculateMultiplePetsBenefitUsageOutput = {
  __typename?: 'CalculateMultiplePetsBenefitUsageOutput';
  petUsages: Array<PetBenefitUsage>;
};

export type CalculatePetBenefitUsageInput = {
  organizationPetId: Scalars['String'];
};

export type CalculatePetBenefitUsageOutput = {
  __typename?: 'CalculatePetBenefitUsageOutput';
  usages: Array<UsageByBenefit>;
};

export type CallHistory = {
  __typename?: 'CallHistory';
  _count: CallHistoryCountOutputType;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** duration of call in seconds */
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id: Scalars['String'];
  participants: Array<CallParticipant>;
  provider: CallProvider;
  recordings: Array<CallRecording>;
  sourceId: Scalars['String'];
  status: CallStatus;
  type: CallHistoryType;
  updatedAt: Scalars['DateTime'];
};


export type CallHistoryParticipantsArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type CallHistoryRecordingsArgs = {
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  distinct?: Maybe<CallRecordingScalarFieldEnum>;
  orderBy?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallRecordingWhereInput>;
};

export type CallHistoryAvgAggregateOutputType = {
  __typename?: 'CallHistoryAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallHistoryAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallHistoryCountAggregateOutputType = {
  __typename?: 'CallHistoryCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  duration: Scalars['Int'];
  from: Scalars['Int'];
  id: Scalars['Int'];
  provider: Scalars['Int'];
  sourceId: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CallHistoryCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallHistoryCountOutputType = {
  __typename?: 'CallHistoryCountOutputType';
  participants: Scalars['Int'];
  recordings: Scalars['Int'];
};

export type CallHistoryCreateInput = {
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryCreateManyClinicInputEnvelope = {
  data: Array<CallHistoryCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallHistoryCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
};

export type CallHistoryCreateNestedOneWithoutParticipantsInput = {
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
};

export type CallHistoryCreateNestedOneWithoutRecordingsInput = {
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
};

export type CallHistoryCreateOrConnectWithoutClinicInput = {
  create: CallHistoryUncheckedCreateWithoutClinicInput;
  where: CallHistoryWhereUniqueInput;
};

export type CallHistoryCreateOrConnectWithoutParticipantsInput = {
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
  where: CallHistoryWhereUniqueInput;
};

export type CallHistoryCreateOrConnectWithoutRecordingsInput = {
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
  where: CallHistoryWhereUniqueInput;
};

export type CallHistoryCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryCreateWithoutParticipantsInput = {
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryCreateWithoutRecordingsInput = {
  clinic: ClinicCreateNestedOneWithoutCallsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryListRelationFilter = {
  every?: Maybe<CallHistoryWhereInput>;
  none?: Maybe<CallHistoryWhereInput>;
  some?: Maybe<CallHistoryWhereInput>;
};

export type CallHistoryMaxAggregateOutputType = {
  __typename?: 'CallHistoryMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallHistoryMinAggregateOutputType = {
  __typename?: 'CallHistoryMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallHistoryOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  From = 'from',
  Id = 'id',
  SourceId = 'sourceId'
}

export type CallHistoryOrderByRelevanceInput = {
  fields: Array<CallHistoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CallHistoryOrderByWithAggregationInput = {
  _avg?: Maybe<CallHistoryAvgOrderByAggregateInput>;
  _count?: Maybe<CallHistoryCountOrderByAggregateInput>;
  _max?: Maybe<CallHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<CallHistoryMinOrderByAggregateInput>;
  _sum?: Maybe<CallHistorySumOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallHistoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CallHistoryOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  participants?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  provider?: Maybe<SortOrder>;
  recordings?: Maybe<CallRecordingOrderByRelationAggregateInput>;
  sourceId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallHistoryRelationFilter = {
  is?: Maybe<CallHistoryWhereInput>;
  isNot?: Maybe<CallHistoryWhereInput>;
};

export enum CallHistoryScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Duration = 'duration',
  From = 'from',
  Id = 'id',
  Provider = 'provider',
  SourceId = 'sourceId',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CallHistoryScalarWhereInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  duration?: Maybe<IntNullableFilter>;
  from?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  sourceId?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallHistoryScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  from?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  provider?: Maybe<EnumCallProviderWithAggregatesFilter>;
  sourceId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCallStatusWithAggregatesFilter>;
  type?: Maybe<EnumCallHistoryTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CallHistorySumAggregateOutputType = {
  __typename?: 'CallHistorySumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallHistorySumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export enum CallHistoryType {
  Video = 'Video',
  Voice = 'Voice'
}

export type CallHistoryUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
};

export type CallHistoryUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedCreateWithoutParticipantsInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  provider: CallProvider;
  recordings?: Maybe<CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput>;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedCreateWithoutRecordingsInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput>;
  provider: CallProvider;
  sourceId: Scalars['String'];
  status?: Maybe<CallStatus>;
  type: CallHistoryType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateManyWithoutCallsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type CallHistoryUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateWithoutParticipantsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUncheckedUpdateWithoutRecordingsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpdateManyWithWhereWithoutClinicInput = {
  data: CallHistoryUncheckedUpdateManyWithoutCallsInput;
  where: CallHistoryScalarWhereInput;
};

export type CallHistoryUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallHistoryCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<CallHistoryCreateWithoutClinicInput>>;
  createMany?: Maybe<CallHistoryCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  set?: Maybe<Array<CallHistoryWhereUniqueInput>>;
  update?: Maybe<Array<CallHistoryUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<CallHistoryUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<CallHistoryUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput = {
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutParticipantsInput>;
  create?: Maybe<CallHistoryUncheckedCreateWithoutParticipantsInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutParticipantsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutParticipantsInput>;
};

export type CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput = {
  connect?: Maybe<CallHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<CallHistoryCreateOrConnectWithoutRecordingsInput>;
  create?: Maybe<CallHistoryUncheckedCreateWithoutRecordingsInput>;
  update?: Maybe<CallHistoryUncheckedUpdateWithoutRecordingsInput>;
  upsert?: Maybe<CallHistoryUpsertWithoutRecordingsInput>;
};

export type CallHistoryUpdateWithWhereUniqueWithoutClinicInput = {
  data: CallHistoryUncheckedUpdateWithoutClinicInput;
  where: CallHistoryWhereUniqueInput;
};

export type CallHistoryUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpdateWithoutParticipantsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<CallProvider>;
  recordings?: Maybe<CallRecordingUpdateManyWithoutCallHistoryNestedInput>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpdateWithoutRecordingsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<CallParticipantUpdateManyWithoutCallHistoryNestedInput>;
  provider?: Maybe<CallProvider>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<CallStatus>;
  type?: Maybe<CallHistoryType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallHistoryUpsertWithWhereUniqueWithoutClinicInput = {
  create: CallHistoryUncheckedCreateWithoutClinicInput;
  update: CallHistoryUncheckedUpdateWithoutClinicInput;
  where: CallHistoryWhereUniqueInput;
};

export type CallHistoryUpsertWithoutParticipantsInput = {
  create: CallHistoryUncheckedCreateWithoutParticipantsInput;
  update: CallHistoryUncheckedUpdateWithoutParticipantsInput;
};

export type CallHistoryUpsertWithoutRecordingsInput = {
  create: CallHistoryUncheckedCreateWithoutRecordingsInput;
  update: CallHistoryUncheckedUpdateWithoutRecordingsInput;
};

export type CallHistoryWhereInput = {
  AND?: Maybe<Array<CallHistoryWhereInput>>;
  NOT?: Maybe<Array<CallHistoryWhereInput>>;
  OR?: Maybe<Array<CallHistoryWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  duration?: Maybe<IntNullableFilter>;
  from?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  participants?: Maybe<CallParticipantListRelationFilter>;
  provider?: Maybe<EnumCallProviderFilter>;
  recordings?: Maybe<CallRecordingListRelationFilter>;
  sourceId?: Maybe<StringFilter>;
  status?: Maybe<EnumCallStatusFilter>;
  type?: Maybe<EnumCallHistoryTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
};

export type CallParticipant = {
  __typename?: 'CallParticipant';
  callHistory: CallHistory;
  callHistoryId: Scalars['String'];
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type CallParticipantCallHistoryIdUserIdCompoundUniqueInput = {
  callHistoryId: Scalars['String'];
  userId: Scalars['String'];
};

export type CallParticipantCountAggregateOutputType = {
  __typename?: 'CallParticipantCountAggregateOutputType';
  _all: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  number: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CallParticipantCountOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CallParticipantCreateInput = {
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateManyCallHistoryInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCreateManyCallHistoryInputEnvelope = {
  data: Array<CallParticipantCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyClinicPetParentInput = {
  callHistoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCreateManyClinicPetParentInputEnvelope = {
  data: Array<CallParticipantCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateManyInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantCreateManyUserInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantCreateManyUserInputEnvelope = {
  data: Array<CallParticipantCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallParticipantCreateNestedManyWithoutCallHistoryInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
};

export type CallParticipantCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
};

export type CallParticipantCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
};

export type CallParticipantCreateOrConnectWithoutCallHistoryInput = {
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantCreateOrConnectWithoutClinicPetParentInput = {
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantCreateOrConnectWithoutUserInput = {
  create: CallParticipantUncheckedCreateWithoutUserInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantCreateWithoutCallHistoryInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutClinicPetParentInput = {
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutCallsInput>;
};

export type CallParticipantCreateWithoutUserInput = {
  callHistory: CallHistoryCreateNestedOneWithoutParticipantsInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutCallsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantListRelationFilter = {
  every?: Maybe<CallParticipantWhereInput>;
  none?: Maybe<CallParticipantWhereInput>;
  some?: Maybe<CallParticipantWhereInput>;
};

export type CallParticipantMaxAggregateOutputType = {
  __typename?: 'CallParticipantMaxAggregateOutputType';
  callHistoryId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantMaxOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CallParticipantMinAggregateOutputType = {
  __typename?: 'CallParticipantMinAggregateOutputType';
  callHistoryId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantMinOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CallParticipantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallParticipantOrderByRelevanceFieldEnum {
  CallHistoryId = 'callHistoryId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  Number = 'number',
  UserId = 'userId'
}

export type CallParticipantOrderByRelevanceInput = {
  fields: Array<CallParticipantOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CallParticipantOrderByWithAggregationInput = {
  _count?: Maybe<CallParticipantCountOrderByAggregateInput>;
  _max?: Maybe<CallParticipantMaxOrderByAggregateInput>;
  _min?: Maybe<CallParticipantMinOrderByAggregateInput>;
  callHistoryId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type CallParticipantOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CallParticipantOrderByRelevanceInput>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  callHistoryId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum CallParticipantScalarFieldEnum {
  CallHistoryId = 'callHistoryId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Number = 'number',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CallParticipantScalarWhereInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereInput>>;
  callHistoryId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  number?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type CallParticipantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallParticipantScalarWhereWithAggregatesInput>>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  number?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CallParticipantUncheckedCreateInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutCallHistoryInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
};

export type CallParticipantUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
};

export type CallParticipantUncheckedCreateWithoutCallHistoryInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedCreateWithoutClinicPetParentInput = {
  callHistoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedCreateWithoutUserInput = {
  callHistoryId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantUncheckedUpdateInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallHistoryNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutCallsInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CallParticipantUncheckedUpdateManyWithoutParticipantsInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CallParticipantUncheckedUpdateWithoutCallHistoryInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutClinicPetParentInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type CallParticipantUncheckedUpdateWithoutUserInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantUpdateInput = {
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantUpdateManyWithWhereWithoutCallHistoryInput = {
  data: CallParticipantUncheckedUpdateManyWithoutParticipantsInput;
  where: CallParticipantScalarWhereInput;
};

export type CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
  where: CallParticipantScalarWhereInput;
};

export type CallParticipantUpdateManyWithWhereWithoutUserInput = {
  data: CallParticipantUncheckedUpdateManyWithoutCallsInput;
  where: CallParticipantScalarWhereInput;
};

export type CallParticipantUpdateManyWithoutCallHistoryNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallParticipantCreateManyCallHistoryInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput>>;
};

export type CallParticipantUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CallParticipantCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CallParticipantUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallParticipantCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<CallParticipantCreateWithoutUserInput>>;
  createMany?: Maybe<CallParticipantCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  set?: Maybe<Array<CallParticipantWhereUniqueInput>>;
  update?: Maybe<Array<CallParticipantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CallParticipantUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<CallParticipantUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CallParticipantUpdateWithWhereUniqueWithoutCallHistoryInput = {
  data: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantUpdateWithWhereUniqueWithoutUserInput = {
  data: CallParticipantUncheckedUpdateWithoutUserInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantUpdateWithoutCallHistoryInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateWithoutClinicPetParentInput = {
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutCallsNestedInput>;
};

export type CallParticipantUpdateWithoutUserInput = {
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutParticipantsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutCallsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallParticipantUpsertWithWhereUniqueWithoutCallHistoryInput = {
  create: CallParticipantUncheckedCreateWithoutCallHistoryInput;
  update: CallParticipantUncheckedUpdateWithoutCallHistoryInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: CallParticipantUncheckedCreateWithoutClinicPetParentInput;
  update: CallParticipantUncheckedUpdateWithoutClinicPetParentInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantUpsertWithWhereUniqueWithoutUserInput = {
  create: CallParticipantUncheckedCreateWithoutUserInput;
  update: CallParticipantUncheckedUpdateWithoutUserInput;
  where: CallParticipantWhereUniqueInput;
};

export type CallParticipantWhereInput = {
  AND?: Maybe<Array<CallParticipantWhereInput>>;
  NOT?: Maybe<Array<CallParticipantWhereInput>>;
  OR?: Maybe<Array<CallParticipantWhereInput>>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  callHistoryId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  number?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type CallParticipantWhereUniqueInput = {
  callHistoryId_clinicPetParentId?: Maybe<CallParticipantCallHistoryIdClinicPetParentIdCompoundUniqueInput>;
  callHistoryId_userId?: Maybe<CallParticipantCallHistoryIdUserIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum CallProvider {
  Twilio = 'Twilio'
}

export type CallRecording = {
  __typename?: 'CallRecording';
  callHistory: CallHistory;
  callHistoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  downloadStatus: RecordingDownloadStatus;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  s3Uri: Scalars['String'];
  twilioCallRecording?: Maybe<TwilioCallRecording>;
  updatedAt: Scalars['DateTime'];
};

export type CallRecordingAvgAggregateOutputType = {
  __typename?: 'CallRecordingAvgAggregateOutputType';
  duration?: Maybe<Scalars['Float']>;
};

export type CallRecordingAvgOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingCountAggregateOutputType = {
  __typename?: 'CallRecordingCountAggregateOutputType';
  _all: Scalars['Int'];
  callHistoryId: Scalars['Int'];
  createdAt: Scalars['Int'];
  downloadStatus: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  s3Uri: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CallRecordingCountOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallRecordingCreateInput = {
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingCreateManyCallHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingCreateManyCallHistoryInputEnvelope = {
  data: Array<CallRecordingCreateManyCallHistoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CallRecordingCreateManyInput = {
  callHistoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingCreateNestedManyWithoutCallHistoryInput = {
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
};

export type CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput = {
  connect?: Maybe<CallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
};

export type CallRecordingCreateOrConnectWithoutCallHistoryInput = {
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
  where: CallRecordingWhereUniqueInput;
};

export type CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput = {
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
  where: CallRecordingWhereUniqueInput;
};

export type CallRecordingCreateWithoutCallHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  twilioCallRecording?: Maybe<TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingCreateWithoutTwilioCallRecordingInput = {
  callHistory: CallHistoryCreateNestedOneWithoutRecordingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingListRelationFilter = {
  every?: Maybe<CallRecordingWhereInput>;
  none?: Maybe<CallRecordingWhereInput>;
  some?: Maybe<CallRecordingWhereInput>;
};

export type CallRecordingMaxAggregateOutputType = {
  __typename?: 'CallRecordingMaxAggregateOutputType';
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingMaxOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallRecordingMinAggregateOutputType = {
  __typename?: 'CallRecordingMinAggregateOutputType';
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingMinOrderByAggregateInput = {
  callHistoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallRecordingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CallRecordingOrderByRelevanceFieldEnum {
  CallHistoryId = 'callHistoryId',
  Id = 'id',
  S3Uri = 's3Uri'
}

export type CallRecordingOrderByRelevanceInput = {
  fields: Array<CallRecordingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CallRecordingOrderByWithAggregationInput = {
  _avg?: Maybe<CallRecordingAvgOrderByAggregateInput>;
  _count?: Maybe<CallRecordingCountOrderByAggregateInput>;
  _max?: Maybe<CallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<CallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<CallRecordingSumOrderByAggregateInput>;
  callHistoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CallRecordingOrderByRelevanceInput>;
  callHistory?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  callHistoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  downloadStatus?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  s3Uri?: Maybe<SortOrder>;
  twilioCallRecording?: Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type CallRecordingRelationFilter = {
  is?: Maybe<CallRecordingWhereInput>;
  isNot?: Maybe<CallRecordingWhereInput>;
};

export enum CallRecordingScalarFieldEnum {
  CallHistoryId = 'callHistoryId',
  CreatedAt = 'createdAt',
  DownloadStatus = 'downloadStatus',
  Duration = 'duration',
  Id = 'id',
  IsDeleted = 'isDeleted',
  S3Uri = 's3Uri',
  UpdatedAt = 'updatedAt'
}

export type CallRecordingScalarWhereInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereInput>>;
  callHistoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  s3Uri?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CallRecordingScalarWhereWithAggregatesInput>>;
  callHistoryId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusWithAggregatesFilter>;
  duration?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  s3Uri?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CallRecordingSumAggregateOutputType = {
  __typename?: 'CallRecordingSumAggregateOutputType';
  duration?: Maybe<Scalars['Int']>;
};

export type CallRecordingSumOrderByAggregateInput = {
  duration?: Maybe<SortOrder>;
};

export type CallRecordingUncheckedCreateInput = {
  callHistoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedCreateNestedManyWithoutCallHistoryInput = {
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
};

export type CallRecordingUncheckedCreateWithoutCallHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput = {
  callHistoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedUpdateInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedUpdateManyInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedUpdateManyWithoutCallHistoryNestedInput = {
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
};

export type CallRecordingUncheckedUpdateManyWithoutRecordingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedUpdateWithoutCallHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput = {
  callHistoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUpdateInput = {
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  status: RecordingControlStatus;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUpdateManyWithWhereWithoutCallHistoryInput = {
  data: CallRecordingUncheckedUpdateManyWithoutRecordingsInput;
  where: CallRecordingScalarWhereInput;
};

export type CallRecordingUpdateManyWithoutCallHistoryNestedInput = {
  connect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CallRecordingCreateOrConnectWithoutCallHistoryInput>>;
  create?: Maybe<Array<CallRecordingCreateWithoutCallHistoryInput>>;
  createMany?: Maybe<CallRecordingCreateManyCallHistoryInputEnvelope>;
  delete?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CallRecordingScalarWhereInput>>;
  disconnect?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  set?: Maybe<Array<CallRecordingWhereUniqueInput>>;
  update?: Maybe<Array<CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput>>;
  updateMany?: Maybe<Array<CallRecordingUpdateManyWithWhereWithoutCallHistoryInput>>;
  upsert?: Maybe<Array<CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput>>;
};

export type CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingNestedInput = {
  connect?: Maybe<CallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<CallRecordingCreateOrConnectWithoutTwilioCallRecordingInput>;
  create?: Maybe<CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput>;
  update?: Maybe<CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput>;
  upsert?: Maybe<CallRecordingUpsertWithoutTwilioCallRecordingInput>;
};

export type CallRecordingUpdateWithWhereUniqueWithoutCallHistoryInput = {
  data: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
  where: CallRecordingWhereUniqueInput;
};

export type CallRecordingUpdateWithoutCallHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  twilioCallRecording?: Maybe<TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUpdateWithoutTwilioCallRecordingInput = {
  callHistory?: Maybe<CallHistoryUpdateOneRequiredWithoutRecordingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadStatus?: Maybe<RecordingDownloadStatus>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  s3Uri?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CallRecordingUpsertWithWhereUniqueWithoutCallHistoryInput = {
  create: CallRecordingUncheckedCreateWithoutCallHistoryInput;
  update: CallRecordingUncheckedUpdateWithoutCallHistoryInput;
  where: CallRecordingWhereUniqueInput;
};

export type CallRecordingUpsertWithoutTwilioCallRecordingInput = {
  create: CallRecordingUncheckedCreateWithoutTwilioCallRecordingInput;
  update: CallRecordingUncheckedUpdateWithoutTwilioCallRecordingInput;
};

export type CallRecordingWhereInput = {
  AND?: Maybe<Array<CallRecordingWhereInput>>;
  NOT?: Maybe<Array<CallRecordingWhereInput>>;
  OR?: Maybe<Array<CallRecordingWhereInput>>;
  callHistory?: Maybe<CallHistoryWhereInput>;
  callHistoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  downloadStatus?: Maybe<EnumRecordingDownloadStatusFilter>;
  duration?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  s3Uri?: Maybe<StringFilter>;
  twilioCallRecording?: Maybe<TwilioCallRecordingWhereInput>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CallRecordingWhereUniqueInput = {
  id: Scalars['String'];
};

export enum CallStatus {
  Busy = 'Busy',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  NoAnswer = 'NoAnswer',
  Queued = 'Queued',
  Ringing = 'Ringing'
}

export enum CampaignRegistryBrandIdentityStatus {
  SelfDeclared = 'SELF_DECLARED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
  VettedVerified = 'VETTED_VERIFIED'
}

export type CampaignRegistryCampaign = {
  __typename?: 'CampaignRegistryCampaign';
  _count: CampaignRegistryCampaignCountOutputType;
  clinicPhoneNumbers: Array<ClinicPhoneNumber>;
  clinicSetting: ClinicSetting;
  clinicSettingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  upstreamCnpId?: Maybe<Scalars['String']>;
};


export type CampaignRegistryCampaignClinicPhoneNumbersArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type CampaignRegistryCampaignCountAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  importedAt: Scalars['Int'];
  isDeleted: Scalars['Int'];
  mnoMetaData: Scalars['Int'];
  settings: Scalars['Int'];
  sharingStatus: Scalars['Int'];
  sourceId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  upstreamCnpId: Scalars['Int'];
};

export type CampaignRegistryCampaignCountOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignCountOutputType = {
  __typename?: 'CampaignRegistryCampaignCountOutputType';
  clinicPhoneNumbers: Scalars['Int'];
};

export type CampaignRegistryCampaignCreateInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignCreateManyClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope = {
  data: Array<CampaignRegistryCampaignCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CampaignRegistryCampaignCreateManyInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
};

export type CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput = {
  connect?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput>;
  create?: Maybe<CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput>;
};

export type CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput = {
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};

export type CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput = {
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};

export type CampaignRegistryCampaignCreateWithoutClinicPhoneNumbersInput = {
  clinicSetting: ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignCreateWithoutClinicSettingInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignListRelationFilter = {
  every?: Maybe<CampaignRegistryCampaignWhereInput>;
  none?: Maybe<CampaignRegistryCampaignWhereInput>;
  some?: Maybe<CampaignRegistryCampaignWhereInput>;
};

export type CampaignRegistryCampaignMaxAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignMaxAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignMaxOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignMinAggregateOutputType = {
  __typename?: 'CampaignRegistryCampaignMinAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignMinOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CampaignRegistryCampaignOrderByRelevanceFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  Id = 'id',
  SourceId = 'sourceId',
  UpstreamCnpId = 'upstreamCnpId'
}

export type CampaignRegistryCampaignOrderByRelevanceInput = {
  fields: Array<CampaignRegistryCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CampaignRegistryCampaignOrderByWithAggregationInput = {
  _count?: Maybe<CampaignRegistryCampaignCountOrderByAggregateInput>;
  _max?: Maybe<CampaignRegistryCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<CampaignRegistryCampaignMinOrderByAggregateInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CampaignRegistryCampaignOrderByRelevanceInput>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedAt?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  mnoMetaData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  sharingStatus?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  upstreamCnpId?: Maybe<SortOrder>;
};

export type CampaignRegistryCampaignRelationFilter = {
  is?: Maybe<CampaignRegistryCampaignWhereInput>;
  isNot?: Maybe<CampaignRegistryCampaignWhereInput>;
};

export enum CampaignRegistryCampaignScalarFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ImportedAt = 'importedAt',
  IsDeleted = 'isDeleted',
  MnoMetaData = 'mnoMetaData',
  Settings = 'settings',
  SharingStatus = 'sharingStatus',
  SourceId = 'sourceId',
  UpdatedAt = 'updatedAt',
  UpstreamCnpId = 'upstreamCnpId'
}

export type CampaignRegistryCampaignScalarWhereInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  importedAt?: Maybe<DateTimeNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  mnoMetaData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  sourceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upstreamCnpId?: Maybe<StringNullableFilter>;
};

export type CampaignRegistryCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignScalarWhereWithAggregatesInput>>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  importedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  mnoMetaData?: Maybe<JsonNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  sourceId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  upstreamCnpId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum CampaignRegistryCampaignSharingStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type CampaignRegistryCampaignUncheckedCreateInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput>;
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
};

export type CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedUpdateInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyWithoutCampaignRegistryCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  set?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  update?: Maybe<Array<CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUpdateInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput = {
  data: CampaignRegistryCampaignUncheckedUpdateManyWithoutCampaignRegistryCampaignsInput;
  where: CampaignRegistryCampaignScalarWhereInput;
};

export type CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CampaignRegistryCampaignCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<CampaignRegistryCampaignCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<CampaignRegistryCampaignCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CampaignRegistryCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  set?: Maybe<Array<CampaignRegistryCampaignWhereUniqueInput>>;
  update?: Maybe<Array<CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<CampaignRegistryCampaignUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput = {
  connect?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<CampaignRegistryCampaignCreateOrConnectWithoutClinicPhoneNumbersInput>;
  create?: Maybe<CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput>;
  upsert?: Maybe<CampaignRegistryCampaignUpsertWithoutClinicPhoneNumbersInput>;
};

export type CampaignRegistryCampaignUpdateWithWhereUniqueWithoutClinicSettingInput = {
  data: CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};

export type CampaignRegistryCampaignUpdateWithoutClinicPhoneNumbersInput = {
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUpdateWithoutClinicSettingInput = {
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  importedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mnoMetaData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  sharingStatus?: Maybe<CampaignRegistryCampaignSharingStatus>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upstreamCnpId?: Maybe<Scalars['String']>;
};

export type CampaignRegistryCampaignUpsertWithWhereUniqueWithoutClinicSettingInput = {
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicSettingInput;
  update: CampaignRegistryCampaignUncheckedUpdateWithoutClinicSettingInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};

export type CampaignRegistryCampaignUpsertWithoutClinicPhoneNumbersInput = {
  create: CampaignRegistryCampaignUncheckedCreateWithoutClinicPhoneNumbersInput;
  update: CampaignRegistryCampaignUncheckedUpdateWithoutClinicPhoneNumbersInput;
};

export type CampaignRegistryCampaignWhereInput = {
  AND?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  NOT?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  OR?: Maybe<Array<CampaignRegistryCampaignWhereInput>>;
  clinicPhoneNumbers?: Maybe<ClinicPhoneNumberListRelationFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  importedAt?: Maybe<DateTimeNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  mnoMetaData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  sharingStatus?: Maybe<EnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  sourceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  upstreamCnpId?: Maybe<StringNullableFilter>;
};

export type CampaignRegistryCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
};

export type CanManuallyRetryPaymentWritebackOutput = {
  __typename?: 'CanManuallyRetryPaymentWritebackOutput';
  reason?: Maybe<Scalars['String']>;
  retryable: Scalars['Boolean'];
};

export type CancelCareSubscriptionInput = {
  allowRefund?: Maybe<Scalars['Boolean']>;
  careSubscriptionId: Scalars['String'];
  trueUpInformation?: Maybe<CancellationTrueupInformation>;
};

export type CancelCareSubscriptionResult = {
  __typename?: 'CancelCareSubscriptionResult';
  messages: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum CancelReasonType {
  ChangedMind = 'ChangedMind',
  ClinicInitiatedOther = 'ClinicInitiatedOther',
  ClinicInitiatedUncollectible = 'ClinicInitiatedUncollectible',
  DeceasedPet = 'DeceasedPet',
  FinancialHardship = 'FinancialHardship',
  Moving = 'Moving',
  Other = 'Other',
  PetRehomed = 'PetRehomed'
}

export type CancellationTrueupInformation = {
  finalMonthAmount?: Maybe<Scalars['Int']>;
  firstDueDate: Scalars['Date'];
  monthlyAmount: Scalars['Int'];
  months: Scalars['Int'];
  paymentMethodId?: Maybe<Scalars['String']>;
  trueUpType?: Maybe<CareSubscriptionTrueUpType>;
};

export type CanonicalBreed = {
  __typename?: 'CanonicalBreed';
  _count: CanonicalBreedCountOutputType;
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  canonicalSpecies: CanonicalSpecies;
  canonicalSpeciesId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt: Scalars['DateTime'];
};


export type CanonicalBreedInformAudienceDefinitionsArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};

export type CanonicalBreedAvgAggregateOutputType = {
  __typename?: 'CanonicalBreedAvgAggregateOutputType';
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedAvgOrderByAggregateInput = {
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
};

export type CanonicalBreedCountAggregateOutputType = {
  __typename?: 'CanonicalBreedCountAggregateOutputType';
  _all: Scalars['Int'];
  avgHeightInCm: Scalars['Int'];
  avgLifeExpectancyInYears: Scalars['Int'];
  avgWeightInKg: Scalars['Int'];
  canonicalSpeciesId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  maxHeightInCm: Scalars['Int'];
  maxLifeExpectancyInYears: Scalars['Int'];
  maxWeightInKg: Scalars['Int'];
  minHeightInCm: Scalars['Int'];
  minLifeExpectancyInYears: Scalars['Int'];
  minWeightInKg: Scalars['Int'];
  name: Scalars['Int'];
  source: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CanonicalBreedCountOrderByAggregateInput = {
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalBreedCountOutputType = {
  __typename?: 'CanonicalBreedCountOutputType';
  informAudienceDefinitions: Scalars['Int'];
};

export type CanonicalBreedCreateInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedCreateManyCanonicalSpeciesInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope = {
  data: Array<CanonicalBreedCreateManyCanonicalSpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CanonicalBreedCreateManyInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
};

export type CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalBreedCreateNestedOneWithoutCanonicalCanineBreedProfileInput = {
  connect?: Maybe<CanonicalBreedWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput>;
  create?: Maybe<CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput>;
};

export type CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput = {
  create: CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput = {
  create: CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  create: CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedCreateWithoutCanonicalCanineBreedProfileInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedCreateWithoutCanonicalSpeciesInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedCreateWithoutInformAudienceDefinitionsInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput>;
  canonicalSpecies: CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedListRelationFilter = {
  every?: Maybe<CanonicalBreedWhereInput>;
  none?: Maybe<CanonicalBreedWhereInput>;
  some?: Maybe<CanonicalBreedWhereInput>;
};

export type CanonicalBreedMaxAggregateOutputType = {
  __typename?: 'CanonicalBreedMaxAggregateOutputType';
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedMaxOrderByAggregateInput = {
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalBreedMinAggregateOutputType = {
  __typename?: 'CanonicalBreedMinAggregateOutputType';
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedMinOrderByAggregateInput = {
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalBreedNameCanonicalSpeciesIdCompoundUniqueInput = {
  canonicalSpeciesId: Scalars['String'];
  name: Scalars['String'];
};

export type CanonicalBreedOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalBreedOrderByRelevanceFieldEnum {
  CanonicalSpeciesId = 'canonicalSpeciesId',
  Description = 'description',
  Id = 'id',
  Name = 'name'
}

export type CanonicalBreedOrderByRelevanceInput = {
  fields: Array<CanonicalBreedOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CanonicalBreedOrderByWithAggregationInput = {
  _avg?: Maybe<CanonicalBreedAvgOrderByAggregateInput>;
  _count?: Maybe<CanonicalBreedCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalBreedMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalBreedMinOrderByAggregateInput>;
  _sum?: Maybe<CanonicalBreedSumOrderByAggregateInput>;
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalBreedOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CanonicalBreedOrderByRelevanceInput>;
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>;
  canonicalSpeciesId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalBreedRelationFilter = {
  is?: Maybe<CanonicalBreedWhereInput>;
  isNot?: Maybe<CanonicalBreedWhereInput>;
};

export enum CanonicalBreedScalarFieldEnum {
  AvgHeightInCm = 'avgHeightInCm',
  AvgLifeExpectancyInYears = 'avgLifeExpectancyInYears',
  AvgWeightInKg = 'avgWeightInKg',
  CanonicalSpeciesId = 'canonicalSpeciesId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  MaxHeightInCm = 'maxHeightInCm',
  MaxLifeExpectancyInYears = 'maxLifeExpectancyInYears',
  MaxWeightInKg = 'maxWeightInKg',
  MinHeightInCm = 'minHeightInCm',
  MinLifeExpectancyInYears = 'minLifeExpectancyInYears',
  MinWeightInKg = 'minWeightInKg',
  Name = 'name',
  Source = 'source',
  UpdatedAt = 'updatedAt'
}

export type CanonicalBreedScalarWhereInput = {
  AND?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  avgHeightInCm?: Maybe<FloatNullableFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  avgWeightInKg?: Maybe<FloatNullableFilter>;
  canonicalSpeciesId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  maxHeightInCm?: Maybe<FloatNullableFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  minHeightInCm?: Maybe<FloatNullableFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  name?: Maybe<StringFilter>;
  source?: Maybe<EnumCanonicalDataSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CanonicalBreedScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalBreedScalarWhereWithAggregatesInput>>;
  avgHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
  avgWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  canonicalSpeciesId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  maxHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
  maxWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  minHeightInCm?: Maybe<FloatNullableWithAggregatesFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableWithAggregatesFilter>;
  minWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  source?: Maybe<EnumCanonicalDataSourceWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CanonicalBreedSumAggregateOutputType = {
  __typename?: 'CanonicalBreedSumAggregateOutputType';
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
};

export type CanonicalBreedSumOrderByAggregateInput = {
  avgHeightInCm?: Maybe<SortOrder>;
  avgLifeExpectancyInYears?: Maybe<SortOrder>;
  avgWeightInKg?: Maybe<SortOrder>;
  maxHeightInCm?: Maybe<SortOrder>;
  maxLifeExpectancyInYears?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minHeightInCm?: Maybe<SortOrder>;
  minLifeExpectancyInYears?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
};

export type CanonicalBreedUncheckedCreateInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
  canonicalSpeciesId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
};

export type CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput>;
  canonicalSpeciesId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  source: CanonicalDataSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateManyInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
};

export type CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput>;
  canonicalSpeciesId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpdateInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpdateManyMutationInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput = {
  data: CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput;
  where: CanonicalBreedScalarWhereInput;
};

export type CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  data: CanonicalBreedUncheckedUpdateManyWithoutCanonicalBreedsInput;
  where: CanonicalBreedScalarWhereInput;
};

export type CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutCanonicalSpeciesInput>>;
  createMany?: Maybe<CanonicalBreedCreateManyCanonicalSpeciesInputEnvelope>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
};

export type CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalBreedCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalBreedCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalBreedScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalBreedWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalBreedUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalBreedUpdateOneRequiredWithoutCanonicalCanineBreedProfileNestedInput = {
  connect?: Maybe<CanonicalBreedWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalBreedCreateOrConnectWithoutCanonicalCanineBreedProfileInput>;
  create?: Maybe<CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput>;
  update?: Maybe<CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput>;
  upsert?: Maybe<CanonicalBreedUpsertWithoutCanonicalCanineBreedProfileInput>;
};

export type CanonicalBreedUpdateWithWhereUniqueWithoutCanonicalSpeciesInput = {
  data: CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  data: CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedUpdateWithoutCanonicalCanineBreedProfileInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpdateWithoutCanonicalSpeciesInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpdateWithoutInformAudienceDefinitionsInput = {
  avgHeightInCm?: Maybe<Scalars['Float']>;
  avgLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  avgWeightInKg?: Maybe<Scalars['Float']>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxHeightInCm?: Maybe<Scalars['Float']>;
  maxLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minHeightInCm?: Maybe<Scalars['Float']>;
  minLifeExpectancyInYears?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<CanonicalDataSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalBreedUpsertWithWhereUniqueWithoutCanonicalSpeciesInput = {
  create: CanonicalBreedUncheckedCreateWithoutCanonicalSpeciesInput;
  update: CanonicalBreedUncheckedUpdateWithoutCanonicalSpeciesInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  create: CanonicalBreedUncheckedCreateWithoutInformAudienceDefinitionsInput;
  update: CanonicalBreedUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalBreedWhereUniqueInput;
};

export type CanonicalBreedUpsertWithoutCanonicalCanineBreedProfileInput = {
  create: CanonicalBreedUncheckedCreateWithoutCanonicalCanineBreedProfileInput;
  update: CanonicalBreedUncheckedUpdateWithoutCanonicalCanineBreedProfileInput;
};

export type CanonicalBreedWhereInput = {
  AND?: Maybe<Array<CanonicalBreedWhereInput>>;
  NOT?: Maybe<Array<CanonicalBreedWhereInput>>;
  OR?: Maybe<Array<CanonicalBreedWhereInput>>;
  avgHeightInCm?: Maybe<FloatNullableFilter>;
  avgLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  avgWeightInKg?: Maybe<FloatNullableFilter>;
  canonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesWhereInput>;
  canonicalSpeciesId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  maxHeightInCm?: Maybe<FloatNullableFilter>;
  maxLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  minHeightInCm?: Maybe<FloatNullableFilter>;
  minLifeExpectancyInYears?: Maybe<FloatNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  name?: Maybe<StringFilter>;
  source?: Maybe<EnumCanonicalDataSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CanonicalBreedWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_canonicalSpeciesId?: Maybe<CanonicalBreedNameCanonicalSpeciesIdCompoundUniqueInput>;
};

export type CanonicalCanineBreedProfile = {
  __typename?: 'CanonicalCanineBreedProfile';
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreed: CanonicalBreed;
  canonicalBreedId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id: Scalars['String'];
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament: Array<AmericanKennelClubBreedTemperament>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt: Scalars['DateTime'];
};

export type CanonicalCanineBreedProfileAvgAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileAvgAggregateOutputType';
  demeanor?: Maybe<Scalars['Float']>;
  energyLevel?: Maybe<Scalars['Float']>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  popularity?: Maybe<Scalars['Float']>;
  sheddingValue?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
};

export type CanonicalCanineBreedProfileAvgOrderByAggregateInput = {
  demeanor?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileCountAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileCountAggregateOutputType';
  _all: Scalars['Int'];
  breedGroup: Scalars['Int'];
  canonicalBreedId: Scalars['Int'];
  createdAt: Scalars['Int'];
  demeanor: Scalars['Int'];
  demeanorCategory: Scalars['Int'];
  energyLevel: Scalars['Int'];
  energyLevelCategory: Scalars['Int'];
  groomingFrequency: Scalars['Int'];
  groomingFrequencyCategory: Scalars['Int'];
  id: Scalars['Int'];
  popularity: Scalars['Int'];
  sheddingCategory: Scalars['Int'];
  sheddingValue: Scalars['Int'];
  temperament: Scalars['Int'];
  trainability: Scalars['Int'];
  trainabilityCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CanonicalCanineBreedProfileCountOrderByAggregateInput = {
  breedGroup?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileCreateInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreed: CanonicalBreedCreateNestedOneWithoutCanonicalCanineBreedProfileInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileCreateManyInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileCreateNestedOneWithoutCanonicalBreedInput = {
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput = {
  create: CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput;
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};

export type CanonicalCanineBreedProfileCreateWithoutCanonicalBreedInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileCreatetemperamentInput = {
  set: Array<AmericanKennelClubBreedTemperament>;
};

export type CanonicalCanineBreedProfileMaxAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileMaxAggregateOutputType';
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileMaxOrderByAggregateInput = {
  breedGroup?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileMinAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileMinAggregateOutputType';
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileMinOrderByAggregateInput = {
  breedGroup?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CanonicalCanineBreedProfileOrderByRelevanceFieldEnum {
  CanonicalBreedId = 'canonicalBreedId',
  Id = 'id'
}

export type CanonicalCanineBreedProfileOrderByRelevanceInput = {
  fields: Array<CanonicalCanineBreedProfileOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CanonicalCanineBreedProfileOrderByWithAggregationInput = {
  _avg?: Maybe<CanonicalCanineBreedProfileAvgOrderByAggregateInput>;
  _count?: Maybe<CanonicalCanineBreedProfileCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalCanineBreedProfileMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalCanineBreedProfileMinOrderByAggregateInput>;
  _sum?: Maybe<CanonicalCanineBreedProfileSumOrderByAggregateInput>;
  breedGroup?: Maybe<SortOrder>;
  canonicalBreedId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CanonicalCanineBreedProfileOrderByRelevanceInput>;
  breedGroup?: Maybe<SortOrder>;
  canonicalBreed?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  canonicalBreedId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  demeanor?: Maybe<SortOrder>;
  demeanorCategory?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  energyLevelCategory?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  groomingFrequencyCategory?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingCategory?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  temperament?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
  trainabilityCategory?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileRelationFilter = {
  is?: Maybe<CanonicalCanineBreedProfileWhereInput>;
  isNot?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};

export enum CanonicalCanineBreedProfileScalarFieldEnum {
  BreedGroup = 'breedGroup',
  CanonicalBreedId = 'canonicalBreedId',
  CreatedAt = 'createdAt',
  Demeanor = 'demeanor',
  DemeanorCategory = 'demeanorCategory',
  EnergyLevel = 'energyLevel',
  EnergyLevelCategory = 'energyLevelCategory',
  GroomingFrequency = 'groomingFrequency',
  GroomingFrequencyCategory = 'groomingFrequencyCategory',
  Id = 'id',
  Popularity = 'popularity',
  SheddingCategory = 'sheddingCategory',
  SheddingValue = 'sheddingValue',
  Temperament = 'temperament',
  Trainability = 'trainability',
  TrainabilityCategory = 'trainabilityCategory',
  UpdatedAt = 'updatedAt'
}

export type CanonicalCanineBreedProfileScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalCanineBreedProfileScalarWhereWithAggregatesInput>>;
  breedGroup?: Maybe<EnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  canonicalBreedId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  demeanor?: Maybe<FloatNullableWithAggregatesFilter>;
  demeanorCategory?: Maybe<EnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
  energyLevel?: Maybe<FloatNullableWithAggregatesFilter>;
  energyLevelCategory?: Maybe<EnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  groomingFrequency?: Maybe<FloatNullableWithAggregatesFilter>;
  groomingFrequencyCategory?: Maybe<EnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  popularity?: Maybe<IntNullableWithAggregatesFilter>;
  sheddingCategory?: Maybe<EnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  sheddingValue?: Maybe<FloatNullableWithAggregatesFilter>;
  temperament?: Maybe<EnumAmericanKennelClubBreedTemperamentNullableListFilter>;
  trainability?: Maybe<FloatNullableWithAggregatesFilter>;
  trainabilityCategory?: Maybe<EnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CanonicalCanineBreedProfileSumAggregateOutputType = {
  __typename?: 'CanonicalCanineBreedProfileSumAggregateOutputType';
  demeanor?: Maybe<Scalars['Float']>;
  energyLevel?: Maybe<Scalars['Float']>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingValue?: Maybe<Scalars['Float']>;
  trainability?: Maybe<Scalars['Float']>;
};

export type CanonicalCanineBreedProfileSumOrderByAggregateInput = {
  demeanor?: Maybe<SortOrder>;
  energyLevel?: Maybe<SortOrder>;
  groomingFrequency?: Maybe<SortOrder>;
  popularity?: Maybe<SortOrder>;
  sheddingValue?: Maybe<SortOrder>;
  trainability?: Maybe<SortOrder>;
};

export type CanonicalCanineBreedProfileUncheckedCreateInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUncheckedCreateNestedOneWithoutCanonicalBreedInput = {
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateManyInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreedId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateOneWithoutCanonicalBreedNestedInput = {
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput>;
  upsert?: Maybe<CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUpdateInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  canonicalBreed?: Maybe<CanonicalBreedUpdateOneRequiredWithoutCanonicalCanineBreedProfileNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUpdateManyMutationInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUpdateOneWithoutCanonicalBreedNestedInput = {
  connect?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalCanineBreedProfileCreateOrConnectWithoutCanonicalBreedInput>;
  create?: Maybe<CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput>;
  upsert?: Maybe<CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput>;
};

export type CanonicalCanineBreedProfileUpdateWithoutCanonicalBreedInput = {
  breedGroup?: Maybe<AmericanKennelClubBreedGroup>;
  createdAt?: Maybe<Scalars['DateTime']>;
  demeanor?: Maybe<Scalars['Float']>;
  demeanorCategory?: Maybe<AmericanKennelClubDemeanorCategory>;
  energyLevel?: Maybe<Scalars['Float']>;
  energyLevelCategory?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  groomingFrequency?: Maybe<Scalars['Float']>;
  groomingFrequencyCategory?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  id?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  sheddingCategory?: Maybe<AmericanKennelClubSheddingCategory>;
  sheddingValue?: Maybe<Scalars['Float']>;
  temperament?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  trainability?: Maybe<Scalars['Float']>;
  trainabilityCategory?: Maybe<AmericanKennelClubTrainabilityCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalCanineBreedProfileUpdatetemperamentInput = {
  push?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  set?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
};

export type CanonicalCanineBreedProfileUpsertWithoutCanonicalBreedInput = {
  create: CanonicalCanineBreedProfileUncheckedCreateWithoutCanonicalBreedInput;
  update: CanonicalCanineBreedProfileUncheckedUpdateWithoutCanonicalBreedInput;
};

export type CanonicalCanineBreedProfileWhereInput = {
  AND?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  NOT?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  OR?: Maybe<Array<CanonicalCanineBreedProfileWhereInput>>;
  breedGroup?: Maybe<EnumAmericanKennelClubBreedGroupNullableFilter>;
  canonicalBreed?: Maybe<CanonicalBreedWhereInput>;
  canonicalBreedId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  demeanor?: Maybe<FloatNullableFilter>;
  demeanorCategory?: Maybe<EnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  energyLevel?: Maybe<FloatNullableFilter>;
  energyLevelCategory?: Maybe<EnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  groomingFrequency?: Maybe<FloatNullableFilter>;
  groomingFrequencyCategory?: Maybe<EnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  id?: Maybe<StringFilter>;
  popularity?: Maybe<IntNullableFilter>;
  sheddingCategory?: Maybe<EnumAmericanKennelClubSheddingCategoryNullableFilter>;
  sheddingValue?: Maybe<FloatNullableFilter>;
  temperament?: Maybe<EnumAmericanKennelClubBreedTemperamentNullableListFilter>;
  trainability?: Maybe<FloatNullableFilter>;
  trainabilityCategory?: Maybe<EnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CanonicalCanineBreedProfileWhereUniqueInput = {
  canonicalBreedId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum CanonicalDataSource {
  AmericanKennelClub = 'AmericanKennelClub'
}

export type CanonicalService = {
  __typename?: 'CanonicalService';
  _count: CanonicalServiceCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  informCampaignEnrollmentCanonicalServices: Array<InformCampaignEnrollmentCanonicalService>;
  name: Scalars['String'];
  product?: Maybe<Product>;
  services: Array<Service>;
  status: CanonicalServiceStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt: Scalars['DateTime'];
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<User>;
  validatedByUserId?: Maybe<Scalars['String']>;
};


export type CanonicalServiceInformAudienceDefinitionsArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type CanonicalServiceInformCampaignEnrollmentCanonicalServicesArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type CanonicalServiceServicesArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};

export type CanonicalServiceCountAggregateOutputType = {
  __typename?: 'CanonicalServiceCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  displayName: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  validatedAt: Scalars['Int'];
  validatedByUserId: Scalars['Int'];
};

export type CanonicalServiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
};

export type CanonicalServiceCountOutputType = {
  __typename?: 'CanonicalServiceCountOutputType';
  informAudienceDefinitions: Scalars['Int'];
  informCampaignEnrollmentCanonicalServices: Scalars['Int'];
  services: Scalars['Int'];
};

export type CanonicalServiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
};

export type CanonicalServiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceCreateManyValidatedByUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceCreateManyValidatedByUserInputEnvelope = {
  data: Array<CanonicalServiceCreateManyValidatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalServiceCreateNestedManyWithoutValidatedByUserInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
};

export type CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type CanonicalServiceCreateNestedOneWithoutProductInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutProductInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutProductInput>;
};

export type CanonicalServiceCreateNestedOneWithoutServicesInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutServicesInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutServicesInput>;
};

export type CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  create: CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create: CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceCreateOrConnectWithoutProductInput = {
  create: CanonicalServiceUncheckedCreateWithoutProductInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceCreateOrConnectWithoutServicesInput = {
  create: CanonicalServiceUncheckedCreateWithoutServicesInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceCreateOrConnectWithoutValidatedByUserInput = {
  create: CanonicalServiceUncheckedCreateWithoutValidatedByUserInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceCreateWithoutInformAudienceDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
};

export type CanonicalServiceCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
};

export type CanonicalServiceCreateWithoutProductInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
};

export type CanonicalServiceCreateWithoutServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserCreateNestedOneWithoutValidatedCanonicalServicesInput>;
};

export type CanonicalServiceCreateWithoutValidatedByUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceListRelationFilter = {
  every?: Maybe<CanonicalServiceWhereInput>;
  none?: Maybe<CanonicalServiceWhereInput>;
  some?: Maybe<CanonicalServiceWhereInput>;
};

export type CanonicalServiceMaxAggregateOutputType = {
  __typename?: 'CanonicalServiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
};

export type CanonicalServiceMinAggregateOutputType = {
  __typename?: 'CanonicalServiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
};

export type CanonicalServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalServiceOrderByRelevanceFieldEnum {
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  Name = 'name',
  ValidatedByUserId = 'validatedByUserId'
}

export type CanonicalServiceOrderByRelevanceInput = {
  fields: Array<CanonicalServiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CanonicalServiceOrderByWithAggregationInput = {
  _count?: Maybe<CanonicalServiceCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalServiceMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalServiceMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUserId?: Maybe<SortOrder>;
};

export type CanonicalServiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CanonicalServiceOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  product?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validatedAt?: Maybe<SortOrder>;
  validatedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  validatedByUserId?: Maybe<SortOrder>;
};

export type CanonicalServiceRelationFilter = {
  is?: Maybe<CanonicalServiceWhereInput>;
  isNot?: Maybe<CanonicalServiceWhereInput>;
};

export enum CanonicalServiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  ValidatedAt = 'validatedAt',
  ValidatedByUserId = 'validatedByUserId'
}

export type CanonicalServiceScalarWhereInput = {
  AND?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumCanonicalServiceStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumCanonicalServiceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  validatedAt?: Maybe<DateTimeNullableFilter>;
  validatedByUserId?: Maybe<StringNullableFilter>;
};

export type CanonicalServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalServiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumCanonicalServiceStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumCanonicalServiceTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  validatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  validatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum CanonicalServiceStatus {
  NeedsReview = 'NeedsReview',
  Validated = 'Validated'
}

export enum CanonicalServiceType {
  Product = 'Product'
}

export type CanonicalServiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
};

export type CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  name: Scalars['String'];
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedCreateWithoutProductInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedCreateWithoutServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedCreateWithoutValidatedByUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  name: Scalars['String'];
  product?: Maybe<ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: CanonicalServiceType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput>>;
};

export type CanonicalServiceUncheckedUpdateManyWithoutValidatedCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateWithoutProductInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateWithoutServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUserId?: Maybe<Scalars['String']>;
};

export type CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
};

export type CanonicalServiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  data: CanonicalServiceUncheckedUpdateManyWithoutCanonicalServicesInput;
  where: CanonicalServiceScalarWhereInput;
};

export type CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput = {
  data: CanonicalServiceUncheckedUpdateManyWithoutValidatedCanonicalServicesInput;
  where: CanonicalServiceScalarWhereInput;
};

export type CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput = {
  connect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalServiceCreateOrConnectWithoutValidatedByUserInput>>;
  create?: Maybe<Array<CanonicalServiceCreateWithoutValidatedByUserInput>>;
  createMany?: Maybe<CanonicalServiceCreateManyValidatedByUserInputEnvelope>;
  delete?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput>>;
  updateMany?: Maybe<Array<CanonicalServiceUpdateManyWithWhereWithoutValidatedByUserInput>>;
  upsert?: Maybe<Array<CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput>>;
};

export type CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type CanonicalServiceUpdateOneRequiredWithoutProductNestedInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutProductInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutProductInput>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutProductInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutProductInput>;
};

export type CanonicalServiceUpdateOneWithoutServicesNestedInput = {
  connect?: Maybe<CanonicalServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalServiceCreateOrConnectWithoutServicesInput>;
  create?: Maybe<CanonicalServiceUncheckedCreateWithoutServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CanonicalServiceUncheckedUpdateWithoutServicesInput>;
  upsert?: Maybe<CanonicalServiceUpsertWithoutServicesInput>;
};

export type CanonicalServiceUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  data: CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceUpdateWithWhereUniqueWithoutValidatedByUserInput = {
  data: CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceUpdateWithoutInformAudienceDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
};

export type CanonicalServiceUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
};

export type CanonicalServiceUpdateWithoutProductInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
};

export type CanonicalServiceUpdateWithoutServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
  validatedByUser?: Maybe<UserUpdateOneWithoutValidatedCanonicalServicesNestedInput>;
};

export type CanonicalServiceUpdateWithoutValidatedByUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductUpdateOneWithoutCanonicalServiceNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutCanonicalServiceNestedInput>;
  status?: Maybe<CanonicalServiceStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<CanonicalServiceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalServiceUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  create: CanonicalServiceUncheckedCreateWithoutInformAudienceDefinitionsInput;
  update: CanonicalServiceUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceUpsertWithWhereUniqueWithoutValidatedByUserInput = {
  create: CanonicalServiceUncheckedCreateWithoutValidatedByUserInput;
  update: CanonicalServiceUncheckedUpdateWithoutValidatedByUserInput;
  where: CanonicalServiceWhereUniqueInput;
};

export type CanonicalServiceUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create: CanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  update: CanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type CanonicalServiceUpsertWithoutProductInput = {
  create: CanonicalServiceUncheckedCreateWithoutProductInput;
  update: CanonicalServiceUncheckedUpdateWithoutProductInput;
};

export type CanonicalServiceUpsertWithoutServicesInput = {
  create: CanonicalServiceUncheckedCreateWithoutServicesInput;
  update: CanonicalServiceUncheckedUpdateWithoutServicesInput;
};

export type CanonicalServiceWhereInput = {
  AND?: Maybe<Array<CanonicalServiceWhereInput>>;
  NOT?: Maybe<Array<CanonicalServiceWhereInput>>;
  OR?: Maybe<Array<CanonicalServiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceListRelationFilter>;
  name?: Maybe<StringFilter>;
  product?: Maybe<ProductWhereInput>;
  services?: Maybe<ServiceListRelationFilter>;
  status?: Maybe<EnumCanonicalServiceStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumCanonicalServiceTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  validatedAt?: Maybe<DateTimeNullableFilter>;
  validatedByUser?: Maybe<UserWhereInput>;
  validatedByUserId?: Maybe<StringNullableFilter>;
};

export type CanonicalServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CanonicalSpecies = {
  __typename?: 'CanonicalSpecies';
  _count: CanonicalSpeciesCountOutputType;
  canonicalBreeds: Array<CanonicalBreed>;
  class?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type CanonicalSpeciesCanonicalBreedsArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  distinct?: Maybe<CanonicalBreedScalarFieldEnum>;
  orderBy?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type CanonicalSpeciesInformAudienceDefinitionsArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};

export type CanonicalSpeciesCountAggregateOutputType = {
  __typename?: 'CanonicalSpeciesCountAggregateOutputType';
  _all: Scalars['Int'];
  class: Scalars['Int'];
  createdAt: Scalars['Int'];
  family: Scalars['Int'];
  genus: Scalars['Int'];
  id: Scalars['Int'];
  kingdom: Scalars['Int'];
  name: Scalars['Int'];
  order: Scalars['Int'];
  phylum: Scalars['Int'];
  scientificName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CanonicalSpeciesCountOrderByAggregateInput = {
  class?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalSpeciesCountOutputType = {
  __typename?: 'CanonicalSpeciesCountOutputType';
  canonicalBreeds: Scalars['Int'];
  informAudienceDefinitions: Scalars['Int'];
};

export type CanonicalSpeciesCreateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesCreateManyInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalSpeciesCreateNestedOneWithoutCanonicalBreedsInput = {
  connect?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput>;
  create?: Maybe<CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput>;
};

export type CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput = {
  create: CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput;
  where: CanonicalSpeciesWhereUniqueInput;
};

export type CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  create: CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput;
  where: CanonicalSpeciesWhereUniqueInput;
};

export type CanonicalSpeciesCreateWithoutCanonicalBreedsInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutCanonicalSpeciesInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesListRelationFilter = {
  every?: Maybe<CanonicalSpeciesWhereInput>;
  none?: Maybe<CanonicalSpeciesWhereInput>;
  some?: Maybe<CanonicalSpeciesWhereInput>;
};

export type CanonicalSpeciesMaxAggregateOutputType = {
  __typename?: 'CanonicalSpeciesMaxAggregateOutputType';
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesMaxOrderByAggregateInput = {
  class?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalSpeciesMinAggregateOutputType = {
  __typename?: 'CanonicalSpeciesMinAggregateOutputType';
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesMinOrderByAggregateInput = {
  class?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalSpeciesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CanonicalSpeciesOrderByRelevanceFieldEnum {
  Class = 'class',
  Family = 'family',
  Genus = 'genus',
  Id = 'id',
  Kingdom = 'kingdom',
  Name = 'name',
  Order = 'order',
  Phylum = 'phylum',
  ScientificName = 'scientificName'
}

export type CanonicalSpeciesOrderByRelevanceInput = {
  fields: Array<CanonicalSpeciesOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CanonicalSpeciesOrderByWithAggregationInput = {
  _count?: Maybe<CanonicalSpeciesCountOrderByAggregateInput>;
  _max?: Maybe<CanonicalSpeciesMaxOrderByAggregateInput>;
  _min?: Maybe<CanonicalSpeciesMinOrderByAggregateInput>;
  class?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kingdom?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CanonicalSpeciesOrderByRelevanceInput>;
  canonicalBreeds?: Maybe<CanonicalBreedOrderByRelationAggregateInput>;
  class?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  family?: Maybe<SortOrder>;
  genus?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  kingdom?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  phylum?: Maybe<SortOrder>;
  scientificName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CanonicalSpeciesRelationFilter = {
  is?: Maybe<CanonicalSpeciesWhereInput>;
  isNot?: Maybe<CanonicalSpeciesWhereInput>;
};

export enum CanonicalSpeciesScalarFieldEnum {
  Class = 'class',
  CreatedAt = 'createdAt',
  Family = 'family',
  Genus = 'genus',
  Id = 'id',
  Kingdom = 'kingdom',
  Name = 'name',
  Order = 'order',
  Phylum = 'phylum',
  ScientificName = 'scientificName',
  UpdatedAt = 'updatedAt'
}

export type CanonicalSpeciesScalarWhereInput = {
  AND?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  OR?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  class?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  family?: Maybe<StringNullableFilter>;
  genus?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  kingdom?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<StringNullableFilter>;
  phylum?: Maybe<StringNullableFilter>;
  scientificName?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CanonicalSpeciesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CanonicalSpeciesScalarWhereWithAggregatesInput>>;
  class?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  family?: Maybe<StringNullableWithAggregatesFilter>;
  genus?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  kingdom?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<StringNullableWithAggregatesFilter>;
  phylum?: Maybe<StringNullableWithAggregatesFilter>;
  scientificName?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CanonicalSpeciesUncheckedCreateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutCanonicalSpeciesInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedUpdateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedUpdateManyInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedUpdateManyWithoutCanonicalSpeciesInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUpdateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUpdateManyMutationInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput = {
  data: CanonicalSpeciesUncheckedUpdateManyWithoutCanonicalSpeciesInput;
  where: CanonicalSpeciesScalarWhereInput;
};

export type CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CanonicalSpeciesCreateOrConnectWithoutInformAudienceDefinitionsInput>>;
  create?: Maybe<Array<CanonicalSpeciesCreateWithoutInformAudienceDefinitionsInput>>;
  delete?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CanonicalSpeciesScalarWhereInput>>;
  disconnect?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  set?: Maybe<Array<CanonicalSpeciesWhereUniqueInput>>;
  update?: Maybe<Array<CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
  updateMany?: Maybe<Array<CanonicalSpeciesUpdateManyWithWhereWithoutInformAudienceDefinitionsInput>>;
  upsert?: Maybe<Array<CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput>>;
};

export type CanonicalSpeciesUpdateOneRequiredWithoutCanonicalBreedsNestedInput = {
  connect?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<CanonicalSpeciesCreateOrConnectWithoutCanonicalBreedsInput>;
  create?: Maybe<CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput>;
  update?: Maybe<CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput>;
  upsert?: Maybe<CanonicalSpeciesUpsertWithoutCanonicalBreedsInput>;
};

export type CanonicalSpeciesUpdateWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  data: CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalSpeciesWhereUniqueInput;
};

export type CanonicalSpeciesUpdateWithoutCanonicalBreedsInput = {
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUpdateWithoutInformAudienceDefinitionsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutCanonicalSpeciesNestedInput>;
  class?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  family?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kingdom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  phylum?: Maybe<Scalars['String']>;
  scientificName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CanonicalSpeciesUpsertWithWhereUniqueWithoutInformAudienceDefinitionsInput = {
  create: CanonicalSpeciesUncheckedCreateWithoutInformAudienceDefinitionsInput;
  update: CanonicalSpeciesUncheckedUpdateWithoutInformAudienceDefinitionsInput;
  where: CanonicalSpeciesWhereUniqueInput;
};

export type CanonicalSpeciesUpsertWithoutCanonicalBreedsInput = {
  create: CanonicalSpeciesUncheckedCreateWithoutCanonicalBreedsInput;
  update: CanonicalSpeciesUncheckedUpdateWithoutCanonicalBreedsInput;
};

export type CanonicalSpeciesWhereInput = {
  AND?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  NOT?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  OR?: Maybe<Array<CanonicalSpeciesWhereInput>>;
  canonicalBreeds?: Maybe<CanonicalBreedListRelationFilter>;
  class?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  family?: Maybe<StringNullableFilter>;
  genus?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  kingdom?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<StringNullableFilter>;
  phylum?: Maybe<StringNullableFilter>;
  scientificName?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CanonicalSpeciesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum CardBrand {
  Amex = 'Amex',
  Diners = 'Diners',
  Discover = 'Discover',
  Jcb = 'JCB',
  Mastercard = 'Mastercard',
  UnionPay = 'UnionPay',
  Unknown = 'Unknown',
  Visa = 'Visa'
}

export enum CardFunding {
  Credit = 'credit',
  Debit = 'debit',
  Prepaid = 'prepaid',
  Unknown = 'unknown'
}

export type CareAccountCreditIntent = {
  __typename?: 'CareAccountCreditIntent';
  _count: CareAccountCreditIntentCountOutputType;
  careAccountCreditPimsWritebackLogEntries: Array<CareAccountCreditPimsWritebackLogEntry>;
  careBenefitUsages: Array<CareBenefitUsage>;
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  followupStep?: Maybe<CareBenefitUsageFollowupStep>;
  followupStepId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue: Scalars['Int'];
  stripePaymentIntents: Array<StripePaymentIntent>;
  updatedAt: Scalars['DateTime'];
};


export type CareAccountCreditIntentCareAccountCreditPimsWritebackLogEntriesArgs = {
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};


export type CareAccountCreditIntentCareBenefitUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type CareAccountCreditIntentStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type CareAccountCreditIntentAvgAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentAvgAggregateOutputType';
  paymentDue?: Maybe<Scalars['Float']>;
};

export type CareAccountCreditIntentAvgOrderByAggregateInput = {
  paymentDue?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentCountAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  followupStepId: Scalars['Int'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  paymentDue: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareAccountCreditIntentCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentCountOutputType = {
  __typename?: 'CareAccountCreditIntentCountOutputType';
  careAccountCreditPimsWritebackLogEntries: Scalars['Int'];
  careBenefitUsages: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
};

export type CareAccountCreditIntentCreateInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateManyFollowupStepInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateManyFollowupStepInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyFollowupStepInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateManyInvoiceInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateManyInvoiceInputEnvelope = {
  data: Array<CareAccountCreditIntentCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
};

export type CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput>;
};

export type CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput>;
};

export type CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateWithoutCareBenefitUsagesInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateWithoutClinicPetParentInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateWithoutFollowupStepInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateWithoutInvoiceInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentCreateWithoutStripePaymentIntentsInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentListRelationFilter = {
  every?: Maybe<CareAccountCreditIntentWhereInput>;
  none?: Maybe<CareAccountCreditIntentWhereInput>;
  some?: Maybe<CareAccountCreditIntentWhereInput>;
};

export type CareAccountCreditIntentMaxAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentMinAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAccountCreditIntentOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  FollowupStepId = 'followupStepId',
  Id = 'id',
  InvoiceId = 'invoiceId'
}

export type CareAccountCreditIntentOrderByRelevanceInput = {
  fields: Array<CareAccountCreditIntentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAccountCreditIntentOrderByWithAggregationInput = {
  _avg?: Maybe<CareAccountCreditIntentAvgOrderByAggregateInput>;
  _count?: Maybe<CareAccountCreditIntentCountOrderByAggregateInput>;
  _max?: Maybe<CareAccountCreditIntentMaxOrderByAggregateInput>;
  _min?: Maybe<CareAccountCreditIntentMinOrderByAggregateInput>;
  _sum?: Maybe<CareAccountCreditIntentSumOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  followupStepId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAccountCreditIntentOrderByRelevanceInput>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByRelationAggregateInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  followupStepId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  invoiceId?: Maybe<SortOrder>;
  paymentDue?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentRelationFilter = {
  is?: Maybe<CareAccountCreditIntentWhereInput>;
  isNot?: Maybe<CareAccountCreditIntentWhereInput>;
};

export enum CareAccountCreditIntentScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  FollowupStepId = 'followupStepId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  PaymentDue = 'paymentDue',
  UpdatedAt = 'updatedAt'
}

export type CareAccountCreditIntentScalarWhereInput = {
  AND?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  followupStepId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringNullableFilter>;
  paymentDue?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAccountCreditIntentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  followupStepId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentDue?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareAccountCreditIntentSumAggregateOutputType = {
  __typename?: 'CareAccountCreditIntentSumAggregateOutputType';
  paymentDue?: Maybe<Scalars['Int']>;
};

export type CareAccountCreditIntentSumOrderByAggregateInput = {
  paymentDue?: Maybe<SortOrder>;
};

export type CareAccountCreditIntentUncheckedCreateInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
};

export type CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStepId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
  where: CareAccountCreditIntentScalarWhereInput;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput = {
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
  where: CareAccountCreditIntentScalarWhereInput;
};

export type CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput = {
  data: CareAccountCreditIntentUncheckedUpdateManyWithoutCareAccountCreditIntentsInput;
  where: CareAccountCreditIntentScalarWhereInput;
};

export type CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutFollowupStepInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutFollowupStepInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyFollowupStepInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutFollowupStepInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput>>;
};

export type CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditIntentCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<CareAccountCreditIntentCreateWithoutInvoiceInput>>;
  createMany?: Maybe<CareAccountCreditIntentCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditIntentWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<CareAccountCreditIntentUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutCareAccountCreditPimsWritebackLogEntriesInput>;
};

export type CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutCareBenefitUsagesInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutCareBenefitUsagesInput>;
};

export type CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditIntentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<CareAccountCreditIntentUpsertWithoutStripePaymentIntentsInput>;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutFollowupStepInput = {
  data: CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateWithoutCareBenefitUsagesInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateWithoutClinicPetParentInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateWithoutFollowupStepInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateWithoutInvoiceInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  paymentDue?: Maybe<Scalars['Int']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpdateWithoutStripePaymentIntentsInput = {
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput>;
  paymentDue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutClinicPetParentInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutClinicPetParentInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutFollowupStepInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutFollowupStepInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutFollowupStepInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutInvoiceInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutInvoiceInput;
  where: CareAccountCreditIntentWhereUniqueInput;
};

export type CareAccountCreditIntentUpsertWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditIntentUpsertWithoutCareBenefitUsagesInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutCareBenefitUsagesInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutCareBenefitUsagesInput;
};

export type CareAccountCreditIntentUpsertWithoutStripePaymentIntentsInput = {
  create: CareAccountCreditIntentUncheckedCreateWithoutStripePaymentIntentsInput;
  update: CareAccountCreditIntentUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type CareAccountCreditIntentWhereInput = {
  AND?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditIntentWhereInput>>;
  careAccountCreditPimsWritebackLogEntries?: Maybe<CareAccountCreditPimsWritebackLogEntryListRelationFilter>;
  careBenefitUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  followupStep?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  followupStepId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoiceId?: Maybe<StringNullableFilter>;
  paymentDue?: Maybe<IntFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAccountCreditIntentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntry = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntry';
  careAccountCreditIntent: CareAccountCreditIntent;
  careAccountCreditIntentId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  writebackLogId: Scalars['Int'];
};

export type CareAccountCreditPimsWritebackLogEntryCountOrderByAggregateInput = {
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateInput = {
  careAccountCreditIntent: CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInput = {
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateManyInput = {
  careAccountCreditIntentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput = {
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput;
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput = {
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  careAccountCreditIntent: CareAccountCreditIntentCreateNestedOneWithoutCareAccountCreditPimsWritebackLogEntriesInput;
};

export type CareAccountCreditPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  none?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  some?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};

export type CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryMaxAggregateOutputType';
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryMaxOrderByAggregateInput = {
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'CareAccountCreditPimsWritebackLogEntryMinAggregateOutputType';
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryMinOrderByAggregateInput = {
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAccountCreditPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  WritebackLogId = 'writebackLogId'
}

export type CareAccountCreditPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<CareAccountCreditPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAccountCreditPimsWritebackLogEntryOrderByWithAggregationInput = {
  _count?: Maybe<CareAccountCreditPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<CareAccountCreditPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CareAccountCreditPimsWritebackLogEntryMinOrderByAggregateInput>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByRelevanceInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CareAccountCreditPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};

export enum CareAccountCreditPimsWritebackLogEntryScalarFieldEnum {
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  WritebackLogId = 'writebackLogId'
}

export type CareAccountCreditPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  careAccountCreditIntentId?: Maybe<StringFilter>;
  writebackLogId?: Maybe<StringFilter>;
};

export type CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  careAccountCreditIntentId?: Maybe<StringWithAggregatesFilter>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateInput = {
  careAccountCreditIntentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput = {
  writebackLogId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  careAccountCreditIntentId: Scalars['String'];
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditPimsWritebackLogEntriesInput = {
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateInput = {
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  data: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateManyWithoutCareAccountCreditPimsWritebackLogEntriesInput;
  where: CareAccountCreditPimsWritebackLogEntryScalarWhereInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  data: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithoutCareAccountCreditIntentInput = {
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneRequiredWithoutCareAccountCreditPimsWritebackLogEntriesNestedInput>;
};

export type CareAccountCreditPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditIntentInput;
  update: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};

export type CareAccountCreditPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  create: CareAccountCreditPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  update: CareAccountCreditPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
};

export type CareAccountCreditPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<CareAccountCreditPimsWritebackLogEntryWhereInput>>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
  careAccountCreditIntentId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  writebackLogId?: Maybe<StringFilter>;
};

export type CareAccountCreditPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_careAccountCreditIntentId?: Maybe<CareAccountCreditPimsWritebackLogEntryWritebackLogIdCareAccountCreditIntentIdCompoundUniqueInput>;
};

export type CareAccountCreditPimsWritebackLogEntryWritebackLogIdCareAccountCreditIntentIdCompoundUniqueInput = {
  careAccountCreditIntentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type CareAddonEnrollment = {
  __typename?: 'CareAddonEnrollment';
  _count: CareAddonEnrollmentCountOutputType;
  addOnPackage: CareAddonPackage;
  addOnPackageId: Scalars['String'];
  associatedUsages: Array<CareBenefitUsage>;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enrollmentPayments: Array<CareEnrollmentPayment>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  organizationPet: OrganizationPet;
  organizationPetId: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: EnrollmentStatus;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type CareAddonEnrollmentAssociatedUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type CareAddonEnrollmentEnrollmentPaymentsArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type CareAddonEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type CareAddonEnrollmentCountAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentCountAggregateOutputType';
  _all: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  createdAt: Scalars['Int'];
  expirationDate: Scalars['Int'];
  id: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  startDate: Scalars['Int'];
  status: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareAddonEnrollmentCountOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentCountOutputType = {
  __typename?: 'CareAddonEnrollmentCountOutputType';
  associatedUsages: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  enrollmentToStripeInvoiceItems: Scalars['Int'];
};

export type CareAddonEnrollmentCreateInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInput = {
  addOnPackageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateManyInput = {
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInput = {
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CareAddonEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
};

export type CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentCreateWithoutAddOnPackageInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateWithoutAssociatedUsagesInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentCreateWithoutOrganizationPetInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutEnrollmentsInput;
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentListRelationFilter = {
  every?: Maybe<CareAddonEnrollmentWhereInput>;
  none?: Maybe<CareAddonEnrollmentWhereInput>;
  some?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type CareAddonEnrollmentMaxAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMaxAggregateOutputType';
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentMaxOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentMinAggregateOutputType = {
  __typename?: 'CareAddonEnrollmentMinAggregateOutputType';
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentMinOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonEnrollmentOrderByRelevanceFieldEnum {
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type CareAddonEnrollmentOrderByRelevanceInput = {
  fields: Array<CareAddonEnrollmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAddonEnrollmentOrderByWithAggregationInput = {
  _count?: Maybe<CareAddonEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CareAddonEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonEnrollmentMinOrderByAggregateInput>;
  addOnPackageId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAddonEnrollmentOrderByRelevanceInput>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackageId?: Maybe<SortOrder>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonEnrollmentRelationFilter = {
  is?: Maybe<CareAddonEnrollmentWhereInput>;
  isNot?: Maybe<CareAddonEnrollmentWhereInput>;
};

export enum CareAddonEnrollmentScalarFieldEnum {
  AddOnPackageId = 'addOnPackageId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  StartDate = 'startDate',
  Status = 'status',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  UpdatedAt = 'updatedAt'
}

export type CareAddonEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  addOnPackageId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  organizationPetId?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentScalarWhereWithAggregatesInput>>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareAddonEnrollmentUncheckedCreateInput = {
  addOnPackageId: Scalars['String'];
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  addOnPackageId: Scalars['String'];
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  addOnPackageId: Scalars['String'];
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  addOnPackageId: Scalars['String'];
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  addOnPackageId: Scalars['String'];
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  addOnPackageId: Scalars['String'];
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput = {
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
  where: CareAddonEnrollmentScalarWhereInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
  where: CareAddonEnrollmentScalarWhereInput;
};

export type CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  data: CareAddonEnrollmentUncheckedUpdateManyWithoutCareAddonEnrollmentsInput;
  where: CareAddonEnrollmentScalarWhereInput;
};

export type CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyAddOnPackageInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CareAddonEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CareAddonEnrollmentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareAddonEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
};

export type CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  connect?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  data: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  data: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  data: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpdateWithoutAddOnPackageInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateWithoutAssociatedUsagesInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpdateWithoutOrganizationPetInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutAddOnPackageInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutAddOnPackageInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutOrganizationPetInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};

export type CareAddonEnrollmentUpsertWithoutAssociatedUsagesInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
};

export type CareAddonEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: CareAddonEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  update: CareAddonEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareAddonEnrollmentWhereInput = {
  AND?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  OR?: Maybe<Array<CareAddonEnrollmentWhereInput>>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
  addOnPackageId?: Maybe<StringFilter>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetId?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAddonPackage = {
  __typename?: 'CareAddonPackage';
  _count: CareAddonPackageCountOutputType;
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit: Array<CareAddonPackageBenefit>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle: CarePlanCycleType;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes: Scalars['String'];
  enrollments: Array<CareAddonEnrollment>;
  id: Scalars['String'];
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal: Scalars['Int'];
  published: Scalars['Boolean'];
  renewalCycle: CarePlanCycleType;
  salesTaxCentsPerMonth: Scalars['Int'];
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CareAddonPackageAddonPackageBenefitArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type CareAddonPackageEnrollmentsArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};

export type CareAddonPackageAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageAvgAggregateOutputType';
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  pricePerRenewal?: Maybe<Scalars['Float']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageAvgOrderByAggregateInput = {
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefit = {
  __typename?: 'CareAddonPackageBenefit';
  addOnPackage: CareAddonPackage;
  addOnPackageId: Scalars['String'];
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput = {
  addOnPackageId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type CareAddonPackageBenefitAvgAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
  includedUses?: Maybe<Scalars['Float']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Float']>;
};

export type CareAddonPackageBenefitAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCountAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitCountAggregateOutputType';
  _all: Scalars['Int'];
  addOnPackageId: Scalars['Int'];
  benefitId: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayOrder: Scalars['Int'];
  id: Scalars['Int'];
  includedUses: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  reportingExpectedUsages: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareAddonPackageBenefitCountOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitCreateInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyAddOnPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInput = {
  addOnPackageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CareAddonPackageBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageBenefitCreateManyInput = {
  addOnPackageId: Scalars['String'];
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
};

export type CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput = {
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput = {
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitCreateWithoutAddOnPackageInput = {
  benefit: CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitCreateWithoutBenefitInput = {
  addOnPackage: CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitListRelationFilter = {
  every?: Maybe<CareAddonPackageBenefitWhereInput>;
  none?: Maybe<CareAddonPackageBenefitWhereInput>;
  some?: Maybe<CareAddonPackageBenefitWhereInput>;
};

export type CareAddonPackageBenefitMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMaxAggregateOutputType';
  addOnPackageId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitMaxOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitMinAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitMinAggregateOutputType';
  addOnPackageId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitMinOrderByAggregateInput = {
  addOnPackageId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageBenefitOrderByRelevanceFieldEnum {
  AddOnPackageId = 'addOnPackageId',
  BenefitId = 'benefitId',
  Id = 'id',
  ProjectedSavings = 'projectedSavings'
}

export type CareAddonPackageBenefitOrderByRelevanceInput = {
  fields: Array<CareAddonPackageBenefitOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAddonPackageBenefitOrderByWithAggregationInput = {
  _avg?: Maybe<CareAddonPackageBenefitAvgOrderByAggregateInput>;
  _count?: Maybe<CareAddonPackageBenefitCountOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageBenefitSumOrderByAggregateInput>;
  addOnPackageId?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAddonPackageBenefitOrderByRelevanceInput>;
  addOnPackage?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  addOnPackageId?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CareAddonPackageBenefitScalarFieldEnum {
  AddOnPackageId = 'addOnPackageId',
  BenefitId = 'benefitId',
  CreatedAt = 'createdAt',
  DisplayOrder = 'displayOrder',
  Id = 'id',
  IncludedUses = 'includedUses',
  ProjectedSavings = 'projectedSavings',
  ReportingExpectedUsages = 'reportingExpectedUsages',
  ReportingExpectedValue = 'reportingExpectedValue',
  UpdatedAt = 'updatedAt'
}

export type CareAddonPackageBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  addOnPackageId?: Maybe<StringFilter>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  reportingExpectedUsages?: Maybe<IntFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonPackageBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitScalarWhereWithAggregatesInput>>;
  addOnPackageId?: Maybe<StringWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
  reportingExpectedUsages?: Maybe<IntWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareAddonPackageBenefitSumAggregateOutputType = {
  __typename?: 'CareAddonPackageBenefitSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
  includedUses?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageBenefitSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export type CareAddonPackageBenefitUncheckedCreateInput = {
  addOnPackageId: Scalars['String'];
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
};

export type CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput = {
  addOnPackageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput = {
  addOnPackageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUpdateInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput = {
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitInput;
  where: CareAddonPackageBenefitScalarWhereInput;
};

export type CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput = {
  data: CareAddonPackageBenefitUncheckedUpdateManyWithoutAddonPackageBenefitsInput;
  where: CareAddonPackageBenefitScalarWhereInput;
};

export type CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutAddOnPackageInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutAddOnPackageInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyAddOnPackageInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutAddOnPackageInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput>>;
};

export type CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareAddonPackageBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareAddonPackageBenefitCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareAddonPackageBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutAddOnPackageInput = {
  data: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  data: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitUpdateWithoutAddOnPackageInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUpdateWithoutBenefitInput = {
  addOnPackage?: Maybe<CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  reportingExpectedUsages?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutAddOnPackageInput = {
  create: CareAddonPackageBenefitUncheckedCreateWithoutAddOnPackageInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutAddOnPackageInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  create: CareAddonPackageBenefitUncheckedCreateWithoutBenefitInput;
  update: CareAddonPackageBenefitUncheckedUpdateWithoutBenefitInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};

export type CareAddonPackageBenefitWhereInput = {
  AND?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageBenefitWhereInput>>;
  addOnPackage?: Maybe<CareAddonPackageWhereInput>;
  addOnPackageId?: Maybe<StringFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  reportingExpectedUsages?: Maybe<IntFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonPackageBenefitWhereUniqueInput = {
  addOnPackageId_displayOrder?: Maybe<CareAddonPackageBenefitAddOnPackageIdDisplayOrderCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareAddonPackageCountAggregateOutputType = {
  __typename?: 'CareAddonPackageCountAggregateOutputType';
  _all: Scalars['Int'];
  addonCancelsAt: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  billingCycle: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  editorNotes: Scalars['Int'];
  id: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  organizationId: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  published: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  salesTaxCentsPerMonth: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  themeColor: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareAddonPackageCountOrderByAggregateInput = {
  addonCancelsAt?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageCountOutputType = {
  __typename?: 'CareAddonPackageCountOutputType';
  addonPackageBenefit: Scalars['Int'];
  enrollments: Scalars['Int'];
};

export type CareAddonPackageCreateInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageCreateManyInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateManyOrganizationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateManyOrganizationInputEnvelope = {
  data: Array<CareAddonPackageCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareAddonPackageCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
};

export type CareAddonPackageCreateNestedOneWithoutAddonPackageBenefitInput = {
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
};

export type CareAddonPackageCreateNestedOneWithoutEnrollmentsInput = {
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
};

export type CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput = {
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageCreateOrConnectWithoutEnrollmentsInput = {
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageCreateOrConnectWithoutOrganizationInput = {
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageCreateWithoutAddonPackageBenefitInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateWithoutEnrollmentsInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCareAddonPackageInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageCreateWithoutOrganizationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageListRelationFilter = {
  every?: Maybe<CareAddonPackageWhereInput>;
  none?: Maybe<CareAddonPackageWhereInput>;
  some?: Maybe<CareAddonPackageWhereInput>;
};

export type CareAddonPackageMaxAggregateOutputType = {
  __typename?: 'CareAddonPackageMaxAggregateOutputType';
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageMaxOrderByAggregateInput = {
  addonCancelsAt?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageMinAggregateOutputType = {
  __typename?: 'CareAddonPackageMinAggregateOutputType';
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageMinOrderByAggregateInput = {
  addonCancelsAt?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAddonPackageOrderByRelevanceFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  Description = 'description',
  EditorNotes = 'editorNotes',
  Id = 'id',
  OrganizationId = 'organizationId',
  StripePriceId = 'stripePriceId',
  ThemeColor = 'themeColor',
  Title = 'title'
}

export type CareAddonPackageOrderByRelevanceInput = {
  fields: Array<CareAddonPackageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAddonPackageOrderByWithAggregationInput = {
  _avg?: Maybe<CareAddonPackageAvgOrderByAggregateInput>;
  _count?: Maybe<CareAddonPackageCountOrderByAggregateInput>;
  _max?: Maybe<CareAddonPackageMaxOrderByAggregateInput>;
  _min?: Maybe<CareAddonPackageMinOrderByAggregateInput>;
  _sum?: Maybe<CareAddonPackageSumOrderByAggregateInput>;
  addonCancelsAt?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAddonPackageOrderByRelevanceInput>;
  addonCancelsAt?: Maybe<SortOrder>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  enrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAddonPackageRelationFilter = {
  is?: Maybe<CareAddonPackageWhereInput>;
  isNot?: Maybe<CareAddonPackageWhereInput>;
};

export enum CareAddonPackageScalarFieldEnum {
  AddonCancelsAt = 'addonCancelsAt',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  BillingCycle = 'billingCycle',
  CreatedAt = 'createdAt',
  Description = 'description',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  EditorNotes = 'editorNotes',
  Id = 'id',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  OrganizationId = 'organizationId',
  PricePerRenewal = 'pricePerRenewal',
  Published = 'published',
  RenewalCycle = 'renewalCycle',
  SalesTaxCentsPerMonth = 'salesTaxCentsPerMonth',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  ThemeColor = 'themeColor',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type CareAddonPackageScalarWhereInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  published?: Maybe<BoolFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonPackageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAddonPackageScalarWhereWithAggregatesInput>>;
  addonCancelsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  salesTaxCentsPerMonth?: Maybe<IntWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareAddonPackageSumAggregateOutputType = {
  __typename?: 'CareAddonPackageSumAggregateOutputType';
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
};

export type CareAddonPackageSumOrderByAggregateInput = {
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
};

export type CareAddonPackageUncheckedCreateInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
};

export type CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedCreateWithoutEnrollmentsInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedCreateWithoutOrganizationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutAddOnPackageInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateManyInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutAssociatedAddonPackagesInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUncheckedUpdateWithoutOrganizationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateManyMutationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareAddonPackageUncheckedUpdateManyWithoutAssociatedAddonPackagesInput;
  where: CareAddonPackageScalarWhereInput;
};

export type CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput = {
  data: CareAddonPackageUncheckedUpdateManyWithoutCareAddonPackageInput;
  where: CareAddonPackageScalarWhereInput;
};

export type CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareAddonPackageUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAddonPackageCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareAddonPackageCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareAddonPackageCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAddonPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  set?: Maybe<Array<CareAddonPackageWhereUniqueInput>>;
  update?: Maybe<Array<CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareAddonPackageUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CareAddonPackageUpdateOneRequiredWithoutAddonPackageBenefitNestedInput = {
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutAddonPackageBenefitInput>;
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutAddonPackageBenefitInput>;
};

export type CareAddonPackageUpdateOneRequiredWithoutEnrollmentsNestedInput = {
  connect?: Maybe<CareAddonPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareAddonPackageCreateOrConnectWithoutEnrollmentsInput>;
  create?: Maybe<CareAddonPackageUncheckedCreateWithoutEnrollmentsInput>;
  update?: Maybe<CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput>;
  upsert?: Maybe<CareAddonPackageUpsertWithoutEnrollmentsInput>;
};

export type CareAddonPackageUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageUpdateWithoutAddonPackageBenefitInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateWithoutEnrollmentsInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organization?: Maybe<OrganizationUpdateOneWithoutCareAddonPackageNestedInput>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpdateWithoutOrganizationInput = {
  addonCancelsAt?: Maybe<Scalars['DateTime']>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitUpdateManyWithoutAddOnPackageNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutAddOnPackageNestedInput>;
  id?: Maybe<Scalars['String']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAddonPackageUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareAddonPackageUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  update: CareAddonPackageUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CareAddonPackageUncheckedCreateWithoutOrganizationInput;
  update: CareAddonPackageUncheckedUpdateWithoutOrganizationInput;
  where: CareAddonPackageWhereUniqueInput;
};

export type CareAddonPackageUpsertWithoutAddonPackageBenefitInput = {
  create: CareAddonPackageUncheckedCreateWithoutAddonPackageBenefitInput;
  update: CareAddonPackageUncheckedUpdateWithoutAddonPackageBenefitInput;
};

export type CareAddonPackageUpsertWithoutEnrollmentsInput = {
  create: CareAddonPackageUncheckedCreateWithoutEnrollmentsInput;
  update: CareAddonPackageUncheckedUpdateWithoutEnrollmentsInput;
};

export type CareAddonPackageWhereInput = {
  AND?: Maybe<Array<CareAddonPackageWhereInput>>;
  NOT?: Maybe<Array<CareAddonPackageWhereInput>>;
  OR?: Maybe<Array<CareAddonPackageWhereInput>>;
  addonCancelsAt?: Maybe<DateTimeNullableFilter>;
  addonPackageBenefit?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  enrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  id?: Maybe<StringFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  published?: Maybe<BoolFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAddonPackageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareAuthorizationRequest = {
  __typename?: 'CareAuthorizationRequest';
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CareAuthorizationRequestCountAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestCountAggregateOutputType';
  _all: Scalars['Int'];
  authCode: Scalars['Int'];
  claimed: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  expiresAt: Scalars['Int'];
  id: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareAuthorizationRequestCountOrderByAggregateInput = {
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestCreateInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestCreateManyClinicPetParentInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<CareAuthorizationRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareAuthorizationRequestCreateManyInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
};

export type CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput = {
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type CareAuthorizationRequestCreateWithoutClinicPetParentInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestListRelationFilter = {
  every?: Maybe<CareAuthorizationRequestWhereInput>;
  none?: Maybe<CareAuthorizationRequestWhereInput>;
  some?: Maybe<CareAuthorizationRequestWhereInput>;
};

export type CareAuthorizationRequestMaxAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMaxAggregateOutputType';
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestMaxOrderByAggregateInput = {
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestMinAggregateOutputType = {
  __typename?: 'CareAuthorizationRequestMinAggregateOutputType';
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestMinOrderByAggregateInput = {
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareAuthorizationRequestOrderByRelevanceFieldEnum {
  AuthCode = 'authCode',
  ClinicPetParentId = 'clinicPetParentId',
  Email = 'email',
  Id = 'id',
  PhoneNumber = 'phoneNumber'
}

export type CareAuthorizationRequestOrderByRelevanceInput = {
  fields: Array<CareAuthorizationRequestOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareAuthorizationRequestOrderByWithAggregationInput = {
  _count?: Maybe<CareAuthorizationRequestCountOrderByAggregateInput>;
  _max?: Maybe<CareAuthorizationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<CareAuthorizationRequestMinOrderByAggregateInput>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareAuthorizationRequestOrderByRelevanceInput>;
  authCode?: Maybe<SortOrder>;
  claimed?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CareAuthorizationRequestScalarFieldEnum {
  AuthCode = 'authCode',
  Claimed = 'claimed',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Email = 'email',
  ExpiresAt = 'expiresAt',
  Id = 'id',
  PhoneNumber = 'phoneNumber',
  UpdatedAt = 'updatedAt'
}

export type CareAuthorizationRequestScalarWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAuthorizationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestScalarWhereWithAggregatesInput>>;
  authCode?: Maybe<StringWithAggregatesFilter>;
  claimed?: Maybe<BoolWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareAuthorizationRequestUncheckedCreateInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
};

export type CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput = {
  authCode: Scalars['String'];
  claimed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUncheckedUpdateInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUpdateInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUpdateManyMutationInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: CareAuthorizationRequestUncheckedUpdateManyWithoutCareAuthorizationRequestsInput;
  where: CareAuthorizationRequestScalarWhereInput;
};

export type CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareAuthorizationRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<CareAuthorizationRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<CareAuthorizationRequestCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareAuthorizationRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  set?: Maybe<Array<CareAuthorizationRequestWhereUniqueInput>>;
  update?: Maybe<Array<CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<CareAuthorizationRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type CareAuthorizationRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type CareAuthorizationRequestUpdateWithoutClinicPetParentInput = {
  authCode?: Maybe<Scalars['String']>;
  claimed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareAuthorizationRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: CareAuthorizationRequestUncheckedCreateWithoutClinicPetParentInput;
  update: CareAuthorizationRequestUncheckedUpdateWithoutClinicPetParentInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};

export type CareAuthorizationRequestWhereInput = {
  AND?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  NOT?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  OR?: Maybe<Array<CareAuthorizationRequestWhereInput>>;
  authCode?: Maybe<StringFilter>;
  claimed?: Maybe<BoolFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareAuthorizationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefit = {
  __typename?: 'CareBenefit';
  _count: CareBenefitCountOutputType;
  addonPackageBenefits: Array<CareAddonPackageBenefit>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories: Array<OrganizationCareBenefitToClinicServiceCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  editorNotes: Scalars['String'];
  enrollmentCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  exhaustible: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organization: Organization;
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  organizationId: Scalars['String'];
  planBenefits: Array<CarePlanBenefit>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage: Scalars['Boolean'];
  /** @deprecated */
  reportingExpectedValue: Scalars['Int'];
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type: CareBenefitType;
  updatedAt: Scalars['DateTime'];
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages: Array<CareBenefitUsage>;
};


export type CareBenefitAddonPackageBenefitsArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  distinct?: Maybe<CareAddonPackageBenefitScalarFieldEnum>;
  orderBy?: Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type CareBenefitConnectedServiceCategoriesArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type CareBenefitEnrollmentCarryoversArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type CareBenefitOrganizationCareBenefitToClinicServiceArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type CareBenefitPlanBenefitsArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type CareBenefitUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};

export type CareBenefitAvgAggregateOutputType = {
  __typename?: 'CareBenefitAvgAggregateOutputType';
  providedDiscountPercentage?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Float']>;
};

export type CareBenefitAvgOrderByAggregateInput = {
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export type CareBenefitCountAggregateOutputType = {
  __typename?: 'CareBenefitCountAggregateOutputType';
  _all: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  editorNotes: Scalars['Int'];
  exhaustible: Scalars['Int'];
  flowAlias: Scalars['Int'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  providedDiscountPercentage: Scalars['Int'];
  providesTeletriage: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  tangible: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usageUnitOfMeasure: Scalars['Int'];
};

export type CareBenefitCountOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
};

export type CareBenefitCountOutputType = {
  __typename?: 'CareBenefitCountOutputType';
  addonPackageBenefits: Scalars['Int'];
  connectedServiceCategories: Scalars['Int'];
  enrollmentCarryovers: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
  planBenefits: Scalars['Int'];
  usages: Scalars['Int'];
};

export type CareBenefitCreateInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateManyAssociatedCarePlanProviderGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareBenefitCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateManyInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitCreateManyOrganizationInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitCreateManyOrganizationInputEnvelope = {
  data: Array<CareBenefitCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareBenefitCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
};

export type CareBenefitCreateNestedOneWithoutAddonPackageBenefitsInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
};

export type CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput>;
};

export type CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput>;
};

export type CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type CareBenefitCreateNestedOneWithoutPlanBenefitsInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
};

export type CareBenefitCreateNestedOneWithoutUsagesInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
};

export type CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput = {
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput = {
  create: CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput = {
  create: CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutOrganizationInput = {
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutPlanBenefitsInput = {
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateOrConnectWithoutUsagesInput = {
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitCreateWithoutAddonPackageBenefitsInput = {
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutConnectedServiceCategoriesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutEnrollmentCarryoversInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutOrganizationInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutPlanBenefitsInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitCreateWithoutUsagesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutCareBenefitsInput;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitListRelationFilter = {
  every?: Maybe<CareBenefitWhereInput>;
  none?: Maybe<CareBenefitWhereInput>;
  some?: Maybe<CareBenefitWhereInput>;
};

export type CareBenefitMaxAggregateOutputType = {
  __typename?: 'CareBenefitMaxAggregateOutputType';
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitMaxOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
};

export type CareBenefitMinAggregateOutputType = {
  __typename?: 'CareBenefitMinAggregateOutputType';
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitMinOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
};

export type CareBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitOrderByRelevanceFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  Description = 'description',
  EditorNotes = 'editorNotes',
  FlowAlias = 'flowAlias',
  Id = 'id',
  OrganizationId = 'organizationId',
  Title = 'title'
}

export type CareBenefitOrderByRelevanceInput = {
  fields: Array<CareBenefitOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareBenefitOrderByWithAggregationInput = {
  _avg?: Maybe<CareBenefitAvgOrderByAggregateInput>;
  _count?: Maybe<CareBenefitCountOrderByAggregateInput>;
  _max?: Maybe<CareBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitSumOrderByAggregateInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  exhaustible?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
};

export type CareBenefitOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareBenefitOrderByRelevanceInput>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitOrderByRelationAggregateInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  exhaustible?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  organizationId?: Maybe<SortOrder>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  providesTeletriage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  tangible?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageUnitOfMeasure?: Maybe<SortOrder>;
  usages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
};

export type CareBenefitRelationFilter = {
  is?: Maybe<CareBenefitWhereInput>;
  isNot?: Maybe<CareBenefitWhereInput>;
};

export enum CareBenefitScalarFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  CreatedAt = 'createdAt',
  Description = 'description',
  EditorNotes = 'editorNotes',
  Exhaustible = 'exhaustible',
  FlowAlias = 'flowAlias',
  Id = 'id',
  OrganizationId = 'organizationId',
  ProvidedDiscountPercentage = 'providedDiscountPercentage',
  ProvidesTeletriage = 'providesTeletriage',
  ReportingExpectedValue = 'reportingExpectedValue',
  Tangible = 'tangible',
  Title = 'title',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UsageUnitOfMeasure = 'usageUnitOfMeasure'
}

export type CareBenefitScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereInput>>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  editorNotes?: Maybe<StringFilter>;
  exhaustible?: Maybe<BoolFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  organizationId?: Maybe<StringFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  providesTeletriage?: Maybe<BoolFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  tangible?: Maybe<BoolFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumCareBenefitTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
};

export type CareBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitScalarWhereWithAggregatesInput>>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  exhaustible?: Maybe<BoolWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  providedDiscountPercentage?: Maybe<IntNullableWithAggregatesFilter>;
  providesTeletriage?: Maybe<BoolWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  tangible?: Maybe<BoolWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumCareBenefitTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
};

export type CareBenefitSumAggregateOutputType = {
  __typename?: 'CareBenefitSumAggregateOutputType';
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
};

export type CareBenefitSumOrderByAggregateInput = {
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export enum CareBenefitType {
  AccountCredit = 'AccountCredit',
  Discount = 'Discount',
  ExternalAccess = 'ExternalAccess',
  ProvidedService = 'ProvidedService',
  Teletriage = 'Teletriage',
  Unspecified = 'Unspecified'
}

export type CareBenefitUncheckedCreateInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
};

export type CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutOrganizationInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutPlanBenefitsInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput>;
};

export type CareBenefitUncheckedCreateWithoutUsagesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible: Scalars['Boolean'];
  title: Scalars['String'];
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUncheckedUpdateInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateManyInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUncheckedUpdateManyWithoutAssociatedCareBenefitsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutOrganizationInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutPlanBenefitsInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUncheckedUpdateWithoutUsagesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export enum CareBenefitUnitOfMeasure {
  Cents = 'CENTS',
  Occurence = 'OCCURENCE'
}

export type CareBenefitUpdateInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareBenefitUncheckedUpdateManyWithoutAssociatedCareBenefitsInput;
  where: CareBenefitScalarWhereInput;
};

export type CareBenefitUpdateManyWithWhereWithoutOrganizationInput = {
  data: CareBenefitUncheckedUpdateManyWithoutCareBenefitsInput;
  where: CareBenefitScalarWhereInput;
};

export type CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareBenefitCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareBenefitUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CareBenefitCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CareBenefitCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CareBenefitUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CareBenefitUpdateOneRequiredWithoutAddonPackageBenefitsNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutAddonPackageBenefitsInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutAddonPackageBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutConnectedServiceCategoriesInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutConnectedServiceCategoriesInput>;
};

export type CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutEnrollmentCarryoversInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutEnrollmentCarryoversInput>;
};

export type CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutPlanBenefitsInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutPlanBenefitsInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutPlanBenefitsInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutPlanBenefitsInput>;
};

export type CareBenefitUpdateOneRequiredWithoutUsagesNestedInput = {
  connect?: Maybe<CareBenefitWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitCreateOrConnectWithoutUsagesInput>;
  create?: Maybe<CareBenefitUncheckedCreateWithoutUsagesInput>;
  update?: Maybe<CareBenefitUncheckedUpdateWithoutUsagesInput>;
  upsert?: Maybe<CareBenefitUpsertWithoutUsagesInput>;
};

export type CareBenefitUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CareBenefitUncheckedUpdateWithoutOrganizationInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitUpdateWithoutAddonPackageBenefitsInput = {
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutConnectedServiceCategoriesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutEnrollmentCarryoversInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutOrganizationInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutPlanBenefitsInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
  usages?: Maybe<CareBenefitUsageUpdateManyWithoutBenefitNestedInput>;
};

export type CareBenefitUpdateWithoutUsagesInput = {
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitUpdateManyWithoutBenefitNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput>;
  exhaustible?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutBenefitNestedInput>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  providesTeletriage?: Maybe<Scalars['Boolean']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  tangible?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CareBenefitType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageUnitOfMeasure?: Maybe<CareBenefitUnitOfMeasure>;
};

export type CareBenefitUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareBenefitUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  update: CareBenefitUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CareBenefitUncheckedCreateWithoutOrganizationInput;
  update: CareBenefitUncheckedUpdateWithoutOrganizationInput;
  where: CareBenefitWhereUniqueInput;
};

export type CareBenefitUpsertWithoutAddonPackageBenefitsInput = {
  create: CareBenefitUncheckedCreateWithoutAddonPackageBenefitsInput;
  update: CareBenefitUncheckedUpdateWithoutAddonPackageBenefitsInput;
};

export type CareBenefitUpsertWithoutConnectedServiceCategoriesInput = {
  create: CareBenefitUncheckedCreateWithoutConnectedServiceCategoriesInput;
  update: CareBenefitUncheckedUpdateWithoutConnectedServiceCategoriesInput;
};

export type CareBenefitUpsertWithoutEnrollmentCarryoversInput = {
  create: CareBenefitUncheckedCreateWithoutEnrollmentCarryoversInput;
  update: CareBenefitUncheckedUpdateWithoutEnrollmentCarryoversInput;
};

export type CareBenefitUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  create: CareBenefitUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
  update: CareBenefitUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type CareBenefitUpsertWithoutPlanBenefitsInput = {
  create: CareBenefitUncheckedCreateWithoutPlanBenefitsInput;
  update: CareBenefitUncheckedUpdateWithoutPlanBenefitsInput;
};

export type CareBenefitUpsertWithoutUsagesInput = {
  create: CareBenefitUncheckedCreateWithoutUsagesInput;
  update: CareBenefitUncheckedUpdateWithoutUsagesInput;
};

export type CareBenefitUsage = {
  __typename?: 'CareBenefitUsage';
  _count: CareBenefitUsageCountOutputType;
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  careAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollment>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollment>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  invoiceLineItemConnections: Array<CareBenefitUsageToInvoiceLineItem>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<User>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usageClinic: Clinic;
  usageClinicId: Scalars['String'];
  usageClinicPet: ClinicPet;
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};


export type CareBenefitUsageInvoiceLineItemConnectionsArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type CareBenefitUsageAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageAvgAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageAvgOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageCountAggregateOutputType';
  _all: Scalars['Int'];
  benefitId: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  connectedAddonEnrollmentId: Scalars['Int'];
  connectedPlanEnrollmentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  exhaustsBenefit: Scalars['Int'];
  id: Scalars['Int'];
  quantityUsed: Scalars['Int'];
  reason: Scalars['Int'];
  recordedByUserId: Scalars['Int'];
  relatedFollowupStepId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usageClinicId: Scalars['Int'];
  usageClinicPetId: Scalars['Int'];
  usedAt: Scalars['Int'];
};

export type CareBenefitUsageCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageCountOutputType = {
  __typename?: 'CareBenefitUsageCountOutputType';
  invoiceLineItemConnections: Scalars['Int'];
};

export type CareBenefitUsageCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyBenefitInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyBenefitInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyCareAccountCreditIntentInput = {
  benefitId: Scalars['String'];
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyConnectedPlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyRecordedByUserInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyRecordedByUserInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyRecordedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyRelatedFollowupStepInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyRelatedFollowupStepInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyUsageClinicInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateManyUsageClinicPetInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateManyUsageClinicPetInputEnvelope = {
  data: Array<CareBenefitUsageCreateManyUsageClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
};

export type CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
};

export type CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput = {
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
};

export type CareBenefitUsageCreateOrConnectWithoutBenefitInput = {
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput = {
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput = {
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput = {
  create: CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicInput = {
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput = {
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageCreateWithoutBenefitInput = {
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutCareAccountCreditIntentInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutInvoiceLineItemConnectionsInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutRecordedByUserInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutRelatedFollowupStepInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutUsageClinicInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPet: ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageCreateWithoutUsageClinicPetInput = {
  benefit: CareBenefitCreateNestedOneWithoutUsagesInput;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutCareBenefitUsagesInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserCreateNestedOneWithoutUsagesRecordedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic: ClinicCreateNestedOneWithoutBenefitsProvidedInput;
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageFollowup = {
  __typename?: 'CareBenefitUsageFollowup';
  _count: CareBenefitUsageFollowupCountOutputType;
  careStripeSubscription: CareStripeSubscription;
  careStripeSubscriptionId: Scalars['String'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  followupSteps: Array<CareBenefitUsageFollowupStep>;
  id: Scalars['String'];
  relatedOrganizationPet: OrganizationPet;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type CareBenefitUsageFollowupFollowupStepsArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};

export type CareBenefitUsageFollowupAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupAvgAggregateOutputType';
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageFollowupAvgOrderByAggregateInput = {
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupCountAggregateOutputType';
  _all: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  completed: Scalars['Int'];
  completedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  relatedOrganizationPetId: Scalars['Int'];
  reportingOriginalInvoiceValueCents: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareBenefitUsageFollowupCountOrderByAggregateInput = {
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupCountOutputType = {
  __typename?: 'CareBenefitUsageFollowupCountOutputType';
  followupSteps: Scalars['Int'];
};

export type CareBenefitUsageFollowupCreateInput = {
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupCreateManyInput = {
  careStripeSubscriptionId: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInput = {
  careStripeSubscriptionId: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope = {
  data: Array<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
};

export type CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput = {
  connect?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput>;
  create?: Maybe<CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput>;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateWithoutFollowupStepsInput = {
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet: OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput = {
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupListRelationFilter = {
  every?: Maybe<CareBenefitUsageFollowupWhereInput>;
  none?: Maybe<CareBenefitUsageFollowupWhereInput>;
  some?: Maybe<CareBenefitUsageFollowupWhereInput>;
};

export type CareBenefitUsageFollowupMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupMaxAggregateOutputType';
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupMaxOrderByAggregateInput = {
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupMinAggregateOutputType';
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupMinOrderByAggregateInput = {
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageFollowupOrderByRelevanceFieldEnum {
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  Id = 'id',
  RelatedOrganizationPetId = 'relatedOrganizationPetId'
}

export type CareBenefitUsageFollowupOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageFollowupOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareBenefitUsageFollowupOrderByWithAggregationInput = {
  _avg?: Maybe<CareBenefitUsageFollowupAvgOrderByAggregateInput>;
  _count?: Maybe<CareBenefitUsageFollowupCountOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageFollowupMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageFollowupMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageFollowupSumOrderByAggregateInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareBenefitUsageFollowupOrderByRelevanceInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  relatedOrganizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  relatedOrganizationPetId?: Maybe<SortOrder>;
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupRelationFilter = {
  is?: Maybe<CareBenefitUsageFollowupWhereInput>;
  isNot?: Maybe<CareBenefitUsageFollowupWhereInput>;
};

export enum CareBenefitUsageFollowupScalarFieldEnum {
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  Completed = 'completed',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  RelatedOrganizationPetId = 'relatedOrganizationPetId',
  ReportingOriginalInvoiceValueCents = 'reportingOriginalInvoiceValueCents',
  UpdatedAt = 'updatedAt'
}

export type CareBenefitUsageFollowupScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  relatedOrganizationPetId?: Maybe<StringFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareBenefitUsageFollowupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupScalarWhereWithAggregatesInput>>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  completed?: Maybe<BoolWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  relatedOrganizationPetId?: Maybe<StringWithAggregatesFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareBenefitUsageFollowupStep = {
  __typename?: 'CareBenefitUsageFollowupStep';
  _count: CareBenefitUsageFollowupStepCountOutputType;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<User>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** Display order starts at 1, and 0 is used for the default value but marks it as not being set */
  displayOrder: Scalars['Int'];
  id: Scalars['String'];
  relatedUsages: Array<CareBenefitUsage>;
  usageFollowup: CareBenefitUsageFollowup;
  usageFollowupId: Scalars['String'];
};


export type CareBenefitUsageFollowupStepCareAccountCreditIntentsArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type CareBenefitUsageFollowupStepRelatedUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};

export type CareBenefitUsageFollowupStepAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageFollowupStepAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepCountAggregateOutputType';
  _all: Scalars['Int'];
  completed: Scalars['Int'];
  completedAt: Scalars['Int'];
  completedByUserId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  displayOrder: Scalars['Int'];
  id: Scalars['Int'];
  usageFollowupId: Scalars['Int'];
};

export type CareBenefitUsageFollowupStepCountOrderByAggregateInput = {
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepCountOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepCountOutputType';
  careAccountCreditIntents: Scalars['Int'];
  relatedUsages: Scalars['Int'];
};

export type CareBenefitUsageFollowupStepCreateInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepCreateManyCompletedByUserInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope = {
  data: Array<CareBenefitUsageFollowupStepCreateManyCompletedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupStepCreateManyInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepCreateManyUsageFollowupInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope = {
  data: Array<CareBenefitUsageFollowupStepCreateManyUsageFollowupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
};

export type CareBenefitUsageFollowupStepCreateNestedManyWithoutUsageFollowupInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
};

export type CareBenefitUsageFollowupStepCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput>;
};

export type CareBenefitUsageFollowupStepCreateNestedOneWithoutRelatedUsagesInput = {
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput>;
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput>;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepCreateWithoutCareAccountCreditIntentsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepCreateWithoutRelatedUsagesInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowup: CareBenefitUsageFollowupCreateNestedOneWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutRelatedFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepListRelationFilter = {
  every?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  none?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  some?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};

export type CareBenefitUsageFollowupStepMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepMaxAggregateOutputType';
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepMaxOrderByAggregateInput = {
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepMinAggregateOutputType';
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepMinOrderByAggregateInput = {
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageFollowupStepOrderByRelevanceFieldEnum {
  CompletedByUserId = 'completedByUserId',
  Description = 'description',
  Id = 'id',
  UsageFollowupId = 'usageFollowupId'
}

export type CareBenefitUsageFollowupStepOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageFollowupStepOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareBenefitUsageFollowupStepOrderByWithAggregationInput = {
  _avg?: Maybe<CareBenefitUsageFollowupStepAvgOrderByAggregateInput>;
  _count?: Maybe<CareBenefitUsageFollowupStepCountOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageFollowupStepMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageFollowupStepMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageFollowupStepSumOrderByAggregateInput>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  completedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  usageFollowupId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareBenefitUsageFollowupStepOrderByRelevanceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  completed?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  completedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  completedByUserId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  relatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  usageFollowupId?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepRelationFilter = {
  is?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  isNot?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};

export enum CareBenefitUsageFollowupStepScalarFieldEnum {
  Completed = 'completed',
  CompletedAt = 'completedAt',
  CompletedByUserId = 'completedByUserId',
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayOrder = 'displayOrder',
  Id = 'id',
  UsageFollowupId = 'usageFollowupId'
}

export type CareBenefitUsageFollowupStepScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  completedByUserId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  displayOrder?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  usageFollowupId?: Maybe<StringFilter>;
};

export type CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereWithAggregatesInput>>;
  completed?: Maybe<BoolWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  completedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  displayOrder?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  usageFollowupId?: Maybe<StringWithAggregatesFilter>;
};

export type CareBenefitUsageFollowupStepSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupStepSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupStepSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId: Scalars['String'];
};

export type CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutFollowupStepInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedBenefitUsageFollowupStepsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutFollowupStepsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput>>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowupId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateManyMutationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput = {
  data: CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedBenefitUsageFollowupStepsInput;
  where: CareBenefitUsageFollowupStepScalarWhereInput;
};

export type CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput = {
  data: CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutFollowupStepsInput;
  where: CareBenefitUsageFollowupStepScalarWhereInput;
};

export type CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutCompletedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutCompletedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyCompletedByUserInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutCompletedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput>>;
};

export type CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupStepCreateOrConnectWithoutUsageFollowupInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupStepCreateWithoutUsageFollowupInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupStepCreateManyUsageFollowupInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupStepScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupStepWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupStepUpdateManyWithWhereWithoutUsageFollowupInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput>>;
};

export type CareBenefitUsageFollowupStepUpdateOneWithoutCareAccountCreditIntentsNestedInput = {
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<CareBenefitUsageFollowupStepUpsertWithoutCareAccountCreditIntentsInput>;
};

export type CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput = {
  connect?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupStepCreateOrConnectWithoutRelatedUsagesInput>;
  create?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput>;
  upsert?: Maybe<CareBenefitUsageFollowupStepUpsertWithoutRelatedUsagesInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutCompletedByUserInput = {
  data: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepUpdateWithWhereUniqueWithoutUsageFollowupInput = {
  data: CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepUpdateWithoutCareAccountCreditIntentsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutCompletedByUserInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutRelatedUsagesInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput>;
};

export type CareBenefitUsageFollowupStepUpdateWithoutUsageFollowupInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutFollowupStepNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedByUser?: Maybe<UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  relatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput>;
};

export type CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutCompletedByUserInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCompletedByUserInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCompletedByUserInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepUpsertWithWhereUniqueWithoutUsageFollowupInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutUsageFollowupInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutUsageFollowupInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};

export type CareBenefitUsageFollowupStepUpsertWithoutCareAccountCreditIntentsInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutCareAccountCreditIntentsInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutCareAccountCreditIntentsInput;
};

export type CareBenefitUsageFollowupStepUpsertWithoutRelatedUsagesInput = {
  create: CareBenefitUsageFollowupStepUncheckedCreateWithoutRelatedUsagesInput;
  update: CareBenefitUsageFollowupStepUncheckedUpdateWithoutRelatedUsagesInput;
};

export type CareBenefitUsageFollowupStepWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupStepWhereInput>>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  completedByUser?: Maybe<UserWhereInput>;
  completedByUserId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  displayOrder?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  relatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  usageFollowup?: Maybe<CareBenefitUsageFollowupWhereInput>;
  usageFollowupId?: Maybe<StringFilter>;
};

export type CareBenefitUsageFollowupStepWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageFollowupSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageFollowupSumAggregateOutputType';
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageFollowupSumOrderByAggregateInput = {
  reportingOriginalInvoiceValueCents?: Maybe<SortOrder>;
};

export type CareBenefitUsageFollowupUncheckedCreateInput = {
  careStripeSubscriptionId: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput = {
  careStripeSubscriptionId: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId: Scalars['String'];
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput = {
  careStripeSubscriptionId: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutUsageFollowupInput>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutAssociatedBenefitUsageFollowupInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareBenefitUsageFollowupsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPetId?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput = {
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpdateInput = {
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpdateManyMutationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  data: CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareBenefitUsageFollowupsInput;
  where: CareBenefitUsageFollowupScalarWhereInput;
};

export type CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput = {
  data: CareBenefitUsageFollowupUncheckedUpdateManyWithoutAssociatedBenefitUsageFollowupInput;
  where: CareBenefitUsageFollowupScalarWhereInput;
};

export type CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageFollowupCreateOrConnectWithoutRelatedOrganizationPetInput>>;
  create?: Maybe<Array<CareBenefitUsageFollowupCreateWithoutRelatedOrganizationPetInput>>;
  createMany?: Maybe<CareBenefitUsageFollowupCreateManyRelatedOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageFollowupScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageFollowupWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageFollowupUpdateManyWithWhereWithoutRelatedOrganizationPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput>>;
};

export type CareBenefitUsageFollowupUpdateOneRequiredWithoutFollowupStepsNestedInput = {
  connect?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageFollowupCreateOrConnectWithoutFollowupStepsInput>;
  create?: Maybe<CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput>;
  update?: Maybe<CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput>;
  upsert?: Maybe<CareBenefitUsageFollowupUpsertWithoutFollowupStepsInput>;
};

export type CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  data: CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupUpdateWithWhereUniqueWithoutRelatedOrganizationPetInput = {
  data: CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupUpdateWithoutCareStripeSubscriptionInput = {
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpdateWithoutFollowupStepsInput = {
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  relatedOrganizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpdateWithoutRelatedOrganizationPetInput = {
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutUsageFollowupNestedInput>;
  id?: Maybe<Scalars['String']>;
  reportingOriginalInvoiceValueCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutCareStripeSubscriptionInput;
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupUpsertWithWhereUniqueWithoutRelatedOrganizationPetInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutRelatedOrganizationPetInput;
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutRelatedOrganizationPetInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};

export type CareBenefitUsageFollowupUpsertWithoutFollowupStepsInput = {
  create: CareBenefitUsageFollowupUncheckedCreateWithoutFollowupStepsInput;
  update: CareBenefitUsageFollowupUncheckedUpdateWithoutFollowupStepsInput;
};

export type CareBenefitUsageFollowupWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageFollowupWhereInput>>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  completed?: Maybe<BoolFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  followupSteps?: Maybe<CareBenefitUsageFollowupStepListRelationFilter>;
  id?: Maybe<StringFilter>;
  relatedOrganizationPet?: Maybe<OrganizationPetWhereInput>;
  relatedOrganizationPetId?: Maybe<StringFilter>;
  reportingOriginalInvoiceValueCents?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareBenefitUsageFollowupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageListRelationFilter = {
  every?: Maybe<CareBenefitUsageWhereInput>;
  none?: Maybe<CareBenefitUsageWhereInput>;
  some?: Maybe<CareBenefitUsageWhereInput>;
};

export type CareBenefitUsageMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  Id = 'id',
  Reason = 'reason',
  RecordedByUserId = 'recordedByUserId',
  RelatedFollowupStepId = 'relatedFollowupStepId',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId'
}

export type CareBenefitUsageOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareBenefitUsageOrderByWithAggregationInput = {
  _avg?: Maybe<CareBenefitUsageAvgOrderByAggregateInput>;
  _count?: Maybe<CareBenefitUsageCountOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageSumOrderByAggregateInput>;
  benefitId?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recordedByUserId?: Maybe<SortOrder>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPetId?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareBenefitUsageOrderByRelevanceInput>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  connectedAddonEnrollmentId?: Maybe<SortOrder>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  connectedPlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exhaustsBenefit?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  quantityUsed?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recordedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  recordedByUserId?: Maybe<SortOrder>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  relatedFollowupStepId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  usageClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  usageClinicId?: Maybe<SortOrder>;
  usageClinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  usageClinicPetId?: Maybe<SortOrder>;
  usedAt?: Maybe<SortOrder>;
};

export type CareBenefitUsageRelationFilter = {
  is?: Maybe<CareBenefitUsageWhereInput>;
  isNot?: Maybe<CareBenefitUsageWhereInput>;
};

export enum CareBenefitUsageScalarFieldEnum {
  BenefitId = 'benefitId',
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  ConnectedAddonEnrollmentId = 'connectedAddonEnrollmentId',
  ConnectedPlanEnrollmentId = 'connectedPlanEnrollmentId',
  CreatedAt = 'createdAt',
  ExhaustsBenefit = 'exhaustsBenefit',
  Id = 'id',
  QuantityUsed = 'quantityUsed',
  Reason = 'reason',
  RecordedByUserId = 'recordedByUserId',
  RelatedFollowupStepId = 'relatedFollowupStepId',
  UpdatedAt = 'updatedAt',
  UsageClinicId = 'usageClinicId',
  UsageClinicPetId = 'usageClinicPetId',
  UsedAt = 'usedAt'
}

export type CareBenefitUsageScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  id?: Maybe<StringFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  relatedFollowupStepId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinicPetId?: Maybe<StringFilter>;
  usedAt?: Maybe<DateTimeFilter>;
};

export type CareBenefitUsageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  connectedAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  connectedPlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  exhaustsBenefit?: Maybe<BoolNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  quantityUsed?: Maybe<IntNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  recordedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedFollowupStepId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  usageClinicId?: Maybe<StringWithAggregatesFilter>;
  usageClinicPetId?: Maybe<StringWithAggregatesFilter>;
  usedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareBenefitUsageSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageSumAggregateOutputType';
  quantityUsed?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageSumOrderByAggregateInput = {
  quantityUsed?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItem = {
  __typename?: 'CareBenefitUsageToInvoiceLineItem';
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<Invoice>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsage: CareBenefitUsage;
  careBenefitUsageId: Scalars['String'];
  confirmedBy?: Maybe<User>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftService?: Maybe<Service>;
  draftServiceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceLineItem?: Maybe<InvoiceLineItem>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemAvgAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Float']>;
};

export type CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput = {
  careBenefitUsageId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
};

export type CareBenefitUsageToInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemCountAggregateOutputType';
  _all: Scalars['Int'];
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId: Scalars['Int'];
  careBenefitUsageId: Scalars['Int'];
  confirmedByUserId: Scalars['Int'];
  draftServiceId: Scalars['Int'];
  id: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
};

export type CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemCreateInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput = {
  adjustedAmount: Scalars['Int'];
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope = {
  data: Array<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutCareBenefitUsageInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput = {
  adjustedAmount: Scalars['Int'];
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput>;
};

export type CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoice?: Maybe<InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput>;
  careBenefitUsage: CareBenefitUsageCreateNestedOneWithoutInvoiceLineItemConnectionsInput;
  confirmedBy?: Maybe<UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  draftService?: Maybe<ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemListRelationFilter = {
  every?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  none?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
  some?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMaxAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemMinAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum {
  AssociatedInvoiceId = 'associatedInvoiceId',
  CareBenefitUsageId = 'careBenefitUsageId',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId',
  Id = 'id',
  InvoiceLineItemId = 'invoiceLineItemId'
}

export type CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<CareBenefitUsageToInvoiceLineItemOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithAggregationInput = {
  _avg?: Maybe<CareBenefitUsageToInvoiceLineItemAvgOrderByAggregateInput>;
  _count?: Maybe<CareBenefitUsageToInvoiceLineItemCountOrderByAggregateInput>;
  _max?: Maybe<CareBenefitUsageToInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareBenefitUsageToInvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput>;
  adjustedAmount?: Maybe<SortOrder>;
  associatedInvoiceId?: Maybe<SortOrder>;
  careBenefitUsageId?: Maybe<SortOrder>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftServiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelevanceInput>;
  adjustedAmount?: Maybe<SortOrder>;
  associatedInvoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  associatedInvoiceId?: Maybe<SortOrder>;
  careBenefitUsage?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  careBenefitUsageId?: Maybe<SortOrder>;
  confirmedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  confirmedByUserId?: Maybe<SortOrder>;
  draftService?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  draftServiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemId?: Maybe<SortOrder>;
};

export enum CareBenefitUsageToInvoiceLineItemScalarFieldEnum {
  AdjustedAmount = 'adjustedAmount',
  AssociatedInvoiceId = 'associatedInvoiceId',
  CareBenefitUsageId = 'careBenefitUsageId',
  ConfirmedByUserId = 'confirmedByUserId',
  DraftServiceId = 'draftServiceId',
  Id = 'id',
  InvoiceLineItemId = 'invoiceLineItemId'
}

export type CareBenefitUsageToInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  adjustedAmount?: Maybe<IntFilter>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  careBenefitUsageId?: Maybe<StringFilter>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  draftServiceId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereWithAggregatesInput>>;
  adjustedAmount?: Maybe<IntWithAggregatesFilter>;
  associatedInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  careBenefitUsageId?: Maybe<StringWithAggregatesFilter>;
  confirmedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  draftServiceId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareBenefitUsageToInvoiceLineItemSumAggregateOutputType = {
  __typename?: 'CareBenefitUsageToInvoiceLineItemSumAggregateOutputType';
  adjustedAmount?: Maybe<Scalars['Int']>;
};

export type CareBenefitUsageToInvoiceLineItemSumOrderByAggregateInput = {
  adjustedAmount?: Maybe<SortOrder>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput = {
  adjustedAmount: Scalars['Int'];
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  adjustedAmount: Scalars['Int'];
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId: Scalars['String'];
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoiceId?: Maybe<Scalars['String']>;
  careBenefitUsageId?: Maybe<Scalars['String']>;
  confirmedByUserId?: Maybe<Scalars['String']>;
  draftServiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedCareBenefitCreditsInput;
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemConnectionsInput;
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageToInvoiceLineItemsInput;
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConnectedCareBenefitsInput;
  where: CareBenefitUsageToInvoiceLineItemScalarWhereInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutAssociatedInvoiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutAssociatedInvoiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyAssociatedInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutAssociatedInvoiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutCareBenefitUsageInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutCareBenefitUsageInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyCareBenefitUsageInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutCareBenefitUsageInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutConfirmedByInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutConfirmedByInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyConfirmedByInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutConfirmedByInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutDraftServiceInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutDraftServiceInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyDraftServiceInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutDraftServiceInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>>;
  create?: Maybe<Array<CareBenefitUsageToInvoiceLineItemCreateWithoutInvoiceLineItemInput>>;
  createMany?: Maybe<CareBenefitUsageToInvoiceLineItemCreateManyInvoiceLineItemInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpdateManyWithWhereWithoutInvoiceLineItemInput>>;
  upsert?: Maybe<Array<CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput>>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutAssociatedInvoiceInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutCareBenefitUsageInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutConfirmedByInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutDraftServiceInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceLineItemInput = {
  data: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutAssociatedInvoiceInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutCareBenefitUsageInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutConfirmedByInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutDraftServiceInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput>;
};

export type CareBenefitUsageToInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  adjustedAmount?: Maybe<Scalars['Int']>;
  associatedInvoice?: Maybe<InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput>;
  careBenefitUsage?: Maybe<CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput>;
  confirmedBy?: Maybe<UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput>;
  draftService?: Maybe<ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutAssociatedInvoiceInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutAssociatedInvoiceInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutAssociatedInvoiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutCareBenefitUsageInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutCareBenefitUsageInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutCareBenefitUsageInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutConfirmedByInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutConfirmedByInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutConfirmedByInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutDraftServiceInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutDraftServiceInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutDraftServiceInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceLineItemInput = {
  create: CareBenefitUsageToInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
  update: CareBenefitUsageToInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};

export type CareBenefitUsageToInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageToInvoiceLineItemWhereInput>>;
  adjustedAmount?: Maybe<IntFilter>;
  associatedInvoice?: Maybe<InvoiceWhereInput>;
  associatedInvoiceId?: Maybe<StringNullableFilter>;
  careBenefitUsage?: Maybe<CareBenefitUsageWhereInput>;
  careBenefitUsageId?: Maybe<StringFilter>;
  confirmedBy?: Maybe<UserWhereInput>;
  confirmedByUserId?: Maybe<StringNullableFilter>;
  draftService?: Maybe<ServiceWhereInput>;
  draftServiceId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  invoiceLineItemId?: Maybe<StringNullableFilter>;
};

export type CareBenefitUsageToInvoiceLineItemWhereUniqueInput = {
  careBenefitUsageId_invoiceLineItemId?: Maybe<CareBenefitUsageToInvoiceLineItemCareBenefitUsageIdInvoiceLineItemIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitUsageUncheckedCreateInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedAddonEnrollmentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutRelatedFollowupStepInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
};

export type CareBenefitUsageUncheckedCreateWithoutBenefitInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput = {
  benefitId: Scalars['String'];
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPetId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput = {
  benefitId: Scalars['String'];
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutCareBenefitUsageInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId: Scalars['String'];
  usedAt: Scalars['DateTime'];
};

export type CareBenefitUsageUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRelatedFollowupStepNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutRelatedUsagesInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutBenefitInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  benefitId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPetId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput = {
  benefitId?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  connectedAddonEnrollmentId?: Maybe<Scalars['String']>;
  connectedPlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUserId?: Maybe<Scalars['String']>;
  relatedFollowupStepId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicId?: Maybe<Scalars['String']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutAssociatedUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutUsagesRecordedInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutRelatedUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutBenefitsProvidedInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput = {
  data: CareBenefitUsageUncheckedUpdateManyWithoutCareBenefitUsagesInput;
  where: CareBenefitUsageScalarWhereInput;
};

export type CareBenefitUsageUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutBenefitInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CareBenefitUsageUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedAddonEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedAddonEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput>>;
};

export type CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutConnectedPlanEnrollmentInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutConnectedPlanEnrollmentInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyConnectedPlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutConnectedPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput>>;
};

export type CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRecordedByUserInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRecordedByUserInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRecordedByUserInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRecordedByUserInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput>>;
};

export type CareBenefitUsageUpdateManyWithoutRelatedFollowupStepNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutRelatedFollowupStepInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutRelatedFollowupStepInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyRelatedFollowupStepInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutRelatedFollowupStepInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput>>;
};

export type CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput = {
  connect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareBenefitUsageCreateOrConnectWithoutUsageClinicPetInput>>;
  create?: Maybe<Array<CareBenefitUsageCreateWithoutUsageClinicPetInput>>;
  createMany?: Maybe<CareBenefitUsageCreateManyUsageClinicPetInputEnvelope>;
  delete?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareBenefitUsageScalarWhereInput>>;
  disconnect?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  set?: Maybe<Array<CareBenefitUsageWhereUniqueInput>>;
  update?: Maybe<Array<CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput>>;
  updateMany?: Maybe<Array<CareBenefitUsageUpdateManyWithWhereWithoutUsageClinicPetInput>>;
  upsert?: Maybe<Array<CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput>>;
};

export type CareBenefitUsageUpdateOneRequiredWithoutInvoiceLineItemConnectionsNestedInput = {
  connect?: Maybe<CareBenefitUsageWhereUniqueInput>;
  connectOrCreate?: Maybe<CareBenefitUsageCreateOrConnectWithoutInvoiceLineItemConnectionsInput>;
  create?: Maybe<CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput>;
  update?: Maybe<CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput>;
  upsert?: Maybe<CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput>;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutBenefitInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutRecordedByUserInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutRelatedFollowupStepInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithWhereUniqueWithoutUsageClinicPetInput = {
  data: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpdateWithoutBenefitInput = {
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutCareAccountCreditIntentInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutConnectedAddonEnrollmentInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutConnectedPlanEnrollmentInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutInvoiceLineItemConnectionsInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutRecordedByUserInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutRelatedFollowupStepInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpdateWithoutUsageClinicPetInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutUsagesNestedInput>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutCareBenefitUsagesNestedInput>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exhaustsBenefit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutCareBenefitUsageNestedInput>;
  quantityUsed?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  recordedByUser?: Maybe<UserUpdateOneWithoutUsagesRecordedNestedInput>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepUpdateOneWithoutRelatedUsagesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageClinic?: Maybe<ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput>;
  usedAt?: Maybe<Scalars['DateTime']>;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutBenefitInput = {
  create: CareBenefitUsageUncheckedCreateWithoutBenefitInput;
  update: CareBenefitUsageUncheckedUpdateWithoutBenefitInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutCareAccountCreditIntentInput;
  update: CareBenefitUsageUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedAddonEnrollmentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutConnectedAddonEnrollmentInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedAddonEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutConnectedPlanEnrollmentInput = {
  create: CareBenefitUsageUncheckedCreateWithoutConnectedPlanEnrollmentInput;
  update: CareBenefitUsageUncheckedUpdateWithoutConnectedPlanEnrollmentInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutRecordedByUserInput = {
  create: CareBenefitUsageUncheckedCreateWithoutRecordedByUserInput;
  update: CareBenefitUsageUncheckedUpdateWithoutRecordedByUserInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutRelatedFollowupStepInput = {
  create: CareBenefitUsageUncheckedCreateWithoutRelatedFollowupStepInput;
  update: CareBenefitUsageUncheckedUpdateWithoutRelatedFollowupStepInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicInput = {
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithWhereUniqueWithoutUsageClinicPetInput = {
  create: CareBenefitUsageUncheckedCreateWithoutUsageClinicPetInput;
  update: CareBenefitUsageUncheckedUpdateWithoutUsageClinicPetInput;
  where: CareBenefitUsageWhereUniqueInput;
};

export type CareBenefitUsageUpsertWithoutInvoiceLineItemConnectionsInput = {
  create: CareBenefitUsageUncheckedCreateWithoutInvoiceLineItemConnectionsInput;
  update: CareBenefitUsageUncheckedUpdateWithoutInvoiceLineItemConnectionsInput;
};

export type CareBenefitUsageWhereInput = {
  AND?: Maybe<Array<CareBenefitUsageWhereInput>>;
  NOT?: Maybe<Array<CareBenefitUsageWhereInput>>;
  OR?: Maybe<Array<CareBenefitUsageWhereInput>>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  connectedAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  connectedAddonEnrollmentId?: Maybe<StringNullableFilter>;
  connectedPlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  connectedPlanEnrollmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  exhaustsBenefit?: Maybe<BoolNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceLineItemConnections?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  quantityUsed?: Maybe<IntNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  recordedByUser?: Maybe<UserWhereInput>;
  recordedByUserId?: Maybe<StringNullableFilter>;
  relatedFollowupStep?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
  relatedFollowupStepId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageClinic?: Maybe<ClinicWhereInput>;
  usageClinicId?: Maybe<StringFilter>;
  usageClinicPet?: Maybe<ClinicPetWhereInput>;
  usageClinicPetId?: Maybe<StringFilter>;
  usedAt?: Maybe<DateTimeFilter>;
};

export type CareBenefitUsageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareBenefitWhereInput = {
  AND?: Maybe<Array<CareBenefitWhereInput>>;
  NOT?: Maybe<Array<CareBenefitWhereInput>>;
  OR?: Maybe<Array<CareBenefitWhereInput>>;
  addonPackageBenefits?: Maybe<CareAddonPackageBenefitListRelationFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  connectedServiceCategories?: Maybe<OrganizationCareBenefitToClinicServiceCategoryListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  editorNotes?: Maybe<StringFilter>;
  enrollmentCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
  exhaustible?: Maybe<BoolFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  organizationId?: Maybe<StringFilter>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  providesTeletriage?: Maybe<BoolFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  tangible?: Maybe<BoolFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumCareBenefitTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usageUnitOfMeasure?: Maybe<EnumCareBenefitUnitOfMeasureNullableFilter>;
  usages?: Maybe<CareBenefitUsageListRelationFilter>;
};

export type CareBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareClinicInfo = {
  __typename?: 'CareClinicInfo';
  careEnabled?: Maybe<Scalars['Boolean']>;
  enabledFeatureFlags: Array<Scalars['String']>;
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<CareOrganizationInfo>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type CareEnrollmentData = {
  __typename?: 'CareEnrollmentData';
  carePlanProviderGroupId: Scalars['String'];
  clientName: Scalars['String'];
  isRenewal: Scalars['Boolean'];
  petName: Scalars['String'];
  planName: Scalars['String'];
};

export type CareEnrollmentOutput = {
  __typename?: 'CareEnrollmentOutput';
  isEnrolled: Scalars['Boolean'];
};

export type CareEnrollmentPayment = {
  __typename?: 'CareEnrollmentPayment';
  /** the total amount of the payment (including fee if present) */
  amount: Scalars['Int'];
  /** the fee amount for the payment (part of the amount column) */
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentAvgAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  applicationFeeAmount?: Maybe<Scalars['Float']>;
};

export type CareEnrollmentPaymentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  careAddonEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput = {
  carePlanEnrollmentId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
};

export type CareEnrollmentPaymentCountAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  applicationFeeAmount: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  id: Scalars['Int'];
  lastPaymentAttempt: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  nextPaymentAttempt: Scalars['Int'];
  paidAt: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
};

export type CareEnrollmentPaymentCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentCreateInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateManyInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope = {
  data: Array<CareEnrollmentPaymentCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCareAddonEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput>;
};

export type CareEnrollmentPaymentCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentListRelationFilter = {
  every?: Maybe<CareEnrollmentPaymentWhereInput>;
  none?: Maybe<CareEnrollmentPaymentWhereInput>;
  some?: Maybe<CareEnrollmentPaymentWhereInput>;
};

export type CareEnrollmentPaymentMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentMinAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentPaymentOrderByRelevanceFieldEnum {
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareEnrollmentPaymentOrderByRelevanceInput = {
  fields: Array<CareEnrollmentPaymentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareEnrollmentPaymentOrderByWithAggregationInput = {
  _avg?: Maybe<CareEnrollmentPaymentAvgOrderByAggregateInput>;
  _count?: Maybe<CareEnrollmentPaymentCountOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentPaymentMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentPaymentMinOrderByAggregateInput>;
  _sum?: Maybe<CareEnrollmentPaymentSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareEnrollmentPaymentOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentAttempt?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  nextPaymentAttempt?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
};

export enum CareEnrollmentPaymentScalarFieldEnum {
  Amount = 'amount',
  ApplicationFeeAmount = 'applicationFeeAmount',
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  Id = 'id',
  LastPaymentAttempt = 'lastPaymentAttempt',
  LastPaymentError = 'lastPaymentError',
  NextPaymentAttempt = 'nextPaymentAttempt',
  PaidAt = 'paidAt',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareEnrollmentPaymentScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  applicationFeeAmount?: Maybe<IntNullableFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
};

export type CareEnrollmentPaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  applicationFeeAmount?: Maybe<IntNullableWithAggregatesFilter>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type CareEnrollmentPaymentSumAggregateOutputType = {
  __typename?: 'CareEnrollmentPaymentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
};

export type CareEnrollmentPaymentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  applicationFeeAmount?: Maybe<SortOrder>;
};

export type CareEnrollmentPaymentUncheckedCreateInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
  where: CareEnrollmentPaymentScalarWhereInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentsInput;
  where: CareEnrollmentPaymentScalarWhereInput;
};

export type CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput = {
  data: CareEnrollmentPaymentUncheckedUpdateManyWithoutEnrollmentPaymentInput;
  where: CareEnrollmentPaymentScalarWhereInput;
};

export type CareEnrollmentPaymentUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCareAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentPaymentCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareEnrollmentPaymentCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareEnrollmentPaymentCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentPaymentWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareEnrollmentPaymentUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  data: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  data: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentUpdateWithoutCareAddonEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput>;
};

export type CareEnrollmentPaymentUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  applicationFeeAmount?: Maybe<Scalars['Int']>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  lastPaymentAttempt?: Maybe<Scalars['DateTime']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutCareAddonEnrollmentInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutCarePlanEnrollmentInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  create: CareEnrollmentPaymentUncheckedCreateWithoutStripeInvoiceInput;
  update: CareEnrollmentPaymentUncheckedUpdateWithoutStripeInvoiceInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};

export type CareEnrollmentPaymentWhereInput = {
  AND?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentPaymentWhereInput>>;
  amount?: Maybe<IntFilter>;
  applicationFeeAmount?: Maybe<IntNullableFilter>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lastPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  nextPaymentAttempt?: Maybe<DateTimeNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
};

export type CareEnrollmentPaymentWhereUniqueInput = {
  careAddonEnrollmentId_stripeInvoiceId?: Maybe<CareEnrollmentPaymentCareAddonEnrollmentIdStripeInvoiceIdCompoundUniqueInput>;
  carePlanEnrollmentId_stripeInvoiceId?: Maybe<CareEnrollmentPaymentCarePlanEnrollmentIdStripeInvoiceIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItem = {
  __typename?: 'CareEnrollmentToStripeInvoiceItem';
  careAddonEnrollment?: Maybe<CareAddonEnrollment>;
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollment>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  stripeInvoiceItem: StripeInvoiceItem;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemCountAggregateOutputType';
  _all: Scalars['Int'];
  careAddonEnrollmentId: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  id: Scalars['Int'];
  stripeInvoiceItemId: Scalars['Int'];
};

export type CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput = {
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemCreateInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput = {
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope = {
  data: Array<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCareAddonEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput = {
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem: StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemListRelationFilter = {
  every?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  none?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
  some?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMaxAggregateOutputType';
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput = {
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'CareEnrollmentToStripeInvoiceItemMinAggregateOutputType';
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput = {
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum {
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  Id = 'id',
  StripeInvoiceItemId = 'stripeInvoiceItemId'
}

export type CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<CareEnrollmentToStripeInvoiceItemOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithAggregationInput = {
  _count?: Maybe<CareEnrollmentToStripeInvoiceItemCountOrderByAggregateInput>;
  _max?: Maybe<CareEnrollmentToStripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<CareEnrollmentToStripeInvoiceItemMinOrderByAggregateInput>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export type CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelevanceInput>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  careAddonEnrollmentId?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceItemId?: Maybe<SortOrder>;
};

export enum CareEnrollmentToStripeInvoiceItemScalarFieldEnum {
  CareAddonEnrollmentId = 'careAddonEnrollmentId',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  Id = 'id',
  StripeInvoiceItemId = 'stripeInvoiceItemId'
}

export type CareEnrollmentToStripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
};

export type CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereWithAggregatesInput>>;
  careAddonEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeInvoiceItemId?: Maybe<StringWithAggregatesFilter>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCareAddonEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput = {
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId: Scalars['String'];
};

export type CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput = {
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItemId?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput = {
  careAddonEnrollmentId?: Maybe<Scalars['String']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutEnrollmentToStripeInvoiceItemsInput;
  where: CareEnrollmentToStripeInvoiceItemScalarWhereInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCareAddonEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCareAddonEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCareAddonEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCareAddonEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCareAddonEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput = {
  connect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateOrConnectWithoutStripeInvoiceItemInput>>;
  create?: Maybe<Array<CareEnrollmentToStripeInvoiceItemCreateWithoutStripeInvoiceItemInput>>;
  createMany?: Maybe<CareEnrollmentToStripeInvoiceItemCreateManyStripeInvoiceItemInputEnvelope>;
  delete?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput>>;
  updateMany?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceItemInput>>;
  upsert?: Maybe<Array<CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput>>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceItemInput = {
  data: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCareAddonEnrollmentInput = {
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutCarePlanEnrollmentInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
};

export type CareEnrollmentToStripeInvoiceItemUpdateWithoutStripeInvoiceItemInput = {
  careAddonEnrollment?: Maybe<CareAddonEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput>;
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCareAddonEnrollmentInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCareAddonEnrollmentInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCareAddonEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutCarePlanEnrollmentInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceItemInput = {
  create: CareEnrollmentToStripeInvoiceItemUncheckedCreateWithoutStripeInvoiceItemInput;
  update: CareEnrollmentToStripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceItemInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};

export type CareEnrollmentToStripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<CareEnrollmentToStripeInvoiceItemWhereInput>>;
  careAddonEnrollment?: Maybe<CareAddonEnrollmentWhereInput>;
  careAddonEnrollmentId?: Maybe<StringNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  carePlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  stripeInvoiceItem?: Maybe<StripeInvoiceItemWhereInput>;
  stripeInvoiceItemId?: Maybe<StringFilter>;
};

export type CareEnrollmentToStripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareEnrollmentWhereInput = {
  organizationPetId: Scalars['String'];
};

export type CareEnrollmentsByClinicOutput = {
  __typename?: 'CareEnrollmentsByClinicOutput';
  carePlanProviderGroupId: Scalars['String'];
  dailyTotal: Scalars['Int'];
  enrollmentData: CareEnrollmentData;
  monthlyTotal: Scalars['Int'];
};

export type CareEnrollmentsByClinicWhereInput = {
  carePlanProviderGroupId: Scalars['String'];
};

export type CareLapsedPayment = {
  __typename?: 'CareLapsedPayment';
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status: CareLapsedPaymentStatus;
  stripeInvoice: StripeInvoice;
  stripeInvoiceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CareLapsedPaymentCountAggregateOutputType = {
  __typename?: 'CareLapsedPaymentCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  expiredAt: Scalars['Int'];
  id: Scalars['Int'];
  lastNotifiedAt: Scalars['Int'];
  resolvedAt: Scalars['Int'];
  status: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareLapsedPaymentCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutLapsedPaymentInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput = {
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput = {
  create: CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput;
  where: CareLapsedPaymentWhereUniqueInput;
};

export type CareLapsedPaymentCreateWithoutStripeInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMaxAggregateOutputType = {
  __typename?: 'CareLapsedPaymentMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentMinAggregateOutputType = {
  __typename?: 'CareLapsedPaymentMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CareLapsedPaymentOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeInvoiceId = 'stripeInvoiceId'
}

export type CareLapsedPaymentOrderByRelevanceInput = {
  fields: Array<CareLapsedPaymentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareLapsedPaymentOrderByWithAggregationInput = {
  _count?: Maybe<CareLapsedPaymentCountOrderByAggregateInput>;
  _max?: Maybe<CareLapsedPaymentMaxOrderByAggregateInput>;
  _min?: Maybe<CareLapsedPaymentMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareLapsedPaymentOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastNotifiedAt?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareLapsedPaymentRelationFilter = {
  is?: Maybe<CareLapsedPaymentWhereInput>;
  isNot?: Maybe<CareLapsedPaymentWhereInput>;
};

export enum CareLapsedPaymentScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  LastNotifiedAt = 'lastNotifiedAt',
  ResolvedAt = 'resolvedAt',
  Status = 'status',
  StripeInvoiceId = 'stripeInvoiceId',
  UpdatedAt = 'updatedAt'
}

export type CareLapsedPaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareLapsedPaymentScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastNotifiedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  resolvedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumCareLapsedPaymentStatusWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum CareLapsedPaymentStatus {
  Expired = 'Expired',
  Pending = 'Pending',
  Resolved = 'Resolved'
}

export type CareLapsedPaymentUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput = {
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput>;
  upsert?: Maybe<CareLapsedPaymentUpsertWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutLapsedPaymentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<CareLapsedPaymentCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput>;
  upsert?: Maybe<CareLapsedPaymentUpsertWithoutStripeInvoiceInput>;
};

export type CareLapsedPaymentUpdateWithoutStripeInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CareLapsedPaymentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareLapsedPaymentUpsertWithoutStripeInvoiceInput = {
  create: CareLapsedPaymentUncheckedCreateWithoutStripeInvoiceInput;
  update: CareLapsedPaymentUncheckedUpdateWithoutStripeInvoiceInput;
};

export type CareLapsedPaymentWhereInput = {
  AND?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  NOT?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  OR?: Maybe<Array<CareLapsedPaymentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  lastNotifiedAt?: Maybe<DateTimeNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumCareLapsedPaymentStatusFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeInvoiceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareLapsedPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export type CareOrganizationInfo = {
  __typename?: 'CareOrganizationInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CarePetSignupInput = {
  carePlanId: Scalars['String'];
  organizationPetId: Scalars['String'];
};

export type CarePlan = {
  __typename?: 'CarePlan';
  _count: CarePlanCountOutputType;
  allowSelfRenewal: Scalars['Boolean'];
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle: CarePlanCycleType;
  createdAt: Scalars['DateTime'];
  currentPlanEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlan>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes: Scalars['String'];
  enrollments: Array<CarePlanEnrollment>;
  explicitRenewalSuccessors: Array<CarePlanEnrollment>;
  flowAlias?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAcceptingPreEnrollments: Scalars['Boolean'];
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  /** Note: we will eventually transform this into proper models once the marketing information gets more nailed down */
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  organization: Organization;
  organizationId: Scalars['String'];
  planBenefits: Array<CarePlanBenefit>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal: Scalars['Int'];
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle: CarePlanCycleType;
  renewalPredecessorPlan: Array<CarePlan>;
  salesTaxCentsPerMonth: Scalars['Int'];
  selectedByRenewalEmailEnrollments: Array<CarePlanEnrollment>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CarePlanCurrentPlanEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type CarePlanEnrollmentsArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type CarePlanExplicitRenewalSuccessorsArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type CarePlanNewPlanEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type CarePlanPlanBenefitsArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  distinct?: Maybe<CarePlanBenefitScalarFieldEnum>;
  orderBy?: Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type CarePlanRenewalPredecessorPlanArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type CarePlanSelectedByRenewalEmailEnrollmentsArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type CarePlanAvgAggregateOutputType = {
  __typename?: 'CarePlanAvgAggregateOutputType';
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Float']>;
  planDiscount?: Maybe<Scalars['Float']>;
  pricePerRenewal?: Maybe<Scalars['Float']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Float']>;
  signupFeePricing?: Maybe<Scalars['Float']>;
};

export type CarePlanAvgOrderByAggregateInput = {
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
};

export type CarePlanBenefit = {
  __typename?: 'CarePlanBenefit';
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  includedUses?: Maybe<Scalars['Int']>;
  plan: CarePlan;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages: Scalars['Float'];
  reportingExpectedValue: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type CarePlanBenefitAvgAggregateOutputType = {
  __typename?: 'CarePlanBenefitAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
  includedUses?: Maybe<Scalars['Float']>;
  providedDiscountPercentage?: Maybe<Scalars['Float']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Float']>;
};

export type CarePlanBenefitAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export type CarePlanBenefitCountAggregateOutputType = {
  __typename?: 'CarePlanBenefitCountAggregateOutputType';
  _all: Scalars['Int'];
  benefitId: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayOrder: Scalars['Int'];
  id: Scalars['Int'];
  includedUses: Scalars['Int'];
  planId: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  providedDiscountPercentage: Scalars['Int'];
  reportingExpectedUsages: Scalars['Int'];
  reportingExpectedValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CarePlanBenefitCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitCreateManyBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitCreateManyBenefitInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateManyInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitCreateManyPlanInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitCreateManyPlanInputEnvelope = {
  data: Array<CarePlanBenefitCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
};

export type CarePlanBenefitCreateNestedManyWithoutPlanInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
};

export type CarePlanBenefitCreateOrConnectWithoutBenefitInput = {
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitCreateOrConnectWithoutPlanInput = {
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  plan: CarePlanCreateNestedOneWithoutPlanBenefitsInput;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitCreateWithoutPlanInput = {
  benefit: CareBenefitCreateNestedOneWithoutPlanBenefitsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryover = {
  __typename?: 'CarePlanBenefitEnrollmentCarryover';
  amount: Scalars['Int'];
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  destinationPlanEnrollment?: Maybe<CarePlanEnrollment>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  sourcePlanEnrollment?: Maybe<CarePlanEnrollment>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type CarePlanBenefitEnrollmentCarryoverAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  benefitId: Scalars['Int'];
  createdAt: Scalars['Int'];
  destinationPlanEnrollmentId: Scalars['Int'];
  id: Scalars['Int'];
  sourcePlanEnrollmentId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CarePlanBenefitEnrollmentCarryoverCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope = {
  data: Array<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit: CareBenefitCreateNestedOneWithoutEnrollmentCarryoversInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverListRelationFilter = {
  every?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  none?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
  some?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};

export type CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanBenefitEnrollmentCarryoverOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  DestinationPlanEnrollmentId = 'destinationPlanEnrollmentId',
  Id = 'id',
  SourcePlanEnrollmentId = 'sourcePlanEnrollmentId'
}

export type CarePlanBenefitEnrollmentCarryoverOrderByRelevanceInput = {
  fields: Array<CarePlanBenefitEnrollmentCarryoverOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CarePlanBenefitEnrollmentCarryoverOrderByWithAggregationInput = {
  _avg?: Maybe<CarePlanBenefitEnrollmentCarryoverAvgOrderByAggregateInput>;
  _count?: Maybe<CarePlanBenefitEnrollmentCarryoverCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanBenefitEnrollmentCarryoverMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanBenefitEnrollmentCarryoverMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanBenefitEnrollmentCarryoverSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  destinationPlanEnrollmentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  sourcePlanEnrollmentId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CarePlanBenefitEnrollmentCarryoverScalarFieldEnum {
  Amount = 'amount',
  BenefitId = 'benefitId',
  CreatedAt = 'createdAt',
  DestinationPlanEnrollmentId = 'destinationPlanEnrollmentId',
  Id = 'id',
  SourcePlanEnrollmentId = 'sourcePlanEnrollmentId',
  UpdatedAt = 'updatedAt'
}

export type CarePlanBenefitEnrollmentCarryoverScalarWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationPlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  sourcePlanEnrollmentId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  destinationPlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  sourcePlanEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CarePlanBenefitEnrollmentCarryoverSourcePlanEnrollmentIdDestinationPlanEnrollmentIdBenefitIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  destinationPlanEnrollmentId: Scalars['String'];
  sourcePlanEnrollmentId: Scalars['String'];
};

export type CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType = {
  __typename?: 'CarePlanBenefitEnrollmentCarryoverSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitEnrollmentCarryoverSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutEnrollmentCarryoversInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutForwardedCarePlanBenefitCarryoversInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutReceivedCarePlanBenefitCarryoversInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutEnrollmentCarryoversInput;
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutReceivedCarePlanBenefitCarryoversInput;
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutForwardedCarePlanBenefitCarryoversInput;
  where: CarePlanBenefitEnrollmentCarryoverScalarWhereInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutDestinationPlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutDestinationPlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManyDestinationPlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutDestinationPlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateOrConnectWithoutSourcePlanEnrollmentInput>>;
  create?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverCreateWithoutSourcePlanEnrollmentInput>>;
  createMany?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateManySourcePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpdateManyWithWhereWithoutSourcePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput>>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutBenefitInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutDestinationPlanEnrollmentInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithWhereUniqueWithoutSourcePlanEnrollmentInput = {
  data: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutBenefitInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutDestinationPlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpdateWithoutSourcePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutEnrollmentCarryoversNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutBenefitInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutBenefitInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutBenefitInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutDestinationPlanEnrollmentInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutDestinationPlanEnrollmentInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutDestinationPlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverUpsertWithWhereUniqueWithoutSourcePlanEnrollmentInput = {
  create: CarePlanBenefitEnrollmentCarryoverUncheckedCreateWithoutSourcePlanEnrollmentInput;
  update: CarePlanBenefitEnrollmentCarryoverUncheckedUpdateWithoutSourcePlanEnrollmentInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};

export type CarePlanBenefitEnrollmentCarryoverWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitEnrollmentCarryoverWhereInput>>;
  amount?: Maybe<IntFilter>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationPlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  destinationPlanEnrollmentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  sourcePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  sourcePlanEnrollmentId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanBenefitEnrollmentCarryoverWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sourcePlanEnrollmentId_destinationPlanEnrollmentId_benefitId?: Maybe<CarePlanBenefitEnrollmentCarryoverSourcePlanEnrollmentIdDestinationPlanEnrollmentIdBenefitIdCompoundUniqueInput>;
};

export type CarePlanBenefitListRelationFilter = {
  every?: Maybe<CarePlanBenefitWhereInput>;
  none?: Maybe<CarePlanBenefitWhereInput>;
  some?: Maybe<CarePlanBenefitWhereInput>;
};

export type CarePlanBenefitMaxAggregateOutputType = {
  __typename?: 'CarePlanBenefitMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitMinAggregateOutputType = {
  __typename?: 'CarePlanBenefitMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanBenefitOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  Id = 'id',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings'
}

export type CarePlanBenefitOrderByRelevanceInput = {
  fields: Array<CarePlanBenefitOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CarePlanBenefitOrderByWithAggregationInput = {
  _avg?: Maybe<CarePlanBenefitAvgOrderByAggregateInput>;
  _count?: Maybe<CarePlanBenefitCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanBenefitMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanBenefitMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanBenefitSumOrderByAggregateInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CarePlanBenefitOrderByRelevanceInput>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  planId?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput = {
  displayOrder: Scalars['Int'];
  planId: Scalars['String'];
};

export enum CarePlanBenefitScalarFieldEnum {
  BenefitId = 'benefitId',
  CreatedAt = 'createdAt',
  DisplayOrder = 'displayOrder',
  Id = 'id',
  IncludedUses = 'includedUses',
  PlanId = 'planId',
  ProjectedSavings = 'projectedSavings',
  ProvidedDiscountPercentage = 'providedDiscountPercentage',
  ReportingExpectedUsages = 'reportingExpectedUsages',
  ReportingExpectedValue = 'reportingExpectedValue',
  UpdatedAt = 'updatedAt'
}

export type CarePlanBenefitScalarWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  planId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  reportingExpectedUsages?: Maybe<FloatFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanBenefitScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanBenefitScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayOrder?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  includedUses?: Maybe<IntNullableWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
  providedDiscountPercentage?: Maybe<IntNullableWithAggregatesFilter>;
  reportingExpectedUsages?: Maybe<FloatWithAggregatesFilter>;
  reportingExpectedValue?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CarePlanBenefitSumAggregateOutputType = {
  __typename?: 'CarePlanBenefitSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
  includedUses?: Maybe<Scalars['Int']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
};

export type CarePlanBenefitSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
  includedUses?: Maybe<SortOrder>;
  providedDiscountPercentage?: Maybe<SortOrder>;
  reportingExpectedUsages?: Maybe<SortOrder>;
  reportingExpectedValue?: Maybe<SortOrder>;
};

export type CarePlanBenefitUncheckedCreateInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
};

export type CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
};

export type CarePlanBenefitUncheckedCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId: Scalars['String'];
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedCreateWithoutPlanInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
};

export type CarePlanBenefitUncheckedUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['String']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUncheckedUpdateWithoutPlanInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput = {
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
  where: CarePlanBenefitScalarWhereInput;
};

export type CarePlanBenefitUpdateManyWithWhereWithoutPlanInput = {
  data: CarePlanBenefitUncheckedUpdateManyWithoutPlanBenefitsInput;
  where: CarePlanBenefitScalarWhereInput;
};

export type CarePlanBenefitUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutBenefitInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type CarePlanBenefitUpdateManyWithoutPlanNestedInput = {
  connect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanBenefitCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanBenefitCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanBenefitCreateManyPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanBenefitScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanBenefitWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanBenefitUpdateManyWithWhereWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput>>;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutBenefitInput = {
  data: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitUpdateWithWhereUniqueWithoutPlanInput = {
  data: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUpdateWithoutPlanInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutPlanBenefitsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  includedUses?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  providedDiscountPercentage?: Maybe<Scalars['Int']>;
  reportingExpectedUsages?: Maybe<Scalars['Float']>;
  reportingExpectedValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutBenefitInput = {
  create: CarePlanBenefitUncheckedCreateWithoutBenefitInput;
  update: CarePlanBenefitUncheckedUpdateWithoutBenefitInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitUpsertWithWhereUniqueWithoutPlanInput = {
  create: CarePlanBenefitUncheckedCreateWithoutPlanInput;
  update: CarePlanBenefitUncheckedUpdateWithoutPlanInput;
  where: CarePlanBenefitWhereUniqueInput;
};

export type CarePlanBenefitWhereInput = {
  AND?: Maybe<Array<CarePlanBenefitWhereInput>>;
  NOT?: Maybe<Array<CarePlanBenefitWhereInput>>;
  OR?: Maybe<Array<CarePlanBenefitWhereInput>>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  includedUses?: Maybe<IntNullableFilter>;
  plan?: Maybe<CarePlanWhereInput>;
  planId?: Maybe<StringFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  providedDiscountPercentage?: Maybe<IntNullableFilter>;
  reportingExpectedUsages?: Maybe<FloatFilter>;
  reportingExpectedValue?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanBenefitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  planId_displayOrder?: Maybe<CarePlanBenefitPlanIdDisplayOrderCompoundUniqueInput>;
};

export type CarePlanCountAggregateOutputType = {
  __typename?: 'CarePlanCountAggregateOutputType';
  _all: Scalars['Int'];
  allowSelfRenewal: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  billingCycle: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultRenewalSuccessorPlanId: Scalars['Int'];
  description: Scalars['Int'];
  earliestTargetPetAgeInMonths: Scalars['Int'];
  editorNotes: Scalars['Int'];
  flowAlias: Scalars['Int'];
  id: Scalars['Int'];
  isAcceptingPreEnrollments: Scalars['Int'];
  latestTargetPetAgeInMonths: Scalars['Int'];
  marketingInformation: Scalars['Int'];
  organizationId: Scalars['Int'];
  planDiscount: Scalars['Int'];
  pricePerRenewal: Scalars['Int'];
  projectedSavings: Scalars['Int'];
  publishEndDate: Scalars['Int'];
  publishStartDate: Scalars['Int'];
  published: Scalars['Int'];
  renewalCycle: Scalars['Int'];
  salesTaxCentsPerMonth: Scalars['Int'];
  signupFeePricing: Scalars['Int'];
  signupFeeStripeProductId: Scalars['Int'];
  stripePriceId: Scalars['Int'];
  targetSpecies: Scalars['Int'];
  themeColor: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CarePlanCountOrderByAggregateInput = {
  allowSelfRenewal?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanCountOutputType = {
  __typename?: 'CarePlanCountOutputType';
  currentPlanEnrollmentSupportEvents: Scalars['Int'];
  enrollments: Scalars['Int'];
  explicitRenewalSuccessors: Scalars['Int'];
  newPlanEnrollmentSupportEvents: Scalars['Int'];
  planBenefits: Scalars['Int'];
  renewalPredecessorPlan: Scalars['Int'];
  selectedByRenewalEmailEnrollments: Scalars['Int'];
};

export type CarePlanCreateInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateManyAssociatedCarePlanProviderGroupInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId: Scalars['String'];
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CarePlanCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateManyDefaultRenewalSuccessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId: Scalars['String'];
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope = {
  data: Array<CarePlanCreateManyDefaultRenewalSuccessorPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateManyInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId: Scalars['String'];
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateManyOrganizationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateManyOrganizationInputEnvelope = {
  data: Array<CarePlanCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
};

export type CarePlanCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
};

export type CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput>;
};

export type CarePlanCreateNestedOneWithoutEnrollmentsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
};

export type CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput>;
};

export type CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput>;
};

export type CarePlanCreateNestedOneWithoutPlanBenefitsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
};

export type CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput>;
};

export type CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput>;
};

export type CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  create: CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput = {
  create: CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput = {
  create: CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutEnrollmentsInput = {
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput = {
  create: CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput = {
  create: CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutOrganizationInput = {
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutPlanBenefitsInput = {
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput = {
  create: CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput = {
  create: CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutExplicitRenewalSuccessorsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutNewPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutOrganizationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutPlanBenefitsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutRenewalPredecessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanCreateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanCreateNestedOneWithoutRenewalPredecessorPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput>;
  organization: OrganizationCreateNestedOneWithoutCarePlansInput;
  planBenefits?: Maybe<CarePlanBenefitCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CarePlanCycleType {
  Annually = 'Annually',
  Monthly = 'Monthly',
  Semianually = 'Semianually'
}

export type CarePlanEnrollment = {
  __typename?: 'CarePlanEnrollment';
  _count: CarePlanEnrollmentCountOutputType;
  associatedUsages: Array<CareBenefitUsage>;
  autoRenews: Scalars['Boolean'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscription>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  createdAt: Scalars['DateTime'];
  enrollmentPayments: Array<CareEnrollmentPayment>;
  enrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlan>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  id: Scalars['String'];
  organizationPet: OrganizationPet;
  organizationPetId: Scalars['String'];
  plan: CarePlan;
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers: Array<CarePlanBenefitEnrollmentCarryover>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlan>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollment>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollment>;
  startDate: Scalars['DateTime'];
  status: EnrollmentStatus;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type CarePlanEnrollmentAssociatedUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type CarePlanEnrollmentCareSubscriptionTrueUpsArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type CarePlanEnrollmentEnrollmentPaymentsArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type CarePlanEnrollmentEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type CarePlanEnrollmentEnrollmentToStripeInvoiceItemsArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type CarePlanEnrollmentForwardedCarePlanBenefitCarryoversArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type CarePlanEnrollmentReceivedCarePlanBenefitCarryoversArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>;
  orderBy?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};

export type CarePlanEnrollmentCountAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentCountAggregateOutputType';
  _all: Scalars['Int'];
  autoRenews: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  createdAt: Scalars['Int'];
  expirationDate: Scalars['Int'];
  explicitRenewalSuccessorPlanId: Scalars['Int'];
  id: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  planId: Scalars['Int'];
  renewalEmailNoticeSentAt: Scalars['Int'];
  renewalEmailSelectedTargetPlanId: Scalars['Int'];
  renewalPredecessorEnrollmentId: Scalars['Int'];
  renewalRejectionReason: Scalars['Int'];
  startDate: Scalars['Int'];
  status: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CarePlanEnrollmentCountOrderByAggregateInput = {
  autoRenews?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentCountOutputType = {
  __typename?: 'CarePlanEnrollmentCountOutputType';
  associatedUsages: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
  enrollmentPayments: Scalars['Int'];
  enrollmentSupportEvents: Scalars['Int'];
  enrollmentToStripeInvoiceItems: Scalars['Int'];
  forwardedCarePlanBenefitCarryovers: Scalars['Int'];
  receivedCarePlanBenefitCarryovers: Scalars['Int'];
};

export type CarePlanEnrollmentCreateInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyPlanInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope = {
  data: Array<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
};

export type CarePlanEnrollmentCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutAssociatedUsagesInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentPaymentsInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutForwardedCarePlanBenefitCarryoversInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutReceivedCarePlanBenefitCarryoversInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput>;
};

export type CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentCreateWithoutAssociatedUsagesInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutCareSubscriptionTrueUpsInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentPaymentsInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentSupportEventsInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutForwardedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutOrganizationPetInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutReceivedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutRenewalPredecessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentCreateWithoutRenewalSuccessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanCreateNestedOneWithoutExplicitRenewalSuccessorsInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet: OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput;
  plan: CarePlanCreateNestedOneWithoutEnrollmentsInput;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanCreateNestedOneWithoutSelectedByRenewalEmailEnrollmentsInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentCreateNestedOneWithoutRenewalSuccessorEnrollmentInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentListRelationFilter = {
  every?: Maybe<CarePlanEnrollmentWhereInput>;
  none?: Maybe<CarePlanEnrollmentWhereInput>;
  some?: Maybe<CarePlanEnrollmentWhereInput>;
};

export type CarePlanEnrollmentMaxAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMaxAggregateOutputType';
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentMaxOrderByAggregateInput = {
  autoRenews?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentMinAggregateOutputType = {
  __typename?: 'CarePlanEnrollmentMinAggregateOutputType';
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentMinOrderByAggregateInput = {
  autoRenews?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanEnrollmentOrderByRelevanceFieldEnum {
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  ExplicitRenewalSuccessorPlanId = 'explicitRenewalSuccessorPlanId',
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  RenewalEmailSelectedTargetPlanId = 'renewalEmailSelectedTargetPlanId',
  RenewalPredecessorEnrollmentId = 'renewalPredecessorEnrollmentId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type CarePlanEnrollmentOrderByRelevanceInput = {
  fields: Array<CarePlanEnrollmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CarePlanEnrollmentOrderByWithAggregationInput = {
  _count?: Maybe<CarePlanEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanEnrollmentMinOrderByAggregateInput>;
  autoRenews?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CarePlanEnrollmentOrderByRelevanceInput>;
  associatedUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  autoRenews?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  expirationDate?: Maybe<SortOrder>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  explicitRenewalSuccessorPlanId?: Maybe<SortOrder>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetId?: Maybe<SortOrder>;
  plan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  planId?: Maybe<SortOrder>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverOrderByRelationAggregateInput>;
  renewalEmailNoticeSentAt?: Maybe<SortOrder>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  renewalEmailSelectedTargetPlanId?: Maybe<SortOrder>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  renewalPredecessorEnrollmentId?: Maybe<SortOrder>;
  renewalRejectionReason?: Maybe<SortOrder>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanEnrollmentRelationFilter = {
  is?: Maybe<CarePlanEnrollmentWhereInput>;
  isNot?: Maybe<CarePlanEnrollmentWhereInput>;
};

export enum CarePlanEnrollmentScalarFieldEnum {
  AutoRenews = 'autoRenews',
  CancelledAt = 'cancelledAt',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  ExplicitRenewalSuccessorPlanId = 'explicitRenewalSuccessorPlanId',
  Id = 'id',
  OrganizationPetId = 'organizationPetId',
  PlanId = 'planId',
  RenewalEmailNoticeSentAt = 'renewalEmailNoticeSentAt',
  RenewalEmailSelectedTargetPlanId = 'renewalEmailSelectedTargetPlanId',
  RenewalPredecessorEnrollmentId = 'renewalPredecessorEnrollmentId',
  RenewalRejectionReason = 'renewalRejectionReason',
  StartDate = 'startDate',
  Status = 'status',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  UpdatedAt = 'updatedAt'
}

export type CarePlanEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  autoRenews?: Maybe<BoolFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  organizationPetId?: Maybe<StringFilter>;
  planId?: Maybe<StringFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableFilter>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableFilter>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentScalarWhereWithAggregatesInput>>;
  autoRenews?: Maybe<BoolWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  planId?: Maybe<StringWithAggregatesFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableWithAggregatesFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  status?: Maybe<EnumEnrollmentStatusWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CarePlanEnrollmentUncheckedCreateInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
};

export type CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
};

export type CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedCreateNestedOneWithoutRenewalPredecessorEnrollmentInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutConnectedPlanEnrollmentInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutSourcePlanEnrollmentInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId: Scalars['String'];
  planId: Scalars['String'];
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedCreateNestedManyWithoutDestinationPlanEnrollmentInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSuccessorsInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
};

export type CarePlanEnrollmentUncheckedUpdateManyWithoutSelectedByRenewalEmailEnrollmentsInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUncheckedUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUncheckedUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlanId?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateManyMutationInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
  where: CarePlanEnrollmentScalarWhereInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSuccessorsInput;
  where: CarePlanEnrollmentScalarWhereInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput = {
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutCarePlanEnrollmentsInput;
  where: CarePlanEnrollmentScalarWhereInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutEnrollmentsInput;
  where: CarePlanEnrollmentScalarWhereInput;
};

export type CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateManyWithoutSelectedByRenewalEmailEnrollmentsInput;
  where: CarePlanEnrollmentScalarWhereInput;
};

export type CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutExplicitRenewalSucccesorPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutExplicitRenewalSucccesorPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyExplicitRenewalSucccesorPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutExplicitRenewalSucccesorPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput>>;
};

export type CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput = {
  connect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanEnrollmentCreateOrConnectWithoutRenewalEmailSelectedTargetPlanInput>>;
  create?: Maybe<Array<CarePlanEnrollmentCreateWithoutRenewalEmailSelectedTargetPlanInput>>;
  createMany?: Maybe<CarePlanEnrollmentCreateManyRenewalEmailSelectedTargetPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
  updateMany?: Maybe<Array<CarePlanEnrollmentUpdateManyWithWhereWithoutRenewalEmailSelectedTargetPlanInput>>;
  upsert?: Maybe<Array<CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput>>;
};

export type CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutCareSubscriptionTrueUpsInput>;
};

export type CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentSupportEventsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutAssociatedUsagesNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutAssociatedUsagesInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentPaymentsNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentPaymentsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutForwardedCarePlanBenefitCarryoversNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutForwardedCarePlanBenefitCarryoversInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutForwardedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutReceivedCarePlanBenefitCarryoversNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutReceivedCarePlanBenefitCarryoversInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutReceivedCarePlanBenefitCarryoversInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalPredecessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput = {
  connect?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanEnrollmentCreateOrConnectWithoutRenewalSuccessorEnrollmentInput>;
  create?: Maybe<CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput>;
  upsert?: Maybe<CarePlanEnrollmentUpsertWithoutRenewalSuccessorEnrollmentInput>;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  data: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  data: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpdateWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput = {
  data: CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpdateWithoutAssociatedUsagesInput = {
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutCareStripeSubscriptionInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutCareSubscriptionTrueUpsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentPaymentsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentSupportEventsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutExplicitRenewalSucccesorPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutForwardedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutOrganizationPetInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutReceivedCarePlanBenefitCarryoversInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalEmailSelectedTargetPlanInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalPredecessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalPredecessorEnrollmentNestedInput>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpdateWithoutRenewalSuccessorEnrollmentInput = {
  associatedUsages?: Maybe<CareBenefitUsageUpdateManyWithoutConnectedPlanEnrollmentNestedInput>;
  autoRenews?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutCarePlanEnrollmentNestedInput>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutSourcePlanEnrollmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput>;
  plan?: Maybe<CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverUpdateManyWithoutDestinationPlanEnrollmentNestedInput>;
  renewalEmailNoticeSentAt?: Maybe<Scalars['DateTime']>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentUpdateOneWithoutRenewalSuccessorEnrollmentNestedInput>;
  renewalRejectionReason?: Maybe<RenewalRejectionReason>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EnrollmentStatus>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutCareStripeSubscriptionInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutExplicitRenewalSucccesorPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutExplicitRenewalSucccesorPlanInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutExplicitRenewalSucccesorPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutOrganizationPetInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutOrganizationPetInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutPlanInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpsertWithWhereUniqueWithoutRenewalEmailSelectedTargetPlanInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalEmailSelectedTargetPlanInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalEmailSelectedTargetPlanInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};

export type CarePlanEnrollmentUpsertWithoutAssociatedUsagesInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutAssociatedUsagesInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutAssociatedUsagesInput;
};

export type CarePlanEnrollmentUpsertWithoutCareSubscriptionTrueUpsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentPaymentsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentPaymentsInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentPaymentsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentSupportEventsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentSupportEventsInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentSupportEventsInput;
};

export type CarePlanEnrollmentUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type CarePlanEnrollmentUpsertWithoutForwardedCarePlanBenefitCarryoversInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutForwardedCarePlanBenefitCarryoversInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutForwardedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentUpsertWithoutReceivedCarePlanBenefitCarryoversInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutReceivedCarePlanBenefitCarryoversInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutReceivedCarePlanBenefitCarryoversInput;
};

export type CarePlanEnrollmentUpsertWithoutRenewalPredecessorEnrollmentInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalPredecessorEnrollmentInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalPredecessorEnrollmentInput;
};

export type CarePlanEnrollmentUpsertWithoutRenewalSuccessorEnrollmentInput = {
  create: CarePlanEnrollmentUncheckedCreateWithoutRenewalSuccessorEnrollmentInput;
  update: CarePlanEnrollmentUncheckedUpdateWithoutRenewalSuccessorEnrollmentInput;
};

export type CarePlanEnrollmentWhereInput = {
  AND?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  NOT?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  OR?: Maybe<Array<CarePlanEnrollmentWhereInput>>;
  associatedUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  autoRenews?: Maybe<BoolFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  careStripeSubscriptionId?: Maybe<StringNullableFilter>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  enrollmentPayments?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  enrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  explicitRenewalSucccesorPlan?: Maybe<CarePlanWhereInput>;
  explicitRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  forwardedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
  id?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetId?: Maybe<StringFilter>;
  plan?: Maybe<CarePlanWhereInput>;
  planId?: Maybe<StringFilter>;
  receivedCarePlanBenefitCarryovers?: Maybe<CarePlanBenefitEnrollmentCarryoverListRelationFilter>;
  renewalEmailNoticeSentAt?: Maybe<DateTimeNullableFilter>;
  renewalEmailSelectedTargetPlan?: Maybe<CarePlanWhereInput>;
  renewalEmailSelectedTargetPlanId?: Maybe<StringNullableFilter>;
  renewalPredecessorEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  renewalPredecessorEnrollmentId?: Maybe<StringNullableFilter>;
  renewalRejectionReason?: Maybe<EnumRenewalRejectionReasonNullableFilter>;
  renewalSuccessorEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumEnrollmentStatusFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  renewalPredecessorEnrollmentId?: Maybe<Scalars['String']>;
};

export type CarePlanListRelationFilter = {
  every?: Maybe<CarePlanWhereInput>;
  none?: Maybe<CarePlanWhereInput>;
  some?: Maybe<CarePlanWhereInput>;
};

export type CarePlanMaxAggregateOutputType = {
  __typename?: 'CarePlanMaxAggregateOutputType';
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanMaxOrderByAggregateInput = {
  allowSelfRenewal?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanMinAggregateOutputType = {
  __typename?: 'CarePlanMinAggregateOutputType';
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanMinOrderByAggregateInput = {
  allowSelfRenewal?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanOrderByRelevanceFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  DefaultRenewalSuccessorPlanId = 'defaultRenewalSuccessorPlanId',
  Description = 'description',
  EditorNotes = 'editorNotes',
  FlowAlias = 'flowAlias',
  Id = 'id',
  OrganizationId = 'organizationId',
  ProjectedSavings = 'projectedSavings',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
  StripePriceId = 'stripePriceId',
  ThemeColor = 'themeColor',
  Title = 'title'
}

export type CarePlanOrderByRelevanceInput = {
  fields: Array<CarePlanOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CarePlanOrderByWithAggregationInput = {
  _avg?: Maybe<CarePlanAvgOrderByAggregateInput>;
  _count?: Maybe<CarePlanCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanMinOrderByAggregateInput>;
  _sum?: Maybe<CarePlanSumOrderByAggregateInput>;
  allowSelfRenewal?: Maybe<SortOrder>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CarePlanOrderByRelevanceInput>;
  allowSelfRenewal?: Maybe<SortOrder>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  billingCycle?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  defaultRenewalSuccessorPlanId?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  editorNotes?: Maybe<SortOrder>;
  enrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAcceptingPreEnrollments?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  marketingInformation?: Maybe<SortOrder>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  planBenefits?: Maybe<CarePlanBenefitOrderByRelationAggregateInput>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  projectedSavings?: Maybe<SortOrder>;
  publishEndDate?: Maybe<SortOrder>;
  publishStartDate?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  renewalCycle?: Maybe<SortOrder>;
  renewalPredecessorPlan?: Maybe<CarePlanOrderByRelationAggregateInput>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  signupFeePricing?: Maybe<SortOrder>;
  signupFeeStripeProductId?: Maybe<SortOrder>;
  stripePriceId?: Maybe<SortOrder>;
  targetSpecies?: Maybe<SortOrder>;
  themeColor?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroup = {
  __typename?: 'CarePlanProviderGroup';
  _count: CarePlanProviderGroupCountOutputType;
  associatedAddonPackages: Array<CareAddonPackage>;
  associatedCareBenefits: Array<CareBenefit>;
  associatedPlans: Array<CarePlan>;
  associatedSubscriptions: Array<CareStripeSubscription>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  launchStatus: CareProgramLaunchStatus;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics: Array<Clinic>;
  paymentProcessorClinic?: Maybe<Clinic>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type CarePlanProviderGroupAssociatedAddonPackagesArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  distinct?: Maybe<CareAddonPackageScalarFieldEnum>;
  orderBy?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type CarePlanProviderGroupAssociatedCareBenefitsArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  distinct?: Maybe<CareBenefitScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type CarePlanProviderGroupAssociatedPlansArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type CarePlanProviderGroupAssociatedSubscriptionsArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type CarePlanProviderGroupParticipatingClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};

export type CarePlanProviderGroupCountAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  launchStatus: Scalars['Int'];
  organizationId: Scalars['Int'];
  paymentProcessorClinicId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CarePlanProviderGroupCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupCountOutputType = {
  __typename?: 'CarePlanProviderGroupCountOutputType';
  associatedAddonPackages: Scalars['Int'];
  associatedCareBenefits: Scalars['Int'];
  associatedPlans: Scalars['Int'];
  associatedSubscriptions: Scalars['Int'];
  participatingClinics: Scalars['Int'];
};

export type CarePlanProviderGroupCreateInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateManyOrganizationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateManyOrganizationInputEnvelope = {
  data: Array<CarePlanProviderGroupCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedAddonPackagesInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedCareBenefitsInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedPlansInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput>;
};

export type CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupCreateWithoutAssociatedAddonPackagesInput = {
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedCareBenefitsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedPlansInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutAssociatedSubscriptionsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutOrganizationInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutParticipatingClinicsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupCreateWithoutPaymentProcessorClinicInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutCarePlanProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupListRelationFilter = {
  every?: Maybe<CarePlanProviderGroupWhereInput>;
  none?: Maybe<CarePlanProviderGroupWhereInput>;
  some?: Maybe<CarePlanProviderGroupWhereInput>;
};

export type CarePlanProviderGroupMaxAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupMinAggregateOutputType = {
  __typename?: 'CarePlanProviderGroupMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CarePlanProviderGroupOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationId = 'organizationId',
  PaymentProcessorClinicId = 'paymentProcessorClinicId'
}

export type CarePlanProviderGroupOrderByRelevanceInput = {
  fields: Array<CarePlanProviderGroupOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CarePlanProviderGroupOrderByWithAggregationInput = {
  _count?: Maybe<CarePlanProviderGroupCountOrderByAggregateInput>;
  _max?: Maybe<CarePlanProviderGroupMaxOrderByAggregateInput>;
  _min?: Maybe<CarePlanProviderGroupMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CarePlanProviderGroupOrderByRelevanceInput>;
  associatedAddonPackages?: Maybe<CareAddonPackageOrderByRelationAggregateInput>;
  associatedCareBenefits?: Maybe<CareBenefitOrderByRelationAggregateInput>;
  associatedPlans?: Maybe<CarePlanOrderByRelationAggregateInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  launchStatus?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  participatingClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  paymentProcessorClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CarePlanProviderGroupRelationFilter = {
  is?: Maybe<CarePlanProviderGroupWhereInput>;
  isNot?: Maybe<CarePlanProviderGroupWhereInput>;
};

export enum CarePlanProviderGroupScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  LaunchStatus = 'launchStatus',
  OrganizationId = 'organizationId',
  PaymentProcessorClinicId = 'paymentProcessorClinicId',
  UpdatedAt = 'updatedAt'
}

export type CarePlanProviderGroupScalarWhereInput = {
  AND?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanProviderGroupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CarePlanProviderGroupUncheckedCreateInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
};

export type CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput = {
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedPlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateManyWithoutCarePlanProviderGroupsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput = {
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organizationId?: Maybe<Scalars['String']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput = {
  data: CarePlanProviderGroupUncheckedUpdateManyWithoutCarePlanProviderGroupsInput;
  where: CarePlanProviderGroupScalarWhereInput;
};

export type CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanProviderGroupCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanProviderGroupCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanProviderGroupCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanProviderGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanProviderGroupWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanProviderGroupUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedAddonPackagesNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedAddonPackagesInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedAddonPackagesInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedCareBenefitsNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedCareBenefitsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedCareBenefitsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedPlansInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedPlansInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutAssociatedSubscriptionsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutParticipatingClinicsInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutParticipatingClinicsInput>;
};

export type CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  connect?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanProviderGroupCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput>;
};

export type CarePlanProviderGroupUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedAddonPackagesInput = {
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedCareBenefitsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedPlansInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutAssociatedSubscriptionsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutOrganizationInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutParticipatingClinicsInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpdateWithoutPaymentProcessorClinicInput = {
  associatedAddonPackages?: Maybe<CareAddonPackageUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedCareBenefits?: Maybe<CareBenefitUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedPlans?: Maybe<CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  launchStatus?: Maybe<CareProgramLaunchStatus>;
  organization?: Maybe<OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanProviderGroupUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutOrganizationInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutOrganizationInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedAddonPackagesInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedAddonPackagesInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedAddonPackagesInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedCareBenefitsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedCareBenefitsInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedCareBenefitsInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedPlansInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedPlansInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedPlansInput;
};

export type CarePlanProviderGroupUpsertWithoutAssociatedSubscriptionsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutAssociatedSubscriptionsInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutAssociatedSubscriptionsInput;
};

export type CarePlanProviderGroupUpsertWithoutParticipatingClinicsInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutParticipatingClinicsInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutParticipatingClinicsInput;
};

export type CarePlanProviderGroupUpsertWithoutPaymentProcessorClinicInput = {
  create: CarePlanProviderGroupUncheckedCreateWithoutPaymentProcessorClinicInput;
  update: CarePlanProviderGroupUncheckedUpdateWithoutPaymentProcessorClinicInput;
};

export type CarePlanProviderGroupWhereInput = {
  AND?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  NOT?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  OR?: Maybe<Array<CarePlanProviderGroupWhereInput>>;
  associatedAddonPackages?: Maybe<CareAddonPackageListRelationFilter>;
  associatedCareBenefits?: Maybe<CareBenefitListRelationFilter>;
  associatedPlans?: Maybe<CarePlanListRelationFilter>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  launchStatus?: Maybe<EnumCareProgramLaunchStatusFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  participatingClinics?: Maybe<ClinicListRelationFilter>;
  paymentProcessorClinic?: Maybe<ClinicWhereInput>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanProviderGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

export type CarePlanRelationFilter = {
  is?: Maybe<CarePlanWhereInput>;
  isNot?: Maybe<CarePlanWhereInput>;
};

export enum CarePlanScalarFieldEnum {
  AllowSelfRenewal = 'allowSelfRenewal',
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  BillingCycle = 'billingCycle',
  CreatedAt = 'createdAt',
  DefaultRenewalSuccessorPlanId = 'defaultRenewalSuccessorPlanId',
  Description = 'description',
  EarliestTargetPetAgeInMonths = 'earliestTargetPetAgeInMonths',
  EditorNotes = 'editorNotes',
  FlowAlias = 'flowAlias',
  Id = 'id',
  IsAcceptingPreEnrollments = 'isAcceptingPreEnrollments',
  LatestTargetPetAgeInMonths = 'latestTargetPetAgeInMonths',
  MarketingInformation = 'marketingInformation',
  OrganizationId = 'organizationId',
  PlanDiscount = 'planDiscount',
  PricePerRenewal = 'pricePerRenewal',
  ProjectedSavings = 'projectedSavings',
  PublishEndDate = 'publishEndDate',
  PublishStartDate = 'publishStartDate',
  Published = 'published',
  RenewalCycle = 'renewalCycle',
  SalesTaxCentsPerMonth = 'salesTaxCentsPerMonth',
  SignupFeePricing = 'signupFeePricing',
  SignupFeeStripeProductId = 'signupFeeStripeProductId',
  StripePriceId = 'stripePriceId',
  TargetSpecies = 'targetSpecies',
  ThemeColor = 'themeColor',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type CarePlanScalarWhereInput = {
  AND?: Maybe<Array<CarePlanScalarWhereInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereInput>>;
  allowSelfRenewal?: Maybe<BoolFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  organizationId?: Maybe<StringFilter>;
  planDiscount?: Maybe<IntNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  publishEndDate?: Maybe<DateTimeNullableFilter>;
  publishStartDate?: Maybe<DateTimeNullableFilter>;
  published?: Maybe<BoolFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CarePlanScalarWhereWithAggregatesInput>>;
  allowSelfRenewal?: Maybe<BoolWithAggregatesFilter>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  editorNotes?: Maybe<StringWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolWithAggregatesFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  marketingInformation?: Maybe<JsonNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  planDiscount?: Maybe<IntNullableWithAggregatesFilter>;
  pricePerRenewal?: Maybe<IntWithAggregatesFilter>;
  projectedSavings?: Maybe<StringNullableWithAggregatesFilter>;
  publishEndDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  publishStartDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  published?: Maybe<BoolWithAggregatesFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeWithAggregatesFilter>;
  salesTaxCentsPerMonth?: Maybe<IntWithAggregatesFilter>;
  signupFeePricing?: Maybe<IntNullableWithAggregatesFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePriceId?: Maybe<StringNullableWithAggregatesFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableWithAggregatesFilter>;
  themeColor?: Maybe<StringNullableWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CarePlanSumAggregateOutputType = {
  __typename?: 'CarePlanSumAggregateOutputType';
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
};

export type CarePlanSumOrderByAggregateInput = {
  earliestTargetPetAgeInMonths?: Maybe<SortOrder>;
  latestTargetPetAgeInMonths?: Maybe<SortOrder>;
  planDiscount?: Maybe<SortOrder>;
  pricePerRenewal?: Maybe<SortOrder>;
  salesTaxCentsPerMonth?: Maybe<SortOrder>;
  signupFeePricing?: Maybe<SortOrder>;
};

export type CarePlanUncheckedCreateInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
};

export type CarePlanUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
};

export type CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutOrganizationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutPlanBenefitsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutRenewalEmailSelectedTargetPlanInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutPlanInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutExplicitRenewalSucccesorPlanInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput>;
  organizationId: Scalars['String'];
  planBenefits?: Maybe<CarePlanBenefitUncheckedCreateNestedManyWithoutPlanInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published: Scalars['Boolean'];
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedCreateNestedManyWithoutDefaultRenewalSuccessorPlanInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateManyInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutAssociatedPlansInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateManyWithoutCarePlansInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CarePlanUncheckedUpdateManyWithoutRenewalPredecessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutOrganizationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutPlanBenefitsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput>;
  organizationId?: Maybe<Scalars['String']>;
  planBenefits?: Maybe<CarePlanBenefitUncheckedUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUncheckedUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateManyMutationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  data: CarePlanUncheckedUpdateManyWithoutAssociatedPlansInput;
  where: CarePlanScalarWhereInput;
};

export type CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput = {
  data: CarePlanUncheckedUpdateManyWithoutRenewalPredecessorPlanInput;
  where: CarePlanScalarWhereInput;
};

export type CarePlanUpdateManyWithWhereWithoutOrganizationInput = {
  data: CarePlanUncheckedUpdateManyWithoutCarePlansInput;
  where: CarePlanScalarWhereInput;
};

export type CarePlanUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CarePlanCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutDefaultRenewalSuccessorPlanInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutDefaultRenewalSuccessorPlanInput>>;
  createMany?: Maybe<CarePlanCreateManyDefaultRenewalSuccessorPlanInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutDefaultRenewalSuccessorPlanInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput>>;
};

export type CarePlanUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CarePlanCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<CarePlanCreateWithoutOrganizationInput>>;
  createMany?: Maybe<CarePlanCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<CarePlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CarePlanScalarWhereInput>>;
  disconnect?: Maybe<Array<CarePlanWhereUniqueInput>>;
  set?: Maybe<Array<CarePlanWhereUniqueInput>>;
  update?: Maybe<Array<CarePlanUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CarePlanUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CarePlanUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CarePlanUpdateOneRequiredWithoutEnrollmentsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutEnrollmentsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutEnrollmentsInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutEnrollmentsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutEnrollmentsInput>;
};

export type CarePlanUpdateOneRequiredWithoutPlanBenefitsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutPlanBenefitsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutPlanBenefitsInput>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutPlanBenefitsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutPlanBenefitsInput>;
};

export type CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutCurrentPlanEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutCurrentPlanEnrollmentSupportEventsInput>;
};

export type CarePlanUpdateOneWithoutExplicitRenewalSuccessorsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutExplicitRenewalSuccessorsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutExplicitRenewalSuccessorsInput>;
};

export type CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutNewPlanEnrollmentSupportEventsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutNewPlanEnrollmentSupportEventsInput>;
};

export type CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutRenewalPredecessorPlanInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput>;
  upsert?: Maybe<CarePlanUpsertWithoutRenewalPredecessorPlanInput>;
};

export type CarePlanUpdateOneWithoutSelectedByRenewalEmailEnrollmentsNestedInput = {
  connect?: Maybe<CarePlanWhereUniqueInput>;
  connectOrCreate?: Maybe<CarePlanCreateOrConnectWithoutSelectedByRenewalEmailEnrollmentsInput>;
  create?: Maybe<CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput>;
  upsert?: Maybe<CarePlanUpsertWithoutSelectedByRenewalEmailEnrollmentsInput>;
};

export type CarePlanUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  data: CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpdateWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput = {
  data: CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CarePlanUncheckedUpdateWithoutOrganizationInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutCurrentPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutDefaultRenewalSuccessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutExplicitRenewalSuccessorsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutNewPlanEnrollmentSupportEventsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutOrganizationInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutPlanBenefitsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutRenewalPredecessorPlanInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutRenewalEmailSelectedTargetPlanNestedInput>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpdateWithoutSelectedByRenewalEmailEnrollmentsInput = {
  allowSelfRenewal?: Maybe<Scalars['Boolean']>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedPlansNestedInput>;
  billingCycle?: Maybe<CarePlanCycleType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanUpdateOneWithoutRenewalPredecessorPlanNestedInput>;
  description?: Maybe<Scalars['String']>;
  earliestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  editorNotes?: Maybe<Scalars['String']>;
  enrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutPlanNestedInput>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentUpdateManyWithoutExplicitRenewalSucccesorPlanNestedInput>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isAcceptingPreEnrollments?: Maybe<Scalars['Boolean']>;
  latestTargetPetAgeInMonths?: Maybe<Scalars['Int']>;
  marketingInformation?: Maybe<Scalars['Json']>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutCarePlansNestedInput>;
  planBenefits?: Maybe<CarePlanBenefitUpdateManyWithoutPlanNestedInput>;
  planDiscount?: Maybe<Scalars['Int']>;
  pricePerRenewal?: Maybe<Scalars['Int']>;
  projectedSavings?: Maybe<Scalars['String']>;
  publishEndDate?: Maybe<Scalars['DateTime']>;
  publishStartDate?: Maybe<Scalars['DateTime']>;
  published?: Maybe<Scalars['Boolean']>;
  renewalCycle?: Maybe<CarePlanCycleType>;
  renewalPredecessorPlan?: Maybe<CarePlanUpdateManyWithoutDefaultRenewalSuccessorPlanNestedInput>;
  salesTaxCentsPerMonth?: Maybe<Scalars['Int']>;
  signupFeePricing?: Maybe<Scalars['Int']>;
  signupFeeStripeProductId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  targetSpecies?: Maybe<SpeciesType>;
  themeColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CarePlanUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  create: CarePlanUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  update: CarePlanUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpsertWithWhereUniqueWithoutDefaultRenewalSuccessorPlanInput = {
  create: CarePlanUncheckedCreateWithoutDefaultRenewalSuccessorPlanInput;
  update: CarePlanUncheckedUpdateWithoutDefaultRenewalSuccessorPlanInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CarePlanUncheckedCreateWithoutOrganizationInput;
  update: CarePlanUncheckedUpdateWithoutOrganizationInput;
  where: CarePlanWhereUniqueInput;
};

export type CarePlanUpsertWithoutCurrentPlanEnrollmentSupportEventsInput = {
  create: CarePlanUncheckedCreateWithoutCurrentPlanEnrollmentSupportEventsInput;
  update: CarePlanUncheckedUpdateWithoutCurrentPlanEnrollmentSupportEventsInput;
};

export type CarePlanUpsertWithoutEnrollmentsInput = {
  create: CarePlanUncheckedCreateWithoutEnrollmentsInput;
  update: CarePlanUncheckedUpdateWithoutEnrollmentsInput;
};

export type CarePlanUpsertWithoutExplicitRenewalSuccessorsInput = {
  create: CarePlanUncheckedCreateWithoutExplicitRenewalSuccessorsInput;
  update: CarePlanUncheckedUpdateWithoutExplicitRenewalSuccessorsInput;
};

export type CarePlanUpsertWithoutNewPlanEnrollmentSupportEventsInput = {
  create: CarePlanUncheckedCreateWithoutNewPlanEnrollmentSupportEventsInput;
  update: CarePlanUncheckedUpdateWithoutNewPlanEnrollmentSupportEventsInput;
};

export type CarePlanUpsertWithoutPlanBenefitsInput = {
  create: CarePlanUncheckedCreateWithoutPlanBenefitsInput;
  update: CarePlanUncheckedUpdateWithoutPlanBenefitsInput;
};

export type CarePlanUpsertWithoutRenewalPredecessorPlanInput = {
  create: CarePlanUncheckedCreateWithoutRenewalPredecessorPlanInput;
  update: CarePlanUncheckedUpdateWithoutRenewalPredecessorPlanInput;
};

export type CarePlanUpsertWithoutSelectedByRenewalEmailEnrollmentsInput = {
  create: CarePlanUncheckedCreateWithoutSelectedByRenewalEmailEnrollmentsInput;
  update: CarePlanUncheckedUpdateWithoutSelectedByRenewalEmailEnrollmentsInput;
};

export type CarePlanWhereInput = {
  AND?: Maybe<Array<CarePlanWhereInput>>;
  NOT?: Maybe<Array<CarePlanWhereInput>>;
  OR?: Maybe<Array<CarePlanWhereInput>>;
  allowSelfRenewal?: Maybe<BoolFilter>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  billingCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  defaultRenewalSuccessorPlan?: Maybe<CarePlanWhereInput>;
  defaultRenewalSuccessorPlanId?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  earliestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  editorNotes?: Maybe<StringFilter>;
  enrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  explicitRenewalSuccessors?: Maybe<CarePlanEnrollmentListRelationFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isAcceptingPreEnrollments?: Maybe<BoolFilter>;
  latestTargetPetAgeInMonths?: Maybe<IntNullableFilter>;
  marketingInformation?: Maybe<JsonNullableFilter>;
  newPlanEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringFilter>;
  planBenefits?: Maybe<CarePlanBenefitListRelationFilter>;
  planDiscount?: Maybe<IntNullableFilter>;
  pricePerRenewal?: Maybe<IntFilter>;
  projectedSavings?: Maybe<StringNullableFilter>;
  publishEndDate?: Maybe<DateTimeNullableFilter>;
  publishStartDate?: Maybe<DateTimeNullableFilter>;
  published?: Maybe<BoolFilter>;
  renewalCycle?: Maybe<EnumCarePlanCycleTypeFilter>;
  renewalPredecessorPlan?: Maybe<CarePlanListRelationFilter>;
  salesTaxCentsPerMonth?: Maybe<IntFilter>;
  selectedByRenewalEmailEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  signupFeePricing?: Maybe<IntNullableFilter>;
  signupFeeStripeProductId?: Maybe<StringNullableFilter>;
  stripePriceId?: Maybe<StringNullableFilter>;
  targetSpecies?: Maybe<EnumSpeciesTypeNullableFilter>;
  themeColor?: Maybe<StringNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CarePlanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum CareProgramLaunchStatus {
  Archived = 'Archived',
  Churning = 'Churning',
  Draft = 'Draft',
  Live = 'Live',
  Prelaunch = 'Prelaunch'
}

export type CareStripeSubscription = {
  __typename?: 'CareStripeSubscription';
  _count: CareStripeSubscriptionCountOutputType;
  activeSubscriber?: Maybe<OrganizationPetParent>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPet?: Maybe<OrganizationPet>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments: Array<CareAddonEnrollment>;
  careBenefitUsageFollowups: Array<CareBenefitUsageFollowup>;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  invoices: Array<StripeInvoice>;
  isTestSubscription: Scalars['Boolean'];
  nextBillingDate: Scalars['DateTime'];
  /** If this subscription originated somewhere else, this allows us to track it */
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParent;
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type CareStripeSubscriptionCareAddonEnrollmentsArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type CareStripeSubscriptionCareBenefitUsageFollowupsArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageFollowupScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type CareStripeSubscriptionCarePlanEnrollmentsArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type CareStripeSubscriptionCareSubscriptionTrueUpsArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type CareStripeSubscriptionInvoicesArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};

export type CareStripeSubscriptionAvgAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionAvgAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Float']>;
};

export type CareStripeSubscriptionAvgOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionCountAggregateOutputType';
  _all: Scalars['Int'];
  associatedCarePlanProviderGroupId: Scalars['Int'];
  associatedPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isTestSubscription: Scalars['Int'];
  nextBillingDate: Scalars['Int'];
  parentCareStripeSubscriptionId: Scalars['Int'];
  paymentStatus: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  subscriptionOwnerId: Scalars['Int'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareStripeSubscriptionCountOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionCountOutputType = {
  __typename?: 'CareStripeSubscriptionCountOutputType';
  careAddonEnrollments: Scalars['Int'];
  careBenefitUsageFollowups: Scalars['Int'];
  carePlanEnrollments: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
  invoices: Scalars['Int'];
};

export type CareStripeSubscriptionCreateInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInput = {
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManyAssociatedPetInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyAssociatedPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManyInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope = {
  data: Array<CareStripeSubscriptionCreateManySubscriptionOwnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
};

export type CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareBenefitUsageFollowupsInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
};

export type CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
};

export type CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionCreateWithoutActiveSubscriberInput = {
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutAssociatedPetInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutCareAddonEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutCareBenefitUsageFollowupsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutCarePlanEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutCareSubscriptionTrueUpsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutInvoicesInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutStripePaymentMethodInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwner: OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput;
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput = {
  activeSubscriber?: Maybe<OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  associatedPet?: Maybe<OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput>;
  stripeSubscriptionId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionListRelationFilter = {
  every?: Maybe<CareStripeSubscriptionWhereInput>;
  none?: Maybe<CareStripeSubscriptionWhereInput>;
  some?: Maybe<CareStripeSubscriptionWhereInput>;
};

export type CareStripeSubscriptionMaxAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMaxAggregateOutputType';
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionMaxOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionMinAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionMinAggregateOutputType';
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionMinOrderByAggregateInput = {
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareStripeSubscriptionOrderByRelevanceFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  AssociatedPetId = 'associatedPetId',
  Id = 'id',
  ParentCareStripeSubscriptionId = 'parentCareStripeSubscriptionId',
  StripeCustomerId = 'stripeCustomerId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId'
}

export type CareStripeSubscriptionOrderByRelevanceInput = {
  fields: Array<CareStripeSubscriptionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareStripeSubscriptionOrderByWithAggregationInput = {
  _avg?: Maybe<CareStripeSubscriptionAvgOrderByAggregateInput>;
  _count?: Maybe<CareStripeSubscriptionCountOrderByAggregateInput>;
  _max?: Maybe<CareStripeSubscriptionMaxOrderByAggregateInput>;
  _min?: Maybe<CareStripeSubscriptionMinOrderByAggregateInput>;
  _sum?: Maybe<CareStripeSubscriptionSumOrderByAggregateInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isTestSubscription?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareStripeSubscriptionOrderByRelevanceInput>;
  activeSubscriber?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  associatedCarePlanProviderGroupId?: Maybe<SortOrder>;
  associatedPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  associatedPetId?: Maybe<SortOrder>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupOrderByRelationAggregateInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  isTestSubscription?: Maybe<SortOrder>;
  nextBillingDate?: Maybe<SortOrder>;
  parentCareStripeSubscriptionId?: Maybe<SortOrder>;
  paymentStatus?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  subscriptionOwner?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  subscriptionOwnerId?: Maybe<SortOrder>;
  upcomingTotalPrice?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionRelationFilter = {
  is?: Maybe<CareStripeSubscriptionWhereInput>;
  isNot?: Maybe<CareStripeSubscriptionWhereInput>;
};

export enum CareStripeSubscriptionScalarFieldEnum {
  AssociatedCarePlanProviderGroupId = 'associatedCarePlanProviderGroupId',
  AssociatedPetId = 'associatedPetId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsTestSubscription = 'isTestSubscription',
  NextBillingDate = 'nextBillingDate',
  ParentCareStripeSubscriptionId = 'parentCareStripeSubscriptionId',
  PaymentStatus = 'paymentStatus',
  StripeCustomerId = 'stripeCustomerId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  SubscriptionOwnerId = 'subscriptionOwnerId',
  UpcomingTotalPrice = 'upcomingTotalPrice',
  UpdatedAt = 'updatedAt'
}

export type CareStripeSubscriptionScalarWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  associatedPetId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isTestSubscription?: Maybe<BoolFilter>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareStripeSubscriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionScalarWhereWithAggregatesInput>>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  associatedPetId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isTestSubscription?: Maybe<BoolWithAggregatesFilter>;
  nextBillingDate?: Maybe<DateTimeWithAggregatesFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  subscriptionOwnerId?: Maybe<StringWithAggregatesFilter>;
  upcomingTotalPrice?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareStripeSubscriptionSumAggregateOutputType = {
  __typename?: 'CareStripeSubscriptionSumAggregateOutputType';
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
};

export type CareStripeSubscriptionSumOrderByAggregateInput = {
  upcomingTotalPrice?: Maybe<SortOrder>;
};

export type CareStripeSubscriptionUncheckedCreateInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedCarePlanProviderGroupInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
};

export type CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  subscriptionOwnerId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate: Scalars['DateTime'];
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId: Scalars['String'];
  upcomingTotalPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput = {
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwnerId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroupId?: Maybe<Scalars['String']>;
  associatedPetId?: Maybe<Scalars['String']>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput;
  where: CareStripeSubscriptionScalarWhereInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput = {
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedSubscriptionsInput;
  where: CareStripeSubscriptionScalarWhereInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutCareStripeSubscriptionsInput;
  where: CareStripeSubscriptionScalarWhereInput;
};

export type CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput = {
  data: CareStripeSubscriptionUncheckedUpdateManyWithoutAllCareStripeSubscriptionsInput;
  where: CareStripeSubscriptionScalarWhereInput;
};

export type CareStripeSubscriptionUpdateManyWithoutAssociatedCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedCarePlanProviderGroupInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedCarePlanProviderGroupInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutAssociatedPetInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutAssociatedPetInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyAssociatedPetInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutAssociatedPetInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput = {
  connect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareStripeSubscriptionCreateOrConnectWithoutSubscriptionOwnerInput>>;
  create?: Maybe<Array<CareStripeSubscriptionCreateWithoutSubscriptionOwnerInput>>;
  createMany?: Maybe<CareStripeSubscriptionCreateManySubscriptionOwnerInputEnvelope>;
  delete?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareStripeSubscriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  set?: Maybe<Array<CareStripeSubscriptionWhereUniqueInput>>;
  update?: Maybe<Array<CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput>>;
  updateMany?: Maybe<Array<CareStripeSubscriptionUpdateManyWithWhereWithoutSubscriptionOwnerInput>>;
  upsert?: Maybe<Array<CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput>>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutCareBenefitUsageFollowupsNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareBenefitUsageFollowupsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareBenefitUsageFollowupsInput>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareSubscriptionTrueUpsInput>;
};

export type CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutInvoicesInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutActiveSubscriberInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutActiveSubscriberInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCareAddonEnrollmentsNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateOneWithoutCarePlanEnrollmentsNestedInput = {
  connect?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  connectOrCreate?: Maybe<CareStripeSubscriptionCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  create?: Maybe<CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput>;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  data: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutAssociatedPetInput = {
  data: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  data: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpdateWithWhereUniqueWithoutSubscriptionOwnerInput = {
  data: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpdateWithoutActiveSubscriberInput = {
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutAssociatedCarePlanProviderGroupInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutAssociatedPetInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutCareAddonEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutCareBenefitUsageFollowupsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutCarePlanEnrollmentsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutCareSubscriptionTrueUpsInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutInvoicesInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutStripePaymentMethodInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionOwner?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpdateWithoutSubscriptionOwnerInput = {
  activeSubscriber?: Maybe<OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  associatedPet?: Maybe<OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput>;
  isTestSubscription?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  parentCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<CareSubscriptionPaymentStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  upcomingTotalPrice?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedCarePlanProviderGroupInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedCarePlanProviderGroupInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedCarePlanProviderGroupInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutAssociatedPetInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutAssociatedPetInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutAssociatedPetInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutStripePaymentMethodInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutStripePaymentMethodInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpsertWithWhereUniqueWithoutSubscriptionOwnerInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutSubscriptionOwnerInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutSubscriptionOwnerInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};

export type CareStripeSubscriptionUpsertWithoutActiveSubscriberInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutActiveSubscriberInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutActiveSubscriberInput;
};

export type CareStripeSubscriptionUpsertWithoutCareAddonEnrollmentsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareAddonEnrollmentsInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareAddonEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutCareBenefitUsageFollowupsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareBenefitUsageFollowupsInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareBenefitUsageFollowupsInput;
};

export type CareStripeSubscriptionUpsertWithoutCarePlanEnrollmentsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCarePlanEnrollmentsInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutCarePlanEnrollmentsInput;
};

export type CareStripeSubscriptionUpsertWithoutCareSubscriptionTrueUpsInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
};

export type CareStripeSubscriptionUpsertWithoutInvoicesInput = {
  create: CareStripeSubscriptionUncheckedCreateWithoutInvoicesInput;
  update: CareStripeSubscriptionUncheckedUpdateWithoutInvoicesInput;
};

export type CareStripeSubscriptionWhereInput = {
  AND?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  OR?: Maybe<Array<CareStripeSubscriptionWhereInput>>;
  activeSubscriber?: Maybe<OrganizationPetParentWhereInput>;
  associatedCarePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  associatedCarePlanProviderGroupId?: Maybe<StringNullableFilter>;
  associatedPet?: Maybe<OrganizationPetWhereInput>;
  associatedPetId?: Maybe<StringNullableFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  careBenefitUsageFollowups?: Maybe<CareBenefitUsageFollowupListRelationFilter>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
  isTestSubscription?: Maybe<BoolFilter>;
  nextBillingDate?: Maybe<DateTimeFilter>;
  parentCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  paymentStatus?: Maybe<EnumCareSubscriptionPaymentStatusNullableFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringFilter>;
  subscriptionOwner?: Maybe<OrganizationPetParentWhereInput>;
  subscriptionOwnerId?: Maybe<StringFilter>;
  upcomingTotalPrice?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareStripeSubscriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
};

export enum CareSubscriptionPaymentStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  PastDue = 'PastDue'
}

export type CareSubscriptionTrueUp = {
  __typename?: 'CareSubscriptionTrueUp';
  _count: CareSubscriptionTrueUpCountOutputType;
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollment;
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscription: CareStripeSubscription;
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  dueAt: Scalars['DateTime'];
  id: Scalars['String'];
  logEntries: Array<CareSubscriptionTrueUpLogEntry>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment: Scalars['Boolean'];
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type: CareSubscriptionTrueUpType;
  updatedAt: Scalars['DateTime'];
};


export type CareSubscriptionTrueUpLogEntriesArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  distinct?: Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>;
  orderBy?: Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};

export type CareSubscriptionTrueUpAvgAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type CareSubscriptionTrueUpAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpCountAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  completedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  dueAt: Scalars['Int'];
  id: Scalars['Int'];
  retryAt: Scalars['Int'];
  shouldCancelEnrollment: Scalars['Int'];
  slackMessageTimestamp: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareSubscriptionTrueUpCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpCountOutputType = {
  __typename?: 'CareSubscriptionTrueUpCountOutputType';
  logEntries: Scalars['Int'];
};

export type CareSubscriptionTrueUpCreateInput = {
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyStripeInvoiceInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateManyStripePaymentMethodInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<CareSubscriptionTrueUpCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
};

export type CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
};

export type CareSubscriptionTrueUpCreateNestedOneWithoutLogEntriesInput = {
  connect?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  connectOrCreate?: Maybe<CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput>;
  create?: Maybe<CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput>;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateWithoutLogEntriesInput = {
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput = {
  amount: Scalars['Int'];
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutCareSubscriptionTrueUpsInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpListRelationFilter = {
  every?: Maybe<CareSubscriptionTrueUpWhereInput>;
  none?: Maybe<CareSubscriptionTrueUpWhereInput>;
  some?: Maybe<CareSubscriptionTrueUpWhereInput>;
};

export type CareSubscriptionTrueUpLogEntry = {
  __typename?: 'CareSubscriptionTrueUpLogEntry';
  careSubscriptionTrueUp: CareSubscriptionTrueUp;
  careSubscriptionTrueUpId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  httpCode?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt: Scalars['Int'];
  status: CareSubscriptionTrueUpStatus;
  updatedAt: Scalars['DateTime'];
};

export type CareSubscriptionTrueUpLogEntryAvgAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryAvgAggregateOutputType';
  httpCode?: Maybe<Scalars['Float']>;
  retryAttempt?: Maybe<Scalars['Float']>;
};

export type CareSubscriptionTrueUpLogEntryAvgOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryCountAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  careSubscriptionTrueUpId: Scalars['Int'];
  createdAt: Scalars['Int'];
  httpCode: Scalars['Int'];
  id: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  retryAttempt: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CareSubscriptionTrueUpLogEntryCountOrderByAggregateInput = {
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryCreateInput = {
  careSubscriptionTrueUp: CareSubscriptionTrueUpCreateNestedOneWithoutLogEntriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope = {
  data: Array<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CareSubscriptionTrueUpLogEntryCreateManyInput = {
  careSubscriptionTrueUpId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryCreateNestedManyWithoutCareSubscriptionTrueUpInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
};

export type CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput = {
  create: CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};

export type CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryListRelationFilter = {
  every?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  none?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
  some?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};

export type CareSubscriptionTrueUpLogEntryMaxAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryMaxAggregateOutputType';
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryMaxOrderByAggregateInput = {
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryMinAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntryMinAggregateOutputType';
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryMinOrderByAggregateInput = {
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpLogEntryOrderByRelevanceFieldEnum {
  CareSubscriptionTrueUpId = 'careSubscriptionTrueUpId',
  Id = 'id'
}

export type CareSubscriptionTrueUpLogEntryOrderByRelevanceInput = {
  fields: Array<CareSubscriptionTrueUpLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareSubscriptionTrueUpLogEntryOrderByWithAggregationInput = {
  _avg?: Maybe<CareSubscriptionTrueUpLogEntryAvgOrderByAggregateInput>;
  _count?: Maybe<CareSubscriptionTrueUpLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<CareSubscriptionTrueUpLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CareSubscriptionTrueUpLogEntryMinOrderByAggregateInput>;
  _sum?: Maybe<CareSubscriptionTrueUpLogEntrySumOrderByAggregateInput>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareSubscriptionTrueUpLogEntryOrderByRelevanceInput>;
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  careSubscriptionTrueUpId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpLogEntryScalarFieldEnum {
  CareSubscriptionTrueUpId = 'careSubscriptionTrueUpId',
  CreatedAt = 'createdAt',
  HttpCode = 'httpCode',
  Id = 'id',
  LastPaymentError = 'lastPaymentError',
  RetryAttempt = 'retryAttempt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CareSubscriptionTrueUpLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  careSubscriptionTrueUpId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  retryAttempt?: Maybe<IntFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereWithAggregatesInput>>;
  careSubscriptionTrueUpId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  retryAttempt?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CareSubscriptionTrueUpLogEntrySumAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpLogEntrySumAggregateOutputType';
  httpCode?: Maybe<Scalars['Int']>;
  retryAttempt?: Maybe<Scalars['Int']>;
};

export type CareSubscriptionTrueUpLogEntrySumOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
  retryAttempt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateInput = {
  careSubscriptionTrueUpId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateInput = {
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyInput = {
  careSubscriptionTrueUpId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutLogEntriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUpdateInput = {
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpUpdateOneRequiredWithoutLogEntriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput = {
  data: CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutLogEntriesInput;
  where: CareSubscriptionTrueUpLogEntryScalarWhereInput;
};

export type CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateOrConnectWithoutCareSubscriptionTrueUpInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpLogEntryCreateWithoutCareSubscriptionTrueUpInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpLogEntryCreateManyCareSubscriptionTrueUpInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpdateManyWithWhereWithoutCareSubscriptionTrueUpInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput>>;
};

export type CareSubscriptionTrueUpLogEntryUpdateWithWhereUniqueWithoutCareSubscriptionTrueUpInput = {
  data: CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};

export type CareSubscriptionTrueUpLogEntryUpdateWithoutCareSubscriptionTrueUpInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  retryAttempt?: Maybe<Scalars['Int']>;
  status?: Maybe<CareSubscriptionTrueUpStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpLogEntryUpsertWithWhereUniqueWithoutCareSubscriptionTrueUpInput = {
  create: CareSubscriptionTrueUpLogEntryUncheckedCreateWithoutCareSubscriptionTrueUpInput;
  update: CareSubscriptionTrueUpLogEntryUncheckedUpdateWithoutCareSubscriptionTrueUpInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};

export type CareSubscriptionTrueUpLogEntryWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpLogEntryWhereInput>>;
  careSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUpWhereInput>;
  careSubscriptionTrueUpId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  retryAttempt?: Maybe<IntFilter>;
  status?: Maybe<EnumCareSubscriptionTrueUpStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareSubscriptionTrueUpLogEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CareSubscriptionTrueUpMaxAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpMinAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpOrderByRelevanceFieldEnum {
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  Id = 'id',
  SlackMessageTimestamp = 'slackMessageTimestamp',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentMethodId = 'stripePaymentMethodId'
}

export type CareSubscriptionTrueUpOrderByRelevanceInput = {
  fields: Array<CareSubscriptionTrueUpOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CareSubscriptionTrueUpOrderByWithAggregationInput = {
  _avg?: Maybe<CareSubscriptionTrueUpAvgOrderByAggregateInput>;
  _count?: Maybe<CareSubscriptionTrueUpCountOrderByAggregateInput>;
  _max?: Maybe<CareSubscriptionTrueUpMaxOrderByAggregateInput>;
  _min?: Maybe<CareSubscriptionTrueUpMinOrderByAggregateInput>;
  _sum?: Maybe<CareSubscriptionTrueUpSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  retryAt?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CareSubscriptionTrueUpOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryOrderByRelationAggregateInput>;
  retryAt?: Maybe<SortOrder>;
  shouldCancelEnrollment?: Maybe<SortOrder>;
  slackMessageTimestamp?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CareSubscriptionTrueUpRelationFilter = {
  is?: Maybe<CareSubscriptionTrueUpWhereInput>;
  isNot?: Maybe<CareSubscriptionTrueUpWhereInput>;
};

export enum CareSubscriptionTrueUpScalarFieldEnum {
  Amount = 'amount',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DueAt = 'dueAt',
  Id = 'id',
  RetryAt = 'retryAt',
  ShouldCancelEnrollment = 'shouldCancelEnrollment',
  SlackMessageTimestamp = 'slackMessageTimestamp',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CareSubscriptionTrueUpScalarWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  dueAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  retryAt?: Maybe<DateTimeNullableFilter>;
  shouldCancelEnrollment?: Maybe<BoolFilter>;
  slackMessageTimestamp?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareSubscriptionTrueUpScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  dueAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  retryAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  shouldCancelEnrollment?: Maybe<BoolWithAggregatesFilter>;
  slackMessageTimestamp?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum CareSubscriptionTrueUpStatus {
  Failure = 'Failure',
  Pending = 'Pending',
  PendingRetryAutomatic = 'Pending_RetryAutomatic',
  Success = 'Success'
}

export type CareSubscriptionTrueUpSubscriptionEnrollmentAndDueAtCompoundUniqueInput = {
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  dueAt: Scalars['DateTime'];
};

export type CareSubscriptionTrueUpSumAggregateOutputType = {
  __typename?: 'CareSubscriptionTrueUpSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type CareSubscriptionTrueUpSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export enum CareSubscriptionTrueUpType {
  ClinicAlternativePayment = 'ClinicAlternativePayment',
  ClinicCollections = 'ClinicCollections',
  Internal = 'Internal'
}

export type CareSubscriptionTrueUpUncheckedCreateInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
};

export type CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput = {
  amount: Scalars['Int'];
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedCreateNestedManyWithoutCareSubscriptionTrueUpInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUncheckedUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
  where: CareSubscriptionTrueUpScalarWhereInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
  where: CareSubscriptionTrueUpScalarWhereInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
  where: CareSubscriptionTrueUpScalarWhereInput;
};

export type CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateManyWithoutCareSubscriptionTrueUpsInput;
  where: CareSubscriptionTrueUpScalarWhereInput;
};

export type CareSubscriptionTrueUpUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CareSubscriptionTrueUpCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<CareSubscriptionTrueUpCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<CareSubscriptionTrueUpCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CareSubscriptionTrueUpScalarWhereInput>>;
  disconnect?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  set?: Maybe<Array<CareSubscriptionTrueUpWhereUniqueInput>>;
  update?: Maybe<Array<CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<CareSubscriptionTrueUpUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type CareSubscriptionTrueUpUpdateOneRequiredWithoutLogEntriesNestedInput = {
  connect?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  connectOrCreate?: Maybe<CareSubscriptionTrueUpCreateOrConnectWithoutLogEntriesInput>;
  create?: Maybe<CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput>;
  update?: Maybe<CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput>;
  upsert?: Maybe<CareSubscriptionTrueUpUpsertWithoutLogEntriesInput>;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  data: CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateWithoutCareStripeSubscriptionInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateWithoutLogEntriesInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpdateWithoutStripePaymentMethodInput = {
  amount?: Maybe<Scalars['Int']>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutCareSubscriptionTrueUpsNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryUpdateManyWithoutCareSubscriptionTrueUpNestedInput>;
  retryAt?: Maybe<Scalars['DateTime']>;
  shouldCancelEnrollment?: Maybe<Scalars['Boolean']>;
  slackMessageTimestamp?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput>;
  type?: Maybe<CareSubscriptionTrueUpType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCarePlanEnrollmentInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutCareStripeSubscriptionInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripeInvoiceInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutStripeInvoiceInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutStripePaymentMethodInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutStripePaymentMethodInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};

export type CareSubscriptionTrueUpUpsertWithoutLogEntriesInput = {
  create: CareSubscriptionTrueUpUncheckedCreateWithoutLogEntriesInput;
  update: CareSubscriptionTrueUpUncheckedUpdateWithoutLogEntriesInput;
};

export type CareSubscriptionTrueUpWhereInput = {
  AND?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  NOT?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  OR?: Maybe<Array<CareSubscriptionTrueUpWhereInput>>;
  amount?: Maybe<IntFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  dueAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  logEntries?: Maybe<CareSubscriptionTrueUpLogEntryListRelationFilter>;
  retryAt?: Maybe<DateTimeNullableFilter>;
  shouldCancelEnrollment?: Maybe<BoolFilter>;
  slackMessageTimestamp?: Maybe<StringNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumCareSubscriptionTrueUpTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CareSubscriptionTrueUpWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  subscriptionEnrollmentAndDueAt?: Maybe<CareSubscriptionTrueUpSubscriptionEnrollmentAndDueAtCompoundUniqueInput>;
};

export type ChangeCareSubscriptionPaymentMethodInput = {
  careStripePaymentMethodId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
};

export type ChangeCareSubscriptionPaymentMethodOutput = {
  __typename?: 'ChangeCareSubscriptionPaymentMethodOutput';
  success: Scalars['Boolean'];
};

export type Channel = {
  __typename?: 'Channel';
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: ChannelCountOutputType;
  appointments: Array<Appointment>;
  assignees: Array<User>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses: Array<ChannelAutomationStatus>;
  channelMembers: Array<ChannelMember>;
  channelPins: Array<ChannelPin>;
  channelStatus?: Maybe<ChannelStatus>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creationSource?: Maybe<ChannelCreationSource>;
  currentWorkflowAction?: Maybe<WorkflowEventAction>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions: Array<FinancialTransaction>;
  friendlyName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessage>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages: Array<ChannelMessage>;
  pets: Array<ClinicPet>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ChannelHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type ChannelAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ChannelAssigneesArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type ChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type ChannelChannelMembersArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type ChannelChannelPinsArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  distinct?: Maybe<ChannelPinScalarFieldEnum>;
  orderBy?: Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type ChannelFinancialTransactionsArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type ChannelMessagesArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type ChannelPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};

export type ChannelAutomationStatus = {
  __typename?: 'ChannelAutomationStatus';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: Channel;
  channelId: Scalars['String'];
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currentWorkflowEventAction?: Maybe<WorkflowEventAction>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  serviceReminder?: Maybe<ServiceReminder>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: AutomationStatus;
  updatedAt: Scalars['DateTime'];
  workflowEventSetting: WorkflowEventSetting;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCountAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  completedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentWorkflowEventActionId: Scalars['Int'];
  expiredAt: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  id: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  startedAt: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
};

export type ChannelAutomationStatusCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusCreateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateManyAppointmentInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyAppointmentInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyChannelInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyChannelInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyClinicPetInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyFormSubmissionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyServiceReminderInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelAutomationStatusCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
};

export type ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
};

export type ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutChannelInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusCreateWithoutAppointmentInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutChannelInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutClinicPetInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutFormSubmissionInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutServiceReminderInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput;
};

export type ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutChannelAutomationStatusesInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel: ChannelCreateNestedOneWithoutAutomationStatusesInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusListRelationFilter = {
  every?: Maybe<ChannelAutomationStatusWhereInput>;
  none?: Maybe<ChannelAutomationStatusWhereInput>;
  some?: Maybe<ChannelAutomationStatusWhereInput>;
};

export type ChannelAutomationStatusMaxAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusMinAggregateOutputType = {
  __typename?: 'ChannelAutomationStatusMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelAutomationStatusOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  ChannelId = 'channelId',
  ClinicPetId = 'clinicPetId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type ChannelAutomationStatusOrderByRelevanceInput = {
  fields: Array<ChannelAutomationStatusOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelAutomationStatusOrderByWithAggregationInput = {
  _count?: Maybe<ChannelAutomationStatusCountOrderByAggregateInput>;
  _max?: Maybe<ChannelAutomationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelAutomationStatusMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelAutomationStatusOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  currentWorkflowEventActionId?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminderId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export enum ChannelAutomationStatusScalarFieldEnum {
  AppointmentId = 'appointmentId',
  CancelledAt = 'cancelledAt',
  ChannelId = 'channelId',
  ClinicPetId = 'clinicPetId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  CurrentWorkflowEventActionId = 'currentWorkflowEventActionId',
  ExpiredAt = 'expiredAt',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  StartedAt = 'startedAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type ChannelAutomationStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  channelId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
};

export type ChannelAutomationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentWorkflowEventActionId?: Maybe<StringNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumAutomationStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelAutomationStatusUncheckedCreateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
};

export type ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutChannelInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId: Scalars['String'];
};

export type ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutChannelInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput = {
  appointmentId?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventActionId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateManyMutationInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  data: ChannelAutomationStatusUncheckedUpdateManyWithoutChannelAutomationStatusesInput;
  where: ChannelAutomationStatusScalarWhereInput;
};

export type ChannelAutomationStatusUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutAppointmentInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutCurrentWorkflowEventActionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutCurrentWorkflowEventActionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyCurrentWorkflowEventActionInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutCurrentWorkflowEventActionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelAutomationStatusCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelAutomationStatusCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelAutomationStatusCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelAutomationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelAutomationStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelAutomationStatusUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutChannelInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutServiceReminderInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  data: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpdateWithoutAppointmentInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutChannelInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicPetInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutClinicWidgetRequestInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutCurrentWorkflowEventActionInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutFormSubmissionInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutServiceReminderInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput>;
};

export type ChannelAutomationStatusUpdateWithoutWorkflowEventSettingInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  channel?: Maybe<ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AutomationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutAppointmentInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutAppointmentInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutChannelInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutChannelInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutChannelInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicPetInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicPetInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutClinicWidgetRequestInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutCurrentWorkflowEventActionInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutCurrentWorkflowEventActionInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutCurrentWorkflowEventActionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutFormSubmissionInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutFormSubmissionInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutServiceReminderInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutServiceReminderInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutServiceReminderInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  create: ChannelAutomationStatusUncheckedCreateWithoutWorkflowEventSettingInput;
  update: ChannelAutomationStatusUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};

export type ChannelAutomationStatusWhereInput = {
  AND?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  OR?: Maybe<Array<ChannelAutomationStatusWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentWorkflowEventAction?: Maybe<WorkflowEventActionWhereInput>;
  currentWorkflowEventActionId?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumAutomationStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  workflowEventSettingId?: Maybe<StringFilter>;
};

export type ChannelAutomationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelCountAggregateOutputType = {
  __typename?: 'ChannelCountAggregateOutputType';
  _all: Scalars['Int'];
  attributes: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  channelType: Scalars['Int'];
  channelVisibility: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentWorkflowActionId: Scalars['Int'];
  friendlyName: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  lastExportAt: Scalars['Int'];
  lastMessageId: Scalars['Int'];
  tags: Scalars['Int'];
  twilioChannelSid: Scalars['Int'];
  uniqueName: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelCountOrderByAggregateInput = {
  attributes?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelCountOutputType = {
  __typename?: 'ChannelCountOutputType';
  HillsToHomeAPILog: Scalars['Int'];
  appointments: Scalars['Int'];
  assignees: Scalars['Int'];
  automationStatuses: Scalars['Int'];
  channelMembers: Scalars['Int'];
  channelPins: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  messages: Scalars['Int'];
  pets: Scalars['Int'];
};

export type ChannelCountResult = {
  __typename?: 'ChannelCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyChannelStatusInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyClinicInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyClinicInputEnvelope = {
  data: Array<ChannelCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyCurrentWorkflowActionInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyCurrentWorkflowActionInputEnvelope = {
  data: Array<ChannelCreateManyCurrentWorkflowActionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyLastMessageInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateManyLastMessageInputEnvelope = {
  data: Array<ChannelCreateManyLastMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
};

export type ChannelCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
};

export type ChannelCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
};

export type ChannelCreateNestedManyWithoutCurrentWorkflowActionInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
};

export type ChannelCreateNestedManyWithoutLastMessageInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
};

export type ChannelCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
};

export type ChannelCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
};

export type ChannelCreateNestedOneWithoutAutomationStatusesInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
};

export type ChannelCreateNestedOneWithoutChannelMembersInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
};

export type ChannelCreateNestedOneWithoutChannelPinsInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelPinsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutChannelPinsInput>;
};

export type ChannelCreateNestedOneWithoutCreationSourceInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
};

export type ChannelCreateNestedOneWithoutFinancialTransactionsInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
};

export type ChannelCreateNestedOneWithoutHillsToHomeApiLogInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutHillsToHomeApiLogInput>;
};

export type ChannelCreateNestedOneWithoutMessagesInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
};

export type ChannelCreateOrConnectWithoutAppointmentsInput = {
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutAssigneesInput = {
  create: ChannelUncheckedCreateWithoutAssigneesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutAutomationStatusesInput = {
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutChannelMembersInput = {
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutChannelPinsInput = {
  create: ChannelUncheckedCreateWithoutChannelPinsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutChannelStatusInput = {
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutClinicInput = {
  create: ChannelUncheckedCreateWithoutClinicInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutCreationSourceInput = {
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutCurrentWorkflowActionInput = {
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutFinancialTransactionsInput = {
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutHillsToHomeApiLogInput = {
  create: ChannelUncheckedCreateWithoutHillsToHomeApiLogInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutLastMessageInput = {
  create: ChannelUncheckedCreateWithoutLastMessageInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutMessagesInput = {
  create: ChannelUncheckedCreateWithoutMessagesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutPetsInput = {
  create: ChannelUncheckedCreateWithoutPetsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutAssigneesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutChannelMembersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutChannelPinsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutChannelStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutCreationSourceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutCurrentWorkflowActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutFinancialTransactionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutHillsToHomeApiLogInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutLastMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutMessagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutPetsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinCreateNestedManyWithoutChannelInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutChannelsInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutChannelsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceCreateNestedOneWithoutChannelInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionCreateNestedOneWithoutChannelsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageCreateNestedOneWithoutLastMessageChannelInput>;
  messages?: Maybe<ChannelMessageCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelCreationSource = {
  __typename?: 'ChannelCreationSource';
  channel: Channel;
  channelId: Scalars['String'];
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creationSource: ChannelCreationSourceType;
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  serviceReminder?: Maybe<ServiceReminder>;
  serviceReminderId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCountAggregateOutputType = {
  __typename?: 'ChannelCreationSourceCountAggregateOutputType';
  _all: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  createdAt: Scalars['Int'];
  creationSource: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  id: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  userId: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
};

export type ChannelCreationSourceCountOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceCreateInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInput = {
  channelId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyFormSubmissionInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyServiceReminderInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyUserInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyUserInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<ChannelCreationSourceCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
};

export type ChannelCreationSourceCreateNestedOneWithoutChannelInput = {
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
};

export type ChannelCreationSourceCreateOrConnectWithoutChannelInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutUserInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceCreateWithoutChannelInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicPetParentInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutClinicWidgetRequestInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutFormSubmissionInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutServiceReminderInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutUserInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceCreateWithoutWorkflowEventSettingInput = {
  channel: ChannelCreateNestedOneWithoutCreationSourceInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput>;
  user?: Maybe<UserCreateNestedOneWithoutChannelCreationSourcesInput>;
};

export type ChannelCreationSourceInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  creationSource: ChannelCreationSourceType;
  formSubmissionId?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceListRelationFilter = {
  every?: Maybe<ChannelCreationSourceWhereInput>;
  none?: Maybe<ChannelCreationSourceWhereInput>;
  some?: Maybe<ChannelCreationSourceWhereInput>;
};

export type ChannelCreationSourceMaxAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMaxAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMaxOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceMinAggregateOutputType = {
  __typename?: 'ChannelCreationSourceMinAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceMinOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelCreationSourceOrderByRelevanceFieldEnum {
  ChannelId = 'channelId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  UserId = 'userId',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type ChannelCreationSourceOrderByRelevanceInput = {
  fields: Array<ChannelCreationSourceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelCreationSourceOrderByWithAggregationInput = {
  _count?: Maybe<ChannelCreationSourceCountOrderByAggregateInput>;
  _max?: Maybe<ChannelCreationSourceMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelCreationSourceMinOrderByAggregateInput>;
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelCreationSourceOrderByRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminderId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type ChannelCreationSourceRelationFilter = {
  is?: Maybe<ChannelCreationSourceWhereInput>;
  isNot?: Maybe<ChannelCreationSourceWhereInput>;
};

export enum ChannelCreationSourceScalarFieldEnum {
  ChannelId = 'channelId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  CreatedAt = 'createdAt',
  CreationSource = 'creationSource',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  ServiceReminderId = 'serviceReminderId',
  UserId = 'userId',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type ChannelCreationSourceScalarWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  channelId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
};

export type ChannelCreationSourceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelCreationSourceScalarWhereWithAggregatesInput>>;
  channelId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringNullableWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum ChannelCreationSourceType {
  Automation = 'Automation',
  CallDeflection = 'CallDeflection',
  ClientInitiated = 'ClientInitiated',
  ClinicInitiated = 'ClinicInitiated',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ServiceReminder = 'ServiceReminder',
  SystemMessage = 'SystemMessage'
}

export type ChannelCreationSourceUncheckedCreateInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
};

export type ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput = {
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
};

export type ChannelCreationSourceUncheckedCreateWithoutChannelInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput = {
  channelId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutUserInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput = {
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutChannelInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutUserInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUpdateInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutUserInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  data: ChannelCreationSourceUncheckedUpdateManyWithoutChannelCreationSourcesInput;
  where: ChannelCreationSourceScalarWhereInput;
};

export type ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreationSourceCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<ChannelCreationSourceCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<ChannelCreationSourceCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelCreationSourceScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  set?: Maybe<Array<ChannelCreationSourceWhereUniqueInput>>;
  update?: Maybe<Array<ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<ChannelCreationSourceUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type ChannelCreationSourceUpdateOneWithoutChannelNestedInput = {
  connect?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreationSourceCreateOrConnectWithoutChannelInput>;
  create?: Maybe<ChannelCreationSourceUncheckedCreateWithoutChannelInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelCreationSourceUncheckedUpdateWithoutChannelInput>;
  upsert?: Maybe<ChannelCreationSourceUpsertWithoutChannelInput>;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutServiceReminderInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutUserInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  data: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpdateWithoutChannelInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicPetParentInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutClinicWidgetRequestInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutFormSubmissionInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutServiceReminderInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutUserInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpdateWithoutWorkflowEventSettingInput = {
  channel?: Maybe<ChannelUpdateOneRequiredWithoutCreationSourceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceType>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput>;
  id?: Maybe<Scalars['String']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutChannelCreationSourcesNestedInput>;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutClinicPetParentInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicPetParentInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutClinicWidgetRequestInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutFormSubmissionInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutFormSubmissionInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutServiceReminderInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutServiceReminderInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutServiceReminderInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutUserInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutUserInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutUserInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutWorkflowEventSettingInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: ChannelCreationSourceWhereUniqueInput;
};

export type ChannelCreationSourceUpsertWithoutChannelInput = {
  create: ChannelCreationSourceUncheckedCreateWithoutChannelInput;
  update: ChannelCreationSourceUncheckedUpdateWithoutChannelInput;
};

export type ChannelCreationSourceWhereInput = {
  AND?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  NOT?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  OR?: Maybe<Array<ChannelCreationSourceWhereInput>>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumChannelCreationSourceTypeFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  serviceReminderId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
};

export type ChannelCreationSourceWhereUniqueInput = {
  channelId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelExportWhereInput = {
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  includeNotes?: Maybe<Scalars['Boolean']>;
};

export type ChannelFilterSelection = {
  __typename?: 'ChannelFilterSelection';
  _count: ChannelFilterSelectionCountOutputType;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
};


export type ChannelFilterSelectionUserChannelFilterSelectionsArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type ChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  filterSelectionType: Scalars['Int'];
  filters: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelFilterSelectionCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionCountOutputType = {
  __typename?: 'ChannelFilterSelectionCountOutputType';
  userChannelFilterSelections: Scalars['Int'];
};

export type ChannelFilterSelectionCreateInput = {
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionCreateManyClinicInputEnvelope = {
  data: Array<ChannelFilterSelectionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelFilterSelectionCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
};

export type ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
};

export type ChannelFilterSelectionCreateOrConnectWithoutClinicInput = {
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type ChannelFilterSelectionCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionCreateWithoutUserChannelFilterSelectionsInput = {
  clinic: ClinicCreateNestedOneWithoutChannelFilterSelectionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionListRelationFilter = {
  every?: Maybe<ChannelFilterSelectionWhereInput>;
  none?: Maybe<ChannelFilterSelectionWhereInput>;
  some?: Maybe<ChannelFilterSelectionWhereInput>;
};

export type ChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'ChannelFilterSelectionMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelFilterSelectionOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  Name = 'name'
}

export type ChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<ChannelFilterSelectionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelFilterSelectionOrderByWithAggregationInput = {
  _count?: Maybe<ChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelFilterSelectionMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelFilterSelectionOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  filterSelectionType?: Maybe<SortOrder>;
  filters?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
};

export type ChannelFilterSelectionRelationFilter = {
  is?: Maybe<ChannelFilterSelectionWhereInput>;
  isNot?: Maybe<ChannelFilterSelectionWhereInput>;
};

export enum ChannelFilterSelectionScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  FilterSelectionType = 'filterSelectionType',
  Filters = 'filters',
  Id = 'id',
  IsDeleted = 'isDeleted',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
  filters?: Maybe<JsonFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeWithAggregatesFilter>;
  filters?: Maybe<JsonWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChannelFilterSelectionUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput>;
};

export type ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput = {
  data: ChannelFilterSelectionUncheckedUpdateManyWithoutChannelFilterSelectionsInput;
  where: ChannelFilterSelectionScalarWhereInput;
};

export type ChannelFilterSelectionUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelFilterSelectionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelFilterSelectionCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelFilterSelectionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelFilterSelectionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput = {
  connect?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelFilterSelectionCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  create?: Maybe<ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  update?: Maybe<ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput>;
};

export type ChannelFilterSelectionUpdateWithWhereUniqueWithoutClinicInput = {
  data: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type ChannelFilterSelectionUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput>;
};

export type ChannelFilterSelectionUpdateWithoutUserChannelFilterSelectionsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelFilterSelectionUpsertWithWhereUniqueWithoutClinicInput = {
  create: ChannelFilterSelectionUncheckedCreateWithoutClinicInput;
  update: ChannelFilterSelectionUncheckedUpdateWithoutClinicInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};

export type ChannelFilterSelectionUpsertWithoutUserChannelFilterSelectionsInput = {
  create: ChannelFilterSelectionUncheckedCreateWithoutUserChannelFilterSelectionsInput;
  update: ChannelFilterSelectionUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
};

export type ChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelFilterSelectionWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  filterSelectionType?: Maybe<EnumFilterSelectionTypeFilter>;
  filters?: Maybe<JsonFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
};

export type ChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelListRelationFilter = {
  every?: Maybe<ChannelWhereInput>;
  none?: Maybe<ChannelWhereInput>;
  some?: Maybe<ChannelWhereInput>;
};

export type ChannelMaxAggregateOutputType = {
  __typename?: 'ChannelMaxAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMaxOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  _count: ChannelMemberCountOutputType;
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages: Array<ChannelMessage>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  draft?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification: Scalars['Boolean'];
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type ChannelMemberChannelMessagesArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMemberAvgAggregateOutputType = {
  __typename?: 'ChannelMemberAvgAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Float']>;
};

export type ChannelMemberAvgOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberCountAggregateOutputType = {
  __typename?: 'ChannelMemberCountAggregateOutputType';
  _all: Scalars['Int'];
  attributes: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  draft: Scalars['Int'];
  id: Scalars['Int'];
  lastConsumedMessageIndex: Scalars['Int'];
  lastConsumptionTimestamp: Scalars['Int'];
  removedAt: Scalars['Int'];
  sendMessageNotification: Scalars['Int'];
  twilioIdentity: Scalars['Int'];
  twilioMemberSid: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChannelMemberCountOrderByAggregateInput = {
  attributes?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMemberCountOutputType = {
  __typename?: 'ChannelMemberCountOutputType';
  channelMessages: Scalars['Int'];
};

export type ChannelMemberCreateInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateManyChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberCreateManyChannelInputEnvelope = {
  data: Array<ChannelMemberCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyClinicPetParentInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ChannelMemberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberCreateManyUserInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberCreateManyUserInputEnvelope = {
  data: Array<ChannelMemberCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMemberCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
};

export type ChannelMemberCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
};

export type ChannelMemberCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
};

export type ChannelMemberCreateNestedOneWithoutChannelMessagesInput = {
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
};

export type ChannelMemberCreateOrConnectWithoutChannelInput = {
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberCreateOrConnectWithoutChannelMessagesInput = {
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberCreateOrConnectWithoutClinicPetParentInput = {
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberCreateOrConnectWithoutUserInput = {
  create: ChannelMemberUncheckedCreateWithoutUserInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberCreateWithoutChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateWithoutChannelMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateWithoutClinicPetParentInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutChannelMembersInput>;
};

export type ChannelMemberCreateWithoutUserInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelMembersInput>;
  channelMessages?: Maybe<ChannelMessageCreateNestedManyWithoutAuthorInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutChannelMembersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberListRelationFilter = {
  every?: Maybe<ChannelMemberWhereInput>;
  none?: Maybe<ChannelMemberWhereInput>;
  some?: Maybe<ChannelMemberWhereInput>;
};

export type ChannelMemberMaxAggregateOutputType = {
  __typename?: 'ChannelMemberMaxAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberMaxOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMemberMinAggregateOutputType = {
  __typename?: 'ChannelMemberMinAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberMinOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMemberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMemberOrderByRelevanceFieldEnum {
  ChannelId = 'channelId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  TwilioIdentity = 'twilioIdentity',
  TwilioMemberSid = 'twilioMemberSid',
  UserId = 'userId'
}

export type ChannelMemberOrderByRelevanceInput = {
  fields: Array<ChannelMemberOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelMemberOrderByWithAggregationInput = {
  _avg?: Maybe<ChannelMemberAvgOrderByAggregateInput>;
  _count?: Maybe<ChannelMemberCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMemberMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMemberMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMemberSumOrderByAggregateInput>;
  attributes?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMemberOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelMemberOrderByRelevanceInput>;
  attributes?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  channelMessages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draft?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastConsumedMessageIndex?: Maybe<SortOrder>;
  lastConsumptionTimestamp?: Maybe<SortOrder>;
  removedAt?: Maybe<SortOrder>;
  sendMessageNotification?: Maybe<SortOrder>;
  twilioIdentity?: Maybe<SortOrder>;
  twilioMemberSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMemberRelationFilter = {
  is?: Maybe<ChannelMemberWhereInput>;
  isNot?: Maybe<ChannelMemberWhereInput>;
};

export enum ChannelMemberScalarFieldEnum {
  Attributes = 'attributes',
  ChannelId = 'channelId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Draft = 'draft',
  Id = 'id',
  LastConsumedMessageIndex = 'lastConsumedMessageIndex',
  LastConsumptionTimestamp = 'lastConsumptionTimestamp',
  RemovedAt = 'removedAt',
  SendMessageNotification = 'sendMessageNotification',
  TwilioIdentity = 'twilioIdentity',
  TwilioMemberSid = 'twilioMemberSid',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ChannelMemberScalarWhereInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  attributes?: Maybe<JsonNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draft?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type ChannelMemberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMemberScalarWhereWithAggregatesInput>>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  draft?: Maybe<JsonNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableWithAggregatesFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableWithAggregatesFilter>;
  removedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  sendMessageNotification?: Maybe<BoolWithAggregatesFilter>;
  twilioIdentity?: Maybe<StringNullableWithAggregatesFilter>;
  twilioMemberSid?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelMemberSubscription = {
  __typename?: 'ChannelMemberSubscription';
  mutation: MutationType;
  node: ChannelMember;
};

export type ChannelMemberSumAggregateOutputType = {
  __typename?: 'ChannelMemberSumAggregateOutputType';
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
};

export type ChannelMemberSumOrderByAggregateInput = {
  lastConsumedMessageIndex?: Maybe<SortOrder>;
};

export type ChannelMemberUncheckedCreateInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
};

export type ChannelMemberUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
};

export type ChannelMemberUncheckedCreateWithoutChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedCreateWithoutChannelMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedCreateWithoutClinicPetParentInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedCreateWithoutUserInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberUncheckedUpdateInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput = {
  attributes?: Maybe<Scalars['Json']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ChannelMemberUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateWithoutChannelMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateWithoutClinicPetParentInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMemberUncheckedUpdateWithoutUserInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelId?: Maybe<Scalars['String']>;
  channelMessages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput = {
  channelId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelMemberUpdateInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
};

export type ChannelMemberUpdateManyMutationInput = {
  attributes?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberUpdateManyWithWhereWithoutChannelInput = {
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
  where: ChannelMemberScalarWhereInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
  where: ChannelMemberScalarWhereInput;
};

export type ChannelMemberUpdateManyWithWhereWithoutUserInput = {
  data: ChannelMemberUncheckedUpdateManyWithoutChannelMembersInput;
  where: ChannelMemberScalarWhereInput;
};

export type ChannelMemberUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMemberCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelMemberUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ChannelMemberCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ChannelMemberUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMemberCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMemberCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMemberCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMemberWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMemberUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMemberUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelMemberUpdateOneWithoutChannelMessagesNestedInput = {
  connect?: Maybe<ChannelMemberWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMemberCreateOrConnectWithoutChannelMessagesInput>;
  create?: Maybe<ChannelMemberUncheckedCreateWithoutChannelMessagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelMemberUncheckedUpdateWithoutChannelMessagesInput>;
  upsert?: Maybe<ChannelMemberUpsertWithoutChannelMessagesInput>;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutChannelInput = {
  data: ChannelMemberUncheckedUpdateWithoutChannelInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpdateWithWhereUniqueWithoutUserInput = {
  data: ChannelMemberUncheckedUpdateWithoutUserInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpdateWithoutChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
};

export type ChannelMemberUpdateWithoutChannelMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
};

export type ChannelMemberUpdateWithoutClinicPetParentInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutChannelMembersNestedInput>;
};

export type ChannelMemberUpdateWithoutUserInput = {
  attributes?: Maybe<Scalars['Json']>;
  channel?: Maybe<ChannelUpdateOneWithoutChannelMembersNestedInput>;
  channelMessages?: Maybe<ChannelMessageUpdateManyWithoutAuthorNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutChannelMembersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draft?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  lastConsumptionTimestamp?: Maybe<Scalars['DateTime']>;
  removedAt?: Maybe<Scalars['DateTime']>;
  sendMessageNotification?: Maybe<Scalars['Boolean']>;
  twilioIdentity?: Maybe<Scalars['String']>;
  twilioMemberSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutChannelInput = {
  create: ChannelMemberUncheckedCreateWithoutChannelInput;
  update: ChannelMemberUncheckedUpdateWithoutChannelInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ChannelMemberUncheckedCreateWithoutClinicPetParentInput;
  update: ChannelMemberUncheckedUpdateWithoutClinicPetParentInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpsertWithWhereUniqueWithoutUserInput = {
  create: ChannelMemberUncheckedCreateWithoutUserInput;
  update: ChannelMemberUncheckedUpdateWithoutUserInput;
  where: ChannelMemberWhereUniqueInput;
};

export type ChannelMemberUpsertWithoutChannelMessagesInput = {
  create: ChannelMemberUncheckedCreateWithoutChannelMessagesInput;
  update: ChannelMemberUncheckedUpdateWithoutChannelMessagesInput;
};

export type ChannelMemberWhereInput = {
  AND?: Maybe<Array<ChannelMemberWhereInput>>;
  NOT?: Maybe<Array<ChannelMemberWhereInput>>;
  OR?: Maybe<Array<ChannelMemberWhereInput>>;
  attributes?: Maybe<JsonNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringNullableFilter>;
  channelMessages?: Maybe<ChannelMessageListRelationFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draft?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  lastConsumedMessageIndex?: Maybe<IntNullableFilter>;
  lastConsumptionTimestamp?: Maybe<DateTimeNullableFilter>;
  removedAt?: Maybe<DateTimeNullableFilter>;
  sendMessageNotification?: Maybe<BoolFilter>;
  twilioIdentity?: Maybe<StringNullableFilter>;
  twilioMemberSid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type ChannelMemberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniqueChannelMemberForPetParentAndChannel?: Maybe<ChannelMemberUniqueChannelMemberForPetParentAndChannelCompoundUniqueInput>;
  uniqueChannelMemberForUserAndChannel?: Maybe<ChannelMemberUniqueChannelMemberForUserAndChannelCompoundUniqueInput>;
};

export type ChannelMessage = {
  __typename?: 'ChannelMessage';
  _count: ChannelMessageCountOutputType;
  attachments: Array<ChannelMessageAttachment>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMember>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses: Array<ChannelMessageAutoResponse>;
  automationRun?: Maybe<AutomationRun>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt: Scalars['Boolean'];
  id: Scalars['String'];
  index: Scalars['Int'];
  isUnreadEligible: Scalars['Boolean'];
  lastMessageChannel: Array<Channel>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers: Array<User>;
  messageType: MessageType;
  pushNotificationLogs: Array<PushNotificationLog>;
  reactions: Array<ChannelMessageReaction>;
  smsNotifificationStatuses: Array<SmsNotificationStatus>;
  source: MessageSource;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  wasEdited: Scalars['Boolean'];
};


export type ChannelMessageAttachmentsArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageAttachmentScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type ChannelMessageAutoResponsesArgs = {
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageAutoResponseScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type ChannelMessageLastMessageChannelArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type ChannelMessageMentionedUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type ChannelMessagePushNotificationLogsArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type ChannelMessageReactionsArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type ChannelMessageSmsNotifificationStatusesArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};

export type ChannelMessageAttachment = {
  __typename?: 'ChannelMessageAttachment';
  _count: ChannelMessageAttachmentCountOutputType;
  attachmentType: ChannelMessageAttachmentType;
  channelMessage: Array<ChannelMessage>;
  computed_optimizedAttachment?: Maybe<Scalars['String']>;
  computed_urlExpiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};


export type ChannelMessageAttachmentChannelMessageArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMessageAttachmentCountAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentCountAggregateOutputType';
  _all: Scalars['Int'];
  attachmentType: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: Scalars['Int'];
  filename: Scalars['Int'];
  id: Scalars['Int'];
  mediaId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  uploadStatus: Scalars['Int'];
  url: Scalars['Int'];
};

export type ChannelMessageAttachmentCountOrderByAggregateInput = {
  attachmentType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentCountOutputType = {
  __typename?: 'ChannelMessageAttachmentCountOutputType';
  channelMessage: Scalars['Int'];
};

export type ChannelMessageAttachmentCreateInput = {
  attachmentType: ChannelMessageAttachmentType;
  channelMessage?: Maybe<ChannelMessageCreateNestedManyWithoutAttachmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  media?: Maybe<MediaCreateNestedOneWithoutChannelMessageAttachmentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentCreateManyInput = {
  attachmentType: ChannelMessageAttachmentType;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentCreateManyMediaInput = {
  attachmentType: ChannelMessageAttachmentType;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentCreateManyMediaInputEnvelope = {
  data: Array<ChannelMessageAttachmentCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
};

export type ChannelMessageAttachmentCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
};

export type ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput = {
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentCreateOrConnectWithoutMediaInput = {
  create: ChannelMessageAttachmentUncheckedCreateWithoutMediaInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentCreateWithoutChannelMessageInput = {
  attachmentType: ChannelMessageAttachmentType;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  media?: Maybe<MediaCreateNestedOneWithoutChannelMessageAttachmentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentCreateWithoutMediaInput = {
  attachmentType: ChannelMessageAttachmentType;
  channelMessage?: Maybe<ChannelMessageCreateNestedManyWithoutAttachmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export enum ChannelMessageAttachmentEntityType {
  Appointment = 'Appointment',
  AutomationRun = 'AutomationRun',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  Form = 'Form',
  ServiceReminderNotification = 'ServiceReminderNotification',
  StripePaymentIntent = 'StripePaymentIntent',
  Transaction = 'Transaction'
}

export type ChannelMessageAttachmentListRelationFilter = {
  every?: Maybe<ChannelMessageAttachmentWhereInput>;
  none?: Maybe<ChannelMessageAttachmentWhereInput>;
  some?: Maybe<ChannelMessageAttachmentWhereInput>;
};

export type ChannelMessageAttachmentMaxAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMaxAggregateOutputType';
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentMaxOrderByAggregateInput = {
  attachmentType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentMinAggregateOutputType = {
  __typename?: 'ChannelMessageAttachmentMinAggregateOutputType';
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentMinOrderByAggregateInput = {
  attachmentType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageAttachmentOrderByRelevanceFieldEnum {
  EntityId = 'entityId',
  Filename = 'filename',
  Id = 'id',
  MediaId = 'mediaId',
  Url = 'url'
}

export type ChannelMessageAttachmentOrderByRelevanceInput = {
  fields: Array<ChannelMessageAttachmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelMessageAttachmentOrderByWithAggregationInput = {
  _count?: Maybe<ChannelMessageAttachmentCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageAttachmentMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageAttachmentMinOrderByAggregateInput>;
  attachmentType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelMessageAttachmentOrderByRelevanceInput>;
  attachmentType?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  mediaId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadStatus?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum ChannelMessageAttachmentScalarFieldEnum {
  AttachmentType = 'attachmentType',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Filename = 'filename',
  Id = 'id',
  MediaId = 'mediaId',
  UpdatedAt = 'updatedAt',
  UploadStatus = 'uploadStatus',
  Url = 'url'
}

export type ChannelMessageAttachmentScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
  url?: Maybe<StringNullableFilter>;
};

export type ChannelMessageAttachmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentScalarWhereWithAggregatesInput>>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  filename?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  url?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ChannelMessageAttachmentSubscription = {
  __typename?: 'ChannelMessageAttachmentSubscription';
  mutation: MutationType;
  node: ChannelMessageAttachment;
};

export enum ChannelMessageAttachmentType {
  AppointmentArrival = 'AppointmentArrival',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentReminder = 'AppointmentReminder',
  AutomationRun = 'AutomationRun',
  CallHistory = 'CallHistory',
  CallRequest = 'CallRequest',
  File = 'File',
  FormRequest = 'FormRequest',
  Image = 'Image',
  Invoice = 'Invoice',
  PetParentRequest = 'PetParentRequest',
  ServiceReminderFormSubmission = 'ServiceReminderFormSubmission',
  ServiceReminderNotification = 'ServiceReminderNotification',
  Video = 'Video',
  WidgetRequest = 'WidgetRequest'
}

export type ChannelMessageAttachmentUncheckedCreateInput = {
  attachmentType: ChannelMessageAttachmentType;
  channelMessage?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
};

export type ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
};

export type ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput = {
  attachmentType: ChannelMessageAttachmentType;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedCreateWithoutMediaInput = {
  attachmentType: ChannelMessageAttachmentType;
  channelMessage?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  channelMessage?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageAttachmentsInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  channelMessage?: Maybe<ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUpdateInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  channelMessage?: Maybe<ChannelMessageUpdateManyWithoutAttachmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUpdateManyMutationInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput = {
  data: ChannelMessageAttachmentUncheckedUpdateManyWithoutAttachmentsInput;
  where: ChannelMessageAttachmentScalarWhereInput;
};

export type ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput = {
  data: ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageAttachmentsInput;
  where: ChannelMessageAttachmentScalarWhereInput;
};

export type ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutChannelMessageInput>>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAttachmentCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ChannelMessageAttachmentCreateWithoutMediaInput>>;
  createMany?: Maybe<ChannelMessageAttachmentCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAttachmentScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAttachmentWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ChannelMessageAttachmentUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ChannelMessageAttachmentUpdateWithWhereUniqueWithoutChannelMessageInput = {
  data: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentUpdateWithWhereUniqueWithoutMediaInput = {
  data: ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentUpdateWithoutChannelMessageInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export type ChannelMessageAttachmentUpdateWithoutMediaInput = {
  attachmentType?: Maybe<ChannelMessageAttachmentType>;
  channelMessage?: Maybe<ChannelMessageUpdateManyWithoutAttachmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<ChannelMessageAttachmentEntityType>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadStatus?: Maybe<ChannelMessageAttachmentUploadStatus>;
  url?: Maybe<Scalars['String']>;
};

export enum ChannelMessageAttachmentUploadStatus {
  Error = 'Error',
  InProgress = 'InProgress',
  Pending = 'Pending',
  Success = 'Success'
}

export type ChannelMessageAttachmentUpsertWithWhereUniqueWithoutChannelMessageInput = {
  create: ChannelMessageAttachmentUncheckedCreateWithoutChannelMessageInput;
  update: ChannelMessageAttachmentUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentUpsertWithWhereUniqueWithoutMediaInput = {
  create: ChannelMessageAttachmentUncheckedCreateWithoutMediaInput;
  update: ChannelMessageAttachmentUncheckedUpdateWithoutMediaInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};

export type ChannelMessageAttachmentWhereInput = {
  AND?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAttachmentWhereInput>>;
  attachmentType?: Maybe<EnumChannelMessageAttachmentTypeFilter>;
  channelMessage?: Maybe<ChannelMessageListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumChannelMessageAttachmentEntityTypeNullableFilter>;
  filename?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  media?: Maybe<MediaWhereInput>;
  mediaId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploadStatus?: Maybe<EnumChannelMessageAttachmentUploadStatusNullableFilter>;
  url?: Maybe<StringNullableFilter>;
};

export type ChannelMessageAttachmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageAutoResponse = {
  __typename?: 'ChannelMessageAutoResponse';
  aiSuggestionMessage: Scalars['String'];
  channelMessage?: Maybe<ChannelMessage>;
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt: Scalars['DateTime'];
};

export type ChannelMessageAutoResponseCountAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseCountAggregateOutputType';
  _all: Scalars['Int'];
  aiSuggestionMessage: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  suggestionStatus: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelMessageAutoResponseCountOrderByAggregateInput = {
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseCreateInput = {
  aiSuggestionMessage: Scalars['String'];
  channelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutAutoResponsesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseCreateManyChannelMessageInput = {
  aiSuggestionMessage: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope = {
  data: Array<ChannelMessageAutoResponseCreateManyChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageAutoResponseCreateManyInput = {
  aiSuggestionMessage: Scalars['String'];
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
};

export type ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput = {
  create: ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};

export type ChannelMessageAutoResponseCreateWithoutChannelMessageInput = {
  aiSuggestionMessage: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseListRelationFilter = {
  every?: Maybe<ChannelMessageAutoResponseWhereInput>;
  none?: Maybe<ChannelMessageAutoResponseWhereInput>;
  some?: Maybe<ChannelMessageAutoResponseWhereInput>;
};

export type ChannelMessageAutoResponseMaxAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseMaxAggregateOutputType';
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseMaxOrderByAggregateInput = {
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseMinAggregateOutputType = {
  __typename?: 'ChannelMessageAutoResponseMinAggregateOutputType';
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseMinOrderByAggregateInput = {
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageAutoResponseOrderByRelevanceFieldEnum {
  AiSuggestionMessage = 'aiSuggestionMessage',
  ChannelMessageId = 'channelMessageId',
  Id = 'id'
}

export type ChannelMessageAutoResponseOrderByRelevanceInput = {
  fields: Array<ChannelMessageAutoResponseOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelMessageAutoResponseOrderByWithAggregationInput = {
  _count?: Maybe<ChannelMessageAutoResponseCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageAutoResponseMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageAutoResponseMinOrderByAggregateInput>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelMessageAutoResponseOrderByRelevanceInput>;
  aiSuggestionMessage?: Maybe<SortOrder>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  suggestionStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ChannelMessageAutoResponseScalarFieldEnum {
  AiSuggestionMessage = 'aiSuggestionMessage',
  ChannelMessageId = 'channelMessageId',
  CreatedAt = 'createdAt',
  Id = 'id',
  SuggestionStatus = 'suggestionStatus',
  UpdatedAt = 'updatedAt'
}

export type ChannelMessageAutoResponseScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  aiSuggestionMessage?: Maybe<StringFilter>;
  channelMessageId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelMessageAutoResponseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseScalarWhereWithAggregatesInput>>;
  aiSuggestionMessage?: Maybe<StringWithAggregatesFilter>;
  channelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChannelMessageAutoResponseUncheckedCreateInput = {
  aiSuggestionMessage: Scalars['String'];
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
};

export type ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput = {
  aiSuggestionMessage: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus: AutoResponseSuggestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUncheckedUpdateInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyWithoutAutoResponsesInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUpdateInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  channelMessage?: Maybe<ChannelMessageUpdateOneWithoutAutoResponsesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUpdateManyMutationInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput = {
  data: ChannelMessageAutoResponseUncheckedUpdateManyWithoutAutoResponsesInput;
  where: ChannelMessageAutoResponseScalarWhereInput;
};

export type ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageAutoResponseCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageAutoResponseCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageAutoResponseCreateManyChannelMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageAutoResponseScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageAutoResponseWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageAutoResponseUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageAutoResponseUpdateWithWhereUniqueWithoutChannelMessageInput = {
  data: ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};

export type ChannelMessageAutoResponseUpdateWithoutChannelMessageInput = {
  aiSuggestionMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  suggestionStatus?: Maybe<AutoResponseSuggestionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageAutoResponseUpsertWithWhereUniqueWithoutChannelMessageInput = {
  create: ChannelMessageAutoResponseUncheckedCreateWithoutChannelMessageInput;
  update: ChannelMessageAutoResponseUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};

export type ChannelMessageAutoResponseWhereInput = {
  AND?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  OR?: Maybe<Array<ChannelMessageAutoResponseWhereInput>>;
  aiSuggestionMessage?: Maybe<StringFilter>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  channelMessageId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  suggestionStatus?: Maybe<EnumAutoResponseSuggestionStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelMessageAutoResponseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageAvgAggregateOutputType = {
  __typename?: 'ChannelMessageAvgAggregateOutputType';
  index?: Maybe<Scalars['Float']>;
};

export type ChannelMessageAvgOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageCountAggregateOutputType = {
  __typename?: 'ChannelMessageCountAggregateOutputType';
  _all: Scalars['Int'];
  attributes: Scalars['Int'];
  authorId: Scalars['Int'];
  body: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  forceReadReceipt: Scalars['Int'];
  id: Scalars['Int'];
  index: Scalars['Int'];
  isUnreadEligible: Scalars['Int'];
  lastUpdatedBy: Scalars['Int'];
  media: Scalars['Int'];
  messageType: Scalars['Int'];
  source: Scalars['Int'];
  sourcePhoneNumber: Scalars['Int'];
  twilioMessageSid: Scalars['Int'];
  updatedAt: Scalars['Int'];
  wasEdited: Scalars['Int'];
};

export type ChannelMessageCountOrderByAggregateInput = {
  attributes?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
};

export type ChannelMessageCountOutputType = {
  __typename?: 'ChannelMessageCountOutputType';
  attachments: Scalars['Int'];
  autoResponses: Scalars['Int'];
  lastMessageChannel: Scalars['Int'];
  mentionedUsers: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
  reactions: Scalars['Int'];
  smsNotifificationStatuses: Scalars['Int'];
};

export type ChannelMessageCreateInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyAuthorInput = {
  attributes?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyAuthorInputEnvelope = {
  data: Array<ChannelMessageCreateManyAuthorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyChannelInputEnvelope = {
  data: Array<ChannelMessageCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType: MessageType;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateNestedManyWithoutAttachmentsInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
};

export type ChannelMessageCreateNestedManyWithoutAuthorInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
};

export type ChannelMessageCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
};

export type ChannelMessageCreateNestedManyWithoutMentionedUsersInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
};

export type ChannelMessageCreateNestedOneWithoutAutoResponsesInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutoResponsesInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutoResponsesInput>;
};

export type ChannelMessageCreateNestedOneWithoutAutomationRunInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
};

export type ChannelMessageCreateNestedOneWithoutLastMessageChannelInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
};

export type ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput>;
};

export type ChannelMessageCreateNestedOneWithoutReactionsInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
};

export type ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
};

export type ChannelMessageCreateOrConnectWithoutAttachmentsInput = {
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutAuthorInput = {
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutAutoResponsesInput = {
  create: ChannelMessageUncheckedCreateWithoutAutoResponsesInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutAutomationRunInput = {
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutChannelInput = {
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutLastMessageChannelInput = {
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutMentionedUsersInput = {
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput = {
  create: ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutReactionsInput = {
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput = {
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageCreateWithoutAttachmentsInput = {
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutAuthorInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutAutoResponsesInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutAutomationRunInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutLastMessageChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutMentionedUsersInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutPushNotificationLogsInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutReactionsInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageCreateWithoutSmsNotifificationStatusesInput = {
  attachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberCreateNestedOneWithoutChannelMessagesInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelCreateNestedOneWithoutMessagesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageListRelationFilter = {
  every?: Maybe<ChannelMessageWhereInput>;
  none?: Maybe<ChannelMessageWhereInput>;
  some?: Maybe<ChannelMessageWhereInput>;
};

export type ChannelMessageMaxAggregateOutputType = {
  __typename?: 'ChannelMessageMaxAggregateOutputType';
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMaxOrderByAggregateInput = {
  authorId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
};

export type ChannelMessageMinAggregateOutputType = {
  __typename?: 'ChannelMessageMinAggregateOutputType';
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageMinOrderByAggregateInput = {
  authorId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
};

export type ChannelMessageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageOrderByRelevanceFieldEnum {
  AuthorId = 'authorId',
  Body = 'body',
  ChannelId = 'channelId',
  Id = 'id',
  LastUpdatedBy = 'lastUpdatedBy',
  SourcePhoneNumber = 'sourcePhoneNumber',
  TwilioMessageSid = 'twilioMessageSid'
}

export type ChannelMessageOrderByRelevanceInput = {
  fields: Array<ChannelMessageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelMessageOrderByWithAggregationInput = {
  _avg?: Maybe<ChannelMessageAvgOrderByAggregateInput>;
  _count?: Maybe<ChannelMessageCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelMessageSumOrderByAggregateInput>;
  attributes?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  messageType?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
};

export type ChannelMessageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelMessageOrderByRelevanceInput>;
  attachments?: Maybe<ChannelMessageAttachmentOrderByRelationAggregateInput>;
  attributes?: Maybe<SortOrder>;
  author?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  authorId?: Maybe<SortOrder>;
  autoResponses?: Maybe<ChannelMessageAutoResponseOrderByRelationAggregateInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  body?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  forceReadReceipt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  isUnreadEligible?: Maybe<SortOrder>;
  lastMessageChannel?: Maybe<ChannelOrderByRelationAggregateInput>;
  lastUpdatedBy?: Maybe<SortOrder>;
  media?: Maybe<SortOrder>;
  mentionedUsers?: Maybe<UserOrderByRelationAggregateInput>;
  messageType?: Maybe<SortOrder>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  reactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  source?: Maybe<SortOrder>;
  sourcePhoneNumber?: Maybe<SortOrder>;
  twilioMessageSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  wasEdited?: Maybe<SortOrder>;
};

export type ChannelMessageReaction = {
  __typename?: 'ChannelMessageReaction';
  channelMessage: ChannelMessage;
  channelMessageId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  reaction: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ChannelMessageReactionCountAggregateOutputType = {
  __typename?: 'ChannelMessageReactionCountAggregateOutputType';
  _all: Scalars['Int'];
  channelMessageId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  reaction: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChannelMessageReactionCountOrderByAggregateInput = {
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMessageReactionCreateInput = {
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateManyChannelMessageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelMessageReactionCreateManyChannelMessageInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateManyInput = {
  channelMessageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelMessageReactionCreateManyUserInput = {
  channelMessageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionCreateManyUserInputEnvelope = {
  data: Array<ChannelMessageReactionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageReactionCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
};

export type ChannelMessageReactionCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
};

export type ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput = {
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionCreateOrConnectWithoutUserInput = {
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionCreateWithoutChannelMessageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutChannelMessageReactionsInput;
};

export type ChannelMessageReactionCreateWithoutUserInput = {
  channelMessage: ChannelMessageCreateNestedOneWithoutReactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionListRelationFilter = {
  every?: Maybe<ChannelMessageReactionWhereInput>;
  none?: Maybe<ChannelMessageReactionWhereInput>;
  some?: Maybe<ChannelMessageReactionWhereInput>;
};

export type ChannelMessageReactionMaxAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMaxAggregateOutputType';
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMaxOrderByAggregateInput = {
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMessageReactionMinAggregateOutputType = {
  __typename?: 'ChannelMessageReactionMinAggregateOutputType';
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionMinOrderByAggregateInput = {
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMessageReactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelMessageReactionOrderByRelevanceFieldEnum {
  ChannelMessageId = 'channelMessageId',
  Id = 'id',
  Reaction = 'reaction',
  UserId = 'userId'
}

export type ChannelMessageReactionOrderByRelevanceInput = {
  fields: Array<ChannelMessageReactionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelMessageReactionOrderByWithAggregationInput = {
  _count?: Maybe<ChannelMessageReactionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMessageReactionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMessageReactionMinOrderByAggregateInput>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelMessageReactionOrderByRelevanceInput>;
  channelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  channelMessageId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reaction?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum ChannelMessageReactionScalarFieldEnum {
  ChannelMessageId = 'channelMessageId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Reaction = 'reaction',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ChannelMessageReactionScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  channelMessageId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type ChannelMessageReactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageReactionScalarWhereWithAggregatesInput>>;
  channelMessageId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  reaction?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelMessageReactionUncheckedCreateInput = {
  channelMessageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
};

export type ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
};

export type ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ChannelMessageReactionUncheckedCreateWithoutUserInput = {
  channelMessageId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionUncheckedUpdateInput = {
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyInput = {
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput = {
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelMessageReactionUncheckedUpdateWithoutUserInput = {
  channelMessageId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionUpdateInput = {
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput>;
};

export type ChannelMessageReactionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput = {
  data: ChannelMessageReactionUncheckedUpdateManyWithoutReactionsInput;
  where: ChannelMessageReactionScalarWhereInput;
};

export type ChannelMessageReactionUpdateManyWithWhereWithoutUserInput = {
  data: ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageReactionsInput;
  where: ChannelMessageReactionScalarWhereInput;
};

export type ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutChannelMessageInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutChannelMessageInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyChannelMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutChannelMessageInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput>>;
};

export type ChannelMessageReactionUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageReactionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelMessageReactionCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelMessageReactionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageReactionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageReactionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelMessageReactionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutChannelMessageInput = {
  data: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionUpdateWithWhereUniqueWithoutUserInput = {
  data: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionUpdateWithoutChannelMessageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput>;
};

export type ChannelMessageReactionUpdateWithoutUserInput = {
  channelMessage?: Maybe<ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reaction?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutChannelMessageInput = {
  create: ChannelMessageReactionUncheckedCreateWithoutChannelMessageInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutChannelMessageInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionUpsertWithWhereUniqueWithoutUserInput = {
  create: ChannelMessageReactionUncheckedCreateWithoutUserInput;
  update: ChannelMessageReactionUncheckedUpdateWithoutUserInput;
  where: ChannelMessageReactionWhereUniqueInput;
};

export type ChannelMessageReactionWhereInput = {
  AND?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  OR?: Maybe<Array<ChannelMessageReactionWhereInput>>;
  channelMessage?: Maybe<ChannelMessageWhereInput>;
  channelMessageId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  reaction?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type ChannelMessageReactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMessageRelationFilter = {
  is?: Maybe<ChannelMessageWhereInput>;
  isNot?: Maybe<ChannelMessageWhereInput>;
};

export enum ChannelMessageScalarFieldEnum {
  Attributes = 'attributes',
  AuthorId = 'authorId',
  Body = 'body',
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ForceReadReceipt = 'forceReadReceipt',
  Id = 'id',
  Index = 'index',
  IsUnreadEligible = 'isUnreadEligible',
  LastUpdatedBy = 'lastUpdatedBy',
  Media = 'media',
  MessageType = 'messageType',
  Source = 'source',
  SourcePhoneNumber = 'sourcePhoneNumber',
  TwilioMessageSid = 'twilioMessageSid',
  UpdatedAt = 'updatedAt',
  WasEdited = 'wasEdited'
}

export type ChannelMessageScalarWhereInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  attributes?: Maybe<JsonNullableFilter>;
  authorId?: Maybe<StringNullableFilter>;
  body?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  wasEdited?: Maybe<BoolFilter>;
};

export type ChannelMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelMessageScalarWhereWithAggregatesInput>>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  authorId?: Maybe<StringNullableWithAggregatesFilter>;
  body?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  forceReadReceipt?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  index?: Maybe<IntWithAggregatesFilter>;
  isUnreadEligible?: Maybe<BoolWithAggregatesFilter>;
  lastUpdatedBy?: Maybe<StringNullableWithAggregatesFilter>;
  media?: Maybe<JsonNullableWithAggregatesFilter>;
  messageType?: Maybe<EnumMessageTypeWithAggregatesFilter>;
  source?: Maybe<EnumMessageSourceWithAggregatesFilter>;
  sourcePhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  twilioMessageSid?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  wasEdited?: Maybe<BoolWithAggregatesFilter>;
};

export type ChannelMessageSubscription = {
  __typename?: 'ChannelMessageSubscription';
  mutation: MutationType;
  node: ChannelMessage;
};

export type ChannelMessageSumAggregateOutputType = {
  __typename?: 'ChannelMessageSumAggregateOutputType';
  index?: Maybe<Scalars['Int']>;
};

export type ChannelMessageSumOrderByAggregateInput = {
  index?: Maybe<SortOrder>;
};

export type ChannelMessageUncheckedCreateInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAttachmentsInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutAuthorInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
};

export type ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
};

export type ChannelMessageUncheckedCreateWithoutAttachmentsInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutAuthorInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutAutoResponsesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutAutomationRunInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutLastMessageChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutMentionedUsersInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutReactionsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutChannelMessageInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedCreateNestedManyWithoutChannelMessageInput>;
  automationRun?: Maybe<AutomationRunUncheckedCreateNestedOneWithoutChannelMessageInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedCreateNestedManyWithoutLastMessageInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput>;
  messageType: MessageType;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutChannelMessageInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAttachmentsNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutAuthorNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
};

export type ChannelMessageUncheckedUpdateManyWithoutMessagesInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAttachmentsInput = {
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAuthorInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAutoResponsesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutAutomationRunInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutMentionedUsersInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutReactionsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  authorId?: Maybe<Scalars['String']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUncheckedUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUncheckedUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUnreadCountResult = {
  __typename?: 'ChannelMessageUnreadCountResult';
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelMessageUpdateInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateManyMutationInput = {
  attributes?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput = {
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessageInput;
  where: ChannelMessageScalarWhereInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutAuthorInput = {
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesInput;
  where: ChannelMessageScalarWhereInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutChannelInput = {
  data: ChannelMessageUncheckedUpdateManyWithoutMessagesInput;
  where: ChannelMessageScalarWhereInput;
};

export type ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput = {
  data: ChannelMessageUncheckedUpdateManyWithoutChannelMessagesWithMentionsInput;
  where: ChannelMessageScalarWhereInput;
};

export type ChannelMessageUpdateManyWithoutAttachmentsNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAttachmentsInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAttachmentsInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAttachmentsInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput>>;
};

export type ChannelMessageUpdateManyWithoutAuthorNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutAuthorInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutAuthorInput>>;
  createMany?: Maybe<ChannelMessageCreateManyAuthorInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type ChannelMessageUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelMessageCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelMessageUpdateManyWithoutMentionedUsersNestedInput = {
  connect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelMessageCreateOrConnectWithoutMentionedUsersInput>>;
  create?: Maybe<Array<ChannelMessageCreateWithoutMentionedUsersInput>>;
  delete?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChannelMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput>>;
  updateMany?: Maybe<Array<ChannelMessageUpdateManyWithWhereWithoutMentionedUsersInput>>;
  upsert?: Maybe<Array<ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput>>;
};

export type ChannelMessageUpdateOneRequiredWithoutAutomationRunNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutomationRunInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutomationRunInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutAutomationRunInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutAutomationRunInput>;
};

export type ChannelMessageUpdateOneRequiredWithoutReactionsNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutReactionsInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutReactionsInput>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutReactionsInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutReactionsInput>;
};

export type ChannelMessageUpdateOneWithoutAutoResponsesNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutAutoResponsesInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutAutoResponsesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutAutoResponsesInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutAutoResponsesInput>;
};

export type ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutLastMessageChannelInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutLastMessageChannelInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutLastMessageChannelInput>;
};

export type ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutPushNotificationLogsInput>;
};

export type ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput = {
  connect?: Maybe<ChannelMessageWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelMessageCreateOrConnectWithoutSmsNotifificationStatusesInput>;
  create?: Maybe<ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput>;
  upsert?: Maybe<ChannelMessageUpsertWithoutSmsNotifificationStatusesInput>;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAttachmentsInput = {
  data: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutAuthorInput = {
  data: ChannelMessageUncheckedUpdateWithoutAuthorInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutChannelInput = {
  data: ChannelMessageUncheckedUpdateWithoutChannelInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpdateWithWhereUniqueWithoutMentionedUsersInput = {
  data: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpdateWithoutAttachmentsInput = {
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutAuthorInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutAutoResponsesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutAutomationRunInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutLastMessageChannelInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutMentionedUsersInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutPushNotificationLogsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutReactionsInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpdateWithoutSmsNotifificationStatusesInput = {
  attachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutChannelMessageNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  author?: Maybe<ChannelMemberUpdateOneWithoutChannelMessagesNestedInput>;
  autoResponses?: Maybe<ChannelMessageAutoResponseUpdateManyWithoutChannelMessageNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutChannelMessageNestedInput>;
  body?: Maybe<Scalars['String']>;
  channel?: Maybe<ChannelUpdateOneWithoutMessagesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceReadReceipt?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  isUnreadEligible?: Maybe<Scalars['Boolean']>;
  lastMessageChannel?: Maybe<ChannelUpdateManyWithoutLastMessageNestedInput>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  mentionedUsers?: Maybe<UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput>;
  messageType?: Maybe<MessageType>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput>;
  reactions?: Maybe<ChannelMessageReactionUpdateManyWithoutChannelMessageNestedInput>;
  source?: Maybe<MessageSource>;
  sourcePhoneNumber?: Maybe<Scalars['String']>;
  twilioMessageSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wasEdited?: Maybe<Scalars['Boolean']>;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAttachmentsInput = {
  create: ChannelMessageUncheckedCreateWithoutAttachmentsInput;
  update: ChannelMessageUncheckedUpdateWithoutAttachmentsInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutAuthorInput = {
  create: ChannelMessageUncheckedCreateWithoutAuthorInput;
  update: ChannelMessageUncheckedUpdateWithoutAuthorInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutChannelInput = {
  create: ChannelMessageUncheckedCreateWithoutChannelInput;
  update: ChannelMessageUncheckedUpdateWithoutChannelInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpsertWithWhereUniqueWithoutMentionedUsersInput = {
  create: ChannelMessageUncheckedCreateWithoutMentionedUsersInput;
  update: ChannelMessageUncheckedUpdateWithoutMentionedUsersInput;
  where: ChannelMessageWhereUniqueInput;
};

export type ChannelMessageUpsertWithoutAutoResponsesInput = {
  create: ChannelMessageUncheckedCreateWithoutAutoResponsesInput;
  update: ChannelMessageUncheckedUpdateWithoutAutoResponsesInput;
};

export type ChannelMessageUpsertWithoutAutomationRunInput = {
  create: ChannelMessageUncheckedCreateWithoutAutomationRunInput;
  update: ChannelMessageUncheckedUpdateWithoutAutomationRunInput;
};

export type ChannelMessageUpsertWithoutLastMessageChannelInput = {
  create: ChannelMessageUncheckedCreateWithoutLastMessageChannelInput;
  update: ChannelMessageUncheckedUpdateWithoutLastMessageChannelInput;
};

export type ChannelMessageUpsertWithoutPushNotificationLogsInput = {
  create: ChannelMessageUncheckedCreateWithoutPushNotificationLogsInput;
  update: ChannelMessageUncheckedUpdateWithoutPushNotificationLogsInput;
};

export type ChannelMessageUpsertWithoutReactionsInput = {
  create: ChannelMessageUncheckedCreateWithoutReactionsInput;
  update: ChannelMessageUncheckedUpdateWithoutReactionsInput;
};

export type ChannelMessageUpsertWithoutSmsNotifificationStatusesInput = {
  create: ChannelMessageUncheckedCreateWithoutSmsNotifificationStatusesInput;
  update: ChannelMessageUncheckedUpdateWithoutSmsNotifificationStatusesInput;
};

export type ChannelMessageWhereInput = {
  AND?: Maybe<Array<ChannelMessageWhereInput>>;
  NOT?: Maybe<Array<ChannelMessageWhereInput>>;
  OR?: Maybe<Array<ChannelMessageWhereInput>>;
  attachments?: Maybe<ChannelMessageAttachmentListRelationFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  author?: Maybe<ChannelMemberWhereInput>;
  authorId?: Maybe<StringNullableFilter>;
  autoResponses?: Maybe<ChannelMessageAutoResponseListRelationFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  body?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  forceReadReceipt?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  isUnreadEligible?: Maybe<BoolFilter>;
  lastMessageChannel?: Maybe<ChannelListRelationFilter>;
  lastUpdatedBy?: Maybe<StringNullableFilter>;
  media?: Maybe<JsonNullableFilter>;
  mentionedUsers?: Maybe<UserListRelationFilter>;
  messageType?: Maybe<EnumMessageTypeFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
  reactions?: Maybe<ChannelMessageReactionListRelationFilter>;
  smsNotifificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
  source?: Maybe<EnumMessageSourceFilter>;
  sourcePhoneNumber?: Maybe<StringNullableFilter>;
  twilioMessageSid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  wasEdited?: Maybe<BoolFilter>;
};

export type ChannelMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelMinAggregateOutputType = {
  __typename?: 'ChannelMinAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelMinOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelOrderByRelevanceFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId',
  CurrentWorkflowActionId = 'currentWorkflowActionId',
  FriendlyName = 'friendlyName',
  Id = 'id',
  LastMessageId = 'lastMessageId',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName'
}

export type ChannelOrderByRelevanceInput = {
  fields: Array<ChannelOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelOrderByWithAggregationInput = {
  _count?: Maybe<ChannelCountOrderByAggregateInput>;
  _max?: Maybe<ChannelMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelMinOrderByAggregateInput>;
  attributes?: Maybe<SortOrder>;
  channelStatusId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  friendlyName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  lastMessageId?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelOrderByWithRelationAndSearchRelevanceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ChannelOrderByRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  attributes?: Maybe<SortOrder>;
  automationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  channelPins?: Maybe<ChannelPinOrderByRelationAggregateInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusId?: Maybe<SortOrder>;
  channelType?: Maybe<SortOrder>;
  channelVisibility?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  currentWorkflowActionId?: Maybe<SortOrder>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  friendlyName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastExportAt?: Maybe<SortOrder>;
  lastMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  lastMessageId?: Maybe<SortOrder>;
  messages?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  tags?: Maybe<SortOrder>;
  twilioChannelSid?: Maybe<SortOrder>;
  uniqueName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelPin = {
  __typename?: 'ChannelPin';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type ChannelPinCountAggregateOutputType = {
  __typename?: 'ChannelPinCountAggregateOutputType';
  _all: Scalars['Int'];
  channelId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChannelPinCountOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelPinCreateInput = {
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelPinsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutPinnedChannelsInput;
};

export type ChannelPinCreateManyChannelInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelPinCreateManyChannelInputEnvelope = {
  data: Array<ChannelPinCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelPinCreateManyInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelPinCreateManyUserInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinCreateManyUserInputEnvelope = {
  data: Array<ChannelPinCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelPinCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
};

export type ChannelPinCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
};

export type ChannelPinCreateOrConnectWithoutChannelInput = {
  create: ChannelPinUncheckedCreateWithoutChannelInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinCreateOrConnectWithoutUserInput = {
  create: ChannelPinUncheckedCreateWithoutUserInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinCreateWithoutChannelInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutPinnedChannelsInput;
};

export type ChannelPinCreateWithoutUserInput = {
  channel?: Maybe<ChannelCreateNestedOneWithoutChannelPinsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinListRelationFilter = {
  every?: Maybe<ChannelPinWhereInput>;
  none?: Maybe<ChannelPinWhereInput>;
  some?: Maybe<ChannelPinWhereInput>;
};

export type ChannelPinMaxAggregateOutputType = {
  __typename?: 'ChannelPinMaxAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinMaxOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelPinMinAggregateOutputType = {
  __typename?: 'ChannelPinMinAggregateOutputType';
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinMinOrderByAggregateInput = {
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelPinOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelPinOrderByRelevanceFieldEnum {
  ChannelId = 'channelId',
  Id = 'id',
  UserId = 'userId'
}

export type ChannelPinOrderByRelevanceInput = {
  fields: Array<ChannelPinOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelPinOrderByWithAggregationInput = {
  _count?: Maybe<ChannelPinCountOrderByAggregateInput>;
  _max?: Maybe<ChannelPinMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelPinMinOrderByAggregateInput>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelPinOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelPinOrderByRelevanceInput>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum ChannelPinScalarFieldEnum {
  ChannelId = 'channelId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UserId = 'userId'
}

export type ChannelPinScalarWhereInput = {
  AND?: Maybe<Array<ChannelPinScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelPinScalarWhereInput>>;
  OR?: Maybe<Array<ChannelPinScalarWhereInput>>;
  channelId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type ChannelPinScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelPinScalarWhereWithAggregatesInput>>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelPinUncheckedCreateInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelPinUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
};

export type ChannelPinUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
};

export type ChannelPinUncheckedCreateWithoutChannelInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelPinUncheckedCreateWithoutUserInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelPinUncheckedUpdateManyWithoutChannelPinsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutPinnedChannelsInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelPinUncheckedUpdateWithoutChannelInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelPinUncheckedUpdateWithoutUserInput = {
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinUpdateInput = {
  channel?: Maybe<ChannelUpdateOneWithoutChannelPinsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutPinnedChannelsNestedInput>;
};

export type ChannelPinUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinUpdateManyWithWhereWithoutChannelInput = {
  data: ChannelPinUncheckedUpdateManyWithoutChannelPinsInput;
  where: ChannelPinScalarWhereInput;
};

export type ChannelPinUpdateManyWithWhereWithoutUserInput = {
  data: ChannelPinUncheckedUpdateManyWithoutPinnedChannelsInput;
  where: ChannelPinScalarWhereInput;
};

export type ChannelPinUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutChannelInput>>;
  createMany?: Maybe<ChannelPinCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type ChannelPinUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelPinCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelPinCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelPinCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelPinScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  set?: Maybe<Array<ChannelPinWhereUniqueInput>>;
  update?: Maybe<Array<ChannelPinUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelPinUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelPinUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelPinUpdateWithWhereUniqueWithoutChannelInput = {
  data: ChannelPinUncheckedUpdateWithoutChannelInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinUpdateWithWhereUniqueWithoutUserInput = {
  data: ChannelPinUncheckedUpdateWithoutUserInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinUpdateWithoutChannelInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutPinnedChannelsNestedInput>;
};

export type ChannelPinUpdateWithoutUserInput = {
  channel?: Maybe<ChannelUpdateOneWithoutChannelPinsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelPinUpsertWithWhereUniqueWithoutChannelInput = {
  create: ChannelPinUncheckedCreateWithoutChannelInput;
  update: ChannelPinUncheckedUpdateWithoutChannelInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinUpsertWithWhereUniqueWithoutUserInput = {
  create: ChannelPinUncheckedCreateWithoutUserInput;
  update: ChannelPinUncheckedUpdateWithoutUserInput;
  where: ChannelPinWhereUniqueInput;
};

export type ChannelPinWhereInput = {
  AND?: Maybe<Array<ChannelPinWhereInput>>;
  NOT?: Maybe<Array<ChannelPinWhereInput>>;
  OR?: Maybe<Array<ChannelPinWhereInput>>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type ChannelPinWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelRelationFilter = {
  is?: Maybe<ChannelWhereInput>;
  isNot?: Maybe<ChannelWhereInput>;
};

export enum ChannelScalarFieldEnum {
  Attributes = 'attributes',
  ChannelStatusId = 'channelStatusId',
  ChannelType = 'channelType',
  ChannelVisibility = 'channelVisibility',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  CurrentWorkflowActionId = 'currentWorkflowActionId',
  FriendlyName = 'friendlyName',
  Id = 'id',
  IsActive = 'isActive',
  LastExportAt = 'lastExportAt',
  LastMessageId = 'lastMessageId',
  Tags = 'tags',
  TwilioChannelSid = 'twilioChannelSid',
  UniqueName = 'uniqueName',
  UpdatedAt = 'updatedAt'
}

export type ChannelScalarWhereInput = {
  AND?: Maybe<Array<ChannelScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereInput>>;
  OR?: Maybe<Array<ChannelScalarWhereInput>>;
  attributes?: Maybe<JsonNullableFilter>;
  channelStatusId?: Maybe<StringNullableFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastExportAt?: Maybe<DateTimeNullableFilter>;
  lastMessageId?: Maybe<StringNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelScalarWhereWithAggregatesInput>>;
  attributes?: Maybe<JsonNullableWithAggregatesFilter>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  channelType?: Maybe<EnumChannelTypeNullableWithAggregatesFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentWorkflowActionId?: Maybe<StringNullableWithAggregatesFilter>;
  friendlyName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  lastExportAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
  twilioChannelSid?: Maybe<StringNullableWithAggregatesFilter>;
  uniqueName?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChannelSearchOutput = {
  __typename?: 'ChannelSearchOutput';
  channels: Array<Channel>;
  total: Scalars['Int'];
};

export type ChannelSelection = {
  __typename?: 'ChannelSelection';
  _count: ChannelSelectionCountOutputType;
  assignees: Array<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  includeUnassigned: Scalars['Boolean'];
  statuses: Array<ChannelStatus>;
  updatedAt: Scalars['DateTime'];
  userSetting?: Maybe<UserSetting>;
};


export type ChannelSelectionAssigneesArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type ChannelSelectionStatusesArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelSelectionCountAggregateOutputType = {
  __typename?: 'ChannelSelectionCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  includeUnassigned: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelSelectionCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelSelectionCountOutputType = {
  __typename?: 'ChannelSelectionCountOutputType';
  assignees: Scalars['Int'];
  statuses: Scalars['Int'];
};

export type ChannelSelectionCreateInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
};

export type ChannelSelectionCreateNestedManyWithoutStatusesInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
};

export type ChannelSelectionCreateNestedOneWithoutUserSettingInput = {
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
};

export type ChannelSelectionCreateOrConnectWithoutAssigneesInput = {
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionCreateOrConnectWithoutStatusesInput = {
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionCreateOrConnectWithoutUserSettingInput = {
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionCreateWithoutAssigneesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutStatusesInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionCreateWithoutUserSettingInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionListRelationFilter = {
  every?: Maybe<ChannelSelectionWhereInput>;
  none?: Maybe<ChannelSelectionWhereInput>;
  some?: Maybe<ChannelSelectionWhereInput>;
};

export type ChannelSelectionMaxAggregateOutputType = {
  __typename?: 'ChannelSelectionMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelSelectionMinAggregateOutputType = {
  __typename?: 'ChannelSelectionMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelSelectionOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type ChannelSelectionOrderByRelevanceInput = {
  fields: Array<ChannelSelectionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelSelectionOrderByWithAggregationInput = {
  _count?: Maybe<ChannelSelectionCountOrderByAggregateInput>;
  _max?: Maybe<ChannelSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelSelectionMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelSelectionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelSelectionOrderByRelevanceInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  includeUnassigned?: Maybe<SortOrder>;
  statuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
};

export type ChannelSelectionRelationFilter = {
  is?: Maybe<ChannelSelectionWhereInput>;
  isNot?: Maybe<ChannelSelectionWhereInput>;
};

export enum ChannelSelectionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IncludeUnassigned = 'includeUnassigned',
  UpdatedAt = 'updatedAt'
}

export type ChannelSelectionScalarWhereInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelSelectionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  includeUnassigned?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChannelSelectionUncheckedCreateInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
};

export type ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
};

export type ChannelSelectionUncheckedCreateWithoutAssigneesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutStatusesInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput>;
};

export type ChannelSelectionUncheckedCreateWithoutUserSettingInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUncheckedUpdateInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
};

export type ChannelSelectionUncheckedUpdateWithoutAssigneesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutStatusesInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUncheckedUpdateWithoutUserSettingInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUpdateInput = {
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput = {
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
  where: ChannelSelectionScalarWhereInput;
};

export type ChannelSelectionUpdateManyWithWhereWithoutStatusesInput = {
  data: ChannelSelectionUncheckedUpdateManyWithoutChannelSelectionsInput;
  where: ChannelSelectionScalarWhereInput;
};

export type ChannelSelectionUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ChannelSelectionUpdateManyWithoutStatusesNestedInput = {
  connect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelSelectionCreateOrConnectWithoutStatusesInput>>;
  create?: Maybe<Array<ChannelSelectionCreateWithoutStatusesInput>>;
  delete?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  set?: Maybe<Array<ChannelSelectionWhereUniqueInput>>;
  update?: Maybe<Array<ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput>>;
  updateMany?: Maybe<Array<ChannelSelectionUpdateManyWithWhereWithoutStatusesInput>>;
  upsert?: Maybe<Array<ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput>>;
};

export type ChannelSelectionUpdateOneWithoutUserSettingNestedInput = {
  connect?: Maybe<ChannelSelectionWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelSelectionCreateOrConnectWithoutUserSettingInput>;
  create?: Maybe<ChannelSelectionUncheckedCreateWithoutUserSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelSelectionUncheckedUpdateWithoutUserSettingInput>;
  upsert?: Maybe<ChannelSelectionUpsertWithoutUserSettingInput>;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutAssigneesInput = {
  data: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionUpdateWithWhereUniqueWithoutStatusesInput = {
  data: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionUpdateWithoutAssigneesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateWithoutStatusesInput = {
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput>;
};

export type ChannelSelectionUpdateWithoutUserSettingInput = {
  assignees?: Maybe<UserUpdateManyWithoutChannelSelectionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutAssigneesInput = {
  create: ChannelSelectionUncheckedCreateWithoutAssigneesInput;
  update: ChannelSelectionUncheckedUpdateWithoutAssigneesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionUpsertWithWhereUniqueWithoutStatusesInput = {
  create: ChannelSelectionUncheckedCreateWithoutStatusesInput;
  update: ChannelSelectionUncheckedUpdateWithoutStatusesInput;
  where: ChannelSelectionWhereUniqueInput;
};

export type ChannelSelectionUpsertWithoutUserSettingInput = {
  create: ChannelSelectionUncheckedCreateWithoutUserSettingInput;
  update: ChannelSelectionUncheckedUpdateWithoutUserSettingInput;
};

export type ChannelSelectionWhereInput = {
  AND?: Maybe<Array<ChannelSelectionWhereInput>>;
  NOT?: Maybe<Array<ChannelSelectionWhereInput>>;
  OR?: Maybe<Array<ChannelSelectionWhereInput>>;
  assignees?: Maybe<UserListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  includeUnassigned?: Maybe<BoolFilter>;
  statuses?: Maybe<ChannelStatusListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
};

export type ChannelSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatus = {
  __typename?: 'ChannelStatus';
  _count: ChannelStatusCountOutputType;
  automationRunActions: Array<AutomationRunAction>;
  boardFilterColumns: Array<BoardFilterColumn>;
  channelSelections: Array<ChannelSelection>;
  channelStatusAction: ChannelStatusAction;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  channels: Array<Channel>;
  clinicSetting?: Maybe<ClinicSetting>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType: Array<ClinicWidgetRequestType>;
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedBy?: Maybe<User>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions: Array<WorkflowEventAction>;
  updatedAt: Scalars['DateTime'];
  workflowEventAction: Array<WorkflowEventAction>;
  workflowEventSettings: Array<WorkflowEventSetting>;
};


export type ChannelStatusAutomationRunActionsArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type ChannelStatusBoardFilterColumnsArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type ChannelStatusChannelSelectionsArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type ChannelStatusChannelStatusChannelAssigneesArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type ChannelStatusChannelsArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type ChannelStatusClinicWidgetRequestTypeArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type ChannelStatusResponseWorkflowEventActionsArgs = {
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventActionWhereInput>;
};


export type ChannelStatusWorkflowEventActionArgs = {
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventActionWhereInput>;
};


export type ChannelStatusWorkflowEventSettingsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export enum ChannelStatusAction {
  Active = 'Active',
  Inactive = 'Inactive',
  InactivePermanently = 'InactivePermanently',
  Migrated = 'Migrated'
}

export type ChannelStatusAvgAggregateOutputType = {
  __typename?: 'ChannelStatusAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ChannelStatusAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssignee = {
  __typename?: 'ChannelStatusChannelAssignee';
  channelStatus: ChannelStatus;
  channelStatusId: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput = {
  channelStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCountAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeCountAggregateOutputType';
  _all: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ChannelStatusChannelAssigneeCountOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeCreateInput = {
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyClinicInput = {
  channelStatusId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateManyInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeCreateManyUserInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeCreateManyUserInputEnvelope = {
  data: Array<ChannelStatusChannelAssigneeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
};

export type ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput = {
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutClinicInput = {
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user: UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusChannelAssigneeCreateWithoutUserInput = {
  channelStatus: ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  clinic: ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeListRelationFilter = {
  every?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  none?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
  some?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};

export type ChannelStatusChannelAssigneeMaxAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMaxAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMaxOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeMinAggregateOutputType = {
  __typename?: 'ChannelStatusChannelAssigneeMinAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeMinOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId',
  Id = 'id',
  UserId = 'userId'
}

export type ChannelStatusChannelAssigneeOrderByRelevanceInput = {
  fields: Array<ChannelStatusChannelAssigneeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelStatusChannelAssigneeOrderByWithAggregationInput = {
  _count?: Maybe<ChannelStatusChannelAssigneeCountOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusChannelAssigneeMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusChannelAssigneeMinOrderByAggregateInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelStatusChannelAssigneeOrderByRelevanceInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum ChannelStatusChannelAssigneeScalarFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UserId = 'userId'
}

export type ChannelStatusChannelAssigneeScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  channelStatusId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereWithAggregatesInput>>;
  channelStatusId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput = {
  channelStatusId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput = {
  channelStatusId: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUpdateInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
  where: ChannelStatusChannelAssigneeScalarWhereInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
  where: ChannelStatusChannelAssigneeScalarWhereInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusChannelAssigneesInput;
  where: ChannelStatusChannelAssigneeScalarWhereInput;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusChannelAssigneeCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ChannelStatusChannelAssigneeCreateWithoutUserInput>>;
  createMany?: Maybe<ChannelStatusChannelAssigneeCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusChannelAssigneeScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusChannelAssigneeWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ChannelStatusChannelAssigneeUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutClinicInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeUpdateWithWhereUniqueWithoutUserInput = {
  data: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeUpdateWithoutChannelStatusInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutClinicInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  user?: Maybe<UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
};

export type ChannelStatusChannelAssigneeUpdateWithoutUserInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutChannelStatusInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutChannelStatusInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutClinicInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutClinicInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutClinicInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeUpsertWithWhereUniqueWithoutUserInput = {
  create: ChannelStatusChannelAssigneeUncheckedCreateWithoutUserInput;
  update: ChannelStatusChannelAssigneeUncheckedUpdateWithoutUserInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};

export type ChannelStatusChannelAssigneeWhereInput = {
  AND?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  OR?: Maybe<Array<ChannelStatusChannelAssigneeWhereInput>>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  channelStatusId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type ChannelStatusChannelAssigneeWhereUniqueInput = {
  channelStatusChannelAssigneeChannelStatusIdAndUserId?: Maybe<ChannelStatusChannelAssigneeChannelStatusChannelAssigneeChannelStatusIdAndUserIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusCountAggregateOutputType = {
  __typename?: 'ChannelStatusCountAggregateOutputType';
  _all: Scalars['Int'];
  channelStatusAction: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  color: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedById: Scalars['Int'];
  deletedOn: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  isExportedToPims: Scalars['Int'];
  name: Scalars['Int'];
  order: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChannelStatusCountOrderByAggregateInput = {
  channelStatusAction?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelStatusCountOutputType = {
  __typename?: 'ChannelStatusCountOutputType';
  automationRunActions: Scalars['Int'];
  boardFilterColumns: Scalars['Int'];
  channelSelections: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  channels: Scalars['Int'];
  clinicWidgetRequestType: Scalars['Int'];
  responseWorkflowEventActions: Scalars['Int'];
  workflowEventAction: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
};

export type ChannelStatusCreateInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateManyClinicSettingInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusCreateManyClinicSettingInputEnvelope = {
  data: Array<ChannelStatusCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyDeletedByInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusCreateManyDeletedByInputEnvelope = {
  data: Array<ChannelStatusCreateManyDeletedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChannelStatusCreateManyInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusCreateNestedManyWithoutChannelSelectionsInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
};

export type ChannelStatusCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
};

export type ChannelStatusCreateNestedManyWithoutDeletedByInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
};

export type ChannelStatusCreateNestedOneWithoutAutomationRunActionsInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
};

export type ChannelStatusCreateNestedOneWithoutBoardFilterColumnsInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusCreateNestedOneWithoutChannelsInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
};

export type ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
};

export type ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput>;
};

export type ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
};

export type ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput>;
};

export type ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput = {
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput = {
  create: ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelSelectionsInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutChannelsInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicSettingInput = {
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutDeletedByInput = {
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput = {
  create: ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput = {
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput = {
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusCreateWithoutAutomationRunActionsInput = {
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutBoardFilterColumnsInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutChannelSelectionsInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutChannelStatusChannelAssigneesInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutChannelsInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutClinicSettingInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutClinicWidgetRequestTypeInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutDeletedByInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutResponseWorkflowEventActionsInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutWorkflowEventActionInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusCreateWithoutWorkflowEventSettingsInput = {
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutChannelStatusInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutChannelStatusesInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserCreateNestedOneWithoutChannelStatusesInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput>;
};

export enum ChannelStatusEntity {
  Appointment = 'Appointment',
  Automation = 'Automation',
  Channel = 'Channel'
}

export type ChannelStatusListRelationFilter = {
  every?: Maybe<ChannelStatusWhereInput>;
  none?: Maybe<ChannelStatusWhereInput>;
  some?: Maybe<ChannelStatusWhereInput>;
};

export type ChannelStatusMaxAggregateOutputType = {
  __typename?: 'ChannelStatusMaxAggregateOutputType';
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusMaxOrderByAggregateInput = {
  channelStatusAction?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelStatusMinAggregateOutputType = {
  __typename?: 'ChannelStatusMinAggregateOutputType';
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusMinOrderByAggregateInput = {
  channelStatusAction?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ChannelStatusOrderByRelevanceFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  Color = 'color',
  DeletedById = 'deletedById',
  Id = 'id',
  Name = 'name'
}

export type ChannelStatusOrderByRelevanceInput = {
  fields: Array<ChannelStatusOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChannelStatusOrderByWithAggregationInput = {
  _avg?: Maybe<ChannelStatusAvgOrderByAggregateInput>;
  _count?: Maybe<ChannelStatusCountOrderByAggregateInput>;
  _max?: Maybe<ChannelStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ChannelStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ChannelStatusSumOrderByAggregateInput>;
  channelStatusAction?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedById?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChannelStatusOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChannelStatusOrderByRelevanceInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  channelStatusAction?: Maybe<SortOrder>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  color?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  deletedById?: Maybe<SortOrder>;
  deletedOn?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isExportedToPims?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventAction?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type ChannelStatusRelationFilter = {
  is?: Maybe<ChannelStatusWhereInput>;
  isNot?: Maybe<ChannelStatusWhereInput>;
};

export enum ChannelStatusScalarFieldEnum {
  ChannelStatusAction = 'channelStatusAction',
  ClinicSettingId = 'clinicSettingId',
  Color = 'color',
  CreatedAt = 'createdAt',
  DeletedById = 'deletedById',
  DeletedOn = 'deletedOn',
  Id = 'id',
  IsDefault = 'isDefault',
  IsExportedToPims = 'isExportedToPims',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updatedAt'
}

export type ChannelStatusScalarWhereInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedById?: Maybe<StringNullableFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChannelStatusScalarWhereWithAggregatesInput>>;
  channelStatusAction?: Maybe<EnumChannelStatusActionWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  color?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedById?: Maybe<StringNullableWithAggregatesFilter>;
  deletedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDefault?: Maybe<BoolNullableWithAggregatesFilter>;
  isExportedToPims?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChannelStatusSumAggregateOutputType = {
  __typename?: 'ChannelStatusSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ChannelStatusSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ChannelStatusUncheckedCreateInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
};

export type ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
};

export type ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput = {
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelSelectionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutChannelsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicSettingInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutDeletedByInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutStatusesInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutChannelStatusInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput>;
};

export type ChannelStatusUncheckedUpdateInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateManyInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelSelectionsNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
};

export type ChannelStatusUncheckedUpdateManyWithoutStatusesInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput = {
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutChannelsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicSettingInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutDeletedByInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateManyMutationInput = {
  channelStatusAction?: Maybe<ChannelStatusAction>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput = {
  data: ChannelStatusUncheckedUpdateManyWithoutStatusesInput;
  where: ChannelStatusScalarWhereInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput = {
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
  where: ChannelStatusScalarWhereInput;
};

export type ChannelStatusUpdateManyWithWhereWithoutDeletedByInput = {
  data: ChannelStatusUncheckedUpdateManyWithoutChannelStatusesInput;
  where: ChannelStatusScalarWhereInput;
};

export type ChannelStatusUpdateManyWithoutChannelSelectionsNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutChannelSelectionsInput>>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
};

export type ChannelStatusUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<ChannelStatusCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type ChannelStatusUpdateManyWithoutDeletedByNestedInput = {
  connect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelStatusCreateOrConnectWithoutDeletedByInput>>;
  create?: Maybe<Array<ChannelStatusCreateWithoutDeletedByInput>>;
  createMany?: Maybe<ChannelStatusCreateManyDeletedByInputEnvelope>;
  delete?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChannelStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput>>;
  updateMany?: Maybe<Array<ChannelStatusUpdateManyWithWhereWithoutDeletedByInput>>;
  upsert?: Maybe<Array<ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput>>;
};

export type ChannelStatusUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput>;
};

export type ChannelStatusUpdateOneWithoutAutomationRunActionsNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutAutomationRunActionsInput>;
};

export type ChannelStatusUpdateOneWithoutBoardFilterColumnsNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutBoardFilterColumnsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutBoardFilterColumnsInput>;
};

export type ChannelStatusUpdateOneWithoutChannelsNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutChannelsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutChannelsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutChannelsInput>;
};

export type ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutClinicWidgetRequestTypeInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput>;
};

export type ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutResponseWorkflowEventActionsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutResponseWorkflowEventActionsInput>;
};

export type ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventActionInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutWorkflowEventActionInput>;
};

export type ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  connect?: Maybe<ChannelStatusWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelStatusCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<ChannelStatusUpsertWithoutWorkflowEventSettingsInput>;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  data: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutClinicSettingInput = {
  data: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpdateWithWhereUniqueWithoutDeletedByInput = {
  data: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpdateWithoutAutomationRunActionsInput = {
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutBoardFilterColumnsInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelSelectionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelStatusChannelAssigneesInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutChannelsInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutClinicSettingInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutClinicWidgetRequestTypeInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutDeletedByInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutResponseWorkflowEventActionsInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutWorkflowEventActionInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpdateWithoutWorkflowEventSettingsInput = {
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutChannelStatusChangeNestedInput>;
  boardFilterColumns?: Maybe<BoardFilterColumnUpdateManyWithoutChannelStatusNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutStatusesNestedInput>;
  channelStatusAction?: Maybe<ChannelStatusAction>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutChannelStatusNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutChannelStatusNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutChannelStatusesNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<UserUpdateOneWithoutChannelStatusesNestedInput>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isExportedToPims?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventAction?: Maybe<WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput>;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelSelectionsInput;
  update: ChannelStatusUncheckedUpdateWithoutChannelSelectionsInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutClinicSettingInput = {
  create: ChannelStatusUncheckedCreateWithoutClinicSettingInput;
  update: ChannelStatusUncheckedUpdateWithoutClinicSettingInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpsertWithWhereUniqueWithoutDeletedByInput = {
  create: ChannelStatusUncheckedCreateWithoutDeletedByInput;
  update: ChannelStatusUncheckedUpdateWithoutDeletedByInput;
  where: ChannelStatusWhereUniqueInput;
};

export type ChannelStatusUpsertWithoutAutomationRunActionsInput = {
  create: ChannelStatusUncheckedCreateWithoutAutomationRunActionsInput;
  update: ChannelStatusUncheckedUpdateWithoutAutomationRunActionsInput;
};

export type ChannelStatusUpsertWithoutBoardFilterColumnsInput = {
  create: ChannelStatusUncheckedCreateWithoutBoardFilterColumnsInput;
  update: ChannelStatusUncheckedUpdateWithoutBoardFilterColumnsInput;
};

export type ChannelStatusUpsertWithoutChannelStatusChannelAssigneesInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  update: ChannelStatusUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
};

export type ChannelStatusUpsertWithoutChannelsInput = {
  create: ChannelStatusUncheckedCreateWithoutChannelsInput;
  update: ChannelStatusUncheckedUpdateWithoutChannelsInput;
};

export type ChannelStatusUpsertWithoutClinicWidgetRequestTypeInput = {
  create: ChannelStatusUncheckedCreateWithoutClinicWidgetRequestTypeInput;
  update: ChannelStatusUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
};

export type ChannelStatusUpsertWithoutResponseWorkflowEventActionsInput = {
  create: ChannelStatusUncheckedCreateWithoutResponseWorkflowEventActionsInput;
  update: ChannelStatusUncheckedUpdateWithoutResponseWorkflowEventActionsInput;
};

export type ChannelStatusUpsertWithoutWorkflowEventActionInput = {
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventActionInput;
  update: ChannelStatusUncheckedUpdateWithoutWorkflowEventActionInput;
};

export type ChannelStatusUpsertWithoutWorkflowEventSettingsInput = {
  create: ChannelStatusUncheckedCreateWithoutWorkflowEventSettingsInput;
  update: ChannelStatusUncheckedUpdateWithoutWorkflowEventSettingsInput;
};

export type ChannelStatusWhereInput = {
  AND?: Maybe<Array<ChannelStatusWhereInput>>;
  NOT?: Maybe<Array<ChannelStatusWhereInput>>;
  OR?: Maybe<Array<ChannelStatusWhereInput>>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  boardFilterColumns?: Maybe<BoardFilterColumnListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  color?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedBy?: Maybe<UserWhereInput>;
  deletedById?: Maybe<StringNullableFilter>;
  deletedOn?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolNullableFilter>;
  isExportedToPims?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  responseWorkflowEventActions?: Maybe<WorkflowEventActionListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventAction?: Maybe<WorkflowEventActionListRelationFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type ChannelStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChannelStatusWithOrder = {
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type ChannelStatusWithOrderResponse = {
  __typename?: 'ChannelStatusWithOrderResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ChannelSubscription = {
  __typename?: 'ChannelSubscription';
  mutation: MutationType;
  node: Channel;
};

export type ChannelSuggestedMessageResponse = {
  __typename?: 'ChannelSuggestedMessageResponse';
  parsedJson?: Maybe<Scalars['Json']>;
  response: Scalars['String'];
};

export type ChannelSuggestedMessageWhereInput = {
  channelId: Scalars['String'];
};

export type ChannelSyncedToSearchWhereInput = {
  channelType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum ChannelType {
  Client = 'Client',
  Team = 'Team'
}

export type ChannelUncheckedCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
};

export type ChannelUncheckedCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
};

export type ChannelUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
};

export type ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
};

export type ChannelUncheckedCreateNestedManyWithoutLastMessageInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
};

export type ChannelUncheckedCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
};

export type ChannelUncheckedCreateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutAssigneesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutChannelMembersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutChannelPinsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutChannelStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutCreationSourceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutCurrentWorkflowActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutFinancialTransactionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutHillsToHomeApiLogInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutLastMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutMessagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutChannelsInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedCreateWithoutPetsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutChannelInput>;
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutChannelsInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutChannelInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutChannelInput>;
  channelPins?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutChannelInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedCreateNestedOneWithoutChannelInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutChannelInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ChannelUncheckedUpdateManyWithoutChannelsInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageChannelInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateManyWithoutLastMessageNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
};

export type ChannelUncheckedUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ChannelUncheckedUpdateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutAssigneesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutChannelMembersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutChannelPinsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutChannelStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutCreationSourceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutFinancialTransactionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutLastMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutMessagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUncheckedUpdateWithoutPetsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUncheckedUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUncheckedUpdateManyWithoutChannelNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUncheckedUpdateOneWithoutChannelNestedInput>;
  currentWorkflowActionId?: Maybe<Scalars['String']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessageId?: Maybe<Scalars['String']>;
  messages?: Maybe<ChannelMessageUncheckedUpdateManyWithoutChannelNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUnreadMessageCountResult = {
  __typename?: 'ChannelUnreadMessageCountResult';
  client?: Maybe<ChannelCountResult>;
  team?: Maybe<ChannelCountResult>;
  total: Scalars['Int'];
  unreadChannels: Array<UnreadMessageChannel>;
};

export type ChannelUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateManyMutationInput = {
  attributes?: Maybe<Scalars['Json']>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateManyWithWhereWithoutAssigneesInput = {
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithWhereWithoutChannelStatusInput = {
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithWhereWithoutClinicInput = {
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput = {
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithWhereWithoutLastMessageInput = {
  data: ChannelUncheckedUpdateManyWithoutLastMessageChannelInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithWhereWithoutPetsInput = {
  data: ChannelUncheckedUpdateManyWithoutChannelsInput;
  where: ChannelScalarWhereInput;
};

export type ChannelUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ChannelCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ChannelUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ChannelCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ChannelCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ChannelUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ChannelCreateWithoutClinicInput>>;
  createMany?: Maybe<ChannelCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutCurrentWorkflowActionInput>>;
  create?: Maybe<Array<ChannelCreateWithoutCurrentWorkflowActionInput>>;
  createMany?: Maybe<ChannelCreateManyCurrentWorkflowActionInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutCurrentWorkflowActionInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput>>;
};

export type ChannelUpdateManyWithoutLastMessageNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutLastMessageInput>>;
  create?: Maybe<Array<ChannelCreateWithoutLastMessageInput>>;
  createMany?: Maybe<ChannelCreateManyLastMessageInputEnvelope>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutLastMessageInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutLastMessageInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutLastMessageInput>>;
};

export type ChannelUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ChannelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChannelCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ChannelCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ChannelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChannelScalarWhereInput>>;
  disconnect?: Maybe<Array<ChannelWhereUniqueInput>>;
  set?: Maybe<Array<ChannelWhereUniqueInput>>;
  update?: Maybe<Array<ChannelUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ChannelUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ChannelUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ChannelUpdateOneRequiredWithoutAutomationStatusesNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAutomationStatusesInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutAutomationStatusesInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAutomationStatusesInput>;
  upsert?: Maybe<ChannelUpsertWithoutAutomationStatusesInput>;
};

export type ChannelUpdateOneRequiredWithoutCreationSourceNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutCreationSourceInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutCreationSourceInput>;
  update?: Maybe<ChannelUncheckedUpdateWithoutCreationSourceInput>;
  upsert?: Maybe<ChannelUpsertWithoutCreationSourceInput>;
};

export type ChannelUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ChannelUpsertWithoutAppointmentsInput>;
};

export type ChannelUpdateOneWithoutChannelMembersNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutChannelMembersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutChannelMembersInput>;
  upsert?: Maybe<ChannelUpsertWithoutChannelMembersInput>;
};

export type ChannelUpdateOneWithoutChannelPinsNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutChannelPinsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutChannelPinsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutChannelPinsInput>;
  upsert?: Maybe<ChannelUpsertWithoutChannelPinsInput>;
};

export type ChannelUpdateOneWithoutFinancialTransactionsNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutFinancialTransactionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ChannelUpsertWithoutFinancialTransactionsInput>;
};

export type ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutHillsToHomeApiLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ChannelUpsertWithoutHillsToHomeApiLogInput>;
};

export type ChannelUpdateOneWithoutMessagesNestedInput = {
  connect?: Maybe<ChannelWhereUniqueInput>;
  connectOrCreate?: Maybe<ChannelCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChannelUncheckedCreateWithoutMessagesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChannelUncheckedUpdateWithoutMessagesInput>;
  upsert?: Maybe<ChannelUpsertWithoutMessagesInput>;
};

export type ChannelUpdateWithWhereUniqueWithoutAssigneesInput = {
  data: ChannelUncheckedUpdateWithoutAssigneesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithWhereUniqueWithoutChannelStatusInput = {
  data: ChannelUncheckedUpdateWithoutChannelStatusInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithWhereUniqueWithoutClinicInput = {
  data: ChannelUncheckedUpdateWithoutClinicInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  data: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithWhereUniqueWithoutLastMessageInput = {
  data: ChannelUncheckedUpdateWithoutLastMessageInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithWhereUniqueWithoutPetsInput = {
  data: ChannelUncheckedUpdateWithoutPetsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpdateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutAssigneesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutChannelMembersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutChannelPinsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutChannelStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutCreationSourceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutCurrentWorkflowActionInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutFinancialTransactionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutHillsToHomeApiLogInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutLastMessageInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutMessagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutChannelsNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutPetsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutChannelNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutChannelNestedInput>;
  assignees?: Maybe<UserUpdateManyWithoutChannelsNestedInput>;
  attributes?: Maybe<Scalars['Json']>;
  automationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutChannelNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutChannelNestedInput>;
  channelPins?: Maybe<ChannelPinUpdateManyWithoutChannelNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutChannelsNestedInput>;
  channelType?: Maybe<ChannelType>;
  channelVisibility?: Maybe<ChannelVisibility>;
  clinic?: Maybe<ClinicUpdateOneWithoutChannelsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ChannelCreationSourceUpdateOneWithoutChannelNestedInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionUpdateOneWithoutChannelsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutChannelNestedInput>;
  friendlyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastExportAt?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<ChannelMessageUpdateOneWithoutLastMessageChannelNestedInput>;
  messages?: Maybe<ChannelMessageUpdateManyWithoutChannelNestedInput>;
  tags?: Maybe<Scalars['Json']>;
  twilioChannelSid?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelUpsertWithWhereUniqueWithoutAssigneesInput = {
  create: ChannelUncheckedCreateWithoutAssigneesInput;
  update: ChannelUncheckedUpdateWithoutAssigneesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithWhereUniqueWithoutChannelStatusInput = {
  create: ChannelUncheckedCreateWithoutChannelStatusInput;
  update: ChannelUncheckedUpdateWithoutChannelStatusInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithWhereUniqueWithoutClinicInput = {
  create: ChannelUncheckedCreateWithoutClinicInput;
  update: ChannelUncheckedUpdateWithoutClinicInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithWhereUniqueWithoutCurrentWorkflowActionInput = {
  create: ChannelUncheckedCreateWithoutCurrentWorkflowActionInput;
  update: ChannelUncheckedUpdateWithoutCurrentWorkflowActionInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithWhereUniqueWithoutLastMessageInput = {
  create: ChannelUncheckedCreateWithoutLastMessageInput;
  update: ChannelUncheckedUpdateWithoutLastMessageInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithWhereUniqueWithoutPetsInput = {
  create: ChannelUncheckedCreateWithoutPetsInput;
  update: ChannelUncheckedUpdateWithoutPetsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelUpsertWithoutAppointmentsInput = {
  create: ChannelUncheckedCreateWithoutAppointmentsInput;
  update: ChannelUncheckedUpdateWithoutAppointmentsInput;
};

export type ChannelUpsertWithoutAutomationStatusesInput = {
  create: ChannelUncheckedCreateWithoutAutomationStatusesInput;
  update: ChannelUncheckedUpdateWithoutAutomationStatusesInput;
};

export type ChannelUpsertWithoutChannelMembersInput = {
  create: ChannelUncheckedCreateWithoutChannelMembersInput;
  update: ChannelUncheckedUpdateWithoutChannelMembersInput;
};

export type ChannelUpsertWithoutChannelPinsInput = {
  create: ChannelUncheckedCreateWithoutChannelPinsInput;
  update: ChannelUncheckedUpdateWithoutChannelPinsInput;
};

export type ChannelUpsertWithoutCreationSourceInput = {
  create: ChannelUncheckedCreateWithoutCreationSourceInput;
  update: ChannelUncheckedUpdateWithoutCreationSourceInput;
};

export type ChannelUpsertWithoutFinancialTransactionsInput = {
  create: ChannelUncheckedCreateWithoutFinancialTransactionsInput;
  update: ChannelUncheckedUpdateWithoutFinancialTransactionsInput;
};

export type ChannelUpsertWithoutHillsToHomeApiLogInput = {
  create: ChannelUncheckedCreateWithoutHillsToHomeApiLogInput;
  update: ChannelUncheckedUpdateWithoutHillsToHomeApiLogInput;
};

export type ChannelUpsertWithoutMessagesInput = {
  create: ChannelUncheckedCreateWithoutMessagesInput;
  update: ChannelUncheckedUpdateWithoutMessagesInput;
};

export enum ChannelVisibility {
  DirectMessage = 'DirectMessage',
  Private = 'Private',
  Public = 'Public'
}

export type ChannelWhereInput = {
  AND?: Maybe<Array<ChannelWhereInput>>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  NOT?: Maybe<Array<ChannelWhereInput>>;
  OR?: Maybe<Array<ChannelWhereInput>>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  assignees?: Maybe<UserListRelationFilter>;
  attributes?: Maybe<JsonNullableFilter>;
  automationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  channelPins?: Maybe<ChannelPinListRelationFilter>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  channelStatusId?: Maybe<StringNullableFilter>;
  channelType?: Maybe<EnumChannelTypeNullableFilter>;
  channelVisibility?: Maybe<EnumChannelVisibilityNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<ChannelCreationSourceWhereInput>;
  currentWorkflowAction?: Maybe<WorkflowEventActionWhereInput>;
  currentWorkflowActionId?: Maybe<StringNullableFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  friendlyName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastExportAt?: Maybe<DateTimeNullableFilter>;
  lastMessage?: Maybe<ChannelMessageWhereInput>;
  lastMessageId?: Maybe<StringNullableFilter>;
  messages?: Maybe<ChannelMessageListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  tags?: Maybe<JsonNullableFilter>;
  twilioChannelSid?: Maybe<StringNullableFilter>;
  uniqueName?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChannelWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ChargeCaptureType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export type CheckClinicCareInput = {
  clinicId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type CheckClinicCareStatusResult = {
  __typename?: 'CheckClinicCareStatusResult';
  idClinic?: Maybe<CareClinicInfo>;
  tokenClinic?: Maybe<CareClinicInfo>;
};

export type CheckPhoneVerificationInput = {
  id: Scalars['String'];
};

export type ChemicalCompound = {
  __typename?: 'ChemicalCompound';
  _count: ChemicalCompoundCountOutputType;
  activeIngredients: Array<ActiveIngredient>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ChemicalCompoundActiveIngredientsArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  distinct?: Maybe<ActiveIngredientScalarFieldEnum>;
  orderBy?: Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};

export type ChemicalCompoundCountAggregateOutputType = {
  __typename?: 'ChemicalCompoundCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  formula: Scalars['Int'];
  id: Scalars['Int'];
  iupacName: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ChemicalCompoundCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChemicalCompoundCountOutputType = {
  __typename?: 'ChemicalCompoundCountOutputType';
  activeIngredients: Scalars['Int'];
};

export type ChemicalCompoundCreateInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutChemicalCompoundInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundCreateNestedOneWithoutActiveIngredientsInput = {
  connect?: Maybe<ChemicalCompoundWhereUniqueInput>;
  connectOrCreate?: Maybe<ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput>;
  create?: Maybe<ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput>;
};

export type ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput = {
  create: ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput;
  where: ChemicalCompoundWhereUniqueInput;
};

export type ChemicalCompoundCreateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundMaxAggregateOutputType = {
  __typename?: 'ChemicalCompoundMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChemicalCompoundMinAggregateOutputType = {
  __typename?: 'ChemicalCompoundMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ChemicalCompoundOrderByRelevanceFieldEnum {
  Description = 'description',
  Formula = 'formula',
  Id = 'id',
  IupacName = 'iupacName',
  Name = 'name'
}

export type ChemicalCompoundOrderByRelevanceInput = {
  fields: Array<ChemicalCompoundOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ChemicalCompoundOrderByWithAggregationInput = {
  _count?: Maybe<ChemicalCompoundCountOrderByAggregateInput>;
  _max?: Maybe<ChemicalCompoundMaxOrderByAggregateInput>;
  _min?: Maybe<ChemicalCompoundMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ChemicalCompoundOrderByRelevanceInput>;
  activeIngredients?: Maybe<ActiveIngredientOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  iupacName?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChemicalCompoundRelationFilter = {
  is?: Maybe<ChemicalCompoundWhereInput>;
  isNot?: Maybe<ChemicalCompoundWhereInput>;
};

export enum ChemicalCompoundScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Formula = 'formula',
  Id = 'id',
  IupacName = 'iupacName',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ChemicalCompoundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ChemicalCompoundScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  formula?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  iupacName?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ChemicalCompoundUncheckedCreateInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutChemicalCompoundInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUncheckedUpdateInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutChemicalCompoundNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUpdateInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutChemicalCompoundNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUpdateOneRequiredWithoutActiveIngredientsNestedInput = {
  connect?: Maybe<ChemicalCompoundWhereUniqueInput>;
  connectOrCreate?: Maybe<ChemicalCompoundCreateOrConnectWithoutActiveIngredientsInput>;
  create?: Maybe<ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput>;
  update?: Maybe<ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput>;
  upsert?: Maybe<ChemicalCompoundUpsertWithoutActiveIngredientsInput>;
};

export type ChemicalCompoundUpdateWithoutActiveIngredientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iupacName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChemicalCompoundUpsertWithoutActiveIngredientsInput = {
  create: ChemicalCompoundUncheckedCreateWithoutActiveIngredientsInput;
  update: ChemicalCompoundUncheckedUpdateWithoutActiveIngredientsInput;
};

export type ChemicalCompoundWhereInput = {
  AND?: Maybe<Array<ChemicalCompoundWhereInput>>;
  NOT?: Maybe<Array<ChemicalCompoundWhereInput>>;
  OR?: Maybe<Array<ChemicalCompoundWhereInput>>;
  activeIngredients?: Maybe<ActiveIngredientListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  formula?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  iupacName?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChemicalCompoundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChurnZeroResponse = {
  __typename?: 'ChurnZeroResponse';
  authToken: Scalars['String'];
};

export type ClaimClinicPetParentAuthCodeRequest = {
  otc: Scalars['String'];
  requestId: Scalars['String'];
};

export type ClaimClinicPetParentAuthCodeResponse = {
  __typename?: 'ClaimClinicPetParentAuthCodeResponse';
  message: Scalars['String'];
  payload?: Maybe<Scalars['Json']>;
  success: Scalars['Boolean'];
};

export type ClearTerminalDisplayResponse = {
  __typename?: 'ClearTerminalDisplayResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  terminal: StripeTerminal;
};

export type ClearUnreadMessagesInput = {
  clinicId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export enum ClientConnectClinicSoftware {
  Avimark = 'AVIMARK',
  Clientrax = 'CLIENTRAX',
  Cornerstone = 'CORNERSTONE',
  CornerstoneCloud = 'CORNERSTONE_CLOUD',
  CovetrusPulse = 'COVETRUS_PULSE',
  Daysmart = 'DAYSMART',
  Dvmax = 'DVMAX',
  DvmManager = 'DVM_MANAGER',
  Ezyvet = 'EZYVET',
  Hippo = 'HIPPO',
  ImpromedAdvantage = 'IMPROMED_ADVANTAGE',
  ImpromedInfinity = 'IMPROMED_INFINITY',
  Infinity = 'INFINITY',
  Intravet = 'INTRAVET',
  Intravetsql = 'INTRAVETSQL',
  Neo = 'NEO',
  Pulse = 'PULSE',
  Rhapsody = 'RHAPSODY',
  ShepherdVeterinarySoftware = 'SHEPHERD_VETERINARY_SOFTWARE',
  Unknown = 'UNKNOWN',
  Unsupported = 'UNSUPPORTED',
  VetechAdvantage = 'VETECH_ADVANTAGE',
  Vetspire = 'VETSPIRE',
  Via = 'VIA',
  VtechPlatinum = 'VTECH_PLATINUM'
}

export type ClientConnectIntegration = {
  __typename?: 'ClientConnectIntegration';
  clientConnectSystem: ClientConnectSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  clinicSoftware: ClientConnectClinicSoftware;
  createdAt: Scalars['DateTime'];
  erpId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isMultiSite: Scalars['Boolean'];
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClientConnectIntegrationCountAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  clientConnectSystem: Scalars['Int'];
  clinicSoftware: Scalars['Int'];
  createdAt: Scalars['Int'];
  erpId: Scalars['Int'];
  id: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  locationNumber: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClientConnectIntegrationCountOrderByAggregateInput = {
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationCreateInput = {
  clientConnectSystem: ClientConnectSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutClientConnectInput>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationCreateManyInput = {
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: ClientConnectIntegrationWhereUniqueInput;
};

export type ClientConnectIntegrationCreateWithoutClinicPimsIntegrationInput = {
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationMaxAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationMaxAggregateOutputType';
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationMaxOrderByAggregateInput = {
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationMinAggregateOutputType = {
  __typename?: 'ClientConnectIntegrationMinAggregateOutputType';
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationMinOrderByAggregateInput = {
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClientConnectIntegrationOrderByRelevanceFieldEnum {
  ErpId = 'erpId',
  Id = 'id',
  LocationNumber = 'locationNumber'
}

export type ClientConnectIntegrationOrderByRelevanceInput = {
  fields: Array<ClientConnectIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClientConnectIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<ClientConnectIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<ClientConnectIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<ClientConnectIntegrationMinOrderByAggregateInput>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicSoftware?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClientConnectIntegrationOrderByRelevanceInput>;
  clientConnectSystem?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clinicSoftware?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  erpId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  locationNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClientConnectIntegrationRelationFilter = {
  is?: Maybe<ClientConnectIntegrationWhereInput>;
  isNot?: Maybe<ClientConnectIntegrationWhereInput>;
};

export enum ClientConnectIntegrationScalarFieldEnum {
  ClientConnectSystem = 'clientConnectSystem',
  ClinicSoftware = 'clinicSoftware',
  CreatedAt = 'createdAt',
  ErpId = 'erpId',
  Id = 'id',
  IsMultiSite = 'isMultiSite',
  LocationNumber = 'locationNumber',
  UpdatedAt = 'updatedAt'
}

export type ClientConnectIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClientConnectIntegrationScalarWhereWithAggregatesInput>>;
  clientConnectSystem?: Maybe<EnumClientConnectSystemWithAggregatesFilter>;
  clinicSoftware?: Maybe<EnumClientConnectClinicSoftwareWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  erpId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
  locationNumber?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClientConnectIntegrationUncheckedCreateInput = {
  clientConnectSystem: ClientConnectSystem;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutClientConnectInput>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  clientConnectSystem: ClientConnectSystem;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUncheckedUpdateInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutClientConnectNestedInput>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUncheckedUpdateManyInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUpdateInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutClientConnectNestedInput>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUpdateManyMutationInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientConnectIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<ClientConnectIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type ClientConnectIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  clientConnectSystem?: Maybe<ClientConnectSystem>;
  clinicSoftware?: Maybe<ClientConnectClinicSoftware>;
  createdAt?: Maybe<Scalars['DateTime']>;
  erpId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  locationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientConnectIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: ClientConnectIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: ClientConnectIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type ClientConnectIntegrationWhereInput = {
  AND?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  NOT?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  OR?: Maybe<Array<ClientConnectIntegrationWhereInput>>;
  clientConnectSystem?: Maybe<EnumClientConnectSystemFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  clinicSoftware?: Maybe<EnumClientConnectClinicSoftwareFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  erpId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  locationNumber?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClientConnectIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ClientConnectSystem {
  Allydvm = 'ALLYDVM',
  Idexx = 'IDEXX'
}

export type Clinic = {
  __typename?: 'Clinic';
  EmailCampaignLog: Array<EmailCampaignLog>;
  EmailCampaignUnsubscribe: Array<EmailCampaignUnsubscribe>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  Rules: Array<Rules>;
  _count: ClinicCountOutputType;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes: Array<AppointmentType>;
  appointmentAvailabilities: Array<AppointmentAvailability>;
  appointments: Array<Appointment>;
  /** @deprecated - 1.0 */
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  automaticSubscriptionReceipts: Scalars['Boolean'];
  automationRuns: Array<AutomationRun>;
  benefitsProvided: Array<CareBenefitUsage>;
  bitwerxHealthchecks: Array<BitwerxHealthcheck>;
  blockedPhoneNumbers: Array<ClinicBlockedPhoneNumber>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls: Array<CallHistory>;
  /** @deprecated - 1.0 */
  canceledAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  cancellationNote?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<Organization>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections: Array<ChannelFilterSelection>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  channels: Array<Channel>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings: Array<ClinicDirectBookingSetting>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings: Array<ClinicEmailCampaignSetting>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicOfficeHours: Array<ClinicOfficeHour>;
  clinicOnboardings: Array<ClinicOnboarding>;
  clinicPetParents: Array<ClinicPetParent>;
  clinicPets: Array<ClinicPet>;
  clinicPhoneNumber: Array<ClinicPhoneNumber>;
  clinicRooms: Array<ClinicRoom>;
  clinicSetting?: Maybe<ClinicSetting>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes: Array<ClinicWorkflowType>;
  /** @deprecated - 1.0 */
  consultationCost?: Maybe<Scalars['Float']>;
  /** @deprecated - 1.0 */
  consultationRequests: Array<ConsultationRequest>;
  /** @deprecated - 1.0 */
  consultations: Array<Consultation>;
  /** @deprecated - 1.0 */
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crmId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  crmUrlParams?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  emailCampaign: Array<EmailCampaign>;
  emailCampaignTemplate: Array<EmailCampaignTemplate>;
  featureFlags: Array<FeatureFlag>;
  financialTransactions: Array<FinancialTransaction>;
  firebaseId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  firebaseVets?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions: Array<FormSubmission>;
  formTemplates: Array<FormTemplate>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  headUserId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  hours?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** @deprecated - 1.0 */
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomer>;
  informPartnerProgramEnrollments: Array<InformPartnerProgramEnrollment>;
  insuranceIntegrations: Array<InsuranceIntegration>;
  /** @deprecated - 1.0 */
  integrationInfo?: Maybe<Scalars['Json']>;
  /** @deprecated - 1.0 */
  integrationSystem?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  integrationType?: Maybe<Scalars['String']>;
  integrations: Array<ClinicPimsIntegration>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive: Scalars['Boolean'];
  /** @deprecated - 1.0 */
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  loyaltyProgram?: Maybe<LoyaltyProgram>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  /** @deprecated - 1.0 */
  marketEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  marketFacebookPageId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts: Array<MassTextAlert>;
  media: Array<Media>;
  messageTemplates: Array<MessageTemplate>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentAddress?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentCity?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentName?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroup>;
  /** @deprecated - 1.0 */
  paymentState?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods: Array<PayoutBatchingPeriod>;
  /** @deprecated - 1.0 */
  pets: Array<Pet>;
  /** @deprecated - 1.0 */
  petsId: Array<Pet>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings: Array<ClinicPostcardSetting>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions: Array<Prescription>;
  /** @deprecated - 1.0 */
  printLastOrder?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  promoCodes: Array<PromoCode>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  responseTime?: Maybe<Scalars['Float']>;
  roles: Array<StaffRole>;
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff: Array<VetInfo>;
  staffId: Array<VetInfo>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripePayoutAccounts: Array<StripePayoutAccount>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations: Array<StripeTerminalLocation>;
  /** @deprecated - 1.0 */
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  submerchantId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  /** @deprecated - 1.0 */
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscribedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated - 1.0 */
  subscriptionId?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults: Array<SurveyResult>;
  /** @deprecated - 1.0 */
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfiguration>;
  twilioVerifications: Array<TwilioVerification>;
  unlayerSetting?: Maybe<UnlayerSetting>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  /** @deprecated - 1.0 */
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  userBoardFiltersColumns: Array<BoardFilterColumn>;
  users: Array<User>;
  usersId?: Maybe<User>;
  vaccinations: Array<Vaccination>;
  vetAvailabilities: Array<VetAvailability>;
  vetsourceInstallation?: Maybe<VetsourceInstallation>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings: Array<WorkflowEventSetting>;
};


export type ClinicEmailCampaignLogArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type ClinicEmailCampaignUnsubscribeArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type ClinicHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type ClinicRulesArgs = {
  cursor?: Maybe<RulesWhereUniqueInput>;
  distinct?: Maybe<RulesScalarFieldEnum>;
  orderBy?: Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RulesWhereInput>;
};


export type ClinicAppoinmentTypesArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type ClinicAppointmentAvailabilitiesArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type ClinicAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type ClinicBenefitsProvidedArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type ClinicBitwerxHealthchecksArgs = {
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  distinct?: Maybe<BitwerxHealthcheckScalarFieldEnum>;
  orderBy?: Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type ClinicBlockedPhoneNumbersArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type ClinicCallsArgs = {
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  distinct?: Maybe<CallHistoryScalarFieldEnum>;
  orderBy?: Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallHistoryWhereInput>;
};


export type ClinicChannelFilterSelectionsArgs = {
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<ChannelFilterSelectionScalarFieldEnum>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type ClinicChannelStatusChannelAssigneesArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type ClinicChannelsArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type ClinicClinicDirectBookingSettingsArgs = {
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicDirectBookingSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type ClinicClinicEmailCampaignSettingsArgs = {
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicEmailCampaignSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type ClinicClinicEmployeesArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type ClinicClinicOfficeHoursArgs = {
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  distinct?: Maybe<ClinicOfficeHourScalarFieldEnum>;
  orderBy?: Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type ClinicClinicOnboardingsArgs = {
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  distinct?: Maybe<ClinicOnboardingScalarFieldEnum>;
  orderBy?: Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type ClinicClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type ClinicClinicPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type ClinicClinicPhoneNumberArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type ClinicClinicRoomsArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type ClinicClinicWidgetRequestsArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type ClinicClinicWorkflowTypesArgs = {
  cursor?: Maybe<ClinicWorkflowTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWorkflowTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWorkflowTypeWhereInput>;
};


export type ClinicConsultationRequestsArgs = {
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  distinct?: Maybe<ConsultationRequestScalarFieldEnum>;
  orderBy?: Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type ClinicConsultationsArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type ClinicDirectBookingExclusionRulesArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type ClinicEmailCampaignArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type ClinicEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type ClinicFeatureFlagsArgs = {
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  distinct?: Maybe<FeatureFlagScalarFieldEnum>;
  orderBy?: Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type ClinicFinancialTransactionsArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type ClinicFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type ClinicFormTemplatesArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type ClinicInformPartnerProgramEnrollmentsArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type ClinicInsuranceIntegrationsArgs = {
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  distinct?: Maybe<InsuranceIntegrationScalarFieldEnum>;
  orderBy?: Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type ClinicIntegrationsArgs = {
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  distinct?: Maybe<ClinicPimsIntegrationScalarFieldEnum>;
  orderBy?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type ClinicLoyaltyPointDeltasArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type ClinicLoyaltyRewardRedemptionActionsArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type ClinicMassTextAlertsArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type ClinicMediaArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<MediaScalarFieldEnum>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type ClinicMessageTemplatesArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type ClinicPayoutBatchingPeriodsArgs = {
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  distinct?: Maybe<PayoutBatchingPeriodScalarFieldEnum>;
  orderBy?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type ClinicPetsArgs = {
  cursor?: Maybe<PetWhereUniqueInput>;
  distinct?: Maybe<PetScalarFieldEnum>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetWhereInput>;
};


export type ClinicPetsIdArgs = {
  cursor?: Maybe<PetWhereUniqueInput>;
  distinct?: Maybe<PetScalarFieldEnum>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetWhereInput>;
};


export type ClinicPostcardSettingsArgs = {
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicPostcardSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type ClinicPrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type ClinicPromoCodesArgs = {
  cursor?: Maybe<PromoCodeWhereUniqueInput>;
  distinct?: Maybe<PromoCodeScalarFieldEnum>;
  orderBy?: Maybe<PromoCodeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PromoCodeWhereInput>;
};


export type ClinicRolesArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type ClinicSentTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type ClinicStaffArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type ClinicStaffIdArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type ClinicStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type ClinicStripePayoutAccountsArgs = {
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  distinct?: Maybe<StripePayoutAccountScalarFieldEnum>;
  orderBy?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type ClinicStripeTerminalLocationsArgs = {
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  distinct?: Maybe<StripeTerminalLocationScalarFieldEnum>;
  orderBy?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type ClinicSurveyResultsArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type ClinicTwilioVerificationsArgs = {
  cursor?: Maybe<TwilioVerificationWhereUniqueInput>;
  distinct?: Maybe<TwilioVerificationScalarFieldEnum>;
  orderBy?: Maybe<TwilioVerificationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TwilioVerificationWhereInput>;
};


export type ClinicUserAppointmentGroupingsArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type ClinicUserBoardFiltersColumnsArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type ClinicUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type ClinicVaccinationsArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type ClinicVetAvailabilitiesArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type ClinicWorkflowEventSettingsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type ClinicAvgAggregateOutputType = {
  __typename?: 'ClinicAvgAggregateOutputType';
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  marketFacebookNumber?: Maybe<Scalars['Float']>;
  ppcLocationId?: Maybe<Scalars['Float']>;
  printReceivedQuantity?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  unlayerSettingId?: Maybe<Scalars['Float']>;
};

export type ClinicAvgOrderByAggregateInput = {
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumber = {
  __typename?: 'ClinicBlockedPhoneNumber';
  blockedBy?: Maybe<User>;
  blockedByUserId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedBy?: Maybe<User>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ClinicBlockedPhoneNumberClinicIdAndPhoneNumberCompoundUniqueInput = {
  clinicId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ClinicBlockedPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberCountAggregateOutputType';
  _all: Scalars['Int'];
  blockedByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  notes: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  unblockedByUserId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicBlockedPhoneNumberCountOrderByAggregateInput = {
  blockedByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberCreateInput = {
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateManyBlockedByInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyBlockedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateManyClinicInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateManyInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateManyUnblockedByInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope = {
  data: Array<ClinicBlockedPhoneNumberCreateManyUnblockedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
};

export type ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberCreateWithoutBlockedByInput = {
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateWithoutClinicInput = {
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedBy?: Maybe<UserCreateNestedOneWithoutUnblockedNumbersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput = {
  blockedBy?: Maybe<UserCreateNestedOneWithoutBlockedNumbersInput>;
  clinic: ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  none?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
  some?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};

export type ClinicBlockedPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberMaxAggregateOutputType';
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberMaxOrderByAggregateInput = {
  blockedByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicBlockedPhoneNumberMinAggregateOutputType';
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberMinOrderByAggregateInput = {
  blockedByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicBlockedPhoneNumberOrderByRelevanceFieldEnum {
  BlockedByUserId = 'blockedByUserId',
  ClinicId = 'clinicId',
  Id = 'id',
  Notes = 'notes',
  PhoneNumber = 'phoneNumber',
  UnblockedByUserId = 'unblockedByUserId'
}

export type ClinicBlockedPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicBlockedPhoneNumberOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicBlockedPhoneNumberOrderByWithAggregationInput = {
  _count?: Maybe<ClinicBlockedPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicBlockedPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicBlockedPhoneNumberMinOrderByAggregateInput>;
  blockedByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  unblockedByUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicBlockedPhoneNumberOrderByRelevanceInput>;
  blockedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  blockedByUserId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  unblockedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  unblockedByUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicBlockedPhoneNumberScalarFieldEnum {
  BlockedByUserId = 'blockedByUserId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Notes = 'notes',
  PhoneNumber = 'phoneNumber',
  UnblockedByUserId = 'unblockedByUserId',
  UpdatedAt = 'updatedAt'
}

export type ClinicBlockedPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  blockedByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringFilter>;
  unblockedByUserId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereWithAggregatesInput>>;
  blockedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  notes?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  unblockedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedNumbersInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedPhoneNumbersInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput>>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedNumbersInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedByUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput = {
  blockedByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpdateInput = {
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedNumbersInput;
  where: ClinicBlockedPhoneNumberScalarWhereInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedPhoneNumbersInput;
  where: ClinicBlockedPhoneNumberScalarWhereInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedNumbersInput;
  where: ClinicBlockedPhoneNumberScalarWhereInput;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutBlockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutBlockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyBlockedByInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutBlockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput>>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput = {
  connect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicBlockedPhoneNumberCreateOrConnectWithoutUnblockedByInput>>;
  create?: Maybe<Array<ClinicBlockedPhoneNumberCreateWithoutUnblockedByInput>>;
  createMany?: Maybe<ClinicBlockedPhoneNumberCreateManyUnblockedByInputEnvelope>;
  delete?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicBlockedPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicBlockedPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput>>;
  updateMany?: Maybe<Array<ClinicBlockedPhoneNumberUpdateManyWithWhereWithoutUnblockedByInput>>;
  upsert?: Maybe<Array<ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput>>;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutBlockedByInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberUpdateWithWhereUniqueWithoutUnblockedByInput = {
  data: ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberUpdateWithoutBlockedByInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpdateWithoutClinicInput = {
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  unblockedBy?: Maybe<UserUpdateOneWithoutUnblockedNumbersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpdateWithoutUnblockedByInput = {
  blockedBy?: Maybe<UserUpdateOneWithoutBlockedNumbersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutBlockedByInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutBlockedByInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutBlockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutClinicInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutClinicInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberUpsertWithWhereUniqueWithoutUnblockedByInput = {
  create: ClinicBlockedPhoneNumberUncheckedCreateWithoutUnblockedByInput;
  update: ClinicBlockedPhoneNumberUncheckedUpdateWithoutUnblockedByInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};

export type ClinicBlockedPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicBlockedPhoneNumberWhereInput>>;
  blockedBy?: Maybe<UserWhereInput>;
  blockedByUserId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringFilter>;
  unblockedBy?: Maybe<UserWhereInput>;
  unblockedByUserId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicBlockedPhoneNumberWhereUniqueInput = {
  clinicIdAndPhoneNumber?: Maybe<ClinicBlockedPhoneNumberClinicIdAndPhoneNumberCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicCancellationInput = {
  cancellationNote: Scalars['String'];
  cancellationReason: Scalars['String'];
};

export type ClinicContactInfo = {
  __typename?: 'ClinicContactInfo';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  emergencyClinics?: Maybe<Array<Maybe<EmergencyClinicContactInfo>>>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  fullAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type ClinicCountAggregateOutputType = {
  __typename?: 'ClinicCountAggregateOutputType';
  _all: Scalars['Int'];
  activationDate: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  allowAllUsersToCloseConsultations: Scalars['Int'];
  autoSubscriptionReceiptEmails: Scalars['Int'];
  automaticSubscriptionReceipts: Scalars['Int'];
  callerIdPhoneNumber: Scalars['Int'];
  canceledAt: Scalars['Int'];
  cancellationNote: Scalars['Int'];
  cancellationReason: Scalars['Int'];
  carePlanProviderGroupId: Scalars['Int'];
  city: Scalars['Int'];
  clinicEmail: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  consultationCost: Scalars['Int'];
  corporateId: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  crmId: Scalars['Int'];
  crmUrlParams: Scalars['Int'];
  customerId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  firebaseVets: Scalars['Int'];
  followUpCost: Scalars['Int'];
  fullAddress: Scalars['Int'];
  hasServiceReminders: Scalars['Int'];
  hasSmsConversations: Scalars['Int'];
  headUserId: Scalars['Int'];
  hours: Scalars['Int'];
  id: Scalars['Int'];
  incomeMethod: Scalars['Int'];
  integrationInfo: Scalars['Int'];
  integrationSystem: Scalars['Int'];
  integrationType: Scalars['Int'];
  isAddAppointmentDisabled: Scalars['Int'];
  isCallerIdVerified: Scalars['Int'];
  isClinicActive: Scalars['Int'];
  isConsultationsActive: Scalars['Int'];
  isIntegrationActive: Scalars['Int'];
  isPaymentUsBank: Scalars['Int'];
  isServiceRemindersActive: Scalars['Int'];
  lastPrintOrderedOn: Scalars['Int'];
  lastPrintOrderedQuantity: Scalars['Int'];
  lat: Scalars['Int'];
  legalEntityId: Scalars['Int'];
  logo: Scalars['Int'];
  long: Scalars['Int'];
  loyaltyProgramId: Scalars['Int'];
  marketEmail: Scalars['Int'];
  marketFacebookNumber: Scalars['Int'];
  marketFacebookPageId: Scalars['Int'];
  marketFacebookPageToken: Scalars['Int'];
  name: Scalars['Int'];
  optimizedLogoUrl: Scalars['Int'];
  organizationId: Scalars['Int'];
  paymentAddress: Scalars['Int'];
  paymentBankAccountNumber: Scalars['Int'];
  paymentBankRoutingNumber: Scalars['Int'];
  paymentCity: Scalars['Int'];
  paymentName: Scalars['Int'];
  paymentPhone: Scalars['Int'];
  paymentState: Scalars['Int'];
  paymentZip: Scalars['Int'];
  phone: Scalars['Int'];
  postalCode: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  printLastOrder: Scalars['Int'];
  printReceivedQuantity: Scalars['Int'];
  remindersIntegrationRequestEmail: Scalars['Int'];
  responseTime: Scalars['Int'];
  smsPhoneNumber: Scalars['Int'];
  state: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeSubscriptionId: Scalars['Int'];
  stripeSubscriptionPlan: Scalars['Int'];
  submerchantApprovedAt: Scalars['Int'];
  submerchantId: Scalars['Int'];
  submerchantIsActive: Scalars['Int'];
  submerchantStatus: Scalars['Int'];
  submerchantStripeChargesEnabled: Scalars['Int'];
  submerchantStripeHasCustomPayouts: Scalars['Int'];
  submerchantStripeId: Scalars['Int'];
  submerchantStripePayoutsEnabled: Scalars['Int'];
  submerchantStripeStatus: Scalars['Int'];
  subscribedAt: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  subscriptionPlan: Scalars['Int'];
  timezone: Scalars['Int'];
  timezoneName: Scalars['Int'];
  token: Scalars['Int'];
  unlayerSettingId: Scalars['Int'];
  unsubscribedAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  websiteUrl: Scalars['Int'];
  widgetSettings: Scalars['Int'];
};

export type ClinicCountOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
};

export type ClinicCountOutputType = {
  __typename?: 'ClinicCountOutputType';
  EmailCampaignLog: Scalars['Int'];
  EmailCampaignUnsubscribe: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
  Rules: Scalars['Int'];
  appoinmentTypes: Scalars['Int'];
  appointmentAvailabilities: Scalars['Int'];
  appointments: Scalars['Int'];
  automationRuns: Scalars['Int'];
  benefitsProvided: Scalars['Int'];
  bitwerxHealthchecks: Scalars['Int'];
  blockedPhoneNumbers: Scalars['Int'];
  calls: Scalars['Int'];
  channelFilterSelections: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  channels: Scalars['Int'];
  clinicDirectBookingSettings: Scalars['Int'];
  clinicEmailCampaignSettings: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicOfficeHours: Scalars['Int'];
  clinicOnboardings: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  clinicPets: Scalars['Int'];
  clinicPhoneNumber: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  clinicWorkflowTypes: Scalars['Int'];
  consultationRequests: Scalars['Int'];
  consultations: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  emailCampaign: Scalars['Int'];
  emailCampaignTemplate: Scalars['Int'];
  featureFlags: Scalars['Int'];
  financialTransactions: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  formTemplates: Scalars['Int'];
  informPartnerProgramEnrollments: Scalars['Int'];
  insuranceIntegrations: Scalars['Int'];
  integrations: Scalars['Int'];
  loyaltyPointDeltas: Scalars['Int'];
  loyaltyRewardRedemptionActions: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
  media: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  payoutBatchingPeriods: Scalars['Int'];
  pets: Scalars['Int'];
  petsId: Scalars['Int'];
  postcardSettings: Scalars['Int'];
  prescriptions: Scalars['Int'];
  promoCodes: Scalars['Int'];
  roles: Scalars['Int'];
  sentTrupanionExamDayOffers: Scalars['Int'];
  staff: Scalars['Int'];
  staffId: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripePayoutAccounts: Scalars['Int'];
  stripeTerminalLocations: Scalars['Int'];
  surveyResults: Scalars['Int'];
  twilioVerifications: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  userBoardFiltersColumns: Scalars['Int'];
  users: Scalars['Int'];
  vaccinations: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
};

export type ClinicCreateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateManyCarePlanProviderGroupInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyCarePlanProviderGroupInputEnvelope = {
  data: Array<ClinicCreateManyCarePlanProviderGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyClinicWidgetSettingInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyLegalEntityInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyLegalEntityInputEnvelope = {
  data: Array<ClinicCreateManyLegalEntityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyLoyaltyProgramInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyLoyaltyProgramInputEnvelope = {
  data: Array<ClinicCreateManyLoyaltyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyOrganizationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyOrganizationInputEnvelope = {
  data: Array<ClinicCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUnlayerSettingInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyUnlayerSettingInputEnvelope = {
  data: Array<ClinicCreateManyUnlayerSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateManyUsersIdInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicCreateManyUsersIdInputEnvelope = {
  data: Array<ClinicCreateManyUsersIdInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicCreateNestedManyWithoutCarePlanProviderGroupInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutClinicWidgetSettingInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutFeatureFlagsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
};

export type ClinicCreateNestedManyWithoutLegalEntityInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutLoyaltyProgramInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
};

export type ClinicCreateNestedManyWithoutStaffInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
};

export type ClinicCreateNestedManyWithoutStripePayoutAccountsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
};

export type ClinicCreateNestedManyWithoutUnlayerSettingInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutUsersIdInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
};

export type ClinicCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
};

export type ClinicCreateNestedOneWithoutAppoinmentTypesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
};

export type ClinicCreateNestedOneWithoutAppointmentAvailabilitiesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
};

export type ClinicCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
};

export type ClinicCreateNestedOneWithoutBenefitsProvidedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
};

export type ClinicCreateNestedOneWithoutBitwerxHealthchecksInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBitwerxHealthchecksInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBitwerxHealthchecksInput>;
};

export type ClinicCreateNestedOneWithoutBlockedPhoneNumbersInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput>;
};

export type ClinicCreateNestedOneWithoutCallsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
};

export type ClinicCreateNestedOneWithoutCarePayoutOrganizationInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
};

export type ClinicCreateNestedOneWithoutChannelFilterSelectionsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
};

export type ClinicCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
};

export type ClinicCreateNestedOneWithoutChannelsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
};

export type ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
};

export type ClinicCreateNestedOneWithoutClinicEmployeesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
};

export type ClinicCreateNestedOneWithoutClinicOfficeHoursInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
};

export type ClinicCreateNestedOneWithoutClinicOnboardingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOnboardingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOnboardingsInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetParentsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
};

export type ClinicCreateNestedOneWithoutClinicPetsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
};

export type ClinicCreateNestedOneWithoutClinicPhoneNumberInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
};

export type ClinicCreateNestedOneWithoutClinicRoomsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
};

export type ClinicCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
};

export type ClinicCreateNestedOneWithoutClinicWidgetRequestsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
};

export type ClinicCreateNestedOneWithoutClinicWorkflowTypesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
};

export type ClinicCreateNestedOneWithoutConsultationRequestsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
};

export type ClinicCreateNestedOneWithoutConsultationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
};

export type ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignLogInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignLogInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignLogInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignTemplateInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignTemplateInput>;
};

export type ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicCreateNestedOneWithoutFinancialTransactionsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
};

export type ClinicCreateNestedOneWithoutFormSubmissionsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
};

export type ClinicCreateNestedOneWithoutFormTemplatesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
};

export type ClinicCreateNestedOneWithoutHillsToHomeApiLogInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutHillsToHomeApiLogInput>;
};

export type ClinicCreateNestedOneWithoutInformPartnerCustomerInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerCustomerInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerCustomerInput>;
};

export type ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
};

export type ClinicCreateNestedOneWithoutInsuranceIntegrationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInsuranceIntegrationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInsuranceIntegrationsInput>;
};

export type ClinicCreateNestedOneWithoutIntegrationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
};

export type ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput>;
};

export type ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type ClinicCreateNestedOneWithoutMassTextAlertsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
};

export type ClinicCreateNestedOneWithoutMediaInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMediaInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMediaInput>;
};

export type ClinicCreateNestedOneWithoutMessageTemplatesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
};

export type ClinicCreateNestedOneWithoutPaymentProcessorForProviderGroupInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput>;
};

export type ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput>;
};

export type ClinicCreateNestedOneWithoutPetsIdInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
};

export type ClinicCreateNestedOneWithoutPostcardSettingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
};

export type ClinicCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
};

export type ClinicCreateNestedOneWithoutPromoCodesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
};

export type ClinicCreateNestedOneWithoutRolesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
};

export type ClinicCreateNestedOneWithoutRulesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
};

export type ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
};

export type ClinicCreateNestedOneWithoutStaffIdInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
};

export type ClinicCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
};

export type ClinicCreateNestedOneWithoutStripeTerminalLocationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
};

export type ClinicCreateNestedOneWithoutSurveyResultsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutSurveyResultsInput>;
};

export type ClinicCreateNestedOneWithoutTwilioConfigurationInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
};

export type ClinicCreateNestedOneWithoutTwilioVerificationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
};

export type ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
};

export type ClinicCreateNestedOneWithoutUserBoardFiltersColumnsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput>;
};

export type ClinicCreateNestedOneWithoutVaccinationsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
};

export type ClinicCreateNestedOneWithoutVetAvailabilitiesInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
};

export type ClinicCreateNestedOneWithoutVetsourceInstallationInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetsourceInstallationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVetsourceInstallationInput>;
};

export type ClinicCreateNestedOneWithoutWorkflowEventSettingsInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
};

export type ClinicCreateOrConnectWithoutAppoinmentTypesInput = {
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput = {
  create: ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutAutomationRunsInput = {
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutBenefitsProvidedInput = {
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutBitwerxHealthchecksInput = {
  create: ClinicUncheckedCreateWithoutBitwerxHealthchecksInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput = {
  create: ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutCallsInput = {
  create: ClinicUncheckedCreateWithoutCallsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutCarePayoutOrganizationInput = {
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutCarePlanProviderGroupInput = {
  create: ClinicUncheckedCreateWithoutCarePlanProviderGroupInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutChannelFilterSelectionsInput = {
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutChannelsInput = {
  create: ClinicUncheckedCreateWithoutChannelsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput = {
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput = {
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicEmployeesInput = {
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicOfficeHoursInput = {
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicOnboardingsInput = {
  create: ClinicUncheckedCreateWithoutClinicOnboardingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicPetParentsInput = {
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicPetsInput = {
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicPhoneNumberInput = {
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicRoomsInput = {
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicSettingInput = {
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetRequestsInput = {
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicWidgetSettingInput = {
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutClinicWorkflowTypesInput = {
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutConsultationRequestsInput = {
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutConsultationsInput = {
  create: ClinicUncheckedCreateWithoutConsultationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  create: ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignLogInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignLogInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignTemplateInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignTemplateInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutFeatureFlagsInput = {
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutFinancialTransactionsInput = {
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutFormSubmissionsInput = {
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutFormTemplatesInput = {
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutHillsToHomeApiLogInput = {
  create: ClinicUncheckedCreateWithoutHillsToHomeApiLogInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutInformPartnerCustomerInput = {
  create: ClinicUncheckedCreateWithoutInformPartnerCustomerInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput = {
  create: ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutInsuranceIntegrationsInput = {
  create: ClinicUncheckedCreateWithoutInsuranceIntegrationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutIntegrationsInput = {
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutLegalEntityInput = {
  create: ClinicUncheckedCreateWithoutLegalEntityInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyProgramInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyProgramInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutMassTextAlertsInput = {
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutMediaInput = {
  create: ClinicUncheckedCreateWithoutMediaInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutMessageTemplatesInput = {
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutOrganizationInput = {
  create: ClinicUncheckedCreateWithoutOrganizationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput = {
  create: ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput = {
  create: ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPetsIdInput = {
  create: ClinicUncheckedCreateWithoutPetsIdInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPetsInput = {
  create: ClinicUncheckedCreateWithoutPetsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPostcardSettingsInput = {
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPrescriptionsInput = {
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutPromoCodesInput = {
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutRolesInput = {
  create: ClinicUncheckedCreateWithoutRolesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutRulesInput = {
  create: ClinicUncheckedCreateWithoutRulesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  create: ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutStaffIdInput = {
  create: ClinicUncheckedCreateWithoutStaffIdInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutStaffInput = {
  create: ClinicUncheckedCreateWithoutStaffInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutStripePayoutAccountsInput = {
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutStripeTerminalLocationsInput = {
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutSurveyResultsInput = {
  create: ClinicUncheckedCreateWithoutSurveyResultsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutTwilioConfigurationInput = {
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutTwilioVerificationsInput = {
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutUnlayerSettingInput = {
  create: ClinicUncheckedCreateWithoutUnlayerSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput = {
  create: ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutUsersIdInput = {
  create: ClinicUncheckedCreateWithoutUsersIdInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutUsersInput = {
  create: ClinicUncheckedCreateWithoutUsersInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutVaccinationsInput = {
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutVetAvailabilitiesInput = {
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutVetsourceInstallationInput = {
  create: ClinicUncheckedCreateWithoutVetsourceInstallationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateOrConnectWithoutWorkflowEventSettingsInput = {
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicCreateWithoutAppoinmentTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutAppointmentAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutBenefitsProvidedInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutBitwerxHealthchecksInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutBlockedPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutCarePayoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutCarePlanProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelFilterSelectionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutChannelsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicDirectBookingSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmailCampaignSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicEmployeesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicOfficeHoursInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicOnboardingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetParentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicPhoneNumberInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicRoomsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWidgetSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutClinicWorkflowTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutConsultationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutDirectBookingExclusionRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignTemplateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFeatureFlagsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFinancialTransactionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutFormTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutInformPartnerCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutInformPartnerProgramEnrollmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutInsuranceIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutLegalEntityInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyPointDeltasInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyProgramInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutMassTextAlertsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutMediaInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutMessageTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPaymentProcessorForProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPayoutBatchingPeriodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPostcardSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPrescriptionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutPromoCodesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutRolesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStaffInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripePayoutAccountsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutStripeTerminalLocationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioConfigurationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutTwilioVerificationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUnlayerSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUserAppointmentGroupingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUserBoardFiltersColumnsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutUsersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutVaccinationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutVetAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutVetsourceInstallationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutClinicInput>;
};

export type ClinicCreateWithoutWorkflowEventSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutParticipatingClinicsInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomCreateNestedManyWithoutClinicInput>;
  clinicSetting?: Maybe<ClinicSettingCreateNestedOneWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityCreateNestedOneWithoutClinicsInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutClinicsInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationCreateNestedManyWithoutClinicInput>;
  unlayerSetting?: Maybe<UnlayerSettingCreateNestedOneWithoutClinicsInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserCreateNestedManyWithoutClinicsInput>;
  usersId?: Maybe<UserCreateNestedOneWithoutClinicsIdInput>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicDirectBookingSetting = {
  __typename?: 'ClinicDirectBookingSetting';
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation: Scalars['Boolean'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  exclusionRulesEnabled: Scalars['Boolean'];
  id: Scalars['String'];
  isOneTimePasswordEnabled: Scalars['Boolean'];
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ClinicDirectBookingSettingAvgAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingAvgAggregateOutputType';
  appointmentIntervalInMinutes?: Maybe<Scalars['Float']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Float']>;
  bookingDeadlineInHours?: Maybe<Scalars['Float']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Float']>;
};

export type ClinicDirectBookingSettingAvgOrderByAggregateInput = {
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCountAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentIntervalInMinutes: Scalars['Int'];
  appointmentIntervalInMinutesDisplay: Scalars['Int'];
  bookingDeadlineInHours: Scalars['Int'];
  bookingNeedsConfirmation: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  exclusionRulesEnabled: Scalars['Int'];
  id: Scalars['Int'];
  isOneTimePasswordEnabled: Scalars['Int'];
  maxSchedulingRangeInMonths: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicDirectBookingSettingCountOrderByAggregateInput = {
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingCreateInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinic: ClinicCreateNestedOneWithoutClinicDirectBookingSettingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingCreateManyClinicInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicDirectBookingSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicDirectBookingSettingCreateManyInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
};

export type ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput = {
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type ClinicDirectBookingSettingCreateWithoutClinicInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingListRelationFilter = {
  every?: Maybe<ClinicDirectBookingSettingWhereInput>;
  none?: Maybe<ClinicDirectBookingSettingWhereInput>;
  some?: Maybe<ClinicDirectBookingSettingWhereInput>;
};

export type ClinicDirectBookingSettingMaxAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMaxAggregateOutputType';
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingMaxOrderByAggregateInput = {
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingMinAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingMinAggregateOutputType';
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingMinOrderByAggregateInput = {
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicDirectBookingSettingOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type ClinicDirectBookingSettingOrderByRelevanceInput = {
  fields: Array<ClinicDirectBookingSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicDirectBookingSettingOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicDirectBookingSettingAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicDirectBookingSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicDirectBookingSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicDirectBookingSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicDirectBookingSettingSumOrderByAggregateInput>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicDirectBookingSettingOrderByRelevanceInput>;
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  bookingNeedsConfirmation?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  exclusionRulesEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isOneTimePasswordEnabled?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicDirectBookingSettingScalarFieldEnum {
  AppointmentIntervalInMinutes = 'appointmentIntervalInMinutes',
  AppointmentIntervalInMinutesDisplay = 'appointmentIntervalInMinutesDisplay',
  BookingDeadlineInHours = 'bookingDeadlineInHours',
  BookingNeedsConfirmation = 'bookingNeedsConfirmation',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  ExclusionRulesEnabled = 'exclusionRulesEnabled',
  Id = 'id',
  IsOneTimePasswordEnabled = 'isOneTimePasswordEnabled',
  MaxSchedulingRangeInMonths = 'maxSchedulingRangeInMonths',
  UpdatedAt = 'updatedAt'
}

export type ClinicDirectBookingSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  appointmentIntervalInMinutes?: Maybe<IntNullableFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  exclusionRulesEnabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicDirectBookingSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingScalarWhereWithAggregatesInput>>;
  appointmentIntervalInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableWithAggregatesFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableWithAggregatesFilter>;
  bookingNeedsConfirmation?: Maybe<BoolWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  exclusionRulesEnabled?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolWithAggregatesFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicDirectBookingSettingSumAggregateOutputType = {
  __typename?: 'ClinicDirectBookingSettingSumAggregateOutputType';
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
};

export type ClinicDirectBookingSettingSumOrderByAggregateInput = {
  appointmentIntervalInMinutes?: Maybe<SortOrder>;
  appointmentIntervalInMinutesDisplay?: Maybe<SortOrder>;
  bookingDeadlineInHours?: Maybe<SortOrder>;
  maxSchedulingRangeInMonths?: Maybe<SortOrder>;
};

export type ClinicDirectBookingSettingUncheckedCreateInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
};

export type ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUpdateInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUpdateManyMutationInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicDirectBookingSettingsInput;
  where: ClinicDirectBookingSettingScalarWhereInput;
};

export type ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicDirectBookingSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicDirectBookingSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicDirectBookingSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicDirectBookingSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicDirectBookingSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicDirectBookingSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicDirectBookingSettingUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type ClinicDirectBookingSettingUpdateWithoutClinicInput = {
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicDirectBookingSettingUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicDirectBookingSettingUncheckedCreateWithoutClinicInput;
  update: ClinicDirectBookingSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};

export type ClinicDirectBookingSettingWhereInput = {
  AND?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  OR?: Maybe<Array<ClinicDirectBookingSettingWhereInput>>;
  appointmentIntervalInMinutes?: Maybe<IntNullableFilter>;
  appointmentIntervalInMinutesDisplay?: Maybe<IntNullableFilter>;
  bookingDeadlineInHours?: Maybe<IntNullableFilter>;
  bookingNeedsConfirmation?: Maybe<BoolFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  exclusionRulesEnabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  isOneTimePasswordEnabled?: Maybe<BoolFilter>;
  maxSchedulingRangeInMonths?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicDirectBookingSettingWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicDirectBookingSettingsInput = {
  clinicId: Scalars['String'];
};

export type ClinicDirectBookingSettingsResponse = {
  __typename?: 'ClinicDirectBookingSettingsResponse';
  appointmentIntervalInMinutes?: Maybe<Scalars['Int']>;
  appointmentIntervalInMinutesDisplay?: Maybe<Scalars['Int']>;
  bookingDeadlineInHours?: Maybe<Scalars['Int']>;
  bookingNeedsConfirmation?: Maybe<Scalars['Boolean']>;
  exclusionRulesEnabled?: Maybe<Scalars['Boolean']>;
  isOneTimePasswordEnabled?: Maybe<Scalars['Boolean']>;
  maxSchedulingRangeInMonths?: Maybe<Scalars['Int']>;
};

export type ClinicEmailCampaignSetting = {
  __typename?: 'ClinicEmailCampaignSetting';
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  contactListId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  accountId: Scalars['Int'];
  careUrlFieldId: Scalars['Int'];
  clinicId: Scalars['Int'];
  contactListId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  petSpeciesTypeFieldId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userGroupId: Scalars['Int'];
};

export type ClinicEmailCampaignSettingCountOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingCreateInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutClinicEmailCampaignSettingsInput;
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingCreateManyClinicInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmailCampaignSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmailCampaignSettingCreateManyInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
};

export type ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput = {
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type ClinicEmailCampaignSettingCreateWithoutClinicInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingListRelationFilter = {
  every?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  none?: Maybe<ClinicEmailCampaignSettingWhereInput>;
  some?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};

export type ClinicEmailCampaignSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMaxAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMaxOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmailCampaignSettingMinAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingMinOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmailCampaignSettingOrderByRelevanceFieldEnum {
  AccountId = 'accountId',
  CareUrlFieldId = 'careUrlFieldId',
  ClinicId = 'clinicId',
  ContactListId = 'contactListId',
  Id = 'id',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  UserGroupId = 'userGroupId'
}

export type ClinicEmailCampaignSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmailCampaignSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicEmailCampaignSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicEmailCampaignSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmailCampaignSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmailCampaignSettingMinOrderByAggregateInput>;
  accountId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
};

export type ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicEmailCampaignSettingOrderByRelevanceInput>;
  accountId?: Maybe<SortOrder>;
  careUrlFieldId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  contactListId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petSpeciesTypeFieldId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGroupId?: Maybe<SortOrder>;
};

export enum ClinicEmailCampaignSettingScalarFieldEnum {
  AccountId = 'accountId',
  CareUrlFieldId = 'careUrlFieldId',
  ClinicId = 'clinicId',
  ContactListId = 'contactListId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PetSpeciesTypeFieldId = 'petSpeciesTypeFieldId',
  UpdatedAt = 'updatedAt',
  UserGroupId = 'userGroupId'
}

export type ClinicEmailCampaignSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userGroupId?: Maybe<StringFilter>;
};

export type ClinicEmailCampaignSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereWithAggregatesInput>>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  careUrlFieldId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  contactListId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  petSpeciesTypeFieldId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userGroupId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmailCampaignSettingUncheckedCreateInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
};

export type ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput = {
  accountId: Scalars['String'];
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId: Scalars['String'];
};

export type ClinicEmailCampaignSettingUncheckedUpdateInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsNestedInput>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateManyMutationInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicEmailCampaignSettingsInput;
  where: ClinicEmailCampaignSettingScalarWhereInput;
};

export type ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmailCampaignSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmailCampaignSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmailCampaignSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmailCampaignSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmailCampaignSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmailCampaignSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicEmailCampaignSettingUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type ClinicEmailCampaignSettingUpdateWithoutClinicInput = {
  accountId?: Maybe<Scalars['String']>;
  careUrlFieldId?: Maybe<Scalars['String']>;
  contactListId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petSpeciesTypeFieldId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroupId?: Maybe<Scalars['String']>;
};

export type ClinicEmailCampaignSettingUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicEmailCampaignSettingUncheckedCreateWithoutClinicInput;
  update: ClinicEmailCampaignSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};

export type ClinicEmailCampaignSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmailCampaignSettingWhereInput>>;
  accountId?: Maybe<StringFilter>;
  careUrlFieldId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  contactListId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  petSpeciesTypeFieldId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userGroupId?: Maybe<StringFilter>;
};

export type ClinicEmailCampaignSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployee = {
  __typename?: 'ClinicEmployee';
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  _count: ClinicEmployeeCountOutputType;
  appointmentAvailabilities: Array<AppointmentAvailability>;
  appointments: Array<Appointment>;
  bitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  clinic?: Maybe<Clinic>;
  clinicEmployeeAppointmentTypeSettings: Array<ClinicEmployeeAppointmentTypeSetting>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  directBookingExclusionRules: Array<DirectBookingExclusionRule>;
  enteredByInvoiceLineItems: Array<InvoiceLineItem>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions: Array<Prescription>;
  providerInvoiceLineItems: Array<InvoiceLineItem>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
  workflows: Array<ClinicEmployeeToWorkflowEventSetting>;
};


export type ClinicEmployeeProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type ClinicEmployeeAppointmentAvailabilitiesArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type ClinicEmployeeAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicEmployeeBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type ClinicEmployeeClinicEmployeeAppointmentTypeSettingsArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type ClinicEmployeeDirectBookingExclusionRulesArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<DirectBookingExclusionRuleScalarFieldEnum>;
  orderBy?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type ClinicEmployeeEnteredByInvoiceLineItemsArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type ClinicEmployeePrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type ClinicEmployeeProviderInvoiceLineItemsArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type ClinicEmployeeUserAppointmentGroupingsArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type ClinicEmployeeVetAvailabilityMapsArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type ClinicEmployeeWorkflowsArgs = {
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSetting = {
  __typename?: 'ClinicEmployeeAppointmentTypeSetting';
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['String'];
  clinicEmployee: ClinicEmployee;
  clinicEmployeeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateManyInput = {
  appointmentTypeId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput = {
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  none?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
  some?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMaxAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeAppointmentTypeSettingMinAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  ClinicEmployeeId = 'clinicEmployeeId',
  Id = 'id'
}

export type ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicEmployeeAppointmentTypeSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeAppointmentTypeSettingMinOrderByAggregateInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicEmployeeAppointmentTypeSettingScalarFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  ClinicEmployeeId = 'clinicEmployeeId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type ClinicEmployeeAppointmentTypeSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateInput = {
  appointmentTypeId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeAppointmentTypeSettingsInput;
  where: ClinicEmployeeAppointmentTypeSettingScalarWhereInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  data: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpdateWithoutClinicEmployeeInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  create: ClinicEmployeeAppointmentTypeSettingUncheckedCreateWithoutClinicEmployeeInput;
  update: ClinicEmployeeAppointmentTypeSettingUncheckedUpdateWithoutClinicEmployeeInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};

export type ClinicEmployeeAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeAppointmentTypeSettingWhereInput>>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicEmployeeAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeClinicEmployeePimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicEmployeeCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDefaultExporter: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  isVisible: Scalars['Int'];
  lastName: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicEmployeeCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeCountOutputType = {
  __typename?: 'ClinicEmployeeCountOutputType';
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
  appointmentAvailabilities: Scalars['Int'];
  appointments: Scalars['Int'];
  bitwerxPaymentWritebackConfigurations: Scalars['Int'];
  clinicEmployeeAppointmentTypeSettings: Scalars['Int'];
  directBookingExclusionRules: Scalars['Int'];
  enteredByInvoiceLineItems: Scalars['Int'];
  prescriptions: Scalars['Int'];
  providerInvoiceLineItems: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  vetAvailabilityMaps: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicEmployeeCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeCreateManyClinicInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeCreateManyIntegrationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicEmployeeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
};

export type ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
};

export type ClinicEmployeeCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
};

export type ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicEmployeeCreateNestedOneWithoutAppointmentAvailabilitiesInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
};

export type ClinicEmployeeCreateNestedOneWithoutWorkflowsInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
};

export type ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput = {
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutClinicInput = {
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput = {
  create: ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutIntegrationInput = {
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  create: ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateOrConnectWithoutWorkflowsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeCreateWithoutAppointmentAvailabilitiesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutEnteredByInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutPrescriptionsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutProviderInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutVetAvailabilityMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutEnteredByInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeListRelationFilter = {
  every?: Maybe<ClinicEmployeeWhereInput>;
  none?: Maybe<ClinicEmployeeWhereInput>;
  some?: Maybe<ClinicEmployeeWhereInput>;
};

export type ClinicEmployeeMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  FirstName = 'firstName',
  Id = 'id',
  IntegrationId = 'integrationId',
  LastName = 'lastName',
  PimsId = 'pimsId',
  Type = 'type'
}

export type ClinicEmployeeOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicEmployeeOrderByWithAggregationInput = {
  _count?: Maybe<ClinicEmployeeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicEmployeeOrderByRelevanceInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDefaultExporter?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
};

export type ClinicEmployeeRelationFilter = {
  is?: Maybe<ClinicEmployeeWhereInput>;
  isNot?: Maybe<ClinicEmployeeWhereInput>;
};

export enum ClinicEmployeeScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDefaultExporter = 'isDefaultExporter',
  IsDeleted = 'isDeleted',
  IsSchedulable = 'isSchedulable',
  IsVisible = 'isVisible',
  LastName = 'lastName',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ClinicEmployeeScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  lastName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicEmployeeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDefaultExporter?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolNullableWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSetting = {
  __typename?: 'ClinicEmployeeToWorkflowEventSetting';
  clinicEmployee: ClinicEmployee;
  clinicEmployeeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  workflow: WorkflowEventSetting;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
};

export type ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput = {
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput;
};

export type ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutWorkflowsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMaxAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicEmployeeToWorkflowEventSettingMinAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum {
  ClinicEmployeeId = 'clinicEmployeeId',
  WorkflowId = 'workflowId'
}

export type ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicEmployeeToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEmployeeToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEmployeeToWorkflowEventSettingMinOrderByAggregateInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowId?: Maybe<SortOrder>;
};

export enum ClinicEmployeeToWorkflowEventSettingScalarFieldEnum {
  ClinicEmployeeId = 'clinicEmployeeId',
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId'
}

export type ClinicEmployeeToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput = {
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeesInput;
  where: ClinicEmployeeToWorkflowEventSettingScalarWhereInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  data: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput>;
};

export type ClinicEmployeeToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutClinicEmployeeInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutClinicEmployeeInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  create: ClinicEmployeeToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
  update: ClinicEmployeeToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  where: ClinicEmployeeToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicEmployeeToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeToWorkflowEventSettingWhereInput>>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  workflowId?: Maybe<StringFilter>;
};

export type ClinicEmployeeToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicEmployeeId?: Maybe<ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput>;
};

export type ClinicEmployeeToWorkflowEventSettingWorkflowIdClinicEmployeeIdCompoundUniqueInput = {
  clinicEmployeeId: Scalars['String'];
  workflowId: Scalars['String'];
};

export type ClinicEmployeeUncheckedCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
};

export type ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutEnteredByInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput>;
};

export type ClinicEmployeeUncheckedUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutProvidersInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput = {
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
  where: ClinicEmployeeScalarWhereInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
  where: ClinicEmployeeScalarWhereInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput = {
  data: ClinicEmployeeUncheckedUpdateManyWithoutProvidersInput;
  where: ClinicEmployeeScalarWhereInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicEmployeeUncheckedUpdateManyWithoutEmployeesInput;
  where: ClinicEmployeeScalarWhereInput;
};

export type ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  data: ClinicEmployeeUncheckedUpdateManyWithoutClinicEmployeesInput;
  where: ClinicEmployeeScalarWhereInput;
};

export type ClinicEmployeeUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type ClinicEmployeeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutDirectBookingExclusionRulesInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutDirectBookingExclusionRulesInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutDirectBookingExclusionRulesInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput>>;
};

export type ClinicEmployeeUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicEmployeeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEmployeeCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicEmployeeCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicEmployeeUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutClinicEmployeeAppointmentTypeSettingsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutVetAvailabilityMapsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput>;
};

export type ClinicEmployeeUpdateOneRequiredWithoutWorkflowsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutWorkflowsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutWorkflowsInput>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutWorkflowsInput>;
};

export type ClinicEmployeeUpdateOneWithoutAppointmentAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicEmployeeUpdateOneWithoutBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutEnteredByInvoiceLineItemsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutPrescriptionsInput>;
};

export type ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput = {
  connect?: Maybe<ClinicEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicEmployeeCreateOrConnectWithoutProviderInvoiceLineItemsInput>;
  create?: Maybe<ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput>;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutAppointmentsInput = {
  data: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  data: ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  data: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpdateWithoutAppointmentAvailabilitiesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutDirectBookingExclusionRulesInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutEnteredByInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutPrescriptionsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutProviderInvoiceLineItemsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutVetAvailabilityMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicEmployeeNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicEmployeesNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutEnteredByNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicEmployeesNestedInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingUpdateManyWithoutClinicEmployeeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutEnteredByNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefaultExporter?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutProviderNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput>;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutAppointmentsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutAppointmentsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicEmployeeUncheckedCreateWithoutClinicInput;
  update: ClinicEmployeeUncheckedUpdateWithoutClinicInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutDirectBookingExclusionRulesInput = {
  create: ClinicEmployeeUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  update: ClinicEmployeeUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicEmployeeUncheckedCreateWithoutIntegrationInput;
  update: ClinicEmployeeUncheckedUpdateWithoutIntegrationInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutUserAppointmentGroupingsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: ClinicEmployeeWhereUniqueInput;
};

export type ClinicEmployeeUpsertWithoutAppointmentAvailabilitiesInput = {
  create: ClinicEmployeeUncheckedCreateWithoutAppointmentAvailabilitiesInput;
  update: ClinicEmployeeUncheckedUpdateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicEmployeeUpsertWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicEmployeeUpsertWithoutClinicEmployeeAppointmentTypeSettingsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutClinicEmployeeAppointmentTypeSettingsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutClinicEmployeeAppointmentTypeSettingsInput;
};

export type ClinicEmployeeUpsertWithoutEnteredByInvoiceLineItemsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutEnteredByInvoiceLineItemsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutEnteredByInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutPrescriptionsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutPrescriptionsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutPrescriptionsInput;
};

export type ClinicEmployeeUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  create: ClinicEmployeeUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  update: ClinicEmployeeUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicEmployeeUpsertWithoutProviderInvoiceLineItemsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutProviderInvoiceLineItemsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutProviderInvoiceLineItemsInput;
};

export type ClinicEmployeeUpsertWithoutVetAvailabilityMapsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutVetAvailabilityMapsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutVetAvailabilityMapsInput;
};

export type ClinicEmployeeUpsertWithoutWorkflowsInput = {
  create: ClinicEmployeeUncheckedCreateWithoutWorkflowsInput;
  update: ClinicEmployeeUncheckedUpdateWithoutWorkflowsInput;
};

export type ClinicEmployeeWhereInput = {
  AND?: Maybe<Array<ClinicEmployeeWhereInput>>;
  NOT?: Maybe<Array<ClinicEmployeeWhereInput>>;
  OR?: Maybe<Array<ClinicEmployeeWhereInput>>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployeeAppointmentTypeSettings?: Maybe<ClinicEmployeeAppointmentTypeSettingListRelationFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  enteredByInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDefaultExporter?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolNullableFilter>;
  lastName?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  providerInvoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
  workflows?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
};

export type ClinicEmployeeWhereUniqueInput = {
  clinicEmployeePimsIdAndIntegrationId?: Maybe<ClinicEmployeeClinicEmployeePimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum ClinicEntityCreationSource {
  ClinicApp = 'ClinicApp',
  DirectBooking = 'DirectBooking',
  InboundSms = 'InboundSms',
  Pims = 'Pims',
  TestEntityAutomation = 'TestEntityAutomation',
  WidgetRequest = 'WidgetRequest'
}

export type ClinicEntityPhoneNumber = {
  __typename?: 'ClinicEntityPhoneNumber';
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  /** whether this number has opted out of messaging */
  isOptedOut: Scalars['Boolean'];
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** the last time opt out messaging was sent to this number */
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  /** The last time we tried to send an sms to this number */
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** This is the result from the last time we tried to send */
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt: Scalars['DateTime'];
};

export type ClinicEntityPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isMobile: Scalars['Int'];
  isOptedOut: Scalars['Int'];
  isPims: Scalars['Int'];
  isPrimary: Scalars['Int'];
  lastOptOutSendDate: Scalars['Int'];
  lastSmsSendDate: Scalars['Int'];
  number: Scalars['Int'];
  phoneNumberLookupRequestId: Scalars['Int'];
  phoneNumberLookupResultId: Scalars['Int'];
  pimsId: Scalars['Int'];
  smsStatus: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicEntityPhoneNumberCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberCreateInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope = {
  data: Array<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
};

export type ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  none?: Maybe<ClinicEntityPhoneNumberWhereInput>;
  some?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type ClinicEntityPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicEntityPhoneNumberMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberNumberClinicPetParentIdCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  number: Scalars['String'];
};

export type ClinicEntityPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicEntityPhoneNumberOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  Number = 'number',
  PhoneNumberLookupRequestId = 'phoneNumberLookupRequestId',
  PhoneNumberLookupResultId = 'phoneNumberLookupResultId',
  PimsId = 'pimsId'
}

export type ClinicEntityPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicEntityPhoneNumberOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicEntityPhoneNumberOrderByWithAggregationInput = {
  _count?: Maybe<ClinicEntityPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicEntityPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicEntityPhoneNumberMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicEntityPhoneNumberOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isMobile?: Maybe<SortOrder>;
  isOptedOut?: Maybe<SortOrder>;
  isPims?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  lastOptOutSendDate?: Maybe<SortOrder>;
  lastSmsSendDate?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumberLookupRequestId?: Maybe<SortOrder>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>;
  phoneNumberLookupResultId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  smsStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicEntityPhoneNumberScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDeleted = 'isDeleted',
  IsMobile = 'isMobile',
  IsOptedOut = 'isOptedOut',
  IsPims = 'isPims',
  IsPrimary = 'isPrimary',
  LastOptOutSendDate = 'lastOptOutSendDate',
  LastSmsSendDate = 'lastSmsSendDate',
  Number = 'number',
  PhoneNumberLookupRequestId = 'phoneNumberLookupRequestId',
  PhoneNumberLookupResultId = 'phoneNumberLookupResultId',
  PimsId = 'pimsId',
  SmsStatus = 'smsStatus',
  UpdatedAt = 'updatedAt'
}

export type ClinicEntityPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  number?: Maybe<StringFilter>;
  phoneNumberLookupRequestId?: Maybe<StringNullableFilter>;
  phoneNumberLookupResultId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicEntityPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isMobile?: Maybe<BoolNullableWithAggregatesFilter>;
  isOptedOut?: Maybe<BoolWithAggregatesFilter>;
  isPims?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  phoneNumberLookupRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumberLookupResultId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum ClinicEntityPhoneNumberSmsStatus {
  Delivered = 'Delivered',
  None = 'None',
  PreValidated = 'PreValidated',
  RejectedBecauseInvalidPhoneNumber = 'RejectedBecauseInvalidPhoneNumber',
  RejectedBecauseLandline = 'RejectedBecauseLandline',
  RejectedFilteredByCarrier = 'RejectedFilteredByCarrier',
  RejectedNotInService = 'RejectedNotInService',
  RejectedOptedOut = 'RejectedOptedOut',
  RejectedUnknown = 'RejectedUnknown'
}

export type ClinicEntityPhoneNumberUncheckedCreateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
};

export type ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupResultId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequestId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumbersInput;
  where: ClinicEntityPhoneNumberScalarWhereInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput;
  where: ClinicEntityPhoneNumberScalarWhereInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicEntityPhoneNumbersInput;
  where: ClinicEntityPhoneNumberScalarWhereInput;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupRequestInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupRequestInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupRequestInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupRequestInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput>>;
};

export type ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput = {
  connect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicEntityPhoneNumberCreateOrConnectWithoutPhoneNumberLookupResultInput>>;
  create?: Maybe<Array<ClinicEntityPhoneNumberCreateWithoutPhoneNumberLookupResultInput>>;
  createMany?: Maybe<ClinicEntityPhoneNumberCreateManyPhoneNumberLookupResultInputEnvelope>;
  delete?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicEntityPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicEntityPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
  updateMany?: Maybe<Array<ClinicEntityPhoneNumberUpdateManyWithWhereWithoutPhoneNumberLookupResultInput>>;
  upsert?: Maybe<Array<ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput>>;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupRequestInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberUpdateWithWhereUniqueWithoutPhoneNumberLookupResultInput = {
  data: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpdateWithoutPhoneNumberLookupRequestInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpdateWithoutPhoneNumberLookupResultInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isMobile?: Maybe<Scalars['Boolean']>;
  isOptedOut?: Maybe<Scalars['Boolean']>;
  isPims?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastOptOutSendDate?: Maybe<Scalars['DateTime']>;
  lastSmsSendDate?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['String']>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  smsStatus?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupRequestInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupRequestInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupRequestInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberUpsertWithWhereUniqueWithoutPhoneNumberLookupResultInput = {
  create: ClinicEntityPhoneNumberUncheckedCreateWithoutPhoneNumberLookupResultInput;
  update: ClinicEntityPhoneNumberUncheckedUpdateWithoutPhoneNumberLookupResultInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};

export type ClinicEntityPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicEntityPhoneNumberWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isMobile?: Maybe<BoolNullableFilter>;
  isOptedOut?: Maybe<BoolFilter>;
  isPims?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  lastOptOutSendDate?: Maybe<DateTimeNullableFilter>;
  lastSmsSendDate?: Maybe<DateTimeNullableFilter>;
  number?: Maybe<StringFilter>;
  phoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequestWhereInput>;
  phoneNumberLookupRequestId?: Maybe<StringNullableFilter>;
  phoneNumberLookupResult?: Maybe<PhoneNumberLookupResultWhereInput>;
  phoneNumberLookupResultId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  smsStatus?: Maybe<EnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicEntityPhoneNumberWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  number_clinicPetParentId?: Maybe<ClinicEntityPhoneNumberNumberClinicPetParentIdCompoundUniqueInput>;
};

export type ClinicListRelationFilter = {
  every?: Maybe<ClinicWhereInput>;
  none?: Maybe<ClinicWhereInput>;
  some?: Maybe<ClinicWhereInput>;
};

export type ClinicMaxAggregateOutputType = {
  __typename?: 'ClinicMaxAggregateOutputType';
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type ClinicMaxOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
};

export type ClinicMinAggregateOutputType = {
  __typename?: 'ClinicMinAggregateOutputType';
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type ClinicMinOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
};

export type ClinicOfficeHour = {
  __typename?: 'ClinicOfficeHour';
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  closeAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  dayOfWeek: Scalars['Int'];
  id: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours: Scalars['Boolean'];
  openAt: Scalars['DateTime'];
  type: ClinicOfficeHoursType;
  updatedAt: Scalars['DateTime'];
};

export type ClinicOfficeHourAvgAggregateOutputType = {
  __typename?: 'ClinicOfficeHourAvgAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Float']>;
};

export type ClinicOfficeHourAvgOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCountAggregateOutputType = {
  __typename?: 'ClinicOfficeHourCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  closeAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  id: Scalars['Int'];
  isEnabled: Scalars['Int'];
  isOpenTwentyFourHours: Scalars['Int'];
  openAt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicOfficeHourCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOfficeHourCreateInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicOfficeHoursInput>;
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourCreateManyClinicInput = {
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourCreateManyClinicInputEnvelope = {
  data: Array<ClinicOfficeHourCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicOfficeHourCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
};

export type ClinicOfficeHourCreateOrConnectWithoutClinicInput = {
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
  where: ClinicOfficeHourWhereUniqueInput;
};

export type ClinicOfficeHourCreateWithoutClinicInput = {
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourListRelationFilter = {
  every?: Maybe<ClinicOfficeHourWhereInput>;
  none?: Maybe<ClinicOfficeHourWhereInput>;
  some?: Maybe<ClinicOfficeHourWhereInput>;
};

export type ClinicOfficeHourMaxAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOfficeHourMinAggregateOutputType = {
  __typename?: 'ClinicOfficeHourMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOfficeHourOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOfficeHourOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type ClinicOfficeHourOrderByRelevanceInput = {
  fields: Array<ClinicOfficeHourOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicOfficeHourOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicOfficeHourAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicOfficeHourCountOrderByAggregateInput>;
  _max?: Maybe<ClinicOfficeHourMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicOfficeHourMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicOfficeHourSumOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicOfficeHourOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  closeAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  isOpenTwentyFourHours?: Maybe<SortOrder>;
  openAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicOfficeHourScalarFieldEnum {
  ClinicId = 'clinicId',
  CloseAt = 'closeAt',
  CreatedAt = 'createdAt',
  DayOfWeek = 'dayOfWeek',
  Id = 'id',
  IsEnabled = 'isEnabled',
  IsOpenTwentyFourHours = 'isOpenTwentyFourHours',
  OpenAt = 'openAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ClinicOfficeHourScalarWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  openAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicOfficeHourScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicOfficeHourScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  closeAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dayOfWeek?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isEnabled?: Maybe<BoolWithAggregatesFilter>;
  isOpenTwentyFourHours?: Maybe<BoolWithAggregatesFilter>;
  openAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicOfficeHourSumAggregateOutputType = {
  __typename?: 'ClinicOfficeHourSumAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Int']>;
};

export type ClinicOfficeHourSumOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type ClinicOfficeHourUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
};

export type ClinicOfficeHourUncheckedCreateWithoutClinicInput = {
  closeAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt: Scalars['DateTime'];
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput = {
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUncheckedUpdateWithoutClinicInput = {
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutClinicOfficeHoursNestedInput>;
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUpdateManyMutationInput = {
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicOfficeHourUncheckedUpdateManyWithoutClinicOfficeHoursInput;
  where: ClinicOfficeHourScalarWhereInput;
};

export type ClinicOfficeHourUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOfficeHourCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOfficeHourCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOfficeHourCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicOfficeHourScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  set?: Maybe<Array<ClinicOfficeHourWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOfficeHourUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicOfficeHourUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
  where: ClinicOfficeHourWhereUniqueInput;
};

export type ClinicOfficeHourUpdateWithoutClinicInput = {
  closeAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isOpenTwentyFourHours?: Maybe<Scalars['Boolean']>;
  openAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ClinicOfficeHoursType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOfficeHourUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicOfficeHourUncheckedCreateWithoutClinicInput;
  update: ClinicOfficeHourUncheckedUpdateWithoutClinicInput;
  where: ClinicOfficeHourWhereUniqueInput;
};

export type ClinicOfficeHourWhereInput = {
  AND?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  NOT?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  OR?: Maybe<Array<ClinicOfficeHourWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  closeAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolFilter>;
  isOpenTwentyFourHours?: Maybe<BoolFilter>;
  openAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumClinicOfficeHoursTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicOfficeHourWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ClinicOfficeHoursType {
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours',
  DirectBookingHours = 'DirectBookingHours'
}

export type ClinicOnboarding = {
  __typename?: 'ClinicOnboarding';
  clinic: Clinic;
  clinicId: Scalars['String'];
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage: Scalars['String'];
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type ClinicOnboardingCountAggregateOutputType = {
  __typename?: 'ClinicOnboardingCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  completionDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  kickoffDate: Scalars['Int'];
  pauseUntilDate: Scalars['Int'];
  salesforceOnboardingId: Scalars['Int'];
  stage: Scalars['Int'];
  trainingDate: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicOnboardingCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOnboardingCreateInput = {
  clinic: ClinicCreateNestedOneWithoutClinicOnboardingsInput;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingCreateManyClinicInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingCreateManyClinicInputEnvelope = {
  data: Array<ClinicOnboardingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicOnboardingCreateManyInput = {
  clinicId: Scalars['String'];
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
};

export type ClinicOnboardingCreateOrConnectWithoutClinicInput = {
  create: ClinicOnboardingUncheckedCreateWithoutClinicInput;
  where: ClinicOnboardingWhereUniqueInput;
};

export type ClinicOnboardingCreateWithoutClinicInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingListRelationFilter = {
  every?: Maybe<ClinicOnboardingWhereInput>;
  none?: Maybe<ClinicOnboardingWhereInput>;
  some?: Maybe<ClinicOnboardingWhereInput>;
};

export type ClinicOnboardingMaxAggregateOutputType = {
  __typename?: 'ClinicOnboardingMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOnboardingMinAggregateOutputType = {
  __typename?: 'ClinicOnboardingMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOnboardingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOnboardingOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  SalesforceOnboardingId = 'salesforceOnboardingId',
  Stage = 'stage'
}

export type ClinicOnboardingOrderByRelevanceInput = {
  fields: Array<ClinicOnboardingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicOnboardingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicOnboardingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicOnboardingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicOnboardingMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicOnboardingOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  completionDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  kickoffDate?: Maybe<SortOrder>;
  pauseUntilDate?: Maybe<SortOrder>;
  salesforceOnboardingId?: Maybe<SortOrder>;
  stage?: Maybe<SortOrder>;
  trainingDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicOnboardingScalarFieldEnum {
  ClinicId = 'clinicId',
  CompletionDate = 'completionDate',
  CreatedAt = 'createdAt',
  Id = 'id',
  KickoffDate = 'kickoffDate',
  PauseUntilDate = 'pauseUntilDate',
  SalesforceOnboardingId = 'salesforceOnboardingId',
  Stage = 'stage',
  TrainingDate = 'trainingDate',
  UpdatedAt = 'updatedAt'
}

export type ClinicOnboardingScalarWhereInput = {
  AND?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  completionDate?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kickoffDate?: Maybe<DateTimeNullableFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableFilter>;
  salesforceOnboardingId?: Maybe<StringNullableFilter>;
  stage?: Maybe<StringFilter>;
  trainingDate?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicOnboardingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicOnboardingScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  completionDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  kickoffDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  salesforceOnboardingId?: Maybe<StringNullableWithAggregatesFilter>;
  stage?: Maybe<StringWithAggregatesFilter>;
  trainingDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicOnboardingUncheckedCreateInput = {
  clinicId: Scalars['String'];
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
};

export type ClinicOnboardingUncheckedCreateWithoutClinicInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOnboardingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicOnboardingUncheckedUpdateManyWithoutClinicOnboardingsInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUncheckedUpdateWithoutClinicInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicOnboardingsNestedInput>;
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUpdateManyMutationInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicOnboardingUncheckedUpdateManyWithoutClinicOnboardingsInput;
  where: ClinicOnboardingScalarWhereInput;
};

export type ClinicOnboardingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicOnboardingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicOnboardingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicOnboardingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicOnboardingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicOnboardingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicOnboardingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicOnboardingUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicOnboardingUncheckedUpdateWithoutClinicInput;
  where: ClinicOnboardingWhereUniqueInput;
};

export type ClinicOnboardingUpdateWithoutClinicInput = {
  completionDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kickoffDate?: Maybe<Scalars['DateTime']>;
  pauseUntilDate?: Maybe<Scalars['DateTime']>;
  salesforceOnboardingId?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  trainingDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicOnboardingUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicOnboardingUncheckedCreateWithoutClinicInput;
  update: ClinicOnboardingUncheckedUpdateWithoutClinicInput;
  where: ClinicOnboardingWhereUniqueInput;
};

export type ClinicOnboardingWhereInput = {
  AND?: Maybe<Array<ClinicOnboardingWhereInput>>;
  NOT?: Maybe<Array<ClinicOnboardingWhereInput>>;
  OR?: Maybe<Array<ClinicOnboardingWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  completionDate?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kickoffDate?: Maybe<DateTimeNullableFilter>;
  pauseUntilDate?: Maybe<DateTimeNullableFilter>;
  salesforceOnboardingId?: Maybe<StringNullableFilter>;
  stage?: Maybe<StringFilter>;
  trainingDate?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicOnboardingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicOrderByRelevanceFieldEnum {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  CancellationNote = 'cancellationNote',
  CancellationReason = 'cancellationReason',
  CarePlanProviderGroupId = 'carePlanProviderGroupId',
  City = 'city',
  ClinicEmail = 'clinicEmail',
  ClinicSettingId = 'clinicSettingId',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  CorporateId = 'corporateId',
  Country = 'country',
  CrmId = 'crmId',
  CustomerId = 'customerId',
  FirebaseId = 'firebaseId',
  FullAddress = 'fullAddress',
  HeadUserId = 'headUserId',
  Hours = 'hours',
  Id = 'id',
  IncomeMethod = 'incomeMethod',
  IntegrationSystem = 'integrationSystem',
  IntegrationType = 'integrationType',
  LegalEntityId = 'legalEntityId',
  Logo = 'logo',
  LoyaltyProgramId = 'loyaltyProgramId',
  MarketEmail = 'marketEmail',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  Name = 'name',
  OptimizedLogoUrl = 'optimizedLogoUrl',
  OrganizationId = 'organizationId',
  PaymentAddress = 'paymentAddress',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentCity = 'paymentCity',
  PaymentName = 'paymentName',
  PaymentPhone = 'paymentPhone',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  Phone = 'phone',
  PostalCode = 'postalCode',
  RemindersIntegrationRequestEmail = 'remindersIntegrationRequestEmail',
  SmsPhoneNumber = 'smsPhoneNumber',
  State = 'state',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  SubmerchantId = 'submerchantId',
  SubmerchantStatus = 'submerchantStatus',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  TimezoneName = 'timezoneName',
  Token = 'token',
  WebsiteUrl = 'websiteUrl'
}

export type ClinicOrderByRelevanceInput = {
  fields: Array<ClinicOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicCountOrderByAggregateInput>;
  _max?: Maybe<ClinicMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSumOrderByAggregateInput>;
  activationDate?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  canceledAt?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicEmail?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  consultationCost?: Maybe<SortOrder>;
  corporateId?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  integrationInfo?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  legalEntityId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  loyaltyProgramId?: Maybe<SortOrder>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  smsPhoneNumber?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  websiteUrl?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
};

export type ClinicOrderByWithRelationAndSearchRelevanceInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  Rules?: Maybe<RulesOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicOrderByRelevanceInput>;
  activationDate?: Maybe<SortOrder>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  allowAllUsersToCloseConsultations?: Maybe<SortOrder>;
  appoinmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  autoSubscriptionReceiptEmails?: Maybe<SortOrder>;
  automaticSubscriptionReceipts?: Maybe<SortOrder>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  benefitsProvided?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckOrderByRelationAggregateInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  callerIdPhoneNumber?: Maybe<SortOrder>;
  calls?: Maybe<CallHistoryOrderByRelationAggregateInput>;
  canceledAt?: Maybe<SortOrder>;
  cancellationNote?: Maybe<SortOrder>;
  cancellationReason?: Maybe<SortOrder>;
  carePayoutOrganization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  carePlanProviderGroupId?: Maybe<SortOrder>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  city?: Maybe<SortOrder>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingOrderByRelationAggregateInput>;
  clinicEmail?: Maybe<SortOrder>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingOrderByRelationAggregateInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourOrderByRelationAggregateInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  clinicRooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeOrderByRelationAggregateInput>;
  consultationCost?: Maybe<SortOrder>;
  consultationRequests?: Maybe<ConsultationRequestOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  corporateId?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  crmUrlParams?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleOrderByRelationAggregateInput>;
  emailCampaign?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  featureFlags?: Maybe<FeatureFlagOrderByRelationAggregateInput>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  firebaseId?: Maybe<SortOrder>;
  firebaseVets?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  formTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  fullAddress?: Maybe<SortOrder>;
  hasServiceReminders?: Maybe<SortOrder>;
  hasSmsConversations?: Maybe<SortOrder>;
  headUserId?: Maybe<SortOrder>;
  hours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomeMethod?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationOrderByRelationAggregateInput>;
  integrationInfo?: Maybe<SortOrder>;
  integrationSystem?: Maybe<SortOrder>;
  integrationType?: Maybe<SortOrder>;
  integrations?: Maybe<ClinicPimsIntegrationOrderByRelationAggregateInput>;
  isAddAppointmentDisabled?: Maybe<SortOrder>;
  isCallerIdVerified?: Maybe<SortOrder>;
  isClinicActive?: Maybe<SortOrder>;
  isConsultationsActive?: Maybe<SortOrder>;
  isIntegrationActive?: Maybe<SortOrder>;
  isPaymentUsBank?: Maybe<SortOrder>;
  isServiceRemindersActive?: Maybe<SortOrder>;
  lastPrintOrderedOn?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  legalEntity?: Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>;
  legalEntityId?: Maybe<SortOrder>;
  logo?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  loyaltyProgramId?: Maybe<SortOrder>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  marketEmail?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  marketFacebookPageId?: Maybe<SortOrder>;
  marketFacebookPageToken?: Maybe<SortOrder>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByRelationAggregateInput>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  optimizedLogoUrl?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  paymentAddress?: Maybe<SortOrder>;
  paymentBankAccountNumber?: Maybe<SortOrder>;
  paymentBankRoutingNumber?: Maybe<SortOrder>;
  paymentCity?: Maybe<SortOrder>;
  paymentName?: Maybe<SortOrder>;
  paymentPhone?: Maybe<SortOrder>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  paymentState?: Maybe<SortOrder>;
  paymentZip?: Maybe<SortOrder>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodOrderByRelationAggregateInput>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  petsId?: Maybe<PetOrderByRelationAggregateInput>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  postcardSettings?: Maybe<ClinicPostcardSettingOrderByRelationAggregateInput>;
  ppcLocationId?: Maybe<SortOrder>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  printLastOrder?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  promoCodes?: Maybe<PromoCodeOrderByRelationAggregateInput>;
  remindersIntegrationRequestEmail?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  smsPhoneNumber?: Maybe<SortOrder>;
  staff?: Maybe<VetInfoOrderByRelationAggregateInput>;
  staffId?: Maybe<VetInfoOrderByRelationAggregateInput>;
  state?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountOrderByRelationAggregateInput>;
  stripeSubscriptionId?: Maybe<SortOrder>;
  stripeSubscriptionPlan?: Maybe<SortOrder>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationOrderByRelationAggregateInput>;
  submerchantApprovedAt?: Maybe<SortOrder>;
  submerchantId?: Maybe<SortOrder>;
  submerchantIsActive?: Maybe<SortOrder>;
  submerchantStatus?: Maybe<SortOrder>;
  submerchantStripeChargesEnabled?: Maybe<SortOrder>;
  submerchantStripeHasCustomPayouts?: Maybe<SortOrder>;
  submerchantStripeId?: Maybe<SortOrder>;
  submerchantStripePayoutsEnabled?: Maybe<SortOrder>;
  submerchantStripeStatus?: Maybe<SortOrder>;
  subscribedAt?: Maybe<SortOrder>;
  subscriptionId?: Maybe<SortOrder>;
  subscriptionPlan?: Maybe<SortOrder>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  timezone?: Maybe<SortOrder>;
  timezoneName?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  twilioConfiguration?: Maybe<TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  twilioVerifications?: Maybe<TwilioVerificationOrderByRelationAggregateInput>;
  unlayerSetting?: Maybe<UnlayerSettingOrderByWithRelationAndSearchRelevanceInput>;
  unlayerSettingId?: Maybe<SortOrder>;
  unsubscribedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  usersId?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>;
  websiteUrl?: Maybe<SortOrder>;
  widgetSettings?: Maybe<SortOrder>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type ClinicPaymentActivityOutput = {
  __typename?: 'ClinicPaymentActivityOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  totalRecentCentsProcessed?: Maybe<Scalars['Int']>;
  totalRecentTransactions?: Maybe<Scalars['Int']>;
};

export type ClinicPaymentFeeConfiguration = {
  __typename?: 'ClinicPaymentFeeConfiguration';
  _count: ClinicPaymentFeeConfigurationCountOutputType;
  clinicSetting: ClinicSetting;
  clinicSettingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  disputeFeeCharge: Scalars['Int'];
  feeCollection: FeeCollection;
  id: Scalars['String'];
  interchangeRates: Array<InterchangeRate>;
  onlineClientServiceFeePercent: Scalars['Float'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineProcessingFeePercent: Scalars['Float'];
  pricingModel: PricingModel;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent: Scalars['Float'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalProcessingFeePercent: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type ClinicPaymentFeeConfigurationInterchangeRatesArgs = {
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  distinct?: Maybe<InterchangeRateScalarFieldEnum>;
  orderBy?: Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterchangeRateWhereInput>;
};

export type ClinicPaymentFeeConfigurationAvgAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationAvgAggregateOutputType';
  disputeFeeCharge?: Maybe<Scalars['Float']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Float']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Float']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationAvgOrderByAggregateInput = {
  disputeFeeCharge?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCountAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  disputeFeeCharge: Scalars['Int'];
  feeCollection: Scalars['Int'];
  id: Scalars['Int'];
  onlineClientServiceFeePercent: Scalars['Int'];
  onlineProcessingFeeFlat: Scalars['Int'];
  onlineProcessingFeePercent: Scalars['Int'];
  pricingModel: Scalars['Int'];
  responsibleForClinicRefundFeesAt: Scalars['Int'];
  terminalClientServiceFeePercent: Scalars['Int'];
  terminalProcessingFeeFlat: Scalars['Int'];
  terminalProcessingFeePercent: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPaymentFeeConfigurationCountOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationCountOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationCountOutputType';
  interchangeRates: Scalars['Int'];
};

export type ClinicPaymentFeeConfigurationCreateInput = {
  clinicSetting: ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationCreateManyInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationCreateNestedOneWithoutInterchangeRatesInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput>;
};

export type ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput = {
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};

export type ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput = {
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};

export type ClinicPaymentFeeConfigurationCreateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationCreateWithoutInterchangeRatesInput = {
  clinicSetting: ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationMaxAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMaxAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationMaxOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationMinAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationMinAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationMinOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  Id = 'id'
}

export type ClinicPaymentFeeConfigurationOrderByRelevanceInput = {
  fields: Array<ClinicPaymentFeeConfigurationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPaymentFeeConfigurationOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPaymentFeeConfigurationAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPaymentFeeConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPaymentFeeConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPaymentFeeConfigurationMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPaymentFeeConfigurationSumOrderByAggregateInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPaymentFeeConfigurationOrderByRelevanceInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFeeCharge?: Maybe<SortOrder>;
  feeCollection?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  interchangeRates?: Maybe<InterchangeRateOrderByRelationAggregateInput>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  pricingModel?: Maybe<SortOrder>;
  responsibleForClinicRefundFeesAt?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationRelationFilter = {
  is?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  isNot?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};

export enum ClinicPaymentFeeConfigurationScalarFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  DisputeFeeCharge = 'disputeFeeCharge',
  FeeCollection = 'feeCollection',
  Id = 'id',
  OnlineClientServiceFeePercent = 'onlineClientServiceFeePercent',
  OnlineProcessingFeeFlat = 'onlineProcessingFeeFlat',
  OnlineProcessingFeePercent = 'onlineProcessingFeePercent',
  PricingModel = 'pricingModel',
  ResponsibleForClinicRefundFeesAt = 'responsibleForClinicRefundFeesAt',
  TerminalClientServiceFeePercent = 'terminalClientServiceFeePercent',
  TerminalProcessingFeeFlat = 'terminalProcessingFeeFlat',
  TerminalProcessingFeePercent = 'terminalProcessingFeePercent',
  UpdatedAt = 'updatedAt'
}

export type ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationScalarWhereWithAggregatesInput>>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  disputeFeeCharge?: Maybe<IntWithAggregatesFilter>;
  feeCollection?: Maybe<EnumFeeCollectionWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  onlineProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  onlineProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  pricingModel?: Maybe<EnumPricingModelWithAggregatesFilter>;
  responsibleForClinicRefundFeesAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  terminalProcessingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  terminalProcessingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPaymentFeeConfigurationSumAggregateOutputType = {
  __typename?: 'ClinicPaymentFeeConfigurationSumAggregateOutputType';
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicPaymentFeeConfigurationSumOrderByAggregateInput = {
  disputeFeeCharge?: Maybe<SortOrder>;
  onlineClientServiceFeePercent?: Maybe<SortOrder>;
  onlineProcessingFeeFlat?: Maybe<SortOrder>;
  onlineProcessingFeePercent?: Maybe<SortOrder>;
  terminalClientServiceFeePercent?: Maybe<SortOrder>;
  terminalProcessingFeeFlat?: Maybe<SortOrder>;
  terminalProcessingFeePercent?: Maybe<SortOrder>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateManyInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUpdateInput = {
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUpdateOneRequiredWithoutInterchangeRatesNestedInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutInterchangeRatesInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutInterchangeRatesInput>;
};

export type ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPaymentFeeConfigurationCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput>;
};

export type ClinicPaymentFeeConfigurationUpdateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  interchangeRates?: Maybe<InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUpdateWithoutInterchangeRatesInput = {
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFeeCharge?: Maybe<Scalars['Int']>;
  feeCollection?: Maybe<FeeCollection>;
  id?: Maybe<Scalars['String']>;
  onlineClientServiceFeePercent?: Maybe<Scalars['Float']>;
  onlineProcessingFeeFlat?: Maybe<Scalars['Int']>;
  onlineProcessingFeePercent?: Maybe<Scalars['Float']>;
  pricingModel?: Maybe<PricingModel>;
  responsibleForClinicRefundFeesAt?: Maybe<Scalars['DateTime']>;
  terminalClientServiceFeePercent?: Maybe<Scalars['Float']>;
  terminalProcessingFeeFlat?: Maybe<Scalars['Int']>;
  terminalProcessingFeePercent?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPaymentFeeConfigurationUpsertWithoutClinicSettingInput = {
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutClinicSettingInput;
  update: ClinicPaymentFeeConfigurationUncheckedUpdateWithoutClinicSettingInput;
};

export type ClinicPaymentFeeConfigurationUpsertWithoutInterchangeRatesInput = {
  create: ClinicPaymentFeeConfigurationUncheckedCreateWithoutInterchangeRatesInput;
  update: ClinicPaymentFeeConfigurationUncheckedUpdateWithoutInterchangeRatesInput;
};

export type ClinicPaymentFeeConfigurationWhereInput = {
  AND?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  NOT?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  OR?: Maybe<Array<ClinicPaymentFeeConfigurationWhereInput>>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  disputeFeeCharge?: Maybe<IntFilter>;
  feeCollection?: Maybe<EnumFeeCollectionFilter>;
  id?: Maybe<StringFilter>;
  interchangeRates?: Maybe<InterchangeRateListRelationFilter>;
  onlineClientServiceFeePercent?: Maybe<FloatFilter>;
  onlineProcessingFeeFlat?: Maybe<IntFilter>;
  onlineProcessingFeePercent?: Maybe<FloatFilter>;
  pricingModel?: Maybe<EnumPricingModelFilter>;
  responsibleForClinicRefundFeesAt?: Maybe<DateTimeNullableFilter>;
  terminalClientServiceFeePercent?: Maybe<FloatFilter>;
  terminalProcessingFeeFlat?: Maybe<IntFilter>;
  terminalProcessingFeePercent?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPaymentFeeConfigurationWhereUniqueInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicPet = {
  __typename?: 'ClinicPet';
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: ClinicPetCountOutputType;
  alerts: Array<ClinicPetAlert>;
  appointments: Array<Appointment>;
  automationRunActions: Array<AutomationRunAction>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  breedModel?: Maybe<Breed>;
  careBenefitUsages: Array<CareBenefitUsage>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channels: Array<Channel>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus: Array<ClinicPetPimsSyncStatus>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  colorModel?: Maybe<Color>;
  computedIsActive: Scalars['Boolean'];
  computedIsDeceased: Scalars['Boolean'];
  computed_optimizedPhotoUrl?: Maybe<Scalars['String']>;
  computed_rawPimsIsActive?: Maybe<Scalars['Boolean']>;
  computed_rawPimsIsDeceased?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  customFieldValues: Array<ClinicPetCustomFieldValue>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions: Array<FormSubmission>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems: Array<InvoiceLineItem>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders: Scalars['Boolean'];
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPet>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents: Array<ClinicPetParent>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions: Array<Prescription>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  serviceReminders: Array<ServiceReminder>;
  sexId?: Maybe<Scalars['String']>;
  sexModel?: Maybe<Sex>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<Species>;
  speciesType: SpeciesType;
  trupanionCertificateAvailabilities: Array<TrupanionCertificateAvailability>;
  updatedAt: Scalars['DateTime'];
  vaccinations: Array<Vaccination>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};


export type ClinicPetHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type ClinicPetAlertsArgs = {
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  distinct?: Maybe<ClinicPetAlertScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type ClinicPetAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicPetAutomationRunActionsArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type ClinicPetCareBenefitUsagesArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type ClinicPetChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type ClinicPetChannelsArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type ClinicPetClinicPetPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type ClinicPetCustomFieldValuesArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<ClinicPetCustomFieldValueScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type ClinicPetFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type ClinicPetInvoiceLineItemsArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type ClinicPetPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type ClinicPetPrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type ClinicPetReceivedTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type ClinicPetServiceRemindersArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type ClinicPetTrupanionCertificateAvailabilitiesArgs = {
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  distinct?: Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type ClinicPetVaccinationsArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};

export type ClinicPetAlert = {
  __typename?: 'ClinicPetAlert';
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type ClinicPetAlertCountAggregateOutputType = {
  __typename?: 'ClinicPetAlertCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isMedical: Scalars['Int'];
  pimsDate: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type ClinicPetAlertCountOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetAlertCreateInput = {
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutAlertsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertCreateManyClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetAlertCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetAlertCreateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetAlertCreateOrConnectWithoutClinicPetInput = {
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
  where: ClinicPetAlertWhereUniqueInput;
};

export type ClinicPetAlertCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertListRelationFilter = {
  every?: Maybe<ClinicPetAlertWhereInput>;
  none?: Maybe<ClinicPetAlertWhereInput>;
  some?: Maybe<ClinicPetAlertWhereInput>;
};

export type ClinicPetAlertMaxAggregateOutputType = {
  __typename?: 'ClinicPetAlertMaxAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMaxOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetAlertMinAggregateOutputType = {
  __typename?: 'ClinicPetAlertMinAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertMinOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetAlertOrderByRelevanceFieldEnum {
  ClinicPetId = 'clinicPetId',
  Id = 'id',
  PimsId = 'pimsId',
  Value = 'value'
}

export type ClinicPetAlertOrderByRelevanceInput = {
  fields: Array<ClinicPetAlertOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetAlertOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetAlertCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetAlertMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetAlertMinOrderByAggregateInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetAlertOrderByRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMedical?: Maybe<SortOrder>;
  pimsDate?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export enum ClinicPetAlertScalarFieldEnum {
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsMedical = 'isMedical',
  PimsDate = 'pimsDate',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type ClinicPetAlertScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  clinicPetId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
};

export type ClinicPetAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetAlertScalarWhereWithAggregatesInput>>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isMedical?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  value?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicPetAlertUncheckedCreateInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetAlertUncheckedCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type ClinicPetAlertUncheckedUpdateInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUncheckedUpdateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetAlertUncheckedUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUpdateInput = {
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutAlertsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput = {
  data: ClinicPetAlertUncheckedUpdateManyWithoutAlertsInput;
  where: ClinicPetAlertScalarWhereInput;
};

export type ClinicPetAlertUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetAlertCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetAlertCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetAlertCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetAlertWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetAlertUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetAlertUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetAlertWhereUniqueInput;
};

export type ClinicPetAlertUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isMedical?: Maybe<Scalars['Boolean']>;
  pimsDate?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type ClinicPetAlertUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: ClinicPetAlertUncheckedCreateWithoutClinicPetInput;
  update: ClinicPetAlertUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetAlertWhereUniqueInput;
};

export type ClinicPetAlertWhereInput = {
  AND?: Maybe<Array<ClinicPetAlertWhereInput>>;
  NOT?: Maybe<Array<ClinicPetAlertWhereInput>>;
  OR?: Maybe<Array<ClinicPetAlertWhereInput>>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isMedical?: Maybe<BoolNullableFilter>;
  pimsDate?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
};

export type ClinicPetAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetAvgAggregateOutputType = {
  __typename?: 'ClinicPetAvgAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

export type ClinicPetAvgOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
};

export type ClinicPetClinicPetPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicPetCountAggregateOutputType = {
  __typename?: 'ClinicPetCountAggregateOutputType';
  _all: Scalars['Int'];
  breed: Scalars['Int'];
  breedId: Scalars['Int'];
  clinicId: Scalars['Int'];
  color: Scalars['Int'];
  colorId: Scalars['Int'];
  createdAt: Scalars['Int'];
  dateOfBirth: Scalars['Int'];
  dateOfDeath: Scalars['Int'];
  gender: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeceased: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrivacyProtected: Scalars['Int'];
  isSubscribedToReminders: Scalars['Int'];
  mediaId: Scalars['Int'];
  name: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  photoUrl: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  sexId: Scalars['Int'];
  species: Scalars['Int'];
  speciesId: Scalars['Int'];
  speciesType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  weight: Scalars['Int'];
  weightInKg: Scalars['Int'];
  weightUnit: Scalars['Int'];
};

export type ClinicPetCountOrderByAggregateInput = {
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
};

export type ClinicPetCountOutputType = {
  __typename?: 'ClinicPetCountOutputType';
  HillsToHomeAPILog: Scalars['Int'];
  alerts: Scalars['Int'];
  appointments: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  careBenefitUsages: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channels: Scalars['Int'];
  clinicPetPimsSyncStatus: Scalars['Int'];
  customFieldValues: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  petParents: Scalars['Int'];
  prescriptions: Scalars['Int'];
  receivedTrupanionExamDayOffers: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  trupanionCertificateAvailabilities: Scalars['Int'];
  vaccinations: Scalars['Int'];
};

export type ClinicPetCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyBreedModelInput = {
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyBreedModelInputEnvelope = {
  data: Array<ClinicPetCreateManyBreedModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyClinicInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyColorModelInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyColorModelInputEnvelope = {
  data: Array<ClinicPetCreateManyColorModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyIntegrationInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyMediaInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyMediaInputEnvelope = {
  data: Array<ClinicPetCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManyOrganizationPetInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManyOrganizationPetInputEnvelope = {
  data: Array<ClinicPetCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManySexModelInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManySexModelInputEnvelope = {
  data: Array<ClinicPetCreateManySexModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateManySpeciesModelInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateManySpeciesModelInputEnvelope = {
  data: Array<ClinicPetCreateManySpeciesModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCreateNestedManyWithoutBreedModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutChannelsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
};

export type ClinicPetCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutColorModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
};

export type ClinicPetCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutPetParentsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
};

export type ClinicPetCreateNestedManyWithoutSexModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
};

export type ClinicPetCreateNestedManyWithoutSpeciesModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
};

export type ClinicPetCreateNestedOneWithoutAlertsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
};

export type ClinicPetCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
};

export type ClinicPetCreateNestedOneWithoutAutomationRunActionsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
};

export type ClinicPetCreateNestedOneWithoutCareBenefitUsagesInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
};

export type ClinicPetCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type ClinicPetCreateNestedOneWithoutClinicPetPimsSyncStatusInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput>;
};

export type ClinicPetCreateNestedOneWithoutCustomFieldValuesInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCustomFieldValuesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutCustomFieldValuesInput>;
};

export type ClinicPetCreateNestedOneWithoutFormSubmissionsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
};

export type ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
};

export type ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetCreateNestedOneWithoutServiceRemindersInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
};

export type ClinicPetCreateNestedOneWithoutTrupanionCertificateAvailabilitiesInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput>;
};

export type ClinicPetCreateNestedOneWithoutVaccinationsInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
};

export type ClinicPetCreateOrConnectWithoutAlertsInput = {
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutAutomationRunActionsInput = {
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutBreedModelInput = {
  create: ClinicPetUncheckedCreateWithoutBreedModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput = {
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutChannelsInput = {
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutClinicInput = {
  create: ClinicPetUncheckedCreateWithoutClinicInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput = {
  create: ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutColorModelInput = {
  create: ClinicPetUncheckedCreateWithoutColorModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutCustomFieldValuesInput = {
  create: ClinicPetUncheckedCreateWithoutCustomFieldValuesInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutFormSubmissionsInput = {
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput = {
  create: ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutIntegrationInput = {
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput = {
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutMediaInput = {
  create: ClinicPetUncheckedCreateWithoutMediaInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutOrganizationPetInput = {
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutPetParentsInput = {
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutPrescriptionsInput = {
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput = {
  create: ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutServiceRemindersInput = {
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutSexModelInput = {
  create: ClinicPetUncheckedCreateWithoutSexModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutSpeciesModelInput = {
  create: ClinicPetUncheckedCreateWithoutSpeciesModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput = {
  create: ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateOrConnectWithoutVaccinationsInput = {
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetCreateWithoutAlertsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutAutomationRunActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutBreedModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutCareBenefitUsagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutChannelAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutChannelsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutClinicPetPimsSyncStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutColorModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutCustomFieldValuesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutFormSubmissionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutHillsToHomeApiLogInput = {
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutIntegrationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutInvoiceLineItemsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutMediaInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutOrganizationPetInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutPetParentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutPrescriptionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutReceivedTrupanionExamDayOffersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutServiceRemindersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutSexModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutSpeciesModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutTrupanionCertificateAvailabilitiesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetCreateWithoutVaccinationsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedCreateNestedOneWithoutPetsInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutPetsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorCreateNestedOneWithoutPetsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetsInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaCreateNestedOneWithoutClinicPetsInput>;
  name: Scalars['String'];
  organizationPet?: Maybe<OrganizationPetCreateNestedOneWithoutClinicPetsInput>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetInput>;
  sexModel?: Maybe<SexCreateNestedOneWithoutPetsInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesCreateNestedOneWithoutPetsInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

/** Custom Values specifically for ClinicPets */
export type ClinicPetCustomFieldValue = {
  __typename?: 'ClinicPetCustomFieldValue';
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fieldDefinition: CustomFieldDefinition;
  fieldDefinitionId: Scalars['String'];
  id: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCountAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  fieldDefinitionId: Scalars['Int'];
  id: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type ClinicPetCustomFieldValueCountOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueCreateInput = {
  clinicPet: ClinicPetCreateNestedOneWithoutCustomFieldValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCreateManyClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetCustomFieldValueCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCustomFieldValueCreateManyFieldDefinitionInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope = {
  data: Array<ClinicPetCustomFieldValueCreateManyFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetCustomFieldValueCreateManyInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
};

export type ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput = {
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput = {
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput = {
  clinicPet: ClinicPetCreateNestedOneWithoutCustomFieldValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueListRelationFilter = {
  every?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  none?: Maybe<ClinicPetCustomFieldValueWhereInput>;
  some?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};

export type ClinicPetCustomFieldValueMaxAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueMaxAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetCustomFieldValueMaxOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueMinAggregateOutputType = {
  __typename?: 'ClinicPetCustomFieldValueMinAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetCustomFieldValueMinOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetCustomFieldValueOrderByRelevanceFieldEnum {
  ClinicPetId = 'clinicPetId',
  FieldDefinitionId = 'fieldDefinitionId',
  Id = 'id'
}

export type ClinicPetCustomFieldValueOrderByRelevanceInput = {
  fields: Array<ClinicPetCustomFieldValueOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetCustomFieldValueOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetCustomFieldValueCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetCustomFieldValueMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetCustomFieldValueMinOrderByAggregateInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetCustomFieldValueOrderByRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export enum ClinicPetCustomFieldValueScalarFieldEnum {
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  FieldDefinitionId = 'fieldDefinitionId',
  Id = 'id',
  RawPimsValue = 'rawPimsValue',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type ClinicPetCustomFieldValueScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<JsonFilter>;
};

export type ClinicPetCustomFieldValueScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereWithAggregatesInput>>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  fieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  value?: Maybe<JsonWithAggregatesFilter>;
};

export type ClinicPetCustomFieldValueUncheckedCreateInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
};

export type ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetCustomFieldValueUncheckedUpdateInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetFieldValuesInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateInput = {
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput = {
  data: ClinicPetCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput;
  where: ClinicPetCustomFieldValueScalarWhereInput;
};

export type ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput = {
  data: ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetFieldValuesInput;
  where: ClinicPetCustomFieldValueScalarWhereInput;
};

export type ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput = {
  connect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
};

export type ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput = {
  data: ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpdateWithoutFieldDefinitionInput = {
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutClinicPetInput;
  update: ClinicPetCustomFieldValueUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput = {
  create: ClinicPetCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
  update: ClinicPetCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};

export type ClinicPetCustomFieldValueWhereInput = {
  AND?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  NOT?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  OR?: Maybe<Array<ClinicPetCustomFieldValueWhereInput>>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
  fieldDefinitionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<JsonFilter>;
};

export type ClinicPetCustomFieldValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetListRelationFilter = {
  every?: Maybe<ClinicPetWhereInput>;
  none?: Maybe<ClinicPetWhereInput>;
  some?: Maybe<ClinicPetWhereInput>;
};

export type ClinicPetMaxAggregateOutputType = {
  __typename?: 'ClinicPetMaxAggregateOutputType';
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetMaxOrderByAggregateInput = {
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
};

export type ClinicPetMinAggregateOutputType = {
  __typename?: 'ClinicPetMinAggregateOutputType';
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetMinOrderByAggregateInput = {
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
};

export type ClinicPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetOrderByRelevanceFieldEnum {
  Breed = 'breed',
  BreedId = 'breedId',
  ClinicId = 'clinicId',
  Color = 'color',
  ColorId = 'colorId',
  Gender = 'gender',
  Id = 'id',
  IntegrationId = 'integrationId',
  MediaId = 'mediaId',
  Name = 'name',
  OrganizationPetId = 'organizationPetId',
  PhotoUrl = 'photoUrl',
  PimsId = 'pimsId',
  SexId = 'sexId',
  Species = 'species',
  SpeciesId = 'speciesId',
  WeightUnit = 'weightUnit'
}

export type ClinicPetOrderByRelevanceInput = {
  fields: Array<ClinicPetOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPetAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPetCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetSumOrderByAggregateInput>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPetId?: Maybe<SortOrder>;
  photoUrl?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  sexId?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
};

export type ClinicPetOrderByWithRelationAndSearchRelevanceInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetOrderByRelevanceInput>;
  alerts?: Maybe<ClinicPetAlertOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  breed?: Maybe<SortOrder>;
  breedId?: Maybe<SortOrder>;
  breedModel?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusOrderByRelationAggregateInput>;
  color?: Maybe<SortOrder>;
  colorId?: Maybe<SortOrder>;
  colorModel?: Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueOrderByRelationAggregateInput>;
  dateOfBirth?: Maybe<SortOrder>;
  dateOfDeath?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  isActive?: Maybe<SortOrder>;
  isDeceased?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  mediaId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetId?: Maybe<SortOrder>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  photoUrl?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  sexId?: Maybe<SortOrder>;
  sexModel?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  species?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  speciesModel?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  speciesType?: Maybe<SortOrder>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
  weightUnit?: Maybe<SortOrder>;
};

export type ClinicPetParent = {
  __typename?: 'ClinicPetParent';
  EmailCampaignLog: Array<EmailCampaignLog>;
  EmailCampaignUnsubscribe: Array<EmailCampaignUnsubscribe>;
  HillsToHomeAPILog: Array<HillsToHomeApiLog>;
  _count: ClinicPetParentCountOutputType;
  addresses: Array<ClinicPetParentAddress>;
  appointments: Array<Appointment>;
  automationRunActions: Array<AutomationRunAction>;
  automationRuns: Array<AutomationRun>;
  bitwerxPaymentHealthcheckResults: Array<BitwerxPaymentHealthcheckResult>;
  calls: Array<CallParticipant>;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  careAuthorizationRequests: Array<CareAuthorizationRequest>;
  channelCreationSources: Array<ChannelCreationSource>;
  channelMembers: Array<ChannelMember>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus: Array<ClinicPetParentPimsSyncStatus>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  createdAt: Scalars['DateTime'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues: Array<ClinicPetParentCustomFieldValue>;
  devices: Array<ClinicPetParentDevice>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients: Array<EmailCampaignCustomListRecipient>;
  emailProviderLogs: Array<EmailProviderLog>;
  firstName: Scalars['String'];
  formSubmissions: Array<FormSubmission>;
  globalPetParent?: Maybe<GlobalPetParent>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informControlGroupParticipants: Array<InformControlGroupParticipant>;
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  invoices: Array<Invoice>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers: Array<LapsedPetParentTriggers>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  massTextAlertEntries: Array<MassTextAlertEntry>;
  organizationPetParent?: Maybe<OrganizationPetParent>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSetting?: Maybe<PetParentSetting>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets: Array<ClinicPet>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  serviceReminders: Array<ServiceReminder>;
  /** This is the user-readable ID from the PIMS if one exists */
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomer>;
  /** StripeCustomer can be shared between multiple petParent records - not unique */
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  surveyResults: Array<SurveyResult>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  /** Linked to User for testing and onboarding purposes */
  userId?: Maybe<Scalars['String']>;
};


export type ClinicPetParentEmailCampaignLogArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type ClinicPetParentEmailCampaignUnsubscribeArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type ClinicPetParentHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<HillsToHomeApiLogScalarFieldEnum>;
  orderBy?: Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type ClinicPetParentAddressesArgs = {
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentAddressScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type ClinicPetParentAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicPetParentAutomationRunActionsArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type ClinicPetParentAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type ClinicPetParentBitwerxPaymentHealthcheckResultsArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type ClinicPetParentCallsArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type ClinicPetParentCareAccountCreditIntentsArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type ClinicPetParentCareAuthorizationRequestsArgs = {
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  distinct?: Maybe<CareAuthorizationRequestScalarFieldEnum>;
  orderBy?: Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type ClinicPetParentChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type ClinicPetParentChannelMembersArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type ClinicPetParentClinicPetParentPimSyncStatusArgs = {
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type ClinicPetParentClinicWidgetRequestsArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type ClinicPetParentCustomFieldValuesArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type ClinicPetParentDevicesArgs = {
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentDeviceScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type ClinicPetParentEmailCampaignCustomListRecipientsArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type ClinicPetParentEmailProviderLogsArgs = {
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  distinct?: Maybe<EmailProviderLogScalarFieldEnum>;
  orderBy?: Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type ClinicPetParentFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type ClinicPetParentInformControlGroupParticipantsArgs = {
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  distinct?: Maybe<InformControlGroupParticipantScalarFieldEnum>;
  orderBy?: Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type ClinicPetParentInvoicesArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type ClinicPetParentLapsedPetParentTriggersArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  distinct?: Maybe<LapsedPetParentTriggersScalarFieldEnum>;
  orderBy?: Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type ClinicPetParentMassTextAlertEntriesArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type ClinicPetParentPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type ClinicPetParentPhoneNumbersArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type ClinicPetParentReceivedTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type ClinicPetParentServiceRemindersArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type ClinicPetParentStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type ClinicPetParentStripePaymentMethodsArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type ClinicPetParentSurveyResultsArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<SurveyResultScalarFieldEnum>;
  orderBy?: Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};

export type ClinicPetParentAddress = {
  __typename?: 'ClinicPetParentAddress';
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicPetParentAddressCountAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressCountAggregateOutputType';
  _all: Scalars['Int'];
  addressType: Scalars['Int'];
  city: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  creationSource: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  line1: Scalars['Int'];
  line2: Scalars['Int'];
  pimsId: Scalars['Int'];
  postalCode: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  stateProvince: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPetParentAddressCountOrderByAggregateInput = {
  addressType?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressCreateInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutAddressesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentAddressCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentAddressCreateManyInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type ClinicPetParentAddressCreateWithoutClinicPetParentInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressListRelationFilter = {
  every?: Maybe<ClinicPetParentAddressWhereInput>;
  none?: Maybe<ClinicPetParentAddressWhereInput>;
  some?: Maybe<ClinicPetParentAddressWhereInput>;
};

export type ClinicPetParentAddressMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMaxAggregateOutputType';
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressMaxOrderByAggregateInput = {
  addressType?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressMinAggregateOutputType = {
  __typename?: 'ClinicPetParentAddressMinAggregateOutputType';
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressMinOrderByAggregateInput = {
  addressType?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentAddressOrderByRelevanceFieldEnum {
  City = 'city',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  Line1 = 'line1',
  Line2 = 'line2',
  PimsId = 'pimsId',
  PostalCode = 'postalCode',
  StateProvince = 'stateProvince'
}

export type ClinicPetParentAddressOrderByRelevanceInput = {
  fields: Array<ClinicPetParentAddressOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetParentAddressOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetParentAddressCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentAddressMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentAddressMinOrderByAggregateInput>;
  addressType?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetParentAddressOrderByRelevanceInput>;
  addressType?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  line1?: Maybe<SortOrder>;
  line2?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  stateProvince?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPetParentAddressScalarFieldEnum {
  AddressType = 'addressType',
  City = 'city',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  CreationSource = 'creationSource',
  Id = 'id',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  Line1 = 'line1',
  Line2 = 'line2',
  PimsId = 'pimsId',
  PostalCode = 'postalCode',
  RawPimsValue = 'rawPimsValue',
  StateProvince = 'stateProvince',
  UpdatedAt = 'updatedAt'
}

export type ClinicPetParentAddressScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  city?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  stateProvince?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentAddressScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressScalarWhereWithAggregatesInput>>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  city?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrimary?: Maybe<BoolNullableWithAggregatesFilter>;
  line1?: Maybe<StringWithAggregatesFilter>;
  line2?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  stateProvince?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum ClinicPetParentAddressType {
  Billing = 'Billing',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Unknown = 'Unknown',
  Work = 'Work'
}

export type ClinicPetParentAddressUncheckedCreateInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUncheckedUpdateInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUniquePimsAddressPerPetParentCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicPetParentAddressUpdateInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutAddressesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUpdateManyMutationInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicPetParentAddressUncheckedUpdateManyWithoutAddressesInput;
  where: ClinicPetParentAddressScalarWhereInput;
};

export type ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentAddressCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentAddressCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentAddressCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentAddressScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentAddressWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentAddressUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentAddressUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type ClinicPetParentAddressUpdateWithoutClinicPetParentInput = {
  addressType?: Maybe<ClinicPetParentAddressType>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  stateProvince?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentAddressUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicPetParentAddressUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicPetParentAddressUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};

export type ClinicPetParentAddressWhereInput = {
  AND?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentAddressWhereInput>>;
  addressType?: Maybe<EnumClinicPetParentAddressTypeNullableFilter>;
  city?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrimary?: Maybe<BoolNullableFilter>;
  line1?: Maybe<StringFilter>;
  line2?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  stateProvince?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentAddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniquePimsAddressPerPetParent?: Maybe<ClinicPetParentAddressUniquePimsAddressPerPetParentCompoundUniqueInput>;
};

export type ClinicPetParentAuthLinkResponse = {
  __typename?: 'ClinicPetParentAuthLinkResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ClinicPetParentClinicPetParentPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicPetParentCountAggregateOutputType = {
  __typename?: 'ClinicPetParentCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  creationSource: Scalars['Int'];
  email: Scalars['Int'];
  firstName: Scalars['Int'];
  globalPetParentId: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrivacyProtected: Scalars['Int'];
  isTest: Scalars['Int'];
  isVerified: Scalars['Int'];
  lastName: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
  petParentSettingId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsPhoneNumber: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  sourcePimsId: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ClinicPetParentCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ClinicPetParentCountOutputType = {
  __typename?: 'ClinicPetParentCountOutputType';
  EmailCampaignLog: Scalars['Int'];
  EmailCampaignUnsubscribe: Scalars['Int'];
  HillsToHomeAPILog: Scalars['Int'];
  addresses: Scalars['Int'];
  appointments: Scalars['Int'];
  automationRunActions: Scalars['Int'];
  automationRuns: Scalars['Int'];
  bitwerxPaymentHealthcheckResults: Scalars['Int'];
  calls: Scalars['Int'];
  careAccountCreditIntents: Scalars['Int'];
  careAuthorizationRequests: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  channelMembers: Scalars['Int'];
  clinicPetParentPimSyncStatus: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  customFieldValues: Scalars['Int'];
  devices: Scalars['Int'];
  emailCampaignCustomListRecipients: Scalars['Int'];
  emailProviderLogs: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  informControlGroupParticipants: Scalars['Int'];
  invoices: Scalars['Int'];
  lapsedPetParentTriggers: Scalars['Int'];
  massTextAlertEntries: Scalars['Int'];
  pets: Scalars['Int'];
  phoneNumbers: Scalars['Int'];
  receivedTrupanionExamDayOffers: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripePaymentMethods: Scalars['Int'];
  surveyResults: Scalars['Int'];
};

export type ClinicPetParentCreateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyClinicInputEnvelope = {
  data: Array<ClinicPetParentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyGlobalPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyGlobalPetParentInputEnvelope = {
  data: Array<ClinicPetParentCreateManyGlobalPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPetParentCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<ClinicPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyPetParentSettingInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyPetParentSettingInputEnvelope = {
  data: Array<ClinicPetParentCreateManyPetParentSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyStripeCustomerInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<ClinicPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateManyUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentCreateManyUserInputEnvelope = {
  data: Array<ClinicPetParentCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutGlobalPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutPetParentSettingInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
};

export type ClinicPetParentCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
};

export type ClinicPetParentCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
};

export type ClinicPetParentCreateNestedOneWithoutAddressesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunActionsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutBitwerxPaymentHealthcheckResultsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCallsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCareAuthorizationRequestsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutChannelMembersInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
};

export type ClinicPetParentCreateNestedOneWithoutClinicPetParentPimSyncStatusInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput>;
};

export type ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutDevicesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutDevicesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutDevicesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetParentCreateNestedOneWithoutInformControlGroupParticipantsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutInvoicesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput>;
};

export type ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput>;
};

export type ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
};

export type ClinicPetParentCreateNestedOneWithoutPhoneNumbersInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
};

export type ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetParentCreateNestedOneWithoutServiceRemindersInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
};

export type ClinicPetParentCreateNestedOneWithoutSurveyResultsInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutSurveyResultsInput>;
};

export type ClinicPetParentCreateOrConnectWithoutAddressesInput = {
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutAutomationRunsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput = {
  create: ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutCallsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutChannelMembersInput = {
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput = {
  create: ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutDevicesInput = {
  create: ClinicPetParentUncheckedCreateWithoutDevicesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput = {
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput = {
  create: ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput = {
  create: ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput = {
  create: ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutIntegrationInput = {
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutInvoicesInput = {
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput = {
  create: ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput = {
  create: ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput = {
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetParentSettingInput = {
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutPetsInput = {
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput = {
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput = {
  create: ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutServiceRemindersInput = {
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripeCustomerInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutSurveyResultsInput = {
  create: ClinicPetParentUncheckedCreateWithoutSurveyResultsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateOrConnectWithoutUserInput = {
  create: ClinicPetParentUncheckedCreateWithoutUserInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentCreateWithoutAddressesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutBitwerxPaymentHealthcheckResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutCareAccountCreditIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutCareAuthorizationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutChannelCreationSourcesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutChannelMembersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutClinicInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutClinicPetParentPimSyncStatusInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutCustomFieldValuesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutDevicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignCustomListRecipientsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutEmailProviderLogsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutGlobalPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutInformControlGroupParticipantsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutIntegrationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutInvoicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutLapsedPetParentTriggersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutLoyaltyAccountHolderInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutMassTextAlertEntriesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutOrganizationPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutPetParentSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutReceivedTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutServiceRemindersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutStripeCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutStripePaymentMethodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutClinicPetParentsInput>;
};

export type ClinicPetParentCreateWithoutUserInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParent?: Maybe<GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParent?: Maybe<OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutClinicPetParentsInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Custom Values specifically for ClinicPetParent */
export type ClinicPetParentCustomFieldValue = {
  __typename?: 'ClinicPetParentCustomFieldValue';
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fieldDefinition: CustomFieldDefinition;
  fieldDefinitionId: Scalars['String'];
  id: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCountAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  fieldDefinitionId: Scalars['Int'];
  id: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
  value: Scalars['Int'];
};

export type ClinicPetParentCustomFieldValueCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueCreateInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope = {
  data: Array<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentCustomFieldValueCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
};

export type ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput = {
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutCustomFieldValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueListRelationFilter = {
  every?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  none?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
  some?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};

export type ClinicPetParentCustomFieldValueMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentCustomFieldValueMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueMinAggregateOutputType = {
  __typename?: 'ClinicPetParentCustomFieldValueMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentCustomFieldValueMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentCustomFieldValueOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  FieldDefinitionId = 'fieldDefinitionId',
  Id = 'id'
}

export type ClinicPetParentCustomFieldValueOrderByRelevanceInput = {
  fields: Array<ClinicPetParentCustomFieldValueOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetParentCustomFieldValueOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetParentCustomFieldValueCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentCustomFieldValueMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentCustomFieldValueMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export type ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetParentCustomFieldValueOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  fieldDefinitionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export enum ClinicPetParentCustomFieldValueScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  FieldDefinitionId = 'fieldDefinitionId',
  Id = 'id',
  RawPimsValue = 'rawPimsValue',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type ClinicPetParentCustomFieldValueScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fieldDefinitionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<JsonFilter>;
};

export type ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  fieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  value?: Maybe<JsonWithAggregatesFilter>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
};

export type ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Json'];
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentFieldValuesInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinitionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutCustomFieldValuesInput;
  where: ClinicPetParentCustomFieldValueScalarWhereInput;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput = {
  data: ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentFieldValuesInput;
  where: ClinicPetParentCustomFieldValueScalarWhereInput;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput = {
  connect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCustomFieldValueCreateOrConnectWithoutFieldDefinitionInput>>;
  create?: Maybe<Array<ClinicPetParentCustomFieldValueCreateWithoutFieldDefinitionInput>>;
  createMany?: Maybe<ClinicPetParentCustomFieldValueCreateManyFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentCustomFieldValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentCustomFieldValueWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput>>;
  updateMany?: Maybe<Array<ClinicPetParentCustomFieldValueUpdateManyWithWhereWithoutFieldDefinitionInput>>;
  upsert?: Maybe<Array<ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput>>;
};

export type ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueUpdateWithWhereUniqueWithoutFieldDefinitionInput = {
  data: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpdateWithoutFieldDefinitionInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueUpsertWithWhereUniqueWithoutFieldDefinitionInput = {
  create: ClinicPetParentCustomFieldValueUncheckedCreateWithoutFieldDefinitionInput;
  update: ClinicPetParentCustomFieldValueUncheckedUpdateWithoutFieldDefinitionInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};

export type ClinicPetParentCustomFieldValueWhereInput = {
  AND?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentCustomFieldValueWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
  fieldDefinitionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<JsonFilter>;
};

export type ClinicPetParentCustomFieldValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetParentDevice = {
  __typename?: 'ClinicPetParentDevice';
  _count: ClinicPetParentDeviceCountOutputType;
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deviceName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instanceId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped: Scalars['Boolean'];
  pushNotificationLogs: Array<PushNotificationLog>;
  pushNotificationStatus: PushNotificationStatus;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt: Scalars['DateTime'];
};


export type ClinicPetParentDevicePushNotificationLogsArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};

export type ClinicPetParentDeviceCountAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceCountAggregateOutputType';
  _all: Scalars['Int'];
  appVersion: Scalars['Int'];
  brand: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deviceName: Scalars['Int'];
  id: Scalars['Int'];
  instanceId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  lastLogin: Scalars['Int'];
  lastPushAlertTimestamp: Scalars['Int'];
  manufacturer: Scalars['Int'];
  model: Scalars['Int'];
  os: Scalars['Int'];
  osVersion: Scalars['Int'];
  pushModalSkipped: Scalars['Int'];
  pushNotificationStatus: Scalars['Int'];
  snsEndpointArn: Scalars['Int'];
  token: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPetParentDeviceCountOrderByAggregateInput = {
  appVersion?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceCountOutputType = {
  __typename?: 'ClinicPetParentDeviceCountOutputType';
  pushNotificationLogs: Scalars['Int'];
};

export type ClinicPetParentDeviceCreateInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutDevicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateManyClinicPetParentInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentDeviceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentDeviceCreateManyInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput = {
  connect?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput>;
};

export type ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};

export type ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput = {
  create: ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};

export type ClinicPetParentDeviceCreateWithoutClinicPetParentInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceCreateWithoutPushNotificationLogsInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutDevicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceListRelationFilter = {
  every?: Maybe<ClinicPetParentDeviceWhereInput>;
  none?: Maybe<ClinicPetParentDeviceWhereInput>;
  some?: Maybe<ClinicPetParentDeviceWhereInput>;
};

export type ClinicPetParentDeviceMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceMaxAggregateOutputType';
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceMaxOrderByAggregateInput = {
  appVersion?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceMinAggregateOutputType = {
  __typename?: 'ClinicPetParentDeviceMinAggregateOutputType';
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceMinOrderByAggregateInput = {
  appVersion?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentDeviceOrderByRelevanceFieldEnum {
  AppVersion = 'appVersion',
  Brand = 'brand',
  ClinicPetParentId = 'clinicPetParentId',
  DeviceName = 'deviceName',
  Id = 'id',
  InstanceId = 'instanceId',
  Manufacturer = 'manufacturer',
  Model = 'model',
  OsVersion = 'osVersion',
  SnsEndpointArn = 'snsEndpointArn',
  Token = 'token'
}

export type ClinicPetParentDeviceOrderByRelevanceInput = {
  fields: Array<ClinicPetParentDeviceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetParentDeviceOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetParentDeviceCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentDeviceMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentDeviceMinOrderByAggregateInput>;
  appVersion?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  pushNotificationStatus?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetParentDeviceOrderByRelevanceInput>;
  appVersion?: Maybe<SortOrder>;
  brand?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deviceName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instanceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  lastLogin?: Maybe<SortOrder>;
  lastPushAlertTimestamp?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  os?: Maybe<SortOrder>;
  osVersion?: Maybe<SortOrder>;
  pushModalSkipped?: Maybe<SortOrder>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  pushNotificationStatus?: Maybe<SortOrder>;
  snsEndpointArn?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentDeviceRelationFilter = {
  is?: Maybe<ClinicPetParentDeviceWhereInput>;
  isNot?: Maybe<ClinicPetParentDeviceWhereInput>;
};

export enum ClinicPetParentDeviceScalarFieldEnum {
  AppVersion = 'appVersion',
  Brand = 'brand',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  DeviceName = 'deviceName',
  Id = 'id',
  InstanceId = 'instanceId',
  IsDeleted = 'isDeleted',
  LastLogin = 'lastLogin',
  LastPushAlertTimestamp = 'lastPushAlertTimestamp',
  Manufacturer = 'manufacturer',
  Model = 'model',
  Os = 'os',
  OsVersion = 'osVersion',
  PushModalSkipped = 'pushModalSkipped',
  PushNotificationStatus = 'pushNotificationStatus',
  SnsEndpointArn = 'snsEndpointArn',
  Token = 'token',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ClinicPetParentDeviceScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  appVersion?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deviceName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  instanceId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  lastLogin?: Maybe<DateTimeNullableFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  model?: Maybe<StringNullableFilter>;
  os?: Maybe<EnumDeviceOsNullableFilter>;
  osVersion?: Maybe<StringNullableFilter>;
  pushModalSkipped?: Maybe<BoolFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusFilter>;
  snsEndpointArn?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumDeviceTypeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentDeviceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceScalarWhereWithAggregatesInput>>;
  appVersion?: Maybe<StringNullableWithAggregatesFilter>;
  brand?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deviceName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  instanceId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  lastLogin?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableWithAggregatesFilter>;
  manufacturer?: Maybe<StringNullableWithAggregatesFilter>;
  model?: Maybe<StringNullableWithAggregatesFilter>;
  os?: Maybe<EnumDeviceOsNullableWithAggregatesFilter>;
  osVersion?: Maybe<StringNullableWithAggregatesFilter>;
  pushModalSkipped?: Maybe<BoolWithAggregatesFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusWithAggregatesFilter>;
  snsEndpointArn?: Maybe<StringNullableWithAggregatesFilter>;
  token?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumDeviceTypeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPetParentDeviceUncheckedCreateInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentDeviceUncheckedUpdateManyWithoutDevicesInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUniqueInstanceIdPerPetParentCompoundUniqueInput = {
  clinicPetParentId: Scalars['String'];
  instanceId: Scalars['String'];
};

export type ClinicPetParentDeviceUpdateInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUpdateManyMutationInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicPetParentDeviceUncheckedUpdateManyWithoutDevicesInput;
  where: ClinicPetParentDeviceScalarWhereInput;
};

export type ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentDeviceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentDeviceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentDeviceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentDeviceScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentDeviceWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentDeviceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput = {
  connect?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentDeviceCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput>;
  update?: Maybe<ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ClinicPetParentDeviceUpsertWithoutPushNotificationLogsInput>;
};

export type ClinicPetParentDeviceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};

export type ClinicPetParentDeviceUpdateWithoutClinicPetParentInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUpdateWithoutPushNotificationLogsInput = {
  appVersion?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastPushAlertTimestamp?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  os?: Maybe<DeviceOs>;
  osVersion?: Maybe<Scalars['String']>;
  pushModalSkipped?: Maybe<Scalars['Boolean']>;
  pushNotificationStatus?: Maybe<PushNotificationStatus>;
  snsEndpointArn?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentDeviceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicPetParentDeviceUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicPetParentDeviceUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};

export type ClinicPetParentDeviceUpsertWithoutPushNotificationLogsInput = {
  create: ClinicPetParentDeviceUncheckedCreateWithoutPushNotificationLogsInput;
  update: ClinicPetParentDeviceUncheckedUpdateWithoutPushNotificationLogsInput;
};

export type ClinicPetParentDeviceWhereInput = {
  AND?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentDeviceWhereInput>>;
  appVersion?: Maybe<StringNullableFilter>;
  brand?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deviceName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  instanceId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  lastLogin?: Maybe<DateTimeNullableFilter>;
  lastPushAlertTimestamp?: Maybe<DateTimeNullableFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  model?: Maybe<StringNullableFilter>;
  os?: Maybe<EnumDeviceOsNullableFilter>;
  osVersion?: Maybe<StringNullableFilter>;
  pushModalSkipped?: Maybe<BoolFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
  pushNotificationStatus?: Maybe<EnumPushNotificationStatusFilter>;
  snsEndpointArn?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumDeviceTypeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentDeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uniqueInstanceIdPerPetParent?: Maybe<ClinicPetParentDeviceUniqueInstanceIdPerPetParentCompoundUniqueInput>;
};

export type ClinicPetParentListRelationFilter = {
  every?: Maybe<ClinicPetParentWhereInput>;
  none?: Maybe<ClinicPetParentWhereInput>;
  some?: Maybe<ClinicPetParentWhereInput>;
};

export type ClinicPetParentMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ClinicPetParentMinAggregateOutputType = {
  __typename?: 'ClinicPetParentMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ClinicPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Email = 'email',
  FirstName = 'firstName',
  GlobalPetParentId = 'globalPetParentId',
  Id = 'id',
  IntegrationId = 'integrationId',
  LastName = 'lastName',
  OrganizationPetParentId = 'organizationPetParentId',
  PetParentSettingId = 'petParentSettingId',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  PimsPhoneNumber = 'pimsPhoneNumber',
  SourcePimsId = 'sourcePimsId',
  StripeCustomerId = 'stripeCustomerId',
  UserId = 'userId'
}

export type ClinicPetParentOrderByRelevanceInput = {
  fields: Array<ClinicPetParentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetParentOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPetParentCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  globalPetParentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
  petParentSettingId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  sourcePimsId?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ClinicPetParentOrderByWithRelationAndSearchRelevanceInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicPetParentOrderByRelevanceInput>;
  addresses?: Maybe<ClinicPetParentAddressOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusOrderByRelationAggregateInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  creationSource?: Maybe<SortOrder>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueOrderByRelationAggregateInput>;
  devices?: Maybe<ClinicPetParentDeviceOrderByRelationAggregateInput>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientOrderByRelationAggregateInput>;
  emailProviderLogs?: Maybe<EmailProviderLogOrderByRelationAggregateInput>;
  firstName?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  globalPetParent?: Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>;
  globalPetParentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantOrderByRelationAggregateInput>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrivacyProtected?: Maybe<SortOrder>;
  isTest?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersOrderByRelationAggregateInput>;
  lastName?: Maybe<SortOrder>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParentId?: Maybe<SortOrder>;
  petParentSetting?: Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>;
  petParentSettingId?: Maybe<SortOrder>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  phoneNumber?: Maybe<SortOrder>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  pimsPhoneNumber?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  sourcePimsId?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  surveyResults?: Maybe<SurveyResultOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatus = {
  __typename?: 'ClinicPetParentPimsSyncStatus';
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: ProfilePimsSyncStatus;
  updatedAt: Scalars['DateTime'];
};

export type ClinicPetParentPimsSyncStatusAvgAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusAvgAggregateOutputType';
  attempts?: Maybe<Scalars['Float']>;
};

export type ClinicPetParentPimsSyncStatusAvgOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusCountAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusCountAggregateOutputType';
  _all: Scalars['Int'];
  attempts: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  details: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPetParentPimsSyncStatusCountOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusCreateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutClinicPetParentPimSyncStatusInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetParentPimsSyncStatusCreateManyInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusListRelationFilter = {
  every?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  none?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
  some?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};

export type ClinicPetParentPimsSyncStatusMaxAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusMaxAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusMaxOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusMinAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusMinAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusMinOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetParentPimsSyncStatusOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Details = 'details',
  Id = 'id'
}

export type ClinicPetParentPimsSyncStatusOrderByRelevanceInput = {
  fields: Array<ClinicPetParentPimsSyncStatusOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetParentPimsSyncStatusOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPetParentPimsSyncStatusAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPetParentPimsSyncStatusCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetParentPimsSyncStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetParentPimsSyncStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetParentPimsSyncStatusSumOrderByAggregateInput>;
  attempts?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetParentPimsSyncStatusOrderByRelevanceInput>;
  attempts?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPetParentPimsSyncStatusScalarFieldEnum {
  Attempts = 'attempts',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Details = 'details',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ClinicPetParentPimsSyncStatusScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereWithAggregatesInput>>;
  attempts?: Maybe<IntNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPetParentPimsSyncStatusSumAggregateOutputType = {
  __typename?: 'ClinicPetParentPimsSyncStatusSumAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetParentPimsSyncStatusSumOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentPimSyncStatusInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUpdateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutClinicPetParentPimSyncStatusNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUpdateManyMutationInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentPimSyncStatusInput;
  where: ClinicPetParentPimsSyncStatusScalarWhereInput;
};

export type ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentPimsSyncStatusCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicPetParentPimsSyncStatusCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentPimsSyncStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentPimsSyncStatusUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicPetParentPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetParentPimsSyncStatusUpdateWithoutClinicPetParentInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicPetParentPimsSyncStatusUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicPetParentPimsSyncStatusUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetParentPimsSyncStatusWhereInput = {
  AND?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentPimsSyncStatusWhereInput>>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetParentPimsSyncStatusWhereUniqueInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetParentRelationFilter = {
  is?: Maybe<ClinicPetParentWhereInput>;
  isNot?: Maybe<ClinicPetParentWhereInput>;
};

export enum ClinicPetParentScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  CreationSource = 'creationSource',
  Email = 'email',
  FirstName = 'firstName',
  GlobalPetParentId = 'globalPetParentId',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IsPrivacyProtected = 'isPrivacyProtected',
  IsTest = 'isTest',
  IsVerified = 'isVerified',
  LastName = 'lastName',
  OrganizationPetParentId = 'organizationPetParentId',
  PetParentSettingId = 'petParentSettingId',
  PhoneNumber = 'phoneNumber',
  PimsId = 'pimsId',
  PimsPhoneNumber = 'pimsPhoneNumber',
  RawPimsValue = 'rawPimsValue',
  SourcePimsId = 'sourcePimsId',
  StripeCustomerId = 'stripeCustomerId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ClinicPetParentScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  globalPetParentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isTest?: Maybe<BoolNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  lastName?: Maybe<StringFilter>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type ClinicPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetParentScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  email?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  globalPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrivacyProtected?: Maybe<BoolNullableWithAggregatesFilter>;
  isTest?: Maybe<BoolNullableWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  sourcePimsId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPetParentUncheckedCreateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutGlobalPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
};

export type ClinicPetParentUncheckedCreateWithoutAddressesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutChannelMembersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicPetParentsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSetting?: Maybe<PetParentSettingCreateNestedOneWithoutPetParentInput>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutDevicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutIntegrationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutInvoicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutPetParentSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutServiceRemindersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutStripeCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedCreateWithoutUserInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetParentsInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetParentInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  lastName: Scalars['String'];
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentUncheckedUpdateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutGlobalPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ClinicPetParentUncheckedUpdateWithoutAddressesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutChannelMembersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutDevicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutIntegrationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutInvoicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentUncheckedUpdateWithoutUserInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  integrationId?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParentId?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentUpdateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sourcePimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutPetsInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithWhereWithoutUserInput = {
  data: ClinicPetParentUncheckedUpdateManyWithoutClinicPetParentsInput;
  where: ClinicPetParentScalarWhereInput;
};

export type ClinicPetParentUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type ClinicPetParentUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPetParentUpdateManyWithoutGlobalPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutGlobalPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutGlobalPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyGlobalPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutGlobalPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput>>;
};

export type ClinicPetParentUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyOrganizationPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetParentSettingNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetParentSettingInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetParentSettingInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyPetParentSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetParentSettingInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput>>;
};

export type ClinicPetParentUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type ClinicPetParentUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetParentCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ClinicPetParentCreateWithoutUserInput>>;
  createMany?: Maybe<ClinicPetParentCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetParentWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetParentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ClinicPetParentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ClinicPetParentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAddressesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAddressesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAddressesInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAddressesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAddressesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutBitwerxPaymentHealthcheckResultsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutBitwerxPaymentHealthcheckResultsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutBitwerxPaymentHealthcheckResultsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCareAccountCreditIntentsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCareAccountCreditIntentsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCareAuthorizationRequestsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCareAuthorizationRequestsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutClinicPetParentPimSyncStatusNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicPetParentPimSyncStatusInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutClinicPetParentPimSyncStatusInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutCustomFieldValuesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCustomFieldValuesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCustomFieldValuesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutDevicesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutDevicesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutDevicesInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutDevicesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutDevicesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignLogInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignLogInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailProviderLogsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailProviderLogsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutInformControlGroupParticipantsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInformControlGroupParticipantsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutInformControlGroupParticipantsInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutInvoicesInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutInvoicesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutInvoicesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutLapsedPetParentTriggersInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutLoyaltyAccountHolderInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutLoyaltyAccountHolderInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutMassTextAlertEntriesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutMassTextAlertEntriesInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutSurveyResultsInput>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutSurveyResultsInput>;
};

export type ClinicPetParentUpdateOneWithoutAutomationRunActionsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutAutomationRunActionsInput>;
};

export type ClinicPetParentUpdateOneWithoutCallsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutCallsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutCallsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutCallsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutCallsInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelCreationSourcesInput>;
};

export type ClinicPetParentUpdateOneWithoutChannelMembersNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutChannelMembersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutChannelMembersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutChannelMembersInput>;
};

export type ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutClinicWidgetRequestsInput>;
};

export type ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignCustomListRecipientsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignCustomListRecipientsInput>;
};

export type ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutFormSubmissionsInput>;
};

export type ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetParentUpdateOneWithoutPhoneNumbersNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutPhoneNumbersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutPhoneNumbersInput>;
};

export type ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutServiceRemindersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutServiceRemindersInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentIntentsInput>;
};

export type ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput = {
  connect?: Maybe<ClinicPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetParentCreateOrConnectWithoutStripePaymentMethodsInput>;
  create?: Maybe<ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput>;
  upsert?: Maybe<ClinicPetParentUpsertWithoutStripePaymentMethodsInput>;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutAppointmentsInput = {
  data: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicPetParentUncheckedUpdateWithoutClinicInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutGlobalPetParentInput = {
  data: ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  data: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetParentSettingInput = {
  data: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutPetsInput = {
  data: ClinicPetParentUncheckedUpdateWithoutPetsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  data: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithWhereUniqueWithoutUserInput = {
  data: ClinicPetParentUncheckedUpdateWithoutUserInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpdateWithoutAddressesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutBitwerxPaymentHealthcheckResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutCareAccountCreditIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutCareAuthorizationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutChannelCreationSourcesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutChannelMembersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicPetParentPimSyncStatusInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutCustomFieldValuesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutDevicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignCustomListRecipientsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutEmailProviderLogsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutGlobalPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutInformControlGroupParticipantsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutIntegrationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutInvoicesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutLapsedPetParentTriggersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutLoyaltyAccountHolderInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutMassTextAlertEntriesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutOrganizationPetParentInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutPetParentSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutServiceRemindersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripeCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutStripePaymentMethodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutClinicPetParentsNestedInput>;
};

export type ClinicPetParentUpdateWithoutUserInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput>;
  addresses?: Maybe<ClinicPetParentAddressUpdateManyWithoutClinicPetParentNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetParentsNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetParentNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicPetParentNestedInput>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultUpdateManyWithoutClinicPetParentNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutClinicPetParentNestedInput>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestUpdateManyWithoutClinicPetParentNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicPetParentNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutClinicPetParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetParentsNestedInput>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusUpdateManyWithoutClinicPetParentNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutClinicPetParentNestedInput>;
  devices?: Maybe<ClinicPetParentDeviceUpdateManyWithoutClinicPetParentNestedInput>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput>;
  emailProviderLogs?: Maybe<EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetParentNestedInput>;
  globalPetParent?: Maybe<GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  id?: Maybe<Scalars['String']>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutClinicPetParentNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput>;
  petParentSetting?: Maybe<PetParentSettingUpdateOneWithoutPetParentNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutPetParentsNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutClinicPetParentNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsPhoneNumber?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetParentNestedInput>;
  sourcePimsId?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput>;
  stripePaymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutAppointmentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAppointmentsInput;
  update: ClinicPetParentUncheckedUpdateWithoutAppointmentsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicInput;
  update: ClinicPetParentUncheckedUpdateWithoutClinicInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutGlobalPetParentInput = {
  create: ClinicPetParentUncheckedCreateWithoutGlobalPetParentInput;
  update: ClinicPetParentUncheckedUpdateWithoutGlobalPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicPetParentUncheckedCreateWithoutIntegrationInput;
  update: ClinicPetParentUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  create: ClinicPetParentUncheckedCreateWithoutOrganizationPetParentInput;
  update: ClinicPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetParentSettingInput = {
  create: ClinicPetParentUncheckedCreateWithoutPetParentSettingInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetParentSettingInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutPetsInput = {
  create: ClinicPetParentUncheckedCreateWithoutPetsInput;
  update: ClinicPetParentUncheckedUpdateWithoutPetsInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripeCustomerInput;
  update: ClinicPetParentUncheckedUpdateWithoutStripeCustomerInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithWhereUniqueWithoutUserInput = {
  create: ClinicPetParentUncheckedCreateWithoutUserInput;
  update: ClinicPetParentUncheckedUpdateWithoutUserInput;
  where: ClinicPetParentWhereUniqueInput;
};

export type ClinicPetParentUpsertWithoutAddressesInput = {
  create: ClinicPetParentUncheckedCreateWithoutAddressesInput;
  update: ClinicPetParentUncheckedUpdateWithoutAddressesInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunActionsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunActionsInput;
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunActionsInput;
};

export type ClinicPetParentUpsertWithoutAutomationRunsInput = {
  create: ClinicPetParentUncheckedCreateWithoutAutomationRunsInput;
  update: ClinicPetParentUncheckedUpdateWithoutAutomationRunsInput;
};

export type ClinicPetParentUpsertWithoutBitwerxPaymentHealthcheckResultsInput = {
  create: ClinicPetParentUncheckedCreateWithoutBitwerxPaymentHealthcheckResultsInput;
  update: ClinicPetParentUncheckedUpdateWithoutBitwerxPaymentHealthcheckResultsInput;
};

export type ClinicPetParentUpsertWithoutCallsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCallsInput;
  update: ClinicPetParentUncheckedUpdateWithoutCallsInput;
};

export type ClinicPetParentUpsertWithoutCareAccountCreditIntentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCareAccountCreditIntentsInput;
  update: ClinicPetParentUncheckedUpdateWithoutCareAccountCreditIntentsInput;
};

export type ClinicPetParentUpsertWithoutCareAuthorizationRequestsInput = {
  create: ClinicPetParentUncheckedCreateWithoutCareAuthorizationRequestsInput;
  update: ClinicPetParentUncheckedUpdateWithoutCareAuthorizationRequestsInput;
};

export type ClinicPetParentUpsertWithoutChannelCreationSourcesInput = {
  create: ClinicPetParentUncheckedCreateWithoutChannelCreationSourcesInput;
  update: ClinicPetParentUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type ClinicPetParentUpsertWithoutChannelMembersInput = {
  create: ClinicPetParentUncheckedCreateWithoutChannelMembersInput;
  update: ClinicPetParentUncheckedUpdateWithoutChannelMembersInput;
};

export type ClinicPetParentUpsertWithoutClinicPetParentPimSyncStatusInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicPetParentPimSyncStatusInput;
  update: ClinicPetParentUncheckedUpdateWithoutClinicPetParentPimSyncStatusInput;
};

export type ClinicPetParentUpsertWithoutClinicWidgetRequestsInput = {
  create: ClinicPetParentUncheckedCreateWithoutClinicWidgetRequestsInput;
  update: ClinicPetParentUncheckedUpdateWithoutClinicWidgetRequestsInput;
};

export type ClinicPetParentUpsertWithoutCustomFieldValuesInput = {
  create: ClinicPetParentUncheckedCreateWithoutCustomFieldValuesInput;
  update: ClinicPetParentUncheckedUpdateWithoutCustomFieldValuesInput;
};

export type ClinicPetParentUpsertWithoutDevicesInput = {
  create: ClinicPetParentUncheckedCreateWithoutDevicesInput;
  update: ClinicPetParentUncheckedUpdateWithoutDevicesInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignCustomListRecipientsInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignCustomListRecipientsInput;
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignCustomListRecipientsInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignLogInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignLogInput;
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignLogInput;
};

export type ClinicPetParentUpsertWithoutEmailCampaignUnsubscribeInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
  update: ClinicPetParentUncheckedUpdateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicPetParentUpsertWithoutEmailProviderLogsInput = {
  create: ClinicPetParentUncheckedCreateWithoutEmailProviderLogsInput;
  update: ClinicPetParentUncheckedUpdateWithoutEmailProviderLogsInput;
};

export type ClinicPetParentUpsertWithoutFormSubmissionsInput = {
  create: ClinicPetParentUncheckedCreateWithoutFormSubmissionsInput;
  update: ClinicPetParentUncheckedUpdateWithoutFormSubmissionsInput;
};

export type ClinicPetParentUpsertWithoutHillsToHomeApiLogInput = {
  create: ClinicPetParentUncheckedCreateWithoutHillsToHomeApiLogInput;
  update: ClinicPetParentUncheckedUpdateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetParentUpsertWithoutInformControlGroupParticipantsInput = {
  create: ClinicPetParentUncheckedCreateWithoutInformControlGroupParticipantsInput;
  update: ClinicPetParentUncheckedUpdateWithoutInformControlGroupParticipantsInput;
};

export type ClinicPetParentUpsertWithoutInvoicesInput = {
  create: ClinicPetParentUncheckedCreateWithoutInvoicesInput;
  update: ClinicPetParentUncheckedUpdateWithoutInvoicesInput;
};

export type ClinicPetParentUpsertWithoutLapsedPetParentTriggersInput = {
  create: ClinicPetParentUncheckedCreateWithoutLapsedPetParentTriggersInput;
  update: ClinicPetParentUncheckedUpdateWithoutLapsedPetParentTriggersInput;
};

export type ClinicPetParentUpsertWithoutLoyaltyAccountHolderInput = {
  create: ClinicPetParentUncheckedCreateWithoutLoyaltyAccountHolderInput;
  update: ClinicPetParentUncheckedUpdateWithoutLoyaltyAccountHolderInput;
};

export type ClinicPetParentUpsertWithoutMassTextAlertEntriesInput = {
  create: ClinicPetParentUncheckedCreateWithoutMassTextAlertEntriesInput;
  update: ClinicPetParentUncheckedUpdateWithoutMassTextAlertEntriesInput;
};

export type ClinicPetParentUpsertWithoutPhoneNumbersInput = {
  create: ClinicPetParentUncheckedCreateWithoutPhoneNumbersInput;
  update: ClinicPetParentUncheckedUpdateWithoutPhoneNumbersInput;
};

export type ClinicPetParentUpsertWithoutReceivedTrupanionExamDayOffersInput = {
  create: ClinicPetParentUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
  update: ClinicPetParentUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetParentUpsertWithoutServiceRemindersInput = {
  create: ClinicPetParentUncheckedCreateWithoutServiceRemindersInput;
  update: ClinicPetParentUncheckedUpdateWithoutServiceRemindersInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentIntentsInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentIntentsInput;
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type ClinicPetParentUpsertWithoutStripePaymentMethodsInput = {
  create: ClinicPetParentUncheckedCreateWithoutStripePaymentMethodsInput;
  update: ClinicPetParentUncheckedUpdateWithoutStripePaymentMethodsInput;
};

export type ClinicPetParentUpsertWithoutSurveyResultsInput = {
  create: ClinicPetParentUncheckedCreateWithoutSurveyResultsInput;
  update: ClinicPetParentUncheckedUpdateWithoutSurveyResultsInput;
};

export type ClinicPetParentWhereInput = {
  AND?: Maybe<Array<ClinicPetParentWhereInput>>;
  EmailCampaignLog?: Maybe<EmailCampaignLogListRelationFilter>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  NOT?: Maybe<Array<ClinicPetParentWhereInput>>;
  OR?: Maybe<Array<ClinicPetParentWhereInput>>;
  addresses?: Maybe<ClinicPetParentAddressListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  bitwerxPaymentHealthcheckResults?: Maybe<BitwerxPaymentHealthcheckResultListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
  careAuthorizationRequests?: Maybe<CareAuthorizationRequestListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentPimSyncStatus?: Maybe<ClinicPetParentPimsSyncStatusListRelationFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  creationSource?: Maybe<EnumClinicEntityCreationSourceNullableFilter>;
  customFieldValues?: Maybe<ClinicPetParentCustomFieldValueListRelationFilter>;
  devices?: Maybe<ClinicPetParentDeviceListRelationFilter>;
  email?: Maybe<StringNullableFilter>;
  emailCampaignCustomListRecipients?: Maybe<EmailCampaignCustomListRecipientListRelationFilter>;
  emailProviderLogs?: Maybe<EmailProviderLogListRelationFilter>;
  firstName?: Maybe<StringFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  globalPetParent?: Maybe<GlobalPetParentWhereInput>;
  globalPetParentId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informControlGroupParticipants?: Maybe<InformControlGroupParticipantListRelationFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isTest?: Maybe<BoolNullableFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersListRelationFilter>;
  lastName?: Maybe<StringFilter>;
  loyaltyAccountHolder?: Maybe<LoyaltyAccountHolderWhereInput>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
  organizationPetParentId?: Maybe<StringNullableFilter>;
  petParentSetting?: Maybe<PetParentSettingWhereInput>;
  petParentSettingId?: Maybe<StringNullableFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  phoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsPhoneNumber?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  sourcePimsId?: Maybe<StringNullableFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripePaymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type ClinicPetParentWhereUniqueInput = {
  clinicPetParentPimsIdAndIntegrationId?: Maybe<ClinicPetParentClinicPetParentPimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
  petParentSettingId?: Maybe<Scalars['String']>;
};

export type ClinicPetParentWithJobChainsResponse = {
  __typename?: 'ClinicPetParentWithJobChainsResponse';
  clinicPetParent: ClinicPetParent;
  jobChains?: Maybe<Array<Maybe<JobChain>>>;
};

export type ClinicPetPimsSyncStatus = {
  __typename?: 'ClinicPetPimsSyncStatus';
  attempts?: Maybe<Scalars['Int']>;
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: ProfilePimsSyncStatus;
  updatedAt: Scalars['DateTime'];
};

export type ClinicPetPimsSyncStatusAvgAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusAvgAggregateOutputType';
  attempts?: Maybe<Scalars['Float']>;
};

export type ClinicPetPimsSyncStatusAvgOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusCountAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusCountAggregateOutputType';
  _all: Scalars['Int'];
  attempts: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  details: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPetPimsSyncStatusCountOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusCreateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPet: ClinicPetCreateNestedOneWithoutClinicPetPimsSyncStatusInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusCreateManyClinicPetInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope = {
  data: Array<ClinicPetPimsSyncStatusCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPetPimsSyncStatusCreateManyInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput = {
  create: ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetPimsSyncStatusCreateWithoutClinicPetInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusListRelationFilter = {
  every?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  none?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
  some?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};

export type ClinicPetPimsSyncStatusMaxAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusMaxAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusMaxOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusMinAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusMinAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusMinOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPetPimsSyncStatusOrderByRelevanceFieldEnum {
  ClinicPetId = 'clinicPetId',
  Details = 'details',
  Id = 'id'
}

export type ClinicPetPimsSyncStatusOrderByRelevanceInput = {
  fields: Array<ClinicPetPimsSyncStatusOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPetPimsSyncStatusOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPetPimsSyncStatusAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPetPimsSyncStatusCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPetPimsSyncStatusMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPetPimsSyncStatusMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPetPimsSyncStatusSumOrderByAggregateInput>;
  attempts?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPetPimsSyncStatusOrderByRelevanceInput>;
  attempts?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPetPimsSyncStatusScalarFieldEnum {
  Attempts = 'attempts',
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  Details = 'details',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ClinicPetPimsSyncStatusScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereWithAggregatesInput>>;
  attempts?: Maybe<IntNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPetPimsSyncStatusSumAggregateOutputType = {
  __typename?: 'ClinicPetPimsSyncStatusSumAggregateOutputType';
  attempts?: Maybe<Scalars['Int']>;
};

export type ClinicPetPimsSyncStatusSumOrderByAggregateInput = {
  attempts?: Maybe<SortOrder>;
};

export type ClinicPetPimsSyncStatusUncheckedCreateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
};

export type ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status: ProfilePimsSyncStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetPimsSyncStatusInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUpdateInput = {
  attempts?: Maybe<Scalars['Int']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutClinicPetPimsSyncStatusNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUpdateManyMutationInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput = {
  data: ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetPimsSyncStatusInput;
  where: ClinicPetPimsSyncStatusScalarWhereInput;
};

export type ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetPimsSyncStatusCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ClinicPetPimsSyncStatusCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ClinicPetPimsSyncStatusCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetPimsSyncStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetPimsSyncStatusWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ClinicPetPimsSyncStatusUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ClinicPetPimsSyncStatusUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetPimsSyncStatusUpdateWithoutClinicPetInput = {
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePimsSyncStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPetPimsSyncStatusUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: ClinicPetPimsSyncStatusUncheckedCreateWithoutClinicPetInput;
  update: ClinicPetPimsSyncStatusUncheckedUpdateWithoutClinicPetInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};

export type ClinicPetPimsSyncStatusWhereInput = {
  AND?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  NOT?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  OR?: Maybe<Array<ClinicPetPimsSyncStatusWhereInput>>;
  attempts?: Maybe<IntNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumProfilePimsSyncStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPetPimsSyncStatusWhereUniqueInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicPetRelationFilter = {
  is?: Maybe<ClinicPetWhereInput>;
  isNot?: Maybe<ClinicPetWhereInput>;
};

export enum ClinicPetScalarFieldEnum {
  Breed = 'breed',
  BreedId = 'breedId',
  ClinicId = 'clinicId',
  Color = 'color',
  ColorId = 'colorId',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  DateOfDeath = 'dateOfDeath',
  Gender = 'gender',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeceased = 'isDeceased',
  IsDeleted = 'isDeleted',
  IsPrivacyProtected = 'isPrivacyProtected',
  IsSubscribedToReminders = 'isSubscribedToReminders',
  MediaId = 'mediaId',
  Name = 'name',
  OrganizationPetId = 'organizationPetId',
  PhotoUrl = 'photoUrl',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  SexId = 'sexId',
  Species = 'species',
  SpeciesId = 'speciesId',
  SpeciesType = 'speciesType',
  UpdatedAt = 'updatedAt',
  Weight = 'weight',
  WeightInKg = 'weightInKg',
  WeightUnit = 'weightUnit'
}

export type ClinicPetScalarWhereInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereInput>>;
  breed?: Maybe<StringNullableFilter>;
  breedId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  color?: Maybe<StringNullableFilter>;
  colorId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  gender?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  organizationPetId?: Maybe<StringNullableFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  sexId?: Maybe<StringNullableFilter>;
  species?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightInKg?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
};

export type ClinicPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPetScalarWhereWithAggregatesInput>>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  breedId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  color?: Maybe<StringNullableWithAggregatesFilter>;
  colorId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dateOfBirth?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dateOfDeath?: Maybe<DateTimeNullableWithAggregatesFilter>;
  gender?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeceased?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isPrivacyProtected?: Maybe<BoolNullableWithAggregatesFilter>;
  isSubscribedToReminders?: Maybe<BoolWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  organizationPetId?: Maybe<StringNullableWithAggregatesFilter>;
  photoUrl?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  sexId?: Maybe<StringNullableWithAggregatesFilter>;
  species?: Maybe<StringNullableWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  speciesType?: Maybe<EnumSpeciesTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  weight?: Maybe<FloatNullableWithAggregatesFilter>;
  weightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  weightUnit?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ClinicPetSumAggregateOutputType = {
  __typename?: 'ClinicPetSumAggregateOutputType';
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
};

export type ClinicPetSumOrderByAggregateInput = {
  weight?: Maybe<SortOrder>;
  weightInKg?: Maybe<SortOrder>;
};

export type ClinicPetUncheckedCreateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutBreedModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutChannelsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutColorModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutPetParentsInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutSexModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
};

export type ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
};

export type ClinicPetUncheckedCreateWithoutAlertsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutAutomationRunActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutBreedModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutChannelsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutColorModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutCustomFieldValuesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutFormSubmissionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput = {
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutIntegrationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutMediaInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutOrganizationPetInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutPetParentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutPrescriptionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutServiceRemindersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutSexModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutSpeciesModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicPetInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedCreateWithoutVaccinationsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedCreateNestedManyWithoutClinicPetInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicPetInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutPetInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicPetInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutPetsInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedCreateNestedManyWithoutClinicPetInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutClinicPetInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetsInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateManyInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateManyWithoutBreedModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutBreedModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutChannelsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutClinicPetsInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutColorModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutColorModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutColorModelInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetParentsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutPetsInput = {
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSexModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSexModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSexModelInput>>;
};

export type ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput>>;
};

export type ClinicPetUncheckedUpdateWithoutAlertsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutBreedModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutChannelsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutColorModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutFormSubmissionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutIntegrationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutMediaInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutOrganizationPetInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutPetParentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutPrescriptionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutServiceRemindersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutSexModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutSpeciesModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUncheckedUpdateWithoutVaccinationsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUncheckedUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedId?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutPetsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUncheckedUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetId?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput>;
  sexId?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateManyMutationInput = {
  breed?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateManyWithWhereWithoutBreedModelInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutChannelsInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutColorModelInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput = {
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutMediaInput = {
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput = {
  data: ClinicPetUncheckedUpdateManyWithoutClinicPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutPetParentsInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutSexModelInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput = {
  data: ClinicPetUncheckedUpdateManyWithoutPetsInput;
  where: ClinicPetScalarWhereInput;
};

export type ClinicPetUpdateManyWithoutBreedModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutBreedModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutBreedModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyBreedModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutBreedModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput>>;
};

export type ClinicPetUpdateManyWithoutChannelsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutChannelsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutChannelsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutChannelsInput>>;
};

export type ClinicPetUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPetCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPetUpdateManyWithoutColorModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutColorModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutColorModelInput>>;
  createMany?: Maybe<ClinicPetCreateManyColorModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutColorModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutColorModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutColorModelInput>>;
};

export type ClinicPetUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPetCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutInvoiceLineItemsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutInvoiceLineItemsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutInvoiceLineItemsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput>>;
};

export type ClinicPetUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutMediaInput>>;
  createMany?: Maybe<ClinicPetCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ClinicPetUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<ClinicPetCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type ClinicPetUpdateManyWithoutPetParentsNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutPetParentsInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutPetParentsInput>>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutPetParentsInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput>>;
};

export type ClinicPetUpdateManyWithoutSexModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSexModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSexModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySexModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSexModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSexModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSexModelInput>>;
};

export type ClinicPetUpdateManyWithoutSpeciesModelNestedInput = {
  connect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPetCreateOrConnectWithoutSpeciesModelInput>>;
  create?: Maybe<Array<ClinicPetCreateWithoutSpeciesModelInput>>;
  createMany?: Maybe<ClinicPetCreateManySpeciesModelInputEnvelope>;
  delete?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPetScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPetWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput>>;
  updateMany?: Maybe<Array<ClinicPetUpdateManyWithWhereWithoutSpeciesModelInput>>;
  upsert?: Maybe<Array<ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput>>;
};

export type ClinicPetUpdateOneRequiredWithoutCareBenefitUsagesNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCareBenefitUsagesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutCareBenefitUsagesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutClinicPetPimsSyncStatusNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutClinicPetPimsSyncStatusInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutClinicPetPimsSyncStatusInput>;
};

export type ClinicPetUpdateOneRequiredWithoutCustomFieldValuesNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutCustomFieldValuesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutCustomFieldValuesInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutCustomFieldValuesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutPrescriptionsInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutPrescriptionsInput>;
};

export type ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutReceivedTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutReceivedTrupanionExamDayOffersInput>;
};

export type ClinicPetUpdateOneRequiredWithoutTrupanionCertificateAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutTrupanionCertificateAvailabilitiesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutTrupanionCertificateAvailabilitiesInput>;
};

export type ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutVaccinationsInput>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutVaccinationsInput>;
};

export type ClinicPetUpdateOneWithoutAlertsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAlertsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAlertsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAlertsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAlertsInput>;
};

export type ClinicPetUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAppointmentsInput>;
};

export type ClinicPetUpdateOneWithoutAutomationRunActionsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutAutomationRunActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutAutomationRunActionsInput>;
};

export type ClinicPetUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutChannelAutomationStatusesInput>;
};

export type ClinicPetUpdateOneWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutFormSubmissionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutFormSubmissionsInput>;
};

export type ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutHillsToHomeApiLogInput>;
};

export type ClinicPetUpdateOneWithoutServiceRemindersNestedInput = {
  connect?: Maybe<ClinicPetWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPetCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPetUncheckedCreateWithoutServiceRemindersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPetUncheckedUpdateWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPetUpsertWithoutServiceRemindersInput>;
};

export type ClinicPetUpdateWithWhereUniqueWithoutBreedModelInput = {
  data: ClinicPetUncheckedUpdateWithoutBreedModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutChannelsInput = {
  data: ClinicPetUncheckedUpdateWithoutChannelsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicPetUncheckedUpdateWithoutClinicInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutColorModelInput = {
  data: ClinicPetUncheckedUpdateWithoutColorModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicPetUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutInvoiceLineItemsInput = {
  data: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutMediaInput = {
  data: ClinicPetUncheckedUpdateWithoutMediaInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  data: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutPetParentsInput = {
  data: ClinicPetUncheckedUpdateWithoutPetParentsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutSexModelInput = {
  data: ClinicPetUncheckedUpdateWithoutSexModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithWhereUniqueWithoutSpeciesModelInput = {
  data: ClinicPetUncheckedUpdateWithoutSpeciesModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpdateWithoutAlertsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutAppointmentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutAutomationRunActionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutBreedModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutCareBenefitUsagesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutChannelAutomationStatusesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutChannelsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutClinicInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutClinicPetPimsSyncStatusInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutColorModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutCustomFieldValuesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutFormSubmissionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutHillsToHomeApiLogInput = {
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutIntegrationInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutInvoiceLineItemsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutMediaInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutOrganizationPetInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutPetParentsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutPrescriptionsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutReceivedTrupanionExamDayOffersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutServiceRemindersInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutSexModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutSpeciesModelInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutTrupanionCertificateAvailabilitiesInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicPetNestedInput>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpdateWithoutVaccinationsInput = {
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput>;
  alerts?: Maybe<ClinicPetAlertUpdateManyWithoutClinicPetNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicPetNestedInput>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutPetNestedInput>;
  breed?: Maybe<Scalars['String']>;
  breedModel?: Maybe<BreedUpdateOneWithoutPetsNestedInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicPetNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicPetNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutPetsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicPetsNestedInput>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusUpdateManyWithoutClinicPetNestedInput>;
  color?: Maybe<Scalars['String']>;
  colorModel?: Maybe<ColorUpdateOneWithoutPetsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutClinicPetNestedInput>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  dateOfDeath?: Maybe<Scalars['DateTime']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicPetNestedInput>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrivacyProtected?: Maybe<Scalars['Boolean']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  media?: Maybe<MediaUpdateOneWithoutClinicPetsNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationPet?: Maybe<OrganizationPetUpdateOneWithoutClinicPetsNestedInput>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutPetsNestedInput>;
  photoUrl?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicPetNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutClinicPetNestedInput>;
  sexModel?: Maybe<SexUpdateOneWithoutPetsNestedInput>;
  species?: Maybe<Scalars['String']>;
  speciesModel?: Maybe<SpeciesUpdateOneWithoutPetsNestedInput>;
  speciesType?: Maybe<SpeciesType>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
  weightInKg?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ClinicPetUpsertWithWhereUniqueWithoutBreedModelInput = {
  create: ClinicPetUncheckedCreateWithoutBreedModelInput;
  update: ClinicPetUncheckedUpdateWithoutBreedModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutChannelsInput = {
  create: ClinicPetUncheckedCreateWithoutChannelsInput;
  update: ClinicPetUncheckedUpdateWithoutChannelsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicPetUncheckedCreateWithoutClinicInput;
  update: ClinicPetUncheckedUpdateWithoutClinicInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutColorModelInput = {
  create: ClinicPetUncheckedCreateWithoutColorModelInput;
  update: ClinicPetUncheckedUpdateWithoutColorModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicPetUncheckedCreateWithoutIntegrationInput;
  update: ClinicPetUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutInvoiceLineItemsInput = {
  create: ClinicPetUncheckedCreateWithoutInvoiceLineItemsInput;
  update: ClinicPetUncheckedUpdateWithoutInvoiceLineItemsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutMediaInput = {
  create: ClinicPetUncheckedCreateWithoutMediaInput;
  update: ClinicPetUncheckedUpdateWithoutMediaInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  create: ClinicPetUncheckedCreateWithoutOrganizationPetInput;
  update: ClinicPetUncheckedUpdateWithoutOrganizationPetInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutPetParentsInput = {
  create: ClinicPetUncheckedCreateWithoutPetParentsInput;
  update: ClinicPetUncheckedUpdateWithoutPetParentsInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutSexModelInput = {
  create: ClinicPetUncheckedCreateWithoutSexModelInput;
  update: ClinicPetUncheckedUpdateWithoutSexModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithWhereUniqueWithoutSpeciesModelInput = {
  create: ClinicPetUncheckedCreateWithoutSpeciesModelInput;
  update: ClinicPetUncheckedUpdateWithoutSpeciesModelInput;
  where: ClinicPetWhereUniqueInput;
};

export type ClinicPetUpsertWithoutAlertsInput = {
  create: ClinicPetUncheckedCreateWithoutAlertsInput;
  update: ClinicPetUncheckedUpdateWithoutAlertsInput;
};

export type ClinicPetUpsertWithoutAppointmentsInput = {
  create: ClinicPetUncheckedCreateWithoutAppointmentsInput;
  update: ClinicPetUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicPetUpsertWithoutAutomationRunActionsInput = {
  create: ClinicPetUncheckedCreateWithoutAutomationRunActionsInput;
  update: ClinicPetUncheckedUpdateWithoutAutomationRunActionsInput;
};

export type ClinicPetUpsertWithoutCareBenefitUsagesInput = {
  create: ClinicPetUncheckedCreateWithoutCareBenefitUsagesInput;
  update: ClinicPetUncheckedUpdateWithoutCareBenefitUsagesInput;
};

export type ClinicPetUpsertWithoutChannelAutomationStatusesInput = {
  create: ClinicPetUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: ClinicPetUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type ClinicPetUpsertWithoutClinicPetPimsSyncStatusInput = {
  create: ClinicPetUncheckedCreateWithoutClinicPetPimsSyncStatusInput;
  update: ClinicPetUncheckedUpdateWithoutClinicPetPimsSyncStatusInput;
};

export type ClinicPetUpsertWithoutCustomFieldValuesInput = {
  create: ClinicPetUncheckedCreateWithoutCustomFieldValuesInput;
  update: ClinicPetUncheckedUpdateWithoutCustomFieldValuesInput;
};

export type ClinicPetUpsertWithoutFormSubmissionsInput = {
  create: ClinicPetUncheckedCreateWithoutFormSubmissionsInput;
  update: ClinicPetUncheckedUpdateWithoutFormSubmissionsInput;
};

export type ClinicPetUpsertWithoutHillsToHomeApiLogInput = {
  create: ClinicPetUncheckedCreateWithoutHillsToHomeApiLogInput;
  update: ClinicPetUncheckedUpdateWithoutHillsToHomeApiLogInput;
};

export type ClinicPetUpsertWithoutPrescriptionsInput = {
  create: ClinicPetUncheckedCreateWithoutPrescriptionsInput;
  update: ClinicPetUncheckedUpdateWithoutPrescriptionsInput;
};

export type ClinicPetUpsertWithoutReceivedTrupanionExamDayOffersInput = {
  create: ClinicPetUncheckedCreateWithoutReceivedTrupanionExamDayOffersInput;
  update: ClinicPetUncheckedUpdateWithoutReceivedTrupanionExamDayOffersInput;
};

export type ClinicPetUpsertWithoutServiceRemindersInput = {
  create: ClinicPetUncheckedCreateWithoutServiceRemindersInput;
  update: ClinicPetUncheckedUpdateWithoutServiceRemindersInput;
};

export type ClinicPetUpsertWithoutTrupanionCertificateAvailabilitiesInput = {
  create: ClinicPetUncheckedCreateWithoutTrupanionCertificateAvailabilitiesInput;
  update: ClinicPetUncheckedUpdateWithoutTrupanionCertificateAvailabilitiesInput;
};

export type ClinicPetUpsertWithoutVaccinationsInput = {
  create: ClinicPetUncheckedCreateWithoutVaccinationsInput;
  update: ClinicPetUncheckedUpdateWithoutVaccinationsInput;
};

export type ClinicPetWhereInput = {
  AND?: Maybe<Array<ClinicPetWhereInput>>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  NOT?: Maybe<Array<ClinicPetWhereInput>>;
  OR?: Maybe<Array<ClinicPetWhereInput>>;
  alerts?: Maybe<ClinicPetAlertListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  breed?: Maybe<StringNullableFilter>;
  breedId?: Maybe<StringNullableFilter>;
  breedModel?: Maybe<BreedWhereInput>;
  careBenefitUsages?: Maybe<CareBenefitUsageListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatusListRelationFilter>;
  color?: Maybe<StringNullableFilter>;
  colorId?: Maybe<StringNullableFilter>;
  colorModel?: Maybe<ColorWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  customFieldValues?: Maybe<ClinicPetCustomFieldValueListRelationFilter>;
  dateOfBirth?: Maybe<DateTimeNullableFilter>;
  dateOfDeath?: Maybe<DateTimeNullableFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  gender?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeceased?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isPrivacyProtected?: Maybe<BoolNullableFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  media?: Maybe<MediaWhereInput>;
  mediaId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetId?: Maybe<StringNullableFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  photoUrl?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  receivedTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  sexId?: Maybe<StringNullableFilter>;
  sexModel?: Maybe<SexWhereInput>;
  species?: Maybe<StringNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  speciesModel?: Maybe<SpeciesWhereInput>;
  speciesType?: Maybe<EnumSpeciesTypeFilter>;
  trupanionCertificateAvailabilities?: Maybe<TrupanionCertificateAvailabilityListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  weight?: Maybe<FloatNullableFilter>;
  weightInKg?: Maybe<FloatNullableFilter>;
  weightUnit?: Maybe<StringNullableFilter>;
};

export type ClinicPetWhereUniqueInput = {
  clinicPetPimsIdAndIntegrationId?: Maybe<ClinicPetClinicPetPimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicPhoneNumber = {
  __typename?: 'ClinicPhoneNumber';
  _count: ClinicPhoneNumberCountOutputType;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<User>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses: Array<SmsNotificationStatus>;
  status: PhoneNumberStatus;
  updatedAt: Scalars['DateTime'];
};


export type ClinicPhoneNumberSmsNotificationStatusesArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  distinct?: Maybe<SmsNotificationStatusScalarFieldEnum>;
  orderBy?: Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};

export type ClinicPhoneNumberCapabilityAndNumberAndProviderCompoundUniqueInput = {
  capability: ServiceCapability;
  number: Scalars['String'];
  provider: ServiceProvider;
};

export type ClinicPhoneNumberCountAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberCountAggregateOutputType';
  _all: Scalars['Int'];
  campaignRegistryCampaignId: Scalars['Int'];
  capability: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  incomingCarrier: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPrimary: Scalars['Int'];
  isTestSmsDelivered: Scalars['Int'];
  isVerified: Scalars['Int'];
  loaSignedAt: Scalars['Int'];
  loaSignedByUserId: Scalars['Int'];
  loaUrl: Scalars['Int'];
  number: Scalars['Int'];
  portingOrderId: Scalars['Int'];
  portingOrderStatus: Scalars['Int'];
  portingStartAt: Scalars['Int'];
  provider: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPhoneNumberCountOrderByAggregateInput = {
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberCountOutputType = {
  __typename?: 'ClinicPhoneNumberCountOutputType';
  smsNotificationStatuses: Scalars['Int'];
};

export type ClinicPhoneNumberCreateInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
  capability: ServiceCapability;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateManyCampaignRegistryCampaignInput = {
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyClinicInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateManyClinicInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateManyInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateManyLoaSignedByUserInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope = {
  data: Array<ClinicPhoneNumberCreateManyLoaSignedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutCampaignRegistryCampaignInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
};

export type ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
};

export type ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput = {
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
};

export type ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutClinicInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput = {
  capability: ServiceCapability;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateWithoutClinicInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
  capability: ServiceCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateWithoutLoaSignedByUserInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
  capability: ServiceCapability;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberCreateWithoutSmsNotificationStatusesInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignCreateNestedOneWithoutClinicPhoneNumbersInput>;
  capability: ServiceCapability;
  clinic: ClinicCreateNestedOneWithoutClinicPhoneNumberInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberListRelationFilter = {
  every?: Maybe<ClinicPhoneNumberWhereInput>;
  none?: Maybe<ClinicPhoneNumberWhereInput>;
  some?: Maybe<ClinicPhoneNumberWhereInput>;
};

export type ClinicPhoneNumberMaxAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMaxAggregateOutputType';
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberMaxOrderByAggregateInput = {
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberMinAggregateOutputType = {
  __typename?: 'ClinicPhoneNumberMinAggregateOutputType';
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberMinOrderByAggregateInput = {
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPhoneNumberOrderByRelevanceFieldEnum {
  CampaignRegistryCampaignId = 'campaignRegistryCampaignId',
  ClinicId = 'clinicId',
  Id = 'id',
  IncomingCarrier = 'incomingCarrier',
  LoaSignedByUserId = 'loaSignedByUserId',
  LoaUrl = 'loaUrl',
  Number = 'number',
  PortingOrderId = 'portingOrderId',
  PortingOrderStatus = 'portingOrderStatus'
}

export type ClinicPhoneNumberOrderByRelevanceInput = {
  fields: Array<ClinicPhoneNumberOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPhoneNumberOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPhoneNumberCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPhoneNumberMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPhoneNumberMinOrderByAggregateInput>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPhoneNumberOrderByRelevanceInput>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>;
  campaignRegistryCampaignId?: Maybe<SortOrder>;
  capability?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  incomingCarrier?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPrimary?: Maybe<SortOrder>;
  isTestSmsDelivered?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  loaSignedAt?: Maybe<SortOrder>;
  loaSignedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  loaSignedByUserId?: Maybe<SortOrder>;
  loaUrl?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  portingOrderId?: Maybe<SortOrder>;
  portingOrderStatus?: Maybe<SortOrder>;
  portingStartAt?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPhoneNumberRelationFilter = {
  is?: Maybe<ClinicPhoneNumberWhereInput>;
  isNot?: Maybe<ClinicPhoneNumberWhereInput>;
};

export enum ClinicPhoneNumberScalarFieldEnum {
  CampaignRegistryCampaignId = 'campaignRegistryCampaignId',
  Capability = 'capability',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IncomingCarrier = 'incomingCarrier',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IsPrimary = 'isPrimary',
  IsTestSmsDelivered = 'isTestSmsDelivered',
  IsVerified = 'isVerified',
  LoaSignedAt = 'loaSignedAt',
  LoaSignedByUserId = 'loaSignedByUserId',
  LoaUrl = 'loaUrl',
  Number = 'number',
  PortingOrderId = 'portingOrderId',
  PortingOrderStatus = 'portingOrderStatus',
  PortingStartAt = 'portingStartAt',
  Provider = 'provider',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ClinicPhoneNumberScalarWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  campaignRegistryCampaignId?: Maybe<StringNullableFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  incomingCarrier?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isTestSmsDelivered?: Maybe<BoolFilter>;
  isVerified?: Maybe<BoolFilter>;
  loaSignedAt?: Maybe<DateTimeNullableFilter>;
  loaSignedByUserId?: Maybe<StringNullableFilter>;
  loaUrl?: Maybe<StringNullableFilter>;
  number?: Maybe<StringFilter>;
  portingOrderId?: Maybe<StringNullableFilter>;
  portingOrderStatus?: Maybe<StringNullableFilter>;
  portingStartAt?: Maybe<DateTimeNullableFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPhoneNumberScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberScalarWhereWithAggregatesInput>>;
  campaignRegistryCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
  capability?: Maybe<EnumServiceCapabilityWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  incomingCarrier?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isPrimary?: Maybe<BoolWithAggregatesFilter>;
  isTestSmsDelivered?: Maybe<BoolWithAggregatesFilter>;
  isVerified?: Maybe<BoolWithAggregatesFilter>;
  loaSignedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  loaSignedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  loaUrl?: Maybe<StringNullableWithAggregatesFilter>;
  number?: Maybe<StringWithAggregatesFilter>;
  portingOrderId?: Maybe<StringNullableWithAggregatesFilter>;
  portingOrderStatus?: Maybe<StringNullableWithAggregatesFilter>;
  portingStartAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  provider?: Maybe<EnumServiceProviderWithAggregatesFilter>;
  status?: Maybe<EnumPhoneNumberStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPhoneNumberSubscription = {
  __typename?: 'ClinicPhoneNumberSubscription';
  mutation: MutationType;
  node: ClinicPhoneNumber;
};

export type ClinicPhoneNumberUncheckedCreateInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutCampaignRegistryCampaignInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
};

export type ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput = {
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutClinicInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput>;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability: ServiceCapability;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary: Scalars['Boolean'];
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider: ServiceProvider;
  status: PhoneNumberStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutCampaignRegistryCampaignNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumbersInput = {
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput>>;
};

export type ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedClinicPhoneNumbersInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput = {
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutClinicInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput = {
  campaignRegistryCampaignId?: Maybe<Scalars['String']>;
  capability?: Maybe<ServiceCapability>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUserId?: Maybe<Scalars['String']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
  capability?: Maybe<ServiceCapability>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateManyMutationInput = {
  capability?: Maybe<ServiceCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput = {
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumbersInput;
  where: ClinicPhoneNumberScalarWhereInput;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutClinicPhoneNumberInput;
  where: ClinicPhoneNumberScalarWhereInput;
};

export type ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput = {
  data: ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedClinicPhoneNumbersInput;
  where: ClinicPhoneNumberScalarWhereInput;
};

export type ClinicPhoneNumberUpdateManyWithoutCampaignRegistryCampaignNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutCampaignRegistryCampaignInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutCampaignRegistryCampaignInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyCampaignRegistryCampaignInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutCampaignRegistryCampaignInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput>>;
};

export type ClinicPhoneNumberUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput = {
  connect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPhoneNumberCreateOrConnectWithoutLoaSignedByUserInput>>;
  create?: Maybe<Array<ClinicPhoneNumberCreateWithoutLoaSignedByUserInput>>;
  createMany?: Maybe<ClinicPhoneNumberCreateManyLoaSignedByUserInputEnvelope>;
  delete?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPhoneNumberScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPhoneNumberWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput>>;
  updateMany?: Maybe<Array<ClinicPhoneNumberUpdateManyWithWhereWithoutLoaSignedByUserInput>>;
  upsert?: Maybe<Array<ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput>>;
};

export type ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput = {
  connect?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPhoneNumberCreateOrConnectWithoutSmsNotificationStatusesInput>;
  create?: Maybe<ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput>;
  upsert?: Maybe<ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput>;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutCampaignRegistryCampaignInput = {
  data: ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpdateWithWhereUniqueWithoutLoaSignedByUserInput = {
  data: ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpdateWithoutCampaignRegistryCampaignInput = {
  capability?: Maybe<ServiceCapability>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateWithoutClinicInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
  capability?: Maybe<ServiceCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateWithoutLoaSignedByUserInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
  capability?: Maybe<ServiceCapability>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpdateWithoutSmsNotificationStatusesInput = {
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignUpdateOneWithoutClinicPhoneNumbersNestedInput>;
  capability?: Maybe<ServiceCapability>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  incomingCarrier?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isTestSmsDelivered?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  loaSignedAt?: Maybe<Scalars['DateTime']>;
  loaSignedByUser?: Maybe<UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput>;
  loaUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  portingOrderId?: Maybe<Scalars['String']>;
  portingOrderStatus?: Maybe<Scalars['String']>;
  portingStartAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<ServiceProvider>;
  status?: Maybe<PhoneNumberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutCampaignRegistryCampaignInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutCampaignRegistryCampaignInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutCampaignRegistryCampaignInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutClinicInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutClinicInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpsertWithWhereUniqueWithoutLoaSignedByUserInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutLoaSignedByUserInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutLoaSignedByUserInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};

export type ClinicPhoneNumberUpsertWithoutSmsNotificationStatusesInput = {
  create: ClinicPhoneNumberUncheckedCreateWithoutSmsNotificationStatusesInput;
  update: ClinicPhoneNumberUncheckedUpdateWithoutSmsNotificationStatusesInput;
};

export type ClinicPhoneNumberWhereInput = {
  AND?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  NOT?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  OR?: Maybe<Array<ClinicPhoneNumberWhereInput>>;
  campaignRegistryCampaign?: Maybe<CampaignRegistryCampaignWhereInput>;
  campaignRegistryCampaignId?: Maybe<StringNullableFilter>;
  capability?: Maybe<EnumServiceCapabilityFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  incomingCarrier?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPrimary?: Maybe<BoolFilter>;
  isTestSmsDelivered?: Maybe<BoolFilter>;
  isVerified?: Maybe<BoolFilter>;
  loaSignedAt?: Maybe<DateTimeNullableFilter>;
  loaSignedByUser?: Maybe<UserWhereInput>;
  loaSignedByUserId?: Maybe<StringNullableFilter>;
  loaUrl?: Maybe<StringNullableFilter>;
  number?: Maybe<StringFilter>;
  portingOrderId?: Maybe<StringNullableFilter>;
  portingOrderStatus?: Maybe<StringNullableFilter>;
  portingStartAt?: Maybe<DateTimeNullableFilter>;
  provider?: Maybe<EnumServiceProviderFilter>;
  smsNotificationStatuses?: Maybe<SmsNotificationStatusListRelationFilter>;
  status?: Maybe<EnumPhoneNumberStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPhoneNumberWhereUniqueInput = {
  capabilityAndNumberAndProvider?: Maybe<ClinicPhoneNumberCapabilityAndNumberAndProviderCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegration = {
  __typename?: 'ClinicPimsIntegration';
  _count: ClinicPimsIntegrationCountOutputType;
  appointmentTypes: Array<AppointmentType>;
  appointments: Array<Appointment>;
  arePostcardsSupported: Scalars['Boolean'];
  availabilities: Array<AppointmentAvailability>;
  bitwerx?: Maybe<BitwerxIntegration>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  breeds: Array<Breed>;
  capabilities: Array<ClinicPimsIntegrationCapability>;
  capabilityHistoryEntries: Array<ClinicPimsIntegrationCapabilityHistoryEntry>;
  clientConnect?: Maybe<ClientConnectIntegration>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  colors: Array<Color>;
  createdAt: Scalars['DateTime'];
  customFieldDefinitions: Array<CustomFieldDefinition>;
  datapoint?: Maybe<DataPointIntegration>;
  datapointId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  employees: Array<ClinicEmployee>;
  ezyvet?: Maybe<EzyVetIntegration>;
  ezyvetId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instinct?: Maybe<InstinctIntegration>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry: Array<IntegrationOutageHistoryEntry>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBillingCodeSupported: Scalars['Boolean'];
  isClientIdSupported: Scalars['Boolean'];
  isContactSyncSupported: Scalars['Boolean'];
  isExportToPimsSupported: Scalars['Boolean'];
  isInvoiceDiscoverySupported: Scalars['Boolean'];
  isInvoicePreviewSupported: Scalars['Boolean'];
  isMergeExistingPimsProfileSupported: Scalars['Boolean'];
  isPatientIdSupported: Scalars['Boolean'];
  isServiceCodeSupported: Scalars['Boolean'];
  isTriggerPimsSyncSupported: Scalars['Boolean'];
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegration>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents: Array<ClinicPetParent>;
  pets: Array<ClinicPet>;
  pimsInvoiceLineItems: Array<PimsInvoiceLineItem>;
  pimsInvoices: Array<PimsInvoice>;
  pimsPaymentTypes: Array<PimsPaymentType>;
  ppc?: Maybe<PpcIntegration>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions: Array<Prescription>;
  rooms: Array<ClinicRoom>;
  serviceCategories: Array<ServiceCategory>;
  serviceReminders: Array<ServiceReminder>;
  services: Array<Service>;
  sexes: Array<Sex>;
  species: Array<Species>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency: PimsSyncFrequency;
  syncvet?: Maybe<SyncVetIntegration>;
  syncvetId?: Maybe<Scalars['String']>;
  systemName: Scalars['String'];
  type: ClinicPimsIntegrationType;
  updatedAt: Scalars['DateTime'];
  vaccinations: Array<Vaccination>;
  vetAvailabilities: Array<VetAvailability>;
  vetdata?: Maybe<VetDataIntegration>;
  vetdataId?: Maybe<Scalars['String']>;
  vetter?: Maybe<VetterIntegration>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration: Scalars['Json'];
  writebackLogEntries: Array<PimsWritebackLogEntry>;
};


export type ClinicPimsIntegrationAppointmentTypesArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type ClinicPimsIntegrationAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicPimsIntegrationAvailabilitiesArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type ClinicPimsIntegrationBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type ClinicPimsIntegrationBreedsArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  distinct?: Maybe<BreedScalarFieldEnum>;
  orderBy?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type ClinicPimsIntegrationCapabilitiesArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  distinct?: Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>;
  orderBy?: Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type ClinicPimsIntegrationCapabilityHistoryEntriesArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type ClinicPimsIntegrationColorsArgs = {
  cursor?: Maybe<ColorWhereUniqueInput>;
  distinct?: Maybe<ColorScalarFieldEnum>;
  orderBy?: Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ColorWhereInput>;
};


export type ClinicPimsIntegrationCustomFieldDefinitionsArgs = {
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  distinct?: Maybe<CustomFieldDefinitionScalarFieldEnum>;
  orderBy?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type ClinicPimsIntegrationEmployeesArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type ClinicPimsIntegrationIntegrationOutageHistoryEntryArgs = {
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type ClinicPimsIntegrationPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type ClinicPimsIntegrationPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type ClinicPimsIntegrationPimsInvoiceLineItemsArgs = {
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<PimsInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type ClinicPimsIntegrationPimsInvoicesArgs = {
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  distinct?: Maybe<PimsInvoiceScalarFieldEnum>;
  orderBy?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type ClinicPimsIntegrationPimsPaymentTypesArgs = {
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  distinct?: Maybe<PimsPaymentTypeScalarFieldEnum>;
  orderBy?: Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsPaymentTypeWhereInput>;
};


export type ClinicPimsIntegrationPrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type ClinicPimsIntegrationRoomsArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type ClinicPimsIntegrationServiceCategoriesArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<ServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type ClinicPimsIntegrationServiceRemindersArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type ClinicPimsIntegrationServicesArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type ClinicPimsIntegrationSexesArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  distinct?: Maybe<SexScalarFieldEnum>;
  orderBy?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};


export type ClinicPimsIntegrationSpeciesArgs = {
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  distinct?: Maybe<SpeciesScalarFieldEnum>;
  orderBy?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpeciesWhereInput>;
};


export type ClinicPimsIntegrationVaccinationsArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<VaccinationScalarFieldEnum>;
  orderBy?: Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type ClinicPimsIntegrationVetAvailabilitiesArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type ClinicPimsIntegrationWritebackLogEntriesArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type ClinicPimsIntegrationCapability = {
  __typename?: 'ClinicPimsIntegrationCapability';
  capability: PimsIntegrationCapability;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ClinicPimsIntegrationCapabilityAvgAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityAvgAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Float']>;
};

export type ClinicPimsIntegrationCapabilityAvgOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityCountAggregateOutputType';
  _all: Scalars['Int'];
  capability: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  syncFrequencyInMinutes: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPimsIntegrationCapabilityCountOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityCreateInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityCreateManyInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPimsIntegrationCapabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
};

export type ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput = {
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntry = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntry';
  capability: PimsIntegrationCapability;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryAvgAggregateOutputType';
  recordsSync?: Maybe<Scalars['Float']>;
  syncDuration?: Maybe<Scalars['Float']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryAvgOrderByAggregateInput = {
  recordsSync?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  capability: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  recordsSync: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  syncDuration: Scalars['Int'];
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCountOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutCapabilityHistoryEntriesInput;
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput = {
  create: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  none?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
  some?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryMaxAggregateOutputType';
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMaxOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntryMinAggregateOutputType';
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryMinOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  Status = 'status',
  StatusMessage = 'statusMessage'
}

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntrySumOrderByAggregateInput>;
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelevanceInput>;
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  recordsSync?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum {
  Capability = 'capability',
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  RecordsSync = 'recordsSync',
  Status = 'status',
  StatusMessage = 'statusMessage',
  SyncDuration = 'syncDuration'
}

export type ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  recordsSync?: Maybe<IntFilter>;
  status?: Maybe<StringFilter>;
  statusMessage?: Maybe<StringNullableFilter>;
  syncDuration?: Maybe<IntNullableFilter>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereWithAggregatesInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  recordsSync?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  statusMessage?: Maybe<StringNullableWithAggregatesFilter>;
  syncDuration?: Maybe<IntNullableWithAggregatesFilter>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityHistoryEntrySumAggregateOutputType';
  recordsSync?: Maybe<Scalars['Int']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntrySumOrderByAggregateInput = {
  recordsSync?: Maybe<SortOrder>;
  syncDuration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync: Scalars['Int'];
  status: Scalars['String'];
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutCapabilityHistoryEntriesInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilityHistoryEntriesNestedInput>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyMutationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutCapabilityHistoryEntriesInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpdateWithoutIntegrationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recordsSync?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  syncDuration?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateWithoutIntegrationInput;
  update: ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  recordsSync?: Maybe<IntFilter>;
  status?: Maybe<StringFilter>;
  statusMessage?: Maybe<StringNullableFilter>;
  syncDuration?: Maybe<IntNullableFilter>;
};

export type ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput = {
  capability: PimsIntegrationCapability;
  integrationId: Scalars['String'];
};

export type ClinicPimsIntegrationCapabilityListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  none?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
  some?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};

export type ClinicPimsIntegrationCapabilityMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMaxAggregateOutputType';
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilityMinAggregateOutputType';
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityMinOrderByAggregateInput = {
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  StatusErrorMessage = 'statusErrorMessage'
}

export type ClinicPimsIntegrationCapabilityOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationCapabilityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPimsIntegrationCapabilityOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicPimsIntegrationCapabilityAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicPimsIntegrationCapabilityCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationCapabilityMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationCapabilityMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicPimsIntegrationCapabilitySumOrderByAggregateInput>;
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelevanceInput>;
  capability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationCapabilityScalarFieldEnum {
  Capability = 'capability',
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  LastSyncedAt = 'lastSyncedAt',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  SyncFrequencyInMinutes = 'syncFrequencyInMinutes',
  UpdatedAt = 'updatedAt'
}

export type ClinicPimsIntegrationCapabilityScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereWithAggregatesInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPimsIntegrationCapabilitySumAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCapabilitySumAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
};

export type ClinicPimsIntegrationCapabilitySumOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
};

export type ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput = {
  capability: PimsIntegrationCapability;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUpdateInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyMutationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutCapabilitiesInput;
  where: ClinicPimsIntegrationCapabilityScalarWhereInput;
};

export type ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCapabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCapabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationCapabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationCapabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicPimsIntegrationCapabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityUpdateWithoutIntegrationInput = {
  capability?: Maybe<PimsIntegrationCapability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPimsIntegrationCapabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicPimsIntegrationCapabilityUncheckedCreateWithoutIntegrationInput;
  update: ClinicPimsIntegrationCapabilityUncheckedUpdateWithoutIntegrationInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};

export type ClinicPimsIntegrationCapabilityWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationCapabilityWhereInput>>;
  capability?: Maybe<EnumPimsIntegrationCapabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  syncFrequencyInMinutes?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPimsIntegrationCapabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_capability?: Maybe<ClinicPimsIntegrationCapabilityIntegrationIdCapabilityCompoundUniqueInput>;
};

export type ClinicPimsIntegrationCountAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  bitwerxId: Scalars['Int'];
  clientConnectId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  datapointId: Scalars['Int'];
  ezyvetId: Scalars['Int'];
  id: Scalars['Int'];
  instinctId: Scalars['Int'];
  isActive: Scalars['Int'];
  lastBatchSyncedAt: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  neoId: Scalars['Int'];
  pauseUntil: Scalars['Int'];
  ppcId: Scalars['Int'];
  status: Scalars['Int'];
  statusChangedAt: Scalars['Int'];
  statusErrorMessage: Scalars['Int'];
  syncFrequency: Scalars['Int'];
  syncvetId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  vetdataId: Scalars['Int'];
  vetterId: Scalars['Int'];
  writebackConfiguration: Scalars['Int'];
};

export type ClinicPimsIntegrationCountOrderByAggregateInput = {
  bitwerxId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationCountOutputType = {
  __typename?: 'ClinicPimsIntegrationCountOutputType';
  appointmentTypes: Scalars['Int'];
  appointments: Scalars['Int'];
  availabilities: Scalars['Int'];
  bitwerxPaymentWritebackConfigurations: Scalars['Int'];
  breeds: Scalars['Int'];
  capabilities: Scalars['Int'];
  capabilityHistoryEntries: Scalars['Int'];
  colors: Scalars['Int'];
  customFieldDefinitions: Scalars['Int'];
  employees: Scalars['Int'];
  integrationOutageHistoryEntry: Scalars['Int'];
  petParents: Scalars['Int'];
  pets: Scalars['Int'];
  pimsInvoiceLineItems: Scalars['Int'];
  pimsInvoices: Scalars['Int'];
  pimsPaymentTypes: Scalars['Int'];
  prescriptions: Scalars['Int'];
  rooms: Scalars['Int'];
  serviceCategories: Scalars['Int'];
  serviceReminders: Scalars['Int'];
  services: Scalars['Int'];
  sexes: Scalars['Int'];
  species: Scalars['Int'];
  vaccinations: Scalars['Int'];
  vetAvailabilities: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
};

export type ClinicPimsIntegrationCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateManyClinicInput = {
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateManyClinicInputEnvelope = {
  data: Array<ClinicPimsIntegrationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPimsIntegrationCreateManyInput = {
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentTypesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutAvailabilitiesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBitwerxInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutBreedsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCapabilitiesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCapabilityHistoryEntriesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutClientConnectInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutColorsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutColorsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutColorsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEmployeesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutIntegrationOutageHistoryEntryInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutNeoInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetParentsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPpcInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutServicesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSexesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSexesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSexesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutSyncvetInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutVetterInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
};

export type ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutClinicInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutColorsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutColorsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutNeoInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPetsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPpcInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutServicesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSexesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSexesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutVetterInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentTypesInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutAppointmentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBitwerxInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutBreedsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCapabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCapabilityHistoryEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutClientConnectInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutColorsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutCustomFieldDefinitionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutDatapointInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutEzyvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutInstinctInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutIntegrationOutageHistoryEntryInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutNeoInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetParentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPetsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoiceLineItemsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsInvoicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPimsPaymentTypesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPpcInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutPrescriptionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServiceCategoriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServiceRemindersInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutServicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSexesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSpeciesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutSyncvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVaccinationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetdataInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutVetterInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationCreateWithoutWritebackLogEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutIntegrationInput>;
  bitwerx?: Maybe<BitwerxIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  clientConnect?: Maybe<ClientConnectIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutIntegrationsInput>;
  colors?: Maybe<ColorCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapoint?: Maybe<DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeCreateNestedManyWithoutIntegrationInput>;
  ezyvet?: Maybe<EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeCreateNestedManyWithoutIntegrationInput>;
  ppc?: Maybe<PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityCreateNestedManyWithoutIntegrationInput>;
  vetdata?: Maybe<VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  vetter?: Maybe<VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationListRelationFilter = {
  every?: Maybe<ClinicPimsIntegrationWhereInput>;
  none?: Maybe<ClinicPimsIntegrationWhereInput>;
  some?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export type ClinicPimsIntegrationMaxAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMaxAggregateOutputType';
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationMaxOrderByAggregateInput = {
  bitwerxId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationMinAggregateOutputType = {
  __typename?: 'ClinicPimsIntegrationMinAggregateOutputType';
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
};

export type ClinicPimsIntegrationMinOrderByAggregateInput = {
  bitwerxId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPimsIntegrationOrderByRelevanceFieldEnum {
  BitwerxId = 'bitwerxId',
  ClientConnectId = 'clientConnectId',
  ClinicId = 'clinicId',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  Id = 'id',
  InstinctId = 'instinctId',
  NeoId = 'neoId',
  PpcId = 'ppcId',
  StatusErrorMessage = 'statusErrorMessage',
  SyncvetId = 'syncvetId',
  VetdataId = 'vetdataId',
  VetterId = 'vetterId'
}

export type ClinicPimsIntegrationOrderByRelevanceInput = {
  fields: Array<ClinicPimsIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPimsIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPimsIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPimsIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPimsIntegrationMinOrderByAggregateInput>;
  bitwerxId?: Maybe<SortOrder>;
  clientConnectId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  datapointId?: Maybe<SortOrder>;
  ezyvetId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instinctId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  neoId?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  ppcId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetdataId?: Maybe<SortOrder>;
  vetterId?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
};

export type ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPimsIntegrationOrderByRelevanceInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  availabilities?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  bitwerx?: Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  bitwerxId?: Maybe<SortOrder>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  breeds?: Maybe<BreedOrderByRelationAggregateInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityOrderByRelationAggregateInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByRelationAggregateInput>;
  clientConnect?: Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clientConnectId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  colors?: Maybe<ColorOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionOrderByRelationAggregateInput>;
  datapoint?: Maybe<DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  datapointId?: Maybe<SortOrder>;
  employees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  ezyvet?: Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ezyvetId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instinct?: Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  instinctId?: Maybe<SortOrder>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryOrderByRelationAggregateInput>;
  isActive?: Maybe<SortOrder>;
  lastBatchSyncedAt?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  neo?: Maybe<NeoIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  neoId?: Maybe<SortOrder>;
  pauseUntil?: Maybe<SortOrder>;
  petParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemOrderByRelationAggregateInput>;
  pimsInvoices?: Maybe<PimsInvoiceOrderByRelationAggregateInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeOrderByRelationAggregateInput>;
  ppc?: Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  ppcId?: Maybe<SortOrder>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  serviceCategories?: Maybe<ServiceCategoryOrderByRelationAggregateInput>;
  serviceReminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  sexes?: Maybe<SexOrderByRelationAggregateInput>;
  species?: Maybe<SpeciesOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  statusChangedAt?: Maybe<SortOrder>;
  statusErrorMessage?: Maybe<SortOrder>;
  syncFrequency?: Maybe<SortOrder>;
  syncvet?: Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  syncvetId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vaccinations?: Maybe<VaccinationOrderByRelationAggregateInput>;
  vetAvailabilities?: Maybe<VetAvailabilityOrderByRelationAggregateInput>;
  vetdata?: Maybe<VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetdataId?: Maybe<SortOrder>;
  vetter?: Maybe<VetterIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  vetterId?: Maybe<SortOrder>;
  writebackConfiguration?: Maybe<SortOrder>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
};

export type ClinicPimsIntegrationRelationFilter = {
  is?: Maybe<ClinicPimsIntegrationWhereInput>;
  isNot?: Maybe<ClinicPimsIntegrationWhereInput>;
};

export enum ClinicPimsIntegrationScalarFieldEnum {
  BitwerxId = 'bitwerxId',
  ClientConnectId = 'clientConnectId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DatapointId = 'datapointId',
  EzyvetId = 'ezyvetId',
  Id = 'id',
  InstinctId = 'instinctId',
  IsActive = 'isActive',
  LastBatchSyncedAt = 'lastBatchSyncedAt',
  LastSyncedAt = 'lastSyncedAt',
  NeoId = 'neoId',
  PauseUntil = 'pauseUntil',
  PpcId = 'ppcId',
  Status = 'status',
  StatusChangedAt = 'statusChangedAt',
  StatusErrorMessage = 'statusErrorMessage',
  SyncFrequency = 'syncFrequency',
  SyncvetId = 'syncvetId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  VetdataId = 'vetdataId',
  VetterId = 'vetterId',
  WritebackConfiguration = 'writebackConfiguration'
}

export type ClinicPimsIntegrationScalarWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  bitwerxId?: Maybe<StringNullableFilter>;
  clientConnectId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  datapointId?: Maybe<StringNullableFilter>;
  ezyvetId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  instinctId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  neoId?: Maybe<StringNullableFilter>;
  pauseUntil?: Maybe<DateTimeNullableFilter>;
  ppcId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  syncvetId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetdataId?: Maybe<StringNullableFilter>;
  vetterId?: Maybe<StringNullableFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
};

export type ClinicPimsIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationScalarWhereWithAggregatesInput>>;
  bitwerxId?: Maybe<StringNullableWithAggregatesFilter>;
  clientConnectId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  datapointId?: Maybe<StringNullableWithAggregatesFilter>;
  ezyvetId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  instinctId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  neoId?: Maybe<StringNullableWithAggregatesFilter>;
  pauseUntil?: Maybe<DateTimeNullableWithAggregatesFilter>;
  ppcId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableWithAggregatesFilter>;
  statusChangedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  statusErrorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyWithAggregatesFilter>;
  syncvetId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetdataId?: Maybe<StringNullableWithAggregatesFilter>;
  vetterId?: Maybe<StringNullableWithAggregatesFilter>;
  writebackConfiguration?: Maybe<JsonWithAggregatesFilter>;
};

export enum ClinicPimsIntegrationType {
  Bitwerx = 'Bitwerx',
  ClientConnect = 'ClientConnect',
  Datapoint = 'Datapoint',
  Ezyvet = 'Ezyvet',
  Instinct = 'Instinct',
  Neo = 'Neo',
  Ppc = 'PPC',
  SyncVet = 'SyncVet',
  Televet = 'Televet',
  Vetdata = 'Vetdata',
  Vetter = 'Vetter'
}

export type ClinicPimsIntegrationUncheckedCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutBitwerxInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutClientConnectInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutSyncvetInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutColorsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutNeoInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPetsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPpcInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutServicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSexesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutVetterInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
};

export type ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutIntegrationInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutIntegrationInput>;
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedCreateNestedManyWithoutIntegrationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutIntegrationInput>;
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput>;
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutIntegrationInput>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutIntegrationInput>;
  species?: Maybe<SpeciesUncheckedCreateNestedManyWithoutIntegrationInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type: ClinicPimsIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyInput = {
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput = {
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  ppcId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutBitwerxNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutClientConnectNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutSyncvetNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  bitwerxId?: Maybe<Scalars['String']>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  clientConnectId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  colors?: Maybe<ColorUncheckedUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapointId?: Maybe<Scalars['String']>;
  employees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neoId?: Maybe<Scalars['String']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput>;
  ppcId?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvetId?: Maybe<Scalars['String']>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicPimsIntegrationUncheckedUpdateManyWithoutIntegrationsInput;
  where: ClinicPimsIntegrationScalarWhereInput;
};

export type ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPimsIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPimsIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPimsIntegrationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPimsIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPimsIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPimsIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxPaymentWritebackConfigurationsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutBreedsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBreedsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBreedsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilitiesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCapabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCapabilityHistoryEntriesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCapabilityHistoryEntriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCapabilityHistoryEntriesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutColorsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutColorsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutColorsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutCustomFieldDefinitionsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutCustomFieldDefinitionsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutIntegrationOutageHistoryEntryNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutIntegrationOutageHistoryEntryInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutIntegrationOutageHistoryEntryInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoiceLineItemsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsInvoicesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPimsPaymentTypesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPimsPaymentTypesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPrescriptionsInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceCategoriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServiceCategoriesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServiceRemindersInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServiceRemindersInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutServicesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutServicesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutServicesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSexesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSexesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSexesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSpeciesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSpeciesInput>;
};

export type ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutWritebackLogEntriesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentTypesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAppointmentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutAvailabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutAvailabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutBitwerxNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutBitwerxInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutBitwerxInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutClientConnectNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutClientConnectInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutClientConnectInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutDatapointNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutDatapointInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutDatapointInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEmployeesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEmployeesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutEzyvetNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutEzyvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutEzyvetInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutInstinctNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutInstinctInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutInstinctInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutNeoNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutNeoInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutNeoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutNeoInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetParentsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetParentsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetParentsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPetsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPetsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutPpcNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutPpcInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutPpcInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutPpcInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutRoomsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutRoomsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutSyncvetNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutSyncvetInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutSyncvetInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVaccinationsInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetAvailabilitiesInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetdataNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetdataInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetdataInput>;
};

export type ClinicPimsIntegrationUpdateOneWithoutVetterNestedInput = {
  connect?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicPimsIntegrationCreateOrConnectWithoutVetterInput>;
  create?: Maybe<ClinicPimsIntegrationUncheckedCreateWithoutVetterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput>;
  upsert?: Maybe<ClinicPimsIntegrationUpsertWithoutVetterInput>;
};

export type ClinicPimsIntegrationUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentTypesInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutAppointmentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBitwerxInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBitwerxPaymentWritebackConfigurationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutBreedsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCapabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCapabilityHistoryEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutClientConnectInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutColorsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutCustomFieldDefinitionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutDatapointInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutEzyvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutInstinctInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutIntegrationOutageHistoryEntryInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutNeoInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetParentsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPetsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoiceLineItemsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsInvoicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPimsPaymentTypesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPpcInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutPrescriptionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServiceCategoriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServiceRemindersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutServicesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSexesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSpeciesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutSyncvetInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVaccinationsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetAvailabilitiesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetdataInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutVetterInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput>;
};

export type ClinicPimsIntegrationUpdateWithoutWritebackLogEntriesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutIntegrationNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutIntegrationNestedInput>;
  availabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  bitwerx?: Maybe<BitwerxIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutIntegrationNestedInput>;
  breeds?: Maybe<BreedUpdateManyWithoutIntegrationNestedInput>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityUpdateManyWithoutIntegrationNestedInput>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  clientConnect?: Maybe<ClientConnectIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutIntegrationsNestedInput>;
  colors?: Maybe<ColorUpdateManyWithoutIntegrationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput>;
  datapoint?: Maybe<DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  employees?: Maybe<ClinicEmployeeUpdateManyWithoutIntegrationNestedInput>;
  ezyvet?: Maybe<EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  id?: Maybe<Scalars['String']>;
  instinct?: Maybe<InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastBatchSyncedAt?: Maybe<Scalars['DateTime']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  neo?: Maybe<NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  pauseUntil?: Maybe<Scalars['DateTime']>;
  petParents?: Maybe<ClinicPetParentUpdateManyWithoutIntegrationNestedInput>;
  pets?: Maybe<ClinicPetUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput>;
  pimsInvoices?: Maybe<PimsInvoiceUpdateManyWithoutIntegrationNestedInput>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput>;
  ppc?: Maybe<PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutIntegrationNestedInput>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutIntegrationNestedInput>;
  serviceCategories?: Maybe<ServiceCategoryUpdateManyWithoutIntegrationNestedInput>;
  serviceReminders?: Maybe<ServiceReminderUpdateManyWithoutIntegrationNestedInput>;
  services?: Maybe<ServiceUpdateManyWithoutIntegrationNestedInput>;
  sexes?: Maybe<SexUpdateManyWithoutIntegrationNestedInput>;
  species?: Maybe<SpeciesUpdateManyWithoutIntegrationNestedInput>;
  status?: Maybe<PimsSyncStatus>;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  syncFrequency?: Maybe<PimsSyncFrequency>;
  syncvet?: Maybe<SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  type?: Maybe<ClinicPimsIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutIntegrationNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutIntegrationNestedInput>;
  vetdata?: Maybe<VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  vetter?: Maybe<VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput>;
  writebackConfiguration?: Maybe<Scalars['Json']>;
};

export type ClinicPimsIntegrationUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutClinicInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutClinicInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentTypesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentTypesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentTypesInput;
};

export type ClinicPimsIntegrationUpsertWithoutAppointmentsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAppointmentsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutAvailabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutAvailabilitiesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutAvailabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutBitwerxInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxInput;
};

export type ClinicPimsIntegrationUpsertWithoutBitwerxPaymentWritebackConfigurationsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBitwerxPaymentWritebackConfigurationsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBitwerxPaymentWritebackConfigurationsInput;
};

export type ClinicPimsIntegrationUpsertWithoutBreedsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutBreedsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutBreedsInput;
};

export type ClinicPimsIntegrationUpsertWithoutCapabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilitiesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCapabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutCapabilityHistoryEntriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCapabilityHistoryEntriesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCapabilityHistoryEntriesInput;
};

export type ClinicPimsIntegrationUpsertWithoutClientConnectInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutClientConnectInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutClientConnectInput;
};

export type ClinicPimsIntegrationUpsertWithoutColorsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutColorsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutColorsInput;
};

export type ClinicPimsIntegrationUpsertWithoutCustomFieldDefinitionsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutCustomFieldDefinitionsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutCustomFieldDefinitionsInput;
};

export type ClinicPimsIntegrationUpsertWithoutDatapointInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutDatapointInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutDatapointInput;
};

export type ClinicPimsIntegrationUpsertWithoutEmployeesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutEmployeesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEmployeesInput;
};

export type ClinicPimsIntegrationUpsertWithoutEzyvetInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutEzyvetInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutEzyvetInput;
};

export type ClinicPimsIntegrationUpsertWithoutInstinctInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutInstinctInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutInstinctInput;
};

export type ClinicPimsIntegrationUpsertWithoutIntegrationOutageHistoryEntryInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutIntegrationOutageHistoryEntryInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutIntegrationOutageHistoryEntryInput;
};

export type ClinicPimsIntegrationUpsertWithoutNeoInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutNeoInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutNeoInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetParentsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetParentsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetParentsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPetsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPetsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPetsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoiceLineItemsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoiceLineItemsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoiceLineItemsInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsInvoicesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsInvoicesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsInvoicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutPimsPaymentTypesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPimsPaymentTypesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPimsPaymentTypesInput;
};

export type ClinicPimsIntegrationUpsertWithoutPpcInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPpcInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPpcInput;
};

export type ClinicPimsIntegrationUpsertWithoutPrescriptionsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutPrescriptionsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutPrescriptionsInput;
};

export type ClinicPimsIntegrationUpsertWithoutRoomsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutRoomsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutRoomsInput;
};

export type ClinicPimsIntegrationUpsertWithoutServiceCategoriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceCategoriesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServiceCategoriesInput;
};

export type ClinicPimsIntegrationUpsertWithoutServiceRemindersInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServiceRemindersInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServiceRemindersInput;
};

export type ClinicPimsIntegrationUpsertWithoutServicesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutServicesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutServicesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSexesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSexesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSexesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSpeciesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSpeciesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSpeciesInput;
};

export type ClinicPimsIntegrationUpsertWithoutSyncvetInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutSyncvetInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutSyncvetInput;
};

export type ClinicPimsIntegrationUpsertWithoutVaccinationsInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVaccinationsInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVaccinationsInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetAvailabilitiesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetAvailabilitiesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetAvailabilitiesInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetdataInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetdataInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetdataInput;
};

export type ClinicPimsIntegrationUpsertWithoutVetterInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutVetterInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutVetterInput;
};

export type ClinicPimsIntegrationUpsertWithoutWritebackLogEntriesInput = {
  create: ClinicPimsIntegrationUncheckedCreateWithoutWritebackLogEntriesInput;
  update: ClinicPimsIntegrationUncheckedUpdateWithoutWritebackLogEntriesInput;
};

export type ClinicPimsIntegrationWhereInput = {
  AND?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  NOT?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  OR?: Maybe<Array<ClinicPimsIntegrationWhereInput>>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  availabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  bitwerx?: Maybe<BitwerxIntegrationWhereInput>;
  bitwerxId?: Maybe<StringNullableFilter>;
  bitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  breeds?: Maybe<BreedListRelationFilter>;
  capabilities?: Maybe<ClinicPimsIntegrationCapabilityListRelationFilter>;
  capabilityHistoryEntries?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryListRelationFilter>;
  clientConnect?: Maybe<ClientConnectIntegrationWhereInput>;
  clientConnectId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  colors?: Maybe<ColorListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customFieldDefinitions?: Maybe<CustomFieldDefinitionListRelationFilter>;
  datapoint?: Maybe<DataPointIntegrationWhereInput>;
  datapointId?: Maybe<StringNullableFilter>;
  employees?: Maybe<ClinicEmployeeListRelationFilter>;
  ezyvet?: Maybe<EzyVetIntegrationWhereInput>;
  ezyvetId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  instinct?: Maybe<InstinctIntegrationWhereInput>;
  instinctId?: Maybe<StringNullableFilter>;
  integrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntryListRelationFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  lastBatchSyncedAt?: Maybe<DateTimeNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  neo?: Maybe<NeoIntegrationWhereInput>;
  neoId?: Maybe<StringNullableFilter>;
  pauseUntil?: Maybe<DateTimeNullableFilter>;
  petParents?: Maybe<ClinicPetParentListRelationFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  pimsInvoiceLineItems?: Maybe<PimsInvoiceLineItemListRelationFilter>;
  pimsInvoices?: Maybe<PimsInvoiceListRelationFilter>;
  pimsPaymentTypes?: Maybe<PimsPaymentTypeListRelationFilter>;
  ppc?: Maybe<PpcIntegrationWhereInput>;
  ppcId?: Maybe<StringNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
  serviceCategories?: Maybe<ServiceCategoryListRelationFilter>;
  serviceReminders?: Maybe<ServiceReminderListRelationFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  sexes?: Maybe<SexListRelationFilter>;
  species?: Maybe<SpeciesListRelationFilter>;
  status?: Maybe<EnumPimsSyncStatusNullableFilter>;
  statusChangedAt?: Maybe<DateTimeNullableFilter>;
  statusErrorMessage?: Maybe<StringNullableFilter>;
  syncFrequency?: Maybe<EnumPimsSyncFrequencyFilter>;
  syncvet?: Maybe<SyncVetIntegrationWhereInput>;
  syncvetId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumClinicPimsIntegrationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  vetdata?: Maybe<VetDataIntegrationWhereInput>;
  vetdataId?: Maybe<StringNullableFilter>;
  vetter?: Maybe<VetterIntegrationWhereInput>;
  vetterId?: Maybe<StringNullableFilter>;
  writebackConfiguration?: Maybe<JsonFilter>;
  writebackLogEntries?: Maybe<PimsWritebackLogEntryListRelationFilter>;
};

export type ClinicPimsIntegrationWhereUniqueInput = {
  bitwerxId?: Maybe<Scalars['String']>;
  clientConnectId?: Maybe<Scalars['String']>;
  datapointId?: Maybe<Scalars['String']>;
  ezyvetId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instinctId?: Maybe<Scalars['String']>;
  neoId?: Maybe<Scalars['String']>;
  ppcId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  vetdataId?: Maybe<Scalars['String']>;
  vetterId?: Maybe<Scalars['String']>;
};

export type ClinicPostcardSetting = {
  __typename?: 'ClinicPostcardSetting';
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isRemindersEnabled: Scalars['Boolean'];
  reminderTemplate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicPostcardSettingCountAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  apiKey: Scalars['Int'];
  apiSecret: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isRemindersEnabled: Scalars['Int'];
  reminderTemplate: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicPostcardSettingCountOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingCreateInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutPostcardSettingsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingCreateManyClinicInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingCreateManyClinicInputEnvelope = {
  data: Array<ClinicPostcardSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicPostcardSettingCreateManyInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
};

export type ClinicPostcardSettingCreateOrConnectWithoutClinicInput = {
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type ClinicPostcardSettingCreateWithoutClinicInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingListRelationFilter = {
  every?: Maybe<ClinicPostcardSettingWhereInput>;
  none?: Maybe<ClinicPostcardSettingWhereInput>;
  some?: Maybe<ClinicPostcardSettingWhereInput>;
};

export type ClinicPostcardSettingMaxAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMaxAggregateOutputType';
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingMaxOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingMinAggregateOutputType = {
  __typename?: 'ClinicPostcardSettingMinAggregateOutputType';
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingMinOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicPostcardSettingOrderByRelevanceFieldEnum {
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ClinicId = 'clinicId',
  Id = 'id',
  ReminderTemplate = 'reminderTemplate'
}

export type ClinicPostcardSettingOrderByRelevanceInput = {
  fields: Array<ClinicPostcardSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicPostcardSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicPostcardSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicPostcardSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicPostcardSettingMinOrderByAggregateInput>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicPostcardSettingOrderByRelevanceInput>;
  apiKey?: Maybe<SortOrder>;
  apiSecret?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isRemindersEnabled?: Maybe<SortOrder>;
  reminderTemplate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicPostcardSettingScalarFieldEnum {
  ApiKey = 'apiKey',
  ApiSecret = 'apiSecret',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsRemindersEnabled = 'isRemindersEnabled',
  ReminderTemplate = 'reminderTemplate',
  UpdatedAt = 'updatedAt'
}

export type ClinicPostcardSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  reminderTemplate?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPostcardSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingScalarWhereWithAggregatesInput>>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
  apiSecret?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isRemindersEnabled?: Maybe<BoolWithAggregatesFilter>;
  reminderTemplate?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicPostcardSettingUncheckedCreateInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
};

export type ClinicPostcardSettingUncheckedCreateWithoutClinicInput = {
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUncheckedUpdateInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUncheckedUpdateWithoutClinicInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUpdateInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPostcardSettingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUpdateManyMutationInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicPostcardSettingUncheckedUpdateManyWithoutPostcardSettingsInput;
  where: ClinicPostcardSettingScalarWhereInput;
};

export type ClinicPostcardSettingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicPostcardSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicPostcardSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicPostcardSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicPostcardSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicPostcardSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicPostcardSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicPostcardSettingUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type ClinicPostcardSettingUpdateWithoutClinicInput = {
  apiKey?: Maybe<Scalars['String']>;
  apiSecret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
  reminderTemplate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicPostcardSettingUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicPostcardSettingUncheckedCreateWithoutClinicInput;
  update: ClinicPostcardSettingUncheckedUpdateWithoutClinicInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};

export type ClinicPostcardSettingWhereInput = {
  AND?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  OR?: Maybe<Array<ClinicPostcardSettingWhereInput>>;
  apiKey?: Maybe<StringFilter>;
  apiSecret?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isRemindersEnabled?: Maybe<BoolFilter>;
  reminderTemplate?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicPostcardSettingWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicRelationFilter = {
  is?: Maybe<ClinicWhereInput>;
  isNot?: Maybe<ClinicWhereInput>;
};

export type ClinicRoom = {
  __typename?: 'ClinicRoom';
  ProviderAppointmentTypeRoomMap: Array<ProviderAppointmentTypeRoomMap>;
  _count: ClinicRoomCountOutputType;
  appointmentAvailability: Array<AppointmentAvailability>;
  appointments: Array<Appointment>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  vetAvailabilityClinicRoomMaps: Array<VetAvailabilityClinicRoomMap>;
  workflows: Array<ClinicRoomToWorkflowEventSetting>;
};


export type ClinicRoomProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>;
  orderBy?: Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type ClinicRoomAppointmentAvailabilityArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<AppointmentAvailabilityScalarFieldEnum>;
  orderBy?: Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type ClinicRoomAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type ClinicRoomUserAppointmentGroupingsArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type ClinicRoomVetAvailabilityClinicRoomMapsArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type ClinicRoomWorkflowsArgs = {
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
};

export type ClinicRoomClinicRoomPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ClinicRoomCountAggregateOutputType = {
  __typename?: 'ClinicRoomCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isSchedulable: Scalars['Int'];
  isVisible: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicRoomCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicRoomCountOutputType = {
  __typename?: 'ClinicRoomCountOutputType';
  ProviderAppointmentTypeRoomMap: Scalars['Int'];
  appointmentAvailability: Scalars['Int'];
  appointments: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  vetAvailabilityClinicRoomMaps: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicRoomCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomCreateManyClinicInputEnvelope = {
  data: Array<ClinicRoomCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomCreateManyIntegrationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomCreateManyIntegrationInputEnvelope = {
  data: Array<ClinicRoomCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
};

export type ClinicRoomCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
};

export type ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicRoomCreateNestedOneWithoutAppointmentAvailabilityInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput>;
};

export type ClinicRoomCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
};

export type ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type ClinicRoomCreateNestedOneWithoutWorkflowsInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
};

export type ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput = {
  create: ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutAppointmentsInput = {
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutClinicInput = {
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutIntegrationInput = {
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput = {
  create: ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput = {
  create: ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateOrConnectWithoutWorkflowsInput = {
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomCreateWithoutAppointmentAvailabilityInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutRoomInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicRoomsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutRoomsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomListRelationFilter = {
  every?: Maybe<ClinicRoomWhereInput>;
  none?: Maybe<ClinicRoomWhereInput>;
  some?: Maybe<ClinicRoomWhereInput>;
};

export type ClinicRoomMaxAggregateOutputType = {
  __typename?: 'ClinicRoomMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicRoomMinAggregateOutputType = {
  __typename?: 'ClinicRoomMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicRoomOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId',
  Type = 'type'
}

export type ClinicRoomOrderByRelevanceInput = {
  fields: Array<ClinicRoomOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicRoomOrderByWithAggregationInput = {
  _count?: Maybe<ClinicRoomCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicRoomOrderByWithRelationAndSearchRelevanceInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClinicRoomOrderByRelevanceInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isSchedulable?: Maybe<SortOrder>;
  isVisible?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapOrderByRelationAggregateInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
};

export type ClinicRoomRelationFilter = {
  is?: Maybe<ClinicRoomWhereInput>;
  isNot?: Maybe<ClinicRoomWhereInput>;
};

export enum ClinicRoomScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IsSchedulable = 'isSchedulable',
  IsVisible = 'isVisible',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ClinicRoomScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicRoomScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  isSchedulable?: Maybe<BoolNullableWithAggregatesFilter>;
  isVisible?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSetting = {
  __typename?: 'ClinicRoomToWorkflowEventSetting';
  clinicRoom: ClinicRoom;
  clinicRoomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  workflow: WorkflowEventSetting;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicRoomId: Scalars['Int'];
  createdAt: Scalars['Int'];
  workflowId: Scalars['Int'];
};

export type ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingCreateInput = {
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateManyInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope = {
  data: Array<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutClinicRoomInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
};

export type ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput = {
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput = {
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput;
};

export type ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput = {
  clinicRoom: ClinicRoomCreateNestedOneWithoutWorkflowsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingListRelationFilter = {
  every?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  none?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
  some?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
};

export type ClinicRoomToWorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMaxAggregateOutputType';
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'ClinicRoomToWorkflowEventSettingMinAggregateOutputType';
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum {
  ClinicRoomId = 'clinicRoomId',
  WorkflowId = 'workflowId'
}

export type ClinicRoomToWorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<ClinicRoomToWorkflowEventSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicRoomToWorkflowEventSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicRoomToWorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicRoomToWorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicRoomToWorkflowEventSettingMinOrderByAggregateInput>;
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelevanceInput>;
  clinicRoom?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowId?: Maybe<SortOrder>;
};

export enum ClinicRoomToWorkflowEventSettingScalarFieldEnum {
  ClinicRoomId = 'clinicRoomId',
  CreatedAt = 'createdAt',
  WorkflowId = 'workflowId'
}

export type ClinicRoomToWorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  clinicRoomId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
};

export type ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereWithAggregatesInput>>;
  clinicRoomId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateInput = {
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput = {
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput = {
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomsInput;
  where: ClinicRoomToWorkflowEventSettingScalarWhereInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyClinicRoomInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<ClinicRoomToWorkflowEventSettingCreateWithoutWorkflowInput>>;
  createMany?: Maybe<ClinicRoomToWorkflowEventSettingCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutClinicRoomInput = {
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithWhereUniqueWithoutWorkflowInput = {
  data: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput>;
};

export type ClinicRoomToWorkflowEventSettingUpdateWithoutWorkflowInput = {
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutClinicRoomInput = {
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutClinicRoomInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutClinicRoomInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingUpsertWithWhereUniqueWithoutWorkflowInput = {
  create: ClinicRoomToWorkflowEventSettingUncheckedCreateWithoutWorkflowInput;
  update: ClinicRoomToWorkflowEventSettingUncheckedUpdateWithoutWorkflowInput;
  where: ClinicRoomToWorkflowEventSettingWhereUniqueInput;
};

export type ClinicRoomToWorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<ClinicRoomToWorkflowEventSettingWhereInput>>;
  clinicRoom?: Maybe<ClinicRoomWhereInput>;
  clinicRoomId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  workflowId?: Maybe<StringFilter>;
};

export type ClinicRoomToWorkflowEventSettingWhereUniqueInput = {
  workflowId_clinicRoomId?: Maybe<ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput>;
};

export type ClinicRoomToWorkflowEventSettingWorkflowIdClinicRoomIdCompoundUniqueInput = {
  clinicRoomId: Scalars['String'];
  workflowId: Scalars['String'];
};

export type ClinicRoomUncheckedCreateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
};

export type ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedCreateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutRoomInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutRoomInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput>;
};

export type ClinicRoomUncheckedUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicRoomUncheckedUpdateManyWithoutRoomsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUncheckedUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutRoomNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicRoomUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicRoomUncheckedUpdateManyWithoutClinicRoomsInput;
  where: ClinicRoomScalarWhereInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutIntegrationInput = {
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
  where: ClinicRoomScalarWhereInput;
};

export type ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput = {
  data: ClinicRoomUncheckedUpdateManyWithoutRoomsInput;
  where: ClinicRoomScalarWhereInput;
};

export type ClinicRoomUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicRoomCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicRoomUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ClinicRoomCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicRoomCreateOrConnectWithoutUserAppointmentGroupingsInput>>;
  create?: Maybe<Array<ClinicRoomCreateWithoutUserAppointmentGroupingsInput>>;
  delete?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicRoomScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  set?: Maybe<Array<ClinicRoomWhereUniqueInput>>;
  update?: Maybe<Array<ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
  updateMany?: Maybe<Array<ClinicRoomUpdateManyWithWhereWithoutUserAppointmentGroupingsInput>>;
  upsert?: Maybe<Array<ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput>>;
};

export type ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutProviderAppointmentTypeRoomMapInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutProviderAppointmentTypeRoomMapInput>;
};

export type ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type ClinicRoomUpdateOneRequiredWithoutWorkflowsNestedInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutWorkflowsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutWorkflowsInput>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutWorkflowsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutWorkflowsInput>;
};

export type ClinicRoomUpdateOneWithoutAppointmentAvailabilityNestedInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentAvailabilityInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutAppointmentAvailabilityInput>;
};

export type ClinicRoomUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ClinicRoomWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicRoomCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicRoomUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicRoomUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicRoomUpsertWithoutAppointmentsInput>;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicRoomUncheckedUpdateWithoutClinicInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpdateWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  data: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpdateWithoutAppointmentAvailabilityInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutAppointmentsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutClinicInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutIntegrationInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutProviderAppointmentTypeRoomMapInput = {
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutUserAppointmentGroupingsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpdateWithoutWorkflowsInput = {
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityUpdateManyWithoutRoomNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutRoomNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicRoomsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutRoomsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput>;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicRoomUncheckedCreateWithoutClinicInput;
  update: ClinicRoomUncheckedUpdateWithoutClinicInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ClinicRoomUncheckedCreateWithoutIntegrationInput;
  update: ClinicRoomUncheckedUpdateWithoutIntegrationInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpsertWithWhereUniqueWithoutUserAppointmentGroupingsInput = {
  create: ClinicRoomUncheckedCreateWithoutUserAppointmentGroupingsInput;
  update: ClinicRoomUncheckedUpdateWithoutUserAppointmentGroupingsInput;
  where: ClinicRoomWhereUniqueInput;
};

export type ClinicRoomUpsertWithoutAppointmentAvailabilityInput = {
  create: ClinicRoomUncheckedCreateWithoutAppointmentAvailabilityInput;
  update: ClinicRoomUncheckedUpdateWithoutAppointmentAvailabilityInput;
};

export type ClinicRoomUpsertWithoutAppointmentsInput = {
  create: ClinicRoomUncheckedCreateWithoutAppointmentsInput;
  update: ClinicRoomUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicRoomUpsertWithoutProviderAppointmentTypeRoomMapInput = {
  create: ClinicRoomUncheckedCreateWithoutProviderAppointmentTypeRoomMapInput;
  update: ClinicRoomUncheckedUpdateWithoutProviderAppointmentTypeRoomMapInput;
};

export type ClinicRoomUpsertWithoutVetAvailabilityClinicRoomMapsInput = {
  create: ClinicRoomUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
  update: ClinicRoomUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type ClinicRoomUpsertWithoutWorkflowsInput = {
  create: ClinicRoomUncheckedCreateWithoutWorkflowsInput;
  update: ClinicRoomUncheckedUpdateWithoutWorkflowsInput;
};

export type ClinicRoomWhereInput = {
  AND?: Maybe<Array<ClinicRoomWhereInput>>;
  NOT?: Maybe<Array<ClinicRoomWhereInput>>;
  OR?: Maybe<Array<ClinicRoomWhereInput>>;
  ProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMapListRelationFilter>;
  appointmentAvailability?: Maybe<AppointmentAvailabilityListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  isSchedulable?: Maybe<BoolNullableFilter>;
  isVisible?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapListRelationFilter>;
  workflows?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
};

export type ClinicRoomWhereUniqueInput = {
  clinicRoomPimsIdAndIntegrationId?: Maybe<ClinicRoomClinicRoomPimsIdAndIntegrationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum ClinicScalarFieldEnum {
  ActivationDate = 'activationDate',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AllowAllUsersToCloseConsultations = 'allowAllUsersToCloseConsultations',
  AutoSubscriptionReceiptEmails = 'autoSubscriptionReceiptEmails',
  AutomaticSubscriptionReceipts = 'automaticSubscriptionReceipts',
  CallerIdPhoneNumber = 'callerIdPhoneNumber',
  CanceledAt = 'canceledAt',
  CancellationNote = 'cancellationNote',
  CancellationReason = 'cancellationReason',
  CarePlanProviderGroupId = 'carePlanProviderGroupId',
  City = 'city',
  ClinicEmail = 'clinicEmail',
  ClinicSettingId = 'clinicSettingId',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  ConsultationCost = 'consultationCost',
  CorporateId = 'corporateId',
  Country = 'country',
  CreatedAt = 'createdAt',
  CrmId = 'crmId',
  CrmUrlParams = 'crmUrlParams',
  CustomerId = 'customerId',
  FirebaseId = 'firebaseId',
  FirebaseVets = 'firebaseVets',
  FollowUpCost = 'followUpCost',
  FullAddress = 'fullAddress',
  HasServiceReminders = 'hasServiceReminders',
  HasSmsConversations = 'hasSmsConversations',
  HeadUserId = 'headUserId',
  Hours = 'hours',
  Id = 'id',
  IncomeMethod = 'incomeMethod',
  IntegrationInfo = 'integrationInfo',
  IntegrationSystem = 'integrationSystem',
  IntegrationType = 'integrationType',
  IsAddAppointmentDisabled = 'isAddAppointmentDisabled',
  IsCallerIdVerified = 'isCallerIdVerified',
  IsClinicActive = 'isClinicActive',
  IsConsultationsActive = 'isConsultationsActive',
  IsIntegrationActive = 'isIntegrationActive',
  IsPaymentUsBank = 'isPaymentUsBank',
  IsServiceRemindersActive = 'isServiceRemindersActive',
  LastPrintOrderedOn = 'lastPrintOrderedOn',
  LastPrintOrderedQuantity = 'lastPrintOrderedQuantity',
  Lat = 'lat',
  LegalEntityId = 'legalEntityId',
  Logo = 'logo',
  Long = 'long',
  LoyaltyProgramId = 'loyaltyProgramId',
  MarketEmail = 'marketEmail',
  MarketFacebookNumber = 'marketFacebookNumber',
  MarketFacebookPageId = 'marketFacebookPageId',
  MarketFacebookPageToken = 'marketFacebookPageToken',
  Name = 'name',
  OptimizedLogoUrl = 'optimizedLogoUrl',
  OrganizationId = 'organizationId',
  PaymentAddress = 'paymentAddress',
  PaymentBankAccountNumber = 'paymentBankAccountNumber',
  PaymentBankRoutingNumber = 'paymentBankRoutingNumber',
  PaymentCity = 'paymentCity',
  PaymentName = 'paymentName',
  PaymentPhone = 'paymentPhone',
  PaymentState = 'paymentState',
  PaymentZip = 'paymentZip',
  Phone = 'phone',
  PostalCode = 'postalCode',
  PpcLocationId = 'ppcLocationId',
  PrintLastOrder = 'printLastOrder',
  PrintReceivedQuantity = 'printReceivedQuantity',
  RemindersIntegrationRequestEmail = 'remindersIntegrationRequestEmail',
  ResponseTime = 'responseTime',
  SmsPhoneNumber = 'smsPhoneNumber',
  State = 'state',
  StripeCustomerId = 'stripeCustomerId',
  StripeSubscriptionId = 'stripeSubscriptionId',
  StripeSubscriptionPlan = 'stripeSubscriptionPlan',
  SubmerchantApprovedAt = 'submerchantApprovedAt',
  SubmerchantId = 'submerchantId',
  SubmerchantIsActive = 'submerchantIsActive',
  SubmerchantStatus = 'submerchantStatus',
  SubmerchantStripeChargesEnabled = 'submerchantStripeChargesEnabled',
  SubmerchantStripeHasCustomPayouts = 'submerchantStripeHasCustomPayouts',
  SubmerchantStripeId = 'submerchantStripeId',
  SubmerchantStripePayoutsEnabled = 'submerchantStripePayoutsEnabled',
  SubmerchantStripeStatus = 'submerchantStripeStatus',
  SubscribedAt = 'subscribedAt',
  SubscriptionId = 'subscriptionId',
  SubscriptionPlan = 'subscriptionPlan',
  Timezone = 'timezone',
  TimezoneName = 'timezoneName',
  Token = 'token',
  UnlayerSettingId = 'unlayerSettingId',
  UnsubscribedAt = 'unsubscribedAt',
  UpdatedAt = 'updatedAt',
  WebsiteUrl = 'websiteUrl',
  WidgetSettings = 'widgetSettings'
}

export type ClinicScalarWhereInput = {
  AND?: Maybe<Array<ClinicScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereInput>>;
  OR?: Maybe<Array<ClinicScalarWhereInput>>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  carePlanProviderGroupId?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  crmId?: Maybe<StringNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  legalEntityId?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  loyaltyProgramId?: Maybe<StringNullableFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  optimizedLogoUrl?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  timezone?: Maybe<IntNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  unlayerSettingId?: Maybe<IntNullableFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
};

export type ClinicScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicScalarWhereWithAggregatesInput>>;
  activationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableWithAggregatesFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableWithAggregatesFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolWithAggregatesFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  canceledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cancellationNote?: Maybe<StringNullableWithAggregatesFilter>;
  cancellationReason?: Maybe<StringNullableWithAggregatesFilter>;
  carePlanProviderGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  clinicEmail?: Maybe<StringNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationCost?: Maybe<FloatNullableWithAggregatesFilter>;
  corporateId?: Maybe<StringNullableWithAggregatesFilter>;
  country?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  crmUrlParams?: Maybe<JsonNullableWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseVets?: Maybe<JsonNullableWithAggregatesFilter>;
  followUpCost?: Maybe<FloatNullableWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  hasServiceReminders?: Maybe<BoolNullableWithAggregatesFilter>;
  hasSmsConversations?: Maybe<BoolNullableWithAggregatesFilter>;
  headUserId?: Maybe<StringNullableWithAggregatesFilter>;
  hours?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  incomeMethod?: Maybe<StringNullableWithAggregatesFilter>;
  integrationInfo?: Maybe<JsonNullableWithAggregatesFilter>;
  integrationSystem?: Maybe<StringNullableWithAggregatesFilter>;
  integrationType?: Maybe<StringNullableWithAggregatesFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableWithAggregatesFilter>;
  isCallerIdVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  isClinicActive?: Maybe<BoolWithAggregatesFilter>;
  isConsultationsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isIntegrationActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isPaymentUsBank?: Maybe<BoolNullableWithAggregatesFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableWithAggregatesFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  lat?: Maybe<FloatNullableWithAggregatesFilter>;
  legalEntityId?: Maybe<StringNullableWithAggregatesFilter>;
  logo?: Maybe<StringNullableWithAggregatesFilter>;
  long?: Maybe<FloatNullableWithAggregatesFilter>;
  loyaltyProgramId?: Maybe<StringNullableWithAggregatesFilter>;
  marketEmail?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookNumber?: Maybe<IntNullableWithAggregatesFilter>;
  marketFacebookPageId?: Maybe<StringNullableWithAggregatesFilter>;
  marketFacebookPageToken?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  optimizedLogoUrl?: Maybe<StringNullableWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  paymentAddress?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableWithAggregatesFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableWithAggregatesFilter>;
  paymentCity?: Maybe<StringNullableWithAggregatesFilter>;
  paymentName?: Maybe<StringNullableWithAggregatesFilter>;
  paymentPhone?: Maybe<StringNullableWithAggregatesFilter>;
  paymentState?: Maybe<StringNullableWithAggregatesFilter>;
  paymentZip?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  ppcLocationId?: Maybe<IntNullableWithAggregatesFilter>;
  printLastOrder?: Maybe<DateTimeNullableWithAggregatesFilter>;
  printReceivedQuantity?: Maybe<IntNullableWithAggregatesFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableWithAggregatesFilter>;
  responseTime?: Maybe<FloatNullableWithAggregatesFilter>;
  smsPhoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  submerchantId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantIsActive?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStatus?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripeId?: Maybe<StringNullableWithAggregatesFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  submerchantStripeStatus?: Maybe<StringNullableWithAggregatesFilter>;
  subscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  subscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  subscriptionPlan?: Maybe<StringNullableWithAggregatesFilter>;
  timezone?: Maybe<IntNullableWithAggregatesFilter>;
  timezoneName?: Maybe<StringNullableWithAggregatesFilter>;
  token?: Maybe<StringNullableWithAggregatesFilter>;
  unlayerSettingId?: Maybe<IntNullableWithAggregatesFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  websiteUrl?: Maybe<StringNullableWithAggregatesFilter>;
  widgetSettings?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicSetting = {
  __typename?: 'ClinicSetting';
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  _count: ClinicSettingCountOutputType;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit: Scalars['Int'];
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns: Array<CampaignRegistryCampaign>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses: Array<ChannelStatus>;
  clinic?: Maybe<Clinic>;
  createdAt: Scalars['DateTime'];
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics: Array<EmergencyClinics>;
  emergencyContactsEnabled: Scalars['Boolean'];
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner: Scalars['Boolean'];
  id: Scalars['String'];
  /** When did we last run the loyalty program queueing cron job, if at all? */
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus: MissedCallMessageResponseStatus;
  outOfOfficeEnabled: Scalars['Boolean'];
  outOfOfficeHeadline: Scalars['String'];
  outOfOfficeShowScheduleEnabled: Scalars['Boolean'];
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfiguration>;
  petPortalSetting?: Maybe<PetPortalSetting>;
  processingFeeFlat: Scalars['Int'];
  processingFeePercent: Scalars['Float'];
  savePaymentInfo: Scalars['Boolean'];
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  /** 6-digit pin for terminal setup, padded with one zero for demo, two zeroes for QA, no zeroes for prod */
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workflowsEnabled: Scalars['Boolean'];
};


export type ClinicSettingCampaignRegistryCampaignsArgs = {
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  distinct?: Maybe<CampaignRegistryCampaignScalarFieldEnum>;
  orderBy?: Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type ClinicSettingChannelStatusesArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type ClinicSettingEmergencyClinicsArgs = {
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  distinct?: Maybe<EmergencyClinicsScalarFieldEnum>;
  orderBy?: Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmergencyClinicsWhereInput>;
};

export type ClinicSettingAvgAggregateOutputType = {
  __typename?: 'ClinicSettingAvgAggregateOutputType';
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Float']>;
  bulkConversationExportLimit?: Maybe<Scalars['Float']>;
  processingFeeFlat?: Maybe<Scalars['Float']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicSettingAvgOrderByAggregateInput = {
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
};

export type ClinicSettingCountAggregateOutputType = {
  __typename?: 'ClinicSettingCountAggregateOutputType';
  MissedCallMessageResponseBody: Scalars['Int'];
  _all: Scalars['Int'];
  appointmentTimeSlotIncrementInMinutes: Scalars['Int'];
  bulkConversationExportLimit: Scalars['Int'];
  campaignRegistryBrand: Scalars['Int'];
  campaignRegistryBrandId: Scalars['Int'];
  campaignRegistryBrandIdentityStatus: Scalars['Int'];
  careStatementDescriptor: Scalars['Int'];
  createdAt: Scalars['Int'];
  earliestAllowedLoyaltyInvoiceDate: Scalars['Int'];
  emergencyContactsEnabled: Scalars['Int'];
  financialReportingConfig: Scalars['Int'];
  hasCustomizableFees: Scalars['Int'];
  hideAppointmentsWithoutPetOwner: Scalars['Int'];
  id: Scalars['Int'];
  loyaltyProgramLastRunAt: Scalars['Int'];
  merchantIdentifier: Scalars['Int'];
  missedCallMessageResponseStatus: Scalars['Int'];
  outOfOfficeEnabled: Scalars['Int'];
  outOfOfficeHeadline: Scalars['Int'];
  outOfOfficeShowScheduleEnabled: Scalars['Int'];
  processingFeeFlat: Scalars['Int'];
  processingFeePercent: Scalars['Int'];
  savePaymentInfo: Scalars['Int'];
  smsAutoResponseWhenClosed: Scalars['Int'];
  smsAutoResponseWhenOpen: Scalars['Int'];
  tags: Scalars['Int'];
  terminalSetupPin: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowsEnabled: Scalars['Int'];
};

export type ClinicSettingCountOrderByAggregateInput = {
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
};

export type ClinicSettingCountOutputType = {
  __typename?: 'ClinicSettingCountOutputType';
  campaignRegistryCampaigns: Scalars['Int'];
  channelStatuses: Scalars['Int'];
  emergencyClinics: Scalars['Int'];
};

export type ClinicSettingCreateInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateManyInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateNestedOneWithoutCampaignRegistryCampaignsInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput>;
};

export type ClinicSettingCreateNestedOneWithoutChannelStatusesInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
};

export type ClinicSettingCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
};

export type ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
};

export type ClinicSettingCreateNestedOneWithoutPaymentFeeConfigInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
};

export type ClinicSettingCreateNestedOneWithoutPetPortalSettingInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
};

export type ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput = {
  create: ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateOrConnectWithoutChannelStatusesInput = {
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateOrConnectWithoutClinicInput = {
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput = {
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput = {
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateOrConnectWithoutPetPortalSettingInput = {
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
  where: ClinicSettingWhereUniqueInput;
};

export type ClinicSettingCreateWithoutCampaignRegistryCampaignsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateWithoutChannelStatusesInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateWithoutClinicInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateWithoutEmergencyClinicsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateWithoutPaymentFeeConfigInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  petPortalSetting?: Maybe<PetPortalSettingCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingCreateWithoutPetPortalSettingInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingMaxAggregateOutputType = {
  __typename?: 'ClinicSettingMaxAggregateOutputType';
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingMaxOrderByAggregateInput = {
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
};

export type ClinicSettingMinAggregateOutputType = {
  __typename?: 'ClinicSettingMinAggregateOutputType';
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingMinOrderByAggregateInput = {
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
};

export enum ClinicSettingOrderByRelevanceFieldEnum {
  MissedCallMessageResponseBody = 'MissedCallMessageResponseBody',
  CampaignRegistryBrandId = 'campaignRegistryBrandId',
  CareStatementDescriptor = 'careStatementDescriptor',
  Id = 'id',
  MerchantIdentifier = 'merchantIdentifier',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  TerminalSetupPin = 'terminalSetupPin'
}

export type ClinicSettingOrderByRelevanceInput = {
  fields: Array<ClinicSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicSettingOrderByWithAggregationInput = {
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  _avg?: Maybe<ClinicSettingAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicSettingMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicSettingSumOrderByAggregateInput>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  careStatementDescriptor?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
};

export type ClinicSettingOrderByWithRelationAndSearchRelevanceInput = {
  MissedCallMessageResponseBody?: Maybe<SortOrder>;
  _relevance?: Maybe<ClinicSettingOrderByRelevanceInput>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  campaignRegistryBrand?: Maybe<SortOrder>;
  campaignRegistryBrandId?: Maybe<SortOrder>;
  campaignRegistryBrandIdentityStatus?: Maybe<SortOrder>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignOrderByRelationAggregateInput>;
  careStatementDescriptor?: Maybe<SortOrder>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<SortOrder>;
  emergencyClinics?: Maybe<EmergencyClinicsOrderByRelationAggregateInput>;
  emergencyContactsEnabled?: Maybe<SortOrder>;
  financialReportingConfig?: Maybe<SortOrder>;
  hasCustomizableFees?: Maybe<SortOrder>;
  hideAppointmentsWithoutPetOwner?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyProgramLastRunAt?: Maybe<SortOrder>;
  merchantIdentifier?: Maybe<SortOrder>;
  missedCallMessageResponseStatus?: Maybe<SortOrder>;
  outOfOfficeEnabled?: Maybe<SortOrder>;
  outOfOfficeHeadline?: Maybe<SortOrder>;
  outOfOfficeShowScheduleEnabled?: Maybe<SortOrder>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  petPortalSetting?: Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
  savePaymentInfo?: Maybe<SortOrder>;
  smsAutoResponseWhenClosed?: Maybe<SortOrder>;
  smsAutoResponseWhenOpen?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  terminalSetupPin?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowsEnabled?: Maybe<SortOrder>;
};

export type ClinicSettingRelationFilter = {
  is?: Maybe<ClinicSettingWhereInput>;
  isNot?: Maybe<ClinicSettingWhereInput>;
};

export enum ClinicSettingScalarFieldEnum {
  MissedCallMessageResponseBody = 'MissedCallMessageResponseBody',
  AppointmentTimeSlotIncrementInMinutes = 'appointmentTimeSlotIncrementInMinutes',
  BulkConversationExportLimit = 'bulkConversationExportLimit',
  CampaignRegistryBrand = 'campaignRegistryBrand',
  CampaignRegistryBrandId = 'campaignRegistryBrandId',
  CampaignRegistryBrandIdentityStatus = 'campaignRegistryBrandIdentityStatus',
  CareStatementDescriptor = 'careStatementDescriptor',
  CreatedAt = 'createdAt',
  EarliestAllowedLoyaltyInvoiceDate = 'earliestAllowedLoyaltyInvoiceDate',
  EmergencyContactsEnabled = 'emergencyContactsEnabled',
  FinancialReportingConfig = 'financialReportingConfig',
  HasCustomizableFees = 'hasCustomizableFees',
  HideAppointmentsWithoutPetOwner = 'hideAppointmentsWithoutPetOwner',
  Id = 'id',
  LoyaltyProgramLastRunAt = 'loyaltyProgramLastRunAt',
  MerchantIdentifier = 'merchantIdentifier',
  MissedCallMessageResponseStatus = 'missedCallMessageResponseStatus',
  OutOfOfficeEnabled = 'outOfOfficeEnabled',
  OutOfOfficeHeadline = 'outOfOfficeHeadline',
  OutOfOfficeShowScheduleEnabled = 'outOfOfficeShowScheduleEnabled',
  ProcessingFeeFlat = 'processingFeeFlat',
  ProcessingFeePercent = 'processingFeePercent',
  SavePaymentInfo = 'savePaymentInfo',
  SmsAutoResponseWhenClosed = 'smsAutoResponseWhenClosed',
  SmsAutoResponseWhenOpen = 'smsAutoResponseWhenOpen',
  Tags = 'tags',
  TerminalSetupPin = 'terminalSetupPin',
  UpdatedAt = 'updatedAt',
  WorkflowsEnabled = 'workflowsEnabled'
}

export type ClinicSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  MissedCallMessageResponseBody?: Maybe<StringNullableWithAggregatesFilter>;
  NOT?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicSettingScalarWhereWithAggregatesInput>>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  bulkConversationExportLimit?: Maybe<IntWithAggregatesFilter>;
  campaignRegistryBrand?: Maybe<JsonNullableWithAggregatesFilter>;
  campaignRegistryBrandId?: Maybe<StringNullableWithAggregatesFilter>;
  campaignRegistryBrandIdentityStatus?: Maybe<EnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  careStatementDescriptor?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  emergencyContactsEnabled?: Maybe<BoolWithAggregatesFilter>;
  financialReportingConfig?: Maybe<JsonNullableWithAggregatesFilter>;
  hasCustomizableFees?: Maybe<BoolNullableWithAggregatesFilter>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  loyaltyProgramLastRunAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  merchantIdentifier?: Maybe<StringNullableWithAggregatesFilter>;
  missedCallMessageResponseStatus?: Maybe<EnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  outOfOfficeEnabled?: Maybe<BoolWithAggregatesFilter>;
  outOfOfficeHeadline?: Maybe<StringWithAggregatesFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolWithAggregatesFilter>;
  processingFeeFlat?: Maybe<IntWithAggregatesFilter>;
  processingFeePercent?: Maybe<FloatWithAggregatesFilter>;
  savePaymentInfo?: Maybe<BoolWithAggregatesFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableWithAggregatesFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableWithAggregatesFilter>;
  tags?: Maybe<JsonNullableWithAggregatesFilter>;
  terminalSetupPin?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowsEnabled?: Maybe<BoolWithAggregatesFilter>;
};

export type ClinicSettingSumAggregateOutputType = {
  __typename?: 'ClinicSettingSumAggregateOutputType';
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
};

export type ClinicSettingSumOrderByAggregateInput = {
  appointmentTimeSlotIncrementInMinutes?: Maybe<SortOrder>;
  bulkConversationExportLimit?: Maybe<SortOrder>;
  processingFeeFlat?: Maybe<SortOrder>;
  processingFeePercent?: Maybe<SortOrder>;
};

export type ClinicSettingUncheckedCreateInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutChannelStatusesInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutClinicInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedCreateWithoutPetPortalSettingInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedCreateNestedManyWithoutClinicSettingInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutClinicSettingInput>;
  clinic?: Maybe<ClinicUncheckedCreateNestedOneWithoutClinicSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedCreateNestedOneWithoutClinicSettingInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateManyInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutChannelStatusesInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutClinicInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  petPortalSetting?: Maybe<PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUncheckedUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateManyMutationInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateOneRequiredWithoutCampaignRegistryCampaignsNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutCampaignRegistryCampaignsInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutCampaignRegistryCampaignsInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutEmergencyClinicsInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutEmergencyClinicsInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPaymentFeeConfigNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPaymentFeeConfigInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPaymentFeeConfigInput>;
};

export type ClinicSettingUpdateOneRequiredWithoutPetPortalSettingNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutPetPortalSettingInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutPetPortalSettingInput>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutPetPortalSettingInput>;
};

export type ClinicSettingUpdateOneWithoutChannelStatusesNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutChannelStatusesInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutChannelStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutChannelStatusesInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutChannelStatusesInput>;
};

export type ClinicSettingUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<ClinicSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicSettingCreateOrConnectWithoutClinicInput>;
  create?: Maybe<ClinicSettingUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicSettingUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<ClinicSettingUpsertWithoutClinicInput>;
};

export type ClinicSettingUpdateWithoutCampaignRegistryCampaignsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateWithoutChannelStatusesInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateWithoutClinicInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateWithoutEmergencyClinicsInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateWithoutPaymentFeeConfigInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  petPortalSetting?: Maybe<PetPortalSettingUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpdateWithoutPetPortalSettingInput = {
  MissedCallMessageResponseBody?: Maybe<Scalars['String']>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<Scalars['Int']>;
  bulkConversationExportLimit?: Maybe<Scalars['Int']>;
  campaignRegistryBrand?: Maybe<Scalars['Json']>;
  campaignRegistryBrandId?: Maybe<Scalars['String']>;
  campaignRegistryBrandIdentityStatus?: Maybe<CampaignRegistryBrandIdentityStatus>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignUpdateManyWithoutClinicSettingNestedInput>;
  careStatementDescriptor?: Maybe<Scalars['String']>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutClinicSettingNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<Scalars['DateTime']>;
  emergencyClinics?: Maybe<EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput>;
  emergencyContactsEnabled?: Maybe<Scalars['Boolean']>;
  financialReportingConfig?: Maybe<Scalars['Json']>;
  hasCustomizableFees?: Maybe<Scalars['Boolean']>;
  hideAppointmentsWithoutPetOwner?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  loyaltyProgramLastRunAt?: Maybe<Scalars['DateTime']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  missedCallMessageResponseStatus?: Maybe<MissedCallMessageResponseStatus>;
  outOfOfficeEnabled?: Maybe<Scalars['Boolean']>;
  outOfOfficeHeadline?: Maybe<Scalars['String']>;
  outOfOfficeShowScheduleEnabled?: Maybe<Scalars['Boolean']>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationUpdateOneWithoutClinicSettingNestedInput>;
  processingFeeFlat?: Maybe<Scalars['Int']>;
  processingFeePercent?: Maybe<Scalars['Float']>;
  savePaymentInfo?: Maybe<Scalars['Boolean']>;
  smsAutoResponseWhenClosed?: Maybe<Scalars['String']>;
  smsAutoResponseWhenOpen?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['Json']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowsEnabled?: Maybe<Scalars['Boolean']>;
};

export type ClinicSettingUpsertWithoutCampaignRegistryCampaignsInput = {
  create: ClinicSettingUncheckedCreateWithoutCampaignRegistryCampaignsInput;
  update: ClinicSettingUncheckedUpdateWithoutCampaignRegistryCampaignsInput;
};

export type ClinicSettingUpsertWithoutChannelStatusesInput = {
  create: ClinicSettingUncheckedCreateWithoutChannelStatusesInput;
  update: ClinicSettingUncheckedUpdateWithoutChannelStatusesInput;
};

export type ClinicSettingUpsertWithoutClinicInput = {
  create: ClinicSettingUncheckedCreateWithoutClinicInput;
  update: ClinicSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicSettingUpsertWithoutEmergencyClinicsInput = {
  create: ClinicSettingUncheckedCreateWithoutEmergencyClinicsInput;
  update: ClinicSettingUncheckedUpdateWithoutEmergencyClinicsInput;
};

export type ClinicSettingUpsertWithoutPaymentFeeConfigInput = {
  create: ClinicSettingUncheckedCreateWithoutPaymentFeeConfigInput;
  update: ClinicSettingUncheckedUpdateWithoutPaymentFeeConfigInput;
};

export type ClinicSettingUpsertWithoutPetPortalSettingInput = {
  create: ClinicSettingUncheckedCreateWithoutPetPortalSettingInput;
  update: ClinicSettingUncheckedUpdateWithoutPetPortalSettingInput;
};

export type ClinicSettingWhereInput = {
  AND?: Maybe<Array<ClinicSettingWhereInput>>;
  MissedCallMessageResponseBody?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<ClinicSettingWhereInput>>;
  OR?: Maybe<Array<ClinicSettingWhereInput>>;
  appointmentTimeSlotIncrementInMinutes?: Maybe<IntNullableFilter>;
  bulkConversationExportLimit?: Maybe<IntFilter>;
  campaignRegistryBrand?: Maybe<JsonNullableFilter>;
  campaignRegistryBrandId?: Maybe<StringNullableFilter>;
  campaignRegistryBrandIdentityStatus?: Maybe<EnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  campaignRegistryCampaigns?: Maybe<CampaignRegistryCampaignListRelationFilter>;
  careStatementDescriptor?: Maybe<StringNullableFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  earliestAllowedLoyaltyInvoiceDate?: Maybe<DateTimeNullableFilter>;
  emergencyClinics?: Maybe<EmergencyClinicsListRelationFilter>;
  emergencyContactsEnabled?: Maybe<BoolFilter>;
  financialReportingConfig?: Maybe<JsonNullableFilter>;
  hasCustomizableFees?: Maybe<BoolNullableFilter>;
  hideAppointmentsWithoutPetOwner?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  loyaltyProgramLastRunAt?: Maybe<DateTimeNullableFilter>;
  merchantIdentifier?: Maybe<StringNullableFilter>;
  missedCallMessageResponseStatus?: Maybe<EnumMissedCallMessageResponseStatusFilter>;
  outOfOfficeEnabled?: Maybe<BoolFilter>;
  outOfOfficeHeadline?: Maybe<StringFilter>;
  outOfOfficeShowScheduleEnabled?: Maybe<BoolFilter>;
  paymentFeeConfig?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  petPortalSetting?: Maybe<PetPortalSettingWhereInput>;
  processingFeeFlat?: Maybe<IntFilter>;
  processingFeePercent?: Maybe<FloatFilter>;
  savePaymentInfo?: Maybe<BoolFilter>;
  smsAutoResponseWhenClosed?: Maybe<StringNullableFilter>;
  smsAutoResponseWhenOpen?: Maybe<StringNullableFilter>;
  tags?: Maybe<JsonNullableFilter>;
  terminalSetupPin?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowsEnabled?: Maybe<BoolFilter>;
};

export type ClinicSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  merchantIdentifier?: Maybe<Scalars['String']>;
  terminalSetupPin?: Maybe<Scalars['String']>;
};

export type ClinicSubscription = {
  __typename?: 'ClinicSubscription';
  mutation: MutationType;
  node: Clinic;
};

export type ClinicSumAggregateOutputType = {
  __typename?: 'ClinicSumAggregateOutputType';
  consultationCost?: Maybe<Scalars['Float']>;
  followUpCost?: Maybe<Scalars['Float']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  responseTime?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Int']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
};

export type ClinicSumOrderByAggregateInput = {
  consultationCost?: Maybe<SortOrder>;
  followUpCost?: Maybe<SortOrder>;
  lastPrintOrderedQuantity?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  long?: Maybe<SortOrder>;
  marketFacebookNumber?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  printReceivedQuantity?: Maybe<SortOrder>;
  responseTime?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
};

export type ClinicUncheckedCreateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateNestedManyWithoutCarePlanProviderGroupInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutLegalEntityInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutPetsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStaffInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
};

export type ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersIdInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
};

export type ClinicUncheckedCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
};

export type ClinicUncheckedCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
};

export type ClinicUncheckedCreateWithoutAppoinmentTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBenefitsProvidedInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBitwerxHealthchecksInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCarePayoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutCarePlanProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelFilterSelectionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutChannelsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicEmployeesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicOfficeHoursInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicOnboardingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetParentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicPhoneNumberInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicRoomsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWidgetSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutClinicWorkflowTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutConsultationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignTemplateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFeatureFlagsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFinancialTransactionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutFormTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInformPartnerCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutInsuranceIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLegalEntityInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyProgramInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMassTextAlertsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMediaInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutMessageTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPostcardSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPrescriptionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutPromoCodesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRolesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStaffInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripePayoutAccountsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutStripeTerminalLocationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioConfigurationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutTwilioVerificationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUnlayerSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutUsersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVaccinationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVetAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutVetsourceInstallationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput>;
};

export type ClinicUncheckedCreateWithoutWorkflowEventSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput>;
  Rules?: Maybe<RulesUncheckedCreateNestedManyWithoutClinicInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutClinicInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutClinicInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutUsageClinicInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedCreateNestedManyWithoutClinicInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedCreateNestedManyWithoutClinicInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedCreateNestedManyWithoutClinicInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutClinicInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutClinicInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedCreateNestedManyWithoutClinicInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutClinicInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutClinicInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutClinicInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutClinicInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutClinicInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutClinicInput>;
  formTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedManyWithoutClinicInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutClinicInput>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutClinicInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutClinicInput>;
  name: Scalars['String'];
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPreviousClinicsInput>;
  petsId?: Maybe<PetUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedCreateNestedManyWithoutClinicInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutClinicInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedCreateNestedManyWithoutClinicInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutClinicInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedCreateNestedManyWithoutClinicsInput>;
  staffId?: Maybe<VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedCreateNestedManyWithoutClinicInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutClinicInput>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutClinicsInput>;
  vaccinations?: Maybe<VaccinationUncheckedCreateNestedManyWithoutClinicInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateManyInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
};

export type ClinicUncheckedUpdateManyWithoutClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsIdInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutClinicsInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutFeatureFlagsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
};

export type ClinicUncheckedUpdateManyWithoutLegalEntityNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLegalEntityInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLegalEntityInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLegalEntityInput>>;
};

export type ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput>>;
};

export type ClinicUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ClinicUncheckedUpdateManyWithoutPreviousClinicsInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUncheckedUpdateManyWithoutStaffNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
};

export type ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersIdNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
};

export type ClinicUncheckedUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type ClinicUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
};

export type ClinicUncheckedUpdateWithoutAppoinmentTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBenefitsProvidedInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutChannelsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicEmployeesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicOfficeHoursInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicOnboardingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetParentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicPhoneNumberInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicRoomsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWidgetSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutConsultationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFeatureFlagsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFinancialTransactionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutFormTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInformPartnerCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLegalEntityInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyProgramInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMassTextAlertsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMediaInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutMessageTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPetsIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPostcardSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPrescriptionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutPromoCodesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutRolesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStaffIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStaffInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripePayoutAccountsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioConfigurationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutTwilioVerificationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUnlayerSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUsersIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutUsersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVaccinationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVetAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutVetsourceInstallationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUncheckedUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUncheckedUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUncheckedUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroupId?: Maybe<Scalars['String']>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUncheckedUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  headUserId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUncheckedUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntityId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgramId?: Maybe<Scalars['String']>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUncheckedUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUncheckedUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUncheckedUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUncheckedUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUncheckedUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUncheckedUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUpdateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateManyMutationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  consultationCost?: Maybe<Scalars['Float']>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput = {
  data: ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutFeatureFlagsInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutLegalEntityInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput = {
  data: ClinicUncheckedUpdateManyWithoutParticipatingClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutOrganizationInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutPetsInput = {
  data: ClinicUncheckedUpdateManyWithoutPreviousClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutStaffInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutUnlayerSettingInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersIdInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsIdInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithWhereWithoutUsersInput = {
  data: ClinicUncheckedUpdateManyWithoutClinicsInput;
  where: ClinicScalarWhereInput;
};

export type ClinicUpdateManyWithoutCarePlanProviderGroupNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutCarePlanProviderGroupInput>>;
  create?: Maybe<Array<ClinicCreateWithoutCarePlanProviderGroupInput>>;
  createMany?: Maybe<ClinicCreateManyCarePlanProviderGroupInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutCarePlanProviderGroupInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput>>;
};

export type ClinicUpdateManyWithoutClinicWidgetSettingNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicCreateManyClinicWidgetSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
};

export type ClinicUpdateManyWithoutFeatureFlagsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutFeatureFlagsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutFeatureFlagsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutFeatureFlagsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput>>;
};

export type ClinicUpdateManyWithoutLegalEntityNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLegalEntityInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLegalEntityInput>>;
  createMany?: Maybe<ClinicCreateManyLegalEntityInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLegalEntityInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLegalEntityInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLegalEntityInput>>;
};

export type ClinicUpdateManyWithoutLoyaltyProgramNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutLoyaltyProgramInput>>;
  create?: Maybe<Array<ClinicCreateWithoutLoyaltyProgramInput>>;
  createMany?: Maybe<ClinicCreateManyLoyaltyProgramInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutLoyaltyProgramInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput>>;
};

export type ClinicUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<ClinicCreateWithoutOrganizationInput>>;
  createMany?: Maybe<ClinicCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ClinicUpdateManyWithoutPetsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutPetsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutPetsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutPetsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutPetsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutPetsInput>>;
};

export type ClinicUpdateManyWithoutStaffNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStaffInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStaffInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStaffInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStaffInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStaffInput>>;
};

export type ClinicUpdateManyWithoutStripePayoutAccountsNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutStripePayoutAccountsInput>>;
  create?: Maybe<Array<ClinicCreateWithoutStripePayoutAccountsInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutStripePayoutAccountsInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput>>;
};

export type ClinicUpdateManyWithoutUnlayerSettingNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<ClinicCreateManyUnlayerSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
};

export type ClinicUpdateManyWithoutUsersIdNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersIdInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersIdInput>>;
  createMany?: Maybe<ClinicCreateManyUsersIdInputEnvelope>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersIdInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersIdInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersIdInput>>;
};

export type ClinicUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<ClinicWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<ClinicCreateWithoutUsersInput>>;
  delete?: Maybe<Array<ClinicWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWhereUniqueInput>>;
  update?: Maybe<Array<ClinicUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<ClinicUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<ClinicUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type ClinicUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAutomationRunsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAutomationRunsInput>;
};

export type ClinicUpdateOneRequiredWithoutBenefitsProvidedNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBenefitsProvidedInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBenefitsProvidedInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBenefitsProvidedInput>;
  upsert?: Maybe<ClinicUpsertWithoutBenefitsProvidedInput>;
};

export type ClinicUpdateOneRequiredWithoutBitwerxHealthchecksNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBitwerxHealthchecksInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBitwerxHealthchecksInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput>;
  upsert?: Maybe<ClinicUpsertWithoutBitwerxHealthchecksInput>;
};

export type ClinicUpdateOneRequiredWithoutBlockedPhoneNumbersNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutBlockedPhoneNumbersInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput>;
  upsert?: Maybe<ClinicUpsertWithoutBlockedPhoneNumbersInput>;
};

export type ClinicUpdateOneRequiredWithoutCallsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCallsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutCallsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCallsInput>;
  upsert?: Maybe<ClinicUpsertWithoutCallsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelFilterSelectionsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelFilterSelectionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelFilterSelectionsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelFilterSelectionsInput>;
};

export type ClinicUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelStatusChannelAssigneesInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicDirectBookingSettingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicDirectBookingSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicDirectBookingSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicEmailCampaignSettingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmailCampaignSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmailCampaignSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicOnboardingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOnboardingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOnboardingsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicOnboardingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicOnboardingsInput>;
};

export type ClinicUpdateOneRequiredWithoutClinicPhoneNumberNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPhoneNumberInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPhoneNumberInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPhoneNumberInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPhoneNumberInput>;
};

export type ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutDirectBookingExclusionRulesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput>;
  upsert?: Maybe<ClinicUpsertWithoutDirectBookingExclusionRulesInput>;
};

export type ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignLogInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignLogInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignLogInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignLogInput>;
};

export type ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignInput>;
};

export type ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFinancialTransactionsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFinancialTransactionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFinancialTransactionsInput>;
};

export type ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutHillsToHomeApiLogInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutHillsToHomeApiLogInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput>;
  upsert?: Maybe<ClinicUpsertWithoutHillsToHomeApiLogInput>;
};

export type ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutInformPartnerProgramEnrollmentsInput>;
};

export type ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInsuranceIntegrationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInsuranceIntegrationsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutInsuranceIntegrationsInput>;
};

export type ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<ClinicUpsertWithoutLoyaltyPointDeltasInput>;
};

export type ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMassTextAlertsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMassTextAlertsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMassTextAlertsInput>;
  upsert?: Maybe<ClinicUpsertWithoutMassTextAlertsInput>;
};

export type ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMessageTemplatesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMessageTemplatesInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMessageTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutMessageTemplatesInput>;
};

export type ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPayoutBatchingPeriodsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPayoutBatchingPeriodsInput>;
};

export type ClinicUpdateOneRequiredWithoutPostcardSettingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPostcardSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPostcardSettingsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPostcardSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPostcardSettingsInput>;
};

export type ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPrescriptionsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutPrescriptionsInput>;
};

export type ClinicUpdateOneRequiredWithoutRulesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRulesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutRulesInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRulesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRulesInput>;
};

export type ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<ClinicUpsertWithoutSentTrupanionExamDayOffersInput>;
};

export type ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripeTerminalLocationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStripeTerminalLocationsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripeTerminalLocationsInput>;
};

export type ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutSurveyResultsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutSurveyResultsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutSurveyResultsInput>;
  upsert?: Maybe<ClinicUpsertWithoutSurveyResultsInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioConfigurationNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioConfigurationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioConfigurationInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioConfigurationInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioConfigurationInput>;
};

export type ClinicUpdateOneRequiredWithoutTwilioVerificationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutTwilioVerificationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutTwilioVerificationsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutTwilioVerificationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutTwilioVerificationsInput>;
};

export type ClinicUpdateOneRequiredWithoutUserBoardFiltersColumnsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserBoardFiltersColumnsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput>;
  upsert?: Maybe<ClinicUpsertWithoutUserBoardFiltersColumnsInput>;
};

export type ClinicUpdateOneRequiredWithoutVaccinationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVaccinationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVaccinationsInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVaccinationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutVaccinationsInput>;
};

export type ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetAvailabilitiesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVetAvailabilitiesInput>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVetAvailabilitiesInput>;
  upsert?: Maybe<ClinicUpsertWithoutVetAvailabilitiesInput>;
};

export type ClinicUpdateOneWithoutAppoinmentTypesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppoinmentTypesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppoinmentTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppoinmentTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppoinmentTypesInput>;
};

export type ClinicUpdateOneWithoutAppointmentAvailabilitiesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentAvailabilitiesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppointmentAvailabilitiesInput>;
};

export type ClinicUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutAppointmentsInput>;
};

export type ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutCarePayoutOrganizationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutCarePayoutOrganizationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput>;
  upsert?: Maybe<ClinicUpsertWithoutCarePayoutOrganizationInput>;
};

export type ClinicUpdateOneWithoutChannelsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutChannelsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutChannelsInput>;
  upsert?: Maybe<ClinicUpsertWithoutChannelsInput>;
};

export type ClinicUpdateOneWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicEmployeesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicEmployeesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicEmployeesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicEmployeesInput>;
};

export type ClinicUpdateOneWithoutClinicOfficeHoursNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicOfficeHoursInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicOfficeHoursInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicOfficeHoursInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicOfficeHoursInput>;
};

export type ClinicUpdateOneWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetParentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetParentsInput>;
};

export type ClinicUpdateOneWithoutClinicPetsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicPetsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicPetsInput>;
};

export type ClinicUpdateOneWithoutClinicRoomsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicRoomsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicRoomsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicRoomsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicRoomsInput>;
};

export type ClinicUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicSettingInput>;
};

export type ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWidgetRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWidgetRequestsInput>;
};

export type ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutClinicWorkflowTypesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutClinicWorkflowTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput>;
  upsert?: Maybe<ClinicUpsertWithoutClinicWorkflowTypesInput>;
};

export type ClinicUpdateOneWithoutConsultationRequestsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationRequestsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationRequestsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationRequestsInput>;
};

export type ClinicUpdateOneWithoutConsultationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutConsultationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutConsultationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutConsultationsInput>;
};

export type ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignTemplateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignTemplateInput>;
};

export type ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutEmailCampaignUnsubscribeInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput>;
  upsert?: Maybe<ClinicUpsertWithoutEmailCampaignUnsubscribeInput>;
};

export type ClinicUpdateOneWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFormSubmissionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormSubmissionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormSubmissionsInput>;
};

export type ClinicUpdateOneWithoutFormTemplatesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutFormTemplatesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutFormTemplatesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutFormTemplatesInput>;
  upsert?: Maybe<ClinicUpsertWithoutFormTemplatesInput>;
};

export type ClinicUpdateOneWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutInformPartnerCustomerInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutInformPartnerCustomerInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutInformPartnerCustomerInput>;
  upsert?: Maybe<ClinicUpsertWithoutInformPartnerCustomerInput>;
};

export type ClinicUpdateOneWithoutIntegrationsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutIntegrationsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutIntegrationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutIntegrationsInput>;
  upsert?: Maybe<ClinicUpsertWithoutIntegrationsInput>;
};

export type ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput>;
  upsert?: Maybe<ClinicUpsertWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type ClinicUpdateOneWithoutMediaNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutMediaInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutMediaInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutMediaInput>;
  upsert?: Maybe<ClinicUpsertWithoutMediaInput>;
};

export type ClinicUpdateOneWithoutPaymentProcessorForProviderGroupNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPaymentProcessorForProviderGroupInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput>;
  upsert?: Maybe<ClinicUpsertWithoutPaymentProcessorForProviderGroupInput>;
};

export type ClinicUpdateOneWithoutPetsIdNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPetsIdInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPetsIdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPetsIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutPetsIdInput>;
};

export type ClinicUpdateOneWithoutPromoCodesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutPromoCodesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutPromoCodesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutPromoCodesInput>;
  upsert?: Maybe<ClinicUpsertWithoutPromoCodesInput>;
};

export type ClinicUpdateOneWithoutRolesNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutRolesInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutRolesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutRolesInput>;
  upsert?: Maybe<ClinicUpsertWithoutRolesInput>;
};

export type ClinicUpdateOneWithoutStaffIdNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStaffIdInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStaffIdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStaffIdInput>;
  upsert?: Maybe<ClinicUpsertWithoutStaffIdInput>;
};

export type ClinicUpdateOneWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<ClinicUpsertWithoutStripePaymentIntentsInput>;
};

export type ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutUserAppointmentGroupingsInput>;
};

export type ClinicUpdateOneWithoutVetsourceInstallationNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutVetsourceInstallationInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutVetsourceInstallationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutVetsourceInstallationInput>;
  upsert?: Maybe<ClinicUpsertWithoutVetsourceInstallationInput>;
};

export type ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  connect?: Maybe<ClinicWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<ClinicUncheckedCreateWithoutWorkflowEventSettingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<ClinicUpsertWithoutWorkflowEventSettingsInput>;
};

export type ClinicUpdateWithWhereUniqueWithoutCarePlanProviderGroupInput = {
  data: ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  data: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutFeatureFlagsInput = {
  data: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutLegalEntityInput = {
  data: ClinicUncheckedUpdateWithoutLegalEntityInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutLoyaltyProgramInput = {
  data: ClinicUncheckedUpdateWithoutLoyaltyProgramInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ClinicUncheckedUpdateWithoutOrganizationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutPetsInput = {
  data: ClinicUncheckedUpdateWithoutPetsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStaffInput = {
  data: ClinicUncheckedUpdateWithoutStaffInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutStripePayoutAccountsInput = {
  data: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUnlayerSettingInput = {
  data: ClinicUncheckedUpdateWithoutUnlayerSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersIdInput = {
  data: ClinicUncheckedUpdateWithoutUsersIdInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithWhereUniqueWithoutUsersInput = {
  data: ClinicUncheckedUpdateWithoutUsersInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpdateWithoutAppoinmentTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAppointmentAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAppointmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutAutomationRunsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBenefitsProvidedInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBitwerxHealthchecksInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutBlockedPhoneNumbersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCallsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCarePayoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutCarePlanProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelFilterSelectionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutChannelsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicDirectBookingSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicEmailCampaignSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicEmployeesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicOfficeHoursInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicOnboardingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPetParentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicPhoneNumberInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicRoomsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWidgetRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWidgetSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutClinicWorkflowTypesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutConsultationRequestsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutConsultationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutDirectBookingExclusionRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignLogInput = {
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignTemplateInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutEmailCampaignUnsubscribeInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFeatureFlagsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFinancialTransactionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFormSubmissionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutFormTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutHillsToHomeApiLogInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInformPartnerCustomerInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutInsuranceIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutIntegrationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLegalEntityInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyPointDeltasInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyProgramInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMassTextAlertsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMediaInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutMessageTemplatesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutOrganizationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPaymentProcessorForProviderGroupInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPayoutBatchingPeriodsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPetsIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPetsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPostcardSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPrescriptionsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutPromoCodesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutRolesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutRulesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStaffIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStaffInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripePaymentIntentsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripePayoutAccountsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutStripeTerminalLocationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutSurveyResultsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutTwilioConfigurationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutTwilioVerificationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUnlayerSettingInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUserAppointmentGroupingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUserBoardFiltersColumnsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUsersIdInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutUsersInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVaccinationsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVetAvailabilitiesInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutVetsourceInstallationInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutClinicNestedInput>;
};

export type ClinicUpdateWithoutWorkflowEventSettingsInput = {
  EmailCampaignLog?: Maybe<EmailCampaignLogUpdateManyWithoutClinicNestedInput>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogUpdateManyWithoutClinicNestedInput>;
  Rules?: Maybe<RulesUpdateManyWithoutClinicNestedInput>;
  activationDate?: Maybe<Scalars['DateTime']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  allowAllUsersToCloseConsultations?: Maybe<Scalars['Boolean']>;
  appoinmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutClinicNestedInput>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityUpdateManyWithoutClinicNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutClinicNestedInput>;
  autoSubscriptionReceiptEmails?: Maybe<Scalars['Json']>;
  automaticSubscriptionReceipts?: Maybe<Scalars['Boolean']>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicNestedInput>;
  benefitsProvided?: Maybe<CareBenefitUsageUpdateManyWithoutUsageClinicNestedInput>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckUpdateManyWithoutClinicNestedInput>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutClinicNestedInput>;
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  calls?: Maybe<CallHistoryUpdateManyWithoutClinicNestedInput>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationNote?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  carePayoutOrganization?: Maybe<OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutParticipatingClinicsNestedInput>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionUpdateManyWithoutClinicNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutClinicNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutClinicNestedInput>;
  city?: Maybe<Scalars['String']>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmail?: Maybe<Scalars['String']>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingUpdateManyWithoutClinicNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutClinicNestedInput>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourUpdateManyWithoutClinicNestedInput>;
  clinicOnboardings?: Maybe<ClinicOnboardingUpdateManyWithoutClinicNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutClinicNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutClinicNestedInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateManyWithoutClinicNestedInput>;
  clinicRooms?: Maybe<ClinicRoomUpdateManyWithoutClinicNestedInput>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneWithoutClinicNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicNestedInput>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput>;
  consultationCost?: Maybe<Scalars['Float']>;
  consultationRequests?: Maybe<ConsultationRequestUpdateManyWithoutClinicNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutClinicNestedInput>;
  corporateId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  crmUrlParams?: Maybe<Scalars['Json']>;
  customerId?: Maybe<Scalars['String']>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput>;
  emailCampaign?: Maybe<EmailCampaignUpdateManyWithoutClinicNestedInput>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutClinicNestedInput>;
  featureFlags?: Maybe<FeatureFlagUpdateManyWithoutClinicsNestedInput>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutClinicNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firebaseVets?: Maybe<Scalars['Json']>;
  followUpCost?: Maybe<Scalars['Float']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutClinicNestedInput>;
  formTemplates?: Maybe<FormTemplateUpdateManyWithoutClinicNestedInput>;
  fullAddress?: Maybe<Scalars['String']>;
  hasServiceReminders?: Maybe<Scalars['Boolean']>;
  hasSmsConversations?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  incomeMethod?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutClinicNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationUpdateManyWithoutClinicNestedInput>;
  integrationInfo?: Maybe<Scalars['Json']>;
  integrationSystem?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  integrations?: Maybe<ClinicPimsIntegrationUpdateManyWithoutClinicNestedInput>;
  isAddAppointmentDisabled?: Maybe<Scalars['Boolean']>;
  isCallerIdVerified?: Maybe<Scalars['Boolean']>;
  isClinicActive?: Maybe<Scalars['Boolean']>;
  isConsultationsActive?: Maybe<Scalars['Boolean']>;
  isIntegrationActive?: Maybe<Scalars['Boolean']>;
  isPaymentUsBank?: Maybe<Scalars['Boolean']>;
  isServiceRemindersActive?: Maybe<Scalars['Boolean']>;
  lastPrintOrderedOn?: Maybe<Scalars['DateTime']>;
  lastPrintOrderedQuantity?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  legalEntity?: Maybe<LegalEntityUpdateOneWithoutClinicsNestedInput>;
  logo?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['Float']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput>;
  loyaltyProgram?: Maybe<LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput>;
  marketEmail?: Maybe<Scalars['String']>;
  marketFacebookNumber?: Maybe<Scalars['Int']>;
  marketFacebookPageId?: Maybe<Scalars['String']>;
  marketFacebookPageToken?: Maybe<Scalars['String']>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutClinicNestedInput>;
  media?: Maybe<MediaUpdateManyWithoutClinicNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutClinicNestedInput>;
  name?: Maybe<Scalars['String']>;
  optimizedLogoUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutClinicsNestedInput>;
  paymentAddress?: Maybe<Scalars['String']>;
  paymentBankAccountNumber?: Maybe<Scalars['String']>;
  paymentBankRoutingNumber?: Maybe<Scalars['String']>;
  paymentCity?: Maybe<Scalars['String']>;
  paymentName?: Maybe<Scalars['String']>;
  paymentPhone?: Maybe<Scalars['String']>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupUpdateOneWithoutPaymentProcessorClinicNestedInput>;
  paymentState?: Maybe<Scalars['String']>;
  paymentZip?: Maybe<Scalars['String']>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput>;
  pets?: Maybe<PetUpdateManyWithoutPreviousClinicsNestedInput>;
  petsId?: Maybe<PetUpdateManyWithoutCurrentClinicNestedInput>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postcardSettings?: Maybe<ClinicPostcardSettingUpdateManyWithoutClinicNestedInput>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutClinicNestedInput>;
  printLastOrder?: Maybe<Scalars['DateTime']>;
  printReceivedQuantity?: Maybe<Scalars['Int']>;
  promoCodes?: Maybe<PromoCodeUpdateManyWithoutClinicNestedInput>;
  remindersIntegrationRequestEmail?: Maybe<Scalars['String']>;
  responseTime?: Maybe<Scalars['Float']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutClinicNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
  staff?: Maybe<VetInfoUpdateManyWithoutClinicsNestedInput>;
  staffId?: Maybe<VetInfoUpdateManyWithoutCurrentClinicNestedInput>;
  state?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutClinicNestedInput>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountUpdateManyWithoutClinicsNestedInput>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionPlan?: Maybe<Scalars['String']>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationUpdateManyWithoutClinicNestedInput>;
  submerchantApprovedAt?: Maybe<Scalars['DateTime']>;
  submerchantId?: Maybe<Scalars['String']>;
  submerchantIsActive?: Maybe<Scalars['Boolean']>;
  submerchantStatus?: Maybe<Scalars['String']>;
  submerchantStripeChargesEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeHasCustomPayouts?: Maybe<Scalars['Boolean']>;
  submerchantStripeId?: Maybe<Scalars['String']>;
  submerchantStripePayoutsEnabled?: Maybe<Scalars['Boolean']>;
  submerchantStripeStatus?: Maybe<Scalars['String']>;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  surveyResults?: Maybe<SurveyResultUpdateManyWithoutClinicNestedInput>;
  timezone?: Maybe<Scalars['Int']>;
  timezoneName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  twilioConfiguration?: Maybe<TwilioConfigurationUpdateOneWithoutClinicNestedInput>;
  twilioVerifications?: Maybe<TwilioVerificationUpdateManyWithoutClinicNestedInput>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneWithoutClinicsNestedInput>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutClinicNestedInput>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnUpdateManyWithoutClinicNestedInput>;
  users?: Maybe<UserUpdateManyWithoutClinicsNestedInput>;
  usersId?: Maybe<UserUpdateOneWithoutClinicsIdNestedInput>;
  vaccinations?: Maybe<VaccinationUpdateManyWithoutClinicNestedInput>;
  vetAvailabilities?: Maybe<VetAvailabilityUpdateManyWithoutClinicNestedInput>;
  vetsourceInstallation?: Maybe<VetsourceInstallationUpdateOneWithoutClinicNestedInput>;
  websiteUrl?: Maybe<Scalars['String']>;
  widgetSettings?: Maybe<Scalars['Json']>;
};

export type ClinicUpsertWithWhereUniqueWithoutCarePlanProviderGroupInput = {
  create: ClinicUncheckedCreateWithoutCarePlanProviderGroupInput;
  update: ClinicUncheckedUpdateWithoutCarePlanProviderGroupInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  create: ClinicUncheckedCreateWithoutClinicWidgetSettingInput;
  update: ClinicUncheckedUpdateWithoutClinicWidgetSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutFeatureFlagsInput = {
  create: ClinicUncheckedCreateWithoutFeatureFlagsInput;
  update: ClinicUncheckedUpdateWithoutFeatureFlagsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutLegalEntityInput = {
  create: ClinicUncheckedCreateWithoutLegalEntityInput;
  update: ClinicUncheckedUpdateWithoutLegalEntityInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutLoyaltyProgramInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyProgramInput;
  update: ClinicUncheckedUpdateWithoutLoyaltyProgramInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ClinicUncheckedCreateWithoutOrganizationInput;
  update: ClinicUncheckedUpdateWithoutOrganizationInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutPetsInput = {
  create: ClinicUncheckedCreateWithoutPetsInput;
  update: ClinicUncheckedUpdateWithoutPetsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStaffInput = {
  create: ClinicUncheckedCreateWithoutStaffInput;
  update: ClinicUncheckedUpdateWithoutStaffInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutStripePayoutAccountsInput = {
  create: ClinicUncheckedCreateWithoutStripePayoutAccountsInput;
  update: ClinicUncheckedUpdateWithoutStripePayoutAccountsInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUnlayerSettingInput = {
  create: ClinicUncheckedCreateWithoutUnlayerSettingInput;
  update: ClinicUncheckedUpdateWithoutUnlayerSettingInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersIdInput = {
  create: ClinicUncheckedCreateWithoutUsersIdInput;
  update: ClinicUncheckedUpdateWithoutUsersIdInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithWhereUniqueWithoutUsersInput = {
  create: ClinicUncheckedCreateWithoutUsersInput;
  update: ClinicUncheckedUpdateWithoutUsersInput;
  where: ClinicWhereUniqueInput;
};

export type ClinicUpsertWithoutAppoinmentTypesInput = {
  create: ClinicUncheckedCreateWithoutAppoinmentTypesInput;
  update: ClinicUncheckedUpdateWithoutAppoinmentTypesInput;
};

export type ClinicUpsertWithoutAppointmentAvailabilitiesInput = {
  create: ClinicUncheckedCreateWithoutAppointmentAvailabilitiesInput;
  update: ClinicUncheckedUpdateWithoutAppointmentAvailabilitiesInput;
};

export type ClinicUpsertWithoutAppointmentsInput = {
  create: ClinicUncheckedCreateWithoutAppointmentsInput;
  update: ClinicUncheckedUpdateWithoutAppointmentsInput;
};

export type ClinicUpsertWithoutAutomationRunsInput = {
  create: ClinicUncheckedCreateWithoutAutomationRunsInput;
  update: ClinicUncheckedUpdateWithoutAutomationRunsInput;
};

export type ClinicUpsertWithoutBenefitsProvidedInput = {
  create: ClinicUncheckedCreateWithoutBenefitsProvidedInput;
  update: ClinicUncheckedUpdateWithoutBenefitsProvidedInput;
};

export type ClinicUpsertWithoutBitwerxHealthchecksInput = {
  create: ClinicUncheckedCreateWithoutBitwerxHealthchecksInput;
  update: ClinicUncheckedUpdateWithoutBitwerxHealthchecksInput;
};

export type ClinicUpsertWithoutBlockedPhoneNumbersInput = {
  create: ClinicUncheckedCreateWithoutBlockedPhoneNumbersInput;
  update: ClinicUncheckedUpdateWithoutBlockedPhoneNumbersInput;
};

export type ClinicUpsertWithoutCallsInput = {
  create: ClinicUncheckedCreateWithoutCallsInput;
  update: ClinicUncheckedUpdateWithoutCallsInput;
};

export type ClinicUpsertWithoutCarePayoutOrganizationInput = {
  create: ClinicUncheckedCreateWithoutCarePayoutOrganizationInput;
  update: ClinicUncheckedUpdateWithoutCarePayoutOrganizationInput;
};

export type ClinicUpsertWithoutChannelFilterSelectionsInput = {
  create: ClinicUncheckedCreateWithoutChannelFilterSelectionsInput;
  update: ClinicUncheckedUpdateWithoutChannelFilterSelectionsInput;
};

export type ClinicUpsertWithoutChannelStatusChannelAssigneesInput = {
  create: ClinicUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  update: ClinicUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
};

export type ClinicUpsertWithoutChannelsInput = {
  create: ClinicUncheckedCreateWithoutChannelsInput;
  update: ClinicUncheckedUpdateWithoutChannelsInput;
};

export type ClinicUpsertWithoutClinicDirectBookingSettingsInput = {
  create: ClinicUncheckedCreateWithoutClinicDirectBookingSettingsInput;
  update: ClinicUncheckedUpdateWithoutClinicDirectBookingSettingsInput;
};

export type ClinicUpsertWithoutClinicEmailCampaignSettingsInput = {
  create: ClinicUncheckedCreateWithoutClinicEmailCampaignSettingsInput;
  update: ClinicUncheckedUpdateWithoutClinicEmailCampaignSettingsInput;
};

export type ClinicUpsertWithoutClinicEmployeesInput = {
  create: ClinicUncheckedCreateWithoutClinicEmployeesInput;
  update: ClinicUncheckedUpdateWithoutClinicEmployeesInput;
};

export type ClinicUpsertWithoutClinicOfficeHoursInput = {
  create: ClinicUncheckedCreateWithoutClinicOfficeHoursInput;
  update: ClinicUncheckedUpdateWithoutClinicOfficeHoursInput;
};

export type ClinicUpsertWithoutClinicOnboardingsInput = {
  create: ClinicUncheckedCreateWithoutClinicOnboardingsInput;
  update: ClinicUncheckedUpdateWithoutClinicOnboardingsInput;
};

export type ClinicUpsertWithoutClinicPetParentsInput = {
  create: ClinicUncheckedCreateWithoutClinicPetParentsInput;
  update: ClinicUncheckedUpdateWithoutClinicPetParentsInput;
};

export type ClinicUpsertWithoutClinicPetsInput = {
  create: ClinicUncheckedCreateWithoutClinicPetsInput;
  update: ClinicUncheckedUpdateWithoutClinicPetsInput;
};

export type ClinicUpsertWithoutClinicPhoneNumberInput = {
  create: ClinicUncheckedCreateWithoutClinicPhoneNumberInput;
  update: ClinicUncheckedUpdateWithoutClinicPhoneNumberInput;
};

export type ClinicUpsertWithoutClinicRoomsInput = {
  create: ClinicUncheckedCreateWithoutClinicRoomsInput;
  update: ClinicUncheckedUpdateWithoutClinicRoomsInput;
};

export type ClinicUpsertWithoutClinicSettingInput = {
  create: ClinicUncheckedCreateWithoutClinicSettingInput;
  update: ClinicUncheckedUpdateWithoutClinicSettingInput;
};

export type ClinicUpsertWithoutClinicWidgetRequestsInput = {
  create: ClinicUncheckedCreateWithoutClinicWidgetRequestsInput;
  update: ClinicUncheckedUpdateWithoutClinicWidgetRequestsInput;
};

export type ClinicUpsertWithoutClinicWorkflowTypesInput = {
  create: ClinicUncheckedCreateWithoutClinicWorkflowTypesInput;
  update: ClinicUncheckedUpdateWithoutClinicWorkflowTypesInput;
};

export type ClinicUpsertWithoutConsultationRequestsInput = {
  create: ClinicUncheckedCreateWithoutConsultationRequestsInput;
  update: ClinicUncheckedUpdateWithoutConsultationRequestsInput;
};

export type ClinicUpsertWithoutConsultationsInput = {
  create: ClinicUncheckedCreateWithoutConsultationsInput;
  update: ClinicUncheckedUpdateWithoutConsultationsInput;
};

export type ClinicUpsertWithoutDirectBookingExclusionRulesInput = {
  create: ClinicUncheckedCreateWithoutDirectBookingExclusionRulesInput;
  update: ClinicUncheckedUpdateWithoutDirectBookingExclusionRulesInput;
};

export type ClinicUpsertWithoutEmailCampaignInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignInput;
  update: ClinicUncheckedUpdateWithoutEmailCampaignInput;
};

export type ClinicUpsertWithoutEmailCampaignLogInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignLogInput;
  update: ClinicUncheckedUpdateWithoutEmailCampaignLogInput;
};

export type ClinicUpsertWithoutEmailCampaignTemplateInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignTemplateInput;
  update: ClinicUncheckedUpdateWithoutEmailCampaignTemplateInput;
};

export type ClinicUpsertWithoutEmailCampaignUnsubscribeInput = {
  create: ClinicUncheckedCreateWithoutEmailCampaignUnsubscribeInput;
  update: ClinicUncheckedUpdateWithoutEmailCampaignUnsubscribeInput;
};

export type ClinicUpsertWithoutFinancialTransactionsInput = {
  create: ClinicUncheckedCreateWithoutFinancialTransactionsInput;
  update: ClinicUncheckedUpdateWithoutFinancialTransactionsInput;
};

export type ClinicUpsertWithoutFormSubmissionsInput = {
  create: ClinicUncheckedCreateWithoutFormSubmissionsInput;
  update: ClinicUncheckedUpdateWithoutFormSubmissionsInput;
};

export type ClinicUpsertWithoutFormTemplatesInput = {
  create: ClinicUncheckedCreateWithoutFormTemplatesInput;
  update: ClinicUncheckedUpdateWithoutFormTemplatesInput;
};

export type ClinicUpsertWithoutHillsToHomeApiLogInput = {
  create: ClinicUncheckedCreateWithoutHillsToHomeApiLogInput;
  update: ClinicUncheckedUpdateWithoutHillsToHomeApiLogInput;
};

export type ClinicUpsertWithoutInformPartnerCustomerInput = {
  create: ClinicUncheckedCreateWithoutInformPartnerCustomerInput;
  update: ClinicUncheckedUpdateWithoutInformPartnerCustomerInput;
};

export type ClinicUpsertWithoutInformPartnerProgramEnrollmentsInput = {
  create: ClinicUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
  update: ClinicUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput;
};

export type ClinicUpsertWithoutInsuranceIntegrationsInput = {
  create: ClinicUncheckedCreateWithoutInsuranceIntegrationsInput;
  update: ClinicUncheckedUpdateWithoutInsuranceIntegrationsInput;
};

export type ClinicUpsertWithoutIntegrationsInput = {
  create: ClinicUncheckedCreateWithoutIntegrationsInput;
  update: ClinicUncheckedUpdateWithoutIntegrationsInput;
};

export type ClinicUpsertWithoutLoyaltyPointDeltasInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyPointDeltasInput;
  update: ClinicUncheckedUpdateWithoutLoyaltyPointDeltasInput;
};

export type ClinicUpsertWithoutLoyaltyRewardRedemptionActionsInput = {
  create: ClinicUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
  update: ClinicUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type ClinicUpsertWithoutMassTextAlertsInput = {
  create: ClinicUncheckedCreateWithoutMassTextAlertsInput;
  update: ClinicUncheckedUpdateWithoutMassTextAlertsInput;
};

export type ClinicUpsertWithoutMediaInput = {
  create: ClinicUncheckedCreateWithoutMediaInput;
  update: ClinicUncheckedUpdateWithoutMediaInput;
};

export type ClinicUpsertWithoutMessageTemplatesInput = {
  create: ClinicUncheckedCreateWithoutMessageTemplatesInput;
  update: ClinicUncheckedUpdateWithoutMessageTemplatesInput;
};

export type ClinicUpsertWithoutPaymentProcessorForProviderGroupInput = {
  create: ClinicUncheckedCreateWithoutPaymentProcessorForProviderGroupInput;
  update: ClinicUncheckedUpdateWithoutPaymentProcessorForProviderGroupInput;
};

export type ClinicUpsertWithoutPayoutBatchingPeriodsInput = {
  create: ClinicUncheckedCreateWithoutPayoutBatchingPeriodsInput;
  update: ClinicUncheckedUpdateWithoutPayoutBatchingPeriodsInput;
};

export type ClinicUpsertWithoutPetsIdInput = {
  create: ClinicUncheckedCreateWithoutPetsIdInput;
  update: ClinicUncheckedUpdateWithoutPetsIdInput;
};

export type ClinicUpsertWithoutPostcardSettingsInput = {
  create: ClinicUncheckedCreateWithoutPostcardSettingsInput;
  update: ClinicUncheckedUpdateWithoutPostcardSettingsInput;
};

export type ClinicUpsertWithoutPrescriptionsInput = {
  create: ClinicUncheckedCreateWithoutPrescriptionsInput;
  update: ClinicUncheckedUpdateWithoutPrescriptionsInput;
};

export type ClinicUpsertWithoutPromoCodesInput = {
  create: ClinicUncheckedCreateWithoutPromoCodesInput;
  update: ClinicUncheckedUpdateWithoutPromoCodesInput;
};

export type ClinicUpsertWithoutRolesInput = {
  create: ClinicUncheckedCreateWithoutRolesInput;
  update: ClinicUncheckedUpdateWithoutRolesInput;
};

export type ClinicUpsertWithoutRulesInput = {
  create: ClinicUncheckedCreateWithoutRulesInput;
  update: ClinicUncheckedUpdateWithoutRulesInput;
};

export type ClinicUpsertWithoutSentTrupanionExamDayOffersInput = {
  create: ClinicUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  update: ClinicUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
};

export type ClinicUpsertWithoutStaffIdInput = {
  create: ClinicUncheckedCreateWithoutStaffIdInput;
  update: ClinicUncheckedUpdateWithoutStaffIdInput;
};

export type ClinicUpsertWithoutStripePaymentIntentsInput = {
  create: ClinicUncheckedCreateWithoutStripePaymentIntentsInput;
  update: ClinicUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type ClinicUpsertWithoutStripeTerminalLocationsInput = {
  create: ClinicUncheckedCreateWithoutStripeTerminalLocationsInput;
  update: ClinicUncheckedUpdateWithoutStripeTerminalLocationsInput;
};

export type ClinicUpsertWithoutSurveyResultsInput = {
  create: ClinicUncheckedCreateWithoutSurveyResultsInput;
  update: ClinicUncheckedUpdateWithoutSurveyResultsInput;
};

export type ClinicUpsertWithoutTwilioConfigurationInput = {
  create: ClinicUncheckedCreateWithoutTwilioConfigurationInput;
  update: ClinicUncheckedUpdateWithoutTwilioConfigurationInput;
};

export type ClinicUpsertWithoutTwilioVerificationsInput = {
  create: ClinicUncheckedCreateWithoutTwilioVerificationsInput;
  update: ClinicUncheckedUpdateWithoutTwilioVerificationsInput;
};

export type ClinicUpsertWithoutUserAppointmentGroupingsInput = {
  create: ClinicUncheckedCreateWithoutUserAppointmentGroupingsInput;
  update: ClinicUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type ClinicUpsertWithoutUserBoardFiltersColumnsInput = {
  create: ClinicUncheckedCreateWithoutUserBoardFiltersColumnsInput;
  update: ClinicUncheckedUpdateWithoutUserBoardFiltersColumnsInput;
};

export type ClinicUpsertWithoutVaccinationsInput = {
  create: ClinicUncheckedCreateWithoutVaccinationsInput;
  update: ClinicUncheckedUpdateWithoutVaccinationsInput;
};

export type ClinicUpsertWithoutVetAvailabilitiesInput = {
  create: ClinicUncheckedCreateWithoutVetAvailabilitiesInput;
  update: ClinicUncheckedUpdateWithoutVetAvailabilitiesInput;
};

export type ClinicUpsertWithoutVetsourceInstallationInput = {
  create: ClinicUncheckedCreateWithoutVetsourceInstallationInput;
  update: ClinicUncheckedUpdateWithoutVetsourceInstallationInput;
};

export type ClinicUpsertWithoutWorkflowEventSettingsInput = {
  create: ClinicUncheckedCreateWithoutWorkflowEventSettingsInput;
  update: ClinicUncheckedUpdateWithoutWorkflowEventSettingsInput;
};

export type ClinicWhereInput = {
  AND?: Maybe<Array<ClinicWhereInput>>;
  EmailCampaignLog?: Maybe<EmailCampaignLogListRelationFilter>;
  EmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  HillsToHomeAPILog?: Maybe<HillsToHomeApiLogListRelationFilter>;
  NOT?: Maybe<Array<ClinicWhereInput>>;
  OR?: Maybe<Array<ClinicWhereInput>>;
  Rules?: Maybe<RulesListRelationFilter>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  allowAllUsersToCloseConsultations?: Maybe<BoolNullableFilter>;
  appoinmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilityListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  autoSubscriptionReceiptEmails?: Maybe<JsonNullableFilter>;
  automaticSubscriptionReceipts?: Maybe<BoolFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  benefitsProvided?: Maybe<CareBenefitUsageListRelationFilter>;
  bitwerxHealthchecks?: Maybe<BitwerxHealthcheckListRelationFilter>;
  blockedPhoneNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  callerIdPhoneNumber?: Maybe<StringNullableFilter>;
  calls?: Maybe<CallHistoryListRelationFilter>;
  canceledAt?: Maybe<DateTimeNullableFilter>;
  cancellationNote?: Maybe<StringNullableFilter>;
  cancellationReason?: Maybe<StringNullableFilter>;
  carePayoutOrganization?: Maybe<OrganizationWhereInput>;
  carePlanProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  carePlanProviderGroupId?: Maybe<StringNullableFilter>;
  channelFilterSelections?: Maybe<ChannelFilterSelectionListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  city?: Maybe<StringNullableFilter>;
  clinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingListRelationFilter>;
  clinicEmail?: Maybe<StringNullableFilter>;
  clinicEmailCampaignSettings?: Maybe<ClinicEmailCampaignSettingListRelationFilter>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicOfficeHours?: Maybe<ClinicOfficeHourListRelationFilter>;
  clinicOnboardings?: Maybe<ClinicOnboardingListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberListRelationFilter>;
  clinicRooms?: Maybe<ClinicRoomListRelationFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringNullableFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  clinicWorkflowTypes?: Maybe<ClinicWorkflowTypeListRelationFilter>;
  consultationCost?: Maybe<FloatNullableFilter>;
  consultationRequests?: Maybe<ConsultationRequestListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  corporateId?: Maybe<StringNullableFilter>;
  country?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  crmId?: Maybe<StringNullableFilter>;
  crmUrlParams?: Maybe<JsonNullableFilter>;
  customerId?: Maybe<StringNullableFilter>;
  directBookingExclusionRules?: Maybe<DirectBookingExclusionRuleListRelationFilter>;
  emailCampaign?: Maybe<EmailCampaignListRelationFilter>;
  emailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
  featureFlags?: Maybe<FeatureFlagListRelationFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firebaseVets?: Maybe<JsonNullableFilter>;
  followUpCost?: Maybe<FloatNullableFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  formTemplates?: Maybe<FormTemplateListRelationFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  hasServiceReminders?: Maybe<BoolNullableFilter>;
  hasSmsConversations?: Maybe<BoolNullableFilter>;
  headUserId?: Maybe<StringNullableFilter>;
  hours?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  incomeMethod?: Maybe<StringNullableFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
  insuranceIntegrations?: Maybe<InsuranceIntegrationListRelationFilter>;
  integrationInfo?: Maybe<JsonNullableFilter>;
  integrationSystem?: Maybe<StringNullableFilter>;
  integrationType?: Maybe<StringNullableFilter>;
  integrations?: Maybe<ClinicPimsIntegrationListRelationFilter>;
  isAddAppointmentDisabled?: Maybe<BoolNullableFilter>;
  isCallerIdVerified?: Maybe<BoolNullableFilter>;
  isClinicActive?: Maybe<BoolFilter>;
  isConsultationsActive?: Maybe<BoolNullableFilter>;
  isIntegrationActive?: Maybe<BoolNullableFilter>;
  isPaymentUsBank?: Maybe<BoolNullableFilter>;
  isServiceRemindersActive?: Maybe<BoolNullableFilter>;
  lastPrintOrderedOn?: Maybe<DateTimeNullableFilter>;
  lastPrintOrderedQuantity?: Maybe<IntNullableFilter>;
  lat?: Maybe<FloatNullableFilter>;
  legalEntity?: Maybe<LegalEntityWhereInput>;
  legalEntityId?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  long?: Maybe<FloatNullableFilter>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  loyaltyProgram?: Maybe<LoyaltyProgramWhereInput>;
  loyaltyProgramId?: Maybe<StringNullableFilter>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  marketEmail?: Maybe<StringNullableFilter>;
  marketFacebookNumber?: Maybe<IntNullableFilter>;
  marketFacebookPageId?: Maybe<StringNullableFilter>;
  marketFacebookPageToken?: Maybe<StringNullableFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
  media?: Maybe<MediaListRelationFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  name?: Maybe<StringFilter>;
  optimizedLogoUrl?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  paymentAddress?: Maybe<StringNullableFilter>;
  paymentBankAccountNumber?: Maybe<StringNullableFilter>;
  paymentBankRoutingNumber?: Maybe<StringNullableFilter>;
  paymentCity?: Maybe<StringNullableFilter>;
  paymentName?: Maybe<StringNullableFilter>;
  paymentPhone?: Maybe<StringNullableFilter>;
  paymentProcessorForProviderGroup?: Maybe<CarePlanProviderGroupWhereInput>;
  paymentState?: Maybe<StringNullableFilter>;
  paymentZip?: Maybe<StringNullableFilter>;
  payoutBatchingPeriods?: Maybe<PayoutBatchingPeriodListRelationFilter>;
  pets?: Maybe<PetListRelationFilter>;
  petsId?: Maybe<PetListRelationFilter>;
  phone?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  postcardSettings?: Maybe<ClinicPostcardSettingListRelationFilter>;
  ppcLocationId?: Maybe<IntNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  printLastOrder?: Maybe<DateTimeNullableFilter>;
  printReceivedQuantity?: Maybe<IntNullableFilter>;
  promoCodes?: Maybe<PromoCodeListRelationFilter>;
  remindersIntegrationRequestEmail?: Maybe<StringNullableFilter>;
  responseTime?: Maybe<FloatNullableFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  smsPhoneNumber?: Maybe<StringNullableFilter>;
  staff?: Maybe<VetInfoListRelationFilter>;
  staffId?: Maybe<VetInfoListRelationFilter>;
  state?: Maybe<StringNullableFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripePayoutAccounts?: Maybe<StripePayoutAccountListRelationFilter>;
  stripeSubscriptionId?: Maybe<StringNullableFilter>;
  stripeSubscriptionPlan?: Maybe<StringNullableFilter>;
  stripeTerminalLocations?: Maybe<StripeTerminalLocationListRelationFilter>;
  submerchantApprovedAt?: Maybe<DateTimeNullableFilter>;
  submerchantId?: Maybe<StringNullableFilter>;
  submerchantIsActive?: Maybe<BoolNullableFilter>;
  submerchantStatus?: Maybe<StringNullableFilter>;
  submerchantStripeChargesEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeHasCustomPayouts?: Maybe<BoolNullableFilter>;
  submerchantStripeId?: Maybe<StringNullableFilter>;
  submerchantStripePayoutsEnabled?: Maybe<BoolNullableFilter>;
  submerchantStripeStatus?: Maybe<StringNullableFilter>;
  subscribedAt?: Maybe<DateTimeNullableFilter>;
  subscriptionId?: Maybe<StringNullableFilter>;
  subscriptionPlan?: Maybe<StringNullableFilter>;
  surveyResults?: Maybe<SurveyResultListRelationFilter>;
  timezone?: Maybe<IntNullableFilter>;
  timezoneName?: Maybe<StringNullableFilter>;
  token?: Maybe<StringNullableFilter>;
  twilioConfiguration?: Maybe<TwilioConfigurationWhereInput>;
  twilioVerifications?: Maybe<TwilioVerificationListRelationFilter>;
  unlayerSetting?: Maybe<UnlayerSettingWhereInput>;
  unlayerSettingId?: Maybe<IntNullableFilter>;
  unsubscribedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  userBoardFiltersColumns?: Maybe<BoardFilterColumnListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  usersId?: Maybe<UserWhereInput>;
  vaccinations?: Maybe<VaccinationListRelationFilter>;
  vetAvailabilities?: Maybe<VetAvailabilityListRelationFilter>;
  vetsourceInstallation?: Maybe<VetsourceInstallationWhereInput>;
  websiteUrl?: Maybe<StringNullableFilter>;
  widgetSettings?: Maybe<JsonNullableFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type ClinicWhereUniqueInput = {
  callerIdPhoneNumber?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['Int']>;
  smsPhoneNumber?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequest = {
  __typename?: 'ClinicWidgetRequest';
  _count: ClinicWidgetRequestCountOutputType;
  automationRuns: Array<AutomationRun>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  directBookingAppointment?: Maybe<Appointment>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs: Array<PushNotificationLog>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};


export type ClinicWidgetRequestAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type ClinicWidgetRequestChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type ClinicWidgetRequestChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type ClinicWidgetRequestPushNotificationLogsArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};

export type ClinicWidgetRequestCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicWidgetRequestTypeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  directBookingAppointmentId: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  id: Scalars['Int'];
  isVerified: Scalars['Int'];
  requestFormSubmission: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
  verificationMethod: Scalars['Int'];
};

export type ClinicWidgetRequestCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestCountOutputType = {
  __typename?: 'ClinicWidgetRequestCountOutputType';
  automationRuns: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  pushNotificationLogs: Scalars['Int'];
};

export type ClinicWidgetRequestCreateInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope = {
  data: Array<ClinicWidgetRequestCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
};

export type ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutDirectBookingAppointmentInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput>;
};

export type ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestCreateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutClinicInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutDirectBookingAppointmentInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutFormSubmissionInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestCreateWithoutPushNotificationLogsInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutClinicWidgetRequestsInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentCreateNestedOneWithoutDirectBookingWidgetRequestInput>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestWhereInput>;
  none?: Maybe<ClinicWidgetRequestWhereInput>;
  some?: Maybe<ClinicWidgetRequestWhereInput>;
};

export type ClinicWidgetRequestMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  DirectBookingAppointmentId = 'directBookingAppointmentId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  Url = 'url'
}

export type ClinicWidgetRequestOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicWidgetRequestOrderByWithAggregationInput = {
  _count?: Maybe<ClinicWidgetRequestCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  requestFormSubmission?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicWidgetRequestOrderByRelevanceInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  directBookingAppointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  directBookingAppointmentId?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  requestFormSubmission?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  verificationMethod?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestRelationFilter = {
  is?: Maybe<ClinicWidgetRequestWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestWhereInput>;
};

export enum ClinicWidgetRequestScalarFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicWidgetRequestTypeId = 'clinicWidgetRequestTypeId',
  CreatedAt = 'createdAt',
  DirectBookingAppointmentId = 'directBookingAppointmentId',
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  IsVerified = 'isVerified',
  RequestFormSubmission = 'requestFormSubmission',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  VerificationMethod = 'verificationMethod'
}

export type ClinicWidgetRequestScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  directBookingAppointmentId?: Maybe<StringNullableFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
};

export type ClinicWidgetRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  directBookingAppointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isVerified?: Maybe<BoolNullableWithAggregatesFilter>;
  requestFormSubmission?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableWithAggregatesFilter>;
};

export type ClinicWidgetRequestSubscription = {
  __typename?: 'ClinicWidgetRequestSubscription';
  mutation: MutationType;
  node: ClinicWidgetRequest;
};

export type ClinicWidgetRequestType = {
  __typename?: 'ClinicWidgetRequestType';
  _count: ClinicWidgetRequestTypeCountOutputType;
  assignees: Array<User>;
  channelStatus?: Maybe<ChannelStatus>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplate>;
  formTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt: Scalars['DateTime'];
  workflows: Array<WorkflowEventSetting>;
};


export type ClinicWidgetRequestTypeAssigneesArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type ClinicWidgetRequestTypeClinicWidgetRequestsArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type ClinicWidgetRequestTypeWorkflowsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type ClinicWidgetRequestTypeAvgAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
};

export type ClinicWidgetRequestTypeAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountAggregateOutputType';
  _all: Scalars['Int'];
  channelStatusId: Scalars['Int'];
  clinicWidgetSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayName: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  order: Scalars['Int'];
  requestFormTemplate: Scalars['Int'];
  requestType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCountOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeCountOutputType = {
  __typename?: 'ClinicWidgetRequestTypeCountOutputType';
  assignees: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  workflows: Scalars['Int'];
};

export type ClinicWidgetRequestTypeCreateInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInput = {
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyChannelStatusInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope = {
  data: Array<ClinicWidgetRequestTypeCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWidgetRequestTypeCreateManyInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
};

export type ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
};

export type ClinicWidgetRequestTypeCreateNestedOneWithoutClinicWidgetRequestsInput = {
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeCreateWithoutAssigneesInput = {
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutChannelStatusInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetRequestsInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutFormTemplateInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeCreateWithoutWorkflowsInput = {
  assignees?: Maybe<UserCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatus?: Maybe<ChannelStatusCreateNestedOneWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeListRelationFilter = {
  every?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  none?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  some?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type ClinicWidgetRequestTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMaxAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeMaxOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeMinAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeMinAggregateOutputType';
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeMinOrderByAggregateInput = {
  channelStatusId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWidgetRequestTypeOrderByRelevanceFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  DisplayName = 'displayName',
  FormTemplateId = 'formTemplateId',
  Id = 'id'
}

export type ClinicWidgetRequestTypeOrderByRelevanceInput = {
  fields: Array<ClinicWidgetRequestTypeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicWidgetRequestTypeOrderByWithAggregationInput = {
  _avg?: Maybe<ClinicWidgetRequestTypeAvgOrderByAggregateInput>;
  _count?: Maybe<ClinicWidgetRequestTypeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetRequestTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetRequestTypeMinOrderByAggregateInput>;
  _sum?: Maybe<ClinicWidgetRequestTypeSumOrderByAggregateInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicWidgetRequestTypeOrderByRelevanceInput>;
  assignees?: Maybe<UserOrderByRelationAggregateInput>;
  channelStatus?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusId?: Maybe<SortOrder>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  requestFormTemplate?: Maybe<SortOrder>;
  requestType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type ClinicWidgetRequestTypeRelationFilter = {
  is?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  isNot?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export enum ClinicWidgetRequestTypeScalarFieldEnum {
  ChannelStatusId = 'channelStatusId',
  ClinicWidgetSettingId = 'clinicWidgetSettingId',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  FormTemplateId = 'formTemplateId',
  Id = 'id',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Order = 'order',
  RequestFormTemplate = 'requestFormTemplate',
  RequestType = 'requestType',
  UpdatedAt = 'updatedAt'
}

export type ClinicWidgetRequestTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  channelStatusId?: Maybe<StringNullableFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicWidgetRequestTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereWithAggregatesInput>>;
  channelStatusId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicWidgetSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  requestFormTemplate?: Maybe<JsonNullableWithAggregatesFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicWidgetRequestTypeSumAggregateOutputType = {
  __typename?: 'ClinicWidgetRequestTypeSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
};

export type ClinicWidgetRequestTypeSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
};

export type ClinicWidgetRequestTypeUncheckedCreateInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutChannelStatusInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
};

export type ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput>;
};

export type ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput = {
  assignees?: Maybe<UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType: WidgetRequestType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput = {
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput = {
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput = {
  assignees?: Maybe<UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatusId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUpdateInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
  where: ClinicWidgetRequestTypeScalarWhereInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypeInput;
  where: ClinicWidgetRequestTypeScalarWhereInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
  where: ClinicWidgetRequestTypeScalarWhereInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetRequestTypesInput;
  where: ClinicWidgetRequestTypeScalarWhereInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWidgetRequestTypesInput;
  where: ClinicWidgetRequestTypeScalarWhereInput;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutAssigneesInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutAssigneesInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutAssigneesInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutChannelStatusNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutChannelStatusInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutChannelStatusInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyChannelStatusInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutChannelStatusInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetSettingInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutClinicWidgetSettingInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyClinicWidgetSettingInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutClinicWidgetSettingInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ClinicWidgetRequestTypeCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestTypeCreateOrConnectWithoutWorkflowsInput>>;
  create?: Maybe<Array<ClinicWidgetRequestTypeCreateWithoutWorkflowsInput>>;
  delete?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestTypeUpdateManyWithWhereWithoutWorkflowsInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput>>;
};

export type ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  connect?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestTypeCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput>;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutAssigneesInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutChannelStatusInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutClinicWidgetSettingInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutFormTemplateInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpdateWithWhereUniqueWithoutWorkflowsInput = {
  data: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpdateWithoutAssigneesInput = {
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutChannelStatusInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetRequestsInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutClinicWidgetSettingInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutFormTemplateInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflows?: Maybe<WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput>;
};

export type ClinicWidgetRequestTypeUpdateWithoutWorkflowsInput = {
  assignees?: Maybe<UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput>;
  channelStatus?: Maybe<ChannelStatusUpdateOneWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  requestFormTemplate?: Maybe<Scalars['Json']>;
  requestType?: Maybe<WidgetRequestType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutAssigneesInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutAssigneesInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutAssigneesInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutChannelStatusInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutChannelStatusInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutChannelStatusInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutClinicWidgetSettingInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetSettingInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetSettingInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutFormTemplateInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutFormTemplateInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutFormTemplateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpsertWithWhereUniqueWithoutWorkflowsInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutWorkflowsInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutWorkflowsInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};

export type ClinicWidgetRequestTypeUpsertWithoutClinicWidgetRequestsInput = {
  create: ClinicWidgetRequestTypeUncheckedCreateWithoutClinicWidgetRequestsInput;
  update: ClinicWidgetRequestTypeUncheckedUpdateWithoutClinicWidgetRequestsInput;
};

export type ClinicWidgetRequestTypeWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestTypeWhereInput>>;
  assignees?: Maybe<UserListRelationFilter>;
  channelStatus?: Maybe<ChannelStatusWhereInput>;
  channelStatusId?: Maybe<StringNullableFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  clinicWidgetSetting?: Maybe<ClinicWidgetSettingWhereInput>;
  clinicWidgetSettingId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  formTemplateId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  order?: Maybe<IntFilter>;
  requestFormTemplate?: Maybe<JsonNullableFilter>;
  requestType?: Maybe<EnumWidgetRequestTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflows?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type ClinicWidgetRequestTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWidgetRequestUncheckedCreateInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutClinicWidgetRequestTypeInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
};

export type ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
};

export type ClinicWidgetRequestUncheckedCreateNestedOneWithoutDirectBookingAppointmentInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutClinicWidgetRequestInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestTypeNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ClinicWidgetRequestUncheckedUpdateOneWithoutDirectBookingAppointmentNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
  where: ClinicWidgetRequestScalarWhereInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
  where: ClinicWidgetRequestScalarWhereInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput = {
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
  where: ClinicWidgetRequestScalarWhereInput;
};

export type ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput = {
  data: ClinicWidgetRequestUncheckedUpdateManyWithoutClinicWidgetRequestsInput;
  where: ClinicWidgetRequestScalarWhereInput;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutClinicWidgetRequestTypeNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutClinicWidgetRequestTypeInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutClinicWidgetRequestTypeInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyClinicWidgetRequestTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutClinicWidgetRequestTypeInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput>>;
};

export type ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWidgetRequestCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ClinicWidgetRequestCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ClinicWidgetRequestCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWidgetRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWidgetRequestWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ClinicWidgetRequestUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ClinicWidgetRequestUpdateOneWithoutAutomationRunsNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutAutomationRunsInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutDirectBookingAppointmentNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutDirectBookingAppointmentInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput>;
};

export type ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput = {
  connect?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetRequestCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput>;
  upsert?: Maybe<ClinicWidgetRequestUpsertWithoutPushNotificationLogsInput>;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  data: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  data: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpdateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutClinicInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutClinicWidgetRequestTypeInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutDirectBookingAppointmentInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutFormSubmissionInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpdateWithoutPushNotificationLogsInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutClinicWidgetRequestNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutClinicWidgetRequestNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directBookingAppointment?: Maybe<AppointmentUpdateOneWithoutDirectBookingWidgetRequestNestedInput>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  requestFormSubmission?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  verificationMethod?: Maybe<ContactVerificationMethod>;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicPetParentInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicPetParentInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutClinicWidgetRequestTypeInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutClinicWidgetRequestTypeInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutClinicWidgetRequestTypeInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutFormSubmissionInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutFormSubmissionInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};

export type ClinicWidgetRequestUpsertWithoutAutomationRunsInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutAutomationRunsInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutAutomationRunsInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelAutomationStatusesInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type ClinicWidgetRequestUpsertWithoutChannelCreationSourcesInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutChannelCreationSourcesInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type ClinicWidgetRequestUpsertWithoutDirectBookingAppointmentInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutDirectBookingAppointmentInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutDirectBookingAppointmentInput;
};

export type ClinicWidgetRequestUpsertWithoutPushNotificationLogsInput = {
  create: ClinicWidgetRequestUncheckedCreateWithoutPushNotificationLogsInput;
  update: ClinicWidgetRequestUncheckedUpdateWithoutPushNotificationLogsInput;
};

export type ClinicWidgetRequestWhereInput = {
  AND?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetRequestWhereInput>>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestType?: Maybe<ClinicWidgetRequestTypeWhereInput>;
  clinicWidgetRequestTypeId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  directBookingAppointment?: Maybe<AppointmentWhereInput>;
  directBookingAppointmentId?: Maybe<StringNullableFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolNullableFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
  requestFormSubmission?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  verificationMethod?: Maybe<EnumContactVerificationMethodNullableFilter>;
};

export type ClinicWidgetRequestWhereUniqueInput = {
  directBookingAppointmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSetting = {
  __typename?: 'ClinicWidgetSetting';
  _count: ClinicWidgetSettingCountOutputType;
  clinic: Array<Clinic>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};


export type ClinicWidgetSettingClinicArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type ClinicWidgetSettingClinicWidgetRequestTypesArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};

export type ClinicWidgetSettingCountAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isEnabled: Scalars['Int'];
  primaryColor: Scalars['Int'];
  secondaryColor: Scalars['Int'];
  updatedAt: Scalars['Int'];
  welcomeMessage: Scalars['Int'];
  whiteLabelUrls: Scalars['Int'];
};

export type ClinicWidgetSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingCountOutputType = {
  __typename?: 'ClinicWidgetSettingCountOutputType';
  clinic: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
};

export type ClinicWidgetSettingCreateInput = {
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
};

export type ClinicWidgetSettingCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicInput = {
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};

export type ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};

export type ClinicWidgetSettingCreateWithoutClinicInput = {
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingCreateWithoutClinicWidgetRequestTypesInput = {
  clinic?: Maybe<ClinicCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingMaxAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingMinAggregateOutputType = {
  __typename?: 'ClinicWidgetSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export type ClinicWidgetSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
};

export enum ClinicWidgetSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
  WelcomeMessage = 'welcomeMessage'
}

export type ClinicWidgetSettingOrderByRelevanceInput = {
  fields: Array<ClinicWidgetSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicWidgetSettingOrderByWithAggregationInput = {
  _count?: Maybe<ClinicWidgetSettingCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWidgetSettingMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWidgetSettingMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicWidgetSettingOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  primaryColor?: Maybe<SortOrder>;
  secondaryColor?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  welcomeMessage?: Maybe<SortOrder>;
  whiteLabelUrls?: Maybe<SortOrder>;
};

export type ClinicWidgetSettingRelationFilter = {
  is?: Maybe<ClinicWidgetSettingWhereInput>;
  isNot?: Maybe<ClinicWidgetSettingWhereInput>;
};

export enum ClinicWidgetSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsEnabled = 'isEnabled',
  PrimaryColor = 'primaryColor',
  SecondaryColor = 'secondaryColor',
  UpdatedAt = 'updatedAt',
  WelcomeMessage = 'welcomeMessage',
  WhiteLabelUrls = 'whiteLabelUrls'
}

export type ClinicWidgetSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  primaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  secondaryColor?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  welcomeMessage?: Maybe<StringNullableWithAggregatesFilter>;
  whiteLabelUrls?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type ClinicWidgetSettingUncheckedCreateInput = {
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicInput = {
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  clinic?: Maybe<ClinicUncheckedCreateNestedManyWithoutClinicWidgetSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateInput = {
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicInput = {
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  clinic?: Maybe<ClinicUncheckedUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateInput = {
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicInput>;
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicInput>;
};

export type ClinicWidgetSettingUpdateOneWithoutClinicWidgetRequestTypesNestedInput = {
  connect?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<ClinicWidgetSettingCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  create?: Maybe<ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput>;
};

export type ClinicWidgetSettingUpdateWithoutClinicInput = {
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpdateWithoutClinicWidgetRequestTypesInput = {
  clinic?: Maybe<ClinicUpdateManyWithoutClinicWidgetSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  whiteLabelUrls?: Maybe<Scalars['Json']>;
};

export type ClinicWidgetSettingUpsertWithoutClinicInput = {
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicInput;
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicInput;
};

export type ClinicWidgetSettingUpsertWithoutClinicWidgetRequestTypesInput = {
  create: ClinicWidgetSettingUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  update: ClinicWidgetSettingUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
};

export type ClinicWidgetSettingWhereInput = {
  AND?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  NOT?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  OR?: Maybe<Array<ClinicWidgetSettingWhereInput>>;
  clinic?: Maybe<ClinicListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isEnabled?: Maybe<BoolNullableFilter>;
  primaryColor?: Maybe<StringNullableFilter>;
  secondaryColor?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  welcomeMessage?: Maybe<StringNullableFilter>;
  whiteLabelUrls?: Maybe<JsonNullableFilter>;
};

export type ClinicWidgetSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClinicWorkflowType = {
  __typename?: 'ClinicWorkflowType';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentType>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultWorkflowType: WorkflowType;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicWorkflowTypeCountAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeCountAggregateOutputType';
  _all: Scalars['Int'];
  appoinmentTypeId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultWorkflowType: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ClinicWorkflowTypeCountOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeCreateInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyClinicInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeCreateManyClinicInputEnvelope = {
  data: Array<ClinicWorkflowTypeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClinicWorkflowTypeCreateManyInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
};

export type ClinicWorkflowTypeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput = {
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeCreateOrConnectWithoutClinicInput = {
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeCreateWithoutAppointmentTypeInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutClinicWorkflowTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeCreateWithoutClinicInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutClinicWorkflowTypesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeListRelationFilter = {
  every?: Maybe<ClinicWorkflowTypeWhereInput>;
  none?: Maybe<ClinicWorkflowTypeWhereInput>;
  some?: Maybe<ClinicWorkflowTypeWhereInput>;
};

export type ClinicWorkflowTypeMaxAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMaxAggregateOutputType';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeMaxOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeMinAggregateOutputType = {
  __typename?: 'ClinicWorkflowTypeMinAggregateOutputType';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeMinOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClinicWorkflowTypeOrderByRelevanceFieldEnum {
  AppoinmentTypeId = 'appoinmentTypeId',
  ClinicId = 'clinicId',
  Id = 'id',
  Name = 'name'
}

export type ClinicWorkflowTypeOrderByRelevanceInput = {
  fields: Array<ClinicWorkflowTypeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ClinicWorkflowTypeOrderByWithAggregationInput = {
  _count?: Maybe<ClinicWorkflowTypeCountOrderByAggregateInput>;
  _max?: Maybe<ClinicWorkflowTypeMaxOrderByAggregateInput>;
  _min?: Maybe<ClinicWorkflowTypeMinOrderByAggregateInput>;
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ClinicWorkflowTypeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ClinicWorkflowTypeOrderByRelevanceInput>;
  appoinmentTypeId?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultWorkflowType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ClinicWorkflowTypeScalarFieldEnum {
  AppoinmentTypeId = 'appoinmentTypeId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DefaultWorkflowType = 'defaultWorkflowType',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ClinicWorkflowTypeScalarWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicWorkflowTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeScalarWhereWithAggregatesInput>>;
  appoinmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ClinicWorkflowTypeUncheckedCreateInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
};

export type ClinicWorkflowTypeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
};

export type ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedCreateWithoutClinicInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType: WorkflowType;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedUpdateInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
  where: ClinicWorkflowTypeScalarWhereInput;
};

export type ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput = {
  data: ClinicWorkflowTypeUncheckedUpdateManyWithoutClinicWorkflowTypesInput;
  where: ClinicWorkflowTypeScalarWhereInput;
};

export type ClinicWorkflowTypeUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ClinicWorkflowTypeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClinicWorkflowTypeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ClinicWorkflowTypeCreateWithoutClinicInput>>;
  createMany?: Maybe<ClinicWorkflowTypeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClinicWorkflowTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  set?: Maybe<Array<ClinicWorkflowTypeWhereUniqueInput>>;
  update?: Maybe<Array<ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ClinicWorkflowTypeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeUpdateWithWhereUniqueWithoutClinicInput = {
  data: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeUpdateWithoutAppointmentTypeInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutClinicWorkflowTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUpdateWithoutClinicInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutClinicWorkflowTypesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultWorkflowType?: Maybe<WorkflowType>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: ClinicWorkflowTypeUncheckedCreateWithoutAppointmentTypeInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutAppointmentTypeInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeUpsertWithWhereUniqueWithoutClinicInput = {
  create: ClinicWorkflowTypeUncheckedCreateWithoutClinicInput;
  update: ClinicWorkflowTypeUncheckedUpdateWithoutClinicInput;
  where: ClinicWorkflowTypeWhereUniqueInput;
};

export type ClinicWorkflowTypeWhereInput = {
  AND?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  NOT?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  OR?: Maybe<Array<ClinicWorkflowTypeWhereInput>>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultWorkflowType?: Maybe<EnumWorkflowTypeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClinicWorkflowTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  _count: ColorCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  pets: Array<ClinicPet>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type ColorPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};

export type ColorCountAggregateOutputType = {
  __typename?: 'ColorCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ColorCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ColorCountOutputType = {
  __typename?: 'ColorCountOutputType';
  pets: Scalars['Int'];
};

export type ColorCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutColorsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutColorModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateManyIntegrationInputEnvelope = {
  data: Array<ColorCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ColorCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
};

export type ColorCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<ColorWhereUniqueInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutPetsInput>;
  create?: Maybe<ColorUncheckedCreateWithoutPetsInput>;
};

export type ColorCreateOrConnectWithoutIntegrationInput = {
  create: ColorUncheckedCreateWithoutIntegrationInput;
  where: ColorWhereUniqueInput;
};

export type ColorCreateOrConnectWithoutPetsInput = {
  create: ColorUncheckedCreateWithoutPetsInput;
  where: ColorWhereUniqueInput;
};

export type ColorCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutColorModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutColorsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ColorListRelationFilter = {
  every?: Maybe<ColorWhereInput>;
  none?: Maybe<ColorWhereInput>;
  some?: Maybe<ColorWhereInput>;
};

export type ColorMaxAggregateOutputType = {
  __typename?: 'ColorMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ColorMinAggregateOutputType = {
  __typename?: 'ColorMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ColorOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ColorOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId'
}

export type ColorOrderByRelevanceInput = {
  fields: Array<ColorOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ColorOrderByWithAggregationInput = {
  _count?: Maybe<ColorCountOrderByAggregateInput>;
  _max?: Maybe<ColorMaxOrderByAggregateInput>;
  _min?: Maybe<ColorMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ColorOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ColorOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ColorRelationFilter = {
  is?: Maybe<ColorWhereInput>;
  isNot?: Maybe<ColorWhereInput>;
};

export enum ColorScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  UpdatedAt = 'updatedAt'
}

export type ColorScalarWhereInput = {
  AND?: Maybe<Array<ColorScalarWhereInput>>;
  NOT?: Maybe<Array<ColorScalarWhereInput>>;
  OR?: Maybe<Array<ColorScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ColorScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ColorScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ColorUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutColorModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
};

export type ColorUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutColorModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedCreateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedUpdateManyWithoutColorsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ColorWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ColorScalarWhereInput>>;
  disconnect?: Maybe<Array<ColorWhereUniqueInput>>;
  set?: Maybe<Array<ColorWhereUniqueInput>>;
  update?: Maybe<Array<ColorUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ColorUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ColorUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ColorUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutColorModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUncheckedUpdateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutColorModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUpdateManyWithWhereWithoutIntegrationInput = {
  data: ColorUncheckedUpdateManyWithoutColorsInput;
  where: ColorScalarWhereInput;
};

export type ColorUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ColorCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ColorCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ColorCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ColorWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ColorScalarWhereInput>>;
  disconnect?: Maybe<Array<ColorWhereUniqueInput>>;
  set?: Maybe<Array<ColorWhereUniqueInput>>;
  update?: Maybe<Array<ColorUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ColorUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ColorUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ColorUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<ColorWhereUniqueInput>;
  connectOrCreate?: Maybe<ColorCreateOrConnectWithoutPetsInput>;
  create?: Maybe<ColorUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ColorUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<ColorUpsertWithoutPetsInput>;
};

export type ColorUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ColorUncheckedUpdateWithoutIntegrationInput;
  where: ColorWhereUniqueInput;
};

export type ColorUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutColorModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUpdateWithoutPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutColorsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ColorUncheckedCreateWithoutIntegrationInput;
  update: ColorUncheckedUpdateWithoutIntegrationInput;
  where: ColorWhereUniqueInput;
};

export type ColorUpsertWithoutPetsInput = {
  create: ColorUncheckedCreateWithoutPetsInput;
  update: ColorUncheckedUpdateWithoutPetsInput;
};

export type ColorWhereInput = {
  AND?: Maybe<Array<ColorWhereInput>>;
  NOT?: Maybe<Array<ColorWhereInput>>;
  OR?: Maybe<Array<ColorWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ColorWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<ColorIntegrationIdPimsIdCompoundUniqueInput>;
};

export type Condition = {
  __typename?: 'Condition';
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  conditionSetId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  operand: Scalars['Json'];
  operator: ConditionOperator;
  set: ConditionSet;
  updatedAt: Scalars['DateTime'];
};

export enum ConditionAttribute {
  AppointmentTypeId = 'AppointmentTypeId',
  ClinicEmployeeId = 'ClinicEmployeeId',
  ClinicRoomId = 'ClinicRoomId',
  PetAgeInYears = 'PetAgeInYears',
  SpeciesType = 'SpeciesType',
  TrupanionCertificateAvailability = 'TrupanionCertificateAvailability'
}

export type ConditionCountAggregateOutputType = {
  __typename?: 'ConditionCountAggregateOutputType';
  _all: Scalars['Int'];
  attribute: Scalars['Int'];
  checksum: Scalars['Int'];
  conditionSetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  operand: Scalars['Int'];
  operator: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ConditionCountOrderByAggregateInput = {
  attribute?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionCreateInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  set: ConditionSetCreateNestedOneWithoutConditionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionCreateManyInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  conditionSetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionCreateManySetInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionCreateManySetInputEnvelope = {
  data: Array<ConditionCreateManySetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConditionCreateNestedManyWithoutSetInput = {
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
};

export type ConditionCreateOrConnectWithoutSetInput = {
  create: ConditionUncheckedCreateWithoutSetInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionCreateWithoutSetInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ConditionEvaluationStatus {
  Evaluate = 'Evaluate',
  Ignore = 'Ignore'
}

export type ConditionListRelationFilter = {
  every?: Maybe<ConditionWhereInput>;
  none?: Maybe<ConditionWhereInput>;
  some?: Maybe<ConditionWhereInput>;
};

export type ConditionMaxAggregateOutputType = {
  __typename?: 'ConditionMaxAggregateOutputType';
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  conditionSetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionMaxOrderByAggregateInput = {
  attribute?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionMinAggregateOutputType = {
  __typename?: 'ConditionMinAggregateOutputType';
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  conditionSetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionMinOrderByAggregateInput = {
  attribute?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ConditionOperator {
  Between = 'BETWEEN',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
  StartsWith = 'STARTS_WITH'
}

export type ConditionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConditionOrderByRelevanceFieldEnum {
  Checksum = 'checksum',
  ConditionSetId = 'conditionSetId',
  Id = 'id'
}

export type ConditionOrderByRelevanceInput = {
  fields: Array<ConditionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConditionOrderByWithAggregationInput = {
  _count?: Maybe<ConditionCountOrderByAggregateInput>;
  _max?: Maybe<ConditionMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionMinOrderByAggregateInput>;
  attribute?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConditionOrderByRelevanceInput>;
  attribute?: Maybe<SortOrder>;
  checksum?: Maybe<SortOrder>;
  conditionSetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operand?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  set?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ConditionScalarFieldEnum {
  Attribute = 'attribute',
  Checksum = 'checksum',
  ConditionSetId = 'conditionSetId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Operand = 'operand',
  Operator = 'operator',
  UpdatedAt = 'updatedAt'
}

export type ConditionScalarWhereInput = {
  AND?: Maybe<Array<ConditionScalarWhereInput>>;
  NOT?: Maybe<Array<ConditionScalarWhereInput>>;
  OR?: Maybe<Array<ConditionScalarWhereInput>>;
  attribute?: Maybe<EnumConditionAttributeFilter>;
  checksum?: Maybe<StringFilter>;
  conditionSetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  operand?: Maybe<JsonFilter>;
  operator?: Maybe<EnumConditionOperatorFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConditionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionScalarWhereWithAggregatesInput>>;
  attribute?: Maybe<EnumConditionAttributeWithAggregatesFilter>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  conditionSetId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  operand?: Maybe<JsonWithAggregatesFilter>;
  operator?: Maybe<EnumConditionOperatorWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ConditionSet = {
  __typename?: 'ConditionSet';
  _count: ConditionSetCountOutputType;
  children: Array<ConditionSet>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions: Array<Condition>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  negate: Scalars['Boolean'];
  operator: ConditionSetOperator;
  parent?: Maybe<ConditionSet>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ConditionSetChildrenArgs = {
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  distinct?: Maybe<ConditionSetScalarFieldEnum>;
  orderBy?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionSetWhereInput>;
};


export type ConditionSetConditionsArgs = {
  cursor?: Maybe<ConditionWhereUniqueInput>;
  distinct?: Maybe<ConditionScalarFieldEnum>;
  orderBy?: Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionWhereInput>;
};

export type ConditionSetCountAggregateOutputType = {
  __typename?: 'ConditionSetCountAggregateOutputType';
  _all: Scalars['Int'];
  conditionalEntityId: Scalars['Int'];
  conditionalEntityType: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  negate: Scalars['Int'];
  operator: Scalars['Int'];
  parentConditionSetId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ConditionSetCountOrderByAggregateInput = {
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionSetCountOutputType = {
  __typename?: 'ConditionSetCountOutputType';
  children: Scalars['Int'];
  conditions: Scalars['Int'];
};

export type ConditionSetCreateInput = {
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetCreateManyInput = {
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetCreateManyParentInput = {
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetCreateManyParentInputEnvelope = {
  data: Array<ConditionSetCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConditionSetCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
};

export type ConditionSetCreateNestedOneWithoutChildrenInput = {
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutChildrenInput>;
  create?: Maybe<ConditionSetUncheckedCreateWithoutChildrenInput>;
};

export type ConditionSetCreateNestedOneWithoutConditionsInput = {
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutConditionsInput>;
  create?: Maybe<ConditionSetUncheckedCreateWithoutConditionsInput>;
};

export type ConditionSetCreateOrConnectWithoutChildrenInput = {
  create: ConditionSetUncheckedCreateWithoutChildrenInput;
  where: ConditionSetWhereUniqueInput;
};

export type ConditionSetCreateOrConnectWithoutConditionsInput = {
  create: ConditionSetUncheckedCreateWithoutConditionsInput;
  where: ConditionSetWhereUniqueInput;
};

export type ConditionSetCreateOrConnectWithoutParentInput = {
  create: ConditionSetUncheckedCreateWithoutParentInput;
  where: ConditionSetWhereUniqueInput;
};

export type ConditionSetCreateWithoutChildrenInput = {
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetCreateWithoutConditionsInput = {
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parent?: Maybe<ConditionSetCreateNestedOneWithoutChildrenInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetCreateWithoutParentInput = {
  children?: Maybe<ConditionSetCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetListRelationFilter = {
  every?: Maybe<ConditionSetWhereInput>;
  none?: Maybe<ConditionSetWhereInput>;
  some?: Maybe<ConditionSetWhereInput>;
};

export type ConditionSetMaxAggregateOutputType = {
  __typename?: 'ConditionSetMaxAggregateOutputType';
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetMaxOrderByAggregateInput = {
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionSetMinAggregateOutputType = {
  __typename?: 'ConditionSetMinAggregateOutputType';
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetMinOrderByAggregateInput = {
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ConditionSetOperator {
  And = 'AND',
  Or = 'OR'
}

export type ConditionSetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConditionSetOrderByRelevanceFieldEnum {
  ConditionalEntityId = 'conditionalEntityId',
  Id = 'id',
  ParentConditionSetId = 'parentConditionSetId'
}

export type ConditionSetOrderByRelevanceInput = {
  fields: Array<ConditionSetOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConditionSetOrderByWithAggregationInput = {
  _count?: Maybe<ConditionSetCountOrderByAggregateInput>;
  _max?: Maybe<ConditionSetMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionSetMinOrderByAggregateInput>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  parentConditionSetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionSetOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConditionSetOrderByRelevanceInput>;
  children?: Maybe<ConditionSetOrderByRelationAggregateInput>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  conditions?: Maybe<ConditionOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  operator?: Maybe<SortOrder>;
  parent?: Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>;
  parentConditionSetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConditionSetRelationFilter = {
  is?: Maybe<ConditionSetWhereInput>;
  isNot?: Maybe<ConditionSetWhereInput>;
};

export enum ConditionSetScalarFieldEnum {
  ConditionalEntityId = 'conditionalEntityId',
  ConditionalEntityType = 'conditionalEntityType',
  CreatedAt = 'createdAt',
  Id = 'id',
  Negate = 'negate',
  Operator = 'operator',
  ParentConditionSetId = 'parentConditionSetId',
  UpdatedAt = 'updatedAt'
}

export type ConditionSetScalarWhereInput = {
  AND?: Maybe<Array<ConditionSetScalarWhereInput>>;
  NOT?: Maybe<Array<ConditionSetScalarWhereInput>>;
  OR?: Maybe<Array<ConditionSetScalarWhereInput>>;
  conditionalEntityId?: Maybe<StringFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  negate?: Maybe<BoolFilter>;
  operator?: Maybe<EnumConditionSetOperatorFilter>;
  parentConditionSetId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConditionSetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionSetScalarWhereWithAggregatesInput>>;
  conditionalEntityId?: Maybe<StringWithAggregatesFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  negate?: Maybe<BoolWithAggregatesFilter>;
  operator?: Maybe<EnumConditionSetOperatorWithAggregatesFilter>;
  parentConditionSetId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ConditionSetUncheckedCreateInput = {
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
};

export type ConditionSetUncheckedCreateWithoutChildrenInput = {
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedCreateWithoutConditionsInput = {
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedCreateWithoutParentInput = {
  children?: Maybe<ConditionSetUncheckedCreateNestedManyWithoutParentInput>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  conditions?: Maybe<ConditionUncheckedCreateNestedManyWithoutSetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: ConditionSetOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateInput = {
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateManyInput = {
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateManyWithoutChildrenInput = {
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConditionSetScalarWhereInput>>;
  disconnect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  set?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  update?: Maybe<Array<ConditionSetUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ConditionSetUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<ConditionSetUpsertWithWhereUniqueWithoutParentInput>>;
};

export type ConditionSetUncheckedUpdateWithoutChildrenInput = {
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateWithoutConditionsInput = {
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parentConditionSetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUncheckedUpdateWithoutParentInput = {
  children?: Maybe<ConditionSetUncheckedUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUncheckedUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpdateInput = {
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpdateManyMutationInput = {
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpdateManyWithWhereWithoutParentInput = {
  data: ConditionSetUncheckedUpdateManyWithoutChildrenInput;
  where: ConditionSetScalarWhereInput;
};

export type ConditionSetUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionSetCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ConditionSetCreateWithoutParentInput>>;
  createMany?: Maybe<ConditionSetCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConditionSetScalarWhereInput>>;
  disconnect?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  set?: Maybe<Array<ConditionSetWhereUniqueInput>>;
  update?: Maybe<Array<ConditionSetUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ConditionSetUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<ConditionSetUpsertWithWhereUniqueWithoutParentInput>>;
};

export type ConditionSetUpdateOneRequiredWithoutConditionsNestedInput = {
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutConditionsInput>;
  create?: Maybe<ConditionSetUncheckedCreateWithoutConditionsInput>;
  update?: Maybe<ConditionSetUncheckedUpdateWithoutConditionsInput>;
  upsert?: Maybe<ConditionSetUpsertWithoutConditionsInput>;
};

export type ConditionSetUpdateOneWithoutChildrenNestedInput = {
  connect?: Maybe<ConditionSetWhereUniqueInput>;
  connectOrCreate?: Maybe<ConditionSetCreateOrConnectWithoutChildrenInput>;
  create?: Maybe<ConditionSetUncheckedCreateWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ConditionSetUncheckedUpdateWithoutChildrenInput>;
  upsert?: Maybe<ConditionSetUpsertWithoutChildrenInput>;
};

export type ConditionSetUpdateWithWhereUniqueWithoutParentInput = {
  data: ConditionSetUncheckedUpdateWithoutParentInput;
  where: ConditionSetWhereUniqueInput;
};

export type ConditionSetUpdateWithoutChildrenInput = {
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpdateWithoutConditionsInput = {
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  parent?: Maybe<ConditionSetUpdateOneWithoutChildrenNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpdateWithoutParentInput = {
  children?: Maybe<ConditionSetUpdateManyWithoutParentNestedInput>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  conditions?: Maybe<ConditionUpdateManyWithoutSetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<ConditionSetOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionSetUpsertWithWhereUniqueWithoutParentInput = {
  create: ConditionSetUncheckedCreateWithoutParentInput;
  update: ConditionSetUncheckedUpdateWithoutParentInput;
  where: ConditionSetWhereUniqueInput;
};

export type ConditionSetUpsertWithoutChildrenInput = {
  create: ConditionSetUncheckedCreateWithoutChildrenInput;
  update: ConditionSetUncheckedUpdateWithoutChildrenInput;
};

export type ConditionSetUpsertWithoutConditionsInput = {
  create: ConditionSetUncheckedCreateWithoutConditionsInput;
  update: ConditionSetUncheckedUpdateWithoutConditionsInput;
};

export type ConditionSetWhereInput = {
  AND?: Maybe<Array<ConditionSetWhereInput>>;
  NOT?: Maybe<Array<ConditionSetWhereInput>>;
  OR?: Maybe<Array<ConditionSetWhereInput>>;
  children?: Maybe<ConditionSetListRelationFilter>;
  conditionalEntityId?: Maybe<StringFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  conditions?: Maybe<ConditionListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  negate?: Maybe<BoolFilter>;
  operator?: Maybe<EnumConditionSetOperatorFilter>;
  parent?: Maybe<ConditionSetWhereInput>;
  parentConditionSetId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConditionSetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConditionUncheckedCreateInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  conditionSetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUncheckedCreateNestedManyWithoutSetInput = {
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
};

export type ConditionUncheckedCreateWithoutSetInput = {
  attribute: ConditionAttribute;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand: Scalars['Json'];
  operator: ConditionOperator;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUncheckedUpdateInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  conditionSetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUncheckedUpdateManyInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  conditionSetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUncheckedUpdateManyWithoutConditionsInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUncheckedUpdateManyWithoutSetNestedInput = {
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  delete?: Maybe<Array<ConditionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConditionScalarWhereInput>>;
  disconnect?: Maybe<Array<ConditionWhereUniqueInput>>;
  set?: Maybe<Array<ConditionWhereUniqueInput>>;
  update?: Maybe<Array<ConditionUpdateWithWhereUniqueWithoutSetInput>>;
  updateMany?: Maybe<Array<ConditionUpdateManyWithWhereWithoutSetInput>>;
  upsert?: Maybe<Array<ConditionUpsertWithWhereUniqueWithoutSetInput>>;
};

export type ConditionUncheckedUpdateWithoutSetInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUpdateInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  set?: Maybe<ConditionSetUpdateOneRequiredWithoutConditionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUpdateManyMutationInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUpdateManyWithWhereWithoutSetInput = {
  data: ConditionUncheckedUpdateManyWithoutConditionsInput;
  where: ConditionScalarWhereInput;
};

export type ConditionUpdateManyWithoutSetNestedInput = {
  connect?: Maybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConditionCreateOrConnectWithoutSetInput>>;
  create?: Maybe<Array<ConditionCreateWithoutSetInput>>;
  createMany?: Maybe<ConditionCreateManySetInputEnvelope>;
  delete?: Maybe<Array<ConditionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConditionScalarWhereInput>>;
  disconnect?: Maybe<Array<ConditionWhereUniqueInput>>;
  set?: Maybe<Array<ConditionWhereUniqueInput>>;
  update?: Maybe<Array<ConditionUpdateWithWhereUniqueWithoutSetInput>>;
  updateMany?: Maybe<Array<ConditionUpdateManyWithWhereWithoutSetInput>>;
  upsert?: Maybe<Array<ConditionUpsertWithWhereUniqueWithoutSetInput>>;
};

export type ConditionUpdateWithWhereUniqueWithoutSetInput = {
  data: ConditionUncheckedUpdateWithoutSetInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionUpdateWithoutSetInput = {
  attribute?: Maybe<ConditionAttribute>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operand?: Maybe<Scalars['Json']>;
  operator?: Maybe<ConditionOperator>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConditionUpsertWithWhereUniqueWithoutSetInput = {
  create: ConditionUncheckedCreateWithoutSetInput;
  update: ConditionUncheckedUpdateWithoutSetInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionWhereInput = {
  AND?: Maybe<Array<ConditionWhereInput>>;
  NOT?: Maybe<Array<ConditionWhereInput>>;
  OR?: Maybe<Array<ConditionWhereInput>>;
  attribute?: Maybe<EnumConditionAttributeFilter>;
  checksum?: Maybe<StringFilter>;
  conditionSetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  operand?: Maybe<JsonFilter>;
  operator?: Maybe<EnumConditionOperatorFilter>;
  set?: Maybe<ConditionSetWhereInput>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConditionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConditionalEntityEvaluation = {
  __typename?: 'ConditionalEntityEvaluation';
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  /** The pirmary key (CUID) of the entity whose ConditionSet(s) were evaluated */
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  /** The primary key (CUID) of the entity whose derived context was evaluated against the ConditionalEntity */
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  result: Scalars['Boolean'];
};

export type ConditionalEntityEvaluationCountAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationCountAggregateOutputType';
  _all: Scalars['Int'];
  audit: Scalars['Int'];
  conditionalEntityChecksum: Scalars['Int'];
  conditionalEntityId: Scalars['Int'];
  conditionalEntityType: Scalars['Int'];
  context: Scalars['Int'];
  contextEntityChecksum: Scalars['Int'];
  contextEntityId: Scalars['Int'];
  contextEntityType: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  result: Scalars['Int'];
};

export type ConditionalEntityEvaluationCountOrderByAggregateInput = {
  audit?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export type ConditionalEntityEvaluationCreateInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type ConditionalEntityEvaluationCreateManyInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type ConditionalEntityEvaluationMaxAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationMaxAggregateOutputType';
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationMaxOrderByAggregateInput = {
  conditionalEntityChecksum?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export type ConditionalEntityEvaluationMinAggregateOutputType = {
  __typename?: 'ConditionalEntityEvaluationMinAggregateOutputType';
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationMinOrderByAggregateInput = {
  conditionalEntityChecksum?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export enum ConditionalEntityEvaluationOrderByRelevanceFieldEnum {
  ConditionalEntityChecksum = 'conditionalEntityChecksum',
  ConditionalEntityId = 'conditionalEntityId',
  ContextEntityChecksum = 'contextEntityChecksum',
  ContextEntityId = 'contextEntityId',
  Id = 'id'
}

export type ConditionalEntityEvaluationOrderByRelevanceInput = {
  fields: Array<ConditionalEntityEvaluationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConditionalEntityEvaluationOrderByWithAggregationInput = {
  _count?: Maybe<ConditionalEntityEvaluationCountOrderByAggregateInput>;
  _max?: Maybe<ConditionalEntityEvaluationMaxOrderByAggregateInput>;
  _min?: Maybe<ConditionalEntityEvaluationMinOrderByAggregateInput>;
  audit?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export type ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConditionalEntityEvaluationOrderByRelevanceInput>;
  audit?: Maybe<SortOrder>;
  conditionalEntityChecksum?: Maybe<SortOrder>;
  conditionalEntityId?: Maybe<SortOrder>;
  conditionalEntityType?: Maybe<SortOrder>;
  context?: Maybe<SortOrder>;
  contextEntityChecksum?: Maybe<SortOrder>;
  contextEntityId?: Maybe<SortOrder>;
  contextEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  result?: Maybe<SortOrder>;
};

export enum ConditionalEntityEvaluationScalarFieldEnum {
  Audit = 'audit',
  ConditionalEntityChecksum = 'conditionalEntityChecksum',
  ConditionalEntityId = 'conditionalEntityId',
  ConditionalEntityType = 'conditionalEntityType',
  Context = 'context',
  ContextEntityChecksum = 'contextEntityChecksum',
  ContextEntityId = 'contextEntityId',
  ContextEntityType = 'contextEntityType',
  CreatedAt = 'createdAt',
  Id = 'id',
  Result = 'result'
}

export type ConditionalEntityEvaluationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConditionalEntityEvaluationScalarWhereWithAggregatesInput>>;
  audit?: Maybe<JsonNullableWithAggregatesFilter>;
  conditionalEntityChecksum?: Maybe<StringNullableWithAggregatesFilter>;
  conditionalEntityId?: Maybe<StringWithAggregatesFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeWithAggregatesFilter>;
  context?: Maybe<JsonNullableWithAggregatesFilter>;
  contextEntityChecksum?: Maybe<StringNullableWithAggregatesFilter>;
  contextEntityId?: Maybe<StringWithAggregatesFilter>;
  contextEntityType?: Maybe<EnumContextEntityTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  result?: Maybe<BoolWithAggregatesFilter>;
};

export type ConditionalEntityEvaluationUncheckedCreateInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId: Scalars['String'];
  conditionalEntityType: ConditionalEntityType;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId: Scalars['String'];
  contextEntityType: ContextEntityType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type ConditionalEntityEvaluationUncheckedUpdateInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationUncheckedUpdateManyInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationUpdateInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationUpdateManyMutationInput = {
  audit?: Maybe<Scalars['Json']>;
  conditionalEntityChecksum?: Maybe<Scalars['String']>;
  conditionalEntityId?: Maybe<Scalars['String']>;
  conditionalEntityType?: Maybe<ConditionalEntityType>;
  context?: Maybe<Scalars['Json']>;
  contextEntityChecksum?: Maybe<Scalars['String']>;
  contextEntityId?: Maybe<Scalars['String']>;
  contextEntityType?: Maybe<ContextEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ConditionalEntityEvaluationWhereInput = {
  AND?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  NOT?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  OR?: Maybe<Array<ConditionalEntityEvaluationWhereInput>>;
  audit?: Maybe<JsonNullableFilter>;
  conditionalEntityChecksum?: Maybe<StringNullableFilter>;
  conditionalEntityId?: Maybe<StringFilter>;
  conditionalEntityType?: Maybe<EnumConditionalEntityTypeFilter>;
  context?: Maybe<JsonNullableFilter>;
  contextEntityChecksum?: Maybe<StringNullableFilter>;
  contextEntityId?: Maybe<StringFilter>;
  contextEntityType?: Maybe<EnumContextEntityTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  result?: Maybe<BoolFilter>;
};

export type ConditionalEntityEvaluationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ConditionalEntityType {
  WorkflowEventSetting = 'WorkflowEventSetting'
}

export type ConditionalSegmentsInput = {
  definitionVariables: SegmentBuilderDefinitionVariablesInput;
  segmentId: Scalars['String'];
};

export type ConfigureEmailCampaignsInput = {
  clinicId: Scalars['String'];
  publicIpAddress: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Consultation = {
  __typename?: 'Consultation';
  _count: ConsultationCountOutputType;
  appointments: Array<Appointment>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats: Array<ConsultationChat>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfo>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<Pet>;
  petId?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfo>;
  petParentId?: Maybe<Scalars['String']>;
  pics: Array<Image>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions: Array<Transaction>;
  treat?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vet?: Maybe<VetInfo>;
  veterinarianId?: Maybe<Scalars['String']>;
};


/** @deprecated - 1.0 */
export type ConsultationAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


/** @deprecated - 1.0 */
export type ConsultationChatsArgs = {
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationChatWhereInput>;
};


/** @deprecated - 1.0 */
export type ConsultationPicsArgs = {
  cursor?: Maybe<ImageWhereUniqueInput>;
  distinct?: Maybe<ImageScalarFieldEnum>;
  orderBy?: Maybe<ImageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ImageWhereInput>;
};


/** @deprecated - 1.0 */
export type ConsultationTransactionsArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};

export type ConsultationAvgAggregateOutputType = {
  __typename?: 'ConsultationAvgAggregateOutputType';
  clientCost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
};

export type ConsultationAvgOrderByAggregateInput = {
  clientCost?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ConsultationChat = {
  __typename?: 'ConsultationChat';
  consultation?: Maybe<Consultation>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConsultationChatCountAggregateOutputType = {
  __typename?: 'ConsultationChatCountAggregateOutputType';
  _all: Scalars['Int'];
  consultationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  creatorId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  isRead: Scalars['Int'];
  text: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ConsultationChatCountOrderByAggregateInput = {
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationChatCreateInput = {
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatCreateManyConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatCreateManyConsultationInputEnvelope = {
  data: Array<ConsultationChatCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyCreatorInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatCreateManyCreatorInputEnvelope = {
  data: Array<ConsultationChatCreateManyCreatorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationChatCreateManyInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
};

export type ConsultationChatCreateNestedManyWithoutCreatorInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
};

export type ConsultationChatCreateOrConnectWithoutConsultationInput = {
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatCreateOrConnectWithoutCreatorInput = {
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatCreateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserCreateNestedOneWithoutConsultationChatsInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatCreateWithoutCreatorInput = {
  consultation?: Maybe<ConsultationCreateNestedOneWithoutChatsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatListRelationFilter = {
  every?: Maybe<ConsultationChatWhereInput>;
  none?: Maybe<ConsultationChatWhereInput>;
  some?: Maybe<ConsultationChatWhereInput>;
};

export type ConsultationChatMaxAggregateOutputType = {
  __typename?: 'ConsultationChatMaxAggregateOutputType';
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatMaxOrderByAggregateInput = {
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationChatMinAggregateOutputType = {
  __typename?: 'ConsultationChatMinAggregateOutputType';
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatMinOrderByAggregateInput = {
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationChatOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationChatOrderByRelevanceFieldEnum {
  ConsultationId = 'consultationId',
  CreatorId = 'creatorId',
  FirebaseId = 'firebaseId',
  Id = 'id',
  Image = 'image',
  Text = 'text'
}

export type ConsultationChatOrderByRelevanceInput = {
  fields: Array<ConsultationChatOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConsultationChatOrderByWithAggregationInput = {
  _count?: Maybe<ConsultationChatCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationChatMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationChatMinOrderByAggregateInput>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creatorId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationChatOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConsultationChatOrderByRelevanceInput>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  creator?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  creatorId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  isRead?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ConsultationChatScalarFieldEnum {
  ConsultationId = 'consultationId',
  CreatedAt = 'createdAt',
  CreatedAtCopy = 'createdAtCopy',
  CreatorId = 'creatorId',
  FirebaseId = 'firebaseId',
  Id = 'id',
  Image = 'image',
  IsRead = 'isRead',
  Text = 'text',
  UpdatedAt = 'updatedAt'
}

export type ConsultationChatScalarWhereInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  image?: Maybe<StringNullableFilter>;
  isRead?: Maybe<BoolNullableFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConsultationChatScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationChatScalarWhereWithAggregatesInput>>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  creatorId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  image?: Maybe<StringNullableWithAggregatesFilter>;
  isRead?: Maybe<BoolNullableWithAggregatesFilter>;
  text?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ConsultationChatUncheckedCreateInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
};

export type ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
};

export type ConsultationChatUncheckedCreateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedCreateWithoutCreatorInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateManyInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutChatsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type ConsultationChatUncheckedUpdateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUncheckedUpdateWithoutCreatorInput = {
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUpdateInput = {
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUpdateManyWithWhereWithoutConsultationInput = {
  data: ConsultationChatUncheckedUpdateManyWithoutChatsInput;
  where: ConsultationChatScalarWhereInput;
};

export type ConsultationChatUpdateManyWithWhereWithoutCreatorInput = {
  data: ConsultationChatUncheckedUpdateManyWithoutConsultationChatsInput;
  where: ConsultationChatScalarWhereInput;
};

export type ConsultationChatUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutConsultationInput>>;
  createMany?: Maybe<ConsultationChatCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type ConsultationChatUpdateManyWithoutCreatorNestedInput = {
  connect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationChatCreateOrConnectWithoutCreatorInput>>;
  create?: Maybe<Array<ConsultationChatCreateWithoutCreatorInput>>;
  createMany?: Maybe<ConsultationChatCreateManyCreatorInputEnvelope>;
  delete?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationChatScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationChatWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: Maybe<Array<ConsultationChatUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: Maybe<Array<ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutConsultationInput = {
  data: ConsultationChatUncheckedUpdateWithoutConsultationInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatUpdateWithWhereUniqueWithoutCreatorInput = {
  data: ConsultationChatUncheckedUpdateWithoutCreatorInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatUpdateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserUpdateOneWithoutConsultationChatsNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUpdateWithoutCreatorInput = {
  consultation?: Maybe<ConsultationUpdateOneWithoutChatsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutConsultationInput = {
  create: ConsultationChatUncheckedCreateWithoutConsultationInput;
  update: ConsultationChatUncheckedUpdateWithoutConsultationInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatUpsertWithWhereUniqueWithoutCreatorInput = {
  create: ConsultationChatUncheckedCreateWithoutCreatorInput;
  update: ConsultationChatUncheckedUpdateWithoutCreatorInput;
  where: ConsultationChatWhereUniqueInput;
};

export type ConsultationChatWhereInput = {
  AND?: Maybe<Array<ConsultationChatWhereInput>>;
  NOT?: Maybe<Array<ConsultationChatWhereInput>>;
  OR?: Maybe<Array<ConsultationChatWhereInput>>;
  consultation?: Maybe<ConsultationWhereInput>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  creator?: Maybe<UserWhereInput>;
  creatorId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  image?: Maybe<StringNullableFilter>;
  isRead?: Maybe<BoolNullableFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConsultationChatWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ConsultationCountAggregateOutputType = {
  __typename?: 'ConsultationCountAggregateOutputType';
  _all: Scalars['Int'];
  callType: Scalars['Int'];
  cancelledAt: Scalars['Int'];
  clientCost: Scalars['Int'];
  clinicId: Scalars['Int'];
  consultationState: Scalars['Int'];
  consultationType: Scalars['Int'];
  cost: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdAtCopy: Scalars['Int'];
  details: Scalars['Int'];
  firebaseId: Scalars['Int'];
  id: Scalars['Int'];
  isPhoneAllowed: Scalars['Int'];
  isVetEmailed: Scalars['Int'];
  isVideoAllowed: Scalars['Int'];
  isVideoChat: Scalars['Int'];
  lastMessageAt: Scalars['Int'];
  nurseId: Scalars['Int'];
  openedAt: Scalars['Int'];
  petId: Scalars['Int'];
  petParentId: Scalars['Int'];
  resolvedAt: Scalars['Int'];
  scheduledAt: Scalars['Int'];
  summary: Scalars['Int'];
  transactionId: Scalars['Int'];
  treat: Scalars['Int'];
  updatedAt: Scalars['Int'];
  veterinarianId: Scalars['Int'];
};

export type ConsultationCountOrderByAggregateInput = {
  callType?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
};

export type ConsultationCountOutputType = {
  __typename?: 'ConsultationCountOutputType';
  appointments: Scalars['Int'];
  chats: Scalars['Int'];
  pics: Scalars['Int'];
  transactions: Scalars['Int'];
};

export type ConsultationCreateInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateManyClinicInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationCreateManyClinicInputEnvelope = {
  data: Array<ConsultationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationCreateManyNurseInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationCreateManyNurseInputEnvelope = {
  data: Array<ConsultationCreateManyNurseInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationCreateManyPetInputEnvelope = {
  data: Array<ConsultationCreateManyPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyPetParentInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationCreateManyPetParentInputEnvelope = {
  data: Array<ConsultationCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateManyVetInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationCreateManyVetInputEnvelope = {
  data: Array<ConsultationCreateManyVetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
};

export type ConsultationCreateNestedManyWithoutNurseInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
};

export type ConsultationCreateNestedManyWithoutPetInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
};

export type ConsultationCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
};

export type ConsultationCreateNestedManyWithoutPicsInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
};

export type ConsultationCreateNestedManyWithoutVetInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
};

export type ConsultationCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
};

export type ConsultationCreateNestedOneWithoutChatsInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
};

export type ConsultationCreateNestedOneWithoutTransactionsInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
};

export type ConsultationCreateOrConnectWithoutAppointmentsInput = {
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutChatsInput = {
  create: ConsultationUncheckedCreateWithoutChatsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutClinicInput = {
  create: ConsultationUncheckedCreateWithoutClinicInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutNurseInput = {
  create: ConsultationUncheckedCreateWithoutNurseInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutPetInput = {
  create: ConsultationUncheckedCreateWithoutPetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutPetParentInput = {
  create: ConsultationUncheckedCreateWithoutPetParentInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutPicsInput = {
  create: ConsultationUncheckedCreateWithoutPicsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutTransactionsInput = {
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateOrConnectWithoutVetInput = {
  create: ConsultationUncheckedCreateWithoutVetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationCreateWithoutAppointmentsInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutChatsInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutClinicInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutNurseInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPetParentInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutPicsInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutTransactionsInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoCreateNestedOneWithoutConsultationsInput>;
};

export type ConsultationCreateWithoutVetInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationsInput>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoCreateNestedOneWithoutConsultationsIdInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetCreateNestedOneWithoutConsultationsInput>;
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutConsultationsInput>;
  pics?: Maybe<ImageCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationListRelationFilter = {
  every?: Maybe<ConsultationWhereInput>;
  none?: Maybe<ConsultationWhereInput>;
  some?: Maybe<ConsultationWhereInput>;
};

export type ConsultationMaxAggregateOutputType = {
  __typename?: 'ConsultationMaxAggregateOutputType';
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationMaxOrderByAggregateInput = {
  callType?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
};

export type ConsultationMinAggregateOutputType = {
  __typename?: 'ConsultationMinAggregateOutputType';
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationMinOrderByAggregateInput = {
  callType?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
};

export type ConsultationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationOrderByRelevanceFieldEnum {
  CallType = 'callType',
  ClinicId = 'clinicId',
  ConsultationState = 'consultationState',
  ConsultationType = 'consultationType',
  Details = 'details',
  FirebaseId = 'firebaseId',
  Id = 'id',
  NurseId = 'nurseId',
  PetId = 'petId',
  PetParentId = 'petParentId',
  Summary = 'summary',
  TransactionId = 'transactionId',
  Treat = 'treat',
  VeterinarianId = 'veterinarianId'
}

export type ConsultationOrderByRelevanceInput = {
  fields: Array<ConsultationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConsultationOrderByWithAggregationInput = {
  _avg?: Maybe<ConsultationAvgOrderByAggregateInput>;
  _count?: Maybe<ConsultationCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationMinOrderByAggregateInput>;
  _sum?: Maybe<ConsultationSumOrderByAggregateInput>;
  callType?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  clientCost?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  nurseId?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  petId?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  resolvedAt?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  treat?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  veterinarianId?: Maybe<SortOrder>;
};

export type ConsultationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConsultationOrderByRelevanceInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  callType?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  chats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  clientCost?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  consultationState?: Maybe<SortOrder>;
  consultationType?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdAtCopy?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPhoneAllowed?: Maybe<SortOrder>;
  isVetEmailed?: Maybe<SortOrder>;
  isVideoAllowed?: Maybe<SortOrder>;
  isVideoChat?: Maybe<SortOrder>;
  lastMessageAt?: Maybe<SortOrder>;
  nurse?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  nurseId?: Maybe<SortOrder>;
  openedAt?: Maybe<SortOrder>;
  pet?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  petId?: Maybe<SortOrder>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  petParentId?: Maybe<SortOrder>;
  pics?: Maybe<ImageOrderByRelationAggregateInput>;
  resolvedAt?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  treat?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vet?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  veterinarianId?: Maybe<SortOrder>;
};

export type ConsultationRelationFilter = {
  is?: Maybe<ConsultationWhereInput>;
  isNot?: Maybe<ConsultationWhereInput>;
};

/** @deprecated - 1.0 */
export type ConsultationRequest = {
  __typename?: 'ConsultationRequest';
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  intent: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConsultationRequestCountAggregateOutputType = {
  __typename?: 'ConsultationRequestCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  consultationFirebaseId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  intent: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isExistingClient: Scalars['Int'];
  petName: Scalars['Int'];
  petParentEmail: Scalars['Int'];
  petParentFirstname: Scalars['Int'];
  petParentLastname: Scalars['Int'];
  petParentPhone: Scalars['Int'];
  petSpecies: Scalars['Int'];
  summary: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ConsultationRequestCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationRequestCreateInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutConsultationRequestsInput>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestCreateManyClinicInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestCreateManyClinicInputEnvelope = {
  data: Array<ConsultationRequestCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ConsultationRequestCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
};

export type ConsultationRequestCreateOrConnectWithoutClinicInput = {
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
  where: ConsultationRequestWhereUniqueInput;
};

export type ConsultationRequestCreateWithoutClinicInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestListRelationFilter = {
  every?: Maybe<ConsultationRequestWhereInput>;
  none?: Maybe<ConsultationRequestWhereInput>;
  some?: Maybe<ConsultationRequestWhereInput>;
};

export type ConsultationRequestMaxAggregateOutputType = {
  __typename?: 'ConsultationRequestMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationRequestMinAggregateOutputType = {
  __typename?: 'ConsultationRequestMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ConsultationRequestOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ConsultationFirebaseId = 'consultationFirebaseId',
  Id = 'id',
  Intent = 'intent',
  PetName = 'petName',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetSpecies = 'petSpecies',
  Summary = 'summary'
}

export type ConsultationRequestOrderByRelevanceInput = {
  fields: Array<ConsultationRequestOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ConsultationRequestOrderByWithAggregationInput = {
  _count?: Maybe<ConsultationRequestCountOrderByAggregateInput>;
  _max?: Maybe<ConsultationRequestMaxOrderByAggregateInput>;
  _min?: Maybe<ConsultationRequestMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ConsultationRequestOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ConsultationRequestOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  consultationFirebaseId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  intent?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExistingClient?: Maybe<SortOrder>;
  petName?: Maybe<SortOrder>;
  petParentEmail?: Maybe<SortOrder>;
  petParentFirstname?: Maybe<SortOrder>;
  petParentLastname?: Maybe<SortOrder>;
  petParentPhone?: Maybe<SortOrder>;
  petSpecies?: Maybe<SortOrder>;
  summary?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ConsultationRequestScalarFieldEnum {
  ClinicId = 'clinicId',
  ConsultationFirebaseId = 'consultationFirebaseId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Intent = 'intent',
  IsDeleted = 'isDeleted',
  IsExistingClient = 'isExistingClient',
  PetName = 'petName',
  PetParentEmail = 'petParentEmail',
  PetParentFirstname = 'petParentFirstname',
  PetParentLastname = 'petParentLastname',
  PetParentPhone = 'petParentPhone',
  PetSpecies = 'petSpecies',
  Summary = 'summary',
  UpdatedAt = 'updatedAt'
}

export type ConsultationRequestScalarWhereInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  intent?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  petName?: Maybe<StringFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConsultationRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationRequestScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationFirebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  intent?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isExistingClient?: Maybe<BoolNullableWithAggregatesFilter>;
  petName?: Maybe<StringWithAggregatesFilter>;
  petParentEmail?: Maybe<StringNullableWithAggregatesFilter>;
  petParentFirstname?: Maybe<StringWithAggregatesFilter>;
  petParentLastname?: Maybe<StringWithAggregatesFilter>;
  petParentPhone?: Maybe<StringWithAggregatesFilter>;
  petSpecies?: Maybe<StringNullableWithAggregatesFilter>;
  summary?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ConsultationRequestUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
};

export type ConsultationRequestUncheckedCreateWithoutClinicInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName: Scalars['String'];
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname: Scalars['String'];
  petParentLastname: Scalars['String'];
  petParentPhone: Scalars['String'];
  petSpecies?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUncheckedUpdateWithoutClinicInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationRequestsNestedInput>;
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUpdateManyMutationInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUpdateManyWithWhereWithoutClinicInput = {
  data: ConsultationRequestUncheckedUpdateManyWithoutConsultationRequestsInput;
  where: ConsultationRequestScalarWhereInput;
};

export type ConsultationRequestUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationRequestCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationRequestCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationRequestCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationRequestWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationRequestUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ConsultationRequestUpdateWithWhereUniqueWithoutClinicInput = {
  data: ConsultationRequestUncheckedUpdateWithoutClinicInput;
  where: ConsultationRequestWhereUniqueInput;
};

export type ConsultationRequestUpdateWithoutClinicInput = {
  consultationFirebaseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intent?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExistingClient?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentEmail?: Maybe<Scalars['String']>;
  petParentFirstname?: Maybe<Scalars['String']>;
  petParentLastname?: Maybe<Scalars['String']>;
  petParentPhone?: Maybe<Scalars['String']>;
  petSpecies?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationRequestUpsertWithWhereUniqueWithoutClinicInput = {
  create: ConsultationRequestUncheckedCreateWithoutClinicInput;
  update: ConsultationRequestUncheckedUpdateWithoutClinicInput;
  where: ConsultationRequestWhereUniqueInput;
};

export type ConsultationRequestWhereInput = {
  AND?: Maybe<Array<ConsultationRequestWhereInput>>;
  NOT?: Maybe<Array<ConsultationRequestWhereInput>>;
  OR?: Maybe<Array<ConsultationRequestWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  consultationFirebaseId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  intent?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isExistingClient?: Maybe<BoolNullableFilter>;
  petName?: Maybe<StringFilter>;
  petParentEmail?: Maybe<StringNullableFilter>;
  petParentFirstname?: Maybe<StringFilter>;
  petParentLastname?: Maybe<StringFilter>;
  petParentPhone?: Maybe<StringFilter>;
  petSpecies?: Maybe<StringNullableFilter>;
  summary?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ConsultationRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ConsultationScalarFieldEnum {
  CallType = 'callType',
  CancelledAt = 'cancelledAt',
  ClientCost = 'clientCost',
  ClinicId = 'clinicId',
  ConsultationState = 'consultationState',
  ConsultationType = 'consultationType',
  Cost = 'cost',
  CreatedAt = 'createdAt',
  CreatedAtCopy = 'createdAtCopy',
  Details = 'details',
  FirebaseId = 'firebaseId',
  Id = 'id',
  IsPhoneAllowed = 'isPhoneAllowed',
  IsVetEmailed = 'isVetEmailed',
  IsVideoAllowed = 'isVideoAllowed',
  IsVideoChat = 'isVideoChat',
  LastMessageAt = 'lastMessageAt',
  NurseId = 'nurseId',
  OpenedAt = 'openedAt',
  PetId = 'petId',
  PetParentId = 'petParentId',
  ResolvedAt = 'resolvedAt',
  ScheduledAt = 'scheduledAt',
  Summary = 'summary',
  TransactionId = 'transactionId',
  Treat = 'treat',
  UpdatedAt = 'updatedAt',
  VeterinarianId = 'veterinarianId'
}

export type ConsultationScalarWhereInput = {
  AND?: Maybe<Array<ConsultationScalarWhereInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereInput>>;
  callType?: Maybe<StringNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  nurseId?: Maybe<StringNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  petId?: Maybe<StringNullableFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  summary?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  treat?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  veterinarianId?: Maybe<StringNullableFilter>;
};

export type ConsultationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ConsultationScalarWhereWithAggregatesInput>>;
  callType?: Maybe<StringNullableWithAggregatesFilter>;
  cancelledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  clientCost?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  consultationState?: Maybe<StringWithAggregatesFilter>;
  consultationType?: Maybe<StringWithAggregatesFilter>;
  cost?: Maybe<FloatNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdAtCopy?: Maybe<DateTimeNullableWithAggregatesFilter>;
  details?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isPhoneAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVetEmailed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoAllowed?: Maybe<BoolNullableWithAggregatesFilter>;
  isVideoChat?: Maybe<BoolNullableWithAggregatesFilter>;
  lastMessageAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  nurseId?: Maybe<StringNullableWithAggregatesFilter>;
  openedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  petId?: Maybe<StringNullableWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  resolvedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  scheduledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  summary?: Maybe<StringNullableWithAggregatesFilter>;
  transactionId?: Maybe<StringNullableWithAggregatesFilter>;
  treat?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  veterinarianId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ConsultationSumAggregateOutputType = {
  __typename?: 'ConsultationSumAggregateOutputType';
  clientCost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
};

export type ConsultationSumOrderByAggregateInput = {
  clientCost?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
};

export type ConsultationUncheckedCreateInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
};

export type ConsultationUncheckedCreateNestedManyWithoutNurseInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
};

export type ConsultationUncheckedCreateNestedManyWithoutPicsInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
};

export type ConsultationUncheckedCreateNestedManyWithoutVetInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
};

export type ConsultationUncheckedCreateWithoutAppointmentsInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutChatsInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutClinicInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutNurseInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutPetInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutPetParentInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutPicsInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutTransactionsInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedCreateWithoutVetInput = {
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutConsultationInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutConsultationInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState: Scalars['String'];
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedCreateNestedManyWithoutConsultationInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutConsultationInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationUncheckedUpdateInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateManyInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsIdInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateManyWithoutConsultationsInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateManyWithoutNurseNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutPicsNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
};

export type ConsultationUncheckedUpdateManyWithoutVetNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
};

export type ConsultationUncheckedUpdateWithoutAppointmentsInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutChatsInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutClinicInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutNurseInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutPetInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutPetParentInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutPicsInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutTransactionsInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  veterinarianId?: Maybe<Scalars['String']>;
};

export type ConsultationUncheckedUpdateWithoutVetInput = {
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurseId?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petId?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  pics?: Maybe<ImageUncheckedUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationUpdateInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateManyMutationInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationUpdateManyWithWhereWithoutClinicInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithWhereWithoutNurseInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationsIdInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithWhereWithoutPetParentInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithWhereWithoutPicsInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithWhereWithoutVetInput = {
  data: ConsultationUncheckedUpdateManyWithoutConsultationsInput;
  where: ConsultationScalarWhereInput;
};

export type ConsultationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutClinicInput>>;
  createMany?: Maybe<ConsultationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type ConsultationUpdateManyWithoutNurseNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutNurseInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutNurseInput>>;
  createMany?: Maybe<ConsultationCreateManyNurseInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutNurseInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutNurseInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutNurseInput>>;
};

export type ConsultationUpdateManyWithoutPetNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetInput>>;
  createMany?: Maybe<ConsultationCreateManyPetInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetInput>>;
};

export type ConsultationUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPetParentInput>>;
  createMany?: Maybe<ConsultationCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type ConsultationUpdateManyWithoutPicsNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutPicsInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutPicsInput>>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutPicsInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutPicsInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutPicsInput>>;
};

export type ConsultationUpdateManyWithoutVetNestedInput = {
  connect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ConsultationCreateOrConnectWithoutVetInput>>;
  create?: Maybe<Array<ConsultationCreateWithoutVetInput>>;
  createMany?: Maybe<ConsultationCreateManyVetInputEnvelope>;
  delete?: Maybe<Array<ConsultationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ConsultationScalarWhereInput>>;
  disconnect?: Maybe<Array<ConsultationWhereUniqueInput>>;
  set?: Maybe<Array<ConsultationWhereUniqueInput>>;
  update?: Maybe<Array<ConsultationUpdateWithWhereUniqueWithoutVetInput>>;
  updateMany?: Maybe<Array<ConsultationUpdateManyWithWhereWithoutVetInput>>;
  upsert?: Maybe<Array<ConsultationUpsertWithWhereUniqueWithoutVetInput>>;
};

export type ConsultationUpdateOneWithoutAppointmentsNestedInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutAppointmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutAppointmentsInput>;
};

export type ConsultationUpdateOneWithoutChatsNestedInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutChatsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutChatsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutChatsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutChatsInput>;
};

export type ConsultationUpdateOneWithoutTransactionsNestedInput = {
  connect?: Maybe<ConsultationWhereUniqueInput>;
  connectOrCreate?: Maybe<ConsultationCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<ConsultationUncheckedCreateWithoutTransactionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ConsultationUncheckedUpdateWithoutTransactionsInput>;
  upsert?: Maybe<ConsultationUpsertWithoutTransactionsInput>;
};

export type ConsultationUpdateWithWhereUniqueWithoutClinicInput = {
  data: ConsultationUncheckedUpdateWithoutClinicInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutNurseInput = {
  data: ConsultationUncheckedUpdateWithoutNurseInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetInput = {
  data: ConsultationUncheckedUpdateWithoutPetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPetParentInput = {
  data: ConsultationUncheckedUpdateWithoutPetParentInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutPicsInput = {
  data: ConsultationUncheckedUpdateWithoutPicsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithWhereUniqueWithoutVetInput = {
  data: ConsultationUncheckedUpdateWithoutVetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpdateWithoutAppointmentsInput = {
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutChatsInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutClinicInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutNurseInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPetInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPetParentInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutPicsInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutTransactionsInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vet?: Maybe<VetInfoUpdateOneWithoutConsultationsNestedInput>;
};

export type ConsultationUpdateWithoutVetInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutConsultationNestedInput>;
  callType?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  chats?: Maybe<ConsultationChatUpdateManyWithoutConsultationNestedInput>;
  clientCost?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutConsultationsNestedInput>;
  consultationState?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAtCopy?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPhoneAllowed?: Maybe<Scalars['Boolean']>;
  isVetEmailed?: Maybe<Scalars['Boolean']>;
  isVideoAllowed?: Maybe<Scalars['Boolean']>;
  isVideoChat?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  nurse?: Maybe<VetInfoUpdateOneWithoutConsultationsIdNestedInput>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pet?: Maybe<PetUpdateOneWithoutConsultationsNestedInput>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutConsultationsNestedInput>;
  pics?: Maybe<ImageUpdateManyWithoutConsultationNestedInput>;
  resolvedAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutConsultationNestedInput>;
  treat?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConsultationUpsertWithWhereUniqueWithoutClinicInput = {
  create: ConsultationUncheckedCreateWithoutClinicInput;
  update: ConsultationUncheckedUpdateWithoutClinicInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutNurseInput = {
  create: ConsultationUncheckedCreateWithoutNurseInput;
  update: ConsultationUncheckedUpdateWithoutNurseInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetInput = {
  create: ConsultationUncheckedCreateWithoutPetInput;
  update: ConsultationUncheckedUpdateWithoutPetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPetParentInput = {
  create: ConsultationUncheckedCreateWithoutPetParentInput;
  update: ConsultationUncheckedUpdateWithoutPetParentInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutPicsInput = {
  create: ConsultationUncheckedCreateWithoutPicsInput;
  update: ConsultationUncheckedUpdateWithoutPicsInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithWhereUniqueWithoutVetInput = {
  create: ConsultationUncheckedCreateWithoutVetInput;
  update: ConsultationUncheckedUpdateWithoutVetInput;
  where: ConsultationWhereUniqueInput;
};

export type ConsultationUpsertWithoutAppointmentsInput = {
  create: ConsultationUncheckedCreateWithoutAppointmentsInput;
  update: ConsultationUncheckedUpdateWithoutAppointmentsInput;
};

export type ConsultationUpsertWithoutChatsInput = {
  create: ConsultationUncheckedCreateWithoutChatsInput;
  update: ConsultationUncheckedUpdateWithoutChatsInput;
};

export type ConsultationUpsertWithoutTransactionsInput = {
  create: ConsultationUncheckedCreateWithoutTransactionsInput;
  update: ConsultationUncheckedUpdateWithoutTransactionsInput;
};

export type ConsultationWhereInput = {
  AND?: Maybe<Array<ConsultationWhereInput>>;
  NOT?: Maybe<Array<ConsultationWhereInput>>;
  OR?: Maybe<Array<ConsultationWhereInput>>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  callType?: Maybe<StringNullableFilter>;
  cancelledAt?: Maybe<DateTimeNullableFilter>;
  chats?: Maybe<ConsultationChatListRelationFilter>;
  clientCost?: Maybe<FloatNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  consultationState?: Maybe<StringFilter>;
  consultationType?: Maybe<StringFilter>;
  cost?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdAtCopy?: Maybe<DateTimeNullableFilter>;
  details?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isPhoneAllowed?: Maybe<BoolNullableFilter>;
  isVetEmailed?: Maybe<BoolNullableFilter>;
  isVideoAllowed?: Maybe<BoolNullableFilter>;
  isVideoChat?: Maybe<BoolNullableFilter>;
  lastMessageAt?: Maybe<DateTimeNullableFilter>;
  nurse?: Maybe<VetInfoWhereInput>;
  nurseId?: Maybe<StringNullableFilter>;
  openedAt?: Maybe<DateTimeNullableFilter>;
  pet?: Maybe<PetWhereInput>;
  petId?: Maybe<StringNullableFilter>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  petParentId?: Maybe<StringNullableFilter>;
  pics?: Maybe<ImageListRelationFilter>;
  resolvedAt?: Maybe<DateTimeNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  summary?: Maybe<StringNullableFilter>;
  transactionId?: Maybe<StringNullableFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  treat?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vet?: Maybe<VetInfoWhereInput>;
  veterinarianId?: Maybe<StringNullableFilter>;
};

export type ConsultationWhereUniqueInput = {
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum ContactEntityType {
  ClinicPetParent = 'ClinicPetParent',
  User = 'User'
}

export enum ContactVerificationMethod {
  Email = 'Email',
  Text = 'Text'
}

export enum ContextEntityType {
  Appointment = 'Appointment',
  AutomationRunAction = 'AutomationRunAction'
}

export type CreateCareEnrollmentAndSubscriptionInput = {
  petSignups: Array<CarePetSignupInput>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export type CreateCareEnrollmentAndSubscriptionResult = {
  __typename?: 'CreateCareEnrollmentAndSubscriptionResult';
  message: Scalars['String'];
  paymentError?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateCarePaymentMethodSetupInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
};

export type CreateChannelFilterSelectionInput = {
  filterSelectionType: FilterSelectionType;
  filters: Scalars['Json'];
  name: Scalars['String'];
};

export type CreateEmailTemplateVersionInput = {
  design: Scalars['Json'];
  html: Scalars['String'];
  type: Scalars['String'];
  unlayerId: Scalars['Int'];
};

export type CreatePaymentMethodSetupInputResult = {
  __typename?: 'CreatePaymentMethodSetupInputResult';
  setupIntentSecret: Scalars['String'];
};

export type CreateTeamChannelInput = {
  assigneeIds: Array<Scalars['ID']>;
  channelVisibility?: Maybe<ChannelVisibility>;
  friendlyName: Scalars['String'];
};

export type CreateTwilioTokenInput = {
  identity: Scalars['String'];
};

export type CreateTwilioTokenResponse = {
  __typename?: 'CreateTwilioTokenResponse';
  token: Scalars['String'];
};

export type CreateWorkflowEventSettingAndConditionsInput = {
  conditions: Array<ConditionCreateWithoutSetInput>;
  workflowEventSetting: WorkflowEventSettingCreateInput;
};

export enum Currency {
  Usd = 'usd'
}

export type CurrentEmailTemplateVersionsFindResponse = {
  __typename?: 'CurrentEmailTemplateVersionsFindResponse';
  draft?: Maybe<EmailTemplateVersion>;
  published?: Maybe<EmailTemplateVersion>;
  unlayer: UnlayerUser;
};

export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  _count: CustomFieldDefinitionCountOutputType;
  clinicPetFieldValues: Array<ClinicPetCustomFieldValue>;
  /** Should only have one of these and should match the targetType */
  clinicPetParentFieldValues: Array<ClinicPetParentCustomFieldValue>;
  clinicPimsIntegration: ClinicPimsIntegration;
  clinicPimsIntegrationId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  designation: CustomFieldDesignation;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /**
   * isSensitiveData marks a field that contains data we do not want to sync regardless of a the settings a clinic may desire
   * because the information includes information like SSN, Credit Card, etc.
   * this behavior should override any sync settings if there is a conflict
   */
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  /** the DATE of the deletion of the field, not IF the field is deleted */
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  /** Raw Pims data synced from the pims */
  rawPimsValue?: Maybe<Scalars['Json']>;
  /** Used to resolve sync conflicts when pimsSyncType is ReadWrite, if null use latest */
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt: Scalars['DateTime'];
  writebackLogs: Array<CustomFieldPimsWritebackLogEntry>;
};


export type CustomFieldDefinitionClinicPetFieldValuesArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<ClinicPetCustomFieldValueScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type CustomFieldDefinitionClinicPetParentFieldValuesArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type CustomFieldDefinitionWritebackLogsArgs = {
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export type CustomFieldDefinitionCountAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPimsIntegrationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  designation: Scalars['Int'];
  existsInPims: Scalars['Int'];
  flowAlias: Scalars['Int'];
  id: Scalars['Int'];
  isSensitiveData: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  normalizedType: Scalars['Int'];
  pimsCreated: Scalars['Int'];
  pimsDataType: Scalars['Int'];
  pimsDeleted: Scalars['Int'];
  pimsDisplayName: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsIsDeleted: Scalars['Int'];
  pimsIsInactive: Scalars['Int'];
  pimsIsRequired: Scalars['Int'];
  pimsName: Scalars['Int'];
  pimsSourceId: Scalars['Int'];
  pimsSyncType: Scalars['Int'];
  pimsUpdated: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  source_of_truth: Scalars['Int'];
  targetType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CustomFieldDefinitionCountOrderByAggregateInput = {
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionCountOutputType = {
  __typename?: 'CustomFieldDefinitionCountOutputType';
  clinicPetFieldValues: Scalars['Int'];
  clinicPetParentFieldValues: Scalars['Int'];
  writebackLogs: Scalars['Int'];
};

export type CustomFieldDefinitionCreateInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateManyClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope = {
  data: Array<CustomFieldDefinitionCreateManyClinicPimsIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomFieldDefinitionCreateManyInput = {
  clinicPimsIntegrationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionCreateNestedManyWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutClinicPetFieldValuesInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutClinicPetParentFieldValuesInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput>;
};

export type CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput>;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionCreateWithoutClinicPetFieldValuesInput = {
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutClinicPetParentFieldValuesInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionCreateWithoutWritebackLogsInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegration: ClinicPimsIntegrationCreateNestedOneWithoutCustomFieldDefinitionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionListRelationFilter = {
  every?: Maybe<CustomFieldDefinitionWhereInput>;
  none?: Maybe<CustomFieldDefinitionWhereInput>;
  some?: Maybe<CustomFieldDefinitionWhereInput>;
};

export type CustomFieldDefinitionMaxAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionMaxAggregateOutputType';
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionMaxOrderByAggregateInput = {
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionMinAggregateOutputType = {
  __typename?: 'CustomFieldDefinitionMinAggregateOutputType';
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionMinOrderByAggregateInput = {
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CustomFieldDefinitionOrderByRelevanceFieldEnum {
  ClinicPimsIntegrationId = 'clinicPimsIntegrationId',
  FlowAlias = 'flowAlias',
  Id = 'id',
  PimsDataType = 'pimsDataType',
  PimsDisplayName = 'pimsDisplayName',
  PimsId = 'pimsId',
  PimsName = 'pimsName',
  PimsSourceId = 'pimsSourceId'
}

export type CustomFieldDefinitionOrderByRelevanceInput = {
  fields: Array<CustomFieldDefinitionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CustomFieldDefinitionOrderByWithAggregationInput = {
  _count?: Maybe<CustomFieldDefinitionCountOrderByAggregateInput>;
  _max?: Maybe<CustomFieldDefinitionMaxOrderByAggregateInput>;
  _min?: Maybe<CustomFieldDefinitionMinOrderByAggregateInput>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CustomFieldDefinitionOrderByRelevanceInput>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueOrderByRelationAggregateInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueOrderByRelationAggregateInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  clinicPimsIntegrationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  designation?: Maybe<SortOrder>;
  existsInPims?: Maybe<SortOrder>;
  flowAlias?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSensitiveData?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  normalizedType?: Maybe<SortOrder>;
  pimsCreated?: Maybe<SortOrder>;
  pimsDataType?: Maybe<SortOrder>;
  pimsDeleted?: Maybe<SortOrder>;
  pimsDisplayName?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsIsDeleted?: Maybe<SortOrder>;
  pimsIsInactive?: Maybe<SortOrder>;
  pimsIsRequired?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  pimsSourceId?: Maybe<SortOrder>;
  pimsSyncType?: Maybe<SortOrder>;
  pimsUpdated?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  source_of_truth?: Maybe<SortOrder>;
  targetType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryOrderByRelationAggregateInput>;
};

export type CustomFieldDefinitionPimsIdClinicPimsIntegrationIdCompoundUniqueInput = {
  clinicPimsIntegrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type CustomFieldDefinitionRelationFilter = {
  is?: Maybe<CustomFieldDefinitionWhereInput>;
  isNot?: Maybe<CustomFieldDefinitionWhereInput>;
};

export enum CustomFieldDefinitionScalarFieldEnum {
  ClinicPimsIntegrationId = 'clinicPimsIntegrationId',
  CreatedAt = 'createdAt',
  Designation = 'designation',
  ExistsInPims = 'existsInPims',
  FlowAlias = 'flowAlias',
  Id = 'id',
  IsSensitiveData = 'isSensitiveData',
  LastSyncedAt = 'lastSyncedAt',
  NormalizedType = 'normalizedType',
  PimsCreated = 'pimsCreated',
  PimsDataType = 'pimsDataType',
  PimsDeleted = 'pimsDeleted',
  PimsDisplayName = 'pimsDisplayName',
  PimsId = 'pimsId',
  PimsIsDeleted = 'pimsIsDeleted',
  PimsIsInactive = 'pimsIsInactive',
  PimsIsRequired = 'pimsIsRequired',
  PimsName = 'pimsName',
  PimsSourceId = 'pimsSourceId',
  PimsSyncType = 'pimsSyncType',
  PimsUpdated = 'pimsUpdated',
  RawPimsValue = 'rawPimsValue',
  SourceOfTruth = 'source_of_truth',
  TargetType = 'targetType',
  UpdatedAt = 'updatedAt'
}

export type CustomFieldDefinitionScalarWhereInput = {
  AND?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  clinicPimsIntegrationId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  designation?: Maybe<EnumCustomFieldDesignationFilter>;
  existsInPims?: Maybe<BoolFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isSensitiveData?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeFilter>;
  pimsCreated?: Maybe<DateTimeNullableFilter>;
  pimsDataType?: Maybe<StringNullableFilter>;
  pimsDeleted?: Maybe<DateTimeNullableFilter>;
  pimsDisplayName?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsIsDeleted?: Maybe<BoolNullableFilter>;
  pimsIsInactive?: Maybe<BoolNullableFilter>;
  pimsIsRequired?: Maybe<BoolNullableFilter>;
  pimsName?: Maybe<StringNullableFilter>;
  pimsSourceId?: Maybe<StringNullableFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeFilter>;
  pimsUpdated?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CustomFieldDefinitionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionScalarWhereWithAggregatesInput>>;
  clinicPimsIntegrationId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  designation?: Maybe<EnumCustomFieldDesignationWithAggregatesFilter>;
  existsInPims?: Maybe<BoolWithAggregatesFilter>;
  flowAlias?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isSensitiveData?: Maybe<BoolNullableWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  pimsCreated?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsDataType?: Maybe<StringNullableWithAggregatesFilter>;
  pimsDeleted?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsDisplayName?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsIsDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsIsInactive?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsIsRequired?: Maybe<BoolNullableWithAggregatesFilter>;
  pimsName?: Maybe<StringNullableWithAggregatesFilter>;
  pimsSourceId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  pimsUpdated?: Maybe<DateTimeNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type CustomFieldDefinitionUncheckedCreateInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegrationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateNestedManyWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput = {
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegrationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegrationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput>;
};

export type CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedCreateNestedManyWithoutFieldDefinitionInput>;
  clinicPimsIntegrationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims: Scalars['Boolean'];
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType: CustomFieldNormalizedDataType;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType: CustomFieldPimsSyncType;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType: CustomFieldTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUncheckedUpdateInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateManyInput = {
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUncheckedUpdateManyWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  delete?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  updateMany?: Maybe<Array<CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput>>;
  upsert?: Maybe<Array<CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
};

export type CustomFieldDefinitionUncheckedUpdateManyWithoutCustomFieldDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput = {
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUncheckedUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegrationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUpdateInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput = {
  data: CustomFieldDefinitionUncheckedUpdateManyWithoutCustomFieldDefinitionsInput;
  where: CustomFieldDefinitionScalarWhereInput;
};

export type CustomFieldDefinitionUpdateManyWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldDefinitionCreateOrConnectWithoutClinicPimsIntegrationInput>>;
  create?: Maybe<Array<CustomFieldDefinitionCreateWithoutClinicPimsIntegrationInput>>;
  createMany?: Maybe<CustomFieldDefinitionCreateManyClinicPimsIntegrationInputEnvelope>;
  delete?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomFieldDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<CustomFieldDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
  updateMany?: Maybe<Array<CustomFieldDefinitionUpdateManyWithWhereWithoutClinicPimsIntegrationInput>>;
  upsert?: Maybe<Array<CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput>>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetFieldValuesNestedInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetFieldValuesInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutClinicPetFieldValuesInput>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutClinicPetParentFieldValuesNestedInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutClinicPetParentFieldValuesInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutClinicPetParentFieldValuesInput>;
};

export type CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput = {
  connect?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldDefinitionCreateOrConnectWithoutWritebackLogsInput>;
  create?: Maybe<CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput>;
  update?: Maybe<CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput>;
  upsert?: Maybe<CustomFieldDefinitionUpsertWithoutWritebackLogsInput>;
};

export type CustomFieldDefinitionUpdateWithWhereUniqueWithoutClinicPimsIntegrationInput = {
  data: CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionUpdateWithoutClinicPetFieldValuesInput = {
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutClinicPetParentFieldValuesInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutClinicPimsIntegrationInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput>;
};

export type CustomFieldDefinitionUpdateWithoutWritebackLogsInput = {
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueUpdateManyWithoutFieldDefinitionNestedInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutCustomFieldDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  designation?: Maybe<CustomFieldDesignation>;
  existsInPims?: Maybe<Scalars['Boolean']>;
  flowAlias?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSensitiveData?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  normalizedType?: Maybe<CustomFieldNormalizedDataType>;
  pimsCreated?: Maybe<Scalars['DateTime']>;
  pimsDataType?: Maybe<Scalars['String']>;
  pimsDeleted?: Maybe<Scalars['DateTime']>;
  pimsDisplayName?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsIsDeleted?: Maybe<Scalars['Boolean']>;
  pimsIsInactive?: Maybe<Scalars['Boolean']>;
  pimsIsRequired?: Maybe<Scalars['Boolean']>;
  pimsName?: Maybe<Scalars['String']>;
  pimsSourceId?: Maybe<Scalars['String']>;
  pimsSyncType?: Maybe<CustomFieldPimsSyncType>;
  pimsUpdated?: Maybe<Scalars['DateTime']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  source_of_truth?: Maybe<CustomFieldsDataSource>;
  targetType?: Maybe<CustomFieldTargetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomFieldDefinitionUpsertWithWhereUniqueWithoutClinicPimsIntegrationInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPimsIntegrationInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};

export type CustomFieldDefinitionUpsertWithoutClinicPetFieldValuesInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetFieldValuesInput;
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPetFieldValuesInput;
};

export type CustomFieldDefinitionUpsertWithoutClinicPetParentFieldValuesInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutClinicPetParentFieldValuesInput;
  update: CustomFieldDefinitionUncheckedUpdateWithoutClinicPetParentFieldValuesInput;
};

export type CustomFieldDefinitionUpsertWithoutWritebackLogsInput = {
  create: CustomFieldDefinitionUncheckedCreateWithoutWritebackLogsInput;
  update: CustomFieldDefinitionUncheckedUpdateWithoutWritebackLogsInput;
};

export type CustomFieldDefinitionWhereInput = {
  AND?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  NOT?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  OR?: Maybe<Array<CustomFieldDefinitionWhereInput>>;
  clinicPetFieldValues?: Maybe<ClinicPetCustomFieldValueListRelationFilter>;
  clinicPetParentFieldValues?: Maybe<ClinicPetParentCustomFieldValueListRelationFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  clinicPimsIntegrationId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  designation?: Maybe<EnumCustomFieldDesignationFilter>;
  existsInPims?: Maybe<BoolFilter>;
  flowAlias?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isSensitiveData?: Maybe<BoolNullableFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  normalizedType?: Maybe<EnumCustomFieldNormalizedDataTypeFilter>;
  pimsCreated?: Maybe<DateTimeNullableFilter>;
  pimsDataType?: Maybe<StringNullableFilter>;
  pimsDeleted?: Maybe<DateTimeNullableFilter>;
  pimsDisplayName?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  pimsIsDeleted?: Maybe<BoolNullableFilter>;
  pimsIsInactive?: Maybe<BoolNullableFilter>;
  pimsIsRequired?: Maybe<BoolNullableFilter>;
  pimsName?: Maybe<StringNullableFilter>;
  pimsSourceId?: Maybe<StringNullableFilter>;
  pimsSyncType?: Maybe<EnumCustomFieldPimsSyncTypeFilter>;
  pimsUpdated?: Maybe<DateTimeNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  source_of_truth?: Maybe<EnumCustomFieldsDataSourceNullableFilter>;
  targetType?: Maybe<EnumCustomFieldTargetTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  writebackLogs?: Maybe<CustomFieldPimsWritebackLogEntryListRelationFilter>;
};

export type CustomFieldDefinitionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_clinicPimsIntegrationId?: Maybe<CustomFieldDefinitionPimsIdClinicPimsIntegrationIdCompoundUniqueInput>;
};

export enum CustomFieldDesignation {
  ClinicPetParentCareSubscriptionStatus = 'ClinicPetParent_CareSubscriptionStatus',
  ClinicPetCarePlanName = 'ClinicPet_CarePlanName',
  ClinicPetCareSubscriptionStatus = 'ClinicPet_CareSubscriptionStatus',
  Unspecified = 'Unspecified'
}

export enum CustomFieldNormalizedDataType {
  Boolean = 'Boolean',
  Number = 'Number',
  String = 'String',
  Unknown = 'Unknown'
}

export enum CustomFieldPimsSyncType {
  NoSync = 'NoSync',
  ReadOnly = 'ReadOnly',
  ReadWrite = 'ReadWrite',
  WriteOnly = 'WriteOnly'
}

export type CustomFieldPimsWritebackLogEntry = {
  __typename?: 'CustomFieldPimsWritebackLogEntry';
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition: CustomFieldDefinition;
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLog: PimsWritebackLogEntry;
  writebackLogId: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  customEntityId: Scalars['Int'];
  customFieldDefinitionId: Scalars['Int'];
  customFieldValue: Scalars['Int'];
  writebackLogId: Scalars['Int'];
};

export type CustomFieldPimsWritebackLogEntryCountOrderByAggregateInput = {
  customEntityId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryCreateInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput;
};

export type CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope = {
  data: Array<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CustomFieldPimsWritebackLogEntryCreateManyInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryCreateNestedManyWithoutCustomFieldDefinitionInput = {
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
};

export type CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput = {
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};

export type CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};

export type CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput;
};

export type CustomFieldPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition: CustomFieldDefinitionCreateNestedOneWithoutWritebackLogsInput;
  customFieldValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  none?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  some?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export type CustomFieldPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryMaxAggregateOutputType';
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryMaxOrderByAggregateInput = {
  customEntityId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'CustomFieldPimsWritebackLogEntryMinAggregateOutputType';
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryMinOrderByAggregateInput = {
  customEntityId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum CustomFieldPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  CustomEntityId = 'customEntityId',
  CustomFieldDefinitionId = 'customFieldDefinitionId',
  WritebackLogId = 'writebackLogId'
}

export type CustomFieldPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<CustomFieldPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type CustomFieldPimsWritebackLogEntryOrderByWithAggregationInput = {
  _count?: Maybe<CustomFieldPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<CustomFieldPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<CustomFieldPimsWritebackLogEntryMinOrderByAggregateInput>;
  customEntityId?: Maybe<SortOrder>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<CustomFieldPimsWritebackLogEntryOrderByRelevanceInput>;
  customEntityId?: Maybe<SortOrder>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  customFieldDefinitionId?: Maybe<SortOrder>;
  customFieldValue?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  writebackLogId?: Maybe<SortOrder>;
};

export type CustomFieldPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};

export enum CustomFieldPimsWritebackLogEntryScalarFieldEnum {
  CustomEntityId = 'customEntityId',
  CustomFieldDefinitionId = 'customFieldDefinitionId',
  CustomFieldValue = 'customFieldValue',
  WritebackLogId = 'writebackLogId'
}

export type CustomFieldPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  customEntityId?: Maybe<StringNullableFilter>;
  customFieldDefinitionId?: Maybe<StringFilter>;
  customFieldValue?: Maybe<JsonNullableFilter>;
  writebackLogId?: Maybe<StringFilter>;
};

export type CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  customEntityId?: Maybe<StringNullableWithAggregatesFilter>;
  customFieldDefinitionId?: Maybe<StringWithAggregatesFilter>;
  customFieldValue?: Maybe<JsonNullableWithAggregatesFilter>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateNestedManyWithoutCustomFieldDefinitionInput = {
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId: Scalars['String'];
};

export type CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId: Scalars['String'];
  customFieldValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutCustomFieldDefinitionNestedInput = {
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  updateMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput>>;
  upsert?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogsInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinitionId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldPimsWritebackLogEntryUpdateInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyMutationInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput = {
  data: CustomFieldPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogsInput;
  where: CustomFieldPimsWritebackLogEntryScalarWhereInput;
};

export type CustomFieldPimsWritebackLogEntryUpdateManyWithoutCustomFieldDefinitionNestedInput = {
  connect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutCustomFieldDefinitionInput>>;
  create?: Maybe<Array<CustomFieldPimsWritebackLogEntryCreateWithoutCustomFieldDefinitionInput>>;
  createMany?: Maybe<CustomFieldPimsWritebackLogEntryCreateManyCustomFieldDefinitionInputEnvelope>;
  delete?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
  updateMany?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpdateManyWithWhereWithoutCustomFieldDefinitionInput>>;
  upsert?: Maybe<Array<CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput>>;
};

export type CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<CustomFieldPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithWhereUniqueWithoutCustomFieldDefinitionInput = {
  data: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithoutCustomFieldDefinitionInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldValue?: Maybe<Scalars['Json']>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput>;
};

export type CustomFieldPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  customEntityId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionUpdateOneRequiredWithoutWritebackLogsNestedInput>;
  customFieldValue?: Maybe<Scalars['Json']>;
};

export type CustomFieldPimsWritebackLogEntryUpsertWithWhereUniqueWithoutCustomFieldDefinitionInput = {
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutCustomFieldDefinitionInput;
  update: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldDefinitionInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};

export type CustomFieldPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  create: CustomFieldPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  update: CustomFieldPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
};

export type CustomFieldPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<CustomFieldPimsWritebackLogEntryWhereInput>>;
  customEntityId?: Maybe<StringNullableFilter>;
  customFieldDefinition?: Maybe<CustomFieldDefinitionWhereInput>;
  customFieldDefinitionId?: Maybe<StringFilter>;
  customFieldValue?: Maybe<JsonNullableFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  writebackLogId?: Maybe<StringFilter>;
};

export type CustomFieldPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_customFieldDefinitionId?: Maybe<CustomFieldPimsWritebackLogEntryWritebackLogIdCustomFieldDefinitionIdCompoundUniqueInput>;
};

export type CustomFieldPimsWritebackLogEntryWritebackLogIdCustomFieldDefinitionIdCompoundUniqueInput = {
  customFieldDefinitionId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export enum CustomFieldTargetType {
  ClinicPet = 'ClinicPet',
  ClinicPetParent = 'ClinicPetParent',
  Unknown = 'Unknown'
}

export enum CustomFieldsDataSource {
  Flow = 'Flow',
  Pims = 'Pims'
}

export type CustomFindUniqueUserBoardFilterResponse = {
  __typename?: 'CustomFindUniqueUserBoardFilterResponse';
  collapsedColumnsMap?: Maybe<Scalars['Json']>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  selectedChannelStatusIdsMap?: Maybe<Scalars['Json']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<DateRangeOutput>;
};

export type CustomFindUniqueUserBoardFilterWhereInput = {
  userId: Scalars['String'];
};

export type CustomUpsertOneUserBoardFilterDataInput = {
  collapsedColumnsMap?: Maybe<Scalars['Json']>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  selectedChannelStatusIdsMap?: Maybe<Scalars['Json']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<DateRangeInput>;
};

export type CustomUpsertOneUserBoardFilterResponse = {
  __typename?: 'CustomUpsertOneUserBoardFilterResponse';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CustomUpsertOneUserBoardFilterWhereInput = {
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export type DashboardSummary = {
  __typename?: 'DashboardSummary';
  Arn?: Maybe<Scalars['String']>;
  CreatedTime?: Maybe<Scalars['String']>;
  DashboardId?: Maybe<Scalars['String']>;
  LastPublishedTime?: Maybe<Scalars['String']>;
  LastUpdatedTime?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PublishedVersionNumber?: Maybe<Scalars['Int']>;
};

export type DataPointIntegration = {
  __typename?: 'DataPointIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isMultiLocation: Scalars['Boolean'];
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type DataPointIntegrationCountAggregateOutputType = {
  __typename?: 'DataPointIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  fixAppointmentTime: Scalars['Int'];
  id: Scalars['Int'];
  isMultiLocation: Scalars['Int'];
  password: Scalars['Int'];
  siteId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
};

export type DataPointIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type DataPointIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutDatapointInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type DataPointIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type DataPointIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: DataPointIntegrationWhereUniqueInput;
};

export type DataPointIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type DataPointIntegrationMaxAggregateOutputType = {
  __typename?: 'DataPointIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type DataPointIntegrationMinAggregateOutputType = {
  __typename?: 'DataPointIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export enum DataPointIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  Password = 'password',
  SiteId = 'siteId',
  Username = 'username'
}

export type DataPointIntegrationOrderByRelevanceInput = {
  fields: Array<DataPointIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DataPointIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<DataPointIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<DataPointIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<DataPointIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type DataPointIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DataPointIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  fixAppointmentTime?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMultiLocation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type DataPointIntegrationRelationFilter = {
  is?: Maybe<DataPointIntegrationWhereInput>;
  isNot?: Maybe<DataPointIntegrationWhereInput>;
};

export enum DataPointIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  FixAppointmentTime = 'fixAppointmentTime',
  Id = 'id',
  IsMultiLocation = 'isMultiLocation',
  Password = 'password',
  SiteId = 'siteId',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type DataPointIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DataPointIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  fixAppointmentTime?: Maybe<BoolNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isMultiLocation?: Maybe<BoolWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  siteId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
};

export type DataPointIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutDatapointInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  siteId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type DataPointIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutDatapointNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutDatapointNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<DataPointIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<DataPointIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type DataPointIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fixAppointmentTime?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isMultiLocation?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type DataPointIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: DataPointIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: DataPointIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type DataPointIntegrationWhereInput = {
  AND?: Maybe<Array<DataPointIntegrationWhereInput>>;
  NOT?: Maybe<Array<DataPointIntegrationWhereInput>>;
  OR?: Maybe<Array<DataPointIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  fixAppointmentTime?: Maybe<BoolNullableFilter>;
  id?: Maybe<StringFilter>;
  isMultiLocation?: Maybe<BoolFilter>;
  password?: Maybe<StringFilter>;
  siteId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
};

export type DataPointIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type DateRangeInput = {
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};

export type DateRangeOutput = {
  __typename?: 'DateRangeOutput';
  gte: Scalars['DateTime'];
  lte: Scalars['DateTime'];
};


export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableListFilter = {
  equals?: Maybe<Array<Scalars['DateTime']>>;
  has?: Maybe<Scalars['DateTime']>;
  hasEvery?: Maybe<Array<Scalars['DateTime']>>;
  hasSome?: Maybe<Array<Scalars['DateTime']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DeleteEmailCampaignCustomListInput = {
  emailCampaignId: Scalars['String'];
};

export enum DeviceOs {
  Android = 'ANDROID',
  Ios = 'IOS',
  Ipados = 'IPADOS',
  Iphoneos = 'IPHONEOS'
}

export enum DeviceType {
  Desktop = 'DESKTOP',
  Gamingconsole = 'GAMINGCONSOLE',
  Handset = 'HANDSET',
  Tablet = 'TABLET',
  Tv = 'TV',
  Unknown = 'UNKNOWN'
}

export type DirectBookingAppointmentResponse = {
  __typename?: 'DirectBookingAppointmentResponse';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export enum DirectBookingAppointmentTypeClientType {
  Both = 'Both',
  ExistingClients = 'ExistingClients',
  NewClients = 'NewClients'
}

export type DirectBookingAppointmentTypeInput = {
  clinicId: Scalars['String'];
};

export type DirectBookingAppointmentTypeName = {
  name: Scalars['String'];
};

export type DirectBookingAppointmentTypeSetting = {
  __typename?: 'DirectBookingAppointmentTypeSetting';
  allowNewClientBooking: Scalars['Boolean'];
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['String'];
  clientType: DirectBookingAppointmentTypeClientType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isPaymentCollectionEnabled: Scalars['Boolean'];
  paymentAmountToCollect: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type DirectBookingAppointmentTypeSettingAvgAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingAvgAggregateOutputType';
  paymentAmountToCollect?: Maybe<Scalars['Float']>;
};

export type DirectBookingAppointmentTypeSettingAvgOrderByAggregateInput = {
  paymentAmountToCollect?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  allowNewClientBooking: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  clientType: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isPaymentCollectionEnabled: Scalars['Int'];
  paymentAmountToCollect: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type DirectBookingAppointmentTypeSettingCountOrderByAggregateInput = {
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingCreateInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutDirectBookingAppointmentTypeSettingInput;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingCreateManyInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId: Scalars['String'];
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingCreateNestedOneWithoutAppointmentTypeInput = {
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  create: DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};

export type DirectBookingAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingMaxAggregateOutputType';
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingMaxOrderByAggregateInput = {
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingMinAggregateOutputType';
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingMinOrderByAggregateInput = {
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum DirectBookingAppointmentTypeSettingOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  Id = 'id'
}

export type DirectBookingAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<DirectBookingAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DirectBookingAppointmentTypeSettingOrderByWithAggregationInput = {
  _avg?: Maybe<DirectBookingAppointmentTypeSettingAvgOrderByAggregateInput>;
  _count?: Maybe<DirectBookingAppointmentTypeSettingCountOrderByAggregateInput>;
  _max?: Maybe<DirectBookingAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingAppointmentTypeSettingMinOrderByAggregateInput>;
  _sum?: Maybe<DirectBookingAppointmentTypeSettingSumOrderByAggregateInput>;
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DirectBookingAppointmentTypeSettingOrderByRelevanceInput>;
  allowNewClientBooking?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  clientType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isPaymentCollectionEnabled?: Maybe<SortOrder>;
  paymentAmountToCollect?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingRelationFilter = {
  is?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
  isNot?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};

export enum DirectBookingAppointmentTypeSettingScalarFieldEnum {
  AllowNewClientBooking = 'allowNewClientBooking',
  AppointmentTypeId = 'appointmentTypeId',
  ClientType = 'clientType',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsPaymentCollectionEnabled = 'isPaymentCollectionEnabled',
  PaymentAmountToCollect = 'paymentAmountToCollect',
  UpdatedAt = 'updatedAt'
}

export type DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  allowNewClientBooking?: Maybe<BoolWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  clientType?: Maybe<EnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isPaymentCollectionEnabled?: Maybe<BoolWithAggregatesFilter>;
  paymentAmountToCollect?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type DirectBookingAppointmentTypeSettingSelection = {
  __typename?: 'DirectBookingAppointmentTypeSettingSelection';
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingAppointmentTypeSettingSumAggregateOutputType = {
  __typename?: 'DirectBookingAppointmentTypeSettingSumAggregateOutputType';
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
};

export type DirectBookingAppointmentTypeSettingSumOrderByAggregateInput = {
  paymentAmountToCollect?: Maybe<SortOrder>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId: Scalars['String'];
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateNestedOneWithoutAppointmentTypeInput = {
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateManyInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateOneWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput>;
  upsert?: Maybe<DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUpdateInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutDirectBookingAppointmentTypeSettingNestedInput>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUpdateManyMutationInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUpdateOneWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput>;
  upsert?: Maybe<DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput>;
};

export type DirectBookingAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  allowNewClientBooking?: Maybe<Scalars['Boolean']>;
  clientType?: Maybe<DirectBookingAppointmentTypeClientType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPaymentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  paymentAmountToCollect?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingAppointmentTypeSettingUpsertWithoutAppointmentTypeInput = {
  create: DirectBookingAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  update: DirectBookingAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
};

export type DirectBookingAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<DirectBookingAppointmentTypeSettingWhereInput>>;
  allowNewClientBooking?: Maybe<BoolFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringFilter>;
  clientType?: Maybe<EnumDirectBookingAppointmentTypeClientTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isPaymentCollectionEnabled?: Maybe<BoolFilter>;
  paymentAmountToCollect?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DirectBookingAppointmentTypeSettingWhereUniqueInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingAppointmentTypes = {
  __typename?: 'DirectBookingAppointmentTypes';
  clinicEmployeeAppointmentTypeSettings: Array<AppointmentTypeClinicEmployeeMapping>;
  defaultDurationInMinutes?: Maybe<Scalars['Int']>;
  defaultSoonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
  delayedStartTime?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  directBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSettingSelection>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DirectBookingAvailability = {
  __typename?: 'DirectBookingAvailability';
  bookingDate?: Maybe<Scalars['DateTime']>;
  hasAvailability?: Maybe<Scalars['Boolean']>;
  openTimeSlots?: Maybe<Scalars['Json']>;
};

export type DirectBookingClinicEmployeeName = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type DirectBookingClinicRoomName = {
  name: Scalars['String'];
};

export type DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameInput = {
  appointmentType: DirectBookingAppointmentTypeName;
  clinicEmployee: DirectBookingClinicEmployeeName;
  clinicId: Scalars['String'];
  clinicRoom: DirectBookingClinicRoomName;
};

export type DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse = {
  __typename?: 'DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DirectBookingDateAvailability = {
  __typename?: 'DirectBookingDateAvailability';
  bookingDate: Scalars['String'];
  hasAvailability: Scalars['Boolean'];
  openTimeSlots: Array<Scalars['DateTime']>;
};

export type DirectBookingExclusionRule = {
  __typename?: 'DirectBookingExclusionRule';
  _count: DirectBookingExclusionRuleCountOutputType;
  appointmentTypes: Array<AppointmentType>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  providers: Array<ClinicEmployee>;
  timeSlots: Array<DirectBookingExclusionTimeSlot>;
  updatedAt: Scalars['DateTime'];
};


export type DirectBookingExclusionRuleAppointmentTypesArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type DirectBookingExclusionRuleProvidersArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type DirectBookingExclusionRuleTimeSlotsArgs = {
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  distinct?: Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>;
  orderBy?: Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};

export type DirectBookingExclusionRuleCountAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type DirectBookingExclusionRuleCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleCountOutputType = {
  __typename?: 'DirectBookingExclusionRuleCountOutputType';
  appointmentTypes: Scalars['Int'];
  providers: Scalars['Int'];
  timeSlots: Scalars['Int'];
};

export type DirectBookingExclusionRuleCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateManyClinicInputEnvelope = {
  data: Array<DirectBookingExclusionRuleCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionRuleCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
};

export type DirectBookingExclusionRuleCreateNestedManyWithoutProvidersInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
};

export type DirectBookingExclusionRuleCreateNestedOneWithoutTimeSlotsInput = {
  connect?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput>;
  create?: Maybe<DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput>;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput = {
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateWithoutProvidersInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleCreateWithoutTimeSlotsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinic: ClinicCreateNestedOneWithoutDirectBookingExclusionRulesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleListRelationFilter = {
  every?: Maybe<DirectBookingExclusionRuleWhereInput>;
  none?: Maybe<DirectBookingExclusionRuleWhereInput>;
  some?: Maybe<DirectBookingExclusionRuleWhereInput>;
};

export type DirectBookingExclusionRuleMaxAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleMinAggregateOutputType = {
  __typename?: 'DirectBookingExclusionRuleMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum DirectBookingExclusionRuleOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  Name = 'name'
}

export type DirectBookingExclusionRuleOrderByRelevanceInput = {
  fields: Array<DirectBookingExclusionRuleOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DirectBookingExclusionRuleOrderByWithAggregationInput = {
  _count?: Maybe<DirectBookingExclusionRuleCountOrderByAggregateInput>;
  _max?: Maybe<DirectBookingExclusionRuleMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingExclusionRuleMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DirectBookingExclusionRuleOrderByRelevanceInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  providers?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionRuleRelationFilter = {
  is?: Maybe<DirectBookingExclusionRuleWhereInput>;
  isNot?: Maybe<DirectBookingExclusionRuleWhereInput>;
};

export enum DirectBookingExclusionRuleScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type DirectBookingExclusionRuleScalarWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DirectBookingExclusionRuleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type DirectBookingExclusionRuleUncheckedCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
};

export type DirectBookingExclusionRuleUncheckedCreateNestedManyWithoutProvidersInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  providers?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutDirectBookingExclusionRulesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateManyWithoutProvidersNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput>>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
  where: DirectBookingExclusionRuleScalarWhereInput;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
  where: DirectBookingExclusionRuleScalarWhereInput;
};

export type DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateManyWithoutDirectBookingExclusionRulesInput;
  where: DirectBookingExclusionRuleScalarWhereInput;
};

export type DirectBookingExclusionRuleUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type DirectBookingExclusionRuleUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutClinicInput>>;
  createMany?: Maybe<DirectBookingExclusionRuleCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type DirectBookingExclusionRuleUpdateManyWithoutProvidersNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionRuleCreateOrConnectWithoutProvidersInput>>;
  create?: Maybe<Array<DirectBookingExclusionRuleCreateWithoutProvidersInput>>;
  delete?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionRuleWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionRuleUpdateManyWithWhereWithoutProvidersInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput>>;
};

export type DirectBookingExclusionRuleUpdateOneRequiredWithoutTimeSlotsNestedInput = {
  connect?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  connectOrCreate?: Maybe<DirectBookingExclusionRuleCreateOrConnectWithoutTimeSlotsInput>;
  create?: Maybe<DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput>;
  update?: Maybe<DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput>;
  upsert?: Maybe<DirectBookingExclusionRuleUpsertWithoutTimeSlotsInput>;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutClinicInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpdateWithWhereUniqueWithoutProvidersInput = {
  data: DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpdateWithoutAppointmentTypesInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateWithoutProvidersInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpdateWithoutTimeSlotsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutDirectBookingExclusionRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  providers?: Maybe<ClinicEmployeeUpdateManyWithoutDirectBookingExclusionRulesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutAppointmentTypesInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutAppointmentTypesInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutClinicInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutClinicInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutClinicInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpsertWithWhereUniqueWithoutProvidersInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutProvidersInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutProvidersInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};

export type DirectBookingExclusionRuleUpsertWithoutTimeSlotsInput = {
  create: DirectBookingExclusionRuleUncheckedCreateWithoutTimeSlotsInput;
  update: DirectBookingExclusionRuleUncheckedUpdateWithoutTimeSlotsInput;
};

export type DirectBookingExclusionRuleWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionRuleWhereInput>>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  providers?: Maybe<ClinicEmployeeListRelationFilter>;
  timeSlots?: Maybe<DirectBookingExclusionTimeSlotListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DirectBookingExclusionRuleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingExclusionTimeSlot = {
  __typename?: 'DirectBookingExclusionTimeSlot';
  createdAt: Scalars['DateTime'];
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  exclusionRule: DirectBookingExclusionRule;
  exclusionRuleId: Scalars['String'];
  id: Scalars['String'];
  startTime: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DirectBookingExclusionTimeSlotAvgAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotAvgAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Float']>;
};

export type DirectBookingExclusionTimeSlotAvgOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotCountAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['Int'];
  exclusionRuleId: Scalars['Int'];
  id: Scalars['Int'];
  startTime: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type DirectBookingExclusionTimeSlotCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  exclusionRule: DirectBookingExclusionRuleCreateNestedOneWithoutTimeSlotsInput;
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotCreateManyExclusionRuleInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope = {
  data: Array<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DirectBookingExclusionTimeSlotCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  exclusionRuleId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotCreateNestedManyWithoutExclusionRuleInput = {
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
};

export type DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput = {
  create: DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};

export type DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotListRelationFilter = {
  every?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  none?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
  some?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};

export type DirectBookingExclusionTimeSlotMaxAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotMinAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum DirectBookingExclusionTimeSlotOrderByRelevanceFieldEnum {
  EndTime = 'endTime',
  ExclusionRuleId = 'exclusionRuleId',
  Id = 'id',
  StartTime = 'startTime'
}

export type DirectBookingExclusionTimeSlotOrderByRelevanceInput = {
  fields: Array<DirectBookingExclusionTimeSlotOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DirectBookingExclusionTimeSlotOrderByWithAggregationInput = {
  _avg?: Maybe<DirectBookingExclusionTimeSlotAvgOrderByAggregateInput>;
  _count?: Maybe<DirectBookingExclusionTimeSlotCountOrderByAggregateInput>;
  _max?: Maybe<DirectBookingExclusionTimeSlotMaxOrderByAggregateInput>;
  _min?: Maybe<DirectBookingExclusionTimeSlotMinOrderByAggregateInput>;
  _sum?: Maybe<DirectBookingExclusionTimeSlotSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRuleId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DirectBookingExclusionTimeSlotOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  dayOfWeek?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>;
  exclusionRuleId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum DirectBookingExclusionTimeSlotScalarFieldEnum {
  CreatedAt = 'createdAt',
  DayOfWeek = 'dayOfWeek',
  EndTime = 'endTime',
  ExclusionRuleId = 'exclusionRuleId',
  Id = 'id',
  StartTime = 'startTime',
  UpdatedAt = 'updatedAt'
}

export type DirectBookingExclusionTimeSlotScalarWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  endTime?: Maybe<StringFilter>;
  exclusionRuleId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  startTime?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dayOfWeek?: Maybe<IntWithAggregatesFilter>;
  endTime?: Maybe<StringWithAggregatesFilter>;
  exclusionRuleId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  startTime?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type DirectBookingExclusionTimeSlotSumAggregateOutputType = {
  __typename?: 'DirectBookingExclusionTimeSlotSumAggregateOutputType';
  dayOfWeek?: Maybe<Scalars['Int']>;
};

export type DirectBookingExclusionTimeSlotSumOrderByAggregateInput = {
  dayOfWeek?: Maybe<SortOrder>;
};

export type DirectBookingExclusionTimeSlotUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  exclusionRuleId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUncheckedCreateNestedManyWithoutExclusionRuleInput = {
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
};

export type DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRuleId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutExclusionRuleNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  delete?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput>>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutTimeSlotsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleUpdateOneRequiredWithoutTimeSlotsNestedInput>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput = {
  data: DirectBookingExclusionTimeSlotUncheckedUpdateManyWithoutTimeSlotsInput;
  where: DirectBookingExclusionTimeSlotScalarWhereInput;
};

export type DirectBookingExclusionTimeSlotUpdateManyWithoutExclusionRuleNestedInput = {
  connect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DirectBookingExclusionTimeSlotCreateOrConnectWithoutExclusionRuleInput>>;
  create?: Maybe<Array<DirectBookingExclusionTimeSlotCreateWithoutExclusionRuleInput>>;
  createMany?: Maybe<DirectBookingExclusionTimeSlotCreateManyExclusionRuleInputEnvelope>;
  delete?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DirectBookingExclusionTimeSlotScalarWhereInput>>;
  disconnect?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  set?: Maybe<Array<DirectBookingExclusionTimeSlotWhereUniqueInput>>;
  update?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput>>;
  updateMany?: Maybe<Array<DirectBookingExclusionTimeSlotUpdateManyWithWhereWithoutExclusionRuleInput>>;
  upsert?: Maybe<Array<DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput>>;
};

export type DirectBookingExclusionTimeSlotUpdateWithWhereUniqueWithoutExclusionRuleInput = {
  data: DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};

export type DirectBookingExclusionTimeSlotUpdateWithoutExclusionRuleInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectBookingExclusionTimeSlotUpsertWithWhereUniqueWithoutExclusionRuleInput = {
  create: DirectBookingExclusionTimeSlotUncheckedCreateWithoutExclusionRuleInput;
  update: DirectBookingExclusionTimeSlotUncheckedUpdateWithoutExclusionRuleInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};

export type DirectBookingExclusionTimeSlotWhereInput = {
  AND?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  NOT?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  OR?: Maybe<Array<DirectBookingExclusionTimeSlotWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  dayOfWeek?: Maybe<IntFilter>;
  endTime?: Maybe<StringFilter>;
  exclusionRule?: Maybe<DirectBookingExclusionRuleWhereInput>;
  exclusionRuleId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  startTime?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DirectBookingExclusionTimeSlotWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlot2Availability = {
  __typename?: 'DirectBookingOpenTimeSlot2Availability';
  availability: Array<DirectBookingDateAvailability>;
  clinicEmployeeFirstName?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  clinicEmployeeLastName?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlot2Input = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clinicId: Scalars['String'];
  dates: Array<Maybe<DirectBookingOpenTimeSlotRange>>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  petParentTimezone?: Maybe<Scalars['String']>;
  showFirstAvailabilityDayOnly?: Maybe<Scalars['Boolean']>;
  soonestAvailableBookingInHours?: Maybe<Scalars['Int']>;
};

export type DirectBookingOpenTimeSlot2Response = {
  __typename?: 'DirectBookingOpenTimeSlot2Response';
  availableTimeSlots?: Maybe<Array<DirectBookingOpenTimeSlot2Availability>>;
  usedTimezone?: Maybe<Scalars['String']>;
};

export type DirectBookingOpenTimeSlotRange = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export enum DirectBookingSpeciesOption {
  NotUsed = 'NotUsed',
  Used = 'Used'
}

export type DirectedAuthLinkInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  loginType?: Maybe<Scalars['String']>;
  organizationPlanId?: Maybe<Scalars['String']>;
};

export type DirectedAuthLinkResult = {
  __typename?: 'DirectedAuthLinkResult';
  success: Scalars['Boolean'];
};

/** @deprecated - 1.0 */
export type Disbursement = {
  __typename?: 'Disbursement';
  _count: DisbursementCountOutputType;
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id: Scalars['String'];
  source: Scalars['String'];
  transactions: Array<Transaction>;
  updatedAt: Scalars['DateTime'];
};


/** @deprecated - 1.0 */
export type DisbursementTransactionsArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<TransactionScalarFieldEnum>;
  orderBy?: Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};

export type DisbursementAvgAggregateOutputType = {
  __typename?: 'DisbursementAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementCountAggregateOutputType = {
  __typename?: 'DisbursementCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  disbursementId: Scalars['Int'];
  id: Scalars['Int'];
  source: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type DisbursementCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DisbursementCountOutputType = {
  __typename?: 'DisbursementCountOutputType';
  transactions: Scalars['Int'];
};

export type DisbursementCreateInput = {
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutDisbursementInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementCreateManyInput = {
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementCreateNestedOneWithoutTransactionsInput = {
  connect?: Maybe<DisbursementWhereUniqueInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
};

export type DisbursementCreateOrConnectWithoutTransactionsInput = {
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
  where: DisbursementWhereUniqueInput;
};

export type DisbursementCreateWithoutTransactionsInput = {
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementMaxAggregateOutputType = {
  __typename?: 'DisbursementMaxAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DisbursementMinAggregateOutputType = {
  __typename?: 'DisbursementMinAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum DisbursementOrderByRelevanceFieldEnum {
  DisbursementId = 'disbursementId',
  Id = 'id',
  Source = 'source'
}

export type DisbursementOrderByRelevanceInput = {
  fields: Array<DisbursementOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DisbursementOrderByWithAggregationInput = {
  _avg?: Maybe<DisbursementAvgOrderByAggregateInput>;
  _count?: Maybe<DisbursementCountOrderByAggregateInput>;
  _max?: Maybe<DisbursementMaxOrderByAggregateInput>;
  _min?: Maybe<DisbursementMinOrderByAggregateInput>;
  _sum?: Maybe<DisbursementSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DisbursementOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DisbursementOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  transactions?: Maybe<TransactionOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type DisbursementRelationFilter = {
  is?: Maybe<DisbursementWhereInput>;
  isNot?: Maybe<DisbursementWhereInput>;
};

export enum DisbursementScalarFieldEnum {
  Amount = 'amount',
  DisbursementDate = 'disbursementDate',
  DisbursementId = 'disbursementId',
  Id = 'id',
  Source = 'source',
  UpdatedAt = 'updatedAt'
}

export type DisbursementScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DisbursementScalarWhereWithAggregatesInput>>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  disbursementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  disbursementId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type DisbursementSumAggregateOutputType = {
  __typename?: 'DisbursementSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type DisbursementSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type DisbursementUncheckedCreateInput = {
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedCreateNestedManyWithoutDisbursementInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUncheckedCreateWithoutTransactionsInput = {
  amount: Scalars['Float'];
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUncheckedUpdateManyWithoutDisbursementNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUncheckedUpdateWithoutTransactionsInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionUpdateManyWithoutDisbursementNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUpdateOneWithoutTransactionsNestedInput = {
  connect?: Maybe<DisbursementWhereUniqueInput>;
  connectOrCreate?: Maybe<DisbursementCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<DisbursementUncheckedCreateWithoutTransactionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DisbursementUncheckedUpdateWithoutTransactionsInput>;
  upsert?: Maybe<DisbursementUpsertWithoutTransactionsInput>;
};

export type DisbursementUpdateWithoutTransactionsInput = {
  amount?: Maybe<Scalars['Float']>;
  disbursementDate?: Maybe<Scalars['DateTime']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DisbursementUpsertWithoutTransactionsInput = {
  create: DisbursementUncheckedCreateWithoutTransactionsInput;
  update: DisbursementUncheckedUpdateWithoutTransactionsInput;
};

export type DisbursementWhereInput = {
  AND?: Maybe<Array<DisbursementWhereInput>>;
  NOT?: Maybe<Array<DisbursementWhereInput>>;
  OR?: Maybe<Array<DisbursementWhereInput>>;
  amount?: Maybe<FloatFilter>;
  disbursementDate?: Maybe<DateTimeNullableFilter>;
  disbursementId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  source?: Maybe<StringFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DisbursementWhereUniqueInput = {
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Documentation = {
  __typename?: 'Documentation';
  category: DocumentationCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  provider: DocumentationProvider;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum DocumentationCategory {
  Birds = 'Birds',
  Cats = 'Cats',
  Dogs = 'Dogs',
  GeneralInformation = 'GeneralInformation',
  Horses = 'Horses',
  Medications = 'Medications',
  Pigs = 'Pigs',
  Reptiles = 'Reptiles',
  Ruminants = 'Ruminants',
  SmallMammals = 'SmallMammals'
}

export type DocumentationCountAggregateOutputType = {
  __typename?: 'DocumentationCountAggregateOutputType';
  _all: Scalars['Int'];
  category: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  provider: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
};

export type DocumentationCountOrderByAggregateInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type DocumentationCreateInput = {
  category: DocumentationCategory;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider: DocumentationProvider;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type DocumentationCreateManyInput = {
  category: DocumentationCategory;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider: DocumentationProvider;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type DocumentationMaxAggregateOutputType = {
  __typename?: 'DocumentationMaxAggregateOutputType';
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationMaxOrderByAggregateInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type DocumentationMinAggregateOutputType = {
  __typename?: 'DocumentationMinAggregateOutputType';
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationMinOrderByAggregateInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum DocumentationOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  Url = 'url'
}

export type DocumentationOrderByRelevanceInput = {
  fields: Array<DocumentationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type DocumentationOrderByWithAggregationInput = {
  _count?: Maybe<DocumentationCountOrderByAggregateInput>;
  _max?: Maybe<DocumentationMaxOrderByAggregateInput>;
  _min?: Maybe<DocumentationMinOrderByAggregateInput>;
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type DocumentationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<DocumentationOrderByRelevanceInput>;
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum DocumentationProvider {
  VeterinaryPartner = 'VeterinaryPartner'
}

export enum DocumentationScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  Id = 'id',
  Provider = 'provider',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type DocumentationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<DocumentationScalarWhereWithAggregatesInput>>;
  category?: Maybe<EnumDocumentationCategoryWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  provider?: Maybe<EnumDocumentationProviderWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
};

export type DocumentationUncheckedCreateInput = {
  category: DocumentationCategory;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider: DocumentationProvider;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type DocumentationUncheckedUpdateInput = {
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUncheckedUpdateManyInput = {
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUpdateInput = {
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationUpdateManyMutationInput = {
  category?: Maybe<DocumentationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<DocumentationProvider>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentationWhereInput = {
  AND?: Maybe<Array<DocumentationWhereInput>>;
  NOT?: Maybe<Array<DocumentationWhereInput>>;
  OR?: Maybe<Array<DocumentationWhereInput>>;
  category?: Maybe<EnumDocumentationCategoryFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumDocumentationProviderFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
};

export type DocumentationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DuplicateEmailCampaignInput = {
  emailCampaignId: Scalars['String'];
  userId: Scalars['String'];
};

export type EmailCampaign = {
  __typename?: 'EmailCampaign';
  _count: EmailCampaignCountOutputType;
  clinic: Clinic;
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomList>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs: Array<EmailCampaignLog>;
  emailCampaignUnsubscribes: Array<EmailCampaignUnsubscribe>;
  from: Scalars['String'];
  htmlContent?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaignExecution?: Maybe<InformCampaignExecution>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  lastUpdatedByUser?: Maybe<User>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType: EmailCampaignSegmentationType;
  sentByUser?: Maybe<User>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status: EmailCampaignStatus;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type: EmailCampaignType;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
  uploads: Array<EmailCampaignCustomListUploadHistory>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type EmailCampaignEmailCampaignLogsArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignLogScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type EmailCampaignEmailCampaignUnsubscribesArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignUnsubscribeScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type EmailCampaignUploadsArgs = {
  cursor?: Maybe<EmailCampaignCustomListUploadHistoryWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignCustomListUploadHistoryScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};

export type EmailCampaignAvgAggregateOutputType = {
  __typename?: 'EmailCampaignAvgAggregateOutputType';
  totalSegmentContacts?: Maybe<Scalars['Float']>;
};

export type EmailCampaignAvgOrderByAggregateInput = {
  totalSegmentContacts?: Maybe<SortOrder>;
};

export type EmailCampaignCountAggregateOutputType = {
  __typename?: 'EmailCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  completedAt: Scalars['Int'];
  contexts: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  deletedAt: Scalars['Int'];
  from: Scalars['Int'];
  htmlContent: Scalars['Int'];
  id: Scalars['Int'];
  informCampaignExecutionId: Scalars['Int'];
  latUpdatedByUserId: Scalars['Int'];
  name: Scalars['Int'];
  replyTo: Scalars['Int'];
  replyToJson: Scalars['Int'];
  scheduledAt: Scalars['Int'];
  segmentArgs: Scalars['Int'];
  segmentWhere: Scalars['Int'];
  segmentationDefinition: Scalars['Int'];
  segmentationType: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  startedAt: Scalars['Int'];
  startsAt: Scalars['Int'];
  status: Scalars['Int'];
  subject: Scalars['Int'];
  totalSegmentContacts: Scalars['Int'];
  type: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type EmailCampaignCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCampaignCountOutputType = {
  __typename?: 'EmailCampaignCountOutputType';
  emailCampaignLogs: Scalars['Int'];
  emailCampaignUnsubscribes: Scalars['Int'];
  uploads: Scalars['Int'];
};

export type EmailCampaignCreateInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  typedSegmentArgs?: Maybe<SegmentsArgsInput>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateManyClinicInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyCreatedByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManyCreatedByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyInformCampaignExecutionInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManyInformCampaignExecutionInputEnvelope = {
  data: Array<EmailCampaignCreateManyInformCampaignExecutionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManyLastUpdatedByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManyLastUpdatedByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyLastUpdatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManySentByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCreateManySentByUserInputEnvelope = {
  data: Array<EmailCampaignCreateManySentByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateManyUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCreateManyUserInputEnvelope = {
  data: Array<EmailCampaignCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
};

export type EmailCampaignCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
};

export type EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
};

export type EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
};

export type EmailCampaignCreateNestedManyWithoutSentByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
};

export type EmailCampaignCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
};

export type EmailCampaignCreateNestedOneWithoutCustomListInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutCustomListInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutCustomListInput>;
};

export type EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput>;
};

export type EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput>;
};

export type EmailCampaignCreateNestedOneWithoutUploadsInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutUploadsInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutUploadsInput>;
};

export type EmailCampaignCreateOrConnectWithoutClinicInput = {
  create: EmailCampaignUncheckedCreateWithoutClinicInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutCreatedByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutCreatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutCustomListInput = {
  create: EmailCampaignUncheckedCreateWithoutCustomListInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput = {
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput = {
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput = {
  create: EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutSentByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutSentByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutUploadsInput = {
  create: EmailCampaignUncheckedCreateWithoutUploadsInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateOrConnectWithoutUserInput = {
  create: EmailCampaignUncheckedCreateWithoutUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignCreateWithoutClinicInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutCreatedByUserInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutCustomListInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutEmailCampaignLogsInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutEmailCampaignUnsubscribesInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutInformCampaignExecutionInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutLastUpdatedByUserInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutSentByUserInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutUploadsInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutEmailCampaignInput>;
};

export type EmailCampaignCreateWithoutUserInput = {
  clinic: ClinicCreateNestedOneWithoutEmailCampaignInput;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsCreatedInput>;
  customList?: Maybe<EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput>;
  lastUpdatedByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignUpdatedInput>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserCreateNestedOneWithoutEmailCampaignsSentInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomList = {
  __typename?: 'EmailCampaignCustomList';
  _count: EmailCampaignCustomListCountOutputType;
  awsFileKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  emailCampaign: EmailCampaign;
  emailCampaignId: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  invalidRecipients: Scalars['Int'];
  recipients: Array<EmailCampaignCustomListRecipient>;
  updatedAt: Scalars['DateTime'];
  validRecipients: Scalars['Int'];
};


export type EmailCampaignCustomListRecipientsArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};

export type EmailCampaignCustomListAvgAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListAvgAggregateOutputType';
  invalidRecipients?: Maybe<Scalars['Float']>;
  validRecipients?: Maybe<Scalars['Float']>;
};

export type EmailCampaignCustomListAvgOrderByAggregateInput = {
  invalidRecipients?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListCountAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListCountAggregateOutputType';
  _all: Scalars['Int'];
  awsFileKey: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  fileName: Scalars['Int'];
  id: Scalars['Int'];
  invalidRecipients: Scalars['Int'];
  updatedAt: Scalars['Int'];
  validRecipients: Scalars['Int'];
};

export type EmailCampaignCustomListCountOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListCountOutputType = {
  __typename?: 'EmailCampaignCustomListCountOutputType';
  recipients: Scalars['Int'];
};

export type EmailCampaignCustomListCreateInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutCustomListInput;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListCreateManyInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListCreateNestedOneWithoutEmailCampaignInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput>;
};

export type EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput = {
  create: EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput;
  where: EmailCampaignCustomListWhereUniqueInput;
};

export type EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput = {
  create: EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput;
  where: EmailCampaignCustomListWhereUniqueInput;
};

export type EmailCampaignCustomListCreateWithoutEmailCampaignInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListCreateWithoutRecipientsInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutCustomListInput;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListMaxAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListMaxAggregateOutputType';
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListMaxOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListMinAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListMinAggregateOutputType';
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListMinOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListOrderByRelevanceFieldEnum {
  AwsFileKey = 'awsFileKey',
  EmailCampaignId = 'emailCampaignId',
  FileName = 'fileName',
  Id = 'id'
}

export type EmailCampaignCustomListOrderByRelevanceInput = {
  fields: Array<EmailCampaignCustomListOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignCustomListOrderByWithAggregationInput = {
  _avg?: Maybe<EmailCampaignCustomListAvgOrderByAggregateInput>;
  _count?: Maybe<EmailCampaignCustomListCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignCustomListMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignCustomListMinOrderByAggregateInput>;
  _sum?: Maybe<EmailCampaignCustomListSumOrderByAggregateInput>;
  awsFileKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignCustomListOrderByRelevanceInput>;
  awsFileKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignId?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invalidRecipients?: Maybe<SortOrder>;
  recipients?: Maybe<EmailCampaignCustomListRecipientOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipient = {
  __typename?: 'EmailCampaignCustomListRecipient';
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  emailCampaignCustomList: EmailCampaignCustomList;
  emailCampaignCustomListId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status: EmailCampaignCustomListRecipientStatus;
  updatedAt: Scalars['DateTime'];
};

export type EmailCampaignCustomListRecipientCountAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  emailCampaignCustomListId: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  lastName: Scalars['Int'];
  reason: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailCampaignCustomListRecipientCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientCreateInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomList: EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomListId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignCustomListRecipientCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope = {
  data: Array<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCustomListRecipientCreateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomListId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignCustomListRecipientCreateNestedManyWithoutEmailCampaignCustomListInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
};

export type EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput = {
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput = {
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomList: EmailCampaignCustomListCreateNestedOneWithoutRecipientsInput;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignCustomListRecipientsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientListRelationFilter = {
  every?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  none?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
  some?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};

export type EmailCampaignCustomListRecipientMaxAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientMinAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListRecipientMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListRecipientOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Email = 'email',
  EmailCampaignCustomListId = 'emailCampaignCustomListId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Reason = 'reason'
}

export type EmailCampaignCustomListRecipientOrderByRelevanceInput = {
  fields: Array<EmailCampaignCustomListRecipientOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignCustomListRecipientOrderByWithAggregationInput = {
  _count?: Maybe<EmailCampaignCustomListRecipientCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignCustomListRecipientMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignCustomListRecipientMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignCustomListRecipientOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignCustomListId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListRecipientScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Email = 'email',
  EmailCampaignCustomListId = 'emailCampaignCustomListId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Reason = 'reason',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type EmailCampaignCustomListRecipientScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  emailCampaignCustomListId?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  emailCampaignCustomListId?: Maybe<StringWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum EmailCampaignCustomListRecipientStatus {
  Invalid = 'Invalid',
  Pending = 'Pending',
  Valid = 'Valid'
}

export type EmailCampaignCustomListRecipientUncheckedCreateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomListId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailCampaignCustomListId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListRecipientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutRecipientsInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomListId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListRecipientsInput;
  where: EmailCampaignCustomListRecipientScalarWhereInput;
};

export type EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput = {
  data: EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutRecipientsInput;
  where: EmailCampaignCustomListRecipientScalarWhereInput;
};

export type EmailCampaignCustomListRecipientUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListRecipientCreateOrConnectWithoutEmailCampaignCustomListInput>>;
  create?: Maybe<Array<EmailCampaignCustomListRecipientCreateWithoutEmailCampaignCustomListInput>>;
  createMany?: Maybe<EmailCampaignCustomListRecipientCreateManyEmailCampaignCustomListInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListRecipientWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListRecipientUpdateManyWithWhereWithoutEmailCampaignCustomListInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput>>;
};

export type EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientUpdateWithWhereUniqueWithoutEmailCampaignCustomListInput = {
  data: EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUpdateWithoutEmailCampaignCustomListInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignCustomListRecipientsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<EmailCampaignCustomListRecipientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutClinicPetParentInput;
  update: EmailCampaignCustomListRecipientUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientUpsertWithWhereUniqueWithoutEmailCampaignCustomListInput = {
  create: EmailCampaignCustomListRecipientUncheckedCreateWithoutEmailCampaignCustomListInput;
  update: EmailCampaignCustomListRecipientUncheckedUpdateWithoutEmailCampaignCustomListInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};

export type EmailCampaignCustomListRecipientWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListRecipientWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  emailCampaignCustomList?: Maybe<EmailCampaignCustomListWhereInput>;
  emailCampaignCustomListId?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumEmailCampaignCustomListRecipientStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignCustomListRecipientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListRelationFilter = {
  is?: Maybe<EmailCampaignCustomListWhereInput>;
  isNot?: Maybe<EmailCampaignCustomListWhereInput>;
};

export type EmailCampaignCustomListResponse = {
  __typename?: 'EmailCampaignCustomListResponse';
  customList?: Maybe<EmailCampaignCustomList>;
  customListHistory?: Maybe<EmailCampaignCustomListUploadHistory>;
};

export enum EmailCampaignCustomListScalarFieldEnum {
  AwsFileKey = 'awsFileKey',
  CreatedAt = 'createdAt',
  EmailCampaignId = 'emailCampaignId',
  FileName = 'fileName',
  Id = 'id',
  InvalidRecipients = 'invalidRecipients',
  UpdatedAt = 'updatedAt',
  ValidRecipients = 'validRecipients'
}

export type EmailCampaignCustomListScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListScalarWhereWithAggregatesInput>>;
  awsFileKey?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringWithAggregatesFilter>;
  fileName?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invalidRecipients?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  validRecipients?: Maybe<IntWithAggregatesFilter>;
};

export type EmailCampaignCustomListSumAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListSumAggregateOutputType';
  invalidRecipients?: Maybe<Scalars['Int']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListSumOrderByAggregateInput = {
  invalidRecipients?: Maybe<SortOrder>;
  validRecipients?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUncheckedCreateInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedCreateNestedManyWithoutEmailCampaignCustomListInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput = {
  awsFileKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateManyInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUncheckedUpdateManyWithoutEmailCampaignCustomListNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateManyMutationInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateOneRequiredWithoutRecipientsNestedInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutRecipientsInput>;
};

export type EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput = {
  connect?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCustomListCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput>;
  upsert?: Maybe<EmailCampaignCustomListUpsertWithoutEmailCampaignInput>;
};

export type EmailCampaignCustomListUpdateWithoutEmailCampaignInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  recipients?: Maybe<EmailCampaignCustomListRecipientUpdateManyWithoutEmailCampaignCustomListNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUpdateWithoutRecipientsInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidRecipients?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validRecipients?: Maybe<Scalars['Int']>;
};

export type EmailCampaignCustomListUploadHistory = {
  __typename?: 'EmailCampaignCustomListUploadHistory';
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  emailCampaign: EmailCampaign;
  emailCampaignId: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EmailCampaignCustomListUploadHistoryCountAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListUploadHistoryCountAggregateOutputType';
  _all: Scalars['Int'];
  awsFileKey: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  expiresAt: Scalars['Int'];
  fileName: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailCampaignCustomListUploadHistoryCountOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUploadHistoryCreateInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutUploadsInput;
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInputEnvelope = {
  data: Array<EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignCustomListUploadHistoryCreateManyInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignCustomListUploadHistoryCreateOrConnectWithoutEmailCampaignInput = {
  create: EmailCampaignCustomListUploadHistoryUncheckedCreateWithoutEmailCampaignInput;
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};

export type EmailCampaignCustomListUploadHistoryCreateWithoutEmailCampaignInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryListRelationFilter = {
  every?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
  none?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
  some?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};

export type EmailCampaignCustomListUploadHistoryMaxAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListUploadHistoryMaxAggregateOutputType';
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryMaxOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUploadHistoryMinAggregateOutputType = {
  __typename?: 'EmailCampaignCustomListUploadHistoryMinAggregateOutputType';
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryMinOrderByAggregateInput = {
  awsFileKey?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUploadHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListUploadHistoryOrderByRelevanceFieldEnum {
  AwsFileKey = 'awsFileKey',
  ClinicId = 'clinicId',
  EmailCampaignId = 'emailCampaignId',
  FileName = 'fileName',
  Id = 'id'
}

export type EmailCampaignCustomListUploadHistoryOrderByRelevanceInput = {
  fields: Array<EmailCampaignCustomListUploadHistoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignCustomListUploadHistoryOrderByWithAggregationInput = {
  _count?: Maybe<EmailCampaignCustomListUploadHistoryCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignCustomListUploadHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignCustomListUploadHistoryMinOrderByAggregateInput>;
  awsFileKey?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignCustomListUploadHistoryOrderByRelevanceInput>;
  awsFileKey?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignId?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  fileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmailCampaignCustomListUploadHistoryScalarFieldEnum {
  AwsFileKey = 'awsFileKey',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  EmailCampaignId = 'emailCampaignId',
  ExpiresAt = 'expiresAt',
  FileName = 'fileName',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type EmailCampaignCustomListUploadHistoryScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereInput>>;
  awsFileKey?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaignId?: Maybe<StringFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  fileName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignCustomListUploadHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereWithAggregatesInput>>;
  awsFileKey?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  fileName?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedCreateInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedCreateWithoutEmailCampaignInput = {
  awsFileKey: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedUpdateInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedUpdateManyInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutUploadsInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUncheckedUpdateWithoutEmailCampaignInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUpdateInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutUploadsNestedInput>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUpdateManyMutationInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUpdateManyWithWhereWithoutEmailCampaignInput = {
  data: EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutUploadsInput;
  where: EmailCampaignCustomListUploadHistoryScalarWhereInput;
};

export type EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignCustomListUploadHistoryCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignCustomListUploadHistoryCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignCustomListUploadHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignCustomListUploadHistoryUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignCustomListUploadHistoryUpdateWithWhereUniqueWithoutEmailCampaignInput = {
  data: EmailCampaignCustomListUploadHistoryUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};

export type EmailCampaignCustomListUploadHistoryUpdateWithoutEmailCampaignInput = {
  awsFileKey?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignCustomListUploadHistoryUpsertWithWhereUniqueWithoutEmailCampaignInput = {
  create: EmailCampaignCustomListUploadHistoryUncheckedCreateWithoutEmailCampaignInput;
  update: EmailCampaignCustomListUploadHistoryUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};

export type EmailCampaignCustomListUploadHistoryWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListUploadHistoryWhereInput>>;
  awsFileKey?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  emailCampaignId?: Maybe<StringFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  fileName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignCustomListUploadHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListUpsertWithoutEmailCampaignInput = {
  create: EmailCampaignCustomListUncheckedCreateWithoutEmailCampaignInput;
  update: EmailCampaignCustomListUncheckedUpdateWithoutEmailCampaignInput;
};

export type EmailCampaignCustomListUpsertWithoutRecipientsInput = {
  create: EmailCampaignCustomListUncheckedCreateWithoutRecipientsInput;
  update: EmailCampaignCustomListUncheckedUpdateWithoutRecipientsInput;
};

export type EmailCampaignCustomListUrlInput = {
  emailCampaignId?: Maybe<Scalars['String']>;
  historicalUploadId?: Maybe<Scalars['String']>;
};

export type EmailCampaignCustomListWhereInput = {
  AND?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  OR?: Maybe<Array<EmailCampaignCustomListWhereInput>>;
  awsFileKey?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  emailCampaignId?: Maybe<StringFilter>;
  fileName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invalidRecipients?: Maybe<IntFilter>;
  recipients?: Maybe<EmailCampaignCustomListRecipientListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  validRecipients?: Maybe<IntFilter>;
};

export type EmailCampaignCustomListWhereUniqueInput = {
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignListRelationFilter = {
  every?: Maybe<EmailCampaignWhereInput>;
  none?: Maybe<EmailCampaignWhereInput>;
  some?: Maybe<EmailCampaignWhereInput>;
};

export type EmailCampaignLog = {
  __typename?: 'EmailCampaignLog';
  action: EmailCampaignLogAction;
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  emailCampaign: EmailCampaign;
  emailCampaignId: Scalars['String'];
  emailCampaignType: EmailCampaignType;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  provider: EmailProvider;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  variant?: Maybe<Scalars['String']>;
};

export enum EmailCampaignLogAction {
  Error = 'Error',
  Sent = 'Sent',
  Skipped = 'Skipped'
}

export type EmailCampaignLogCountAggregateOutputType = {
  __typename?: 'EmailCampaignLogCountAggregateOutputType';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  emailCampaignType: Scalars['Int'];
  error: Scalars['Int'];
  id: Scalars['Int'];
  provider: Scalars['Int'];
  providerTransactionId: Scalars['Int'];
  skipReason: Scalars['Int'];
  targetEmail: Scalars['Int'];
  updatedAt: Scalars['Int'];
  variant: Scalars['Int'];
};

export type EmailCampaignLogCountOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogCreateInput = {
  action: EmailCampaignLogAction;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyClinicInput = {
  action: EmailCampaignLogAction;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyClinicPetParentInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyEmailCampaignInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateManyEmailCampaignInputEnvelope = {
  data: Array<EmailCampaignLogCreateManyEmailCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignLogCreateManyInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
};

export type EmailCampaignLogCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignLogCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignLogCreateOrConnectWithoutClinicInput = {
  create: EmailCampaignLogUncheckedCreateWithoutClinicInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput = {
  create: EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput = {
  create: EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogCreateWithoutClinicInput = {
  action: EmailCampaignLogAction;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateWithoutClinicPetParentInput = {
  action: EmailCampaignLogAction;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign: EmailCampaignCreateNestedOneWithoutEmailCampaignLogsInput;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogCreateWithoutEmailCampaignInput = {
  action: EmailCampaignLogAction;
  clinic: ClinicCreateNestedOneWithoutEmailCampaignLogInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailCampaignLogInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogListRelationFilter = {
  every?: Maybe<EmailCampaignLogWhereInput>;
  none?: Maybe<EmailCampaignLogWhereInput>;
  some?: Maybe<EmailCampaignLogWhereInput>;
};

export type EmailCampaignLogMaxAggregateOutputType = {
  __typename?: 'EmailCampaignLogMaxAggregateOutputType';
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogMaxOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogMinAggregateOutputType = {
  __typename?: 'EmailCampaignLogMinAggregateOutputType';
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogMinOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignLogOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  EmailCampaignId = 'emailCampaignId',
  Error = 'error',
  Id = 'id',
  ProviderTransactionId = 'providerTransactionId',
  TargetEmail = 'targetEmail',
  Variant = 'variant'
}

export type EmailCampaignLogOrderByRelevanceInput = {
  fields: Array<EmailCampaignLogOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignLogOrderByWithAggregationInput = {
  _count?: Maybe<EmailCampaignLogCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignLogMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignLogMinOrderByAggregateInput>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export type EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignLogOrderByRelevanceInput>;
  action?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignId?: Maybe<SortOrder>;
  emailCampaignType?: Maybe<SortOrder>;
  error?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerTransactionId?: Maybe<SortOrder>;
  skipReason?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variant?: Maybe<SortOrder>;
};

export enum EmailCampaignLogScalarFieldEnum {
  Action = 'action',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  EmailCampaignId = 'emailCampaignId',
  EmailCampaignType = 'emailCampaignType',
  Error = 'error',
  Id = 'id',
  Provider = 'provider',
  ProviderTransactionId = 'providerTransactionId',
  SkipReason = 'skipReason',
  TargetEmail = 'targetEmail',
  UpdatedAt = 'updatedAt',
  Variant = 'variant'
}

export type EmailCampaignLogScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  action?: Maybe<EnumEmailCampaignLogActionFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaignId?: Maybe<StringFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeFilter>;
  error?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerTransactionId?: Maybe<StringNullableFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableFilter>;
  targetEmail?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  variant?: Maybe<StringNullableFilter>;
};

export type EmailCampaignLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignLogScalarWhereWithAggregatesInput>>;
  action?: Maybe<EnumEmailCampaignLogActionWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringWithAggregatesFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeWithAggregatesFilter>;
  error?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  provider?: Maybe<EnumEmailProviderWithAggregatesFilter>;
  providerTransactionId?: Maybe<StringNullableWithAggregatesFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  targetEmail?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  variant?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum EmailCampaignLogSkipReason {
  InvalidEmail = 'InvalidEmail',
  Suppressed = 'Suppressed',
  Unsubscribed = 'Unsubscribed'
}

export type EmailCampaignLogUncheckedCreateInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignLogUncheckedCreateWithoutClinicInput = {
  action: EmailCampaignLogAction;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId: Scalars['String'];
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput = {
  action: EmailCampaignLogAction;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogsInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignLogUncheckedUpdateWithoutClinicInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateManyMutationInput = {
  action?: Maybe<EmailCampaignLogAction>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutClinicInput = {
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput;
  where: EmailCampaignLogScalarWhereInput;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogInput;
  where: EmailCampaignLogScalarWhereInput;
};

export type EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput = {
  data: EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignLogsInput;
  where: EmailCampaignLogScalarWhereInput;
};

export type EmailCampaignLogUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignLogUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignLogCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignLogCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignLogCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignLogUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutClinicInput = {
  data: EmailCampaignLogUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogUpdateWithWhereUniqueWithoutEmailCampaignInput = {
  data: EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogUpdateWithoutClinicInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateWithoutClinicPetParentInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpdateWithoutEmailCampaignInput = {
  action?: Maybe<EmailCampaignLogAction>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailCampaignLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignType?: Maybe<EmailCampaignType>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerTransactionId?: Maybe<Scalars['String']>;
  skipReason?: Maybe<EmailCampaignLogSkipReason>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variant?: Maybe<Scalars['String']>;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutClinicInput = {
  create: EmailCampaignLogUncheckedCreateWithoutClinicInput;
  update: EmailCampaignLogUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: EmailCampaignLogUncheckedCreateWithoutClinicPetParentInput;
  update: EmailCampaignLogUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogUpsertWithWhereUniqueWithoutEmailCampaignInput = {
  create: EmailCampaignLogUncheckedCreateWithoutEmailCampaignInput;
  update: EmailCampaignLogUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignLogWhereUniqueInput;
};

export type EmailCampaignLogWhereInput = {
  AND?: Maybe<Array<EmailCampaignLogWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignLogWhereInput>>;
  OR?: Maybe<Array<EmailCampaignLogWhereInput>>;
  action?: Maybe<EnumEmailCampaignLogActionFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  emailCampaignId?: Maybe<StringFilter>;
  emailCampaignType?: Maybe<EnumEmailCampaignTypeFilter>;
  error?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerTransactionId?: Maybe<StringNullableFilter>;
  skipReason?: Maybe<EnumEmailCampaignLogSkipReasonNullableFilter>;
  targetEmail?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  variant?: Maybe<StringNullableFilter>;
};

export type EmailCampaignLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignMaxAggregateOutputType = {
  __typename?: 'EmailCampaignMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCampaignMinAggregateOutputType = {
  __typename?: 'EmailCampaignMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  CreatedByUserId = 'createdByUserId',
  From = 'from',
  HtmlContent = 'htmlContent',
  Id = 'id',
  InformCampaignExecutionId = 'informCampaignExecutionId',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  ReplyTo = 'replyTo',
  SentByUserId = 'sentByUserId',
  Subject = 'subject',
  UserId = 'userId'
}

export type EmailCampaignOrderByRelevanceInput = {
  fields: Array<EmailCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignOrderByWithAggregationInput = {
  _avg?: Maybe<EmailCampaignAvgOrderByAggregateInput>;
  _count?: Maybe<EmailCampaignCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignMinOrderByAggregateInput>;
  _sum?: Maybe<EmailCampaignSumOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  from?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmailCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  contexts?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdByUserId?: Maybe<SortOrder>;
  customList?: Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>;
  deletedAt?: Maybe<SortOrder>;
  emailCampaignLogs?: Maybe<EmailCampaignLogOrderByRelationAggregateInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeOrderByRelationAggregateInput>;
  from?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignExecution?: Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignExecutionId?: Maybe<SortOrder>;
  lastUpdatedByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  replyTo?: Maybe<SortOrder>;
  replyToJson?: Maybe<SortOrder>;
  scheduledAt?: Maybe<SortOrder>;
  segmentArgs?: Maybe<SortOrder>;
  segmentWhere?: Maybe<SortOrder>;
  segmentationDefinition?: Maybe<SortOrder>;
  segmentationType?: Maybe<SortOrder>;
  sentByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sentByUserId?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  totalSegmentContacts?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryOrderByRelationAggregateInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export type EmailCampaignRelationFilter = {
  is?: Maybe<EmailCampaignWhereInput>;
  isNot?: Maybe<EmailCampaignWhereInput>;
};

export enum EmailCampaignScalarFieldEnum {
  ClinicId = 'clinicId',
  CompletedAt = 'completedAt',
  Contexts = 'contexts',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  DeletedAt = 'deletedAt',
  From = 'from',
  HtmlContent = 'htmlContent',
  Id = 'id',
  InformCampaignExecutionId = 'informCampaignExecutionId',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  ReplyTo = 'replyTo',
  ReplyToJson = 'replyToJson',
  ScheduledAt = 'scheduledAt',
  SegmentArgs = 'segmentArgs',
  SegmentWhere = 'segmentWhere',
  SegmentationDefinition = 'segmentationDefinition',
  SegmentationType = 'segmentationType',
  SentByUserId = 'sentByUserId',
  StartedAt = 'startedAt',
  StartsAt = 'startsAt',
  Status = 'status',
  Subject = 'subject',
  TotalSegmentContacts = 'totalSegmentContacts',
  Type = 'type',
  UnlayerJson = 'unlayerJson',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type EmailCampaignScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  contexts?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  from?: Maybe<StringFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaignExecutionId?: Maybe<StringNullableFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  replyTo?: Maybe<StringNullableFilter>;
  replyToJson?: Maybe<JsonNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  segmentArgs?: Maybe<JsonNullableFilter>;
  segmentWhere?: Maybe<JsonNullableFilter>;
  segmentationDefinition?: Maybe<JsonNullableFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  startsAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEmailCampaignStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  totalSegmentContacts?: Maybe<IntNullableFilter>;
  type?: Maybe<EnumEmailCampaignTypeFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type EmailCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  contexts?: Maybe<JsonNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  from?: Maybe<StringWithAggregatesFilter>;
  htmlContent?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informCampaignExecutionId?: Maybe<StringNullableWithAggregatesFilter>;
  latUpdatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  replyTo?: Maybe<StringNullableWithAggregatesFilter>;
  replyToJson?: Maybe<JsonNullableWithAggregatesFilter>;
  scheduledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  segmentArgs?: Maybe<JsonNullableWithAggregatesFilter>;
  segmentWhere?: Maybe<JsonNullableWithAggregatesFilter>;
  segmentationDefinition?: Maybe<JsonNullableWithAggregatesFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  startedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumEmailCampaignStatusWithAggregatesFilter>;
  subject?: Maybe<StringNullableWithAggregatesFilter>;
  totalSegmentContacts?: Maybe<IntNullableWithAggregatesFilter>;
  type?: Maybe<EnumEmailCampaignTypeWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum EmailCampaignSegmentationType {
  AllActiveClients = 'AllActiveClients',
  ClinicPetParentPrismaWhere = 'ClinicPetParentPrismaWhere',
  ClinicPetParentRawSql = 'ClinicPetParentRawSql',
  CustomList = 'CustomList'
}

export enum EmailCampaignStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Sending = 'Sending',
  Sent = 'Sent'
}

export type EmailCampaignSumAggregateOutputType = {
  __typename?: 'EmailCampaignSumAggregateOutputType';
  totalSegmentContacts?: Maybe<Scalars['Int']>;
};

export type EmailCampaignSumOrderByAggregateInput = {
  totalSegmentContacts?: Maybe<SortOrder>;
};

export type EmailCampaignTemplate = {
  __typename?: 'EmailCampaignTemplate';
  User?: Maybe<User>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id: Scalars['String'];
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};

export type EmailCampaignTemplateCountAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  htmlContent: Scalars['Int'];
  id: Scalars['Int'];
  latUpdatedByUserId: Scalars['Int'];
  name: Scalars['Int'];
  organizationId: Scalars['Int'];
  templateAvailability: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailCampaignTemplateCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateCreateInput = {
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateManyOrganizationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateManyOrganizationInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateManyUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateManyUserInputEnvelope = {
  data: Array<EmailCampaignTemplateCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
};

export type EmailCampaignTemplateCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
};

export type EmailCampaignTemplateCreateOrConnectWithoutClinicInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutClinicInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateCreateOrConnectWithoutUserInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutUserInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateCreateWithoutClinicInput = {
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateWithoutOrganizationInput = {
  User?: Maybe<UserCreateNestedOneWithoutEmailCampaignTemplateInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateCreateWithoutUserInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignTemplateInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateListRelationFilter = {
  every?: Maybe<EmailCampaignTemplateWhereInput>;
  none?: Maybe<EmailCampaignTemplateWhereInput>;
  some?: Maybe<EmailCampaignTemplateWhereInput>;
};

export type EmailCampaignTemplateMaxAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateMinAggregateOutputType = {
  __typename?: 'EmailCampaignTemplateMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignTemplateOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  HtmlContent = 'htmlContent',
  Id = 'id',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  OrganizationId = 'organizationId'
}

export type EmailCampaignTemplateOrderByRelevanceInput = {
  fields: Array<EmailCampaignTemplateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignTemplateOrderByWithAggregationInput = {
  _count?: Maybe<EmailCampaignTemplateCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignTemplateMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput = {
  User?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<EmailCampaignTemplateOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latUpdatedByUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  templateAvailability?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmailCampaignTemplateScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  HtmlContent = 'htmlContent',
  Id = 'id',
  LatUpdatedByUserId = 'latUpdatedByUserId',
  Name = 'name',
  OrganizationId = 'organizationId',
  TemplateAvailability = 'templateAvailability',
  UnlayerJson = 'unlayerJson',
  UpdatedAt = 'updatedAt'
}

export type EmailCampaignTemplateScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  htmlContent?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  htmlContent?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  latUpdatedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  organizationId?: Maybe<StringNullableWithAggregatesFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EmailCampaignTemplateUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
};

export type EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedCreateWithoutUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability: TemplateAvailability;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUncheckedUpdateWithoutUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpdateInput = {
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput = {
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
  where: EmailCampaignTemplateScalarWhereInput;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput = {
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
  where: EmailCampaignTemplateScalarWhereInput;
};

export type EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput = {
  data: EmailCampaignTemplateUncheckedUpdateManyWithoutEmailCampaignTemplateInput;
  where: EmailCampaignTemplateScalarWhereInput;
};

export type EmailCampaignTemplateUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutOrganizationInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type EmailCampaignTemplateUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignTemplateCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignTemplateCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignTemplateCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignTemplateUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  data: EmailCampaignTemplateUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateUpdateWithWhereUniqueWithoutUserInput = {
  data: EmailCampaignTemplateUncheckedUpdateWithoutUserInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateUpdateWithoutClinicInput = {
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpdateWithoutOrganizationInput = {
  User?: Maybe<UserUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpdateWithoutUserInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput>;
  templateAvailability?: Maybe<TemplateAvailability>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutClinicInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutOrganizationInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutOrganizationInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateUpsertWithWhereUniqueWithoutUserInput = {
  create: EmailCampaignTemplateUncheckedCreateWithoutUserInput;
  update: EmailCampaignTemplateUncheckedUpdateWithoutUserInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};

export type EmailCampaignTemplateWhereInput = {
  AND?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  OR?: Maybe<Array<EmailCampaignTemplateWhereInput>>;
  User?: Maybe<UserWhereInput>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  htmlContent?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  templateAvailability?: Maybe<EnumTemplateAvailabilityFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum EmailCampaignType {
  ClinicEmailCampaign = 'ClinicEmailCampaign',
  InformScheduledCampaign = 'InformScheduledCampaign'
}

export type EmailCampaignUncheckedCreateInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
};

export type EmailCampaignUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
};

export type EmailCampaignUncheckedCreateWithoutClinicInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutCreatedByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutCustomListInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutSentByUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutUploadsInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedCreateWithoutUserInput = {
  clinicId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedCreateNestedOneWithoutEmailCampaignInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedCreateNestedManyWithoutEmailCampaignInput>;
};

export type EmailCampaignUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignUpdatedInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsCreatedInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsSentInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutSentByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput>>;
};

export type EmailCampaignUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EmailCampaignUncheckedUpdateWithoutClinicInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutCreatedByUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutCustomListInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutSentByUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutUploadsInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type EmailCampaignUncheckedUpdateWithoutUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  customList?: Maybe<EmailCampaignCustomListUncheckedUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecutionId?: Maybe<Scalars['String']>;
  latUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUserId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUncheckedUpdateManyWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUnsubscribe = {
  __typename?: 'EmailCampaignUnsubscribe';
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  emailCampaign?: Maybe<EmailCampaign>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source: EmailCampaignUnsubscribeSource;
  targetEmail: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EmailCampaignUnsubscribeCountAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailCampaignId: Scalars['Int'];
  id: Scalars['Int'];
  resubscribedAt: Scalars['Int'];
  source: Scalars['Int'];
  targetEmail: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailCampaignUnsubscribeCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeCreateInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateManyClinicInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateManyClinicInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyClinicPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyEmailCampaignInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope = {
  data: Array<EmailCampaignUnsubscribeCreateManyEmailCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailCampaignUnsubscribeCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignUnsubscribeCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeCreateWithoutClinicInput = {
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignCreateNestedOneWithoutEmailCampaignUnsubscribesInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutEmailCampaignUnsubscribeInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeListRelationFilter = {
  every?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  none?: Maybe<EmailCampaignUnsubscribeWhereInput>;
  some?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};

export type EmailCampaignUnsubscribeMaxAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeMinAggregateOutputType = {
  __typename?: 'EmailCampaignUnsubscribeMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailCampaignUnsubscribeOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  EmailCampaignId = 'emailCampaignId',
  Id = 'id',
  TargetEmail = 'targetEmail'
}

export type EmailCampaignUnsubscribeOrderByRelevanceInput = {
  fields: Array<EmailCampaignUnsubscribeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailCampaignUnsubscribeOrderByWithAggregationInput = {
  _count?: Maybe<EmailCampaignUnsubscribeCountOrderByAggregateInput>;
  _max?: Maybe<EmailCampaignUnsubscribeMaxOrderByAggregateInput>;
  _min?: Maybe<EmailCampaignUnsubscribeMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaignId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailCampaignUnsubscribeOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailCampaign?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  emailCampaignId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  resubscribedAt?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  targetEmail?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmailCampaignUnsubscribeScalarFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  EmailCampaignId = 'emailCampaignId',
  Id = 'id',
  ResubscribedAt = 'resubscribedAt',
  Source = 'source',
  TargetEmail = 'targetEmail',
  UpdatedAt = 'updatedAt'
}

export type EmailCampaignUnsubscribeScalarWhereInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaignId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  resubscribedAt?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceFilter>;
  targetEmail?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignUnsubscribeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  resubscribedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
  targetEmail?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum EmailCampaignUnsubscribeSource {
  Bounce = 'Bounce',
  SpamReport = 'SpamReport',
  Unsubscribe = 'Unsubscribe'
}

export type EmailCampaignUnsubscribeUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
};

export type EmailCampaignUnsubscribeUncheckedCreateNestedManyWithoutEmailCampaignInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribesInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaignId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput;
  where: EmailCampaignUnsubscribeScalarWhereInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribeInput;
  where: EmailCampaignUnsubscribeScalarWhereInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateManyWithoutEmailCampaignUnsubscribesInput;
  where: EmailCampaignUnsubscribeScalarWhereInput;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput = {
  connect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignUnsubscribeCreateOrConnectWithoutEmailCampaignInput>>;
  create?: Maybe<Array<EmailCampaignUnsubscribeCreateWithoutEmailCampaignInput>>;
  createMany?: Maybe<EmailCampaignUnsubscribeCreateManyEmailCampaignInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignUnsubscribeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignUnsubscribeWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput>>;
  updateMany?: Maybe<Array<EmailCampaignUnsubscribeUpdateManyWithWhereWithoutEmailCampaignInput>>;
  upsert?: Maybe<Array<EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput>>;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeUpdateWithWhereUniqueWithoutEmailCampaignInput = {
  data: EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeUpdateWithoutClinicInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpdateWithoutClinicPetParentInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaign?: Maybe<EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpdateWithoutEmailCampaignInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutEmailCampaignUnsubscribeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  resubscribedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<EmailCampaignUnsubscribeSource>;
  targetEmail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutClinicPetParentInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeUpsertWithWhereUniqueWithoutEmailCampaignInput = {
  create: EmailCampaignUnsubscribeUncheckedCreateWithoutEmailCampaignInput;
  update: EmailCampaignUnsubscribeUncheckedUpdateWithoutEmailCampaignInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};

export type EmailCampaignUnsubscribeWhereInput = {
  AND?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  OR?: Maybe<Array<EmailCampaignUnsubscribeWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaign?: Maybe<EmailCampaignWhereInput>;
  emailCampaignId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  resubscribedAt?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<EnumEmailCampaignUnsubscribeSourceFilter>;
  targetEmail?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailCampaignUnsubscribeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailCampaignUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  typedSegmentArgs?: Maybe<SegmentsArgsInput>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateManyMutationInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailCampaignUpdateManyWithWhereWithoutClinicInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsCreatedInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignUpdatedInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutSentByUserInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignsSentInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithWhereWithoutUserInput = {
  data: EmailCampaignUncheckedUpdateManyWithoutEmailCampaignInput;
  where: EmailCampaignScalarWhereInput;
};

export type EmailCampaignUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutClinicInput>>;
  createMany?: Maybe<EmailCampaignCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type EmailCampaignUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutInformCampaignExecutionInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutInformCampaignExecutionInput>>;
  createMany?: Maybe<EmailCampaignCreateManyInformCampaignExecutionInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutInformCampaignExecutionInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput>>;
};

export type EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutLastUpdatedByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutLastUpdatedByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyLastUpdatedByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutLastUpdatedByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput>>;
};

export type EmailCampaignUpdateManyWithoutSentByUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutSentByUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutSentByUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManySentByUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutSentByUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput>>;
};

export type EmailCampaignUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailCampaignCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<EmailCampaignCreateWithoutUserInput>>;
  createMany?: Maybe<EmailCampaignCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  set?: Maybe<Array<EmailCampaignWhereUniqueInput>>;
  update?: Maybe<Array<EmailCampaignUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EmailCampaignUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<EmailCampaignUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EmailCampaignUpdateOneRequiredWithoutCustomListNestedInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutCustomListInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutCustomListInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutCustomListInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutCustomListInput>;
};

export type EmailCampaignUpdateOneRequiredWithoutEmailCampaignLogsNestedInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignLogsInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutEmailCampaignLogsInput>;
};

export type EmailCampaignUpdateOneRequiredWithoutUploadsNestedInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutUploadsInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutUploadsInput>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutUploadsInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutUploadsInput>;
};

export type EmailCampaignUpdateOneWithoutEmailCampaignUnsubscribesNestedInput = {
  connect?: Maybe<EmailCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailCampaignCreateOrConnectWithoutEmailCampaignUnsubscribesInput>;
  create?: Maybe<EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput>;
  upsert?: Maybe<EmailCampaignUpsertWithoutEmailCampaignUnsubscribesInput>;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutClinicInput = {
  data: EmailCampaignUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: EmailCampaignUncheckedUpdateWithoutCreatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutInformCampaignExecutionInput = {
  data: EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutLastUpdatedByUserInput = {
  data: EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutSentByUserInput = {
  data: EmailCampaignUncheckedUpdateWithoutSentByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithWhereUniqueWithoutUserInput = {
  data: EmailCampaignUncheckedUpdateWithoutUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpdateWithoutClinicInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutCreatedByUserInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutCustomListInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutEmailCampaignLogsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutEmailCampaignUnsubscribesInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutInformCampaignExecutionInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutLastUpdatedByUserInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutSentByUserInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutUploadsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpdateWithoutUserInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutEmailCampaignNestedInput>;
  completedAt?: Maybe<Scalars['DateTime']>;
  contexts?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsCreatedNestedInput>;
  customList?: Maybe<EmailCampaignCustomListUpdateOneWithoutEmailCampaignNestedInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailCampaignLogs?: Maybe<EmailCampaignLogUpdateManyWithoutEmailCampaignNestedInput>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeUpdateManyWithoutEmailCampaignNestedInput>;
  from?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignExecution?: Maybe<InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput>;
  lastUpdatedByUser?: Maybe<UserUpdateOneWithoutEmailCampaignUpdatedNestedInput>;
  name?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyToJson?: Maybe<Scalars['Json']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentArgs?: Maybe<Scalars['Json']>;
  segmentWhere?: Maybe<Scalars['Json']>;
  segmentationDefinition?: Maybe<Scalars['Json']>;
  segmentationType?: Maybe<EmailCampaignSegmentationType>;
  sentByUser?: Maybe<UserUpdateOneWithoutEmailCampaignsSentNestedInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EmailCampaignStatus>;
  subject?: Maybe<Scalars['String']>;
  totalSegmentContacts?: Maybe<Scalars['Int']>;
  type?: Maybe<EmailCampaignType>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryUpdateManyWithoutEmailCampaignNestedInput>;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutClinicInput = {
  create: EmailCampaignUncheckedCreateWithoutClinicInput;
  update: EmailCampaignUncheckedUpdateWithoutClinicInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutCreatedByUserInput;
  update: EmailCampaignUncheckedUpdateWithoutCreatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutInformCampaignExecutionInput = {
  create: EmailCampaignUncheckedCreateWithoutInformCampaignExecutionInput;
  update: EmailCampaignUncheckedUpdateWithoutInformCampaignExecutionInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutLastUpdatedByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutLastUpdatedByUserInput;
  update: EmailCampaignUncheckedUpdateWithoutLastUpdatedByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutSentByUserInput = {
  create: EmailCampaignUncheckedCreateWithoutSentByUserInput;
  update: EmailCampaignUncheckedUpdateWithoutSentByUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithWhereUniqueWithoutUserInput = {
  create: EmailCampaignUncheckedCreateWithoutUserInput;
  update: EmailCampaignUncheckedUpdateWithoutUserInput;
  where: EmailCampaignWhereUniqueInput;
};

export type EmailCampaignUpsertWithoutCustomListInput = {
  create: EmailCampaignUncheckedCreateWithoutCustomListInput;
  update: EmailCampaignUncheckedUpdateWithoutCustomListInput;
};

export type EmailCampaignUpsertWithoutEmailCampaignLogsInput = {
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignLogsInput;
  update: EmailCampaignUncheckedUpdateWithoutEmailCampaignLogsInput;
};

export type EmailCampaignUpsertWithoutEmailCampaignUnsubscribesInput = {
  create: EmailCampaignUncheckedCreateWithoutEmailCampaignUnsubscribesInput;
  update: EmailCampaignUncheckedUpdateWithoutEmailCampaignUnsubscribesInput;
};

export type EmailCampaignUpsertWithoutUploadsInput = {
  create: EmailCampaignUncheckedCreateWithoutUploadsInput;
  update: EmailCampaignUncheckedUpdateWithoutUploadsInput;
};

export type EmailCampaignWhereInput = {
  AND?: Maybe<Array<EmailCampaignWhereInput>>;
  NOT?: Maybe<Array<EmailCampaignWhereInput>>;
  OR?: Maybe<Array<EmailCampaignWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  contexts?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  createdByUserId?: Maybe<StringNullableFilter>;
  customList?: Maybe<EmailCampaignCustomListWhereInput>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  emailCampaignLogs?: Maybe<EmailCampaignLogListRelationFilter>;
  emailCampaignUnsubscribes?: Maybe<EmailCampaignUnsubscribeListRelationFilter>;
  from?: Maybe<StringFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaignExecution?: Maybe<InformCampaignExecutionWhereInput>;
  informCampaignExecutionId?: Maybe<StringNullableFilter>;
  lastUpdatedByUser?: Maybe<UserWhereInput>;
  latUpdatedByUserId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  replyTo?: Maybe<StringNullableFilter>;
  replyToJson?: Maybe<JsonNullableFilter>;
  scheduledAt?: Maybe<DateTimeNullableFilter>;
  segmentArgs?: Maybe<JsonNullableFilter>;
  segmentWhere?: Maybe<JsonNullableFilter>;
  segmentationDefinition?: Maybe<JsonNullableFilter>;
  segmentationType?: Maybe<EnumEmailCampaignSegmentationTypeFilter>;
  sentByUser?: Maybe<UserWhereInput>;
  sentByUserId?: Maybe<StringNullableFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  startsAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumEmailCampaignStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  totalSegmentContacts?: Maybe<IntNullableFilter>;
  type?: Maybe<EnumEmailCampaignTypeFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploads?: Maybe<EmailCampaignCustomListUploadHistoryListRelationFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type EmailCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum EmailProvider {
  Sendgrid = 'Sendgrid'
}

export type EmailProviderLog = {
  __typename?: 'EmailProviderLog';
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id: Scalars['String'];
  provider: EmailProvider;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EmailProviderLogCountAggregateOutputType = {
  __typename?: 'EmailProviderLogCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: Scalars['Int'];
  id: Scalars['Int'];
  provider: Scalars['Int'];
  providerEmailTemplateId: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailProviderLogCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailProviderLogCreateInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutEmailProviderLogsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<EmailProviderLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailProviderLogCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
};

export type EmailProviderLogCreateOrConnectWithoutClinicPetParentInput = {
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
  where: EmailProviderLogWhereUniqueInput;
};

export type EmailProviderLogCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum EmailProviderLogEntityType {
  Appointment = 'Appointment',
  AutomationRun = 'AutomationRun',
  Channel = 'Channel',
  ChannelMessage = 'ChannelMessage',
  ClinicPetParent = 'ClinicPetParent',
  ClinicWidgetRequest = 'ClinicWidgetRequest',
  ServiceReminderNotification = 'ServiceReminderNotification',
  StripeRefund = 'StripeRefund'
}

export type EmailProviderLogListRelationFilter = {
  every?: Maybe<EmailProviderLogWhereInput>;
  none?: Maybe<EmailProviderLogWhereInput>;
  some?: Maybe<EmailProviderLogWhereInput>;
};

export type EmailProviderLogMaxAggregateOutputType = {
  __typename?: 'EmailProviderLogMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailProviderLogMinAggregateOutputType = {
  __typename?: 'EmailProviderLogMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailProviderLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailProviderLogOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  EntityId = 'entityId',
  Id = 'id',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId'
}

export type EmailProviderLogOrderByRelevanceInput = {
  fields: Array<EmailProviderLogOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailProviderLogOrderByWithAggregationInput = {
  _count?: Maybe<EmailProviderLogCountOrderByAggregateInput>;
  _max?: Maybe<EmailProviderLogMaxOrderByAggregateInput>;
  _min?: Maybe<EmailProviderLogMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailProviderLogOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailProviderLogOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerEmailTemplateId?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmailProviderLogScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id',
  Provider = 'provider',
  ProviderEmailTemplateId = 'providerEmailTemplateId',
  ProviderMessageId = 'providerMessageId',
  UpdatedAt = 'updatedAt'
}

export type EmailProviderLogScalarWhereInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailProviderLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailProviderLogScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  entityId?: Maybe<StringWithAggregatesFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  provider?: Maybe<EnumEmailProviderWithAggregatesFilter>;
  providerEmailTemplateId?: Maybe<StringWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EmailProviderLogUncheckedCreateInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
};

export type EmailProviderLogUncheckedCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EmailProviderLogEntityType;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId: Scalars['String'];
  providerMessageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutEmailProviderLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: EmailProviderLogUncheckedUpdateManyWithoutEmailProviderLogsInput;
  where: EmailProviderLogScalarWhereInput;
};

export type EmailProviderLogUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailProviderLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<EmailProviderLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<EmailProviderLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailProviderLogScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  set?: Maybe<Array<EmailProviderLogWhereUniqueInput>>;
  update?: Maybe<Array<EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<EmailProviderLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type EmailProviderLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailProviderLogWhereUniqueInput;
};

export type EmailProviderLogUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EmailProviderLogEntityType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<EmailProvider>;
  providerEmailTemplateId?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailProviderLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: EmailProviderLogUncheckedCreateWithoutClinicPetParentInput;
  update: EmailProviderLogUncheckedUpdateWithoutClinicPetParentInput;
  where: EmailProviderLogWhereUniqueInput;
};

export type EmailProviderLogWhereInput = {
  AND?: Maybe<Array<EmailProviderLogWhereInput>>;
  NOT?: Maybe<Array<EmailProviderLogWhereInput>>;
  OR?: Maybe<Array<EmailProviderLogWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEmailProviderLogEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumEmailProviderFilter>;
  providerEmailTemplateId?: Maybe<StringFilter>;
  providerMessageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailProviderLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  _count: EmailTemplateCountOutputType;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  emailTemplatesVersions: Array<EmailTemplateVersion>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSetting: UnlayerSetting;
  unlayerSettingId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type EmailTemplateEmailTemplatesVersionsArgs = {
  cursor?: Maybe<EmailTemplateVersionWhereUniqueInput>;
  distinct?: Maybe<EmailTemplateVersionScalarFieldEnum>;
  orderBy?: Maybe<EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailTemplateVersionWhereInput>;
};

export type EmailTemplateAvgAggregateOutputType = {
  __typename?: 'EmailTemplateAvgAggregateOutputType';
  unlayerSettingId?: Maybe<Scalars['Float']>;
};

export type EmailTemplateAvgOrderByAggregateInput = {
  unlayerSettingId?: Maybe<SortOrder>;
};

export type EmailTemplateCountAggregateOutputType = {
  __typename?: 'EmailTemplateCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  lastPublishedAt: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  unlayerSettingId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmailTemplateCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailTemplateCountOutputType = {
  __typename?: 'EmailTemplateCountOutputType';
  emailTemplatesVersions: Scalars['Int'];
};

export type EmailTemplateCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  emailTemplatesVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput>;
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSetting: UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateManyUnlayerSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateManyUnlayerSettingInputEnvelope = {
  data: Array<EmailTemplateCreateManyUnlayerSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateCreateNestedManyWithoutUnlayerSettingInput = {
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
};

export type EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput = {
  connect?: Maybe<EmailTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput>;
  create?: Maybe<EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput>;
};

export type EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput = {
  create: EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput;
  where: EmailTemplateWhereUniqueInput;
};

export type EmailTemplateCreateOrConnectWithoutUnlayerSettingInput = {
  create: EmailTemplateUncheckedCreateWithoutUnlayerSettingInput;
  where: EmailTemplateWhereUniqueInput;
};

export type EmailTemplateCreateWithoutEmailTemplatesVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSetting: UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateCreateWithoutUnlayerSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  emailTemplatesVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput>;
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateListRelationFilter = {
  every?: Maybe<EmailTemplateWhereInput>;
  none?: Maybe<EmailTemplateWhereInput>;
  some?: Maybe<EmailTemplateWhereInput>;
};

export type EmailTemplateMaxAggregateOutputType = {
  __typename?: 'EmailTemplateMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailTemplateMinAggregateOutputType = {
  __typename?: 'EmailTemplateMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailTemplateOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name'
}

export type EmailTemplateOrderByRelevanceInput = {
  fields: Array<EmailTemplateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailTemplateOrderByWithAggregationInput = {
  _avg?: Maybe<EmailTemplateAvgOrderByAggregateInput>;
  _count?: Maybe<EmailTemplateCountOrderByAggregateInput>;
  _max?: Maybe<EmailTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<EmailTemplateMinOrderByAggregateInput>;
  _sum?: Maybe<EmailTemplateSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailTemplateOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailTemplateOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  lastPublishedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unlayerSetting?: Maybe<UnlayerSettingOrderByWithRelationAndSearchRelevanceInput>;
  unlayerSettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmailTemplateRelationFilter = {
  is?: Maybe<EmailTemplateWhereInput>;
  isNot?: Maybe<EmailTemplateWhereInput>;
};

export enum EmailTemplateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  IsActive = 'isActive',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  Type = 'type',
  UnlayerSettingId = 'unlayerSettingId',
  UpdatedAt = 'updatedAt'
}

export type EmailTemplateScalarWhereInput = {
  AND?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  OR?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumEmailTemplateTypeFilter>;
  unlayerSettingId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailTemplateScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumEmailTemplateTypeWithAggregatesFilter>;
  unlayerSettingId?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EmailTemplateSumAggregateOutputType = {
  __typename?: 'EmailTemplateSumAggregateOutputType';
  unlayerSettingId?: Maybe<Scalars['Int']>;
};

export type EmailTemplateSumOrderByAggregateInput = {
  unlayerSettingId?: Maybe<SortOrder>;
};

export enum EmailTemplateType {
  ServiceReminder = 'ServiceReminder'
}

export type EmailTemplateUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput>;
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput = {
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
};

export type EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  unlayerSettingId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedCreateWithoutUnlayerSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput>;
  id?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: EmailTemplateType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateManyWithoutEmailTemplatesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput = {
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
};

export type EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSettingId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput = {
  data: EmailTemplateUncheckedUpdateManyWithoutEmailTemplatesInput;
  where: EmailTemplateScalarWhereInput;
};

export type EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput = {
  connect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateCreateOrConnectWithoutUnlayerSettingInput>>;
  create?: Maybe<Array<EmailTemplateCreateWithoutUnlayerSettingInput>>;
  createMany?: Maybe<EmailTemplateCreateManyUnlayerSettingInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput>>;
  updateMany?: Maybe<Array<EmailTemplateUpdateManyWithWhereWithoutUnlayerSettingInput>>;
  upsert?: Maybe<Array<EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput>>;
};

export type EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput = {
  connect?: Maybe<EmailTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<EmailTemplateCreateOrConnectWithoutEmailTemplatesVersionsInput>;
  create?: Maybe<EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput>;
  update?: Maybe<EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput>;
  upsert?: Maybe<EmailTemplateUpsertWithoutEmailTemplatesVersionsInput>;
};

export type EmailTemplateUpdateWithWhereUniqueWithoutUnlayerSettingInput = {
  data: EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput;
  where: EmailTemplateWhereUniqueInput;
};

export type EmailTemplateUpdateWithoutEmailTemplatesVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  unlayerSetting?: Maybe<UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpdateWithoutUnlayerSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateUpsertWithWhereUniqueWithoutUnlayerSettingInput = {
  create: EmailTemplateUncheckedCreateWithoutUnlayerSettingInput;
  update: EmailTemplateUncheckedUpdateWithoutUnlayerSettingInput;
  where: EmailTemplateWhereUniqueInput;
};

export type EmailTemplateUpsertWithoutEmailTemplatesVersionsInput = {
  create: EmailTemplateUncheckedCreateWithoutEmailTemplatesVersionsInput;
  update: EmailTemplateUncheckedUpdateWithoutEmailTemplatesVersionsInput;
};

export type EmailTemplateVersion = {
  __typename?: 'EmailTemplateVersion';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplate: EmailTemplate;
  emailTemplateId: Scalars['String'];
  html: Scalars['String'];
  id: Scalars['String'];
  isCurrent: Scalars['Boolean'];
  lastEditedBy?: Maybe<User>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type EmailTemplateVersionAvgAggregateOutputType = {
  __typename?: 'EmailTemplateVersionAvgAggregateOutputType';
  version?: Maybe<Scalars['Float']>;
};

export type EmailTemplateVersionAvgOrderByAggregateInput = {
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionCountAggregateOutputType = {
  __typename?: 'EmailTemplateVersionCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  design: Scalars['Int'];
  emailTemplateId: Scalars['Int'];
  html: Scalars['Int'];
  id: Scalars['Int'];
  isCurrent: Scalars['Int'];
  lastEditedById: Scalars['Int'];
  previewImageUrl: Scalars['Int'];
  publishedAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  version: Scalars['Int'];
};

export type EmailTemplateVersionCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplate: EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput;
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<UserCreateNestedOneWithoutEmailTemplateVersionsInput>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionCreateManyEmailTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionCreateManyEmailTemplateInputEnvelope = {
  data: Array<EmailTemplateVersionCreateManyEmailTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateVersionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplateId: Scalars['String'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionCreateManyLastEditedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplateId: Scalars['String'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionCreateManyLastEditedByInputEnvelope = {
  data: Array<EmailTemplateVersionCreateManyLastEditedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateVersionCreateNestedManyWithoutEmailTemplateInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
};

export type EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
};

export type EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput = {
  create: EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput = {
  create: EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionCreateWithoutEmailTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<UserCreateNestedOneWithoutEmailTemplateVersionsInput>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionCreateWithoutLastEditedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplate: EmailTemplateCreateNestedOneWithoutEmailTemplatesVersionsInput;
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionListRelationFilter = {
  every?: Maybe<EmailTemplateVersionWhereInput>;
  none?: Maybe<EmailTemplateVersionWhereInput>;
  some?: Maybe<EmailTemplateVersionWhereInput>;
};

export type EmailTemplateVersionMaxAggregateOutputType = {
  __typename?: 'EmailTemplateVersionMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionMinAggregateOutputType = {
  __typename?: 'EmailTemplateVersionMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmailTemplateVersionOrderByRelevanceFieldEnum {
  EmailTemplateId = 'emailTemplateId',
  Html = 'html',
  Id = 'id',
  LastEditedById = 'lastEditedById',
  PreviewImageUrl = 'previewImageUrl'
}

export type EmailTemplateVersionOrderByRelevanceInput = {
  fields: Array<EmailTemplateVersionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmailTemplateVersionOrderByWithAggregationInput = {
  _avg?: Maybe<EmailTemplateVersionAvgOrderByAggregateInput>;
  _count?: Maybe<EmailTemplateVersionCountOrderByAggregateInput>;
  _max?: Maybe<EmailTemplateVersionMaxOrderByAggregateInput>;
  _min?: Maybe<EmailTemplateVersionMinOrderByAggregateInput>;
  _sum?: Maybe<EmailTemplateVersionSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  emailTemplateId?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  lastEditedById?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmailTemplateVersionOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  design?: Maybe<SortOrder>;
  emailTemplate?: Maybe<EmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  emailTemplateId?: Maybe<SortOrder>;
  html?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCurrent?: Maybe<SortOrder>;
  lastEditedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  lastEditedById?: Maybe<SortOrder>;
  previewImageUrl?: Maybe<SortOrder>;
  publishedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
};

export enum EmailTemplateVersionScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Design = 'design',
  EmailTemplateId = 'emailTemplateId',
  Html = 'html',
  Id = 'id',
  IsCurrent = 'isCurrent',
  LastEditedById = 'lastEditedById',
  PreviewImageUrl = 'previewImageUrl',
  PublishedAt = 'publishedAt',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type EmailTemplateVersionScalarWhereInput = {
  AND?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  OR?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  design?: Maybe<JsonFilter>;
  emailTemplateId?: Maybe<StringFilter>;
  html?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isCurrent?: Maybe<BoolFilter>;
  lastEditedById?: Maybe<StringNullableFilter>;
  previewImageUrl?: Maybe<StringNullableFilter>;
  publishedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  version?: Maybe<IntFilter>;
};

export type EmailTemplateVersionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmailTemplateVersionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  design?: Maybe<JsonWithAggregatesFilter>;
  emailTemplateId?: Maybe<StringWithAggregatesFilter>;
  html?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isCurrent?: Maybe<BoolWithAggregatesFilter>;
  lastEditedById?: Maybe<StringNullableWithAggregatesFilter>;
  previewImageUrl?: Maybe<StringNullableWithAggregatesFilter>;
  publishedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  version?: Maybe<IntWithAggregatesFilter>;
};

export type EmailTemplateVersionSumAggregateOutputType = {
  __typename?: 'EmailTemplateVersionSumAggregateOutputType';
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionSumOrderByAggregateInput = {
  version?: Maybe<SortOrder>;
};

export type EmailTemplateVersionUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplateId: Scalars['String'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedCreateNestedManyWithoutEmailTemplateInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
};

export type EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
};

export type EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design: Scalars['Json'];
  emailTemplateId: Scalars['String'];
  html: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateNestedInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput>>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplatesVersionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput>>;
};

export type EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedById?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplateId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplate?: Maybe<EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<UserUpdateOneWithoutEmailTemplateVersionsNestedInput>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput = {
  data: EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplatesVersionsInput;
  where: EmailTemplateVersionScalarWhereInput;
};

export type EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput = {
  data: EmailTemplateVersionUncheckedUpdateManyWithoutEmailTemplateVersionsInput;
  where: EmailTemplateVersionScalarWhereInput;
};

export type EmailTemplateVersionUpdateManyWithoutEmailTemplateNestedInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutEmailTemplateInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutEmailTemplateInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyEmailTemplateInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutEmailTemplateInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput>>;
};

export type EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput = {
  connect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmailTemplateVersionCreateOrConnectWithoutLastEditedByInput>>;
  create?: Maybe<Array<EmailTemplateVersionCreateWithoutLastEditedByInput>>;
  createMany?: Maybe<EmailTemplateVersionCreateManyLastEditedByInputEnvelope>;
  delete?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmailTemplateVersionScalarWhereInput>>;
  disconnect?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  set?: Maybe<Array<EmailTemplateVersionWhereUniqueInput>>;
  update?: Maybe<Array<EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput>>;
  updateMany?: Maybe<Array<EmailTemplateVersionUpdateManyWithWhereWithoutLastEditedByInput>>;
  upsert?: Maybe<Array<EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput>>;
};

export type EmailTemplateVersionUpdateWithWhereUniqueWithoutEmailTemplateInput = {
  data: EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionUpdateWithWhereUniqueWithoutLastEditedByInput = {
  data: EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionUpdateWithoutEmailTemplateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<UserUpdateOneWithoutEmailTemplateVersionsNestedInput>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUpdateWithoutLastEditedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  design?: Maybe<Scalars['Json']>;
  emailTemplate?: Maybe<EmailTemplateUpdateOneRequiredWithoutEmailTemplatesVersionsNestedInput>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export type EmailTemplateVersionUpsertWithWhereUniqueWithoutEmailTemplateInput = {
  create: EmailTemplateVersionUncheckedCreateWithoutEmailTemplateInput;
  update: EmailTemplateVersionUncheckedUpdateWithoutEmailTemplateInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionUpsertWithWhereUniqueWithoutLastEditedByInput = {
  create: EmailTemplateVersionUncheckedCreateWithoutLastEditedByInput;
  update: EmailTemplateVersionUncheckedUpdateWithoutLastEditedByInput;
  where: EmailTemplateVersionWhereUniqueInput;
};

export type EmailTemplateVersionWhereInput = {
  AND?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  OR?: Maybe<Array<EmailTemplateVersionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  design?: Maybe<JsonFilter>;
  emailTemplate?: Maybe<EmailTemplateWhereInput>;
  emailTemplateId?: Maybe<StringFilter>;
  html?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isCurrent?: Maybe<BoolFilter>;
  lastEditedBy?: Maybe<UserWhereInput>;
  lastEditedById?: Maybe<StringNullableFilter>;
  previewImageUrl?: Maybe<StringNullableFilter>;
  publishedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  version?: Maybe<IntFilter>;
};

export type EmailTemplateVersionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmailTemplateWhereInput = {
  AND?: Maybe<Array<EmailTemplateWhereInput>>;
  NOT?: Maybe<Array<EmailTemplateWhereInput>>;
  OR?: Maybe<Array<EmailTemplateWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  emailTemplatesVersions?: Maybe<EmailTemplateVersionListRelationFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  lastPublishedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumEmailTemplateTypeFilter>;
  unlayerSetting?: Maybe<UnlayerSettingWhereInput>;
  unlayerSettingId?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmbedUrl = {
  __typename?: 'EmbedURL';
  EmbedUrl?: Maybe<Scalars['String']>;
  RequestId?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['Int']>;
};

export type EmergencyClinicContactInfo = {
  __typename?: 'EmergencyClinicContactInfo';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type EmergencyClinics = {
  __typename?: 'EmergencyClinics';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSetting: ClinicSetting;
  clinicSettingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EmergencyClinicsAvgAggregateOutputType = {
  __typename?: 'EmergencyClinicsAvgAggregateOutputType';
  displayOrder?: Maybe<Scalars['Float']>;
};

export type EmergencyClinicsAvgOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput = {
  clinicSettingId: Scalars['String'];
  displayOrder: Scalars['Int'];
};

export type EmergencyClinicsCountAggregateOutputType = {
  __typename?: 'EmergencyClinicsCountAggregateOutputType';
  _all: Scalars['Int'];
  addressLine1: Scalars['Int'];
  addressLine2: Scalars['Int'];
  city: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayOrder: Scalars['Int'];
  fullAddress: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  phone: Scalars['Int'];
  postalCode: Scalars['Int'];
  state: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EmergencyClinicsCountOrderByAggregateInput = {
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmergencyClinicsCreateInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSetting: ClinicSettingCreateNestedOneWithoutEmergencyClinicsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsCreateManyClinicSettingInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsCreateManyClinicSettingInputEnvelope = {
  data: Array<EmergencyClinicsCreateManyClinicSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmergencyClinicsCreateManyInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
};

export type EmergencyClinicsCreateOrConnectWithoutClinicSettingInput = {
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
  where: EmergencyClinicsWhereUniqueInput;
};

export type EmergencyClinicsCreateWithoutClinicSettingInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsListRelationFilter = {
  every?: Maybe<EmergencyClinicsWhereInput>;
  none?: Maybe<EmergencyClinicsWhereInput>;
  some?: Maybe<EmergencyClinicsWhereInput>;
};

export type EmergencyClinicsMaxAggregateOutputType = {
  __typename?: 'EmergencyClinicsMaxAggregateOutputType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsMaxOrderByAggregateInput = {
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmergencyClinicsMinAggregateOutputType = {
  __typename?: 'EmergencyClinicsMinAggregateOutputType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsMinOrderByAggregateInput = {
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmergencyClinicsOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EmergencyClinicsOrderByRelevanceFieldEnum {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  ClinicSettingId = 'clinicSettingId',
  FullAddress = 'fullAddress',
  Id = 'id',
  Name = 'name',
  Phone = 'phone',
  PostalCode = 'postalCode',
  State = 'state'
}

export type EmergencyClinicsOrderByRelevanceInput = {
  fields: Array<EmergencyClinicsOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EmergencyClinicsOrderByWithAggregationInput = {
  _avg?: Maybe<EmergencyClinicsAvgOrderByAggregateInput>;
  _count?: Maybe<EmergencyClinicsCountOrderByAggregateInput>;
  _max?: Maybe<EmergencyClinicsMaxOrderByAggregateInput>;
  _min?: Maybe<EmergencyClinicsMinOrderByAggregateInput>;
  _sum?: Maybe<EmergencyClinicsSumOrderByAggregateInput>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EmergencyClinicsOrderByRelevanceInput>;
  addressLine1?: Maybe<SortOrder>;
  addressLine2?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayOrder?: Maybe<SortOrder>;
  fullAddress?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EmergencyClinicsScalarFieldEnum {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  DisplayOrder = 'displayOrder',
  FullAddress = 'fullAddress',
  Id = 'id',
  IsDeleted = 'isDeleted',
  Name = 'name',
  Phone = 'phone',
  PostalCode = 'postalCode',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

export type EmergencyClinicsScalarWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmergencyClinicsScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EmergencyClinicsScalarWhereWithAggregatesInput>>;
  addressLine1?: Maybe<StringNullableWithAggregatesFilter>;
  addressLine2?: Maybe<StringNullableWithAggregatesFilter>;
  city?: Maybe<StringNullableWithAggregatesFilter>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayOrder?: Maybe<IntWithAggregatesFilter>;
  fullAddress?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  phone?: Maybe<StringWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  state?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EmergencyClinicsSumAggregateOutputType = {
  __typename?: 'EmergencyClinicsSumAggregateOutputType';
  displayOrder?: Maybe<Scalars['Int']>;
};

export type EmergencyClinicsSumOrderByAggregateInput = {
  displayOrder?: Maybe<SortOrder>;
};

export type EmergencyClinicsUncheckedCreateInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUncheckedCreateNestedManyWithoutClinicSettingInput = {
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
};

export type EmergencyClinicsUncheckedCreateWithoutClinicSettingInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder: Scalars['Int'];
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUncheckedUpdateInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUncheckedUpdateManyInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUpdateInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutEmergencyClinicsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUpdateManyMutationInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput = {
  data: EmergencyClinicsUncheckedUpdateManyWithoutEmergencyClinicsInput;
  where: EmergencyClinicsScalarWhereInput;
};

export type EmergencyClinicsUpdateManyWithoutClinicSettingNestedInput = {
  connect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyClinicsCreateOrConnectWithoutClinicSettingInput>>;
  create?: Maybe<Array<EmergencyClinicsCreateWithoutClinicSettingInput>>;
  createMany?: Maybe<EmergencyClinicsCreateManyClinicSettingInputEnvelope>;
  delete?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmergencyClinicsScalarWhereInput>>;
  disconnect?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  set?: Maybe<Array<EmergencyClinicsWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput>>;
  updateMany?: Maybe<Array<EmergencyClinicsUpdateManyWithWhereWithoutClinicSettingInput>>;
  upsert?: Maybe<Array<EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput>>;
};

export type EmergencyClinicsUpdateWithWhereUniqueWithoutClinicSettingInput = {
  data: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
  where: EmergencyClinicsWhereUniqueInput;
};

export type EmergencyClinicsUpdateWithoutClinicSettingInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayOrder?: Maybe<Scalars['Int']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyClinicsUpsertWithWhereUniqueWithoutClinicSettingInput = {
  create: EmergencyClinicsUncheckedCreateWithoutClinicSettingInput;
  update: EmergencyClinicsUncheckedUpdateWithoutClinicSettingInput;
  where: EmergencyClinicsWhereUniqueInput;
};

export type EmergencyClinicsWhereInput = {
  AND?: Maybe<Array<EmergencyClinicsWhereInput>>;
  NOT?: Maybe<Array<EmergencyClinicsWhereInput>>;
  OR?: Maybe<Array<EmergencyClinicsWhereInput>>;
  addressLine1?: Maybe<StringNullableFilter>;
  addressLine2?: Maybe<StringNullableFilter>;
  city?: Maybe<StringNullableFilter>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayOrder?: Maybe<IntFilter>;
  fullAddress?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmergencyClinicsWhereUniqueInput = {
  clinicSettingId_displayOrder?: Maybe<EmergencyClinicsClinicSettingIdDisplayOrderCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum EncryptedSignedUploadPurpose {
  LegalEntityClinic = 'LegalEntity_Clinic',
  LegalEntityRepresentative = 'LegalEntity_Representative'
}

export type EncryptedUploadResponse = {
  __typename?: 'EncryptedUploadResponse';
  success: Scalars['Boolean'];
};

export type EnhancedStripeRefundPaymentInput = {
  amount: Scalars['Int'];
  channelId?: Maybe<Scalars['String']>;
  issuerInitials?: Maybe<Scalars['String']>;
  reason: StripeRefundReason;
  reasonOther?: Maybe<Scalars['String']>;
};

export type EnrollmentAndSubscriptionInput = {
  carePlanId: Scalars['String'];
  organizationPetId: Scalars['String'];
};

export enum EnrollmentStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Cancelling = 'Cancelling',
  Expired = 'Expired',
  NeverStarted = 'NeverStarted',
  PaymentFailed = 'PaymentFailed',
  Pending = 'Pending'
}

export type EnrollmentSupportEvent = {
  __typename?: 'EnrollmentSupportEvent';
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollment;
  carePlanEnrollmentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currentPlan?: Maybe<CarePlan>;
  currentPlanId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlan>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<User>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoice?: Maybe<StripeInvoice>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requester?: Maybe<User>;
  requesterId?: Maybe<Scalars['String']>;
  responder?: Maybe<User>;
  responderId?: Maybe<Scalars['String']>;
  status: SupportEventStatus;
  transferFromPet?: Maybe<OrganizationPet>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPet?: Maybe<OrganizationPet>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventAvgAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type EnrollmentSupportEventAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventCountAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  cancelReason: Scalars['Int'];
  carePlanEnrollmentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentPlanId: Scalars['Int'];
  id: Scalars['Int'];
  manageMembershipDescription: Scalars['Int'];
  manageMembershipRequestType: Scalars['Int'];
  newPlanId: Scalars['Int'];
  notes: Scalars['Int'];
  paymentSchedule: Scalars['Int'];
  pointOfContactId: Scalars['Int'];
  relatedInvoiceId: Scalars['Int'];
  requesterId: Scalars['Int'];
  responderId: Scalars['Int'];
  status: Scalars['Int'];
  transferFromPetId: Scalars['Int'];
  transferToPetId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  zendeskTicketUrl: Scalars['Int'];
};

export type EnrollmentSupportEventCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventCreateInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyCarePlanEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyCurrentPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyCurrentPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyNewPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyNewPlanInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyNewPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyPointOfContactInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyPointOfContactInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyPointOfContactInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyRelatedInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyRelatedInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyRequesterInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyRequesterInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyRequesterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyResponderInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyResponderInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyResponderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyTransferFromPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyTransferFromPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateManyTransferToPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateManyTransferToPetInputEnvelope = {
  data: Array<EnrollmentSupportEventCreateManyTransferToPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutCurrentPlanInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutNewPlanInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutRequesterInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutResponderInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
};

export type EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
};

export type EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutRequesterInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutRequesterInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutResponderInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutResponderInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutCurrentPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutNewPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutPointOfContactInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutRelatedInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutRequesterInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutResponderInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutTransferFromPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferToPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventCreateWithoutTransferToPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment: CarePlanEnrollmentCreateNestedOneWithoutEnrollmentSupportEventsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanCreateNestedOneWithoutCurrentPlanEnrollmentSupportEventsInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlan?: Maybe<CarePlanCreateNestedOneWithoutNewPlanEnrollmentSupportEventsInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput>;
  relatedInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput>;
  requester?: Maybe<UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput>;
  responder?: Maybe<UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventListRelationFilter = {
  every?: Maybe<EnrollmentSupportEventWhereInput>;
  none?: Maybe<EnrollmentSupportEventWhereInput>;
  some?: Maybe<EnrollmentSupportEventWhereInput>;
};

export type EnrollmentSupportEventMaxAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventMaxAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventMinAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventMinAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EnrollmentSupportEventOrderByRelevanceFieldEnum {
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CurrentPlanId = 'currentPlanId',
  Id = 'id',
  ManageMembershipDescription = 'manageMembershipDescription',
  NewPlanId = 'newPlanId',
  Notes = 'notes',
  PointOfContactId = 'pointOfContactId',
  RelatedInvoiceId = 'relatedInvoiceId',
  RequesterId = 'requesterId',
  ResponderId = 'responderId',
  TransferFromPetId = 'transferFromPetId',
  TransferToPetId = 'transferToPetId',
  ZendeskTicketUrl = 'zendeskTicketUrl'
}

export type EnrollmentSupportEventOrderByRelevanceInput = {
  fields: Array<EnrollmentSupportEventOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EnrollmentSupportEventOrderByWithAggregationInput = {
  _avg?: Maybe<EnrollmentSupportEventAvgOrderByAggregateInput>;
  _count?: Maybe<EnrollmentSupportEventCountOrderByAggregateInput>;
  _max?: Maybe<EnrollmentSupportEventMaxOrderByAggregateInput>;
  _min?: Maybe<EnrollmentSupportEventMinOrderByAggregateInput>;
  _sum?: Maybe<EnrollmentSupportEventSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  newPlanId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  pointOfContactId?: Maybe<SortOrder>;
  relatedInvoiceId?: Maybe<SortOrder>;
  requesterId?: Maybe<SortOrder>;
  responderId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EnrollmentSupportEventOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  cancelReason?: Maybe<SortOrder>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  carePlanEnrollmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  currentPlanId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  manageMembershipDescription?: Maybe<SortOrder>;
  manageMembershipRequestType?: Maybe<SortOrder>;
  newPlan?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  newPlanId?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  paymentSchedule?: Maybe<SortOrder>;
  pointOfContact?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  pointOfContactId?: Maybe<SortOrder>;
  relatedInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  relatedInvoiceId?: Maybe<SortOrder>;
  requester?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  requesterId?: Maybe<SortOrder>;
  responder?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  responderId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transferFromPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  transferFromPetId?: Maybe<SortOrder>;
  transferToPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  transferToPetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zendeskTicketUrl?: Maybe<SortOrder>;
};

export enum EnrollmentSupportEventScalarFieldEnum {
  Amount = 'amount',
  CancelReason = 'cancelReason',
  CarePlanEnrollmentId = 'carePlanEnrollmentId',
  CreatedAt = 'createdAt',
  CurrentPlanId = 'currentPlanId',
  Id = 'id',
  ManageMembershipDescription = 'manageMembershipDescription',
  ManageMembershipRequestType = 'manageMembershipRequestType',
  NewPlanId = 'newPlanId',
  Notes = 'notes',
  PaymentSchedule = 'paymentSchedule',
  PointOfContactId = 'pointOfContactId',
  RelatedInvoiceId = 'relatedInvoiceId',
  RequesterId = 'requesterId',
  ResponderId = 'responderId',
  Status = 'status',
  TransferFromPetId = 'transferFromPetId',
  TransferToPetId = 'transferToPetId',
  UpdatedAt = 'updatedAt',
  ZendeskTicketUrl = 'zendeskTicketUrl'
}

export type EnrollmentSupportEventScalarWhereInput = {
  AND?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  amount?: Maybe<FloatNullableFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableFilter>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentPlanId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  manageMembershipDescription?: Maybe<StringNullableFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeFilter>;
  newPlanId?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableFilter>;
  pointOfContactId?: Maybe<StringNullableFilter>;
  relatedInvoiceId?: Maybe<StringNullableFilter>;
  requesterId?: Maybe<StringNullableFilter>;
  responderId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumSupportEventStatusFilter>;
  transferFromPetId?: Maybe<StringNullableFilter>;
  transferToPetId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  zendeskTicketUrl?: Maybe<StringNullableFilter>;
};

export type EnrollmentSupportEventScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventScalarWhereWithAggregatesInput>>;
  amount?: Maybe<FloatNullableWithAggregatesFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableWithAggregatesFilter>;
  carePlanEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  manageMembershipDescription?: Maybe<StringNullableWithAggregatesFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeWithAggregatesFilter>;
  newPlanId?: Maybe<StringNullableWithAggregatesFilter>;
  notes?: Maybe<StringNullableWithAggregatesFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableWithAggregatesFilter>;
  pointOfContactId?: Maybe<StringNullableWithAggregatesFilter>;
  relatedInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  requesterId?: Maybe<StringNullableWithAggregatesFilter>;
  responderId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumSupportEventStatusWithAggregatesFilter>;
  transferFromPetId?: Maybe<StringNullableWithAggregatesFilter>;
  transferToPetId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  zendeskTicketUrl?: Maybe<StringNullableWithAggregatesFilter>;
};

export type EnrollmentSupportEventSumAggregateOutputType = {
  __typename?: 'EnrollmentSupportEventSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type EnrollmentSupportEventSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type EnrollmentSupportEventUncheckedCreateInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutCarePlanEnrollmentInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutCurrentPlanInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutNewPlanInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutRequesterInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutResponderInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType: ManageMembershipRequestType;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRequestedEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutResponderEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetEnrollmentSupportEventInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetEnrollmentSupportEventInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput>>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutResponderInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferToPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlanId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlanId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContactId?: Maybe<Scalars['String']>;
  relatedInvoiceId?: Maybe<Scalars['String']>;
  requesterId?: Maybe<Scalars['String']>;
  responderId?: Maybe<Scalars['String']>;
  status?: Maybe<SupportEventStatus>;
  transferFromPetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  status?: Maybe<SupportEventStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutCurrentPlanEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutNewPlanEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutRequestedEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutResponderEnrollmentSupportEventsInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetEnrollmentSupportEventInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput = {
  data: EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetEnrollmentSupportEventInput;
  where: EnrollmentSupportEventScalarWhereInput;
};

export type EnrollmentSupportEventUpdateManyWithoutCarePlanEnrollmentNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCarePlanEnrollmentInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCarePlanEnrollmentInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCarePlanEnrollmentInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCarePlanEnrollmentInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutCurrentPlanNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutCurrentPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutCurrentPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyCurrentPlanInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutCurrentPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutNewPlanNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutNewPlanInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutNewPlanInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyNewPlanInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutNewPlanInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutPointOfContactInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutPointOfContactInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyPointOfContactInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutPointOfContactInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRelatedInvoiceInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRelatedInvoiceInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRelatedInvoiceInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRelatedInvoiceInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutRequesterInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutRequesterInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyRequesterInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutRequesterInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutResponderNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutResponderInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutResponderInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyResponderInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutResponderInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferFromPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferFromPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferFromPetInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferFromPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput>>;
};

export type EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput = {
  connect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EnrollmentSupportEventCreateOrConnectWithoutTransferToPetInput>>;
  create?: Maybe<Array<EnrollmentSupportEventCreateWithoutTransferToPetInput>>;
  createMany?: Maybe<EnrollmentSupportEventCreateManyTransferToPetInputEnvelope>;
  delete?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EnrollmentSupportEventScalarWhereInput>>;
  disconnect?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  set?: Maybe<Array<EnrollmentSupportEventWhereUniqueInput>>;
  update?: Maybe<Array<EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput>>;
  updateMany?: Maybe<Array<EnrollmentSupportEventUpdateManyWithWhereWithoutTransferToPetInput>>;
  upsert?: Maybe<Array<EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput>>;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutCurrentPlanInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutNewPlanInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutPointOfContactInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutRelatedInvoiceInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutRequesterInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutResponderInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutResponderInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferFromPetInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithWhereUniqueWithoutTransferToPetInput = {
  data: EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpdateWithoutCarePlanEnrollmentInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutCurrentPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutNewPlanInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutPointOfContactInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutRelatedInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutRequesterInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutResponderInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutTransferFromPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferToPet?: Maybe<OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpdateWithoutTransferToPetInput = {
  amount?: Maybe<Scalars['Float']>;
  cancelReason?: Maybe<CancelReasonType>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentUpdateOneRequiredWithoutEnrollmentSupportEventsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentPlan?: Maybe<CarePlanUpdateOneWithoutCurrentPlanEnrollmentSupportEventsNestedInput>;
  id?: Maybe<Scalars['String']>;
  manageMembershipDescription?: Maybe<Scalars['String']>;
  manageMembershipRequestType?: Maybe<ManageMembershipRequestType>;
  newPlan?: Maybe<CarePlanUpdateOneWithoutNewPlanEnrollmentSupportEventsNestedInput>;
  notes?: Maybe<Scalars['String']>;
  paymentSchedule?: Maybe<PaymentSchedule>;
  pointOfContact?: Maybe<UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput>;
  relatedInvoice?: Maybe<StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput>;
  requester?: Maybe<UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput>;
  responder?: Maybe<UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput>;
  status?: Maybe<SupportEventStatus>;
  transferFromPet?: Maybe<OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zendeskTicketUrl?: Maybe<Scalars['String']>;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutCarePlanEnrollmentInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutCarePlanEnrollmentInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutCarePlanEnrollmentInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutCurrentPlanInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutCurrentPlanInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutCurrentPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutNewPlanInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutNewPlanInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutNewPlanInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutPointOfContactInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutPointOfContactInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutPointOfContactInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutRelatedInvoiceInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutRelatedInvoiceInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutRelatedInvoiceInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutRequesterInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutRequesterInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutRequesterInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutResponderInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutResponderInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutResponderInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferFromPetInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferFromPetInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutTransferFromPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventUpsertWithWhereUniqueWithoutTransferToPetInput = {
  create: EnrollmentSupportEventUncheckedCreateWithoutTransferToPetInput;
  update: EnrollmentSupportEventUncheckedUpdateWithoutTransferToPetInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};

export type EnrollmentSupportEventWhereInput = {
  AND?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  NOT?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  OR?: Maybe<Array<EnrollmentSupportEventWhereInput>>;
  amount?: Maybe<FloatNullableFilter>;
  cancelReason?: Maybe<EnumCancelReasonTypeNullableFilter>;
  carePlanEnrollment?: Maybe<CarePlanEnrollmentWhereInput>;
  carePlanEnrollmentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentPlan?: Maybe<CarePlanWhereInput>;
  currentPlanId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  manageMembershipDescription?: Maybe<StringNullableFilter>;
  manageMembershipRequestType?: Maybe<EnumManageMembershipRequestTypeFilter>;
  newPlan?: Maybe<CarePlanWhereInput>;
  newPlanId?: Maybe<StringNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  paymentSchedule?: Maybe<EnumPaymentScheduleNullableFilter>;
  pointOfContact?: Maybe<UserWhereInput>;
  pointOfContactId?: Maybe<StringNullableFilter>;
  relatedInvoice?: Maybe<StripeInvoiceWhereInput>;
  relatedInvoiceId?: Maybe<StringNullableFilter>;
  requester?: Maybe<UserWhereInput>;
  requesterId?: Maybe<StringNullableFilter>;
  responder?: Maybe<UserWhereInput>;
  responderId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumSupportEventStatusFilter>;
  transferFromPet?: Maybe<OrganizationPetWhereInput>;
  transferFromPetId?: Maybe<StringNullableFilter>;
  transferToPet?: Maybe<OrganizationPetWhereInput>;
  transferToPetId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  zendeskTicketUrl?: Maybe<StringNullableFilter>;
};

export type EnrollmentSupportEventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EnsurePetsPromotedInput = {
  clinicPetParentId: Scalars['String'];
};

export type EnsurePetsPromotedResult = {
  __typename?: 'EnsurePetsPromotedResult';
  success: Scalars['Boolean'];
};

export type EnumAmericanKennelClubBreedGroupNullableFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
};

export type EnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
};

export type EnumAmericanKennelClubBreedTemperamentNullableListFilter = {
  equals?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  has?: Maybe<AmericanKennelClubBreedTemperament>;
  hasEvery?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  hasSome?: Maybe<Array<AmericanKennelClubBreedTemperament>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumAmericanKennelClubDemeanorCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
};

export type EnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
};

export type EnumAmericanKennelClubEnergyLevelCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
};

export type EnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
};

export type EnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
};

export type EnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
};

export type EnumAmericanKennelClubSheddingCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
};

export type EnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
};

export type EnumAmericanKennelClubTrainabilityCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
};

export type EnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
};

export type EnumAppointmentDateRangeTypeFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentDateRangeType>;
};

export type EnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
};

export type EnumAppointmentDateRangeTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
};

export type EnumAppointmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentStatus>;
};

export type EnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type EnumAppointmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type EnumAppointmentTypeVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<AppointmentTypeVisibility>;
};

export type EnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
};

export type EnumAppointmentTypeVisibilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
};

export type EnumAutoResponseSuggestionStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutoResponseSuggestionStatus>;
};

export type EnumAutoResponseSuggestionStatusFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
};

export type EnumAutoResponseSuggestionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  _min?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
};

export type EnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
};

export type EnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
};

export type EnumAutomationRunActionPromptTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionPromptType>;
};

export type EnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
};

export type EnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
};

export type EnumAutomationRunActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunActionType>;
};

export type EnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
};

export type EnumAutomationRunActionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
};

export type EnumAutomationRunStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunStatus>;
};

export type EnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
};

export type EnumAutomationRunStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
};

export type EnumAutomationRunTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomationRunType>;
};

export type EnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
  notIn?: Maybe<Array<AutomationRunType>>;
};

export type EnumAutomationRunTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunType>>;
};

export type EnumAutomationStatusFieldUpdateOperationsInput = {
  set?: Maybe<AutomationStatus>;
};

export type EnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
  notIn?: Maybe<Array<AutomationStatus>>;
};

export type EnumAutomationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationStatus>>;
};

export type EnumBitwerxHealthcheckRunStatusNullableFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
};

export type EnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
};

export type EnumBitwerxHealthcheckTypeFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxHealthcheckType>;
};

export type EnumBitwerxHealthcheckTypeFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
};

export type EnumBitwerxHealthcheckTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
};

export type EnumBitwerxPaymentEndpointFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxPaymentEndpoint>;
};

export type EnumBitwerxPaymentEndpointFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
};

export type EnumBitwerxPaymentEndpointWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  _min?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
};

export type EnumBitwerxSystemFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxSystem>;
};

export type EnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
  notIn?: Maybe<Array<BitwerxSystem>>;
};

export type EnumBitwerxSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxSystem>>;
};

export type EnumBoardFilterColumnTypeFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterColumnType>;
};

export type EnumBoardFilterColumnTypeFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
};

export type EnumBoardFilterColumnTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  _min?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
};

export type EnumBoardFilterDateRangeNullableFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
};

export type EnumBoardFilterDateRangeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  _min?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
};

export type EnumBoardFilterViewFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterView>;
};

export type EnumBoardFilterViewFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewFilter>;
  notIn?: Maybe<Array<BoardFilterView>>;
};

export type EnumBoardFilterViewWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBoardFilterViewFilter>;
  _min?: Maybe<NestedEnumBoardFilterViewFilter>;
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterView>>;
};

export type EnumCallHistoryTypeFieldUpdateOperationsInput = {
  set?: Maybe<CallHistoryType>;
};

export type EnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
  notIn?: Maybe<Array<CallHistoryType>>;
};

export type EnumCallHistoryTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CallHistoryType>>;
};

export type EnumCallProviderFieldUpdateOperationsInput = {
  set?: Maybe<CallProvider>;
};

export type EnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
  notIn?: Maybe<Array<CallProvider>>;
};

export type EnumCallProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<CallProvider>>;
};

export type EnumCallStatusFieldUpdateOperationsInput = {
  set?: Maybe<CallStatus>;
};

export type EnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
  notIn?: Maybe<Array<CallStatus>>;
};

export type EnumCallStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CallStatus>>;
};

export type EnumCampaignRegistryBrandIdentityStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
};

export type EnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
};

export type EnumCampaignRegistryCampaignSharingStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
};

export type EnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
};

export type EnumCancelReasonTypeNullableFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  notIn?: Maybe<Array<CancelReasonType>>;
};

export type EnumCancelReasonTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  _min?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CancelReasonType>>;
};

export type EnumCanonicalDataSourceFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalDataSource>;
};

export type EnumCanonicalDataSourceFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
};

export type EnumCanonicalDataSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  _min?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
};

export type EnumCanonicalServiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalServiceStatus>;
};

export type EnumCanonicalServiceStatusFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
};

export type EnumCanonicalServiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
};

export type EnumCanonicalServiceTypeFieldUpdateOperationsInput = {
  set?: Maybe<CanonicalServiceType>;
};

export type EnumCanonicalServiceTypeFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
};

export type EnumCanonicalServiceTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
};

export type EnumCardBrandFieldUpdateOperationsInput = {
  set?: Maybe<CardBrand>;
};

export type EnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
  notIn?: Maybe<Array<CardBrand>>;
};

export type EnumCardBrandWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  notIn?: Maybe<Array<CardBrand>>;
};

export type EnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
  notIn?: Maybe<Array<CardFunding>>;
};

export type EnumCardFundingNullableListFilter = {
  equals?: Maybe<Array<CardFunding>>;
  has?: Maybe<CardFunding>;
  hasEvery?: Maybe<Array<CardFunding>>;
  hasSome?: Maybe<Array<CardFunding>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumCardFundingNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CardFunding>>;
};

export type EnumCareBenefitTypeFieldUpdateOperationsInput = {
  set?: Maybe<CareBenefitType>;
};

export type EnumCareBenefitTypeFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeFilter>;
  notIn?: Maybe<Array<CareBenefitType>>;
};

export type EnumCareBenefitTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareBenefitTypeFilter>;
  _min?: Maybe<NestedEnumCareBenefitTypeFilter>;
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CareBenefitType>>;
};

export type EnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
};

export type EnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
};

export type EnumCareLapsedPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareLapsedPaymentStatus>;
};

export type EnumCareLapsedPaymentStatusFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
};

export type EnumCareLapsedPaymentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  _min?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
};

export type EnumCarePlanCycleTypeFieldUpdateOperationsInput = {
  set?: Maybe<CarePlanCycleType>;
};

export type EnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
};

export type EnumCarePlanCycleTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
};

export type EnumCareProgramLaunchStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareProgramLaunchStatus>;
};

export type EnumCareProgramLaunchStatusFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
};

export type EnumCareProgramLaunchStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  _min?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
};

export type EnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
};

export type EnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
};

export type EnumCareSubscriptionTrueUpStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionTrueUpStatus>;
};

export type EnumCareSubscriptionTrueUpStatusFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
};

export type EnumCareSubscriptionTrueUpStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
};

export type EnumCareSubscriptionTrueUpTypeFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionTrueUpType>;
};

export type EnumCareSubscriptionTrueUpTypeFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
};

export type EnumCareSubscriptionTrueUpTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
};

export type EnumChannelCreationSourceTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelCreationSourceType>;
};

export type EnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
};

export type EnumChannelCreationSourceTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
};

export type EnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
};

export type EnumChannelMessageAttachmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentType>;
};

export type EnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
};

export type EnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
};

export type EnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
};

export type EnumChannelStatusActionFieldUpdateOperationsInput = {
  set?: Maybe<ChannelStatusAction>;
};

export type EnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
};

export type EnumChannelStatusActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
};

export type EnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
  notIn?: Maybe<Array<ChannelType>>;
};

export type EnumChannelTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelType>>;
};

export type EnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  notIn?: Maybe<Array<ChannelVisibility>>;
};

export type EnumChannelVisibilityNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelVisibility>>;
};

export type EnumChargeCaptureTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChargeCaptureType>;
};

export type EnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
};

export type EnumChargeCaptureTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
};

export type EnumClientConnectClinicSoftwareFieldUpdateOperationsInput = {
  set?: Maybe<ClientConnectClinicSoftware>;
};

export type EnumClientConnectClinicSoftwareFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
};

export type EnumClientConnectClinicSoftwareWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  _min?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareWithAggregatesFilter>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
};

export type EnumClientConnectSystemFieldUpdateOperationsInput = {
  set?: Maybe<ClientConnectSystem>;
};

export type EnumClientConnectSystemFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemFilter>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
};

export type EnumClientConnectSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClientConnectSystemFilter>;
  _min?: Maybe<NestedEnumClientConnectSystemFilter>;
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
};

export type EnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
};

export type EnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
};

export type EnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
};

export type EnumClinicOfficeHoursTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicOfficeHoursType>;
};

export type EnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
};

export type EnumClinicOfficeHoursTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
};

export type EnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
};

export type EnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
};

export type EnumClinicPimsIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPimsIntegrationType>;
};

export type EnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
};

export type EnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
};

export type EnumConditionAttributeFieldUpdateOperationsInput = {
  set?: Maybe<ConditionAttribute>;
};

export type EnumConditionAttributeFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeFilter>;
  notIn?: Maybe<Array<ConditionAttribute>>;
};

export type EnumConditionAttributeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionAttributeFilter>;
  _min?: Maybe<NestedEnumConditionAttributeFilter>;
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionAttribute>>;
};

export type EnumConditionEvaluationStatusFieldUpdateOperationsInput = {
  set?: Maybe<ConditionEvaluationStatus>;
};

export type EnumConditionEvaluationStatusFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
};

export type EnumConditionEvaluationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  _min?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
};

export type EnumConditionOperatorFieldUpdateOperationsInput = {
  set?: Maybe<ConditionOperator>;
};

export type EnumConditionOperatorFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorFilter>;
  notIn?: Maybe<Array<ConditionOperator>>;
};

export type EnumConditionOperatorWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionOperatorFilter>;
  _min?: Maybe<NestedEnumConditionOperatorFilter>;
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionOperator>>;
};

export type EnumConditionSetOperatorFieldUpdateOperationsInput = {
  set?: Maybe<ConditionSetOperator>;
};

export type EnumConditionSetOperatorFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorFilter>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
};

export type EnumConditionSetOperatorWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionSetOperatorFilter>;
  _min?: Maybe<NestedEnumConditionSetOperatorFilter>;
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
};

export type EnumConditionalEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ConditionalEntityType>;
};

export type EnumConditionalEntityTypeFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
};

export type EnumConditionalEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  _min?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
};

export type EnumContactEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContactEntityType>;
};

export type EnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
  notIn?: Maybe<Array<ContactEntityType>>;
};

export type EnumContactEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ContactEntityType>>;
};

export type EnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
};

export type EnumContactVerificationMethodNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
};

export type EnumContextEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ContextEntityType>;
};

export type EnumContextEntityTypeFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeFilter>;
  notIn?: Maybe<Array<ContextEntityType>>;
};

export type EnumContextEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumContextEntityTypeFilter>;
  _min?: Maybe<NestedEnumContextEntityTypeFilter>;
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ContextEntityType>>;
};

export type EnumCurrencyFieldUpdateOperationsInput = {
  set?: Maybe<Currency>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type EnumCurrencyWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type EnumCustomFieldDesignationFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldDesignation>;
};

export type EnumCustomFieldDesignationFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
};

export type EnumCustomFieldDesignationWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  _min?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
};

export type EnumCustomFieldNormalizedDataTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldNormalizedDataType>;
};

export type EnumCustomFieldNormalizedDataTypeFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
};

export type EnumCustomFieldNormalizedDataTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
};

export type EnumCustomFieldPimsSyncTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldPimsSyncType>;
};

export type EnumCustomFieldPimsSyncTypeFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
};

export type EnumCustomFieldPimsSyncTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
};

export type EnumCustomFieldTargetTypeFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldTargetType>;
};

export type EnumCustomFieldTargetTypeFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
};

export type EnumCustomFieldTargetTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
};

export type EnumCustomFieldsDataSourceNullableFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
};

export type EnumCustomFieldsDataSourceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  _min?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
};

export type EnumDeviceOsNullableFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableFilter>;
  notIn?: Maybe<Array<DeviceOs>>;
};

export type EnumDeviceOsNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumDeviceOsNullableFilter>;
  _min?: Maybe<NestedEnumDeviceOsNullableFilter>;
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<DeviceOs>>;
};

export type EnumDeviceTypeNullableFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  notIn?: Maybe<Array<DeviceType>>;
};

export type EnumDeviceTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  _min?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<DeviceType>>;
};

export type EnumDirectBookingAppointmentTypeClientTypeFieldUpdateOperationsInput = {
  set?: Maybe<DirectBookingAppointmentTypeClientType>;
};

export type EnumDirectBookingAppointmentTypeClientTypeFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
};

export type EnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  _min?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
};

export type EnumDirectBookingSpeciesOptionFieldUpdateOperationsInput = {
  set?: Maybe<DirectBookingSpeciesOption>;
};

export type EnumDirectBookingSpeciesOptionFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
};

export type EnumDirectBookingSpeciesOptionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  _min?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
};

export type EnumDocumentationCategoryFieldUpdateOperationsInput = {
  set?: Maybe<DocumentationCategory>;
};

export type EnumDocumentationCategoryFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryFilter>;
  notIn?: Maybe<Array<DocumentationCategory>>;
};

export type EnumDocumentationCategoryWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDocumentationCategoryFilter>;
  _min?: Maybe<NestedEnumDocumentationCategoryFilter>;
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryWithAggregatesFilter>;
  notIn?: Maybe<Array<DocumentationCategory>>;
};

export type EnumDocumentationProviderFieldUpdateOperationsInput = {
  set?: Maybe<DocumentationProvider>;
};

export type EnumDocumentationProviderFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderFilter>;
  notIn?: Maybe<Array<DocumentationProvider>>;
};

export type EnumDocumentationProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDocumentationProviderFilter>;
  _min?: Maybe<NestedEnumDocumentationProviderFilter>;
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<DocumentationProvider>>;
};

export type EnumEmailCampaignCustomListRecipientStatusFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignCustomListRecipientStatus>;
};

export type EnumEmailCampaignCustomListRecipientStatusFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
};

export type EnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  _min?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
};

export type EnumEmailCampaignLogActionFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignLogAction>;
};

export type EnumEmailCampaignLogActionFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
};

export type EnumEmailCampaignLogActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
};

export type EnumEmailCampaignLogSkipReasonNullableFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
};

export type EnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
};

export type EnumEmailCampaignSegmentationTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignSegmentationType>;
};

export type EnumEmailCampaignSegmentationTypeFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
};

export type EnumEmailCampaignSegmentationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  _min?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
};

export type EnumEmailCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignStatus>;
};

export type EnumEmailCampaignStatusFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
};

export type EnumEmailCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  _min?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
};

export type EnumEmailCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignType>;
};

export type EnumEmailCampaignTypeFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  notIn?: Maybe<Array<EmailCampaignType>>;
};

export type EnumEmailCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  _min?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignType>>;
};

export type EnumEmailCampaignUnsubscribeSourceFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignUnsubscribeSource>;
};

export type EnumEmailCampaignUnsubscribeSourceFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
};

export type EnumEmailCampaignUnsubscribeSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  _min?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
};

export type EnumEmailProviderFieldUpdateOperationsInput = {
  set?: Maybe<EmailProvider>;
};

export type EnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
  notIn?: Maybe<Array<EmailProvider>>;
};

export type EnumEmailProviderLogEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailProviderLogEntityType>;
};

export type EnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
};

export type EnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
};

export type EnumEmailProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailProvider>>;
};

export type EnumEmailTemplateTypeFieldUpdateOperationsInput = {
  set?: Maybe<EmailTemplateType>;
};

export type EnumEmailTemplateTypeFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  notIn?: Maybe<Array<EmailTemplateType>>;
};

export type EnumEmailTemplateTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  _min?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailTemplateType>>;
};

export type EnumEnrollmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<EnrollmentStatus>;
};

export type EnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
};

export type EnumEnrollmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
};

export type EnumFeatureFlagStateFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagState>;
};

export type EnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
  notIn?: Maybe<Array<FeatureFlagState>>;
};

export type EnumFeatureFlagStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  notIn?: Maybe<Array<FeatureFlagState>>;
};

export type EnumFeatureFlagTypeFieldUpdateOperationsInput = {
  set?: Maybe<FeatureFlagType>;
};

export type EnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  notIn?: Maybe<Array<FeatureFlagType>>;
};

export type EnumFeatureFlagTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FeatureFlagType>>;
};

export type EnumFeeCollectionFieldUpdateOperationsInput = {
  set?: Maybe<FeeCollection>;
};

export type EnumFeeCollectionFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionFilter>;
  notIn?: Maybe<Array<FeeCollection>>;
};

export type EnumFeeCollectionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeeCollectionFilter>;
  _min?: Maybe<NestedEnumFeeCollectionFilter>;
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionWithAggregatesFilter>;
  notIn?: Maybe<Array<FeeCollection>>;
};

export type EnumFilterSelectionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FilterSelectionType>;
};

export type EnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  notIn?: Maybe<Array<FilterSelectionType>>;
};

export type EnumFilterSelectionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FilterSelectionType>>;
};

export type EnumFinancialTransactionTypeFieldUpdateOperationsInput = {
  set?: Maybe<FinancialTransactionType>;
};

export type EnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
};

export type EnumFinancialTransactionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
};

export type EnumFormTemplateTypeFieldUpdateOperationsInput = {
  set?: Maybe<FormTemplateType>;
};

export type EnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
  notIn?: Maybe<Array<FormTemplateType>>;
};

export type EnumFormTemplateTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FormTemplateType>>;
};

export type EnumHillsToHomeResultTypeFieldUpdateOperationsInput = {
  set?: Maybe<HillsToHomeResultType>;
};

export type EnumHillsToHomeResultTypeFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
};

export type EnumHillsToHomeResultTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  _min?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
};

export type EnumHillsToHomeSpeciesNullableFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
};

export type EnumHillsToHomeSpeciesNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  _min?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
};

export type EnumInformAudienceDefinitionStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformAudienceDefinitionStatus>;
};

export type EnumInformAudienceDefinitionStatusFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
};

export type EnumInformAudienceDefinitionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
};

export type EnumInformAudienceDefinitionTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformAudienceDefinitionType>;
};

export type EnumInformAudienceDefinitionTypeFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
};

export type EnumInformAudienceDefinitionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
};

export type EnumInformCampaignExecutionStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignExecutionStatus>;
};

export type EnumInformCampaignExecutionStatusFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
};

export type EnumInformCampaignExecutionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  _min?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
};

export type EnumInformCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignStatus>;
};

export type EnumInformCampaignStatusFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusFilter>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
};

export type EnumInformCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignStatusFilter>;
  _min?: Maybe<NestedEnumInformCampaignStatusFilter>;
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
};

export type EnumInformCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformCampaignType>;
};

export type EnumInformCampaignTypeFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeFilter>;
  notIn?: Maybe<Array<InformCampaignType>>;
};

export type EnumInformCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignTypeFilter>;
  _min?: Maybe<NestedEnumInformCampaignTypeFilter>;
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignType>>;
};

export type EnumInformControlGroupEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformControlGroupEntityType>;
};

export type EnumInformControlGroupEntityTypeFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
};

export type EnumInformControlGroupEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  _min?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
};

export type EnumInformEmailTemplateStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformEmailTemplateStatus>;
};

export type EnumInformEmailTemplateStatusFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
};

export type EnumInformEmailTemplateStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  _min?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
};

export type EnumInformEnrollmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformEnrollmentStatus>;
};

export type EnumInformEnrollmentStatusFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
};

export type EnumInformEnrollmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  _min?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderName>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
};

export type EnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
};

export type EnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerCustomerPharmacyProviderType>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
};

export type EnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
};

export type EnumInformPartnerIntegrationModelNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationModelName>;
};

export type EnumInformPartnerIntegrationModelNameFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
};

export type EnumInformPartnerIntegrationModelNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
};

export type EnumInformPartnerIntegrationModelStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationModelStatus>;
};

export type EnumInformPartnerIntegrationModelStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
};

export type EnumInformPartnerIntegrationModelStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
};

export type EnumInformPartnerIntegrationStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerIntegrationStatus>;
};

export type EnumInformPartnerIntegrationStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
};

export type EnumInformPartnerIntegrationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
};

export type EnumInformPartnerProgramStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerProgramStatus>;
};

export type EnumInformPartnerProgramStatusFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
};

export type EnumInformPartnerProgramStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
};

export type EnumInformPartnerStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerStatus>;
};

export type EnumInformPartnerStatusFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusFilter>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
};

export type EnumInformPartnerStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerStatusFilter>;
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
};

export type EnumInformPartnerUserRoleNameFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserRoleName>;
};

export type EnumInformPartnerUserRoleNameFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
};

export type EnumInformPartnerUserRoleNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
};

export type EnumInformPartnerUserRoleStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserRoleStatus>;
};

export type EnumInformPartnerUserRoleStatusFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
};

export type EnumInformPartnerUserRoleStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
};

export type EnumInformPartnerUserStatusFieldUpdateOperationsInput = {
  set?: Maybe<InformPartnerUserStatus>;
};

export type EnumInformPartnerUserStatusFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
};

export type EnumInformPartnerUserStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
};

export type EnumInformScheduledCampaignCadenceNullableFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
};

export type EnumInformScheduledCampaignCadenceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  _min?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
};

export type EnumInformScheduledCampaignCommunicationChannelNullableListFilter = {
  equals?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  has?: Maybe<InformScheduledCampaignCommunicationChannel>;
  hasEvery?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  hasSome?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumInsuranceIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<InsuranceIntegrationType>;
};

export type EnumInsuranceIntegrationTypeFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
};

export type EnumInsuranceIntegrationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  _min?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
};

export type EnumIntegrationStatusNullableFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  notIn?: Maybe<Array<IntegrationStatus>>;
};

export type EnumIntegrationStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<IntegrationStatus>>;
};

export type EnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  notIn?: Maybe<Array<IntegrationType>>;
};

export type EnumIntegrationTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<IntegrationType>>;
};

export type EnumInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceLineItemType>;
};

export type EnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
};

export type EnumInvoiceLineItemTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
};

export type EnumInvoicePimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<InvoicePimsWritebackAction>;
};

export type EnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
};

export type EnumInvoicePimsWritebackActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
};

export type EnumInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceStatus>;
};

export type EnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
  notIn?: Maybe<Array<InvoiceStatus>>;
};

export type EnumInvoiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoiceStatus>>;
};

export type EnumLegalEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<LegalEntityType>;
};

export type EnumLegalEntityTypeFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeFilter>;
  notIn?: Maybe<Array<LegalEntityType>>;
};

export type EnumLegalEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLegalEntityTypeFilter>;
  _min?: Maybe<NestedEnumLegalEntityTypeFilter>;
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<LegalEntityType>>;
};

export type EnumLoyaltyAccountMergerStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyAccountMergerStatus>;
};

export type EnumLoyaltyAccountMergerStatusFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
};

export type EnumLoyaltyAccountMergerStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
};

export type EnumLoyaltyPointDeltaSourceFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyPointDeltaSource>;
};

export type EnumLoyaltyPointDeltaSourceFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
};

export type EnumLoyaltyPointDeltaSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  _min?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
};

export type EnumLoyaltyProgramStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyProgramStatus>;
};

export type EnumLoyaltyProgramStatusFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
};

export type EnumLoyaltyProgramStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
};

export type EnumLoyaltyRewardAvailabilityFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardAvailability>;
};

export type EnumLoyaltyRewardAvailabilityFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
};

export type EnumLoyaltyRewardAvailabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
};

export type EnumLoyaltyRewardRedemptionActorTypeFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardRedemptionActorType>;
};

export type EnumLoyaltyRewardRedemptionActorTypeFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
};

export type EnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
};

export type EnumLoyaltyRewardRedemptionStatusFieldUpdateOperationsInput = {
  set?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type EnumLoyaltyRewardRedemptionStatusFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
};

export type EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
};

export type EnumManageMembershipRequestTypeFieldUpdateOperationsInput = {
  set?: Maybe<ManageMembershipRequestType>;
};

export type EnumManageMembershipRequestTypeFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
};

export type EnumManageMembershipRequestTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  _min?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
};

export type EnumMassTextAlertStatusFieldUpdateOperationsInput = {
  set?: Maybe<MassTextAlertStatus>;
};

export type EnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
};

export type EnumMassTextAlertStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
};

export type EnumMediaTypeFieldUpdateOperationsInput = {
  set?: Maybe<MediaType>;
};

export type EnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
  notIn?: Maybe<Array<MediaType>>;
};

export type EnumMediaTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMediaTypeFilter>;
  _min?: Maybe<NestedEnumMediaTypeFilter>;
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MediaType>>;
};

export type EnumMediaVersionFieldUpdateOperationsInput = {
  set?: Maybe<MediaVersion>;
};

export type EnumMediaVersionFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionFilter>;
  notIn?: Maybe<Array<MediaVersion>>;
};

export type EnumMediaVersionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMediaVersionFilter>;
  _min?: Maybe<NestedEnumMediaVersionFilter>;
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionWithAggregatesFilter>;
  notIn?: Maybe<Array<MediaVersion>>;
};

export type EnumMessageSourceFieldUpdateOperationsInput = {
  set?: Maybe<MessageSource>;
};

export type EnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
  notIn?: Maybe<Array<MessageSource>>;
};

export type EnumMessageSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<MessageSource>>;
};

export type EnumMessageTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessageType>;
};

export type EnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
  notIn?: Maybe<Array<MessageType>>;
};

export type EnumMessageTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MessageType>>;
};

export type EnumMessagingCampaignStatusFieldUpdateOperationsInput = {
  set?: Maybe<MessagingCampaignStatus>;
};

export type EnumMessagingCampaignStatusFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
};

export type EnumMessagingCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
};

export type EnumMessagingCampaignTypeFieldUpdateOperationsInput = {
  set?: Maybe<MessagingCampaignType>;
};

export type EnumMessagingCampaignTypeFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
};

export type EnumMessagingCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
};

export type EnumMissedCallMessageResponseStatusFieldUpdateOperationsInput = {
  set?: Maybe<MissedCallMessageResponseStatus>;
};

export type EnumMissedCallMessageResponseStatusFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
};

export type EnumMissedCallMessageResponseStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  _min?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
};

export type EnumNormalizedWritebackErrorNullableFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
};

export type EnumNormalizedWritebackErrorNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  _min?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
};

export type EnumNotificationEventFieldUpdateOperationsInput = {
  set?: Maybe<NotificationEvent>;
};

export type EnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
  notIn?: Maybe<Array<NotificationEvent>>;
};

export type EnumNotificationEventWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  notIn?: Maybe<Array<NotificationEvent>>;
};

export type EnumNotificationSettingTypeFieldUpdateOperationsInput = {
  set?: Maybe<NotificationSettingType>;
};

export type EnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  notIn?: Maybe<Array<NotificationSettingType>>;
};

export type EnumNotificationSettingTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<NotificationSettingType>>;
};

export type EnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  notIn?: Maybe<Array<PaymentMedium>>;
};

export type EnumPaymentMediumNullableListFilter = {
  equals?: Maybe<Array<PaymentMedium>>;
  has?: Maybe<PaymentMedium>;
  hasEvery?: Maybe<Array<PaymentMedium>>;
  hasSome?: Maybe<Array<PaymentMedium>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumPaymentMediumNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentMedium>>;
};

export type EnumPaymentPimsWritebackActionFieldUpdateOperationsInput = {
  set?: Maybe<PaymentPimsWritebackAction>;
};

export type EnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
};

export type EnumPaymentPimsWritebackActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
};

export type EnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  notIn?: Maybe<Array<PaymentRelationship>>;
};

export type EnumPaymentRelationshipNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentRelationship>>;
};

export type EnumPaymentScheduleNullableFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  notIn?: Maybe<Array<PaymentSchedule>>;
};

export type EnumPaymentScheduleNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  _min?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentSchedule>>;
};

export type EnumPaymentSourceFieldUpdateOperationsInput = {
  set?: Maybe<PaymentSource>;
};

export type EnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
  notIn?: Maybe<Array<PaymentSource>>;
};

export type EnumPaymentSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentSource>>;
};

export type EnumPermissionTypeFieldUpdateOperationsInput = {
  set?: Maybe<PermissionType>;
};

export type EnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
  notIn?: Maybe<Array<PermissionType>>;
};

export type EnumPermissionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PermissionType>>;
};

export type EnumPhoneNumberLookupRequestStatusFieldUpdateOperationsInput = {
  set?: Maybe<PhoneNumberLookupRequestStatus>;
};

export type EnumPhoneNumberLookupRequestStatusFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
};

export type EnumPhoneNumberLookupRequestStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  _min?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
};

export type EnumPhoneNumberStatusFieldUpdateOperationsInput = {
  set?: Maybe<PhoneNumberStatus>;
};

export type EnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
};

export type EnumPhoneNumberStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
};

export type EnumPimsIntegrationCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<PimsIntegrationCapability>;
};

export type EnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
};

export type EnumPimsIntegrationCapabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
};

export type EnumPimsInvoiceLineItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsInvoiceLineItemType>;
};

export type EnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
};

export type EnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
};

export type EnumPimsSyncFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncFrequency>;
};

export type EnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
};

export type EnumPimsSyncFrequencyWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
};

export type EnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
};

export type EnumPimsSyncStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
};

export type EnumPimsWritebackStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackStatus>;
};

export type EnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
};

export type EnumPimsWritebackStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
};

export type EnumPimsWritebackTypeFieldUpdateOperationsInput = {
  set?: Maybe<PimsWritebackType>;
};

export type EnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  notIn?: Maybe<Array<PimsWritebackType>>;
};

export type EnumPimsWritebackTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsWritebackType>>;
};

export type EnumPricingModelFieldUpdateOperationsInput = {
  set?: Maybe<PricingModel>;
};

export type EnumPricingModelFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelFilter>;
  notIn?: Maybe<Array<PricingModel>>;
};

export type EnumPricingModelWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPricingModelFilter>;
  _min?: Maybe<NestedEnumPricingModelFilter>;
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelWithAggregatesFilter>;
  notIn?: Maybe<Array<PricingModel>>;
};

export type EnumProductIndicationNullableListFilter = {
  equals?: Maybe<Array<ProductIndication>>;
  has?: Maybe<ProductIndication>;
  hasEvery?: Maybe<Array<ProductIndication>>;
  hasSome?: Maybe<Array<ProductIndication>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumProductSupplyDurationUnitNullableFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
};

export type EnumProductSupplyDurationUnitNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  _min?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
};

export type EnumProductTypeFieldUpdateOperationsInput = {
  set?: Maybe<ProductType>;
};

export type EnumProductTypeFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeFilter>;
  notIn?: Maybe<Array<ProductType>>;
};

export type EnumProductTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumProductTypeFilter>;
  _min?: Maybe<NestedEnumProductTypeFilter>;
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ProductType>>;
};

export type EnumProfilePimsSyncStatusFieldUpdateOperationsInput = {
  set?: Maybe<ProfilePimsSyncStatus>;
};

export type EnumProfilePimsSyncStatusFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
};

export type EnumProfilePimsSyncStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  _min?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
};

export type EnumPushNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
};

export type EnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  _min?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
};

export type EnumPushNotificationLogStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationLogStatusType>;
};

export type EnumPushNotificationLogStatusTypeFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
};

export type EnumPushNotificationLogStatusTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  _min?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
};

export type EnumPushNotificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationStatus>;
};

export type EnumPushNotificationStatusFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusFilter>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
};

export type EnumPushNotificationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPushNotificationStatusFilter>;
  _min?: Maybe<NestedEnumPushNotificationStatusFilter>;
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
};

export type EnumRecordingDownloadStatusFieldUpdateOperationsInput = {
  set?: Maybe<RecordingDownloadStatus>;
};

export type EnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
};

export type EnumRecordingDownloadStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
};

export type EnumRefundReasonFieldUpdateOperationsInput = {
  set?: Maybe<RefundReason>;
};

export type EnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type EnumRefundReasonWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type EnumRenewalRejectionReasonNullableFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
};

export type EnumRenewalRejectionReasonNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  _min?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type EnumRoleWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type EnumRuleActionsFieldUpdateOperationsInput = {
  set?: Maybe<RuleActions>;
};

export type EnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
  notIn?: Maybe<Array<RuleActions>>;
};

export type EnumRuleActionsWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  notIn?: Maybe<Array<RuleActions>>;
};

export type EnumSegmentModelTypeFieldUpdateOperationsInput = {
  set?: Maybe<SegmentModelType>;
};

export type EnumSegmentModelTypeFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeFilter>;
  notIn?: Maybe<Array<SegmentModelType>>;
};

export type EnumSegmentModelTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSegmentModelTypeFilter>;
  _min?: Maybe<NestedEnumSegmentModelTypeFilter>;
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SegmentModelType>>;
};

export type EnumSegmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<SegmentType>;
};

export type EnumSegmentTypeFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeFilter>;
  notIn?: Maybe<Array<SegmentType>>;
};

export type EnumSegmentTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSegmentTypeFilter>;
  _min?: Maybe<NestedEnumSegmentTypeFilter>;
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SegmentType>>;
};

export type EnumServiceCapabilityFieldUpdateOperationsInput = {
  set?: Maybe<ServiceCapability>;
};

export type EnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
  notIn?: Maybe<Array<ServiceCapability>>;
};

export type EnumServiceCapabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceCapability>>;
};

export type EnumServiceProviderFieldUpdateOperationsInput = {
  set?: Maybe<ServiceProvider>;
};

export type EnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
  notIn?: Maybe<Array<ServiceProvider>>;
};

export type EnumServiceProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceProvider>>;
};

export type EnumServiceReminderNotificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderNotificationType>;
};

export type EnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
};

export type EnumServiceReminderNotificationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
};

export type EnumServiceReminderTimingOffsetUnitFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingOffsetUnit>;
};

export type EnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
};

export type EnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
};

export type EnumServiceReminderTimingTypeFieldUpdateOperationsInput = {
  set?: Maybe<ServiceReminderTimingType>;
};

export type EnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
};

export type EnumServiceReminderTimingTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
};

export type EnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  notIn?: Maybe<Array<SmsEntityType>>;
};

export type EnumSmsEntityTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsEntityType>>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
};

export type EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
};

export type EnumSmsNotificationProviderFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationProvider>;
};

export type EnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
};

export type EnumSmsNotificationProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
};

export type EnumSmsNotificationStatusTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationStatusType>;
};

export type EnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
};

export type EnumSmsNotificationStatusTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
};

export type EnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type EnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type EnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type EnumSpeciesTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type EnumSpeciesTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type EnumStateFieldUpdateOperationsInput = {
  set?: Maybe<State>;
};

export type EnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
  notIn?: Maybe<Array<State>>;
};

export type EnumStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  notIn?: Maybe<Array<State>>;
};

export type EnumStripeCollectionMethodFieldUpdateOperationsInput = {
  set?: Maybe<StripeCollectionMethod>;
};

export type EnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
};

export type EnumStripeCollectionMethodWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
};

export type EnumStripeDisputeStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeDisputeStatus>;
};

export type EnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
};

export type EnumStripeDisputeStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
};

export type EnumStripeInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeInvoiceStatus>;
};

export type EnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
};

export type EnumStripeInvoiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
};

export type EnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  notIn?: Maybe<Array<StripePaymentError>>;
};

export type EnumStripePaymentErrorNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentError>>;
};

export type EnumStripePaymentIntentStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentIntentStatus>;
};

export type EnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
};

export type EnumStripePaymentIntentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
};

export type EnumStripePaymentMethodTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentMethodType>;
};

export type EnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
};

export type EnumStripePaymentMethodTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
};

export type EnumStripePaymentTransferTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentTransferType>;
};

export type EnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
};

export type EnumStripePaymentTransferTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
};

export type EnumStripePayoutStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripePayoutStatus>;
};

export type EnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
};

export type EnumStripePayoutStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
};

export type EnumStripeReceiptMediumFieldUpdateOperationsInput = {
  set?: Maybe<StripeReceiptMedium>;
};

export type EnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
};

export type EnumStripeReceiptMediumWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
};

export type EnumStripeTerminalLocationTypeFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalLocationType>;
};

export type EnumStripeTerminalLocationTypeFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
};

export type EnumStripeTerminalLocationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  _min?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
};

export type EnumStripeTerminalModelFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalModel>;
};

export type EnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
};

export type EnumStripeTerminalModelWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
};

export type EnumStripeTerminalStatusFieldUpdateOperationsInput = {
  set?: Maybe<StripeTerminalStatus>;
};

export type EnumStripeTerminalStatusFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
};

export type EnumStripeTerminalStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  _min?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
};

export type EnumSupportEventStatusFieldUpdateOperationsInput = {
  set?: Maybe<SupportEventStatus>;
};

export type EnumSupportEventStatusFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusFilter>;
  notIn?: Maybe<Array<SupportEventStatus>>;
};

export type EnumSupportEventStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSupportEventStatusFilter>;
  _min?: Maybe<NestedEnumSupportEventStatusFilter>;
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<SupportEventStatus>>;
};

export type EnumSurveyStepNullableFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableFilter>;
  notIn?: Maybe<Array<SurveyStep>>;
};

export type EnumSurveyStepNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSurveyStepNullableFilter>;
  _min?: Maybe<NestedEnumSurveyStepNullableFilter>;
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyStep>>;
};

export type EnumSurveyTypeFieldUpdateOperationsInput = {
  set?: Maybe<SurveyType>;
};

export type EnumSurveyTypeFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type EnumSurveyTypeNullableFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type EnumSurveyTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  _min?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type EnumSurveyTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSurveyTypeFilter>;
  _min?: Maybe<NestedEnumSurveyTypeFilter>;
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type EnumTemplateAvailabilityFieldUpdateOperationsInput = {
  set?: Maybe<TemplateAvailability>;
};

export type EnumTemplateAvailabilityFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  notIn?: Maybe<Array<TemplateAvailability>>;
};

export type EnumTemplateAvailabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  _min?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<TemplateAvailability>>;
};

export type EnumTwilioRecordingStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioRecordingStatus>;
};

export type EnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
};

export type EnumTwilioRecordingStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
};

export type EnumTwilioVerificationStatusFieldUpdateOperationsInput = {
  set?: Maybe<TwilioVerificationStatus>;
};

export type EnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
};

export type EnumTwilioVerificationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
};

export type EnumUserTypeFieldUpdateOperationsInput = {
  set?: Maybe<UserType>;
};

export type EnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
  notIn?: Maybe<Array<UserType>>;
};

export type EnumUserTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<UserType>>;
};

export type EnumVetDataIntegrationSystemFieldUpdateOperationsInput = {
  set?: Maybe<VetDataIntegrationSystem>;
};

export type EnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
};

export type EnumVetDataIntegrationSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
};

export type EnumVetLicenseTypeFieldUpdateOperationsInput = {
  set?: Maybe<VetLicenseType>;
};

export type EnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
  notIn?: Maybe<Array<VetLicenseType>>;
};

export type EnumVetLicenseTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VetLicenseType>>;
};

export type EnumVetsourceCodeTagTypeFieldUpdateOperationsInput = {
  set?: Maybe<VetsourceCodeTagType>;
};

export type EnumVetsourceCodeTagTypeFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
};

export type EnumVetsourceCodeTagTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  _min?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
};

export type EnumVetsourceInstallationConnectivityStateFieldUpdateOperationsInput = {
  set?: Maybe<VetsourceInstallationConnectivityState>;
};

export type EnumVetsourceInstallationConnectivityStateFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
};

export type EnumVetsourceInstallationConnectivityStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  _min?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
};

export type EnumWidgetRequestTypeFieldUpdateOperationsInput = {
  set?: Maybe<WidgetRequestType>;
};

export type EnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  notIn?: Maybe<Array<WidgetRequestType>>;
};

export type EnumWidgetRequestTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WidgetRequestType>>;
};

export type EnumWorkflowActionTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowActionType>;
};

export type EnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  notIn?: Maybe<Array<WorkflowActionType>>;
};

export type EnumWorkflowActionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowActionType>>;
};

export type EnumWorkflowEventFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEvent>;
};

export type EnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
  notIn?: Maybe<Array<WorkflowEvent>>;
};

export type EnumWorkflowEventTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowEventTriggerType>;
};

export type EnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
};

export type EnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
};

export type EnumWorkflowEventWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowEvent>>;
};

export type EnumWorkflowSendingHoursFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowSendingHours>;
};

export type EnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
};

export type EnumWorkflowSendingHoursWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
};

export type EnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type EnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type EnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type EnumWorkflowTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type EnumWorkflowTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type EnumWorkflowVisitTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowVisitType>;
};

export type EnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
};

export type EnumWorkflowVisitTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
};

export type ExportCancellationSummaryAsPdfInput = {
  accountCredit?: Maybe<ItemInfo>;
  applyDiscounts: Scalars['Boolean'];
  balanceDue: Scalars['Float'];
  discount?: Maybe<ItemInfo>;
  exams?: Maybe<ItemInfo>;
  fees?: Maybe<ItemInfo>;
  hasAccountCredit: Scalars['Boolean'];
  hasDiscount: Scalars['Boolean'];
  hasPlanDiscount: Scalars['Boolean'];
  hasTeletriage: Scalars['Boolean'];
  includeFees: Scalars['Boolean'];
  includeManualAdjustment: Scalars['Boolean'];
  manualAdjustment?: Maybe<Scalars['Float']>;
  paymentsMade?: Maybe<PaymentsMade>;
  petName: Scalars['String'];
  plan: Scalars['String'];
  remainingMembershipBalance?: Maybe<PaymentsMade>;
  species: Scalars['String'];
  subtotal?: Maybe<ItemInfo>;
  teletriage?: Maybe<ItemInfo>;
  totalSavings: Scalars['Float'];
};

export type ExportCancellationSummaryAsPdfResponse = {
  __typename?: 'ExportCancellationSummaryAsPdfResponse';
  base64PDF?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ExportItemizedInvoiceToReceiptPdfInput = {
  invoiceId: Scalars['String'];
};

export type ExportItemizedInvoiceToReceiptPdfResponse = {
  __typename?: 'ExportItemizedInvoiceToReceiptPDFResponse';
  base64PDF?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ExportNonItemizedReceiptAsPdfInput = {
  stripePaymentIntentId: Scalars['String'];
};

export type ExportNonItemizedReceiptAsPdfResponse = {
  __typename?: 'ExportNonItemizedReceiptAsPdfResponse';
  base64PDF?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EzyVetIntegration = {
  __typename?: 'EzyVetIntegration';
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EzyVetIntegrationCountAggregateOutputType = {
  __typename?: 'EzyVetIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  client_id: Scalars['Int'];
  client_secret: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  id: Scalars['Int'];
  ownership_id: Scalars['Int'];
  scopes: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type EzyVetIntegrationCountOrderByAggregateInput = {
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EzyVetIntegrationCreateInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutEzyvetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationCreateManyInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: EzyVetIntegrationWhereUniqueInput;
};

export type EzyVetIntegrationCreateWithoutClinicPimsIntegrationInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationMaxAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMaxAggregateOutputType';
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationMaxOrderByAggregateInput = {
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EzyVetIntegrationMinAggregateOutputType = {
  __typename?: 'EzyVetIntegrationMinAggregateOutputType';
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationMinOrderByAggregateInput = {
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum EzyVetIntegrationOrderByRelevanceFieldEnum {
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Id = 'id',
  OwnershipId = 'ownership_id',
  Scopes = 'scopes'
}

export type EzyVetIntegrationOrderByRelevanceInput = {
  fields: Array<EzyVetIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type EzyVetIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<EzyVetIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<EzyVetIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<EzyVetIntegrationMinOrderByAggregateInput>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<EzyVetIntegrationOrderByRelevanceInput>;
  client_id?: Maybe<SortOrder>;
  client_secret?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ownership_id?: Maybe<SortOrder>;
  scopes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EzyVetIntegrationRelationFilter = {
  is?: Maybe<EzyVetIntegrationWhereInput>;
  isNot?: Maybe<EzyVetIntegrationWhereInput>;
};

export enum EzyVetIntegrationScalarFieldEnum {
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  CreatedAt = 'createdAt',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Id = 'id',
  OwnershipId = 'ownership_id',
  Scopes = 'scopes',
  UpdatedAt = 'updatedAt'
}

export type EzyVetIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<EzyVetIntegrationScalarWhereWithAggregatesInput>>;
  client_id?: Maybe<StringNullableWithAggregatesFilter>;
  client_secret?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  ownership_id?: Maybe<StringNullableWithAggregatesFilter>;
  scopes?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type EzyVetIntegrationUncheckedCreateInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutEzyvetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUncheckedUpdateInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutEzyvetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUncheckedUpdateManyInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUpdateInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutEzyvetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUpdateManyMutationInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<EzyVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type EzyVetIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ownership_id?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EzyVetIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: EzyVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: EzyVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type EzyVetIntegrationWhereInput = {
  AND?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  NOT?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  OR?: Maybe<Array<EzyVetIntegrationWhereInput>>;
  client_id?: Maybe<StringNullableFilter>;
  client_secret?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  ownership_id?: Maybe<StringNullableFilter>;
  scopes?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EzyVetIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  _count: FeatureFlagCountOutputType;
  clinics: Array<Clinic>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  key: Scalars['String'];
  state: FeatureFlagState;
  type: FeatureFlagType;
  updatedAt: Scalars['DateTime'];
};


export type FeatureFlagClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};

export type FeatureFlagCountAggregateOutputType = {
  __typename?: 'FeatureFlagCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  key: Scalars['Int'];
  state: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FeatureFlagCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FeatureFlagCountOutputType = {
  __typename?: 'FeatureFlagCountOutputType';
  clinics: Scalars['Int'];
};

export type FeatureFlagCreateInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutFeatureFlagsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  state?: Maybe<FeatureFlagState>;
  type: FeatureFlagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  state?: Maybe<FeatureFlagState>;
  type: FeatureFlagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
};

export type FeatureFlagCreateOrConnectWithoutClinicsInput = {
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
  where: FeatureFlagWhereUniqueInput;
};

export type FeatureFlagCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  state?: Maybe<FeatureFlagState>;
  type: FeatureFlagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagListRelationFilter = {
  every?: Maybe<FeatureFlagWhereInput>;
  none?: Maybe<FeatureFlagWhereInput>;
  some?: Maybe<FeatureFlagWhereInput>;
};

export type FeatureFlagMaxAggregateOutputType = {
  __typename?: 'FeatureFlagMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FeatureFlagMinAggregateOutputType = {
  __typename?: 'FeatureFlagMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FeatureFlagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FeatureFlagOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  Key = 'key'
}

export type FeatureFlagOrderByRelevanceInput = {
  fields: Array<FeatureFlagOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FeatureFlagOrderByWithAggregationInput = {
  _count?: Maybe<FeatureFlagCountOrderByAggregateInput>;
  _max?: Maybe<FeatureFlagMaxOrderByAggregateInput>;
  _min?: Maybe<FeatureFlagMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FeatureFlagOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FeatureFlagOrderByRelevanceInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum FeatureFlagScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Key = 'key',
  State = 'state',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type FeatureFlagScalarWhereInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  key?: Maybe<StringFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FeatureFlagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FeatureFlagScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  key?: Maybe<StringWithAggregatesFilter>;
  state?: Maybe<EnumFeatureFlagStateWithAggregatesFilter>;
  type?: Maybe<EnumFeatureFlagTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum FeatureFlagState {
  Off = 'Off',
  On = 'On'
}

export enum FeatureFlagType {
  Clinic = 'Clinic',
  Global = 'Global'
}

export type FeatureFlagUncheckedCreateInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutFeatureFlagsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  state?: Maybe<FeatureFlagState>;
  type: FeatureFlagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUncheckedCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
};

export type FeatureFlagUncheckedCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  state?: Maybe<FeatureFlagState>;
  type: FeatureFlagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUncheckedUpdateInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutFeatureFlagsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUncheckedUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUncheckedUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUpdateInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutFeatureFlagsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUpdateManyWithWhereWithoutClinicsInput = {
  data: FeatureFlagUncheckedUpdateManyWithoutFeatureFlagsInput;
  where: FeatureFlagScalarWhereInput;
};

export type FeatureFlagUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureFlagCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<FeatureFlagCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FeatureFlagScalarWhereInput>>;
  disconnect?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  set?: Maybe<Array<FeatureFlagWhereUniqueInput>>;
  update?: Maybe<Array<FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<FeatureFlagUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type FeatureFlagUpdateWithWhereUniqueWithoutClinicsInput = {
  data: FeatureFlagUncheckedUpdateWithoutClinicsInput;
  where: FeatureFlagWhereUniqueInput;
};

export type FeatureFlagUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  state?: Maybe<FeatureFlagState>;
  type?: Maybe<FeatureFlagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeatureFlagUpsertWithWhereUniqueWithoutClinicsInput = {
  create: FeatureFlagUncheckedCreateWithoutClinicsInput;
  update: FeatureFlagUncheckedUpdateWithoutClinicsInput;
  where: FeatureFlagWhereUniqueInput;
};

export type FeatureFlagWhereInput = {
  AND?: Maybe<Array<FeatureFlagWhereInput>>;
  NOT?: Maybe<Array<FeatureFlagWhereInput>>;
  OR?: Maybe<Array<FeatureFlagWhereInput>>;
  clinics?: Maybe<ClinicListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  key?: Maybe<StringFilter>;
  state?: Maybe<EnumFeatureFlagStateFilter>;
  type?: Maybe<EnumFeatureFlagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FeatureFlagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export enum FeeCollection {
  DuringPayment = 'DuringPayment',
  PostPaid = 'PostPaid'
}

export type FileExportData = {
  __typename?: 'FileExportData';
  base64Encoding: Scalars['String'];
  fileType: Scalars['String'];
};

export enum FilterSelectionType {
  Private = 'Private',
  PublicReadonly = 'PublicReadonly'
}

export type FinancialAdjustment = {
  __typename?: 'FinancialAdjustment';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransaction>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FinancialAdjustmentAvgAggregateOutputType = {
  __typename?: 'FinancialAdjustmentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type FinancialAdjustmentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type FinancialAdjustmentCountAggregateOutputType = {
  __typename?: 'FinancialAdjustmentCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FinancialAdjustmentCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialAdjustmentCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutAdjustmentInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentCreateManyInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput = {
  connect?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput>;
};

export type FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput = {
  create: FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput;
  where: FinancialAdjustmentWhereUniqueInput;
};

export type FinancialAdjustmentCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentMaxAggregateOutputType = {
  __typename?: 'FinancialAdjustmentMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialAdjustmentMinAggregateOutputType = {
  __typename?: 'FinancialAdjustmentMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum FinancialAdjustmentOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id'
}

export type FinancialAdjustmentOrderByRelevanceInput = {
  fields: Array<FinancialAdjustmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FinancialAdjustmentOrderByWithAggregationInput = {
  _avg?: Maybe<FinancialAdjustmentAvgOrderByAggregateInput>;
  _count?: Maybe<FinancialAdjustmentCountOrderByAggregateInput>;
  _max?: Maybe<FinancialAdjustmentMaxOrderByAggregateInput>;
  _min?: Maybe<FinancialAdjustmentMinOrderByAggregateInput>;
  _sum?: Maybe<FinancialAdjustmentSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FinancialAdjustmentOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialAdjustmentRelationFilter = {
  is?: Maybe<FinancialAdjustmentWhereInput>;
  isNot?: Maybe<FinancialAdjustmentWhereInput>;
};

export enum FinancialAdjustmentScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type FinancialAdjustmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FinancialAdjustmentScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type FinancialAdjustmentSumAggregateOutputType = {
  __typename?: 'FinancialAdjustmentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type FinancialAdjustmentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type FinancialAdjustmentUncheckedCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutAdjustmentInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutAdjustmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutAdjustmentNestedInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput = {
  connect?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialAdjustmentCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput>;
  upsert?: Maybe<FinancialAdjustmentUpsertWithoutFinancialTransactionInput>;
};

export type FinancialAdjustmentUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialAdjustmentUpsertWithoutFinancialTransactionInput = {
  create: FinancialAdjustmentUncheckedCreateWithoutFinancialTransactionInput;
  update: FinancialAdjustmentUncheckedUpdateWithoutFinancialTransactionInput;
};

export type FinancialAdjustmentWhereInput = {
  AND?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  NOT?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  OR?: Maybe<Array<FinancialAdjustmentWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FinancialAdjustmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FinancialTransaction = {
  __typename?: 'FinancialTransaction';
  adjustment?: Maybe<FinancialAdjustment>;
  adjustmentId?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  stripeDispute?: Maybe<StripeDispute>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoice>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayout>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefund?: Maybe<StripeRefund>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt: Scalars['DateTime'];
};

export type FinancialTransactionCountAggregateOutputType = {
  __typename?: 'FinancialTransactionCountAggregateOutputType';
  _all: Scalars['Int'];
  adjustmentId: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  stripeDisputeId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeRefundId: Scalars['Int'];
  timestamp: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FinancialTransactionCountOrderByAggregateInput = {
  adjustmentId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialTransactionCreateInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateManyChannelInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateManyChannelInputEnvelope = {
  data: Array<FinancialTransactionCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyClinicInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateManyClinicInputEnvelope = {
  data: Array<FinancialTransactionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateManyInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateManyStripePayoutInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateManyStripePayoutInputEnvelope = {
  data: Array<FinancialTransactionCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FinancialTransactionCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
};

export type FinancialTransactionCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
};

export type FinancialTransactionCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
};

export type FinancialTransactionCreateNestedOneWithoutAdjustmentInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeDisputeInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionCreateNestedOneWithoutStripeRefundInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
};

export type FinancialTransactionCreateOrConnectWithoutAdjustmentInput = {
  create: FinancialTransactionUncheckedCreateWithoutAdjustmentInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutChannelInput = {
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutClinicInput = {
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeDisputeInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripePayoutInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateOrConnectWithoutStripeRefundInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionCreateWithoutAdjustmentInput = {
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutChannelInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutClinicInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutStripeDisputeInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutStripeInvoiceInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutStripePaymentIntentInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutStripePayoutInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutFinancialTransactionInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionCreateWithoutStripeRefundInput = {
  adjustment?: Maybe<FinancialAdjustmentCreateNestedOneWithoutFinancialTransactionInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutFinancialTransactionsInput>;
  clinic: ClinicCreateNestedOneWithoutFinancialTransactionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeCreateNestedOneWithoutFinancialTransactionInput>;
  stripeInvoice?: Maybe<StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutFinancialTransactionsInput>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionListRelationFilter = {
  every?: Maybe<FinancialTransactionWhereInput>;
  none?: Maybe<FinancialTransactionWhereInput>;
  some?: Maybe<FinancialTransactionWhereInput>;
};

export type FinancialTransactionMaxAggregateOutputType = {
  __typename?: 'FinancialTransactionMaxAggregateOutputType';
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionMaxOrderByAggregateInput = {
  adjustmentId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialTransactionMinAggregateOutputType = {
  __typename?: 'FinancialTransactionMinAggregateOutputType';
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionMinOrderByAggregateInput = {
  adjustmentId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FinancialTransactionOrderByRelevanceFieldEnum {
  AdjustmentId = 'adjustmentId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  Id = 'id',
  StripeDisputeId = 'stripeDisputeId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeRefundId = 'stripeRefundId'
}

export type FinancialTransactionOrderByRelevanceInput = {
  fields: Array<FinancialTransactionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FinancialTransactionOrderByWithAggregationInput = {
  _count?: Maybe<FinancialTransactionCountOrderByAggregateInput>;
  _max?: Maybe<FinancialTransactionMaxOrderByAggregateInput>;
  _min?: Maybe<FinancialTransactionMinOrderByAggregateInput>;
  adjustmentId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialTransactionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FinancialTransactionOrderByRelevanceInput>;
  adjustment?: Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>;
  adjustmentId?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeDispute?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  stripeDisputeId?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeRefund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  stripeRefundId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FinancialTransactionRelationFilter = {
  is?: Maybe<FinancialTransactionWhereInput>;
  isNot?: Maybe<FinancialTransactionWhereInput>;
};

export enum FinancialTransactionScalarFieldEnum {
  AdjustmentId = 'adjustmentId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  StripeDisputeId = 'stripeDisputeId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeRefundId = 'stripeRefundId',
  Timestamp = 'timestamp',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type FinancialTransactionScalarWhereInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  adjustmentId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FinancialTransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FinancialTransactionScalarWhereWithAggregatesInput>>;
  adjustmentId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeDisputeId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeRefundId?: Maybe<StringNullableWithAggregatesFilter>;
  timestamp?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumFinancialTransactionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum FinancialTransactionType {
  Adjustment = 'Adjustment',
  Dispute = 'Dispute',
  Refund = 'Refund',
  Sale = 'Sale',
  Subscription = 'Subscription'
}

export type FinancialTransactionUncheckedCreateInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
};

export type FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutAdjustmentInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
};

export type FinancialTransactionUncheckedCreateWithoutAdjustmentInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutChannelInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutClinicInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeDisputeInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripePayoutInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedCreateWithoutStripeRefundInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  type: FinancialTransactionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateManyInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutAdjustmentNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutAdjustmentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutAdjustmentInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
};

export type FinancialTransactionUncheckedUpdateWithoutAdjustmentInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutChannelInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutClinicInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripePayoutInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUncheckedUpdateWithoutStripeRefundInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateManyWithWhereWithoutChannelInput = {
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
  where: FinancialTransactionScalarWhereInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutClinicInput = {
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
  where: FinancialTransactionScalarWhereInput;
};

export type FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput = {
  data: FinancialTransactionUncheckedUpdateManyWithoutFinancialTransactionsInput;
  where: FinancialTransactionScalarWhereInput;
};

export type FinancialTransactionUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutChannelInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type FinancialTransactionUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutClinicInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FinancialTransactionUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FinancialTransactionCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<FinancialTransactionCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<FinancialTransactionCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FinancialTransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  set?: Maybe<Array<FinancialTransactionWhereUniqueInput>>;
  update?: Maybe<Array<FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<FinancialTransactionUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type FinancialTransactionUpdateOneWithoutAdjustmentNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutAdjustmentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutAdjustmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutAdjustmentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutAdjustmentInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeDisputeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeDisputeInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeInvoiceInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeInvoiceInput>;
};

export type FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripePaymentIntentInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripePaymentIntentInput>;
};

export type FinancialTransactionUpdateOneWithoutStripeRefundNestedInput = {
  connect?: Maybe<FinancialTransactionWhereUniqueInput>;
  connectOrCreate?: Maybe<FinancialTransactionCreateOrConnectWithoutStripeRefundInput>;
  create?: Maybe<FinancialTransactionUncheckedCreateWithoutStripeRefundInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FinancialTransactionUncheckedUpdateWithoutStripeRefundInput>;
  upsert?: Maybe<FinancialTransactionUpsertWithoutStripeRefundInput>;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutChannelInput = {
  data: FinancialTransactionUncheckedUpdateWithoutChannelInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutClinicInput = {
  data: FinancialTransactionUncheckedUpdateWithoutClinicInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpdateWithWhereUniqueWithoutStripePayoutInput = {
  data: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpdateWithoutAdjustmentInput = {
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutChannelInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutClinicInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutStripeDisputeInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutStripeInvoiceInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutStripePaymentIntentInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutStripePayoutInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutFinancialTransactionNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpdateWithoutStripeRefundInput = {
  adjustment?: Maybe<FinancialAdjustmentUpdateOneWithoutFinancialTransactionNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutFinancialTransactionsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutFinancialTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  stripeDispute?: Maybe<StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<FinancialTransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutChannelInput = {
  create: FinancialTransactionUncheckedCreateWithoutChannelInput;
  update: FinancialTransactionUncheckedUpdateWithoutChannelInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutClinicInput = {
  create: FinancialTransactionUncheckedCreateWithoutClinicInput;
  update: FinancialTransactionUncheckedUpdateWithoutClinicInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpsertWithWhereUniqueWithoutStripePayoutInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripePayoutInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripePayoutInput;
  where: FinancialTransactionWhereUniqueInput;
};

export type FinancialTransactionUpsertWithoutAdjustmentInput = {
  create: FinancialTransactionUncheckedCreateWithoutAdjustmentInput;
  update: FinancialTransactionUncheckedUpdateWithoutAdjustmentInput;
};

export type FinancialTransactionUpsertWithoutStripeDisputeInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeDisputeInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripeDisputeInput;
};

export type FinancialTransactionUpsertWithoutStripeInvoiceInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeInvoiceInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripeInvoiceInput;
};

export type FinancialTransactionUpsertWithoutStripePaymentIntentInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripePaymentIntentInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripePaymentIntentInput;
};

export type FinancialTransactionUpsertWithoutStripeRefundInput = {
  create: FinancialTransactionUncheckedCreateWithoutStripeRefundInput;
  update: FinancialTransactionUncheckedUpdateWithoutStripeRefundInput;
};

export type FinancialTransactionWhereInput = {
  AND?: Maybe<Array<FinancialTransactionWhereInput>>;
  NOT?: Maybe<Array<FinancialTransactionWhereInput>>;
  OR?: Maybe<Array<FinancialTransactionWhereInput>>;
  adjustment?: Maybe<FinancialAdjustmentWhereInput>;
  adjustmentId?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  stripeDispute?: Maybe<StripeDisputeWhereInput>;
  stripeDisputeId?: Maybe<StringNullableFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeInvoiceId?: Maybe<StringNullableFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeRefund?: Maybe<StripeRefundWhereInput>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumFinancialTransactionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FinancialTransactionWhereUniqueInput = {
  adjustmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeDisputeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeRefundId?: Maybe<Scalars['String']>;
};

export type FindClinicPetParentByPhoneOrEmailRequest = {
  clinicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type FindCurrentEmailTemplateVersionInput = {
  type: Scalars['String'];
};

export type FindEmployerIdentificationNumberInput = {
  companyName: Scalars['String'];
};

export type FindEmployerIdentificationNumberResponse = {
  __typename?: 'FindEmployerIdentificationNumberResponse';
  address?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  ein?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FindMissingBatchingPeriodDatesOutput = {
  __typename?: 'FindMissingBatchingPeriodDatesOutput';
  dates?: Maybe<Array<Scalars['String']>>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** @deprecated - 1.0 */
export type FirebaseStructure = {
  __typename?: 'FirebaseStructure';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  structure?: Maybe<Scalars['String']>;
  table: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FirebaseStructureCountAggregateOutputType = {
  __typename?: 'FirebaseStructureCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  structure: Scalars['Int'];
  table: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FirebaseStructureCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FirebaseStructureCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureMaxAggregateOutputType = {
  __typename?: 'FirebaseStructureMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FirebaseStructureMinAggregateOutputType = {
  __typename?: 'FirebaseStructureMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum FirebaseStructureOrderByRelevanceFieldEnum {
  Id = 'id',
  Structure = 'structure',
  Table = 'table'
}

export type FirebaseStructureOrderByRelevanceInput = {
  fields: Array<FirebaseStructureOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FirebaseStructureOrderByWithAggregationInput = {
  _count?: Maybe<FirebaseStructureCountOrderByAggregateInput>;
  _max?: Maybe<FirebaseStructureMaxOrderByAggregateInput>;
  _min?: Maybe<FirebaseStructureMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FirebaseStructureOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FirebaseStructureOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  structure?: Maybe<SortOrder>;
  table?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum FirebaseStructureScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Structure = 'structure',
  Table = 'table',
  UpdatedAt = 'updatedAt'
}

export type FirebaseStructureScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FirebaseStructureScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  structure?: Maybe<StringNullableWithAggregatesFilter>;
  table?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type FirebaseStructureUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  structure?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FirebaseStructureWhereInput = {
  AND?: Maybe<Array<FirebaseStructureWhereInput>>;
  NOT?: Maybe<Array<FirebaseStructureWhereInput>>;
  OR?: Maybe<Array<FirebaseStructureWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  structure?: Maybe<StringNullableFilter>;
  table?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FirebaseStructureWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export enum FormExportErrorType {
  Connection = 'Connection',
  GeneratingForm = 'GeneratingForm',
  InvalidParent = 'InvalidParent',
  InvalidPet = 'InvalidPet',
  PimsConfiguration = 'PimsConfiguration',
  PimsServer = 'PimsServer',
  Throttled = 'Throttled',
  Unauthorized = 'Unauthorized',
  Unknown = 'Unknown'
}

export type FormSubmission = {
  __typename?: 'FormSubmission';
  _count: FormSubmissionCountOutputType;
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunAction>;
  automationRuns: Array<AutomationRun>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests: Array<ClinicWidgetRequest>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplate>;
  formTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications: Array<ServiceReminderNotification>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};


export type FormSubmissionAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type FormSubmissionChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type FormSubmissionChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type FormSubmissionClinicWidgetRequestsArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type FormSubmissionServiceReminderNotificationsArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type FormSubmissionUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type FormSubmissionCountAggregateOutputType = {
  __typename?: 'FormSubmissionCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  completedContent: Scalars['Int'];
  createdAt: Scalars['Int'];
  draftContent: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  id: Scalars['Int'];
  isCallDeflectionForm: Scalars['Int'];
  submittedAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FormSubmissionCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormSubmissionCountOutputType = {
  __typename?: 'FormSubmissionCountOutputType';
  automationRuns: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  clinicWidgetRequests: Scalars['Int'];
  serviceReminderNotifications: Scalars['Int'];
  users: Scalars['Int'];
};

export type FormSubmissionCreateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateManyAppointmentInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateManyAppointmentInputEnvelope = {
  data: Array<FormSubmissionCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateManyClinicInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateManyClinicPetInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyClinicPetParentInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateManyClinicPetParentInputEnvelope = {
  data: Array<FormSubmissionCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyFormTemplateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateManyFormTemplateInputEnvelope = {
  data: Array<FormSubmissionCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormSubmissionCreateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
};

export type FormSubmissionCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
};

export type FormSubmissionCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
};

export type FormSubmissionCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
};

export type FormSubmissionCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunActionInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
};

export type FormSubmissionCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type FormSubmissionCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type FormSubmissionCreateNestedOneWithoutClinicWidgetRequestsInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
};

export type FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
};

export type FormSubmissionCreateOrConnectWithoutAppointmentInput = {
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunActionInput = {
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutAutomationRunsInput = {
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicPetParentInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutFormTemplateInput = {
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput = {
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateOrConnectWithoutUsersInput = {
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionCreateWithoutAppointmentInput = {
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutAutomationRunActionInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutAutomationRunsInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutChannelAutomationStatusesInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutChannelCreationSourcesInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutClinicInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutClinicPetInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutClinicPetParentInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutClinicWidgetRequestsInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutFormTemplateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutServiceReminderNotificationsInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionCreateWithoutUsersInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFormSubmissionsInput>;
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutFormSubmissionInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutFormSubmissionsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutFormSubmissionsInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutFormSubmissionsInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionExportInput = {
  id: Scalars['String'];
  petIds: Array<Maybe<Scalars['String']>>;
};

export type FormSubmissionExportResponse = {
  __typename?: 'FormSubmissionExportResponse';
  results: Array<Maybe<FormSubmissionExportResult>>;
};

export type FormSubmissionExportResult = {
  __typename?: 'FormSubmissionExportResult';
  errorMessage?: Maybe<Scalars['String']>;
  errorType?: Maybe<FormExportErrorType>;
  formId: Scalars['String'];
  petId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type FormSubmissionListRelationFilter = {
  every?: Maybe<FormSubmissionWhereInput>;
  none?: Maybe<FormSubmissionWhereInput>;
  some?: Maybe<FormSubmissionWhereInput>;
};

export type FormSubmissionMaxAggregateOutputType = {
  __typename?: 'FormSubmissionMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormSubmissionMinAggregateOutputType = {
  __typename?: 'FormSubmissionMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormSubmissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormSubmissionOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  FormTemplateId = 'formTemplateId',
  Id = 'id'
}

export type FormSubmissionOrderByRelevanceInput = {
  fields: Array<FormSubmissionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FormSubmissionOrderByWithAggregationInput = {
  _count?: Maybe<FormSubmissionCountOrderByAggregateInput>;
  _max?: Maybe<FormSubmissionMaxOrderByAggregateInput>;
  _min?: Maybe<FormSubmissionMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  completedContent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  submittedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormSubmissionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FormSubmissionOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestOrderByRelationAggregateInput>;
  completedContent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  formTemplateId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCallDeflectionForm?: Maybe<SortOrder>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  submittedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
};

export type FormSubmissionRelationFilter = {
  is?: Maybe<FormSubmissionWhereInput>;
  isNot?: Maybe<FormSubmissionWhereInput>;
};

export enum FormSubmissionScalarFieldEnum {
  AppointmentId = 'appointmentId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  CompletedContent = 'completedContent',
  CreatedAt = 'createdAt',
  DraftContent = 'draftContent',
  FormTemplateId = 'formTemplateId',
  Id = 'id',
  IsCallDeflectionForm = 'isCallDeflectionForm',
  SubmittedAt = 'submittedAt',
  UpdatedAt = 'updatedAt'
}

export type FormSubmissionScalarWhereInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FormSubmissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormSubmissionScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  completedContent?: Maybe<JsonNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableWithAggregatesFilter>;
  submittedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type FormSubmissionSubscription = {
  __typename?: 'FormSubmissionSubscription';
  mutation: MutationType;
  node: FormSubmission;
};

export type FormSubmissionUncheckedCreateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
};

export type FormSubmissionUncheckedCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
};

export type FormSubmissionUncheckedCreateWithoutAppointmentInput = {
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutAutomationRunsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicPetParentInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutFormTemplateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedCreateNestedManyWithoutFormSubmissionsInput>;
};

export type FormSubmissionUncheckedCreateWithoutUsersInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutFormSubmissionInput>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type FormSubmissionUncheckedUpdateWithoutAppointmentInput = {
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutAutomationRunsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicPetParentInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutFormTemplateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUncheckedUpdateWithoutUsersInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateManyMutationInput = {
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUpdateManyWithWhereWithoutAppointmentInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithWhereWithoutUsersInput = {
  data: FormSubmissionUncheckedUpdateManyWithoutFormSubmissionsInput;
  where: FormSubmissionScalarWhereInput;
};

export type FormSubmissionUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type FormSubmissionUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<FormSubmissionCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type FormSubmissionUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<FormSubmissionCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type FormSubmissionUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormSubmissionCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<FormSubmissionCreateWithoutUsersInput>>;
  delete?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormSubmissionScalarWhereInput>>;
  disconnect?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  set?: Maybe<Array<FormSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<FormSubmissionUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<FormSubmissionUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<FormSubmissionUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunActionNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunActionInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunActionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunActionInput>;
};

export type FormSubmissionUpdateOneWithoutAutomationRunsNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutAutomationRunsInput>;
};

export type FormSubmissionUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelAutomationStatusesInput>;
};

export type FormSubmissionUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutChannelCreationSourcesInput>;
};

export type FormSubmissionUpdateOneWithoutClinicWidgetRequestsNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutClinicWidgetRequestsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutClinicWidgetRequestsInput>;
};

export type FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput = {
  connect?: Maybe<FormSubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<FormSubmissionCreateOrConnectWithoutServiceReminderNotificationsInput>;
  create?: Maybe<FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput>;
  upsert?: Maybe<FormSubmissionUpsertWithoutServiceReminderNotificationsInput>;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicInput = {
  data: FormSubmissionUncheckedUpdateWithoutClinicInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutFormTemplateInput = {
  data: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithWhereUniqueWithoutUsersInput = {
  data: FormSubmissionUncheckedUpdateWithoutUsersInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpdateWithoutAppointmentInput = {
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunActionInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutAutomationRunsInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutChannelAutomationStatusesInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutChannelCreationSourcesInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicPetInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicPetParentInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutClinicWidgetRequestsInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutFormTemplateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutServiceReminderNotificationsInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserUpdateManyWithoutFormSubmissionsNestedInput>;
};

export type FormSubmissionUpdateWithoutUsersInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFormSubmissionsNestedInput>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutFormSubmissionNestedInput>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutFormSubmissionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutFormSubmissionNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutFormSubmissionNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutFormSubmissionsNestedInput>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestUpdateManyWithoutFormSubmissionNestedInput>;
  completedContent?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutFormSubmissionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCallDeflectionForm?: Maybe<Scalars['Boolean']>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: FormSubmissionUncheckedCreateWithoutAppointmentInput;
  update: FormSubmissionUncheckedUpdateWithoutAppointmentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicPetInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicPetParentInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicPetParentInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutFormTemplateInput = {
  create: FormSubmissionUncheckedCreateWithoutFormTemplateInput;
  update: FormSubmissionUncheckedUpdateWithoutFormTemplateInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithWhereUniqueWithoutUsersInput = {
  create: FormSubmissionUncheckedCreateWithoutUsersInput;
  update: FormSubmissionUncheckedUpdateWithoutUsersInput;
  where: FormSubmissionWhereUniqueInput;
};

export type FormSubmissionUpsertWithoutAutomationRunActionInput = {
  create: FormSubmissionUncheckedCreateWithoutAutomationRunActionInput;
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunActionInput;
};

export type FormSubmissionUpsertWithoutAutomationRunsInput = {
  create: FormSubmissionUncheckedCreateWithoutAutomationRunsInput;
  update: FormSubmissionUncheckedUpdateWithoutAutomationRunsInput;
};

export type FormSubmissionUpsertWithoutChannelAutomationStatusesInput = {
  create: FormSubmissionUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: FormSubmissionUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type FormSubmissionUpsertWithoutChannelCreationSourcesInput = {
  create: FormSubmissionUncheckedCreateWithoutChannelCreationSourcesInput;
  update: FormSubmissionUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type FormSubmissionUpsertWithoutClinicWidgetRequestsInput = {
  create: FormSubmissionUncheckedCreateWithoutClinicWidgetRequestsInput;
  update: FormSubmissionUncheckedUpdateWithoutClinicWidgetRequestsInput;
};

export type FormSubmissionUpsertWithoutServiceReminderNotificationsInput = {
  create: FormSubmissionUncheckedCreateWithoutServiceReminderNotificationsInput;
  update: FormSubmissionUncheckedUpdateWithoutServiceReminderNotificationsInput;
};

export type FormSubmissionWhereInput = {
  AND?: Maybe<Array<FormSubmissionWhereInput>>;
  NOT?: Maybe<Array<FormSubmissionWhereInput>>;
  OR?: Maybe<Array<FormSubmissionWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicWidgetRequests?: Maybe<ClinicWidgetRequestListRelationFilter>;
  completedContent?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  formTemplateId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isCallDeflectionForm?: Maybe<BoolNullableFilter>;
  serviceReminderNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  submittedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type FormSubmissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  _count: FormTemplateCountOutputType;
  autoExportEnabled: Scalars['Boolean'];
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  content?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions: Array<FormSubmission>;
  formTemplateType: FormTemplateType;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services: Array<Service>;
  title: Scalars['String'];
  triggeredWorkflows: Array<WorkflowEventSetting>;
  updatedAt: Scalars['DateTime'];
};


export type FormTemplateClinicWidgetRequestTypesArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type FormTemplateFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type FormTemplateServicesArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type FormTemplateTriggeredWorkflowsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type FormTemplateCountAggregateOutputType = {
  __typename?: 'FormTemplateCountAggregateOutputType';
  _all: Scalars['Int'];
  autoExportEnabled: Scalars['Int'];
  clinicId: Scalars['Int'];
  content: Scalars['Int'];
  createdAt: Scalars['Int'];
  draftContent: Scalars['Int'];
  formTemplateType: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type FormTemplateCountOrderByAggregateInput = {
  autoExportEnabled?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormTemplateCountOutputType = {
  __typename?: 'FormTemplateCountOutputType';
  clinicWidgetRequestTypes: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  services: Scalars['Int'];
  triggeredWorkflows: Scalars['Int'];
};

export type FormTemplateCreateInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateManyClinicInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateManyClinicInputEnvelope = {
  data: Array<FormTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FormTemplateCreateManyInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
};

export type FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
};

export type FormTemplateCreateNestedOneWithoutClinicWidgetRequestTypesInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
};

export type FormTemplateCreateNestedOneWithoutFormSubmissionsInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
};

export type FormTemplateCreateNestedOneWithoutServicesInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
};

export type FormTemplateCreateOrConnectWithoutClinicInput = {
  create: FormTemplateUncheckedCreateWithoutClinicInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateCreateOrConnectWithoutFormSubmissionsInput = {
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateCreateOrConnectWithoutServicesInput = {
  create: FormTemplateUncheckedCreateWithoutServicesInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput = {
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateCreateWithoutClinicInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateWithoutClinicWidgetRequestTypesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateWithoutFormSubmissionsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateWithoutServicesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateCreateWithoutTriggeredWorkflowsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutFormTemplatesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateListRelationFilter = {
  every?: Maybe<FormTemplateWhereInput>;
  none?: Maybe<FormTemplateWhereInput>;
  some?: Maybe<FormTemplateWhereInput>;
};

export type FormTemplateMaxAggregateOutputType = {
  __typename?: 'FormTemplateMaxAggregateOutputType';
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateMaxOrderByAggregateInput = {
  autoExportEnabled?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormTemplateMinAggregateOutputType = {
  __typename?: 'FormTemplateMinAggregateOutputType';
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateMinOrderByAggregateInput = {
  autoExportEnabled?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FormTemplateOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  Title = 'title'
}

export type FormTemplateOrderByRelevanceInput = {
  fields: Array<FormTemplateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type FormTemplateOrderByWithAggregationInput = {
  _count?: Maybe<FormTemplateCountOrderByAggregateInput>;
  _max?: Maybe<FormTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<FormTemplateMinOrderByAggregateInput>;
  autoExportEnabled?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formTemplateType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormTemplateOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<FormTemplateOrderByRelevanceInput>;
  autoExportEnabled?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftContent?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  formTemplateType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  services?: Maybe<ServiceOrderByRelationAggregateInput>;
  title?: Maybe<SortOrder>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type FormTemplateRelationFilter = {
  is?: Maybe<FormTemplateWhereInput>;
  isNot?: Maybe<FormTemplateWhereInput>;
};

export enum FormTemplateScalarFieldEnum {
  AutoExportEnabled = 'autoExportEnabled',
  ClinicId = 'clinicId',
  Content = 'content',
  CreatedAt = 'createdAt',
  DraftContent = 'draftContent',
  FormTemplateType = 'formTemplateType',
  Id = 'id',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type FormTemplateScalarWhereInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereInput>>;
  autoExportEnabled?: Maybe<BoolFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  content?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FormTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<FormTemplateScalarWhereWithAggregatesInput>>;
  autoExportEnabled?: Maybe<BoolWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  content?: Maybe<JsonNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  draftContent?: Maybe<JsonNullableWithAggregatesFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  isDeleted?: Maybe<BoolNullableWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum FormTemplateType {
  FillInTheBlank = 'FillInTheBlank',
  General = 'General',
  ServiceReminder = 'ServiceReminder',
  WidgetRequest = 'WidgetRequest'
}

export type FormTemplateUncheckedCreateInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
};

export type FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
};

export type FormTemplateUncheckedCreateWithoutClinicInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedCreateWithoutFormSubmissionsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedCreateWithoutServicesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutFormTemplateInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutFormTemplateInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedCreateNestedManyWithoutFormTemplateInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateManyInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
};

export type FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateWithoutClinicInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateWithoutFormSubmissionsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateWithoutServicesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateManyMutationInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateManyWithWhereWithoutClinicInput = {
  data: FormTemplateUncheckedUpdateManyWithoutFormTemplatesInput;
  where: FormTemplateScalarWhereInput;
};

export type FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput = {
  data: FormTemplateUncheckedUpdateManyWithoutTriggeringFormTemplatesInput;
  where: FormTemplateScalarWhereInput;
};

export type FormTemplateUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<FormTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput = {
  connect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FormTemplateCreateOrConnectWithoutTriggeredWorkflowsInput>>;
  create?: Maybe<Array<FormTemplateCreateWithoutTriggeredWorkflowsInput>>;
  delete?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FormTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  set?: Maybe<Array<FormTemplateWhereUniqueInput>>;
  update?: Maybe<Array<FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
  updateMany?: Maybe<Array<FormTemplateUpdateManyWithWhereWithoutTriggeredWorkflowsInput>>;
  upsert?: Maybe<Array<FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput>>;
};

export type FormTemplateUpdateOneWithoutClinicWidgetRequestTypesNestedInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutClinicWidgetRequestTypesInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutClinicWidgetRequestTypesInput>;
};

export type FormTemplateUpdateOneWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutFormSubmissionsInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutFormSubmissionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutFormSubmissionsInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutFormSubmissionsInput>;
};

export type FormTemplateUpdateOneWithoutServicesNestedInput = {
  connect?: Maybe<FormTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<FormTemplateCreateOrConnectWithoutServicesInput>;
  create?: Maybe<FormTemplateUncheckedCreateWithoutServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<FormTemplateUncheckedUpdateWithoutServicesInput>;
  upsert?: Maybe<FormTemplateUpsertWithoutServicesInput>;
};

export type FormTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  data: FormTemplateUncheckedUpdateWithoutClinicInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateUpdateWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  data: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateUpdateWithoutClinicInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateWithoutClinicWidgetRequestTypesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateWithoutFormSubmissionsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateWithoutServicesInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpdateWithoutTriggeredWorkflowsInput = {
  autoExportEnabled?: Maybe<Scalars['Boolean']>;
  clinic?: Maybe<ClinicUpdateOneWithoutFormTemplatesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutFormTemplateNestedInput>;
  content?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftContent?: Maybe<Scalars['Json']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutFormTemplateNestedInput>;
  formTemplateType?: Maybe<FormTemplateType>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  services?: Maybe<ServiceUpdateManyWithoutFormTemplateNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FormTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  create: FormTemplateUncheckedCreateWithoutClinicInput;
  update: FormTemplateUncheckedUpdateWithoutClinicInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateUpsertWithWhereUniqueWithoutTriggeredWorkflowsInput = {
  create: FormTemplateUncheckedCreateWithoutTriggeredWorkflowsInput;
  update: FormTemplateUncheckedUpdateWithoutTriggeredWorkflowsInput;
  where: FormTemplateWhereUniqueInput;
};

export type FormTemplateUpsertWithoutClinicWidgetRequestTypesInput = {
  create: FormTemplateUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  update: FormTemplateUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
};

export type FormTemplateUpsertWithoutFormSubmissionsInput = {
  create: FormTemplateUncheckedCreateWithoutFormSubmissionsInput;
  update: FormTemplateUncheckedUpdateWithoutFormSubmissionsInput;
};

export type FormTemplateUpsertWithoutServicesInput = {
  create: FormTemplateUncheckedCreateWithoutServicesInput;
  update: FormTemplateUncheckedUpdateWithoutServicesInput;
};

export type FormTemplateWhereInput = {
  AND?: Maybe<Array<FormTemplateWhereInput>>;
  NOT?: Maybe<Array<FormTemplateWhereInput>>;
  OR?: Maybe<Array<FormTemplateWhereInput>>;
  autoExportEnabled?: Maybe<BoolFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  content?: Maybe<JsonNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draftContent?: Maybe<JsonNullableFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  formTemplateType?: Maybe<EnumFormTemplateTypeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  isDeleted?: Maybe<BoolNullableFilter>;
  services?: Maybe<ServiceListRelationFilter>;
  title?: Maybe<StringFilter>;
  triggeredWorkflows?: Maybe<WorkflowEventSettingListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type FormTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GenerateCustomTokenResponse = {
  __typename?: 'GenerateCustomTokenResponse';
  token?: Maybe<Scalars['String']>;
};

export type GeneratePetIdPdfInput = {
  petId: Scalars['String'];
  petParentId: Scalars['String'];
};

export type GeneratePetIdPdfResponse = {
  __typename?: 'GeneratePetIdPdfResponse';
  base64PDF?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GenerateTerminalAuthTokenResponse = {
  __typename?: 'GenerateTerminalAuthTokenResponse';
  terminal: StripeTerminal;
  token: Scalars['String'];
};

export type GenerateTerminalSetupPinResponse = {
  __typename?: 'GenerateTerminalSetupPinResponse';
  pin: Scalars['String'];
};

export type GenerateTinyUrlAndTokenInput = {
  clinicPetParentId: Scalars['String'];
};

export type GenerateWidgetAuthenticationRequestResponse = {
  __typename?: 'GenerateWidgetAuthenticationRequestResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  payload?: Maybe<Scalars['Json']>;
  success: Scalars['Boolean'];
};

export type GetBalanceForClientInput = {
  clinicPetParentId: Scalars['String'];
};

export type GetChannelStatusIdsWithEntityCountOfZeroWhere = {
  channelStatusIds: Array<Scalars['String']>;
  clinicId: Scalars['String'];
  end: Scalars['DateTime'];
  entity: ChannelStatusEntity;
  start: Scalars['DateTime'];
};

export type GetDirectedAuthLinkResult = {
  __typename?: 'GetDirectedAuthLinkResult';
  link: Scalars['String'];
};

export type GetEntityHistoryInput = {
  id: Scalars['String'];
};

export type GetEntityHistoryOutput = {
  __typename?: 'GetEntityHistoryOutput';
  data: Scalars['String'];
};

export type GetLatestActiveSubscriptionInvoiceOutput = {
  __typename?: 'GetLatestActiveSubscriptionInvoiceOutput';
  paymentAmountNeeded: Scalars['Int'];
  paymentAmountReceived: Scalars['Int'];
  paymentIntentSecret: Scalars['String'];
  subscriptionStatus: Scalars['String'];
};

export type GetOrCreateActiveChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetOrCreateActiveClientChannelInput = {
  clinicPetParentIds: Array<Scalars['String']>;
};

export type GetPetIdDataInput = {
  petId: Scalars['String'];
  petParentId: Scalars['String'];
};

export type GetPetIdDataResponse = {
  __typename?: 'GetPetIdDataResponse';
  clinic: Clinic;
  history: Array<PetIdHistoryItem>;
  pet: ClinicPet;
  petParent: ClinicPetParent;
};

export type GetSignedUploadInput = {
  contentType: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  overrideClinicIdWith?: Maybe<Scalars['String']>;
};

export type GetSignedUploadUrlOutput = {
  __typename?: 'GetSignedUploadUrlOutput';
  finalUrl: Scalars['String'];
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type GetTwilioNumbersInput = {
  clinicId: Scalars['ID'];
};

export type GlobalPetParent = {
  __typename?: 'GlobalPetParent';
  _count: GlobalPetParentCountOutputType;
  /** all related clinic pet parents */
  clinicPetParents: Array<ClinicPetParent>;
  createdAt: Scalars['DateTime'];
  /** Used for matching */
  email: Scalars['String'];
  id: Scalars['String'];
  /** Used as a secondary characteristic for matching */
  primaryPhoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type GlobalPetParentClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};

export type GlobalPetParentCountAggregateOutputType = {
  __typename?: 'GlobalPetParentCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  id: Scalars['Int'];
  primaryPhoneNumber: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type GlobalPetParentCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalPetParentCountOutputType = {
  __typename?: 'GlobalPetParentCountOutputType';
  clinicPetParents: Scalars['Int'];
};

export type GlobalPetParentCreateInput = {
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutGlobalPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentCreateNestedOneWithoutClinicPetParentsInput = {
  connect?: Maybe<GlobalPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput>;
};

export type GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput = {
  create: GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput;
  where: GlobalPetParentWhereUniqueInput;
};

export type GlobalPetParentCreateWithoutClinicPetParentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentMaxAggregateOutputType = {
  __typename?: 'GlobalPetParentMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalPetParentMinAggregateOutputType = {
  __typename?: 'GlobalPetParentMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum GlobalPetParentOrderByRelevanceFieldEnum {
  Email = 'email',
  Id = 'id',
  PrimaryPhoneNumber = 'primaryPhoneNumber'
}

export type GlobalPetParentOrderByRelevanceInput = {
  fields: Array<GlobalPetParentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type GlobalPetParentOrderByWithAggregationInput = {
  _count?: Maybe<GlobalPetParentCountOrderByAggregateInput>;
  _max?: Maybe<GlobalPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<GlobalPetParentMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalPetParentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<GlobalPetParentOrderByRelevanceInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  primaryPhoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GlobalPetParentRelationFilter = {
  is?: Maybe<GlobalPetParentWhereInput>;
  isNot?: Maybe<GlobalPetParentWhereInput>;
};

export enum GlobalPetParentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  PrimaryPhoneNumber = 'primaryPhoneNumber',
  UpdatedAt = 'updatedAt'
}

export type GlobalPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<GlobalPetParentScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  primaryPhoneNumber?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type GlobalPetParentUncheckedCreateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutGlobalPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUncheckedUpdateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutGlobalPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUpdateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutGlobalPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUpdateOneWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<GlobalPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<GlobalPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput>;
  upsert?: Maybe<GlobalPetParentUpsertWithoutClinicPetParentsInput>;
};

export type GlobalPetParentUpdateWithoutClinicPetParentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalPetParentUpsertWithoutClinicPetParentsInput = {
  create: GlobalPetParentUncheckedCreateWithoutClinicPetParentsInput;
  update: GlobalPetParentUncheckedUpdateWithoutClinicPetParentsInput;
};

export type GlobalPetParentWhereInput = {
  AND?: Maybe<Array<GlobalPetParentWhereInput>>;
  NOT?: Maybe<Array<GlobalPetParentWhereInput>>;
  OR?: Maybe<Array<GlobalPetParentWhereInput>>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  primaryPhoneNumber?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GlobalPetParentWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type HasCarePetWithEnrollmentOutput = {
  __typename?: 'HasCarePetWithEnrollmentOutput';
  hasEnrolledPet: Scalars['Boolean'];
};

export type HasCarePetWithEnrollmentWhereInput = {
  organizationPetParentId: Scalars['String'];
};

export type HasConflictingAppointmentInput = {
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type HillsToHomeApiLog = {
  __typename?: 'HillsToHomeAPILog';
  automationRun?: Maybe<AutomationRun>;
  automationRunId?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt: Scalars['DateTime'];
};

export type HillsToHomeApiLogCountAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogCountAggregateOutputType';
  _all: Scalars['Int'];
  automationRunId: Scalars['Int'];
  channelId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  petParentId: Scalars['Int'];
  products: Scalars['Int'];
  response: Scalars['Int'];
  resultType: Scalars['Int'];
  shipTo: Scalars['Int'];
  species: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type HillsToHomeApiLogCountOrderByAggregateInput = {
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogCreateInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyAutomationRunInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyAutomationRunInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyChannelInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyChannelInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyChannelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyClinicInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicPetInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyClinicPetInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyClinicPetParentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope = {
  data: Array<HillsToHomeApiLogCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HillsToHomeApiLogCreateManyInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
};

export type HillsToHomeApiLogCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
};

export type HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutChannelInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutChannelInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogCreateWithoutAutomationRunInput = {
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateWithoutChannelInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateWithoutClinicInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateWithoutClinicPetInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogCreateWithoutClinicPetParentInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutHillsToHomeApiLogInput>;
  channel?: Maybe<ChannelCreateNestedOneWithoutHillsToHomeApiLogInput>;
  clinic: ClinicCreateNestedOneWithoutHillsToHomeApiLogInput;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutHillsToHomeApiLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogListRelationFilter = {
  every?: Maybe<HillsToHomeApiLogWhereInput>;
  none?: Maybe<HillsToHomeApiLogWhereInput>;
  some?: Maybe<HillsToHomeApiLogWhereInput>;
};

export type HillsToHomeApiLogMaxAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogMaxAggregateOutputType';
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogMaxOrderByAggregateInput = {
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogMinAggregateOutputType = {
  __typename?: 'HillsToHomeAPILogMinAggregateOutputType';
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogMinOrderByAggregateInput = {
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum HillsToHomeApiLogOrderByRelevanceFieldEnum {
  AutomationRunId = 'automationRunId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  Id = 'id',
  PetParentId = 'petParentId',
  Products = 'products',
  ShipTo = 'shipTo'
}

export type HillsToHomeApiLogOrderByRelevanceInput = {
  fields: Array<HillsToHomeApiLogOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type HillsToHomeApiLogOrderByWithAggregationInput = {
  _count?: Maybe<HillsToHomeApiLogCountOrderByAggregateInput>;
  _max?: Maybe<HillsToHomeApiLogMaxOrderByAggregateInput>;
  _min?: Maybe<HillsToHomeApiLogMinOrderByAggregateInput>;
  automationRunId?: Maybe<SortOrder>;
  channelId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<HillsToHomeApiLogOrderByRelevanceInput>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  automationRunId?: Maybe<SortOrder>;
  channel?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  channelId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  products?: Maybe<SortOrder>;
  response?: Maybe<SortOrder>;
  resultType?: Maybe<SortOrder>;
  shipTo?: Maybe<SortOrder>;
  species?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum HillsToHomeApiLogScalarFieldEnum {
  AutomationRunId = 'automationRunId',
  ChannelId = 'channelId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PetParentId = 'petParentId',
  Products = 'products',
  Response = 'response',
  ResultType = 'resultType',
  ShipTo = 'shipTo',
  Species = 'species',
  UpdatedAt = 'updatedAt'
}

export type HillsToHomeApiLogScalarWhereInput = {
  AND?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  automationRunId?: Maybe<StringNullableFilter>;
  channelId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  products?: Maybe<StringNullableFilter>;
  response?: Maybe<JsonFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeFilter>;
  shipTo?: Maybe<StringNullableFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type HillsToHomeApiLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogScalarWhereWithAggregatesInput>>;
  automationRunId?: Maybe<StringNullableWithAggregatesFilter>;
  channelId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  products?: Maybe<StringNullableWithAggregatesFilter>;
  response?: Maybe<JsonWithAggregatesFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeWithAggregatesFilter>;
  shipTo?: Maybe<StringNullableWithAggregatesFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type HillsToHomeApiLogUncheckedCreateInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutChannelInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
};

export type HillsToHomeApiLogUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutChannelInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response: Scalars['Json'];
  resultType: HillsToHomeResultType;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateManyInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput = {
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutChannelInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput = {
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
  where: HillsToHomeApiLogScalarWhereInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput = {
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
  where: HillsToHomeApiLogScalarWhereInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput = {
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
  where: HillsToHomeApiLogScalarWhereInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput = {
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
  where: HillsToHomeApiLogScalarWhereInput;
};

export type HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: HillsToHomeApiLogUncheckedUpdateManyWithoutHillsToHomeApiLogInput;
  where: HillsToHomeApiLogScalarWhereInput;
};

export type HillsToHomeApiLogUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutChannelNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutChannelInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutChannelInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyChannelInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type HillsToHomeApiLogUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HillsToHomeApiLogCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<HillsToHomeApiLogCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<HillsToHomeApiLogCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HillsToHomeApiLogScalarWhereInput>>;
  disconnect?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  set?: Maybe<Array<HillsToHomeApiLogWhereUniqueInput>>;
  update?: Maybe<Array<HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<HillsToHomeApiLogUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutAutomationRunInput = {
  data: HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutChannelInput = {
  data: HillsToHomeApiLogUncheckedUpdateWithoutChannelInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicInput = {
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpdateWithoutAutomationRunInput = {
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateWithoutChannelInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateWithoutClinicInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateWithoutClinicPetInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpdateWithoutClinicPetParentInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  channel?: Maybe<ChannelUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutHillsToHomeApiLogNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutHillsToHomeApiLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['Json']>;
  resultType?: Maybe<HillsToHomeResultType>;
  shipTo?: Maybe<Scalars['String']>;
  species?: Maybe<HillsToHomeSpecies>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutAutomationRunInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutAutomationRunInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutAutomationRunInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutChannelInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutChannelInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutChannelInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: HillsToHomeApiLogUncheckedCreateWithoutClinicPetParentInput;
  update: HillsToHomeApiLogUncheckedUpdateWithoutClinicPetParentInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};

export type HillsToHomeApiLogWhereInput = {
  AND?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  NOT?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  OR?: Maybe<Array<HillsToHomeApiLogWhereInput>>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  automationRunId?: Maybe<StringNullableFilter>;
  channel?: Maybe<ChannelWhereInput>;
  channelId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  products?: Maybe<StringNullableFilter>;
  response?: Maybe<JsonFilter>;
  resultType?: Maybe<EnumHillsToHomeResultTypeFilter>;
  shipTo?: Maybe<StringNullableFilter>;
  species?: Maybe<EnumHillsToHomeSpeciesNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type HillsToHomeApiLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum HillsToHomeResultType {
  Error = 'Error',
  Existing = 'Existing',
  New = 'New'
}

export enum HillsToHomeSpecies {
  Cat = 'Cat',
  Dog = 'Dog'
}

export type IdInput = {
  id: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Image = {
  __typename?: 'Image';
  _count: ImageCountOutputType;
  consultation: Array<Consultation>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  source: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


/** @deprecated - 1.0 */
export type ImageConsultationArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};

export type ImageCountAggregateOutputType = {
  __typename?: 'ImageCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  source: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ImageCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ImageCountOutputType = {
  __typename?: 'ImageCountOutputType';
  consultation: Scalars['Int'];
};

export type ImageCreateInput = {
  consultation?: Maybe<ConsultationCreateNestedManyWithoutPicsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
};

export type ImageCreateOrConnectWithoutConsultationInput = {
  create: ImageUncheckedCreateWithoutConsultationInput;
  where: ImageWhereUniqueInput;
};

export type ImageCreateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageListRelationFilter = {
  every?: Maybe<ImageWhereInput>;
  none?: Maybe<ImageWhereInput>;
  some?: Maybe<ImageWhereInput>;
};

export type ImageMaxAggregateOutputType = {
  __typename?: 'ImageMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ImageMinAggregateOutputType = {
  __typename?: 'ImageMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ImageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ImageOrderByRelevanceFieldEnum {
  Id = 'id',
  Source = 'source'
}

export type ImageOrderByRelevanceInput = {
  fields: Array<ImageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ImageOrderByWithAggregationInput = {
  _count?: Maybe<ImageCountOrderByAggregateInput>;
  _max?: Maybe<ImageMaxOrderByAggregateInput>;
  _min?: Maybe<ImageMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ImageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ImageOrderByRelevanceInput>;
  consultation?: Maybe<ConsultationOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ImageScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Source = 'source',
  UpdatedAt = 'updatedAt'
}

export type ImageScalarWhereInput = {
  AND?: Maybe<Array<ImageScalarWhereInput>>;
  NOT?: Maybe<Array<ImageScalarWhereInput>>;
  OR?: Maybe<Array<ImageScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  source?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ImageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ImageScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  source?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ImageUncheckedCreateInput = {
  consultation?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPicsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUncheckedCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
};

export type ImageUncheckedCreateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUncheckedUpdateInput = {
  consultation?: Maybe<ConsultationUncheckedUpdateManyWithoutPicsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUncheckedUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type ImageUncheckedUpdateManyWithoutPicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUncheckedUpdateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUpdateInput = {
  consultation?: Maybe<ConsultationUpdateManyWithoutPicsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUpdateManyWithWhereWithoutConsultationInput = {
  data: ImageUncheckedUpdateManyWithoutPicsInput;
  where: ImageScalarWhereInput;
};

export type ImageUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<ImageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ImageCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<ImageCreateWithoutConsultationInput>>;
  delete?: Maybe<Array<ImageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ImageScalarWhereInput>>;
  disconnect?: Maybe<Array<ImageWhereUniqueInput>>;
  set?: Maybe<Array<ImageWhereUniqueInput>>;
  update?: Maybe<Array<ImageUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<ImageUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<ImageUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type ImageUpdateWithWhereUniqueWithoutConsultationInput = {
  data: ImageUncheckedUpdateWithoutConsultationInput;
  where: ImageWhereUniqueInput;
};

export type ImageUpdateWithoutConsultationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ImageUpsertWithWhereUniqueWithoutConsultationInput = {
  create: ImageUncheckedCreateWithoutConsultationInput;
  update: ImageUncheckedUpdateWithoutConsultationInput;
  where: ImageWhereUniqueInput;
};

export type ImageWhereInput = {
  AND?: Maybe<Array<ImageWhereInput>>;
  NOT?: Maybe<Array<ImageWhereInput>>;
  OR?: Maybe<Array<ImageWhereInput>>;
  consultation?: Maybe<ConsultationListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  source?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ImageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type InformAudienceDefinition = {
  __typename?: 'InformAudienceDefinition';
  _count: InformAudienceDefinitionCountOutputType;
  canonicalBreeds: Array<CanonicalBreed>;
  canonicalServices: Array<CanonicalService>;
  canonicalSpecies: Array<CanonicalSpecies>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaigns: Array<InformCampaign>;
  informPartner?: Maybe<InformPartner>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt: Scalars['DateTime'];
};


export type InformAudienceDefinitionCanonicalBreedsArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  distinct?: Maybe<CanonicalBreedScalarFieldEnum>;
  orderBy?: Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type InformAudienceDefinitionCanonicalServicesArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<CanonicalServiceScalarFieldEnum>;
  orderBy?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type InformAudienceDefinitionCanonicalSpeciesArgs = {
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  distinct?: Maybe<CanonicalSpeciesScalarFieldEnum>;
  orderBy?: Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type InformAudienceDefinitionInformCampaignsArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};

export type InformAudienceDefinitionCountAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformAudienceDefinitionCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionCountOutputType = {
  __typename?: 'InformAudienceDefinitionCountOutputType';
  canonicalBreeds: Scalars['Int'];
  canonicalServices: Scalars['Int'];
  canonicalSpecies: Scalars['Int'];
  informCampaigns: Scalars['Int'];
};

export type InformAudienceDefinitionCreateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateManyInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateManyInformPartnerInputEnvelope = {
  data: Array<InformAudienceDefinitionCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformAudienceDefinitionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalBreedsInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalServicesInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutCanonicalSpeciesInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
};

export type InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
};

export type InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput = {
  connect?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput>;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionCreateWithoutCanonicalBreedsInput = {
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateWithoutCanonicalServicesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateWithoutInformCampaignsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionCreateWithoutInformPartnerInput = {
  canonicalBreeds?: Maybe<CanonicalBreedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionListRelationFilter = {
  every?: Maybe<InformAudienceDefinitionWhereInput>;
  none?: Maybe<InformAudienceDefinitionWhereInput>;
  some?: Maybe<InformAudienceDefinitionWhereInput>;
};

export type InformAudienceDefinitionMaxAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionMinAggregateOutputType = {
  __typename?: 'InformAudienceDefinitionMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformAudienceDefinitionOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name'
}

export type InformAudienceDefinitionOrderByRelevanceInput = {
  fields: Array<InformAudienceDefinitionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformAudienceDefinitionOrderByWithAggregationInput = {
  _count?: Maybe<InformAudienceDefinitionCountOrderByAggregateInput>;
  _max?: Maybe<InformAudienceDefinitionMaxOrderByAggregateInput>;
  _min?: Maybe<InformAudienceDefinitionMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformAudienceDefinitionOrderByRelevanceInput>;
  canonicalBreeds?: Maybe<CanonicalBreedOrderByRelationAggregateInput>;
  canonicalServices?: Maybe<CanonicalServiceOrderByRelationAggregateInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformAudienceDefinitionRelationFilter = {
  is?: Maybe<InformAudienceDefinitionWhereInput>;
  isNot?: Maybe<InformAudienceDefinitionWhereInput>;
};

export enum InformAudienceDefinitionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Settings = 'settings',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InformAudienceDefinitionScalarWhereInput = {
  AND?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformAudienceDefinitionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformAudienceDefinitionStatus {
  Active = 'Active',
  Archived = 'Archived',
  Paused = 'Paused'
}

export enum InformAudienceDefinitionType {
  FinalDose = 'FinalDose',
  FirstPurchase = 'FirstPurchase',
  Lapsed = 'Lapsed',
  LapsedOrNonUser = 'LapsedOrNonUser',
  NonUser = 'NonUser'
}

export type InformAudienceDefinitionUncheckedCreateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalBreedsInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalServicesInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutCanonicalSpeciesInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
};

export type InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput = {
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedCreateNestedManyWithoutInformAudienceDefinitionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformAudienceDefinitionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformAudienceDefinitionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalBreedsNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalServicesNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutCanonicalSpeciesNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput = {
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUncheckedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput = {
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
  where: InformAudienceDefinitionScalarWhereInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput = {
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
  where: InformAudienceDefinitionScalarWhereInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput = {
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
  where: InformAudienceDefinitionScalarWhereInput;
};

export type InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformAudienceDefinitionUncheckedUpdateManyWithoutInformAudienceDefinitionsInput;
  where: InformAudienceDefinitionScalarWhereInput;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalBreedsNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalBreedsInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalBreedsInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalBreedsInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalServicesNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalServicesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalServicesInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalServicesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutCanonicalSpeciesNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutCanonicalSpeciesInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutCanonicalSpeciesInput>>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutCanonicalSpeciesInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput>>;
};

export type InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAudienceDefinitionCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformAudienceDefinitionCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformAudienceDefinitionCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAudienceDefinitionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  set?: Maybe<Array<InformAudienceDefinitionWhereUniqueInput>>;
  update?: Maybe<Array<InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformAudienceDefinitionUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput = {
  connect?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAudienceDefinitionCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput>;
  update?: Maybe<InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput>;
  upsert?: Maybe<InformAudienceDefinitionUpsertWithoutInformCampaignsInput>;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalBreedsInput = {
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalServicesInput = {
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutCanonicalSpeciesInput = {
  data: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalBreedsInput = {
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalServicesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateWithoutCanonicalSpeciesInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateWithoutInformCampaignsInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpdateWithoutInformPartnerInput = {
  canonicalBreeds?: Maybe<CanonicalBreedUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  canonicalSpecies?: Maybe<CanonicalSpeciesUpdateManyWithoutInformAudienceDefinitionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformAudienceDefinitionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformAudienceDefinitionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalBreedsInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalBreedsInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalBreedsInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalServicesInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalServicesInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalServicesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutCanonicalSpeciesInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutCanonicalSpeciesInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutCanonicalSpeciesInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutInformPartnerInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutInformPartnerInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};

export type InformAudienceDefinitionUpsertWithoutInformCampaignsInput = {
  create: InformAudienceDefinitionUncheckedCreateWithoutInformCampaignsInput;
  update: InformAudienceDefinitionUncheckedUpdateWithoutInformCampaignsInput;
};

export type InformAudienceDefinitionWhereInput = {
  AND?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  NOT?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  OR?: Maybe<Array<InformAudienceDefinitionWhereInput>>;
  canonicalBreeds?: Maybe<CanonicalBreedListRelationFilter>;
  canonicalServices?: Maybe<CanonicalServiceListRelationFilter>;
  canonicalSpecies?: Maybe<CanonicalSpeciesListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformAudienceDefinitionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumInformAudienceDefinitionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformAudienceDefinitionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaign = {
  __typename?: 'InformAutomationCampaign';
  id: Scalars['String'];
  informCampaign: InformCampaign;
  workflowEventSetting: WorkflowEventSetting;
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignCountAggregateOutputType = {
  __typename?: 'InformAutomationCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
};

export type InformAutomationCampaignCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignCreateInput = {
  informCampaign: InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput;
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput;
};

export type InformAutomationCampaignCreateManyInput = {
  id: Scalars['String'];
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignCreateManyWorkflowEventSettingInput = {
  id: Scalars['String'];
};

export type InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<InformAutomationCampaignCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
};

export type InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput = {
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
};

export type InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput = {
  create: InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput;
  where: InformAutomationCampaignWhereUniqueInput;
};

export type InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput = {
  create: InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput;
  where: InformAutomationCampaignWhereUniqueInput;
};

export type InformAutomationCampaignCreateWithoutInformCampaignInput = {
  workflowEventSetting: WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput;
};

export type InformAutomationCampaignCreateWithoutWorkflowEventSettingInput = {
  informCampaign: InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput;
};

export type InformAutomationCampaignListRelationFilter = {
  every?: Maybe<InformAutomationCampaignWhereInput>;
  none?: Maybe<InformAutomationCampaignWhereInput>;
  some?: Maybe<InformAutomationCampaignWhereInput>;
};

export type InformAutomationCampaignMaxAggregateOutputType = {
  __typename?: 'InformAutomationCampaignMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignMinAggregateOutputType = {
  __typename?: 'InformAutomationCampaignMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformAutomationCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type InformAutomationCampaignOrderByRelevanceInput = {
  fields: Array<InformAutomationCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformAutomationCampaignOrderByWithAggregationInput = {
  _count?: Maybe<InformAutomationCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformAutomationCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformAutomationCampaignMinOrderByAggregateInput>;
  id?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformAutomationCampaignOrderByRelevanceInput>;
  id?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSettingId?: Maybe<SortOrder>;
};

export type InformAutomationCampaignRelationFilter = {
  is?: Maybe<InformAutomationCampaignWhereInput>;
  isNot?: Maybe<InformAutomationCampaignWhereInput>;
};

export enum InformAutomationCampaignScalarFieldEnum {
  Id = 'id',
  WorkflowEventSettingId = 'workflowEventSettingId'
}

export type InformAutomationCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  workflowEventSettingId?: Maybe<StringFilter>;
};

export type InformAutomationCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformAutomationCampaignScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringWithAggregatesFilter>;
};

export type InformAutomationCampaignUncheckedCreateInput = {
  id: Scalars['String'];
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
};

export type InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput = {
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
};

export type InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput = {
  workflowEventSettingId: Scalars['String'];
};

export type InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput = {
  id: Scalars['String'];
};

export type InformAutomationCampaignUncheckedUpdateInput = {
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyWithoutInformAutomationCampaignsInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput = {
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput>;
  upsert?: Maybe<InformAutomationCampaignUpsertWithoutInformCampaignInput>;
};

export type InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput = {
  workflowEventSettingId?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformAutomationCampaignUpdateInput = {
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput>;
};

export type InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  data: InformAutomationCampaignUncheckedUpdateManyWithoutInformAutomationCampaignsInput;
  where: InformAutomationCampaignScalarWhereInput;
};

export type InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformAutomationCampaignCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<InformAutomationCampaignCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<InformAutomationCampaignCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformAutomationCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformAutomationCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<InformAutomationCampaignUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput = {
  connect?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformAutomationCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput>;
  upsert?: Maybe<InformAutomationCampaignUpsertWithoutInformCampaignInput>;
};

export type InformAutomationCampaignUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  data: InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: InformAutomationCampaignWhereUniqueInput;
};

export type InformAutomationCampaignUpdateWithoutInformCampaignInput = {
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput>;
};

export type InformAutomationCampaignUpdateWithoutWorkflowEventSettingInput = {
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput>;
};

export type InformAutomationCampaignUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  create: InformAutomationCampaignUncheckedCreateWithoutWorkflowEventSettingInput;
  update: InformAutomationCampaignUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: InformAutomationCampaignWhereUniqueInput;
};

export type InformAutomationCampaignUpsertWithoutInformCampaignInput = {
  create: InformAutomationCampaignUncheckedCreateWithoutInformCampaignInput;
  update: InformAutomationCampaignUncheckedUpdateWithoutInformCampaignInput;
};

export type InformAutomationCampaignWhereInput = {
  AND?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  NOT?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  OR?: Maybe<Array<InformAutomationCampaignWhereInput>>;
  id?: Maybe<StringFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  workflowEventSettingId?: Maybe<StringFilter>;
};

export type InformAutomationCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformCampaign = {
  __typename?: 'InformCampaign';
  _count: InformCampaignCountOutputType;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informAudienceDefinition: InformAudienceDefinition;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaign>;
  informCampaignEnrollments: Array<InformCampaignEnrollment>;
  informCampaignExecutions: Array<InformCampaignExecution>;
  informCampaignGroup?: Maybe<InformCampaignGroup>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgram: InformPartnerProgram;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaign>;
  name: Scalars['String'];
  status: InformCampaignStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt: Scalars['DateTime'];
};


export type InformCampaignInformCampaignEnrollmentsArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  distinct?: Maybe<InformCampaignEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type InformCampaignInformCampaignExecutionsArgs = {
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  distinct?: Maybe<InformCampaignExecutionScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};

export type InformCampaignCountAggregateOutputType = {
  __typename?: 'InformCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayName: Scalars['Int'];
  id: Scalars['Int'];
  informAudienceDefinitionId: Scalars['Int'];
  informCampaignGroupId: Scalars['Int'];
  informPartnerProgramId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformCampaignCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignCountOutputType = {
  __typename?: 'InformCampaignCountOutputType';
  informCampaignEnrollments: Scalars['Int'];
  informCampaignExecutions: Scalars['Int'];
};

export type InformCampaignCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateManyInformAudienceDefinitionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateManyInformAudienceDefinitionInputEnvelope = {
  data: Array<InformCampaignCreateManyInformAudienceDefinitionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInformCampaignGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateManyInformCampaignGroupInputEnvelope = {
  data: Array<InformCampaignCreateManyInformCampaignGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInformPartnerProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateManyInformPartnerProgramInputEnvelope = {
  data: Array<InformCampaignCreateManyInformPartnerProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateNestedManyWithoutInformAudienceDefinitionInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
};

export type InformCampaignCreateNestedManyWithoutInformCampaignGroupInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
};

export type InformCampaignCreateNestedManyWithoutInformPartnerProgramInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
};

export type InformCampaignCreateNestedOneWithoutInformAutomationCampaignInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput>;
};

export type InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
};

export type InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput>;
};

export type InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput>;
};

export type InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput = {
  create: InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput = {
  create: InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformCampaignGroupInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignGroupInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformPartnerProgramInput = {
  create: InformCampaignUncheckedCreateWithoutInformPartnerProgramInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput = {
  create: InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignCreateWithoutInformAudienceDefinitionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformAutomationCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformCampaignEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformCampaignExecutionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformCampaignGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformPartnerProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignCreateWithoutInformScheduledCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition: InformAudienceDefinitionCreateNestedOneWithoutInformCampaignsInput;
  informAutomationCampaign?: Maybe<InformAutomationCampaignCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollment = {
  __typename?: 'InformCampaignEnrollment';
  _count: InformCampaignEnrollmentCountOutputType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  informCampaign: InformCampaign;
  informCampaignEnrollmentCanonicalServices: Array<InformCampaignEnrollmentCanonicalService>;
  informCampaignId: Scalars['String'];
  informPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type InformCampaignEnrollmentInformCampaignEnrollmentCanonicalServicesArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};

export type InformCampaignEnrollmentCanonicalService = {
  __typename?: 'InformCampaignEnrollmentCanonicalService';
  canonicalService: CanonicalService;
  canonicalServiceId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  informCampaignEnrollment: InformCampaignEnrollment;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};

export type InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceCountAggregateOutputType';
  _all: Scalars['Int'];
  canonicalServiceId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  informCampaignEnrollmentId: Scalars['Int'];
  onlinePharmacyUrl: Scalars['Int'];
  settings: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformCampaignEnrollmentCanonicalServiceCountOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateInput = {
  canonicalService: CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollment: InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope = {
  data: Array<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInput = {
  canonicalServiceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope = {
  data: Array<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateManyInput = {
  canonicalServiceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutCanonicalServiceInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput = {
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput = {
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollment: InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput = {
  canonicalService: CanonicalServiceCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceInformCampaignEnrollmentIdCanonicalServiceIdCompoundUniqueInput = {
  canonicalServiceId: Scalars['String'];
  informCampaignEnrollmentId: Scalars['String'];
};

export type InformCampaignEnrollmentCanonicalServiceListRelationFilter = {
  every?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  none?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
  some?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};

export type InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceMaxAggregateOutputType';
  canonicalServiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceMaxOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCanonicalServiceMinAggregateOutputType';
  canonicalServiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceMinOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignEnrollmentCanonicalServiceOrderByRelevanceFieldEnum {
  CanonicalServiceId = 'canonicalServiceId',
  Id = 'id',
  InformCampaignEnrollmentId = 'informCampaignEnrollmentId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl'
}

export type InformCampaignEnrollmentCanonicalServiceOrderByRelevanceInput = {
  fields: Array<InformCampaignEnrollmentCanonicalServiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformCampaignEnrollmentCanonicalServiceOrderByWithAggregationInput = {
  _count?: Maybe<InformCampaignEnrollmentCanonicalServiceCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignEnrollmentCanonicalServiceMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignEnrollmentCanonicalServiceMinOrderByAggregateInput>;
  canonicalServiceId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelevanceInput>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  canonicalServiceId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollmentId?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformCampaignEnrollmentCanonicalServiceScalarFieldEnum {
  CanonicalServiceId = 'canonicalServiceId',
  CreatedAt = 'createdAt',
  Id = 'id',
  InformCampaignEnrollmentId = 'informCampaignEnrollmentId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  Settings = 'settings',
  UpdatedAt = 'updatedAt'
}

export type InformCampaignEnrollmentCanonicalServiceScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  canonicalServiceId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaignEnrollmentId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereWithAggregatesInput>>;
  canonicalServiceId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informCampaignEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateInput = {
  canonicalServiceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId: Scalars['String'];
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput = {
  canonicalServiceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput = {
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput;
  where: InformCampaignEnrollmentCanonicalServiceScalarWhereInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput = {
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentCanonicalServicesInput;
  where: InformCampaignEnrollmentCanonicalServiceScalarWhereInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyCanonicalServiceInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateOrConnectWithoutInformCampaignEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceCreateWithoutInformCampaignEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateManyInformCampaignEnrollmentInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpdateManyWithWhereWithoutInformCampaignEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput>>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput = {
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithWhereUniqueWithoutInformCampaignEnrollmentInput = {
  data: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithoutCanonicalServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpdateWithoutInformCampaignEnrollmentInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput = {
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutCanonicalServiceInput;
  update: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutCanonicalServiceInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceUpsertWithWhereUniqueWithoutInformCampaignEnrollmentInput = {
  create: InformCampaignEnrollmentCanonicalServiceUncheckedCreateWithoutInformCampaignEnrollmentInput;
  update: InformCampaignEnrollmentCanonicalServiceUncheckedUpdateWithoutInformCampaignEnrollmentInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};

export type InformCampaignEnrollmentCanonicalServiceWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentCanonicalServiceWhereInput>>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
  canonicalServiceId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaignEnrollment?: Maybe<InformCampaignEnrollmentWhereInput>;
  informCampaignEnrollmentId?: Maybe<StringFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignEnrollmentCanonicalServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentId_canonicalServiceId?: Maybe<InformCampaignEnrollmentCanonicalServiceInformCampaignEnrollmentIdCanonicalServiceIdCompoundUniqueInput>;
};

export type InformCampaignEnrollmentCountAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  informCampaignId: Scalars['Int'];
  informPartnerProgramEnrollmentId: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformCampaignEnrollmentCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentCountOutputType = {
  __typename?: 'InformCampaignEnrollmentCountOutputType';
  informCampaignEnrollmentCanonicalServices: Scalars['Int'];
};

export type InformCampaignEnrollmentCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateManyInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope = {
  data: Array<InformCampaignEnrollmentCreateManyInformCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope = {
  data: Array<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignEnrollmentCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateNestedManyWithoutInformCampaignInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
};

export type InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
};

export type InformCampaignEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  connect?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  create?: Maybe<InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  informPartnerProgramEnrollment: InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignEnrollmentsInput;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentInformPartnerProgramEnrollmentIdInformCampaignIdCompoundUniqueInput = {
  informCampaignId: Scalars['String'];
  informPartnerProgramEnrollmentId: Scalars['String'];
};

export type InformCampaignEnrollmentListRelationFilter = {
  every?: Maybe<InformCampaignEnrollmentWhereInput>;
  none?: Maybe<InformCampaignEnrollmentWhereInput>;
  some?: Maybe<InformCampaignEnrollmentWhereInput>;
};

export type InformCampaignEnrollmentMaxAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  status?: Maybe<InformEnrollmentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentMinAggregateOutputType = {
  __typename?: 'InformCampaignEnrollmentMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  status?: Maybe<InformEnrollmentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignEnrollmentOrderByRelevanceFieldEnum {
  Id = 'id',
  InformCampaignId = 'informCampaignId',
  InformPartnerProgramEnrollmentId = 'informPartnerProgramEnrollmentId'
}

export type InformCampaignEnrollmentOrderByRelevanceInput = {
  fields: Array<InformCampaignEnrollmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformCampaignEnrollmentOrderByWithAggregationInput = {
  _count?: Maybe<InformCampaignEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignEnrollmentMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformCampaignEnrollmentOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceOrderByRelationAggregateInput>;
  informCampaignId?: Maybe<SortOrder>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgramEnrollmentId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignEnrollmentRelationFilter = {
  is?: Maybe<InformCampaignEnrollmentWhereInput>;
  isNot?: Maybe<InformCampaignEnrollmentWhereInput>;
};

export enum InformCampaignEnrollmentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InformCampaignId = 'informCampaignId',
  InformPartnerProgramEnrollmentId = 'informPartnerProgramEnrollmentId',
  Settings = 'settings',
  Status = 'status',
  StatusMessage = 'statusMessage',
  UpdatedAt = 'updatedAt'
}

export type InformCampaignEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaignId?: Maybe<StringFilter>;
  informPartnerProgramEnrollmentId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informCampaignId?: Maybe<StringWithAggregatesFilter>;
  informPartnerProgramEnrollmentId?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformEnrollmentStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformCampaignEnrollmentUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  informCampaignId: Scalars['String'];
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
};

export type InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  informPartnerProgramEnrollmentId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedCreateNestedManyWithoutInformCampaignEnrollmentInput>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  informCampaignId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput>>;
};

export type InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  informPartnerProgramEnrollmentId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUncheckedUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput = {
  data: InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput;
  where: InformCampaignEnrollmentScalarWhereInput;
};

export type InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput = {
  data: InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignEnrollmentsInput;
  where: InformCampaignEnrollmentScalarWhereInput;
};

export type InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformCampaignInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput>>;
};

export type InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput = {
  connect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignEnrollmentCreateOrConnectWithoutInformPartnerProgramEnrollmentInput>>;
  create?: Maybe<Array<InformCampaignEnrollmentCreateWithoutInformPartnerProgramEnrollmentInput>>;
  createMany?: Maybe<InformCampaignEnrollmentCreateManyInformPartnerProgramEnrollmentInputEnvelope>;
  delete?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
  updateMany?: Maybe<Array<InformCampaignEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramEnrollmentInput>>;
  upsert?: Maybe<Array<InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput>>;
};

export type InformCampaignEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentCanonicalServicesNestedInput = {
  connect?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  create?: Maybe<InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  update?: Maybe<InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput>;
  upsert?: Maybe<InformCampaignEnrollmentUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput>;
};

export type InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformCampaignInput = {
  data: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput = {
  data: InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpdateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpdateWithoutInformPartnerProgramEnrollmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceUpdateManyWithoutInformCampaignEnrollmentNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformCampaignInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignInput;
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramEnrollmentInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformPartnerProgramEnrollmentInput;
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformPartnerProgramEnrollmentInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};

export type InformCampaignEnrollmentUpsertWithoutInformCampaignEnrollmentCanonicalServicesInput = {
  create: InformCampaignEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentCanonicalServicesInput;
  update: InformCampaignEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentCanonicalServicesInput;
};

export type InformCampaignEnrollmentWhereInput = {
  AND?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  NOT?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  OR?: Maybe<Array<InformCampaignEnrollmentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  informCampaignEnrollmentCanonicalServices?: Maybe<InformCampaignEnrollmentCanonicalServiceListRelationFilter>;
  informCampaignId?: Maybe<StringFilter>;
  informPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  informPartnerProgramEnrollmentId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignEnrollmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentId_informCampaignId?: Maybe<InformCampaignEnrollmentInformPartnerProgramEnrollmentIdInformCampaignIdCompoundUniqueInput>;
};

export type InformCampaignExecution = {
  __typename?: 'InformCampaignExecution';
  _count: InformCampaignExecutionCountOutputType;
  createdAt: Scalars['DateTime'];
  emailCampaigns: Array<EmailCampaign>;
  executeAt: Scalars['DateTime'];
  id: Scalars['String'];
  informCampaign: InformCampaign;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type InformCampaignExecutionEmailCampaignsArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};

export type InformCampaignExecutionCountAggregateOutputType = {
  __typename?: 'InformCampaignExecutionCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  executeAt: Scalars['Int'];
  id: Scalars['Int'];
  informCampaignId: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformCampaignExecutionCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignExecutionCountOutputType = {
  __typename?: 'InformCampaignExecutionCountOutputType';
  emailCampaigns: Scalars['Int'];
};

export type InformCampaignExecutionCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionCreateManyInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionCreateManyInformCampaignInputEnvelope = {
  data: Array<InformCampaignExecutionCreateManyInformCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignExecutionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionCreateNestedManyWithoutInformCampaignInput = {
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
};

export type InformCampaignExecutionCreateNestedOneWithoutEmailCampaignsInput = {
  connect?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput>;
  create?: Maybe<InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput>;
};

export type InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput = {
  create: InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput;
  where: InformCampaignExecutionWhereUniqueInput;
};

export type InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput = {
  create: InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput;
  where: InformCampaignExecutionWhereUniqueInput;
};

export type InformCampaignExecutionCreateWithoutEmailCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformCampaignExecutionsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionCreateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignCreateNestedManyWithoutInformCampaignExecutionInput>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionListRelationFilter = {
  every?: Maybe<InformCampaignExecutionWhereInput>;
  none?: Maybe<InformCampaignExecutionWhereInput>;
  some?: Maybe<InformCampaignExecutionWhereInput>;
};

export type InformCampaignExecutionMaxAggregateOutputType = {
  __typename?: 'InformCampaignExecutionMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignExecutionMinAggregateOutputType = {
  __typename?: 'InformCampaignExecutionMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignExecutionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignExecutionOrderByRelevanceFieldEnum {
  Id = 'id',
  InformCampaignId = 'informCampaignId'
}

export type InformCampaignExecutionOrderByRelevanceInput = {
  fields: Array<InformCampaignExecutionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformCampaignExecutionOrderByWithAggregationInput = {
  _count?: Maybe<InformCampaignExecutionCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignExecutionMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignExecutionMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformCampaignExecutionOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  emailCampaigns?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignExecutionRelationFilter = {
  is?: Maybe<InformCampaignExecutionWhereInput>;
  isNot?: Maybe<InformCampaignExecutionWhereInput>;
};

export enum InformCampaignExecutionScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExecuteAt = 'executeAt',
  Id = 'id',
  InformCampaignId = 'informCampaignId',
  Settings = 'settings',
  Status = 'status',
  StatusMessage = 'statusMessage',
  UpdatedAt = 'updatedAt'
}

export type InformCampaignExecutionScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  executeAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaignId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignExecutionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignExecutionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  executeAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informCampaignId?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformCampaignExecutionStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Running = 'Running',
  Scheduled = 'Scheduled',
  Skipped = 'Skipped'
}

export type InformCampaignExecutionUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput = {
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
};

export type InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  informCampaignId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutInformCampaignExecutionInput>;
  executeAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status: InformCampaignExecutionStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignExecutionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput = {
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  delete?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput>>;
};

export type InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaignId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUncheckedUpdateManyWithoutInformCampaignExecutionNestedInput>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput = {
  data: InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignExecutionsInput;
  where: InformCampaignExecutionScalarWhereInput;
};

export type InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput = {
  connect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignExecutionCreateOrConnectWithoutInformCampaignInput>>;
  create?: Maybe<Array<InformCampaignExecutionCreateWithoutInformCampaignInput>>;
  createMany?: Maybe<InformCampaignExecutionCreateManyInformCampaignInputEnvelope>;
  delete?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignExecutionScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignExecutionWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput>>;
  updateMany?: Maybe<Array<InformCampaignExecutionUpdateManyWithWhereWithoutInformCampaignInput>>;
  upsert?: Maybe<Array<InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput>>;
};

export type InformCampaignExecutionUpdateOneWithoutEmailCampaignsNestedInput = {
  connect?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignExecutionCreateOrConnectWithoutEmailCampaignsInput>;
  create?: Maybe<InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput>;
  upsert?: Maybe<InformCampaignExecutionUpsertWithoutEmailCampaignsInput>;
};

export type InformCampaignExecutionUpdateWithWhereUniqueWithoutInformCampaignInput = {
  data: InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput;
  where: InformCampaignExecutionWhereUniqueInput;
};

export type InformCampaignExecutionUpdateWithoutEmailCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUpdateWithoutInformCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailCampaigns?: Maybe<EmailCampaignUpdateManyWithoutInformCampaignExecutionNestedInput>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformCampaignExecutionStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignExecutionUpsertWithWhereUniqueWithoutInformCampaignInput = {
  create: InformCampaignExecutionUncheckedCreateWithoutInformCampaignInput;
  update: InformCampaignExecutionUncheckedUpdateWithoutInformCampaignInput;
  where: InformCampaignExecutionWhereUniqueInput;
};

export type InformCampaignExecutionUpsertWithoutEmailCampaignsInput = {
  create: InformCampaignExecutionUncheckedCreateWithoutEmailCampaignsInput;
  update: InformCampaignExecutionUncheckedUpdateWithoutEmailCampaignsInput;
};

export type InformCampaignExecutionWhereInput = {
  AND?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  NOT?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  OR?: Maybe<Array<InformCampaignExecutionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  emailCampaigns?: Maybe<EmailCampaignListRelationFilter>;
  executeAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  informCampaignId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformCampaignExecutionStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignExecutionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformCampaignGroup = {
  __typename?: 'InformCampaignGroup';
  _count: InformCampaignGroupCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaigns: Array<InformCampaign>;
  informPartner: InformPartner;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InformCampaignGroupInformCampaignsArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};

export type InformCampaignGroupCountAggregateOutputType = {
  __typename?: 'InformCampaignGroupCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  displayName: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformCampaignGroupCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignGroupCountOutputType = {
  __typename?: 'InformCampaignGroupCountOutputType';
  informCampaigns: Scalars['Int'];
};

export type InformCampaignGroupCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformCampaignGroupInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupCreateManyInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupCreateManyInformPartnerInputEnvelope = {
  data: Array<InformCampaignGroupCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformCampaignGroupCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
};

export type InformCampaignGroupCreateNestedOneWithoutInformCampaignsInput = {
  connect?: Maybe<InformCampaignGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput>;
};

export type InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput = {
  create: InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput;
  where: InformCampaignGroupWhereUniqueInput;
};

export type InformCampaignGroupCreateOrConnectWithoutInformPartnerInput = {
  create: InformCampaignGroupUncheckedCreateWithoutInformPartnerInput;
  where: InformCampaignGroupWhereUniqueInput;
};

export type InformCampaignGroupCreateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformCampaignGroupInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupListRelationFilter = {
  every?: Maybe<InformCampaignGroupWhereInput>;
  none?: Maybe<InformCampaignGroupWhereInput>;
  some?: Maybe<InformCampaignGroupWhereInput>;
};

export type InformCampaignGroupMaxAggregateOutputType = {
  __typename?: 'InformCampaignGroupMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignGroupMinAggregateOutputType = {
  __typename?: 'InformCampaignGroupMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignGroupNameInformPartnerIdCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
};

export type InformCampaignGroupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignGroupOrderByRelevanceFieldEnum {
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name'
}

export type InformCampaignGroupOrderByRelevanceInput = {
  fields: Array<InformCampaignGroupOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformCampaignGroupOrderByWithAggregationInput = {
  _count?: Maybe<InformCampaignGroupCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignGroupMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignGroupMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformCampaignGroupOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignGroupRelationFilter = {
  is?: Maybe<InformCampaignGroupWhereInput>;
  isNot?: Maybe<InformCampaignGroupWhereInput>;
};

export enum InformCampaignGroupScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  DisplayName = 'displayName',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type InformCampaignGroupScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignGroupScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignGroupScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformCampaignGroupUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
};

export type InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedUpdateManyWithoutInformCampaignGroupsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformCampaignGroupUncheckedUpdateManyWithoutInformCampaignGroupsInput;
  where: InformCampaignGroupScalarWhereInput;
};

export type InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignGroupCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformCampaignGroupCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformCampaignGroupCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignGroupWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformCampaignGroupUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput = {
  connect?: Maybe<InformCampaignGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignGroupCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput>;
  upsert?: Maybe<InformCampaignGroupUpsertWithoutInformCampaignsInput>;
};

export type InformCampaignGroupUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput;
  where: InformCampaignGroupWhereUniqueInput;
};

export type InformCampaignGroupUpdateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignGroupUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformCampaignGroupUncheckedCreateWithoutInformPartnerInput;
  update: InformCampaignGroupUncheckedUpdateWithoutInformPartnerInput;
  where: InformCampaignGroupWhereUniqueInput;
};

export type InformCampaignGroupUpsertWithoutInformCampaignsInput = {
  create: InformCampaignGroupUncheckedCreateWithoutInformCampaignsInput;
  update: InformCampaignGroupUncheckedUpdateWithoutInformCampaignsInput;
};

export type InformCampaignGroupWhereInput = {
  AND?: Maybe<Array<InformCampaignGroupWhereInput>>;
  NOT?: Maybe<Array<InformCampaignGroupWhereInput>>;
  OR?: Maybe<Array<InformCampaignGroupWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignGroupWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_informPartnerId?: Maybe<InformCampaignGroupNameInformPartnerIdCompoundUniqueInput>;
};

export type InformCampaignListRelationFilter = {
  every?: Maybe<InformCampaignWhereInput>;
  none?: Maybe<InformCampaignWhereInput>;
  some?: Maybe<InformCampaignWhereInput>;
};

export type InformCampaignMaxAggregateOutputType = {
  __typename?: 'InformCampaignMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignMinAggregateOutputType = {
  __typename?: 'InformCampaignMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformCampaignOrderByRelevanceFieldEnum {
  DisplayName = 'displayName',
  Id = 'id',
  InformAudienceDefinitionId = 'informAudienceDefinitionId',
  InformCampaignGroupId = 'informCampaignGroupId',
  InformPartnerProgramId = 'informPartnerProgramId',
  Name = 'name'
}

export type InformCampaignOrderByRelevanceInput = {
  fields: Array<InformCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformCampaignOrderByWithAggregationInput = {
  _count?: Maybe<InformCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformCampaignMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformCampaignOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  informAudienceDefinitionId?: Maybe<SortOrder>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentOrderByRelationAggregateInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionOrderByRelationAggregateInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>;
  informCampaignGroupId?: Maybe<SortOrder>;
  informPartnerProgram?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgramId?: Maybe<SortOrder>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformCampaignRelationFilter = {
  is?: Maybe<InformCampaignWhereInput>;
  isNot?: Maybe<InformCampaignWhereInput>;
};

export enum InformCampaignScalarFieldEnum {
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  Id = 'id',
  InformAudienceDefinitionId = 'informAudienceDefinitionId',
  InformCampaignGroupId = 'informCampaignGroupId',
  InformPartnerProgramId = 'informPartnerProgramId',
  Name = 'name',
  Status = 'status',
  StatusMessage = 'statusMessage',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InformCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformCampaignScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinitionId?: Maybe<StringFilter>;
  informCampaignGroupId?: Maybe<StringNullableFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformCampaignStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumInformCampaignTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformCampaignScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informAudienceDefinitionId?: Maybe<StringWithAggregatesFilter>;
  informCampaignGroupId?: Maybe<StringNullableWithAggregatesFilter>;
  informPartnerProgramId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformCampaignStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumInformCampaignTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformCampaignStatus {
  Active = 'Active',
  Archived = 'Archived',
  Paused = 'Paused'
}

export enum InformCampaignType {
  Automation = 'Automation',
  Scheduled = 'Scheduled'
}

export type InformCampaignUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformAudienceDefinitionInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformCampaignGroupInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
};

export type InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
};

export type InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformCampaignGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informPartnerProgramId: Scalars['String'];
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformPartnerProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId: Scalars['String'];
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedCreateNestedOneWithoutInformCampaignInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedCreateNestedManyWithoutInformCampaignInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type: InformCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformAudienceDefinitionNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformCampaignGroupNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput>>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
};

export type InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitionId?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUncheckedUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroupId?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput = {
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
  where: InformCampaignScalarWhereInput;
};

export type InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput = {
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
  where: InformCampaignScalarWhereInput;
};

export type InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput = {
  data: InformCampaignUncheckedUpdateManyWithoutInformCampaignsInput;
  where: InformCampaignScalarWhereInput;
};

export type InformCampaignUpdateManyWithoutInformAudienceDefinitionNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformAudienceDefinitionInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformAudienceDefinitionInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformAudienceDefinitionInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformAudienceDefinitionInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput>>;
};

export type InformCampaignUpdateManyWithoutInformCampaignGroupNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformCampaignGroupInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformCampaignGroupInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformCampaignGroupInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformCampaignGroupInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput>>;
};

export type InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput = {
  connect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformCampaignCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformCampaignCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformCampaignCreateManyInformPartnerProgramInputEnvelope>;
  delete?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformCampaignUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
};

export type InformCampaignUpdateOneRequiredWithoutInformAutomationCampaignNestedInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformAutomationCampaignInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformAutomationCampaignInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformCampaignEnrollmentsInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformCampaignExecutionsNestedInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformCampaignExecutionsInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformCampaignExecutionsInput>;
};

export type InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput = {
  connect?: Maybe<InformCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformCampaignCreateOrConnectWithoutInformScheduledCampaignInput>;
  create?: Maybe<InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput>;
  update?: Maybe<InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput>;
  upsert?: Maybe<InformCampaignUpsertWithoutInformScheduledCampaignInput>;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformAudienceDefinitionInput = {
  data: InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformCampaignGroupInput = {
  data: InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpdateWithWhereUniqueWithoutInformPartnerProgramInput = {
  data: InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpdateWithoutInformAudienceDefinitionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformAutomationCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformCampaignEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformCampaignExecutionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformCampaignGroupInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformPartnerProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpdateWithoutInformScheduledCampaignInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignUpdateOneWithoutInformCampaignNestedInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionUpdateManyWithoutInformCampaignNestedInput>;
  informCampaignGroup?: Maybe<InformCampaignGroupUpdateOneWithoutInformCampaignsNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformCampaignStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  type?: Maybe<InformCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformAudienceDefinitionInput = {
  create: InformCampaignUncheckedCreateWithoutInformAudienceDefinitionInput;
  update: InformCampaignUncheckedUpdateWithoutInformAudienceDefinitionInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformCampaignGroupInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignGroupInput;
  update: InformCampaignUncheckedUpdateWithoutInformCampaignGroupInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpsertWithWhereUniqueWithoutInformPartnerProgramInput = {
  create: InformCampaignUncheckedCreateWithoutInformPartnerProgramInput;
  update: InformCampaignUncheckedUpdateWithoutInformPartnerProgramInput;
  where: InformCampaignWhereUniqueInput;
};

export type InformCampaignUpsertWithoutInformAutomationCampaignInput = {
  create: InformCampaignUncheckedCreateWithoutInformAutomationCampaignInput;
  update: InformCampaignUncheckedUpdateWithoutInformAutomationCampaignInput;
};

export type InformCampaignUpsertWithoutInformCampaignEnrollmentsInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignEnrollmentsInput;
  update: InformCampaignUncheckedUpdateWithoutInformCampaignEnrollmentsInput;
};

export type InformCampaignUpsertWithoutInformCampaignExecutionsInput = {
  create: InformCampaignUncheckedCreateWithoutInformCampaignExecutionsInput;
  update: InformCampaignUncheckedUpdateWithoutInformCampaignExecutionsInput;
};

export type InformCampaignUpsertWithoutInformScheduledCampaignInput = {
  create: InformCampaignUncheckedCreateWithoutInformScheduledCampaignInput;
  update: InformCampaignUncheckedUpdateWithoutInformScheduledCampaignInput;
};

export type InformCampaignWhereInput = {
  AND?: Maybe<Array<InformCampaignWhereInput>>;
  NOT?: Maybe<Array<InformCampaignWhereInput>>;
  OR?: Maybe<Array<InformCampaignWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinition?: Maybe<InformAudienceDefinitionWhereInput>;
  informAudienceDefinitionId?: Maybe<StringFilter>;
  informAutomationCampaign?: Maybe<InformAutomationCampaignWhereInput>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentListRelationFilter>;
  informCampaignExecutions?: Maybe<InformCampaignExecutionListRelationFilter>;
  informCampaignGroup?: Maybe<InformCampaignGroupWhereInput>;
  informCampaignGroupId?: Maybe<StringNullableFilter>;
  informPartnerProgram?: Maybe<InformPartnerProgramWhereInput>;
  informPartnerProgramId?: Maybe<StringFilter>;
  informScheduledCampaign?: Maybe<InformScheduledCampaignWhereInput>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformCampaignStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumInformCampaignTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum InformControlGroupEntityType {
  InformCampaign = 'InformCampaign',
  InformCampaignGroup = 'InformCampaignGroup',
  InformPartnerProgram = 'InformPartnerProgram'
}

export type InformControlGroupParticipant = {
  __typename?: 'InformControlGroupParticipant';
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType: InformControlGroupEntityType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type InformControlGroupParticipantCountAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  controlGroupEntityId: Scalars['Int'];
  controlGroupEntityType: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformControlGroupParticipantCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantCreateInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInformControlGroupParticipantsInput;
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantCreateManyClinicPetParentInput = {
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope = {
  data: Array<InformControlGroupParticipantCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformControlGroupParticipantCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
};

export type InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput = {
  create: InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};

export type InformControlGroupParticipantCreateWithoutClinicPetParentInput = {
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantListRelationFilter = {
  every?: Maybe<InformControlGroupParticipantWhereInput>;
  none?: Maybe<InformControlGroupParticipantWhereInput>;
  some?: Maybe<InformControlGroupParticipantWhereInput>;
};

export type InformControlGroupParticipantMaxAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantMinAggregateOutputType = {
  __typename?: 'InformControlGroupParticipantMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformControlGroupParticipantOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  ControlGroupEntityId = 'controlGroupEntityId',
  Id = 'id'
}

export type InformControlGroupParticipantOrderByRelevanceInput = {
  fields: Array<InformControlGroupParticipantOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformControlGroupParticipantOrderByWithAggregationInput = {
  _count?: Maybe<InformControlGroupParticipantCountOrderByAggregateInput>;
  _max?: Maybe<InformControlGroupParticipantMaxOrderByAggregateInput>;
  _min?: Maybe<InformControlGroupParticipantMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformControlGroupParticipantOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  controlGroupEntityId?: Maybe<SortOrder>;
  controlGroupEntityType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformControlGroupParticipantScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  ControlGroupEntityId = 'controlGroupEntityId',
  ControlGroupEntityType = 'controlGroupEntityType',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDeleted = 'isDeleted',
  UpdatedAt = 'updatedAt'
}

export type InformControlGroupParticipantScalarWhereInput = {
  AND?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  controlGroupEntityId?: Maybe<StringFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformControlGroupParticipantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  controlGroupEntityId?: Maybe<StringWithAggregatesFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformControlGroupParticipantUncheckedCreateInput = {
  clinicPetParentId: Scalars['String'];
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
};

export type InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput = {
  controlGroupEntityId: Scalars['String'];
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  set?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  update?: Maybe<Array<InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type InformControlGroupParticipantUncheckedUpdateManyWithoutInformControlGroupParticipantsInput = {
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput = {
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInformControlGroupParticipantsNestedInput>;
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUpdateManyMutationInput = {
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: InformControlGroupParticipantUncheckedUpdateManyWithoutInformControlGroupParticipantsInput;
  where: InformControlGroupParticipantScalarWhereInput;
};

export type InformControlGroupParticipantUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformControlGroupParticipantCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InformControlGroupParticipantCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InformControlGroupParticipantCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformControlGroupParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  set?: Maybe<Array<InformControlGroupParticipantWhereUniqueInput>>;
  update?: Maybe<Array<InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InformControlGroupParticipantUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type InformControlGroupParticipantUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};

export type InformControlGroupParticipantUpdateWithoutClinicPetParentInput = {
  controlGroupEntityId?: Maybe<Scalars['String']>;
  controlGroupEntityType?: Maybe<InformControlGroupEntityType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformControlGroupParticipantUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: InformControlGroupParticipantUncheckedCreateWithoutClinicPetParentInput;
  update: InformControlGroupParticipantUncheckedUpdateWithoutClinicPetParentInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};

export type InformControlGroupParticipantWhereInput = {
  AND?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  NOT?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  OR?: Maybe<Array<InformControlGroupParticipantWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  controlGroupEntityId?: Maybe<StringFilter>;
  controlGroupEntityType?: Maybe<EnumInformControlGroupEntityTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformControlGroupParticipantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformEmailTemplate = {
  __typename?: 'InformEmailTemplate';
  _count: InformEmailTemplateCountOutputType;
  createdAt: Scalars['DateTime'];
  history: Array<Scalars['Json']>;
  htmlContent?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informPartner?: Maybe<InformPartner>;
  informPartnerId?: Maybe<Scalars['String']>;
  informScheduledCampaigns: Array<InformScheduledCampaign>;
  name: Scalars['String'];
  status: InformEmailTemplateStatus;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type InformEmailTemplateInformScheduledCampaignsArgs = {
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  distinct?: Maybe<InformScheduledCampaignScalarFieldEnum>;
  orderBy?: Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};

export type InformEmailTemplateCountAggregateOutputType = {
  __typename?: 'InformEmailTemplateCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  history: Scalars['Int'];
  htmlContent: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  unlayerJson: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformEmailTemplateCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformEmailTemplateCountOutputType = {
  __typename?: 'InformEmailTemplateCountOutputType';
  informScheduledCampaigns: Scalars['Int'];
};

export type InformEmailTemplateCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateCreateManyInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateCreateManyInformPartnerInputEnvelope = {
  data: Array<InformEmailTemplateCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformEmailTemplateCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
};

export type InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput = {
  connect?: Maybe<InformEmailTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput>;
  create?: Maybe<InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput>;
};

export type InformEmailTemplateCreateOrConnectWithoutInformPartnerInput = {
  create: InformEmailTemplateUncheckedCreateWithoutInformPartnerInput;
  where: InformEmailTemplateWhereUniqueInput;
};

export type InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput = {
  create: InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput;
  where: InformEmailTemplateWhereUniqueInput;
};

export type InformEmailTemplateCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateCreateWithoutInformScheduledCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateCreatehistoryInput = {
  set: Array<Scalars['Json']>;
};

export type InformEmailTemplateListRelationFilter = {
  every?: Maybe<InformEmailTemplateWhereInput>;
  none?: Maybe<InformEmailTemplateWhereInput>;
  some?: Maybe<InformEmailTemplateWhereInput>;
};

export type InformEmailTemplateMaxAggregateOutputType = {
  __typename?: 'InformEmailTemplateMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformEmailTemplateMinAggregateOutputType = {
  __typename?: 'InformEmailTemplateMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformEmailTemplateNameInformPartnerIdCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
};

export type InformEmailTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformEmailTemplateOrderByRelevanceFieldEnum {
  HtmlContent = 'htmlContent',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name'
}

export type InformEmailTemplateOrderByRelevanceInput = {
  fields: Array<InformEmailTemplateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformEmailTemplateOrderByWithAggregationInput = {
  _count?: Maybe<InformEmailTemplateCountOrderByAggregateInput>;
  _max?: Maybe<InformEmailTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<InformEmailTemplateMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformEmailTemplateOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  history?: Maybe<SortOrder>;
  htmlContent?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformEmailTemplateRelationFilter = {
  is?: Maybe<InformEmailTemplateWhereInput>;
  isNot?: Maybe<InformEmailTemplateWhereInput>;
};

export enum InformEmailTemplateScalarFieldEnum {
  CreatedAt = 'createdAt',
  History = 'history',
  HtmlContent = 'htmlContent',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Status = 'status',
  UnlayerJson = 'unlayerJson',
  UpdatedAt = 'updatedAt'
}

export type InformEmailTemplateScalarWhereInput = {
  AND?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  OR?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  history?: Maybe<JsonNullableListFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformEmailTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformEmailTemplateScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  history?: Maybe<JsonNullableListFilter>;
  htmlContent?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformEmailTemplateStatus {
  Archived = 'Archived',
  Draft = 'Draft',
  Published = 'Published'
}

export type InformEmailTemplateUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
};

export type InformEmailTemplateUncheckedCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedUpdateManyWithoutInformEmailTemplatesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  set?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformEmailTemplateUncheckedUpdateManyWithoutInformEmailTemplatesInput;
  where: InformEmailTemplateScalarWhereInput;
};

export type InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformEmailTemplateCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformEmailTemplateCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformEmailTemplateCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformEmailTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  set?: Maybe<Array<InformEmailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformEmailTemplateUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput = {
  connect?: Maybe<InformEmailTemplateWhereUniqueInput>;
  connectOrCreate?: Maybe<InformEmailTemplateCreateOrConnectWithoutInformScheduledCampaignsInput>;
  create?: Maybe<InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput>;
  upsert?: Maybe<InformEmailTemplateUpsertWithoutInformScheduledCampaignsInput>;
};

export type InformEmailTemplateUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput;
  where: InformEmailTemplateWhereUniqueInput;
};

export type InformEmailTemplateUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUpdateWithoutInformScheduledCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Scalars['Json']>>;
  htmlContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformEmailTemplateStatus>;
  unlayerJson?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformEmailTemplateUpdatehistoryInput = {
  push?: Maybe<Array<Scalars['Json']>>;
  set?: Maybe<Array<Scalars['Json']>>;
};

export type InformEmailTemplateUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformEmailTemplateUncheckedCreateWithoutInformPartnerInput;
  update: InformEmailTemplateUncheckedUpdateWithoutInformPartnerInput;
  where: InformEmailTemplateWhereUniqueInput;
};

export type InformEmailTemplateUpsertWithoutInformScheduledCampaignsInput = {
  create: InformEmailTemplateUncheckedCreateWithoutInformScheduledCampaignsInput;
  update: InformEmailTemplateUncheckedUpdateWithoutInformScheduledCampaignsInput;
};

export type InformEmailTemplateWhereInput = {
  AND?: Maybe<Array<InformEmailTemplateWhereInput>>;
  NOT?: Maybe<Array<InformEmailTemplateWhereInput>>;
  OR?: Maybe<Array<InformEmailTemplateWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  history?: Maybe<JsonNullableListFilter>;
  htmlContent?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringNullableFilter>;
  informScheduledCampaigns?: Maybe<InformScheduledCampaignListRelationFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumInformEmailTemplateStatusFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformEmailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name_informPartnerId?: Maybe<InformEmailTemplateNameInformPartnerIdCompoundUniqueInput>;
};

export enum InformEnrollmentStatus {
  Active = 'Active',
  Archived = 'Archived',
  Canceled = 'Canceled',
  NeedsReview = 'NeedsReview',
  NotEnrolled = 'NotEnrolled',
  NotMigrated = 'NotMigrated',
  Onboarding = 'Onboarding',
  Paused = 'Paused'
}

export type InformEntityHistory = {
  __typename?: 'InformEntityHistory';
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  state: Scalars['Json'];
};

export type InformEntityHistoryCountAggregateOutputType = {
  __typename?: 'InformEntityHistoryCountAggregateOutputType';
  _all: Scalars['Int'];
  authorId: Scalars['Int'];
  authorType: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: Scalars['Int'];
  id: Scalars['Int'];
  state: Scalars['Int'];
};

export type InformEntityHistoryCountOrderByAggregateInput = {
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type InformEntityHistoryCreateInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryCreateManyInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryMaxAggregateOutputType = {
  __typename?: 'InformEntityHistoryMaxAggregateOutputType';
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type InformEntityHistoryMaxOrderByAggregateInput = {
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
};

export type InformEntityHistoryMinAggregateOutputType = {
  __typename?: 'InformEntityHistoryMinAggregateOutputType';
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type InformEntityHistoryMinOrderByAggregateInput = {
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
};

export enum InformEntityHistoryOrderByRelevanceFieldEnum {
  AuthorId = 'authorId',
  AuthorType = 'authorType',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id'
}

export type InformEntityHistoryOrderByRelevanceInput = {
  fields: Array<InformEntityHistoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformEntityHistoryOrderByWithAggregationInput = {
  _count?: Maybe<InformEntityHistoryCountOrderByAggregateInput>;
  _max?: Maybe<InformEntityHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<InformEntityHistoryMinOrderByAggregateInput>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export type InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformEntityHistoryOrderByRelevanceInput>;
  authorId?: Maybe<SortOrder>;
  authorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
};

export enum InformEntityHistoryScalarFieldEnum {
  AuthorId = 'authorId',
  AuthorType = 'authorType',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id',
  State = 'state'
}

export type InformEntityHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformEntityHistoryScalarWhereWithAggregatesInput>>;
  authorId?: Maybe<StringNullableWithAggregatesFilter>;
  authorType?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  entityId?: Maybe<StringWithAggregatesFilter>;
  entityType?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  state?: Maybe<JsonWithAggregatesFilter>;
};

export type InformEntityHistoryUncheckedCreateInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  state: Scalars['Json'];
};

export type InformEntityHistoryUncheckedUpdateInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUncheckedUpdateManyInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUpdateInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryUpdateManyMutationInput = {
  authorId?: Maybe<Scalars['String']>;
  authorType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Json']>;
};

export type InformEntityHistoryWhereInput = {
  AND?: Maybe<Array<InformEntityHistoryWhereInput>>;
  NOT?: Maybe<Array<InformEntityHistoryWhereInput>>;
  OR?: Maybe<Array<InformEntityHistoryWhereInput>>;
  authorId?: Maybe<StringNullableFilter>;
  authorType?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  state?: Maybe<JsonFilter>;
};

export type InformEntityHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformPartner = {
  __typename?: 'InformPartner';
  InformPartnerCustomer: Array<InformPartnerCustomer>;
  _count: InformPartnerCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informAudienceDefinitions: Array<InformAudienceDefinition>;
  informCampaignGroups: Array<InformCampaignGroup>;
  informEmailTemplates: Array<InformEmailTemplate>;
  informPartnerIntegrations: Array<InformPartnerIntegration>;
  informPartnerPrograms: Array<InformPartnerProgram>;
  informPartnerUsers: Array<InformPartnerUser>;
  name: Scalars['String'];
  products: Array<Product>;
  settings?: Maybe<Scalars['Json']>;
  status: InformPartnerStatus;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerInformPartnerCustomerArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type InformPartnerInformAudienceDefinitionsArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<InformAudienceDefinitionScalarFieldEnum>;
  orderBy?: Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type InformPartnerInformCampaignGroupsArgs = {
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  distinct?: Maybe<InformCampaignGroupScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type InformPartnerInformEmailTemplatesArgs = {
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  distinct?: Maybe<InformEmailTemplateScalarFieldEnum>;
  orderBy?: Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type InformPartnerInformPartnerIntegrationsArgs = {
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  distinct?: Maybe<InformPartnerIntegrationScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type InformPartnerInformPartnerProgramsArgs = {
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  distinct?: Maybe<InformPartnerProgramScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type InformPartnerInformPartnerUsersArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type InformPartnerProductsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<ProductScalarFieldEnum>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};

export type InformPartnerCountAggregateOutputType = {
  __typename?: 'InformPartnerCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCountOutputType = {
  __typename?: 'InformPartnerCountOutputType';
  InformPartnerCustomer: Scalars['Int'];
  informAudienceDefinitions: Scalars['Int'];
  informCampaignGroups: Scalars['Int'];
  informEmailTemplates: Scalars['Int'];
  informPartnerIntegrations: Scalars['Int'];
  informPartnerPrograms: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  products: Scalars['Int'];
};

export type InformPartnerCreateInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateNestedOneWithoutInformAudienceDefinitionsInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput>;
};

export type InformPartnerCreateNestedOneWithoutInformCampaignGroupsInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput>;
};

export type InformPartnerCreateNestedOneWithoutInformEmailTemplatesInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput>;
};

export type InformPartnerCreateNestedOneWithoutInformPartnerUsersInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerUsersInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerUsersInput>;
};

export type InformPartnerCreateNestedOneWithoutProductsInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutProductsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutProductsInput>;
};

export type InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput = {
  create: InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput = {
  create: InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput = {
  create: InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutInformPartnerUsersInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerUsersInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateOrConnectWithoutProductsInput = {
  create: InformPartnerUncheckedCreateWithoutProductsInput;
  where: InformPartnerWhereUniqueInput;
};

export type InformPartnerCreateWithoutInformAudienceDefinitionsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformCampaignGroupsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformEmailTemplatesInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformPartnerIntegrationsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformPartnerProgramsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutInformPartnerUsersInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCreateWithoutProductsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomer = {
  __typename?: 'InformPartnerCustomer';
  _count: InformPartnerCustomerCountOutputType;
  boehringerIngelheimCustomerAlignments: Array<BoehringerIngelheimCustomerAlignment>;
  checksum: Scalars['String'];
  childInformPartnerCustomers: Array<InformPartnerCustomer>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  informPartner: InformPartner;
  informPartnerId: Scalars['String'];
  informPartnerIntegration: InformPartnerIntegration;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers: Array<InformPartnerUser>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders: Array<InformPartnerCustomerPharmacyProvider>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles: Array<InformPartnerUserRole>;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerCustomerBoehringerIngelheimCustomerAlignmentsArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  distinct?: Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>;
  orderBy?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type InformPartnerCustomerChildInformPartnerCustomersArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type InformPartnerCustomerInformPartnerUsersArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type InformPartnerCustomerPharmacyProvidersArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type InformPartnerCustomerRolesArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserRoleScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};

export type InformPartnerCustomerCountAggregateOutputType = {
  __typename?: 'InformPartnerCustomerCountAggregateOutputType';
  _all: Scalars['Int'];
  checksum: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  name: Scalars['Int'];
  parentExternalId: Scalars['Int'];
  parentInformPartnerCustomerId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  postalCode: Scalars['Int'];
  rawData: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerCustomerCountOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerCountOutputType = {
  __typename?: 'InformPartnerCustomerCountOutputType';
  boehringerIngelheimCustomerAlignments: Scalars['Int'];
  childInformPartnerCustomers: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  pharmacyProviders: Scalars['Int'];
  roles: Scalars['Int'];
};

export type InformPartnerCustomerCreateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateManyInformPartnerInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateManyInformPartnerIntegrationInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateManyInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateManyParentInformPartnerCustomerInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerCustomerCreateManyParentInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
};

export type InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerCustomerCreateNestedOneWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput>;
};

export type InformPartnerCustomerCreateNestedOneWithoutRolesInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutRolesInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutRolesInput>;
};

export type InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutClinicInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutClinicInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateOrConnectWithoutRolesInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutRolesInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutChildInformPartnerCustomersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutClinicInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutPharmacyProvidersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerCreateWithoutRolesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerCustomerInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutChildInformPartnerCustomersInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerInformPartnerIdExternalIdCompoundUniqueInput = {
  externalId: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformPartnerCustomerListRelationFilter = {
  every?: Maybe<InformPartnerCustomerWhereInput>;
  none?: Maybe<InformPartnerCustomerWhereInput>;
  some?: Maybe<InformPartnerCustomerWhereInput>;
};

export type InformPartnerCustomerMaxAggregateOutputType = {
  __typename?: 'InformPartnerCustomerMaxAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerMaxOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerMinAggregateOutputType = {
  __typename?: 'InformPartnerCustomerMinAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerMinOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerOrderByRelevanceFieldEnum {
  Checksum = 'checksum',
  ClinicId = 'clinicId',
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  Name = 'name',
  ParentExternalId = 'parentExternalId',
  ParentInformPartnerCustomerId = 'parentInformPartnerCustomerId',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode'
}

export type InformPartnerCustomerOrderByRelevanceInput = {
  fields: Array<InformPartnerCustomerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerCustomerOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerCustomerCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerCustomerMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerCustomerMinOrderByAggregateInput>;
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerCustomerOrderByRelevanceInput>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentOrderByRelationAggregateInput>;
  checksum?: Maybe<SortOrder>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  parentExternalId?: Maybe<SortOrder>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  parentInformPartnerCustomerId?: Maybe<SortOrder>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  roles?: Maybe<InformPartnerUserRoleOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProvider = {
  __typename?: 'InformPartnerCustomerPharmacyProvider';
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  informPartnerCustomer?: Maybe<InformPartnerCustomer>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegration: InformPartnerIntegration;
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status: InformPartnerCustomerPharmacyProviderStatus;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt: Scalars['DateTime'];
};

export type InformPartnerCustomerPharmacyProviderCountAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  name: Scalars['Int'];
  onlinePharmacyUrl: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  postalCode: Scalars['Int'];
  rawData: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerCustomerPharmacyProviderCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProviderCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput;
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerCustomerPharmacyProviderCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput = {
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput = {
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput;
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerCreateNestedOneWithoutPharmacyProvidersInput>;
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderExternalIdNameCompoundUniqueInput = {
  externalId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
};

export type InformPartnerCustomerPharmacyProviderListRelationFilter = {
  every?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  none?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
  some?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};

export type InformPartnerCustomerPharmacyProviderMaxAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProviderMinAggregateOutputType = {
  __typename?: 'InformPartnerCustomerPharmacyProviderMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerPharmacyProviderName {
  Vetsource = 'Vetsource'
}

export type InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerPharmacyProviderOrderByRelevanceFieldEnum {
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode'
}

export type InformPartnerCustomerPharmacyProviderOrderByRelevanceInput = {
  fields: Array<InformPartnerCustomerPharmacyProviderOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerCustomerPharmacyProviderOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerCustomerPharmacyProviderCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerCustomerPharmacyProviderMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerCustomerPharmacyProviderMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  onlinePharmacyUrl?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerCustomerPharmacyProviderScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  Name = 'name',
  OnlinePharmacyUrl = 'onlinePharmacyUrl',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode',
  RawData = 'rawData',
  Settings = 'settings',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerCustomerPharmacyProviderScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringNullableFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerCustomerPharmacyProviderStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum InformPartnerCustomerPharmacyProviderType {
  InStore = 'InStore',
  Online = 'Online',
  OnlineAndInStore = 'OnlineAndInStore'
}

export type InformPartnerCustomerPharmacyProviderUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name: InformPartnerCustomerPharmacyProviderName;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type: InformPartnerCustomerPharmacyProviderType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutPharmacyProvidersInput;
  where: InformPartnerCustomerPharmacyProviderScalarWhereInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerPharmacyProvidersInput;
  where: InformPartnerCustomerPharmacyProviderScalarWhereInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerPharmacyProviderCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerPharmacyProviderCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  data: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUpdateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput>;
  name?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  onlinePharmacyUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  type?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerCustomerInput;
  update: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  create: InformPartnerCustomerPharmacyProviderUncheckedCreateWithoutInformPartnerIntegrationInput;
  update: InformPartnerCustomerPharmacyProviderUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};

export type InformPartnerCustomerPharmacyProviderWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerPharmacyProviderWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerCustomerId?: Maybe<StringNullableFilter>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  name?: Maybe<EnumInformPartnerCustomerPharmacyProviderNameFilter>;
  onlinePharmacyUrl?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  type?: Maybe<EnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerCustomerPharmacyProviderWhereUniqueInput = {
  externalId_name?: Maybe<InformPartnerCustomerPharmacyProviderExternalIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type InformPartnerCustomerRelationFilter = {
  is?: Maybe<InformPartnerCustomerWhereInput>;
  isNot?: Maybe<InformPartnerCustomerWhereInput>;
};

export enum InformPartnerCustomerScalarFieldEnum {
  Checksum = 'checksum',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  Name = 'name',
  ParentExternalId = 'parentExternalId',
  ParentInformPartnerCustomerId = 'parentInformPartnerCustomerId',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode',
  RawData = 'rawData',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerCustomerScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  checksum?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  parentExternalId?: Maybe<StringNullableFilter>;
  parentInformPartnerCustomerId?: Maybe<StringNullableFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerCustomerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerCustomerScalarWhereWithAggregatesInput>>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  parentExternalId?: Maybe<StringNullableWithAggregatesFilter>;
  parentInformPartnerCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  phoneNumber?: Maybe<StringNullableWithAggregatesFilter>;
  postalCode?: Maybe<StringNullableWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformPartnerCustomerUncheckedCreateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
};

export type InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerCustomerUncheckedCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
};

export type InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutClinicInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedCreateWithoutRolesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  checksum: Scalars['String'];
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutParentInformPartnerCustomerInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput>;
  name: Scalars['String'];
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerCustomerInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateManyInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutChildInformPartnerCustomersInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomerInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
};

export type InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
};

export type InformPartnerCustomerUncheckedUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutClinicInput>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutClinicInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUncheckedUpdateWithoutRolesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomerId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateManyMutationInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomerInput;
  where: InformPartnerCustomerScalarWhereInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput;
  where: InformPartnerCustomerScalarWhereInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput = {
  data: InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerCustomersInput;
  where: InformPartnerCustomerScalarWhereInput;
};

export type InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput = {
  data: InformPartnerCustomerUncheckedUpdateManyWithoutChildInformPartnerCustomersInput;
  where: InformPartnerCustomerScalarWhereInput;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutInformPartnerUsersInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutInformPartnerUsersInput>>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutInformPartnerUsersInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput>>;
};

export type InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerCustomerCreateOrConnectWithoutParentInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerCustomerCreateWithoutParentInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerCustomerCreateManyParentInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerCustomerScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerCustomerWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerCustomerUpdateManyWithWhereWithoutParentInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput>>;
};

export type InformPartnerCustomerUpdateOneRequiredWithoutBoehringerIngelheimCustomerAlignmentsNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput>;
};

export type InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutRolesInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutRolesInput>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutRolesInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutRolesInput>;
};

export type InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutChildInformPartnerCustomersInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutChildInformPartnerCustomersInput>;
};

export type InformPartnerCustomerUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutClinicInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutClinicInput>;
};

export type InformPartnerCustomerUpdateOneWithoutPharmacyProvidersNestedInput = {
  connect?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCustomerCreateOrConnectWithoutPharmacyProvidersInput>;
  create?: Maybe<InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput>;
  upsert?: Maybe<InformPartnerCustomerUpsertWithoutPharmacyProvidersInput>;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutInformPartnerUsersInput = {
  data: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpdateWithWhereUniqueWithoutParentInformPartnerCustomerInput = {
  data: InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutChildInformPartnerCustomersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutClinicInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerIntegrationInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutInformPartnerUsersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutParentInformPartnerCustomerInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutPharmacyProvidersInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpdateWithoutRolesInput = {
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentUpdateManyWithoutInformPartnerCustomerNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutParentInformPartnerCustomerNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutInformPartnerCustomerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput>;
  name?: Maybe<Scalars['String']>;
  parentExternalId?: Maybe<Scalars['String']>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneWithoutChildInformPartnerCustomersNestedInput>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerCustomerNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerIntegrationInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutInformPartnerUsersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutInformPartnerUsersInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutInformPartnerUsersInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpsertWithWhereUniqueWithoutParentInformPartnerCustomerInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutParentInformPartnerCustomerInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutParentInformPartnerCustomerInput;
  where: InformPartnerCustomerWhereUniqueInput;
};

export type InformPartnerCustomerUpsertWithoutBoehringerIngelheimCustomerAlignmentsInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutBoehringerIngelheimCustomerAlignmentsInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutBoehringerIngelheimCustomerAlignmentsInput;
};

export type InformPartnerCustomerUpsertWithoutChildInformPartnerCustomersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutChildInformPartnerCustomersInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutChildInformPartnerCustomersInput;
};

export type InformPartnerCustomerUpsertWithoutClinicInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutClinicInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutClinicInput;
};

export type InformPartnerCustomerUpsertWithoutPharmacyProvidersInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutPharmacyProvidersInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutPharmacyProvidersInput;
};

export type InformPartnerCustomerUpsertWithoutRolesInput = {
  create: InformPartnerCustomerUncheckedCreateWithoutRolesInput;
  update: InformPartnerCustomerUncheckedUpdateWithoutRolesInput;
};

export type InformPartnerCustomerWhereInput = {
  AND?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  NOT?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  OR?: Maybe<Array<InformPartnerCustomerWhereInput>>;
  boehringerIngelheimCustomerAlignments?: Maybe<BoehringerIngelheimCustomerAlignmentListRelationFilter>;
  checksum?: Maybe<StringFilter>;
  childInformPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  name?: Maybe<StringFilter>;
  parentExternalId?: Maybe<StringNullableFilter>;
  parentInformPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  parentInformPartnerCustomerId?: Maybe<StringNullableFilter>;
  pharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderListRelationFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  roles?: Maybe<InformPartnerUserRoleListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerCustomerWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId_externalId?: Maybe<InformPartnerCustomerInformPartnerIdExternalIdCompoundUniqueInput>;
};

export type InformPartnerIntegration = {
  __typename?: 'InformPartnerIntegration';
  _count: InformPartnerIntegrationCountOutputType;
  createdAt: Scalars['DateTime'];
  credentials?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  informPartner: InformPartner;
  informPartnerCustomerPharmacyProviders: Array<InformPartnerCustomerPharmacyProvider>;
  informPartnerCustomers: Array<InformPartnerCustomer>;
  informPartnerId: Scalars['String'];
  informPartnerUsers: Array<InformPartnerUser>;
  models: Array<InformPartnerIntegrationModel>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerIntegrationInformPartnerCustomerPharmacyProvidersArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type InformPartnerIntegrationInformPartnerCustomersArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type InformPartnerIntegrationInformPartnerUsersArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type InformPartnerIntegrationModelsArgs = {
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  distinct?: Maybe<InformPartnerIntegrationModelScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};

export type InformPartnerIntegrationCountAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  credentials: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationCountOutputType = {
  __typename?: 'InformPartnerIntegrationCountOutputType';
  informPartnerCustomerPharmacyProviders: Scalars['Int'];
  informPartnerCustomers: Scalars['Int'];
  informPartnerUsers: Scalars['Int'];
  models: Scalars['Int'];
};

export type InformPartnerIntegrationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateManyInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerIntegrationCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomerPharmacyProvidersInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerCustomersInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput>;
};

export type InformPartnerIntegrationCreateNestedOneWithoutModelsInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutModelsInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutModelsInput>;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationCreateOrConnectWithoutModelsInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutModelsInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateWithoutInformPartnerUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationCreateWithoutModelsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerIntegrationsInput;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationListRelationFilter = {
  every?: Maybe<InformPartnerIntegrationWhereInput>;
  none?: Maybe<InformPartnerIntegrationWhereInput>;
  some?: Maybe<InformPartnerIntegrationWhereInput>;
};

export type InformPartnerIntegrationMaxAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationMinAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModel = {
  __typename?: 'InformPartnerIntegrationModel';
  createdAt: Scalars['DateTime'];
  executeAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  informPartnerIntegration: InformPartnerIntegration;
  informPartnerIntegrationId: Scalars['String'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type InformPartnerIntegrationModelAvgAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelAvgAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Float']>;
};

export type InformPartnerIntegrationModelAvgOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelCountAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  executeAt: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  lastSyncedAt: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  syncFrequencyInMinutes: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerIntegrationModelCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutModelsInput;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerIntegrationModelCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput = {
  create: InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};

export type InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelInformPartnerIntegrationIdNameCompoundUniqueInput = {
  informPartnerIntegrationId: Scalars['String'];
  name: InformPartnerIntegrationModelName;
};

export type InformPartnerIntegrationModelListRelationFilter = {
  every?: Maybe<InformPartnerIntegrationModelWhereInput>;
  none?: Maybe<InformPartnerIntegrationModelWhereInput>;
  some?: Maybe<InformPartnerIntegrationModelWhereInput>;
};

export type InformPartnerIntegrationModelMaxAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelMinAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationModelName {
  BoehringerIngelheimCustomerAlignment = 'BoehringerIngelheimCustomerAlignment',
  BoehringerIngelheimTerritory = 'BoehringerIngelheimTerritory',
  InformPartnerCustomer = 'InformPartnerCustomer',
  InformPartnerCustomerPharmacyProvider = 'InformPartnerCustomerPharmacyProvider',
  InformPartnerUser = 'InformPartnerUser'
}

export type InformPartnerIntegrationModelOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationModelOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerIntegrationId = 'informPartnerIntegrationId'
}

export type InformPartnerIntegrationModelOrderByRelevanceInput = {
  fields: Array<InformPartnerIntegrationModelOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerIntegrationModelOrderByWithAggregationInput = {
  _avg?: Maybe<InformPartnerIntegrationModelAvgOrderByAggregateInput>;
  _count?: Maybe<InformPartnerIntegrationModelCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerIntegrationModelMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerIntegrationModelMinOrderByAggregateInput>;
  _sum?: Maybe<InformPartnerIntegrationModelSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerIntegrationModelOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  executeAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastSyncedAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  syncFrequencyInMinutes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationModelScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExecuteAt = 'executeAt',
  Id = 'id',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  LastSyncedAt = 'lastSyncedAt',
  Name = 'name',
  Status = 'status',
  StatusMessage = 'statusMessage',
  SyncFrequencyInMinutes = 'syncFrequencyInMinutes',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerIntegrationModelScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  executeAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  syncFrequencyInMinutes?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerIntegrationModelScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  executeAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  syncFrequencyInMinutes?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerIntegrationModelStatus {
  Error = 'Error',
  New = 'New',
  Paused = 'Paused',
  Synced = 'Synced',
  Syncing = 'Syncing'
}

export type InformPartnerIntegrationModelSumAggregateOutputType = {
  __typename?: 'InformPartnerIntegrationModelSumAggregateOutputType';
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
};

export type InformPartnerIntegrationModelSumOrderByAggregateInput = {
  syncFrequencyInMinutes?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationModelUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name: InformPartnerIntegrationModelName;
  status: InformPartnerIntegrationModelStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerIntegrationModelUncheckedUpdateManyWithoutModelsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutModelsNestedInput>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  data: InformPartnerIntegrationModelUncheckedUpdateManyWithoutModelsInput;
  where: InformPartnerIntegrationModelScalarWhereInput;
};

export type InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationModelCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerIntegrationModelCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerIntegrationModelCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationModelScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerIntegrationModelWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationModelUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerIntegrationModelUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  data: InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};

export type InformPartnerIntegrationModelUpdateWithoutInformPartnerIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  executeAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<InformPartnerIntegrationModelName>;
  status?: Maybe<InformPartnerIntegrationModelStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  syncFrequencyInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationModelUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  create: InformPartnerIntegrationModelUncheckedCreateWithoutInformPartnerIntegrationInput;
  update: InformPartnerIntegrationModelUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};

export type InformPartnerIntegrationModelWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationModelWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  executeAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  lastSyncedAt?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<EnumInformPartnerIntegrationModelNameFilter>;
  status?: Maybe<EnumInformPartnerIntegrationModelStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  syncFrequencyInMinutes?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerIntegrationModelWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId_name?: Maybe<InformPartnerIntegrationModelInformPartnerIntegrationIdNameCompoundUniqueInput>;
};

export type InformPartnerIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerId = 'informPartnerId'
}

export type InformPartnerIntegrationOrderByRelevanceInput = {
  fields: Array<InformPartnerIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerIntegrationOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  credentials?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderOrderByRelationAggregateInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  models?: Maybe<InformPartnerIntegrationModelOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerIntegrationRelationFilter = {
  is?: Maybe<InformPartnerIntegrationWhereInput>;
  isNot?: Maybe<InformPartnerIntegrationWhereInput>;
};

export enum InformPartnerIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Credentials = 'credentials',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Status = 'status',
  StatusMessage = 'statusMessage',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerIntegrationScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  credentials?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  credentials?: Maybe<JsonNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerIntegrationStatus {
  Active = 'Active',
  Archived = 'Archived',
  Paused = 'Paused'
}

export type InformPartnerIntegrationUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerId: Scalars['String'];
  models?: Maybe<InformPartnerIntegrationModelUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedCreateWithoutModelsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  informPartnerId: Scalars['String'];
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput>;
  status: InformPartnerIntegrationStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerIntegrationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  models?: Maybe<InformPartnerIntegrationModelUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUncheckedUpdateWithoutModelsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerIntegrationsInput;
  where: InformPartnerIntegrationScalarWhereInput;
};

export type InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerIntegrationCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerIntegrationCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerIntegrationUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomerPharmacyProvidersNestedInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomerPharmacyProvidersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerCustomerPharmacyProvidersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerCustomersNestedInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerCustomersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerCustomersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutInformPartnerUsersInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutInformPartnerUsersInput>;
};

export type InformPartnerIntegrationUpdateOneRequiredWithoutModelsNestedInput = {
  connect?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerIntegrationCreateOrConnectWithoutModelsInput>;
  create?: Maybe<InformPartnerIntegrationUncheckedCreateWithoutModelsInput>;
  update?: Maybe<InformPartnerIntegrationUncheckedUpdateWithoutModelsInput>;
  upsert?: Maybe<InformPartnerIntegrationUpsertWithoutModelsInput>;
};

export type InformPartnerIntegrationUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerCustomerPharmacyProvidersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerCustomersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateWithoutInformPartnerUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  models?: Maybe<InformPartnerIntegrationModelUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpdateWithoutModelsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  credentials?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput>;
  status?: Maybe<InformPartnerIntegrationStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerIntegrationUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerCustomerPharmacyProvidersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomerPharmacyProvidersInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomerPharmacyProvidersInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerCustomersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerCustomersInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerCustomersInput;
};

export type InformPartnerIntegrationUpsertWithoutInformPartnerUsersInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutInformPartnerUsersInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutInformPartnerUsersInput;
};

export type InformPartnerIntegrationUpsertWithoutModelsInput = {
  create: InformPartnerIntegrationUncheckedCreateWithoutModelsInput;
  update: InformPartnerIntegrationUncheckedUpdateWithoutModelsInput;
};

export type InformPartnerIntegrationWhereInput = {
  AND?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  NOT?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  OR?: Maybe<Array<InformPartnerIntegrationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  credentials?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerCustomerPharmacyProviders?: Maybe<InformPartnerCustomerPharmacyProviderListRelationFilter>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  models?: Maybe<InformPartnerIntegrationModelListRelationFilter>;
  status?: Maybe<EnumInformPartnerIntegrationStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InformPartnerMaxAggregateOutputType = {
  __typename?: 'InformPartnerMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerMinAggregateOutputType = {
  __typename?: 'InformPartnerMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name'
}

export type InformPartnerOrderByRelevanceInput = {
  fields: Array<InformPartnerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerOrderByWithRelationAndSearchRelevanceInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionOrderByRelationAggregateInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupOrderByRelationAggregateInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateOrderByRelationAggregateInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationOrderByRelationAggregateInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramOrderByRelationAggregateInput>;
  informPartnerUsers?: Maybe<InformPartnerUserOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgram = {
  __typename?: 'InformPartnerProgram';
  _count: InformPartnerProgramCountOutputType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaigns: Array<InformCampaign>;
  informPartner: InformPartner;
  informPartnerId: Scalars['String'];
  informPartnerProgramEnrollments: Array<InformPartnerProgramEnrollment>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformPartnerProgramStatus;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerProgramInformCampaignsArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<InformCampaignScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};


export type InformPartnerProgramInformPartnerProgramEnrollmentsArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};

export type InformPartnerProgramCountAggregateOutputType = {
  __typename?: 'InformPartnerProgramCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerProgramCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramCountOutputType = {
  __typename?: 'InformPartnerProgramCountOutputType';
  informCampaigns: Scalars['Int'];
  informPartnerProgramEnrollments: Scalars['Int'];
};

export type InformPartnerProgramCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramCreateManyInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerProgramCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerProgramCreateNestedOneWithoutInformCampaignsInput = {
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput>;
};

export type InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput = {
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
};

export type InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput;
  where: InformPartnerProgramWhereUniqueInput;
};

export type InformPartnerProgramCreateOrConnectWithoutInformPartnerInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerInput;
  where: InformPartnerProgramWhereUniqueInput;
};

export type InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
  where: InformPartnerProgramWhereUniqueInput;
};

export type InformPartnerProgramCreateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramCreateWithoutInformPartnerProgramEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerProgramsInput;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollment = {
  __typename?: 'InformPartnerProgramEnrollment';
  _count: InformPartnerProgramEnrollmentCountOutputType;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<User>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaignEnrollments: Array<InformCampaignEnrollment>;
  informPartnerProgram: InformPartnerProgram;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerProgramEnrollmentInformCampaignEnrollmentsArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  distinct?: Maybe<InformCampaignEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};

export type InformPartnerProgramEnrollmentClinicIdInformPartnerProgramIdCompoundUniqueInput = {
  clinicId: Scalars['String'];
  informPartnerProgramId: Scalars['String'];
};

export type InformPartnerProgramEnrollmentCountAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentCountAggregateOutputType';
  _all: Scalars['Int'];
  acceptedTermsAt: Scalars['Int'];
  acceptedTermsByUserId: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerProgramId: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  statusMessage: Scalars['Int'];
  statusUpdatedAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerProgramEnrollmentCountOrderByAggregateInput = {
  acceptedTermsAt?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentCountOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentCountOutputType';
  informCampaignEnrollments: Scalars['Int'];
};

export type InformPartnerProgramEnrollmentCreateInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyClinicInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope = {
  data: Array<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerProgramEnrollmentCreateManyInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
};

export type InformPartnerProgramEnrollmentCreateNestedManyWithoutInformPartnerProgramInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
};

export type InformPartnerProgramEnrollmentCreateNestedOneWithoutInformCampaignEnrollmentsInput = {
  connect?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  create?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateWithoutClinicInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateWithoutInformCampaignEnrollmentsInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgram: InformPartnerProgramCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  clinic: ClinicCreateNestedOneWithoutInformPartnerProgramEnrollmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentListRelationFilter = {
  every?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  none?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  some?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};

export type InformPartnerProgramEnrollmentMaxAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentMaxAggregateOutputType';
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentMaxOrderByAggregateInput = {
  acceptedTermsAt?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentMinAggregateOutputType = {
  __typename?: 'InformPartnerProgramEnrollmentMinAggregateOutputType';
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentMinOrderByAggregateInput = {
  acceptedTermsAt?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerProgramEnrollmentOrderByRelevanceFieldEnum {
  AcceptedTermsByUserId = 'acceptedTermsByUserId',
  ClinicId = 'clinicId',
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerProgramId = 'informPartnerProgramId'
}

export type InformPartnerProgramEnrollmentOrderByRelevanceInput = {
  fields: Array<InformPartnerProgramEnrollmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerProgramEnrollmentOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerProgramEnrollmentCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerProgramEnrollmentMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerProgramEnrollmentMinOrderByAggregateInput>;
  acceptedTermsAt?: Maybe<SortOrder>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerProgramId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerProgramEnrollmentOrderByRelevanceInput>;
  acceptedTermsAt?: Maybe<SortOrder>;
  acceptedTermsByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  acceptedTermsByUserId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentOrderByRelationAggregateInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerProgramId?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  statusMessage?: Maybe<SortOrder>;
  statusUpdatedAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramEnrollmentRelationFilter = {
  is?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
  isNot?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};

export enum InformPartnerProgramEnrollmentScalarFieldEnum {
  AcceptedTermsAt = 'acceptedTermsAt',
  AcceptedTermsByUserId = 'acceptedTermsByUserId',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  Id = 'id',
  InformPartnerProgramId = 'informPartnerProgramId',
  Settings = 'settings',
  Status = 'status',
  StatusMessage = 'statusMessage',
  StatusUpdatedAt = 'statusUpdatedAt',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerProgramEnrollmentScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  acceptedTermsAt?: Maybe<DateTimeNullableFilter>;
  acceptedTermsByUserId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerProgramId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereWithAggregatesInput>>;
  acceptedTermsAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  acceptedTermsByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  externalId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerProgramId?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformEnrollmentStatusWithAggregatesFilter>;
  statusMessage?: Maybe<JsonNullableWithAggregatesFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramEnrollmentInput>;
  settings?: Maybe<Scalars['Json']>;
  status: InformEnrollmentStatus;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUserId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateManyMutationInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
  where: InformPartnerProgramEnrollmentScalarWhereInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput;
  where: InformPartnerProgramEnrollmentScalarWhereInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramEnrollmentsInput;
  where: InformPartnerProgramEnrollmentScalarWhereInput;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutAcceptedTermsByUserInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutAcceptedTermsByUserInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyAcceptedTermsByUserInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutAcceptedTermsByUserInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput>>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutClinicInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformPartnerProgramInput>>;
  create?: Maybe<Array<InformPartnerProgramEnrollmentCreateWithoutInformPartnerProgramInput>>;
  createMany?: Maybe<InformPartnerProgramEnrollmentCreateManyInformPartnerProgramInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramEnrollmentScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramEnrollmentWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramEnrollmentUpdateManyWithWhereWithoutInformPartnerProgramInput>>;
  upsert?: Maybe<Array<InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput>>;
};

export type InformPartnerProgramEnrollmentUpdateOneRequiredWithoutInformCampaignEnrollmentsNestedInput = {
  connect?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramEnrollmentCreateOrConnectWithoutInformCampaignEnrollmentsInput>;
  create?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput>;
  update?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput>;
  upsert?: Maybe<InformPartnerProgramEnrollmentUpsertWithoutInformCampaignEnrollmentsInput>;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutAcceptedTermsByUserInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutClinicInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpdateWithWhereUniqueWithoutInformPartnerProgramInput = {
  data: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpdateWithoutAcceptedTermsByUserInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutClinicInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutInformCampaignEnrollmentsInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgram?: Maybe<InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpdateWithoutInformPartnerProgramInput = {
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsByUser?: Maybe<UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentUpdateManyWithoutInformPartnerProgramEnrollmentNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformEnrollmentStatus>;
  statusMessage?: Maybe<Scalars['Json']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutAcceptedTermsByUserInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutAcceptedTermsByUserInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutAcceptedTermsByUserInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutClinicInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutClinicInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutClinicInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpsertWithWhereUniqueWithoutInformPartnerProgramInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformPartnerProgramInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformPartnerProgramInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};

export type InformPartnerProgramEnrollmentUpsertWithoutInformCampaignEnrollmentsInput = {
  create: InformPartnerProgramEnrollmentUncheckedCreateWithoutInformCampaignEnrollmentsInput;
  update: InformPartnerProgramEnrollmentUncheckedUpdateWithoutInformCampaignEnrollmentsInput;
};

export type InformPartnerProgramEnrollmentWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramEnrollmentWhereInput>>;
  acceptedTermsAt?: Maybe<DateTimeNullableFilter>;
  acceptedTermsByUser?: Maybe<UserWhereInput>;
  acceptedTermsByUserId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaignEnrollments?: Maybe<InformCampaignEnrollmentListRelationFilter>;
  informPartnerProgram?: Maybe<InformPartnerProgramWhereInput>;
  informPartnerProgramId?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformEnrollmentStatusFilter>;
  statusMessage?: Maybe<JsonNullableFilter>;
  statusUpdatedAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerProgramEnrollmentWhereUniqueInput = {
  clinicId_informPartnerProgramId?: Maybe<InformPartnerProgramEnrollmentClinicIdInformPartnerProgramIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type InformPartnerProgramInformPartnerIdNameCompoundUniqueInput = {
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
};

export type InformPartnerProgramListRelationFilter = {
  every?: Maybe<InformPartnerProgramWhereInput>;
  none?: Maybe<InformPartnerProgramWhereInput>;
  some?: Maybe<InformPartnerProgramWhereInput>;
};

export type InformPartnerProgramMaxAggregateOutputType = {
  __typename?: 'InformPartnerProgramMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramMinAggregateOutputType = {
  __typename?: 'InformPartnerProgramMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerProgramOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name'
}

export type InformPartnerProgramOrderByRelevanceInput = {
  fields: Array<InformPartnerProgramOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerProgramOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerProgramCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerProgramMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerProgramMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerProgramOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaigns?: Maybe<InformCampaignOrderByRelationAggregateInput>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerProgramRelationFilter = {
  is?: Maybe<InformPartnerProgramWhereInput>;
  isNot?: Maybe<InformPartnerProgramWhereInput>;
};

export enum InformPartnerProgramScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Name = 'name',
  Settings = 'settings',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerProgramScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerProgramScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerProgramScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerProgramStatus {
  Active = 'Active',
  Archived = 'Archived',
  Paused = 'Paused'
}

export type InformPartnerProgramUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartnerId: Scalars['String'];
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedCreateNestedManyWithoutInformPartnerProgramInput>;
  informPartnerId: Scalars['String'];
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerProgramsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUncheckedUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerProgramsInput;
  where: InformPartnerProgramScalarWhereInput;
};

export type InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerProgramCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerProgramCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerProgramCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerProgramScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerProgramWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerProgramUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerProgramUpdateOneRequiredWithoutInformCampaignsNestedInput = {
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformCampaignsInput>;
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput>;
  update?: Maybe<InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput>;
  upsert?: Maybe<InformPartnerProgramUpsertWithoutInformCampaignsInput>;
};

export type InformPartnerProgramUpdateOneRequiredWithoutInformPartnerProgramEnrollmentsNestedInput = {
  connect?: Maybe<InformPartnerProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerProgramCreateOrConnectWithoutInformPartnerProgramEnrollmentsInput>;
  create?: Maybe<InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput>;
  update?: Maybe<InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput>;
  upsert?: Maybe<InformPartnerProgramUpsertWithoutInformPartnerProgramEnrollmentsInput>;
};

export type InformPartnerProgramUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerProgramWhereUniqueInput;
};

export type InformPartnerProgramUpdateWithoutInformCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpdateWithoutInformPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutInformPartnerProgramNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpdateWithoutInformPartnerProgramEnrollmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaigns?: Maybe<InformCampaignUpdateManyWithoutInformPartnerProgramNestedInput>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerProgramUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerInput;
  update: InformPartnerProgramUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerProgramWhereUniqueInput;
};

export type InformPartnerProgramUpsertWithoutInformCampaignsInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformCampaignsInput;
  update: InformPartnerProgramUncheckedUpdateWithoutInformCampaignsInput;
};

export type InformPartnerProgramUpsertWithoutInformPartnerProgramEnrollmentsInput = {
  create: InformPartnerProgramUncheckedCreateWithoutInformPartnerProgramEnrollmentsInput;
  update: InformPartnerProgramUncheckedUpdateWithoutInformPartnerProgramEnrollmentsInput;
};

export type InformPartnerProgramWhereInput = {
  AND?: Maybe<Array<InformPartnerProgramWhereInput>>;
  NOT?: Maybe<Array<InformPartnerProgramWhereInput>>;
  OR?: Maybe<Array<InformPartnerProgramWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaigns?: Maybe<InformCampaignListRelationFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerProgramEnrollments?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
  name?: Maybe<StringFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerProgramStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerProgramWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerId_name?: Maybe<InformPartnerProgramInformPartnerIdNameCompoundUniqueInput>;
};

export type InformPartnerRelationFilter = {
  is?: Maybe<InformPartnerWhereInput>;
  isNot?: Maybe<InformPartnerWhereInput>;
};

export enum InformPartnerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Settings = 'settings',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerStatus {
  Active = 'Active',
  Archived = 'Archived',
  Paused = 'Paused'
}

export type InformPartnerUncheckedCreateInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutInformPartnerUsersInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutInformPartnerInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedCreateWithoutProductsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedCreateNestedManyWithoutInformPartnerInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUncheckedUpdateWithoutProductsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateOneRequiredWithoutInformCampaignGroupsNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformCampaignGroupsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformCampaignGroupsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerCustomerInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerCustomerInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerIntegrationsNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerIntegrationsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerIntegrationsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerProgramsNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerProgramsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerProgramsInput>;
};

export type InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformPartnerUsersInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformPartnerUsersInput>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformPartnerUsersInput>;
};

export type InformPartnerUpdateOneWithoutInformAudienceDefinitionsNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformAudienceDefinitionsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformAudienceDefinitionsInput>;
};

export type InformPartnerUpdateOneWithoutInformEmailTemplatesNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutInformEmailTemplatesInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutInformEmailTemplatesInput>;
};

export type InformPartnerUpdateOneWithoutProductsNestedInput = {
  connect?: Maybe<InformPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerCreateOrConnectWithoutProductsInput>;
  create?: Maybe<InformPartnerUncheckedCreateWithoutProductsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerUncheckedUpdateWithoutProductsInput>;
  upsert?: Maybe<InformPartnerUpsertWithoutProductsInput>;
};

export type InformPartnerUpdateWithoutInformAudienceDefinitionsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformCampaignGroupsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformEmailTemplatesInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformPartnerIntegrationsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformPartnerProgramsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutInformPartnerUsersInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutInformPartnerNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpdateWithoutProductsInput = {
  InformPartnerCustomer?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionUpdateManyWithoutInformPartnerNestedInput>;
  informCampaignGroups?: Maybe<InformCampaignGroupUpdateManyWithoutInformPartnerNestedInput>;
  informEmailTemplates?: Maybe<InformEmailTemplateUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerPrograms?: Maybe<InformPartnerProgramUpdateManyWithoutInformPartnerNestedInput>;
  informPartnerUsers?: Maybe<InformPartnerUserUpdateManyWithoutInformPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUpsertWithoutInformAudienceDefinitionsInput = {
  create: InformPartnerUncheckedCreateWithoutInformAudienceDefinitionsInput;
  update: InformPartnerUncheckedUpdateWithoutInformAudienceDefinitionsInput;
};

export type InformPartnerUpsertWithoutInformCampaignGroupsInput = {
  create: InformPartnerUncheckedCreateWithoutInformCampaignGroupsInput;
  update: InformPartnerUncheckedUpdateWithoutInformCampaignGroupsInput;
};

export type InformPartnerUpsertWithoutInformEmailTemplatesInput = {
  create: InformPartnerUncheckedCreateWithoutInformEmailTemplatesInput;
  update: InformPartnerUncheckedUpdateWithoutInformEmailTemplatesInput;
};

export type InformPartnerUpsertWithoutInformPartnerCustomerInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerCustomerInput;
  update: InformPartnerUncheckedUpdateWithoutInformPartnerCustomerInput;
};

export type InformPartnerUpsertWithoutInformPartnerIntegrationsInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerIntegrationsInput;
  update: InformPartnerUncheckedUpdateWithoutInformPartnerIntegrationsInput;
};

export type InformPartnerUpsertWithoutInformPartnerProgramsInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerProgramsInput;
  update: InformPartnerUncheckedUpdateWithoutInformPartnerProgramsInput;
};

export type InformPartnerUpsertWithoutInformPartnerUsersInput = {
  create: InformPartnerUncheckedCreateWithoutInformPartnerUsersInput;
  update: InformPartnerUncheckedUpdateWithoutInformPartnerUsersInput;
};

export type InformPartnerUpsertWithoutProductsInput = {
  create: InformPartnerUncheckedCreateWithoutProductsInput;
  update: InformPartnerUncheckedUpdateWithoutProductsInput;
};

export type InformPartnerUser = {
  __typename?: 'InformPartnerUser';
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id: Array<UserImpersonation>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id: Array<UserImpersonation>;
  _count: InformPartnerUserCountOutputType;
  boehringerIngelheimTerritories: Array<BoehringerIngelheimTerritory>;
  checksum: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informPartner: InformPartner;
  informPartnerCustomers: Array<InformPartnerCustomer>;
  informPartnerId: Scalars['String'];
  informPartnerIntegration: InformPartnerIntegration;
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles: Array<InformPartnerUserRole>;
  settings?: Maybe<Scalars['Json']>;
  status: InformPartnerUserStatus;
  updatedAt: Scalars['DateTime'];
};


export type InformPartnerUserUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdArgs = {
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserImpersonationWhereInput>;
};


export type InformPartnerUserUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdArgs = {
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserImpersonationWhereInput>;
};


export type InformPartnerUserBoehringerIngelheimTerritoriesArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  distinct?: Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>;
  orderBy?: Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type InformPartnerUserInformPartnerCustomersArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<InformPartnerCustomerScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type InformPartnerUserRolesArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  distinct?: Maybe<InformPartnerUserRoleScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};

export type InformPartnerUserCountAggregateOutputType = {
  __typename?: 'InformPartnerUserCountAggregateOutputType';
  _all: Scalars['Int'];
  checksum: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  externalId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  informPartnerIntegrationId: Scalars['Int'];
  lastName: Scalars['Int'];
  phone: Scalars['Int'];
  rawData: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerUserCountOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserCountOutputType = {
  __typename?: 'InformPartnerUserCountOutputType';
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id: Scalars['Int'];
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id: Scalars['Int'];
  boehringerIngelheimTerritories: Scalars['Int'];
  informPartnerCustomers: Scalars['Int'];
  roles: Scalars['Int'];
};

export type InformPartnerUserCreateInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateManyInformPartnerInput = {
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateManyInformPartnerInputEnvelope = {
  data: Array<InformPartnerUserCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserCreateManyInformPartnerIntegrationInput = {
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope = {
  data: Array<InformPartnerUserCreateManyInformPartnerIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserCreateManyInput = {
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerCustomersInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerUserCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerUserCreateNestedOneWithoutRolesInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutRolesInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutRolesInput>;
};

export type InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput = {
  create: InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutRolesInput = {
  create: InformPartnerUserUncheckedCreateWithoutRolesInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutInformPartnerCustomersInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutInformPartnerInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutInformPartnerIntegrationInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutRolesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner: InformPartnerCreateNestedOneWithoutInformPartnerUsersInput;
  informPartnerCustomers?: Maybe<InformPartnerCustomerCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegration: InformPartnerIntegrationCreateNestedOneWithoutInformPartnerUsersInput;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserInformPartnerIdEmailCompoundUniqueInput = {
  email: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformPartnerUserInformPartnerIdExternalIdCompoundUniqueInput = {
  externalId: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformPartnerUserInformPartnerIdFirebaseIdCompoundUniqueInput = {
  firebaseId: Scalars['String'];
  informPartnerId: Scalars['String'];
};

export type InformPartnerUserListRelationFilter = {
  every?: Maybe<InformPartnerUserWhereInput>;
  none?: Maybe<InformPartnerUserWhereInput>;
  some?: Maybe<InformPartnerUserWhereInput>;
};

export type InformPartnerUserMaxAggregateOutputType = {
  __typename?: 'InformPartnerUserMaxAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserMaxOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserMinAggregateOutputType = {
  __typename?: 'InformPartnerUserMinAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserMinOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerUserOrderByRelevanceFieldEnum {
  Checksum = 'checksum',
  Email = 'email',
  ExternalId = 'externalId',
  FirebaseId = 'firebaseId',
  FirstName = 'firstName',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  LastName = 'lastName',
  Phone = 'phone'
}

export type InformPartnerUserOrderByRelevanceInput = {
  fields: Array<InformPartnerUserOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerUserOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerUserCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerUserMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerUserMinOrderByAggregateInput>;
  checksum?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserOrderByWithRelationAndSearchRelevanceInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  _relevance?: Maybe<InformPartnerUserOrderByRelevanceInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryOrderByRelationAggregateInput>;
  checksum?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerOrderByRelationAggregateInput>;
  informPartnerId?: Maybe<SortOrder>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerIntegrationId?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  rawData?: Maybe<SortOrder>;
  roles?: Maybe<InformPartnerUserRoleOrderByRelationAggregateInput>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserRelationFilter = {
  is?: Maybe<InformPartnerUserWhereInput>;
  isNot?: Maybe<InformPartnerUserWhereInput>;
};

export type InformPartnerUserRole = {
  __typename?: 'InformPartnerUserRole';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  informPartnerCustomer: InformPartnerCustomer;
  informPartnerCustomerId: Scalars['String'];
  informPartnerUser: InformPartnerUser;
  informPartnerUserId: Scalars['String'];
  name: InformPartnerUserRoleName;
  settings?: Maybe<Scalars['Json']>;
  status: InformPartnerUserRoleStatus;
  updatedAt: Scalars['DateTime'];
};

export type InformPartnerUserRoleCountAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  informPartnerCustomerId: Scalars['Int'];
  informPartnerUserId: Scalars['Int'];
  name: Scalars['Int'];
  settings: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InformPartnerUserRoleCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserRoleCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutRolesInput;
  informPartnerUser: InformPartnerUserCreateNestedOneWithoutRolesInput;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUserId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope = {
  data: Array<InformPartnerUserRoleCreateManyInformPartnerCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope = {
  data: Array<InformPartnerUserRoleCreateManyInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformPartnerUserRoleCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  informPartnerUserId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerUserRoleCreateNestedManyWithoutInformPartnerUserInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
};

export type InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput = {
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput = {
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUser: InformPartnerUserCreateNestedOneWithoutRolesInput;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleCreateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer: InformPartnerCustomerCreateNestedOneWithoutRolesInput;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleInformPartnerUserIdInformPartnerCustomerIdNameCompoundUniqueInput = {
  informPartnerCustomerId: Scalars['String'];
  informPartnerUserId: Scalars['String'];
  name: InformPartnerUserRoleName;
};

export type InformPartnerUserRoleListRelationFilter = {
  every?: Maybe<InformPartnerUserRoleWhereInput>;
  none?: Maybe<InformPartnerUserRoleWhereInput>;
  some?: Maybe<InformPartnerUserRoleWhereInput>;
};

export type InformPartnerUserRoleMaxAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserRoleMinAggregateOutputType = {
  __typename?: 'InformPartnerUserRoleMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerUserRoleName {
  Default = 'Default'
}

export type InformPartnerUserRoleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformPartnerUserRoleOrderByRelevanceFieldEnum {
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  InformPartnerUserId = 'informPartnerUserId'
}

export type InformPartnerUserRoleOrderByRelevanceInput = {
  fields: Array<InformPartnerUserRoleOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformPartnerUserRoleOrderByWithAggregationInput = {
  _count?: Maybe<InformPartnerUserRoleCountOrderByAggregateInput>;
  _max?: Maybe<InformPartnerUserRoleMaxOrderByAggregateInput>;
  _min?: Maybe<InformPartnerUserRoleMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformPartnerUserRoleOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerCustomerId?: Maybe<SortOrder>;
  informPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerUserId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  settings?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InformPartnerUserRoleScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InformPartnerCustomerId = 'informPartnerCustomerId',
  InformPartnerUserId = 'informPartnerUserId',
  Name = 'name',
  Settings = 'settings',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerUserRoleScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  informPartnerUserId?: Maybe<StringFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerUserRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerCustomerId?: Maybe<StringWithAggregatesFilter>;
  informPartnerUserId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerUserRoleStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type InformPartnerUserRoleUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  informPartnerUserId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerCustomerInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
};

export type InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
};

export type InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUserId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId: Scalars['String'];
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
};

export type InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomerId?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput = {
  data: InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput;
  where: InformPartnerUserRoleScalarWhereInput;
};

export type InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput = {
  data: InformPartnerUserRoleUncheckedUpdateManyWithoutRolesInput;
  where: InformPartnerUserRoleScalarWhereInput;
};

export type InformPartnerUserRoleUpdateManyWithoutInformPartnerCustomerNestedInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerCustomerInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerCustomerInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerCustomerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerCustomerInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput>>;
};

export type InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput = {
  connect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserRoleCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<InformPartnerUserRoleCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<InformPartnerUserRoleCreateManyInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserRoleWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<InformPartnerUserRoleUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
};

export type InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerCustomerInput = {
  data: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleUpdateWithWhereUniqueWithoutInformPartnerUserInput = {
  data: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleUpdateWithoutInformPartnerCustomerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUpdateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerUpdateOneRequiredWithoutRolesNestedInput>;
  name?: Maybe<InformPartnerUserRoleName>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserRoleStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerCustomerInput = {
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerCustomerInput;
  update: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerCustomerInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleUpsertWithWhereUniqueWithoutInformPartnerUserInput = {
  create: InformPartnerUserRoleUncheckedCreateWithoutInformPartnerUserInput;
  update: InformPartnerUserRoleUncheckedUpdateWithoutInformPartnerUserInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};

export type InformPartnerUserRoleWhereInput = {
  AND?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserRoleWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  informPartnerCustomer?: Maybe<InformPartnerCustomerWhereInput>;
  informPartnerCustomerId?: Maybe<StringFilter>;
  informPartnerUser?: Maybe<InformPartnerUserWhereInput>;
  informPartnerUserId?: Maybe<StringFilter>;
  name?: Maybe<EnumInformPartnerUserRoleNameFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerUserRoleStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerUserRoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerUserId_informPartnerCustomerId_name?: Maybe<InformPartnerUserRoleInformPartnerUserIdInformPartnerCustomerIdNameCompoundUniqueInput>;
};

export enum InformPartnerUserScalarFieldEnum {
  Checksum = 'checksum',
  CreatedAt = 'createdAt',
  Email = 'email',
  ExternalId = 'externalId',
  FirebaseId = 'firebaseId',
  FirstName = 'firstName',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  InformPartnerIntegrationId = 'informPartnerIntegrationId',
  LastName = 'lastName',
  Phone = 'phone',
  RawData = 'rawData',
  Settings = 'settings',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type InformPartnerUserScalarWhereInput = {
  AND?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  checksum?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  externalId?: Maybe<StringFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerUserStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerUserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformPartnerUserScalarWhereWithAggregatesInput>>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  externalId?: Maybe<StringWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informPartnerId?: Maybe<StringWithAggregatesFilter>;
  informPartnerIntegrationId?: Maybe<StringWithAggregatesFilter>;
  lastName?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringWithAggregatesFilter>;
  rawData?: Maybe<JsonNullableWithAggregatesFilter>;
  settings?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<EnumInformPartnerUserStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InformPartnerUserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type InformPartnerUserUncheckedCreateInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutBoehringerIngelheimTerritoriesInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerCustomersInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
};

export type InformPartnerUserUncheckedCreateNestedManyWithoutInformPartnerIntegrationInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
};

export type InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutRolesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalId: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedCreateNestedManyWithoutInformPartnerUsersInput>;
  informPartnerId: Scalars['String'];
  informPartnerIntegrationId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedCreateNestedManyWithoutInformPartnerUserInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateManyInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerCustomersNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutRolesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUncheckedUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerId?: Maybe<Scalars['String']>;
  informPartnerIntegrationId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUncheckedUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateManyMutationInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput = {
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
  where: InformPartnerUserScalarWhereInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput = {
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
  where: InformPartnerUserScalarWhereInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput = {
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
  where: InformPartnerUserScalarWhereInput;
};

export type InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput = {
  data: InformPartnerUserUncheckedUpdateManyWithoutInformPartnerUsersInput;
  where: InformPartnerUserScalarWhereInput;
};

export type InformPartnerUserUpdateManyWithoutBoehringerIngelheimTerritoriesNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutBoehringerIngelheimTerritoriesInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutBoehringerIngelheimTerritoriesInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutBoehringerIngelheimTerritoriesInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerCustomersNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerCustomersInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerCustomersInput>>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerCustomersInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerIntegrationNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerIntegrationInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerIntegrationInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerIntegrationInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerIntegrationInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput>>;
};

export type InformPartnerUserUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformPartnerUserCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<InformPartnerUserCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<InformPartnerUserCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformPartnerUserScalarWhereInput>>;
  disconnect?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  set?: Maybe<Array<InformPartnerUserWhereUniqueInput>>;
  update?: Maybe<Array<InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<InformPartnerUserUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type InformPartnerUserUpdateOneRequiredWithoutRolesNestedInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutRolesInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutRolesInput>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutRolesInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutRolesInput>;
};

export type InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput = {
  connect?: Maybe<InformPartnerUserWhereUniqueInput>;
  connectOrCreate?: Maybe<InformPartnerUserCreateOrConnectWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  create?: Maybe<InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  upsert?: Maybe<InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput = {
  data: InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerCustomersInput = {
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpdateWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  data: InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpdateWithoutBoehringerIngelheimTerritoriesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutInformPartnerCustomersInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutInformPartnerInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutInformPartnerIntegrationInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutRolesInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryUpdateManyWithoutInformPartnerUsersNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartner?: Maybe<InformPartnerUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerUpdateManyWithoutInformPartnerUsersNestedInput>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationUpdateOneRequiredWithoutInformPartnerUsersNestedInput>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rawData?: Maybe<Scalars['Json']>;
  roles?: Maybe<InformPartnerUserRoleUpdateManyWithoutInformPartnerUserNestedInput>;
  settings?: Maybe<Scalars['Json']>;
  status?: Maybe<InformPartnerUserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutBoehringerIngelheimTerritoriesInput = {
  create: InformPartnerUserUncheckedCreateWithoutBoehringerIngelheimTerritoriesInput;
  update: InformPartnerUserUncheckedUpdateWithoutBoehringerIngelheimTerritoriesInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerCustomersInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerCustomersInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerCustomersInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpsertWithWhereUniqueWithoutInformPartnerIntegrationInput = {
  create: InformPartnerUserUncheckedCreateWithoutInformPartnerIntegrationInput;
  update: InformPartnerUserUncheckedUpdateWithoutInformPartnerIntegrationInput;
  where: InformPartnerUserWhereUniqueInput;
};

export type InformPartnerUserUpsertWithoutRolesInput = {
  create: InformPartnerUserUncheckedCreateWithoutRolesInput;
  update: InformPartnerUserUncheckedUpdateWithoutRolesInput;
};

export type InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
  update: InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
};

export type InformPartnerUserUpsertWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  create: InformPartnerUserUncheckedCreateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
  update: InformPartnerUserUncheckedUpdateWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
};

export type InformPartnerUserWhereInput = {
  AND?: Maybe<Array<InformPartnerUserWhereInput>>;
  NOT?: Maybe<Array<InformPartnerUserWhereInput>>;
  OR?: Maybe<Array<InformPartnerUserWhereInput>>;
  UserImpersonation_InformPartnerUserToUserImpersonation_impersonated_inform_partner_user_id?: Maybe<UserImpersonationListRelationFilter>;
  UserImpersonation_InformPartnerUserToUserImpersonation_inform_partner_user_id?: Maybe<UserImpersonationListRelationFilter>;
  boehringerIngelheimTerritories?: Maybe<BoehringerIngelheimTerritoryListRelationFilter>;
  checksum?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  externalId?: Maybe<StringFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerCustomers?: Maybe<InformPartnerCustomerListRelationFilter>;
  informPartnerId?: Maybe<StringFilter>;
  informPartnerIntegration?: Maybe<InformPartnerIntegrationWhereInput>;
  informPartnerIntegrationId?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringFilter>;
  rawData?: Maybe<JsonNullableFilter>;
  roles?: Maybe<InformPartnerUserRoleListRelationFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerUserStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerUserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  informPartnerId_email?: Maybe<InformPartnerUserInformPartnerIdEmailCompoundUniqueInput>;
  informPartnerId_externalId?: Maybe<InformPartnerUserInformPartnerIdExternalIdCompoundUniqueInput>;
  informPartnerId_firebaseId?: Maybe<InformPartnerUserInformPartnerIdFirebaseIdCompoundUniqueInput>;
};

export type InformPartnerWhereInput = {
  AND?: Maybe<Array<InformPartnerWhereInput>>;
  InformPartnerCustomer?: Maybe<InformPartnerCustomerListRelationFilter>;
  NOT?: Maybe<Array<InformPartnerWhereInput>>;
  OR?: Maybe<Array<InformPartnerWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informAudienceDefinitions?: Maybe<InformAudienceDefinitionListRelationFilter>;
  informCampaignGroups?: Maybe<InformCampaignGroupListRelationFilter>;
  informEmailTemplates?: Maybe<InformEmailTemplateListRelationFilter>;
  informPartnerIntegrations?: Maybe<InformPartnerIntegrationListRelationFilter>;
  informPartnerPrograms?: Maybe<InformPartnerProgramListRelationFilter>;
  informPartnerUsers?: Maybe<InformPartnerUserListRelationFilter>;
  name?: Maybe<StringFilter>;
  products?: Maybe<ProductListRelationFilter>;
  settings?: Maybe<JsonNullableFilter>;
  status?: Maybe<EnumInformPartnerStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InformPartnerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InformScheduledCampaign = {
  __typename?: 'InformScheduledCampaign';
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels: Array<InformScheduledCampaignCommunicationChannel>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informCampaign: InformCampaign;
  informEmailTemplate?: Maybe<InformEmailTemplate>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export enum InformScheduledCampaignCadence {
  Annually = 'Annually',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Seasonally = 'Seasonally',
  Weekly = 'Weekly'
}

export enum InformScheduledCampaignCommunicationChannel {
  Email = 'Email',
  Sms = 'SMS'
}

export type InformScheduledCampaignCountAggregateOutputType = {
  __typename?: 'InformScheduledCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  cadence: Scalars['Int'];
  communicationChannels: Scalars['Int'];
  content: Scalars['Int'];
  id: Scalars['Int'];
  informEmailTemplateId: Scalars['Int'];
  schedule: Scalars['Int'];
  subject: Scalars['Int'];
  unlayerJson: Scalars['Int'];
};

export type InformScheduledCampaignCountOrderByAggregateInput = {
  cadence?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
};

export type InformScheduledCampaignCreateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput;
  informEmailTemplate?: Maybe<InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignCreateManyInformEmailTemplateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope = {
  data: Array<InformScheduledCampaignCreateManyInformEmailTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InformScheduledCampaignCreateManyInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignCreateNestedManyWithoutInformEmailTemplateInput = {
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
};

export type InformScheduledCampaignCreateNestedOneWithoutInformCampaignInput = {
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
};

export type InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput = {
  create: InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput;
  where: InformScheduledCampaignWhereUniqueInput;
};

export type InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput = {
  create: InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};

export type InformScheduledCampaignCreateWithoutInformCampaignInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informEmailTemplate?: Maybe<InformEmailTemplateCreateNestedOneWithoutInformScheduledCampaignsInput>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignCreateWithoutInformEmailTemplateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informCampaign: InformCampaignCreateNestedOneWithoutInformScheduledCampaignInput;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignCreatecommunicationChannelsInput = {
  set: Array<InformScheduledCampaignCommunicationChannel>;
};

export type InformScheduledCampaignListRelationFilter = {
  every?: Maybe<InformScheduledCampaignWhereInput>;
  none?: Maybe<InformScheduledCampaignWhereInput>;
  some?: Maybe<InformScheduledCampaignWhereInput>;
};

export type InformScheduledCampaignMaxAggregateOutputType = {
  __typename?: 'InformScheduledCampaignMaxAggregateOutputType';
  cadence?: Maybe<InformScheduledCampaignCadence>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type InformScheduledCampaignMaxOrderByAggregateInput = {
  cadence?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
};

export type InformScheduledCampaignMinAggregateOutputType = {
  __typename?: 'InformScheduledCampaignMinAggregateOutputType';
  cadence?: Maybe<InformScheduledCampaignCadence>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type InformScheduledCampaignMinOrderByAggregateInput = {
  cadence?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
};

export type InformScheduledCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InformScheduledCampaignOrderByRelevanceFieldEnum {
  Content = 'content',
  Id = 'id',
  InformEmailTemplateId = 'informEmailTemplateId',
  Subject = 'subject'
}

export type InformScheduledCampaignOrderByRelevanceInput = {
  fields: Array<InformScheduledCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InformScheduledCampaignOrderByWithAggregationInput = {
  _count?: Maybe<InformScheduledCampaignCountOrderByAggregateInput>;
  _max?: Maybe<InformScheduledCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<InformScheduledCampaignMinOrderByAggregateInput>;
  cadence?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informEmailTemplateId?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
};

export type InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InformScheduledCampaignOrderByRelevanceInput>;
  cadence?: Maybe<SortOrder>;
  communicationChannels?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informCampaign?: Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  informEmailTemplateId?: Maybe<SortOrder>;
  schedule?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  unlayerJson?: Maybe<SortOrder>;
};

export type InformScheduledCampaignRelationFilter = {
  is?: Maybe<InformScheduledCampaignWhereInput>;
  isNot?: Maybe<InformScheduledCampaignWhereInput>;
};

export enum InformScheduledCampaignScalarFieldEnum {
  Cadence = 'cadence',
  CommunicationChannels = 'communicationChannels',
  Content = 'content',
  Id = 'id',
  InformEmailTemplateId = 'informEmailTemplateId',
  Schedule = 'schedule',
  Subject = 'subject',
  UnlayerJson = 'unlayerJson'
}

export type InformScheduledCampaignScalarWhereInput = {
  AND?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  OR?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
  content?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informEmailTemplateId?: Maybe<StringNullableFilter>;
  schedule?: Maybe<JsonNullableFilter>;
  subject?: Maybe<StringFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
};

export type InformScheduledCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InformScheduledCampaignScalarWhereWithAggregatesInput>>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
  content?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  informEmailTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  schedule?: Maybe<JsonNullableWithAggregatesFilter>;
  subject?: Maybe<StringWithAggregatesFilter>;
  unlayerJson?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type InformScheduledCampaignUncheckedCreateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedCreateNestedManyWithoutInformEmailTemplateInput = {
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
};

export type InformScheduledCampaignUncheckedCreateNestedOneWithoutInformCampaignInput = {
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
};

export type InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  schedule?: Maybe<Scalars['Json']>;
  subject: Scalars['String'];
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedUpdateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedUpdateManyInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedUpdateManyWithoutInformEmailTemplateNestedInput = {
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  delete?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput>>;
  updateMany?: Maybe<Array<InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput>>;
  upsert?: Maybe<Array<InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput>>;
};

export type InformScheduledCampaignUncheckedUpdateManyWithoutInformScheduledCampaignsInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedUpdateOneWithoutInformCampaignNestedInput = {
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput>;
  upsert?: Maybe<InformScheduledCampaignUpsertWithoutInformCampaignInput>;
};

export type InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informEmailTemplateId?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUpdateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUpdateManyMutationInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput = {
  data: InformScheduledCampaignUncheckedUpdateManyWithoutInformScheduledCampaignsInput;
  where: InformScheduledCampaignScalarWhereInput;
};

export type InformScheduledCampaignUpdateManyWithoutInformEmailTemplateNestedInput = {
  connect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InformScheduledCampaignCreateOrConnectWithoutInformEmailTemplateInput>>;
  create?: Maybe<Array<InformScheduledCampaignCreateWithoutInformEmailTemplateInput>>;
  createMany?: Maybe<InformScheduledCampaignCreateManyInformEmailTemplateInputEnvelope>;
  delete?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InformScheduledCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  set?: Maybe<Array<InformScheduledCampaignWhereUniqueInput>>;
  update?: Maybe<Array<InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput>>;
  updateMany?: Maybe<Array<InformScheduledCampaignUpdateManyWithWhereWithoutInformEmailTemplateInput>>;
  upsert?: Maybe<Array<InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput>>;
};

export type InformScheduledCampaignUpdateOneWithoutInformCampaignNestedInput = {
  connect?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<InformScheduledCampaignCreateOrConnectWithoutInformCampaignInput>;
  create?: Maybe<InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput>;
  upsert?: Maybe<InformScheduledCampaignUpsertWithoutInformCampaignInput>;
};

export type InformScheduledCampaignUpdateWithWhereUniqueWithoutInformEmailTemplateInput = {
  data: InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};

export type InformScheduledCampaignUpdateWithoutInformCampaignInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informEmailTemplate?: Maybe<InformEmailTemplateUpdateOneWithoutInformScheduledCampaignsNestedInput>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUpdateWithoutInformEmailTemplateInput = {
  cadence?: Maybe<InformScheduledCampaignCadence>;
  communicationChannels?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  content?: Maybe<Scalars['String']>;
  informCampaign?: Maybe<InformCampaignUpdateOneRequiredWithoutInformScheduledCampaignNestedInput>;
  schedule?: Maybe<Scalars['Json']>;
  subject?: Maybe<Scalars['String']>;
  unlayerJson?: Maybe<Scalars['Json']>;
};

export type InformScheduledCampaignUpdatecommunicationChannelsInput = {
  push?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
  set?: Maybe<Array<InformScheduledCampaignCommunicationChannel>>;
};

export type InformScheduledCampaignUpsertWithWhereUniqueWithoutInformEmailTemplateInput = {
  create: InformScheduledCampaignUncheckedCreateWithoutInformEmailTemplateInput;
  update: InformScheduledCampaignUncheckedUpdateWithoutInformEmailTemplateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};

export type InformScheduledCampaignUpsertWithoutInformCampaignInput = {
  create: InformScheduledCampaignUncheckedCreateWithoutInformCampaignInput;
  update: InformScheduledCampaignUncheckedUpdateWithoutInformCampaignInput;
};

export type InformScheduledCampaignWhereInput = {
  AND?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  NOT?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  OR?: Maybe<Array<InformScheduledCampaignWhereInput>>;
  cadence?: Maybe<EnumInformScheduledCampaignCadenceNullableFilter>;
  communicationChannels?: Maybe<EnumInformScheduledCampaignCommunicationChannelNullableListFilter>;
  content?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  informCampaign?: Maybe<InformCampaignWhereInput>;
  informEmailTemplate?: Maybe<InformEmailTemplateWhereInput>;
  informEmailTemplateId?: Maybe<StringNullableFilter>;
  schedule?: Maybe<JsonNullableFilter>;
  subject?: Maybe<StringFilter>;
  unlayerJson?: Maybe<JsonNullableFilter>;
};

export type InformScheduledCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InstinctIntegration = {
  __typename?: 'InstinctIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** The specific clinic location, if clinic has multiple locations i.e. is part of a Market */
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  /** each "Market" (a clinic or collection of clinics) has a subdomain */
  subdomain: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type InstinctIntegrationCountAggregateOutputType = {
  __typename?: 'InstinctIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultAppointmentTypeId: Scalars['Int'];
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  password: Scalars['Int'];
  subdomain: Scalars['Int'];
  updatedAt: Scalars['Int'];
  username: Scalars['Int'];
};

export type InstinctIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type InstinctIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutInstinctInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  subdomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type InstinctIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  subdomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type InstinctIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: InstinctIntegrationWhereUniqueInput;
};

export type InstinctIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  subdomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type InstinctIntegrationMaxAggregateOutputType = {
  __typename?: 'InstinctIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type InstinctIntegrationMinAggregateOutputType = {
  __typename?: 'InstinctIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export enum InstinctIntegrationOrderByRelevanceFieldEnum {
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Id = 'id',
  LocationId = 'locationId',
  Password = 'password',
  Subdomain = 'subdomain',
  Username = 'username'
}

export type InstinctIntegrationOrderByRelevanceInput = {
  fields: Array<InstinctIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InstinctIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<InstinctIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InstinctIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InstinctIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InstinctIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  defaultAppointmentTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  subdomain?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type InstinctIntegrationRelationFilter = {
  is?: Maybe<InstinctIntegrationWhereInput>;
  isNot?: Maybe<InstinctIntegrationWhereInput>;
};

export enum InstinctIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  DefaultAppointmentTypeId = 'defaultAppointmentTypeId',
  Id = 'id',
  LocationId = 'locationId',
  Password = 'password',
  Subdomain = 'subdomain',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type InstinctIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InstinctIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  locationId?: Maybe<StringNullableWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  subdomain?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  username?: Maybe<StringWithAggregatesFilter>;
};

export type InstinctIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutInstinctInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  subdomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  subdomain: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type InstinctIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutInstinctNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutInstinctNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<InstinctIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InstinctIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type InstinctIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultAppointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type InstinctIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: InstinctIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: InstinctIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type InstinctIntegrationWhereInput = {
  AND?: Maybe<Array<InstinctIntegrationWhereInput>>;
  NOT?: Maybe<Array<InstinctIntegrationWhereInput>>;
  OR?: Maybe<Array<InstinctIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultAppointmentTypeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  locationId?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  subdomain?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
};

export type InstinctIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InsuranceIntegration = {
  __typename?: 'InsuranceIntegration';
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  trupanionIntegration?: Maybe<TrupanionIntegration>;
  type: InsuranceIntegrationType;
  updatedAt: Scalars['DateTime'];
};

export type InsuranceIntegrationCountAggregateOutputType = {
  __typename?: 'InsuranceIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InsuranceIntegrationCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InsuranceIntegrationCreateInput = {
  clinic: ClinicCreateNestedOneWithoutInsuranceIntegrationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationCreateManyClinicInputEnvelope = {
  data: Array<InsuranceIntegrationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InsuranceIntegrationCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
};

export type InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput = {
  connect?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput>;
  create?: Maybe<InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput>;
};

export type InsuranceIntegrationCreateOrConnectWithoutClinicInput = {
  create: InsuranceIntegrationUncheckedCreateWithoutClinicInput;
  where: InsuranceIntegrationWhereUniqueInput;
};

export type InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput = {
  create: InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput;
  where: InsuranceIntegrationWhereUniqueInput;
};

export type InsuranceIntegrationCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationCreateWithoutTrupanionIntegrationInput = {
  clinic: ClinicCreateNestedOneWithoutInsuranceIntegrationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationListRelationFilter = {
  every?: Maybe<InsuranceIntegrationWhereInput>;
  none?: Maybe<InsuranceIntegrationWhereInput>;
  some?: Maybe<InsuranceIntegrationWhereInput>;
};

export type InsuranceIntegrationMaxAggregateOutputType = {
  __typename?: 'InsuranceIntegrationMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InsuranceIntegrationMinAggregateOutputType = {
  __typename?: 'InsuranceIntegrationMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InsuranceIntegrationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InsuranceIntegrationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type InsuranceIntegrationOrderByRelevanceInput = {
  fields: Array<InsuranceIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InsuranceIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<InsuranceIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<InsuranceIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<InsuranceIntegrationMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InsuranceIntegrationOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InsuranceIntegrationRelationFilter = {
  is?: Maybe<InsuranceIntegrationWhereInput>;
  isNot?: Maybe<InsuranceIntegrationWhereInput>;
};

export enum InsuranceIntegrationScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InsuranceIntegrationScalarWhereInput = {
  AND?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  OR?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  type?: Maybe<EnumInsuranceIntegrationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InsuranceIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InsuranceIntegrationScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumInsuranceIntegrationTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InsuranceIntegrationType {
  Trupanion = 'Trupanion'
}

export type InsuranceIntegrationUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
};

export type InsuranceIntegrationUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type: InsuranceIntegrationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type InsuranceIntegrationUncheckedUpdateManyWithoutInsuranceIntegrationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput = {
  data: InsuranceIntegrationUncheckedUpdateManyWithoutInsuranceIntegrationsInput;
  where: InsuranceIntegrationScalarWhereInput;
};

export type InsuranceIntegrationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InsuranceIntegrationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<InsuranceIntegrationCreateWithoutClinicInput>>;
  createMany?: Maybe<InsuranceIntegrationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InsuranceIntegrationScalarWhereInput>>;
  disconnect?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  set?: Maybe<Array<InsuranceIntegrationWhereUniqueInput>>;
  update?: Maybe<Array<InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<InsuranceIntegrationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput = {
  connect?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<InsuranceIntegrationCreateOrConnectWithoutTrupanionIntegrationInput>;
  create?: Maybe<InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput>;
  update?: Maybe<InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput>;
  upsert?: Maybe<InsuranceIntegrationUpsertWithoutTrupanionIntegrationInput>;
};

export type InsuranceIntegrationUpdateWithWhereUniqueWithoutClinicInput = {
  data: InsuranceIntegrationUncheckedUpdateWithoutClinicInput;
  where: InsuranceIntegrationWhereUniqueInput;
};

export type InsuranceIntegrationUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUpdateWithoutTrupanionIntegrationInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutInsuranceIntegrationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  type?: Maybe<InsuranceIntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InsuranceIntegrationUpsertWithWhereUniqueWithoutClinicInput = {
  create: InsuranceIntegrationUncheckedCreateWithoutClinicInput;
  update: InsuranceIntegrationUncheckedUpdateWithoutClinicInput;
  where: InsuranceIntegrationWhereUniqueInput;
};

export type InsuranceIntegrationUpsertWithoutTrupanionIntegrationInput = {
  create: InsuranceIntegrationUncheckedCreateWithoutTrupanionIntegrationInput;
  update: InsuranceIntegrationUncheckedUpdateWithoutTrupanionIntegrationInput;
};

export type InsuranceIntegrationWhereInput = {
  AND?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  NOT?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  OR?: Maybe<Array<InsuranceIntegrationWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  type?: Maybe<EnumInsuranceIntegrationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InsuranceIntegrationWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntegrationOutageHistoryEntry = {
  __typename?: 'IntegrationOutageHistoryEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryCountAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  status: Scalars['Int'];
};

export type IntegrationOutageHistoryEntryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutIntegrationOutageHistoryEntryInput;
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope = {
  data: Array<IntegrationOutageHistoryEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type IntegrationOutageHistoryEntryCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
};

export type IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput = {
  create: IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};

export type IntegrationOutageHistoryEntryCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryListRelationFilter = {
  every?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  none?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
  some?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};

export type IntegrationOutageHistoryEntryMaxAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryMinAggregateOutputType = {
  __typename?: 'IntegrationOutageHistoryEntryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum IntegrationOutageHistoryEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  Status = 'status'
}

export type IntegrationOutageHistoryEntryOrderByRelevanceInput = {
  fields: Array<IntegrationOutageHistoryEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type IntegrationOutageHistoryEntryOrderByWithAggregationInput = {
  _count?: Maybe<IntegrationOutageHistoryEntryCountOrderByAggregateInput>;
  _max?: Maybe<IntegrationOutageHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<IntegrationOutageHistoryEntryMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<IntegrationOutageHistoryEntryOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export enum IntegrationOutageHistoryEntryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  Status = 'status'
}

export type IntegrationOutageHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  status?: Maybe<StringFilter>;
};

export type IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
};

export type IntegrationOutageHistoryEntrySubscription = {
  __typename?: 'IntegrationOutageHistoryEntrySubscription';
  mutation: MutationType;
  node: IntegrationOutageHistoryEntry;
};

export type IntegrationOutageHistoryEntryUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
};

export type IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type IntegrationOutageHistoryEntryUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationOutageHistoryEntryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutIntegrationOutageHistoryEntryNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput = {
  data: IntegrationOutageHistoryEntryUncheckedUpdateManyWithoutIntegrationOutageHistoryEntryInput;
  where: IntegrationOutageHistoryEntryScalarWhereInput;
};

export type IntegrationOutageHistoryEntryUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<IntegrationOutageHistoryEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<IntegrationOutageHistoryEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<IntegrationOutageHistoryEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<IntegrationOutageHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<IntegrationOutageHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<IntegrationOutageHistoryEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type IntegrationOutageHistoryEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};

export type IntegrationOutageHistoryEntryUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type IntegrationOutageHistoryEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: IntegrationOutageHistoryEntryUncheckedCreateWithoutIntegrationInput;
  update: IntegrationOutageHistoryEntryUncheckedUpdateWithoutIntegrationInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};

export type IntegrationOutageHistoryEntryWhereInput = {
  AND?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  OR?: Maybe<Array<IntegrationOutageHistoryEntryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  status?: Maybe<StringFilter>;
};

export type IntegrationOutageHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum IntegrationStatus {
  Abandoned = 'Abandoned',
  Connected = 'Connected',
  Created = 'Created',
  NotIntegrated = 'NotIntegrated',
  NotValid = 'NotValid',
  Valid = 'Valid'
}

export enum IntegrationType {
  ClinicWebApp = 'CLINIC_WEB_APP',
  DocupostWebhook = 'DOCUPOST_WEBHOOK',
  Ezyvet = 'EZYVET',
  Instinct = 'INSTINCT',
  Internal = 'INTERNAL',
  Neo = 'NEO',
  PetWidget = 'PET_WIDGET',
  SalesforceWebhook = 'SALESFORCE_WEBHOOK',
  StripePciReview = 'STRIPE_PCI_REVIEW',
  TerminalApp = 'TERMINAL_APP',
  Vetdata = 'VETDATA',
  Vetter = 'VETTER',
  VideoPluginWebApp = 'VIDEO_PLUGIN_WEB_APP'
}

export type InterchangeRate = {
  __typename?: 'InterchangeRate';
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  clinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  clinicPaymentFeeConfigurationId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  fundingSources: Array<CardFunding>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  paymentMedia: Array<PaymentMedium>;
  startsAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type InterchangeRateAvgAggregateOutputType = {
  __typename?: 'InterchangeRateAvgAggregateOutputType';
  basisPoints?: Maybe<Scalars['Float']>;
  centsPerTransaction?: Maybe<Scalars['Float']>;
};

export type InterchangeRateAvgOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
};

export type InterchangeRateCountAggregateOutputType = {
  __typename?: 'InterchangeRateCountAggregateOutputType';
  _all: Scalars['Int'];
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  clinicPaymentFeeConfigurationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  endsAt: Scalars['Int'];
  fundingSources: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  paymentMedia: Scalars['Int'];
  startsAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InterchangeRateCountOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InterchangeRateCreateInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  clinicPaymentFeeConfiguration: ClinicPaymentFeeConfigurationCreateNestedOneWithoutInterchangeRatesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateCreateManyClinicPaymentFeeConfigurationInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope = {
  data: Array<InterchangeRateCreateManyClinicPaymentFeeConfigurationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InterchangeRateCreateManyInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  clinicPaymentFeeConfigurationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateCreateNestedManyWithoutClinicPaymentFeeConfigurationInput = {
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
};

export type InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput = {
  create: InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput;
  where: InterchangeRateWhereUniqueInput;
};

export type InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateCreatefundingSourcesInput = {
  set: Array<CardFunding>;
};

export type InterchangeRateCreatepaymentMediaInput = {
  set: Array<PaymentMedium>;
};

export type InterchangeRateListRelationFilter = {
  every?: Maybe<InterchangeRateWhereInput>;
  none?: Maybe<InterchangeRateWhereInput>;
  some?: Maybe<InterchangeRateWhereInput>;
};

export type InterchangeRateMaxAggregateOutputType = {
  __typename?: 'InterchangeRateMaxAggregateOutputType';
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateMaxOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InterchangeRateMinAggregateOutputType = {
  __typename?: 'InterchangeRateMinAggregateOutputType';
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateMinOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InterchangeRateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InterchangeRateOrderByRelevanceFieldEnum {
  ClinicPaymentFeeConfigurationId = 'clinicPaymentFeeConfigurationId',
  Id = 'id'
}

export type InterchangeRateOrderByRelevanceInput = {
  fields: Array<InterchangeRateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InterchangeRateOrderByWithAggregationInput = {
  _avg?: Maybe<InterchangeRateAvgOrderByAggregateInput>;
  _count?: Maybe<InterchangeRateCountOrderByAggregateInput>;
  _max?: Maybe<InterchangeRateMaxOrderByAggregateInput>;
  _min?: Maybe<InterchangeRateMinOrderByAggregateInput>;
  _sum?: Maybe<InterchangeRateSumOrderByAggregateInput>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InterchangeRateOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InterchangeRateOrderByRelevanceInput>;
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  clinicPaymentFeeConfigurationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  fundingSources?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  paymentMedia?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InterchangeRateScalarFieldEnum {
  BasisPoints = 'basisPoints',
  CentsPerTransaction = 'centsPerTransaction',
  ClinicPaymentFeeConfigurationId = 'clinicPaymentFeeConfigurationId',
  CreatedAt = 'createdAt',
  EndsAt = 'endsAt',
  FundingSources = 'fundingSources',
  Id = 'id',
  IsActive = 'isActive',
  PaymentMedia = 'paymentMedia',
  StartsAt = 'startsAt',
  UpdatedAt = 'updatedAt'
}

export type InterchangeRateScalarWhereInput = {
  AND?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  NOT?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  OR?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  basisPoints?: Maybe<IntFilter>;
  centsPerTransaction?: Maybe<IntFilter>;
  clinicPaymentFeeConfigurationId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InterchangeRateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InterchangeRateScalarWhereWithAggregatesInput>>;
  basisPoints?: Maybe<IntWithAggregatesFilter>;
  centsPerTransaction?: Maybe<IntWithAggregatesFilter>;
  clinicPaymentFeeConfigurationId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InterchangeRateSumAggregateOutputType = {
  __typename?: 'InterchangeRateSumAggregateOutputType';
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
};

export type InterchangeRateSumOrderByAggregateInput = {
  basisPoints?: Maybe<SortOrder>;
  centsPerTransaction?: Maybe<SortOrder>;
};

export type InterchangeRateUncheckedCreateInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  clinicPaymentFeeConfigurationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedCreateNestedManyWithoutClinicPaymentFeeConfigurationInput = {
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
};

export type InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput = {
  basisPoints: Scalars['Int'];
  centsPerTransaction: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateManyInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  clinicPaymentFeeConfigurationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput = {
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  delete?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  disconnect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  set?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  update?: Maybe<Array<InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  updateMany?: Maybe<Array<InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput>>;
  upsert?: Maybe<Array<InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
};

export type InterchangeRateUncheckedUpdateManyWithoutInterchangeRatesInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdateInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationUpdateOneRequiredWithoutInterchangeRatesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdateManyMutationInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput = {
  data: InterchangeRateUncheckedUpdateManyWithoutInterchangeRatesInput;
  where: InterchangeRateScalarWhereInput;
};

export type InterchangeRateUpdateManyWithoutClinicPaymentFeeConfigurationNestedInput = {
  connect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InterchangeRateCreateOrConnectWithoutClinicPaymentFeeConfigurationInput>>;
  create?: Maybe<Array<InterchangeRateCreateWithoutClinicPaymentFeeConfigurationInput>>;
  createMany?: Maybe<InterchangeRateCreateManyClinicPaymentFeeConfigurationInputEnvelope>;
  delete?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InterchangeRateScalarWhereInput>>;
  disconnect?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  set?: Maybe<Array<InterchangeRateWhereUniqueInput>>;
  update?: Maybe<Array<InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
  updateMany?: Maybe<Array<InterchangeRateUpdateManyWithWhereWithoutClinicPaymentFeeConfigurationInput>>;
  upsert?: Maybe<Array<InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput>>;
};

export type InterchangeRateUpdateWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput = {
  data: InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput;
  where: InterchangeRateWhereUniqueInput;
};

export type InterchangeRateUpdateWithoutClinicPaymentFeeConfigurationInput = {
  basisPoints?: Maybe<Scalars['Int']>;
  centsPerTransaction?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingSources?: Maybe<Array<CardFunding>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paymentMedia?: Maybe<Array<PaymentMedium>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterchangeRateUpdatefundingSourcesInput = {
  push?: Maybe<Array<CardFunding>>;
  set?: Maybe<Array<CardFunding>>;
};

export type InterchangeRateUpdatepaymentMediaInput = {
  push?: Maybe<Array<PaymentMedium>>;
  set?: Maybe<Array<PaymentMedium>>;
};

export type InterchangeRateUpsertWithWhereUniqueWithoutClinicPaymentFeeConfigurationInput = {
  create: InterchangeRateUncheckedCreateWithoutClinicPaymentFeeConfigurationInput;
  update: InterchangeRateUncheckedUpdateWithoutClinicPaymentFeeConfigurationInput;
  where: InterchangeRateWhereUniqueInput;
};

export type InterchangeRateWhereInput = {
  AND?: Maybe<Array<InterchangeRateWhereInput>>;
  NOT?: Maybe<Array<InterchangeRateWhereInput>>;
  OR?: Maybe<Array<InterchangeRateWhereInput>>;
  basisPoints?: Maybe<IntFilter>;
  centsPerTransaction?: Maybe<IntFilter>;
  clinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
  clinicPaymentFeeConfigurationId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  fundingSources?: Maybe<EnumCardFundingNullableListFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  paymentMedia?: Maybe<EnumPaymentMediumNullableListFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InterchangeRateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _count: InvoiceCountOutputType;
  associatedCareBenefitCredits: Array<CareBenefitUsageToInvoiceLineItem>;
  careAccountCreditIntents: Array<CareAccountCreditIntent>;
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** The clinic readable identifier for this invoice, e.g. number */
  identifier?: Maybe<Scalars['String']>;
  lineItems: Array<InvoiceLineItem>;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  outstandingBalance: Scalars['Int'];
  pimsInvoice?: Maybe<PimsInvoice>;
  relatedInvoiceWritebacks: Array<InvoicePimsWritebackLogEntry>;
  sentBy?: Maybe<User>;
  sentByUserId?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  stripePaymentIntents: Array<StripePaymentIntent>;
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type InvoiceAssociatedCareBenefitCreditsArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type InvoiceCareAccountCreditIntentsArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<CareAccountCreditIntentScalarFieldEnum>;
  orderBy?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type InvoiceLineItemsArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type InvoiceLoyaltyPointDeltasArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type InvoiceRelatedInvoiceWritebacksArgs = {
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type InvoiceStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type InvoiceAvgAggregateOutputType = {
  __typename?: 'InvoiceAvgAggregateOutputType';
  outstandingBalance?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type InvoiceAvgOrderByAggregateInput = {
  outstandingBalance?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type InvoiceCountAggregateOutputType = {
  __typename?: 'InvoiceCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  date: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  identifier: Scalars['Int'];
  outstandingBalance: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  status: Scalars['Int'];
  subtotal: Scalars['Int'];
  taxes: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InvoiceCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceCountOutputType = {
  __typename?: 'InvoiceCountOutputType';
  associatedCareBenefitCredits: Scalars['Int'];
  careAccountCreditIntents: Scalars['Int'];
  lineItems: Scalars['Int'];
  loyaltyPointDeltas: Scalars['Int'];
  relatedInvoiceWritebacks: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
};

export type InvoiceCreateInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManyClinicPetParentInputEnvelope = {
  data: Array<InvoiceCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManySentByInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManySentByInputEnvelope = {
  data: Array<InvoiceCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
};

export type InvoiceCreateNestedManyWithoutSentByInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
};

export type InvoiceCreateNestedManyWithoutStripePaymentIntentsInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
};

export type InvoiceCreateNestedOneWithoutAssociatedCareBenefitCreditsInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
};

export type InvoiceCreateNestedOneWithoutCareAccountCreditIntentsInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput>;
};

export type InvoiceCreateNestedOneWithoutLineItemsInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
};

export type InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput>;
};

export type InvoiceCreateNestedOneWithoutPimsInvoiceInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
};

export type InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
};

export type InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput = {
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput = {
  create: InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutClinicPetParentInput = {
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutLineItemsInput = {
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  create: InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutPimsInvoiceInput = {
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput = {
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutSentByInput = {
  create: InvoiceUncheckedCreateWithoutSentByInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateWithoutAssociatedCareBenefitCreditsInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutCareAccountCreditIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutClinicPetParentInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutLineItemsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutLoyaltyPointDeltasInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutPimsInvoiceInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutRelatedInvoiceWritebacksInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutSentByInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutStripePaymentIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentCreateNestedManyWithoutInvoiceInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutInvoicesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentInvoicesInput>;
  status?: Maybe<InvoiceStatus>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  AutomationRun: Array<AutomationRun>;
  _count: InvoiceLineItemCountOutputType;
  amount: Scalars['Int'];
  clinicPets: Array<ClinicPet>;
  connectedCareBenefits: Array<CareBenefitUsageToInvoiceLineItem>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployee>;
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id: Scalars['String'];
  invoice: Invoice;
  invoiceId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isTaxable: Scalars['Boolean'];
  isVoided: Scalars['Boolean'];
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  provider?: Maybe<ClinicEmployee>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  /** The time field is the time the item was added (or service was rendered for PIMS-managed invoices) */
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt: Scalars['DateTime'];
};


export type InvoiceLineItemAutomationRunArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type InvoiceLineItemClinicPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type InvoiceLineItemConnectedCareBenefitsArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};

export type InvoiceLineItemAvgAggregateOutputType = {
  __typename?: 'InvoiceLineItemAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  lineNumber?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type InvoiceLineItemAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountAggregateOutputType = {
  __typename?: 'InvoiceLineItemCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  discount: Scalars['Int'];
  enteredById: Scalars['Int'];
  fee: Scalars['Int'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isHidden: Scalars['Int'];
  isTaxable: Scalars['Int'];
  isVoided: Scalars['Int'];
  lineNumber: Scalars['Int'];
  providerId: Scalars['Int'];
  quantity: Scalars['Int'];
  serviceId: Scalars['Int'];
  time: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type InvoiceLineItemCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceLineItemCountOutputType = {
  __typename?: 'InvoiceLineItemCountOutputType';
  AutomationRun: Scalars['Int'];
  clinicPets: Scalars['Int'];
  connectedCareBenefits: Scalars['Int'];
};

export type InvoiceLineItemCreateInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyEnteredByInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyEnteredByInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyEnteredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyInvoiceInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyInvoiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyProviderInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyProviderInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyProviderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateManyServiceInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateManyServiceInputEnvelope = {
  data: Array<InvoiceLineItemCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceLineItemCreateNestedManyWithoutClinicPetsInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
};

export type InvoiceLineItemCreateNestedManyWithoutEnteredByInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
};

export type InvoiceLineItemCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
};

export type InvoiceLineItemCreateNestedManyWithoutProviderInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
};

export type InvoiceLineItemCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
};

export type InvoiceLineItemCreateNestedOneWithoutAutomationRunInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutAutomationRunInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutAutomationRunInput>;
};

export type InvoiceLineItemCreateNestedOneWithoutConnectedCareBenefitsInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
};

export type InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
};

export type InvoiceLineItemCreateOrConnectWithoutAutomationRunInput = {
  create: InvoiceLineItemUncheckedCreateWithoutAutomationRunInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutClinicPetsInput = {
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput = {
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutEnteredByInput = {
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutInvoiceInput = {
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput = {
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutProviderInput = {
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateOrConnectWithoutServiceInput = {
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemCreateWithoutAutomationRunInput = {
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutClinicPetsInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutConnectedCareBenefitsInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutEnteredByInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutInvoiceInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutPimsInvoiceLineItemInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutProviderInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  quantity: Scalars['Float'];
  service?: Maybe<ServiceCreateNestedOneWithoutInvoiceLineItemsInput>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemCreateWithoutServiceInput = {
  AutomationRun?: Maybe<AutomationRunCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredBy?: Maybe<ClinicEmployeeCreateNestedOneWithoutEnteredByInvoiceLineItemsInput>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutLineItemsInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput>;
  provider?: Maybe<ClinicEmployeeCreateNestedOneWithoutProviderInvoiceLineItemsInput>;
  quantity: Scalars['Float'];
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemListRelationFilter = {
  every?: Maybe<InvoiceLineItemWhereInput>;
  none?: Maybe<InvoiceLineItemWhereInput>;
  some?: Maybe<InvoiceLineItemWhereInput>;
};

export type InvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'InvoiceLineItemMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceLineItemMinAggregateOutputType = {
  __typename?: 'InvoiceLineItemMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceLineItemOrderByRelevanceFieldEnum {
  Description = 'description',
  EnteredById = 'enteredById',
  Id = 'id',
  InvoiceId = 'invoiceId',
  ProviderId = 'providerId',
  ServiceId = 'serviceId'
}

export type InvoiceLineItemOrderByRelevanceInput = {
  fields: Array<InvoiceLineItemOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InvoiceLineItemOrderByWithAggregationInput = {
  _avg?: Maybe<InvoiceLineItemAvgOrderByAggregateInput>;
  _count?: Maybe<InvoiceLineItemCountOrderByAggregateInput>;
  _max?: Maybe<InvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceLineItemMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceLineItemSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  enteredById?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  providerId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  AutomationRun?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  _relevance?: Maybe<InvoiceLineItemOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  enteredBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  enteredById?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  invoiceId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isHidden?: Maybe<SortOrder>;
  isTaxable?: Maybe<SortOrder>;
  isVoided?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  provider?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  providerId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceLineItemRelationFilter = {
  is?: Maybe<InvoiceLineItemWhereInput>;
  isNot?: Maybe<InvoiceLineItemWhereInput>;
};

export enum InvoiceLineItemScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Description = 'description',
  Discount = 'discount',
  EnteredById = 'enteredById',
  Fee = 'fee',
  Id = 'id',
  InvoiceId = 'invoiceId',
  IsDeleted = 'isDeleted',
  IsHidden = 'isHidden',
  IsTaxable = 'isTaxable',
  IsVoided = 'isVoided',
  LineNumber = 'lineNumber',
  ProviderId = 'providerId',
  Quantity = 'quantity',
  ServiceId = 'serviceId',
  Time = 'time',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  discount?: Maybe<IntFilter>;
  enteredById?: Maybe<StringNullableFilter>;
  fee?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  lineNumber?: Maybe<IntFilter>;
  providerId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceLineItemScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  discount?: Maybe<IntWithAggregatesFilter>;
  enteredById?: Maybe<StringNullableWithAggregatesFilter>;
  fee?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isHidden?: Maybe<BoolWithAggregatesFilter>;
  isTaxable?: Maybe<BoolWithAggregatesFilter>;
  isVoided?: Maybe<BoolWithAggregatesFilter>;
  lineNumber?: Maybe<IntWithAggregatesFilter>;
  providerId?: Maybe<StringNullableWithAggregatesFilter>;
  quantity?: Maybe<FloatWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  time?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type InvoiceLineItemSumAggregateOutputType = {
  __typename?: 'InvoiceLineItemSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  lineNumber?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type InvoiceLineItemSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  lineNumber?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
};

export enum InvoiceLineItemType {
  Adjustment = 'adjustment',
  Code = 'code',
  Discount = 'discount',
  Estimate = 'estimate',
  Fee = 'fee',
  Note = 'note',
  Payment = 'payment',
  Return = 'return',
  Tax = 'tax',
  Unknown = 'unknown',
  Writeoff = 'writeoff'
}

export type InvoiceLineItemUncheckedCreateInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutClinicPetsInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutEnteredByInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutProviderInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
};

export type InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
};

export type InvoiceLineItemUncheckedCreateWithoutAutomationRunInput = {
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutClinicPetsInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutEnteredByInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutInvoiceInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutProviderInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  quantity: Scalars['Float'];
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedCreateWithoutServiceInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutInvioceLineItemInput>;
  amount: Scalars['Int'];
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutInvoiceLineItemsInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceLineItemInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  discount: Scalars['Int'];
  enteredById?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  invoiceId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber: Scalars['Int'];
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  time?: Maybe<Scalars['DateTime']>;
  type: InvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutClinicPetsNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutEnteredByNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutProviderNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
};

export type InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput = {
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutEnteredByInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutInvoiceInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutProviderInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUncheckedUpdateWithoutServiceInput = {
  AutomationRun?: Maybe<AutomationRunUncheckedUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredById?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput>;
  providerId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput = {
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
  where: InvoiceLineItemScalarWhereInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput = {
  data: InvoiceLineItemUncheckedUpdateManyWithoutEnteredByInvoiceLineItemsInput;
  where: InvoiceLineItemScalarWhereInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput = {
  data: InvoiceLineItemUncheckedUpdateManyWithoutLineItemsInput;
  where: InvoiceLineItemScalarWhereInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutProviderInput = {
  data: InvoiceLineItemUncheckedUpdateManyWithoutProviderInvoiceLineItemsInput;
  where: InvoiceLineItemScalarWhereInput;
};

export type InvoiceLineItemUpdateManyWithWhereWithoutServiceInput = {
  data: InvoiceLineItemUncheckedUpdateManyWithoutInvoiceLineItemsInput;
  where: InvoiceLineItemScalarWhereInput;
};

export type InvoiceLineItemUpdateManyWithoutClinicPetsNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutClinicPetsInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutClinicPetsInput>>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutClinicPetsInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput>>;
};

export type InvoiceLineItemUpdateManyWithoutEnteredByNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutEnteredByInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutEnteredByInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyEnteredByInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutEnteredByInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput>>;
};

export type InvoiceLineItemUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoiceLineItemUpdateManyWithoutProviderNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutProviderInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutProviderInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyProviderInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutProviderInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput>>;
};

export type InvoiceLineItemUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceLineItemCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<InvoiceLineItemCreateWithoutServiceInput>>;
  createMany?: Maybe<InvoiceLineItemCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<InvoiceLineItemUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutPimsInvoiceLineItemInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput>;
};

export type InvoiceLineItemUpdateOneWithoutAutomationRunNestedInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutAutomationRunInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutAutomationRunInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutAutomationRunInput>;
};

export type InvoiceLineItemUpdateOneWithoutConnectedCareBenefitsNestedInput = {
  connect?: Maybe<InvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceLineItemCreateOrConnectWithoutConnectedCareBenefitsInput>;
  create?: Maybe<InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput>;
  upsert?: Maybe<InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput>;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutClinicPetsInput = {
  data: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutEnteredByInput = {
  data: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutProviderInput = {
  data: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpdateWithWhereUniqueWithoutServiceInput = {
  data: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpdateWithoutAutomationRunInput = {
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutClinicPetsInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutConnectedCareBenefitsInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutEnteredByInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutInvoiceInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutPimsInvoiceLineItemInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutProviderInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  service?: Maybe<ServiceUpdateOneWithoutInvoiceLineItemsNestedInput>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpdateWithoutServiceInput = {
  AutomationRun?: Maybe<AutomationRunUpdateManyWithoutInvioceLineItemNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutInvoiceLineItemsNestedInput>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutInvoiceLineItemNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<ClinicEmployeeUpdateOneWithoutEnteredByInvoiceLineItemsNestedInput>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutLineItemsNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isVoided?: Maybe<Scalars['Boolean']>;
  lineNumber?: Maybe<Scalars['Int']>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput>;
  provider?: Maybe<ClinicEmployeeUpdateOneWithoutProviderInvoiceLineItemsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<InvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutClinicPetsInput = {
  create: InvoiceLineItemUncheckedCreateWithoutClinicPetsInput;
  update: InvoiceLineItemUncheckedUpdateWithoutClinicPetsInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutEnteredByInput = {
  create: InvoiceLineItemUncheckedCreateWithoutEnteredByInput;
  update: InvoiceLineItemUncheckedUpdateWithoutEnteredByInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: InvoiceLineItemUncheckedCreateWithoutInvoiceInput;
  update: InvoiceLineItemUncheckedUpdateWithoutInvoiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutProviderInput = {
  create: InvoiceLineItemUncheckedCreateWithoutProviderInput;
  update: InvoiceLineItemUncheckedUpdateWithoutProviderInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpsertWithWhereUniqueWithoutServiceInput = {
  create: InvoiceLineItemUncheckedCreateWithoutServiceInput;
  update: InvoiceLineItemUncheckedUpdateWithoutServiceInput;
  where: InvoiceLineItemWhereUniqueInput;
};

export type InvoiceLineItemUpsertWithoutAutomationRunInput = {
  create: InvoiceLineItemUncheckedCreateWithoutAutomationRunInput;
  update: InvoiceLineItemUncheckedUpdateWithoutAutomationRunInput;
};

export type InvoiceLineItemUpsertWithoutConnectedCareBenefitsInput = {
  create: InvoiceLineItemUncheckedCreateWithoutConnectedCareBenefitsInput;
  update: InvoiceLineItemUncheckedUpdateWithoutConnectedCareBenefitsInput;
};

export type InvoiceLineItemUpsertWithoutPimsInvoiceLineItemInput = {
  create: InvoiceLineItemUncheckedCreateWithoutPimsInvoiceLineItemInput;
  update: InvoiceLineItemUncheckedUpdateWithoutPimsInvoiceLineItemInput;
};

export type InvoiceLineItemWhereInput = {
  AND?: Maybe<Array<InvoiceLineItemWhereInput>>;
  AutomationRun?: Maybe<AutomationRunListRelationFilter>;
  NOT?: Maybe<Array<InvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<InvoiceLineItemWhereInput>>;
  amount?: Maybe<IntFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  connectedCareBenefits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  discount?: Maybe<IntFilter>;
  enteredBy?: Maybe<ClinicEmployeeWhereInput>;
  enteredById?: Maybe<StringNullableFilter>;
  fee?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoiceId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  isTaxable?: Maybe<BoolFilter>;
  isVoided?: Maybe<BoolFilter>;
  lineNumber?: Maybe<IntFilter>;
  pimsInvoiceLineItem?: Maybe<PimsInvoiceLineItemWhereInput>;
  provider?: Maybe<ClinicEmployeeWhereInput>;
  providerId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringNullableFilter>;
  time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumInvoiceLineItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
};

export type InvoiceMaxAggregateOutputType = {
  __typename?: 'InvoiceMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceMinAggregateOutputType = {
  __typename?: 'InvoiceMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoiceOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Description = 'description',
  Id = 'id',
  Identifier = 'identifier',
  SentByUserId = 'sentByUserId'
}

export type InvoiceOrderByRelevanceInput = {
  fields: Array<InvoiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InvoiceOrderByWithAggregationInput = {
  _avg?: Maybe<InvoiceAvgOrderByAggregateInput>;
  _count?: Maybe<InvoiceCountOrderByAggregateInput>;
  _max?: Maybe<InvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<InvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<InvoiceSumOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  outstandingBalance?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InvoiceOrderByRelevanceInput>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentOrderByRelationAggregateInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  identifier?: Maybe<SortOrder>;
  lineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  outstandingBalance?: Maybe<SortOrder>;
  pimsInvoice?: Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryOrderByRelationAggregateInput>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sentByUserId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InvoicePimsWritebackAction {
  CareDiscountApplied = 'Care_Discount_Applied',
  PaymentCollected = 'Payment_Collected'
}

export type InvoicePimsWritebackLogEntry = {
  __typename?: 'InvoicePimsWritebackLogEntry';
  action: InvoicePimsWritebackAction;
  invoice: Invoice;
  invoiceId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  writebackLogId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  invoiceId: Scalars['Int'];
  writebackLogId: Scalars['Int'];
};

export type InvoicePimsWritebackLogEntryCountOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryCreateInput = {
  action: InvoicePimsWritebackAction;
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
};

export type InvoicePimsWritebackLogEntryCreateManyInput = {
  action: InvoicePimsWritebackAction;
  invoiceId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInput = {
  action: InvoicePimsWritebackAction;
  writebackLogId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope = {
  data: Array<InvoicePimsWritebackLogEntryCreateManyInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoicePimsWritebackLogEntryCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
};

export type InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput = {
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput = {
  action: InvoicePimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput;
};

export type InvoicePimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  action: InvoicePimsWritebackAction;
  invoice: InvoiceCreateNestedOneWithoutRelatedInvoiceWritebacksInput;
};

export type InvoicePimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  none?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  some?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export type InvoicePimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMaxAggregateOutputType';
  action?: Maybe<InvoicePimsWritebackAction>;
  invoiceId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryMaxOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'InvoicePimsWritebackLogEntryMinAggregateOutputType';
  action?: Maybe<InvoicePimsWritebackAction>;
  invoiceId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryMinOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum {
  InvoiceId = 'invoiceId',
  WritebackLogId = 'writebackLogId'
}

export type InvoicePimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<InvoicePimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type InvoicePimsWritebackLogEntryOrderByWithAggregationInput = {
  _count?: Maybe<InvoicePimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<InvoicePimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<InvoicePimsWritebackLogEntryMinOrderByAggregateInput>;
  action?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<InvoicePimsWritebackLogEntryOrderByRelevanceInput>;
  action?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  invoiceId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  writebackLogId?: Maybe<SortOrder>;
};

export type InvoicePimsWritebackLogEntryRelationFilter = {
  is?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};

export enum InvoicePimsWritebackLogEntryScalarFieldEnum {
  Action = 'action',
  InvoiceId = 'invoiceId',
  WritebackLogId = 'writebackLogId'
}

export type InvoicePimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
  invoiceId?: Maybe<StringFilter>;
  writebackLogId?: Maybe<StringFilter>;
};

export type InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  action?: Maybe<EnumInvoicePimsWritebackActionWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateInput = {
  action: InvoicePimsWritebackAction;
  invoiceId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput = {
  action: InvoicePimsWritebackAction;
  writebackLogId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  action: InvoicePimsWritebackAction;
  invoiceId: Scalars['String'];
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoiceId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoiceId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoiceId?: Maybe<Scalars['String']>;
};

export type InvoicePimsWritebackLogEntryUpdateInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput = {
  data: InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutRelatedInvoiceWritebacksInput;
  where: InvoicePimsWritebackLogEntryScalarWhereInput;
};

export type InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput = {
  connect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoicePimsWritebackLogEntryCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoicePimsWritebackLogEntryCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoicePimsWritebackLogEntryCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoicePimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<InvoicePimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoicePimsWritebackLogEntryUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoicePimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type InvoicePimsWritebackLogEntryUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutInvoiceInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  action?: Maybe<InvoicePimsWritebackAction>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput>;
};

export type InvoicePimsWritebackLogEntryUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutInvoiceInput;
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutInvoiceInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};

export type InvoicePimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  create: InvoicePimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  update: InvoicePimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
};

export type InvoicePimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<InvoicePimsWritebackLogEntryWhereInput>>;
  action?: Maybe<EnumInvoicePimsWritebackActionFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoiceId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  writebackLogId?: Maybe<StringFilter>;
};

export type InvoicePimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_invoiceId?: Maybe<InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput>;
};

export type InvoicePimsWritebackLogEntryWritebackLogIdInvoiceIdCompoundUniqueInput = {
  invoiceId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type InvoiceRelationFilter = {
  is?: Maybe<InvoiceWhereInput>;
  isNot?: Maybe<InvoiceWhereInput>;
};

export enum InvoiceScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Date = 'date',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Identifier = 'identifier',
  OutstandingBalance = 'outstandingBalance',
  SentByUserId = 'sentByUserId',
  Status = 'status',
  Subtotal = 'subtotal',
  Taxes = 'taxes',
  Total = 'total',
  UpdatedAt = 'updatedAt'
}

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<InvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  identifier?: Maybe<StringNullableFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  date?: Maybe<DateTimeNullableWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  identifier?: Maybe<StringNullableWithAggregatesFilter>;
  outstandingBalance?: Maybe<IntWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumInvoiceStatusWithAggregatesFilter>;
  subtotal?: Maybe<IntWithAggregatesFilter>;
  taxes?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum InvoiceStatus {
  Closed = 'Closed',
  Open = 'Open'
}

export type InvoiceSumAggregateOutputType = {
  __typename?: 'InvoiceSumAggregateOutputType';
  outstandingBalance?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type InvoiceSumOrderByAggregateInput = {
  outstandingBalance?: Maybe<SortOrder>;
  subtotal?: Maybe<SortOrder>;
  taxes?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type InvoiceUncheckedCreateInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
};

export type InvoiceUncheckedCreateNestedManyWithoutSentByInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
};

export type InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
};

export type InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutClinicPetParentInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutLineItemsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutPimsInvoiceInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutSentByInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedCreateWithoutStripePaymentIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutAssociatedInvoiceInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedCreateNestedManyWithoutInvoiceInput>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutInvoiceInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedManyWithoutInvoiceInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal: Scalars['Int'];
  taxes?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateManyWithoutSentByNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
};

export type InvoiceUncheckedUpdateManyWithoutSentInvoicesInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
};

export type InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutClinicPetParentInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutLineItemsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutPimsInvoiceInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutSentByInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUncheckedUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateManyWithoutInvoiceNestedInput>;
  sentByUserId?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithWhereWithoutSentByInput = {
  data: InvoiceUncheckedUpdateManyWithoutSentInvoicesInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput = {
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<InvoiceCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type InvoiceUpdateManyWithoutSentByNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutSentByInput>>;
  createMany?: Maybe<InvoiceCreateManySentByInputEnvelope>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutSentByInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutSentByInput>>;
};

export type InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutStripePaymentIntentsInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutStripePaymentIntentsInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutStripePaymentIntentsInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput>>;
};

export type InvoiceUpdateOneRequiredWithoutLineItemsNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLineItemsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutLineItemsInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutLineItemsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutLineItemsInput>;
};

export type InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutPimsInvoiceInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutPimsInvoiceInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutPimsInvoiceInput>;
  upsert?: Maybe<InvoiceUpsertWithoutPimsInvoiceInput>;
};

export type InvoiceUpdateOneRequiredWithoutRelatedInvoiceWritebacksNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutRelatedInvoiceWritebacksInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput>;
  upsert?: Maybe<InvoiceUpsertWithoutRelatedInvoiceWritebacksInput>;
};

export type InvoiceUpdateOneWithoutAssociatedCareBenefitCreditsNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAssociatedCareBenefitCreditsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput>;
};

export type InvoiceUpdateOneWithoutCareAccountCreditIntentsNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutCareAccountCreditIntentsInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutCareAccountCreditIntentsInput>;
};

export type InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<InvoiceUpsertWithoutLoyaltyPointDeltasInput>;
};

export type InvoiceUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutSentByInput = {
  data: InvoiceUncheckedUpdateWithoutSentByInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutStripePaymentIntentsInput = {
  data: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithoutAssociatedCareBenefitCreditsInput = {
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutCareAccountCreditIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutClinicPetParentInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutLineItemsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutLoyaltyPointDeltasInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutPimsInvoiceInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutRelatedInvoiceWritebacksInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutSentByInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  status?: Maybe<InvoiceStatus>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutInvoicesNestedInput>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutStripePaymentIntentsInput = {
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutAssociatedInvoiceNestedInput>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentUpdateManyWithoutInvoiceNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutInvoicesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  lineItems?: Maybe<InvoiceLineItemUpdateManyWithoutInvoiceNestedInput>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput>;
  outstandingBalance?: Maybe<Scalars['Int']>;
  pimsInvoice?: Maybe<PimsInvoiceUpdateOneWithoutInvoiceNestedInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryUpdateManyWithoutInvoiceNestedInput>;
  sentBy?: Maybe<UserUpdateOneWithoutSentInvoicesNestedInput>;
  status?: Maybe<InvoiceStatus>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: InvoiceUncheckedCreateWithoutClinicPetParentInput;
  update: InvoiceUncheckedUpdateWithoutClinicPetParentInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutSentByInput = {
  create: InvoiceUncheckedCreateWithoutSentByInput;
  update: InvoiceUncheckedUpdateWithoutSentByInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutStripePaymentIntentsInput = {
  create: InvoiceUncheckedCreateWithoutStripePaymentIntentsInput;
  update: InvoiceUncheckedUpdateWithoutStripePaymentIntentsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithoutAssociatedCareBenefitCreditsInput = {
  create: InvoiceUncheckedCreateWithoutAssociatedCareBenefitCreditsInput;
  update: InvoiceUncheckedUpdateWithoutAssociatedCareBenefitCreditsInput;
};

export type InvoiceUpsertWithoutCareAccountCreditIntentsInput = {
  create: InvoiceUncheckedCreateWithoutCareAccountCreditIntentsInput;
  update: InvoiceUncheckedUpdateWithoutCareAccountCreditIntentsInput;
};

export type InvoiceUpsertWithoutLineItemsInput = {
  create: InvoiceUncheckedCreateWithoutLineItemsInput;
  update: InvoiceUncheckedUpdateWithoutLineItemsInput;
};

export type InvoiceUpsertWithoutLoyaltyPointDeltasInput = {
  create: InvoiceUncheckedCreateWithoutLoyaltyPointDeltasInput;
  update: InvoiceUncheckedUpdateWithoutLoyaltyPointDeltasInput;
};

export type InvoiceUpsertWithoutPimsInvoiceInput = {
  create: InvoiceUncheckedCreateWithoutPimsInvoiceInput;
  update: InvoiceUncheckedUpdateWithoutPimsInvoiceInput;
};

export type InvoiceUpsertWithoutRelatedInvoiceWritebacksInput = {
  create: InvoiceUncheckedCreateWithoutRelatedInvoiceWritebacksInput;
  update: InvoiceUncheckedUpdateWithoutRelatedInvoiceWritebacksInput;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  associatedCareBenefitCredits?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  careAccountCreditIntents?: Maybe<CareAccountCreditIntentListRelationFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  identifier?: Maybe<StringNullableFilter>;
  lineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  outstandingBalance?: Maybe<IntFilter>;
  pimsInvoice?: Maybe<PimsInvoiceWhereInput>;
  relatedInvoiceWritebacks?: Maybe<InvoicePimsWritebackLogEntryListRelationFilter>;
  sentBy?: Maybe<UserWhereInput>;
  sentByUserId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  subtotal?: Maybe<IntFilter>;
  taxes?: Maybe<IntFilter>;
  total?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvokeAiConverseContent = {
  text: Scalars['String'];
};

export type InvokeAiConverseInput = {
  messages: Array<InvokeAiConverseMessage>;
  modelId?: Maybe<Scalars['String']>;
  systemPrompt?: Maybe<Scalars['String']>;
};

export type InvokeAiConverseMessage = {
  content: Array<InvokeAiConverseContent>;
  role: AiConverseRole;
};

export type InvokeAiConverseOutput = {
  __typename?: 'InvokeAiConverseOutput';
  message: Scalars['String'];
  role: AiConverseRole;
};

export type ItemInfo = {
  discountedValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  savings?: Maybe<Scalars['Float']>;
  usedValue: Scalars['Float'];
};

export type JobChain = {
  __typename?: 'JobChain';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type JobChainStatus = {
  __typename?: 'JobChainStatus';
  completedAt?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['Json']>;
  erroredAt?: Maybe<Scalars['DateTime']>;
  jobChainId?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<Scalars['Json']>>>;
  queuedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

export type JobChainStatusInput = {
  jobChainId: Scalars['String'];
};

export type JobChainSubscritptionPayload = {
  __typename?: 'JobChainSubscritptionPayload';
  error?: Maybe<Scalars['Json']>;
  jobChainId?: Maybe<Scalars['String']>;
  payload?: Maybe<Array<Maybe<Scalars['Json']>>>;
  success: Scalars['Boolean'];
};

export type JobChainSubscritptionWhereInput = {
  id: StringFilter;
};


export type JsonFilter = {
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export enum JsonNullValueFilter {
  AnyNull = 'AnyNull',
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export enum JsonNullValueInput {
  JsonNull = 'JsonNull'
}

export type JsonNullableFilter = {
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export type JsonNullableListFilter = {
  equals?: Maybe<Array<Scalars['Json']>>;
  has?: Maybe<Scalars['Json']>;
  hasEvery?: Maybe<Array<Scalars['Json']>>;
  hasSome?: Maybe<Array<Scalars['Json']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedJsonNullableFilter>;
  _min?: Maybe<NestedJsonNullableFilter>;
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export type JsonWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedJsonFilter>;
  _min?: Maybe<NestedJsonFilter>;
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export type LapsedPetParentTriggers = {
  __typename?: 'LapsedPetParentTriggers';
  automation: WorkflowEventSetting;
  automationId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isQueued: Scalars['Boolean'];
  triggerAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LapsedPetParentTriggersAutomationIdClinicPetParentIdCompoundUniqueInput = {
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LapsedPetParentTriggersCountAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersCountAggregateOutputType';
  _all: Scalars['Int'];
  automationId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isQueued: Scalars['Int'];
  triggerAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LapsedPetParentTriggersCountOrderByAggregateInput = {
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersCreateInput = {
  automation: WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersCreateManyAutomationInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersCreateManyAutomationInputEnvelope = {
  data: Array<LapsedPetParentTriggersCreateManyAutomationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyClinicPetParentInput = {
  automationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope = {
  data: Array<LapsedPetParentTriggersCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LapsedPetParentTriggersCreateManyInput = {
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
};

export type LapsedPetParentTriggersCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
};

export type LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput = {
  create: LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput = {
  create: LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersCreateWithoutAutomationInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLapsedPetParentTriggersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersCreateWithoutClinicPetParentInput = {
  automation: WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersListRelationFilter = {
  every?: Maybe<LapsedPetParentTriggersWhereInput>;
  none?: Maybe<LapsedPetParentTriggersWhereInput>;
  some?: Maybe<LapsedPetParentTriggersWhereInput>;
};

export type LapsedPetParentTriggersMaxAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersMaxAggregateOutputType';
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersMaxOrderByAggregateInput = {
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersMinAggregateOutputType = {
  __typename?: 'LapsedPetParentTriggersMinAggregateOutputType';
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersMinOrderByAggregateInput = {
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LapsedPetParentTriggersOrderByRelevanceFieldEnum {
  AutomationId = 'automationId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id'
}

export type LapsedPetParentTriggersOrderByRelevanceInput = {
  fields: Array<LapsedPetParentTriggersOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LapsedPetParentTriggersOrderByWithAggregationInput = {
  _count?: Maybe<LapsedPetParentTriggersCountOrderByAggregateInput>;
  _max?: Maybe<LapsedPetParentTriggersMaxOrderByAggregateInput>;
  _min?: Maybe<LapsedPetParentTriggersMinOrderByAggregateInput>;
  automationId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LapsedPetParentTriggersOrderByRelevanceInput>;
  automation?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  automationId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isQueued?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LapsedPetParentTriggersScalarFieldEnum {
  AutomationId = 'automationId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDeleted = 'isDeleted',
  IsQueued = 'isQueued',
  TriggerAt = 'triggerAt',
  UpdatedAt = 'updatedAt'
}

export type LapsedPetParentTriggersScalarWhereInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  automationId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isQueued?: Maybe<BoolFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LapsedPetParentTriggersScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersScalarWhereWithAggregatesInput>>;
  automationId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isQueued?: Maybe<BoolWithAggregatesFilter>;
  triggerAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LapsedPetParentTriggersUncheckedCreateInput = {
  automationId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
};

export type LapsedPetParentTriggersUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
};

export type LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput = {
  automationId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedUpdateInput = {
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyInput = {
  automationId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput>>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput = {
  automationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput = {
  automationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUpdateInput = {
  automation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput = {
  data: LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput;
  where: LapsedPetParentTriggersScalarWhereInput;
};

export type LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: LapsedPetParentTriggersUncheckedUpdateManyWithoutLapsedPetParentTriggersInput;
  where: LapsedPetParentTriggersScalarWhereInput;
};

export type LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutAutomationInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutAutomationInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyAutomationInputEnvelope>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutAutomationInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput>>;
};

export type LapsedPetParentTriggersUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LapsedPetParentTriggersCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<LapsedPetParentTriggersCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<LapsedPetParentTriggersCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LapsedPetParentTriggersScalarWhereInput>>;
  disconnect?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  set?: Maybe<Array<LapsedPetParentTriggersWhereUniqueInput>>;
  update?: Maybe<Array<LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<LapsedPetParentTriggersUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type LapsedPetParentTriggersUpdateWithWhereUniqueWithoutAutomationInput = {
  data: LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersUpdateWithoutAutomationInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUpdateWithoutClinicPetParentInput = {
  automation?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isQueued?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LapsedPetParentTriggersUpsertWithWhereUniqueWithoutAutomationInput = {
  create: LapsedPetParentTriggersUncheckedCreateWithoutAutomationInput;
  update: LapsedPetParentTriggersUncheckedUpdateWithoutAutomationInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: LapsedPetParentTriggersUncheckedCreateWithoutClinicPetParentInput;
  update: LapsedPetParentTriggersUncheckedUpdateWithoutClinicPetParentInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};

export type LapsedPetParentTriggersWhereInput = {
  AND?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  NOT?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  OR?: Maybe<Array<LapsedPetParentTriggersWhereInput>>;
  automation?: Maybe<WorkflowEventSettingWhereInput>;
  automationId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isQueued?: Maybe<BoolFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LapsedPetParentTriggersWhereUniqueInput = {
  automationId_clinicPetParentId?: Maybe<LapsedPetParentTriggersAutomationIdClinicPetParentIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  _count: LegalEntityCountOutputType;
  clinics: Array<Clinic>;
  createdAt: Scalars['DateTime'];
  ein: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt: Scalars['DateTime'];
};


export type LegalEntityClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};

export type LegalEntityCountAggregateOutputType = {
  __typename?: 'LegalEntityCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  ein: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LegalEntityCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LegalEntityCountOutputType = {
  __typename?: 'LegalEntityCountOutputType';
  clinics: Scalars['Int'];
};

export type LegalEntityCreateInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutLegalEntityInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ein: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityCreateNestedOneWithoutClinicsInput = {
  connect?: Maybe<LegalEntityWhereUniqueInput>;
  connectOrCreate?: Maybe<LegalEntityCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<LegalEntityUncheckedCreateWithoutClinicsInput>;
};

export type LegalEntityCreateOrConnectWithoutClinicsInput = {
  create: LegalEntityUncheckedCreateWithoutClinicsInput;
  where: LegalEntityWhereUniqueInput;
};

export type LegalEntityCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityMaxAggregateOutputType = {
  __typename?: 'LegalEntityMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LegalEntityMinAggregateOutputType = {
  __typename?: 'LegalEntityMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LegalEntityOrderByRelevanceFieldEnum {
  Ein = 'ein',
  Id = 'id',
  Name = 'name'
}

export type LegalEntityOrderByRelevanceInput = {
  fields: Array<LegalEntityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LegalEntityOrderByWithAggregationInput = {
  _count?: Maybe<LegalEntityCountOrderByAggregateInput>;
  _max?: Maybe<LegalEntityMaxOrderByAggregateInput>;
  _min?: Maybe<LegalEntityMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LegalEntityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LegalEntityOrderByRelevanceInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  ein?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LegalEntityRelationFilter = {
  is?: Maybe<LegalEntityWhereInput>;
  isNot?: Maybe<LegalEntityWhereInput>;
};

export enum LegalEntityScalarFieldEnum {
  CreatedAt = 'createdAt',
  Ein = 'ein',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type LegalEntityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LegalEntityScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  ein?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumLegalEntityTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum LegalEntityType {
  GovernmentalUnit = 'GOVERNMENTAL_UNIT',
  GovernmentInstrumentality = 'GOVERNMENT_INSTRUMENTALITY',
  IncorporatedNonProfit = 'INCORPORATED_NON_PROFIT',
  MultiMemberLlc = 'MULTI_MEMBER_LLC',
  PrivateCorporation = 'PRIVATE_CORPORATION',
  PrivatePartnership = 'PRIVATE_PARTNERSHIP',
  PublicCorporation = 'PUBLIC_CORPORATION',
  PublicPartnership = 'PUBLIC_PARTNERSHIP',
  SingleMemberLlc = 'SINGLE_MEMBER_LLC',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  TaxExemptGovernmentInstrumentality = 'TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY',
  UnincorporatedAssociation = 'UNINCORPORATED_ASSOCIATION',
  UnincorporatedNonProfit = 'UNINCORPORATED_NON_PROFIT'
}

export type LegalEntityUncheckedCreateInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLegalEntityInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ein: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUncheckedCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: LegalEntityType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUncheckedUpdateInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutLegalEntityNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUncheckedUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUpdateInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutLegalEntityNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUpdateOneWithoutClinicsNestedInput = {
  connect?: Maybe<LegalEntityWhereUniqueInput>;
  connectOrCreate?: Maybe<LegalEntityCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<LegalEntityUncheckedCreateWithoutClinicsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LegalEntityUncheckedUpdateWithoutClinicsInput>;
  upsert?: Maybe<LegalEntityUpsertWithoutClinicsInput>;
};

export type LegalEntityUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ein?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<LegalEntityType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LegalEntityUpsertWithoutClinicsInput = {
  create: LegalEntityUncheckedCreateWithoutClinicsInput;
  update: LegalEntityUncheckedUpdateWithoutClinicsInput;
};

export type LegalEntityWhereInput = {
  AND?: Maybe<Array<LegalEntityWhereInput>>;
  NOT?: Maybe<Array<LegalEntityWhereInput>>;
  OR?: Maybe<Array<LegalEntityWhereInput>>;
  clinics?: Maybe<ClinicListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  ein?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumLegalEntityTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LegalEntityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoginLinkOutput = {
  __typename?: 'LoginLinkOutput';
  url: Scalars['String'];
};

export type LoyaltyAccount = {
  __typename?: 'LoyaltyAccount';
  _count: LoyaltyAccountCountOutputType;
  accountHolders: Array<LoyaltyAccountHolder>;
  createdAt: Scalars['DateTime'];
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  /** leftover balance from running total point systems */
  leftOverSpend: Scalars['Int'];
  mergeSent?: Maybe<LoyaltyAccountMerger>;
  mergesTaken: Array<LoyaltyAccountMerger>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  /** how many points an account has */
  pointBalance: Scalars['Int'];
  pointDeltas: Array<LoyaltyPointDelta>;
  program: LoyaltyProgram;
  programId: Scalars['String'];
  rewardRedemptions: Array<LoyaltyRewardRedemption>;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyAccountAccountHoldersArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  distinct?: Maybe<LoyaltyAccountHolderScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type LoyaltyAccountMergesTakenArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  distinct?: Maybe<LoyaltyAccountMergerScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type LoyaltyAccountPointDeltasArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type LoyaltyAccountRewardRedemptionsArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export type LoyaltyAccountAvgAggregateOutputType = {
  __typename?: 'LoyaltyAccountAvgAggregateOutputType';
  leftOverSpend?: Maybe<Scalars['Float']>;
  pointBalance?: Maybe<Scalars['Float']>;
};

export type LoyaltyAccountAvgOrderByAggregateInput = {
  leftOverSpend?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
};

export type LoyaltyAccountCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deactivationDate: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  leftOverSpend: Scalars['Int'];
  migratedAt: Scalars['Int'];
  pointBalance: Scalars['Int'];
  programId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyAccountCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountCountOutputType = {
  __typename?: 'LoyaltyAccountCountOutputType';
  accountHolders: Scalars['Int'];
  mergesTaken: Scalars['Int'];
  pointDeltas: Scalars['Int'];
  rewardRedemptions: Scalars['Int'];
};

export type LoyaltyAccountCreateInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateManyProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyAccountCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
};

export type LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutMergeSentInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergeSentInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergeSentInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutMergesTakenInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergesTakenInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergesTakenInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutPointDeltasInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutPointDeltasInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutPointDeltasInput>;
};

export type LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput>;
};

export type LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput = {
  create: LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateOrConnectWithoutMergeSentInput = {
  create: LoyaltyAccountUncheckedCreateWithoutMergeSentInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateOrConnectWithoutMergesTakenInput = {
  create: LoyaltyAccountUncheckedCreateWithoutMergesTakenInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateOrConnectWithoutPointDeltasInput = {
  create: LoyaltyAccountUncheckedCreateWithoutPointDeltasInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateOrConnectWithoutProgramInput = {
  create: LoyaltyAccountUncheckedCreateWithoutProgramInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput = {
  create: LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountCreateWithoutAccountHoldersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateWithoutMergeSentInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateWithoutMergesTakenInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateWithoutPointDeltasInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateWithoutProgramInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountCreateWithoutRewardRedemptionsInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutAccountInput>;
  program: LoyaltyProgramCreateNestedOneWithoutAccountsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolder = {
  __typename?: 'LoyaltyAccountHolder';
  _count: LoyaltyAccountHolderCountOutputType;
  account: LoyaltyAccount;
  accountId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  program: LoyaltyProgram;
  programId: Scalars['String'];
  rewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyAccountHolderRewardRedemptionActionsArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export type LoyaltyAccountHolderClinicPetParentIdAccountIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type LoyaltyAccountHolderCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderCountAggregateOutputType';
  _all: Scalars['Int'];
  accountId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  programId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyAccountHolderCountOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderCountOutputType = {
  __typename?: 'LoyaltyAccountHolderCountOutputType';
  rewardRedemptionActions: Scalars['Int'];
};

export type LoyaltyAccountHolderCreateInput = {
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateManyAccountInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyAccountHolderCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountHolderCreateManyInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateManyProgramInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyAccountHolderCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountHolderCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
};

export type LoyaltyAccountHolderCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
};

export type LoyaltyAccountHolderCreateNestedOneWithoutClinicPetParentInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput>;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutAccountInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutAccountInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutProgramInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutProgramInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderCreateWithoutAccountInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateWithoutClinicPetParentInput = {
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateWithoutProgramInput = {
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderCreateWithoutRewardRedemptionActionsInput = {
  account: LoyaltyAccountCreateNestedOneWithoutAccountHoldersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutLoyaltyAccountHolderInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderListRelationFilter = {
  every?: Maybe<LoyaltyAccountHolderWhereInput>;
  none?: Maybe<LoyaltyAccountHolderWhereInput>;
  some?: Maybe<LoyaltyAccountHolderWhereInput>;
};

export type LoyaltyAccountHolderMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderMaxAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderMaxOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountHolderMinAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderMinOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountHolderOrderByRelevanceFieldEnum {
  AccountId = 'accountId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  ProgramId = 'programId'
}

export type LoyaltyAccountHolderOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountHolderOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyAccountHolderOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyAccountHolderCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountHolderMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountHolderMinOrderByAggregateInput>;
  accountId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyAccountHolderOrderByRelevanceInput>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  accountId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  programId?: Maybe<SortOrder>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountHolderRelationFilter = {
  is?: Maybe<LoyaltyAccountHolderWhereInput>;
  isNot?: Maybe<LoyaltyAccountHolderWhereInput>;
};

export enum LoyaltyAccountHolderScalarFieldEnum {
  AccountId = 'accountId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProgramId = 'programId',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyAccountHolderScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  programId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyAccountHolderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderScalarWhereWithAggregatesInput>>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyAccountHolderUncheckedCreateInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
};

export type LoyaltyAccountHolderUncheckedCreateNestedOneWithoutClinicPetParentInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutAccountInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutProgramInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput = {
  accountId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateInput = {
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyInput = {
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountHoldersInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutLoyaltyAccountHoldersInput = {
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyAccountHolderUncheckedUpdateOneWithoutClinicPetParentNestedInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput = {
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput = {
  accountId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput = {
  data: LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountHoldersInput;
  where: LoyaltyAccountHolderScalarWhereInput;
};

export type LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput = {
  data: LoyaltyAccountHolderUncheckedUpdateManyWithoutLoyaltyAccountHoldersInput;
  where: LoyaltyAccountHolderScalarWhereInput;
};

export type LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountHolderCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountHolderCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountHolderCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountHolderScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountHolderWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountHolderUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyAccountHolderUpdateOneWithoutClinicPetParentNestedInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutClinicPetParentInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutClinicPetParentInput>;
};

export type LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput = {
  connect?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountHolderCreateOrConnectWithoutRewardRedemptionActionsInput>;
  create?: Maybe<LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput>;
  upsert?: Maybe<LoyaltyAccountHolderUpsertWithoutRewardRedemptionActionsInput>;
};

export type LoyaltyAccountHolderUpdateWithWhereUniqueWithoutAccountInput = {
  data: LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderUpdateWithWhereUniqueWithoutProgramInput = {
  data: LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderUpdateWithoutAccountInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateWithoutClinicPetParentInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateWithoutProgramInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpdateWithoutRewardRedemptionActionsInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutLoyaltyAccountHolderNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountHolderUpsertWithWhereUniqueWithoutAccountInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutAccountInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutAccountInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderUpsertWithWhereUniqueWithoutProgramInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutProgramInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutProgramInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};

export type LoyaltyAccountHolderUpsertWithoutClinicPetParentInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutClinicPetParentInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutClinicPetParentInput;
};

export type LoyaltyAccountHolderUpsertWithoutRewardRedemptionActionsInput = {
  create: LoyaltyAccountHolderUncheckedCreateWithoutRewardRedemptionActionsInput;
  update: LoyaltyAccountHolderUncheckedUpdateWithoutRewardRedemptionActionsInput;
};

export type LoyaltyAccountHolderWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountHolderWhereInput>>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  accountId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  rewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyAccountHolderWhereUniqueInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicPetParentId_accountId?: Maybe<LoyaltyAccountHolderClinicPetParentIdAccountIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountListRelationFilter = {
  every?: Maybe<LoyaltyAccountWhereInput>;
  none?: Maybe<LoyaltyAccountWhereInput>;
  some?: Maybe<LoyaltyAccountWhereInput>;
};

export type LoyaltyAccountMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountMerger = {
  __typename?: 'LoyaltyAccountMerger';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  initiatedBy: User;
  mergeDataFromAccount: LoyaltyAccount;
  mergeDataFromAccountId: Scalars['String'];
  mergeDataIntoAccount: LoyaltyAccount;
  mergeDataIntoAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status: LoyaltyAccountMergerStatus;
};

export type LoyaltyAccountMergerCountAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerCountAggregateOutputType';
  _all: Scalars['Int'];
  completedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  errorMessage: Scalars['Int'];
  id: Scalars['Int'];
  mergeDataFromAccountId: Scalars['Int'];
  mergeDataIntoAccountId: Scalars['Int'];
  mergerInitiatorId: Scalars['Int'];
  status: Scalars['Int'];
};

export type LoyaltyAccountMergerCountOrderByAggregateInput = {
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerCreateInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateManyInitiatedByInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope = {
  data: Array<LoyaltyAccountMergerCreateManyInitiatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountMergerCreateManyInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateManyMergeDataIntoAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope = {
  data: Array<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
};

export type LoyaltyAccountMergerCreateNestedManyWithoutMergeDataIntoAccountInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
};

export type LoyaltyAccountMergerCreateNestedOneWithoutMergeDataFromAccountInput = {
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerCreateWithoutInitiatedByInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateWithoutMergeDataFromAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataIntoAccount: LoyaltyAccountCreateNestedOneWithoutMergesTakenInput;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy: UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput;
  mergeDataFromAccount: LoyaltyAccountCreateNestedOneWithoutMergeSentInput;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerListRelationFilter = {
  every?: Maybe<LoyaltyAccountMergerWhereInput>;
  none?: Maybe<LoyaltyAccountMergerWhereInput>;
  some?: Maybe<LoyaltyAccountMergerWhereInput>;
};

export type LoyaltyAccountMergerMaxAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerMaxAggregateOutputType';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerMaxOrderByAggregateInput = {
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountMergerMinAggregateOutputType';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerMinOrderByAggregateInput = {
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountMergerOrderByRelevanceFieldEnum {
  ErrorMessage = 'errorMessage',
  Id = 'id',
  MergeDataFromAccountId = 'mergeDataFromAccountId',
  MergeDataIntoAccountId = 'mergeDataIntoAccountId',
  MergerInitiatorId = 'mergerInitiatorId'
}

export type LoyaltyAccountMergerOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountMergerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyAccountMergerOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyAccountMergerCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountMergerMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountMergerMinOrderByAggregateInput>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyAccountMergerOrderByRelevanceInput>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initiatedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  mergeDataFromAccountId?: Maybe<SortOrder>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  mergeDataIntoAccountId?: Maybe<SortOrder>;
  mergerInitiatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyAccountMergerRelationFilter = {
  is?: Maybe<LoyaltyAccountMergerWhereInput>;
  isNot?: Maybe<LoyaltyAccountMergerWhereInput>;
};

export enum LoyaltyAccountMergerScalarFieldEnum {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  ErrorMessage = 'errorMessage',
  Id = 'id',
  MergeDataFromAccountId = 'mergeDataFromAccountId',
  MergeDataIntoAccountId = 'mergeDataIntoAccountId',
  MergerInitiatorId = 'mergerInitiatorId',
  Status = 'status'
}

export type LoyaltyAccountMergerScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  mergeDataFromAccountId?: Maybe<StringFilter>;
  mergeDataIntoAccountId?: Maybe<StringFilter>;
  mergerInitiatorId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusFilter>;
};

export type LoyaltyAccountMergerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerScalarWhereWithAggregatesInput>>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  errorMessage?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  mergeDataFromAccountId?: Maybe<StringWithAggregatesFilter>;
  mergeDataIntoAccountId?: Maybe<StringWithAggregatesFilter>;
  mergerInitiatorId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
};

export enum LoyaltyAccountMergerStatus {
  Completed = 'Completed',
  Errored = 'Errored',
  Started = 'Started'
}

export type LoyaltyAccountMergerUncheckedCreateInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
};

export type LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
};

export type LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput = {
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergeDataIntoAccountId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId: Scalars['String'];
  mergerInitiatorId: Scalars['String'];
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput>>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutIntitiatedLoyaltyAccountMergesInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
};

export type LoyaltyAccountMergerUncheckedUpdateManyWithoutMergesTakenInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput = {
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput>;
  upsert?: Maybe<LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataIntoAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
  mergerInitiatorId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpdateInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpdateManyMutationInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput = {
  data: LoyaltyAccountMergerUncheckedUpdateManyWithoutIntitiatedLoyaltyAccountMergesInput;
  where: LoyaltyAccountMergerScalarWhereInput;
};

export type LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput = {
  data: LoyaltyAccountMergerUncheckedUpdateManyWithoutMergesTakenInput;
  where: LoyaltyAccountMergerScalarWhereInput;
};

export type LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutInitiatedByInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutInitiatedByInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyInitiatedByInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutInitiatedByInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput>>;
};

export type LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataIntoAccountInput>>;
  create?: Maybe<Array<LoyaltyAccountMergerCreateWithoutMergeDataIntoAccountInput>>;
  createMany?: Maybe<LoyaltyAccountMergerCreateManyMergeDataIntoAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountMergerScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountMergerWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountMergerUpdateManyWithWhereWithoutMergeDataIntoAccountInput>>;
  upsert?: Maybe<Array<LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput>>;
};

export type LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput = {
  connect?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountMergerCreateOrConnectWithoutMergeDataFromAccountInput>;
  create?: Maybe<LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput>;
  upsert?: Maybe<LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput>;
};

export type LoyaltyAccountMergerUpdateWithWhereUniqueWithoutInitiatedByInput = {
  data: LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerUpdateWithWhereUniqueWithoutMergeDataIntoAccountInput = {
  data: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerUpdateWithoutInitiatedByInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpdateWithoutMergeDataFromAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpdateWithoutMergeDataIntoAccountInput = {
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiatedBy?: Maybe<UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput>;
  status?: Maybe<LoyaltyAccountMergerStatus>;
};

export type LoyaltyAccountMergerUpsertWithWhereUniqueWithoutInitiatedByInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutInitiatedByInput;
  update: LoyaltyAccountMergerUncheckedUpdateWithoutInitiatedByInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerUpsertWithWhereUniqueWithoutMergeDataIntoAccountInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataIntoAccountInput;
  update: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataIntoAccountInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};

export type LoyaltyAccountMergerUpsertWithoutMergeDataFromAccountInput = {
  create: LoyaltyAccountMergerUncheckedCreateWithoutMergeDataFromAccountInput;
  update: LoyaltyAccountMergerUncheckedUpdateWithoutMergeDataFromAccountInput;
};

export type LoyaltyAccountMergerWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountMergerWhereInput>>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  initiatedBy?: Maybe<UserWhereInput>;
  mergeDataFromAccount?: Maybe<LoyaltyAccountWhereInput>;
  mergeDataFromAccountId?: Maybe<StringFilter>;
  mergeDataIntoAccount?: Maybe<LoyaltyAccountWhereInput>;
  mergeDataIntoAccountId?: Maybe<StringFilter>;
  mergerInitiatorId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyAccountMergerStatusFilter>;
};

export type LoyaltyAccountMergerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  mergeDataFromAccountId?: Maybe<Scalars['String']>;
};

export type LoyaltyAccountMinAggregateOutputType = {
  __typename?: 'LoyaltyAccountMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyAccountOrderByRelevanceFieldEnum {
  Id = 'id',
  ProgramId = 'programId'
}

export type LoyaltyAccountOrderByRelevanceInput = {
  fields: Array<LoyaltyAccountOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyAccountOrderByWithAggregationInput = {
  _avg?: Maybe<LoyaltyAccountAvgOrderByAggregateInput>;
  _count?: Maybe<LoyaltyAccountCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyAccountMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyAccountMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyAccountSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  migratedAt?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyAccountOrderByRelevanceInput>;
  accountHolders?: Maybe<LoyaltyAccountHolderOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  deactivationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  leftOverSpend?: Maybe<SortOrder>;
  mergeSent?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerOrderByRelationAggregateInput>;
  migratedAt?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
  pointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  programId?: Maybe<SortOrder>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyAccountRelationFilter = {
  is?: Maybe<LoyaltyAccountWhereInput>;
  isNot?: Maybe<LoyaltyAccountWhereInput>;
};

export enum LoyaltyAccountScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeactivationDate = 'deactivationDate',
  Id = 'id',
  IsActive = 'isActive',
  LeftOverSpend = 'leftOverSpend',
  MigratedAt = 'migratedAt',
  PointBalance = 'pointBalance',
  ProgramId = 'programId',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyAccountScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deactivationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  leftOverSpend?: Maybe<IntFilter>;
  migratedAt?: Maybe<DateTimeNullableFilter>;
  pointBalance?: Maybe<IntFilter>;
  programId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyAccountScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deactivationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  leftOverSpend?: Maybe<IntWithAggregatesFilter>;
  migratedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pointBalance?: Maybe<IntWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyAccountSubscription = {
  __typename?: 'LoyaltyAccountSubscription';
  mutation: MutationType;
  node: LoyaltyAccount;
};

export type LoyaltyAccountSumAggregateOutputType = {
  __typename?: 'LoyaltyAccountSumAggregateOutputType';
  leftOverSpend?: Maybe<Scalars['Int']>;
  pointBalance?: Maybe<Scalars['Int']>;
};

export type LoyaltyAccountSumOrderByAggregateInput = {
  leftOverSpend?: Maybe<SortOrder>;
  pointBalance?: Maybe<SortOrder>;
};

export type LoyaltyAccountUncheckedCreateInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  programId: Scalars['String'];
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
};

export type LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  programId: Scalars['String'];
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateWithoutMergeSentInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  programId: Scalars['String'];
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateWithoutMergesTakenInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  programId: Scalars['String'];
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateWithoutPointDeltasInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId: Scalars['String'];
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateWithoutProgramInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedOneWithoutMergeDataFromAccountInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutMergeDataIntoAccountInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateManyWithoutAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateWithoutMergeSentInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateWithoutProgramInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUncheckedUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateManyWithWhereWithoutProgramInput = {
  data: LoyaltyAccountUncheckedUpdateManyWithoutAccountsInput;
  where: LoyaltyAccountScalarWhereInput;
};

export type LoyaltyAccountUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyAccountCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyAccountCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyAccountCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyAccountWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyAccountUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutAccountHoldersNestedInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutAccountHoldersInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutAccountHoldersInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutMergeSentNestedInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergeSentInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergeSentInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutMergeSentInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutMergeSentInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutMergesTakenNestedInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutMergesTakenInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutMergesTakenInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutMergesTakenInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutPointDeltasInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutPointDeltasInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutPointDeltasInput>;
};

export type LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput = {
  connect?: Maybe<LoyaltyAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyAccountCreateOrConnectWithoutRewardRedemptionsInput>;
  create?: Maybe<LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput>;
  update?: Maybe<LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput>;
  upsert?: Maybe<LoyaltyAccountUpsertWithoutRewardRedemptionsInput>;
};

export type LoyaltyAccountUpdateWithWhereUniqueWithoutProgramInput = {
  data: LoyaltyAccountUncheckedUpdateWithoutProgramInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountUpdateWithoutAccountHoldersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateWithoutMergeSentInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateWithoutMergesTakenInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateWithoutPointDeltasInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateWithoutProgramInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpdateWithoutRewardRedemptionsInput = {
  accountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutAccountNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deactivationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leftOverSpend?: Maybe<Scalars['Int']>;
  mergeSent?: Maybe<LoyaltyAccountMergerUpdateOneWithoutMergeDataFromAccountNestedInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerUpdateManyWithoutMergeDataIntoAccountNestedInput>;
  migratedAt?: Maybe<Scalars['DateTime']>;
  pointBalance?: Maybe<Scalars['Int']>;
  pointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyAccountUpsertWithWhereUniqueWithoutProgramInput = {
  create: LoyaltyAccountUncheckedCreateWithoutProgramInput;
  update: LoyaltyAccountUncheckedUpdateWithoutProgramInput;
  where: LoyaltyAccountWhereUniqueInput;
};

export type LoyaltyAccountUpsertWithoutAccountHoldersInput = {
  create: LoyaltyAccountUncheckedCreateWithoutAccountHoldersInput;
  update: LoyaltyAccountUncheckedUpdateWithoutAccountHoldersInput;
};

export type LoyaltyAccountUpsertWithoutMergeSentInput = {
  create: LoyaltyAccountUncheckedCreateWithoutMergeSentInput;
  update: LoyaltyAccountUncheckedUpdateWithoutMergeSentInput;
};

export type LoyaltyAccountUpsertWithoutMergesTakenInput = {
  create: LoyaltyAccountUncheckedCreateWithoutMergesTakenInput;
  update: LoyaltyAccountUncheckedUpdateWithoutMergesTakenInput;
};

export type LoyaltyAccountUpsertWithoutPointDeltasInput = {
  create: LoyaltyAccountUncheckedCreateWithoutPointDeltasInput;
  update: LoyaltyAccountUncheckedUpdateWithoutPointDeltasInput;
};

export type LoyaltyAccountUpsertWithoutRewardRedemptionsInput = {
  create: LoyaltyAccountUncheckedCreateWithoutRewardRedemptionsInput;
  update: LoyaltyAccountUncheckedUpdateWithoutRewardRedemptionsInput;
};

export type LoyaltyAccountWhereInput = {
  AND?: Maybe<Array<LoyaltyAccountWhereInput>>;
  NOT?: Maybe<Array<LoyaltyAccountWhereInput>>;
  OR?: Maybe<Array<LoyaltyAccountWhereInput>>;
  accountHolders?: Maybe<LoyaltyAccountHolderListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deactivationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  leftOverSpend?: Maybe<IntFilter>;
  mergeSent?: Maybe<LoyaltyAccountMergerWhereInput>;
  mergesTaken?: Maybe<LoyaltyAccountMergerListRelationFilter>;
  migratedAt?: Maybe<DateTimeNullableFilter>;
  pointBalance?: Maybe<IntFilter>;
  pointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  rewardRedemptions?: Maybe<LoyaltyRewardRedemptionListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoicePointGranter = {
  __typename?: 'LoyaltyInvoicePointGranter';
  _count: LoyaltyInvoicePointGranterCountOutputType;
  allowAllServices: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  grantingServiceCategories: Array<LoyaltyPointGrantingServiceCategory>;
  grantingServices: Array<LoyaltyPointGrantingService>;
  id: Scalars['String'];
  label: Scalars['String'];
  program: LoyaltyProgram;
  programId: Scalars['String'];
  relatedInvoiceLoyaltyPointDeltas: Array<LoyaltyPointDelta>;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyInvoicePointGranterGrantingServiceCategoriesArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type LoyaltyInvoicePointGranterGrantingServicesArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type LoyaltyInvoicePointGranterRelatedInvoiceLoyaltyPointDeltasArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export type LoyaltyInvoicePointGranterCountAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterCountAggregateOutputType';
  _all: Scalars['Int'];
  allowAllServices: Scalars['Int'];
  createdAt: Scalars['Int'];
  enabled: Scalars['Int'];
  id: Scalars['Int'];
  label: Scalars['Int'];
  programId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyInvoicePointGranterCountOrderByAggregateInput = {
  allowAllServices?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterCountOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterCountOutputType';
  grantingServiceCategories: Scalars['Int'];
  grantingServices: Scalars['Int'];
  relatedInvoiceLoyaltyPointDeltas: Scalars['Int'];
};

export type LoyaltyInvoicePointGranterCreateInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateManyInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateManyProgramInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyInvoicePointGranterCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput>;
};

export type LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterCreateWithoutGrantingServiceCategoriesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateWithoutGrantingServicesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateWithoutProgramInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutPointGrantersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterListRelationFilter = {
  every?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  none?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  some?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export type LoyaltyInvoicePointGranterMaxAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterMaxAggregateOutputType';
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterMaxOrderByAggregateInput = {
  allowAllServices?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterMinAggregateOutputType = {
  __typename?: 'LoyaltyInvoicePointGranterMinAggregateOutputType';
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterMinOrderByAggregateInput = {
  allowAllServices?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyInvoicePointGranterOrderByRelevanceFieldEnum {
  Id = 'id',
  Label = 'label',
  ProgramId = 'programId'
}

export type LoyaltyInvoicePointGranterOrderByRelevanceInput = {
  fields: Array<LoyaltyInvoicePointGranterOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyInvoicePointGranterOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyInvoicePointGranterCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyInvoicePointGranterMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyInvoicePointGranterMinOrderByAggregateInput>;
  allowAllServices?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyInvoicePointGranterOrderByRelevanceInput>;
  allowAllServices?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  programId?: Maybe<SortOrder>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyInvoicePointGranterRelationFilter = {
  is?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  isNot?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};

export enum LoyaltyInvoicePointGranterScalarFieldEnum {
  AllowAllServices = 'allowAllServices',
  CreatedAt = 'createdAt',
  Enabled = 'enabled',
  Id = 'id',
  Label = 'label',
  ProgramId = 'programId',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyInvoicePointGranterScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  allowAllServices?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  enabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  programId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereWithAggregatesInput>>;
  allowAllServices?: Maybe<BoolWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  enabled?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  label?: Maybe<StringWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutPointGrantersInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateManyMutationInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput = {
  data: LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutPointGrantersInput;
  where: LoyaltyInvoicePointGranterScalarWhereInput;
};

export type LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyInvoicePointGranterCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyInvoicePointGranterCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyInvoicePointGranterCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyInvoicePointGranterScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyInvoicePointGranterWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyInvoicePointGranterUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServiceCategoriesInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutGrantingServiceCategoriesInput>;
};

export type LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutGrantingServicesInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutGrantingServicesInput>;
};

export type LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput = {
  connect?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyInvoicePointGranterCreateOrConnectWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  create?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  upsert?: Maybe<LoyaltyInvoicePointGranterUpsertWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
};

export type LoyaltyInvoicePointGranterUpdateWithWhereUniqueWithoutProgramInput = {
  data: LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterUpdateWithoutGrantingServiceCategoriesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutGrantingServicesInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutProgramInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  allowAllServices?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyInvoicePointGranterUpsertWithWhereUniqueWithoutProgramInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutProgramInput;
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutProgramInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutGrantingServiceCategoriesInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServiceCategoriesInput;
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServiceCategoriesInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutGrantingServicesInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutGrantingServicesInput;
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutGrantingServicesInput;
};

export type LoyaltyInvoicePointGranterUpsertWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  create: LoyaltyInvoicePointGranterUncheckedCreateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
  update: LoyaltyInvoicePointGranterUncheckedUpdateWithoutRelatedInvoiceLoyaltyPointDeltasInput;
};

export type LoyaltyInvoicePointGranterWhereInput = {
  AND?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  NOT?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  OR?: Maybe<Array<LoyaltyInvoicePointGranterWhereInput>>;
  allowAllServices?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  enabled?: Maybe<BoolFilter>;
  grantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryListRelationFilter>;
  grantingServices?: Maybe<LoyaltyPointGrantingServiceListRelationFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  relatedInvoiceLoyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyInvoicePointGranterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyInvoiceTriggerInput = {
  invoiceId: Scalars['String'];
};

export type LoyaltyInvoiceTriggerOutput = {
  __typename?: 'LoyaltyInvoiceTriggerOutput';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type LoyaltyPointDelta = {
  __typename?: 'LoyaltyPointDelta';
  account: LoyaltyAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  grantingInvoice?: Maybe<Invoice>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount: Scalars['Boolean'];
  relatedClinic: Clinic;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
  source: LoyaltyPointDeltaSource;
  updatedAt: Scalars['DateTime'];
  userGrant?: Maybe<LoyaltyPointUserGrant>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaAvgAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaAvgAggregateOutputType';
  leftOverSpendDelta?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

export type LoyaltyPointDeltaAvgOrderByAggregateInput = {
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaCountAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaCountAggregateOutputType';
  _all: Scalars['Int'];
  accountId: Scalars['Int'];
  createdAt: Scalars['Int'];
  grantingInvoiceId: Scalars['Int'];
  id: Scalars['Int'];
  invoicePointGranterId: Scalars['Int'];
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount: Scalars['Int'];
  relatedClinicId: Scalars['Int'];
  source: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userGrantId: Scalars['Int'];
};

export type LoyaltyPointDeltaCountOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaCreateInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyGrantingInvoiceInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyGrantingInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyInvoicePointGranterInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyInvoicePointGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyRelatedClinicInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyRelatedClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateManyUserGrantInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaCreateManyUserGrantInputEnvelope = {
  data: Array<LoyaltyPointDeltaCreateManyUserGrantInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutGrantingInvoiceInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutInvoicePointGranterInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutRelatedClinicInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
};

export type LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
};

export type LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput = {
  connect?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput>;
  create?: Maybe<LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutAccountInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutAccountInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateWithoutRelatedClinicInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateWithoutRelatedRedemptionHistoryEntryInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointDeltaCreateWithoutUserGrantInput = {
  account: LoyaltyAccountCreateNestedOneWithoutPointDeltasInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceCreateNestedOneWithoutLoyaltyPointDeltasInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterCreateNestedOneWithoutRelatedInvoiceLoyaltyPointDeltasInput>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic: ClinicCreateNestedOneWithoutLoyaltyPointDeltasInput;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaGrantingInvoiceIdInvoicePointGranterIdCompoundUniqueInput = {
  grantingInvoiceId: Scalars['String'];
  invoicePointGranterId: Scalars['String'];
};

export type LoyaltyPointDeltaListRelationFilter = {
  every?: Maybe<LoyaltyPointDeltaWhereInput>;
  none?: Maybe<LoyaltyPointDeltaWhereInput>;
  some?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export type LoyaltyPointDeltaMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaMaxAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaMaxOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaMinAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaMinAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaMinOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointDeltaOrderByRelevanceFieldEnum {
  AccountId = 'accountId',
  GrantingInvoiceId = 'grantingInvoiceId',
  Id = 'id',
  InvoicePointGranterId = 'invoicePointGranterId',
  RelatedClinicId = 'relatedClinicId',
  UserGrantId = 'userGrantId'
}

export type LoyaltyPointDeltaOrderByRelevanceInput = {
  fields: Array<LoyaltyPointDeltaOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyPointDeltaOrderByWithAggregationInput = {
  _avg?: Maybe<LoyaltyPointDeltaAvgOrderByAggregateInput>;
  _count?: Maybe<LoyaltyPointDeltaCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointDeltaMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointDeltaMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyPointDeltaSumOrderByAggregateInput>;
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingInvoiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoicePointGranterId?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGrantId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyPointDeltaOrderByRelevanceInput>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingInvoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  grantingInvoiceId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  invoicePointGranterId?: Maybe<SortOrder>;
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  postedToAccount?: Maybe<SortOrder>;
  relatedClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  relatedClinicId?: Maybe<SortOrder>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  source?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userGrant?: Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>;
  userGrantId?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaRelationFilter = {
  is?: Maybe<LoyaltyPointDeltaWhereInput>;
  isNot?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export enum LoyaltyPointDeltaScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  GrantingInvoiceId = 'grantingInvoiceId',
  Id = 'id',
  InvoicePointGranterId = 'invoicePointGranterId',
  LeftOverSpendDelta = 'leftOverSpendDelta',
  Points = 'points',
  PostedToAccount = 'postedToAccount',
  RelatedClinicId = 'relatedClinicId',
  Source = 'source',
  UpdatedAt = 'updatedAt',
  UserGrantId = 'userGrantId'
}

export type LoyaltyPointDeltaScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  grantingInvoiceId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoicePointGranterId?: Maybe<StringNullableFilter>;
  leftOverSpendDelta?: Maybe<IntFilter>;
  points?: Maybe<IntFilter>;
  postedToAccount?: Maybe<BoolFilter>;
  relatedClinicId?: Maybe<StringFilter>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userGrantId?: Maybe<StringNullableFilter>;
};

export type LoyaltyPointDeltaScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaScalarWhereWithAggregatesInput>>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  grantingInvoiceId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invoicePointGranterId?: Maybe<StringNullableWithAggregatesFilter>;
  leftOverSpendDelta?: Maybe<IntWithAggregatesFilter>;
  points?: Maybe<IntWithAggregatesFilter>;
  postedToAccount?: Maybe<BoolWithAggregatesFilter>;
  relatedClinicId?: Maybe<StringWithAggregatesFilter>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userGrantId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum LoyaltyPointDeltaSource {
  Correction = 'Correction',
  InvoiceSpend = 'InvoiceSpend',
  Merge = 'Merge',
  Migration = 'Migration',
  RewardRedemption = 'RewardRedemption',
  UserGrant = 'UserGrant'
}

export type LoyaltyPointDeltaSumAggregateOutputType = {
  __typename?: 'LoyaltyPointDeltaSumAggregateOutputType';
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type LoyaltyPointDeltaSumOrderByAggregateInput = {
  leftOverSpendDelta?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
};

export type LoyaltyPointDeltaUncheckedCreateInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutGrantingInvoiceInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutInvoicePointGranterInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutRelatedClinicInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
};

export type LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta: Scalars['Int'];
  points: Scalars['Int'];
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId: Scalars['String'];
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput>;
  source: LoyaltyPointDeltaSource;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaUncheckedUpdateInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutGrantingInvoiceNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutInvoicePointGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutPointDeltasInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedClinicNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedInvoiceLoyaltyPointDeltasInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput>>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrantId?: Maybe<Scalars['String']>;
};

export type LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoiceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranterId?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaUpdateInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput = {
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutPointDeltasInput;
  where: LoyaltyPointDeltaScalarWhereInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput = {
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
  where: LoyaltyPointDeltaScalarWhereInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput = {
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutRelatedInvoiceLoyaltyPointDeltasInput;
  where: LoyaltyPointDeltaScalarWhereInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput = {
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
  where: LoyaltyPointDeltaScalarWhereInput;
};

export type LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput = {
  data: LoyaltyPointDeltaUncheckedUpdateManyWithoutLoyaltyPointDeltasInput;
  where: LoyaltyPointDeltaScalarWhereInput;
};

export type LoyaltyPointDeltaUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutGrantingInvoiceNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutGrantingInvoiceInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutGrantingInvoiceInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyGrantingInvoiceInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutGrantingInvoiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutInvoicePointGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutInvoicePointGranterInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutInvoicePointGranterInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyInvoicePointGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutInvoicePointGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutRelatedClinicNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyRelatedClinicInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
};

export type LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput = {
  connect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointDeltaCreateOrConnectWithoutUserGrantInput>>;
  create?: Maybe<Array<LoyaltyPointDeltaCreateWithoutUserGrantInput>>;
  createMany?: Maybe<LoyaltyPointDeltaCreateManyUserGrantInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointDeltaScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointDeltaWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput>>;
  updateMany?: Maybe<Array<LoyaltyPointDeltaUpdateManyWithWhereWithoutUserGrantInput>>;
  upsert?: Maybe<Array<LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput>>;
};

export type LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput = {
  connect?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyPointDeltaCreateOrConnectWithoutRelatedRedemptionHistoryEntryInput>;
  create?: Maybe<LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput>;
  upsert?: Maybe<LoyaltyPointDeltaUpsertWithoutRelatedRedemptionHistoryEntryInput>;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutAccountInput = {
  data: LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutGrantingInvoiceInput = {
  data: LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutInvoicePointGranterInput = {
  data: LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutRelatedClinicInput = {
  data: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpdateWithWhereUniqueWithoutUserGrantInput = {
  data: LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpdateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutGrantingInvoiceInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutInvoicePointGranterInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutRelatedClinicInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutRelatedRedemptionHistoryEntryInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGrant?: Maybe<LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
};

export type LoyaltyPointDeltaUpdateWithoutUserGrantInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutPointDeltasNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingInvoice?: Maybe<InvoiceUpdateOneWithoutLoyaltyPointDeltasNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterUpdateOneWithoutRelatedInvoiceLoyaltyPointDeltasNestedInput>;
  leftOverSpendDelta?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  postedToAccount?: Maybe<Scalars['Boolean']>;
  relatedClinic?: Maybe<ClinicUpdateOneRequiredWithoutLoyaltyPointDeltasNestedInput>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput>;
  source?: Maybe<LoyaltyPointDeltaSource>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutAccountInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutAccountInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutAccountInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutGrantingInvoiceInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutGrantingInvoiceInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutGrantingInvoiceInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutInvoicePointGranterInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutInvoicePointGranterInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutInvoicePointGranterInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutRelatedClinicInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedClinicInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedClinicInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpsertWithWhereUniqueWithoutUserGrantInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutUserGrantInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutUserGrantInput;
  where: LoyaltyPointDeltaWhereUniqueInput;
};

export type LoyaltyPointDeltaUpsertWithoutRelatedRedemptionHistoryEntryInput = {
  create: LoyaltyPointDeltaUncheckedCreateWithoutRelatedRedemptionHistoryEntryInput;
  update: LoyaltyPointDeltaUncheckedUpdateWithoutRelatedRedemptionHistoryEntryInput;
};

export type LoyaltyPointDeltaWhereInput = {
  AND?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointDeltaWhereInput>>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  grantingInvoice?: Maybe<InvoiceWhereInput>;
  grantingInvoiceId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoicePointGranter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  invoicePointGranterId?: Maybe<StringNullableFilter>;
  leftOverSpendDelta?: Maybe<IntFilter>;
  points?: Maybe<IntFilter>;
  postedToAccount?: Maybe<BoolFilter>;
  relatedClinic?: Maybe<ClinicWhereInput>;
  relatedClinicId?: Maybe<StringFilter>;
  relatedRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  source?: Maybe<EnumLoyaltyPointDeltaSourceFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userGrant?: Maybe<LoyaltyPointUserGrantWhereInput>;
  userGrantId?: Maybe<StringNullableFilter>;
};

export type LoyaltyPointDeltaWhereUniqueInput = {
  grantingInvoiceId_invoicePointGranterId?: Maybe<LoyaltyPointDeltaGrantingInvoiceIdInvoicePointGranterIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantByUser = {
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyAccountId: Scalars['String'];
  points: Scalars['Int'];
  publicNotes?: Maybe<Scalars['String']>;
};

export type LoyaltyPointGrantingService = {
  __typename?: 'LoyaltyPointGrantingService';
  createdAt: Scalars['DateTime'];
  granter: LoyaltyInvoicePointGranter;
  granterId: Scalars['String'];
  service: Service;
  serviceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPointGrantingServiceCategory = {
  __typename?: 'LoyaltyPointGrantingServiceCategory';
  createdAt: Scalars['DateTime'];
  granter: LoyaltyInvoicePointGranter;
  granterId: Scalars['String'];
  serviceCategory: ServiceCategory;
  serviceCategoryId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPointGrantingServiceCategoryCountAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  granterId: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyPointGrantingServiceCategoryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCategoryCreateManyGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  serviceCategoryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutGranterInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput = {
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput = {
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServiceCategoriesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryListRelationFilter = {
  every?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  none?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
  some?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};

export type LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryMinAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCategoryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceCategoryOrderByRelevanceFieldEnum {
  GranterId = 'granterId',
  ServiceCategoryId = 'serviceCategoryId'
}

export type LoyaltyPointGrantingServiceCategoryOrderByRelevanceInput = {
  fields: Array<LoyaltyPointGrantingServiceCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyPointGrantingServiceCategoryOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyPointGrantingServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointGrantingServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointGrantingServiceCategoryMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  granter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  granterId?: Maybe<SortOrder>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceCategoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  GranterId = 'granterId',
  ServiceCategoryId = 'serviceCategoryId',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyPointGrantingServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  granterId?: Maybe<StringFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  granterId?: Maybe<StringWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyPointGrantingServiceCategoryServiceCategoryIdGranterIdCompoundUniqueInput = {
  granterId: Scalars['String'];
  serviceCategoryId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  serviceCategoryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutGranterInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGrantingServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput = {
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutGrantingServiceCategoriesInput;
  where: LoyaltyPointGrantingServiceCategoryScalarWhereInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput = {
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutLoyaltyPointGrantingServiceCategoriesInput;
  where: LoyaltyPointGrantingServiceCategoryScalarWhereInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithoutGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutGranterInput = {
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  data: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpdateWithoutServiceCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServiceCategoriesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutGranterInput = {
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutGranterInput;
  update: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  create: LoyaltyPointGrantingServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
  update: LoyaltyPointGrantingServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCategoryWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceCategoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  granter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  granterId?: Maybe<StringFilter>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
  serviceCategoryId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointGrantingServiceCategoryWhereUniqueInput = {
  serviceCategoryId_granterId?: Maybe<LoyaltyPointGrantingServiceCategoryServiceCategoryIdGranterIdCompoundUniqueInput>;
};

export type LoyaltyPointGrantingServiceCountAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  granterId: Scalars['Int'];
  serviceId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyPointGrantingServiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput;
  service: ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCreateManyGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCreateManyGranterInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCreateManyServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope = {
  data: Array<LoyaltyPointGrantingServiceCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointGrantingServiceCreateNestedManyWithoutGranterInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
};

export type LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput = {
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput = {
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  service: ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter: LoyaltyInvoicePointGranterCreateNestedOneWithoutGrantingServicesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceListRelationFilter = {
  every?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  none?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
  some?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};

export type LoyaltyPointGrantingServiceMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceMinAggregateOutputType = {
  __typename?: 'LoyaltyPointGrantingServiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceOrderByRelevanceFieldEnum {
  GranterId = 'granterId',
  ServiceId = 'serviceId'
}

export type LoyaltyPointGrantingServiceOrderByRelevanceInput = {
  fields: Array<LoyaltyPointGrantingServiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyPointGrantingServiceOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyPointGrantingServiceCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointGrantingServiceMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointGrantingServiceMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  granterId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyPointGrantingServiceOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  granter?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  granterId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LoyaltyPointGrantingServiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  GranterId = 'granterId',
  ServiceId = 'serviceId',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyPointGrantingServiceScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  granterId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  granterId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyPointGrantingServiceServiceIdGranterIdCompoundUniqueInput = {
  granterId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type LoyaltyPointGrantingServiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutGranterInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGrantingServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutLoyaltyPointGrantingServicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granterId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput = {
  data: LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutGrantingServicesInput;
  where: LoyaltyPointGrantingServiceScalarWhereInput;
};

export type LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput = {
  data: LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutLoyaltyPointGrantingServicesInput;
  where: LoyaltyPointGrantingServiceScalarWhereInput;
};

export type LoyaltyPointGrantingServiceUpdateManyWithoutGranterNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutGranterInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutGranterInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyGranterInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutGranterInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput>>;
};

export type LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointGrantingServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<LoyaltyPointGrantingServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<LoyaltyPointGrantingServiceCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointGrantingServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointGrantingServiceWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<LoyaltyPointGrantingServiceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutGranterInput = {
  data: LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceUpdateWithoutGranterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  granter?: Maybe<LoyaltyInvoicePointGranterUpdateOneRequiredWithoutGrantingServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutGranterInput = {
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutGranterInput;
  update: LoyaltyPointGrantingServiceUncheckedUpdateWithoutGranterInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: LoyaltyPointGrantingServiceUncheckedCreateWithoutServiceInput;
  update: LoyaltyPointGrantingServiceUncheckedUpdateWithoutServiceInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};

export type LoyaltyPointGrantingServiceWhereInput = {
  AND?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointGrantingServiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  granter?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
  granterId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointGrantingServiceWhereUniqueInput = {
  serviceId_granterId?: Maybe<LoyaltyPointGrantingServiceServiceIdGranterIdCompoundUniqueInput>;
};

export type LoyaltyPointUserGrant = {
  __typename?: 'LoyaltyPointUserGrant';
  _count: LoyaltyPointUserGrantCountOutputType;
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  grantingUser: User;
  grantingUserId: Scalars['String'];
  id: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas: Array<LoyaltyPointDelta>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyPointUserGrantLoyaltyPointDeltasArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointDeltaScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};

export type LoyaltyPointUserGrantAvgAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type LoyaltyPointUserGrantAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantCountAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  grantingUserId: Scalars['Int'];
  id: Scalars['Int'];
  internalNotes: Scalars['Int'];
  publicNotes: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyPointUserGrantCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantCountOutputType = {
  __typename?: 'LoyaltyPointUserGrantCountOutputType';
  loyaltyPointDeltas: Scalars['Int'];
};

export type LoyaltyPointUserGrantCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUser: UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantCreateManyGrantingUserInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope = {
  data: Array<LoyaltyPointUserGrantCreateManyGrantingUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyPointUserGrantCreateManyInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput = {
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
};

export type LoyaltyPointUserGrantCreateNestedOneWithoutLoyaltyPointDeltasInput = {
  connect?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput = {
  create: LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};

export type LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput = {
  create: LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};

export type LoyaltyPointUserGrantCreateWithoutGrantingUserInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaCreateNestedManyWithoutUserGrantInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantCreateWithoutLoyaltyPointDeltasInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUser: UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantListRelationFilter = {
  every?: Maybe<LoyaltyPointUserGrantWhereInput>;
  none?: Maybe<LoyaltyPointUserGrantWhereInput>;
  some?: Maybe<LoyaltyPointUserGrantWhereInput>;
};

export type LoyaltyPointUserGrantMaxAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantMinAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyPointUserGrantOrderByRelevanceFieldEnum {
  GrantingUserId = 'grantingUserId',
  Id = 'id',
  InternalNotes = 'internalNotes',
  PublicNotes = 'publicNotes'
}

export type LoyaltyPointUserGrantOrderByRelevanceInput = {
  fields: Array<LoyaltyPointUserGrantOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyPointUserGrantOrderByWithAggregationInput = {
  _avg?: Maybe<LoyaltyPointUserGrantAvgOrderByAggregateInput>;
  _count?: Maybe<LoyaltyPointUserGrantCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyPointUserGrantMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyPointUserGrantMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyPointUserGrantSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  publicNotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyPointUserGrantOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  grantingUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  grantingUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaOrderByRelationAggregateInput>;
  publicNotes?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantRelationFilter = {
  is?: Maybe<LoyaltyPointUserGrantWhereInput>;
  isNot?: Maybe<LoyaltyPointUserGrantWhereInput>;
};

export enum LoyaltyPointUserGrantScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  GrantingUserId = 'grantingUserId',
  Id = 'id',
  InternalNotes = 'internalNotes',
  PublicNotes = 'publicNotes',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyPointUserGrantScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  grantingUserId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  publicNotes?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointUserGrantScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  grantingUserId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  internalNotes?: Maybe<StringNullableWithAggregatesFilter>;
  publicNotes?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyPointUserGrantSumAggregateOutputType = {
  __typename?: 'LoyaltyPointUserGrantSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type LoyaltyPointUserGrantSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type LoyaltyPointUserGrantUncheckedCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput = {
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
};

export type LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedCreateNestedManyWithoutUserGrantInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput = {
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput>>;
  upsert?: Maybe<Array<LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput>>;
};

export type LoyaltyPointUserGrantUncheckedUpdateManyWithoutLoyaltyPointUserGrantsInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUncheckedUpdateManyWithoutUserGrantNestedInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUser?: Maybe<UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput = {
  data: LoyaltyPointUserGrantUncheckedUpdateManyWithoutLoyaltyPointUserGrantsInput;
  where: LoyaltyPointUserGrantScalarWhereInput;
};

export type LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput = {
  connect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyPointUserGrantCreateOrConnectWithoutGrantingUserInput>>;
  create?: Maybe<Array<LoyaltyPointUserGrantCreateWithoutGrantingUserInput>>;
  createMany?: Maybe<LoyaltyPointUserGrantCreateManyGrantingUserInputEnvelope>;
  delete?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyPointUserGrantScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyPointUserGrantWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyPointUserGrantUpdateManyWithWhereWithoutGrantingUserInput>>;
  upsert?: Maybe<Array<LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput>>;
};

export type LoyaltyPointUserGrantUpdateOneWithoutLoyaltyPointDeltasNestedInput = {
  connect?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyPointUserGrantCreateOrConnectWithoutLoyaltyPointDeltasInput>;
  create?: Maybe<LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput>;
  upsert?: Maybe<LoyaltyPointUserGrantUpsertWithoutLoyaltyPointDeltasInput>;
};

export type LoyaltyPointUserGrantUpdateWithWhereUniqueWithoutGrantingUserInput = {
  data: LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};

export type LoyaltyPointUserGrantUpdateWithoutGrantingUserInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaUpdateManyWithoutUserGrantNestedInput>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUpdateWithoutLoyaltyPointDeltasInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  grantingUser?: Maybe<UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  publicNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPointUserGrantUpsertWithWhereUniqueWithoutGrantingUserInput = {
  create: LoyaltyPointUserGrantUncheckedCreateWithoutGrantingUserInput;
  update: LoyaltyPointUserGrantUncheckedUpdateWithoutGrantingUserInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};

export type LoyaltyPointUserGrantUpsertWithoutLoyaltyPointDeltasInput = {
  create: LoyaltyPointUserGrantUncheckedCreateWithoutLoyaltyPointDeltasInput;
  update: LoyaltyPointUserGrantUncheckedUpdateWithoutLoyaltyPointDeltasInput;
};

export type LoyaltyPointUserGrantWhereInput = {
  AND?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  NOT?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  OR?: Maybe<Array<LoyaltyPointUserGrantWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  grantingUser?: Maybe<UserWhereInput>;
  grantingUserId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  loyaltyPointDeltas?: Maybe<LoyaltyPointDeltaListRelationFilter>;
  publicNotes?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyPointUserGrantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  _count: LoyaltyProgramCountOutputType;
  accounts: Array<LoyaltyAccount>;
  allowOpenInvoices: Scalars['Boolean'];
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  loyaltyAccountHolders: Array<LoyaltyAccountHolder>;
  minimumTransactionCents: Scalars['Int'];
  participatingClinics: Array<Clinic>;
  pointGranters: Array<LoyaltyInvoicePointGranter>;
  rewards: Array<LoyaltyReward>;
  status: LoyaltyProgramStatus;
  statusHistories: Array<LoyaltyProgramStatusHistory>;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyProgramAccountsArgs = {
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  distinct?: Maybe<LoyaltyAccountScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type LoyaltyProgramLoyaltyAccountHoldersArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  distinct?: Maybe<LoyaltyAccountHolderScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type LoyaltyProgramParticipatingClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type LoyaltyProgramPointGrantersArgs = {
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  distinct?: Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type LoyaltyProgramRewardsArgs = {
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type LoyaltyProgramStatusHistoriesArgs = {
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};

export type LoyaltyProgramAvgAggregateOutputType = {
  __typename?: 'LoyaltyProgramAvgAggregateOutputType';
  centsPerPoint?: Maybe<Scalars['Float']>;
  minimumTransactionCents?: Maybe<Scalars['Float']>;
};

export type LoyaltyProgramAvgOrderByAggregateInput = {
  centsPerPoint?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
};

export type LoyaltyProgramCountAggregateOutputType = {
  __typename?: 'LoyaltyProgramCountAggregateOutputType';
  _all: Scalars['Int'];
  allowOpenInvoices: Scalars['Int'];
  allowSpendCarryOver: Scalars['Int'];
  centsPerPoint: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  minimumTransactionCents: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyProgramCountOrderByAggregateInput = {
  allowOpenInvoices?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyProgramCountOutputType = {
  __typename?: 'LoyaltyProgramCountOutputType';
  accounts: Scalars['Int'];
  loyaltyAccountHolders: Scalars['Int'];
  participatingClinics: Scalars['Int'];
  pointGranters: Scalars['Int'];
  rewards: Scalars['Int'];
  statusHistories: Scalars['Int'];
};

export type LoyaltyProgramCreateInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateManyInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  status: LoyaltyProgramStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateNestedOneWithoutAccountsInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutAccountsInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutLoyaltyAccountHoldersInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutParticipatingClinicsInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutPointGrantersInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutPointGrantersInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutPointGrantersInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutRewardsInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutRewardsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutRewardsInput>;
};

export type LoyaltyProgramCreateNestedOneWithoutStatusHistoriesInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput>;
};

export type LoyaltyProgramCreateOrConnectWithoutAccountsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutAccountsInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput = {
  create: LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateOrConnectWithoutPointGrantersInput = {
  create: LoyaltyProgramUncheckedCreateWithoutPointGrantersInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateOrConnectWithoutRewardsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutRewardsInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput = {
  create: LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput;
  where: LoyaltyProgramWhereUniqueInput;
};

export type LoyaltyProgramCreateWithoutAccountsInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateWithoutLoyaltyAccountHoldersInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateWithoutParticipatingClinicsInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateWithoutPointGrantersInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateWithoutRewardsInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramCreateWithoutStatusHistoriesInput = {
  accounts?: Maybe<LoyaltyAccountCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramMaxAggregateOutputType = {
  __typename?: 'LoyaltyProgramMaxAggregateOutputType';
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramMaxOrderByAggregateInput = {
  allowOpenInvoices?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyProgramMinAggregateOutputType = {
  __typename?: 'LoyaltyProgramMinAggregateOutputType';
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramMinOrderByAggregateInput = {
  allowOpenInvoices?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LoyaltyProgramOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id'
}

export type LoyaltyProgramOrderByRelevanceInput = {
  fields: Array<LoyaltyProgramOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyProgramOrderByWithAggregationInput = {
  _avg?: Maybe<LoyaltyProgramAvgOrderByAggregateInput>;
  _count?: Maybe<LoyaltyProgramCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyProgramMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyProgramMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyProgramSumOrderByAggregateInput>;
  allowOpenInvoices?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyProgramOrderByRelevanceInput>;
  accounts?: Maybe<LoyaltyAccountOrderByRelationAggregateInput>;
  allowOpenInvoices?: Maybe<SortOrder>;
  allowSpendCarryOver?: Maybe<SortOrder>;
  centsPerPoint?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderOrderByRelationAggregateInput>;
  minimumTransactionCents?: Maybe<SortOrder>;
  participatingClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterOrderByRelationAggregateInput>;
  rewards?: Maybe<LoyaltyRewardOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyProgramRelationFilter = {
  is?: Maybe<LoyaltyProgramWhereInput>;
  isNot?: Maybe<LoyaltyProgramWhereInput>;
};

export enum LoyaltyProgramScalarFieldEnum {
  AllowOpenInvoices = 'allowOpenInvoices',
  AllowSpendCarryOver = 'allowSpendCarryOver',
  CentsPerPoint = 'centsPerPoint',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  MinimumTransactionCents = 'minimumTransactionCents',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyProgramScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyProgramScalarWhereWithAggregatesInput>>;
  allowOpenInvoices?: Maybe<BoolWithAggregatesFilter>;
  allowSpendCarryOver?: Maybe<BoolWithAggregatesFilter>;
  centsPerPoint?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  minimumTransactionCents?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum LoyaltyProgramStatus {
  Archived = 'Archived',
  Draft = 'Draft',
  Live = 'Live',
  Test = 'Test'
}

export type LoyaltyProgramStatusHistory = {
  __typename?: 'LoyaltyProgramStatusHistory';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  program: LoyaltyProgram;
  programId: Scalars['String'];
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryCountAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  programId: Scalars['Int'];
  status: Scalars['Int'];
};

export type LoyaltyProgramStatusHistoryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program: LoyaltyProgramCreateNestedOneWithoutStatusHistoriesInput;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryCreateManyProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyProgramStatusHistoryCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyProgramStatusHistoryCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
};

export type LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput = {
  create: LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput;
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
};

export type LoyaltyProgramStatusHistoryCreateWithoutProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryListRelationFilter = {
  every?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  none?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
  some?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};

export type LoyaltyProgramStatusHistoryMaxAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryMinAggregateOutputType = {
  __typename?: 'LoyaltyProgramStatusHistoryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyProgramStatusHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  ProgramId = 'programId'
}

export type LoyaltyProgramStatusHistoryOrderByRelevanceInput = {
  fields: Array<LoyaltyProgramStatusHistoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyProgramStatusHistoryOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyProgramStatusHistoryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyProgramStatusHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyProgramStatusHistoryMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyProgramStatusHistoryOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  programId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export enum LoyaltyProgramStatusHistoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProgramId = 'programId',
  Status = 'status'
}

export type LoyaltyProgramStatusHistoryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  programId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
};

export type LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusWithAggregatesFilter>;
};

export type LoyaltyProgramStatusHistoryUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
};

export type LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status: LoyaltyProgramStatus;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutStatusHistoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutStatusHistoriesNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput = {
  data: LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutStatusHistoriesInput;
  where: LoyaltyProgramStatusHistoryScalarWhereInput;
};

export type LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyProgramStatusHistoryCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyProgramStatusHistoryCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyProgramStatusHistoryCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyProgramStatusHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyProgramStatusHistoryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyProgramStatusHistoryUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyProgramStatusHistoryUpdateWithWhereUniqueWithoutProgramInput = {
  data: LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput;
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
};

export type LoyaltyProgramStatusHistoryUpdateWithoutProgramInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyProgramStatus>;
};

export type LoyaltyProgramStatusHistoryUpsertWithWhereUniqueWithoutProgramInput = {
  create: LoyaltyProgramStatusHistoryUncheckedCreateWithoutProgramInput;
  update: LoyaltyProgramStatusHistoryUncheckedUpdateWithoutProgramInput;
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
};

export type LoyaltyProgramStatusHistoryWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramStatusHistoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
};

export type LoyaltyProgramStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramSubscription = {
  __typename?: 'LoyaltyProgramSubscription';
  mutation: MutationType;
  node: LoyaltyProgram;
};

export type LoyaltyProgramSumAggregateOutputType = {
  __typename?: 'LoyaltyProgramSumAggregateOutputType';
  centsPerPoint?: Maybe<Scalars['Int']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
};

export type LoyaltyProgramSumOrderByAggregateInput = {
  centsPerPoint?: Maybe<SortOrder>;
  minimumTransactionCents?: Maybe<SortOrder>;
};

export type LoyaltyProgramUncheckedCreateInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutAccountsInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutPointGrantersInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutRewardsInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedCreateNestedManyWithoutProgramInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedCreateNestedManyWithoutProgramInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver: Scalars['Boolean'];
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedCreateNestedManyWithoutProgramInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutLoyaltyProgramInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedCreateNestedManyWithoutProgramInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput>;
  status: LoyaltyProgramStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateManyInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutAccountsInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutRewardsInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUncheckedUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput = {
  accounts?: Maybe<LoyaltyAccountUncheckedUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUncheckedUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUncheckedUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUncheckedUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateManyMutationInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutAccountsNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutAccountsInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutAccountsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutAccountsInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutLoyaltyAccountHoldersNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutLoyaltyAccountHoldersInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutLoyaltyAccountHoldersInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutPointGrantersNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutPointGrantersInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutPointGrantersInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutPointGrantersInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutRewardsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutRewardsInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutRewardsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutRewardsInput>;
};

export type LoyaltyProgramUpdateOneRequiredWithoutStatusHistoriesNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutStatusHistoriesInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutStatusHistoriesInput>;
};

export type LoyaltyProgramUpdateOneWithoutParticipatingClinicsNestedInput = {
  connect?: Maybe<LoyaltyProgramWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyProgramCreateOrConnectWithoutParticipatingClinicsInput>;
  create?: Maybe<LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput>;
  upsert?: Maybe<LoyaltyProgramUpsertWithoutParticipatingClinicsInput>;
};

export type LoyaltyProgramUpdateWithoutAccountsInput = {
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateWithoutLoyaltyAccountHoldersInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateWithoutParticipatingClinicsInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateWithoutPointGrantersInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateWithoutRewardsInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryUpdateManyWithoutProgramNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpdateWithoutStatusHistoriesInput = {
  accounts?: Maybe<LoyaltyAccountUpdateManyWithoutProgramNestedInput>;
  allowOpenInvoices?: Maybe<Scalars['Boolean']>;
  allowSpendCarryOver?: Maybe<Scalars['Boolean']>;
  centsPerPoint?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderUpdateManyWithoutProgramNestedInput>;
  minimumTransactionCents?: Maybe<Scalars['Int']>;
  participatingClinics?: Maybe<ClinicUpdateManyWithoutLoyaltyProgramNestedInput>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterUpdateManyWithoutProgramNestedInput>;
  rewards?: Maybe<LoyaltyRewardUpdateManyWithoutProgramNestedInput>;
  status?: Maybe<LoyaltyProgramStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyProgramUpsertWithoutAccountsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutAccountsInput;
  update: LoyaltyProgramUncheckedUpdateWithoutAccountsInput;
};

export type LoyaltyProgramUpsertWithoutLoyaltyAccountHoldersInput = {
  create: LoyaltyProgramUncheckedCreateWithoutLoyaltyAccountHoldersInput;
  update: LoyaltyProgramUncheckedUpdateWithoutLoyaltyAccountHoldersInput;
};

export type LoyaltyProgramUpsertWithoutParticipatingClinicsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutParticipatingClinicsInput;
  update: LoyaltyProgramUncheckedUpdateWithoutParticipatingClinicsInput;
};

export type LoyaltyProgramUpsertWithoutPointGrantersInput = {
  create: LoyaltyProgramUncheckedCreateWithoutPointGrantersInput;
  update: LoyaltyProgramUncheckedUpdateWithoutPointGrantersInput;
};

export type LoyaltyProgramUpsertWithoutRewardsInput = {
  create: LoyaltyProgramUncheckedCreateWithoutRewardsInput;
  update: LoyaltyProgramUncheckedUpdateWithoutRewardsInput;
};

export type LoyaltyProgramUpsertWithoutStatusHistoriesInput = {
  create: LoyaltyProgramUncheckedCreateWithoutStatusHistoriesInput;
  update: LoyaltyProgramUncheckedUpdateWithoutStatusHistoriesInput;
};

export type LoyaltyProgramWhereInput = {
  AND?: Maybe<Array<LoyaltyProgramWhereInput>>;
  NOT?: Maybe<Array<LoyaltyProgramWhereInput>>;
  OR?: Maybe<Array<LoyaltyProgramWhereInput>>;
  accounts?: Maybe<LoyaltyAccountListRelationFilter>;
  allowOpenInvoices?: Maybe<BoolFilter>;
  allowSpendCarryOver?: Maybe<BoolFilter>;
  centsPerPoint?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  loyaltyAccountHolders?: Maybe<LoyaltyAccountHolderListRelationFilter>;
  minimumTransactionCents?: Maybe<IntFilter>;
  participatingClinics?: Maybe<ClinicListRelationFilter>;
  pointGranters?: Maybe<LoyaltyInvoicePointGranterListRelationFilter>;
  rewards?: Maybe<LoyaltyRewardListRelationFilter>;
  status?: Maybe<EnumLoyaltyProgramStatusFilter>;
  statusHistories?: Maybe<LoyaltyProgramStatusHistoryListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyProgramWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyReward = {
  __typename?: 'LoyaltyReward';
  _count: LoyaltyRewardCountOutputType;
  availability: LoyaltyRewardAvailability;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  /** Notes that are available only to the clinic */
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  program: LoyaltyProgram;
  programId: Scalars['String'];
  redemptions: Array<LoyaltyRewardRedemption>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyRewardRedemptionsArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export enum LoyaltyRewardAvailability {
  Archived = 'Archived',
  Published = 'Published',
  Unpublished = 'Unpublished'
}

export type LoyaltyRewardAvgAggregateOutputType = {
  __typename?: 'LoyaltyRewardAvgAggregateOutputType';
  pointCost?: Maybe<Scalars['Float']>;
};

export type LoyaltyRewardAvgOrderByAggregateInput = {
  pointCost?: Maybe<SortOrder>;
};

export type LoyaltyRewardCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardCountAggregateOutputType';
  _all: Scalars['Int'];
  availability: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  internalNotes: Scalars['Int'];
  pointCost: Scalars['Int'];
  programId: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyRewardCountOrderByAggregateInput = {
  availability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardCountOutputType = {
  __typename?: 'LoyaltyRewardCountOutputType';
  redemptions: Scalars['Int'];
};

export type LoyaltyRewardCreateInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  program: LoyaltyProgramCreateNestedOneWithoutRewardsInput;
  redemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardCreateManyInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  programId: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardCreateManyProgramInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardCreateManyProgramInputEnvelope = {
  data: Array<LoyaltyRewardCreateManyProgramInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
};

export type LoyaltyRewardCreateNestedOneWithoutRedemptionsInput = {
  connect?: Maybe<LoyaltyRewardWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardCreateOrConnectWithoutRedemptionsInput>;
  create?: Maybe<LoyaltyRewardUncheckedCreateWithoutRedemptionsInput>;
};

export type LoyaltyRewardCreateOrConnectWithoutProgramInput = {
  create: LoyaltyRewardUncheckedCreateWithoutProgramInput;
  where: LoyaltyRewardWhereUniqueInput;
};

export type LoyaltyRewardCreateOrConnectWithoutRedemptionsInput = {
  create: LoyaltyRewardUncheckedCreateWithoutRedemptionsInput;
  where: LoyaltyRewardWhereUniqueInput;
};

export type LoyaltyRewardCreateWithoutProgramInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  redemptions?: Maybe<LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardCreateWithoutRedemptionsInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  program: LoyaltyProgramCreateNestedOneWithoutRewardsInput;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardListRelationFilter = {
  every?: Maybe<LoyaltyRewardWhereInput>;
  none?: Maybe<LoyaltyRewardWhereInput>;
  some?: Maybe<LoyaltyRewardWhereInput>;
};

export type LoyaltyRewardMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardMaxAggregateOutputType';
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardMaxOrderByAggregateInput = {
  availability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardMinAggregateOutputType';
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardMinOrderByAggregateInput = {
  availability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  InternalNotes = 'internalNotes',
  ProgramId = 'programId',
  Title = 'title'
}

export type LoyaltyRewardOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyRewardOrderByWithAggregationInput = {
  _avg?: Maybe<LoyaltyRewardAvgOrderByAggregateInput>;
  _count?: Maybe<LoyaltyRewardCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardMinOrderByAggregateInput>;
  _sum?: Maybe<LoyaltyRewardSumOrderByAggregateInput>;
  availability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  programId?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyRewardOrderByRelevanceInput>;
  availability?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNotes?: Maybe<SortOrder>;
  pointCost?: Maybe<SortOrder>;
  program?: Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>;
  programId?: Maybe<SortOrder>;
  redemptions?: Maybe<LoyaltyRewardRedemptionOrderByRelationAggregateInput>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemption = {
  __typename?: 'LoyaltyRewardRedemption';
  _count: LoyaltyRewardRedemptionCountOutputType;
  account: LoyaltyAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  internalNote: Scalars['String'];
  redemptionHistories: Array<LoyaltyRewardRedemptionHistoryEntry>;
  reward: LoyaltyReward;
  rewardId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
  updatedAt: Scalars['DateTime'];
};


export type LoyaltyRewardRedemptionRedemptionHistoriesArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export enum LoyaltyRewardRedemptionActorType {
  ClinicUser = 'ClinicUser',
  PetParent = 'PetParent',
  System = 'System'
}

export type LoyaltyRewardRedemptionCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionCountAggregateOutputType';
  _all: Scalars['Int'];
  accountId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  internalNote: Scalars['Int'];
  rewardId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LoyaltyRewardRedemptionCountOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionCountOutputType = {
  __typename?: 'LoyaltyRewardRedemptionCountOutputType';
  redemptionHistories: Scalars['Int'];
};

export type LoyaltyRewardRedemptionCreateInput = {
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateManyAccountInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionCreateManyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateManyRewardInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateManyRewardInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionCreateManyRewardInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
};

export type LoyaltyRewardRedemptionCreateNestedManyWithoutRewardInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
};

export type LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput = {
  connect?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput>;
  create?: Maybe<LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput>;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateWithoutRedemptionHistoriesInput = {
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  reward: LoyaltyRewardCreateNestedOneWithoutRedemptionsInput;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionCreateWithoutRewardInput = {
  account: LoyaltyAccountCreateNestedOneWithoutRewardRedemptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Used for when a Loyalty Account holder requests a Loyalty Reward */
export type LoyaltyRewardRedemptionHistoryEntry = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntry';
  actingAccountHolder?: Maybe<LoyaltyAccountHolder>;
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUser?: Maybe<User>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pointDelta?: Maybe<LoyaltyPointDelta>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemption: LoyaltyRewardRedemption;
  redemptionId: Scalars['String'];
  relatedClinic?: Maybe<Clinic>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  actingAccountHolderId: Scalars['Int'];
  actingUserId: Scalars['Int'];
  actorType: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  pointDeltaId: Scalars['Int'];
  redemptionId: Scalars['Int'];
  relatedClinicId: Scalars['Int'];
  status: Scalars['Int'];
};

export type LoyaltyRewardRedemptionHistoryEntryCountOrderByAggregateInput = {
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInput = {
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope = {
  data: Array<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingAccountHolderInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRedemptionInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutRelatedClinicInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateNestedOneWithoutPointDeltaInput = {
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput = {
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutPointDeltaInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
  relatedClinic?: Maybe<ClinicCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderCreateNestedOneWithoutRewardRedemptionActionsInput>;
  actingUser?: Maybe<UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaCreateNestedOneWithoutRelatedRedemptionHistoryEntryInput>;
  redemption: LoyaltyRewardRedemptionCreateNestedOneWithoutRedemptionHistoriesInput;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryListRelationFilter = {
  every?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  none?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  some?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryMaxAggregateOutputType';
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryMaxOrderByAggregateInput = {
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionHistoryEntryMinAggregateOutputType';
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryMinOrderByAggregateInput = {
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceFieldEnum {
  ActingAccountHolderId = 'actingAccountHolderId',
  ActingUserId = 'actingUserId',
  Id = 'id',
  PointDeltaId = 'pointDeltaId',
  RedemptionId = 'redemptionId',
  RelatedClinicId = 'relatedClinicId'
}

export type LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyRewardRedemptionHistoryEntryCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardRedemptionHistoryEntryMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardRedemptionHistoryEntryMinOrderByAggregateInput>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUserId?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pointDeltaId?: Maybe<SortOrder>;
  redemptionId?: Maybe<SortOrder>;
  relatedClinicId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelevanceInput>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>;
  actingAccountHolderId?: Maybe<SortOrder>;
  actingUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  actingUserId?: Maybe<SortOrder>;
  actorType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pointDelta?: Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>;
  pointDeltaId?: Maybe<SortOrder>;
  redemption?: Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>;
  redemptionId?: Maybe<SortOrder>;
  relatedClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  relatedClinicId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionHistoryEntryRedemptionIdStatusCompoundUniqueInput = {
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryRelationFilter = {
  is?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
  isNot?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};

export enum LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum {
  ActingAccountHolderId = 'actingAccountHolderId',
  ActingUserId = 'actingUserId',
  ActorType = 'actorType',
  CreatedAt = 'createdAt',
  Id = 'id',
  PointDeltaId = 'pointDeltaId',
  RedemptionId = 'redemptionId',
  RelatedClinicId = 'relatedClinicId',
  Status = 'status'
}

export type LoyaltyRewardRedemptionHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  actingAccountHolderId?: Maybe<StringNullableFilter>;
  actingUserId?: Maybe<StringNullableFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  pointDeltaId?: Maybe<StringNullableFilter>;
  redemptionId?: Maybe<StringFilter>;
  relatedClinicId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
};

export type LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereWithAggregatesInput>>;
  actingAccountHolderId?: Maybe<StringNullableWithAggregatesFilter>;
  actingUserId?: Maybe<StringNullableWithAggregatesFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  pointDeltaId?: Maybe<StringNullableWithAggregatesFilter>;
  redemptionId?: Maybe<StringWithAggregatesFilter>;
  relatedClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingAccountHolderInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRelatedClinicInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedOneWithoutPointDeltaInput = {
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput = {
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType: LoyaltyRewardRedemptionActorType;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId: Scalars['String'];
  status: LoyaltyRewardRedemptionStatus;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingAccountHolderNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionHistoriesInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRelatedClinicNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRewardRedemptionActionsInput = {
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateOneWithoutPointDeltaNestedInput = {
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput = {
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  relatedClinicId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput = {
  actingAccountHolderId?: Maybe<Scalars['String']>;
  actingUserId?: Maybe<Scalars['String']>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyMutationInput = {
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRewardRedemptionActionsInput;
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput;
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionHistoriesInput;
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutLoyaltyRewardRedemptionActionsInput;
  where: LoyaltyRewardRedemptionHistoryEntryScalarWhereInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingAccountHolderNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingAccountHolderInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingAccountHolderInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingAccountHolderInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingAccountHolderInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutActingUserInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutActingUserInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyActingUserInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutActingUserInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRedemptionInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRedemptionInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRedemptionInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRedemptionInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRelatedClinicNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutRelatedClinicInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryCreateWithoutRelatedClinicInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateManyRelatedClinicInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithWhereWithoutRelatedClinicInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput>>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateOneWithoutPointDeltaNestedInput = {
  connect?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateOrConnectWithoutPointDeltaInput>;
  create?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingAccountHolderInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutActingUserInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRedemptionInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithWhereUniqueWithoutRelatedClinicInput = {
  data: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutActingAccountHolderInput = {
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutActingUserInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutPointDeltaInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutRedemptionInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
  relatedClinic?: Maybe<ClinicUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpdateWithoutRelatedClinicInput = {
  actingAccountHolder?: Maybe<LoyaltyAccountHolderUpdateOneWithoutRewardRedemptionActionsNestedInput>;
  actingUser?: Maybe<UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput>;
  actorType?: Maybe<LoyaltyRewardRedemptionActorType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pointDelta?: Maybe<LoyaltyPointDeltaUpdateOneWithoutRelatedRedemptionHistoryEntryNestedInput>;
  redemption?: Maybe<LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingAccountHolderInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingAccountHolderInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingAccountHolderInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutActingUserInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutActingUserInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutActingUserInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRedemptionInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRedemptionInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRedemptionInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithWhereUniqueWithoutRelatedClinicInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutRelatedClinicInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutRelatedClinicInput;
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};

export type LoyaltyRewardRedemptionHistoryEntryUpsertWithoutPointDeltaInput = {
  create: LoyaltyRewardRedemptionHistoryEntryUncheckedCreateWithoutPointDeltaInput;
  update: LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateWithoutPointDeltaInput;
};

export type LoyaltyRewardRedemptionHistoryEntryWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntryWhereInput>>;
  actingAccountHolder?: Maybe<LoyaltyAccountHolderWhereInput>;
  actingAccountHolderId?: Maybe<StringNullableFilter>;
  actingUser?: Maybe<UserWhereInput>;
  actingUserId?: Maybe<StringNullableFilter>;
  actorType?: Maybe<EnumLoyaltyRewardRedemptionActorTypeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  pointDelta?: Maybe<LoyaltyPointDeltaWhereInput>;
  pointDeltaId?: Maybe<StringNullableFilter>;
  redemption?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  redemptionId?: Maybe<StringFilter>;
  relatedClinic?: Maybe<ClinicWhereInput>;
  relatedClinicId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
};

export type LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pointDeltaId?: Maybe<Scalars['String']>;
  redemptionId_status?: Maybe<LoyaltyRewardRedemptionHistoryEntryRedemptionIdStatusCompoundUniqueInput>;
};

export type LoyaltyRewardRedemptionInput = {
  loyaltyRewardRedemptionId?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRedemptionListRelationFilter = {
  every?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  none?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  some?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export type LoyaltyRewardRedemptionMaxAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionMaxAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionMaxOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionMinAggregateOutputType = {
  __typename?: 'LoyaltyRewardRedemptionMinAggregateOutputType';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionMinOrderByAggregateInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum LoyaltyRewardRedemptionOrderByRelevanceFieldEnum {
  AccountId = 'accountId',
  Id = 'id',
  InternalNote = 'internalNote',
  RewardId = 'rewardId'
}

export type LoyaltyRewardRedemptionOrderByRelevanceInput = {
  fields: Array<LoyaltyRewardRedemptionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type LoyaltyRewardRedemptionOrderByWithAggregationInput = {
  _count?: Maybe<LoyaltyRewardRedemptionCountOrderByAggregateInput>;
  _max?: Maybe<LoyaltyRewardRedemptionMaxOrderByAggregateInput>;
  _min?: Maybe<LoyaltyRewardRedemptionMinOrderByAggregateInput>;
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  rewardId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<LoyaltyRewardRedemptionOrderByRelevanceInput>;
  account?: Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>;
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  internalNote?: Maybe<SortOrder>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  reward?: Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>;
  rewardId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LoyaltyRewardRedemptionRelationFilter = {
  is?: Maybe<LoyaltyRewardRedemptionWhereInput>;
  isNot?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};

export enum LoyaltyRewardRedemptionScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  InternalNote = 'internalNote',
  RewardId = 'rewardId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyRewardRedemptionScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  internalNote?: Maybe<StringFilter>;
  rewardId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyRewardRedemptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereWithAggregatesInput>>;
  accountId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  internalNote?: Maybe<StringWithAggregatesFilter>;
  rewardId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum LoyaltyRewardRedemptionStatus {
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  Fulfilled = 'Fulfilled',
  PendingFulfillment = 'PendingFulfillment',
  Rejected = 'Rejected',
  Requested = 'Requested'
}

export type LoyaltyRewardRedemptionSubscription = {
  __typename?: 'LoyaltyRewardRedemptionSubscription';
  mutation: MutationType;
  node: LoyaltyRewardRedemption;
};

export type LoyaltyRewardRedemptionUncheckedCreateInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
  rewardId: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
};

export type LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
  rewardId: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  rewardId: Scalars['String'];
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote: Scalars['String'];
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutRedemptionInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRedemptionsInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput>>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardRedemptionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutRedemptionNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpdateInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput = {
  data: LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardRedemptionsInput;
  where: LoyaltyRewardRedemptionScalarWhereInput;
};

export type LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput = {
  data: LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRedemptionsInput;
  where: LoyaltyRewardRedemptionScalarWhereInput;
};

export type LoyaltyRewardRedemptionUpdateManyWithoutAccountNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutAccountInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardRedemptionCreateOrConnectWithoutRewardInput>>;
  create?: Maybe<Array<LoyaltyRewardRedemptionCreateWithoutRewardInput>>;
  createMany?: Maybe<LoyaltyRewardRedemptionCreateManyRewardInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardRedemptionScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardRedemptionWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardRedemptionUpdateManyWithWhereWithoutRewardInput>>;
  upsert?: Maybe<Array<LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput>>;
};

export type LoyaltyRewardRedemptionUpdateOneRequiredWithoutRedemptionHistoriesNestedInput = {
  connect?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardRedemptionCreateOrConnectWithoutRedemptionHistoriesInput>;
  create?: Maybe<LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput>;
  update?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput>;
  upsert?: Maybe<LoyaltyRewardRedemptionUpsertWithoutRedemptionHistoriesInput>;
};

export type LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutAccountInput = {
  data: LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionUpdateWithWhereUniqueWithoutRewardInput = {
  data: LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionUpdateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpdateWithoutRedemptionHistoriesInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  reward?: Maybe<LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpdateWithoutRewardInput = {
  account?: Maybe<LoyaltyAccountUpdateOneRequiredWithoutRewardRedemptionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutRedemptionNestedInput>;
  status?: Maybe<LoyaltyRewardRedemptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutAccountInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutAccountInput;
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutAccountInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionUpsertWithWhereUniqueWithoutRewardInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRewardInput;
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutRewardInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};

export type LoyaltyRewardRedemptionUpsertWithoutRedemptionHistoriesInput = {
  create: LoyaltyRewardRedemptionUncheckedCreateWithoutRedemptionHistoriesInput;
  update: LoyaltyRewardRedemptionUncheckedUpdateWithoutRedemptionHistoriesInput;
};

export type LoyaltyRewardRedemptionWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardRedemptionWhereInput>>;
  account?: Maybe<LoyaltyAccountWhereInput>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  internalNote?: Maybe<StringFilter>;
  redemptionHistories?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  reward?: Maybe<LoyaltyRewardWhereInput>;
  rewardId?: Maybe<StringFilter>;
  status?: Maybe<EnumLoyaltyRewardRedemptionStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyRewardRedemptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type LoyaltyRewardRelationFilter = {
  is?: Maybe<LoyaltyRewardWhereInput>;
  isNot?: Maybe<LoyaltyRewardWhereInput>;
};

export enum LoyaltyRewardScalarFieldEnum {
  Availability = 'availability',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  InternalNotes = 'internalNotes',
  PointCost = 'pointCost',
  ProgramId = 'programId',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type LoyaltyRewardScalarWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  pointCost?: Maybe<IntFilter>;
  programId?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyRewardScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<LoyaltyRewardScalarWhereWithAggregatesInput>>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  internalNotes?: Maybe<StringNullableWithAggregatesFilter>;
  pointCost?: Maybe<IntWithAggregatesFilter>;
  programId?: Maybe<StringWithAggregatesFilter>;
  title?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type LoyaltyRewardSumAggregateOutputType = {
  __typename?: 'LoyaltyRewardSumAggregateOutputType';
  pointCost?: Maybe<Scalars['Int']>;
};

export type LoyaltyRewardSumOrderByAggregateInput = {
  pointCost?: Maybe<SortOrder>;
};

export type LoyaltyRewardUncheckedCreateInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  programId: Scalars['String'];
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedCreateNestedManyWithoutProgramInput = {
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
};

export type LoyaltyRewardUncheckedCreateWithoutProgramInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedCreateNestedManyWithoutRewardInput>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedCreateWithoutRedemptionsInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost: Scalars['Int'];
  programId: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedUpdateInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedUpdateManyInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyRewardUncheckedUpdateManyWithoutRewardsInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedUpdateWithoutProgramInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUncheckedUpdateManyWithoutRewardNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUpdateInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUpdateManyMutationInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUpdateManyWithWhereWithoutProgramInput = {
  data: LoyaltyRewardUncheckedUpdateManyWithoutRewardsInput;
  where: LoyaltyRewardScalarWhereInput;
};

export type LoyaltyRewardUpdateManyWithoutProgramNestedInput = {
  connect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LoyaltyRewardCreateOrConnectWithoutProgramInput>>;
  create?: Maybe<Array<LoyaltyRewardCreateWithoutProgramInput>>;
  createMany?: Maybe<LoyaltyRewardCreateManyProgramInputEnvelope>;
  delete?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LoyaltyRewardScalarWhereInput>>;
  disconnect?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  set?: Maybe<Array<LoyaltyRewardWhereUniqueInput>>;
  update?: Maybe<Array<LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<LoyaltyRewardUpdateManyWithWhereWithoutProgramInput>>;
  upsert?: Maybe<Array<LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput>>;
};

export type LoyaltyRewardUpdateOneRequiredWithoutRedemptionsNestedInput = {
  connect?: Maybe<LoyaltyRewardWhereUniqueInput>;
  connectOrCreate?: Maybe<LoyaltyRewardCreateOrConnectWithoutRedemptionsInput>;
  create?: Maybe<LoyaltyRewardUncheckedCreateWithoutRedemptionsInput>;
  update?: Maybe<LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput>;
  upsert?: Maybe<LoyaltyRewardUpsertWithoutRedemptionsInput>;
};

export type LoyaltyRewardUpdateWithWhereUniqueWithoutProgramInput = {
  data: LoyaltyRewardUncheckedUpdateWithoutProgramInput;
  where: LoyaltyRewardWhereUniqueInput;
};

export type LoyaltyRewardUpdateWithoutProgramInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  redemptions?: Maybe<LoyaltyRewardRedemptionUpdateManyWithoutRewardNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUpdateWithoutRedemptionsInput = {
  availability?: Maybe<LoyaltyRewardAvailability>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  pointCost?: Maybe<Scalars['Int']>;
  program?: Maybe<LoyaltyProgramUpdateOneRequiredWithoutRewardsNestedInput>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyRewardUpsertWithWhereUniqueWithoutProgramInput = {
  create: LoyaltyRewardUncheckedCreateWithoutProgramInput;
  update: LoyaltyRewardUncheckedUpdateWithoutProgramInput;
  where: LoyaltyRewardWhereUniqueInput;
};

export type LoyaltyRewardUpsertWithoutRedemptionsInput = {
  create: LoyaltyRewardUncheckedCreateWithoutRedemptionsInput;
  update: LoyaltyRewardUncheckedUpdateWithoutRedemptionsInput;
};

export type LoyaltyRewardWhereInput = {
  AND?: Maybe<Array<LoyaltyRewardWhereInput>>;
  NOT?: Maybe<Array<LoyaltyRewardWhereInput>>;
  OR?: Maybe<Array<LoyaltyRewardWhereInput>>;
  availability?: Maybe<EnumLoyaltyRewardAvailabilityFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  internalNotes?: Maybe<StringNullableFilter>;
  pointCost?: Maybe<IntFilter>;
  program?: Maybe<LoyaltyProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  redemptions?: Maybe<LoyaltyRewardRedemptionListRelationFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LoyaltyRewardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum ManageMembershipRequestType {
  Cancel = 'Cancel',
  EditBenefit = 'EditBenefit',
  OptOut = 'OptOut',
  Other = 'Other',
  RemoveBenefit = 'RemoveBenefit',
  Transfer = 'Transfer',
  Upgrade = 'Upgrade'
}

export type MassTextAlert = {
  __typename?: 'MassTextAlert';
  _count: MassTextAlertCountOutputType;
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUser: User;
  createdByUserId: Scalars['String'];
  id: Scalars['String'];
  massTextAlertEntries: Array<MassTextAlertEntry>;
  status: MassTextAlertStatus;
  updatedAt: Scalars['DateTime'];
};


export type MassTextAlertMassTextAlertEntriesArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertEntryScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MassTextAlertCountAggregateOutputType = {
  __typename?: 'MassTextAlertCountAggregateOutputType';
  _all: Scalars['Int'];
  alertBody: Scalars['Int'];
  appointmentDateRangeType: Scalars['Int'];
  appointmentDateTimeFrom: Scalars['Int'];
  appointmentDateTimeTo: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MassTextAlertCountOrderByAggregateInput = {
  alertBody?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertCountOutputType = {
  __typename?: 'MassTextAlertCountOutputType';
  massTextAlertEntries: Scalars['Int'];
};

export type MassTextAlertCreateInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateManyClinicInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateManyClinicInputEnvelope = {
  data: Array<MassTextAlertCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyCreatedByUserInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateManyCreatedByUserInputEnvelope = {
  data: Array<MassTextAlertCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertCreateManyInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
};

export type MassTextAlertCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
};

export type MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput = {
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
};

export type MassTextAlertCreateOrConnectWithoutClinicInput = {
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertCreateOrConnectWithoutCreatedByUserInput = {
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput = {
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertCreateWithoutClinicInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateWithoutCreatedByUserInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertCreateWithoutMassTextAlertEntriesInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinic: ClinicCreateNestedOneWithoutMassTextAlertsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser: UserCreateNestedOneWithoutMassTextAlertsInput;
  id?: Maybe<Scalars['String']>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntry = {
  __typename?: 'MassTextAlertEntry';
  _count: MassTextAlertEntryCountOutputType;
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  massAlertEntryAppointments: Array<MassTextAlertEntryAppointment>;
  massTextAlert: MassTextAlert;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type MassTextAlertEntryMassAlertEntryAppointmentsArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MassTextAlertEntryAppointment = {
  __typename?: 'MassTextAlertEntryAppointment';
  appointment: Appointment;
  appointmentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  massTextAlertEntry: MassTextAlertEntry;
  massTextAlertEntryId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MassTextAlertEntryAppointmentCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  massTextAlertEntryId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MassTextAlertEntryAppointmentCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentCreateInput = {
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateManyInput = {
  appointmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput = {
  appointmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope = {
  data: Array<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
};

export type MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput = {
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput = {
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentCreateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntry: MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput = {
  appointment: AppointmentCreateNestedOneWithoutMassTextAlertEntryAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentListRelationFilter = {
  every?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  none?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
  some?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};

export type MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput = {
  appointmentId: Scalars['String'];
  massTextAlertEntryId: Scalars['String'];
};

export type MassTextAlertEntryAppointmentMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryAppointmentMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  Id = 'id',
  MassTextAlertEntryId = 'massTextAlertEntryId'
}

export type MassTextAlertEntryAppointmentOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryAppointmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MassTextAlertEntryAppointmentOrderByWithAggregationInput = {
  _count?: Maybe<MassTextAlertEntryAppointmentCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryAppointmentMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryAppointmentMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MassTextAlertEntryAppointmentOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>;
  massTextAlertEntryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryAppointmentScalarFieldEnum {
  AppointmentId = 'appointmentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MassTextAlertEntryId = 'massTextAlertEntryId',
  UpdatedAt = 'updatedAt'
}

export type MassTextAlertEntryAppointmentScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  appointmentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  massTextAlertEntryId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateInput = {
  appointmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput = {
  appointmentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput = {
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryAppointmentsInput;
  where: MassTextAlertEntryAppointmentScalarWhereInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput = {
  data: MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassAlertEntryAppointmentsInput;
  where: MassTextAlertEntryAppointmentScalarWhereInput;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutAppointmentInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryAppointmentCreateOrConnectWithoutMassTextAlertEntryInput>>;
  create?: Maybe<Array<MassTextAlertEntryAppointmentCreateWithoutMassTextAlertEntryInput>>;
  createMany?: Maybe<MassTextAlertEntryAppointmentCreateManyMassTextAlertEntryInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryAppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryAppointmentWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryAppointmentUpdateManyWithWhereWithoutMassTextAlertEntryInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput>>;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentUpdateWithWhereUniqueWithoutMassTextAlertEntryInput = {
  data: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentUpdateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpdateWithoutMassTextAlertEntryInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutMassTextAlertEntryAppointmentsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutAppointmentInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutAppointmentInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentUpsertWithWhereUniqueWithoutMassTextAlertEntryInput = {
  create: MassTextAlertEntryAppointmentUncheckedCreateWithoutMassTextAlertEntryInput;
  update: MassTextAlertEntryAppointmentUncheckedUpdateWithoutMassTextAlertEntryInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};

export type MassTextAlertEntryAppointmentWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryAppointmentWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massTextAlertEntry?: Maybe<MassTextAlertEntryWhereInput>;
  massTextAlertEntryId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertEntryAppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  massTextAlertEntryId_appointmentId?: Maybe<MassTextAlertEntryAppointmentMassTextAlertEntryIdAppointmentIdCompoundUniqueInput>;
};

export type MassTextAlertEntryCountAggregateOutputType = {
  __typename?: 'MassTextAlertEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  massTextAlertId: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MassTextAlertEntryCountOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryCountOutputType = {
  __typename?: 'MassTextAlertEntryCountOutputType';
  massAlertEntryAppointments: Scalars['Int'];
};

export type MassTextAlertEntryCreateInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateManyClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateManyClinicPetParentInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateManyInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateManyMassTextAlertInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateManyMassTextAlertInputEnvelope = {
  data: Array<MassTextAlertEntryCreateManyMassTextAlertInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MassTextAlertEntryCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
};

export type MassTextAlertEntryCreateNestedManyWithoutMassTextAlertInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
};

export type MassTextAlertEntryCreateNestedOneWithoutMassAlertEntryAppointmentsInput = {
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateWithoutMassAlertEntryAppointmentsInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlert: MassTextAlertCreateNestedOneWithoutMassTextAlertEntriesInput;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryCreateWithoutMassTextAlertInput = {
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutMassTextAlertEntriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentCreateNestedManyWithoutMassTextAlertEntryInput>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryListRelationFilter = {
  every?: Maybe<MassTextAlertEntryWhereInput>;
  none?: Maybe<MassTextAlertEntryWhereInput>;
  some?: Maybe<MassTextAlertEntryWhereInput>;
};

export type MassTextAlertEntryMaxAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMaxAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryMaxOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryMinAggregateOutputType = {
  __typename?: 'MassTextAlertEntryMinAggregateOutputType';
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryMinOrderByAggregateInput = {
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertEntryOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  MassTextAlertId = 'massTextAlertId',
  PhoneNumber = 'phoneNumber'
}

export type MassTextAlertEntryOrderByRelevanceInput = {
  fields: Array<MassTextAlertEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MassTextAlertEntryOrderByWithAggregationInput = {
  _count?: Maybe<MassTextAlertEntryCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertEntryMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertEntryMinOrderByAggregateInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MassTextAlertEntryOrderByRelevanceInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentOrderByRelationAggregateInput>;
  massTextAlert?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  massTextAlertId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertEntryRelationFilter = {
  is?: Maybe<MassTextAlertEntryWhereInput>;
  isNot?: Maybe<MassTextAlertEntryWhereInput>;
};

export enum MassTextAlertEntryScalarFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MassTextAlertId = 'massTextAlertId',
  PhoneNumber = 'phoneNumber',
  UpdatedAt = 'updatedAt'
}

export type MassTextAlertEntryScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massTextAlertId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertEntryScalarWhereWithAggregatesInput>>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  massTextAlertId?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type MassTextAlertEntryUncheckedCreateInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
};

export type MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
};

export type MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput = {
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedCreateNestedManyWithoutMassTextAlertEntryInput>;
  phoneNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateManyInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUncheckedUpdateManyWithoutMassTextAlertEntryNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpdateInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
  where: MassTextAlertEntryScalarWhereInput;
};

export type MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput = {
  data: MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertEntriesInput;
  where: MassTextAlertEntryScalarWhereInput;
};

export type MassTextAlertEntryUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput = {
  connect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertEntryCreateOrConnectWithoutMassTextAlertInput>>;
  create?: Maybe<Array<MassTextAlertEntryCreateWithoutMassTextAlertInput>>;
  createMany?: Maybe<MassTextAlertEntryCreateManyMassTextAlertInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertEntryWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput>>;
  updateMany?: Maybe<Array<MassTextAlertEntryUpdateManyWithWhereWithoutMassTextAlertInput>>;
  upsert?: Maybe<Array<MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput>>;
};

export type MassTextAlertEntryUpdateOneRequiredWithoutMassAlertEntryAppointmentsNestedInput = {
  connect?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<MassTextAlertEntryCreateOrConnectWithoutMassAlertEntryAppointmentsInput>;
  create?: Maybe<MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput>;
  update?: Maybe<MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput>;
  upsert?: Maybe<MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput>;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryUpdateWithWhereUniqueWithoutMassTextAlertInput = {
  data: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryUpdateWithoutClinicPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpdateWithoutMassAlertEntryAppointmentsInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlert?: Maybe<MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpdateWithoutMassTextAlertInput = {
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentUpdateManyWithoutMassTextAlertEntryNestedInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutClinicPetParentInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutClinicPetParentInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryUpsertWithWhereUniqueWithoutMassTextAlertInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutMassTextAlertInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutMassTextAlertInput;
  where: MassTextAlertEntryWhereUniqueInput;
};

export type MassTextAlertEntryUpsertWithoutMassAlertEntryAppointmentsInput = {
  create: MassTextAlertEntryUncheckedCreateWithoutMassAlertEntryAppointmentsInput;
  update: MassTextAlertEntryUncheckedUpdateWithoutMassAlertEntryAppointmentsInput;
};

export type MassTextAlertEntryWhereInput = {
  AND?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  OR?: Maybe<Array<MassTextAlertEntryWhereInput>>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  massAlertEntryAppointments?: Maybe<MassTextAlertEntryAppointmentListRelationFilter>;
  massTextAlert?: Maybe<MassTextAlertWhereInput>;
  massTextAlertId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MassTextAlertListRelationFilter = {
  every?: Maybe<MassTextAlertWhereInput>;
  none?: Maybe<MassTextAlertWhereInput>;
  some?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertMaxAggregateOutputType = {
  __typename?: 'MassTextAlertMaxAggregateOutputType';
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertMaxOrderByAggregateInput = {
  alertBody?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertMinAggregateOutputType = {
  __typename?: 'MassTextAlertMinAggregateOutputType';
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertMinOrderByAggregateInput = {
  alertBody?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MassTextAlertOrderByRelevanceFieldEnum {
  AlertBody = 'alertBody',
  ClinicId = 'clinicId',
  CreatedByUserId = 'createdByUserId',
  Id = 'id'
}

export type MassTextAlertOrderByRelevanceInput = {
  fields: Array<MassTextAlertOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MassTextAlertOrderByWithAggregationInput = {
  _count?: Maybe<MassTextAlertCountOrderByAggregateInput>;
  _max?: Maybe<MassTextAlertMaxOrderByAggregateInput>;
  _min?: Maybe<MassTextAlertMinOrderByAggregateInput>;
  alertBody?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MassTextAlertOrderByRelevanceInput>;
  alertBody?: Maybe<SortOrder>;
  appointmentDateRangeType?: Maybe<SortOrder>;
  appointmentDateTimeFrom?: Maybe<SortOrder>;
  appointmentDateTimeTo?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdByUserId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MassTextAlertRelationFilter = {
  is?: Maybe<MassTextAlertWhereInput>;
  isNot?: Maybe<MassTextAlertWhereInput>;
};

export type MassTextAlertReportEntry = {
  __typename?: 'MassTextAlertReportEntry';
  errorDetails?: Maybe<Scalars['String']>;
  petParentFullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  smsStatus?: Maybe<SmsNotificationStatusType>;
};

export enum MassTextAlertScalarFieldEnum {
  AlertBody = 'alertBody',
  AppointmentDateRangeType = 'appointmentDateRangeType',
  AppointmentDateTimeFrom = 'appointmentDateTimeFrom',
  AppointmentDateTimeTo = 'appointmentDateTimeTo',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type MassTextAlertScalarWhereInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  alertBody?: Maybe<StringFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MassTextAlertScalarWhereWithAggregatesInput>>;
  alertBody?: Maybe<StringWithAggregatesFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeWithAggregatesFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeWithAggregatesFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumMassTextAlertStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum MassTextAlertStatus {
  Processing = 'Processing',
  Sent = 'Sent'
}

export type MassTextAlertSubscription = {
  __typename?: 'MassTextAlertSubscription';
  mutation: MutationType;
  node: MassTextAlert;
};

export type MassTextAlertUncheckedCreateInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
};

export type MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
};

export type MassTextAlertUncheckedCreateWithoutClinicInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedCreateWithoutCreatedByUserInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedCreateNestedManyWithoutMassTextAlertInput>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput = {
  alertBody: Scalars['String'];
  appointmentDateRangeType: AppointmentDateRangeType;
  appointmentDateTimeFrom: Scalars['DateTime'];
  appointmentDateTimeTo: Scalars['DateTime'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  status: MassTextAlertStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateManyInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateWithoutClinicInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateWithoutCreatedByUserInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUncheckedUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpdateInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpdateManyMutationInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpdateManyWithWhereWithoutClinicInput = {
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
  where: MassTextAlertScalarWhereInput;
};

export type MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: MassTextAlertUncheckedUpdateManyWithoutMassTextAlertsInput;
  where: MassTextAlertScalarWhereInput;
};

export type MassTextAlertUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutClinicInput>>;
  createMany?: Maybe<MassTextAlertCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MassTextAlertUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MassTextAlertCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<MassTextAlertCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<MassTextAlertCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MassTextAlertScalarWhereInput>>;
  disconnect?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  set?: Maybe<Array<MassTextAlertWhereUniqueInput>>;
  update?: Maybe<Array<MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<MassTextAlertUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type MassTextAlertUpdateOneRequiredWithoutMassTextAlertEntriesNestedInput = {
  connect?: Maybe<MassTextAlertWhereUniqueInput>;
  connectOrCreate?: Maybe<MassTextAlertCreateOrConnectWithoutMassTextAlertEntriesInput>;
  create?: Maybe<MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput>;
  update?: Maybe<MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput>;
  upsert?: Maybe<MassTextAlertUpsertWithoutMassTextAlertEntriesInput>;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutClinicInput = {
  data: MassTextAlertUncheckedUpdateWithoutClinicInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertUpdateWithoutClinicInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpdateWithoutCreatedByUserInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryUpdateManyWithoutMassTextAlertNestedInput>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpdateWithoutMassTextAlertEntriesInput = {
  alertBody?: Maybe<Scalars['String']>;
  appointmentDateRangeType?: Maybe<AppointmentDateRangeType>;
  appointmentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  appointmentDateTimeTo?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneRequiredWithoutMassTextAlertsNestedInput>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<MassTextAlertStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutClinicInput = {
  create: MassTextAlertUncheckedCreateWithoutClinicInput;
  update: MassTextAlertUncheckedUpdateWithoutClinicInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: MassTextAlertUncheckedCreateWithoutCreatedByUserInput;
  update: MassTextAlertUncheckedUpdateWithoutCreatedByUserInput;
  where: MassTextAlertWhereUniqueInput;
};

export type MassTextAlertUpsertWithoutMassTextAlertEntriesInput = {
  create: MassTextAlertUncheckedCreateWithoutMassTextAlertEntriesInput;
  update: MassTextAlertUncheckedUpdateWithoutMassTextAlertEntriesInput;
};

export type MassTextAlertWhereInput = {
  AND?: Maybe<Array<MassTextAlertWhereInput>>;
  NOT?: Maybe<Array<MassTextAlertWhereInput>>;
  OR?: Maybe<Array<MassTextAlertWhereInput>>;
  alertBody?: Maybe<StringFilter>;
  appointmentDateRangeType?: Maybe<EnumAppointmentDateRangeTypeFilter>;
  appointmentDateTimeFrom?: Maybe<DateTimeFilter>;
  appointmentDateTimeTo?: Maybe<DateTimeFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  createdByUserId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  massTextAlertEntries?: Maybe<MassTextAlertEntryListRelationFilter>;
  status?: Maybe<EnumMassTextAlertStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MassTextAlertWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  _count: MediaCountOutputType;
  channelMessageAttachments: Array<ChannelMessageAttachment>;
  checksum: Scalars['String'];
  children: Array<Media>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets: Array<ClinicPet>;
  createdAt: Scalars['DateTime'];
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  parent?: Maybe<Media>;
  parentMediaId?: Maybe<Scalars['String']>;
  products: Array<Product>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  users: Array<User>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};


export type MediaChannelMessageAttachmentsArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageAttachmentScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type MediaChildrenArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<MediaScalarFieldEnum>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type MediaClinicPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type MediaProductsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<ProductScalarFieldEnum>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};


export type MediaUsersArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type MediaAvgAggregateOutputType = {
  __typename?: 'MediaAvgAggregateOutputType';
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type MediaAvgOrderByAggregateInput = {
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaCountAggregateOutputType = {
  __typename?: 'MediaCountAggregateOutputType';
  _all: Scalars['Int'];
  checksum: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  durationInSeconds: Scalars['Int'];
  format: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  parentMediaId: Scalars['Int'];
  properties: Scalars['Int'];
  s3Bucket: Scalars['Int'];
  s3Key: Scalars['Int'];
  sizeInMegabytes: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
  version: Scalars['Int'];
  width: Scalars['Int'];
};

export type MediaCountOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  properties?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaCountOutputType = {
  __typename?: 'MediaCountOutputType';
  channelMessageAttachments: Scalars['Int'];
  children: Scalars['Int'];
  clinicPets: Scalars['Int'];
  products: Scalars['Int'];
  users: Scalars['Int'];
};

export type MediaCreateInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateManyClinicInput = {
  checksum: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateManyClinicInputEnvelope = {
  data: Array<MediaCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MediaCreateManyInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateManyParentInput = {
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateManyParentInputEnvelope = {
  data: Array<MediaCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MediaCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
};

export type MediaCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
};

export type MediaCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
};

export type MediaCreateNestedOneWithoutChannelMessageAttachmentsInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChannelMessageAttachmentsInput>;
  create?: Maybe<MediaUncheckedCreateWithoutChannelMessageAttachmentsInput>;
};

export type MediaCreateNestedOneWithoutChildrenInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChildrenInput>;
  create?: Maybe<MediaUncheckedCreateWithoutChildrenInput>;
};

export type MediaCreateNestedOneWithoutClinicPetsInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<MediaUncheckedCreateWithoutClinicPetsInput>;
};

export type MediaCreateNestedOneWithoutUsersInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutUsersInput>;
  create?: Maybe<MediaUncheckedCreateWithoutUsersInput>;
};

export type MediaCreateOrConnectWithoutChannelMessageAttachmentsInput = {
  create: MediaUncheckedCreateWithoutChannelMessageAttachmentsInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutChildrenInput = {
  create: MediaUncheckedCreateWithoutChildrenInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutClinicInput = {
  create: MediaUncheckedCreateWithoutClinicInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutClinicPetsInput = {
  create: MediaUncheckedCreateWithoutClinicPetsInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutParentInput = {
  create: MediaUncheckedCreateWithoutParentInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutProductsInput = {
  create: MediaUncheckedCreateWithoutProductsInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateOrConnectWithoutUsersInput = {
  create: MediaUncheckedCreateWithoutUsersInput;
  where: MediaWhereUniqueInput;
};

export type MediaCreateWithoutChannelMessageAttachmentsInput = {
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutChildrenInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutClinicInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutClinicPetsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutParentInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutProductsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaCreateWithoutUsersInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaCreateNestedManyWithoutParentInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutMediaInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaCreateNestedOneWithoutChildrenInput>;
  products?: Maybe<ProductCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaListRelationFilter = {
  every?: Maybe<MediaWhereInput>;
  none?: Maybe<MediaWhereInput>;
  some?: Maybe<MediaWhereInput>;
};

export type MediaMaxAggregateOutputType = {
  __typename?: 'MediaMaxAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaMaxOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaMinAggregateOutputType = {
  __typename?: 'MediaMinAggregateOutputType';
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaMinOrderByAggregateInput = {
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MediaOrderByRelevanceFieldEnum {
  Checksum = 'checksum',
  ClinicId = 'clinicId',
  Format = 'format',
  Id = 'id',
  ParentMediaId = 'parentMediaId',
  S3Bucket = 's3Bucket',
  S3Key = 's3Key',
  Url = 'url'
}

export type MediaOrderByRelevanceInput = {
  fields: Array<MediaOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MediaOrderByWithAggregationInput = {
  _avg?: Maybe<MediaAvgOrderByAggregateInput>;
  _count?: Maybe<MediaCountOrderByAggregateInput>;
  _max?: Maybe<MediaMaxOrderByAggregateInput>;
  _min?: Maybe<MediaMinOrderByAggregateInput>;
  _sum?: Maybe<MediaSumOrderByAggregateInput>;
  checksum?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parentMediaId?: Maybe<SortOrder>;
  properties?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  version?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MediaOrderByRelevanceInput>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentOrderByRelationAggregateInput>;
  checksum?: Maybe<SortOrder>;
  children?: Maybe<MediaOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  durationInSeconds?: Maybe<SortOrder>;
  format?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  parent?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  parentMediaId?: Maybe<SortOrder>;
  products?: Maybe<ProductOrderByRelationAggregateInput>;
  properties?: Maybe<SortOrder>;
  s3Bucket?: Maybe<SortOrder>;
  s3Key?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  version?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export type MediaRelationFilter = {
  is?: Maybe<MediaWhereInput>;
  isNot?: Maybe<MediaWhereInput>;
};

export enum MediaScalarFieldEnum {
  Checksum = 'checksum',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DurationInSeconds = 'durationInSeconds',
  Format = 'format',
  Height = 'height',
  Id = 'id',
  ParentMediaId = 'parentMediaId',
  Properties = 'properties',
  S3Bucket = 's3Bucket',
  S3Key = 's3Key',
  SizeInMegabytes = 'sizeInMegabytes',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Version = 'version',
  Width = 'width'
}

export type MediaScalarWhereInput = {
  AND?: Maybe<Array<MediaScalarWhereInput>>;
  NOT?: Maybe<Array<MediaScalarWhereInput>>;
  OR?: Maybe<Array<MediaScalarWhereInput>>;
  checksum?: Maybe<StringFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  durationInSeconds?: Maybe<FloatNullableFilter>;
  format?: Maybe<StringFilter>;
  height?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  parentMediaId?: Maybe<StringNullableFilter>;
  properties?: Maybe<JsonNullableFilter>;
  s3Bucket?: Maybe<StringNullableFilter>;
  s3Key?: Maybe<StringNullableFilter>;
  sizeInMegabytes?: Maybe<FloatFilter>;
  type?: Maybe<EnumMediaTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  version?: Maybe<EnumMediaVersionFilter>;
  width?: Maybe<IntNullableFilter>;
};

export type MediaScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MediaScalarWhereWithAggregatesInput>>;
  checksum?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  durationInSeconds?: Maybe<FloatNullableWithAggregatesFilter>;
  format?: Maybe<StringWithAggregatesFilter>;
  height?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  parentMediaId?: Maybe<StringNullableWithAggregatesFilter>;
  properties?: Maybe<JsonNullableWithAggregatesFilter>;
  s3Bucket?: Maybe<StringNullableWithAggregatesFilter>;
  s3Key?: Maybe<StringNullableWithAggregatesFilter>;
  sizeInMegabytes?: Maybe<FloatWithAggregatesFilter>;
  type?: Maybe<EnumMediaTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
  version?: Maybe<EnumMediaVersionWithAggregatesFilter>;
  width?: Maybe<IntNullableWithAggregatesFilter>;
};

export type MediaSumAggregateOutputType = {
  __typename?: 'MediaSumAggregateOutputType';
  durationInSeconds?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaSumOrderByAggregateInput = {
  durationInSeconds?: Maybe<SortOrder>;
  height?: Maybe<SortOrder>;
  sizeInMegabytes?: Maybe<SortOrder>;
  width?: Maybe<SortOrder>;
};

export enum MediaType {
  Audio = 'Audio',
  Image = 'Image',
  Video = 'Video'
}

export type MediaUncheckedCreateInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
};

export type MediaUncheckedCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
};

export type MediaUncheckedCreateNestedManyWithoutProductsInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
};

export type MediaUncheckedCreateWithoutChannelMessageAttachmentsInput = {
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutChildrenInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutClinicInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutClinicPetsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutParentInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutProductsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  users?: Maybe<UserUncheckedCreateNestedManyWithoutMediaInput>;
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedCreateWithoutUsersInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedCreateNestedManyWithoutMediaInput>;
  checksum: Scalars['String'];
  children?: Maybe<MediaUncheckedCreateNestedManyWithoutParentInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutMediaInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedCreateNestedManyWithoutMediaInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes: Scalars['Float'];
  type: MediaType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  version: MediaVersion;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateManyInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateManyWithoutChildrenInput = {
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MediaUncheckedUpdateManyWithoutMediaInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutParentInput>>;
};

export type MediaUncheckedUpdateManyWithoutProductsNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput = {
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutChildrenInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutClinicInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutClinicPetsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutParentInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutProductsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUncheckedUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUncheckedUpdateWithoutUsersInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUncheckedUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUncheckedUpdateManyWithoutParentNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parentMediaId?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUncheckedUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateManyMutationInput = {
  checksum?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateManyWithWhereWithoutClinicInput = {
  data: MediaUncheckedUpdateManyWithoutMediaInput;
  where: MediaScalarWhereInput;
};

export type MediaUpdateManyWithWhereWithoutParentInput = {
  data: MediaUncheckedUpdateManyWithoutChildrenInput;
  where: MediaScalarWhereInput;
};

export type MediaUpdateManyWithWhereWithoutProductsInput = {
  data: MediaUncheckedUpdateManyWithoutMediaInput;
  where: MediaScalarWhereInput;
};

export type MediaUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MediaCreateWithoutClinicInput>>;
  createMany?: Maybe<MediaCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MediaUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<MediaCreateWithoutParentInput>>;
  createMany?: Maybe<MediaCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutParentInput>>;
};

export type MediaUpdateManyWithoutProductsNestedInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MediaCreateOrConnectWithoutProductsInput>>;
  create?: Maybe<Array<MediaCreateWithoutProductsInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type MediaUpdateOneWithoutChannelMessageAttachmentsNestedInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChannelMessageAttachmentsInput>;
  create?: Maybe<MediaUncheckedCreateWithoutChannelMessageAttachmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput>;
  upsert?: Maybe<MediaUpsertWithoutChannelMessageAttachmentsInput>;
};

export type MediaUpdateOneWithoutChildrenNestedInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutChildrenInput>;
  create?: Maybe<MediaUncheckedCreateWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MediaUncheckedUpdateWithoutChildrenInput>;
  upsert?: Maybe<MediaUpsertWithoutChildrenInput>;
};

export type MediaUpdateOneWithoutClinicPetsNestedInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<MediaUncheckedCreateWithoutClinicPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MediaUncheckedUpdateWithoutClinicPetsInput>;
  upsert?: Maybe<MediaUpsertWithoutClinicPetsInput>;
};

export type MediaUpdateOneWithoutUsersNestedInput = {
  connect?: Maybe<MediaWhereUniqueInput>;
  connectOrCreate?: Maybe<MediaCreateOrConnectWithoutUsersInput>;
  create?: Maybe<MediaUncheckedCreateWithoutUsersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MediaUncheckedUpdateWithoutUsersInput>;
  upsert?: Maybe<MediaUpsertWithoutUsersInput>;
};

export type MediaUpdateWithWhereUniqueWithoutClinicInput = {
  data: MediaUncheckedUpdateWithoutClinicInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpdateWithWhereUniqueWithoutParentInput = {
  data: MediaUncheckedUpdateWithoutParentInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpdateWithWhereUniqueWithoutProductsInput = {
  data: MediaUncheckedUpdateWithoutProductsInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpdateWithoutChannelMessageAttachmentsInput = {
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutChildrenInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutClinicInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutClinicPetsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutParentInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutProductsInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  users?: Maybe<UserUpdateManyWithoutMediaNestedInput>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpdateWithoutUsersInput = {
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentUpdateManyWithoutMediaNestedInput>;
  checksum?: Maybe<Scalars['String']>;
  children?: Maybe<MediaUpdateManyWithoutParentNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutMediaNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutMediaNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInSeconds?: Maybe<Scalars['Float']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<MediaUpdateOneWithoutChildrenNestedInput>;
  products?: Maybe<ProductUpdateManyWithoutMediaNestedInput>;
  properties?: Maybe<Scalars['Json']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  sizeInMegabytes?: Maybe<Scalars['Float']>;
  type?: Maybe<MediaType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<MediaVersion>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaUpsertWithWhereUniqueWithoutClinicInput = {
  create: MediaUncheckedCreateWithoutClinicInput;
  update: MediaUncheckedUpdateWithoutClinicInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpsertWithWhereUniqueWithoutParentInput = {
  create: MediaUncheckedCreateWithoutParentInput;
  update: MediaUncheckedUpdateWithoutParentInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpsertWithWhereUniqueWithoutProductsInput = {
  create: MediaUncheckedCreateWithoutProductsInput;
  update: MediaUncheckedUpdateWithoutProductsInput;
  where: MediaWhereUniqueInput;
};

export type MediaUpsertWithoutChannelMessageAttachmentsInput = {
  create: MediaUncheckedCreateWithoutChannelMessageAttachmentsInput;
  update: MediaUncheckedUpdateWithoutChannelMessageAttachmentsInput;
};

export type MediaUpsertWithoutChildrenInput = {
  create: MediaUncheckedCreateWithoutChildrenInput;
  update: MediaUncheckedUpdateWithoutChildrenInput;
};

export type MediaUpsertWithoutClinicPetsInput = {
  create: MediaUncheckedCreateWithoutClinicPetsInput;
  update: MediaUncheckedUpdateWithoutClinicPetsInput;
};

export type MediaUpsertWithoutUsersInput = {
  create: MediaUncheckedCreateWithoutUsersInput;
  update: MediaUncheckedUpdateWithoutUsersInput;
};

export enum MediaVersion {
  Derived = 'Derived',
  Large = 'Large',
  Medium = 'Medium',
  Original = 'Original',
  Small = 'Small',
  Thumbnail = 'Thumbnail'
}

export type MediaWhereInput = {
  AND?: Maybe<Array<MediaWhereInput>>;
  NOT?: Maybe<Array<MediaWhereInput>>;
  OR?: Maybe<Array<MediaWhereInput>>;
  channelMessageAttachments?: Maybe<ChannelMessageAttachmentListRelationFilter>;
  checksum?: Maybe<StringFilter>;
  children?: Maybe<MediaListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  durationInSeconds?: Maybe<FloatNullableFilter>;
  format?: Maybe<StringFilter>;
  height?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  parent?: Maybe<MediaWhereInput>;
  parentMediaId?: Maybe<StringNullableFilter>;
  products?: Maybe<ProductListRelationFilter>;
  properties?: Maybe<JsonNullableFilter>;
  s3Bucket?: Maybe<StringNullableFilter>;
  s3Key?: Maybe<StringNullableFilter>;
  sizeInMegabytes?: Maybe<FloatFilter>;
  type?: Maybe<EnumMediaTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  users?: Maybe<UserListRelationFilter>;
  version?: Maybe<EnumMediaVersionFilter>;
  width?: Maybe<IntNullableFilter>;
};

export type MediaWhereUniqueInput = {
  checksum?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type MergePetParentInput = {
  clinicId: Scalars['String'];
  deleteId: Scalars['String'];
  keepId: Scalars['String'];
  update?: Maybe<ClinicPetParentUpdateInput>;
};

export type MergePetParentResult = {
  __typename?: 'MergePetParentResult';
  messages?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type MessageEventResponse = {
  __typename?: 'MessageEventResponse';
  jobChainId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum MessageSource {
  Sms = 'Sms',
  Web = 'Web'
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  attachments: Scalars['Json'];
  body: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateCountAggregateOutputType = {
  __typename?: 'MessageTemplateCountAggregateOutputType';
  _all: Scalars['Int'];
  attachments: Scalars['Int'];
  body: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isArchived: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
  updatedById: Scalars['Int'];
};

export type MessageTemplateCountOrderByAggregateInput = {
  attachments?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
};

export type MessageTemplateCreateInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCreateNestedOneWithoutMessageTemplatesInput>;
};

export type MessageTemplateCreateManyClinicInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateCreateManyClinicInputEnvelope = {
  data: Array<MessageTemplateCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateManyInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateCreateManyUpdatedByInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateCreateManyUpdatedByInputEnvelope = {
  data: Array<MessageTemplateCreateManyUpdatedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessageTemplateCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
};

export type MessageTemplateCreateNestedManyWithoutUpdatedByInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
};

export type MessageTemplateCreateOrConnectWithoutClinicInput = {
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateCreateOrConnectWithoutUpdatedByInput = {
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateCreateWithoutClinicInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserCreateNestedOneWithoutMessageTemplatesInput>;
};

export type MessageTemplateCreateWithoutUpdatedByInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinic: ClinicCreateNestedOneWithoutMessageTemplatesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateListRelationFilter = {
  every?: Maybe<MessageTemplateWhereInput>;
  none?: Maybe<MessageTemplateWhereInput>;
  some?: Maybe<MessageTemplateWhereInput>;
};

export type MessageTemplateMaxAggregateOutputType = {
  __typename?: 'MessageTemplateMaxAggregateOutputType';
  body?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateMaxOrderByAggregateInput = {
  body?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
};

export type MessageTemplateMinAggregateOutputType = {
  __typename?: 'MessageTemplateMinAggregateOutputType';
  body?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateMinOrderByAggregateInput = {
  body?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
};

export type MessageTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MessageTemplateOrderByRelevanceFieldEnum {
  Body = 'body',
  ClinicId = 'clinicId',
  Id = 'id',
  Name = 'name',
  UpdatedById = 'updatedById'
}

export type MessageTemplateOrderByRelevanceInput = {
  fields: Array<MessageTemplateOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MessageTemplateOrderByWithAggregationInput = {
  _count?: Maybe<MessageTemplateCountOrderByAggregateInput>;
  _max?: Maybe<MessageTemplateMaxOrderByAggregateInput>;
  _min?: Maybe<MessageTemplateMinOrderByAggregateInput>;
  attachments?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedById?: Maybe<SortOrder>;
};

export type MessageTemplateOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MessageTemplateOrderByRelevanceInput>;
  attachments?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isArchived?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  updatedById?: Maybe<SortOrder>;
};

export enum MessageTemplateScalarFieldEnum {
  Attachments = 'attachments',
  Body = 'body',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsArchived = 'isArchived',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedById = 'updatedById'
}

export type MessageTemplateScalarWhereInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  attachments?: Maybe<JsonFilter>;
  body?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isArchived?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedById?: Maybe<StringNullableFilter>;
};

export type MessageTemplateScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessageTemplateScalarWhereWithAggregatesInput>>;
  attachments?: Maybe<JsonWithAggregatesFilter>;
  body?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isArchived?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedById?: Maybe<StringNullableWithAggregatesFilter>;
};

export type MessageTemplateUncheckedCreateInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
};

export type MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
};

export type MessageTemplateUncheckedCreateWithoutClinicInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedCreateWithoutUpdatedByInput = {
  attachments?: Maybe<Scalars['Json']>;
  body: Scalars['String'];
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateUncheckedUpdateInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedUpdateManyInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type MessageTemplateUncheckedUpdateWithoutClinicInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type MessageTemplateUncheckedUpdateWithoutUpdatedByInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateUpdateInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserUpdateOneWithoutMessageTemplatesNestedInput>;
};

export type MessageTemplateUpdateManyMutationInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateUpdateManyWithWhereWithoutClinicInput = {
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
  where: MessageTemplateScalarWhereInput;
};

export type MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput = {
  data: MessageTemplateUncheckedUpdateManyWithoutMessageTemplatesInput;
  where: MessageTemplateScalarWhereInput;
};

export type MessageTemplateUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutClinicInput>>;
  createMany?: Maybe<MessageTemplateCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type MessageTemplateUpdateManyWithoutUpdatedByNestedInput = {
  connect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessageTemplateCreateOrConnectWithoutUpdatedByInput>>;
  create?: Maybe<Array<MessageTemplateCreateWithoutUpdatedByInput>>;
  createMany?: Maybe<MessageTemplateCreateManyUpdatedByInputEnvelope>;
  delete?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessageTemplateScalarWhereInput>>;
  disconnect?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  set?: Maybe<Array<MessageTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput>>;
  updateMany?: Maybe<Array<MessageTemplateUpdateManyWithWhereWithoutUpdatedByInput>>;
  upsert?: Maybe<Array<MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput>>;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutClinicInput = {
  data: MessageTemplateUncheckedUpdateWithoutClinicInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateUpdateWithWhereUniqueWithoutUpdatedByInput = {
  data: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateUpdateWithoutClinicInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<UserUpdateOneWithoutMessageTemplatesNestedInput>;
};

export type MessageTemplateUpdateWithoutUpdatedByInput = {
  attachments?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutMessageTemplatesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutClinicInput = {
  create: MessageTemplateUncheckedCreateWithoutClinicInput;
  update: MessageTemplateUncheckedUpdateWithoutClinicInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateUpsertWithWhereUniqueWithoutUpdatedByInput = {
  create: MessageTemplateUncheckedCreateWithoutUpdatedByInput;
  update: MessageTemplateUncheckedUpdateWithoutUpdatedByInput;
  where: MessageTemplateWhereUniqueInput;
};

export type MessageTemplateWhereInput = {
  AND?: Maybe<Array<MessageTemplateWhereInput>>;
  NOT?: Maybe<Array<MessageTemplateWhereInput>>;
  OR?: Maybe<Array<MessageTemplateWhereInput>>;
  attachments?: Maybe<JsonFilter>;
  body?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isArchived?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedById?: Maybe<StringNullableFilter>;
};

export type MessageTemplateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum MessageType {
  Message = 'Message',
  Note = 'Note'
}

export type MessagingCampaign = {
  __typename?: 'MessagingCampaign';
  _count: MessagingCampaignCountOutputType;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  messagingPartner: MessagingPartner;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt: Scalars['DateTime'];
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings: Array<WorkflowEventSetting>;
};


export type MessagingCampaignWorkflowEventSettingsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type MessagingCampaignCountAggregateOutputType = {
  __typename?: 'MessagingCampaignCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  messagingPartnerId: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowEventSettingTemplate: Scalars['Int'];
};

export type MessagingCampaignCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
};

export type MessagingCampaignCountOutputType = {
  __typename?: 'MessagingCampaignCountOutputType';
  workflowEventSettings: Scalars['Int'];
};

export type MessagingCampaignCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartner: MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput;
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignCreateManyMessagingPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignCreateManyMessagingPartnerInputEnvelope = {
  data: Array<MessagingCampaignCreateManyMessagingPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MessagingCampaignCreateNestedManyWithoutMessagingPartnerInput = {
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
};

export type MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput = {
  connect?: Maybe<MessagingCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput>;
};

export type MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput = {
  create: MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput;
  where: MessagingCampaignWhereUniqueInput;
};

export type MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput = {
  create: MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput;
  where: MessagingCampaignWhereUniqueInput;
};

export type MessagingCampaignCreateWithoutMessagingPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignCreateWithoutWorkflowEventSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartner: MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput;
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignListRelationFilter = {
  every?: Maybe<MessagingCampaignWhereInput>;
  none?: Maybe<MessagingCampaignWhereInput>;
  some?: Maybe<MessagingCampaignWhereInput>;
};

export type MessagingCampaignMaxAggregateOutputType = {
  __typename?: 'MessagingCampaignMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingCampaignMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingCampaignMinAggregateOutputType = {
  __typename?: 'MessagingCampaignMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingCampaignMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingCampaignOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MessagingCampaignOrderByRelevanceFieldEnum {
  Id = 'id',
  MessagingPartnerId = 'messagingPartnerId',
  Name = 'name'
}

export type MessagingCampaignOrderByRelevanceInput = {
  fields: Array<MessagingCampaignOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MessagingCampaignOrderByWithAggregationInput = {
  _count?: Maybe<MessagingCampaignCountOrderByAggregateInput>;
  _max?: Maybe<MessagingCampaignMaxOrderByAggregateInput>;
  _min?: Maybe<MessagingCampaignMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
};

export type MessagingCampaignOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MessagingCampaignOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingPartner?: Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>;
  messagingPartnerId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingTemplate?: Maybe<SortOrder>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type MessagingCampaignRelationFilter = {
  is?: Maybe<MessagingCampaignWhereInput>;
  isNot?: Maybe<MessagingCampaignWhereInput>;
};

export enum MessagingCampaignScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  MessagingPartnerId = 'messagingPartnerId',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  WorkflowEventSettingTemplate = 'workflowEventSettingTemplate'
}

export type MessagingCampaignScalarWhereInput = {
  AND?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  NOT?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  OR?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  messagingPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumMessagingCampaignStatusFilter>;
  type?: Maybe<EnumMessagingCampaignTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableFilter>;
};

export type MessagingCampaignScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessagingCampaignScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  messagingPartnerId?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumMessagingCampaignStatusWithAggregatesFilter>;
  type?: Maybe<EnumMessagingCampaignTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableWithAggregatesFilter>;
};

export enum MessagingCampaignStatus {
  Active = 'Active',
  Paused = 'Paused'
}

export enum MessagingCampaignType {
  Automation = 'Automation'
}

export type MessagingCampaignUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignUncheckedCreateNestedManyWithoutMessagingPartnerInput = {
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
};

export type MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput>;
};

export type MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId: Scalars['String'];
  name: Scalars['String'];
  status: MessagingCampaignStatus;
  type: MessagingCampaignType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateManyWithoutMessagingCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUncheckedUpdateManyWithoutMessagingPartnerNestedInput = {
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  delete?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  set?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  update?: Maybe<Array<MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput>>;
  updateMany?: Maybe<Array<MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput>>;
  upsert?: Maybe<Array<MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput>>;
};

export type MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartnerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartner?: Maybe<MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput = {
  data: MessagingCampaignUncheckedUpdateManyWithoutMessagingCampaignsInput;
  where: MessagingCampaignScalarWhereInput;
};

export type MessagingCampaignUpdateManyWithoutMessagingPartnerNestedInput = {
  connect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MessagingCampaignCreateOrConnectWithoutMessagingPartnerInput>>;
  create?: Maybe<Array<MessagingCampaignCreateWithoutMessagingPartnerInput>>;
  createMany?: Maybe<MessagingCampaignCreateManyMessagingPartnerInputEnvelope>;
  delete?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MessagingCampaignScalarWhereInput>>;
  disconnect?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  set?: Maybe<Array<MessagingCampaignWhereUniqueInput>>;
  update?: Maybe<Array<MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput>>;
  updateMany?: Maybe<Array<MessagingCampaignUpdateManyWithWhereWithoutMessagingPartnerInput>>;
  upsert?: Maybe<Array<MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput>>;
};

export type MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  connect?: Maybe<MessagingCampaignWhereUniqueInput>;
  connectOrCreate?: Maybe<MessagingCampaignCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<MessagingCampaignUpsertWithoutWorkflowEventSettingsInput>;
};

export type MessagingCampaignUpdateWithWhereUniqueWithoutMessagingPartnerInput = {
  data: MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput;
  where: MessagingCampaignWhereUniqueInput;
};

export type MessagingCampaignUpdateWithoutMessagingPartnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput>;
};

export type MessagingCampaignUpdateWithoutWorkflowEventSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingPartner?: Maybe<MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MessagingCampaignStatus>;
  type?: Maybe<MessagingCampaignType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingTemplate?: Maybe<Scalars['Json']>;
};

export type MessagingCampaignUpsertWithWhereUniqueWithoutMessagingPartnerInput = {
  create: MessagingCampaignUncheckedCreateWithoutMessagingPartnerInput;
  update: MessagingCampaignUncheckedUpdateWithoutMessagingPartnerInput;
  where: MessagingCampaignWhereUniqueInput;
};

export type MessagingCampaignUpsertWithoutWorkflowEventSettingsInput = {
  create: MessagingCampaignUncheckedCreateWithoutWorkflowEventSettingsInput;
  update: MessagingCampaignUncheckedUpdateWithoutWorkflowEventSettingsInput;
};

export type MessagingCampaignWhereInput = {
  AND?: Maybe<Array<MessagingCampaignWhereInput>>;
  NOT?: Maybe<Array<MessagingCampaignWhereInput>>;
  OR?: Maybe<Array<MessagingCampaignWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  messagingPartner?: Maybe<MessagingPartnerWhereInput>;
  messagingPartnerId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  status?: Maybe<EnumMessagingCampaignStatusFilter>;
  type?: Maybe<EnumMessagingCampaignTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSettingTemplate?: Maybe<JsonNullableFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type MessagingCampaignWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MessagingPartner = {
  __typename?: 'MessagingPartner';
  _count: MessagingPartnerCountOutputType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  messagingCampaigns: Array<MessagingCampaign>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type MessagingPartnerMessagingCampaignsArgs = {
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  distinct?: Maybe<MessagingCampaignScalarFieldEnum>;
  orderBy?: Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingCampaignWhereInput>;
};

export type MessagingPartnerCountAggregateOutputType = {
  __typename?: 'MessagingPartnerCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MessagingPartnerCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingPartnerCountOutputType = {
  __typename?: 'MessagingPartnerCountOutputType';
  messagingCampaigns: Scalars['Int'];
};

export type MessagingPartnerCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignCreateNestedManyWithoutMessagingPartnerInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerCreateNestedOneWithoutMessagingCampaignsInput = {
  connect?: Maybe<MessagingPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput>;
  create?: Maybe<MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput>;
};

export type MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput = {
  create: MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput;
  where: MessagingPartnerWhereUniqueInput;
};

export type MessagingPartnerCreateWithoutMessagingCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerMaxAggregateOutputType = {
  __typename?: 'MessagingPartnerMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingPartnerMinAggregateOutputType = {
  __typename?: 'MessagingPartnerMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum MessagingPartnerOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type MessagingPartnerOrderByRelevanceInput = {
  fields: Array<MessagingPartnerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type MessagingPartnerOrderByWithAggregationInput = {
  _count?: Maybe<MessagingPartnerCountOrderByAggregateInput>;
  _max?: Maybe<MessagingPartnerMaxOrderByAggregateInput>;
  _min?: Maybe<MessagingPartnerMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingPartnerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<MessagingPartnerOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingCampaigns?: Maybe<MessagingCampaignOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type MessagingPartnerRelationFilter = {
  is?: Maybe<MessagingPartnerWhereInput>;
  isNot?: Maybe<MessagingPartnerWhereInput>;
};

export enum MessagingPartnerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type MessagingPartnerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<MessagingPartnerScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type MessagingPartnerUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignUncheckedCreateNestedManyWithoutMessagingPartnerInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignUncheckedUpdateManyWithoutMessagingPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingCampaigns?: Maybe<MessagingCampaignUpdateManyWithoutMessagingPartnerNestedInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUpdateOneRequiredWithoutMessagingCampaignsNestedInput = {
  connect?: Maybe<MessagingPartnerWhereUniqueInput>;
  connectOrCreate?: Maybe<MessagingPartnerCreateOrConnectWithoutMessagingCampaignsInput>;
  create?: Maybe<MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput>;
  update?: Maybe<MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput>;
  upsert?: Maybe<MessagingPartnerUpsertWithoutMessagingCampaignsInput>;
};

export type MessagingPartnerUpdateWithoutMessagingCampaignsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessagingPartnerUpsertWithoutMessagingCampaignsInput = {
  create: MessagingPartnerUncheckedCreateWithoutMessagingCampaignsInput;
  update: MessagingPartnerUncheckedUpdateWithoutMessagingCampaignsInput;
};

export type MessagingPartnerWhereInput = {
  AND?: Maybe<Array<MessagingPartnerWhereInput>>;
  NOT?: Maybe<Array<MessagingPartnerWhereInput>>;
  OR?: Maybe<Array<MessagingPartnerWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  messagingCampaigns?: Maybe<MessagingCampaignListRelationFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type MessagingPartnerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum MissedCallMessageResponseStatus {
  All = 'All',
  Disabled = 'Disabled',
  PetParentsOnly = 'PetParentsOnly'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvitation2?: Maybe<AcceptUserInvitationResponse>;
  addChannelMember2?: Maybe<ChannelMember>;
  addChannelTag?: Maybe<Channel>;
  addClinicToLoyaltyProgram: OperationResult;
  addEmailCampaignsCareUrlField?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use upsertUserToClinic instead */
  addUserToClinic: User;
  archiveLoyaltyProgram: OperationResult;
  buildSignedLookerUrl: Scalars['String'];
  bulkPublishWorkflowEventSettings: BulkPublishWorkflowEventSettingsResponse;
  cancelCareSubscription?: Maybe<CancelCareSubscriptionResult>;
  cancelClinicSubscription?: Maybe<GenericResponse>;
  cancelLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  cancelStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  changeCareSubscriptionPaymentMethod?: Maybe<ChangeCareSubscriptionPaymentMethodOutput>;
  channelBulkExportToPims?: Maybe<GenericResponse>;
  channelExportToPims?: Maybe<GenericResponse>;
  claimClinicPetParentAuthCode?: Maybe<ClaimClinicPetParentAuthCodeResponse>;
  clearTerminalDisplay?: Maybe<ClearTerminalDisplayResponse>;
  clearUnreadMessages2: GenericResponse;
  configureEmailCampaignsForClinic?: Maybe<Scalars['String']>;
  confirmAppointment2: Appointment;
  connectStripeTerminal?: Maybe<StripeTerminal>;
  copyOneWorkflowEventSetting: WorkflowEventSetting;
  createCareEnrollmentsAndSubscriptions: CreateCareEnrollmentAndSubscriptionResult;
  createCarePaymentMethodSetup: CreatePaymentMethodSetupInputResult;
  createChannelFilterSelection: ChannelFilterSelection;
  createChannelMessage2: ChannelMessage;
  createClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  createClinicWidgetRequest2: ClinicWidgetRequest;
  createDirectBookingAppointment?: Maybe<DirectBookingAppointmentResponse>;
  createEmailTemplateVersion: EmailTemplateVersion;
  createImpersonation: TokenOutput;
  createLoyaltyProgram: LoyaltyProgram;
  createOneActiveIngredient: ActiveIngredient;
  createOneAppointment: Appointment;
  createOneAppointmentAvailability: AppointmentAvailability;
  createOneAppointmentType: AppointmentType;
  createOneAutomationRun: AutomationRun;
  createOneAutomationRunAction: AutomationRunAction;
  createOneBitwerxHealthcheck: BitwerxHealthcheck;
  createOneBitwerxIntegration: BitwerxIntegration;
  createOneBitwerxPaymentHealthcheckResult: BitwerxPaymentHealthcheckResult;
  createOneBitwerxPaymentHealthcheckRun: BitwerxPaymentHealthcheckRun;
  createOneBoardFilterColumn: BoardFilterColumn;
  createOneBoardFilterSpecificDateRange: BoardFilterSpecificDateRange;
  createOneBoehringerIngelheimCustomerAlignment: BoehringerIngelheimCustomerAlignment;
  createOneBoehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  createOneBreed: Breed;
  createOneCallHistory: CallHistory;
  createOneCallParticipant: CallParticipant;
  createOneCallRecording?: Maybe<CallRecording>;
  createOneCampaignRegistryCampaign: CampaignRegistryCampaign;
  createOneCanonicalBreed: CanonicalBreed;
  createOneCanonicalCanineBreedProfile: CanonicalCanineBreedProfile;
  createOneCanonicalService: CanonicalService;
  createOneCanonicalSpecies: CanonicalSpecies;
  createOneCareAddonEnrollment: CareAddonEnrollment;
  createOneCareAddonPackage: CareAddonPackage;
  createOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  createOneCareAuthorizationRequest: CareAuthorizationRequest;
  createOneCareBenefit: CareBenefit;
  createOneCareBenefitUsage: CareBenefitUsage;
  createOneCareBenefitUsageFollowup: CareBenefitUsageFollowup;
  createOneCareBenefitUsageFollowupStep: CareBenefitUsageFollowupStep;
  createOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  createOneCareEnrollmentPayment: CareEnrollmentPayment;
  createOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  createOneCareLapsedPayment: CareLapsedPayment;
  createOneCarePlan: CarePlan;
  createOneCarePlanBenefit: CarePlanBenefit;
  createOneCarePlanBenefitEnrollmentCarryover: CarePlanBenefitEnrollmentCarryover;
  createOneCarePlanEnrollment: CarePlanEnrollment;
  createOneCarePlanProviderGroup: CarePlanProviderGroup;
  createOneCareStripeSubscription: CareStripeSubscription;
  createOneCareSubscriptionTrueUp: CareSubscriptionTrueUp;
  createOneCareSubscriptionTrueUpLogEntry: CareSubscriptionTrueUpLogEntry;
  createOneChannel: Channel;
  createOneChannelAutomationStatus: ChannelAutomationStatus;
  createOneChannelCreationSource: ChannelCreationSource;
  createOneChannelFilterSelection: ChannelFilterSelection;
  createOneChannelMember: ChannelMember;
  createOneChannelMessage: ChannelMessage;
  createOneChannelMessageAttachment: ChannelMessageAttachment;
  createOneChannelMessageAutoResponse: ChannelMessageAutoResponse;
  createOneChannelMessageReaction: ChannelMessageReaction;
  createOneChannelPin: ChannelPin;
  createOneChannelSelection: ChannelSelection;
  createOneChannelStatus: ChannelStatus;
  createOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  createOneChemicalCompound: ChemicalCompound;
  createOneClientConnectIntegration: ClientConnectIntegration;
  createOneClinic: Clinic;
  createOneClinicBlockedPhoneNumber: ClinicBlockedPhoneNumber;
  createOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  createOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  createOneClinicEmployee: ClinicEmployee;
  createOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  createOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  createOneClinicOfficeHour: ClinicOfficeHour;
  createOneClinicOnboarding: ClinicOnboarding;
  createOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  createOneClinicPet: ClinicPet;
  createOneClinicPetAlert: ClinicPetAlert;
  createOneClinicPetCustomFieldValue: ClinicPetCustomFieldValue;
  createOneClinicPetParent: ClinicPetParent;
  createOneClinicPetParentAddress: ClinicPetParentAddress;
  createOneClinicPetParentCustomFieldValue: ClinicPetParentCustomFieldValue;
  createOneClinicPetParentDevice: ClinicPetParentDevice;
  createOneClinicPetParentPimsSyncStatus: ClinicPetParentPimsSyncStatus;
  createOneClinicPetPimsSyncStatus: ClinicPetPimsSyncStatus;
  createOneClinicPhoneNumber: ClinicPhoneNumber;
  createOneClinicPimsIntegration: ClinicPimsIntegration;
  createOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  createOneClinicPimsIntegrationCapabilityHistoryEntry: ClinicPimsIntegrationCapabilityHistoryEntry;
  createOneClinicPostcardSetting: ClinicPostcardSetting;
  createOneClinicRoom: ClinicRoom;
  createOneClinicSetting: ClinicSetting;
  createOneClinicWidgetRequest: ClinicWidgetRequest;
  createOneClinicWidgetRequestType: ClinicWidgetRequestType;
  createOneClinicWidgetSetting: ClinicWidgetSetting;
  createOneColor: Color;
  createOneCondition: Condition;
  createOneConditionSet: ConditionSet;
  createOneConditionalEntityEvaluation: ConditionalEntityEvaluation;
  createOneConsultationRequest: ConsultationRequest;
  createOneCustomFieldDefinition: CustomFieldDefinition;
  createOneCustomFieldPimsWritebackLogEntry: CustomFieldPimsWritebackLogEntry;
  createOneDirectBookingAppointmentTypeSetting: DirectBookingAppointmentTypeSetting;
  createOneDirectBookingExclusionRule: DirectBookingExclusionRule;
  createOneDirectBookingExclusionTimeSlot: DirectBookingExclusionTimeSlot;
  createOneDisbursement: Disbursement;
  createOneDocumentation: Documentation;
  createOneEmailCampaign: EmailCampaign;
  createOneEmailCampaignCustomList: EmailCampaignCustomList;
  createOneEmailCampaignCustomListRecipient: EmailCampaignCustomListRecipient;
  createOneEmailCampaignCustomListUploadHistory: EmailCampaignCustomListUploadHistory;
  createOneEmailCampaignLog: EmailCampaignLog;
  createOneEmailCampaignTemplate: EmailCampaignTemplate;
  createOneEmailCampaignUnsubscribe: EmailCampaignUnsubscribe;
  createOneEmailProviderLog: EmailProviderLog;
  createOneEmergencyClinics: EmergencyClinics;
  createOneEnrollmentSupportEvent: EnrollmentSupportEvent;
  createOneEzyVetIntegration: EzyVetIntegration;
  createOneFeatureFlag: FeatureFlag;
  createOneFinancialAdjustment: FinancialAdjustment;
  createOneFinancialTransaction: FinancialTransaction;
  createOneFormSubmission: FormSubmission;
  createOneFormTemplate: FormTemplate;
  createOneGlobalPetParent: GlobalPetParent;
  createOneHillsToHomeAPILog: HillsToHomeApiLog;
  createOneInformAudienceDefinition: InformAudienceDefinition;
  createOneInformAutomationCampaign: InformAutomationCampaign;
  createOneInformCampaign: InformCampaign;
  createOneInformCampaignEnrollment: InformCampaignEnrollment;
  createOneInformCampaignEnrollmentCanonicalService: InformCampaignEnrollmentCanonicalService;
  createOneInformCampaignExecution: InformCampaignExecution;
  createOneInformCampaignGroup: InformCampaignGroup;
  createOneInformControlGroupParticipant: InformControlGroupParticipant;
  createOneInformEmailTemplate: InformEmailTemplate;
  createOneInformEntityHistory: InformEntityHistory;
  createOneInformPartner: InformPartner;
  createOneInformPartnerCustomer: InformPartnerCustomer;
  createOneInformPartnerCustomerPharmacyProvider: InformPartnerCustomerPharmacyProvider;
  createOneInformPartnerIntegration: InformPartnerIntegration;
  createOneInformPartnerIntegrationModel: InformPartnerIntegrationModel;
  createOneInformPartnerProgram: InformPartnerProgram;
  createOneInformPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  createOneInformPartnerUser: InformPartnerUser;
  createOneInformPartnerUserRole: InformPartnerUserRole;
  createOneInformScheduledCampaign: InformScheduledCampaign;
  createOneInstinctIntegration: InstinctIntegration;
  createOneInsuranceIntegration: InsuranceIntegration;
  createOneIntegrationOutageHistoryEntry: IntegrationOutageHistoryEntry;
  createOneInterchangeRate: InterchangeRate;
  createOneInvoice: Invoice;
  createOneInvoiceLineItem: InvoiceLineItem;
  createOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  createOneLapsedPetParentTriggers: LapsedPetParentTriggers;
  createOneLegalEntity: LegalEntity;
  createOneLoyaltyAccount: LoyaltyAccount;
  createOneLoyaltyAccountHolder: LoyaltyAccountHolder;
  createOneLoyaltyAccountMerger: LoyaltyAccountMerger;
  createOneLoyaltyInvoicePointGranter: LoyaltyInvoicePointGranter;
  createOneLoyaltyPointGrantingService: LoyaltyPointGrantingService;
  createOneLoyaltyPointGrantingServiceCategory: LoyaltyPointGrantingServiceCategory;
  createOneLoyaltyPointUserGrant: LoyaltyPointUserGrant;
  createOneLoyaltyReward: LoyaltyReward;
  createOneLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  createOneMassTextAlert: MassTextAlert;
  createOneMassTextAlertEntry: MassTextAlertEntry;
  createOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  createOneMedia: Media;
  createOneMessageTemplate: MessageTemplate;
  createOneMessagingCampaign: MessagingCampaign;
  createOneMessagingPartner: MessagingPartner;
  createOneNonPrismaSchemaScriptRan: NonPrismaSchemaScriptRan;
  createOneNotificationEventToMessage: NotificationEventToMessage;
  createOneOrganization: Organization;
  createOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  createOneOrganizationCareBenefitToClinicServiceCategory: OrganizationCareBenefitToClinicServiceCategory;
  createOneOrganizationPet: OrganizationPet;
  createOneOrganizationPetParent: OrganizationPetParent;
  createOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  createOnePPCIntegration: PpcIntegration;
  createOnePayoutBatchingPeriod: PayoutBatchingPeriod;
  createOnePermission: Permission;
  createOnePetParentSetting: PetParentSetting;
  createOnePetPortalSetting: PetPortalSetting;
  createOnePhoneNumberLookupRequest: PhoneNumberLookupRequest;
  createOnePhoneNumberLookupResult: PhoneNumberLookupResult;
  createOnePimsInvoice: PimsInvoice;
  createOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  createOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  createOnePrescription: Prescription;
  createOneProduct: Product;
  createOneProviderAppointmentTypeRoomMap: ProviderAppointmentTypeRoomMap;
  createOneProviderAppointmentTypeRoomMapByName: DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameResponse;
  createOnePushNotificationLog: PushNotificationLog;
  createOneRules: Rules;
  createOneSegment: Segment;
  createOneSentTrupanionExamDayOffer: SentTrupanionExamDayOffer;
  createOneService: Service;
  createOneServiceCategory: ServiceCategory;
  createOneServiceReminder: ServiceReminder;
  createOneServiceReminderNotification: ServiceReminderNotification;
  createOneServiceReminderTiming: ServiceReminderTiming;
  createOneSex: Sex;
  createOneSmsNotificationStatus: SmsNotificationStatus;
  createOneSpecies: Species;
  createOneStaffRole: StaffRole;
  createOneStripeCustomer: StripeCustomer;
  createOneStripeDispute: StripeDispute;
  createOneStripeInvoice: StripeInvoice;
  createOneStripeInvoiceItem: StripeInvoiceItem;
  createOneStripePaymentIntent: StripePaymentIntent;
  createOneStripePaymentMethod: StripePaymentMethod;
  createOneStripePayout: StripePayout;
  createOneStripePayoutAccount: StripePayoutAccount;
  createOneStripeReceipt: StripeReceipt;
  createOneStripeReceiptHistory: StripeReceiptHistory;
  createOneStripeRefund: StripeRefund;
  createOneStripeTerminal: StripeTerminal;
  createOneStripeTerminalLocation: StripeTerminalLocation;
  createOneSurveyAppointmentTypeSetting: SurveyAppointmentTypeSetting;
  createOneSurveyResult: SurveyResult;
  createOneSurveySetting: SurveySetting;
  createOneSyncVetIntegration: SyncVetIntegration;
  createOneTransaction: Transaction;
  createOneTrupanionCertificateAvailability: TrupanionCertificateAvailability;
  createOneTrupanionIntegratedAppointmentType: TrupanionIntegratedAppointmentType;
  createOneTrupanionIntegration: TrupanionIntegration;
  createOneUser: User;
  createOneUserAppointmentGrouping: UserAppointmentGrouping;
  createOneUserBoardFilter: UserBoardFilter;
  createOneUserChannelFilterSelection: UserChannelFilterSelection;
  createOneUserNotificationSetting: UserNotificationSetting;
  createOneUserSetting: UserSetting;
  createOneVaccination: Vaccination;
  createOneVetAvailability: VetAvailability;
  createOneVetAvailabilityClinicRoomMap: VetAvailabilityClinicRoomMap;
  createOneVetAvailabilityMap: VetAvailabilityMap;
  createOneVetInfo: VetInfo;
  createOneVetsourceCodeTag: VetsourceCodeTag;
  createOneVetsourceInstallation: VetsourceInstallation;
  createOneVetsourceRevenueCategory: VetsourceRevenueCategory;
  createOneWorkflowEventSetting: WorkflowEventSetting;
  /** @deprecated use createCarePaymentMethodSetup */
  createPaymentMethodSetup: CreatePaymentMethodSetupInputResult;
  createStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  createStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  createSystemChannelMessage2?: Maybe<ChannelMessage>;
  createTeamChannel2?: Maybe<Channel>;
  createTerminalAppPaymentIntent?: Maybe<TerminalAppPaymentResponse>;
  createTwilioToken: CreateTwilioTokenResponse;
  createWorkflowEventSettingAndConditions: WorkflowEventSetting;
  customUpsertOneUserBoardFilter?: Maybe<CustomUpsertOneUserBoardFilterResponse>;
  deactivateLoyaltyAccount: LoyaltyAccount;
  deleteChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  deleteEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  deleteManyActiveIngredient?: Maybe<BatchPayload>;
  deleteManyAppointment?: Maybe<BatchPayload>;
  deleteManyAppointmentAvailability?: Maybe<BatchPayload>;
  deleteManyAppointmentType?: Maybe<BatchPayload>;
  deleteManyAutomationRun?: Maybe<BatchPayload>;
  deleteManyAutomationRunAction?: Maybe<BatchPayload>;
  deleteManyBitwerxHealthcheck?: Maybe<BatchPayload>;
  deleteManyBitwerxIntegration?: Maybe<BatchPayload>;
  deleteManyBitwerxPaymentHealthcheckResult?: Maybe<BatchPayload>;
  deleteManyBitwerxPaymentHealthcheckRun?: Maybe<BatchPayload>;
  deleteManyBoardFilterColumn?: Maybe<BatchPayload>;
  deleteManyBoardFilterSpecificDateRange?: Maybe<BatchPayload>;
  deleteManyBoehringerIngelheimCustomerAlignment?: Maybe<BatchPayload>;
  deleteManyBoehringerIngelheimTerritory?: Maybe<BatchPayload>;
  deleteManyBreed?: Maybe<BatchPayload>;
  deleteManyCallHistory?: Maybe<BatchPayload>;
  deleteManyCallParticipant?: Maybe<BatchPayload>;
  deleteManyCampaignRegistryCampaign?: Maybe<BatchPayload>;
  deleteManyCanonicalBreed?: Maybe<BatchPayload>;
  deleteManyCanonicalCanineBreedProfile?: Maybe<BatchPayload>;
  deleteManyCanonicalService?: Maybe<BatchPayload>;
  deleteManyCanonicalSpecies?: Maybe<BatchPayload>;
  deleteManyCareAddonEnrollment?: Maybe<BatchPayload>;
  deleteManyCareAddonPackage?: Maybe<BatchPayload>;
  deleteManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  deleteManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  deleteManyCareBenefit?: Maybe<BatchPayload>;
  deleteManyCareBenefitUsage?: Maybe<BatchPayload>;
  deleteManyCareBenefitUsageFollowup?: Maybe<BatchPayload>;
  deleteManyCareBenefitUsageFollowupStep?: Maybe<BatchPayload>;
  deleteManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  deleteManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  deleteManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  deleteManyCareLapsedPayment?: Maybe<BatchPayload>;
  deleteManyCarePlan?: Maybe<BatchPayload>;
  deleteManyCarePlanBenefit?: Maybe<BatchPayload>;
  deleteManyCarePlanBenefitEnrollmentCarryover?: Maybe<BatchPayload>;
  deleteManyCarePlanEnrollment?: Maybe<BatchPayload>;
  deleteManyCarePlanProviderGroup?: Maybe<BatchPayload>;
  deleteManyCareStripeSubscription?: Maybe<BatchPayload>;
  deleteManyCareSubscriptionTrueUp?: Maybe<BatchPayload>;
  deleteManyCareSubscriptionTrueUpLogEntry?: Maybe<BatchPayload>;
  deleteManyChannel?: Maybe<BatchPayload>;
  deleteManyChannelAutomationStatus?: Maybe<BatchPayload>;
  deleteManyChannelCreationSource?: Maybe<BatchPayload>;
  deleteManyChannelFilterSelection?: Maybe<BatchPayload>;
  deleteManyChannelMember?: Maybe<BatchPayload>;
  deleteManyChannelMessage?: Maybe<BatchPayload>;
  deleteManyChannelMessageAttachment?: Maybe<BatchPayload>;
  deleteManyChannelMessageAutoResponse?: Maybe<BatchPayload>;
  deleteManyChannelMessageReaction?: Maybe<BatchPayload>;
  deleteManyChannelPin?: Maybe<BatchPayload>;
  deleteManyChannelSelection?: Maybe<BatchPayload>;
  deleteManyChannelStatus?: Maybe<BatchPayload>;
  deleteManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  deleteManyChemicalCompound?: Maybe<BatchPayload>;
  deleteManyClientConnectIntegration?: Maybe<BatchPayload>;
  deleteManyClinic?: Maybe<BatchPayload>;
  deleteManyClinicBlockedPhoneNumber?: Maybe<BatchPayload>;
  deleteManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  deleteManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  deleteManyClinicEmployee?: Maybe<BatchPayload>;
  deleteManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  deleteManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  deleteManyClinicOfficeHour?: Maybe<BatchPayload>;
  deleteManyClinicOnboarding?: Maybe<BatchPayload>;
  deleteManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  deleteManyClinicPet?: Maybe<BatchPayload>;
  deleteManyClinicPetAlert?: Maybe<BatchPayload>;
  deleteManyClinicPetCustomFieldValue?: Maybe<BatchPayload>;
  deleteManyClinicPetParent?: Maybe<BatchPayload>;
  deleteManyClinicPetParentAddress?: Maybe<BatchPayload>;
  deleteManyClinicPetParentCustomFieldValue?: Maybe<BatchPayload>;
  deleteManyClinicPetParentDevice?: Maybe<BatchPayload>;
  deleteManyClinicPetParentPimsSyncStatus?: Maybe<BatchPayload>;
  deleteManyClinicPetPimsSyncStatus?: Maybe<BatchPayload>;
  deleteManyClinicPhoneNumber?: Maybe<BatchPayload>;
  deleteManyClinicPimsIntegration?: Maybe<BatchPayload>;
  deleteManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  deleteManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<BatchPayload>;
  deleteManyClinicPostcardSetting?: Maybe<BatchPayload>;
  deleteManyClinicRoom?: Maybe<BatchPayload>;
  deleteManyClinicSetting?: Maybe<BatchPayload>;
  deleteManyClinicWidgetRequest?: Maybe<BatchPayload>;
  deleteManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  deleteManyClinicWidgetSetting?: Maybe<BatchPayload>;
  deleteManyColor?: Maybe<BatchPayload>;
  deleteManyCondition?: Maybe<BatchPayload>;
  deleteManyConditionSet?: Maybe<BatchPayload>;
  deleteManyConditionalEntityEvaluation?: Maybe<BatchPayload>;
  deleteManyConsultationRequest?: Maybe<BatchPayload>;
  deleteManyCustomFieldDefinition?: Maybe<BatchPayload>;
  deleteManyCustomFieldPimsWritebackLogEntry?: Maybe<BatchPayload>;
  deleteManyDirectBookingAppointmentTypeSetting?: Maybe<BatchPayload>;
  deleteManyDirectBookingExclusionTimeSlot?: Maybe<BatchPayload>;
  deleteManyDisbursement?: Maybe<BatchPayload>;
  deleteManyDocumentation?: Maybe<BatchPayload>;
  deleteManyEmailCampaign?: Maybe<BatchPayload>;
  deleteManyEmailCampaignCustomList?: Maybe<BatchPayload>;
  deleteManyEmailCampaignCustomListRecipient?: Maybe<BatchPayload>;
  deleteManyEmailCampaignCustomListUploadHistory?: Maybe<BatchPayload>;
  deleteManyEmailCampaignLog?: Maybe<BatchPayload>;
  deleteManyEmailCampaignTemplate?: Maybe<BatchPayload>;
  deleteManyEmailCampaignUnsubscribe?: Maybe<BatchPayload>;
  deleteManyEmailProviderLog?: Maybe<BatchPayload>;
  deleteManyEmergencyClinics?: Maybe<BatchPayload>;
  deleteManyEnrollmentSupportEvent?: Maybe<BatchPayload>;
  deleteManyEzyVetIntegration?: Maybe<BatchPayload>;
  deleteManyFeatureFlag?: Maybe<BatchPayload>;
  deleteManyFinancialAdjustment?: Maybe<BatchPayload>;
  deleteManyFinancialTransaction?: Maybe<BatchPayload>;
  deleteManyFormSubmission?: Maybe<BatchPayload>;
  deleteManyFormTemplate?: Maybe<BatchPayload>;
  deleteManyGlobalPetParent?: Maybe<BatchPayload>;
  deleteManyHillsToHomeAPILog?: Maybe<BatchPayload>;
  deleteManyInformAudienceDefinition?: Maybe<BatchPayload>;
  deleteManyInformAutomationCampaign?: Maybe<BatchPayload>;
  deleteManyInformCampaign?: Maybe<BatchPayload>;
  deleteManyInformCampaignEnrollment?: Maybe<BatchPayload>;
  deleteManyInformCampaignEnrollmentCanonicalService?: Maybe<BatchPayload>;
  deleteManyInformCampaignExecution?: Maybe<BatchPayload>;
  deleteManyInformCampaignGroup?: Maybe<BatchPayload>;
  deleteManyInformControlGroupParticipant?: Maybe<BatchPayload>;
  deleteManyInformEmailTemplate?: Maybe<BatchPayload>;
  deleteManyInformEntityHistory?: Maybe<BatchPayload>;
  deleteManyInformPartner?: Maybe<BatchPayload>;
  deleteManyInformPartnerCustomer?: Maybe<BatchPayload>;
  deleteManyInformPartnerCustomerPharmacyProvider?: Maybe<BatchPayload>;
  deleteManyInformPartnerIntegration?: Maybe<BatchPayload>;
  deleteManyInformPartnerIntegrationModel?: Maybe<BatchPayload>;
  deleteManyInformPartnerProgram?: Maybe<BatchPayload>;
  deleteManyInformPartnerProgramEnrollment?: Maybe<BatchPayload>;
  deleteManyInformPartnerUser?: Maybe<BatchPayload>;
  deleteManyInformPartnerUserRole?: Maybe<BatchPayload>;
  deleteManyInformScheduledCampaign?: Maybe<BatchPayload>;
  deleteManyInstinctIntegration?: Maybe<BatchPayload>;
  deleteManyInsuranceIntegration?: Maybe<BatchPayload>;
  deleteManyIntegrationOutageHistoryEntry?: Maybe<BatchPayload>;
  deleteManyInterchangeRate?: Maybe<BatchPayload>;
  deleteManyInvoice?: Maybe<BatchPayload>;
  deleteManyInvoiceLineItem?: Maybe<BatchPayload>;
  deleteManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  deleteManyLapsedPetParentTriggers?: Maybe<BatchPayload>;
  deleteManyLoyaltyAccount?: Maybe<BatchPayload>;
  deleteManyLoyaltyAccountHolder?: Maybe<BatchPayload>;
  deleteManyLoyaltyAccountMerger?: Maybe<BatchPayload>;
  deleteManyLoyaltyInvoicePointGranter?: Maybe<BatchPayload>;
  deleteManyLoyaltyPointGrantingService?: Maybe<BatchPayload>;
  deleteManyLoyaltyPointGrantingServiceCategory?: Maybe<BatchPayload>;
  deleteManyLoyaltyPointUserGrant?: Maybe<BatchPayload>;
  deleteManyLoyaltyRewardRedemption?: Maybe<BatchPayload>;
  deleteManyMassTextAlert?: Maybe<BatchPayload>;
  deleteManyMassTextAlertEntry?: Maybe<BatchPayload>;
  deleteManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  deleteManyMedia?: Maybe<BatchPayload>;
  deleteManyMessageTemplate?: Maybe<BatchPayload>;
  deleteManyMessagingCampaign?: Maybe<BatchPayload>;
  deleteManyMessagingPartner?: Maybe<BatchPayload>;
  deleteManyNonPrismaSchemaScriptRan?: Maybe<BatchPayload>;
  deleteManyNotificationEventToMessage?: Maybe<BatchPayload>;
  deleteManyOrganization?: Maybe<BatchPayload>;
  deleteManyOrganizationCareBenefitToClinicService?: Maybe<BatchPayload>;
  deleteManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<BatchPayload>;
  deleteManyOrganizationPet?: Maybe<BatchPayload>;
  deleteManyOrganizationPetParent?: Maybe<BatchPayload>;
  deleteManyOrganizationPetToOrganizationPetParent?: Maybe<BatchPayload>;
  deleteManyPPCIntegration?: Maybe<BatchPayload>;
  deleteManyPermission?: Maybe<BatchPayload>;
  deleteManyPetParentSetting?: Maybe<BatchPayload>;
  deleteManyPetPortalSetting?: Maybe<BatchPayload>;
  deleteManyPhoneNumberLookupRequest?: Maybe<BatchPayload>;
  deleteManyPhoneNumberLookupResult?: Maybe<BatchPayload>;
  deleteManyPimsInvoice?: Maybe<BatchPayload>;
  deleteManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  deleteManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  deleteManyPrescription?: Maybe<BatchPayload>;
  deleteManyProduct?: Maybe<BatchPayload>;
  deleteManyProviderAppointmentTypeRoomMap?: Maybe<BatchPayload>;
  deleteManyPushNotificationLog?: Maybe<BatchPayload>;
  deleteManyRules?: Maybe<BatchPayload>;
  deleteManySegment?: Maybe<BatchPayload>;
  deleteManySentTrupanionExamDayOffer?: Maybe<BatchPayload>;
  deleteManyService?: Maybe<BatchPayload>;
  deleteManyServiceCategory?: Maybe<BatchPayload>;
  deleteManyServiceReminder?: Maybe<BatchPayload>;
  deleteManyServiceReminderNotification?: Maybe<BatchPayload>;
  deleteManyServiceReminderTiming?: Maybe<BatchPayload>;
  deleteManySex?: Maybe<BatchPayload>;
  deleteManySmsNotificationStatus?: Maybe<BatchPayload>;
  deleteManySpecies?: Maybe<BatchPayload>;
  deleteManyStaffRole?: Maybe<BatchPayload>;
  deleteManyStripeCustomer?: Maybe<BatchPayload>;
  deleteManyStripeDispute?: Maybe<BatchPayload>;
  deleteManyStripeInvoice?: Maybe<BatchPayload>;
  deleteManyStripeInvoiceItem?: Maybe<BatchPayload>;
  deleteManyStripePaymentMethod?: Maybe<BatchPayload>;
  deleteManyStripePayout?: Maybe<BatchPayload>;
  deleteManyStripePayoutAccount?: Maybe<BatchPayload>;
  deleteManyStripeReceipt?: Maybe<BatchPayload>;
  deleteManyStripeReceiptHistory?: Maybe<BatchPayload>;
  deleteManyStripeRefund?: Maybe<BatchPayload>;
  deleteManyStripeTerminal?: Maybe<BatchPayload>;
  deleteManyStripeTerminalLocation?: Maybe<BatchPayload>;
  deleteManySurveyAppointmentTypeSetting?: Maybe<BatchPayload>;
  deleteManySurveyResult?: Maybe<BatchPayload>;
  deleteManySurveySetting?: Maybe<BatchPayload>;
  deleteManySyncVetIntegration?: Maybe<BatchPayload>;
  deleteManyTransaction?: Maybe<BatchPayload>;
  deleteManyTrupanionCertificateAvailability?: Maybe<BatchPayload>;
  deleteManyTrupanionIntegratedAppointmentType?: Maybe<BatchPayload>;
  deleteManyTrupanionIntegration?: Maybe<BatchPayload>;
  deleteManyUser?: Maybe<BatchPayload>;
  deleteManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  deleteManyUserBoardFilter?: Maybe<BatchPayload>;
  deleteManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  deleteManyUserNotificationSetting?: Maybe<BatchPayload>;
  deleteManyUserSetting?: Maybe<BatchPayload>;
  deleteManyVaccination?: Maybe<BatchPayload>;
  deleteManyVetAvailability?: Maybe<BatchPayload>;
  deleteManyVetAvailabilityClinicRoomMap?: Maybe<BatchPayload>;
  deleteManyVetAvailabilityMap?: Maybe<BatchPayload>;
  deleteManyVetInfo?: Maybe<BatchPayload>;
  deleteManyVetsourceCodeTag?: Maybe<BatchPayload>;
  deleteManyVetsourceInstallation?: Maybe<BatchPayload>;
  deleteManyVetsourceRevenueCategory?: Maybe<BatchPayload>;
  deleteManyWorkflowEventSetting?: Maybe<BatchPayload>;
  deleteOneActiveIngredient?: Maybe<ActiveIngredient>;
  deleteOneAppointment?: Maybe<Appointment>;
  deleteOneAppointmentAvailability?: Maybe<AppointmentAvailability>;
  deleteOneAppointmentType?: Maybe<AppointmentType>;
  deleteOneAutomationRun?: Maybe<AutomationRun>;
  deleteOneAutomationRunAction?: Maybe<AutomationRunAction>;
  deleteOneBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  deleteOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  deleteOneBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  deleteOneBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  deleteOneBoardFilterColumn?: Maybe<BoardFilterColumn>;
  deleteOneBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  deleteOneBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  deleteOneBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  deleteOneBreed?: Maybe<Breed>;
  deleteOneCallHistory?: Maybe<CallHistory>;
  deleteOneCallParticipant?: Maybe<CallParticipant>;
  /** Deletes a CallRecording and its associated TwilioCallRecording */
  deleteOneCallRecording?: Maybe<CallRecording>;
  deleteOneCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  deleteOneCanonicalBreed?: Maybe<CanonicalBreed>;
  deleteOneCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  deleteOneCanonicalService?: Maybe<CanonicalService>;
  deleteOneCanonicalSpecies?: Maybe<CanonicalSpecies>;
  deleteOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  deleteOneCareAddonPackage?: Maybe<CareAddonPackage>;
  deleteOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  deleteOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  deleteOneCareBenefit?: Maybe<CareBenefit>;
  deleteOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  deleteOneCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  deleteOneCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  deleteOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  deleteOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  deleteOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  deleteOneCareLapsedPayment?: Maybe<CareLapsedPayment>;
  deleteOneCarePlan?: Maybe<CarePlan>;
  deleteOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  deleteOneCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  deleteOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  deleteOneCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  deleteOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  deleteOneCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  deleteOneCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  deleteOneChannel?: Maybe<Channel>;
  deleteOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  deleteOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  deleteOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  deleteOneChannelMember?: Maybe<ChannelMember>;
  deleteOneChannelMessage?: Maybe<ChannelMessage>;
  deleteOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  deleteOneChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  deleteOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  deleteOneChannelPin?: Maybe<ChannelPin>;
  deleteOneChannelSelection?: Maybe<ChannelSelection>;
  deleteOneChannelStatus?: Maybe<ChannelStatus>;
  deleteOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  deleteOneChemicalCompound?: Maybe<ChemicalCompound>;
  deleteOneClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  deleteOneClinic?: Maybe<Clinic>;
  deleteOneClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  deleteOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  deleteOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  deleteOneClinicEmployee?: Maybe<ClinicEmployee>;
  deleteOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  deleteOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  deleteOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  deleteOneClinicOnboarding?: Maybe<ClinicOnboarding>;
  deleteOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  deleteOneClinicPet?: Maybe<ClinicPet>;
  deleteOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  deleteOneClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  deleteOneClinicPetParent?: Maybe<ClinicPetParent>;
  deleteOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  deleteOneClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  deleteOneClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  deleteOneClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  deleteOneClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  deleteOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  deleteOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  deleteOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  deleteOneClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  deleteOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  deleteOneClinicRoom?: Maybe<ClinicRoom>;
  deleteOneClinicSetting?: Maybe<ClinicSetting>;
  deleteOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  deleteOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  deleteOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  deleteOneColor?: Maybe<Color>;
  deleteOneCondition?: Maybe<Condition>;
  deleteOneConditionSet?: Maybe<ConditionSet>;
  deleteOneConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  deleteOneConsultationRequest?: Maybe<ConsultationRequest>;
  deleteOneCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  deleteOneCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  deleteOneDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  deleteOneDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  deleteOneDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  deleteOneDisbursement?: Maybe<Disbursement>;
  deleteOneDocumentation?: Maybe<Documentation>;
  deleteOneEmailCampaign?: Maybe<EmailCampaign>;
  deleteOneEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  deleteOneEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  deleteOneEmailCampaignCustomListUploadHistory?: Maybe<EmailCampaignCustomListUploadHistory>;
  deleteOneEmailCampaignLog?: Maybe<EmailCampaignLog>;
  deleteOneEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  deleteOneEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  deleteOneEmailProviderLog?: Maybe<EmailProviderLog>;
  deleteOneEmergencyClinics?: Maybe<EmergencyClinics>;
  deleteOneEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  deleteOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  deleteOneFeatureFlag?: Maybe<FeatureFlag>;
  deleteOneFinancialAdjustment?: Maybe<FinancialAdjustment>;
  deleteOneFinancialTransaction?: Maybe<FinancialTransaction>;
  deleteOneFormSubmission?: Maybe<FormSubmission>;
  deleteOneFormTemplate?: Maybe<FormTemplate>;
  deleteOneGlobalPetParent?: Maybe<GlobalPetParent>;
  deleteOneHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  deleteOneInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  deleteOneInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  deleteOneInformCampaign?: Maybe<InformCampaign>;
  deleteOneInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  deleteOneInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  deleteOneInformCampaignExecution?: Maybe<InformCampaignExecution>;
  deleteOneInformCampaignGroup?: Maybe<InformCampaignGroup>;
  deleteOneInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  deleteOneInformEmailTemplate?: Maybe<InformEmailTemplate>;
  deleteOneInformEntityHistory?: Maybe<InformEntityHistory>;
  deleteOneInformPartner?: Maybe<InformPartner>;
  deleteOneInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  deleteOneInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  deleteOneInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  deleteOneInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  deleteOneInformPartnerProgram?: Maybe<InformPartnerProgram>;
  deleteOneInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  deleteOneInformPartnerUser?: Maybe<InformPartnerUser>;
  deleteOneInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  deleteOneInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  deleteOneInstinctIntegration?: Maybe<InstinctIntegration>;
  deleteOneInsuranceIntegration?: Maybe<InsuranceIntegration>;
  deleteOneIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  deleteOneInterchangeRate?: Maybe<InterchangeRate>;
  deleteOneInvoice?: Maybe<Invoice>;
  deleteOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  deleteOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  deleteOneLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  deleteOneLoyaltyAccount?: Maybe<LoyaltyAccount>;
  deleteOneLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  deleteOneLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  deleteOneLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  deleteOneLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  deleteOneLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  deleteOneLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  deleteOneLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  deleteOneMassTextAlert?: Maybe<MassTextAlert>;
  deleteOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  deleteOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  deleteOneMedia?: Maybe<Media>;
  deleteOneMessageTemplate?: Maybe<MessageTemplate>;
  deleteOneMessagingCampaign?: Maybe<MessagingCampaign>;
  deleteOneMessagingPartner?: Maybe<MessagingPartner>;
  deleteOneNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  deleteOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  deleteOneOrganization?: Maybe<Organization>;
  deleteOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  deleteOneOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  deleteOneOrganizationPet?: Maybe<OrganizationPet>;
  deleteOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  deleteOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  deleteOnePPCIntegration?: Maybe<PpcIntegration>;
  deleteOnePermission?: Maybe<Permission>;
  deleteOnePetParentSetting?: Maybe<PetParentSetting>;
  deleteOnePetPortalSetting?: Maybe<PetPortalSetting>;
  deleteOnePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  deleteOnePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  deleteOnePimsInvoice?: Maybe<PimsInvoice>;
  deleteOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  deleteOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  deleteOnePrescription?: Maybe<Prescription>;
  deleteOneProduct?: Maybe<Product>;
  deleteOneProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  deleteOnePushNotificationLog?: Maybe<PushNotificationLog>;
  deleteOneRules?: Maybe<Rules>;
  deleteOneSegment?: Maybe<Segment>;
  deleteOneSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  deleteOneService?: Maybe<Service>;
  deleteOneServiceCategory?: Maybe<ServiceCategory>;
  deleteOneServiceReminder?: Maybe<ServiceReminder>;
  deleteOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  deleteOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  deleteOneSex?: Maybe<Sex>;
  deleteOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  deleteOneSpecies?: Maybe<Species>;
  deleteOneStaffRole?: Maybe<StaffRole>;
  deleteOneStripeCustomer?: Maybe<StripeCustomer>;
  deleteOneStripeDispute?: Maybe<StripeDispute>;
  deleteOneStripeInvoice?: Maybe<StripeInvoice>;
  deleteOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  deleteOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  deleteOneStripePayout?: Maybe<StripePayout>;
  deleteOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  deleteOneStripeReceipt?: Maybe<StripeReceipt>;
  deleteOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  deleteOneStripeRefund?: Maybe<StripeRefund>;
  deleteOneStripeTerminal?: Maybe<StripeTerminal>;
  deleteOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  deleteOneSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  deleteOneSurveyResult?: Maybe<SurveyResult>;
  deleteOneSurveySetting?: Maybe<SurveySetting>;
  deleteOneSyncVetIntegration?: Maybe<SyncVetIntegration>;
  deleteOneTransaction?: Maybe<Transaction>;
  deleteOneTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  deleteOneTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  deleteOneTrupanionIntegration?: Maybe<TrupanionIntegration>;
  deleteOneUser?: Maybe<User>;
  deleteOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  deleteOneUserBoardFilter?: Maybe<UserBoardFilter>;
  deleteOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  deleteOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  deleteOneUserSetting?: Maybe<UserSetting>;
  deleteOneVaccination?: Maybe<Vaccination>;
  deleteOneVetAvailability?: Maybe<VetAvailability>;
  deleteOneVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  deleteOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  deleteOneVetInfo?: Maybe<VetInfo>;
  deleteOneVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  deleteOneVetsourceInstallation?: Maybe<VetsourceInstallation>;
  deleteOneVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  deleteOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  duplicateEmailCampaign?: Maybe<EmailCampaign>;
  encryptAndUploadData: EncryptedUploadResponse;
  ensurePetsPromoted?: Maybe<EnsurePetsPromotedResult>;
  findOrCreateLoyaltyAccountForClinicPetParent: LoyaltyAccount;
  findOrCreateLoyaltyProgram: LoyaltyProgram;
  formSubmissionExportToPims?: Maybe<FormSubmissionExportResponse>;
  fulfillLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  generateCustomToken?: Maybe<GenerateCustomTokenResponse>;
  generateTerminalAuthToken?: Maybe<GenerateTerminalAuthTokenResponse>;
  generateTerminalSetupPin?: Maybe<GenerateTerminalSetupPinResponse>;
  generateTinyUrlAndToken?: Maybe<Scalars['Json']>;
  generateWidgetAuthenticationRequest?: Maybe<GenerateWidgetAuthenticationRequestResponse>;
  getDirectedCareLoginLink: GetDirectedAuthLinkResult;
  getOrCreateActiveChannel?: Maybe<Channel>;
  getOrCreateActiveClientChannel?: Maybe<Channel>;
  getSignedDownloadUrl?: Maybe<Scalars['String']>;
  getSignedUploadUrl: GetSignedUploadUrlOutput;
  initSurveySetting: SurveySetting;
  /** Merge the information from the deleteId petparent into the keepId pet parent and soft delete deleteId */
  mergePetParent: MergePetParentResult;
  orderPhoneNumber?: Maybe<ClinicPhoneNumber>;
  performCarePlanEnrollmentUpgrade?: Maybe<PerformCarePlanEnrollmentUpgradeOutput>;
  postCareInvoiceAccountCreditToPims: WritebackCareInvoiceAccountCreditToPimsResponse;
  processTerminalPayment?: Maybe<ProcessTerminalPaymentResponse>;
  redeemClinicPetParentAuthCode?: Maybe<Scalars['Json']>;
  redeemOneTimeCode: RedeemOneTimeCodeResult;
  refundStripePaymentIntent2?: Maybe<StripePaymentIntent>;
  regeneratePetParentClinicListWithTokens?: Maybe<Scalars['Json']>;
  removeChannelMember2?: Maybe<ChannelMember>;
  removeChannelTag?: Maybe<Channel>;
  removeClinicFromLoyaltyProgram: OperationResult;
  removeUserFromClinic: User;
  requestBitwerxIntegration?: Maybe<ClinicPimsIntegration>;
  requestCareLoginLink: AuthLinkRequestResult;
  requestLoyaltyReward: LoyaltyRewardRedemption;
  requestOneTimeCode: RequestOneTimeCodeResult;
  resendReceiptEmail: ResendReceiptEmailOutput;
  resendUserInvitation: User;
  resetAutomationRun?: Maybe<AutomationRun>;
  retryPaymentWriteback?: Maybe<RetryPaymentWritebackOutput>;
  saveEmailTemplateVersion: EmailTemplateVersion;
  sendCareSignUpEmail?: Maybe<SendCareSignUpEmailOutput>;
  sendClinicPetParentAuthCode?: Maybe<SendClinicPetParentAuthCodeResponse>;
  sendClinicPetParentAuthLink?: Maybe<ClinicPetParentAuthLinkResponse>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  sendDirectedCareLoginLink: DirectedAuthLinkResult;
  sendEmailCampaignNow?: Maybe<SentEmailCampaign>;
  sendEnrollmentEmail?: Maybe<SendEnrollmentEmailOutput>;
  sendPaymentToTerminal?: Maybe<SendPaymentToTerminalResponse>;
  sendUiRefreshEvent?: Maybe<UiRefresh>;
  sendWidgetCodeToDeveloper?: Maybe<GenericResponse>;
  setClinicPetParentPrivacy?: Maybe<PrivacyResponse>;
  setPaymentIntentClinicPetParent2?: Maybe<StripePaymentIntent>;
  stripeConnectConnectedAccount?: Maybe<StripeAccountOutput>;
  stripeCreateConnectedAccount?: Maybe<StripeCreateAccountOutput>;
  submitForm2?: Maybe<SubmitFormResponse>;
  submitLoyaltyPointGrantToAccountByUser: OperationResult;
  submitPetParentRequest?: Maybe<SubmitFormResponse>;
  submitPrescriptionRefillRequest: SubmitPrescriptionRefillRequestResponse;
  syncClinic?: Maybe<Scalars['Json']>;
  syncManyPimsIntegrationModel?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  syncPimsIntegrationModel: ClinicPimsIntegrationCapability;
  toggleTwilioVideoCallRecordingStatus?: Maybe<CallRecording>;
  transferCareSubscription?: Maybe<TransferSubscriptionOutput>;
  transferTwilioNumber?: Maybe<GenericResponse>;
  triggerEmailCampaignsDataSync?: Maybe<Scalars['Boolean']>;
  triggerLoyaltyInvoiceProcessing: LoyaltyInvoiceTriggerOutput;
  triggerMessageEvent?: Maybe<MessageEventResponse>;
  triggerWorkflowEvent?: Maybe<GenericResponse>;
  trupanionIssueCertificate?: Maybe<TrupanionIssueCertificateResponse>;
  twilioVerifyCallerId?: Maybe<TwilioCallerIdVerification>;
  updateAppointmentTypeProviderRoomMaps: UpdateAppointmentTypeProviderRoomMapsResponse;
  updateCareSubscription?: Maybe<UpdateCareSubscriptionResult>;
  updateChannelFilterSelection: ChannelFilterSelection;
  updateClinicAppointmentTypeSettings?: Maybe<AppointmentType>;
  updateClinicPetParentContactInfoFromPims: ClinicPetParent;
  updateClinicPetWithoutPims?: Maybe<ClinicPet>;
  updateClinicPostcardSetting: ClinicPostcardSetting;
  updateClinicSettingTransactionColumn: ClinicSetting;
  updateManyActiveIngredient?: Maybe<BatchPayload>;
  updateManyAppointment?: Maybe<BatchPayload>;
  updateManyAppointmentAvailability?: Maybe<BatchPayload>;
  updateManyAppointmentType?: Maybe<BatchPayload>;
  updateManyAppointmentTypeSortOrder: AppointmentTypeWithOrderResponse;
  updateManyAutomationRun?: Maybe<BatchPayload>;
  updateManyAutomationRunAction?: Maybe<BatchPayload>;
  updateManyBitwerxHealthcheck?: Maybe<BatchPayload>;
  updateManyBitwerxIntegration?: Maybe<BatchPayload>;
  updateManyBitwerxPaymentHealthcheckResult?: Maybe<BatchPayload>;
  updateManyBitwerxPaymentHealthcheckRun?: Maybe<BatchPayload>;
  updateManyBoardFilterColumn?: Maybe<BatchPayload>;
  updateManyBoardFilterSpecificDateRange?: Maybe<BatchPayload>;
  updateManyBoehringerIngelheimCustomerAlignment?: Maybe<BatchPayload>;
  updateManyBoehringerIngelheimTerritory?: Maybe<BatchPayload>;
  updateManyBreed?: Maybe<BatchPayload>;
  updateManyCallHistory?: Maybe<BatchPayload>;
  updateManyCallParticipant?: Maybe<BatchPayload>;
  updateManyCampaignRegistryCampaign?: Maybe<BatchPayload>;
  updateManyCanonicalBreed?: Maybe<BatchPayload>;
  updateManyCanonicalCanineBreedProfile?: Maybe<BatchPayload>;
  updateManyCanonicalService?: Maybe<BatchPayload>;
  updateManyCanonicalSpecies?: Maybe<BatchPayload>;
  updateManyCareAddonEnrollment?: Maybe<BatchPayload>;
  updateManyCareAddonPackage?: Maybe<BatchPayload>;
  updateManyCareAddonPackageBenefit?: Maybe<BatchPayload>;
  updateManyCareAuthorizationRequest?: Maybe<BatchPayload>;
  updateManyCareBenefit?: Maybe<BatchPayload>;
  updateManyCareBenefitUsage?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageFollowup?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageFollowupStep?: Maybe<BatchPayload>;
  updateManyCareBenefitUsageToInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyCareEnrollmentPayment?: Maybe<BatchPayload>;
  updateManyCareEnrollmentToStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyCareLapsedPayment?: Maybe<BatchPayload>;
  updateManyCarePlan?: Maybe<BatchPayload>;
  updateManyCarePlanBenefit?: Maybe<BatchPayload>;
  updateManyCarePlanBenefitEnrollmentCarryover?: Maybe<BatchPayload>;
  updateManyCarePlanEnrollment?: Maybe<BatchPayload>;
  updateManyCarePlanProviderGroup?: Maybe<BatchPayload>;
  updateManyCareStripeSubscription?: Maybe<BatchPayload>;
  updateManyCareSubscriptionTrueUp?: Maybe<BatchPayload>;
  updateManyCareSubscriptionTrueUpLogEntry?: Maybe<BatchPayload>;
  updateManyChannel?: Maybe<BatchPayload>;
  updateManyChannelAutomationStatus?: Maybe<BatchPayload>;
  updateManyChannelCreationSource?: Maybe<BatchPayload>;
  updateManyChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyChannelMember?: Maybe<BatchPayload>;
  updateManyChannelMessage?: Maybe<BatchPayload>;
  updateManyChannelMessageAttachment?: Maybe<BatchPayload>;
  updateManyChannelMessageAutoResponse?: Maybe<BatchPayload>;
  updateManyChannelMessageReaction?: Maybe<BatchPayload>;
  updateManyChannelPin?: Maybe<BatchPayload>;
  updateManyChannelSelection?: Maybe<BatchPayload>;
  updateManyChannelStatus?: Maybe<BatchPayload>;
  updateManyChannelStatusChannelAssignee?: Maybe<BatchPayload>;
  updateManyChannelStatusSortOrder: ChannelStatusWithOrderResponse;
  updateManyChemicalCompound?: Maybe<BatchPayload>;
  updateManyClientConnectIntegration?: Maybe<BatchPayload>;
  updateManyClinic?: Maybe<BatchPayload>;
  updateManyClinicBlockedPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicDirectBookingSetting?: Maybe<BatchPayload>;
  updateManyClinicEmailCampaignSetting?: Maybe<BatchPayload>;
  updateManyClinicEmployee?: Maybe<BatchPayload>;
  updateManyClinicEmployeeAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManyClinicEntityPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicOfficeHour?: Maybe<BatchPayload>;
  updateManyClinicOnboarding?: Maybe<BatchPayload>;
  updateManyClinicPaymentFeeConfiguration?: Maybe<BatchPayload>;
  updateManyClinicPet?: Maybe<BatchPayload>;
  updateManyClinicPetAlert?: Maybe<BatchPayload>;
  updateManyClinicPetCustomFieldValue?: Maybe<BatchPayload>;
  updateManyClinicPetParent?: Maybe<BatchPayload>;
  updateManyClinicPetParentAddress?: Maybe<BatchPayload>;
  updateManyClinicPetParentCustomFieldValue?: Maybe<BatchPayload>;
  updateManyClinicPetParentDevice?: Maybe<BatchPayload>;
  updateManyClinicPetParentPimsSyncStatus?: Maybe<BatchPayload>;
  updateManyClinicPetPimsSyncStatus?: Maybe<BatchPayload>;
  updateManyClinicPhoneNumber?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegration?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegrationCapability?: Maybe<BatchPayload>;
  updateManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<BatchPayload>;
  updateManyClinicPostcardSetting?: Maybe<BatchPayload>;
  updateManyClinicRoom?: Maybe<BatchPayload>;
  updateManyClinicSetting?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequest?: Maybe<BatchPayload>;
  updateManyClinicWidgetRequestType?: Maybe<BatchPayload>;
  updateManyClinicWidgetSetting?: Maybe<BatchPayload>;
  updateManyColor?: Maybe<BatchPayload>;
  updateManyCondition?: Maybe<BatchPayload>;
  updateManyConditionSet?: Maybe<BatchPayload>;
  updateManyConditionalEntityEvaluation?: Maybe<BatchPayload>;
  updateManyConsultationRequest?: Maybe<BatchPayload>;
  updateManyCustomFieldDefinition?: Maybe<BatchPayload>;
  updateManyCustomFieldPimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyDirectBookingAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManyDirectBookingExclusionTimeSlot?: Maybe<BatchPayload>;
  updateManyDisbursement?: Maybe<BatchPayload>;
  updateManyDocumentation?: Maybe<BatchPayload>;
  updateManyEmailCampaign?: Maybe<BatchPayload>;
  updateManyEmailCampaignCustomList?: Maybe<BatchPayload>;
  updateManyEmailCampaignCustomListRecipient?: Maybe<BatchPayload>;
  updateManyEmailCampaignCustomListUploadHistory?: Maybe<BatchPayload>;
  updateManyEmailCampaignLog?: Maybe<BatchPayload>;
  updateManyEmailCampaignTemplate?: Maybe<BatchPayload>;
  updateManyEmailCampaignUnsubscribe?: Maybe<BatchPayload>;
  updateManyEmailProviderLog?: Maybe<BatchPayload>;
  updateManyEmailTemplateVersion?: Maybe<BatchPayload>;
  updateManyEmergencyClinics?: Maybe<BatchPayload>;
  updateManyEnrollmentSupportEvent?: Maybe<BatchPayload>;
  updateManyEzyVetIntegration?: Maybe<BatchPayload>;
  updateManyFeatureFlag?: Maybe<BatchPayload>;
  updateManyFinancialAdjustment?: Maybe<BatchPayload>;
  updateManyFinancialTransaction?: Maybe<BatchPayload>;
  updateManyFormSubmission?: Maybe<BatchPayload>;
  updateManyFormTemplate?: Maybe<BatchPayload>;
  updateManyGlobalPetParent?: Maybe<BatchPayload>;
  updateManyHillsToHomeAPILog?: Maybe<BatchPayload>;
  updateManyInformAudienceDefinition?: Maybe<BatchPayload>;
  updateManyInformCampaign?: Maybe<BatchPayload>;
  updateManyInformCampaignEnrollment?: Maybe<BatchPayload>;
  updateManyInformCampaignEnrollmentCanonicalService?: Maybe<BatchPayload>;
  updateManyInformCampaignExecution?: Maybe<BatchPayload>;
  updateManyInformCampaignGroup?: Maybe<BatchPayload>;
  updateManyInformControlGroupParticipant?: Maybe<BatchPayload>;
  updateManyInformEmailTemplate?: Maybe<BatchPayload>;
  updateManyInformEntityHistory?: Maybe<BatchPayload>;
  updateManyInformPartner?: Maybe<BatchPayload>;
  updateManyInformPartnerCustomer?: Maybe<BatchPayload>;
  updateManyInformPartnerCustomerPharmacyProvider?: Maybe<BatchPayload>;
  updateManyInformPartnerIntegration?: Maybe<BatchPayload>;
  updateManyInformPartnerIntegrationModel?: Maybe<BatchPayload>;
  updateManyInformPartnerProgram?: Maybe<BatchPayload>;
  updateManyInformPartnerProgramEnrollment?: Maybe<BatchPayload>;
  updateManyInformPartnerUser?: Maybe<BatchPayload>;
  updateManyInformPartnerUserRole?: Maybe<BatchPayload>;
  updateManyInformScheduledCampaign?: Maybe<BatchPayload>;
  updateManyInstinctIntegration?: Maybe<BatchPayload>;
  updateManyInsuranceIntegration?: Maybe<BatchPayload>;
  updateManyIntegrationOutageHistoryEntry?: Maybe<BatchPayload>;
  updateManyInterchangeRate?: Maybe<BatchPayload>;
  updateManyInvoice?: Maybe<BatchPayload>;
  updateManyInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyInvoicePimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyLapsedPetParentTriggers?: Maybe<BatchPayload>;
  updateManyLegalEntity?: Maybe<BatchPayload>;
  updateManyLoyaltyAccount?: Maybe<BatchPayload>;
  updateManyLoyaltyAccountHolder?: Maybe<BatchPayload>;
  updateManyLoyaltyAccountMerger?: Maybe<BatchPayload>;
  updateManyLoyaltyInvoicePointGranter?: Maybe<BatchPayload>;
  updateManyLoyaltyPointGrantingService?: Maybe<BatchPayload>;
  updateManyLoyaltyPointGrantingServiceCategory?: Maybe<BatchPayload>;
  updateManyLoyaltyPointUserGrant?: Maybe<BatchPayload>;
  updateManyLoyaltyReward?: Maybe<BatchPayload>;
  updateManyLoyaltyRewardRedemption?: Maybe<BatchPayload>;
  updateManyMassTextAlert?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntry?: Maybe<BatchPayload>;
  updateManyMassTextAlertEntryAppointment?: Maybe<BatchPayload>;
  updateManyMedia?: Maybe<BatchPayload>;
  updateManyMessageTemplate?: Maybe<BatchPayload>;
  updateManyMessagingCampaign?: Maybe<BatchPayload>;
  updateManyMessagingPartner?: Maybe<BatchPayload>;
  updateManyNonPrismaSchemaScriptRan?: Maybe<BatchPayload>;
  updateManyNotificationEventToMessage?: Maybe<BatchPayload>;
  updateManyOrganization?: Maybe<BatchPayload>;
  updateManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<BatchPayload>;
  updateManyOrganizationPet?: Maybe<BatchPayload>;
  updateManyOrganizationPetParent?: Maybe<BatchPayload>;
  updateManyPPCIntegration?: Maybe<BatchPayload>;
  updateManyPayoutBatchingPeriod?: Maybe<BatchPayload>;
  updateManyPermission?: Maybe<BatchPayload>;
  updateManyPetParentSetting?: Maybe<BatchPayload>;
  updateManyPetPortalSetting?: Maybe<BatchPayload>;
  updateManyPhoneNumberLookupRequest?: Maybe<BatchPayload>;
  updateManyPhoneNumberLookupResult?: Maybe<BatchPayload>;
  updateManyPimsInvoice?: Maybe<BatchPayload>;
  updateManyPimsInvoiceLineItem?: Maybe<BatchPayload>;
  updateManyPimsWritebackLogEntry?: Maybe<BatchPayload>;
  updateManyPrescription?: Maybe<BatchPayload>;
  updateManyProduct?: Maybe<BatchPayload>;
  updateManyProviderAppointmentTypeRoomMap?: Maybe<BatchPayload>;
  updateManyPushNotificationLog?: Maybe<BatchPayload>;
  updateManyRules?: Maybe<BatchPayload>;
  updateManySegment?: Maybe<BatchPayload>;
  updateManySentTrupanionExamDayOffer?: Maybe<BatchPayload>;
  updateManyService?: Maybe<BatchPayload>;
  updateManyServiceCategory?: Maybe<BatchPayload>;
  updateManyServiceReminder?: Maybe<BatchPayload>;
  updateManyServiceReminderNotification?: Maybe<BatchPayload>;
  updateManyServiceReminderTiming?: Maybe<BatchPayload>;
  updateManyServicesAndTimings: Array<Service>;
  updateManySex?: Maybe<BatchPayload>;
  updateManySmsNotificationStatus?: Maybe<BatchPayload>;
  updateManySpecies?: Maybe<BatchPayload>;
  updateManyStaffRole?: Maybe<BatchPayload>;
  updateManyStripeCustomer?: Maybe<BatchPayload>;
  updateManyStripeDispute?: Maybe<BatchPayload>;
  updateManyStripeInvoice?: Maybe<BatchPayload>;
  updateManyStripeInvoiceItem?: Maybe<BatchPayload>;
  updateManyStripePaymentIntent?: Maybe<BatchPayload>;
  updateManyStripePaymentMethod?: Maybe<BatchPayload>;
  updateManyStripePayout?: Maybe<BatchPayload>;
  updateManyStripePayoutAccount?: Maybe<BatchPayload>;
  updateManyStripeReceipt?: Maybe<BatchPayload>;
  updateManyStripeReceiptHistory?: Maybe<BatchPayload>;
  updateManyStripeRefund?: Maybe<BatchPayload>;
  updateManyStripeTerminal?: Maybe<BatchPayload>;
  updateManyStripeTerminalLocation?: Maybe<BatchPayload>;
  updateManySurveyAppointmentTypeSetting?: Maybe<BatchPayload>;
  updateManySurveyResult?: Maybe<BatchPayload>;
  updateManySurveySetting?: Maybe<BatchPayload>;
  updateManySyncVetIntegration?: Maybe<BatchPayload>;
  updateManyTimingsDeliveryOptions: Array<Service>;
  updateManyTransaction?: Maybe<BatchPayload>;
  updateManyTrupanionCertificateAvailability?: Maybe<BatchPayload>;
  updateManyTrupanionIntegratedAppointmentType?: Maybe<BatchPayload>;
  updateManyTrupanionIntegration?: Maybe<BatchPayload>;
  updateManyUser?: Maybe<BatchPayload>;
  updateManyUserAppointmentGrouping?: Maybe<BatchPayload>;
  updateManyUserBoardFilter?: Maybe<BatchPayload>;
  updateManyUserChannelFilterSelection?: Maybe<BatchPayload>;
  updateManyUserNotificationSetting?: Maybe<BatchPayload>;
  updateManyUserSetting?: Maybe<BatchPayload>;
  updateManyVaccination?: Maybe<BatchPayload>;
  updateManyVetAvailability?: Maybe<BatchPayload>;
  updateManyVetAvailabilityClinicRoomMap?: Maybe<BatchPayload>;
  updateManyVetAvailabilityMap?: Maybe<BatchPayload>;
  updateManyVetInfo?: Maybe<BatchPayload>;
  updateManyVetsourceCodeTag?: Maybe<BatchPayload>;
  updateManyVetsourceInstallation?: Maybe<BatchPayload>;
  updateManyVetsourceRevenueCategory?: Maybe<BatchPayload>;
  updateManyWorkflowEventSetting?: Maybe<BatchPayload>;
  updateOneActiveIngredient: ActiveIngredient;
  updateOneAppointment: Appointment;
  updateOneAppointmentAvailability: AppointmentAvailability;
  updateOneAppointmentType: AppointmentType;
  updateOneAutomationRun: AutomationRun;
  updateOneAutomationRunAction: AutomationRunAction;
  updateOneBitwerxHealthcheck: BitwerxHealthcheck;
  updateOneBitwerxIntegration: BitwerxIntegration;
  updateOneBitwerxPaymentHealthcheckResult: BitwerxPaymentHealthcheckResult;
  updateOneBitwerxPaymentHealthcheckRun: BitwerxPaymentHealthcheckRun;
  updateOneBitwerxPaymentWritebackConfiguration: BitwerxPaymentWritebackConfiguration;
  updateOneBoardFilterColumn: BoardFilterColumn;
  updateOneBoardFilterSpecificDateRange: BoardFilterSpecificDateRange;
  updateOneBoehringerIngelheimCustomerAlignment: BoehringerIngelheimCustomerAlignment;
  updateOneBoehringerIngelheimTerritory: BoehringerIngelheimTerritory;
  updateOneBreed: Breed;
  updateOneCallHistory: CallHistory;
  updateOneCallParticipant: CallParticipant;
  updateOneCallRecording?: Maybe<CallRecording>;
  updateOneCampaignRegistryCampaign: CampaignRegistryCampaign;
  updateOneCanonicalBreed: CanonicalBreed;
  updateOneCanonicalCanineBreedProfile: CanonicalCanineBreedProfile;
  updateOneCanonicalService: CanonicalService;
  updateOneCanonicalSpecies: CanonicalSpecies;
  updateOneCareAddonEnrollment: CareAddonEnrollment;
  updateOneCareAddonPackage: CareAddonPackage;
  updateOneCareAddonPackageBenefit: CareAddonPackageBenefit;
  updateOneCareAuthorizationRequest: CareAuthorizationRequest;
  updateOneCareBenefit: CareBenefit;
  updateOneCareBenefitUsage: CareBenefitUsage;
  updateOneCareBenefitUsageFollowup: CareBenefitUsageFollowup;
  updateOneCareBenefitUsageFollowupStep: CareBenefitUsageFollowupStep;
  updateOneCareBenefitUsageToInvoiceLineItem: CareBenefitUsageToInvoiceLineItem;
  updateOneCareEnrollmentPayment: CareEnrollmentPayment;
  updateOneCareEnrollmentToStripeInvoiceItem: CareEnrollmentToStripeInvoiceItem;
  updateOneCareLapsedPayment: CareLapsedPayment;
  updateOneCarePlan: CarePlan;
  updateOneCarePlanBenefit: CarePlanBenefit;
  updateOneCarePlanBenefitEnrollmentCarryover: CarePlanBenefitEnrollmentCarryover;
  updateOneCarePlanEnrollment: CarePlanEnrollment;
  updateOneCarePlanProviderGroup: CarePlanProviderGroup;
  updateOneCareStripeSubscription: CareStripeSubscription;
  updateOneCareSubscriptionTrueUp: CareSubscriptionTrueUp;
  updateOneCareSubscriptionTrueUpLogEntry: CareSubscriptionTrueUpLogEntry;
  updateOneChannel: Channel;
  updateOneChannelAutomationStatus: ChannelAutomationStatus;
  updateOneChannelCreationSource: ChannelCreationSource;
  updateOneChannelFilterSelection: ChannelFilterSelection;
  updateOneChannelMember: ChannelMember;
  updateOneChannelMessage: ChannelMessage;
  updateOneChannelMessageAttachment: ChannelMessageAttachment;
  updateOneChannelMessageAutoResponse: ChannelMessageAutoResponse;
  updateOneChannelMessageReaction: ChannelMessageReaction;
  updateOneChannelPin: ChannelPin;
  updateOneChannelSelection: ChannelSelection;
  updateOneChannelStatus: ChannelStatus;
  updateOneChannelStatusChannelAssignee: ChannelStatusChannelAssignee;
  updateOneChemicalCompound: ChemicalCompound;
  updateOneClientConnectIntegration: ClientConnectIntegration;
  updateOneClinic: Clinic;
  updateOneClinicBlockedPhoneNumber: ClinicBlockedPhoneNumber;
  updateOneClinicDirectBookingSetting: ClinicDirectBookingSetting;
  updateOneClinicEmailCampaignSetting: ClinicEmailCampaignSetting;
  updateOneClinicEmployee: ClinicEmployee;
  updateOneClinicEmployeeAppointmentTypeSetting: ClinicEmployeeAppointmentTypeSetting;
  updateOneClinicEntityPhoneNumber: ClinicEntityPhoneNumber;
  updateOneClinicOfficeHour: ClinicOfficeHour;
  updateOneClinicOnboarding: ClinicOnboarding;
  updateOneClinicPaymentFeeConfiguration: ClinicPaymentFeeConfiguration;
  updateOneClinicPet: ClinicPet;
  updateOneClinicPetAlert: ClinicPetAlert;
  updateOneClinicPetCustomFieldValue: ClinicPetCustomFieldValue;
  updateOneClinicPetParent: ClinicPetParent;
  updateOneClinicPetParentAddress: ClinicPetParentAddress;
  updateOneClinicPetParentAndEnqueuePhoneNumberLookup: ClinicPetParentWithJobChainsResponse;
  updateOneClinicPetParentCustomFieldValue: ClinicPetParentCustomFieldValue;
  updateOneClinicPetParentDevice: ClinicPetParentDevice;
  updateOneClinicPetParentPimsSyncStatus: ClinicPetParentPimsSyncStatus;
  updateOneClinicPetPimsSyncStatus: ClinicPetPimsSyncStatus;
  updateOneClinicPhoneNumber: ClinicPhoneNumber;
  updateOneClinicPimsIntegration: ClinicPimsIntegration;
  updateOneClinicPimsIntegrationCapability: ClinicPimsIntegrationCapability;
  updateOneClinicPimsIntegrationCapabilityHistoryEntry: ClinicPimsIntegrationCapabilityHistoryEntry;
  updateOneClinicPostcardSetting: ClinicPostcardSetting;
  updateOneClinicRoom: ClinicRoom;
  updateOneClinicSetting: ClinicSetting;
  updateOneClinicWidgetRequest: ClinicWidgetRequest;
  updateOneClinicWidgetRequestType: ClinicWidgetRequestType;
  updateOneClinicWidgetSetting: ClinicWidgetSetting;
  updateOneColor: Color;
  updateOneCondition: Condition;
  updateOneConditionSet: ConditionSet;
  updateOneConditionalEntityEvaluation: ConditionalEntityEvaluation;
  updateOneConsultationRequest: ConsultationRequest;
  updateOneCustomFieldDefinition: CustomFieldDefinition;
  updateOneCustomFieldPimsWritebackLogEntry: CustomFieldPimsWritebackLogEntry;
  updateOneDirectBookingAppointmentTypeSetting: DirectBookingAppointmentTypeSetting;
  updateOneDirectBookingExclusionRule: DirectBookingExclusionRule;
  updateOneDirectBookingExclusionTimeSlot: DirectBookingExclusionTimeSlot;
  updateOneDisbursement: Disbursement;
  updateOneDocumentation: Documentation;
  updateOneEmailCampaign: EmailCampaign;
  updateOneEmailCampaignCustomList: EmailCampaignCustomList;
  updateOneEmailCampaignCustomListRecipient: EmailCampaignCustomListRecipient;
  updateOneEmailCampaignCustomListUploadHistory: EmailCampaignCustomListUploadHistory;
  updateOneEmailCampaignLog: EmailCampaignLog;
  updateOneEmailCampaignTemplate: EmailCampaignTemplate;
  updateOneEmailCampaignUnsubscribe: EmailCampaignUnsubscribe;
  updateOneEmailProviderLog: EmailProviderLog;
  updateOneEmailTemplateVersion: EmailTemplateVersion;
  updateOneEmergencyClinics: EmergencyClinics;
  updateOneEnrollmentSupportEvent: EnrollmentSupportEvent;
  updateOneEzyVetIntegration: EzyVetIntegration;
  updateOneFeatureFlag: FeatureFlag;
  updateOneFinancialAdjustment: FinancialAdjustment;
  updateOneFinancialTransaction: FinancialTransaction;
  updateOneFormSubmission: FormSubmission;
  updateOneFormTemplate: FormTemplate;
  updateOneGlobalPetParent: GlobalPetParent;
  updateOneHillsToHomeAPILog: HillsToHomeApiLog;
  updateOneInformAudienceDefinition: InformAudienceDefinition;
  updateOneInformAutomationCampaign: InformAutomationCampaign;
  updateOneInformCampaign: InformCampaign;
  updateOneInformCampaignEnrollment: InformCampaignEnrollment;
  updateOneInformCampaignEnrollmentCanonicalService: InformCampaignEnrollmentCanonicalService;
  updateOneInformCampaignExecution: InformCampaignExecution;
  updateOneInformCampaignGroup: InformCampaignGroup;
  updateOneInformControlGroupParticipant: InformControlGroupParticipant;
  updateOneInformEmailTemplate: InformEmailTemplate;
  updateOneInformEntityHistory: InformEntityHistory;
  updateOneInformPartner: InformPartner;
  updateOneInformPartnerCustomer: InformPartnerCustomer;
  updateOneInformPartnerCustomerPharmacyProvider: InformPartnerCustomerPharmacyProvider;
  updateOneInformPartnerIntegration: InformPartnerIntegration;
  updateOneInformPartnerIntegrationModel: InformPartnerIntegrationModel;
  updateOneInformPartnerProgram: InformPartnerProgram;
  updateOneInformPartnerProgramEnrollment: InformPartnerProgramEnrollment;
  updateOneInformPartnerUser: InformPartnerUser;
  updateOneInformPartnerUserRole: InformPartnerUserRole;
  updateOneInformScheduledCampaign: InformScheduledCampaign;
  updateOneInstinctIntegration: InstinctIntegration;
  updateOneInsuranceIntegration: InsuranceIntegration;
  updateOneIntegrationOutageHistoryEntry: IntegrationOutageHistoryEntry;
  updateOneInterchangeRate: InterchangeRate;
  updateOneInvoice: Invoice;
  updateOneInvoiceLineItem: InvoiceLineItem;
  updateOneInvoicePimsWritebackLogEntry: InvoicePimsWritebackLogEntry;
  updateOneLapsedPetParentTriggers: LapsedPetParentTriggers;
  updateOneLegalEntity: LegalEntity;
  updateOneLoyaltyAccount: LoyaltyAccount;
  updateOneLoyaltyAccountHolder: LoyaltyAccountHolder;
  updateOneLoyaltyAccountMerger: LoyaltyAccountMerger;
  updateOneLoyaltyInvoicePointGranter: LoyaltyInvoicePointGranter;
  updateOneLoyaltyPointGrantingService: LoyaltyPointGrantingService;
  updateOneLoyaltyPointGrantingServiceCategory: LoyaltyPointGrantingServiceCategory;
  updateOneLoyaltyPointUserGrant: LoyaltyPointUserGrant;
  updateOneLoyaltyProgram: LoyaltyProgram;
  updateOneLoyaltyReward: LoyaltyReward;
  updateOneLoyaltyRewardRedemption: LoyaltyRewardRedemption;
  updateOneMassTextAlert: MassTextAlert;
  updateOneMassTextAlertEntry: MassTextAlertEntry;
  updateOneMassTextAlertEntryAppointment: MassTextAlertEntryAppointment;
  updateOneMedia: Media;
  updateOneMessageTemplate: MessageTemplate;
  updateOneMessagingCampaign: MessagingCampaign;
  updateOneMessagingPartner: MessagingPartner;
  updateOneNonPrismaSchemaScriptRan: NonPrismaSchemaScriptRan;
  updateOneNotificationEventToMessage: NotificationEventToMessage;
  updateOneOrganization: Organization;
  updateOneOrganizationCareBenefitToClinicService: OrganizationCareBenefitToClinicService;
  updateOneOrganizationCareBenefitToClinicServiceCategory: OrganizationCareBenefitToClinicServiceCategory;
  updateOneOrganizationPet: OrganizationPet;
  updateOneOrganizationPetParent: OrganizationPetParent;
  updateOneOrganizationPetToOrganizationPetParent: OrganizationPetToOrganizationPetParent;
  updateOnePPCIntegration: PpcIntegration;
  updateOnePayoutBatchingPeriod: PayoutBatchingPeriod;
  updateOnePermission: Permission;
  updateOnePetParentSetting: PetParentSetting;
  updateOnePetPortalSetting: PetPortalSetting;
  updateOnePhoneNumberLookupRequest: PhoneNumberLookupRequest;
  updateOnePhoneNumberLookupResult: PhoneNumberLookupResult;
  updateOnePimsInvoice: PimsInvoice;
  updateOnePimsInvoiceLineItem: PimsInvoiceLineItem;
  updateOnePimsWritebackLogEntry: PimsWritebackLogEntry;
  updateOnePrescription: Prescription;
  updateOneProduct: Product;
  updateOneProviderAppointmentTypeRoomMap: ProviderAppointmentTypeRoomMap;
  updateOnePushNotificationLog: PushNotificationLog;
  updateOneRules: Rules;
  updateOneSegment: Segment;
  updateOneSentTrupanionExamDayOffer: SentTrupanionExamDayOffer;
  updateOneService: Service;
  updateOneServiceCategory: ServiceCategory;
  updateOneServiceReminder: ServiceReminder;
  updateOneServiceReminderNotification: ServiceReminderNotification;
  updateOneServiceReminderTiming: ServiceReminderTiming;
  updateOneSex: Sex;
  updateOneSmsNotificationStatus: SmsNotificationStatus;
  updateOneSpecies: Species;
  updateOneStaffRole: StaffRole;
  updateOneStripeCustomer: StripeCustomer;
  updateOneStripeDispute: StripeDispute;
  updateOneStripeInvoice: StripeInvoice;
  updateOneStripeInvoiceItem: StripeInvoiceItem;
  updateOneStripePaymentIntent: StripePaymentIntent;
  updateOneStripePaymentMethod: StripePaymentMethod;
  updateOneStripePayout: StripePayout;
  updateOneStripePayoutAccount: StripePayoutAccount;
  updateOneStripeReceipt: StripeReceipt;
  updateOneStripeReceiptHistory: StripeReceiptHistory;
  updateOneStripeRefund: StripeRefund;
  updateOneStripeTerminal: StripeTerminal;
  updateOneStripeTerminalLocation: StripeTerminalLocation;
  updateOneSurveyAppointmentTypeSetting: SurveyAppointmentTypeSetting;
  updateOneSurveyResult: SurveyResult;
  updateOneSurveySetting: SurveySetting;
  updateOneSyncVetIntegration: SyncVetIntegration;
  updateOneTransaction: Transaction;
  updateOneTrupanionCertificateAvailability: TrupanionCertificateAvailability;
  updateOneTrupanionIntegratedAppointmentType: TrupanionIntegratedAppointmentType;
  updateOneTrupanionIntegration: TrupanionIntegration;
  updateOneUser: User;
  updateOneUserAppointmentGrouping: UserAppointmentGrouping;
  updateOneUserBoardFilter: UserBoardFilter;
  updateOneUserChannelFilterSelection: UserChannelFilterSelection;
  updateOneUserNotificationSetting: UserNotificationSetting;
  updateOneUserSetting: UserSetting;
  updateOneVaccination: Vaccination;
  updateOneVetAvailability: VetAvailability;
  updateOneVetAvailabilityClinicRoomMap: VetAvailabilityClinicRoomMap;
  updateOneVetAvailabilityMap: VetAvailabilityMap;
  updateOneVetInfo: VetInfo;
  updateOneVetsourceCodeTag: VetsourceCodeTag;
  updateOneVetsourceInstallation: VetsourceInstallation;
  updateOneVetsourceRevenueCategory: VetsourceRevenueCategory;
  updateOneWorkflowEventSetting: WorkflowEventSetting;
  updatePetParentPaymentIntent?: Maybe<StripePaymentIntent>;
  updateStripePayment?: Maybe<StripePaymentOutput>;
  updateTeamChannel2?: Maybe<Channel>;
  updateTerminalAppPaymentIntent?: Maybe<StripePaymentIntent>;
  uploadEmailCampaignCustomList?: Maybe<EmailCampaignCustomListResponse>;
  upsertOneActiveIngredient?: Maybe<ActiveIngredient>;
  upsertOneAppointment?: Maybe<Appointment>;
  upsertOneAppointmentAvailability?: Maybe<AppointmentAvailability>;
  upsertOneAppointmentType?: Maybe<AppointmentType>;
  upsertOneAutomationRun?: Maybe<AutomationRun>;
  upsertOneAutomationRunAction?: Maybe<AutomationRunAction>;
  upsertOneBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  upsertOneBitwerxIntegration?: Maybe<BitwerxIntegration>;
  upsertOneBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  upsertOneBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  upsertOneBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  upsertOneBoardFilterColumn?: Maybe<BoardFilterColumn>;
  upsertOneBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  upsertOneBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  upsertOneBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  upsertOneBreed?: Maybe<Breed>;
  upsertOneCallHistory?: Maybe<CallHistory>;
  upsertOneCallParticipant?: Maybe<CallParticipant>;
  upsertOneCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  upsertOneCanonicalBreed?: Maybe<CanonicalBreed>;
  upsertOneCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  upsertOneCanonicalService?: Maybe<CanonicalService>;
  upsertOneCanonicalSpecies?: Maybe<CanonicalSpecies>;
  upsertOneCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  upsertOneCareAddonPackage?: Maybe<CareAddonPackage>;
  upsertOneCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  upsertOneCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  upsertOneCareBenefit?: Maybe<CareBenefit>;
  upsertOneCareBenefitUsage?: Maybe<CareBenefitUsage>;
  upsertOneCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  upsertOneCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  upsertOneCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  upsertOneCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  upsertOneCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  upsertOneCareLapsedPayment?: Maybe<CareLapsedPayment>;
  upsertOneCarePlan?: Maybe<CarePlan>;
  upsertOneCarePlanBenefit?: Maybe<CarePlanBenefit>;
  upsertOneCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  upsertOneCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  upsertOneCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  upsertOneCareStripeSubscription?: Maybe<CareStripeSubscription>;
  upsertOneCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  upsertOneCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  upsertOneChannel?: Maybe<Channel>;
  upsertOneChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  upsertOneChannelCreationSource?: Maybe<ChannelCreationSource>;
  upsertOneChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  upsertOneChannelMember?: Maybe<ChannelMember>;
  upsertOneChannelMessage?: Maybe<ChannelMessage>;
  upsertOneChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  upsertOneChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  upsertOneChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  upsertOneChannelPin?: Maybe<ChannelPin>;
  upsertOneChannelSelection?: Maybe<ChannelSelection>;
  upsertOneChannelStatus?: Maybe<ChannelStatus>;
  upsertOneChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  upsertOneChemicalCompound?: Maybe<ChemicalCompound>;
  upsertOneClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  upsertOneClinic?: Maybe<Clinic>;
  upsertOneClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  upsertOneClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  upsertOneClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  upsertOneClinicEmployee?: Maybe<ClinicEmployee>;
  upsertOneClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  upsertOneClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  upsertOneClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  upsertOneClinicOnboarding?: Maybe<ClinicOnboarding>;
  upsertOneClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  upsertOneClinicPet?: Maybe<ClinicPet>;
  upsertOneClinicPetAlert?: Maybe<ClinicPetAlert>;
  upsertOneClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  upsertOneClinicPetParent?: Maybe<ClinicPetParent>;
  upsertOneClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  upsertOneClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  upsertOneClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  upsertOneClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  upsertOneClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  upsertOneClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  upsertOneClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  upsertOneClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  upsertOneClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  upsertOneClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  upsertOneClinicRoom?: Maybe<ClinicRoom>;
  upsertOneClinicSetting?: Maybe<ClinicSetting>;
  upsertOneClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  upsertOneClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  upsertOneClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  upsertOneColor?: Maybe<Color>;
  upsertOneCondition?: Maybe<Condition>;
  upsertOneConditionSet?: Maybe<ConditionSet>;
  upsertOneConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  upsertOneConsultationRequest?: Maybe<ConsultationRequest>;
  upsertOneCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  upsertOneCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  upsertOneDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  upsertOneDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  upsertOneDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  upsertOneDisbursement?: Maybe<Disbursement>;
  upsertOneDocumentation?: Maybe<Documentation>;
  upsertOneEmailCampaign?: Maybe<EmailCampaign>;
  upsertOneEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  upsertOneEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  upsertOneEmailCampaignCustomListUploadHistory?: Maybe<EmailCampaignCustomListUploadHistory>;
  upsertOneEmailCampaignLog?: Maybe<EmailCampaignLog>;
  upsertOneEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  upsertOneEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  upsertOneEmailProviderLog?: Maybe<EmailProviderLog>;
  upsertOneEmergencyClinics?: Maybe<EmergencyClinics>;
  upsertOneEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  upsertOneEzyVetIntegration?: Maybe<EzyVetIntegration>;
  upsertOneFeatureFlag?: Maybe<FeatureFlag>;
  upsertOneFinancialAdjustment?: Maybe<FinancialAdjustment>;
  upsertOneFinancialTransaction?: Maybe<FinancialTransaction>;
  upsertOneFormSubmission?: Maybe<FormSubmission>;
  upsertOneFormTemplate?: Maybe<FormTemplate>;
  upsertOneGlobalPetParent?: Maybe<GlobalPetParent>;
  upsertOneHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  upsertOneInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  upsertOneInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  upsertOneInformCampaign?: Maybe<InformCampaign>;
  upsertOneInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  upsertOneInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  upsertOneInformCampaignExecution?: Maybe<InformCampaignExecution>;
  upsertOneInformCampaignGroup?: Maybe<InformCampaignGroup>;
  upsertOneInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  upsertOneInformEmailTemplate?: Maybe<InformEmailTemplate>;
  upsertOneInformEntityHistory?: Maybe<InformEntityHistory>;
  upsertOneInformPartner?: Maybe<InformPartner>;
  upsertOneInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  upsertOneInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  upsertOneInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  upsertOneInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  upsertOneInformPartnerProgram?: Maybe<InformPartnerProgram>;
  upsertOneInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  upsertOneInformPartnerUser?: Maybe<InformPartnerUser>;
  upsertOneInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  upsertOneInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  upsertOneInstinctIntegration?: Maybe<InstinctIntegration>;
  upsertOneInsuranceIntegration?: Maybe<InsuranceIntegration>;
  upsertOneIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  upsertOneInterchangeRate?: Maybe<InterchangeRate>;
  upsertOneInvoice?: Maybe<Invoice>;
  upsertOneInvoiceLineItem?: Maybe<InvoiceLineItem>;
  upsertOneInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  upsertOneLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  upsertOneLegalEntity?: Maybe<LegalEntity>;
  upsertOneLoyaltyAccount?: Maybe<LoyaltyAccount>;
  upsertOneLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  upsertOneLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  upsertOneLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  upsertOneLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  upsertOneLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  upsertOneLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  upsertOneLoyaltyReward?: Maybe<LoyaltyReward>;
  upsertOneLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  upsertOneMassTextAlert?: Maybe<MassTextAlert>;
  upsertOneMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  upsertOneMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  upsertOneMedia?: Maybe<Media>;
  upsertOneMessageTemplate?: Maybe<MessageTemplate>;
  upsertOneMessagingCampaign?: Maybe<MessagingCampaign>;
  upsertOneMessagingPartner?: Maybe<MessagingPartner>;
  upsertOneNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  upsertOneNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  upsertOneOrganization?: Maybe<Organization>;
  upsertOneOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  upsertOneOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  upsertOneOrganizationPet?: Maybe<OrganizationPet>;
  upsertOneOrganizationPetParent?: Maybe<OrganizationPetParent>;
  upsertOneOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  upsertOnePPCIntegration?: Maybe<PpcIntegration>;
  upsertOnePayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  upsertOnePermission?: Maybe<Permission>;
  upsertOnePetParentSetting?: Maybe<PetParentSetting>;
  upsertOnePetPortalSetting?: Maybe<PetPortalSetting>;
  upsertOnePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  upsertOnePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  upsertOnePimsInvoice?: Maybe<PimsInvoice>;
  upsertOnePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  upsertOnePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  upsertOnePrescription?: Maybe<Prescription>;
  upsertOneProduct?: Maybe<Product>;
  upsertOneProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  upsertOnePushNotificationLog?: Maybe<PushNotificationLog>;
  upsertOneRules?: Maybe<Rules>;
  upsertOneSegment?: Maybe<Segment>;
  upsertOneSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  upsertOneService?: Maybe<Service>;
  upsertOneServiceCategory?: Maybe<ServiceCategory>;
  upsertOneServiceReminder?: Maybe<ServiceReminder>;
  upsertOneServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  upsertOneServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  upsertOneSex?: Maybe<Sex>;
  upsertOneSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  upsertOneSpecies?: Maybe<Species>;
  upsertOneStaffRole?: Maybe<StaffRole>;
  upsertOneStripeCustomer?: Maybe<StripeCustomer>;
  upsertOneStripeDispute?: Maybe<StripeDispute>;
  upsertOneStripeInvoice?: Maybe<StripeInvoice>;
  upsertOneStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  upsertOneStripePaymentIntent?: Maybe<StripePaymentIntent>;
  upsertOneStripePaymentMethod?: Maybe<StripePaymentMethod>;
  upsertOneStripePayout?: Maybe<StripePayout>;
  upsertOneStripePayoutAccount?: Maybe<StripePayoutAccount>;
  upsertOneStripeReceipt?: Maybe<StripeReceipt>;
  upsertOneStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  upsertOneStripeRefund?: Maybe<StripeRefund>;
  upsertOneStripeTerminal?: Maybe<StripeTerminal>;
  upsertOneStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  upsertOneSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  upsertOneSurveyResult?: Maybe<SurveyResult>;
  upsertOneSurveySetting?: Maybe<SurveySetting>;
  upsertOneSyncVetIntegration?: Maybe<SyncVetIntegration>;
  upsertOneTransaction?: Maybe<Transaction>;
  upsertOneTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  upsertOneTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  upsertOneTrupanionIntegration?: Maybe<TrupanionIntegration>;
  upsertOneUser?: Maybe<User>;
  upsertOneUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  upsertOneUserBoardFilter?: Maybe<UserBoardFilter>;
  upsertOneUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  upsertOneUserNotificationSetting?: Maybe<UserNotificationSetting>;
  upsertOneUserSetting?: Maybe<UserSetting>;
  upsertOneVaccination?: Maybe<Vaccination>;
  upsertOneVetAvailability?: Maybe<VetAvailability>;
  upsertOneVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  upsertOneVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  upsertOneVetInfo?: Maybe<VetInfo>;
  upsertOneVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  upsertOneVetsourceInstallation?: Maybe<VetsourceInstallation>;
  upsertOneVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  upsertOneWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  upsertUserToClinic: User;
  verifyClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  verifyClinicWidgetRequest2?: Maybe<ClinicWidgetRequest>;
  verifyWidgetAuthenticationRequest?: Maybe<VerifyWidgetAuthenticationRequestResponse>;
};


export type MutationAcceptUserInvitation2Args = {
  data: AcceptUserInvitationInput;
};


export type MutationAddChannelMember2Args = {
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationAddChannelTagArgs = {
  channelId: Scalars['String'];
  tag?: Maybe<TagInput>;
};


export type MutationAddClinicToLoyaltyProgramArgs = {
  clinicId: Scalars['String'];
  programId: Scalars['String'];
};


export type MutationAddEmailCampaignsCareUrlFieldArgs = {
  clinicId: Scalars['String'];
};


export type MutationAddUserToClinicArgs = {
  data: UserCreateInput;
};


export type MutationArchiveLoyaltyProgramArgs = {
  disconnectMemberClinics?: Maybe<Scalars['Boolean']>;
  programId: Scalars['String'];
};


export type MutationBuildSignedLookerUrlArgs = {
  reportId: Scalars['String'];
};


export type MutationBulkPublishWorkflowEventSettingsArgs = {
  data: BulkPublishWorkflowEventSettingsInput;
};


export type MutationCancelCareSubscriptionArgs = {
  data?: Maybe<CancelCareSubscriptionInput>;
};


export type MutationCancelClinicSubscriptionArgs = {
  data: ClinicCancellationInput;
  where: ClinicWhereUniqueInput;
};


export type MutationCancelLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionInput;
};


export type MutationCancelStripePaymentIntent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationChangeCareSubscriptionPaymentMethodArgs = {
  data?: Maybe<ChangeCareSubscriptionPaymentMethodInput>;
};


export type MutationChannelBulkExportToPimsArgs = {
  where: Array<Maybe<ChannelExportWhereInput>>;
};


export type MutationChannelExportToPimsArgs = {
  where: ChannelExportWhereInput;
};


export type MutationClaimClinicPetParentAuthCodeArgs = {
  data: ClaimClinicPetParentAuthCodeRequest;
};


export type MutationClearTerminalDisplayArgs = {
  stripeTerminalId: Scalars['String'];
};


export type MutationClearUnreadMessages2Args = {
  data: ClearUnreadMessagesInput;
};


export type MutationConfigureEmailCampaignsForClinicArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};


export type MutationConfirmAppointment2Args = {
  where?: Maybe<AppointmentWhereUniqueInput>;
};


export type MutationConnectStripeTerminalArgs = {
  displayName: Scalars['String'];
  pairingCode: Scalars['String'];
};


export type MutationCopyOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationCreateCareEnrollmentsAndSubscriptionsArgs = {
  data: CreateCareEnrollmentAndSubscriptionInput;
};


export type MutationCreateCarePaymentMethodSetupArgs = {
  data?: Maybe<CreateCarePaymentMethodSetupInput>;
};


export type MutationCreateChannelFilterSelectionArgs = {
  data?: Maybe<CreateChannelFilterSelectionInput>;
};


export type MutationCreateChannelMessage2Args = {
  data: ChannelMessageCreateInput;
};


export type MutationCreateClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
  skip?: Maybe<Scalars['Int']>;
};


export type MutationCreateClinicWidgetRequest2Args = {
  data: ClinicWidgetRequestCreateInput;
};


export type MutationCreateDirectBookingAppointmentArgs = {
  data?: Maybe<AppointmentCreateInput>;
};


export type MutationCreateEmailTemplateVersionArgs = {
  data: CreateEmailTemplateVersionInput;
};


export type MutationCreateImpersonationArgs = {
  data: IdInput;
};


export type MutationCreateOneActiveIngredientArgs = {
  data: ActiveIngredientCreateInput;
};


export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};


export type MutationCreateOneAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityCreateInput;
};


export type MutationCreateOneAppointmentTypeArgs = {
  data: AppointmentTypeCreateInput;
};


export type MutationCreateOneAutomationRunArgs = {
  data: AutomationRunCreateInput;
};


export type MutationCreateOneAutomationRunActionArgs = {
  data: AutomationRunActionCreateInput;
};


export type MutationCreateOneBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckCreateInput;
};


export type MutationCreateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationCreateInput;
};


export type MutationCreateOneBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultCreateInput;
};


export type MutationCreateOneBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunCreateInput;
};


export type MutationCreateOneBoardFilterColumnArgs = {
  data: BoardFilterColumnCreateInput;
};


export type MutationCreateOneBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeCreateInput;
};


export type MutationCreateOneBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentCreateInput;
};


export type MutationCreateOneBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryCreateInput;
};


export type MutationCreateOneBreedArgs = {
  data: BreedCreateInput;
};


export type MutationCreateOneCallHistoryArgs = {
  data: CallHistoryCreateInput;
};


export type MutationCreateOneCallParticipantArgs = {
  data: CallParticipantCreateInput;
};


export type MutationCreateOneCallRecordingArgs = {
  callSid: Scalars['String'];
};


export type MutationCreateOneCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignCreateInput;
};


export type MutationCreateOneCanonicalBreedArgs = {
  data: CanonicalBreedCreateInput;
};


export type MutationCreateOneCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileCreateInput;
};


export type MutationCreateOneCanonicalServiceArgs = {
  data: CanonicalServiceCreateInput;
};


export type MutationCreateOneCanonicalSpeciesArgs = {
  data: CanonicalSpeciesCreateInput;
};


export type MutationCreateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentCreateInput;
};


export type MutationCreateOneCareAddonPackageArgs = {
  data: CareAddonPackageCreateInput;
};


export type MutationCreateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitCreateInput;
};


export type MutationCreateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestCreateInput;
};


export type MutationCreateOneCareBenefitArgs = {
  data: CareBenefitCreateInput;
};


export type MutationCreateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageCreateInput;
};


export type MutationCreateOneCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupCreateInput;
};


export type MutationCreateOneCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepCreateInput;
};


export type MutationCreateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemCreateInput;
};


export type MutationCreateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentCreateInput;
};


export type MutationCreateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemCreateInput;
};


export type MutationCreateOneCareLapsedPaymentArgs = {
  data: CareLapsedPaymentCreateInput;
};


export type MutationCreateOneCarePlanArgs = {
  data: CarePlanCreateInput;
};


export type MutationCreateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitCreateInput;
};


export type MutationCreateOneCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverCreateInput;
};


export type MutationCreateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentCreateInput;
};


export type MutationCreateOneCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupCreateInput;
};


export type MutationCreateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionCreateInput;
};


export type MutationCreateOneCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpCreateInput;
};


export type MutationCreateOneCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryCreateInput;
};


export type MutationCreateOneChannelArgs = {
  data: ChannelCreateInput;
};


export type MutationCreateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusCreateInput;
};


export type MutationCreateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceCreateInput;
};


export type MutationCreateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionCreateInput;
};


export type MutationCreateOneChannelMemberArgs = {
  data: ChannelMemberCreateInput;
};


export type MutationCreateOneChannelMessageArgs = {
  data: ChannelMessageCreateInput;
};


export type MutationCreateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentCreateInput;
};


export type MutationCreateOneChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseCreateInput;
};


export type MutationCreateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionCreateInput;
};


export type MutationCreateOneChannelPinArgs = {
  data: ChannelPinCreateInput;
};


export type MutationCreateOneChannelSelectionArgs = {
  data: ChannelSelectionCreateInput;
};


export type MutationCreateOneChannelStatusArgs = {
  data: ChannelStatusCreateInput;
};


export type MutationCreateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeCreateInput;
};


export type MutationCreateOneChemicalCompoundArgs = {
  data: ChemicalCompoundCreateInput;
};


export type MutationCreateOneClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationCreateInput;
};


export type MutationCreateOneClinicArgs = {
  data: ClinicCreateInput;
};


export type MutationCreateOneClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberCreateInput;
};


export type MutationCreateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingCreateInput;
};


export type MutationCreateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingCreateInput;
};


export type MutationCreateOneClinicEmployeeArgs = {
  data: ClinicEmployeeCreateInput;
};


export type MutationCreateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingCreateInput;
};


export type MutationCreateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberCreateInput;
};


export type MutationCreateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourCreateInput;
};


export type MutationCreateOneClinicOnboardingArgs = {
  data: ClinicOnboardingCreateInput;
};


export type MutationCreateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationCreateInput;
};


export type MutationCreateOneClinicPetArgs = {
  data: ClinicPetCreateInput;
};


export type MutationCreateOneClinicPetAlertArgs = {
  data: ClinicPetAlertCreateInput;
};


export type MutationCreateOneClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueCreateInput;
};


export type MutationCreateOneClinicPetParentArgs = {
  data: ClinicPetParentCreateInput;
};


export type MutationCreateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressCreateInput;
};


export type MutationCreateOneClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueCreateInput;
};


export type MutationCreateOneClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceCreateInput;
};


export type MutationCreateOneClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusCreateInput;
};


export type MutationCreateOneClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusCreateInput;
};


export type MutationCreateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberCreateInput;
};


export type MutationCreateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationCreateInput;
};


export type MutationCreateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityCreateInput;
};


export type MutationCreateOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryCreateInput;
};


export type MutationCreateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingCreateInput;
};


export type MutationCreateOneClinicRoomArgs = {
  data: ClinicRoomCreateInput;
};


export type MutationCreateOneClinicSettingArgs = {
  data: ClinicSettingCreateInput;
};


export type MutationCreateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestCreateInput;
};


export type MutationCreateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeCreateInput;
};


export type MutationCreateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingCreateInput;
};


export type MutationCreateOneColorArgs = {
  data: ColorCreateInput;
};


export type MutationCreateOneConditionArgs = {
  data: ConditionCreateInput;
};


export type MutationCreateOneConditionSetArgs = {
  data: ConditionSetCreateInput;
};


export type MutationCreateOneConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationCreateInput;
};


export type MutationCreateOneConsultationRequestArgs = {
  data: ConsultationRequestCreateInput;
};


export type MutationCreateOneCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionCreateInput;
};


export type MutationCreateOneCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryCreateInput;
};


export type MutationCreateOneDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingCreateInput;
};


export type MutationCreateOneDirectBookingExclusionRuleArgs = {
  data: DirectBookingExclusionRuleCreateInput;
};


export type MutationCreateOneDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotCreateInput;
};


export type MutationCreateOneDisbursementArgs = {
  data: DisbursementCreateInput;
};


export type MutationCreateOneDocumentationArgs = {
  data: DocumentationCreateInput;
};


export type MutationCreateOneEmailCampaignArgs = {
  data: EmailCampaignCreateInput;
};


export type MutationCreateOneEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListCreateInput;
};


export type MutationCreateOneEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientCreateInput;
};


export type MutationCreateOneEmailCampaignCustomListUploadHistoryArgs = {
  data: EmailCampaignCustomListUploadHistoryCreateInput;
};


export type MutationCreateOneEmailCampaignLogArgs = {
  data: EmailCampaignLogCreateInput;
};


export type MutationCreateOneEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateCreateInput;
};


export type MutationCreateOneEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeCreateInput;
};


export type MutationCreateOneEmailProviderLogArgs = {
  data: EmailProviderLogCreateInput;
};


export type MutationCreateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsCreateInput;
};


export type MutationCreateOneEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventCreateInput;
};


export type MutationCreateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationCreateInput;
};


export type MutationCreateOneFeatureFlagArgs = {
  data: FeatureFlagCreateInput;
};


export type MutationCreateOneFinancialAdjustmentArgs = {
  data: FinancialAdjustmentCreateInput;
};


export type MutationCreateOneFinancialTransactionArgs = {
  data: FinancialTransactionCreateInput;
};


export type MutationCreateOneFormSubmissionArgs = {
  data: FormSubmissionCreateInput;
};


export type MutationCreateOneFormTemplateArgs = {
  data: FormTemplateCreateInput;
};


export type MutationCreateOneGlobalPetParentArgs = {
  data: GlobalPetParentCreateInput;
};


export type MutationCreateOneHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogCreateInput;
};


export type MutationCreateOneInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionCreateInput;
};


export type MutationCreateOneInformAutomationCampaignArgs = {
  data: InformAutomationCampaignCreateInput;
};


export type MutationCreateOneInformCampaignArgs = {
  data: InformCampaignCreateInput;
};


export type MutationCreateOneInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentCreateInput;
};


export type MutationCreateOneInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceCreateInput;
};


export type MutationCreateOneInformCampaignExecutionArgs = {
  data: InformCampaignExecutionCreateInput;
};


export type MutationCreateOneInformCampaignGroupArgs = {
  data: InformCampaignGroupCreateInput;
};


export type MutationCreateOneInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantCreateInput;
};


export type MutationCreateOneInformEmailTemplateArgs = {
  data: InformEmailTemplateCreateInput;
};


export type MutationCreateOneInformEntityHistoryArgs = {
  data: InformEntityHistoryCreateInput;
};


export type MutationCreateOneInformPartnerArgs = {
  data: InformPartnerCreateInput;
};


export type MutationCreateOneInformPartnerCustomerArgs = {
  data: InformPartnerCustomerCreateInput;
};


export type MutationCreateOneInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderCreateInput;
};


export type MutationCreateOneInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationCreateInput;
};


export type MutationCreateOneInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelCreateInput;
};


export type MutationCreateOneInformPartnerProgramArgs = {
  data: InformPartnerProgramCreateInput;
};


export type MutationCreateOneInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentCreateInput;
};


export type MutationCreateOneInformPartnerUserArgs = {
  data: InformPartnerUserCreateInput;
};


export type MutationCreateOneInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleCreateInput;
};


export type MutationCreateOneInformScheduledCampaignArgs = {
  data: InformScheduledCampaignCreateInput;
};


export type MutationCreateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationCreateInput;
};


export type MutationCreateOneInsuranceIntegrationArgs = {
  data: InsuranceIntegrationCreateInput;
};


export type MutationCreateOneIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryCreateInput;
};


export type MutationCreateOneInterchangeRateArgs = {
  data: InterchangeRateCreateInput;
};


export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemCreateInput;
};


export type MutationCreateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryCreateInput;
};


export type MutationCreateOneLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersCreateInput;
};


export type MutationCreateOneLegalEntityArgs = {
  data: LegalEntityCreateInput;
};


export type MutationCreateOneLoyaltyAccountArgs = {
  data: LoyaltyAccountCreateInput;
};


export type MutationCreateOneLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderCreateInput;
};


export type MutationCreateOneLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerCreateInput;
};


export type MutationCreateOneLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterCreateInput;
};


export type MutationCreateOneLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceCreateInput;
};


export type MutationCreateOneLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryCreateInput;
};


export type MutationCreateOneLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantCreateInput;
};


export type MutationCreateOneLoyaltyRewardArgs = {
  data: LoyaltyRewardCreateInput;
};


export type MutationCreateOneLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionCreateInput;
};


export type MutationCreateOneMassTextAlertArgs = {
  data: MassTextAlertCreateInput;
};


export type MutationCreateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryCreateInput;
};


export type MutationCreateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentCreateInput;
};


export type MutationCreateOneMediaArgs = {
  data: MediaCreateInput;
};


export type MutationCreateOneMessageTemplateArgs = {
  data: MessageTemplateCreateInput;
};


export type MutationCreateOneMessagingCampaignArgs = {
  data: MessagingCampaignCreateInput;
};


export type MutationCreateOneMessagingPartnerArgs = {
  data: MessagingPartnerCreateInput;
};


export type MutationCreateOneNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanCreateInput;
};


export type MutationCreateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageCreateInput;
};


export type MutationCreateOneOrganizationArgs = {
  data: OrganizationCreateInput;
};


export type MutationCreateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceCreateInput;
};


export type MutationCreateOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryCreateInput;
};


export type MutationCreateOneOrganizationPetArgs = {
  data: OrganizationPetCreateInput;
};


export type MutationCreateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentCreateInput;
};


export type MutationCreateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentCreateInput;
};


export type MutationCreateOnePpcIntegrationArgs = {
  data: PpcIntegrationCreateInput;
};


export type MutationCreateOnePayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodCreateInput;
};


export type MutationCreateOnePermissionArgs = {
  data: PermissionCreateInput;
};


export type MutationCreateOnePetParentSettingArgs = {
  data: PetParentSettingCreateInput;
};


export type MutationCreateOnePetPortalSettingArgs = {
  data: PetPortalSettingCreateInput;
};


export type MutationCreateOnePhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestCreateInput;
};


export type MutationCreateOnePhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultCreateInput;
};


export type MutationCreateOnePimsInvoiceArgs = {
  data: PimsInvoiceCreateInput;
};


export type MutationCreateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemCreateInput;
};


export type MutationCreateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryCreateInput;
};


export type MutationCreateOnePrescriptionArgs = {
  data: PrescriptionCreateInput;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateOneProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapCreateInput;
};


export type MutationCreateOneProviderAppointmentTypeRoomMapByNameArgs = {
  data: DirectBookingCreateOneProviderAppointmentTypeRoomMapByNameInput;
};


export type MutationCreateOnePushNotificationLogArgs = {
  data: PushNotificationLogCreateInput;
};


export type MutationCreateOneRulesArgs = {
  data: RulesCreateInput;
};


export type MutationCreateOneSegmentArgs = {
  data: SegmentCreateInput;
};


export type MutationCreateOneSentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferCreateInput;
};


export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationCreateOneServiceCategoryArgs = {
  data: ServiceCategoryCreateInput;
};


export type MutationCreateOneServiceReminderArgs = {
  data: ServiceReminderCreateInput;
};


export type MutationCreateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationCreateInput;
};


export type MutationCreateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingCreateInput;
};


export type MutationCreateOneSexArgs = {
  data: SexCreateInput;
};


export type MutationCreateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusCreateInput;
};


export type MutationCreateOneSpeciesArgs = {
  data: SpeciesCreateInput;
};


export type MutationCreateOneStaffRoleArgs = {
  data: StaffRoleCreateInput;
};


export type MutationCreateOneStripeCustomerArgs = {
  data: StripeCustomerCreateInput;
};


export type MutationCreateOneStripeDisputeArgs = {
  data: StripeDisputeCreateInput;
};


export type MutationCreateOneStripeInvoiceArgs = {
  data: StripeInvoiceCreateInput;
};


export type MutationCreateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemCreateInput;
};


export type MutationCreateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentCreateInput;
};


export type MutationCreateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodCreateInput;
};


export type MutationCreateOneStripePayoutArgs = {
  data: StripePayoutCreateInput;
};


export type MutationCreateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountCreateInput;
};


export type MutationCreateOneStripeReceiptArgs = {
  data: StripeReceiptCreateInput;
};


export type MutationCreateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryCreateInput;
};


export type MutationCreateOneStripeRefundArgs = {
  data: StripeRefundCreateInput;
};


export type MutationCreateOneStripeTerminalArgs = {
  data: StripeTerminalCreateInput;
};


export type MutationCreateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationCreateInput;
};


export type MutationCreateOneSurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingCreateInput;
};


export type MutationCreateOneSurveyResultArgs = {
  data: SurveyResultCreateInput;
};


export type MutationCreateOneSurveySettingArgs = {
  data: SurveySettingCreateInput;
};


export type MutationCreateOneSyncVetIntegrationArgs = {
  data: SyncVetIntegrationCreateInput;
};


export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};


export type MutationCreateOneTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityCreateInput;
};


export type MutationCreateOneTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeCreateInput;
};


export type MutationCreateOneTrupanionIntegrationArgs = {
  data: TrupanionIntegrationCreateInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingCreateInput;
};


export type MutationCreateOneUserBoardFilterArgs = {
  data: UserBoardFilterCreateInput;
};


export type MutationCreateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionCreateInput;
};


export type MutationCreateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingCreateInput;
};


export type MutationCreateOneUserSettingArgs = {
  data: UserSettingCreateInput;
};


export type MutationCreateOneVaccinationArgs = {
  data: VaccinationCreateInput;
};


export type MutationCreateOneVetAvailabilityArgs = {
  data: VetAvailabilityCreateInput;
};


export type MutationCreateOneVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapCreateInput;
};


export type MutationCreateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapCreateInput;
};


export type MutationCreateOneVetInfoArgs = {
  data: VetInfoCreateInput;
};


export type MutationCreateOneVetsourceCodeTagArgs = {
  data: VetsourceCodeTagCreateInput;
};


export type MutationCreateOneVetsourceInstallationArgs = {
  data: VetsourceInstallationCreateInput;
};


export type MutationCreateOneVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryCreateInput;
};


export type MutationCreateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingCreateInput;
};


export type MutationCreateStripePaymentIntent2Args = {
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  data: StripeCreatePaymentInput;
  emailOverride?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  stripeTerminalStripeId?: Maybe<Scalars['String']>;
};


export type MutationCreateStripeTerminalLocationArgs = {
  displayName: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
};


export type MutationCreateSystemChannelMessage2Args = {
  data: SystemMessageCreateInput;
};


export type MutationCreateTeamChannel2Args = {
  data: CreateTeamChannelInput;
};


export type MutationCreateTerminalAppPaymentIntentArgs = {
  cardType?: Maybe<CardFunding>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  data: StripeCreatePaymentInput;
  emailOverride?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};


export type MutationCreateTwilioTokenArgs = {
  data: CreateTwilioTokenInput;
};


export type MutationCreateWorkflowEventSettingAndConditionsArgs = {
  data: CreateWorkflowEventSettingAndConditionsInput;
};


export type MutationCustomUpsertOneUserBoardFilterArgs = {
  data: CustomUpsertOneUserBoardFilterDataInput;
  where: CustomUpsertOneUserBoardFilterWhereInput;
};


export type MutationDeactivateLoyaltyAccountArgs = {
  loyaltyAccountId: Scalars['String'];
};


export type MutationDeleteChannelFilterSelectionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEmailCampaignCustomListArgs = {
  data: DeleteEmailCampaignCustomListInput;
};


export type MutationDeleteManyActiveIngredientArgs = {
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type MutationDeleteManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
};


export type MutationDeleteManyAppointmentAvailabilityArgs = {
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type MutationDeleteManyAppointmentTypeArgs = {
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type MutationDeleteManyAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereInput>;
};


export type MutationDeleteManyAutomationRunActionArgs = {
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type MutationDeleteManyBitwerxHealthcheckArgs = {
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type MutationDeleteManyBitwerxIntegrationArgs = {
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type MutationDeleteManyBitwerxPaymentHealthcheckResultArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type MutationDeleteManyBitwerxPaymentHealthcheckRunArgs = {
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type MutationDeleteManyBoardFilterColumnArgs = {
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type MutationDeleteManyBoardFilterSpecificDateRangeArgs = {
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type MutationDeleteManyBoehringerIngelheimCustomerAlignmentArgs = {
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type MutationDeleteManyBoehringerIngelheimTerritoryArgs = {
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type MutationDeleteManyBreedArgs = {
  where?: Maybe<BreedWhereInput>;
};


export type MutationDeleteManyCallHistoryArgs = {
  where?: Maybe<CallHistoryWhereInput>;
};


export type MutationDeleteManyCallParticipantArgs = {
  where?: Maybe<CallParticipantWhereInput>;
};


export type MutationDeleteManyCampaignRegistryCampaignArgs = {
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type MutationDeleteManyCanonicalBreedArgs = {
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type MutationDeleteManyCanonicalCanineBreedProfileArgs = {
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type MutationDeleteManyCanonicalServiceArgs = {
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type MutationDeleteManyCanonicalSpeciesArgs = {
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type MutationDeleteManyCareAddonEnrollmentArgs = {
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type MutationDeleteManyCareAddonPackageArgs = {
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type MutationDeleteManyCareAddonPackageBenefitArgs = {
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type MutationDeleteManyCareAuthorizationRequestArgs = {
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type MutationDeleteManyCareBenefitArgs = {
  where?: Maybe<CareBenefitWhereInput>;
};


export type MutationDeleteManyCareBenefitUsageArgs = {
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type MutationDeleteManyCareBenefitUsageFollowupArgs = {
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type MutationDeleteManyCareBenefitUsageFollowupStepArgs = {
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type MutationDeleteManyCareBenefitUsageToInvoiceLineItemArgs = {
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type MutationDeleteManyCareEnrollmentPaymentArgs = {
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type MutationDeleteManyCareEnrollmentToStripeInvoiceItemArgs = {
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type MutationDeleteManyCareLapsedPaymentArgs = {
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type MutationDeleteManyCarePlanArgs = {
  where?: Maybe<CarePlanWhereInput>;
};


export type MutationDeleteManyCarePlanBenefitArgs = {
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type MutationDeleteManyCarePlanBenefitEnrollmentCarryoverArgs = {
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type MutationDeleteManyCarePlanEnrollmentArgs = {
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type MutationDeleteManyCarePlanProviderGroupArgs = {
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type MutationDeleteManyCareStripeSubscriptionArgs = {
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type MutationDeleteManyCareSubscriptionTrueUpArgs = {
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type MutationDeleteManyCareSubscriptionTrueUpLogEntryArgs = {
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type MutationDeleteManyChannelArgs = {
  where?: Maybe<ChannelWhereInput>;
};


export type MutationDeleteManyChannelAutomationStatusArgs = {
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type MutationDeleteManyChannelCreationSourceArgs = {
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type MutationDeleteManyChannelFilterSelectionArgs = {
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type MutationDeleteManyChannelMemberArgs = {
  where?: Maybe<ChannelMemberWhereInput>;
};


export type MutationDeleteManyChannelMessageArgs = {
  where?: Maybe<ChannelMessageWhereInput>;
};


export type MutationDeleteManyChannelMessageAttachmentArgs = {
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type MutationDeleteManyChannelMessageAutoResponseArgs = {
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type MutationDeleteManyChannelMessageReactionArgs = {
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type MutationDeleteManyChannelPinArgs = {
  where?: Maybe<ChannelPinWhereInput>;
};


export type MutationDeleteManyChannelSelectionArgs = {
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type MutationDeleteManyChannelStatusArgs = {
  where?: Maybe<ChannelStatusWhereInput>;
};


export type MutationDeleteManyChannelStatusChannelAssigneeArgs = {
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type MutationDeleteManyChemicalCompoundArgs = {
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type MutationDeleteManyClientConnectIntegrationArgs = {
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type MutationDeleteManyClinicArgs = {
  where?: Maybe<ClinicWhereInput>;
};


export type MutationDeleteManyClinicBlockedPhoneNumberArgs = {
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type MutationDeleteManyClinicDirectBookingSettingArgs = {
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type MutationDeleteManyClinicEmailCampaignSettingArgs = {
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type MutationDeleteManyClinicEmployeeArgs = {
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type MutationDeleteManyClinicEmployeeAppointmentTypeSettingArgs = {
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type MutationDeleteManyClinicEntityPhoneNumberArgs = {
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type MutationDeleteManyClinicOfficeHourArgs = {
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type MutationDeleteManyClinicOnboardingArgs = {
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type MutationDeleteManyClinicPaymentFeeConfigurationArgs = {
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type MutationDeleteManyClinicPetArgs = {
  where?: Maybe<ClinicPetWhereInput>;
};


export type MutationDeleteManyClinicPetAlertArgs = {
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type MutationDeleteManyClinicPetCustomFieldValueArgs = {
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type MutationDeleteManyClinicPetParentArgs = {
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type MutationDeleteManyClinicPetParentAddressArgs = {
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type MutationDeleteManyClinicPetParentCustomFieldValueArgs = {
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type MutationDeleteManyClinicPetParentDeviceArgs = {
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type MutationDeleteManyClinicPetParentPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type MutationDeleteManyClinicPetPimsSyncStatusArgs = {
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type MutationDeleteManyClinicPhoneNumberArgs = {
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type MutationDeleteManyClinicPimsIntegrationArgs = {
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type MutationDeleteManyClinicPimsIntegrationCapabilityArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type MutationDeleteManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type MutationDeleteManyClinicPostcardSettingArgs = {
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type MutationDeleteManyClinicRoomArgs = {
  where?: Maybe<ClinicRoomWhereInput>;
};


export type MutationDeleteManyClinicSettingArgs = {
  where?: Maybe<ClinicSettingWhereInput>;
};


export type MutationDeleteManyClinicWidgetRequestArgs = {
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type MutationDeleteManyClinicWidgetRequestTypeArgs = {
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type MutationDeleteManyClinicWidgetSettingArgs = {
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type MutationDeleteManyColorArgs = {
  where?: Maybe<ColorWhereInput>;
};


export type MutationDeleteManyConditionArgs = {
  where?: Maybe<ConditionWhereInput>;
};


export type MutationDeleteManyConditionSetArgs = {
  where?: Maybe<ConditionSetWhereInput>;
};


export type MutationDeleteManyConditionalEntityEvaluationArgs = {
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type MutationDeleteManyConsultationRequestArgs = {
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type MutationDeleteManyCustomFieldDefinitionArgs = {
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type MutationDeleteManyCustomFieldPimsWritebackLogEntryArgs = {
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type MutationDeleteManyDirectBookingAppointmentTypeSettingArgs = {
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type MutationDeleteManyDirectBookingExclusionTimeSlotArgs = {
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type MutationDeleteManyDisbursementArgs = {
  where?: Maybe<DisbursementWhereInput>;
};


export type MutationDeleteManyDocumentationArgs = {
  where?: Maybe<DocumentationWhereInput>;
};


export type MutationDeleteManyEmailCampaignArgs = {
  where?: Maybe<EmailCampaignWhereInput>;
};


export type MutationDeleteManyEmailCampaignCustomListArgs = {
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type MutationDeleteManyEmailCampaignCustomListRecipientArgs = {
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type MutationDeleteManyEmailCampaignCustomListUploadHistoryArgs = {
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type MutationDeleteManyEmailCampaignLogArgs = {
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type MutationDeleteManyEmailCampaignTemplateArgs = {
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type MutationDeleteManyEmailCampaignUnsubscribeArgs = {
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type MutationDeleteManyEmailProviderLogArgs = {
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type MutationDeleteManyEmergencyClinicsArgs = {
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type MutationDeleteManyEnrollmentSupportEventArgs = {
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type MutationDeleteManyEzyVetIntegrationArgs = {
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type MutationDeleteManyFeatureFlagArgs = {
  where?: Maybe<FeatureFlagWhereInput>;
};


export type MutationDeleteManyFinancialAdjustmentArgs = {
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type MutationDeleteManyFinancialTransactionArgs = {
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type MutationDeleteManyFormSubmissionArgs = {
  where?: Maybe<FormSubmissionWhereInput>;
};


export type MutationDeleteManyFormTemplateArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};


export type MutationDeleteManyGlobalPetParentArgs = {
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type MutationDeleteManyHillsToHomeApiLogArgs = {
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type MutationDeleteManyInformAudienceDefinitionArgs = {
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type MutationDeleteManyInformAutomationCampaignArgs = {
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type MutationDeleteManyInformCampaignArgs = {
  where?: Maybe<InformCampaignWhereInput>;
};


export type MutationDeleteManyInformCampaignEnrollmentArgs = {
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type MutationDeleteManyInformCampaignEnrollmentCanonicalServiceArgs = {
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type MutationDeleteManyInformCampaignExecutionArgs = {
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type MutationDeleteManyInformCampaignGroupArgs = {
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type MutationDeleteManyInformControlGroupParticipantArgs = {
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type MutationDeleteManyInformEmailTemplateArgs = {
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type MutationDeleteManyInformEntityHistoryArgs = {
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type MutationDeleteManyInformPartnerArgs = {
  where?: Maybe<InformPartnerWhereInput>;
};


export type MutationDeleteManyInformPartnerCustomerArgs = {
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type MutationDeleteManyInformPartnerCustomerPharmacyProviderArgs = {
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type MutationDeleteManyInformPartnerIntegrationArgs = {
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type MutationDeleteManyInformPartnerIntegrationModelArgs = {
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type MutationDeleteManyInformPartnerProgramArgs = {
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type MutationDeleteManyInformPartnerProgramEnrollmentArgs = {
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type MutationDeleteManyInformPartnerUserArgs = {
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type MutationDeleteManyInformPartnerUserRoleArgs = {
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type MutationDeleteManyInformScheduledCampaignArgs = {
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type MutationDeleteManyInstinctIntegrationArgs = {
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type MutationDeleteManyInsuranceIntegrationArgs = {
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type MutationDeleteManyIntegrationOutageHistoryEntryArgs = {
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type MutationDeleteManyInterchangeRateArgs = {
  where?: Maybe<InterchangeRateWhereInput>;
};


export type MutationDeleteManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};


export type MutationDeleteManyInvoiceLineItemArgs = {
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type MutationDeleteManyInvoicePimsWritebackLogEntryArgs = {
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type MutationDeleteManyLapsedPetParentTriggersArgs = {
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type MutationDeleteManyLoyaltyAccountArgs = {
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type MutationDeleteManyLoyaltyAccountHolderArgs = {
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type MutationDeleteManyLoyaltyAccountMergerArgs = {
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type MutationDeleteManyLoyaltyInvoicePointGranterArgs = {
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type MutationDeleteManyLoyaltyPointGrantingServiceArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type MutationDeleteManyLoyaltyPointGrantingServiceCategoryArgs = {
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type MutationDeleteManyLoyaltyPointUserGrantArgs = {
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type MutationDeleteManyLoyaltyRewardRedemptionArgs = {
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type MutationDeleteManyMassTextAlertArgs = {
  where?: Maybe<MassTextAlertWhereInput>;
};


export type MutationDeleteManyMassTextAlertEntryArgs = {
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type MutationDeleteManyMassTextAlertEntryAppointmentArgs = {
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type MutationDeleteManyMediaArgs = {
  where?: Maybe<MediaWhereInput>;
};


export type MutationDeleteManyMessageTemplateArgs = {
  where?: Maybe<MessageTemplateWhereInput>;
};


export type MutationDeleteManyMessagingCampaignArgs = {
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type MutationDeleteManyMessagingPartnerArgs = {
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type MutationDeleteManyNonPrismaSchemaScriptRanArgs = {
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type MutationDeleteManyNotificationEventToMessageArgs = {
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type MutationDeleteManyOrganizationArgs = {
  where?: Maybe<OrganizationWhereInput>;
};


export type MutationDeleteManyOrganizationCareBenefitToClinicServiceArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type MutationDeleteManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type MutationDeleteManyOrganizationPetArgs = {
  where?: Maybe<OrganizationPetWhereInput>;
};


export type MutationDeleteManyOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type MutationDeleteManyOrganizationPetToOrganizationPetParentArgs = {
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type MutationDeleteManyPpcIntegrationArgs = {
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type MutationDeleteManyPermissionArgs = {
  where?: Maybe<PermissionWhereInput>;
};


export type MutationDeleteManyPetParentSettingArgs = {
  where?: Maybe<PetParentSettingWhereInput>;
};


export type MutationDeleteManyPetPortalSettingArgs = {
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type MutationDeleteManyPhoneNumberLookupRequestArgs = {
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type MutationDeleteManyPhoneNumberLookupResultArgs = {
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type MutationDeleteManyPimsInvoiceArgs = {
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type MutationDeleteManyPimsInvoiceLineItemArgs = {
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type MutationDeleteManyPimsWritebackLogEntryArgs = {
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type MutationDeleteManyPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
};


export type MutationDeleteManyProductArgs = {
  where?: Maybe<ProductWhereInput>;
};


export type MutationDeleteManyProviderAppointmentTypeRoomMapArgs = {
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type MutationDeleteManyPushNotificationLogArgs = {
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type MutationDeleteManyRulesArgs = {
  where?: Maybe<RulesWhereInput>;
};


export type MutationDeleteManySegmentArgs = {
  where?: Maybe<SegmentWhereInput>;
};


export type MutationDeleteManySentTrupanionExamDayOfferArgs = {
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type MutationDeleteManyServiceArgs = {
  where?: Maybe<ServiceWhereInput>;
};


export type MutationDeleteManyServiceCategoryArgs = {
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type MutationDeleteManyServiceReminderArgs = {
  where?: Maybe<ServiceReminderWhereInput>;
};


export type MutationDeleteManyServiceReminderNotificationArgs = {
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type MutationDeleteManyServiceReminderTimingArgs = {
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type MutationDeleteManySexArgs = {
  where?: Maybe<SexWhereInput>;
};


export type MutationDeleteManySmsNotificationStatusArgs = {
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type MutationDeleteManySpeciesArgs = {
  where?: Maybe<SpeciesWhereInput>;
};


export type MutationDeleteManyStaffRoleArgs = {
  where?: Maybe<StaffRoleWhereInput>;
};


export type MutationDeleteManyStripeCustomerArgs = {
  where?: Maybe<StripeCustomerWhereInput>;
};


export type MutationDeleteManyStripeDisputeArgs = {
  where?: Maybe<StripeDisputeWhereInput>;
};


export type MutationDeleteManyStripeInvoiceArgs = {
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type MutationDeleteManyStripeInvoiceItemArgs = {
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type MutationDeleteManyStripePaymentMethodArgs = {
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type MutationDeleteManyStripePayoutArgs = {
  where?: Maybe<StripePayoutWhereInput>;
};


export type MutationDeleteManyStripePayoutAccountArgs = {
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type MutationDeleteManyStripeReceiptArgs = {
  where?: Maybe<StripeReceiptWhereInput>;
};


export type MutationDeleteManyStripeReceiptHistoryArgs = {
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type MutationDeleteManyStripeRefundArgs = {
  where?: Maybe<StripeRefundWhereInput>;
};


export type MutationDeleteManyStripeTerminalArgs = {
  where?: Maybe<StripeTerminalWhereInput>;
};


export type MutationDeleteManyStripeTerminalLocationArgs = {
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type MutationDeleteManySurveyAppointmentTypeSettingArgs = {
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type MutationDeleteManySurveyResultArgs = {
  where?: Maybe<SurveyResultWhereInput>;
};


export type MutationDeleteManySurveySettingArgs = {
  where?: Maybe<SurveySettingWhereInput>;
};


export type MutationDeleteManySyncVetIntegrationArgs = {
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type MutationDeleteManyTransactionArgs = {
  where?: Maybe<TransactionWhereInput>;
};


export type MutationDeleteManyTrupanionCertificateAvailabilityArgs = {
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type MutationDeleteManyTrupanionIntegratedAppointmentTypeArgs = {
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type MutationDeleteManyTrupanionIntegrationArgs = {
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationDeleteManyUserAppointmentGroupingArgs = {
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type MutationDeleteManyUserBoardFilterArgs = {
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type MutationDeleteManyUserChannelFilterSelectionArgs = {
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type MutationDeleteManyUserNotificationSettingArgs = {
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type MutationDeleteManyUserSettingArgs = {
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationDeleteManyVaccinationArgs = {
  where?: Maybe<VaccinationWhereInput>;
};


export type MutationDeleteManyVetAvailabilityArgs = {
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type MutationDeleteManyVetAvailabilityClinicRoomMapArgs = {
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type MutationDeleteManyVetAvailabilityMapArgs = {
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type MutationDeleteManyVetInfoArgs = {
  where?: Maybe<VetInfoWhereInput>;
};


export type MutationDeleteManyVetsourceCodeTagArgs = {
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type MutationDeleteManyVetsourceInstallationArgs = {
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type MutationDeleteManyVetsourceRevenueCategoryArgs = {
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type MutationDeleteManyWorkflowEventSettingArgs = {
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type MutationDeleteOneActiveIngredientArgs = {
  where: ActiveIngredientWhereUniqueInput;
};


export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationDeleteOneAppointmentAvailabilityArgs = {
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};


export type MutationDeleteOneAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};


export type MutationDeleteOneAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};


export type MutationDeleteOneBitwerxHealthcheckArgs = {
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type MutationDeleteOneBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};


export type MutationDeleteOneBitwerxPaymentHealthcheckResultArgs = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type MutationDeleteOneBitwerxPaymentHealthcheckRunArgs = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type MutationDeleteOneBoardFilterColumnArgs = {
  where: BoardFilterColumnWhereUniqueInput;
};


export type MutationDeleteOneBoardFilterSpecificDateRangeArgs = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type MutationDeleteOneBoehringerIngelheimCustomerAlignmentArgs = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type MutationDeleteOneBoehringerIngelheimTerritoryArgs = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type MutationDeleteOneBreedArgs = {
  where: BreedWhereUniqueInput;
};


export type MutationDeleteOneCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};


export type MutationDeleteOneCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};


export type MutationDeleteOneCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};


export type MutationDeleteOneCampaignRegistryCampaignArgs = {
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type MutationDeleteOneCanonicalBreedArgs = {
  where: CanonicalBreedWhereUniqueInput;
};


export type MutationDeleteOneCanonicalCanineBreedProfileArgs = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type MutationDeleteOneCanonicalServiceArgs = {
  where: CanonicalServiceWhereUniqueInput;
};


export type MutationDeleteOneCanonicalSpeciesArgs = {
  where: CanonicalSpeciesWhereUniqueInput;
};


export type MutationDeleteOneCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};


export type MutationDeleteOneCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type MutationDeleteOneCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageFollowupArgs = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageFollowupStepArgs = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type MutationDeleteOneCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOneCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type MutationDeleteOneCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type MutationDeleteOneCareLapsedPaymentArgs = {
  where: CareLapsedPaymentWhereUniqueInput;
};


export type MutationDeleteOneCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};


export type MutationDeleteOneCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};


export type MutationDeleteOneCarePlanBenefitEnrollmentCarryoverArgs = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type MutationDeleteOneCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneCarePlanProviderGroupArgs = {
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type MutationDeleteOneCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type MutationDeleteOneCareSubscriptionTrueUpArgs = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type MutationDeleteOneCareSubscriptionTrueUpLogEntryArgs = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type MutationDeleteOneChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type MutationDeleteOneChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type MutationDeleteOneChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};


export type MutationDeleteOneChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type MutationDeleteOneChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageAutoResponseArgs = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type MutationDeleteOneChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};


export type MutationDeleteOneChannelPinArgs = {
  where: ChannelPinWhereUniqueInput;
};


export type MutationDeleteOneChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};


export type MutationDeleteOneChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};


export type MutationDeleteOneChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type MutationDeleteOneChemicalCompoundArgs = {
  where: ChemicalCompoundWhereUniqueInput;
};


export type MutationDeleteOneClientConnectIntegrationArgs = {
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type MutationDeleteOneClinicArgs = {
  where: ClinicWhereUniqueInput;
};


export type MutationDeleteOneClinicBlockedPhoneNumberArgs = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};


export type MutationDeleteOneClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};


export type MutationDeleteOneClinicOnboardingArgs = {
  where: ClinicOnboardingWhereUniqueInput;
};


export type MutationDeleteOneClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type MutationDeleteOneClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};


export type MutationDeleteOneClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};


export type MutationDeleteOneClinicPetCustomFieldValueArgs = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentCustomFieldValueArgs = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentDeviceArgs = {
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type MutationDeleteOneClinicPetParentPimsSyncStatusArgs = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type MutationDeleteOneClinicPetPimsSyncStatusArgs = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type MutationDeleteOneClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type MutationDeleteOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type MutationDeleteOneClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};


export type MutationDeleteOneClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type MutationDeleteOneClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type MutationDeleteOneColorArgs = {
  where: ColorWhereUniqueInput;
};


export type MutationDeleteOneConditionArgs = {
  where: ConditionWhereUniqueInput;
};


export type MutationDeleteOneConditionSetArgs = {
  where: ConditionSetWhereUniqueInput;
};


export type MutationDeleteOneConditionalEntityEvaluationArgs = {
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type MutationDeleteOneConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};


export type MutationDeleteOneCustomFieldDefinitionArgs = {
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type MutationDeleteOneCustomFieldPimsWritebackLogEntryArgs = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingAppointmentTypeSettingArgs = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingExclusionRuleArgs = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type MutationDeleteOneDirectBookingExclusionTimeSlotArgs = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type MutationDeleteOneDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};


export type MutationDeleteOneDocumentationArgs = {
  where: DocumentationWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignArgs = {
  where: EmailCampaignWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignCustomListArgs = {
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignCustomListRecipientArgs = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignCustomListUploadHistoryArgs = {
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignLogArgs = {
  where: EmailCampaignLogWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignTemplateArgs = {
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type MutationDeleteOneEmailCampaignUnsubscribeArgs = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type MutationDeleteOneEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};


export type MutationDeleteOneEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};


export type MutationDeleteOneEnrollmentSupportEventArgs = {
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type MutationDeleteOneEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};


export type MutationDeleteOneFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};


export type MutationDeleteOneFinancialAdjustmentArgs = {
  where: FinancialAdjustmentWhereUniqueInput;
};


export type MutationDeleteOneFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};


export type MutationDeleteOneFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type MutationDeleteOneFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};


export type MutationDeleteOneGlobalPetParentArgs = {
  where: GlobalPetParentWhereUniqueInput;
};


export type MutationDeleteOneHillsToHomeApiLogArgs = {
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type MutationDeleteOneInformAudienceDefinitionArgs = {
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type MutationDeleteOneInformAutomationCampaignArgs = {
  where: InformAutomationCampaignWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignArgs = {
  where: InformCampaignWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignEnrollmentArgs = {
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignEnrollmentCanonicalServiceArgs = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignExecutionArgs = {
  where: InformCampaignExecutionWhereUniqueInput;
};


export type MutationDeleteOneInformCampaignGroupArgs = {
  where: InformCampaignGroupWhereUniqueInput;
};


export type MutationDeleteOneInformControlGroupParticipantArgs = {
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type MutationDeleteOneInformEmailTemplateArgs = {
  where: InformEmailTemplateWhereUniqueInput;
};


export type MutationDeleteOneInformEntityHistoryArgs = {
  where: InformEntityHistoryWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerArgs = {
  where: InformPartnerWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerCustomerArgs = {
  where: InformPartnerCustomerWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerCustomerPharmacyProviderArgs = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerIntegrationArgs = {
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerIntegrationModelArgs = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerProgramArgs = {
  where: InformPartnerProgramWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerProgramEnrollmentArgs = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerUserArgs = {
  where: InformPartnerUserWhereUniqueInput;
};


export type MutationDeleteOneInformPartnerUserRoleArgs = {
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type MutationDeleteOneInformScheduledCampaignArgs = {
  where: InformScheduledCampaignWhereUniqueInput;
};


export type MutationDeleteOneInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};


export type MutationDeleteOneInsuranceIntegrationArgs = {
  where: InsuranceIntegrationWhereUniqueInput;
};


export type MutationDeleteOneIntegrationOutageHistoryEntryArgs = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type MutationDeleteOneInterchangeRateArgs = {
  where: InterchangeRateWhereUniqueInput;
};


export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationDeleteOneInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOneInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOneLapsedPetParentTriggersArgs = {
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountHolderArgs = {
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyAccountMergerArgs = {
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyInvoicePointGranterArgs = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointGrantingServiceArgs = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointGrantingServiceCategoryArgs = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyPointUserGrantArgs = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type MutationDeleteOneLoyaltyRewardRedemptionArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};


export type MutationDeleteOneMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type MutationDeleteOneMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type MutationDeleteOneMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};


export type MutationDeleteOneMessagingCampaignArgs = {
  where: MessagingCampaignWhereUniqueInput;
};


export type MutationDeleteOneMessagingPartnerArgs = {
  where: MessagingPartnerWhereUniqueInput;
};


export type MutationDeleteOneNonPrismaSchemaScriptRanArgs = {
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type MutationDeleteOneNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};


export type MutationDeleteOneOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type MutationDeleteOneOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type MutationDeleteOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};


export type MutationDeleteOneOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type MutationDeleteOnePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};


export type MutationDeleteOnePermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type MutationDeleteOnePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};


export type MutationDeleteOnePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};


export type MutationDeleteOnePhoneNumberLookupRequestArgs = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type MutationDeleteOnePhoneNumberLookupResultArgs = {
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type MutationDeleteOnePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};


export type MutationDeleteOnePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type MutationDeleteOnePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type MutationDeleteOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteOneProviderAppointmentTypeRoomMapArgs = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type MutationDeleteOnePushNotificationLogArgs = {
  where: PushNotificationLogWhereUniqueInput;
};


export type MutationDeleteOneRulesArgs = {
  where: RulesWhereUniqueInput;
};


export type MutationDeleteOneSegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type MutationDeleteOneSentTrupanionExamDayOfferArgs = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteOneServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type MutationDeleteOneServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};


export type MutationDeleteOneSexArgs = {
  where: SexWhereUniqueInput;
};


export type MutationDeleteOneSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};


export type MutationDeleteOneSpeciesArgs = {
  where: SpeciesWhereUniqueInput;
};


export type MutationDeleteOneStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};


export type MutationDeleteOneStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};


export type MutationDeleteOneStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};


export type MutationDeleteOneStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};


export type MutationDeleteOneStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};


export type MutationDeleteOneStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};


export type MutationDeleteOneStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};


export type MutationDeleteOneStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};


export type MutationDeleteOneStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};


export type MutationDeleteOneStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type MutationDeleteOneStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};


export type MutationDeleteOneStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};


export type MutationDeleteOneStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};


export type MutationDeleteOneSurveyAppointmentTypeSettingArgs = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type MutationDeleteOneSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type MutationDeleteOneSurveySettingArgs = {
  where: SurveySettingWhereUniqueInput;
};


export type MutationDeleteOneSyncVetIntegrationArgs = {
  where: SyncVetIntegrationWhereUniqueInput;
};


export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type MutationDeleteOneTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneTrupanionIntegratedAppointmentTypeArgs = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type MutationDeleteOneTrupanionIntegrationArgs = {
  where: TrupanionIntegrationWhereUniqueInput;
};


export type MutationDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteOneUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type MutationDeleteOneUserBoardFilterArgs = {
  where: UserBoardFilterWhereUniqueInput;
};


export type MutationDeleteOneUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type MutationDeleteOneUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};


export type MutationDeleteOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type MutationDeleteOneVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityClinicRoomMapArgs = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type MutationDeleteOneVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};


export type MutationDeleteOneVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};


export type MutationDeleteOneVetsourceCodeTagArgs = {
  where: VetsourceCodeTagWhereUniqueInput;
};


export type MutationDeleteOneVetsourceInstallationArgs = {
  where: VetsourceInstallationWhereUniqueInput;
};


export type MutationDeleteOneVetsourceRevenueCategoryArgs = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type MutationDeleteOneWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationDuplicateEmailCampaignArgs = {
  data: DuplicateEmailCampaignInput;
};


export type MutationEncryptAndUploadDataArgs = {
  body: Scalars['String'];
  extension: Scalars['String'];
  purpose: EncryptedSignedUploadPurpose;
};


export type MutationEnsurePetsPromotedArgs = {
  data?: Maybe<EnsurePetsPromotedInput>;
};


export type MutationFindOrCreateLoyaltyAccountForClinicPetParentArgs = {
  clinicPetParentId: Scalars['String'];
};


export type MutationFormSubmissionExportToPimsArgs = {
  data?: Maybe<FormSubmissionExportInput>;
};


export type MutationFulfillLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionInput;
};


export type MutationGenerateTerminalAuthTokenArgs = {
  stripeTerminalId: Scalars['String'];
};


export type MutationGenerateTerminalSetupPinArgs = {
  clinicId: Scalars['String'];
};


export type MutationGenerateTinyUrlAndTokenArgs = {
  data: GenerateTinyUrlAndTokenInput;
};


export type MutationGenerateWidgetAuthenticationRequestArgs = {
  where: WidgetClinicPetParentLookupInput;
};


export type MutationGetDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};


export type MutationGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};


export type MutationGetOrCreateActiveClientChannelArgs = {
  creationSource?: Maybe<ChannelCreationSourceInput>;
  data: GetOrCreateActiveClientChannelInput;
};


export type MutationGetSignedDownloadUrlArgs = {
  assetUrl: Scalars['String'];
};


export type MutationGetSignedUploadUrlArgs = {
  data: GetSignedUploadInput;
};


export type MutationInitSurveySettingArgs = {
  clinicId: Scalars['String'];
};


export type MutationMergePetParentArgs = {
  data?: Maybe<MergePetParentInput>;
};


export type MutationOrderPhoneNumberArgs = {
  data: OrderPhoneNumberInput;
};


export type MutationPerformCarePlanEnrollmentUpgradeArgs = {
  data: PerformCarePlanEnrollmentUpgradeInput;
};


export type MutationPostCareInvoiceAccountCreditToPimsArgs = {
  data: WritebackCareInvoiceAccountCreditToPimsInput;
};


export type MutationProcessTerminalPaymentArgs = {
  paymentIntent: PaymentIntentInput;
  stripeTerminalId: Scalars['String'];
};


export type MutationRedeemClinicPetParentAuthCodeArgs = {
  authCode: Scalars['String'];
};


export type MutationRedeemOneTimeCodeArgs = {
  data: RedeemOneTimeCodeInput;
};


export type MutationRefundStripePaymentIntent2Args = {
  data: EnhancedStripeRefundPaymentInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationRemoveChannelMember2Args = {
  channelMemberId: Scalars['String'];
};


export type MutationRemoveChannelTagArgs = {
  channelId: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<TagType>;
};


export type MutationRemoveClinicFromLoyaltyProgramArgs = {
  clinicId: Scalars['String'];
  programId: Scalars['String'];
};


export type MutationRemoveUserFromClinicArgs = {
  data: RemoveUserFromClinicInput;
};


export type MutationRequestBitwerxIntegrationArgs = {
  data: RequestBitwerxIntegrationInput;
};


export type MutationRequestCareLoginLinkArgs = {
  data: AuthLinkRequestInput;
};


export type MutationRequestLoyaltyRewardArgs = {
  data: RequestLoyaltyRewardInput;
};


export type MutationRequestOneTimeCodeArgs = {
  data?: Maybe<RequestOneTimeCodeInput>;
};


export type MutationResendReceiptEmailArgs = {
  emailOverride?: Maybe<Scalars['String']>;
  stripePaymentIntentId: Scalars['String'];
};


export type MutationResendUserInvitationArgs = {
  data: ResendUserInvitationInput;
};


export type MutationResetAutomationRunArgs = {
  where?: Maybe<AutomationRunWhereUniqueInput>;
};


export type MutationRetryPaymentWritebackArgs = {
  stripePaymentIntentId: Scalars['String'];
};


export type MutationSaveEmailTemplateVersionArgs = {
  data: SaveEmailTemplateVersionInput;
};


export type MutationSendCareSignUpEmailArgs = {
  data?: Maybe<SendCareSignUpEmailInput>;
};


export type MutationSendClinicPetParentAuthCodeArgs = {
  data: SendClinicPetParentAuthCodeRequest;
};


export type MutationSendClinicPetParentAuthLinkArgs = {
  data: SendClinicPetParentAuthLinkRequest;
};


export type MutationSendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};


export type MutationSendDirectedCareLoginLinkArgs = {
  data: DirectedAuthLinkInput;
};


export type MutationSendEmailCampaignNowArgs = {
  data: SendEmailCampaignNowInput;
};


export type MutationSendEnrollmentEmailArgs = {
  data?: Maybe<SendEnrollmentEmailInput>;
};


export type MutationSendPaymentToTerminalArgs = {
  stripePaymentIntentId: Scalars['String'];
  stripeTerminalId: Scalars['String'];
};


export type MutationSendUiRefreshEventArgs = {
  data: UiRefreshInput;
};


export type MutationSendWidgetCodeToDeveloperArgs = {
  data: SendWidgetCodeToDeveloperInput;
};


export type MutationSetClinicPetParentPrivacyArgs = {
  clinicPetParentId: Scalars['String'];
};


export type MutationSetPaymentIntentClinicPetParent2Args = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationStripeConnectConnectedAccountArgs = {
  data: StripeConnectOauthInput;
};


export type MutationStripeCreateConnectedAccountArgs = {
  data: StripeCreateAccountInput;
};


export type MutationSubmitForm2Args = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};


export type MutationSubmitLoyaltyPointGrantToAccountByUserArgs = {
  data: LoyaltyPointGrantByUser;
};


export type MutationSubmitPetParentRequestArgs = {
  data: SubmitPetParentRequestInput;
};


export type MutationSubmitPrescriptionRefillRequestArgs = {
  data: SubmitPrescriptionRefillRequestInput;
};


export type MutationSyncClinicArgs = {
  clinicId: Scalars['String'];
};


export type MutationSyncManyPimsIntegrationModelArgs = {
  data: SyncManyPimsIntegrationModelInput;
};


export type MutationSyncPimsIntegrationModelArgs = {
  data: SyncPimsIntegrationModelInput;
};


export type MutationToggleTwilioVideoCallRecordingStatusArgs = {
  data: VideoCallRecordingToggleInput;
};


export type MutationTransferCareSubscriptionArgs = {
  data?: Maybe<TransferSubscriptionInput>;
};


export type MutationTransferTwilioNumberArgs = {
  data: TransferTwilioNumberInput;
};


export type MutationTriggerEmailCampaignsDataSyncArgs = {
  clinicId: Scalars['String'];
};


export type MutationTriggerLoyaltyInvoiceProcessingArgs = {
  data: LoyaltyInvoiceTriggerInput;
};


export type MutationTriggerMessageEventArgs = {
  data: TriggerMessageEventInput;
};


export type MutationTriggerWorkflowEventArgs = {
  data: TriggerWorkflowEventInput;
};


export type MutationTrupanionIssueCertificateArgs = {
  data: TrupanionIssueCertificateDataInput;
  where: TrupanionIssueCertificateWhereInput;
};


export type MutationTwilioVerifyCallerIdArgs = {
  data: TwilioVerifyCallerIdInput;
};


export type MutationUpdateAppointmentTypeProviderRoomMapsArgs = {
  data: UpdateAppointmentTypeProviderRoomMapsInput;
};


export type MutationUpdateCareSubscriptionArgs = {
  data?: Maybe<UpdateCareSubscriptionInput>;
};


export type MutationUpdateChannelFilterSelectionArgs = {
  data?: Maybe<UpdateChannelFilterSelectionInput>;
};


export type MutationUpdateClinicAppointmentTypeSettingsArgs = {
  appointmentTypeId: Scalars['String'];
  displayName: Scalars['String'];
  showInPetPortal: Scalars['Boolean'];
};


export type MutationUpdateClinicPetParentContactInfoFromPimsArgs = {
  where?: Maybe<ClinicPetParentWhereUniqueInput>;
};


export type MutationUpdateClinicPetWithoutPimsArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};


export type MutationUpdateClinicPostcardSettingArgs = {
  data: UpdateClinicPostcardSettingDataInput;
  where?: Maybe<UpdateClinicPostcardSettingWhereInput>;
};


export type MutationUpdateClinicSettingTransactionColumnArgs = {
  data: ClinicSettingUpdateInput;
  where: ClinicSettingWhereUniqueInput;
};


export type MutationUpdateManyActiveIngredientArgs = {
  data: ActiveIngredientUpdateManyMutationInput;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type MutationUpdateManyAppointmentArgs = {
  data: AppointmentUpdateManyMutationInput;
  where?: Maybe<AppointmentWhereInput>;
};


export type MutationUpdateManyAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityUpdateManyMutationInput;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type MutationUpdateManyAppointmentTypeArgs = {
  data: AppointmentTypeUpdateManyMutationInput;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type MutationUpdateManyAppointmentTypeSortOrderArgs = {
  data?: Maybe<Array<AppointmentTypeWithOrder>>;
};


export type MutationUpdateManyAutomationRunArgs = {
  data: AutomationRunUpdateManyMutationInput;
  where?: Maybe<AutomationRunWhereInput>;
};


export type MutationUpdateManyAutomationRunActionArgs = {
  data: AutomationRunActionUpdateManyMutationInput;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type MutationUpdateManyBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckUpdateManyMutationInput;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type MutationUpdateManyBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateManyMutationInput;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type MutationUpdateManyBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultUpdateManyMutationInput;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type MutationUpdateManyBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunUpdateManyMutationInput;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type MutationUpdateManyBoardFilterColumnArgs = {
  data: BoardFilterColumnUpdateManyMutationInput;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type MutationUpdateManyBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeUpdateManyMutationInput;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type MutationUpdateManyBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentUpdateManyMutationInput;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type MutationUpdateManyBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryUpdateManyMutationInput;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type MutationUpdateManyBreedArgs = {
  data: BreedUpdateManyMutationInput;
  where?: Maybe<BreedWhereInput>;
};


export type MutationUpdateManyCallHistoryArgs = {
  data: CallHistoryUpdateManyMutationInput;
  where?: Maybe<CallHistoryWhereInput>;
};


export type MutationUpdateManyCallParticipantArgs = {
  data: CallParticipantUpdateManyMutationInput;
  where?: Maybe<CallParticipantWhereInput>;
};


export type MutationUpdateManyCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignUpdateManyMutationInput;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type MutationUpdateManyCanonicalBreedArgs = {
  data: CanonicalBreedUpdateManyMutationInput;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type MutationUpdateManyCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileUpdateManyMutationInput;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type MutationUpdateManyCanonicalServiceArgs = {
  data: CanonicalServiceUpdateManyMutationInput;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type MutationUpdateManyCanonicalSpeciesArgs = {
  data: CanonicalSpeciesUpdateManyMutationInput;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type MutationUpdateManyCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateManyMutationInput;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type MutationUpdateManyCareAddonPackageArgs = {
  data: CareAddonPackageUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type MutationUpdateManyCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateManyMutationInput;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type MutationUpdateManyCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateManyMutationInput;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type MutationUpdateManyCareBenefitArgs = {
  data: CareBenefitUpdateManyMutationInput;
  where?: Maybe<CareBenefitWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type MutationUpdateManyCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type MutationUpdateManyCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type MutationUpdateManyCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type MutationUpdateManyCareLapsedPaymentArgs = {
  data: CareLapsedPaymentUpdateManyMutationInput;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type MutationUpdateManyCarePlanArgs = {
  data: CarePlanUpdateManyMutationInput;
  where?: Maybe<CarePlanWhereInput>;
};


export type MutationUpdateManyCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateManyMutationInput;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type MutationUpdateManyCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverUpdateManyMutationInput;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type MutationUpdateManyCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateManyMutationInput;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type MutationUpdateManyCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupUpdateManyMutationInput;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type MutationUpdateManyCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateManyMutationInput;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type MutationUpdateManyCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpUpdateManyMutationInput;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type MutationUpdateManyCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryUpdateManyMutationInput;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type MutationUpdateManyChannelArgs = {
  data: ChannelUpdateManyMutationInput;
  where?: Maybe<ChannelWhereInput>;
};


export type MutationUpdateManyChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateManyMutationInput;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type MutationUpdateManyChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateManyMutationInput;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type MutationUpdateManyChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type MutationUpdateManyChannelMemberArgs = {
  data: ChannelMemberUpdateManyMutationInput;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type MutationUpdateManyChannelMessageArgs = {
  data: ChannelMessageUpdateManyMutationInput;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type MutationUpdateManyChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateManyMutationInput;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type MutationUpdateManyChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseUpdateManyMutationInput;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type MutationUpdateManyChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateManyMutationInput;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type MutationUpdateManyChannelPinArgs = {
  data: ChannelPinUpdateManyMutationInput;
  where?: Maybe<ChannelPinWhereInput>;
};


export type MutationUpdateManyChannelSelectionArgs = {
  data: ChannelSelectionUpdateManyMutationInput;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type MutationUpdateManyChannelStatusArgs = {
  data: ChannelStatusUpdateManyMutationInput;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type MutationUpdateManyChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateManyMutationInput;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type MutationUpdateManyChannelStatusSortOrderArgs = {
  data?: Maybe<Array<ChannelStatusWithOrder>>;
};


export type MutationUpdateManyChemicalCompoundArgs = {
  data: ChemicalCompoundUpdateManyMutationInput;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type MutationUpdateManyClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationUpdateManyMutationInput;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type MutationUpdateManyClinicArgs = {
  data: ClinicUpdateManyMutationInput;
  where?: Maybe<ClinicWhereInput>;
};


export type MutationUpdateManyClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateManyMutationInput;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type MutationUpdateManyClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type MutationUpdateManyClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type MutationUpdateManyClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManyClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateManyMutationInput;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type MutationUpdateManyClinicOnboardingArgs = {
  data: ClinicOnboardingUpdateManyMutationInput;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type MutationUpdateManyClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateManyMutationInput;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type MutationUpdateManyClinicPetArgs = {
  data: ClinicPetUpdateManyMutationInput;
  where?: Maybe<ClinicPetWhereInput>;
};


export type MutationUpdateManyClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateManyMutationInput;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type MutationUpdateManyClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueUpdateManyMutationInput;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type MutationUpdateManyClinicPetParentArgs = {
  data: ClinicPetParentUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type MutationUpdateManyClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type MutationUpdateManyClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type MutationUpdateManyClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type MutationUpdateManyClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusUpdateManyMutationInput;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type MutationUpdateManyClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusUpdateManyMutationInput;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type MutationUpdateManyClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateManyMutationInput;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type MutationUpdateManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUpdateManyMutationInput;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type MutationUpdateManyClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateManyMutationInput;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type MutationUpdateManyClinicRoomArgs = {
  data: ClinicRoomUpdateManyMutationInput;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type MutationUpdateManyClinicSettingArgs = {
  data: ClinicSettingUpdateManyMutationInput;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type MutationUpdateManyClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type MutationUpdateManyClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type MutationUpdateManyClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateManyMutationInput;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type MutationUpdateManyColorArgs = {
  data: ColorUpdateManyMutationInput;
  where?: Maybe<ColorWhereInput>;
};


export type MutationUpdateManyConditionArgs = {
  data: ConditionUpdateManyMutationInput;
  where?: Maybe<ConditionWhereInput>;
};


export type MutationUpdateManyConditionSetArgs = {
  data: ConditionSetUpdateManyMutationInput;
  where?: Maybe<ConditionSetWhereInput>;
};


export type MutationUpdateManyConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationUpdateManyMutationInput;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type MutationUpdateManyConsultationRequestArgs = {
  data: ConsultationRequestUpdateManyMutationInput;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type MutationUpdateManyCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionUpdateManyMutationInput;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type MutationUpdateManyCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManyDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotUpdateManyMutationInput;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type MutationUpdateManyDisbursementArgs = {
  data: DisbursementUpdateManyMutationInput;
  where?: Maybe<DisbursementWhereInput>;
};


export type MutationUpdateManyDocumentationArgs = {
  data: DocumentationUpdateManyMutationInput;
  where?: Maybe<DocumentationWhereInput>;
};


export type MutationUpdateManyEmailCampaignArgs = {
  data: EmailCampaignUpdateManyMutationInput;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type MutationUpdateManyEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListUpdateManyMutationInput;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type MutationUpdateManyEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientUpdateManyMutationInput;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type MutationUpdateManyEmailCampaignCustomListUploadHistoryArgs = {
  data: EmailCampaignCustomListUploadHistoryUpdateManyMutationInput;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type MutationUpdateManyEmailCampaignLogArgs = {
  data: EmailCampaignLogUpdateManyMutationInput;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type MutationUpdateManyEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateUpdateManyMutationInput;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type MutationUpdateManyEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeUpdateManyMutationInput;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type MutationUpdateManyEmailProviderLogArgs = {
  data: EmailProviderLogUpdateManyMutationInput;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type MutationUpdateManyEmailTemplateVersionArgs = {
  data: EmailTemplateVersionUpdateManyMutationInput;
  where?: Maybe<EmailTemplateVersionWhereInput>;
};


export type MutationUpdateManyEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateManyMutationInput;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type MutationUpdateManyEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventUpdateManyMutationInput;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type MutationUpdateManyEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateManyMutationInput;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type MutationUpdateManyFeatureFlagArgs = {
  data: FeatureFlagUpdateManyMutationInput;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type MutationUpdateManyFinancialAdjustmentArgs = {
  data: FinancialAdjustmentUpdateManyMutationInput;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type MutationUpdateManyFinancialTransactionArgs = {
  data: FinancialTransactionUpdateManyMutationInput;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type MutationUpdateManyFormSubmissionArgs = {
  data: FormSubmissionUpdateManyMutationInput;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type MutationUpdateManyFormTemplateArgs = {
  data: FormTemplateUpdateManyMutationInput;
  where?: Maybe<FormTemplateWhereInput>;
};


export type MutationUpdateManyGlobalPetParentArgs = {
  data: GlobalPetParentUpdateManyMutationInput;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type MutationUpdateManyHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogUpdateManyMutationInput;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type MutationUpdateManyInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionUpdateManyMutationInput;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type MutationUpdateManyInformCampaignArgs = {
  data: InformCampaignUpdateManyMutationInput;
  where?: Maybe<InformCampaignWhereInput>;
};


export type MutationUpdateManyInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentUpdateManyMutationInput;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type MutationUpdateManyInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceUpdateManyMutationInput;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type MutationUpdateManyInformCampaignExecutionArgs = {
  data: InformCampaignExecutionUpdateManyMutationInput;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type MutationUpdateManyInformCampaignGroupArgs = {
  data: InformCampaignGroupUpdateManyMutationInput;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type MutationUpdateManyInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantUpdateManyMutationInput;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type MutationUpdateManyInformEmailTemplateArgs = {
  data: InformEmailTemplateUpdateManyMutationInput;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type MutationUpdateManyInformEntityHistoryArgs = {
  data: InformEntityHistoryUpdateManyMutationInput;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type MutationUpdateManyInformPartnerArgs = {
  data: InformPartnerUpdateManyMutationInput;
  where?: Maybe<InformPartnerWhereInput>;
};


export type MutationUpdateManyInformPartnerCustomerArgs = {
  data: InformPartnerCustomerUpdateManyMutationInput;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type MutationUpdateManyInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderUpdateManyMutationInput;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type MutationUpdateManyInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationUpdateManyMutationInput;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type MutationUpdateManyInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelUpdateManyMutationInput;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type MutationUpdateManyInformPartnerProgramArgs = {
  data: InformPartnerProgramUpdateManyMutationInput;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type MutationUpdateManyInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentUpdateManyMutationInput;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type MutationUpdateManyInformPartnerUserArgs = {
  data: InformPartnerUserUpdateManyMutationInput;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type MutationUpdateManyInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleUpdateManyMutationInput;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type MutationUpdateManyInformScheduledCampaignArgs = {
  data: InformScheduledCampaignUpdateManyMutationInput;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type MutationUpdateManyInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateManyMutationInput;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type MutationUpdateManyInsuranceIntegrationArgs = {
  data: InsuranceIntegrationUpdateManyMutationInput;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type MutationUpdateManyIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryUpdateManyMutationInput;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type MutationUpdateManyInterchangeRateArgs = {
  data: InterchangeRateUpdateManyMutationInput;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type MutationUpdateManyInvoiceArgs = {
  data: InvoiceUpdateManyMutationInput;
  where?: Maybe<InvoiceWhereInput>;
};


export type MutationUpdateManyInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type MutationUpdateManyInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersUpdateManyMutationInput;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type MutationUpdateManyLegalEntityArgs = {
  data: LegalEntityUpdateManyMutationInput;
  where?: Maybe<LegalEntityWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountArgs = {
  data: LoyaltyAccountUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type MutationUpdateManyLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerUpdateManyMutationInput;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type MutationUpdateManyLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterUpdateManyMutationInput;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type MutationUpdateManyLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type MutationUpdateManyLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type MutationUpdateManyLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantUpdateManyMutationInput;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type MutationUpdateManyLoyaltyRewardArgs = {
  data: LoyaltyRewardUpdateManyMutationInput;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type MutationUpdateManyLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionUpdateManyMutationInput;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type MutationUpdateManyMassTextAlertArgs = {
  data: MassTextAlertUpdateManyMutationInput;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type MutationUpdateManyMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type MutationUpdateManyMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateManyMutationInput;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type MutationUpdateManyMediaArgs = {
  data: MediaUpdateManyMutationInput;
  where?: Maybe<MediaWhereInput>;
};


export type MutationUpdateManyMessageTemplateArgs = {
  data: MessageTemplateUpdateManyMutationInput;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type MutationUpdateManyMessagingCampaignArgs = {
  data: MessagingCampaignUpdateManyMutationInput;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type MutationUpdateManyMessagingPartnerArgs = {
  data: MessagingPartnerUpdateManyMutationInput;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type MutationUpdateManyNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanUpdateManyMutationInput;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type MutationUpdateManyNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateManyMutationInput;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type MutationUpdateManyOrganizationArgs = {
  data: OrganizationUpdateManyMutationInput;
  where?: Maybe<OrganizationWhereInput>;
};


export type MutationUpdateManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryUpdateManyMutationInput;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type MutationUpdateManyOrganizationPetArgs = {
  data: OrganizationPetUpdateManyMutationInput;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type MutationUpdateManyOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateManyMutationInput;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type MutationUpdateManyPpcIntegrationArgs = {
  data: PpcIntegrationUpdateManyMutationInput;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type MutationUpdateManyPayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodUpdateManyMutationInput;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type MutationUpdateManyPermissionArgs = {
  data: PermissionUpdateManyMutationInput;
  where?: Maybe<PermissionWhereInput>;
};


export type MutationUpdateManyPetParentSettingArgs = {
  data: PetParentSettingUpdateManyMutationInput;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type MutationUpdateManyPetPortalSettingArgs = {
  data: PetPortalSettingUpdateManyMutationInput;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type MutationUpdateManyPhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestUpdateManyMutationInput;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type MutationUpdateManyPhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultUpdateManyMutationInput;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type MutationUpdateManyPimsInvoiceArgs = {
  data: PimsInvoiceUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type MutationUpdateManyPimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateManyMutationInput;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type MutationUpdateManyPimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateManyMutationInput;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type MutationUpdateManyPrescriptionArgs = {
  data: PrescriptionUpdateManyMutationInput;
  where?: Maybe<PrescriptionWhereInput>;
};


export type MutationUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: Maybe<ProductWhereInput>;
};


export type MutationUpdateManyProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapUpdateManyMutationInput;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type MutationUpdateManyPushNotificationLogArgs = {
  data: PushNotificationLogUpdateManyMutationInput;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type MutationUpdateManyRulesArgs = {
  data: RulesUpdateManyMutationInput;
  where?: Maybe<RulesWhereInput>;
};


export type MutationUpdateManySegmentArgs = {
  data: SegmentUpdateManyMutationInput;
  where?: Maybe<SegmentWhereInput>;
};


export type MutationUpdateManySentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferUpdateManyMutationInput;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type MutationUpdateManyServiceArgs = {
  data: ServiceUpdateManyMutationInput;
  where?: Maybe<ServiceWhereInput>;
};


export type MutationUpdateManyServiceCategoryArgs = {
  data: ServiceCategoryUpdateManyMutationInput;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type MutationUpdateManyServiceReminderArgs = {
  data: ServiceReminderUpdateManyMutationInput;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type MutationUpdateManyServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateManyMutationInput;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type MutationUpdateManyServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateManyMutationInput;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type MutationUpdateManyServicesAndTimingsArgs = {
  data: Array<ServiceReminderTimingInput>;
  where: ServiceWhereInput;
};


export type MutationUpdateManySexArgs = {
  data: SexUpdateManyMutationInput;
  where?: Maybe<SexWhereInput>;
};


export type MutationUpdateManySmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateManyMutationInput;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type MutationUpdateManySpeciesArgs = {
  data: SpeciesUpdateManyMutationInput;
  where?: Maybe<SpeciesWhereInput>;
};


export type MutationUpdateManyStaffRoleArgs = {
  data: StaffRoleUpdateManyMutationInput;
  where?: Maybe<StaffRoleWhereInput>;
};


export type MutationUpdateManyStripeCustomerArgs = {
  data: StripeCustomerUpdateManyMutationInput;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type MutationUpdateManyStripeDisputeArgs = {
  data: StripeDisputeUpdateManyMutationInput;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type MutationUpdateManyStripeInvoiceArgs = {
  data: StripeInvoiceUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type MutationUpdateManyStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateManyMutationInput;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type MutationUpdateManyStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateManyMutationInput;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type MutationUpdateManyStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateManyMutationInput;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type MutationUpdateManyStripePayoutArgs = {
  data: StripePayoutUpdateManyMutationInput;
  where?: Maybe<StripePayoutWhereInput>;
};


export type MutationUpdateManyStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateManyMutationInput;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type MutationUpdateManyStripeReceiptArgs = {
  data: StripeReceiptUpdateManyMutationInput;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type MutationUpdateManyStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateManyMutationInput;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type MutationUpdateManyStripeRefundArgs = {
  data: StripeRefundUpdateManyMutationInput;
  where?: Maybe<StripeRefundWhereInput>;
};


export type MutationUpdateManyStripeTerminalArgs = {
  data: StripeTerminalUpdateManyMutationInput;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type MutationUpdateManyStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateManyMutationInput;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type MutationUpdateManySurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingUpdateManyMutationInput;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type MutationUpdateManySurveyResultArgs = {
  data: SurveyResultUpdateManyMutationInput;
  where?: Maybe<SurveyResultWhereInput>;
};


export type MutationUpdateManySurveySettingArgs = {
  data: SurveySettingUpdateManyMutationInput;
  where?: Maybe<SurveySettingWhereInput>;
};


export type MutationUpdateManySyncVetIntegrationArgs = {
  data: SyncVetIntegrationUpdateManyMutationInput;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type MutationUpdateManyTimingsDeliveryOptionsArgs = {
  data: ServiceReminderTimingDeliveryOptionInput;
  where: ServiceWhereInput;
};


export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: Maybe<TransactionWhereInput>;
};


export type MutationUpdateManyTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityUpdateManyMutationInput;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type MutationUpdateManyTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeUpdateManyMutationInput;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type MutationUpdateManyTrupanionIntegrationArgs = {
  data: TrupanionIntegrationUpdateManyMutationInput;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationUpdateManyUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateManyMutationInput;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type MutationUpdateManyUserBoardFilterArgs = {
  data: UserBoardFilterUpdateManyMutationInput;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type MutationUpdateManyUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateManyMutationInput;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type MutationUpdateManyUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateManyMutationInput;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type MutationUpdateManyUserSettingArgs = {
  data: UserSettingUpdateManyMutationInput;
  where?: Maybe<UserSettingWhereInput>;
};


export type MutationUpdateManyVaccinationArgs = {
  data: VaccinationUpdateManyMutationInput;
  where?: Maybe<VaccinationWhereInput>;
};


export type MutationUpdateManyVetAvailabilityArgs = {
  data: VetAvailabilityUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type MutationUpdateManyVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type MutationUpdateManyVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateManyMutationInput;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type MutationUpdateManyVetInfoArgs = {
  data: VetInfoUpdateManyMutationInput;
  where?: Maybe<VetInfoWhereInput>;
};


export type MutationUpdateManyVetsourceCodeTagArgs = {
  data: VetsourceCodeTagUpdateManyMutationInput;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type MutationUpdateManyVetsourceInstallationArgs = {
  data: VetsourceInstallationUpdateManyMutationInput;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type MutationUpdateManyVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryUpdateManyMutationInput;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type MutationUpdateManyWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateManyMutationInput;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type MutationUpdateOneActiveIngredientArgs = {
  data: ActiveIngredientUpdateInput;
  where: ActiveIngredientWhereUniqueInput;
};


export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};


export type MutationUpdateOneAppointmentAvailabilityArgs = {
  data: AppointmentAvailabilityUpdateInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type MutationUpdateOneAppointmentTypeArgs = {
  data: AppointmentTypeUpdateInput;
  where: AppointmentTypeWhereUniqueInput;
};


export type MutationUpdateOneAutomationRunArgs = {
  data: AutomationRunUpdateInput;
  where: AutomationRunWhereUniqueInput;
};


export type MutationUpdateOneAutomationRunActionArgs = {
  data: AutomationRunActionUpdateInput;
  where: AutomationRunActionWhereUniqueInput;
};


export type MutationUpdateOneBitwerxHealthcheckArgs = {
  data: BitwerxHealthcheckUpdateInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type MutationUpdateOneBitwerxIntegrationArgs = {
  data: BitwerxIntegrationUpdateInput;
  where: BitwerxIntegrationWhereUniqueInput;
};


export type MutationUpdateOneBitwerxPaymentHealthcheckResultArgs = {
  data: BitwerxPaymentHealthcheckResultUpdateInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type MutationUpdateOneBitwerxPaymentHealthcheckRunArgs = {
  data: BitwerxPaymentHealthcheckRunUpdateInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type MutationUpdateOneBitwerxPaymentWritebackConfigurationArgs = {
  data: BitwerxPaymentWritebackConfigurationUpdateInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};


export type MutationUpdateOneBoardFilterColumnArgs = {
  data: BoardFilterColumnUpdateInput;
  where: BoardFilterColumnWhereUniqueInput;
};


export type MutationUpdateOneBoardFilterSpecificDateRangeArgs = {
  data: BoardFilterSpecificDateRangeUpdateInput;
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type MutationUpdateOneBoehringerIngelheimCustomerAlignmentArgs = {
  data: BoehringerIngelheimCustomerAlignmentUpdateInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type MutationUpdateOneBoehringerIngelheimTerritoryArgs = {
  data: BoehringerIngelheimTerritoryUpdateInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type MutationUpdateOneBreedArgs = {
  data: BreedUpdateInput;
  where: BreedWhereUniqueInput;
};


export type MutationUpdateOneCallHistoryArgs = {
  data: CallHistoryUpdateInput;
  where: CallHistoryWhereUniqueInput;
};


export type MutationUpdateOneCallParticipantArgs = {
  data: CallParticipantUpdateInput;
  where: CallParticipantWhereUniqueInput;
};


export type MutationUpdateOneCallRecordingArgs = {
  data: CallRecordingUpdateInput;
  where: CallRecordingWhereUniqueInput;
};


export type MutationUpdateOneCampaignRegistryCampaignArgs = {
  data: CampaignRegistryCampaignUpdateInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type MutationUpdateOneCanonicalBreedArgs = {
  data: CanonicalBreedUpdateInput;
  where: CanonicalBreedWhereUniqueInput;
};


export type MutationUpdateOneCanonicalCanineBreedProfileArgs = {
  data: CanonicalCanineBreedProfileUpdateInput;
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type MutationUpdateOneCanonicalServiceArgs = {
  data: CanonicalServiceUpdateInput;
  where: CanonicalServiceWhereUniqueInput;
};


export type MutationUpdateOneCanonicalSpeciesArgs = {
  data: CanonicalSpeciesUpdateInput;
  where: CanonicalSpeciesWhereUniqueInput;
};


export type MutationUpdateOneCareAddonEnrollmentArgs = {
  data: CareAddonEnrollmentUpdateInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type MutationUpdateOneCareAddonPackageArgs = {
  data: CareAddonPackageUpdateInput;
  where: CareAddonPackageWhereUniqueInput;
};


export type MutationUpdateOneCareAddonPackageBenefitArgs = {
  data: CareAddonPackageBenefitUpdateInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type MutationUpdateOneCareAuthorizationRequestArgs = {
  data: CareAuthorizationRequestUpdateInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type MutationUpdateOneCareBenefitArgs = {
  data: CareBenefitUpdateInput;
  where: CareBenefitWhereUniqueInput;
};


export type MutationUpdateOneCareBenefitUsageArgs = {
  data: CareBenefitUsageUpdateInput;
  where: CareBenefitUsageWhereUniqueInput;
};


export type MutationUpdateOneCareBenefitUsageFollowupArgs = {
  data: CareBenefitUsageFollowupUpdateInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type MutationUpdateOneCareBenefitUsageFollowupStepArgs = {
  data: CareBenefitUsageFollowupStepUpdateInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type MutationUpdateOneCareBenefitUsageToInvoiceLineItemArgs = {
  data: CareBenefitUsageToInvoiceLineItemUpdateInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type MutationUpdateOneCareEnrollmentPaymentArgs = {
  data: CareEnrollmentPaymentUpdateInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type MutationUpdateOneCareEnrollmentToStripeInvoiceItemArgs = {
  data: CareEnrollmentToStripeInvoiceItemUpdateInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type MutationUpdateOneCareLapsedPaymentArgs = {
  data: CareLapsedPaymentUpdateInput;
  where: CareLapsedPaymentWhereUniqueInput;
};


export type MutationUpdateOneCarePlanArgs = {
  data: CarePlanUpdateInput;
  where: CarePlanWhereUniqueInput;
};


export type MutationUpdateOneCarePlanBenefitArgs = {
  data: CarePlanBenefitUpdateInput;
  where: CarePlanBenefitWhereUniqueInput;
};


export type MutationUpdateOneCarePlanBenefitEnrollmentCarryoverArgs = {
  data: CarePlanBenefitEnrollmentCarryoverUpdateInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type MutationUpdateOneCarePlanEnrollmentArgs = {
  data: CarePlanEnrollmentUpdateInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type MutationUpdateOneCarePlanProviderGroupArgs = {
  data: CarePlanProviderGroupUpdateInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type MutationUpdateOneCareStripeSubscriptionArgs = {
  data: CareStripeSubscriptionUpdateInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type MutationUpdateOneCareSubscriptionTrueUpArgs = {
  data: CareSubscriptionTrueUpUpdateInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type MutationUpdateOneCareSubscriptionTrueUpLogEntryArgs = {
  data: CareSubscriptionTrueUpLogEntryUpdateInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type MutationUpdateOneChannelArgs = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationUpdateOneChannelAutomationStatusArgs = {
  data: ChannelAutomationStatusUpdateInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type MutationUpdateOneChannelCreationSourceArgs = {
  data: ChannelCreationSourceUpdateInput;
  where: ChannelCreationSourceWhereUniqueInput;
};


export type MutationUpdateOneChannelFilterSelectionArgs = {
  data: ChannelFilterSelectionUpdateInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpdateOneChannelMemberArgs = {
  data: ChannelMemberUpdateInput;
  where: ChannelMemberWhereUniqueInput;
};


export type MutationUpdateOneChannelMessageArgs = {
  data: ChannelMessageUpdateInput;
  where: ChannelMessageWhereUniqueInput;
};


export type MutationUpdateOneChannelMessageAttachmentArgs = {
  data: ChannelMessageAttachmentUpdateInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type MutationUpdateOneChannelMessageAutoResponseArgs = {
  data: ChannelMessageAutoResponseUpdateInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type MutationUpdateOneChannelMessageReactionArgs = {
  data: ChannelMessageReactionUpdateInput;
  where: ChannelMessageReactionWhereUniqueInput;
};


export type MutationUpdateOneChannelPinArgs = {
  data: ChannelPinUpdateInput;
  where: ChannelPinWhereUniqueInput;
};


export type MutationUpdateOneChannelSelectionArgs = {
  data: ChannelSelectionUpdateInput;
  where: ChannelSelectionWhereUniqueInput;
};


export type MutationUpdateOneChannelStatusArgs = {
  data: ChannelStatusUpdateInput;
  where: ChannelStatusWhereUniqueInput;
};


export type MutationUpdateOneChannelStatusChannelAssigneeArgs = {
  data: ChannelStatusChannelAssigneeUpdateInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type MutationUpdateOneChemicalCompoundArgs = {
  data: ChemicalCompoundUpdateInput;
  where: ChemicalCompoundWhereUniqueInput;
};


export type MutationUpdateOneClientConnectIntegrationArgs = {
  data: ClientConnectIntegrationUpdateInput;
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type MutationUpdateOneClinicArgs = {
  data: ClinicUpdateInput;
  where: ClinicWhereUniqueInput;
};


export type MutationUpdateOneClinicBlockedPhoneNumberArgs = {
  data: ClinicBlockedPhoneNumberUpdateInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type MutationUpdateOneClinicDirectBookingSettingArgs = {
  data: ClinicDirectBookingSettingUpdateInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type MutationUpdateOneClinicEmailCampaignSettingArgs = {
  data: ClinicEmailCampaignSettingUpdateInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type MutationUpdateOneClinicEmployeeArgs = {
  data: ClinicEmployeeUpdateInput;
  where: ClinicEmployeeWhereUniqueInput;
};


export type MutationUpdateOneClinicEmployeeAppointmentTypeSettingArgs = {
  data: ClinicEmployeeAppointmentTypeSettingUpdateInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpdateOneClinicEntityPhoneNumberArgs = {
  data: ClinicEntityPhoneNumberUpdateInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type MutationUpdateOneClinicOfficeHourArgs = {
  data: ClinicOfficeHourUpdateInput;
  where: ClinicOfficeHourWhereUniqueInput;
};


export type MutationUpdateOneClinicOnboardingArgs = {
  data: ClinicOnboardingUpdateInput;
  where: ClinicOnboardingWhereUniqueInput;
};


export type MutationUpdateOneClinicPaymentFeeConfigurationArgs = {
  data: ClinicPaymentFeeConfigurationUpdateInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type MutationUpdateOneClinicPetArgs = {
  data: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};


export type MutationUpdateOneClinicPetAlertArgs = {
  data: ClinicPetAlertUpdateInput;
  where: ClinicPetAlertWhereUniqueInput;
};


export type MutationUpdateOneClinicPetCustomFieldValueArgs = {
  data: ClinicPetCustomFieldValueUpdateInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentArgs = {
  data: ClinicPetParentUpdateInput;
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentAddressArgs = {
  data: ClinicPetParentAddressUpdateInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupArgs = {
  data: ClinicPetParentUpdateInput;
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentCustomFieldValueArgs = {
  data: ClinicPetParentCustomFieldValueUpdateInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentDeviceArgs = {
  data: ClinicPetParentDeviceUpdateInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type MutationUpdateOneClinicPetParentPimsSyncStatusArgs = {
  data: ClinicPetParentPimsSyncStatusUpdateInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type MutationUpdateOneClinicPetPimsSyncStatusArgs = {
  data: ClinicPetPimsSyncStatusUpdateInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type MutationUpdateOneClinicPhoneNumberArgs = {
  data: ClinicPhoneNumberUpdateInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type MutationUpdateOneClinicPimsIntegrationArgs = {
  data: ClinicPimsIntegrationUpdateInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type MutationUpdateOneClinicPimsIntegrationCapabilityArgs = {
  data: ClinicPimsIntegrationCapabilityUpdateInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type MutationUpdateOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  data: ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type MutationUpdateOneClinicPostcardSettingArgs = {
  data: ClinicPostcardSettingUpdateInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type MutationUpdateOneClinicRoomArgs = {
  data: ClinicRoomUpdateInput;
  where: ClinicRoomWhereUniqueInput;
};


export type MutationUpdateOneClinicSettingArgs = {
  data: ClinicSettingUpdateInput;
  where: ClinicSettingWhereUniqueInput;
};


export type MutationUpdateOneClinicWidgetRequestArgs = {
  data: ClinicWidgetRequestUpdateInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type MutationUpdateOneClinicWidgetRequestTypeArgs = {
  data: ClinicWidgetRequestTypeUpdateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type MutationUpdateOneClinicWidgetSettingArgs = {
  data: ClinicWidgetSettingUpdateInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type MutationUpdateOneColorArgs = {
  data: ColorUpdateInput;
  where: ColorWhereUniqueInput;
};


export type MutationUpdateOneConditionArgs = {
  data: ConditionUpdateInput;
  where: ConditionWhereUniqueInput;
};


export type MutationUpdateOneConditionSetArgs = {
  data: ConditionSetUpdateInput;
  where: ConditionSetWhereUniqueInput;
};


export type MutationUpdateOneConditionalEntityEvaluationArgs = {
  data: ConditionalEntityEvaluationUpdateInput;
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type MutationUpdateOneConsultationRequestArgs = {
  data: ConsultationRequestUpdateInput;
  where: ConsultationRequestWhereUniqueInput;
};


export type MutationUpdateOneCustomFieldDefinitionArgs = {
  data: CustomFieldDefinitionUpdateInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type MutationUpdateOneCustomFieldPimsWritebackLogEntryArgs = {
  data: CustomFieldPimsWritebackLogEntryUpdateInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpdateOneDirectBookingAppointmentTypeSettingArgs = {
  data: DirectBookingAppointmentTypeSettingUpdateInput;
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpdateOneDirectBookingExclusionRuleArgs = {
  data: DirectBookingExclusionRuleUpdateInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type MutationUpdateOneDirectBookingExclusionTimeSlotArgs = {
  data: DirectBookingExclusionTimeSlotUpdateInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type MutationUpdateOneDisbursementArgs = {
  data: DisbursementUpdateInput;
  where: DisbursementWhereUniqueInput;
};


export type MutationUpdateOneDocumentationArgs = {
  data: DocumentationUpdateInput;
  where: DocumentationWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignArgs = {
  data: EmailCampaignUpdateInput;
  where: EmailCampaignWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignCustomListArgs = {
  data: EmailCampaignCustomListUpdateInput;
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignCustomListRecipientArgs = {
  data: EmailCampaignCustomListRecipientUpdateInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignCustomListUploadHistoryArgs = {
  data: EmailCampaignCustomListUploadHistoryUpdateInput;
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignLogArgs = {
  data: EmailCampaignLogUpdateInput;
  where: EmailCampaignLogWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignTemplateArgs = {
  data: EmailCampaignTemplateUpdateInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type MutationUpdateOneEmailCampaignUnsubscribeArgs = {
  data: EmailCampaignUnsubscribeUpdateInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type MutationUpdateOneEmailProviderLogArgs = {
  data: EmailProviderLogUpdateInput;
  where: EmailProviderLogWhereUniqueInput;
};


export type MutationUpdateOneEmailTemplateVersionArgs = {
  data: EmailTemplateVersionUpdateInput;
  where: EmailTemplateVersionWhereUniqueInput;
};


export type MutationUpdateOneEmergencyClinicsArgs = {
  data: EmergencyClinicsUpdateInput;
  where: EmergencyClinicsWhereUniqueInput;
};


export type MutationUpdateOneEnrollmentSupportEventArgs = {
  data: EnrollmentSupportEventUpdateInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type MutationUpdateOneEzyVetIntegrationArgs = {
  data: EzyVetIntegrationUpdateInput;
  where: EzyVetIntegrationWhereUniqueInput;
};


export type MutationUpdateOneFeatureFlagArgs = {
  data: FeatureFlagUpdateInput;
  where: FeatureFlagWhereUniqueInput;
};


export type MutationUpdateOneFinancialAdjustmentArgs = {
  data: FinancialAdjustmentUpdateInput;
  where: FinancialAdjustmentWhereUniqueInput;
};


export type MutationUpdateOneFinancialTransactionArgs = {
  data: FinancialTransactionUpdateInput;
  where: FinancialTransactionWhereUniqueInput;
};


export type MutationUpdateOneFormSubmissionArgs = {
  data: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};


export type MutationUpdateOneFormTemplateArgs = {
  data: FormTemplateUpdateInput;
  where: FormTemplateWhereUniqueInput;
};


export type MutationUpdateOneGlobalPetParentArgs = {
  data: GlobalPetParentUpdateInput;
  where: GlobalPetParentWhereUniqueInput;
};


export type MutationUpdateOneHillsToHomeApiLogArgs = {
  data: HillsToHomeApiLogUpdateInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type MutationUpdateOneInformAudienceDefinitionArgs = {
  data: InformAudienceDefinitionUpdateInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type MutationUpdateOneInformAutomationCampaignArgs = {
  data: InformAutomationCampaignUpdateInput;
  where: InformAutomationCampaignWhereUniqueInput;
};


export type MutationUpdateOneInformCampaignArgs = {
  data: InformCampaignUpdateInput;
  where: InformCampaignWhereUniqueInput;
};


export type MutationUpdateOneInformCampaignEnrollmentArgs = {
  data: InformCampaignEnrollmentUpdateInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type MutationUpdateOneInformCampaignEnrollmentCanonicalServiceArgs = {
  data: InformCampaignEnrollmentCanonicalServiceUpdateInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type MutationUpdateOneInformCampaignExecutionArgs = {
  data: InformCampaignExecutionUpdateInput;
  where: InformCampaignExecutionWhereUniqueInput;
};


export type MutationUpdateOneInformCampaignGroupArgs = {
  data: InformCampaignGroupUpdateInput;
  where: InformCampaignGroupWhereUniqueInput;
};


export type MutationUpdateOneInformControlGroupParticipantArgs = {
  data: InformControlGroupParticipantUpdateInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type MutationUpdateOneInformEmailTemplateArgs = {
  data: InformEmailTemplateUpdateInput;
  where: InformEmailTemplateWhereUniqueInput;
};


export type MutationUpdateOneInformEntityHistoryArgs = {
  data: InformEntityHistoryUpdateInput;
  where: InformEntityHistoryWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerArgs = {
  data: InformPartnerUpdateInput;
  where: InformPartnerWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerCustomerArgs = {
  data: InformPartnerCustomerUpdateInput;
  where: InformPartnerCustomerWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerCustomerPharmacyProviderArgs = {
  data: InformPartnerCustomerPharmacyProviderUpdateInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerIntegrationArgs = {
  data: InformPartnerIntegrationUpdateInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerIntegrationModelArgs = {
  data: InformPartnerIntegrationModelUpdateInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerProgramArgs = {
  data: InformPartnerProgramUpdateInput;
  where: InformPartnerProgramWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerProgramEnrollmentArgs = {
  data: InformPartnerProgramEnrollmentUpdateInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerUserArgs = {
  data: InformPartnerUserUpdateInput;
  where: InformPartnerUserWhereUniqueInput;
};


export type MutationUpdateOneInformPartnerUserRoleArgs = {
  data: InformPartnerUserRoleUpdateInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type MutationUpdateOneInformScheduledCampaignArgs = {
  data: InformScheduledCampaignUpdateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};


export type MutationUpdateOneInstinctIntegrationArgs = {
  data: InstinctIntegrationUpdateInput;
  where: InstinctIntegrationWhereUniqueInput;
};


export type MutationUpdateOneInsuranceIntegrationArgs = {
  data: InsuranceIntegrationUpdateInput;
  where: InsuranceIntegrationWhereUniqueInput;
};


export type MutationUpdateOneIntegrationOutageHistoryEntryArgs = {
  data: IntegrationOutageHistoryEntryUpdateInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type MutationUpdateOneInterchangeRateArgs = {
  data: InterchangeRateUpdateInput;
  where: InterchangeRateWhereUniqueInput;
};


export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateOneInvoiceLineItemArgs = {
  data: InvoiceLineItemUpdateInput;
  where: InvoiceLineItemWhereUniqueInput;
};


export type MutationUpdateOneInvoicePimsWritebackLogEntryArgs = {
  data: InvoicePimsWritebackLogEntryUpdateInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpdateOneLapsedPetParentTriggersArgs = {
  data: LapsedPetParentTriggersUpdateInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type MutationUpdateOneLegalEntityArgs = {
  data: LegalEntityUpdateInput;
  where: LegalEntityWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyAccountArgs = {
  data: LoyaltyAccountUpdateInput;
  where: LoyaltyAccountWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyAccountHolderArgs = {
  data: LoyaltyAccountHolderUpdateInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyAccountMergerArgs = {
  data: LoyaltyAccountMergerUpdateInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyInvoicePointGranterArgs = {
  data: LoyaltyInvoicePointGranterUpdateInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyPointGrantingServiceArgs = {
  data: LoyaltyPointGrantingServiceUpdateInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyPointGrantingServiceCategoryArgs = {
  data: LoyaltyPointGrantingServiceCategoryUpdateInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyPointUserGrantArgs = {
  data: LoyaltyPointUserGrantUpdateInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyProgramArgs = {
  data: LoyaltyProgramUpdateInput;
  where: LoyaltyProgramWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyRewardArgs = {
  data: LoyaltyRewardUpdateInput;
  where: LoyaltyRewardWhereUniqueInput;
};


export type MutationUpdateOneLoyaltyRewardRedemptionArgs = {
  data: LoyaltyRewardRedemptionUpdateInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type MutationUpdateOneMassTextAlertArgs = {
  data: MassTextAlertUpdateInput;
  where: MassTextAlertWhereUniqueInput;
};


export type MutationUpdateOneMassTextAlertEntryArgs = {
  data: MassTextAlertEntryUpdateInput;
  where: MassTextAlertEntryWhereUniqueInput;
};


export type MutationUpdateOneMassTextAlertEntryAppointmentArgs = {
  data: MassTextAlertEntryAppointmentUpdateInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type MutationUpdateOneMediaArgs = {
  data: MediaUpdateInput;
  where: MediaWhereUniqueInput;
};


export type MutationUpdateOneMessageTemplateArgs = {
  data: MessageTemplateUpdateInput;
  where: MessageTemplateWhereUniqueInput;
};


export type MutationUpdateOneMessagingCampaignArgs = {
  data: MessagingCampaignUpdateInput;
  where: MessagingCampaignWhereUniqueInput;
};


export type MutationUpdateOneMessagingPartnerArgs = {
  data: MessagingPartnerUpdateInput;
  where: MessagingPartnerWhereUniqueInput;
};


export type MutationUpdateOneNonPrismaSchemaScriptRanArgs = {
  data: NonPrismaSchemaScriptRanUpdateInput;
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type MutationUpdateOneNotificationEventToMessageArgs = {
  data: NotificationEventToMessageUpdateInput;
  where: NotificationEventToMessageWhereUniqueInput;
};


export type MutationUpdateOneOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateOneOrganizationCareBenefitToClinicServiceArgs = {
  data: OrganizationCareBenefitToClinicServiceUpdateInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type MutationUpdateOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  data: OrganizationCareBenefitToClinicServiceCategoryUpdateInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type MutationUpdateOneOrganizationPetArgs = {
  data: OrganizationPetUpdateInput;
  where: OrganizationPetWhereUniqueInput;
};


export type MutationUpdateOneOrganizationPetParentArgs = {
  data: OrganizationPetParentUpdateInput;
  where: OrganizationPetParentWhereUniqueInput;
};


export type MutationUpdateOneOrganizationPetToOrganizationPetParentArgs = {
  data: OrganizationPetToOrganizationPetParentUpdateInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type MutationUpdateOnePpcIntegrationArgs = {
  data: PpcIntegrationUpdateInput;
  where: PpcIntegrationWhereUniqueInput;
};


export type MutationUpdateOnePayoutBatchingPeriodArgs = {
  data: PayoutBatchingPeriodUpdateInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};


export type MutationUpdateOnePermissionArgs = {
  data: PermissionUpdateInput;
  where: PermissionWhereUniqueInput;
};


export type MutationUpdateOnePetParentSettingArgs = {
  data: PetParentSettingUpdateInput;
  where: PetParentSettingWhereUniqueInput;
};


export type MutationUpdateOnePetPortalSettingArgs = {
  data: PetPortalSettingUpdateInput;
  where: PetPortalSettingWhereUniqueInput;
};


export type MutationUpdateOnePhoneNumberLookupRequestArgs = {
  data: PhoneNumberLookupRequestUpdateInput;
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type MutationUpdateOnePhoneNumberLookupResultArgs = {
  data: PhoneNumberLookupResultUpdateInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type MutationUpdateOnePimsInvoiceArgs = {
  data: PimsInvoiceUpdateInput;
  where: PimsInvoiceWhereUniqueInput;
};


export type MutationUpdateOnePimsInvoiceLineItemArgs = {
  data: PimsInvoiceLineItemUpdateInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type MutationUpdateOnePimsWritebackLogEntryArgs = {
  data: PimsWritebackLogEntryUpdateInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpdateOnePrescriptionArgs = {
  data: PrescriptionUpdateInput;
  where: PrescriptionWhereUniqueInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateOneProviderAppointmentTypeRoomMapArgs = {
  data: ProviderAppointmentTypeRoomMapUpdateInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type MutationUpdateOnePushNotificationLogArgs = {
  data: PushNotificationLogUpdateInput;
  where: PushNotificationLogWhereUniqueInput;
};


export type MutationUpdateOneRulesArgs = {
  data: RulesUpdateInput;
  where: RulesWhereUniqueInput;
};


export type MutationUpdateOneSegmentArgs = {
  data: SegmentUpdateInput;
  where: SegmentWhereUniqueInput;
};


export type MutationUpdateOneSentTrupanionExamDayOfferArgs = {
  data: SentTrupanionExamDayOfferUpdateInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpdateOneServiceCategoryArgs = {
  data: ServiceCategoryUpdateInput;
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationUpdateOneServiceReminderArgs = {
  data: ServiceReminderUpdateInput;
  where: ServiceReminderWhereUniqueInput;
};


export type MutationUpdateOneServiceReminderNotificationArgs = {
  data: ServiceReminderNotificationUpdateInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type MutationUpdateOneServiceReminderTimingArgs = {
  data: ServiceReminderTimingUpdateInput;
  where: ServiceReminderTimingWhereUniqueInput;
};


export type MutationUpdateOneSexArgs = {
  data: SexUpdateInput;
  where: SexWhereUniqueInput;
};


export type MutationUpdateOneSmsNotificationStatusArgs = {
  data: SmsNotificationStatusUpdateInput;
  where: SmsNotificationStatusWhereUniqueInput;
};


export type MutationUpdateOneSpeciesArgs = {
  data: SpeciesUpdateInput;
  where: SpeciesWhereUniqueInput;
};


export type MutationUpdateOneStaffRoleArgs = {
  data: StaffRoleUpdateInput;
  where: StaffRoleWhereUniqueInput;
};


export type MutationUpdateOneStripeCustomerArgs = {
  data: StripeCustomerUpdateInput;
  where: StripeCustomerWhereUniqueInput;
};


export type MutationUpdateOneStripeDisputeArgs = {
  data: StripeDisputeUpdateInput;
  where: StripeDisputeWhereUniqueInput;
};


export type MutationUpdateOneStripeInvoiceArgs = {
  data: StripeInvoiceUpdateInput;
  where: StripeInvoiceWhereUniqueInput;
};


export type MutationUpdateOneStripeInvoiceItemArgs = {
  data: StripeInvoiceItemUpdateInput;
  where: StripeInvoiceItemWhereUniqueInput;
};


export type MutationUpdateOneStripePaymentIntentArgs = {
  data: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationUpdateOneStripePaymentMethodArgs = {
  data: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};


export type MutationUpdateOneStripePayoutArgs = {
  data: StripePayoutUpdateInput;
  where: StripePayoutWhereUniqueInput;
};


export type MutationUpdateOneStripePayoutAccountArgs = {
  data: StripePayoutAccountUpdateInput;
  where: StripePayoutAccountWhereUniqueInput;
};


export type MutationUpdateOneStripeReceiptArgs = {
  data: StripeReceiptUpdateInput;
  where: StripeReceiptWhereUniqueInput;
};


export type MutationUpdateOneStripeReceiptHistoryArgs = {
  data: StripeReceiptHistoryUpdateInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type MutationUpdateOneStripeRefundArgs = {
  data: StripeRefundUpdateInput;
  where: StripeRefundWhereUniqueInput;
};


export type MutationUpdateOneStripeTerminalArgs = {
  data: StripeTerminalUpdateInput;
  where: StripeTerminalWhereUniqueInput;
};


export type MutationUpdateOneStripeTerminalLocationArgs = {
  data: StripeTerminalLocationUpdateInput;
  where: StripeTerminalLocationWhereUniqueInput;
};


export type MutationUpdateOneSurveyAppointmentTypeSettingArgs = {
  data: SurveyAppointmentTypeSettingUpdateInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpdateOneSurveyResultArgs = {
  data: SurveyResultUpdateInput;
  where: SurveyResultWhereUniqueInput;
};


export type MutationUpdateOneSurveySettingArgs = {
  data: SurveySettingUpdateInput;
  where: SurveySettingWhereUniqueInput;
};


export type MutationUpdateOneSyncVetIntegrationArgs = {
  data: SyncVetIntegrationUpdateInput;
  where: SyncVetIntegrationWhereUniqueInput;
};


export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpdateOneTrupanionCertificateAvailabilityArgs = {
  data: TrupanionCertificateAvailabilityUpdateInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type MutationUpdateOneTrupanionIntegratedAppointmentTypeArgs = {
  data: TrupanionIntegratedAppointmentTypeUpdateInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type MutationUpdateOneTrupanionIntegrationArgs = {
  data: TrupanionIntegrationUpdateInput;
  where: TrupanionIntegrationWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOneUserAppointmentGroupingArgs = {
  data: UserAppointmentGroupingUpdateInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type MutationUpdateOneUserBoardFilterArgs = {
  data: UserBoardFilterUpdateInput;
  where: UserBoardFilterWhereUniqueInput;
};


export type MutationUpdateOneUserChannelFilterSelectionArgs = {
  data: UserChannelFilterSelectionUpdateInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpdateOneUserNotificationSettingArgs = {
  data: UserNotificationSettingUpdateInput;
  where: UserNotificationSettingWhereUniqueInput;
};


export type MutationUpdateOneUserSettingArgs = {
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};


export type MutationUpdateOneVaccinationArgs = {
  data: VaccinationUpdateInput;
  where: VaccinationWhereUniqueInput;
};


export type MutationUpdateOneVetAvailabilityArgs = {
  data: VetAvailabilityUpdateInput;
  where: VetAvailabilityWhereUniqueInput;
};


export type MutationUpdateOneVetAvailabilityClinicRoomMapArgs = {
  data: VetAvailabilityClinicRoomMapUpdateInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type MutationUpdateOneVetAvailabilityMapArgs = {
  data: VetAvailabilityMapUpdateInput;
  where: VetAvailabilityMapWhereUniqueInput;
};


export type MutationUpdateOneVetInfoArgs = {
  data: VetInfoUpdateInput;
  where: VetInfoWhereUniqueInput;
};


export type MutationUpdateOneVetsourceCodeTagArgs = {
  data: VetsourceCodeTagUpdateInput;
  where: VetsourceCodeTagWhereUniqueInput;
};


export type MutationUpdateOneVetsourceInstallationArgs = {
  data: VetsourceInstallationUpdateInput;
  where: VetsourceInstallationWhereUniqueInput;
};


export type MutationUpdateOneVetsourceRevenueCategoryArgs = {
  data: VetsourceRevenueCategoryUpdateInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type MutationUpdateOneWorkflowEventSettingArgs = {
  data: WorkflowEventSettingUpdateInput;
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationUpdatePetParentPaymentIntentArgs = {
  data: StripeUpdatePaymentInput;
};


export type MutationUpdateStripePaymentArgs = {
  data: StripeUpdatePaymentInput;
};


export type MutationUpdateTeamChannel2Args = {
  data: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationUpdateTerminalAppPaymentIntentArgs = {
  data: StripeUpdatePaymentInput;
};


export type MutationUploadEmailCampaignCustomListArgs = {
  data: UploadEmailCampaignCustomListInput;
};


export type MutationUpsertOneActiveIngredientArgs = {
  create: ActiveIngredientCreateInput;
  update: ActiveIngredientUpdateInput;
  where: ActiveIngredientWhereUniqueInput;
};


export type MutationUpsertOneAppointmentArgs = {
  create: AppointmentCreateInput;
  update: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};


export type MutationUpsertOneAppointmentAvailabilityArgs = {
  create: AppointmentAvailabilityCreateInput;
  update: AppointmentAvailabilityUpdateInput;
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneAppointmentTypeArgs = {
  create: AppointmentTypeCreateInput;
  update: AppointmentTypeUpdateInput;
  where: AppointmentTypeWhereUniqueInput;
};


export type MutationUpsertOneAutomationRunArgs = {
  create: AutomationRunCreateInput;
  update: AutomationRunUpdateInput;
  where: AutomationRunWhereUniqueInput;
};


export type MutationUpsertOneAutomationRunActionArgs = {
  create: AutomationRunActionCreateInput;
  update: AutomationRunActionUpdateInput;
  where: AutomationRunActionWhereUniqueInput;
};


export type MutationUpsertOneBitwerxHealthcheckArgs = {
  create: BitwerxHealthcheckCreateInput;
  update: BitwerxHealthcheckUpdateInput;
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type MutationUpsertOneBitwerxIntegrationArgs = {
  create: BitwerxIntegrationCreateInput;
  update: BitwerxIntegrationUpdateInput;
  where: BitwerxIntegrationWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentHealthcheckResultArgs = {
  create: BitwerxPaymentHealthcheckResultCreateInput;
  update: BitwerxPaymentHealthcheckResultUpdateInput;
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentHealthcheckRunArgs = {
  create: BitwerxPaymentHealthcheckRunCreateInput;
  update: BitwerxPaymentHealthcheckRunUpdateInput;
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type MutationUpsertOneBitwerxPaymentWritebackConfigurationArgs = {
  create: BitwerxPaymentWritebackConfigurationCreateInput;
  update: BitwerxPaymentWritebackConfigurationUpdateInput;
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};


export type MutationUpsertOneBoardFilterColumnArgs = {
  create: BoardFilterColumnCreateInput;
  update: BoardFilterColumnUpdateInput;
  where: BoardFilterColumnWhereUniqueInput;
};


export type MutationUpsertOneBoardFilterSpecificDateRangeArgs = {
  create: BoardFilterSpecificDateRangeCreateInput;
  update: BoardFilterSpecificDateRangeUpdateInput;
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type MutationUpsertOneBoehringerIngelheimCustomerAlignmentArgs = {
  create: BoehringerIngelheimCustomerAlignmentCreateInput;
  update: BoehringerIngelheimCustomerAlignmentUpdateInput;
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type MutationUpsertOneBoehringerIngelheimTerritoryArgs = {
  create: BoehringerIngelheimTerritoryCreateInput;
  update: BoehringerIngelheimTerritoryUpdateInput;
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type MutationUpsertOneBreedArgs = {
  create: BreedCreateInput;
  update: BreedUpdateInput;
  where: BreedWhereUniqueInput;
};


export type MutationUpsertOneCallHistoryArgs = {
  create: CallHistoryCreateInput;
  update: CallHistoryUpdateInput;
  where: CallHistoryWhereUniqueInput;
};


export type MutationUpsertOneCallParticipantArgs = {
  create: CallParticipantCreateInput;
  update: CallParticipantUpdateInput;
  where: CallParticipantWhereUniqueInput;
};


export type MutationUpsertOneCampaignRegistryCampaignArgs = {
  create: CampaignRegistryCampaignCreateInput;
  update: CampaignRegistryCampaignUpdateInput;
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type MutationUpsertOneCanonicalBreedArgs = {
  create: CanonicalBreedCreateInput;
  update: CanonicalBreedUpdateInput;
  where: CanonicalBreedWhereUniqueInput;
};


export type MutationUpsertOneCanonicalCanineBreedProfileArgs = {
  create: CanonicalCanineBreedProfileCreateInput;
  update: CanonicalCanineBreedProfileUpdateInput;
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type MutationUpsertOneCanonicalServiceArgs = {
  create: CanonicalServiceCreateInput;
  update: CanonicalServiceUpdateInput;
  where: CanonicalServiceWhereUniqueInput;
};


export type MutationUpsertOneCanonicalSpeciesArgs = {
  create: CanonicalSpeciesCreateInput;
  update: CanonicalSpeciesUpdateInput;
  where: CanonicalSpeciesWhereUniqueInput;
};


export type MutationUpsertOneCareAddonEnrollmentArgs = {
  create: CareAddonEnrollmentCreateInput;
  update: CareAddonEnrollmentUpdateInput;
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneCareAddonPackageArgs = {
  create: CareAddonPackageCreateInput;
  update: CareAddonPackageUpdateInput;
  where: CareAddonPackageWhereUniqueInput;
};


export type MutationUpsertOneCareAddonPackageBenefitArgs = {
  create: CareAddonPackageBenefitCreateInput;
  update: CareAddonPackageBenefitUpdateInput;
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type MutationUpsertOneCareAuthorizationRequestArgs = {
  create: CareAuthorizationRequestCreateInput;
  update: CareAuthorizationRequestUpdateInput;
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitArgs = {
  create: CareBenefitCreateInput;
  update: CareBenefitUpdateInput;
  where: CareBenefitWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageArgs = {
  create: CareBenefitUsageCreateInput;
  update: CareBenefitUsageUpdateInput;
  where: CareBenefitUsageWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageFollowupArgs = {
  create: CareBenefitUsageFollowupCreateInput;
  update: CareBenefitUsageFollowupUpdateInput;
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageFollowupStepArgs = {
  create: CareBenefitUsageFollowupStepCreateInput;
  update: CareBenefitUsageFollowupStepUpdateInput;
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type MutationUpsertOneCareBenefitUsageToInvoiceLineItemArgs = {
  create: CareBenefitUsageToInvoiceLineItemCreateInput;
  update: CareBenefitUsageToInvoiceLineItemUpdateInput;
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOneCareEnrollmentPaymentArgs = {
  create: CareEnrollmentPaymentCreateInput;
  update: CareEnrollmentPaymentUpdateInput;
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type MutationUpsertOneCareEnrollmentToStripeInvoiceItemArgs = {
  create: CareEnrollmentToStripeInvoiceItemCreateInput;
  update: CareEnrollmentToStripeInvoiceItemUpdateInput;
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type MutationUpsertOneCareLapsedPaymentArgs = {
  create: CareLapsedPaymentCreateInput;
  update: CareLapsedPaymentUpdateInput;
  where: CareLapsedPaymentWhereUniqueInput;
};


export type MutationUpsertOneCarePlanArgs = {
  create: CarePlanCreateInput;
  update: CarePlanUpdateInput;
  where: CarePlanWhereUniqueInput;
};


export type MutationUpsertOneCarePlanBenefitArgs = {
  create: CarePlanBenefitCreateInput;
  update: CarePlanBenefitUpdateInput;
  where: CarePlanBenefitWhereUniqueInput;
};


export type MutationUpsertOneCarePlanBenefitEnrollmentCarryoverArgs = {
  create: CarePlanBenefitEnrollmentCarryoverCreateInput;
  update: CarePlanBenefitEnrollmentCarryoverUpdateInput;
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type MutationUpsertOneCarePlanEnrollmentArgs = {
  create: CarePlanEnrollmentCreateInput;
  update: CarePlanEnrollmentUpdateInput;
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneCarePlanProviderGroupArgs = {
  create: CarePlanProviderGroupCreateInput;
  update: CarePlanProviderGroupUpdateInput;
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type MutationUpsertOneCareStripeSubscriptionArgs = {
  create: CareStripeSubscriptionCreateInput;
  update: CareStripeSubscriptionUpdateInput;
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type MutationUpsertOneCareSubscriptionTrueUpArgs = {
  create: CareSubscriptionTrueUpCreateInput;
  update: CareSubscriptionTrueUpUpdateInput;
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type MutationUpsertOneCareSubscriptionTrueUpLogEntryArgs = {
  create: CareSubscriptionTrueUpLogEntryCreateInput;
  update: CareSubscriptionTrueUpLogEntryUpdateInput;
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type MutationUpsertOneChannelArgs = {
  create: ChannelCreateInput;
  update: ChannelUpdateInput;
  where: ChannelWhereUniqueInput;
};


export type MutationUpsertOneChannelAutomationStatusArgs = {
  create: ChannelAutomationStatusCreateInput;
  update: ChannelAutomationStatusUpdateInput;
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type MutationUpsertOneChannelCreationSourceArgs = {
  create: ChannelCreationSourceCreateInput;
  update: ChannelCreationSourceUpdateInput;
  where: ChannelCreationSourceWhereUniqueInput;
};


export type MutationUpsertOneChannelFilterSelectionArgs = {
  create: ChannelFilterSelectionCreateInput;
  update: ChannelFilterSelectionUpdateInput;
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpsertOneChannelMemberArgs = {
  create: ChannelMemberCreateInput;
  update: ChannelMemberUpdateInput;
  where: ChannelMemberWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageArgs = {
  create: ChannelMessageCreateInput;
  update: ChannelMessageUpdateInput;
  where: ChannelMessageWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageAttachmentArgs = {
  create: ChannelMessageAttachmentCreateInput;
  update: ChannelMessageAttachmentUpdateInput;
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageAutoResponseArgs = {
  create: ChannelMessageAutoResponseCreateInput;
  update: ChannelMessageAutoResponseUpdateInput;
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type MutationUpsertOneChannelMessageReactionArgs = {
  create: ChannelMessageReactionCreateInput;
  update: ChannelMessageReactionUpdateInput;
  where: ChannelMessageReactionWhereUniqueInput;
};


export type MutationUpsertOneChannelPinArgs = {
  create: ChannelPinCreateInput;
  update: ChannelPinUpdateInput;
  where: ChannelPinWhereUniqueInput;
};


export type MutationUpsertOneChannelSelectionArgs = {
  create: ChannelSelectionCreateInput;
  update: ChannelSelectionUpdateInput;
  where: ChannelSelectionWhereUniqueInput;
};


export type MutationUpsertOneChannelStatusArgs = {
  create: ChannelStatusCreateInput;
  update: ChannelStatusUpdateInput;
  where: ChannelStatusWhereUniqueInput;
};


export type MutationUpsertOneChannelStatusChannelAssigneeArgs = {
  create: ChannelStatusChannelAssigneeCreateInput;
  update: ChannelStatusChannelAssigneeUpdateInput;
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type MutationUpsertOneChemicalCompoundArgs = {
  create: ChemicalCompoundCreateInput;
  update: ChemicalCompoundUpdateInput;
  where: ChemicalCompoundWhereUniqueInput;
};


export type MutationUpsertOneClientConnectIntegrationArgs = {
  create: ClientConnectIntegrationCreateInput;
  update: ClientConnectIntegrationUpdateInput;
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type MutationUpsertOneClinicArgs = {
  create: ClinicCreateInput;
  update: ClinicUpdateInput;
  where: ClinicWhereUniqueInput;
};


export type MutationUpsertOneClinicBlockedPhoneNumberArgs = {
  create: ClinicBlockedPhoneNumberCreateInput;
  update: ClinicBlockedPhoneNumberUpdateInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicDirectBookingSettingArgs = {
  create: ClinicDirectBookingSettingCreateInput;
  update: ClinicDirectBookingSettingUpdateInput;
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicEmailCampaignSettingArgs = {
  create: ClinicEmailCampaignSettingCreateInput;
  update: ClinicEmailCampaignSettingUpdateInput;
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicEmployeeArgs = {
  create: ClinicEmployeeCreateInput;
  update: ClinicEmployeeUpdateInput;
  where: ClinicEmployeeWhereUniqueInput;
};


export type MutationUpsertOneClinicEmployeeAppointmentTypeSettingArgs = {
  create: ClinicEmployeeAppointmentTypeSettingCreateInput;
  update: ClinicEmployeeAppointmentTypeSettingUpdateInput;
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicEntityPhoneNumberArgs = {
  create: ClinicEntityPhoneNumberCreateInput;
  update: ClinicEntityPhoneNumberUpdateInput;
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicOfficeHourArgs = {
  create: ClinicOfficeHourCreateInput;
  update: ClinicOfficeHourUpdateInput;
  where: ClinicOfficeHourWhereUniqueInput;
};


export type MutationUpsertOneClinicOnboardingArgs = {
  create: ClinicOnboardingCreateInput;
  update: ClinicOnboardingUpdateInput;
  where: ClinicOnboardingWhereUniqueInput;
};


export type MutationUpsertOneClinicPaymentFeeConfigurationArgs = {
  create: ClinicPaymentFeeConfigurationCreateInput;
  update: ClinicPaymentFeeConfigurationUpdateInput;
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type MutationUpsertOneClinicPetArgs = {
  create: ClinicPetCreateInput;
  update: ClinicPetUpdateInput;
  where: ClinicPetWhereUniqueInput;
};


export type MutationUpsertOneClinicPetAlertArgs = {
  create: ClinicPetAlertCreateInput;
  update: ClinicPetAlertUpdateInput;
  where: ClinicPetAlertWhereUniqueInput;
};


export type MutationUpsertOneClinicPetCustomFieldValueArgs = {
  create: ClinicPetCustomFieldValueCreateInput;
  update: ClinicPetCustomFieldValueUpdateInput;
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentArgs = {
  create: ClinicPetParentCreateInput;
  update: ClinicPetParentUpdateInput;
  where: ClinicPetParentWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentAddressArgs = {
  create: ClinicPetParentAddressCreateInput;
  update: ClinicPetParentAddressUpdateInput;
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentCustomFieldValueArgs = {
  create: ClinicPetParentCustomFieldValueCreateInput;
  update: ClinicPetParentCustomFieldValueUpdateInput;
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentDeviceArgs = {
  create: ClinicPetParentDeviceCreateInput;
  update: ClinicPetParentDeviceUpdateInput;
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type MutationUpsertOneClinicPetParentPimsSyncStatusArgs = {
  create: ClinicPetParentPimsSyncStatusCreateInput;
  update: ClinicPetParentPimsSyncStatusUpdateInput;
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type MutationUpsertOneClinicPetPimsSyncStatusArgs = {
  create: ClinicPetPimsSyncStatusCreateInput;
  update: ClinicPetPimsSyncStatusUpdateInput;
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type MutationUpsertOneClinicPhoneNumberArgs = {
  create: ClinicPhoneNumberCreateInput;
  update: ClinicPhoneNumberUpdateInput;
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationArgs = {
  create: ClinicPimsIntegrationCreateInput;
  update: ClinicPimsIntegrationUpdateInput;
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationCapabilityArgs = {
  create: ClinicPimsIntegrationCapabilityCreateInput;
  update: ClinicPimsIntegrationCapabilityUpdateInput;
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type MutationUpsertOneClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  create: ClinicPimsIntegrationCapabilityHistoryEntryCreateInput;
  update: ClinicPimsIntegrationCapabilityHistoryEntryUpdateInput;
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type MutationUpsertOneClinicPostcardSettingArgs = {
  create: ClinicPostcardSettingCreateInput;
  update: ClinicPostcardSettingUpdateInput;
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicRoomArgs = {
  create: ClinicRoomCreateInput;
  update: ClinicRoomUpdateInput;
  where: ClinicRoomWhereUniqueInput;
};


export type MutationUpsertOneClinicSettingArgs = {
  create: ClinicSettingCreateInput;
  update: ClinicSettingUpdateInput;
  where: ClinicSettingWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetRequestArgs = {
  create: ClinicWidgetRequestCreateInput;
  update: ClinicWidgetRequestUpdateInput;
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetRequestTypeArgs = {
  create: ClinicWidgetRequestTypeCreateInput;
  update: ClinicWidgetRequestTypeUpdateInput;
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type MutationUpsertOneClinicWidgetSettingArgs = {
  create: ClinicWidgetSettingCreateInput;
  update: ClinicWidgetSettingUpdateInput;
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type MutationUpsertOneColorArgs = {
  create: ColorCreateInput;
  update: ColorUpdateInput;
  where: ColorWhereUniqueInput;
};


export type MutationUpsertOneConditionArgs = {
  create: ConditionCreateInput;
  update: ConditionUpdateInput;
  where: ConditionWhereUniqueInput;
};


export type MutationUpsertOneConditionSetArgs = {
  create: ConditionSetCreateInput;
  update: ConditionSetUpdateInput;
  where: ConditionSetWhereUniqueInput;
};


export type MutationUpsertOneConditionalEntityEvaluationArgs = {
  create: ConditionalEntityEvaluationCreateInput;
  update: ConditionalEntityEvaluationUpdateInput;
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type MutationUpsertOneConsultationRequestArgs = {
  create: ConsultationRequestCreateInput;
  update: ConsultationRequestUpdateInput;
  where: ConsultationRequestWhereUniqueInput;
};


export type MutationUpsertOneCustomFieldDefinitionArgs = {
  create: CustomFieldDefinitionCreateInput;
  update: CustomFieldDefinitionUpdateInput;
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type MutationUpsertOneCustomFieldPimsWritebackLogEntryArgs = {
  create: CustomFieldPimsWritebackLogEntryCreateInput;
  update: CustomFieldPimsWritebackLogEntryUpdateInput;
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingAppointmentTypeSettingArgs = {
  create: DirectBookingAppointmentTypeSettingCreateInput;
  update: DirectBookingAppointmentTypeSettingUpdateInput;
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingExclusionRuleArgs = {
  create: DirectBookingExclusionRuleCreateInput;
  update: DirectBookingExclusionRuleUpdateInput;
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type MutationUpsertOneDirectBookingExclusionTimeSlotArgs = {
  create: DirectBookingExclusionTimeSlotCreateInput;
  update: DirectBookingExclusionTimeSlotUpdateInput;
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type MutationUpsertOneDisbursementArgs = {
  create: DisbursementCreateInput;
  update: DisbursementUpdateInput;
  where: DisbursementWhereUniqueInput;
};


export type MutationUpsertOneDocumentationArgs = {
  create: DocumentationCreateInput;
  update: DocumentationUpdateInput;
  where: DocumentationWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignArgs = {
  create: EmailCampaignCreateInput;
  update: EmailCampaignUpdateInput;
  where: EmailCampaignWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignCustomListArgs = {
  create: EmailCampaignCustomListCreateInput;
  update: EmailCampaignCustomListUpdateInput;
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignCustomListRecipientArgs = {
  create: EmailCampaignCustomListRecipientCreateInput;
  update: EmailCampaignCustomListRecipientUpdateInput;
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignCustomListUploadHistoryArgs = {
  create: EmailCampaignCustomListUploadHistoryCreateInput;
  update: EmailCampaignCustomListUploadHistoryUpdateInput;
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignLogArgs = {
  create: EmailCampaignLogCreateInput;
  update: EmailCampaignLogUpdateInput;
  where: EmailCampaignLogWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignTemplateArgs = {
  create: EmailCampaignTemplateCreateInput;
  update: EmailCampaignTemplateUpdateInput;
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type MutationUpsertOneEmailCampaignUnsubscribeArgs = {
  create: EmailCampaignUnsubscribeCreateInput;
  update: EmailCampaignUnsubscribeUpdateInput;
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type MutationUpsertOneEmailProviderLogArgs = {
  create: EmailProviderLogCreateInput;
  update: EmailProviderLogUpdateInput;
  where: EmailProviderLogWhereUniqueInput;
};


export type MutationUpsertOneEmergencyClinicsArgs = {
  create: EmergencyClinicsCreateInput;
  update: EmergencyClinicsUpdateInput;
  where: EmergencyClinicsWhereUniqueInput;
};


export type MutationUpsertOneEnrollmentSupportEventArgs = {
  create: EnrollmentSupportEventCreateInput;
  update: EnrollmentSupportEventUpdateInput;
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type MutationUpsertOneEzyVetIntegrationArgs = {
  create: EzyVetIntegrationCreateInput;
  update: EzyVetIntegrationUpdateInput;
  where: EzyVetIntegrationWhereUniqueInput;
};


export type MutationUpsertOneFeatureFlagArgs = {
  create: FeatureFlagCreateInput;
  update: FeatureFlagUpdateInput;
  where: FeatureFlagWhereUniqueInput;
};


export type MutationUpsertOneFinancialAdjustmentArgs = {
  create: FinancialAdjustmentCreateInput;
  update: FinancialAdjustmentUpdateInput;
  where: FinancialAdjustmentWhereUniqueInput;
};


export type MutationUpsertOneFinancialTransactionArgs = {
  create: FinancialTransactionCreateInput;
  update: FinancialTransactionUpdateInput;
  where: FinancialTransactionWhereUniqueInput;
};


export type MutationUpsertOneFormSubmissionArgs = {
  create: FormSubmissionCreateInput;
  update: FormSubmissionUpdateInput;
  where: FormSubmissionWhereUniqueInput;
};


export type MutationUpsertOneFormTemplateArgs = {
  create: FormTemplateCreateInput;
  update: FormTemplateUpdateInput;
  where: FormTemplateWhereUniqueInput;
};


export type MutationUpsertOneGlobalPetParentArgs = {
  create: GlobalPetParentCreateInput;
  update: GlobalPetParentUpdateInput;
  where: GlobalPetParentWhereUniqueInput;
};


export type MutationUpsertOneHillsToHomeApiLogArgs = {
  create: HillsToHomeApiLogCreateInput;
  update: HillsToHomeApiLogUpdateInput;
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type MutationUpsertOneInformAudienceDefinitionArgs = {
  create: InformAudienceDefinitionCreateInput;
  update: InformAudienceDefinitionUpdateInput;
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type MutationUpsertOneInformAutomationCampaignArgs = {
  create: InformAutomationCampaignCreateInput;
  update: InformAutomationCampaignUpdateInput;
  where: InformAutomationCampaignWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignArgs = {
  create: InformCampaignCreateInput;
  update: InformCampaignUpdateInput;
  where: InformCampaignWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignEnrollmentArgs = {
  create: InformCampaignEnrollmentCreateInput;
  update: InformCampaignEnrollmentUpdateInput;
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignEnrollmentCanonicalServiceArgs = {
  create: InformCampaignEnrollmentCanonicalServiceCreateInput;
  update: InformCampaignEnrollmentCanonicalServiceUpdateInput;
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignExecutionArgs = {
  create: InformCampaignExecutionCreateInput;
  update: InformCampaignExecutionUpdateInput;
  where: InformCampaignExecutionWhereUniqueInput;
};


export type MutationUpsertOneInformCampaignGroupArgs = {
  create: InformCampaignGroupCreateInput;
  update: InformCampaignGroupUpdateInput;
  where: InformCampaignGroupWhereUniqueInput;
};


export type MutationUpsertOneInformControlGroupParticipantArgs = {
  create: InformControlGroupParticipantCreateInput;
  update: InformControlGroupParticipantUpdateInput;
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type MutationUpsertOneInformEmailTemplateArgs = {
  create: InformEmailTemplateCreateInput;
  update: InformEmailTemplateUpdateInput;
  where: InformEmailTemplateWhereUniqueInput;
};


export type MutationUpsertOneInformEntityHistoryArgs = {
  create: InformEntityHistoryCreateInput;
  update: InformEntityHistoryUpdateInput;
  where: InformEntityHistoryWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerArgs = {
  create: InformPartnerCreateInput;
  update: InformPartnerUpdateInput;
  where: InformPartnerWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerCustomerArgs = {
  create: InformPartnerCustomerCreateInput;
  update: InformPartnerCustomerUpdateInput;
  where: InformPartnerCustomerWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerCustomerPharmacyProviderArgs = {
  create: InformPartnerCustomerPharmacyProviderCreateInput;
  update: InformPartnerCustomerPharmacyProviderUpdateInput;
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerIntegrationArgs = {
  create: InformPartnerIntegrationCreateInput;
  update: InformPartnerIntegrationUpdateInput;
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerIntegrationModelArgs = {
  create: InformPartnerIntegrationModelCreateInput;
  update: InformPartnerIntegrationModelUpdateInput;
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerProgramArgs = {
  create: InformPartnerProgramCreateInput;
  update: InformPartnerProgramUpdateInput;
  where: InformPartnerProgramWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerProgramEnrollmentArgs = {
  create: InformPartnerProgramEnrollmentCreateInput;
  update: InformPartnerProgramEnrollmentUpdateInput;
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerUserArgs = {
  create: InformPartnerUserCreateInput;
  update: InformPartnerUserUpdateInput;
  where: InformPartnerUserWhereUniqueInput;
};


export type MutationUpsertOneInformPartnerUserRoleArgs = {
  create: InformPartnerUserRoleCreateInput;
  update: InformPartnerUserRoleUpdateInput;
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type MutationUpsertOneInformScheduledCampaignArgs = {
  create: InformScheduledCampaignCreateInput;
  update: InformScheduledCampaignUpdateInput;
  where: InformScheduledCampaignWhereUniqueInput;
};


export type MutationUpsertOneInstinctIntegrationArgs = {
  create: InstinctIntegrationCreateInput;
  update: InstinctIntegrationUpdateInput;
  where: InstinctIntegrationWhereUniqueInput;
};


export type MutationUpsertOneInsuranceIntegrationArgs = {
  create: InsuranceIntegrationCreateInput;
  update: InsuranceIntegrationUpdateInput;
  where: InsuranceIntegrationWhereUniqueInput;
};


export type MutationUpsertOneIntegrationOutageHistoryEntryArgs = {
  create: IntegrationOutageHistoryEntryCreateInput;
  update: IntegrationOutageHistoryEntryUpdateInput;
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type MutationUpsertOneInterchangeRateArgs = {
  create: InterchangeRateCreateInput;
  update: InterchangeRateUpdateInput;
  where: InterchangeRateWhereUniqueInput;
};


export type MutationUpsertOneInvoiceArgs = {
  create: InvoiceCreateInput;
  update: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpsertOneInvoiceLineItemArgs = {
  create: InvoiceLineItemCreateInput;
  update: InvoiceLineItemUpdateInput;
  where: InvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOneInvoicePimsWritebackLogEntryArgs = {
  create: InvoicePimsWritebackLogEntryCreateInput;
  update: InvoicePimsWritebackLogEntryUpdateInput;
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOneLapsedPetParentTriggersArgs = {
  create: LapsedPetParentTriggersCreateInput;
  update: LapsedPetParentTriggersUpdateInput;
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type MutationUpsertOneLegalEntityArgs = {
  create: LegalEntityCreateInput;
  update: LegalEntityUpdateInput;
  where: LegalEntityWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountArgs = {
  create: LoyaltyAccountCreateInput;
  update: LoyaltyAccountUpdateInput;
  where: LoyaltyAccountWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountHolderArgs = {
  create: LoyaltyAccountHolderCreateInput;
  update: LoyaltyAccountHolderUpdateInput;
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyAccountMergerArgs = {
  create: LoyaltyAccountMergerCreateInput;
  update: LoyaltyAccountMergerUpdateInput;
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyInvoicePointGranterArgs = {
  create: LoyaltyInvoicePointGranterCreateInput;
  update: LoyaltyInvoicePointGranterUpdateInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointGrantingServiceArgs = {
  create: LoyaltyPointGrantingServiceCreateInput;
  update: LoyaltyPointGrantingServiceUpdateInput;
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointGrantingServiceCategoryArgs = {
  create: LoyaltyPointGrantingServiceCategoryCreateInput;
  update: LoyaltyPointGrantingServiceCategoryUpdateInput;
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyPointUserGrantArgs = {
  create: LoyaltyPointUserGrantCreateInput;
  update: LoyaltyPointUserGrantUpdateInput;
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyRewardArgs = {
  create: LoyaltyRewardCreateInput;
  update: LoyaltyRewardUpdateInput;
  where: LoyaltyRewardWhereUniqueInput;
};


export type MutationUpsertOneLoyaltyRewardRedemptionArgs = {
  create: LoyaltyRewardRedemptionCreateInput;
  update: LoyaltyRewardRedemptionUpdateInput;
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertArgs = {
  create: MassTextAlertCreateInput;
  update: MassTextAlertUpdateInput;
  where: MassTextAlertWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertEntryArgs = {
  create: MassTextAlertEntryCreateInput;
  update: MassTextAlertEntryUpdateInput;
  where: MassTextAlertEntryWhereUniqueInput;
};


export type MutationUpsertOneMassTextAlertEntryAppointmentArgs = {
  create: MassTextAlertEntryAppointmentCreateInput;
  update: MassTextAlertEntryAppointmentUpdateInput;
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type MutationUpsertOneMediaArgs = {
  create: MediaCreateInput;
  update: MediaUpdateInput;
  where: MediaWhereUniqueInput;
};


export type MutationUpsertOneMessageTemplateArgs = {
  create: MessageTemplateCreateInput;
  update: MessageTemplateUpdateInput;
  where: MessageTemplateWhereUniqueInput;
};


export type MutationUpsertOneMessagingCampaignArgs = {
  create: MessagingCampaignCreateInput;
  update: MessagingCampaignUpdateInput;
  where: MessagingCampaignWhereUniqueInput;
};


export type MutationUpsertOneMessagingPartnerArgs = {
  create: MessagingPartnerCreateInput;
  update: MessagingPartnerUpdateInput;
  where: MessagingPartnerWhereUniqueInput;
};


export type MutationUpsertOneNonPrismaSchemaScriptRanArgs = {
  create: NonPrismaSchemaScriptRanCreateInput;
  update: NonPrismaSchemaScriptRanUpdateInput;
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type MutationUpsertOneNotificationEventToMessageArgs = {
  create: NotificationEventToMessageCreateInput;
  update: NotificationEventToMessageUpdateInput;
  where: NotificationEventToMessageWhereUniqueInput;
};


export type MutationUpsertOneOrganizationArgs = {
  create: OrganizationCreateInput;
  update: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpsertOneOrganizationCareBenefitToClinicServiceArgs = {
  create: OrganizationCareBenefitToClinicServiceCreateInput;
  update: OrganizationCareBenefitToClinicServiceUpdateInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type MutationUpsertOneOrganizationCareBenefitToClinicServiceCategoryArgs = {
  create: OrganizationCareBenefitToClinicServiceCategoryCreateInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUpdateInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetArgs = {
  create: OrganizationPetCreateInput;
  update: OrganizationPetUpdateInput;
  where: OrganizationPetWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetParentArgs = {
  create: OrganizationPetParentCreateInput;
  update: OrganizationPetParentUpdateInput;
  where: OrganizationPetParentWhereUniqueInput;
};


export type MutationUpsertOneOrganizationPetToOrganizationPetParentArgs = {
  create: OrganizationPetToOrganizationPetParentCreateInput;
  update: OrganizationPetToOrganizationPetParentUpdateInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type MutationUpsertOnePpcIntegrationArgs = {
  create: PpcIntegrationCreateInput;
  update: PpcIntegrationUpdateInput;
  where: PpcIntegrationWhereUniqueInput;
};


export type MutationUpsertOnePayoutBatchingPeriodArgs = {
  create: PayoutBatchingPeriodCreateInput;
  update: PayoutBatchingPeriodUpdateInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};


export type MutationUpsertOnePermissionArgs = {
  create: PermissionCreateInput;
  update: PermissionUpdateInput;
  where: PermissionWhereUniqueInput;
};


export type MutationUpsertOnePetParentSettingArgs = {
  create: PetParentSettingCreateInput;
  update: PetParentSettingUpdateInput;
  where: PetParentSettingWhereUniqueInput;
};


export type MutationUpsertOnePetPortalSettingArgs = {
  create: PetPortalSettingCreateInput;
  update: PetPortalSettingUpdateInput;
  where: PetPortalSettingWhereUniqueInput;
};


export type MutationUpsertOnePhoneNumberLookupRequestArgs = {
  create: PhoneNumberLookupRequestCreateInput;
  update: PhoneNumberLookupRequestUpdateInput;
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type MutationUpsertOnePhoneNumberLookupResultArgs = {
  create: PhoneNumberLookupResultCreateInput;
  update: PhoneNumberLookupResultUpdateInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type MutationUpsertOnePimsInvoiceArgs = {
  create: PimsInvoiceCreateInput;
  update: PimsInvoiceUpdateInput;
  where: PimsInvoiceWhereUniqueInput;
};


export type MutationUpsertOnePimsInvoiceLineItemArgs = {
  create: PimsInvoiceLineItemCreateInput;
  update: PimsInvoiceLineItemUpdateInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type MutationUpsertOnePimsWritebackLogEntryArgs = {
  create: PimsWritebackLogEntryCreateInput;
  update: PimsWritebackLogEntryUpdateInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type MutationUpsertOnePrescriptionArgs = {
  create: PrescriptionCreateInput;
  update: PrescriptionUpdateInput;
  where: PrescriptionWhereUniqueInput;
};


export type MutationUpsertOneProductArgs = {
  create: ProductCreateInput;
  update: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpsertOneProviderAppointmentTypeRoomMapArgs = {
  create: ProviderAppointmentTypeRoomMapCreateInput;
  update: ProviderAppointmentTypeRoomMapUpdateInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type MutationUpsertOnePushNotificationLogArgs = {
  create: PushNotificationLogCreateInput;
  update: PushNotificationLogUpdateInput;
  where: PushNotificationLogWhereUniqueInput;
};


export type MutationUpsertOneRulesArgs = {
  create: RulesCreateInput;
  update: RulesUpdateInput;
  where: RulesWhereUniqueInput;
};


export type MutationUpsertOneSegmentArgs = {
  create: SegmentCreateInput;
  update: SegmentUpdateInput;
  where: SegmentWhereUniqueInput;
};


export type MutationUpsertOneSentTrupanionExamDayOfferArgs = {
  create: SentTrupanionExamDayOfferCreateInput;
  update: SentTrupanionExamDayOfferUpdateInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type MutationUpsertOneServiceArgs = {
  create: ServiceCreateInput;
  update: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpsertOneServiceCategoryArgs = {
  create: ServiceCategoryCreateInput;
  update: ServiceCategoryUpdateInput;
  where: ServiceCategoryWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderArgs = {
  create: ServiceReminderCreateInput;
  update: ServiceReminderUpdateInput;
  where: ServiceReminderWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderNotificationArgs = {
  create: ServiceReminderNotificationCreateInput;
  update: ServiceReminderNotificationUpdateInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type MutationUpsertOneServiceReminderTimingArgs = {
  create: ServiceReminderTimingCreateInput;
  update: ServiceReminderTimingUpdateInput;
  where: ServiceReminderTimingWhereUniqueInput;
};


export type MutationUpsertOneSexArgs = {
  create: SexCreateInput;
  update: SexUpdateInput;
  where: SexWhereUniqueInput;
};


export type MutationUpsertOneSmsNotificationStatusArgs = {
  create: SmsNotificationStatusCreateInput;
  update: SmsNotificationStatusUpdateInput;
  where: SmsNotificationStatusWhereUniqueInput;
};


export type MutationUpsertOneSpeciesArgs = {
  create: SpeciesCreateInput;
  update: SpeciesUpdateInput;
  where: SpeciesWhereUniqueInput;
};


export type MutationUpsertOneStaffRoleArgs = {
  create: StaffRoleCreateInput;
  update: StaffRoleUpdateInput;
  where: StaffRoleWhereUniqueInput;
};


export type MutationUpsertOneStripeCustomerArgs = {
  create: StripeCustomerCreateInput;
  update: StripeCustomerUpdateInput;
  where: StripeCustomerWhereUniqueInput;
};


export type MutationUpsertOneStripeDisputeArgs = {
  create: StripeDisputeCreateInput;
  update: StripeDisputeUpdateInput;
  where: StripeDisputeWhereUniqueInput;
};


export type MutationUpsertOneStripeInvoiceArgs = {
  create: StripeInvoiceCreateInput;
  update: StripeInvoiceUpdateInput;
  where: StripeInvoiceWhereUniqueInput;
};


export type MutationUpsertOneStripeInvoiceItemArgs = {
  create: StripeInvoiceItemCreateInput;
  update: StripeInvoiceItemUpdateInput;
  where: StripeInvoiceItemWhereUniqueInput;
};


export type MutationUpsertOneStripePaymentIntentArgs = {
  create: StripePaymentIntentCreateInput;
  update: StripePaymentIntentUpdateInput;
  where: StripePaymentIntentWhereUniqueInput;
};


export type MutationUpsertOneStripePaymentMethodArgs = {
  create: StripePaymentMethodCreateInput;
  update: StripePaymentMethodUpdateInput;
  where: StripePaymentMethodWhereUniqueInput;
};


export type MutationUpsertOneStripePayoutArgs = {
  create: StripePayoutCreateInput;
  update: StripePayoutUpdateInput;
  where: StripePayoutWhereUniqueInput;
};


export type MutationUpsertOneStripePayoutAccountArgs = {
  create: StripePayoutAccountCreateInput;
  update: StripePayoutAccountUpdateInput;
  where: StripePayoutAccountWhereUniqueInput;
};


export type MutationUpsertOneStripeReceiptArgs = {
  create: StripeReceiptCreateInput;
  update: StripeReceiptUpdateInput;
  where: StripeReceiptWhereUniqueInput;
};


export type MutationUpsertOneStripeReceiptHistoryArgs = {
  create: StripeReceiptHistoryCreateInput;
  update: StripeReceiptHistoryUpdateInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type MutationUpsertOneStripeRefundArgs = {
  create: StripeRefundCreateInput;
  update: StripeRefundUpdateInput;
  where: StripeRefundWhereUniqueInput;
};


export type MutationUpsertOneStripeTerminalArgs = {
  create: StripeTerminalCreateInput;
  update: StripeTerminalUpdateInput;
  where: StripeTerminalWhereUniqueInput;
};


export type MutationUpsertOneStripeTerminalLocationArgs = {
  create: StripeTerminalLocationCreateInput;
  update: StripeTerminalLocationUpdateInput;
  where: StripeTerminalLocationWhereUniqueInput;
};


export type MutationUpsertOneSurveyAppointmentTypeSettingArgs = {
  create: SurveyAppointmentTypeSettingCreateInput;
  update: SurveyAppointmentTypeSettingUpdateInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type MutationUpsertOneSurveyResultArgs = {
  create: SurveyResultCreateInput;
  update: SurveyResultUpdateInput;
  where: SurveyResultWhereUniqueInput;
};


export type MutationUpsertOneSurveySettingArgs = {
  create: SurveySettingCreateInput;
  update: SurveySettingUpdateInput;
  where: SurveySettingWhereUniqueInput;
};


export type MutationUpsertOneSyncVetIntegrationArgs = {
  create: SyncVetIntegrationCreateInput;
  update: SyncVetIntegrationUpdateInput;
  where: SyncVetIntegrationWhereUniqueInput;
};


export type MutationUpsertOneTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationUpsertOneTrupanionCertificateAvailabilityArgs = {
  create: TrupanionCertificateAvailabilityCreateInput;
  update: TrupanionCertificateAvailabilityUpdateInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneTrupanionIntegratedAppointmentTypeArgs = {
  create: TrupanionIntegratedAppointmentTypeCreateInput;
  update: TrupanionIntegratedAppointmentTypeUpdateInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type MutationUpsertOneTrupanionIntegrationArgs = {
  create: TrupanionIntegrationCreateInput;
  update: TrupanionIntegrationUpdateInput;
  where: TrupanionIntegrationWhereUniqueInput;
};


export type MutationUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertOneUserAppointmentGroupingArgs = {
  create: UserAppointmentGroupingCreateInput;
  update: UserAppointmentGroupingUpdateInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type MutationUpsertOneUserBoardFilterArgs = {
  create: UserBoardFilterCreateInput;
  update: UserBoardFilterUpdateInput;
  where: UserBoardFilterWhereUniqueInput;
};


export type MutationUpsertOneUserChannelFilterSelectionArgs = {
  create: UserChannelFilterSelectionCreateInput;
  update: UserChannelFilterSelectionUpdateInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type MutationUpsertOneUserNotificationSettingArgs = {
  create: UserNotificationSettingCreateInput;
  update: UserNotificationSettingUpdateInput;
  where: UserNotificationSettingWhereUniqueInput;
};


export type MutationUpsertOneUserSettingArgs = {
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};


export type MutationUpsertOneVaccinationArgs = {
  create: VaccinationCreateInput;
  update: VaccinationUpdateInput;
  where: VaccinationWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityArgs = {
  create: VetAvailabilityCreateInput;
  update: VetAvailabilityUpdateInput;
  where: VetAvailabilityWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityClinicRoomMapArgs = {
  create: VetAvailabilityClinicRoomMapCreateInput;
  update: VetAvailabilityClinicRoomMapUpdateInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type MutationUpsertOneVetAvailabilityMapArgs = {
  create: VetAvailabilityMapCreateInput;
  update: VetAvailabilityMapUpdateInput;
  where: VetAvailabilityMapWhereUniqueInput;
};


export type MutationUpsertOneVetInfoArgs = {
  create: VetInfoCreateInput;
  update: VetInfoUpdateInput;
  where: VetInfoWhereUniqueInput;
};


export type MutationUpsertOneVetsourceCodeTagArgs = {
  create: VetsourceCodeTagCreateInput;
  update: VetsourceCodeTagUpdateInput;
  where: VetsourceCodeTagWhereUniqueInput;
};


export type MutationUpsertOneVetsourceInstallationArgs = {
  create: VetsourceInstallationCreateInput;
  update: VetsourceInstallationUpdateInput;
  where: VetsourceInstallationWhereUniqueInput;
};


export type MutationUpsertOneVetsourceRevenueCategoryArgs = {
  create: VetsourceRevenueCategoryCreateInput;
  update: VetsourceRevenueCategoryUpdateInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type MutationUpsertOneWorkflowEventSettingArgs = {
  create: WorkflowEventSettingCreateInput;
  update: WorkflowEventSettingUpdateInput;
  where: WorkflowEventSettingWhereUniqueInput;
};


export type MutationUpsertUserToClinicArgs = {
  data: UpsertUserToClinicInput;
};


export type MutationVerifyClinicWidgetRequestArgs = {
  where: VerifyClinicWidgetRequestInput;
};


export type MutationVerifyClinicWidgetRequest2Args = {
  where: VerifyClinicWidgetRequestInput;
};


export type MutationVerifyWidgetAuthenticationRequestArgs = {
  data: VerifyWidgetAuthenticationRequestInput;
};

export enum MutationType {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  Upsert = 'upsert'
}

export type NeoIntegration = {
  __typename?: 'NeoIntegration';
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NeoIntegrationCountAggregateOutputType = {
  __typename?: 'NeoIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  authToken: Scalars['Int'];
  branchId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NeoIntegrationCountOrderByAggregateInput = {
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NeoIntegrationCreateInput = {
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutNeoInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationCreateManyInput = {
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: NeoIntegrationWhereUniqueInput;
};

export type NeoIntegrationCreateWithoutClinicPimsIntegrationInput = {
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationMaxAggregateOutputType = {
  __typename?: 'NeoIntegrationMaxAggregateOutputType';
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationMaxOrderByAggregateInput = {
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NeoIntegrationMinAggregateOutputType = {
  __typename?: 'NeoIntegrationMinAggregateOutputType';
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationMinOrderByAggregateInput = {
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum NeoIntegrationOrderByRelevanceFieldEnum {
  AuthToken = 'authToken',
  BranchId = 'branchId',
  Id = 'id'
}

export type NeoIntegrationOrderByRelevanceInput = {
  fields: Array<NeoIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type NeoIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<NeoIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<NeoIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<NeoIntegrationMinOrderByAggregateInput>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NeoIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<NeoIntegrationOrderByRelevanceInput>;
  authToken?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NeoIntegrationRelationFilter = {
  is?: Maybe<NeoIntegrationWhereInput>;
  isNot?: Maybe<NeoIntegrationWhereInput>;
};

export enum NeoIntegrationScalarFieldEnum {
  AuthToken = 'authToken',
  BranchId = 'branchId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type NeoIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NeoIntegrationScalarWhereWithAggregatesInput>>;
  authToken?: Maybe<StringWithAggregatesFilter>;
  branchId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type NeoIntegrationUncheckedCreateInput = {
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutNeoInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  authToken: Scalars['String'];
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUncheckedUpdateInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutNeoNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUncheckedUpdateManyInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUpdateInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutNeoNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUpdateManyMutationInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<NeoIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<NeoIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<NeoIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type NeoIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  authToken?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NeoIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: NeoIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: NeoIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type NeoIntegrationWhereInput = {
  AND?: Maybe<Array<NeoIntegrationWhereInput>>;
  NOT?: Maybe<Array<NeoIntegrationWhereInput>>;
  OR?: Maybe<Array<NeoIntegrationWhereInput>>;
  authToken?: Maybe<StringFilter>;
  branchId?: Maybe<StringNullableFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NeoIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedBoolNullableFilter>;
  _min?: Maybe<NestedBoolNullableFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDateTimeNullableFilter>;
  _min?: Maybe<NestedDateTimeNullableFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAmericanKennelClubBreedGroupNullableFilter = {
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
};

export type NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableFilter>;
  equals?: Maybe<AmericanKennelClubBreedGroup>;
  in?: Maybe<Array<AmericanKennelClubBreedGroup>>;
  not?: Maybe<NestedEnumAmericanKennelClubBreedGroupNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubBreedGroup>>;
};

export type NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
};

export type NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubDemeanorCategory>;
  in?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubDemeanorCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubDemeanorCategory>>;
};

export type NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
};

export type NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubEnergyLevelCategory>;
  in?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubEnergyLevelCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubEnergyLevelCategory>>;
};

export type NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
};

export type NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
  in?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubGroomingFrequencyCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubGroomingFrequencyCategory>>;
};

export type NestedEnumAmericanKennelClubSheddingCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
};

export type NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubSheddingCategory>;
  in?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubSheddingCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubSheddingCategory>>;
};

export type NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter = {
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
};

export type NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  _min?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableFilter>;
  equals?: Maybe<AmericanKennelClubTrainabilityCategory>;
  in?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
  not?: Maybe<NestedEnumAmericanKennelClubTrainabilityCategoryNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AmericanKennelClubTrainabilityCategory>>;
};

export type NestedEnumAppointmentDateRangeTypeFilter = {
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
};

export type NestedEnumAppointmentDateRangeTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  _min?: Maybe<NestedEnumAppointmentDateRangeTypeFilter>;
  equals?: Maybe<AppointmentDateRangeType>;
  in?: Maybe<Array<AppointmentDateRangeType>>;
  not?: Maybe<NestedEnumAppointmentDateRangeTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentDateRangeType>>;
};

export type NestedEnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type NestedEnumAppointmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentStatusFilter>;
  _min?: Maybe<NestedEnumAppointmentStatusFilter>;
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type NestedEnumAppointmentTypeVisibilityFilter = {
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
};

export type NestedEnumAppointmentTypeVisibilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  _min?: Maybe<NestedEnumAppointmentTypeVisibilityFilter>;
  equals?: Maybe<AppointmentTypeVisibility>;
  in?: Maybe<Array<AppointmentTypeVisibility>>;
  not?: Maybe<NestedEnumAppointmentTypeVisibilityWithAggregatesFilter>;
  notIn?: Maybe<Array<AppointmentTypeVisibility>>;
};

export type NestedEnumAutoResponseSuggestionStatusFilter = {
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
};

export type NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  _min?: Maybe<NestedEnumAutoResponseSuggestionStatusFilter>;
  equals?: Maybe<AutoResponseSuggestionStatus>;
  in?: Maybe<Array<AutoResponseSuggestionStatus>>;
  not?: Maybe<NestedEnumAutoResponseSuggestionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutoResponseSuggestionStatus>>;
};

export type NestedEnumAutomatedTriggerTypeNullableFilter = {
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
};

export type NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  _min?: Maybe<NestedEnumAutomatedTriggerTypeNullableFilter>;
  equals?: Maybe<AutomatedTriggerType>;
  in?: Maybe<Array<AutomatedTriggerType>>;
  not?: Maybe<NestedEnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomatedTriggerType>>;
};

export type NestedEnumAutomationRunActionPromptTypeFilter = {
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
};

export type NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionPromptTypeFilter>;
  equals?: Maybe<AutomationRunActionPromptType>;
  in?: Maybe<Array<AutomationRunActionPromptType>>;
  not?: Maybe<NestedEnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunActionPromptType>>;
};

export type NestedEnumAutomationRunActionTypeFilter = {
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
};

export type NestedEnumAutomationRunActionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunActionTypeFilter>;
  equals?: Maybe<AutomationRunActionType>;
  in?: Maybe<Array<AutomationRunActionType>>;
  not?: Maybe<NestedEnumAutomationRunActionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunActionType>>;
};

export type NestedEnumAutomationRunStatusFilter = {
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusFilter>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
};

export type NestedEnumAutomationRunStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunStatusFilter>;
  _min?: Maybe<NestedEnumAutomationRunStatusFilter>;
  equals?: Maybe<AutomationRunStatus>;
  in?: Maybe<Array<AutomationRunStatus>>;
  not?: Maybe<NestedEnumAutomationRunStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunStatus>>;
};

export type NestedEnumAutomationRunTypeFilter = {
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeFilter>;
  notIn?: Maybe<Array<AutomationRunType>>;
};

export type NestedEnumAutomationRunTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationRunTypeFilter>;
  _min?: Maybe<NestedEnumAutomationRunTypeFilter>;
  equals?: Maybe<AutomationRunType>;
  in?: Maybe<Array<AutomationRunType>>;
  not?: Maybe<NestedEnumAutomationRunTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationRunType>>;
};

export type NestedEnumAutomationStatusFilter = {
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusFilter>;
  notIn?: Maybe<Array<AutomationStatus>>;
};

export type NestedEnumAutomationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumAutomationStatusFilter>;
  _min?: Maybe<NestedEnumAutomationStatusFilter>;
  equals?: Maybe<AutomationStatus>;
  in?: Maybe<Array<AutomationStatus>>;
  not?: Maybe<NestedEnumAutomationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<AutomationStatus>>;
};

export type NestedEnumBitwerxHealthcheckRunStatusNullableFilter = {
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
};

export type NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableFilter>;
  equals?: Maybe<BitwerxHealthcheckRunStatus>;
  in?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckRunStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckRunStatus>>;
};

export type NestedEnumBitwerxHealthcheckTypeFilter = {
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
};

export type NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  _min?: Maybe<NestedEnumBitwerxHealthcheckTypeFilter>;
  equals?: Maybe<BitwerxHealthcheckType>;
  in?: Maybe<Array<BitwerxHealthcheckType>>;
  not?: Maybe<NestedEnumBitwerxHealthcheckTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxHealthcheckType>>;
};

export type NestedEnumBitwerxPaymentEndpointFilter = {
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
};

export type NestedEnumBitwerxPaymentEndpointWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  _min?: Maybe<NestedEnumBitwerxPaymentEndpointFilter>;
  equals?: Maybe<BitwerxPaymentEndpoint>;
  in?: Maybe<Array<BitwerxPaymentEndpoint>>;
  not?: Maybe<NestedEnumBitwerxPaymentEndpointWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxPaymentEndpoint>>;
};

export type NestedEnumBitwerxSystemFilter = {
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemFilter>;
  notIn?: Maybe<Array<BitwerxSystem>>;
};

export type NestedEnumBitwerxSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBitwerxSystemFilter>;
  _min?: Maybe<NestedEnumBitwerxSystemFilter>;
  equals?: Maybe<BitwerxSystem>;
  in?: Maybe<Array<BitwerxSystem>>;
  not?: Maybe<NestedEnumBitwerxSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<BitwerxSystem>>;
};

export type NestedEnumBoardFilterColumnTypeFilter = {
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
};

export type NestedEnumBoardFilterColumnTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  _min?: Maybe<NestedEnumBoardFilterColumnTypeFilter>;
  equals?: Maybe<BoardFilterColumnType>;
  in?: Maybe<Array<BoardFilterColumnType>>;
  not?: Maybe<NestedEnumBoardFilterColumnTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterColumnType>>;
};

export type NestedEnumBoardFilterDateRangeNullableFilter = {
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
};

export type NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  _min?: Maybe<NestedEnumBoardFilterDateRangeNullableFilter>;
  equals?: Maybe<BoardFilterDateRange>;
  in?: Maybe<Array<BoardFilterDateRange>>;
  not?: Maybe<NestedEnumBoardFilterDateRangeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterDateRange>>;
};

export type NestedEnumBoardFilterViewFilter = {
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewFilter>;
  notIn?: Maybe<Array<BoardFilterView>>;
};

export type NestedEnumBoardFilterViewWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumBoardFilterViewFilter>;
  _min?: Maybe<NestedEnumBoardFilterViewFilter>;
  equals?: Maybe<BoardFilterView>;
  in?: Maybe<Array<BoardFilterView>>;
  not?: Maybe<NestedEnumBoardFilterViewWithAggregatesFilter>;
  notIn?: Maybe<Array<BoardFilterView>>;
};

export type NestedEnumCallHistoryTypeFilter = {
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeFilter>;
  notIn?: Maybe<Array<CallHistoryType>>;
};

export type NestedEnumCallHistoryTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallHistoryTypeFilter>;
  _min?: Maybe<NestedEnumCallHistoryTypeFilter>;
  equals?: Maybe<CallHistoryType>;
  in?: Maybe<Array<CallHistoryType>>;
  not?: Maybe<NestedEnumCallHistoryTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CallHistoryType>>;
};

export type NestedEnumCallProviderFilter = {
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderFilter>;
  notIn?: Maybe<Array<CallProvider>>;
};

export type NestedEnumCallProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallProviderFilter>;
  _min?: Maybe<NestedEnumCallProviderFilter>;
  equals?: Maybe<CallProvider>;
  in?: Maybe<Array<CallProvider>>;
  not?: Maybe<NestedEnumCallProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<CallProvider>>;
};

export type NestedEnumCallStatusFilter = {
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusFilter>;
  notIn?: Maybe<Array<CallStatus>>;
};

export type NestedEnumCallStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCallStatusFilter>;
  _min?: Maybe<NestedEnumCallStatusFilter>;
  equals?: Maybe<CallStatus>;
  in?: Maybe<Array<CallStatus>>;
  not?: Maybe<NestedEnumCallStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CallStatus>>;
};

export type NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
};

export type NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableFilter>;
  equals?: Maybe<CampaignRegistryBrandIdentityStatus>;
  in?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryBrandIdentityStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CampaignRegistryBrandIdentityStatus>>;
};

export type NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter = {
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
};

export type NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  _min?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableFilter>;
  equals?: Maybe<CampaignRegistryCampaignSharingStatus>;
  in?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
  not?: Maybe<NestedEnumCampaignRegistryCampaignSharingStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CampaignRegistryCampaignSharingStatus>>;
};

export type NestedEnumCancelReasonTypeNullableFilter = {
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  notIn?: Maybe<Array<CancelReasonType>>;
};

export type NestedEnumCancelReasonTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  _min?: Maybe<NestedEnumCancelReasonTypeNullableFilter>;
  equals?: Maybe<CancelReasonType>;
  in?: Maybe<Array<CancelReasonType>>;
  not?: Maybe<NestedEnumCancelReasonTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CancelReasonType>>;
};

export type NestedEnumCanonicalDataSourceFilter = {
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
};

export type NestedEnumCanonicalDataSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  _min?: Maybe<NestedEnumCanonicalDataSourceFilter>;
  equals?: Maybe<CanonicalDataSource>;
  in?: Maybe<Array<CanonicalDataSource>>;
  not?: Maybe<NestedEnumCanonicalDataSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalDataSource>>;
};

export type NestedEnumCanonicalServiceStatusFilter = {
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
};

export type NestedEnumCanonicalServiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceStatusFilter>;
  equals?: Maybe<CanonicalServiceStatus>;
  in?: Maybe<Array<CanonicalServiceStatus>>;
  not?: Maybe<NestedEnumCanonicalServiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalServiceStatus>>;
};

export type NestedEnumCanonicalServiceTypeFilter = {
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
};

export type NestedEnumCanonicalServiceTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  _min?: Maybe<NestedEnumCanonicalServiceTypeFilter>;
  equals?: Maybe<CanonicalServiceType>;
  in?: Maybe<Array<CanonicalServiceType>>;
  not?: Maybe<NestedEnumCanonicalServiceTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CanonicalServiceType>>;
};

export type NestedEnumCardBrandFilter = {
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandFilter>;
  notIn?: Maybe<Array<CardBrand>>;
};

export type NestedEnumCardBrandWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCardBrandFilter>;
  _min?: Maybe<NestedEnumCardBrandFilter>;
  equals?: Maybe<CardBrand>;
  in?: Maybe<Array<CardBrand>>;
  not?: Maybe<NestedEnumCardBrandWithAggregatesFilter>;
  notIn?: Maybe<Array<CardBrand>>;
};

export type NestedEnumCardFundingNullableFilter = {
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableFilter>;
  notIn?: Maybe<Array<CardFunding>>;
};

export type NestedEnumCardFundingNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCardFundingNullableFilter>;
  _min?: Maybe<NestedEnumCardFundingNullableFilter>;
  equals?: Maybe<CardFunding>;
  in?: Maybe<Array<CardFunding>>;
  not?: Maybe<NestedEnumCardFundingNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CardFunding>>;
};

export type NestedEnumCareBenefitTypeFilter = {
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeFilter>;
  notIn?: Maybe<Array<CareBenefitType>>;
};

export type NestedEnumCareBenefitTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareBenefitTypeFilter>;
  _min?: Maybe<NestedEnumCareBenefitTypeFilter>;
  equals?: Maybe<CareBenefitType>;
  in?: Maybe<Array<CareBenefitType>>;
  not?: Maybe<NestedEnumCareBenefitTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CareBenefitType>>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableFilter = {
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
};

export type NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  _min?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableFilter>;
  equals?: Maybe<CareBenefitUnitOfMeasure>;
  in?: Maybe<Array<CareBenefitUnitOfMeasure>>;
  not?: Maybe<NestedEnumCareBenefitUnitOfMeasureNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CareBenefitUnitOfMeasure>>;
};

export type NestedEnumCareLapsedPaymentStatusFilter = {
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
};

export type NestedEnumCareLapsedPaymentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  _min?: Maybe<NestedEnumCareLapsedPaymentStatusFilter>;
  equals?: Maybe<CareLapsedPaymentStatus>;
  in?: Maybe<Array<CareLapsedPaymentStatus>>;
  not?: Maybe<NestedEnumCareLapsedPaymentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareLapsedPaymentStatus>>;
};

export type NestedEnumCarePlanCycleTypeFilter = {
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
};

export type NestedEnumCarePlanCycleTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  _min?: Maybe<NestedEnumCarePlanCycleTypeFilter>;
  equals?: Maybe<CarePlanCycleType>;
  in?: Maybe<Array<CarePlanCycleType>>;
  not?: Maybe<NestedEnumCarePlanCycleTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CarePlanCycleType>>;
};

export type NestedEnumCareProgramLaunchStatusFilter = {
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
};

export type NestedEnumCareProgramLaunchStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  _min?: Maybe<NestedEnumCareProgramLaunchStatusFilter>;
  equals?: Maybe<CareProgramLaunchStatus>;
  in?: Maybe<Array<CareProgramLaunchStatus>>;
  not?: Maybe<NestedEnumCareProgramLaunchStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareProgramLaunchStatus>>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableFilter = {
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
};

export type NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableFilter>;
  equals?: Maybe<CareSubscriptionPaymentStatus>;
  in?: Maybe<Array<CareSubscriptionPaymentStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionPaymentStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionPaymentStatus>>;
};

export type NestedEnumCareSubscriptionTrueUpStatusFilter = {
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
};

export type NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpStatusFilter>;
  equals?: Maybe<CareSubscriptionTrueUpStatus>;
  in?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpStatus>>;
};

export type NestedEnumCareSubscriptionTrueUpTypeFilter = {
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
};

export type NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  _min?: Maybe<NestedEnumCareSubscriptionTrueUpTypeFilter>;
  equals?: Maybe<CareSubscriptionTrueUpType>;
  in?: Maybe<Array<CareSubscriptionTrueUpType>>;
  not?: Maybe<NestedEnumCareSubscriptionTrueUpTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CareSubscriptionTrueUpType>>;
};

export type NestedEnumChannelCreationSourceTypeFilter = {
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
};

export type NestedEnumChannelCreationSourceTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  _min?: Maybe<NestedEnumChannelCreationSourceTypeFilter>;
  equals?: Maybe<ChannelCreationSourceType>;
  in?: Maybe<Array<ChannelCreationSourceType>>;
  not?: Maybe<NestedEnumChannelCreationSourceTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelCreationSourceType>>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
};

export type NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableFilter>;
  equals?: Maybe<ChannelMessageAttachmentEntityType>;
  in?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentEntityTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentEntityType>>;
};

export type NestedEnumChannelMessageAttachmentTypeFilter = {
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
};

export type NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentTypeFilter>;
  equals?: Maybe<ChannelMessageAttachmentType>;
  in?: Maybe<Array<ChannelMessageAttachmentType>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentType>>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableFilter = {
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
};

export type NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  _min?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableFilter>;
  equals?: Maybe<ChannelMessageAttachmentUploadStatus>;
  in?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
  not?: Maybe<NestedEnumChannelMessageAttachmentUploadStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelMessageAttachmentUploadStatus>>;
};

export type NestedEnumChannelStatusActionFilter = {
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionFilter>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
};

export type NestedEnumChannelStatusActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChannelStatusActionFilter>;
  _min?: Maybe<NestedEnumChannelStatusActionFilter>;
  equals?: Maybe<ChannelStatusAction>;
  in?: Maybe<Array<ChannelStatusAction>>;
  not?: Maybe<NestedEnumChannelStatusActionWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelStatusAction>>;
};

export type NestedEnumChannelTypeNullableFilter = {
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableFilter>;
  notIn?: Maybe<Array<ChannelType>>;
};

export type NestedEnumChannelTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelTypeNullableFilter>;
  _min?: Maybe<NestedEnumChannelTypeNullableFilter>;
  equals?: Maybe<ChannelType>;
  in?: Maybe<Array<ChannelType>>;
  not?: Maybe<NestedEnumChannelTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelType>>;
};

export type NestedEnumChannelVisibilityNullableFilter = {
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  notIn?: Maybe<Array<ChannelVisibility>>;
};

export type NestedEnumChannelVisibilityNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  _min?: Maybe<NestedEnumChannelVisibilityNullableFilter>;
  equals?: Maybe<ChannelVisibility>;
  in?: Maybe<Array<ChannelVisibility>>;
  not?: Maybe<NestedEnumChannelVisibilityNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ChannelVisibility>>;
};

export type NestedEnumChargeCaptureTypeFilter = {
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
};

export type NestedEnumChargeCaptureTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  _min?: Maybe<NestedEnumChargeCaptureTypeFilter>;
  equals?: Maybe<ChargeCaptureType>;
  in?: Maybe<Array<ChargeCaptureType>>;
  not?: Maybe<NestedEnumChargeCaptureTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ChargeCaptureType>>;
};

export type NestedEnumClientConnectClinicSoftwareFilter = {
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
};

export type NestedEnumClientConnectClinicSoftwareWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  _min?: Maybe<NestedEnumClientConnectClinicSoftwareFilter>;
  equals?: Maybe<ClientConnectClinicSoftware>;
  in?: Maybe<Array<ClientConnectClinicSoftware>>;
  not?: Maybe<NestedEnumClientConnectClinicSoftwareWithAggregatesFilter>;
  notIn?: Maybe<Array<ClientConnectClinicSoftware>>;
};

export type NestedEnumClientConnectSystemFilter = {
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemFilter>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
};

export type NestedEnumClientConnectSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClientConnectSystemFilter>;
  _min?: Maybe<NestedEnumClientConnectSystemFilter>;
  equals?: Maybe<ClientConnectSystem>;
  in?: Maybe<Array<ClientConnectSystem>>;
  not?: Maybe<NestedEnumClientConnectSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<ClientConnectSystem>>;
};

export type NestedEnumClinicEntityCreationSourceNullableFilter = {
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
};

export type NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityCreationSourceNullableFilter>;
  equals?: Maybe<ClinicEntityCreationSource>;
  in?: Maybe<Array<ClinicEntityCreationSource>>;
  not?: Maybe<NestedEnumClinicEntityCreationSourceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicEntityCreationSource>>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter = {
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
};

export type NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  _min?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableFilter>;
  equals?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
  in?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
  not?: Maybe<NestedEnumClinicEntityPhoneNumberSmsStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicEntityPhoneNumberSmsStatus>>;
};

export type NestedEnumClinicOfficeHoursTypeFilter = {
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
};

export type NestedEnumClinicOfficeHoursTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  _min?: Maybe<NestedEnumClinicOfficeHoursTypeFilter>;
  equals?: Maybe<ClinicOfficeHoursType>;
  in?: Maybe<Array<ClinicOfficeHoursType>>;
  not?: Maybe<NestedEnumClinicOfficeHoursTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicOfficeHoursType>>;
};

export type NestedEnumClinicPetParentAddressTypeNullableFilter = {
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
};

export type NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  _min?: Maybe<NestedEnumClinicPetParentAddressTypeNullableFilter>;
  equals?: Maybe<ClinicPetParentAddressType>;
  in?: Maybe<Array<ClinicPetParentAddressType>>;
  not?: Maybe<NestedEnumClinicPetParentAddressTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicPetParentAddressType>>;
};

export type NestedEnumClinicPimsIntegrationTypeFilter = {
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
};

export type NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  _min?: Maybe<NestedEnumClinicPimsIntegrationTypeFilter>;
  equals?: Maybe<ClinicPimsIntegrationType>;
  in?: Maybe<Array<ClinicPimsIntegrationType>>;
  not?: Maybe<NestedEnumClinicPimsIntegrationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ClinicPimsIntegrationType>>;
};

export type NestedEnumConditionAttributeFilter = {
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeFilter>;
  notIn?: Maybe<Array<ConditionAttribute>>;
};

export type NestedEnumConditionAttributeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionAttributeFilter>;
  _min?: Maybe<NestedEnumConditionAttributeFilter>;
  equals?: Maybe<ConditionAttribute>;
  in?: Maybe<Array<ConditionAttribute>>;
  not?: Maybe<NestedEnumConditionAttributeWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionAttribute>>;
};

export type NestedEnumConditionEvaluationStatusFilter = {
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
};

export type NestedEnumConditionEvaluationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  _min?: Maybe<NestedEnumConditionEvaluationStatusFilter>;
  equals?: Maybe<ConditionEvaluationStatus>;
  in?: Maybe<Array<ConditionEvaluationStatus>>;
  not?: Maybe<NestedEnumConditionEvaluationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionEvaluationStatus>>;
};

export type NestedEnumConditionOperatorFilter = {
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorFilter>;
  notIn?: Maybe<Array<ConditionOperator>>;
};

export type NestedEnumConditionOperatorWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionOperatorFilter>;
  _min?: Maybe<NestedEnumConditionOperatorFilter>;
  equals?: Maybe<ConditionOperator>;
  in?: Maybe<Array<ConditionOperator>>;
  not?: Maybe<NestedEnumConditionOperatorWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionOperator>>;
};

export type NestedEnumConditionSetOperatorFilter = {
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorFilter>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
};

export type NestedEnumConditionSetOperatorWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionSetOperatorFilter>;
  _min?: Maybe<NestedEnumConditionSetOperatorFilter>;
  equals?: Maybe<ConditionSetOperator>;
  in?: Maybe<Array<ConditionSetOperator>>;
  not?: Maybe<NestedEnumConditionSetOperatorWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionSetOperator>>;
};

export type NestedEnumConditionalEntityTypeFilter = {
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
};

export type NestedEnumConditionalEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  _min?: Maybe<NestedEnumConditionalEntityTypeFilter>;
  equals?: Maybe<ConditionalEntityType>;
  in?: Maybe<Array<ConditionalEntityType>>;
  not?: Maybe<NestedEnumConditionalEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ConditionalEntityType>>;
};

export type NestedEnumContactEntityTypeFilter = {
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeFilter>;
  notIn?: Maybe<Array<ContactEntityType>>;
};

export type NestedEnumContactEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumContactEntityTypeFilter>;
  _min?: Maybe<NestedEnumContactEntityTypeFilter>;
  equals?: Maybe<ContactEntityType>;
  in?: Maybe<Array<ContactEntityType>>;
  not?: Maybe<NestedEnumContactEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ContactEntityType>>;
};

export type NestedEnumContactVerificationMethodNullableFilter = {
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
};

export type NestedEnumContactVerificationMethodNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  _min?: Maybe<NestedEnumContactVerificationMethodNullableFilter>;
  equals?: Maybe<ContactVerificationMethod>;
  in?: Maybe<Array<ContactVerificationMethod>>;
  not?: Maybe<NestedEnumContactVerificationMethodNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ContactVerificationMethod>>;
};

export type NestedEnumContextEntityTypeFilter = {
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeFilter>;
  notIn?: Maybe<Array<ContextEntityType>>;
};

export type NestedEnumContextEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumContextEntityTypeFilter>;
  _min?: Maybe<NestedEnumContextEntityTypeFilter>;
  equals?: Maybe<ContextEntityType>;
  in?: Maybe<Array<ContextEntityType>>;
  not?: Maybe<NestedEnumContextEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ContextEntityType>>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type NestedEnumCurrencyWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCurrencyFilter>;
  _min?: Maybe<NestedEnumCurrencyFilter>;
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyWithAggregatesFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type NestedEnumCustomFieldDesignationFilter = {
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
};

export type NestedEnumCustomFieldDesignationWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  _min?: Maybe<NestedEnumCustomFieldDesignationFilter>;
  equals?: Maybe<CustomFieldDesignation>;
  in?: Maybe<Array<CustomFieldDesignation>>;
  not?: Maybe<NestedEnumCustomFieldDesignationWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldDesignation>>;
};

export type NestedEnumCustomFieldNormalizedDataTypeFilter = {
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
};

export type NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldNormalizedDataTypeFilter>;
  equals?: Maybe<CustomFieldNormalizedDataType>;
  in?: Maybe<Array<CustomFieldNormalizedDataType>>;
  not?: Maybe<NestedEnumCustomFieldNormalizedDataTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldNormalizedDataType>>;
};

export type NestedEnumCustomFieldPimsSyncTypeFilter = {
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
};

export type NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldPimsSyncTypeFilter>;
  equals?: Maybe<CustomFieldPimsSyncType>;
  in?: Maybe<Array<CustomFieldPimsSyncType>>;
  not?: Maybe<NestedEnumCustomFieldPimsSyncTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldPimsSyncType>>;
};

export type NestedEnumCustomFieldTargetTypeFilter = {
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
};

export type NestedEnumCustomFieldTargetTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  _min?: Maybe<NestedEnumCustomFieldTargetTypeFilter>;
  equals?: Maybe<CustomFieldTargetType>;
  in?: Maybe<Array<CustomFieldTargetType>>;
  not?: Maybe<NestedEnumCustomFieldTargetTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldTargetType>>;
};

export type NestedEnumCustomFieldsDataSourceNullableFilter = {
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
};

export type NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  _min?: Maybe<NestedEnumCustomFieldsDataSourceNullableFilter>;
  equals?: Maybe<CustomFieldsDataSource>;
  in?: Maybe<Array<CustomFieldsDataSource>>;
  not?: Maybe<NestedEnumCustomFieldsDataSourceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<CustomFieldsDataSource>>;
};

export type NestedEnumDeviceOsNullableFilter = {
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableFilter>;
  notIn?: Maybe<Array<DeviceOs>>;
};

export type NestedEnumDeviceOsNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumDeviceOsNullableFilter>;
  _min?: Maybe<NestedEnumDeviceOsNullableFilter>;
  equals?: Maybe<DeviceOs>;
  in?: Maybe<Array<DeviceOs>>;
  not?: Maybe<NestedEnumDeviceOsNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<DeviceOs>>;
};

export type NestedEnumDeviceTypeNullableFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  notIn?: Maybe<Array<DeviceType>>;
};

export type NestedEnumDeviceTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  _min?: Maybe<NestedEnumDeviceTypeNullableFilter>;
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<DeviceType>>;
};

export type NestedEnumDirectBookingAppointmentTypeClientTypeFilter = {
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
};

export type NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  _min?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeFilter>;
  equals?: Maybe<DirectBookingAppointmentTypeClientType>;
  in?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
  not?: Maybe<NestedEnumDirectBookingAppointmentTypeClientTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<DirectBookingAppointmentTypeClientType>>;
};

export type NestedEnumDirectBookingSpeciesOptionFilter = {
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
};

export type NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  _min?: Maybe<NestedEnumDirectBookingSpeciesOptionFilter>;
  equals?: Maybe<DirectBookingSpeciesOption>;
  in?: Maybe<Array<DirectBookingSpeciesOption>>;
  not?: Maybe<NestedEnumDirectBookingSpeciesOptionWithAggregatesFilter>;
  notIn?: Maybe<Array<DirectBookingSpeciesOption>>;
};

export type NestedEnumDocumentationCategoryFilter = {
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryFilter>;
  notIn?: Maybe<Array<DocumentationCategory>>;
};

export type NestedEnumDocumentationCategoryWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDocumentationCategoryFilter>;
  _min?: Maybe<NestedEnumDocumentationCategoryFilter>;
  equals?: Maybe<DocumentationCategory>;
  in?: Maybe<Array<DocumentationCategory>>;
  not?: Maybe<NestedEnumDocumentationCategoryWithAggregatesFilter>;
  notIn?: Maybe<Array<DocumentationCategory>>;
};

export type NestedEnumDocumentationProviderFilter = {
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderFilter>;
  notIn?: Maybe<Array<DocumentationProvider>>;
};

export type NestedEnumDocumentationProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumDocumentationProviderFilter>;
  _min?: Maybe<NestedEnumDocumentationProviderFilter>;
  equals?: Maybe<DocumentationProvider>;
  in?: Maybe<Array<DocumentationProvider>>;
  not?: Maybe<NestedEnumDocumentationProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<DocumentationProvider>>;
};

export type NestedEnumEmailCampaignCustomListRecipientStatusFilter = {
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
};

export type NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  _min?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusFilter>;
  equals?: Maybe<EmailCampaignCustomListRecipientStatus>;
  in?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
  not?: Maybe<NestedEnumEmailCampaignCustomListRecipientStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignCustomListRecipientStatus>>;
};

export type NestedEnumEmailCampaignLogActionFilter = {
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
};

export type NestedEnumEmailCampaignLogActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogActionFilter>;
  equals?: Maybe<EmailCampaignLogAction>;
  in?: Maybe<Array<EmailCampaignLogAction>>;
  not?: Maybe<NestedEnumEmailCampaignLogActionWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignLogAction>>;
};

export type NestedEnumEmailCampaignLogSkipReasonNullableFilter = {
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
};

export type NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  _min?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableFilter>;
  equals?: Maybe<EmailCampaignLogSkipReason>;
  in?: Maybe<Array<EmailCampaignLogSkipReason>>;
  not?: Maybe<NestedEnumEmailCampaignLogSkipReasonNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignLogSkipReason>>;
};

export type NestedEnumEmailCampaignSegmentationTypeFilter = {
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
};

export type NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  _min?: Maybe<NestedEnumEmailCampaignSegmentationTypeFilter>;
  equals?: Maybe<EmailCampaignSegmentationType>;
  in?: Maybe<Array<EmailCampaignSegmentationType>>;
  not?: Maybe<NestedEnumEmailCampaignSegmentationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignSegmentationType>>;
};

export type NestedEnumEmailCampaignStatusFilter = {
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
};

export type NestedEnumEmailCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  _min?: Maybe<NestedEnumEmailCampaignStatusFilter>;
  equals?: Maybe<EmailCampaignStatus>;
  in?: Maybe<Array<EmailCampaignStatus>>;
  not?: Maybe<NestedEnumEmailCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignStatus>>;
};

export type NestedEnumEmailCampaignTypeFilter = {
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  notIn?: Maybe<Array<EmailCampaignType>>;
};

export type NestedEnumEmailCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  _min?: Maybe<NestedEnumEmailCampaignTypeFilter>;
  equals?: Maybe<EmailCampaignType>;
  in?: Maybe<Array<EmailCampaignType>>;
  not?: Maybe<NestedEnumEmailCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignType>>;
};

export type NestedEnumEmailCampaignUnsubscribeSourceFilter = {
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
};

export type NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  _min?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceFilter>;
  equals?: Maybe<EmailCampaignUnsubscribeSource>;
  in?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
  not?: Maybe<NestedEnumEmailCampaignUnsubscribeSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailCampaignUnsubscribeSource>>;
};

export type NestedEnumEmailProviderFilter = {
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderFilter>;
  notIn?: Maybe<Array<EmailProvider>>;
};

export type NestedEnumEmailProviderLogEntityTypeFilter = {
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
};

export type NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  _min?: Maybe<NestedEnumEmailProviderLogEntityTypeFilter>;
  equals?: Maybe<EmailProviderLogEntityType>;
  in?: Maybe<Array<EmailProviderLogEntityType>>;
  not?: Maybe<NestedEnumEmailProviderLogEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailProviderLogEntityType>>;
};

export type NestedEnumEmailProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailProviderFilter>;
  _min?: Maybe<NestedEnumEmailProviderFilter>;
  equals?: Maybe<EmailProvider>;
  in?: Maybe<Array<EmailProvider>>;
  not?: Maybe<NestedEnumEmailProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailProvider>>;
};

export type NestedEnumEmailTemplateTypeFilter = {
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  notIn?: Maybe<Array<EmailTemplateType>>;
};

export type NestedEnumEmailTemplateTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  _min?: Maybe<NestedEnumEmailTemplateTypeFilter>;
  equals?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  not?: Maybe<NestedEnumEmailTemplateTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<EmailTemplateType>>;
};

export type NestedEnumEnrollmentStatusFilter = {
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusFilter>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
};

export type NestedEnumEnrollmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumEnrollmentStatusFilter>;
  _min?: Maybe<NestedEnumEnrollmentStatusFilter>;
  equals?: Maybe<EnrollmentStatus>;
  in?: Maybe<Array<EnrollmentStatus>>;
  not?: Maybe<NestedEnumEnrollmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<EnrollmentStatus>>;
};

export type NestedEnumFeatureFlagStateFilter = {
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateFilter>;
  notIn?: Maybe<Array<FeatureFlagState>>;
};

export type NestedEnumFeatureFlagStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeatureFlagStateFilter>;
  _min?: Maybe<NestedEnumFeatureFlagStateFilter>;
  equals?: Maybe<FeatureFlagState>;
  in?: Maybe<Array<FeatureFlagState>>;
  not?: Maybe<NestedEnumFeatureFlagStateWithAggregatesFilter>;
  notIn?: Maybe<Array<FeatureFlagState>>;
};

export type NestedEnumFeatureFlagTypeFilter = {
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  notIn?: Maybe<Array<FeatureFlagType>>;
};

export type NestedEnumFeatureFlagTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  _min?: Maybe<NestedEnumFeatureFlagTypeFilter>;
  equals?: Maybe<FeatureFlagType>;
  in?: Maybe<Array<FeatureFlagType>>;
  not?: Maybe<NestedEnumFeatureFlagTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FeatureFlagType>>;
};

export type NestedEnumFeeCollectionFilter = {
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionFilter>;
  notIn?: Maybe<Array<FeeCollection>>;
};

export type NestedEnumFeeCollectionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFeeCollectionFilter>;
  _min?: Maybe<NestedEnumFeeCollectionFilter>;
  equals?: Maybe<FeeCollection>;
  in?: Maybe<Array<FeeCollection>>;
  not?: Maybe<NestedEnumFeeCollectionWithAggregatesFilter>;
  notIn?: Maybe<Array<FeeCollection>>;
};

export type NestedEnumFilterSelectionTypeFilter = {
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  notIn?: Maybe<Array<FilterSelectionType>>;
};

export type NestedEnumFilterSelectionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  _min?: Maybe<NestedEnumFilterSelectionTypeFilter>;
  equals?: Maybe<FilterSelectionType>;
  in?: Maybe<Array<FilterSelectionType>>;
  not?: Maybe<NestedEnumFilterSelectionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FilterSelectionType>>;
};

export type NestedEnumFinancialTransactionTypeFilter = {
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
};

export type NestedEnumFinancialTransactionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  _min?: Maybe<NestedEnumFinancialTransactionTypeFilter>;
  equals?: Maybe<FinancialTransactionType>;
  in?: Maybe<Array<FinancialTransactionType>>;
  not?: Maybe<NestedEnumFinancialTransactionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FinancialTransactionType>>;
};

export type NestedEnumFormTemplateTypeFilter = {
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeFilter>;
  notIn?: Maybe<Array<FormTemplateType>>;
};

export type NestedEnumFormTemplateTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumFormTemplateTypeFilter>;
  _min?: Maybe<NestedEnumFormTemplateTypeFilter>;
  equals?: Maybe<FormTemplateType>;
  in?: Maybe<Array<FormTemplateType>>;
  not?: Maybe<NestedEnumFormTemplateTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<FormTemplateType>>;
};

export type NestedEnumHillsToHomeResultTypeFilter = {
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
};

export type NestedEnumHillsToHomeResultTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  _min?: Maybe<NestedEnumHillsToHomeResultTypeFilter>;
  equals?: Maybe<HillsToHomeResultType>;
  in?: Maybe<Array<HillsToHomeResultType>>;
  not?: Maybe<NestedEnumHillsToHomeResultTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<HillsToHomeResultType>>;
};

export type NestedEnumHillsToHomeSpeciesNullableFilter = {
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
};

export type NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  _min?: Maybe<NestedEnumHillsToHomeSpeciesNullableFilter>;
  equals?: Maybe<HillsToHomeSpecies>;
  in?: Maybe<Array<HillsToHomeSpecies>>;
  not?: Maybe<NestedEnumHillsToHomeSpeciesNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<HillsToHomeSpecies>>;
};

export type NestedEnumInformAudienceDefinitionStatusFilter = {
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
};

export type NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionStatusFilter>;
  equals?: Maybe<InformAudienceDefinitionStatus>;
  in?: Maybe<Array<InformAudienceDefinitionStatus>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionStatus>>;
};

export type NestedEnumInformAudienceDefinitionTypeFilter = {
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
};

export type NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  _min?: Maybe<NestedEnumInformAudienceDefinitionTypeFilter>;
  equals?: Maybe<InformAudienceDefinitionType>;
  in?: Maybe<Array<InformAudienceDefinitionType>>;
  not?: Maybe<NestedEnumInformAudienceDefinitionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformAudienceDefinitionType>>;
};

export type NestedEnumInformCampaignExecutionStatusFilter = {
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
};

export type NestedEnumInformCampaignExecutionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  _min?: Maybe<NestedEnumInformCampaignExecutionStatusFilter>;
  equals?: Maybe<InformCampaignExecutionStatus>;
  in?: Maybe<Array<InformCampaignExecutionStatus>>;
  not?: Maybe<NestedEnumInformCampaignExecutionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignExecutionStatus>>;
};

export type NestedEnumInformCampaignStatusFilter = {
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusFilter>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
};

export type NestedEnumInformCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignStatusFilter>;
  _min?: Maybe<NestedEnumInformCampaignStatusFilter>;
  equals?: Maybe<InformCampaignStatus>;
  in?: Maybe<Array<InformCampaignStatus>>;
  not?: Maybe<NestedEnumInformCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignStatus>>;
};

export type NestedEnumInformCampaignTypeFilter = {
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeFilter>;
  notIn?: Maybe<Array<InformCampaignType>>;
};

export type NestedEnumInformCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformCampaignTypeFilter>;
  _min?: Maybe<NestedEnumInformCampaignTypeFilter>;
  equals?: Maybe<InformCampaignType>;
  in?: Maybe<Array<InformCampaignType>>;
  not?: Maybe<NestedEnumInformCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformCampaignType>>;
};

export type NestedEnumInformControlGroupEntityTypeFilter = {
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
};

export type NestedEnumInformControlGroupEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  _min?: Maybe<NestedEnumInformControlGroupEntityTypeFilter>;
  equals?: Maybe<InformControlGroupEntityType>;
  in?: Maybe<Array<InformControlGroupEntityType>>;
  not?: Maybe<NestedEnumInformControlGroupEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformControlGroupEntityType>>;
};

export type NestedEnumInformEmailTemplateStatusFilter = {
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
};

export type NestedEnumInformEmailTemplateStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  _min?: Maybe<NestedEnumInformEmailTemplateStatusFilter>;
  equals?: Maybe<InformEmailTemplateStatus>;
  in?: Maybe<Array<InformEmailTemplateStatus>>;
  not?: Maybe<NestedEnumInformEmailTemplateStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformEmailTemplateStatus>>;
};

export type NestedEnumInformEnrollmentStatusFilter = {
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
};

export type NestedEnumInformEnrollmentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  _min?: Maybe<NestedEnumInformEnrollmentStatusFilter>;
  equals?: Maybe<InformEnrollmentStatus>;
  in?: Maybe<Array<InformEnrollmentStatus>>;
  not?: Maybe<NestedEnumInformEnrollmentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformEnrollmentStatus>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderNameFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderName>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderName>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderStatus>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderStatus>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter = {
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
};

export type NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  _min?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeFilter>;
  equals?: Maybe<InformPartnerCustomerPharmacyProviderType>;
  in?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
  not?: Maybe<NestedEnumInformPartnerCustomerPharmacyProviderTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerCustomerPharmacyProviderType>>;
};

export type NestedEnumInformPartnerIntegrationModelNameFilter = {
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
};

export type NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelNameFilter>;
  equals?: Maybe<InformPartnerIntegrationModelName>;
  in?: Maybe<Array<InformPartnerIntegrationModelName>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelName>>;
};

export type NestedEnumInformPartnerIntegrationModelStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
};

export type NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationModelStatusFilter>;
  equals?: Maybe<InformPartnerIntegrationModelStatus>;
  in?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationModelStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationModelStatus>>;
};

export type NestedEnumInformPartnerIntegrationStatusFilter = {
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
};

export type NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerIntegrationStatusFilter>;
  equals?: Maybe<InformPartnerIntegrationStatus>;
  in?: Maybe<Array<InformPartnerIntegrationStatus>>;
  not?: Maybe<NestedEnumInformPartnerIntegrationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerIntegrationStatus>>;
};

export type NestedEnumInformPartnerProgramStatusFilter = {
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
};

export type NestedEnumInformPartnerProgramStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerProgramStatusFilter>;
  equals?: Maybe<InformPartnerProgramStatus>;
  in?: Maybe<Array<InformPartnerProgramStatus>>;
  not?: Maybe<NestedEnumInformPartnerProgramStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerProgramStatus>>;
};

export type NestedEnumInformPartnerStatusFilter = {
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusFilter>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
};

export type NestedEnumInformPartnerStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerStatusFilter>;
  equals?: Maybe<InformPartnerStatus>;
  in?: Maybe<Array<InformPartnerStatus>>;
  not?: Maybe<NestedEnumInformPartnerStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerStatus>>;
};

export type NestedEnumInformPartnerUserRoleNameFilter = {
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
};

export type NestedEnumInformPartnerUserRoleNameWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleNameFilter>;
  equals?: Maybe<InformPartnerUserRoleName>;
  in?: Maybe<Array<InformPartnerUserRoleName>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleNameWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleName>>;
};

export type NestedEnumInformPartnerUserRoleStatusFilter = {
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
};

export type NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserRoleStatusFilter>;
  equals?: Maybe<InformPartnerUserRoleStatus>;
  in?: Maybe<Array<InformPartnerUserRoleStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserRoleStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserRoleStatus>>;
};

export type NestedEnumInformPartnerUserStatusFilter = {
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
};

export type NestedEnumInformPartnerUserStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  _min?: Maybe<NestedEnumInformPartnerUserStatusFilter>;
  equals?: Maybe<InformPartnerUserStatus>;
  in?: Maybe<Array<InformPartnerUserStatus>>;
  not?: Maybe<NestedEnumInformPartnerUserStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InformPartnerUserStatus>>;
};

export type NestedEnumInformScheduledCampaignCadenceNullableFilter = {
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
};

export type NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  _min?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableFilter>;
  equals?: Maybe<InformScheduledCampaignCadence>;
  in?: Maybe<Array<InformScheduledCampaignCadence>>;
  not?: Maybe<NestedEnumInformScheduledCampaignCadenceNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<InformScheduledCampaignCadence>>;
};

export type NestedEnumInsuranceIntegrationTypeFilter = {
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
};

export type NestedEnumInsuranceIntegrationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  _min?: Maybe<NestedEnumInsuranceIntegrationTypeFilter>;
  equals?: Maybe<InsuranceIntegrationType>;
  in?: Maybe<Array<InsuranceIntegrationType>>;
  not?: Maybe<NestedEnumInsuranceIntegrationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InsuranceIntegrationType>>;
};

export type NestedEnumIntegrationStatusNullableFilter = {
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  notIn?: Maybe<Array<IntegrationStatus>>;
};

export type NestedEnumIntegrationStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationStatusNullableFilter>;
  equals?: Maybe<IntegrationStatus>;
  in?: Maybe<Array<IntegrationStatus>>;
  not?: Maybe<NestedEnumIntegrationStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<IntegrationStatus>>;
};

export type NestedEnumIntegrationTypeNullableFilter = {
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  notIn?: Maybe<Array<IntegrationType>>;
};

export type NestedEnumIntegrationTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  _min?: Maybe<NestedEnumIntegrationTypeNullableFilter>;
  equals?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  not?: Maybe<NestedEnumIntegrationTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<IntegrationType>>;
};

export type NestedEnumInvoiceLineItemTypeFilter = {
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
};

export type NestedEnumInvoiceLineItemTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  _min?: Maybe<NestedEnumInvoiceLineItemTypeFilter>;
  equals?: Maybe<InvoiceLineItemType>;
  in?: Maybe<Array<InvoiceLineItemType>>;
  not?: Maybe<NestedEnumInvoiceLineItemTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoiceLineItemType>>;
};

export type NestedEnumInvoicePimsWritebackActionFilter = {
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
};

export type NestedEnumInvoicePimsWritebackActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  _min?: Maybe<NestedEnumInvoicePimsWritebackActionFilter>;
  equals?: Maybe<InvoicePimsWritebackAction>;
  in?: Maybe<Array<InvoicePimsWritebackAction>>;
  not?: Maybe<NestedEnumInvoicePimsWritebackActionWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoicePimsWritebackAction>>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
  notIn?: Maybe<Array<InvoiceStatus>>;
};

export type NestedEnumInvoiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumInvoiceStatusFilter>;
  _min?: Maybe<NestedEnumInvoiceStatusFilter>;
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<InvoiceStatus>>;
};

export type NestedEnumLegalEntityTypeFilter = {
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeFilter>;
  notIn?: Maybe<Array<LegalEntityType>>;
};

export type NestedEnumLegalEntityTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLegalEntityTypeFilter>;
  _min?: Maybe<NestedEnumLegalEntityTypeFilter>;
  equals?: Maybe<LegalEntityType>;
  in?: Maybe<Array<LegalEntityType>>;
  not?: Maybe<NestedEnumLegalEntityTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<LegalEntityType>>;
};

export type NestedEnumLoyaltyAccountMergerStatusFilter = {
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
};

export type NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyAccountMergerStatusFilter>;
  equals?: Maybe<LoyaltyAccountMergerStatus>;
  in?: Maybe<Array<LoyaltyAccountMergerStatus>>;
  not?: Maybe<NestedEnumLoyaltyAccountMergerStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyAccountMergerStatus>>;
};

export type NestedEnumLoyaltyPointDeltaSourceFilter = {
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
};

export type NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  _min?: Maybe<NestedEnumLoyaltyPointDeltaSourceFilter>;
  equals?: Maybe<LoyaltyPointDeltaSource>;
  in?: Maybe<Array<LoyaltyPointDeltaSource>>;
  not?: Maybe<NestedEnumLoyaltyPointDeltaSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyPointDeltaSource>>;
};

export type NestedEnumLoyaltyProgramStatusFilter = {
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
};

export type NestedEnumLoyaltyProgramStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyProgramStatusFilter>;
  equals?: Maybe<LoyaltyProgramStatus>;
  in?: Maybe<Array<LoyaltyProgramStatus>>;
  not?: Maybe<NestedEnumLoyaltyProgramStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyProgramStatus>>;
};

export type NestedEnumLoyaltyRewardAvailabilityFilter = {
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
};

export type NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardAvailabilityFilter>;
  equals?: Maybe<LoyaltyRewardAvailability>;
  in?: Maybe<Array<LoyaltyRewardAvailability>>;
  not?: Maybe<NestedEnumLoyaltyRewardAvailabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardAvailability>>;
};

export type NestedEnumLoyaltyRewardRedemptionActorTypeFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
};

export type NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeFilter>;
  equals?: Maybe<LoyaltyRewardRedemptionActorType>;
  in?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionActorTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionActorType>>;
};

export type NestedEnumLoyaltyRewardRedemptionStatusFilter = {
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
};

export type NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  _min?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusFilter>;
  equals?: Maybe<LoyaltyRewardRedemptionStatus>;
  in?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
  not?: Maybe<NestedEnumLoyaltyRewardRedemptionStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<LoyaltyRewardRedemptionStatus>>;
};

export type NestedEnumManageMembershipRequestTypeFilter = {
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
};

export type NestedEnumManageMembershipRequestTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  _min?: Maybe<NestedEnumManageMembershipRequestTypeFilter>;
  equals?: Maybe<ManageMembershipRequestType>;
  in?: Maybe<Array<ManageMembershipRequestType>>;
  not?: Maybe<NestedEnumManageMembershipRequestTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ManageMembershipRequestType>>;
};

export type NestedEnumMassTextAlertStatusFilter = {
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
};

export type NestedEnumMassTextAlertStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  _min?: Maybe<NestedEnumMassTextAlertStatusFilter>;
  equals?: Maybe<MassTextAlertStatus>;
  in?: Maybe<Array<MassTextAlertStatus>>;
  not?: Maybe<NestedEnumMassTextAlertStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MassTextAlertStatus>>;
};

export type NestedEnumMediaTypeFilter = {
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeFilter>;
  notIn?: Maybe<Array<MediaType>>;
};

export type NestedEnumMediaTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMediaTypeFilter>;
  _min?: Maybe<NestedEnumMediaTypeFilter>;
  equals?: Maybe<MediaType>;
  in?: Maybe<Array<MediaType>>;
  not?: Maybe<NestedEnumMediaTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MediaType>>;
};

export type NestedEnumMediaVersionFilter = {
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionFilter>;
  notIn?: Maybe<Array<MediaVersion>>;
};

export type NestedEnumMediaVersionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMediaVersionFilter>;
  _min?: Maybe<NestedEnumMediaVersionFilter>;
  equals?: Maybe<MediaVersion>;
  in?: Maybe<Array<MediaVersion>>;
  not?: Maybe<NestedEnumMediaVersionWithAggregatesFilter>;
  notIn?: Maybe<Array<MediaVersion>>;
};

export type NestedEnumMessageSourceFilter = {
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceFilter>;
  notIn?: Maybe<Array<MessageSource>>;
};

export type NestedEnumMessageSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessageSourceFilter>;
  _min?: Maybe<NestedEnumMessageSourceFilter>;
  equals?: Maybe<MessageSource>;
  in?: Maybe<Array<MessageSource>>;
  not?: Maybe<NestedEnumMessageSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<MessageSource>>;
};

export type NestedEnumMessageTypeFilter = {
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeFilter>;
  notIn?: Maybe<Array<MessageType>>;
};

export type NestedEnumMessageTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessageTypeFilter>;
  _min?: Maybe<NestedEnumMessageTypeFilter>;
  equals?: Maybe<MessageType>;
  in?: Maybe<Array<MessageType>>;
  not?: Maybe<NestedEnumMessageTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MessageType>>;
};

export type NestedEnumMessagingCampaignStatusFilter = {
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
};

export type NestedEnumMessagingCampaignStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignStatusFilter>;
  equals?: Maybe<MessagingCampaignStatus>;
  in?: Maybe<Array<MessagingCampaignStatus>>;
  not?: Maybe<NestedEnumMessagingCampaignStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MessagingCampaignStatus>>;
};

export type NestedEnumMessagingCampaignTypeFilter = {
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
};

export type NestedEnumMessagingCampaignTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  _min?: Maybe<NestedEnumMessagingCampaignTypeFilter>;
  equals?: Maybe<MessagingCampaignType>;
  in?: Maybe<Array<MessagingCampaignType>>;
  not?: Maybe<NestedEnumMessagingCampaignTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<MessagingCampaignType>>;
};

export type NestedEnumMissedCallMessageResponseStatusFilter = {
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
};

export type NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  _min?: Maybe<NestedEnumMissedCallMessageResponseStatusFilter>;
  equals?: Maybe<MissedCallMessageResponseStatus>;
  in?: Maybe<Array<MissedCallMessageResponseStatus>>;
  not?: Maybe<NestedEnumMissedCallMessageResponseStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<MissedCallMessageResponseStatus>>;
};

export type NestedEnumNormalizedWritebackErrorNullableFilter = {
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
};

export type NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  _min?: Maybe<NestedEnumNormalizedWritebackErrorNullableFilter>;
  equals?: Maybe<NormalizedWritebackError>;
  in?: Maybe<Array<NormalizedWritebackError>>;
  not?: Maybe<NestedEnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<NormalizedWritebackError>>;
};

export type NestedEnumNotificationEventFilter = {
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventFilter>;
  notIn?: Maybe<Array<NotificationEvent>>;
};

export type NestedEnumNotificationEventWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumNotificationEventFilter>;
  _min?: Maybe<NestedEnumNotificationEventFilter>;
  equals?: Maybe<NotificationEvent>;
  in?: Maybe<Array<NotificationEvent>>;
  not?: Maybe<NestedEnumNotificationEventWithAggregatesFilter>;
  notIn?: Maybe<Array<NotificationEvent>>;
};

export type NestedEnumNotificationSettingTypeFilter = {
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  notIn?: Maybe<Array<NotificationSettingType>>;
};

export type NestedEnumNotificationSettingTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  _min?: Maybe<NestedEnumNotificationSettingTypeFilter>;
  equals?: Maybe<NotificationSettingType>;
  in?: Maybe<Array<NotificationSettingType>>;
  not?: Maybe<NestedEnumNotificationSettingTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<NotificationSettingType>>;
};

export type NestedEnumPaymentMediumNullableFilter = {
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  notIn?: Maybe<Array<PaymentMedium>>;
};

export type NestedEnumPaymentMediumNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  _min?: Maybe<NestedEnumPaymentMediumNullableFilter>;
  equals?: Maybe<PaymentMedium>;
  in?: Maybe<Array<PaymentMedium>>;
  not?: Maybe<NestedEnumPaymentMediumNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentMedium>>;
};

export type NestedEnumPaymentPimsWritebackActionFilter = {
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
};

export type NestedEnumPaymentPimsWritebackActionWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  _min?: Maybe<NestedEnumPaymentPimsWritebackActionFilter>;
  equals?: Maybe<PaymentPimsWritebackAction>;
  in?: Maybe<Array<PaymentPimsWritebackAction>>;
  not?: Maybe<NestedEnumPaymentPimsWritebackActionWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentPimsWritebackAction>>;
};

export type NestedEnumPaymentRelationshipNullableFilter = {
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  notIn?: Maybe<Array<PaymentRelationship>>;
};

export type NestedEnumPaymentRelationshipNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  _min?: Maybe<NestedEnumPaymentRelationshipNullableFilter>;
  equals?: Maybe<PaymentRelationship>;
  in?: Maybe<Array<PaymentRelationship>>;
  not?: Maybe<NestedEnumPaymentRelationshipNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentRelationship>>;
};

export type NestedEnumPaymentScheduleNullableFilter = {
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  notIn?: Maybe<Array<PaymentSchedule>>;
};

export type NestedEnumPaymentScheduleNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  _min?: Maybe<NestedEnumPaymentScheduleNullableFilter>;
  equals?: Maybe<PaymentSchedule>;
  in?: Maybe<Array<PaymentSchedule>>;
  not?: Maybe<NestedEnumPaymentScheduleNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentSchedule>>;
};

export type NestedEnumPaymentSourceFilter = {
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceFilter>;
  notIn?: Maybe<Array<PaymentSource>>;
};

export type NestedEnumPaymentSourceWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPaymentSourceFilter>;
  _min?: Maybe<NestedEnumPaymentSourceFilter>;
  equals?: Maybe<PaymentSource>;
  in?: Maybe<Array<PaymentSource>>;
  not?: Maybe<NestedEnumPaymentSourceWithAggregatesFilter>;
  notIn?: Maybe<Array<PaymentSource>>;
};

export type NestedEnumPermissionTypeFilter = {
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeFilter>;
  notIn?: Maybe<Array<PermissionType>>;
};

export type NestedEnumPermissionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPermissionTypeFilter>;
  _min?: Maybe<NestedEnumPermissionTypeFilter>;
  equals?: Maybe<PermissionType>;
  in?: Maybe<Array<PermissionType>>;
  not?: Maybe<NestedEnumPermissionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PermissionType>>;
};

export type NestedEnumPhoneNumberLookupRequestStatusFilter = {
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
};

export type NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  _min?: Maybe<NestedEnumPhoneNumberLookupRequestStatusFilter>;
  equals?: Maybe<PhoneNumberLookupRequestStatus>;
  in?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
  not?: Maybe<NestedEnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PhoneNumberLookupRequestStatus>>;
};

export type NestedEnumPhoneNumberStatusFilter = {
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
};

export type NestedEnumPhoneNumberStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  _min?: Maybe<NestedEnumPhoneNumberStatusFilter>;
  equals?: Maybe<PhoneNumberStatus>;
  in?: Maybe<Array<PhoneNumberStatus>>;
  not?: Maybe<NestedEnumPhoneNumberStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PhoneNumberStatus>>;
};

export type NestedEnumPimsIntegrationCapabilityFilter = {
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
};

export type NestedEnumPimsIntegrationCapabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  _min?: Maybe<NestedEnumPimsIntegrationCapabilityFilter>;
  equals?: Maybe<PimsIntegrationCapability>;
  in?: Maybe<Array<PimsIntegrationCapability>>;
  not?: Maybe<NestedEnumPimsIntegrationCapabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsIntegrationCapability>>;
};

export type NestedEnumPimsInvoiceLineItemTypeFilter = {
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
};

export type NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  _min?: Maybe<NestedEnumPimsInvoiceLineItemTypeFilter>;
  equals?: Maybe<PimsInvoiceLineItemType>;
  in?: Maybe<Array<PimsInvoiceLineItemType>>;
  not?: Maybe<NestedEnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsInvoiceLineItemType>>;
};

export type NestedEnumPimsSyncFrequencyFilter = {
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
};

export type NestedEnumPimsSyncFrequencyWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  _min?: Maybe<NestedEnumPimsSyncFrequencyFilter>;
  equals?: Maybe<PimsSyncFrequency>;
  in?: Maybe<Array<PimsSyncFrequency>>;
  not?: Maybe<NestedEnumPimsSyncFrequencyWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsSyncFrequency>>;
};

export type NestedEnumPimsSyncStatusNullableFilter = {
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
};

export type NestedEnumPimsSyncStatusNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  _min?: Maybe<NestedEnumPimsSyncStatusNullableFilter>;
  equals?: Maybe<PimsSyncStatus>;
  in?: Maybe<Array<PimsSyncStatus>>;
  not?: Maybe<NestedEnumPimsSyncStatusNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsSyncStatus>>;
};

export type NestedEnumPimsWritebackStatusFilter = {
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
};

export type NestedEnumPimsWritebackStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  _min?: Maybe<NestedEnumPimsWritebackStatusFilter>;
  equals?: Maybe<PimsWritebackStatus>;
  in?: Maybe<Array<PimsWritebackStatus>>;
  not?: Maybe<NestedEnumPimsWritebackStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsWritebackStatus>>;
};

export type NestedEnumPimsWritebackTypeFilter = {
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  notIn?: Maybe<Array<PimsWritebackType>>;
};

export type NestedEnumPimsWritebackTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  _min?: Maybe<NestedEnumPimsWritebackTypeFilter>;
  equals?: Maybe<PimsWritebackType>;
  in?: Maybe<Array<PimsWritebackType>>;
  not?: Maybe<NestedEnumPimsWritebackTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PimsWritebackType>>;
};

export type NestedEnumPricingModelFilter = {
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelFilter>;
  notIn?: Maybe<Array<PricingModel>>;
};

export type NestedEnumPricingModelWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPricingModelFilter>;
  _min?: Maybe<NestedEnumPricingModelFilter>;
  equals?: Maybe<PricingModel>;
  in?: Maybe<Array<PricingModel>>;
  not?: Maybe<NestedEnumPricingModelWithAggregatesFilter>;
  notIn?: Maybe<Array<PricingModel>>;
};

export type NestedEnumProductSupplyDurationUnitNullableFilter = {
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
};

export type NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  _min?: Maybe<NestedEnumProductSupplyDurationUnitNullableFilter>;
  equals?: Maybe<ProductSupplyDurationUnit>;
  in?: Maybe<Array<ProductSupplyDurationUnit>>;
  not?: Maybe<NestedEnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<ProductSupplyDurationUnit>>;
};

export type NestedEnumProductTypeFilter = {
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeFilter>;
  notIn?: Maybe<Array<ProductType>>;
};

export type NestedEnumProductTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumProductTypeFilter>;
  _min?: Maybe<NestedEnumProductTypeFilter>;
  equals?: Maybe<ProductType>;
  in?: Maybe<Array<ProductType>>;
  not?: Maybe<NestedEnumProductTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ProductType>>;
};

export type NestedEnumProfilePimsSyncStatusFilter = {
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
};

export type NestedEnumProfilePimsSyncStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  _min?: Maybe<NestedEnumProfilePimsSyncStatusFilter>;
  equals?: Maybe<ProfilePimsSyncStatus>;
  in?: Maybe<Array<ProfilePimsSyncStatus>>;
  not?: Maybe<NestedEnumProfilePimsSyncStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<ProfilePimsSyncStatus>>;
};

export type NestedEnumPushNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
};

export type NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  _min?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableFilter>;
  equals?: Maybe<PushNotificationDeliveryErrorType>;
  in?: Maybe<Array<PushNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationDeliveryErrorType>>;
};

export type NestedEnumPushNotificationLogStatusTypeFilter = {
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
};

export type NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  _min?: Maybe<NestedEnumPushNotificationLogStatusTypeFilter>;
  equals?: Maybe<PushNotificationLogStatusType>;
  in?: Maybe<Array<PushNotificationLogStatusType>>;
  not?: Maybe<NestedEnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationLogStatusType>>;
};

export type NestedEnumPushNotificationStatusFilter = {
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusFilter>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
};

export type NestedEnumPushNotificationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumPushNotificationStatusFilter>;
  _min?: Maybe<NestedEnumPushNotificationStatusFilter>;
  equals?: Maybe<PushNotificationStatus>;
  in?: Maybe<Array<PushNotificationStatus>>;
  not?: Maybe<NestedEnumPushNotificationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<PushNotificationStatus>>;
};

export type NestedEnumRecordingDownloadStatusFilter = {
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
};

export type NestedEnumRecordingDownloadStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  _min?: Maybe<NestedEnumRecordingDownloadStatusFilter>;
  equals?: Maybe<RecordingDownloadStatus>;
  in?: Maybe<Array<RecordingDownloadStatus>>;
  not?: Maybe<NestedEnumRecordingDownloadStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<RecordingDownloadStatus>>;
};

export type NestedEnumRefundReasonFilter = {
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type NestedEnumRefundReasonWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRefundReasonFilter>;
  _min?: Maybe<NestedEnumRefundReasonFilter>;
  equals?: Maybe<RefundReason>;
  in?: Maybe<Array<RefundReason>>;
  not?: Maybe<NestedEnumRefundReasonWithAggregatesFilter>;
  notIn?: Maybe<Array<RefundReason>>;
};

export type NestedEnumRenewalRejectionReasonNullableFilter = {
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
};

export type NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  _min?: Maybe<NestedEnumRenewalRejectionReasonNullableFilter>;
  equals?: Maybe<RenewalRejectionReason>;
  in?: Maybe<Array<RenewalRejectionReason>>;
  not?: Maybe<NestedEnumRenewalRejectionReasonNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<RenewalRejectionReason>>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type NestedEnumRoleWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRoleFilter>;
  _min?: Maybe<NestedEnumRoleFilter>;
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type NestedEnumRuleActionsFilter = {
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsFilter>;
  notIn?: Maybe<Array<RuleActions>>;
};

export type NestedEnumRuleActionsWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumRuleActionsFilter>;
  _min?: Maybe<NestedEnumRuleActionsFilter>;
  equals?: Maybe<RuleActions>;
  in?: Maybe<Array<RuleActions>>;
  not?: Maybe<NestedEnumRuleActionsWithAggregatesFilter>;
  notIn?: Maybe<Array<RuleActions>>;
};

export type NestedEnumSegmentModelTypeFilter = {
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeFilter>;
  notIn?: Maybe<Array<SegmentModelType>>;
};

export type NestedEnumSegmentModelTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSegmentModelTypeFilter>;
  _min?: Maybe<NestedEnumSegmentModelTypeFilter>;
  equals?: Maybe<SegmentModelType>;
  in?: Maybe<Array<SegmentModelType>>;
  not?: Maybe<NestedEnumSegmentModelTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SegmentModelType>>;
};

export type NestedEnumSegmentTypeFilter = {
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeFilter>;
  notIn?: Maybe<Array<SegmentType>>;
};

export type NestedEnumSegmentTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSegmentTypeFilter>;
  _min?: Maybe<NestedEnumSegmentTypeFilter>;
  equals?: Maybe<SegmentType>;
  in?: Maybe<Array<SegmentType>>;
  not?: Maybe<NestedEnumSegmentTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SegmentType>>;
};

export type NestedEnumServiceCapabilityFilter = {
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityFilter>;
  notIn?: Maybe<Array<ServiceCapability>>;
};

export type NestedEnumServiceCapabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceCapabilityFilter>;
  _min?: Maybe<NestedEnumServiceCapabilityFilter>;
  equals?: Maybe<ServiceCapability>;
  in?: Maybe<Array<ServiceCapability>>;
  not?: Maybe<NestedEnumServiceCapabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceCapability>>;
};

export type NestedEnumServiceProviderFilter = {
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderFilter>;
  notIn?: Maybe<Array<ServiceProvider>>;
};

export type NestedEnumServiceProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceProviderFilter>;
  _min?: Maybe<NestedEnumServiceProviderFilter>;
  equals?: Maybe<ServiceProvider>;
  in?: Maybe<Array<ServiceProvider>>;
  not?: Maybe<NestedEnumServiceProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceProvider>>;
};

export type NestedEnumServiceReminderNotificationTypeFilter = {
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
};

export type NestedEnumServiceReminderNotificationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  _min?: Maybe<NestedEnumServiceReminderNotificationTypeFilter>;
  equals?: Maybe<ServiceReminderNotificationType>;
  in?: Maybe<Array<ServiceReminderNotificationType>>;
  not?: Maybe<NestedEnumServiceReminderNotificationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderNotificationType>>;
};

export type NestedEnumServiceReminderTimingOffsetUnitFilter = {
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
};

export type NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingOffsetUnitFilter>;
  equals?: Maybe<ServiceReminderTimingOffsetUnit>;
  in?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
  not?: Maybe<NestedEnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingOffsetUnit>>;
};

export type NestedEnumServiceReminderTimingTypeFilter = {
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
};

export type NestedEnumServiceReminderTimingTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  _min?: Maybe<NestedEnumServiceReminderTimingTypeFilter>;
  equals?: Maybe<ServiceReminderTimingType>;
  in?: Maybe<Array<ServiceReminderTimingType>>;
  not?: Maybe<NestedEnumServiceReminderTimingTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<ServiceReminderTimingType>>;
};

export type NestedEnumSmsEntityTypeNullableFilter = {
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  notIn?: Maybe<Array<SmsEntityType>>;
};

export type NestedEnumSmsEntityTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  _min?: Maybe<NestedEnumSmsEntityTypeNullableFilter>;
  equals?: Maybe<SmsEntityType>;
  in?: Maybe<Array<SmsEntityType>>;
  not?: Maybe<NestedEnumSmsEntityTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsEntityType>>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter = {
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
};

export type NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  _min?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  equals?: Maybe<SmsNotificationDeliveryErrorType>;
  in?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
  not?: Maybe<NestedEnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationDeliveryErrorType>>;
};

export type NestedEnumSmsNotificationProviderFilter = {
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
};

export type NestedEnumSmsNotificationProviderWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  _min?: Maybe<NestedEnumSmsNotificationProviderFilter>;
  equals?: Maybe<SmsNotificationProvider>;
  in?: Maybe<Array<SmsNotificationProvider>>;
  not?: Maybe<NestedEnumSmsNotificationProviderWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationProvider>>;
};

export type NestedEnumSmsNotificationStatusTypeFilter = {
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
};

export type NestedEnumSmsNotificationStatusTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  _min?: Maybe<NestedEnumSmsNotificationStatusTypeFilter>;
  equals?: Maybe<SmsNotificationStatusType>;
  in?: Maybe<Array<SmsNotificationStatusType>>;
  not?: Maybe<NestedEnumSmsNotificationStatusTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SmsNotificationStatusType>>;
};

export type NestedEnumSpeciesTypeFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type NestedEnumSpeciesTypeNullableFilter = {
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type NestedEnumSpeciesTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeNullableFilter>;
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type NestedEnumSpeciesTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSpeciesTypeFilter>;
  _min?: Maybe<NestedEnumSpeciesTypeFilter>;
  equals?: Maybe<SpeciesType>;
  in?: Maybe<Array<SpeciesType>>;
  not?: Maybe<NestedEnumSpeciesTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SpeciesType>>;
};

export type NestedEnumStateFilter = {
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateFilter>;
  notIn?: Maybe<Array<State>>;
};

export type NestedEnumStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStateFilter>;
  _min?: Maybe<NestedEnumStateFilter>;
  equals?: Maybe<State>;
  in?: Maybe<Array<State>>;
  not?: Maybe<NestedEnumStateWithAggregatesFilter>;
  notIn?: Maybe<Array<State>>;
};

export type NestedEnumStripeCollectionMethodFilter = {
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
};

export type NestedEnumStripeCollectionMethodWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  _min?: Maybe<NestedEnumStripeCollectionMethodFilter>;
  equals?: Maybe<StripeCollectionMethod>;
  in?: Maybe<Array<StripeCollectionMethod>>;
  not?: Maybe<NestedEnumStripeCollectionMethodWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeCollectionMethod>>;
};

export type NestedEnumStripeDisputeStatusFilter = {
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
};

export type NestedEnumStripeDisputeStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  _min?: Maybe<NestedEnumStripeDisputeStatusFilter>;
  equals?: Maybe<StripeDisputeStatus>;
  in?: Maybe<Array<StripeDisputeStatus>>;
  not?: Maybe<NestedEnumStripeDisputeStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeDisputeStatus>>;
};

export type NestedEnumStripeInvoiceStatusFilter = {
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
};

export type NestedEnumStripeInvoiceStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  _min?: Maybe<NestedEnumStripeInvoiceStatusFilter>;
  equals?: Maybe<StripeInvoiceStatus>;
  in?: Maybe<Array<StripeInvoiceStatus>>;
  not?: Maybe<NestedEnumStripeInvoiceStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeInvoiceStatus>>;
};

export type NestedEnumStripePaymentErrorNullableFilter = {
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  notIn?: Maybe<Array<StripePaymentError>>;
};

export type NestedEnumStripePaymentErrorNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  _min?: Maybe<NestedEnumStripePaymentErrorNullableFilter>;
  equals?: Maybe<StripePaymentError>;
  in?: Maybe<Array<StripePaymentError>>;
  not?: Maybe<NestedEnumStripePaymentErrorNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentError>>;
};

export type NestedEnumStripePaymentIntentStatusFilter = {
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
};

export type NestedEnumStripePaymentIntentStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  _min?: Maybe<NestedEnumStripePaymentIntentStatusFilter>;
  equals?: Maybe<StripePaymentIntentStatus>;
  in?: Maybe<Array<StripePaymentIntentStatus>>;
  not?: Maybe<NestedEnumStripePaymentIntentStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentIntentStatus>>;
};

export type NestedEnumStripePaymentMethodTypeFilter = {
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
};

export type NestedEnumStripePaymentMethodTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  _min?: Maybe<NestedEnumStripePaymentMethodTypeFilter>;
  equals?: Maybe<StripePaymentMethodType>;
  in?: Maybe<Array<StripePaymentMethodType>>;
  not?: Maybe<NestedEnumStripePaymentMethodTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentMethodType>>;
};

export type NestedEnumStripePaymentTransferTypeFilter = {
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
};

export type NestedEnumStripePaymentTransferTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  _min?: Maybe<NestedEnumStripePaymentTransferTypeFilter>;
  equals?: Maybe<StripePaymentTransferType>;
  in?: Maybe<Array<StripePaymentTransferType>>;
  not?: Maybe<NestedEnumStripePaymentTransferTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePaymentTransferType>>;
};

export type NestedEnumStripePayoutStatusFilter = {
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusFilter>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
};

export type NestedEnumStripePayoutStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripePayoutStatusFilter>;
  _min?: Maybe<NestedEnumStripePayoutStatusFilter>;
  equals?: Maybe<StripePayoutStatus>;
  in?: Maybe<Array<StripePayoutStatus>>;
  not?: Maybe<NestedEnumStripePayoutStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripePayoutStatus>>;
};

export type NestedEnumStripeReceiptMediumFilter = {
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
};

export type NestedEnumStripeReceiptMediumWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  _min?: Maybe<NestedEnumStripeReceiptMediumFilter>;
  equals?: Maybe<StripeReceiptMedium>;
  in?: Maybe<Array<StripeReceiptMedium>>;
  not?: Maybe<NestedEnumStripeReceiptMediumWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeReceiptMedium>>;
};

export type NestedEnumStripeTerminalLocationTypeFilter = {
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
};

export type NestedEnumStripeTerminalLocationTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  _min?: Maybe<NestedEnumStripeTerminalLocationTypeFilter>;
  equals?: Maybe<StripeTerminalLocationType>;
  in?: Maybe<Array<StripeTerminalLocationType>>;
  not?: Maybe<NestedEnumStripeTerminalLocationTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalLocationType>>;
};

export type NestedEnumStripeTerminalModelFilter = {
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelFilter>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
};

export type NestedEnumStripeTerminalModelWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalModelFilter>;
  _min?: Maybe<NestedEnumStripeTerminalModelFilter>;
  equals?: Maybe<StripeTerminalModel>;
  in?: Maybe<Array<StripeTerminalModel>>;
  not?: Maybe<NestedEnumStripeTerminalModelWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalModel>>;
};

export type NestedEnumStripeTerminalStatusFilter = {
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
};

export type NestedEnumStripeTerminalStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  _min?: Maybe<NestedEnumStripeTerminalStatusFilter>;
  equals?: Maybe<StripeTerminalStatus>;
  in?: Maybe<Array<StripeTerminalStatus>>;
  not?: Maybe<NestedEnumStripeTerminalStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<StripeTerminalStatus>>;
};

export type NestedEnumSupportEventStatusFilter = {
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusFilter>;
  notIn?: Maybe<Array<SupportEventStatus>>;
};

export type NestedEnumSupportEventStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSupportEventStatusFilter>;
  _min?: Maybe<NestedEnumSupportEventStatusFilter>;
  equals?: Maybe<SupportEventStatus>;
  in?: Maybe<Array<SupportEventStatus>>;
  not?: Maybe<NestedEnumSupportEventStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<SupportEventStatus>>;
};

export type NestedEnumSurveyStepNullableFilter = {
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableFilter>;
  notIn?: Maybe<Array<SurveyStep>>;
};

export type NestedEnumSurveyStepNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSurveyStepNullableFilter>;
  _min?: Maybe<NestedEnumSurveyStepNullableFilter>;
  equals?: Maybe<SurveyStep>;
  in?: Maybe<Array<SurveyStep>>;
  not?: Maybe<NestedEnumSurveyStepNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyStep>>;
};

export type NestedEnumSurveyTypeFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type NestedEnumSurveyTypeNullableFilter = {
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type NestedEnumSurveyTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  _min?: Maybe<NestedEnumSurveyTypeNullableFilter>;
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type NestedEnumSurveyTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumSurveyTypeFilter>;
  _min?: Maybe<NestedEnumSurveyTypeFilter>;
  equals?: Maybe<SurveyType>;
  in?: Maybe<Array<SurveyType>>;
  not?: Maybe<NestedEnumSurveyTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<SurveyType>>;
};

export type NestedEnumTemplateAvailabilityFilter = {
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  notIn?: Maybe<Array<TemplateAvailability>>;
};

export type NestedEnumTemplateAvailabilityWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  _min?: Maybe<NestedEnumTemplateAvailabilityFilter>;
  equals?: Maybe<TemplateAvailability>;
  in?: Maybe<Array<TemplateAvailability>>;
  not?: Maybe<NestedEnumTemplateAvailabilityWithAggregatesFilter>;
  notIn?: Maybe<Array<TemplateAvailability>>;
};

export type NestedEnumTwilioRecordingStatusFilter = {
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
};

export type NestedEnumTwilioRecordingStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  _min?: Maybe<NestedEnumTwilioRecordingStatusFilter>;
  equals?: Maybe<TwilioRecordingStatus>;
  in?: Maybe<Array<TwilioRecordingStatus>>;
  not?: Maybe<NestedEnumTwilioRecordingStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<TwilioRecordingStatus>>;
};

export type NestedEnumTwilioVerificationStatusFilter = {
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
};

export type NestedEnumTwilioVerificationStatusWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  _min?: Maybe<NestedEnumTwilioVerificationStatusFilter>;
  equals?: Maybe<TwilioVerificationStatus>;
  in?: Maybe<Array<TwilioVerificationStatus>>;
  not?: Maybe<NestedEnumTwilioVerificationStatusWithAggregatesFilter>;
  notIn?: Maybe<Array<TwilioVerificationStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeFilter>;
  notIn?: Maybe<Array<UserType>>;
};

export type NestedEnumUserTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumUserTypeFilter>;
  _min?: Maybe<NestedEnumUserTypeFilter>;
  equals?: Maybe<UserType>;
  in?: Maybe<Array<UserType>>;
  not?: Maybe<NestedEnumUserTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<UserType>>;
};

export type NestedEnumVetDataIntegrationSystemFilter = {
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
};

export type NestedEnumVetDataIntegrationSystemWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  _min?: Maybe<NestedEnumVetDataIntegrationSystemFilter>;
  equals?: Maybe<VetDataIntegrationSystem>;
  in?: Maybe<Array<VetDataIntegrationSystem>>;
  not?: Maybe<NestedEnumVetDataIntegrationSystemWithAggregatesFilter>;
  notIn?: Maybe<Array<VetDataIntegrationSystem>>;
};

export type NestedEnumVetLicenseTypeFilter = {
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeFilter>;
  notIn?: Maybe<Array<VetLicenseType>>;
};

export type NestedEnumVetLicenseTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetLicenseTypeFilter>;
  _min?: Maybe<NestedEnumVetLicenseTypeFilter>;
  equals?: Maybe<VetLicenseType>;
  in?: Maybe<Array<VetLicenseType>>;
  not?: Maybe<NestedEnumVetLicenseTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VetLicenseType>>;
};

export type NestedEnumVetsourceCodeTagTypeFilter = {
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
};

export type NestedEnumVetsourceCodeTagTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  _min?: Maybe<NestedEnumVetsourceCodeTagTypeFilter>;
  equals?: Maybe<VetsourceCodeTagType>;
  in?: Maybe<Array<VetsourceCodeTagType>>;
  not?: Maybe<NestedEnumVetsourceCodeTagTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VetsourceCodeTagType>>;
};

export type NestedEnumVetsourceInstallationConnectivityStateFilter = {
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
};

export type NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  _min?: Maybe<NestedEnumVetsourceInstallationConnectivityStateFilter>;
  equals?: Maybe<VetsourceInstallationConnectivityState>;
  in?: Maybe<Array<VetsourceInstallationConnectivityState>>;
  not?: Maybe<NestedEnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  notIn?: Maybe<Array<VetsourceInstallationConnectivityState>>;
};

export type NestedEnumWidgetRequestTypeFilter = {
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  notIn?: Maybe<Array<WidgetRequestType>>;
};

export type NestedEnumWidgetRequestTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  _min?: Maybe<NestedEnumWidgetRequestTypeFilter>;
  equals?: Maybe<WidgetRequestType>;
  in?: Maybe<Array<WidgetRequestType>>;
  not?: Maybe<NestedEnumWidgetRequestTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WidgetRequestType>>;
};

export type NestedEnumWorkflowActionTypeFilter = {
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  notIn?: Maybe<Array<WorkflowActionType>>;
};

export type NestedEnumWorkflowActionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowActionTypeFilter>;
  equals?: Maybe<WorkflowActionType>;
  in?: Maybe<Array<WorkflowActionType>>;
  not?: Maybe<NestedEnumWorkflowActionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowActionType>>;
};

export type NestedEnumWorkflowEventFilter = {
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventFilter>;
  notIn?: Maybe<Array<WorkflowEvent>>;
};

export type NestedEnumWorkflowEventTriggerTypeFilter = {
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
};

export type NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowEventTriggerTypeFilter>;
  equals?: Maybe<WorkflowEventTriggerType>;
  in?: Maybe<Array<WorkflowEventTriggerType>>;
  not?: Maybe<NestedEnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowEventTriggerType>>;
};

export type NestedEnumWorkflowEventWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowEventFilter>;
  _min?: Maybe<NestedEnumWorkflowEventFilter>;
  equals?: Maybe<WorkflowEvent>;
  in?: Maybe<Array<WorkflowEvent>>;
  not?: Maybe<NestedEnumWorkflowEventWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowEvent>>;
};

export type NestedEnumWorkflowSendingHoursFilter = {
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
};

export type NestedEnumWorkflowSendingHoursWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  _min?: Maybe<NestedEnumWorkflowSendingHoursFilter>;
  equals?: Maybe<WorkflowSendingHours>;
  in?: Maybe<Array<WorkflowSendingHours>>;
  not?: Maybe<NestedEnumWorkflowSendingHoursWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowSendingHours>>;
};

export type NestedEnumWorkflowTypeFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type NestedEnumWorkflowTypeNullableFilter = {
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type NestedEnumWorkflowTypeNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeNullableFilter>;
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type NestedEnumWorkflowTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowTypeFilter>;
  equals?: Maybe<WorkflowType>;
  in?: Maybe<Array<WorkflowType>>;
  not?: Maybe<NestedEnumWorkflowTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowType>>;
};

export type NestedEnumWorkflowVisitTypeFilter = {
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
};

export type NestedEnumWorkflowVisitTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  _min?: Maybe<NestedEnumWorkflowVisitTypeFilter>;
  equals?: Maybe<WorkflowVisitType>;
  in?: Maybe<Array<WorkflowVisitType>>;
  not?: Maybe<NestedEnumWorkflowVisitTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<WorkflowVisitType>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedFloatNullableFilter>;
  _min?: Maybe<NestedFloatNullableFilter>;
  _sum?: Maybe<NestedFloatNullableFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedFloatFilter>;
  _min?: Maybe<NestedFloatFilter>;
  _sum?: Maybe<NestedFloatFilter>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedJsonFilter = {
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export type NestedJsonNullableFilter = {
  array_contains?: Maybe<Scalars['Json']>;
  array_ends_with?: Maybe<Scalars['Json']>;
  array_starts_with?: Maybe<Scalars['Json']>;
  equals?: Maybe<Scalars['Json']>;
  gt?: Maybe<Scalars['Json']>;
  gte?: Maybe<Scalars['Json']>;
  lt?: Maybe<Scalars['Json']>;
  lte?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NonPrismaSchemaScriptRan = {
  __typename?: 'NonPrismaSchemaScriptRan';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  scriptName: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NonPrismaSchemaScriptRanCountAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  scriptName: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NonPrismaSchemaScriptRanCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NonPrismaSchemaScriptRanCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanMaxAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NonPrismaSchemaScriptRanMinAggregateOutputType = {
  __typename?: 'NonPrismaSchemaScriptRanMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum NonPrismaSchemaScriptRanOrderByRelevanceFieldEnum {
  Id = 'id',
  ScriptName = 'scriptName',
  Status = 'status'
}

export type NonPrismaSchemaScriptRanOrderByRelevanceInput = {
  fields: Array<NonPrismaSchemaScriptRanOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type NonPrismaSchemaScriptRanOrderByWithAggregationInput = {
  _count?: Maybe<NonPrismaSchemaScriptRanCountOrderByAggregateInput>;
  _max?: Maybe<NonPrismaSchemaScriptRanMaxOrderByAggregateInput>;
  _min?: Maybe<NonPrismaSchemaScriptRanMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<NonPrismaSchemaScriptRanOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  scriptName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum NonPrismaSchemaScriptRanScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ScriptName = 'scriptName',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NonPrismaSchemaScriptRanScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  scriptName?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type NonPrismaSchemaScriptRanUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NonPrismaSchemaScriptRanWhereInput = {
  AND?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  NOT?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  OR?: Maybe<Array<NonPrismaSchemaScriptRanWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  scriptName?: Maybe<StringFilter>;
  status?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NonPrismaSchemaScriptRanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  scriptName?: Maybe<Scalars['String']>;
};

export enum NormalizedWritebackError {
  BitwerxDown = 'Bitwerx_Down',
  BitwerxGenericError = 'Bitwerx_GenericError',
  BitwerxHeartbeatTooOld = 'Bitwerx_HeartbeatTooOld',
  BitwerxInvalidConfiguration = 'Bitwerx_InvalidConfiguration',
  BitwerxInvalidQuery = 'Bitwerx_InvalidQuery',
  BitwerxInvoiceClosed = 'Bitwerx_InvoiceClosed',
  BitwerxPimsConnectivityIssue = 'Bitwerx_PimsConnectivityIssue',
  BitwerxTimedOut = 'Bitwerx_TimedOut',
  BitwerxUnknown = 'Bitwerx_Unknown'
}

export enum NotificationEvent {
  AppointmentArrivalConfirmation = 'AppointmentArrivalConfirmation',
  AppointmentCancelation = 'AppointmentCancelation',
  AppointmentConfirmation = 'AppointmentConfirmation',
  AppointmentConfirmed = 'AppointmentConfirmed',
  AppointmentFollowup = 'AppointmentFollowup',
  AppointmentPimsSync = 'AppointmentPimsSync',
  AppointmentReminder = 'AppointmentReminder',
  AppointmentRescheduleRequested = 'AppointmentRescheduleRequested',
  AppointmentScheduled = 'AppointmentScheduled',
  CallDeflectionFormSubmitted = 'CallDeflectionFormSubmitted',
  ServiceReminderFormSubmitted = 'ServiceReminderFormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequestFormSubmitted'
}

export type NotificationEventToMessage = {
  __typename?: 'NotificationEventToMessage';
  createdAt: Scalars['DateTime'];
  emailBody: Scalars['String'];
  emailCallToAction: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  event: NotificationEvent;
  id: Scalars['String'];
  smsMessage: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationEventToMessageCountAggregateOutputType = {
  __typename?: 'NotificationEventToMessageCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailBody: Scalars['Int'];
  emailCallToAction: Scalars['Int'];
  emailSalutation: Scalars['Int'];
  emailSubject: Scalars['Int'];
  emailTitle: Scalars['Int'];
  event: Scalars['Int'];
  id: Scalars['Int'];
  smsMessage: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NotificationEventToMessageCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NotificationEventToMessageCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody: Scalars['String'];
  emailCallToAction: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  event: NotificationEvent;
  id?: Maybe<Scalars['String']>;
  smsMessage: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody: Scalars['String'];
  emailCallToAction: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  event: NotificationEvent;
  id?: Maybe<Scalars['String']>;
  smsMessage: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageMaxAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NotificationEventToMessageMinAggregateOutputType = {
  __typename?: 'NotificationEventToMessageMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum NotificationEventToMessageOrderByRelevanceFieldEnum {
  EmailBody = 'emailBody',
  EmailCallToAction = 'emailCallToAction',
  EmailSalutation = 'emailSalutation',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  Id = 'id',
  SmsMessage = 'smsMessage'
}

export type NotificationEventToMessageOrderByRelevanceInput = {
  fields: Array<NotificationEventToMessageOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type NotificationEventToMessageOrderByWithAggregationInput = {
  _count?: Maybe<NotificationEventToMessageCountOrderByAggregateInput>;
  _max?: Maybe<NotificationEventToMessageMaxOrderByAggregateInput>;
  _min?: Maybe<NotificationEventToMessageMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<NotificationEventToMessageOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  emailBody?: Maybe<SortOrder>;
  emailCallToAction?: Maybe<SortOrder>;
  emailSalutation?: Maybe<SortOrder>;
  emailSubject?: Maybe<SortOrder>;
  emailTitle?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  smsMessage?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum NotificationEventToMessageScalarFieldEnum {
  CreatedAt = 'createdAt',
  EmailBody = 'emailBody',
  EmailCallToAction = 'emailCallToAction',
  EmailSalutation = 'emailSalutation',
  EmailSubject = 'emailSubject',
  EmailTitle = 'emailTitle',
  Event = 'event',
  Id = 'id',
  SmsMessage = 'smsMessage',
  UpdatedAt = 'updatedAt'
}

export type NotificationEventToMessageScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<NotificationEventToMessageScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailBody?: Maybe<StringWithAggregatesFilter>;
  emailCallToAction?: Maybe<StringWithAggregatesFilter>;
  emailSalutation?: Maybe<StringWithAggregatesFilter>;
  emailSubject?: Maybe<StringWithAggregatesFilter>;
  emailTitle?: Maybe<StringWithAggregatesFilter>;
  event?: Maybe<EnumNotificationEventWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  smsMessage?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type NotificationEventToMessageUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody: Scalars['String'];
  emailCallToAction: Scalars['String'];
  emailSalutation: Scalars['String'];
  emailSubject: Scalars['String'];
  emailTitle: Scalars['String'];
  event: NotificationEvent;
  id?: Maybe<Scalars['String']>;
  smsMessage: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailBody?: Maybe<Scalars['String']>;
  emailCallToAction?: Maybe<Scalars['String']>;
  emailSalutation?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTitle?: Maybe<Scalars['String']>;
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
  smsMessage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEventToMessageWhereInput = {
  AND?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  NOT?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  OR?: Maybe<Array<NotificationEventToMessageWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  emailBody?: Maybe<StringFilter>;
  emailCallToAction?: Maybe<StringFilter>;
  emailSalutation?: Maybe<StringFilter>;
  emailSubject?: Maybe<StringFilter>;
  emailTitle?: Maybe<StringFilter>;
  event?: Maybe<EnumNotificationEventFilter>;
  id?: Maybe<StringFilter>;
  smsMessage?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NotificationEventToMessageWhereUniqueInput = {
  event?: Maybe<NotificationEvent>;
  id?: Maybe<Scalars['String']>;
};

export enum NotificationSettingType {
  AppointmentConfirmed = 'Appointment_Confirmed',
  AppointmentRescheduleRequested = 'Appointment_RescheduleRequested',
  ConnectWidgetRequestSubmitted = 'ConnectWidget_RequestSubmitted',
  ConversationAssigned = 'Conversation_Assigned',
  ConversationNewMessage = 'Conversation_NewMessage',
  ConversationStatusChange = 'Conversation_StatusChange',
  FormSubmitted = 'Form_Submitted',
  PaymentReceived = 'Payment_Received'
}

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumAmericanKennelClubBreedGroupFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubBreedGroup>;
};

export type NullableEnumAmericanKennelClubDemeanorCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubDemeanorCategory>;
};

export type NullableEnumAmericanKennelClubEnergyLevelCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubEnergyLevelCategory>;
};

export type NullableEnumAmericanKennelClubGroomingFrequencyCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubGroomingFrequencyCategory>;
};

export type NullableEnumAmericanKennelClubSheddingCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubSheddingCategory>;
};

export type NullableEnumAmericanKennelClubTrainabilityCategoryFieldUpdateOperationsInput = {
  set?: Maybe<AmericanKennelClubTrainabilityCategory>;
};

export type NullableEnumAutomatedTriggerTypeFieldUpdateOperationsInput = {
  set?: Maybe<AutomatedTriggerType>;
};

export type NullableEnumBitwerxHealthcheckRunStatusFieldUpdateOperationsInput = {
  set?: Maybe<BitwerxHealthcheckRunStatus>;
};

export type NullableEnumBoardFilterDateRangeFieldUpdateOperationsInput = {
  set?: Maybe<BoardFilterDateRange>;
};

export type NullableEnumCampaignRegistryBrandIdentityStatusFieldUpdateOperationsInput = {
  set?: Maybe<CampaignRegistryBrandIdentityStatus>;
};

export type NullableEnumCampaignRegistryCampaignSharingStatusFieldUpdateOperationsInput = {
  set?: Maybe<CampaignRegistryCampaignSharingStatus>;
};

export type NullableEnumCancelReasonTypeFieldUpdateOperationsInput = {
  set?: Maybe<CancelReasonType>;
};

export type NullableEnumCardFundingFieldUpdateOperationsInput = {
  set?: Maybe<CardFunding>;
};

export type NullableEnumCareBenefitUnitOfMeasureFieldUpdateOperationsInput = {
  set?: Maybe<CareBenefitUnitOfMeasure>;
};

export type NullableEnumCareSubscriptionPaymentStatusFieldUpdateOperationsInput = {
  set?: Maybe<CareSubscriptionPaymentStatus>;
};

export type NullableEnumChannelMessageAttachmentEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentEntityType>;
};

export type NullableEnumChannelMessageAttachmentUploadStatusFieldUpdateOperationsInput = {
  set?: Maybe<ChannelMessageAttachmentUploadStatus>;
};

export type NullableEnumChannelTypeFieldUpdateOperationsInput = {
  set?: Maybe<ChannelType>;
};

export type NullableEnumChannelVisibilityFieldUpdateOperationsInput = {
  set?: Maybe<ChannelVisibility>;
};

export type NullableEnumClinicEntityCreationSourceFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityCreationSource>;
};

export type NullableEnumClinicEntityPhoneNumberSmsStatusFieldUpdateOperationsInput = {
  set?: Maybe<ClinicEntityPhoneNumberSmsStatus>;
};

export type NullableEnumClinicPetParentAddressTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClinicPetParentAddressType>;
};

export type NullableEnumContactVerificationMethodFieldUpdateOperationsInput = {
  set?: Maybe<ContactVerificationMethod>;
};

export type NullableEnumCustomFieldsDataSourceFieldUpdateOperationsInput = {
  set?: Maybe<CustomFieldsDataSource>;
};

export type NullableEnumDeviceOsFieldUpdateOperationsInput = {
  set?: Maybe<DeviceOs>;
};

export type NullableEnumDeviceTypeFieldUpdateOperationsInput = {
  set?: Maybe<DeviceType>;
};

export type NullableEnumEmailCampaignLogSkipReasonFieldUpdateOperationsInput = {
  set?: Maybe<EmailCampaignLogSkipReason>;
};

export type NullableEnumHillsToHomeSpeciesFieldUpdateOperationsInput = {
  set?: Maybe<HillsToHomeSpecies>;
};

export type NullableEnumInformScheduledCampaignCadenceFieldUpdateOperationsInput = {
  set?: Maybe<InformScheduledCampaignCadence>;
};

export type NullableEnumIntegrationStatusFieldUpdateOperationsInput = {
  set?: Maybe<IntegrationStatus>;
};

export type NullableEnumIntegrationTypeFieldUpdateOperationsInput = {
  set?: Maybe<IntegrationType>;
};

export type NullableEnumNormalizedWritebackErrorFieldUpdateOperationsInput = {
  set?: Maybe<NormalizedWritebackError>;
};

export type NullableEnumPaymentMediumFieldUpdateOperationsInput = {
  set?: Maybe<PaymentMedium>;
};

export type NullableEnumPaymentRelationshipFieldUpdateOperationsInput = {
  set?: Maybe<PaymentRelationship>;
};

export type NullableEnumPaymentScheduleFieldUpdateOperationsInput = {
  set?: Maybe<PaymentSchedule>;
};

export type NullableEnumPimsSyncStatusFieldUpdateOperationsInput = {
  set?: Maybe<PimsSyncStatus>;
};

export type NullableEnumProductSupplyDurationUnitFieldUpdateOperationsInput = {
  set?: Maybe<ProductSupplyDurationUnit>;
};

export type NullableEnumPushNotificationDeliveryErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<PushNotificationDeliveryErrorType>;
};

export type NullableEnumRenewalRejectionReasonFieldUpdateOperationsInput = {
  set?: Maybe<RenewalRejectionReason>;
};

export type NullableEnumSmsEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsEntityType>;
};

export type NullableEnumSmsNotificationDeliveryErrorTypeFieldUpdateOperationsInput = {
  set?: Maybe<SmsNotificationDeliveryErrorType>;
};

export type NullableEnumSpeciesTypeFieldUpdateOperationsInput = {
  set?: Maybe<SpeciesType>;
};

export type NullableEnumStripePaymentErrorFieldUpdateOperationsInput = {
  set?: Maybe<StripePaymentError>;
};

export type NullableEnumSurveyStepFieldUpdateOperationsInput = {
  set?: Maybe<SurveyStep>;
};

export type NullableEnumSurveyTypeFieldUpdateOperationsInput = {
  set?: Maybe<SurveyType>;
};

export type NullableEnumWorkflowTypeFieldUpdateOperationsInput = {
  set?: Maybe<WorkflowType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  set?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export enum NullableJsonNullValueInput {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NumberOfPetParentsReached = {
  __typename?: 'NumberOfPetParentsReached';
  numberOfPetPerents?: Maybe<Scalars['Int']>;
  timeEstimateInSeconds?: Maybe<Scalars['Int']>;
};

export type OperationResult = {
  __typename?: 'OperationResult';
  messages: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type OrderPhoneNumberInput = {
  phoneNumber: Scalars['String'];
};

export type OrderPhoneNumberResponse = {
  __typename?: 'OrderPhoneNumberResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Organization = {
  __typename?: 'Organization';
  EmailCampaignTemplate: Array<EmailCampaignTemplate>;
  _count: OrganizationCountOutputType;
  careAddonPackage: Array<CareAddonPackage>;
  careBenefits: Array<CareBenefit>;
  carePlanProviderGroups: Array<CarePlanProviderGroup>;
  carePlans: Array<CarePlan>;
  clinics: Array<Clinic>;
  configuration: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  crmId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationPetParents: Array<OrganizationPetParent>;
  organizationPets: Array<OrganizationPet>;
  paymentProcessorClinic?: Maybe<Clinic>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type OrganizationEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type OrganizationCareAddonPackageArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  distinct?: Maybe<CareAddonPackageScalarFieldEnum>;
  orderBy?: Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type OrganizationCareBenefitsArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  distinct?: Maybe<CareBenefitScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type OrganizationCarePlanProviderGroupsArgs = {
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  distinct?: Maybe<CarePlanProviderGroupScalarFieldEnum>;
  orderBy?: Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type OrganizationCarePlansArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<CarePlanScalarFieldEnum>;
  orderBy?: Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type OrganizationClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type OrganizationOrganizationPetParentsArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type OrganizationOrganizationPetsArgs = {
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  distinct?: Maybe<OrganizationPetScalarFieldEnum>;
  orderBy?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetWhereInput>;
};

export type OrganizationCareBenefitToClinicService = {
  __typename?: 'OrganizationCareBenefitToClinicService';
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  service: Service;
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategory = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategory';
  benefit: CareBenefit;
  benefitId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  serviceCategory: ServiceCategory;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryBenefitIdServiceCategoryIdCompoundUniqueInput = {
  benefitId: Scalars['String'];
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryCountAggregateOutputType';
  _all: Scalars['Int'];
  benefitId: Scalars['Int'];
  createdAt: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput = {
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput = {
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory: ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput = {
  benefit: CareBenefitCreateNestedOneWithoutConnectedServiceCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryListRelationFilter = {
  every?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  none?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
  some?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCategoryMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  ServiceCategoryId = 'serviceCategoryId'
}

export type OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceInput = {
  fields: Array<OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceCategoryMinOrderByAggregateInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelevanceInput>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  serviceCategoryId?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum {
  BenefitId = 'benefitId',
  CreatedAt = 'createdAt',
  ServiceCategoryId = 'serviceCategoryId'
}

export type OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  serviceCategoryId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput = {
  benefitId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedCareBenefitsInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedServiceCategoriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput = {
  benefitId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput = {
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedServiceCategoriesInput;
  where: OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput = {
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutConnectedCareBenefitsInput;
  where: OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutBenefitInput = {
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  data: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithoutBenefitInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpdateWithoutServiceCategoryInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutConnectedServiceCategoriesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutBenefitInput = {
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutBenefitInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  create: OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateWithoutServiceCategoryInput;
  update: OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateWithoutServiceCategoryInput;
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCategoryWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategoryWhereInput>>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
  serviceCategoryId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput = {
  benefitId_serviceCategoryId?: Maybe<OrganizationCareBenefitToClinicServiceCategoryBenefitIdServiceCategoryIdCompoundUniqueInput>;
};

export type OrganizationCareBenefitToClinicServiceCountAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceCountAggregateOutputType';
  _all: Scalars['Int'];
  benefitId: Scalars['Int'];
  serviceId: Scalars['Int'];
};

export type OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceCreateInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyBenefitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateManyInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope = {
  data: Array<OrganizationCareBenefitToClinicServiceCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput = {
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput = {
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput = {
  service: ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput = {
  benefit: CareBenefitCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type OrganizationCareBenefitToClinicServiceListRelationFilter = {
  every?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  none?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  some?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};

export type OrganizationCareBenefitToClinicServiceMaxAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMaxAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceMinAggregateOutputType = {
  __typename?: 'OrganizationCareBenefitToClinicServiceMinAggregateOutputType';
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput = {
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId'
}

export type OrganizationCareBenefitToClinicServiceOrderByRelevanceInput = {
  fields: Array<OrganizationCareBenefitToClinicServiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationCareBenefitToClinicServiceOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationCareBenefitToClinicServiceCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationCareBenefitToClinicServiceMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationCareBenefitToClinicServiceMinOrderByAggregateInput>;
  benefitId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
};

export type OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelevanceInput>;
  benefit?: Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>;
  benefitId?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
};

export enum OrganizationCareBenefitToClinicServiceScalarFieldEnum {
  BenefitId = 'benefitId',
  ServiceId = 'serviceId'
}

export type OrganizationCareBenefitToClinicServiceScalarWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  benefitId?: Maybe<StringFilter>;
  serviceId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereWithAggregatesInput>>;
  benefitId?: Maybe<StringWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateInput = {
  benefitId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutBenefitInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput = {
  serviceId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput = {
  benefitId: Scalars['String'];
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyInput = {
  benefitId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput = {
  serviceId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput = {
  benefitId?: Maybe<Scalars['String']>;
};

export type OrganizationCareBenefitToClinicServiceUpdateInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput = {
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput = {
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutOrganizationCareBenefitToClinicServiceInput;
  where: OrganizationCareBenefitToClinicServiceScalarWhereInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutBenefitNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutBenefitInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutBenefitInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyBenefitInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutBenefitInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<OrganizationCareBenefitToClinicServiceCreateWithoutServiceInput>>;
  createMany?: Maybe<OrganizationCareBenefitToClinicServiceCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutBenefitInput = {
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithWhereUniqueWithoutServiceInput = {
  data: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutBenefitInput = {
  service?: Maybe<ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpdateWithoutServiceInput = {
  benefit?: Maybe<CareBenefitUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput>;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutBenefitInput = {
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutBenefitInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutBenefitInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceUpsertWithWhereUniqueWithoutServiceInput = {
  create: OrganizationCareBenefitToClinicServiceUncheckedCreateWithoutServiceInput;
  update: OrganizationCareBenefitToClinicServiceUncheckedUpdateWithoutServiceInput;
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};

export type OrganizationCareBenefitToClinicServiceWhereInput = {
  AND?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  NOT?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  OR?: Maybe<Array<OrganizationCareBenefitToClinicServiceWhereInput>>;
  benefit?: Maybe<CareBenefitWhereInput>;
  benefitId?: Maybe<StringFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringFilter>;
};

export type OrganizationCareBenefitToClinicServiceWhereUniqueInput = {
  benefitId_serviceId?: Maybe<OrganizationCareBenefitToClinicServiceBenefitIdServiceIdCompoundUniqueInput>;
};

export type OrganizationCountAggregateOutputType = {
  __typename?: 'OrganizationCountAggregateOutputType';
  _all: Scalars['Int'];
  configuration: Scalars['Int'];
  createdAt: Scalars['Int'];
  crmId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  paymentProcessorClinicId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrganizationCountOrderByAggregateInput = {
  configuration?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationCountOutputType = {
  __typename?: 'OrganizationCountOutputType';
  EmailCampaignTemplate: Scalars['Int'];
  careAddonPackage: Scalars['Int'];
  careBenefits: Scalars['Int'];
  carePlanProviderGroups: Scalars['Int'];
  carePlans: Scalars['Int'];
  clinics: Scalars['Int'];
  organizationPetParents: Scalars['Int'];
  organizationPets: Scalars['Int'];
};

export type OrganizationCreateInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateManyInput = {
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateNestedOneWithoutCareAddonPackageInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
};

export type OrganizationCreateNestedOneWithoutCareBenefitsInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
};

export type OrganizationCreateNestedOneWithoutCarePlanProviderGroupsInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput>;
};

export type OrganizationCreateNestedOneWithoutCarePlansInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
};

export type OrganizationCreateNestedOneWithoutClinicsInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
};

export type OrganizationCreateNestedOneWithoutEmailCampaignTemplateInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetParentsInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPetsInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
};

export type OrganizationCreateNestedOneWithoutPaymentProcessorClinicInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationCreateOrConnectWithoutCareAddonPackageInput = {
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCareBenefitsInput = {
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput = {
  create: OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCarePlansInput = {
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutClinicsInput = {
  create: OrganizationUncheckedCreateWithoutClinicsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput = {
  create: OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetParentsInput = {
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPetsInput = {
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput = {
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateWithoutCareAddonPackageInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutCareBenefitsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutCarePlanProviderGroupsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutCarePlansInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutClinicsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutEmailCampaignTemplateInput = {
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutOrganizationPetParentsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutOrganizationPetsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinic?: Maybe<ClinicCreateNestedOneWithoutCarePayoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationCreateWithoutPaymentProcessorClinicInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMaxAggregateOutputType = {
  __typename?: 'OrganizationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationMinAggregateOutputType = {
  __typename?: 'OrganizationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum OrganizationOrderByRelevanceFieldEnum {
  CrmId = 'crmId',
  Id = 'id',
  Name = 'name',
  PaymentProcessorClinicId = 'paymentProcessorClinicId'
}

export type OrganizationOrderByRelevanceInput = {
  fields: Array<OrganizationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationMinOrderByAggregateInput>;
  configuration?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationOrderByWithRelationAndSearchRelevanceInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationOrderByRelevanceInput>;
  careAddonPackage?: Maybe<CareAddonPackageOrderByRelationAggregateInput>;
  careBenefits?: Maybe<CareBenefitOrderByRelationAggregateInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupOrderByRelationAggregateInput>;
  carePlans?: Maybe<CarePlanOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  configuration?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  organizationPets?: Maybe<OrganizationPetOrderByRelationAggregateInput>;
  paymentProcessorClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  paymentProcessorClinicId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPet = {
  __typename?: 'OrganizationPet';
  TransferFromPetEnrollmentSupportEvent: Array<EnrollmentSupportEvent>;
  TransferToPetEnrollmentSupportEvent: Array<EnrollmentSupportEvent>;
  _count: OrganizationPetCountOutputType;
  associatedBenefitUsageFollowup: Array<CareBenefitUsageFollowup>;
  associatedSubscriptions: Array<CareStripeSubscription>;
  careAddonEnrollments: Array<CareAddonEnrollment>;
  carePlanEnrollments: Array<CarePlanEnrollment>;
  clinicPets: Array<ClinicPet>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type OrganizationPetTransferFromPetEnrollmentSupportEventArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type OrganizationPetTransferToPetEnrollmentSupportEventArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type OrganizationPetAssociatedBenefitUsageFollowupArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageFollowupScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type OrganizationPetAssociatedSubscriptionsArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type OrganizationPetCareAddonEnrollmentsArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CareAddonEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type OrganizationPetCarePlanEnrollmentsArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<CarePlanEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type OrganizationPetClinicPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type OrganizationPetOrganizationPetToOrganizationPetParentsArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type OrganizationPetCountAggregateOutputType = {
  __typename?: 'OrganizationPetCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  preferredName: Scalars['Int'];
  profilePictureUrl: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrganizationPetCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetCountOutputType = {
  __typename?: 'OrganizationPetCountOutputType';
  TransferFromPetEnrollmentSupportEvent: Scalars['Int'];
  TransferToPetEnrollmentSupportEvent: Scalars['Int'];
  associatedBenefitUsageFollowup: Scalars['Int'];
  associatedSubscriptions: Scalars['Int'];
  careAddonEnrollments: Scalars['Int'];
  carePlanEnrollments: Scalars['Int'];
  clinicPets: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
};

export type OrganizationPetCreateInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateManyOrganizationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
};

export type OrganizationPetCreateNestedOneWithoutAssociatedBenefitUsageFollowupInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput>;
};

export type OrganizationPetCreateNestedOneWithoutAssociatedSubscriptionsInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput>;
};

export type OrganizationPetCreateNestedOneWithoutCareAddonEnrollmentsInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
};

export type OrganizationPetCreateNestedOneWithoutCarePlanEnrollmentsInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
};

export type OrganizationPetCreateNestedOneWithoutClinicPetsInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
};

export type OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetCreateNestedOneWithoutTransferFromPetEnrollmentSupportEventInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput>;
};

export type OrganizationPetCreateNestedOneWithoutTransferToPetEnrollmentSupportEventInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput>;
};

export type OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput = {
  create: OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput = {
  create: OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput = {
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput = {
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutClinicPetsInput = {
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput = {
  create: OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput = {
  create: OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetCreateWithoutAssociatedBenefitUsageFollowupInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutAssociatedSubscriptionsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutCareAddonEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutCarePlanEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutClinicPetsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutOrganizationInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutTransferFromPetEnrollmentSupportEventInput = {
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetCreateWithoutTransferToPetEnrollmentSupportEventInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutTransferFromPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetEnrollmentInput = {
  careAddonIds: Array<Scalars['String']>;
  carePlanIds: Array<Scalars['String']>;
  organizationPetId: Scalars['String'];
};

export type OrganizationPetListRelationFilter = {
  every?: Maybe<OrganizationPetWhereInput>;
  none?: Maybe<OrganizationPetWhereInput>;
  some?: Maybe<OrganizationPetWhereInput>;
};

export type OrganizationPetMaxAggregateOutputType = {
  __typename?: 'OrganizationPetMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetMinAggregateOutputType = {
  __typename?: 'OrganizationPetMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetOrderByRelevanceFieldEnum {
  Id = 'id',
  OrganizationId = 'organizationId',
  PreferredName = 'preferredName',
  ProfilePictureUrl = 'profilePictureUrl'
}

export type OrganizationPetOrderByRelevanceInput = {
  fields: Array<OrganizationPetOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationPetOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationPetCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredName?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetOrderByWithRelationAndSearchRelevanceInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  _relevance?: Maybe<OrganizationPetOrderByRelevanceInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupOrderByRelationAggregateInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentOrderByRelationAggregateInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentOrderByRelationAggregateInput>;
  clinicPets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  preferredName?: Maybe<SortOrder>;
  profilePictureUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParent = {
  __typename?: 'OrganizationPetParent';
  _count: OrganizationPetParentCountOutputType;
  /** @deprecated - as parents might have multiple pet subscriptions */
  activeCareStripeSubscription?: Maybe<CareStripeSubscription>;
  /** @deprecated - as parents might have multiple pet subscriptions */
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions: Array<CareStripeSubscription>;
  clinicPetParents: Array<ClinicPetParent>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents: Array<OrganizationPetToOrganizationPetParent>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomer>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type OrganizationPetParentAllCareStripeSubscriptionsArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type OrganizationPetParentClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type OrganizationPetParentOrganizationPetToOrganizationPetParentsArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>;
  orderBy?: Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type OrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetParentCountAggregateOutputType';
  _all: Scalars['Int'];
  activeCareStripeSubscriptionId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  preferredFirstName: Scalars['Int'];
  preferredLastName: Scalars['Int'];
  stripeCustomerTableId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OrganizationPetParentCountOrderByAggregateInput = {
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParentCountOutputType = {
  __typename?: 'OrganizationPetParentCountOutputType';
  allCareStripeSubscriptions: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  organizationPetToOrganizationPetParents: Scalars['Int'];
};

export type OrganizationPetParentCreateInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateManyInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateManyOrganizationInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateManyOrganizationInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyOrganizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateManyStripeCustomerInputEnvelope = {
  data: Array<OrganizationPetParentCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetParentCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
};

export type OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
};

export type OrganizationPetParentCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutAllCareStripeSubscriptionsInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutClinicPetParentsInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
};

export type OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput = {
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput = {
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentCreateWithoutActiveCareStripeSubscriptionInput = {
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateWithoutAllCareStripeSubscriptionsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateWithoutClinicPetParentsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateWithoutOrganizationInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentCreateWithoutStripeCustomerInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionCreateNestedOneWithoutActiveSubscriberInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPetParentsInput;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetParentWhereInput>;
};

export type OrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetParentMaxAggregateOutputType';
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentMaxOrderByAggregateInput = {
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetParentMinAggregateOutputType';
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentMinOrderByAggregateInput = {
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetParentOrderByRelevanceFieldEnum {
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  Id = 'id',
  OrganizationId = 'organizationId',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  StripeCustomerTableId = 'stripeCustomerTableId'
}

export type OrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetParentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationPetParentOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetParentMinOrderByAggregateInput>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<OrganizationPetParentOrderByRelevanceInput>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  activeCareStripeSubscriptionId?: Maybe<SortOrder>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>;
  organizationId?: Maybe<SortOrder>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput>;
  preferredFirstName?: Maybe<SortOrder>;
  preferredLastName?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerTableId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OrganizationPetParentRelationFilter = {
  is?: Maybe<OrganizationPetParentWhereInput>;
  isNot?: Maybe<OrganizationPetParentWhereInput>;
};

export enum OrganizationPetParentScalarFieldEnum {
  ActiveCareStripeSubscriptionId = 'activeCareStripeSubscriptionId',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrganizationId = 'organizationId',
  PreferredFirstName = 'preferredFirstName',
  PreferredLastName = 'preferredLastName',
  StripeCustomerTableId = 'stripeCustomerTableId',
  UpdatedAt = 'updatedAt'
}

export type OrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  organizationId?: Maybe<StringFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetParentScalarWhereWithAggregatesInput>>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  preferredFirstName?: Maybe<StringWithAggregatesFilter>;
  preferredLastName?: Maybe<StringWithAggregatesFilter>;
  stripeCustomerTableId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type OrganizationPetParentUncheckedCreateInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
};

export type OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
};

export type OrganizationPetParentUncheckedCreateNestedOneWithoutActiveCareStripeSubscriptionInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput = {
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutSubscriptionOwnerInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput>;
  preferredFirstName: Scalars['String'];
  preferredLastName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateManyInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type OrganizationPetParentUncheckedUpdateOneWithoutActiveCareStripeSubscriptionNestedInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput = {
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomerTableId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
  where: OrganizationPetParentScalarWhereInput;
};

export type OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput = {
  data: OrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentsInput;
  where: OrganizationPetParentScalarWhereInput;
};

export type OrganizationPetParentUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetParentCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<OrganizationPetParentCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<OrganizationPetParentCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<OrganizationPetParentUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutAllCareStripeSubscriptionsNestedInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutAllCareStripeSubscriptionsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput>;
};

export type OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetParentUpdateOneWithoutActiveCareStripeSubscriptionNestedInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutActiveCareStripeSubscriptionInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput>;
};

export type OrganizationPetParentUpdateOneWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<OrganizationPetParentWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetParentCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput>;
  upsert?: Maybe<OrganizationPetParentUpsertWithoutClinicPetParentsInput>;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  data: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentUpdateWithoutActiveCareStripeSubscriptionInput = {
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateWithoutAllCareStripeSubscriptionsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateWithoutClinicPetParentsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateWithoutOrganizationInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpdateWithoutStripeCustomerInput = {
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneWithoutActiveSubscriberNestedInput>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutSubscriptionOwnerNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredLastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationInput;
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  create: OrganizationPetParentUncheckedCreateWithoutStripeCustomerInput;
  update: OrganizationPetParentUncheckedUpdateWithoutStripeCustomerInput;
  where: OrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetParentUpsertWithoutActiveCareStripeSubscriptionInput = {
  create: OrganizationPetParentUncheckedCreateWithoutActiveCareStripeSubscriptionInput;
  update: OrganizationPetParentUncheckedUpdateWithoutActiveCareStripeSubscriptionInput;
};

export type OrganizationPetParentUpsertWithoutAllCareStripeSubscriptionsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutAllCareStripeSubscriptionsInput;
  update: OrganizationPetParentUncheckedUpdateWithoutAllCareStripeSubscriptionsInput;
};

export type OrganizationPetParentUpsertWithoutClinicPetParentsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutClinicPetParentsInput;
  update: OrganizationPetParentUncheckedUpdateWithoutClinicPetParentsInput;
};

export type OrganizationPetParentUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  create: OrganizationPetParentUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
  update: OrganizationPetParentUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetParentWhereInput>>;
  activeCareStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  activeCareStripeSubscriptionId?: Maybe<StringNullableFilter>;
  allCareStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
  preferredFirstName?: Maybe<StringFilter>;
  preferredLastName?: Maybe<StringFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  stripeCustomerTableId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrganizationPetParentWhereUniqueInput = {
  activeCareStripeSubscriptionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type OrganizationPetRelationFilter = {
  is?: Maybe<OrganizationPetWhereInput>;
  isNot?: Maybe<OrganizationPetWhereInput>;
};

export enum OrganizationPetScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OrganizationId = 'organizationId',
  PreferredName = 'preferredName',
  ProfilePictureUrl = 'profilePictureUrl',
  UpdatedAt = 'updatedAt'
}

export type OrganizationPetScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  organizationId?: Maybe<StringFilter>;
  preferredName?: Maybe<StringFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrganizationPetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  organizationId?: Maybe<StringWithAggregatesFilter>;
  preferredName?: Maybe<StringWithAggregatesFilter>;
  profilePictureUrl?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParent = {
  __typename?: 'OrganizationPetToOrganizationPetParent';
  organizationPet: OrganizationPet;
  organizationPetId: Scalars['String'];
  organizationPetParent: OrganizationPetParent;
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCountAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentCountAggregateOutputType';
  _all: Scalars['Int'];
  organizationPetId: Scalars['Int'];
  organizationPetParentId: Scalars['Int'];
};

export type OrganizationPetToOrganizationPetParentCountOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentCreateInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateManyInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope = {
  data: Array<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
};

export type OrganizationPetToOrganizationPetParentCreateNestedManyWithoutOrganizationPetParentInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput = {
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput = {
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput = {
  organizationPetParent: OrganizationPetParentCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput = {
  organizationPet: OrganizationPetCreateNestedOneWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetToOrganizationPetParentListRelationFilter = {
  every?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  none?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
  some?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};

export type OrganizationPetToOrganizationPetParentMaxAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMaxAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentMinAggregateOutputType = {
  __typename?: 'OrganizationPetToOrganizationPetParentMinAggregateOutputType';
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentMinOrderByAggregateInput = {
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId'
}

export type OrganizationPetToOrganizationPetParentOrderByRelevanceInput = {
  fields: Array<OrganizationPetToOrganizationPetParentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OrganizationPetToOrganizationPetParentOrderByWithAggregationInput = {
  _count?: Maybe<OrganizationPetToOrganizationPetParentCountOrderByAggregateInput>;
  _max?: Maybe<OrganizationPetToOrganizationPetParentMaxOrderByAggregateInput>;
  _min?: Maybe<OrganizationPetToOrganizationPetParentMinOrderByAggregateInput>;
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<OrganizationPetToOrganizationPetParentOrderByRelevanceInput>;
  organizationPet?: Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetId?: Maybe<SortOrder>;
  organizationPetParent?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  organizationPetParentId?: Maybe<SortOrder>;
};

export type OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export enum OrganizationPetToOrganizationPetParentScalarFieldEnum {
  OrganizationPetId = 'organizationPetId',
  OrganizationPetParentId = 'organizationPetParentId'
}

export type OrganizationPetToOrganizationPetParentScalarWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPetParentId?: Maybe<StringFilter>;
};

export type OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereWithAggregatesInput>>;
  organizationPetId?: Maybe<StringWithAggregatesFilter>;
  organizationPetParentId?: Maybe<StringWithAggregatesFilter>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateInput = {
  organizationPetId: Scalars['String'];
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetParentInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput = {
  organizationPetParentId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput = {
  organizationPetId: Scalars['String'];
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyInput = {
  organizationPetId?: Maybe<Scalars['String']>;
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetParentNestedInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput = {
  organizationPetParentId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput = {
  organizationPetId?: Maybe<Scalars['String']>;
};

export type OrganizationPetToOrganizationPetParentUpdateInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput = {
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput = {
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetToOrganizationPetParentsInput;
  where: OrganizationPetToOrganizationPetParentScalarWhereInput;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetParentNestedInput = {
  connect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateOrConnectWithoutOrganizationPetParentInput>>;
  create?: Maybe<Array<OrganizationPetToOrganizationPetParentCreateWithoutOrganizationPetParentInput>>;
  createMany?: Maybe<OrganizationPetToOrganizationPetParentCreateManyOrganizationPetParentInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetToOrganizationPetParentScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput>>;
  updateMany?: Maybe<Array<OrganizationPetToOrganizationPetParentUpdateManyWithWhereWithoutOrganizationPetParentInput>>;
  upsert?: Maybe<Array<OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput>>;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetInput = {
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithWhereUniqueWithoutOrganizationPetParentInput = {
  data: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetInput = {
  organizationPetParent?: Maybe<OrganizationPetParentUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpdateWithoutOrganizationPetParentInput = {
  organizationPet?: Maybe<OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput>;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetInput = {
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentUpsertWithWhereUniqueWithoutOrganizationPetParentInput = {
  create: OrganizationPetToOrganizationPetParentUncheckedCreateWithoutOrganizationPetParentInput;
  update: OrganizationPetToOrganizationPetParentUncheckedUpdateWithoutOrganizationPetParentInput;
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};

export type OrganizationPetToOrganizationPetParentWhereInput = {
  AND?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  OR?: Maybe<Array<OrganizationPetToOrganizationPetParentWhereInput>>;
  organizationPet?: Maybe<OrganizationPetWhereInput>;
  organizationPetId?: Maybe<StringFilter>;
  organizationPetParent?: Maybe<OrganizationPetParentWhereInput>;
  organizationPetParentId?: Maybe<StringFilter>;
};

export type OrganizationPetToOrganizationPetParentWhereUniqueInput = {
  organizationPetId_organizationPetParentId?: Maybe<OrganizationPetToOrganizationPetParentOrganizationPetIdOrganizationPetParentIdCompoundUniqueInput>;
};

export type OrganizationPetUncheckedCreateInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput = {
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
};

export type OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutClinicPetsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput = {
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferToPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutTransferFromPetInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedCreateNestedManyWithoutRelatedOrganizationPetInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutAssociatedPetInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  clinicPets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationPetInput>;
  preferredName: Scalars['String'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutClinicPetsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput = {
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutTransferFromPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUncheckedUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUncheckedUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrganizationPetUncheckedUpdateManyWithoutOrganizationPetsInput;
  where: OrganizationPetScalarWhereInput;
};

export type OrganizationPetUpdateManyWithoutOrganizationNestedInput = {
  connect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OrganizationPetCreateOrConnectWithoutOrganizationInput>>;
  create?: Maybe<Array<OrganizationPetCreateWithoutOrganizationInput>>;
  createMany?: Maybe<OrganizationPetCreateManyOrganizationInputEnvelope>;
  delete?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OrganizationPetScalarWhereInput>>;
  disconnect?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationPetWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<OrganizationPetUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: Maybe<Array<OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationPetUpdateOneRequiredWithoutAssociatedBenefitUsageFollowupNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedBenefitUsageFollowupInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutAssociatedBenefitUsageFollowupInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutCareAddonEnrollmentsNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCareAddonEnrollmentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCareAddonEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutCarePlanEnrollmentsNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutCarePlanEnrollmentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutCarePlanEnrollmentsInput>;
};

export type OrganizationPetUpdateOneRequiredWithoutOrganizationPetToOrganizationPetParentsNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutOrganizationPetToOrganizationPetParentsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput>;
};

export type OrganizationPetUpdateOneWithoutAssociatedSubscriptionsNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutAssociatedSubscriptionsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutAssociatedSubscriptionsInput>;
};

export type OrganizationPetUpdateOneWithoutClinicPetsNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutClinicPetsInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutClinicPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutClinicPetsInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutClinicPetsInput>;
};

export type OrganizationPetUpdateOneWithoutTransferFromPetEnrollmentSupportEventNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferFromPetEnrollmentSupportEventInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutTransferFromPetEnrollmentSupportEventInput>;
};

export type OrganizationPetUpdateOneWithoutTransferToPetEnrollmentSupportEventNestedInput = {
  connect?: Maybe<OrganizationPetWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationPetCreateOrConnectWithoutTransferToPetEnrollmentSupportEventInput>;
  create?: Maybe<OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput>;
  upsert?: Maybe<OrganizationPetUpsertWithoutTransferToPetEnrollmentSupportEventInput>;
};

export type OrganizationPetUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetUpdateWithoutAssociatedBenefitUsageFollowupInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutAssociatedSubscriptionsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutCareAddonEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutCarePlanEnrollmentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutClinicPetsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutOrganizationInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutOrganizationPetToOrganizationPetParentsInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutTransferFromPetEnrollmentSupportEventInput = {
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferToPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpdateWithoutTransferToPetEnrollmentSupportEventInput = {
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventUpdateManyWithoutTransferFromPetNestedInput>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupUpdateManyWithoutRelatedOrganizationPetNestedInput>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutAssociatedPetNestedInput>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentUpdateManyWithoutOrganizationPetNestedInput>;
  clinicPets?: Maybe<ClinicPetUpdateManyWithoutOrganizationPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentUpdateManyWithoutOrganizationPetNestedInput>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationPetUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrganizationPetUncheckedCreateWithoutOrganizationInput;
  update: OrganizationPetUncheckedUpdateWithoutOrganizationInput;
  where: OrganizationPetWhereUniqueInput;
};

export type OrganizationPetUpsertWithoutAssociatedBenefitUsageFollowupInput = {
  create: OrganizationPetUncheckedCreateWithoutAssociatedBenefitUsageFollowupInput;
  update: OrganizationPetUncheckedUpdateWithoutAssociatedBenefitUsageFollowupInput;
};

export type OrganizationPetUpsertWithoutAssociatedSubscriptionsInput = {
  create: OrganizationPetUncheckedCreateWithoutAssociatedSubscriptionsInput;
  update: OrganizationPetUncheckedUpdateWithoutAssociatedSubscriptionsInput;
};

export type OrganizationPetUpsertWithoutCareAddonEnrollmentsInput = {
  create: OrganizationPetUncheckedCreateWithoutCareAddonEnrollmentsInput;
  update: OrganizationPetUncheckedUpdateWithoutCareAddonEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutCarePlanEnrollmentsInput = {
  create: OrganizationPetUncheckedCreateWithoutCarePlanEnrollmentsInput;
  update: OrganizationPetUncheckedUpdateWithoutCarePlanEnrollmentsInput;
};

export type OrganizationPetUpsertWithoutClinicPetsInput = {
  create: OrganizationPetUncheckedCreateWithoutClinicPetsInput;
  update: OrganizationPetUncheckedUpdateWithoutClinicPetsInput;
};

export type OrganizationPetUpsertWithoutOrganizationPetToOrganizationPetParentsInput = {
  create: OrganizationPetUncheckedCreateWithoutOrganizationPetToOrganizationPetParentsInput;
  update: OrganizationPetUncheckedUpdateWithoutOrganizationPetToOrganizationPetParentsInput;
};

export type OrganizationPetUpsertWithoutTransferFromPetEnrollmentSupportEventInput = {
  create: OrganizationPetUncheckedCreateWithoutTransferFromPetEnrollmentSupportEventInput;
  update: OrganizationPetUncheckedUpdateWithoutTransferFromPetEnrollmentSupportEventInput;
};

export type OrganizationPetUpsertWithoutTransferToPetEnrollmentSupportEventInput = {
  create: OrganizationPetUncheckedCreateWithoutTransferToPetEnrollmentSupportEventInput;
  update: OrganizationPetUncheckedUpdateWithoutTransferToPetEnrollmentSupportEventInput;
};

export type OrganizationPetWhereInput = {
  AND?: Maybe<Array<OrganizationPetWhereInput>>;
  NOT?: Maybe<Array<OrganizationPetWhereInput>>;
  OR?: Maybe<Array<OrganizationPetWhereInput>>;
  TransferFromPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventListRelationFilter>;
  TransferToPetEnrollmentSupportEvent?: Maybe<EnrollmentSupportEventListRelationFilter>;
  associatedBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowupListRelationFilter>;
  associatedSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  careAddonEnrollments?: Maybe<CareAddonEnrollmentListRelationFilter>;
  carePlanEnrollments?: Maybe<CarePlanEnrollmentListRelationFilter>;
  clinicPets?: Maybe<ClinicPetListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  organizationId?: Maybe<StringFilter>;
  organizationPetToOrganizationPetParents?: Maybe<OrganizationPetToOrganizationPetParentListRelationFilter>;
  preferredName?: Maybe<StringFilter>;
  profilePictureUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrganizationPetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OrganizationRelationFilter = {
  is?: Maybe<OrganizationWhereInput>;
  isNot?: Maybe<OrganizationWhereInput>;
};

export enum OrganizationScalarFieldEnum {
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  CrmId = 'crmId',
  Id = 'id',
  Name = 'name',
  PaymentProcessorClinicId = 'paymentProcessorClinicId',
  UpdatedAt = 'updatedAt'
}

export type OrganizationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OrganizationScalarWhereWithAggregatesInput>>;
  configuration?: Maybe<JsonWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  paymentProcessorClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type OrganizationUncheckedCreateInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateNestedOneWithoutPaymentProcessorClinicInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUncheckedCreateWithoutCareAddonPackageInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutCareBenefitsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutCarePlansInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutClinicsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput = {
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetParentsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutOrganizationPetsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutOrganizationInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedCreateNestedManyWithoutOrganizationInput>;
  careBenefits?: Maybe<CareBenefitUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedCreateNestedManyWithoutOrganizationInput>;
  carePlans?: Maybe<CarePlanUncheckedCreateNestedManyWithoutOrganizationInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutOrganizationInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutOrganizationInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateManyInput = {
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUncheckedUpdateWithoutCareAddonPackageInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutCareBenefitsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutCarePlansInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutClinicsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutOrganizationPetsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUncheckedUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUncheckedUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUncheckedUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUncheckedUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateManyMutationInput = {
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateOneRequiredWithoutCareBenefitsNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareBenefitsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCareBenefitsInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareBenefitsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareBenefitsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCarePlansNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlansInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlansInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCarePlansInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCarePlansInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetParentsNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetParentsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetParentsInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetParentsInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPetsNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutOrganizationPetsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutOrganizationPetsInput>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutOrganizationPetsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutOrganizationPetsInput>;
};

export type OrganizationUpdateOneWithoutCareAddonPackageNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCareAddonPackageInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCareAddonPackageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCareAddonPackageInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCareAddonPackageInput>;
};

export type OrganizationUpdateOneWithoutCarePlanProviderGroupsNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutCarePlanProviderGroupsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutCarePlanProviderGroupsInput>;
};

export type OrganizationUpdateOneWithoutClinicsNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutClinicsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutClinicsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutClinicsInput>;
};

export type OrganizationUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<OrganizationUpsertWithoutEmailCampaignTemplateInput>;
};

export type OrganizationUpdateOneWithoutPaymentProcessorClinicNestedInput = {
  connect?: Maybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutPaymentProcessorClinicInput>;
  create?: Maybe<OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPaymentProcessorClinicInput>;
};

export type OrganizationUpdateWithoutCareAddonPackageInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutCareBenefitsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutCarePlanProviderGroupsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutCarePlansInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutClinicsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutEmailCampaignTemplateInput = {
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutOrganizationPetParentsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutOrganizationPetsInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  paymentProcessorClinic?: Maybe<ClinicUpdateOneWithoutCarePayoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpdateWithoutPaymentProcessorClinicInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutOrganizationNestedInput>;
  careAddonPackage?: Maybe<CareAddonPackageUpdateManyWithoutOrganizationNestedInput>;
  careBenefits?: Maybe<CareBenefitUpdateManyWithoutOrganizationNestedInput>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupUpdateManyWithoutOrganizationNestedInput>;
  carePlans?: Maybe<CarePlanUpdateManyWithoutOrganizationNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutOrganizationNestedInput>;
  configuration?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutOrganizationNestedInput>;
  organizationPets?: Maybe<OrganizationPetUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationUpsertWithoutCareAddonPackageInput = {
  create: OrganizationUncheckedCreateWithoutCareAddonPackageInput;
  update: OrganizationUncheckedUpdateWithoutCareAddonPackageInput;
};

export type OrganizationUpsertWithoutCareBenefitsInput = {
  create: OrganizationUncheckedCreateWithoutCareBenefitsInput;
  update: OrganizationUncheckedUpdateWithoutCareBenefitsInput;
};

export type OrganizationUpsertWithoutCarePlanProviderGroupsInput = {
  create: OrganizationUncheckedCreateWithoutCarePlanProviderGroupsInput;
  update: OrganizationUncheckedUpdateWithoutCarePlanProviderGroupsInput;
};

export type OrganizationUpsertWithoutCarePlansInput = {
  create: OrganizationUncheckedCreateWithoutCarePlansInput;
  update: OrganizationUncheckedUpdateWithoutCarePlansInput;
};

export type OrganizationUpsertWithoutClinicsInput = {
  create: OrganizationUncheckedCreateWithoutClinicsInput;
  update: OrganizationUncheckedUpdateWithoutClinicsInput;
};

export type OrganizationUpsertWithoutEmailCampaignTemplateInput = {
  create: OrganizationUncheckedCreateWithoutEmailCampaignTemplateInput;
  update: OrganizationUncheckedUpdateWithoutEmailCampaignTemplateInput;
};

export type OrganizationUpsertWithoutOrganizationPetParentsInput = {
  create: OrganizationUncheckedCreateWithoutOrganizationPetParentsInput;
  update: OrganizationUncheckedUpdateWithoutOrganizationPetParentsInput;
};

export type OrganizationUpsertWithoutOrganizationPetsInput = {
  create: OrganizationUncheckedCreateWithoutOrganizationPetsInput;
  update: OrganizationUncheckedUpdateWithoutOrganizationPetsInput;
};

export type OrganizationUpsertWithoutPaymentProcessorClinicInput = {
  create: OrganizationUncheckedCreateWithoutPaymentProcessorClinicInput;
  update: OrganizationUncheckedUpdateWithoutPaymentProcessorClinicInput;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<OrganizationWhereInput>>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
  NOT?: Maybe<Array<OrganizationWhereInput>>;
  OR?: Maybe<Array<OrganizationWhereInput>>;
  careAddonPackage?: Maybe<CareAddonPackageListRelationFilter>;
  careBenefits?: Maybe<CareBenefitListRelationFilter>;
  carePlanProviderGroups?: Maybe<CarePlanProviderGroupListRelationFilter>;
  carePlans?: Maybe<CarePlanListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  configuration?: Maybe<JsonFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  crmId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  organizationPets?: Maybe<OrganizationPetListRelationFilter>;
  paymentProcessorClinic?: Maybe<ClinicWhereInput>;
  paymentProcessorClinicId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OrganizationWhereUniqueInput = {
  crmId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProcessorClinicId?: Maybe<Scalars['String']>;
};

/** @deprecated - 1.0 */
export type OwnerInfo = {
  __typename?: 'OwnerInfo';
  _count: OwnerInfoCountOutputType;
  consultations: Array<Consultation>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  pets: Array<Pet>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


/** @deprecated - 1.0 */
export type OwnerInfoConsultationsArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


/** @deprecated - 1.0 */
export type OwnerInfoPetsArgs = {
  cursor?: Maybe<PetWhereUniqueInput>;
  distinct?: Maybe<PetScalarFieldEnum>;
  orderBy?: Maybe<PetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetWhereInput>;
};

export type OwnerInfoCountAggregateOutputType = {
  __typename?: 'OwnerInfoCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  customerId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OwnerInfoCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OwnerInfoCountOutputType = {
  __typename?: 'OwnerInfoCountOutputType';
  consultations: Scalars['Int'];
  pets: Scalars['Int'];
};

export type OwnerInfoCreateInput = {
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoCreateNestedOneWithoutConsultationsInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
};

export type OwnerInfoCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
};

export type OwnerInfoCreateNestedOneWithoutUserInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
};

export type OwnerInfoCreateOrConnectWithoutConsultationsInput = {
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
  where: OwnerInfoWhereUniqueInput;
};

export type OwnerInfoCreateOrConnectWithoutPetsInput = {
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
  where: OwnerInfoWhereUniqueInput;
};

export type OwnerInfoCreateOrConnectWithoutUserInput = {
  create: OwnerInfoUncheckedCreateWithoutUserInput;
  where: OwnerInfoWhereUniqueInput;
};

export type OwnerInfoCreateWithoutConsultationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutPetsInput = {
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoCreateWithoutUserInput = {
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoMaxAggregateOutputType = {
  __typename?: 'OwnerInfoMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OwnerInfoMinAggregateOutputType = {
  __typename?: 'OwnerInfoMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum OwnerInfoOrderByRelevanceFieldEnum {
  CustomerId = 'customerId',
  FirebaseId = 'firebaseId',
  Id = 'id'
}

export type OwnerInfoOrderByRelevanceInput = {
  fields: Array<OwnerInfoOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type OwnerInfoOrderByWithAggregationInput = {
  _count?: Maybe<OwnerInfoCountOrderByAggregateInput>;
  _max?: Maybe<OwnerInfoMaxOrderByAggregateInput>;
  _min?: Maybe<OwnerInfoMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OwnerInfoOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<OwnerInfoOrderByRelevanceInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pets?: Maybe<PetOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
};

export type OwnerInfoRelationFilter = {
  is?: Maybe<OwnerInfoWhereInput>;
  isNot?: Maybe<OwnerInfoWhereInput>;
};

export enum OwnerInfoScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  FirebaseId = 'firebaseId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type OwnerInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OwnerInfoScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  customerId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type OwnerInfoUncheckedCreateInput = {
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutConsultationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutPetsInput = {
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutOwnerInfoInput>;
};

export type OwnerInfoUncheckedCreateWithoutUserInput = {
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetParentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedCreateNestedManyWithoutPetParentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoUncheckedUpdateInput = {
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoUncheckedUpdateWithoutConsultationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateWithoutPetsInput = {
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUncheckedUpdateWithoutUserInput = {
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUncheckedUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoUpdateInput = {
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoUpdateOneWithoutConsultationsNestedInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutConsultationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutConsultationsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutConsultationsInput>;
};

export type OwnerInfoUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutPetsInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutPetsInput>;
};

export type OwnerInfoUpdateOneWithoutUserNestedInput = {
  connect?: Maybe<OwnerInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<OwnerInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<OwnerInfoUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OwnerInfoUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<OwnerInfoUpsertWithoutUserInput>;
};

export type OwnerInfoUpdateWithoutConsultationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateWithoutPetsInput = {
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutOwnerInfoNestedInput>;
};

export type OwnerInfoUpdateWithoutUserInput = {
  consultations?: Maybe<ConsultationUpdateManyWithoutPetParentNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pets?: Maybe<PetUpdateManyWithoutPetParentNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OwnerInfoUpsertWithoutConsultationsInput = {
  create: OwnerInfoUncheckedCreateWithoutConsultationsInput;
  update: OwnerInfoUncheckedUpdateWithoutConsultationsInput;
};

export type OwnerInfoUpsertWithoutPetsInput = {
  create: OwnerInfoUncheckedCreateWithoutPetsInput;
  update: OwnerInfoUncheckedUpdateWithoutPetsInput;
};

export type OwnerInfoUpsertWithoutUserInput = {
  create: OwnerInfoUncheckedCreateWithoutUserInput;
  update: OwnerInfoUncheckedUpdateWithoutUserInput;
};

export type OwnerInfoWhereInput = {
  AND?: Maybe<Array<OwnerInfoWhereInput>>;
  NOT?: Maybe<Array<OwnerInfoWhereInput>>;
  OR?: Maybe<Array<OwnerInfoWhereInput>>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customerId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  pets?: Maybe<PetListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
};

export type OwnerInfoWhereUniqueInput = {
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PpcIntegration = {
  __typename?: 'PPCIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PpcIntegrationCountAggregateOutputType = {
  __typename?: 'PPCIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  ppcClinicId: Scalars['Int'];
  ppcLocationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PpcIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PpcIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutPpcInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: PpcIntegrationWhereUniqueInput;
};

export type PpcIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationMaxAggregateOutputType = {
  __typename?: 'PPCIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PpcIntegrationMinAggregateOutputType = {
  __typename?: 'PPCIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PpcIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PpcClinicId = 'ppcClinicId',
  PpcLocationId = 'ppcLocationId'
}

export type PpcIntegrationOrderByRelevanceInput = {
  fields: Array<PpcIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PpcIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<PpcIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<PpcIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<PpcIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PpcIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PpcIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ppcClinicId?: Maybe<SortOrder>;
  ppcLocationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PpcIntegrationRelationFilter = {
  is?: Maybe<PpcIntegrationWhereInput>;
  isNot?: Maybe<PpcIntegrationWhereInput>;
};

export enum PpcIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PpcClinicId = 'ppcClinicId',
  PpcLocationId = 'ppcLocationId',
  UpdatedAt = 'updatedAt'
}

export type PpcIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PpcIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  ppcClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  ppcLocationId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PpcIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutPpcInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutPpcNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutPpcNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<PpcIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<PpcIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<PpcIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type PpcIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ppcClinicId?: Maybe<Scalars['String']>;
  ppcLocationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PpcIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: PpcIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: PpcIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type PpcIntegrationWhereInput = {
  AND?: Maybe<Array<PpcIntegrationWhereInput>>;
  NOT?: Maybe<Array<PpcIntegrationWhereInput>>;
  OR?: Maybe<Array<PpcIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  ppcClinicId?: Maybe<StringNullableFilter>;
  ppcLocationId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PpcIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PaymentIntentInput = {
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  create: StripeCreatePaymentInput;
  emailOverride?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']>>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
};

export enum PaymentMedium {
  StripeOnline = 'StripeOnline',
  StripeTerminal = 'StripeTerminal',
  StripeTerminalApp = 'StripeTerminalApp',
  StripeVirtualTerminal = 'StripeVirtualTerminal'
}

export enum PaymentPimsWritebackAction {
  CareDiscountApplied = 'Care_Discount_Applied',
  PaymentCollected = 'Payment_Collected'
}

export type PaymentPimsWritebackLogEntry = {
  __typename?: 'PaymentPimsWritebackLogEntry';
  action: PaymentPimsWritebackAction;
  payment: StripePaymentIntent;
  paymentId: Scalars['String'];
  writebackLog: PimsWritebackLogEntry;
  writebackLogId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  paymentId: Scalars['Int'];
  writebackLogId: Scalars['Int'];
};

export type PaymentPimsWritebackLogEntryCountOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryCreateInput = {
  action: PaymentPimsWritebackAction;
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
};

export type PaymentPimsWritebackLogEntryCreateManyInput = {
  action: PaymentPimsWritebackAction;
  paymentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInput = {
  action: PaymentPimsWritebackAction;
  writebackLogId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope = {
  data: Array<PaymentPimsWritebackLogEntryCreateManyPaymentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput = {
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
};

export type PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput = {
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
};

export type PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput = {
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
};

export type PaymentPimsWritebackLogEntryCreateWithoutPaymentInput = {
  action: PaymentPimsWritebackAction;
  writebackLog: PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput;
};

export type PaymentPimsWritebackLogEntryCreateWithoutWritebackLogInput = {
  action: PaymentPimsWritebackAction;
  payment: StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput;
};

export type PaymentPimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  none?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  some?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export type PaymentPimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMaxAggregateOutputType';
  action?: Maybe<PaymentPimsWritebackAction>;
  paymentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryMaxOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PaymentPimsWritebackLogEntryMinAggregateOutputType';
  action?: Maybe<PaymentPimsWritebackAction>;
  paymentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryMinOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum {
  PaymentId = 'paymentId',
  WritebackLogId = 'writebackLogId'
}

export type PaymentPimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PaymentPimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PaymentPimsWritebackLogEntryOrderByWithAggregationInput = {
  _count?: Maybe<PaymentPimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<PaymentPimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PaymentPimsWritebackLogEntryMinOrderByAggregateInput>;
  action?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  writebackLogId?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PaymentPimsWritebackLogEntryOrderByRelevanceInput>;
  action?: Maybe<SortOrder>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  paymentId?: Maybe<SortOrder>;
  writebackLog?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  writebackLogId?: Maybe<SortOrder>;
};

export type PaymentPimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export enum PaymentPimsWritebackLogEntryScalarFieldEnum {
  Action = 'action',
  PaymentId = 'paymentId',
  WritebackLogId = 'writebackLogId'
}

export type PaymentPimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
  paymentId?: Maybe<StringFilter>;
  writebackLogId?: Maybe<StringFilter>;
};

export type PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  action?: Maybe<EnumPaymentPimsWritebackActionWithAggregatesFilter>;
  paymentId?: Maybe<StringWithAggregatesFilter>;
  writebackLogId?: Maybe<StringWithAggregatesFilter>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateInput = {
  action: PaymentPimsWritebackAction;
  paymentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput = {
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput = {
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput = {
  action: PaymentPimsWritebackAction;
  writebackLogId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput = {
  action: PaymentPimsWritebackAction;
  paymentId: Scalars['String'];
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  paymentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  paymentId?: Maybe<Scalars['String']>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput = {
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  writebackLogId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  paymentId?: Maybe<Scalars['String']>;
};

export type PaymentPimsWritebackLogEntryUpdateInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpdateManyMutationInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput = {
  data: PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
  where: PaymentPimsWritebackLogEntryScalarWhereInput;
};

export type PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput = {
  connect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentPimsWritebackLogEntryCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentPimsWritebackLogEntryCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentPimsWritebackLogEntryCreateManyPaymentInputEnvelope>;
  delete?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentPimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PaymentPimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentPimsWritebackLogEntryUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput = {
  connect?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentPimsWritebackLogEntryCreateOrConnectWithoutWritebackLogInput>;
  create?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput>;
  upsert?: Maybe<PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput>;
};

export type PaymentPimsWritebackLogEntryUpdateWithWhereUniqueWithoutPaymentInput = {
  data: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutPaymentInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  writebackLog?: Maybe<PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpdateWithoutWritebackLogInput = {
  action?: Maybe<PaymentPimsWritebackAction>;
  payment?: Maybe<StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
};

export type PaymentPimsWritebackLogEntryUpsertWithWhereUniqueWithoutPaymentInput = {
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutPaymentInput;
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutPaymentInput;
  where: PaymentPimsWritebackLogEntryWhereUniqueInput;
};

export type PaymentPimsWritebackLogEntryUpsertWithoutWritebackLogInput = {
  create: PaymentPimsWritebackLogEntryUncheckedCreateWithoutWritebackLogInput;
  update: PaymentPimsWritebackLogEntryUncheckedUpdateWithoutWritebackLogInput;
};

export type PaymentPimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PaymentPimsWritebackLogEntryWhereInput>>;
  action?: Maybe<EnumPaymentPimsWritebackActionFilter>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
  paymentId?: Maybe<StringFilter>;
  writebackLog?: Maybe<PimsWritebackLogEntryWhereInput>;
  writebackLogId?: Maybe<StringFilter>;
};

export type PaymentPimsWritebackLogEntryWhereUniqueInput = {
  writebackLogId?: Maybe<Scalars['String']>;
  writebackLogId_paymentId?: Maybe<PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput>;
};

export type PaymentPimsWritebackLogEntryWritebackLogIdPaymentIdCompoundUniqueInput = {
  paymentId: Scalars['String'];
  writebackLogId: Scalars['String'];
};

export enum PaymentRelationship {
  AccountBalance = 'AccountBalance',
  CustomAmount = 'CustomAmount'
}

export enum PaymentSchedule {
  Full = 'Full',
  OverTime = 'OverTime'
}

export enum PaymentSource {
  Braintree = 'Braintree',
  Stripe = 'Stripe'
}

export type PaymentsMade = {
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  total: Scalars['Float'];
};

export type PayoutBatchingPeriod = {
  __typename?: 'PayoutBatchingPeriod';
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayout>;
  updatedAt: Scalars['DateTime'];
};

export type PayoutBatchingPeriodClinicIdStartsAtEndsAtCompoundUniqueInput = {
  clinicId: Scalars['String'];
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
};

export type PayoutBatchingPeriodCountAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdByUserId: Scalars['Int'];
  endsAt: Scalars['Int'];
  id: Scalars['Int'];
  startsAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PayoutBatchingPeriodCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodCreateInput = {
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateManyClinicInputEnvelope = {
  data: Array<PayoutBatchingPeriodCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayoutBatchingPeriodCreateManyCreatedByUserInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope = {
  data: Array<PayoutBatchingPeriodCreateManyCreatedByUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayoutBatchingPeriodCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
};

export type PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
};

export type PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput = {
  connect?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput>;
  create?: Maybe<PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput>;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutClinicInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutClinicInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateWithoutCreatedByUserInput = {
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodCreateWithoutStripePayoutInput = {
  clinic: ClinicCreateNestedOneWithoutPayoutBatchingPeriodsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodListRelationFilter = {
  every?: Maybe<PayoutBatchingPeriodWhereInput>;
  none?: Maybe<PayoutBatchingPeriodWhereInput>;
  some?: Maybe<PayoutBatchingPeriodWhereInput>;
};

export type PayoutBatchingPeriodMaxAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodMinAggregateOutputType = {
  __typename?: 'PayoutBatchingPeriodMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PayoutBatchingPeriodOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  CreatedByUserId = 'createdByUserId',
  Id = 'id'
}

export type PayoutBatchingPeriodOrderByRelevanceInput = {
  fields: Array<PayoutBatchingPeriodOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PayoutBatchingPeriodOrderByWithAggregationInput = {
  _count?: Maybe<PayoutBatchingPeriodCountOrderByAggregateInput>;
  _max?: Maybe<PayoutBatchingPeriodMaxOrderByAggregateInput>;
  _min?: Maybe<PayoutBatchingPeriodMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUserId?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PayoutBatchingPeriodOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdByUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdByUserId?: Maybe<SortOrder>;
  endsAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  startsAt?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutBatchingPeriodRelationFilter = {
  is?: Maybe<PayoutBatchingPeriodWhereInput>;
  isNot?: Maybe<PayoutBatchingPeriodWhereInput>;
};

export enum PayoutBatchingPeriodScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  EndsAt = 'endsAt',
  Id = 'id',
  StartsAt = 'startsAt',
  UpdatedAt = 'updatedAt'
}

export type PayoutBatchingPeriodScalarWhereInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUserId?: Maybe<StringNullableFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PayoutBatchingPeriodScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  createdByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  endsAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  startsAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PayoutBatchingPeriodUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
};

export type PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  stripePayout?: Maybe<StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedPayoutBatchingPeriodsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateManyWithoutPayoutBatchingPeriodsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput = {
  data: PayoutBatchingPeriodUncheckedUpdateManyWithoutPayoutBatchingPeriodsInput;
  where: PayoutBatchingPeriodScalarWhereInput;
};

export type PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput = {
  data: PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedPayoutBatchingPeriodsInput;
  where: PayoutBatchingPeriodScalarWhereInput;
};

export type PayoutBatchingPeriodUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutClinicInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput = {
  connect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayoutBatchingPeriodCreateOrConnectWithoutCreatedByUserInput>>;
  create?: Maybe<Array<PayoutBatchingPeriodCreateWithoutCreatedByUserInput>>;
  createMany?: Maybe<PayoutBatchingPeriodCreateManyCreatedByUserInputEnvelope>;
  delete?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayoutBatchingPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  set?: Maybe<Array<PayoutBatchingPeriodWhereUniqueInput>>;
  update?: Maybe<Array<PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput>>;
  updateMany?: Maybe<Array<PayoutBatchingPeriodUpdateManyWithWhereWithoutCreatedByUserInput>>;
  upsert?: Maybe<Array<PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput>>;
};

export type PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput = {
  connect?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  connectOrCreate?: Maybe<PayoutBatchingPeriodCreateOrConnectWithoutStripePayoutInput>;
  create?: Maybe<PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput>;
  upsert?: Maybe<PayoutBatchingPeriodUpsertWithoutStripePayoutInput>;
};

export type PayoutBatchingPeriodUpdateWithWhereUniqueWithoutClinicInput = {
  data: PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodUpdateWithWhereUniqueWithoutCreatedByUserInput = {
  data: PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateWithoutCreatedByUserInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpdateWithoutStripePayoutInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPayoutBatchingPeriodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUser?: Maybe<UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayoutBatchingPeriodUpsertWithWhereUniqueWithoutClinicInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutClinicInput;
  update: PayoutBatchingPeriodUncheckedUpdateWithoutClinicInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodUpsertWithWhereUniqueWithoutCreatedByUserInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutCreatedByUserInput;
  update: PayoutBatchingPeriodUncheckedUpdateWithoutCreatedByUserInput;
  where: PayoutBatchingPeriodWhereUniqueInput;
};

export type PayoutBatchingPeriodUpsertWithoutStripePayoutInput = {
  create: PayoutBatchingPeriodUncheckedCreateWithoutStripePayoutInput;
  update: PayoutBatchingPeriodUncheckedUpdateWithoutStripePayoutInput;
};

export type PayoutBatchingPeriodWhereInput = {
  AND?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  NOT?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  OR?: Maybe<Array<PayoutBatchingPeriodWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdByUser?: Maybe<UserWhereInput>;
  createdByUserId?: Maybe<StringNullableFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PayoutBatchingPeriodWhereUniqueInput = {
  clinicId_startsAt_endsAt?: Maybe<PayoutBatchingPeriodClinicIdStartsAtEndsAtCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type PerformCarePlanEnrollmentUpgradeInput = {
  carePlanEnrollmentId: Scalars['String'];
  feeAmount: Scalars['Float'];
  newPlanId: Scalars['String'];
  relatedInvoiceId?: Maybe<Scalars['String']>;
};

export type PerformCarePlanEnrollmentUpgradeOutput = {
  __typename?: 'PerformCarePlanEnrollmentUpgradeOutput';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  upgradeInvoiceId?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  _count: PermissionCountOutputType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  roles: Array<StaffRole>;
  type: PermissionType;
  updatedAt: Scalars['DateTime'];
};


export type PermissionRolesArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};

export type PermissionCountAggregateOutputType = {
  __typename?: 'PermissionCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PermissionCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionCountOutputType = {
  __typename?: 'PermissionCountOutputType';
  roles: Scalars['Int'];
};

export type PermissionCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutPermissionsInput>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionCreateNestedManyWithoutRolesInput = {
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
};

export type PermissionCreateOrConnectWithoutRolesInput = {
  create: PermissionUncheckedCreateWithoutRolesInput;
  where: PermissionWhereUniqueInput;
};

export type PermissionCreateWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionListRelationFilter = {
  every?: Maybe<PermissionWhereInput>;
  none?: Maybe<PermissionWhereInput>;
  some?: Maybe<PermissionWhereInput>;
};

export type PermissionMaxAggregateOutputType = {
  __typename?: 'PermissionMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionMinAggregateOutputType = {
  __typename?: 'PermissionMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PermissionOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type PermissionOrderByRelevanceInput = {
  fields: Array<PermissionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PermissionOrderByWithAggregationInput = {
  _count?: Maybe<PermissionCountOrderByAggregateInput>;
  _max?: Maybe<PermissionMaxOrderByAggregateInput>;
  _min?: Maybe<PermissionMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PermissionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PermissionOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PermissionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PermissionScalarWhereInput = {
  AND?: Maybe<Array<PermissionScalarWhereInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereInput>>;
  OR?: Maybe<Array<PermissionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PermissionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PermissionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumPermissionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PermissionType {
  ChangeClinicPricing = 'ChangeClinicPricing',
  ManageChannelMessage = 'ManageChannelMessage',
  ReadAppointment = 'ReadAppointment',
  ReadChannel = 'ReadChannel',
  ReadClinicPetParent = 'ReadClinicPetParent',
  ReadConsultation = 'ReadConsultation',
  ReadOrganization = 'ReadOrganization',
  ReadPet = 'ReadPet',
  ReadUser = 'ReadUser',
  WriteAppointment = 'WriteAppointment',
  WriteChannel = 'WriteChannel',
  WriteClinic = 'WriteClinic',
  WriteClinicPetParent = 'WriteClinicPetParent',
  WriteConsultation = 'WriteConsultation',
  WriteOrganization = 'WriteOrganization',
  WritePet = 'WritePet',
  WriteUser = 'WriteUser'
}

export type PermissionUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedCreateNestedManyWithoutRolesInput = {
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
};

export type PermissionUncheckedCreateWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type: PermissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutPermissionsNestedInput>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutPermissionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUncheckedUpdateManyWithoutRolesNestedInput = {
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type PermissionUncheckedUpdateWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutPermissionsNestedInput>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpdateManyWithWhereWithoutRolesInput = {
  data: PermissionUncheckedUpdateManyWithoutPermissionsInput;
  where: PermissionScalarWhereInput;
};

export type PermissionUpdateManyWithoutRolesNestedInput = {
  connect?: Maybe<Array<PermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PermissionCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<PermissionCreateWithoutRolesInput>>;
  delete?: Maybe<Array<PermissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PermissionScalarWhereInput>>;
  disconnect?: Maybe<Array<PermissionWhereUniqueInput>>;
  set?: Maybe<Array<PermissionWhereUniqueInput>>;
  update?: Maybe<Array<PermissionUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<PermissionUpdateManyWithWhereWithoutRolesInput>>;
  upsert?: Maybe<Array<PermissionUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type PermissionUpdateWithWhereUniqueWithoutRolesInput = {
  data: PermissionUncheckedUpdateWithoutRolesInput;
  where: PermissionWhereUniqueInput;
};

export type PermissionUpdateWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionUpsertWithWhereUniqueWithoutRolesInput = {
  create: PermissionUncheckedCreateWithoutRolesInput;
  update: PermissionUncheckedUpdateWithoutRolesInput;
  where: PermissionWhereUniqueInput;
};

export type PermissionWhereInput = {
  AND?: Maybe<Array<PermissionWhereInput>>;
  NOT?: Maybe<Array<PermissionWhereInput>>;
  OR?: Maybe<Array<PermissionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
  type?: Maybe<EnumPermissionTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PermissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PermissionType>;
};

/** @deprecated - 1.0 */
export type Pet = {
  __typename?: 'Pet';
  _count: PetCountOutputType;
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations: Array<Consultation>;
  createdAt: Scalars['DateTime'];
  currentClinic?: Maybe<Clinic>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  petParent?: Maybe<OwnerInfo>;
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics: Array<Clinic>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Float'];
};


/** @deprecated - 1.0 */
export type PetConsultationsArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


/** @deprecated - 1.0 */
export type PetPreviousClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};

export type PetAvgAggregateOutputType = {
  __typename?: 'PetAvgAggregateOutputType';
  bornOnDay?: Maybe<Scalars['Float']>;
  bornOnMonth?: Maybe<Scalars['Float']>;
  bornOnYear?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetAvgOrderByAggregateInput = {
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetBenefitUsage = {
  __typename?: 'PetBenefitUsage';
  organizationPetId: Scalars['String'];
  usages: Array<UsageByBenefit>;
};

export type PetCountAggregateOutputType = {
  __typename?: 'PetCountAggregateOutputType';
  _all: Scalars['Int'];
  bornOn: Scalars['Int'];
  bornOnDay: Scalars['Int'];
  bornOnMonth: Scalars['Int'];
  bornOnYear: Scalars['Int'];
  breed: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  environment: Scalars['Int'];
  firebaseId: Scalars['Int'];
  gender: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  petParentId: Scalars['Int'];
  petType: Scalars['Int'];
  photo: Scalars['Int'];
  pimsId: Scalars['Int'];
  repoStatus: Scalars['Int'];
  updatedAt: Scalars['Int'];
  weight: Scalars['Int'];
};

export type PetCountOrderByAggregateInput = {
  bornOn?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetCountOutputType = {
  __typename?: 'PetCountOutputType';
  consultations: Scalars['Int'];
  previousClinics: Scalars['Int'];
};

export type PetCreateInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateManyCurrentClinicInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateManyCurrentClinicInputEnvelope = {
  data: Array<PetCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateManyInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateManyPetParentInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateManyPetParentInputEnvelope = {
  data: Array<PetCreateManyPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PetCreateNestedManyWithoutCurrentClinicInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
};

export type PetCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
};

export type PetCreateNestedManyWithoutPreviousClinicsInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
};

export type PetCreateNestedOneWithoutConsultationsInput = {
  connect?: Maybe<PetWhereUniqueInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
};

export type PetCreateOrConnectWithoutConsultationsInput = {
  create: PetUncheckedCreateWithoutConsultationsInput;
  where: PetWhereUniqueInput;
};

export type PetCreateOrConnectWithoutCurrentClinicInput = {
  create: PetUncheckedCreateWithoutCurrentClinicInput;
  where: PetWhereUniqueInput;
};

export type PetCreateOrConnectWithoutPetParentInput = {
  create: PetUncheckedCreateWithoutPetParentInput;
  where: PetWhereUniqueInput;
};

export type PetCreateOrConnectWithoutPreviousClinicsInput = {
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
  where: PetWhereUniqueInput;
};

export type PetCreateWithoutConsultationsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateWithoutCurrentClinicInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateWithoutPetParentInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetCreateWithoutPreviousClinicsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutPetsIdInput>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParent?: Maybe<OwnerInfoCreateNestedOneWithoutPetsInput>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetIdHistoryItem = {
  __typename?: 'PetIdHistoryItem';
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
};

export type PetListRelationFilter = {
  every?: Maybe<PetWhereInput>;
  none?: Maybe<PetWhereInput>;
  some?: Maybe<PetWhereInput>;
};

export type PetMaxAggregateOutputType = {
  __typename?: 'PetMaxAggregateOutputType';
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetMaxOrderByAggregateInput = {
  bornOn?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetMinAggregateOutputType = {
  __typename?: 'PetMinAggregateOutputType';
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetMinOrderByAggregateInput = {
  bornOn?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PetOrderByRelevanceFieldEnum {
  Breed = 'breed',
  ClinicId = 'clinicId',
  Environment = 'environment',
  FirebaseId = 'firebaseId',
  Gender = 'gender',
  Id = 'id',
  Name = 'name',
  PetParentId = 'petParentId',
  PetType = 'petType',
  Photo = 'photo',
  PimsId = 'pimsId',
  RepoStatus = 'repoStatus'
}

export type PetOrderByRelevanceInput = {
  fields: Array<PetOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PetOrderByWithAggregationInput = {
  _avg?: Maybe<PetAvgOrderByAggregateInput>;
  _count?: Maybe<PetCountOrderByAggregateInput>;
  _max?: Maybe<PetMaxOrderByAggregateInput>;
  _min?: Maybe<PetMinOrderByAggregateInput>;
  _sum?: Maybe<PetSumOrderByAggregateInput>;
  bornOn?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  environment?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  repoStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PetOrderByRelevanceInput>;
  bornOn?: Maybe<SortOrder>;
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  breed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  environment?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  gender?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  petParent?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  petParentId?: Maybe<SortOrder>;
  petType?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  previousClinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  repoStatus?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetParentSetting = {
  __typename?: 'PetParentSetting';
  _count: PetParentSettingCountOutputType;
  createdAt: Scalars['DateTime'];
  emailNotifications: Scalars['Boolean'];
  emailOptedOutByPetParent: Scalars['Boolean'];
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isSubscribedToReminders: Scalars['Boolean'];
  petParent: Array<ClinicPetParent>;
  pushNotifications: Scalars['Boolean'];
  pushOptedOutByPetParent: Scalars['Boolean'];
  sendSurvey: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
  smsOptedOutByPetParent: Scalars['Boolean'];
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};


export type PetParentSettingPetParentArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};

export type PetParentSettingCountAggregateOutputType = {
  __typename?: 'PetParentSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  emailOptedOutByPetParent: Scalars['Int'];
  emailOptedOutByPetParentAt: Scalars['Int'];
  id: Scalars['Int'];
  isSubscribedToReminders: Scalars['Int'];
  pushNotifications: Scalars['Int'];
  pushOptedOutByPetParent: Scalars['Int'];
  sendSurvey: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  smsOptedOutByPetParent: Scalars['Int'];
  smsOptedOutByPetParentAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PetParentSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetParentSettingCountOutputType = {
  __typename?: 'PetParentSettingCountOutputType';
  petParent: Scalars['Int'];
};

export type PetParentSettingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentCreateNestedManyWithoutPetParentSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingCreateNestedOneWithoutPetParentInput = {
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
};

export type PetParentSettingCreateOrConnectWithoutPetParentInput = {
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
  where: PetParentSettingWhereUniqueInput;
};

export type PetParentSettingCreateWithoutPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingMaxAggregateOutputType = {
  __typename?: 'PetParentSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetParentSettingMinAggregateOutputType = {
  __typename?: 'PetParentSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PetParentSettingOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type PetParentSettingOrderByRelevanceInput = {
  fields: Array<PetParentSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PetParentSettingOrderByWithAggregationInput = {
  _count?: Maybe<PetParentSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetParentSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetParentSettingMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetParentSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PetParentSettingOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  emailOptedOutByPetParent?: Maybe<SortOrder>;
  emailOptedOutByPetParentAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isSubscribedToReminders?: Maybe<SortOrder>;
  petParent?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  pushNotifications?: Maybe<SortOrder>;
  pushOptedOutByPetParent?: Maybe<SortOrder>;
  sendSurvey?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  smsOptedOutByPetParent?: Maybe<SortOrder>;
  smsOptedOutByPetParentAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetParentSettingRelationFilter = {
  is?: Maybe<PetParentSettingWhereInput>;
  isNot?: Maybe<PetParentSettingWhereInput>;
};

export enum PetParentSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  EmailNotifications = 'emailNotifications',
  EmailOptedOutByPetParent = 'emailOptedOutByPetParent',
  EmailOptedOutByPetParentAt = 'emailOptedOutByPetParentAt',
  Id = 'id',
  IsSubscribedToReminders = 'isSubscribedToReminders',
  PushNotifications = 'pushNotifications',
  PushOptedOutByPetParent = 'pushOptedOutByPetParent',
  SendSurvey = 'sendSurvey',
  SmsNotifications = 'smsNotifications',
  SmsOptedOutByPetParent = 'smsOptedOutByPetParent',
  SmsOptedOutByPetParentAt = 'smsOptedOutByPetParentAt',
  UpdatedAt = 'updatedAt'
}

export type PetParentSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetParentSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  emailOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  emailOptedOutByPetParentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isSubscribedToReminders?: Maybe<BoolWithAggregatesFilter>;
  pushNotifications?: Maybe<BoolWithAggregatesFilter>;
  pushOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  sendSurvey?: Maybe<BoolWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  smsOptedOutByPetParent?: Maybe<BoolWithAggregatesFilter>;
  smsOptedOutByPetParentAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PetParentSettingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutPetParentSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUncheckedCreateWithoutPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutPetParentSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUncheckedUpdateWithoutPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  petParent?: Maybe<ClinicPetParentUpdateManyWithoutPetParentSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUpdateOneWithoutPetParentNestedInput = {
  connect?: Maybe<PetParentSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetParentSettingCreateOrConnectWithoutPetParentInput>;
  create?: Maybe<PetParentSettingUncheckedCreateWithoutPetParentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PetParentSettingUncheckedUpdateWithoutPetParentInput>;
  upsert?: Maybe<PetParentSettingUpsertWithoutPetParentInput>;
};

export type PetParentSettingUpdateWithoutPetParentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  emailOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isSubscribedToReminders?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  pushOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  sendSurvey?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParent?: Maybe<Scalars['Boolean']>;
  smsOptedOutByPetParentAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetParentSettingUpsertWithoutPetParentInput = {
  create: PetParentSettingUncheckedCreateWithoutPetParentInput;
  update: PetParentSettingUncheckedUpdateWithoutPetParentInput;
};

export type PetParentSettingWhereInput = {
  AND?: Maybe<Array<PetParentSettingWhereInput>>;
  NOT?: Maybe<Array<PetParentSettingWhereInput>>;
  OR?: Maybe<Array<PetParentSettingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  emailOptedOutByPetParent?: Maybe<BoolFilter>;
  emailOptedOutByPetParentAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  isSubscribedToReminders?: Maybe<BoolFilter>;
  petParent?: Maybe<ClinicPetParentListRelationFilter>;
  pushNotifications?: Maybe<BoolFilter>;
  pushOptedOutByPetParent?: Maybe<BoolFilter>;
  sendSurvey?: Maybe<BoolFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  smsOptedOutByPetParent?: Maybe<BoolFilter>;
  smsOptedOutByPetParentAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PetParentSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PetPortalSetting = {
  __typename?: 'PetPortalSetting';
  clinicSetting: ClinicSetting;
  clinicSettingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  showAppointments: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type PetPortalSettingCountAggregateOutputType = {
  __typename?: 'PetPortalSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicSettingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  showAppointments: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PetPortalSettingCountOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetPortalSettingCreateInput = {
  clinicSetting: ClinicSettingCreateNestedOneWithoutPetPortalSettingInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingCreateManyInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
};

export type PetPortalSettingCreateOrConnectWithoutClinicSettingInput = {
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
  where: PetPortalSettingWhereUniqueInput;
};

export type PetPortalSettingCreateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingMaxAggregateOutputType = {
  __typename?: 'PetPortalSettingMaxAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingMaxOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetPortalSettingMinAggregateOutputType = {
  __typename?: 'PetPortalSettingMinAggregateOutputType';
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingMinOrderByAggregateInput = {
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PetPortalSettingOrderByRelevanceFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  Id = 'id'
}

export type PetPortalSettingOrderByRelevanceInput = {
  fields: Array<PetPortalSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PetPortalSettingOrderByWithAggregationInput = {
  _count?: Maybe<PetPortalSettingCountOrderByAggregateInput>;
  _max?: Maybe<PetPortalSettingMaxOrderByAggregateInput>;
  _min?: Maybe<PetPortalSettingMinOrderByAggregateInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetPortalSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PetPortalSettingOrderByRelevanceInput>;
  clinicSetting?: Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>;
  clinicSettingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  showAppointments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PetPortalSettingRelationFilter = {
  is?: Maybe<PetPortalSettingWhereInput>;
  isNot?: Maybe<PetPortalSettingWhereInput>;
};

export enum PetPortalSettingScalarFieldEnum {
  ClinicSettingId = 'clinicSettingId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ShowAppointments = 'showAppointments',
  UpdatedAt = 'updatedAt'
}

export type PetPortalSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetPortalSettingScalarWhereWithAggregatesInput>>;
  clinicSettingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  showAppointments?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PetPortalSettingUncheckedCreateInput = {
  clinicSettingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUncheckedCreateNestedOneWithoutClinicSettingInput = {
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
};

export type PetPortalSettingUncheckedCreateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUncheckedUpdateInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUncheckedUpdateManyInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUncheckedUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
};

export type PetPortalSettingUncheckedUpdateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUpdateInput = {
  clinicSetting?: Maybe<ClinicSettingUpdateOneRequiredWithoutPetPortalSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUpdateOneWithoutClinicSettingNestedInput = {
  connect?: Maybe<PetPortalSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<PetPortalSettingCreateOrConnectWithoutClinicSettingInput>;
  create?: Maybe<PetPortalSettingUncheckedCreateWithoutClinicSettingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PetPortalSettingUncheckedUpdateWithoutClinicSettingInput>;
  upsert?: Maybe<PetPortalSettingUpsertWithoutClinicSettingInput>;
};

export type PetPortalSettingUpdateWithoutClinicSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  showAppointments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PetPortalSettingUpsertWithoutClinicSettingInput = {
  create: PetPortalSettingUncheckedCreateWithoutClinicSettingInput;
  update: PetPortalSettingUncheckedUpdateWithoutClinicSettingInput;
};

export type PetPortalSettingWhereInput = {
  AND?: Maybe<Array<PetPortalSettingWhereInput>>;
  NOT?: Maybe<Array<PetPortalSettingWhereInput>>;
  OR?: Maybe<Array<PetPortalSettingWhereInput>>;
  clinicSetting?: Maybe<ClinicSettingWhereInput>;
  clinicSettingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  showAppointments?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PetPortalSettingWhereUniqueInput = {
  clinicSettingId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PetRelationFilter = {
  is?: Maybe<PetWhereInput>;
  isNot?: Maybe<PetWhereInput>;
};

export enum PetScalarFieldEnum {
  BornOn = 'bornOn',
  BornOnDay = 'bornOnDay',
  BornOnMonth = 'bornOnMonth',
  BornOnYear = 'bornOnYear',
  Breed = 'breed',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Environment = 'environment',
  FirebaseId = 'firebaseId',
  Gender = 'gender',
  Id = 'id',
  Name = 'name',
  PetParentId = 'petParentId',
  PetType = 'petType',
  Photo = 'photo',
  PimsId = 'pimsId',
  RepoStatus = 'repoStatus',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type PetScalarWhereInput = {
  AND?: Maybe<Array<PetScalarWhereInput>>;
  NOT?: Maybe<Array<PetScalarWhereInput>>;
  OR?: Maybe<Array<PetScalarWhereInput>>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  breed?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  environment?: Maybe<StringFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  gender?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  photo?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weight?: Maybe<FloatFilter>;
};

export type PetScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PetScalarWhereWithAggregatesInput>>;
  bornOn?: Maybe<DateTimeNullableWithAggregatesFilter>;
  bornOnDay?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnMonth?: Maybe<IntNullableWithAggregatesFilter>;
  bornOnYear?: Maybe<IntNullableWithAggregatesFilter>;
  breed?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  environment?: Maybe<StringWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  gender?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  petParentId?: Maybe<StringNullableWithAggregatesFilter>;
  petType?: Maybe<StringWithAggregatesFilter>;
  photo?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  repoStatus?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  weight?: Maybe<FloatWithAggregatesFilter>;
};

export type PetSumAggregateOutputType = {
  __typename?: 'PetSumAggregateOutputType';
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetSumOrderByAggregateInput = {
  bornOnDay?: Maybe<SortOrder>;
  bornOnMonth?: Maybe<SortOrder>;
  bornOnYear?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
};

export type PetUncheckedCreateInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
};

export type PetUncheckedCreateNestedManyWithoutPetParentInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
};

export type PetUncheckedCreateNestedManyWithoutPreviousClinicsInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
};

export type PetUncheckedCreateWithoutConsultationsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedCreateWithoutCurrentClinicInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedCreateWithoutPetParentInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutPetsInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedCreateWithoutPreviousClinicsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutPetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  petParentId?: Maybe<Scalars['String']>;
  petType: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateManyInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateManyWithoutCurrentClinicNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
};

export type PetUncheckedUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type PetUncheckedUpdateManyWithoutPetsIdInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateManyWithoutPetsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateManyWithoutPreviousClinicsNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
};

export type PetUncheckedUpdateWithoutConsultationsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateWithoutCurrentClinicInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateWithoutPetParentInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUncheckedUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUncheckedUpdateWithoutPreviousClinicsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParentId?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateManyMutationInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateManyWithWhereWithoutCurrentClinicInput = {
  data: PetUncheckedUpdateManyWithoutPetsIdInput;
  where: PetScalarWhereInput;
};

export type PetUpdateManyWithWhereWithoutPetParentInput = {
  data: PetUncheckedUpdateManyWithoutPetsInput;
  where: PetScalarWhereInput;
};

export type PetUpdateManyWithWhereWithoutPreviousClinicsInput = {
  data: PetUncheckedUpdateManyWithoutPetsInput;
  where: PetScalarWhereInput;
};

export type PetUpdateManyWithoutCurrentClinicNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<PetCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<PetCreateManyCurrentClinicInputEnvelope>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
};

export type PetUpdateManyWithoutPetParentNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPetParentInput>>;
  create?: Maybe<Array<PetCreateWithoutPetParentInput>>;
  createMany?: Maybe<PetCreateManyPetParentInputEnvelope>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPetParentInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPetParentInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPetParentInput>>;
};

export type PetUpdateManyWithoutPreviousClinicsNestedInput = {
  connect?: Maybe<Array<PetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PetCreateOrConnectWithoutPreviousClinicsInput>>;
  create?: Maybe<Array<PetCreateWithoutPreviousClinicsInput>>;
  delete?: Maybe<Array<PetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PetScalarWhereInput>>;
  disconnect?: Maybe<Array<PetWhereUniqueInput>>;
  set?: Maybe<Array<PetWhereUniqueInput>>;
  update?: Maybe<Array<PetUpdateWithWhereUniqueWithoutPreviousClinicsInput>>;
  updateMany?: Maybe<Array<PetUpdateManyWithWhereWithoutPreviousClinicsInput>>;
  upsert?: Maybe<Array<PetUpsertWithWhereUniqueWithoutPreviousClinicsInput>>;
};

export type PetUpdateOneWithoutConsultationsNestedInput = {
  connect?: Maybe<PetWhereUniqueInput>;
  connectOrCreate?: Maybe<PetCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<PetUncheckedCreateWithoutConsultationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PetUncheckedUpdateWithoutConsultationsInput>;
  upsert?: Maybe<PetUpsertWithoutConsultationsInput>;
};

export type PetUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  data: PetUncheckedUpdateWithoutCurrentClinicInput;
  where: PetWhereUniqueInput;
};

export type PetUpdateWithWhereUniqueWithoutPetParentInput = {
  data: PetUncheckedUpdateWithoutPetParentInput;
  where: PetWhereUniqueInput;
};

export type PetUpdateWithWhereUniqueWithoutPreviousClinicsInput = {
  data: PetUncheckedUpdateWithoutPreviousClinicsInput;
  where: PetWhereUniqueInput;
};

export type PetUpdateWithoutConsultationsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateWithoutCurrentClinicInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateWithoutPetParentInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  previousClinics?: Maybe<ClinicUpdateManyWithoutPetsNestedInput>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpdateWithoutPreviousClinicsInput = {
  bornOn?: Maybe<Scalars['DateTime']>;
  bornOnDay?: Maybe<Scalars['Int']>;
  bornOnMonth?: Maybe<Scalars['Int']>;
  bornOnYear?: Maybe<Scalars['Int']>;
  breed?: Maybe<Scalars['String']>;
  consultations?: Maybe<ConsultationUpdateManyWithoutPetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutPetsIdNestedInput>;
  environment?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  petParent?: Maybe<OwnerInfoUpdateOneWithoutPetsNestedInput>;
  petType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  repoStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  create: PetUncheckedCreateWithoutCurrentClinicInput;
  update: PetUncheckedUpdateWithoutCurrentClinicInput;
  where: PetWhereUniqueInput;
};

export type PetUpsertWithWhereUniqueWithoutPetParentInput = {
  create: PetUncheckedCreateWithoutPetParentInput;
  update: PetUncheckedUpdateWithoutPetParentInput;
  where: PetWhereUniqueInput;
};

export type PetUpsertWithWhereUniqueWithoutPreviousClinicsInput = {
  create: PetUncheckedCreateWithoutPreviousClinicsInput;
  update: PetUncheckedUpdateWithoutPreviousClinicsInput;
  where: PetWhereUniqueInput;
};

export type PetUpsertWithoutConsultationsInput = {
  create: PetUncheckedCreateWithoutConsultationsInput;
  update: PetUncheckedUpdateWithoutConsultationsInput;
};

export type PetWhereInput = {
  AND?: Maybe<Array<PetWhereInput>>;
  NOT?: Maybe<Array<PetWhereInput>>;
  OR?: Maybe<Array<PetWhereInput>>;
  bornOn?: Maybe<DateTimeNullableFilter>;
  bornOnDay?: Maybe<IntNullableFilter>;
  bornOnMonth?: Maybe<IntNullableFilter>;
  bornOnYear?: Maybe<IntNullableFilter>;
  breed?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  environment?: Maybe<StringFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  gender?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  petParent?: Maybe<OwnerInfoWhereInput>;
  petParentId?: Maybe<StringNullableFilter>;
  petType?: Maybe<StringFilter>;
  photo?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  previousClinics?: Maybe<ClinicListRelationFilter>;
  repoStatus?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  weight?: Maybe<FloatFilter>;
};

export type PetWhereUniqueInput = {
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupRequest = {
  __typename?: 'PhoneNumberLookupRequest';
  _count: PhoneNumberLookupRequestCountOutputType;
  clinicEntityPhoneNumbers: Array<ClinicEntityPhoneNumber>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  providerRequestId: Scalars['String'];
  results: Array<PhoneNumberLookupResult>;
  status: PhoneNumberLookupRequestStatus;
  updatedAt: Scalars['DateTime'];
};


export type PhoneNumberLookupRequestClinicEntityPhoneNumbersArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type PhoneNumberLookupRequestResultsArgs = {
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  distinct?: Maybe<PhoneNumberLookupResultScalarFieldEnum>;
  orderBy?: Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export type PhoneNumberLookupRequestCountAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  providerRequestId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PhoneNumberLookupRequestCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestCountOutputType = {
  __typename?: 'PhoneNumberLookupRequestCountOutputType';
  clinicEntityPhoneNumbers: Scalars['Int'];
  results: Scalars['Int'];
};

export type PhoneNumberLookupRequestCreateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultCreateNestedManyWithoutRequestInput>;
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestCreateNestedOneWithoutClinicEntityPhoneNumbersInput = {
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput = {
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput>;
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput>;
};

export type PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput = {
  create: PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
  where: PhoneNumberLookupRequestWhereUniqueInput;
};

export type PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput = {
  create: PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput;
  where: PhoneNumberLookupRequestWhereUniqueInput;
};

export type PhoneNumberLookupRequestCreateWithoutClinicEntityPhoneNumbersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultCreateNestedManyWithoutRequestInput>;
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestCreateWithoutResultsInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestMaxAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestMinAggregateOutputType = {
  __typename?: 'PhoneNumberLookupRequestMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PhoneNumberLookupRequestOrderByRelevanceFieldEnum {
  Id = 'id',
  ProviderRequestId = 'providerRequestId'
}

export type PhoneNumberLookupRequestOrderByRelevanceInput = {
  fields: Array<PhoneNumberLookupRequestOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PhoneNumberLookupRequestOrderByWithAggregationInput = {
  _count?: Maybe<PhoneNumberLookupRequestCountOrderByAggregateInput>;
  _max?: Maybe<PhoneNumberLookupRequestMaxOrderByAggregateInput>;
  _min?: Maybe<PhoneNumberLookupRequestMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PhoneNumberLookupRequestOrderByRelevanceInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerRequestId?: Maybe<SortOrder>;
  results?: Maybe<PhoneNumberLookupResultOrderByRelationAggregateInput>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PhoneNumberLookupRequestRelationFilter = {
  is?: Maybe<PhoneNumberLookupRequestWhereInput>;
  isNot?: Maybe<PhoneNumberLookupRequestWhereInput>;
};

export enum PhoneNumberLookupRequestScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderRequestId = 'providerRequestId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type PhoneNumberLookupRequestScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PhoneNumberLookupRequestScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  providerRequestId?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumPhoneNumberLookupRequestStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PhoneNumberLookupRequestStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartialComplete = 'PARTIAL_COMPLETE'
}

export type PhoneNumberLookupRequestUncheckedCreateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput>;
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  results?: Maybe<PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput>;
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupRequestInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId: Scalars['String'];
  status: PhoneNumberLookupRequestStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedUpdateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUpdateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput = {
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutResultsInput>;
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput>;
  update?: Maybe<PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput>;
  upsert?: Maybe<PhoneNumberLookupRequestUpsertWithoutResultsInput>;
};

export type PhoneNumberLookupRequestUpdateOneWithoutClinicEntityPhoneNumbersNestedInput = {
  connect?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupRequestCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  create?: Maybe<PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput>;
  upsert?: Maybe<PhoneNumberLookupRequestUpsertWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupRequestUpdateWithoutClinicEntityPhoneNumbersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  results?: Maybe<PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUpdateWithoutResultsInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupRequestNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  providerRequestId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneNumberLookupRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberLookupRequestUpsertWithoutClinicEntityPhoneNumbersInput = {
  create: PhoneNumberLookupRequestUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
  update: PhoneNumberLookupRequestUncheckedUpdateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupRequestUpsertWithoutResultsInput = {
  create: PhoneNumberLookupRequestUncheckedCreateWithoutResultsInput;
  update: PhoneNumberLookupRequestUncheckedUpdateWithoutResultsInput;
};

export type PhoneNumberLookupRequestWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupRequestWhereInput>>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  providerRequestId?: Maybe<StringFilter>;
  results?: Maybe<PhoneNumberLookupResultListRelationFilter>;
  status?: Maybe<EnumPhoneNumberLookupRequestStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PhoneNumberLookupRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PhoneNumberLookupResult = {
  __typename?: 'PhoneNumberLookupResult';
  _count: PhoneNumberLookupResultCountOutputType;
  clinicEntityPhoneNumbers: Array<ClinicEntityPhoneNumber>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id: Scalars['String'];
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  lookupRequestId: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequest;
  responseCode: Scalars['Int'];
};


export type PhoneNumberLookupResultClinicEntityPhoneNumbersArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicEntityPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};

export type PhoneNumberLookupResultAvgAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultAvgAggregateOutputType';
  responseCode?: Maybe<Scalars['Float']>;
};

export type PhoneNumberLookupResultAvgOrderByAggregateInput = {
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultCountAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultCountAggregateOutputType';
  _all: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  e164Format: Scalars['Int'];
  formatted: Scalars['Int'];
  id: Scalars['Int'];
  lineProvider: Scalars['Int'];
  lineType: Scalars['Int'];
  lookupRequestId: Scalars['Int'];
  message: Scalars['Int'];
  mobileCountryCode: Scalars['Int'];
  mobileNetworkCode: Scalars['Int'];
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultCountOrderByAggregateInput = {
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultCountOutputType = {
  __typename?: 'PhoneNumberLookupResultCountOutputType';
  clinicEntityPhoneNumbers: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput>;
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateManyInput = {
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  lookupRequestId: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateManyRequestInput = {
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateManyRequestInputEnvelope = {
  data: Array<PhoneNumberLookupResultCreateManyRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PhoneNumberLookupResultCreateNestedManyWithoutRequestInput = {
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
};

export type PhoneNumberLookupResultCreateNestedOneWithoutClinicEntityPhoneNumbersInput = {
  connect?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  create?: Maybe<PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput = {
  create: PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};

export type PhoneNumberLookupResultCreateOrConnectWithoutRequestInput = {
  create: PhoneNumberLookupResultUncheckedCreateWithoutRequestInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};

export type PhoneNumberLookupResultCreateWithoutClinicEntityPhoneNumbersInput = {
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request: PhoneNumberLookupRequestCreateNestedOneWithoutResultsInput;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultCreateWithoutRequestInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberCreateNestedManyWithoutPhoneNumberLookupResultInput>;
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultListRelationFilter = {
  every?: Maybe<PhoneNumberLookupResultWhereInput>;
  none?: Maybe<PhoneNumberLookupResultWhereInput>;
  some?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export type PhoneNumberLookupResultMaxAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultMaxAggregateOutputType';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultMaxOrderByAggregateInput = {
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultMinAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultMinAggregateOutputType';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultMinOrderByAggregateInput = {
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PhoneNumberLookupResultOrderByRelevanceFieldEnum {
  Country = 'country',
  E164Format = 'e164Format',
  Formatted = 'formatted',
  Id = 'id',
  LineProvider = 'lineProvider',
  LineType = 'lineType',
  LookupRequestId = 'lookupRequestId',
  Message = 'message',
  MobileCountryCode = 'mobileCountryCode',
  MobileNetworkCode = 'mobileNetworkCode'
}

export type PhoneNumberLookupResultOrderByRelevanceInput = {
  fields: Array<PhoneNumberLookupResultOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PhoneNumberLookupResultOrderByWithAggregationInput = {
  _avg?: Maybe<PhoneNumberLookupResultAvgOrderByAggregateInput>;
  _count?: Maybe<PhoneNumberLookupResultCountOrderByAggregateInput>;
  _max?: Maybe<PhoneNumberLookupResultMaxOrderByAggregateInput>;
  _min?: Maybe<PhoneNumberLookupResultMinOrderByAggregateInput>;
  _sum?: Maybe<PhoneNumberLookupResultSumOrderByAggregateInput>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PhoneNumberLookupResultOrderByRelevanceInput>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberOrderByRelationAggregateInput>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  e164Format?: Maybe<SortOrder>;
  formatted?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lineProvider?: Maybe<SortOrder>;
  lineType?: Maybe<SortOrder>;
  lookupRequestId?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  mobileCountryCode?: Maybe<SortOrder>;
  mobileNetworkCode?: Maybe<SortOrder>;
  request?: Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>;
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultRelationFilter = {
  is?: Maybe<PhoneNumberLookupResultWhereInput>;
  isNot?: Maybe<PhoneNumberLookupResultWhereInput>;
};

export enum PhoneNumberLookupResultScalarFieldEnum {
  Country = 'country',
  CreatedAt = 'createdAt',
  E164Format = 'e164Format',
  Formatted = 'formatted',
  Id = 'id',
  LineProvider = 'lineProvider',
  LineType = 'lineType',
  LookupRequestId = 'lookupRequestId',
  Message = 'message',
  MobileCountryCode = 'mobileCountryCode',
  MobileNetworkCode = 'mobileNetworkCode',
  ResponseCode = 'responseCode'
}

export type PhoneNumberLookupResultScalarWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  country?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  e164Format?: Maybe<StringFilter>;
  formatted?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lineProvider?: Maybe<StringFilter>;
  lineType?: Maybe<StringFilter>;
  lookupRequestId?: Maybe<StringFilter>;
  message?: Maybe<StringFilter>;
  mobileCountryCode?: Maybe<StringNullableFilter>;
  mobileNetworkCode?: Maybe<StringNullableFilter>;
  responseCode?: Maybe<IntFilter>;
};

export type PhoneNumberLookupResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultScalarWhereWithAggregatesInput>>;
  country?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  e164Format?: Maybe<StringWithAggregatesFilter>;
  formatted?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lineProvider?: Maybe<StringWithAggregatesFilter>;
  lineType?: Maybe<StringWithAggregatesFilter>;
  lookupRequestId?: Maybe<StringWithAggregatesFilter>;
  message?: Maybe<StringWithAggregatesFilter>;
  mobileCountryCode?: Maybe<StringNullableWithAggregatesFilter>;
  mobileNetworkCode?: Maybe<StringNullableWithAggregatesFilter>;
  responseCode?: Maybe<IntWithAggregatesFilter>;
};

export type PhoneNumberLookupResultSumAggregateOutputType = {
  __typename?: 'PhoneNumberLookupResultSumAggregateOutputType';
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultSumOrderByAggregateInput = {
  responseCode?: Maybe<SortOrder>;
};

export type PhoneNumberLookupResultUncheckedCreateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput>;
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  lookupRequestId: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultUncheckedCreateNestedManyWithoutRequestInput = {
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
};

export type PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput = {
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  lookupRequestId: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultUncheckedCreateWithoutRequestInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedCreateNestedManyWithoutPhoneNumberLookupResultInput>;
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format: Scalars['String'];
  formatted: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lineProvider: Scalars['String'];
  lineType: Scalars['String'];
  message: Scalars['String'];
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
};

export type PhoneNumberLookupResultUncheckedUpdateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyInput = {
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyWithoutRequestNestedInput = {
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  delete?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  disconnect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  set?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  update?: Maybe<Array<PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput>>;
  updateMany?: Maybe<Array<PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput>>;
  upsert?: Maybe<Array<PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput>>;
};

export type PhoneNumberLookupResultUncheckedUpdateManyWithoutResultsInput = {
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput = {
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  lookupRequestId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUncheckedUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUpdateInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request?: Maybe<PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUpdateManyMutationInput = {
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput = {
  data: PhoneNumberLookupResultUncheckedUpdateManyWithoutResultsInput;
  where: PhoneNumberLookupResultScalarWhereInput;
};

export type PhoneNumberLookupResultUpdateManyWithoutRequestNestedInput = {
  connect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhoneNumberLookupResultCreateOrConnectWithoutRequestInput>>;
  create?: Maybe<Array<PhoneNumberLookupResultCreateWithoutRequestInput>>;
  createMany?: Maybe<PhoneNumberLookupResultCreateManyRequestInputEnvelope>;
  delete?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PhoneNumberLookupResultScalarWhereInput>>;
  disconnect?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  set?: Maybe<Array<PhoneNumberLookupResultWhereUniqueInput>>;
  update?: Maybe<Array<PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput>>;
  updateMany?: Maybe<Array<PhoneNumberLookupResultUpdateManyWithWhereWithoutRequestInput>>;
  upsert?: Maybe<Array<PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput>>;
};

export type PhoneNumberLookupResultUpdateOneWithoutClinicEntityPhoneNumbersNestedInput = {
  connect?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  connectOrCreate?: Maybe<PhoneNumberLookupResultCreateOrConnectWithoutClinicEntityPhoneNumbersInput>;
  create?: Maybe<PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput>;
  upsert?: Maybe<PhoneNumberLookupResultUpsertWithoutClinicEntityPhoneNumbersInput>;
};

export type PhoneNumberLookupResultUpdateWithWhereUniqueWithoutRequestInput = {
  data: PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};

export type PhoneNumberLookupResultUpdateWithoutClinicEntityPhoneNumbersInput = {
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  request?: Maybe<PhoneNumberLookupRequestUpdateOneRequiredWithoutResultsNestedInput>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUpdateWithoutRequestInput = {
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberUpdateManyWithoutPhoneNumberLookupResultNestedInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  e164Format?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineProvider?: Maybe<Scalars['String']>;
  lineType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['Int']>;
};

export type PhoneNumberLookupResultUpsertWithWhereUniqueWithoutRequestInput = {
  create: PhoneNumberLookupResultUncheckedCreateWithoutRequestInput;
  update: PhoneNumberLookupResultUncheckedUpdateWithoutRequestInput;
  where: PhoneNumberLookupResultWhereUniqueInput;
};

export type PhoneNumberLookupResultUpsertWithoutClinicEntityPhoneNumbersInput = {
  create: PhoneNumberLookupResultUncheckedCreateWithoutClinicEntityPhoneNumbersInput;
  update: PhoneNumberLookupResultUncheckedUpdateWithoutClinicEntityPhoneNumbersInput;
};

export type PhoneNumberLookupResultWhereInput = {
  AND?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  NOT?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  OR?: Maybe<Array<PhoneNumberLookupResultWhereInput>>;
  clinicEntityPhoneNumbers?: Maybe<ClinicEntityPhoneNumberListRelationFilter>;
  country?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  e164Format?: Maybe<StringFilter>;
  formatted?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lineProvider?: Maybe<StringFilter>;
  lineType?: Maybe<StringFilter>;
  lookupRequestId?: Maybe<StringFilter>;
  message?: Maybe<StringFilter>;
  mobileCountryCode?: Maybe<StringNullableFilter>;
  mobileNetworkCode?: Maybe<StringNullableFilter>;
  request?: Maybe<PhoneNumberLookupRequestWhereInput>;
  responseCode?: Maybe<IntFilter>;
};

export type PhoneNumberLookupResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PhoneNumberStatus {
  Hosted = 'Hosted',
  HostingNotAvailable = 'HostingNotAvailable',
  New = 'New',
  PortingInProgress = 'PortingInProgress',
  ProblemHosting = 'ProblemHosting',
  ReadyToHost = 'ReadyToHost'
}

export enum PimsIntegrationCapability {
  Appointment = 'Appointment',
  AppointmentAvailability = 'AppointmentAvailability',
  AppointmentLiveDelete = 'AppointmentLiveDelete',
  AppointmentType = 'AppointmentType',
  Availability = 'Availability',
  AvailabilityResources = 'AvailabilityResources',
  Breed = 'Breed',
  ClinicEmployee = 'ClinicEmployee',
  ClinicEntityPhoneNumber = 'ClinicEntityPhoneNumber',
  ClinicPet = 'ClinicPet',
  ClinicPetAlert = 'ClinicPetAlert',
  ClinicPetCustomFields = 'ClinicPetCustomFields',
  ClinicPetParent = 'ClinicPetParent',
  ClinicPetParentAddress = 'ClinicPetParentAddress',
  ClinicPetParentContactInfo = 'ClinicPetParentContactInfo',
  ClinicPetParentCustomFields = 'ClinicPetParentCustomFields',
  ClinicPetToClinicPetParent = 'ClinicPetToClinicPetParent',
  ClinicRoom = 'ClinicRoom',
  Color = 'Color',
  Email = 'Email',
  Invoice = 'Invoice',
  InvoiceLineItem = 'InvoiceLineItem',
  NormalizedService = 'NormalizedService',
  PimsPaymentType = 'PimsPaymentType',
  Prescription = 'Prescription',
  Service = 'Service',
  ServiceCategory = 'ServiceCategory',
  ServiceReminder = 'ServiceReminder',
  Sex = 'Sex',
  Species = 'Species',
  Vaccination = 'Vaccination',
  Vital = 'Vital'
}

export type PimsInvoice = {
  __typename?: 'PimsInvoice';
  createdAt: Scalars['DateTime'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  invoice: Invoice;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  /** The status from PIMS for this invoice, since these are specific to a PIMS or a clinic config */
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PimsInvoiceCountAggregateOutputType = {
  __typename?: 'PimsInvoiceCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PimsInvoiceCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
};

export type PimsInvoiceCreateNestedOneWithoutInvoiceInput = {
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
};

export type PimsInvoiceCreateOrConnectWithoutIntegrationInput = {
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
  where: PimsInvoiceWhereUniqueInput;
};

export type PimsInvoiceCreateOrConnectWithoutInvoiceInput = {
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
  where: PimsInvoiceWhereUniqueInput;
};

export type PimsInvoiceCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoice: InvoiceCreateNestedOneWithoutPimsInvoiceInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoicesInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItem = {
  __typename?: 'PimsInvoiceLineItem';
  createdAt: Scalars['DateTime'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  invoiceLineItem: InvoiceLineItem;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt: Scalars['DateTime'];
};

export type PimsInvoiceLineItemCountAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  integrationId: Scalars['Int'];
  invoiceLineItemId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PimsInvoiceLineItemCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemCreateManyIntegrationInputEnvelope = {
  data: Array<PimsInvoiceLineItemCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsInvoiceLineItemCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
};

export type PimsInvoiceLineItemCreateNestedOneWithoutInvoiceLineItemInput = {
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput = {
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput = {
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type PimsInvoiceLineItemCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItem: InvoiceLineItemCreateNestedOneWithoutPimsInvoiceLineItemInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsInvoiceLineItemsInput;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemListRelationFilter = {
  every?: Maybe<PimsInvoiceLineItemWhereInput>;
  none?: Maybe<PimsInvoiceLineItemWhereInput>;
  some?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export type PimsInvoiceLineItemMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemMinAggregateOutputType = {
  __typename?: 'PimsInvoiceLineItemMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceLineItemOrderByRelevanceFieldEnum {
  IntegrationId = 'integrationId',
  InvoiceLineItemId = 'invoiceLineItemId',
  PimsId = 'pimsId'
}

export type PimsInvoiceLineItemOrderByRelevanceInput = {
  fields: Array<PimsInvoiceLineItemOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PimsInvoiceLineItemOrderByWithAggregationInput = {
  _count?: Maybe<PimsInvoiceLineItemCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceLineItemMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceLineItemMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItemId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PimsInvoiceLineItemOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItem?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  invoiceLineItemId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceLineItemRelationFilter = {
  is?: Maybe<PimsInvoiceLineItemWhereInput>;
  isNot?: Maybe<PimsInvoiceLineItemWhereInput>;
};

export enum PimsInvoiceLineItemScalarFieldEnum {
  CreatedAt = 'createdAt',
  IntegrationId = 'integrationId',
  InvoiceLineItemId = 'invoiceLineItemId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PimsInvoiceLineItemScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  invoiceLineItemId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsInvoiceLineItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  invoiceLineItemId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PimsInvoiceLineItemType {
  BitwerxAdjustment = 'Bitwerx_Adjustment',
  BitwerxCode = 'Bitwerx_Code',
  BitwerxDiscount = 'Bitwerx_Discount',
  BitwerxEstimate = 'Bitwerx_Estimate',
  BitwerxFee = 'Bitwerx_Fee',
  BitwerxNote = 'Bitwerx_Note',
  BitwerxPayment = 'Bitwerx_Payment',
  BitwerxReturn = 'Bitwerx_Return',
  BitwerxTax = 'Bitwerx_Tax',
  BitwerxWriteOff = 'Bitwerx_WriteOff',
  VetdataAdjustment = 'Vetdata_Adjustment',
  VetdataCode = 'Vetdata_Code',
  VetdataDiscount = 'Vetdata_Discount',
  VetdataEstimate = 'Vetdata_Estimate',
  VetdataFee = 'Vetdata_Fee',
  VetdataNote = 'Vetdata_Note',
  VetdataPayment = 'Vetdata_Payment',
  VetdataReturn = 'Vetdata_Return',
  VetdataTax = 'Vetdata_Tax',
  VetdataUnknown = 'Vetdata_Unknown',
  VetdataWriteOff = 'Vetdata_WriteOff'
}

export type PimsInvoiceLineItemUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
};

export type PimsInvoiceLineItemUncheckedCreateNestedOneWithoutInvoiceLineItemInput = {
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  type: PimsInvoiceLineItemType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedUpdateOneWithoutInvoiceLineItemNestedInput = {
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItemId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput = {
  data: PimsInvoiceLineItemUncheckedUpdateManyWithoutPimsInvoiceLineItemsInput;
  where: PimsInvoiceLineItemScalarWhereInput;
};

export type PimsInvoiceLineItemUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceLineItemCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceLineItemCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceLineItemCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceLineItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  set?: Maybe<Array<PimsInvoiceLineItemWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceLineItemUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsInvoiceLineItemUpdateOneWithoutInvoiceLineItemNestedInput = {
  connect?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceLineItemCreateOrConnectWithoutInvoiceLineItemInput>;
  create?: Maybe<PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput>;
  upsert?: Maybe<PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput>;
};

export type PimsInvoiceLineItemUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type PimsInvoiceLineItemUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceLineItem?: Maybe<InvoiceLineItemUpdateOneRequiredWithoutPimsInvoiceLineItemNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUpdateWithoutInvoiceLineItemInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoiceLineItemsNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  type?: Maybe<PimsInvoiceLineItemType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceLineItemUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: PimsInvoiceLineItemUncheckedCreateWithoutIntegrationInput;
  update: PimsInvoiceLineItemUncheckedUpdateWithoutIntegrationInput;
  where: PimsInvoiceLineItemWhereUniqueInput;
};

export type PimsInvoiceLineItemUpsertWithoutInvoiceLineItemInput = {
  create: PimsInvoiceLineItemUncheckedCreateWithoutInvoiceLineItemInput;
  update: PimsInvoiceLineItemUncheckedUpdateWithoutInvoiceLineItemInput;
};

export type PimsInvoiceLineItemWhereInput = {
  AND?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceLineItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  invoiceLineItem?: Maybe<InvoiceLineItemWhereInput>;
  invoiceLineItemId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  type?: Maybe<EnumPimsInvoiceLineItemTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsInvoiceLineItemWhereUniqueInput = {
  invoiceLineItemId?: Maybe<Scalars['String']>;
  invoiceLineItemId_integrationId_pimsId?: Maybe<PimsInvoiceLineItemInvoiceLineItemIdIntegrationIdPimsIdCompoundUniqueInput>;
  pimsInvoiceLineItemUniqueIntegrationIdPimsId?: Maybe<PimsInvoiceLineItemPimsInvoiceLineItemUniqueIntegrationIdPimsIdCompoundUniqueInput>;
};

export type PimsInvoiceListRelationFilter = {
  every?: Maybe<PimsInvoiceWhereInput>;
  none?: Maybe<PimsInvoiceWhereInput>;
  some?: Maybe<PimsInvoiceWhereInput>;
};

export type PimsInvoiceMaxAggregateOutputType = {
  __typename?: 'PimsInvoiceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceMinAggregateOutputType = {
  __typename?: 'PimsInvoiceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsInvoiceOrderByRelevanceFieldEnum {
  IntegrationId = 'integrationId',
  InvoiceId = 'invoiceId',
  PimsId = 'pimsId',
  Status = 'status'
}

export type PimsInvoiceOrderByRelevanceInput = {
  fields: Array<PimsInvoiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PimsInvoiceOrderByWithAggregationInput = {
  _count?: Maybe<PimsInvoiceCountOrderByAggregateInput>;
  _max?: Maybe<PimsInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<PimsInvoiceMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PimsInvoiceOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoice?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  invoiceId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsInvoiceRelationFilter = {
  is?: Maybe<PimsInvoiceWhereInput>;
  isNot?: Maybe<PimsInvoiceWhereInput>;
};

export enum PimsInvoiceScalarFieldEnum {
  CreatedAt = 'createdAt',
  IntegrationId = 'integrationId',
  InvoiceId = 'invoiceId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type PimsInvoiceScalarWhereInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  integrationId?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsInvoiceScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  invoiceId?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  status?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PimsInvoiceUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
};

export type PimsInvoiceUncheckedCreateNestedOneWithoutInvoiceInput = {
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
};

export type PimsInvoiceUncheckedCreateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedUpdateOneWithoutInvoiceNestedInput = {
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
};

export type PimsInvoiceUncheckedUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoiceId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUncheckedUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integrationId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput = {
  data: PimsInvoiceUncheckedUpdateManyWithoutPimsInvoicesInput;
  where: PimsInvoiceScalarWhereInput;
};

export type PimsInvoiceUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsInvoiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsInvoiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsInvoiceCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<PimsInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsInvoiceUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsInvoiceUpdateOneWithoutInvoiceNestedInput = {
  connect?: Maybe<PimsInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsInvoiceCreateOrConnectWithoutInvoiceInput>;
  create?: Maybe<PimsInvoiceUncheckedCreateWithoutInvoiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsInvoiceUncheckedUpdateWithoutInvoiceInput>;
  upsert?: Maybe<PimsInvoiceUpsertWithoutInvoiceInput>;
};

export type PimsInvoiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
  where: PimsInvoiceWhereUniqueInput;
};

export type PimsInvoiceUpdateWithoutIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutPimsInvoiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsInvoicesNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsInvoiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: PimsInvoiceUncheckedCreateWithoutIntegrationInput;
  update: PimsInvoiceUncheckedUpdateWithoutIntegrationInput;
  where: PimsInvoiceWhereUniqueInput;
};

export type PimsInvoiceUpsertWithoutInvoiceInput = {
  create: PimsInvoiceUncheckedCreateWithoutInvoiceInput;
  update: PimsInvoiceUncheckedUpdateWithoutInvoiceInput;
};

export type PimsInvoiceWhereInput = {
  AND?: Maybe<Array<PimsInvoiceWhereInput>>;
  NOT?: Maybe<Array<PimsInvoiceWhereInput>>;
  OR?: Maybe<Array<PimsInvoiceWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoiceId?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  status?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsInvoiceWhereUniqueInput = {
  invoiceId?: Maybe<Scalars['String']>;
  invoiceId_integrationId_pimsId?: Maybe<PimsInvoiceInvoiceIdIntegrationIdPimsIdCompoundUniqueInput>;
  pimsInvoiceUniqueIntegrationIdPimsId?: Maybe<PimsInvoicePimsInvoiceUniqueIntegrationIdPimsIdCompoundUniqueInput>;
};

export type PimsPaymentType = {
  __typename?: 'PimsPaymentType';
  _count: PimsPaymentTypeCountOutputType;
  amexBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  careDiscountBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  defaultBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations: Array<BitwerxPaymentWritebackConfiguration>;
};


export type PimsPaymentTypeAmexBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type PimsPaymentTypeCareDiscountBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type PimsPaymentTypeDefaultBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type PimsPaymentTypeDiscoverBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type PimsPaymentTypeMastercardBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type PimsPaymentTypeVisaBitwerxPaymentWritebackConfigurationsArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>;
  orderBy?: Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};

export type PimsPaymentTypeCountAggregateOutputType = {
  __typename?: 'PimsPaymentTypeCountAggregateOutputType';
  _all: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isCreditCard: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isInactive: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
};

export type PimsPaymentTypeCountOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type PimsPaymentTypeCountOutputType = {
  __typename?: 'PimsPaymentTypeCountOutputType';
  amexBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  careDiscountBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  defaultBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  discoverBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  mastercardBitwerxPaymentWritebackConfigurations: Scalars['Int'];
  visaBitwerxPaymentWritebackConfigurations: Scalars['Int'];
};

export type PimsPaymentTypeCreateInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateManyInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeCreateManyIntegrationInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeCreateManyIntegrationInputEnvelope = {
  data: Array<PimsPaymentTypeCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsPaymentTypeCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
};

export type PimsPaymentTypeCreateNestedOneWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateNestedOneWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutIntegrationInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutIntegrationInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutIntegrationInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPimsPaymentTypesInput;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeListRelationFilter = {
  every?: Maybe<PimsPaymentTypeWhereInput>;
  none?: Maybe<PimsPaymentTypeWhereInput>;
  some?: Maybe<PimsPaymentTypeWhereInput>;
};

export type PimsPaymentTypeMaxAggregateOutputType = {
  __typename?: 'PimsPaymentTypeMaxAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PimsPaymentTypeMaxOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PimsPaymentTypeMinAggregateOutputType = {
  __typename?: 'PimsPaymentTypeMinAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type PimsPaymentTypeMinOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
};

export type PimsPaymentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsPaymentTypeOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId'
}

export type PimsPaymentTypeOrderByRelevanceInput = {
  fields: Array<PimsPaymentTypeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PimsPaymentTypeOrderByWithAggregationInput = {
  _count?: Maybe<PimsPaymentTypeCountOrderByAggregateInput>;
  _max?: Maybe<PimsPaymentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<PimsPaymentTypeMinOrderByAggregateInput>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
};

export type PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PimsPaymentTypeOrderByRelevanceInput>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  description?: Maybe<SortOrder>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isCreditCard?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isInactive?: Maybe<SortOrder>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationOrderByRelationAggregateInput>;
};

export type PimsPaymentTypePimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type PimsPaymentTypeRelationFilter = {
  is?: Maybe<PimsPaymentTypeWhereInput>;
  isNot?: Maybe<PimsPaymentTypeWhereInput>;
};

export enum PimsPaymentTypeScalarFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsCreditCard = 'isCreditCard',
  IsDeleted = 'isDeleted',
  IsInactive = 'isInactive',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue'
}

export type PimsPaymentTypeScalarWhereInput = {
  AND?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  OR?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isCreditCard?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isInactive?: Maybe<BoolFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
};

export type PimsPaymentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsPaymentTypeScalarWhereWithAggregatesInput>>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isCreditCard?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isInactive?: Maybe<BoolWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonWithAggregatesFilter>;
};

export type PimsPaymentTypeUncheckedCreateInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
};

export type PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutIntegrationInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutVisaPimsPaymentTypeInput>;
};

export type PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutAmexPimsPaymentTypeInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutCareDiscountPimsPaymentTypeInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDefaultPimsPaymentTypeInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutDiscoverPimsPaymentTypeInput>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isCreditCard: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive: Scalars['Boolean'];
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedCreateNestedManyWithoutMastercardPimsPaymentTypeInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
};

export type PimsPaymentTypeUncheckedUpdateInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateManyInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  set?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  update?: Maybe<Array<PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsPaymentTypeUncheckedUpdateManyWithoutPimsPaymentTypesInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUncheckedUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUpdateInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateManyMutationInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput = {
  data: PimsPaymentTypeUncheckedUpdateManyWithoutPimsPaymentTypesInput;
  where: PimsPaymentTypeScalarWhereInput;
};

export type PimsPaymentTypeUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsPaymentTypeCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsPaymentTypeCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsPaymentTypeCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsPaymentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  set?: Maybe<Array<PimsPaymentTypeWhereUniqueInput>>;
  update?: Maybe<Array<PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsPaymentTypeUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsPaymentTypeUpdateOneWithoutAmexBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutAmexBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutCareDiscountBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutDefaultBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutDefaultBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutDiscoverBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutMastercardBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutMastercardBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateOneWithoutVisaBitwerxPaymentWritebackConfigurationsNestedInput = {
  connect?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsPaymentTypeCreateOrConnectWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  create?: Maybe<PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
  upsert?: Maybe<PimsPaymentTypeUpsertWithoutVisaBitwerxPaymentWritebackConfigurationsInput>;
};

export type PimsPaymentTypeUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutIntegrationInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutVisaPimsPaymentTypeNestedInput>;
};

export type PimsPaymentTypeUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutAmexPimsPaymentTypeNestedInput>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutCareDiscountPimsPaymentTypeNestedInput>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDefaultPimsPaymentTypeNestedInput>;
  description?: Maybe<Scalars['String']>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutDiscoverPimsPaymentTypeNestedInput>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPimsPaymentTypesNestedInput>;
  isCreditCard?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInactive?: Maybe<Scalars['Boolean']>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationUpdateManyWithoutMastercardPimsPaymentTypeNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
};

export type PimsPaymentTypeUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutIntegrationInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutIntegrationInput;
  where: PimsPaymentTypeWhereUniqueInput;
};

export type PimsPaymentTypeUpsertWithoutAmexBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutAmexBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutCareDiscountBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutDefaultBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutDefaultBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutDiscoverBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutMastercardBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutMastercardBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeUpsertWithoutVisaBitwerxPaymentWritebackConfigurationsInput = {
  create: PimsPaymentTypeUncheckedCreateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
  update: PimsPaymentTypeUncheckedUpdateWithoutVisaBitwerxPaymentWritebackConfigurationsInput;
};

export type PimsPaymentTypeWhereInput = {
  AND?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  NOT?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  OR?: Maybe<Array<PimsPaymentTypeWhereInput>>;
  amexBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  careDiscountBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  defaultBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  description?: Maybe<StringNullableFilter>;
  discoverBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isCreditCard?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isInactive?: Maybe<BoolFilter>;
  mastercardBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  visaBitwerxPaymentWritebackConfigurations?: Maybe<BitwerxPaymentWritebackConfigurationListRelationFilter>;
};

export type PimsPaymentTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<PimsPaymentTypePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum PimsSyncFrequency {
  Sync_5Min = 'Sync_5_Min',
  Sync_24Hour = 'Sync_24_Hour',
  Sync_30Sec = 'Sync_30_Sec'
}

export enum PimsSyncStatus {
  Completed = 'Completed',
  Created = 'Created',
  Error = 'Error',
  Started = 'Started'
}

export type PimsWritebackLogEntry = {
  __typename?: 'PimsWritebackLogEntry';
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  createdAt: Scalars['DateTime'];
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntry>;
  httpCode?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntry>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntry>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  /** Only set this when there were errors */
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<User>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt: Scalars['DateTime'];
};

export type PimsWritebackLogEntryAvgAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryAvgAggregateOutputType';
  httpCode?: Maybe<Scalars['Float']>;
};

export type PimsWritebackLogEntryAvgOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryCountAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  httpCode: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  normalizedError: Scalars['Int'];
  pimsError: Scalars['Int'];
  pimsId: Scalars['Int'];
  requestBody: Scalars['Int'];
  requestId: Scalars['Int'];
  status: Scalars['Int'];
  triggeredByUserId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PimsWritebackLogEntryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryCreateInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateManyIntegrationInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateManyTriggeredByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope = {
  data: Array<PimsWritebackLogEntryCreateManyTriggeredByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
};

export type PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutCareAccountCreditLogInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutCustomFieldsLogInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutInvoiceLogInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
};

export type PimsWritebackLogEntryCreateNestedOneWithoutPaymentLogInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryCreateWithoutCareAccountCreditLogInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateWithoutCustomFieldsLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateWithoutIntegrationInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateWithoutInvoiceLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateWithoutPaymentLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredBy?: Maybe<UserCreateNestedOneWithoutTriggeredWritebacksInput>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryCreateWithoutTriggeredByInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutWritebackLogEntriesInput;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryListRelationFilter = {
  every?: Maybe<PimsWritebackLogEntryWhereInput>;
  none?: Maybe<PimsWritebackLogEntryWhereInput>;
  some?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export type PimsWritebackLogEntryMaxAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryMinAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PimsWritebackLogEntryOrderByRelevanceFieldEnum {
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestId = 'requestId',
  TriggeredByUserId = 'triggeredByUserId'
}

export type PimsWritebackLogEntryOrderByRelevanceInput = {
  fields: Array<PimsWritebackLogEntryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PimsWritebackLogEntryOrderByWithAggregationInput = {
  _avg?: Maybe<PimsWritebackLogEntryAvgOrderByAggregateInput>;
  _count?: Maybe<PimsWritebackLogEntryCountOrderByAggregateInput>;
  _max?: Maybe<PimsWritebackLogEntryMaxOrderByAggregateInput>;
  _min?: Maybe<PimsWritebackLogEntryMinOrderByAggregateInput>;
  _sum?: Maybe<PimsWritebackLogEntrySumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  normalizedError?: Maybe<SortOrder>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredByUserId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PimsWritebackLogEntryOrderByRelevanceInput>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  httpCode?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  normalizedError?: Maybe<SortOrder>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  pimsError?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  requestId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  triggeredBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  triggeredByUserId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryRelationFilter = {
  is?: Maybe<PimsWritebackLogEntryWhereInput>;
  isNot?: Maybe<PimsWritebackLogEntryWhereInput>;
};

export enum PimsWritebackLogEntryScalarFieldEnum {
  CreatedAt = 'createdAt',
  HttpCode = 'httpCode',
  Id = 'id',
  IntegrationId = 'integrationId',
  NormalizedError = 'normalizedError',
  PimsError = 'pimsError',
  PimsId = 'pimsId',
  RequestBody = 'requestBody',
  RequestId = 'requestId',
  Status = 'status',
  TriggeredByUserId = 'triggeredByUserId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PimsWritebackLogEntryScalarWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableFilter>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsWritebackLogEntryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  httpCode?: Maybe<IntNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableWithAggregatesFilter>;
  pimsError?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  requestBody?: Maybe<JsonNullableWithAggregatesFilter>;
  requestId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumPimsWritebackStatusWithAggregatesFilter>;
  triggeredByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  type?: Maybe<EnumPimsWritebackTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PimsWritebackLogEntrySumAggregateOutputType = {
  __typename?: 'PimsWritebackLogEntrySumAggregateOutputType';
  httpCode?: Maybe<Scalars['Int']>;
};

export type PimsWritebackLogEntrySumOrderByAggregateInput = {
  httpCode?: Maybe<SortOrder>;
};

export type PimsWritebackLogEntryUncheckedCreateInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
};

export type PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedOneWithoutWritebackLogInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status: PimsWritebackStatus;
  type: PimsWritebackType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredByUserId?: Maybe<Scalars['String']>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput = {
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutWritebackLogEntriesInput;
  where: PimsWritebackLogEntryScalarWhereInput;
};

export type PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput = {
  data: PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredWritebacksInput;
  where: PimsWritebackLogEntryScalarWhereInput;
};

export type PimsWritebackLogEntryUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput = {
  connect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PimsWritebackLogEntryCreateOrConnectWithoutTriggeredByInput>>;
  create?: Maybe<Array<PimsWritebackLogEntryCreateWithoutTriggeredByInput>>;
  createMany?: Maybe<PimsWritebackLogEntryCreateManyTriggeredByInputEnvelope>;
  delete?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PimsWritebackLogEntryScalarWhereInput>>;
  disconnect?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  set?: Maybe<Array<PimsWritebackLogEntryWhereUniqueInput>>;
  update?: Maybe<Array<PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput>>;
  updateMany?: Maybe<Array<PimsWritebackLogEntryUpdateManyWithWhereWithoutTriggeredByInput>>;
  upsert?: Maybe<Array<PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput>>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutCareAccountCreditLogNestedInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCareAccountCreditLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutCareAccountCreditLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutCustomFieldsLogNestedInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutCustomFieldsLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutCustomFieldsLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutInvoiceLogNestedInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutInvoiceLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutInvoiceLogInput>;
};

export type PimsWritebackLogEntryUpdateOneRequiredWithoutPaymentLogNestedInput = {
  connect?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  connectOrCreate?: Maybe<PimsWritebackLogEntryCreateOrConnectWithoutPaymentLogInput>;
  create?: Maybe<PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput>;
  update?: Maybe<PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput>;
  upsert?: Maybe<PimsWritebackLogEntryUpsertWithoutPaymentLogInput>;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryUpdateWithWhereUniqueWithoutTriggeredByInput = {
  data: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryUpdateWithoutCareAccountCreditLogInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateWithoutCustomFieldsLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateWithoutIntegrationInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateWithoutInvoiceLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateWithoutPaymentLogInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  triggeredBy?: Maybe<UserUpdateOneWithoutTriggeredWritebacksNestedInput>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpdateWithoutTriggeredByInput = {
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  httpCode?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutWritebackLogEntriesNestedInput>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  normalizedError?: Maybe<NormalizedWritebackError>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryUpdateOneWithoutWritebackLogNestedInput>;
  pimsError?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  requestBody?: Maybe<Scalars['Json']>;
  requestId?: Maybe<Scalars['String']>;
  status?: Maybe<PimsWritebackStatus>;
  type?: Maybe<PimsWritebackType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutIntegrationInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutIntegrationInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryUpsertWithWhereUniqueWithoutTriggeredByInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutTriggeredByInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutTriggeredByInput;
  where: PimsWritebackLogEntryWhereUniqueInput;
};

export type PimsWritebackLogEntryUpsertWithoutCareAccountCreditLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutCareAccountCreditLogInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutCareAccountCreditLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutCustomFieldsLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutCustomFieldsLogInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutCustomFieldsLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutInvoiceLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutInvoiceLogInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutInvoiceLogInput;
};

export type PimsWritebackLogEntryUpsertWithoutPaymentLogInput = {
  create: PimsWritebackLogEntryUncheckedCreateWithoutPaymentLogInput;
  update: PimsWritebackLogEntryUncheckedUpdateWithoutPaymentLogInput;
};

export type PimsWritebackLogEntryWhereInput = {
  AND?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  NOT?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  OR?: Maybe<Array<PimsWritebackLogEntryWhereInput>>;
  careAccountCreditLog?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  customFieldsLog?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
  httpCode?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  invoiceLog?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
  normalizedError?: Maybe<EnumNormalizedWritebackErrorNullableFilter>;
  paymentLog?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
  pimsError?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  requestBody?: Maybe<JsonNullableFilter>;
  requestId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPimsWritebackStatusFilter>;
  triggeredBy?: Maybe<UserWhereInput>;
  triggeredByUserId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumPimsWritebackTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PimsWritebackLogEntryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PimsWritebackStatus {
  Failure = 'Failure',
  Pending = 'Pending',
  PendingRetryAutomatic = 'Pending_RetryAutomatic',
  PendingRetryManual = 'Pending_RetryManual',
  Success = 'Success'
}

export enum PimsWritebackType {
  CustomField = 'CustomField',
  Payment = 'Payment'
}


export type Prescription = {
  __typename?: 'Prescription';
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployee>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type PrescriptionAvgAggregateOutputType = {
  __typename?: 'PrescriptionAvgAggregateOutputType';
  currentRefillNumber?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  totalRefills?: Maybe<Scalars['Float']>;
};

export type PrescriptionAvgOrderByAggregateInput = {
  currentRefillNumber?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionCountAggregateOutputType = {
  __typename?: 'PrescriptionCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  comments: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentRefillNumber: Scalars['Int'];
  description: Scalars['Int'];
  expiredAt: Scalars['Int'];
  id: Scalars['Int'];
  instructions: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  issuedAt: Scalars['Int'];
  lastRequestedAt: Scalars['Int'];
  pimsId: Scalars['Int'];
  prescribingDoctorId: Scalars['Int'];
  quantity: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  serviceId: Scalars['Int'];
  totalRefills: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PrescriptionCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionCreateInput = {
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyClinicInput = {
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyClinicInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyClinicPetInput = {
  clinicId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyClinicPetInputEnvelope = {
  data: Array<PrescriptionCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyIntegrationInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyIntegrationInputEnvelope = {
  data: Array<PrescriptionCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyPrescribingDoctorInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyPrescribingDoctorInputEnvelope = {
  data: Array<PrescriptionCreateManyPrescribingDoctorInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyServiceInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyServiceInputEnvelope = {
  data: Array<PrescriptionCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutPrescribingDoctorInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
};

export type PrescriptionCreateOrConnectWithoutClinicInput = {
  create: PrescriptionUncheckedCreateWithoutClinicInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutClinicPetInput = {
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutIntegrationInput = {
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutPrescribingDoctorInput = {
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutServiceInput = {
  create: PrescriptionUncheckedCreateWithoutServiceInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateWithoutClinicInput = {
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutClinicPetInput = {
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutIntegrationInput = {
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutPrescribingDoctorInput = {
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceCreateNestedOneWithoutPrescriptionsInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutServiceInput = {
  clinic: ClinicCreateNestedOneWithoutPrescriptionsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutPrescriptionsInput;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutPrescriptionsInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctor?: Maybe<ClinicEmployeeCreateNestedOneWithoutPrescriptionsInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionListRelationFilter = {
  every?: Maybe<PrescriptionWhereInput>;
  none?: Maybe<PrescriptionWhereInput>;
  some?: Maybe<PrescriptionWhereInput>;
};

export type PrescriptionMaxAggregateOutputType = {
  __typename?: 'PrescriptionMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionMinAggregateOutputType = {
  __typename?: 'PrescriptionMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PrescriptionOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  Comments = 'comments',
  Description = 'description',
  Id = 'id',
  Instructions = 'instructions',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  PrescribingDoctorId = 'prescribingDoctorId',
  ServiceId = 'serviceId'
}

export type PrescriptionOrderByRelevanceInput = {
  fields: Array<PrescriptionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PrescriptionOrderByWithAggregationInput = {
  _avg?: Maybe<PrescriptionAvgOrderByAggregateInput>;
  _count?: Maybe<PrescriptionCountOrderByAggregateInput>;
  _max?: Maybe<PrescriptionMaxOrderByAggregateInput>;
  _min?: Maybe<PrescriptionMinOrderByAggregateInput>;
  _sum?: Maybe<PrescriptionSumOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescribingDoctorId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PrescriptionOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currentRefillNumber?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  instructions?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  issuedAt?: Maybe<SortOrder>;
  lastRequestedAt?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  prescribingDoctor?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  prescribingDoctorId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export enum PrescriptionScalarFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  Comments = 'comments',
  CreatedAt = 'createdAt',
  CurrentRefillNumber = 'currentRefillNumber',
  Description = 'description',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  Instructions = 'instructions',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IssuedAt = 'issuedAt',
  LastRequestedAt = 'lastRequestedAt',
  PimsId = 'pimsId',
  PrescribingDoctorId = 'prescribingDoctorId',
  Quantity = 'quantity',
  RawPimsValue = 'rawPimsValue',
  ServiceId = 'serviceId',
  TotalRefills = 'totalRefills',
  UpdatedAt = 'updatedAt'
}

export type PrescriptionScalarWhereInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  comments?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentRefillNumber?: Maybe<IntNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  instructions?: Maybe<StringNullableFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  serviceId?: Maybe<StringNullableFilter>;
  totalRefills?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PrescriptionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  comments?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentRefillNumber?: Maybe<IntNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  instructions?: Maybe<StringNullableWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  issuedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  prescribingDoctorId?: Maybe<StringNullableWithAggregatesFilter>;
  quantity?: Maybe<FloatNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  serviceId?: Maybe<StringNullableWithAggregatesFilter>;
  totalRefills?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PrescriptionSumAggregateOutputType = {
  __typename?: 'PrescriptionSumAggregateOutputType';
  currentRefillNumber?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  totalRefills?: Maybe<Scalars['Int']>;
};

export type PrescriptionSumOrderByAggregateInput = {
  currentRefillNumber?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  totalRefills?: Maybe<SortOrder>;
};

export type PrescriptionUncheckedCreateInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutPrescribingDoctorInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
};

export type PrescriptionUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
};

export type PrescriptionUncheckedCreateWithoutClinicInput = {
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutClinicPetInput = {
  clinicId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutIntegrationInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutPrescribingDoctorInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedCreateWithoutServiceInput = {
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId: Scalars['String'];
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescribingDoctorNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
};

export type PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type PrescriptionUncheckedUpdateWithoutClinicInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutClinicPetInput = {
  clinicId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutIntegrationInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUncheckedUpdateWithoutServiceInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctorId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateManyMutationInput = {
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicInput = {
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutClinicPetInput = {
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutIntegrationInput = {
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput = {
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutServiceInput = {
  data: PrescriptionUncheckedUpdateManyWithoutPrescriptionsInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PrescriptionUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutClinicPetInput>>;
  createMany?: Maybe<PrescriptionCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type PrescriptionUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutIntegrationInput>>;
  createMany?: Maybe<PrescriptionCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type PrescriptionUpdateManyWithoutPrescribingDoctorNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPrescribingDoctorInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPrescribingDoctorInput>>;
  createMany?: Maybe<PrescriptionCreateManyPrescribingDoctorInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPrescribingDoctorInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput>>;
};

export type PrescriptionUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutServiceInput>>;
  createMany?: Maybe<PrescriptionCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicInput = {
  data: PrescriptionUncheckedUpdateWithoutClinicInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: PrescriptionUncheckedUpdateWithoutClinicPetInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: PrescriptionUncheckedUpdateWithoutIntegrationInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutPrescribingDoctorInput = {
  data: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutServiceInput = {
  data: PrescriptionUncheckedUpdateWithoutServiceInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithoutClinicInput = {
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutClinicPetInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutIntegrationInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutPrescribingDoctorInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneWithoutPrescriptionsNestedInput>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutServiceInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefillNumber?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutPrescriptionsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  lastRequestedAt?: Maybe<Scalars['DateTime']>;
  pimsId?: Maybe<Scalars['String']>;
  prescribingDoctor?: Maybe<ClinicEmployeeUpdateOneWithoutPrescriptionsNestedInput>;
  quantity?: Maybe<Scalars['Float']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  totalRefills?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicInput = {
  create: PrescriptionUncheckedCreateWithoutClinicInput;
  update: PrescriptionUncheckedUpdateWithoutClinicInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: PrescriptionUncheckedCreateWithoutClinicPetInput;
  update: PrescriptionUncheckedUpdateWithoutClinicPetInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: PrescriptionUncheckedCreateWithoutIntegrationInput;
  update: PrescriptionUncheckedUpdateWithoutIntegrationInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutPrescribingDoctorInput = {
  create: PrescriptionUncheckedCreateWithoutPrescribingDoctorInput;
  update: PrescriptionUncheckedUpdateWithoutPrescribingDoctorInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutServiceInput = {
  create: PrescriptionUncheckedCreateWithoutServiceInput;
  update: PrescriptionUncheckedUpdateWithoutServiceInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionWhereInput = {
  AND?: Maybe<Array<PrescriptionWhereInput>>;
  NOT?: Maybe<Array<PrescriptionWhereInput>>;
  OR?: Maybe<Array<PrescriptionWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  comments?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentRefillNumber?: Maybe<IntNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  instructions?: Maybe<StringNullableFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  issuedAt?: Maybe<DateTimeNullableFilter>;
  lastRequestedAt?: Maybe<DateTimeNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  prescribingDoctor?: Maybe<ClinicEmployeeWhereInput>;
  prescribingDoctorId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<FloatNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringNullableFilter>;
  totalRefills?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PrescriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<PrescriptionPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum PricingModel {
  BlendedRate = 'BlendedRate',
  InterchangePlus = 'InterchangePlus'
}

export type PrivacyResponse = {
  __typename?: 'PrivacyResponse';
  confirmation: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ProcessTerminalPaymentResponse = {
  __typename?: 'ProcessTerminalPaymentResponse';
  error?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  terminal: StripeTerminal;
};

export type Product = {
  __typename?: 'Product';
  _count: ProductCountOutputType;
  activeIngredients: Array<ActiveIngredient>;
  brand: Scalars['String'];
  canonicalService: CanonicalService;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  indications: Array<ProductIndication>;
  informPartner?: Maybe<InformPartner>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media: Array<Media>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<Product>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  variants: Array<Product>;
};


export type ProductActiveIngredientsArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  distinct?: Maybe<ActiveIngredientScalarFieldEnum>;
  orderBy?: Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type ProductMediaArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<MediaScalarFieldEnum>;
  orderBy?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type ProductVariantsArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<ProductScalarFieldEnum>;
  orderBy?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};

export type ProductAvgAggregateOutputType = {
  __typename?: 'ProductAvgAggregateOutputType';
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  supplyDuration?: Maybe<Scalars['Float']>;
};

export type ProductAvgOrderByAggregateInput = {
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
};

export type ProductCountAggregateOutputType = {
  __typename?: 'ProductCountAggregateOutputType';
  _all: Scalars['Int'];
  brand: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  details: Scalars['Int'];
  id: Scalars['Int'];
  indications: Scalars['Int'];
  informPartnerId: Scalars['Int'];
  manufacturer: Scalars['Int'];
  maxWeightInKg: Scalars['Int'];
  minWeightInKg: Scalars['Int'];
  name: Scalars['Int'];
  parentProductId: Scalars['Int'];
  quantity: Scalars['Int'];
  supplyDuration: Scalars['Int'];
  supplyDurationUnit: Scalars['Int'];
  type: Scalars['Int'];
  universalProductCode: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProductCountOrderByAggregateInput = {
  brand?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProductCountOutputType = {
  __typename?: 'ProductCountOutputType';
  activeIngredients: Scalars['Int'];
  media: Scalars['Int'];
  variants: Scalars['Int'];
};

export type ProductCreateInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateManyInformPartnerInput = {
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateManyInformPartnerInputEnvelope = {
  data: Array<ProductCreateManyInformPartnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateManyInput = {
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateManyParentInput = {
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateManyParentInputEnvelope = {
  data: Array<ProductCreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
};

export type ProductCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
};

export type ProductCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
};

export type ProductCreateNestedOneWithoutActiveIngredientsInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutActiveIngredientsInput>;
  create?: Maybe<ProductUncheckedCreateWithoutActiveIngredientsInput>;
};

export type ProductCreateNestedOneWithoutCanonicalServiceInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
};

export type ProductCreateNestedOneWithoutVariantsInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: Maybe<ProductUncheckedCreateWithoutVariantsInput>;
};

export type ProductCreateOrConnectWithoutActiveIngredientsInput = {
  create: ProductUncheckedCreateWithoutActiveIngredientsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutCanonicalServiceInput = {
  create: ProductUncheckedCreateWithoutCanonicalServiceInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutInformPartnerInput = {
  create: ProductUncheckedCreateWithoutInformPartnerInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutMediaInput = {
  create: ProductUncheckedCreateWithoutMediaInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutParentInput = {
  create: ProductUncheckedCreateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutVariantsInput = {
  create: ProductUncheckedCreateWithoutVariantsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutActiveIngredientsInput = {
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateWithoutCanonicalServiceInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateWithoutInformPartnerInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateWithoutMediaInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateWithoutParentInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductCreateNestedManyWithoutParentInput>;
};

export type ProductCreateWithoutVariantsInput = {
  activeIngredients?: Maybe<ActiveIngredientCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutProductInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerCreateNestedOneWithoutProductsInput>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<ProductCreateNestedOneWithoutVariantsInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCreateindicationsInput = {
  set: Array<ProductIndication>;
};

export enum ProductIndication {
  Combo = 'COMBO',
  CongestiveHeartFailure = 'CongestiveHeartFailure',
  Dental = 'Dental',
  DigestiveCare = 'DigestiveCare',
  Fleatick = 'FLEATICK',
  Flea = 'Flea',
  HeartDisease = 'HEART_DISEASE',
  Heartworm = 'Heartworm',
  JointSuppThera = 'JOINT_SUPP_THERA',
  Kidney = 'Kidney',
  Nsaid = 'NSAID',
  Other = 'OTHER',
  PituitaryParsIntermediaDysfunction = 'PituitaryParsIntermediaDysfunction',
  SkinCare = 'SkinCare',
  Tick = 'Tick',
  Urinary = 'Urinary',
  Vaccine = 'VACCINE',
  WeightManagement = 'WeightManagement'
}

export type ProductListRelationFilter = {
  every?: Maybe<ProductWhereInput>;
  none?: Maybe<ProductWhereInput>;
  some?: Maybe<ProductWhereInput>;
};

export type ProductMaxAggregateOutputType = {
  __typename?: 'ProductMaxAggregateOutputType';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductMaxOrderByAggregateInput = {
  brand?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProductMinAggregateOutputType = {
  __typename?: 'ProductMinAggregateOutputType';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductMinOrderByAggregateInput = {
  brand?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ProductOrderByRelevanceFieldEnum {
  Brand = 'brand',
  Description = 'description',
  Id = 'id',
  InformPartnerId = 'informPartnerId',
  Manufacturer = 'manufacturer',
  Name = 'name',
  ParentProductId = 'parentProductId',
  UniversalProductCode = 'universalProductCode'
}

export type ProductOrderByRelevanceInput = {
  fields: Array<ProductOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ProductOrderByWithAggregationInput = {
  _avg?: Maybe<ProductAvgOrderByAggregateInput>;
  _count?: Maybe<ProductCountOrderByAggregateInput>;
  _max?: Maybe<ProductMaxOrderByAggregateInput>;
  _min?: Maybe<ProductMinOrderByAggregateInput>;
  _sum?: Maybe<ProductSumOrderByAggregateInput>;
  brand?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  informPartnerId?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentProductId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProductOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ProductOrderByRelevanceInput>;
  activeIngredients?: Maybe<ActiveIngredientOrderByRelationAggregateInput>;
  brand?: Maybe<SortOrder>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  details?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  indications?: Maybe<SortOrder>;
  informPartner?: Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerId?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  maxWeightInKg?: Maybe<SortOrder>;
  media?: Maybe<MediaOrderByRelationAggregateInput>;
  minWeightInKg?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parent?: Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>;
  parentProductId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
  supplyDurationUnit?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  universalProductCode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  variants?: Maybe<ProductOrderByRelationAggregateInput>;
};

export type ProductRelationFilter = {
  is?: Maybe<ProductWhereInput>;
  isNot?: Maybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  Brand = 'brand',
  CreatedAt = 'createdAt',
  Description = 'description',
  Details = 'details',
  Id = 'id',
  Indications = 'indications',
  InformPartnerId = 'informPartnerId',
  Manufacturer = 'manufacturer',
  MaxWeightInKg = 'maxWeightInKg',
  MinWeightInKg = 'minWeightInKg',
  Name = 'name',
  ParentProductId = 'parentProductId',
  Quantity = 'quantity',
  SupplyDuration = 'supplyDuration',
  SupplyDurationUnit = 'supplyDurationUnit',
  Type = 'type',
  UniversalProductCode = 'universalProductCode',
  UpdatedAt = 'updatedAt'
}

export type ProductScalarWhereInput = {
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  brand?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  details?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  informPartnerId?: Maybe<StringNullableFilter>;
  manufacturer?: Maybe<StringFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  name?: Maybe<StringFilter>;
  parentProductId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntNullableFilter>;
  supplyDuration?: Maybe<FloatNullableFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableFilter>;
  type?: Maybe<EnumProductTypeFilter>;
  universalProductCode?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ProductScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProductScalarWhereWithAggregatesInput>>;
  brand?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  details?: Maybe<JsonNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  informPartnerId?: Maybe<StringNullableWithAggregatesFilter>;
  manufacturer?: Maybe<StringWithAggregatesFilter>;
  maxWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  minWeightInKg?: Maybe<FloatNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  parentProductId?: Maybe<StringNullableWithAggregatesFilter>;
  quantity?: Maybe<IntNullableWithAggregatesFilter>;
  supplyDuration?: Maybe<FloatNullableWithAggregatesFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableWithAggregatesFilter>;
  type?: Maybe<EnumProductTypeWithAggregatesFilter>;
  universalProductCode?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ProductSumAggregateOutputType = {
  __typename?: 'ProductSumAggregateOutputType';
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
};

export type ProductSumOrderByAggregateInput = {
  maxWeightInKg?: Maybe<SortOrder>;
  minWeightInKg?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  supplyDuration?: Maybe<SortOrder>;
};

export enum ProductSupplyDurationUnit {
  Days = 'Days',
  Months = 'Months',
  Weeks = 'Weeks',
  Years = 'Years'
}

export enum ProductType {
  Food = 'Food',
  Medication = 'Medication'
}

export type ProductTypeNameBrandManufacturerCompoundUniqueInput = {
  brand: Scalars['String'];
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  type: ProductType;
};

export type ProductUncheckedCreateInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateNestedManyWithoutInformPartnerInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
};

export type ProductUncheckedCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
};

export type ProductUncheckedCreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
};

export type ProductUncheckedCreateNestedOneWithoutCanonicalServiceInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
};

export type ProductUncheckedCreateWithoutActiveIngredientsInput = {
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateWithoutCanonicalServiceInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateWithoutInformPartnerInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateWithoutMediaInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateWithoutParentInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedCreateNestedManyWithoutParentInput>;
};

export type ProductUncheckedCreateWithoutVariantsInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedCreateNestedManyWithoutProductInput>;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer: Scalars['String'];
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedCreateNestedManyWithoutProductsInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type: ProductType;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUncheckedUpdateInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateManyInput = {
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUncheckedUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type ProductUncheckedUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ProductUncheckedUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutParentInput>>;
};

export type ProductUncheckedUpdateManyWithoutProductsInput = {
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUncheckedUpdateManyWithoutVariantsInput = {
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUncheckedUpdateOneWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUncheckedUpdateWithoutCanonicalServiceInput>;
  upsert?: Maybe<ProductUpsertWithoutCanonicalServiceInput>;
};

export type ProductUncheckedUpdateWithoutActiveIngredientsInput = {
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateWithoutCanonicalServiceInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateWithoutInformPartnerInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateWithoutMediaInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateWithoutParentInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUncheckedUpdateManyWithoutParentNestedInput>;
};

export type ProductUncheckedUpdateWithoutVariantsInput = {
  activeIngredients?: Maybe<ActiveIngredientUncheckedUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  id?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartnerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUncheckedUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentProductId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdateInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateManyMutationInput = {
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdateManyWithWhereWithoutInformPartnerInput = {
  data: ProductUncheckedUpdateManyWithoutProductsInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutMediaInput = {
  data: ProductUncheckedUpdateManyWithoutProductsInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutParentInput = {
  data: ProductUncheckedUpdateManyWithoutVariantsInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutInformPartnerNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutInformPartnerInput>>;
  create?: Maybe<Array<ProductCreateWithoutInformPartnerInput>>;
  createMany?: Maybe<ProductCreateManyInformPartnerInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutInformPartnerInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutInformPartnerInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutInformPartnerInput>>;
};

export type ProductUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<ProductCreateWithoutMediaInput>>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type ProductUpdateManyWithoutParentNestedInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProductCreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<ProductCreateWithoutParentInput>>;
  createMany?: Maybe<ProductCreateManyParentInputEnvelope>;
  delete?: Maybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProductScalarWhereInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  update?: Maybe<Array<ProductUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<ProductUpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<ProductUpsertWithWhereUniqueWithoutParentInput>>;
};

export type ProductUpdateOneRequiredWithoutActiveIngredientsNestedInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutActiveIngredientsInput>;
  create?: Maybe<ProductUncheckedCreateWithoutActiveIngredientsInput>;
  update?: Maybe<ProductUncheckedUpdateWithoutActiveIngredientsInput>;
  upsert?: Maybe<ProductUpsertWithoutActiveIngredientsInput>;
};

export type ProductUpdateOneWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutCanonicalServiceInput>;
  create?: Maybe<ProductUncheckedCreateWithoutCanonicalServiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUncheckedUpdateWithoutCanonicalServiceInput>;
  upsert?: Maybe<ProductUpsertWithoutCanonicalServiceInput>;
};

export type ProductUpdateOneWithoutVariantsNestedInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
  connectOrCreate?: Maybe<ProductCreateOrConnectWithoutVariantsInput>;
  create?: Maybe<ProductUncheckedCreateWithoutVariantsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProductUncheckedUpdateWithoutVariantsInput>;
  upsert?: Maybe<ProductUpsertWithoutVariantsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutInformPartnerInput = {
  data: ProductUncheckedUpdateWithoutInformPartnerInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutMediaInput = {
  data: ProductUncheckedUpdateWithoutMediaInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutParentInput = {
  data: ProductUncheckedUpdateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutActiveIngredientsInput = {
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateWithoutCanonicalServiceInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateWithoutInformPartnerInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateWithoutMediaInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateWithoutParentInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants?: Maybe<ProductUpdateManyWithoutParentNestedInput>;
};

export type ProductUpdateWithoutVariantsInput = {
  activeIngredients?: Maybe<ActiveIngredientUpdateManyWithoutProductNestedInput>;
  brand?: Maybe<Scalars['String']>;
  canonicalService?: Maybe<CanonicalServiceUpdateOneRequiredWithoutProductNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['Json']>;
  indications?: Maybe<Array<ProductIndication>>;
  informPartner?: Maybe<InformPartnerUpdateOneWithoutProductsNestedInput>;
  manufacturer?: Maybe<Scalars['String']>;
  maxWeightInKg?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaUpdateManyWithoutProductsNestedInput>;
  minWeightInKg?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ProductUpdateOneWithoutVariantsNestedInput>;
  quantity?: Maybe<Scalars['Int']>;
  supplyDuration?: Maybe<Scalars['Float']>;
  supplyDurationUnit?: Maybe<ProductSupplyDurationUnit>;
  type?: Maybe<ProductType>;
  universalProductCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductUpdateindicationsInput = {
  push?: Maybe<Array<ProductIndication>>;
  set?: Maybe<Array<ProductIndication>>;
};

export type ProductUpsertWithWhereUniqueWithoutInformPartnerInput = {
  create: ProductUncheckedCreateWithoutInformPartnerInput;
  update: ProductUncheckedUpdateWithoutInformPartnerInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutMediaInput = {
  create: ProductUncheckedCreateWithoutMediaInput;
  update: ProductUncheckedUpdateWithoutMediaInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutParentInput = {
  create: ProductUncheckedCreateWithoutParentInput;
  update: ProductUncheckedUpdateWithoutParentInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutActiveIngredientsInput = {
  create: ProductUncheckedCreateWithoutActiveIngredientsInput;
  update: ProductUncheckedUpdateWithoutActiveIngredientsInput;
};

export type ProductUpsertWithoutCanonicalServiceInput = {
  create: ProductUncheckedCreateWithoutCanonicalServiceInput;
  update: ProductUncheckedUpdateWithoutCanonicalServiceInput;
};

export type ProductUpsertWithoutVariantsInput = {
  create: ProductUncheckedCreateWithoutVariantsInput;
  update: ProductUncheckedUpdateWithoutVariantsInput;
};

export type ProductWhereInput = {
  AND?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  activeIngredients?: Maybe<ActiveIngredientListRelationFilter>;
  brand?: Maybe<StringFilter>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  details?: Maybe<JsonNullableFilter>;
  id?: Maybe<StringFilter>;
  indications?: Maybe<EnumProductIndicationNullableListFilter>;
  informPartner?: Maybe<InformPartnerWhereInput>;
  informPartnerId?: Maybe<StringNullableFilter>;
  manufacturer?: Maybe<StringFilter>;
  maxWeightInKg?: Maybe<FloatNullableFilter>;
  media?: Maybe<MediaListRelationFilter>;
  minWeightInKg?: Maybe<FloatNullableFilter>;
  name?: Maybe<StringFilter>;
  parent?: Maybe<ProductWhereInput>;
  parentProductId?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntNullableFilter>;
  supplyDuration?: Maybe<FloatNullableFilter>;
  supplyDurationUnit?: Maybe<EnumProductSupplyDurationUnitNullableFilter>;
  type?: Maybe<EnumProductTypeFilter>;
  universalProductCode?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  variants?: Maybe<ProductListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  type_name_brand_manufacturer?: Maybe<ProductTypeNameBrandManufacturerCompoundUniqueInput>;
};

export enum ProfilePimsSyncStatus {
  Error = 'Error',
  InProgress = 'InProgress',
  Queued = 'Queued',
  Success = 'Success'
}

/** @deprecated - 1.0 */
export type PromoCode = {
  __typename?: 'PromoCode';
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromoCodeAvgAggregateOutputType = {
  __typename?: 'PromoCodeAvgAggregateOutputType';
  amountAvailable?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PromoCodeAvgOrderByAggregateInput = {
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
};

export type PromoCodeCountAggregateOutputType = {
  __typename?: 'PromoCodeCountAggregateOutputType';
  _all: Scalars['Int'];
  amountAvailable: Scalars['Int'];
  amountUsed: Scalars['Int'];
  clinicId: Scalars['Int'];
  code: Scalars['Int'];
  created: Scalars['Int'];
  createdAt: Scalars['Int'];
  discount: Scalars['Int'];
  expiresAt: Scalars['Int'];
  firebaseId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PromoCodeCountOrderByAggregateInput = {
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PromoCodeCreateInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutPromoCodesInput>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeCreateManyClinicInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeCreateManyClinicInputEnvelope = {
  data: Array<PromoCodeCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PromoCodeCreateManyInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
};

export type PromoCodeCreateOrConnectWithoutClinicInput = {
  create: PromoCodeUncheckedCreateWithoutClinicInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateWithoutClinicInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeListRelationFilter = {
  every?: Maybe<PromoCodeWhereInput>;
  none?: Maybe<PromoCodeWhereInput>;
  some?: Maybe<PromoCodeWhereInput>;
};

export type PromoCodeMaxAggregateOutputType = {
  __typename?: 'PromoCodeMaxAggregateOutputType';
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeMaxOrderByAggregateInput = {
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PromoCodeMinAggregateOutputType = {
  __typename?: 'PromoCodeMinAggregateOutputType';
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeMinOrderByAggregateInput = {
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PromoCodeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PromoCodeOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Code = 'code',
  FirebaseId = 'firebaseId',
  Id = 'id'
}

export type PromoCodeOrderByRelevanceInput = {
  fields: Array<PromoCodeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PromoCodeOrderByWithAggregationInput = {
  _avg?: Maybe<PromoCodeAvgOrderByAggregateInput>;
  _count?: Maybe<PromoCodeCountOrderByAggregateInput>;
  _max?: Maybe<PromoCodeMaxOrderByAggregateInput>;
  _min?: Maybe<PromoCodeMinOrderByAggregateInput>;
  _sum?: Maybe<PromoCodeSumOrderByAggregateInput>;
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PromoCodeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PromoCodeOrderByRelevanceInput>;
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  created?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
  expiresAt?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PromoCodeScalarFieldEnum {
  AmountAvailable = 'amountAvailable',
  AmountUsed = 'amountUsed',
  ClinicId = 'clinicId',
  Code = 'code',
  Created = 'created',
  CreatedAt = 'createdAt',
  Discount = 'discount',
  ExpiresAt = 'expiresAt',
  FirebaseId = 'firebaseId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type PromoCodeScalarWhereInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereInput>>;
  amountAvailable?: Maybe<IntNullableFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoCodeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PromoCodeScalarWhereWithAggregatesInput>>;
  amountAvailable?: Maybe<IntNullableWithAggregatesFilter>;
  amountUsed?: Maybe<IntNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  created?: Maybe<DateTimeWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  discount?: Maybe<FloatWithAggregatesFilter>;
  expiresAt?: Maybe<DateTimeWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PromoCodeSumAggregateOutputType = {
  __typename?: 'PromoCodeSumAggregateOutputType';
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PromoCodeSumOrderByAggregateInput = {
  amountAvailable?: Maybe<SortOrder>;
  amountUsed?: Maybe<SortOrder>;
  discount?: Maybe<SortOrder>;
};

export type PromoCodeUncheckedCreateInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
};

export type PromoCodeUncheckedCreateWithoutClinicInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  created: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  discount: Scalars['Float'];
  expiresAt: Scalars['DateTime'];
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUncheckedUpdateInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUncheckedUpdateManyInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinicId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PromoCodeUncheckedUpdateManyWithoutPromoCodesInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUncheckedUpdateWithoutClinicInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUpdateInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  clinic?: Maybe<ClinicUpdateOneWithoutPromoCodesNestedInput>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUpdateManyMutationInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUpdateManyWithWhereWithoutClinicInput = {
  data: PromoCodeUncheckedUpdateManyWithoutPromoCodesInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PromoCodeCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<PromoCodeCreateWithoutClinicInput>>;
  createMany?: Maybe<PromoCodeCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  set?: Maybe<Array<PromoCodeWhereUniqueInput>>;
  update?: Maybe<Array<PromoCodeUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<PromoCodeUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<PromoCodeUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type PromoCodeUpdateWithWhereUniqueWithoutClinicInput = {
  data: PromoCodeUncheckedUpdateWithoutClinicInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithoutClinicInput = {
  amountAvailable?: Maybe<Scalars['Int']>;
  amountUsed?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PromoCodeUpsertWithWhereUniqueWithoutClinicInput = {
  create: PromoCodeUncheckedCreateWithoutClinicInput;
  update: PromoCodeUncheckedUpdateWithoutClinicInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeWhereInput = {
  AND?: Maybe<Array<PromoCodeWhereInput>>;
  NOT?: Maybe<Array<PromoCodeWhereInput>>;
  OR?: Maybe<Array<PromoCodeWhereInput>>;
  amountAvailable?: Maybe<IntNullableFilter>;
  amountUsed?: Maybe<IntNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  created?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  discount?: Maybe<FloatFilter>;
  expiresAt?: Maybe<DateTimeFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PromoCodeWhereUniqueInput = {
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ProviderAppointmentTypeRoomMap = {
  __typename?: 'ProviderAppointmentTypeRoomMap';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  appointmentType?: Maybe<AppointmentType>;
  clinicEmployee: ClinicEmployee;
  clinicEmployeeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  room: ClinicRoom;
  roomId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProviderAppointmentTypeRoomMapClinicEmployeeIdAppoinmentTypeIdRoomIdCompoundUniqueInput = {
  appoinmentTypeId: Scalars['String'];
  clinicEmployeeId: Scalars['String'];
  roomId: Scalars['String'];
};

export type ProviderAppointmentTypeRoomMapCountAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapCountAggregateOutputType';
  _all: Scalars['Int'];
  appoinmentTypeId: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  roomId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ProviderAppointmentTypeRoomMapCountOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapCreateInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyRoomInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope = {
  data: Array<ProviderAppointmentTypeRoomMapCreateManyRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room: ClinicRoomCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapCreateWithoutRoomInput = {
  appointmentType?: Maybe<AppointmentTypeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput>;
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutProviderAppointmentTypeRoomMapInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapListRelationFilter = {
  every?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  none?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
  some?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};

export type ProviderAppointmentTypeRoomMapMaxAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapMaxAggregateOutputType';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapMaxOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapMinAggregateOutputType = {
  __typename?: 'ProviderAppointmentTypeRoomMapMinAggregateOutputType';
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapMinOrderByAggregateInput = {
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ProviderAppointmentTypeRoomMapOrderByRelevanceFieldEnum {
  AppoinmentTypeId = 'appoinmentTypeId',
  ClinicEmployeeId = 'clinicEmployeeId',
  Id = 'id',
  RoomId = 'roomId'
}

export type ProviderAppointmentTypeRoomMapOrderByRelevanceInput = {
  fields: Array<ProviderAppointmentTypeRoomMapOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ProviderAppointmentTypeRoomMapOrderByWithAggregationInput = {
  _count?: Maybe<ProviderAppointmentTypeRoomMapCountOrderByAggregateInput>;
  _max?: Maybe<ProviderAppointmentTypeRoomMapMaxOrderByAggregateInput>;
  _min?: Maybe<ProviderAppointmentTypeRoomMapMinOrderByAggregateInput>;
  appoinmentTypeId?: Maybe<SortOrder>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ProviderAppointmentTypeRoomMapOrderByRelevanceInput>;
  appoinmentTypeId?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  room?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  roomId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ProviderAppointmentTypeRoomMapScalarFieldEnum {
  AppoinmentTypeId = 'appoinmentTypeId',
  ClinicEmployeeId = 'clinicEmployeeId',
  CreatedAt = 'createdAt',
  Id = 'id',
  RoomId = 'roomId',
  UpdatedAt = 'updatedAt'
}

export type ProviderAppointmentTypeRoomMapScalarWhereInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  roomId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereWithAggregatesInput>>;
  appoinmentTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  roomId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateNestedManyWithoutRoomInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput = {
  appoinmentTypeId?: Maybe<Scalars['String']>;
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateManyWithoutProviderAppointmentTypeRoomMapInput;
  where: ProviderAppointmentTypeRoomMapScalarWhereInput;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateManyWithoutRoomNestedInput = {
  connect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateOrConnectWithoutRoomInput>>;
  create?: Maybe<Array<ProviderAppointmentTypeRoomMapCreateWithoutRoomInput>>;
  createMany?: Maybe<ProviderAppointmentTypeRoomMapCreateManyRoomInputEnvelope>;
  delete?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProviderAppointmentTypeRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput>>;
  updateMany?: Maybe<Array<ProviderAppointmentTypeRoomMapUpdateManyWithWhereWithoutRoomInput>>;
  upsert?: Maybe<Array<ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput>>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithWhereUniqueWithoutRoomInput = {
  data: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutAppointmentTypeInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutClinicEmployeeInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  room?: Maybe<ClinicRoomUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpdateWithoutRoomInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneWithoutProviderAppointmentTypeRoomMapNestedInput>;
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutProviderAppointmentTypeRoomMapNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutAppointmentTypeInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutAppointmentTypeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutClinicEmployeeInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutClinicEmployeeInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapUpsertWithWhereUniqueWithoutRoomInput = {
  create: ProviderAppointmentTypeRoomMapUncheckedCreateWithoutRoomInput;
  update: ProviderAppointmentTypeRoomMapUncheckedUpdateWithoutRoomInput;
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};

export type ProviderAppointmentTypeRoomMapWhereInput = {
  AND?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  NOT?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  OR?: Maybe<Array<ProviderAppointmentTypeRoomMapWhereInput>>;
  appoinmentTypeId?: Maybe<StringNullableFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  room?: Maybe<ClinicRoomWhereInput>;
  roomId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ProviderAppointmentTypeRoomMapWhereUniqueInput = {
  clinicEmployeeId_appoinmentTypeId_roomId?: Maybe<ProviderAppointmentTypeRoomMapClinicEmployeeIdAppoinmentTypeIdRoomIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum PushNotificationDeliveryErrorType {
  AuthorizationError = 'AuthorizationError',
  EndpointDisabled = 'EndpointDisabled',
  InternalError = 'InternalError',
  InvalidParameter = 'InvalidParameter',
  Unknown = 'Unknown'
}

export type PushNotificationLog = {
  __typename?: 'PushNotificationLog';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDevice: ClinicPetParentDevice;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Our categorization of the error */
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id: Scalars['String'];
  /** the error message given by the provider */
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessage>;
  /** optionally included: the channel associated with this notification */
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefund>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PushNotificationLogCountAggregateOutputType = {
  __typename?: 'PushNotificationLogCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  clinicPetParentDeviceId: Scalars['Int'];
  clinicWidgetRequestId: Scalars['Int'];
  createdAt: Scalars['Int'];
  errorType: Scalars['Int'];
  id: Scalars['Int'];
  providerErrorData: Scalars['Int'];
  sentChannelMessageId: Scalars['Int'];
  status: Scalars['Int'];
  stripeRefundId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PushNotificationLogCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PushNotificationLogCreateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManyAppointmentInput = {
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManyAppointmentInputEnvelope = {
  data: Array<PushNotificationLogCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyClinicPetParentDeviceInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope = {
  data: Array<PushNotificationLogCreateManyClinicPetParentDeviceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope = {
  data: Array<PushNotificationLogCreateManyClinicWidgetRequestInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManySentChannelMessageInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManySentChannelMessageInputEnvelope = {
  data: Array<PushNotificationLogCreateManySentChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateManyStripeRefundInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateManyStripeRefundInputEnvelope = {
  data: Array<PushNotificationLogCreateManyStripeRefundInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PushNotificationLogCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
};

export type PushNotificationLogCreateNestedManyWithoutClinicPetParentDeviceInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
};

export type PushNotificationLogCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
};

export type PushNotificationLogCreateNestedManyWithoutSentChannelMessageInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
};

export type PushNotificationLogCreateNestedManyWithoutStripeRefundInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
};

export type PushNotificationLogCreateOrConnectWithoutAppointmentInput = {
  create: PushNotificationLogUncheckedCreateWithoutAppointmentInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput = {
  create: PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput = {
  create: PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput = {
  create: PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogCreateOrConnectWithoutStripeRefundInput = {
  create: PushNotificationLogUncheckedCreateWithoutStripeRefundInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogCreateWithoutAppointmentInput = {
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateWithoutClinicPetParentDeviceInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateWithoutClinicWidgetRequestInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateWithoutSentChannelMessageInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefund?: Maybe<StripeRefundCreateNestedOneWithoutPushNotificationLogsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogCreateWithoutStripeRefundInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPushNotificationLogsInput>;
  clinicPetParentDevice: ClinicPetParentDeviceCreateNestedOneWithoutPushNotificationLogsInput;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestCreateNestedOneWithoutPushNotificationLogsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutPushNotificationLogsInput>;
  status: PushNotificationLogStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogListRelationFilter = {
  every?: Maybe<PushNotificationLogWhereInput>;
  none?: Maybe<PushNotificationLogWhereInput>;
  some?: Maybe<PushNotificationLogWhereInput>;
};

export type PushNotificationLogMaxAggregateOutputType = {
  __typename?: 'PushNotificationLogMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PushNotificationLogMinAggregateOutputType = {
  __typename?: 'PushNotificationLogMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PushNotificationLogOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PushNotificationLogOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  ClinicPetParentDeviceId = 'clinicPetParentDeviceId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  Id = 'id',
  ProviderErrorData = 'providerErrorData',
  SentChannelMessageId = 'sentChannelMessageId',
  StripeRefundId = 'stripeRefundId'
}

export type PushNotificationLogOrderByRelevanceInput = {
  fields: Array<PushNotificationLogOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type PushNotificationLogOrderByWithAggregationInput = {
  _count?: Maybe<PushNotificationLogCountOrderByAggregateInput>;
  _max?: Maybe<PushNotificationLogMaxOrderByAggregateInput>;
  _min?: Maybe<PushNotificationLogMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeRefundId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PushNotificationLogOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<PushNotificationLogOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentDeviceId?: Maybe<SortOrder>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>;
  clinicWidgetRequestId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  sentChannelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  sentChannelMessageId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeRefund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  stripeRefundId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PushNotificationLogScalarFieldEnum {
  AppointmentId = 'appointmentId',
  ClinicPetParentDeviceId = 'clinicPetParentDeviceId',
  ClinicWidgetRequestId = 'clinicWidgetRequestId',
  CreatedAt = 'createdAt',
  ErrorType = 'errorType',
  Id = 'id',
  ProviderErrorData = 'providerErrorData',
  SentChannelMessageId = 'sentChannelMessageId',
  Status = 'status',
  StripeRefundId = 'stripeRefundId',
  UpdatedAt = 'updatedAt'
}

export type PushNotificationLogScalarWhereInput = {
  AND?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  NOT?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  OR?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  clinicPetParentDeviceId?: Maybe<StringFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableFilter>;
  id?: Maybe<StringFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeFilter>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PushNotificationLogScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PushNotificationLogScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentDeviceId?: Maybe<StringWithAggregatesFilter>;
  clinicWidgetRequestId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  providerErrorData?: Maybe<StringNullableWithAggregatesFilter>;
  sentChannelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeWithAggregatesFilter>;
  stripeRefundId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum PushNotificationLogStatusType {
  Error = 'Error',
  Sent = 'Sent'
}

export type PushNotificationLogUncheckedCreateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutClinicPetParentDeviceInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutClinicWidgetRequestInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutSentChannelMessageInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
};

export type PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
};

export type PushNotificationLogUncheckedCreateWithoutAppointmentInput = {
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedCreateWithoutStripeRefundInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId: Scalars['String'];
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status: PushNotificationLogStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutClinicPetParentDeviceNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput = {
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutSentChannelMessageNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
};

export type PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput>>;
};

export type PushNotificationLogUncheckedUpdateWithoutAppointmentInput = {
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefundId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUncheckedUpdateWithoutStripeRefundInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicPetParentDeviceId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput = {
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
  where: PushNotificationLogScalarWhereInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput = {
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
  where: PushNotificationLogScalarWhereInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput = {
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
  where: PushNotificationLogScalarWhereInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput = {
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
  where: PushNotificationLogScalarWhereInput;
};

export type PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput = {
  data: PushNotificationLogUncheckedUpdateManyWithoutPushNotificationLogsInput;
  where: PushNotificationLogScalarWhereInput;
};

export type PushNotificationLogUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutAppointmentInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type PushNotificationLogUpdateManyWithoutClinicPetParentDeviceNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicPetParentDeviceInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicPetParentDeviceInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicPetParentDeviceInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicPetParentDeviceInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput>>;
};

export type PushNotificationLogUpdateManyWithoutClinicWidgetRequestNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutClinicWidgetRequestInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutClinicWidgetRequestInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyClinicWidgetRequestInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutClinicWidgetRequestInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput>>;
};

export type PushNotificationLogUpdateManyWithoutSentChannelMessageNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<PushNotificationLogCreateManySentChannelMessageInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
};

export type PushNotificationLogUpdateManyWithoutStripeRefundNestedInput = {
  connect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PushNotificationLogCreateOrConnectWithoutStripeRefundInput>>;
  create?: Maybe<Array<PushNotificationLogCreateWithoutStripeRefundInput>>;
  createMany?: Maybe<PushNotificationLogCreateManyStripeRefundInputEnvelope>;
  delete?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PushNotificationLogScalarWhereInput>>;
  disconnect?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  set?: Maybe<Array<PushNotificationLogWhereUniqueInput>>;
  update?: Maybe<Array<PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput>>;
  updateMany?: Maybe<Array<PushNotificationLogUpdateManyWithWhereWithoutStripeRefundInput>>;
  upsert?: Maybe<Array<PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput>>;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: PushNotificationLogUncheckedUpdateWithoutAppointmentInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutClinicPetParentDeviceInput = {
  data: PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutClinicWidgetRequestInput = {
  data: PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutSentChannelMessageInput = {
  data: PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpdateWithWhereUniqueWithoutStripeRefundInput = {
  data: PushNotificationLogUncheckedUpdateWithoutStripeRefundInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpdateWithoutAppointmentInput = {
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateWithoutClinicPetParentDeviceInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateWithoutClinicWidgetRequestInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateWithoutSentChannelMessageInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  status?: Maybe<PushNotificationLogStatusType>;
  stripeRefund?: Maybe<StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpdateWithoutStripeRefundInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutPushNotificationLogsNestedInput>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceUpdateOneRequiredWithoutPushNotificationLogsNestedInput>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestUpdateOneWithoutPushNotificationLogsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorType?: Maybe<PushNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  providerErrorData?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutPushNotificationLogsNestedInput>;
  status?: Maybe<PushNotificationLogStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: PushNotificationLogUncheckedCreateWithoutAppointmentInput;
  update: PushNotificationLogUncheckedUpdateWithoutAppointmentInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutClinicPetParentDeviceInput = {
  create: PushNotificationLogUncheckedCreateWithoutClinicPetParentDeviceInput;
  update: PushNotificationLogUncheckedUpdateWithoutClinicPetParentDeviceInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutClinicWidgetRequestInput = {
  create: PushNotificationLogUncheckedCreateWithoutClinicWidgetRequestInput;
  update: PushNotificationLogUncheckedUpdateWithoutClinicWidgetRequestInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutSentChannelMessageInput = {
  create: PushNotificationLogUncheckedCreateWithoutSentChannelMessageInput;
  update: PushNotificationLogUncheckedUpdateWithoutSentChannelMessageInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogUpsertWithWhereUniqueWithoutStripeRefundInput = {
  create: PushNotificationLogUncheckedCreateWithoutStripeRefundInput;
  update: PushNotificationLogUncheckedUpdateWithoutStripeRefundInput;
  where: PushNotificationLogWhereUniqueInput;
};

export type PushNotificationLogWhereInput = {
  AND?: Maybe<Array<PushNotificationLogWhereInput>>;
  NOT?: Maybe<Array<PushNotificationLogWhereInput>>;
  OR?: Maybe<Array<PushNotificationLogWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  clinicPetParentDevice?: Maybe<ClinicPetParentDeviceWhereInput>;
  clinicPetParentDeviceId?: Maybe<StringFilter>;
  clinicWidgetRequest?: Maybe<ClinicWidgetRequestWhereInput>;
  clinicWidgetRequestId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errorType?: Maybe<EnumPushNotificationDeliveryErrorTypeNullableFilter>;
  id?: Maybe<StringFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  sentChannelMessage?: Maybe<ChannelMessageWhereInput>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPushNotificationLogStatusTypeFilter>;
  stripeRefund?: Maybe<StripeRefundWhereInput>;
  stripeRefundId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PushNotificationLogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PushNotificationStatus {
  Blocked = 'BLOCKED',
  Denied = 'DENIED',
  Granted = 'GRANTED',
  Limited = 'LIMITED',
  Unavailable = 'UNAVAILABLE',
  Undetermined = 'UNDETERMINED'
}

export type Query = {
  __typename?: 'Query';
  aggregateActiveIngredient?: Maybe<AggregateActiveIngredient>;
  aggregateAppointment?: Maybe<AggregateAppointment>;
  aggregateAppointmentAvailability?: Maybe<AggregateAppointmentAvailability>;
  aggregateAppointmentType?: Maybe<AggregateAppointmentType>;
  aggregateAutomationRun?: Maybe<AggregateAutomationRun>;
  aggregateAutomationRunAction?: Maybe<AggregateAutomationRunAction>;
  aggregateBitwerxHealthcheck?: Maybe<AggregateBitwerxHealthcheck>;
  aggregateBitwerxIntegration?: Maybe<AggregateBitwerxIntegration>;
  aggregateBitwerxPaymentHealthcheckResult?: Maybe<AggregateBitwerxPaymentHealthcheckResult>;
  aggregateBitwerxPaymentHealthcheckRun?: Maybe<AggregateBitwerxPaymentHealthcheckRun>;
  aggregateBoardFilterColumn?: Maybe<AggregateBoardFilterColumn>;
  aggregateBoardFilterSpecificDateRange?: Maybe<AggregateBoardFilterSpecificDateRange>;
  aggregateBoehringerIngelheimCustomerAlignment?: Maybe<AggregateBoehringerIngelheimCustomerAlignment>;
  aggregateBoehringerIngelheimTerritory?: Maybe<AggregateBoehringerIngelheimTerritory>;
  aggregateBreed?: Maybe<AggregateBreed>;
  aggregateCallHistory?: Maybe<AggregateCallHistory>;
  aggregateCallParticipant?: Maybe<AggregateCallParticipant>;
  aggregateCallRecording?: Maybe<AggregateCallRecording>;
  aggregateCampaignRegistryCampaign?: Maybe<AggregateCampaignRegistryCampaign>;
  aggregateCanonicalBreed?: Maybe<AggregateCanonicalBreed>;
  aggregateCanonicalCanineBreedProfile?: Maybe<AggregateCanonicalCanineBreedProfile>;
  aggregateCanonicalService?: Maybe<AggregateCanonicalService>;
  aggregateCanonicalSpecies?: Maybe<AggregateCanonicalSpecies>;
  aggregateCareAccountCreditIntent?: Maybe<AggregateCareAccountCreditIntent>;
  aggregateCareAccountCreditPimsWritebackLogEntry?: Maybe<AggregateCareAccountCreditPimsWritebackLogEntry>;
  aggregateCareAddonEnrollment?: Maybe<AggregateCareAddonEnrollment>;
  aggregateCareAddonPackage?: Maybe<AggregateCareAddonPackage>;
  aggregateCareAddonPackageBenefit?: Maybe<AggregateCareAddonPackageBenefit>;
  aggregateCareAuthorizationRequest?: Maybe<AggregateCareAuthorizationRequest>;
  aggregateCareBenefit?: Maybe<AggregateCareBenefit>;
  aggregateCareBenefitUsage?: Maybe<AggregateCareBenefitUsage>;
  aggregateCareBenefitUsageFollowup?: Maybe<AggregateCareBenefitUsageFollowup>;
  aggregateCareBenefitUsageFollowupStep?: Maybe<AggregateCareBenefitUsageFollowupStep>;
  aggregateCareBenefitUsageToInvoiceLineItem?: Maybe<AggregateCareBenefitUsageToInvoiceLineItem>;
  aggregateCareEnrollmentPayment?: Maybe<AggregateCareEnrollmentPayment>;
  aggregateCareEnrollmentToStripeInvoiceItem?: Maybe<AggregateCareEnrollmentToStripeInvoiceItem>;
  aggregateCareLapsedPayment?: Maybe<AggregateCareLapsedPayment>;
  aggregateCarePlan?: Maybe<AggregateCarePlan>;
  aggregateCarePlanBenefit?: Maybe<AggregateCarePlanBenefit>;
  aggregateCarePlanBenefitEnrollmentCarryover?: Maybe<AggregateCarePlanBenefitEnrollmentCarryover>;
  aggregateCarePlanEnrollment?: Maybe<AggregateCarePlanEnrollment>;
  aggregateCarePlanProviderGroup?: Maybe<AggregateCarePlanProviderGroup>;
  aggregateCareStripeSubscription?: Maybe<AggregateCareStripeSubscription>;
  aggregateCareSubscriptionTrueUp?: Maybe<AggregateCareSubscriptionTrueUp>;
  aggregateCareSubscriptionTrueUpLogEntry?: Maybe<AggregateCareSubscriptionTrueUpLogEntry>;
  aggregateChannel?: Maybe<AggregateChannel>;
  aggregateChannelAutomationStatus?: Maybe<AggregateChannelAutomationStatus>;
  aggregateChannelCreationSource?: Maybe<AggregateChannelCreationSource>;
  aggregateChannelFilterSelection?: Maybe<AggregateChannelFilterSelection>;
  aggregateChannelMember?: Maybe<AggregateChannelMember>;
  aggregateChannelMessage?: Maybe<AggregateChannelMessage>;
  aggregateChannelMessageAttachment?: Maybe<AggregateChannelMessageAttachment>;
  aggregateChannelMessageAutoResponse?: Maybe<AggregateChannelMessageAutoResponse>;
  aggregateChannelMessageReaction?: Maybe<AggregateChannelMessageReaction>;
  aggregateChannelPin?: Maybe<AggregateChannelPin>;
  aggregateChannelSelection?: Maybe<AggregateChannelSelection>;
  aggregateChannelStatus?: Maybe<AggregateChannelStatus>;
  aggregateChannelStatusChannelAssignee?: Maybe<AggregateChannelStatusChannelAssignee>;
  aggregateChemicalCompound?: Maybe<AggregateChemicalCompound>;
  aggregateClientConnectIntegration?: Maybe<AggregateClientConnectIntegration>;
  aggregateClinic?: Maybe<AggregateClinic>;
  aggregateClinicBlockedPhoneNumber?: Maybe<AggregateClinicBlockedPhoneNumber>;
  aggregateClinicDirectBookingSetting?: Maybe<AggregateClinicDirectBookingSetting>;
  aggregateClinicEmailCampaignSetting?: Maybe<AggregateClinicEmailCampaignSetting>;
  aggregateClinicEmployee?: Maybe<AggregateClinicEmployee>;
  aggregateClinicEmployeeAppointmentTypeSetting?: Maybe<AggregateClinicEmployeeAppointmentTypeSetting>;
  aggregateClinicEntityPhoneNumber?: Maybe<AggregateClinicEntityPhoneNumber>;
  aggregateClinicOfficeHour?: Maybe<AggregateClinicOfficeHour>;
  aggregateClinicOnboarding?: Maybe<AggregateClinicOnboarding>;
  aggregateClinicPaymentFeeConfiguration?: Maybe<AggregateClinicPaymentFeeConfiguration>;
  aggregateClinicPet?: Maybe<AggregateClinicPet>;
  aggregateClinicPetAlert?: Maybe<AggregateClinicPetAlert>;
  aggregateClinicPetCustomFieldValue?: Maybe<AggregateClinicPetCustomFieldValue>;
  aggregateClinicPetParent?: Maybe<AggregateClinicPetParent>;
  aggregateClinicPetParentAddress?: Maybe<AggregateClinicPetParentAddress>;
  aggregateClinicPetParentCustomFieldValue?: Maybe<AggregateClinicPetParentCustomFieldValue>;
  aggregateClinicPetParentDevice?: Maybe<AggregateClinicPetParentDevice>;
  aggregateClinicPetParentPimsSyncStatus?: Maybe<AggregateClinicPetParentPimsSyncStatus>;
  aggregateClinicPetPimsSyncStatus?: Maybe<AggregateClinicPetPimsSyncStatus>;
  aggregateClinicPhoneNumber?: Maybe<AggregateClinicPhoneNumber>;
  aggregateClinicPimsIntegration?: Maybe<AggregateClinicPimsIntegration>;
  aggregateClinicPimsIntegrationCapability?: Maybe<AggregateClinicPimsIntegrationCapability>;
  aggregateClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<AggregateClinicPimsIntegrationCapabilityHistoryEntry>;
  aggregateClinicPostcardSetting?: Maybe<AggregateClinicPostcardSetting>;
  aggregateClinicRoom?: Maybe<AggregateClinicRoom>;
  aggregateClinicSetting?: Maybe<AggregateClinicSetting>;
  aggregateClinicWidgetRequest?: Maybe<AggregateClinicWidgetRequest>;
  aggregateClinicWidgetRequestType?: Maybe<AggregateClinicWidgetRequestType>;
  aggregateClinicWidgetSetting?: Maybe<AggregateClinicWidgetSetting>;
  aggregateColor?: Maybe<AggregateColor>;
  aggregateCondition?: Maybe<AggregateCondition>;
  aggregateConditionSet?: Maybe<AggregateConditionSet>;
  aggregateConditionalEntityEvaluation?: Maybe<AggregateConditionalEntityEvaluation>;
  aggregateConsultation?: Maybe<AggregateConsultation>;
  aggregateConsultationRequest?: Maybe<AggregateConsultationRequest>;
  aggregateCustomFieldDefinition?: Maybe<AggregateCustomFieldDefinition>;
  aggregateCustomFieldPimsWritebackLogEntry?: Maybe<AggregateCustomFieldPimsWritebackLogEntry>;
  aggregateDirectBookingAppointmentTypeSetting?: Maybe<AggregateDirectBookingAppointmentTypeSetting>;
  aggregateDisbursement?: Maybe<AggregateDisbursement>;
  aggregateDocumentation?: Maybe<AggregateDocumentation>;
  aggregateEmailCampaign?: Maybe<AggregateEmailCampaign>;
  aggregateEmailCampaignCustomList?: Maybe<AggregateEmailCampaignCustomList>;
  aggregateEmailCampaignCustomListRecipient?: Maybe<AggregateEmailCampaignCustomListRecipient>;
  aggregateEmailCampaignCustomListUploadHistory?: Maybe<AggregateEmailCampaignCustomListUploadHistory>;
  aggregateEmailCampaignLog?: Maybe<AggregateEmailCampaignLog>;
  aggregateEmailCampaignTemplate?: Maybe<AggregateEmailCampaignTemplate>;
  aggregateEmailCampaignUnsubscribe?: Maybe<AggregateEmailCampaignUnsubscribe>;
  aggregateEmailProviderLog?: Maybe<AggregateEmailProviderLog>;
  aggregateEmergencyClinics?: Maybe<AggregateEmergencyClinics>;
  aggregateEnrollmentSupportEvent?: Maybe<AggregateEnrollmentSupportEvent>;
  aggregateEzyVetIntegration?: Maybe<AggregateEzyVetIntegration>;
  aggregateFeatureFlag?: Maybe<AggregateFeatureFlag>;
  aggregateFinancialAdjustment?: Maybe<AggregateFinancialAdjustment>;
  aggregateFinancialTransaction?: Maybe<AggregateFinancialTransaction>;
  aggregateFormSubmission?: Maybe<AggregateFormSubmission>;
  aggregateFormTemplate?: Maybe<AggregateFormTemplate>;
  aggregateGlobalPetParent?: Maybe<AggregateGlobalPetParent>;
  aggregateHillsToHomeAPILog?: Maybe<AggregateHillsToHomeApiLog>;
  aggregateInformAudienceDefinition?: Maybe<AggregateInformAudienceDefinition>;
  aggregateInformAutomationCampaign?: Maybe<AggregateInformAutomationCampaign>;
  aggregateInformCampaign?: Maybe<AggregateInformCampaign>;
  aggregateInformCampaignEnrollment?: Maybe<AggregateInformCampaignEnrollment>;
  aggregateInformCampaignEnrollmentCanonicalService?: Maybe<AggregateInformCampaignEnrollmentCanonicalService>;
  aggregateInformCampaignExecution?: Maybe<AggregateInformCampaignExecution>;
  aggregateInformCampaignGroup?: Maybe<AggregateInformCampaignGroup>;
  aggregateInformControlGroupParticipant?: Maybe<AggregateInformControlGroupParticipant>;
  aggregateInformEmailTemplate?: Maybe<AggregateInformEmailTemplate>;
  aggregateInformEntityHistory?: Maybe<AggregateInformEntityHistory>;
  aggregateInformPartner?: Maybe<AggregateInformPartner>;
  aggregateInformPartnerCustomer?: Maybe<AggregateInformPartnerCustomer>;
  aggregateInformPartnerCustomerPharmacyProvider?: Maybe<AggregateInformPartnerCustomerPharmacyProvider>;
  aggregateInformPartnerIntegration?: Maybe<AggregateInformPartnerIntegration>;
  aggregateInformPartnerIntegrationModel?: Maybe<AggregateInformPartnerIntegrationModel>;
  aggregateInformPartnerProgram?: Maybe<AggregateInformPartnerProgram>;
  aggregateInformPartnerProgramEnrollment?: Maybe<AggregateInformPartnerProgramEnrollment>;
  aggregateInformPartnerUser?: Maybe<AggregateInformPartnerUser>;
  aggregateInformPartnerUserRole?: Maybe<AggregateInformPartnerUserRole>;
  aggregateInformScheduledCampaign?: Maybe<AggregateInformScheduledCampaign>;
  aggregateInstinctIntegration?: Maybe<AggregateInstinctIntegration>;
  aggregateInsuranceIntegration?: Maybe<AggregateInsuranceIntegration>;
  aggregateIntegrationOutageHistoryEntry?: Maybe<AggregateIntegrationOutageHistoryEntry>;
  aggregateInterchangeRate?: Maybe<AggregateInterchangeRate>;
  aggregateInvoice?: Maybe<AggregateInvoice>;
  aggregateInvoiceLineItem?: Maybe<AggregateInvoiceLineItem>;
  aggregateInvoicePimsWritebackLogEntry?: Maybe<AggregateInvoicePimsWritebackLogEntry>;
  aggregateLapsedPetParentTriggers?: Maybe<AggregateLapsedPetParentTriggers>;
  aggregateLegalEntity?: Maybe<AggregateLegalEntity>;
  aggregateLoyaltyAccount?: Maybe<AggregateLoyaltyAccount>;
  aggregateLoyaltyAccountHolder?: Maybe<AggregateLoyaltyAccountHolder>;
  aggregateLoyaltyAccountMerger?: Maybe<AggregateLoyaltyAccountMerger>;
  aggregateLoyaltyInvoicePointGranter?: Maybe<AggregateLoyaltyInvoicePointGranter>;
  aggregateLoyaltyPointDelta?: Maybe<AggregateLoyaltyPointDelta>;
  aggregateLoyaltyPointGrantingService?: Maybe<AggregateLoyaltyPointGrantingService>;
  aggregateLoyaltyPointGrantingServiceCategory?: Maybe<AggregateLoyaltyPointGrantingServiceCategory>;
  aggregateLoyaltyPointUserGrant?: Maybe<AggregateLoyaltyPointUserGrant>;
  aggregateLoyaltyProgram?: Maybe<AggregateLoyaltyProgram>;
  aggregateLoyaltyProgramStatusHistory?: Maybe<AggregateLoyaltyProgramStatusHistory>;
  aggregateLoyaltyReward?: Maybe<AggregateLoyaltyReward>;
  aggregateLoyaltyRewardRedemption?: Maybe<AggregateLoyaltyRewardRedemption>;
  aggregateLoyaltyRewardRedemptionHistoryEntry?: Maybe<AggregateLoyaltyRewardRedemptionHistoryEntry>;
  aggregateMassTextAlert?: Maybe<AggregateMassTextAlert>;
  aggregateMassTextAlertEntry?: Maybe<AggregateMassTextAlertEntry>;
  aggregateMassTextAlertEntryAppointment?: Maybe<AggregateMassTextAlertEntryAppointment>;
  aggregateMedia?: Maybe<AggregateMedia>;
  aggregateMessageTemplate?: Maybe<AggregateMessageTemplate>;
  aggregateMessagingCampaign?: Maybe<AggregateMessagingCampaign>;
  aggregateMessagingPartner?: Maybe<AggregateMessagingPartner>;
  aggregateNonPrismaSchemaScriptRan?: Maybe<AggregateNonPrismaSchemaScriptRan>;
  aggregateNotificationEventToMessage?: Maybe<AggregateNotificationEventToMessage>;
  aggregateOrganization?: Maybe<AggregateOrganization>;
  aggregateOrganizationCareBenefitToClinicService?: Maybe<AggregateOrganizationCareBenefitToClinicService>;
  aggregateOrganizationCareBenefitToClinicServiceCategory?: Maybe<AggregateOrganizationCareBenefitToClinicServiceCategory>;
  aggregateOrganizationPet?: Maybe<AggregateOrganizationPet>;
  aggregateOrganizationPetParent?: Maybe<AggregateOrganizationPetParent>;
  aggregateOrganizationPetToOrganizationPetParent?: Maybe<AggregateOrganizationPetToOrganizationPetParent>;
  aggregatePPCIntegration?: Maybe<AggregatePpcIntegration>;
  aggregatePermission?: Maybe<AggregatePermission>;
  aggregatePetParentSetting?: Maybe<AggregatePetParentSetting>;
  aggregatePetPortalSetting?: Maybe<AggregatePetPortalSetting>;
  aggregatePhoneNumberLookupRequest?: Maybe<AggregatePhoneNumberLookupRequest>;
  aggregatePhoneNumberLookupResult?: Maybe<AggregatePhoneNumberLookupResult>;
  aggregatePimsInvoice?: Maybe<AggregatePimsInvoice>;
  aggregatePimsInvoiceLineItem?: Maybe<AggregatePimsInvoiceLineItem>;
  aggregatePimsPaymentType?: Maybe<AggregatePimsPaymentType>;
  aggregatePimsWritebackLogEntry?: Maybe<AggregatePimsWritebackLogEntry>;
  aggregatePrescription?: Maybe<AggregatePrescription>;
  aggregateProduct?: Maybe<AggregateProduct>;
  aggregateProviderAppointmentTypeRoomMap?: Maybe<AggregateProviderAppointmentTypeRoomMap>;
  aggregatePushNotificationLog?: Maybe<AggregatePushNotificationLog>;
  aggregateRules?: Maybe<AggregateRules>;
  aggregateSegment?: Maybe<AggregateSegment>;
  aggregateSentTrupanionExamDayOffer?: Maybe<AggregateSentTrupanionExamDayOffer>;
  aggregateService?: Maybe<AggregateService>;
  aggregateServiceCategory?: Maybe<AggregateServiceCategory>;
  aggregateServiceReminder?: Maybe<AggregateServiceReminder>;
  aggregateServiceReminderNotification?: Maybe<AggregateServiceReminderNotification>;
  aggregateServiceReminderTiming?: Maybe<AggregateServiceReminderTiming>;
  aggregateSex?: Maybe<AggregateSex>;
  aggregateSmsNotificationStatus?: Maybe<AggregateSmsNotificationStatus>;
  aggregateSpecies?: Maybe<AggregateSpecies>;
  aggregateStaffRole?: Maybe<AggregateStaffRole>;
  aggregateStripeCustomer?: Maybe<AggregateStripeCustomer>;
  aggregateStripeDispute?: Maybe<AggregateStripeDispute>;
  aggregateStripeInvoice?: Maybe<AggregateStripeInvoice>;
  aggregateStripeInvoiceItem?: Maybe<AggregateStripeInvoiceItem>;
  aggregateStripePaymentIntent?: Maybe<AggregateStripePaymentIntent>;
  aggregateStripePaymentMethod?: Maybe<AggregateStripePaymentMethod>;
  aggregateStripePayout?: Maybe<AggregateStripePayout>;
  aggregateStripePayoutAccount?: Maybe<AggregateStripePayoutAccount>;
  aggregateStripeReceipt?: Maybe<AggregateStripeReceipt>;
  aggregateStripeReceiptHistory?: Maybe<AggregateStripeReceiptHistory>;
  aggregateStripeRefund?: Maybe<AggregateStripeRefund>;
  aggregateStripeTerminal?: Maybe<AggregateStripeTerminal>;
  aggregateStripeTerminalLocation?: Maybe<AggregateStripeTerminalLocation>;
  aggregateSurveyAppointmentTypeSetting?: Maybe<AggregateSurveyAppointmentTypeSetting>;
  aggregateSurveyResult?: Maybe<AggregateSurveyResult>;
  aggregateSurveySetting?: Maybe<AggregateSurveySetting>;
  aggregateSyncVetIntegration?: Maybe<AggregateSyncVetIntegration>;
  aggregateTransaction?: Maybe<AggregateTransaction>;
  aggregateTrupanionCertificateAvailability?: Maybe<AggregateTrupanionCertificateAvailability>;
  aggregateTrupanionIntegratedAppointmentType?: Maybe<AggregateTrupanionIntegratedAppointmentType>;
  aggregateTrupanionIntegration?: Maybe<AggregateTrupanionIntegration>;
  aggregateTwilioCallRecording?: Maybe<AggregateTwilioCallRecording>;
  aggregateUser?: Maybe<AggregateUser>;
  aggregateUserAppointmentGrouping?: Maybe<AggregateUserAppointmentGrouping>;
  aggregateUserBoardFilter?: Maybe<AggregateUserBoardFilter>;
  aggregateUserChannelFilterSelection?: Maybe<AggregateUserChannelFilterSelection>;
  aggregateUserNotificationSetting?: Maybe<AggregateUserNotificationSetting>;
  aggregateUserSetting?: Maybe<AggregateUserSetting>;
  aggregateVaccination?: Maybe<AggregateVaccination>;
  aggregateVetAvailability?: Maybe<AggregateVetAvailability>;
  aggregateVetAvailabilityClinicRoomMap?: Maybe<AggregateVetAvailabilityClinicRoomMap>;
  aggregateVetAvailabilityMap?: Maybe<AggregateVetAvailabilityMap>;
  aggregateVetInfo?: Maybe<AggregateVetInfo>;
  aggregateVetsourceCodeTag?: Maybe<AggregateVetsourceCodeTag>;
  aggregateVetsourceInstallation?: Maybe<AggregateVetsourceInstallation>;
  aggregateVetsourceRevenueCategory?: Maybe<AggregateVetsourceRevenueCategory>;
  aggregateWorkflowEventSetting?: Maybe<AggregateWorkflowEventSetting>;
  aiChannelSuggestedMessage?: Maybe<ChannelSuggestedMessageResponse>;
  appointmentFilters?: Maybe<AppointmentFilter>;
  calculateMultiplePetsBenefitUsage?: Maybe<CalculateMultiplePetsBenefitUsageOutput>;
  calculatePetBenefitUsage?: Maybe<CalculatePetBenefitUsageOutput>;
  canManuallyRetryPaymentWriteback: CanManuallyRetryPaymentWritebackOutput;
  canSendEmailCampaign?: Maybe<Scalars['Boolean']>;
  channelExport?: Maybe<FileExportData>;
  channelMessageUnreadCount: ChannelMessageUnreadCountResult;
  channelSearch: ChannelSearchOutput;
  channelUnreadMessageCount?: Maybe<ChannelUnreadMessageCountResult>;
  checkClinicCareStatus: CheckClinicCareStatusResult;
  checkPhoneVerification?: Maybe<Scalars['Json']>;
  clientReadTest?: Maybe<Scalars['Boolean']>;
  clientWriteTest?: Maybe<Scalars['Boolean']>;
  clinicPaymentActivity?: Maybe<ClinicPaymentActivityOutput>;
  clinicPetParentSearch: Array<ClinicPetParent>;
  clinicPetSearch: Array<ClinicPet>;
  customFindUniqueUserBoardFilter?: Maybe<CustomFindUniqueUserBoardFilterResponse>;
  estimateNumberOfPetParentsReached?: Maybe<NumberOfPetParentsReached>;
  exportCancellationSummaryAsPdf: ExportCancellationSummaryAsPdfResponse;
  exportItemizedInvoiceAsReceiptPDF: ExportItemizedInvoiceToReceiptPdfResponse;
  exportNonItemizedReceiptAsPdf: ExportNonItemizedReceiptAsPdfResponse;
  findClinicContactInfo?: Maybe<ClinicContactInfo>;
  findClinicPetParentByPhoneOrEmail: Array<ClinicPetParent>;
  findClinicPetParentByToken?: Maybe<ClinicPetParent>;
  findCurrentEmailTemplateVersions: CurrentEmailTemplateVersionsFindResponse;
  findEmployerIdentificationNumber?: Maybe<Array<Maybe<FindEmployerIdentificationNumberResponse>>>;
  findFirstActiveIngredient?: Maybe<ActiveIngredient>;
  findFirstAppointment?: Maybe<Appointment>;
  findFirstAppointmentAvailability?: Maybe<AppointmentAvailability>;
  findFirstAppointmentType?: Maybe<AppointmentType>;
  findFirstAutomationRun?: Maybe<AutomationRun>;
  findFirstAutomationRunAction?: Maybe<AutomationRunAction>;
  findFirstBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  findFirstBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findFirstBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  findFirstBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  findFirstBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  findFirstBoardFilterColumn?: Maybe<BoardFilterColumn>;
  findFirstBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  findFirstBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  findFirstBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  findFirstBreed?: Maybe<Breed>;
  findFirstCallHistory?: Maybe<CallHistory>;
  findFirstCallParticipant?: Maybe<CallParticipant>;
  findFirstCallRecording?: Maybe<CallRecording>;
  findFirstCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  findFirstCanonicalBreed?: Maybe<CanonicalBreed>;
  findFirstCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  findFirstCanonicalService?: Maybe<CanonicalService>;
  findFirstCanonicalSpecies?: Maybe<CanonicalSpecies>;
  findFirstCareAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  findFirstCareAccountCreditPimsWritebackLogEntry?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  findFirstCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findFirstCareAddonPackage?: Maybe<CareAddonPackage>;
  findFirstCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findFirstCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findFirstCareBenefit?: Maybe<CareBenefit>;
  findFirstCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findFirstCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  findFirstCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  findFirstCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findFirstCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findFirstCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findFirstCareLapsedPayment?: Maybe<CareLapsedPayment>;
  findFirstCarePlan?: Maybe<CarePlan>;
  findFirstCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findFirstCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  findFirstCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findFirstCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  findFirstCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findFirstCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  findFirstCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  findFirstChannel?: Maybe<Channel>;
  findFirstChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findFirstChannelCreationSource?: Maybe<ChannelCreationSource>;
  findFirstChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findFirstChannelMember?: Maybe<ChannelMember>;
  findFirstChannelMessage?: Maybe<ChannelMessage>;
  findFirstChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findFirstChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  findFirstChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findFirstChannelPin?: Maybe<ChannelPin>;
  findFirstChannelSelection?: Maybe<ChannelSelection>;
  findFirstChannelStatus?: Maybe<ChannelStatus>;
  findFirstChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findFirstChemicalCompound?: Maybe<ChemicalCompound>;
  findFirstClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  findFirstClinic?: Maybe<Clinic>;
  findFirstClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  findFirstClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findFirstClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findFirstClinicEmployee?: Maybe<ClinicEmployee>;
  findFirstClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findFirstClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findFirstClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findFirstClinicOnboarding?: Maybe<ClinicOnboarding>;
  findFirstClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findFirstClinicPet?: Maybe<ClinicPet>;
  findFirstClinicPetAlert?: Maybe<ClinicPetAlert>;
  findFirstClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  findFirstClinicPetParent?: Maybe<ClinicPetParent>;
  findFirstClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findFirstClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  findFirstClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  findFirstClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  findFirstClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  findFirstClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findFirstClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findFirstClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findFirstClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  findFirstClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findFirstClinicRoom?: Maybe<ClinicRoom>;
  findFirstClinicSetting?: Maybe<ClinicSetting>;
  findFirstClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findFirstClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findFirstClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findFirstColor?: Maybe<Color>;
  findFirstCondition?: Maybe<Condition>;
  findFirstConditionSet?: Maybe<ConditionSet>;
  findFirstConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  findFirstConsultation?: Maybe<Consultation>;
  findFirstConsultationRequest?: Maybe<ConsultationRequest>;
  findFirstCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  findFirstCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  findFirstDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  findFirstDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  findFirstDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  findFirstDisbursement?: Maybe<Disbursement>;
  findFirstDocumentation?: Maybe<Documentation>;
  findFirstEmailCampaign?: Maybe<EmailCampaign>;
  findFirstEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  findFirstEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  findFirstEmailCampaignCustomListUploadHistory?: Maybe<EmailCampaignCustomListUploadHistory>;
  findFirstEmailCampaignLog?: Maybe<EmailCampaignLog>;
  findFirstEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  findFirstEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  findFirstEmailProviderLog?: Maybe<EmailProviderLog>;
  findFirstEmergencyClinics?: Maybe<EmergencyClinics>;
  findFirstEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  findFirstEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findFirstFeatureFlag?: Maybe<FeatureFlag>;
  findFirstFinancialAdjustment?: Maybe<FinancialAdjustment>;
  findFirstFinancialTransaction?: Maybe<FinancialTransaction>;
  findFirstFormSubmission?: Maybe<FormSubmission>;
  findFirstFormTemplate?: Maybe<FormTemplate>;
  findFirstGlobalPetParent?: Maybe<GlobalPetParent>;
  findFirstHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  findFirstInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  findFirstInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  findFirstInformCampaign?: Maybe<InformCampaign>;
  findFirstInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  findFirstInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  findFirstInformCampaignExecution?: Maybe<InformCampaignExecution>;
  findFirstInformCampaignGroup?: Maybe<InformCampaignGroup>;
  findFirstInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  findFirstInformEmailTemplate?: Maybe<InformEmailTemplate>;
  findFirstInformEntityHistory?: Maybe<InformEntityHistory>;
  findFirstInformPartner?: Maybe<InformPartner>;
  findFirstInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  findFirstInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  findFirstInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  findFirstInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  findFirstInformPartnerProgram?: Maybe<InformPartnerProgram>;
  findFirstInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  findFirstInformPartnerUser?: Maybe<InformPartnerUser>;
  findFirstInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  findFirstInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  findFirstInstinctIntegration?: Maybe<InstinctIntegration>;
  findFirstInsuranceIntegration?: Maybe<InsuranceIntegration>;
  findFirstIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  findFirstInterchangeRate?: Maybe<InterchangeRate>;
  findFirstInvoice?: Maybe<Invoice>;
  findFirstInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findFirstInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findFirstLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  findFirstLegalEntity?: Maybe<LegalEntity>;
  findFirstLoyaltyAccount?: Maybe<LoyaltyAccount>;
  findFirstLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  findFirstLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  findFirstLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  findFirstLoyaltyPointDelta?: Maybe<LoyaltyPointDelta>;
  findFirstLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  findFirstLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  findFirstLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  findFirstLoyaltyProgram?: Maybe<LoyaltyProgram>;
  findFirstLoyaltyProgramStatusHistory?: Maybe<LoyaltyProgramStatusHistory>;
  findFirstLoyaltyReward?: Maybe<LoyaltyReward>;
  findFirstLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  findFirstLoyaltyRewardRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
  findFirstMassTextAlert?: Maybe<MassTextAlert>;
  findFirstMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findFirstMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findFirstMedia?: Maybe<Media>;
  findFirstMessageTemplate?: Maybe<MessageTemplate>;
  findFirstMessagingCampaign?: Maybe<MessagingCampaign>;
  findFirstMessagingPartner?: Maybe<MessagingPartner>;
  findFirstNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  findFirstNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findFirstOrganization?: Maybe<Organization>;
  findFirstOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findFirstOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  findFirstOrganizationPet?: Maybe<OrganizationPet>;
  findFirstOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findFirstOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findFirstPPCIntegration?: Maybe<PpcIntegration>;
  findFirstPayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  findFirstPermission?: Maybe<Permission>;
  findFirstPetParentSetting?: Maybe<PetParentSetting>;
  findFirstPetPortalSetting?: Maybe<PetPortalSetting>;
  findFirstPhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  findFirstPhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  findFirstPimsInvoice?: Maybe<PimsInvoice>;
  findFirstPimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findFirstPimsPaymentType?: Maybe<PimsPaymentType>;
  findFirstPimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findFirstPrescription?: Maybe<Prescription>;
  findFirstProduct?: Maybe<Product>;
  findFirstProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  findFirstPushNotificationLog?: Maybe<PushNotificationLog>;
  findFirstRules?: Maybe<Rules>;
  findFirstSegment?: Maybe<Segment>;
  findFirstSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  findFirstService?: Maybe<Service>;
  findFirstServiceCategory?: Maybe<ServiceCategory>;
  findFirstServiceReminder?: Maybe<ServiceReminder>;
  findFirstServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findFirstServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findFirstSex?: Maybe<Sex>;
  findFirstSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findFirstSpecies?: Maybe<Species>;
  findFirstStaffRole?: Maybe<StaffRole>;
  findFirstStripeCustomer?: Maybe<StripeCustomer>;
  findFirstStripeDispute?: Maybe<StripeDispute>;
  findFirstStripeInvoice?: Maybe<StripeInvoice>;
  findFirstStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findFirstStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findFirstStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findFirstStripePayout?: Maybe<StripePayout>;
  findFirstStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findFirstStripeReceipt?: Maybe<StripeReceipt>;
  findFirstStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findFirstStripeRefund?: Maybe<StripeRefund>;
  findFirstStripeTerminal?: Maybe<StripeTerminal>;
  findFirstStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findFirstSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  findFirstSurveyResult?: Maybe<SurveyResult>;
  findFirstSurveySetting?: Maybe<SurveySetting>;
  findFirstSyncVetIntegration?: Maybe<SyncVetIntegration>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  findFirstTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  findFirstTrupanionIntegration?: Maybe<TrupanionIntegration>;
  findFirstTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findFirstUser?: Maybe<User>;
  findFirstUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findFirstUserBoardFilter?: Maybe<UserBoardFilter>;
  findFirstUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findFirstUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findFirstUserSetting?: Maybe<UserSetting>;
  findFirstVaccination?: Maybe<Vaccination>;
  findFirstVetAvailability?: Maybe<VetAvailability>;
  findFirstVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  findFirstVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findFirstVetInfo?: Maybe<VetInfo>;
  findFirstVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  findFirstVetsourceInstallation?: Maybe<VetsourceInstallation>;
  findFirstVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  findFirstWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  findManyActiveIngredient?: Maybe<Array<ActiveIngredient>>;
  findManyActiveIngredientCount: Scalars['Int'];
  findManyAppointment?: Maybe<Array<Appointment>>;
  findManyAppointmentAvailability?: Maybe<Array<AppointmentAvailability>>;
  findManyAppointmentAvailabilityCount: Scalars['Int'];
  findManyAppointmentCount: Scalars['Int'];
  findManyAppointmentType?: Maybe<Array<AppointmentType>>;
  findManyAppointmentTypeCount: Scalars['Int'];
  findManyAutomationRun?: Maybe<Array<AutomationRun>>;
  findManyAutomationRunAction?: Maybe<Array<AutomationRunAction>>;
  findManyAutomationRunActionCount: Scalars['Int'];
  findManyAutomationRunCount: Scalars['Int'];
  findManyBitwerxHealthcheck?: Maybe<Array<BitwerxHealthcheck>>;
  findManyBitwerxHealthcheckCount: Scalars['Int'];
  findManyBitwerxIntegration?: Maybe<Array<BitwerxIntegration>>;
  findManyBitwerxIntegrationCount: Scalars['Int'];
  findManyBitwerxPaymentHealthcheckResult?: Maybe<Array<BitwerxPaymentHealthcheckResult>>;
  findManyBitwerxPaymentHealthcheckResultCount: Scalars['Int'];
  findManyBitwerxPaymentHealthcheckRun?: Maybe<Array<BitwerxPaymentHealthcheckRun>>;
  findManyBitwerxPaymentHealthcheckRunCount: Scalars['Int'];
  findManyBitwerxPaymentWritebackConfigurationCount: Scalars['Int'];
  findManyBoardFilterColumn?: Maybe<Array<BoardFilterColumn>>;
  findManyBoardFilterColumnCount: Scalars['Int'];
  findManyBoardFilterSpecificDateRange?: Maybe<Array<BoardFilterSpecificDateRange>>;
  findManyBoardFilterSpecificDateRangeCount: Scalars['Int'];
  findManyBoehringerIngelheimCustomerAlignment?: Maybe<Array<BoehringerIngelheimCustomerAlignment>>;
  findManyBoehringerIngelheimCustomerAlignmentCount: Scalars['Int'];
  findManyBoehringerIngelheimTerritory?: Maybe<Array<BoehringerIngelheimTerritory>>;
  findManyBoehringerIngelheimTerritoryCount: Scalars['Int'];
  findManyBreed?: Maybe<Array<Breed>>;
  findManyBreedCount: Scalars['Int'];
  findManyCallHistory?: Maybe<Array<CallHistory>>;
  findManyCallHistoryCount: Scalars['Int'];
  findManyCallParticipant?: Maybe<Array<CallParticipant>>;
  findManyCallParticipantCount: Scalars['Int'];
  findManyCallRecording?: Maybe<Array<CallRecording>>;
  findManyCallRecordingCount: Scalars['Int'];
  findManyCampaignRegistryCampaign?: Maybe<Array<CampaignRegistryCampaign>>;
  findManyCampaignRegistryCampaignCount: Scalars['Int'];
  findManyCanonicalBreed?: Maybe<Array<CanonicalBreed>>;
  findManyCanonicalBreedCount: Scalars['Int'];
  findManyCanonicalCanineBreedProfile?: Maybe<Array<CanonicalCanineBreedProfile>>;
  findManyCanonicalCanineBreedProfileCount: Scalars['Int'];
  findManyCanonicalService?: Maybe<Array<CanonicalService>>;
  findManyCanonicalServiceCount: Scalars['Int'];
  findManyCanonicalSpecies?: Maybe<Array<CanonicalSpecies>>;
  findManyCanonicalSpeciesCount: Scalars['Int'];
  findManyCareAccountCreditIntent?: Maybe<Array<CareAccountCreditIntent>>;
  findManyCareAccountCreditIntentCount: Scalars['Int'];
  findManyCareAccountCreditPimsWritebackLogEntry?: Maybe<Array<CareAccountCreditPimsWritebackLogEntry>>;
  findManyCareAccountCreditPimsWritebackLogEntryCount: Scalars['Int'];
  findManyCareAddonEnrollment?: Maybe<Array<CareAddonEnrollment>>;
  findManyCareAddonEnrollmentCount: Scalars['Int'];
  findManyCareAddonPackage?: Maybe<Array<CareAddonPackage>>;
  findManyCareAddonPackageBenefit?: Maybe<Array<CareAddonPackageBenefit>>;
  findManyCareAddonPackageBenefitCount: Scalars['Int'];
  findManyCareAddonPackageCount: Scalars['Int'];
  findManyCareAuthorizationRequest?: Maybe<Array<CareAuthorizationRequest>>;
  findManyCareAuthorizationRequestCount: Scalars['Int'];
  findManyCareBenefit?: Maybe<Array<CareBenefit>>;
  findManyCareBenefitCount: Scalars['Int'];
  findManyCareBenefitUsage?: Maybe<Array<CareBenefitUsage>>;
  findManyCareBenefitUsageCount: Scalars['Int'];
  findManyCareBenefitUsageFollowup?: Maybe<Array<CareBenefitUsageFollowup>>;
  findManyCareBenefitUsageFollowupCount: Scalars['Int'];
  findManyCareBenefitUsageFollowupStep?: Maybe<Array<CareBenefitUsageFollowupStep>>;
  findManyCareBenefitUsageFollowupStepCount: Scalars['Int'];
  findManyCareBenefitUsageToInvoiceLineItem?: Maybe<Array<CareBenefitUsageToInvoiceLineItem>>;
  findManyCareBenefitUsageToInvoiceLineItemCount: Scalars['Int'];
  findManyCareEnrollmentPayment?: Maybe<Array<CareEnrollmentPayment>>;
  findManyCareEnrollmentPaymentCount: Scalars['Int'];
  findManyCareEnrollmentToStripeInvoiceItem?: Maybe<Array<CareEnrollmentToStripeInvoiceItem>>;
  findManyCareEnrollmentToStripeInvoiceItemCount: Scalars['Int'];
  findManyCareLapsedPayment?: Maybe<Array<CareLapsedPayment>>;
  findManyCareLapsedPaymentCount: Scalars['Int'];
  findManyCarePlan?: Maybe<Array<CarePlan>>;
  findManyCarePlanBenefit?: Maybe<Array<CarePlanBenefit>>;
  findManyCarePlanBenefitCount: Scalars['Int'];
  findManyCarePlanBenefitEnrollmentCarryover?: Maybe<Array<CarePlanBenefitEnrollmentCarryover>>;
  findManyCarePlanBenefitEnrollmentCarryoverCount: Scalars['Int'];
  findManyCarePlanCount: Scalars['Int'];
  findManyCarePlanEnrollment?: Maybe<Array<CarePlanEnrollment>>;
  findManyCarePlanEnrollmentCount: Scalars['Int'];
  findManyCarePlanProviderGroup?: Maybe<Array<CarePlanProviderGroup>>;
  findManyCarePlanProviderGroupCount: Scalars['Int'];
  findManyCareStripeSubscription?: Maybe<Array<CareStripeSubscription>>;
  findManyCareStripeSubscriptionCount: Scalars['Int'];
  findManyCareSubscriptionTrueUp?: Maybe<Array<CareSubscriptionTrueUp>>;
  findManyCareSubscriptionTrueUpCount: Scalars['Int'];
  findManyCareSubscriptionTrueUpLogEntry?: Maybe<Array<CareSubscriptionTrueUpLogEntry>>;
  findManyCareSubscriptionTrueUpLogEntryCount: Scalars['Int'];
  findManyChannel?: Maybe<Array<Channel>>;
  findManyChannelAutomationStatus?: Maybe<Array<ChannelAutomationStatus>>;
  findManyChannelAutomationStatusCount: Scalars['Int'];
  findManyChannelCount: Scalars['Int'];
  findManyChannelCreationSource?: Maybe<Array<ChannelCreationSource>>;
  findManyChannelCreationSourceCount: Scalars['Int'];
  findManyChannelFilterSelection?: Maybe<Array<ChannelFilterSelection>>;
  findManyChannelFilterSelectionCount: Scalars['Int'];
  findManyChannelMember?: Maybe<Array<ChannelMember>>;
  findManyChannelMemberCount: Scalars['Int'];
  findManyChannelMessage?: Maybe<Array<ChannelMessage>>;
  findManyChannelMessageAttachment?: Maybe<Array<ChannelMessageAttachment>>;
  findManyChannelMessageAttachmentCount: Scalars['Int'];
  findManyChannelMessageAutoResponse?: Maybe<Array<ChannelMessageAutoResponse>>;
  findManyChannelMessageAutoResponseCount: Scalars['Int'];
  findManyChannelMessageCount: Scalars['Int'];
  findManyChannelMessageReaction?: Maybe<Array<ChannelMessageReaction>>;
  findManyChannelMessageReactionCount: Scalars['Int'];
  findManyChannelPin?: Maybe<Array<ChannelPin>>;
  findManyChannelPinCount: Scalars['Int'];
  findManyChannelSelection?: Maybe<Array<ChannelSelection>>;
  findManyChannelSelectionCount: Scalars['Int'];
  findManyChannelStatus?: Maybe<Array<ChannelStatus>>;
  findManyChannelStatusChannelAssignee?: Maybe<Array<ChannelStatusChannelAssignee>>;
  findManyChannelStatusChannelAssigneeCount: Scalars['Int'];
  findManyChannelStatusCount: Scalars['Int'];
  findManyChemicalCompound?: Maybe<Array<ChemicalCompound>>;
  findManyChemicalCompoundCount: Scalars['Int'];
  findManyClientConnectIntegration?: Maybe<Array<ClientConnectIntegration>>;
  findManyClientConnectIntegrationCount: Scalars['Int'];
  findManyClinic?: Maybe<Array<Clinic>>;
  findManyClinicBlockedPhoneNumber?: Maybe<Array<ClinicBlockedPhoneNumber>>;
  findManyClinicBlockedPhoneNumberCount: Scalars['Int'];
  findManyClinicCount: Scalars['Int'];
  findManyClinicDirectBookingSetting?: Maybe<Array<ClinicDirectBookingSetting>>;
  findManyClinicDirectBookingSettingCount: Scalars['Int'];
  findManyClinicEmailCampaignSetting?: Maybe<Array<ClinicEmailCampaignSetting>>;
  findManyClinicEmailCampaignSettingCount: Scalars['Int'];
  findManyClinicEmployee?: Maybe<Array<ClinicEmployee>>;
  findManyClinicEmployeeAppointmentTypeSetting?: Maybe<Array<ClinicEmployeeAppointmentTypeSetting>>;
  findManyClinicEmployeeAppointmentTypeSettingCount: Scalars['Int'];
  findManyClinicEmployeeCount: Scalars['Int'];
  findManyClinicEntityPhoneNumber?: Maybe<Array<ClinicEntityPhoneNumber>>;
  findManyClinicEntityPhoneNumberCount: Scalars['Int'];
  findManyClinicOfficeHour?: Maybe<Array<ClinicOfficeHour>>;
  findManyClinicOfficeHourCount: Scalars['Int'];
  findManyClinicOnboarding?: Maybe<Array<ClinicOnboarding>>;
  findManyClinicOnboardingCount: Scalars['Int'];
  findManyClinicPaymentFeeConfiguration?: Maybe<Array<ClinicPaymentFeeConfiguration>>;
  findManyClinicPaymentFeeConfigurationCount: Scalars['Int'];
  findManyClinicPet?: Maybe<Array<ClinicPet>>;
  findManyClinicPetAlert?: Maybe<Array<ClinicPetAlert>>;
  findManyClinicPetAlertCount: Scalars['Int'];
  findManyClinicPetCount: Scalars['Int'];
  findManyClinicPetCustomFieldValue?: Maybe<Array<ClinicPetCustomFieldValue>>;
  findManyClinicPetCustomFieldValueCount: Scalars['Int'];
  findManyClinicPetParent?: Maybe<Array<ClinicPetParent>>;
  findManyClinicPetParentAddress?: Maybe<Array<ClinicPetParentAddress>>;
  findManyClinicPetParentAddressCount: Scalars['Int'];
  findManyClinicPetParentCount: Scalars['Int'];
  findManyClinicPetParentCustomFieldValue?: Maybe<Array<ClinicPetParentCustomFieldValue>>;
  findManyClinicPetParentCustomFieldValueCount: Scalars['Int'];
  findManyClinicPetParentDevice?: Maybe<Array<ClinicPetParentDevice>>;
  findManyClinicPetParentDeviceCount: Scalars['Int'];
  findManyClinicPetParentPimsSyncStatus?: Maybe<Array<ClinicPetParentPimsSyncStatus>>;
  findManyClinicPetParentPimsSyncStatusCount: Scalars['Int'];
  findManyClinicPetPimsSyncStatus?: Maybe<Array<ClinicPetPimsSyncStatus>>;
  findManyClinicPetPimsSyncStatusCount: Scalars['Int'];
  findManyClinicPhoneNumber?: Maybe<Array<ClinicPhoneNumber>>;
  findManyClinicPhoneNumberCount: Scalars['Int'];
  findManyClinicPimsIntegration?: Maybe<Array<ClinicPimsIntegration>>;
  findManyClinicPimsIntegrationCapability?: Maybe<Array<ClinicPimsIntegrationCapability>>;
  findManyClinicPimsIntegrationCapabilityCount: Scalars['Int'];
  findManyClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<Array<ClinicPimsIntegrationCapabilityHistoryEntry>>;
  findManyClinicPimsIntegrationCapabilityHistoryEntryCount: Scalars['Int'];
  findManyClinicPimsIntegrationCount: Scalars['Int'];
  findManyClinicPostcardSetting?: Maybe<Array<ClinicPostcardSetting>>;
  findManyClinicPostcardSettingCount: Scalars['Int'];
  findManyClinicRoom?: Maybe<Array<ClinicRoom>>;
  findManyClinicRoomCount: Scalars['Int'];
  findManyClinicSetting?: Maybe<Array<ClinicSetting>>;
  findManyClinicSettingCount: Scalars['Int'];
  findManyClinicWidgetRequest?: Maybe<Array<ClinicWidgetRequest>>;
  findManyClinicWidgetRequestCount: Scalars['Int'];
  findManyClinicWidgetRequestType?: Maybe<Array<ClinicWidgetRequestType>>;
  findManyClinicWidgetRequestTypeCount: Scalars['Int'];
  findManyClinicWidgetSetting?: Maybe<Array<ClinicWidgetSetting>>;
  findManyClinicWidgetSettingCount: Scalars['Int'];
  findManyColor?: Maybe<Array<Color>>;
  findManyColorCount: Scalars['Int'];
  findManyCondition?: Maybe<Array<Condition>>;
  findManyConditionCount: Scalars['Int'];
  findManyConditionSet?: Maybe<Array<ConditionSet>>;
  findManyConditionSetCount: Scalars['Int'];
  findManyConditionalEntityEvaluation?: Maybe<Array<ConditionalEntityEvaluation>>;
  findManyConditionalEntityEvaluationCount: Scalars['Int'];
  findManyConsultation?: Maybe<Array<Consultation>>;
  findManyConsultationCount: Scalars['Int'];
  findManyConsultationRequest?: Maybe<Array<ConsultationRequest>>;
  findManyConsultationRequestCount: Scalars['Int'];
  findManyCustomFieldDefinition?: Maybe<Array<CustomFieldDefinition>>;
  findManyCustomFieldDefinitionCount: Scalars['Int'];
  findManyCustomFieldPimsWritebackLogEntry?: Maybe<Array<CustomFieldPimsWritebackLogEntry>>;
  findManyCustomFieldPimsWritebackLogEntryCount: Scalars['Int'];
  findManyDirectBookingAppointmentTypeSetting?: Maybe<Array<DirectBookingAppointmentTypeSetting>>;
  findManyDirectBookingAppointmentTypeSettingCount: Scalars['Int'];
  findManyDirectBookingExclusionRule?: Maybe<Array<DirectBookingExclusionRule>>;
  findManyDirectBookingExclusionRuleCount: Scalars['Int'];
  findManyDirectBookingExclusionTimeSlot?: Maybe<Array<DirectBookingExclusionTimeSlot>>;
  findManyDirectBookingExclusionTimeSlotCount: Scalars['Int'];
  findManyDisbursement?: Maybe<Array<Disbursement>>;
  findManyDisbursementCount: Scalars['Int'];
  findManyDocumentation?: Maybe<Array<Documentation>>;
  findManyDocumentationCount: Scalars['Int'];
  findManyEmailCampaign?: Maybe<Array<EmailCampaign>>;
  findManyEmailCampaignCount: Scalars['Int'];
  findManyEmailCampaignCustomList?: Maybe<Array<EmailCampaignCustomList>>;
  findManyEmailCampaignCustomListCount: Scalars['Int'];
  findManyEmailCampaignCustomListRecipient?: Maybe<Array<EmailCampaignCustomListRecipient>>;
  findManyEmailCampaignCustomListRecipientCount: Scalars['Int'];
  findManyEmailCampaignCustomListUploadHistory?: Maybe<Array<EmailCampaignCustomListUploadHistory>>;
  findManyEmailCampaignCustomListUploadHistoryCount: Scalars['Int'];
  findManyEmailCampaignLog?: Maybe<Array<EmailCampaignLog>>;
  findManyEmailCampaignLogCount: Scalars['Int'];
  findManyEmailCampaignTemplate?: Maybe<Array<EmailCampaignTemplate>>;
  findManyEmailCampaignTemplateCount: Scalars['Int'];
  findManyEmailCampaignUnsubscribe?: Maybe<Array<EmailCampaignUnsubscribe>>;
  findManyEmailCampaignUnsubscribeCount: Scalars['Int'];
  findManyEmailProviderLog?: Maybe<Array<EmailProviderLog>>;
  findManyEmailProviderLogCount: Scalars['Int'];
  findManyEmergencyClinics?: Maybe<Array<EmergencyClinics>>;
  findManyEmergencyClinicsCount: Scalars['Int'];
  findManyEnrollmentSupportEvent?: Maybe<Array<EnrollmentSupportEvent>>;
  findManyEnrollmentSupportEventCount: Scalars['Int'];
  findManyEzyVetIntegration?: Maybe<Array<EzyVetIntegration>>;
  findManyEzyVetIntegrationCount: Scalars['Int'];
  findManyFeatureFlag?: Maybe<Array<FeatureFlag>>;
  findManyFeatureFlagCount: Scalars['Int'];
  findManyFinancialAdjustment?: Maybe<Array<FinancialAdjustment>>;
  findManyFinancialAdjustmentCount: Scalars['Int'];
  findManyFinancialTransaction?: Maybe<Array<FinancialTransaction>>;
  findManyFinancialTransactionCount: Scalars['Int'];
  findManyFormSubmission?: Maybe<Array<FormSubmission>>;
  findManyFormSubmissionCount: Scalars['Int'];
  findManyFormTemplate?: Maybe<Array<FormTemplate>>;
  findManyFormTemplateCount: Scalars['Int'];
  findManyGlobalPetParent?: Maybe<Array<GlobalPetParent>>;
  findManyGlobalPetParentCount: Scalars['Int'];
  findManyHillsToHomeAPILog?: Maybe<Array<HillsToHomeApiLog>>;
  findManyHillsToHomeAPILogCount: Scalars['Int'];
  findManyInformAudienceDefinition?: Maybe<Array<InformAudienceDefinition>>;
  findManyInformAudienceDefinitionCount: Scalars['Int'];
  findManyInformAutomationCampaign?: Maybe<Array<InformAutomationCampaign>>;
  findManyInformAutomationCampaignCount: Scalars['Int'];
  findManyInformCampaign?: Maybe<Array<InformCampaign>>;
  findManyInformCampaignCount: Scalars['Int'];
  findManyInformCampaignEnrollment?: Maybe<Array<InformCampaignEnrollment>>;
  findManyInformCampaignEnrollmentCanonicalService?: Maybe<Array<InformCampaignEnrollmentCanonicalService>>;
  findManyInformCampaignEnrollmentCanonicalServiceCount: Scalars['Int'];
  findManyInformCampaignEnrollmentCount: Scalars['Int'];
  findManyInformCampaignExecution?: Maybe<Array<InformCampaignExecution>>;
  findManyInformCampaignExecutionCount: Scalars['Int'];
  findManyInformCampaignGroup?: Maybe<Array<InformCampaignGroup>>;
  findManyInformCampaignGroupCount: Scalars['Int'];
  findManyInformControlGroupParticipant?: Maybe<Array<InformControlGroupParticipant>>;
  findManyInformControlGroupParticipantCount: Scalars['Int'];
  findManyInformEmailTemplate?: Maybe<Array<InformEmailTemplate>>;
  findManyInformEmailTemplateCount: Scalars['Int'];
  findManyInformEntityHistory?: Maybe<Array<InformEntityHistory>>;
  findManyInformEntityHistoryCount: Scalars['Int'];
  findManyInformPartner?: Maybe<Array<InformPartner>>;
  findManyInformPartnerCount: Scalars['Int'];
  findManyInformPartnerCustomer?: Maybe<Array<InformPartnerCustomer>>;
  findManyInformPartnerCustomerCount: Scalars['Int'];
  findManyInformPartnerCustomerPharmacyProvider?: Maybe<Array<InformPartnerCustomerPharmacyProvider>>;
  findManyInformPartnerCustomerPharmacyProviderCount: Scalars['Int'];
  findManyInformPartnerIntegration?: Maybe<Array<InformPartnerIntegration>>;
  findManyInformPartnerIntegrationCount: Scalars['Int'];
  findManyInformPartnerIntegrationModel?: Maybe<Array<InformPartnerIntegrationModel>>;
  findManyInformPartnerIntegrationModelCount: Scalars['Int'];
  findManyInformPartnerProgram?: Maybe<Array<InformPartnerProgram>>;
  findManyInformPartnerProgramCount: Scalars['Int'];
  findManyInformPartnerProgramEnrollment?: Maybe<Array<InformPartnerProgramEnrollment>>;
  findManyInformPartnerProgramEnrollmentCount: Scalars['Int'];
  findManyInformPartnerUser?: Maybe<Array<InformPartnerUser>>;
  findManyInformPartnerUserCount: Scalars['Int'];
  findManyInformPartnerUserRole?: Maybe<Array<InformPartnerUserRole>>;
  findManyInformPartnerUserRoleCount: Scalars['Int'];
  findManyInformScheduledCampaign?: Maybe<Array<InformScheduledCampaign>>;
  findManyInformScheduledCampaignCount: Scalars['Int'];
  findManyInstinctIntegration?: Maybe<Array<InstinctIntegration>>;
  findManyInstinctIntegrationCount: Scalars['Int'];
  findManyInsuranceIntegration?: Maybe<Array<InsuranceIntegration>>;
  findManyInsuranceIntegrationCount: Scalars['Int'];
  findManyIntegrationOutageHistoryEntry?: Maybe<Array<IntegrationOutageHistoryEntry>>;
  findManyIntegrationOutageHistoryEntryCount: Scalars['Int'];
  findManyInterchangeRate?: Maybe<Array<InterchangeRate>>;
  findManyInterchangeRateCount: Scalars['Int'];
  findManyInvoice?: Maybe<Array<Invoice>>;
  findManyInvoiceCount: Scalars['Int'];
  findManyInvoiceLineItem?: Maybe<Array<InvoiceLineItem>>;
  findManyInvoiceLineItemCount: Scalars['Int'];
  findManyInvoicePimsWritebackLogEntry?: Maybe<Array<InvoicePimsWritebackLogEntry>>;
  findManyInvoicePimsWritebackLogEntryCount: Scalars['Int'];
  findManyLapsedPetParentTriggers?: Maybe<Array<LapsedPetParentTriggers>>;
  findManyLapsedPetParentTriggersCount: Scalars['Int'];
  findManyLegalEntity?: Maybe<Array<LegalEntity>>;
  findManyLegalEntityCount: Scalars['Int'];
  findManyLoyaltyAccount?: Maybe<Array<LoyaltyAccount>>;
  findManyLoyaltyAccountCount: Scalars['Int'];
  findManyLoyaltyAccountHolder?: Maybe<Array<LoyaltyAccountHolder>>;
  findManyLoyaltyAccountHolderCount: Scalars['Int'];
  findManyLoyaltyAccountMerger?: Maybe<Array<LoyaltyAccountMerger>>;
  findManyLoyaltyAccountMergerCount: Scalars['Int'];
  findManyLoyaltyInvoicePointGranter?: Maybe<Array<LoyaltyInvoicePointGranter>>;
  findManyLoyaltyInvoicePointGranterCount: Scalars['Int'];
  findManyLoyaltyPointDelta?: Maybe<Array<LoyaltyPointDelta>>;
  findManyLoyaltyPointDeltaCount: Scalars['Int'];
  findManyLoyaltyPointGrantingService?: Maybe<Array<LoyaltyPointGrantingService>>;
  findManyLoyaltyPointGrantingServiceCategory?: Maybe<Array<LoyaltyPointGrantingServiceCategory>>;
  findManyLoyaltyPointGrantingServiceCategoryCount: Scalars['Int'];
  findManyLoyaltyPointGrantingServiceCount: Scalars['Int'];
  findManyLoyaltyPointUserGrant?: Maybe<Array<LoyaltyPointUserGrant>>;
  findManyLoyaltyPointUserGrantCount: Scalars['Int'];
  findManyLoyaltyProgram?: Maybe<Array<LoyaltyProgram>>;
  findManyLoyaltyProgramCount: Scalars['Int'];
  findManyLoyaltyProgramStatusHistory?: Maybe<Array<LoyaltyProgramStatusHistory>>;
  findManyLoyaltyProgramStatusHistoryCount: Scalars['Int'];
  findManyLoyaltyReward?: Maybe<Array<LoyaltyReward>>;
  findManyLoyaltyRewardCount: Scalars['Int'];
  findManyLoyaltyRewardRedemption?: Maybe<Array<LoyaltyRewardRedemption>>;
  findManyLoyaltyRewardRedemptionCount: Scalars['Int'];
  findManyLoyaltyRewardRedemptionHistoryEntry?: Maybe<Array<LoyaltyRewardRedemptionHistoryEntry>>;
  findManyLoyaltyRewardRedemptionHistoryEntryCount: Scalars['Int'];
  findManyMassTextAlert?: Maybe<Array<MassTextAlert>>;
  findManyMassTextAlertCount: Scalars['Int'];
  findManyMassTextAlertEntry?: Maybe<Array<MassTextAlertEntry>>;
  findManyMassTextAlertEntryAppointment?: Maybe<Array<MassTextAlertEntryAppointment>>;
  findManyMassTextAlertEntryAppointmentCount: Scalars['Int'];
  findManyMassTextAlertEntryCount: Scalars['Int'];
  findManyMedia?: Maybe<Array<Media>>;
  findManyMediaCount: Scalars['Int'];
  findManyMessageTemplate?: Maybe<Array<MessageTemplate>>;
  findManyMessageTemplateCount: Scalars['Int'];
  findManyMessagingCampaign?: Maybe<Array<MessagingCampaign>>;
  findManyMessagingCampaignCount: Scalars['Int'];
  findManyMessagingPartner?: Maybe<Array<MessagingPartner>>;
  findManyMessagingPartnerCount: Scalars['Int'];
  findManyNonPrismaSchemaScriptRan?: Maybe<Array<NonPrismaSchemaScriptRan>>;
  findManyNonPrismaSchemaScriptRanCount: Scalars['Int'];
  findManyNotificationEventToMessage?: Maybe<Array<NotificationEventToMessage>>;
  findManyNotificationEventToMessageCount: Scalars['Int'];
  findManyOrganization?: Maybe<Array<Organization>>;
  findManyOrganizationCareBenefitToClinicService?: Maybe<Array<OrganizationCareBenefitToClinicService>>;
  findManyOrganizationCareBenefitToClinicServiceCategory?: Maybe<Array<OrganizationCareBenefitToClinicServiceCategory>>;
  findManyOrganizationCareBenefitToClinicServiceCategoryCount: Scalars['Int'];
  findManyOrganizationCareBenefitToClinicServiceCount: Scalars['Int'];
  findManyOrganizationCount: Scalars['Int'];
  findManyOrganizationPet?: Maybe<Array<OrganizationPet>>;
  findManyOrganizationPetCount: Scalars['Int'];
  findManyOrganizationPetParent?: Maybe<Array<OrganizationPetParent>>;
  findManyOrganizationPetParentCount: Scalars['Int'];
  findManyOrganizationPetToOrganizationPetParent?: Maybe<Array<OrganizationPetToOrganizationPetParent>>;
  findManyOrganizationPetToOrganizationPetParentCount: Scalars['Int'];
  findManyPPCIntegration?: Maybe<Array<PpcIntegration>>;
  findManyPPCIntegrationCount: Scalars['Int'];
  findManyParentWorkflowEventSetting: Array<Maybe<WorkflowEventSetting>>;
  findManyPayoutBatchingPeriod?: Maybe<Array<PayoutBatchingPeriod>>;
  findManyPayoutBatchingPeriodCount: Scalars['Int'];
  findManyPermission?: Maybe<Array<Permission>>;
  findManyPermissionCount: Scalars['Int'];
  findManyPetParentSetting?: Maybe<Array<PetParentSetting>>;
  findManyPetParentSettingCount: Scalars['Int'];
  findManyPetPortalSetting?: Maybe<Array<PetPortalSetting>>;
  findManyPetPortalSettingCount: Scalars['Int'];
  findManyPhoneNumberLookupRequest?: Maybe<Array<PhoneNumberLookupRequest>>;
  findManyPhoneNumberLookupRequestCount: Scalars['Int'];
  findManyPhoneNumberLookupResult?: Maybe<Array<PhoneNumberLookupResult>>;
  findManyPhoneNumberLookupResultCount: Scalars['Int'];
  findManyPimsInvoice?: Maybe<Array<PimsInvoice>>;
  findManyPimsInvoiceCount: Scalars['Int'];
  findManyPimsInvoiceLineItem?: Maybe<Array<PimsInvoiceLineItem>>;
  findManyPimsInvoiceLineItemCount: Scalars['Int'];
  findManyPimsPaymentType?: Maybe<Array<PimsPaymentType>>;
  findManyPimsPaymentTypeCount: Scalars['Int'];
  findManyPimsWritebackLogEntry?: Maybe<Array<PimsWritebackLogEntry>>;
  findManyPimsWritebackLogEntryCount: Scalars['Int'];
  findManyPrescription?: Maybe<Array<Prescription>>;
  findManyPrescriptionCount: Scalars['Int'];
  findManyProduct?: Maybe<Array<Product>>;
  findManyProductCount: Scalars['Int'];
  findManyProviderAppointmentTypeRoomMap?: Maybe<Array<ProviderAppointmentTypeRoomMap>>;
  findManyProviderAppointmentTypeRoomMapCount: Scalars['Int'];
  findManyPushNotificationLog?: Maybe<Array<PushNotificationLog>>;
  findManyPushNotificationLogCount: Scalars['Int'];
  findManyRules?: Maybe<Array<Rules>>;
  findManyRulesCount: Scalars['Int'];
  findManySegment?: Maybe<Array<Segment>>;
  findManySegmentCount: Scalars['Int'];
  findManySentTrupanionExamDayOffer?: Maybe<Array<SentTrupanionExamDayOffer>>;
  findManySentTrupanionExamDayOfferCount: Scalars['Int'];
  findManyService?: Maybe<Array<Service>>;
  findManyServiceCategory?: Maybe<Array<ServiceCategory>>;
  findManyServiceCategoryCount: Scalars['Int'];
  findManyServiceCount: Scalars['Int'];
  findManyServiceReminder?: Maybe<Array<ServiceReminder>>;
  findManyServiceReminderCount: Scalars['Int'];
  findManyServiceReminderNotification?: Maybe<Array<ServiceReminderNotification>>;
  findManyServiceReminderNotificationCount: Scalars['Int'];
  findManyServiceReminderTiming?: Maybe<Array<ServiceReminderTiming>>;
  findManyServiceReminderTimingCount: Scalars['Int'];
  findManySex?: Maybe<Array<Sex>>;
  findManySexCount: Scalars['Int'];
  findManySmsNotificationStatus?: Maybe<Array<SmsNotificationStatus>>;
  findManySmsNotificationStatusCount: Scalars['Int'];
  findManySpecies?: Maybe<Array<Species>>;
  findManySpeciesCount: Scalars['Int'];
  findManyStaffRole?: Maybe<Array<StaffRole>>;
  findManyStaffRoleCount: Scalars['Int'];
  findManyStripeCustomer?: Maybe<Array<StripeCustomer>>;
  findManyStripeCustomerCount: Scalars['Int'];
  findManyStripeDispute?: Maybe<Array<StripeDispute>>;
  findManyStripeDisputeCount: Scalars['Int'];
  findManyStripeInvoice?: Maybe<Array<StripeInvoice>>;
  findManyStripeInvoiceCount: Scalars['Int'];
  findManyStripeInvoiceItem?: Maybe<Array<StripeInvoiceItem>>;
  findManyStripeInvoiceItemCount: Scalars['Int'];
  findManyStripePaymentIntent?: Maybe<Array<StripePaymentIntent>>;
  findManyStripePaymentIntentCount: Scalars['Int'];
  findManyStripePaymentMethod?: Maybe<Array<StripePaymentMethod>>;
  findManyStripePaymentMethodCount: Scalars['Int'];
  findManyStripePayout?: Maybe<Array<StripePayout>>;
  findManyStripePayoutAccount?: Maybe<Array<StripePayoutAccount>>;
  findManyStripePayoutAccountCount: Scalars['Int'];
  findManyStripePayoutCount: Scalars['Int'];
  findManyStripeReceipt?: Maybe<Array<StripeReceipt>>;
  findManyStripeReceiptCount: Scalars['Int'];
  findManyStripeReceiptHistory?: Maybe<Array<StripeReceiptHistory>>;
  findManyStripeReceiptHistoryCount: Scalars['Int'];
  findManyStripeRefund?: Maybe<Array<StripeRefund>>;
  findManyStripeRefundCount: Scalars['Int'];
  findManyStripeTerminal?: Maybe<Array<StripeTerminal>>;
  findManyStripeTerminalCount: Scalars['Int'];
  findManyStripeTerminalLocation?: Maybe<Array<StripeTerminalLocation>>;
  findManyStripeTerminalLocationCount: Scalars['Int'];
  findManySurveyAppointmentTypeSetting?: Maybe<Array<SurveyAppointmentTypeSetting>>;
  findManySurveyAppointmentTypeSettingCount: Scalars['Int'];
  findManySurveyResult?: Maybe<Array<SurveyResult>>;
  findManySurveyResultCount: Scalars['Int'];
  findManySurveySetting?: Maybe<Array<SurveySetting>>;
  findManySurveySettingCount: Scalars['Int'];
  findManySyncVetIntegration?: Maybe<Array<SyncVetIntegration>>;
  findManySyncVetIntegrationCount: Scalars['Int'];
  findManyTransaction?: Maybe<Array<Transaction>>;
  findManyTransactionCount: Scalars['Int'];
  findManyTrupanionCertificateAvailability?: Maybe<Array<TrupanionCertificateAvailability>>;
  findManyTrupanionCertificateAvailabilityCount: Scalars['Int'];
  findManyTrupanionIntegratedAppointmentType?: Maybe<Array<TrupanionIntegratedAppointmentType>>;
  findManyTrupanionIntegratedAppointmentTypeCount: Scalars['Int'];
  findManyTrupanionIntegration?: Maybe<Array<TrupanionIntegration>>;
  findManyTrupanionIntegrationCount: Scalars['Int'];
  findManyTwilioCallRecording?: Maybe<Array<TwilioCallRecording>>;
  findManyTwilioCallRecordingCount: Scalars['Int'];
  findManyUser?: Maybe<Array<User>>;
  findManyUserAppointmentGrouping?: Maybe<Array<UserAppointmentGrouping>>;
  findManyUserAppointmentGroupingCount: Scalars['Int'];
  findManyUserBoardFilter?: Maybe<Array<UserBoardFilter>>;
  findManyUserBoardFilterCount: Scalars['Int'];
  findManyUserChannelFilterSelection?: Maybe<Array<UserChannelFilterSelection>>;
  findManyUserChannelFilterSelectionCount: Scalars['Int'];
  findManyUserCount: Scalars['Int'];
  findManyUserNotificationSetting?: Maybe<Array<UserNotificationSetting>>;
  findManyUserNotificationSettingCount: Scalars['Int'];
  findManyUserSetting?: Maybe<Array<UserSetting>>;
  findManyUserSettingCount: Scalars['Int'];
  findManyVaccination?: Maybe<Array<Vaccination>>;
  findManyVaccinationCount: Scalars['Int'];
  findManyVetAvailability?: Maybe<Array<VetAvailability>>;
  findManyVetAvailabilityClinicRoomMap?: Maybe<Array<VetAvailabilityClinicRoomMap>>;
  findManyVetAvailabilityClinicRoomMapCount: Scalars['Int'];
  findManyVetAvailabilityCount: Scalars['Int'];
  findManyVetAvailabilityMap?: Maybe<Array<VetAvailabilityMap>>;
  findManyVetAvailabilityMapCount: Scalars['Int'];
  findManyVetInfo?: Maybe<Array<VetInfo>>;
  findManyVetInfoCount: Scalars['Int'];
  findManyVetsourceCodeTag?: Maybe<Array<VetsourceCodeTag>>;
  findManyVetsourceCodeTagCount: Scalars['Int'];
  findManyVetsourceInstallation?: Maybe<Array<VetsourceInstallation>>;
  findManyVetsourceInstallationCount: Scalars['Int'];
  findManyVetsourceRevenueCategory?: Maybe<Array<VetsourceRevenueCategory>>;
  findManyVetsourceRevenueCategoryCount: Scalars['Int'];
  findManyWorkflowEventSetting?: Maybe<Array<WorkflowEventSetting>>;
  findManyWorkflowEventSettingCount: Scalars['Int'];
  findMissingPayoutBatchingPeriodDates: FindMissingBatchingPeriodDatesOutput;
  findOrganizationPetParentByToken?: Maybe<OrganizationPetParent>;
  findUniqueActiveIngredient?: Maybe<ActiveIngredient>;
  findUniqueAppointment?: Maybe<Appointment>;
  findUniqueAppointmentAvailability?: Maybe<AppointmentAvailability>;
  findUniqueAppointmentType?: Maybe<AppointmentType>;
  findUniqueAutomationRun?: Maybe<AutomationRun>;
  findUniqueAutomationRunAction?: Maybe<AutomationRunAction>;
  findUniqueBitwerxHealthcheck?: Maybe<BitwerxHealthcheck>;
  findUniqueBitwerxIntegration?: Maybe<BitwerxIntegration>;
  findUniqueBitwerxPaymentHealthcheckResult?: Maybe<BitwerxPaymentHealthcheckResult>;
  findUniqueBitwerxPaymentHealthcheckRun?: Maybe<BitwerxPaymentHealthcheckRun>;
  findUniqueBitwerxPaymentWritebackConfiguration?: Maybe<BitwerxPaymentWritebackConfiguration>;
  findUniqueBoardFilterColumn?: Maybe<BoardFilterColumn>;
  findUniqueBoardFilterSpecificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  findUniqueBoehringerIngelheimCustomerAlignment?: Maybe<BoehringerIngelheimCustomerAlignment>;
  findUniqueBoehringerIngelheimTerritory?: Maybe<BoehringerIngelheimTerritory>;
  findUniqueBreed?: Maybe<Breed>;
  findUniqueCallHistory?: Maybe<CallHistory>;
  findUniqueCallParticipant?: Maybe<CallParticipant>;
  findUniqueCallRecording?: Maybe<CallRecording>;
  findUniqueCampaignRegistryCampaign?: Maybe<CampaignRegistryCampaign>;
  findUniqueCanonicalBreed?: Maybe<CanonicalBreed>;
  findUniqueCanonicalCanineBreedProfile?: Maybe<CanonicalCanineBreedProfile>;
  findUniqueCanonicalService?: Maybe<CanonicalService>;
  findUniqueCanonicalSpecies?: Maybe<CanonicalSpecies>;
  findUniqueCareAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  findUniqueCareAccountCreditPimsWritebackLogEntry?: Maybe<CareAccountCreditPimsWritebackLogEntry>;
  findUniqueCareAddonEnrollment?: Maybe<CareAddonEnrollment>;
  findUniqueCareAddonPackage?: Maybe<CareAddonPackage>;
  findUniqueCareAddonPackageBenefit?: Maybe<CareAddonPackageBenefit>;
  findUniqueCareAuthorizationRequest?: Maybe<CareAuthorizationRequest>;
  findUniqueCareBenefit?: Maybe<CareBenefit>;
  findUniqueCareBenefitUsage?: Maybe<CareBenefitUsage>;
  findUniqueCareBenefitUsageFollowup?: Maybe<CareBenefitUsageFollowup>;
  findUniqueCareBenefitUsageFollowupStep?: Maybe<CareBenefitUsageFollowupStep>;
  findUniqueCareBenefitUsageToInvoiceLineItem?: Maybe<CareBenefitUsageToInvoiceLineItem>;
  findUniqueCareEnrollmentPayment?: Maybe<CareEnrollmentPayment>;
  findUniqueCareEnrollmentToStripeInvoiceItem?: Maybe<CareEnrollmentToStripeInvoiceItem>;
  findUniqueCareLapsedPayment?: Maybe<CareLapsedPayment>;
  findUniqueCarePlan?: Maybe<CarePlan>;
  findUniqueCarePlanBenefit?: Maybe<CarePlanBenefit>;
  findUniqueCarePlanBenefitEnrollmentCarryover?: Maybe<CarePlanBenefitEnrollmentCarryover>;
  findUniqueCarePlanEnrollment?: Maybe<CarePlanEnrollment>;
  findUniqueCarePlanProviderGroup?: Maybe<CarePlanProviderGroup>;
  findUniqueCareStripeSubscription?: Maybe<CareStripeSubscription>;
  findUniqueCareSubscriptionTrueUp?: Maybe<CareSubscriptionTrueUp>;
  findUniqueCareSubscriptionTrueUpLogEntry?: Maybe<CareSubscriptionTrueUpLogEntry>;
  findUniqueChannel?: Maybe<Channel>;
  findUniqueChannelAutomationStatus?: Maybe<ChannelAutomationStatus>;
  findUniqueChannelCreationSource?: Maybe<ChannelCreationSource>;
  findUniqueChannelFilterSelection?: Maybe<ChannelFilterSelection>;
  findUniqueChannelMember?: Maybe<ChannelMember>;
  findUniqueChannelMessage?: Maybe<ChannelMessage>;
  findUniqueChannelMessageAttachment?: Maybe<ChannelMessageAttachment>;
  findUniqueChannelMessageAutoResponse?: Maybe<ChannelMessageAutoResponse>;
  findUniqueChannelMessageReaction?: Maybe<ChannelMessageReaction>;
  findUniqueChannelPin?: Maybe<ChannelPin>;
  findUniqueChannelSelection?: Maybe<ChannelSelection>;
  findUniqueChannelStatus?: Maybe<ChannelStatus>;
  findUniqueChannelStatusChannelAssignee?: Maybe<ChannelStatusChannelAssignee>;
  findUniqueChemicalCompound?: Maybe<ChemicalCompound>;
  findUniqueClientConnectIntegration?: Maybe<ClientConnectIntegration>;
  findUniqueClinic?: Maybe<Clinic>;
  findUniqueClinicBlockedPhoneNumber?: Maybe<ClinicBlockedPhoneNumber>;
  findUniqueClinicDirectBookingSetting?: Maybe<ClinicDirectBookingSetting>;
  findUniqueClinicEmailCampaignSetting?: Maybe<ClinicEmailCampaignSetting>;
  findUniqueClinicEmployee?: Maybe<ClinicEmployee>;
  findUniqueClinicEmployeeAppointmentTypeSetting?: Maybe<ClinicEmployeeAppointmentTypeSetting>;
  findUniqueClinicEntityPhoneNumber?: Maybe<ClinicEntityPhoneNumber>;
  findUniqueClinicOfficeHour?: Maybe<ClinicOfficeHour>;
  findUniqueClinicOnboarding?: Maybe<ClinicOnboarding>;
  findUniqueClinicPaymentFeeConfiguration?: Maybe<ClinicPaymentFeeConfiguration>;
  findUniqueClinicPet?: Maybe<ClinicPet>;
  findUniqueClinicPetAlert?: Maybe<ClinicPetAlert>;
  findUniqueClinicPetCustomFieldValue?: Maybe<ClinicPetCustomFieldValue>;
  findUniqueClinicPetParent?: Maybe<ClinicPetParent>;
  findUniqueClinicPetParentAddress?: Maybe<ClinicPetParentAddress>;
  findUniqueClinicPetParentCustomFieldValue?: Maybe<ClinicPetParentCustomFieldValue>;
  findUniqueClinicPetParentDevice?: Maybe<ClinicPetParentDevice>;
  findUniqueClinicPetParentPimsSyncStatus?: Maybe<ClinicPetParentPimsSyncStatus>;
  findUniqueClinicPetPimsSyncStatus?: Maybe<ClinicPetPimsSyncStatus>;
  findUniqueClinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  findUniqueClinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  findUniqueClinicPimsIntegrationCapability?: Maybe<ClinicPimsIntegrationCapability>;
  findUniqueClinicPimsIntegrationCapabilityHistoryEntry?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntry>;
  findUniqueClinicPostcardSetting?: Maybe<ClinicPostcardSetting>;
  findUniqueClinicRoom?: Maybe<ClinicRoom>;
  findUniqueClinicSetting?: Maybe<ClinicSetting>;
  findUniqueClinicWidgetRequest?: Maybe<ClinicWidgetRequest>;
  findUniqueClinicWidgetRequestType?: Maybe<ClinicWidgetRequestType>;
  findUniqueClinicWidgetSetting?: Maybe<ClinicWidgetSetting>;
  findUniqueColor?: Maybe<Color>;
  findUniqueCondition?: Maybe<Condition>;
  findUniqueConditionSet?: Maybe<ConditionSet>;
  findUniqueConditionalEntityEvaluation?: Maybe<ConditionalEntityEvaluation>;
  findUniqueConsultation?: Maybe<Consultation>;
  findUniqueConsultationRequest?: Maybe<ConsultationRequest>;
  findUniqueCustomFieldDefinition?: Maybe<CustomFieldDefinition>;
  findUniqueCustomFieldPimsWritebackLogEntry?: Maybe<CustomFieldPimsWritebackLogEntry>;
  findUniqueDirectBookingAppointmentTypeSetting?: Maybe<DirectBookingAppointmentTypeSetting>;
  findUniqueDirectBookingExclusionRule?: Maybe<DirectBookingExclusionRule>;
  findUniqueDirectBookingExclusionTimeSlot?: Maybe<DirectBookingExclusionTimeSlot>;
  findUniqueDisbursement?: Maybe<Disbursement>;
  findUniqueDocumentation?: Maybe<Documentation>;
  findUniqueEmailCampaign?: Maybe<EmailCampaign>;
  findUniqueEmailCampaignCustomList?: Maybe<EmailCampaignCustomList>;
  findUniqueEmailCampaignCustomListRecipient?: Maybe<EmailCampaignCustomListRecipient>;
  findUniqueEmailCampaignCustomListUploadHistory?: Maybe<EmailCampaignCustomListUploadHistory>;
  findUniqueEmailCampaignLog?: Maybe<EmailCampaignLog>;
  findUniqueEmailCampaignTemplate?: Maybe<EmailCampaignTemplate>;
  findUniqueEmailCampaignUnsubscribe?: Maybe<EmailCampaignUnsubscribe>;
  findUniqueEmailProviderLog?: Maybe<EmailProviderLog>;
  findUniqueEmergencyClinics?: Maybe<EmergencyClinics>;
  findUniqueEnrollmentSupportEvent?: Maybe<EnrollmentSupportEvent>;
  findUniqueEzyVetIntegration?: Maybe<EzyVetIntegration>;
  findUniqueFeatureFlag?: Maybe<FeatureFlag>;
  findUniqueFinancialAdjustment?: Maybe<FinancialAdjustment>;
  findUniqueFinancialTransaction?: Maybe<FinancialTransaction>;
  findUniqueFormSubmission?: Maybe<FormSubmission>;
  findUniqueFormTemplate?: Maybe<FormTemplate>;
  findUniqueGlobalPetParent?: Maybe<GlobalPetParent>;
  findUniqueHillsToHomeAPILog?: Maybe<HillsToHomeApiLog>;
  findUniqueInformAudienceDefinition?: Maybe<InformAudienceDefinition>;
  findUniqueInformAutomationCampaign?: Maybe<InformAutomationCampaign>;
  findUniqueInformCampaign?: Maybe<InformCampaign>;
  findUniqueInformCampaignEnrollment?: Maybe<InformCampaignEnrollment>;
  findUniqueInformCampaignEnrollmentCanonicalService?: Maybe<InformCampaignEnrollmentCanonicalService>;
  findUniqueInformCampaignExecution?: Maybe<InformCampaignExecution>;
  findUniqueInformCampaignGroup?: Maybe<InformCampaignGroup>;
  findUniqueInformControlGroupParticipant?: Maybe<InformControlGroupParticipant>;
  findUniqueInformEmailTemplate?: Maybe<InformEmailTemplate>;
  findUniqueInformEntityHistory?: Maybe<InformEntityHistory>;
  findUniqueInformPartner?: Maybe<InformPartner>;
  findUniqueInformPartnerCustomer?: Maybe<InformPartnerCustomer>;
  findUniqueInformPartnerCustomerPharmacyProvider?: Maybe<InformPartnerCustomerPharmacyProvider>;
  findUniqueInformPartnerIntegration?: Maybe<InformPartnerIntegration>;
  findUniqueInformPartnerIntegrationModel?: Maybe<InformPartnerIntegrationModel>;
  findUniqueInformPartnerProgram?: Maybe<InformPartnerProgram>;
  findUniqueInformPartnerProgramEnrollment?: Maybe<InformPartnerProgramEnrollment>;
  findUniqueInformPartnerUser?: Maybe<InformPartnerUser>;
  findUniqueInformPartnerUserRole?: Maybe<InformPartnerUserRole>;
  findUniqueInformScheduledCampaign?: Maybe<InformScheduledCampaign>;
  findUniqueInstinctIntegration?: Maybe<InstinctIntegration>;
  findUniqueInsuranceIntegration?: Maybe<InsuranceIntegration>;
  findUniqueIntegrationOutageHistoryEntry?: Maybe<IntegrationOutageHistoryEntry>;
  findUniqueInterchangeRate?: Maybe<InterchangeRate>;
  findUniqueInvoice?: Maybe<Invoice>;
  findUniqueInvoiceLineItem?: Maybe<InvoiceLineItem>;
  findUniqueInvoicePimsWritebackLogEntry?: Maybe<InvoicePimsWritebackLogEntry>;
  findUniqueLapsedPetParentTriggers?: Maybe<LapsedPetParentTriggers>;
  findUniqueLegalEntity?: Maybe<LegalEntity>;
  findUniqueLoyaltyAccount?: Maybe<LoyaltyAccount>;
  findUniqueLoyaltyAccountHolder?: Maybe<LoyaltyAccountHolder>;
  findUniqueLoyaltyAccountMerger?: Maybe<LoyaltyAccountMerger>;
  findUniqueLoyaltyInvoicePointGranter?: Maybe<LoyaltyInvoicePointGranter>;
  findUniqueLoyaltyPointDelta?: Maybe<LoyaltyPointDelta>;
  findUniqueLoyaltyPointGrantingService?: Maybe<LoyaltyPointGrantingService>;
  findUniqueLoyaltyPointGrantingServiceCategory?: Maybe<LoyaltyPointGrantingServiceCategory>;
  findUniqueLoyaltyPointUserGrant?: Maybe<LoyaltyPointUserGrant>;
  findUniqueLoyaltyProgram?: Maybe<LoyaltyProgram>;
  findUniqueLoyaltyProgramStatusHistory?: Maybe<LoyaltyProgramStatusHistory>;
  findUniqueLoyaltyReward?: Maybe<LoyaltyReward>;
  findUniqueLoyaltyRewardRedemption?: Maybe<LoyaltyRewardRedemption>;
  findUniqueLoyaltyRewardRedemptionHistoryEntry?: Maybe<LoyaltyRewardRedemptionHistoryEntry>;
  findUniqueMassTextAlert?: Maybe<MassTextAlert>;
  findUniqueMassTextAlertEntry?: Maybe<MassTextAlertEntry>;
  findUniqueMassTextAlertEntryAppointment?: Maybe<MassTextAlertEntryAppointment>;
  findUniqueMedia?: Maybe<Media>;
  findUniqueMessageTemplate?: Maybe<MessageTemplate>;
  findUniqueMessagingCampaign?: Maybe<MessagingCampaign>;
  findUniqueMessagingPartner?: Maybe<MessagingPartner>;
  findUniqueNonPrismaSchemaScriptRan?: Maybe<NonPrismaSchemaScriptRan>;
  findUniqueNotificationEventToMessage?: Maybe<NotificationEventToMessage>;
  findUniqueOrganization?: Maybe<Organization>;
  findUniqueOrganizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicService>;
  findUniqueOrganizationCareBenefitToClinicServiceCategory?: Maybe<OrganizationCareBenefitToClinicServiceCategory>;
  findUniqueOrganizationPet?: Maybe<OrganizationPet>;
  findUniqueOrganizationPetParent?: Maybe<OrganizationPetParent>;
  findUniqueOrganizationPetToOrganizationPetParent?: Maybe<OrganizationPetToOrganizationPetParent>;
  findUniquePPCIntegration?: Maybe<PpcIntegration>;
  findUniquePayoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  findUniquePermission?: Maybe<Permission>;
  findUniquePetParentSetting?: Maybe<PetParentSetting>;
  findUniquePetPortalSetting?: Maybe<PetPortalSetting>;
  findUniquePhoneNumberLookupRequest?: Maybe<PhoneNumberLookupRequest>;
  findUniquePhoneNumberLookupResult?: Maybe<PhoneNumberLookupResult>;
  findUniquePimsInvoice?: Maybe<PimsInvoice>;
  findUniquePimsInvoiceLineItem?: Maybe<PimsInvoiceLineItem>;
  findUniquePimsPaymentType?: Maybe<PimsPaymentType>;
  findUniquePimsWritebackLogEntry?: Maybe<PimsWritebackLogEntry>;
  findUniquePrescription?: Maybe<Prescription>;
  findUniqueProduct?: Maybe<Product>;
  findUniqueProviderAppointmentTypeRoomMap?: Maybe<ProviderAppointmentTypeRoomMap>;
  findUniquePushNotificationLog?: Maybe<PushNotificationLog>;
  findUniqueRules?: Maybe<Rules>;
  findUniqueSegment?: Maybe<Segment>;
  findUniqueSentTrupanionExamDayOffer?: Maybe<SentTrupanionExamDayOffer>;
  findUniqueService?: Maybe<Service>;
  findUniqueServiceCategory?: Maybe<ServiceCategory>;
  findUniqueServiceReminder?: Maybe<ServiceReminder>;
  findUniqueServiceReminderNotification?: Maybe<ServiceReminderNotification>;
  findUniqueServiceReminderTiming?: Maybe<ServiceReminderTiming>;
  findUniqueSex?: Maybe<Sex>;
  findUniqueSmsNotificationStatus?: Maybe<SmsNotificationStatus>;
  findUniqueSpecies?: Maybe<Species>;
  findUniqueStaffRole?: Maybe<StaffRole>;
  findUniqueStripeCustomer?: Maybe<StripeCustomer>;
  findUniqueStripeDispute?: Maybe<StripeDispute>;
  findUniqueStripeInvoice?: Maybe<StripeInvoice>;
  findUniqueStripeInvoiceItem?: Maybe<StripeInvoiceItem>;
  findUniqueStripePaymentIntent?: Maybe<StripePaymentIntent>;
  findUniqueStripePaymentMethod?: Maybe<StripePaymentMethod>;
  findUniqueStripePayout?: Maybe<StripePayout>;
  findUniqueStripePayoutAccount?: Maybe<StripePayoutAccount>;
  findUniqueStripeReceipt?: Maybe<StripeReceipt>;
  findUniqueStripeReceiptHistory?: Maybe<StripeReceiptHistory>;
  findUniqueStripeRefund?: Maybe<StripeRefund>;
  findUniqueStripeTerminal?: Maybe<StripeTerminal>;
  findUniqueStripeTerminalLocation?: Maybe<StripeTerminalLocation>;
  findUniqueSurveyAppointmentTypeSetting?: Maybe<SurveyAppointmentTypeSetting>;
  findUniqueSurveyResult?: Maybe<SurveyResult>;
  findUniqueSurveySetting?: Maybe<SurveySetting>;
  findUniqueSyncVetIntegration?: Maybe<SyncVetIntegration>;
  findUniqueTransaction?: Maybe<Transaction>;
  findUniqueTrupanionCertificateAvailability?: Maybe<TrupanionCertificateAvailability>;
  findUniqueTrupanionIntegratedAppointmentType?: Maybe<TrupanionIntegratedAppointmentType>;
  findUniqueTrupanionIntegration?: Maybe<TrupanionIntegration>;
  findUniqueTwilioCallRecording?: Maybe<TwilioCallRecording>;
  findUniqueUser?: Maybe<User>;
  findUniqueUserAppointmentGrouping?: Maybe<UserAppointmentGrouping>;
  findUniqueUserBoardFilter?: Maybe<UserBoardFilter>;
  findUniqueUserChannelFilterSelection?: Maybe<UserChannelFilterSelection>;
  findUniqueUserNotificationSetting?: Maybe<UserNotificationSetting>;
  findUniqueUserSetting?: Maybe<UserSetting>;
  findUniqueVaccination?: Maybe<Vaccination>;
  findUniqueVetAvailability?: Maybe<VetAvailability>;
  findUniqueVetAvailabilityClinicRoomMap?: Maybe<VetAvailabilityClinicRoomMap>;
  findUniqueVetAvailabilityMap?: Maybe<VetAvailabilityMap>;
  findUniqueVetInfo?: Maybe<VetInfo>;
  findUniqueVetsourceCodeTag?: Maybe<VetsourceCodeTag>;
  findUniqueVetsourceInstallation?: Maybe<VetsourceInstallation>;
  findUniqueVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  findUniqueWorkflowEventSetting?: Maybe<WorkflowEventSetting>;
  formSubmissionExport?: Maybe<FileExportData>;
  generatePetIdPdf: GeneratePetIdPdfResponse;
  getAppointmentHistory: GetEntityHistoryOutput;
  getAvailableFormTemplatesForWorkflows: Array<Maybe<FormTemplate>>;
  getBalanceForClient?: Maybe<BitwerxClientBalance>;
  getChannelStatusIdsWithEntityCountOfZero: Array<Scalars['String']>;
  getClinicDirectBookingSettings?: Maybe<ClinicDirectBookingSettingsResponse>;
  getClinicPetHistory: GetEntityHistoryOutput;
  getClinicPetParentHistory: GetEntityHistoryOutput;
  getCurrentInformPartnerUser?: Maybe<InformPartnerUser>;
  getCurrentUser?: Maybe<User>;
  getDirectBookingAppointmentTypes: Array<Maybe<DirectBookingAppointmentTypes>>;
  getDirectBookingOpenTimeSlots2: DirectBookingOpenTimeSlot2Response;
  getEmailCampaignCustomListUrl?: Maybe<Scalars['String']>;
  getEmailCampaignLoginUrl?: Maybe<Scalars['String']>;
  getEmailCampaignSegmentCount?: Maybe<Scalars['Int']>;
  getHasConflictingAppointment?: Maybe<Scalars['Boolean']>;
  getInvoiceById?: Maybe<Invoice>;
  getInvoicesForClient: Array<Invoice>;
  getInvoicesForClients: Array<Invoice>;
  getLatestActiveSubscriptionInvoice?: Maybe<GetLatestActiveSubscriptionInvoiceOutput>;
  getOrCreateActiveChannel?: Maybe<Channel>;
  getPetIdData: GetPetIdDataResponse;
  getPossiblyDeflectedFormSubmission?: Maybe<FormSubmission>;
  getQuickSightAdminDashboards?: Maybe<Array<Maybe<DashboardSummary>>>;
  getQuickSightDashboards?: Maybe<Array<Maybe<DashboardSummary>>>;
  getQuickSightEmbedURL?: Maybe<EmbedUrl>;
  getStripePaymentIntentData?: Maybe<StripePaymentOutput>;
  getTerminalToken?: Maybe<StripeTerminalTokenOutput>;
  getTwilioNumbers?: Maybe<GenericResponse>;
  getWorkflowEventActionHistory: GetEntityHistoryOutput;
  getWorkflowEventSettingHistory: GetEntityHistoryOutput;
  healthCheck?: Maybe<GenericResponse>;
  invokeConverseAiRequest: InvokeAiConverseOutput;
  jobChainStatus?: Maybe<JobChainStatus>;
  massTextAlertReport?: Maybe<Array<Maybe<MassTextAlertReportEntry>>>;
  renderWorkflowNotificationBody?: Maybe<Scalars['String']>;
  retrieveAuthTokenForContact?: Maybe<ChurnZeroResponse>;
  savedChannelFilterSelections: Array<ChannelFilterSelection>;
  searchPhoneNumber?: Maybe<SearchPhoneNumberResponse>;
  searchTwilioNumbers?: Maybe<GenericResponse>;
  sendConsultationReceipt?: Maybe<Scalars['Json']>;
  sendDailyCronJobs?: Maybe<Scalars['Json']>;
  sendDailyFinancialReport?: Maybe<Scalars['Json']>;
  sendPetParentReceipt?: Maybe<Scalars['Json']>;
  sendVetSubscriptionReceipt?: Maybe<Scalars['Json']>;
  stripeCreateCustomerPortal?: Maybe<StripeCustomerPortalOutput>;
  stripeCreateLoginLink?: Maybe<LoginLinkOutput>;
  stripeRetrieveConnectedAccount?: Maybe<StripeAccountOutput>;
  stripeRetrievePetParentPortalUrl?: Maybe<StripeCustomerPortalOutput>;
  tokenValidation?: Maybe<Scalars['Json']>;
  trupanionCertificateAvailability?: Maybe<Array<Maybe<TrupanionAvailability>>>;
  trupanionHospitalSearch?: Maybe<Array<Maybe<TrupanionHospital>>>;
  twilioCapabilityToken?: Maybe<TwilioCapabilityToken>;
  userAppointmentGroupingsFilters: Scalars['Json'];
  verificationCheck?: Maybe<GenericResponse>;
  verificationRequest?: Maybe<GenericResponse>;
  verifyTerminalSetupPin: VerifyTerminalSetupPinResponse;
  widgetClinicPetParentLookup2: Array<WidgetClinicPetParentLookupResponse>;
  widgetClinicPetParentLookupEnrollment: Array<Maybe<WidgetClinicPetParentLookupResponse>>;
};


export type QueryAggregateActiveIngredientArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type QueryAggregateAppointmentArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type QueryAggregateAppointmentAvailabilityArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type QueryAggregateAppointmentTypeArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type QueryAggregateAutomationRunArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type QueryAggregateAutomationRunActionArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type QueryAggregateBitwerxHealthcheckArgs = {
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type QueryAggregateBitwerxIntegrationArgs = {
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type QueryAggregateBitwerxPaymentHealthcheckResultArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type QueryAggregateBitwerxPaymentHealthcheckRunArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type QueryAggregateBoardFilterColumnArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type QueryAggregateBoardFilterSpecificDateRangeArgs = {
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type QueryAggregateBoehringerIngelheimCustomerAlignmentArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type QueryAggregateBoehringerIngelheimTerritoryArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type QueryAggregateBreedArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type QueryAggregateCallHistoryArgs = {
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallHistoryWhereInput>;
};


export type QueryAggregateCallParticipantArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type QueryAggregateCallRecordingArgs = {
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallRecordingWhereInput>;
};


export type QueryAggregateCampaignRegistryCampaignArgs = {
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type QueryAggregateCanonicalBreedArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type QueryAggregateCanonicalCanineBreedProfileArgs = {
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type QueryAggregateCanonicalServiceArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type QueryAggregateCanonicalSpeciesArgs = {
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type QueryAggregateCareAccountCreditIntentArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type QueryAggregateCareAccountCreditPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};


export type QueryAggregateCareAddonEnrollmentArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type QueryAggregateCareAddonPackageArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type QueryAggregateCareAddonPackageBenefitArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type QueryAggregateCareAuthorizationRequestArgs = {
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type QueryAggregateCareBenefitArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type QueryAggregateCareBenefitUsageArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type QueryAggregateCareBenefitUsageFollowupArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type QueryAggregateCareBenefitUsageFollowupStepArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type QueryAggregateCareBenefitUsageToInvoiceLineItemArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type QueryAggregateCareEnrollmentPaymentArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type QueryAggregateCareEnrollmentToStripeInvoiceItemArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type QueryAggregateCareLapsedPaymentArgs = {
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type QueryAggregateCarePlanArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type QueryAggregateCarePlanBenefitArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type QueryAggregateCarePlanBenefitEnrollmentCarryoverArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type QueryAggregateCarePlanEnrollmentArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type QueryAggregateCarePlanProviderGroupArgs = {
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type QueryAggregateCareStripeSubscriptionArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type QueryAggregateCareSubscriptionTrueUpArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type QueryAggregateCareSubscriptionTrueUpLogEntryArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type QueryAggregateChannelArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type QueryAggregateChannelAutomationStatusArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type QueryAggregateChannelCreationSourceArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type QueryAggregateChannelFilterSelectionArgs = {
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type QueryAggregateChannelMemberArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type QueryAggregateChannelMessageArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type QueryAggregateChannelMessageAttachmentArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type QueryAggregateChannelMessageAutoResponseArgs = {
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type QueryAggregateChannelMessageReactionArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type QueryAggregateChannelPinArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type QueryAggregateChannelSelectionArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type QueryAggregateChannelStatusArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type QueryAggregateChannelStatusChannelAssigneeArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type QueryAggregateChemicalCompoundArgs = {
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type QueryAggregateClientConnectIntegrationArgs = {
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type QueryAggregateClinicArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type QueryAggregateClinicBlockedPhoneNumberArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type QueryAggregateClinicDirectBookingSettingArgs = {
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type QueryAggregateClinicEmailCampaignSettingArgs = {
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type QueryAggregateClinicEmployeeArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type QueryAggregateClinicEmployeeAppointmentTypeSettingArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type QueryAggregateClinicEntityPhoneNumberArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type QueryAggregateClinicOfficeHourArgs = {
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type QueryAggregateClinicOnboardingArgs = {
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type QueryAggregateClinicPaymentFeeConfigurationArgs = {
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type QueryAggregateClinicPetArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type QueryAggregateClinicPetAlertArgs = {
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type QueryAggregateClinicPetCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type QueryAggregateClinicPetParentArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type QueryAggregateClinicPetParentAddressArgs = {
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type QueryAggregateClinicPetParentCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type QueryAggregateClinicPetParentDeviceArgs = {
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type QueryAggregateClinicPetParentPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type QueryAggregateClinicPetPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type QueryAggregateClinicPhoneNumberArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type QueryAggregateClinicPimsIntegrationArgs = {
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type QueryAggregateClinicPimsIntegrationCapabilityArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type QueryAggregateClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type QueryAggregateClinicPostcardSettingArgs = {
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type QueryAggregateClinicRoomArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type QueryAggregateClinicSettingArgs = {
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type QueryAggregateClinicWidgetRequestArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type QueryAggregateClinicWidgetRequestTypeArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type QueryAggregateClinicWidgetSettingArgs = {
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type QueryAggregateColorArgs = {
  cursor?: Maybe<ColorWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ColorWhereInput>;
};


export type QueryAggregateConditionArgs = {
  cursor?: Maybe<ConditionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionWhereInput>;
};


export type QueryAggregateConditionSetArgs = {
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionSetWhereInput>;
};


export type QueryAggregateConditionalEntityEvaluationArgs = {
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type QueryAggregateConsultationArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type QueryAggregateConsultationRequestArgs = {
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type QueryAggregateCustomFieldDefinitionArgs = {
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type QueryAggregateCustomFieldPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type QueryAggregateDirectBookingAppointmentTypeSettingArgs = {
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type QueryAggregateDisbursementArgs = {
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DisbursementWhereInput>;
};


export type QueryAggregateDocumentationArgs = {
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentationWhereInput>;
};


export type QueryAggregateEmailCampaignArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type QueryAggregateEmailCampaignCustomListArgs = {
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type QueryAggregateEmailCampaignCustomListRecipientArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type QueryAggregateEmailCampaignCustomListUploadHistoryArgs = {
  cursor?: Maybe<EmailCampaignCustomListUploadHistoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type QueryAggregateEmailCampaignLogArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type QueryAggregateEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type QueryAggregateEmailCampaignUnsubscribeArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type QueryAggregateEmailProviderLogArgs = {
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type QueryAggregateEmergencyClinicsArgs = {
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type QueryAggregateEnrollmentSupportEventArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type QueryAggregateEzyVetIntegrationArgs = {
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type QueryAggregateFeatureFlagArgs = {
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type QueryAggregateFinancialAdjustmentArgs = {
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type QueryAggregateFinancialTransactionArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type QueryAggregateFormSubmissionArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type QueryAggregateFormTemplateArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryAggregateGlobalPetParentArgs = {
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type QueryAggregateHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type QueryAggregateInformAudienceDefinitionArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type QueryAggregateInformAutomationCampaignArgs = {
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type QueryAggregateInformCampaignArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};


export type QueryAggregateInformCampaignEnrollmentArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type QueryAggregateInformCampaignEnrollmentCanonicalServiceArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type QueryAggregateInformCampaignExecutionArgs = {
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type QueryAggregateInformCampaignGroupArgs = {
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type QueryAggregateInformControlGroupParticipantArgs = {
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type QueryAggregateInformEmailTemplateArgs = {
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type QueryAggregateInformEntityHistoryArgs = {
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type QueryAggregateInformPartnerArgs = {
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerWhereInput>;
};


export type QueryAggregateInformPartnerCustomerArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type QueryAggregateInformPartnerCustomerPharmacyProviderArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type QueryAggregateInformPartnerIntegrationArgs = {
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type QueryAggregateInformPartnerIntegrationModelArgs = {
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type QueryAggregateInformPartnerProgramArgs = {
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type QueryAggregateInformPartnerProgramEnrollmentArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type QueryAggregateInformPartnerUserArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type QueryAggregateInformPartnerUserRoleArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type QueryAggregateInformScheduledCampaignArgs = {
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type QueryAggregateInstinctIntegrationArgs = {
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type QueryAggregateInsuranceIntegrationArgs = {
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type QueryAggregateIntegrationOutageHistoryEntryArgs = {
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type QueryAggregateInterchangeRateArgs = {
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type QueryAggregateInvoiceArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type QueryAggregateInvoiceLineItemArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type QueryAggregateInvoicePimsWritebackLogEntryArgs = {
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type QueryAggregateLapsedPetParentTriggersArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type QueryAggregateLegalEntityArgs = {
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LegalEntityWhereInput>;
};


export type QueryAggregateLoyaltyAccountArgs = {
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type QueryAggregateLoyaltyAccountHolderArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type QueryAggregateLoyaltyAccountMergerArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type QueryAggregateLoyaltyInvoicePointGranterArgs = {
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type QueryAggregateLoyaltyPointDeltaArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type QueryAggregateLoyaltyPointGrantingServiceArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type QueryAggregateLoyaltyPointGrantingServiceCategoryArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type QueryAggregateLoyaltyPointUserGrantArgs = {
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type QueryAggregateLoyaltyProgramArgs = {
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramWhereInput>;
};


export type QueryAggregateLoyaltyProgramStatusHistoryArgs = {
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};


export type QueryAggregateLoyaltyRewardArgs = {
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type QueryAggregateLoyaltyRewardRedemptionArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type QueryAggregateLoyaltyRewardRedemptionHistoryEntryArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type QueryAggregateMassTextAlertArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type QueryAggregateMassTextAlertEntryArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type QueryAggregateMassTextAlertEntryAppointmentArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type QueryAggregateMediaArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type QueryAggregateMessageTemplateArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type QueryAggregateMessagingCampaignArgs = {
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type QueryAggregateMessagingPartnerArgs = {
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type QueryAggregateNonPrismaSchemaScriptRanArgs = {
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type QueryAggregateNotificationEventToMessageArgs = {
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type QueryAggregateOrganizationArgs = {
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryAggregateOrganizationCareBenefitToClinicServiceArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type QueryAggregateOrganizationCareBenefitToClinicServiceCategoryArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type QueryAggregateOrganizationPetArgs = {
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type QueryAggregateOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type QueryAggregateOrganizationPetToOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type QueryAggregatePpcIntegrationArgs = {
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type QueryAggregatePermissionArgs = {
  cursor?: Maybe<PermissionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PermissionWhereInput>;
};


export type QueryAggregatePetParentSettingArgs = {
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type QueryAggregatePetPortalSettingArgs = {
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type QueryAggregatePhoneNumberLookupRequestArgs = {
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type QueryAggregatePhoneNumberLookupResultArgs = {
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type QueryAggregatePimsInvoiceArgs = {
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type QueryAggregatePimsInvoiceLineItemArgs = {
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type QueryAggregatePimsPaymentTypeArgs = {
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsPaymentTypeWhereInput>;
};


export type QueryAggregatePimsWritebackLogEntryArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type QueryAggregatePrescriptionArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryAggregateProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type QueryAggregatePushNotificationLogArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type QueryAggregateRulesArgs = {
  cursor?: Maybe<RulesWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RulesWhereInput>;
};


export type QueryAggregateSegmentArgs = {
  cursor?: Maybe<SegmentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SegmentWhereInput>;
};


export type QueryAggregateSentTrupanionExamDayOfferArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type QueryAggregateServiceArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type QueryAggregateServiceCategoryArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type QueryAggregateServiceReminderArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type QueryAggregateServiceReminderNotificationArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type QueryAggregateServiceReminderTimingArgs = {
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type QueryAggregateSexArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};


export type QueryAggregateSmsNotificationStatusArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type QueryAggregateSpeciesArgs = {
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpeciesWhereInput>;
};


export type QueryAggregateStaffRoleArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type QueryAggregateStripeCustomerArgs = {
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type QueryAggregateStripeDisputeArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type QueryAggregateStripeInvoiceArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type QueryAggregateStripeInvoiceItemArgs = {
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type QueryAggregateStripePaymentIntentArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type QueryAggregateStripePaymentMethodArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type QueryAggregateStripePayoutArgs = {
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutWhereInput>;
};


export type QueryAggregateStripePayoutAccountArgs = {
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type QueryAggregateStripeReceiptArgs = {
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type QueryAggregateStripeReceiptHistoryArgs = {
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type QueryAggregateStripeRefundArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type QueryAggregateStripeTerminalArgs = {
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type QueryAggregateStripeTerminalLocationArgs = {
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type QueryAggregateSurveyAppointmentTypeSettingArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type QueryAggregateSurveyResultArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type QueryAggregateSurveySettingArgs = {
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveySettingWhereInput>;
};


export type QueryAggregateSyncVetIntegrationArgs = {
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type QueryAggregateTransactionArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QueryAggregateTrupanionCertificateAvailabilityArgs = {
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type QueryAggregateTrupanionIntegratedAppointmentTypeArgs = {
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type QueryAggregateTrupanionIntegrationArgs = {
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type QueryAggregateTwilioCallRecordingArgs = {
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TwilioCallRecordingWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryAggregateUserAppointmentGroupingArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type QueryAggregateUserBoardFilterArgs = {
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type QueryAggregateUserChannelFilterSelectionArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type QueryAggregateUserNotificationSettingArgs = {
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type QueryAggregateUserSettingArgs = {
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserSettingWhereInput>;
};


export type QueryAggregateVaccinationArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type QueryAggregateVetAvailabilityArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type QueryAggregateVetAvailabilityClinicRoomMapArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type QueryAggregateVetAvailabilityMapArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type QueryAggregateVetInfoArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type QueryAggregateVetsourceCodeTagArgs = {
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type QueryAggregateVetsourceInstallationArgs = {
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type QueryAggregateVetsourceRevenueCategoryArgs = {
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type QueryAggregateWorkflowEventSettingArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type QueryAiChannelSuggestedMessageArgs = {
  alternatePrompt?: Maybe<Scalars['String']>;
  maxTokensToSample?: Maybe<Scalars['Int']>;
  where: ChannelSuggestedMessageWhereInput;
};


export type QueryCalculateMultiplePetsBenefitUsageArgs = {
  where?: Maybe<CalculateMultiplePetsBenefitUsageInput>;
};


export type QueryCalculatePetBenefitUsageArgs = {
  where?: Maybe<CalculatePetBenefitUsageInput>;
};


export type QueryCanManuallyRetryPaymentWritebackArgs = {
  stripePaymentIntentId: Scalars['String'];
};


export type QueryCanSendEmailCampaignArgs = {
  clinicId: Scalars['String'];
};


export type QueryChannelExportArgs = {
  where: ChannelExportWhereInput;
};


export type QueryChannelSearchArgs = {
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SearchChannelInput>;
};


export type QueryCheckClinicCareStatusArgs = {
  data: CheckClinicCareInput;
};


export type QueryCheckPhoneVerificationArgs = {
  data: CheckPhoneVerificationInput;
};


export type QueryClinicPetParentSearchArgs = {
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SearchClinicPetParentInput>;
};


export type QueryClinicPetSearchArgs = {
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SearchClinicPetInput>;
};


export type QueryCustomFindUniqueUserBoardFilterArgs = {
  where: CustomFindUniqueUserBoardFilterWhereInput;
};


export type QueryEstimateNumberOfPetParentsReachedArgs = {
  alertBody: Scalars['String'];
  appointmentDateTimeFrom: Scalars['String'];
  appointmentDateTimeTo: Scalars['String'];
  clinicId: Scalars['String'];
};


export type QueryExportCancellationSummaryAsPdfArgs = {
  data: ExportCancellationSummaryAsPdfInput;
};


export type QueryExportItemizedInvoiceAsReceiptPdfArgs = {
  data: ExportItemizedInvoiceToReceiptPdfInput;
};


export type QueryExportNonItemizedReceiptAsPdfArgs = {
  data: ExportNonItemizedReceiptAsPdfInput;
};


export type QueryFindClinicContactInfoArgs = {
  clinicId: Scalars['String'];
};


export type QueryFindClinicPetParentByPhoneOrEmailArgs = {
  where: FindClinicPetParentByPhoneOrEmailRequest;
};


export type QueryFindCurrentEmailTemplateVersionsArgs = {
  data: FindCurrentEmailTemplateVersionInput;
};


export type QueryFindEmployerIdentificationNumberArgs = {
  data: FindEmployerIdentificationNumberInput;
};


export type QueryFindFirstActiveIngredientArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type QueryFindFirstAppointmentArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type QueryFindFirstAppointmentAvailabilityArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type QueryFindFirstAppointmentTypeArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type QueryFindFirstAutomationRunArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type QueryFindFirstAutomationRunActionArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type QueryFindFirstBitwerxHealthcheckArgs = {
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type QueryFindFirstBitwerxIntegrationArgs = {
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type QueryFindFirstBitwerxPaymentHealthcheckResultArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type QueryFindFirstBitwerxPaymentHealthcheckRunArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type QueryFindFirstBitwerxPaymentWritebackConfigurationArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type QueryFindFirstBoardFilterColumnArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type QueryFindFirstBoardFilterSpecificDateRangeArgs = {
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type QueryFindFirstBoehringerIngelheimCustomerAlignmentArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type QueryFindFirstBoehringerIngelheimTerritoryArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type QueryFindFirstBreedArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type QueryFindFirstCallHistoryArgs = {
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallHistoryWhereInput>;
};


export type QueryFindFirstCallParticipantArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type QueryFindFirstCallRecordingArgs = {
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallRecordingWhereInput>;
};


export type QueryFindFirstCampaignRegistryCampaignArgs = {
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type QueryFindFirstCanonicalBreedArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type QueryFindFirstCanonicalCanineBreedProfileArgs = {
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type QueryFindFirstCanonicalServiceArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type QueryFindFirstCanonicalSpeciesArgs = {
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type QueryFindFirstCareAccountCreditIntentArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type QueryFindFirstCareAccountCreditPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};


export type QueryFindFirstCareAddonEnrollmentArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type QueryFindFirstCareAddonPackageArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type QueryFindFirstCareAddonPackageBenefitArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type QueryFindFirstCareAuthorizationRequestArgs = {
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type QueryFindFirstCareBenefitArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type QueryFindFirstCareBenefitUsageArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type QueryFindFirstCareBenefitUsageFollowupArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type QueryFindFirstCareBenefitUsageFollowupStepArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type QueryFindFirstCareBenefitUsageToInvoiceLineItemArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type QueryFindFirstCareEnrollmentPaymentArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type QueryFindFirstCareEnrollmentToStripeInvoiceItemArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type QueryFindFirstCareLapsedPaymentArgs = {
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type QueryFindFirstCarePlanArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type QueryFindFirstCarePlanBenefitArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type QueryFindFirstCarePlanBenefitEnrollmentCarryoverArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type QueryFindFirstCarePlanEnrollmentArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type QueryFindFirstCarePlanProviderGroupArgs = {
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type QueryFindFirstCareStripeSubscriptionArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type QueryFindFirstCareSubscriptionTrueUpArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type QueryFindFirstCareSubscriptionTrueUpLogEntryArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type QueryFindFirstChannelArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type QueryFindFirstChannelAutomationStatusArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type QueryFindFirstChannelCreationSourceArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type QueryFindFirstChannelFilterSelectionArgs = {
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type QueryFindFirstChannelMemberArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type QueryFindFirstChannelMessageArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type QueryFindFirstChannelMessageAttachmentArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type QueryFindFirstChannelMessageAutoResponseArgs = {
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type QueryFindFirstChannelMessageReactionArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type QueryFindFirstChannelPinArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type QueryFindFirstChannelSelectionArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type QueryFindFirstChannelStatusArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type QueryFindFirstChannelStatusChannelAssigneeArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type QueryFindFirstChemicalCompoundArgs = {
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type QueryFindFirstClientConnectIntegrationArgs = {
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type QueryFindFirstClinicArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type QueryFindFirstClinicBlockedPhoneNumberArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type QueryFindFirstClinicDirectBookingSettingArgs = {
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type QueryFindFirstClinicEmailCampaignSettingArgs = {
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type QueryFindFirstClinicEmployeeArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type QueryFindFirstClinicEmployeeAppointmentTypeSettingArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type QueryFindFirstClinicEntityPhoneNumberArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type QueryFindFirstClinicOfficeHourArgs = {
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type QueryFindFirstClinicOnboardingArgs = {
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type QueryFindFirstClinicPaymentFeeConfigurationArgs = {
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type QueryFindFirstClinicPetArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type QueryFindFirstClinicPetAlertArgs = {
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type QueryFindFirstClinicPetCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type QueryFindFirstClinicPetParentArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type QueryFindFirstClinicPetParentAddressArgs = {
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type QueryFindFirstClinicPetParentCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type QueryFindFirstClinicPetParentDeviceArgs = {
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type QueryFindFirstClinicPetParentPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type QueryFindFirstClinicPetPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type QueryFindFirstClinicPhoneNumberArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type QueryFindFirstClinicPimsIntegrationArgs = {
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type QueryFindFirstClinicPimsIntegrationCapabilityArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type QueryFindFirstClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type QueryFindFirstClinicPostcardSettingArgs = {
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type QueryFindFirstClinicRoomArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type QueryFindFirstClinicSettingArgs = {
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type QueryFindFirstClinicWidgetRequestArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type QueryFindFirstClinicWidgetRequestTypeArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type QueryFindFirstClinicWidgetSettingArgs = {
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type QueryFindFirstColorArgs = {
  cursor?: Maybe<ColorWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ColorWhereInput>;
};


export type QueryFindFirstConditionArgs = {
  cursor?: Maybe<ConditionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionWhereInput>;
};


export type QueryFindFirstConditionSetArgs = {
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionSetWhereInput>;
};


export type QueryFindFirstConditionalEntityEvaluationArgs = {
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type QueryFindFirstConsultationArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type QueryFindFirstConsultationRequestArgs = {
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type QueryFindFirstCustomFieldDefinitionArgs = {
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type QueryFindFirstCustomFieldPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type QueryFindFirstDirectBookingAppointmentTypeSettingArgs = {
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type QueryFindFirstDirectBookingExclusionRuleArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type QueryFindFirstDirectBookingExclusionTimeSlotArgs = {
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type QueryFindFirstDisbursementArgs = {
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DisbursementWhereInput>;
};


export type QueryFindFirstDocumentationArgs = {
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentationWhereInput>;
};


export type QueryFindFirstEmailCampaignArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type QueryFindFirstEmailCampaignCustomListArgs = {
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type QueryFindFirstEmailCampaignCustomListRecipientArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type QueryFindFirstEmailCampaignCustomListUploadHistoryArgs = {
  cursor?: Maybe<EmailCampaignCustomListUploadHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type QueryFindFirstEmailCampaignLogArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type QueryFindFirstEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type QueryFindFirstEmailCampaignUnsubscribeArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type QueryFindFirstEmailProviderLogArgs = {
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type QueryFindFirstEmergencyClinicsArgs = {
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type QueryFindFirstEnrollmentSupportEventArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type QueryFindFirstEzyVetIntegrationArgs = {
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type QueryFindFirstFeatureFlagArgs = {
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type QueryFindFirstFinancialAdjustmentArgs = {
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type QueryFindFirstFinancialTransactionArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type QueryFindFirstFormSubmissionArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type QueryFindFirstFormTemplateArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryFindFirstGlobalPetParentArgs = {
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type QueryFindFirstHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type QueryFindFirstInformAudienceDefinitionArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type QueryFindFirstInformAutomationCampaignArgs = {
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type QueryFindFirstInformCampaignArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};


export type QueryFindFirstInformCampaignEnrollmentArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type QueryFindFirstInformCampaignEnrollmentCanonicalServiceArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type QueryFindFirstInformCampaignExecutionArgs = {
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type QueryFindFirstInformCampaignGroupArgs = {
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type QueryFindFirstInformControlGroupParticipantArgs = {
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type QueryFindFirstInformEmailTemplateArgs = {
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type QueryFindFirstInformEntityHistoryArgs = {
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type QueryFindFirstInformPartnerArgs = {
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerWhereInput>;
};


export type QueryFindFirstInformPartnerCustomerArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type QueryFindFirstInformPartnerCustomerPharmacyProviderArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type QueryFindFirstInformPartnerIntegrationArgs = {
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type QueryFindFirstInformPartnerIntegrationModelArgs = {
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type QueryFindFirstInformPartnerProgramArgs = {
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type QueryFindFirstInformPartnerProgramEnrollmentArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type QueryFindFirstInformPartnerUserArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type QueryFindFirstInformPartnerUserRoleArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type QueryFindFirstInformScheduledCampaignArgs = {
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type QueryFindFirstInstinctIntegrationArgs = {
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type QueryFindFirstInsuranceIntegrationArgs = {
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type QueryFindFirstIntegrationOutageHistoryEntryArgs = {
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type QueryFindFirstInterchangeRateArgs = {
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type QueryFindFirstInvoiceArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type QueryFindFirstInvoiceLineItemArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type QueryFindFirstInvoicePimsWritebackLogEntryArgs = {
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type QueryFindFirstLapsedPetParentTriggersArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type QueryFindFirstLegalEntityArgs = {
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LegalEntityWhereInput>;
};


export type QueryFindFirstLoyaltyAccountArgs = {
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type QueryFindFirstLoyaltyAccountHolderArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type QueryFindFirstLoyaltyAccountMergerArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type QueryFindFirstLoyaltyInvoicePointGranterArgs = {
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type QueryFindFirstLoyaltyPointDeltaArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type QueryFindFirstLoyaltyPointGrantingServiceArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type QueryFindFirstLoyaltyPointGrantingServiceCategoryArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type QueryFindFirstLoyaltyPointUserGrantArgs = {
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type QueryFindFirstLoyaltyProgramArgs = {
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramWhereInput>;
};


export type QueryFindFirstLoyaltyProgramStatusHistoryArgs = {
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};


export type QueryFindFirstLoyaltyRewardArgs = {
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type QueryFindFirstLoyaltyRewardRedemptionArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type QueryFindFirstLoyaltyRewardRedemptionHistoryEntryArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type QueryFindFirstMassTextAlertArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type QueryFindFirstMassTextAlertEntryArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type QueryFindFirstMassTextAlertEntryAppointmentArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type QueryFindFirstMediaArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type QueryFindFirstMessageTemplateArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type QueryFindFirstMessagingCampaignArgs = {
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type QueryFindFirstMessagingPartnerArgs = {
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type QueryFindFirstNonPrismaSchemaScriptRanArgs = {
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type QueryFindFirstNotificationEventToMessageArgs = {
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type QueryFindFirstOrganizationArgs = {
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryFindFirstOrganizationCareBenefitToClinicServiceArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type QueryFindFirstOrganizationCareBenefitToClinicServiceCategoryArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type QueryFindFirstOrganizationPetArgs = {
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type QueryFindFirstOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type QueryFindFirstOrganizationPetToOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type QueryFindFirstPpcIntegrationArgs = {
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type QueryFindFirstPayoutBatchingPeriodArgs = {
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type QueryFindFirstPermissionArgs = {
  cursor?: Maybe<PermissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PermissionWhereInput>;
};


export type QueryFindFirstPetParentSettingArgs = {
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type QueryFindFirstPetPortalSettingArgs = {
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type QueryFindFirstPhoneNumberLookupRequestArgs = {
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type QueryFindFirstPhoneNumberLookupResultArgs = {
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type QueryFindFirstPimsInvoiceArgs = {
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type QueryFindFirstPimsInvoiceLineItemArgs = {
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type QueryFindFirstPimsPaymentTypeArgs = {
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsPaymentTypeWhereInput>;
};


export type QueryFindFirstPimsWritebackLogEntryArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type QueryFindFirstPrescriptionArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type QueryFindFirstProductArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryFindFirstProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type QueryFindFirstPushNotificationLogArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type QueryFindFirstRulesArgs = {
  cursor?: Maybe<RulesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RulesWhereInput>;
};


export type QueryFindFirstSegmentArgs = {
  cursor?: Maybe<SegmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SegmentWhereInput>;
};


export type QueryFindFirstSentTrupanionExamDayOfferArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type QueryFindFirstServiceArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type QueryFindFirstServiceCategoryArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type QueryFindFirstServiceReminderArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type QueryFindFirstServiceReminderNotificationArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type QueryFindFirstServiceReminderTimingArgs = {
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type QueryFindFirstSexArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};


export type QueryFindFirstSmsNotificationStatusArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type QueryFindFirstSpeciesArgs = {
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpeciesWhereInput>;
};


export type QueryFindFirstStaffRoleArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type QueryFindFirstStripeCustomerArgs = {
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type QueryFindFirstStripeDisputeArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type QueryFindFirstStripeInvoiceArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type QueryFindFirstStripeInvoiceItemArgs = {
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type QueryFindFirstStripePaymentIntentArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type QueryFindFirstStripePaymentMethodArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type QueryFindFirstStripePayoutArgs = {
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutWhereInput>;
};


export type QueryFindFirstStripePayoutAccountArgs = {
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type QueryFindFirstStripeReceiptArgs = {
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type QueryFindFirstStripeReceiptHistoryArgs = {
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type QueryFindFirstStripeRefundArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type QueryFindFirstStripeTerminalArgs = {
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type QueryFindFirstStripeTerminalLocationArgs = {
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type QueryFindFirstSurveyAppointmentTypeSettingArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type QueryFindFirstSurveyResultArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type QueryFindFirstSurveySettingArgs = {
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveySettingWhereInput>;
};


export type QueryFindFirstSyncVetIntegrationArgs = {
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type QueryFindFirstTransactionArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QueryFindFirstTrupanionCertificateAvailabilityArgs = {
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type QueryFindFirstTrupanionIntegratedAppointmentTypeArgs = {
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type QueryFindFirstTrupanionIntegrationArgs = {
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type QueryFindFirstTwilioCallRecordingArgs = {
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TwilioCallRecordingWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryFindFirstUserAppointmentGroupingArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type QueryFindFirstUserBoardFilterArgs = {
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type QueryFindFirstUserChannelFilterSelectionArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type QueryFindFirstUserNotificationSettingArgs = {
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type QueryFindFirstUserSettingArgs = {
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserSettingWhereInput>;
};


export type QueryFindFirstVaccinationArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type QueryFindFirstVetAvailabilityArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type QueryFindFirstVetAvailabilityClinicRoomMapArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type QueryFindFirstVetAvailabilityMapArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type QueryFindFirstVetInfoArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type QueryFindFirstVetsourceCodeTagArgs = {
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type QueryFindFirstVetsourceInstallationArgs = {
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type QueryFindFirstVetsourceRevenueCategoryArgs = {
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type QueryFindFirstWorkflowEventSettingArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type QueryFindManyActiveIngredientArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type QueryFindManyActiveIngredientCountArgs = {
  cursor?: Maybe<ActiveIngredientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ActiveIngredientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ActiveIngredientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ActiveIngredientWhereInput>;
};


export type QueryFindManyAppointmentArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type QueryFindManyAppointmentAvailabilityArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type QueryFindManyAppointmentAvailabilityCountArgs = {
  cursor?: Maybe<AppointmentAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentAvailabilityWhereInput>;
};


export type QueryFindManyAppointmentCountArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type QueryFindManyAppointmentTypeArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type QueryFindManyAppointmentTypeCountArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type QueryFindManyAutomationRunArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type QueryFindManyAutomationRunActionArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type QueryFindManyAutomationRunActionCountArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunActionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type QueryFindManyAutomationRunCountArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<AutomationRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type QueryFindManyBitwerxHealthcheckArgs = {
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type QueryFindManyBitwerxHealthcheckCountArgs = {
  cursor?: Maybe<BitwerxHealthcheckWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxHealthcheckScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxHealthcheckOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxHealthcheckWhereInput>;
};


export type QueryFindManyBitwerxIntegrationArgs = {
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type QueryFindManyBitwerxIntegrationCountArgs = {
  cursor?: Maybe<BitwerxIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxIntegrationWhereInput>;
};


export type QueryFindManyBitwerxPaymentHealthcheckResultArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type QueryFindManyBitwerxPaymentHealthcheckResultCountArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckResultWhereInput>;
};


export type QueryFindManyBitwerxPaymentHealthcheckRunArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type QueryFindManyBitwerxPaymentHealthcheckRunCountArgs = {
  cursor?: Maybe<BitwerxPaymentHealthcheckRunWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentHealthcheckRunOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentHealthcheckRunWhereInput>;
};


export type QueryFindManyBitwerxPaymentWritebackConfigurationCountArgs = {
  cursor?: Maybe<BitwerxPaymentWritebackConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BitwerxPaymentWritebackConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BitwerxPaymentWritebackConfigurationWhereInput>;
};


export type QueryFindManyBoardFilterColumnArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type QueryFindManyBoardFilterColumnCountArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterColumnScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};


export type QueryFindManyBoardFilterSpecificDateRangeArgs = {
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type QueryFindManyBoardFilterSpecificDateRangeCountArgs = {
  cursor?: Maybe<BoardFilterSpecificDateRangeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
};


export type QueryFindManyBoehringerIngelheimCustomerAlignmentArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type QueryFindManyBoehringerIngelheimCustomerAlignmentCountArgs = {
  cursor?: Maybe<BoehringerIngelheimCustomerAlignmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimCustomerAlignmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimCustomerAlignmentWhereInput>;
};


export type QueryFindManyBoehringerIngelheimTerritoryArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type QueryFindManyBoehringerIngelheimTerritoryCountArgs = {
  cursor?: Maybe<BoehringerIngelheimTerritoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BoehringerIngelheimTerritoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoehringerIngelheimTerritoryWhereInput>;
};


export type QueryFindManyBreedArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type QueryFindManyBreedCountArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<BreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type QueryFindManyCallHistoryArgs = {
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallHistoryWhereInput>;
};


export type QueryFindManyCallHistoryCountArgs = {
  cursor?: Maybe<CallHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallHistoryWhereInput>;
};


export type QueryFindManyCallParticipantArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type QueryFindManyCallParticipantCountArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type QueryFindManyCallRecordingArgs = {
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallRecordingWhereInput>;
};


export type QueryFindManyCallRecordingCountArgs = {
  cursor?: Maybe<CallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallRecordingWhereInput>;
};


export type QueryFindManyCampaignRegistryCampaignArgs = {
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type QueryFindManyCampaignRegistryCampaignCountArgs = {
  cursor?: Maybe<CampaignRegistryCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CampaignRegistryCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CampaignRegistryCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignRegistryCampaignWhereInput>;
};


export type QueryFindManyCanonicalBreedArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type QueryFindManyCanonicalBreedCountArgs = {
  cursor?: Maybe<CanonicalBreedWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalBreedScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalBreedOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalBreedWhereInput>;
};


export type QueryFindManyCanonicalCanineBreedProfileArgs = {
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type QueryFindManyCanonicalCanineBreedProfileCountArgs = {
  cursor?: Maybe<CanonicalCanineBreedProfileWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalCanineBreedProfileScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalCanineBreedProfileOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalCanineBreedProfileWhereInput>;
};


export type QueryFindManyCanonicalServiceArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type QueryFindManyCanonicalServiceCountArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};


export type QueryFindManyCanonicalSpeciesArgs = {
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type QueryFindManyCanonicalSpeciesCountArgs = {
  cursor?: Maybe<CanonicalSpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CanonicalSpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CanonicalSpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalSpeciesWhereInput>;
};


export type QueryFindManyCareAccountCreditIntentArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type QueryFindManyCareAccountCreditIntentCountArgs = {
  cursor?: Maybe<CareAccountCreditIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditIntentWhereInput>;
};


export type QueryFindManyCareAccountCreditPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyCareAccountCreditPimsWritebackLogEntryCountArgs = {
  cursor?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAccountCreditPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAccountCreditPimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyCareAddonEnrollmentArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type QueryFindManyCareAddonEnrollmentCountArgs = {
  cursor?: Maybe<CareAddonEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonEnrollmentWhereInput>;
};


export type QueryFindManyCareAddonPackageArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type QueryFindManyCareAddonPackageBenefitArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type QueryFindManyCareAddonPackageBenefitCountArgs = {
  cursor?: Maybe<CareAddonPackageBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageBenefitWhereInput>;
};


export type QueryFindManyCareAddonPackageCountArgs = {
  cursor?: Maybe<CareAddonPackageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAddonPackageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAddonPackageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAddonPackageWhereInput>;
};


export type QueryFindManyCareAuthorizationRequestArgs = {
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type QueryFindManyCareAuthorizationRequestCountArgs = {
  cursor?: Maybe<CareAuthorizationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareAuthorizationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareAuthorizationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareAuthorizationRequestWhereInput>;
};


export type QueryFindManyCareBenefitArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type QueryFindManyCareBenefitCountArgs = {
  cursor?: Maybe<CareBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitWhereInput>;
};


export type QueryFindManyCareBenefitUsageArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type QueryFindManyCareBenefitUsageCountArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type QueryFindManyCareBenefitUsageFollowupArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type QueryFindManyCareBenefitUsageFollowupCountArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupWhereInput>;
};


export type QueryFindManyCareBenefitUsageFollowupStepArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type QueryFindManyCareBenefitUsageFollowupStepCountArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type QueryFindManyCareBenefitUsageToInvoiceLineItemArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type QueryFindManyCareBenefitUsageToInvoiceLineItemCountArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type QueryFindManyCareEnrollmentPaymentArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type QueryFindManyCareEnrollmentPaymentCountArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type QueryFindManyCareEnrollmentToStripeInvoiceItemArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type QueryFindManyCareEnrollmentToStripeInvoiceItemCountArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};


export type QueryFindManyCareLapsedPaymentArgs = {
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type QueryFindManyCareLapsedPaymentCountArgs = {
  cursor?: Maybe<CareLapsedPaymentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareLapsedPaymentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareLapsedPaymentWhereInput>;
};


export type QueryFindManyCarePlanArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type QueryFindManyCarePlanBenefitArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type QueryFindManyCarePlanBenefitCountArgs = {
  cursor?: Maybe<CarePlanBenefitWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitWhereInput>;
};


export type QueryFindManyCarePlanBenefitEnrollmentCarryoverArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type QueryFindManyCarePlanBenefitEnrollmentCarryoverCountArgs = {
  cursor?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanBenefitEnrollmentCarryoverOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanBenefitEnrollmentCarryoverWhereInput>;
};


export type QueryFindManyCarePlanCountArgs = {
  cursor?: Maybe<CarePlanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanWhereInput>;
};


export type QueryFindManyCarePlanEnrollmentArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type QueryFindManyCarePlanEnrollmentCountArgs = {
  cursor?: Maybe<CarePlanEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanEnrollmentWhereInput>;
};


export type QueryFindManyCarePlanProviderGroupArgs = {
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type QueryFindManyCarePlanProviderGroupCountArgs = {
  cursor?: Maybe<CarePlanProviderGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CarePlanProviderGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CarePlanProviderGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CarePlanProviderGroupWhereInput>;
};


export type QueryFindManyCareStripeSubscriptionArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type QueryFindManyCareStripeSubscriptionCountArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareStripeSubscriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type QueryFindManyCareSubscriptionTrueUpArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type QueryFindManyCareSubscriptionTrueUpCountArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type QueryFindManyCareSubscriptionTrueUpLogEntryArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type QueryFindManyCareSubscriptionTrueUpLogEntryCountArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CareSubscriptionTrueUpLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpLogEntryWhereInput>;
};


export type QueryFindManyChannelArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type QueryFindManyChannelAutomationStatusArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type QueryFindManyChannelAutomationStatusCountArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelAutomationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type QueryFindManyChannelCountArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type QueryFindManyChannelCreationSourceArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type QueryFindManyChannelCreationSourceCountArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelCreationSourceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type QueryFindManyChannelFilterSelectionArgs = {
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type QueryFindManyChannelFilterSelectionCountArgs = {
  cursor?: Maybe<ChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelFilterSelectionWhereInput>;
};


export type QueryFindManyChannelMemberArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type QueryFindManyChannelMemberCountArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMemberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type QueryFindManyChannelMessageArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type QueryFindManyChannelMessageAttachmentArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type QueryFindManyChannelMessageAttachmentCountArgs = {
  cursor?: Maybe<ChannelMessageAttachmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAttachmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAttachmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAttachmentWhereInput>;
};


export type QueryFindManyChannelMessageAutoResponseArgs = {
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type QueryFindManyChannelMessageAutoResponseCountArgs = {
  cursor?: Maybe<ChannelMessageAutoResponseWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageAutoResponseScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageAutoResponseOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageAutoResponseWhereInput>;
};


export type QueryFindManyChannelMessageCountArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type QueryFindManyChannelMessageReactionArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type QueryFindManyChannelMessageReactionCountArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelMessageReactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type QueryFindManyChannelPinArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type QueryFindManyChannelPinCountArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelPinScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type QueryFindManyChannelSelectionArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type QueryFindManyChannelSelectionCountArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type QueryFindManyChannelStatusArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type QueryFindManyChannelStatusChannelAssigneeArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type QueryFindManyChannelStatusChannelAssigneeCountArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type QueryFindManyChannelStatusCountArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChannelStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type QueryFindManyChemicalCompoundArgs = {
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type QueryFindManyChemicalCompoundCountArgs = {
  cursor?: Maybe<ChemicalCompoundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ChemicalCompoundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ChemicalCompoundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChemicalCompoundWhereInput>;
};


export type QueryFindManyClientConnectIntegrationArgs = {
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type QueryFindManyClientConnectIntegrationCountArgs = {
  cursor?: Maybe<ClientConnectIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClientConnectIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClientConnectIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientConnectIntegrationWhereInput>;
};


export type QueryFindManyClinicArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type QueryFindManyClinicBlockedPhoneNumberArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type QueryFindManyClinicBlockedPhoneNumberCountArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type QueryFindManyClinicCountArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type QueryFindManyClinicDirectBookingSettingArgs = {
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type QueryFindManyClinicDirectBookingSettingCountArgs = {
  cursor?: Maybe<ClinicDirectBookingSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicDirectBookingSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicDirectBookingSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicDirectBookingSettingWhereInput>;
};


export type QueryFindManyClinicEmailCampaignSettingArgs = {
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type QueryFindManyClinicEmailCampaignSettingCountArgs = {
  cursor?: Maybe<ClinicEmailCampaignSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmailCampaignSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmailCampaignSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmailCampaignSettingWhereInput>;
};


export type QueryFindManyClinicEmployeeArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type QueryFindManyClinicEmployeeAppointmentTypeSettingArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type QueryFindManyClinicEmployeeAppointmentTypeSettingCountArgs = {
  cursor?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeAppointmentTypeSettingWhereInput>;
};


export type QueryFindManyClinicEmployeeCountArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEmployeeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type QueryFindManyClinicEntityPhoneNumberArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type QueryFindManyClinicEntityPhoneNumberCountArgs = {
  cursor?: Maybe<ClinicEntityPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicEntityPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicEntityPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEntityPhoneNumberWhereInput>;
};


export type QueryFindManyClinicOfficeHourArgs = {
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type QueryFindManyClinicOfficeHourCountArgs = {
  cursor?: Maybe<ClinicOfficeHourWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOfficeHourScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOfficeHourOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOfficeHourWhereInput>;
};


export type QueryFindManyClinicOnboardingArgs = {
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type QueryFindManyClinicOnboardingCountArgs = {
  cursor?: Maybe<ClinicOnboardingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicOnboardingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicOnboardingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicOnboardingWhereInput>;
};


export type QueryFindManyClinicPaymentFeeConfigurationArgs = {
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type QueryFindManyClinicPaymentFeeConfigurationCountArgs = {
  cursor?: Maybe<ClinicPaymentFeeConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPaymentFeeConfigurationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPaymentFeeConfigurationWhereInput>;
};


export type QueryFindManyClinicPetArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type QueryFindManyClinicPetAlertArgs = {
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type QueryFindManyClinicPetAlertCountArgs = {
  cursor?: Maybe<ClinicPetAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetAlertWhereInput>;
};


export type QueryFindManyClinicPetCountArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type QueryFindManyClinicPetCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type QueryFindManyClinicPetCustomFieldValueCountArgs = {
  cursor?: Maybe<ClinicPetCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetCustomFieldValueWhereInput>;
};


export type QueryFindManyClinicPetParentArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type QueryFindManyClinicPetParentAddressArgs = {
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type QueryFindManyClinicPetParentAddressCountArgs = {
  cursor?: Maybe<ClinicPetParentAddressWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentAddressScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentAddressOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentAddressWhereInput>;
};


export type QueryFindManyClinicPetParentCountArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type QueryFindManyClinicPetParentCustomFieldValueArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type QueryFindManyClinicPetParentCustomFieldValueCountArgs = {
  cursor?: Maybe<ClinicPetParentCustomFieldValueWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentCustomFieldValueOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentCustomFieldValueWhereInput>;
};


export type QueryFindManyClinicPetParentDeviceArgs = {
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type QueryFindManyClinicPetParentDeviceCountArgs = {
  cursor?: Maybe<ClinicPetParentDeviceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentDeviceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentDeviceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentDeviceWhereInput>;
};


export type QueryFindManyClinicPetParentPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type QueryFindManyClinicPetParentPimsSyncStatusCountArgs = {
  cursor?: Maybe<ClinicPetParentPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetParentPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentPimsSyncStatusWhereInput>;
};


export type QueryFindManyClinicPetPimsSyncStatusArgs = {
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type QueryFindManyClinicPetPimsSyncStatusCountArgs = {
  cursor?: Maybe<ClinicPetPimsSyncStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPetPimsSyncStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetPimsSyncStatusWhereInput>;
};


export type QueryFindManyClinicPhoneNumberArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type QueryFindManyClinicPhoneNumberCountArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPhoneNumberScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationArgs = {
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityCountArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationCapabilityHistoryEntryCountArgs = {
  cursor?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationCapabilityHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationCapabilityHistoryEntryWhereInput>;
};


export type QueryFindManyClinicPimsIntegrationCountArgs = {
  cursor?: Maybe<ClinicPimsIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPimsIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPimsIntegrationWhereInput>;
};


export type QueryFindManyClinicPostcardSettingArgs = {
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type QueryFindManyClinicPostcardSettingCountArgs = {
  cursor?: Maybe<ClinicPostcardSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicPostcardSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicPostcardSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPostcardSettingWhereInput>;
};


export type QueryFindManyClinicRoomArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type QueryFindManyClinicRoomCountArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicRoomScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};


export type QueryFindManyClinicSettingArgs = {
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type QueryFindManyClinicSettingCountArgs = {
  cursor?: Maybe<ClinicSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicSettingWhereInput>;
};


export type QueryFindManyClinicWidgetRequestArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type QueryFindManyClinicWidgetRequestCountArgs = {
  cursor?: Maybe<ClinicWidgetRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestWhereInput>;
};


export type QueryFindManyClinicWidgetRequestTypeArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type QueryFindManyClinicWidgetRequestTypeCountArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetRequestTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type QueryFindManyClinicWidgetSettingArgs = {
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type QueryFindManyClinicWidgetSettingCountArgs = {
  cursor?: Maybe<ClinicWidgetSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ClinicWidgetSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ClinicWidgetSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetSettingWhereInput>;
};


export type QueryFindManyColorArgs = {
  cursor?: Maybe<ColorWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ColorWhereInput>;
};


export type QueryFindManyColorCountArgs = {
  cursor?: Maybe<ColorWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ColorScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ColorOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ColorWhereInput>;
};


export type QueryFindManyConditionArgs = {
  cursor?: Maybe<ConditionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionWhereInput>;
};


export type QueryFindManyConditionCountArgs = {
  cursor?: Maybe<ConditionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionWhereInput>;
};


export type QueryFindManyConditionSetArgs = {
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionSetWhereInput>;
};


export type QueryFindManyConditionSetCountArgs = {
  cursor?: Maybe<ConditionSetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionSetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionSetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionSetWhereInput>;
};


export type QueryFindManyConditionalEntityEvaluationArgs = {
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type QueryFindManyConditionalEntityEvaluationCountArgs = {
  cursor?: Maybe<ConditionalEntityEvaluationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConditionalEntityEvaluationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConditionalEntityEvaluationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConditionalEntityEvaluationWhereInput>;
};


export type QueryFindManyConsultationArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type QueryFindManyConsultationCountArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type QueryFindManyConsultationRequestArgs = {
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type QueryFindManyConsultationRequestCountArgs = {
  cursor?: Maybe<ConsultationRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ConsultationRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ConsultationRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationRequestWhereInput>;
};


export type QueryFindManyCustomFieldDefinitionArgs = {
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type QueryFindManyCustomFieldDefinitionCountArgs = {
  cursor?: Maybe<CustomFieldDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldDefinitionWhereInput>;
};


export type QueryFindManyCustomFieldPimsWritebackLogEntryArgs = {
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyCustomFieldPimsWritebackLogEntryCountArgs = {
  cursor?: Maybe<CustomFieldPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<CustomFieldPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CustomFieldPimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyDirectBookingAppointmentTypeSettingArgs = {
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type QueryFindManyDirectBookingAppointmentTypeSettingCountArgs = {
  cursor?: Maybe<DirectBookingAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingAppointmentTypeSettingWhereInput>;
};


export type QueryFindManyDirectBookingExclusionRuleArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type QueryFindManyDirectBookingExclusionRuleCountArgs = {
  cursor?: Maybe<DirectBookingExclusionRuleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionRuleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionRuleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionRuleWhereInput>;
};


export type QueryFindManyDirectBookingExclusionTimeSlotArgs = {
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type QueryFindManyDirectBookingExclusionTimeSlotCountArgs = {
  cursor?: Maybe<DirectBookingExclusionTimeSlotWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DirectBookingExclusionTimeSlotOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DirectBookingExclusionTimeSlotWhereInput>;
};


export type QueryFindManyDisbursementArgs = {
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DisbursementWhereInput>;
};


export type QueryFindManyDisbursementCountArgs = {
  cursor?: Maybe<DisbursementWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DisbursementScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DisbursementWhereInput>;
};


export type QueryFindManyDocumentationArgs = {
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentationWhereInput>;
};


export type QueryFindManyDocumentationCountArgs = {
  cursor?: Maybe<DocumentationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<DocumentationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<DocumentationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentationWhereInput>;
};


export type QueryFindManyEmailCampaignArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type QueryFindManyEmailCampaignCountArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListArgs = {
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListCountArgs = {
  cursor?: Maybe<EmailCampaignCustomListWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListRecipientArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListRecipientCountArgs = {
  cursor?: Maybe<EmailCampaignCustomListRecipientWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListRecipientOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListRecipientWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListUploadHistoryArgs = {
  cursor?: Maybe<EmailCampaignCustomListUploadHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type QueryFindManyEmailCampaignCustomListUploadHistoryCountArgs = {
  cursor?: Maybe<EmailCampaignCustomListUploadHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignCustomListUploadHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignCustomListUploadHistoryWhereInput>;
};


export type QueryFindManyEmailCampaignLogArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type QueryFindManyEmailCampaignLogCountArgs = {
  cursor?: Maybe<EmailCampaignLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignLogWhereInput>;
};


export type QueryFindManyEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type QueryFindManyEmailCampaignTemplateCountArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type QueryFindManyEmailCampaignUnsubscribeArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type QueryFindManyEmailCampaignUnsubscribeCountArgs = {
  cursor?: Maybe<EmailCampaignUnsubscribeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailCampaignUnsubscribeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailCampaignUnsubscribeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignUnsubscribeWhereInput>;
};


export type QueryFindManyEmailProviderLogArgs = {
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type QueryFindManyEmailProviderLogCountArgs = {
  cursor?: Maybe<EmailProviderLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmailProviderLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmailProviderLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailProviderLogWhereInput>;
};


export type QueryFindManyEmergencyClinicsArgs = {
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type QueryFindManyEmergencyClinicsCountArgs = {
  cursor?: Maybe<EmergencyClinicsWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EmergencyClinicsScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EmergencyClinicsOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmergencyClinicsWhereInput>;
};


export type QueryFindManyEnrollmentSupportEventArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type QueryFindManyEnrollmentSupportEventCountArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EnrollmentSupportEventScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type QueryFindManyEzyVetIntegrationArgs = {
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type QueryFindManyEzyVetIntegrationCountArgs = {
  cursor?: Maybe<EzyVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<EzyVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<EzyVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EzyVetIntegrationWhereInput>;
};


export type QueryFindManyFeatureFlagArgs = {
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type QueryFindManyFeatureFlagCountArgs = {
  cursor?: Maybe<FeatureFlagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FeatureFlagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FeatureFlagWhereInput>;
};


export type QueryFindManyFinancialAdjustmentArgs = {
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type QueryFindManyFinancialAdjustmentCountArgs = {
  cursor?: Maybe<FinancialAdjustmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialAdjustmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialAdjustmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialAdjustmentWhereInput>;
};


export type QueryFindManyFinancialTransactionArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type QueryFindManyFinancialTransactionCountArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FinancialTransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type QueryFindManyFormSubmissionArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type QueryFindManyFormSubmissionCountArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormSubmissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type QueryFindManyFormTemplateArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryFindManyFormTemplateCountArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<FormTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryFindManyGlobalPetParentArgs = {
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type QueryFindManyGlobalPetParentCountArgs = {
  cursor?: Maybe<GlobalPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<GlobalPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<GlobalPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GlobalPetParentWhereInput>;
};


export type QueryFindManyHillsToHomeApiLogArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type QueryFindManyHillsToHomeApiLogCountArgs = {
  cursor?: Maybe<HillsToHomeApiLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<HillsToHomeApiLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<HillsToHomeApiLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<HillsToHomeApiLogWhereInput>;
};


export type QueryFindManyInformAudienceDefinitionArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type QueryFindManyInformAudienceDefinitionCountArgs = {
  cursor?: Maybe<InformAudienceDefinitionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAudienceDefinitionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAudienceDefinitionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAudienceDefinitionWhereInput>;
};


export type QueryFindManyInformAutomationCampaignArgs = {
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type QueryFindManyInformAutomationCampaignCountArgs = {
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformAutomationCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type QueryFindManyInformCampaignArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};


export type QueryFindManyInformCampaignCountArgs = {
  cursor?: Maybe<InformCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignWhereInput>;
};


export type QueryFindManyInformCampaignEnrollmentArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type QueryFindManyInformCampaignEnrollmentCanonicalServiceArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type QueryFindManyInformCampaignEnrollmentCanonicalServiceCountArgs = {
  cursor?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentCanonicalServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentCanonicalServiceWhereInput>;
};


export type QueryFindManyInformCampaignEnrollmentCountArgs = {
  cursor?: Maybe<InformCampaignEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignEnrollmentWhereInput>;
};


export type QueryFindManyInformCampaignExecutionArgs = {
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type QueryFindManyInformCampaignExecutionCountArgs = {
  cursor?: Maybe<InformCampaignExecutionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignExecutionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignExecutionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignExecutionWhereInput>;
};


export type QueryFindManyInformCampaignGroupArgs = {
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type QueryFindManyInformCampaignGroupCountArgs = {
  cursor?: Maybe<InformCampaignGroupWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformCampaignGroupScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformCampaignGroupOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformCampaignGroupWhereInput>;
};


export type QueryFindManyInformControlGroupParticipantArgs = {
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type QueryFindManyInformControlGroupParticipantCountArgs = {
  cursor?: Maybe<InformControlGroupParticipantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformControlGroupParticipantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformControlGroupParticipantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformControlGroupParticipantWhereInput>;
};


export type QueryFindManyInformEmailTemplateArgs = {
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type QueryFindManyInformEmailTemplateCountArgs = {
  cursor?: Maybe<InformEmailTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEmailTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEmailTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEmailTemplateWhereInput>;
};


export type QueryFindManyInformEntityHistoryArgs = {
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type QueryFindManyInformEntityHistoryCountArgs = {
  cursor?: Maybe<InformEntityHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformEntityHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformEntityHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformEntityHistoryWhereInput>;
};


export type QueryFindManyInformPartnerArgs = {
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerWhereInput>;
};


export type QueryFindManyInformPartnerCountArgs = {
  cursor?: Maybe<InformPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerWhereInput>;
};


export type QueryFindManyInformPartnerCustomerArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type QueryFindManyInformPartnerCustomerCountArgs = {
  cursor?: Maybe<InformPartnerCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerWhereInput>;
};


export type QueryFindManyInformPartnerCustomerPharmacyProviderArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type QueryFindManyInformPartnerCustomerPharmacyProviderCountArgs = {
  cursor?: Maybe<InformPartnerCustomerPharmacyProviderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerCustomerPharmacyProviderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerCustomerPharmacyProviderWhereInput>;
};


export type QueryFindManyInformPartnerIntegrationArgs = {
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type QueryFindManyInformPartnerIntegrationCountArgs = {
  cursor?: Maybe<InformPartnerIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationWhereInput>;
};


export type QueryFindManyInformPartnerIntegrationModelArgs = {
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type QueryFindManyInformPartnerIntegrationModelCountArgs = {
  cursor?: Maybe<InformPartnerIntegrationModelWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerIntegrationModelScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerIntegrationModelOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerIntegrationModelWhereInput>;
};


export type QueryFindManyInformPartnerProgramArgs = {
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type QueryFindManyInformPartnerProgramCountArgs = {
  cursor?: Maybe<InformPartnerProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramWhereInput>;
};


export type QueryFindManyInformPartnerProgramEnrollmentArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type QueryFindManyInformPartnerProgramEnrollmentCountArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type QueryFindManyInformPartnerUserArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type QueryFindManyInformPartnerUserCountArgs = {
  cursor?: Maybe<InformPartnerUserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserWhereInput>;
};


export type QueryFindManyInformPartnerUserRoleArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type QueryFindManyInformPartnerUserRoleCountArgs = {
  cursor?: Maybe<InformPartnerUserRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformPartnerUserRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformPartnerUserRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerUserRoleWhereInput>;
};


export type QueryFindManyInformScheduledCampaignArgs = {
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type QueryFindManyInformScheduledCampaignCountArgs = {
  cursor?: Maybe<InformScheduledCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InformScheduledCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InformScheduledCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformScheduledCampaignWhereInput>;
};


export type QueryFindManyInstinctIntegrationArgs = {
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type QueryFindManyInstinctIntegrationCountArgs = {
  cursor?: Maybe<InstinctIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InstinctIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InstinctIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstinctIntegrationWhereInput>;
};


export type QueryFindManyInsuranceIntegrationArgs = {
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type QueryFindManyInsuranceIntegrationCountArgs = {
  cursor?: Maybe<InsuranceIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InsuranceIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InsuranceIntegrationWhereInput>;
};


export type QueryFindManyIntegrationOutageHistoryEntryArgs = {
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type QueryFindManyIntegrationOutageHistoryEntryCountArgs = {
  cursor?: Maybe<IntegrationOutageHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<IntegrationOutageHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
};


export type QueryFindManyInterchangeRateArgs = {
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type QueryFindManyInterchangeRateCountArgs = {
  cursor?: Maybe<InterchangeRateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InterchangeRateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InterchangeRateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InterchangeRateWhereInput>;
};


export type QueryFindManyInvoiceArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type QueryFindManyInvoiceCountArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type QueryFindManyInvoiceLineItemArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type QueryFindManyInvoiceLineItemCountArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type QueryFindManyInvoicePimsWritebackLogEntryArgs = {
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyInvoicePimsWritebackLogEntryCountArgs = {
  cursor?: Maybe<InvoicePimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<InvoicePimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoicePimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyLapsedPetParentTriggersArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type QueryFindManyLapsedPetParentTriggersCountArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LapsedPetParentTriggersScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type QueryFindManyLegalEntityArgs = {
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LegalEntityWhereInput>;
};


export type QueryFindManyLegalEntityCountArgs = {
  cursor?: Maybe<LegalEntityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LegalEntityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LegalEntityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LegalEntityWhereInput>;
};


export type QueryFindManyLoyaltyAccountArgs = {
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type QueryFindManyLoyaltyAccountCountArgs = {
  cursor?: Maybe<LoyaltyAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountWhereInput>;
};


export type QueryFindManyLoyaltyAccountHolderArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type QueryFindManyLoyaltyAccountHolderCountArgs = {
  cursor?: Maybe<LoyaltyAccountHolderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountHolderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountHolderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
};


export type QueryFindManyLoyaltyAccountMergerArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type QueryFindManyLoyaltyAccountMergerCountArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyAccountMergerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type QueryFindManyLoyaltyInvoicePointGranterArgs = {
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type QueryFindManyLoyaltyInvoicePointGranterCountArgs = {
  cursor?: Maybe<LoyaltyInvoicePointGranterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyInvoicePointGranterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyInvoicePointGranterWhereInput>;
};


export type QueryFindManyLoyaltyPointDeltaArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type QueryFindManyLoyaltyPointDeltaCountArgs = {
  cursor?: Maybe<LoyaltyPointDeltaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointDeltaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointDeltaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
};


export type QueryFindManyLoyaltyPointGrantingServiceArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCategoryArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCategoryCountArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};


export type QueryFindManyLoyaltyPointGrantingServiceCountArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type QueryFindManyLoyaltyPointUserGrantArgs = {
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type QueryFindManyLoyaltyPointUserGrantCountArgs = {
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyPointUserGrantScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type QueryFindManyLoyaltyProgramArgs = {
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramWhereInput>;
};


export type QueryFindManyLoyaltyProgramCountArgs = {
  cursor?: Maybe<LoyaltyProgramWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramWhereInput>;
};


export type QueryFindManyLoyaltyProgramStatusHistoryArgs = {
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};


export type QueryFindManyLoyaltyProgramStatusHistoryCountArgs = {
  cursor?: Maybe<LoyaltyProgramStatusHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyProgramStatusHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyProgramStatusHistoryWhereInput>;
};


export type QueryFindManyLoyaltyRewardArgs = {
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type QueryFindManyLoyaltyRewardCountArgs = {
  cursor?: Maybe<LoyaltyRewardWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardWhereInput>;
};


export type QueryFindManyLoyaltyRewardRedemptionArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type QueryFindManyLoyaltyRewardRedemptionCountArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
};


export type QueryFindManyLoyaltyRewardRedemptionHistoryEntryArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type QueryFindManyLoyaltyRewardRedemptionHistoryEntryCountArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type QueryFindManyMassTextAlertArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type QueryFindManyMassTextAlertCountArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type QueryFindManyMassTextAlertEntryArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type QueryFindManyMassTextAlertEntryAppointmentArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type QueryFindManyMassTextAlertEntryAppointmentCountArgs = {
  cursor?: Maybe<MassTextAlertEntryAppointmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryAppointmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryAppointmentWhereInput>;
};


export type QueryFindManyMassTextAlertEntryCountArgs = {
  cursor?: Maybe<MassTextAlertEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MassTextAlertEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertEntryWhereInput>;
};


export type QueryFindManyMediaArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type QueryFindManyMediaCountArgs = {
  cursor?: Maybe<MediaWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MediaScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MediaWhereInput>;
};


export type QueryFindManyMessageTemplateArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type QueryFindManyMessageTemplateCountArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessageTemplateScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type QueryFindManyMessagingCampaignArgs = {
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type QueryFindManyMessagingCampaignCountArgs = {
  cursor?: Maybe<MessagingCampaignWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingCampaignScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingCampaignWhereInput>;
};


export type QueryFindManyMessagingPartnerArgs = {
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type QueryFindManyMessagingPartnerCountArgs = {
  cursor?: Maybe<MessagingPartnerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<MessagingPartnerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<MessagingPartnerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessagingPartnerWhereInput>;
};


export type QueryFindManyNonPrismaSchemaScriptRanArgs = {
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type QueryFindManyNonPrismaSchemaScriptRanCountArgs = {
  cursor?: Maybe<NonPrismaSchemaScriptRanWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NonPrismaSchemaScriptRanOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NonPrismaSchemaScriptRanWhereInput>;
};


export type QueryFindManyNotificationEventToMessageArgs = {
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type QueryFindManyNotificationEventToMessageCountArgs = {
  cursor?: Maybe<NotificationEventToMessageWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<NotificationEventToMessageScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<NotificationEventToMessageOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationEventToMessageWhereInput>;
};


export type QueryFindManyOrganizationArgs = {
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCategoryArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCategoryCountArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type QueryFindManyOrganizationCareBenefitToClinicServiceCountArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type QueryFindManyOrganizationCountArgs = {
  cursor?: Maybe<OrganizationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryFindManyOrganizationPetArgs = {
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type QueryFindManyOrganizationPetCountArgs = {
  cursor?: Maybe<OrganizationPetWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetWhereInput>;
};


export type QueryFindManyOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type QueryFindManyOrganizationPetParentCountArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type QueryFindManyOrganizationPetToOrganizationPetParentArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type QueryFindManyOrganizationPetToOrganizationPetParentCountArgs = {
  cursor?: Maybe<OrganizationPetToOrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<OrganizationPetToOrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetToOrganizationPetParentWhereInput>;
};


export type QueryFindManyPpcIntegrationArgs = {
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type QueryFindManyPpcIntegrationCountArgs = {
  cursor?: Maybe<PpcIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PpcIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PpcIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PpcIntegrationWhereInput>;
};


export type QueryFindManyParentWorkflowEventSettingArgs = {
  workflowId: Scalars['String'];
};


export type QueryFindManyPayoutBatchingPeriodArgs = {
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type QueryFindManyPayoutBatchingPeriodCountArgs = {
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PayoutBatchingPeriodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type QueryFindManyPermissionArgs = {
  cursor?: Maybe<PermissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PermissionWhereInput>;
};


export type QueryFindManyPermissionCountArgs = {
  cursor?: Maybe<PermissionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PermissionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PermissionWhereInput>;
};


export type QueryFindManyPetParentSettingArgs = {
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type QueryFindManyPetParentSettingCountArgs = {
  cursor?: Maybe<PetParentSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetParentSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetParentSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetParentSettingWhereInput>;
};


export type QueryFindManyPetPortalSettingArgs = {
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type QueryFindManyPetPortalSettingCountArgs = {
  cursor?: Maybe<PetPortalSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PetPortalSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PetPortalSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PetPortalSettingWhereInput>;
};


export type QueryFindManyPhoneNumberLookupRequestArgs = {
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type QueryFindManyPhoneNumberLookupRequestCountArgs = {
  cursor?: Maybe<PhoneNumberLookupRequestWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupRequestScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupRequestOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupRequestWhereInput>;
};


export type QueryFindManyPhoneNumberLookupResultArgs = {
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type QueryFindManyPhoneNumberLookupResultCountArgs = {
  cursor?: Maybe<PhoneNumberLookupResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PhoneNumberLookupResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PhoneNumberLookupResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PhoneNumberLookupResultWhereInput>;
};


export type QueryFindManyPimsInvoiceArgs = {
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type QueryFindManyPimsInvoiceCountArgs = {
  cursor?: Maybe<PimsInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceWhereInput>;
};


export type QueryFindManyPimsInvoiceLineItemArgs = {
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type QueryFindManyPimsInvoiceLineItemCountArgs = {
  cursor?: Maybe<PimsInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsInvoiceLineItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsInvoiceLineItemWhereInput>;
};


export type QueryFindManyPimsPaymentTypeArgs = {
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsPaymentTypeWhereInput>;
};


export type QueryFindManyPimsPaymentTypeCountArgs = {
  cursor?: Maybe<PimsPaymentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsPaymentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsPaymentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsPaymentTypeWhereInput>;
};


export type QueryFindManyPimsWritebackLogEntryArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyPimsWritebackLogEntryCountArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PimsWritebackLogEntryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type QueryFindManyPrescriptionArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type QueryFindManyPrescriptionCountArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PrescriptionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type QueryFindManyProductArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryFindManyProductCountArgs = {
  cursor?: Maybe<ProductWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProductScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProductOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProductWhereInput>;
};


export type QueryFindManyProviderAppointmentTypeRoomMapArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type QueryFindManyProviderAppointmentTypeRoomMapCountArgs = {
  cursor?: Maybe<ProviderAppointmentTypeRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ProviderAppointmentTypeRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ProviderAppointmentTypeRoomMapWhereInput>;
};


export type QueryFindManyPushNotificationLogArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type QueryFindManyPushNotificationLogCountArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<PushNotificationLogScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};


export type QueryFindManyRulesArgs = {
  cursor?: Maybe<RulesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RulesWhereInput>;
};


export type QueryFindManyRulesCountArgs = {
  cursor?: Maybe<RulesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<RulesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<RulesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RulesWhereInput>;
};


export type QueryFindManySegmentArgs = {
  cursor?: Maybe<SegmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SegmentWhereInput>;
};


export type QueryFindManySegmentCountArgs = {
  cursor?: Maybe<SegmentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SegmentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SegmentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SegmentWhereInput>;
};


export type QueryFindManySentTrupanionExamDayOfferArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type QueryFindManySentTrupanionExamDayOfferCountArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SentTrupanionExamDayOfferScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type QueryFindManyServiceArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type QueryFindManyServiceCategoryArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type QueryFindManyServiceCategoryCountArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type QueryFindManyServiceCountArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type QueryFindManyServiceReminderArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type QueryFindManyServiceReminderCountArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type QueryFindManyServiceReminderNotificationArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type QueryFindManyServiceReminderNotificationCountArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderNotificationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};


export type QueryFindManyServiceReminderTimingArgs = {
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type QueryFindManyServiceReminderTimingCountArgs = {
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<ServiceReminderTimingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type QueryFindManySexArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};


export type QueryFindManySexCountArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SexScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SexOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};


export type QueryFindManySmsNotificationStatusArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type QueryFindManySmsNotificationStatusCountArgs = {
  cursor?: Maybe<SmsNotificationStatusWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SmsNotificationStatusScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SmsNotificationStatusWhereInput>;
};


export type QueryFindManySpeciesArgs = {
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpeciesWhereInput>;
};


export type QueryFindManySpeciesCountArgs = {
  cursor?: Maybe<SpeciesWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SpeciesScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpeciesWhereInput>;
};


export type QueryFindManyStaffRoleArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type QueryFindManyStaffRoleCountArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StaffRoleScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type QueryFindManyStripeCustomerArgs = {
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type QueryFindManyStripeCustomerCountArgs = {
  cursor?: Maybe<StripeCustomerWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeCustomerScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeCustomerWhereInput>;
};


export type QueryFindManyStripeDisputeArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type QueryFindManyStripeDisputeCountArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeDisputeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type QueryFindManyStripeInvoiceArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type QueryFindManyStripeInvoiceCountArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type QueryFindManyStripeInvoiceItemArgs = {
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type QueryFindManyStripeInvoiceItemCountArgs = {
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeInvoiceItemScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};


export type QueryFindManyStripePaymentIntentArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type QueryFindManyStripePaymentIntentCountArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentIntentScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type QueryFindManyStripePaymentMethodArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type QueryFindManyStripePaymentMethodCountArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePaymentMethodScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};


export type QueryFindManyStripePayoutArgs = {
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutWhereInput>;
};


export type QueryFindManyStripePayoutAccountArgs = {
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type QueryFindManyStripePayoutAccountCountArgs = {
  cursor?: Maybe<StripePayoutAccountWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutAccountScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutAccountWhereInput>;
};


export type QueryFindManyStripePayoutCountArgs = {
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripePayoutScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutWhereInput>;
};


export type QueryFindManyStripeReceiptArgs = {
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type QueryFindManyStripeReceiptCountArgs = {
  cursor?: Maybe<StripeReceiptWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptWhereInput>;
};


export type QueryFindManyStripeReceiptHistoryArgs = {
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type QueryFindManyStripeReceiptHistoryCountArgs = {
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeReceiptHistoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};


export type QueryFindManyStripeRefundArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type QueryFindManyStripeRefundCountArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeRefundScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type QueryFindManyStripeTerminalArgs = {
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type QueryFindManyStripeTerminalCountArgs = {
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalWhereInput>;
};


export type QueryFindManyStripeTerminalLocationArgs = {
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type QueryFindManyStripeTerminalLocationCountArgs = {
  cursor?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<StripeTerminalLocationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalLocationWhereInput>;
};


export type QueryFindManySurveyAppointmentTypeSettingArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type QueryFindManySurveyAppointmentTypeSettingCountArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type QueryFindManySurveyResultArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type QueryFindManySurveyResultCountArgs = {
  cursor?: Maybe<SurveyResultWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveyResultScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveyResultOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyResultWhereInput>;
};


export type QueryFindManySurveySettingArgs = {
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveySettingWhereInput>;
};


export type QueryFindManySurveySettingCountArgs = {
  cursor?: Maybe<SurveySettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SurveySettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveySettingWhereInput>;
};


export type QueryFindManySyncVetIntegrationArgs = {
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type QueryFindManySyncVetIntegrationCountArgs = {
  cursor?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<SyncVetIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SyncVetIntegrationWhereInput>;
};


export type QueryFindManyTransactionArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QueryFindManyTransactionCountArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TransactionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TransactionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type QueryFindManyTrupanionCertificateAvailabilityArgs = {
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type QueryFindManyTrupanionCertificateAvailabilityCountArgs = {
  cursor?: Maybe<TrupanionCertificateAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};


export type QueryFindManyTrupanionIntegratedAppointmentTypeArgs = {
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type QueryFindManyTrupanionIntegratedAppointmentTypeCountArgs = {
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};


export type QueryFindManyTrupanionIntegrationArgs = {
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type QueryFindManyTrupanionIntegrationCountArgs = {
  cursor?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TrupanionIntegrationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegrationWhereInput>;
};


export type QueryFindManyTwilioCallRecordingArgs = {
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TwilioCallRecordingWhereInput>;
};


export type QueryFindManyTwilioCallRecordingCountArgs = {
  cursor?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<TwilioCallRecordingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TwilioCallRecordingWhereInput>;
};


export type QueryFindManyUserArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryFindManyUserAppointmentGroupingArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type QueryFindManyUserAppointmentGroupingCountArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserAppointmentGroupingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type QueryFindManyUserBoardFilterArgs = {
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type QueryFindManyUserBoardFilterCountArgs = {
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserBoardFilterScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type QueryFindManyUserChannelFilterSelectionArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type QueryFindManyUserChannelFilterSelectionCountArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserChannelFilterSelectionScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type QueryFindManyUserCountArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type QueryFindManyUserNotificationSettingArgs = {
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type QueryFindManyUserNotificationSettingCountArgs = {
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserNotificationSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type QueryFindManyUserSettingArgs = {
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserSettingWhereInput>;
};


export type QueryFindManyUserSettingCountArgs = {
  cursor?: Maybe<UserSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<UserSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserSettingWhereInput>;
};


export type QueryFindManyVaccinationArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type QueryFindManyVaccinationCountArgs = {
  cursor?: Maybe<VaccinationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VaccinationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VaccinationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VaccinationWhereInput>;
};


export type QueryFindManyVetAvailabilityArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type QueryFindManyVetAvailabilityClinicRoomMapArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type QueryFindManyVetAvailabilityClinicRoomMapCountArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type QueryFindManyVetAvailabilityCountArgs = {
  cursor?: Maybe<VetAvailabilityWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityWhereInput>;
};


export type QueryFindManyVetAvailabilityMapArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type QueryFindManyVetAvailabilityMapCountArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetAvailabilityMapScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};


export type QueryFindManyVetInfoArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type QueryFindManyVetInfoCountArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetInfoScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};


export type QueryFindManyVetsourceCodeTagArgs = {
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type QueryFindManyVetsourceCodeTagCountArgs = {
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceCodeTagScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};


export type QueryFindManyVetsourceInstallationArgs = {
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type QueryFindManyVetsourceInstallationCountArgs = {
  cursor?: Maybe<VetsourceInstallationWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceInstallationScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceInstallationWhereInput>;
};


export type QueryFindManyVetsourceRevenueCategoryArgs = {
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type QueryFindManyVetsourceRevenueCategoryCountArgs = {
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<VetsourceRevenueCategoryScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};


export type QueryFindManyWorkflowEventSettingArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type QueryFindManyWorkflowEventSettingCountArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<Array<Maybe<WorkflowEventSettingScalarFieldEnum>>>;
  orderBy?: Maybe<Array<Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};


export type QueryFindUniqueActiveIngredientArgs = {
  where: ActiveIngredientWhereUniqueInput;
};


export type QueryFindUniqueAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type QueryFindUniqueAppointmentAvailabilityArgs = {
  where: AppointmentAvailabilityWhereUniqueInput;
};


export type QueryFindUniqueAppointmentTypeArgs = {
  where: AppointmentTypeWhereUniqueInput;
};


export type QueryFindUniqueAutomationRunArgs = {
  where: AutomationRunWhereUniqueInput;
};


export type QueryFindUniqueAutomationRunActionArgs = {
  where: AutomationRunActionWhereUniqueInput;
};


export type QueryFindUniqueBitwerxHealthcheckArgs = {
  where: BitwerxHealthcheckWhereUniqueInput;
};


export type QueryFindUniqueBitwerxIntegrationArgs = {
  where: BitwerxIntegrationWhereUniqueInput;
};


export type QueryFindUniqueBitwerxPaymentHealthcheckResultArgs = {
  where: BitwerxPaymentHealthcheckResultWhereUniqueInput;
};


export type QueryFindUniqueBitwerxPaymentHealthcheckRunArgs = {
  where: BitwerxPaymentHealthcheckRunWhereUniqueInput;
};


export type QueryFindUniqueBitwerxPaymentWritebackConfigurationArgs = {
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
};


export type QueryFindUniqueBoardFilterColumnArgs = {
  where: BoardFilterColumnWhereUniqueInput;
};


export type QueryFindUniqueBoardFilterSpecificDateRangeArgs = {
  where: BoardFilterSpecificDateRangeWhereUniqueInput;
};


export type QueryFindUniqueBoehringerIngelheimCustomerAlignmentArgs = {
  where: BoehringerIngelheimCustomerAlignmentWhereUniqueInput;
};


export type QueryFindUniqueBoehringerIngelheimTerritoryArgs = {
  where: BoehringerIngelheimTerritoryWhereUniqueInput;
};


export type QueryFindUniqueBreedArgs = {
  where: BreedWhereUniqueInput;
};


export type QueryFindUniqueCallHistoryArgs = {
  where: CallHistoryWhereUniqueInput;
};


export type QueryFindUniqueCallParticipantArgs = {
  where: CallParticipantWhereUniqueInput;
};


export type QueryFindUniqueCallRecordingArgs = {
  where: CallRecordingWhereUniqueInput;
};


export type QueryFindUniqueCampaignRegistryCampaignArgs = {
  where: CampaignRegistryCampaignWhereUniqueInput;
};


export type QueryFindUniqueCanonicalBreedArgs = {
  where: CanonicalBreedWhereUniqueInput;
};


export type QueryFindUniqueCanonicalCanineBreedProfileArgs = {
  where: CanonicalCanineBreedProfileWhereUniqueInput;
};


export type QueryFindUniqueCanonicalServiceArgs = {
  where: CanonicalServiceWhereUniqueInput;
};


export type QueryFindUniqueCanonicalSpeciesArgs = {
  where: CanonicalSpeciesWhereUniqueInput;
};


export type QueryFindUniqueCareAccountCreditIntentArgs = {
  where: CareAccountCreditIntentWhereUniqueInput;
};


export type QueryFindUniqueCareAccountCreditPimsWritebackLogEntryArgs = {
  where: CareAccountCreditPimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindUniqueCareAddonEnrollmentArgs = {
  where: CareAddonEnrollmentWhereUniqueInput;
};


export type QueryFindUniqueCareAddonPackageArgs = {
  where: CareAddonPackageWhereUniqueInput;
};


export type QueryFindUniqueCareAddonPackageBenefitArgs = {
  where: CareAddonPackageBenefitWhereUniqueInput;
};


export type QueryFindUniqueCareAuthorizationRequestArgs = {
  where: CareAuthorizationRequestWhereUniqueInput;
};


export type QueryFindUniqueCareBenefitArgs = {
  where: CareBenefitWhereUniqueInput;
};


export type QueryFindUniqueCareBenefitUsageArgs = {
  where: CareBenefitUsageWhereUniqueInput;
};


export type QueryFindUniqueCareBenefitUsageFollowupArgs = {
  where: CareBenefitUsageFollowupWhereUniqueInput;
};


export type QueryFindUniqueCareBenefitUsageFollowupStepArgs = {
  where: CareBenefitUsageFollowupStepWhereUniqueInput;
};


export type QueryFindUniqueCareBenefitUsageToInvoiceLineItemArgs = {
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
};


export type QueryFindUniqueCareEnrollmentPaymentArgs = {
  where: CareEnrollmentPaymentWhereUniqueInput;
};


export type QueryFindUniqueCareEnrollmentToStripeInvoiceItemArgs = {
  where: CareEnrollmentToStripeInvoiceItemWhereUniqueInput;
};


export type QueryFindUniqueCareLapsedPaymentArgs = {
  where: CareLapsedPaymentWhereUniqueInput;
};


export type QueryFindUniqueCarePlanArgs = {
  where: CarePlanWhereUniqueInput;
};


export type QueryFindUniqueCarePlanBenefitArgs = {
  where: CarePlanBenefitWhereUniqueInput;
};


export type QueryFindUniqueCarePlanBenefitEnrollmentCarryoverArgs = {
  where: CarePlanBenefitEnrollmentCarryoverWhereUniqueInput;
};


export type QueryFindUniqueCarePlanEnrollmentArgs = {
  where: CarePlanEnrollmentWhereUniqueInput;
};


export type QueryFindUniqueCarePlanProviderGroupArgs = {
  where: CarePlanProviderGroupWhereUniqueInput;
};


export type QueryFindUniqueCareStripeSubscriptionArgs = {
  where: CareStripeSubscriptionWhereUniqueInput;
};


export type QueryFindUniqueCareSubscriptionTrueUpArgs = {
  where: CareSubscriptionTrueUpWhereUniqueInput;
};


export type QueryFindUniqueCareSubscriptionTrueUpLogEntryArgs = {
  where: CareSubscriptionTrueUpLogEntryWhereUniqueInput;
};


export type QueryFindUniqueChannelArgs = {
  where: ChannelWhereUniqueInput;
};


export type QueryFindUniqueChannelAutomationStatusArgs = {
  where: ChannelAutomationStatusWhereUniqueInput;
};


export type QueryFindUniqueChannelCreationSourceArgs = {
  where: ChannelCreationSourceWhereUniqueInput;
};


export type QueryFindUniqueChannelFilterSelectionArgs = {
  where: ChannelFilterSelectionWhereUniqueInput;
};


export type QueryFindUniqueChannelMemberArgs = {
  where: ChannelMemberWhereUniqueInput;
};


export type QueryFindUniqueChannelMessageArgs = {
  where: ChannelMessageWhereUniqueInput;
};


export type QueryFindUniqueChannelMessageAttachmentArgs = {
  where: ChannelMessageAttachmentWhereUniqueInput;
};


export type QueryFindUniqueChannelMessageAutoResponseArgs = {
  where: ChannelMessageAutoResponseWhereUniqueInput;
};


export type QueryFindUniqueChannelMessageReactionArgs = {
  where: ChannelMessageReactionWhereUniqueInput;
};


export type QueryFindUniqueChannelPinArgs = {
  where: ChannelPinWhereUniqueInput;
};


export type QueryFindUniqueChannelSelectionArgs = {
  where: ChannelSelectionWhereUniqueInput;
};


export type QueryFindUniqueChannelStatusArgs = {
  where: ChannelStatusWhereUniqueInput;
};


export type QueryFindUniqueChannelStatusChannelAssigneeArgs = {
  where: ChannelStatusChannelAssigneeWhereUniqueInput;
};


export type QueryFindUniqueChemicalCompoundArgs = {
  where: ChemicalCompoundWhereUniqueInput;
};


export type QueryFindUniqueClientConnectIntegrationArgs = {
  where: ClientConnectIntegrationWhereUniqueInput;
};


export type QueryFindUniqueClinicArgs = {
  where: ClinicWhereUniqueInput;
};


export type QueryFindUniqueClinicBlockedPhoneNumberArgs = {
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
};


export type QueryFindUniqueClinicDirectBookingSettingArgs = {
  where: ClinicDirectBookingSettingWhereUniqueInput;
};


export type QueryFindUniqueClinicEmailCampaignSettingArgs = {
  where: ClinicEmailCampaignSettingWhereUniqueInput;
};


export type QueryFindUniqueClinicEmployeeArgs = {
  where: ClinicEmployeeWhereUniqueInput;
};


export type QueryFindUniqueClinicEmployeeAppointmentTypeSettingArgs = {
  where: ClinicEmployeeAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindUniqueClinicEntityPhoneNumberArgs = {
  where: ClinicEntityPhoneNumberWhereUniqueInput;
};


export type QueryFindUniqueClinicOfficeHourArgs = {
  where: ClinicOfficeHourWhereUniqueInput;
};


export type QueryFindUniqueClinicOnboardingArgs = {
  where: ClinicOnboardingWhereUniqueInput;
};


export type QueryFindUniqueClinicPaymentFeeConfigurationArgs = {
  where: ClinicPaymentFeeConfigurationWhereUniqueInput;
};


export type QueryFindUniqueClinicPetArgs = {
  where: ClinicPetWhereUniqueInput;
};


export type QueryFindUniqueClinicPetAlertArgs = {
  where: ClinicPetAlertWhereUniqueInput;
};


export type QueryFindUniqueClinicPetCustomFieldValueArgs = {
  where: ClinicPetCustomFieldValueWhereUniqueInput;
};


export type QueryFindUniqueClinicPetParentArgs = {
  where: ClinicPetParentWhereUniqueInput;
};


export type QueryFindUniqueClinicPetParentAddressArgs = {
  where: ClinicPetParentAddressWhereUniqueInput;
};


export type QueryFindUniqueClinicPetParentCustomFieldValueArgs = {
  where: ClinicPetParentCustomFieldValueWhereUniqueInput;
};


export type QueryFindUniqueClinicPetParentDeviceArgs = {
  where: ClinicPetParentDeviceWhereUniqueInput;
};


export type QueryFindUniqueClinicPetParentPimsSyncStatusArgs = {
  where: ClinicPetParentPimsSyncStatusWhereUniqueInput;
};


export type QueryFindUniqueClinicPetPimsSyncStatusArgs = {
  where: ClinicPetPimsSyncStatusWhereUniqueInput;
};


export type QueryFindUniqueClinicPhoneNumberArgs = {
  where: ClinicPhoneNumberWhereUniqueInput;
};


export type QueryFindUniqueClinicPimsIntegrationArgs = {
  where: ClinicPimsIntegrationWhereUniqueInput;
};


export type QueryFindUniqueClinicPimsIntegrationCapabilityArgs = {
  where: ClinicPimsIntegrationCapabilityWhereUniqueInput;
};


export type QueryFindUniqueClinicPimsIntegrationCapabilityHistoryEntryArgs = {
  where: ClinicPimsIntegrationCapabilityHistoryEntryWhereUniqueInput;
};


export type QueryFindUniqueClinicPostcardSettingArgs = {
  where: ClinicPostcardSettingWhereUniqueInput;
};


export type QueryFindUniqueClinicRoomArgs = {
  where: ClinicRoomWhereUniqueInput;
};


export type QueryFindUniqueClinicSettingArgs = {
  where: ClinicSettingWhereUniqueInput;
};


export type QueryFindUniqueClinicWidgetRequestArgs = {
  where: ClinicWidgetRequestWhereUniqueInput;
};


export type QueryFindUniqueClinicWidgetRequestTypeArgs = {
  where: ClinicWidgetRequestTypeWhereUniqueInput;
};


export type QueryFindUniqueClinicWidgetSettingArgs = {
  where: ClinicWidgetSettingWhereUniqueInput;
};


export type QueryFindUniqueColorArgs = {
  where: ColorWhereUniqueInput;
};


export type QueryFindUniqueConditionArgs = {
  where: ConditionWhereUniqueInput;
};


export type QueryFindUniqueConditionSetArgs = {
  where: ConditionSetWhereUniqueInput;
};


export type QueryFindUniqueConditionalEntityEvaluationArgs = {
  where: ConditionalEntityEvaluationWhereUniqueInput;
};


export type QueryFindUniqueConsultationArgs = {
  where: ConsultationWhereUniqueInput;
};


export type QueryFindUniqueConsultationRequestArgs = {
  where: ConsultationRequestWhereUniqueInput;
};


export type QueryFindUniqueCustomFieldDefinitionArgs = {
  where: CustomFieldDefinitionWhereUniqueInput;
};


export type QueryFindUniqueCustomFieldPimsWritebackLogEntryArgs = {
  where: CustomFieldPimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindUniqueDirectBookingAppointmentTypeSettingArgs = {
  where: DirectBookingAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindUniqueDirectBookingExclusionRuleArgs = {
  where: DirectBookingExclusionRuleWhereUniqueInput;
};


export type QueryFindUniqueDirectBookingExclusionTimeSlotArgs = {
  where: DirectBookingExclusionTimeSlotWhereUniqueInput;
};


export type QueryFindUniqueDisbursementArgs = {
  where: DisbursementWhereUniqueInput;
};


export type QueryFindUniqueDocumentationArgs = {
  where: DocumentationWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignArgs = {
  where: EmailCampaignWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignCustomListArgs = {
  where: EmailCampaignCustomListWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignCustomListRecipientArgs = {
  where: EmailCampaignCustomListRecipientWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignCustomListUploadHistoryArgs = {
  where: EmailCampaignCustomListUploadHistoryWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignLogArgs = {
  where: EmailCampaignLogWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignTemplateArgs = {
  where: EmailCampaignTemplateWhereUniqueInput;
};


export type QueryFindUniqueEmailCampaignUnsubscribeArgs = {
  where: EmailCampaignUnsubscribeWhereUniqueInput;
};


export type QueryFindUniqueEmailProviderLogArgs = {
  where: EmailProviderLogWhereUniqueInput;
};


export type QueryFindUniqueEmergencyClinicsArgs = {
  where: EmergencyClinicsWhereUniqueInput;
};


export type QueryFindUniqueEnrollmentSupportEventArgs = {
  where: EnrollmentSupportEventWhereUniqueInput;
};


export type QueryFindUniqueEzyVetIntegrationArgs = {
  where: EzyVetIntegrationWhereUniqueInput;
};


export type QueryFindUniqueFeatureFlagArgs = {
  where: FeatureFlagWhereUniqueInput;
};


export type QueryFindUniqueFinancialAdjustmentArgs = {
  where: FinancialAdjustmentWhereUniqueInput;
};


export type QueryFindUniqueFinancialTransactionArgs = {
  where: FinancialTransactionWhereUniqueInput;
};


export type QueryFindUniqueFormSubmissionArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type QueryFindUniqueFormTemplateArgs = {
  where: FormTemplateWhereUniqueInput;
};


export type QueryFindUniqueGlobalPetParentArgs = {
  where: GlobalPetParentWhereUniqueInput;
};


export type QueryFindUniqueHillsToHomeApiLogArgs = {
  where: HillsToHomeApiLogWhereUniqueInput;
};


export type QueryFindUniqueInformAudienceDefinitionArgs = {
  where: InformAudienceDefinitionWhereUniqueInput;
};


export type QueryFindUniqueInformAutomationCampaignArgs = {
  where: InformAutomationCampaignWhereUniqueInput;
};


export type QueryFindUniqueInformCampaignArgs = {
  where: InformCampaignWhereUniqueInput;
};


export type QueryFindUniqueInformCampaignEnrollmentArgs = {
  where: InformCampaignEnrollmentWhereUniqueInput;
};


export type QueryFindUniqueInformCampaignEnrollmentCanonicalServiceArgs = {
  where: InformCampaignEnrollmentCanonicalServiceWhereUniqueInput;
};


export type QueryFindUniqueInformCampaignExecutionArgs = {
  where: InformCampaignExecutionWhereUniqueInput;
};


export type QueryFindUniqueInformCampaignGroupArgs = {
  where: InformCampaignGroupWhereUniqueInput;
};


export type QueryFindUniqueInformControlGroupParticipantArgs = {
  where: InformControlGroupParticipantWhereUniqueInput;
};


export type QueryFindUniqueInformEmailTemplateArgs = {
  where: InformEmailTemplateWhereUniqueInput;
};


export type QueryFindUniqueInformEntityHistoryArgs = {
  where: InformEntityHistoryWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerArgs = {
  where: InformPartnerWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerCustomerArgs = {
  where: InformPartnerCustomerWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerCustomerPharmacyProviderArgs = {
  where: InformPartnerCustomerPharmacyProviderWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerIntegrationArgs = {
  where: InformPartnerIntegrationWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerIntegrationModelArgs = {
  where: InformPartnerIntegrationModelWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerProgramArgs = {
  where: InformPartnerProgramWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerProgramEnrollmentArgs = {
  where: InformPartnerProgramEnrollmentWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerUserArgs = {
  where: InformPartnerUserWhereUniqueInput;
};


export type QueryFindUniqueInformPartnerUserRoleArgs = {
  where: InformPartnerUserRoleWhereUniqueInput;
};


export type QueryFindUniqueInformScheduledCampaignArgs = {
  where: InformScheduledCampaignWhereUniqueInput;
};


export type QueryFindUniqueInstinctIntegrationArgs = {
  where: InstinctIntegrationWhereUniqueInput;
};


export type QueryFindUniqueInsuranceIntegrationArgs = {
  where: InsuranceIntegrationWhereUniqueInput;
};


export type QueryFindUniqueIntegrationOutageHistoryEntryArgs = {
  where: IntegrationOutageHistoryEntryWhereUniqueInput;
};


export type QueryFindUniqueInterchangeRateArgs = {
  where: InterchangeRateWhereUniqueInput;
};


export type QueryFindUniqueInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryFindUniqueInvoiceLineItemArgs = {
  where: InvoiceLineItemWhereUniqueInput;
};


export type QueryFindUniqueInvoicePimsWritebackLogEntryArgs = {
  where: InvoicePimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindUniqueLapsedPetParentTriggersArgs = {
  where: LapsedPetParentTriggersWhereUniqueInput;
};


export type QueryFindUniqueLegalEntityArgs = {
  where: LegalEntityWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyAccountArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyAccountHolderArgs = {
  where: LoyaltyAccountHolderWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyAccountMergerArgs = {
  where: LoyaltyAccountMergerWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyInvoicePointGranterArgs = {
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyPointDeltaArgs = {
  where: LoyaltyPointDeltaWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyPointGrantingServiceArgs = {
  where: LoyaltyPointGrantingServiceWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyPointGrantingServiceCategoryArgs = {
  where: LoyaltyPointGrantingServiceCategoryWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyPointUserGrantArgs = {
  where: LoyaltyPointUserGrantWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyProgramArgs = {
  where: LoyaltyProgramWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyProgramStatusHistoryArgs = {
  where: LoyaltyProgramStatusHistoryWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyRewardArgs = {
  where: LoyaltyRewardWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyRewardRedemptionArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type QueryFindUniqueLoyaltyRewardRedemptionHistoryEntryArgs = {
  where: LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput;
};


export type QueryFindUniqueMassTextAlertArgs = {
  where: MassTextAlertWhereUniqueInput;
};


export type QueryFindUniqueMassTextAlertEntryArgs = {
  where: MassTextAlertEntryWhereUniqueInput;
};


export type QueryFindUniqueMassTextAlertEntryAppointmentArgs = {
  where: MassTextAlertEntryAppointmentWhereUniqueInput;
};


export type QueryFindUniqueMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type QueryFindUniqueMessageTemplateArgs = {
  where: MessageTemplateWhereUniqueInput;
};


export type QueryFindUniqueMessagingCampaignArgs = {
  where: MessagingCampaignWhereUniqueInput;
};


export type QueryFindUniqueMessagingPartnerArgs = {
  where: MessagingPartnerWhereUniqueInput;
};


export type QueryFindUniqueNonPrismaSchemaScriptRanArgs = {
  where: NonPrismaSchemaScriptRanWhereUniqueInput;
};


export type QueryFindUniqueNotificationEventToMessageArgs = {
  where: NotificationEventToMessageWhereUniqueInput;
};


export type QueryFindUniqueOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryFindUniqueOrganizationCareBenefitToClinicServiceArgs = {
  where: OrganizationCareBenefitToClinicServiceWhereUniqueInput;
};


export type QueryFindUniqueOrganizationCareBenefitToClinicServiceCategoryArgs = {
  where: OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput;
};


export type QueryFindUniqueOrganizationPetArgs = {
  where: OrganizationPetWhereUniqueInput;
};


export type QueryFindUniqueOrganizationPetParentArgs = {
  where: OrganizationPetParentWhereUniqueInput;
};


export type QueryFindUniqueOrganizationPetToOrganizationPetParentArgs = {
  where: OrganizationPetToOrganizationPetParentWhereUniqueInput;
};


export type QueryFindUniquePpcIntegrationArgs = {
  where: PpcIntegrationWhereUniqueInput;
};


export type QueryFindUniquePayoutBatchingPeriodArgs = {
  where: PayoutBatchingPeriodWhereUniqueInput;
};


export type QueryFindUniquePermissionArgs = {
  where: PermissionWhereUniqueInput;
};


export type QueryFindUniquePetParentSettingArgs = {
  where: PetParentSettingWhereUniqueInput;
};


export type QueryFindUniquePetPortalSettingArgs = {
  where: PetPortalSettingWhereUniqueInput;
};


export type QueryFindUniquePhoneNumberLookupRequestArgs = {
  where: PhoneNumberLookupRequestWhereUniqueInput;
};


export type QueryFindUniquePhoneNumberLookupResultArgs = {
  where: PhoneNumberLookupResultWhereUniqueInput;
};


export type QueryFindUniquePimsInvoiceArgs = {
  where: PimsInvoiceWhereUniqueInput;
};


export type QueryFindUniquePimsInvoiceLineItemArgs = {
  where: PimsInvoiceLineItemWhereUniqueInput;
};


export type QueryFindUniquePimsPaymentTypeArgs = {
  where: PimsPaymentTypeWhereUniqueInput;
};


export type QueryFindUniquePimsWritebackLogEntryArgs = {
  where: PimsWritebackLogEntryWhereUniqueInput;
};


export type QueryFindUniquePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};


export type QueryFindUniqueProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryFindUniqueProviderAppointmentTypeRoomMapArgs = {
  where: ProviderAppointmentTypeRoomMapWhereUniqueInput;
};


export type QueryFindUniquePushNotificationLogArgs = {
  where: PushNotificationLogWhereUniqueInput;
};


export type QueryFindUniqueRulesArgs = {
  where: RulesWhereUniqueInput;
};


export type QueryFindUniqueSegmentArgs = {
  where: SegmentWhereUniqueInput;
};


export type QueryFindUniqueSentTrupanionExamDayOfferArgs = {
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};


export type QueryFindUniqueServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type QueryFindUniqueServiceCategoryArgs = {
  where: ServiceCategoryWhereUniqueInput;
};


export type QueryFindUniqueServiceReminderArgs = {
  where: ServiceReminderWhereUniqueInput;
};


export type QueryFindUniqueServiceReminderNotificationArgs = {
  where: ServiceReminderNotificationWhereUniqueInput;
};


export type QueryFindUniqueServiceReminderTimingArgs = {
  where: ServiceReminderTimingWhereUniqueInput;
};


export type QueryFindUniqueSexArgs = {
  where: SexWhereUniqueInput;
};


export type QueryFindUniqueSmsNotificationStatusArgs = {
  where: SmsNotificationStatusWhereUniqueInput;
};


export type QueryFindUniqueSpeciesArgs = {
  where: SpeciesWhereUniqueInput;
};


export type QueryFindUniqueStaffRoleArgs = {
  where: StaffRoleWhereUniqueInput;
};


export type QueryFindUniqueStripeCustomerArgs = {
  where: StripeCustomerWhereUniqueInput;
};


export type QueryFindUniqueStripeDisputeArgs = {
  where: StripeDisputeWhereUniqueInput;
};


export type QueryFindUniqueStripeInvoiceArgs = {
  where: StripeInvoiceWhereUniqueInput;
};


export type QueryFindUniqueStripeInvoiceItemArgs = {
  where: StripeInvoiceItemWhereUniqueInput;
};


export type QueryFindUniqueStripePaymentIntentArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryFindUniqueStripePaymentMethodArgs = {
  where: StripePaymentMethodWhereUniqueInput;
};


export type QueryFindUniqueStripePayoutArgs = {
  where: StripePayoutWhereUniqueInput;
};


export type QueryFindUniqueStripePayoutAccountArgs = {
  where: StripePayoutAccountWhereUniqueInput;
};


export type QueryFindUniqueStripeReceiptArgs = {
  where: StripeReceiptWhereUniqueInput;
};


export type QueryFindUniqueStripeReceiptHistoryArgs = {
  where: StripeReceiptHistoryWhereUniqueInput;
};


export type QueryFindUniqueStripeRefundArgs = {
  where: StripeRefundWhereUniqueInput;
};


export type QueryFindUniqueStripeTerminalArgs = {
  where: StripeTerminalWhereUniqueInput;
};


export type QueryFindUniqueStripeTerminalLocationArgs = {
  where: StripeTerminalLocationWhereUniqueInput;
};


export type QueryFindUniqueSurveyAppointmentTypeSettingArgs = {
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};


export type QueryFindUniqueSurveyResultArgs = {
  where: SurveyResultWhereUniqueInput;
};


export type QueryFindUniqueSurveySettingArgs = {
  where: SurveySettingWhereUniqueInput;
};


export type QueryFindUniqueSyncVetIntegrationArgs = {
  where: SyncVetIntegrationWhereUniqueInput;
};


export type QueryFindUniqueTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryFindUniqueTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};


export type QueryFindUniqueTrupanionIntegratedAppointmentTypeArgs = {
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};


export type QueryFindUniqueTrupanionIntegrationArgs = {
  where: TrupanionIntegrationWhereUniqueInput;
};


export type QueryFindUniqueTwilioCallRecordingArgs = {
  where: TwilioCallRecordingWhereUniqueInput;
};


export type QueryFindUniqueUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryFindUniqueUserAppointmentGroupingArgs = {
  where: UserAppointmentGroupingWhereUniqueInput;
};


export type QueryFindUniqueUserBoardFilterArgs = {
  where: UserBoardFilterWhereUniqueInput;
};


export type QueryFindUniqueUserChannelFilterSelectionArgs = {
  where: UserChannelFilterSelectionWhereUniqueInput;
};


export type QueryFindUniqueUserNotificationSettingArgs = {
  where: UserNotificationSettingWhereUniqueInput;
};


export type QueryFindUniqueUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};


export type QueryFindUniqueVaccinationArgs = {
  where: VaccinationWhereUniqueInput;
};


export type QueryFindUniqueVetAvailabilityArgs = {
  where: VetAvailabilityWhereUniqueInput;
};


export type QueryFindUniqueVetAvailabilityClinicRoomMapArgs = {
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};


export type QueryFindUniqueVetAvailabilityMapArgs = {
  where: VetAvailabilityMapWhereUniqueInput;
};


export type QueryFindUniqueVetInfoArgs = {
  where: VetInfoWhereUniqueInput;
};


export type QueryFindUniqueVetsourceCodeTagArgs = {
  where: VetsourceCodeTagWhereUniqueInput;
};


export type QueryFindUniqueVetsourceInstallationArgs = {
  where: VetsourceInstallationWhereUniqueInput;
};


export type QueryFindUniqueVetsourceRevenueCategoryArgs = {
  where: VetsourceRevenueCategoryWhereUniqueInput;
};


export type QueryFindUniqueWorkflowEventSettingArgs = {
  where: WorkflowEventSettingWhereUniqueInput;
};


export type QueryFormSubmissionExportArgs = {
  where: FormSubmissionWhereUniqueInput;
};


export type QueryGeneratePetIdPdfArgs = {
  data: GeneratePetIdPdfInput;
};


export type QueryGetAppointmentHistoryArgs = {
  where: GetEntityHistoryInput;
};


export type QueryGetAvailableFormTemplatesForWorkflowsArgs = {
  where?: Maybe<FormTemplateWhereInput>;
};


export type QueryGetBalanceForClientArgs = {
  where: GetBalanceForClientInput;
};


export type QueryGetChannelStatusIdsWithEntityCountOfZeroArgs = {
  where: GetChannelStatusIdsWithEntityCountOfZeroWhere;
};


export type QueryGetClinicDirectBookingSettingsArgs = {
  data?: Maybe<ClinicDirectBookingSettingsInput>;
};


export type QueryGetClinicPetHistoryArgs = {
  where: GetEntityHistoryInput;
};


export type QueryGetClinicPetParentHistoryArgs = {
  where: GetEntityHistoryInput;
};


export type QueryGetCurrentInformPartnerUserArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetDirectBookingAppointmentTypesArgs = {
  data: DirectBookingAppointmentTypeInput;
};


export type QueryGetDirectBookingOpenTimeSlots2Args = {
  data: DirectBookingOpenTimeSlot2Input;
};


export type QueryGetEmailCampaignCustomListUrlArgs = {
  data: EmailCampaignCustomListUrlInput;
};


export type QueryGetEmailCampaignLoginUrlArgs = {
  data?: Maybe<ConfigureEmailCampaignsInput>;
};


export type QueryGetEmailCampaignSegmentCountArgs = {
  clinicId: Scalars['String'];
  emailCampaignId?: Maybe<Scalars['String']>;
};


export type QueryGetHasConflictingAppointmentArgs = {
  data: HasConflictingAppointmentInput;
};


export type QueryGetInvoiceByIdArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryGetInvoicesForClientArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where: InvoiceWhereInput;
};


export type QueryGetInvoicesForClientsArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  orderBy?: Maybe<Array<InvoiceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where: InvoiceWhereInput;
};


export type QueryGetOrCreateActiveChannelArgs = {
  data: GetOrCreateActiveChannelInput;
};


export type QueryGetPetIdDataArgs = {
  data: GetPetIdDataInput;
};


export type QueryGetPossiblyDeflectedFormSubmissionArgs = {
  data?: Maybe<FormSubmissionWhereInput>;
};


export type QueryGetQuickSightEmbedUrlArgs = {
  where: GetQuickSightEmbedUrlInput;
};


export type QueryGetStripePaymentIntentDataArgs = {
  where: StripePaymentIntentWhereUniqueInput;
};


export type QueryGetTerminalTokenArgs = {
  where: StripeTerminalLocationUniqueInput;
};


export type QueryGetTwilioNumbersArgs = {
  data?: Maybe<GetTwilioNumbersInput>;
};


export type QueryGetWorkflowEventActionHistoryArgs = {
  where: GetEntityHistoryInput;
};


export type QueryGetWorkflowEventSettingHistoryArgs = {
  where: GetEntityHistoryInput;
};


export type QueryInvokeConverseAiRequestArgs = {
  data: InvokeAiConverseInput;
};


export type QueryJobChainStatusArgs = {
  where: JobChainStatusInput;
};


export type QueryMassTextAlertReportArgs = {
  massTextAlertId: Scalars['String'];
};


export type QueryRenderWorkflowNotificationBodyArgs = {
  automationRunId: Scalars['String'];
};


export type QuerySavedChannelFilterSelectionsArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  orderBy?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type QuerySearchPhoneNumberArgs = {
  data?: Maybe<SearchPhoneNumberInput>;
};


export type QuerySearchTwilioNumbersArgs = {
  data: SearchTwilioNumbersInput;
};


export type QuerySendConsultationReceiptArgs = {
  data: SendConsultationReceiptInput;
};


export type QuerySendPetParentReceiptArgs = {
  data: SendPetParentReceiptInput;
};


export type QuerySendVetSubscriptionReceiptArgs = {
  data: SendVetSubscriptionReceiptInput;
};


export type QueryStripeCreateCustomerPortalArgs = {
  data?: Maybe<StripeCustomerPortalInput>;
};


export type QueryStripeRetrievePetParentPortalUrlArgs = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  data?: Maybe<StripeCustomerPortalInput>;
};


export type QueryTokenValidationArgs = {
  data: TokenValidationInput;
};


export type QueryTrupanionCertificateAvailabilityArgs = {
  where: TrupanionCertificateAvailabilityInput;
};


export type QueryTrupanionHospitalSearchArgs = {
  where: TrupanionHospitalSearchInput;
};


export type QueryTwilioCapabilityTokenArgs = {
  type?: Maybe<TwilioCapabilityType>;
};


export type QueryVerificationCheckArgs = {
  data?: Maybe<VerificationCheckInput>;
};


export type QueryVerificationRequestArgs = {
  data?: Maybe<VerificationRequestInput>;
};


export type QueryVerifyTerminalSetupPinArgs = {
  pin: Scalars['String'];
};


export type QueryWidgetClinicPetParentLookup2Args = {
  where: WidgetClinicPetParentLookupInput;
};


export type QueryWidgetClinicPetParentLookupEnrollmentArgs = {
  where: WidgetClinicPetParentLookupInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RecordingControlStatus {
  InProgress = 'InProgress',
  Paused = 'Paused',
  Stopped = 'Stopped'
}

export enum RecordingDownloadStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type RedeemOneTimeCodeInput = {
  code: Scalars['String'];
  trusted: Scalars['Boolean'];
};

export type RedeemOneTimeCodeResult = {
  __typename?: 'RedeemOneTimeCodeResult';
  success: Scalars['Boolean'];
  token: Scalars['String'];
};

export enum RefundReason {
  Duplicate = 'Duplicate',
  Fraudulent = 'Fraudulent',
  Other = 'Other',
  PartialRefund = 'Partial_Refund',
  RequestedByCustomer = 'Requested_By_Customer'
}

export type RemoveUserFromClinicInput = {
  clinicId: Scalars['String'];
  userId: Scalars['String'];
};

export enum RenewalRejectionReason {
  DeceasedPet = 'DeceasedPet',
  OptOut = 'OptOut',
  PaymentsMissing = 'PaymentsMissing'
}

export type RequestBitwerxIntegrationInput = {
  clinicContactEmail: Scalars['String'];
  forPayments?: Maybe<Scalars['Boolean']>;
};

export type RequestLoyaltyRewardInput = {
  loyaltyAccountId: Scalars['String'];
  loyaltyRewardId: Scalars['String'];
};

export type RequestOneTimeCodeInput = {
  emailAddress?: Maybe<Scalars['String']>;
  method: RequestOneTimeCodeMethod;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum RequestOneTimeCodeMethod {
  Email = 'EMAIL',
  Text = 'TEXT'
}

export type RequestOneTimeCodeResult = {
  __typename?: 'RequestOneTimeCodeResult';
  success: Scalars['Boolean'];
};

export type ResendReceiptEmailOutput = {
  __typename?: 'ResendReceiptEmailOutput';
  error?: Maybe<Scalars['String']>;
  jobChainId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ResendUserInvitationInput = {
  email: Scalars['String'];
};

export type RetryPaymentWritebackOutput = {
  __typename?: 'RetryPaymentWritebackOutput';
  error?: Maybe<Scalars['String']>;
  jobChainId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** @deprecated - 1.0 */
export type Review = {
  __typename?: 'Review';
  _count: ReviewCountOutputType;
  createdAt: Scalars['DateTime'];
  feedbackMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags: Array<ReviewTag>;
  reviewType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


/** @deprecated - 1.0 */
export type ReviewReviewTagsArgs = {
  cursor?: Maybe<ReviewTagWhereUniqueInput>;
  distinct?: Maybe<ReviewTagScalarFieldEnum>;
  orderBy?: Maybe<ReviewTagOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReviewTagWhereInput>;
};

export type ReviewAvgAggregateOutputType = {
  __typename?: 'ReviewAvgAggregateOutputType';
  rating?: Maybe<Scalars['Float']>;
  ratingScale?: Maybe<Scalars['Float']>;
};

export type ReviewAvgOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

export type ReviewCountAggregateOutputType = {
  __typename?: 'ReviewCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  feedbackMessage: Scalars['Int'];
  id: Scalars['Int'];
  rating: Scalars['Int'];
  ratingDisplay: Scalars['Int'];
  ratingScale: Scalars['Int'];
  relationshipId: Scalars['Int'];
  reviewType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ReviewCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewCountOutputType = {
  __typename?: 'ReviewCountOutputType';
  reviewTags: Scalars['Int'];
};

export type ReviewCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
};

export type ReviewCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewCreateManyUserInputEnvelope = {
  data: Array<ReviewCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReviewCreateNestedManyWithoutReviewTagsInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
};

export type ReviewCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
};

export type ReviewCreateOrConnectWithoutReviewTagsInput = {
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateOrConnectWithoutUserInput = {
  create: ReviewUncheckedCreateWithoutUserInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateWithoutReviewTagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutReviewsInput>;
};

export type ReviewCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagCreateNestedManyWithoutReviewsInput>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewListRelationFilter = {
  every?: Maybe<ReviewWhereInput>;
  none?: Maybe<ReviewWhereInput>;
  some?: Maybe<ReviewWhereInput>;
};

export type ReviewMaxAggregateOutputType = {
  __typename?: 'ReviewMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewMinAggregateOutputType = {
  __typename?: 'ReviewMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewOrderByRelevanceFieldEnum {
  FeedbackMessage = 'feedbackMessage',
  Id = 'id',
  RatingDisplay = 'ratingDisplay',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  UserId = 'userId'
}

export type ReviewOrderByRelevanceInput = {
  fields: Array<ReviewOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ReviewOrderByWithAggregationInput = {
  _avg?: Maybe<ReviewAvgOrderByAggregateInput>;
  _count?: Maybe<ReviewCountOrderByAggregateInput>;
  _max?: Maybe<ReviewMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewMinOrderByAggregateInput>;
  _sum?: Maybe<ReviewSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type ReviewOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ReviewOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  feedbackMessage?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  ratingDisplay?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
  relationshipId?: Maybe<SortOrder>;
  reviewTags?: Maybe<ReviewTagOrderByRelationAggregateInput>;
  reviewType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum ReviewScalarFieldEnum {
  CreatedAt = 'createdAt',
  FeedbackMessage = 'feedbackMessage',
  Id = 'id',
  Rating = 'rating',
  RatingDisplay = 'ratingDisplay',
  RatingScale = 'ratingScale',
  RelationshipId = 'relationshipId',
  ReviewType = 'reviewType',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ReviewScalarWhereInput = {
  AND?: Maybe<Array<ReviewScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereInput>>;
  OR?: Maybe<Array<ReviewScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type ReviewScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  feedbackMessage?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  rating?: Maybe<IntWithAggregatesFilter>;
  ratingDisplay?: Maybe<StringWithAggregatesFilter>;
  ratingScale?: Maybe<IntWithAggregatesFilter>;
  relationshipId?: Maybe<StringNullableWithAggregatesFilter>;
  reviewType?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ReviewSumAggregateOutputType = {
  __typename?: 'ReviewSumAggregateOutputType';
  rating?: Maybe<Scalars['Int']>;
  ratingScale?: Maybe<Scalars['Int']>;
};

export type ReviewSumOrderByAggregateInput = {
  rating?: Maybe<SortOrder>;
  ratingScale?: Maybe<SortOrder>;
};

/** @deprecated - 1.0 */
export type ReviewTag = {
  __typename?: 'ReviewTag';
  _count: ReviewTagCountOutputType;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews: Array<Review>;
  updatedAt: Scalars['DateTime'];
};


/** @deprecated - 1.0 */
export type ReviewTagReviewsArgs = {
  cursor?: Maybe<ReviewWhereUniqueInput>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReviewWhereInput>;
};

export type ReviewTagCountAggregateOutputType = {
  __typename?: 'ReviewTagCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ReviewTagCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ReviewTagCountOutputType = {
  __typename?: 'ReviewTagCountOutputType';
  reviews: Scalars['Int'];
};

export type ReviewTagCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewCreateNestedManyWithoutReviewTagsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagCreateNestedManyWithoutReviewsInput = {
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
};

export type ReviewTagCreateOrConnectWithoutReviewsInput = {
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
  where: ReviewTagWhereUniqueInput;
};

export type ReviewTagCreateWithoutReviewsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagListRelationFilter = {
  every?: Maybe<ReviewTagWhereInput>;
  none?: Maybe<ReviewTagWhereInput>;
  some?: Maybe<ReviewTagWhereInput>;
};

export type ReviewTagMaxAggregateOutputType = {
  __typename?: 'ReviewTagMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ReviewTagMinAggregateOutputType = {
  __typename?: 'ReviewTagMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ReviewTagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ReviewTagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type ReviewTagOrderByRelevanceInput = {
  fields: Array<ReviewTagOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ReviewTagOrderByWithAggregationInput = {
  _count?: Maybe<ReviewTagCountOrderByAggregateInput>;
  _max?: Maybe<ReviewTagMaxOrderByAggregateInput>;
  _min?: Maybe<ReviewTagMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ReviewTagOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ReviewTagOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ReviewTagScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ReviewTagScalarWhereInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ReviewTagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ReviewTagScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ReviewTagUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutReviewTagsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUncheckedCreateNestedManyWithoutReviewsInput = {
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
};

export type ReviewTagUncheckedCreateWithoutReviewsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutReviewTagsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewTagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput = {
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
};

export type ReviewTagUncheckedUpdateWithoutReviewsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reviews?: Maybe<ReviewUpdateManyWithoutReviewTagsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUpdateManyWithWhereWithoutReviewsInput = {
  data: ReviewTagUncheckedUpdateManyWithoutReviewTagsInput;
  where: ReviewTagScalarWhereInput;
};

export type ReviewTagUpdateManyWithoutReviewsNestedInput = {
  connect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewTagCreateOrConnectWithoutReviewsInput>>;
  create?: Maybe<Array<ReviewTagCreateWithoutReviewsInput>>;
  delete?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewTagScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  set?: Maybe<Array<ReviewTagWhereUniqueInput>>;
  update?: Maybe<Array<ReviewTagUpdateWithWhereUniqueWithoutReviewsInput>>;
  updateMany?: Maybe<Array<ReviewTagUpdateManyWithWhereWithoutReviewsInput>>;
  upsert?: Maybe<Array<ReviewTagUpsertWithWhereUniqueWithoutReviewsInput>>;
};

export type ReviewTagUpdateWithWhereUniqueWithoutReviewsInput = {
  data: ReviewTagUncheckedUpdateWithoutReviewsInput;
  where: ReviewTagWhereUniqueInput;
};

export type ReviewTagUpdateWithoutReviewsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewTagUpsertWithWhereUniqueWithoutReviewsInput = {
  create: ReviewTagUncheckedCreateWithoutReviewsInput;
  update: ReviewTagUncheckedUpdateWithoutReviewsInput;
  where: ReviewTagWhereUniqueInput;
};

export type ReviewTagWhereInput = {
  AND?: Maybe<Array<ReviewTagWhereInput>>;
  NOT?: Maybe<Array<ReviewTagWhereInput>>;
  OR?: Maybe<Array<ReviewTagWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ReviewTagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateNestedManyWithoutReviewTagsInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
};

export type ReviewUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
};

export type ReviewUncheckedCreateWithoutReviewTagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  ratingDisplay: Scalars['String'];
  ratingScale: Scalars['Int'];
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedCreateNestedManyWithoutReviewsInput>;
  reviewType: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutReviewTagsNestedInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
};

export type ReviewUncheckedUpdateManyWithoutReviewsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ReviewUncheckedUpdateWithoutReviewTagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReviewUncheckedUpdateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUncheckedUpdateManyWithoutReviewsNestedInput>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsNestedInput>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutReviewsNestedInput>;
};

export type ReviewUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewUpdateManyWithWhereWithoutReviewTagsInput = {
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
  where: ReviewScalarWhereInput;
};

export type ReviewUpdateManyWithWhereWithoutUserInput = {
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
  where: ReviewScalarWhereInput;
};

export type ReviewUpdateManyWithoutReviewTagsNestedInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutReviewTagsInput>>;
  create?: Maybe<Array<ReviewCreateWithoutReviewTagsInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutReviewTagsInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutReviewTagsInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutReviewTagsInput>>;
};

export type ReviewUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ReviewUpdateWithWhereUniqueWithoutReviewTagsInput = {
  data: ReviewUncheckedUpdateWithoutReviewTagsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewUpdateWithWhereUniqueWithoutUserInput = {
  data: ReviewUncheckedUpdateWithoutUserInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewUpdateWithoutReviewTagsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutReviewsNestedInput>;
};

export type ReviewUpdateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingDisplay?: Maybe<Scalars['String']>;
  ratingScale?: Maybe<Scalars['Int']>;
  relationshipId?: Maybe<Scalars['String']>;
  reviewTags?: Maybe<ReviewTagUpdateManyWithoutReviewsNestedInput>;
  reviewType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewUpsertWithWhereUniqueWithoutReviewTagsInput = {
  create: ReviewUncheckedCreateWithoutReviewTagsInput;
  update: ReviewUncheckedUpdateWithoutReviewTagsInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewUpsertWithWhereUniqueWithoutUserInput = {
  create: ReviewUncheckedCreateWithoutUserInput;
  update: ReviewUncheckedUpdateWithoutUserInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  feedbackMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  rating?: Maybe<IntFilter>;
  ratingDisplay?: Maybe<StringFilter>;
  ratingScale?: Maybe<IntFilter>;
  relationshipId?: Maybe<StringNullableFilter>;
  reviewTags?: Maybe<ReviewTagListRelationFilter>;
  reviewType?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Head = 'HEAD',
  Member = 'MEMBER',
  Nurse = 'NURSE',
  Pm = 'PM',
  Rec = 'REC',
  Technician = 'TECHNICIAN',
  Vet = 'VET'
}

export enum RuleActions {
  UpdateInactiveChannelStatus = 'UpdateInactiveChannelStatus'
}

export type Rules = {
  __typename?: 'Rules';
  action: RuleActions;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  options: Scalars['Json'];
  updatedAt: Scalars['DateTime'];
};

export type RulesCountAggregateOutputType = {
  __typename?: 'RulesCountAggregateOutputType';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  options: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type RulesCountOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RulesCreateInput = {
  action?: Maybe<RuleActions>;
  clinic: ClinicCreateNestedOneWithoutRulesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesCreateManyClinicInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesCreateManyClinicInputEnvelope = {
  data: Array<RulesCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RulesCreateManyInput = {
  action?: Maybe<RuleActions>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
};

export type RulesCreateOrConnectWithoutClinicInput = {
  create: RulesUncheckedCreateWithoutClinicInput;
  where: RulesWhereUniqueInput;
};

export type RulesCreateWithoutClinicInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesListRelationFilter = {
  every?: Maybe<RulesWhereInput>;
  none?: Maybe<RulesWhereInput>;
  some?: Maybe<RulesWhereInput>;
};

export type RulesMaxAggregateOutputType = {
  __typename?: 'RulesMaxAggregateOutputType';
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesMaxOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RulesMinAggregateOutputType = {
  __typename?: 'RulesMinAggregateOutputType';
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesMinOrderByAggregateInput = {
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RulesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum RulesOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type RulesOrderByRelevanceInput = {
  fields: Array<RulesOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type RulesOrderByWithAggregationInput = {
  _count?: Maybe<RulesCountOrderByAggregateInput>;
  _max?: Maybe<RulesMaxOrderByAggregateInput>;
  _min?: Maybe<RulesMinOrderByAggregateInput>;
  action?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RulesOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<RulesOrderByRelevanceInput>;
  action?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  options?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum RulesScalarFieldEnum {
  Action = 'action',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Options = 'options',
  UpdatedAt = 'updatedAt'
}

export type RulesScalarWhereInput = {
  AND?: Maybe<Array<RulesScalarWhereInput>>;
  NOT?: Maybe<Array<RulesScalarWhereInput>>;
  OR?: Maybe<Array<RulesScalarWhereInput>>;
  action?: Maybe<EnumRuleActionsFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  options?: Maybe<JsonFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RulesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<RulesScalarWhereWithAggregatesInput>>;
  action?: Maybe<EnumRuleActionsWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  options?: Maybe<JsonWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type RulesUncheckedCreateInput = {
  action?: Maybe<RuleActions>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
};

export type RulesUncheckedCreateWithoutClinicInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUncheckedUpdateInput = {
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUncheckedUpdateManyInput = {
  action?: Maybe<RuleActions>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type RulesUncheckedUpdateManyWithoutRulesInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUncheckedUpdateWithoutClinicInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUpdateInput = {
  action?: Maybe<RuleActions>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutRulesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUpdateManyMutationInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUpdateManyWithWhereWithoutClinicInput = {
  data: RulesUncheckedUpdateManyWithoutRulesInput;
  where: RulesScalarWhereInput;
};

export type RulesUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<RulesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RulesCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<RulesCreateWithoutClinicInput>>;
  createMany?: Maybe<RulesCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<RulesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RulesScalarWhereInput>>;
  disconnect?: Maybe<Array<RulesWhereUniqueInput>>;
  set?: Maybe<Array<RulesWhereUniqueInput>>;
  update?: Maybe<Array<RulesUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<RulesUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<RulesUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type RulesUpdateWithWhereUniqueWithoutClinicInput = {
  data: RulesUncheckedUpdateWithoutClinicInput;
  where: RulesWhereUniqueInput;
};

export type RulesUpdateWithoutClinicInput = {
  action?: Maybe<RuleActions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RulesUpsertWithWhereUniqueWithoutClinicInput = {
  create: RulesUncheckedCreateWithoutClinicInput;
  update: RulesUncheckedUpdateWithoutClinicInput;
  where: RulesWhereUniqueInput;
};

export type RulesWhereInput = {
  AND?: Maybe<Array<RulesWhereInput>>;
  NOT?: Maybe<Array<RulesWhereInput>>;
  OR?: Maybe<Array<RulesWhereInput>>;
  action?: Maybe<EnumRuleActionsFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  options?: Maybe<JsonFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RulesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SaveEmailTemplateVersionInput = {
  design: Scalars['Json'];
  emailTemplateVersionId: Scalars['String'];
  html: Scalars['String'];
  shouldPublish?: Maybe<Scalars['Boolean']>;
};

export type SearchChannelInput = {
  and?: Maybe<Array<Maybe<SearchChannelInput>>>;
  appointmentClinicEmployeeIds?: Maybe<Array<Scalars['String']>>;
  appointmentRoomIds?: Maybe<Array<Scalars['String']>>;
  appointmentTypeIds?: Maybe<Array<Scalars['String']>>;
  assigneeIds?: Maybe<Array<Scalars['String']>>;
  assigneeNames?: Maybe<Scalars['String']>;
  channelPinUserIds?: Maybe<Array<Scalars['String']>>;
  channelStatusAction?: Maybe<Scalars['String']>;
  channelStatusIds?: Maybe<Array<Scalars['String']>>;
  channelStatusName?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  excludedChannelPinUserIds?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  idIn?: Maybe<Array<Scalars['String']>>;
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isUnassigned?: Maybe<Scalars['Boolean']>;
  messageBodies?: Maybe<Scalars['String']>;
  or?: Maybe<Array<Maybe<SearchChannelInput>>>;
  petNames?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  tagNames?: Maybe<Array<Scalars['String']>>;
  uniqueName?: Maybe<Scalars['String']>;
  userNames?: Maybe<Scalars['String']>;
};

export type SearchClinicPetInput = {
  name?: Maybe<Scalars['String']>;
  petParentNames?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type SearchClinicPetParentInput = {
  and?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  or?: Maybe<Array<Maybe<SearchClinicPetParentInput>>>;
  petNames?: Maybe<Scalars['String']>;
  petPimsIds?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
};

export type SearchPhoneNumberInput = {
  code: Scalars['String'];
  quantity: Scalars['String'];
};

export type SearchPhoneNumberResponse = {
  __typename?: 'SearchPhoneNumberResponse';
  phoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchTwilioNumbersInput = {
  areaCode?: Maybe<Scalars['String']>;
};

export type Segment = {
  __typename?: 'Segment';
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  model: SegmentModelType;
  name: Scalars['String'];
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
};

export type SegmentAvgAggregateOutputType = {
  __typename?: 'SegmentAvgAggregateOutputType';
  sortingOrder?: Maybe<Scalars['Float']>;
};

export type SegmentAvgOrderByAggregateInput = {
  sortingOrder?: Maybe<SortOrder>;
};

export type SegmentBuilderDefinitionVariablesInput = {
  staticVariables: Scalars['Json'];
};

export type SegmentCountAggregateOutputType = {
  __typename?: 'SegmentCountAggregateOutputType';
  _all: Scalars['Int'];
  definitionVariables: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  model: Scalars['Int'];
  name: Scalars['Int'];
  prismaDefinition: Scalars['Int'];
  rawSqlDefinition: Scalars['Int'];
  sortingOrder: Scalars['Int'];
  type: Scalars['Int'];
};

export type SegmentCountOrderByAggregateInput = {
  definitionVariables?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type SegmentCreateInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model: SegmentModelType;
  name: Scalars['String'];
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
};

export type SegmentCreateManyInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model: SegmentModelType;
  name: Scalars['String'];
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
};

export type SegmentMaxAggregateOutputType = {
  __typename?: 'SegmentMaxAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentMaxOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type SegmentMinAggregateOutputType = {
  __typename?: 'SegmentMinAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentMinOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export enum SegmentModelType {
  ClinicPetParent = 'ClinicPetParent'
}

export enum SegmentOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
  RawSqlDefinition = 'rawSqlDefinition'
}

export type SegmentOrderByRelevanceInput = {
  fields: Array<SegmentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SegmentOrderByWithAggregationInput = {
  _avg?: Maybe<SegmentAvgOrderByAggregateInput>;
  _count?: Maybe<SegmentCountOrderByAggregateInput>;
  _max?: Maybe<SegmentMaxOrderByAggregateInput>;
  _min?: Maybe<SegmentMinOrderByAggregateInput>;
  _sum?: Maybe<SegmentSumOrderByAggregateInput>;
  definitionVariables?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type SegmentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SegmentOrderByRelevanceInput>;
  definitionVariables?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  prismaDefinition?: Maybe<SortOrder>;
  rawSqlDefinition?: Maybe<SortOrder>;
  sortingOrder?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export enum SegmentScalarFieldEnum {
  DefinitionVariables = 'definitionVariables',
  Description = 'description',
  Id = 'id',
  Model = 'model',
  Name = 'name',
  PrismaDefinition = 'prismaDefinition',
  RawSqlDefinition = 'rawSqlDefinition',
  SortingOrder = 'sortingOrder',
  Type = 'type'
}

export type SegmentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SegmentScalarWhereWithAggregatesInput>>;
  definitionVariables?: Maybe<JsonNullableWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  model?: Maybe<EnumSegmentModelTypeWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  prismaDefinition?: Maybe<JsonNullableWithAggregatesFilter>;
  rawSqlDefinition?: Maybe<StringNullableWithAggregatesFilter>;
  sortingOrder?: Maybe<IntWithAggregatesFilter>;
  type?: Maybe<EnumSegmentTypeWithAggregatesFilter>;
};

export type SegmentSumAggregateOutputType = {
  __typename?: 'SegmentSumAggregateOutputType';
  sortingOrder?: Maybe<Scalars['Int']>;
};

export type SegmentSumOrderByAggregateInput = {
  sortingOrder?: Maybe<SortOrder>;
};

export enum SegmentType {
  Clinic = 'Clinic',
  ClinicRawSql = 'ClinicRawSql',
  System = 'System',
  SystemRawSql = 'SystemRawSql'
}

export type SegmentUncheckedCreateInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model: SegmentModelType;
  name: Scalars['String'];
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder: Scalars['Int'];
  type: SegmentType;
};

export type SegmentUncheckedUpdateInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentUncheckedUpdateManyInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentUpdateInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentUpdateManyMutationInput = {
  definitionVariables?: Maybe<Scalars['Json']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  model?: Maybe<SegmentModelType>;
  name?: Maybe<Scalars['String']>;
  prismaDefinition?: Maybe<Scalars['Json']>;
  rawSqlDefinition?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<SegmentType>;
};

export type SegmentWhereInput = {
  AND?: Maybe<Array<SegmentWhereInput>>;
  NOT?: Maybe<Array<SegmentWhereInput>>;
  OR?: Maybe<Array<SegmentWhereInput>>;
  definitionVariables?: Maybe<JsonNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  model?: Maybe<EnumSegmentModelTypeFilter>;
  name?: Maybe<StringFilter>;
  prismaDefinition?: Maybe<JsonNullableFilter>;
  rawSqlDefinition?: Maybe<StringNullableFilter>;
  sortingOrder?: Maybe<IntFilter>;
  type?: Maybe<EnumSegmentTypeFilter>;
};

export type SegmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SegmentsArgsInput = {
  conditionalSegments?: Maybe<Array<Maybe<ConditionalSegmentsInput>>>;
};

export type SendCareSignUpEmailInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type SendCareSignUpEmailOutput = {
  __typename?: 'SendCareSignUpEmailOutput';
  success: Scalars['Boolean'];
};

export type SendCareSignUpEmailResult = {
  __typename?: 'SendCareSignUpEmailResult';
  success: Scalars['Boolean'];
};

export type SendClinicPetParentAuthCodeRequest = {
  clinicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['Json']>;
};

export type SendClinicPetParentAuthCodeResponse = {
  __typename?: 'SendClinicPetParentAuthCodeResponse';
  message: Scalars['String'];
  requestId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendClinicPetParentAuthLinkRequest = {
  clinicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isDeepLink?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  queryParams?: Maybe<Scalars['Json']>;
};

export type SendConsultationReceiptInput = {
  consultationFirebaseId: Scalars['String'];
  consultationId: Scalars['String'];
  emails: Array<Scalars['String']>;
  firebaseId: Scalars['String'];
};

export type SendEnrollmentEmailInput = {
  careStripeSubscriptionId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type SendEnrollmentEmailOutput = {
  __typename?: 'SendEnrollmentEmailOutput';
  success: Scalars['Boolean'];
};

export type SendPaymentToTerminalResponse = {
  __typename?: 'SendPaymentToTerminalResponse';
  success: Scalars['Boolean'];
  terminal: StripeTerminal;
};

export type SendPetParentReceiptInput = {
  consultationId: Scalars['String'];
  firebaseId: Scalars['String'];
};

export type SendWidgetCodeToDeveloperInput = {
  ccEmailAddresses?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type SentTrupanionExamDayOffer = {
  __typename?: 'SentTrupanionExamDayOffer';
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['String'];
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  clinicUser: User;
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegration: TrupanionIntegration;
  trupanionIntegrationId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SentTrupanionExamDayOfferCountAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferCountAggregateOutputType';
  _all: Scalars['Int'];
  activationDate: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  expirationDate: Scalars['Int'];
  id: Scalars['Int'];
  referenceNumber: Scalars['Int'];
  sentByClinicUserId: Scalars['Int'];
  trupanionIntegrationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SentTrupanionExamDayOfferCountOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferCreateInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyAppointmentTypeInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetParentInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicUserInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyClinicUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateManyInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope = {
  data: Array<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicPetInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateWithoutClinicUserInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType: AppointmentTypeCreateNestedOneWithoutRelatedTrupanionExamDayOffersInput;
  clinic: ClinicCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  clinicPet: ClinicPetCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutReceivedTrupanionExamDayOffersInput;
  clinicUser: UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferListRelationFilter = {
  every?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  none?: Maybe<SentTrupanionExamDayOfferWhereInput>;
  some?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};

export type SentTrupanionExamDayOfferMaxAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferMaxAggregateOutputType';
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferMaxOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferMinAggregateOutputType = {
  __typename?: 'SentTrupanionExamDayOfferMinAggregateOutputType';
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferMinOrderByAggregateInput = {
  activationDate?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SentTrupanionExamDayOfferOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  ReferenceNumber = 'referenceNumber',
  SentByClinicUserId = 'sentByClinicUserId',
  TrupanionIntegrationId = 'trupanionIntegrationId'
}

export type SentTrupanionExamDayOfferOrderByRelevanceInput = {
  fields: Array<SentTrupanionExamDayOfferOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SentTrupanionExamDayOfferOrderByWithAggregationInput = {
  _count?: Maybe<SentTrupanionExamDayOfferCountOrderByAggregateInput>;
  _max?: Maybe<SentTrupanionExamDayOfferMaxOrderByAggregateInput>;
  _min?: Maybe<SentTrupanionExamDayOfferMinOrderByAggregateInput>;
  activationDate?: Maybe<SortOrder>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SentTrupanionExamDayOfferOrderByRelevanceInput>;
  activationDate?: Maybe<SortOrder>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  referenceNumber?: Maybe<SortOrder>;
  sentByClinicUserId?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SentTrupanionExamDayOfferScalarFieldEnum {
  ActivationDate = 'activationDate',
  AppointmentTypeId = 'appointmentTypeId',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  Id = 'id',
  ReferenceNumber = 'referenceNumber',
  SentByClinicUserId = 'sentByClinicUserId',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  UpdatedAt = 'updatedAt'
}

export type SentTrupanionExamDayOfferScalarWhereInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  referenceNumber?: Maybe<StringFilter>;
  sentByClinicUserId?: Maybe<StringFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SentTrupanionExamDayOfferScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereWithAggregatesInput>>;
  activationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  referenceNumber?: Maybe<StringWithAggregatesFilter>;
  sentByClinicUserId?: Maybe<StringWithAggregatesFilter>;
  trupanionIntegrationId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SentTrupanionExamDayOfferUncheckedCreateInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['String'];
  sentByClinicUserId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutRelatedTrupanionExamDayOffersInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentTypeId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  sentByClinicUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateManyMutationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutRelatedTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutReceivedTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateManyWithoutSentTrupanionExamDayOffersInput;
  where: SentTrupanionExamDayOfferScalarWhereInput;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutClinicUserInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutClinicUserInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyClinicUserInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutClinicUserInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput>>;
};

export type SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput = {
  connect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SentTrupanionExamDayOfferCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<SentTrupanionExamDayOfferCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<SentTrupanionExamDayOfferCreateManyTrupanionIntegrationInputEnvelope>;
  delete?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SentTrupanionExamDayOfferScalarWhereInput>>;
  disconnect?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  set?: Maybe<Array<SentTrupanionExamDayOfferWhereUniqueInput>>;
  update?: Maybe<Array<SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<SentTrupanionExamDayOfferUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutClinicUserInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithWhereUniqueWithoutTrupanionIntegrationInput = {
  data: SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpdateWithoutAppointmentTypeInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicPetInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicPetParentInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateWithoutClinicUserInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpdateWithoutTrupanionIntegrationInput = {
  activationDate?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutRelatedTrupanionExamDayOffersNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutReceivedTrupanionExamDayOffersNestedInput>;
  clinicUser?: Maybe<UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutAppointmentTypeInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutAppointmentTypeInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicPetParentInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicPetParentInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutClinicUserInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutClinicUserInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutClinicUserInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferUpsertWithWhereUniqueWithoutTrupanionIntegrationInput = {
  create: SentTrupanionExamDayOfferUncheckedCreateWithoutTrupanionIntegrationInput;
  update: SentTrupanionExamDayOfferUncheckedUpdateWithoutTrupanionIntegrationInput;
  where: SentTrupanionExamDayOfferWhereUniqueInput;
};

export type SentTrupanionExamDayOfferWhereInput = {
  AND?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  NOT?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  OR?: Maybe<Array<SentTrupanionExamDayOfferWhereInput>>;
  activationDate?: Maybe<DateTimeNullableFilter>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  clinicUser?: Maybe<UserWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  referenceNumber?: Maybe<StringFilter>;
  sentByClinicUserId?: Maybe<StringFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SentTrupanionExamDayOfferWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  _count: ServiceCountOutputType;
  canonicalService?: Maybe<CanonicalService>;
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  draftCareBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  formTemplate?: Maybe<FormTemplate>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders: Scalars['Boolean'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  invoiceLineItems: Array<InvoiceLineItem>;
  isDeleted: Scalars['Boolean'];
  isPetPortal: Scalars['Boolean'];
  isPostcard: Scalars['Boolean'];
  isReminderActive: Scalars['Boolean'];
  loyaltyPointGrantingServices: Array<LoyaltyPointGrantingService>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService: Array<OrganizationCareBenefitToClinicService>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions: Array<Prescription>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings: Array<ServiceReminderTiming>;
  reminders: Array<ServiceReminder>;
  serviceCategory?: Maybe<ServiceCategory>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vetsourceCodeTags: Array<VetsourceCodeTag>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};


export type ServiceDraftCareBenefitUsageToInvoiceLineItemsArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type ServiceInvoiceLineItemsArgs = {
  cursor?: Maybe<InvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<InvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<InvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceLineItemWhereInput>;
};


export type ServiceLoyaltyPointGrantingServicesArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointGrantingServiceScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceWhereInput>;
};


export type ServiceOrganizationCareBenefitToClinicServiceArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceWhereUniqueInput>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceScalarFieldEnum>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
};


export type ServicePrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  distinct?: Maybe<PrescriptionScalarFieldEnum>;
  orderBy?: Maybe<PrescriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};


export type ServiceReminderTimingsArgs = {
  cursor?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderTimingScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderTimingWhereInput>;
};


export type ServiceRemindersArgs = {
  cursor?: Maybe<ServiceReminderWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderWhereInput>;
};


export type ServiceVetsourceCodeTagsArgs = {
  cursor?: Maybe<VetsourceCodeTagWhereUniqueInput>;
  distinct?: Maybe<VetsourceCodeTagScalarFieldEnum>;
  orderBy?: Maybe<VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceCodeTagWhereInput>;
};

export enum ServiceCapability {
  Mms = 'MMS',
  Sms = 'SMS',
  Voice = 'Voice'
}

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  _count: ServiceCategoryCountOutputType;
  childServiceCategoires: Array<ServiceCategory>;
  childServices: Array<Service>;
  connectedCareBenefits: Array<OrganizationCareBenefitToClinicServiceCategory>;
  description: Scalars['String'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  loyaltyPointGrantingServiceCategories: Array<LoyaltyPointGrantingServiceCategory>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategory>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};


export type ServiceCategoryChildServiceCategoiresArgs = {
  cursor?: Maybe<ServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<ServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceCategoryWhereInput>;
};


export type ServiceCategoryChildServicesArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type ServiceCategoryConnectedCareBenefitsArgs = {
  cursor?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<OrganizationCareBenefitToClinicServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationCareBenefitToClinicServiceCategoryWhereInput>;
};


export type ServiceCategoryLoyaltyPointGrantingServiceCategoriesArgs = {
  cursor?: Maybe<LoyaltyPointGrantingServiceCategoryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointGrantingServiceCategoryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointGrantingServiceCategoryWhereInput>;
};

export type ServiceCategoryCountAggregateOutputType = {
  __typename?: 'ServiceCategoryCountAggregateOutputType';
  _all: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  parentCategoryPimsId: Scalars['Int'];
  parentServiceCategoryId: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  shortDescription: Scalars['Int'];
};

export type ServiceCategoryCountOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
};

export type ServiceCategoryCountOutputType = {
  __typename?: 'ServiceCategoryCountOutputType';
  childServiceCategoires: Scalars['Int'];
  childServices: Scalars['Int'];
  connectedCareBenefits: Scalars['Int'];
  loyaltyPointGrantingServiceCategories: Scalars['Int'];
};

export type ServiceCategoryCreateInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateManyInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateManyIntegrationInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceCategoryCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCategoryCreateManyParentServiceCategoryInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateManyParentServiceCategoryInputEnvelope = {
  data: Array<ServiceCategoryCreateManyParentServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCategoryCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
};

export type ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
};

export type ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput>;
};

export type ServiceCategoryCreateNestedOneWithoutChildServicesInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServicesInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServicesInput>;
};

export type ServiceCategoryCreateNestedOneWithoutConnectedCareBenefitsInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput>;
};

export type ServiceCategoryCreateNestedOneWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
};

export type ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput = {
  create: ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateOrConnectWithoutChildServicesInput = {
  create: ServiceCategoryUncheckedCreateWithoutChildServicesInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput = {
  create: ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateOrConnectWithoutIntegrationInput = {
  create: ServiceCategoryUncheckedCreateWithoutIntegrationInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  create: ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput = {
  create: ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateWithoutChildServiceCategoiresInput = {
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateWithoutChildServicesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateWithoutConnectedCareBenefitsInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateWithoutIntegrationInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServiceCategoiresInput>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryCreateWithoutParentServiceCategoryInput = {
  childServiceCategoires?: Maybe<ServiceCategoryCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceCategoriesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryListRelationFilter = {
  every?: Maybe<ServiceCategoryWhereInput>;
  none?: Maybe<ServiceCategoryWhereInput>;
  some?: Maybe<ServiceCategoryWhereInput>;
};

export type ServiceCategoryMaxAggregateOutputType = {
  __typename?: 'ServiceCategoryMaxAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryMaxOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
};

export type ServiceCategoryMinAggregateOutputType = {
  __typename?: 'ServiceCategoryMinAggregateOutputType';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryMinOrderByAggregateInput = {
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
};

export type ServiceCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceCategoryOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  ParentCategoryPimsId = 'parentCategoryPimsId',
  ParentServiceCategoryId = 'parentServiceCategoryId',
  PimsId = 'pimsId',
  ShortDescription = 'shortDescription'
}

export type ServiceCategoryOrderByRelevanceInput = {
  fields: Array<ServiceCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ServiceCategoryOrderByWithAggregationInput = {
  _count?: Maybe<ServiceCategoryCountOrderByAggregateInput>;
  _max?: Maybe<ServiceCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceCategoryMinOrderByAggregateInput>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
};

export type ServiceCategoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ServiceCategoryOrderByRelevanceInput>;
  childServiceCategoires?: Maybe<ServiceCategoryOrderByRelationAggregateInput>;
  childServices?: Maybe<ServiceOrderByRelationAggregateInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryOrderByRelationAggregateInput>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryOrderByRelationAggregateInput>;
  parentCategoryPimsId?: Maybe<SortOrder>;
  parentServiceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  parentServiceCategoryId?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  shortDescription?: Maybe<SortOrder>;
};

export type ServiceCategoryPimsIdIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ServiceCategoryRelationFilter = {
  is?: Maybe<ServiceCategoryWhereInput>;
  isNot?: Maybe<ServiceCategoryWhereInput>;
};

export enum ServiceCategoryScalarFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsDeleted = 'isDeleted',
  ParentCategoryPimsId = 'parentCategoryPimsId',
  ParentServiceCategoryId = 'parentServiceCategoryId',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  ShortDescription = 'shortDescription'
}

export type ServiceCategoryScalarWhereInput = {
  AND?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  OR?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  parentCategoryPimsId?: Maybe<StringNullableFilter>;
  parentServiceCategoryId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  shortDescription?: Maybe<StringFilter>;
};

export type ServiceCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceCategoryScalarWhereWithAggregatesInput>>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  parentCategoryPimsId?: Maybe<StringNullableWithAggregatesFilter>;
  parentServiceCategoryId?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonWithAggregatesFilter>;
  shortDescription?: Maybe<StringWithAggregatesFilter>;
};

export type ServiceCategoryUncheckedCreateInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
};

export type ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
};

export type ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput = {
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateWithoutChildServicesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateWithoutIntegrationInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedCreateNestedManyWithoutParentServiceCategoryInput>;
  childServices?: Maybe<ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedCreateNestedManyWithoutServiceCategoryInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId: Scalars['String'];
  rawPimsValue: Scalars['Json'];
  shortDescription: Scalars['String'];
};

export type ServiceCategoryUncheckedUpdateInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateManyInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutChildServiceCategoiresInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput>>;
};

export type ServiceCategoryUncheckedUpdateManyWithoutServiceCategoriesInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput = {
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutChildServicesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutIntegrationInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategoryId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUncheckedUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUncheckedUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateManyMutationInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput = {
  data: ServiceCategoryUncheckedUpdateManyWithoutServiceCategoriesInput;
  where: ServiceCategoryScalarWhereInput;
};

export type ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput = {
  data: ServiceCategoryUncheckedUpdateManyWithoutChildServiceCategoiresInput;
  where: ServiceCategoryScalarWhereInput;
};

export type ServiceCategoryUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput = {
  connect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCategoryCreateOrConnectWithoutParentServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCategoryCreateWithoutParentServiceCategoryInput>>;
  createMany?: Maybe<ServiceCategoryCreateManyParentServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  set?: Maybe<Array<ServiceCategoryWhereUniqueInput>>;
  update?: Maybe<Array<ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceCategoryUpdateManyWithWhereWithoutParentServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput>>;
};

export type ServiceCategoryUpdateOneRequiredWithoutConnectedCareBenefitsNestedInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutConnectedCareBenefitsInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutConnectedCareBenefitsInput>;
};

export type ServiceCategoryUpdateOneRequiredWithoutLoyaltyPointGrantingServiceCategoriesNestedInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutLoyaltyPointGrantingServiceCategoriesInput>;
};

export type ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServiceCategoiresInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutChildServiceCategoiresInput>;
};

export type ServiceCategoryUpdateOneWithoutChildServicesNestedInput = {
  connect?: Maybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCategoryCreateOrConnectWithoutChildServicesInput>;
  create?: Maybe<ServiceCategoryUncheckedCreateWithoutChildServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceCategoryUncheckedUpdateWithoutChildServicesInput>;
  upsert?: Maybe<ServiceCategoryUpsertWithoutChildServicesInput>;
};

export type ServiceCategoryUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ServiceCategoryUncheckedUpdateWithoutIntegrationInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryUpdateWithWhereUniqueWithoutParentServiceCategoryInput = {
  data: ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryUpdateWithoutChildServiceCategoiresInput = {
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateWithoutChildServicesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateWithoutConnectedCareBenefitsInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateWithoutIntegrationInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  parentServiceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServiceCategoiresNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpdateWithoutParentServiceCategoryInput = {
  childServiceCategoires?: Maybe<ServiceCategoryUpdateManyWithoutParentServiceCategoryNestedInput>;
  childServices?: Maybe<ServiceUpdateManyWithoutServiceCategoryNestedInput>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceCategoriesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryUpdateManyWithoutServiceCategoryNestedInput>;
  parentCategoryPimsId?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ServiceCategoryUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ServiceCategoryUncheckedCreateWithoutIntegrationInput;
  update: ServiceCategoryUncheckedUpdateWithoutIntegrationInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryUpsertWithWhereUniqueWithoutParentServiceCategoryInput = {
  create: ServiceCategoryUncheckedCreateWithoutParentServiceCategoryInput;
  update: ServiceCategoryUncheckedUpdateWithoutParentServiceCategoryInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryUpsertWithoutChildServiceCategoiresInput = {
  create: ServiceCategoryUncheckedCreateWithoutChildServiceCategoiresInput;
  update: ServiceCategoryUncheckedUpdateWithoutChildServiceCategoiresInput;
};

export type ServiceCategoryUpsertWithoutChildServicesInput = {
  create: ServiceCategoryUncheckedCreateWithoutChildServicesInput;
  update: ServiceCategoryUncheckedUpdateWithoutChildServicesInput;
};

export type ServiceCategoryUpsertWithoutConnectedCareBenefitsInput = {
  create: ServiceCategoryUncheckedCreateWithoutConnectedCareBenefitsInput;
  update: ServiceCategoryUncheckedUpdateWithoutConnectedCareBenefitsInput;
};

export type ServiceCategoryUpsertWithoutLoyaltyPointGrantingServiceCategoriesInput = {
  create: ServiceCategoryUncheckedCreateWithoutLoyaltyPointGrantingServiceCategoriesInput;
  update: ServiceCategoryUncheckedUpdateWithoutLoyaltyPointGrantingServiceCategoriesInput;
};

export type ServiceCategoryWhereInput = {
  AND?: Maybe<Array<ServiceCategoryWhereInput>>;
  NOT?: Maybe<Array<ServiceCategoryWhereInput>>;
  OR?: Maybe<Array<ServiceCategoryWhereInput>>;
  childServiceCategoires?: Maybe<ServiceCategoryListRelationFilter>;
  childServices?: Maybe<ServiceListRelationFilter>;
  connectedCareBenefits?: Maybe<OrganizationCareBenefitToClinicServiceCategoryListRelationFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  loyaltyPointGrantingServiceCategories?: Maybe<LoyaltyPointGrantingServiceCategoryListRelationFilter>;
  parentCategoryPimsId?: Maybe<StringNullableFilter>;
  parentServiceCategory?: Maybe<ServiceCategoryWhereInput>;
  parentServiceCategoryId?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonFilter>;
  shortDescription?: Maybe<StringFilter>;
};

export type ServiceCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsId_integrationId?: Maybe<ServiceCategoryPimsIdIntegrationIdCompoundUniqueInput>;
};

export type ServiceCountAggregateOutputType = {
  __typename?: 'ServiceCountAggregateOutputType';
  _all: Scalars['Int'];
  canonicalServiceId: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  formTemplateId: Scalars['Int'];
  hasConfiguredReminders: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isPetPortal: Scalars['Int'];
  isPostcard: Scalars['Int'];
  isReminderActive: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  pimsName: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  reminderActivationDate: Scalars['Int'];
  serviceCategoryId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  vetsourceRevenueCategoryName: Scalars['Int'];
};

export type ServiceCountOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceCountOutputType = {
  __typename?: 'ServiceCountOutputType';
  draftCareBenefitUsageToInvoiceLineItems: Scalars['Int'];
  invoiceLineItems: Scalars['Int'];
  loyaltyPointGrantingServices: Scalars['Int'];
  organizationCareBenefitToClinicService: Scalars['Int'];
  prescriptions: Scalars['Int'];
  reminderTimings: Scalars['Int'];
  reminders: Scalars['Int'];
  vetsourceCodeTags: Scalars['Int'];
};

export type ServiceCreateInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateManyCanonicalServiceInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyCanonicalServiceInputEnvelope = {
  data: Array<ServiceCreateManyCanonicalServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyFormTemplateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyFormTemplateInputEnvelope = {
  data: Array<ServiceCreateManyFormTemplateInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyIntegrationInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyServiceCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceCreateManyServiceCategoryInputEnvelope = {
  data: Array<ServiceCreateManyServiceCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateManyVetsourceRevenueCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceCreateManyVetsourceRevenueCategoryInputEnvelope = {
  data: Array<ServiceCreateManyVetsourceRevenueCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceCreateNestedManyWithoutCanonicalServiceInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutVetsourceCodeTagsInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
};

export type ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
};

export type ServiceCreateNestedOneWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type ServiceCreateNestedOneWithoutInvoiceLineItemsInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
};

export type ServiceCreateNestedOneWithoutLoyaltyPointGrantingServicesInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput>;
};

export type ServiceCreateNestedOneWithoutOrganizationCareBenefitToClinicServiceInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type ServiceCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
};

export type ServiceCreateNestedOneWithoutReminderTimingsInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
};

export type ServiceCreateNestedOneWithoutRemindersInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
};

export type ServiceCreateOrConnectWithoutCanonicalServiceInput = {
  create: ServiceUncheckedCreateWithoutCanonicalServiceInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutFormTemplateInput = {
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutIntegrationInput = {
  create: ServiceUncheckedCreateWithoutIntegrationInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutInvoiceLineItemsInput = {
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput = {
  create: ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput = {
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutPrescriptionsInput = {
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutReminderTimingsInput = {
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutRemindersInput = {
  create: ServiceUncheckedCreateWithoutRemindersInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceCategoryInput = {
  create: ServiceUncheckedCreateWithoutServiceCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutVetsourceCodeTagsInput = {
  create: ServiceUncheckedCreateWithoutVetsourceCodeTagsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput = {
  create: ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateWithoutCanonicalServiceInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutFormTemplateInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutIntegrationInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutInvoiceLineItemsInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutLoyaltyPointGrantingServicesInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutPrescriptionsInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutReminderTimingsInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutRemindersInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutServiceCategoryInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutVetsourceCodeTagsInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput>;
};

export type ServiceCreateWithoutVetsourceRevenueCategoryInput = {
  canonicalService?: Maybe<CanonicalServiceCreateNestedOneWithoutServicesInput>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutDraftServiceInput>;
  formTemplate?: Maybe<FormTemplateCreateNestedOneWithoutServicesInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServicesInput;
  invoiceLineItems?: Maybe<InvoiceLineItemCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderCreateNestedManyWithoutServiceInput>;
  serviceCategory?: Maybe<ServiceCategoryCreateNestedOneWithoutChildServicesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagCreateNestedManyWithoutServiceInput>;
};

export type ServiceListRelationFilter = {
  every?: Maybe<ServiceWhereInput>;
  none?: Maybe<ServiceWhereInput>;
  some?: Maybe<ServiceWhereInput>;
};

export type ServiceMaxAggregateOutputType = {
  __typename?: 'ServiceMaxAggregateOutputType';
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceMaxOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceMinAggregateOutputType = {
  __typename?: 'ServiceMinAggregateOutputType';
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceMinOrderByAggregateInput = {
  canonicalServiceId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceOrderByRelevanceFieldEnum {
  CanonicalServiceId = 'canonicalServiceId',
  Code = 'code',
  FormTemplateId = 'formTemplateId',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId',
  PimsName = 'pimsName',
  ServiceCategoryId = 'serviceCategoryId',
  VetsourceRevenueCategoryName = 'vetsourceRevenueCategoryName'
}

export type ServiceOrderByRelevanceInput = {
  fields: Array<ServiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ServiceOrderByWithAggregationInput = {
  _count?: Maybe<ServiceCountOrderByAggregateInput>;
  _max?: Maybe<ServiceMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceMinOrderByAggregateInput>;
  canonicalServiceId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  formTemplateId?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export type ServiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ServiceOrderByRelevanceInput>;
  canonicalService?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  canonicalServiceId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  formTemplate?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  formTemplateId?: Maybe<SortOrder>;
  hasConfiguredReminders?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  invoiceLineItems?: Maybe<InvoiceLineItemOrderByRelationAggregateInput>;
  isDeleted?: Maybe<SortOrder>;
  isPetPortal?: Maybe<SortOrder>;
  isPostcard?: Maybe<SortOrder>;
  isReminderActive?: Maybe<SortOrder>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceOrderByRelationAggregateInput>;
  name?: Maybe<SortOrder>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  prescriptions?: Maybe<PrescriptionOrderByRelationAggregateInput>;
  rawPimsValue?: Maybe<SortOrder>;
  reminderActivationDate?: Maybe<SortOrder>;
  reminderTimings?: Maybe<ServiceReminderTimingOrderByRelationAggregateInput>;
  reminders?: Maybe<ServiceReminderOrderByRelationAggregateInput>;
  serviceCategory?: Maybe<ServiceCategoryOrderByWithRelationAndSearchRelevanceInput>;
  serviceCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagOrderByRelationAggregateInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  vetsourceRevenueCategoryName?: Maybe<SortOrder>;
};

export enum ServiceProvider {
  Bandwidth = 'Bandwidth',
  Twilio = 'Twilio'
}

export type ServiceRelationFilter = {
  is?: Maybe<ServiceWhereInput>;
  isNot?: Maybe<ServiceWhereInput>;
};

export type ServiceReminder = {
  __typename?: 'ServiceReminder';
  _count: ServiceReminderCountOutputType;
  automationRuns: Array<AutomationRun>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  clinicPet?: Maybe<ClinicPet>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded: Scalars['Boolean'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  notifications: Array<ServiceReminderNotification>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: Service;
  serviceId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type ServiceReminderAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type ServiceReminderChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type ServiceReminderChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type ServiceReminderNotificationsArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderCountAggregateOutputType = {
  __typename?: 'ServiceReminderCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  dateLastGiven: Scalars['Int'];
  dueDate: Scalars['Int'];
  hasResponded: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isDeleted: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  serviceId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ServiceReminderCountOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderCountOutputType = {
  __typename?: 'ServiceReminderCountOutputType';
  automationRuns: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  notifications: Scalars['Int'];
};

export type ServiceReminderCreateInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyClinicPetInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyClinicPetInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyClinicPetParentInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyClinicPetParentInputEnvelope = {
  data: Array<ServiceReminderCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyIntegrationInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyIntegrationInputEnvelope = {
  data: Array<ServiceReminderCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateManyServiceInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
};

export type ServiceReminderCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
};

export type ServiceReminderCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
};

export type ServiceReminderCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
};

export type ServiceReminderCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type ServiceReminderCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type ServiceReminderCreateNestedOneWithoutNotificationsInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
};

export type ServiceReminderCreateOrConnectWithoutAutomationRunsInput = {
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetInput = {
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutClinicPetParentInput = {
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutIntegrationInput = {
  create: ServiceReminderUncheckedCreateWithoutIntegrationInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutNotificationsInput = {
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateOrConnectWithoutServiceInput = {
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderCreateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutClinicPetInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutIntegrationInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutNotificationsInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  service: ServiceCreateNestedOneWithoutRemindersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderCreateWithoutServiceInput = {
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutServiceReminderInput>;
  clinicPet?: Maybe<ClinicPetCreateNestedOneWithoutServiceRemindersInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutServiceRemindersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutServiceRemindersInput;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderListRelationFilter = {
  every?: Maybe<ServiceReminderWhereInput>;
  none?: Maybe<ServiceReminderWhereInput>;
  some?: Maybe<ServiceReminderWhereInput>;
};

export type ServiceReminderMaxAggregateOutputType = {
  __typename?: 'ServiceReminderMaxAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderMaxOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderMinAggregateOutputType = {
  __typename?: 'ServiceReminderMinAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderMinOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotification = {
  __typename?: 'ServiceReminderNotification';
  _count: ServiceReminderNotificationCountOutputType;
  bundledNotifications: Array<ServiceReminderNotification>;
  createdAt: Scalars['DateTime'];
  formSubmission?: Maybe<FormSubmission>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded: Scalars['Boolean'];
  id: Scalars['String'];
  notificationType: ServiceReminderNotificationType;
  parentNotification?: Maybe<ServiceReminderNotification>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued: Scalars['Boolean'];
  serviceReminder: ServiceReminder;
  serviceReminderId: Scalars['String'];
  timing?: Maybe<ServiceReminderTiming>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ServiceReminderNotificationBundledNotificationsArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationCountAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  formSubmissionId: Scalars['Int'];
  hasResponded: Scalars['Int'];
  id: Scalars['Int'];
  notificationType: Scalars['Int'];
  parentNotificationId: Scalars['Int'];
  queueAt: Scalars['Int'];
  queued: Scalars['Int'];
  serviceReminderId: Scalars['Int'];
  timingId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ServiceReminderNotificationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationCountOutputType = {
  __typename?: 'ServiceReminderNotificationCountOutputType';
  bundledNotifications: Scalars['Int'];
};

export type ServiceReminderNotificationCreateInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyFormSubmissionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyParentNotificationInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyParentNotificationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyServiceReminderInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyServiceReminderInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateManyTimingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateManyTimingInputEnvelope = {
  data: Array<ServiceReminderNotificationCreateManyTimingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
};

export type ServiceReminderNotificationCreateNestedManyWithoutTimingInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
};

export type ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput = {
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationCreateOrConnectWithoutTimingInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationCreateWithoutBundledNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateWithoutFormSubmissionInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateWithoutParentNotificationInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateWithoutServiceReminderInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  timing?: Maybe<ServiceReminderTimingCreateNestedOneWithoutNotificationsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationCreateWithoutTimingInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionCreateNestedOneWithoutServiceReminderNotificationsInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationCreateNestedOneWithoutBundledNotificationsInput>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder: ServiceReminderCreateNestedOneWithoutNotificationsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationListRelationFilter = {
  every?: Maybe<ServiceReminderNotificationWhereInput>;
  none?: Maybe<ServiceReminderNotificationWhereInput>;
  some?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderNotificationMaxAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationMinAggregateOutputType = {
  __typename?: 'ServiceReminderNotificationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderNotificationOrderByRelevanceFieldEnum {
  FormSubmissionId = 'formSubmissionId',
  Id = 'id',
  ParentNotificationId = 'parentNotificationId',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId'
}

export type ServiceReminderNotificationOrderByRelevanceInput = {
  fields: Array<ServiceReminderNotificationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ServiceReminderNotificationOrderByWithAggregationInput = {
  _count?: Maybe<ServiceReminderNotificationCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderNotificationMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderNotificationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotificationId?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  serviceReminderId?: Maybe<SortOrder>;
  timingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ServiceReminderNotificationOrderByRelevanceInput>;
  bundledNotifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  formSubmission?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  formSubmissionId?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notificationType?: Maybe<SortOrder>;
  parentNotification?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  parentNotificationId?: Maybe<SortOrder>;
  queueAt?: Maybe<SortOrder>;
  queued?: Maybe<SortOrder>;
  serviceReminder?: Maybe<ServiceReminderOrderByWithRelationAndSearchRelevanceInput>;
  serviceReminderId?: Maybe<SortOrder>;
  timing?: Maybe<ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput>;
  timingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderNotificationRelationFilter = {
  is?: Maybe<ServiceReminderNotificationWhereInput>;
  isNot?: Maybe<ServiceReminderNotificationWhereInput>;
};

export enum ServiceReminderNotificationScalarFieldEnum {
  CreatedAt = 'createdAt',
  FormSubmissionId = 'formSubmissionId',
  HasResponded = 'hasResponded',
  Id = 'id',
  NotificationType = 'notificationType',
  ParentNotificationId = 'parentNotificationId',
  QueueAt = 'queueAt',
  Queued = 'queued',
  ServiceReminderId = 'serviceReminderId',
  TimingId = 'timingId',
  UpdatedAt = 'updatedAt'
}

export type ServiceReminderNotificationScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  hasResponded?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  parentNotificationId?: Maybe<StringNullableFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  serviceReminderId?: Maybe<StringFilter>;
  timingId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderNotificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  formSubmissionId?: Maybe<StringNullableWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeWithAggregatesFilter>;
  parentNotificationId?: Maybe<StringNullableWithAggregatesFilter>;
  queueAt?: Maybe<DateTimeWithAggregatesFilter>;
  queued?: Maybe<BoolWithAggregatesFilter>;
  serviceReminderId?: Maybe<StringWithAggregatesFilter>;
  timingId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum ServiceReminderNotificationType {
  Digital = 'Digital',
  Postcard = 'Postcard'
}

export type ServiceReminderNotificationUncheckedCreateInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutFormSubmissionInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
};

export type ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedCreateWithoutTimingInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutParentNotificationInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt: Scalars['DateTime'];
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  timingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUncheckedUpdateWithoutTimingInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmissionId?: Maybe<Scalars['String']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotificationId?: Maybe<Scalars['String']>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminderId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput = {
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNotificationsInput;
  where: ServiceReminderNotificationScalarWhereInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput = {
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutBundledNotificationsInput;
  where: ServiceReminderNotificationScalarWhereInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput = {
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
  where: ServiceReminderNotificationScalarWhereInput;
};

export type ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput = {
  data: ServiceReminderNotificationUncheckedUpdateManyWithoutNotificationsInput;
  where: ServiceReminderNotificationScalarWhereInput;
};

export type ServiceReminderNotificationUpdateManyWithoutFormSubmissionNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutFormSubmissionInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutFormSubmissionInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyFormSubmissionInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutFormSubmissionInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutParentNotificationInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutParentNotificationInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyParentNotificationInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutParentNotificationInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutServiceReminderInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutServiceReminderInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyServiceReminderInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutServiceReminderInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput>>;
};

export type ServiceReminderNotificationUpdateManyWithoutTimingNestedInput = {
  connect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderNotificationCreateOrConnectWithoutTimingInput>>;
  create?: Maybe<Array<ServiceReminderNotificationCreateWithoutTimingInput>>;
  createMany?: Maybe<ServiceReminderNotificationCreateManyTimingInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderNotificationScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderNotificationWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput>>;
  updateMany?: Maybe<Array<ServiceReminderNotificationUpdateManyWithWhereWithoutTimingInput>>;
  upsert?: Maybe<Array<ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput>>;
};

export type ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput = {
  connect?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderNotificationCreateOrConnectWithoutBundledNotificationsInput>;
  create?: Maybe<ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput>;
  upsert?: Maybe<ServiceReminderNotificationUpsertWithoutBundledNotificationsInput>;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutFormSubmissionInput = {
  data: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutParentNotificationInput = {
  data: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutServiceReminderInput = {
  data: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpdateWithWhereUniqueWithoutTimingInput = {
  data: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpdateWithoutBundledNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateWithoutFormSubmissionInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateWithoutParentNotificationInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateWithoutServiceReminderInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  timing?: Maybe<ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpdateWithoutTimingInput = {
  bundledNotifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutParentNotificationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formSubmission?: Maybe<FormSubmissionUpdateOneWithoutServiceReminderNotificationsNestedInput>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  notificationType?: Maybe<ServiceReminderNotificationType>;
  parentNotification?: Maybe<ServiceReminderNotificationUpdateOneWithoutBundledNotificationsNestedInput>;
  queueAt?: Maybe<Scalars['DateTime']>;
  queued?: Maybe<Scalars['Boolean']>;
  serviceReminder?: Maybe<ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutFormSubmissionInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutFormSubmissionInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutFormSubmissionInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutParentNotificationInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutParentNotificationInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutParentNotificationInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutServiceReminderInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutServiceReminderInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutServiceReminderInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpsertWithWhereUniqueWithoutTimingInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutTimingInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutTimingInput;
  where: ServiceReminderNotificationWhereUniqueInput;
};

export type ServiceReminderNotificationUpsertWithoutBundledNotificationsInput = {
  create: ServiceReminderNotificationUncheckedCreateWithoutBundledNotificationsInput;
  update: ServiceReminderNotificationUncheckedUpdateWithoutBundledNotificationsInput;
};

export type ServiceReminderNotificationWhereInput = {
  AND?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  OR?: Maybe<Array<ServiceReminderNotificationWhereInput>>;
  bundledNotifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formSubmission?: Maybe<FormSubmissionWhereInput>;
  formSubmissionId?: Maybe<StringNullableFilter>;
  hasResponded?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  notificationType?: Maybe<EnumServiceReminderNotificationTypeFilter>;
  parentNotification?: Maybe<ServiceReminderNotificationWhereInput>;
  parentNotificationId?: Maybe<StringNullableFilter>;
  queueAt?: Maybe<DateTimeFilter>;
  queued?: Maybe<BoolFilter>;
  serviceReminder?: Maybe<ServiceReminderWhereInput>;
  serviceReminderId?: Maybe<StringFilter>;
  timing?: Maybe<ServiceReminderTimingWhereInput>;
  timingId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderOrderByRelevanceFieldEnum {
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  ServiceId = 'serviceId'
}

export type ServiceReminderOrderByRelevanceInput = {
  fields: Array<ServiceReminderOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ServiceReminderOrderByWithAggregationInput = {
  _count?: Maybe<ServiceReminderCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderMinOrderByAggregateInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ServiceReminderOrderByRelevanceInput>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateLastGiven?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  hasResponded?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ServiceReminderRelationFilter = {
  is?: Maybe<ServiceReminderWhereInput>;
  isNot?: Maybe<ServiceReminderWhereInput>;
};

export enum ServiceReminderScalarFieldEnum {
  ClinicPetId = 'clinicPetId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  DateLastGiven = 'dateLastGiven',
  DueDate = 'dueDate',
  HasResponded = 'hasResponded',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsDeleted = 'isDeleted',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  ServiceId = 'serviceId',
  UpdatedAt = 'updatedAt'
}

export type ServiceReminderScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateLastGiven?: Maybe<DateTimeNullableFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  hasResponded?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  serviceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderScalarWhereWithAggregatesInput>>;
  clinicPetId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dateLastGiven?: Maybe<DateTimeNullableWithAggregatesFilter>;
  dueDate?: Maybe<DateTimeWithAggregatesFilter>;
  hasResponded?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ServiceReminderTiming = {
  __typename?: 'ServiceReminderTiming';
  _count: ServiceReminderTimingCountOutputType;
  createdAt: Scalars['DateTime'];
  hourOfDay: Scalars['Int'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isEmail: Scalars['Boolean'];
  isPush: Scalars['Boolean'];
  isSms: Scalars['Boolean'];
  minuteOfHour: Scalars['Int'];
  notifications: Array<ServiceReminderNotification>;
  offset: Scalars['Float'];
  offsetUnit: ServiceReminderTimingOffsetUnit;
  service: Service;
  serviceId: Scalars['String'];
  timingType: ServiceReminderTimingType;
  updatedAt: Scalars['DateTime'];
};


export type ServiceReminderTimingNotificationsArgs = {
  cursor?: Maybe<ServiceReminderNotificationWhereUniqueInput>;
  distinct?: Maybe<ServiceReminderNotificationScalarFieldEnum>;
  orderBy?: Maybe<ServiceReminderNotificationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceReminderNotificationWhereInput>;
};

export type ServiceReminderTimingAvgAggregateOutputType = {
  __typename?: 'ServiceReminderTimingAvgAggregateOutputType';
  hourOfDay?: Maybe<Scalars['Float']>;
  minuteOfHour?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type ServiceReminderTimingAvgOrderByAggregateInput = {
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountAggregateOutputType = {
  __typename?: 'ServiceReminderTimingCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  hourOfDay: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isEmail: Scalars['Int'];
  isPush: Scalars['Int'];
  isSms: Scalars['Int'];
  minuteOfHour: Scalars['Int'];
  offset: Scalars['Int'];
  offsetUnit: Scalars['Int'];
  serviceId: Scalars['Int'];
  timingType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ServiceReminderTimingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderTimingCountOutputType = {
  __typename?: 'ServiceReminderTimingCountOutputType';
  notifications: Scalars['Int'];
};

export type ServiceReminderTimingCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingCreateManyServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingCreateManyServiceInputEnvelope = {
  data: Array<ServiceReminderTimingCreateManyServiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
};

export type ServiceReminderTimingCreateNestedOneWithoutNotificationsInput = {
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
};

export type ServiceReminderTimingCreateOrConnectWithoutNotificationsInput = {
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
  where: ServiceReminderTimingWhereUniqueInput;
};

export type ServiceReminderTimingCreateOrConnectWithoutServiceInput = {
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
  where: ServiceReminderTimingWhereUniqueInput;
};

export type ServiceReminderTimingCreateWithoutNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  service: ServiceCreateNestedOneWithoutReminderTimingsInput;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationCreateNestedManyWithoutTimingInput>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingDeliveryOptionInput = {
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
};

export type ServiceReminderTimingInput = {
  hourOfDay: Scalars['Int'];
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour: Scalars['Int'];
  offset: Scalars['Float'];
  offsetUnit: Scalars['String'];
  timingType: ServiceReminderTimingType;
};

export type ServiceReminderTimingListRelationFilter = {
  every?: Maybe<ServiceReminderTimingWhereInput>;
  none?: Maybe<ServiceReminderTimingWhereInput>;
  some?: Maybe<ServiceReminderTimingWhereInput>;
};

export type ServiceReminderTimingMaxAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderTimingMinAggregateOutputType = {
  __typename?: 'ServiceReminderTimingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOffsetUnit {
  Days = 'Days',
  Months = 'Months',
  Weeks = 'Weeks'
}

export type ServiceReminderTimingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingOrderByRelevanceFieldEnum {
  Id = 'id',
  ServiceId = 'serviceId'
}

export type ServiceReminderTimingOrderByRelevanceInput = {
  fields: Array<ServiceReminderTimingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type ServiceReminderTimingOrderByWithAggregationInput = {
  _avg?: Maybe<ServiceReminderTimingAvgOrderByAggregateInput>;
  _count?: Maybe<ServiceReminderTimingCountOrderByAggregateInput>;
  _max?: Maybe<ServiceReminderTimingMaxOrderByAggregateInput>;
  _min?: Maybe<ServiceReminderTimingMinOrderByAggregateInput>;
  _sum?: Maybe<ServiceReminderTimingSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderTimingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<ServiceReminderTimingOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  hourOfDay?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isEmail?: Maybe<SortOrder>;
  isPush?: Maybe<SortOrder>;
  isSms?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  notifications?: Maybe<ServiceReminderNotificationOrderByRelationAggregateInput>;
  offset?: Maybe<SortOrder>;
  offsetUnit?: Maybe<SortOrder>;
  service?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  serviceId?: Maybe<SortOrder>;
  timingType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ServiceReminderTimingRelationFilter = {
  is?: Maybe<ServiceReminderTimingWhereInput>;
  isNot?: Maybe<ServiceReminderTimingWhereInput>;
};

export enum ServiceReminderTimingScalarFieldEnum {
  CreatedAt = 'createdAt',
  HourOfDay = 'hourOfDay',
  Id = 'id',
  IsDeleted = 'isDeleted',
  IsEmail = 'isEmail',
  IsPush = 'isPush',
  IsSms = 'isSms',
  MinuteOfHour = 'minuteOfHour',
  Offset = 'offset',
  OffsetUnit = 'offsetUnit',
  ServiceId = 'serviceId',
  TimingType = 'timingType',
  UpdatedAt = 'updatedAt'
}

export type ServiceReminderTimingScalarWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  hourOfDay?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isEmail?: Maybe<BoolFilter>;
  isPush?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  serviceId?: Maybe<StringFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderTimingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceReminderTimingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  hourOfDay?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isEmail?: Maybe<BoolWithAggregatesFilter>;
  isPush?: Maybe<BoolWithAggregatesFilter>;
  isSms?: Maybe<BoolWithAggregatesFilter>;
  minuteOfHour?: Maybe<IntWithAggregatesFilter>;
  offset?: Maybe<FloatWithAggregatesFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitWithAggregatesFilter>;
  serviceId?: Maybe<StringWithAggregatesFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type ServiceReminderTimingSumAggregateOutputType = {
  __typename?: 'ServiceReminderTimingSumAggregateOutputType';
  hourOfDay?: Maybe<Scalars['Int']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
};

export type ServiceReminderTimingSumOrderByAggregateInput = {
  hourOfDay?: Maybe<SortOrder>;
  minuteOfHour?: Maybe<SortOrder>;
  offset?: Maybe<SortOrder>;
};

export enum ServiceReminderTimingType {
  BeforeDue = 'BeforeDue',
  PastDue = 'PastDue'
}

export type ServiceReminderTimingUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
};

export type ServiceReminderTimingUncheckedCreateWithoutNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId: Scalars['String'];
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedCreateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutTimingInput>;
  offset: Scalars['Float'];
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  serviceId?: Maybe<Scalars['String']>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUncheckedUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutTimingNestedInput>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingNestedInput>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceReminderTimingUncheckedUpdateManyWithoutReminderTimingsInput;
  where: ServiceReminderTimingScalarWhereInput;
};

export type ServiceReminderTimingUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderTimingCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderTimingCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderTimingCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderTimingScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderTimingWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderTimingUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceReminderTimingUpdateOneWithoutNotificationsNestedInput = {
  connect?: Maybe<ServiceReminderTimingWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderTimingCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<ServiceReminderTimingUncheckedCreateWithoutNotificationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderTimingUpsertWithoutNotificationsInput>;
};

export type ServiceReminderTimingUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
  where: ServiceReminderTimingWhereUniqueInput;
};

export type ServiceReminderTimingUpdateWithoutNotificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUpdateWithoutServiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hourOfDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEmail?: Maybe<Scalars['Boolean']>;
  isPush?: Maybe<Scalars['Boolean']>;
  isSms?: Maybe<Scalars['Boolean']>;
  minuteOfHour?: Maybe<Scalars['Int']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutTimingNestedInput>;
  offset?: Maybe<Scalars['Float']>;
  offsetUnit?: Maybe<ServiceReminderTimingOffsetUnit>;
  timingType?: Maybe<ServiceReminderTimingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderTimingUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceReminderTimingUncheckedCreateWithoutServiceInput;
  update: ServiceReminderTimingUncheckedUpdateWithoutServiceInput;
  where: ServiceReminderTimingWhereUniqueInput;
};

export type ServiceReminderTimingUpsertWithoutNotificationsInput = {
  create: ServiceReminderTimingUncheckedCreateWithoutNotificationsInput;
  update: ServiceReminderTimingUncheckedUpdateWithoutNotificationsInput;
};

export type ServiceReminderTimingWhereInput = {
  AND?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  OR?: Maybe<Array<ServiceReminderTimingWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  hourOfDay?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isEmail?: Maybe<BoolFilter>;
  isPush?: Maybe<BoolFilter>;
  isSms?: Maybe<BoolFilter>;
  minuteOfHour?: Maybe<IntFilter>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  offset?: Maybe<FloatFilter>;
  offsetUnit?: Maybe<EnumServiceReminderTimingOffsetUnitFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringFilter>;
  timingType?: Maybe<EnumServiceReminderTimingTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderTimingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ServiceReminderUncheckedCreateInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
};

export type ServiceReminderUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
};

export type ServiceReminderUncheckedCreateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutIntegrationInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutNotificationsInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedCreateWithoutServiceInput = {
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutServiceReminderInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutServiceReminderInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedCreateNestedManyWithoutServiceReminderInput>;
  pimsId: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutRemindersInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput = {
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutIntegrationInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutNotificationsInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUncheckedUpdateWithoutServiceInput = {
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUncheckedUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetInput = {
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
  where: ServiceReminderScalarWhereInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
  where: ServiceReminderScalarWhereInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutIntegrationInput = {
  data: ServiceReminderUncheckedUpdateManyWithoutServiceRemindersInput;
  where: ServiceReminderScalarWhereInput;
};

export type ServiceReminderUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceReminderUncheckedUpdateManyWithoutRemindersInput;
  where: ServiceReminderScalarWhereInput;
};

export type ServiceReminderUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type ServiceReminderUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<ServiceReminderCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type ServiceReminderUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceReminderCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceReminderUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceReminderCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<ServiceReminderCreateWithoutServiceInput>>;
  createMany?: Maybe<ServiceReminderCreateManyServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceReminderScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  set?: Maybe<Array<ServiceReminderWhereUniqueInput>>;
  update?: Maybe<Array<ServiceReminderUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<ServiceReminderUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<ServiceReminderUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type ServiceReminderUpdateOneRequiredWithoutNotificationsNestedInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutNotificationsInput>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutNotificationsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutNotificationsInput>;
};

export type ServiceReminderUpdateOneWithoutAutomationRunsNestedInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutAutomationRunsInput>;
};

export type ServiceReminderUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelAutomationStatusesInput>;
};

export type ServiceReminderUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<ServiceReminderWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceReminderCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<ServiceReminderUpsertWithoutChannelCreationSourcesInput>;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ServiceReminderUncheckedUpdateWithoutIntegrationInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceReminderUncheckedUpdateWithoutServiceInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpdateWithoutAutomationRunsInput = {
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutChannelAutomationStatusesInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutChannelCreationSourcesInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutClinicPetInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutClinicPetParentInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutIntegrationInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutNotificationsInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  service?: Maybe<ServiceUpdateOneRequiredWithoutRemindersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpdateWithoutServiceInput = {
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutServiceReminderNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutServiceReminderNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutServiceReminderNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneWithoutServiceRemindersNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutServiceRemindersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateLastGiven?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  hasResponded?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServiceRemindersNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<ServiceReminderNotificationUpdateManyWithoutServiceReminderNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: ServiceReminderUncheckedCreateWithoutClinicPetInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: ServiceReminderUncheckedCreateWithoutClinicPetParentInput;
  update: ServiceReminderUncheckedUpdateWithoutClinicPetParentInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ServiceReminderUncheckedCreateWithoutIntegrationInput;
  update: ServiceReminderUncheckedUpdateWithoutIntegrationInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceReminderUncheckedCreateWithoutServiceInput;
  update: ServiceReminderUncheckedUpdateWithoutServiceInput;
  where: ServiceReminderWhereUniqueInput;
};

export type ServiceReminderUpsertWithoutAutomationRunsInput = {
  create: ServiceReminderUncheckedCreateWithoutAutomationRunsInput;
  update: ServiceReminderUncheckedUpdateWithoutAutomationRunsInput;
};

export type ServiceReminderUpsertWithoutChannelAutomationStatusesInput = {
  create: ServiceReminderUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: ServiceReminderUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type ServiceReminderUpsertWithoutChannelCreationSourcesInput = {
  create: ServiceReminderUncheckedCreateWithoutChannelCreationSourcesInput;
  update: ServiceReminderUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type ServiceReminderUpsertWithoutNotificationsInput = {
  create: ServiceReminderUncheckedCreateWithoutNotificationsInput;
  update: ServiceReminderUncheckedUpdateWithoutNotificationsInput;
};

export type ServiceReminderWhereInput = {
  AND?: Maybe<Array<ServiceReminderWhereInput>>;
  NOT?: Maybe<Array<ServiceReminderWhereInput>>;
  OR?: Maybe<Array<ServiceReminderWhereInput>>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateLastGiven?: Maybe<DateTimeNullableFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  hasResponded?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  notifications?: Maybe<ServiceReminderNotificationListRelationFilter>;
  pimsId?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  service?: Maybe<ServiceWhereInput>;
  serviceId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ServiceReminderWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  pimsIdAndIntegrationId?: Maybe<ServiceReminderPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export enum ServiceScalarFieldEnum {
  CanonicalServiceId = 'canonicalServiceId',
  Code = 'code',
  CreatedAt = 'createdAt',
  FormTemplateId = 'formTemplateId',
  HasConfiguredReminders = 'hasConfiguredReminders',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsDeleted = 'isDeleted',
  IsPetPortal = 'isPetPortal',
  IsPostcard = 'isPostcard',
  IsReminderActive = 'isReminderActive',
  Name = 'name',
  PimsId = 'pimsId',
  PimsName = 'pimsName',
  RawPimsValue = 'rawPimsValue',
  ReminderActivationDate = 'reminderActivationDate',
  ServiceCategoryId = 'serviceCategoryId',
  UpdatedAt = 'updatedAt',
  VetsourceRevenueCategoryName = 'vetsourceRevenueCategoryName'
}

export type ServiceScalarWhereInput = {
  AND?: Maybe<Array<ServiceScalarWhereInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereInput>>;
  OR?: Maybe<Array<ServiceScalarWhereInput>>;
  canonicalServiceId?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  formTemplateId?: Maybe<StringNullableFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPetPortal?: Maybe<BoolFilter>;
  isPostcard?: Maybe<BoolFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableFilter>;
  serviceCategoryId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableFilter>;
};

export type ServiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ServiceScalarWhereWithAggregatesInput>>;
  canonicalServiceId?: Maybe<StringNullableWithAggregatesFilter>;
  code?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  formTemplateId?: Maybe<StringNullableWithAggregatesFilter>;
  hasConfiguredReminders?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isPetPortal?: Maybe<BoolWithAggregatesFilter>;
  isPostcard?: Maybe<BoolWithAggregatesFilter>;
  isReminderActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringWithAggregatesFilter>;
  pimsName?: Maybe<StringWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  serviceCategoryId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableWithAggregatesFilter>;
};

export type ServiceServiceCodeAndIntegrationIdCompoundUniqueInput = {
  code: Scalars['String'];
  integrationId: Scalars['String'];
};

export type ServiceServicePimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type ServiceUncheckedCreateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateNestedManyWithoutCanonicalServiceInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
};

export type ServiceUncheckedCreateNestedManyWithoutFormTemplateInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
};

export type ServiceUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
};

export type ServiceUncheckedCreateNestedManyWithoutServiceCategoryInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
};

export type ServiceUncheckedCreateNestedManyWithoutVetsourceCodeTagsInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
};

export type ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
};

export type ServiceUncheckedCreateWithoutCanonicalServiceInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutFormTemplateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutIntegrationInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutInvoiceLineItemsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutPrescriptionsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutReminderTimingsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutRemindersInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutServiceCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutVetsourceCodeTagsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutDraftServiceInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedCreateNestedManyWithoutServiceInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedCreateNestedManyWithoutServiceInput>;
  name: Scalars['String'];
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedCreateNestedManyWithoutServiceInput>;
  pimsId: Scalars['String'];
  pimsName: Scalars['String'];
  prescriptions?: Maybe<PrescriptionUncheckedCreateNestedManyWithoutServiceInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedCreateNestedManyWithoutServiceInput>;
  reminders?: Maybe<ServiceReminderUncheckedCreateNestedManyWithoutServiceInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput>;
};

export type ServiceUncheckedUpdateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
};

export type ServiceUncheckedUpdateManyWithoutChildServicesInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type ServiceUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceUncheckedUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type ServiceUncheckedUpdateManyWithoutServiceInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceUncheckedUpdateManyWithoutServicesInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateManyWithoutVetsourceCodeTagsNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
};

export type ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
};

export type ServiceUncheckedUpdateWithoutCanonicalServiceInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutFormTemplateInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutIntegrationInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutInvoiceLineItemsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutPrescriptionsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutReminderTimingsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutRemindersInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutServiceCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategoryName?: Maybe<Scalars['String']>;
};

export type ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput = {
  canonicalServiceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutDraftServiceNestedInput>;
  formTemplateId?: Maybe<Scalars['String']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUncheckedUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUncheckedUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUncheckedUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUncheckedUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUncheckedUpdateManyWithoutServiceNestedInput>;
  serviceCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpdateInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateManyMutationInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceUpdateManyWithWhereWithoutCanonicalServiceInput = {
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutFormTemplateInput = {
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutIntegrationInput = {
  data: ServiceUncheckedUpdateManyWithoutServicesInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutServiceCategoryInput = {
  data: ServiceUncheckedUpdateManyWithoutChildServicesInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput = {
  data: ServiceUncheckedUpdateManyWithoutServiceInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput = {
  data: ServiceUncheckedUpdateManyWithoutServiceInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithoutCanonicalServiceNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutCanonicalServiceInput>>;
  create?: Maybe<Array<ServiceCreateWithoutCanonicalServiceInput>>;
  createMany?: Maybe<ServiceCreateManyCanonicalServiceInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutCanonicalServiceInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput>>;
};

export type ServiceUpdateManyWithoutFormTemplateNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutFormTemplateInput>>;
  create?: Maybe<Array<ServiceCreateWithoutFormTemplateInput>>;
  createMany?: Maybe<ServiceCreateManyFormTemplateInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutFormTemplateInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutFormTemplateInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutFormTemplateInput>>;
};

export type ServiceUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<ServiceCreateWithoutIntegrationInput>>;
  createMany?: Maybe<ServiceCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type ServiceUpdateManyWithoutServiceCategoryNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutServiceCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutServiceCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyServiceCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutServiceCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput>>;
};

export type ServiceUpdateManyWithoutVetsourceCodeTagsNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceCodeTagsInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceCodeTagsInput>>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceCodeTagsInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput>>;
};

export type ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput = {
  connect?: Maybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ServiceCreateOrConnectWithoutVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<ServiceCreateWithoutVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<ServiceCreateManyVetsourceRevenueCategoryInputEnvelope>;
  delete?: Maybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<ServiceWhereUniqueInput>>;
  set?: Maybe<Array<ServiceWhereUniqueInput>>;
  update?: Maybe<Array<ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<ServiceUpdateManyWithWhereWithoutVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput>>;
};

export type ServiceUpdateOneRequiredWithoutLoyaltyPointGrantingServicesNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutLoyaltyPointGrantingServicesInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput>;
  upsert?: Maybe<ServiceUpsertWithoutLoyaltyPointGrantingServicesInput>;
};

export type ServiceUpdateOneRequiredWithoutOrganizationCareBenefitToClinicServiceNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutOrganizationCareBenefitToClinicServiceInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput>;
  upsert?: Maybe<ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput>;
};

export type ServiceUpdateOneRequiredWithoutReminderTimingsNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutReminderTimingsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutReminderTimingsInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutReminderTimingsInput>;
  upsert?: Maybe<ServiceUpsertWithoutReminderTimingsInput>;
};

export type ServiceUpdateOneRequiredWithoutRemindersNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutRemindersInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutRemindersInput>;
  update?: Maybe<ServiceUncheckedUpdateWithoutRemindersInput>;
  upsert?: Maybe<ServiceUpsertWithoutRemindersInput>;
};

export type ServiceUpdateOneWithoutDraftCareBenefitUsageToInvoiceLineItemsNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutInvoiceLineItemsNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutInvoiceLineItemsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutInvoiceLineItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceUncheckedUpdateWithoutInvoiceLineItemsInput>;
  upsert?: Maybe<ServiceUpsertWithoutInvoiceLineItemsInput>;
};

export type ServiceUpdateOneWithoutPrescriptionsNestedInput = {
  connect?: Maybe<ServiceWhereUniqueInput>;
  connectOrCreate?: Maybe<ServiceCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<ServiceUncheckedCreateWithoutPrescriptionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ServiceUncheckedUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<ServiceUpsertWithoutPrescriptionsInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutCanonicalServiceInput = {
  data: ServiceUncheckedUpdateWithoutCanonicalServiceInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutFormTemplateInput = {
  data: ServiceUncheckedUpdateWithoutFormTemplateInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: ServiceUncheckedUpdateWithoutIntegrationInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutServiceCategoryInput = {
  data: ServiceUncheckedUpdateWithoutServiceCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutVetsourceCodeTagsInput = {
  data: ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutVetsourceRevenueCategoryInput = {
  data: ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithoutCanonicalServiceInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutFormTemplateInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutIntegrationInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutInvoiceLineItemsInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutLoyaltyPointGrantingServicesInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutOrganizationCareBenefitToClinicServiceInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutPrescriptionsInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutReminderTimingsInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutRemindersInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutServiceCategoryInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutVetsourceCodeTagsInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput>;
};

export type ServiceUpdateWithoutVetsourceRevenueCategoryInput = {
  canonicalService?: Maybe<CanonicalServiceUpdateOneWithoutServicesNestedInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutDraftServiceNestedInput>;
  formTemplate?: Maybe<FormTemplateUpdateOneWithoutServicesNestedInput>;
  hasConfiguredReminders?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutServicesNestedInput>;
  invoiceLineItems?: Maybe<InvoiceLineItemUpdateManyWithoutServiceNestedInput>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPetPortal?: Maybe<Scalars['Boolean']>;
  isPostcard?: Maybe<Scalars['Boolean']>;
  isReminderActive?: Maybe<Scalars['Boolean']>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceUpdateManyWithoutServiceNestedInput>;
  name?: Maybe<Scalars['String']>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceUpdateManyWithoutServiceNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  pimsName?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutServiceNestedInput>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reminderActivationDate?: Maybe<Scalars['DateTime']>;
  reminderTimings?: Maybe<ServiceReminderTimingUpdateManyWithoutServiceNestedInput>;
  reminders?: Maybe<ServiceReminderUpdateManyWithoutServiceNestedInput>;
  serviceCategory?: Maybe<ServiceCategoryUpdateOneWithoutChildServicesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagUpdateManyWithoutServiceNestedInput>;
};

export type ServiceUpsertWithWhereUniqueWithoutCanonicalServiceInput = {
  create: ServiceUncheckedCreateWithoutCanonicalServiceInput;
  update: ServiceUncheckedUpdateWithoutCanonicalServiceInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutFormTemplateInput = {
  create: ServiceUncheckedCreateWithoutFormTemplateInput;
  update: ServiceUncheckedUpdateWithoutFormTemplateInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: ServiceUncheckedCreateWithoutIntegrationInput;
  update: ServiceUncheckedUpdateWithoutIntegrationInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutServiceCategoryInput = {
  create: ServiceUncheckedCreateWithoutServiceCategoryInput;
  update: ServiceUncheckedUpdateWithoutServiceCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutVetsourceCodeTagsInput = {
  create: ServiceUncheckedCreateWithoutVetsourceCodeTagsInput;
  update: ServiceUncheckedUpdateWithoutVetsourceCodeTagsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutVetsourceRevenueCategoryInput = {
  create: ServiceUncheckedCreateWithoutVetsourceRevenueCategoryInput;
  update: ServiceUncheckedUpdateWithoutVetsourceRevenueCategoryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithoutDraftCareBenefitUsageToInvoiceLineItemsInput = {
  create: ServiceUncheckedCreateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
  update: ServiceUncheckedUpdateWithoutDraftCareBenefitUsageToInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutInvoiceLineItemsInput = {
  create: ServiceUncheckedCreateWithoutInvoiceLineItemsInput;
  update: ServiceUncheckedUpdateWithoutInvoiceLineItemsInput;
};

export type ServiceUpsertWithoutLoyaltyPointGrantingServicesInput = {
  create: ServiceUncheckedCreateWithoutLoyaltyPointGrantingServicesInput;
  update: ServiceUncheckedUpdateWithoutLoyaltyPointGrantingServicesInput;
};

export type ServiceUpsertWithoutOrganizationCareBenefitToClinicServiceInput = {
  create: ServiceUncheckedCreateWithoutOrganizationCareBenefitToClinicServiceInput;
  update: ServiceUncheckedUpdateWithoutOrganizationCareBenefitToClinicServiceInput;
};

export type ServiceUpsertWithoutPrescriptionsInput = {
  create: ServiceUncheckedCreateWithoutPrescriptionsInput;
  update: ServiceUncheckedUpdateWithoutPrescriptionsInput;
};

export type ServiceUpsertWithoutReminderTimingsInput = {
  create: ServiceUncheckedCreateWithoutReminderTimingsInput;
  update: ServiceUncheckedUpdateWithoutReminderTimingsInput;
};

export type ServiceUpsertWithoutRemindersInput = {
  create: ServiceUncheckedCreateWithoutRemindersInput;
  update: ServiceUncheckedUpdateWithoutRemindersInput;
};

export type ServiceWhereInput = {
  AND?: Maybe<Array<ServiceWhereInput>>;
  NOT?: Maybe<Array<ServiceWhereInput>>;
  OR?: Maybe<Array<ServiceWhereInput>>;
  canonicalService?: Maybe<CanonicalServiceWhereInput>;
  canonicalServiceId?: Maybe<StringNullableFilter>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  draftCareBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  formTemplate?: Maybe<FormTemplateWhereInput>;
  formTemplateId?: Maybe<StringNullableFilter>;
  hasConfiguredReminders?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  invoiceLineItems?: Maybe<InvoiceLineItemListRelationFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isPetPortal?: Maybe<BoolFilter>;
  isPostcard?: Maybe<BoolFilter>;
  isReminderActive?: Maybe<BoolFilter>;
  loyaltyPointGrantingServices?: Maybe<LoyaltyPointGrantingServiceListRelationFilter>;
  name?: Maybe<StringFilter>;
  organizationCareBenefitToClinicService?: Maybe<OrganizationCareBenefitToClinicServiceListRelationFilter>;
  pimsId?: Maybe<StringFilter>;
  pimsName?: Maybe<StringFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  reminderActivationDate?: Maybe<DateTimeNullableFilter>;
  reminderTimings?: Maybe<ServiceReminderTimingListRelationFilter>;
  reminders?: Maybe<ServiceReminderListRelationFilter>;
  serviceCategory?: Maybe<ServiceCategoryWhereInput>;
  serviceCategoryId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetsourceCodeTags?: Maybe<VetsourceCodeTagListRelationFilter>;
  vetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryWhereInput>;
  vetsourceRevenueCategoryName?: Maybe<StringNullableFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  serviceCodeAndIntegrationId?: Maybe<ServiceServiceCodeAndIntegrationIdCompoundUniqueInput>;
  servicePimsIdAndIntegrationId?: Maybe<ServiceServicePimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type Sex = {
  __typename?: 'Sex';
  _count: SexCountOutputType;
  abbreviation?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets: Array<ClinicPet>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<Species>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type SexPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};

export type SexCountAggregateOutputType = {
  __typename?: 'SexCountAggregateOutputType';
  _all: Scalars['Int'];
  abbreviation: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isDesexed: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  speciesId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SexCountOrderByAggregateInput = {
  abbreviation?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SexCountOutputType = {
  __typename?: 'SexCountOutputType';
  pets: Scalars['Int'];
};

export type SexCreateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateManyInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateManyIntegrationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateManyIntegrationInputEnvelope = {
  data: Array<SexCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SexCreateManySpeciesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateManySpeciesInputEnvelope = {
  data: Array<SexCreateManySpeciesInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SexCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
};

export type SexCreateNestedManyWithoutSpeciesInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
};

export type SexCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<SexWhereUniqueInput>;
  connectOrCreate?: Maybe<SexCreateOrConnectWithoutPetsInput>;
  create?: Maybe<SexUncheckedCreateWithoutPetsInput>;
};

export type SexCreateOrConnectWithoutIntegrationInput = {
  create: SexUncheckedCreateWithoutIntegrationInput;
  where: SexWhereUniqueInput;
};

export type SexCreateOrConnectWithoutPetsInput = {
  create: SexUncheckedCreateWithoutPetsInput;
  where: SexWhereUniqueInput;
};

export type SexCreateOrConnectWithoutSpeciesInput = {
  create: SexUncheckedCreateWithoutSpeciesInput;
  where: SexWhereUniqueInput;
};

export type SexCreateWithoutIntegrationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateWithoutPetsInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesCreateNestedOneWithoutSexesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexCreateWithoutSpeciesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSexesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type SexListRelationFilter = {
  every?: Maybe<SexWhereInput>;
  none?: Maybe<SexWhereInput>;
  some?: Maybe<SexWhereInput>;
};

export type SexMaxAggregateOutputType = {
  __typename?: 'SexMaxAggregateOutputType';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexMaxOrderByAggregateInput = {
  abbreviation?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SexMinAggregateOutputType = {
  __typename?: 'SexMinAggregateOutputType';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexMinOrderByAggregateInput = {
  abbreviation?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SexOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SexOrderByRelevanceFieldEnum {
  Abbreviation = 'abbreviation',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId',
  SpeciesId = 'speciesId'
}

export type SexOrderByRelevanceInput = {
  fields: Array<SexOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SexOrderByWithAggregationInput = {
  _count?: Maybe<SexCountOrderByAggregateInput>;
  _max?: Maybe<SexMaxOrderByAggregateInput>;
  _min?: Maybe<SexMinOrderByAggregateInput>;
  abbreviation?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SexOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SexOrderByRelevanceInput>;
  abbreviation?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isDesexed?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  species?: Maybe<SpeciesOrderByWithRelationAndSearchRelevanceInput>;
  speciesId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SexRelationFilter = {
  is?: Maybe<SexWhereInput>;
  isNot?: Maybe<SexWhereInput>;
};

export enum SexScalarFieldEnum {
  Abbreviation = 'abbreviation',
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  IsDesexed = 'isDesexed',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  SpeciesId = 'speciesId',
  UpdatedAt = 'updatedAt'
}

export type SexScalarWhereInput = {
  AND?: Maybe<Array<SexScalarWhereInput>>;
  NOT?: Maybe<Array<SexScalarWhereInput>>;
  OR?: Maybe<Array<SexScalarWhereInput>>;
  abbreviation?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isDesexed?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  speciesId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SexScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SexScalarWhereWithAggregatesInput>>;
  abbreviation?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isDesexed?: Maybe<BoolNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  speciesId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SexUncheckedCreateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
};

export type SexUncheckedCreateNestedManyWithoutSpeciesInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
};

export type SexUncheckedCreateWithoutIntegrationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedCreateWithoutPetsInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedCreateWithoutSpeciesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSexModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateManyInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type SexUncheckedUpdateManyWithoutSexesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateManyWithoutSpeciesNestedInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutSpeciesInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutSpeciesInput>>;
};

export type SexUncheckedUpdateWithoutIntegrationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateWithoutPetsInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  speciesId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUncheckedUpdateWithoutSpeciesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpdateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpdateManyMutationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpdateManyWithWhereWithoutIntegrationInput = {
  data: SexUncheckedUpdateManyWithoutSexesInput;
  where: SexScalarWhereInput;
};

export type SexUpdateManyWithWhereWithoutSpeciesInput = {
  data: SexUncheckedUpdateManyWithoutSexesInput;
  where: SexScalarWhereInput;
};

export type SexUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SexCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SexCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type SexUpdateManyWithoutSpeciesNestedInput = {
  connect?: Maybe<Array<SexWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SexCreateOrConnectWithoutSpeciesInput>>;
  create?: Maybe<Array<SexCreateWithoutSpeciesInput>>;
  createMany?: Maybe<SexCreateManySpeciesInputEnvelope>;
  delete?: Maybe<Array<SexWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SexScalarWhereInput>>;
  disconnect?: Maybe<Array<SexWhereUniqueInput>>;
  set?: Maybe<Array<SexWhereUniqueInput>>;
  update?: Maybe<Array<SexUpdateWithWhereUniqueWithoutSpeciesInput>>;
  updateMany?: Maybe<Array<SexUpdateManyWithWhereWithoutSpeciesInput>>;
  upsert?: Maybe<Array<SexUpsertWithWhereUniqueWithoutSpeciesInput>>;
};

export type SexUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<SexWhereUniqueInput>;
  connectOrCreate?: Maybe<SexCreateOrConnectWithoutPetsInput>;
  create?: Maybe<SexUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SexUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<SexUpsertWithoutPetsInput>;
};

export type SexUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: SexUncheckedUpdateWithoutIntegrationInput;
  where: SexWhereUniqueInput;
};

export type SexUpdateWithWhereUniqueWithoutSpeciesInput = {
  data: SexUncheckedUpdateWithoutSpeciesInput;
  where: SexWhereUniqueInput;
};

export type SexUpdateWithoutIntegrationInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpdateWithoutPetsInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  species?: Maybe<SpeciesUpdateOneWithoutSexesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpdateWithoutSpeciesInput = {
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSexesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDesexed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSexModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SexUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: SexUncheckedCreateWithoutIntegrationInput;
  update: SexUncheckedUpdateWithoutIntegrationInput;
  where: SexWhereUniqueInput;
};

export type SexUpsertWithWhereUniqueWithoutSpeciesInput = {
  create: SexUncheckedCreateWithoutSpeciesInput;
  update: SexUncheckedUpdateWithoutSpeciesInput;
  where: SexWhereUniqueInput;
};

export type SexUpsertWithoutPetsInput = {
  create: SexUncheckedCreateWithoutPetsInput;
  update: SexUncheckedUpdateWithoutPetsInput;
};

export type SexWhereInput = {
  AND?: Maybe<Array<SexWhereInput>>;
  NOT?: Maybe<Array<SexWhereInput>>;
  OR?: Maybe<Array<SexWhereInput>>;
  abbreviation?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isDesexed?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  species?: Maybe<SpeciesWhereInput>;
  speciesId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SexWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<SexIntegrationIdPimsIdCompoundUniqueInput>;
};

export enum SmsEntityType {
  AutomationRun = 'AutomationRun',
  ChannelMessage = 'ChannelMessage',
  MassTextAlertEntry = 'MassTextAlertEntry',
  ServiceReminderNotification = 'ServiceReminderNotification'
}

export enum SmsNotificationDeliveryErrorType {
  CannotReceiveMessageType = 'CannotReceiveMessageType',
  ConnectionError = 'ConnectionError',
  ErrorSendingMessageToProvider = 'ErrorSendingMessageToProvider',
  InvalidContactInfo = 'InvalidContactInfo',
  MessageFilteredByCarrier = 'MessageFilteredByCarrier',
  NotificationRefused = 'NotificationRefused',
  Unknown = 'Unknown'
}

export enum SmsNotificationProvider {
  Bandwidth = 'Bandwidth',
  Twilio = 'Twilio'
}

export type SmsNotificationStatus = {
  __typename?: 'SmsNotificationStatus';
  clinicPhoneNumber?: Maybe<ClinicPhoneNumber>;
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  /** Our categorization of the error */
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id: Scalars['String'];
  /** who is delivering our message (e.g. Twilio) */
  provider: SmsNotificationProvider;
  /** the error message given by the provider */
  providerErrorData?: Maybe<Scalars['String']>;
  /** the unique id the deliverer gives us to track the message (e.g. message_sId) */
  providerMessageId: Scalars['String'];
  /** (e.g. the phone number or email of the message) */
  receivingNumber: Scalars['String'];
  sentChannelMessage?: Maybe<ChannelMessage>;
  /** optionally included: the channel associated with this notification */
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId: Scalars['String'];
  /** the kind of entity the sourceContactEntityId references */
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt: Scalars['DateTime'];
};

export type SmsNotificationStatusCountAggregateOutputType = {
  __typename?: 'SmsNotificationStatusCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPhoneNumberId: Scalars['Int'];
  createdAt: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: Scalars['Int'];
  errorType: Scalars['Int'];
  id: Scalars['Int'];
  provider: Scalars['Int'];
  providerErrorData: Scalars['Int'];
  providerMessageId: Scalars['Int'];
  receivingNumber: Scalars['Int'];
  sentChannelMessageId: Scalars['Int'];
  sourceContactEntityId: Scalars['Int'];
  sourceContactEntityType: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SmsNotificationStatusCountOrderByAggregateInput = {
  clinicPhoneNumberId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SmsNotificationStatusCreateInput = {
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManyClinicPhoneNumberInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateManyInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusCreateManySentChannelMessageInputEnvelope = {
  data: Array<SmsNotificationStatusCreateManySentChannelMessageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SmsNotificationStatusCreateNestedManyWithoutClinicPhoneNumberInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
};

export type SmsNotificationStatusCreateNestedManyWithoutSentChannelMessageInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
};

export type SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput = {
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput = {
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusCreateWithoutClinicPhoneNumberInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessage?: Maybe<ChannelMessageCreateNestedOneWithoutSmsNotifificationStatusesInput>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusCreateWithoutSentChannelMessageInput = {
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberCreateNestedOneWithoutSmsNotificationStatusesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusListRelationFilter = {
  every?: Maybe<SmsNotificationStatusWhereInput>;
  none?: Maybe<SmsNotificationStatusWhereInput>;
  some?: Maybe<SmsNotificationStatusWhereInput>;
};

export type SmsNotificationStatusMaxAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMaxAggregateOutputType';
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusMaxOrderByAggregateInput = {
  clinicPhoneNumberId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SmsNotificationStatusMinAggregateOutputType = {
  __typename?: 'SmsNotificationStatusMinAggregateOutputType';
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusMinOrderByAggregateInput = {
  clinicPhoneNumberId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SmsNotificationStatusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SmsNotificationStatusOrderByRelevanceFieldEnum {
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  EntityId = 'entityId',
  Id = 'id',
  ProviderErrorData = 'providerErrorData',
  ProviderMessageId = 'providerMessageId',
  ReceivingNumber = 'receivingNumber',
  SentChannelMessageId = 'sentChannelMessageId',
  SourceContactEntityId = 'sourceContactEntityId'
}

export type SmsNotificationStatusOrderByRelevanceInput = {
  fields: Array<SmsNotificationStatusOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SmsNotificationStatusOrderByWithAggregationInput = {
  _count?: Maybe<SmsNotificationStatusCountOrderByAggregateInput>;
  _max?: Maybe<SmsNotificationStatusMaxOrderByAggregateInput>;
  _min?: Maybe<SmsNotificationStatusMinOrderByAggregateInput>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sentChannelMessageId?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SmsNotificationStatusOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SmsNotificationStatusOrderByRelevanceInput>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  clinicPhoneNumberId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  errorType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  providerErrorData?: Maybe<SortOrder>;
  providerMessageId?: Maybe<SortOrder>;
  receivingNumber?: Maybe<SortOrder>;
  sentChannelMessage?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  sentChannelMessageId?: Maybe<SortOrder>;
  sourceContactEntityId?: Maybe<SortOrder>;
  sourceContactEntityType?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SmsNotificationStatusScalarFieldEnum {
  ClinicPhoneNumberId = 'clinicPhoneNumberId',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  ErrorType = 'errorType',
  Id = 'id',
  Provider = 'provider',
  ProviderErrorData = 'providerErrorData',
  ProviderMessageId = 'providerMessageId',
  ReceivingNumber = 'receivingNumber',
  SentChannelMessageId = 'sentChannelMessageId',
  SourceContactEntityId = 'sourceContactEntityId',
  SourceContactEntityType = 'sourceContactEntityType',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SmsNotificationStatusScalarWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  providerMessageId?: Maybe<StringFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SmsNotificationStatusScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SmsNotificationStatusScalarWhereWithAggregatesInput>>;
  clinicPhoneNumberId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  entityId?: Maybe<StringNullableWithAggregatesFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableWithAggregatesFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  provider?: Maybe<EnumSmsNotificationProviderWithAggregatesFilter>;
  providerErrorData?: Maybe<StringNullableWithAggregatesFilter>;
  providerMessageId?: Maybe<StringWithAggregatesFilter>;
  receivingNumber?: Maybe<StringWithAggregatesFilter>;
  sentChannelMessageId?: Maybe<StringNullableWithAggregatesFilter>;
  sourceContactEntityId?: Maybe<StringWithAggregatesFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeWithAggregatesFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SmsNotificationStatusSubscription = {
  __typename?: 'SmsNotificationStatusSubscription';
  mutation: MutationType;
  node: SmsNotificationStatus;
};

export enum SmsNotificationStatusType {
  Delivered = 'Delivered',
  Error = 'Error',
  SendError = 'Send_Error',
  Sent = 'Sent'
}

export type SmsNotificationStatusUncheckedCreateInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutClinicPhoneNumberInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
};

export type SmsNotificationStatusUncheckedCreateNestedManyWithoutSentChannelMessageInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
};

export type SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider: SmsNotificationProvider;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId: Scalars['String'];
  receivingNumber: Scalars['String'];
  sourceContactEntityId: Scalars['String'];
  sourceContactEntityType: ContactEntityType;
  status: SmsNotificationStatusType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateManyInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutClinicPhoneNumberNestedInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSentChannelMessageNestedInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessageId?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput = {
  clinicPhoneNumberId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUpdateInput = {
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput = {
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotificationStatusesInput;
  where: SmsNotificationStatusScalarWhereInput;
};

export type SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput = {
  data: SmsNotificationStatusUncheckedUpdateManyWithoutSmsNotifificationStatusesInput;
  where: SmsNotificationStatusScalarWhereInput;
};

export type SmsNotificationStatusUpdateManyWithoutClinicPhoneNumberNestedInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutClinicPhoneNumberInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutClinicPhoneNumberInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManyClinicPhoneNumberInputEnvelope>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutClinicPhoneNumberInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput>>;
};

export type SmsNotificationStatusUpdateManyWithoutSentChannelMessageNestedInput = {
  connect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SmsNotificationStatusCreateOrConnectWithoutSentChannelMessageInput>>;
  create?: Maybe<Array<SmsNotificationStatusCreateWithoutSentChannelMessageInput>>;
  createMany?: Maybe<SmsNotificationStatusCreateManySentChannelMessageInputEnvelope>;
  delete?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SmsNotificationStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  set?: Maybe<Array<SmsNotificationStatusWhereUniqueInput>>;
  update?: Maybe<Array<SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput>>;
  updateMany?: Maybe<Array<SmsNotificationStatusUpdateManyWithWhereWithoutSentChannelMessageInput>>;
  upsert?: Maybe<Array<SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput>>;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutClinicPhoneNumberInput = {
  data: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusUpdateWithWhereUniqueWithoutSentChannelMessageInput = {
  data: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusUpdateWithoutClinicPhoneNumberInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sentChannelMessage?: Maybe<ChannelMessageUpdateOneWithoutSmsNotifificationStatusesNestedInput>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUpdateWithoutSentChannelMessageInput = {
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberUpdateOneWithoutSmsNotificationStatusesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<SmsEntityType>;
  errorType?: Maybe<SmsNotificationDeliveryErrorType>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsNotificationProvider>;
  providerErrorData?: Maybe<Scalars['String']>;
  providerMessageId?: Maybe<Scalars['String']>;
  receivingNumber?: Maybe<Scalars['String']>;
  sourceContactEntityId?: Maybe<Scalars['String']>;
  sourceContactEntityType?: Maybe<ContactEntityType>;
  status?: Maybe<SmsNotificationStatusType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutClinicPhoneNumberInput = {
  create: SmsNotificationStatusUncheckedCreateWithoutClinicPhoneNumberInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutClinicPhoneNumberInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusUpsertWithWhereUniqueWithoutSentChannelMessageInput = {
  create: SmsNotificationStatusUncheckedCreateWithoutSentChannelMessageInput;
  update: SmsNotificationStatusUncheckedUpdateWithoutSentChannelMessageInput;
  where: SmsNotificationStatusWhereUniqueInput;
};

export type SmsNotificationStatusWhereInput = {
  AND?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  NOT?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  OR?: Maybe<Array<SmsNotificationStatusWhereInput>>;
  clinicPhoneNumber?: Maybe<ClinicPhoneNumberWhereInput>;
  clinicPhoneNumberId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringNullableFilter>;
  entityType?: Maybe<EnumSmsEntityTypeNullableFilter>;
  errorType?: Maybe<EnumSmsNotificationDeliveryErrorTypeNullableFilter>;
  id?: Maybe<StringFilter>;
  provider?: Maybe<EnumSmsNotificationProviderFilter>;
  providerErrorData?: Maybe<StringNullableFilter>;
  providerMessageId?: Maybe<StringFilter>;
  receivingNumber?: Maybe<StringFilter>;
  sentChannelMessage?: Maybe<ChannelMessageWhereInput>;
  sentChannelMessageId?: Maybe<StringNullableFilter>;
  sourceContactEntityId?: Maybe<StringFilter>;
  sourceContactEntityType?: Maybe<EnumContactEntityTypeFilter>;
  status?: Maybe<EnumSmsNotificationStatusTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SmsNotificationStatusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Species = {
  __typename?: 'Species';
  _count: SpeciesCountOutputType;
  breeds: Array<Breed>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption: DirectBookingSpeciesOption;
  id: Scalars['String'];
  integration: ClinicPimsIntegration;
  integrationId: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  pets: Array<ClinicPet>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes: Array<Sex>;
  updatedAt: Scalars['DateTime'];
};


export type SpeciesBreedsArgs = {
  cursor?: Maybe<BreedWhereUniqueInput>;
  distinct?: Maybe<BreedScalarFieldEnum>;
  orderBy?: Maybe<BreedOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BreedWhereInput>;
};


export type SpeciesPetsArgs = {
  cursor?: Maybe<ClinicPetWhereUniqueInput>;
  distinct?: Maybe<ClinicPetScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetWhereInput>;
};


export type SpeciesSexesArgs = {
  cursor?: Maybe<SexWhereUniqueInput>;
  distinct?: Maybe<SexScalarFieldEnum>;
  orderBy?: Maybe<SexOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SexWhereInput>;
};

export type SpeciesCountAggregateOutputType = {
  __typename?: 'SpeciesCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  directBookingSpeciesOption: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  name: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SpeciesCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpeciesCountOutputType = {
  __typename?: 'SpeciesCountOutputType';
  breeds: Scalars['Int'];
  pets: Scalars['Int'];
  sexes: Scalars['Int'];
};

export type SpeciesCreateInput = {
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateManyIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateManyIntegrationInputEnvelope = {
  data: Array<SpeciesCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SpeciesCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
};

export type SpeciesCreateNestedOneWithoutBreedsInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutBreedsInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutBreedsInput>;
};

export type SpeciesCreateNestedOneWithoutPetsInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutPetsInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutPetsInput>;
};

export type SpeciesCreateNestedOneWithoutSexesInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutSexesInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutSexesInput>;
};

export type SpeciesCreateOrConnectWithoutBreedsInput = {
  create: SpeciesUncheckedCreateWithoutBreedsInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesCreateOrConnectWithoutIntegrationInput = {
  create: SpeciesUncheckedCreateWithoutIntegrationInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesCreateOrConnectWithoutPetsInput = {
  create: SpeciesUncheckedCreateWithoutPetsInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesCreateOrConnectWithoutSexesInput = {
  create: SpeciesUncheckedCreateWithoutSexesInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesCreateWithoutBreedsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateWithoutIntegrationInput = {
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateWithoutPetsInput = {
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesCreateWithoutSexesInput = {
  breeds?: Maybe<BreedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration: ClinicPimsIntegrationCreateNestedOneWithoutSpeciesInput;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesIntegrationIdPimsIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type SpeciesListRelationFilter = {
  every?: Maybe<SpeciesWhereInput>;
  none?: Maybe<SpeciesWhereInput>;
  some?: Maybe<SpeciesWhereInput>;
};

export type SpeciesMaxAggregateOutputType = {
  __typename?: 'SpeciesMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpeciesMinAggregateOutputType = {
  __typename?: 'SpeciesMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpeciesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SpeciesOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  Name = 'name',
  PimsId = 'pimsId'
}

export type SpeciesOrderByRelevanceInput = {
  fields: Array<SpeciesOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SpeciesOrderByWithAggregationInput = {
  _count?: Maybe<SpeciesCountOrderByAggregateInput>;
  _max?: Maybe<SpeciesMaxOrderByAggregateInput>;
  _min?: Maybe<SpeciesMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpeciesOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SpeciesOrderByRelevanceInput>;
  breeds?: Maybe<BreedOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  directBookingSpeciesOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pets?: Maybe<ClinicPetOrderByRelationAggregateInput>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  sexes?: Maybe<SexOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpeciesRelationFilter = {
  is?: Maybe<SpeciesWhereInput>;
  isNot?: Maybe<SpeciesWhereInput>;
};

export enum SpeciesScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  DirectBookingSpeciesOption = 'directBookingSpeciesOption',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Name = 'name',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  UpdatedAt = 'updatedAt'
}

export type SpeciesScalarWhereInput = {
  AND?: Maybe<Array<SpeciesScalarWhereInput>>;
  NOT?: Maybe<Array<SpeciesScalarWhereInput>>;
  OR?: Maybe<Array<SpeciesScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SpeciesScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SpeciesScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum SpeciesType {
  Avian = 'Avian',
  Bovine = 'Bovine',
  Canine = 'Canine',
  Equine = 'Equine',
  Feline = 'Feline',
  Leporine = 'Leporine',
  Other = 'Other'
}

export type SpeciesUncheckedCreateInput = {
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
};

export type SpeciesUncheckedCreateWithoutBreedsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedCreateWithoutIntegrationInput = {
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedCreateWithoutPetsInput = {
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedCreateNestedManyWithoutSpeciesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedCreateWithoutSexesInput = {
  breeds?: Maybe<BreedUncheckedCreateNestedManyWithoutSpeciesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pets?: Maybe<ClinicPetUncheckedCreateNestedManyWithoutSpeciesModelInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateInput = {
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<SpeciesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SpeciesScalarWhereInput>>;
  disconnect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  set?: Maybe<Array<SpeciesWhereUniqueInput>>;
  update?: Maybe<Array<SpeciesUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SpeciesUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SpeciesUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type SpeciesUncheckedUpdateManyWithoutSpeciesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateWithoutBreedsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateWithoutIntegrationInput = {
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateWithoutPetsInput = {
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUncheckedUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUncheckedUpdateWithoutSexesInput = {
  breeds?: Maybe<BreedUncheckedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUncheckedUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateInput = {
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateManyWithWhereWithoutIntegrationInput = {
  data: SpeciesUncheckedUpdateManyWithoutSpeciesInput;
  where: SpeciesScalarWhereInput;
};

export type SpeciesUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SpeciesCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<SpeciesCreateWithoutIntegrationInput>>;
  createMany?: Maybe<SpeciesCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<SpeciesWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SpeciesScalarWhereInput>>;
  disconnect?: Maybe<Array<SpeciesWhereUniqueInput>>;
  set?: Maybe<Array<SpeciesWhereUniqueInput>>;
  update?: Maybe<Array<SpeciesUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<SpeciesUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<SpeciesUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type SpeciesUpdateOneWithoutBreedsNestedInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutBreedsInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutBreedsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutBreedsInput>;
  upsert?: Maybe<SpeciesUpsertWithoutBreedsInput>;
};

export type SpeciesUpdateOneWithoutPetsNestedInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutPetsInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutPetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutPetsInput>;
  upsert?: Maybe<SpeciesUpsertWithoutPetsInput>;
};

export type SpeciesUpdateOneWithoutSexesNestedInput = {
  connect?: Maybe<SpeciesWhereUniqueInput>;
  connectOrCreate?: Maybe<SpeciesCreateOrConnectWithoutSexesInput>;
  create?: Maybe<SpeciesUncheckedCreateWithoutSexesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SpeciesUncheckedUpdateWithoutSexesInput>;
  upsert?: Maybe<SpeciesUpsertWithoutSexesInput>;
};

export type SpeciesUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: SpeciesUncheckedUpdateWithoutIntegrationInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesUpdateWithoutBreedsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateWithoutIntegrationInput = {
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateWithoutPetsInput = {
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  sexes?: Maybe<SexUpdateManyWithoutSpeciesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpdateWithoutSexesInput = {
  breeds?: Maybe<BreedUpdateManyWithoutSpeciesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  directBookingSpeciesOption?: Maybe<DirectBookingSpeciesOption>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneRequiredWithoutSpeciesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pets?: Maybe<ClinicPetUpdateManyWithoutSpeciesModelNestedInput>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpeciesUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: SpeciesUncheckedCreateWithoutIntegrationInput;
  update: SpeciesUncheckedUpdateWithoutIntegrationInput;
  where: SpeciesWhereUniqueInput;
};

export type SpeciesUpsertWithoutBreedsInput = {
  create: SpeciesUncheckedCreateWithoutBreedsInput;
  update: SpeciesUncheckedUpdateWithoutBreedsInput;
};

export type SpeciesUpsertWithoutPetsInput = {
  create: SpeciesUncheckedCreateWithoutPetsInput;
  update: SpeciesUncheckedUpdateWithoutPetsInput;
};

export type SpeciesUpsertWithoutSexesInput = {
  create: SpeciesUncheckedCreateWithoutSexesInput;
  update: SpeciesUncheckedUpdateWithoutSexesInput;
};

export type SpeciesWhereInput = {
  AND?: Maybe<Array<SpeciesWhereInput>>;
  NOT?: Maybe<Array<SpeciesWhereInput>>;
  OR?: Maybe<Array<SpeciesWhereInput>>;
  breeds?: Maybe<BreedListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  directBookingSpeciesOption?: Maybe<EnumDirectBookingSpeciesOptionFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  pets?: Maybe<ClinicPetListRelationFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  sexes?: Maybe<SexListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SpeciesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  integrationId_pimsId?: Maybe<SpeciesIntegrationIdPimsIdCompoundUniqueInput>;
};

export enum SqsQueueNames {
  ConditionEngineJobs = 'ConditionEngineJobs',
  CoreQueue = 'CoreQueue',
  GenericModelChangeQueue = 'GenericModelChangeQueue',
  InformCampaignJobs = 'InformCampaignJobs',
  InformJobs = 'InformJobs',
  InformPartnerIntegrationRequestJobs = 'InformPartnerIntegrationRequestJobs',
  LoyaltyProgram = 'LoyaltyProgram',
  MediaJobs = 'MediaJobs',
  NotificationJobs = 'NotificationJobs',
  PimsEtlIntegrationSync = 'PimsEtlIntegrationSync',
  PimsEtlModelChanges = 'PimsEtlModelChanges',
  PimsEtlModelDataSync = 'PimsEtlModelDataSync',
  PimsEtlOnboardingIntegrationSync = 'PimsEtlOnboardingIntegrationSync',
  PimsEtlOnboardingModelChanges = 'PimsEtlOnboardingModelChanges',
  PimsEtlOnboardingModelDataSync = 'PimsEtlOnboardingModelDataSync',
  PimsEtlOnboardingRetryModelChanges = 'PimsEtlOnboardingRetryModelChanges',
  PimsEtlRetryModelChanges = 'PimsEtlRetryModelChanges',
  PimsSyncJobs = 'PimsSyncJobs',
  PimsWriteback = 'PimsWriteback',
  RealTimeGenericModelChangeQueue = 'RealTimeGenericModelChangeQueue',
  SearchIndexing = 'SearchIndexing',
  SearchIndexingPimsCreate = 'SearchIndexingPimsCreate'
}

export type StaffRole = {
  __typename?: 'StaffRole';
  _count: StaffRoleCountOutputType;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  permissions: Array<Permission>;
  role: Role;
  updatedAt: Scalars['DateTime'];
  users: Array<VetInfo>;
};


export type StaffRolePermissionsArgs = {
  cursor?: Maybe<PermissionWhereUniqueInput>;
  distinct?: Maybe<PermissionScalarFieldEnum>;
  orderBy?: Maybe<PermissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PermissionWhereInput>;
};


export type StaffRoleUsersArgs = {
  cursor?: Maybe<VetInfoWhereUniqueInput>;
  distinct?: Maybe<VetInfoScalarFieldEnum>;
  orderBy?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetInfoWhereInput>;
};

export type StaffRoleCountAggregateOutputType = {
  __typename?: 'StaffRoleCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  role: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StaffRoleCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleCountOutputType = {
  __typename?: 'StaffRoleCountOutputType';
  permissions: Scalars['Int'];
  users: Scalars['Int'];
};

export type StaffRoleCreateInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateManyClinicInputEnvelope = {
  data: Array<StaffRoleCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StaffRoleCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
};

export type StaffRoleCreateNestedManyWithoutPermissionsInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
};

export type StaffRoleCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
};

export type StaffRoleCreateOrConnectWithoutClinicInput = {
  create: StaffRoleUncheckedCreateWithoutClinicInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleCreateOrConnectWithoutPermissionsInput = {
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleCreateOrConnectWithoutUsersInput = {
  create: StaffRoleUncheckedCreateWithoutUsersInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutPermissionsInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleCreateWithoutUsersInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutRolesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleListRelationFilter = {
  every?: Maybe<StaffRoleWhereInput>;
  none?: Maybe<StaffRoleWhereInput>;
  some?: Maybe<StaffRoleWhereInput>;
};

export type StaffRoleMaxAggregateOutputType = {
  __typename?: 'StaffRoleMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleMinAggregateOutputType = {
  __typename?: 'StaffRoleMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StaffRoleOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id'
}

export type StaffRoleOrderByRelevanceInput = {
  fields: Array<StaffRoleOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StaffRoleOrderByWithAggregationInput = {
  _count?: Maybe<StaffRoleCountOrderByAggregateInput>;
  _max?: Maybe<StaffRoleMaxOrderByAggregateInput>;
  _min?: Maybe<StaffRoleMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StaffRoleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StaffRoleOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  permissions?: Maybe<PermissionOrderByRelationAggregateInput>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  users?: Maybe<VetInfoOrderByRelationAggregateInput>;
};

export enum StaffRoleScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export type StaffRoleScalarWhereInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  role?: Maybe<EnumRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StaffRoleScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StaffRoleScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  role?: Maybe<EnumRoleWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StaffRoleUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutPermissionsInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
};

export type StaffRoleUncheckedCreateNestedManyWithoutUsersInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
};

export type StaffRoleUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutPermissionsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedCreateNestedManyWithoutRolesInput>;
};

export type StaffRoleUncheckedCreateWithoutUsersInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedCreateNestedManyWithoutRolesInput>;
  role: Role;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutPermissionsNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
};

export type StaffRoleUncheckedUpdateManyWithoutRolesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUncheckedUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type StaffRoleUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateWithoutPermissionsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUncheckedUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUncheckedUpdateWithoutUsersInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUncheckedUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUpdateManyWithWhereWithoutClinicInput = {
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
  where: StaffRoleScalarWhereInput;
};

export type StaffRoleUpdateManyWithWhereWithoutPermissionsInput = {
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
  where: StaffRoleScalarWhereInput;
};

export type StaffRoleUpdateManyWithWhereWithoutUsersInput = {
  data: StaffRoleUncheckedUpdateManyWithoutRolesInput;
  where: StaffRoleScalarWhereInput;
};

export type StaffRoleUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutClinicInput>>;
  createMany?: Maybe<StaffRoleCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StaffRoleUpdateManyWithoutPermissionsNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutPermissionsInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutPermissionsInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutPermissionsInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput>>;
};

export type StaffRoleUpdateManyWithoutUsersNestedInput = {
  connect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StaffRoleCreateOrConnectWithoutUsersInput>>;
  create?: Maybe<Array<StaffRoleCreateWithoutUsersInput>>;
  delete?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StaffRoleScalarWhereInput>>;
  disconnect?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  set?: Maybe<Array<StaffRoleWhereUniqueInput>>;
  update?: Maybe<Array<StaffRoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<StaffRoleUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: Maybe<Array<StaffRoleUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type StaffRoleUpdateWithWhereUniqueWithoutClinicInput = {
  data: StaffRoleUncheckedUpdateWithoutClinicInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutPermissionsInput = {
  data: StaffRoleUncheckedUpdateWithoutPermissionsInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleUpdateWithWhereUniqueWithoutUsersInput = {
  data: StaffRoleUncheckedUpdateWithoutUsersInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateWithoutPermissionsInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<VetInfoUpdateManyWithoutRolesNestedInput>;
};

export type StaffRoleUpdateWithoutUsersInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutRolesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionUpdateManyWithoutRolesNestedInput>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffRoleUpsertWithWhereUniqueWithoutClinicInput = {
  create: StaffRoleUncheckedCreateWithoutClinicInput;
  update: StaffRoleUncheckedUpdateWithoutClinicInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutPermissionsInput = {
  create: StaffRoleUncheckedCreateWithoutPermissionsInput;
  update: StaffRoleUncheckedUpdateWithoutPermissionsInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleUpsertWithWhereUniqueWithoutUsersInput = {
  create: StaffRoleUncheckedCreateWithoutUsersInput;
  update: StaffRoleUncheckedUpdateWithoutUsersInput;
  where: StaffRoleWhereUniqueInput;
};

export type StaffRoleWhereInput = {
  AND?: Maybe<Array<StaffRoleWhereInput>>;
  NOT?: Maybe<Array<StaffRoleWhereInput>>;
  OR?: Maybe<Array<StaffRoleWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  permissions?: Maybe<PermissionListRelationFilter>;
  role?: Maybe<EnumRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  users?: Maybe<VetInfoListRelationFilter>;
};

export type StaffRoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  capabilities: StripeCapabilities;
  chargesEnabled: Scalars['Boolean'];
  defaultAccount?: Maybe<StripePayoutAccount>;
  id: Scalars['String'];
  payoutAccounts?: Maybe<Array<StripePayoutAccount>>;
  payoutSchedule: StripePayoutSchedule;
  payoutsEnabled: Scalars['Boolean'];
  requirements: StripeRequirements;
};

export type StripeAccountOutput = {
  __typename?: 'StripeAccountOutput';
  account: StripeAccount;
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_payments?: Maybe<StripeCapabilityState>;
  transfers: StripeCapabilityState;
};

export enum StripeCapabilityState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum StripeCaptureType {
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum StripeCollectionMethod {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice'
}

export type StripeConnectOauthInput = {
  oauthCode: Scalars['String'];
};

export type StripeCreateAccountInput = {
  redirectUrl: Scalars['String'];
};

export type StripeCreateAccountOutput = {
  __typename?: 'StripeCreateAccountOutput';
  account: StripeAccount;
  onboarding: LoginLinkOutput;
};

export type StripeCreatePaymentInput = {
  amount: Scalars['Int'];
  captureType?: Maybe<StripeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptorSuffix?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<StripePaymentMethodType>;
  receiptEmail?: Maybe<Scalars['String']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  _count: StripeCustomerCountOutputType;
  clinicPetParents: Array<ClinicPetParent>;
  id: Scalars['String'];
  invoices: Array<StripeInvoice>;
  organizationPetParents: Array<OrganizationPetParent>;
  paymentMethods: Array<StripePaymentMethod>;
  stripeId: Scalars['String'];
};


export type StripeCustomerClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type StripeCustomerInvoicesArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type StripeCustomerOrganizationPetParentsArgs = {
  cursor?: Maybe<OrganizationPetParentWhereUniqueInput>;
  distinct?: Maybe<OrganizationPetParentScalarFieldEnum>;
  orderBy?: Maybe<OrganizationPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationPetParentWhereInput>;
};


export type StripeCustomerPaymentMethodsArgs = {
  cursor?: Maybe<StripePaymentMethodWhereUniqueInput>;
  distinct?: Maybe<StripePaymentMethodScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentMethodWhereInput>;
};

export type StripeCustomerCountAggregateOutputType = {
  __typename?: 'StripeCustomerCountAggregateOutputType';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  stripeId: Scalars['Int'];
};

export type StripeCustomerCountOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerCountOutputType = {
  __typename?: 'StripeCustomerCountOutputType';
  clinicPetParents: Scalars['Int'];
  invoices: Scalars['Int'];
  organizationPetParents: Scalars['Int'];
  paymentMethods: Scalars['Int'];
};

export type StripeCustomerCreateInput = {
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateNestedOneWithoutClinicPetParentsInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
};

export type StripeCustomerCreateNestedOneWithoutInvoicesInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
};

export type StripeCustomerCreateNestedOneWithoutOrganizationPetParentsInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
};

export type StripeCustomerCreateNestedOneWithoutPaymentMethodsInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
};

export type StripeCustomerCreateOrConnectWithoutClinicPetParentsInput = {
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
  where: StripeCustomerWhereUniqueInput;
};

export type StripeCustomerCreateOrConnectWithoutInvoicesInput = {
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
  where: StripeCustomerWhereUniqueInput;
};

export type StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput = {
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
  where: StripeCustomerWhereUniqueInput;
};

export type StripeCustomerCreateOrConnectWithoutPaymentMethodsInput = {
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
  where: StripeCustomerWhereUniqueInput;
};

export type StripeCustomerCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateWithoutInvoicesInput = {
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateWithoutOrganizationPetParentsInput = {
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerCreateWithoutPaymentMethodsInput = {
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerMaxAggregateOutputType = {
  __typename?: 'StripeCustomerMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMaxOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerMinAggregateOutputType = {
  __typename?: 'StripeCustomerMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerMinOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export enum StripeCustomerOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeId = 'stripeId'
}

export type StripeCustomerOrderByRelevanceInput = {
  fields: Array<StripeCustomerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeCustomerOrderByWithAggregationInput = {
  _count?: Maybe<StripeCustomerCountOrderByAggregateInput>;
  _max?: Maybe<StripeCustomerMaxOrderByAggregateInput>;
  _min?: Maybe<StripeCustomerMinOrderByAggregateInput>;
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeCustomerOrderByRelevanceInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  invoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  organizationPetParents?: Maybe<OrganizationPetParentOrderByRelationAggregateInput>;
  paymentMethods?: Maybe<StripePaymentMethodOrderByRelationAggregateInput>;
  stripeId?: Maybe<SortOrder>;
};

export type StripeCustomerPortalInput = {
  returnUrl?: Maybe<Scalars['String']>;
};

export type StripeCustomerPortalOutput = {
  __typename?: 'StripeCustomerPortalOutput';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type StripeCustomerPortalWhereUniqueInput = {
  customerId: Scalars['String'];
};

export type StripeCustomerRelationFilter = {
  is?: Maybe<StripeCustomerWhereInput>;
  isNot?: Maybe<StripeCustomerWhereInput>;
};

export enum StripeCustomerScalarFieldEnum {
  Id = 'id',
  StripeId = 'stripeId'
}

export type StripeCustomerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeCustomerScalarWhereWithAggregatesInput>>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
};

export type StripeCustomerUncheckedCreateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerUncheckedCreateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerUncheckedCreateWithoutInvoicesInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerUncheckedCreateWithoutPaymentMethodsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedCreateNestedManyWithoutStripeCustomerInput>;
  stripeId: Scalars['String'];
};

export type StripeCustomerUncheckedUpdateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateManyInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutInvoicesInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUncheckedUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateInput = {
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutInvoicesInput>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutInvoicesInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutInvoicesInput>;
};

export type StripeCustomerUpdateOneWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutClinicPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutClinicPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutOrganizationPetParentsNestedInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutOrganizationPetParentsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutOrganizationPetParentsInput>;
};

export type StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput = {
  connect?: Maybe<StripeCustomerWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeCustomerCreateOrConnectWithoutPaymentMethodsInput>;
  create?: Maybe<StripeCustomerUncheckedCreateWithoutPaymentMethodsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput>;
  upsert?: Maybe<StripeCustomerUpsertWithoutPaymentMethodsInput>;
};

export type StripeCustomerUpdateWithoutClinicPetParentsInput = {
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateWithoutInvoicesInput = {
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateWithoutOrganizationPetParentsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
  paymentMethods?: Maybe<StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpdateWithoutPaymentMethodsInput = {
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput>;
  organizationPetParents?: Maybe<OrganizationPetParentUpdateManyWithoutStripeCustomerNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeCustomerUpsertWithoutClinicPetParentsInput = {
  create: StripeCustomerUncheckedCreateWithoutClinicPetParentsInput;
  update: StripeCustomerUncheckedUpdateWithoutClinicPetParentsInput;
};

export type StripeCustomerUpsertWithoutInvoicesInput = {
  create: StripeCustomerUncheckedCreateWithoutInvoicesInput;
  update: StripeCustomerUncheckedUpdateWithoutInvoicesInput;
};

export type StripeCustomerUpsertWithoutOrganizationPetParentsInput = {
  create: StripeCustomerUncheckedCreateWithoutOrganizationPetParentsInput;
  update: StripeCustomerUncheckedUpdateWithoutOrganizationPetParentsInput;
};

export type StripeCustomerUpsertWithoutPaymentMethodsInput = {
  create: StripeCustomerUncheckedCreateWithoutPaymentMethodsInput;
  update: StripeCustomerUncheckedUpdateWithoutPaymentMethodsInput;
};

export type StripeCustomerWhereInput = {
  AND?: Maybe<Array<StripeCustomerWhereInput>>;
  NOT?: Maybe<Array<StripeCustomerWhereInput>>;
  OR?: Maybe<Array<StripeCustomerWhereInput>>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  id?: Maybe<StringFilter>;
  invoices?: Maybe<StripeInvoiceListRelationFilter>;
  organizationPetParents?: Maybe<OrganizationPetParentListRelationFilter>;
  paymentMethods?: Maybe<StripePaymentMethodListRelationFilter>;
  stripeId?: Maybe<StringFilter>;
};

export type StripeCustomerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeDispute = {
  __typename?: 'StripeDispute';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** This will be the amount we charge the clinic for the dispute if they lose it */
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransaction>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** This is how much we refunded to them for the processing fees if they lose the dispute */
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayout>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StripeDisputeAvgAggregateOutputType = {
  __typename?: 'StripeDisputeAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  disputeFee?: Maybe<Scalars['Float']>;
  processingFeesRefunded?: Maybe<Scalars['Float']>;
};

export type StripeDisputeAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
};

export type StripeDisputeCountAggregateOutputType = {
  __typename?: 'StripeDisputeCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  disputeFee: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  id: Scalars['Int'];
  processingFeesRefunded: Scalars['Int'];
  status: Scalars['Int'];
  stripeDisputeFeeChargeId: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeTransferReversalId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeDisputeCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeDisputeCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateManyStripePayoutInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeDisputeCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
};

export type StripeDisputeCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
};

export type StripeDisputeCreateNestedOneWithoutFinancialTransactionInput = {
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
};

export type StripeDisputeCreateOrConnectWithoutFinancialTransactionInput = {
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput = {
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeCreateOrConnectWithoutStripePayoutInput = {
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateWithoutStripePaymentIntentInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeDisputesInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeCreateWithoutStripePayoutInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeListRelationFilter = {
  every?: Maybe<StripeDisputeWhereInput>;
  none?: Maybe<StripeDisputeWhereInput>;
  some?: Maybe<StripeDisputeWhereInput>;
};

export type StripeDisputeMaxAggregateOutputType = {
  __typename?: 'StripeDisputeMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeDisputeMinAggregateOutputType = {
  __typename?: 'StripeDisputeMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeDisputeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeDisputeOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId'
}

export type StripeDisputeOrderByRelevanceInput = {
  fields: Array<StripeDisputeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeDisputeOrderByWithAggregationInput = {
  _avg?: Maybe<StripeDisputeAvgOrderByAggregateInput>;
  _count?: Maybe<StripeDisputeCountOrderByAggregateInput>;
  _max?: Maybe<StripeDisputeMaxOrderByAggregateInput>;
  _min?: Maybe<StripeDisputeMinOrderByAggregateInput>;
  _sum?: Maybe<StripeDisputeSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeDisputeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeDisputeOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputeFeeChargeId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeTransferReversalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeDisputeRelationFilter = {
  is?: Maybe<StripeDisputeWhereInput>;
  isNot?: Maybe<StripeDisputeWhereInput>;
};

export enum StripeDisputeScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  DisputeFee = 'disputeFee',
  FundsAvailableAt = 'fundsAvailableAt',
  Id = 'id',
  ProcessingFeesRefunded = 'processingFeesRefunded',
  Status = 'status',
  StripeDisputeFeeChargeId = 'stripeDisputeFeeChargeId',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeTransferReversalId = 'stripeTransferReversalId',
  UpdatedAt = 'updatedAt'
}

export type StripeDisputeScalarWhereInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  disputeFee?: Maybe<IntNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  processingFeesRefunded?: Maybe<IntNullableFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeDisputeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeDisputeScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  disputeFee?: Maybe<IntNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  processingFeesRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripeDisputeStatusWithAggregatesFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTransferReversalId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripeDisputeStatus {
  ChargeRefunded = 'Charge_Refunded',
  Lost = 'Lost',
  NeedsResponse = 'Needs_Response',
  UnderReview = 'Under_Review',
  WarningClosed = 'Warning_Closed',
  WarningNeedsResponse = 'Warning_Needs_Response',
  WarningUnderReview = 'Warning_Under_Review',
  Won = 'Won'
}

export type StripeDisputeSumAggregateOutputType = {
  __typename?: 'StripeDisputeSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  disputeFee?: Maybe<Scalars['Int']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
};

export type StripeDisputeSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  disputeFee?: Maybe<SortOrder>;
  processingFeesRefunded?: Maybe<SortOrder>;
};

export type StripeDisputeUncheckedCreateInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
};

export type StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
};

export type StripeDisputeUncheckedCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedCreateWithoutStripePayoutInput = {
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeDisputeInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status: StripeDisputeStatus;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
};

export type StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUncheckedUpdateWithoutStripePayoutInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputeInput;
  where: StripeDisputeScalarWhereInput;
};

export type StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput = {
  data: StripeDisputeUncheckedUpdateManyWithoutStripeDisputesInput;
  where: StripeDisputeScalarWhereInput;
};

export type StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePaymentIntentInputEnvelope>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
};

export type StripeDisputeUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeDisputeCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeDisputeCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeDisputeCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeDisputeScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  set?: Maybe<Array<StripeDisputeWhereUniqueInput>>;
  update?: Maybe<Array<StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeDisputeUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripeDisputeUpdateOneWithoutFinancialTransactionNestedInput = {
  connect?: Maybe<StripeDisputeWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeDisputeCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeDisputeUncheckedCreateWithoutFinancialTransactionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeDisputeUpsertWithoutFinancialTransactionInput>;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  data: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeUpdateWithWhereUniqueWithoutStripePayoutInput = {
  data: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateWithoutStripePaymentIntentInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeDisputesNestedInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpdateWithoutStripePayoutInput = {
  amount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disputeFee?: Maybe<Scalars['Int']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeDisputeNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  processingFeesRefunded?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeDisputeStatus>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  create: StripeDisputeUncheckedCreateWithoutStripePaymentIntentInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePaymentIntentInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeUpsertWithWhereUniqueWithoutStripePayoutInput = {
  create: StripeDisputeUncheckedCreateWithoutStripePayoutInput;
  update: StripeDisputeUncheckedUpdateWithoutStripePayoutInput;
  where: StripeDisputeWhereUniqueInput;
};

export type StripeDisputeUpsertWithoutFinancialTransactionInput = {
  create: StripeDisputeUncheckedCreateWithoutFinancialTransactionInput;
  update: StripeDisputeUncheckedUpdateWithoutFinancialTransactionInput;
};

export type StripeDisputeWhereInput = {
  AND?: Maybe<Array<StripeDisputeWhereInput>>;
  NOT?: Maybe<Array<StripeDisputeWhereInput>>;
  OR?: Maybe<Array<StripeDisputeWhereInput>>;
  amount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  disputeFee?: Maybe<IntNullableFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  processingFeesRefunded?: Maybe<IntNullableFilter>;
  status?: Maybe<EnumStripeDisputeStatusFilter>;
  stripeDisputeFeeChargeId?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeTransferReversalId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeDisputeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeDisputeFeeChargeId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeTransferReversalId?: Maybe<Scalars['String']>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  _count: StripeInvoiceCountOutputType;
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscription;
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  collectionMethod: StripeCollectionMethod;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment: Array<CareEnrollmentPayment>;
  financialTransaction?: Maybe<FinancialTransaction>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  lapsedPayment?: Maybe<CareLapsedPayment>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  relatedStripeInvoiceItems: Array<StripeInvoiceItem>;
  serviceFeeAmount: Scalars['Int'];
  /** example 71.66% would be 0.7166 */
  serviceFeePercent: Scalars['Float'];
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomer;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type StripeInvoiceCareSubscriptionTrueUpsArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type StripeInvoiceEnrollmentPaymentArgs = {
  cursor?: Maybe<CareEnrollmentPaymentWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentPaymentScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentPaymentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentPaymentWhereInput>;
};


export type StripeInvoiceRelatedEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type StripeInvoiceRelatedStripeInvoiceItemsArgs = {
  cursor?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<StripeInvoiceItemScalarFieldEnum>;
  orderBy?: Maybe<StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceItemWhereInput>;
};

export type StripeInvoiceAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeInvoiceCountAggregateOutputType = {
  __typename?: 'StripeInvoiceCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['Int'];
  collectionMethod: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  dueAt: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  id: Scalars['Int'];
  paidAt: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  serviceFeePercent: Scalars['Int'];
  status: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeInvoiceCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeInvoiceCountOutputType = {
  __typename?: 'StripeInvoiceCountOutputType';
  careSubscriptionTrueUps: Scalars['Int'];
  enrollmentPayment: Scalars['Int'];
  relatedEnrollmentSupportEvents: Scalars['Int'];
  relatedStripeInvoiceItems: Scalars['Int'];
};

export type StripeInvoiceCreateInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope = {
  data: Array<StripeInvoiceCreateManyCareStripeSubscriptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripeCustomerInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateManyStripePaymentMethodInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripeInvoiceCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type StripeInvoiceCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
};

export type StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
};

export type StripeInvoiceCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
};

export type StripeInvoiceCreateNestedOneWithoutEnrollmentPaymentInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
};

export type StripeInvoiceCreateNestedOneWithoutFinancialTransactionInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
};

export type StripeInvoiceCreateNestedOneWithoutLapsedPaymentInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput>;
};

export type StripeInvoiceCreateNestedOneWithoutRelatedEnrollmentSupportEventsInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput>;
};

export type StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
};

export type StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput = {
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  create: StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput = {
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput = {
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput = {
  create: StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput = {
  create: StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput = {
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripeCustomerInput = {
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput = {
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceCreateWithoutCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutCareSubscriptionTrueUpsInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutEnrollmentPaymentInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutLapsedPaymentInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutRelatedEnrollmentSupportEventsInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutRelatedStripeInvoiceItemsInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutStripeCustomerInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceCreateWithoutStripePaymentMethodInput = {
  amount: Scalars['Int'];
  careStripeSubscription: CareStripeSubscriptionCreateNestedOneWithoutInvoicesInput;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomer: StripeCustomerCreateNestedOneWithoutInvoicesInput;
  stripeId: Scalars['String'];
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceItem = {
  __typename?: 'StripeInvoiceItem';
  _count: StripeInvoiceItemCountOutputType;
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  enrollmentToStripeInvoiceItems: Array<CareEnrollmentToStripeInvoiceItem>;
  id: Scalars['String'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoice: StripeInvoice;
  stripeInvoiceId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};


export type StripeInvoiceItemEnrollmentToStripeInvoiceItemsArgs = {
  cursor?: Maybe<CareEnrollmentToStripeInvoiceItemWhereUniqueInput>;
  distinct?: Maybe<CareEnrollmentToStripeInvoiceItemScalarFieldEnum>;
  orderBy?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareEnrollmentToStripeInvoiceItemWhereInput>;
};

export type StripeInvoiceItemAvgAggregateOutputType = {
  __typename?: 'StripeInvoiceItemAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitAmount?: Maybe<Scalars['Float']>;
};

export type StripeInvoiceItemAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountAggregateOutputType = {
  __typename?: 'StripeInvoiceItemCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  date: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  periodEnd: Scalars['Int'];
  periodStart: Scalars['Int'];
  quantity: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripeInvoiceId: Scalars['Int'];
  stripeSubscriptionItemId: Scalars['Int'];
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemCountOutputType = {
  __typename?: 'StripeInvoiceItemCountOutputType';
  enrollmentToStripeInvoiceItems: Scalars['Int'];
};

export type StripeInvoiceItemCreateInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemCreateManyInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemCreateManyStripeInvoiceInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope = {
  data: Array<StripeInvoiceItemCreateManyStripeInvoiceInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeInvoiceItemCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
};

export type StripeInvoiceItemCreateNestedOneWithoutEnrollmentToStripeInvoiceItemsInput = {
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  where: StripeInvoiceItemWhereUniqueInput;
};

export type StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput = {
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
  where: StripeInvoiceItemWhereUniqueInput;
};

export type StripeInvoiceItemCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoice: StripeInvoiceCreateNestedOneWithoutRelatedStripeInvoiceItemsInput;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemCreateNestedManyWithoutStripeInvoiceItemInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemListRelationFilter = {
  every?: Maybe<StripeInvoiceItemWhereInput>;
  none?: Maybe<StripeInvoiceItemWhereInput>;
  some?: Maybe<StripeInvoiceItemWhereInput>;
};

export type StripeInvoiceItemMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemMinAggregateOutputType = {
  __typename?: 'StripeInvoiceItemMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceItemOrderByRelevanceFieldEnum {
  Description = 'description',
  Id = 'id',
  StripeId = 'stripeId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId'
}

export type StripeInvoiceItemOrderByRelevanceInput = {
  fields: Array<StripeInvoiceItemOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeInvoiceItemOrderByWithAggregationInput = {
  _avg?: Maybe<StripeInvoiceItemAvgOrderByAggregateInput>;
  _count?: Maybe<StripeInvoiceItemCountOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceItemMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceItemMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceItemSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeInvoiceItemOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  periodEnd?: Maybe<SortOrder>;
  periodStart?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoice?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  stripeInvoiceId?: Maybe<SortOrder>;
  stripeSubscriptionItemId?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemRelationFilter = {
  is?: Maybe<StripeInvoiceItemWhereInput>;
  isNot?: Maybe<StripeInvoiceItemWhereInput>;
};

export enum StripeInvoiceItemScalarFieldEnum {
  Amount = 'amount',
  Date = 'date',
  Description = 'description',
  Id = 'id',
  PeriodEnd = 'periodEnd',
  PeriodStart = 'periodStart',
  Quantity = 'quantity',
  StripeId = 'stripeId',
  StripeInvoiceId = 'stripeInvoiceId',
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  UnitAmount = 'unitAmount'
}

export type StripeInvoiceItemScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  quantity?: Maybe<IntFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeInvoiceId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  unitAmount?: Maybe<IntFilter>;
};

export type StripeInvoiceItemScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceItemScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  periodEnd?: Maybe<DateTimeWithAggregatesFilter>;
  periodStart?: Maybe<DateTimeWithAggregatesFilter>;
  quantity?: Maybe<IntWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripeInvoiceId?: Maybe<StringWithAggregatesFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableWithAggregatesFilter>;
  unitAmount?: Maybe<IntWithAggregatesFilter>;
};

export type StripeInvoiceItemSumAggregateOutputType = {
  __typename?: 'StripeInvoiceItemSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  unitAmount?: Maybe<SortOrder>;
};

export type StripeInvoiceItemUncheckedCreateInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput = {
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
};

export type StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeInvoiceId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput = {
  amount: Scalars['Int'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceItemInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  quantity: Scalars['Int'];
  stripeId: Scalars['String'];
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount: Scalars['Int'];
};

export type StripeInvoiceItemUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceItemNestedInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput = {
  data: StripeInvoiceItemUncheckedUpdateManyWithoutRelatedStripeInvoiceItemsInput;
  where: StripeInvoiceItemScalarWhereInput;
};

export type StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput = {
  connect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceItemCreateOrConnectWithoutStripeInvoiceInput>>;
  create?: Maybe<Array<StripeInvoiceItemCreateWithoutStripeInvoiceInput>>;
  createMany?: Maybe<StripeInvoiceItemCreateManyStripeInvoiceInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceItemScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceItemWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput>>;
  updateMany?: Maybe<Array<StripeInvoiceItemUpdateManyWithWhereWithoutStripeInvoiceInput>>;
  upsert?: Maybe<Array<StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput>>;
};

export type StripeInvoiceItemUpdateOneRequiredWithoutEnrollmentToStripeInvoiceItemsNestedInput = {
  connect?: Maybe<StripeInvoiceItemWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceItemCreateOrConnectWithoutEnrollmentToStripeInvoiceItemsInput>;
  create?: Maybe<StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput>;
  update?: Maybe<StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput>;
};

export type StripeInvoiceItemUpdateWithWhereUniqueWithoutStripeInvoiceInput = {
  data: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
  where: StripeInvoiceItemWhereUniqueInput;
};

export type StripeInvoiceItemUpdateWithoutEnrollmentToStripeInvoiceItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoice?: Maybe<StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUpdateWithoutStripeInvoiceInput = {
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemUpdateManyWithoutStripeInvoiceItemNestedInput>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeSubscriptionItemId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceItemUpsertWithWhereUniqueWithoutStripeInvoiceInput = {
  create: StripeInvoiceItemUncheckedCreateWithoutStripeInvoiceInput;
  update: StripeInvoiceItemUncheckedUpdateWithoutStripeInvoiceInput;
  where: StripeInvoiceItemWhereUniqueInput;
};

export type StripeInvoiceItemUpsertWithoutEnrollmentToStripeInvoiceItemsInput = {
  create: StripeInvoiceItemUncheckedCreateWithoutEnrollmentToStripeInvoiceItemsInput;
  update: StripeInvoiceItemUncheckedUpdateWithoutEnrollmentToStripeInvoiceItemsInput;
};

export type StripeInvoiceItemWhereInput = {
  AND?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceItemWhereInput>>;
  amount?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  enrollmentToStripeInvoiceItems?: Maybe<CareEnrollmentToStripeInvoiceItemListRelationFilter>;
  id?: Maybe<StringFilter>;
  periodEnd?: Maybe<DateTimeFilter>;
  periodStart?: Maybe<DateTimeFilter>;
  quantity?: Maybe<IntFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeInvoice?: Maybe<StripeInvoiceWhereInput>;
  stripeInvoiceId?: Maybe<StringFilter>;
  stripeSubscriptionItemId?: Maybe<StringNullableFilter>;
  unitAmount?: Maybe<IntFilter>;
};

export type StripeInvoiceItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeInvoiceListRelationFilter = {
  every?: Maybe<StripeInvoiceWhereInput>;
  none?: Maybe<StripeInvoiceWhereInput>;
  some?: Maybe<StripeInvoiceWhereInput>;
};

export type StripeInvoiceMaxAggregateOutputType = {
  __typename?: 'StripeInvoiceMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeInvoiceMinAggregateOutputType = {
  __typename?: 'StripeInvoiceMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeInvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeInvoiceOrderByRelevanceFieldEnum {
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  Id = 'id',
  StripeCustomerId = 'stripeCustomerId',
  StripeId = 'stripeId',
  StripePaymentMethodId = 'stripePaymentMethodId'
}

export type StripeInvoiceOrderByRelevanceInput = {
  fields: Array<StripeInvoiceOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeInvoiceOrderByWithAggregationInput = {
  _avg?: Maybe<StripeInvoiceAvgOrderByAggregateInput>;
  _count?: Maybe<StripeInvoiceCountOrderByAggregateInput>;
  _max?: Maybe<StripeInvoiceMaxOrderByAggregateInput>;
  _min?: Maybe<StripeInvoiceMinOrderByAggregateInput>;
  _sum?: Maybe<StripeInvoiceSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  collectionMethod?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeInvoiceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeInvoiceOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  careStripeSubscriptionId?: Maybe<SortOrder>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  collectionMethod?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentOrderByRelationAggregateInput>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lapsedPayment?: Maybe<CareLapsedPaymentOrderByWithRelationAndSearchRelevanceInput>;
  paidAt?: Maybe<SortOrder>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemOrderByRelationAggregateInput>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeInvoiceRelationFilter = {
  is?: Maybe<StripeInvoiceWhereInput>;
  isNot?: Maybe<StripeInvoiceWhereInput>;
};

export enum StripeInvoiceScalarFieldEnum {
  Amount = 'amount',
  CareStripeSubscriptionId = 'careStripeSubscriptionId',
  CollectionMethod = 'collectionMethod',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DueAt = 'dueAt',
  FundsAvailableAt = 'fundsAvailableAt',
  Id = 'id',
  PaidAt = 'paidAt',
  ServiceFeeAmount = 'serviceFeeAmount',
  ServiceFeePercent = 'serviceFeePercent',
  Status = 'status',
  StripeCustomerId = 'stripeCustomerId',
  StripeId = 'stripeId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  Total = 'total',
  UpdatedAt = 'updatedAt'
}

export type StripeInvoiceScalarWhereInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  serviceFeePercent?: Maybe<FloatFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeInvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeInvoiceScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  careStripeSubscriptionId?: Maybe<StringWithAggregatesFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currency?: Maybe<EnumCurrencyWithAggregatesFilter>;
  dueAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<IntWithAggregatesFilter>;
  serviceFeePercent?: Maybe<FloatWithAggregatesFilter>;
  status?: Maybe<EnumStripeInvoiceStatusWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void'
}

export type StripeInvoiceSumAggregateOutputType = {
  __typename?: 'StripeInvoiceSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeInvoiceSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  serviceFeePercent?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeInvoiceUncheckedCreateInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutCareStripeSubscriptionInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
};

export type StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
};

export type StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput = {
  amount: Scalars['Int'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutStripeCustomerInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput = {
  amount: Scalars['Int'];
  careStripeSubscriptionId: Scalars['String'];
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  collectionMethod: StripeCollectionMethod;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedCreateNestedOneWithoutStripeInvoiceInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRelatedInvoiceInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedCreateNestedManyWithoutStripeInvoiceInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status: StripeInvoiceStatus;
  stripeCustomerId: Scalars['String'];
  stripeId: Scalars['String'];
  total: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  amount?: Maybe<Scalars['Int']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput = {
  amount?: Maybe<Scalars['Int']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscriptionId?: Maybe<Scalars['String']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUncheckedUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUncheckedUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput = {
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
  where: StripeInvoiceScalarWhereInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput = {
  data: StripeInvoiceUncheckedUpdateManyWithoutInvoicesInput;
  where: StripeInvoiceScalarWhereInput;
};

export type StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  data: StripeInvoiceUncheckedUpdateManyWithoutStripeInvoicesInput;
  where: StripeInvoiceScalarWhereInput;
};

export type StripeInvoiceUpdateManyWithoutCareStripeSubscriptionNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutCareStripeSubscriptionInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutCareStripeSubscriptionInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyCareStripeSubscriptionInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutCareStripeSubscriptionInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeInvoiceCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripeInvoiceCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripeInvoiceCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeInvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  set?: Maybe<Array<StripeInvoiceWhereUniqueInput>>;
  update?: Maybe<Array<StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripeInvoiceUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type StripeInvoiceUpdateOneRequiredWithoutLapsedPaymentNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutLapsedPaymentInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutLapsedPaymentInput>;
};

export type StripeInvoiceUpdateOneRequiredWithoutRelatedStripeInvoiceItemsNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedStripeInvoiceItemsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput>;
};

export type StripeInvoiceUpdateOneWithoutCareSubscriptionTrueUpsNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutCareSubscriptionTrueUpsInput>;
};

export type StripeInvoiceUpdateOneWithoutEnrollmentPaymentNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutEnrollmentPaymentInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutEnrollmentPaymentInput>;
};

export type StripeInvoiceUpdateOneWithoutFinancialTransactionNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutFinancialTransactionInput>;
};

export type StripeInvoiceUpdateOneWithoutRelatedEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<StripeInvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeInvoiceCreateOrConnectWithoutRelatedEnrollmentSupportEventsInput>;
  create?: Maybe<StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput>;
  upsert?: Maybe<StripeInvoiceUpsertWithoutRelatedEnrollmentSupportEventsInput>;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  data: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  data: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  data: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpdateWithoutCareStripeSubscriptionInput = {
  amount?: Maybe<Scalars['Int']>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutCareSubscriptionTrueUpsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutEnrollmentPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutLapsedPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutRelatedEnrollmentSupportEventsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutRelatedStripeInvoiceItemsInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutStripeCustomerInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpdateWithoutStripePaymentMethodInput = {
  amount?: Maybe<Scalars['Int']>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionUpdateOneRequiredWithoutInvoicesNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripeInvoiceNestedInput>;
  collectionMethod?: Maybe<StripeCollectionMethod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueAt?: Maybe<Scalars['DateTime']>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentUpdateManyWithoutStripeInvoiceNestedInput>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeInvoiceNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lapsedPayment?: Maybe<CareLapsedPaymentUpdateOneWithoutStripeInvoiceNestedInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRelatedInvoiceNestedInput>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemUpdateManyWithoutStripeInvoiceNestedInput>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  serviceFeePercent?: Maybe<Scalars['Float']>;
  status?: Maybe<StripeInvoiceStatus>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutCareStripeSubscriptionInput = {
  create: StripeInvoiceUncheckedCreateWithoutCareStripeSubscriptionInput;
  update: StripeInvoiceUncheckedUpdateWithoutCareStripeSubscriptionInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  create: StripeInvoiceUncheckedCreateWithoutStripeCustomerInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripeCustomerInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  create: StripeInvoiceUncheckedCreateWithoutStripePaymentMethodInput;
  update: StripeInvoiceUncheckedUpdateWithoutStripePaymentMethodInput;
  where: StripeInvoiceWhereUniqueInput;
};

export type StripeInvoiceUpsertWithoutCareSubscriptionTrueUpsInput = {
  create: StripeInvoiceUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  update: StripeInvoiceUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
};

export type StripeInvoiceUpsertWithoutEnrollmentPaymentInput = {
  create: StripeInvoiceUncheckedCreateWithoutEnrollmentPaymentInput;
  update: StripeInvoiceUncheckedUpdateWithoutEnrollmentPaymentInput;
};

export type StripeInvoiceUpsertWithoutFinancialTransactionInput = {
  create: StripeInvoiceUncheckedCreateWithoutFinancialTransactionInput;
  update: StripeInvoiceUncheckedUpdateWithoutFinancialTransactionInput;
};

export type StripeInvoiceUpsertWithoutLapsedPaymentInput = {
  create: StripeInvoiceUncheckedCreateWithoutLapsedPaymentInput;
  update: StripeInvoiceUncheckedUpdateWithoutLapsedPaymentInput;
};

export type StripeInvoiceUpsertWithoutRelatedEnrollmentSupportEventsInput = {
  create: StripeInvoiceUncheckedCreateWithoutRelatedEnrollmentSupportEventsInput;
  update: StripeInvoiceUncheckedUpdateWithoutRelatedEnrollmentSupportEventsInput;
};

export type StripeInvoiceUpsertWithoutRelatedStripeInvoiceItemsInput = {
  create: StripeInvoiceUncheckedCreateWithoutRelatedStripeInvoiceItemsInput;
  update: StripeInvoiceUncheckedUpdateWithoutRelatedStripeInvoiceItemsInput;
};

export type StripeInvoiceWhereInput = {
  AND?: Maybe<Array<StripeInvoiceWhereInput>>;
  NOT?: Maybe<Array<StripeInvoiceWhereInput>>;
  OR?: Maybe<Array<StripeInvoiceWhereInput>>;
  amount?: Maybe<IntFilter>;
  careStripeSubscription?: Maybe<CareStripeSubscriptionWhereInput>;
  careStripeSubscriptionId?: Maybe<StringFilter>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  collectionMethod?: Maybe<EnumStripeCollectionMethodFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  dueAt?: Maybe<DateTimeNullableFilter>;
  enrollmentPayment?: Maybe<CareEnrollmentPaymentListRelationFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  lapsedPayment?: Maybe<CareLapsedPaymentWhereInput>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  relatedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  relatedStripeInvoiceItems?: Maybe<StripeInvoiceItemListRelationFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  serviceFeePercent?: Maybe<FloatFilter>;
  status?: Maybe<EnumStripeInvoiceStatusFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  stripeCustomerId?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeInvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayment = {
  __typename?: 'StripePayment';
  amount: Scalars['Int'];
  amountCaptured?: Maybe<Scalars['Int']>;
  amountRefunded?: Maybe<Scalars['Int']>;
  fees?: Maybe<StripePaymentFees>;
  id: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
  transferType?: Maybe<Scalars['String']>;
};

export enum StripePaymentError {
  Blocked = 'Blocked',
  CardNotSupported = 'CardNotSupported',
  CurrencyNotSupported = 'CurrencyNotSupported',
  DuplicateTransaction = 'DuplicateTransaction',
  ExpiredCard = 'ExpiredCard',
  Fraudulent = 'Fraudulent',
  GenericDecline = 'GenericDecline',
  IncorrectCvc = 'IncorrectCVC',
  IncorrectNumber = 'IncorrectNumber',
  IncorrectPin = 'IncorrectPIN',
  IncorrectPostalCode = 'IncorrectPostalCode',
  InsufficientFunds = 'InsufficientFunds',
  InvalidAccount = 'InvalidAccount',
  InvalidExpiry = 'InvalidExpiry',
  LostStolenCard = 'LostStolenCard'
}

export type StripePaymentFees = {
  __typename?: 'StripePaymentFees';
  clientServiceFeeAmount: Scalars['Int'];
  clientServiceFeePercent: Scalars['Float'];
  clinicServiceFeeAmount: Scalars['Int'];
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  StripeDispute: Array<StripeDispute>;
  _count: StripePaymentIntentCountOutputType;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunAction>;
  captureType: ChargeCaptureType;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntent>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransaction>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  invoices: Array<Invoice>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<User>;
  sentByAutomation: Scalars['Boolean'];
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status: StripePaymentIntentStatus;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayout>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceipt?: Maybe<StripeReceipt>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds: Array<StripeRefund>;
  stripeTerminal?: Maybe<StripeTerminal>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType: StripePaymentTransferType;
  updatedAt: Scalars['DateTime'];
  writebackLogEntries: Array<PaymentPimsWritebackLogEntry>;
};


export type StripePaymentIntentStripeDisputeArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type StripePaymentIntentInvoicesArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type StripePaymentIntentStripeRefundsArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type StripePaymentIntentWritebackLogEntriesArgs = {
  cursor?: Maybe<PaymentPimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<PaymentPimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<PaymentPimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentPimsWritebackLogEntryWhereInput>;
};

export type StripePaymentIntentAvgAggregateOutputType = {
  __typename?: 'StripePaymentIntentAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountCaptured?: Maybe<Scalars['Float']>;
  clientServiceFee?: Maybe<Scalars['Float']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Float']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalCaptured?: Maybe<Scalars['Float']>;
};

export type StripePaymentIntentAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountAggregateOutputType = {
  __typename?: 'StripePaymentIntentCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType: Scalars['Int'];
  cardType: Scalars['Int'];
  careAccountCreditIntentId: Scalars['Int'];
  clientServiceFee: Scalars['Int'];
  clientServiceFeePercent: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  clinicServiceFeeFlat: Scalars['Int'];
  clinicServiceFeePercent: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailOverride: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  id: Scalars['Int'];
  lastPaymentError: Scalars['Int'];
  paidAt: Scalars['Int'];
  paymentIdentifier: Scalars['Int'];
  paymentMedium: Scalars['Int'];
  paymentRelationship: Scalars['Int'];
  sentByAutomation: Scalars['Int'];
  sentByUserId: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  settledAt: Scalars['Int'];
  status: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripePaymentMethodId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  stripeTerminalId: Scalars['Int'];
  total: Scalars['Int'];
  totalCaptured: Scalars['Int'];
  transferType: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripePaymentIntentCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentIntentCountOutputType = {
  __typename?: 'StripePaymentIntentCountOutputType';
  StripeDispute: Scalars['Int'];
  invoices: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
  writebackLogEntries: Scalars['Int'];
};

export type StripePaymentIntentCreateInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateManyCareAccountCreditIntentInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyCareAccountCreditIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyClinicInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyClinicInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyClinicPetParentInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManySentByInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManySentByInputEnvelope = {
  data: Array<StripePaymentIntentCreateManySentByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePaymentMethodInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripePayoutInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyStripePayoutInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateManyStripeTerminalInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentCreateManyStripeTerminalInputEnvelope = {
  data: Array<StripePaymentIntentCreateManyStripeTerminalInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentIntentCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutInvoicesInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
};

export type StripePaymentIntentCreateNestedManyWithoutSentByInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
};

export type StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
};

export type StripePaymentIntentCreateNestedOneWithoutAutomationRunActionInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutFinancialTransactionInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeDisputeInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
};

export type StripePaymentIntentCreateNestedOneWithoutWritebackLogEntriesInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
};

export type StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput = {
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput = {
  create: StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicInput = {
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput = {
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput = {
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutInvoicesInput = {
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutSentByInput = {
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripePayoutInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput = {
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentCreateWithoutAutomationRunActionInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutCareAccountCreditIntentInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutClinicInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutClinicPetParentInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutFinancialTransactionInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutInvoicesInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutSentByInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripeDisputeInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripePaymentMethodInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripePayoutInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripeReceiptInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripeRefundsInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutStripeTerminalInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentCreateWithoutWritebackLogEntriesInput = {
  StripeDispute?: Maybe<StripeDisputeCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentIntentsInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserCreateNestedOneWithoutSentStripePaymentIntentsInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethod?: Maybe<StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutPaymentInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminal?: Maybe<StripeTerminalCreateNestedOneWithoutPaymentsInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentListRelationFilter = {
  every?: Maybe<StripePaymentIntentWhereInput>;
  none?: Maybe<StripePaymentIntentWhereInput>;
  some?: Maybe<StripePaymentIntentWhereInput>;
};

export type StripePaymentIntentMaxAggregateOutputType = {
  __typename?: 'StripePaymentIntentMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentIntentMinAggregateOutputType = {
  __typename?: 'StripePaymentIntentMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentIntentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentIntentOrderByRelevanceFieldEnum {
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  EmailOverride = 'emailOverride',
  Id = 'id',
  PaymentIdentifier = 'paymentIdentifier',
  SentByUserId = 'sentByUserId',
  StripeId = 'stripeId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeReceiptId = 'stripeReceiptId',
  StripeTerminalId = 'stripeTerminalId'
}

export type StripePaymentIntentOrderByRelevanceInput = {
  fields: Array<StripePaymentIntentOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripePaymentIntentOrderByWithAggregationInput = {
  _avg?: Maybe<StripePaymentIntentAvgOrderByAggregateInput>;
  _count?: Maybe<StripePaymentIntentCountOrderByAggregateInput>;
  _max?: Maybe<StripePaymentIntentMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentIntentMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentIntentSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  captureType?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastPaymentError?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeTerminalId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput = {
  StripeDispute?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  _relevance?: Maybe<StripePaymentIntentOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  automationRunAction?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  captureType?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentOrderByWithRelationAndSearchRelevanceInput>;
  careAccountCreditIntentId?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailOverride?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  lastPaymentError?: Maybe<SortOrder>;
  paidAt?: Maybe<SortOrder>;
  paymentIdentifier?: Maybe<SortOrder>;
  paymentMedium?: Maybe<SortOrder>;
  paymentRelationship?: Maybe<SortOrder>;
  sentBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sentByAutomation?: Maybe<SortOrder>;
  sentByUserId?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settledAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethod?: Maybe<StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceiptId?: Maybe<SortOrder>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  stripeTerminal?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  stripeTerminalId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
  transferType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryOrderByRelationAggregateInput>;
};

export type StripePaymentIntentRelationFilter = {
  is?: Maybe<StripePaymentIntentWhereInput>;
  isNot?: Maybe<StripePaymentIntentWhereInput>;
};

export enum StripePaymentIntentScalarFieldEnum {
  Amount = 'amount',
  AmountCaptured = 'amountCaptured',
  CaptureType = 'captureType',
  CardType = 'cardType',
  CareAccountCreditIntentId = 'careAccountCreditIntentId',
  ClientServiceFee = 'clientServiceFee',
  ClientServiceFeePercent = 'clientServiceFeePercent',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  ClinicServiceFeeFlat = 'clinicServiceFeeFlat',
  ClinicServiceFeePercent = 'clinicServiceFeePercent',
  CreatedAt = 'createdAt',
  EmailOverride = 'emailOverride',
  FundsAvailableAt = 'fundsAvailableAt',
  Id = 'id',
  LastPaymentError = 'lastPaymentError',
  PaidAt = 'paidAt',
  PaymentIdentifier = 'paymentIdentifier',
  PaymentMedium = 'paymentMedium',
  PaymentRelationship = 'paymentRelationship',
  SentByAutomation = 'sentByAutomation',
  SentByUserId = 'sentByUserId',
  ServiceFeeAmount = 'serviceFeeAmount',
  SettledAt = 'settledAt',
  Status = 'status',
  StripeId = 'stripeId',
  StripePaymentMethodId = 'stripePaymentMethodId',
  StripePayoutId = 'stripePayoutId',
  StripeReceiptId = 'stripeReceiptId',
  StripeTerminalId = 'stripeTerminalId',
  Total = 'total',
  TotalCaptured = 'totalCaptured',
  TransferType = 'transferType',
  UpdatedAt = 'updatedAt'
}

export type StripePaymentIntentScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  cardType?: Maybe<EnumCardFundingNullableFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailOverride?: Maybe<StringNullableFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  paymentIdentifier?: Maybe<StringNullableFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePaymentIntentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentIntentScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  amountCaptured?: Maybe<IntWithAggregatesFilter>;
  captureType?: Maybe<EnumChargeCaptureTypeWithAggregatesFilter>;
  cardType?: Maybe<EnumCardFundingNullableWithAggregatesFilter>;
  careAccountCreditIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  clientServiceFee?: Maybe<IntNullableWithAggregatesFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableWithAggregatesFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailOverride?: Maybe<StringNullableWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableWithAggregatesFilter>;
  paidAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  paymentIdentifier?: Maybe<StringNullableWithAggregatesFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableWithAggregatesFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableWithAggregatesFilter>;
  sentByAutomation?: Maybe<BoolWithAggregatesFilter>;
  sentByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<IntWithAggregatesFilter>;
  settledAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentMethodId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeTerminalId?: Maybe<StringNullableWithAggregatesFilter>;
  total?: Maybe<IntWithAggregatesFilter>;
  totalCaptured?: Maybe<IntNullableWithAggregatesFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripePaymentIntentStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Processing = 'Processing',
  RequiresAction = 'Requires_Action',
  RequiresCapture = 'Requires_Capture',
  RequiresConfirmation = 'Requires_Confirmation',
  RequiresPaymentMethod = 'Requires_Payment_Method',
  Succeeded = 'Succeeded'
}

export type StripePaymentIntentSubscription = {
  __typename?: 'StripePaymentIntentSubscription';
  model: Scalars['String'];
  mutation: MutationType;
  node: StripePaymentIntent;
};

export type StripePaymentIntentSumAggregateOutputType = {
  __typename?: 'StripePaymentIntentSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
};

export type StripePaymentIntentSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountCaptured?: Maybe<SortOrder>;
  clientServiceFee?: Maybe<SortOrder>;
  clientServiceFeePercent?: Maybe<SortOrder>;
  clinicServiceFeeFlat?: Maybe<SortOrder>;
  clinicServiceFeePercent?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  totalCaptured?: Maybe<SortOrder>;
};

export type StripePaymentIntentUncheckedCreateInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutCareAccountCreditIntentInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutInvoicesInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
};

export type StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutInvoicesInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutSentByInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput = {
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripePayoutInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedCreateNestedManyWithoutPaymentInput>;
};

export type StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  amount: Scalars['Int'];
  amountCaptured: Scalars['Int'];
  automationRunAction?: Maybe<AutomationRunActionUncheckedCreateNestedOneWithoutPaymentIntentInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripePaymentIntentInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutStripePaymentIntentsInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount: Scalars['Int'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId: Scalars['String'];
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUncheckedUpdateInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutInvoicesNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
};

export type StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutInvoicesInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutSentByInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUncheckedUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput = {
  StripeDispute?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUncheckedUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  sentByUserId?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminalId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUpdateInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutSentByInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutSentStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentIntentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput = {
  data: StripePaymentIntentUncheckedUpdateManyWithoutPaymentsInput;
  where: StripePaymentIntentScalarWhereInput;
};

export type StripePaymentIntentUpdateManyWithoutCareAccountCreditIntentNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutCareAccountCreditIntentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutCareAccountCreditIntentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyCareAccountCreditIntentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutCareAccountCreditIntentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput>>;
};

export type StripePaymentIntentUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StripePaymentIntentUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type StripePaymentIntentUpdateManyWithoutInvoicesNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutInvoicesInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutInvoicesInput>>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutInvoicesInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput>>;
};

export type StripePaymentIntentUpdateManyWithoutSentByNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutSentByInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutSentByInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManySentByInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutSentByInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePaymentMethodInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePaymentMethodInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePaymentMethodInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePaymentMethodInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput = {
  connect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentIntentCreateOrConnectWithoutStripeTerminalInput>>;
  create?: Maybe<Array<StripePaymentIntentCreateWithoutStripeTerminalInput>>;
  createMany?: Maybe<StripePaymentIntentCreateManyStripeTerminalInputEnvelope>;
  delete?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentIntentScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentIntentWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput>>;
  updateMany?: Maybe<Array<StripePaymentIntentUpdateManyWithWhereWithoutStripeTerminalInput>>;
  upsert?: Maybe<Array<StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput>>;
};

export type StripePaymentIntentUpdateOneRequiredWithoutWritebackLogEntriesNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutWritebackLogEntriesInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutWritebackLogEntriesInput>;
};

export type StripePaymentIntentUpdateOneWithoutAutomationRunActionNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutAutomationRunActionInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutAutomationRunActionInput>;
};

export type StripePaymentIntentUpdateOneWithoutFinancialTransactionNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutFinancialTransactionInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeDisputeNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeDisputeInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeDisputeInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeReceiptInput>;
};

export type StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput = {
  connect?: Maybe<StripePaymentIntentWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentIntentCreateOrConnectWithoutStripeRefundsInput>;
  create?: Maybe<StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePaymentIntentUpsertWithoutStripeRefundsInput>;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutInvoicesInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutSentByInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePaymentMethodInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripePayoutInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithWhereUniqueWithoutStripeTerminalInput = {
  data: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpdateWithoutAutomationRunActionInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutCareAccountCreditIntentInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutClinicInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutClinicPetParentInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutFinancialTransactionInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutInvoicesInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutSentByInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeDisputeInput = {
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripePaymentMethodInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripePayoutInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeReceiptInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeRefundsInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutStripeTerminalInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryUpdateManyWithoutPaymentNestedInput>;
};

export type StripePaymentIntentUpdateWithoutWritebackLogEntriesInput = {
  StripeDispute?: Maybe<StripeDisputeUpdateManyWithoutStripePaymentIntentNestedInput>;
  amount?: Maybe<Scalars['Int']>;
  amountCaptured?: Maybe<Scalars['Int']>;
  automationRunAction?: Maybe<AutomationRunActionUpdateOneWithoutPaymentIntentNestedInput>;
  captureType?: Maybe<ChargeCaptureType>;
  cardType?: Maybe<CardFunding>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clientServiceFee?: Maybe<Scalars['Int']>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinic?: Maybe<ClinicUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentIntentsNestedInput>;
  clinicServiceFeeFlat?: Maybe<Scalars['Int']>;
  clinicServiceFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailOverride?: Maybe<Scalars['String']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripePaymentIntentNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<InvoiceUpdateManyWithoutStripePaymentIntentsNestedInput>;
  lastPaymentError?: Maybe<StripePaymentError>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  sentBy?: Maybe<UserUpdateOneWithoutSentStripePaymentIntentsNestedInput>;
  sentByAutomation?: Maybe<Scalars['Boolean']>;
  serviceFeeAmount?: Maybe<Scalars['Int']>;
  settledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StripePaymentIntentStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutPaymentNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput>;
  stripeTerminal?: Maybe<StripeTerminalUpdateOneWithoutPaymentsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  totalCaptured?: Maybe<Scalars['Int']>;
  transferType?: Maybe<StripePaymentTransferType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutCareAccountCreditIntentInput = {
  create: StripePaymentIntentUncheckedCreateWithoutCareAccountCreditIntentInput;
  update: StripePaymentIntentUncheckedUpdateWithoutCareAccountCreditIntentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicInput = {
  create: StripePaymentIntentUncheckedCreateWithoutClinicInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: StripePaymentIntentUncheckedCreateWithoutClinicPetParentInput;
  update: StripePaymentIntentUncheckedUpdateWithoutClinicPetParentInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutInvoicesInput = {
  create: StripePaymentIntentUncheckedCreateWithoutInvoicesInput;
  update: StripePaymentIntentUncheckedUpdateWithoutInvoicesInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutSentByInput = {
  create: StripePaymentIntentUncheckedCreateWithoutSentByInput;
  update: StripePaymentIntentUncheckedUpdateWithoutSentByInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePaymentMethodInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripePaymentMethodInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePaymentMethodInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripePayoutInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripePayoutInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripePayoutInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithWhereUniqueWithoutStripeTerminalInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeTerminalInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeTerminalInput;
  where: StripePaymentIntentWhereUniqueInput;
};

export type StripePaymentIntentUpsertWithoutAutomationRunActionInput = {
  create: StripePaymentIntentUncheckedCreateWithoutAutomationRunActionInput;
  update: StripePaymentIntentUncheckedUpdateWithoutAutomationRunActionInput;
};

export type StripePaymentIntentUpsertWithoutFinancialTransactionInput = {
  create: StripePaymentIntentUncheckedCreateWithoutFinancialTransactionInput;
  update: StripePaymentIntentUncheckedUpdateWithoutFinancialTransactionInput;
};

export type StripePaymentIntentUpsertWithoutStripeDisputeInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeDisputeInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeDisputeInput;
};

export type StripePaymentIntentUpsertWithoutStripeReceiptInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeReceiptInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeReceiptInput;
};

export type StripePaymentIntentUpsertWithoutStripeRefundsInput = {
  create: StripePaymentIntentUncheckedCreateWithoutStripeRefundsInput;
  update: StripePaymentIntentUncheckedUpdateWithoutStripeRefundsInput;
};

export type StripePaymentIntentUpsertWithoutWritebackLogEntriesInput = {
  create: StripePaymentIntentUncheckedCreateWithoutWritebackLogEntriesInput;
  update: StripePaymentIntentUncheckedUpdateWithoutWritebackLogEntriesInput;
};

export type StripePaymentIntentWhereInput = {
  AND?: Maybe<Array<StripePaymentIntentWhereInput>>;
  NOT?: Maybe<Array<StripePaymentIntentWhereInput>>;
  OR?: Maybe<Array<StripePaymentIntentWhereInput>>;
  StripeDispute?: Maybe<StripeDisputeListRelationFilter>;
  amount?: Maybe<IntFilter>;
  amountCaptured?: Maybe<IntFilter>;
  automationRunAction?: Maybe<AutomationRunActionWhereInput>;
  captureType?: Maybe<EnumChargeCaptureTypeFilter>;
  cardType?: Maybe<EnumCardFundingNullableFilter>;
  careAccountCreditIntent?: Maybe<CareAccountCreditIntentWhereInput>;
  careAccountCreditIntentId?: Maybe<StringNullableFilter>;
  clientServiceFee?: Maybe<IntNullableFilter>;
  clientServiceFeePercent?: Maybe<FloatNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  clinicServiceFeeFlat?: Maybe<IntNullableFilter>;
  clinicServiceFeePercent?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailOverride?: Maybe<StringNullableFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  lastPaymentError?: Maybe<EnumStripePaymentErrorNullableFilter>;
  paidAt?: Maybe<DateTimeNullableFilter>;
  paymentIdentifier?: Maybe<StringNullableFilter>;
  paymentMedium?: Maybe<EnumPaymentMediumNullableFilter>;
  paymentRelationship?: Maybe<EnumPaymentRelationshipNullableFilter>;
  sentBy?: Maybe<UserWhereInput>;
  sentByAutomation?: Maybe<BoolFilter>;
  sentByUserId?: Maybe<StringNullableFilter>;
  serviceFeeAmount?: Maybe<IntFilter>;
  settledAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumStripePaymentIntentStatusFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentMethod?: Maybe<StripePaymentMethodWhereInput>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  stripeTerminal?: Maybe<StripeTerminalWhereInput>;
  stripeTerminalId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntFilter>;
  totalCaptured?: Maybe<IntNullableFilter>;
  transferType?: Maybe<EnumStripePaymentTransferTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  writebackLogEntries?: Maybe<PaymentPimsWritebackLogEntryListRelationFilter>;
};

export type StripePaymentIntentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  paymentIdentifier?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  _count: StripePaymentMethodCountOutputType;
  cardBrand: CardBrand;
  careStripeSubscriptions: Array<CareStripeSubscription>;
  careSubscriptionTrueUps: Array<CareSubscriptionTrueUp>;
  clinicPetParent?: Maybe<ClinicPetParent>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isReusable: Scalars['Boolean'];
  last4: Scalars['String'];
  permitsFutureUsage: Scalars['Boolean'];
  stripeCustomer?: Maybe<StripeCustomer>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices: Array<StripeInvoice>;
  stripePaymentIntents: Array<StripePaymentIntent>;
  type: StripePaymentMethodType;
  updatedAt: Scalars['DateTime'];
};


export type StripePaymentMethodCareStripeSubscriptionsArgs = {
  cursor?: Maybe<CareStripeSubscriptionWhereUniqueInput>;
  distinct?: Maybe<CareStripeSubscriptionScalarFieldEnum>;
  orderBy?: Maybe<CareStripeSubscriptionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareStripeSubscriptionWhereInput>;
};


export type StripePaymentMethodCareSubscriptionTrueUpsArgs = {
  cursor?: Maybe<CareSubscriptionTrueUpWhereUniqueInput>;
  distinct?: Maybe<CareSubscriptionTrueUpScalarFieldEnum>;
  orderBy?: Maybe<CareSubscriptionTrueUpOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
};


export type StripePaymentMethodStripeInvoicesArgs = {
  cursor?: Maybe<StripeInvoiceWhereUniqueInput>;
  distinct?: Maybe<StripeInvoiceScalarFieldEnum>;
  orderBy?: Maybe<StripeInvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeInvoiceWhereInput>;
};


export type StripePaymentMethodStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type StripePaymentMethodAvgAggregateOutputType = {
  __typename?: 'StripePaymentMethodAvgAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Float']>;
  expirationYear?: Maybe<Scalars['Float']>;
};

export type StripePaymentMethodAvgOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountAggregateOutputType = {
  __typename?: 'StripePaymentMethodCountAggregateOutputType';
  _all: Scalars['Int'];
  cardBrand: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint: Scalars['Int'];
  funding: Scalars['Int'];
  id: Scalars['Int'];
  isDefault: Scalars['Int'];
  isDeleted: Scalars['Int'];
  isExpired: Scalars['Int'];
  isReusable: Scalars['Int'];
  last4: Scalars['Int'];
  permitsFutureUsage: Scalars['Int'];
  stripeCustomerId: Scalars['Int'];
  stripeId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripePaymentMethodCountOrderByAggregateInput = {
  cardBrand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentMethodCountOutputType = {
  __typename?: 'StripePaymentMethodCountOutputType';
  careStripeSubscriptions: Scalars['Int'];
  careSubscriptionTrueUps: Scalars['Int'];
  stripeInvoices: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
};

export type StripePaymentMethodCreateInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateManyClinicPetParentInput = {
  cardBrand: CardBrand;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateManyClinicPetParentInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateManyInput = {
  cardBrand: CardBrand;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInput = {
  cardBrand: CardBrand;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateManyStripeCustomerInputEnvelope = {
  data: Array<StripePaymentMethodCreateManyStripeCustomerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePaymentMethodCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
};

export type StripePaymentMethodCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
};

export type StripePaymentMethodCreateNestedOneWithoutCareStripeSubscriptionsInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutCareSubscriptionTrueUpsInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripeInvoicesInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
};

export type StripePaymentMethodCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
};

export type StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput = {
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodCreateWithoutCareStripeSubscriptionsInput = {
  cardBrand: CardBrand;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateWithoutCareSubscriptionTrueUpsInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateWithoutClinicPetParentInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateWithoutStripeCustomerInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateWithoutStripeInvoicesInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodCreateWithoutStripePaymentIntentsInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParent?: Maybe<ClinicPetParentCreateNestedOneWithoutStripePaymentMethodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerCreateNestedOneWithoutPaymentMethodsInput>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodFingerprintAndCustomerIdCompoundUniqueInput = {
  fingerprint: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

export type StripePaymentMethodListRelationFilter = {
  every?: Maybe<StripePaymentMethodWhereInput>;
  none?: Maybe<StripePaymentMethodWhereInput>;
  some?: Maybe<StripePaymentMethodWhereInput>;
};

export type StripePaymentMethodMaxAggregateOutputType = {
  __typename?: 'StripePaymentMethodMaxAggregateOutputType';
  cardBrand?: Maybe<CardBrand>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodMaxOrderByAggregateInput = {
  cardBrand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentMethodMinAggregateOutputType = {
  __typename?: 'StripePaymentMethodMinAggregateOutputType';
  cardBrand?: Maybe<CardBrand>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodMinOrderByAggregateInput = {
  cardBrand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentMethodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePaymentMethodOrderByRelevanceFieldEnum {
  ClinicPetParentId = 'clinicPetParentId',
  Fingerprint = 'fingerprint',
  Id = 'id',
  Last4 = 'last4',
  StripeCustomerId = 'stripeCustomerId',
  StripeId = 'stripeId'
}

export type StripePaymentMethodOrderByRelevanceInput = {
  fields: Array<StripePaymentMethodOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripePaymentMethodOrderByWithAggregationInput = {
  _avg?: Maybe<StripePaymentMethodAvgOrderByAggregateInput>;
  _count?: Maybe<StripePaymentMethodCountOrderByAggregateInput>;
  _max?: Maybe<StripePaymentMethodMaxOrderByAggregateInput>;
  _min?: Maybe<StripePaymentMethodMinOrderByAggregateInput>;
  _sum?: Maybe<StripePaymentMethodSumOrderByAggregateInput>;
  cardBrand?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentMethodOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripePaymentMethodOrderByRelevanceInput>;
  cardBrand?: Maybe<SortOrder>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionOrderByRelationAggregateInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpOrderByRelationAggregateInput>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fingerprint?: Maybe<SortOrder>;
  funding?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDefault?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  isExpired?: Maybe<SortOrder>;
  isReusable?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  permitsFutureUsage?: Maybe<SortOrder>;
  stripeCustomer?: Maybe<StripeCustomerOrderByWithRelationAndSearchRelevanceInput>;
  stripeCustomerId?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripeInvoices?: Maybe<StripeInvoiceOrderByRelationAggregateInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePaymentMethodRelationFilter = {
  is?: Maybe<StripePaymentMethodWhereInput>;
  isNot?: Maybe<StripePaymentMethodWhereInput>;
};

export enum StripePaymentMethodScalarFieldEnum {
  CardBrand = 'cardBrand',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  ExpirationMonth = 'expirationMonth',
  ExpirationYear = 'expirationYear',
  Fingerprint = 'fingerprint',
  Funding = 'funding',
  Id = 'id',
  IsDefault = 'isDefault',
  IsDeleted = 'isDeleted',
  IsExpired = 'isExpired',
  IsReusable = 'isReusable',
  Last4 = 'last4',
  PermitsFutureUsage = 'permitsFutureUsage',
  StripeCustomerId = 'stripeCustomerId',
  StripeId = 'stripeId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type StripePaymentMethodScalarWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  last4?: Maybe<StringFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePaymentMethodScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePaymentMethodScalarWhereWithAggregatesInput>>;
  cardBrand?: Maybe<EnumCardBrandWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expirationMonth?: Maybe<IntWithAggregatesFilter>;
  expirationYear?: Maybe<IntWithAggregatesFilter>;
  fingerprint?: Maybe<StringNullableWithAggregatesFilter>;
  funding?: Maybe<EnumCardFundingNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDefault?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  isExpired?: Maybe<BoolWithAggregatesFilter>;
  isReusable?: Maybe<BoolWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  permitsFutureUsage?: Maybe<BoolWithAggregatesFilter>;
  stripeCustomerId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StripePaymentMethodSumAggregateOutputType = {
  __typename?: 'StripePaymentMethodSumAggregateOutputType';
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
};

export type StripePaymentMethodSumOrderByAggregateInput = {
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
};

export enum StripePaymentMethodType {
  CardNotPresent = 'CardNotPresent',
  CardPresent = 'CardPresent'
}

export type StripePaymentMethodUncheckedCreateInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
};

export type StripePaymentMethodUncheckedCreateNestedManyWithoutStripeCustomerInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
};

export type StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput = {
  cardBrand: CardBrand;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput = {
  cardBrand: CardBrand;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripeInvoices?: Maybe<StripeInvoiceUncheckedCreateNestedManyWithoutStripePaymentMethodInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateManyInput = {
  cardBrand?: Maybe<CardBrand>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput = {
  cardBrand?: Maybe<CardBrand>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput = {
  cardBrand?: Maybe<CardBrand>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput = {
  cardBrand?: Maybe<CardBrand>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUncheckedUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateManyMutationInput = {
  cardBrand?: Maybe<CardBrand>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: StripePaymentMethodUncheckedUpdateManyWithoutStripePaymentMethodsInput;
  where: StripePaymentMethodScalarWhereInput;
};

export type StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput = {
  data: StripePaymentMethodUncheckedUpdateManyWithoutPaymentMethodsInput;
  where: StripePaymentMethodScalarWhereInput;
};

export type StripePaymentMethodUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type StripePaymentMethodUpdateManyWithoutStripeCustomerNestedInput = {
  connect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePaymentMethodCreateOrConnectWithoutStripeCustomerInput>>;
  create?: Maybe<Array<StripePaymentMethodCreateWithoutStripeCustomerInput>>;
  createMany?: Maybe<StripePaymentMethodCreateManyStripeCustomerInputEnvelope>;
  delete?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePaymentMethodScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  set?: Maybe<Array<StripePaymentMethodWhereUniqueInput>>;
  update?: Maybe<Array<StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput>>;
  updateMany?: Maybe<Array<StripePaymentMethodUpdateManyWithWhereWithoutStripeCustomerInput>>;
  upsert?: Maybe<Array<StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput>>;
};

export type StripePaymentMethodUpdateOneWithoutCareStripeSubscriptionsNestedInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareStripeSubscriptionsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput>;
};

export type StripePaymentMethodUpdateOneWithoutCareSubscriptionTrueUpsNestedInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutCareSubscriptionTrueUpsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutCareSubscriptionTrueUpsInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripeInvoicesNestedInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripeInvoicesInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripeInvoicesInput>;
};

export type StripePaymentMethodUpdateOneWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<StripePaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePaymentMethodCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePaymentMethodUpsertWithoutStripePaymentIntentsInput>;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpdateWithWhereUniqueWithoutStripeCustomerInput = {
  data: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpdateWithoutCareStripeSubscriptionsInput = {
  cardBrand?: Maybe<CardBrand>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateWithoutCareSubscriptionTrueUpsInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateWithoutClinicPetParentInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateWithoutStripeCustomerInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateWithoutStripeInvoicesInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpdateWithoutStripePaymentIntentsInput = {
  cardBrand?: Maybe<CardBrand>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionUpdateManyWithoutStripePaymentMethodNestedInput>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpUpdateManyWithoutStripePaymentMethodNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneWithoutStripePaymentMethodsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<CardFunding>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isReusable?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  permitsFutureUsage?: Maybe<Scalars['Boolean']>;
  stripeCustomer?: Maybe<StripeCustomerUpdateOneWithoutPaymentMethodsNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripeInvoices?: Maybe<StripeInvoiceUpdateManyWithoutStripePaymentMethodNestedInput>;
  type?: Maybe<StripePaymentMethodType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: StripePaymentMethodUncheckedCreateWithoutClinicPetParentInput;
  update: StripePaymentMethodUncheckedUpdateWithoutClinicPetParentInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpsertWithWhereUniqueWithoutStripeCustomerInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripeCustomerInput;
  update: StripePaymentMethodUncheckedUpdateWithoutStripeCustomerInput;
  where: StripePaymentMethodWhereUniqueInput;
};

export type StripePaymentMethodUpsertWithoutCareStripeSubscriptionsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutCareStripeSubscriptionsInput;
  update: StripePaymentMethodUncheckedUpdateWithoutCareStripeSubscriptionsInput;
};

export type StripePaymentMethodUpsertWithoutCareSubscriptionTrueUpsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutCareSubscriptionTrueUpsInput;
  update: StripePaymentMethodUncheckedUpdateWithoutCareSubscriptionTrueUpsInput;
};

export type StripePaymentMethodUpsertWithoutStripeInvoicesInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripeInvoicesInput;
  update: StripePaymentMethodUncheckedUpdateWithoutStripeInvoicesInput;
};

export type StripePaymentMethodUpsertWithoutStripePaymentIntentsInput = {
  create: StripePaymentMethodUncheckedCreateWithoutStripePaymentIntentsInput;
  update: StripePaymentMethodUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type StripePaymentMethodWhereInput = {
  AND?: Maybe<Array<StripePaymentMethodWhereInput>>;
  NOT?: Maybe<Array<StripePaymentMethodWhereInput>>;
  OR?: Maybe<Array<StripePaymentMethodWhereInput>>;
  cardBrand?: Maybe<EnumCardBrandFilter>;
  careStripeSubscriptions?: Maybe<CareStripeSubscriptionListRelationFilter>;
  careSubscriptionTrueUps?: Maybe<CareSubscriptionTrueUpListRelationFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationMonth?: Maybe<IntFilter>;
  expirationYear?: Maybe<IntFilter>;
  fingerprint?: Maybe<StringNullableFilter>;
  funding?: Maybe<EnumCardFundingNullableFilter>;
  id?: Maybe<StringFilter>;
  isDefault?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  isExpired?: Maybe<BoolFilter>;
  isReusable?: Maybe<BoolFilter>;
  last4?: Maybe<StringFilter>;
  permitsFutureUsage?: Maybe<BoolFilter>;
  stripeCustomer?: Maybe<StripeCustomerWhereInput>;
  stripeCustomerId?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  stripeInvoices?: Maybe<StripeInvoiceListRelationFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  type?: Maybe<EnumStripePaymentMethodTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePaymentMethodWhereUniqueInput = {
  fingerprintAndCustomerId?: Maybe<StripePaymentMethodFingerprintAndCustomerIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePaymentOutput = {
  __typename?: 'StripePaymentOutput';
  payment: StripePayment;
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
  requiresAction?: Maybe<Scalars['Boolean']>;
};

export enum StripePaymentTransferType {
  ApplicationFee = 'Application_Fee',
  TransferData = 'Transfer_Data'
}

export type StripePayout = {
  __typename?: 'StripePayout';
  _count: StripePayoutCountOutputType;
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  financialTransactions: Array<FinancialTransaction>;
  id: Scalars['String'];
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriod>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes: Array<StripeDispute>;
  stripeId: Scalars['String'];
  stripePaymentIntents: Array<StripePaymentIntent>;
  stripePayoutAccount?: Maybe<StripePayoutAccount>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds: Array<StripeRefund>;
  updatedAt: Scalars['DateTime'];
};


export type StripePayoutFinancialTransactionsArgs = {
  cursor?: Maybe<FinancialTransactionWhereUniqueInput>;
  distinct?: Maybe<FinancialTransactionScalarFieldEnum>;
  orderBy?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FinancialTransactionWhereInput>;
};


export type StripePayoutStripeDisputesArgs = {
  cursor?: Maybe<StripeDisputeWhereUniqueInput>;
  distinct?: Maybe<StripeDisputeScalarFieldEnum>;
  orderBy?: Maybe<StripeDisputeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeDisputeWhereInput>;
};


export type StripePayoutStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type StripePayoutStripeRefundsArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};

export type StripePayoutAccount = {
  __typename?: 'StripePayoutAccount';
  _count: StripePayoutAccountCountOutputType;
  bankName?: Maybe<Scalars['String']>;
  clinics: Array<Clinic>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  default: Scalars['Boolean'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripePayouts: Array<StripePayout>;
  type: StripePayoutAccountType;
  updatedAt: Scalars['DateTime'];
};


export type StripePayoutAccountClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type StripePayoutAccountStripePayoutsArgs = {
  cursor?: Maybe<StripePayoutWhereUniqueInput>;
  distinct?: Maybe<StripePayoutScalarFieldEnum>;
  orderBy?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePayoutWhereInput>;
};

export type StripePayoutAccountCountAggregateOutputType = {
  __typename?: 'StripePayoutAccountCountAggregateOutputType';
  _all: Scalars['Int'];
  bankName: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  last4: Scalars['Int'];
  stripeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripePayoutAccountCountOrderByAggregateInput = {
  bankName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutAccountCountOutputType = {
  __typename?: 'StripePayoutAccountCountOutputType';
  clinics: Scalars['Int'];
  stripePayouts: Scalars['Int'];
};

export type StripePayoutAccountCreateInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountCreateManyInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
};

export type StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput = {
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
};

export type StripePayoutAccountCreateOrConnectWithoutClinicsInput = {
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
  where: StripePayoutAccountWhereUniqueInput;
};

export type StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput = {
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
  where: StripePayoutAccountWhereUniqueInput;
};

export type StripePayoutAccountCreateWithoutClinicsInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripePayouts?: Maybe<StripePayoutCreateNestedManyWithoutStripePayoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountCreateWithoutStripePayoutsInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutStripePayoutAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountListRelationFilter = {
  every?: Maybe<StripePayoutAccountWhereInput>;
  none?: Maybe<StripePayoutAccountWhereInput>;
  some?: Maybe<StripePayoutAccountWhereInput>;
};

export type StripePayoutAccountMaxAggregateOutputType = {
  __typename?: 'StripePayoutAccountMaxAggregateOutputType';
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountMaxOrderByAggregateInput = {
  bankName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutAccountMinAggregateOutputType = {
  __typename?: 'StripePayoutAccountMinAggregateOutputType';
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountMinOrderByAggregateInput = {
  bankName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutAccountOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutAccountOrderByRelevanceFieldEnum {
  BankName = 'bankName',
  Id = 'id',
  Last4 = 'last4',
  StripeId = 'stripeId'
}

export type StripePayoutAccountOrderByRelevanceInput = {
  fields: Array<StripePayoutAccountOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripePayoutAccountOrderByWithAggregationInput = {
  _count?: Maybe<StripePayoutAccountCountOrderByAggregateInput>;
  _max?: Maybe<StripePayoutAccountMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutAccountMinOrderByAggregateInput>;
  bankName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripePayoutAccountOrderByRelevanceInput>;
  bankName?: Maybe<SortOrder>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  last4?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePayouts?: Maybe<StripePayoutOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutAccountRelationFilter = {
  is?: Maybe<StripePayoutAccountWhereInput>;
  isNot?: Maybe<StripePayoutAccountWhereInput>;
};

export enum StripePayoutAccountScalarFieldEnum {
  BankName = 'bankName',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDeleted = 'isDeleted',
  Last4 = 'last4',
  StripeId = 'stripeId',
  UpdatedAt = 'updatedAt'
}

export type StripePayoutAccountScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  bankName?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  last4?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePayoutAccountScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutAccountScalarWhereWithAggregatesInput>>;
  bankName?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  last4?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripePayoutAccountType {
  BankAccount = 'bank_account',
  Card = 'card'
}

export type StripePayoutAccountUncheckedCreateInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
};

export type StripePayoutAccountUncheckedCreateWithoutClinicsInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripePayouts?: Maybe<StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStripePayoutAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedUpdateInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedUpdateManyInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedUpdateWithoutClinicsInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStripePayoutAccountsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUpdateInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUpdateManyMutationInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput = {
  data: StripePayoutAccountUncheckedUpdateManyWithoutStripePayoutAccountsInput;
  where: StripePayoutAccountScalarWhereInput;
};

export type StripePayoutAccountUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutAccountCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<StripePayoutAccountCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePayoutAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  set?: Maybe<Array<StripePayoutAccountWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<StripePayoutAccountUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput = {
  connect?: Maybe<StripePayoutAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutAccountCreateOrConnectWithoutStripePayoutsInput>;
  create?: Maybe<StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput>;
  upsert?: Maybe<StripePayoutAccountUpsertWithoutStripePayoutsInput>;
};

export type StripePayoutAccountUpdateWithWhereUniqueWithoutClinicsInput = {
  data: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
  where: StripePayoutAccountWhereUniqueInput;
};

export type StripePayoutAccountUpdateWithoutClinicsInput = {
  bankName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayouts?: Maybe<StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUpdateWithoutStripePayoutsInput = {
  bankName?: Maybe<Scalars['String']>;
  clinics?: Maybe<ClinicUpdateManyWithoutStripePayoutAccountsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutAccountUpsertWithWhereUniqueWithoutClinicsInput = {
  create: StripePayoutAccountUncheckedCreateWithoutClinicsInput;
  update: StripePayoutAccountUncheckedUpdateWithoutClinicsInput;
  where: StripePayoutAccountWhereUniqueInput;
};

export type StripePayoutAccountUpsertWithoutStripePayoutsInput = {
  create: StripePayoutAccountUncheckedCreateWithoutStripePayoutsInput;
  update: StripePayoutAccountUncheckedUpdateWithoutStripePayoutsInput;
};

export type StripePayoutAccountWhereInput = {
  AND?: Maybe<Array<StripePayoutAccountWhereInput>>;
  NOT?: Maybe<Array<StripePayoutAccountWhereInput>>;
  OR?: Maybe<Array<StripePayoutAccountWhereInput>>;
  bankName?: Maybe<StringNullableFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  last4?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePayouts?: Maybe<StripePayoutListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePayoutAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripePayoutAvgAggregateOutputType = {
  __typename?: 'StripePayoutAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
};

export type StripePayoutAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutCountAggregateOutputType = {
  __typename?: 'StripePayoutCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  arrivalDate: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  initiatedAt: Scalars['Int'];
  payoutBatchingPeriodId: Scalars['Int'];
  statementDescriptor: Scalars['Int'];
  status: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripePayoutAccountId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripePayoutCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutCountOutputType = {
  __typename?: 'StripePayoutCountOutputType';
  financialTransactions: Scalars['Int'];
  stripeDisputes: Scalars['Int'];
  stripePaymentIntents: Scalars['Int'];
  stripeRefunds: Scalars['Int'];
};

export type StripePayoutCreateInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateManyInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeId: Scalars['String'];
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateManyStripePayoutAccountInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateManyStripePayoutAccountInputEnvelope = {
  data: Array<StripePayoutCreateManyStripePayoutAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripePayoutCreateNestedManyWithoutStripePayoutAccountInput = {
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
};

export type StripePayoutCreateNestedOneWithoutFinancialTransactionsInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
};

export type StripePayoutCreateNestedOneWithoutPayoutBatchingPeriodInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeDisputesInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
};

export type StripePayoutCreateNestedOneWithoutStripePaymentIntentsInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
};

export type StripePayoutCreateNestedOneWithoutStripeRefundsInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
};

export type StripePayoutCreateOrConnectWithoutFinancialTransactionsInput = {
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput = {
  create: StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateOrConnectWithoutStripeDisputesInput = {
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput = {
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateOrConnectWithoutStripePayoutAccountInput = {
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateOrConnectWithoutStripeRefundsInput = {
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutCreateWithoutFinancialTransactionsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateWithoutPayoutBatchingPeriodInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateWithoutStripeDisputesInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateWithoutStripePaymentIntentsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateWithoutStripePayoutAccountInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutCreateWithoutStripeRefundsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodCreateNestedOneWithoutStripePayoutInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountCreateNestedOneWithoutStripePayoutsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutListRelationFilter = {
  every?: Maybe<StripePayoutWhereInput>;
  none?: Maybe<StripePayoutWhereInput>;
  some?: Maybe<StripePayoutWhereInput>;
};

export type StripePayoutMaxAggregateOutputType = {
  __typename?: 'StripePayoutMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutMinAggregateOutputType = {
  __typename?: 'StripePayoutMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripePayoutOrderByRelevanceFieldEnum {
  Id = 'id',
  PayoutBatchingPeriodId = 'payoutBatchingPeriodId',
  StatementDescriptor = 'statementDescriptor',
  StripeId = 'stripeId',
  StripePayoutAccountId = 'stripePayoutAccountId'
}

export type StripePayoutOrderByRelevanceInput = {
  fields: Array<StripePayoutOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripePayoutOrderByWithAggregationInput = {
  _avg?: Maybe<StripePayoutAvgOrderByAggregateInput>;
  _count?: Maybe<StripePayoutCountOrderByAggregateInput>;
  _max?: Maybe<StripePayoutMaxOrderByAggregateInput>;
  _min?: Maybe<StripePayoutMinOrderByAggregateInput>;
  _sum?: Maybe<StripePayoutSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripePayoutOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  arrivalDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  financialTransactions?: Maybe<FinancialTransactionOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  initiatedAt?: Maybe<SortOrder>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  payoutBatchingPeriodId?: Maybe<SortOrder>;
  statementDescriptor?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  stripeDisputes?: Maybe<StripeDisputeOrderByRelationAggregateInput>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutAccountId?: Maybe<SortOrder>;
  stripeRefunds?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripePayoutRelationFilter = {
  is?: Maybe<StripePayoutWhereInput>;
  isNot?: Maybe<StripePayoutWhereInput>;
};

export enum StripePayoutScalarFieldEnum {
  Amount = 'amount',
  ArrivalDate = 'arrivalDate',
  CreatedAt = 'createdAt',
  Id = 'id',
  InitiatedAt = 'initiatedAt',
  PayoutBatchingPeriodId = 'payoutBatchingPeriodId',
  StatementDescriptor = 'statementDescriptor',
  Status = 'status',
  StripeId = 'stripeId',
  StripePayoutAccountId = 'stripePayoutAccountId',
  UpdatedAt = 'updatedAt'
}

export type StripePayoutScalarWhereInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  payoutBatchingPeriodId?: Maybe<StringNullableFilter>;
  statementDescriptor?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePayoutScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripePayoutScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  arrivalDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  initiatedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  payoutBatchingPeriodId?: Maybe<StringNullableWithAggregatesFilter>;
  statementDescriptor?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripePayoutStatusWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripePayoutAccountId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StripePayoutSchedule = {
  __typename?: 'StripePayoutSchedule';
  automatic: Scalars['Boolean'];
  daysDelayed: Scalars['Int'];
  interval: Scalars['String'];
};

export enum StripePayoutStatus {
  Canceled = 'Canceled',
  Failed = 'Failed',
  InTransit = 'In_Transit',
  Paid = 'Paid',
  Pending = 'Pending'
}

export type StripePayoutSumAggregateOutputType = {
  __typename?: 'StripePayoutSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
};

export type StripePayoutSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
};

export type StripePayoutUncheckedCreateInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateNestedManyWithoutStripePayoutAccountInput = {
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
};

export type StripePayoutUncheckedCreateNestedOneWithoutPayoutBatchingPeriodInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutUncheckedCreateWithoutFinancialTransactionsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateWithoutStripeDisputesInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateWithoutStripePayoutAccountInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedCreateWithoutStripeRefundsInput = {
  amount: Scalars['Int'];
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedCreateNestedManyWithoutStripePayoutInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
  stripeDisputes?: Maybe<StripeDisputeUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripeId: Scalars['String'];
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripePayoutInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutAccountNestedInput = {
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
};

export type StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateOneWithoutPayoutBatchingPeriodNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutStripeDisputesInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUncheckedUpdateWithoutStripeRefundsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccountId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput = {
  data: StripePayoutUncheckedUpdateManyWithoutStripePayoutsInput;
  where: StripePayoutScalarWhereInput;
};

export type StripePayoutUpdateManyWithoutStripePayoutAccountNestedInput = {
  connect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripePayoutCreateOrConnectWithoutStripePayoutAccountInput>>;
  create?: Maybe<Array<StripePayoutCreateWithoutStripePayoutAccountInput>>;
  createMany?: Maybe<StripePayoutCreateManyStripePayoutAccountInputEnvelope>;
  delete?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripePayoutScalarWhereInput>>;
  disconnect?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  set?: Maybe<Array<StripePayoutWhereUniqueInput>>;
  update?: Maybe<Array<StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput>>;
  updateMany?: Maybe<Array<StripePayoutUpdateManyWithWhereWithoutStripePayoutAccountInput>>;
  upsert?: Maybe<Array<StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput>>;
};

export type StripePayoutUpdateOneWithoutFinancialTransactionsNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutFinancialTransactionsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutFinancialTransactionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutFinancialTransactionsInput>;
};

export type StripePayoutUpdateOneWithoutPayoutBatchingPeriodNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutPayoutBatchingPeriodInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutPayoutBatchingPeriodInput>;
};

export type StripePayoutUpdateOneWithoutStripeDisputesNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeDisputesInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeDisputesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeDisputesInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeDisputesInput>;
};

export type StripePayoutUpdateOneWithoutStripePaymentIntentsNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripePaymentIntentsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripePaymentIntentsInput>;
};

export type StripePayoutUpdateOneWithoutStripeRefundsNestedInput = {
  connect?: Maybe<StripePayoutWhereUniqueInput>;
  connectOrCreate?: Maybe<StripePayoutCreateOrConnectWithoutStripeRefundsInput>;
  create?: Maybe<StripePayoutUncheckedCreateWithoutStripeRefundsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripePayoutUncheckedUpdateWithoutStripeRefundsInput>;
  upsert?: Maybe<StripePayoutUpsertWithoutStripeRefundsInput>;
};

export type StripePayoutUpdateWithWhereUniqueWithoutStripePayoutAccountInput = {
  data: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutUpdateWithoutFinancialTransactionsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateWithoutPayoutBatchingPeriodInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateWithoutStripeDisputesInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateWithoutStripePaymentIntentsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateWithoutStripePayoutAccountInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripeRefunds?: Maybe<StripeRefundUpdateManyWithoutStripePayoutNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpdateWithoutStripeRefundsInput = {
  amount?: Maybe<Scalars['Int']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransactions?: Maybe<FinancialTransactionUpdateManyWithoutStripePayoutNestedInput>;
  id?: Maybe<Scalars['String']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodUpdateOneWithoutStripePayoutNestedInput>;
  statementDescriptor?: Maybe<Scalars['String']>;
  status?: Maybe<StripePayoutStatus>;
  stripeDisputes?: Maybe<StripeDisputeUpdateManyWithoutStripePayoutNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutStripePayoutNestedInput>;
  stripePayoutAccount?: Maybe<StripePayoutAccountUpdateOneWithoutStripePayoutsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripePayoutUpsertWithWhereUniqueWithoutStripePayoutAccountInput = {
  create: StripePayoutUncheckedCreateWithoutStripePayoutAccountInput;
  update: StripePayoutUncheckedUpdateWithoutStripePayoutAccountInput;
  where: StripePayoutWhereUniqueInput;
};

export type StripePayoutUpsertWithoutFinancialTransactionsInput = {
  create: StripePayoutUncheckedCreateWithoutFinancialTransactionsInput;
  update: StripePayoutUncheckedUpdateWithoutFinancialTransactionsInput;
};

export type StripePayoutUpsertWithoutPayoutBatchingPeriodInput = {
  create: StripePayoutUncheckedCreateWithoutPayoutBatchingPeriodInput;
  update: StripePayoutUncheckedUpdateWithoutPayoutBatchingPeriodInput;
};

export type StripePayoutUpsertWithoutStripeDisputesInput = {
  create: StripePayoutUncheckedCreateWithoutStripeDisputesInput;
  update: StripePayoutUncheckedUpdateWithoutStripeDisputesInput;
};

export type StripePayoutUpsertWithoutStripePaymentIntentsInput = {
  create: StripePayoutUncheckedCreateWithoutStripePaymentIntentsInput;
  update: StripePayoutUncheckedUpdateWithoutStripePaymentIntentsInput;
};

export type StripePayoutUpsertWithoutStripeRefundsInput = {
  create: StripePayoutUncheckedCreateWithoutStripeRefundsInput;
  update: StripePayoutUncheckedUpdateWithoutStripeRefundsInput;
};

export type StripePayoutWhereInput = {
  AND?: Maybe<Array<StripePayoutWhereInput>>;
  NOT?: Maybe<Array<StripePayoutWhereInput>>;
  OR?: Maybe<Array<StripePayoutWhereInput>>;
  amount?: Maybe<IntFilter>;
  arrivalDate?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  financialTransactions?: Maybe<FinancialTransactionListRelationFilter>;
  id?: Maybe<StringFilter>;
  initiatedAt?: Maybe<DateTimeNullableFilter>;
  payoutBatchingPeriod?: Maybe<PayoutBatchingPeriodWhereInput>;
  payoutBatchingPeriodId?: Maybe<StringNullableFilter>;
  statementDescriptor?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStripePayoutStatusFilter>;
  stripeDisputes?: Maybe<StripeDisputeListRelationFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  stripePayoutAccount?: Maybe<StripePayoutAccountWhereInput>;
  stripePayoutAccountId?: Maybe<StringNullableFilter>;
  stripeRefunds?: Maybe<StripeRefundListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripePayoutWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  payoutBatchingPeriodId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeReceipt = {
  __typename?: 'StripeReceipt';
  _count: StripeReceiptCountOutputType;
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dedicatedFileName?: Maybe<Scalars['String']>;
  history: Array<StripeReceiptHistory>;
  id: Scalars['String'];
  payment?: Maybe<StripePaymentIntent>;
  refund?: Maybe<StripeRefund>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};


export type StripeReceiptHistoryArgs = {
  cursor?: Maybe<StripeReceiptHistoryWhereUniqueInput>;
  distinct?: Maybe<StripeReceiptHistoryScalarFieldEnum>;
  orderBy?: Maybe<StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type StripeReceiptCountAggregateOutputType = {
  __typename?: 'StripeReceiptCountAggregateOutputType';
  _all: Scalars['Int'];
  accountType: Scalars['Int'];
  applicationCryptogram: Scalars['Int'];
  applicationName: Scalars['Int'];
  authorizationCode: Scalars['Int'];
  createdAt: Scalars['Int'];
  dedicatedFileName: Scalars['Int'];
  id: Scalars['Int'];
  stripeId: Scalars['Int'];
  terminalVerificationResults: Scalars['Int'];
  transactionStatusInformation: Scalars['Int'];
};

export type StripeReceiptCountOrderByAggregateInput = {
  accountType?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export type StripeReceiptCountOutputType = {
  __typename?: 'StripeReceiptCountOutputType';
  history: Scalars['Int'];
};

export type StripeReceiptCreateInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateManyInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateNestedOneWithoutHistoryInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
};

export type StripeReceiptCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
};

export type StripeReceiptCreateNestedOneWithoutRefundInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
};

export type StripeReceiptCreateOrConnectWithoutHistoryInput = {
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
  where: StripeReceiptWhereUniqueInput;
};

export type StripeReceiptCreateOrConnectWithoutPaymentInput = {
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
  where: StripeReceiptWhereUniqueInput;
};

export type StripeReceiptCreateOrConnectWithoutRefundInput = {
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
  where: StripeReceiptWhereUniqueInput;
};

export type StripeReceiptCreateWithoutHistoryInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateWithoutPaymentInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  refund?: Maybe<StripeRefundCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptCreateWithoutRefundInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistory = {
  __typename?: 'StripeReceiptHistory';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  medium: StripeReceiptMedium;
  receipt: StripeReceipt;
  stripeReceiptId: Scalars['String'];
};

export type StripeReceiptHistoryCountAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  medium: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
};

export type StripeReceiptHistoryCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  receipt: StripeReceiptCreateNestedOneWithoutHistoryInput;
};

export type StripeReceiptHistoryCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId: Scalars['String'];
};

export type StripeReceiptHistoryCreateManyReceiptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryCreateManyReceiptInputEnvelope = {
  data: Array<StripeReceiptHistoryCreateManyReceiptInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeReceiptHistoryCreateNestedManyWithoutReceiptInput = {
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
};

export type StripeReceiptHistoryCreateOrConnectWithoutReceiptInput = {
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type StripeReceiptHistoryCreateWithoutReceiptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryListRelationFilter = {
  every?: Maybe<StripeReceiptHistoryWhereInput>;
  none?: Maybe<StripeReceiptHistoryWhereInput>;
  some?: Maybe<StripeReceiptHistoryWhereInput>;
};

export type StripeReceiptHistoryMaxAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistoryMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryMinAggregateOutputType = {
  __typename?: 'StripeReceiptHistoryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistoryMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeReceiptHistoryOrderByRelevanceFieldEnum {
  Id = 'id',
  StripeReceiptId = 'stripeReceiptId'
}

export type StripeReceiptHistoryOrderByRelevanceInput = {
  fields: Array<StripeReceiptHistoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeReceiptHistoryOrderByWithAggregationInput = {
  _count?: Maybe<StripeReceiptHistoryCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptHistoryMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptHistoryMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
};

export type StripeReceiptHistoryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeReceiptHistoryOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medium?: Maybe<SortOrder>;
  receipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceiptId?: Maybe<SortOrder>;
};

export enum StripeReceiptHistoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Medium = 'medium',
  StripeReceiptId = 'stripeReceiptId'
}

export type StripeReceiptHistoryScalarWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
  stripeReceiptId?: Maybe<StringFilter>;
};

export type StripeReceiptHistoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  medium?: Maybe<EnumStripeReceiptMediumWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringWithAggregatesFilter>;
};

export type StripeReceiptHistoryUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId: Scalars['String'];
};

export type StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput = {
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
};

export type StripeReceiptHistoryUncheckedCreateWithoutReceiptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistoryUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput = {
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
};

export type StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
  receipt?: Maybe<StripeReceiptUpdateOneRequiredWithoutHistoryNestedInput>;
};

export type StripeReceiptHistoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput = {
  data: StripeReceiptHistoryUncheckedUpdateManyWithoutHistoryInput;
  where: StripeReceiptHistoryScalarWhereInput;
};

export type StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput = {
  connect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeReceiptHistoryCreateOrConnectWithoutReceiptInput>>;
  create?: Maybe<Array<StripeReceiptHistoryCreateWithoutReceiptInput>>;
  createMany?: Maybe<StripeReceiptHistoryCreateManyReceiptInputEnvelope>;
  delete?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeReceiptHistoryScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  set?: Maybe<Array<StripeReceiptHistoryWhereUniqueInput>>;
  update?: Maybe<Array<StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput>>;
  updateMany?: Maybe<Array<StripeReceiptHistoryUpdateManyWithWhereWithoutReceiptInput>>;
  upsert?: Maybe<Array<StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput>>;
};

export type StripeReceiptHistoryUpdateWithWhereUniqueWithoutReceiptInput = {
  data: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type StripeReceiptHistoryUpdateWithoutReceiptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  medium?: Maybe<StripeReceiptMedium>;
};

export type StripeReceiptHistoryUpsertWithWhereUniqueWithoutReceiptInput = {
  create: StripeReceiptHistoryUncheckedCreateWithoutReceiptInput;
  update: StripeReceiptHistoryUncheckedUpdateWithoutReceiptInput;
  where: StripeReceiptHistoryWhereUniqueInput;
};

export type StripeReceiptHistoryWhereInput = {
  AND?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  OR?: Maybe<Array<StripeReceiptHistoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  medium?: Maybe<EnumStripeReceiptMediumFilter>;
  receipt?: Maybe<StripeReceiptWhereInput>;
  stripeReceiptId?: Maybe<StringFilter>;
};

export type StripeReceiptHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxAggregateOutputType = {
  __typename?: 'StripeReceiptMaxAggregateOutputType';
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMaxOrderByAggregateInput = {
  accountType?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptMedium {
  Email = 'Email',
  Printed = 'Printed'
}

export type StripeReceiptMinAggregateOutputType = {
  __typename?: 'StripeReceiptMinAggregateOutputType';
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptMinOrderByAggregateInput = {
  accountType?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export enum StripeReceiptOrderByRelevanceFieldEnum {
  AccountType = 'accountType',
  ApplicationCryptogram = 'applicationCryptogram',
  ApplicationName = 'applicationName',
  AuthorizationCode = 'authorizationCode',
  DedicatedFileName = 'dedicatedFileName',
  Id = 'id',
  StripeId = 'stripeId',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation'
}

export type StripeReceiptOrderByRelevanceInput = {
  fields: Array<StripeReceiptOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeReceiptOrderByWithAggregationInput = {
  _count?: Maybe<StripeReceiptCountOrderByAggregateInput>;
  _max?: Maybe<StripeReceiptMaxOrderByAggregateInput>;
  _min?: Maybe<StripeReceiptMinOrderByAggregateInput>;
  accountType?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export type StripeReceiptOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeReceiptOrderByRelevanceInput>;
  accountType?: Maybe<SortOrder>;
  applicationCryptogram?: Maybe<SortOrder>;
  applicationName?: Maybe<SortOrder>;
  authorizationCode?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dedicatedFileName?: Maybe<SortOrder>;
  history?: Maybe<StripeReceiptHistoryOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  payment?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  refund?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  stripeId?: Maybe<SortOrder>;
  terminalVerificationResults?: Maybe<SortOrder>;
  transactionStatusInformation?: Maybe<SortOrder>;
};

export type StripeReceiptRelationFilter = {
  is?: Maybe<StripeReceiptWhereInput>;
  isNot?: Maybe<StripeReceiptWhereInput>;
};

export enum StripeReceiptScalarFieldEnum {
  AccountType = 'accountType',
  ApplicationCryptogram = 'applicationCryptogram',
  ApplicationName = 'applicationName',
  AuthorizationCode = 'authorizationCode',
  CreatedAt = 'createdAt',
  DedicatedFileName = 'dedicatedFileName',
  Id = 'id',
  StripeId = 'stripeId',
  TerminalVerificationResults = 'terminalVerificationResults',
  TransactionStatusInformation = 'transactionStatusInformation'
}

export type StripeReceiptScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeReceiptScalarWhereWithAggregatesInput>>;
  accountType?: Maybe<StringNullableWithAggregatesFilter>;
  applicationCryptogram?: Maybe<StringNullableWithAggregatesFilter>;
  applicationName?: Maybe<StringNullableWithAggregatesFilter>;
  authorizationCode?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  dedicatedFileName?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringNullableWithAggregatesFilter>;
  terminalVerificationResults?: Maybe<StringNullableWithAggregatesFilter>;
  transactionStatusInformation?: Maybe<StringNullableWithAggregatesFilter>;
};

export type StripeReceiptUncheckedCreateInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutHistoryInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutPaymentInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  refund?: Maybe<StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedCreateWithoutRefundInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedCreateNestedManyWithoutReceiptInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedCreateNestedOneWithoutStripeReceiptInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateManyInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutHistoryInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutPaymentInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  refund?: Maybe<StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUncheckedUpdateWithoutRefundInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUncheckedUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUncheckedUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateManyMutationInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateOneRequiredWithoutHistoryNestedInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutHistoryInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutHistoryInput>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutHistoryInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutHistoryInput>;
};

export type StripeReceiptUpdateOneWithoutPaymentNestedInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutPaymentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutPaymentInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutPaymentInput>;
};

export type StripeReceiptUpdateOneWithoutRefundNestedInput = {
  connect?: Maybe<StripeReceiptWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeReceiptCreateOrConnectWithoutRefundInput>;
  create?: Maybe<StripeReceiptUncheckedCreateWithoutRefundInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeReceiptUncheckedUpdateWithoutRefundInput>;
  upsert?: Maybe<StripeReceiptUpsertWithoutRefundInput>;
};

export type StripeReceiptUpdateWithoutHistoryInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateWithoutPaymentInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  refund?: Maybe<StripeRefundUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpdateWithoutRefundInput = {
  accountType?: Maybe<Scalars['String']>;
  applicationCryptogram?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dedicatedFileName?: Maybe<Scalars['String']>;
  history?: Maybe<StripeReceiptHistoryUpdateManyWithoutReceiptNestedInput>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<StripePaymentIntentUpdateOneWithoutStripeReceiptNestedInput>;
  stripeId?: Maybe<Scalars['String']>;
  terminalVerificationResults?: Maybe<Scalars['String']>;
  transactionStatusInformation?: Maybe<Scalars['String']>;
};

export type StripeReceiptUpsertWithoutHistoryInput = {
  create: StripeReceiptUncheckedCreateWithoutHistoryInput;
  update: StripeReceiptUncheckedUpdateWithoutHistoryInput;
};

export type StripeReceiptUpsertWithoutPaymentInput = {
  create: StripeReceiptUncheckedCreateWithoutPaymentInput;
  update: StripeReceiptUncheckedUpdateWithoutPaymentInput;
};

export type StripeReceiptUpsertWithoutRefundInput = {
  create: StripeReceiptUncheckedCreateWithoutRefundInput;
  update: StripeReceiptUncheckedUpdateWithoutRefundInput;
};

export type StripeReceiptWhereInput = {
  AND?: Maybe<Array<StripeReceiptWhereInput>>;
  NOT?: Maybe<Array<StripeReceiptWhereInput>>;
  OR?: Maybe<Array<StripeReceiptWhereInput>>;
  accountType?: Maybe<StringNullableFilter>;
  applicationCryptogram?: Maybe<StringNullableFilter>;
  applicationName?: Maybe<StringNullableFilter>;
  authorizationCode?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dedicatedFileName?: Maybe<StringNullableFilter>;
  history?: Maybe<StripeReceiptHistoryListRelationFilter>;
  id?: Maybe<StringFilter>;
  payment?: Maybe<StripePaymentIntentWhereInput>;
  refund?: Maybe<StripeRefundWhereInput>;
  stripeId?: Maybe<StringNullableFilter>;
  terminalVerificationResults?: Maybe<StringNullableFilter>;
  transactionStatusInformation?: Maybe<StringNullableFilter>;
};

export type StripeReceiptWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeRefund = {
  __typename?: 'StripeRefund';
  _count: StripeRefundCountOutputType;
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  financialTransaction?: Maybe<FinancialTransaction>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  issuedBy?: Maybe<User>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs: Array<PushNotificationLog>;
  reason: RefundReason;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayout>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceipt?: Maybe<StripeReceipt>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


export type StripeRefundPushNotificationLogsArgs = {
  cursor?: Maybe<PushNotificationLogWhereUniqueInput>;
  distinct?: Maybe<PushNotificationLogScalarFieldEnum>;
  orderBy?: Maybe<PushNotificationLogOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PushNotificationLogWhereInput>;
};

export type StripeRefundAvgAggregateOutputType = {
  __typename?: 'StripeRefundAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  clientFeeRefunded?: Maybe<Scalars['Float']>;
  clinicFeeRefunded?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type StripeRefundAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundCountAggregateOutputType = {
  __typename?: 'StripeRefundCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  clientFeeRefunded: Scalars['Int'];
  clinicFeeRefunded: Scalars['Int'];
  createdAt: Scalars['Int'];
  fundsAvailableAt: Scalars['Int'];
  id: Scalars['Int'];
  issuedByUserId: Scalars['Int'];
  reason: Scalars['Int'];
  reasonOther: Scalars['Int'];
  stripeId: Scalars['Int'];
  stripePaymentIntentId: Scalars['Int'];
  stripePayoutId: Scalars['Int'];
  stripeReceiptId: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeRefundCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeRefundCountOutputType = {
  __typename?: 'StripeRefundCountOutputType';
  pushNotificationLogs: Scalars['Int'];
};

export type StripeRefundCreateInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyIssuedByInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyIssuedByInputEnvelope = {
  data: Array<StripeRefundCreateManyIssuedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePaymentIntentInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyStripePaymentIntentInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePaymentIntentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateManyStripePayoutInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateManyStripePayoutInputEnvelope = {
  data: Array<StripeRefundCreateManyStripePayoutInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeRefundCreateNestedManyWithoutIssuedByInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
};

export type StripeRefundCreateNestedManyWithoutStripePaymentIntentInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
};

export type StripeRefundCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
};

export type StripeRefundCreateNestedOneWithoutFinancialTransactionInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
};

export type StripeRefundCreateNestedOneWithoutPushNotificationLogsInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutPushNotificationLogsInput>;
};

export type StripeRefundCreateNestedOneWithoutStripeReceiptInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
};

export type StripeRefundCreateOrConnectWithoutFinancialTransactionInput = {
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateOrConnectWithoutIssuedByInput = {
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateOrConnectWithoutPushNotificationLogsInput = {
  create: StripeRefundUncheckedCreateWithoutPushNotificationLogsInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateOrConnectWithoutStripePaymentIntentInput = {
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateOrConnectWithoutStripePayoutInput = {
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateOrConnectWithoutStripeReceiptInput = {
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateWithoutIssuedByInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateWithoutPushNotificationLogsInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateWithoutStripePaymentIntentInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateWithoutStripePayoutInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripeReceipt?: Maybe<StripeReceiptCreateNestedOneWithoutRefundInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundCreateWithoutStripeReceiptInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserCreateNestedOneWithoutRefundsIssuedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntentCreateNestedOneWithoutStripeRefundsInput>;
  stripePayout?: Maybe<StripePayoutCreateNestedOneWithoutStripeRefundsInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundListRelationFilter = {
  every?: Maybe<StripeRefundWhereInput>;
  none?: Maybe<StripeRefundWhereInput>;
  some?: Maybe<StripeRefundWhereInput>;
};

export type StripeRefundMaxAggregateOutputType = {
  __typename?: 'StripeRefundMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeRefundMinAggregateOutputType = {
  __typename?: 'StripeRefundMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeRefundOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeRefundOrderByRelevanceFieldEnum {
  Id = 'id',
  IssuedByUserId = 'issuedByUserId',
  ReasonOther = 'reasonOther',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeReceiptId = 'stripeReceiptId'
}

export type StripeRefundOrderByRelevanceInput = {
  fields: Array<StripeRefundOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeRefundOrderByWithAggregationInput = {
  _avg?: Maybe<StripeRefundAvgOrderByAggregateInput>;
  _count?: Maybe<StripeRefundCountOrderByAggregateInput>;
  _max?: Maybe<StripeRefundMaxOrderByAggregateInput>;
  _min?: Maybe<StripeRefundMinOrderByAggregateInput>;
  _sum?: Maybe<StripeRefundSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuedByUserId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceiptId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeRefundOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeRefundOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  financialTransaction?: Maybe<FinancialTransactionOrderByWithRelationAndSearchRelevanceInput>;
  fundsAvailableAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  issuedByUserId?: Maybe<SortOrder>;
  pushNotificationLogs?: Maybe<PushNotificationLogOrderByRelationAggregateInput>;
  reason?: Maybe<SortOrder>;
  reasonOther?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentIntent?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  stripePaymentIntentId?: Maybe<SortOrder>;
  stripePayout?: Maybe<StripePayoutOrderByWithRelationAndSearchRelevanceInput>;
  stripePayoutId?: Maybe<SortOrder>;
  stripeReceipt?: Maybe<StripeReceiptOrderByWithRelationAndSearchRelevanceInput>;
  stripeReceiptId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum StripeRefundReason {
  Duplicate = 'Duplicate',
  Fraudulent = 'Fraudulent',
  Other = 'Other',
  PartialRefund = 'Partial_Refund',
  RequestedByCustomer = 'Requested_By_Customer'
}

export type StripeRefundRelationFilter = {
  is?: Maybe<StripeRefundWhereInput>;
  isNot?: Maybe<StripeRefundWhereInput>;
};

export enum StripeRefundScalarFieldEnum {
  Amount = 'amount',
  ClientFeeRefunded = 'clientFeeRefunded',
  ClinicFeeRefunded = 'clinicFeeRefunded',
  CreatedAt = 'createdAt',
  FundsAvailableAt = 'fundsAvailableAt',
  Id = 'id',
  IssuedByUserId = 'issuedByUserId',
  Reason = 'reason',
  ReasonOther = 'reasonOther',
  StripeId = 'stripeId',
  StripePaymentIntentId = 'stripePaymentIntentId',
  StripePayoutId = 'stripePayoutId',
  StripeReceiptId = 'stripeReceiptId',
  Total = 'total',
  UpdatedAt = 'updatedAt'
}

export type StripeRefundScalarWhereInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereInput>>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  issuedByUserId?: Maybe<StringNullableFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeRefundScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeRefundScalarWhereWithAggregatesInput>>;
  amount?: Maybe<IntWithAggregatesFilter>;
  clientFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  clinicFeeRefunded?: Maybe<IntNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  fundsAvailableAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  issuedByUserId?: Maybe<StringNullableWithAggregatesFilter>;
  reason?: Maybe<EnumRefundReasonWithAggregatesFilter>;
  reasonOther?: Maybe<StringNullableWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  stripePaymentIntentId?: Maybe<StringNullableWithAggregatesFilter>;
  stripePayoutId?: Maybe<StringNullableWithAggregatesFilter>;
  stripeReceiptId?: Maybe<StringNullableWithAggregatesFilter>;
  total?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StripeRefundSumAggregateOutputType = {
  __typename?: 'StripeRefundSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripeRefundSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  clientFeeRefunded?: Maybe<SortOrder>;
  clinicFeeRefunded?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
};

export type StripeRefundUncheckedCreateInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePaymentIntentInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
};

export type StripeRefundUncheckedCreateNestedManyWithoutStripePayoutInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
};

export type StripeRefundUncheckedCreateNestedOneWithoutStripeReceiptInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
};

export type StripeRefundUncheckedCreateWithoutFinancialTransactionInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateWithoutIssuedByInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateWithoutPushNotificationLogsInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateWithoutStripePaymentIntentInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateWithoutStripePayoutInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedCreateWithoutStripeReceiptInput = {
  amount: Scalars['Int'];
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedCreateNestedOneWithoutStripeRefundInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedCreateNestedManyWithoutStripeRefundInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateOneWithoutStripeReceiptNestedInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
};

export type StripeRefundUncheckedUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateWithoutIssuedByInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateWithoutStripePayoutInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUncheckedUpdateWithoutStripeReceiptInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUncheckedUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedByUserId?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUncheckedUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  stripePayoutId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateManyWithWhereWithoutIssuedByInput = {
  data: StripeRefundUncheckedUpdateManyWithoutRefundsIssuedInput;
  where: StripeRefundScalarWhereInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput = {
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
  where: StripeRefundScalarWhereInput;
};

export type StripeRefundUpdateManyWithWhereWithoutStripePayoutInput = {
  data: StripeRefundUncheckedUpdateManyWithoutStripeRefundsInput;
  where: StripeRefundScalarWhereInput;
};

export type StripeRefundUpdateManyWithoutIssuedByNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutIssuedByInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutIssuedByInput>>;
  createMany?: Maybe<StripeRefundCreateManyIssuedByInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutIssuedByInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput>>;
};

export type StripeRefundUpdateManyWithoutStripePaymentIntentNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePaymentIntentInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePaymentIntentInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePaymentIntentInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePaymentIntentInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput>>;
};

export type StripeRefundUpdateManyWithoutStripePayoutNestedInput = {
  connect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeRefundCreateOrConnectWithoutStripePayoutInput>>;
  create?: Maybe<Array<StripeRefundCreateWithoutStripePayoutInput>>;
  createMany?: Maybe<StripeRefundCreateManyStripePayoutInputEnvelope>;
  delete?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeRefundScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  set?: Maybe<Array<StripeRefundWhereUniqueInput>>;
  update?: Maybe<Array<StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput>>;
  updateMany?: Maybe<Array<StripeRefundUpdateManyWithWhereWithoutStripePayoutInput>>;
  upsert?: Maybe<Array<StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput>>;
};

export type StripeRefundUpdateOneWithoutFinancialTransactionNestedInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutFinancialTransactionInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutFinancialTransactionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutFinancialTransactionInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutFinancialTransactionInput>;
};

export type StripeRefundUpdateOneWithoutPushNotificationLogsNestedInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutPushNotificationLogsInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutPushNotificationLogsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutPushNotificationLogsInput>;
};

export type StripeRefundUpdateOneWithoutStripeReceiptNestedInput = {
  connect?: Maybe<StripeRefundWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeRefundCreateOrConnectWithoutStripeReceiptInput>;
  create?: Maybe<StripeRefundUncheckedCreateWithoutStripeReceiptInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeRefundUncheckedUpdateWithoutStripeReceiptInput>;
  upsert?: Maybe<StripeRefundUpsertWithoutStripeReceiptInput>;
};

export type StripeRefundUpdateWithWhereUniqueWithoutIssuedByInput = {
  data: StripeRefundUncheckedUpdateWithoutIssuedByInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePaymentIntentInput = {
  data: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpdateWithWhereUniqueWithoutStripePayoutInput = {
  data: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpdateWithoutFinancialTransactionInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateWithoutIssuedByInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateWithoutPushNotificationLogsInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateWithoutStripePaymentIntentInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateWithoutStripePayoutInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripeReceipt?: Maybe<StripeReceiptUpdateOneWithoutRefundNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpdateWithoutStripeReceiptInput = {
  amount?: Maybe<Scalars['Int']>;
  clientFeeRefunded?: Maybe<Scalars['Int']>;
  clinicFeeRefunded?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  financialTransaction?: Maybe<FinancialTransactionUpdateOneWithoutStripeRefundNestedInput>;
  fundsAvailableAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<UserUpdateOneWithoutRefundsIssuedNestedInput>;
  pushNotificationLogs?: Maybe<PushNotificationLogUpdateManyWithoutStripeRefundNestedInput>;
  reason?: Maybe<RefundReason>;
  reasonOther?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentIntent?: Maybe<StripePaymentIntentUpdateOneWithoutStripeRefundsNestedInput>;
  stripePayout?: Maybe<StripePayoutUpdateOneWithoutStripeRefundsNestedInput>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeRefundUpsertWithWhereUniqueWithoutIssuedByInput = {
  create: StripeRefundUncheckedCreateWithoutIssuedByInput;
  update: StripeRefundUncheckedUpdateWithoutIssuedByInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePaymentIntentInput = {
  create: StripeRefundUncheckedCreateWithoutStripePaymentIntentInput;
  update: StripeRefundUncheckedUpdateWithoutStripePaymentIntentInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpsertWithWhereUniqueWithoutStripePayoutInput = {
  create: StripeRefundUncheckedCreateWithoutStripePayoutInput;
  update: StripeRefundUncheckedUpdateWithoutStripePayoutInput;
  where: StripeRefundWhereUniqueInput;
};

export type StripeRefundUpsertWithoutFinancialTransactionInput = {
  create: StripeRefundUncheckedCreateWithoutFinancialTransactionInput;
  update: StripeRefundUncheckedUpdateWithoutFinancialTransactionInput;
};

export type StripeRefundUpsertWithoutPushNotificationLogsInput = {
  create: StripeRefundUncheckedCreateWithoutPushNotificationLogsInput;
  update: StripeRefundUncheckedUpdateWithoutPushNotificationLogsInput;
};

export type StripeRefundUpsertWithoutStripeReceiptInput = {
  create: StripeRefundUncheckedCreateWithoutStripeReceiptInput;
  update: StripeRefundUncheckedUpdateWithoutStripeReceiptInput;
};

export type StripeRefundWhereInput = {
  AND?: Maybe<Array<StripeRefundWhereInput>>;
  NOT?: Maybe<Array<StripeRefundWhereInput>>;
  OR?: Maybe<Array<StripeRefundWhereInput>>;
  amount?: Maybe<IntFilter>;
  clientFeeRefunded?: Maybe<IntNullableFilter>;
  clinicFeeRefunded?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  financialTransaction?: Maybe<FinancialTransactionWhereInput>;
  fundsAvailableAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  issuedBy?: Maybe<UserWhereInput>;
  issuedByUserId?: Maybe<StringNullableFilter>;
  pushNotificationLogs?: Maybe<PushNotificationLogListRelationFilter>;
  reason?: Maybe<EnumRefundReasonFilter>;
  reasonOther?: Maybe<StringNullableFilter>;
  stripeId?: Maybe<StringFilter>;
  stripePaymentIntent?: Maybe<StripePaymentIntentWhereInput>;
  stripePaymentIntentId?: Maybe<StringNullableFilter>;
  stripePayout?: Maybe<StripePayoutWhereInput>;
  stripePayoutId?: Maybe<StringNullableFilter>;
  stripeReceipt?: Maybe<StripeReceiptWhereInput>;
  stripeReceiptId?: Maybe<StringNullableFilter>;
  total?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeRefundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeReceiptId?: Maybe<Scalars['String']>;
};

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  currentlyDue?: Maybe<Array<Scalars['String']>>;
  deadline?: Maybe<Scalars['Date']>;
  disabledReason?: Maybe<Scalars['String']>;
  eventuallyDue?: Maybe<Array<Scalars['String']>>;
  pastDue?: Maybe<Array<Scalars['String']>>;
  pendingVerification?: Maybe<Array<Scalars['String']>>;
};

export type StripeTerminal = {
  __typename?: 'StripeTerminal';
  _count: StripeTerminalCountOutputType;
  createdAt: Scalars['DateTime'];
  healthchecks: Array<StripeTerminalHealthcheck>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  location: StripeTerminalLocation;
  locationId: Scalars['String'];
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments: Array<StripePaymentIntent>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type StripeTerminalHealthchecksArgs = {
  cursor?: Maybe<StripeTerminalHealthcheckWhereUniqueInput>;
  distinct?: Maybe<StripeTerminalHealthcheckScalarFieldEnum>;
  orderBy?: Maybe<StripeTerminalHealthcheckOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalHealthcheckWhereInput>;
};


export type StripeTerminalPaymentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};

export type StripeTerminalCountAggregateOutputType = {
  __typename?: 'StripeTerminalCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  locationId: Scalars['Int'];
  model: Scalars['Int'];
  name: Scalars['Int'];
  serialNumber: Scalars['Int'];
  stripeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeTerminalCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalCountOutputType = {
  __typename?: 'StripeTerminalCountOutputType';
  healthchecks: Scalars['Int'];
  payments: Scalars['Int'];
};

export type StripeTerminalCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['String'];
  model: StripeTerminalModel;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalCreateManyLocationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model: StripeTerminalModel;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalCreateManyLocationInputEnvelope = {
  data: Array<StripeTerminalCreateManyLocationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalCreateNestedManyWithoutLocationInput = {
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
};

export type StripeTerminalCreateNestedOneWithoutHealthchecksInput = {
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutHealthchecksInput>;
  create?: Maybe<StripeTerminalUncheckedCreateWithoutHealthchecksInput>;
};

export type StripeTerminalCreateNestedOneWithoutPaymentsInput = {
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
};

export type StripeTerminalCreateOrConnectWithoutHealthchecksInput = {
  create: StripeTerminalUncheckedCreateWithoutHealthchecksInput;
  where: StripeTerminalWhereUniqueInput;
};

export type StripeTerminalCreateOrConnectWithoutLocationInput = {
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
  where: StripeTerminalWhereUniqueInput;
};

export type StripeTerminalCreateOrConnectWithoutPaymentsInput = {
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
  where: StripeTerminalWhereUniqueInput;
};

export type StripeTerminalCreateWithoutHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalCreateWithoutLocationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalCreateWithoutPaymentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location: StripeTerminalLocationCreateNestedOneWithoutTerminalsInput;
  model: StripeTerminalModel;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheck = {
  __typename?: 'StripeTerminalHealthcheck';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  terminal: StripeTerminal;
  terminalId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StripeTerminalHealthcheckCountAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  ipAddress: Scalars['Int'];
  status: Scalars['Int'];
  tapVersion: Scalars['Int'];
  terminalId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeTerminalHealthcheckCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  terminal: StripeTerminalCreateNestedOneWithoutHealthchecksInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckCreateManyTerminalInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckCreateManyTerminalInputEnvelope = {
  data: Array<StripeTerminalHealthcheckCreateManyTerminalInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalHealthcheckCreateNestedManyWithoutTerminalInput = {
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
};

export type StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput = {
  create: StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput;
  where: StripeTerminalHealthcheckWhereUniqueInput;
};

export type StripeTerminalHealthcheckCreateWithoutTerminalInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckListRelationFilter = {
  every?: Maybe<StripeTerminalHealthcheckWhereInput>;
  none?: Maybe<StripeTerminalHealthcheckWhereInput>;
  some?: Maybe<StripeTerminalHealthcheckWhereInput>;
};

export type StripeTerminalHealthcheckMaxAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckMinAggregateOutputType = {
  __typename?: 'StripeTerminalHealthcheckMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalHealthcheckOrderByRelevanceFieldEnum {
  Id = 'id',
  IpAddress = 'ipAddress',
  TapVersion = 'tapVersion',
  TerminalId = 'terminalId'
}

export type StripeTerminalHealthcheckOrderByRelevanceInput = {
  fields: Array<StripeTerminalHealthcheckOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeTerminalHealthcheckOrderByWithAggregationInput = {
  _count?: Maybe<StripeTerminalHealthcheckCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalHealthcheckMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalHealthcheckMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalHealthcheckOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeTerminalHealthcheckOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  ipAddress?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  tapVersion?: Maybe<SortOrder>;
  terminal?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  terminalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum StripeTerminalHealthcheckScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IpAddress = 'ipAddress',
  Status = 'status',
  TapVersion = 'tapVersion',
  TerminalId = 'terminalId',
  UpdatedAt = 'updatedAt'
}

export type StripeTerminalHealthcheckScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  ipAddress?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStripeTerminalStatusFilter>;
  tapVersion?: Maybe<StringNullableFilter>;
  terminalId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalHealthcheckScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  ipAddress?: Maybe<StringNullableWithAggregatesFilter>;
  status?: Maybe<EnumStripeTerminalStatusWithAggregatesFilter>;
  tapVersion?: Maybe<StringNullableWithAggregatesFilter>;
  terminalId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type StripeTerminalHealthcheckUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput = {
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
};

export type StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status: StripeTerminalStatus;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyWithoutHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput = {
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput>>;
  updateMany?: Maybe<Array<StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput>>;
  upsert?: Maybe<Array<StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput>>;
};

export type StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  terminal?: Maybe<StripeTerminalUpdateOneRequiredWithoutHealthchecksNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput = {
  data: StripeTerminalHealthcheckUncheckedUpdateManyWithoutHealthchecksInput;
  where: StripeTerminalHealthcheckScalarWhereInput;
};

export type StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput = {
  connect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalHealthcheckCreateOrConnectWithoutTerminalInput>>;
  create?: Maybe<Array<StripeTerminalHealthcheckCreateWithoutTerminalInput>>;
  createMany?: Maybe<StripeTerminalHealthcheckCreateManyTerminalInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalHealthcheckScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalHealthcheckWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput>>;
  updateMany?: Maybe<Array<StripeTerminalHealthcheckUpdateManyWithWhereWithoutTerminalInput>>;
  upsert?: Maybe<Array<StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput>>;
};

export type StripeTerminalHealthcheckUpdateWithWhereUniqueWithoutTerminalInput = {
  data: StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput;
  where: StripeTerminalHealthcheckWhereUniqueInput;
};

export type StripeTerminalHealthcheckUpdateWithoutTerminalInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  status?: Maybe<StripeTerminalStatus>;
  tapVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalHealthcheckUpsertWithWhereUniqueWithoutTerminalInput = {
  create: StripeTerminalHealthcheckUncheckedCreateWithoutTerminalInput;
  update: StripeTerminalHealthcheckUncheckedUpdateWithoutTerminalInput;
  where: StripeTerminalHealthcheckWhereUniqueInput;
};

export type StripeTerminalHealthcheckWhereInput = {
  AND?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  OR?: Maybe<Array<StripeTerminalHealthcheckWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  ipAddress?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumStripeTerminalStatusFilter>;
  tapVersion?: Maybe<StringNullableFilter>;
  terminal?: Maybe<StripeTerminalWhereInput>;
  terminalId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalHealthcheckWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StripeTerminalListRelationFilter = {
  every?: Maybe<StripeTerminalWhereInput>;
  none?: Maybe<StripeTerminalWhereInput>;
  some?: Maybe<StripeTerminalWhereInput>;
};

export type StripeTerminalLocation = {
  __typename?: 'StripeTerminalLocation';
  _count: StripeTerminalLocationCountOutputType;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  stripeId: Scalars['String'];
  terminals: Array<StripeTerminal>;
  type: StripeTerminalLocationType;
  updatedAt: Scalars['DateTime'];
};


export type StripeTerminalLocationTerminalsArgs = {
  cursor?: Maybe<StripeTerminalWhereUniqueInput>;
  distinct?: Maybe<StripeTerminalScalarFieldEnum>;
  orderBy?: Maybe<StripeTerminalOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeTerminalWhereInput>;
};

export type StripeTerminalLocationCountAggregateOutputType = {
  __typename?: 'StripeTerminalLocationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  name: Scalars['Int'];
  stripeId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type StripeTerminalLocationCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalLocationCountOutputType = {
  __typename?: 'StripeTerminalLocationCountOutputType';
  terminals: Scalars['Int'];
};

export type StripeTerminalLocationCreateInput = {
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationCreateManyClinicInputEnvelope = {
  data: Array<StripeTerminalLocationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StripeTerminalLocationCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
};

export type StripeTerminalLocationCreateNestedOneWithoutTerminalsInput = {
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
};

export type StripeTerminalLocationCreateOrConnectWithoutClinicInput = {
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
  where: StripeTerminalLocationWhereUniqueInput;
};

export type StripeTerminalLocationCreateOrConnectWithoutTerminalsInput = {
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
  where: StripeTerminalLocationWhereUniqueInput;
};

export type StripeTerminalLocationCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  terminals?: Maybe<StripeTerminalCreateNestedManyWithoutLocationInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationCreateWithoutTerminalsInput = {
  clinic: ClinicCreateNestedOneWithoutStripeTerminalLocationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationListRelationFilter = {
  every?: Maybe<StripeTerminalLocationWhereInput>;
  none?: Maybe<StripeTerminalLocationWhereInput>;
  some?: Maybe<StripeTerminalLocationWhereInput>;
};

export type StripeTerminalLocationMaxAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalLocationMinAggregateOutputType = {
  __typename?: 'StripeTerminalLocationMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalLocationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalLocationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  Name = 'name',
  StripeId = 'stripeId'
}

export type StripeTerminalLocationOrderByRelevanceInput = {
  fields: Array<StripeTerminalLocationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeTerminalLocationOrderByWithAggregationInput = {
  _count?: Maybe<StripeTerminalLocationCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalLocationMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalLocationMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeTerminalLocationOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  terminals?: Maybe<StripeTerminalOrderByRelationAggregateInput>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalLocationRelationFilter = {
  is?: Maybe<StripeTerminalLocationWhereInput>;
  isNot?: Maybe<StripeTerminalLocationWhereInput>;
};

export enum StripeTerminalLocationScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  Name = 'name',
  StripeId = 'stripeId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type StripeTerminalLocationScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalLocationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalLocationScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripeTerminalLocationType {
  StripeApp = 'StripeApp',
  Tap = 'TAP'
}

export type StripeTerminalLocationUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
};

export type StripeTerminalLocationUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  terminals?: Maybe<StripeTerminalUncheckedCreateNestedManyWithoutLocationInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedCreateWithoutTerminalsInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  stripeId: Scalars['String'];
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUniqueInput = {
  locationId: Scalars['String'];
};

export type StripeTerminalLocationUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationNestedInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput = {
  data: StripeTerminalLocationUncheckedUpdateManyWithoutStripeTerminalLocationsInput;
  where: StripeTerminalLocationScalarWhereInput;
};

export type StripeTerminalLocationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalLocationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<StripeTerminalLocationCreateWithoutClinicInput>>;
  createMany?: Maybe<StripeTerminalLocationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalLocationScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalLocationWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<StripeTerminalLocationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput = {
  connect?: Maybe<StripeTerminalLocationWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalLocationCreateOrConnectWithoutTerminalsInput>;
  create?: Maybe<StripeTerminalLocationUncheckedCreateWithoutTerminalsInput>;
  update?: Maybe<StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput>;
  upsert?: Maybe<StripeTerminalLocationUpsertWithoutTerminalsInput>;
};

export type StripeTerminalLocationUpdateWithWhereUniqueWithoutClinicInput = {
  data: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
  where: StripeTerminalLocationWhereUniqueInput;
};

export type StripeTerminalLocationUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  terminals?: Maybe<StripeTerminalUpdateManyWithoutLocationNestedInput>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUpdateWithoutTerminalsInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutStripeTerminalLocationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<StripeTerminalLocationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalLocationUpsertWithWhereUniqueWithoutClinicInput = {
  create: StripeTerminalLocationUncheckedCreateWithoutClinicInput;
  update: StripeTerminalLocationUncheckedUpdateWithoutClinicInput;
  where: StripeTerminalLocationWhereUniqueInput;
};

export type StripeTerminalLocationUpsertWithoutTerminalsInput = {
  create: StripeTerminalLocationUncheckedCreateWithoutTerminalsInput;
  update: StripeTerminalLocationUncheckedUpdateWithoutTerminalsInput;
};

export type StripeTerminalLocationWhereInput = {
  AND?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  OR?: Maybe<Array<StripeTerminalLocationWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  terminals?: Maybe<StripeTerminalListRelationFilter>;
  type?: Maybe<EnumStripeTerminalLocationTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalLocationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeTerminalMaxAggregateOutputType = {
  __typename?: 'StripeTerminalMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalMinAggregateOutputType = {
  __typename?: 'StripeTerminalMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum StripeTerminalModel {
  BbposWisePosE = 'BBPOS_WisePOS_E',
  StripeS700 = 'Stripe_S700',
  VerifoneP400 = 'Verifone_P400'
}

export type StripeTerminalOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StripeTerminalOrderByRelevanceFieldEnum {
  Id = 'id',
  LocationId = 'locationId',
  Name = 'name',
  SerialNumber = 'serialNumber',
  StripeId = 'stripeId'
}

export type StripeTerminalOrderByRelevanceInput = {
  fields: Array<StripeTerminalOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type StripeTerminalOrderByWithAggregationInput = {
  _count?: Maybe<StripeTerminalCountOrderByAggregateInput>;
  _max?: Maybe<StripeTerminalMaxOrderByAggregateInput>;
  _min?: Maybe<StripeTerminalMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<StripeTerminalOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  healthchecks?: Maybe<StripeTerminalHealthcheckOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  location?: Maybe<StripeTerminalLocationOrderByWithRelationAndSearchRelevanceInput>;
  locationId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  payments?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  serialNumber?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type StripeTerminalRelationFilter = {
  is?: Maybe<StripeTerminalWhereInput>;
  isNot?: Maybe<StripeTerminalWhereInput>;
};

export enum StripeTerminalScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  LocationId = 'locationId',
  Model = 'model',
  Name = 'name',
  SerialNumber = 'serialNumber',
  StripeId = 'stripeId',
  UpdatedAt = 'updatedAt'
}

export type StripeTerminalScalarWhereInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  locationId?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  name?: Maybe<StringFilter>;
  serialNumber?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<StripeTerminalScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  locationId?: Maybe<StringWithAggregatesFilter>;
  model?: Maybe<EnumStripeTerminalModelWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  serialNumber?: Maybe<StringWithAggregatesFilter>;
  stripeId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum StripeTerminalStatus {
  Offline = 'Offline',
  Online = 'Online'
}

export type StripeTerminalTokenOutput = {
  __typename?: 'StripeTerminalTokenOutput';
  secret: Scalars['String'];
};

export type StripeTerminalUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['String'];
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedCreateNestedManyWithoutLocationInput = {
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
};

export type StripeTerminalUncheckedCreateWithoutHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['String'];
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedCreateWithoutLocationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model: StripeTerminalModel;
  name: Scalars['String'];
  payments?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutStripeTerminalInput>;
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedCreateWithoutPaymentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedCreateNestedManyWithoutTerminalInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['String'];
  model: StripeTerminalModel;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateManyWithoutLocationNestedInput = {
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type StripeTerminalUncheckedUpdateManyWithoutTerminalsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateWithoutHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateWithoutLocationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUncheckedUpdateWithoutPaymentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUncheckedUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpdateManyWithWhereWithoutLocationInput = {
  data: StripeTerminalUncheckedUpdateManyWithoutTerminalsInput;
  where: StripeTerminalScalarWhereInput;
};

export type StripeTerminalUpdateManyWithoutLocationNestedInput = {
  connect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StripeTerminalCreateOrConnectWithoutLocationInput>>;
  create?: Maybe<Array<StripeTerminalCreateWithoutLocationInput>>;
  createMany?: Maybe<StripeTerminalCreateManyLocationInputEnvelope>;
  delete?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StripeTerminalScalarWhereInput>>;
  disconnect?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  set?: Maybe<Array<StripeTerminalWhereUniqueInput>>;
  update?: Maybe<Array<StripeTerminalUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: Maybe<Array<StripeTerminalUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: Maybe<Array<StripeTerminalUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type StripeTerminalUpdateOneRequiredWithoutHealthchecksNestedInput = {
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutHealthchecksInput>;
  create?: Maybe<StripeTerminalUncheckedCreateWithoutHealthchecksInput>;
  update?: Maybe<StripeTerminalUncheckedUpdateWithoutHealthchecksInput>;
  upsert?: Maybe<StripeTerminalUpsertWithoutHealthchecksInput>;
};

export type StripeTerminalUpdateOneWithoutPaymentsNestedInput = {
  connect?: Maybe<StripeTerminalWhereUniqueInput>;
  connectOrCreate?: Maybe<StripeTerminalCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<StripeTerminalUncheckedCreateWithoutPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StripeTerminalUncheckedUpdateWithoutPaymentsInput>;
  upsert?: Maybe<StripeTerminalUpsertWithoutPaymentsInput>;
};

export type StripeTerminalUpdateWithWhereUniqueWithoutLocationInput = {
  data: StripeTerminalUncheckedUpdateWithoutLocationInput;
  where: StripeTerminalWhereUniqueInput;
};

export type StripeTerminalUpdateWithoutHealthchecksInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpdateWithoutLocationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<StripePaymentIntentUpdateManyWithoutStripeTerminalNestedInput>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpdateWithoutPaymentsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  healthchecks?: Maybe<StripeTerminalHealthcheckUpdateManyWithoutTerminalNestedInput>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  location?: Maybe<StripeTerminalLocationUpdateOneRequiredWithoutTerminalsNestedInput>;
  model?: Maybe<StripeTerminalModel>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StripeTerminalUpsertWithWhereUniqueWithoutLocationInput = {
  create: StripeTerminalUncheckedCreateWithoutLocationInput;
  update: StripeTerminalUncheckedUpdateWithoutLocationInput;
  where: StripeTerminalWhereUniqueInput;
};

export type StripeTerminalUpsertWithoutHealthchecksInput = {
  create: StripeTerminalUncheckedCreateWithoutHealthchecksInput;
  update: StripeTerminalUncheckedUpdateWithoutHealthchecksInput;
};

export type StripeTerminalUpsertWithoutPaymentsInput = {
  create: StripeTerminalUncheckedCreateWithoutPaymentsInput;
  update: StripeTerminalUncheckedUpdateWithoutPaymentsInput;
};

export type StripeTerminalWhereInput = {
  AND?: Maybe<Array<StripeTerminalWhereInput>>;
  NOT?: Maybe<Array<StripeTerminalWhereInput>>;
  OR?: Maybe<Array<StripeTerminalWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  healthchecks?: Maybe<StripeTerminalHealthcheckListRelationFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  location?: Maybe<StripeTerminalLocationWhereInput>;
  locationId?: Maybe<StringFilter>;
  model?: Maybe<EnumStripeTerminalModelFilter>;
  name?: Maybe<StringFilter>;
  payments?: Maybe<StripePaymentIntentListRelationFilter>;
  serialNumber?: Maybe<StringFilter>;
  stripeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type StripeTerminalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

export type StripeUpdatePaymentInput = {
  amount: Scalars['Int'];
  captureType?: Maybe<StripeCaptureType>;
  cardType?: Maybe<CardFunding>;
  clientServiceFeePercent?: Maybe<Scalars['Float']>;
  clinicId: Scalars['String'];
  customer?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptorSuffix?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  paymentIntentId: Scalars['String'];
  paymentMedium?: Maybe<PaymentMedium>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<StripePaymentMethodType>;
  receiptEmail?: Maybe<Scalars['String']>;
};

export type SubmitFormResponse = {
  __typename?: 'SubmitFormResponse';
  channelId?: Maybe<Scalars['String']>;
  formSubmission: FormSubmission;
};

export type SubmitPetParentRequestInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  clinicWidgetRequestTypeId: Scalars['String'];
  completedContent: Scalars['Json'];
  formTemplateId: Scalars['String'];
};

export type SubmitPrescriptionRefillRequestInput = {
  clinicId?: Maybe<Scalars['String']>;
  clinicWidgetRequestTypeId: Scalars['String'];
  formSubmission: Scalars['Json'];
  petParentId: Scalars['String'];
  prescriptionsIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SubmitPrescriptionRefillRequestResponse = {
  __typename?: 'SubmitPrescriptionRefillRequestResponse';
  channelId?: Maybe<Scalars['String']>;
  clinicWidgetRequestId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  automationRunActionChanged: AutomationRunActionSubscription;
  automationRunChanged: AutomationRunSubscription;
  channelChanged: ChannelSubscription;
  channelMemberChanged: ChannelMemberSubscription;
  channelMessageAttachmentChanged: ChannelMessageAttachmentSubscription;
  channelMessageChanged: ChannelMessageSubscription;
  channelSyncedToSearch: Channel;
  checkPetEnrollment: CareEnrollmentOutput;
  checkPetEnrollmentsByClinic: CareEnrollmentsByClinicOutput;
  checkPetParentHasEnrolledPet: HasCarePetWithEnrollmentOutput;
  clinicChanged: ClinicSubscription;
  clinicPhoneNumberChanged: ClinicPhoneNumberSubscription;
  clinicWidgetRequestChanged: ClinicWidgetRequestSubscription;
  formSubmissionChanged?: Maybe<FormSubmissionSubscription>;
  integrationOutageHistoryEntryChanged: IntegrationOutageHistoryEntrySubscription;
  jobChainCompleted?: Maybe<JobChainSubscritptionPayload>;
  loyaltyAccountUpdated: LoyaltyAccountSubscription;
  loyaltyProgramUpdated: LoyaltyProgramSubscription;
  loyaltyRewardRedemptionUpdated: LoyaltyRewardRedemptionSubscription;
  massTextAlertChanged: MassTextAlertSubscription;
  recordingStatusChanged: TwilioCallRecordingSubscription;
  smsNotificationStatusChanged?: Maybe<SmsNotificationStatusSubscription>;
  stripePaymentIntentChanged: StripePaymentIntentSubscription;
  twilioVerificationChanged: TwilioVerificationSubscription;
  uiRefresh?: Maybe<UiRefresh>;
  unreadMessageCountChanged: ChannelSubscription;
};


export type SubscriptionAutomationRunActionChangedArgs = {
  where: SubscriptionAutomationRunActionWhereInput;
};


export type SubscriptionAutomationRunChangedArgs = {
  where: SubscriptionAutomationRunActionWhereInput;
};


export type SubscriptionChannelChangedArgs = {
  where: SubscriptionChannelWhereInput;
};


export type SubscriptionChannelMemberChangedArgs = {
  where: SubscriptionChannelMemberWhereInput;
};


export type SubscriptionChannelMessageAttachmentChangedArgs = {
  where: SubscriptionChannelMessageAttachmentWhereInput;
};


export type SubscriptionChannelMessageChangedArgs = {
  where: SubscriptionChannelMessageWhereInput;
};


export type SubscriptionChannelSyncedToSearchArgs = {
  where?: Maybe<ChannelSyncedToSearchWhereInput>;
};


export type SubscriptionCheckPetEnrollmentArgs = {
  where: CareEnrollmentWhereInput;
};


export type SubscriptionCheckPetEnrollmentsByClinicArgs = {
  where: CareEnrollmentsByClinicWhereInput;
};


export type SubscriptionCheckPetParentHasEnrolledPetArgs = {
  where: HasCarePetWithEnrollmentWhereInput;
};


export type SubscriptionClinicChangedArgs = {
  where: SubscriptionClinicWhereInput;
};


export type SubscriptionClinicPhoneNumberChangedArgs = {
  where: ClinicPhoneNumberWhereInput;
};


export type SubscriptionClinicWidgetRequestChangedArgs = {
  where: SubscriptionClinicWidgetRequestWhereInput;
};


export type SubscriptionFormSubmissionChangedArgs = {
  where?: Maybe<SubscriptionFormSubmissionWhereInput>;
};


export type SubscriptionIntegrationOutageHistoryEntryChangedArgs = {
  where: IntegrationOutageHistoryEntryWhereInput;
};


export type SubscriptionJobChainCompletedArgs = {
  where: JobChainSubscritptionWhereInput;
};


export type SubscriptionLoyaltyAccountUpdatedArgs = {
  where: LoyaltyAccountWhereUniqueInput;
};


export type SubscriptionLoyaltyProgramUpdatedArgs = {
  where: LoyaltyProgramWhereUniqueInput;
};


export type SubscriptionLoyaltyRewardRedemptionUpdatedArgs = {
  where: LoyaltyRewardRedemptionWhereUniqueInput;
};


export type SubscriptionMassTextAlertChangedArgs = {
  where: SubscriptionMassTextAlertWhereInput;
};


export type SubscriptionRecordingStatusChangedArgs = {
  where: SubscriptionTwilioCallRecordingWhereInput;
};


export type SubscriptionSmsNotificationStatusChangedArgs = {
  where: SubscriptionSmsNotificationStatusWhereInput;
};


export type SubscriptionStripePaymentIntentChangedArgs = {
  where: SubscriptionStripePaymentIntentWhereInput;
};


export type SubscriptionTwilioVerificationChangedArgs = {
  where: SubscriptionTwilioVerificationWhereInput;
};


export type SubscriptionUnreadMessageCountChangedArgs = {
  where: SubscriptionChannelWhereInput;
};

export type SubscriptionAutomationRunActionWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: AutomationRunActionWhereInput;
};

export type SubscriptionAutomationRunWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: AutomationRunWhereInput;
};

export type SubscriptionChannelMemberWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ChannelMemberWhereInput;
};

export type SubscriptionChannelMessageAttachmentWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ChannelMessageAttachmentWhereInput;
};

export type SubscriptionChannelMessageWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ChannelMessageWhereInput;
};

export type SubscriptionChannelWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ChannelWhereInput;
};

export type SubscriptionClinicWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ClinicWhereInput;
};

export type SubscriptionClinicWidgetRequestWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: ClinicWidgetRequestWhereInput;
};

export type SubscriptionFormSubmissionWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: FormSubmissionWhereInput;
};

export type SubscriptionMassTextAlertWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: MassTextAlertWhereInput;
};

export type SubscriptionSmsNotificationStatusWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: SmsNotificationStatusWhereInput;
};

export type SubscriptionStripePaymentIntentWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: StripePaymentIntentWhereUniqueInput;
};

export type SubscriptionTwilioCallRecordingWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: TwilioCallRecordingWhereInput;
};

export type SubscriptionTwilioVerificationWhereInput = {
  mutation?: Maybe<Array<MutationType>>;
  node: TwilioVerificationWhereInput;
};

export enum SupportEventStatus {
  Canceled = 'Canceled',
  Closed = 'Closed',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  System = 'System'
}

export type SurveyAppointmentTypeSetting = {
  __typename?: 'SurveyAppointmentTypeSetting';
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['String'];
  automatedTriggerInMinutes: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  surveySetting: SurveySetting;
  surveySettingId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyAppointmentTypeSettingAvgAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingAvgAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Float']>;
};

export type SurveyAppointmentTypeSettingAvgOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingCountAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  automatedTriggerInMinutes: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  surveySettingId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SurveyAppointmentTypeSettingCountOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingCreateInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySetting: SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingCreateManyAppointmentTypeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope = {
  data: Array<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyAppointmentTypeSettingCreateManyInput = {
  appointmentTypeId: Scalars['String'];
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingCreateManySurveySettingInput = {
  appointmentTypeId: Scalars['String'];
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope = {
  data: Array<SurveyAppointmentTypeSettingCreateManySurveySettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyAppointmentTypeSettingCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
};

export type SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
};

export type SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput = {
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput = {
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySetting: SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutSurveyAppointmentTypeSettingsInput;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingListRelationFilter = {
  every?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  none?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
  some?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};

export type SurveyAppointmentTypeSettingMaxAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingMaxAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingMaxOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingMinAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingMinAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingMinOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SurveyAppointmentTypeSettingOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  Id = 'id',
  SurveySettingId = 'surveySettingId'
}

export type SurveyAppointmentTypeSettingOrderByRelevanceInput = {
  fields: Array<SurveyAppointmentTypeSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SurveyAppointmentTypeSettingOrderByWithAggregationInput = {
  _avg?: Maybe<SurveyAppointmentTypeSettingAvgOrderByAggregateInput>;
  _count?: Maybe<SurveyAppointmentTypeSettingCountOrderByAggregateInput>;
  _max?: Maybe<SurveyAppointmentTypeSettingMaxOrderByAggregateInput>;
  _min?: Maybe<SurveyAppointmentTypeSettingMinOrderByAggregateInput>;
  _sum?: Maybe<SurveyAppointmentTypeSettingSumOrderByAggregateInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SurveyAppointmentTypeSettingOrderByRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  surveySetting?: Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>;
  surveySettingId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SurveyAppointmentTypeSettingScalarFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  AutomatedTriggerInMinutes = 'automatedTriggerInMinutes',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  SurveySettingId = 'surveySettingId',
  UpdatedAt = 'updatedAt'
}

export type SurveyAppointmentTypeSettingScalarWhereInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  appointmentTypeId?: Maybe<StringFilter>;
  automatedTriggerInMinutes?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  surveySettingId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereWithAggregatesInput>>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  automatedTriggerInMinutes?: Maybe<IntWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  surveySettingId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SurveyAppointmentTypeSettingSumAggregateOutputType = {
  __typename?: 'SurveyAppointmentTypeSettingSumAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
};

export type SurveyAppointmentTypeSettingSumOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateInput = {
  appointmentTypeId: Scalars['String'];
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutAppointmentTypeInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput = {
  appointmentTypeId: Scalars['String'];
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeSettingsInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveyAppointmentTypeSettingsInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySettingId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySetting?: Maybe<SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUpdateManyMutationInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput = {
  data: SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveyAppointmentTypeSettingsInput;
  where: SurveyAppointmentTypeSettingScalarWhereInput;
};

export type SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput = {
  data: SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutAppointmentTypeSettingsInput;
  where: SurveyAppointmentTypeSettingScalarWhereInput;
};

export type SurveyAppointmentTypeSettingUpdateManyWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutAppointmentTypeInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutAppointmentTypeInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManyAppointmentTypeInputEnvelope>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutAppointmentTypeInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput>>;
};

export type SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput = {
  connect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyAppointmentTypeSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<SurveyAppointmentTypeSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<SurveyAppointmentTypeSettingCreateManySurveySettingInputEnvelope>;
  delete?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyAppointmentTypeSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  set?: Maybe<Array<SurveyAppointmentTypeSettingWhereUniqueInput>>;
  update?: Maybe<Array<SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<SurveyAppointmentTypeSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
};

export type SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutAppointmentTypeInput = {
  data: SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingUpdateWithWhereUniqueWithoutSurveySettingInput = {
  data: SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingUpdateWithoutAppointmentTypeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  surveySetting?: Maybe<SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUpdateWithoutSurveySettingInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutSurveyAppointmentTypeSettingsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutAppointmentTypeInput = {
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutAppointmentTypeInput;
  update: SurveyAppointmentTypeSettingUncheckedUpdateWithoutAppointmentTypeInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingUpsertWithWhereUniqueWithoutSurveySettingInput = {
  create: SurveyAppointmentTypeSettingUncheckedCreateWithoutSurveySettingInput;
  update: SurveyAppointmentTypeSettingUncheckedUpdateWithoutSurveySettingInput;
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
};

export type SurveyAppointmentTypeSettingWhereInput = {
  AND?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  NOT?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  OR?: Maybe<Array<SurveyAppointmentTypeSettingWhereInput>>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringFilter>;
  automatedTriggerInMinutes?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  surveySetting?: Maybe<SurveySettingWhereInput>;
  surveySettingId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SurveyAppointmentTypeSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  appointment: Appointment;
  appointmentId: Scalars['String'];
  automationRun?: Maybe<AutomationRun>;
  automationRunId?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPetParent: ClinicPetParent;
  clinicPetParentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type: SurveyType;
  updatedAt: Scalars['DateTime'];
};

export type SurveyResultAvgAggregateOutputType = {
  __typename?: 'SurveyResultAvgAggregateOutputType';
  score?: Maybe<Scalars['Float']>;
};

export type SurveyResultAvgOrderByAggregateInput = {
  score?: Maybe<SortOrder>;
};

export type SurveyResultCountAggregateOutputType = {
  __typename?: 'SurveyResultCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  automationRunId: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetParentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  feedback: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  score: Scalars['Int'];
  surveySentAt: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SurveyResultCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyResultCreateInput = {
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateManyAppointmentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateManyAppointmentInputEnvelope = {
  data: Array<SurveyResultCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyAutomationRunInput = {
  appointmentId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateManyAutomationRunInputEnvelope = {
  data: Array<SurveyResultCreateManyAutomationRunInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyClinicInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateManyClinicInputEnvelope = {
  data: Array<SurveyResultCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyClinicPetParentInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateManyClinicPetParentInputEnvelope = {
  data: Array<SurveyResultCreateManyClinicPetParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SurveyResultCreateManyInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
};

export type SurveyResultCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
};

export type SurveyResultCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
};

export type SurveyResultCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
};

export type SurveyResultCreateOrConnectWithoutAppointmentInput = {
  create: SurveyResultUncheckedCreateWithoutAppointmentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultCreateOrConnectWithoutAutomationRunInput = {
  create: SurveyResultUncheckedCreateWithoutAutomationRunInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultCreateOrConnectWithoutClinicInput = {
  create: SurveyResultUncheckedCreateWithoutClinicInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultCreateOrConnectWithoutClinicPetParentInput = {
  create: SurveyResultUncheckedCreateWithoutClinicPetParentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultCreateWithoutAppointmentInput = {
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateWithoutAutomationRunInput = {
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateWithoutClinicInput = {
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
  clinicPetParent: ClinicPetParentCreateNestedOneWithoutSurveyResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultCreateWithoutClinicPetParentInput = {
  appointment: AppointmentCreateNestedOneWithoutSurveyResultsInput;
  automationRun?: Maybe<AutomationRunCreateNestedOneWithoutSurveyResultsInput>;
  clinic: ClinicCreateNestedOneWithoutSurveyResultsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultListRelationFilter = {
  every?: Maybe<SurveyResultWhereInput>;
  none?: Maybe<SurveyResultWhereInput>;
  some?: Maybe<SurveyResultWhereInput>;
};

export type SurveyResultMaxAggregateOutputType = {
  __typename?: 'SurveyResultMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyResultMinAggregateOutputType = {
  __typename?: 'SurveyResultMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyResultOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SurveyResultOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  AutomationRunId = 'automationRunId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  Feedback = 'feedback',
  Id = 'id'
}

export type SurveyResultOrderByRelevanceInput = {
  fields: Array<SurveyResultOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SurveyResultOrderByWithAggregationInput = {
  _avg?: Maybe<SurveyResultAvgOrderByAggregateInput>;
  _count?: Maybe<SurveyResultCountOrderByAggregateInput>;
  _max?: Maybe<SurveyResultMaxOrderByAggregateInput>;
  _min?: Maybe<SurveyResultMinOrderByAggregateInput>;
  _sum?: Maybe<SurveyResultSumOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  automationRunId?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveyResultOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SurveyResultOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  automationRun?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  automationRunId?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPetParent?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetParentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  score?: Maybe<SortOrder>;
  surveySentAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SurveyResultScalarFieldEnum {
  AppointmentId = 'appointmentId',
  AutomationRunId = 'automationRunId',
  ClinicId = 'clinicId',
  ClinicPetParentId = 'clinicPetParentId',
  CreatedAt = 'createdAt',
  Feedback = 'feedback',
  Id = 'id',
  IsDeleted = 'isDeleted',
  Score = 'score',
  SurveySentAt = 'surveySentAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type SurveyResultScalarWhereInput = {
  AND?: Maybe<Array<SurveyResultScalarWhereInput>>;
  NOT?: Maybe<Array<SurveyResultScalarWhereInput>>;
  OR?: Maybe<Array<SurveyResultScalarWhereInput>>;
  appointmentId?: Maybe<StringFilter>;
  automationRunId?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  feedback?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  score?: Maybe<IntFilter>;
  surveySentAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumSurveyTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SurveyResultScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveyResultScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringWithAggregatesFilter>;
  automationRunId?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetParentId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  feedback?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  score?: Maybe<IntWithAggregatesFilter>;
  surveySentAt?: Maybe<DateTimeWithAggregatesFilter>;
  type?: Maybe<EnumSurveyTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SurveyResultSumAggregateOutputType = {
  __typename?: 'SurveyResultSumAggregateOutputType';
  score?: Maybe<Scalars['Int']>;
};

export type SurveyResultSumOrderByAggregateInput = {
  score?: Maybe<SortOrder>;
};

export type SurveyResultUncheckedCreateInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutAutomationRunInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
};

export type SurveyResultUncheckedCreateNestedManyWithoutClinicPetParentInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
};

export type SurveyResultUncheckedCreateWithoutAppointmentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedCreateWithoutAutomationRunInput = {
  appointmentId: Scalars['String'];
  clinicId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedCreateWithoutClinicInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicPetParentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedCreateWithoutClinicPetParentInput = {
  appointmentId: Scalars['String'];
  automationRunId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score: Scalars['Int'];
  surveySentAt: Scalars['DateTime'];
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateWithoutAppointmentInput = {
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateWithoutAutomationRunInput = {
  appointmentId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateWithoutClinicInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicPetParentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUncheckedUpdateWithoutClinicPetParentInput = {
  appointmentId?: Maybe<Scalars['String']>;
  automationRunId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateManyWithWhereWithoutAppointmentInput = {
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
  where: SurveyResultScalarWhereInput;
};

export type SurveyResultUpdateManyWithWhereWithoutAutomationRunInput = {
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
  where: SurveyResultScalarWhereInput;
};

export type SurveyResultUpdateManyWithWhereWithoutClinicInput = {
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
  where: SurveyResultScalarWhereInput;
};

export type SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput = {
  data: SurveyResultUncheckedUpdateManyWithoutSurveyResultsInput;
  where: SurveyResultScalarWhereInput;
};

export type SurveyResultUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAppointmentInput>>;
  createMany?: Maybe<SurveyResultCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type SurveyResultUpdateManyWithoutAutomationRunNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutAutomationRunInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutAutomationRunInput>>;
  createMany?: Maybe<SurveyResultCreateManyAutomationRunInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutAutomationRunInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput>>;
};

export type SurveyResultUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type SurveyResultUpdateManyWithoutClinicPetParentNestedInput = {
  connect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SurveyResultCreateOrConnectWithoutClinicPetParentInput>>;
  create?: Maybe<Array<SurveyResultCreateWithoutClinicPetParentInput>>;
  createMany?: Maybe<SurveyResultCreateManyClinicPetParentInputEnvelope>;
  delete?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SurveyResultScalarWhereInput>>;
  disconnect?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  set?: Maybe<Array<SurveyResultWhereUniqueInput>>;
  update?: Maybe<Array<SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput>>;
  updateMany?: Maybe<Array<SurveyResultUpdateManyWithWhereWithoutClinicPetParentInput>>;
  upsert?: Maybe<Array<SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput>>;
};

export type SurveyResultUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: SurveyResultUncheckedUpdateWithoutAppointmentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutAutomationRunInput = {
  data: SurveyResultUncheckedUpdateWithoutAutomationRunInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutClinicInput = {
  data: SurveyResultUncheckedUpdateWithoutClinicInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpdateWithWhereUniqueWithoutClinicPetParentInput = {
  data: SurveyResultUncheckedUpdateWithoutClinicPetParentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpdateWithoutAppointmentInput = {
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateWithoutAutomationRunInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateWithoutClinicInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
  clinicPetParent?: Maybe<ClinicPetParentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpdateWithoutClinicPetParentInput = {
  appointment?: Maybe<AppointmentUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  automationRun?: Maybe<AutomationRunUpdateOneWithoutSurveyResultsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutSurveyResultsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Int']>;
  surveySentAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SurveyType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResultUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: SurveyResultUncheckedCreateWithoutAppointmentInput;
  update: SurveyResultUncheckedUpdateWithoutAppointmentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutAutomationRunInput = {
  create: SurveyResultUncheckedCreateWithoutAutomationRunInput;
  update: SurveyResultUncheckedUpdateWithoutAutomationRunInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutClinicInput = {
  create: SurveyResultUncheckedCreateWithoutClinicInput;
  update: SurveyResultUncheckedUpdateWithoutClinicInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultUpsertWithWhereUniqueWithoutClinicPetParentInput = {
  create: SurveyResultUncheckedCreateWithoutClinicPetParentInput;
  update: SurveyResultUncheckedUpdateWithoutClinicPetParentInput;
  where: SurveyResultWhereUniqueInput;
};

export type SurveyResultWhereInput = {
  AND?: Maybe<Array<SurveyResultWhereInput>>;
  NOT?: Maybe<Array<SurveyResultWhereInput>>;
  OR?: Maybe<Array<SurveyResultWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringFilter>;
  automationRun?: Maybe<AutomationRunWhereInput>;
  automationRunId?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPetParent?: Maybe<ClinicPetParentWhereInput>;
  clinicPetParentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  feedback?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  score?: Maybe<IntFilter>;
  surveySentAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumSurveyTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SurveyResultWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SurveySetting = {
  __typename?: 'SurveySetting';
  _count: SurveySettingCountOutputType;
  appointmentTypeSettings: Array<SurveyAppointmentTypeSetting>;
  createdAt: Scalars['DateTime'];
  frequencyInDays: Scalars['Int'];
  id: Scalars['String'];
  isAutomatedTriggerForAllAppointmentTypes: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview: Scalars['Boolean'];
  showLinkToLeaveReviewNeutral: Scalars['Boolean'];
  showLinkToLeaveReviewSatisfied: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  workflowEventSettings: Array<WorkflowEventSetting>;
};


export type SurveySettingAppointmentTypeSettingsArgs = {
  cursor?: Maybe<SurveyAppointmentTypeSettingWhereUniqueInput>;
  distinct?: Maybe<SurveyAppointmentTypeSettingScalarFieldEnum>;
  orderBy?: Maybe<SurveyAppointmentTypeSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SurveyAppointmentTypeSettingWhereInput>;
};


export type SurveySettingWorkflowEventSettingsArgs = {
  cursor?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventSettingWhereInput>;
};

export type SurveySettingAvgAggregateOutputType = {
  __typename?: 'SurveySettingAvgAggregateOutputType';
  frequencyInDays?: Maybe<Scalars['Float']>;
};

export type SurveySettingAvgOrderByAggregateInput = {
  frequencyInDays?: Maybe<SortOrder>;
};

export type SurveySettingCountAggregateOutputType = {
  __typename?: 'SurveySettingCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  frequencyInDays: Scalars['Int'];
  id: Scalars['Int'];
  isAutomatedTriggerForAllAppointmentTypes: Scalars['Int'];
  isDeleted: Scalars['Int'];
  linkToLeaveReview: Scalars['Int'];
  showLinkToLeaveReview: Scalars['Int'];
  showLinkToLeaveReviewNeutral: Scalars['Int'];
  showLinkToLeaveReviewSatisfied: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SurveySettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveySettingCountOutputType = {
  __typename?: 'SurveySettingCountOutputType';
  appointmentTypeSettings: Scalars['Int'];
  workflowEventSettings: Scalars['Int'];
};

export type SurveySettingCreateInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingCreateNestedOneWithoutAppointmentTypeSettingsInput = {
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput>;
  create?: Maybe<SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput>;
};

export type SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput = {
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput>;
};

export type SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput = {
  create: SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput;
  where: SurveySettingWhereUniqueInput;
};

export type SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput = {
  create: SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput;
  where: SurveySettingWhereUniqueInput;
};

export type SurveySettingCreateWithoutAppointmentTypeSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingCreateWithoutWorkflowEventSettingsInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingCreateNestedManyWithoutSurveySettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingMaxAggregateOutputType = {
  __typename?: 'SurveySettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveySettingMinAggregateOutputType = {
  __typename?: 'SurveySettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SurveySettingOrderByRelevanceFieldEnum {
  Id = 'id',
  LinkToLeaveReview = 'linkToLeaveReview'
}

export type SurveySettingOrderByRelevanceInput = {
  fields: Array<SurveySettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SurveySettingOrderByWithAggregationInput = {
  _avg?: Maybe<SurveySettingAvgOrderByAggregateInput>;
  _count?: Maybe<SurveySettingCountOrderByAggregateInput>;
  _max?: Maybe<SurveySettingMaxOrderByAggregateInput>;
  _min?: Maybe<SurveySettingMinOrderByAggregateInput>;
  _sum?: Maybe<SurveySettingSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SurveySettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SurveySettingOrderByRelevanceInput>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  frequencyInDays?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  linkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReview?: Maybe<SortOrder>;
  showLinkToLeaveReviewNeutral?: Maybe<SortOrder>;
  showLinkToLeaveReviewSatisfied?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettings?: Maybe<WorkflowEventSettingOrderByRelationAggregateInput>;
};

export type SurveySettingRelationFilter = {
  is?: Maybe<SurveySettingWhereInput>;
  isNot?: Maybe<SurveySettingWhereInput>;
};

export enum SurveySettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  FrequencyInDays = 'frequencyInDays',
  Id = 'id',
  IsAutomatedTriggerForAllAppointmentTypes = 'isAutomatedTriggerForAllAppointmentTypes',
  IsDeleted = 'isDeleted',
  LinkToLeaveReview = 'linkToLeaveReview',
  ShowLinkToLeaveReview = 'showLinkToLeaveReview',
  ShowLinkToLeaveReviewNeutral = 'showLinkToLeaveReviewNeutral',
  ShowLinkToLeaveReviewSatisfied = 'showLinkToLeaveReviewSatisfied',
  UpdatedAt = 'updatedAt'
}

export type SurveySettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SurveySettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  frequencyInDays?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  linkToLeaveReview?: Maybe<StringNullableWithAggregatesFilter>;
  showLinkToLeaveReview?: Maybe<BoolWithAggregatesFilter>;
  showLinkToLeaveReviewNeutral?: Maybe<BoolWithAggregatesFilter>;
  showLinkToLeaveReviewSatisfied?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SurveySettingSumAggregateOutputType = {
  __typename?: 'SurveySettingSumAggregateOutputType';
  frequencyInDays?: Maybe<Scalars['Int']>;
};

export type SurveySettingSumOrderByAggregateInput = {
  frequencyInDays?: Maybe<SortOrder>;
};

export type SurveySettingUncheckedCreateInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
};

export type SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedCreateNestedManyWithoutSurveySettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingUncheckedUpdateInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUncheckedUpdateManyWithoutSurveySettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingUpdateInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingUpdateOneRequiredWithoutAppointmentTypeSettingsNestedInput = {
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutAppointmentTypeSettingsInput>;
  create?: Maybe<SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput>;
  update?: Maybe<SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput>;
  upsert?: Maybe<SurveySettingUpsertWithoutAppointmentTypeSettingsInput>;
};

export type SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput = {
  connect?: Maybe<SurveySettingWhereUniqueInput>;
  connectOrCreate?: Maybe<SurveySettingCreateOrConnectWithoutWorkflowEventSettingsInput>;
  create?: Maybe<SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput>;
  upsert?: Maybe<SurveySettingUpsertWithoutWorkflowEventSettingsInput>;
};

export type SurveySettingUpdateWithoutAppointmentTypeSettingsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettings?: Maybe<WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput>;
};

export type SurveySettingUpdateWithoutWorkflowEventSettingsInput = {
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingUpdateManyWithoutSurveySettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequencyInDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  linkToLeaveReview?: Maybe<Scalars['String']>;
  showLinkToLeaveReview?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewNeutral?: Maybe<Scalars['Boolean']>;
  showLinkToLeaveReviewSatisfied?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveySettingUpsertWithoutAppointmentTypeSettingsInput = {
  create: SurveySettingUncheckedCreateWithoutAppointmentTypeSettingsInput;
  update: SurveySettingUncheckedUpdateWithoutAppointmentTypeSettingsInput;
};

export type SurveySettingUpsertWithoutWorkflowEventSettingsInput = {
  create: SurveySettingUncheckedCreateWithoutWorkflowEventSettingsInput;
  update: SurveySettingUncheckedUpdateWithoutWorkflowEventSettingsInput;
};

export type SurveySettingWhereInput = {
  AND?: Maybe<Array<SurveySettingWhereInput>>;
  NOT?: Maybe<Array<SurveySettingWhereInput>>;
  OR?: Maybe<Array<SurveySettingWhereInput>>;
  appointmentTypeSettings?: Maybe<SurveyAppointmentTypeSettingListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  frequencyInDays?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isAutomatedTriggerForAllAppointmentTypes?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  linkToLeaveReview?: Maybe<StringNullableFilter>;
  showLinkToLeaveReview?: Maybe<BoolFilter>;
  showLinkToLeaveReviewNeutral?: Maybe<BoolFilter>;
  showLinkToLeaveReviewSatisfied?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSettings?: Maybe<WorkflowEventSettingListRelationFilter>;
};

export type SurveySettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SurveyStep {
  DetractorFeedback = 'DetractorFeedback',
  DissatisfiedFeedback = 'DissatisfiedFeedback',
  PassivePromoterFeedback = 'PassivePromoterFeedback',
  Rating = 'Rating',
  SatisfiedFeedback = 'SatisfiedFeedback'
}

export enum SurveyType {
  Csat = 'CSAT',
  Nps = 'NPS'
}

export type SyncManyPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  integrationTypes?: Maybe<Array<Maybe<ClinicPimsIntegrationType>>>;
  models?: Maybe<Array<PimsIntegrationCapability>>;
  syncFrom?: Maybe<Scalars['String']>;
};

export type SyncPimsIntegrationModelInput = {
  clinicId: Scalars['String'];
  integrationTypes?: Maybe<Array<Maybe<ClinicPimsIntegrationType>>>;
  model: PimsIntegrationCapability;
  syncFrom?: Maybe<Scalars['String']>;
};

export type SyncVetIntegration = {
  __typename?: 'SyncVetIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SyncVetIntegrationCountAggregateOutputType = {
  __typename?: 'SyncVetIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationStatus: Scalars['Int'];
  pimsName: Scalars['Int'];
  practiceId: Scalars['Int'];
  siteId: Scalars['Int'];
  syncvetId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SyncVetIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SyncVetIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutSyncvetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: SyncVetIntegrationWhereUniqueInput;
};

export type SyncVetIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationMaxAggregateOutputType = {
  __typename?: 'SyncVetIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SyncVetIntegrationMinAggregateOutputType = {
  __typename?: 'SyncVetIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum SyncVetIntegrationOrderByRelevanceFieldEnum {
  Id = 'id',
  PimsName = 'pimsName',
  PracticeId = 'practiceId',
  SiteId = 'siteId',
  SyncvetId = 'syncvetId'
}

export type SyncVetIntegrationOrderByRelevanceInput = {
  fields: Array<SyncVetIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type SyncVetIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<SyncVetIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<SyncVetIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<SyncVetIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SyncVetIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<SyncVetIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationStatus?: Maybe<SortOrder>;
  pimsName?: Maybe<SortOrder>;
  practiceId?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  syncvetId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SyncVetIntegrationRelationFilter = {
  is?: Maybe<SyncVetIntegrationWhereInput>;
  isNot?: Maybe<SyncVetIntegrationWhereInput>;
};

export enum SyncVetIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IntegrationStatus = 'integrationStatus',
  PimsName = 'pimsName',
  PracticeId = 'practiceId',
  SiteId = 'siteId',
  SyncvetId = 'syncvetId',
  UpdatedAt = 'updatedAt'
}

export type SyncVetIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<SyncVetIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationStatus?: Maybe<EnumIntegrationStatusNullableWithAggregatesFilter>;
  pimsName?: Maybe<StringWithAggregatesFilter>;
  practiceId?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  syncvetId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type SyncVetIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutSyncvetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName: Scalars['String'];
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutSyncvetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutSyncvetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<SyncVetIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<SyncVetIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<SyncVetIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type SyncVetIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationStatus?: Maybe<IntegrationStatus>;
  pimsName?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  syncvetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SyncVetIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: SyncVetIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: SyncVetIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type SyncVetIntegrationWhereInput = {
  AND?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  NOT?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  OR?: Maybe<Array<SyncVetIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationStatus?: Maybe<EnumIntegrationStatusNullableFilter>;
  pimsName?: Maybe<StringFilter>;
  practiceId?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  syncvetId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SyncVetIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SystemMessageCreateInput = {
  attributes?: Maybe<Scalars['Json']>;
  body?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  petParentIds?: Maybe<Array<Scalars['String']>>;
};

export type TagEntity = {
  id: Scalars['String'];
  label: Scalars['String'];
  type: TagEntityType;
};

export enum TagEntityType {
  ClinicPet = 'ClinicPet',
  FormSubmission = 'FormSubmission'
}

export type TagInput = {
  colorBackground?: Maybe<Scalars['String']>;
  colorText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tagEntities?: Maybe<Array<TagEntity>>;
  tagType?: Maybe<TagType>;
};

export enum TagType {
  Custom = 'Custom',
  FormRequested = 'FormRequested',
  FormSubmitted = 'FormSubmitted',
  PaymentReceived = 'PaymentReceived',
  PaymentRequested = 'PaymentRequested',
  TrupanionCandidate = 'TrupanionCandidate'
}

export enum TemplateAvailability {
  Care = 'Care',
  Clinic = 'Clinic',
  Global = 'Global',
  Organization = 'Organization'
}

export type TerminalAppPaymentResponse = {
  __typename?: 'TerminalAppPaymentResponse';
  clientSecret: Scalars['String'];
  paymentIntent: StripePaymentIntent;
};

export type TinyUrl = {
  __typename?: 'TinyUrl';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type TinyUrlCountAggregateOutputType = {
  __typename?: 'TinyUrlCountAggregateOutputType';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isActive: Scalars['Int'];
  token: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['Int'];
};

export type TinyUrlCountOrderByAggregateInput = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type TinyUrlCreateInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type TinyUrlCreateManyInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type TinyUrlMaxAggregateOutputType = {
  __typename?: 'TinyUrlMaxAggregateOutputType';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlMaxOrderByAggregateInput = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type TinyUrlMinAggregateOutputType = {
  __typename?: 'TinyUrlMinAggregateOutputType';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlMinOrderByAggregateInput = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum TinyUrlOrderByRelevanceFieldEnum {
  Code = 'code',
  Id = 'id',
  Token = 'token',
  Url = 'url'
}

export type TinyUrlOrderByRelevanceInput = {
  fields: Array<TinyUrlOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TinyUrlOrderByWithAggregationInput = {
  _count?: Maybe<TinyUrlCountOrderByAggregateInput>;
  _max?: Maybe<TinyUrlMaxOrderByAggregateInput>;
  _min?: Maybe<TinyUrlMinOrderByAggregateInput>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type TinyUrlOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TinyUrlOrderByRelevanceInput>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  token?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export enum TinyUrlScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsActive = 'isActive',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export type TinyUrlScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TinyUrlScalarWhereWithAggregatesInput>>;
  code?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isActive?: Maybe<BoolNullableWithAggregatesFilter>;
  token?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  url?: Maybe<StringWithAggregatesFilter>;
};

export type TinyUrlUncheckedCreateInput = {
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type TinyUrlUncheckedUpdateInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlUncheckedUpdateManyInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlUpdateManyMutationInput = {
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TinyUrlWhereInput = {
  AND?: Maybe<Array<TinyUrlWhereInput>>;
  NOT?: Maybe<Array<TinyUrlWhereInput>>;
  OR?: Maybe<Array<TinyUrlWhereInput>>;
  code?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
};

export type TinyUrlWhereUniqueInput = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type TokenOutput = {
  __typename?: 'TokenOutput';
  token: Scalars['String'];
};

export type TokenValidationInput = {
  clinicPetParentId: Scalars['String'];
};

/** @deprecated - 1.0 */
export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultation?: Maybe<Consultation>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disbursement?: Maybe<Disbursement>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source: PaymentSource;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TransactionAvgAggregateOutputType = {
  __typename?: 'TransactionAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
};

export type TransactionCountAggregateOutputType = {
  __typename?: 'TransactionCountAggregateOutputType';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  amountForSettlement: Scalars['Int'];
  amountSettled: Scalars['Int'];
  braintreeGraphqlId: Scalars['Int'];
  cardLast4: Scalars['Int'];
  cardType: Scalars['Int'];
  clinicEarnings: Scalars['Int'];
  consultationId: Scalars['Int'];
  createdAt: Scalars['Int'];
  disbursementDate: Scalars['Int'];
  disbursementId: Scalars['Int'];
  id: Scalars['Int'];
  serviceFeeAmount: Scalars['Int'];
  settlementDate: Scalars['Int'];
  source: Scalars['Int'];
  status: Scalars['Int'];
  transactionId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionCreateInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
  createdAt: Scalars['DateTime'];
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyConsultationInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyConsultationInputEnvelope = {
  data: Array<TransactionCreateManyConsultationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyDisbursementInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateManyDisbursementInputEnvelope = {
  data: Array<TransactionCreateManyDisbursementInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutDisbursementInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutConsultationInput = {
  create: TransactionUncheckedCreateWithoutConsultationInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutDisbursementInput = {
  create: TransactionUncheckedCreateWithoutDisbursementInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutConsultationInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  disbursement?: Maybe<DisbursementCreateNestedOneWithoutTransactionsInput>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionCreateWithoutDisbursementInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultation?: Maybe<ConsultationCreateNestedOneWithoutTransactionsInput>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum TransactionIsolationLevel {
  ReadCommitted = 'ReadCommitted',
  ReadUncommitted = 'ReadUncommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
};

export type TransactionMaxAggregateOutputType = {
  __typename?: 'TransactionMaxAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionMaxOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionMinAggregateOutputType = {
  __typename?: 'TransactionMinAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionMinOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TransactionOrderByRelevanceFieldEnum {
  BraintreeGraphqlId = 'braintreeGraphqlId',
  CardLast4 = 'cardLast4',
  CardType = 'cardType',
  ConsultationId = 'consultationId',
  DisbursementDate = 'disbursementDate',
  DisbursementId = 'disbursementId',
  Id = 'id',
  Status = 'status',
  TransactionId = 'transactionId'
}

export type TransactionOrderByRelevanceInput = {
  fields: Array<TransactionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: Maybe<TransactionAvgOrderByAggregateInput>;
  _count?: Maybe<TransactionCountOrderByAggregateInput>;
  _max?: Maybe<TransactionMaxOrderByAggregateInput>;
  _min?: Maybe<TransactionMinOrderByAggregateInput>;
  _sum?: Maybe<TransactionSumOrderByAggregateInput>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TransactionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TransactionOrderByRelevanceInput>;
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  braintreeGraphqlId?: Maybe<SortOrder>;
  cardLast4?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  consultation?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  consultationId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  disbursement?: Maybe<DisbursementOrderByWithRelationAndSearchRelevanceInput>;
  disbursementDate?: Maybe<SortOrder>;
  disbursementId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
  settlementDate?: Maybe<SortOrder>;
  source?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  transactionId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  Amount = 'amount',
  AmountForSettlement = 'amountForSettlement',
  AmountSettled = 'amountSettled',
  BraintreeGraphqlId = 'braintreeGraphqlId',
  CardLast4 = 'cardLast4',
  CardType = 'cardType',
  ClinicEarnings = 'clinicEarnings',
  ConsultationId = 'consultationId',
  CreatedAt = 'createdAt',
  DisbursementDate = 'disbursementDate',
  DisbursementId = 'disbursementId',
  Id = 'id',
  ServiceFeeAmount = 'serviceFeeAmount',
  SettlementDate = 'settlementDate',
  Source = 'source',
  Status = 'status',
  TransactionId = 'transactionId',
  UpdatedAt = 'updatedAt'
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  cardLast4?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  disbursementDate?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
  status?: Maybe<StringFilter>;
  transactionId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  amountForSettlement?: Maybe<FloatNullableWithAggregatesFilter>;
  amountSettled?: Maybe<FloatNullableWithAggregatesFilter>;
  braintreeGraphqlId?: Maybe<StringNullableWithAggregatesFilter>;
  cardLast4?: Maybe<StringWithAggregatesFilter>;
  cardType?: Maybe<StringWithAggregatesFilter>;
  clinicEarnings?: Maybe<FloatNullableWithAggregatesFilter>;
  consultationId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  disbursementDate?: Maybe<StringNullableWithAggregatesFilter>;
  disbursementId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  serviceFeeAmount?: Maybe<FloatNullableWithAggregatesFilter>;
  settlementDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  source?: Maybe<EnumPaymentSourceWithAggregatesFilter>;
  status?: Maybe<StringWithAggregatesFilter>;
  transactionId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type TransactionSumAggregateOutputType = {
  __typename?: 'TransactionSumAggregateOutputType';
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: Maybe<SortOrder>;
  amountForSettlement?: Maybe<SortOrder>;
  amountSettled?: Maybe<SortOrder>;
  clinicEarnings?: Maybe<SortOrder>;
  serviceFeeAmount?: Maybe<SortOrder>;
};

export type TransactionUncheckedCreateInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedCreateNestedManyWithoutConsultationInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
};

export type TransactionUncheckedCreateNestedManyWithoutDisbursementInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
};

export type TransactionUncheckedCreateWithoutConsultationInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedCreateWithoutDisbursementInput = {
  amount: Scalars['Float'];
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4: Scalars['String'];
  cardType: Scalars['String'];
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedUpdateManyInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type TransactionUncheckedUpdateManyWithoutDisbursementNestedInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
};

export type TransactionUncheckedUpdateManyWithoutTransactionsInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedUpdateWithoutConsultationInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  disbursementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUncheckedUpdateWithoutDisbursementInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsNestedInput>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUpdateManyWithWhereWithoutConsultationInput = {
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutDisbursementInput = {
  data: TransactionUncheckedUpdateManyWithoutTransactionsInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutConsultationNestedInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutConsultationInput>>;
  create?: Maybe<Array<TransactionCreateWithoutConsultationInput>>;
  createMany?: Maybe<TransactionCreateManyConsultationInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutConsultationInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutConsultationInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutConsultationInput>>;
};

export type TransactionUpdateManyWithoutDisbursementNestedInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutDisbursementInput>>;
  create?: Maybe<Array<TransactionCreateWithoutDisbursementInput>>;
  createMany?: Maybe<TransactionCreateManyDisbursementInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutDisbursementInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutDisbursementInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutDisbursementInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutConsultationInput = {
  data: TransactionUncheckedUpdateWithoutConsultationInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutDisbursementInput = {
  data: TransactionUncheckedUpdateWithoutDisbursementInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutConsultationInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursement?: Maybe<DisbursementUpdateOneWithoutTransactionsNestedInput>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUpdateWithoutDisbursementInput = {
  amount?: Maybe<Scalars['Float']>;
  amountForSettlement?: Maybe<Scalars['Float']>;
  amountSettled?: Maybe<Scalars['Float']>;
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  clinicEarnings?: Maybe<Scalars['Float']>;
  consultation?: Maybe<ConsultationUpdateOneWithoutTransactionsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  disbursementDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceFeeAmount?: Maybe<Scalars['Float']>;
  settlementDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<PaymentSource>;
  status?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransactionUpsertWithWhereUniqueWithoutConsultationInput = {
  create: TransactionUncheckedCreateWithoutConsultationInput;
  update: TransactionUncheckedUpdateWithoutConsultationInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutDisbursementInput = {
  create: TransactionUncheckedCreateWithoutDisbursementInput;
  update: TransactionUncheckedUpdateWithoutDisbursementInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  amount?: Maybe<FloatFilter>;
  amountForSettlement?: Maybe<FloatNullableFilter>;
  amountSettled?: Maybe<FloatNullableFilter>;
  braintreeGraphqlId?: Maybe<StringNullableFilter>;
  cardLast4?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  clinicEarnings?: Maybe<FloatNullableFilter>;
  consultation?: Maybe<ConsultationWhereInput>;
  consultationId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  disbursement?: Maybe<DisbursementWhereInput>;
  disbursementDate?: Maybe<StringNullableFilter>;
  disbursementId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  serviceFeeAmount?: Maybe<FloatNullableFilter>;
  settlementDate?: Maybe<DateTimeNullableFilter>;
  source?: Maybe<EnumPaymentSourceFilter>;
  status?: Maybe<StringFilter>;
  transactionId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransactionWhereUniqueInput = {
  braintreeGraphqlId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type TransferSubscriptionInput = {
  carePlanEnrollmentId: Scalars['String'];
  careStripeSubscriptionId: Scalars['String'];
  currentClinicPetId: Scalars['String'];
  newClinicPetId: Scalars['String'];
};

export type TransferSubscriptionOutput = {
  __typename?: 'TransferSubscriptionOutput';
  success: Scalars['Boolean'];
};

export type TransferTwilioNumberInput = {
  fromSubaccountSid?: Maybe<Scalars['String']>;
  phoneNumberSid: Scalars['String'];
};

export type TriggerMessageEventInput = {
  data?: Maybe<Scalars['Json']>;
  event?: Maybe<Scalars['String']>;
  queueName?: Maybe<SqsQueueNames>;
  useSubscription?: Maybe<Scalars['Boolean']>;
};

export type TriggerWorkflowEventInput = {
  actionId?: Maybe<Scalars['String']>;
  actionNumber?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  entityId: Scalars['String'];
  event: Scalars['String'];
  workflowId?: Maybe<Scalars['String']>;
};

export type TrupanionAvailability = {
  __typename?: 'TrupanionAvailability';
  clinicPetId: Scalars['String'];
  clinicPetName?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  pimsBreed?: Maybe<Scalars['String']>;
  speciesType?: Maybe<SpeciesType>;
  trupanionClosestBreed?: Maybe<Scalars['String']>;
  unavailableReasons?: Maybe<UnavailableReasons>;
};

export type TrupanionCertificateAvailability = {
  __typename?: 'TrupanionCertificateAvailability';
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityAvgAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityAvgAggregateOutputType';
  responseStatusCode?: Maybe<Scalars['Float']>;
};

export type TrupanionCertificateAvailabilityAvgOrderByAggregateInput = {
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityCountAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isAvailable: Scalars['Int'];
  requestBody: Scalars['Int'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons: Scalars['Int'];
};

export type TrupanionCertificateAvailabilityCountOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityCreateInput = {
  clinicPet: ClinicPetCreateNestedOneWithoutTrupanionCertificateAvailabilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityCreateManyClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope = {
  data: Array<TrupanionCertificateAvailabilityCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrupanionCertificateAvailabilityCreateManyInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
};

export type TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput = {
  create: TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};

export type TrupanionCertificateAvailabilityCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityInput = {
  clinicPetParentId: Scalars['String'];
};

export type TrupanionCertificateAvailabilityListRelationFilter = {
  every?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  none?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
  some?: Maybe<TrupanionCertificateAvailabilityWhereInput>;
};

export type TrupanionCertificateAvailabilityMaxAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityMaxAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
};

export type TrupanionCertificateAvailabilityMaxOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityMinAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilityMinAggregateOutputType';
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
};

export type TrupanionCertificateAvailabilityMinOrderByAggregateInput = {
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TrupanionCertificateAvailabilityOrderByRelevanceFieldEnum {
  ClinicPetId = 'clinicPetId',
  Id = 'id'
}

export type TrupanionCertificateAvailabilityOrderByRelevanceInput = {
  fields: Array<TrupanionCertificateAvailabilityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TrupanionCertificateAvailabilityOrderByWithAggregationInput = {
  _avg?: Maybe<TrupanionCertificateAvailabilityAvgOrderByAggregateInput>;
  _count?: Maybe<TrupanionCertificateAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<TrupanionCertificateAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionCertificateAvailabilityMinOrderByAggregateInput>;
  _sum?: Maybe<TrupanionCertificateAvailabilitySumOrderByAggregateInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TrupanionCertificateAvailabilityOrderByRelevanceInput>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  requestBody?: Maybe<SortOrder>;
  responseStatusCode?: Maybe<SortOrder>;
  unavailableReasons?: Maybe<SortOrder>;
};

export enum TrupanionCertificateAvailabilityScalarFieldEnum {
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsAvailable = 'isAvailable',
  RequestBody = 'requestBody',
  ResponseStatusCode = 'responseStatusCode',
  UnavailableReasons = 'unavailableReasons'
}

export type TrupanionCertificateAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolNullableFilter>;
  requestBody?: Maybe<JsonFilter>;
  responseStatusCode?: Maybe<IntFilter>;
  unavailableReasons?: Maybe<JsonNullableFilter>;
};

export type TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereWithAggregatesInput>>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isAvailable?: Maybe<BoolNullableWithAggregatesFilter>;
  requestBody?: Maybe<JsonWithAggregatesFilter>;
  responseStatusCode?: Maybe<IntWithAggregatesFilter>;
  unavailableReasons?: Maybe<JsonNullableWithAggregatesFilter>;
};

export type TrupanionCertificateAvailabilitySumAggregateOutputType = {
  __typename?: 'TrupanionCertificateAvailabilitySumAggregateOutputType';
  responseStatusCode?: Maybe<Scalars['Int']>;
};

export type TrupanionCertificateAvailabilitySumOrderByAggregateInput = {
  responseStatusCode?: Maybe<SortOrder>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateInput = {
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
};

export type TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody: Scalars['Json'];
  responseStatusCode: Scalars['Int'];
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyInput = {
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutTrupanionCertificateAvailabilitiesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpdateInput = {
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutTrupanionCertificateAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput = {
  data: TrupanionCertificateAvailabilityUncheckedUpdateManyWithoutTrupanionCertificateAvailabilitiesInput;
  where: TrupanionCertificateAvailabilityScalarWhereInput;
};

export type TrupanionCertificateAvailabilityUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionCertificateAvailabilityCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<TrupanionCertificateAvailabilityCreateWithoutClinicPetInput>>;
  createMany?: Maybe<TrupanionCertificateAvailabilityCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TrupanionCertificateAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<TrupanionCertificateAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<TrupanionCertificateAvailabilityUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type TrupanionCertificateAvailabilityUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};

export type TrupanionCertificateAvailabilityUpdateWithoutClinicPetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requestBody?: Maybe<Scalars['Json']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  unavailableReasons?: Maybe<Scalars['Json']>;
};

export type TrupanionCertificateAvailabilityUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: TrupanionCertificateAvailabilityUncheckedCreateWithoutClinicPetInput;
  update: TrupanionCertificateAvailabilityUncheckedUpdateWithoutClinicPetInput;
  where: TrupanionCertificateAvailabilityWhereUniqueInput;
};

export type TrupanionCertificateAvailabilityWhereInput = {
  AND?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  NOT?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  OR?: Maybe<Array<TrupanionCertificateAvailabilityWhereInput>>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isAvailable?: Maybe<BoolNullableFilter>;
  requestBody?: Maybe<JsonFilter>;
  responseStatusCode?: Maybe<IntFilter>;
  unavailableReasons?: Maybe<JsonNullableFilter>;
};

export type TrupanionCertificateAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TrupanionHospital = {
  __typename?: 'TrupanionHospital';
  Address1?: Maybe<Scalars['String']>;
  Address2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  Id: Scalars['String'];
  IsCertificateOptedIn?: Maybe<Scalars['Boolean']>;
  IsoAlpha3CountryCode?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  LegacyId?: Maybe<Scalars['String']>;
  Lon?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  StateCode?: Maybe<Scalars['String']>;
  VetDirectPayAccepted?: Maybe<Scalars['Boolean']>;
};

export type TrupanionHospitalSearchInput = {
  clinicId: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Int']>;
};

export type TrupanionIntegratedAppointmentType = {
  __typename?: 'TrupanionIntegratedAppointmentType';
  appointmentType: AppointmentType;
  appointmentTypeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  trupanionIntegration: TrupanionIntegration;
  trupanionIntegrationId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TrupanionIntegratedAppointmentTypeCountAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentTypeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  trupanionIntegrationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TrupanionIntegratedAppointmentTypeCountOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeCreateInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeCreateManyInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope = {
  data: Array<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput = {
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
};

export type TrupanionIntegratedAppointmentTypeCreateNestedOneWithoutAppointmentTypeInput = {
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput = {
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};

export type TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput = {
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};

export type TrupanionIntegratedAppointmentTypeCreateWithoutAppointmentTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegration: TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput = {
  appointmentType: AppointmentTypeCreateNestedOneWithoutTrupanionIntegratedAppointmentTypeInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeListRelationFilter = {
  every?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  none?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  some?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};

export type TrupanionIntegratedAppointmentTypeMaxAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeMaxAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeMaxOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeMinAggregateOutputType = {
  __typename?: 'TrupanionIntegratedAppointmentTypeMinAggregateOutputType';
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeMinOrderByAggregateInput = {
  appointmentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TrupanionIntegratedAppointmentTypeOrderByRelevanceFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  Id = 'id',
  TrupanionIntegrationId = 'trupanionIntegrationId'
}

export type TrupanionIntegratedAppointmentTypeOrderByRelevanceInput = {
  fields: Array<TrupanionIntegratedAppointmentTypeOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TrupanionIntegratedAppointmentTypeOrderByWithAggregationInput = {
  _count?: Maybe<TrupanionIntegratedAppointmentTypeCountOrderByAggregateInput>;
  _max?: Maybe<TrupanionIntegratedAppointmentTypeMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionIntegratedAppointmentTypeMinOrderByAggregateInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TrupanionIntegratedAppointmentTypeOrderByRelevanceInput>;
  appointmentType?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  appointmentTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  trupanionIntegration?: Maybe<TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  trupanionIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegratedAppointmentTypeRelationFilter = {
  is?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
  isNot?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};

export enum TrupanionIntegratedAppointmentTypeScalarFieldEnum {
  AppointmentTypeId = 'appointmentTypeId',
  CreatedAt = 'createdAt',
  Id = 'id',
  TrupanionIntegrationId = 'trupanionIntegrationId',
  UpdatedAt = 'updatedAt'
}

export type TrupanionIntegratedAppointmentTypeScalarWhereInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  appointmentTypeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereWithAggregatesInput>>;
  appointmentTypeId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  trupanionIntegrationId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput = {
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateNestedOneWithoutAppointmentTypeInput = {
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput = {
  appointmentTypeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegratedAppointmentTypesInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput = {
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  delete?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateOneWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput>;
  upsert?: Maybe<TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput = {
  data: TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegratedAppointmentTypesInput;
  where: TrupanionIntegratedAppointmentTypeScalarWhereInput;
};

export type TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput = {
  connect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutTrupanionIntegrationInput>>;
  create?: Maybe<Array<TrupanionIntegratedAppointmentTypeCreateWithoutTrupanionIntegrationInput>>;
  createMany?: Maybe<TrupanionIntegratedAppointmentTypeCreateManyTrupanionIntegrationInputEnvelope>;
  delete?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  set?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereUniqueInput>>;
  update?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput>>;
  updateMany?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpdateManyWithWhereWithoutTrupanionIntegrationInput>>;
  upsert?: Maybe<Array<TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput>>;
};

export type TrupanionIntegratedAppointmentTypeUpdateOneWithoutAppointmentTypeNestedInput = {
  connect?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegratedAppointmentTypeCreateOrConnectWithoutAppointmentTypeInput>;
  create?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput>;
  upsert?: Maybe<TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput>;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithWhereUniqueWithoutTrupanionIntegrationInput = {
  data: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithoutAppointmentTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  trupanionIntegration?: Maybe<TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpdateWithoutTrupanionIntegrationInput = {
  appointmentType?: Maybe<AppointmentTypeUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypeNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegratedAppointmentTypeUpsertWithWhereUniqueWithoutTrupanionIntegrationInput = {
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutTrupanionIntegrationInput;
  update: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutTrupanionIntegrationInput;
  where: TrupanionIntegratedAppointmentTypeWhereUniqueInput;
};

export type TrupanionIntegratedAppointmentTypeUpsertWithoutAppointmentTypeInput = {
  create: TrupanionIntegratedAppointmentTypeUncheckedCreateWithoutAppointmentTypeInput;
  update: TrupanionIntegratedAppointmentTypeUncheckedUpdateWithoutAppointmentTypeInput;
};

export type TrupanionIntegratedAppointmentTypeWhereInput = {
  AND?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegratedAppointmentTypeWhereInput>>;
  appointmentType?: Maybe<AppointmentTypeWhereInput>;
  appointmentTypeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  trupanionIntegration?: Maybe<TrupanionIntegrationWhereInput>;
  trupanionIntegrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TrupanionIntegratedAppointmentTypeWhereUniqueInput = {
  appointmentTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type TrupanionIntegration = {
  __typename?: 'TrupanionIntegration';
  _count: TrupanionIntegrationCountOutputType;
  createdAt: Scalars['DateTime'];
  hospitalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  insuranceIntegration: InsuranceIntegration;
  insuranceIntegrationId: Scalars['String'];
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  trupanionIntegratedAppointmentTypes: Array<TrupanionIntegratedAppointmentType>;
  updatedAt: Scalars['DateTime'];
};


export type TrupanionIntegrationSentTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type TrupanionIntegrationTrupanionIntegratedAppointmentTypesArgs = {
  cursor?: Maybe<TrupanionIntegratedAppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<TrupanionIntegratedAppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<TrupanionIntegratedAppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TrupanionIntegratedAppointmentTypeWhereInput>;
};

export type TrupanionIntegrationCountAggregateOutputType = {
  __typename?: 'TrupanionIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  hospitalId: Scalars['Int'];
  id: Scalars['Int'];
  insuranceIntegrationId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TrupanionIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegrationCountOutputType = {
  __typename?: 'TrupanionIntegrationCountOutputType';
  sentTrupanionExamDayOffers: Scalars['Int'];
  trupanionIntegratedAppointmentTypes: Scalars['Int'];
};

export type TrupanionIntegrationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationCreateNestedOneWithoutInsuranceIntegrationInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
};

export type TrupanionIntegrationCreateNestedOneWithoutTrupanionIntegratedAppointmentTypesInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput>;
};

export type TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput;
  where: TrupanionIntegrationWhereUniqueInput;
};

export type TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  where: TrupanionIntegrationWhereUniqueInput;
};

export type TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput;
  where: TrupanionIntegrationWhereUniqueInput;
};

export type TrupanionIntegrationCreateWithoutInsuranceIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationCreateWithoutSentTrupanionExamDayOffersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationCreateWithoutTrupanionIntegratedAppointmentTypesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration: InsuranceIntegrationCreateNestedOneWithoutTrupanionIntegrationInput;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationMaxAggregateOutputType = {
  __typename?: 'TrupanionIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegrationMinAggregateOutputType = {
  __typename?: 'TrupanionIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum TrupanionIntegrationOrderByRelevanceFieldEnum {
  HospitalId = 'hospitalId',
  Id = 'id',
  InsuranceIntegrationId = 'insuranceIntegrationId'
}

export type TrupanionIntegrationOrderByRelevanceInput = {
  fields: Array<TrupanionIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TrupanionIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<TrupanionIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<TrupanionIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<TrupanionIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TrupanionIntegrationOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  hospitalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  insuranceIntegration?: Maybe<InsuranceIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  insuranceIntegrationId?: Maybe<SortOrder>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
};

export type TrupanionIntegrationRelationFilter = {
  is?: Maybe<TrupanionIntegrationWhereInput>;
  isNot?: Maybe<TrupanionIntegrationWhereInput>;
};

export enum TrupanionIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  HospitalId = 'hospitalId',
  Id = 'id',
  InsuranceIntegrationId = 'insuranceIntegrationId',
  UpdatedAt = 'updatedAt'
}

export type TrupanionIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TrupanionIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  hospitalId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  insuranceIntegrationId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type TrupanionIntegrationUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId: Scalars['String'];
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedCreateNestedOneWithoutInsuranceIntegrationInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId: Scalars['String'];
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId: Scalars['String'];
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutTrupanionIntegrationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedUpdateOneWithoutInsuranceIntegrationNestedInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutSentTrupanionExamDayOffersInput>;
};

export type TrupanionIntegrationUpdateOneRequiredWithoutTrupanionIntegratedAppointmentTypesNestedInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutTrupanionIntegratedAppointmentTypesInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutTrupanionIntegratedAppointmentTypesInput>;
};

export type TrupanionIntegrationUpdateOneWithoutInsuranceIntegrationNestedInput = {
  connect?: Maybe<TrupanionIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<TrupanionIntegrationCreateOrConnectWithoutInsuranceIntegrationInput>;
  create?: Maybe<TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput>;
  upsert?: Maybe<TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput>;
};

export type TrupanionIntegrationUpdateWithoutInsuranceIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpdateWithoutSentTrupanionExamDayOffersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpdateWithoutTrupanionIntegratedAppointmentTypesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hospitalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceIntegration?: Maybe<InsuranceIntegrationUpdateOneRequiredWithoutTrupanionIntegrationNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutTrupanionIntegrationNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TrupanionIntegrationUpsertWithoutInsuranceIntegrationInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutInsuranceIntegrationInput;
  update: TrupanionIntegrationUncheckedUpdateWithoutInsuranceIntegrationInput;
};

export type TrupanionIntegrationUpsertWithoutSentTrupanionExamDayOffersInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  update: TrupanionIntegrationUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
};

export type TrupanionIntegrationUpsertWithoutTrupanionIntegratedAppointmentTypesInput = {
  create: TrupanionIntegrationUncheckedCreateWithoutTrupanionIntegratedAppointmentTypesInput;
  update: TrupanionIntegrationUncheckedUpdateWithoutTrupanionIntegratedAppointmentTypesInput;
};

export type TrupanionIntegrationWhereInput = {
  AND?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  NOT?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  OR?: Maybe<Array<TrupanionIntegrationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  hospitalId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  insuranceIntegration?: Maybe<InsuranceIntegrationWhereInput>;
  insuranceIntegrationId?: Maybe<StringFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  trupanionIntegratedAppointmentTypes?: Maybe<TrupanionIntegratedAppointmentTypeListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TrupanionIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  insuranceIntegrationId?: Maybe<Scalars['String']>;
};

export type TrupanionIssueCertificateDataInput = {
  isSmsOptedIn?: Maybe<Scalars['Boolean']>;
  trupanionBreed?: Maybe<Scalars['String']>;
};

export type TrupanionIssueCertificateResponse = {
  __typename?: 'TrupanionIssueCertificateResponse';
  errorMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  referenceNumber?: Maybe<Scalars['String']>;
};

export type TrupanionIssueCertificateWhereInput = {
  clinicPetId: Scalars['String'];
  clinicPetParentId: Scalars['String'];
};

export type TwilioCallRecording = {
  __typename?: 'TwilioCallRecording';
  callRecording: CallRecording;
  callRecordingId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status: TwilioRecordingStatus;
  updatedAt: Scalars['DateTime'];
  uri: Scalars['String'];
};

export type TwilioCallRecordingAvgAggregateOutputType = {
  __typename?: 'TwilioCallRecordingAvgAggregateOutputType';
  errorCode?: Maybe<Scalars['Float']>;
};

export type TwilioCallRecordingAvgOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCountAggregateOutputType = {
  __typename?: 'TwilioCallRecordingCountAggregateOutputType';
  _all: Scalars['Int'];
  callRecordingId: Scalars['Int'];
  createdAt: Scalars['Int'];
  errorCode: Scalars['Int'];
  sid: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  uri: Scalars['Int'];
};

export type TwilioCallRecordingCountOrderByAggregateInput = {
  callRecordingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  sid?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
};

export type TwilioCallRecordingCreateInput = {
  callRecording: CallRecordingCreateNestedOneWithoutTwilioCallRecordingInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
};

export type TwilioCallRecordingCreateManyInput = {
  callRecordingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
};

export type TwilioCallRecordingCreateNestedOneWithoutCallRecordingInput = {
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput = {
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
  where: TwilioCallRecordingWhereUniqueInput;
};

export type TwilioCallRecordingCreateWithoutCallRecordingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
};

export type TwilioCallRecordingMaxAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMaxAggregateOutputType';
  callRecordingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMaxOrderByAggregateInput = {
  callRecordingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  sid?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
};

export type TwilioCallRecordingMinAggregateOutputType = {
  __typename?: 'TwilioCallRecordingMinAggregateOutputType';
  callRecordingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingMinOrderByAggregateInput = {
  callRecordingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  sid?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
};

export enum TwilioCallRecordingOrderByRelevanceFieldEnum {
  CallRecordingId = 'callRecordingId',
  Sid = 'sid',
  Uri = 'uri'
}

export type TwilioCallRecordingOrderByRelevanceInput = {
  fields: Array<TwilioCallRecordingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TwilioCallRecordingOrderByWithAggregationInput = {
  _avg?: Maybe<TwilioCallRecordingAvgOrderByAggregateInput>;
  _count?: Maybe<TwilioCallRecordingCountOrderByAggregateInput>;
  _max?: Maybe<TwilioCallRecordingMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioCallRecordingMinOrderByAggregateInput>;
  _sum?: Maybe<TwilioCallRecordingSumOrderByAggregateInput>;
  callRecordingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  sid?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
};

export type TwilioCallRecordingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TwilioCallRecordingOrderByRelevanceInput>;
  callRecording?: Maybe<CallRecordingOrderByWithRelationAndSearchRelevanceInput>;
  callRecordingId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  errorCode?: Maybe<SortOrder>;
  sid?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uri?: Maybe<SortOrder>;
};

export type TwilioCallRecordingRelationFilter = {
  is?: Maybe<TwilioCallRecordingWhereInput>;
  isNot?: Maybe<TwilioCallRecordingWhereInput>;
};

export enum TwilioCallRecordingScalarFieldEnum {
  CallRecordingId = 'callRecordingId',
  CreatedAt = 'createdAt',
  ErrorCode = 'errorCode',
  Sid = 'sid',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Uri = 'uri'
}

export type TwilioCallRecordingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioCallRecordingScalarWhereWithAggregatesInput>>;
  callRecordingId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  errorCode?: Maybe<IntNullableWithAggregatesFilter>;
  sid?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumTwilioRecordingStatusWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  uri?: Maybe<StringWithAggregatesFilter>;
};

export type TwilioCallRecordingSubscription = {
  __typename?: 'TwilioCallRecordingSubscription';
  mutation: MutationType;
  node: TwilioCallRecording;
};

export type TwilioCallRecordingSumAggregateOutputType = {
  __typename?: 'TwilioCallRecordingSumAggregateOutputType';
  errorCode?: Maybe<Scalars['Int']>;
};

export type TwilioCallRecordingSumOrderByAggregateInput = {
  errorCode?: Maybe<SortOrder>;
};

export type TwilioCallRecordingUncheckedCreateInput = {
  callRecordingId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
};

export type TwilioCallRecordingUncheckedCreateNestedOneWithoutCallRecordingInput = {
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid: Scalars['String'];
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri: Scalars['String'];
};

export type TwilioCallRecordingUncheckedUpdateInput = {
  callRecordingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateManyInput = {
  callRecordingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUncheckedUpdateOneWithoutCallRecordingNestedInput = {
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUpdateInput = {
  callRecording?: Maybe<CallRecordingUpdateOneRequiredWithoutTwilioCallRecordingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUpdateOneWithoutCallRecordingNestedInput = {
  connect?: Maybe<TwilioCallRecordingWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioCallRecordingCreateOrConnectWithoutCallRecordingInput>;
  create?: Maybe<TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput>;
  upsert?: Maybe<TwilioCallRecordingUpsertWithoutCallRecordingInput>;
};

export type TwilioCallRecordingUpdateWithoutCallRecordingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['Int']>;
  sid?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioRecordingStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
};

export type TwilioCallRecordingUpsertWithoutCallRecordingInput = {
  create: TwilioCallRecordingUncheckedCreateWithoutCallRecordingInput;
  update: TwilioCallRecordingUncheckedUpdateWithoutCallRecordingInput;
};

export type TwilioCallRecordingWhereInput = {
  AND?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  NOT?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  OR?: Maybe<Array<TwilioCallRecordingWhereInput>>;
  callRecording?: Maybe<CallRecordingWhereInput>;
  callRecordingId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errorCode?: Maybe<IntNullableFilter>;
  sid?: Maybe<StringFilter>;
  status?: Maybe<EnumTwilioRecordingStatusFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uri?: Maybe<StringFilter>;
};

export type TwilioCallRecordingWhereUniqueInput = {
  callRecordingId?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
};

export type TwilioCallerIdVerification = {
  __typename?: 'TwilioCallerIdVerification';
  callSid: Scalars['String'];
  validationCode: Scalars['String'];
};

export type TwilioCapabilityToken = {
  __typename?: 'TwilioCapabilityToken';
  token: Scalars['String'];
};

export enum TwilioCapabilityType {
  Phone = 'Phone',
  Video = 'Video'
}

export type TwilioConfiguration = {
  __typename?: 'TwilioConfiguration';
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TwilioConfigurationCountAggregateOutputType = {
  __typename?: 'TwilioConfigurationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  messagingServiceSid: Scalars['Int'];
  subaccountSid: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TwilioConfigurationCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioConfigurationCreateInput = {
  clinic: ClinicCreateNestedOneWithoutTwilioConfigurationInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
};

export type TwilioConfigurationCreateOrConnectWithoutClinicInput = {
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
  where: TwilioConfigurationWhereUniqueInput;
};

export type TwilioConfigurationCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationMaxAggregateOutputType = {
  __typename?: 'TwilioConfigurationMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioConfigurationMinAggregateOutputType = {
  __typename?: 'TwilioConfigurationMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum TwilioConfigurationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  MessagingServiceSid = 'messagingServiceSid',
  SubaccountSid = 'subaccountSid'
}

export type TwilioConfigurationOrderByRelevanceInput = {
  fields: Array<TwilioConfigurationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TwilioConfigurationOrderByWithAggregationInput = {
  _count?: Maybe<TwilioConfigurationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioConfigurationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioConfigurationMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioConfigurationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TwilioConfigurationOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  messagingServiceSid?: Maybe<SortOrder>;
  subaccountSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioConfigurationRelationFilter = {
  is?: Maybe<TwilioConfigurationWhereInput>;
  isNot?: Maybe<TwilioConfigurationWhereInput>;
};

export enum TwilioConfigurationScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MessagingServiceSid = 'messagingServiceSid',
  SubaccountSid = 'subaccountSid',
  UpdatedAt = 'updatedAt'
}

export type TwilioConfigurationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioConfigurationScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  messagingServiceSid?: Maybe<StringNullableWithAggregatesFilter>;
  subaccountSid?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type TwilioConfigurationUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUncheckedCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
};

export type TwilioConfigurationUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUncheckedUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
};

export type TwilioConfigurationUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioConfigurationNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<TwilioConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<TwilioConfigurationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<TwilioConfigurationUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TwilioConfigurationUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<TwilioConfigurationUpsertWithoutClinicInput>;
};

export type TwilioConfigurationUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  subaccountSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioConfigurationUpsertWithoutClinicInput = {
  create: TwilioConfigurationUncheckedCreateWithoutClinicInput;
  update: TwilioConfigurationUncheckedUpdateWithoutClinicInput;
};

export type TwilioConfigurationWhereInput = {
  AND?: Maybe<Array<TwilioConfigurationWhereInput>>;
  NOT?: Maybe<Array<TwilioConfigurationWhereInput>>;
  OR?: Maybe<Array<TwilioConfigurationWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  messagingServiceSid?: Maybe<StringNullableFilter>;
  subaccountSid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TwilioConfigurationWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum TwilioRecordingStatus {
  Absent = 'Absent',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Processing = 'Processing',
  Stopped = 'Stopped'
}

export type TwilioVerification = {
  __typename?: 'TwilioVerification';
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  status: TwilioVerificationStatus;
  twilioCallSid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TwilioVerificationCountAggregateOutputType = {
  __typename?: 'TwilioVerificationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  phoneNumber: Scalars['Int'];
  status: Scalars['Int'];
  twilioCallSid: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type TwilioVerificationCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioVerificationCreateInput = {
  clinic: ClinicCreateNestedOneWithoutTwilioVerificationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationCreateManyClinicInputEnvelope = {
  data: Array<TwilioVerificationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TwilioVerificationCreateManyInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
};

export type TwilioVerificationCreateOrConnectWithoutClinicInput = {
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
  where: TwilioVerificationWhereUniqueInput;
};

export type TwilioVerificationCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationListRelationFilter = {
  every?: Maybe<TwilioVerificationWhereInput>;
  none?: Maybe<TwilioVerificationWhereInput>;
  some?: Maybe<TwilioVerificationWhereInput>;
};

export type TwilioVerificationMaxAggregateOutputType = {
  __typename?: 'TwilioVerificationMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioVerificationMinAggregateOutputType = {
  __typename?: 'TwilioVerificationMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioVerificationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum TwilioVerificationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  PhoneNumber = 'phoneNumber',
  TwilioCallSid = 'twilioCallSid'
}

export type TwilioVerificationOrderByRelevanceInput = {
  fields: Array<TwilioVerificationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type TwilioVerificationOrderByWithAggregationInput = {
  _count?: Maybe<TwilioVerificationCountOrderByAggregateInput>;
  _max?: Maybe<TwilioVerificationMaxOrderByAggregateInput>;
  _min?: Maybe<TwilioVerificationMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TwilioVerificationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<TwilioVerificationOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  twilioCallSid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum TwilioVerificationScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PhoneNumber = 'phoneNumber',
  Status = 'status',
  TwilioCallSid = 'twilioCallSid',
  UpdatedAt = 'updatedAt'
}

export type TwilioVerificationScalarWhereInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TwilioVerificationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<TwilioVerificationScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  phoneNumber?: Maybe<StringWithAggregatesFilter>;
  status?: Maybe<EnumTwilioVerificationStatusWithAggregatesFilter>;
  twilioCallSid?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum TwilioVerificationStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success'
}

export type TwilioVerificationSubscription = {
  __typename?: 'TwilioVerificationSubscription';
  mutation: MutationType;
  node: TwilioVerification;
};

export type TwilioVerificationUncheckedCreateInput = {
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
};

export type TwilioVerificationUncheckedCreateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUncheckedUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutTwilioVerificationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUpdateManyWithWhereWithoutClinicInput = {
  data: TwilioVerificationUncheckedUpdateManyWithoutTwilioVerificationsInput;
  where: TwilioVerificationScalarWhereInput;
};

export type TwilioVerificationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TwilioVerificationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<TwilioVerificationCreateWithoutClinicInput>>;
  createMany?: Maybe<TwilioVerificationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TwilioVerificationScalarWhereInput>>;
  disconnect?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  set?: Maybe<Array<TwilioVerificationWhereUniqueInput>>;
  update?: Maybe<Array<TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<TwilioVerificationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type TwilioVerificationUpdateWithWhereUniqueWithoutClinicInput = {
  data: TwilioVerificationUncheckedUpdateWithoutClinicInput;
  where: TwilioVerificationWhereUniqueInput;
};

export type TwilioVerificationUpdateWithoutClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<TwilioVerificationStatus>;
  twilioCallSid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioVerificationUpsertWithWhereUniqueWithoutClinicInput = {
  create: TwilioVerificationUncheckedCreateWithoutClinicInput;
  update: TwilioVerificationUncheckedUpdateWithoutClinicInput;
  where: TwilioVerificationWhereUniqueInput;
};

export type TwilioVerificationWhereInput = {
  AND?: Maybe<Array<TwilioVerificationWhereInput>>;
  NOT?: Maybe<Array<TwilioVerificationWhereInput>>;
  OR?: Maybe<Array<TwilioVerificationWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  status?: Maybe<EnumTwilioVerificationStatusFilter>;
  twilioCallSid?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TwilioVerificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  twilioCallSid?: Maybe<Scalars['String']>;
};

export type TwilioVerifyCallerIdInput = {
  callDelay?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  friendlyName: Scalars['String'];
  isCallerId?: Maybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};

export type UiRefresh = {
  __typename?: 'UiRefresh';
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
  refreshType: UiRefreshType;
};

export type UiRefreshInput = {
  message: Scalars['String'];
  metadata?: Maybe<Scalars['Json']>;
  refreshType: UiRefreshType;
};

export enum UiRefreshType {
  HardRefresh = 'HardRefresh',
  SuggestRefresh = 'SuggestRefresh'
}

export type UnavailableReasons = {
  __typename?: 'UnavailableReasons';
  IsActiveOrPendingCertificateFound?: Maybe<Scalars['Boolean']>;
  IsAdoptionDateOutsideOfActivationWindow?: Maybe<Scalars['Boolean']>;
  IsEmailAssociatedToManyPolicies?: Maybe<Scalars['Boolean']>;
  IsExamDateOutsideOfActivationWindow?: Maybe<Scalars['Boolean']>;
  IsPetAlreadyEnrolled?: Maybe<Scalars['Boolean']>;
  IsPetPolicyCancelled?: Maybe<Scalars['Boolean']>;
};

export type UnlayerSetting = {
  __typename?: 'UnlayerSetting';
  _count: UnlayerSettingCountOutputType;
  clinics: Array<Clinic>;
  createdAt: Scalars['DateTime'];
  emailTemplates: Array<EmailTemplate>;
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type UnlayerSettingClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type UnlayerSettingEmailTemplatesArgs = {
  cursor?: Maybe<EmailTemplateWhereUniqueInput>;
  distinct?: Maybe<EmailTemplateScalarFieldEnum>;
  orderBy?: Maybe<EmailTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailTemplateWhereInput>;
};

export type UnlayerSettingAvgAggregateOutputType = {
  __typename?: 'UnlayerSettingAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']>;
};

export type UnlayerSettingAvgOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type UnlayerSettingCountAggregateOutputType = {
  __typename?: 'UnlayerSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UnlayerSettingCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingCountOutputType = {
  __typename?: 'UnlayerSettingCountOutputType';
  clinics: Scalars['Int'];
  emailTemplates: Scalars['Int'];
};

export type UnlayerSettingCreateInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutUnlayerSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateCreateNestedManyWithoutUnlayerSettingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingCreateNestedOneWithoutClinicsInput = {
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutClinicsInput>;
};

export type UnlayerSettingCreateNestedOneWithoutEmailTemplatesInput = {
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput>;
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput>;
};

export type UnlayerSettingCreateOrConnectWithoutClinicsInput = {
  create: UnlayerSettingUncheckedCreateWithoutClinicsInput;
  where: UnlayerSettingWhereUniqueInput;
};

export type UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput = {
  create: UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput;
  where: UnlayerSettingWhereUniqueInput;
};

export type UnlayerSettingCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateCreateNestedManyWithoutUnlayerSettingInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingCreateWithoutEmailTemplatesInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutUnlayerSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingMaxAggregateOutputType = {
  __typename?: 'UnlayerSettingMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingMinAggregateOutputType = {
  __typename?: 'UnlayerSettingMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingOrderByWithAggregationInput = {
  _avg?: Maybe<UnlayerSettingAvgOrderByAggregateInput>;
  _count?: Maybe<UnlayerSettingCountOrderByAggregateInput>;
  _max?: Maybe<UnlayerSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UnlayerSettingMinOrderByAggregateInput>;
  _sum?: Maybe<UnlayerSettingSumOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingOrderByWithRelationAndSearchRelevanceInput = {
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  emailTemplates?: Maybe<EmailTemplateOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnlayerSettingRelationFilter = {
  is?: Maybe<UnlayerSettingWhereInput>;
  isNot?: Maybe<UnlayerSettingWhereInput>;
};

export enum UnlayerSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type UnlayerSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UnlayerSettingScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<IntWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type UnlayerSettingSumAggregateOutputType = {
  __typename?: 'UnlayerSettingSumAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
};

export type UnlayerSettingSumOrderByAggregateInput = {
  id?: Maybe<SortOrder>;
};

export type UnlayerSettingUncheckedCreateInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedCreateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUnlayerSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedUpdateInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUnlayerSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpdateInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutUnlayerSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpdateOneRequiredWithoutEmailTemplatesNestedInput = {
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutEmailTemplatesInput>;
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput>;
  update?: Maybe<UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput>;
  upsert?: Maybe<UnlayerSettingUpsertWithoutEmailTemplatesInput>;
};

export type UnlayerSettingUpdateOneWithoutClinicsNestedInput = {
  connect?: Maybe<UnlayerSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UnlayerSettingCreateOrConnectWithoutClinicsInput>;
  create?: Maybe<UnlayerSettingUncheckedCreateWithoutClinicsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UnlayerSettingUncheckedUpdateWithoutClinicsInput>;
  upsert?: Maybe<UnlayerSettingUpsertWithoutClinicsInput>;
};

export type UnlayerSettingUpdateWithoutClinicsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailTemplates?: Maybe<EmailTemplateUpdateManyWithoutUnlayerSettingNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpdateWithoutEmailTemplatesInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutUnlayerSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnlayerSettingUpsertWithoutClinicsInput = {
  create: UnlayerSettingUncheckedCreateWithoutClinicsInput;
  update: UnlayerSettingUncheckedUpdateWithoutClinicsInput;
};

export type UnlayerSettingUpsertWithoutEmailTemplatesInput = {
  create: UnlayerSettingUncheckedCreateWithoutEmailTemplatesInput;
  update: UnlayerSettingUncheckedUpdateWithoutEmailTemplatesInput;
};

export type UnlayerSettingWhereInput = {
  AND?: Maybe<Array<UnlayerSettingWhereInput>>;
  NOT?: Maybe<Array<UnlayerSettingWhereInput>>;
  OR?: Maybe<Array<UnlayerSettingWhereInput>>;
  clinics?: Maybe<ClinicListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailTemplates?: Maybe<EmailTemplateListRelationFilter>;
  id?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnlayerSettingWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type UnlayerUser = {
  __typename?: 'UnlayerUser';
  signature: Scalars['String'];
  userId: Scalars['Int'];
};

export type UnreadMessageChannel = {
  __typename?: 'UnreadMessageChannel';
  channelId: Scalars['String'];
  channelLastMessageIndex?: Maybe<Scalars['Int']>;
  channelType?: Maybe<ChannelType>;
  lastConsumedMessageIndex?: Maybe<Scalars['Int']>;
  unreadMessageCount: Scalars['Int'];
};

export type UpdateAppointmentTypeProviderRoomMapsInput = {
  appointmentTypeId: Scalars['String'];
  providerRoomMaps: Scalars['Json'];
};

export type UpdateAppointmentTypeProviderRoomMapsResponse = {
  __typename?: 'UpdateAppointmentTypeProviderRoomMapsResponse';
  count?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type UpdateCareSubscriptionInput = {
  addedEnrollments: Array<Maybe<OrganizationPetEnrollmentInput>>;
  cancelledAddonEnrollmentIds?: Maybe<Array<Scalars['String']>>;
  cancelledPlanEnrollmentIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateCareSubscriptionResult = {
  __typename?: 'UpdateCareSubscriptionResult';
  success: Scalars['Boolean'];
};

export type UpdateChannelFilterSelectionInput = {
  filterSelectionType?: Maybe<FilterSelectionType>;
  filters?: Maybe<Scalars['Json']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateClinicPostcardSettingDataInput = {
  isRemindersEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateClinicPostcardSettingWhereInput = {
  id: Scalars['String'];
};

export type UploadEmailCampaignCustomListInput = {
  awsFileKey: Scalars['String'];
  emailCampaignId: Scalars['String'];
  fileName: Scalars['String'];
};

export type UpsertUserToClinicInput = {
  clinicId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type UsageByBenefit = {
  __typename?: 'UsageByBenefit';
  benefit: CareBenefit;
  carryover?: Maybe<Scalars['Int']>;
  costCovered?: Maybe<Scalars['Int']>;
  displayOrder?: Maybe<Scalars['Int']>;
  exhausted: Scalars['Boolean'];
  provided: Scalars['Int'];
  recentActivity: Array<CareBenefitUsage>;
  sources: BenefitSource;
  used: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  EmailCampaign: Array<EmailCampaign>;
  EmailCampaignTemplate: Array<EmailCampaignTemplate>;
  EmailCampaignUpdated: Array<EmailCampaign>;
  EmailCampaignsCreated: Array<EmailCampaign>;
  EmailCampaignsSent: Array<EmailCampaign>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Array<UserImpersonation>;
  UserImpersonation_UserToUserImpersonation_user_id: Array<UserImpersonation>;
  _count: UserCountOutputType;
  blockedNumbers: Array<ClinicBlockedPhoneNumber>;
  boardFilters: Array<UserBoardFilter>;
  calls: Array<CallParticipant>;
  careBenefitUsageToInvoiceLineItems: Array<CareBenefitUsageToInvoiceLineItem>;
  channelCreationSources: Array<ChannelCreationSource>;
  channelMembers: Array<ChannelMember>;
  channelMessageReactions: Array<ChannelMessageReaction>;
  channelMessagesWithMentions: Array<ChannelMessage>;
  channelSelections: Array<ChannelSelection>;
  channelStatusChannelAssignees: Array<ChannelStatusChannelAssignee>;
  channelStatuses: Array<ChannelStatus>;
  channels: Array<Channel>;
  /** Test pet parents */
  clinicPetParents: Array<ClinicPetParent>;
  clinicWidgetRequestTypes: Array<ClinicWidgetRequestType>;
  clinics: Array<Clinic>;
  /** @deprecated - 1.0 */
  clinicsId: Array<Clinic>;
  completedBenefitUsageFollowupSteps: Array<CareBenefitUsageFollowupStep>;
  computed_optimizedProfilePic?: Maybe<Scalars['String']>;
  /** @deprecated - 1.0 */
  consultationChats: Array<ConsultationChat>;
  createdAt: Scalars['DateTime'];
  createdPayoutBatchingPeriods: Array<PayoutBatchingPeriod>;
  crmId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email: Scalars['String'];
  emailTemplateVersions: Array<EmailTemplateVersion>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions: Array<FormSubmission>;
  id: Scalars['String'];
  informPartnerProgramEnrollmentTermsAcceptances: Array<InformPartnerProgramEnrollment>;
  intitiatedLoyaltyAccountMerges: Array<LoyaltyAccountMerger>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations: Array<User>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<User>;
  invitedById?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired: Scalars['Boolean'];
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers: Array<ClinicPhoneNumber>;
  loyaltyPointUserGrants: Array<LoyaltyPointUserGrant>;
  loyaltyRewardRedemptionActions: Array<LoyaltyRewardRedemptionHistoryEntry>;
  manuallySentAutomationRuns: Array<AutomationRun>;
  massTextAlerts: Array<MassTextAlert>;
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates: Array<MessageTemplate>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfo>;
  /** @deprecated - 1.0 */
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels: Array<ChannelPin>;
  pointOfContactEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued: Array<StripeRefund>;
  requestedEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  responderEnrollmentSupportEvents: Array<EnrollmentSupportEvent>;
  /** @deprecated - 1.0 */
  reviews: Array<Review>;
  sentInvoices: Array<Invoice>;
  sentStripePaymentIntents: Array<StripePaymentIntent>;
  sentTrupanionExamDayOffers: Array<SentTrupanionExamDayOffer>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks: Array<PimsWritebackLogEntry>;
  type: UserType;
  unblockedNumbers: Array<ClinicBlockedPhoneNumber>;
  updatedAt: Scalars['DateTime'];
  usagesRecorded: Array<CareBenefitUsage>;
  userAppointmentGroupings: Array<UserAppointmentGrouping>;
  userChannelFilterSelections: Array<UserChannelFilterSelection>;
  userSetting?: Maybe<UserSetting>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices: Array<CanonicalService>;
  vetInfo?: Maybe<VetInfo>;
  vetInfoId?: Maybe<Scalars['String']>;
};


export type UserEmailCampaignArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type UserEmailCampaignTemplateArgs = {
  cursor?: Maybe<EmailCampaignTemplateWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignTemplateScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignTemplateWhereInput>;
};


export type UserEmailCampaignUpdatedArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type UserEmailCampaignsCreatedArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type UserEmailCampaignsSentArgs = {
  cursor?: Maybe<EmailCampaignWhereUniqueInput>;
  distinct?: Maybe<EmailCampaignScalarFieldEnum>;
  orderBy?: Maybe<EmailCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailCampaignWhereInput>;
};


export type UserUserImpersonation_UserToUserImpersonation_Impersonated_User_IdArgs = {
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserImpersonationWhereInput>;
};


export type UserUserImpersonation_UserToUserImpersonation_User_IdArgs = {
  cursor?: Maybe<UserImpersonationWhereUniqueInput>;
  distinct?: Maybe<UserImpersonationScalarFieldEnum>;
  orderBy?: Maybe<UserImpersonationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserImpersonationWhereInput>;
};


export type UserBlockedNumbersArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type UserBoardFiltersArgs = {
  cursor?: Maybe<UserBoardFilterWhereUniqueInput>;
  distinct?: Maybe<UserBoardFilterScalarFieldEnum>;
  orderBy?: Maybe<UserBoardFilterOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserBoardFilterWhereInput>;
};


export type UserCallsArgs = {
  cursor?: Maybe<CallParticipantWhereUniqueInput>;
  distinct?: Maybe<CallParticipantScalarFieldEnum>;
  orderBy?: Maybe<CallParticipantOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CallParticipantWhereInput>;
};


export type UserCareBenefitUsageToInvoiceLineItemsArgs = {
  cursor?: Maybe<CareBenefitUsageToInvoiceLineItemWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageToInvoiceLineItemScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageToInvoiceLineItemWhereInput>;
};


export type UserChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type UserChannelMembersArgs = {
  cursor?: Maybe<ChannelMemberWhereUniqueInput>;
  distinct?: Maybe<ChannelMemberScalarFieldEnum>;
  orderBy?: Maybe<ChannelMemberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMemberWhereInput>;
};


export type UserChannelMessageReactionsArgs = {
  cursor?: Maybe<ChannelMessageReactionWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageReactionScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageReactionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageReactionWhereInput>;
};


export type UserChannelMessagesWithMentionsArgs = {
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  distinct?: Maybe<ChannelMessageScalarFieldEnum>;
  orderBy?: Maybe<ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelMessageWhereInput>;
};


export type UserChannelSelectionsArgs = {
  cursor?: Maybe<ChannelSelectionWhereUniqueInput>;
  distinct?: Maybe<ChannelSelectionScalarFieldEnum>;
  orderBy?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelSelectionWhereInput>;
};


export type UserChannelStatusChannelAssigneesArgs = {
  cursor?: Maybe<ChannelStatusChannelAssigneeWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusChannelAssigneeScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusChannelAssigneeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusChannelAssigneeWhereInput>;
};


export type UserChannelStatusesArgs = {
  cursor?: Maybe<ChannelStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelStatusWhereInput>;
};


export type UserChannelsArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type UserClinicPetParentsArgs = {
  cursor?: Maybe<ClinicPetParentWhereUniqueInput>;
  distinct?: Maybe<ClinicPetParentScalarFieldEnum>;
  orderBy?: Maybe<ClinicPetParentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPetParentWhereInput>;
};


export type UserClinicWidgetRequestTypesArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type UserClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type UserClinicsIdArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type UserCompletedBenefitUsageFollowupStepsArgs = {
  cursor?: Maybe<CareBenefitUsageFollowupStepWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageFollowupStepScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageFollowupStepOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageFollowupStepWhereInput>;
};


export type UserConsultationChatsArgs = {
  cursor?: Maybe<ConsultationChatWhereUniqueInput>;
  distinct?: Maybe<ConsultationChatScalarFieldEnum>;
  orderBy?: Maybe<ConsultationChatOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationChatWhereInput>;
};


export type UserCreatedPayoutBatchingPeriodsArgs = {
  cursor?: Maybe<PayoutBatchingPeriodWhereUniqueInput>;
  distinct?: Maybe<PayoutBatchingPeriodScalarFieldEnum>;
  orderBy?: Maybe<PayoutBatchingPeriodOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
};


export type UserEmailTemplateVersionsArgs = {
  cursor?: Maybe<EmailTemplateVersionWhereUniqueInput>;
  distinct?: Maybe<EmailTemplateVersionScalarFieldEnum>;
  orderBy?: Maybe<EmailTemplateVersionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmailTemplateVersionWhereInput>;
};


export type UserFormSubmissionsArgs = {
  cursor?: Maybe<FormSubmissionWhereUniqueInput>;
  distinct?: Maybe<FormSubmissionScalarFieldEnum>;
  orderBy?: Maybe<FormSubmissionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormSubmissionWhereInput>;
};


export type UserInformPartnerProgramEnrollmentTermsAcceptancesArgs = {
  cursor?: Maybe<InformPartnerProgramEnrollmentWhereUniqueInput>;
  distinct?: Maybe<InformPartnerProgramEnrollmentScalarFieldEnum>;
  orderBy?: Maybe<InformPartnerProgramEnrollmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformPartnerProgramEnrollmentWhereInput>;
};


export type UserIntitiatedLoyaltyAccountMergesArgs = {
  cursor?: Maybe<LoyaltyAccountMergerWhereUniqueInput>;
  distinct?: Maybe<LoyaltyAccountMergerScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyAccountMergerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyAccountMergerWhereInput>;
};


export type UserInvitationsArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};


export type UserLoaSignedClinicPhoneNumbersArgs = {
  cursor?: Maybe<ClinicPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicPhoneNumberWhereInput>;
};


export type UserLoyaltyPointUserGrantsArgs = {
  cursor?: Maybe<LoyaltyPointUserGrantWhereUniqueInput>;
  distinct?: Maybe<LoyaltyPointUserGrantScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyPointUserGrantOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyPointUserGrantWhereInput>;
};


export type UserLoyaltyRewardRedemptionActionsArgs = {
  cursor?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereUniqueInput>;
  distinct?: Maybe<LoyaltyRewardRedemptionHistoryEntryScalarFieldEnum>;
  orderBy?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LoyaltyRewardRedemptionHistoryEntryWhereInput>;
};


export type UserManuallySentAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type UserMassTextAlertsArgs = {
  cursor?: Maybe<MassTextAlertWhereUniqueInput>;
  distinct?: Maybe<MassTextAlertScalarFieldEnum>;
  orderBy?: Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MassTextAlertWhereInput>;
};


export type UserMessageTemplatesArgs = {
  cursor?: Maybe<MessageTemplateWhereUniqueInput>;
  distinct?: Maybe<MessageTemplateScalarFieldEnum>;
  orderBy?: Maybe<MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<MessageTemplateWhereInput>;
};


export type UserPinnedChannelsArgs = {
  cursor?: Maybe<ChannelPinWhereUniqueInput>;
  distinct?: Maybe<ChannelPinScalarFieldEnum>;
  orderBy?: Maybe<ChannelPinOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelPinWhereInput>;
};


export type UserPointOfContactEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type UserRefundsIssuedArgs = {
  cursor?: Maybe<StripeRefundWhereUniqueInput>;
  distinct?: Maybe<StripeRefundScalarFieldEnum>;
  orderBy?: Maybe<StripeRefundOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripeRefundWhereInput>;
};


export type UserRequestedEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type UserResponderEnrollmentSupportEventsArgs = {
  cursor?: Maybe<EnrollmentSupportEventWhereUniqueInput>;
  distinct?: Maybe<EnrollmentSupportEventScalarFieldEnum>;
  orderBy?: Maybe<EnrollmentSupportEventOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EnrollmentSupportEventWhereInput>;
};


export type UserReviewsArgs = {
  cursor?: Maybe<ReviewWhereUniqueInput>;
  distinct?: Maybe<ReviewScalarFieldEnum>;
  orderBy?: Maybe<ReviewOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReviewWhereInput>;
};


export type UserSentInvoicesArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  orderBy?: Maybe<InvoiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};


export type UserSentStripePaymentIntentsArgs = {
  cursor?: Maybe<StripePaymentIntentWhereUniqueInput>;
  distinct?: Maybe<StripePaymentIntentScalarFieldEnum>;
  orderBy?: Maybe<StripePaymentIntentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StripePaymentIntentWhereInput>;
};


export type UserSentTrupanionExamDayOffersArgs = {
  cursor?: Maybe<SentTrupanionExamDayOfferWhereUniqueInput>;
  distinct?: Maybe<SentTrupanionExamDayOfferScalarFieldEnum>;
  orderBy?: Maybe<SentTrupanionExamDayOfferOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SentTrupanionExamDayOfferWhereInput>;
};


export type UserTriggeredWritebacksArgs = {
  cursor?: Maybe<PimsWritebackLogEntryWhereUniqueInput>;
  distinct?: Maybe<PimsWritebackLogEntryScalarFieldEnum>;
  orderBy?: Maybe<PimsWritebackLogEntryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PimsWritebackLogEntryWhereInput>;
};


export type UserUnblockedNumbersArgs = {
  cursor?: Maybe<ClinicBlockedPhoneNumberWhereUniqueInput>;
  distinct?: Maybe<ClinicBlockedPhoneNumberScalarFieldEnum>;
  orderBy?: Maybe<ClinicBlockedPhoneNumberOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
};


export type UserUsagesRecordedArgs = {
  cursor?: Maybe<CareBenefitUsageWhereUniqueInput>;
  distinct?: Maybe<CareBenefitUsageScalarFieldEnum>;
  orderBy?: Maybe<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CareBenefitUsageWhereInput>;
};


export type UserUserAppointmentGroupingsArgs = {
  cursor?: Maybe<UserAppointmentGroupingWhereUniqueInput>;
  distinct?: Maybe<UserAppointmentGroupingScalarFieldEnum>;
  orderBy?: Maybe<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserAppointmentGroupingWhereInput>;
};


export type UserUserChannelFilterSelectionsArgs = {
  cursor?: Maybe<UserChannelFilterSelectionWhereUniqueInput>;
  distinct?: Maybe<UserChannelFilterSelectionScalarFieldEnum>;
  orderBy?: Maybe<UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserChannelFilterSelectionWhereInput>;
};


export type UserValidatedCanonicalServicesArgs = {
  cursor?: Maybe<CanonicalServiceWhereUniqueInput>;
  distinct?: Maybe<CanonicalServiceScalarFieldEnum>;
  orderBy?: Maybe<CanonicalServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CanonicalServiceWhereInput>;
};

export type UserAppointmentGrouping = {
  __typename?: 'UserAppointmentGrouping';
  _count: UserAppointmentGroupingCountOutputType;
  appointmentTypes: Array<AppointmentType>;
  clinic?: Maybe<Clinic>;
  clinicEmployees: Array<ClinicEmployee>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  label: Scalars['String'];
  rooms: Array<ClinicRoom>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type UserAppointmentGroupingAppointmentTypesArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type UserAppointmentGroupingClinicEmployeesArgs = {
  cursor?: Maybe<ClinicEmployeeWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeWhereInput>;
};


export type UserAppointmentGroupingRoomsArgs = {
  cursor?: Maybe<ClinicRoomWhereUniqueInput>;
  distinct?: Maybe<ClinicRoomScalarFieldEnum>;
  orderBy?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomWhereInput>;
};

export type UserAppointmentGroupingCountAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  label: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserAppointmentGroupingCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingCountOutputType = {
  __typename?: 'UserAppointmentGroupingCountOutputType';
  appointmentTypes: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  rooms: Scalars['Int'];
};

export type UserAppointmentGroupingCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateManyClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyClinicInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingCreateManyUserInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingCreateManyUserInputEnvelope = {
  data: Array<UserAppointmentGroupingCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicEmployeesInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutRoomsInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
};

export type UserAppointmentGroupingCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
};

export type UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutClinicInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutRoomsInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingCreateOrConnectWithoutUserInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingCreateWithoutAppointmentTypesInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserAppointmentGroupingsInput>;
};

export type UserAppointmentGroupingCreateWithoutUserInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingListRelationFilter = {
  every?: Maybe<UserAppointmentGroupingWhereInput>;
  none?: Maybe<UserAppointmentGroupingWhereInput>;
  some?: Maybe<UserAppointmentGroupingWhereInput>;
};

export type UserAppointmentGroupingMaxAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingMinAggregateOutputType = {
  __typename?: 'UserAppointmentGroupingMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserAppointmentGroupingOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  Label = 'label',
  UserId = 'userId'
}

export type UserAppointmentGroupingOrderByRelevanceInput = {
  fields: Array<UserAppointmentGroupingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserAppointmentGroupingOrderByWithAggregationInput = {
  _count?: Maybe<UserAppointmentGroupingCountOrderByAggregateInput>;
  _max?: Maybe<UserAppointmentGroupingMaxOrderByAggregateInput>;
  _min?: Maybe<UserAppointmentGroupingMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserAppointmentGroupingOrderByRelevanceInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployees?: Maybe<ClinicEmployeeOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  rooms?: Maybe<ClinicRoomOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum UserAppointmentGroupingScalarFieldEnum {
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserAppointmentGroupingScalarWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type UserAppointmentGroupingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  label?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserAppointmentGroupingUncheckedCreateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicEmployeesInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutRoomsInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
};

export type UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput = {
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedCreateWithoutUserInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  rooms?: Maybe<ClinicRoomUncheckedCreateNestedManyWithoutUserAppointmentGroupingsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingUncheckedUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutRoomsNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput = {
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput = {
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserAppointmentGroupingUncheckedUpdateWithoutUserInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUncheckedUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingUpdateInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
  where: UserAppointmentGroupingScalarWhereInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput = {
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
  where: UserAppointmentGroupingScalarWhereInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput = {
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
  where: UserAppointmentGroupingScalarWhereInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput = {
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
  where: UserAppointmentGroupingScalarWhereInput;
};

export type UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput = {
  data: UserAppointmentGroupingUncheckedUpdateManyWithoutUserAppointmentGroupingsInput;
  where: UserAppointmentGroupingScalarWhereInput;
};

export type UserAppointmentGroupingUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicEmployeesInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicEmployeesInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicEmployeesInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutClinicInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutRoomsNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutRoomsInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutRoomsInput>>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutRoomsInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput>>;
};

export type UserAppointmentGroupingUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAppointmentGroupingCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAppointmentGroupingCreateWithoutUserInput>>;
  createMany?: Maybe<UserAppointmentGroupingCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAppointmentGroupingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  set?: Maybe<Array<UserAppointmentGroupingWhereUniqueInput>>;
  update?: Maybe<Array<UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAppointmentGroupingUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  data: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicEmployeesInput = {
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutClinicInput = {
  data: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutRoomsInput = {
  data: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpdateWithWhereUniqueWithoutUserInput = {
  data: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpdateWithoutAppointmentTypesInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicEmployeesInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutClinicInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutRoomsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
};

export type UserAppointmentGroupingUpdateWithoutUserInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  clinic?: Maybe<ClinicUpdateOneWithoutUserAppointmentGroupingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  rooms?: Maybe<ClinicRoomUpdateManyWithoutUserAppointmentGroupingsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutAppointmentTypesInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutAppointmentTypesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicEmployeesInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicEmployeesInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicEmployeesInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutClinicInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutClinicInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutClinicInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutRoomsInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutRoomsInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutRoomsInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingUpsertWithWhereUniqueWithoutUserInput = {
  create: UserAppointmentGroupingUncheckedCreateWithoutUserInput;
  update: UserAppointmentGroupingUncheckedUpdateWithoutUserInput;
  where: UserAppointmentGroupingWhereUniqueInput;
};

export type UserAppointmentGroupingWhereInput = {
  AND?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  NOT?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  OR?: Maybe<Array<UserAppointmentGroupingWhereInput>>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployees?: Maybe<ClinicEmployeeListRelationFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  label?: Maybe<StringFilter>;
  rooms?: Maybe<ClinicRoomListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type UserAppointmentGroupingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserBoardFilter = {
  __typename?: 'UserBoardFilter';
  _count: UserBoardFilterCountOutputType;
  columns: Array<BoardFilterColumn>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id: Scalars['String'];
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRange>;
  user: User;
  userId: Scalars['String'];
};


export type UserBoardFilterColumnsArgs = {
  cursor?: Maybe<BoardFilterColumnWhereUniqueInput>;
  distinct?: Maybe<BoardFilterColumnScalarFieldEnum>;
  orderBy?: Maybe<BoardFilterColumnOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BoardFilterColumnWhereInput>;
};

export type UserBoardFilterCountAggregateOutputType = {
  __typename?: 'UserBoardFilterCountAggregateOutputType';
  _all: Scalars['Int'];
  currentView: Scalars['Int'];
  dateRangeOption: Scalars['Int'];
  id: Scalars['Int'];
  shouldCollapseNoStatusColumn: Scalars['Int'];
  shouldHideEmptyColumns: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserBoardFilterCountOrderByAggregateInput = {
  currentView?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  shouldCollapseNoStatusColumn?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserBoardFilterCountOutputType = {
  __typename?: 'UserBoardFilterCountOutputType';
  columns: Scalars['Int'];
};

export type UserBoardFilterCreateInput = {
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateManyInput = {
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type UserBoardFilterCreateManyUserInput = {
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
};

export type UserBoardFilterCreateManyUserInputEnvelope = {
  data: Array<UserBoardFilterCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserBoardFilterCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
};

export type UserBoardFilterCreateNestedOneWithoutColumnsInput = {
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutColumnsInput>;
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutColumnsInput>;
};

export type UserBoardFilterCreateNestedOneWithoutSpecificDateRangeInput = {
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput>;
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput>;
};

export type UserBoardFilterCreateOrConnectWithoutColumnsInput = {
  create: UserBoardFilterUncheckedCreateWithoutColumnsInput;
  where: UserBoardFilterWhereUniqueInput;
};

export type UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput = {
  create: UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput;
  where: UserBoardFilterWhereUniqueInput;
};

export type UserBoardFilterCreateOrConnectWithoutUserInput = {
  create: UserBoardFilterUncheckedCreateWithoutUserInput;
  where: UserBoardFilterWhereUniqueInput;
};

export type UserBoardFilterCreateWithoutColumnsInput = {
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateWithoutSpecificDateRangeInput = {
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  user: UserCreateNestedOneWithoutBoardFiltersInput;
};

export type UserBoardFilterCreateWithoutUserInput = {
  columns?: Maybe<BoardFilterColumnCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterListRelationFilter = {
  every?: Maybe<UserBoardFilterWhereInput>;
  none?: Maybe<UserBoardFilterWhereInput>;
  some?: Maybe<UserBoardFilterWhereInput>;
};

export type UserBoardFilterMaxAggregateOutputType = {
  __typename?: 'UserBoardFilterMaxAggregateOutputType';
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterMaxOrderByAggregateInput = {
  currentView?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  shouldCollapseNoStatusColumn?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserBoardFilterMinAggregateOutputType = {
  __typename?: 'UserBoardFilterMinAggregateOutputType';
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterMinOrderByAggregateInput = {
  currentView?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  shouldCollapseNoStatusColumn?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserBoardFilterOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserBoardFilterOrderByRelevanceFieldEnum {
  Id = 'id',
  UserId = 'userId'
}

export type UserBoardFilterOrderByRelevanceInput = {
  fields: Array<UserBoardFilterOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserBoardFilterOrderByWithAggregationInput = {
  _count?: Maybe<UserBoardFilterCountOrderByAggregateInput>;
  _max?: Maybe<UserBoardFilterMaxOrderByAggregateInput>;
  _min?: Maybe<UserBoardFilterMinOrderByAggregateInput>;
  currentView?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  shouldCollapseNoStatusColumn?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserBoardFilterOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserBoardFilterOrderByRelevanceInput>;
  columns?: Maybe<BoardFilterColumnOrderByRelationAggregateInput>;
  currentView?: Maybe<SortOrder>;
  dateRangeOption?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  shouldCollapseNoStatusColumn?: Maybe<SortOrder>;
  shouldHideEmptyColumns?: Maybe<SortOrder>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export type UserBoardFilterRelationFilter = {
  is?: Maybe<UserBoardFilterWhereInput>;
  isNot?: Maybe<UserBoardFilterWhereInput>;
};

export enum UserBoardFilterScalarFieldEnum {
  CurrentView = 'currentView',
  DateRangeOption = 'dateRangeOption',
  Id = 'id',
  ShouldCollapseNoStatusColumn = 'shouldCollapseNoStatusColumn',
  ShouldHideEmptyColumns = 'shouldHideEmptyColumns',
  UserId = 'userId'
}

export type UserBoardFilterScalarWhereInput = {
  AND?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  NOT?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  OR?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  currentView?: Maybe<EnumBoardFilterViewFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableFilter>;
  id?: Maybe<StringFilter>;
  shouldCollapseNoStatusColumn?: Maybe<BoolNullableFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserBoardFilterScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserBoardFilterScalarWhereWithAggregatesInput>>;
  currentView?: Maybe<EnumBoardFilterViewWithAggregatesFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  shouldCollapseNoStatusColumn?: Maybe<BoolNullableWithAggregatesFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type UserBoardFilterUncheckedCreateInput = {
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
  userId: Scalars['String'];
};

export type UserBoardFilterUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
};

export type UserBoardFilterUncheckedCreateWithoutColumnsInput = {
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
  userId: Scalars['String'];
};

export type UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput = {
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type UserBoardFilterUncheckedCreateWithoutUserInput = {
  columns?: Maybe<BoardFilterColumnUncheckedCreateNestedManyWithoutUserBoardFilterInput>;
  currentView: BoardFilterView;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedCreateNestedOneWithoutUserBoardFilterInput>;
};

export type UserBoardFilterUncheckedUpdateInput = {
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterUncheckedUpdateManyInput = {
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterUncheckedUpdateManyWithoutBoardFiltersInput = {
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
};

export type UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  disconnect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  set?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  update?: Maybe<Array<UserBoardFilterUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserBoardFilterUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserBoardFilterUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserBoardFilterUncheckedUpdateWithoutColumnsInput = {
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput = {
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserBoardFilterUncheckedUpdateWithoutUserInput = {
  columns?: Maybe<BoardFilterColumnUncheckedUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUncheckedUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUpdateInput = {
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateManyMutationInput = {
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
};

export type UserBoardFilterUpdateManyWithWhereWithoutUserInput = {
  data: UserBoardFilterUncheckedUpdateManyWithoutBoardFiltersInput;
  where: UserBoardFilterScalarWhereInput;
};

export type UserBoardFilterUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserBoardFilterCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserBoardFilterCreateWithoutUserInput>>;
  createMany?: Maybe<UserBoardFilterCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserBoardFilterScalarWhereInput>>;
  disconnect?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  set?: Maybe<Array<UserBoardFilterWhereUniqueInput>>;
  update?: Maybe<Array<UserBoardFilterUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserBoardFilterUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserBoardFilterUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserBoardFilterUpdateOneWithoutColumnsNestedInput = {
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutColumnsInput>;
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutColumnsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserBoardFilterUncheckedUpdateWithoutColumnsInput>;
  upsert?: Maybe<UserBoardFilterUpsertWithoutColumnsInput>;
};

export type UserBoardFilterUpdateOneWithoutSpecificDateRangeNestedInput = {
  connect?: Maybe<UserBoardFilterWhereUniqueInput>;
  connectOrCreate?: Maybe<UserBoardFilterCreateOrConnectWithoutSpecificDateRangeInput>;
  create?: Maybe<UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput>;
  upsert?: Maybe<UserBoardFilterUpsertWithoutSpecificDateRangeInput>;
};

export type UserBoardFilterUpdateWithWhereUniqueWithoutUserInput = {
  data: UserBoardFilterUncheckedUpdateWithoutUserInput;
  where: UserBoardFilterWhereUniqueInput;
};

export type UserBoardFilterUpdateWithoutColumnsInput = {
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateWithoutSpecificDateRangeInput = {
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutBoardFiltersNestedInput>;
};

export type UserBoardFilterUpdateWithoutUserInput = {
  columns?: Maybe<BoardFilterColumnUpdateManyWithoutUserBoardFilterNestedInput>;
  currentView?: Maybe<BoardFilterView>;
  dateRangeOption?: Maybe<BoardFilterDateRange>;
  id?: Maybe<Scalars['String']>;
  shouldCollapseNoStatusColumn?: Maybe<Scalars['Boolean']>;
  shouldHideEmptyColumns?: Maybe<Scalars['Boolean']>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeUpdateOneWithoutUserBoardFilterNestedInput>;
};

export type UserBoardFilterUpsertWithWhereUniqueWithoutUserInput = {
  create: UserBoardFilterUncheckedCreateWithoutUserInput;
  update: UserBoardFilterUncheckedUpdateWithoutUserInput;
  where: UserBoardFilterWhereUniqueInput;
};

export type UserBoardFilterUpsertWithoutColumnsInput = {
  create: UserBoardFilterUncheckedCreateWithoutColumnsInput;
  update: UserBoardFilterUncheckedUpdateWithoutColumnsInput;
};

export type UserBoardFilterUpsertWithoutSpecificDateRangeInput = {
  create: UserBoardFilterUncheckedCreateWithoutSpecificDateRangeInput;
  update: UserBoardFilterUncheckedUpdateWithoutSpecificDateRangeInput;
};

export type UserBoardFilterWhereInput = {
  AND?: Maybe<Array<UserBoardFilterWhereInput>>;
  NOT?: Maybe<Array<UserBoardFilterWhereInput>>;
  OR?: Maybe<Array<UserBoardFilterWhereInput>>;
  columns?: Maybe<BoardFilterColumnListRelationFilter>;
  currentView?: Maybe<EnumBoardFilterViewFilter>;
  dateRangeOption?: Maybe<EnumBoardFilterDateRangeNullableFilter>;
  id?: Maybe<StringFilter>;
  shouldCollapseNoStatusColumn?: Maybe<BoolNullableFilter>;
  shouldHideEmptyColumns?: Maybe<BoolNullableFilter>;
  specificDateRange?: Maybe<BoardFilterSpecificDateRangeWhereInput>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type UserBoardFilterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelection = {
  __typename?: 'UserChannelFilterSelection';
  filterSelection: ChannelFilterSelection;
  filterSelectionId: Scalars['String'];
  id: Scalars['String'];
  isFilterOwner: Scalars['Boolean'];
  user: User;
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionCountAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionCountAggregateOutputType';
  _all: Scalars['Int'];
  filterSelectionId: Scalars['Int'];
  id: Scalars['Int'];
  isFilterOwner: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserChannelFilterSelectionCountOrderByAggregateInput = {
  filterSelectionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionCreateInput = {
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyFilterSelectionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateManyInput = {
  filterSelectionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionCreateManyUserInput = {
  filterSelectionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionCreateManyUserInputEnvelope = {
  data: Array<UserChannelFilterSelectionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutFilterSelectionInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
};

export type UserChannelFilterSelectionCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput = {
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionCreateOrConnectWithoutUserInput = {
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  user: UserCreateNestedOneWithoutUserChannelFilterSelectionsInput;
};

export type UserChannelFilterSelectionCreateWithoutUserInput = {
  filterSelection: ChannelFilterSelectionCreateNestedOneWithoutUserChannelFilterSelectionsInput;
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionListRelationFilter = {
  every?: Maybe<UserChannelFilterSelectionWhereInput>;
  none?: Maybe<UserChannelFilterSelectionWhereInput>;
  some?: Maybe<UserChannelFilterSelectionWhereInput>;
};

export type UserChannelFilterSelectionMaxAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMaxAggregateOutputType';
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelectionMaxOrderByAggregateInput = {
  filterSelectionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionMinAggregateOutputType = {
  __typename?: 'UserChannelFilterSelectionMinAggregateOutputType';
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelectionMinOrderByAggregateInput = {
  filterSelectionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserChannelFilterSelectionOrderByRelevanceFieldEnum {
  FilterSelectionId = 'filterSelectionId',
  Id = 'id',
  UserId = 'userId'
}

export type UserChannelFilterSelectionOrderByRelevanceInput = {
  fields: Array<UserChannelFilterSelectionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserChannelFilterSelectionOrderByWithAggregationInput = {
  _count?: Maybe<UserChannelFilterSelectionCountOrderByAggregateInput>;
  _max?: Maybe<UserChannelFilterSelectionMaxOrderByAggregateInput>;
  _min?: Maybe<UserChannelFilterSelectionMinOrderByAggregateInput>;
  filterSelectionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserChannelFilterSelectionOrderByRelevanceInput>;
  filterSelection?: Maybe<ChannelFilterSelectionOrderByWithRelationAndSearchRelevanceInput>;
  filterSelectionId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isFilterOwner?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum UserChannelFilterSelectionScalarFieldEnum {
  FilterSelectionId = 'filterSelectionId',
  Id = 'id',
  IsFilterOwner = 'isFilterOwner',
  UserId = 'userId'
}

export type UserChannelFilterSelectionScalarWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  filterSelectionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserChannelFilterSelectionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionScalarWhereWithAggregatesInput>>;
  filterSelectionId?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isFilterOwner?: Maybe<BoolWithAggregatesFilter>;
  userId?: Maybe<StringWithAggregatesFilter>;
};

export type UserChannelFilterSelectionUncheckedCreateInput = {
  filterSelectionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutFilterSelectionInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
};

export type UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
};

export type UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionUncheckedCreateWithoutUserInput = {
  filterSelectionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isFilterOwner: Scalars['Boolean'];
};

export type UserChannelFilterSelectionUncheckedUpdateInput = {
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyInput = {
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutFilterSelectionNestedInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput = {
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserChannelFilterSelectionUncheckedUpdateWithoutUserInput = {
  filterSelectionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateInput = {
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
};

export type UserChannelFilterSelectionUpdateManyMutationInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput = {
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
  where: UserChannelFilterSelectionScalarWhereInput;
};

export type UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput = {
  data: UserChannelFilterSelectionUncheckedUpdateManyWithoutUserChannelFilterSelectionsInput;
  where: UserChannelFilterSelectionScalarWhereInput;
};

export type UserChannelFilterSelectionUpdateManyWithoutFilterSelectionNestedInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutFilterSelectionInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutFilterSelectionInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyFilterSelectionInputEnvelope>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutFilterSelectionInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput>>;
};

export type UserChannelFilterSelectionUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserChannelFilterSelectionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserChannelFilterSelectionCreateWithoutUserInput>>;
  createMany?: Maybe<UserChannelFilterSelectionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserChannelFilterSelectionScalarWhereInput>>;
  disconnect?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  set?: Maybe<Array<UserChannelFilterSelectionWhereUniqueInput>>;
  update?: Maybe<Array<UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserChannelFilterSelectionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutFilterSelectionInput = {
  data: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionUpdateWithWhereUniqueWithoutUserInput = {
  data: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionUpdateWithoutFilterSelectionInput = {
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
};

export type UserChannelFilterSelectionUpdateWithoutUserInput = {
  filterSelection?: Maybe<ChannelFilterSelectionUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput>;
  id?: Maybe<Scalars['String']>;
  isFilterOwner?: Maybe<Scalars['Boolean']>;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutFilterSelectionInput = {
  create: UserChannelFilterSelectionUncheckedCreateWithoutFilterSelectionInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutFilterSelectionInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionUpsertWithWhereUniqueWithoutUserInput = {
  create: UserChannelFilterSelectionUncheckedCreateWithoutUserInput;
  update: UserChannelFilterSelectionUncheckedUpdateWithoutUserInput;
  where: UserChannelFilterSelectionWhereUniqueInput;
};

export type UserChannelFilterSelectionUserIdAndFilterSelectionIdCompoundUniqueInput = {
  filterSelectionId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserChannelFilterSelectionWhereInput = {
  AND?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  NOT?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  OR?: Maybe<Array<UserChannelFilterSelectionWhereInput>>;
  filterSelection?: Maybe<ChannelFilterSelectionWhereInput>;
  filterSelectionId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isFilterOwner?: Maybe<BoolFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringFilter>;
};

export type UserChannelFilterSelectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userIdAndFilterSelectionId?: Maybe<UserChannelFilterSelectionUserIdAndFilterSelectionIdCompoundUniqueInput>;
};

export type UserCountAggregateOutputType = {
  __typename?: 'UserCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  crmId: Scalars['Int'];
  email: Scalars['Int'];
  firebaseId: Scalars['Int'];
  firstName: Scalars['Int'];
  id: Scalars['Int'];
  invitationCode: Scalars['Int'];
  invitedAt: Scalars['Int'];
  invitedById: Scalars['Int'];
  isActive: Scalars['Int'];
  isAdmin: Scalars['Int'];
  isNewPasswordRequired: Scalars['Int'];
  lastName: Scalars['Int'];
  mediaId: Scalars['Int'];
  nameDisplay: Scalars['Int'];
  namePrefix: Scalars['Int'];
  nameSuffix: Scalars['Int'];
  ownerInfoId: Scalars['Int'];
  phone: Scalars['Int'];
  profilePic: Scalars['Int'];
  signUpDate: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userSettingId: Scalars['Int'];
  vetInfoId: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type UserCountOutputType = {
  __typename?: 'UserCountOutputType';
  EmailCampaign: Scalars['Int'];
  EmailCampaignTemplate: Scalars['Int'];
  EmailCampaignUpdated: Scalars['Int'];
  EmailCampaignsCreated: Scalars['Int'];
  EmailCampaignsSent: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_impersonated_user_id: Scalars['Int'];
  UserImpersonation_UserToUserImpersonation_user_id: Scalars['Int'];
  blockedNumbers: Scalars['Int'];
  boardFilters: Scalars['Int'];
  calls: Scalars['Int'];
  careBenefitUsageToInvoiceLineItems: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  channelMembers: Scalars['Int'];
  channelMessageReactions: Scalars['Int'];
  channelMessagesWithMentions: Scalars['Int'];
  channelSelections: Scalars['Int'];
  channelStatusChannelAssignees: Scalars['Int'];
  channelStatuses: Scalars['Int'];
  channels: Scalars['Int'];
  clinicPetParents: Scalars['Int'];
  clinicWidgetRequestTypes: Scalars['Int'];
  clinics: Scalars['Int'];
  clinicsId: Scalars['Int'];
  completedBenefitUsageFollowupSteps: Scalars['Int'];
  consultationChats: Scalars['Int'];
  createdPayoutBatchingPeriods: Scalars['Int'];
  emailTemplateVersions: Scalars['Int'];
  formSubmissions: Scalars['Int'];
  informPartnerProgramEnrollmentTermsAcceptances: Scalars['Int'];
  intitiatedLoyaltyAccountMerges: Scalars['Int'];
  invitations: Scalars['Int'];
  loaSignedClinicPhoneNumbers: Scalars['Int'];
  loyaltyPointUserGrants: Scalars['Int'];
  loyaltyRewardRedemptionActions: Scalars['Int'];
  manuallySentAutomationRuns: Scalars['Int'];
  massTextAlerts: Scalars['Int'];
  messageTemplates: Scalars['Int'];
  pinnedChannels: Scalars['Int'];
  pointOfContactEnrollmentSupportEvents: Scalars['Int'];
  refundsIssued: Scalars['Int'];
  requestedEnrollmentSupportEvents: Scalars['Int'];
  responderEnrollmentSupportEvents: Scalars['Int'];
  reviews: Scalars['Int'];
  sentInvoices: Scalars['Int'];
  sentStripePaymentIntents: Scalars['Int'];
  sentTrupanionExamDayOffers: Scalars['Int'];
  triggeredWritebacks: Scalars['Int'];
  unblockedNumbers: Scalars['Int'];
  usagesRecorded: Scalars['Int'];
  userAppointmentGroupings: Scalars['Int'];
  userChannelFilterSelections: Scalars['Int'];
  validatedCanonicalServices: Scalars['Int'];
};

export type UserCreateInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserCreateManyInvitedByInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserCreateManyInvitedByInputEnvelope = {
  data: Array<UserCreateManyInvitedByInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyMediaInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserCreateManyMediaInputEnvelope = {
  data: Array<UserCreateManyMediaInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateManyUserSettingInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserCreateManyUserSettingInputEnvelope = {
  data: Array<UserCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
};

export type UserCreateNestedManyWithoutChannelSelectionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
};

export type UserCreateNestedManyWithoutChannelsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
};

export type UserCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
};

export type UserCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
};

export type UserCreateNestedManyWithoutFormSubmissionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
};

export type UserCreateNestedManyWithoutInvitedByInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
};

export type UserCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
};

export type UserCreateNestedManyWithoutUserSettingInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
};

export type UserCreateNestedOneWithoutBlockedNumbersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlockedNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutBlockedNumbersInput>;
};

export type UserCreateNestedOneWithoutBoardFiltersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBoardFiltersInput>;
  create?: Maybe<UserUncheckedCreateWithoutBoardFiltersInput>;
};

export type UserCreateNestedOneWithoutCallsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
};

export type UserCreateNestedOneWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type UserCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type UserCreateNestedOneWithoutChannelMembersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
};

export type UserCreateNestedOneWithoutChannelMessageReactionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
};

export type UserCreateNestedOneWithoutChannelStatusChannelAssigneesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
};

export type UserCreateNestedOneWithoutChannelStatusesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
};

export type UserCreateNestedOneWithoutClinicPetParentsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<UserUncheckedCreateWithoutClinicPetParentsInput>;
};

export type UserCreateNestedOneWithoutClinicsIdInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
};

export type UserCreateNestedOneWithoutCompletedBenefitUsageFollowupStepsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput>;
};

export type UserCreateNestedOneWithoutConsultationChatsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
};

export type UserCreateNestedOneWithoutCreatedPayoutBatchingPeriodsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignTemplateInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignTemplateInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignUpdatedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignUpdatedInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignUpdatedInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignsCreatedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsCreatedInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsCreatedInput>;
};

export type UserCreateNestedOneWithoutEmailCampaignsSentInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsSentInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsSentInput>;
};

export type UserCreateNestedOneWithoutEmailTemplateVersionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailTemplateVersionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailTemplateVersionsInput>;
};

export type UserCreateNestedOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  create?: Maybe<UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
};

export type UserCreateNestedOneWithoutIntitiatedLoyaltyAccountMergesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput>;
  create?: Maybe<UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput>;
};

export type UserCreateNestedOneWithoutInvitationsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
};

export type UserCreateNestedOneWithoutLoaSignedClinicPhoneNumbersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput>;
};

export type UserCreateNestedOneWithoutLoyaltyPointUserGrantsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput>;
};

export type UserCreateNestedOneWithoutLoyaltyRewardRedemptionActionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type UserCreateNestedOneWithoutManuallySentAutomationRunsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
};

export type UserCreateNestedOneWithoutMassTextAlertsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
};

export type UserCreateNestedOneWithoutMessageTemplatesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
};

export type UserCreateNestedOneWithoutOwnerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
};

export type UserCreateNestedOneWithoutPinnedChannelsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPinnedChannelsInput>;
  create?: Maybe<UserUncheckedCreateWithoutPinnedChannelsInput>;
};

export type UserCreateNestedOneWithoutPointOfContactEnrollmentSupportEventsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput>;
};

export type UserCreateNestedOneWithoutRefundsIssuedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
};

export type UserCreateNestedOneWithoutRequestedEnrollmentSupportEventsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput>;
};

export type UserCreateNestedOneWithoutResponderEnrollmentSupportEventsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput>;
};

export type UserCreateNestedOneWithoutReviewsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
};

export type UserCreateNestedOneWithoutSentInvoicesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
};

export type UserCreateNestedOneWithoutSentStripePaymentIntentsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
};

export type UserCreateNestedOneWithoutSentTrupanionExamDayOffersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
};

export type UserCreateNestedOneWithoutTriggeredWritebacksInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
};

export type UserCreateNestedOneWithoutUnblockedNumbersInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUnblockedNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutUnblockedNumbersInput>;
};

export type UserCreateNestedOneWithoutUsagesRecordedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
};

export type UserCreateNestedOneWithoutUserAppointmentGroupingsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
};

export type UserCreateNestedOneWithoutUserChannelFilterSelectionsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
};

export type UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserCreateNestedOneWithoutValidatedCanonicalServicesInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutValidatedCanonicalServicesInput>;
  create?: Maybe<UserUncheckedCreateWithoutValidatedCanonicalServicesInput>;
};

export type UserCreateNestedOneWithoutVetInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
};

export type UserCreateOrConnectWithoutBlockedNumbersInput = {
  create: UserUncheckedCreateWithoutBlockedNumbersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBoardFiltersInput = {
  create: UserUncheckedCreateWithoutBoardFiltersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCallsInput = {
  create: UserUncheckedCreateWithoutCallsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelMembersInput = {
  create: UserUncheckedCreateWithoutChannelMembersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelMessageReactionsInput = {
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelMessagesWithMentionsInput = {
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelSelectionsInput = {
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput = {
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelStatusesInput = {
  create: UserUncheckedCreateWithoutChannelStatusesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutChannelsInput = {
  create: UserUncheckedCreateWithoutChannelsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClinicPetParentsInput = {
  create: UserUncheckedCreateWithoutClinicPetParentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClinicWidgetRequestTypesInput = {
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClinicsIdInput = {
  create: UserUncheckedCreateWithoutClinicsIdInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClinicsInput = {
  create: UserUncheckedCreateWithoutClinicsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput = {
  create: UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutConsultationChatsInput = {
  create: UserUncheckedCreateWithoutConsultationChatsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput = {
  create: UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailCampaignInput = {
  create: UserUncheckedCreateWithoutEmailCampaignInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailCampaignTemplateInput = {
  create: UserUncheckedCreateWithoutEmailCampaignTemplateInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailCampaignUpdatedInput = {
  create: UserUncheckedCreateWithoutEmailCampaignUpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailCampaignsCreatedInput = {
  create: UserUncheckedCreateWithoutEmailCampaignsCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailCampaignsSentInput = {
  create: UserUncheckedCreateWithoutEmailCampaignsSentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailTemplateVersionsInput = {
  create: UserUncheckedCreateWithoutEmailTemplateVersionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFormSubmissionsInput = {
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  create: UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput = {
  create: UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInvitationsInput = {
  create: UserUncheckedCreateWithoutInvitationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInvitedByInput = {
  create: UserUncheckedCreateWithoutInvitedByInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput = {
  create: UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput = {
  create: UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput = {
  create: UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutManuallySentAutomationRunsInput = {
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMassTextAlertsInput = {
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMediaInput = {
  create: UserUncheckedCreateWithoutMediaInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMessageTemplatesInput = {
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOwnerInfoInput = {
  create: UserUncheckedCreateWithoutOwnerInfoInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPinnedChannelsInput = {
  create: UserUncheckedCreateWithoutPinnedChannelsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRefundsIssuedInput = {
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReviewsInput = {
  create: UserUncheckedCreateWithoutReviewsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentInvoicesInput = {
  create: UserUncheckedCreateWithoutSentInvoicesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentStripePaymentIntentsInput = {
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput = {
  create: UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTriggeredWritebacksInput = {
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUnblockedNumbersInput = {
  create: UserUncheckedCreateWithoutUnblockedNumbersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUsagesRecordedInput = {
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserAppointmentGroupingsInput = {
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserChannelFilterSelectionsInput = {
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserSettingInput = {
  create: UserUncheckedCreateWithoutUserSettingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutValidatedCanonicalServicesInput = {
  create: UserUncheckedCreateWithoutValidatedCanonicalServicesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutVetInfoInput = {
  create: UserUncheckedCreateWithoutVetInfoInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutBlockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutBoardFiltersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutCallsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelCreationSourcesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelMembersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelMessageReactionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelMessagesWithMentionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelStatusesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutClinicPetParentsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutClinicWidgetRequestTypesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutClinicsIdInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutClinicsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutCompletedBenefitUsageFollowupStepsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutConsultationChatsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutCreatedPayoutBatchingPeriodsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailCampaignInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailCampaignTemplateInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailCampaignUpdatedInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailCampaignsCreatedInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailCampaignsSentInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutEmailTemplateVersionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutFormSubmissionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutIntitiatedLoyaltyAccountMergesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutInvitationsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutInvitedByInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutLoaSignedClinicPhoneNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutLoyaltyPointUserGrantsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutManuallySentAutomationRunsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutMassTextAlertsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutMediaInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutMessageTemplatesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutOwnerInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutPinnedChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutPointOfContactEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutRefundsIssuedInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutRequestedEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutResponderEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutReviewsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutSentInvoicesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutSentStripePaymentIntentsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutTriggeredWritebacksInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUnblockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUsagesRecordedInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUserAppointmentGroupingsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUserChannelFilterSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutUserSettingInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutValidatedCanonicalServicesInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutVetInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserCreateNestedOneWithoutInvitationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertCreateNestedManyWithoutCreatedByUserInput>;
  media?: Maybe<MediaCreateNestedOneWithoutUsersInput>;
  messageTemplates?: Maybe<MessageTemplateCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoCreateNestedOneWithoutUserInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionCreateNestedManyWithoutUserInput>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceCreateNestedManyWithoutValidatedByUserInput>;
};

export type UserImpersonation = {
  __typename?: 'UserImpersonation';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  impersonatedInformPartnerUser?: Maybe<InformPartnerUser>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUser?: Maybe<User>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUser?: Maybe<InformPartnerUser>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCountAggregateOutputType = {
  __typename?: 'UserImpersonationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  impersonatedInformPartnerUserId: Scalars['Int'];
  impersonatedUserId: Scalars['Int'];
  informPartnerUserId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserImpersonationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateManyImpersonatedInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyImpersonatedInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyImpersonatedUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyImpersonatedUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyImpersonatedUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyInformPartnerUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyInformPartnerUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationCreateManyUserInputEnvelope = {
  data: Array<UserImpersonationCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserImpersonationCreateNestedManyWithoutImpersonatedInformPartnerUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
};

export type UserImpersonationCreateNestedManyWithoutImpersonatedUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
};

export type UserImpersonationCreateNestedManyWithoutInformPartnerUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
};

export type UserImpersonationCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
};

export type UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput = {
  create: UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationCreateOrConnectWithoutImpersonatedUserInput = {
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationCreateOrConnectWithoutInformPartnerUserInput = {
  create: UserImpersonationUncheckedCreateWithoutInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationCreateOrConnectWithoutUserInput = {
  create: UserImpersonationUncheckedCreateWithoutUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateWithoutImpersonatedUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserImpersonationCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput>;
  impersonatedUser?: Maybe<UserCreateNestedOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  informPartnerUser?: Maybe<InformPartnerUserCreateNestedOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationListRelationFilter = {
  every?: Maybe<UserImpersonationWhereInput>;
  none?: Maybe<UserImpersonationWhereInput>;
  some?: Maybe<UserImpersonationWhereInput>;
};

export type UserImpersonationMaxAggregateOutputType = {
  __typename?: 'UserImpersonationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationMinAggregateOutputType = {
  __typename?: 'UserImpersonationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserImpersonationOrderByRelevanceFieldEnum {
  Id = 'id',
  ImpersonatedInformPartnerUserId = 'impersonatedInformPartnerUserId',
  ImpersonatedUserId = 'impersonatedUserId',
  InformPartnerUserId = 'informPartnerUserId',
  UserId = 'userId'
}

export type UserImpersonationOrderByRelevanceInput = {
  fields: Array<UserImpersonationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserImpersonationOrderByWithAggregationInput = {
  _count?: Maybe<UserImpersonationCountOrderByAggregateInput>;
  _max?: Maybe<UserImpersonationMaxOrderByAggregateInput>;
  _min?: Maybe<UserImpersonationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  impersonatedUserId?: Maybe<SortOrder>;
  informPartnerUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type UserImpersonationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserImpersonationOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  impersonatedInformPartnerUserId?: Maybe<SortOrder>;
  impersonatedUser?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  impersonatedUserId?: Maybe<SortOrder>;
  informPartnerUser?: Maybe<InformPartnerUserOrderByWithRelationAndSearchRelevanceInput>;
  informPartnerUserId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: Maybe<SortOrder>;
};

export enum UserImpersonationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ImpersonatedInformPartnerUserId = 'impersonatedInformPartnerUserId',
  ImpersonatedUserId = 'impersonatedUserId',
  InformPartnerUserId = 'informPartnerUserId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserImpersonationScalarWhereInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableFilter>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  informPartnerUserId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type UserImpersonationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserImpersonationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableWithAggregatesFilter>;
  impersonatedUserId?: Maybe<StringNullableWithAggregatesFilter>;
  informPartnerUserId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserImpersonationUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedInformPartnerUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutInformPartnerUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
};

export type UserImpersonationUncheckedCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
};

export type UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutImpersonatedUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutImpersonatedInformPartnerUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutInformPartnerUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUncheckedUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserImpersonationUncheckedUpdateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUserId?: Maybe<Scalars['String']>;
  impersonatedUserId?: Maybe<Scalars['String']>;
  informPartnerUserId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
};

export type UserImpersonationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput = {
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdInput;
  where: UserImpersonationScalarWhereInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput = {
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
  where: UserImpersonationScalarWhereInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput = {
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdInput;
  where: UserImpersonationScalarWhereInput;
};

export type UserImpersonationUpdateManyWithWhereWithoutUserInput = {
  data: UserImpersonationUncheckedUpdateManyWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
  where: UserImpersonationScalarWhereInput;
};

export type UserImpersonationUpdateManyWithoutImpersonatedInformPartnerUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput>>;
};

export type UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutImpersonatedUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutImpersonatedUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyImpersonatedUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutImpersonatedUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput>>;
};

export type UserImpersonationUpdateManyWithoutInformPartnerUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutInformPartnerUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutInformPartnerUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyInformPartnerUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutInformPartnerUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput>>;
};

export type UserImpersonationUpdateManyWithoutUserNestedInput = {
  connect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserImpersonationCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserImpersonationCreateWithoutUserInput>>;
  createMany?: Maybe<UserImpersonationCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserImpersonationScalarWhereInput>>;
  disconnect?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  set?: Maybe<Array<UserImpersonationWhereUniqueInput>>;
  update?: Maybe<Array<UserImpersonationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserImpersonationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserImpersonationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedInformPartnerUserInput = {
  data: UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutImpersonatedUserInput = {
  data: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutInformPartnerUserInput = {
  data: UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpdateWithWhereUniqueWithoutUserInput = {
  data: UserImpersonationUncheckedUpdateWithoutUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpdateWithoutImpersonatedInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutImpersonatedUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutInformPartnerUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput>;
};

export type UserImpersonationUpdateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Impersonated_Inform_Partner_User_IdNestedInput>;
  impersonatedUser?: Maybe<UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput>;
  informPartnerUser?: Maybe<InformPartnerUserUpdateOneWithoutUserImpersonation_InformPartnerUserToUserImpersonation_Inform_Partner_User_IdNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedInformPartnerUserInput = {
  create: UserImpersonationUncheckedCreateWithoutImpersonatedInformPartnerUserInput;
  update: UserImpersonationUncheckedUpdateWithoutImpersonatedInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutImpersonatedUserInput = {
  create: UserImpersonationUncheckedCreateWithoutImpersonatedUserInput;
  update: UserImpersonationUncheckedUpdateWithoutImpersonatedUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutInformPartnerUserInput = {
  create: UserImpersonationUncheckedCreateWithoutInformPartnerUserInput;
  update: UserImpersonationUncheckedUpdateWithoutInformPartnerUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationUpsertWithWhereUniqueWithoutUserInput = {
  create: UserImpersonationUncheckedCreateWithoutUserInput;
  update: UserImpersonationUncheckedUpdateWithoutUserInput;
  where: UserImpersonationWhereUniqueInput;
};

export type UserImpersonationWhereInput = {
  AND?: Maybe<Array<UserImpersonationWhereInput>>;
  NOT?: Maybe<Array<UserImpersonationWhereInput>>;
  OR?: Maybe<Array<UserImpersonationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  impersonatedInformPartnerUser?: Maybe<InformPartnerUserWhereInput>;
  impersonatedInformPartnerUserId?: Maybe<StringNullableFilter>;
  impersonatedUser?: Maybe<UserWhereInput>;
  impersonatedUserId?: Maybe<StringNullableFilter>;
  informPartnerUser?: Maybe<InformPartnerUserWhereInput>;
  informPartnerUserId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type UserImpersonationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
};

export type UserMaxAggregateOutputType = {
  __typename?: 'UserMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type UserMinAggregateOutputType = {
  __typename?: 'UserMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  emailEnabled: Scalars['Boolean'];
  id: Scalars['String'];
  pushNotificationEnabled: Scalars['Boolean'];
  settingType: NotificationSettingType;
  smsEnabled: Scalars['Boolean'];
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userSetting?: Maybe<UserSetting>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingCountAggregateOutputType = {
  __typename?: 'UserNotificationSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  browserNotificationEnabled: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailEnabled: Scalars['Int'];
  id: Scalars['Int'];
  pushNotificationEnabled: Scalars['Int'];
  settingType: Scalars['Int'];
  smsEnabled: Scalars['Int'];
  soundNotificationUrl: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userSettingId: Scalars['Int'];
};

export type UserNotificationSettingCountOrderByAggregateInput = {
  browserNotificationEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
};

export type UserNotificationSettingCreateInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingCreateNestedOneWithoutNotificationSettingsInput>;
};

export type UserNotificationSettingCreateManyInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingCreateManyUserSettingInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingCreateManyUserSettingInputEnvelope = {
  data: Array<UserNotificationSettingCreateManyUserSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingCreateNestedManyWithoutUserSettingInput = {
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
};

export type UserNotificationSettingCreateOrConnectWithoutUserSettingInput = {
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
  where: UserNotificationSettingWhereUniqueInput;
};

export type UserNotificationSettingCreateWithoutUserSettingInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingListRelationFilter = {
  every?: Maybe<UserNotificationSettingWhereInput>;
  none?: Maybe<UserNotificationSettingWhereInput>;
  some?: Maybe<UserNotificationSettingWhereInput>;
};

export type UserNotificationSettingMaxAggregateOutputType = {
  __typename?: 'UserNotificationSettingMaxAggregateOutputType';
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingMaxOrderByAggregateInput = {
  browserNotificationEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
};

export type UserNotificationSettingMinAggregateOutputType = {
  __typename?: 'UserNotificationSettingMinAggregateOutputType';
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingMinOrderByAggregateInput = {
  browserNotificationEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
};

export type UserNotificationSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserNotificationSettingOrderByRelevanceFieldEnum {
  Id = 'id',
  SoundNotificationUrl = 'soundNotificationUrl',
  UserSettingId = 'userSettingId'
}

export type UserNotificationSettingOrderByRelevanceInput = {
  fields: Array<UserNotificationSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserNotificationSettingOrderByWithAggregationInput = {
  _count?: Maybe<UserNotificationSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserNotificationSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserNotificationSettingMinOrderByAggregateInput>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
};

export type UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserNotificationSettingOrderByRelevanceInput>;
  browserNotificationEnabled?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailEnabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  pushNotificationEnabled?: Maybe<SortOrder>;
  settingType?: Maybe<SortOrder>;
  smsEnabled?: Maybe<SortOrder>;
  soundNotificationUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  userSettingId?: Maybe<SortOrder>;
};

export enum UserNotificationSettingScalarFieldEnum {
  BrowserNotificationEnabled = 'browserNotificationEnabled',
  CreatedAt = 'createdAt',
  EmailEnabled = 'emailEnabled',
  Id = 'id',
  PushNotificationEnabled = 'pushNotificationEnabled',
  SettingType = 'settingType',
  SmsEnabled = 'smsEnabled',
  SoundNotificationUrl = 'soundNotificationUrl',
  UpdatedAt = 'updatedAt',
  UserSettingId = 'userSettingId'
}

export type UserNotificationSettingScalarWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
};

export type UserNotificationSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserNotificationSettingScalarWhereWithAggregatesInput>>;
  browserNotificationEnabled?: Maybe<BoolNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailEnabled?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  pushNotificationEnabled?: Maybe<BoolWithAggregatesFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeWithAggregatesFilter>;
  smsEnabled?: Maybe<BoolWithAggregatesFilter>;
  soundNotificationUrl?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserNotificationSettingUncheckedCreateInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput = {
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
};

export type UserNotificationSettingUncheckedCreateWithoutUserSettingInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType: NotificationSettingType;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingUncheckedUpdateInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingUncheckedUpdateManyInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput = {
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
};

export type UserNotificationSettingUncheckedUpdateWithoutUserSettingInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingUpdateInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutNotificationSettingsNestedInput>;
};

export type UserNotificationSettingUpdateManyMutationInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput = {
  data: UserNotificationSettingUncheckedUpdateManyWithoutNotificationSettingsInput;
  where: UserNotificationSettingScalarWhereInput;
};

export type UserNotificationSettingUpdateManyWithoutUserSettingNestedInput = {
  connect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserNotificationSettingCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserNotificationSettingCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserNotificationSettingCreateManyUserSettingInputEnvelope>;
  delete?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserNotificationSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  set?: Maybe<Array<UserNotificationSettingWhereUniqueInput>>;
  update?: Maybe<Array<UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserNotificationSettingUpdateManyWithWhereWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput>>;
};

export type UserNotificationSettingUpdateWithWhereUniqueWithoutUserSettingInput = {
  data: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
  where: UserNotificationSettingWhereUniqueInput;
};

export type UserNotificationSettingUpdateWithoutUserSettingInput = {
  browserNotificationEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  pushNotificationEnabled?: Maybe<Scalars['Boolean']>;
  settingType?: Maybe<NotificationSettingType>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  soundNotificationUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserNotificationSettingUpsertWithWhereUniqueWithoutUserSettingInput = {
  create: UserNotificationSettingUncheckedCreateWithoutUserSettingInput;
  update: UserNotificationSettingUncheckedUpdateWithoutUserSettingInput;
  where: UserNotificationSettingWhereUniqueInput;
};

export type UserNotificationSettingWhereInput = {
  AND?: Maybe<Array<UserNotificationSettingWhereInput>>;
  NOT?: Maybe<Array<UserNotificationSettingWhereInput>>;
  OR?: Maybe<Array<UserNotificationSettingWhereInput>>;
  browserNotificationEnabled?: Maybe<BoolNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailEnabled?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  pushNotificationEnabled?: Maybe<BoolFilter>;
  settingType?: Maybe<EnumNotificationSettingTypeFilter>;
  smsEnabled?: Maybe<BoolFilter>;
  soundNotificationUrl?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
  userSettingId?: Maybe<StringNullableFilter>;
};

export type UserNotificationSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  CrmId = 'crmId',
  Email = 'email',
  FirebaseId = 'firebaseId',
  FirstName = 'firstName',
  Id = 'id',
  InvitationCode = 'invitationCode',
  InvitedById = 'invitedById',
  LastName = 'lastName',
  MediaId = 'mediaId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  OwnerInfoId = 'ownerInfoId',
  Phone = 'phone',
  ProfilePic = 'profilePic',
  UserSettingId = 'userSettingId',
  VetInfoId = 'vetInfoId'
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserOrderByWithAggregationInput = {
  _count?: Maybe<UserCountOrderByAggregateInput>;
  _max?: Maybe<UserMaxOrderByAggregateInput>;
  _min?: Maybe<UserMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  crmId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invitationCode?: Maybe<SortOrder>;
  invitedAt?: Maybe<SortOrder>;
  invitedById?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  mediaId?: Maybe<SortOrder>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  ownerInfoId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  profilePic?: Maybe<SortOrder>;
  signUpDate?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userSettingId?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  EmailCampaign?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateOrderByRelationAggregateInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignOrderByRelationAggregateInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserOrderByRelevanceInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  boardFilters?: Maybe<UserBoardFilterOrderByRelationAggregateInput>;
  calls?: Maybe<CallParticipantOrderByRelationAggregateInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  channelMembers?: Maybe<ChannelMemberOrderByRelationAggregateInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionOrderByRelationAggregateInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageOrderByRelationAggregateInput>;
  channelSelections?: Maybe<ChannelSelectionOrderByRelationAggregateInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeOrderByRelationAggregateInput>;
  channelStatuses?: Maybe<ChannelStatusOrderByRelationAggregateInput>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  clinicPetParents?: Maybe<ClinicPetParentOrderByRelationAggregateInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  clinicsId?: Maybe<ClinicOrderByRelationAggregateInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepOrderByRelationAggregateInput>;
  consultationChats?: Maybe<ConsultationChatOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodOrderByRelationAggregateInput>;
  crmId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionOrderByRelationAggregateInput>;
  firebaseId?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  formSubmissions?: Maybe<FormSubmissionOrderByRelationAggregateInput>;
  id?: Maybe<SortOrder>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentOrderByRelationAggregateInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerOrderByRelationAggregateInput>;
  invitationCode?: Maybe<SortOrder>;
  invitations?: Maybe<UserOrderByRelationAggregateInput>;
  invitedAt?: Maybe<SortOrder>;
  invitedBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  invitedById?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAdmin?: Maybe<SortOrder>;
  isNewPasswordRequired?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberOrderByRelationAggregateInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantOrderByRelationAggregateInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryOrderByRelationAggregateInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  massTextAlerts?: Maybe<MassTextAlertOrderByRelationAggregateInput>;
  media?: Maybe<MediaOrderByWithRelationAndSearchRelevanceInput>;
  mediaId?: Maybe<SortOrder>;
  messageTemplates?: Maybe<MessageTemplateOrderByRelationAggregateInput>;
  nameDisplay?: Maybe<SortOrder>;
  namePrefix?: Maybe<SortOrder>;
  nameSuffix?: Maybe<SortOrder>;
  ownerInfo?: Maybe<OwnerInfoOrderByWithRelationAndSearchRelevanceInput>;
  ownerInfoId?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  pinnedChannels?: Maybe<ChannelPinOrderByRelationAggregateInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  profilePic?: Maybe<SortOrder>;
  refundsIssued?: Maybe<StripeRefundOrderByRelationAggregateInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventOrderByRelationAggregateInput>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  sentInvoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentOrderByRelationAggregateInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferOrderByRelationAggregateInput>;
  signUpDate?: Maybe<SortOrder>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryOrderByRelationAggregateInput>;
  type?: Maybe<SortOrder>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  usagesRecorded?: Maybe<CareBenefitUsageOrderByRelationAggregateInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingOrderByRelationAggregateInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionOrderByRelationAggregateInput>;
  userSetting?: Maybe<UserSettingOrderByWithRelationAndSearchRelevanceInput>;
  userSettingId?: Maybe<SortOrder>;
  validatedCanonicalServices?: Maybe<CanonicalServiceOrderByRelationAggregateInput>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  vetInfoId?: Maybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  CrmId = 'crmId',
  Email = 'email',
  FirebaseId = 'firebaseId',
  FirstName = 'firstName',
  Id = 'id',
  InvitationCode = 'invitationCode',
  InvitedAt = 'invitedAt',
  InvitedById = 'invitedById',
  IsActive = 'isActive',
  IsAdmin = 'isAdmin',
  IsNewPasswordRequired = 'isNewPasswordRequired',
  LastName = 'lastName',
  MediaId = 'mediaId',
  NameDisplay = 'nameDisplay',
  NamePrefix = 'namePrefix',
  NameSuffix = 'nameSuffix',
  OwnerInfoId = 'ownerInfoId',
  Phone = 'phone',
  ProfilePic = 'profilePic',
  SignUpDate = 'signUpDate',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserSettingId = 'userSettingId',
  VetInfoId = 'vetInfoId'
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  crmId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitedById?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  lastName?: Maybe<StringFilter>;
  mediaId?: Maybe<StringNullableFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userSettingId?: Maybe<StringNullableFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  crmId?: Maybe<StringNullableWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  firstName?: Maybe<StringWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  invitationCode?: Maybe<StringNullableWithAggregatesFilter>;
  invitedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  invitedById?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isAdmin?: Maybe<BoolNullableWithAggregatesFilter>;
  isNewPasswordRequired?: Maybe<BoolWithAggregatesFilter>;
  lastName?: Maybe<StringWithAggregatesFilter>;
  mediaId?: Maybe<StringNullableWithAggregatesFilter>;
  nameDisplay?: Maybe<StringNullableWithAggregatesFilter>;
  namePrefix?: Maybe<StringNullableWithAggregatesFilter>;
  nameSuffix?: Maybe<StringNullableWithAggregatesFilter>;
  ownerInfoId?: Maybe<StringNullableWithAggregatesFilter>;
  phone?: Maybe<StringNullableWithAggregatesFilter>;
  profilePic?: Maybe<StringNullableWithAggregatesFilter>;
  signUpDate?: Maybe<DateTimeNullableWithAggregatesFilter>;
  type?: Maybe<EnumUserTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  userSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  _count: UserSettingCountOutputType;
  browserNotificationFilter?: Maybe<ChannelSelection>;
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  emailNotifications: Scalars['Boolean'];
  enterAddsNewLine: Scalars['Boolean'];
  id: Scalars['String'];
  leftNavPanel: Scalars['Boolean'];
  notificationSettings: Array<UserNotificationSetting>;
  pushNotifications: Scalars['Boolean'];
  skipLoadingAnimation: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  user: Array<User>;
};


export type UserSettingNotificationSettingsArgs = {
  cursor?: Maybe<UserNotificationSettingWhereUniqueInput>;
  distinct?: Maybe<UserNotificationSettingScalarFieldEnum>;
  orderBy?: Maybe<UserNotificationSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserNotificationSettingWhereInput>;
};


export type UserSettingUserArgs = {
  cursor?: Maybe<UserWhereUniqueInput>;
  distinct?: Maybe<UserScalarFieldEnum>;
  orderBy?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export type UserSettingCountAggregateOutputType = {
  __typename?: 'UserSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  browserNotificationFilterId: Scalars['Int'];
  createdAt: Scalars['Int'];
  emailNotifications: Scalars['Int'];
  enterAddsNewLine: Scalars['Int'];
  id: Scalars['Int'];
  leftNavPanel: Scalars['Int'];
  pushNotifications: Scalars['Int'];
  skipLoadingAnimation: Scalars['Int'];
  smsNotifications: Scalars['Int'];
  snoozeNotificationsUntil: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UserSettingCountOrderByAggregateInput = {
  browserNotificationFilterId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserSettingCountOutputType = {
  __typename?: 'UserSettingCountOutputType';
  notificationSettings: Scalars['Int'];
  user: Scalars['Int'];
};

export type UserSettingCreateInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingCreateManyInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingCreateNestedOneWithoutBrowserNotificationFilterInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingCreateNestedOneWithoutNotificationSettingsInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
};

export type UserSettingCreateNestedOneWithoutUserInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
};

export type UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput = {
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingCreateOrConnectWithoutNotificationSettingsInput = {
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingCreateOrConnectWithoutUserInput = {
  create: UserSettingUncheckedCreateWithoutUserInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingCreateWithoutBrowserNotificationFilterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutNotificationSettingsInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingCreateWithoutUserInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionCreateNestedOneWithoutUserSettingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingMaxAggregateOutputType = {
  __typename?: 'UserSettingMaxAggregateOutputType';
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingMaxOrderByAggregateInput = {
  browserNotificationFilterId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserSettingMinAggregateOutputType = {
  __typename?: 'UserSettingMinAggregateOutputType';
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingMinOrderByAggregateInput = {
  browserNotificationFilterId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum UserSettingOrderByRelevanceFieldEnum {
  BrowserNotificationFilterId = 'browserNotificationFilterId',
  Id = 'id'
}

export type UserSettingOrderByRelevanceInput = {
  fields: Array<UserSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type UserSettingOrderByWithAggregationInput = {
  _count?: Maybe<UserSettingCountOrderByAggregateInput>;
  _max?: Maybe<UserSettingMaxOrderByAggregateInput>;
  _min?: Maybe<UserSettingMinOrderByAggregateInput>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  pushNotifications?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<UserSettingOrderByRelevanceInput>;
  browserNotificationFilter?: Maybe<ChannelSelectionOrderByWithRelationAndSearchRelevanceInput>;
  browserNotificationFilterId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  emailNotifications?: Maybe<SortOrder>;
  enterAddsNewLine?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leftNavPanel?: Maybe<SortOrder>;
  notificationSettings?: Maybe<UserNotificationSettingOrderByRelationAggregateInput>;
  pushNotifications?: Maybe<SortOrder>;
  skipLoadingAnimation?: Maybe<SortOrder>;
  smsNotifications?: Maybe<SortOrder>;
  snoozeNotificationsUntil?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByRelationAggregateInput>;
};

export type UserSettingRelationFilter = {
  is?: Maybe<UserSettingWhereInput>;
  isNot?: Maybe<UserSettingWhereInput>;
};

export enum UserSettingScalarFieldEnum {
  BrowserNotificationFilterId = 'browserNotificationFilterId',
  CreatedAt = 'createdAt',
  EmailNotifications = 'emailNotifications',
  EnterAddsNewLine = 'enterAddsNewLine',
  Id = 'id',
  LeftNavPanel = 'leftNavPanel',
  PushNotifications = 'pushNotifications',
  SkipLoadingAnimation = 'skipLoadingAnimation',
  SmsNotifications = 'smsNotifications',
  SnoozeNotificationsUntil = 'snoozeNotificationsUntil',
  UpdatedAt = 'updatedAt'
}

export type UserSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  browserNotificationFilterId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  emailNotifications?: Maybe<BoolWithAggregatesFilter>;
  enterAddsNewLine?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  leftNavPanel?: Maybe<BoolWithAggregatesFilter>;
  pushNotifications?: Maybe<BoolWithAggregatesFilter>;
  skipLoadingAnimation?: Maybe<BoolWithAggregatesFilter>;
  smsNotifications?: Maybe<BoolWithAggregatesFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type UserSettingUncheckedCreateInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateNestedOneWithoutBrowserNotificationFilterInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateWithoutNotificationSettingsInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedManyWithoutUserSettingInput>;
};

export type UserSettingUncheckedCreateWithoutUserInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedCreateNestedManyWithoutUserSettingInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUncheckedUpdateInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateManyInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUncheckedUpdateOneWithoutBrowserNotificationFilterNestedInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateWithoutNotificationSettingsInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUncheckedUpdateWithoutUserInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUncheckedUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUpdateInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUpdateOneWithoutBrowserNotificationFilterNestedInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutBrowserNotificationFilterInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput>;
  upsert?: Maybe<UserSettingUpsertWithoutBrowserNotificationFilterInput>;
};

export type UserSettingUpdateOneWithoutNotificationSettingsNestedInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutNotificationSettingsInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutNotificationSettingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutNotificationSettingsInput>;
  upsert?: Maybe<UserSettingUpsertWithoutNotificationSettingsInput>;
};

export type UserSettingUpdateOneWithoutUserNestedInput = {
  connect?: Maybe<UserSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<UserSettingCreateOrConnectWithoutUserInput>;
  create?: Maybe<UserSettingUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserSettingUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<UserSettingUpsertWithoutUserInput>;
};

export type UserSettingUpdateWithoutBrowserNotificationFilterInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateWithoutNotificationSettingsInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateManyWithoutUserSettingNestedInput>;
};

export type UserSettingUpdateWithoutUserInput = {
  browserNotificationFilter?: Maybe<ChannelSelectionUpdateOneWithoutUserSettingNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  emailNotifications?: Maybe<Scalars['Boolean']>;
  enterAddsNewLine?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  leftNavPanel?: Maybe<Scalars['Boolean']>;
  notificationSettings?: Maybe<UserNotificationSettingUpdateManyWithoutUserSettingNestedInput>;
  pushNotifications?: Maybe<Scalars['Boolean']>;
  skipLoadingAnimation?: Maybe<Scalars['Boolean']>;
  smsNotifications?: Maybe<Scalars['Boolean']>;
  snoozeNotificationsUntil?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSettingUpsertWithoutBrowserNotificationFilterInput = {
  create: UserSettingUncheckedCreateWithoutBrowserNotificationFilterInput;
  update: UserSettingUncheckedUpdateWithoutBrowserNotificationFilterInput;
};

export type UserSettingUpsertWithoutNotificationSettingsInput = {
  create: UserSettingUncheckedCreateWithoutNotificationSettingsInput;
  update: UserSettingUncheckedUpdateWithoutNotificationSettingsInput;
};

export type UserSettingUpsertWithoutUserInput = {
  create: UserSettingUncheckedCreateWithoutUserInput;
  update: UserSettingUncheckedUpdateWithoutUserInput;
};

export type UserSettingWhereInput = {
  AND?: Maybe<Array<UserSettingWhereInput>>;
  NOT?: Maybe<Array<UserSettingWhereInput>>;
  OR?: Maybe<Array<UserSettingWhereInput>>;
  browserNotificationFilter?: Maybe<ChannelSelectionWhereInput>;
  browserNotificationFilterId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  emailNotifications?: Maybe<BoolFilter>;
  enterAddsNewLine?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  leftNavPanel?: Maybe<BoolFilter>;
  notificationSettings?: Maybe<UserNotificationSettingListRelationFilter>;
  pushNotifications?: Maybe<BoolFilter>;
  skipLoadingAnimation?: Maybe<BoolFilter>;
  smsNotifications?: Maybe<BoolFilter>;
  snoozeNotificationsUntil?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserListRelationFilter>;
};

export type UserSettingWhereUniqueInput = {
  browserNotificationFilterId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum UserType {
  Owner = 'OWNER',
  Vet = 'VET'
}

export type UserUncheckedCreateInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateNestedManyWithoutChannelMessagesWithMentionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelSelectionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
};

export type UserUncheckedCreateNestedManyWithoutChannelsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicWidgetRequestTypesInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
};

export type UserUncheckedCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
};

export type UserUncheckedCreateNestedManyWithoutFormSubmissionsInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
};

export type UserUncheckedCreateNestedManyWithoutInvitedByInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
};

export type UserUncheckedCreateNestedManyWithoutMediaInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
};

export type UserUncheckedCreateNestedManyWithoutUserSettingInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
};

export type UserUncheckedCreateNestedOneWithoutOwnerInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
};

export type UserUncheckedCreateNestedOneWithoutVetInfoInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
};

export type UserUncheckedCreateWithoutBlockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutBoardFiltersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutCallsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelCreationSourcesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelMembersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelMessageReactionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelMessagesWithMentionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelStatusesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutClinicPetParentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutClinicWidgetRequestTypesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutClinicsIdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutClinicsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutConsultationChatsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailCampaignInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailCampaignTemplateInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailCampaignUpdatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailCampaignsCreatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailCampaignsSentInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutEmailTemplateVersionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutFormSubmissionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutInvitationsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutInvitedByInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutManuallySentAutomationRunsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutMassTextAlertsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutMediaInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutMessageTemplatesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutOwnerInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutPinnedChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutRefundsIssuedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutReviewsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutSentInvoicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutSentStripePaymentIntentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutTriggeredWritebacksInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUnblockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUsagesRecordedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUserAppointmentGroupingsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUserChannelFilterSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutUserSettingInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutValidatedCanonicalServicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedCreateWithoutVetInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedCreateNestedManyWithoutUserInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutLastUpdatedByUserInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedCreateNestedManyWithoutSentByUserInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutImpersonatedUserInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedCreateNestedManyWithoutUserInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutBlockedByInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedCreateNestedManyWithoutUserInput>;
  calls?: Maybe<CallParticipantUncheckedCreateNestedManyWithoutUserInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedCreateNestedManyWithoutConfirmedByInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutUserInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedCreateNestedManyWithoutUserInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedCreateNestedManyWithoutUserInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedCreateNestedManyWithoutMentionedUsersInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedCreateNestedManyWithoutAssigneesInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedCreateNestedManyWithoutUserInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedCreateNestedManyWithoutDeletedByInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedCreateNestedManyWithoutUserInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutAssigneesInput>;
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersInput>;
  clinicsId?: Maybe<ClinicUncheckedCreateNestedManyWithoutUsersIdInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedCreateNestedManyWithoutCompletedByUserInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedCreateNestedManyWithoutCreatorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  crmId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedCreateNestedManyWithoutLastEditedByInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  formSubmissions?: Maybe<FormSubmissionUncheckedCreateNestedManyWithoutUsersInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedCreateNestedManyWithoutAcceptedTermsByUserInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedCreateNestedManyWithoutInitiatedByInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedCreateNestedManyWithoutInvitedByInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedCreateNestedManyWithoutLoaSignedByUserInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedCreateNestedManyWithoutGrantingUserInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedCreateNestedManyWithoutActingUserInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutManuallySentByInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedCreateNestedManyWithoutCreatedByUserInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedCreateNestedManyWithoutUpdatedByInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedCreateNestedManyWithoutUserInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutPointOfContactInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedCreateNestedManyWithoutIssuedByInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutRequesterInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedCreateNestedManyWithoutResponderInput>;
  reviews?: Maybe<ReviewUncheckedCreateNestedManyWithoutUserInput>;
  sentInvoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutSentByInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedCreateNestedManyWithoutSentByInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedCreateNestedManyWithoutClinicUserInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedCreateNestedManyWithoutTriggeredByInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedCreateNestedManyWithoutUnblockedByInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedCreateNestedManyWithoutRecordedByUserInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedCreateNestedManyWithoutUserInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedCreateNestedManyWithoutUserInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedCreateNestedManyWithoutValidatedByUserInput>;
};

export type UserUncheckedUpdateInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutAssigneesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutChannelMessagesWithMentionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelSelectionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
};

export type UserUncheckedUpdateManyWithoutChannelsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicWidgetRequestTypesNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
};

export type UserUncheckedUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type UserUncheckedUpdateManyWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
};

export type UserUncheckedUpdateManyWithoutInvitationsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutInvitedByNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
};

export type UserUncheckedUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type UserUncheckedUpdateManyWithoutMentionedUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateManyWithoutUserSettingNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
};

export type UserUncheckedUpdateManyWithoutUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateOneWithoutOwnerInfoNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
};

export type UserUncheckedUpdateOneWithoutVetInfoNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
};

export type UserUncheckedUpdateWithoutBlockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutBoardFiltersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutCallsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelCreationSourcesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelMembersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelMessageReactionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelStatusesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutClinicPetParentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutClinicsIdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutClinicsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutConsultationChatsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailCampaignInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailCampaignTemplateInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailCampaignUpdatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailCampaignsCreatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailCampaignsSentInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutEmailTemplateVersionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutFormSubmissionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutInvitationsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutInvitedByInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutManuallySentAutomationRunsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutMassTextAlertsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutMediaInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutMessageTemplatesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutOwnerInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutPinnedChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutRefundsIssuedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutReviewsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutSentInvoicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutSentStripePaymentIntentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutTriggeredWritebacksInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUnblockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUsagesRecordedInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUserAppointmentGroupingsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutUserSettingInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutValidatedCanonicalServicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type UserUncheckedUpdateWithoutVetInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUncheckedUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUncheckedUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUncheckedUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUncheckedUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUncheckedUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUncheckedUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUncheckedUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUncheckedUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUncheckedUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUncheckedUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUncheckedUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUncheckedUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUncheckedUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUncheckedUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUncheckedUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUncheckedUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUncheckedUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUncheckedUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUncheckedUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUncheckedUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedById?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUncheckedUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUncheckedUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUncheckedUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUncheckedUpdateManyWithoutCreatedByUserNestedInput>;
  mediaId?: Maybe<Scalars['String']>;
  messageTemplates?: Maybe<MessageTemplateUncheckedUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUncheckedUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUncheckedUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUncheckedUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUncheckedUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUncheckedUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUncheckedUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUncheckedUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUncheckedUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUncheckedUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUncheckedUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUncheckedUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUncheckedUpdateManyWithoutUserNestedInput>;
  userSettingId?: Maybe<Scalars['String']>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUncheckedUpdateManyWithoutValidatedByUserNestedInput>;
};

export type UserUpdateInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput = {
  data: UserUncheckedUpdateManyWithoutMentionedUsersInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutChannelSelectionsInput = {
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutChannelsInput = {
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput = {
  data: UserUncheckedUpdateManyWithoutAssigneesInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutClinicsInput = {
  data: UserUncheckedUpdateManyWithoutUsersInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFormSubmissionsInput = {
  data: UserUncheckedUpdateManyWithoutUsersInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutInvitedByInput = {
  data: UserUncheckedUpdateManyWithoutInvitationsInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutMediaInput = {
  data: UserUncheckedUpdateManyWithoutUsersInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutUserSettingInput = {
  data: UserUncheckedUpdateManyWithoutUserInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutChannelMessagesWithMentionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelMessagesWithMentionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelMessagesWithMentionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelMessagesWithMentionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput>>;
};

export type UserUpdateManyWithoutChannelSelectionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelSelectionsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelSelectionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelSelectionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelSelectionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelSelectionsInput>>;
};

export type UserUpdateManyWithoutChannelsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutChannelsInput>>;
  create?: Maybe<Array<UserCreateWithoutChannelsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutChannelsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutChannelsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutChannelsInput>>;
};

export type UserUpdateManyWithoutClinicWidgetRequestTypesNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicWidgetRequestTypesInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicWidgetRequestTypesInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput>>;
};

export type UserUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<UserCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type UserUpdateManyWithoutFormSubmissionsNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutFormSubmissionsInput>>;
  create?: Maybe<Array<UserCreateWithoutFormSubmissionsInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutFormSubmissionsInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutFormSubmissionsInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutFormSubmissionsInput>>;
};

export type UserUpdateManyWithoutInvitedByNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutInvitedByInput>>;
  create?: Maybe<Array<UserCreateWithoutInvitedByInput>>;
  createMany?: Maybe<UserCreateManyInvitedByInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutInvitedByInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutInvitedByInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutInvitedByInput>>;
};

export type UserUpdateManyWithoutMediaNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutMediaInput>>;
  create?: Maybe<Array<UserCreateWithoutMediaInput>>;
  createMany?: Maybe<UserCreateManyMediaInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutMediaInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutMediaInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutMediaInput>>;
};

export type UserUpdateManyWithoutUserSettingNestedInput = {
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutUserSettingInput>>;
  create?: Maybe<Array<UserCreateWithoutUserSettingInput>>;
  createMany?: Maybe<UserCreateManyUserSettingInputEnvelope>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutUserSettingInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutUserSettingInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutUserSettingInput>>;
};

export type UserUpdateOneRequiredWithoutBoardFiltersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBoardFiltersInput>;
  create?: Maybe<UserUncheckedCreateWithoutBoardFiltersInput>;
  update?: Maybe<UserUncheckedUpdateWithoutBoardFiltersInput>;
  upsert?: Maybe<UserUpsertWithoutBoardFiltersInput>;
};

export type UserUpdateOneRequiredWithoutChannelMessageReactionsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMessageReactionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelMessageReactionsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMessageReactionsInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMessageReactionsInput>;
};

export type UserUpdateOneRequiredWithoutChannelStatusChannelAssigneesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusChannelAssigneesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusChannelAssigneesInput>;
};

export type UserUpdateOneRequiredWithoutIntitiatedLoyaltyAccountMergesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutIntitiatedLoyaltyAccountMergesInput>;
  create?: Maybe<UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput>;
  update?: Maybe<UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput>;
  upsert?: Maybe<UserUpsertWithoutIntitiatedLoyaltyAccountMergesInput>;
};

export type UserUpdateOneRequiredWithoutLoyaltyPointUserGrantsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyPointUserGrantsInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput>;
  upsert?: Maybe<UserUpsertWithoutLoyaltyPointUserGrantsInput>;
};

export type UserUpdateOneRequiredWithoutMassTextAlertsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMassTextAlertsInput>;
  create?: Maybe<UserUncheckedCreateWithoutMassTextAlertsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutMassTextAlertsInput>;
  upsert?: Maybe<UserUpsertWithoutMassTextAlertsInput>;
};

export type UserUpdateOneRequiredWithoutPinnedChannelsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPinnedChannelsInput>;
  create?: Maybe<UserUncheckedCreateWithoutPinnedChannelsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutPinnedChannelsInput>;
  upsert?: Maybe<UserUpsertWithoutPinnedChannelsInput>;
};

export type UserUpdateOneRequiredWithoutSentTrupanionExamDayOffersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentTrupanionExamDayOffersInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput>;
  update?: Maybe<UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput>;
  upsert?: Maybe<UserUpsertWithoutSentTrupanionExamDayOffersInput>;
};

export type UserUpdateOneRequiredWithoutUserChannelFilterSelectionsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserChannelFilterSelectionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserChannelFilterSelectionsInput>;
  update?: Maybe<UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput>;
  upsert?: Maybe<UserUpsertWithoutUserChannelFilterSelectionsInput>;
};

export type UserUpdateOneWithoutBlockedNumbersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBlockedNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutBlockedNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutBlockedNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutBlockedNumbersInput>;
};

export type UserUpdateOneWithoutCallsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCallsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCallsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutCallsInput>;
  upsert?: Maybe<UserUpsertWithoutCallsInput>;
};

export type UserUpdateOneWithoutCareBenefitUsageToInvoiceLineItemsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput>;
  upsert?: Maybe<UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput>;
};

export type UserUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelCreationSourcesInput>;
};

export type UserUpdateOneWithoutChannelMembersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelMembersInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelMembersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelMembersInput>;
  upsert?: Maybe<UserUpsertWithoutChannelMembersInput>;
};

export type UserUpdateOneWithoutChannelStatusesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutChannelStatusesInput>;
  create?: Maybe<UserUncheckedCreateWithoutChannelStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutChannelStatusesInput>;
  upsert?: Maybe<UserUpsertWithoutChannelStatusesInput>;
};

export type UserUpdateOneWithoutClinicPetParentsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicPetParentsInput>;
  create?: Maybe<UserUncheckedCreateWithoutClinicPetParentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutClinicPetParentsInput>;
  upsert?: Maybe<UserUpsertWithoutClinicPetParentsInput>;
};

export type UserUpdateOneWithoutClinicsIdNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClinicsIdInput>;
  create?: Maybe<UserUncheckedCreateWithoutClinicsIdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutClinicsIdInput>;
  upsert?: Maybe<UserUpsertWithoutClinicsIdInput>;
};

export type UserUpdateOneWithoutCompletedBenefitUsageFollowupStepsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCompletedBenefitUsageFollowupStepsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput>;
  upsert?: Maybe<UserUpsertWithoutCompletedBenefitUsageFollowupStepsInput>;
};

export type UserUpdateOneWithoutConsultationChatsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutConsultationChatsInput>;
  create?: Maybe<UserUncheckedCreateWithoutConsultationChatsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutConsultationChatsInput>;
  upsert?: Maybe<UserUpsertWithoutConsultationChatsInput>;
};

export type UserUpdateOneWithoutCreatedPayoutBatchingPeriodsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCreatedPayoutBatchingPeriodsInput>;
  create?: Maybe<UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput>;
  upsert?: Maybe<UserUpsertWithoutCreatedPayoutBatchingPeriodsInput>;
};

export type UserUpdateOneWithoutEmailCampaignNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignInput>;
};

export type UserUpdateOneWithoutEmailCampaignTemplateNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignTemplateInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignTemplateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignTemplateInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignTemplateInput>;
};

export type UserUpdateOneWithoutEmailCampaignUpdatedNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignUpdatedInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignUpdatedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignUpdatedInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignUpdatedInput>;
};

export type UserUpdateOneWithoutEmailCampaignsCreatedNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsCreatedInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsCreatedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignsCreatedInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignsCreatedInput>;
};

export type UserUpdateOneWithoutEmailCampaignsSentNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailCampaignsSentInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailCampaignsSentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailCampaignsSentInput>;
  upsert?: Maybe<UserUpsertWithoutEmailCampaignsSentInput>;
};

export type UserUpdateOneWithoutEmailTemplateVersionsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEmailTemplateVersionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutEmailTemplateVersionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutEmailTemplateVersionsInput>;
  upsert?: Maybe<UserUpsertWithoutEmailTemplateVersionsInput>;
};

export type UserUpdateOneWithoutInformPartnerProgramEnrollmentTermsAcceptancesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  create?: Maybe<UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
  upsert?: Maybe<UserUpsertWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput>;
};

export type UserUpdateOneWithoutInvitationsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutInvitationsInput>;
  create?: Maybe<UserUncheckedCreateWithoutInvitationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutInvitationsInput>;
  upsert?: Maybe<UserUpsertWithoutInvitationsInput>;
};

export type UserUpdateOneWithoutLoaSignedClinicPhoneNumbersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoaSignedClinicPhoneNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutLoaSignedClinicPhoneNumbersInput>;
};

export type UserUpdateOneWithoutLoyaltyRewardRedemptionActionsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutLoyaltyRewardRedemptionActionsInput>;
  create?: Maybe<UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput>;
  upsert?: Maybe<UserUpsertWithoutLoyaltyRewardRedemptionActionsInput>;
};

export type UserUpdateOneWithoutManuallySentAutomationRunsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutManuallySentAutomationRunsInput>;
  create?: Maybe<UserUncheckedCreateWithoutManuallySentAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutManuallySentAutomationRunsInput>;
  upsert?: Maybe<UserUpsertWithoutManuallySentAutomationRunsInput>;
};

export type UserUpdateOneWithoutMessageTemplatesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMessageTemplatesInput>;
  create?: Maybe<UserUncheckedCreateWithoutMessageTemplatesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutMessageTemplatesInput>;
  upsert?: Maybe<UserUpsertWithoutMessageTemplatesInput>;
};

export type UserUpdateOneWithoutOwnerInfoNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutOwnerInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutOwnerInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutOwnerInfoInput>;
  upsert?: Maybe<UserUpsertWithoutOwnerInfoInput>;
};

export type UserUpdateOneWithoutPointOfContactEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutPointOfContactEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutPointOfContactEnrollmentSupportEventsInput>;
};

export type UserUpdateOneWithoutRefundsIssuedNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRefundsIssuedInput>;
  create?: Maybe<UserUncheckedCreateWithoutRefundsIssuedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutRefundsIssuedInput>;
  upsert?: Maybe<UserUpsertWithoutRefundsIssuedInput>;
};

export type UserUpdateOneWithoutRequestedEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRequestedEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutRequestedEnrollmentSupportEventsInput>;
};

export type UserUpdateOneWithoutResponderEnrollmentSupportEventsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutResponderEnrollmentSupportEventsInput>;
  create?: Maybe<UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput>;
  upsert?: Maybe<UserUpsertWithoutResponderEnrollmentSupportEventsInput>;
};

export type UserUpdateOneWithoutReviewsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  create?: Maybe<UserUncheckedCreateWithoutReviewsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutReviewsInput>;
  upsert?: Maybe<UserUpsertWithoutReviewsInput>;
};

export type UserUpdateOneWithoutSentInvoicesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentInvoicesInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentInvoicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutSentInvoicesInput>;
  upsert?: Maybe<UserUpsertWithoutSentInvoicesInput>;
};

export type UserUpdateOneWithoutSentStripePaymentIntentsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSentStripePaymentIntentsInput>;
  create?: Maybe<UserUncheckedCreateWithoutSentStripePaymentIntentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutSentStripePaymentIntentsInput>;
  upsert?: Maybe<UserUpsertWithoutSentStripePaymentIntentsInput>;
};

export type UserUpdateOneWithoutTriggeredWritebacksNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTriggeredWritebacksInput>;
  create?: Maybe<UserUncheckedCreateWithoutTriggeredWritebacksInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutTriggeredWritebacksInput>;
  upsert?: Maybe<UserUpsertWithoutTriggeredWritebacksInput>;
};

export type UserUpdateOneWithoutUnblockedNumbersNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUnblockedNumbersInput>;
  create?: Maybe<UserUncheckedCreateWithoutUnblockedNumbersInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutUnblockedNumbersInput>;
  upsert?: Maybe<UserUpsertWithoutUnblockedNumbersInput>;
};

export type UserUpdateOneWithoutUsagesRecordedNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUsagesRecordedInput>;
  create?: Maybe<UserUncheckedCreateWithoutUsagesRecordedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutUsagesRecordedInput>;
  upsert?: Maybe<UserUpsertWithoutUsagesRecordedInput>;
};

export type UserUpdateOneWithoutUserAppointmentGroupingsNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAppointmentGroupingsInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserAppointmentGroupingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutUserAppointmentGroupingsInput>;
  upsert?: Maybe<UserUpsertWithoutUserAppointmentGroupingsInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput>;
};

export type UserUpdateOneWithoutUserImpersonation_UserToUserImpersonation_User_IdNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  create?: Maybe<UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
  upsert?: Maybe<UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput>;
};

export type UserUpdateOneWithoutValidatedCanonicalServicesNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutValidatedCanonicalServicesInput>;
  create?: Maybe<UserUncheckedCreateWithoutValidatedCanonicalServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutValidatedCanonicalServicesInput>;
  upsert?: Maybe<UserUpsertWithoutValidatedCanonicalServicesInput>;
};

export type UserUpdateOneWithoutVetInfoNestedInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutVetInfoInput>;
  create?: Maybe<UserUncheckedCreateWithoutVetInfoInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUncheckedUpdateWithoutVetInfoInput>;
  upsert?: Maybe<UserUpsertWithoutVetInfoInput>;
};

export type UserUpdateWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  data: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelSelectionsInput = {
  data: UserUncheckedUpdateWithoutChannelSelectionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutChannelsInput = {
  data: UserUncheckedUpdateWithoutChannelsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  data: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutClinicsInput = {
  data: UserUncheckedUpdateWithoutClinicsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFormSubmissionsInput = {
  data: UserUncheckedUpdateWithoutFormSubmissionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutInvitedByInput = {
  data: UserUncheckedUpdateWithoutInvitedByInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutMediaInput = {
  data: UserUncheckedUpdateWithoutMediaInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutUserSettingInput = {
  data: UserUncheckedUpdateWithoutUserSettingInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutBlockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutBoardFiltersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutCallsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelCreationSourcesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelMembersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelMessageReactionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelMessagesWithMentionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelStatusChannelAssigneesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelStatusesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutClinicPetParentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutClinicWidgetRequestTypesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutClinicsIdInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutClinicsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutCompletedBenefitUsageFollowupStepsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutConsultationChatsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutCreatedPayoutBatchingPeriodsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignInput = {
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignTemplateInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignUpdatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignsCreatedInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailCampaignsSentInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutEmailTemplateVersionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutFormSubmissionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutIntitiatedLoyaltyAccountMergesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutInvitationsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutInvitedByInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutLoaSignedClinicPhoneNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutLoyaltyPointUserGrantsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutLoyaltyRewardRedemptionActionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutManuallySentAutomationRunsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutMassTextAlertsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutMediaInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutMessageTemplatesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutOwnerInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutPinnedChannelsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutPointOfContactEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutRefundsIssuedInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutRequestedEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutResponderEnrollmentSupportEventsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutReviewsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutSentInvoicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutSentStripePaymentIntentsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutSentTrupanionExamDayOffersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutTriggeredWritebacksInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUnblockedNumbersInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUsagesRecordedInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserAppointmentGroupingsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserChannelFilterSelectionsInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserSettingInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutValidatedCanonicalServicesInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutVetInfoInput = {
  EmailCampaign?: Maybe<EmailCampaignUpdateManyWithoutUserNestedInput>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateUpdateManyWithoutUserNestedInput>;
  EmailCampaignUpdated?: Maybe<EmailCampaignUpdateManyWithoutLastUpdatedByUserNestedInput>;
  EmailCampaignsCreated?: Maybe<EmailCampaignUpdateManyWithoutCreatedByUserNestedInput>;
  EmailCampaignsSent?: Maybe<EmailCampaignUpdateManyWithoutSentByUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationUpdateManyWithoutImpersonatedUserNestedInput>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationUpdateManyWithoutUserNestedInput>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutBlockedByNestedInput>;
  boardFilters?: Maybe<UserBoardFilterUpdateManyWithoutUserNestedInput>;
  calls?: Maybe<CallParticipantUpdateManyWithoutUserNestedInput>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemUpdateManyWithoutConfirmedByNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutUserNestedInput>;
  channelMembers?: Maybe<ChannelMemberUpdateManyWithoutUserNestedInput>;
  channelMessageReactions?: Maybe<ChannelMessageReactionUpdateManyWithoutUserNestedInput>;
  channelMessagesWithMentions?: Maybe<ChannelMessageUpdateManyWithoutMentionedUsersNestedInput>;
  channelSelections?: Maybe<ChannelSelectionUpdateManyWithoutAssigneesNestedInput>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeUpdateManyWithoutUserNestedInput>;
  channelStatuses?: Maybe<ChannelStatusUpdateManyWithoutDeletedByNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutAssigneesNestedInput>;
  clinicPetParents?: Maybe<ClinicPetParentUpdateManyWithoutUserNestedInput>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutAssigneesNestedInput>;
  clinics?: Maybe<ClinicUpdateManyWithoutUsersNestedInput>;
  clinicsId?: Maybe<ClinicUpdateManyWithoutUsersIdNestedInput>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepUpdateManyWithoutCompletedByUserNestedInput>;
  consultationChats?: Maybe<ConsultationChatUpdateManyWithoutCreatorNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodUpdateManyWithoutCreatedByUserNestedInput>;
  crmId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionUpdateManyWithoutLastEditedByNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formSubmissions?: Maybe<FormSubmissionUpdateManyWithoutUsersNestedInput>;
  id?: Maybe<Scalars['String']>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentUpdateManyWithoutAcceptedTermsByUserNestedInput>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerUpdateManyWithoutInitiatedByNestedInput>;
  invitationCode?: Maybe<Scalars['String']>;
  invitations?: Maybe<UserUpdateManyWithoutInvitedByNestedInput>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedBy?: Maybe<UserUpdateOneWithoutInvitationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isNewPasswordRequired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberUpdateManyWithoutLoaSignedByUserNestedInput>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantUpdateManyWithoutGrantingUserNestedInput>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryUpdateManyWithoutActingUserNestedInput>;
  manuallySentAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutManuallySentByNestedInput>;
  massTextAlerts?: Maybe<MassTextAlertUpdateManyWithoutCreatedByUserNestedInput>;
  media?: Maybe<MediaUpdateOneWithoutUsersNestedInput>;
  messageTemplates?: Maybe<MessageTemplateUpdateManyWithoutUpdatedByNestedInput>;
  nameDisplay?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  nameSuffix?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoUpdateOneWithoutUserNestedInput>;
  phone?: Maybe<Scalars['String']>;
  pinnedChannels?: Maybe<ChannelPinUpdateManyWithoutUserNestedInput>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutPointOfContactNestedInput>;
  profilePic?: Maybe<Scalars['String']>;
  refundsIssued?: Maybe<StripeRefundUpdateManyWithoutIssuedByNestedInput>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutRequesterNestedInput>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventUpdateManyWithoutResponderNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sentInvoices?: Maybe<InvoiceUpdateManyWithoutSentByNestedInput>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentUpdateManyWithoutSentByNestedInput>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferUpdateManyWithoutClinicUserNestedInput>;
  signUpDate?: Maybe<Scalars['DateTime']>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryUpdateManyWithoutTriggeredByNestedInput>;
  type?: Maybe<UserType>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberUpdateManyWithoutUnblockedByNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usagesRecorded?: Maybe<CareBenefitUsageUpdateManyWithoutRecordedByUserNestedInput>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingUpdateManyWithoutUserNestedInput>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionUpdateManyWithoutUserNestedInput>;
  userSetting?: Maybe<UserSettingUpdateOneWithoutUserNestedInput>;
  validatedCanonicalServices?: Maybe<CanonicalServiceUpdateManyWithoutValidatedByUserNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutChannelMessagesWithMentionsInput = {
  create: UserUncheckedCreateWithoutChannelMessagesWithMentionsInput;
  update: UserUncheckedUpdateWithoutChannelMessagesWithMentionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelSelectionsInput = {
  create: UserUncheckedCreateWithoutChannelSelectionsInput;
  update: UserUncheckedUpdateWithoutChannelSelectionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutChannelsInput = {
  create: UserUncheckedCreateWithoutChannelsInput;
  update: UserUncheckedUpdateWithoutChannelsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicWidgetRequestTypesInput = {
  create: UserUncheckedCreateWithoutClinicWidgetRequestTypesInput;
  update: UserUncheckedUpdateWithoutClinicWidgetRequestTypesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutClinicsInput = {
  create: UserUncheckedCreateWithoutClinicsInput;
  update: UserUncheckedUpdateWithoutClinicsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFormSubmissionsInput = {
  create: UserUncheckedCreateWithoutFormSubmissionsInput;
  update: UserUncheckedUpdateWithoutFormSubmissionsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutInvitedByInput = {
  create: UserUncheckedCreateWithoutInvitedByInput;
  update: UserUncheckedUpdateWithoutInvitedByInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutMediaInput = {
  create: UserUncheckedCreateWithoutMediaInput;
  update: UserUncheckedUpdateWithoutMediaInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutUserSettingInput = {
  create: UserUncheckedCreateWithoutUserSettingInput;
  update: UserUncheckedUpdateWithoutUserSettingInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutBlockedNumbersInput = {
  create: UserUncheckedCreateWithoutBlockedNumbersInput;
  update: UserUncheckedUpdateWithoutBlockedNumbersInput;
};

export type UserUpsertWithoutBoardFiltersInput = {
  create: UserUncheckedCreateWithoutBoardFiltersInput;
  update: UserUncheckedUpdateWithoutBoardFiltersInput;
};

export type UserUpsertWithoutCallsInput = {
  create: UserUncheckedCreateWithoutCallsInput;
  update: UserUncheckedUpdateWithoutCallsInput;
};

export type UserUpsertWithoutCareBenefitUsageToInvoiceLineItemsInput = {
  create: UserUncheckedCreateWithoutCareBenefitUsageToInvoiceLineItemsInput;
  update: UserUncheckedUpdateWithoutCareBenefitUsageToInvoiceLineItemsInput;
};

export type UserUpsertWithoutChannelCreationSourcesInput = {
  create: UserUncheckedCreateWithoutChannelCreationSourcesInput;
  update: UserUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type UserUpsertWithoutChannelMembersInput = {
  create: UserUncheckedCreateWithoutChannelMembersInput;
  update: UserUncheckedUpdateWithoutChannelMembersInput;
};

export type UserUpsertWithoutChannelMessageReactionsInput = {
  create: UserUncheckedCreateWithoutChannelMessageReactionsInput;
  update: UserUncheckedUpdateWithoutChannelMessageReactionsInput;
};

export type UserUpsertWithoutChannelStatusChannelAssigneesInput = {
  create: UserUncheckedCreateWithoutChannelStatusChannelAssigneesInput;
  update: UserUncheckedUpdateWithoutChannelStatusChannelAssigneesInput;
};

export type UserUpsertWithoutChannelStatusesInput = {
  create: UserUncheckedCreateWithoutChannelStatusesInput;
  update: UserUncheckedUpdateWithoutChannelStatusesInput;
};

export type UserUpsertWithoutClinicPetParentsInput = {
  create: UserUncheckedCreateWithoutClinicPetParentsInput;
  update: UserUncheckedUpdateWithoutClinicPetParentsInput;
};

export type UserUpsertWithoutClinicsIdInput = {
  create: UserUncheckedCreateWithoutClinicsIdInput;
  update: UserUncheckedUpdateWithoutClinicsIdInput;
};

export type UserUpsertWithoutCompletedBenefitUsageFollowupStepsInput = {
  create: UserUncheckedCreateWithoutCompletedBenefitUsageFollowupStepsInput;
  update: UserUncheckedUpdateWithoutCompletedBenefitUsageFollowupStepsInput;
};

export type UserUpsertWithoutConsultationChatsInput = {
  create: UserUncheckedCreateWithoutConsultationChatsInput;
  update: UserUncheckedUpdateWithoutConsultationChatsInput;
};

export type UserUpsertWithoutCreatedPayoutBatchingPeriodsInput = {
  create: UserUncheckedCreateWithoutCreatedPayoutBatchingPeriodsInput;
  update: UserUncheckedUpdateWithoutCreatedPayoutBatchingPeriodsInput;
};

export type UserUpsertWithoutEmailCampaignInput = {
  create: UserUncheckedCreateWithoutEmailCampaignInput;
  update: UserUncheckedUpdateWithoutEmailCampaignInput;
};

export type UserUpsertWithoutEmailCampaignTemplateInput = {
  create: UserUncheckedCreateWithoutEmailCampaignTemplateInput;
  update: UserUncheckedUpdateWithoutEmailCampaignTemplateInput;
};

export type UserUpsertWithoutEmailCampaignUpdatedInput = {
  create: UserUncheckedCreateWithoutEmailCampaignUpdatedInput;
  update: UserUncheckedUpdateWithoutEmailCampaignUpdatedInput;
};

export type UserUpsertWithoutEmailCampaignsCreatedInput = {
  create: UserUncheckedCreateWithoutEmailCampaignsCreatedInput;
  update: UserUncheckedUpdateWithoutEmailCampaignsCreatedInput;
};

export type UserUpsertWithoutEmailCampaignsSentInput = {
  create: UserUncheckedCreateWithoutEmailCampaignsSentInput;
  update: UserUncheckedUpdateWithoutEmailCampaignsSentInput;
};

export type UserUpsertWithoutEmailTemplateVersionsInput = {
  create: UserUncheckedCreateWithoutEmailTemplateVersionsInput;
  update: UserUncheckedUpdateWithoutEmailTemplateVersionsInput;
};

export type UserUpsertWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput = {
  create: UserUncheckedCreateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
  update: UserUncheckedUpdateWithoutInformPartnerProgramEnrollmentTermsAcceptancesInput;
};

export type UserUpsertWithoutIntitiatedLoyaltyAccountMergesInput = {
  create: UserUncheckedCreateWithoutIntitiatedLoyaltyAccountMergesInput;
  update: UserUncheckedUpdateWithoutIntitiatedLoyaltyAccountMergesInput;
};

export type UserUpsertWithoutInvitationsInput = {
  create: UserUncheckedCreateWithoutInvitationsInput;
  update: UserUncheckedUpdateWithoutInvitationsInput;
};

export type UserUpsertWithoutLoaSignedClinicPhoneNumbersInput = {
  create: UserUncheckedCreateWithoutLoaSignedClinicPhoneNumbersInput;
  update: UserUncheckedUpdateWithoutLoaSignedClinicPhoneNumbersInput;
};

export type UserUpsertWithoutLoyaltyPointUserGrantsInput = {
  create: UserUncheckedCreateWithoutLoyaltyPointUserGrantsInput;
  update: UserUncheckedUpdateWithoutLoyaltyPointUserGrantsInput;
};

export type UserUpsertWithoutLoyaltyRewardRedemptionActionsInput = {
  create: UserUncheckedCreateWithoutLoyaltyRewardRedemptionActionsInput;
  update: UserUncheckedUpdateWithoutLoyaltyRewardRedemptionActionsInput;
};

export type UserUpsertWithoutManuallySentAutomationRunsInput = {
  create: UserUncheckedCreateWithoutManuallySentAutomationRunsInput;
  update: UserUncheckedUpdateWithoutManuallySentAutomationRunsInput;
};

export type UserUpsertWithoutMassTextAlertsInput = {
  create: UserUncheckedCreateWithoutMassTextAlertsInput;
  update: UserUncheckedUpdateWithoutMassTextAlertsInput;
};

export type UserUpsertWithoutMessageTemplatesInput = {
  create: UserUncheckedCreateWithoutMessageTemplatesInput;
  update: UserUncheckedUpdateWithoutMessageTemplatesInput;
};

export type UserUpsertWithoutOwnerInfoInput = {
  create: UserUncheckedCreateWithoutOwnerInfoInput;
  update: UserUncheckedUpdateWithoutOwnerInfoInput;
};

export type UserUpsertWithoutPinnedChannelsInput = {
  create: UserUncheckedCreateWithoutPinnedChannelsInput;
  update: UserUncheckedUpdateWithoutPinnedChannelsInput;
};

export type UserUpsertWithoutPointOfContactEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutPointOfContactEnrollmentSupportEventsInput;
  update: UserUncheckedUpdateWithoutPointOfContactEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutRefundsIssuedInput = {
  create: UserUncheckedCreateWithoutRefundsIssuedInput;
  update: UserUncheckedUpdateWithoutRefundsIssuedInput;
};

export type UserUpsertWithoutRequestedEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutRequestedEnrollmentSupportEventsInput;
  update: UserUncheckedUpdateWithoutRequestedEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutResponderEnrollmentSupportEventsInput = {
  create: UserUncheckedCreateWithoutResponderEnrollmentSupportEventsInput;
  update: UserUncheckedUpdateWithoutResponderEnrollmentSupportEventsInput;
};

export type UserUpsertWithoutReviewsInput = {
  create: UserUncheckedCreateWithoutReviewsInput;
  update: UserUncheckedUpdateWithoutReviewsInput;
};

export type UserUpsertWithoutSentInvoicesInput = {
  create: UserUncheckedCreateWithoutSentInvoicesInput;
  update: UserUncheckedUpdateWithoutSentInvoicesInput;
};

export type UserUpsertWithoutSentStripePaymentIntentsInput = {
  create: UserUncheckedCreateWithoutSentStripePaymentIntentsInput;
  update: UserUncheckedUpdateWithoutSentStripePaymentIntentsInput;
};

export type UserUpsertWithoutSentTrupanionExamDayOffersInput = {
  create: UserUncheckedCreateWithoutSentTrupanionExamDayOffersInput;
  update: UserUncheckedUpdateWithoutSentTrupanionExamDayOffersInput;
};

export type UserUpsertWithoutTriggeredWritebacksInput = {
  create: UserUncheckedCreateWithoutTriggeredWritebacksInput;
  update: UserUncheckedUpdateWithoutTriggeredWritebacksInput;
};

export type UserUpsertWithoutUnblockedNumbersInput = {
  create: UserUncheckedCreateWithoutUnblockedNumbersInput;
  update: UserUncheckedUpdateWithoutUnblockedNumbersInput;
};

export type UserUpsertWithoutUsagesRecordedInput = {
  create: UserUncheckedCreateWithoutUsagesRecordedInput;
  update: UserUncheckedUpdateWithoutUsagesRecordedInput;
};

export type UserUpsertWithoutUserAppointmentGroupingsInput = {
  create: UserUncheckedCreateWithoutUserAppointmentGroupingsInput;
  update: UserUncheckedUpdateWithoutUserAppointmentGroupingsInput;
};

export type UserUpsertWithoutUserChannelFilterSelectionsInput = {
  create: UserUncheckedCreateWithoutUserChannelFilterSelectionsInput;
  update: UserUncheckedUpdateWithoutUserChannelFilterSelectionsInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput = {
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_Impersonated_User_IdInput;
};

export type UserUpsertWithoutUserImpersonation_UserToUserImpersonation_User_IdInput = {
  create: UserUncheckedCreateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
  update: UserUncheckedUpdateWithoutUserImpersonation_UserToUserImpersonation_User_IdInput;
};

export type UserUpsertWithoutValidatedCanonicalServicesInput = {
  create: UserUncheckedCreateWithoutValidatedCanonicalServicesInput;
  update: UserUncheckedUpdateWithoutValidatedCanonicalServicesInput;
};

export type UserUpsertWithoutVetInfoInput = {
  create: UserUncheckedCreateWithoutVetInfoInput;
  update: UserUncheckedUpdateWithoutVetInfoInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  EmailCampaign?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignTemplate?: Maybe<EmailCampaignTemplateListRelationFilter>;
  EmailCampaignUpdated?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignsCreated?: Maybe<EmailCampaignListRelationFilter>;
  EmailCampaignsSent?: Maybe<EmailCampaignListRelationFilter>;
  NOT?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  UserImpersonation_UserToUserImpersonation_impersonated_user_id?: Maybe<UserImpersonationListRelationFilter>;
  UserImpersonation_UserToUserImpersonation_user_id?: Maybe<UserImpersonationListRelationFilter>;
  blockedNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  boardFilters?: Maybe<UserBoardFilterListRelationFilter>;
  calls?: Maybe<CallParticipantListRelationFilter>;
  careBenefitUsageToInvoiceLineItems?: Maybe<CareBenefitUsageToInvoiceLineItemListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  channelMembers?: Maybe<ChannelMemberListRelationFilter>;
  channelMessageReactions?: Maybe<ChannelMessageReactionListRelationFilter>;
  channelMessagesWithMentions?: Maybe<ChannelMessageListRelationFilter>;
  channelSelections?: Maybe<ChannelSelectionListRelationFilter>;
  channelStatusChannelAssignees?: Maybe<ChannelStatusChannelAssigneeListRelationFilter>;
  channelStatuses?: Maybe<ChannelStatusListRelationFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  clinicPetParents?: Maybe<ClinicPetParentListRelationFilter>;
  clinicWidgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  clinics?: Maybe<ClinicListRelationFilter>;
  clinicsId?: Maybe<ClinicListRelationFilter>;
  completedBenefitUsageFollowupSteps?: Maybe<CareBenefitUsageFollowupStepListRelationFilter>;
  consultationChats?: Maybe<ConsultationChatListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdPayoutBatchingPeriods?: Maybe<PayoutBatchingPeriodListRelationFilter>;
  crmId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringFilter>;
  emailTemplateVersions?: Maybe<EmailTemplateVersionListRelationFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  formSubmissions?: Maybe<FormSubmissionListRelationFilter>;
  id?: Maybe<StringFilter>;
  informPartnerProgramEnrollmentTermsAcceptances?: Maybe<InformPartnerProgramEnrollmentListRelationFilter>;
  intitiatedLoyaltyAccountMerges?: Maybe<LoyaltyAccountMergerListRelationFilter>;
  invitationCode?: Maybe<StringNullableFilter>;
  invitations?: Maybe<UserListRelationFilter>;
  invitedAt?: Maybe<DateTimeNullableFilter>;
  invitedBy?: Maybe<UserWhereInput>;
  invitedById?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isAdmin?: Maybe<BoolNullableFilter>;
  isNewPasswordRequired?: Maybe<BoolFilter>;
  lastName?: Maybe<StringFilter>;
  loaSignedClinicPhoneNumbers?: Maybe<ClinicPhoneNumberListRelationFilter>;
  loyaltyPointUserGrants?: Maybe<LoyaltyPointUserGrantListRelationFilter>;
  loyaltyRewardRedemptionActions?: Maybe<LoyaltyRewardRedemptionHistoryEntryListRelationFilter>;
  manuallySentAutomationRuns?: Maybe<AutomationRunListRelationFilter>;
  massTextAlerts?: Maybe<MassTextAlertListRelationFilter>;
  media?: Maybe<MediaWhereInput>;
  mediaId?: Maybe<StringNullableFilter>;
  messageTemplates?: Maybe<MessageTemplateListRelationFilter>;
  nameDisplay?: Maybe<StringNullableFilter>;
  namePrefix?: Maybe<StringNullableFilter>;
  nameSuffix?: Maybe<StringNullableFilter>;
  ownerInfo?: Maybe<OwnerInfoWhereInput>;
  ownerInfoId?: Maybe<StringNullableFilter>;
  phone?: Maybe<StringNullableFilter>;
  pinnedChannels?: Maybe<ChannelPinListRelationFilter>;
  pointOfContactEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  profilePic?: Maybe<StringNullableFilter>;
  refundsIssued?: Maybe<StripeRefundListRelationFilter>;
  requestedEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  responderEnrollmentSupportEvents?: Maybe<EnrollmentSupportEventListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  sentInvoices?: Maybe<InvoiceListRelationFilter>;
  sentStripePaymentIntents?: Maybe<StripePaymentIntentListRelationFilter>;
  sentTrupanionExamDayOffers?: Maybe<SentTrupanionExamDayOfferListRelationFilter>;
  signUpDate?: Maybe<DateTimeNullableFilter>;
  triggeredWritebacks?: Maybe<PimsWritebackLogEntryListRelationFilter>;
  type?: Maybe<EnumUserTypeFilter>;
  unblockedNumbers?: Maybe<ClinicBlockedPhoneNumberListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usagesRecorded?: Maybe<CareBenefitUsageListRelationFilter>;
  userAppointmentGroupings?: Maybe<UserAppointmentGroupingListRelationFilter>;
  userChannelFilterSelections?: Maybe<UserChannelFilterSelectionListRelationFilter>;
  userSetting?: Maybe<UserSettingWhereInput>;
  userSettingId?: Maybe<StringNullableFilter>;
  validatedCanonicalServices?: Maybe<CanonicalServiceListRelationFilter>;
  vetInfo?: Maybe<VetInfoWhereInput>;
  vetInfoId?: Maybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitationCode?: Maybe<Scalars['String']>;
  ownerInfoId?: Maybe<Scalars['String']>;
  userSettingId?: Maybe<Scalars['String']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  clinicPet: ClinicPet;
  clinicPetId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCountAggregateOutputType = {
  __typename?: 'VaccinationCountAggregateOutputType';
  _all: Scalars['Int'];
  administeredAt: Scalars['Int'];
  clinicId: Scalars['Int'];
  clinicPetId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  expiredAt: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isDeleted: Scalars['Int'];
  manufacturer: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  serialNumber: Scalars['Int'];
  siteId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  virusType: Scalars['Int'];
};

export type VaccinationCountOrderByAggregateInput = {
  administeredAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
};

export type VaccinationCreateInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateManyClinicInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateManyClinicInputEnvelope = {
  data: Array<VaccinationCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyClinicPetInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateManyClinicPetInputEnvelope = {
  data: Array<VaccinationCreateManyClinicPetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateManyInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateManyIntegrationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateManyIntegrationInputEnvelope = {
  data: Array<VaccinationCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VaccinationCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
};

export type VaccinationCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
};

export type VaccinationCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
};

export type VaccinationCreateOrConnectWithoutClinicInput = {
  create: VaccinationUncheckedCreateWithoutClinicInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationCreateOrConnectWithoutClinicPetInput = {
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationCreateOrConnectWithoutIntegrationInput = {
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationCreateWithoutClinicInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateWithoutClinicPetInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVaccinationsInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationCreateWithoutIntegrationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic: ClinicCreateNestedOneWithoutVaccinationsInput;
  clinicPet: ClinicPetCreateNestedOneWithoutVaccinationsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationListRelationFilter = {
  every?: Maybe<VaccinationWhereInput>;
  none?: Maybe<VaccinationWhereInput>;
  some?: Maybe<VaccinationWhereInput>;
};

export type VaccinationMaxAggregateOutputType = {
  __typename?: 'VaccinationMaxAggregateOutputType';
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationMaxOrderByAggregateInput = {
  administeredAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
};

export type VaccinationMinAggregateOutputType = {
  __typename?: 'VaccinationMinAggregateOutputType';
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationMinOrderByAggregateInput = {
  administeredAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
};

export type VaccinationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VaccinationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  Description = 'description',
  Id = 'id',
  IntegrationId = 'integrationId',
  Manufacturer = 'manufacturer',
  PimsId = 'pimsId',
  SerialNumber = 'serialNumber',
  SiteId = 'siteId',
  VirusType = 'virusType'
}

export type VaccinationOrderByRelevanceInput = {
  fields: Array<VaccinationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VaccinationOrderByWithAggregationInput = {
  _count?: Maybe<VaccinationCountOrderByAggregateInput>;
  _max?: Maybe<VaccinationMaxOrderByAggregateInput>;
  _min?: Maybe<VaccinationMinOrderByAggregateInput>;
  administeredAt?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
};

export type VaccinationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VaccinationOrderByRelevanceInput>;
  administeredAt?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  clinicPet?: Maybe<ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
  clinicPetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  expiredAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  manufacturer?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  serialNumber?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  virusType?: Maybe<SortOrder>;
};

export enum VaccinationScalarFieldEnum {
  AdministeredAt = 'administeredAt',
  ClinicId = 'clinicId',
  ClinicPetId = 'clinicPetId',
  CreatedAt = 'createdAt',
  Description = 'description',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsDeleted = 'isDeleted',
  Manufacturer = 'manufacturer',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  SerialNumber = 'serialNumber',
  SiteId = 'siteId',
  UpdatedAt = 'updatedAt',
  VirusType = 'virusType'
}

export type VaccinationScalarWhereInput = {
  AND?: Maybe<Array<VaccinationScalarWhereInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereInput>>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  virusType?: Maybe<StringNullableFilter>;
};

export type VaccinationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VaccinationScalarWhereWithAggregatesInput>>;
  administeredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  clinicPetId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  description?: Maybe<StringNullableWithAggregatesFilter>;
  expiredAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  manufacturer?: Maybe<StringNullableWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  serialNumber?: Maybe<StringNullableWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  virusType?: Maybe<StringNullableWithAggregatesFilter>;
};

export type VaccinationUncheckedCreateInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
};

export type VaccinationUncheckedCreateNestedManyWithoutClinicPetInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
};

export type VaccinationUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
};

export type VaccinationUncheckedCreateWithoutClinicInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedCreateWithoutClinicPetInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedCreateWithoutIntegrationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId: Scalars['String'];
  clinicPetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateManyInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type VaccinationUncheckedUpdateManyWithoutVaccinationsInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateWithoutClinicInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateWithoutClinicPetInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUncheckedUpdateWithoutIntegrationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicId?: Maybe<Scalars['String']>;
  clinicPetId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpdateInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpdateManyMutationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpdateManyWithWhereWithoutClinicInput = {
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
  where: VaccinationScalarWhereInput;
};

export type VaccinationUpdateManyWithWhereWithoutClinicPetInput = {
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
  where: VaccinationScalarWhereInput;
};

export type VaccinationUpdateManyWithWhereWithoutIntegrationInput = {
  data: VaccinationUncheckedUpdateManyWithoutVaccinationsInput;
  where: VaccinationScalarWhereInput;
};

export type VaccinationUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type VaccinationUpdateManyWithoutClinicPetNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutClinicPetInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutClinicPetInput>>;
  createMany?: Maybe<VaccinationCreateManyClinicPetInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutClinicPetInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutClinicPetInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutClinicPetInput>>;
};

export type VaccinationUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VaccinationCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VaccinationCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VaccinationCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<VaccinationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VaccinationScalarWhereInput>>;
  disconnect?: Maybe<Array<VaccinationWhereUniqueInput>>;
  set?: Maybe<Array<VaccinationWhereUniqueInput>>;
  update?: Maybe<Array<VaccinationUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VaccinationUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VaccinationUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicInput = {
  data: VaccinationUncheckedUpdateWithoutClinicInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutClinicPetInput = {
  data: VaccinationUncheckedUpdateWithoutClinicPetInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: VaccinationUncheckedUpdateWithoutIntegrationInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationUpdateWithoutClinicInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpdateWithoutClinicPetInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVaccinationsNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpdateWithoutIntegrationInput = {
  administeredAt?: Maybe<Scalars['DateTime']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVaccinationsNestedInput>;
  clinicPet?: Maybe<ClinicPetUpdateOneRequiredWithoutVaccinationsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  serialNumber?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  virusType?: Maybe<Scalars['String']>;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicInput = {
  create: VaccinationUncheckedCreateWithoutClinicInput;
  update: VaccinationUncheckedUpdateWithoutClinicInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutClinicPetInput = {
  create: VaccinationUncheckedCreateWithoutClinicPetInput;
  update: VaccinationUncheckedUpdateWithoutClinicPetInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: VaccinationUncheckedCreateWithoutIntegrationInput;
  update: VaccinationUncheckedUpdateWithoutIntegrationInput;
  where: VaccinationWhereUniqueInput;
};

export type VaccinationVaccinationsPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type VaccinationWhereInput = {
  AND?: Maybe<Array<VaccinationWhereInput>>;
  NOT?: Maybe<Array<VaccinationWhereInput>>;
  OR?: Maybe<Array<VaccinationWhereInput>>;
  administeredAt?: Maybe<DateTimeNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  clinicPet?: Maybe<ClinicPetWhereInput>;
  clinicPetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  expiredAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isDeleted?: Maybe<BoolFilter>;
  manufacturer?: Maybe<StringNullableFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  serialNumber?: Maybe<StringNullableFilter>;
  siteId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  virusType?: Maybe<StringNullableFilter>;
};

export type VaccinationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  vaccinationsPimsIdAndIntegrationId?: Maybe<VaccinationVaccinationsPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type VerificationCheckInput = {
  code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type: VerificationType;
};

export type VerificationRequestInput = {
  clinicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type: VerificationType;
};

export enum VerificationType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type VerifyClinicWidgetRequestInput = {
  clinicWidgetRequestId: Scalars['ID'];
};

export type VerifyTerminalSetupPinResponse = {
  __typename?: 'VerifyTerminalSetupPinResponse';
  clinicId: Scalars['String'];
  connectionToken: Scalars['String'];
  locationId: Scalars['String'];
};

export type VerifyWidgetAuthenticationRequestInput = {
  code: Scalars['String'];
};

export type VerifyWidgetAuthenticationRequestResponse = {
  __typename?: 'VerifyWidgetAuthenticationRequestResponse';
  success?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type VetAvailability = {
  __typename?: 'VetAvailability';
  _count: VetAvailabilityCountOutputType;
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic: Clinic;
  clinicId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  integration?: Maybe<ClinicPimsIntegration>;
  integrationId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds: Array<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  vetAvailabilityClinicRoomMaps: Array<VetAvailabilityClinicRoomMap>;
  vetAvailabilityMaps: Array<VetAvailabilityMap>;
};


export type VetAvailabilityVetAvailabilityClinicRoomMapsArgs = {
  cursor?: Maybe<VetAvailabilityClinicRoomMapWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityClinicRoomMapScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};


export type VetAvailabilityVetAvailabilityMapsArgs = {
  cursor?: Maybe<VetAvailabilityMapWhereUniqueInput>;
  distinct?: Maybe<VetAvailabilityMapScalarFieldEnum>;
  orderBy?: Maybe<VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetAvailabilityMapWhereInput>;
};

export type VetAvailabilityClinicRoomMap = {
  __typename?: 'VetAvailabilityClinicRoomMap';
  clinicRoom: ClinicRoom;
  clinicRoomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  vetAvailability: VetAvailability;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapClinicRoomIdVetAvailabilityIdCompoundUniqueInput = {
  clinicRoomId: Scalars['String'];
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCountAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicRoomId: Scalars['Int'];
  createdAt: Scalars['Int'];
  vetAvailabilityId: Scalars['Int'];
};

export type VetAvailabilityClinicRoomMapCountOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapCreateInput = {
  clinicRoom: ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapCreateManyClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope = {
  data: Array<VetAvailabilityClinicRoomMapCreateManyClinicRoomInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityClinicRoomMapCreateManyInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope = {
  data: Array<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityClinicRoomMapCreateNestedManyWithoutClinicRoomInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
};

export type VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
};

export type VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput = {
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput = {
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput = {
  clinicRoom: ClinicRoomCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapListRelationFilter = {
  every?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  none?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
  some?: Maybe<VetAvailabilityClinicRoomMapWhereInput>;
};

export type VetAvailabilityClinicRoomMapMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapMaxAggregateOutputType';
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapMaxOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapMinAggregateOutputType = {
  __typename?: 'VetAvailabilityClinicRoomMapMinAggregateOutputType';
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapMinOrderByAggregateInput = {
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityClinicRoomMapOrderByRelevanceFieldEnum {
  ClinicRoomId = 'clinicRoomId',
  VetAvailabilityId = 'vetAvailabilityId'
}

export type VetAvailabilityClinicRoomMapOrderByRelevanceInput = {
  fields: Array<VetAvailabilityClinicRoomMapOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetAvailabilityClinicRoomMapOrderByWithAggregationInput = {
  _count?: Maybe<VetAvailabilityClinicRoomMapCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityClinicRoomMapMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityClinicRoomMapMinOrderByAggregateInput>;
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityClinicRoomMapOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetAvailabilityClinicRoomMapOrderByRelevanceInput>;
  clinicRoom?: Maybe<ClinicRoomOrderByWithRelationAndSearchRelevanceInput>;
  clinicRoomId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailability?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export enum VetAvailabilityClinicRoomMapScalarFieldEnum {
  ClinicRoomId = 'clinicRoomId',
  CreatedAt = 'createdAt',
  VetAvailabilityId = 'vetAvailabilityId'
}

export type VetAvailabilityClinicRoomMapScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  clinicRoomId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
};

export type VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereWithAggregatesInput>>;
  clinicRoomId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetAvailabilityId?: Maybe<StringWithAggregatesFilter>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutClinicRoomInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
};

export type VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput = {
  clinicRoomId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutClinicRoomNestedInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput = {
  clinicRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapUpdateInput = {
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
};

export type VetAvailabilityClinicRoomMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput = {
  data: VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput;
  where: VetAvailabilityClinicRoomMapScalarWhereInput;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput = {
  data: VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityClinicRoomMapsInput;
  where: VetAvailabilityClinicRoomMapScalarWhereInput;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithoutClinicRoomNestedInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutClinicRoomInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutClinicRoomInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyClinicRoomInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutClinicRoomInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput>>;
};

export type VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput = {
  connect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityClinicRoomMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityClinicRoomMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityClinicRoomMapCreateManyVetAvailabilityInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityClinicRoomMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityClinicRoomMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityClinicRoomMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
};

export type VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutClinicRoomInput = {
  data: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapUpdateWithWhereUniqueWithoutVetAvailabilityInput = {
  data: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapUpdateWithoutClinicRoomInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
};

export type VetAvailabilityClinicRoomMapUpdateWithoutVetAvailabilityInput = {
  clinicRoom?: Maybe<ClinicRoomUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutClinicRoomInput = {
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutClinicRoomInput;
  update: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutClinicRoomInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapUpsertWithWhereUniqueWithoutVetAvailabilityInput = {
  create: VetAvailabilityClinicRoomMapUncheckedCreateWithoutVetAvailabilityInput;
  update: VetAvailabilityClinicRoomMapUncheckedUpdateWithoutVetAvailabilityInput;
  where: VetAvailabilityClinicRoomMapWhereUniqueInput;
};

export type VetAvailabilityClinicRoomMapWhereInput = {
  AND?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityClinicRoomMapWhereInput>>;
  clinicRoom?: Maybe<ClinicRoomWhereInput>;
  clinicRoomId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailability?: Maybe<VetAvailabilityWhereInput>;
  vetAvailabilityId?: Maybe<StringFilter>;
};

export type VetAvailabilityClinicRoomMapWhereUniqueInput = {
  clinicRoomIdVetAvailabilityId?: Maybe<VetAvailabilityClinicRoomMapClinicRoomIdVetAvailabilityIdCompoundUniqueInput>;
};

export type VetAvailabilityCountAggregateOutputType = {
  __typename?: 'VetAvailabilityCountAggregateOutputType';
  _all: Scalars['Int'];
  availabilityPimsType: Scalars['Int'];
  availabilityType: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['Int'];
  integrationId: Scalars['Int'];
  isActive: Scalars['Int'];
  isAvailable: Scalars['Int'];
  isRecurring: Scalars['Int'];
  pimsId: Scalars['Int'];
  rawPimsValue: Scalars['Int'];
  reason: Scalars['Int'];
  recurringRules: Scalars['Int'];
  resourceIds: Scalars['Int'];
  siteId: Scalars['Int'];
  startDate: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetAvailabilityCountOrderByAggregateInput = {
  availabilityPimsType?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetAvailabilityCountOutputType = {
  __typename?: 'VetAvailabilityCountOutputType';
  vetAvailabilityClinicRoomMaps: Scalars['Int'];
  vetAvailabilityMaps: Scalars['Int'];
};

export type VetAvailabilityCreateInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateManyClinicInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityCreateManyClinicInputEnvelope = {
  data: Array<VetAvailabilityCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateManyInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityCreateManyIntegrationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityCreateManyIntegrationInputEnvelope = {
  data: Array<VetAvailabilityCreateManyIntegrationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
};

export type VetAvailabilityCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
};

export type VetAvailabilityCreateNestedOneWithoutVetAvailabilityClinicRoomMapsInput = {
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput = {
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityCreateOrConnectWithoutClinicInput = {
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityCreateOrConnectWithoutIntegrationInput = {
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput = {
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput = {
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityCreateWithoutClinicInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutIntegrationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateWithoutVetAvailabilityMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic: ClinicCreateNestedOneWithoutVetAvailabilitiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetAvailabilitiesInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityCreateresourceIdsInput = {
  set: Array<Scalars['String']>;
};

export type VetAvailabilityListRelationFilter = {
  every?: Maybe<VetAvailabilityWhereInput>;
  none?: Maybe<VetAvailabilityWhereInput>;
  some?: Maybe<VetAvailabilityWhereInput>;
};

export type VetAvailabilityMap = {
  __typename?: 'VetAvailabilityMap';
  clinicEmployee: ClinicEmployee;
  clinicEmployeeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  vetAvailability: VetAvailability;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput = {
  clinicEmployeeId: Scalars['String'];
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCountAggregateOutputType = {
  __typename?: 'VetAvailabilityMapCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicEmployeeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  vetAvailabilityId: Scalars['Int'];
};

export type VetAvailabilityMapCountOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapCreateInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateManyClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyClinicEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateManyInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapCreateManyVetAvailabilityInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope = {
  data: Array<VetAvailabilityMapCreateManyVetAvailabilityInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetAvailabilityMapCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
};

export type VetAvailabilityMapCreateNestedManyWithoutVetAvailabilityInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
};

export type VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput = {
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput = {
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability: VetAvailabilityCreateNestedOneWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityMapCreateWithoutVetAvailabilityInput = {
  clinicEmployee: ClinicEmployeeCreateNestedOneWithoutVetAvailabilityMapsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapListRelationFilter = {
  every?: Maybe<VetAvailabilityMapWhereInput>;
  none?: Maybe<VetAvailabilityMapWhereInput>;
  some?: Maybe<VetAvailabilityMapWhereInput>;
};

export type VetAvailabilityMapMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMaxAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMaxOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMapMinAggregateOutputType';
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapMinOrderByAggregateInput = {
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityMapOrderByRelevanceFieldEnum {
  ClinicEmployeeId = 'clinicEmployeeId',
  VetAvailabilityId = 'vetAvailabilityId'
}

export type VetAvailabilityMapOrderByRelevanceInput = {
  fields: Array<VetAvailabilityMapOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetAvailabilityMapOrderByWithAggregationInput = {
  _count?: Maybe<VetAvailabilityMapCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMapMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMapMinOrderByAggregateInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export type VetAvailabilityMapOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetAvailabilityMapOrderByRelevanceInput>;
  clinicEmployee?: Maybe<ClinicEmployeeOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployeeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  vetAvailability?: Maybe<VetAvailabilityOrderByWithRelationAndSearchRelevanceInput>;
  vetAvailabilityId?: Maybe<SortOrder>;
};

export enum VetAvailabilityMapScalarFieldEnum {
  ClinicEmployeeId = 'clinicEmployeeId',
  CreatedAt = 'createdAt',
  VetAvailabilityId = 'vetAvailabilityId'
}

export type VetAvailabilityMapScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailabilityId?: Maybe<StringFilter>;
};

export type VetAvailabilityMapScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityMapScalarWhereWithAggregatesInput>>;
  clinicEmployeeId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetAvailabilityId?: Maybe<StringWithAggregatesFilter>;
};

export type VetAvailabilityMapUncheckedCreateInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutClinicEmployeeInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
};

export type VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
};

export type VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId: Scalars['String'];
};

export type VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput = {
  clinicEmployeeId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUncheckedUpdateInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityId?: Maybe<Scalars['String']>;
};

export type VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput = {
  clinicEmployeeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUpdateInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
};

export type VetAvailabilityMapUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput = {
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
  where: VetAvailabilityMapScalarWhereInput;
};

export type VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput = {
  data: VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityMapsInput;
  where: VetAvailabilityMapScalarWhereInput;
};

export type VetAvailabilityMapUpdateManyWithoutClinicEmployeeNestedInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutClinicEmployeeInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutClinicEmployeeInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyClinicEmployeeInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutClinicEmployeeInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput>>;
};

export type VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput = {
  connect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityMapCreateOrConnectWithoutVetAvailabilityInput>>;
  create?: Maybe<Array<VetAvailabilityMapCreateWithoutVetAvailabilityInput>>;
  createMany?: Maybe<VetAvailabilityMapCreateManyVetAvailabilityInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityMapScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityMapWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput>>;
  updateMany?: Maybe<Array<VetAvailabilityMapUpdateManyWithWhereWithoutVetAvailabilityInput>>;
  upsert?: Maybe<Array<VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput>>;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutClinicEmployeeInput = {
  data: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapUpdateWithWhereUniqueWithoutVetAvailabilityInput = {
  data: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapUpdateWithoutClinicEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  vetAvailability?: Maybe<VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
};

export type VetAvailabilityMapUpdateWithoutVetAvailabilityInput = {
  clinicEmployee?: Maybe<ClinicEmployeeUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutClinicEmployeeInput = {
  create: VetAvailabilityMapUncheckedCreateWithoutClinicEmployeeInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutClinicEmployeeInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapUpsertWithWhereUniqueWithoutVetAvailabilityInput = {
  create: VetAvailabilityMapUncheckedCreateWithoutVetAvailabilityInput;
  update: VetAvailabilityMapUncheckedUpdateWithoutVetAvailabilityInput;
  where: VetAvailabilityMapWhereUniqueInput;
};

export type VetAvailabilityMapWhereInput = {
  AND?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityMapWhereInput>>;
  clinicEmployee?: Maybe<ClinicEmployeeWhereInput>;
  clinicEmployeeId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  vetAvailability?: Maybe<VetAvailabilityWhereInput>;
  vetAvailabilityId?: Maybe<StringFilter>;
};

export type VetAvailabilityMapWhereUniqueInput = {
  clinicEmployeeIdVetAvailabilityId?: Maybe<VetAvailabilityMapClinicEmployeeIdVetAvailabilityIdCompoundUniqueInput>;
};

export type VetAvailabilityMaxAggregateOutputType = {
  __typename?: 'VetAvailabilityMaxAggregateOutputType';
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMaxOrderByAggregateInput = {
  availabilityPimsType?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetAvailabilityMinAggregateOutputType = {
  __typename?: 'VetAvailabilityMinAggregateOutputType';
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityMinOrderByAggregateInput = {
  availabilityPimsType?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetAvailabilityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetAvailabilityOrderByRelevanceFieldEnum {
  AvailabilityPimsType = 'availabilityPimsType',
  AvailabilityType = 'availabilityType',
  ClinicId = 'clinicId',
  Id = 'id',
  IntegrationId = 'integrationId',
  PimsId = 'pimsId',
  Reason = 'reason',
  RecurringRules = 'recurringRules',
  ResourceIds = 'resourceIds',
  SiteId = 'siteId'
}

export type VetAvailabilityOrderByRelevanceInput = {
  fields: Array<VetAvailabilityOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetAvailabilityOrderByWithAggregationInput = {
  _count?: Maybe<VetAvailabilityCountOrderByAggregateInput>;
  _max?: Maybe<VetAvailabilityMaxOrderByAggregateInput>;
  _min?: Maybe<VetAvailabilityMinOrderByAggregateInput>;
  availabilityPimsType?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetAvailabilityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetAvailabilityOrderByRelevanceInput>;
  availabilityPimsType?: Maybe<SortOrder>;
  availabilityType?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  integrationId?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  isAvailable?: Maybe<SortOrder>;
  isRecurring?: Maybe<SortOrder>;
  pimsId?: Maybe<SortOrder>;
  rawPimsValue?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  recurringRules?: Maybe<SortOrder>;
  resourceIds?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapOrderByRelationAggregateInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapOrderByRelationAggregateInput>;
};

export type VetAvailabilityRelationFilter = {
  is?: Maybe<VetAvailabilityWhereInput>;
  isNot?: Maybe<VetAvailabilityWhereInput>;
};

export enum VetAvailabilityScalarFieldEnum {
  AvailabilityPimsType = 'availabilityPimsType',
  AvailabilityType = 'availabilityType',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Id = 'id',
  IntegrationId = 'integrationId',
  IsActive = 'isActive',
  IsAvailable = 'isAvailable',
  IsRecurring = 'isRecurring',
  PimsId = 'pimsId',
  RawPimsValue = 'rawPimsValue',
  Reason = 'reason',
  RecurringRules = 'recurringRules',
  ResourceIds = 'resourceIds',
  SiteId = 'siteId',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt'
}

export type VetAvailabilityScalarWhereInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  siteId?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetAvailabilityScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetAvailabilityScalarWhereWithAggregatesInput>>;
  availabilityPimsType?: Maybe<StringNullableWithAggregatesFilter>;
  availabilityType?: Maybe<StringNullableWithAggregatesFilter>;
  clinicId?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  endDate?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  integrationId?: Maybe<StringNullableWithAggregatesFilter>;
  isActive?: Maybe<BoolWithAggregatesFilter>;
  isAvailable?: Maybe<BoolWithAggregatesFilter>;
  isRecurring?: Maybe<BoolWithAggregatesFilter>;
  pimsId?: Maybe<StringNullableWithAggregatesFilter>;
  rawPimsValue?: Maybe<JsonNullableWithAggregatesFilter>;
  reason?: Maybe<StringNullableWithAggregatesFilter>;
  recurringRules?: Maybe<StringNullableWithAggregatesFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  startDate?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetAvailabilityUncheckedCreateInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
};

export type VetAvailabilityUncheckedCreateNestedManyWithoutIntegrationInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
};

export type VetAvailabilityUncheckedCreateWithoutClinicInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutIntegrationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedCreateNestedManyWithoutVetAvailabilityInput>;
};

export type VetAvailabilityUncheckedUpdateInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateManyInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityUncheckedUpdateWithoutClinicInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutIntegrationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUncheckedUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateManyMutationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetAvailabilityUpdateManyWithWhereWithoutClinicInput = {
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
  where: VetAvailabilityScalarWhereInput;
};

export type VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput = {
  data: VetAvailabilityUncheckedUpdateManyWithoutVetAvailabilitiesInput;
  where: VetAvailabilityScalarWhereInput;
};

export type VetAvailabilityUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutClinicInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type VetAvailabilityUpdateManyWithoutIntegrationNestedInput = {
  connect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetAvailabilityCreateOrConnectWithoutIntegrationInput>>;
  create?: Maybe<Array<VetAvailabilityCreateWithoutIntegrationInput>>;
  createMany?: Maybe<VetAvailabilityCreateManyIntegrationInputEnvelope>;
  delete?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetAvailabilityScalarWhereInput>>;
  disconnect?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<VetAvailabilityWhereUniqueInput>>;
  update?: Maybe<Array<VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput>>;
  updateMany?: Maybe<Array<VetAvailabilityUpdateManyWithWhereWithoutIntegrationInput>>;
  upsert?: Maybe<Array<VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput>>;
};

export type VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityClinicRoomMapsNestedInput = {
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityClinicRoomMapsInput>;
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput>;
  update?: Maybe<VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput>;
  upsert?: Maybe<VetAvailabilityUpsertWithoutVetAvailabilityClinicRoomMapsInput>;
};

export type VetAvailabilityUpdateOneRequiredWithoutVetAvailabilityMapsNestedInput = {
  connect?: Maybe<VetAvailabilityWhereUniqueInput>;
  connectOrCreate?: Maybe<VetAvailabilityCreateOrConnectWithoutVetAvailabilityMapsInput>;
  create?: Maybe<VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput>;
  update?: Maybe<VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput>;
  upsert?: Maybe<VetAvailabilityUpsertWithoutVetAvailabilityMapsInput>;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutClinicInput = {
  data: VetAvailabilityUncheckedUpdateWithoutClinicInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityUpdateWithWhereUniqueWithoutIntegrationInput = {
  data: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityUpdateWithoutClinicInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutIntegrationInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutVetAvailabilityClinicRoomMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateWithoutVetAvailabilityMapsInput = {
  availabilityPimsType?: Maybe<Scalars['String']>;
  availabilityType?: Maybe<Scalars['String']>;
  clinic?: Maybe<ClinicUpdateOneRequiredWithoutVetAvailabilitiesNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetAvailabilitiesNestedInput>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  pimsId?: Maybe<Scalars['String']>;
  rawPimsValue?: Maybe<Scalars['Json']>;
  reason?: Maybe<Scalars['String']>;
  recurringRules?: Maybe<Scalars['String']>;
  resourceIds?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapUpdateManyWithoutVetAvailabilityNestedInput>;
};

export type VetAvailabilityUpdateresourceIdsInput = {
  push?: Maybe<Array<Scalars['String']>>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutClinicInput = {
  create: VetAvailabilityUncheckedCreateWithoutClinicInput;
  update: VetAvailabilityUncheckedUpdateWithoutClinicInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityUpsertWithWhereUniqueWithoutIntegrationInput = {
  create: VetAvailabilityUncheckedCreateWithoutIntegrationInput;
  update: VetAvailabilityUncheckedUpdateWithoutIntegrationInput;
  where: VetAvailabilityWhereUniqueInput;
};

export type VetAvailabilityUpsertWithoutVetAvailabilityClinicRoomMapsInput = {
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityClinicRoomMapsInput;
  update: VetAvailabilityUncheckedUpdateWithoutVetAvailabilityClinicRoomMapsInput;
};

export type VetAvailabilityUpsertWithoutVetAvailabilityMapsInput = {
  create: VetAvailabilityUncheckedCreateWithoutVetAvailabilityMapsInput;
  update: VetAvailabilityUncheckedUpdateWithoutVetAvailabilityMapsInput;
};

export type VetAvailabilityVetAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput = {
  integrationId: Scalars['String'];
  pimsId: Scalars['String'];
};

export type VetAvailabilityWhereInput = {
  AND?: Maybe<Array<VetAvailabilityWhereInput>>;
  NOT?: Maybe<Array<VetAvailabilityWhereInput>>;
  OR?: Maybe<Array<VetAvailabilityWhereInput>>;
  availabilityPimsType?: Maybe<StringNullableFilter>;
  availabilityType?: Maybe<StringNullableFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  integration?: Maybe<ClinicPimsIntegrationWhereInput>;
  integrationId?: Maybe<StringNullableFilter>;
  isActive?: Maybe<BoolFilter>;
  isAvailable?: Maybe<BoolFilter>;
  isRecurring?: Maybe<BoolFilter>;
  pimsId?: Maybe<StringNullableFilter>;
  rawPimsValue?: Maybe<JsonNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  recurringRules?: Maybe<StringNullableFilter>;
  resourceIds?: Maybe<StringNullableListFilter>;
  siteId?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetAvailabilityClinicRoomMaps?: Maybe<VetAvailabilityClinicRoomMapListRelationFilter>;
  vetAvailabilityMaps?: Maybe<VetAvailabilityMapListRelationFilter>;
};

export type VetAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  vetAvailabilityPimsIdAndIntegrationId?: Maybe<VetAvailabilityVetAvailabilityPimsIdAndIntegrationIdCompoundUniqueInput>;
};

export type VetDataIntegration = {
  __typename?: 'VetDataIntegration';
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  defaultProviderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite: Scalars['Boolean'];
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt: Scalars['DateTime'];
};

export type VetDataIntegrationCountAggregateOutputType = {
  __typename?: 'VetDataIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  defaultProviderId: Scalars['Int'];
  id: Scalars['Int'];
  installationId: Scalars['Int'];
  invoiceCodeId: Scalars['Int'];
  isMultiSite: Scalars['Int'];
  siteId: Scalars['Int'];
  system: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetDataIntegrationCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetDataIntegrationCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetdataInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: VetDataIntegrationWhereUniqueInput;
};

export type VetDataIntegrationCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationMaxAggregateOutputType = {
  __typename?: 'VetDataIntegrationMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetDataIntegrationMinAggregateOutputType = {
  __typename?: 'VetDataIntegrationMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VetDataIntegrationOrderByRelevanceFieldEnum {
  DefaultProviderId = 'defaultProviderId',
  Id = 'id',
  InstallationId = 'installationId',
  InvoiceCodeId = 'invoiceCodeId',
  SiteId = 'siteId'
}

export type VetDataIntegrationOrderByRelevanceInput = {
  fields: Array<VetDataIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetDataIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<VetDataIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetDataIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetDataIntegrationMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetDataIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetDataIntegrationOrderByRelevanceInput>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  defaultProviderId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  installationId?: Maybe<SortOrder>;
  invoiceCodeId?: Maybe<SortOrder>;
  isMultiSite?: Maybe<SortOrder>;
  siteId?: Maybe<SortOrder>;
  system?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetDataIntegrationRelationFilter = {
  is?: Maybe<VetDataIntegrationWhereInput>;
  isNot?: Maybe<VetDataIntegrationWhereInput>;
};

export enum VetDataIntegrationScalarFieldEnum {
  CreatedAt = 'createdAt',
  DefaultProviderId = 'defaultProviderId',
  Id = 'id',
  InstallationId = 'installationId',
  InvoiceCodeId = 'invoiceCodeId',
  IsMultiSite = 'isMultiSite',
  SiteId = 'siteId',
  System = 'system',
  UpdatedAt = 'updatedAt'
}

export type VetDataIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetDataIntegrationScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  defaultProviderId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  installationId?: Maybe<StringWithAggregatesFilter>;
  invoiceCodeId?: Maybe<StringNullableWithAggregatesFilter>;
  isMultiSite?: Maybe<BoolWithAggregatesFilter>;
  siteId?: Maybe<StringNullableWithAggregatesFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export enum VetDataIntegrationSystem {
  Advantage = 'ADVANTAGE',
  Avimark = 'AVIMARK',
  Clientrax = 'CLIENTRAX',
  Cornerstone = 'CORNERSTONE',
  DvmaxSql = 'DVMAX_SQL',
  DvmManager = 'DVM_MANAGER',
  Evetpractice = 'EVETPRACTICE',
  HippoManager = 'HIPPO_MANAGER',
  Hvms = 'HVMS',
  Impromed = 'IMPROMED',
  ImpromedTriplecrown = 'IMPROMED_TRIPLECROWN',
  Intravet = 'INTRAVET',
  Stringsoft = 'STRINGSOFT',
  Via = 'VIA',
  Vtp = 'VTP'
}

export type VetDataIntegrationUncheckedCreateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetdataInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId: Scalars['String'];
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system: VetDataIntegrationSystem;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUncheckedUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetdataNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUpdateInput = {
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetdataNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<VetDataIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetDataIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type VetDataIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProviderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installationId?: Maybe<Scalars['String']>;
  invoiceCodeId?: Maybe<Scalars['String']>;
  isMultiSite?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['String']>;
  system?: Maybe<VetDataIntegrationSystem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetDataIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: VetDataIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: VetDataIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type VetDataIntegrationWhereInput = {
  AND?: Maybe<Array<VetDataIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetDataIntegrationWhereInput>>;
  OR?: Maybe<Array<VetDataIntegrationWhereInput>>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultProviderId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  installationId?: Maybe<StringFilter>;
  invoiceCodeId?: Maybe<StringNullableFilter>;
  isMultiSite?: Maybe<BoolFilter>;
  siteId?: Maybe<StringNullableFilter>;
  system?: Maybe<EnumVetDataIntegrationSystemFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetDataIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetInfo = {
  __typename?: 'VetInfo';
  _count: VetInfoCountOutputType;
  clinics: Array<Clinic>;
  consultations: Array<Consultation>;
  consultationsId: Array<Consultation>;
  createdAt: Scalars['DateTime'];
  currentClinic?: Maybe<Clinic>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  roles: Array<StaffRole>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  vetLicenses: Array<VetLicense>;
};


export type VetInfoClinicsArgs = {
  cursor?: Maybe<ClinicWhereUniqueInput>;
  distinct?: Maybe<ClinicScalarFieldEnum>;
  orderBy?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWhereInput>;
};


export type VetInfoConsultationsArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type VetInfoConsultationsIdArgs = {
  cursor?: Maybe<ConsultationWhereUniqueInput>;
  distinct?: Maybe<ConsultationScalarFieldEnum>;
  orderBy?: Maybe<ConsultationOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ConsultationWhereInput>;
};


export type VetInfoRolesArgs = {
  cursor?: Maybe<StaffRoleWhereUniqueInput>;
  distinct?: Maybe<StaffRoleScalarFieldEnum>;
  orderBy?: Maybe<StaffRoleOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StaffRoleWhereInput>;
};


export type VetInfoVetLicensesArgs = {
  cursor?: Maybe<VetLicenseWhereUniqueInput>;
  distinct?: Maybe<VetLicenseScalarFieldEnum>;
  orderBy?: Maybe<VetLicenseOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetLicenseWhereInput>;
};

export type VetInfoCountAggregateOutputType = {
  __typename?: 'VetInfoCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  currentClinicId: Scalars['Int'];
  firebaseId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetInfoCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoCountOutputType = {
  __typename?: 'VetInfoCountOutputType';
  clinics: Scalars['Int'];
  consultations: Scalars['Int'];
  consultationsId: Scalars['Int'];
  roles: Scalars['Int'];
  vetLicenses: Scalars['Int'];
};

export type VetInfoCreateInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateManyCurrentClinicInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateManyCurrentClinicInputEnvelope = {
  data: Array<VetInfoCreateManyCurrentClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetInfoCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
};

export type VetInfoCreateNestedManyWithoutCurrentClinicInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
};

export type VetInfoCreateNestedManyWithoutRolesInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
};

export type VetInfoCreateNestedOneWithoutConsultationsIdInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
};

export type VetInfoCreateNestedOneWithoutConsultationsInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
};

export type VetInfoCreateNestedOneWithoutUserInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
};

export type VetInfoCreateNestedOneWithoutVetLicensesInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
};

export type VetInfoCreateOrConnectWithoutClinicsInput = {
  create: VetInfoUncheckedCreateWithoutClinicsInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsIdInput = {
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutConsultationsInput = {
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutCurrentClinicInput = {
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutRolesInput = {
  create: VetInfoUncheckedCreateWithoutRolesInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutUserInput = {
  create: VetInfoUncheckedCreateWithoutUserInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateOrConnectWithoutVetLicensesInput = {
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoCreateWithoutClinicsInput = {
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutConsultationsIdInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutConsultationsInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutCurrentClinicInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutRolesInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutUserInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetLicenses?: Maybe<VetLicenseCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoCreateWithoutVetLicensesInput = {
  clinics?: Maybe<ClinicCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutVetInfoInput>;
};

export type VetInfoListRelationFilter = {
  every?: Maybe<VetInfoWhereInput>;
  none?: Maybe<VetInfoWhereInput>;
  some?: Maybe<VetInfoWhereInput>;
};

export type VetInfoMaxAggregateOutputType = {
  __typename?: 'VetInfoMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoMinAggregateOutputType = {
  __typename?: 'VetInfoMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetInfoOrderByRelevanceFieldEnum {
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId',
  Id = 'id'
}

export type VetInfoOrderByRelevanceInput = {
  fields: Array<VetInfoOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetInfoOrderByWithAggregationInput = {
  _count?: Maybe<VetInfoCountOrderByAggregateInput>;
  _max?: Maybe<VetInfoMaxOrderByAggregateInput>;
  _min?: Maybe<VetInfoMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetInfoOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetInfoOrderByRelevanceInput>;
  clinics?: Maybe<ClinicOrderByRelationAggregateInput>;
  consultations?: Maybe<ConsultationOrderByRelationAggregateInput>;
  consultationsId?: Maybe<ConsultationOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  currentClinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  currentClinicId?: Maybe<SortOrder>;
  firebaseId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  roles?: Maybe<StaffRoleOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  vetLicenses?: Maybe<VetLicenseOrderByRelationAggregateInput>;
};

export type VetInfoRelationFilter = {
  is?: Maybe<VetInfoWhereInput>;
  isNot?: Maybe<VetInfoWhereInput>;
};

export enum VetInfoScalarFieldEnum {
  CreatedAt = 'createdAt',
  CurrentClinicId = 'currentClinicId',
  FirebaseId = 'firebaseId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type VetInfoScalarWhereInput = {
  AND?: Maybe<Array<VetInfoScalarWhereInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetInfoScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetInfoScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  currentClinicId?: Maybe<StringNullableWithAggregatesFilter>;
  firebaseId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetInfoUncheckedCreateInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateNestedManyWithoutClinicsInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
};

export type VetInfoUncheckedCreateNestedManyWithoutCurrentClinicInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
};

export type VetInfoUncheckedCreateNestedManyWithoutRolesInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
};

export type VetInfoUncheckedCreateWithoutClinicsInput = {
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsIdInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutConsultationsInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutCurrentClinicInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutRolesInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutUserInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicCreateNestedOneWithoutStaffIdInput>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetLicenses?: Maybe<VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput>;
};

export type VetInfoUncheckedCreateWithoutVetLicensesInput = {
  clinics?: Maybe<ClinicUncheckedCreateNestedManyWithoutStaffInput>;
  consultations?: Maybe<ConsultationUncheckedCreateNestedManyWithoutVetInput>;
  consultationsId?: Maybe<ConsultationUncheckedCreateNestedManyWithoutNurseInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedCreateNestedManyWithoutUsersInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedCreateNestedOneWithoutVetInfoInput>;
};

export type VetInfoUncheckedUpdateInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutCurrentClinicNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutRolesNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffIdInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutStaffInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateManyWithoutUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUncheckedUpdateWithoutClinicsInput = {
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsIdInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutConsultationsInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutCurrentClinicInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutRolesInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutUserInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateWithoutStaffIdInput>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetLicenses?: Maybe<VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUncheckedUpdateWithoutVetLicensesInput = {
  clinics?: Maybe<ClinicUncheckedUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUncheckedUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUncheckedUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinicId?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUncheckedUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUncheckedUpdateOneWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetInfoUpdateManyWithWhereWithoutClinicsInput = {
  data: VetInfoUncheckedUpdateManyWithoutStaffInput;
  where: VetInfoScalarWhereInput;
};

export type VetInfoUpdateManyWithWhereWithoutCurrentClinicInput = {
  data: VetInfoUncheckedUpdateManyWithoutStaffIdInput;
  where: VetInfoScalarWhereInput;
};

export type VetInfoUpdateManyWithWhereWithoutRolesInput = {
  data: VetInfoUncheckedUpdateManyWithoutUsersInput;
  where: VetInfoScalarWhereInput;
};

export type VetInfoUpdateManyWithoutClinicsNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutClinicsInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutClinicsInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutClinicsInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutClinicsInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutClinicsInput>>;
};

export type VetInfoUpdateManyWithoutCurrentClinicNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutCurrentClinicInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutCurrentClinicInput>>;
  createMany?: Maybe<VetInfoCreateManyCurrentClinicInputEnvelope>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutCurrentClinicInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput>>;
};

export type VetInfoUpdateManyWithoutRolesNestedInput = {
  connect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetInfoCreateOrConnectWithoutRolesInput>>;
  create?: Maybe<Array<VetInfoCreateWithoutRolesInput>>;
  delete?: Maybe<Array<VetInfoWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetInfoScalarWhereInput>>;
  disconnect?: Maybe<Array<VetInfoWhereUniqueInput>>;
  set?: Maybe<Array<VetInfoWhereUniqueInput>>;
  update?: Maybe<Array<VetInfoUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: Maybe<Array<VetInfoUpdateManyWithWhereWithoutRolesInput>>;
  upsert?: Maybe<Array<VetInfoUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type VetInfoUpdateOneWithoutConsultationsIdNestedInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsIdInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsIdInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsIdInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsIdInput>;
};

export type VetInfoUpdateOneWithoutConsultationsNestedInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutConsultationsInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutConsultationsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutConsultationsInput>;
  upsert?: Maybe<VetInfoUpsertWithoutConsultationsInput>;
};

export type VetInfoUpdateOneWithoutUserNestedInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutUserInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutUserInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutUserInput>;
  upsert?: Maybe<VetInfoUpsertWithoutUserInput>;
};

export type VetInfoUpdateOneWithoutVetLicensesNestedInput = {
  connect?: Maybe<VetInfoWhereUniqueInput>;
  connectOrCreate?: Maybe<VetInfoCreateOrConnectWithoutVetLicensesInput>;
  create?: Maybe<VetInfoUncheckedCreateWithoutVetLicensesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetInfoUncheckedUpdateWithoutVetLicensesInput>;
  upsert?: Maybe<VetInfoUpsertWithoutVetLicensesInput>;
};

export type VetInfoUpdateWithWhereUniqueWithoutClinicsInput = {
  data: VetInfoUncheckedUpdateWithoutClinicsInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutCurrentClinicInput = {
  data: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpdateWithWhereUniqueWithoutRolesInput = {
  data: VetInfoUncheckedUpdateWithoutRolesInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpdateWithoutClinicsInput = {
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutConsultationsIdInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutConsultationsInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutCurrentClinicInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutRolesInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutUserInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetLicenses?: Maybe<VetLicenseUpdateManyWithoutVetInfoNestedInput>;
};

export type VetInfoUpdateWithoutVetLicensesInput = {
  clinics?: Maybe<ClinicUpdateManyWithoutStaffNestedInput>;
  consultations?: Maybe<ConsultationUpdateManyWithoutVetNestedInput>;
  consultationsId?: Maybe<ConsultationUpdateManyWithoutNurseNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentClinic?: Maybe<ClinicUpdateOneWithoutStaffIdNestedInput>;
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  roles?: Maybe<StaffRoleUpdateManyWithoutUsersNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserUpdateOneWithoutVetInfoNestedInput>;
};

export type VetInfoUpsertWithWhereUniqueWithoutClinicsInput = {
  create: VetInfoUncheckedCreateWithoutClinicsInput;
  update: VetInfoUncheckedUpdateWithoutClinicsInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutCurrentClinicInput = {
  create: VetInfoUncheckedCreateWithoutCurrentClinicInput;
  update: VetInfoUncheckedUpdateWithoutCurrentClinicInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpsertWithWhereUniqueWithoutRolesInput = {
  create: VetInfoUncheckedCreateWithoutRolesInput;
  update: VetInfoUncheckedUpdateWithoutRolesInput;
  where: VetInfoWhereUniqueInput;
};

export type VetInfoUpsertWithoutConsultationsIdInput = {
  create: VetInfoUncheckedCreateWithoutConsultationsIdInput;
  update: VetInfoUncheckedUpdateWithoutConsultationsIdInput;
};

export type VetInfoUpsertWithoutConsultationsInput = {
  create: VetInfoUncheckedCreateWithoutConsultationsInput;
  update: VetInfoUncheckedUpdateWithoutConsultationsInput;
};

export type VetInfoUpsertWithoutUserInput = {
  create: VetInfoUncheckedCreateWithoutUserInput;
  update: VetInfoUncheckedUpdateWithoutUserInput;
};

export type VetInfoUpsertWithoutVetLicensesInput = {
  create: VetInfoUncheckedCreateWithoutVetLicensesInput;
  update: VetInfoUncheckedUpdateWithoutVetLicensesInput;
};

export type VetInfoWhereInput = {
  AND?: Maybe<Array<VetInfoWhereInput>>;
  NOT?: Maybe<Array<VetInfoWhereInput>>;
  OR?: Maybe<Array<VetInfoWhereInput>>;
  clinics?: Maybe<ClinicListRelationFilter>;
  consultations?: Maybe<ConsultationListRelationFilter>;
  consultationsId?: Maybe<ConsultationListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currentClinic?: Maybe<ClinicWhereInput>;
  currentClinicId?: Maybe<StringNullableFilter>;
  firebaseId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  roles?: Maybe<StaffRoleListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserWhereInput>;
  vetLicenses?: Maybe<VetLicenseListRelationFilter>;
};

export type VetInfoWhereUniqueInput = {
  firebaseId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type VetLicense = {
  __typename?: 'VetLicense';
  createdAt: Scalars['DateTime'];
  expirationDate: Scalars['DateTime'];
  id: Scalars['String'];
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt: Scalars['DateTime'];
  vetInfo?: Maybe<VetInfo>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseCountAggregateOutputType = {
  __typename?: 'VetLicenseCountAggregateOutputType';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  expirationDate: Scalars['Int'];
  id: Scalars['Int'];
  issuingAuthority: Scalars['Int'];
  licenseNumber: Scalars['Int'];
  licenseType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  vetInfoId: Scalars['Int'];
};

export type VetLicenseCountOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type VetLicenseCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfo?: Maybe<VetInfoCreateNestedOneWithoutVetLicensesInput>;
};

export type VetLicenseCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseCreateManyVetInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseCreateManyVetInfoInputEnvelope = {
  data: Array<VetLicenseCreateManyVetInfoInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetLicenseCreateNestedManyWithoutVetInfoInput = {
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
};

export type VetLicenseCreateOrConnectWithoutVetInfoInput = {
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
  where: VetLicenseWhereUniqueInput;
};

export type VetLicenseCreateWithoutVetInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseListRelationFilter = {
  every?: Maybe<VetLicenseWhereInput>;
  none?: Maybe<VetLicenseWhereInput>;
  some?: Maybe<VetLicenseWhereInput>;
};

export type VetLicenseMaxAggregateOutputType = {
  __typename?: 'VetLicenseMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseMaxOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type VetLicenseMinAggregateOutputType = {
  __typename?: 'VetLicenseMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseMinOrderByAggregateInput = {
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type VetLicenseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetLicenseOrderByRelevanceFieldEnum {
  Id = 'id',
  LicenseNumber = 'licenseNumber',
  VetInfoId = 'vetInfoId'
}

export type VetLicenseOrderByRelevanceInput = {
  fields: Array<VetLicenseOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetLicenseOrderByWithAggregationInput = {
  _count?: Maybe<VetLicenseCountOrderByAggregateInput>;
  _max?: Maybe<VetLicenseMaxOrderByAggregateInput>;
  _min?: Maybe<VetLicenseMinOrderByAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetInfoId?: Maybe<SortOrder>;
};

export type VetLicenseOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetLicenseOrderByRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  issuingAuthority?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vetInfo?: Maybe<VetInfoOrderByWithRelationAndSearchRelevanceInput>;
  vetInfoId?: Maybe<SortOrder>;
};

export enum VetLicenseScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  Id = 'id',
  IssuingAuthority = 'issuingAuthority',
  LicenseNumber = 'licenseNumber',
  LicenseType = 'licenseType',
  UpdatedAt = 'updatedAt',
  VetInfoId = 'vetInfoId'
}

export type VetLicenseScalarWhereInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  licenseNumber?: Maybe<StringFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetInfoId?: Maybe<StringNullableFilter>;
};

export type VetLicenseScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetLicenseScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  expirationDate?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  issuingAuthority?: Maybe<EnumStateWithAggregatesFilter>;
  licenseNumber?: Maybe<StringWithAggregatesFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  vetInfoId?: Maybe<StringNullableWithAggregatesFilter>;
};

export enum VetLicenseType {
  Dea = 'DEA',
  Technician = 'Technician',
  Veterinary = 'Veterinary'
}

export type VetLicenseUncheckedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseUncheckedCreateNestedManyWithoutVetInfoInput = {
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
};

export type VetLicenseUncheckedCreateWithoutVetInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  issuingAuthority: State;
  licenseNumber: Scalars['String'];
  licenseType: VetLicenseType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseUncheckedUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseUncheckedUpdateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfoId?: Maybe<Scalars['String']>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetInfoNestedInput = {
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
};

export type VetLicenseUncheckedUpdateManyWithoutVetLicensesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseUncheckedUpdateWithoutVetInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vetInfo?: Maybe<VetInfoUpdateOneWithoutVetLicensesNestedInput>;
};

export type VetLicenseUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseUpdateManyWithWhereWithoutVetInfoInput = {
  data: VetLicenseUncheckedUpdateManyWithoutVetLicensesInput;
  where: VetLicenseScalarWhereInput;
};

export type VetLicenseUpdateManyWithoutVetInfoNestedInput = {
  connect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetLicenseCreateOrConnectWithoutVetInfoInput>>;
  create?: Maybe<Array<VetLicenseCreateWithoutVetInfoInput>>;
  createMany?: Maybe<VetLicenseCreateManyVetInfoInputEnvelope>;
  delete?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetLicenseScalarWhereInput>>;
  disconnect?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  set?: Maybe<Array<VetLicenseWhereUniqueInput>>;
  update?: Maybe<Array<VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput>>;
  updateMany?: Maybe<Array<VetLicenseUpdateManyWithWhereWithoutVetInfoInput>>;
  upsert?: Maybe<Array<VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput>>;
};

export type VetLicenseUpdateWithWhereUniqueWithoutVetInfoInput = {
  data: VetLicenseUncheckedUpdateWithoutVetInfoInput;
  where: VetLicenseWhereUniqueInput;
};

export type VetLicenseUpdateWithoutVetInfoInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  issuingAuthority?: Maybe<State>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseType?: Maybe<VetLicenseType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetLicenseUpsertWithWhereUniqueWithoutVetInfoInput = {
  create: VetLicenseUncheckedCreateWithoutVetInfoInput;
  update: VetLicenseUncheckedUpdateWithoutVetInfoInput;
  where: VetLicenseWhereUniqueInput;
};

export type VetLicenseWhereInput = {
  AND?: Maybe<Array<VetLicenseWhereInput>>;
  NOT?: Maybe<Array<VetLicenseWhereInput>>;
  OR?: Maybe<Array<VetLicenseWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  issuingAuthority?: Maybe<EnumStateFilter>;
  licenseNumber?: Maybe<StringFilter>;
  licenseType?: Maybe<EnumVetLicenseTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vetInfo?: Maybe<VetInfoWhereInput>;
  vetInfoId?: Maybe<StringNullableFilter>;
};

export type VetLicenseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VetsourceCodeTag = {
  __typename?: 'VetsourceCodeTag';
  Service: Array<Service>;
  _count: VetsourceCodeTagCountOutputType;
  externalId: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};


export type VetsourceCodeTagServiceArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};

export type VetsourceCodeTagAvgAggregateOutputType = {
  __typename?: 'VetsourceCodeTagAvgAggregateOutputType';
  externalId?: Maybe<Scalars['Float']>;
};

export type VetsourceCodeTagAvgOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
};

export type VetsourceCodeTagCountAggregateOutputType = {
  __typename?: 'VetsourceCodeTagCountAggregateOutputType';
  _all: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  type: Scalars['Int'];
};

export type VetsourceCodeTagCountOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagCountOutputType = {
  __typename?: 'VetsourceCodeTagCountOutputType';
  Service: Scalars['Int'];
};

export type VetsourceCodeTagCreateInput = {
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceCodeTagsInput>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagCreateManyInput = {
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
};

export type VetsourceCodeTagCreateOrConnectWithoutServiceInput = {
  create: VetsourceCodeTagUncheckedCreateWithoutServiceInput;
  where: VetsourceCodeTagWhereUniqueInput;
};

export type VetsourceCodeTagCreateWithoutServiceInput = {
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagListRelationFilter = {
  every?: Maybe<VetsourceCodeTagWhereInput>;
  none?: Maybe<VetsourceCodeTagWhereInput>;
  some?: Maybe<VetsourceCodeTagWhereInput>;
};

export type VetsourceCodeTagMaxAggregateOutputType = {
  __typename?: 'VetsourceCodeTagMaxAggregateOutputType';
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagMaxOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagMinAggregateOutputType = {
  __typename?: 'VetsourceCodeTagMinAggregateOutputType';
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagMinOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetsourceCodeTagOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type VetsourceCodeTagOrderByRelevanceInput = {
  fields: Array<VetsourceCodeTagOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetsourceCodeTagOrderByWithAggregationInput = {
  _avg?: Maybe<VetsourceCodeTagAvgOrderByAggregateInput>;
  _count?: Maybe<VetsourceCodeTagCountOrderByAggregateInput>;
  _max?: Maybe<VetsourceCodeTagMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceCodeTagMinOrderByAggregateInput>;
  _sum?: Maybe<VetsourceCodeTagSumOrderByAggregateInput>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type VetsourceCodeTagOrderByWithRelationAndSearchRelevanceInput = {
  Service?: Maybe<ServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetsourceCodeTagOrderByRelevanceInput>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export enum VetsourceCodeTagScalarFieldEnum {
  ExternalId = 'externalId',
  Id = 'id',
  Name = 'name',
  Type = 'type'
}

export type VetsourceCodeTagScalarWhereInput = {
  AND?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  OR?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  externalId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeFilter>;
};

export type VetsourceCodeTagScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceCodeTagScalarWhereWithAggregatesInput>>;
  externalId?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeWithAggregatesFilter>;
};

export type VetsourceCodeTagSumAggregateOutputType = {
  __typename?: 'VetsourceCodeTagSumAggregateOutputType';
  externalId?: Maybe<Scalars['Int']>;
};

export type VetsourceCodeTagSumOrderByAggregateInput = {
  externalId?: Maybe<SortOrder>;
};

export enum VetsourceCodeTagType {
  Dental = 'Dental',
  Diagnostic = 'Diagnostic',
  Diet = 'Diet',
  Vaccine = 'Vaccine',
  WellnessPlan = 'WellnessPlan'
}

export type VetsourceCodeTagUncheckedCreateInput = {
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceCodeTagsInput>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
};

export type VetsourceCodeTagUncheckedCreateWithoutServiceInput = {
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: VetsourceCodeTagType;
};

export type VetsourceCodeTagUncheckedUpdateInput = {
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceCodeTagsNestedInput>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUncheckedUpdateManyInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  delete?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  disconnect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  set?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type VetsourceCodeTagUncheckedUpdateManyWithoutVetsourceCodeTagsInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUncheckedUpdateWithoutServiceInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpdateInput = {
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceCodeTagsNestedInput>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpdateManyMutationInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput = {
  data: VetsourceCodeTagUncheckedUpdateManyWithoutVetsourceCodeTagsInput;
  where: VetsourceCodeTagScalarWhereInput;
};

export type VetsourceCodeTagUpdateManyWithoutServiceNestedInput = {
  connect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceCodeTagCreateOrConnectWithoutServiceInput>>;
  create?: Maybe<Array<VetsourceCodeTagCreateWithoutServiceInput>>;
  delete?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetsourceCodeTagScalarWhereInput>>;
  disconnect?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  set?: Maybe<Array<VetsourceCodeTagWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: Maybe<Array<VetsourceCodeTagUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: Maybe<Array<VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type VetsourceCodeTagUpdateWithWhereUniqueWithoutServiceInput = {
  data: VetsourceCodeTagUncheckedUpdateWithoutServiceInput;
  where: VetsourceCodeTagWhereUniqueInput;
};

export type VetsourceCodeTagUpdateWithoutServiceInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VetsourceCodeTagType>;
};

export type VetsourceCodeTagUpsertWithWhereUniqueWithoutServiceInput = {
  create: VetsourceCodeTagUncheckedCreateWithoutServiceInput;
  update: VetsourceCodeTagUncheckedUpdateWithoutServiceInput;
  where: VetsourceCodeTagWhereUniqueInput;
};

export type VetsourceCodeTagWhereInput = {
  AND?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  NOT?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  OR?: Maybe<Array<VetsourceCodeTagWhereInput>>;
  Service?: Maybe<ServiceListRelationFilter>;
  externalId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<EnumVetsourceCodeTagTypeFilter>;
};

export type VetsourceCodeTagWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VetsourceInstallation = {
  __typename?: 'VetsourceInstallation';
  clinic?: Maybe<Clinic>;
  clinicId?: Maybe<Scalars['String']>;
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum VetsourceInstallationConnectivityState {
  AlreadyConnected = 'AlreadyConnected',
  NeedsConnection = 'NeedsConnection'
}

export type VetsourceInstallationCountAggregateOutputType = {
  __typename?: 'VetsourceInstallationCountAggregateOutputType';
  _all: Scalars['Int'];
  clinicId: Scalars['Int'];
  connectivityState: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  isVetDataInstalled: Scalars['Int'];
  newConnectionPartner: Scalars['Int'];
  pims: Scalars['Int'];
  segment: Scalars['Int'];
  syncVetEulaUrl: Scalars['Int'];
  syncVetId: Scalars['Int'];
  syncVetRegistrationKey: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetsourceInstallationCountOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceInstallationCreateInput = {
  clinic?: Maybe<ClinicCreateNestedOneWithoutVetsourceInstallationInput>;
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationCreateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
};

export type VetsourceInstallationCreateOrConnectWithoutClinicInput = {
  create: VetsourceInstallationUncheckedCreateWithoutClinicInput;
  where: VetsourceInstallationWhereUniqueInput;
};

export type VetsourceInstallationCreateWithoutClinicInput = {
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationMaxAggregateOutputType = {
  __typename?: 'VetsourceInstallationMaxAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationMaxOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceInstallationMinAggregateOutputType = {
  __typename?: 'VetsourceInstallationMinAggregateOutputType';
  clinicId?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationMinOrderByAggregateInput = {
  clinicId?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VetsourceInstallationOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  NewConnectionPartner = 'newConnectionPartner',
  Pims = 'pims',
  Segment = 'segment',
  SyncVetEulaUrl = 'syncVetEulaUrl',
  SyncVetId = 'syncVetId',
  SyncVetRegistrationKey = 'syncVetRegistrationKey'
}

export type VetsourceInstallationOrderByRelevanceInput = {
  fields: Array<VetsourceInstallationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetsourceInstallationOrderByWithAggregationInput = {
  _count?: Maybe<VetsourceInstallationCountOrderByAggregateInput>;
  _max?: Maybe<VetsourceInstallationMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceInstallationMinOrderByAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceInstallationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetsourceInstallationOrderByRelevanceInput>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicId?: Maybe<SortOrder>;
  connectivityState?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVetDataInstalled?: Maybe<SortOrder>;
  newConnectionPartner?: Maybe<SortOrder>;
  pims?: Maybe<SortOrder>;
  segment?: Maybe<SortOrder>;
  syncVetEulaUrl?: Maybe<SortOrder>;
  syncVetId?: Maybe<SortOrder>;
  syncVetRegistrationKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceInstallationRelationFilter = {
  is?: Maybe<VetsourceInstallationWhereInput>;
  isNot?: Maybe<VetsourceInstallationWhereInput>;
};

export enum VetsourceInstallationScalarFieldEnum {
  ClinicId = 'clinicId',
  ConnectivityState = 'connectivityState',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsVetDataInstalled = 'isVetDataInstalled',
  NewConnectionPartner = 'newConnectionPartner',
  Pims = 'pims',
  Segment = 'segment',
  SyncVetEulaUrl = 'syncVetEulaUrl',
  SyncVetId = 'syncVetId',
  SyncVetRegistrationKey = 'syncVetRegistrationKey',
  UpdatedAt = 'updatedAt'
}

export type VetsourceInstallationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceInstallationScalarWhereWithAggregatesInput>>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  connectivityState?: Maybe<EnumVetsourceInstallationConnectivityStateWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isVetDataInstalled?: Maybe<BoolNullableWithAggregatesFilter>;
  newConnectionPartner?: Maybe<StringNullableWithAggregatesFilter>;
  pims?: Maybe<StringWithAggregatesFilter>;
  segment?: Maybe<StringWithAggregatesFilter>;
  syncVetEulaUrl?: Maybe<StringNullableWithAggregatesFilter>;
  syncVetId?: Maybe<StringNullableWithAggregatesFilter>;
  syncVetRegistrationKey?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetsourceInstallationUncheckedCreateInput = {
  clinicId?: Maybe<Scalars['String']>;
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUncheckedCreateNestedOneWithoutClinicInput = {
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
};

export type VetsourceInstallationUncheckedCreateWithoutClinicInput = {
  connectivityState: VetsourceInstallationConnectivityState;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims: Scalars['String'];
  segment: Scalars['String'];
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUncheckedUpdateInput = {
  clinicId?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUncheckedUpdateManyInput = {
  clinicId?: Maybe<Scalars['String']>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUncheckedUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetsourceInstallationUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<VetsourceInstallationUpsertWithoutClinicInput>;
};

export type VetsourceInstallationUncheckedUpdateWithoutClinicInput = {
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUpdateInput = {
  clinic?: Maybe<ClinicUpdateOneWithoutVetsourceInstallationNestedInput>;
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUpdateManyMutationInput = {
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUpdateOneWithoutClinicNestedInput = {
  connect?: Maybe<VetsourceInstallationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceInstallationCreateOrConnectWithoutClinicInput>;
  create?: Maybe<VetsourceInstallationUncheckedCreateWithoutClinicInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetsourceInstallationUncheckedUpdateWithoutClinicInput>;
  upsert?: Maybe<VetsourceInstallationUpsertWithoutClinicInput>;
};

export type VetsourceInstallationUpdateWithoutClinicInput = {
  connectivityState?: Maybe<VetsourceInstallationConnectivityState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVetDataInstalled?: Maybe<Scalars['Boolean']>;
  newConnectionPartner?: Maybe<Scalars['String']>;
  pims?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  syncVetEulaUrl?: Maybe<Scalars['String']>;
  syncVetId?: Maybe<Scalars['String']>;
  syncVetRegistrationKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceInstallationUpsertWithoutClinicInput = {
  create: VetsourceInstallationUncheckedCreateWithoutClinicInput;
  update: VetsourceInstallationUncheckedUpdateWithoutClinicInput;
};

export type VetsourceInstallationWhereInput = {
  AND?: Maybe<Array<VetsourceInstallationWhereInput>>;
  NOT?: Maybe<Array<VetsourceInstallationWhereInput>>;
  OR?: Maybe<Array<VetsourceInstallationWhereInput>>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicId?: Maybe<StringNullableFilter>;
  connectivityState?: Maybe<EnumVetsourceInstallationConnectivityStateFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isVetDataInstalled?: Maybe<BoolNullableFilter>;
  newConnectionPartner?: Maybe<StringNullableFilter>;
  pims?: Maybe<StringFilter>;
  segment?: Maybe<StringFilter>;
  syncVetEulaUrl?: Maybe<StringNullableFilter>;
  syncVetId?: Maybe<StringNullableFilter>;
  syncVetRegistrationKey?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetsourceInstallationWhereUniqueInput = {
  clinicId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type VetsourceRevenueCategory = {
  __typename?: 'VetsourceRevenueCategory';
  Service: Array<Service>;
  _count: VetsourceRevenueCategoryCountOutputType;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory: Array<VetsourceRevenueCategory>;
  createdAt: Scalars['DateTime'];
  externalId: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategory>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


export type VetsourceRevenueCategoryServiceArgs = {
  cursor?: Maybe<ServiceWhereUniqueInput>;
  distinct?: Maybe<ServiceScalarFieldEnum>;
  orderBy?: Maybe<ServiceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ServiceWhereInput>;
};


export type VetsourceRevenueCategoryChildrenVetsourceRevenueCategoryArgs = {
  cursor?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  distinct?: Maybe<VetsourceRevenueCategoryScalarFieldEnum>;
  orderBy?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<VetsourceRevenueCategoryWhereInput>;
};

export type VetsourceRevenueCategoryAvgAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryAvgAggregateOutputType';
  accountNumber?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['Float']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Float']>;
};

export type VetsourceRevenueCategoryAvgOrderByAggregateInput = {
  accountNumber?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryCountAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryCountAggregateOutputType';
  _all: Scalars['Int'];
  accountName: Scalars['Int'];
  accountNumber: Scalars['Int'];
  createdAt: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  parentVetsourceRevenueCategoryExternalId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetsourceRevenueCategoryCountOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryCountOutputType = {
  __typename?: 'VetsourceRevenueCategoryCountOutputType';
  Service: Scalars['Int'];
  childrenVetsourceRevenueCategory: Scalars['Int'];
};

export type VetsourceRevenueCategoryCreateInput = {
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryCreateManyInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope = {
  data: Array<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput = {
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
};

export type VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput = {
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput>;
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryCreateNestedOneWithoutServiceInput = {
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput>;
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput>;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};

export type VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};

export type VetsourceRevenueCategoryCreateWithoutChildrenVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryCreateWithoutServiceInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryCreateNestedOneWithoutChildrenVetsourceRevenueCategoryInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryListRelationFilter = {
  every?: Maybe<VetsourceRevenueCategoryWhereInput>;
  none?: Maybe<VetsourceRevenueCategoryWhereInput>;
  some?: Maybe<VetsourceRevenueCategoryWhereInput>;
};

export type VetsourceRevenueCategoryMaxAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryMaxAggregateOutputType';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryMaxOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryMinAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategoryMinAggregateOutputType';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryMinOrderByAggregateInput = {
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum VetsourceRevenueCategoryOrderByRelevanceFieldEnum {
  AccountName = 'accountName',
  Id = 'id',
  Name = 'name'
}

export type VetsourceRevenueCategoryOrderByRelevanceInput = {
  fields: Array<VetsourceRevenueCategoryOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetsourceRevenueCategoryOrderByWithAggregationInput = {
  _avg?: Maybe<VetsourceRevenueCategoryAvgOrderByAggregateInput>;
  _count?: Maybe<VetsourceRevenueCategoryCountOrderByAggregateInput>;
  _max?: Maybe<VetsourceRevenueCategoryMaxOrderByAggregateInput>;
  _min?: Maybe<VetsourceRevenueCategoryMinOrderByAggregateInput>;
  _sum?: Maybe<VetsourceRevenueCategorySumOrderByAggregateInput>;
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput = {
  Service?: Maybe<ServiceOrderByRelationAggregateInput>;
  _relevance?: Maybe<VetsourceRevenueCategoryOrderByRelevanceInput>;
  accountName?: Maybe<SortOrder>;
  accountNumber?: Maybe<SortOrder>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryOrderByWithRelationAndSearchRelevanceInput>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryRelationFilter = {
  is?: Maybe<VetsourceRevenueCategoryWhereInput>;
  isNot?: Maybe<VetsourceRevenueCategoryWhereInput>;
};

export enum VetsourceRevenueCategoryScalarFieldEnum {
  AccountName = 'accountName',
  AccountNumber = 'accountNumber',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  Id = 'id',
  Name = 'name',
  ParentVetsourceRevenueCategoryExternalId = 'parentVetsourceRevenueCategoryExternalId',
  UpdatedAt = 'updatedAt'
}

export type VetsourceRevenueCategoryScalarWhereInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  accountName?: Maybe<StringNullableFilter>;
  accountNumber?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetsourceRevenueCategoryScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryScalarWhereWithAggregatesInput>>;
  accountName?: Maybe<StringNullableWithAggregatesFilter>;
  accountNumber?: Maybe<IntNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  externalId?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetsourceRevenueCategorySumAggregateOutputType = {
  __typename?: 'VetsourceRevenueCategorySumAggregateOutputType';
  accountNumber?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
};

export type VetsourceRevenueCategorySumOrderByAggregateInput = {
  accountNumber?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<SortOrder>;
};

export type VetsourceRevenueCategoryUncheckedCreateInput = {
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput = {
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUncheckedCreateNestedManyWithoutVetsourceRevenueCategoryInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedCreateNestedManyWithoutParentVetsourceRevenueCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateInput = {
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyWithoutChildrenVetsourceRevenueCategoryInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput = {
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  delete?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  set?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUncheckedUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUncheckedUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpdateInput = {
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpdateManyMutationInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput = {
  data: VetsourceRevenueCategoryUncheckedUpdateManyWithoutChildrenVetsourceRevenueCategoryInput;
  where: VetsourceRevenueCategoryScalarWhereInput;
};

export type VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput = {
  connect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<VetsourceRevenueCategoryCreateOrConnectWithoutParentVetsourceRevenueCategoryInput>>;
  create?: Maybe<Array<VetsourceRevenueCategoryCreateWithoutParentVetsourceRevenueCategoryInput>>;
  createMany?: Maybe<VetsourceRevenueCategoryCreateManyParentVetsourceRevenueCategoryInputEnvelope>;
  delete?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<VetsourceRevenueCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  set?: Maybe<Array<VetsourceRevenueCategoryWhereUniqueInput>>;
  update?: Maybe<Array<VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
  updateMany?: Maybe<Array<VetsourceRevenueCategoryUpdateManyWithWhereWithoutParentVetsourceRevenueCategoryInput>>;
  upsert?: Maybe<Array<VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput>>;
};

export type VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput = {
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutChildrenVetsourceRevenueCategoryInput>;
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput>;
  upsert?: Maybe<VetsourceRevenueCategoryUpsertWithoutChildrenVetsourceRevenueCategoryInput>;
};

export type VetsourceRevenueCategoryUpdateOneWithoutServiceNestedInput = {
  connect?: Maybe<VetsourceRevenueCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<VetsourceRevenueCategoryCreateOrConnectWithoutServiceInput>;
  create?: Maybe<VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput>;
  upsert?: Maybe<VetsourceRevenueCategoryUpsertWithoutServiceInput>;
};

export type VetsourceRevenueCategoryUpdateWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput = {
  data: VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};

export type VetsourceRevenueCategoryUpdateWithoutChildrenVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpdateWithoutParentVetsourceRevenueCategoryInput = {
  Service?: Maybe<ServiceUpdateManyWithoutVetsourceRevenueCategoryNestedInput>;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpdateWithoutServiceInput = {
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['Int']>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateManyWithoutParentVetsourceRevenueCategoryNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryUpdateOneWithoutChildrenVetsourceRevenueCategoryNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetsourceRevenueCategoryUpsertWithWhereUniqueWithoutParentVetsourceRevenueCategoryInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutParentVetsourceRevenueCategoryInput;
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutParentVetsourceRevenueCategoryInput;
  where: VetsourceRevenueCategoryWhereUniqueInput;
};

export type VetsourceRevenueCategoryUpsertWithoutChildrenVetsourceRevenueCategoryInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutChildrenVetsourceRevenueCategoryInput;
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutChildrenVetsourceRevenueCategoryInput;
};

export type VetsourceRevenueCategoryUpsertWithoutServiceInput = {
  create: VetsourceRevenueCategoryUncheckedCreateWithoutServiceInput;
  update: VetsourceRevenueCategoryUncheckedUpdateWithoutServiceInput;
};

export type VetsourceRevenueCategoryWhereInput = {
  AND?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  NOT?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  OR?: Maybe<Array<VetsourceRevenueCategoryWhereInput>>;
  Service?: Maybe<ServiceListRelationFilter>;
  accountName?: Maybe<StringNullableFilter>;
  accountNumber?: Maybe<IntNullableFilter>;
  childrenVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  externalId?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  parentVetsourceRevenueCategory?: Maybe<VetsourceRevenueCategoryWhereInput>;
  parentVetsourceRevenueCategoryExternalId?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetsourceRevenueCategoryWhereUniqueInput = {
  externalId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VetterIntegration = {
  __typename?: 'VetterIntegration';
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegration>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type VetterIntegrationCountAggregateOutputType = {
  __typename?: 'VetterIntegrationCountAggregateOutputType';
  _all: Scalars['Int'];
  apiKey: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VetterIntegrationCountOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetterIntegrationCreateInput = {
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationCreateNestedOneWithoutVetterInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationCreateManyInput = {
  apiKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationCreateNestedOneWithoutClinicPimsIntegrationInput = {
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
};

export type VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput = {
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  where: VetterIntegrationWhereUniqueInput;
};

export type VetterIntegrationCreateWithoutClinicPimsIntegrationInput = {
  apiKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationMaxAggregateOutputType = {
  __typename?: 'VetterIntegrationMaxAggregateOutputType';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationMaxOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetterIntegrationMinAggregateOutputType = {
  __typename?: 'VetterIntegrationMinAggregateOutputType';
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationMinOrderByAggregateInput = {
  apiKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum VetterIntegrationOrderByRelevanceFieldEnum {
  ApiKey = 'apiKey',
  Id = 'id'
}

export type VetterIntegrationOrderByRelevanceInput = {
  fields: Array<VetterIntegrationOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type VetterIntegrationOrderByWithAggregationInput = {
  _count?: Maybe<VetterIntegrationCountOrderByAggregateInput>;
  _max?: Maybe<VetterIntegrationMaxOrderByAggregateInput>;
  _min?: Maybe<VetterIntegrationMinOrderByAggregateInput>;
  apiKey?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetterIntegrationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<VetterIntegrationOrderByRelevanceInput>;
  apiKey?: Maybe<SortOrder>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationOrderByWithRelationAndSearchRelevanceInput>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type VetterIntegrationRelationFilter = {
  is?: Maybe<VetterIntegrationWhereInput>;
  isNot?: Maybe<VetterIntegrationWhereInput>;
};

export enum VetterIntegrationScalarFieldEnum {
  ApiKey = 'apiKey',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type VetterIntegrationScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<VetterIntegrationScalarWhereWithAggregatesInput>>;
  apiKey?: Maybe<StringWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type VetterIntegrationUncheckedCreateInput = {
  apiKey: Scalars['String'];
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedCreateNestedOneWithoutVetterInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput = {
  apiKey: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUncheckedUpdateInput = {
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUncheckedUpdateOneWithoutVetterNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUncheckedUpdateManyInput = {
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput = {
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUpdateInput = {
  apiKey?: Maybe<Scalars['String']>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationUpdateOneWithoutVetterNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUpdateManyMutationInput = {
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUpdateOneWithoutClinicPimsIntegrationNestedInput = {
  connect?: Maybe<VetterIntegrationWhereUniqueInput>;
  connectOrCreate?: Maybe<VetterIntegrationCreateOrConnectWithoutClinicPimsIntegrationInput>;
  create?: Maybe<VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput>;
  upsert?: Maybe<VetterIntegrationUpsertWithoutClinicPimsIntegrationInput>;
};

export type VetterIntegrationUpdateWithoutClinicPimsIntegrationInput = {
  apiKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VetterIntegrationUpsertWithoutClinicPimsIntegrationInput = {
  create: VetterIntegrationUncheckedCreateWithoutClinicPimsIntegrationInput;
  update: VetterIntegrationUncheckedUpdateWithoutClinicPimsIntegrationInput;
};

export type VetterIntegrationWhereInput = {
  AND?: Maybe<Array<VetterIntegrationWhereInput>>;
  NOT?: Maybe<Array<VetterIntegrationWhereInput>>;
  OR?: Maybe<Array<VetterIntegrationWhereInput>>;
  apiKey?: Maybe<StringFilter>;
  clinicPimsIntegration?: Maybe<ClinicPimsIntegrationWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type VetterIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VideoCallRecordingToggleInput = {
  roomSid: Scalars['String'];
  status: RecordingControlStatus;
};

export type WidgetClinicPetParentLookupInput = {
  clinicId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  petName?: Maybe<Scalars['String']>;
  petParentFirstName?: Maybe<Scalars['String']>;
  petParentLastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type WidgetClinicPetParentLookupResponse = {
  __typename?: 'WidgetClinicPetParentLookupResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<ClinicEntityCreationSource>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  pets: Array<ClinicPet>;
  phoneNumbers: Array<ClinicEntityPhoneNumber>;
  pimsId?: Maybe<Scalars['String']>;
};

export enum WidgetRequestType {
  AdvancedRequestRxRefill = 'AdvancedRequestRxRefill',
  DirectBooking = 'DirectBooking',
  InboundSms = 'InboundSms',
  RequestAppointment = 'RequestAppointment',
  RequestBoarding = 'RequestBoarding',
  RequestGroomingAppoinment = 'RequestGroomingAppoinment',
  RequestMedicalRecords = 'RequestMedicalRecords',
  RequestRxRefill = 'RequestRxRefill',
  RequestVirtualConsult = 'RequestVirtualConsult',
  TalkToStaff = 'TalkToStaff',
  Teletriage = 'Teletriage'
}

export enum WorkflowActionType {
  ConversationMessageTemplate = 'Conversation_MessageTemplate',
  ConversationStatusChange = 'Conversation_StatusChange',
  ConversationSystemMessage = 'Conversation_SystemMessage',
  WorkflowEventTrigger = 'WorkflowEvent_Trigger'
}

export enum WorkflowEvent {
  AppointmentClientArrival = 'Appointment_ClientArrival',
  AppointmentConfirmReminder = 'Appointment_ConfirmReminder',
  AppointmentFollowup = 'Appointment_Followup',
  AppointmentPimsSync = 'Appointment_Pims_Sync',
  AppointmentReminder = 'Appointment_Reminder',
  AppointmentScheduled = 'Appointment_Scheduled',
  CallDeflectionFormSubmitted = 'CallDeflection_FormSubmitted',
  InvoiceLineItemCreated = 'InvoiceLineItem_Created',
  LapsedPetParent = 'LapsedPetParent',
  ServiceReminderFormSubmitted = 'ServiceReminder_FormSubmitted',
  WidgetRequestFormSubmitted = 'WidgetRequest_FormSubmitted'
}

export type WorkflowEventAction = {
  __typename?: 'WorkflowEventAction';
  _count: WorkflowEventActionCountOutputType;
  actionType: WorkflowActionType;
  automationRunActions: Array<AutomationRunAction>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelStatusChange?: Maybe<ChannelStatus>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels: Array<Channel>;
  config: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Scalars['Int'];
  promptType: AutomationRunActionPromptType;
  responseChannelStatusChange?: Maybe<ChannelStatus>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns: Array<AutomationRun>;
  triggerWorkflow?: Maybe<WorkflowEventSetting>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workflowEventSetting?: Maybe<WorkflowEventSetting>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};


export type WorkflowEventActionAutomationRunActionsArgs = {
  cursor?: Maybe<AutomationRunActionWhereUniqueInput>;
  distinct?: Maybe<AutomationRunActionScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunActionWhereInput>;
};


export type WorkflowEventActionChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type WorkflowEventActionChannelsArgs = {
  cursor?: Maybe<ChannelWhereUniqueInput>;
  distinct?: Maybe<ChannelScalarFieldEnum>;
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelWhereInput>;
};


export type WorkflowEventActionReturnedAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};

export type WorkflowEventActionAvgAggregateOutputType = {
  __typename?: 'WorkflowEventActionAvgAggregateOutputType';
  order?: Maybe<Scalars['Float']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Float']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Float']>;
};

export type WorkflowEventActionAvgOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountAggregateOutputType = {
  __typename?: 'WorkflowEventActionCountAggregateOutputType';
  _all: Scalars['Int'];
  actionType: Scalars['Int'];
  channelStatusChangeId: Scalars['Int'];
  config: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  promptType: Scalars['Int'];
  responseChannelStatusChangeId: Scalars['Int'];
  triggerWorkflowId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowEventSettingId: Scalars['Int'];
  workflowEventTrigger: Scalars['Int'];
  workflowEventTriggerActionNumber: Scalars['Int'];
  workflowEventTriggerDelayInMilliseconds: Scalars['Int'];
};

export type WorkflowEventActionCountOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionCountOutputType = {
  __typename?: 'WorkflowEventActionCountOutputType';
  automationRunActions: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channels: Scalars['Int'];
  returnedAutomationRuns: Scalars['Int'];
};

export type WorkflowEventActionCreateInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyInput = {
  actionType: WorkflowActionType;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyResponseChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyResponseChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInput = {
  actionType: WorkflowActionType;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyTriggerWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInput = {
  actionType: WorkflowActionType;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope = {
  data: Array<WorkflowEventActionCreateManyWorkflowEventSettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventActionCreateNestedManyWithoutChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventActionCreateNestedManyWithoutResponseChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
};

export type WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
};

export type WorkflowEventActionCreateNestedOneWithoutAutomationRunActionsInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutChannelsInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
};

export type WorkflowEventActionCreateNestedOneWithoutReturnedAutomationRunsInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput>;
};

export type WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutChannelsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput = {
  create: WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput = {
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput = {
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionCreateWithoutAutomationRunActionsInput = {
  actionType: WorkflowActionType;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutChannelAutomationStatusesInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutChannelsInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutReturnedAutomationRunsInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutTriggerWorkflowInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingCreateNestedOneWithoutActionsInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionCreateWithoutWorkflowEventSettingInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventActionInput>;
  channels?: Maybe<ChannelCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutResponseWorkflowEventActionsInput>;
  returnedAutomationRuns?: Maybe<AutomationRunCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionListRelationFilter = {
  every?: Maybe<WorkflowEventActionWhereInput>;
  none?: Maybe<WorkflowEventActionWhereInput>;
  some?: Maybe<WorkflowEventActionWhereInput>;
};

export type WorkflowEventActionMaxAggregateOutputType = {
  __typename?: 'WorkflowEventActionMaxAggregateOutputType';
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionMaxOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionMinAggregateOutputType = {
  __typename?: 'WorkflowEventActionMinAggregateOutputType';
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionMinOrderByAggregateInput = {
  actionType?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventActionOrderByRelevanceFieldEnum {
  ChannelStatusChangeId = 'channelStatusChangeId',
  Id = 'id',
  ResponseChannelStatusChangeId = 'responseChannelStatusChangeId',
  TriggerWorkflowId = 'triggerWorkflowId',
  WorkflowEventSettingId = 'workflowEventSettingId',
  WorkflowEventTrigger = 'workflowEventTrigger'
}

export type WorkflowEventActionOrderByRelevanceInput = {
  fields: Array<WorkflowEventActionOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type WorkflowEventActionOrderByWithAggregationInput = {
  _avg?: Maybe<WorkflowEventActionAvgOrderByAggregateInput>;
  _count?: Maybe<WorkflowEventActionCountOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventActionMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventActionMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventActionSumOrderByAggregateInput>;
  actionType?: Maybe<SortOrder>;
  channelStatusChangeId?: Maybe<SortOrder>;
  config?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  triggerWorkflowId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<WorkflowEventActionOrderByRelevanceInput>;
  actionType?: Maybe<SortOrder>;
  automationRunActions?: Maybe<AutomationRunActionOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  channelStatusChangeId?: Maybe<SortOrder>;
  channels?: Maybe<ChannelOrderByRelationAggregateInput>;
  config?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  promptType?: Maybe<SortOrder>;
  responseChannelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  responseChannelStatusChangeId?: Maybe<SortOrder>;
  returnedAutomationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  triggerWorkflowId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowEventSetting?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowEventSettingId?: Maybe<SortOrder>;
  workflowEventTrigger?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionRelationFilter = {
  is?: Maybe<WorkflowEventActionWhereInput>;
  isNot?: Maybe<WorkflowEventActionWhereInput>;
};

export enum WorkflowEventActionScalarFieldEnum {
  ActionType = 'actionType',
  ChannelStatusChangeId = 'channelStatusChangeId',
  Config = 'config',
  CreatedAt = 'createdAt',
  Id = 'id',
  Order = 'order',
  PromptType = 'promptType',
  ResponseChannelStatusChangeId = 'responseChannelStatusChangeId',
  TriggerWorkflowId = 'triggerWorkflowId',
  UpdatedAt = 'updatedAt',
  WorkflowEventSettingId = 'workflowEventSettingId',
  WorkflowEventTrigger = 'workflowEventTrigger',
  WorkflowEventTriggerActionNumber = 'workflowEventTriggerActionNumber',
  WorkflowEventTriggerDelayInMilliseconds = 'workflowEventTriggerDelayInMilliseconds'
}

export type WorkflowEventActionScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  config?: Maybe<JsonFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  responseChannelStatusChangeId?: Maybe<StringNullableFilter>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
};

export type WorkflowEventActionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventActionScalarWhereWithAggregatesInput>>;
  actionType?: Maybe<EnumWorkflowActionTypeWithAggregatesFilter>;
  channelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  config?: Maybe<JsonWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  order?: Maybe<IntWithAggregatesFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeWithAggregatesFilter>;
  responseChannelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  triggerWorkflowId?: Maybe<StringNullableWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowEventSettingId?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTrigger?: Maybe<StringNullableWithAggregatesFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableWithAggregatesFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableWithAggregatesFilter>;
};

export type WorkflowEventActionSumAggregateOutputType = {
  __typename?: 'WorkflowEventActionSumAggregateOutputType';
  order?: Maybe<Scalars['Int']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionSumOrderByAggregateInput = {
  order?: Maybe<SortOrder>;
  workflowEventTriggerActionNumber?: Maybe<SortOrder>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<SortOrder>;
};

export type WorkflowEventActionUncheckedCreateInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutResponseChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
};

export type WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
};

export type WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput = {
  actionType: WorkflowActionType;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutChannelsInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput = {
  actionType: WorkflowActionType;
  automationRunActions?: Maybe<AutomationRunActionUncheckedCreateNestedManyWithoutAutomationActionInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutCurrentWorkflowEventActionInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedCreateNestedManyWithoutCurrentWorkflowActionInput>;
  config: Scalars['Json'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutReturnToAutomationActionInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutActionsInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutResponseChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutResponseWorkflowEventActionsInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput = {
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutChannelsInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSettingId?: Maybe<Scalars['String']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUncheckedUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChangeId?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelUncheckedUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChangeId?: Maybe<Scalars['String']>;
  returnedAutomationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflowId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateManyMutationInput = {
  actionType?: Maybe<WorkflowActionType>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput = {
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionInput;
  where: WorkflowEventActionScalarWhereInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput = {
  data: WorkflowEventActionUncheckedUpdateManyWithoutResponseWorkflowEventActionsInput;
  where: WorkflowEventActionScalarWhereInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput = {
  data: WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventActionsInput;
  where: WorkflowEventActionScalarWhereInput;
};

export type WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput = {
  data: WorkflowEventActionUncheckedUpdateManyWithoutActionsInput;
  where: WorkflowEventActionScalarWhereInput;
};

export type WorkflowEventActionUpdateManyWithoutChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput>>;
};

export type WorkflowEventActionUpdateManyWithoutResponseChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutResponseChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutResponseChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyResponseChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutResponseChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput>>;
};

export type WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutTriggerWorkflowInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutTriggerWorkflowInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyTriggerWorkflowInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutTriggerWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput>>;
};

export type WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput = {
  connect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventActionCreateOrConnectWithoutWorkflowEventSettingInput>>;
  create?: Maybe<Array<WorkflowEventActionCreateWithoutWorkflowEventSettingInput>>;
  createMany?: Maybe<WorkflowEventActionCreateManyWorkflowEventSettingInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventActionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventActionWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventActionUpdateManyWithWhereWithoutWorkflowEventSettingInput>>;
  upsert?: Maybe<Array<WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput>>;
};

export type WorkflowEventActionUpdateOneWithoutAutomationRunActionsNestedInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutAutomationRunActionsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutAutomationRunActionsInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventActionUpdateOneWithoutChannelsNestedInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutChannelsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutChannelsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutChannelsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutChannelsInput>;
};

export type WorkflowEventActionUpdateOneWithoutReturnedAutomationRunsNestedInput = {
  connect?: Maybe<WorkflowEventActionWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventActionCreateOrConnectWithoutReturnedAutomationRunsInput>;
  create?: Maybe<WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput>;
  upsert?: Maybe<WorkflowEventActionUpsertWithoutReturnedAutomationRunsInput>;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutChannelStatusChangeInput = {
  data: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutResponseChannelStatusChangeInput = {
  data: WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutTriggerWorkflowInput = {
  data: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpdateWithWhereUniqueWithoutWorkflowEventSettingInput = {
  data: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpdateWithoutAutomationRunActionsInput = {
  actionType?: Maybe<WorkflowActionType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutChannelAutomationStatusesInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutChannelStatusChangeInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutChannelsInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutResponseChannelStatusChangeInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutReturnedAutomationRunsInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutTriggerWorkflowInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventSetting?: Maybe<WorkflowEventSettingUpdateOneWithoutActionsNestedInput>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpdateWithoutWorkflowEventSettingInput = {
  actionType?: Maybe<WorkflowActionType>;
  automationRunActions?: Maybe<AutomationRunActionUpdateManyWithoutAutomationActionNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutCurrentWorkflowEventActionNestedInput>;
  channelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventActionNestedInput>;
  channels?: Maybe<ChannelUpdateManyWithoutCurrentWorkflowActionNestedInput>;
  config?: Maybe<Scalars['Json']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  promptType?: Maybe<AutomationRunActionPromptType>;
  responseChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutResponseWorkflowEventActionsNestedInput>;
  returnedAutomationRuns?: Maybe<AutomationRunUpdateManyWithoutReturnToAutomationActionNestedInput>;
  triggerWorkflow?: Maybe<WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowEventTrigger?: Maybe<Scalars['String']>;
  workflowEventTriggerActionNumber?: Maybe<Scalars['Int']>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<Scalars['Int']>;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutChannelStatusChangeInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelStatusChangeInput;
  update: WorkflowEventActionUncheckedUpdateWithoutChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutResponseChannelStatusChangeInput = {
  create: WorkflowEventActionUncheckedCreateWithoutResponseChannelStatusChangeInput;
  update: WorkflowEventActionUncheckedUpdateWithoutResponseChannelStatusChangeInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutTriggerWorkflowInput = {
  create: WorkflowEventActionUncheckedCreateWithoutTriggerWorkflowInput;
  update: WorkflowEventActionUncheckedUpdateWithoutTriggerWorkflowInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpsertWithWhereUniqueWithoutWorkflowEventSettingInput = {
  create: WorkflowEventActionUncheckedCreateWithoutWorkflowEventSettingInput;
  update: WorkflowEventActionUncheckedUpdateWithoutWorkflowEventSettingInput;
  where: WorkflowEventActionWhereUniqueInput;
};

export type WorkflowEventActionUpsertWithoutAutomationRunActionsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutAutomationRunActionsInput;
  update: WorkflowEventActionUncheckedUpdateWithoutAutomationRunActionsInput;
};

export type WorkflowEventActionUpsertWithoutChannelAutomationStatusesInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: WorkflowEventActionUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventActionUpsertWithoutChannelsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutChannelsInput;
  update: WorkflowEventActionUncheckedUpdateWithoutChannelsInput;
};

export type WorkflowEventActionUpsertWithoutReturnedAutomationRunsInput = {
  create: WorkflowEventActionUncheckedCreateWithoutReturnedAutomationRunsInput;
  update: WorkflowEventActionUncheckedUpdateWithoutReturnedAutomationRunsInput;
};

export type WorkflowEventActionWhereInput = {
  AND?: Maybe<Array<WorkflowEventActionWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventActionWhereInput>>;
  OR?: Maybe<Array<WorkflowEventActionWhereInput>>;
  actionType?: Maybe<EnumWorkflowActionTypeFilter>;
  automationRunActions?: Maybe<AutomationRunActionListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelStatusChange?: Maybe<ChannelStatusWhereInput>;
  channelStatusChangeId?: Maybe<StringNullableFilter>;
  channels?: Maybe<ChannelListRelationFilter>;
  config?: Maybe<JsonFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  promptType?: Maybe<EnumAutomationRunActionPromptTypeFilter>;
  responseChannelStatusChange?: Maybe<ChannelStatusWhereInput>;
  responseChannelStatusChangeId?: Maybe<StringNullableFilter>;
  returnedAutomationRuns?: Maybe<AutomationRunListRelationFilter>;
  triggerWorkflow?: Maybe<WorkflowEventSettingWhereInput>;
  triggerWorkflowId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowEventSetting?: Maybe<WorkflowEventSettingWhereInput>;
  workflowEventSettingId?: Maybe<StringNullableFilter>;
  workflowEventTrigger?: Maybe<StringNullableFilter>;
  workflowEventTriggerActionNumber?: Maybe<IntNullableFilter>;
  workflowEventTriggerDelayInMilliseconds?: Maybe<IntNullableFilter>;
};

export type WorkflowEventActionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type WorkflowEventSetting = {
  __typename?: 'WorkflowEventSetting';
  _count: WorkflowEventSettingCountOutputType;
  actions: Array<WorkflowEventAction>;
  appointmentTypes: Array<AppointmentType>;
  appointments: Array<Appointment>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns: Array<AutomationRun>;
  channelAutomationStatuses: Array<ChannelAutomationStatus>;
  channelCreationSources: Array<ChannelCreationSource>;
  childRunEvaluationStatus: ConditionEvaluationStatus;
  clinic?: Maybe<Clinic>;
  clinicEmployees: Array<ClinicEmployeeToWorkflowEventSetting>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms: Array<ClinicRoomToWorkflowEventSetting>;
  conditionSets: Array<ConditionSet>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours: Scalars['Int'];
  id: Scalars['String'];
  informAutomationCampaigns: Array<InformAutomationCampaign>;
  initialChannelStatusChange?: Maybe<ChannelStatus>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers: Array<LapsedPetParentTriggers>;
  messagingCampaign?: Maybe<MessagingCampaign>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours: WorkflowSendingHours;
  surveySetting?: Maybe<SurveySetting>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType: WorkflowEventTriggerType;
  triggeringFormTemplates: Array<FormTemplate>;
  updatedAt: Scalars['DateTime'];
  visitType: WorkflowVisitType;
  widgetRequestTypes: Array<ClinicWidgetRequestType>;
  workflowEventActions: Array<WorkflowEventAction>;
  workflowTriggers: Array<WorkflowTrigger>;
  workflowType: WorkflowType;
};


export type WorkflowEventSettingActionsArgs = {
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventActionWhereInput>;
};


export type WorkflowEventSettingAppointmentTypesArgs = {
  cursor?: Maybe<AppointmentTypeWhereUniqueInput>;
  distinct?: Maybe<AppointmentTypeScalarFieldEnum>;
  orderBy?: Maybe<AppointmentTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentTypeWhereInput>;
};


export type WorkflowEventSettingAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  distinct?: Maybe<AppointmentScalarFieldEnum>;
  orderBy?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};


export type WorkflowEventSettingAutomationRunsArgs = {
  cursor?: Maybe<AutomationRunWhereUniqueInput>;
  distinct?: Maybe<AutomationRunScalarFieldEnum>;
  orderBy?: Maybe<AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AutomationRunWhereInput>;
};


export type WorkflowEventSettingChannelAutomationStatusesArgs = {
  cursor?: Maybe<ChannelAutomationStatusWhereUniqueInput>;
  distinct?: Maybe<ChannelAutomationStatusScalarFieldEnum>;
  orderBy?: Maybe<ChannelAutomationStatusOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelAutomationStatusWhereInput>;
};


export type WorkflowEventSettingChannelCreationSourcesArgs = {
  cursor?: Maybe<ChannelCreationSourceWhereUniqueInput>;
  distinct?: Maybe<ChannelCreationSourceScalarFieldEnum>;
  orderBy?: Maybe<ChannelCreationSourceOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChannelCreationSourceWhereInput>;
};


export type WorkflowEventSettingClinicEmployeesArgs = {
  cursor?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicEmployeeToWorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicEmployeeToWorkflowEventSettingWhereInput>;
};


export type WorkflowEventSettingClinicRoomsArgs = {
  cursor?: Maybe<ClinicRoomToWorkflowEventSettingWhereUniqueInput>;
  distinct?: Maybe<ClinicRoomToWorkflowEventSettingScalarFieldEnum>;
  orderBy?: Maybe<ClinicRoomToWorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicRoomToWorkflowEventSettingWhereInput>;
};


export type WorkflowEventSettingInformAutomationCampaignsArgs = {
  cursor?: Maybe<InformAutomationCampaignWhereUniqueInput>;
  distinct?: Maybe<InformAutomationCampaignScalarFieldEnum>;
  orderBy?: Maybe<InformAutomationCampaignOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InformAutomationCampaignWhereInput>;
};


export type WorkflowEventSettingLapsedPetParentTriggersArgs = {
  cursor?: Maybe<LapsedPetParentTriggersWhereUniqueInput>;
  distinct?: Maybe<LapsedPetParentTriggersScalarFieldEnum>;
  orderBy?: Maybe<LapsedPetParentTriggersOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LapsedPetParentTriggersWhereInput>;
};


export type WorkflowEventSettingTriggeringFormTemplatesArgs = {
  cursor?: Maybe<FormTemplateWhereUniqueInput>;
  distinct?: Maybe<FormTemplateScalarFieldEnum>;
  orderBy?: Maybe<FormTemplateOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FormTemplateWhereInput>;
};


export type WorkflowEventSettingWidgetRequestTypesArgs = {
  cursor?: Maybe<ClinicWidgetRequestTypeWhereUniqueInput>;
  distinct?: Maybe<ClinicWidgetRequestTypeScalarFieldEnum>;
  orderBy?: Maybe<ClinicWidgetRequestTypeOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClinicWidgetRequestTypeWhereInput>;
};


export type WorkflowEventSettingWorkflowEventActionsArgs = {
  cursor?: Maybe<WorkflowEventActionWhereUniqueInput>;
  distinct?: Maybe<WorkflowEventActionScalarFieldEnum>;
  orderBy?: Maybe<WorkflowEventActionOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowEventActionWhereInput>;
};


export type WorkflowEventSettingWorkflowTriggersArgs = {
  cursor?: Maybe<WorkflowTriggerWhereUniqueInput>;
  distinct?: Maybe<WorkflowTriggerScalarFieldEnum>;
  orderBy?: Maybe<WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorkflowTriggerWhereInput>;
};

export type WorkflowEventSettingAvgAggregateOutputType = {
  __typename?: 'WorkflowEventSettingAvgAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Float']>;
  groupByWindowInHours?: Maybe<Scalars['Float']>;
};

export type WorkflowEventSettingAvgOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountAggregateOutputType = {
  __typename?: 'WorkflowEventSettingCountAggregateOutputType';
  _all: Scalars['Int'];
  automatedTriggerInMinutes: Scalars['Int'];
  automatedTriggerTimeOfDay: Scalars['Int'];
  automatedTriggerType: Scalars['Int'];
  childRunEvaluationStatus: Scalars['Int'];
  clinicId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  event: Scalars['Int'];
  groupByWindowInHours: Scalars['Int'];
  id: Scalars['Int'];
  initialChannelStatusChangeId: Scalars['Int'];
  isPublished: Scalars['Int'];
  messagingCampaignId: Scalars['Int'];
  name: Scalars['Int'];
  notificationBody: Scalars['Int'];
  sendingHours: Scalars['Int'];
  surveySettingId: Scalars['Int'];
  surveyStep: Scalars['Int'];
  surveyType: Scalars['Int'];
  timeFormat: Scalars['Int'];
  triggerType: Scalars['Int'];
  updatedAt: Scalars['Int'];
  visitType: Scalars['Int'];
  workflowType: Scalars['Int'];
};

export type WorkflowEventSettingCountOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
};

export type WorkflowEventSettingCountOutputType = {
  __typename?: 'WorkflowEventSettingCountOutputType';
  actions: Scalars['Int'];
  appointmentTypes: Scalars['Int'];
  appointments: Scalars['Int'];
  automationRuns: Scalars['Int'];
  channelAutomationStatuses: Scalars['Int'];
  channelCreationSources: Scalars['Int'];
  clinicEmployees: Scalars['Int'];
  clinicRooms: Scalars['Int'];
  informAutomationCampaigns: Scalars['Int'];
  lapsedPetParentTriggers: Scalars['Int'];
  triggeringFormTemplates: Scalars['Int'];
  widgetRequestTypes: Scalars['Int'];
  workflowEventActions: Scalars['Int'];
  workflowTriggers: Scalars['Int'];
};

export type WorkflowEventSettingCreateInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManyClinicInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManyClinicInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyClinicInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManyInitialChannelStatusChangeInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyInitialChannelStatusChangeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManyInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManyMessagingCampaignInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManyMessagingCampaignInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateManySurveySettingInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateManySurveySettingInputEnvelope = {
  data: Array<WorkflowEventSettingCreateManySurveySettingInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
};

export type WorkflowEventSettingCreateNestedManyWithoutInitialChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventSettingCreateNestedManyWithoutMessagingCampaignInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
};

export type WorkflowEventSettingCreateNestedManyWithoutSurveySettingInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
};

export type WorkflowEventSettingCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
};

export type WorkflowEventSettingCreateNestedManyWithoutWidgetRequestTypesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
};

export type WorkflowEventSettingCreateNestedOneWithoutActionsInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutAutomationRunsInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelAutomationStatusesInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutChannelCreationSourcesInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicEmployeesInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutClinicRoomsInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutInformAutomationCampaignsInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutLapsedPetParentTriggersInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowEventActionsInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
};

export type WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
};

export type WorkflowEventSettingCreateOrConnectWithoutActionsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingCreateWithoutActionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutAppointmentTypesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutAppointmentsInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutAutomationRunsInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutChannelAutomationStatusesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutChannelCreationSourcesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutClinicEmployeesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutClinicInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutClinicRoomsInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutInformAutomationCampaignsInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutLapsedPetParentTriggersInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutMessagingCampaignInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutSurveySettingInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutWidgetRequestTypesInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutWorkflowEventActionsInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingCreateWithoutWorkflowTriggersInput = {
  actions?: Maybe<WorkflowEventActionCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicCreateNestedOneWithoutWorkflowEventSettingsInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusCreateNestedOneWithoutWorkflowEventSettingsInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersCreateNestedManyWithoutAutomationInput>;
  messagingCampaign?: Maybe<MessagingCampaignCreateNestedOneWithoutWorkflowEventSettingsInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingCreateNestedOneWithoutWorkflowEventSettingsInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingListRelationFilter = {
  every?: Maybe<WorkflowEventSettingWhereInput>;
  none?: Maybe<WorkflowEventSettingWhereInput>;
  some?: Maybe<WorkflowEventSettingWhereInput>;
};

export type WorkflowEventSettingMaxAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMaxAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingMaxOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
};

export type WorkflowEventSettingMinAggregateOutputType = {
  __typename?: 'WorkflowEventSettingMinAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingMinOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
};

export type WorkflowEventSettingOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowEventSettingOrderByRelevanceFieldEnum {
  ClinicId = 'clinicId',
  Id = 'id',
  InitialChannelStatusChangeId = 'initialChannelStatusChangeId',
  MessagingCampaignId = 'messagingCampaignId',
  Name = 'name',
  NotificationBody = 'notificationBody',
  SurveySettingId = 'surveySettingId',
  TimeFormat = 'timeFormat'
}

export type WorkflowEventSettingOrderByRelevanceInput = {
  fields: Array<WorkflowEventSettingOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type WorkflowEventSettingOrderByWithAggregationInput = {
  _avg?: Maybe<WorkflowEventSettingAvgOrderByAggregateInput>;
  _count?: Maybe<WorkflowEventSettingCountOrderByAggregateInput>;
  _max?: Maybe<WorkflowEventSettingMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowEventSettingMinOrderByAggregateInput>;
  _sum?: Maybe<WorkflowEventSettingSumOrderByAggregateInput>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  clinicId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  messagingCampaignId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  surveySettingId?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  workflowType?: Maybe<SortOrder>;
};

export type WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<WorkflowEventSettingOrderByRelevanceInput>;
  actions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  appointmentTypes?: Maybe<AppointmentTypeOrderByRelationAggregateInput>;
  appointments?: Maybe<AppointmentOrderByRelationAggregateInput>;
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  automatedTriggerTimeOfDay?: Maybe<SortOrder>;
  automatedTriggerType?: Maybe<SortOrder>;
  automationRuns?: Maybe<AutomationRunOrderByRelationAggregateInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusOrderByRelationAggregateInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceOrderByRelationAggregateInput>;
  childRunEvaluationStatus?: Maybe<SortOrder>;
  clinic?: Maybe<ClinicOrderByWithRelationAndSearchRelevanceInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingOrderByRelationAggregateInput>;
  clinicId?: Maybe<SortOrder>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingOrderByRelationAggregateInput>;
  createdAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  event?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignOrderByRelationAggregateInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusOrderByWithRelationAndSearchRelevanceInput>;
  initialChannelStatusChangeId?: Maybe<SortOrder>;
  isPublished?: Maybe<SortOrder>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersOrderByRelationAggregateInput>;
  messagingCampaign?: Maybe<MessagingCampaignOrderByWithRelationAndSearchRelevanceInput>;
  messagingCampaignId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  notificationBody?: Maybe<SortOrder>;
  sendingHours?: Maybe<SortOrder>;
  surveySetting?: Maybe<SurveySettingOrderByWithRelationAndSearchRelevanceInput>;
  surveySettingId?: Maybe<SortOrder>;
  surveyStep?: Maybe<SortOrder>;
  surveyType?: Maybe<SortOrder>;
  timeFormat?: Maybe<SortOrder>;
  triggerType?: Maybe<SortOrder>;
  triggeringFormTemplates?: Maybe<FormTemplateOrderByRelationAggregateInput>;
  updatedAt?: Maybe<SortOrder>;
  visitType?: Maybe<SortOrder>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeOrderByRelationAggregateInput>;
  workflowEventActions?: Maybe<WorkflowEventActionOrderByRelationAggregateInput>;
  workflowTriggers?: Maybe<WorkflowTriggerOrderByRelationAggregateInput>;
  workflowType?: Maybe<SortOrder>;
};

export type WorkflowEventSettingRelationFilter = {
  is?: Maybe<WorkflowEventSettingWhereInput>;
  isNot?: Maybe<WorkflowEventSettingWhereInput>;
};

export enum WorkflowEventSettingScalarFieldEnum {
  AutomatedTriggerInMinutes = 'automatedTriggerInMinutes',
  AutomatedTriggerTimeOfDay = 'automatedTriggerTimeOfDay',
  AutomatedTriggerType = 'automatedTriggerType',
  ChildRunEvaluationStatus = 'childRunEvaluationStatus',
  ClinicId = 'clinicId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Event = 'event',
  GroupByWindowInHours = 'groupByWindowInHours',
  Id = 'id',
  InitialChannelStatusChangeId = 'initialChannelStatusChangeId',
  IsPublished = 'isPublished',
  MessagingCampaignId = 'messagingCampaignId',
  Name = 'name',
  NotificationBody = 'notificationBody',
  SendingHours = 'sendingHours',
  SurveySettingId = 'surveySettingId',
  SurveyStep = 'surveyStep',
  SurveyType = 'surveyType',
  TimeFormat = 'timeFormat',
  TriggerType = 'triggerType',
  UpdatedAt = 'updatedAt',
  VisitType = 'visitType',
  WorkflowType = 'workflowType'
}

export type WorkflowEventSettingScalarWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  initialChannelStatusChangeId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  messagingCampaignId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  surveySettingId?: Maybe<StringNullableFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
};

export type WorkflowEventSettingScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowEventSettingScalarWhereWithAggregatesInput>>;
  automatedTriggerInMinutes?: Maybe<IntNullableWithAggregatesFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableWithAggregatesFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableWithAggregatesFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusWithAggregatesFilter>;
  clinicId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  deletedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  event?: Maybe<EnumWorkflowEventWithAggregatesFilter>;
  groupByWindowInHours?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  initialChannelStatusChangeId?: Maybe<StringNullableWithAggregatesFilter>;
  isPublished?: Maybe<BoolNullableWithAggregatesFilter>;
  messagingCampaignId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  notificationBody?: Maybe<StringNullableWithAggregatesFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursWithAggregatesFilter>;
  surveySettingId?: Maybe<StringNullableWithAggregatesFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableWithAggregatesFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableWithAggregatesFilter>;
  timeFormat?: Maybe<StringNullableWithAggregatesFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeWithAggregatesFilter>;
  workflowType?: Maybe<EnumWorkflowTypeWithAggregatesFilter>;
};

export type WorkflowEventSettingSumAggregateOutputType = {
  __typename?: 'WorkflowEventSettingSumAggregateOutputType';
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
};

export type WorkflowEventSettingSumOrderByAggregateInput = {
  automatedTriggerInMinutes?: Maybe<SortOrder>;
  groupByWindowInHours?: Maybe<SortOrder>;
};

export type WorkflowEventSettingUncheckedCreateInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentTypesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutAppointmentsInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutClinicInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutInitialChannelStatusChangeInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutMessagingCampaignInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutSurveySettingInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutTriggeringFormTemplatesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
};

export type WorkflowEventSettingUncheckedCreateNestedManyWithoutWidgetRequestTypesInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
};

export type WorkflowEventSettingUncheckedCreateWithoutActionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput = {
  actions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  appointments?: Maybe<AppointmentUncheckedCreateNestedManyWithoutWorkflowsInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedCreateNestedManyWithoutOriginAutomationInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedCreateNestedManyWithoutWorkflowInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: WorkflowEvent;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedCreateNestedManyWithoutWorkflowEventSettingInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedCreateNestedManyWithoutAutomationInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedCreateNestedManyWithoutTriggeredWorkflowsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedCreateNestedManyWithoutWorkflowsInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedCreateNestedManyWithoutTriggerWorkflowInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateManyInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutInitialChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutMessagingCampaignNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutSurveySettingNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutTriggeringFormTemplatesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWidgetRequestTypesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutActionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput = {
  actions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUncheckedUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  clinicId?: Maybe<Scalars['String']>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUncheckedUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUncheckedUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChangeId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUncheckedUpdateManyWithoutAutomationNestedInput>;
  messagingCampaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySettingId?: Maybe<Scalars['String']>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUncheckedUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUncheckedUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUncheckedUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateManyMutationInput = {
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowEventSettingsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutTriggeredWorkflowsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput = {
  data: WorkflowEventSettingUncheckedUpdateManyWithoutWorkflowsInput;
  where: WorkflowEventSettingScalarWhereInput;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentTypesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentTypesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutAppointmentsNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutAppointmentsInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutAppointmentsInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutAppointmentsInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutClinicNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutClinicInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutClinicInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyClinicInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutClinicInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutInitialChannelStatusChangeNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutInitialChannelStatusChangeInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutInitialChannelStatusChangeInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyInitialChannelStatusChangeInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutInitialChannelStatusChangeInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutMessagingCampaignNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutMessagingCampaignInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutMessagingCampaignInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManyMessagingCampaignInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutMessagingCampaignInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutSurveySettingNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutSurveySettingInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutSurveySettingInput>>;
  createMany?: Maybe<WorkflowEventSettingCreateManySurveySettingInputEnvelope>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutSurveySettingInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutTriggeringFormTemplatesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutTriggeringFormTemplatesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutTriggeringFormTemplatesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutTriggeringFormTemplatesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput>>;
};

export type WorkflowEventSettingUpdateManyWithoutWidgetRequestTypesNestedInput = {
  connect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowEventSettingCreateOrConnectWithoutWidgetRequestTypesInput>>;
  create?: Maybe<Array<WorkflowEventSettingCreateWithoutWidgetRequestTypesInput>>;
  delete?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowEventSettingScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowEventSettingWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput>>;
  updateMany?: Maybe<Array<WorkflowEventSettingUpdateManyWithWhereWithoutWidgetRequestTypesInput>>;
  upsert?: Maybe<Array<WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput>>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutAutomationRunsNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutAutomationRunsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutAutomationRunsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutChannelAutomationStatusesNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelAutomationStatusesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicEmployeesNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicEmployeesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicEmployeesInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutClinicRoomsNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutClinicRoomsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutClinicRoomsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutInformAutomationCampaignsNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutInformAutomationCampaignsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutInformAutomationCampaignsInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutLapsedPetParentTriggersNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutLapsedPetParentTriggersInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutLapsedPetParentTriggersInput>;
};

export type WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowTriggersInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowTriggersInput>;
};

export type WorkflowEventSettingUpdateOneWithoutActionsNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutActionsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutActionsInput>;
};

export type WorkflowEventSettingUpdateOneWithoutChannelCreationSourcesNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutChannelCreationSourcesInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput>;
};

export type WorkflowEventSettingUpdateOneWithoutWorkflowEventActionsNestedInput = {
  connect?: Maybe<WorkflowEventSettingWhereUniqueInput>;
  connectOrCreate?: Maybe<WorkflowEventSettingCreateOrConnectWithoutWorkflowEventActionsInput>;
  create?: Maybe<WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput>;
  upsert?: Maybe<WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput>;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentTypesInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutAppointmentsInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutClinicInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutInitialChannelStatusChangeInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutMessagingCampaignInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutSurveySettingInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithWhereUniqueWithoutWidgetRequestTypesInput = {
  data: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpdateWithoutActionsInput = {
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentTypesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutAppointmentsInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutAutomationRunsInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutChannelAutomationStatusesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutChannelCreationSourcesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutClinicEmployeesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutClinicInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutClinicRoomsInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutInformAutomationCampaignsInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutInitialChannelStatusChangeInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutLapsedPetParentTriggersInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutMessagingCampaignInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutSurveySettingInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutTriggeringFormTemplatesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutWidgetRequestTypesInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowEventActionsInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowTriggers?: Maybe<WorkflowTriggerUpdateManyWithoutWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpdateWithoutWorkflowTriggersInput = {
  actions?: Maybe<WorkflowEventActionUpdateManyWithoutWorkflowEventSettingNestedInput>;
  appointmentTypes?: Maybe<AppointmentTypeUpdateManyWithoutWorkflowsNestedInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutWorkflowsNestedInput>;
  automatedTriggerInMinutes?: Maybe<Scalars['Int']>;
  automatedTriggerTimeOfDay?: Maybe<Scalars['DateTime']>;
  automatedTriggerType?: Maybe<AutomatedTriggerType>;
  automationRuns?: Maybe<AutomationRunUpdateManyWithoutOriginAutomationNestedInput>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusUpdateManyWithoutWorkflowEventSettingNestedInput>;
  channelCreationSources?: Maybe<ChannelCreationSourceUpdateManyWithoutWorkflowEventSettingNestedInput>;
  childRunEvaluationStatus?: Maybe<ConditionEvaluationStatus>;
  clinic?: Maybe<ClinicUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingUpdateManyWithoutWorkflowNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<WorkflowEvent>;
  groupByWindowInHours?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignUpdateManyWithoutWorkflowEventSettingNestedInput>;
  initialChannelStatusChange?: Maybe<ChannelStatusUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  isPublished?: Maybe<Scalars['Boolean']>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersUpdateManyWithoutAutomationNestedInput>;
  messagingCampaign?: Maybe<MessagingCampaignUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  name?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  sendingHours?: Maybe<WorkflowSendingHours>;
  surveySetting?: Maybe<SurveySettingUpdateOneWithoutWorkflowEventSettingsNestedInput>;
  surveyStep?: Maybe<SurveyStep>;
  surveyType?: Maybe<SurveyType>;
  timeFormat?: Maybe<Scalars['String']>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
  triggeringFormTemplates?: Maybe<FormTemplateUpdateManyWithoutTriggeredWorkflowsNestedInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visitType?: Maybe<WorkflowVisitType>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeUpdateManyWithoutWorkflowsNestedInput>;
  workflowEventActions?: Maybe<WorkflowEventActionUpdateManyWithoutTriggerWorkflowNestedInput>;
  workflowType?: Maybe<WorkflowType>;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentTypesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentTypesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutAppointmentsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAppointmentsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAppointmentsInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutClinicInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutInitialChannelStatusChangeInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutInitialChannelStatusChangeInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutInitialChannelStatusChangeInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutMessagingCampaignInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutMessagingCampaignInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutMessagingCampaignInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutSurveySettingInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutSurveySettingInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutSurveySettingInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutTriggeringFormTemplatesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutTriggeringFormTemplatesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutTriggeringFormTemplatesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithWhereUniqueWithoutWidgetRequestTypesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWidgetRequestTypesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutWidgetRequestTypesInput;
  where: WorkflowEventSettingWhereUniqueInput;
};

export type WorkflowEventSettingUpsertWithoutActionsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutActionsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutActionsInput;
};

export type WorkflowEventSettingUpsertWithoutAutomationRunsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutAutomationRunsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutAutomationRunsInput;
};

export type WorkflowEventSettingUpsertWithoutChannelAutomationStatusesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutChannelAutomationStatusesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelAutomationStatusesInput;
};

export type WorkflowEventSettingUpsertWithoutChannelCreationSourcesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutChannelCreationSourcesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutChannelCreationSourcesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicEmployeesInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicEmployeesInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicEmployeesInput;
};

export type WorkflowEventSettingUpsertWithoutClinicRoomsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutClinicRoomsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutClinicRoomsInput;
};

export type WorkflowEventSettingUpsertWithoutInformAutomationCampaignsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutInformAutomationCampaignsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutInformAutomationCampaignsInput;
};

export type WorkflowEventSettingUpsertWithoutLapsedPetParentTriggersInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutLapsedPetParentTriggersInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutLapsedPetParentTriggersInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowEventActionsInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowEventActionsInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowEventActionsInput;
};

export type WorkflowEventSettingUpsertWithoutWorkflowTriggersInput = {
  create: WorkflowEventSettingUncheckedCreateWithoutWorkflowTriggersInput;
  update: WorkflowEventSettingUncheckedUpdateWithoutWorkflowTriggersInput;
};

export type WorkflowEventSettingWhereInput = {
  AND?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  NOT?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  OR?: Maybe<Array<WorkflowEventSettingWhereInput>>;
  actions?: Maybe<WorkflowEventActionListRelationFilter>;
  appointmentTypes?: Maybe<AppointmentTypeListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  automatedTriggerInMinutes?: Maybe<IntNullableFilter>;
  automatedTriggerTimeOfDay?: Maybe<DateTimeNullableFilter>;
  automatedTriggerType?: Maybe<EnumAutomatedTriggerTypeNullableFilter>;
  automationRuns?: Maybe<AutomationRunListRelationFilter>;
  channelAutomationStatuses?: Maybe<ChannelAutomationStatusListRelationFilter>;
  channelCreationSources?: Maybe<ChannelCreationSourceListRelationFilter>;
  childRunEvaluationStatus?: Maybe<EnumConditionEvaluationStatusFilter>;
  clinic?: Maybe<ClinicWhereInput>;
  clinicEmployees?: Maybe<ClinicEmployeeToWorkflowEventSettingListRelationFilter>;
  clinicId?: Maybe<StringNullableFilter>;
  clinicRooms?: Maybe<ClinicRoomToWorkflowEventSettingListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  event?: Maybe<EnumWorkflowEventFilter>;
  groupByWindowInHours?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  informAutomationCampaigns?: Maybe<InformAutomationCampaignListRelationFilter>;
  initialChannelStatusChange?: Maybe<ChannelStatusWhereInput>;
  initialChannelStatusChangeId?: Maybe<StringNullableFilter>;
  isPublished?: Maybe<BoolNullableFilter>;
  lapsedPetParentTriggers?: Maybe<LapsedPetParentTriggersListRelationFilter>;
  messagingCampaign?: Maybe<MessagingCampaignWhereInput>;
  messagingCampaignId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  notificationBody?: Maybe<StringNullableFilter>;
  sendingHours?: Maybe<EnumWorkflowSendingHoursFilter>;
  surveySetting?: Maybe<SurveySettingWhereInput>;
  surveySettingId?: Maybe<StringNullableFilter>;
  surveyStep?: Maybe<EnumSurveyStepNullableFilter>;
  surveyType?: Maybe<EnumSurveyTypeNullableFilter>;
  timeFormat?: Maybe<StringNullableFilter>;
  triggerType?: Maybe<EnumWorkflowEventTriggerTypeFilter>;
  triggeringFormTemplates?: Maybe<FormTemplateListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  visitType?: Maybe<EnumWorkflowVisitTypeFilter>;
  widgetRequestTypes?: Maybe<ClinicWidgetRequestTypeListRelationFilter>;
  workflowEventActions?: Maybe<WorkflowEventActionListRelationFilter>;
  workflowTriggers?: Maybe<WorkflowTriggerListRelationFilter>;
  workflowType?: Maybe<EnumWorkflowTypeFilter>;
};

export type WorkflowEventSettingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum WorkflowEventTriggerType {
  Automated = 'Automated',
  Manual = 'Manual'
}

export enum WorkflowSendingHours {
  All24Hours = 'All24Hours',
  AutomationHours = 'AutomationHours',
  BusinessHours = 'BusinessHours'
}

export type WorkflowTrigger = {
  __typename?: 'WorkflowTrigger';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hasTriggered: Scalars['Boolean'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  triggerAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  workflow: WorkflowEventSetting;
  workflowId: Scalars['String'];
};

export type WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput = {
  appointmentId: Scalars['String'];
  workflowId: Scalars['String'];
};

export type WorkflowTriggerCountAggregateOutputType = {
  __typename?: 'WorkflowTriggerCountAggregateOutputType';
  _all: Scalars['Int'];
  appointmentId: Scalars['Int'];
  createdAt: Scalars['Int'];
  hasTriggered: Scalars['Int'];
  id: Scalars['Int'];
  isDeleted: Scalars['Int'];
  triggerAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  workflowId: Scalars['Int'];
};

export type WorkflowTriggerCountOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type WorkflowTriggerCreateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerCreateManyAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type WorkflowTriggerCreateManyAppointmentInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyAppointmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type WorkflowTriggerCreateManyWorkflowInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerCreateManyWorkflowInputEnvelope = {
  data: Array<WorkflowTriggerCreateManyWorkflowInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorkflowTriggerCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
};

export type WorkflowTriggerCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
};

export type WorkflowTriggerCreateOrConnectWithoutAppointmentInput = {
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerCreateOrConnectWithoutWorkflowInput = {
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerCreateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow: WorkflowEventSettingCreateNestedOneWithoutWorkflowTriggersInput;
};

export type WorkflowTriggerCreateWithoutWorkflowInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutWorkflowTriggersInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerListRelationFilter = {
  every?: Maybe<WorkflowTriggerWhereInput>;
  none?: Maybe<WorkflowTriggerWhereInput>;
  some?: Maybe<WorkflowTriggerWhereInput>;
};

export type WorkflowTriggerMaxAggregateOutputType = {
  __typename?: 'WorkflowTriggerMaxAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerMaxOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type WorkflowTriggerMinAggregateOutputType = {
  __typename?: 'WorkflowTriggerMinAggregateOutputType';
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerMinOrderByAggregateInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type WorkflowTriggerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum WorkflowTriggerOrderByRelevanceFieldEnum {
  AppointmentId = 'appointmentId',
  Id = 'id',
  WorkflowId = 'workflowId'
}

export type WorkflowTriggerOrderByRelevanceInput = {
  fields: Array<WorkflowTriggerOrderByRelevanceFieldEnum>;
  search: Scalars['String'];
  sort: SortOrder;
};

export type WorkflowTriggerOrderByWithAggregationInput = {
  _count?: Maybe<WorkflowTriggerCountOrderByAggregateInput>;
  _max?: Maybe<WorkflowTriggerMaxOrderByAggregateInput>;
  _min?: Maybe<WorkflowTriggerMinOrderByAggregateInput>;
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflowId?: Maybe<SortOrder>;
};

export type WorkflowTriggerOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: Maybe<WorkflowTriggerOrderByRelevanceInput>;
  appointment?: Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  hasTriggered?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDeleted?: Maybe<SortOrder>;
  triggerAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  workflow?: Maybe<WorkflowEventSettingOrderByWithRelationAndSearchRelevanceInput>;
  workflowId?: Maybe<SortOrder>;
};

export enum WorkflowTriggerScalarFieldEnum {
  AppointmentId = 'appointmentId',
  CreatedAt = 'createdAt',
  HasTriggered = 'hasTriggered',
  Id = 'id',
  IsDeleted = 'isDeleted',
  TriggerAt = 'triggerAt',
  UpdatedAt = 'updatedAt',
  WorkflowId = 'workflowId'
}

export type WorkflowTriggerScalarWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflowId?: Maybe<StringFilter>;
};

export type WorkflowTriggerScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<WorkflowTriggerScalarWhereWithAggregatesInput>>;
  appointmentId?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  hasTriggered?: Maybe<BoolWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isDeleted?: Maybe<BoolWithAggregatesFilter>;
  triggerAt?: Maybe<DateTimeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  workflowId?: Maybe<StringWithAggregatesFilter>;
};

export type WorkflowTriggerUncheckedCreateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
};

export type WorkflowTriggerUncheckedCreateNestedManyWithoutWorkflowInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
};

export type WorkflowTriggerUncheckedCreateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId: Scalars['String'];
};

export type WorkflowTriggerUncheckedCreateWithoutWorkflowInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerUncheckedUpdateInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerUncheckedUpdateManyInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflowId?: Maybe<Scalars['String']>;
};

export type WorkflowTriggerUncheckedUpdateWithoutWorkflowInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput>;
};

export type WorkflowTriggerUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput = {
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
  where: WorkflowTriggerScalarWhereInput;
};

export type WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput = {
  data: WorkflowTriggerUncheckedUpdateManyWithoutWorkflowTriggersInput;
  where: WorkflowTriggerScalarWhereInput;
};

export type WorkflowTriggerUpdateManyWithoutAppointmentNestedInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutAppointmentInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type WorkflowTriggerUpdateManyWithoutWorkflowNestedInput = {
  connect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorkflowTriggerCreateOrConnectWithoutWorkflowInput>>;
  create?: Maybe<Array<WorkflowTriggerCreateWithoutWorkflowInput>>;
  createMany?: Maybe<WorkflowTriggerCreateManyWorkflowInputEnvelope>;
  delete?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorkflowTriggerScalarWhereInput>>;
  disconnect?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  set?: Maybe<Array<WorkflowTriggerWhereUniqueInput>>;
  update?: Maybe<Array<WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput>>;
  updateMany?: Maybe<Array<WorkflowTriggerUpdateManyWithWhereWithoutWorkflowInput>>;
  upsert?: Maybe<Array<WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput>>;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerUpdateWithWhereUniqueWithoutWorkflowInput = {
  data: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerUpdateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<WorkflowEventSettingUpdateOneRequiredWithoutWorkflowTriggersNestedInput>;
};

export type WorkflowTriggerUpdateWithoutWorkflowInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutWorkflowTriggersNestedInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasTriggered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  triggerAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: WorkflowTriggerUncheckedCreateWithoutAppointmentInput;
  update: WorkflowTriggerUncheckedUpdateWithoutAppointmentInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerUpsertWithWhereUniqueWithoutWorkflowInput = {
  create: WorkflowTriggerUncheckedCreateWithoutWorkflowInput;
  update: WorkflowTriggerUncheckedUpdateWithoutWorkflowInput;
  where: WorkflowTriggerWhereUniqueInput;
};

export type WorkflowTriggerWhereInput = {
  AND?: Maybe<Array<WorkflowTriggerWhereInput>>;
  NOT?: Maybe<Array<WorkflowTriggerWhereInput>>;
  OR?: Maybe<Array<WorkflowTriggerWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  hasTriggered?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  isDeleted?: Maybe<BoolFilter>;
  triggerAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  workflow?: Maybe<WorkflowEventSettingWhereInput>;
  workflowId?: Maybe<StringFilter>;
};

export type WorkflowTriggerWhereUniqueInput = {
  appointmentId_workflowId?: Maybe<WorkflowTriggerAppointmentIdWorkflowIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum WorkflowType {
  Boarding = 'Boarding',
  Curbside = 'Curbside',
  Diagnostics = 'Diagnostics',
  Emergency = 'Emergency',
  InHouse = 'InHouse',
  MobileVisit = 'MobileVisit',
  NoWorkflow = 'NoWorkflow',
  Specialty = 'Specialty',
  Surgery = 'Surgery',
  Telemedicine = 'Telemedicine',
  Wellness = 'Wellness'
}

export enum WorkflowVisitType {
  Client = 'Client',
  Patient = 'Patient'
}

export type WritebackCareInvoiceAccountCreditToPimsInput = {
  invoiceId: Scalars['String'];
  paymentAmount: Scalars['Int'];
};

export type WritebackCareInvoiceAccountCreditToPimsResponse = {
  __typename?: 'WritebackCareInvoiceAccountCreditToPimsResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GetQuickSightEmbedUrlInput = {
  dashboardId: Scalars['String'];
};

export type SendEmailCampaignNowInput = {
  emailCampaignId: Scalars['String'];
};

export type SendVetSubscriptionReceiptInput = {
  clinicId: Scalars['String'];
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  firebaseId: Scalars['String'];
};

export type SentEmailCampaign = {
  __typename?: 'sentEmailCampaign';
  id?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type AppointmentBaseFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'createdAt' | 'updatedAt' | 'description' | 'durationInMinutes' | 'pimsId' | 'startAt' | 'consultationId' | 'integrationId' | 'appointmentTypeId' | 'isActive' | 'isDeleted' | 'title' | 'clinicPetId' | 'clinicId' | 'roomId' | 'status' | 'pimsStatus' | 'channelId'>
);

export type AppointmentFullSelectionFragment = (
  { __typename?: 'Appointment' }
  & { workflows: Array<(
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name'>
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
    & { channelStatus?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & Pick<ChannelStatus, 'id' | 'name' | 'color' | 'channelStatusAction'>
    )> }
  )>, appointmentType?: Maybe<(
    { __typename?: 'AppointmentType' }
    & AppointmentTypeBaseSelectionFragment
  )>, room?: Maybe<(
    { __typename?: 'ClinicRoom' }
    & ClinicRoomBaseSelectionFragment
  )>, clinicEmployees: Array<(
    { __typename?: 'ClinicEmployee' }
    & ClinicEmployeeBaseSelectionFragment
  )>, clinicPet?: Maybe<(
    { __typename?: 'ClinicPet' }
    & ClinicPetNestedSelectionFragment
  )>, clinicPetParents: Array<(
    { __typename?: 'ClinicPetParent' }
    & ClinicPetParentSelectionFragment
  )> }
  & AppointmentBaseFragment
);

export type AppointmentPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'breed' | 'dateOfBirth' | 'gender'>
  & { petParents: Array<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'clinicId' | 'email' | 'updatedAt'>
    & { phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
    )> }
  )> }
  & PetAvatarFragment
);

export type AppointmentTypeBaseSelectionFragment = (
  { __typename?: 'AppointmentType' }
  & Pick<AppointmentType, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'isVisible'>
);

export type AppointmentTypeWorkflowsSelectionFragment = (
  { __typename?: 'AppointmentType' }
  & { workflows: Array<(
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name' | 'workflowType' | 'event'>
  )> }
  & AppointmentTypeBaseSelectionFragment
);

export type ClinicEmployeeBaseSelectionFragment = (
  { __typename?: 'ClinicEmployee' }
  & Pick<ClinicEmployee, 'id' | 'createdAt' | 'updatedAt' | 'firstName' | 'lastName' | 'isVisible'>
);

export type ClinicRoomBaseSelectionFragment = (
  { __typename?: 'ClinicRoom' }
  & Pick<ClinicRoom, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'isVisible'>
);

export type CreateOneAppointmentMutationVariables = Exact<{
  data: AppointmentCreateInput;
}>;


export type CreateOneAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createOneAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentFullSelectionFragment
  ) }
);

export type UpdateOneAppointmentMutationVariables = Exact<{
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateInput;
}>;


export type UpdateOneAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateOneAppointment: (
    { __typename?: 'Appointment' }
    & AppointmentFullSelectionFragment
  ) }
);

export type FindManyAppointmentQueryVariables = Exact<{
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<AppointmentOrderByWithRelationAndSearchRelevanceInput> | AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyAppointmentQuery = (
  { __typename?: 'Query' }
  & { findManyAppointment?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & AppointmentFullSelectionFragment
  )>> }
);

export type FindManyAppointmentCountQueryVariables = Exact<{
  where?: Maybe<AppointmentWhereInput>;
  orderBy?: Maybe<Array<AppointmentOrderByWithRelationAndSearchRelevanceInput> | AppointmentOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyAppointmentCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyAppointmentCount'>
);

export type FindUniqueAppointmentQueryVariables = Exact<{
  where: AppointmentWhereUniqueInput;
}>;


export type FindUniqueAppointmentQuery = (
  { __typename?: 'Query' }
  & { findUniqueAppointment?: Maybe<(
    { __typename?: 'Appointment' }
    & AppointmentFullSelectionFragment
  )> }
);

export type GetAppointmentOptionsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetAppointmentOptionsQuery = (
  { __typename?: 'Query' }
  & { clinicEmployees?: Maybe<Array<(
    { __typename?: 'ClinicEmployee' }
    & ClinicEmployeeBaseSelectionFragment
  )>>, clinicRooms?: Maybe<Array<(
    { __typename?: 'ClinicRoom' }
    & ClinicRoomBaseSelectionFragment
  )>>, appointmentTypes?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & AppointmentTypeWorkflowsSelectionFragment
  )>> }
);

export type GetAppointmentPetsQueryVariables = Exact<{
  whereInput?: Maybe<SearchClinicPetInput>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetAppointmentPetsQuery = (
  { __typename?: 'Query' }
  & { clinicPetSearch: Array<(
    { __typename?: 'ClinicPet' }
    & AppointmentPetFragment
  )> }
);

export type GetFilterUserAppointmentGroupsOptionsQueryVariables = Exact<{
  where: UserAppointmentGroupingWhereInput;
}>;


export type GetFilterUserAppointmentGroupsOptionsQuery = (
  { __typename?: 'Query' }
  & { userAppointmentGroupings?: Maybe<Array<(
    { __typename?: 'UserAppointmentGrouping' }
    & Pick<UserAppointmentGrouping, 'id' | 'clinicId' | 'label'>
  )>> }
);

export type GetUserAppointmentGroupingsQueryVariables = Exact<{
  where: UserAppointmentGroupingWhereInput;
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetUserAppointmentGroupingsQuery = (
  { __typename?: 'Query' }
  & { count: Query['findManyUserAppointmentGroupingCount'] }
  & { userAppointmentGroupings?: Maybe<Array<(
    { __typename?: 'UserAppointmentGrouping' }
    & Pick<UserAppointmentGrouping, 'id' | 'clinicId' | 'label'>
    & { clinicEmployees: Array<(
      { __typename?: 'ClinicEmployee' }
      & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>
    )>, rooms: Array<(
      { __typename?: 'ClinicRoom' }
      & Pick<ClinicRoom, 'id' | 'name'>
    )>, appointmentTypes: Array<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'name'>
    )> }
  )>> }
);

export type GetUserAppointmentGroupingsFiltersQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetUserAppointmentGroupingsFiltersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userAppointmentGroupingsFilters'>
);

export type AutomationEditorAutomationFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id' | 'name' | 'isPublished' | 'triggerType' | 'visitType' | 'sendingHours' | 'automatedTriggerType' | 'automatedTriggerInMinutes' | 'automatedTriggerTimeOfDay' | 'event' | 'notificationBody' | 'timeFormat'>
  & { triggeringFormTemplates: Array<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'title'>
  )>, widgetRequestTypes: Array<(
    { __typename?: 'ClinicWidgetRequestType' }
    & Pick<ClinicWidgetRequestType, 'id' | 'displayName'>
  )>, appointmentTypes: Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'displayName' | 'name'>
  )>, clinicRooms: Array<(
    { __typename?: 'ClinicRoomToWorkflowEventSetting' }
    & Pick<ClinicRoomToWorkflowEventSetting, 'clinicRoomId'>
    & { clinicRoom: (
      { __typename?: 'ClinicRoom' }
      & Pick<ClinicRoom, 'id' | 'name'>
    ) }
  )>, clinicEmployees: Array<(
    { __typename?: 'ClinicEmployeeToWorkflowEventSetting' }
    & Pick<ClinicEmployeeToWorkflowEventSetting, 'clinicEmployeeId'>
    & { clinicEmployee: (
      { __typename?: 'ClinicEmployee' }
      & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>
    ) }
  )>, actions: Array<(
    { __typename?: 'WorkflowEventAction' }
    & Pick<WorkflowEventAction, 'id' | 'config' | 'promptType' | 'order' | 'channelStatusChangeId' | 'workflowEventTriggerDelayInMilliseconds' | 'workflowEventTriggerActionNumber' | 'triggerWorkflowId'>
  )>, conditionSets: Array<(
    { __typename?: 'ConditionSet' }
    & Pick<ConditionSet, 'id'>
  )> }
);

export type AutomationsListItemFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id' | 'name' | 'isPublished' | 'sendingHours' | 'triggerType' | 'event' | 'childRunEvaluationStatus' | 'messagingCampaignId'>
  & { conditionSets: Array<(
    { __typename?: 'ConditionSet' }
    & Pick<ConditionSet, 'id'>
    & { conditions: Array<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'id'>
    )> }
  )> }
);

export type AutomationsParentItemFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id' | 'name' | 'isPublished'>
);

export type CreateAutomationAndSendConditionsMutationVariables = Exact<{
  workflowEventSettingData: WorkflowEventSettingCreateInput;
  conditionsData: Array<ConditionCreateWithoutSetInput> | ConditionCreateWithoutSetInput;
}>;


export type CreateAutomationAndSendConditionsMutation = (
  { __typename?: 'Mutation' }
  & { createWorkflowEventSettingAndConditions: (
    { __typename?: 'WorkflowEventSetting' }
    & AutomationEditorAutomationFragment
  ) }
);

export type DeleteOneAutomationMutationVariables = Exact<{
  workflowEventSettingId: Scalars['String'];
}>;


export type DeleteOneAutomationMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneWorkflowEventSetting?: Maybe<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationsListItemFragment
  )>, deleteManyCondition?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )>, deleteManyConditionSet?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type DuplicateOneAutomationMutationVariables = Exact<{
  where: WorkflowEventSettingWhereUniqueInput;
}>;


export type DuplicateOneAutomationMutation = (
  { __typename?: 'Mutation' }
  & { copyOneWorkflowEventSetting: (
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id'>
  ) }
);

export type ToggleManyAutomationPublishedStateMutationVariables = Exact<{
  workflowEventSettingIds: Array<Scalars['String']> | Scalars['String'];
  isPublished: Scalars['Boolean'];
}>;


export type ToggleManyAutomationPublishedStateMutation = (
  { __typename?: 'Mutation' }
  & { bulkPublishWorkflowEventSettings: (
    { __typename?: 'BulkPublishWorkflowEventSettingsResponse' }
    & Pick<BulkPublishWorkflowEventSettingsResponse, 'count'>
  ) }
);

export type UpdateOneAutomationMutationVariables = Exact<{
  workflowEventSettingId: Scalars['String'];
  workflowEventSettingData: WorkflowEventSettingUpdateInput;
}>;


export type UpdateOneAutomationMutation = (
  { __typename?: 'Mutation' }
  & { updateOneWorkflowEventSetting: (
    { __typename?: 'WorkflowEventSetting' }
    & AutomationEditorAutomationFragment
  ) }
);

export type UpdateOneAutomationAndConditionSetMutationVariables = Exact<{
  workflowEventSettingId: Scalars['String'];
  workflowEventSettingData: WorkflowEventSettingUpdateInput;
  conditionSetId: Scalars['String'];
  conditionSetData: ConditionSetUpdateInput;
}>;


export type UpdateOneAutomationAndConditionSetMutation = (
  { __typename?: 'Mutation' }
  & { updateOneWorkflowEventSetting: (
    { __typename?: 'WorkflowEventSetting' }
    & AutomationEditorAutomationFragment
  ), updateOneConditionSet: (
    { __typename?: 'ConditionSet' }
    & AutomationEditorConditionSetFragment
  ) }
);

export type AutomationEditorConditionFragment = (
  { __typename?: 'Condition' }
  & Pick<Condition, 'id' | 'createdAt' | 'attribute' | 'operator' | 'operand' | 'checksum'>
);

export type AutomationEditorConditionSetFragment = (
  { __typename?: 'ConditionSet' }
  & Pick<ConditionSet, 'id' | 'conditionalEntityType' | 'conditionalEntityId' | 'parentConditionSetId' | 'operator' | 'negate'>
  & { conditions: Array<(
    { __typename?: 'Condition' }
    & AutomationEditorConditionFragment
  )> }
);

export type GetAutomationEditorDataQueryVariables = Exact<{
  workflowEventSettingId?: Maybe<Scalars['String']>;
}>;


export type GetAutomationEditorDataQuery = (
  { __typename?: 'Query' }
  & { findUniqueWorkflowEventSetting?: Maybe<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationEditorAutomationFragment
  )>, findManyConditionSet?: Maybe<Array<(
    { __typename?: 'ConditionSet' }
    & AutomationEditorConditionSetFragment
  )>> }
);

export type GetAutomationFormTemplateOptionsQueryVariables = Exact<{
  where: FormTemplateWhereInput;
}>;


export type GetAutomationFormTemplateOptionsQuery = (
  { __typename?: 'Query' }
  & { findManyFormTemplate?: Maybe<Array<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'title' | 'isActive' | 'isDeleted'>
  )>> }
);

export type GetAutomationParentAutomationsQueryVariables = Exact<{
  workflowId: Scalars['String'];
}>;


export type GetAutomationParentAutomationsQuery = (
  { __typename?: 'Query' }
  & { findManyParentWorkflowEventSetting: Array<Maybe<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationsParentItemFragment
  )>> }
);

export type GetAutomationSendConditionOptionsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetAutomationSendConditionOptionsQuery = (
  { __typename?: 'Query' }
  & { clinicEmployees?: Maybe<Array<(
    { __typename?: 'ClinicEmployee' }
    & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName' | 'isActive' | 'isDeleted' | 'isVisible'>
  )>>, clinicRooms?: Maybe<Array<(
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'isActive' | 'isVisible' | 'isDeleted'>
  )>>, appointmentTypes?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'name' | 'displayName' | 'isVisible'>
  )>> }
);

export type GetAutomationWidgetRequestTypeOptionsQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type GetAutomationWidgetRequestTypeOptionsQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { clinicWidgetSetting?: Maybe<(
      { __typename?: 'ClinicWidgetSetting' }
      & { clinicWidgetRequestTypes: Array<(
        { __typename?: 'ClinicWidgetRequestType' }
        & Pick<ClinicWidgetRequestType, 'id' | 'displayName' | 'requestType' | 'isDeleted' | 'isActive'>
      )> }
    )> }
  )> }
);

export type GetAutomationsConversationStatusOptionsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetAutomationsConversationStatusOptionsQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'color' | 'name' | 'channelStatusAction'>
  )>> }
);

export type GetAutomationsListQueryVariables = Exact<{
  clinicId: Scalars['String'];
  inEventType?: Maybe<Array<WorkflowEvent> | WorkflowEvent>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
}>;


export type GetAutomationsListQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationsListItemFragment
  )>> }
);

export type AutomationsSelectionListAutomationFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & { conditionSets: Array<(
    { __typename?: 'ConditionSet' }
    & AutomationEditorConditionSetFragment
  )> }
  & AutomationEditorAutomationFragment
);

export type GetAutomationsSelectionListQueryVariables = Exact<{
  clinicId: Scalars['String'];
  inEventType?: Maybe<Array<WorkflowEvent> | WorkflowEvent>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
}>;


export type GetAutomationsSelectionListQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationsSelectionListAutomationFragment
  )>> }
);

export type GetGlobalAutomationsListQueryVariables = Exact<{
  inEventType?: Maybe<Array<WorkflowEvent> | WorkflowEvent>;
  triggerType?: Maybe<WorkflowEventTriggerType>;
}>;


export type GetGlobalAutomationsListQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & AutomationsListItemFragment
  )>> }
);

export type BoardPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'updatedAt' | 'isPrimary' | 'isDeleted'>
  )> }
  & PetParentAvatarFragment
);

export type UpdateUserBoardFilterSettingsMutationVariables = Exact<{
  data: CustomUpsertOneUserBoardFilterDataInput;
  where: CustomUpsertOneUserBoardFilterWhereInput;
}>;


export type UpdateUserBoardFilterSettingsMutation = (
  { __typename?: 'Mutation' }
  & { customUpsertOneUserBoardFilter?: Maybe<(
    { __typename?: 'CustomUpsertOneUserBoardFilterResponse' }
    & Pick<CustomUpsertOneUserBoardFilterResponse, 'success' | 'error'>
  )> }
);

export type GetEmptyChannelStatusIdsQueryVariables = Exact<{
  where: GetChannelStatusIdsWithEntityCountOfZeroWhere;
}>;


export type GetEmptyChannelStatusIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getChannelStatusIdsWithEntityCountOfZero'>
);

export type GetPaginatedAutomationRunsQueryVariables = Exact<{
  where: AutomationRunWhereInput;
  orderBy?: Maybe<Array<AutomationRunOrderByWithRelationAndSearchRelevanceInput> | AutomationRunOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedAutomationRunsQuery = (
  { __typename?: 'Query' }
  & { count: Query['findManyAutomationRunCount'] }
  & { automationRuns?: Maybe<Array<(
    { __typename?: 'AutomationRun' }
    & Pick<AutomationRun, 'id' | 'status'>
    & { originAutomation: (
      { __typename?: 'WorkflowEventSetting' }
      & Pick<WorkflowEventSetting, 'id' | 'name'>
    ), appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'startAt' | 'durationInMinutes'>
      & { clinicPetParents: Array<(
        { __typename?: 'ClinicPetParent' }
        & Pick<ClinicPetParent, 'id'>
        & BoardPetParentFragment
      )>, clinicPet?: Maybe<(
        { __typename?: 'ClinicPet' }
        & Pick<ClinicPet, 'id'>
        & PetAvatarFragment
      )>, channel?: Maybe<(
        { __typename?: 'Channel' }
        & Pick<Channel, 'id' | 'updatedAt'>
        & { lastMessage?: Maybe<(
          { __typename?: 'ChannelMessage' }
          & Pick<ChannelMessage, 'id' | 'createdAt'>
        )>, channelStatus?: Maybe<(
          { __typename?: 'ChannelStatus' }
          & Pick<ChannelStatus, 'id'>
          & ChannelListChannelStatusFragment
        )> }
      )> }
    )> }
  )>> }
);

export type GetPaginatedChannelStatusesQueryVariables = Exact<{
  where?: Maybe<ChannelStatusWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetPaginatedChannelStatusesQuery = (
  { __typename?: 'Query' }
  & { count: Query['findManyChannelStatusCount'] }
  & { channelStatuses?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'channelStatusAction' | 'color' | 'name'>
  )>> }
);

export type GetPaginatedChannelsQueryVariables = Exact<{
  where: ChannelWhereInput;
  orderBy?: Maybe<Array<ChannelOrderByWithRelationAndSearchRelevanceInput> | ChannelOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  countOnly: Scalars['Boolean'];
}>;


export type GetPaginatedChannelsQuery = (
  { __typename?: 'Query' }
  & { count: Query['findManyChannelCount'] }
  & { channels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & ChannelListChannelFragment
  )>> }
);

export type GetUserBoardFilterSettingsQueryVariables = Exact<{
  where: CustomFindUniqueUserBoardFilterWhereInput;
}>;


export type GetUserBoardFilterSettingsQuery = (
  { __typename?: 'Query' }
  & { customFindUniqueUserBoardFilter?: Maybe<(
    { __typename?: 'CustomFindUniqueUserBoardFilterResponse' }
    & Pick<CustomFindUniqueUserBoardFilterResponse, 'currentView' | 'collapsedColumnsMap' | 'selectedChannelStatusIdsMap' | 'shouldHideEmptyColumns' | 'shouldCollapseNoStatusColumn' | 'dateRangeOption'>
    & { specificDateRange?: Maybe<(
      { __typename?: 'DateRangeOutput' }
      & Pick<DateRangeOutput, 'gte' | 'lte'>
    )> }
  )> }
);

export type ChannelFilterSelectionFragment = (
  { __typename?: 'ChannelFilterSelection' }
  & Pick<ChannelFilterSelection, 'id' | 'isDeleted' | 'name' | 'filters' | 'filterSelectionType'>
  & { userChannelFilterSelections: Array<(
    { __typename?: 'UserChannelFilterSelection' }
    & Pick<UserChannelFilterSelection, 'id' | 'isFilterOwner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type CreateChannelFilterSelectionMutationVariables = Exact<{
  data: CreateChannelFilterSelectionInput;
}>;


export type CreateChannelFilterSelectionMutation = (
  { __typename?: 'Mutation' }
  & { createChannelFilterSelection: (
    { __typename?: 'ChannelFilterSelection' }
    & ChannelFilterSelectionFragment
  ) }
);

export type DeleteChannelFilterSelectionMutationVariables = Exact<{
  channelFilterSelectionId: Scalars['String'];
}>;


export type DeleteChannelFilterSelectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteChannelFilterSelection?: Maybe<(
    { __typename?: 'ChannelFilterSelection' }
    & ChannelFilterSelectionFragment
  )> }
);

export type UpdateChannelFilterSelectionMutationVariables = Exact<{
  data: UpdateChannelFilterSelectionInput;
}>;


export type UpdateChannelFilterSelectionMutation = (
  { __typename?: 'Mutation' }
  & { updateChannelFilterSelection: (
    { __typename?: 'ChannelFilterSelection' }
    & ChannelFilterSelectionFragment
  ) }
);

export type GetChannelFilterSelectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChannelFilterSelectionsQuery = (
  { __typename?: 'Query' }
  & { savedChannelFilterSelections: Array<(
    { __typename?: 'ChannelFilterSelection' }
    & ChannelFilterSelectionFragment
  )> }
);

export type DeleteChannelMessageMutationVariables = Exact<{
  where: ChannelMessageWhereUniqueInput;
}>;


export type DeleteChannelMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneChannelMessage?: Maybe<(
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'id'>
  )> }
);

export type GetServiceReminderNotificationQueryVariables = Exact<{
  serviceReminderNotificationId: Scalars['String'];
}>;


export type GetServiceReminderNotificationQuery = (
  { __typename?: 'Query' }
  & { findManyServiceReminderNotification?: Maybe<Array<(
    { __typename?: 'ServiceReminderNotification' }
    & Pick<ServiceReminderNotification, 'id' | 'queueAt' | 'hasResponded'>
    & { bundledNotifications: Array<(
      { __typename?: 'ServiceReminderNotification' }
      & Pick<ServiceReminderNotification, 'id' | 'hasResponded'>
      & { serviceReminder: (
        { __typename?: 'ServiceReminder' }
        & Pick<ServiceReminder, 'id' | 'dueDate'>
        & { clinicPet?: Maybe<(
          { __typename?: 'ClinicPet' }
          & PetAvatarFragment
        )>, service: (
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'name'>
        ) }
      ) }
    )>, serviceReminder: (
      { __typename?: 'ServiceReminder' }
      & Pick<ServiceReminder, 'id' | 'dueDate'>
      & { service: (
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'name'>
      ), clinicPet?: Maybe<(
        { __typename?: 'ClinicPet' }
        & PetAvatarFragment
      )> }
    ) }
  )>> }
);

export type ChatEntityAppointmentFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'createdAt' | 'description' | 'startAt' | 'durationInMinutes'>
);

export type ChatEntityFormSubmissionFragment = (
  { __typename?: 'FormSubmission' }
  & Pick<FormSubmission, 'id' | 'submittedAt' | 'clinicPetParentId'>
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'title'>
  )> }
);

export type GetChatEntitiesQueryVariables = Exact<{
  appointmentWhereInput?: Maybe<AppointmentWhereInput>;
  formSubmissionsWhereInput?: Maybe<FormSubmissionWhereInput>;
}>;


export type GetChatEntitiesQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & ChatEntityAppointmentFragment
  )>>, formSubmissions?: Maybe<Array<(
    { __typename?: 'FormSubmission' }
    & ChatEntityFormSubmissionFragment
  )>> }
);

export type GetArchivedOverlayChannelStatusesQueryVariables = Exact<{
  where?: Maybe<ChannelStatusWhereInput>;
}>;


export type GetArchivedOverlayChannelStatusesQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'channelStatusAction' | 'order'>
  )>> }
);

export type GetPetParentActiveChannelMembersQueryVariables = Exact<{
  clinicPetParentIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPetParentActiveChannelMembersQuery = (
  { __typename?: 'Query' }
  & { findManyChannelMember?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'channelId'>
  )>> }
);

export type UpdateArchivedOverlayChannelStatusMutationVariables = Exact<{
  where: ChannelWhereUniqueInput;
  data: ChannelUpdateInput;
}>;


export type UpdateArchivedOverlayChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
    & { channelStatus?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & Pick<ChannelStatus, 'id'>
    )> }
  ) }
);

export type FindDocumentationQueryVariables = Exact<{
  where?: Maybe<DocumentationWhereInput>;
}>;


export type FindDocumentationQuery = (
  { __typename?: 'Query' }
  & { findManyDocumentation?: Maybe<Array<(
    { __typename?: 'Documentation' }
    & DocumentationFragment
  )>> }
);

export type DocumentationFragment = (
  { __typename?: 'Documentation' }
  & Pick<Documentation, 'id' | 'createdAt' | 'updatedAt' | 'provider' | 'category' | 'title' | 'url'>
);

export type TeamMemberMentionsListFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
  & ClinicUserAvatarFragment
);

export type GetTeamMembersMentionListQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>;
}>;


export type GetTeamMembersMentionListQuery = (
  { __typename?: 'Query' }
  & { findManyUser?: Maybe<Array<(
    { __typename?: 'User' }
    & TeamMemberMentionsListFragment
  )>> }
);

export type CreateChannelMessageAutoResponseMutationVariables = Exact<{
  data: ChannelMessageAutoResponseCreateInput;
}>;


export type CreateChannelMessageAutoResponseMutation = (
  { __typename?: 'Mutation' }
  & { createOneChannelMessageAutoResponse: (
    { __typename?: 'ChannelMessageAutoResponse' }
    & Pick<ChannelMessageAutoResponse, 'id'>
  ) }
);

export type GetConversationsQuickReplyQueryVariables = Exact<{
  where: ChannelSuggestedMessageWhereInput;
  alternatePrompt?: Maybe<Scalars['String']>;
  maxTokensToSample?: Maybe<Scalars['Int']>;
}>;


export type GetConversationsQuickReplyQuery = (
  { __typename?: 'Query' }
  & { aiChannelSuggestedMessage?: Maybe<(
    { __typename?: 'ChannelSuggestedMessageResponse' }
    & Pick<ChannelSuggestedMessageResponse, 'response' | 'parsedJson'>
  )> }
);

export type UpdateChannelMessageAutoResponseMutationVariables = Exact<{
  where: ChannelMessageAutoResponseWhereUniqueInput;
  data: ChannelMessageAutoResponseUpdateInput;
}>;


export type UpdateChannelMessageAutoResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannelMessageAutoResponse: (
    { __typename?: 'ChannelMessageAutoResponse' }
    & Pick<ChannelMessageAutoResponse, 'id'>
  ) }
);

export type SmsNotificationStatusFragment = (
  { __typename?: 'SmsNotificationStatus' }
  & Pick<SmsNotificationStatus, 'id' | 'createdAt' | 'updatedAt' | 'status' | 'receivingNumber' | 'sourceContactEntityId' | 'sourceContactEntityType' | 'provider' | 'providerMessageId' | 'errorType' | 'providerErrorData' | 'sentChannelMessageId'>
);

export type OrganizationPetCareBadgePlanEnrollmentFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id'>
  & { organizationPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & SidePanelOrganizationPetFragment
  )> }
);

export type ChannelListChannelMemberPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'pimsId'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'createdAt' | 'updatedAt' | 'number' | 'isDeleted' | 'isPrimary' | 'isOptedOut'>
  )> }
  & PetParentAvatarFragment
);

export type ChannelListChannelMemberFragment = (
  { __typename?: 'ChannelMember' }
  & Pick<ChannelMember, 'id' | 'lastConsumedMessageIndex' | 'twilioMemberSid' | 'twilioIdentity' | 'removedAt'>
  & { clinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id'>
    & ChannelListChannelMemberPetParentFragment
  )> }
);

export type ChannelListChannelStatusFragment = (
  { __typename?: 'ChannelStatus' }
  & Pick<ChannelStatus, 'id' | 'name' | 'color' | 'channelStatusAction'>
);

export type ChannelListChannelPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId'>
  & PetAvatarFragment
);

export type ChannelListChannelLastMessageFragment = (
  { __typename?: 'ChannelMessage' }
  & Pick<ChannelMessage, 'id' | 'createdAt' | 'deletedAt' | 'index' | 'body'>
  & { attachments: Array<(
    { __typename?: 'ChannelMessageAttachment' }
    & Pick<ChannelMessageAttachment, 'id' | 'attachmentType' | 'entityType'>
  )> }
);

export type ChannelListChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'updatedAt' | 'lastExportAt' | 'tags' | 'isPinned'>
  & { pets: Array<(
    { __typename?: 'ClinicPet' }
    & ChannelListChannelPetFragment
  )>, channelStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id'>
    & ChannelListChannelStatusFragment
  )>, channelMembers: Array<(
    { __typename?: 'ChannelMember' }
    & ChannelListChannelMemberFragment
  )>, lastMessage?: Maybe<(
    { __typename?: 'ChannelMessage' }
    & ChannelListChannelLastMessageFragment
  )>, creationSource?: Maybe<(
    { __typename?: 'ChannelCreationSource' }
    & Pick<ChannelCreationSource, 'id' | 'creationSource'>
  )>, channelPins: Array<(
    { __typename?: 'ChannelPin' }
    & ChannelPinFragment
  )> }
);

export type ChannelPinFragment = (
  { __typename?: 'ChannelPin' }
  & Pick<ChannelPin, 'id' | 'userId' | 'createdAt'>
);

export type ChannelViewClinicPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId'>
  & PetAvatarFragment
);

export type ChannelViewChannelStatusFragment = (
  { __typename?: 'ChannelStatus' }
  & Pick<ChannelStatus, 'id' | 'name' | 'color' | 'channelStatusAction'>
);

export type ChannelViewClinicPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'pimsId' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'isDeleted'>
  & { pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id'>
    & ChannelViewClinicPetFragment
  )>, phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt' | 'smsStatus'>
  )>, petParentSetting?: Maybe<(
    { __typename?: 'PetParentSetting' }
    & Pick<PetParentSetting, 'id' | 'smsNotifications' | 'emailOptedOutByPetParent' | 'pushNotifications'>
  )> }
  & PetParentAvatarFragment
);

export type ChannelViewChannelMemberFragment = (
  { __typename?: 'ChannelMember' }
  & Pick<ChannelMember, 'id' | 'lastConsumedMessageIndex' | 'twilioMemberSid' | 'twilioIdentity' | 'removedAt'>
  & { clinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id'>
    & ChannelViewClinicPetParentFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePic' | 'email' | 'type'>
    & { vetInfo?: Maybe<(
      { __typename?: 'VetInfo' }
      & Pick<VetInfo, 'id'>
      & { roles: Array<(
        { __typename?: 'StaffRole' }
        & Pick<StaffRole, 'id' | 'role'>
      )> }
    )> }
  )> }
);

export type ChannelViewChannelPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId'>
  & PetAvatarFragment
  & OrganizationPetCareBadgePlanEnrollmentFragment
);

export type ChannelViewChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'createdAt' | 'updatedAt' | 'isActive' | 'attributes' | 'twilioChannelSid' | 'friendlyName' | 'channelType' | 'channelVisibility'>
  & { creationSource?: Maybe<(
    { __typename?: 'ChannelCreationSource' }
    & Pick<ChannelCreationSource, 'id' | 'creationSource' | 'userId' | 'workflowEventSettingId' | 'clinicWidgetRequestId' | 'serviceReminderId' | 'clinicPetParentId' | 'formSubmissionId'>
  )>, channelMembers: Array<(
    { __typename?: 'ChannelMember' }
    & ChannelViewChannelMemberFragment
  )>, channelStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & ChannelViewChannelStatusFragment
  )>, lastMessage?: Maybe<(
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'id' | 'createdAt' | 'index' | 'body'>
    & { attachments: Array<(
      { __typename?: 'ChannelMessageAttachment' }
      & Pick<ChannelMessageAttachment, 'id' | 'attachmentType' | 'entityType'>
    )> }
  )>, assignees: Array<(
    { __typename?: 'User' }
    & { channelMembers: Array<(
      { __typename?: 'ChannelMember' }
      & ChannelViewChannelMemberFragment
    )> }
    & ClinicUserAvatarFragment
  )>, clinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name' | 'logo'>
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & ChannelViewChannelPetFragment
  )> }
);

export type ChannelExportToPimsMutationVariables = Exact<{
  where: ChannelExportWhereInput;
}>;


export type ChannelExportToPimsMutation = (
  { __typename?: 'Mutation' }
  & { channelExportToPims?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success' | 'payload'>
  )> }
);

export type ClearUnreadMessagesMutationVariables = Exact<{
  userId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
}>;


export type ClearUnreadMessagesMutation = (
  { __typename?: 'Mutation' }
  & { clearUnreadMessages: (
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success' | 'payload'>
  ) }
);

export type ConfirmAppointmentMutationVariables = Exact<{
  where?: Maybe<AppointmentWhereUniqueInput>;
}>;


export type ConfirmAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { confirmAppointment: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'status'>
  ) }
);

export type CreateChannelMessageMutationVariables = Exact<{
  data: ChannelMessageCreateInput;
}>;


export type CreateChannelMessageMutation = (
  { __typename?: 'Mutation' }
  & { createChannelMessage2: (
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'index' | 'body' | 'messageType' | 'forceReadReceipt'>
    & { attachments: Array<(
      { __typename?: 'ChannelMessageAttachment' }
      & Pick<ChannelMessageAttachment, 'id' | 'attachmentType' | 'entityType' | 'entityId' | 'filename' | 'url'>
    )>, author?: Maybe<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePic'>
      )>, clinicPetParent?: Maybe<(
        { __typename?: 'ClinicPetParent' }
        & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      )> }
    )>, channel?: Maybe<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
    )> }
  ) }
);

export type CreateChannelPinMutationVariables = Exact<{
  userId: Scalars['String'];
  channelId: Scalars['String'];
}>;


export type CreateChannelPinMutation = (
  { __typename?: 'Mutation' }
  & { createOneChannelPin: (
    { __typename?: 'ChannelPin' }
    & ChannelPinFragment
  ) }
);

export type DeleteChannelPinByUserAndChannelMutationVariables = Exact<{
  userId: Scalars['String'];
  channelId: Scalars['String'];
}>;


export type DeleteChannelPinByUserAndChannelMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyChannelPin?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type GetPaymentReceiptPdfQueryVariables = Exact<{
  data: ExportItemizedInvoiceToReceiptPdfInput;
}>;


export type GetPaymentReceiptPdfQuery = (
  { __typename?: 'Query' }
  & { exportItemizedInvoiceAsReceiptPDF: (
    { __typename?: 'ExportItemizedInvoiceToReceiptPDFResponse' }
    & Pick<ExportItemizedInvoiceToReceiptPdfResponse, 'success' | 'base64PDF'>
  ) }
);

export type UpdateCareBenefitUsageMutationVariables = Exact<{
  where: CareBenefitUsageWhereUniqueInput;
  data: CareBenefitUsageUpdateInput;
}>;


export type UpdateCareBenefitUsageMutation = (
  { __typename?: 'Mutation' }
  & { updateOneCareBenefitUsage: (
    { __typename?: 'CareBenefitUsage' }
    & Pick<CareBenefitUsage, 'id' | 'quantityUsed' | 'exhaustsBenefit'>
  ) }
);

export type UpdateOneCareBenefitUsageToInvoiceLineItemMutationVariables = Exact<{
  where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput;
  data: CareBenefitUsageToInvoiceLineItemUpdateInput;
}>;


export type UpdateOneCareBenefitUsageToInvoiceLineItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOneCareBenefitUsageToInvoiceLineItem: (
    { __typename?: 'CareBenefitUsageToInvoiceLineItem' }
    & Pick<CareBenefitUsageToInvoiceLineItem, 'id' | 'adjustedAmount'>
  ) }
);

export type UpdateChannelAssigneesMutationVariables = Exact<{
  where: ChannelWhereUniqueInput;
  data: ChannelUpdateInput;
}>;


export type UpdateChannelAssigneesMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
    & { channelStatus?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & Pick<ChannelStatus, 'id'>
    )>, assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type UpdateChannelMemberMutationVariables = Exact<{
  where: ChannelMemberWhereUniqueInput;
  data: ChannelMemberUpdateInput;
}>;


export type UpdateChannelMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannelMember: (
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'lastConsumedMessageIndex' | 'removedAt'>
  ) }
);

export type UpdateChannelMessageAttributesMutationVariables = Exact<{
  where: ChannelMessageWhereUniqueInput;
  data: ChannelMessageUpdateInput;
}>;


export type UpdateChannelMessageAttributesMutation = (
  { __typename?: 'Mutation' }
  & { updateChannelMessage: (
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'id' | 'attributes'>
    & { reactions: Array<(
      { __typename?: 'ChannelMessageReaction' }
      & ReactionFragment
    )> }
  ) }
);

export type UpdateChannelPetsMutationVariables = Exact<{
  where: ChannelWhereUniqueInput;
  data: ChannelUpdateInput;
}>;


export type UpdateChannelPetsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id'>
      & ChannelViewChannelPetFragment
    )> }
  ) }
);

export type UpdateLineItemMutationVariables = Exact<{
  where: InvoiceLineItemWhereUniqueInput;
  data: InvoiceLineItemUpdateInput;
}>;


export type UpdateLineItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOneInvoiceLineItem: (
    { __typename?: 'InvoiceLineItem' }
    & Pick<InvoiceLineItem, 'id'>
    & { connectedCareBenefits: Array<(
      { __typename?: 'CareBenefitUsageToInvoiceLineItem' }
      & Pick<CareBenefitUsageToInvoiceLineItem, 'id' | 'adjustedAmount'>
      & { careBenefitUsage: (
        { __typename?: 'CareBenefitUsage' }
        & Pick<CareBenefitUsage, 'id' | 'quantityUsed'>
      ) }
    )> }
  ) }
);

export type CalculateMultiplePetsBenefitsUsageQueryVariables = Exact<{
  where?: Maybe<CalculateMultiplePetsBenefitUsageInput>;
}>;


export type CalculateMultiplePetsBenefitsUsageQuery = (
  { __typename?: 'Query' }
  & { calculateMultiplePetsBenefitUsage?: Maybe<(
    { __typename?: 'CalculateMultiplePetsBenefitUsageOutput' }
    & CalculateMultiplePetsBenefitUsageFragment
  )> }
);

export type CalculateMultiplePetsBenefitUsageFragment = (
  { __typename?: 'CalculateMultiplePetsBenefitUsageOutput' }
  & { petUsages: Array<(
    { __typename?: 'PetBenefitUsage' }
    & PetUsageFragment
  )> }
);

export type PetUsageFragment = (
  { __typename?: 'PetBenefitUsage' }
  & Pick<PetBenefitUsage, 'organizationPetId'>
  & { usages: Array<(
    { __typename?: 'UsageByBenefit' }
    & PetUsageByBenefitFragment
  )> }
);

export type PetUsageByBenefitFragment = (
  { __typename?: 'UsageByBenefit' }
  & Pick<UsageByBenefit, 'provided' | 'used' | 'exhausted' | 'displayOrder'>
  & { sources: (
    { __typename?: 'BenefitSource' }
    & Pick<BenefitSource, 'planIds' | 'addonIds'>
  ), benefit: (
    { __typename?: 'CareBenefit' }
    & Pick<CareBenefit, 'id' | 'title' | 'tangible' | 'exhaustible' | 'providedDiscountPercentage' | 'usageUnitOfMeasure'>
  ) }
);

export type GetChannelAssigneeMenuDataQueryVariables = Exact<{
  channelId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
}>;


export type GetChannelAssigneeMenuDataQuery = (
  { __typename?: 'Query' }
  & { findUniqueChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
    & { assignees: Array<(
      { __typename?: 'User' }
      & ClinicUserAvatarFragment
    )> }
  )>, users?: Maybe<Array<(
    { __typename?: 'User' }
    & ClinicUserAvatarFragment
  )>> }
);

export type GetChannelListChannelsQueryVariables = Exact<{
  where: SearchChannelInput;
  take: Scalars['Int'];
  skip: Scalars['Int'];
  orderBy?: Maybe<ChannelOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type GetChannelListChannelsQuery = (
  { __typename?: 'Query' }
  & { channelSearch: (
    { __typename?: 'ChannelSearchOutput' }
    & Pick<ChannelSearchOutput, 'total'>
    & { channels: Array<(
      { __typename?: 'Channel' }
      & ChannelListChannelFragment
    )> }
  ) }
);

export type GetChannelMembersConsumptionHorizonQueryVariables = Exact<{
  where: ChannelMemberWhereInput;
}>;


export type GetChannelMembersConsumptionHorizonQuery = (
  { __typename?: 'Query' }
  & { channelMembers?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'lastConsumedMessageIndex'>
  )>> }
);

export type GetChannelPdfDownloadDataQueryVariables = Exact<{
  where: ChannelExportWhereInput;
}>;


export type GetChannelPdfDownloadDataQuery = (
  { __typename?: 'Query' }
  & { channelExport?: Maybe<(
    { __typename?: 'FileExportData' }
    & Pick<FileExportData, 'fileType' | 'base64Encoding'>
  )> }
);

export type ChannelPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId'>
  & PetAvatarFragment
);

export type GetChannelPetsQueryVariables = Exact<{
  where?: Maybe<ClinicPetParentWhereInput>;
  petsWhere: ClinicPetWhereInput;
  petsTake?: Maybe<Scalars['Int']>;
}>;


export type GetChannelPetsQuery = (
  { __typename?: 'Query' }
  & { findManyClinicPetParent?: Maybe<Array<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id'>
      & ChannelPetFragment
    )> }
  )>> }
);

export type ChannelViewChannelMessageAttachmentFragment = (
  { __typename?: 'ChannelMessageAttachment' }
  & Pick<ChannelMessageAttachment, 'id' | 'attachmentType' | 'entityType' | 'entityId' | 'filename' | 'uploadStatus' | 'url'>
);

export type ChannelViewChannelMessageFragment = (
  { __typename?: 'ChannelMessage' }
  & Pick<ChannelMessage, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'index' | 'body' | 'messageType' | 'forceReadReceipt' | 'source' | 'attributes' | 'sourcePhoneNumber'>
  & { mentionedUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'nameDisplay'>
  )>, attachments: Array<(
    { __typename?: 'ChannelMessageAttachment' }
    & ChannelViewChannelMessageAttachmentFragment
  )>, smsNotifificationStatuses: Array<(
    { __typename?: 'SmsNotificationStatus' }
    & SmsNotificationStatusFragment
  )>, author?: Maybe<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'twilioIdentity'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePic' | 'nameDisplay' | 'type'>
    )>, clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      & { phoneNumbers: Array<(
        { __typename?: 'ClinicEntityPhoneNumber' }
        & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
      )>, petParentSetting?: Maybe<(
        { __typename?: 'PetParentSetting' }
        & Pick<PetParentSetting, 'id' | 'smsNotifications'>
      )> }
      & PetParentAvatarFragment
    )> }
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
  )>, reactions: Array<(
    { __typename?: 'ChannelMessageReaction' }
    & ReactionFragment
  )> }
);

export type ReactionFragment = (
  { __typename?: 'ChannelMessageReaction' }
  & Pick<ChannelMessageReaction, 'id' | 'createdAt' | 'deletedAt' | 'reaction' | 'userId'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'namePrefix' | 'nameDisplay' | 'firstName' | 'lastName' | 'nameSuffix'>
  ) }
);

export type GetChannelViewChannelMessagesQueryVariables = Exact<{
  where: ChannelMessageWhereInput;
  orderBy?: Maybe<Array<ChannelMessageOrderByWithRelationAndSearchRelevanceInput> | ChannelMessageOrderByWithRelationAndSearchRelevanceInput>;
  cursor?: Maybe<ChannelMessageWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetChannelViewChannelMessagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyChannelMessageCount'>
  & { findManyChannelMessage?: Maybe<Array<(
    { __typename?: 'ChannelMessage' }
    & ChannelViewChannelMessageFragment
  )>> }
);

export type ChannelViewClientDropdownClinicPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
  & { pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'name'>
  )>, channelMembers: Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'removedAt'>
    & { channel?: Maybe<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
      & { channelStatus?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'channelStatusAction'>
      )> }
    )> }
  )> }
  & PetParentAvatarFragment
);

export type GetChannelViewClientDropdownPetParentsQueryVariables = Exact<{
  petIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetChannelViewClientDropdownPetParentsQuery = (
  { __typename?: 'Query' }
  & { findManyClinicPetParent?: Maybe<Array<(
    { __typename?: 'ClinicPetParent' }
    & ChannelViewClientDropdownClinicPetParentFragment
  )>> }
);

export type GetClinicChannelViewChannelQueryVariables = Exact<{
  channelId: Scalars['String'];
  clinicId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetClinicChannelViewChannelQuery = (
  { __typename?: 'Query' }
  & { findUniqueChannel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelViewChannelFragment
  )> }
);

export type GetClinicWidgetRequestQueryVariables = Exact<{
  where: ClinicWidgetRequestWhereUniqueInput;
}>;


export type GetClinicWidgetRequestQuery = (
  { __typename?: 'Query' }
  & { clinicWidgetRequest?: Maybe<(
    { __typename?: 'ClinicWidgetRequest' }
    & Pick<ClinicWidgetRequest, 'id' | 'url' | 'isVerified' | 'requestFormSubmission'>
    & { clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'pimsId'>
      & { phoneNumbers: Array<(
        { __typename?: 'ClinicEntityPhoneNumber' }
        & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
      )> }
      & PetParentAvatarFragment
    )>, clinicWidgetRequestType?: Maybe<(
      { __typename?: 'ClinicWidgetRequestType' }
      & Pick<ClinicWidgetRequestType, 'id' | 'displayName' | 'requestType'>
    )>, formSubmission?: Maybe<(
      { __typename?: 'FormSubmission' }
      & Pick<FormSubmission, 'id' | 'completedContent'>
    )> }
  )> }
);

export type ExportabilityCheckChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id'>
  & { pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'pimsId'>
  )>, channelMembers: Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id'>
    & { clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'pimsId'>
      & { pets: Array<(
        { __typename?: 'ClinicPet' }
        & Pick<ClinicPet, 'id' | 'pimsId'>
      )> }
    )> }
  )> }
);

export type GetExportabilityCheckChannelQueryVariables = Exact<{
  channelId: Scalars['String'];
  clinicId?: Maybe<Scalars['String']>;
}>;


export type GetExportabilityCheckChannelQuery = (
  { __typename?: 'Query' }
  & { findUniqueChannel?: Maybe<(
    { __typename?: 'Channel' }
    & ExportabilityCheckChannelFragment
  )> }
);

export type GetMessageComposerAttachmentFormTemplateQueryVariables = Exact<{
  where?: Maybe<FormTemplateWhereInput>;
}>;


export type GetMessageComposerAttachmentFormTemplateQuery = (
  { __typename?: 'Query' }
  & { findFirstFormTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'title' | 'content'>
  )> }
);

export type MessageComposerFormTemplateFragment = (
  { __typename?: 'FormTemplate' }
  & Pick<FormTemplate, 'id' | 'title' | 'isActive' | 'isDeleted' | 'formTemplateType' | 'content'>
);

export type GetMessageComposerFormTemplatesQueryVariables = Exact<{
  where: FormTemplateWhereInput;
}>;


export type GetMessageComposerFormTemplatesQuery = (
  { __typename?: 'Query' }
  & { findManyFormTemplate?: Maybe<Array<(
    { __typename?: 'FormTemplate' }
    & MessageComposerFormTemplateFragment
  )>> }
);

export type MessageComposerMessageTemplateFragment = (
  { __typename?: 'MessageTemplate' }
  & Pick<MessageTemplate, 'id' | 'name' | 'body' | 'isArchived' | 'attachments'>
);

export type GetMessageComposerMessageTemplatesQueryVariables = Exact<{
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<MessageTemplateOrderByWithRelationAndSearchRelevanceInput> | MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type GetMessageComposerMessageTemplatesQuery = (
  { __typename?: 'Query' }
  & { findManyMessageTemplate?: Maybe<Array<(
    { __typename?: 'MessageTemplate' }
    & MessageComposerMessageTemplateFragment
  )>> }
);

export type GetClinicServicesForInvoiceLineItemsQueryVariables = Exact<{
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<ServiceOrderByWithRelationAndSearchRelevanceInput> | ServiceOrderByWithRelationAndSearchRelevanceInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetClinicServicesForInvoiceLineItemsQuery = (
  { __typename?: 'Query' }
  & { findManyService?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'code'>
    & { organizationCareBenefitToClinicService: Array<(
      { __typename?: 'OrganizationCareBenefitToClinicService' }
      & { benefit: (
        { __typename?: 'CareBenefit' }
        & Pick<CareBenefit, 'id' | 'title' | 'description' | 'tangible' | 'providedDiscountPercentage' | 'usageUnitOfMeasure'>
        & { planBenefits: Array<(
          { __typename?: 'CarePlanBenefit' }
          & Pick<CarePlanBenefit, 'id' | 'includedUses'>
        )> }
      ) }
    )> }
  )>> }
);

export type GetSmsNotificationStatusQueryVariables = Exact<{
  where: SmsNotificationStatusWhereInput;
}>;


export type GetSmsNotificationStatusQuery = (
  { __typename?: 'Query' }
  & { findFirstSmsNotificationStatus?: Maybe<(
    { __typename?: 'SmsNotificationStatus' }
    & SmsNotificationStatusFragment
  )> }
);

export type SubscribeToAutomationRunActionSubscriptionVariables = Exact<{
  automationRunId: Scalars['String'];
}>;


export type SubscribeToAutomationRunActionSubscription = (
  { __typename?: 'Subscription' }
  & { automationRunActionChanged: (
    { __typename?: 'AutomationRunActionSubscription' }
    & { node: (
      { __typename?: 'AutomationRunAction' }
      & AutomationRunActionFragment
    ) }
  ) }
);

export type SubscribeToChannelListChannelsSubscriptionVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type SubscribeToChannelListChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { channelSyncedToSearch: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
  ) }
);

export type SubscribeToChannelMessageAttachmentSubscriptionVariables = Exact<{
  channelMessageAttachmentId: Scalars['String'];
}>;


export type SubscribeToChannelMessageAttachmentSubscription = (
  { __typename?: 'Subscription' }
  & { channelMessageAttachmentChanged: (
    { __typename?: 'ChannelMessageAttachmentSubscription' }
    & { node: (
      { __typename?: 'ChannelMessageAttachment' }
      & Pick<ChannelMessageAttachment, 'id' | 'filename' | 'url' | 'uploadStatus' | 'entityId' | 'attachmentType'>
    ) }
  ) }
);

export type SubscribeToChannelViewChannelSubscriptionVariables = Exact<{
  channelId: Scalars['String'];
  clinicId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type SubscribeToChannelViewChannelSubscription = (
  { __typename?: 'Subscription' }
  & { channelChanged: (
    { __typename?: 'ChannelSubscription' }
    & { node: (
      { __typename?: 'Channel' }
      & ChannelViewChannelFragment
    ) }
  ) }
);

export type SubscribeToChannelViewChannelMembersSubscriptionVariables = Exact<{
  channelId: Scalars['String'];
}>;


export type SubscribeToChannelViewChannelMembersSubscription = (
  { __typename?: 'Subscription' }
  & { channelMemberChanged: (
    { __typename?: 'ChannelMemberSubscription' }
    & { node: (
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id' | 'removedAt' | 'lastConsumedMessageIndex'>
      & { clinicPetParent?: Maybe<(
        { __typename?: 'ClinicPetParent' }
        & Pick<ClinicPetParent, 'id'>
        & { phoneNumbers: Array<(
          { __typename?: 'ClinicEntityPhoneNumber' }
          & Pick<ClinicEntityPhoneNumber, 'id' | 'isPrimary' | 'number' | 'smsStatus' | 'lastSmsSendDate'>
        )> }
      )> }
    ) }
  ) }
);

export type SubscribeToChannelViewChannelMessagesSubscriptionVariables = Exact<{
  channelId: Scalars['String'];
}>;


export type SubscribeToChannelViewChannelMessagesSubscription = (
  { __typename?: 'Subscription' }
  & { channelMessageChanged: (
    { __typename?: 'ChannelMessageSubscription' }
    & Pick<ChannelMessageSubscription, 'mutation'>
    & { node: (
      { __typename?: 'ChannelMessage' }
      & ChannelViewChannelMessageFragment
    ) }
  ) }
);

export type SubscribeToClinicWidgetRequestSubscriptionVariables = Exact<{
  where: SubscriptionClinicWidgetRequestWhereInput;
}>;


export type SubscribeToClinicWidgetRequestSubscription = (
  { __typename?: 'Subscription' }
  & { clinicWidgetRequestChanged: (
    { __typename?: 'ClinicWidgetRequestSubscription' }
    & { node: (
      { __typename?: 'ClinicWidgetRequest' }
      & Pick<ClinicWidgetRequest, 'id' | 'isVerified'>
    ) }
  ) }
);

export type SubscribeToPendingFulfillmentRewardUpdateSubscriptionVariables = Exact<{
  where: LoyaltyRewardRedemptionWhereUniqueInput;
}>;


export type SubscribeToPendingFulfillmentRewardUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { loyaltyRewardRedemptionUpdated: (
    { __typename?: 'LoyaltyRewardRedemptionSubscription' }
    & { node: (
      { __typename?: 'LoyaltyRewardRedemption' }
      & Pick<LoyaltyRewardRedemption, 'id' | 'status'>
      & { account: (
        { __typename?: 'LoyaltyAccount' }
        & Pick<LoyaltyAccount, 'id'>
      ) }
    ) }
  ) }
);

export type SubscribeToSmsNotificationStatusSubscriptionVariables = Exact<{
  where: SubscriptionSmsNotificationStatusWhereInput;
}>;


export type SubscribeToSmsNotificationStatusSubscription = (
  { __typename?: 'Subscription' }
  & { smsNotificationStatusChanged?: Maybe<(
    { __typename?: 'SmsNotificationStatusSubscription' }
    & { node: (
      { __typename?: 'SmsNotificationStatus' }
      & SmsNotificationStatusFragment
    ) }
  )> }
);

export type DemoWorkflowAppintmentWorkflowFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id' | 'name' | 'event' | 'isPublished' | 'createdAt' | 'surveySettingId' | 'surveyStep' | 'surveyType'>
);

export type DemoWorkflowAppointmentFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'description' | 'isDeleted' | 'createdAt' | 'startAt'>
  & { workflows: Array<(
    { __typename?: 'WorkflowEventSetting' }
    & DemoWorkflowAppintmentWorkflowFragment
  )>, clinicPet?: Maybe<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'name'>
  )> }
);

export type TriggerDemoWorkflowEventMutationVariables = Exact<{
  data: TriggerWorkflowEventInput;
}>;


export type TriggerDemoWorkflowEventMutation = (
  { __typename?: 'Mutation' }
  & { triggerWorkflowEvent?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success'>
  )> }
);

export type GetDemoWorkflowClinicAppointmentsQueryVariables = Exact<{
  where?: Maybe<AppointmentWhereInput>;
}>;


export type GetDemoWorkflowClinicAppointmentsQuery = (
  { __typename?: 'Query' }
  & { findManyAppointment?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & DemoWorkflowAppointmentFragment
  )>> }
);

export type SoftDeleteFormsMenuFormTemplateMutationVariables = Exact<{
  where: FormTemplateWhereUniqueInput;
}>;


export type SoftDeleteFormsMenuFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id'>
  ) }
);

export type CreateFormTemplateMutationVariables = Exact<{
  clinicId: Scalars['String'];
  title: Scalars['String'];
  content?: Maybe<Scalars['Json']>;
}>;


export type CreateFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id'>
  ) }
);

export type ImportFormTemplateMutationVariables = Exact<{
  data: FormTemplateCreateInput;
}>;


export type ImportFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id'>
  ) }
);

export type ToggleActiveFormTemplateMutationVariables = Exact<{
  formId: Scalars['String'];
  isActive: Scalars['Boolean'];
}>;


export type ToggleActiveFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'isActive'>
  ) }
);

export type UpdateFormTemplateMutationVariables = Exact<{
  where: FormTemplateWhereUniqueInput;
  data: FormTemplateUpdateInput;
}>;


export type UpdateFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & ClinicFormTemplateFragment
  ) }
);

export type AcceptUserInvitationMutationVariables = Exact<{
  data: AcceptUserInvitationInput;
}>;


export type AcceptUserInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptUserInvitation?: Maybe<(
    { __typename?: 'AcceptUserInvitationResponse' }
    & Pick<AcceptUserInvitationResponse, 'token'>
  )> }
);

export type LoyaltyProgramRewardFragment = (
  { __typename?: 'LoyaltyReward' }
  & Pick<LoyaltyReward, 'id' | 'title' | 'description' | 'pointCost' | 'availability'>
);

export type CreateLoyaltyProgramRewardMutationVariables = Exact<{
  data: LoyaltyRewardCreateInput;
}>;


export type CreateLoyaltyProgramRewardMutation = (
  { __typename?: 'Mutation' }
  & { createOneLoyaltyReward: (
    { __typename?: 'LoyaltyReward' }
    & Pick<LoyaltyReward, 'id'>
  ) }
);

export type FindOrCreateLoyaltyProgramMutationVariables = Exact<{ [key: string]: never; }>;


export type FindOrCreateLoyaltyProgramMutation = (
  { __typename?: 'Mutation' }
  & { findOrCreateLoyaltyProgram: (
    { __typename?: 'LoyaltyProgram' }
    & Pick<LoyaltyProgram, 'id' | 'status' | 'centsPerPoint' | 'allowSpendCarryOver' | 'minimumTransactionCents' | 'description'>
  ) }
);

export type UpdateLoyaltyProgramRewardMutationVariables = Exact<{
  data: LoyaltyRewardUpdateInput;
  where: LoyaltyRewardWhereUniqueInput;
}>;


export type UpdateLoyaltyProgramRewardMutation = (
  { __typename?: 'Mutation' }
  & { updateOneLoyaltyReward: (
    { __typename?: 'LoyaltyReward' }
    & Pick<LoyaltyReward, 'id'>
  ) }
);

export type UpdateLoyaltyProgramRewardsAvailabilityMutationVariables = Exact<{
  data: LoyaltyRewardUpdateManyMutationInput;
  where: LoyaltyRewardWhereInput;
}>;


export type UpdateLoyaltyProgramRewardsAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateManyLoyaltyReward?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdateLoyaltyProgramSettingsMutationVariables = Exact<{
  data: LoyaltyProgramUpdateInput;
  where: LoyaltyProgramWhereUniqueInput;
}>;


export type UpdateLoyaltyProgramSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneLoyaltyProgram: (
    { __typename?: 'LoyaltyProgram' }
    & Pick<LoyaltyProgram, 'id'>
  ) }
);

export type UpdateOneLoyaltyInvoicePointGranterMutationVariables = Exact<{
  data: LoyaltyInvoicePointGranterUpdateInput;
  where: LoyaltyInvoicePointGranterWhereUniqueInput;
}>;


export type UpdateOneLoyaltyInvoicePointGranterMutation = (
  { __typename?: 'Mutation' }
  & { updateOneLoyaltyInvoicePointGranter: (
    { __typename?: 'LoyaltyInvoicePointGranter' }
    & Pick<LoyaltyInvoicePointGranter, 'id'>
  ) }
);

export type GetLoyaltyProgramBasicInfoQueryVariables = Exact<{
  where?: Maybe<LoyaltyProgramWhereInput>;
}>;


export type GetLoyaltyProgramBasicInfoQuery = (
  { __typename?: 'Query' }
  & { findFirstLoyaltyProgram?: Maybe<(
    { __typename?: 'LoyaltyProgram' }
    & Pick<LoyaltyProgram, 'id' | 'status'>
  )> }
);

export type GetLoyaltyProgramPointGrantingServicesQueryVariables = Exact<{
  where?: Maybe<LoyaltyProgramWhereInput>;
}>;


export type GetLoyaltyProgramPointGrantingServicesQuery = (
  { __typename?: 'Query' }
  & { findFirstLoyaltyProgram?: Maybe<(
    { __typename?: 'LoyaltyProgram' }
    & PointGranterServiceFragment
  )> }
);

export type PointGranterServiceFragment = (
  { __typename?: 'LoyaltyProgram' }
  & Pick<LoyaltyProgram, 'id' | 'status'>
  & { pointGranters: Array<(
    { __typename?: 'LoyaltyInvoicePointGranter' }
    & Pick<LoyaltyInvoicePointGranter, 'id' | 'enabled' | 'allowAllServices'>
    & { grantingServices: Array<(
      { __typename?: 'LoyaltyPointGrantingService' }
      & Pick<LoyaltyPointGrantingService, 'serviceId'>
      & { service: (
        { __typename?: 'Service' }
        & ServiceFromIntegrationIdFragment
      ) }
    )> }
  )> }
);

export type GetLoyaltyProgramRewardsQueryVariables = Exact<{
  where?: Maybe<LoyaltyRewardWhereInput>;
  orderBy?: Maybe<Array<Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>> | Maybe<LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetLoyaltyProgramRewardsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyLoyaltyRewardCount'>
  & { findManyLoyaltyReward?: Maybe<Array<(
    { __typename?: 'LoyaltyReward' }
    & LoyaltyProgramRewardFragment
  )>> }
);

export type GetServicesByIntegrationIdQueryVariables = Exact<{
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<ServiceOrderByWithRelationAndSearchRelevanceInput> | ServiceOrderByWithRelationAndSearchRelevanceInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetServicesByIntegrationIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyServiceCount'>
  & { findManyService?: Maybe<Array<(
    { __typename?: 'Service' }
    & ServiceFromIntegrationIdFragment
  )>> }
);

export type ServiceFromIntegrationIdFragment = (
  { __typename?: 'Service' }
  & Pick<Service, 'id' | 'name' | 'code' | 'pimsName'>
);

export type GetServicesIdsByIntegrationIdQueryVariables = Exact<{
  where?: Maybe<ServiceWhereInput>;
}>;


export type GetServicesIdsByIntegrationIdQuery = (
  { __typename?: 'Query' }
  & { servicesIds?: Maybe<Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id'>
  )>> }
);

export type FullMessageTemplateFragment = (
  { __typename?: 'MessageTemplate' }
  & Pick<MessageTemplate, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'body' | 'isArchived' | 'attachments'>
  & { updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type ArchiveMessageTemplateMutationVariables = Exact<{
  messageTemplateId: Scalars['String'];
  isArchived: Scalars['Boolean'];
}>;


export type ArchiveMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneMessageTemplate: (
    { __typename?: 'MessageTemplate' }
    & Pick<MessageTemplate, 'id' | 'updatedAt' | 'isArchived'>
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type CreateMessageTemplateMutationVariables = Exact<{
  data: MessageTemplateCreateInput;
}>;


export type CreateMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createOneMessageTemplate: (
    { __typename?: 'MessageTemplate' }
    & FullMessageTemplateFragment
  ) }
);

export type SaveMessageTemplateMutationVariables = Exact<{
  where: MessageTemplateWhereUniqueInput;
  data: MessageTemplateUpdateInput;
}>;


export type SaveMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneMessageTemplate: (
    { __typename?: 'MessageTemplate' }
    & Pick<MessageTemplate, 'id' | 'updatedAt' | 'name' | 'body' | 'isArchived'>
    & { updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  ) }
);

export type GetMessageTemplatesQueryVariables = Exact<{
  where?: Maybe<MessageTemplateWhereInput>;
  orderBy?: Maybe<Array<MessageTemplateOrderByWithRelationAndSearchRelevanceInput> | MessageTemplateOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type GetMessageTemplatesQuery = (
  { __typename?: 'Query' }
  & { findManyMessageTemplate?: Maybe<Array<(
    { __typename?: 'MessageTemplate' }
    & FullMessageTemplateFragment
  )>> }
);

export type CreateOneEmailCampaignMutationVariables = Exact<{
  data: EmailCampaignCreateInput;
}>;


export type CreateOneEmailCampaignMutation = (
  { __typename?: 'Mutation' }
  & { createOneEmailCampaign: (
    { __typename?: 'EmailCampaign' }
    & EmailCampaignFragment
  ) }
);

export type DeleteEmailCampaignCustomListMutationVariables = Exact<{
  data: DeleteEmailCampaignCustomListInput;
}>;


export type DeleteEmailCampaignCustomListMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmailCampaignCustomList?: Maybe<(
    { __typename?: 'EmailCampaignCustomList' }
    & Pick<EmailCampaignCustomList, 'id'>
  )> }
);

export type DuplicateEmailCampaignMutationVariables = Exact<{
  data: DuplicateEmailCampaignInput;
}>;


export type DuplicateEmailCampaignMutation = (
  { __typename?: 'Mutation' }
  & { duplicateEmailCampaign?: Maybe<(
    { __typename?: 'EmailCampaign' }
    & Pick<EmailCampaign, 'id'>
  )> }
);

export type FindManyAppointmentTypeQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type FindManyAppointmentTypeQuery = (
  { __typename?: 'Query' }
  & { findManyAppointmentType?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & AppointmentTypeWorkflowsSelectionFragment
  )>> }
);

export type FindManyClinicPetParentCountQueryVariables = Exact<{
  where: ClinicPetParentWhereInput;
}>;


export type FindManyClinicPetParentCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyClinicPetParentCount'>
);

export type FindManyEmailCampaignQueryVariables = Exact<{
  where?: Maybe<EmailCampaignWhereInput>;
}>;


export type FindManyEmailCampaignQuery = (
  { __typename?: 'Query' }
  & { findManyEmailCampaign?: Maybe<Array<(
    { __typename?: 'EmailCampaign' }
    & EmailCampaignFragment
  )>> }
);

export type EmailCampaignFragment = (
  { __typename?: 'EmailCampaign' }
  & Pick<EmailCampaign, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'name' | 'subject' | 'from' | 'status' | 'htmlContent' | 'unlayerJson' | 'segmentArgs' | 'totalSegmentContacts' | 'replyToJson' | 'segmentationType'>
  & { createdByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nameDisplay' | 'email'>
  )>, sentByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nameDisplay' | 'email'>
  )>, lastUpdatedByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nameDisplay' | 'email'>
  )>, customList?: Maybe<(
    { __typename?: 'EmailCampaignCustomList' }
    & Pick<EmailCampaignCustomList, 'id' | 'awsFileKey' | 'fileName' | 'validRecipients' | 'invalidRecipients'>
  )>, uploads: Array<(
    { __typename?: 'EmailCampaignCustomListUploadHistory' }
    & Pick<EmailCampaignCustomListUploadHistory, 'id' | 'expiresAt' | 'fileName' | 'awsFileKey'>
  )> }
);

export type FindManyEmailCampaignTemplateQueryVariables = Exact<{
  where: EmailCampaignTemplateWhereInput;
}>;


export type FindManyEmailCampaignTemplateQuery = (
  { __typename?: 'Query' }
  & { findManyEmailCampaignTemplate?: Maybe<Array<(
    { __typename?: 'EmailCampaignTemplate' }
    & EmailCampaignTemplateFragment
  )>> }
);

export type EmailCampaignTemplateFragment = (
  { __typename?: 'EmailCampaignTemplate' }
  & Pick<EmailCampaignTemplate, 'id' | 'name' | 'htmlContent' | 'unlayerJson'>
);

export type CanSendEmailCampaignQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type CanSendEmailCampaignQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canSendEmailCampaign'>
);

export type GetEmailCampaignCustomListUrlQueryVariables = Exact<{
  data: EmailCampaignCustomListUrlInput;
}>;


export type GetEmailCampaignCustomListUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailCampaignCustomListUrl'>
);

export type GetEmailCampaignSegmentCountQueryVariables = Exact<{
  clinicId: Scalars['String'];
  emailCampaignId?: Maybe<Scalars['String']>;
}>;


export type GetEmailCampaignSegmentCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailCampaignSegmentCount'>
);

export type SendEmailCampaignNowMutationVariables = Exact<{
  data: SendEmailCampaignNowInput;
}>;


export type SendEmailCampaignNowMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailCampaignNow?: Maybe<(
    { __typename?: 'sentEmailCampaign' }
    & Pick<SentEmailCampaign, 'id' | 'time'>
  )> }
);

export type UpdateOneEmailCampaignMutationVariables = Exact<{
  data: EmailCampaignUpdateInput;
  where: EmailCampaignWhereUniqueInput;
}>;


export type UpdateOneEmailCampaignMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEmailCampaign: (
    { __typename?: 'EmailCampaign' }
    & EmailCampaignFragment
  ) }
);

export type UploadEmailCampaignCustomListMutationVariables = Exact<{
  data: UploadEmailCampaignCustomListInput;
}>;


export type UploadEmailCampaignCustomListMutation = (
  { __typename?: 'Mutation' }
  & { uploadEmailCampaignCustomList?: Maybe<(
    { __typename?: 'EmailCampaignCustomListResponse' }
    & { customList?: Maybe<(
      { __typename?: 'EmailCampaignCustomList' }
      & Pick<EmailCampaignCustomList, 'id' | 'awsFileKey' | 'fileName' | 'validRecipients' | 'invalidRecipients'>
    )>, customListHistory?: Maybe<(
      { __typename?: 'EmailCampaignCustomListUploadHistory' }
      & Pick<EmailCampaignCustomListUploadHistory, 'id' | 'expiresAt' | 'fileName' | 'awsFileKey'>
    )> }
  )> }
);

export type GetQuickSightDashboardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuickSightDashboardsQuery = (
  { __typename?: 'Query' }
  & { getQuickSightDashboards?: Maybe<Array<Maybe<(
    { __typename?: 'DashboardSummary' }
    & Pick<DashboardSummary, 'Name' | 'PublishedVersionNumber' | 'DashboardId'>
  )>>> }
);

export type GetQuickSightEmbedUrlQueryVariables = Exact<{
  where: GetQuickSightEmbedUrlInput;
}>;


export type GetQuickSightEmbedUrlQuery = (
  { __typename?: 'Query' }
  & { getQuickSightEmbedURL?: Maybe<(
    { __typename?: 'EmbedURL' }
    & Pick<EmbedUrl, 'Status' | 'EmbedUrl' | 'RequestId'>
  )> }
);

export type GetSignedDownloadUrlMutationVariables = Exact<{
  assetUrl: Scalars['String'];
}>;


export type GetSignedDownloadUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getSignedDownloadUrl'>
);

export type CreateOnePayoutBatchingPeriodMutationVariables = Exact<{
  data: PayoutBatchingPeriodCreateInput;
}>;


export type CreateOnePayoutBatchingPeriodMutation = (
  { __typename?: 'Mutation' }
  & { createOnePayoutBatchingPeriod: (
    { __typename?: 'PayoutBatchingPeriod' }
    & Pick<PayoutBatchingPeriod, 'id' | 'createdAt' | 'updatedAt' | 'startsAt' | 'endsAt'>
  ) }
);

export type ResendReceiptEmailMutationVariables = Exact<{
  intentId: Scalars['String'];
  emailOverride?: Maybe<Scalars['String']>;
}>;


export type ResendReceiptEmailMutation = (
  { __typename?: 'Mutation' }
  & { resendReceiptEmail: (
    { __typename?: 'ResendReceiptEmailOutput' }
    & Pick<ResendReceiptEmailOutput, 'success' | 'error' | 'jobChainId'>
  ) }
);

export type RetryPaymentWritebackMutationVariables = Exact<{
  stripePaymentIntentId: Scalars['String'];
}>;


export type RetryPaymentWritebackMutation = (
  { __typename?: 'Mutation' }
  & { retryPaymentWriteback?: Maybe<(
    { __typename?: 'RetryPaymentWritebackOutput' }
    & Pick<RetryPaymentWritebackOutput, 'success' | 'error' | 'jobChainId'>
  )> }
);

export type CheckCanManuallyRetryPaymentWritebackQueryVariables = Exact<{
  paymentIntentId: Scalars['String'];
}>;


export type CheckCanManuallyRetryPaymentWritebackQuery = (
  { __typename?: 'Query' }
  & { canManuallyRetryPaymentWriteback: (
    { __typename?: 'CanManuallyRetryPaymentWritebackOutput' }
    & Pick<CanManuallyRetryPaymentWritebackOutput, 'retryable' | 'reason'>
  ) }
);

export type FindFirstPayoutBatchingPeriodQueryVariables = Exact<{
  where?: Maybe<PayoutBatchingPeriodWhereInput>;
}>;


export type FindFirstPayoutBatchingPeriodQuery = (
  { __typename?: 'Query' }
  & { findManyPayoutBatchingPeriod?: Maybe<Array<(
    { __typename?: 'PayoutBatchingPeriod' }
    & PayoutBatchingPeriodFragment
  )>> }
);

export type PayoutBatchingPeriodFragment = (
  { __typename?: 'PayoutBatchingPeriod' }
  & Pick<PayoutBatchingPeriod, 'id' | 'createdAt' | 'updatedAt' | 'startsAt' | 'endsAt' | 'createdByUserId' | 'clinicId'>
);

export type FindMissingPayoutBatchingPeriodDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindMissingPayoutBatchingPeriodDatesQuery = (
  { __typename?: 'Query' }
  & { findMissingPayoutBatchingPeriodDates: (
    { __typename?: 'FindMissingBatchingPeriodDatesOutput' }
    & Pick<FindMissingBatchingPeriodDatesOutput, 'success' | 'error' | 'dates'>
  ) }
);

export type GetStripeReceiptUrlQueryVariables = Exact<{
  intentId: Scalars['String'];
}>;


export type GetStripeReceiptUrlQuery = (
  { __typename?: 'Query' }
  & { getStripePaymentIntentData?: Maybe<(
    { __typename?: 'StripePaymentOutput' }
    & { payment: (
      { __typename?: 'StripePayment' }
      & Pick<StripePayment, 'receiptUrl'>
    ) }
  )> }
);

export type RequestBitwerxIntegrationMutationVariables = Exact<{
  data: RequestBitwerxIntegrationInput;
}>;


export type RequestBitwerxIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { requestBitwerxIntegration?: Maybe<(
    { __typename?: 'ClinicPimsIntegration' }
    & Pick<ClinicPimsIntegration, 'id'>
  )> }
);

export type UpsertPetPortalSettingsMutationVariables = Exact<{
  where: PetPortalSettingWhereUniqueInput;
  create: PetPortalSettingCreateInput;
  update: PetPortalSettingUpdateInput;
}>;


export type UpsertPetPortalSettingsMutation = (
  { __typename?: 'Mutation' }
  & { upsertOnePetPortalSetting?: Maybe<(
    { __typename?: 'PetPortalSetting' }
    & Pick<PetPortalSetting, 'id' | 'clinicSettingId' | 'showAppointments'>
  )> }
);

export type JobChainCompletedSubscriptionVariables = Exact<{
  jobChainId: Scalars['String'];
}>;


export type JobChainCompletedSubscription = (
  { __typename?: 'Subscription' }
  & { jobChainCompleted?: Maybe<(
    { __typename?: 'JobChainSubscritptionPayload' }
    & Pick<JobChainSubscritptionPayload, 'jobChainId' | 'payload' | 'error' | 'success'>
  )> }
);

export type JobChainStatusQueryVariables = Exact<{
  jobChainId: Scalars['String'];
}>;


export type JobChainStatusQuery = (
  { __typename?: 'Query' }
  & { jobChainStatus?: Maybe<(
    { __typename?: 'JobChainStatus' }
    & Pick<JobChainStatus, 'status' | 'payload' | 'error' | 'queuedAt' | 'completedAt' | 'erroredAt'>
  )> }
);

export type StripeCreateCustomerPortalQueryVariables = Exact<{
  data?: Maybe<StripeCustomerPortalInput>;
}>;


export type StripeCreateCustomerPortalQuery = (
  { __typename?: 'Query' }
  & { stripeCreateCustomerPortal?: Maybe<(
    { __typename?: 'StripeCustomerPortalOutput' }
    & Pick<StripeCustomerPortalOutput, 'id' | 'url'>
  )> }
);

export type TriggerMessageEventMutationVariables = Exact<{
  useSubscription?: Maybe<Scalars['Boolean']>;
  event: Scalars['String'];
  queueName?: Maybe<SqsQueueNames>;
  data?: Maybe<Scalars['Json']>;
}>;


export type TriggerMessageEventMutation = (
  { __typename?: 'Mutation' }
  & { triggerMessageEvent?: Maybe<(
    { __typename?: 'MessageEventResponse' }
    & Pick<MessageEventResponse, 'success' | 'jobChainId'>
  )> }
);

export type ExclusionRuleSelectionFragment = (
  { __typename?: 'DirectBookingExclusionRule' }
  & Pick<DirectBookingExclusionRule, 'id' | 'name' | 'isActive'>
  & { appointmentTypes: Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'name' | 'displayName'>
  )>, providers: Array<(
    { __typename?: 'ClinicEmployee' }
    & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>
  )>, timeSlots: Array<(
    { __typename?: 'DirectBookingExclusionTimeSlot' }
    & Pick<DirectBookingExclusionTimeSlot, 'id' | 'updatedAt' | 'dayOfWeek' | 'startTime' | 'endTime'>
  )> }
);

export type DeleteDirectBookingExclusionRuleMutationVariables = Exact<{
  ruleId: Scalars['String'];
}>;


export type DeleteDirectBookingExclusionRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneDirectBookingExclusionRule?: Maybe<(
    { __typename?: 'DirectBookingExclusionRule' }
    & Pick<DirectBookingExclusionRule, 'id'>
  )> }
);

export type UpdateDirectBookingExclusionRuleMutationVariables = Exact<{
  ruleId: Scalars['String'];
  data: DirectBookingExclusionRuleUpdateInput;
}>;


export type UpdateDirectBookingExclusionRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateOneDirectBookingExclusionRule: (
    { __typename?: 'DirectBookingExclusionRule' }
    & ExclusionRuleSelectionFragment
  ) }
);

export type UpsertDirectBookingSettingsMutationVariables = Exact<{
  where: ClinicDirectBookingSettingWhereUniqueInput;
  create: ClinicDirectBookingSettingCreateInput;
  update: ClinicDirectBookingSettingUpdateInput;
}>;


export type UpsertDirectBookingSettingsMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneClinicDirectBookingSetting?: Maybe<(
    { __typename?: 'ClinicDirectBookingSetting' }
    & Pick<ClinicDirectBookingSetting, 'id' | 'exclusionRulesEnabled' | 'appointmentIntervalInMinutesDisplay'>
  )> }
);

export type UpsertDirectBookingExclusionRuleMutationVariables = Exact<{
  where: DirectBookingExclusionRuleWhereUniqueInput;
  create: DirectBookingExclusionRuleCreateInput;
  update: DirectBookingExclusionRuleUpdateInput;
}>;


export type UpsertDirectBookingExclusionRuleMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneDirectBookingExclusionRule?: Maybe<(
    { __typename?: 'DirectBookingExclusionRule' }
    & ExclusionRuleSelectionFragment
  )> }
);

export type GetClinicTimezoneNameQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicTimezoneNameQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'timezoneName'>
  )> }
);

export type GetDirectBookingAppointmentTypesQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetDirectBookingAppointmentTypesQuery = (
  { __typename?: 'Query' }
  & { findManyAppointmentType?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'name'>
  )>> }
);

export type GetDirectBookingExclusionsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetDirectBookingExclusionsQuery = (
  { __typename?: 'Query' }
  & { findManyDirectBookingExclusionRule?: Maybe<Array<(
    { __typename?: 'DirectBookingExclusionRule' }
    & ExclusionRuleSelectionFragment
  )>> }
);

export type GetDirectBookingProvidersQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetDirectBookingProvidersQuery = (
  { __typename?: 'Query' }
  & { findManyClinicEmployee?: Maybe<Array<(
    { __typename?: 'ClinicEmployee' }
    & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>
  )>> }
);

export type GetClinicDirectBookingSettingsQueryVariables = Exact<{
  where: ClinicDirectBookingSettingWhereInput;
}>;


export type GetClinicDirectBookingSettingsQuery = (
  { __typename?: 'Query' }
  & { findFirstClinicDirectBookingSetting?: Maybe<(
    { __typename?: 'ClinicDirectBookingSetting' }
    & Pick<ClinicDirectBookingSetting, 'id' | 'exclusionRulesEnabled' | 'appointmentIntervalInMinutesDisplay'>
  )> }
);

export type UpdateAppointmentTypeOrderMutationVariables = Exact<{
  data?: Maybe<Array<AppointmentTypeWithOrder> | AppointmentTypeWithOrder>;
}>;


export type UpdateAppointmentTypeOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateManyAppointmentTypeSortOrder: (
    { __typename?: 'AppointmentTypeWithOrderResponse' }
    & Pick<AppointmentTypeWithOrderResponse, 'success' | 'error'>
  ) }
);

export type UpdateAppointmentTypeProviderRoomMapsMutationVariables = Exact<{
  data: UpdateAppointmentTypeProviderRoomMapsInput;
}>;


export type UpdateAppointmentTypeProviderRoomMapsMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointmentTypeProviderRoomMaps: (
    { __typename?: 'UpdateAppointmentTypeProviderRoomMapsResponse' }
    & Pick<UpdateAppointmentTypeProviderRoomMapsResponse, 'success' | 'count'>
  ) }
);

export type UpdateOneAppointmentTypeMutationVariables = Exact<{
  where: AppointmentTypeWhereUniqueInput;
  data: AppointmentTypeUpdateInput;
}>;


export type UpdateOneAppointmentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateOneAppointmentType: (
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id'>
  ) }
);

export type ProviderFragment = (
  { __typename?: 'ClinicEmployee' }
  & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName' | 'pimsId'>
);

export type AppointmentTypeFragment = (
  { __typename?: 'AppointmentType' }
  & Pick<AppointmentType, 'id' | 'name' | 'displayName' | 'displayOrder' | 'showInPetPortal' | 'description' | 'isAvailableForDirectBooking' | 'defaultDurationInMinutes' | 'defaultSoonestAvailableBookingInHours' | 'delayedStartTime'>
  & { directBookingAppointmentTypeSetting?: Maybe<(
    { __typename?: 'DirectBookingAppointmentTypeSetting' }
    & Pick<DirectBookingAppointmentTypeSetting, 'id' | 'clientType'>
  )>, clinicEmployeeAppointmentTypeSettings: Array<(
    { __typename?: 'ClinicEmployeeAppointmentTypeSetting' }
    & { clinicEmployee: (
      { __typename?: 'ClinicEmployee' }
      & ProviderFragment
    ) }
  )> }
);

export type RoomFragment = (
  { __typename?: 'ClinicRoom' }
  & Pick<ClinicRoom, 'id' | 'name' | 'type'>
);

export type GetAppointmentSettingsQueryVariables = Exact<{
  clinicSettingId: Scalars['String'];
  clinicId: Scalars['String'];
}>;


export type GetAppointmentSettingsQuery = (
  { __typename?: 'Query' }
  & { findUniquePetPortalSetting?: Maybe<(
    { __typename?: 'PetPortalSetting' }
    & Pick<PetPortalSetting, 'id' | 'clinicSettingId' | 'showAppointments'>
  )>, findManyAppointmentType?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & AppointmentTypeFragment
  )>>, findManyClinicEmployee?: Maybe<Array<(
    { __typename?: 'ClinicEmployee' }
    & ProviderFragment
  )>>, findManyClinicRoom?: Maybe<Array<(
    { __typename?: 'ClinicRoom' }
    & RoomFragment
  )>> }
);

export type GetProviderAppointmentTypeRoomMapQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetProviderAppointmentTypeRoomMapQuery = (
  { __typename?: 'Query' }
  & { findManyProviderAppointmentTypeRoomMap?: Maybe<Array<(
    { __typename?: 'ProviderAppointmentTypeRoomMap' }
    & Pick<ProviderAppointmentTypeRoomMap, 'id' | 'clinicEmployeeId' | 'appoinmentTypeId' | 'roomId'>
  )>> }
);

export type ClinicSettingsConversationsChannelStatusFragment = (
  { __typename?: 'ChannelStatus' }
  & Pick<ChannelStatus, 'id' | 'createdAt' | 'updatedAt' | 'channelStatusAction' | 'color' | 'deletedOn' | 'isDefault' | 'name' | 'order' | 'isExportedToPims'>
  & { channelStatusChannelAssignees: Array<(
    { __typename?: 'ChannelStatusChannelAssignee' }
    & ClinicSettingsConversationsChannelStatusChannelAssigneeFragment
  )> }
);

export type ClinicSettingsConversationsChannelStatusChannelAssigneeFragment = (
  { __typename?: 'ChannelStatusChannelAssignee' }
  & Pick<ChannelStatusChannelAssignee, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ClinicSettingsConversationsRuleFragment = (
  { __typename?: 'Rules' }
  & Pick<Rules, 'action' | 'clinicId' | 'createdAt' | 'id' | 'options' | 'updatedAt'>
);

export type CreateClinicSettingsConversationsChannelStatusMutationVariables = Exact<{
  clinicSettingId: Scalars['String'];
  channelStatusAction: ChannelStatusAction;
  color: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
}>;


export type CreateClinicSettingsConversationsChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { createOneChannelStatus: (
    { __typename?: 'ChannelStatus' }
    & ClinicSettingsConversationsChannelStatusFragment
  ) }
);

export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables = Exact<{
  data: RulesCreateInput;
}>;


export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation = (
  { __typename?: 'Mutation' }
  & { createOneRules: (
    { __typename?: 'Rules' }
    & ClinicSettingsConversationsRuleFragment
  ) }
);

export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables = Exact<{
  data: ChannelStatusChannelAssigneeCreateInput;
}>;


export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation = (
  { __typename?: 'Mutation' }
  & { createOneChannelStatusChannelAssignee: (
    { __typename?: 'ChannelStatusChannelAssignee' }
    & ClinicSettingsConversationsChannelStatusChannelAssigneeFragment
  ) }
);

export type DeleteClinicSettingsConversationsChannelStatusMutationVariables = Exact<{
  data: ChannelStatusUpdateInput;
  channelStatusId: Scalars['String'];
}>;


export type DeleteClinicSettingsConversationsChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannelStatus: (
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id'>
  ) }
);

export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables = Exact<{
  ruleId: Scalars['String'];
}>;


export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneRules?: Maybe<(
    { __typename?: 'Rules' }
    & Pick<Rules, 'id'>
  )> }
);

export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneChannelStatusChannelAssignee?: Maybe<(
    { __typename?: 'ChannelStatusChannelAssignee' }
    & Pick<ChannelStatusChannelAssignee, 'id'>
    & ClinicSettingsConversationsChannelStatusChannelAssigneeFragment
  )> }
);

export type OrderClinicSettingsConversationsChannelStatusMutationVariables = Exact<{
  data: ChannelStatusUpdateInput;
  channelStatusId: Scalars['String'];
}>;


export type OrderClinicSettingsConversationsChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannelStatus: (
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'order' | 'name'>
  ) }
);

export type UpdateClinicSettingsConversationsChannelStatusMutationVariables = Exact<{
  data: ChannelStatusUpdateInput;
  channelStatusId: Scalars['String'];
}>;


export type UpdateClinicSettingsConversationsChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannelStatus: (
    { __typename?: 'ChannelStatus' }
    & ClinicSettingsConversationsChannelStatusFragment
  ) }
);

export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables = Exact<{
  data: RulesUpdateInput;
  ruleId: Scalars['String'];
}>;


export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateOneRules: (
    { __typename?: 'Rules' }
    & ClinicSettingsConversationsRuleFragment
  ) }
);

export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutationVariables = Exact<{
  data?: Maybe<Array<ChannelStatusWithOrder> | ChannelStatusWithOrder>;
}>;


export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateManyChannelStatusSortOrder: (
    { __typename?: 'ChannelStatusWithOrderResponse' }
    & Pick<ChannelStatusWithOrderResponse, 'success' | 'error'>
  ) }
);

export type GetClinicSettingsClinicSettingIdQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type GetClinicSettingsClinicSettingIdQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { clinicSetting?: Maybe<(
      { __typename?: 'ClinicSetting' }
      & Pick<ClinicSetting, 'id'>
    )> }
  )> }
);

export type GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery = (
  { __typename?: 'Query' }
  & { findManyRules?: Maybe<Array<(
    { __typename?: 'Rules' }
    & ClinicSettingsConversationsRuleFragment
  )>> }
);

export type GetClinicSettingsConversationsChannelStatusesQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicSettingsConversationsChannelStatusesQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & ClinicSettingsConversationsChannelStatusFragment
  )>> }
);

export type GetClinicSettingsConversationsChannelsWithStatusesQueryVariables = Exact<{
  where?: Maybe<ChannelWhereInput>;
}>;


export type GetClinicSettingsConversationsChannelsWithStatusesQuery = (
  { __typename?: 'Query' }
  & { findManyChannel?: Maybe<Array<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'updatedAt'>
    & { lastMessage?: Maybe<(
      { __typename?: 'ChannelMessage' }
      & Pick<ChannelMessage, 'createdAt'>
    )> }
  )>> }
);

export type GetAutomationOverviewQueryVariables = Exact<{
  clinicId?: Maybe<Scalars['String']>;
}>;


export type GetAutomationOverviewQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name' | 'isPublished' | 'event' | 'visitType' | 'automatedTriggerType' | 'automatedTriggerInMinutes' | 'sendingHours' | 'notificationBody'>
    & { appointmentTypes: Array<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'name'>
    )>, clinicEmployees: Array<(
      { __typename?: 'ClinicEmployeeToWorkflowEventSetting' }
      & { clinicEmployee: (
        { __typename?: 'ClinicEmployee' }
        & Pick<ClinicEmployee, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, clinicRooms: Array<(
      { __typename?: 'ClinicRoomToWorkflowEventSetting' }
      & { clinicRoom: (
        { __typename?: 'ClinicRoom' }
        & Pick<ClinicRoom, 'id' | 'name'>
      ) }
    )>, conditionSets: Array<(
      { __typename?: 'ConditionSet' }
      & { conditions: Array<(
        { __typename?: 'Condition' }
        & Pick<Condition, 'id' | 'attribute' | 'operand'>
      )> }
    )>, actions: Array<(
      { __typename?: 'WorkflowEventAction' }
      & Pick<WorkflowEventAction, 'id' | 'order' | 'promptType' | 'config'>
      & { channelStatusChange?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'name'>
      )> }
    )> }
  )>> }
);

export type FindAutomationsForVisualizerQueryVariables = Exact<{
  where?: Maybe<WorkflowEventSettingWhereInput>;
}>;


export type FindAutomationsForVisualizerQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & VisualizerAutomationFragment
  )>> }
);

export type VisualizerAutomationFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id' | 'name'>
  & { actions: Array<(
    { __typename?: 'WorkflowEventAction' }
    & VisualizerAutomationActionFragment
  )> }
);

export type VisualizerAutomationActionFragment = (
  { __typename?: 'WorkflowEventAction' }
  & Pick<WorkflowEventAction, 'id' | 'createdAt' | 'updatedAt' | 'actionType' | 'config' | 'order' | 'triggerWorkflowId' | 'workflowEventTriggerActionNumber' | 'workflowEventSettingId' | 'channelStatusChangeId' | 'responseChannelStatusChangeId' | 'promptType'>
);

export type FindFormTemplatesForVisualizerQueryVariables = Exact<{
  where?: Maybe<FormTemplateWhereInput>;
}>;


export type FindFormTemplatesForVisualizerQuery = (
  { __typename?: 'Query' }
  & { findManyFormTemplate?: Maybe<Array<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'title'>
  )>> }
);

export type FindChannelStatusForVisualizerQueryVariables = Exact<{
  where?: Maybe<ChannelStatusWhereInput>;
}>;


export type FindChannelStatusForVisualizerQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'name'>
  )>> }
);

export type EmergencyClinicsFragment = (
  { __typename?: 'EmergencyClinics' }
  & Pick<EmergencyClinics, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'phone' | 'clinicSettingId' | 'isDeleted' | 'fullAddress' | 'displayOrder'>
);

export type CreateEmergencyClinicMutationVariables = Exact<{
  data: EmergencyClinicsCreateInput;
}>;


export type CreateEmergencyClinicMutation = (
  { __typename?: 'Mutation' }
  & { createOneEmergencyClinics: (
    { __typename?: 'EmergencyClinics' }
    & EmergencyClinicsFragment
  ) }
);

export type SoftDeleteEmergencyClinicMutationVariables = Exact<{
  where: EmergencyClinicsWhereUniqueInput;
}>;


export type SoftDeleteEmergencyClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEmergencyClinics: (
    { __typename?: 'EmergencyClinics' }
    & Pick<EmergencyClinics, 'id'>
  ) }
);

export type UpdateClinicSettingsClinicMutationVariables = Exact<{
  data: ClinicUpdateInput;
  id: Scalars['String'];
}>;


export type UpdateClinicSettingsClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'timezoneName' | 'name' | 'logo' | 'phone' | 'fullAddress' | 'websiteUrl' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'country' | 'city' | 'state'>
  ) }
);

export type UpdateClinicSettingsOutOfOfficeInformationMutationVariables = Exact<{
  data: ClinicSettingUpdateInput;
  id?: Maybe<Scalars['String']>;
}>;


export type UpdateClinicSettingsOutOfOfficeInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicSetting: (
    { __typename?: 'ClinicSetting' }
    & Pick<ClinicSetting, 'id' | 'outOfOfficeHeadline' | 'outOfOfficeEnabled' | 'outOfOfficeShowScheduleEnabled' | 'emergencyContactsEnabled'>
  ) }
);

export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationVariables = Exact<{
  data: TwilioVerifyCallerIdInput;
}>;


export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation = (
  { __typename?: 'Mutation' }
  & { twilioVerifyCallerId?: Maybe<(
    { __typename?: 'TwilioCallerIdVerification' }
    & Pick<TwilioCallerIdVerification, 'callSid' | 'validationCode'>
  )> }
);

export type UpdateEmergencyClinicMutationVariables = Exact<{
  where: EmergencyClinicsWhereUniqueInput;
  data: EmergencyClinicsUpdateInput;
}>;


export type UpdateEmergencyClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEmergencyClinics: (
    { __typename?: 'EmergencyClinics' }
    & EmergencyClinicsFragment
  ) }
);

export type UpdateEmergencyClinicDisplayOrderMutationVariables = Exact<{
  where: EmergencyClinicsWhereUniqueInput;
  data: Scalars['Int'];
}>;


export type UpdateEmergencyClinicDisplayOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEmergencyClinics: (
    { __typename?: 'EmergencyClinics' }
    & Pick<EmergencyClinics, 'id'>
  ) }
);

export type FindEmployerIdentificationNumberQueryVariables = Exact<{
  data: FindEmployerIdentificationNumberInput;
}>;


export type FindEmployerIdentificationNumberQuery = (
  { __typename?: 'Query' }
  & { findEmployerIdentificationNumber?: Maybe<Array<Maybe<(
    { __typename?: 'FindEmployerIdentificationNumberResponse' }
    & Pick<FindEmployerIdentificationNumberResponse, 'ein' | 'companyName' | 'address' | 'companyPhone' | 'url' | 'businessName' | 'error'>
  )>>> }
);

export type ClinicOfficeHourSelectionFragment = (
  { __typename?: 'ClinicOfficeHour' }
  & Pick<ClinicOfficeHour, 'id' | 'closeAt' | 'dayOfWeek' | 'isEnabled' | 'isOpenTwentyFourHours' | 'openAt' | 'type'>
);

export type GetClinicSettingsGeneralQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetClinicSettingsGeneralQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'country' | 'fullAddress' | 'logo' | 'name' | 'phone' | 'clinicEmail' | 'postalCode' | 'state' | 'timezoneName' | 'websiteUrl' | 'isCallerIdVerified'>
    & { clinicOfficeHours: Array<(
      { __typename?: 'ClinicOfficeHour' }
      & ClinicOfficeHourSelectionFragment
    )>, clinicSetting?: Maybe<(
      { __typename?: 'ClinicSetting' }
      & Pick<ClinicSetting, 'id' | 'outOfOfficeEnabled' | 'outOfOfficeHeadline' | 'outOfOfficeShowScheduleEnabled' | 'emergencyContactsEnabled'>
      & { emergencyClinics: Array<(
        { __typename?: 'EmergencyClinics' }
        & EmergencyClinicsFragment
      )> }
    )>, twilioVerifications: Array<(
      { __typename?: 'TwilioVerification' }
      & Pick<TwilioVerification, 'id' | 'phoneNumber' | 'status'>
    )> }
  )> }
);

export type FindClinicLegalEntityQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type FindClinicLegalEntityQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { legalEntity?: Maybe<(
      { __typename?: 'LegalEntity' }
      & LegalEntityFragment
    )> }
  )> }
);

export type LegalEntityFragment = (
  { __typename?: 'LegalEntity' }
  & Pick<LegalEntity, 'id' | 'ein' | 'type' | 'name'>
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
  )> }
);

export type UpsertOneLegalEntityMutationVariables = Exact<{
  where: LegalEntityWhereUniqueInput;
  create: LegalEntityCreateInput;
  update: LegalEntityUpdateInput;
}>;


export type UpsertOneLegalEntityMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneLegalEntity?: Maybe<(
    { __typename?: 'LegalEntity' }
    & LegalEntityFragment
  )> }
);

export type SubscribeToClinicSettingsGeneralTwilioVerificationSubscriptionVariables = Exact<{
  where: SubscriptionTwilioVerificationWhereInput;
}>;


export type SubscribeToClinicSettingsGeneralTwilioVerificationSubscription = (
  { __typename?: 'Subscription' }
  & { twilioVerificationChanged: (
    { __typename?: 'TwilioVerificationSubscription' }
    & { node: (
      { __typename?: 'TwilioVerification' }
      & Pick<TwilioVerification, 'status'>
    ) }
  ) }
);

export type ClinicSettingsNotificationsUserSettingsFragment = (
  { __typename?: 'UserSetting' }
  & Pick<UserSetting, 'id' | 'snoozeNotificationsUntil'>
  & { browserNotificationFilter?: Maybe<(
    { __typename?: 'ChannelSelection' }
    & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
    & { assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>, notificationSettings: Array<(
    { __typename?: 'UserNotificationSetting' }
    & Pick<UserNotificationSetting, 'id' | 'emailEnabled' | 'smsEnabled' | 'soundNotificationUrl' | 'browserNotificationEnabled' | 'pushNotificationEnabled'>
  )> }
);

export type UpdateClinicSettingsNotificationsUserSettingMutationVariables = Exact<{
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
}>;


export type UpdateClinicSettingsNotificationsUserSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUserSetting: (
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'id' | 'snoozeNotificationsUntil'>
    & { browserNotificationFilter?: Maybe<(
      { __typename?: 'ChannelSelection' }
      & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
      & { assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>, notificationSettings: Array<(
      { __typename?: 'UserNotificationSetting' }
      & Pick<UserNotificationSetting, 'id' | 'emailEnabled' | 'smsEnabled' | 'soundNotificationUrl' | 'browserNotificationEnabled' | 'pushNotificationEnabled'>
    )> }
  ) }
);

export type GetClinicSettingsNotificationsUserSettingsQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type GetClinicSettingsNotificationsUserSettingsQuery = (
  { __typename?: 'Query' }
  & { findUniqueUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firebaseId' | 'firstName' | 'lastName' | 'profilePic' | 'email'>
    & { userSetting?: Maybe<(
      { __typename?: 'UserSetting' }
      & Pick<UserSetting, 'id' | 'emailNotifications' | 'smsNotifications' | 'snoozeNotificationsUntil'>
      & { browserNotificationFilter?: Maybe<(
        { __typename?: 'ChannelSelection' }
        & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
        & { assignees: Array<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )> }
      )>, notificationSettings: Array<(
        { __typename?: 'UserNotificationSetting' }
        & Pick<UserNotificationSetting, 'id' | 'settingType' | 'emailEnabled' | 'smsEnabled' | 'pushNotificationEnabled' | 'soundNotificationUrl' | 'browserNotificationEnabled' | 'createdAt' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UpdateUserPreferencesMutationVariables = Exact<{
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
}>;


export type UpdateUserPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUserSetting: (
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'id' | 'enterAddsNewLine' | 'skipLoadingAnimation'>
  ) }
);

export type UserSettingsDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'namePrefix' | 'nameSuffix' | 'phone' | 'email' | 'firebaseId'>
  & { vetInfo?: Maybe<(
    { __typename?: 'VetInfo' }
    & Pick<VetInfo, 'id'>
  )> }
  & ClinicUserAvatarFragment
);

export type UpdateChangePasswordModalUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateChangePasswordModalUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'namePrefix' | 'nameSuffix' | 'email' | 'phone' | 'isNewPasswordRequired'>
  ) }
);

export type UpdateClinicSettingsUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateClinicSettingsUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profilePic'>
  ) }
);

export type GetClinicSettingsUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type GetClinicSettingsUserQuery = (
  { __typename?: 'Query' }
  & { findUniqueUser?: Maybe<(
    { __typename?: 'User' }
    & UserSettingsDataFragment
  )> }
);

export type EncryptAndUploadDataMutationVariables = Exact<{
  body: Scalars['String'];
  purpose: EncryptedSignedUploadPurpose;
  extension: Scalars['String'];
}>;


export type EncryptAndUploadDataMutation = (
  { __typename?: 'Mutation' }
  & { encryptAndUploadData: (
    { __typename?: 'EncryptedUploadResponse' }
    & Pick<EncryptedUploadResponse, 'success'>
  ) }
);

export type GenerateTerminalSetupPinMutationVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GenerateTerminalSetupPinMutation = (
  { __typename?: 'Mutation' }
  & { generateTerminalSetupPin?: Maybe<(
    { __typename?: 'GenerateTerminalSetupPinResponse' }
    & Pick<GenerateTerminalSetupPinResponse, 'pin'>
  )> }
);

export type UpsertOneBitwerxPaymentWritebackConfigurationMutationVariables = Exact<{
  where: BitwerxPaymentWritebackConfigurationWhereUniqueInput;
  create: BitwerxPaymentWritebackConfigurationCreateInput;
  update: BitwerxPaymentWritebackConfigurationUpdateInput;
}>;


export type UpsertOneBitwerxPaymentWritebackConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneBitwerxPaymentWritebackConfiguration?: Maybe<(
    { __typename?: 'BitwerxPaymentWritebackConfiguration' }
    & Pick<BitwerxPaymentWritebackConfiguration, 'id' | 'integrationId' | 'enteredById' | 'defaultPimsPaymentTypeId' | 'visaPimsPaymentTypeId' | 'mastercardPimsPaymentTypeId' | 'amexPimsPaymentTypeId' | 'discoverPimsPaymentTypeId' | 'writebackManualPayment'>
  )> }
);

export type GetBitwerxPaymentWritebackConfigQueryVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type GetBitwerxPaymentWritebackConfigQuery = (
  { __typename?: 'Query' }
  & { findUniqueBitwerxPaymentWritebackConfiguration?: Maybe<(
    { __typename?: 'BitwerxPaymentWritebackConfiguration' }
    & Pick<BitwerxPaymentWritebackConfiguration, 'id' | 'integrationId' | 'enteredById' | 'defaultPimsPaymentTypeId' | 'visaPimsPaymentTypeId' | 'mastercardPimsPaymentTypeId' | 'amexPimsPaymentTypeId' | 'discoverPimsPaymentTypeId' | 'writebackManualPayment'>
  )> }
);

export type GetClinicEmployeesForPaymentSettingsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicEmployeesForPaymentSettingsQuery = (
  { __typename?: 'Query' }
  & { findManyClinicEmployee?: Maybe<Array<(
    { __typename?: 'ClinicEmployee' }
    & Pick<ClinicEmployee, 'pimsId' | 'isDeleted'>
    & ClinicEmployeeBaseSelectionFragment
  )>> }
);

export type PimsPaymentTypeSelectionFragment = (
  { __typename?: 'PimsPaymentType' }
  & Pick<PimsPaymentType, 'id' | 'pimsId' | 'isInactive' | 'isDeleted' | 'description'>
);

export type GetPimsPaymentTypesQueryVariables = Exact<{
  integrationId: Scalars['String'];
}>;


export type GetPimsPaymentTypesQuery = (
  { __typename?: 'Query' }
  & { findManyPimsPaymentType?: Maybe<Array<(
    { __typename?: 'PimsPaymentType' }
    & PimsPaymentTypeSelectionFragment
  )>> }
);

export type GetTerminalLocationCountQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetTerminalLocationCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyStripeTerminalLocationCount'>
);

export type ReminderSettingsServiceFragment = (
  { __typename?: 'Service' }
  & Pick<Service, 'id' | 'code' | 'name' | 'formTemplateId' | 'pimsName' | 'pimsId' | 'isPetPortal' | 'isPostcard' | 'reminderActivationDate'>
  & { reminderTimings: Array<(
    { __typename?: 'ServiceReminderTiming' }
    & ReminderSettingsServiceTimingFragment
  )> }
);

export type ReminderSettingsServiceTimingFragment = (
  { __typename?: 'ServiceReminderTiming' }
  & Pick<ServiceReminderTiming, 'id' | 'timingType' | 'offset' | 'offsetUnit' | 'hourOfDay' | 'minuteOfHour' | 'isEmail' | 'isSms' | 'isPush'>
);

export type ReminderSettingsWorkflowEventSettingFragment = (
  { __typename?: 'WorkflowEventSetting' }
  & Pick<WorkflowEventSetting, 'id'>
  & { actions: Array<(
    { __typename?: 'WorkflowEventAction' }
    & Pick<WorkflowEventAction, 'id' | 'config'>
    & { channelStatusChange?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & Pick<ChannelStatus, 'id' | 'name' | 'color'>
    )> }
  )> }
);

export type ToggleClinicIsServiceRemindersActiveMutationVariables = Exact<{
  clinicId: Scalars['String'];
  isServiceRemindersActive: Scalars['Boolean'];
}>;


export type ToggleClinicIsServiceRemindersActiveMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'isServiceRemindersActive'>
  ) }
);

export type UpdateClinicPostcardSettingMutationVariables = Exact<{
  where?: Maybe<UpdateClinicPostcardSettingWhereInput>;
  data: UpdateClinicPostcardSettingDataInput;
}>;


export type UpdateClinicPostcardSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateClinicPostcardSetting: (
    { __typename?: 'ClinicPostcardSetting' }
    & Pick<ClinicPostcardSetting, 'id'>
  ) }
);

export type UpdateReminderSettingsServiceMutationVariables = Exact<{
  where?: Maybe<ServiceWhereInput>;
  data: ServiceUpdateManyMutationInput;
}>;


export type UpdateReminderSettingsServiceMutation = (
  { __typename?: 'Mutation' }
  & { updateManyService?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdateReminderSettingsWorkflowEventSettingMutationVariables = Exact<{
  where: WorkflowEventSettingWhereUniqueInput;
  data: WorkflowEventSettingUpdateInput;
}>;


export type UpdateReminderSettingsWorkflowEventSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOneWorkflowEventSetting: (
    { __typename?: 'WorkflowEventSetting' }
    & ReminderSettingsWorkflowEventSettingFragment
  ) }
);

export type UpdateServicesTimingsMutationVariables = Exact<{
  where: ServiceWhereInput;
  data: Array<ServiceReminderTimingInput> | ServiceReminderTimingInput;
}>;


export type UpdateServicesTimingsMutation = (
  { __typename?: 'Mutation' }
  & { updateManyServicesAndTimings: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id'>
  )> }
);

export type ToggleServicesTimingsDeliveryOptionsMutationVariables = Exact<{
  where: ServiceWhereInput;
  data: ServiceReminderTimingDeliveryOptionInput;
}>;


export type ToggleServicesTimingsDeliveryOptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateManyTimingsDeliveryOptions: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id'>
  )> }
);

export type GetClinicIsServiceRemindersActiveQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicIsServiceRemindersActiveQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'isServiceRemindersActive'>
  )> }
);

export type GetClinicPostcardsSettingsQueryVariables = Exact<{
  where: ClinicPostcardSettingWhereUniqueInput;
}>;


export type GetClinicPostcardsSettingsQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPostcardSetting?: Maybe<(
    { __typename?: 'ClinicPostcardSetting' }
    & Pick<ClinicPostcardSetting, 'id' | 'isRemindersEnabled' | 'apiKey' | 'apiSecret'>
  )> }
);

export type GetClinicStripeInfoQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type GetClinicStripeInfoQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'stripeCustomerId' | 'stripeSubscriptionId'>
  )> }
);

export type GetReminderSettingsClinicServicesQueryVariables = Exact<{
  where?: Maybe<ServiceWhereInput>;
  orderBy?: Maybe<Array<ServiceOrderByWithRelationAndSearchRelevanceInput> | ServiceOrderByWithRelationAndSearchRelevanceInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetReminderSettingsClinicServicesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyServiceCount'>
  & { findManyService?: Maybe<Array<(
    { __typename?: 'Service' }
    & ReminderSettingsServiceFragment
  )>> }
);

export type GetReminderSettingsClinicServicesFormTemplateIdQueryVariables = Exact<{
  clinicId?: Maybe<Scalars['String']>;
}>;


export type GetReminderSettingsClinicServicesFormTemplateIdQuery = (
  { __typename?: 'Query' }
  & { findFirstFormTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'isDeleted' | 'isActive'>
  )> }
);

export type GetReminderSettingsClinicServicesTimingsQueryVariables = Exact<{
  where?: Maybe<ServiceReminderTimingWhereInput>;
}>;


export type GetReminderSettingsClinicServicesTimingsQuery = (
  { __typename?: 'Query' }
  & { findManyServiceReminderTiming?: Maybe<Array<(
    { __typename?: 'ServiceReminderTiming' }
    & ReminderSettingsServiceTimingFragment
  )>> }
);

export type GetReminderSettingsWorkflowEventSettingQueryVariables = Exact<{
  where: WorkflowEventSettingWhereInput;
}>;


export type GetReminderSettingsWorkflowEventSettingQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & ReminderSettingsWorkflowEventSettingFragment
  )>> }
);

export type ClinicSettingsTeamUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'namePrefix' | 'nameSuffix' | 'invitedAt' | 'invitationCode' | 'email' | 'phone'>
  & { vetInfo?: Maybe<(
    { __typename?: 'VetInfo' }
    & Pick<VetInfo, 'id'>
    & { roles: Array<(
      { __typename?: 'StaffRole' }
      & Pick<StaffRole, 'id' | 'role'>
    )> }
  )> }
  & ClinicUserAvatarFragment
);

export type DeleteClinicSettingsTeamUserMutationVariables = Exact<{
  data: RemoveUserFromClinicInput;
}>;


export type DeleteClinicSettingsTeamUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromClinic: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ResendUserInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  clinicId: Scalars['String'];
}>;


export type ResendUserInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendUserInvitation: (
    { __typename?: 'User' }
    & ClinicSettingsTeamUserFragment
  ) }
);

export type UpdateClinicSettingsTeamUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateClinicSettingsTeamUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'namePrefix' | 'nameSuffix' | 'email' | 'phone' | 'isNewPasswordRequired'>
  ) }
);

export type UpsertUserToClinicMutationVariables = Exact<{
  data: UpsertUserToClinicInput;
}>;


export type UpsertUserToClinicMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserToClinic: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type GetClinicSettingsTeamUsersQueryVariables = Exact<{
  where: UserWhereInput;
  clinicId: Scalars['String'];
}>;


export type GetClinicSettingsTeamUsersQuery = (
  { __typename?: 'Query' }
  & { findManyUser?: Maybe<Array<(
    { __typename?: 'User' }
    & ClinicSettingsTeamUserFragment
  )>> }
);

export type CreateOneClinicBlockedPhoneNumberMutationVariables = Exact<{
  data: ClinicBlockedPhoneNumberCreateInput;
}>;


export type CreateOneClinicBlockedPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { createOneClinicBlockedPhoneNumber: (
    { __typename?: 'ClinicBlockedPhoneNumber' }
    & ClinicBlockedPhoneNumberFragment
  ) }
);

export type ClinicBlockedPhoneNumberFragment = (
  { __typename?: 'ClinicBlockedPhoneNumber' }
  & Pick<ClinicBlockedPhoneNumber, 'id' | 'clinicId' | 'phoneNumber' | 'createdAt' | 'updatedAt' | 'notes'>
  & { blockedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nameDisplay' | 'firstName' | 'lastName'>
  )>, unblockedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nameDisplay' | 'firstName' | 'lastName'>
  )> }
);

export type FindManyClinicBlockedPhoneNumberQueryVariables = Exact<{
  where?: Maybe<ClinicBlockedPhoneNumberWhereInput>;
}>;


export type FindManyClinicBlockedPhoneNumberQuery = (
  { __typename?: 'Query' }
  & { findManyClinicBlockedPhoneNumber?: Maybe<Array<(
    { __typename?: 'ClinicBlockedPhoneNumber' }
    & ClinicBlockedPhoneNumberFragment
  )>> }
);

export type UpdateOneClinicBlockedPhoneNumberMutationVariables = Exact<{
  data: ClinicBlockedPhoneNumberUpdateInput;
  where: ClinicBlockedPhoneNumberWhereUniqueInput;
}>;


export type UpdateOneClinicBlockedPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicBlockedPhoneNumber: (
    { __typename?: 'ClinicBlockedPhoneNumber' }
    & ClinicBlockedPhoneNumberFragment
  ) }
);

export type CreateOneClinicPhoneNumberMutationVariables = Exact<{
  data: ClinicPhoneNumberCreateInput;
}>;


export type CreateOneClinicPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { createOneClinicPhoneNumber: (
    { __typename?: 'ClinicPhoneNumber' }
    & ClinicPhoneNumberFragment
  ) }
);

export type OrderBandwidthPhoneNumberMutationVariables = Exact<{
  data: OrderPhoneNumberInput;
}>;


export type OrderBandwidthPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { orderPhoneNumber?: Maybe<(
    { __typename?: 'ClinicPhoneNumber' }
    & ClinicPhoneNumberFragment
  )> }
);

export type UpdateClinicSettingsConversationsSmsMutationVariables = Exact<{
  where: ClinicWhereUniqueInput;
  data: ClinicUpdateInput;
}>;


export type UpdateClinicSettingsConversationsSmsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'hasSmsConversations'>
  ) }
);

export type UpdateClinicSettingsConversationsSmsAutoResponseMutationVariables = Exact<{
  where: ClinicSettingWhereUniqueInput;
  data: ClinicSettingUpdateInput;
}>;


export type UpdateClinicSettingsConversationsSmsAutoResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicSetting: (
    { __typename?: 'ClinicSetting' }
    & Pick<ClinicSetting, 'id' | 'smsAutoResponseWhenOpen' | 'smsAutoResponseWhenClosed'>
  ) }
);

export type UpdateClinicSettingsConversationsTransferTwilioNumberMutationVariables = Exact<{
  phoneNumberSid: Scalars['String'];
  fromSubaccountSid?: Maybe<Scalars['String']>;
}>;


export type UpdateClinicSettingsConversationsTransferTwilioNumberMutation = (
  { __typename?: 'Mutation' }
  & { transferTwilioNumber?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success' | 'payload'>
  )> }
);

export type GetClinicPhoneNumbersQueryVariables = Exact<{
  where?: Maybe<ClinicPhoneNumberWhereInput>;
}>;


export type GetClinicPhoneNumbersQuery = (
  { __typename?: 'Query' }
  & { findManyClinicPhoneNumber?: Maybe<Array<(
    { __typename?: 'ClinicPhoneNumber' }
    & ClinicPhoneNumberFragment
  )>> }
);

export type ClinicPhoneNumberFragment = (
  { __typename?: 'ClinicPhoneNumber' }
  & Pick<ClinicPhoneNumber, 'id' | 'capability' | 'provider' | 'isPrimary' | 'isActive' | 'isVerified' | 'isDeleted' | 'number' | 'status' | 'loaSignedAt' | 'loaSignedByUserId' | 'loaUrl' | 'incomingCarrier'>
);

export type GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClinicSettingsConversationsAllActiveTwilioNumbersQuery = (
  { __typename?: 'Query' }
  & { getTwilioNumbers?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success' | 'payload'>
  )> }
);

export type GetClinicSettingsConversationsSmsQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  clinicId: Scalars['ID'];
}>;


export type GetClinicSettingsConversationsSmsQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'hasSmsConversations'>
    & { twilioConfiguration?: Maybe<(
      { __typename?: 'TwilioConfiguration' }
      & Pick<TwilioConfiguration, 'id' | 'subaccountSid' | 'messagingServiceSid'>
    )>, clinicSetting?: Maybe<(
      { __typename?: 'ClinicSetting' }
      & Pick<ClinicSetting, 'id' | 'smsAutoResponseWhenOpen' | 'smsAutoResponseWhenClosed'>
    )> }
  )>, getTwilioNumbers?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success' | 'payload'>
  )> }
);

export type SearchAvailableBandwidthNumbersQueryVariables = Exact<{
  data?: Maybe<SearchPhoneNumberInput>;
}>;


export type SearchAvailableBandwidthNumbersQuery = (
  { __typename?: 'Query' }
  & { searchPhoneNumber?: Maybe<(
    { __typename?: 'SearchPhoneNumberResponse' }
    & Pick<SearchPhoneNumberResponse, 'total' | 'phoneNumbers'>
  )> }
);

export type UpdateOneClinicPhoneNumberMutationVariables = Exact<{
  where: ClinicPhoneNumberWhereUniqueInput;
  data: ClinicPhoneNumberUpdateInput;
}>;


export type UpdateOneClinicPhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicPhoneNumber: (
    { __typename?: 'ClinicPhoneNumber' }
    & ClinicPhoneNumberFragment
  ) }
);

export type SubscribeToClinicPhoneNumbersSubscriptionVariables = Exact<{
  where: ClinicPhoneNumberWhereInput;
}>;


export type SubscribeToClinicPhoneNumbersSubscription = (
  { __typename?: 'Subscription' }
  & { clinicPhoneNumberChanged: (
    { __typename?: 'ClinicPhoneNumberSubscription' }
    & Pick<ClinicPhoneNumberSubscription, 'mutation'>
    & { node: (
      { __typename?: 'ClinicPhoneNumber' }
      & ClinicPhoneNumberFragment
    ) }
  ) }
);

export type ConnectSettingsClinicWidgetRequestTypeFragment = (
  { __typename?: 'ClinicWidgetRequestType' }
  & Pick<ClinicWidgetRequestType, 'id' | 'order' | 'isActive' | 'isDeleted' | 'displayName' | 'requestType'>
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'title'>
    & { clinicWidgetRequestTypes: Array<(
      { __typename?: 'ClinicWidgetRequestType' }
      & Pick<ClinicWidgetRequestType, 'id'>
    )> }
  )>, assignees: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & ClinicUserAvatarFragment
  )>, channelStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'name'>
  )> }
);

export type ConnectSettingsClinicWidgetSettingFragment = (
  { __typename?: 'ClinicWidgetSetting' }
  & { clinicWidgetRequestTypes: Array<(
    { __typename?: 'ClinicWidgetRequestType' }
    & ConnectSettingsClinicWidgetRequestTypeFragment
  )> }
);

export type SendWidgetCodeToDeveloperMutationVariables = Exact<{
  data: SendWidgetCodeToDeveloperInput;
}>;


export type SendWidgetCodeToDeveloperMutation = (
  { __typename?: 'Mutation' }
  & { sendWidgetCodeToDeveloper?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'success'>
  )> }
);

export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationVariables = Exact<{
  where: FormTemplateWhereUniqueInput;
}>;


export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id'>
  ) }
);

export type UpdateClinicSettingsConnectWidgetMutationVariables = Exact<{
  where: ClinicWhereUniqueInput;
  data: ClinicUpdateInput;
}>;


export type UpdateClinicSettingsConnectWidgetMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
  ) }
);

export type GetClinicSettingsConnectWidgetQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type GetClinicSettingsConnectWidgetQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { clinicWidgetSetting?: Maybe<(
      { __typename?: 'ClinicWidgetSetting' }
      & Pick<ClinicWidgetSetting, 'id' | 'isEnabled' | 'welcomeMessage' | 'primaryColor' | 'secondaryColor' | 'whiteLabelUrls'>
      & ConnectSettingsClinicWidgetSettingFragment
    )> }
  )> }
);

export type GetClinicSettingsConnectWidgetRequestOptionsQueryVariables = Exact<{
  where: ClinicWhereUniqueInput;
}>;


export type GetClinicSettingsConnectWidgetRequestOptionsQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { clinicWidgetSetting?: Maybe<(
      { __typename?: 'ClinicWidgetSetting' }
      & Pick<ClinicWidgetSetting, 'id'>
      & ConnectSettingsClinicWidgetSettingFragment
    )> }
  )> }
);

export type GetFormTemplateContentQueryVariables = Exact<{
  formTemplateId: Scalars['String'];
}>;


export type GetFormTemplateContentQuery = (
  { __typename?: 'Query' }
  & { findUniqueFormTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'id' | 'content'>
  )> }
);

export type RetrieveAuthTokenForContactQueryVariables = Exact<{ [key: string]: never; }>;


export type RetrieveAuthTokenForContactQuery = (
  { __typename?: 'Query' }
  & { retrieveAuthTokenForContact?: Maybe<(
    { __typename?: 'ChurnZeroResponse' }
    & Pick<ChurnZeroResponse, 'authToken'>
  )> }
);

export type CreateSurveyChannelStatusMutationVariables = Exact<{
  data: ChannelStatusCreateInput;
}>;


export type CreateSurveyChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { createOneChannelStatus: (
    { __typename?: 'ChannelStatus' }
    & ClinicSettingsConversationsChannelStatusFragment
  ) }
);

export type InitSurveySettingMutationVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type InitSurveySettingMutation = (
  { __typename?: 'Mutation' }
  & { initSurveySetting: (
    { __typename?: 'SurveySetting' }
    & Pick<SurveySetting, 'id'>
  ) }
);

export type UpdateSurveyGeneralSettingsMutationVariables = Exact<{
  where: SurveySettingWhereUniqueInput;
  data: SurveySettingUpdateInput;
}>;


export type UpdateSurveyGeneralSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneSurveySetting: (
    { __typename?: 'SurveySetting' }
    & Pick<SurveySetting, 'id' | 'isAutomatedTriggerForAllAppointmentTypes' | 'frequencyInDays' | 'showLinkToLeaveReviewNeutral' | 'showLinkToLeaveReviewSatisfied' | 'linkToLeaveReview'>
  ) }
);

export type UpsertSurveyAppointmentTypeSettingsMutationVariables = Exact<{
  where: SurveyAppointmentTypeSettingWhereUniqueInput;
  create: SurveyAppointmentTypeSettingCreateInput;
  update: SurveyAppointmentTypeSettingUpdateInput;
}>;


export type UpsertSurveyAppointmentTypeSettingsMutation = (
  { __typename?: 'Mutation' }
  & { upsertOneSurveyAppointmentTypeSetting?: Maybe<(
    { __typename?: 'SurveyAppointmentTypeSetting' }
    & Pick<SurveyAppointmentTypeSetting, 'id' | 'isActive' | 'automatedTriggerInMinutes' | 'surveySettingId'>
  )> }
);

export type GetDefaultSurveyStatusesQueryVariables = Exact<{
  clinicSettingId: Scalars['String'];
  positiveStatusNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  negativeStatusNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sentStatusNames?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetDefaultSurveyStatusesQuery = (
  { __typename?: 'Query' }
  & { positiveStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'name'>
  )>, negativeStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'name'>
  )>, sentStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'name'>
  )> }
);

export type GetSurveyAppointmentTypeSettingsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetSurveyAppointmentTypeSettingsQuery = (
  { __typename?: 'Query' }
  & { findManyAppointmentType?: Maybe<Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'displayName' | 'description'>
    & { surveyAppointmentTypeSettings: Array<(
      { __typename?: 'SurveyAppointmentTypeSetting' }
      & Pick<SurveyAppointmentTypeSetting, 'id' | 'createdAt' | 'updatedAt' | 'isActive' | 'automatedTriggerInMinutes' | 'surveySettingId'>
    )> }
  )>> }
);

export type GetSurveyGeneralSettingsQueryVariables = Exact<{
  settingId: Scalars['String'];
}>;


export type GetSurveyGeneralSettingsQuery = (
  { __typename?: 'Query' }
  & { findUniqueSurveySetting?: Maybe<(
    { __typename?: 'SurveySetting' }
    & Pick<SurveySetting, 'id' | 'createdAt' | 'updatedAt' | 'isAutomatedTriggerForAllAppointmentTypes' | 'frequencyInDays' | 'showLinkToLeaveReviewNeutral' | 'showLinkToLeaveReviewSatisfied' | 'linkToLeaveReview'>
  )> }
);

export type GetSurveyWorkflowEventSettingsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetSurveyWorkflowEventSettingsQuery = (
  { __typename?: 'Query' }
  & { findManyWorkflowEventSetting?: Maybe<Array<(
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name' | 'surveySettingId' | 'surveyStep' | 'surveyType' | 'isPublished' | 'sendingHours' | 'notificationBody' | 'event'>
    & { actions: Array<(
      { __typename?: 'WorkflowEventAction' }
      & Pick<WorkflowEventAction, 'id' | 'actionType' | 'order' | 'config' | 'channelStatusChangeId'>
      & { channelStatusChange?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'name'>
      )> }
    )> }
  )>> }
);

export type CreateTeamChannelMutationVariables = Exact<{
  data: CreateTeamChannelInput;
}>;


export type CreateTeamChannelMutation = (
  { __typename?: 'Mutation' }
  & { createTeamChannel2?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'friendlyName' | 'channelVisibility'>
  )> }
);

export type GetTeamChannelsQueryVariables = Exact<{
  wherePublic: ChannelWhereInput;
  orderBy?: Maybe<Array<ChannelOrderByWithRelationAndSearchRelevanceInput> | ChannelOrderByWithRelationAndSearchRelevanceInput>;
  wherePrivate: ChannelWhereInput;
  whereDirectMessages: ChannelWhereInput;
}>;


export type GetTeamChannelsQuery = (
  { __typename?: 'Query' }
  & { publicChannels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'isActive' | 'friendlyName' | 'channelVisibility'>
  )>>, privateChannels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'isActive' | 'friendlyName' | 'channelVisibility'>
  )>>, directMessages?: Maybe<Array<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'isActive' | 'friendlyName' | 'channelVisibility'>
    & { assignees: Array<(
      { __typename?: 'User' }
      & ClinicUserAvatarFragment
    )> }
  )>> }
);

export type SubscribeToTeamChannelsSubscriptionVariables = Exact<{
  where: SubscriptionChannelWhereInput;
}>;


export type SubscribeToTeamChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { channelChanged: (
    { __typename?: 'ChannelSubscription' }
    & Pick<ChannelSubscription, 'mutation'>
    & { node: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'isActive' | 'friendlyName' | 'channelType' | 'channelVisibility'>
    ) }
  ) }
);

export type FindManyAppointmentAutomationsQueryVariables = Exact<{
  where?: Maybe<AppointmentWhereInput>;
}>;


export type FindManyAppointmentAutomationsQuery = (
  { __typename?: 'Query' }
  & { findManyAppointment?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'description' | 'startAt'>
    & { appointmentType?: Maybe<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'displayName' | 'name'>
    )>, clinicPet?: Maybe<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'name'>
    )>, workflows: Array<(
      { __typename?: 'WorkflowEventSetting' }
      & Pick<WorkflowEventSetting, 'id' | 'name' | 'event' | 'surveySettingId' | 'surveyStep' | 'surveyType' | 'isPublished'>
    )> }
  )>> }
);

export type GetAutomationRunQueryVariables = Exact<{
  where: AutomationRunWhereUniqueInput;
}>;


export type GetAutomationRunQuery = (
  { __typename?: 'Query' }
  & { findUniqueAutomationRun?: Maybe<(
    { __typename?: 'AutomationRun' }
    & AutomationRunFragment
  )> }
);

export type GetManyAutomationRunsQueryVariables = Exact<{
  where: AutomationRunWhereInput;
}>;


export type GetManyAutomationRunsQuery = (
  { __typename?: 'Query' }
  & { findManyAutomationRun?: Maybe<Array<(
    { __typename?: 'AutomationRun' }
    & AutomationRunFragment
  )>> }
);

export type GetAutomationRunActionQueryVariables = Exact<{
  where?: Maybe<AutomationRunActionWhereInput>;
  orderBy?: Maybe<Array<Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>> | Maybe<AutomationRunActionOrderByWithRelationAndSearchRelevanceInput>>;
}>;


export type GetAutomationRunActionQuery = (
  { __typename?: 'Query' }
  & { findManyAutomationRunAction?: Maybe<Array<(
    { __typename?: 'AutomationRunAction' }
    & AutomationRunActionFragment
  )>> }
);

export type AutomationRunFragment = (
  { __typename?: 'AutomationRun' }
  & Pick<AutomationRun, 'id' | 'status' | 'channelMessageId' | 'originAutomationId' | 'runType' | 'manuallySentById' | 'startedAt' | 'completedAt' | 'appointmentId' | 'formSubmissionId' | 'serviceReminderId' | 'clinicWidgetRequestId' | 'clinicPetParentId'>
  & { actions: Array<(
    { __typename?: 'AutomationRunAction' }
    & AutomationRunActionFragment
  )>, originAutomation: (
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name'>
  ), channelMessage: (
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'channelId'>
  ) }
);

export type AutomationRunActionFragment = (
  { __typename?: 'AutomationRunAction' }
  & Pick<AutomationRunAction, 'id' | 'createdAt' | 'updatedAt' | 'automationRunId' | 'promptType' | 'automationActionId' | 'body' | 'attachments' | 'actionType' | 'petParentId' | 'petId' | 'buttons' | 'channelStatusChangeId' | 'order'>
  & { automationRun: (
    { __typename?: 'AutomationRun' }
    & Pick<AutomationRun, 'id' | 'status'>
    & { channelMessage: (
      { __typename?: 'ChannelMessage' }
      & { channel?: Maybe<(
        { __typename?: 'Channel' }
        & Pick<Channel, 'id'>
      )> }
    ) }
  ), automationAction?: Maybe<(
    { __typename?: 'WorkflowEventAction' }
    & Pick<WorkflowEventAction, 'id' | 'config'>
    & { workflowEventSetting?: Maybe<(
      { __typename?: 'WorkflowEventSetting' }
      & Pick<WorkflowEventSetting, 'id' | 'name' | 'surveyStep' | 'event'>
    )> }
  )>, petParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'isDeleted' | 'isActive'>
  )>, formSubmission?: Maybe<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'submittedAt'>
    & { clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'isDeleted' | 'isActive'>
    )> }
  )>, paymentIntent?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & Pick<StripePaymentIntent, 'total' | 'amount' | 'serviceFeeAmount' | 'paidAt'>
    & StripePaymentIntentSelectionFragment
  )> }
);

export type DeleteManyAutomationRunActionMutationVariables = Exact<{
  where: AutomationRunActionWhereInput;
}>;


export type DeleteManyAutomationRunActionMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyAutomationRunAction?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdateAutomationRunActionMutationVariables = Exact<{
  where: AutomationRunActionWhereUniqueInput;
  data: AutomationRunActionUpdateInput;
}>;


export type UpdateAutomationRunActionMutation = (
  { __typename?: 'Mutation' }
  & { updateOneAutomationRunAction: (
    { __typename?: 'AutomationRunAction' }
    & AutomationRunActionFragment
  ) }
);

export type UpdateAutomationRunMutationVariables = Exact<{
  where: AutomationRunWhereUniqueInput;
  data: AutomationRunUpdateInput;
}>;


export type UpdateAutomationRunMutation = (
  { __typename?: 'Mutation' }
  & { updateOneAutomationRun: (
    { __typename?: 'AutomationRun' }
    & AutomationRunFragment
  ) }
);

export type ResetAutomationRunMutationVariables = Exact<{
  where: AutomationRunWhereUniqueInput;
}>;


export type ResetAutomationRunMutation = (
  { __typename?: 'Mutation' }
  & { resetAutomationRun?: Maybe<(
    { __typename?: 'AutomationRun' }
    & AutomationRunFragment
  )> }
);

export type CalculatePetBenefitUsageQueryVariables = Exact<{
  where?: Maybe<CalculatePetBenefitUsageInput>;
}>;


export type CalculatePetBenefitUsageQuery = (
  { __typename?: 'Query' }
  & { calculatePetBenefitUsage?: Maybe<(
    { __typename?: 'CalculatePetBenefitUsageOutput' }
    & { usages: Array<(
      { __typename?: 'UsageByBenefit' }
      & Pick<UsageByBenefit, 'provided' | 'used' | 'exhausted' | 'displayOrder' | 'carryover' | 'costCovered'>
      & { benefit: (
        { __typename?: 'CareBenefit' }
        & Pick<CareBenefit, 'id' | 'title' | 'description' | 'tangible' | 'exhaustible' | 'usageUnitOfMeasure' | 'providedDiscountPercentage' | 'type'>
        & { planBenefits: Array<(
          { __typename?: 'CarePlanBenefit' }
          & CarePlanBenefitUsageFragment
        )> }
      ), sources: (
        { __typename?: 'BenefitSource' }
        & Pick<BenefitSource, 'addonIds' | 'planIds'>
      ) }
    )> }
  )> }
);

export type CarePlanBenefitUsageFragment = (
  { __typename?: 'CarePlanBenefit' }
  & Pick<CarePlanBenefit, 'id' | 'providedDiscountPercentage' | 'benefitId' | 'planId'>
);

export type ClinicPetSelectionFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'breed' | 'gender' | 'isActive' | 'isDeleted' | 'dateOfBirth' | 'speciesType' | 'weight' | 'weightUnit'>
  & { alerts: Array<(
    { __typename?: 'ClinicPetAlert' }
    & Pick<ClinicPetAlert, 'value' | 'pimsDate' | 'pimsId' | 'isMedical'>
  )> }
  & PetAvatarFragment
  & OrganizationPetCareBadgePlanEnrollmentFragment
);

export type ClinicPetNestedSelectionFragment = (
  { __typename?: 'ClinicPet' }
  & { petParents: Array<(
    { __typename?: 'ClinicPetParent' }
    & ClinicPetParentSelectionFragment
  )> }
  & ClinicPetSelectionFragment
);

export type CreateOneClinicPetMutationVariables = Exact<{
  data: ClinicPetCreateInput;
}>;


export type CreateOneClinicPetMutation = (
  { __typename?: 'Mutation' }
  & { createOneClinicPet: (
    { __typename?: 'ClinicPet' }
    & ClinicPetSelectionFragment
  ) }
);

export type ClinicPetParentSelectionFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive' | 'updatedAt' | 'pimsId'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
  )> }
  & PetParentAvatarFragment
);

export type CreateOneClinicPetParentMutationVariables = Exact<{
  data: ClinicPetParentCreateInput;
}>;


export type CreateOneClinicPetParentMutation = (
  { __typename?: 'Mutation' }
  & { createOneClinicPetParent: (
    { __typename?: 'ClinicPetParent' }
    & ClinicPetParentSelectionFragment
  ) }
);

export type CreateCareBenefitUsageToInvoiceLineItemMutationVariables = Exact<{
  data: CareBenefitUsageToInvoiceLineItemCreateInput;
}>;


export type CreateCareBenefitUsageToInvoiceLineItemMutation = (
  { __typename?: 'Mutation' }
  & { createOneCareBenefitUsageToInvoiceLineItem: (
    { __typename?: 'CareBenefitUsageToInvoiceLineItem' }
    & Pick<CareBenefitUsageToInvoiceLineItem, 'id'>
  ) }
);

export type GetEmailCampaignLoginUrlQueryVariables = Exact<{
  data: ConfigureEmailCampaignsInput;
}>;


export type GetEmailCampaignLoginUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmailCampaignLoginUrl'>
);

export type ConfigureEmailCampaignsForClinicMutationVariables = Exact<{
  data: ConfigureEmailCampaignsInput;
}>;


export type ConfigureEmailCampaignsForClinicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'configureEmailCampaignsForClinic'>
);

export type GetClinicChannelPetParentsQueryVariables = Exact<{
  where: ChannelWhereUniqueInput;
}>;


export type GetClinicChannelPetParentsQuery = (
  { __typename?: 'Query' }
  & { findUniqueChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'channelType' | 'friendlyName'>
    & { channelMembers: Array<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id' | 'removedAt'>
      & { clinicPetParent?: Maybe<(
        { __typename?: 'ClinicPetParent' }
        & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetClinicPetEnrollmentsByParentQueryVariables = Exact<{
  where: ClinicPetParentWhereUniqueInput;
  clinicId: Scalars['String'];
}>;


export type GetClinicPetEnrollmentsByParentQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'organizationPetParentId'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & SidePanelParentPetDataFragment
    )> }
    & PetParentAvatarFragment
  )> }
);

export type GetClinicTagsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicTagsQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & { clinicSetting?: Maybe<(
      { __typename?: 'ClinicSetting' }
      & Pick<ClinicSetting, 'tags'>
    )> }
  )> }
);

export type GetDirectedCareLoginLinkMutationVariables = Exact<{
  data: DirectedAuthLinkInput;
}>;


export type GetDirectedCareLoginLinkMutation = (
  { __typename?: 'Mutation' }
  & { getDirectedCareLoginLink: (
    { __typename?: 'GetDirectedAuthLinkResult' }
    & Pick<GetDirectedAuthLinkResult, 'link'>
  ) }
);

export type GetExistingChannelMembersQueryVariables = Exact<{
  clinicPetParentIds: Array<Scalars['String']> | Scalars['String'];
  channelStatusAction?: Maybe<EnumChannelStatusActionFilter>;
}>;


export type GetExistingChannelMembersQuery = (
  { __typename?: 'Query' }
  & { findManyChannelMember?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'channelId'>
    & { channel?: Maybe<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
    )> }
  )>> }
);

export type FormSubmissionFragment = (
  { __typename?: 'FormSubmission' }
  & Pick<FormSubmission, 'id' | 'submittedAt' | 'draftContent' | 'completedContent' | 'isCallDeflectionForm'>
  & { clinicPet?: Maybe<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & PetAvatarFragment
  )>, clinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id' | 'name' | 'pimsId' | 'species' | 'createdAt' | 'updatedAt' | 'isDeceased'>
    )> }
  )>, formTemplate?: Maybe<(
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'title'>
  )> }
);

export type GetFormSubmissionQueryVariables = Exact<{
  formSubmissionId: Scalars['String'];
  clinicId: Scalars['String'];
}>;


export type GetFormSubmissionQuery = (
  { __typename?: 'Query' }
  & { findUniqueFormSubmission?: Maybe<(
    { __typename?: 'FormSubmission' }
    & FormSubmissionFragment
  )> }
);

export type GetSignedUploadUrlMutationVariables = Exact<{
  data: GetSignedUploadInput;
}>;


export type GetSignedUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { getSignedUploadUrl: (
    { __typename?: 'GetSignedUploadUrlOutput' }
    & Pick<GetSignedUploadUrlOutput, 'key' | 'uploadUrl' | 'finalUrl'>
  ) }
);

export type RemoveChannelTagMutationVariables = Exact<{
  channelId: Scalars['String'];
  tagName: Scalars['String'];
}>;


export type RemoveChannelTagMutation = (
  { __typename?: 'Mutation' }
  & { removeChannelTag?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'tags' | 'clinicId'>
  )> }
);

export type SaveWorkflowEventSettingsMutationVariables = Exact<{
  data: WorkflowEventSettingUpdateInput;
  where: WorkflowEventSettingWhereUniqueInput;
}>;


export type SaveWorkflowEventSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneWorkflowEventSetting: (
    { __typename?: 'WorkflowEventSetting' }
    & Pick<WorkflowEventSetting, 'id' | 'name' | 'surveySettingId' | 'surveyStep' | 'surveyType' | 'notificationBody' | 'event'>
    & { actions: Array<(
      { __typename?: 'WorkflowEventAction' }
      & Pick<WorkflowEventAction, 'id' | 'actionType' | 'order' | 'config' | 'channelStatusChangeId'>
      & { channelStatusChange?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type UpdateChannelStatusMutationVariables = Exact<{
  where: ChannelWhereUniqueInput;
  data: ChannelUpdateInput;
}>;


export type UpdateChannelStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOneChannel: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'clinicId' | 'channelStatusId'>
    & { channelStatus?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & ChannelListChannelStatusFragment
    )> }
  ) }
);

export type UserAppointmentGroupingFullSelectionFragment = (
  { __typename?: 'UserAppointmentGrouping' }
  & Pick<UserAppointmentGrouping, 'id' | 'label'>
  & { clinicEmployees: Array<(
    { __typename?: 'ClinicEmployee' }
    & Pick<ClinicEmployee, 'id'>
  )>, rooms: Array<(
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id'>
  )>, appointmentTypes: Array<(
    { __typename?: 'AppointmentType' }
    & Pick<AppointmentType, 'id'>
  )> }
);

export type CreateOneUserAppointmentGroupingMutationVariables = Exact<{
  data: UserAppointmentGroupingCreateInput;
}>;


export type CreateOneUserAppointmentGroupingMutation = (
  { __typename?: 'Mutation' }
  & { createOneUserAppointmentGrouping: (
    { __typename?: 'UserAppointmentGrouping' }
    & UserAppointmentGroupingFullSelectionFragment
  ) }
);

export type UpdateOneUserAppointmentGroupingMutationVariables = Exact<{
  data: UserAppointmentGroupingUpdateInput;
  where: UserAppointmentGroupingWhereUniqueInput;
}>;


export type UpdateOneUserAppointmentGroupingMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUserAppointmentGrouping: (
    { __typename?: 'UserAppointmentGrouping' }
    & UserAppointmentGroupingFullSelectionFragment
  ) }
);

export type DeleteOneUserAppointmentGroupingMutationVariables = Exact<{
  where: UserAppointmentGroupingWhereUniqueInput;
}>;


export type DeleteOneUserAppointmentGroupingMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneUserAppointmentGrouping?: Maybe<(
    { __typename?: 'UserAppointmentGrouping' }
    & UserAppointmentGroupingFullSelectionFragment
  )> }
);

export type FindManyUserAppointmentGroupingQueryVariables = Exact<{
  where?: Maybe<UserAppointmentGroupingWhereInput>;
  orderBy?: Maybe<Array<UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput> | UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyUserAppointmentGroupingQuery = (
  { __typename?: 'Query' }
  & { findManyUserAppointmentGrouping?: Maybe<Array<(
    { __typename?: 'UserAppointmentGrouping' }
    & UserAppointmentGroupingFullSelectionFragment
  )>> }
);

export type ClinicUserAvatarFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'nameDisplay' | 'profilePic'>
);

export type PetAvatarFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'computed_rawPimsIsDeceased' | 'computed_rawPimsIsActive' | 'computedIsDeceased' | 'computedIsActive' | 'isDeceased' | 'isActive' | 'name' | 'photoUrl' | 'rawPimsValue' | 'species'>
  & { organizationPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & SidePanelOrganizationPetFragment
  )> }
);

export type PetParentAvatarFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'creationSource' | 'isTest'>
);

export type GetClinicPetParentAvatarPhoneNumbersQueryVariables = Exact<{
  clinicPetParentId: Scalars['String'];
}>;


export type GetClinicPetParentAvatarPhoneNumbersQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id'>
    & { phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'id' | 'updatedAt' | 'number' | 'isPrimary' | 'isDeleted'>
    )> }
  )> }
);

export type GetConversationStatusOptionsQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetConversationStatusOptionsQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & Pick<ChannelStatus, 'id' | 'color' | 'name' | 'channelStatusAction'>
  )>> }
);

export type UpdateCreatePasswordModalUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateCreatePasswordModalUserMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'namePrefix' | 'nameSuffix' | 'email' | 'phone' | 'isNewPasswordRequired'>
  ) }
);

export type EmailTemplateVersionFragment = (
  { __typename?: 'EmailTemplateVersion' }
  & Pick<EmailTemplateVersion, 'id' | 'html' | 'design' | 'previewImageUrl' | 'emailTemplateId' | 'isCurrent' | 'version' | 'lastEditedById' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'publishedAt'>
  & { lastEditedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName'>
  )> }
);

export type CreateEmailTemplateVersionMutationVariables = Exact<{
  data: CreateEmailTemplateVersionInput;
}>;


export type CreateEmailTemplateVersionMutation = (
  { __typename?: 'Mutation' }
  & { createEmailTemplateVersion: (
    { __typename?: 'EmailTemplateVersion' }
    & EmailTemplateVersionFragment
  ) }
);

export type SaveEmailTemplateVersionMutationVariables = Exact<{
  data: SaveEmailTemplateVersionInput;
}>;


export type SaveEmailTemplateVersionMutation = (
  { __typename?: 'Mutation' }
  & { saveEmailTemplateVersion: (
    { __typename?: 'EmailTemplateVersion' }
    & EmailTemplateVersionFragment
  ) }
);

export type UpdateOneEmailTemplateVersionMutationVariables = Exact<{
  data: EmailTemplateVersionUpdateInput;
  where: EmailTemplateVersionWhereUniqueInput;
}>;


export type UpdateOneEmailTemplateVersionMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEmailTemplateVersion: (
    { __typename?: 'EmailTemplateVersion' }
    & Pick<EmailTemplateVersion, 'id'>
  ) }
);

export type FindCurrentEmailTemplateVersionsQueryVariables = Exact<{
  data: FindCurrentEmailTemplateVersionInput;
}>;


export type FindCurrentEmailTemplateVersionsQuery = (
  { __typename?: 'Query' }
  & { findCurrentEmailTemplateVersions: (
    { __typename?: 'CurrentEmailTemplateVersionsFindResponse' }
    & { unlayer: (
      { __typename?: 'UnlayerUser' }
      & Pick<UnlayerUser, 'userId' | 'signature'>
    ), draft?: Maybe<(
      { __typename?: 'EmailTemplateVersion' }
      & EmailTemplateVersionFragment
    )>, published?: Maybe<(
      { __typename?: 'EmailTemplateVersion' }
      & EmailTemplateVersionFragment
    )> }
  ) }
);

export type MergePetParentProfilesMutationVariables = Exact<{
  data?: Maybe<MergePetParentInput>;
}>;


export type MergePetParentProfilesMutation = (
  { __typename?: 'Mutation' }
  & { mergePetParent: (
    { __typename?: 'MergePetParentResult' }
    & Pick<MergePetParentResult, 'success' | 'messages'>
  ) }
);

export type MergeProfileClientSearchQueryVariables = Exact<{
  where?: Maybe<SearchClinicPetParentInput>;
  clinicId: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
}>;


export type MergeProfileClientSearchQuery = (
  { __typename?: 'Query' }
  & { clinicPetParentSearch: Array<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'isTest' | 'pimsId' | 'firstName' | 'lastName'>
    & { pets: Array<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id' | 'pimsId' | 'name'>
      & { organizationPet?: Maybe<(
        { __typename?: 'OrganizationPet' }
        & Pick<OrganizationPet, 'id'>
        & { carePlanEnrollments: Array<(
          { __typename?: 'CarePlanEnrollment' }
          & Pick<CarePlanEnrollment, 'id'>
        )> }
      )> }
    )>, loyaltyAccountHolder?: Maybe<(
      { __typename?: 'LoyaltyAccountHolder' }
      & Pick<LoyaltyAccountHolder, 'id'>
      & { account: (
        { __typename?: 'LoyaltyAccount' }
        & Pick<LoyaltyAccount, 'pointBalance'>
        & { rewardRedemptions: Array<(
          { __typename?: 'LoyaltyRewardRedemption' }
          & Pick<LoyaltyRewardRedemption, 'id'>
        )> }
      ) }
    )>, channelMembers: Array<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id'>
      & { channel?: Maybe<(
        { __typename?: 'Channel' }
        & { channelStatus?: Maybe<(
          { __typename?: 'ChannelStatus' }
          & Pick<ChannelStatus, 'channelStatusAction'>
        )> }
      )> }
    )> }
  )> }
);

export type GetNonItemizedReceiptPdfQueryVariables = Exact<{
  intentId: Scalars['String'];
}>;


export type GetNonItemizedReceiptPdfQuery = (
  { __typename?: 'Query' }
  & { exportNonItemizedReceiptAsPdf: (
    { __typename?: 'ExportNonItemizedReceiptAsPdfResponse' }
    & Pick<ExportNonItemizedReceiptAsPdfResponse, 'success' | 'base64PDF'>
  ) }
);

export type GetReceiptPetParentQueryVariables = Exact<{
  clinicPetParentId: Scalars['String'];
}>;


export type GetReceiptPetParentQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'email'>
    & { phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'id' | 'number'>
    )> }
  )> }
);

export type GetStripeReceiptQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStripeReceiptQuery = (
  { __typename?: 'Query' }
  & { findUniqueStripeReceipt?: Maybe<(
    { __typename?: 'StripeReceipt' }
    & Pick<StripeReceipt, 'id' | 'stripeId' | 'applicationName' | 'dedicatedFileName' | 'accountType'>
  )> }
);

export type SyncPimsIntegrationModelMutationVariables = Exact<{
  data: SyncManyPimsIntegrationModelInput;
}>;


export type SyncPimsIntegrationModelMutation = (
  { __typename?: 'Mutation' }
  & { syncManyPimsIntegrationModel?: Maybe<Array<(
    { __typename?: 'ClinicPimsIntegrationCapability' }
    & Pick<ClinicPimsIntegrationCapability, 'lastSyncedAt'>
  )>> }
);

export type GetClinicPimsIntegrationCapabilitySyncQueryVariables = Exact<{
  where?: Maybe<ClinicPimsIntegrationCapabilityWhereInput>;
}>;


export type GetClinicPimsIntegrationCapabilitySyncQuery = (
  { __typename?: 'Query' }
  & { findManyClinicPimsIntegrationCapability?: Maybe<Array<(
    { __typename?: 'ClinicPimsIntegrationCapability' }
    & Pick<ClinicPimsIntegrationCapability, 'lastSyncedAt'>
  )>> }
);

export type GetEnrollmentsCountQueryVariables = Exact<{
  where?: Maybe<CarePlanEnrollmentWhereInput>;
}>;


export type GetEnrollmentsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyCarePlanEnrollmentCount'>
);

export type SubscribeToNewCareEnrollmentsSubscriptionVariables = Exact<{
  where: CareEnrollmentsByClinicWhereInput;
}>;


export type SubscribeToNewCareEnrollmentsSubscription = (
  { __typename?: 'Subscription' }
  & { checkPetEnrollmentsByClinic: (
    { __typename?: 'CareEnrollmentsByClinicOutput' }
    & Pick<CareEnrollmentsByClinicOutput, 'dailyTotal' | 'monthlyTotal'>
    & { enrollmentData: (
      { __typename?: 'CareEnrollmentData' }
      & Pick<CareEnrollmentData, 'clientName' | 'petName' | 'planName' | 'isRenewal'>
    ) }
  ) }
);

export type FindUniqueClinicPetParentQueryVariables = Exact<{
  where: ClinicPetParentWhereUniqueInput;
}>;


export type FindUniqueClinicPetParentQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'firstName'>
  )> }
);

export type InvoicePetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive' | 'updatedAt' | 'creationSource' | 'pimsId'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'breed' | 'gender' | 'isDeleted' | 'speciesType' | 'weight' | 'clinicId' | 'dateOfBirth' | 'weightUnit' | 'pimsId'>
    & { petParents: Array<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'firstName' | 'id' | 'lastName' | 'email' | 'pimsId'>
      & { phoneNumbers: Array<(
        { __typename?: 'ClinicEntityPhoneNumber' }
        & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
      )> }
    )>, alerts: Array<(
      { __typename?: 'ClinicPetAlert' }
      & Pick<ClinicPetAlert, 'value' | 'pimsDate' | 'pimsId' | 'isMedical'>
    )> }
    & PetAvatarFragment
  )> }
);

export type PostCareInvoiceAccountCreditToPimsMutationVariables = Exact<{
  data: WritebackCareInvoiceAccountCreditToPimsInput;
}>;


export type PostCareInvoiceAccountCreditToPimsMutation = (
  { __typename?: 'Mutation' }
  & { postCareInvoiceAccountCreditToPims: (
    { __typename?: 'WritebackCareInvoiceAccountCreditToPimsResponse' }
    & Pick<WritebackCareInvoiceAccountCreditToPimsResponse, 'success' | 'error'>
  ) }
);

export type IssueTrupanionCertificateMutationVariables = Exact<{
  where: TrupanionIssueCertificateWhereInput;
  data: TrupanionIssueCertificateDataInput;
}>;


export type IssueTrupanionCertificateMutation = (
  { __typename?: 'Mutation' }
  & { trupanionIssueCertificate?: Maybe<(
    { __typename?: 'TrupanionIssueCertificateResponse' }
    & Pick<TrupanionIssueCertificateResponse, 'referenceNumber' | 'errorMessages'>
  )> }
);

export type GetInvoicePetParentsQueryVariables = Exact<{
  whereInput?: Maybe<SearchClinicPetParentInput>;
  pageSize?: Maybe<Scalars['Int']>;
  petWhere?: Maybe<ClinicPetWhereInput>;
}>;


export type GetInvoicePetParentsQuery = (
  { __typename?: 'Query' }
  & { clinicPetParentSearch: Array<(
    { __typename?: 'ClinicPetParent' }
    & InvoicePetParentFragment
  )> }
);

export type GetPaymentIntentStatusQueryVariables = Exact<{
  where: StripePaymentIntentWhereUniqueInput;
}>;


export type GetPaymentIntentStatusQuery = (
  { __typename?: 'Query' }
  & { findUniqueStripePaymentIntent?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & Pick<StripePaymentIntent, 'id' | 'status' | 'lastPaymentError' | 'paymentRelationship'>
  )> }
);

export type TrupanionCertificateAvailabilityQueryVariables = Exact<{
  where: TrupanionCertificateAvailabilityInput;
}>;


export type TrupanionCertificateAvailabilityQuery = (
  { __typename?: 'Query' }
  & { trupanionCertificateAvailability?: Maybe<Array<Maybe<(
    { __typename?: 'TrupanionAvailability' }
    & Pick<TrupanionAvailability, 'clinicPetId' | 'clinicPetName' | 'isAvailable' | 'trupanionClosestBreed' | 'pimsBreed' | 'speciesType' | 'errorMessages'>
    & { unavailableReasons?: Maybe<(
      { __typename?: 'UnavailableReasons' }
      & Pick<UnavailableReasons, 'IsPetAlreadyEnrolled' | 'IsPetPolicyCancelled' | 'IsActiveOrPendingCertificateFound' | 'IsExamDateOutsideOfActivationWindow' | 'IsAdoptionDateOutsideOfActivationWindow' | 'IsEmailAssociatedToManyPolicies'>
    )> }
  )>>> }
);

export type CreateMassTextAlertMutationVariables = Exact<{
  data: MassTextAlertCreateInput;
}>;


export type CreateMassTextAlertMutation = (
  { __typename?: 'Mutation' }
  & { createOneMassTextAlert: (
    { __typename?: 'MassTextAlert' }
    & Pick<MassTextAlert, 'id' | 'appointmentDateTimeTo' | 'appointmentDateTimeFrom' | 'alertBody'>
  ) }
);

export type MassTextAleryActivityLogFragment = (
  { __typename?: 'MassTextAlert' }
  & Pick<MassTextAlert, 'id' | 'createdAt' | 'alertBody' | 'appointmentDateRangeType' | 'appointmentDateTimeFrom' | 'appointmentDateTimeTo' | 'status'>
  & { createdByUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & ClinicUserAvatarFragment
  ) }
);

export type GetActivityLogForMassTextAlertsQueryVariables = Exact<{
  where?: Maybe<MassTextAlertWhereInput>;
  orderBy?: Maybe<Array<Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>> | Maybe<MassTextAlertOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetActivityLogForMassTextAlertsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyMassTextAlertCount'>
  & { findManyMassTextAlert?: Maybe<Array<(
    { __typename?: 'MassTextAlert' }
    & MassTextAleryActivityLogFragment
  )>> }
);

export type GetDeliveryReportForMassTextAlertQueryVariables = Exact<{
  massTextAlertId: Scalars['String'];
}>;


export type GetDeliveryReportForMassTextAlertQuery = (
  { __typename?: 'Query' }
  & { massTextAlertReport?: Maybe<Array<Maybe<(
    { __typename?: 'MassTextAlertReportEntry' }
    & Pick<MassTextAlertReportEntry, 'phoneNumber' | 'petParentFullName' | 'smsStatus' | 'errorDetails'>
  )>>> }
);

export type GetEstimatedSendTimeForMassTextAlertQueryVariables = Exact<{
  clinicId: Scalars['String'];
  appointmentDateTimeFrom: Scalars['String'];
  appointmentDateTimeTo: Scalars['String'];
  alertBody: Scalars['String'];
}>;


export type GetEstimatedSendTimeForMassTextAlertQuery = (
  { __typename?: 'Query' }
  & { estimateNumberOfPetParentsReached?: Maybe<(
    { __typename?: 'NumberOfPetParentsReached' }
    & Pick<NumberOfPetParentsReached, 'numberOfPetPerents' | 'timeEstimateInSeconds'>
  )> }
);

export type SubscribeToMassTextAlertStatusSubscriptionVariables = Exact<{
  where: SubscriptionMassTextAlertWhereInput;
}>;


export type SubscribeToMassTextAlertStatusSubscription = (
  { __typename?: 'Subscription' }
  & { massTextAlertChanged: (
    { __typename?: 'MassTextAlertSubscription' }
    & { node: (
      { __typename?: 'MassTextAlert' }
      & Pick<MassTextAlert, 'id' | 'status'>
    ) }
  ) }
);

export type GetAppointmentFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppointmentFiltersQuery = (
  { __typename?: 'Query' }
  & { appointmentFilters?: Maybe<(
    { __typename?: 'AppointmentFilter' }
    & Pick<AppointmentFilter, 'currentDay' | 'startDate' | 'endDate' | 'daysAheadInterval' | 'defaultAppointmentDurationInMinutes'>
  )> }
);

export type CompleteCareBenefitUsageFollowupMutationVariables = Exact<{
  followupId: Scalars['String'];
  completedAt: Scalars['DateTime'];
}>;


export type CompleteCareBenefitUsageFollowupMutation = (
  { __typename?: 'Mutation' }
  & { updateOneCareBenefitUsageFollowup: (
    { __typename?: 'CareBenefitUsageFollowup' }
    & Pick<CareBenefitUsageFollowup, 'id' | 'completed'>
  ) }
);

export type CompleteCareBenefitUsageFollowupStepMutationVariables = Exact<{
  stepId: Scalars['String'];
  completedAt: Scalars['DateTime'];
  completedByUserId: Scalars['String'];
}>;


export type CompleteCareBenefitUsageFollowupStepMutation = (
  { __typename?: 'Mutation' }
  & { updateOneCareBenefitUsageFollowupStep: (
    { __typename?: 'CareBenefitUsageFollowupStep' }
    & Pick<CareBenefitUsageFollowupStep, 'id' | 'completed'>
  ) }
);

export type GetCareBenefitUsageFollowupQueryVariables = Exact<{
  organizationPetId: Scalars['String'];
}>;


export type GetCareBenefitUsageFollowupQuery = (
  { __typename?: 'Query' }
  & { findManyCareBenefitUsageFollowup?: Maybe<Array<(
    { __typename?: 'CareBenefitUsageFollowup' }
    & CareBenefitUsageFollowUpFragment
  )>> }
);

export type CareAccountCreditIntentFragment = (
  { __typename?: 'CareAccountCreditIntent' }
  & Pick<CareAccountCreditIntent, 'id' | 'invoiceId' | 'paymentDue'>
  & { careAccountCreditPimsWritebackLogEntries: Array<(
    { __typename?: 'CareAccountCreditPimsWritebackLogEntry' }
    & Pick<CareAccountCreditPimsWritebackLogEntry, 'writebackLogId'>
  )> }
);

export type CareBenefitUsageFollowUpFragment = (
  { __typename?: 'CareBenefitUsageFollowup' }
  & Pick<CareBenefitUsageFollowup, 'id' | 'careStripeSubscriptionId' | 'completed'>
  & { followupSteps: Array<(
    { __typename?: 'CareBenefitUsageFollowupStep' }
    & Pick<CareBenefitUsageFollowupStep, 'id' | 'description' | 'completed' | 'displayOrder'>
    & { careAccountCreditIntents: Array<(
      { __typename?: 'CareAccountCreditIntent' }
      & CareAccountCreditIntentFragment
    )> }
  )> }
);

export type GetConnectedServicesQueryVariables = Exact<{
  where?: Maybe<OrganizationCareBenefitToClinicServiceWhereInput>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetConnectedServicesQuery = (
  { __typename?: 'Query' }
  & { findManyOrganizationCareBenefitToClinicService?: Maybe<Array<(
    { __typename?: 'OrganizationCareBenefitToClinicService' }
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name' | 'integrationId'>
    ) }
  )>> }
);

export type CancelCareSubscriptionMutationVariables = Exact<{
  data?: Maybe<CancelCareSubscriptionInput>;
}>;


export type CancelCareSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelCareSubscription?: Maybe<(
    { __typename?: 'CancelCareSubscriptionResult' }
    & Pick<CancelCareSubscriptionResult, 'success' | 'messages'>
  )> }
);

export type CreateCarePaymentMethodSetupMutationVariables = Exact<{
  data?: Maybe<CreateCarePaymentMethodSetupInput>;
}>;


export type CreateCarePaymentMethodSetupMutation = (
  { __typename?: 'Mutation' }
  & { createCarePaymentMethodSetup: (
    { __typename?: 'CreatePaymentMethodSetupInputResult' }
    & Pick<CreatePaymentMethodSetupInputResult, 'setupIntentSecret'>
  ) }
);

export type CreateEnrollmentSupportEventMutationVariables = Exact<{
  data: EnrollmentSupportEventCreateInput;
}>;


export type CreateEnrollmentSupportEventMutation = (
  { __typename?: 'Mutation' }
  & { createOneEnrollmentSupportEvent: (
    { __typename?: 'EnrollmentSupportEvent' }
    & Pick<EnrollmentSupportEvent, 'id' | 'createdAt' | 'carePlanEnrollmentId' | 'requesterId' | 'pointOfContactId' | 'manageMembershipRequestType' | 'cancelReason' | 'manageMembershipDescription' | 'relatedInvoiceId'>
  ) }
);

export type EnsurePetsArePromotedMutationVariables = Exact<{
  data?: Maybe<EnsurePetsPromotedInput>;
}>;


export type EnsurePetsArePromotedMutation = (
  { __typename?: 'Mutation' }
  & { ensurePetsPromoted?: Maybe<(
    { __typename?: 'EnsurePetsPromotedResult' }
    & Pick<EnsurePetsPromotedResult, 'success'>
  )> }
);

export type PerformCarePlanEnrollmentUpgradeMutationVariables = Exact<{
  data: PerformCarePlanEnrollmentUpgradeInput;
}>;


export type PerformCarePlanEnrollmentUpgradeMutation = (
  { __typename?: 'Mutation' }
  & { performCarePlanEnrollmentUpgrade?: Maybe<(
    { __typename?: 'PerformCarePlanEnrollmentUpgradeOutput' }
    & Pick<PerformCarePlanEnrollmentUpgradeOutput, 'success' | 'message' | 'upgradeInvoiceId'>
  )> }
);

export type TransferCareSubscriptionMutationVariables = Exact<{
  data?: Maybe<TransferSubscriptionInput>;
}>;


export type TransferCareSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { transferCareSubscription?: Maybe<(
    { __typename?: 'TransferSubscriptionOutput' }
    & Pick<TransferSubscriptionOutput, 'success'>
  )> }
);

export type UpdateCarePlanEnrollmentMutationVariables = Exact<{
  where: CarePlanEnrollmentWhereUniqueInput;
  data: CarePlanEnrollmentUpdateInput;
}>;


export type UpdateCarePlanEnrollmentMutation = (
  { __typename?: 'Mutation' }
  & { updateOneCarePlanEnrollment: (
    { __typename?: 'CarePlanEnrollment' }
    & Pick<CarePlanEnrollment, 'id' | 'autoRenews'>
  ) }
);

export type ExportCancellationSummaryAsPdfQueryVariables = Exact<{
  data: ExportCancellationSummaryAsPdfInput;
}>;


export type ExportCancellationSummaryAsPdfQuery = (
  { __typename?: 'Query' }
  & { exportCancellationSummaryAsPdf: (
    { __typename?: 'ExportCancellationSummaryAsPdfResponse' }
    & Pick<ExportCancellationSummaryAsPdfResponse, 'success' | 'base64PDF'>
  ) }
);

export type FindLastUsageByBenefitQueryVariables = Exact<{
  usageClinicPetId?: Maybe<Scalars['String']>;
  benefitId?: Maybe<Scalars['String']>;
}>;


export type FindLastUsageByBenefitQuery = (
  { __typename?: 'Query' }
  & { findManyCareBenefitUsage?: Maybe<Array<(
    { __typename?: 'CareBenefitUsage' }
    & Pick<CareBenefitUsage, 'id'>
  )>> }
);

export type FindManyCareBenefitUsageQueryVariables = Exact<{
  orgPetId?: Maybe<Scalars['String']>;
  orgPetParentId?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput> | CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type FindManyCareBenefitUsageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyCareBenefitUsageCount'>
  & { findManyCareBenefitUsage?: Maybe<Array<(
    { __typename?: 'CareBenefitUsage' }
    & CareBenefitActivityUsageFragment
  )>> }
);

export type RecordingUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'namePrefix' | 'firstName' | 'lastName'>
);

export type CareBenefitActivityUsageFragment = (
  { __typename?: 'CareBenefitUsage' }
  & Pick<CareBenefitUsage, 'id' | 'createdAt' | 'updatedAt' | 'usedAt' | 'quantityUsed' | 'exhaustsBenefit'>
  & { recordedByUser?: Maybe<(
    { __typename?: 'User' }
    & RecordingUserFragment
  )>, usageClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'name'>
  ), benefit: (
    { __typename?: 'CareBenefit' }
    & Pick<CareBenefit, 'id' | 'title' | 'description' | 'tangible' | 'exhaustible' | 'usageUnitOfMeasure' | 'type'>
  ), invoiceLineItemConnections: Array<(
    { __typename?: 'CareBenefitUsageToInvoiceLineItem' }
    & Pick<CareBenefitUsageToInvoiceLineItem, 'id' | 'adjustedAmount'>
  )> }
);

export type ActivityLogCareEnrollmentFragment = (
  { __typename?: 'CarePlanEnrollment' }
  & Pick<CarePlanEnrollment, 'id' | 'startDate' | 'expirationDate' | 'cancelledAt' | 'renewalRejectionReason' | 'renewalEmailNoticeSentAt'>
  & { renewalSuccessorEnrollment?: Maybe<(
    { __typename?: 'CarePlanEnrollment' }
    & Pick<CarePlanEnrollment, 'id'>
  )>, renewalPredecessorEnrollment?: Maybe<(
    { __typename?: 'CarePlanEnrollment' }
    & Pick<CarePlanEnrollment, 'id'>
    & { plan: (
      { __typename?: 'CarePlan' }
      & Pick<CarePlan, 'id' | 'title' | 'flowAlias'>
    ) }
  )>, plan: (
    { __typename?: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'title' | 'flowAlias'>
  ) }
);

export type ActivityLogEnrollmentSupportEventFragment = (
  { __typename?: 'EnrollmentSupportEvent' }
  & Pick<EnrollmentSupportEvent, 'id' | 'createdAt' | 'carePlanEnrollmentId' | 'manageMembershipRequestType' | 'cancelReason' | 'amount' | 'paymentSchedule'>
  & { requester?: Maybe<(
    { __typename?: 'User' }
    & RecordingUserFragment
  )>, currentPlan?: Maybe<(
    { __typename?: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'flowAlias' | 'title'>
  )>, newPlan?: Maybe<(
    { __typename?: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'flowAlias' | 'title'>
  )>, transferFromPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & Pick<OrganizationPet, 'id' | 'preferredName'>
  )>, transferToPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & Pick<OrganizationPet, 'id' | 'preferredName'>
  )>, carePlanEnrollment: (
    { __typename?: 'CarePlanEnrollment' }
    & Pick<CarePlanEnrollment, 'id'>
    & { careSubscriptionTrueUps: Array<(
      { __typename?: 'CareSubscriptionTrueUp' }
      & Pick<CareSubscriptionTrueUp, 'id'>
    )> }
  ) }
);

export type GetCareActivityLogItemsQueryVariables = Exact<{
  enrollmentWhere?: Maybe<CarePlanEnrollmentWhereInput>;
  supportEventWhere?: Maybe<EnrollmentSupportEventWhereInput>;
  benefitUsageWhere?: Maybe<CareBenefitUsageWhereInput>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetCareActivityLogItemsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyEnrollmentSupportEventCount' | 'findManyCareBenefitUsageCount'>
  & { findManyCarePlanEnrollment?: Maybe<Array<(
    { __typename?: 'CarePlanEnrollment' }
    & ActivityLogCareEnrollmentFragment
  )>>, findManyEnrollmentSupportEvent?: Maybe<Array<(
    { __typename?: 'EnrollmentSupportEvent' }
    & ActivityLogEnrollmentSupportEventFragment
  )>>, findManyCareBenefitUsage?: Maybe<Array<(
    { __typename?: 'CareBenefitUsage' }
    & CareBenefitActivityUsageFragment
  )>> }
);

export type CarePlanInfoFragment = (
  { __typename?: 'CarePlan' }
  & Pick<CarePlan, 'id' | 'pricePerRenewal' | 'planDiscount' | 'renewalCycle' | 'title' | 'flowAlias'>
  & { planBenefits: Array<(
    { __typename?: 'CarePlanBenefit' }
    & Pick<CarePlanBenefit, 'id'>
    & { benefit: (
      { __typename?: 'CareBenefit' }
      & Pick<CareBenefit, 'id' | 'type' | 'reportingExpectedValue'>
    ) }
  )> }
);

export type GetCareEnrollmentInfoQueryVariables = Exact<{
  enrollmentId?: Maybe<Scalars['String']>;
}>;


export type GetCareEnrollmentInfoQuery = (
  { __typename?: 'Query' }
  & { findUniqueCarePlanEnrollment?: Maybe<(
    { __typename?: 'CarePlanEnrollment' }
    & Pick<CarePlanEnrollment, 'id' | 'startDate'>
    & { plan: (
      { __typename?: 'CarePlan' }
      & CarePlanInfoFragment
    ), enrollmentPayments: Array<(
      { __typename?: 'CareEnrollmentPayment' }
      & Pick<CareEnrollmentPayment, 'id' | 'amount' | 'applicationFeeAmount' | 'paidAt'>
    )> }
  )> }
);

export type GetCareEnrollmentTrueUpHistoryQueryVariables = Exact<{
  where?: Maybe<CareSubscriptionTrueUpWhereInput>;
}>;


export type GetCareEnrollmentTrueUpHistoryQuery = (
  { __typename?: 'Query' }
  & { findManyCareSubscriptionTrueUp?: Maybe<Array<(
    { __typename?: 'CareSubscriptionTrueUp' }
    & Pick<CareSubscriptionTrueUp, 'id' | 'dueAt' | 'completedAt' | 'retryAt' | 'amount' | 'shouldCancelEnrollment'>
  )>> }
);

export type GetCareSavedPaymentMethodsQueryVariables = Exact<{
  where?: Maybe<StripePaymentMethodWhereInput>;
}>;


export type GetCareSavedPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { findManyStripePaymentMethod?: Maybe<Array<(
    { __typename?: 'StripePaymentMethod' }
    & Pick<StripePaymentMethod, 'id' | 'isDefault' | 'isExpired' | 'last4' | 'cardBrand' | 'expirationMonth' | 'expirationYear' | 'stripeId'>
  )>> }
);

export type GetBalanceForClientQueryVariables = Exact<{
  where: GetBalanceForClientInput;
}>;


export type GetBalanceForClientQuery = (
  { __typename?: 'Query' }
  & { getBalanceForClient?: Maybe<(
    { __typename?: 'BitwerxClientBalance' }
    & BalanceForClientFragment
  )> }
);

export type BalanceForClientFragment = (
  { __typename?: 'BitwerxClientBalance' }
  & Pick<BitwerxClientBalance, 'currentBalance' | 'agingCurrent' | 'aging30D' | 'aging60D' | 'aging90D' | 'aging120D'>
);

export type GetInvoicesForClientQueryVariables = Exact<{
  where: InvoiceWhereInput;
}>;


export type GetInvoicesForClientQuery = (
  { __typename?: 'Query' }
  & { getInvoicesForClient: Array<(
    { __typename?: 'Invoice' }
    & InvoiceForClientFragment
  )> }
);

export type InvoiceForClientFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'status' | 'createdAt' | 'updatedAt' | 'subtotal' | 'taxes' | 'total' | 'date' | 'outstandingBalance' | 'currency' | 'identifier'>
  & { sentBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nameDisplay' | 'firstName' | 'lastName'>
  )>, clinicPetParent: (
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
  ), stripePaymentIntents: Array<(
    { __typename?: 'StripePaymentIntent' }
    & StripePaymentIntentStatusFragment
  )>, relatedInvoiceWritebacks: Array<(
    { __typename?: 'InvoicePimsWritebackLogEntry' }
    & Pick<InvoicePimsWritebackLogEntry, 'action'>
  )> }
);

export type StripePaymentIntentStatusFragment = (
  { __typename?: 'StripePaymentIntent' }
  & Pick<StripePaymentIntent, 'status'>
);

export type ClinicCarePlanFragment = (
  { __typename?: 'CarePlan' }
  & Pick<CarePlan, 'id' | 'title' | 'flowAlias' | 'description' | 'published' | 'pricePerRenewal' | 'renewalCycle'>
);

export type ClinicCarePlanProviderGroupFragment = (
  { __typename?: 'CarePlanProviderGroup' }
  & Pick<CarePlanProviderGroup, 'id'>
  & { associatedPlans: Array<(
    { __typename?: 'CarePlan' }
    & ClinicCarePlanFragment
  )> }
);

export type GetClinicCarePlansQueryVariables = Exact<{
  where?: Maybe<CarePlanProviderGroupWhereInput>;
}>;


export type GetClinicCarePlansQuery = (
  { __typename?: 'Query' }
  & { findFirstCarePlanProviderGroup?: Maybe<(
    { __typename?: 'CarePlanProviderGroup' }
    & ClinicCarePlanProviderGroupFragment
  )> }
);

export type GetCustomerStripePortalUrlQueryVariables = Exact<{
  data?: Maybe<StripeCustomerPortalInput>;
  clinicPetParentId?: Maybe<Scalars['String']>;
}>;


export type GetCustomerStripePortalUrlQuery = (
  { __typename?: 'Query' }
  & { stripeRetrievePetParentPortalUrl?: Maybe<(
    { __typename?: 'StripeCustomerPortalOutput' }
    & Pick<StripeCustomerPortalOutput, 'id' | 'url'>
  )> }
);

export type CareBenefitPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & { organizationPetParent?: Maybe<(
    { __typename?: 'OrganizationPetParent' }
    & Pick<OrganizationPetParent, 'id' | 'stripeCustomerTableId'>
  )> }
  & StripeTerminalClinicPetParentFragment
  & PetParentAvatarFragment
);

export type StripeTerminalClinicPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'email' | 'pimsId'>
);

export type GetSidePanelBenefitsClinicPetParentQueryVariables = Exact<{
  clinicPetParentId: Scalars['String'];
}>;


export type GetSidePanelBenefitsClinicPetParentQuery = (
  { __typename?: 'Query' }
  & { findUniqueClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & CareBenefitPetParentFragment
  )> }
);

export type LoyaltyProgramRewardRedemptionFragment = (
  { __typename?: 'LoyaltyRewardRedemption' }
  & Pick<LoyaltyRewardRedemption, 'id' | 'createdAt'>
  & { reward: (
    { __typename?: 'LoyaltyReward' }
    & Pick<LoyaltyReward, 'id' | 'title' | 'description' | 'pointCost'>
  ) }
);

export type RedemptionHistoryFragment = (
  { __typename?: 'LoyaltyRewardRedemptionHistoryEntry' }
  & Pick<LoyaltyRewardRedemptionHistoryEntry, 'id' | 'createdAt' | 'status' | 'actorType'>
  & { actingUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type RewardHistoryFragment = (
  { __typename?: 'LoyaltyPointDelta' }
  & Pick<LoyaltyPointDelta, 'id' | 'createdAt' | 'source' | 'points'>
  & { userGrant?: Maybe<(
    { __typename?: 'LoyaltyPointUserGrant' }
    & Pick<LoyaltyPointUserGrant, 'id' | 'amount' | 'publicNotes' | 'internalNotes'>
    & { grantingUser: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  )>, relatedRedemptionHistoryEntry?: Maybe<(
    { __typename?: 'LoyaltyRewardRedemptionHistoryEntry' }
    & Pick<LoyaltyRewardRedemptionHistoryEntry, 'id'>
    & { redemption: (
      { __typename?: 'LoyaltyRewardRedemption' }
      & Pick<LoyaltyRewardRedemption, 'id' | 'updatedAt' | 'status'>
      & { reward: (
        { __typename?: 'LoyaltyReward' }
        & Pick<LoyaltyReward, 'id' | 'title' | 'pointCost'>
      ), redemptionHistories: Array<(
        { __typename?: 'LoyaltyRewardRedemptionHistoryEntry' }
        & RedemptionHistoryFragment
      )> }
    ) }
  )> }
);

export type ClaimRewardMutationVariables = Exact<{
  data: RequestLoyaltyRewardInput;
}>;


export type ClaimRewardMutation = (
  { __typename?: 'Mutation' }
  & { requestLoyaltyReward: (
    { __typename?: 'LoyaltyRewardRedemption' }
    & Pick<LoyaltyRewardRedemption, 'id'>
  ) }
);

export type FindOrCreateLoyaltyAccountForClinicPetParentMutationVariables = Exact<{
  clinicPetParentId: Scalars['String'];
}>;


export type FindOrCreateLoyaltyAccountForClinicPetParentMutation = (
  { __typename?: 'Mutation' }
  & { findOrCreateLoyaltyAccountForClinicPetParent: (
    { __typename?: 'LoyaltyAccount' }
    & Pick<LoyaltyAccount, 'id' | 'pointBalance'>
    & { program: (
      { __typename?: 'LoyaltyProgram' }
      & Pick<LoyaltyProgram, 'id' | 'status'>
    ), rewardRedemptions: Array<(
      { __typename?: 'LoyaltyRewardRedemption' }
      & LoyaltyProgramRewardRedemptionFragment
    )> }
  ) }
);

export type FulfillRewardMutationVariables = Exact<{
  data: LoyaltyRewardRedemptionInput;
}>;


export type FulfillRewardMutation = (
  { __typename?: 'Mutation' }
  & { fulfillLoyaltyRewardRedemption: (
    { __typename?: 'LoyaltyRewardRedemption' }
    & Pick<LoyaltyRewardRedemption, 'id'>
  ) }
);

export type GrantPointsMutationVariables = Exact<{
  data: LoyaltyPointGrantByUser;
}>;


export type GrantPointsMutation = (
  { __typename?: 'Mutation' }
  & { submitLoyaltyPointGrantToAccountByUser: (
    { __typename?: 'OperationResult' }
    & Pick<OperationResult, 'success'>
  ) }
);

export type GetFirstLoyaltyRewardRedemptionQueryVariables = Exact<{
  where?: Maybe<LoyaltyRewardRedemptionWhereInput>;
}>;


export type GetFirstLoyaltyRewardRedemptionQuery = (
  { __typename?: 'Query' }
  & { findFirstLoyaltyRewardRedemption?: Maybe<(
    { __typename?: 'LoyaltyRewardRedemption' }
    & Pick<LoyaltyRewardRedemption, 'id'>
  )> }
);

export type GetPetParentLoyaltyAccountHolderQueryVariables = Exact<{
  where?: Maybe<LoyaltyAccountHolderWhereInput>;
}>;


export type GetPetParentLoyaltyAccountHolderQuery = (
  { __typename?: 'Query' }
  & { findFirstLoyaltyAccountHolder?: Maybe<(
    { __typename?: 'LoyaltyAccountHolder' }
    & Pick<LoyaltyAccountHolder, 'id'>
    & { account: (
      { __typename?: 'LoyaltyAccount' }
      & Pick<LoyaltyAccount, 'id' | 'pointBalance'>
      & { rewardRedemptions: Array<(
        { __typename?: 'LoyaltyRewardRedemption' }
        & LoyaltyProgramRewardRedemptionFragment
      )> }
    ) }
  )> }
);

export type GetPetParentLoyaltyProgramDataQueryVariables = Exact<{
  where?: Maybe<ClinicPetParentWhereInput>;
}>;


export type GetPetParentLoyaltyProgramDataQuery = (
  { __typename?: 'Query' }
  & { findFirstClinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
    & { loyaltyAccountHolder?: Maybe<(
      { __typename?: 'LoyaltyAccountHolder' }
      & Pick<LoyaltyAccountHolder, 'id' | 'programId'>
      & { account: (
        { __typename?: 'LoyaltyAccount' }
        & Pick<LoyaltyAccount, 'id' | 'pointBalance'>
      ) }
    )> }
  )> }
);

export type GetRewardsHistoryQueryVariables = Exact<{
  where?: Maybe<LoyaltyPointDeltaWhereInput>;
  take: Scalars['Int'];
}>;


export type GetRewardsHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyLoyaltyPointDeltaCount'>
  & { findManyLoyaltyPointDelta?: Maybe<Array<(
    { __typename?: 'LoyaltyPointDelta' }
    & RewardHistoryFragment
  )>> }
);

export type CheckPetEnrollmentSubscriptionVariables = Exact<{
  where: CareEnrollmentWhereInput;
}>;


export type CheckPetEnrollmentSubscription = (
  { __typename?: 'Subscription' }
  & { checkPetEnrollment: (
    { __typename?: 'CareEnrollmentOutput' }
    & Pick<CareEnrollmentOutput, 'isEnrolled'>
  ) }
);

export type CheckPetParentHasEnrolledPetSubscriptionVariables = Exact<{
  where: HasCarePetWithEnrollmentWhereInput;
}>;


export type CheckPetParentHasEnrolledPetSubscription = (
  { __typename?: 'Subscription' }
  & { checkPetParentHasEnrolledPet: (
    { __typename?: 'HasCarePetWithEnrollmentOutput' }
    & Pick<HasCarePetWithEnrollmentOutput, 'hasEnrolledPet'>
  ) }
);

export type SubscribeToLoyaltyAccountSubscriptionVariables = Exact<{
  where: LoyaltyAccountWhereUniqueInput;
}>;


export type SubscribeToLoyaltyAccountSubscription = (
  { __typename?: 'Subscription' }
  & { loyaltyAccountUpdated: (
    { __typename?: 'LoyaltyAccountSubscription' }
    & Pick<LoyaltyAccountSubscription, 'mutation'>
    & { node: (
      { __typename?: 'LoyaltyAccount' }
      & Pick<LoyaltyAccount, 'id' | 'pointBalance'>
    ) }
  ) }
);

export type SubscribeToLoyaltyProgramUpdateSubscriptionVariables = Exact<{
  where: LoyaltyProgramWhereUniqueInput;
}>;


export type SubscribeToLoyaltyProgramUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { loyaltyProgramUpdated: (
    { __typename?: 'LoyaltyProgramSubscription' }
    & Pick<LoyaltyProgramSubscription, 'mutation'>
    & { node: (
      { __typename?: 'LoyaltyProgram' }
      & Pick<LoyaltyProgram, 'id' | 'status'>
    ) }
  ) }
);

export type SubscribeToLoyaltyRewardRedemptionUpdatedSubscriptionVariables = Exact<{
  where: LoyaltyRewardRedemptionWhereUniqueInput;
}>;


export type SubscribeToLoyaltyRewardRedemptionUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { loyaltyRewardRedemptionUpdated: (
    { __typename?: 'LoyaltyRewardRedemptionSubscription' }
    & Pick<LoyaltyRewardRedemptionSubscription, 'mutation'>
    & { node: (
      { __typename?: 'LoyaltyRewardRedemption' }
      & Pick<LoyaltyRewardRedemption, 'id' | 'status'>
    ) }
  ) }
);

export type CareSubscriptionFragment = (
  { __typename?: 'CareStripeSubscription' }
  & Pick<CareStripeSubscription, 'id' | 'paymentStatus' | 'nextBillingDate'>
  & { stripePaymentMethod?: Maybe<(
    { __typename?: 'StripePaymentMethod' }
    & Pick<StripePaymentMethod, 'id' | 'last4' | 'stripeId' | 'cardBrand' | 'expirationMonth' | 'expirationYear' | 'isExpired'>
  )> }
);

export type CareEnrollmentFragment = (
  { __typename?: 'CarePlanEnrollment' }
  & Pick<CarePlanEnrollment, 'id' | 'planId' | 'status' | 'startDate' | 'autoRenews' | 'expirationDate' | 'careStripeSubscriptionId'>
  & { careStripeSubscription?: Maybe<(
    { __typename?: 'CareStripeSubscription' }
    & Pick<CareStripeSubscription, 'paymentStatus'>
  )>, plan: (
    { __typename?: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'title' | 'flowAlias' | 'pricePerRenewal'>
    & { planBenefits: Array<(
      { __typename?: 'CarePlanBenefit' }
      & Pick<CarePlanBenefit, 'id' | 'benefitId' | 'displayOrder'>
    )> }
  ), careSubscriptionTrueUps: Array<(
    { __typename?: 'CareSubscriptionTrueUp' }
    & EnrollmentTrueUpsFragment
  )>, enrollmentPayments: Array<(
    { __typename?: 'CareEnrollmentPayment' }
    & Pick<CareEnrollmentPayment, 'id' | 'amount' | 'applicationFeeAmount' | 'paidAt'>
  )> }
);

export type EnrollmentTrueUpsFragment = (
  { __typename?: 'CareSubscriptionTrueUp' }
  & Pick<CareSubscriptionTrueUp, 'id' | 'dueAt' | 'completedAt' | 'retryAt' | 'amount' | 'shouldCancelEnrollment'>
);

export type SidePanelOrganizationPetFragment = (
  { __typename?: 'OrganizationPet' }
  & Pick<OrganizationPet, 'id'>
  & { associatedSubscriptions: Array<(
    { __typename?: 'CareStripeSubscription' }
    & CareSubscriptionFragment
  )>, carePlanEnrollments: Array<(
    { __typename?: 'CarePlanEnrollment' }
    & CareEnrollmentFragment
  )> }
);

export type SidePanelParentPetDataFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId' | 'weight' | 'clinicId'>
  & { organizationPet?: Maybe<(
    { __typename?: 'OrganizationPet' }
    & SidePanelOrganizationPetFragment
  )> }
  & PetAvatarFragment
);

export type UpdatePetParentSettingMutationVariables = Exact<{
  data: PetParentSettingUpdateInput;
  where: PetParentSettingWhereUniqueInput;
}>;


export type UpdatePetParentSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOnePetParentSetting: (
    { __typename?: 'PetParentSetting' }
    & SidePanelProfilePetParentSettingFragment
  ) }
);

export type HasDeviceThatCanRecievePushQueryVariables = Exact<{
  clinicPetParentIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HasDeviceThatCanRecievePushQuery = (
  { __typename?: 'Query' }
  & { findFirstClinicPetParentDevice?: Maybe<(
    { __typename?: 'ClinicPetParentDevice' }
    & Pick<ClinicPetParentDevice, 'id' | 'pushNotificationStatus'>
  )> }
);

export type GetSidePanelParentPetsQueryVariables = Exact<{
  where?: Maybe<ClinicPetWhereInput>;
  take: Scalars['Int'];
  orderBy?: Maybe<Array<ClinicPetOrderByWithRelationAndSearchRelevanceInput> | ClinicPetOrderByWithRelationAndSearchRelevanceInput>;
}>;


export type GetSidePanelParentPetsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'findManyClinicPetCount'>
  & { findManyClinicPet?: Maybe<Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id'>
    & SidePanelParentPetDataFragment
  )>> }
);

export type SidePanelProfilePetParentSettingFragment = (
  { __typename?: 'PetParentSetting' }
  & Pick<PetParentSetting, 'id' | 'createdAt' | 'updatedAt' | 'emailNotifications' | 'emailOptedOutByPetParent' | 'smsNotifications' | 'smsOptedOutByPetParent' | 'sendSurvey' | 'pushNotifications'>
);

export type SidePanelPetParentProfilePhoneNumberFragment = (
  { __typename?: 'ClinicEntityPhoneNumber' }
  & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
  & { phoneNumberLookupResult?: Maybe<(
    { __typename?: 'PhoneNumberLookupResult' }
    & Pick<PhoneNumberLookupResult, 'id' | 'lineType'>
  )>, phoneNumberLookupRequest?: Maybe<(
    { __typename?: 'PhoneNumberLookupRequest' }
    & Pick<PhoneNumberLookupRequest, 'id' | 'status'>
  )> }
);

export type SidePanelProfileClinicPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive' | 'createdAt' | 'updatedAt' | 'pimsId'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & SidePanelPetParentProfilePhoneNumberFragment
  )>, petParentSetting?: Maybe<(
    { __typename?: 'PetParentSetting' }
    & SidePanelProfilePetParentSettingFragment
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'breed' | 'gender' | 'isActive' | 'isDeleted' | 'isDeceased' | 'dateOfBirth' | 'name' | 'species' | 'speciesType' | 'weight' | 'weightUnit' | 'photoUrl'>
    & { alerts: Array<(
      { __typename?: 'ClinicPetAlert' }
      & Pick<ClinicPetAlert, 'value' | 'pimsDate' | 'pimsId' | 'isMedical'>
    )> }
    & OrganizationPetCareBadgePlanEnrollmentFragment
  )>, loyaltyAccountHolder?: Maybe<(
    { __typename?: 'LoyaltyAccountHolder' }
    & Pick<LoyaltyAccountHolder, 'id'>
    & { program: (
      { __typename?: 'LoyaltyProgram' }
      & Pick<LoyaltyProgram, 'id' | 'status'>
    ), account: (
      { __typename?: 'LoyaltyAccount' }
      & Pick<LoyaltyAccount, 'pointBalance'>
      & { rewardRedemptions: Array<(
        { __typename?: 'LoyaltyRewardRedemption' }
        & Pick<LoyaltyRewardRedemption, 'id'>
      )> }
    ) }
  )> }
  & PetParentAvatarFragment
);

export type GetSidePanelPetParentProfileQueryVariables = Exact<{
  clinicPetParentId: Scalars['String'];
  clinicId: Scalars['String'];
}>;


export type GetSidePanelPetParentProfileQuery = (
  { __typename?: 'Query' }
  & { clinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & SidePanelProfileClinicPetParentFragment
  )> }
);

export type UpdateClinicPetWithoutPimsMutationVariables = Exact<{
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUpdateInput;
}>;


export type UpdateClinicPetWithoutPimsMutation = (
  { __typename?: 'Mutation' }
  & { updateClinicPetWithoutPims?: Maybe<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'id' | 'dateOfDeath'>
    & PetAvatarFragment
  )> }
);

export type UpdateOneClinicPetMutationVariables = Exact<{
  where: ClinicPetWhereUniqueInput;
  data: ClinicPetUpdateInput;
}>;


export type UpdateOneClinicPetMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicPet: (
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'dateOfDeath'>
    & PetAvatarFragment
  ) }
);

export type SidePanelPetServiceRemindersFragment = (
  { __typename?: 'ServiceReminder' }
  & Pick<ServiceReminder, 'id' | 'dueDate' | 'dateLastGiven'>
  & { service: (
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'reminderActivationDate'>
  ) }
);

export type GetSidePanelPetProfileQueryVariables = Exact<{
  clinicPetId?: Maybe<Scalars['String']>;
}>;


export type GetSidePanelPetProfileQuery = (
  { __typename?: 'Query' }
  & { clinicPet?: Maybe<(
    { __typename?: 'ClinicPet' }
    & Pick<ClinicPet, 'dateOfBirth' | 'dateOfDeath' | 'breed' | 'color' | 'gender' | 'weight' | 'weightUnit' | 'pimsId' | 'isDeleted'>
    & { alerts: Array<(
      { __typename?: 'ClinicPetAlert' }
      & Pick<ClinicPetAlert, 'value' | 'pimsDate' | 'pimsId' | 'isMedical'>
    )>, petParents: Array<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      & PetParentAvatarFragment
    )>, serviceReminders: Array<(
      { __typename?: 'ServiceReminder' }
      & SidePanelPetServiceRemindersFragment
    )> }
    & PetAvatarFragment
    & OrganizationPetCareBadgePlanEnrollmentFragment
  )> }
);

export type SidePanelSearchClinicEntityPhoneNumberFragment = (
  { __typename?: 'ClinicEntityPhoneNumber' }
  & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
);

export type SidePanelSearchClinicPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'pimsId' | 'speciesType' | 'breed' | 'gender' | 'isActive' | 'isDeleted'>
  & PetAvatarFragment
);

export type SidePanelSearchClinicPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'updatedAt' | 'firstName' | 'lastName' | 'email' | 'isActive' | 'pimsId'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & SidePanelSearchClinicEntityPhoneNumberFragment
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & SidePanelSearchClinicPetFragment
  )> }
  & PetParentAvatarFragment
);

export type SidePanelSearchQueryVariables = Exact<{
  whereInput?: Maybe<SearchClinicPetParentInput>;
  pageSize?: Maybe<Scalars['Int']>;
  petWhere?: Maybe<ClinicPetWhereInput>;
}>;


export type SidePanelSearchQuery = (
  { __typename?: 'Query' }
  & { clinicPetParentSearch: Array<(
    { __typename?: 'ClinicPetParent' }
    & SidePanelSearchClinicPetParentFragment
  )> }
);

export type SidePanelPetFragment = (
  { __typename?: 'ClinicPet' }
  & Pick<ClinicPet, 'id' | 'breed' | 'gender'>
  & PetAvatarFragment
);

export type SidePanelPetParentFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
  )> }
  & PetParentAvatarFragment
);

export type ExistingClinicPetParentMatchFragment = (
  { __typename?: 'ClinicPetParent' }
  & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'creationSource'>
  & { phoneNumbers: Array<(
    { __typename?: 'ClinicEntityPhoneNumber' }
    & Pick<ClinicEntityPhoneNumber, 'id' | 'updatedAt' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted'>
  )>, pets: Array<(
    { __typename?: 'ClinicPet' }
    & PetAvatarFragment
  )> }
);

export type CheckForExistingClinicPetParentQueryVariables = Exact<{
  where: ClinicPetParentWhereInput;
}>;


export type CheckForExistingClinicPetParentQuery = (
  { __typename?: 'Query' }
  & { findManyClinicPetParent?: Maybe<Array<(
    { __typename?: 'ClinicPetParent' }
    & ExistingClinicPetParentMatchFragment
  )>> }
);

export type ChannelStatusFragment = (
  { __typename?: 'ChannelStatus' }
  & Pick<ChannelStatus, 'id' | 'createdAt' | 'updatedAt' | 'channelStatusAction' | 'color' | 'deletedOn' | 'isDefault' | 'name' | 'order' | 'isExportedToPims'>
);

export type ChannelStatusChannelAssigneeFragmentFragment = (
  { __typename?: 'ChannelStatusChannelAssignee' }
  & Pick<ChannelStatusChannelAssignee, 'id'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type FullChannelStatusFragment = (
  { __typename?: 'ChannelStatus' }
  & Pick<ChannelStatus, 'id' | 'createdAt' | 'updatedAt' | 'channelStatusAction' | 'color' | 'deletedOn' | 'isDefault' | 'name' | 'order' | 'isExportedToPims'>
  & { channelStatusChannelAssignees: Array<(
    { __typename?: 'ChannelStatusChannelAssignee' }
    & ChannelStatusChannelAssigneeFragmentFragment
  )> }
);

export type SharedUserNotificationSettingsFragment = (
  { __typename?: 'UserSetting' }
  & Pick<UserSetting, 'id' | 'snoozeNotificationsUntil'>
  & { browserNotificationFilter?: Maybe<(
    { __typename?: 'ChannelSelection' }
    & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
    & { assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>, notificationSettings: Array<(
    { __typename?: 'UserNotificationSetting' }
    & Pick<UserNotificationSetting, 'id' | 'emailEnabled' | 'smsEnabled' | 'soundNotificationUrl' | 'browserNotificationEnabled' | 'pushNotificationEnabled'>
  )> }
);

export type StripePaymentIntentFragment = (
  { __typename?: 'StripePaymentIntent' }
  & Pick<StripePaymentIntent, 'id' | 'createdAt' | 'updatedAt' | 'stripeId' | 'status' | 'amount' | 'clientServiceFee' | 'total' | 'amountCaptured' | 'serviceFeeAmount' | 'clientServiceFeePercent' | 'lastPaymentError'>
  & { clinicPetParent?: Maybe<(
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
  )>, stripePaymentMethod?: Maybe<(
    { __typename?: 'StripePaymentMethod' }
    & Pick<StripePaymentMethod, 'last4' | 'cardBrand'>
  )>, stripeRefunds: Array<(
    { __typename?: 'StripeRefund' }
    & Pick<StripeRefund, 'id' | 'amount' | 'clientFeeRefunded' | 'updatedAt'>
  )> }
);

export type StripePaymentOutputFragment = (
  { __typename?: 'StripePaymentOutput' }
  & Pick<StripePaymentOutput, 'paymentIntentClientSecret' | 'requiresAction'>
  & { payment: (
    { __typename?: 'StripePayment' }
    & Pick<StripePayment, 'id' | 'status' | 'amount' | 'total' | 'amountCaptured' | 'amountRefunded' | 'transferType' | 'receiptUrl'>
  ) }
);

export type AddChannelMemberMutationVariables = Exact<{
  channelId: Scalars['String'];
  clinicPetParentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}>;


export type AddChannelMemberMutation = (
  { __typename?: 'Mutation' }
  & { addChannelMember?: Maybe<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'removedAt'>
    & { clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type AddClinicFeatureFlagMutationVariables = Exact<{
  clinicId: Scalars['String'];
  featureFlagKey: Scalars['String'];
}>;


export type AddClinicFeatureFlagMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
    & { featureFlags: Array<(
      { __typename?: 'FeatureFlag' }
      & Pick<FeatureFlag, 'id' | 'key'>
    )> }
  ) }
);

export type CancelStripePaymentIntentMutationVariables = Exact<{
  where: StripePaymentIntentWhereUniqueInput;
}>;


export type CancelStripePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { cancelStripePaymentIntent2?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & Pick<StripePaymentIntent, 'id' | 'status'>
  )> }
);

export type ClearTerminalDisplayMutationVariables = Exact<{
  stripeTerminalId: Scalars['String'];
}>;


export type ClearTerminalDisplayMutation = (
  { __typename?: 'Mutation' }
  & { clearTerminalDisplay?: Maybe<(
    { __typename?: 'ClearTerminalDisplayResponse' }
    & Pick<ClearTerminalDisplayResponse, 'success' | 'error'>
  )> }
);

export type ClearUserInvitationMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ClearUserInvitationMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'invitationCode'>
  ) }
);

export type CreateFormSubmissionMutationVariables = Exact<{
  data: FormSubmissionCreateInput;
}>;


export type CreateFormSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createOneFormSubmission: (
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id'>
  ) }
);

export type StripePaymentIntentSelectionFragment = (
  { __typename?: 'StripePaymentIntent' }
  & Pick<StripePaymentIntent, 'id' | 'status' | 'amount' | 'amountCaptured' | 'serviceFeeAmount' | 'stripeId'>
  & { stripePaymentMethod?: Maybe<(
    { __typename?: 'StripePaymentMethod' }
    & Pick<StripePaymentMethod, 'cardBrand' | 'last4' | 'type'>
  )> }
);

export type CreateStripePaymentIntentMutationVariables = Exact<{
  data: StripeCreatePaymentInput;
  clinicPetParentId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  paymentMedium?: Maybe<PaymentMedium>;
  stripeTerminalStripeId?: Maybe<Scalars['String']>;
  invoiceIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  paymentRelationship?: Maybe<PaymentRelationship>;
  emailOverride?: Maybe<Scalars['String']>;
  careAccountCreditIntentId?: Maybe<Scalars['String']>;
  cardType?: Maybe<CardFunding>;
}>;


export type CreateStripePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createStripePaymentIntent2?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & StripePaymentIntentSelectionFragment
  )> }
);

export type CreateSystemChannelMessageMutationVariables = Exact<{
  data: SystemMessageCreateInput;
}>;


export type CreateSystemChannelMessageMutation = (
  { __typename?: 'Mutation' }
  & { createSystemChannelMessage?: Maybe<(
    { __typename?: 'ChannelMessage' }
    & Pick<ChannelMessage, 'id' | 'createdAt' | 'updatedAt' | 'index' | 'body' | 'messageType'>
    & { attachments: Array<(
      { __typename?: 'ChannelMessageAttachment' }
      & Pick<ChannelMessageAttachment, 'id' | 'attachmentType' | 'entityType' | 'entityId' | 'filename' | 'url'>
    )>, author?: Maybe<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id' | 'twilioIdentity'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePic'>
      )>, clinicPetParent?: Maybe<(
        { __typename?: 'ClinicPetParent' }
        & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      )> }
    )>, channel?: Maybe<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
    )> }
  )> }
);

export type GetOrCreateActiveClientChannelMutationVariables = Exact<{
  data: GetOrCreateActiveClientChannelInput;
}>;


export type GetOrCreateActiveClientChannelMutation = (
  { __typename?: 'Mutation' }
  & { getOrCreateActiveClientChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id'>
  )> }
);

export type ProcessTerminalPaymentMutationVariables = Exact<{
  stripeTerminalId: Scalars['String'];
  paymentIntent: PaymentIntentInput;
}>;


export type ProcessTerminalPaymentMutation = (
  { __typename?: 'Mutation' }
  & { processTerminalPayment?: Maybe<(
    { __typename?: 'ProcessTerminalPaymentResponse' }
    & Pick<ProcessTerminalPaymentResponse, 'success' | 'stripePaymentIntentId' | 'error'>
  )> }
);

export type RefundStripePaymentIntentMutationVariables = Exact<{
  where: StripePaymentIntentWhereUniqueInput;
  data: EnhancedStripeRefundPaymentInput;
}>;


export type RefundStripePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { refundStripePaymentIntent2?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & Pick<StripePaymentIntent, 'id'>
  )> }
);

export type RemoveChannelMemberMutationVariables = Exact<{
  channelMemberId: Scalars['String'];
}>;


export type RemoveChannelMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeChannelMember?: Maybe<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'removedAt'>
    & { clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type RemoveClinicFeatureFlagMutationVariables = Exact<{
  clinicId: Scalars['String'];
  featureFlagKey: Scalars['String'];
}>;


export type RemoveClinicFeatureFlagMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinic: (
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id'>
    & { featureFlags: Array<(
      { __typename?: 'FeatureFlag' }
      & Pick<FeatureFlag, 'id' | 'key'>
    )> }
  ) }
);

export type UpdateCurrentClinicMutationVariables = Exact<{
  vetInfoId: Scalars['String'];
  clinicId: Scalars['String'];
}>;


export type UpdateCurrentClinicMutation = (
  { __typename?: 'Mutation' }
  & { updateOneVetInfo: (
    { __typename?: 'VetInfo' }
    & ClinicUserVetInfoFragment
  ) }
);

export type UpdateLeftNavPanelToggleMutationVariables = Exact<{
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
}>;


export type UpdateLeftNavPanelToggleMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUserSetting: (
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'leftNavPanel'>
  ) }
);

export type UpdateOneClinicPetParentMutationVariables = Exact<{
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUpdateInput;
}>;


export type UpdateOneClinicPetParentMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicPetParent: (
    { __typename?: 'ClinicPetParent' }
    & ClinicPetParentSelectionFragment
  ) }
);

export type ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutationFragment = (
  { __typename?: 'ClinicPetParentWithJobChainsResponse' }
  & { clinicPetParent: (
    { __typename?: 'ClinicPetParent' }
    & Pick<ClinicPetParent, 'id' | 'email' | 'firstName' | 'lastName' | 'isActive' | 'updatedAt' | 'pimsId'>
    & { phoneNumbers: Array<(
      { __typename?: 'ClinicEntityPhoneNumber' }
      & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'isPrimary' | 'isMobile' | 'isDeleted' | 'updatedAt'>
    )> }
    & PetParentAvatarFragment
  ), jobChains?: Maybe<Array<Maybe<(
    { __typename?: 'JobChain' }
    & Pick<JobChain, 'id' | 'name'>
  )>>> }
);

export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationVariables = Exact<{
  where: ClinicPetParentWhereUniqueInput;
  data: ClinicPetParentUpdateInput;
}>;


export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClinicPetParentAndEnqueuePhoneNumberLookup: (
    { __typename?: 'ClinicPetParentWithJobChainsResponse' }
    & ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutationFragment
  ) }
);

export type GetAllClinicChannelStatusesQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetAllClinicChannelStatusesQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & FullChannelStatusFragment
  )>> }
);

export type ClinicFormTemplateFragment = (
  { __typename?: 'FormTemplate' }
  & Pick<FormTemplate, 'id' | 'isActive' | 'isDeleted' | 'content' | 'draftContent' | 'title' | 'createdAt' | 'updatedAt' | 'formTemplateType' | 'autoExportEnabled'>
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name'>
  )>, clinicWidgetRequestTypes: Array<(
    { __typename?: 'ClinicWidgetRequestType' }
    & Pick<ClinicWidgetRequestType, 'id' | 'displayName' | 'requestType'>
  )>, clinic?: Maybe<(
    { __typename?: 'Clinic' }
    & Pick<Clinic, 'id' | 'hasServiceReminders'>
  )> }
);

export type GetClinicFormTemplatesQueryVariables = Exact<{
  where: FormTemplateWhereInput;
}>;


export type GetClinicFormTemplatesQuery = (
  { __typename?: 'Query' }
  & { findManyFormTemplate?: Maybe<Array<(
    { __typename?: 'FormTemplate' }
    & ClinicFormTemplateFragment
  )>> }
);

export type ClinicPimsIntegrationFragment = (
  { __typename?: 'ClinicPimsIntegration' }
  & Pick<ClinicPimsIntegration, 'id' | 'type' | 'isActive' | 'lastSyncedAt' | 'status' | 'displayName' | 'isExportToPimsSupported' | 'isInvoicePreviewSupported' | 'isInvoiceDiscoverySupported' | 'isTriggerPimsSyncSupported' | 'isClientIdSupported' | 'isMergeExistingPimsProfileSupported' | 'isContactSyncSupported' | 'isBillingCodeSupported' | 'isPatientIdSupported' | 'isServiceCodeSupported' | 'arePostcardsSupported' | 'systemName'>
  & { vetdata?: Maybe<(
    { __typename?: 'VetDataIntegration' }
    & Pick<VetDataIntegration, 'id' | 'system' | 'isMultiSite'>
  )>, bitwerx?: Maybe<(
    { __typename?: 'BitwerxIntegration' }
    & Pick<BitwerxIntegration, 'id' | 'practiceId' | 'system'>
  )>, capabilities: Array<(
    { __typename?: 'ClinicPimsIntegrationCapability' }
    & Pick<ClinicPimsIntegrationCapability, 'id' | 'capability' | 'isActive' | 'lastSyncedAt'>
  )> }
);

export type ClinicUserClinicFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'createdAt' | 'name' | 'phone' | 'submerchantStripeId' | 'submerchantStripeChargesEnabled' | 'submerchantStripePayoutsEnabled' | 'timezoneName' | 'logo' | 'fullAddress' | 'websiteUrl' | 'clinicEmail' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'country' | 'city' | 'state' | 'isCallerIdVerified' | 'hasSmsConversations' | 'hasServiceReminders' | 'activationDate' | 'isClinicActive' | 'crmId' | 'organizationId' | 'isAddAppointmentDisabled' | 'carePlanProviderGroupId'>
  & { roles: Array<(
    { __typename?: 'StaffRole' }
    & Pick<StaffRole, 'id' | 'role'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'type'>
    )> }
  )>, integrations: Array<(
    { __typename?: 'ClinicPimsIntegration' }
    & ClinicPimsIntegrationFragment
  )>, clinicOfficeHours: Array<(
    { __typename?: 'ClinicOfficeHour' }
    & Pick<ClinicOfficeHour, 'id' | 'type' | 'dayOfWeek' | 'openAt' | 'closeAt' | 'isEnabled' | 'isOpenTwentyFourHours'>
  )>, clinicSetting?: Maybe<(
    { __typename?: 'ClinicSetting' }
    & Pick<ClinicSetting, 'id' | 'appointmentTimeSlotIncrementInMinutes' | 'hasCustomizableFees' | 'hideAppointmentsWithoutPetOwner' | 'outOfOfficeHeadline' | 'outOfOfficeEnabled' | 'outOfOfficeShowScheduleEnabled'>
    & { paymentFeeConfig?: Maybe<(
      { __typename?: 'ClinicPaymentFeeConfiguration' }
      & Pick<ClinicPaymentFeeConfiguration, 'id' | 'onlineProcessingFeeFlat' | 'onlineProcessingFeePercent' | 'onlineClientServiceFeePercent' | 'terminalProcessingFeeFlat' | 'terminalProcessingFeePercent' | 'terminalClientServiceFeePercent'>
    )> }
  )>, stripeTerminalLocations: Array<(
    { __typename?: 'StripeTerminalLocation' }
    & Pick<StripeTerminalLocation, 'id' | 'stripeId' | 'isActive'>
    & { terminals: Array<(
      { __typename?: 'StripeTerminal' }
      & Pick<StripeTerminal, 'id' | 'name' | 'model'>
    )> }
  )> }
);

export type ClinicUserVetInfoFragment = (
  { __typename?: 'VetInfo' }
  & Pick<VetInfo, 'id' | 'createdAt' | 'updatedAt'>
  & { roles: Array<(
    { __typename?: 'StaffRole' }
    & Pick<StaffRole, 'id' | 'role'>
    & { clinic?: Maybe<(
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    )>, permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'type'>
    )> }
  )>, currentClinic?: Maybe<(
    { __typename?: 'Clinic' }
    & ClinicUserClinicFragment
  )> }
);

export type ClinicUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'nameDisplay' | 'profilePic' | 'email' | 'phone' | 'type' | 'invitationCode' | 'isNewPasswordRequired' | 'isAdmin'>
  & { userSetting?: Maybe<(
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'id' | 'pushNotifications' | 'smsNotifications' | 'emailNotifications' | 'leftNavPanel' | 'snoozeNotificationsUntil' | 'enterAddsNewLine' | 'skipLoadingAnimation'>
    & { browserNotificationFilter?: Maybe<(
      { __typename?: 'ChannelSelection' }
      & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
      & { assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>, notificationSettings: Array<(
      { __typename?: 'UserNotificationSetting' }
      & Pick<UserNotificationSetting, 'id' | 'soundNotificationUrl' | 'browserNotificationEnabled'>
    )> }
  )>, clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicUserClinicFragment
  )>, vetInfo?: Maybe<(
    { __typename?: 'VetInfo' }
    & ClinicUserVetInfoFragment
  )> }
);

export type GetClinicUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClinicUserQuery = (
  { __typename?: 'Query' }
  & { clinicUser?: Maybe<(
    { __typename?: 'User' }
    & ClinicUserFragment
  )> }
);

export type GetClinicUsersQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'firebaseId' | 'email'>
    & ClinicUserAvatarFragment
  )>> }
);

export type GetPaginatedAppointmentsQueryVariables = Exact<{
  where: AppointmentWhereInput;
  take: Scalars['Int'];
  skip: Scalars['Int'];
  orderBy?: Maybe<Array<Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>> | Maybe<AppointmentOrderByWithRelationAndSearchRelevanceInput>>;
  countOnly: Scalars['Boolean'];
}>;


export type GetPaginatedAppointmentsQuery = (
  { __typename?: 'Query' }
  & { count: Query['findManyAppointmentCount'] }
  & { appointments?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'pimsId' | 'startAt' | 'description' | 'durationInMinutes' | 'title' | 'status' | 'pimsStatus' | 'channelId'>
    & { appointmentType?: Maybe<(
      { __typename?: 'AppointmentType' }
      & Pick<AppointmentType, 'id' | 'name' | 'displayName'>
    )>, channel?: Maybe<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'updatedAt'>
      & { lastMessage?: Maybe<(
        { __typename?: 'ChannelMessage' }
        & Pick<ChannelMessage, 'id' | 'createdAt'>
      )>, channelStatus?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & ChannelListChannelStatusFragment
      )> }
    )>, clinicPet?: Maybe<(
      { __typename?: 'ClinicPet' }
      & Pick<ClinicPet, 'id' | 'computed_rawPimsIsDeceased' | 'computed_rawPimsIsActive' | 'computedIsDeceased' | 'computedIsActive' | 'isDeceased' | 'isActive' | 'name' | 'breed' | 'species' | 'photoUrl'>
    )>, clinicPetParents: Array<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
      & { phoneNumbers: Array<(
        { __typename?: 'ClinicEntityPhoneNumber' }
        & Pick<ClinicEntityPhoneNumber, 'id' | 'number' | 'updatedAt' | 'isPrimary' | 'isDeleted'>
      )> }
    )> }
  )>> }
);

export type GetStripePaymentIntentQueryVariables = Exact<{
  where: StripePaymentIntentWhereUniqueInput;
}>;


export type GetStripePaymentIntentQuery = (
  { __typename?: 'Query' }
  & { stripePaymentIntent?: Maybe<(
    { __typename?: 'StripePaymentIntent' }
    & StripePaymentIntentFragment
  )>, stripePaymentOutput?: Maybe<(
    { __typename?: 'StripePaymentOutput' }
    & StripePaymentOutputFragment
  )> }
);

export type SubscribeToStripePaymentIntentChangedSubscriptionVariables = Exact<{
  where: SubscriptionStripePaymentIntentWhereInput;
}>;


export type SubscribeToStripePaymentIntentChangedSubscription = (
  { __typename?: 'Subscription' }
  & { stripePaymentIntentChanged: (
    { __typename?: 'StripePaymentIntentSubscription' }
    & { node: (
      { __typename?: 'StripePaymentIntent' }
      & StripePaymentIntentFragment
    ) }
  ) }
);

export type SubscriptionChannelMessageFragment = (
  { __typename?: 'ChannelMessage' }
  & Pick<ChannelMessage, 'id' | 'body' | 'messageType'>
  & { attachments: Array<(
    { __typename?: 'ChannelMessageAttachment' }
    & Pick<ChannelMessageAttachment, 'id' | 'attachmentType'>
  )>, mentionedUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'id'>
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'channelType'>
    & { assignees: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, lastMessage?: Maybe<(
      { __typename?: 'ChannelMessage' }
      & Pick<ChannelMessage, 'id'>
    )>, channelStatus?: Maybe<(
      { __typename?: 'ChannelStatus' }
      & Pick<ChannelStatus, 'id' | 'channelStatusAction'>
    )> }
  )>, author?: Maybe<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'twilioIdentity'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, clinicPetParent?: Maybe<(
      { __typename?: 'ClinicPetParent' }
      & Pick<ClinicPetParent, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type SubscribeToClinicUserChannelMessagesSubscriptionVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type SubscribeToClinicUserChannelMessagesSubscription = (
  { __typename?: 'Subscription' }
  & { channelMessageChanged: (
    { __typename?: 'ChannelMessageSubscription' }
    & Pick<ChannelMessageSubscription, 'mutation'>
    & { node: (
      { __typename?: 'ChannelMessage' }
      & SubscriptionChannelMessageFragment
    ) }
  ) }
);

export type SubscribeToClinicUserChannelsSubscriptionVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type SubscribeToClinicUserChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { channelChanged: (
    { __typename?: 'ChannelSubscription' }
    & { node: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'friendlyName'>
      & { assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, channelStatus?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'color' | 'name' | 'channelStatusAction'>
      )> }
    ) }
  ) }
);

export type SubscribeToFormSubmissionSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type SubscribeToFormSubmissionSubscription = (
  { __typename?: 'Subscription' }
  & { formSubmissionChanged?: Maybe<(
    { __typename?: 'FormSubmissionSubscription' }
    & { node: (
      { __typename?: 'FormSubmission' }
      & Pick<FormSubmission, 'id' | 'submittedAt'>
    ) }
  )> }
);

export type SubscribeToUiRefreshSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeToUiRefreshSubscription = (
  { __typename?: 'Subscription' }
  & { uiRefresh?: Maybe<(
    { __typename?: 'UiRefresh' }
    & Pick<UiRefresh, 'refreshType' | 'message' | 'metadata'>
  )> }
);

export type SubscribeToUnreadMessageCountChangedSubscriptionVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type SubscribeToUnreadMessageCountChangedSubscription = (
  { __typename?: 'Subscription' }
  & { unreadMessageCountChanged: (
    { __typename?: 'ChannelSubscription' }
    & { node: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
      & { assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, channelStatus?: Maybe<(
        { __typename?: 'ChannelStatus' }
        & Pick<ChannelStatus, 'id' | 'channelStatusAction'>
      )> }
    ) }
  ) }
);

export type SharedUpdateUserSettingMutationVariables = Exact<{
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
}>;


export type SharedUpdateUserSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateOneUserSetting: (
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'id' | 'snoozeNotificationsUntil'>
    & { browserNotificationFilter?: Maybe<(
      { __typename?: 'ChannelSelection' }
      & Pick<ChannelSelection, 'id' | 'includeUnassigned'>
      & { assignees: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>, notificationSettings: Array<(
      { __typename?: 'UserNotificationSetting' }
      & Pick<UserNotificationSetting, 'id' | 'emailEnabled' | 'smsEnabled' | 'soundNotificationUrl' | 'browserNotificationEnabled' | 'pushNotificationEnabled'>
    )> }
  ) }
);

export type GetMostRecentSyncDataQueryVariables = Exact<{
  where?: Maybe<IntegrationOutageHistoryEntryWhereInput>;
}>;


export type GetMostRecentSyncDataQuery = (
  { __typename?: 'Query' }
  & { findFirstIntegrationOutageHistoryEntry?: Maybe<(
    { __typename?: 'IntegrationOutageHistoryEntry' }
    & Pick<IntegrationOutageHistoryEntry, 'status' | 'id' | 'createdAt'>
  )> }
);

export type SubscribeToSyncOutagesSubscriptionVariables = Exact<{
  where: IntegrationOutageHistoryEntryWhereInput;
}>;


export type SubscribeToSyncOutagesSubscription = (
  { __typename?: 'Subscription' }
  & { integrationOutageHistoryEntryChanged: (
    { __typename?: 'IntegrationOutageHistoryEntrySubscription' }
    & { node: (
      { __typename?: 'IntegrationOutageHistoryEntry' }
      & Pick<IntegrationOutageHistoryEntry, 'id' | 'createdAt' | 'status'>
    ) }
  ) }
);

export type GetTerminalTokenQueryVariables = Exact<{
  where: StripeTerminalLocationUniqueInput;
}>;


export type GetTerminalTokenQuery = (
  { __typename?: 'Query' }
  & { getTerminalToken?: Maybe<(
    { __typename?: 'StripeTerminalTokenOutput' }
    & Pick<StripeTerminalTokenOutput, 'secret'>
  )> }
);

export type FindManyStripePayoutQueryVariables = Exact<{
  where: StripePayoutWhereInput;
}>;


export type FindManyStripePayoutQuery = (
  { __typename?: 'Query' }
  & { findManyStripePayout?: Maybe<Array<(
    { __typename?: 'StripePayout' }
    & Pick<StripePayout, 'id' | 'createdAt' | 'updatedAt' | 'stripeId' | 'amount' | 'arrivalDate' | 'initiatedAt' | 'status'>
    & { stripePaymentIntents: Array<(
      { __typename?: 'StripePaymentIntent' }
      & Pick<StripePaymentIntent, 'id'>
    )>, stripeRefunds: Array<(
      { __typename?: 'StripeRefund' }
      & Pick<StripeRefund, 'id'>
    )>, stripeDisputes: Array<(
      { __typename?: 'StripeDispute' }
      & Pick<StripeDispute, 'id'>
    )>, stripePayoutAccount?: Maybe<(
      { __typename?: 'StripePayoutAccount' }
      & Pick<StripePayoutAccount, 'id' | 'last4' | 'bankName'>
    )>, financialTransactions: Array<(
      { __typename?: 'FinancialTransaction' }
      & Pick<FinancialTransaction, 'id'>
    )>, payoutBatchingPeriod?: Maybe<(
      { __typename?: 'PayoutBatchingPeriod' }
      & Pick<PayoutBatchingPeriod, 'id' | 'endsAt'>
    )> }
  )>> }
);

export type GetClinicChannelStatusesQueryVariables = Exact<{
  clinicId: Scalars['String'];
}>;


export type GetClinicChannelStatusesQuery = (
  { __typename?: 'Query' }
  & { findManyChannelStatus?: Maybe<Array<(
    { __typename?: 'ChannelStatus' }
    & FullChannelStatusFragment
  )>> }
);

export type GetUniqueChannelStatusQueryVariables = Exact<{
  where: ChannelStatusWhereUniqueInput;
}>;


export type GetUniqueChannelStatusQuery = (
  { __typename?: 'Query' }
  & { findUniqueChannelStatus?: Maybe<(
    { __typename?: 'ChannelStatus' }
    & FullChannelStatusFragment
  )> }
);

export type GetClinicPaymentActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClinicPaymentActivityQuery = (
  { __typename?: 'Query' }
  & { clinicPaymentActivity?: Maybe<(
    { __typename?: 'ClinicPaymentActivityOutput' }
    & Pick<ClinicPaymentActivityOutput, 'success' | 'error' | 'totalRecentTransactions' | 'totalRecentCentsProcessed'>
  )> }
);

export type GetUnreadMessageCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadMessageCountQuery = (
  { __typename?: 'Query' }
  & { channelUnreadMessageCount?: Maybe<(
    { __typename?: 'ChannelUnreadMessageCountResult' }
    & Pick<ChannelUnreadMessageCountResult, 'total'>
    & { unreadChannels: Array<(
      { __typename?: 'UnreadMessageChannel' }
      & Pick<UnreadMessageChannel, 'channelId' | 'channelLastMessageIndex' | 'lastConsumedMessageIndex' | 'unreadMessageCount' | 'channelType'>
    )>, client?: Maybe<(
      { __typename?: 'ChannelCountResult' }
      & Pick<ChannelCountResult, 'total'>
      & { unreadChannels: Array<(
        { __typename?: 'UnreadMessageChannel' }
        & Pick<UnreadMessageChannel, 'channelId' | 'channelLastMessageIndex' | 'lastConsumedMessageIndex' | 'unreadMessageCount' | 'channelType'>
      )> }
    )>, team?: Maybe<(
      { __typename?: 'ChannelCountResult' }
      & Pick<ChannelCountResult, 'total'>
      & { unreadChannels: Array<(
        { __typename?: 'UnreadMessageChannel' }
        & Pick<UnreadMessageChannel, 'channelId' | 'channelLastMessageIndex' | 'lastConsumedMessageIndex' | 'unreadMessageCount' | 'channelType'>
      )> }
    )> }
  )> }
);

export const AppointmentBaseFragmentDoc = gql`
    fragment AppointmentBase on Appointment {
  id
  createdAt
  updatedAt
  description
  durationInMinutes
  pimsId
  startAt
  consultationId
  integrationId
  appointmentTypeId
  isActive
  isDeleted
  title
  clinicPetId
  clinicId
  roomId
  status
  pimsStatus
  channelId
}
    `;
export const AppointmentTypeBaseSelectionFragmentDoc = gql`
    fragment AppointmentTypeBaseSelection on AppointmentType {
  id
  createdAt
  updatedAt
  name
  isVisible
}
    `;
export const ClinicRoomBaseSelectionFragmentDoc = gql`
    fragment ClinicRoomBaseSelection on ClinicRoom {
  id
  createdAt
  updatedAt
  name
  isVisible
}
    `;
export const ClinicEmployeeBaseSelectionFragmentDoc = gql`
    fragment ClinicEmployeeBaseSelection on ClinicEmployee {
  id
  createdAt
  updatedAt
  firstName
  lastName
  isVisible
}
    `;
export const CareSubscriptionFragmentDoc = gql`
    fragment CareSubscription on CareStripeSubscription {
  id
  paymentStatus
  nextBillingDate
  stripePaymentMethod {
    id
    last4
    stripeId
    cardBrand
    expirationMonth
    expirationYear
    isExpired
  }
}
    `;
export const EnrollmentTrueUpsFragmentDoc = gql`
    fragment EnrollmentTrueUps on CareSubscriptionTrueUp {
  id
  dueAt
  completedAt
  retryAt
  amount
  shouldCancelEnrollment
}
    `;
export const CareEnrollmentFragmentDoc = gql`
    fragment CareEnrollment on CarePlanEnrollment {
  id
  planId
  status
  startDate
  autoRenews
  expirationDate
  careStripeSubscriptionId
  careStripeSubscription {
    paymentStatus
  }
  plan {
    id
    title
    flowAlias
    pricePerRenewal
    planBenefits {
      id
      benefitId
      displayOrder
    }
  }
  careSubscriptionTrueUps(
    where: {type: {equals: Internal}}
    orderBy: {dueAt: asc}
  ) {
    ...EnrollmentTrueUps
  }
  enrollmentPayments(where: {paidAt: {not: null}}) {
    id
    amount
    applicationFeeAmount
    paidAt
  }
}
    ${EnrollmentTrueUpsFragmentDoc}`;
export const SidePanelOrganizationPetFragmentDoc = gql`
    fragment SidePanelOrganizationPet on OrganizationPet {
  id
  associatedSubscriptions(
    where: {paymentStatus: {notIn: [Open, Draft]}}
    orderBy: {createdAt: desc}
  ) {
    ...CareSubscription
  }
  carePlanEnrollments(orderBy: {expirationDate: desc}) {
    ...CareEnrollment
  }
}
    ${CareSubscriptionFragmentDoc}
${CareEnrollmentFragmentDoc}`;
export const PetAvatarFragmentDoc = gql`
    fragment PetAvatar on ClinicPet {
  id
  computed_rawPimsIsDeceased
  computed_rawPimsIsActive
  computedIsDeceased @client
  computedIsActive @client
  isDeceased
  isActive
  name
  photoUrl
  rawPimsValue
  species
  organizationPet {
    ...SidePanelOrganizationPet
  }
}
    ${SidePanelOrganizationPetFragmentDoc}`;
export const OrganizationPetCareBadgePlanEnrollmentFragmentDoc = gql`
    fragment OrganizationPetCareBadgePlanEnrollment on ClinicPet {
  id
  organizationPet {
    ...SidePanelOrganizationPet
  }
}
    ${SidePanelOrganizationPetFragmentDoc}`;
export const ClinicPetSelectionFragmentDoc = gql`
    fragment ClinicPetSelection on ClinicPet {
  id
  breed
  gender
  isActive
  isDeleted
  dateOfBirth
  speciesType
  weight
  weightUnit
  ...PetAvatar
  ...OrganizationPetCareBadgePlanEnrollment
  alerts {
    value
    pimsDate
    pimsId
    isMedical
  }
}
    ${PetAvatarFragmentDoc}
${OrganizationPetCareBadgePlanEnrollmentFragmentDoc}`;
export const PetParentAvatarFragmentDoc = gql`
    fragment PetParentAvatar on ClinicPetParent {
  id
  firstName
  lastName
  creationSource
  isTest
}
    `;
export const ClinicPetParentSelectionFragmentDoc = gql`
    fragment ClinicPetParentSelection on ClinicPetParent {
  id
  email
  firstName
  lastName
  isActive
  updatedAt
  ...PetParentAvatar
  pimsId
  phoneNumbers {
    id
    number
    isPrimary
    isMobile
    isDeleted
    updatedAt
  }
}
    ${PetParentAvatarFragmentDoc}`;
export const ClinicPetNestedSelectionFragmentDoc = gql`
    fragment ClinicPetNestedSelection on ClinicPet {
  ...ClinicPetSelection
  petParents(where: {isActive: {equals: true}, isDeleted: {equals: false}}) {
    ...ClinicPetParentSelection
  }
}
    ${ClinicPetSelectionFragmentDoc}
${ClinicPetParentSelectionFragmentDoc}`;
export const AppointmentFullSelectionFragmentDoc = gql`
    fragment AppointmentFullSelection on Appointment {
  ...AppointmentBase
  workflows {
    id
    name
  }
  channel {
    id
    channelStatus {
      id
      name
      color
      channelStatusAction
    }
  }
  appointmentType {
    ...AppointmentTypeBaseSelection
  }
  room {
    ...ClinicRoomBaseSelection
  }
  clinicEmployees {
    ...ClinicEmployeeBaseSelection
  }
  clinicPet {
    ...ClinicPetNestedSelection
  }
  clinicPetParents(where: {isActive: {equals: true}, isDeleted: {equals: false}}) {
    ...ClinicPetParentSelection
  }
}
    ${AppointmentBaseFragmentDoc}
${AppointmentTypeBaseSelectionFragmentDoc}
${ClinicRoomBaseSelectionFragmentDoc}
${ClinicEmployeeBaseSelectionFragmentDoc}
${ClinicPetNestedSelectionFragmentDoc}
${ClinicPetParentSelectionFragmentDoc}`;
export const AppointmentPetFragmentDoc = gql`
    fragment AppointmentPet on ClinicPet {
  id
  breed
  dateOfBirth
  gender
  ...PetAvatar
  petParents(where: {isDeleted: {not: {equals: true}}}) {
    id
    firstName
    lastName
    clinicId
    phoneNumbers {
      id
      number
      isPrimary
      isMobile
      isDeleted
      updatedAt
    }
    email
    updatedAt
  }
}
    ${PetAvatarFragmentDoc}`;
export const AppointmentTypeWorkflowsSelectionFragmentDoc = gql`
    fragment AppointmentTypeWorkflowsSelection on AppointmentType {
  ...AppointmentTypeBaseSelection
  workflows {
    id
    name
    workflowType
    event
  }
}
    ${AppointmentTypeBaseSelectionFragmentDoc}`;
export const AutomationsListItemFragmentDoc = gql`
    fragment AutomationsListItem on WorkflowEventSetting {
  id
  name
  isPublished
  sendingHours
  triggerType
  event
  childRunEvaluationStatus
  messagingCampaignId
  conditionSets {
    id
    conditions {
      id
    }
  }
}
    `;
export const AutomationsParentItemFragmentDoc = gql`
    fragment AutomationsParentItem on WorkflowEventSetting {
  id
  name
  isPublished
}
    `;
export const AutomationEditorAutomationFragmentDoc = gql`
    fragment AutomationEditorAutomation on WorkflowEventSetting {
  id
  name
  isPublished
  triggerType
  visitType
  sendingHours
  automatedTriggerType
  automatedTriggerInMinutes
  automatedTriggerTimeOfDay
  event
  notificationBody
  timeFormat
  triggeringFormTemplates {
    id
    title
  }
  widgetRequestTypes {
    id
    displayName
  }
  appointmentTypes {
    id
    displayName
    name
  }
  clinicRooms {
    clinicRoomId
    clinicRoom {
      id
      name
    }
  }
  clinicEmployees {
    clinicEmployeeId
    clinicEmployee {
      id
      firstName
      lastName
    }
  }
  actions(orderBy: {order: asc}) {
    id
    config
    promptType
    order
    channelStatusChangeId
    workflowEventTriggerDelayInMilliseconds
    workflowEventTriggerActionNumber
    triggerWorkflowId
  }
  conditionSets {
    id
  }
}
    `;
export const AutomationEditorConditionFragmentDoc = gql`
    fragment AutomationEditorCondition on Condition {
  id
  createdAt
  attribute
  operator
  operand
  checksum
}
    `;
export const AutomationEditorConditionSetFragmentDoc = gql`
    fragment AutomationEditorConditionSet on ConditionSet {
  id
  conditionalEntityType
  conditionalEntityId
  parentConditionSetId
  operator
  negate
  conditions {
    ...AutomationEditorCondition
  }
}
    ${AutomationEditorConditionFragmentDoc}`;
export const AutomationsSelectionListAutomationFragmentDoc = gql`
    fragment AutomationsSelectionListAutomation on WorkflowEventSetting {
  ...AutomationEditorAutomation
  conditionSets {
    ...AutomationEditorConditionSet
  }
}
    ${AutomationEditorAutomationFragmentDoc}
${AutomationEditorConditionSetFragmentDoc}`;
export const BoardPetParentFragmentDoc = gql`
    fragment BoardPetParent on ClinicPetParent {
  id
  ...PetParentAvatar
  phoneNumbers {
    id
    number
    updatedAt
    isPrimary
    isDeleted
  }
}
    ${PetParentAvatarFragmentDoc}`;
export const ChannelFilterSelectionFragmentDoc = gql`
    fragment ChannelFilterSelection on ChannelFilterSelection {
  id
  isDeleted
  name
  filters
  filterSelectionType
  userChannelFilterSelections(where: {isFilterOwner: {equals: true}}) {
    id
    isFilterOwner
    user {
      id
      firstName
      lastName
    }
  }
}
    `;
export const ChatEntityAppointmentFragmentDoc = gql`
    fragment ChatEntityAppointment on Appointment {
  id
  createdAt
  description
  startAt
  durationInMinutes
}
    `;
export const ChatEntityFormSubmissionFragmentDoc = gql`
    fragment ChatEntityFormSubmission on FormSubmission {
  id
  submittedAt
  formTemplate {
    title
  }
  clinicPetParentId
}
    `;
export const DocumentationFragmentDoc = gql`
    fragment Documentation on Documentation {
  id
  createdAt
  updatedAt
  provider
  category
  title
  url
}
    `;
export const ClinicUserAvatarFragmentDoc = gql`
    fragment ClinicUserAvatar on User {
  id
  firstName
  lastName
  displayName @client
  nameDisplay
  profilePic
}
    `;
export const TeamMemberMentionsListFragmentDoc = gql`
    fragment TeamMemberMentionsList on User {
  id
  ...ClinicUserAvatar
}
    ${ClinicUserAvatarFragmentDoc}`;
export const ChannelListChannelPetFragmentDoc = gql`
    fragment ChannelListChannelPet on ClinicPet {
  id
  ...PetAvatar
  pimsId
}
    ${PetAvatarFragmentDoc}`;
export const ChannelListChannelStatusFragmentDoc = gql`
    fragment ChannelListChannelStatus on ChannelStatus {
  id
  name
  color
  channelStatusAction
}
    `;
export const ChannelListChannelMemberPetParentFragmentDoc = gql`
    fragment ChannelListChannelMemberPetParent on ClinicPetParent {
  id
  firstName
  lastName
  pimsId
  ...PetParentAvatar
  phoneNumbers {
    id
    createdAt
    updatedAt
    number
    isDeleted
    isPrimary
    isOptedOut
  }
}
    ${PetParentAvatarFragmentDoc}`;
export const ChannelListChannelMemberFragmentDoc = gql`
    fragment ChannelListChannelMember on ChannelMember {
  id
  lastConsumedMessageIndex
  twilioMemberSid
  twilioIdentity
  removedAt
  clinicPetParent {
    id
    ...ChannelListChannelMemberPetParent
  }
}
    ${ChannelListChannelMemberPetParentFragmentDoc}`;
export const ChannelListChannelLastMessageFragmentDoc = gql`
    fragment ChannelListChannelLastMessage on ChannelMessage {
  id
  createdAt
  deletedAt
  index
  body
  attachments {
    id
    attachmentType
    entityType
  }
}
    `;
export const ChannelPinFragmentDoc = gql`
    fragment ChannelPin on ChannelPin {
  id
  userId
  createdAt
}
    `;
export const ChannelListChannelFragmentDoc = gql`
    fragment ChannelListChannel on Channel {
  id
  updatedAt
  lastExportAt
  tags
  isPinned @client
  pets {
    ...ChannelListChannelPet
  }
  channelStatus {
    id
    ...ChannelListChannelStatus
  }
  channelMembers(
    where: {clinicPetParentId: {not: {equals: null}}, removedAt: {equals: null}}
  ) {
    ...ChannelListChannelMember
  }
  lastMessage {
    ...ChannelListChannelLastMessage
  }
  creationSource {
    id
    creationSource
  }
  channelPins {
    ...ChannelPin
  }
}
    ${ChannelListChannelPetFragmentDoc}
${ChannelListChannelStatusFragmentDoc}
${ChannelListChannelMemberFragmentDoc}
${ChannelListChannelLastMessageFragmentDoc}
${ChannelPinFragmentDoc}`;
export const ChannelViewClinicPetFragmentDoc = gql`
    fragment ChannelViewClinicPet on ClinicPet {
  id
  pimsId
  ...PetAvatar
}
    ${PetAvatarFragmentDoc}`;
export const ChannelViewClinicPetParentFragmentDoc = gql`
    fragment ChannelViewClinicPetParent on ClinicPetParent {
  id
  pimsId
  firstName
  lastName
  email
  isActive
  isDeleted
  ...PetParentAvatar
  pets(
    take: 30
    where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isDeleted: {equals: false}, OR: [{NOT: {isDeceased: {equals: true}}}, {isDeceased: null}]}
  ) {
    id
    ...ChannelViewClinicPet
  }
  phoneNumbers {
    id
    number
    isPrimary
    isMobile
    isDeleted
    updatedAt
    smsStatus
  }
  petParentSetting {
    id
    smsNotifications
    emailOptedOutByPetParent
    pushNotifications
  }
}
    ${PetParentAvatarFragmentDoc}
${ChannelViewClinicPetFragmentDoc}`;
export const ChannelViewChannelMemberFragmentDoc = gql`
    fragment ChannelViewChannelMember on ChannelMember {
  id
  lastConsumedMessageIndex
  twilioMemberSid
  twilioIdentity
  removedAt
  clinicPetParent {
    id
    ...ChannelViewClinicPetParent
  }
  user {
    id
    firstName
    lastName
    profilePic
    email
    vetInfo {
      id
      roles {
        id
        role
      }
    }
    type
  }
}
    ${ChannelViewClinicPetParentFragmentDoc}`;
export const ChannelViewChannelStatusFragmentDoc = gql`
    fragment ChannelViewChannelStatus on ChannelStatus {
  id
  name
  color
  channelStatusAction
}
    `;
export const ChannelViewChannelPetFragmentDoc = gql`
    fragment ChannelViewChannelPet on ClinicPet {
  id
  pimsId
  ...PetAvatar
  ...OrganizationPetCareBadgePlanEnrollment
}
    ${PetAvatarFragmentDoc}
${OrganizationPetCareBadgePlanEnrollmentFragmentDoc}`;
export const ChannelViewChannelFragmentDoc = gql`
    fragment ChannelViewChannel on Channel {
  id
  createdAt
  creationSource {
    id
    creationSource
    userId
    workflowEventSettingId
    clinicWidgetRequestId
    serviceReminderId
    clinicPetParentId
    formSubmissionId
  }
  updatedAt
  isActive
  attributes
  twilioChannelSid
  friendlyName
  channelType
  channelVisibility
  channelMembers(
    where: {channelId: {equals: $channelId}, OR: [{clinicPetParentId: {not: {equals: null}}}, {userId: {equals: $userId}}]}
  ) {
    ...ChannelViewChannelMember
  }
  channelStatus {
    ...ChannelViewChannelStatus
  }
  lastMessage {
    id
    createdAt
    index
    body
    attachments {
      id
      attachmentType
      entityType
    }
  }
  assignees {
    ...ClinicUserAvatar
    channelMembers(where: {channelId: {equals: $channelId}}) {
      ...ChannelViewChannelMember
    }
  }
  clinic {
    id
    name
    logo
  }
  pets {
    ...ChannelViewChannelPet
  }
}
    ${ChannelViewChannelMemberFragmentDoc}
${ChannelViewChannelStatusFragmentDoc}
${ClinicUserAvatarFragmentDoc}
${ChannelViewChannelPetFragmentDoc}`;
export const PetUsageByBenefitFragmentDoc = gql`
    fragment PetUsageByBenefit on UsageByBenefit {
  provided
  used
  exhausted
  displayOrder
  sources {
    planIds
    addonIds
  }
  benefit {
    id
    title
    tangible
    exhaustible
    providedDiscountPercentage
    usageUnitOfMeasure
  }
}
    `;
export const PetUsageFragmentDoc = gql`
    fragment PetUsage on PetBenefitUsage {
  organizationPetId
  usages {
    ...PetUsageByBenefit
  }
}
    ${PetUsageByBenefitFragmentDoc}`;
export const CalculateMultiplePetsBenefitUsageFragmentDoc = gql`
    fragment CalculateMultiplePetsBenefitUsage on CalculateMultiplePetsBenefitUsageOutput {
  petUsages {
    ...PetUsage
  }
}
    ${PetUsageFragmentDoc}`;
export const ChannelPetFragmentDoc = gql`
    fragment ChannelPet on ClinicPet {
  id
  pimsId
  ...PetAvatar
}
    ${PetAvatarFragmentDoc}`;
export const ChannelViewChannelMessageAttachmentFragmentDoc = gql`
    fragment ChannelViewChannelMessageAttachment on ChannelMessageAttachment {
  id
  attachmentType
  entityType
  entityId
  filename
  uploadStatus
  url
}
    `;
export const SmsNotificationStatusFragmentDoc = gql`
    fragment SmsNotificationStatus on SmsNotificationStatus {
  id
  createdAt
  updatedAt
  status
  receivingNumber
  sourceContactEntityId
  sourceContactEntityType
  provider
  providerMessageId
  errorType
  providerErrorData
  sentChannelMessageId
}
    `;
export const ReactionFragmentDoc = gql`
    fragment Reaction on ChannelMessageReaction {
  id
  createdAt
  deletedAt
  reaction
  userId
  user {
    id
    namePrefix
    nameDisplay
    firstName
    lastName
    nameSuffix
  }
}
    `;
export const ChannelViewChannelMessageFragmentDoc = gql`
    fragment ChannelViewChannelMessage on ChannelMessage {
  id
  createdAt
  updatedAt
  deletedAt
  index
  body
  messageType
  forceReadReceipt
  mentionedUsers {
    firstName
    lastName
    nameDisplay
  }
  source
  attachments {
    ...ChannelViewChannelMessageAttachment
  }
  smsNotifificationStatuses {
    ...SmsNotificationStatus
  }
  author {
    id
    twilioIdentity
    user {
      id
      firstName
      lastName
      profilePic
      nameDisplay
      type
    }
    clinicPetParent {
      id
      firstName
      lastName
      ...PetParentAvatar
      phoneNumbers {
        id
        number
        isPrimary
        isMobile
        isDeleted
        updatedAt
      }
      petParentSetting {
        id
        smsNotifications
      }
    }
  }
  channel {
    id
  }
  attributes
  reactions(orderBy: {createdAt: asc}) {
    ...Reaction
  }
  sourcePhoneNumber
}
    ${ChannelViewChannelMessageAttachmentFragmentDoc}
${SmsNotificationStatusFragmentDoc}
${PetParentAvatarFragmentDoc}
${ReactionFragmentDoc}`;
export const ChannelViewClientDropdownClinicPetParentFragmentDoc = gql`
    fragment ChannelViewClientDropdownClinicPetParent on ClinicPetParent {
  id
  firstName
  lastName
  ...PetParentAvatar
  pets {
    id
    name
  }
  channelMembers {
    id
    removedAt
    channel {
      id
      channelStatus {
        id
        channelStatusAction
      }
    }
  }
}
    ${PetParentAvatarFragmentDoc}`;
export const ExportabilityCheckChannelFragmentDoc = gql`
    fragment ExportabilityCheckChannel on Channel {
  id
  pets {
    id
    pimsId
  }
  channelMembers {
    id
    clinicPetParent {
      id
      pimsId
      pets(where: {clinicId: {equals: $clinicId}}) {
        id
        pimsId
      }
    }
  }
}
    `;
export const MessageComposerFormTemplateFragmentDoc = gql`
    fragment MessageComposerFormTemplate on FormTemplate {
  id
  title
  isActive
  isDeleted
  formTemplateType
  content
}
    `;
export const MessageComposerMessageTemplateFragmentDoc = gql`
    fragment MessageComposerMessageTemplate on MessageTemplate {
  id
  name
  body
  isArchived
  attachments
}
    `;
export const DemoWorkflowAppintmentWorkflowFragmentDoc = gql`
    fragment DemoWorkflowAppintmentWorkflow on WorkflowEventSetting {
  id
  name
  event
  isPublished
  createdAt
  surveySettingId
  surveyStep
  surveyType
}
    `;
export const DemoWorkflowAppointmentFragmentDoc = gql`
    fragment DemoWorkflowAppointment on Appointment {
  id
  description
  workflows {
    ...DemoWorkflowAppintmentWorkflow
  }
  isDeleted
  createdAt
  startAt
  clinicPet {
    name
  }
}
    ${DemoWorkflowAppintmentWorkflowFragmentDoc}`;
export const LoyaltyProgramRewardFragmentDoc = gql`
    fragment LoyaltyProgramReward on LoyaltyReward {
  id
  title
  description
  pointCost
  availability
}
    `;
export const ServiceFromIntegrationIdFragmentDoc = gql`
    fragment ServiceFromIntegrationId on Service {
  id
  name
  code
  pimsName
}
    `;
export const PointGranterServiceFragmentDoc = gql`
    fragment PointGranterService on LoyaltyProgram {
  id
  status
  pointGranters {
    id
    enabled
    allowAllServices
    grantingServices {
      serviceId
      service {
        ...ServiceFromIntegrationId
      }
    }
  }
}
    ${ServiceFromIntegrationIdFragmentDoc}`;
export const FullMessageTemplateFragmentDoc = gql`
    fragment FullMessageTemplate on MessageTemplate {
  id
  createdAt
  updatedAt
  updatedBy {
    id
    firstName
    lastName
  }
  name
  body
  isArchived
  attachments
}
    `;
export const EmailCampaignFragmentDoc = gql`
    fragment EmailCampaign on EmailCampaign {
  id
  createdAt
  updatedAt
  deletedAt
  name
  subject
  from
  status
  htmlContent
  unlayerJson
  segmentArgs
  createdByUser {
    id
    firstName
    lastName
    nameDisplay
    email
  }
  sentByUser {
    id
    firstName
    lastName
    nameDisplay
    email
  }
  lastUpdatedByUser {
    id
    firstName
    lastName
    nameDisplay
    email
  }
  totalSegmentContacts
  replyToJson
  segmentationType
  customList {
    id
    awsFileKey
    fileName
    validRecipients
    invalidRecipients
  }
  uploads {
    id
    expiresAt
    fileName
    awsFileKey
  }
}
    `;
export const EmailCampaignTemplateFragmentDoc = gql`
    fragment EmailCampaignTemplate on EmailCampaignTemplate {
  id
  name
  htmlContent
  unlayerJson
}
    `;
export const PayoutBatchingPeriodFragmentDoc = gql`
    fragment PayoutBatchingPeriod on PayoutBatchingPeriod {
  id
  createdAt
  updatedAt
  startsAt
  endsAt
  createdByUserId
  clinicId
}
    `;
export const ExclusionRuleSelectionFragmentDoc = gql`
    fragment ExclusionRuleSelection on DirectBookingExclusionRule {
  id
  name
  isActive
  appointmentTypes {
    id
    name
    displayName
  }
  providers {
    id
    firstName
    lastName
  }
  timeSlots {
    id
    updatedAt
    dayOfWeek
    startTime
    endTime
  }
}
    `;
export const ProviderFragmentDoc = gql`
    fragment Provider on ClinicEmployee {
  id
  firstName
  lastName
  pimsId
}
    `;
export const AppointmentTypeFragmentDoc = gql`
    fragment AppointmentType on AppointmentType {
  id
  name
  displayName
  displayOrder
  showInPetPortal
  description
  isAvailableForDirectBooking
  defaultDurationInMinutes
  defaultSoonestAvailableBookingInHours
  delayedStartTime
  directBookingAppointmentTypeSetting {
    id
    clientType
  }
  clinicEmployeeAppointmentTypeSettings {
    clinicEmployee {
      ...Provider
    }
  }
}
    ${ProviderFragmentDoc}`;
export const RoomFragmentDoc = gql`
    fragment Room on ClinicRoom {
  id
  name
  type
}
    `;
export const ClinicSettingsConversationsChannelStatusChannelAssigneeFragmentDoc = gql`
    fragment ClinicSettingsConversationsChannelStatusChannelAssignee on ChannelStatusChannelAssignee {
  id
  user {
    id
  }
}
    `;
export const ClinicSettingsConversationsChannelStatusFragmentDoc = gql`
    fragment ClinicSettingsConversationsChannelStatus on ChannelStatus {
  id
  createdAt
  updatedAt
  channelStatusAction
  color
  deletedOn
  isDefault
  name
  order
  isExportedToPims
  channelStatusChannelAssignees {
    ...ClinicSettingsConversationsChannelStatusChannelAssignee
  }
}
    ${ClinicSettingsConversationsChannelStatusChannelAssigneeFragmentDoc}`;
export const ClinicSettingsConversationsRuleFragmentDoc = gql`
    fragment ClinicSettingsConversationsRule on Rules {
  action
  clinicId
  createdAt
  id
  options
  updatedAt
}
    `;
export const VisualizerAutomationActionFragmentDoc = gql`
    fragment VisualizerAutomationAction on WorkflowEventAction {
  id
  createdAt
  updatedAt
  actionType
  config
  order
  triggerWorkflowId
  workflowEventTriggerActionNumber
  workflowEventSettingId
  channelStatusChangeId
  responseChannelStatusChangeId
  promptType
}
    `;
export const VisualizerAutomationFragmentDoc = gql`
    fragment VisualizerAutomation on WorkflowEventSetting {
  id
  name
  actions {
    ...VisualizerAutomationAction
  }
}
    ${VisualizerAutomationActionFragmentDoc}`;
export const EmergencyClinicsFragmentDoc = gql`
    fragment EmergencyClinics on EmergencyClinics {
  id
  name
  addressLine1
  addressLine2
  city
  state
  postalCode
  phone
  clinicSettingId
  isDeleted
  fullAddress
  displayOrder
}
    `;
export const ClinicOfficeHourSelectionFragmentDoc = gql`
    fragment ClinicOfficeHourSelection on ClinicOfficeHour {
  id
  closeAt
  dayOfWeek
  isEnabled
  isOpenTwentyFourHours
  openAt
  type
}
    `;
export const LegalEntityFragmentDoc = gql`
    fragment LegalEntity on LegalEntity {
  id
  ein
  type
  name
  clinics {
    id
  }
}
    `;
export const ClinicSettingsNotificationsUserSettingsFragmentDoc = gql`
    fragment ClinicSettingsNotificationsUserSettings on UserSetting {
  id
  browserNotificationFilter {
    id
    assignees {
      id
    }
    includeUnassigned
  }
  snoozeNotificationsUntil
  notificationSettings {
    id
    emailEnabled
    smsEnabled
    soundNotificationUrl
    browserNotificationEnabled
    pushNotificationEnabled
  }
}
    `;
export const UserSettingsDataFragmentDoc = gql`
    fragment UserSettingsData on User {
  id
  namePrefix
  nameSuffix
  phone
  email
  firebaseId
  ...ClinicUserAvatar
  vetInfo {
    id
  }
}
    ${ClinicUserAvatarFragmentDoc}`;
export const PimsPaymentTypeSelectionFragmentDoc = gql`
    fragment PimsPaymentTypeSelection on PimsPaymentType {
  id
  pimsId
  isInactive
  isDeleted
  description
}
    `;
export const ReminderSettingsServiceTimingFragmentDoc = gql`
    fragment ReminderSettingsServiceTiming on ServiceReminderTiming {
  id
  timingType
  offset
  offsetUnit
  hourOfDay
  minuteOfHour
  isEmail
  isSms
  isPush
}
    `;
export const ReminderSettingsServiceFragmentDoc = gql`
    fragment ReminderSettingsService on Service {
  id
  code
  name
  formTemplateId
  pimsName
  pimsId
  formTemplateId
  isPetPortal
  isPostcard
  reminderActivationDate
  reminderTimings(where: {isDeleted: {equals: false}}) {
    ...ReminderSettingsServiceTiming
  }
}
    ${ReminderSettingsServiceTimingFragmentDoc}`;
export const ReminderSettingsWorkflowEventSettingFragmentDoc = gql`
    fragment ReminderSettingsWorkflowEventSetting on WorkflowEventSetting {
  id
  actions {
    id
    config
    channelStatusChange {
      id
      name
      color
    }
  }
}
    `;
export const ClinicSettingsTeamUserFragmentDoc = gql`
    fragment ClinicSettingsTeamUser on User {
  id
  ...ClinicUserAvatar
  namePrefix
  nameSuffix
  invitedAt
  invitationCode
  email
  phone
  vetInfo {
    id
    roles(where: {clinicId: {equals: $clinicId}}) {
      id
      role
    }
  }
}
    ${ClinicUserAvatarFragmentDoc}`;
export const ClinicBlockedPhoneNumberFragmentDoc = gql`
    fragment ClinicBlockedPhoneNumber on ClinicBlockedPhoneNumber {
  id
  clinicId
  phoneNumber
  createdAt
  updatedAt
  blockedBy {
    id
    nameDisplay
    firstName
    lastName
  }
  unblockedBy {
    id
    nameDisplay
    firstName
    lastName
  }
  notes
}
    `;
export const ClinicPhoneNumberFragmentDoc = gql`
    fragment ClinicPhoneNumber on ClinicPhoneNumber {
  id
  capability
  provider
  isPrimary
  isActive
  isVerified
  isDeleted
  number
  status
  loaSignedAt
  loaSignedByUserId
  loaUrl
  incomingCarrier
}
    `;
export const ConnectSettingsClinicWidgetRequestTypeFragmentDoc = gql`
    fragment ConnectSettingsClinicWidgetRequestType on ClinicWidgetRequestType {
  id
  order
  isActive
  isDeleted
  displayName
  requestType
  formTemplate {
    id
    title
    clinicWidgetRequestTypes {
      id
    }
  }
  assignees {
    id
    ...ClinicUserAvatar
  }
  channelStatus {
    id
    name
  }
}
    ${ClinicUserAvatarFragmentDoc}`;
export const ConnectSettingsClinicWidgetSettingFragmentDoc = gql`
    fragment ConnectSettingsClinicWidgetSetting on ClinicWidgetSetting {
  clinicWidgetRequestTypes(where: {isDeleted: {equals: false}}) {
    ...ConnectSettingsClinicWidgetRequestType
  }
}
    ${ConnectSettingsClinicWidgetRequestTypeFragmentDoc}`;
export const StripePaymentIntentSelectionFragmentDoc = gql`
    fragment StripePaymentIntentSelection on StripePaymentIntent {
  id
  status
  amount
  amountCaptured
  serviceFeeAmount
  stripeId
  stripePaymentMethod {
    cardBrand
    last4
    type
  }
}
    `;
export const AutomationRunActionFragmentDoc = gql`
    fragment AutomationRunAction on AutomationRunAction {
  id
  createdAt
  updatedAt
  automationRunId
  automationRun {
    id
    status
    channelMessage {
      channel {
        id
      }
    }
  }
  automationAction {
    workflowEventSetting {
      id
      name
      surveyStep
      event
    }
    id
    config
  }
  petParent {
    id
    isDeleted
    isActive
  }
  promptType
  automationActionId
  body
  attachments
  actionType
  petParentId
  petId
  buttons
  channelStatusChangeId
  order
  automationAction {
    id
    config
  }
  formSubmission {
    id
    submittedAt
    clinicPetParent {
      id
      isDeleted
      isActive
    }
  }
  paymentIntent {
    total
    amount
    serviceFeeAmount
    paidAt
    ...StripePaymentIntentSelection
  }
  automationAction {
    workflowEventSetting {
      id
      name
    }
  }
}
    ${StripePaymentIntentSelectionFragmentDoc}`;
export const AutomationRunFragmentDoc = gql`
    fragment AutomationRun on AutomationRun {
  id
  status
  channelMessageId
  originAutomationId
  runType
  manuallySentById
  startedAt
  completedAt
  appointmentId
  formSubmissionId
  serviceReminderId
  clinicWidgetRequestId
  clinicPetParentId
  actions(orderBy: {createdAt: asc}) {
    ...AutomationRunAction
  }
  originAutomation {
    id
    name
  }
  channelMessage {
    channelId
  }
}
    ${AutomationRunActionFragmentDoc}`;
export const CarePlanBenefitUsageFragmentDoc = gql`
    fragment CarePlanBenefitUsage on CarePlanBenefit {
  id
  providedDiscountPercentage
  benefitId
  planId
}
    `;
export const FormSubmissionFragmentDoc = gql`
    fragment FormSubmission on FormSubmission {
  id
  clinicPet {
    id
    name
    createdAt
    updatedAt
    ...PetAvatar
  }
  clinicPetParent {
    id
    firstName
    lastName
    pets(
      where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isDeleted: {equals: false}, OR: [{NOT: {isDeceased: {equals: true}}}, {isDeceased: {equals: null}}]}
    ) {
      id
      name
      pimsId
      species
      createdAt
      updatedAt
      isDeceased
    }
  }
  submittedAt
  formTemplate {
    title
  }
  draftContent
  completedContent
  isCallDeflectionForm
}
    ${PetAvatarFragmentDoc}`;
export const UserAppointmentGroupingFullSelectionFragmentDoc = gql`
    fragment UserAppointmentGroupingFullSelection on UserAppointmentGrouping {
  id
  label
  clinicEmployees {
    id
  }
  rooms {
    id
  }
  appointmentTypes {
    id
  }
}
    `;
export const EmailTemplateVersionFragmentDoc = gql`
    fragment EmailTemplateVersion on EmailTemplateVersion {
  id
  html
  design
  previewImageUrl
  emailTemplateId
  isCurrent
  version
  lastEditedById
  lastEditedBy {
    firstName
    lastName
  }
  createdAt
  updatedAt
  deletedAt
  publishedAt
}
    `;
export const InvoicePetParentFragmentDoc = gql`
    fragment InvoicePetParent on ClinicPetParent {
  id
  email
  firstName
  lastName
  isActive
  lastName
  updatedAt
  creationSource
  phoneNumbers {
    id
    number
    isPrimary
    isMobile
    isDeleted
    updatedAt
  }
  pimsId
  pets(take: 20, where: $petWhere) {
    breed
    gender
    isDeleted
    speciesType
    weight
    clinicId
    dateOfBirth
    weightUnit
    ...PetAvatar
    pimsId
    petParents(where: {isActive: {equals: true}, isDeleted: {equals: false}}) {
      firstName
      id
      lastName
      phoneNumbers {
        id
        number
        isPrimary
        isMobile
        isDeleted
        updatedAt
      }
      email
      pimsId
    }
    alerts {
      value
      pimsDate
      pimsId
      isMedical
    }
  }
}
    ${PetAvatarFragmentDoc}`;
export const MassTextAleryActivityLogFragmentDoc = gql`
    fragment MassTextAleryActivityLog on MassTextAlert {
  id
  createdAt
  alertBody
  appointmentDateRangeType
  appointmentDateTimeFrom
  appointmentDateTimeTo
  createdByUser {
    id
    ...ClinicUserAvatar
  }
  status
}
    ${ClinicUserAvatarFragmentDoc}`;
export const CareAccountCreditIntentFragmentDoc = gql`
    fragment CareAccountCreditIntent on CareAccountCreditIntent {
  id
  invoiceId
  paymentDue
  careAccountCreditPimsWritebackLogEntries {
    writebackLogId
  }
}
    `;
export const CareBenefitUsageFollowUpFragmentDoc = gql`
    fragment CareBenefitUsageFollowUp on CareBenefitUsageFollowup {
  id
  careStripeSubscriptionId
  completed
  followupSteps(orderBy: {displayOrder: asc}) {
    id
    description
    completed
    displayOrder
    careAccountCreditIntents {
      ...CareAccountCreditIntent
    }
  }
}
    ${CareAccountCreditIntentFragmentDoc}`;
export const RecordingUserFragmentDoc = gql`
    fragment RecordingUser on User {
  id
  namePrefix
  firstName
  lastName
}
    `;
export const CareBenefitActivityUsageFragmentDoc = gql`
    fragment CareBenefitActivityUsage on CareBenefitUsage {
  id
  createdAt
  updatedAt
  usedAt
  quantityUsed
  exhaustsBenefit
  recordedByUser {
    ...RecordingUser
  }
  usageClinic {
    id
    name
  }
  benefit {
    id
    title
    description
    tangible
    exhaustible
    usageUnitOfMeasure
    type
  }
  invoiceLineItemConnections {
    id
    adjustedAmount
  }
}
    ${RecordingUserFragmentDoc}`;
export const ActivityLogCareEnrollmentFragmentDoc = gql`
    fragment ActivityLogCareEnrollment on CarePlanEnrollment {
  id
  startDate
  expirationDate
  cancelledAt
  renewalRejectionReason
  renewalEmailNoticeSentAt
  renewalSuccessorEnrollment {
    id
  }
  renewalPredecessorEnrollment {
    id
    plan {
      id
      title
      flowAlias
    }
  }
  plan {
    id
    title
    flowAlias
  }
}
    `;
export const ActivityLogEnrollmentSupportEventFragmentDoc = gql`
    fragment ActivityLogEnrollmentSupportEvent on EnrollmentSupportEvent {
  id
  createdAt
  requester {
    ...RecordingUser
  }
  carePlanEnrollmentId
  manageMembershipRequestType
  cancelReason
  amount
  paymentSchedule
  currentPlan {
    id
    flowAlias
    title
  }
  newPlan {
    id
    flowAlias
    title
  }
  transferFromPet {
    id
    preferredName
  }
  transferToPet {
    id
    preferredName
  }
  carePlanEnrollment {
    id
    careSubscriptionTrueUps(where: {type: {not: {equals: Internal}}}) {
      id
    }
  }
}
    ${RecordingUserFragmentDoc}`;
export const CarePlanInfoFragmentDoc = gql`
    fragment CarePlanInfo on CarePlan {
  id
  pricePerRenewal
  planDiscount
  renewalCycle
  title
  flowAlias
  planBenefits {
    id
    benefit {
      id
      type
      reportingExpectedValue
    }
  }
}
    `;
export const BalanceForClientFragmentDoc = gql`
    fragment BalanceForClient on BitwerxClientBalance {
  currentBalance
  agingCurrent
  aging30D
  aging60D
  aging90D
  aging120D
}
    `;
export const StripePaymentIntentStatusFragmentDoc = gql`
    fragment StripePaymentIntentStatus on StripePaymentIntent {
  status
}
    `;
export const InvoiceForClientFragmentDoc = gql`
    fragment InvoiceForClient on Invoice {
  id
  status
  createdAt
  updatedAt
  subtotal
  taxes
  total
  date
  outstandingBalance
  currency
  sentBy {
    id
    nameDisplay
    firstName
    lastName
  }
  clinicPetParent {
    id
    firstName
    lastName
    email
    phoneNumber
  }
  identifier
  clinicPetParent {
    id
    email
    phoneNumber
    firstName
    lastName
  }
  stripePaymentIntents {
    ...StripePaymentIntentStatus
  }
  relatedInvoiceWritebacks {
    action
  }
}
    ${StripePaymentIntentStatusFragmentDoc}`;
export const ClinicCarePlanFragmentDoc = gql`
    fragment ClinicCarePlan on CarePlan {
  id
  title
  flowAlias
  description
  published
  pricePerRenewal
  renewalCycle
}
    `;
export const ClinicCarePlanProviderGroupFragmentDoc = gql`
    fragment ClinicCarePlanProviderGroup on CarePlanProviderGroup {
  id
  associatedPlans(where: {published: {equals: true}}) {
    ...ClinicCarePlan
  }
}
    ${ClinicCarePlanFragmentDoc}`;
export const StripeTerminalClinicPetParentFragmentDoc = gql`
    fragment StripeTerminalClinicPetParent on ClinicPetParent {
  id
  firstName
  lastName
  email
  pimsId
}
    `;
export const CareBenefitPetParentFragmentDoc = gql`
    fragment CareBenefitPetParent on ClinicPetParent {
  ...StripeTerminalClinicPetParent
  ...PetParentAvatar
  organizationPetParent {
    id
    stripeCustomerTableId
  }
}
    ${StripeTerminalClinicPetParentFragmentDoc}
${PetParentAvatarFragmentDoc}`;
export const LoyaltyProgramRewardRedemptionFragmentDoc = gql`
    fragment LoyaltyProgramRewardRedemption on LoyaltyRewardRedemption {
  id
  createdAt
  reward {
    id
    title
    description
    pointCost
  }
}
    `;
export const RedemptionHistoryFragmentDoc = gql`
    fragment RedemptionHistory on LoyaltyRewardRedemptionHistoryEntry {
  id
  createdAt
  status
  actorType
  actingUser {
    id
    firstName
    lastName
  }
}
    `;
export const RewardHistoryFragmentDoc = gql`
    fragment RewardHistory on LoyaltyPointDelta {
  id
  createdAt
  source
  userGrant {
    id
    amount
    publicNotes
    internalNotes
    grantingUser {
      id
      firstName
      lastName
    }
  }
  points
  relatedRedemptionHistoryEntry {
    id
    redemption {
      id
      updatedAt
      status
      reward {
        id
        title
        pointCost
      }
      redemptionHistories {
        ...RedemptionHistory
      }
    }
  }
}
    ${RedemptionHistoryFragmentDoc}`;
export const SidePanelParentPetDataFragmentDoc = gql`
    fragment SidePanelParentPetData on ClinicPet {
  id
  pimsId
  weight
  clinicId
  ...PetAvatar
  organizationPet {
    ...SidePanelOrganizationPet
  }
}
    ${PetAvatarFragmentDoc}
${SidePanelOrganizationPetFragmentDoc}`;
export const SidePanelPetParentProfilePhoneNumberFragmentDoc = gql`
    fragment SidePanelPetParentProfilePhoneNumber on ClinicEntityPhoneNumber {
  id
  number
  isPrimary
  isMobile
  isDeleted
  updatedAt
  phoneNumberLookupResult {
    id
    lineType
  }
  phoneNumberLookupRequest {
    id
    status
  }
}
    `;
export const SidePanelProfilePetParentSettingFragmentDoc = gql`
    fragment SidePanelProfilePetParentSetting on PetParentSetting {
  id
  createdAt
  updatedAt
  emailNotifications
  emailOptedOutByPetParent
  smsNotifications
  smsOptedOutByPetParent
  sendSurvey
  pushNotifications
}
    `;
export const SidePanelProfileClinicPetParentFragmentDoc = gql`
    fragment SidePanelProfileClinicPetParent on ClinicPetParent {
  id
  email
  firstName
  lastName
  isActive
  createdAt
  updatedAt
  pimsId
  ...PetParentAvatar
  phoneNumbers {
    ...SidePanelPetParentProfilePhoneNumber
  }
  petParentSetting {
    ...SidePanelProfilePetParentSetting
  }
  pets(
    take: 30
    where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isDeleted: {equals: false}, OR: [{NOT: {isDeceased: {equals: true}}}, {isDeceased: {equals: null}}]}
  ) {
    id
    breed
    gender
    isActive
    isDeleted
    isDeceased
    dateOfBirth
    name
    species
    speciesType
    weight
    weightUnit
    isDeceased
    isActive
    photoUrl
    ...OrganizationPetCareBadgePlanEnrollment
    alerts {
      value
      pimsDate
      pimsId
      isMedical
    }
  }
  loyaltyAccountHolder {
    id
    program {
      id
      status
    }
    account {
      pointBalance
      rewardRedemptions {
        id
      }
    }
  }
}
    ${PetParentAvatarFragmentDoc}
${SidePanelPetParentProfilePhoneNumberFragmentDoc}
${SidePanelProfilePetParentSettingFragmentDoc}
${OrganizationPetCareBadgePlanEnrollmentFragmentDoc}`;
export const SidePanelPetServiceRemindersFragmentDoc = gql`
    fragment SidePanelPetServiceReminders on ServiceReminder {
  id
  dueDate
  dateLastGiven
  service {
    id
    name
    reminderActivationDate
  }
}
    `;
export const SidePanelSearchClinicEntityPhoneNumberFragmentDoc = gql`
    fragment SidePanelSearchClinicEntityPhoneNumber on ClinicEntityPhoneNumber {
  id
  number
  isPrimary
  isMobile
  isDeleted
  updatedAt
}
    `;
export const SidePanelSearchClinicPetFragmentDoc = gql`
    fragment SidePanelSearchClinicPet on ClinicPet {
  id
  pimsId
  speciesType
  breed
  gender
  isActive
  isDeleted
  ...PetAvatar
}
    ${PetAvatarFragmentDoc}`;
export const SidePanelSearchClinicPetParentFragmentDoc = gql`
    fragment SidePanelSearchClinicPetParent on ClinicPetParent {
  id
  updatedAt
  firstName
  lastName
  email
  isActive
  ...PetParentAvatar
  phoneNumbers(where: {isPrimary: {equals: true}}) {
    ...SidePanelSearchClinicEntityPhoneNumber
  }
  pimsId
  pets(take: 5, where: $petWhere) {
    ...SidePanelSearchClinicPet
  }
}
    ${PetParentAvatarFragmentDoc}
${SidePanelSearchClinicEntityPhoneNumberFragmentDoc}
${SidePanelSearchClinicPetFragmentDoc}`;
export const SidePanelPetFragmentDoc = gql`
    fragment SidePanelPet on ClinicPet {
  id
  breed
  gender
  ...PetAvatar
}
    ${PetAvatarFragmentDoc}`;
export const SidePanelPetParentFragmentDoc = gql`
    fragment SidePanelPetParent on ClinicPetParent {
  id
  phoneNumbers {
    id
    number
    isPrimary
    isMobile
    isDeleted
    updatedAt
  }
  ...PetParentAvatar
}
    ${PetParentAvatarFragmentDoc}`;
export const ExistingClinicPetParentMatchFragmentDoc = gql`
    fragment ExistingClinicPetParentMatch on ClinicPetParent {
  id
  firstName
  lastName
  email
  phoneNumber
  phoneNumbers {
    id
    updatedAt
    number
    isPrimary
    isMobile
    isDeleted
  }
  creationSource
  pets {
    ...PetAvatar
  }
}
    ${PetAvatarFragmentDoc}`;
export const ChannelStatusFragmentDoc = gql`
    fragment ChannelStatus on ChannelStatus {
  id
  createdAt
  updatedAt
  channelStatusAction
  color
  deletedOn
  isDefault
  name
  order
  isExportedToPims
}
    `;
export const ChannelStatusChannelAssigneeFragmentFragmentDoc = gql`
    fragment ChannelStatusChannelAssigneeFragment on ChannelStatusChannelAssignee {
  id
  user {
    id
  }
}
    `;
export const FullChannelStatusFragmentDoc = gql`
    fragment FullChannelStatus on ChannelStatus {
  id
  createdAt
  updatedAt
  channelStatusAction
  color
  deletedOn
  isDefault
  name
  order
  isExportedToPims
  channelStatusChannelAssignees {
    ...ChannelStatusChannelAssigneeFragment
  }
}
    ${ChannelStatusChannelAssigneeFragmentFragmentDoc}`;
export const SharedUserNotificationSettingsFragmentDoc = gql`
    fragment SharedUserNotificationSettings on UserSetting {
  id
  browserNotificationFilter {
    id
    assignees {
      id
    }
    includeUnassigned
  }
  snoozeNotificationsUntil
  notificationSettings {
    id
    emailEnabled
    smsEnabled
    soundNotificationUrl
    browserNotificationEnabled
    pushNotificationEnabled
  }
}
    `;
export const StripePaymentIntentFragmentDoc = gql`
    fragment StripePaymentIntent on StripePaymentIntent {
  id
  createdAt
  updatedAt
  stripeId
  status
  amount
  clientServiceFee
  total
  amountCaptured
  serviceFeeAmount
  clientServiceFeePercent
  lastPaymentError
  clinicPetParent {
    id
    firstName
    lastName
  }
  stripePaymentMethod {
    last4
    cardBrand
  }
  stripeRefunds {
    id
    amount
    clientFeeRefunded
    updatedAt
  }
}
    `;
export const StripePaymentOutputFragmentDoc = gql`
    fragment StripePaymentOutput on StripePaymentOutput {
  paymentIntentClientSecret
  requiresAction
  payment {
    id
    status
    amount
    total
    amountCaptured
    amountRefunded
    transferType
    receiptUrl
  }
}
    `;
export const ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutationFragmentDoc = gql`
    fragment ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutation on ClinicPetParentWithJobChainsResponse {
  clinicPetParent {
    id
    email
    firstName
    lastName
    isActive
    updatedAt
    ...PetParentAvatar
    pimsId
    phoneNumbers {
      id
      number
      isPrimary
      isMobile
      isDeleted
      updatedAt
    }
  }
  jobChains {
    id
    name
  }
}
    ${PetParentAvatarFragmentDoc}`;
export const ClinicFormTemplateFragmentDoc = gql`
    fragment ClinicFormTemplate on FormTemplate {
  id
  isActive
  isDeleted
  content
  draftContent
  title
  createdAt
  updatedAt
  formTemplateType
  autoExportEnabled
  services {
    id
    name
  }
  clinicWidgetRequestTypes {
    id
    displayName
    requestType
  }
  clinic {
    id
    hasServiceReminders
  }
}
    `;
export const ClinicPimsIntegrationFragmentDoc = gql`
    fragment ClinicPimsIntegration on ClinicPimsIntegration {
  id
  type
  isActive
  lastSyncedAt
  status
  displayName @client
  isExportToPimsSupported @client
  isInvoicePreviewSupported @client
  isInvoiceDiscoverySupported @client
  isTriggerPimsSyncSupported @client
  isClientIdSupported @client
  isMergeExistingPimsProfileSupported @client
  isContactSyncSupported @client
  isBillingCodeSupported @client
  isPatientIdSupported @client
  isServiceCodeSupported @client
  arePostcardsSupported @client
  systemName @client
  vetdata {
    id
    system
    isMultiSite
  }
  bitwerx {
    id
    practiceId
    system
  }
  capabilities {
    id
    capability
    isActive
    lastSyncedAt
  }
}
    `;
export const ClinicUserClinicFragmentDoc = gql`
    fragment ClinicUserClinic on Clinic {
  id
  createdAt
  name
  phone
  submerchantStripeId
  submerchantStripeChargesEnabled
  submerchantStripePayoutsEnabled
  timezoneName
  logo
  fullAddress
  websiteUrl
  clinicEmail
  addressLine1
  addressLine2
  postalCode
  country
  city
  state
  isCallerIdVerified
  hasSmsConversations
  hasServiceReminders
  activationDate
  isClinicActive
  crmId
  organizationId
  roles {
    id
    role
    permissions {
      id
      type
    }
  }
  integrations {
    ...ClinicPimsIntegration
  }
  isAddAppointmentDisabled
  clinicOfficeHours {
    id
    type
    dayOfWeek
    openAt
    closeAt
    isEnabled
    isOpenTwentyFourHours
  }
  clinicSetting {
    id
    appointmentTimeSlotIncrementInMinutes
    hasCustomizableFees
    hideAppointmentsWithoutPetOwner
    outOfOfficeHeadline
    outOfOfficeEnabled
    outOfOfficeShowScheduleEnabled
    paymentFeeConfig {
      id
      onlineProcessingFeeFlat
      onlineProcessingFeePercent
      onlineClientServiceFeePercent
      terminalProcessingFeeFlat
      terminalProcessingFeePercent
      terminalClientServiceFeePercent
    }
  }
  stripeTerminalLocations {
    id
    stripeId
    isActive
    terminals {
      id
      name
      model
    }
  }
  carePlanProviderGroupId
}
    ${ClinicPimsIntegrationFragmentDoc}`;
export const ClinicUserVetInfoFragmentDoc = gql`
    fragment ClinicUserVetInfo on VetInfo {
  id
  createdAt
  updatedAt
  roles {
    id
    role
    clinic {
      id
      name
    }
    permissions {
      id
      type
    }
  }
  currentClinic {
    ...ClinicUserClinic
  }
}
    ${ClinicUserClinicFragmentDoc}`;
export const ClinicUserFragmentDoc = gql`
    fragment ClinicUser on User {
  id
  firstName
  lastName
  displayName @client
  nameDisplay
  profilePic
  email
  phone
  type
  invitationCode
  isNewPasswordRequired
  isAdmin
  userSetting {
    id
    pushNotifications
    smsNotifications
    emailNotifications
    leftNavPanel
    browserNotificationFilter {
      id
      assignees {
        id
      }
      includeUnassigned
    }
    snoozeNotificationsUntil
    notificationSettings {
      id
      soundNotificationUrl
      browserNotificationEnabled
    }
    enterAddsNewLine
    skipLoadingAnimation
  }
  clinics {
    ...ClinicUserClinic
  }
  vetInfo {
    ...ClinicUserVetInfo
  }
}
    ${ClinicUserClinicFragmentDoc}
${ClinicUserVetInfoFragmentDoc}`;
export const SubscriptionChannelMessageFragmentDoc = gql`
    fragment SubscriptionChannelMessage on ChannelMessage {
  id
  body
  messageType
  attachments {
    id
    attachmentType
  }
  mentionedUsers {
    firstName
    lastName
    id
  }
  channel {
    id
    channelType
    assignees {
      id
    }
    lastMessage {
      id
    }
    channelStatus {
      id
      channelStatusAction
    }
  }
  author {
    id
    twilioIdentity
    user {
      id
      firstName
      lastName
    }
    clinicPetParent {
      id
      firstName
      lastName
    }
  }
}
    `;
export const CreateOneAppointmentDocument = gql`
    mutation createOneAppointment($data: AppointmentCreateInput!) {
  createOneAppointment(data: $data) {
    ...AppointmentFullSelection
  }
}
    ${AppointmentFullSelectionFragmentDoc}`;
export type CreateOneAppointmentMutationFn = Apollo.MutationFunction<CreateOneAppointmentMutation, CreateOneAppointmentMutationVariables>;

/**
 * __useCreateOneAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateOneAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAppointmentMutation, { data, loading, error }] = useCreateOneAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneAppointmentMutation, CreateOneAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneAppointmentMutation, CreateOneAppointmentMutationVariables>(CreateOneAppointmentDocument, options);
      }
export type CreateOneAppointmentMutationHookResult = ReturnType<typeof useCreateOneAppointmentMutation>;
export type CreateOneAppointmentMutationResult = Apollo.MutationResult<CreateOneAppointmentMutation>;
export type CreateOneAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateOneAppointmentMutation, CreateOneAppointmentMutationVariables>;
export const UpdateOneAppointmentDocument = gql`
    mutation updateOneAppointment($where: AppointmentWhereUniqueInput!, $data: AppointmentUpdateInput!) {
  updateOneAppointment(where: $where, data: $data) {
    ...AppointmentFullSelection
  }
}
    ${AppointmentFullSelectionFragmentDoc}`;
export type UpdateOneAppointmentMutationFn = Apollo.MutationFunction<UpdateOneAppointmentMutation, UpdateOneAppointmentMutationVariables>;

/**
 * __useUpdateOneAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAppointmentMutation, { data, loading, error }] = useUpdateOneAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAppointmentMutation, UpdateOneAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAppointmentMutation, UpdateOneAppointmentMutationVariables>(UpdateOneAppointmentDocument, options);
      }
export type UpdateOneAppointmentMutationHookResult = ReturnType<typeof useUpdateOneAppointmentMutation>;
export type UpdateOneAppointmentMutationResult = Apollo.MutationResult<UpdateOneAppointmentMutation>;
export type UpdateOneAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateOneAppointmentMutation, UpdateOneAppointmentMutationVariables>;
export const FindManyAppointmentDocument = gql`
    query findManyAppointment($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationAndSearchRelevanceInput!], $skip: Int, $take: Int) {
  findManyAppointment(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    ...AppointmentFullSelection
  }
}
    ${AppointmentFullSelectionFragmentDoc}`;

/**
 * __useFindManyAppointmentQuery__
 *
 * To run a query within a React component, call `useFindManyAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAppointmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyAppointmentQuery(baseOptions?: Apollo.QueryHookOptions<FindManyAppointmentQuery, FindManyAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyAppointmentQuery, FindManyAppointmentQueryVariables>(FindManyAppointmentDocument, options);
      }
export function useFindManyAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyAppointmentQuery, FindManyAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyAppointmentQuery, FindManyAppointmentQueryVariables>(FindManyAppointmentDocument, options);
        }
export type FindManyAppointmentQueryHookResult = ReturnType<typeof useFindManyAppointmentQuery>;
export type FindManyAppointmentLazyQueryHookResult = ReturnType<typeof useFindManyAppointmentLazyQuery>;
export type FindManyAppointmentQueryResult = Apollo.QueryResult<FindManyAppointmentQuery, FindManyAppointmentQueryVariables>;
export function refetchFindManyAppointmentQuery(variables?: FindManyAppointmentQueryVariables) {
      return { query: FindManyAppointmentDocument, variables: variables }
    }
export const FindManyAppointmentCountDocument = gql`
    query findManyAppointmentCount($where: AppointmentWhereInput, $orderBy: [AppointmentOrderByWithRelationAndSearchRelevanceInput!], $skip: Int, $take: Int) {
  findManyAppointmentCount(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
  )
}
    `;

/**
 * __useFindManyAppointmentCountQuery__
 *
 * To run a query within a React component, call `useFindManyAppointmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAppointmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAppointmentCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyAppointmentCountQuery(baseOptions?: Apollo.QueryHookOptions<FindManyAppointmentCountQuery, FindManyAppointmentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyAppointmentCountQuery, FindManyAppointmentCountQueryVariables>(FindManyAppointmentCountDocument, options);
      }
export function useFindManyAppointmentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyAppointmentCountQuery, FindManyAppointmentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyAppointmentCountQuery, FindManyAppointmentCountQueryVariables>(FindManyAppointmentCountDocument, options);
        }
export type FindManyAppointmentCountQueryHookResult = ReturnType<typeof useFindManyAppointmentCountQuery>;
export type FindManyAppointmentCountLazyQueryHookResult = ReturnType<typeof useFindManyAppointmentCountLazyQuery>;
export type FindManyAppointmentCountQueryResult = Apollo.QueryResult<FindManyAppointmentCountQuery, FindManyAppointmentCountQueryVariables>;
export function refetchFindManyAppointmentCountQuery(variables?: FindManyAppointmentCountQueryVariables) {
      return { query: FindManyAppointmentCountDocument, variables: variables }
    }
export const FindUniqueAppointmentDocument = gql`
    query findUniqueAppointment($where: AppointmentWhereUniqueInput!) {
  findUniqueAppointment(where: $where) {
    ...AppointmentFullSelection
  }
}
    ${AppointmentFullSelectionFragmentDoc}`;

/**
 * __useFindUniqueAppointmentQuery__
 *
 * To run a query within a React component, call `useFindUniqueAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueAppointmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueAppointmentQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueAppointmentQuery, FindUniqueAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueAppointmentQuery, FindUniqueAppointmentQueryVariables>(FindUniqueAppointmentDocument, options);
      }
export function useFindUniqueAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueAppointmentQuery, FindUniqueAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueAppointmentQuery, FindUniqueAppointmentQueryVariables>(FindUniqueAppointmentDocument, options);
        }
export type FindUniqueAppointmentQueryHookResult = ReturnType<typeof useFindUniqueAppointmentQuery>;
export type FindUniqueAppointmentLazyQueryHookResult = ReturnType<typeof useFindUniqueAppointmentLazyQuery>;
export type FindUniqueAppointmentQueryResult = Apollo.QueryResult<FindUniqueAppointmentQuery, FindUniqueAppointmentQueryVariables>;
export function refetchFindUniqueAppointmentQuery(variables?: FindUniqueAppointmentQueryVariables) {
      return { query: FindUniqueAppointmentDocument, variables: variables }
    }
export const GetAppointmentOptionsDocument = gql`
    query getAppointmentOptions($clinicId: String!) {
  clinicEmployees: findManyClinicEmployee(
    where: {isActive: {equals: true}, isVisible: {equals: true}, isDeleted: {equals: false}, clinic: {id: {equals: $clinicId}}}
  ) {
    ...ClinicEmployeeBaseSelection
  }
  clinicRooms: findManyClinicRoom(
    where: {isActive: {equals: true}, isVisible: {equals: true}, isDeleted: {equals: false}, clinic: {id: {equals: $clinicId}}}
  ) {
    ...ClinicRoomBaseSelection
  }
  appointmentTypes: findManyAppointmentType(
    where: {clinic: {id: {equals: $clinicId}}, isVisible: {equals: true}}
    orderBy: {displayOrder: asc}
  ) {
    ...AppointmentTypeWorkflowsSelection
  }
}
    ${ClinicEmployeeBaseSelectionFragmentDoc}
${ClinicRoomBaseSelectionFragmentDoc}
${AppointmentTypeWorkflowsSelectionFragmentDoc}`;

/**
 * __useGetAppointmentOptionsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentOptionsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAppointmentOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentOptionsQuery, GetAppointmentOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentOptionsQuery, GetAppointmentOptionsQueryVariables>(GetAppointmentOptionsDocument, options);
      }
export function useGetAppointmentOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentOptionsQuery, GetAppointmentOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentOptionsQuery, GetAppointmentOptionsQueryVariables>(GetAppointmentOptionsDocument, options);
        }
export type GetAppointmentOptionsQueryHookResult = ReturnType<typeof useGetAppointmentOptionsQuery>;
export type GetAppointmentOptionsLazyQueryHookResult = ReturnType<typeof useGetAppointmentOptionsLazyQuery>;
export type GetAppointmentOptionsQueryResult = Apollo.QueryResult<GetAppointmentOptionsQuery, GetAppointmentOptionsQueryVariables>;
export function refetchGetAppointmentOptionsQuery(variables?: GetAppointmentOptionsQueryVariables) {
      return { query: GetAppointmentOptionsDocument, variables: variables }
    }
export const GetAppointmentPetsDocument = gql`
    query getAppointmentPets($whereInput: SearchClinicPetInput, $pageSize: Int) {
  clinicPetSearch(where: $whereInput, take: $pageSize) {
    ...AppointmentPet
  }
}
    ${AppointmentPetFragmentDoc}`;

/**
 * __useGetAppointmentPetsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentPetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentPetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentPetsQuery({
 *   variables: {
 *      whereInput: // value for 'whereInput'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAppointmentPetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentPetsQuery, GetAppointmentPetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentPetsQuery, GetAppointmentPetsQueryVariables>(GetAppointmentPetsDocument, options);
      }
export function useGetAppointmentPetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentPetsQuery, GetAppointmentPetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentPetsQuery, GetAppointmentPetsQueryVariables>(GetAppointmentPetsDocument, options);
        }
export type GetAppointmentPetsQueryHookResult = ReturnType<typeof useGetAppointmentPetsQuery>;
export type GetAppointmentPetsLazyQueryHookResult = ReturnType<typeof useGetAppointmentPetsLazyQuery>;
export type GetAppointmentPetsQueryResult = Apollo.QueryResult<GetAppointmentPetsQuery, GetAppointmentPetsQueryVariables>;
export function refetchGetAppointmentPetsQuery(variables?: GetAppointmentPetsQueryVariables) {
      return { query: GetAppointmentPetsDocument, variables: variables }
    }
export const GetFilterUserAppointmentGroupsOptionsDocument = gql`
    query getFilterUserAppointmentGroupsOptions($where: UserAppointmentGroupingWhereInput!) {
  userAppointmentGroupings: findManyUserAppointmentGrouping(
    where: $where
    orderBy: {createdAt: asc}
  ) {
    id
    clinicId
    label
  }
}
    `;

/**
 * __useGetFilterUserAppointmentGroupsOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilterUserAppointmentGroupsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterUserAppointmentGroupsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterUserAppointmentGroupsOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFilterUserAppointmentGroupsOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetFilterUserAppointmentGroupsOptionsQuery, GetFilterUserAppointmentGroupsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterUserAppointmentGroupsOptionsQuery, GetFilterUserAppointmentGroupsOptionsQueryVariables>(GetFilterUserAppointmentGroupsOptionsDocument, options);
      }
export function useGetFilterUserAppointmentGroupsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterUserAppointmentGroupsOptionsQuery, GetFilterUserAppointmentGroupsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterUserAppointmentGroupsOptionsQuery, GetFilterUserAppointmentGroupsOptionsQueryVariables>(GetFilterUserAppointmentGroupsOptionsDocument, options);
        }
export type GetFilterUserAppointmentGroupsOptionsQueryHookResult = ReturnType<typeof useGetFilterUserAppointmentGroupsOptionsQuery>;
export type GetFilterUserAppointmentGroupsOptionsLazyQueryHookResult = ReturnType<typeof useGetFilterUserAppointmentGroupsOptionsLazyQuery>;
export type GetFilterUserAppointmentGroupsOptionsQueryResult = Apollo.QueryResult<GetFilterUserAppointmentGroupsOptionsQuery, GetFilterUserAppointmentGroupsOptionsQueryVariables>;
export function refetchGetFilterUserAppointmentGroupsOptionsQuery(variables?: GetFilterUserAppointmentGroupsOptionsQueryVariables) {
      return { query: GetFilterUserAppointmentGroupsOptionsDocument, variables: variables }
    }
export const GetUserAppointmentGroupingsDocument = gql`
    query getUserAppointmentGroupings($where: UserAppointmentGroupingWhereInput!, $take: Int!, $skip: Int!) {
  count: findManyUserAppointmentGroupingCount(where: $where)
  userAppointmentGroupings: findManyUserAppointmentGrouping(
    where: $where
    take: $take
    skip: $skip
    orderBy: {createdAt: asc}
  ) {
    id
    clinicId
    label
    clinicEmployees {
      id
      firstName
      lastName
    }
    rooms {
      id
      name
    }
    appointmentTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserAppointmentGroupingsQuery__
 *
 * To run a query within a React component, call `useGetUserAppointmentGroupingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppointmentGroupingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppointmentGroupingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUserAppointmentGroupingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserAppointmentGroupingsQuery, GetUserAppointmentGroupingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAppointmentGroupingsQuery, GetUserAppointmentGroupingsQueryVariables>(GetUserAppointmentGroupingsDocument, options);
      }
export function useGetUserAppointmentGroupingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAppointmentGroupingsQuery, GetUserAppointmentGroupingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAppointmentGroupingsQuery, GetUserAppointmentGroupingsQueryVariables>(GetUserAppointmentGroupingsDocument, options);
        }
export type GetUserAppointmentGroupingsQueryHookResult = ReturnType<typeof useGetUserAppointmentGroupingsQuery>;
export type GetUserAppointmentGroupingsLazyQueryHookResult = ReturnType<typeof useGetUserAppointmentGroupingsLazyQuery>;
export type GetUserAppointmentGroupingsQueryResult = Apollo.QueryResult<GetUserAppointmentGroupingsQuery, GetUserAppointmentGroupingsQueryVariables>;
export function refetchGetUserAppointmentGroupingsQuery(variables?: GetUserAppointmentGroupingsQueryVariables) {
      return { query: GetUserAppointmentGroupingsDocument, variables: variables }
    }
export const GetUserAppointmentGroupingsFiltersDocument = gql`
    query getUserAppointmentGroupingsFilters($clinicId: String!) {
  userAppointmentGroupingsFilters @client
}
    `;

/**
 * __useGetUserAppointmentGroupingsFiltersQuery__
 *
 * To run a query within a React component, call `useGetUserAppointmentGroupingsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppointmentGroupingsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppointmentGroupingsFiltersQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetUserAppointmentGroupingsFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetUserAppointmentGroupingsFiltersQuery, GetUserAppointmentGroupingsFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAppointmentGroupingsFiltersQuery, GetUserAppointmentGroupingsFiltersQueryVariables>(GetUserAppointmentGroupingsFiltersDocument, options);
      }
export function useGetUserAppointmentGroupingsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAppointmentGroupingsFiltersQuery, GetUserAppointmentGroupingsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAppointmentGroupingsFiltersQuery, GetUserAppointmentGroupingsFiltersQueryVariables>(GetUserAppointmentGroupingsFiltersDocument, options);
        }
export type GetUserAppointmentGroupingsFiltersQueryHookResult = ReturnType<typeof useGetUserAppointmentGroupingsFiltersQuery>;
export type GetUserAppointmentGroupingsFiltersLazyQueryHookResult = ReturnType<typeof useGetUserAppointmentGroupingsFiltersLazyQuery>;
export type GetUserAppointmentGroupingsFiltersQueryResult = Apollo.QueryResult<GetUserAppointmentGroupingsFiltersQuery, GetUserAppointmentGroupingsFiltersQueryVariables>;
export function refetchGetUserAppointmentGroupingsFiltersQuery(variables?: GetUserAppointmentGroupingsFiltersQueryVariables) {
      return { query: GetUserAppointmentGroupingsFiltersDocument, variables: variables }
    }
export const CreateAutomationAndSendConditionsDocument = gql`
    mutation createAutomationAndSendConditions($workflowEventSettingData: WorkflowEventSettingCreateInput!, $conditionsData: [ConditionCreateWithoutSetInput!]!) {
  createWorkflowEventSettingAndConditions(
    data: {workflowEventSetting: $workflowEventSettingData, conditions: $conditionsData}
  ) {
    ...AutomationEditorAutomation
  }
}
    ${AutomationEditorAutomationFragmentDoc}`;
export type CreateAutomationAndSendConditionsMutationFn = Apollo.MutationFunction<CreateAutomationAndSendConditionsMutation, CreateAutomationAndSendConditionsMutationVariables>;

/**
 * __useCreateAutomationAndSendConditionsMutation__
 *
 * To run a mutation, you first call `useCreateAutomationAndSendConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutomationAndSendConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutomationAndSendConditionsMutation, { data, loading, error }] = useCreateAutomationAndSendConditionsMutation({
 *   variables: {
 *      workflowEventSettingData: // value for 'workflowEventSettingData'
 *      conditionsData: // value for 'conditionsData'
 *   },
 * });
 */
export function useCreateAutomationAndSendConditionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAutomationAndSendConditionsMutation, CreateAutomationAndSendConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAutomationAndSendConditionsMutation, CreateAutomationAndSendConditionsMutationVariables>(CreateAutomationAndSendConditionsDocument, options);
      }
export type CreateAutomationAndSendConditionsMutationHookResult = ReturnType<typeof useCreateAutomationAndSendConditionsMutation>;
export type CreateAutomationAndSendConditionsMutationResult = Apollo.MutationResult<CreateAutomationAndSendConditionsMutation>;
export type CreateAutomationAndSendConditionsMutationOptions = Apollo.BaseMutationOptions<CreateAutomationAndSendConditionsMutation, CreateAutomationAndSendConditionsMutationVariables>;
export const DeleteOneAutomationDocument = gql`
    mutation deleteOneAutomation($workflowEventSettingId: String!) {
  deleteOneWorkflowEventSetting(where: {id: $workflowEventSettingId}) {
    ...AutomationsListItem
  }
  deleteManyCondition(
    where: {set: {conditionalEntityType: {equals: WorkflowEventSetting}, conditionalEntityId: {equals: $workflowEventSettingId}}}
  ) {
    count
  }
  deleteManyConditionSet(
    where: {conditionalEntityType: {equals: WorkflowEventSetting}, conditionalEntityId: {equals: $workflowEventSettingId}}
  ) {
    count
  }
}
    ${AutomationsListItemFragmentDoc}`;
export type DeleteOneAutomationMutationFn = Apollo.MutationFunction<DeleteOneAutomationMutation, DeleteOneAutomationMutationVariables>;

/**
 * __useDeleteOneAutomationMutation__
 *
 * To run a mutation, you first call `useDeleteOneAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneAutomationMutation, { data, loading, error }] = useDeleteOneAutomationMutation({
 *   variables: {
 *      workflowEventSettingId: // value for 'workflowEventSettingId'
 *   },
 * });
 */
export function useDeleteOneAutomationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneAutomationMutation, DeleteOneAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneAutomationMutation, DeleteOneAutomationMutationVariables>(DeleteOneAutomationDocument, options);
      }
export type DeleteOneAutomationMutationHookResult = ReturnType<typeof useDeleteOneAutomationMutation>;
export type DeleteOneAutomationMutationResult = Apollo.MutationResult<DeleteOneAutomationMutation>;
export type DeleteOneAutomationMutationOptions = Apollo.BaseMutationOptions<DeleteOneAutomationMutation, DeleteOneAutomationMutationVariables>;
export const DuplicateOneAutomationDocument = gql`
    mutation duplicateOneAutomation($where: WorkflowEventSettingWhereUniqueInput!) {
  copyOneWorkflowEventSetting(where: $where) {
    id
  }
}
    `;
export type DuplicateOneAutomationMutationFn = Apollo.MutationFunction<DuplicateOneAutomationMutation, DuplicateOneAutomationMutationVariables>;

/**
 * __useDuplicateOneAutomationMutation__
 *
 * To run a mutation, you first call `useDuplicateOneAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOneAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOneAutomationMutation, { data, loading, error }] = useDuplicateOneAutomationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDuplicateOneAutomationMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateOneAutomationMutation, DuplicateOneAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateOneAutomationMutation, DuplicateOneAutomationMutationVariables>(DuplicateOneAutomationDocument, options);
      }
export type DuplicateOneAutomationMutationHookResult = ReturnType<typeof useDuplicateOneAutomationMutation>;
export type DuplicateOneAutomationMutationResult = Apollo.MutationResult<DuplicateOneAutomationMutation>;
export type DuplicateOneAutomationMutationOptions = Apollo.BaseMutationOptions<DuplicateOneAutomationMutation, DuplicateOneAutomationMutationVariables>;
export const ToggleManyAutomationPublishedStateDocument = gql`
    mutation toggleManyAutomationPublishedState($workflowEventSettingIds: [String!]!, $isPublished: Boolean!) {
  bulkPublishWorkflowEventSettings(
    data: {workflowEventSettingIds: $workflowEventSettingIds, isPublished: $isPublished}
  ) {
    count
  }
}
    `;
export type ToggleManyAutomationPublishedStateMutationFn = Apollo.MutationFunction<ToggleManyAutomationPublishedStateMutation, ToggleManyAutomationPublishedStateMutationVariables>;

/**
 * __useToggleManyAutomationPublishedStateMutation__
 *
 * To run a mutation, you first call `useToggleManyAutomationPublishedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleManyAutomationPublishedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleManyAutomationPublishedStateMutation, { data, loading, error }] = useToggleManyAutomationPublishedStateMutation({
 *   variables: {
 *      workflowEventSettingIds: // value for 'workflowEventSettingIds'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useToggleManyAutomationPublishedStateMutation(baseOptions?: Apollo.MutationHookOptions<ToggleManyAutomationPublishedStateMutation, ToggleManyAutomationPublishedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleManyAutomationPublishedStateMutation, ToggleManyAutomationPublishedStateMutationVariables>(ToggleManyAutomationPublishedStateDocument, options);
      }
export type ToggleManyAutomationPublishedStateMutationHookResult = ReturnType<typeof useToggleManyAutomationPublishedStateMutation>;
export type ToggleManyAutomationPublishedStateMutationResult = Apollo.MutationResult<ToggleManyAutomationPublishedStateMutation>;
export type ToggleManyAutomationPublishedStateMutationOptions = Apollo.BaseMutationOptions<ToggleManyAutomationPublishedStateMutation, ToggleManyAutomationPublishedStateMutationVariables>;
export const UpdateOneAutomationDocument = gql`
    mutation updateOneAutomation($workflowEventSettingId: String!, $workflowEventSettingData: WorkflowEventSettingUpdateInput!) {
  updateOneWorkflowEventSetting(
    where: {id: $workflowEventSettingId}
    data: $workflowEventSettingData
  ) {
    ...AutomationEditorAutomation
  }
}
    ${AutomationEditorAutomationFragmentDoc}`;
export type UpdateOneAutomationMutationFn = Apollo.MutationFunction<UpdateOneAutomationMutation, UpdateOneAutomationMutationVariables>;

/**
 * __useUpdateOneAutomationMutation__
 *
 * To run a mutation, you first call `useUpdateOneAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAutomationMutation, { data, loading, error }] = useUpdateOneAutomationMutation({
 *   variables: {
 *      workflowEventSettingId: // value for 'workflowEventSettingId'
 *      workflowEventSettingData: // value for 'workflowEventSettingData'
 *   },
 * });
 */
export function useUpdateOneAutomationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAutomationMutation, UpdateOneAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAutomationMutation, UpdateOneAutomationMutationVariables>(UpdateOneAutomationDocument, options);
      }
export type UpdateOneAutomationMutationHookResult = ReturnType<typeof useUpdateOneAutomationMutation>;
export type UpdateOneAutomationMutationResult = Apollo.MutationResult<UpdateOneAutomationMutation>;
export type UpdateOneAutomationMutationOptions = Apollo.BaseMutationOptions<UpdateOneAutomationMutation, UpdateOneAutomationMutationVariables>;
export const UpdateOneAutomationAndConditionSetDocument = gql`
    mutation updateOneAutomationAndConditionSet($workflowEventSettingId: String!, $workflowEventSettingData: WorkflowEventSettingUpdateInput!, $conditionSetId: String!, $conditionSetData: ConditionSetUpdateInput!) {
  updateOneWorkflowEventSetting(
    where: {id: $workflowEventSettingId}
    data: $workflowEventSettingData
  ) {
    ...AutomationEditorAutomation
  }
  updateOneConditionSet(where: {id: $conditionSetId}, data: $conditionSetData) {
    ...AutomationEditorConditionSet
  }
}
    ${AutomationEditorAutomationFragmentDoc}
${AutomationEditorConditionSetFragmentDoc}`;
export type UpdateOneAutomationAndConditionSetMutationFn = Apollo.MutationFunction<UpdateOneAutomationAndConditionSetMutation, UpdateOneAutomationAndConditionSetMutationVariables>;

/**
 * __useUpdateOneAutomationAndConditionSetMutation__
 *
 * To run a mutation, you first call `useUpdateOneAutomationAndConditionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAutomationAndConditionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAutomationAndConditionSetMutation, { data, loading, error }] = useUpdateOneAutomationAndConditionSetMutation({
 *   variables: {
 *      workflowEventSettingId: // value for 'workflowEventSettingId'
 *      workflowEventSettingData: // value for 'workflowEventSettingData'
 *      conditionSetId: // value for 'conditionSetId'
 *      conditionSetData: // value for 'conditionSetData'
 *   },
 * });
 */
export function useUpdateOneAutomationAndConditionSetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAutomationAndConditionSetMutation, UpdateOneAutomationAndConditionSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAutomationAndConditionSetMutation, UpdateOneAutomationAndConditionSetMutationVariables>(UpdateOneAutomationAndConditionSetDocument, options);
      }
export type UpdateOneAutomationAndConditionSetMutationHookResult = ReturnType<typeof useUpdateOneAutomationAndConditionSetMutation>;
export type UpdateOneAutomationAndConditionSetMutationResult = Apollo.MutationResult<UpdateOneAutomationAndConditionSetMutation>;
export type UpdateOneAutomationAndConditionSetMutationOptions = Apollo.BaseMutationOptions<UpdateOneAutomationAndConditionSetMutation, UpdateOneAutomationAndConditionSetMutationVariables>;
export const GetAutomationEditorDataDocument = gql`
    query getAutomationEditorData($workflowEventSettingId: String) {
  findUniqueWorkflowEventSetting(where: {id: $workflowEventSettingId}) {
    ...AutomationEditorAutomation
  }
  findManyConditionSet(
    where: {conditionalEntityType: {equals: WorkflowEventSetting}, conditionalEntityId: {equals: $workflowEventSettingId}}
  ) {
    ...AutomationEditorConditionSet
  }
}
    ${AutomationEditorAutomationFragmentDoc}
${AutomationEditorConditionSetFragmentDoc}`;

/**
 * __useGetAutomationEditorDataQuery__
 *
 * To run a query within a React component, call `useGetAutomationEditorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationEditorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationEditorDataQuery({
 *   variables: {
 *      workflowEventSettingId: // value for 'workflowEventSettingId'
 *   },
 * });
 */
export function useGetAutomationEditorDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAutomationEditorDataQuery, GetAutomationEditorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationEditorDataQuery, GetAutomationEditorDataQueryVariables>(GetAutomationEditorDataDocument, options);
      }
export function useGetAutomationEditorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationEditorDataQuery, GetAutomationEditorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationEditorDataQuery, GetAutomationEditorDataQueryVariables>(GetAutomationEditorDataDocument, options);
        }
export type GetAutomationEditorDataQueryHookResult = ReturnType<typeof useGetAutomationEditorDataQuery>;
export type GetAutomationEditorDataLazyQueryHookResult = ReturnType<typeof useGetAutomationEditorDataLazyQuery>;
export type GetAutomationEditorDataQueryResult = Apollo.QueryResult<GetAutomationEditorDataQuery, GetAutomationEditorDataQueryVariables>;
export function refetchGetAutomationEditorDataQuery(variables?: GetAutomationEditorDataQueryVariables) {
      return { query: GetAutomationEditorDataDocument, variables: variables }
    }
export const GetAutomationFormTemplateOptionsDocument = gql`
    query getAutomationFormTemplateOptions($where: FormTemplateWhereInput!) {
  findManyFormTemplate(where: $where, orderBy: {title: asc}) {
    id
    title
    isActive
    isDeleted
  }
}
    `;

/**
 * __useGetAutomationFormTemplateOptionsQuery__
 *
 * To run a query within a React component, call `useGetAutomationFormTemplateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationFormTemplateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationFormTemplateOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAutomationFormTemplateOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationFormTemplateOptionsQuery, GetAutomationFormTemplateOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationFormTemplateOptionsQuery, GetAutomationFormTemplateOptionsQueryVariables>(GetAutomationFormTemplateOptionsDocument, options);
      }
export function useGetAutomationFormTemplateOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationFormTemplateOptionsQuery, GetAutomationFormTemplateOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationFormTemplateOptionsQuery, GetAutomationFormTemplateOptionsQueryVariables>(GetAutomationFormTemplateOptionsDocument, options);
        }
export type GetAutomationFormTemplateOptionsQueryHookResult = ReturnType<typeof useGetAutomationFormTemplateOptionsQuery>;
export type GetAutomationFormTemplateOptionsLazyQueryHookResult = ReturnType<typeof useGetAutomationFormTemplateOptionsLazyQuery>;
export type GetAutomationFormTemplateOptionsQueryResult = Apollo.QueryResult<GetAutomationFormTemplateOptionsQuery, GetAutomationFormTemplateOptionsQueryVariables>;
export function refetchGetAutomationFormTemplateOptionsQuery(variables?: GetAutomationFormTemplateOptionsQueryVariables) {
      return { query: GetAutomationFormTemplateOptionsDocument, variables: variables }
    }
export const GetAutomationParentAutomationsDocument = gql`
    query getAutomationParentAutomations($workflowId: String!) {
  findManyParentWorkflowEventSetting(workflowId: $workflowId) {
    ...AutomationsParentItem
  }
}
    ${AutomationsParentItemFragmentDoc}`;

/**
 * __useGetAutomationParentAutomationsQuery__
 *
 * To run a query within a React component, call `useGetAutomationParentAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationParentAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationParentAutomationsQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useGetAutomationParentAutomationsQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationParentAutomationsQuery, GetAutomationParentAutomationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationParentAutomationsQuery, GetAutomationParentAutomationsQueryVariables>(GetAutomationParentAutomationsDocument, options);
      }
export function useGetAutomationParentAutomationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationParentAutomationsQuery, GetAutomationParentAutomationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationParentAutomationsQuery, GetAutomationParentAutomationsQueryVariables>(GetAutomationParentAutomationsDocument, options);
        }
export type GetAutomationParentAutomationsQueryHookResult = ReturnType<typeof useGetAutomationParentAutomationsQuery>;
export type GetAutomationParentAutomationsLazyQueryHookResult = ReturnType<typeof useGetAutomationParentAutomationsLazyQuery>;
export type GetAutomationParentAutomationsQueryResult = Apollo.QueryResult<GetAutomationParentAutomationsQuery, GetAutomationParentAutomationsQueryVariables>;
export function refetchGetAutomationParentAutomationsQuery(variables?: GetAutomationParentAutomationsQueryVariables) {
      return { query: GetAutomationParentAutomationsDocument, variables: variables }
    }
export const GetAutomationSendConditionOptionsDocument = gql`
    query getAutomationSendConditionOptions($clinicId: String!) {
  clinicEmployees: findManyClinicEmployee(where: {clinicId: {equals: $clinicId}}) {
    id
    firstName
    lastName
    isActive
    isDeleted
    isVisible
  }
  clinicRooms: findManyClinicRoom(where: {clinicId: {equals: $clinicId}}) {
    id
    name
    isActive
    isVisible
    isDeleted
  }
  appointmentTypes: findManyAppointmentType(
    where: {clinicId: {equals: $clinicId}}
  ) {
    id
    name
    displayName
    isVisible
  }
}
    `;

/**
 * __useGetAutomationSendConditionOptionsQuery__
 *
 * To run a query within a React component, call `useGetAutomationSendConditionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationSendConditionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationSendConditionOptionsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAutomationSendConditionOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationSendConditionOptionsQuery, GetAutomationSendConditionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationSendConditionOptionsQuery, GetAutomationSendConditionOptionsQueryVariables>(GetAutomationSendConditionOptionsDocument, options);
      }
export function useGetAutomationSendConditionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationSendConditionOptionsQuery, GetAutomationSendConditionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationSendConditionOptionsQuery, GetAutomationSendConditionOptionsQueryVariables>(GetAutomationSendConditionOptionsDocument, options);
        }
export type GetAutomationSendConditionOptionsQueryHookResult = ReturnType<typeof useGetAutomationSendConditionOptionsQuery>;
export type GetAutomationSendConditionOptionsLazyQueryHookResult = ReturnType<typeof useGetAutomationSendConditionOptionsLazyQuery>;
export type GetAutomationSendConditionOptionsQueryResult = Apollo.QueryResult<GetAutomationSendConditionOptionsQuery, GetAutomationSendConditionOptionsQueryVariables>;
export function refetchGetAutomationSendConditionOptionsQuery(variables?: GetAutomationSendConditionOptionsQueryVariables) {
      return { query: GetAutomationSendConditionOptionsDocument, variables: variables }
    }
export const GetAutomationWidgetRequestTypeOptionsDocument = gql`
    query getAutomationWidgetRequestTypeOptions($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    clinicWidgetSetting {
      clinicWidgetRequestTypes {
        id
        displayName
        requestType
        isDeleted
        isActive
      }
    }
  }
}
    `;

/**
 * __useGetAutomationWidgetRequestTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetAutomationWidgetRequestTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationWidgetRequestTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationWidgetRequestTypeOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAutomationWidgetRequestTypeOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationWidgetRequestTypeOptionsQuery, GetAutomationWidgetRequestTypeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationWidgetRequestTypeOptionsQuery, GetAutomationWidgetRequestTypeOptionsQueryVariables>(GetAutomationWidgetRequestTypeOptionsDocument, options);
      }
export function useGetAutomationWidgetRequestTypeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationWidgetRequestTypeOptionsQuery, GetAutomationWidgetRequestTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationWidgetRequestTypeOptionsQuery, GetAutomationWidgetRequestTypeOptionsQueryVariables>(GetAutomationWidgetRequestTypeOptionsDocument, options);
        }
export type GetAutomationWidgetRequestTypeOptionsQueryHookResult = ReturnType<typeof useGetAutomationWidgetRequestTypeOptionsQuery>;
export type GetAutomationWidgetRequestTypeOptionsLazyQueryHookResult = ReturnType<typeof useGetAutomationWidgetRequestTypeOptionsLazyQuery>;
export type GetAutomationWidgetRequestTypeOptionsQueryResult = Apollo.QueryResult<GetAutomationWidgetRequestTypeOptionsQuery, GetAutomationWidgetRequestTypeOptionsQueryVariables>;
export function refetchGetAutomationWidgetRequestTypeOptionsQuery(variables?: GetAutomationWidgetRequestTypeOptionsQueryVariables) {
      return { query: GetAutomationWidgetRequestTypeOptionsDocument, variables: variables }
    }
export const GetAutomationsConversationStatusOptionsDocument = gql`
    query getAutomationsConversationStatusOptions($clinicId: String!) {
  findManyChannelStatus(
    where: {clinicSetting: {clinic: {id: {equals: $clinicId}}}, deletedOn: {equals: null}}
    orderBy: {name: asc}
  ) {
    id
    color
    name
    channelStatusAction
  }
}
    `;

/**
 * __useGetAutomationsConversationStatusOptionsQuery__
 *
 * To run a query within a React component, call `useGetAutomationsConversationStatusOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationsConversationStatusOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationsConversationStatusOptionsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAutomationsConversationStatusOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationsConversationStatusOptionsQuery, GetAutomationsConversationStatusOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationsConversationStatusOptionsQuery, GetAutomationsConversationStatusOptionsQueryVariables>(GetAutomationsConversationStatusOptionsDocument, options);
      }
export function useGetAutomationsConversationStatusOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationsConversationStatusOptionsQuery, GetAutomationsConversationStatusOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationsConversationStatusOptionsQuery, GetAutomationsConversationStatusOptionsQueryVariables>(GetAutomationsConversationStatusOptionsDocument, options);
        }
export type GetAutomationsConversationStatusOptionsQueryHookResult = ReturnType<typeof useGetAutomationsConversationStatusOptionsQuery>;
export type GetAutomationsConversationStatusOptionsLazyQueryHookResult = ReturnType<typeof useGetAutomationsConversationStatusOptionsLazyQuery>;
export type GetAutomationsConversationStatusOptionsQueryResult = Apollo.QueryResult<GetAutomationsConversationStatusOptionsQuery, GetAutomationsConversationStatusOptionsQueryVariables>;
export function refetchGetAutomationsConversationStatusOptionsQuery(variables?: GetAutomationsConversationStatusOptionsQueryVariables) {
      return { query: GetAutomationsConversationStatusOptionsDocument, variables: variables }
    }
export const GetAutomationsListDocument = gql`
    query getAutomationsList($clinicId: String!, $inEventType: [WorkflowEvent!], $triggerType: WorkflowEventTriggerType) {
  findManyWorkflowEventSetting(
    where: {clinic: {id: {equals: $clinicId}}, triggerType: {equals: $triggerType}, event: {not: {equals: ServiceReminder_FormSubmitted}, in: $inEventType}, surveySettingId: null, deletedAt: null}
    orderBy: {name: asc}
  ) {
    ...AutomationsListItem
  }
}
    ${AutomationsListItemFragmentDoc}`;

/**
 * __useGetAutomationsListQuery__
 *
 * To run a query within a React component, call `useGetAutomationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationsListQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      inEventType: // value for 'inEventType'
 *      triggerType: // value for 'triggerType'
 *   },
 * });
 */
export function useGetAutomationsListQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationsListQuery, GetAutomationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationsListQuery, GetAutomationsListQueryVariables>(GetAutomationsListDocument, options);
      }
export function useGetAutomationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationsListQuery, GetAutomationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationsListQuery, GetAutomationsListQueryVariables>(GetAutomationsListDocument, options);
        }
export type GetAutomationsListQueryHookResult = ReturnType<typeof useGetAutomationsListQuery>;
export type GetAutomationsListLazyQueryHookResult = ReturnType<typeof useGetAutomationsListLazyQuery>;
export type GetAutomationsListQueryResult = Apollo.QueryResult<GetAutomationsListQuery, GetAutomationsListQueryVariables>;
export function refetchGetAutomationsListQuery(variables?: GetAutomationsListQueryVariables) {
      return { query: GetAutomationsListDocument, variables: variables }
    }
export const GetAutomationsSelectionListDocument = gql`
    query getAutomationsSelectionList($clinicId: String!, $inEventType: [WorkflowEvent!], $triggerType: WorkflowEventTriggerType) {
  findManyWorkflowEventSetting(
    where: {clinic: {id: {equals: $clinicId}}, triggerType: {equals: $triggerType}, event: {not: {equals: ServiceReminder_FormSubmitted}, in: $inEventType}, surveySettingId: null, deletedAt: null}
    orderBy: {name: asc}
  ) {
    ...AutomationsSelectionListAutomation
  }
}
    ${AutomationsSelectionListAutomationFragmentDoc}`;

/**
 * __useGetAutomationsSelectionListQuery__
 *
 * To run a query within a React component, call `useGetAutomationsSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationsSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationsSelectionListQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      inEventType: // value for 'inEventType'
 *      triggerType: // value for 'triggerType'
 *   },
 * });
 */
export function useGetAutomationsSelectionListQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationsSelectionListQuery, GetAutomationsSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationsSelectionListQuery, GetAutomationsSelectionListQueryVariables>(GetAutomationsSelectionListDocument, options);
      }
export function useGetAutomationsSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationsSelectionListQuery, GetAutomationsSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationsSelectionListQuery, GetAutomationsSelectionListQueryVariables>(GetAutomationsSelectionListDocument, options);
        }
export type GetAutomationsSelectionListQueryHookResult = ReturnType<typeof useGetAutomationsSelectionListQuery>;
export type GetAutomationsSelectionListLazyQueryHookResult = ReturnType<typeof useGetAutomationsSelectionListLazyQuery>;
export type GetAutomationsSelectionListQueryResult = Apollo.QueryResult<GetAutomationsSelectionListQuery, GetAutomationsSelectionListQueryVariables>;
export function refetchGetAutomationsSelectionListQuery(variables?: GetAutomationsSelectionListQueryVariables) {
      return { query: GetAutomationsSelectionListDocument, variables: variables }
    }
export const GetGlobalAutomationsListDocument = gql`
    query getGlobalAutomationsList($inEventType: [WorkflowEvent!], $triggerType: WorkflowEventTriggerType) {
  findManyWorkflowEventSetting(
    where: {clinic: null, triggerType: {equals: $triggerType}, event: {not: {equals: ServiceReminder_FormSubmitted}, in: $inEventType}, surveySettingId: null, deletedAt: null}
    orderBy: {name: asc}
  ) {
    ...AutomationsListItem
  }
}
    ${AutomationsListItemFragmentDoc}`;

/**
 * __useGetGlobalAutomationsListQuery__
 *
 * To run a query within a React component, call `useGetGlobalAutomationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalAutomationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalAutomationsListQuery({
 *   variables: {
 *      inEventType: // value for 'inEventType'
 *      triggerType: // value for 'triggerType'
 *   },
 * });
 */
export function useGetGlobalAutomationsListQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalAutomationsListQuery, GetGlobalAutomationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalAutomationsListQuery, GetGlobalAutomationsListQueryVariables>(GetGlobalAutomationsListDocument, options);
      }
export function useGetGlobalAutomationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalAutomationsListQuery, GetGlobalAutomationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalAutomationsListQuery, GetGlobalAutomationsListQueryVariables>(GetGlobalAutomationsListDocument, options);
        }
export type GetGlobalAutomationsListQueryHookResult = ReturnType<typeof useGetGlobalAutomationsListQuery>;
export type GetGlobalAutomationsListLazyQueryHookResult = ReturnType<typeof useGetGlobalAutomationsListLazyQuery>;
export type GetGlobalAutomationsListQueryResult = Apollo.QueryResult<GetGlobalAutomationsListQuery, GetGlobalAutomationsListQueryVariables>;
export function refetchGetGlobalAutomationsListQuery(variables?: GetGlobalAutomationsListQueryVariables) {
      return { query: GetGlobalAutomationsListDocument, variables: variables }
    }
export const UpdateUserBoardFilterSettingsDocument = gql`
    mutation updateUserBoardFilterSettings($data: CustomUpsertOneUserBoardFilterDataInput!, $where: CustomUpsertOneUserBoardFilterWhereInput!) {
  customUpsertOneUserBoardFilter(data: $data, where: $where) {
    success
    error
  }
}
    `;
export type UpdateUserBoardFilterSettingsMutationFn = Apollo.MutationFunction<UpdateUserBoardFilterSettingsMutation, UpdateUserBoardFilterSettingsMutationVariables>;

/**
 * __useUpdateUserBoardFilterSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserBoardFilterSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBoardFilterSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBoardFilterSettingsMutation, { data, loading, error }] = useUpdateUserBoardFilterSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserBoardFilterSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBoardFilterSettingsMutation, UpdateUserBoardFilterSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBoardFilterSettingsMutation, UpdateUserBoardFilterSettingsMutationVariables>(UpdateUserBoardFilterSettingsDocument, options);
      }
export type UpdateUserBoardFilterSettingsMutationHookResult = ReturnType<typeof useUpdateUserBoardFilterSettingsMutation>;
export type UpdateUserBoardFilterSettingsMutationResult = Apollo.MutationResult<UpdateUserBoardFilterSettingsMutation>;
export type UpdateUserBoardFilterSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserBoardFilterSettingsMutation, UpdateUserBoardFilterSettingsMutationVariables>;
export const GetEmptyChannelStatusIdsDocument = gql`
    query getEmptyChannelStatusIds($where: GetChannelStatusIdsWithEntityCountOfZeroWhere!) {
  getChannelStatusIdsWithEntityCountOfZero(where: $where)
}
    `;

/**
 * __useGetEmptyChannelStatusIdsQuery__
 *
 * To run a query within a React component, call `useGetEmptyChannelStatusIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmptyChannelStatusIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmptyChannelStatusIdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmptyChannelStatusIdsQuery(baseOptions: Apollo.QueryHookOptions<GetEmptyChannelStatusIdsQuery, GetEmptyChannelStatusIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmptyChannelStatusIdsQuery, GetEmptyChannelStatusIdsQueryVariables>(GetEmptyChannelStatusIdsDocument, options);
      }
export function useGetEmptyChannelStatusIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmptyChannelStatusIdsQuery, GetEmptyChannelStatusIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmptyChannelStatusIdsQuery, GetEmptyChannelStatusIdsQueryVariables>(GetEmptyChannelStatusIdsDocument, options);
        }
export type GetEmptyChannelStatusIdsQueryHookResult = ReturnType<typeof useGetEmptyChannelStatusIdsQuery>;
export type GetEmptyChannelStatusIdsLazyQueryHookResult = ReturnType<typeof useGetEmptyChannelStatusIdsLazyQuery>;
export type GetEmptyChannelStatusIdsQueryResult = Apollo.QueryResult<GetEmptyChannelStatusIdsQuery, GetEmptyChannelStatusIdsQueryVariables>;
export function refetchGetEmptyChannelStatusIdsQuery(variables?: GetEmptyChannelStatusIdsQueryVariables) {
      return { query: GetEmptyChannelStatusIdsDocument, variables: variables }
    }
export const GetPaginatedAutomationRunsDocument = gql`
    query getPaginatedAutomationRuns($where: AutomationRunWhereInput!, $orderBy: [AutomationRunOrderByWithRelationAndSearchRelevanceInput!], $skip: Int, $take: Int) {
  count: findManyAutomationRunCount(where: $where)
  automationRuns: findManyAutomationRun(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    id
    status
    originAutomation {
      id
      name
    }
    appointment {
      id
      startAt
      durationInMinutes
      clinicPetParents {
        id
        ...BoardPetParent
      }
      clinicPet {
        id
        ...PetAvatar
      }
      channel {
        id
        lastMessage {
          id
          createdAt
        }
        updatedAt
        channelStatus {
          id
          ...ChannelListChannelStatus
        }
      }
    }
  }
}
    ${BoardPetParentFragmentDoc}
${PetAvatarFragmentDoc}
${ChannelListChannelStatusFragmentDoc}`;

/**
 * __useGetPaginatedAutomationRunsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedAutomationRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedAutomationRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedAutomationRunsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedAutomationRunsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedAutomationRunsQuery, GetPaginatedAutomationRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedAutomationRunsQuery, GetPaginatedAutomationRunsQueryVariables>(GetPaginatedAutomationRunsDocument, options);
      }
export function useGetPaginatedAutomationRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedAutomationRunsQuery, GetPaginatedAutomationRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedAutomationRunsQuery, GetPaginatedAutomationRunsQueryVariables>(GetPaginatedAutomationRunsDocument, options);
        }
export type GetPaginatedAutomationRunsQueryHookResult = ReturnType<typeof useGetPaginatedAutomationRunsQuery>;
export type GetPaginatedAutomationRunsLazyQueryHookResult = ReturnType<typeof useGetPaginatedAutomationRunsLazyQuery>;
export type GetPaginatedAutomationRunsQueryResult = Apollo.QueryResult<GetPaginatedAutomationRunsQuery, GetPaginatedAutomationRunsQueryVariables>;
export function refetchGetPaginatedAutomationRunsQuery(variables?: GetPaginatedAutomationRunsQueryVariables) {
      return { query: GetPaginatedAutomationRunsDocument, variables: variables }
    }
export const GetPaginatedChannelStatusesDocument = gql`
    query getPaginatedChannelStatuses($where: ChannelStatusWhereInput, $skip: Int, $take: Int) {
  count: findManyChannelStatusCount(where: $where)
  channelStatuses: findManyChannelStatus(
    where: $where
    take: $take
    skip: $skip
    orderBy: {order: asc}
  ) {
    id
    channelStatusAction
    color
    name
  }
}
    `;

/**
 * __useGetPaginatedChannelStatusesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedChannelStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedChannelStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedChannelStatusesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedChannelStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaginatedChannelStatusesQuery, GetPaginatedChannelStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedChannelStatusesQuery, GetPaginatedChannelStatusesQueryVariables>(GetPaginatedChannelStatusesDocument, options);
      }
export function useGetPaginatedChannelStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedChannelStatusesQuery, GetPaginatedChannelStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedChannelStatusesQuery, GetPaginatedChannelStatusesQueryVariables>(GetPaginatedChannelStatusesDocument, options);
        }
export type GetPaginatedChannelStatusesQueryHookResult = ReturnType<typeof useGetPaginatedChannelStatusesQuery>;
export type GetPaginatedChannelStatusesLazyQueryHookResult = ReturnType<typeof useGetPaginatedChannelStatusesLazyQuery>;
export type GetPaginatedChannelStatusesQueryResult = Apollo.QueryResult<GetPaginatedChannelStatusesQuery, GetPaginatedChannelStatusesQueryVariables>;
export function refetchGetPaginatedChannelStatusesQuery(variables?: GetPaginatedChannelStatusesQueryVariables) {
      return { query: GetPaginatedChannelStatusesDocument, variables: variables }
    }
export const GetPaginatedChannelsDocument = gql`
    query getPaginatedChannels($where: ChannelWhereInput!, $orderBy: [ChannelOrderByWithRelationAndSearchRelevanceInput!], $skip: Int, $take: Int, $countOnly: Boolean!) {
  count: findManyChannelCount(where: $where)
  channels: findManyChannel(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) @skip(if: $countOnly) {
    ...ChannelListChannel
  }
}
    ${ChannelListChannelFragmentDoc}`;

/**
 * __useGetPaginatedChannelsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedChannelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      countOnly: // value for 'countOnly'
 *   },
 * });
 */
export function useGetPaginatedChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedChannelsQuery, GetPaginatedChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedChannelsQuery, GetPaginatedChannelsQueryVariables>(GetPaginatedChannelsDocument, options);
      }
export function useGetPaginatedChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedChannelsQuery, GetPaginatedChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedChannelsQuery, GetPaginatedChannelsQueryVariables>(GetPaginatedChannelsDocument, options);
        }
export type GetPaginatedChannelsQueryHookResult = ReturnType<typeof useGetPaginatedChannelsQuery>;
export type GetPaginatedChannelsLazyQueryHookResult = ReturnType<typeof useGetPaginatedChannelsLazyQuery>;
export type GetPaginatedChannelsQueryResult = Apollo.QueryResult<GetPaginatedChannelsQuery, GetPaginatedChannelsQueryVariables>;
export function refetchGetPaginatedChannelsQuery(variables?: GetPaginatedChannelsQueryVariables) {
      return { query: GetPaginatedChannelsDocument, variables: variables }
    }
export const GetUserBoardFilterSettingsDocument = gql`
    query getUserBoardFilterSettings($where: CustomFindUniqueUserBoardFilterWhereInput!) {
  customFindUniqueUserBoardFilter(where: $where) {
    currentView
    collapsedColumnsMap
    selectedChannelStatusIdsMap
    shouldHideEmptyColumns
    shouldCollapseNoStatusColumn
    dateRangeOption
    specificDateRange {
      gte
      lte
    }
  }
}
    `;

/**
 * __useGetUserBoardFilterSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserBoardFilterSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBoardFilterSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBoardFilterSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserBoardFilterSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserBoardFilterSettingsQuery, GetUserBoardFilterSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBoardFilterSettingsQuery, GetUserBoardFilterSettingsQueryVariables>(GetUserBoardFilterSettingsDocument, options);
      }
export function useGetUserBoardFilterSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBoardFilterSettingsQuery, GetUserBoardFilterSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBoardFilterSettingsQuery, GetUserBoardFilterSettingsQueryVariables>(GetUserBoardFilterSettingsDocument, options);
        }
export type GetUserBoardFilterSettingsQueryHookResult = ReturnType<typeof useGetUserBoardFilterSettingsQuery>;
export type GetUserBoardFilterSettingsLazyQueryHookResult = ReturnType<typeof useGetUserBoardFilterSettingsLazyQuery>;
export type GetUserBoardFilterSettingsQueryResult = Apollo.QueryResult<GetUserBoardFilterSettingsQuery, GetUserBoardFilterSettingsQueryVariables>;
export function refetchGetUserBoardFilterSettingsQuery(variables?: GetUserBoardFilterSettingsQueryVariables) {
      return { query: GetUserBoardFilterSettingsDocument, variables: variables }
    }
export const CreateChannelFilterSelectionDocument = gql`
    mutation createChannelFilterSelection($data: CreateChannelFilterSelectionInput!) {
  createChannelFilterSelection(data: $data) {
    ...ChannelFilterSelection
  }
}
    ${ChannelFilterSelectionFragmentDoc}`;
export type CreateChannelFilterSelectionMutationFn = Apollo.MutationFunction<CreateChannelFilterSelectionMutation, CreateChannelFilterSelectionMutationVariables>;

/**
 * __useCreateChannelFilterSelectionMutation__
 *
 * To run a mutation, you first call `useCreateChannelFilterSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelFilterSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelFilterSelectionMutation, { data, loading, error }] = useCreateChannelFilterSelectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChannelFilterSelectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelFilterSelectionMutation, CreateChannelFilterSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelFilterSelectionMutation, CreateChannelFilterSelectionMutationVariables>(CreateChannelFilterSelectionDocument, options);
      }
export type CreateChannelFilterSelectionMutationHookResult = ReturnType<typeof useCreateChannelFilterSelectionMutation>;
export type CreateChannelFilterSelectionMutationResult = Apollo.MutationResult<CreateChannelFilterSelectionMutation>;
export type CreateChannelFilterSelectionMutationOptions = Apollo.BaseMutationOptions<CreateChannelFilterSelectionMutation, CreateChannelFilterSelectionMutationVariables>;
export const DeleteChannelFilterSelectionDocument = gql`
    mutation deleteChannelFilterSelection($channelFilterSelectionId: String!) {
  deleteChannelFilterSelection(id: $channelFilterSelectionId) {
    ...ChannelFilterSelection
  }
}
    ${ChannelFilterSelectionFragmentDoc}`;
export type DeleteChannelFilterSelectionMutationFn = Apollo.MutationFunction<DeleteChannelFilterSelectionMutation, DeleteChannelFilterSelectionMutationVariables>;

/**
 * __useDeleteChannelFilterSelectionMutation__
 *
 * To run a mutation, you first call `useDeleteChannelFilterSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelFilterSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelFilterSelectionMutation, { data, loading, error }] = useDeleteChannelFilterSelectionMutation({
 *   variables: {
 *      channelFilterSelectionId: // value for 'channelFilterSelectionId'
 *   },
 * });
 */
export function useDeleteChannelFilterSelectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelFilterSelectionMutation, DeleteChannelFilterSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelFilterSelectionMutation, DeleteChannelFilterSelectionMutationVariables>(DeleteChannelFilterSelectionDocument, options);
      }
export type DeleteChannelFilterSelectionMutationHookResult = ReturnType<typeof useDeleteChannelFilterSelectionMutation>;
export type DeleteChannelFilterSelectionMutationResult = Apollo.MutationResult<DeleteChannelFilterSelectionMutation>;
export type DeleteChannelFilterSelectionMutationOptions = Apollo.BaseMutationOptions<DeleteChannelFilterSelectionMutation, DeleteChannelFilterSelectionMutationVariables>;
export const UpdateChannelFilterSelectionDocument = gql`
    mutation updateChannelFilterSelection($data: UpdateChannelFilterSelectionInput!) {
  updateChannelFilterSelection(data: $data) {
    ...ChannelFilterSelection
  }
}
    ${ChannelFilterSelectionFragmentDoc}`;
export type UpdateChannelFilterSelectionMutationFn = Apollo.MutationFunction<UpdateChannelFilterSelectionMutation, UpdateChannelFilterSelectionMutationVariables>;

/**
 * __useUpdateChannelFilterSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateChannelFilterSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelFilterSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelFilterSelectionMutation, { data, loading, error }] = useUpdateChannelFilterSelectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelFilterSelectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelFilterSelectionMutation, UpdateChannelFilterSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelFilterSelectionMutation, UpdateChannelFilterSelectionMutationVariables>(UpdateChannelFilterSelectionDocument, options);
      }
export type UpdateChannelFilterSelectionMutationHookResult = ReturnType<typeof useUpdateChannelFilterSelectionMutation>;
export type UpdateChannelFilterSelectionMutationResult = Apollo.MutationResult<UpdateChannelFilterSelectionMutation>;
export type UpdateChannelFilterSelectionMutationOptions = Apollo.BaseMutationOptions<UpdateChannelFilterSelectionMutation, UpdateChannelFilterSelectionMutationVariables>;
export const GetChannelFilterSelectionsDocument = gql`
    query getChannelFilterSelections {
  savedChannelFilterSelections(orderBy: {id: asc}) {
    ...ChannelFilterSelection
  }
}
    ${ChannelFilterSelectionFragmentDoc}`;

/**
 * __useGetChannelFilterSelectionsQuery__
 *
 * To run a query within a React component, call `useGetChannelFilterSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelFilterSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelFilterSelectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChannelFilterSelectionsQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelFilterSelectionsQuery, GetChannelFilterSelectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelFilterSelectionsQuery, GetChannelFilterSelectionsQueryVariables>(GetChannelFilterSelectionsDocument, options);
      }
export function useGetChannelFilterSelectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelFilterSelectionsQuery, GetChannelFilterSelectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelFilterSelectionsQuery, GetChannelFilterSelectionsQueryVariables>(GetChannelFilterSelectionsDocument, options);
        }
export type GetChannelFilterSelectionsQueryHookResult = ReturnType<typeof useGetChannelFilterSelectionsQuery>;
export type GetChannelFilterSelectionsLazyQueryHookResult = ReturnType<typeof useGetChannelFilterSelectionsLazyQuery>;
export type GetChannelFilterSelectionsQueryResult = Apollo.QueryResult<GetChannelFilterSelectionsQuery, GetChannelFilterSelectionsQueryVariables>;
export function refetchGetChannelFilterSelectionsQuery(variables?: GetChannelFilterSelectionsQueryVariables) {
      return { query: GetChannelFilterSelectionsDocument, variables: variables }
    }
export const DeleteChannelMessageDocument = gql`
    mutation deleteChannelMessage($where: ChannelMessageWhereUniqueInput!) {
  deleteOneChannelMessage(where: $where) {
    id
  }
}
    `;
export type DeleteChannelMessageMutationFn = Apollo.MutationFunction<DeleteChannelMessageMutation, DeleteChannelMessageMutationVariables>;

/**
 * __useDeleteChannelMessageMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMessageMutation, { data, loading, error }] = useDeleteChannelMessageMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteChannelMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelMessageMutation, DeleteChannelMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelMessageMutation, DeleteChannelMessageMutationVariables>(DeleteChannelMessageDocument, options);
      }
export type DeleteChannelMessageMutationHookResult = ReturnType<typeof useDeleteChannelMessageMutation>;
export type DeleteChannelMessageMutationResult = Apollo.MutationResult<DeleteChannelMessageMutation>;
export type DeleteChannelMessageMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMessageMutation, DeleteChannelMessageMutationVariables>;
export const GetServiceReminderNotificationDocument = gql`
    query getServiceReminderNotification($serviceReminderNotificationId: String!) {
  findManyServiceReminderNotification(
    where: {id: {equals: $serviceReminderNotificationId}}
  ) {
    id
    queueAt
    hasResponded
    bundledNotifications {
      id
      hasResponded
      serviceReminder {
        id
        dueDate
        clinicPet {
          ...PetAvatar
        }
        service {
          id
          name
        }
      }
    }
    serviceReminder {
      id
      dueDate
      service {
        id
        name
      }
      clinicPet {
        ...PetAvatar
      }
    }
  }
}
    ${PetAvatarFragmentDoc}`;

/**
 * __useGetServiceReminderNotificationQuery__
 *
 * To run a query within a React component, call `useGetServiceReminderNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceReminderNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceReminderNotificationQuery({
 *   variables: {
 *      serviceReminderNotificationId: // value for 'serviceReminderNotificationId'
 *   },
 * });
 */
export function useGetServiceReminderNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetServiceReminderNotificationQuery, GetServiceReminderNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceReminderNotificationQuery, GetServiceReminderNotificationQueryVariables>(GetServiceReminderNotificationDocument, options);
      }
export function useGetServiceReminderNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceReminderNotificationQuery, GetServiceReminderNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceReminderNotificationQuery, GetServiceReminderNotificationQueryVariables>(GetServiceReminderNotificationDocument, options);
        }
export type GetServiceReminderNotificationQueryHookResult = ReturnType<typeof useGetServiceReminderNotificationQuery>;
export type GetServiceReminderNotificationLazyQueryHookResult = ReturnType<typeof useGetServiceReminderNotificationLazyQuery>;
export type GetServiceReminderNotificationQueryResult = Apollo.QueryResult<GetServiceReminderNotificationQuery, GetServiceReminderNotificationQueryVariables>;
export function refetchGetServiceReminderNotificationQuery(variables?: GetServiceReminderNotificationQueryVariables) {
      return { query: GetServiceReminderNotificationDocument, variables: variables }
    }
export const GetChatEntitiesDocument = gql`
    query getChatEntities($appointmentWhereInput: AppointmentWhereInput, $formSubmissionsWhereInput: FormSubmissionWhereInput) {
  appointments: findManyAppointment(where: $appointmentWhereInput) {
    ...ChatEntityAppointment
  }
  formSubmissions: findManyFormSubmission(where: $formSubmissionsWhereInput) {
    ...ChatEntityFormSubmission
  }
}
    ${ChatEntityAppointmentFragmentDoc}
${ChatEntityFormSubmissionFragmentDoc}`;

/**
 * __useGetChatEntitiesQuery__
 *
 * To run a query within a React component, call `useGetChatEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatEntitiesQuery({
 *   variables: {
 *      appointmentWhereInput: // value for 'appointmentWhereInput'
 *      formSubmissionsWhereInput: // value for 'formSubmissionsWhereInput'
 *   },
 * });
 */
export function useGetChatEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetChatEntitiesQuery, GetChatEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatEntitiesQuery, GetChatEntitiesQueryVariables>(GetChatEntitiesDocument, options);
      }
export function useGetChatEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatEntitiesQuery, GetChatEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatEntitiesQuery, GetChatEntitiesQueryVariables>(GetChatEntitiesDocument, options);
        }
export type GetChatEntitiesQueryHookResult = ReturnType<typeof useGetChatEntitiesQuery>;
export type GetChatEntitiesLazyQueryHookResult = ReturnType<typeof useGetChatEntitiesLazyQuery>;
export type GetChatEntitiesQueryResult = Apollo.QueryResult<GetChatEntitiesQuery, GetChatEntitiesQueryVariables>;
export function refetchGetChatEntitiesQuery(variables?: GetChatEntitiesQueryVariables) {
      return { query: GetChatEntitiesDocument, variables: variables }
    }
export const GetArchivedOverlayChannelStatusesDocument = gql`
    query getArchivedOverlayChannelStatuses($where: ChannelStatusWhereInput) {
  findManyChannelStatus(where: $where, orderBy: {order: asc}) {
    id
    channelStatusAction
    order
  }
}
    `;

/**
 * __useGetArchivedOverlayChannelStatusesQuery__
 *
 * To run a query within a React component, call `useGetArchivedOverlayChannelStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedOverlayChannelStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedOverlayChannelStatusesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetArchivedOverlayChannelStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetArchivedOverlayChannelStatusesQuery, GetArchivedOverlayChannelStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchivedOverlayChannelStatusesQuery, GetArchivedOverlayChannelStatusesQueryVariables>(GetArchivedOverlayChannelStatusesDocument, options);
      }
export function useGetArchivedOverlayChannelStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedOverlayChannelStatusesQuery, GetArchivedOverlayChannelStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchivedOverlayChannelStatusesQuery, GetArchivedOverlayChannelStatusesQueryVariables>(GetArchivedOverlayChannelStatusesDocument, options);
        }
export type GetArchivedOverlayChannelStatusesQueryHookResult = ReturnType<typeof useGetArchivedOverlayChannelStatusesQuery>;
export type GetArchivedOverlayChannelStatusesLazyQueryHookResult = ReturnType<typeof useGetArchivedOverlayChannelStatusesLazyQuery>;
export type GetArchivedOverlayChannelStatusesQueryResult = Apollo.QueryResult<GetArchivedOverlayChannelStatusesQuery, GetArchivedOverlayChannelStatusesQueryVariables>;
export function refetchGetArchivedOverlayChannelStatusesQuery(variables?: GetArchivedOverlayChannelStatusesQueryVariables) {
      return { query: GetArchivedOverlayChannelStatusesDocument, variables: variables }
    }
export const GetPetParentActiveChannelMembersDocument = gql`
    query getPetParentActiveChannelMembers($clinicPetParentIds: [String!]!) {
  findManyChannelMember(
    where: {channel: {channelStatus: {channelStatusAction: {equals: Active}}}, removedAt: {equals: null}, clinicPetParentId: {in: $clinicPetParentIds}}
  ) {
    id
    channelId
  }
}
    `;

/**
 * __useGetPetParentActiveChannelMembersQuery__
 *
 * To run a query within a React component, call `useGetPetParentActiveChannelMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetParentActiveChannelMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetParentActiveChannelMembersQuery({
 *   variables: {
 *      clinicPetParentIds: // value for 'clinicPetParentIds'
 *   },
 * });
 */
export function useGetPetParentActiveChannelMembersQuery(baseOptions: Apollo.QueryHookOptions<GetPetParentActiveChannelMembersQuery, GetPetParentActiveChannelMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetParentActiveChannelMembersQuery, GetPetParentActiveChannelMembersQueryVariables>(GetPetParentActiveChannelMembersDocument, options);
      }
export function useGetPetParentActiveChannelMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetParentActiveChannelMembersQuery, GetPetParentActiveChannelMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetParentActiveChannelMembersQuery, GetPetParentActiveChannelMembersQueryVariables>(GetPetParentActiveChannelMembersDocument, options);
        }
export type GetPetParentActiveChannelMembersQueryHookResult = ReturnType<typeof useGetPetParentActiveChannelMembersQuery>;
export type GetPetParentActiveChannelMembersLazyQueryHookResult = ReturnType<typeof useGetPetParentActiveChannelMembersLazyQuery>;
export type GetPetParentActiveChannelMembersQueryResult = Apollo.QueryResult<GetPetParentActiveChannelMembersQuery, GetPetParentActiveChannelMembersQueryVariables>;
export function refetchGetPetParentActiveChannelMembersQuery(variables?: GetPetParentActiveChannelMembersQueryVariables) {
      return { query: GetPetParentActiveChannelMembersDocument, variables: variables }
    }
export const UpdateArchivedOverlayChannelStatusDocument = gql`
    mutation updateArchivedOverlayChannelStatus($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
  updateOneChannel(where: $where, data: $data) {
    id
    channelStatus {
      id
    }
  }
}
    `;
export type UpdateArchivedOverlayChannelStatusMutationFn = Apollo.MutationFunction<UpdateArchivedOverlayChannelStatusMutation, UpdateArchivedOverlayChannelStatusMutationVariables>;

/**
 * __useUpdateArchivedOverlayChannelStatusMutation__
 *
 * To run a mutation, you first call `useUpdateArchivedOverlayChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArchivedOverlayChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArchivedOverlayChannelStatusMutation, { data, loading, error }] = useUpdateArchivedOverlayChannelStatusMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArchivedOverlayChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArchivedOverlayChannelStatusMutation, UpdateArchivedOverlayChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArchivedOverlayChannelStatusMutation, UpdateArchivedOverlayChannelStatusMutationVariables>(UpdateArchivedOverlayChannelStatusDocument, options);
      }
export type UpdateArchivedOverlayChannelStatusMutationHookResult = ReturnType<typeof useUpdateArchivedOverlayChannelStatusMutation>;
export type UpdateArchivedOverlayChannelStatusMutationResult = Apollo.MutationResult<UpdateArchivedOverlayChannelStatusMutation>;
export type UpdateArchivedOverlayChannelStatusMutationOptions = Apollo.BaseMutationOptions<UpdateArchivedOverlayChannelStatusMutation, UpdateArchivedOverlayChannelStatusMutationVariables>;
export const FindDocumentationDocument = gql`
    query findDocumentation($where: DocumentationWhereInput) {
  findManyDocumentation(where: $where) {
    ...Documentation
  }
}
    ${DocumentationFragmentDoc}`;

/**
 * __useFindDocumentationQuery__
 *
 * To run a query within a React component, call `useFindDocumentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDocumentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDocumentationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindDocumentationQuery(baseOptions?: Apollo.QueryHookOptions<FindDocumentationQuery, FindDocumentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDocumentationQuery, FindDocumentationQueryVariables>(FindDocumentationDocument, options);
      }
export function useFindDocumentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDocumentationQuery, FindDocumentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDocumentationQuery, FindDocumentationQueryVariables>(FindDocumentationDocument, options);
        }
export type FindDocumentationQueryHookResult = ReturnType<typeof useFindDocumentationQuery>;
export type FindDocumentationLazyQueryHookResult = ReturnType<typeof useFindDocumentationLazyQuery>;
export type FindDocumentationQueryResult = Apollo.QueryResult<FindDocumentationQuery, FindDocumentationQueryVariables>;
export function refetchFindDocumentationQuery(variables?: FindDocumentationQueryVariables) {
      return { query: FindDocumentationDocument, variables: variables }
    }
export const GetTeamMembersMentionListDocument = gql`
    query getTeamMembersMentionList($where: UserWhereInput) {
  findManyUser(where: $where, orderBy: {firstName: asc}) {
    ...TeamMemberMentionsList
  }
}
    ${TeamMemberMentionsListFragmentDoc}`;

/**
 * __useGetTeamMembersMentionListQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersMentionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersMentionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersMentionListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTeamMembersMentionListQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamMembersMentionListQuery, GetTeamMembersMentionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamMembersMentionListQuery, GetTeamMembersMentionListQueryVariables>(GetTeamMembersMentionListDocument, options);
      }
export function useGetTeamMembersMentionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersMentionListQuery, GetTeamMembersMentionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamMembersMentionListQuery, GetTeamMembersMentionListQueryVariables>(GetTeamMembersMentionListDocument, options);
        }
export type GetTeamMembersMentionListQueryHookResult = ReturnType<typeof useGetTeamMembersMentionListQuery>;
export type GetTeamMembersMentionListLazyQueryHookResult = ReturnType<typeof useGetTeamMembersMentionListLazyQuery>;
export type GetTeamMembersMentionListQueryResult = Apollo.QueryResult<GetTeamMembersMentionListQuery, GetTeamMembersMentionListQueryVariables>;
export function refetchGetTeamMembersMentionListQuery(variables?: GetTeamMembersMentionListQueryVariables) {
      return { query: GetTeamMembersMentionListDocument, variables: variables }
    }
export const CreateChannelMessageAutoResponseDocument = gql`
    mutation createChannelMessageAutoResponse($data: ChannelMessageAutoResponseCreateInput!) {
  createOneChannelMessageAutoResponse(data: $data) {
    id
  }
}
    `;
export type CreateChannelMessageAutoResponseMutationFn = Apollo.MutationFunction<CreateChannelMessageAutoResponseMutation, CreateChannelMessageAutoResponseMutationVariables>;

/**
 * __useCreateChannelMessageAutoResponseMutation__
 *
 * To run a mutation, you first call `useCreateChannelMessageAutoResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMessageAutoResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMessageAutoResponseMutation, { data, loading, error }] = useCreateChannelMessageAutoResponseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChannelMessageAutoResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMessageAutoResponseMutation, CreateChannelMessageAutoResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMessageAutoResponseMutation, CreateChannelMessageAutoResponseMutationVariables>(CreateChannelMessageAutoResponseDocument, options);
      }
export type CreateChannelMessageAutoResponseMutationHookResult = ReturnType<typeof useCreateChannelMessageAutoResponseMutation>;
export type CreateChannelMessageAutoResponseMutationResult = Apollo.MutationResult<CreateChannelMessageAutoResponseMutation>;
export type CreateChannelMessageAutoResponseMutationOptions = Apollo.BaseMutationOptions<CreateChannelMessageAutoResponseMutation, CreateChannelMessageAutoResponseMutationVariables>;
export const GetConversationsQuickReplyDocument = gql`
    query getConversationsQuickReply($where: ChannelSuggestedMessageWhereInput!, $alternatePrompt: String, $maxTokensToSample: Int) {
  aiChannelSuggestedMessage(
    where: $where
    alternatePrompt: $alternatePrompt
    maxTokensToSample: $maxTokensToSample
  ) {
    response
    parsedJson
  }
}
    `;

/**
 * __useGetConversationsQuickReplyQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuickReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuickReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuickReplyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      alternatePrompt: // value for 'alternatePrompt'
 *      maxTokensToSample: // value for 'maxTokensToSample'
 *   },
 * });
 */
export function useGetConversationsQuickReplyQuery(baseOptions: Apollo.QueryHookOptions<GetConversationsQuickReplyQuery, GetConversationsQuickReplyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationsQuickReplyQuery, GetConversationsQuickReplyQueryVariables>(GetConversationsQuickReplyDocument, options);
      }
export function useGetConversationsQuickReplyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsQuickReplyQuery, GetConversationsQuickReplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationsQuickReplyQuery, GetConversationsQuickReplyQueryVariables>(GetConversationsQuickReplyDocument, options);
        }
export type GetConversationsQuickReplyQueryHookResult = ReturnType<typeof useGetConversationsQuickReplyQuery>;
export type GetConversationsQuickReplyLazyQueryHookResult = ReturnType<typeof useGetConversationsQuickReplyLazyQuery>;
export type GetConversationsQuickReplyQueryResult = Apollo.QueryResult<GetConversationsQuickReplyQuery, GetConversationsQuickReplyQueryVariables>;
export function refetchGetConversationsQuickReplyQuery(variables?: GetConversationsQuickReplyQueryVariables) {
      return { query: GetConversationsQuickReplyDocument, variables: variables }
    }
export const UpdateChannelMessageAutoResponseDocument = gql`
    mutation updateChannelMessageAutoResponse($where: ChannelMessageAutoResponseWhereUniqueInput!, $data: ChannelMessageAutoResponseUpdateInput!) {
  updateOneChannelMessageAutoResponse(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateChannelMessageAutoResponseMutationFn = Apollo.MutationFunction<UpdateChannelMessageAutoResponseMutation, UpdateChannelMessageAutoResponseMutationVariables>;

/**
 * __useUpdateChannelMessageAutoResponseMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMessageAutoResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMessageAutoResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMessageAutoResponseMutation, { data, loading, error }] = useUpdateChannelMessageAutoResponseMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelMessageAutoResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMessageAutoResponseMutation, UpdateChannelMessageAutoResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMessageAutoResponseMutation, UpdateChannelMessageAutoResponseMutationVariables>(UpdateChannelMessageAutoResponseDocument, options);
      }
export type UpdateChannelMessageAutoResponseMutationHookResult = ReturnType<typeof useUpdateChannelMessageAutoResponseMutation>;
export type UpdateChannelMessageAutoResponseMutationResult = Apollo.MutationResult<UpdateChannelMessageAutoResponseMutation>;
export type UpdateChannelMessageAutoResponseMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMessageAutoResponseMutation, UpdateChannelMessageAutoResponseMutationVariables>;
export const ChannelExportToPimsDocument = gql`
    mutation channelExportToPims($where: ChannelExportWhereInput!) {
  channelExportToPims(where: $where) {
    success
    payload
  }
}
    `;
export type ChannelExportToPimsMutationFn = Apollo.MutationFunction<ChannelExportToPimsMutation, ChannelExportToPimsMutationVariables>;

/**
 * __useChannelExportToPimsMutation__
 *
 * To run a mutation, you first call `useChannelExportToPimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChannelExportToPimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [channelExportToPimsMutation, { data, loading, error }] = useChannelExportToPimsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChannelExportToPimsMutation(baseOptions?: Apollo.MutationHookOptions<ChannelExportToPimsMutation, ChannelExportToPimsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChannelExportToPimsMutation, ChannelExportToPimsMutationVariables>(ChannelExportToPimsDocument, options);
      }
export type ChannelExportToPimsMutationHookResult = ReturnType<typeof useChannelExportToPimsMutation>;
export type ChannelExportToPimsMutationResult = Apollo.MutationResult<ChannelExportToPimsMutation>;
export type ChannelExportToPimsMutationOptions = Apollo.BaseMutationOptions<ChannelExportToPimsMutation, ChannelExportToPimsMutationVariables>;
export const ClearUnreadMessagesDocument = gql`
    mutation clearUnreadMessages($userId: String!, $clinicId: String) {
  clearUnreadMessages: clearUnreadMessages2(
    data: {userId: $userId, clinicId: $clinicId}
  ) {
    success
    payload
  }
}
    `;
export type ClearUnreadMessagesMutationFn = Apollo.MutationFunction<ClearUnreadMessagesMutation, ClearUnreadMessagesMutationVariables>;

/**
 * __useClearUnreadMessagesMutation__
 *
 * To run a mutation, you first call `useClearUnreadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUnreadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUnreadMessagesMutation, { data, loading, error }] = useClearUnreadMessagesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useClearUnreadMessagesMutation(baseOptions?: Apollo.MutationHookOptions<ClearUnreadMessagesMutation, ClearUnreadMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearUnreadMessagesMutation, ClearUnreadMessagesMutationVariables>(ClearUnreadMessagesDocument, options);
      }
export type ClearUnreadMessagesMutationHookResult = ReturnType<typeof useClearUnreadMessagesMutation>;
export type ClearUnreadMessagesMutationResult = Apollo.MutationResult<ClearUnreadMessagesMutation>;
export type ClearUnreadMessagesMutationOptions = Apollo.BaseMutationOptions<ClearUnreadMessagesMutation, ClearUnreadMessagesMutationVariables>;
export const ConfirmAppointmentDocument = gql`
    mutation confirmAppointment($where: AppointmentWhereUniqueInput) {
  confirmAppointment: confirmAppointment2(where: $where) {
    id
    status
  }
}
    `;
export type ConfirmAppointmentMutationFn = Apollo.MutationFunction<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>;

/**
 * __useConfirmAppointmentMutation__
 *
 * To run a mutation, you first call `useConfirmAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAppointmentMutation, { data, loading, error }] = useConfirmAppointmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConfirmAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>(ConfirmAppointmentDocument, options);
      }
export type ConfirmAppointmentMutationHookResult = ReturnType<typeof useConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationResult = Apollo.MutationResult<ConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationOptions = Apollo.BaseMutationOptions<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>;
export const CreateChannelMessageDocument = gql`
    mutation createChannelMessage($data: ChannelMessageCreateInput!) {
  createChannelMessage2(data: $data) {
    id
    createdAt
    updatedAt
    deletedAt
    index
    body
    messageType
    forceReadReceipt
    attachments {
      id
      attachmentType
      entityType
      entityId
      filename
      url
    }
    author {
      id
      user {
        id
        firstName
        lastName
        profilePic
      }
      clinicPetParent {
        id
        firstName
        lastName
      }
    }
    channel {
      id
    }
  }
}
    `;
export type CreateChannelMessageMutationFn = Apollo.MutationFunction<CreateChannelMessageMutation, CreateChannelMessageMutationVariables>;

/**
 * __useCreateChannelMessageMutation__
 *
 * To run a mutation, you first call `useCreateChannelMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMessageMutation, { data, loading, error }] = useCreateChannelMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChannelMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMessageMutation, CreateChannelMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMessageMutation, CreateChannelMessageMutationVariables>(CreateChannelMessageDocument, options);
      }
export type CreateChannelMessageMutationHookResult = ReturnType<typeof useCreateChannelMessageMutation>;
export type CreateChannelMessageMutationResult = Apollo.MutationResult<CreateChannelMessageMutation>;
export type CreateChannelMessageMutationOptions = Apollo.BaseMutationOptions<CreateChannelMessageMutation, CreateChannelMessageMutationVariables>;
export const CreateChannelPinDocument = gql`
    mutation createChannelPin($userId: String!, $channelId: String!) {
  createOneChannelPin(
    data: {user: {connect: {id: $userId}}, channel: {connect: {id: $channelId}}}
  ) {
    ...ChannelPin
  }
}
    ${ChannelPinFragmentDoc}`;
export type CreateChannelPinMutationFn = Apollo.MutationFunction<CreateChannelPinMutation, CreateChannelPinMutationVariables>;

/**
 * __useCreateChannelPinMutation__
 *
 * To run a mutation, you first call `useCreateChannelPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelPinMutation, { data, loading, error }] = useCreateChannelPinMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useCreateChannelPinMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelPinMutation, CreateChannelPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelPinMutation, CreateChannelPinMutationVariables>(CreateChannelPinDocument, options);
      }
export type CreateChannelPinMutationHookResult = ReturnType<typeof useCreateChannelPinMutation>;
export type CreateChannelPinMutationResult = Apollo.MutationResult<CreateChannelPinMutation>;
export type CreateChannelPinMutationOptions = Apollo.BaseMutationOptions<CreateChannelPinMutation, CreateChannelPinMutationVariables>;
export const DeleteChannelPinByUserAndChannelDocument = gql`
    mutation deleteChannelPinByUserAndChannel($userId: String!, $channelId: String!) {
  deleteManyChannelPin(
    where: {AND: [{userId: {equals: $userId}}, {channelId: {equals: $channelId}}]}
  ) {
    count
  }
}
    `;
export type DeleteChannelPinByUserAndChannelMutationFn = Apollo.MutationFunction<DeleteChannelPinByUserAndChannelMutation, DeleteChannelPinByUserAndChannelMutationVariables>;

/**
 * __useDeleteChannelPinByUserAndChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelPinByUserAndChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelPinByUserAndChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelPinByUserAndChannelMutation, { data, loading, error }] = useDeleteChannelPinByUserAndChannelMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteChannelPinByUserAndChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelPinByUserAndChannelMutation, DeleteChannelPinByUserAndChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelPinByUserAndChannelMutation, DeleteChannelPinByUserAndChannelMutationVariables>(DeleteChannelPinByUserAndChannelDocument, options);
      }
export type DeleteChannelPinByUserAndChannelMutationHookResult = ReturnType<typeof useDeleteChannelPinByUserAndChannelMutation>;
export type DeleteChannelPinByUserAndChannelMutationResult = Apollo.MutationResult<DeleteChannelPinByUserAndChannelMutation>;
export type DeleteChannelPinByUserAndChannelMutationOptions = Apollo.BaseMutationOptions<DeleteChannelPinByUserAndChannelMutation, DeleteChannelPinByUserAndChannelMutationVariables>;
export const GetPaymentReceiptPdfDocument = gql`
    query getPaymentReceiptPdf($data: ExportItemizedInvoiceToReceiptPDFInput!) {
  exportItemizedInvoiceAsReceiptPDF(data: $data) {
    success
    base64PDF
  }
}
    `;

/**
 * __useGetPaymentReceiptPdfQuery__
 *
 * To run a query within a React component, call `useGetPaymentReceiptPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReceiptPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReceiptPdfQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetPaymentReceiptPdfQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentReceiptPdfQuery, GetPaymentReceiptPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentReceiptPdfQuery, GetPaymentReceiptPdfQueryVariables>(GetPaymentReceiptPdfDocument, options);
      }
export function useGetPaymentReceiptPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentReceiptPdfQuery, GetPaymentReceiptPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentReceiptPdfQuery, GetPaymentReceiptPdfQueryVariables>(GetPaymentReceiptPdfDocument, options);
        }
export type GetPaymentReceiptPdfQueryHookResult = ReturnType<typeof useGetPaymentReceiptPdfQuery>;
export type GetPaymentReceiptPdfLazyQueryHookResult = ReturnType<typeof useGetPaymentReceiptPdfLazyQuery>;
export type GetPaymentReceiptPdfQueryResult = Apollo.QueryResult<GetPaymentReceiptPdfQuery, GetPaymentReceiptPdfQueryVariables>;
export function refetchGetPaymentReceiptPdfQuery(variables?: GetPaymentReceiptPdfQueryVariables) {
      return { query: GetPaymentReceiptPdfDocument, variables: variables }
    }
export const UpdateCareBenefitUsageDocument = gql`
    mutation updateCareBenefitUsage($where: CareBenefitUsageWhereUniqueInput!, $data: CareBenefitUsageUpdateInput!) {
  updateOneCareBenefitUsage(where: $where, data: $data) {
    id
    quantityUsed
    exhaustsBenefit
  }
}
    `;
export type UpdateCareBenefitUsageMutationFn = Apollo.MutationFunction<UpdateCareBenefitUsageMutation, UpdateCareBenefitUsageMutationVariables>;

/**
 * __useUpdateCareBenefitUsageMutation__
 *
 * To run a mutation, you first call `useUpdateCareBenefitUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareBenefitUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareBenefitUsageMutation, { data, loading, error }] = useUpdateCareBenefitUsageMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCareBenefitUsageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCareBenefitUsageMutation, UpdateCareBenefitUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCareBenefitUsageMutation, UpdateCareBenefitUsageMutationVariables>(UpdateCareBenefitUsageDocument, options);
      }
export type UpdateCareBenefitUsageMutationHookResult = ReturnType<typeof useUpdateCareBenefitUsageMutation>;
export type UpdateCareBenefitUsageMutationResult = Apollo.MutationResult<UpdateCareBenefitUsageMutation>;
export type UpdateCareBenefitUsageMutationOptions = Apollo.BaseMutationOptions<UpdateCareBenefitUsageMutation, UpdateCareBenefitUsageMutationVariables>;
export const UpdateOneCareBenefitUsageToInvoiceLineItemDocument = gql`
    mutation updateOneCareBenefitUsageToInvoiceLineItem($where: CareBenefitUsageToInvoiceLineItemWhereUniqueInput!, $data: CareBenefitUsageToInvoiceLineItemUpdateInput!) {
  updateOneCareBenefitUsageToInvoiceLineItem(where: $where, data: $data) {
    id
    adjustedAmount
  }
}
    `;
export type UpdateOneCareBenefitUsageToInvoiceLineItemMutationFn = Apollo.MutationFunction<UpdateOneCareBenefitUsageToInvoiceLineItemMutation, UpdateOneCareBenefitUsageToInvoiceLineItemMutationVariables>;

/**
 * __useUpdateOneCareBenefitUsageToInvoiceLineItemMutation__
 *
 * To run a mutation, you first call `useUpdateOneCareBenefitUsageToInvoiceLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCareBenefitUsageToInvoiceLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneCareBenefitUsageToInvoiceLineItemMutation, { data, loading, error }] = useUpdateOneCareBenefitUsageToInvoiceLineItemMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneCareBenefitUsageToInvoiceLineItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneCareBenefitUsageToInvoiceLineItemMutation, UpdateOneCareBenefitUsageToInvoiceLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneCareBenefitUsageToInvoiceLineItemMutation, UpdateOneCareBenefitUsageToInvoiceLineItemMutationVariables>(UpdateOneCareBenefitUsageToInvoiceLineItemDocument, options);
      }
export type UpdateOneCareBenefitUsageToInvoiceLineItemMutationHookResult = ReturnType<typeof useUpdateOneCareBenefitUsageToInvoiceLineItemMutation>;
export type UpdateOneCareBenefitUsageToInvoiceLineItemMutationResult = Apollo.MutationResult<UpdateOneCareBenefitUsageToInvoiceLineItemMutation>;
export type UpdateOneCareBenefitUsageToInvoiceLineItemMutationOptions = Apollo.BaseMutationOptions<UpdateOneCareBenefitUsageToInvoiceLineItemMutation, UpdateOneCareBenefitUsageToInvoiceLineItemMutationVariables>;
export const UpdateChannelAssigneesDocument = gql`
    mutation updateChannelAssignees($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
  updateOneChannel(where: $where, data: $data) {
    id
    channelStatus {
      id
    }
    assignees {
      id
    }
  }
}
    `;
export type UpdateChannelAssigneesMutationFn = Apollo.MutationFunction<UpdateChannelAssigneesMutation, UpdateChannelAssigneesMutationVariables>;

/**
 * __useUpdateChannelAssigneesMutation__
 *
 * To run a mutation, you first call `useUpdateChannelAssigneesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelAssigneesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelAssigneesMutation, { data, loading, error }] = useUpdateChannelAssigneesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelAssigneesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelAssigneesMutation, UpdateChannelAssigneesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelAssigneesMutation, UpdateChannelAssigneesMutationVariables>(UpdateChannelAssigneesDocument, options);
      }
export type UpdateChannelAssigneesMutationHookResult = ReturnType<typeof useUpdateChannelAssigneesMutation>;
export type UpdateChannelAssigneesMutationResult = Apollo.MutationResult<UpdateChannelAssigneesMutation>;
export type UpdateChannelAssigneesMutationOptions = Apollo.BaseMutationOptions<UpdateChannelAssigneesMutation, UpdateChannelAssigneesMutationVariables>;
export const UpdateChannelMemberDocument = gql`
    mutation updateChannelMember($where: ChannelMemberWhereUniqueInput!, $data: ChannelMemberUpdateInput!) {
  updateOneChannelMember(where: $where, data: $data) {
    id
    lastConsumedMessageIndex
    removedAt
  }
}
    `;
export type UpdateChannelMemberMutationFn = Apollo.MutationFunction<UpdateChannelMemberMutation, UpdateChannelMemberMutationVariables>;

/**
 * __useUpdateChannelMemberMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMemberMutation, { data, loading, error }] = useUpdateChannelMemberMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMemberMutation, UpdateChannelMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMemberMutation, UpdateChannelMemberMutationVariables>(UpdateChannelMemberDocument, options);
      }
export type UpdateChannelMemberMutationHookResult = ReturnType<typeof useUpdateChannelMemberMutation>;
export type UpdateChannelMemberMutationResult = Apollo.MutationResult<UpdateChannelMemberMutation>;
export type UpdateChannelMemberMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMemberMutation, UpdateChannelMemberMutationVariables>;
export const UpdateChannelMessageAttributesDocument = gql`
    mutation updateChannelMessageAttributes($where: ChannelMessageWhereUniqueInput!, $data: ChannelMessageUpdateInput!) {
  updateChannelMessage: updateOneChannelMessage(where: $where, data: $data) {
    id
    attributes
    reactions {
      ...Reaction
    }
  }
}
    ${ReactionFragmentDoc}`;
export type UpdateChannelMessageAttributesMutationFn = Apollo.MutationFunction<UpdateChannelMessageAttributesMutation, UpdateChannelMessageAttributesMutationVariables>;

/**
 * __useUpdateChannelMessageAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMessageAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMessageAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMessageAttributesMutation, { data, loading, error }] = useUpdateChannelMessageAttributesMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelMessageAttributesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMessageAttributesMutation, UpdateChannelMessageAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMessageAttributesMutation, UpdateChannelMessageAttributesMutationVariables>(UpdateChannelMessageAttributesDocument, options);
      }
export type UpdateChannelMessageAttributesMutationHookResult = ReturnType<typeof useUpdateChannelMessageAttributesMutation>;
export type UpdateChannelMessageAttributesMutationResult = Apollo.MutationResult<UpdateChannelMessageAttributesMutation>;
export type UpdateChannelMessageAttributesMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMessageAttributesMutation, UpdateChannelMessageAttributesMutationVariables>;
export const UpdateChannelPetsDocument = gql`
    mutation updateChannelPets($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
  updateOneChannel(where: $where, data: $data) {
    id
    pets {
      id
      ...ChannelViewChannelPet
    }
  }
}
    ${ChannelViewChannelPetFragmentDoc}`;
export type UpdateChannelPetsMutationFn = Apollo.MutationFunction<UpdateChannelPetsMutation, UpdateChannelPetsMutationVariables>;

/**
 * __useUpdateChannelPetsMutation__
 *
 * To run a mutation, you first call `useUpdateChannelPetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelPetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelPetsMutation, { data, loading, error }] = useUpdateChannelPetsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelPetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelPetsMutation, UpdateChannelPetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelPetsMutation, UpdateChannelPetsMutationVariables>(UpdateChannelPetsDocument, options);
      }
export type UpdateChannelPetsMutationHookResult = ReturnType<typeof useUpdateChannelPetsMutation>;
export type UpdateChannelPetsMutationResult = Apollo.MutationResult<UpdateChannelPetsMutation>;
export type UpdateChannelPetsMutationOptions = Apollo.BaseMutationOptions<UpdateChannelPetsMutation, UpdateChannelPetsMutationVariables>;
export const UpdateLineItemDocument = gql`
    mutation updateLineItem($where: InvoiceLineItemWhereUniqueInput!, $data: InvoiceLineItemUpdateInput!) {
  updateOneInvoiceLineItem(where: $where, data: $data) {
    id
    connectedCareBenefits {
      id
      adjustedAmount
      careBenefitUsage {
        id
        quantityUsed
      }
    }
  }
}
    `;
export type UpdateLineItemMutationFn = Apollo.MutationFunction<UpdateLineItemMutation, UpdateLineItemMutationVariables>;

/**
 * __useUpdateLineItemMutation__
 *
 * To run a mutation, you first call `useUpdateLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineItemMutation, { data, loading, error }] = useUpdateLineItemMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLineItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLineItemMutation, UpdateLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLineItemMutation, UpdateLineItemMutationVariables>(UpdateLineItemDocument, options);
      }
export type UpdateLineItemMutationHookResult = ReturnType<typeof useUpdateLineItemMutation>;
export type UpdateLineItemMutationResult = Apollo.MutationResult<UpdateLineItemMutation>;
export type UpdateLineItemMutationOptions = Apollo.BaseMutationOptions<UpdateLineItemMutation, UpdateLineItemMutationVariables>;
export const CalculateMultiplePetsBenefitsUsageDocument = gql`
    query calculateMultiplePetsBenefitsUsage($where: CalculateMultiplePetsBenefitUsageInput) {
  calculateMultiplePetsBenefitUsage(where: $where) {
    ...CalculateMultiplePetsBenefitUsage
  }
}
    ${CalculateMultiplePetsBenefitUsageFragmentDoc}`;

/**
 * __useCalculateMultiplePetsBenefitsUsageQuery__
 *
 * To run a query within a React component, call `useCalculateMultiplePetsBenefitsUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateMultiplePetsBenefitsUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateMultiplePetsBenefitsUsageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCalculateMultiplePetsBenefitsUsageQuery(baseOptions?: Apollo.QueryHookOptions<CalculateMultiplePetsBenefitsUsageQuery, CalculateMultiplePetsBenefitsUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateMultiplePetsBenefitsUsageQuery, CalculateMultiplePetsBenefitsUsageQueryVariables>(CalculateMultiplePetsBenefitsUsageDocument, options);
      }
export function useCalculateMultiplePetsBenefitsUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateMultiplePetsBenefitsUsageQuery, CalculateMultiplePetsBenefitsUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateMultiplePetsBenefitsUsageQuery, CalculateMultiplePetsBenefitsUsageQueryVariables>(CalculateMultiplePetsBenefitsUsageDocument, options);
        }
export type CalculateMultiplePetsBenefitsUsageQueryHookResult = ReturnType<typeof useCalculateMultiplePetsBenefitsUsageQuery>;
export type CalculateMultiplePetsBenefitsUsageLazyQueryHookResult = ReturnType<typeof useCalculateMultiplePetsBenefitsUsageLazyQuery>;
export type CalculateMultiplePetsBenefitsUsageQueryResult = Apollo.QueryResult<CalculateMultiplePetsBenefitsUsageQuery, CalculateMultiplePetsBenefitsUsageQueryVariables>;
export function refetchCalculateMultiplePetsBenefitsUsageQuery(variables?: CalculateMultiplePetsBenefitsUsageQueryVariables) {
      return { query: CalculateMultiplePetsBenefitsUsageDocument, variables: variables }
    }
export const GetChannelAssigneeMenuDataDocument = gql`
    query getChannelAssigneeMenuData($channelId: String!, $clinicId: String) {
  findUniqueChannel(where: {id: $channelId}) {
    id
    assignees(
      where: {isActive: {equals: true}, vetInfo: {clinics: {some: {id: {equals: $clinicId}}}}}
    ) {
      ...ClinicUserAvatar
    }
  }
  users: findManyUser(
    where: {vetInfo: {clinics: {some: {id: {equals: $clinicId}}}}, isActive: {equals: true}}
  ) {
    ...ClinicUserAvatar
  }
}
    ${ClinicUserAvatarFragmentDoc}`;

/**
 * __useGetChannelAssigneeMenuDataQuery__
 *
 * To run a query within a React component, call `useGetChannelAssigneeMenuDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelAssigneeMenuDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelAssigneeMenuDataQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetChannelAssigneeMenuDataQuery(baseOptions: Apollo.QueryHookOptions<GetChannelAssigneeMenuDataQuery, GetChannelAssigneeMenuDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelAssigneeMenuDataQuery, GetChannelAssigneeMenuDataQueryVariables>(GetChannelAssigneeMenuDataDocument, options);
      }
export function useGetChannelAssigneeMenuDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelAssigneeMenuDataQuery, GetChannelAssigneeMenuDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelAssigneeMenuDataQuery, GetChannelAssigneeMenuDataQueryVariables>(GetChannelAssigneeMenuDataDocument, options);
        }
export type GetChannelAssigneeMenuDataQueryHookResult = ReturnType<typeof useGetChannelAssigneeMenuDataQuery>;
export type GetChannelAssigneeMenuDataLazyQueryHookResult = ReturnType<typeof useGetChannelAssigneeMenuDataLazyQuery>;
export type GetChannelAssigneeMenuDataQueryResult = Apollo.QueryResult<GetChannelAssigneeMenuDataQuery, GetChannelAssigneeMenuDataQueryVariables>;
export function refetchGetChannelAssigneeMenuDataQuery(variables?: GetChannelAssigneeMenuDataQueryVariables) {
      return { query: GetChannelAssigneeMenuDataDocument, variables: variables }
    }
export const GetChannelListChannelsDocument = gql`
    query getChannelListChannels($where: SearchChannelInput!, $take: Int!, $skip: Int!, $orderBy: ChannelOrderByWithRelationAndSearchRelevanceInput) {
  channelSearch(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    total
    channels {
      ...ChannelListChannel
    }
  }
}
    ${ChannelListChannelFragmentDoc}`;

/**
 * __useGetChannelListChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelListChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelListChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelListChannelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetChannelListChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetChannelListChannelsQuery, GetChannelListChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelListChannelsQuery, GetChannelListChannelsQueryVariables>(GetChannelListChannelsDocument, options);
      }
export function useGetChannelListChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelListChannelsQuery, GetChannelListChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelListChannelsQuery, GetChannelListChannelsQueryVariables>(GetChannelListChannelsDocument, options);
        }
export type GetChannelListChannelsQueryHookResult = ReturnType<typeof useGetChannelListChannelsQuery>;
export type GetChannelListChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelListChannelsLazyQuery>;
export type GetChannelListChannelsQueryResult = Apollo.QueryResult<GetChannelListChannelsQuery, GetChannelListChannelsQueryVariables>;
export function refetchGetChannelListChannelsQuery(variables?: GetChannelListChannelsQueryVariables) {
      return { query: GetChannelListChannelsDocument, variables: variables }
    }
export const GetChannelMembersConsumptionHorizonDocument = gql`
    query getChannelMembersConsumptionHorizon($where: ChannelMemberWhereInput!) {
  channelMembers: findManyChannelMember(where: $where) {
    id
    lastConsumedMessageIndex
  }
}
    `;

/**
 * __useGetChannelMembersConsumptionHorizonQuery__
 *
 * To run a query within a React component, call `useGetChannelMembersConsumptionHorizonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelMembersConsumptionHorizonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelMembersConsumptionHorizonQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetChannelMembersConsumptionHorizonQuery(baseOptions: Apollo.QueryHookOptions<GetChannelMembersConsumptionHorizonQuery, GetChannelMembersConsumptionHorizonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelMembersConsumptionHorizonQuery, GetChannelMembersConsumptionHorizonQueryVariables>(GetChannelMembersConsumptionHorizonDocument, options);
      }
export function useGetChannelMembersConsumptionHorizonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelMembersConsumptionHorizonQuery, GetChannelMembersConsumptionHorizonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelMembersConsumptionHorizonQuery, GetChannelMembersConsumptionHorizonQueryVariables>(GetChannelMembersConsumptionHorizonDocument, options);
        }
export type GetChannelMembersConsumptionHorizonQueryHookResult = ReturnType<typeof useGetChannelMembersConsumptionHorizonQuery>;
export type GetChannelMembersConsumptionHorizonLazyQueryHookResult = ReturnType<typeof useGetChannelMembersConsumptionHorizonLazyQuery>;
export type GetChannelMembersConsumptionHorizonQueryResult = Apollo.QueryResult<GetChannelMembersConsumptionHorizonQuery, GetChannelMembersConsumptionHorizonQueryVariables>;
export function refetchGetChannelMembersConsumptionHorizonQuery(variables?: GetChannelMembersConsumptionHorizonQueryVariables) {
      return { query: GetChannelMembersConsumptionHorizonDocument, variables: variables }
    }
export const GetChannelPdfDownloadDataDocument = gql`
    query getChannelPdfDownloadData($where: ChannelExportWhereInput!) {
  channelExport(where: $where) {
    fileType
    base64Encoding
  }
}
    `;

/**
 * __useGetChannelPdfDownloadDataQuery__
 *
 * To run a query within a React component, call `useGetChannelPdfDownloadDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPdfDownloadDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPdfDownloadDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetChannelPdfDownloadDataQuery(baseOptions: Apollo.QueryHookOptions<GetChannelPdfDownloadDataQuery, GetChannelPdfDownloadDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelPdfDownloadDataQuery, GetChannelPdfDownloadDataQueryVariables>(GetChannelPdfDownloadDataDocument, options);
      }
export function useGetChannelPdfDownloadDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelPdfDownloadDataQuery, GetChannelPdfDownloadDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelPdfDownloadDataQuery, GetChannelPdfDownloadDataQueryVariables>(GetChannelPdfDownloadDataDocument, options);
        }
export type GetChannelPdfDownloadDataQueryHookResult = ReturnType<typeof useGetChannelPdfDownloadDataQuery>;
export type GetChannelPdfDownloadDataLazyQueryHookResult = ReturnType<typeof useGetChannelPdfDownloadDataLazyQuery>;
export type GetChannelPdfDownloadDataQueryResult = Apollo.QueryResult<GetChannelPdfDownloadDataQuery, GetChannelPdfDownloadDataQueryVariables>;
export function refetchGetChannelPdfDownloadDataQuery(variables?: GetChannelPdfDownloadDataQueryVariables) {
      return { query: GetChannelPdfDownloadDataDocument, variables: variables }
    }
export const GetChannelPetsDocument = gql`
    query getChannelPets($where: ClinicPetParentWhereInput, $petsWhere: ClinicPetWhereInput!, $petsTake: Int) {
  findManyClinicPetParent(where: $where) {
    id
    pets(where: $petsWhere, take: $petsTake) {
      id
      ...ChannelPet
    }
  }
}
    ${ChannelPetFragmentDoc}`;

/**
 * __useGetChannelPetsQuery__
 *
 * To run a query within a React component, call `useGetChannelPetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      petsWhere: // value for 'petsWhere'
 *      petsTake: // value for 'petsTake'
 *   },
 * });
 */
export function useGetChannelPetsQuery(baseOptions: Apollo.QueryHookOptions<GetChannelPetsQuery, GetChannelPetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelPetsQuery, GetChannelPetsQueryVariables>(GetChannelPetsDocument, options);
      }
export function useGetChannelPetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelPetsQuery, GetChannelPetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelPetsQuery, GetChannelPetsQueryVariables>(GetChannelPetsDocument, options);
        }
export type GetChannelPetsQueryHookResult = ReturnType<typeof useGetChannelPetsQuery>;
export type GetChannelPetsLazyQueryHookResult = ReturnType<typeof useGetChannelPetsLazyQuery>;
export type GetChannelPetsQueryResult = Apollo.QueryResult<GetChannelPetsQuery, GetChannelPetsQueryVariables>;
export function refetchGetChannelPetsQuery(variables?: GetChannelPetsQueryVariables) {
      return { query: GetChannelPetsDocument, variables: variables }
    }
export const GetChannelViewChannelMessagesDocument = gql`
    query getChannelViewChannelMessages($where: ChannelMessageWhereInput!, $orderBy: [ChannelMessageOrderByWithRelationAndSearchRelevanceInput!], $cursor: ChannelMessageWhereUniqueInput, $skip: Int, $take: Int) {
  findManyChannelMessage(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    ...ChannelViewChannelMessage
  }
  findManyChannelMessageCount(where: $where)
}
    ${ChannelViewChannelMessageFragmentDoc}`;

/**
 * __useGetChannelViewChannelMessagesQuery__
 *
 * To run a query within a React component, call `useGetChannelViewChannelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelViewChannelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelViewChannelMessagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetChannelViewChannelMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetChannelViewChannelMessagesQuery, GetChannelViewChannelMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelViewChannelMessagesQuery, GetChannelViewChannelMessagesQueryVariables>(GetChannelViewChannelMessagesDocument, options);
      }
export function useGetChannelViewChannelMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelViewChannelMessagesQuery, GetChannelViewChannelMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelViewChannelMessagesQuery, GetChannelViewChannelMessagesQueryVariables>(GetChannelViewChannelMessagesDocument, options);
        }
export type GetChannelViewChannelMessagesQueryHookResult = ReturnType<typeof useGetChannelViewChannelMessagesQuery>;
export type GetChannelViewChannelMessagesLazyQueryHookResult = ReturnType<typeof useGetChannelViewChannelMessagesLazyQuery>;
export type GetChannelViewChannelMessagesQueryResult = Apollo.QueryResult<GetChannelViewChannelMessagesQuery, GetChannelViewChannelMessagesQueryVariables>;
export function refetchGetChannelViewChannelMessagesQuery(variables?: GetChannelViewChannelMessagesQueryVariables) {
      return { query: GetChannelViewChannelMessagesDocument, variables: variables }
    }
export const GetChannelViewClientDropdownPetParentsDocument = gql`
    query getChannelViewClientDropdownPetParents($petIds: [String!]!) {
  findManyClinicPetParent(where: {pets: {some: {id: {in: $petIds}}}}) {
    ...ChannelViewClientDropdownClinicPetParent
  }
}
    ${ChannelViewClientDropdownClinicPetParentFragmentDoc}`;

/**
 * __useGetChannelViewClientDropdownPetParentsQuery__
 *
 * To run a query within a React component, call `useGetChannelViewClientDropdownPetParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelViewClientDropdownPetParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelViewClientDropdownPetParentsQuery({
 *   variables: {
 *      petIds: // value for 'petIds'
 *   },
 * });
 */
export function useGetChannelViewClientDropdownPetParentsQuery(baseOptions: Apollo.QueryHookOptions<GetChannelViewClientDropdownPetParentsQuery, GetChannelViewClientDropdownPetParentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelViewClientDropdownPetParentsQuery, GetChannelViewClientDropdownPetParentsQueryVariables>(GetChannelViewClientDropdownPetParentsDocument, options);
      }
export function useGetChannelViewClientDropdownPetParentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelViewClientDropdownPetParentsQuery, GetChannelViewClientDropdownPetParentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelViewClientDropdownPetParentsQuery, GetChannelViewClientDropdownPetParentsQueryVariables>(GetChannelViewClientDropdownPetParentsDocument, options);
        }
export type GetChannelViewClientDropdownPetParentsQueryHookResult = ReturnType<typeof useGetChannelViewClientDropdownPetParentsQuery>;
export type GetChannelViewClientDropdownPetParentsLazyQueryHookResult = ReturnType<typeof useGetChannelViewClientDropdownPetParentsLazyQuery>;
export type GetChannelViewClientDropdownPetParentsQueryResult = Apollo.QueryResult<GetChannelViewClientDropdownPetParentsQuery, GetChannelViewClientDropdownPetParentsQueryVariables>;
export function refetchGetChannelViewClientDropdownPetParentsQuery(variables?: GetChannelViewClientDropdownPetParentsQueryVariables) {
      return { query: GetChannelViewClientDropdownPetParentsDocument, variables: variables }
    }
export const GetClinicChannelViewChannelDocument = gql`
    query getClinicChannelViewChannel($channelId: String!, $clinicId: String!, $userId: String!) {
  findUniqueChannel(where: {id: $channelId}) {
    ...ChannelViewChannel
  }
}
    ${ChannelViewChannelFragmentDoc}`;

/**
 * __useGetClinicChannelViewChannelQuery__
 *
 * To run a query within a React component, call `useGetClinicChannelViewChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicChannelViewChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicChannelViewChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clinicId: // value for 'clinicId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetClinicChannelViewChannelQuery(baseOptions: Apollo.QueryHookOptions<GetClinicChannelViewChannelQuery, GetClinicChannelViewChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicChannelViewChannelQuery, GetClinicChannelViewChannelQueryVariables>(GetClinicChannelViewChannelDocument, options);
      }
export function useGetClinicChannelViewChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicChannelViewChannelQuery, GetClinicChannelViewChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicChannelViewChannelQuery, GetClinicChannelViewChannelQueryVariables>(GetClinicChannelViewChannelDocument, options);
        }
export type GetClinicChannelViewChannelQueryHookResult = ReturnType<typeof useGetClinicChannelViewChannelQuery>;
export type GetClinicChannelViewChannelLazyQueryHookResult = ReturnType<typeof useGetClinicChannelViewChannelLazyQuery>;
export type GetClinicChannelViewChannelQueryResult = Apollo.QueryResult<GetClinicChannelViewChannelQuery, GetClinicChannelViewChannelQueryVariables>;
export function refetchGetClinicChannelViewChannelQuery(variables?: GetClinicChannelViewChannelQueryVariables) {
      return { query: GetClinicChannelViewChannelDocument, variables: variables }
    }
export const GetClinicWidgetRequestDocument = gql`
    query getClinicWidgetRequest($where: ClinicWidgetRequestWhereUniqueInput!) {
  clinicWidgetRequest: findUniqueClinicWidgetRequest(where: $where) {
    id
    clinicPetParent {
      id
      firstName
      lastName
      pimsId
      ...PetParentAvatar
      phoneNumbers {
        id
        number
        isPrimary
        isMobile
        isDeleted
        updatedAt
      }
    }
    clinicWidgetRequestType {
      id
      displayName
      requestType
    }
    formSubmission {
      id
      completedContent
    }
    url
    isVerified
    requestFormSubmission
  }
}
    ${PetParentAvatarFragmentDoc}`;

/**
 * __useGetClinicWidgetRequestQuery__
 *
 * To run a query within a React component, call `useGetClinicWidgetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicWidgetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicWidgetRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicWidgetRequestQuery(baseOptions: Apollo.QueryHookOptions<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>(GetClinicWidgetRequestDocument, options);
      }
export function useGetClinicWidgetRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>(GetClinicWidgetRequestDocument, options);
        }
export type GetClinicWidgetRequestQueryHookResult = ReturnType<typeof useGetClinicWidgetRequestQuery>;
export type GetClinicWidgetRequestLazyQueryHookResult = ReturnType<typeof useGetClinicWidgetRequestLazyQuery>;
export type GetClinicWidgetRequestQueryResult = Apollo.QueryResult<GetClinicWidgetRequestQuery, GetClinicWidgetRequestQueryVariables>;
export function refetchGetClinicWidgetRequestQuery(variables?: GetClinicWidgetRequestQueryVariables) {
      return { query: GetClinicWidgetRequestDocument, variables: variables }
    }
export const GetExportabilityCheckChannelDocument = gql`
    query getExportabilityCheckChannel($channelId: String!, $clinicId: String) {
  findUniqueChannel(where: {id: $channelId}) {
    ...ExportabilityCheckChannel
  }
}
    ${ExportabilityCheckChannelFragmentDoc}`;

/**
 * __useGetExportabilityCheckChannelQuery__
 *
 * To run a query within a React component, call `useGetExportabilityCheckChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportabilityCheckChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportabilityCheckChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetExportabilityCheckChannelQuery(baseOptions: Apollo.QueryHookOptions<GetExportabilityCheckChannelQuery, GetExportabilityCheckChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExportabilityCheckChannelQuery, GetExportabilityCheckChannelQueryVariables>(GetExportabilityCheckChannelDocument, options);
      }
export function useGetExportabilityCheckChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportabilityCheckChannelQuery, GetExportabilityCheckChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExportabilityCheckChannelQuery, GetExportabilityCheckChannelQueryVariables>(GetExportabilityCheckChannelDocument, options);
        }
export type GetExportabilityCheckChannelQueryHookResult = ReturnType<typeof useGetExportabilityCheckChannelQuery>;
export type GetExportabilityCheckChannelLazyQueryHookResult = ReturnType<typeof useGetExportabilityCheckChannelLazyQuery>;
export type GetExportabilityCheckChannelQueryResult = Apollo.QueryResult<GetExportabilityCheckChannelQuery, GetExportabilityCheckChannelQueryVariables>;
export function refetchGetExportabilityCheckChannelQuery(variables?: GetExportabilityCheckChannelQueryVariables) {
      return { query: GetExportabilityCheckChannelDocument, variables: variables }
    }
export const GetMessageComposerAttachmentFormTemplateDocument = gql`
    query getMessageComposerAttachmentFormTemplate($where: FormTemplateWhereInput) {
  findFirstFormTemplate(where: $where) {
    id
    title
    content
  }
}
    `;

/**
 * __useGetMessageComposerAttachmentFormTemplateQuery__
 *
 * To run a query within a React component, call `useGetMessageComposerAttachmentFormTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageComposerAttachmentFormTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageComposerAttachmentFormTemplateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMessageComposerAttachmentFormTemplateQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageComposerAttachmentFormTemplateQuery, GetMessageComposerAttachmentFormTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageComposerAttachmentFormTemplateQuery, GetMessageComposerAttachmentFormTemplateQueryVariables>(GetMessageComposerAttachmentFormTemplateDocument, options);
      }
export function useGetMessageComposerAttachmentFormTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageComposerAttachmentFormTemplateQuery, GetMessageComposerAttachmentFormTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageComposerAttachmentFormTemplateQuery, GetMessageComposerAttachmentFormTemplateQueryVariables>(GetMessageComposerAttachmentFormTemplateDocument, options);
        }
export type GetMessageComposerAttachmentFormTemplateQueryHookResult = ReturnType<typeof useGetMessageComposerAttachmentFormTemplateQuery>;
export type GetMessageComposerAttachmentFormTemplateLazyQueryHookResult = ReturnType<typeof useGetMessageComposerAttachmentFormTemplateLazyQuery>;
export type GetMessageComposerAttachmentFormTemplateQueryResult = Apollo.QueryResult<GetMessageComposerAttachmentFormTemplateQuery, GetMessageComposerAttachmentFormTemplateQueryVariables>;
export function refetchGetMessageComposerAttachmentFormTemplateQuery(variables?: GetMessageComposerAttachmentFormTemplateQueryVariables) {
      return { query: GetMessageComposerAttachmentFormTemplateDocument, variables: variables }
    }
export const GetMessageComposerFormTemplatesDocument = gql`
    query getMessageComposerFormTemplates($where: FormTemplateWhereInput!) {
  findManyFormTemplate(where: $where) {
    ...MessageComposerFormTemplate
  }
}
    ${MessageComposerFormTemplateFragmentDoc}`;

/**
 * __useGetMessageComposerFormTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageComposerFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageComposerFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageComposerFormTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMessageComposerFormTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetMessageComposerFormTemplatesQuery, GetMessageComposerFormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageComposerFormTemplatesQuery, GetMessageComposerFormTemplatesQueryVariables>(GetMessageComposerFormTemplatesDocument, options);
      }
export function useGetMessageComposerFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageComposerFormTemplatesQuery, GetMessageComposerFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageComposerFormTemplatesQuery, GetMessageComposerFormTemplatesQueryVariables>(GetMessageComposerFormTemplatesDocument, options);
        }
export type GetMessageComposerFormTemplatesQueryHookResult = ReturnType<typeof useGetMessageComposerFormTemplatesQuery>;
export type GetMessageComposerFormTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageComposerFormTemplatesLazyQuery>;
export type GetMessageComposerFormTemplatesQueryResult = Apollo.QueryResult<GetMessageComposerFormTemplatesQuery, GetMessageComposerFormTemplatesQueryVariables>;
export function refetchGetMessageComposerFormTemplatesQuery(variables?: GetMessageComposerFormTemplatesQueryVariables) {
      return { query: GetMessageComposerFormTemplatesDocument, variables: variables }
    }
export const GetMessageComposerMessageTemplatesDocument = gql`
    query getMessageComposerMessageTemplates($where: MessageTemplateWhereInput, $orderBy: [MessageTemplateOrderByWithRelationAndSearchRelevanceInput!]) {
  findManyMessageTemplate(where: $where, orderBy: $orderBy) {
    ...MessageComposerMessageTemplate
  }
}
    ${MessageComposerMessageTemplateFragmentDoc}`;

/**
 * __useGetMessageComposerMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageComposerMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageComposerMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageComposerMessageTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMessageComposerMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageComposerMessageTemplatesQuery, GetMessageComposerMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageComposerMessageTemplatesQuery, GetMessageComposerMessageTemplatesQueryVariables>(GetMessageComposerMessageTemplatesDocument, options);
      }
export function useGetMessageComposerMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageComposerMessageTemplatesQuery, GetMessageComposerMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageComposerMessageTemplatesQuery, GetMessageComposerMessageTemplatesQueryVariables>(GetMessageComposerMessageTemplatesDocument, options);
        }
export type GetMessageComposerMessageTemplatesQueryHookResult = ReturnType<typeof useGetMessageComposerMessageTemplatesQuery>;
export type GetMessageComposerMessageTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageComposerMessageTemplatesLazyQuery>;
export type GetMessageComposerMessageTemplatesQueryResult = Apollo.QueryResult<GetMessageComposerMessageTemplatesQuery, GetMessageComposerMessageTemplatesQueryVariables>;
export function refetchGetMessageComposerMessageTemplatesQuery(variables?: GetMessageComposerMessageTemplatesQueryVariables) {
      return { query: GetMessageComposerMessageTemplatesDocument, variables: variables }
    }
export const GetClinicServicesForInvoiceLineItemsDocument = gql`
    query getClinicServicesForInvoiceLineItems($where: ServiceWhereInput, $orderBy: [ServiceOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  findManyService(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    name
    code
    organizationCareBenefitToClinicService {
      benefit {
        id
        title
        description
        tangible
        providedDiscountPercentage
        usageUnitOfMeasure
        planBenefits {
          id
          includedUses
        }
      }
    }
  }
}
    `;

/**
 * __useGetClinicServicesForInvoiceLineItemsQuery__
 *
 * To run a query within a React component, call `useGetClinicServicesForInvoiceLineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicServicesForInvoiceLineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicServicesForInvoiceLineItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetClinicServicesForInvoiceLineItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicServicesForInvoiceLineItemsQuery, GetClinicServicesForInvoiceLineItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicServicesForInvoiceLineItemsQuery, GetClinicServicesForInvoiceLineItemsQueryVariables>(GetClinicServicesForInvoiceLineItemsDocument, options);
      }
export function useGetClinicServicesForInvoiceLineItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicServicesForInvoiceLineItemsQuery, GetClinicServicesForInvoiceLineItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicServicesForInvoiceLineItemsQuery, GetClinicServicesForInvoiceLineItemsQueryVariables>(GetClinicServicesForInvoiceLineItemsDocument, options);
        }
export type GetClinicServicesForInvoiceLineItemsQueryHookResult = ReturnType<typeof useGetClinicServicesForInvoiceLineItemsQuery>;
export type GetClinicServicesForInvoiceLineItemsLazyQueryHookResult = ReturnType<typeof useGetClinicServicesForInvoiceLineItemsLazyQuery>;
export type GetClinicServicesForInvoiceLineItemsQueryResult = Apollo.QueryResult<GetClinicServicesForInvoiceLineItemsQuery, GetClinicServicesForInvoiceLineItemsQueryVariables>;
export function refetchGetClinicServicesForInvoiceLineItemsQuery(variables?: GetClinicServicesForInvoiceLineItemsQueryVariables) {
      return { query: GetClinicServicesForInvoiceLineItemsDocument, variables: variables }
    }
export const GetSmsNotificationStatusDocument = gql`
    query getSmsNotificationStatus($where: SmsNotificationStatusWhereInput!) {
  findFirstSmsNotificationStatus(where: $where, orderBy: {createdAt: desc}) {
    ...SmsNotificationStatus
  }
}
    ${SmsNotificationStatusFragmentDoc}`;

/**
 * __useGetSmsNotificationStatusQuery__
 *
 * To run a query within a React component, call `useGetSmsNotificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsNotificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsNotificationStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSmsNotificationStatusQuery(baseOptions: Apollo.QueryHookOptions<GetSmsNotificationStatusQuery, GetSmsNotificationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsNotificationStatusQuery, GetSmsNotificationStatusQueryVariables>(GetSmsNotificationStatusDocument, options);
      }
export function useGetSmsNotificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsNotificationStatusQuery, GetSmsNotificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsNotificationStatusQuery, GetSmsNotificationStatusQueryVariables>(GetSmsNotificationStatusDocument, options);
        }
export type GetSmsNotificationStatusQueryHookResult = ReturnType<typeof useGetSmsNotificationStatusQuery>;
export type GetSmsNotificationStatusLazyQueryHookResult = ReturnType<typeof useGetSmsNotificationStatusLazyQuery>;
export type GetSmsNotificationStatusQueryResult = Apollo.QueryResult<GetSmsNotificationStatusQuery, GetSmsNotificationStatusQueryVariables>;
export function refetchGetSmsNotificationStatusQuery(variables?: GetSmsNotificationStatusQueryVariables) {
      return { query: GetSmsNotificationStatusDocument, variables: variables }
    }
export const SubscribeToAutomationRunActionDocument = gql`
    subscription subscribeToAutomationRunAction($automationRunId: String!) {
  automationRunActionChanged(
    where: {node: {automationRunId: {equals: $automationRunId}}}
  ) {
    node {
      ...AutomationRunAction
    }
  }
}
    ${AutomationRunActionFragmentDoc}`;

/**
 * __useSubscribeToAutomationRunActionSubscription__
 *
 * To run a query within a React component, call `useSubscribeToAutomationRunActionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToAutomationRunActionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToAutomationRunActionSubscription({
 *   variables: {
 *      automationRunId: // value for 'automationRunId'
 *   },
 * });
 */
export function useSubscribeToAutomationRunActionSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToAutomationRunActionSubscription, SubscribeToAutomationRunActionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToAutomationRunActionSubscription, SubscribeToAutomationRunActionSubscriptionVariables>(SubscribeToAutomationRunActionDocument, options);
      }
export type SubscribeToAutomationRunActionSubscriptionHookResult = ReturnType<typeof useSubscribeToAutomationRunActionSubscription>;
export type SubscribeToAutomationRunActionSubscriptionResult = Apollo.SubscriptionResult<SubscribeToAutomationRunActionSubscription>;
export const SubscribeToChannelListChannelsDocument = gql`
    subscription subscribeToChannelListChannels($clinicId: String!) {
  channelSyncedToSearch(where: {clinicId: $clinicId}) {
    id
  }
}
    `;

/**
 * __useSubscribeToChannelListChannelsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToChannelListChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToChannelListChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToChannelListChannelsSubscription({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSubscribeToChannelListChannelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToChannelListChannelsSubscription, SubscribeToChannelListChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToChannelListChannelsSubscription, SubscribeToChannelListChannelsSubscriptionVariables>(SubscribeToChannelListChannelsDocument, options);
      }
export type SubscribeToChannelListChannelsSubscriptionHookResult = ReturnType<typeof useSubscribeToChannelListChannelsSubscription>;
export type SubscribeToChannelListChannelsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToChannelListChannelsSubscription>;
export const SubscribeToChannelMessageAttachmentDocument = gql`
    subscription subscribeToChannelMessageAttachment($channelMessageAttachmentId: String!) {
  channelMessageAttachmentChanged(
    where: {node: {id: {equals: $channelMessageAttachmentId}}}
  ) {
    node {
      id
      filename
      url
      uploadStatus
      entityId
      attachmentType
    }
  }
}
    `;

/**
 * __useSubscribeToChannelMessageAttachmentSubscription__
 *
 * To run a query within a React component, call `useSubscribeToChannelMessageAttachmentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToChannelMessageAttachmentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToChannelMessageAttachmentSubscription({
 *   variables: {
 *      channelMessageAttachmentId: // value for 'channelMessageAttachmentId'
 *   },
 * });
 */
export function useSubscribeToChannelMessageAttachmentSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToChannelMessageAttachmentSubscription, SubscribeToChannelMessageAttachmentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToChannelMessageAttachmentSubscription, SubscribeToChannelMessageAttachmentSubscriptionVariables>(SubscribeToChannelMessageAttachmentDocument, options);
      }
export type SubscribeToChannelMessageAttachmentSubscriptionHookResult = ReturnType<typeof useSubscribeToChannelMessageAttachmentSubscription>;
export type SubscribeToChannelMessageAttachmentSubscriptionResult = Apollo.SubscriptionResult<SubscribeToChannelMessageAttachmentSubscription>;
export const SubscribeToChannelViewChannelDocument = gql`
    subscription subscribeToChannelViewChannel($channelId: String!, $clinicId: String!, $userId: String!) {
  channelChanged(where: {node: {id: {equals: $channelId}}}) {
    node {
      ...ChannelViewChannel
    }
  }
}
    ${ChannelViewChannelFragmentDoc}`;

/**
 * __useSubscribeToChannelViewChannelSubscription__
 *
 * To run a query within a React component, call `useSubscribeToChannelViewChannelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToChannelViewChannelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToChannelViewChannelSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clinicId: // value for 'clinicId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscribeToChannelViewChannelSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToChannelViewChannelSubscription, SubscribeToChannelViewChannelSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToChannelViewChannelSubscription, SubscribeToChannelViewChannelSubscriptionVariables>(SubscribeToChannelViewChannelDocument, options);
      }
export type SubscribeToChannelViewChannelSubscriptionHookResult = ReturnType<typeof useSubscribeToChannelViewChannelSubscription>;
export type SubscribeToChannelViewChannelSubscriptionResult = Apollo.SubscriptionResult<SubscribeToChannelViewChannelSubscription>;
export const SubscribeToChannelViewChannelMembersDocument = gql`
    subscription subscribeToChannelViewChannelMembers($channelId: String!) {
  channelMemberChanged(where: {node: {channelId: {equals: $channelId}}}) {
    node {
      id
      removedAt
      lastConsumedMessageIndex
      clinicPetParent {
        id
        phoneNumbers {
          id
          isPrimary
          number
          smsStatus
          lastSmsSendDate
        }
      }
    }
  }
}
    `;

/**
 * __useSubscribeToChannelViewChannelMembersSubscription__
 *
 * To run a query within a React component, call `useSubscribeToChannelViewChannelMembersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToChannelViewChannelMembersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToChannelViewChannelMembersSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSubscribeToChannelViewChannelMembersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToChannelViewChannelMembersSubscription, SubscribeToChannelViewChannelMembersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToChannelViewChannelMembersSubscription, SubscribeToChannelViewChannelMembersSubscriptionVariables>(SubscribeToChannelViewChannelMembersDocument, options);
      }
export type SubscribeToChannelViewChannelMembersSubscriptionHookResult = ReturnType<typeof useSubscribeToChannelViewChannelMembersSubscription>;
export type SubscribeToChannelViewChannelMembersSubscriptionResult = Apollo.SubscriptionResult<SubscribeToChannelViewChannelMembersSubscription>;
export const SubscribeToChannelViewChannelMessagesDocument = gql`
    subscription subscribeToChannelViewChannelMessages($channelId: String!) {
  channelMessageChanged(where: {node: {channelId: {equals: $channelId}}}) {
    node {
      ...ChannelViewChannelMessage
    }
    mutation
  }
}
    ${ChannelViewChannelMessageFragmentDoc}`;

/**
 * __useSubscribeToChannelViewChannelMessagesSubscription__
 *
 * To run a query within a React component, call `useSubscribeToChannelViewChannelMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToChannelViewChannelMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToChannelViewChannelMessagesSubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSubscribeToChannelViewChannelMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToChannelViewChannelMessagesSubscription, SubscribeToChannelViewChannelMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToChannelViewChannelMessagesSubscription, SubscribeToChannelViewChannelMessagesSubscriptionVariables>(SubscribeToChannelViewChannelMessagesDocument, options);
      }
export type SubscribeToChannelViewChannelMessagesSubscriptionHookResult = ReturnType<typeof useSubscribeToChannelViewChannelMessagesSubscription>;
export type SubscribeToChannelViewChannelMessagesSubscriptionResult = Apollo.SubscriptionResult<SubscribeToChannelViewChannelMessagesSubscription>;
export const SubscribeToClinicWidgetRequestDocument = gql`
    subscription subscribeToClinicWidgetRequest($where: SubscriptionClinicWidgetRequestWhereInput!) {
  clinicWidgetRequestChanged(where: $where) {
    node {
      id
      isVerified
    }
  }
}
    `;

/**
 * __useSubscribeToClinicWidgetRequestSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClinicWidgetRequestSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClinicWidgetRequestSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClinicWidgetRequestSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToClinicWidgetRequestSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToClinicWidgetRequestSubscription, SubscribeToClinicWidgetRequestSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToClinicWidgetRequestSubscription, SubscribeToClinicWidgetRequestSubscriptionVariables>(SubscribeToClinicWidgetRequestDocument, options);
      }
export type SubscribeToClinicWidgetRequestSubscriptionHookResult = ReturnType<typeof useSubscribeToClinicWidgetRequestSubscription>;
export type SubscribeToClinicWidgetRequestSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClinicWidgetRequestSubscription>;
export const SubscribeToPendingFulfillmentRewardUpdateDocument = gql`
    subscription subscribeToPendingFulfillmentRewardUpdate($where: LoyaltyRewardRedemptionWhereUniqueInput!) {
  loyaltyRewardRedemptionUpdated(where: $where) {
    node {
      id
      account {
        id
      }
      status
    }
  }
}
    `;

/**
 * __useSubscribeToPendingFulfillmentRewardUpdateSubscription__
 *
 * To run a query within a React component, call `useSubscribeToPendingFulfillmentRewardUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToPendingFulfillmentRewardUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToPendingFulfillmentRewardUpdateSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToPendingFulfillmentRewardUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToPendingFulfillmentRewardUpdateSubscription, SubscribeToPendingFulfillmentRewardUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToPendingFulfillmentRewardUpdateSubscription, SubscribeToPendingFulfillmentRewardUpdateSubscriptionVariables>(SubscribeToPendingFulfillmentRewardUpdateDocument, options);
      }
export type SubscribeToPendingFulfillmentRewardUpdateSubscriptionHookResult = ReturnType<typeof useSubscribeToPendingFulfillmentRewardUpdateSubscription>;
export type SubscribeToPendingFulfillmentRewardUpdateSubscriptionResult = Apollo.SubscriptionResult<SubscribeToPendingFulfillmentRewardUpdateSubscription>;
export const SubscribeToSmsNotificationStatusDocument = gql`
    subscription subscribeToSmsNotificationStatus($where: SubscriptionSmsNotificationStatusWhereInput!) {
  smsNotificationStatusChanged(where: $where) {
    node {
      ...SmsNotificationStatus
    }
  }
}
    ${SmsNotificationStatusFragmentDoc}`;

/**
 * __useSubscribeToSmsNotificationStatusSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSmsNotificationStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSmsNotificationStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSmsNotificationStatusSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToSmsNotificationStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToSmsNotificationStatusSubscription, SubscribeToSmsNotificationStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToSmsNotificationStatusSubscription, SubscribeToSmsNotificationStatusSubscriptionVariables>(SubscribeToSmsNotificationStatusDocument, options);
      }
export type SubscribeToSmsNotificationStatusSubscriptionHookResult = ReturnType<typeof useSubscribeToSmsNotificationStatusSubscription>;
export type SubscribeToSmsNotificationStatusSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSmsNotificationStatusSubscription>;
export const TriggerDemoWorkflowEventDocument = gql`
    mutation triggerDemoWorkflowEvent($data: TriggerWorkflowEventInput!) {
  triggerWorkflowEvent(data: $data) {
    success
  }
}
    `;
export type TriggerDemoWorkflowEventMutationFn = Apollo.MutationFunction<TriggerDemoWorkflowEventMutation, TriggerDemoWorkflowEventMutationVariables>;

/**
 * __useTriggerDemoWorkflowEventMutation__
 *
 * To run a mutation, you first call `useTriggerDemoWorkflowEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerDemoWorkflowEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerDemoWorkflowEventMutation, { data, loading, error }] = useTriggerDemoWorkflowEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTriggerDemoWorkflowEventMutation(baseOptions?: Apollo.MutationHookOptions<TriggerDemoWorkflowEventMutation, TriggerDemoWorkflowEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerDemoWorkflowEventMutation, TriggerDemoWorkflowEventMutationVariables>(TriggerDemoWorkflowEventDocument, options);
      }
export type TriggerDemoWorkflowEventMutationHookResult = ReturnType<typeof useTriggerDemoWorkflowEventMutation>;
export type TriggerDemoWorkflowEventMutationResult = Apollo.MutationResult<TriggerDemoWorkflowEventMutation>;
export type TriggerDemoWorkflowEventMutationOptions = Apollo.BaseMutationOptions<TriggerDemoWorkflowEventMutation, TriggerDemoWorkflowEventMutationVariables>;
export const GetDemoWorkflowClinicAppointmentsDocument = gql`
    query getDemoWorkflowClinicAppointments($where: AppointmentWhereInput) {
  findManyAppointment(where: $where, orderBy: {createdAt: desc}, take: 100) {
    ...DemoWorkflowAppointment
  }
}
    ${DemoWorkflowAppointmentFragmentDoc}`;

/**
 * __useGetDemoWorkflowClinicAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetDemoWorkflowClinicAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoWorkflowClinicAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoWorkflowClinicAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDemoWorkflowClinicAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDemoWorkflowClinicAppointmentsQuery, GetDemoWorkflowClinicAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemoWorkflowClinicAppointmentsQuery, GetDemoWorkflowClinicAppointmentsQueryVariables>(GetDemoWorkflowClinicAppointmentsDocument, options);
      }
export function useGetDemoWorkflowClinicAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoWorkflowClinicAppointmentsQuery, GetDemoWorkflowClinicAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemoWorkflowClinicAppointmentsQuery, GetDemoWorkflowClinicAppointmentsQueryVariables>(GetDemoWorkflowClinicAppointmentsDocument, options);
        }
export type GetDemoWorkflowClinicAppointmentsQueryHookResult = ReturnType<typeof useGetDemoWorkflowClinicAppointmentsQuery>;
export type GetDemoWorkflowClinicAppointmentsLazyQueryHookResult = ReturnType<typeof useGetDemoWorkflowClinicAppointmentsLazyQuery>;
export type GetDemoWorkflowClinicAppointmentsQueryResult = Apollo.QueryResult<GetDemoWorkflowClinicAppointmentsQuery, GetDemoWorkflowClinicAppointmentsQueryVariables>;
export function refetchGetDemoWorkflowClinicAppointmentsQuery(variables?: GetDemoWorkflowClinicAppointmentsQueryVariables) {
      return { query: GetDemoWorkflowClinicAppointmentsDocument, variables: variables }
    }
export const SoftDeleteFormsMenuFormTemplateDocument = gql`
    mutation softDeleteFormsMenuFormTemplate($where: FormTemplateWhereUniqueInput!) {
  updateOneFormTemplate(where: $where, data: {isDeleted: true, isActive: false}) {
    id
  }
}
    `;
export type SoftDeleteFormsMenuFormTemplateMutationFn = Apollo.MutationFunction<SoftDeleteFormsMenuFormTemplateMutation, SoftDeleteFormsMenuFormTemplateMutationVariables>;

/**
 * __useSoftDeleteFormsMenuFormTemplateMutation__
 *
 * To run a mutation, you first call `useSoftDeleteFormsMenuFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteFormsMenuFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteFormsMenuFormTemplateMutation, { data, loading, error }] = useSoftDeleteFormsMenuFormTemplateMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSoftDeleteFormsMenuFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteFormsMenuFormTemplateMutation, SoftDeleteFormsMenuFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteFormsMenuFormTemplateMutation, SoftDeleteFormsMenuFormTemplateMutationVariables>(SoftDeleteFormsMenuFormTemplateDocument, options);
      }
export type SoftDeleteFormsMenuFormTemplateMutationHookResult = ReturnType<typeof useSoftDeleteFormsMenuFormTemplateMutation>;
export type SoftDeleteFormsMenuFormTemplateMutationResult = Apollo.MutationResult<SoftDeleteFormsMenuFormTemplateMutation>;
export type SoftDeleteFormsMenuFormTemplateMutationOptions = Apollo.BaseMutationOptions<SoftDeleteFormsMenuFormTemplateMutation, SoftDeleteFormsMenuFormTemplateMutationVariables>;
export const CreateFormTemplateDocument = gql`
    mutation createFormTemplate($clinicId: String!, $title: String!, $content: Json) {
  createOneFormTemplate(
    data: {clinic: {connect: {id: $clinicId}}, title: $title, content: $content}
  ) {
    id
  }
}
    `;
export type CreateFormTemplateMutationFn = Apollo.MutationFunction<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>;

/**
 * __useCreateFormTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateMutation, { data, loading, error }] = useCreateFormTemplateMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>(CreateFormTemplateDocument, options);
      }
export type CreateFormTemplateMutationHookResult = ReturnType<typeof useCreateFormTemplateMutation>;
export type CreateFormTemplateMutationResult = Apollo.MutationResult<CreateFormTemplateMutation>;
export type CreateFormTemplateMutationOptions = Apollo.BaseMutationOptions<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>;
export const ImportFormTemplateDocument = gql`
    mutation importFormTemplate($data: FormTemplateCreateInput!) {
  createOneFormTemplate(data: $data) {
    id
  }
}
    `;
export type ImportFormTemplateMutationFn = Apollo.MutationFunction<ImportFormTemplateMutation, ImportFormTemplateMutationVariables>;

/**
 * __useImportFormTemplateMutation__
 *
 * To run a mutation, you first call `useImportFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importFormTemplateMutation, { data, loading, error }] = useImportFormTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ImportFormTemplateMutation, ImportFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportFormTemplateMutation, ImportFormTemplateMutationVariables>(ImportFormTemplateDocument, options);
      }
export type ImportFormTemplateMutationHookResult = ReturnType<typeof useImportFormTemplateMutation>;
export type ImportFormTemplateMutationResult = Apollo.MutationResult<ImportFormTemplateMutation>;
export type ImportFormTemplateMutationOptions = Apollo.BaseMutationOptions<ImportFormTemplateMutation, ImportFormTemplateMutationVariables>;
export const ToggleActiveFormTemplateDocument = gql`
    mutation toggleActiveFormTemplate($formId: String!, $isActive: Boolean!) {
  updateOneFormTemplate(where: {id: $formId}, data: {isActive: $isActive}) {
    id
    isActive
  }
}
    `;
export type ToggleActiveFormTemplateMutationFn = Apollo.MutationFunction<ToggleActiveFormTemplateMutation, ToggleActiveFormTemplateMutationVariables>;

/**
 * __useToggleActiveFormTemplateMutation__
 *
 * To run a mutation, you first call `useToggleActiveFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveFormTemplateMutation, { data, loading, error }] = useToggleActiveFormTemplateMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useToggleActiveFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveFormTemplateMutation, ToggleActiveFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveFormTemplateMutation, ToggleActiveFormTemplateMutationVariables>(ToggleActiveFormTemplateDocument, options);
      }
export type ToggleActiveFormTemplateMutationHookResult = ReturnType<typeof useToggleActiveFormTemplateMutation>;
export type ToggleActiveFormTemplateMutationResult = Apollo.MutationResult<ToggleActiveFormTemplateMutation>;
export type ToggleActiveFormTemplateMutationOptions = Apollo.BaseMutationOptions<ToggleActiveFormTemplateMutation, ToggleActiveFormTemplateMutationVariables>;
export const UpdateFormTemplateDocument = gql`
    mutation updateFormTemplate($where: FormTemplateWhereUniqueInput!, $data: FormTemplateUpdateInput!) {
  updateOneFormTemplate(where: $where, data: $data) {
    ...ClinicFormTemplate
  }
}
    ${ClinicFormTemplateFragmentDoc}`;
export type UpdateFormTemplateMutationFn = Apollo.MutationFunction<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>;

/**
 * __useUpdateFormTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateMutation, { data, loading, error }] = useUpdateFormTemplateMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>(UpdateFormTemplateDocument, options);
      }
export type UpdateFormTemplateMutationHookResult = ReturnType<typeof useUpdateFormTemplateMutation>;
export type UpdateFormTemplateMutationResult = Apollo.MutationResult<UpdateFormTemplateMutation>;
export type UpdateFormTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>;
export const AcceptUserInvitationDocument = gql`
    mutation acceptUserInvitation($data: AcceptUserInvitationInput!) {
  acceptUserInvitation: acceptUserInvitation2(data: $data) {
    token
  }
}
    `;
export type AcceptUserInvitationMutationFn = Apollo.MutationFunction<AcceptUserInvitationMutation, AcceptUserInvitationMutationVariables>;

/**
 * __useAcceptUserInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptUserInvitationMutation, { data, loading, error }] = useAcceptUserInvitationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcceptUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptUserInvitationMutation, AcceptUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptUserInvitationMutation, AcceptUserInvitationMutationVariables>(AcceptUserInvitationDocument, options);
      }
export type AcceptUserInvitationMutationHookResult = ReturnType<typeof useAcceptUserInvitationMutation>;
export type AcceptUserInvitationMutationResult = Apollo.MutationResult<AcceptUserInvitationMutation>;
export type AcceptUserInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptUserInvitationMutation, AcceptUserInvitationMutationVariables>;
export const CreateLoyaltyProgramRewardDocument = gql`
    mutation createLoyaltyProgramReward($data: LoyaltyRewardCreateInput!) {
  createOneLoyaltyReward(data: $data) {
    id
  }
}
    `;
export type CreateLoyaltyProgramRewardMutationFn = Apollo.MutationFunction<CreateLoyaltyProgramRewardMutation, CreateLoyaltyProgramRewardMutationVariables>;

/**
 * __useCreateLoyaltyProgramRewardMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyProgramRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyProgramRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyProgramRewardMutation, { data, loading, error }] = useCreateLoyaltyProgramRewardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyProgramRewardMutation(baseOptions?: Apollo.MutationHookOptions<CreateLoyaltyProgramRewardMutation, CreateLoyaltyProgramRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLoyaltyProgramRewardMutation, CreateLoyaltyProgramRewardMutationVariables>(CreateLoyaltyProgramRewardDocument, options);
      }
export type CreateLoyaltyProgramRewardMutationHookResult = ReturnType<typeof useCreateLoyaltyProgramRewardMutation>;
export type CreateLoyaltyProgramRewardMutationResult = Apollo.MutationResult<CreateLoyaltyProgramRewardMutation>;
export type CreateLoyaltyProgramRewardMutationOptions = Apollo.BaseMutationOptions<CreateLoyaltyProgramRewardMutation, CreateLoyaltyProgramRewardMutationVariables>;
export const FindOrCreateLoyaltyProgramDocument = gql`
    mutation findOrCreateLoyaltyProgram {
  findOrCreateLoyaltyProgram {
    id
    status
    centsPerPoint
    allowSpendCarryOver
    minimumTransactionCents
    description
  }
}
    `;
export type FindOrCreateLoyaltyProgramMutationFn = Apollo.MutationFunction<FindOrCreateLoyaltyProgramMutation, FindOrCreateLoyaltyProgramMutationVariables>;

/**
 * __useFindOrCreateLoyaltyProgramMutation__
 *
 * To run a mutation, you first call `useFindOrCreateLoyaltyProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateLoyaltyProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateLoyaltyProgramMutation, { data, loading, error }] = useFindOrCreateLoyaltyProgramMutation({
 *   variables: {
 *   },
 * });
 */
export function useFindOrCreateLoyaltyProgramMutation(baseOptions?: Apollo.MutationHookOptions<FindOrCreateLoyaltyProgramMutation, FindOrCreateLoyaltyProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindOrCreateLoyaltyProgramMutation, FindOrCreateLoyaltyProgramMutationVariables>(FindOrCreateLoyaltyProgramDocument, options);
      }
export type FindOrCreateLoyaltyProgramMutationHookResult = ReturnType<typeof useFindOrCreateLoyaltyProgramMutation>;
export type FindOrCreateLoyaltyProgramMutationResult = Apollo.MutationResult<FindOrCreateLoyaltyProgramMutation>;
export type FindOrCreateLoyaltyProgramMutationOptions = Apollo.BaseMutationOptions<FindOrCreateLoyaltyProgramMutation, FindOrCreateLoyaltyProgramMutationVariables>;
export const UpdateLoyaltyProgramRewardDocument = gql`
    mutation updateLoyaltyProgramReward($data: LoyaltyRewardUpdateInput!, $where: LoyaltyRewardWhereUniqueInput!) {
  updateOneLoyaltyReward(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateLoyaltyProgramRewardMutationFn = Apollo.MutationFunction<UpdateLoyaltyProgramRewardMutation, UpdateLoyaltyProgramRewardMutationVariables>;

/**
 * __useUpdateLoyaltyProgramRewardMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyProgramRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyProgramRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyProgramRewardMutation, { data, loading, error }] = useUpdateLoyaltyProgramRewardMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLoyaltyProgramRewardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoyaltyProgramRewardMutation, UpdateLoyaltyProgramRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoyaltyProgramRewardMutation, UpdateLoyaltyProgramRewardMutationVariables>(UpdateLoyaltyProgramRewardDocument, options);
      }
export type UpdateLoyaltyProgramRewardMutationHookResult = ReturnType<typeof useUpdateLoyaltyProgramRewardMutation>;
export type UpdateLoyaltyProgramRewardMutationResult = Apollo.MutationResult<UpdateLoyaltyProgramRewardMutation>;
export type UpdateLoyaltyProgramRewardMutationOptions = Apollo.BaseMutationOptions<UpdateLoyaltyProgramRewardMutation, UpdateLoyaltyProgramRewardMutationVariables>;
export const UpdateLoyaltyProgramRewardsAvailabilityDocument = gql`
    mutation updateLoyaltyProgramRewardsAvailability($data: LoyaltyRewardUpdateManyMutationInput!, $where: LoyaltyRewardWhereInput!) {
  updateManyLoyaltyReward(data: $data, where: $where) {
    count
  }
}
    `;
export type UpdateLoyaltyProgramRewardsAvailabilityMutationFn = Apollo.MutationFunction<UpdateLoyaltyProgramRewardsAvailabilityMutation, UpdateLoyaltyProgramRewardsAvailabilityMutationVariables>;

/**
 * __useUpdateLoyaltyProgramRewardsAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyProgramRewardsAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyProgramRewardsAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyProgramRewardsAvailabilityMutation, { data, loading, error }] = useUpdateLoyaltyProgramRewardsAvailabilityMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLoyaltyProgramRewardsAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoyaltyProgramRewardsAvailabilityMutation, UpdateLoyaltyProgramRewardsAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoyaltyProgramRewardsAvailabilityMutation, UpdateLoyaltyProgramRewardsAvailabilityMutationVariables>(UpdateLoyaltyProgramRewardsAvailabilityDocument, options);
      }
export type UpdateLoyaltyProgramRewardsAvailabilityMutationHookResult = ReturnType<typeof useUpdateLoyaltyProgramRewardsAvailabilityMutation>;
export type UpdateLoyaltyProgramRewardsAvailabilityMutationResult = Apollo.MutationResult<UpdateLoyaltyProgramRewardsAvailabilityMutation>;
export type UpdateLoyaltyProgramRewardsAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpdateLoyaltyProgramRewardsAvailabilityMutation, UpdateLoyaltyProgramRewardsAvailabilityMutationVariables>;
export const UpdateLoyaltyProgramSettingsDocument = gql`
    mutation updateLoyaltyProgramSettings($data: LoyaltyProgramUpdateInput!, $where: LoyaltyProgramWhereUniqueInput!) {
  updateOneLoyaltyProgram(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateLoyaltyProgramSettingsMutationFn = Apollo.MutationFunction<UpdateLoyaltyProgramSettingsMutation, UpdateLoyaltyProgramSettingsMutationVariables>;

/**
 * __useUpdateLoyaltyProgramSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyProgramSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyProgramSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyProgramSettingsMutation, { data, loading, error }] = useUpdateLoyaltyProgramSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLoyaltyProgramSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoyaltyProgramSettingsMutation, UpdateLoyaltyProgramSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoyaltyProgramSettingsMutation, UpdateLoyaltyProgramSettingsMutationVariables>(UpdateLoyaltyProgramSettingsDocument, options);
      }
export type UpdateLoyaltyProgramSettingsMutationHookResult = ReturnType<typeof useUpdateLoyaltyProgramSettingsMutation>;
export type UpdateLoyaltyProgramSettingsMutationResult = Apollo.MutationResult<UpdateLoyaltyProgramSettingsMutation>;
export type UpdateLoyaltyProgramSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateLoyaltyProgramSettingsMutation, UpdateLoyaltyProgramSettingsMutationVariables>;
export const UpdateOneLoyaltyInvoicePointGranterDocument = gql`
    mutation updateOneLoyaltyInvoicePointGranter($data: LoyaltyInvoicePointGranterUpdateInput!, $where: LoyaltyInvoicePointGranterWhereUniqueInput!) {
  updateOneLoyaltyInvoicePointGranter(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneLoyaltyInvoicePointGranterMutationFn = Apollo.MutationFunction<UpdateOneLoyaltyInvoicePointGranterMutation, UpdateOneLoyaltyInvoicePointGranterMutationVariables>;

/**
 * __useUpdateOneLoyaltyInvoicePointGranterMutation__
 *
 * To run a mutation, you first call `useUpdateOneLoyaltyInvoicePointGranterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneLoyaltyInvoicePointGranterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneLoyaltyInvoicePointGranterMutation, { data, loading, error }] = useUpdateOneLoyaltyInvoicePointGranterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneLoyaltyInvoicePointGranterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneLoyaltyInvoicePointGranterMutation, UpdateOneLoyaltyInvoicePointGranterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneLoyaltyInvoicePointGranterMutation, UpdateOneLoyaltyInvoicePointGranterMutationVariables>(UpdateOneLoyaltyInvoicePointGranterDocument, options);
      }
export type UpdateOneLoyaltyInvoicePointGranterMutationHookResult = ReturnType<typeof useUpdateOneLoyaltyInvoicePointGranterMutation>;
export type UpdateOneLoyaltyInvoicePointGranterMutationResult = Apollo.MutationResult<UpdateOneLoyaltyInvoicePointGranterMutation>;
export type UpdateOneLoyaltyInvoicePointGranterMutationOptions = Apollo.BaseMutationOptions<UpdateOneLoyaltyInvoicePointGranterMutation, UpdateOneLoyaltyInvoicePointGranterMutationVariables>;
export const GetLoyaltyProgramBasicInfoDocument = gql`
    query getLoyaltyProgramBasicInfo($where: LoyaltyProgramWhereInput) {
  findFirstLoyaltyProgram(where: $where) {
    id
    status
  }
}
    `;

/**
 * __useGetLoyaltyProgramBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyProgramBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyProgramBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyProgramBasicInfoQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoyaltyProgramBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLoyaltyProgramBasicInfoQuery, GetLoyaltyProgramBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyProgramBasicInfoQuery, GetLoyaltyProgramBasicInfoQueryVariables>(GetLoyaltyProgramBasicInfoDocument, options);
      }
export function useGetLoyaltyProgramBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyProgramBasicInfoQuery, GetLoyaltyProgramBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyProgramBasicInfoQuery, GetLoyaltyProgramBasicInfoQueryVariables>(GetLoyaltyProgramBasicInfoDocument, options);
        }
export type GetLoyaltyProgramBasicInfoQueryHookResult = ReturnType<typeof useGetLoyaltyProgramBasicInfoQuery>;
export type GetLoyaltyProgramBasicInfoLazyQueryHookResult = ReturnType<typeof useGetLoyaltyProgramBasicInfoLazyQuery>;
export type GetLoyaltyProgramBasicInfoQueryResult = Apollo.QueryResult<GetLoyaltyProgramBasicInfoQuery, GetLoyaltyProgramBasicInfoQueryVariables>;
export function refetchGetLoyaltyProgramBasicInfoQuery(variables?: GetLoyaltyProgramBasicInfoQueryVariables) {
      return { query: GetLoyaltyProgramBasicInfoDocument, variables: variables }
    }
export const GetLoyaltyProgramPointGrantingServicesDocument = gql`
    query getLoyaltyProgramPointGrantingServices($where: LoyaltyProgramWhereInput) {
  findFirstLoyaltyProgram(where: $where) {
    ...PointGranterService
  }
}
    ${PointGranterServiceFragmentDoc}`;

/**
 * __useGetLoyaltyProgramPointGrantingServicesQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyProgramPointGrantingServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyProgramPointGrantingServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyProgramPointGrantingServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoyaltyProgramPointGrantingServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoyaltyProgramPointGrantingServicesQuery, GetLoyaltyProgramPointGrantingServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyProgramPointGrantingServicesQuery, GetLoyaltyProgramPointGrantingServicesQueryVariables>(GetLoyaltyProgramPointGrantingServicesDocument, options);
      }
export function useGetLoyaltyProgramPointGrantingServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyProgramPointGrantingServicesQuery, GetLoyaltyProgramPointGrantingServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyProgramPointGrantingServicesQuery, GetLoyaltyProgramPointGrantingServicesQueryVariables>(GetLoyaltyProgramPointGrantingServicesDocument, options);
        }
export type GetLoyaltyProgramPointGrantingServicesQueryHookResult = ReturnType<typeof useGetLoyaltyProgramPointGrantingServicesQuery>;
export type GetLoyaltyProgramPointGrantingServicesLazyQueryHookResult = ReturnType<typeof useGetLoyaltyProgramPointGrantingServicesLazyQuery>;
export type GetLoyaltyProgramPointGrantingServicesQueryResult = Apollo.QueryResult<GetLoyaltyProgramPointGrantingServicesQuery, GetLoyaltyProgramPointGrantingServicesQueryVariables>;
export function refetchGetLoyaltyProgramPointGrantingServicesQuery(variables?: GetLoyaltyProgramPointGrantingServicesQueryVariables) {
      return { query: GetLoyaltyProgramPointGrantingServicesDocument, variables: variables }
    }
export const GetLoyaltyProgramRewardsDocument = gql`
    query getLoyaltyProgramRewards($where: LoyaltyRewardWhereInput, $orderBy: [LoyaltyRewardOrderByWithRelationAndSearchRelevanceInput], $take: Int, $skip: Int) {
  findManyLoyaltyReward(
    where: $where
    orderBy: $orderBy
    take: $take
    skip: $skip
  ) {
    ...LoyaltyProgramReward
  }
  findManyLoyaltyRewardCount(where: $where)
}
    ${LoyaltyProgramRewardFragmentDoc}`;

/**
 * __useGetLoyaltyProgramRewardsQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyProgramRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyProgramRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyProgramRewardsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetLoyaltyProgramRewardsQuery(baseOptions?: Apollo.QueryHookOptions<GetLoyaltyProgramRewardsQuery, GetLoyaltyProgramRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyProgramRewardsQuery, GetLoyaltyProgramRewardsQueryVariables>(GetLoyaltyProgramRewardsDocument, options);
      }
export function useGetLoyaltyProgramRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyProgramRewardsQuery, GetLoyaltyProgramRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyProgramRewardsQuery, GetLoyaltyProgramRewardsQueryVariables>(GetLoyaltyProgramRewardsDocument, options);
        }
export type GetLoyaltyProgramRewardsQueryHookResult = ReturnType<typeof useGetLoyaltyProgramRewardsQuery>;
export type GetLoyaltyProgramRewardsLazyQueryHookResult = ReturnType<typeof useGetLoyaltyProgramRewardsLazyQuery>;
export type GetLoyaltyProgramRewardsQueryResult = Apollo.QueryResult<GetLoyaltyProgramRewardsQuery, GetLoyaltyProgramRewardsQueryVariables>;
export function refetchGetLoyaltyProgramRewardsQuery(variables?: GetLoyaltyProgramRewardsQueryVariables) {
      return { query: GetLoyaltyProgramRewardsDocument, variables: variables }
    }
export const GetServicesByIntegrationIdDocument = gql`
    query getServicesByIntegrationId($where: ServiceWhereInput, $orderBy: [ServiceOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  findManyService(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    ...ServiceFromIntegrationId
  }
  findManyServiceCount(where: $where)
}
    ${ServiceFromIntegrationIdFragmentDoc}`;

/**
 * __useGetServicesByIntegrationIdQuery__
 *
 * To run a query within a React component, call `useGetServicesByIntegrationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesByIntegrationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesByIntegrationIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetServicesByIntegrationIdQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesByIntegrationIdQuery, GetServicesByIntegrationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesByIntegrationIdQuery, GetServicesByIntegrationIdQueryVariables>(GetServicesByIntegrationIdDocument, options);
      }
export function useGetServicesByIntegrationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesByIntegrationIdQuery, GetServicesByIntegrationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesByIntegrationIdQuery, GetServicesByIntegrationIdQueryVariables>(GetServicesByIntegrationIdDocument, options);
        }
export type GetServicesByIntegrationIdQueryHookResult = ReturnType<typeof useGetServicesByIntegrationIdQuery>;
export type GetServicesByIntegrationIdLazyQueryHookResult = ReturnType<typeof useGetServicesByIntegrationIdLazyQuery>;
export type GetServicesByIntegrationIdQueryResult = Apollo.QueryResult<GetServicesByIntegrationIdQuery, GetServicesByIntegrationIdQueryVariables>;
export function refetchGetServicesByIntegrationIdQuery(variables?: GetServicesByIntegrationIdQueryVariables) {
      return { query: GetServicesByIntegrationIdDocument, variables: variables }
    }
export const GetServicesIdsByIntegrationIdDocument = gql`
    query getServicesIdsByIntegrationId($where: ServiceWhereInput) {
  servicesIds: findManyService(where: $where) {
    id
  }
}
    `;

/**
 * __useGetServicesIdsByIntegrationIdQuery__
 *
 * To run a query within a React component, call `useGetServicesIdsByIntegrationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesIdsByIntegrationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesIdsByIntegrationIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetServicesIdsByIntegrationIdQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesIdsByIntegrationIdQuery, GetServicesIdsByIntegrationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesIdsByIntegrationIdQuery, GetServicesIdsByIntegrationIdQueryVariables>(GetServicesIdsByIntegrationIdDocument, options);
      }
export function useGetServicesIdsByIntegrationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesIdsByIntegrationIdQuery, GetServicesIdsByIntegrationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesIdsByIntegrationIdQuery, GetServicesIdsByIntegrationIdQueryVariables>(GetServicesIdsByIntegrationIdDocument, options);
        }
export type GetServicesIdsByIntegrationIdQueryHookResult = ReturnType<typeof useGetServicesIdsByIntegrationIdQuery>;
export type GetServicesIdsByIntegrationIdLazyQueryHookResult = ReturnType<typeof useGetServicesIdsByIntegrationIdLazyQuery>;
export type GetServicesIdsByIntegrationIdQueryResult = Apollo.QueryResult<GetServicesIdsByIntegrationIdQuery, GetServicesIdsByIntegrationIdQueryVariables>;
export function refetchGetServicesIdsByIntegrationIdQuery(variables?: GetServicesIdsByIntegrationIdQueryVariables) {
      return { query: GetServicesIdsByIntegrationIdDocument, variables: variables }
    }
export const ArchiveMessageTemplateDocument = gql`
    mutation archiveMessageTemplate($messageTemplateId: String!, $isArchived: Boolean!) {
  updateOneMessageTemplate(
    where: {id: $messageTemplateId}
    data: {isArchived: $isArchived}
  ) {
    id
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
    isArchived
  }
}
    `;
export type ArchiveMessageTemplateMutationFn = Apollo.MutationFunction<ArchiveMessageTemplateMutation, ArchiveMessageTemplateMutationVariables>;

/**
 * __useArchiveMessageTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMessageTemplateMutation, { data, loading, error }] = useArchiveMessageTemplateMutation({
 *   variables: {
 *      messageTemplateId: // value for 'messageTemplateId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useArchiveMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveMessageTemplateMutation, ArchiveMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveMessageTemplateMutation, ArchiveMessageTemplateMutationVariables>(ArchiveMessageTemplateDocument, options);
      }
export type ArchiveMessageTemplateMutationHookResult = ReturnType<typeof useArchiveMessageTemplateMutation>;
export type ArchiveMessageTemplateMutationResult = Apollo.MutationResult<ArchiveMessageTemplateMutation>;
export type ArchiveMessageTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveMessageTemplateMutation, ArchiveMessageTemplateMutationVariables>;
export const CreateMessageTemplateDocument = gql`
    mutation createMessageTemplate($data: MessageTemplateCreateInput!) {
  createOneMessageTemplate(data: $data) {
    ...FullMessageTemplate
  }
}
    ${FullMessageTemplateFragmentDoc}`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CreateMessageTemplateDocument, options);
      }
export type CreateMessageTemplateMutationHookResult = ReturnType<typeof useCreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationResult = Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;
export const SaveMessageTemplateDocument = gql`
    mutation saveMessageTemplate($where: MessageTemplateWhereUniqueInput!, $data: MessageTemplateUpdateInput!) {
  updateOneMessageTemplate(where: $where, data: $data) {
    id
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
    name
    body
    isArchived
  }
}
    `;
export type SaveMessageTemplateMutationFn = Apollo.MutationFunction<SaveMessageTemplateMutation, SaveMessageTemplateMutationVariables>;

/**
 * __useSaveMessageTemplateMutation__
 *
 * To run a mutation, you first call `useSaveMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMessageTemplateMutation, { data, loading, error }] = useSaveMessageTemplateMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SaveMessageTemplateMutation, SaveMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMessageTemplateMutation, SaveMessageTemplateMutationVariables>(SaveMessageTemplateDocument, options);
      }
export type SaveMessageTemplateMutationHookResult = ReturnType<typeof useSaveMessageTemplateMutation>;
export type SaveMessageTemplateMutationResult = Apollo.MutationResult<SaveMessageTemplateMutation>;
export type SaveMessageTemplateMutationOptions = Apollo.BaseMutationOptions<SaveMessageTemplateMutation, SaveMessageTemplateMutationVariables>;
export const GetMessageTemplatesDocument = gql`
    query getMessageTemplates($where: MessageTemplateWhereInput, $orderBy: [MessageTemplateOrderByWithRelationAndSearchRelevanceInput!]) {
  findManyMessageTemplate(where: $where, orderBy: $orderBy) {
    ...FullMessageTemplate
  }
}
    ${FullMessageTemplateFragmentDoc}`;

/**
 * __useGetMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMessageTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
      }
export function useGetMessageTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>(GetMessageTemplatesDocument, options);
        }
export type GetMessageTemplatesQueryHookResult = ReturnType<typeof useGetMessageTemplatesQuery>;
export type GetMessageTemplatesLazyQueryHookResult = ReturnType<typeof useGetMessageTemplatesLazyQuery>;
export type GetMessageTemplatesQueryResult = Apollo.QueryResult<GetMessageTemplatesQuery, GetMessageTemplatesQueryVariables>;
export function refetchGetMessageTemplatesQuery(variables?: GetMessageTemplatesQueryVariables) {
      return { query: GetMessageTemplatesDocument, variables: variables }
    }
export const CreateOneEmailCampaignDocument = gql`
    mutation createOneEmailCampaign($data: EmailCampaignCreateInput!) {
  createOneEmailCampaign(data: $data) {
    ...EmailCampaign
  }
}
    ${EmailCampaignFragmentDoc}`;
export type CreateOneEmailCampaignMutationFn = Apollo.MutationFunction<CreateOneEmailCampaignMutation, CreateOneEmailCampaignMutationVariables>;

/**
 * __useCreateOneEmailCampaignMutation__
 *
 * To run a mutation, you first call `useCreateOneEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneEmailCampaignMutation, { data, loading, error }] = useCreateOneEmailCampaignMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneEmailCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneEmailCampaignMutation, CreateOneEmailCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneEmailCampaignMutation, CreateOneEmailCampaignMutationVariables>(CreateOneEmailCampaignDocument, options);
      }
export type CreateOneEmailCampaignMutationHookResult = ReturnType<typeof useCreateOneEmailCampaignMutation>;
export type CreateOneEmailCampaignMutationResult = Apollo.MutationResult<CreateOneEmailCampaignMutation>;
export type CreateOneEmailCampaignMutationOptions = Apollo.BaseMutationOptions<CreateOneEmailCampaignMutation, CreateOneEmailCampaignMutationVariables>;
export const DeleteEmailCampaignCustomListDocument = gql`
    mutation deleteEmailCampaignCustomList($data: DeleteEmailCampaignCustomListInput!) {
  deleteEmailCampaignCustomList(data: $data) {
    id
  }
}
    `;
export type DeleteEmailCampaignCustomListMutationFn = Apollo.MutationFunction<DeleteEmailCampaignCustomListMutation, DeleteEmailCampaignCustomListMutationVariables>;

/**
 * __useDeleteEmailCampaignCustomListMutation__
 *
 * To run a mutation, you first call `useDeleteEmailCampaignCustomListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailCampaignCustomListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailCampaignCustomListMutation, { data, loading, error }] = useDeleteEmailCampaignCustomListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteEmailCampaignCustomListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailCampaignCustomListMutation, DeleteEmailCampaignCustomListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailCampaignCustomListMutation, DeleteEmailCampaignCustomListMutationVariables>(DeleteEmailCampaignCustomListDocument, options);
      }
export type DeleteEmailCampaignCustomListMutationHookResult = ReturnType<typeof useDeleteEmailCampaignCustomListMutation>;
export type DeleteEmailCampaignCustomListMutationResult = Apollo.MutationResult<DeleteEmailCampaignCustomListMutation>;
export type DeleteEmailCampaignCustomListMutationOptions = Apollo.BaseMutationOptions<DeleteEmailCampaignCustomListMutation, DeleteEmailCampaignCustomListMutationVariables>;
export const DuplicateEmailCampaignDocument = gql`
    mutation duplicateEmailCampaign($data: DuplicateEmailCampaignInput!) {
  duplicateEmailCampaign(data: $data) {
    id
  }
}
    `;
export type DuplicateEmailCampaignMutationFn = Apollo.MutationFunction<DuplicateEmailCampaignMutation, DuplicateEmailCampaignMutationVariables>;

/**
 * __useDuplicateEmailCampaignMutation__
 *
 * To run a mutation, you first call `useDuplicateEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateEmailCampaignMutation, { data, loading, error }] = useDuplicateEmailCampaignMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDuplicateEmailCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateEmailCampaignMutation, DuplicateEmailCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateEmailCampaignMutation, DuplicateEmailCampaignMutationVariables>(DuplicateEmailCampaignDocument, options);
      }
export type DuplicateEmailCampaignMutationHookResult = ReturnType<typeof useDuplicateEmailCampaignMutation>;
export type DuplicateEmailCampaignMutationResult = Apollo.MutationResult<DuplicateEmailCampaignMutation>;
export type DuplicateEmailCampaignMutationOptions = Apollo.BaseMutationOptions<DuplicateEmailCampaignMutation, DuplicateEmailCampaignMutationVariables>;
export const FindManyAppointmentTypeDocument = gql`
    query findManyAppointmentType($clinicId: String!) {
  findManyAppointmentType(
    where: {clinic: {id: {equals: $clinicId}}, isVisible: {equals: true}}
    orderBy: {displayOrder: asc}
  ) {
    ...AppointmentTypeWorkflowsSelection
  }
}
    ${AppointmentTypeWorkflowsSelectionFragmentDoc}`;

/**
 * __useFindManyAppointmentTypeQuery__
 *
 * To run a query within a React component, call `useFindManyAppointmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAppointmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAppointmentTypeQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useFindManyAppointmentTypeQuery(baseOptions: Apollo.QueryHookOptions<FindManyAppointmentTypeQuery, FindManyAppointmentTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyAppointmentTypeQuery, FindManyAppointmentTypeQueryVariables>(FindManyAppointmentTypeDocument, options);
      }
export function useFindManyAppointmentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyAppointmentTypeQuery, FindManyAppointmentTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyAppointmentTypeQuery, FindManyAppointmentTypeQueryVariables>(FindManyAppointmentTypeDocument, options);
        }
export type FindManyAppointmentTypeQueryHookResult = ReturnType<typeof useFindManyAppointmentTypeQuery>;
export type FindManyAppointmentTypeLazyQueryHookResult = ReturnType<typeof useFindManyAppointmentTypeLazyQuery>;
export type FindManyAppointmentTypeQueryResult = Apollo.QueryResult<FindManyAppointmentTypeQuery, FindManyAppointmentTypeQueryVariables>;
export function refetchFindManyAppointmentTypeQuery(variables?: FindManyAppointmentTypeQueryVariables) {
      return { query: FindManyAppointmentTypeDocument, variables: variables }
    }
export const FindManyClinicPetParentCountDocument = gql`
    query findManyClinicPetParentCount($where: ClinicPetParentWhereInput!) {
  findManyClinicPetParentCount(where: $where)
}
    `;

/**
 * __useFindManyClinicPetParentCountQuery__
 *
 * To run a query within a React component, call `useFindManyClinicPetParentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyClinicPetParentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyClinicPetParentCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyClinicPetParentCountQuery(baseOptions: Apollo.QueryHookOptions<FindManyClinicPetParentCountQuery, FindManyClinicPetParentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyClinicPetParentCountQuery, FindManyClinicPetParentCountQueryVariables>(FindManyClinicPetParentCountDocument, options);
      }
export function useFindManyClinicPetParentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyClinicPetParentCountQuery, FindManyClinicPetParentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyClinicPetParentCountQuery, FindManyClinicPetParentCountQueryVariables>(FindManyClinicPetParentCountDocument, options);
        }
export type FindManyClinicPetParentCountQueryHookResult = ReturnType<typeof useFindManyClinicPetParentCountQuery>;
export type FindManyClinicPetParentCountLazyQueryHookResult = ReturnType<typeof useFindManyClinicPetParentCountLazyQuery>;
export type FindManyClinicPetParentCountQueryResult = Apollo.QueryResult<FindManyClinicPetParentCountQuery, FindManyClinicPetParentCountQueryVariables>;
export function refetchFindManyClinicPetParentCountQuery(variables?: FindManyClinicPetParentCountQueryVariables) {
      return { query: FindManyClinicPetParentCountDocument, variables: variables }
    }
export const FindManyEmailCampaignDocument = gql`
    query findManyEmailCampaign($where: EmailCampaignWhereInput) {
  findManyEmailCampaign(where: $where) {
    ...EmailCampaign
  }
}
    ${EmailCampaignFragmentDoc}`;

/**
 * __useFindManyEmailCampaignQuery__
 *
 * To run a query within a React component, call `useFindManyEmailCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyEmailCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyEmailCampaignQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyEmailCampaignQuery(baseOptions?: Apollo.QueryHookOptions<FindManyEmailCampaignQuery, FindManyEmailCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyEmailCampaignQuery, FindManyEmailCampaignQueryVariables>(FindManyEmailCampaignDocument, options);
      }
export function useFindManyEmailCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyEmailCampaignQuery, FindManyEmailCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyEmailCampaignQuery, FindManyEmailCampaignQueryVariables>(FindManyEmailCampaignDocument, options);
        }
export type FindManyEmailCampaignQueryHookResult = ReturnType<typeof useFindManyEmailCampaignQuery>;
export type FindManyEmailCampaignLazyQueryHookResult = ReturnType<typeof useFindManyEmailCampaignLazyQuery>;
export type FindManyEmailCampaignQueryResult = Apollo.QueryResult<FindManyEmailCampaignQuery, FindManyEmailCampaignQueryVariables>;
export function refetchFindManyEmailCampaignQuery(variables?: FindManyEmailCampaignQueryVariables) {
      return { query: FindManyEmailCampaignDocument, variables: variables }
    }
export const FindManyEmailCampaignTemplateDocument = gql`
    query findManyEmailCampaignTemplate($where: EmailCampaignTemplateWhereInput!) {
  findManyEmailCampaignTemplate(where: $where) {
    ...EmailCampaignTemplate
  }
}
    ${EmailCampaignTemplateFragmentDoc}`;

/**
 * __useFindManyEmailCampaignTemplateQuery__
 *
 * To run a query within a React component, call `useFindManyEmailCampaignTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyEmailCampaignTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyEmailCampaignTemplateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyEmailCampaignTemplateQuery(baseOptions: Apollo.QueryHookOptions<FindManyEmailCampaignTemplateQuery, FindManyEmailCampaignTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyEmailCampaignTemplateQuery, FindManyEmailCampaignTemplateQueryVariables>(FindManyEmailCampaignTemplateDocument, options);
      }
export function useFindManyEmailCampaignTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyEmailCampaignTemplateQuery, FindManyEmailCampaignTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyEmailCampaignTemplateQuery, FindManyEmailCampaignTemplateQueryVariables>(FindManyEmailCampaignTemplateDocument, options);
        }
export type FindManyEmailCampaignTemplateQueryHookResult = ReturnType<typeof useFindManyEmailCampaignTemplateQuery>;
export type FindManyEmailCampaignTemplateLazyQueryHookResult = ReturnType<typeof useFindManyEmailCampaignTemplateLazyQuery>;
export type FindManyEmailCampaignTemplateQueryResult = Apollo.QueryResult<FindManyEmailCampaignTemplateQuery, FindManyEmailCampaignTemplateQueryVariables>;
export function refetchFindManyEmailCampaignTemplateQuery(variables?: FindManyEmailCampaignTemplateQueryVariables) {
      return { query: FindManyEmailCampaignTemplateDocument, variables: variables }
    }
export const CanSendEmailCampaignDocument = gql`
    query canSendEmailCampaign($clinicId: String!) {
  canSendEmailCampaign(clinicId: $clinicId)
}
    `;

/**
 * __useCanSendEmailCampaignQuery__
 *
 * To run a query within a React component, call `useCanSendEmailCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanSendEmailCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSendEmailCampaignQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useCanSendEmailCampaignQuery(baseOptions: Apollo.QueryHookOptions<CanSendEmailCampaignQuery, CanSendEmailCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanSendEmailCampaignQuery, CanSendEmailCampaignQueryVariables>(CanSendEmailCampaignDocument, options);
      }
export function useCanSendEmailCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanSendEmailCampaignQuery, CanSendEmailCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanSendEmailCampaignQuery, CanSendEmailCampaignQueryVariables>(CanSendEmailCampaignDocument, options);
        }
export type CanSendEmailCampaignQueryHookResult = ReturnType<typeof useCanSendEmailCampaignQuery>;
export type CanSendEmailCampaignLazyQueryHookResult = ReturnType<typeof useCanSendEmailCampaignLazyQuery>;
export type CanSendEmailCampaignQueryResult = Apollo.QueryResult<CanSendEmailCampaignQuery, CanSendEmailCampaignQueryVariables>;
export function refetchCanSendEmailCampaignQuery(variables?: CanSendEmailCampaignQueryVariables) {
      return { query: CanSendEmailCampaignDocument, variables: variables }
    }
export const GetEmailCampaignCustomListUrlDocument = gql`
    query getEmailCampaignCustomListUrl($data: EmailCampaignCustomListUrlInput!) {
  getEmailCampaignCustomListUrl(data: $data)
}
    `;

/**
 * __useGetEmailCampaignCustomListUrlQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignCustomListUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignCustomListUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignCustomListUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetEmailCampaignCustomListUrlQuery(baseOptions: Apollo.QueryHookOptions<GetEmailCampaignCustomListUrlQuery, GetEmailCampaignCustomListUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCampaignCustomListUrlQuery, GetEmailCampaignCustomListUrlQueryVariables>(GetEmailCampaignCustomListUrlDocument, options);
      }
export function useGetEmailCampaignCustomListUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCampaignCustomListUrlQuery, GetEmailCampaignCustomListUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCampaignCustomListUrlQuery, GetEmailCampaignCustomListUrlQueryVariables>(GetEmailCampaignCustomListUrlDocument, options);
        }
export type GetEmailCampaignCustomListUrlQueryHookResult = ReturnType<typeof useGetEmailCampaignCustomListUrlQuery>;
export type GetEmailCampaignCustomListUrlLazyQueryHookResult = ReturnType<typeof useGetEmailCampaignCustomListUrlLazyQuery>;
export type GetEmailCampaignCustomListUrlQueryResult = Apollo.QueryResult<GetEmailCampaignCustomListUrlQuery, GetEmailCampaignCustomListUrlQueryVariables>;
export function refetchGetEmailCampaignCustomListUrlQuery(variables?: GetEmailCampaignCustomListUrlQueryVariables) {
      return { query: GetEmailCampaignCustomListUrlDocument, variables: variables }
    }
export const GetEmailCampaignSegmentCountDocument = gql`
    query getEmailCampaignSegmentCount($clinicId: String!, $emailCampaignId: String) {
  getEmailCampaignSegmentCount(
    clinicId: $clinicId
    emailCampaignId: $emailCampaignId
  )
}
    `;

/**
 * __useGetEmailCampaignSegmentCountQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignSegmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignSegmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignSegmentCountQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      emailCampaignId: // value for 'emailCampaignId'
 *   },
 * });
 */
export function useGetEmailCampaignSegmentCountQuery(baseOptions: Apollo.QueryHookOptions<GetEmailCampaignSegmentCountQuery, GetEmailCampaignSegmentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCampaignSegmentCountQuery, GetEmailCampaignSegmentCountQueryVariables>(GetEmailCampaignSegmentCountDocument, options);
      }
export function useGetEmailCampaignSegmentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCampaignSegmentCountQuery, GetEmailCampaignSegmentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCampaignSegmentCountQuery, GetEmailCampaignSegmentCountQueryVariables>(GetEmailCampaignSegmentCountDocument, options);
        }
export type GetEmailCampaignSegmentCountQueryHookResult = ReturnType<typeof useGetEmailCampaignSegmentCountQuery>;
export type GetEmailCampaignSegmentCountLazyQueryHookResult = ReturnType<typeof useGetEmailCampaignSegmentCountLazyQuery>;
export type GetEmailCampaignSegmentCountQueryResult = Apollo.QueryResult<GetEmailCampaignSegmentCountQuery, GetEmailCampaignSegmentCountQueryVariables>;
export function refetchGetEmailCampaignSegmentCountQuery(variables?: GetEmailCampaignSegmentCountQueryVariables) {
      return { query: GetEmailCampaignSegmentCountDocument, variables: variables }
    }
export const SendEmailCampaignNowDocument = gql`
    mutation sendEmailCampaignNow($data: sendEmailCampaignNowInput!) {
  sendEmailCampaignNow(data: $data) {
    id
    time
  }
}
    `;
export type SendEmailCampaignNowMutationFn = Apollo.MutationFunction<SendEmailCampaignNowMutation, SendEmailCampaignNowMutationVariables>;

/**
 * __useSendEmailCampaignNowMutation__
 *
 * To run a mutation, you first call `useSendEmailCampaignNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailCampaignNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailCampaignNowMutation, { data, loading, error }] = useSendEmailCampaignNowMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendEmailCampaignNowMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailCampaignNowMutation, SendEmailCampaignNowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailCampaignNowMutation, SendEmailCampaignNowMutationVariables>(SendEmailCampaignNowDocument, options);
      }
export type SendEmailCampaignNowMutationHookResult = ReturnType<typeof useSendEmailCampaignNowMutation>;
export type SendEmailCampaignNowMutationResult = Apollo.MutationResult<SendEmailCampaignNowMutation>;
export type SendEmailCampaignNowMutationOptions = Apollo.BaseMutationOptions<SendEmailCampaignNowMutation, SendEmailCampaignNowMutationVariables>;
export const UpdateOneEmailCampaignDocument = gql`
    mutation updateOneEmailCampaign($data: EmailCampaignUpdateInput!, $where: EmailCampaignWhereUniqueInput!) {
  updateOneEmailCampaign(data: $data, where: $where) {
    ...EmailCampaign
  }
}
    ${EmailCampaignFragmentDoc}`;
export type UpdateOneEmailCampaignMutationFn = Apollo.MutationFunction<UpdateOneEmailCampaignMutation, UpdateOneEmailCampaignMutationVariables>;

/**
 * __useUpdateOneEmailCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateOneEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEmailCampaignMutation, { data, loading, error }] = useUpdateOneEmailCampaignMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneEmailCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneEmailCampaignMutation, UpdateOneEmailCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneEmailCampaignMutation, UpdateOneEmailCampaignMutationVariables>(UpdateOneEmailCampaignDocument, options);
      }
export type UpdateOneEmailCampaignMutationHookResult = ReturnType<typeof useUpdateOneEmailCampaignMutation>;
export type UpdateOneEmailCampaignMutationResult = Apollo.MutationResult<UpdateOneEmailCampaignMutation>;
export type UpdateOneEmailCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateOneEmailCampaignMutation, UpdateOneEmailCampaignMutationVariables>;
export const UploadEmailCampaignCustomListDocument = gql`
    mutation uploadEmailCampaignCustomList($data: UploadEmailCampaignCustomListInput!) {
  uploadEmailCampaignCustomList(data: $data) {
    customList {
      id
      awsFileKey
      fileName
      validRecipients
      invalidRecipients
    }
    customListHistory {
      id
      expiresAt
      fileName
      awsFileKey
    }
  }
}
    `;
export type UploadEmailCampaignCustomListMutationFn = Apollo.MutationFunction<UploadEmailCampaignCustomListMutation, UploadEmailCampaignCustomListMutationVariables>;

/**
 * __useUploadEmailCampaignCustomListMutation__
 *
 * To run a mutation, you first call `useUploadEmailCampaignCustomListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEmailCampaignCustomListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEmailCampaignCustomListMutation, { data, loading, error }] = useUploadEmailCampaignCustomListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadEmailCampaignCustomListMutation(baseOptions?: Apollo.MutationHookOptions<UploadEmailCampaignCustomListMutation, UploadEmailCampaignCustomListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadEmailCampaignCustomListMutation, UploadEmailCampaignCustomListMutationVariables>(UploadEmailCampaignCustomListDocument, options);
      }
export type UploadEmailCampaignCustomListMutationHookResult = ReturnType<typeof useUploadEmailCampaignCustomListMutation>;
export type UploadEmailCampaignCustomListMutationResult = Apollo.MutationResult<UploadEmailCampaignCustomListMutation>;
export type UploadEmailCampaignCustomListMutationOptions = Apollo.BaseMutationOptions<UploadEmailCampaignCustomListMutation, UploadEmailCampaignCustomListMutationVariables>;
export const GetQuickSightDashboardsDocument = gql`
    query getQuickSightDashboards {
  getQuickSightDashboards {
    Name
    PublishedVersionNumber
    DashboardId
  }
}
    `;

/**
 * __useGetQuickSightDashboardsQuery__
 *
 * To run a query within a React component, call `useGetQuickSightDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickSightDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickSightDashboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuickSightDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuickSightDashboardsQuery, GetQuickSightDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickSightDashboardsQuery, GetQuickSightDashboardsQueryVariables>(GetQuickSightDashboardsDocument, options);
      }
export function useGetQuickSightDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickSightDashboardsQuery, GetQuickSightDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickSightDashboardsQuery, GetQuickSightDashboardsQueryVariables>(GetQuickSightDashboardsDocument, options);
        }
export type GetQuickSightDashboardsQueryHookResult = ReturnType<typeof useGetQuickSightDashboardsQuery>;
export type GetQuickSightDashboardsLazyQueryHookResult = ReturnType<typeof useGetQuickSightDashboardsLazyQuery>;
export type GetQuickSightDashboardsQueryResult = Apollo.QueryResult<GetQuickSightDashboardsQuery, GetQuickSightDashboardsQueryVariables>;
export function refetchGetQuickSightDashboardsQuery(variables?: GetQuickSightDashboardsQueryVariables) {
      return { query: GetQuickSightDashboardsDocument, variables: variables }
    }
export const GetQuickSightEmbedUrlDocument = gql`
    query getQuickSightEmbedURL($where: getQuickSightEmbedURLInput!) {
  getQuickSightEmbedURL(where: $where) {
    Status
    EmbedUrl
    RequestId
  }
}
    `;

/**
 * __useGetQuickSightEmbedUrlQuery__
 *
 * To run a query within a React component, call `useGetQuickSightEmbedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickSightEmbedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickSightEmbedUrlQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetQuickSightEmbedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetQuickSightEmbedUrlQuery, GetQuickSightEmbedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickSightEmbedUrlQuery, GetQuickSightEmbedUrlQueryVariables>(GetQuickSightEmbedUrlDocument, options);
      }
export function useGetQuickSightEmbedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickSightEmbedUrlQuery, GetQuickSightEmbedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickSightEmbedUrlQuery, GetQuickSightEmbedUrlQueryVariables>(GetQuickSightEmbedUrlDocument, options);
        }
export type GetQuickSightEmbedUrlQueryHookResult = ReturnType<typeof useGetQuickSightEmbedUrlQuery>;
export type GetQuickSightEmbedUrlLazyQueryHookResult = ReturnType<typeof useGetQuickSightEmbedUrlLazyQuery>;
export type GetQuickSightEmbedUrlQueryResult = Apollo.QueryResult<GetQuickSightEmbedUrlQuery, GetQuickSightEmbedUrlQueryVariables>;
export function refetchGetQuickSightEmbedUrlQuery(variables?: GetQuickSightEmbedUrlQueryVariables) {
      return { query: GetQuickSightEmbedUrlDocument, variables: variables }
    }
export const GetSignedDownloadUrlDocument = gql`
    mutation getSignedDownloadUrl($assetUrl: String!) {
  getSignedDownloadUrl(assetUrl: $assetUrl)
}
    `;
export type GetSignedDownloadUrlMutationFn = Apollo.MutationFunction<GetSignedDownloadUrlMutation, GetSignedDownloadUrlMutationVariables>;

/**
 * __useGetSignedDownloadUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedDownloadUrlMutation, { data, loading, error }] = useGetSignedDownloadUrlMutation({
 *   variables: {
 *      assetUrl: // value for 'assetUrl'
 *   },
 * });
 */
export function useGetSignedDownloadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedDownloadUrlMutation, GetSignedDownloadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedDownloadUrlMutation, GetSignedDownloadUrlMutationVariables>(GetSignedDownloadUrlDocument, options);
      }
export type GetSignedDownloadUrlMutationHookResult = ReturnType<typeof useGetSignedDownloadUrlMutation>;
export type GetSignedDownloadUrlMutationResult = Apollo.MutationResult<GetSignedDownloadUrlMutation>;
export type GetSignedDownloadUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedDownloadUrlMutation, GetSignedDownloadUrlMutationVariables>;
export const CreateOnePayoutBatchingPeriodDocument = gql`
    mutation createOnePayoutBatchingPeriod($data: PayoutBatchingPeriodCreateInput!) {
  createOnePayoutBatchingPeriod(data: $data) {
    id
    createdAt
    updatedAt
    startsAt
    endsAt
  }
}
    `;
export type CreateOnePayoutBatchingPeriodMutationFn = Apollo.MutationFunction<CreateOnePayoutBatchingPeriodMutation, CreateOnePayoutBatchingPeriodMutationVariables>;

/**
 * __useCreateOnePayoutBatchingPeriodMutation__
 *
 * To run a mutation, you first call `useCreateOnePayoutBatchingPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePayoutBatchingPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePayoutBatchingPeriodMutation, { data, loading, error }] = useCreateOnePayoutBatchingPeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOnePayoutBatchingPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnePayoutBatchingPeriodMutation, CreateOnePayoutBatchingPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnePayoutBatchingPeriodMutation, CreateOnePayoutBatchingPeriodMutationVariables>(CreateOnePayoutBatchingPeriodDocument, options);
      }
export type CreateOnePayoutBatchingPeriodMutationHookResult = ReturnType<typeof useCreateOnePayoutBatchingPeriodMutation>;
export type CreateOnePayoutBatchingPeriodMutationResult = Apollo.MutationResult<CreateOnePayoutBatchingPeriodMutation>;
export type CreateOnePayoutBatchingPeriodMutationOptions = Apollo.BaseMutationOptions<CreateOnePayoutBatchingPeriodMutation, CreateOnePayoutBatchingPeriodMutationVariables>;
export const ResendReceiptEmailDocument = gql`
    mutation resendReceiptEmail($intentId: String!, $emailOverride: String) {
  resendReceiptEmail(
    stripePaymentIntentId: $intentId
    emailOverride: $emailOverride
  ) {
    success
    error
    jobChainId
  }
}
    `;
export type ResendReceiptEmailMutationFn = Apollo.MutationFunction<ResendReceiptEmailMutation, ResendReceiptEmailMutationVariables>;

/**
 * __useResendReceiptEmailMutation__
 *
 * To run a mutation, you first call `useResendReceiptEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendReceiptEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendReceiptEmailMutation, { data, loading, error }] = useResendReceiptEmailMutation({
 *   variables: {
 *      intentId: // value for 'intentId'
 *      emailOverride: // value for 'emailOverride'
 *   },
 * });
 */
export function useResendReceiptEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendReceiptEmailMutation, ResendReceiptEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendReceiptEmailMutation, ResendReceiptEmailMutationVariables>(ResendReceiptEmailDocument, options);
      }
export type ResendReceiptEmailMutationHookResult = ReturnType<typeof useResendReceiptEmailMutation>;
export type ResendReceiptEmailMutationResult = Apollo.MutationResult<ResendReceiptEmailMutation>;
export type ResendReceiptEmailMutationOptions = Apollo.BaseMutationOptions<ResendReceiptEmailMutation, ResendReceiptEmailMutationVariables>;
export const RetryPaymentWritebackDocument = gql`
    mutation retryPaymentWriteback($stripePaymentIntentId: String!) {
  retryPaymentWriteback(stripePaymentIntentId: $stripePaymentIntentId) {
    success
    error
    jobChainId
  }
}
    `;
export type RetryPaymentWritebackMutationFn = Apollo.MutationFunction<RetryPaymentWritebackMutation, RetryPaymentWritebackMutationVariables>;

/**
 * __useRetryPaymentWritebackMutation__
 *
 * To run a mutation, you first call `useRetryPaymentWritebackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryPaymentWritebackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryPaymentWritebackMutation, { data, loading, error }] = useRetryPaymentWritebackMutation({
 *   variables: {
 *      stripePaymentIntentId: // value for 'stripePaymentIntentId'
 *   },
 * });
 */
export function useRetryPaymentWritebackMutation(baseOptions?: Apollo.MutationHookOptions<RetryPaymentWritebackMutation, RetryPaymentWritebackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryPaymentWritebackMutation, RetryPaymentWritebackMutationVariables>(RetryPaymentWritebackDocument, options);
      }
export type RetryPaymentWritebackMutationHookResult = ReturnType<typeof useRetryPaymentWritebackMutation>;
export type RetryPaymentWritebackMutationResult = Apollo.MutationResult<RetryPaymentWritebackMutation>;
export type RetryPaymentWritebackMutationOptions = Apollo.BaseMutationOptions<RetryPaymentWritebackMutation, RetryPaymentWritebackMutationVariables>;
export const CheckCanManuallyRetryPaymentWritebackDocument = gql`
    query checkCanManuallyRetryPaymentWriteback($paymentIntentId: String!) {
  canManuallyRetryPaymentWriteback(stripePaymentIntentId: $paymentIntentId) {
    retryable
    reason
  }
}
    `;

/**
 * __useCheckCanManuallyRetryPaymentWritebackQuery__
 *
 * To run a query within a React component, call `useCheckCanManuallyRetryPaymentWritebackQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCanManuallyRetryPaymentWritebackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCanManuallyRetryPaymentWritebackQuery({
 *   variables: {
 *      paymentIntentId: // value for 'paymentIntentId'
 *   },
 * });
 */
export function useCheckCanManuallyRetryPaymentWritebackQuery(baseOptions: Apollo.QueryHookOptions<CheckCanManuallyRetryPaymentWritebackQuery, CheckCanManuallyRetryPaymentWritebackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCanManuallyRetryPaymentWritebackQuery, CheckCanManuallyRetryPaymentWritebackQueryVariables>(CheckCanManuallyRetryPaymentWritebackDocument, options);
      }
export function useCheckCanManuallyRetryPaymentWritebackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCanManuallyRetryPaymentWritebackQuery, CheckCanManuallyRetryPaymentWritebackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCanManuallyRetryPaymentWritebackQuery, CheckCanManuallyRetryPaymentWritebackQueryVariables>(CheckCanManuallyRetryPaymentWritebackDocument, options);
        }
export type CheckCanManuallyRetryPaymentWritebackQueryHookResult = ReturnType<typeof useCheckCanManuallyRetryPaymentWritebackQuery>;
export type CheckCanManuallyRetryPaymentWritebackLazyQueryHookResult = ReturnType<typeof useCheckCanManuallyRetryPaymentWritebackLazyQuery>;
export type CheckCanManuallyRetryPaymentWritebackQueryResult = Apollo.QueryResult<CheckCanManuallyRetryPaymentWritebackQuery, CheckCanManuallyRetryPaymentWritebackQueryVariables>;
export function refetchCheckCanManuallyRetryPaymentWritebackQuery(variables?: CheckCanManuallyRetryPaymentWritebackQueryVariables) {
      return { query: CheckCanManuallyRetryPaymentWritebackDocument, variables: variables }
    }
export const FindFirstPayoutBatchingPeriodDocument = gql`
    query findFirstPayoutBatchingPeriod($where: PayoutBatchingPeriodWhereInput) {
  findManyPayoutBatchingPeriod(where: $where, orderBy: {endsAt: desc}, take: 1) {
    ...PayoutBatchingPeriod
  }
}
    ${PayoutBatchingPeriodFragmentDoc}`;

/**
 * __useFindFirstPayoutBatchingPeriodQuery__
 *
 * To run a query within a React component, call `useFindFirstPayoutBatchingPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstPayoutBatchingPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstPayoutBatchingPeriodQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstPayoutBatchingPeriodQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstPayoutBatchingPeriodQuery, FindFirstPayoutBatchingPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstPayoutBatchingPeriodQuery, FindFirstPayoutBatchingPeriodQueryVariables>(FindFirstPayoutBatchingPeriodDocument, options);
      }
export function useFindFirstPayoutBatchingPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstPayoutBatchingPeriodQuery, FindFirstPayoutBatchingPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstPayoutBatchingPeriodQuery, FindFirstPayoutBatchingPeriodQueryVariables>(FindFirstPayoutBatchingPeriodDocument, options);
        }
export type FindFirstPayoutBatchingPeriodQueryHookResult = ReturnType<typeof useFindFirstPayoutBatchingPeriodQuery>;
export type FindFirstPayoutBatchingPeriodLazyQueryHookResult = ReturnType<typeof useFindFirstPayoutBatchingPeriodLazyQuery>;
export type FindFirstPayoutBatchingPeriodQueryResult = Apollo.QueryResult<FindFirstPayoutBatchingPeriodQuery, FindFirstPayoutBatchingPeriodQueryVariables>;
export function refetchFindFirstPayoutBatchingPeriodQuery(variables?: FindFirstPayoutBatchingPeriodQueryVariables) {
      return { query: FindFirstPayoutBatchingPeriodDocument, variables: variables }
    }
export const FindMissingPayoutBatchingPeriodDatesDocument = gql`
    query findMissingPayoutBatchingPeriodDates {
  findMissingPayoutBatchingPeriodDates {
    success
    error
    dates
  }
}
    `;

/**
 * __useFindMissingPayoutBatchingPeriodDatesQuery__
 *
 * To run a query within a React component, call `useFindMissingPayoutBatchingPeriodDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMissingPayoutBatchingPeriodDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMissingPayoutBatchingPeriodDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMissingPayoutBatchingPeriodDatesQuery(baseOptions?: Apollo.QueryHookOptions<FindMissingPayoutBatchingPeriodDatesQuery, FindMissingPayoutBatchingPeriodDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMissingPayoutBatchingPeriodDatesQuery, FindMissingPayoutBatchingPeriodDatesQueryVariables>(FindMissingPayoutBatchingPeriodDatesDocument, options);
      }
export function useFindMissingPayoutBatchingPeriodDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMissingPayoutBatchingPeriodDatesQuery, FindMissingPayoutBatchingPeriodDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMissingPayoutBatchingPeriodDatesQuery, FindMissingPayoutBatchingPeriodDatesQueryVariables>(FindMissingPayoutBatchingPeriodDatesDocument, options);
        }
export type FindMissingPayoutBatchingPeriodDatesQueryHookResult = ReturnType<typeof useFindMissingPayoutBatchingPeriodDatesQuery>;
export type FindMissingPayoutBatchingPeriodDatesLazyQueryHookResult = ReturnType<typeof useFindMissingPayoutBatchingPeriodDatesLazyQuery>;
export type FindMissingPayoutBatchingPeriodDatesQueryResult = Apollo.QueryResult<FindMissingPayoutBatchingPeriodDatesQuery, FindMissingPayoutBatchingPeriodDatesQueryVariables>;
export function refetchFindMissingPayoutBatchingPeriodDatesQuery(variables?: FindMissingPayoutBatchingPeriodDatesQueryVariables) {
      return { query: FindMissingPayoutBatchingPeriodDatesDocument, variables: variables }
    }
export const GetStripeReceiptUrlDocument = gql`
    query getStripeReceiptUrl($intentId: String!) {
  getStripePaymentIntentData(where: {id: $intentId}) {
    payment {
      receiptUrl
    }
  }
}
    `;

/**
 * __useGetStripeReceiptUrlQuery__
 *
 * To run a query within a React component, call `useGetStripeReceiptUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeReceiptUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeReceiptUrlQuery({
 *   variables: {
 *      intentId: // value for 'intentId'
 *   },
 * });
 */
export function useGetStripeReceiptUrlQuery(baseOptions: Apollo.QueryHookOptions<GetStripeReceiptUrlQuery, GetStripeReceiptUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeReceiptUrlQuery, GetStripeReceiptUrlQueryVariables>(GetStripeReceiptUrlDocument, options);
      }
export function useGetStripeReceiptUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeReceiptUrlQuery, GetStripeReceiptUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeReceiptUrlQuery, GetStripeReceiptUrlQueryVariables>(GetStripeReceiptUrlDocument, options);
        }
export type GetStripeReceiptUrlQueryHookResult = ReturnType<typeof useGetStripeReceiptUrlQuery>;
export type GetStripeReceiptUrlLazyQueryHookResult = ReturnType<typeof useGetStripeReceiptUrlLazyQuery>;
export type GetStripeReceiptUrlQueryResult = Apollo.QueryResult<GetStripeReceiptUrlQuery, GetStripeReceiptUrlQueryVariables>;
export function refetchGetStripeReceiptUrlQuery(variables?: GetStripeReceiptUrlQueryVariables) {
      return { query: GetStripeReceiptUrlDocument, variables: variables }
    }
export const RequestBitwerxIntegrationDocument = gql`
    mutation requestBitwerxIntegration($data: RequestBitwerxIntegrationInput!) {
  requestBitwerxIntegration(data: $data) {
    id
  }
}
    `;
export type RequestBitwerxIntegrationMutationFn = Apollo.MutationFunction<RequestBitwerxIntegrationMutation, RequestBitwerxIntegrationMutationVariables>;

/**
 * __useRequestBitwerxIntegrationMutation__
 *
 * To run a mutation, you first call `useRequestBitwerxIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBitwerxIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBitwerxIntegrationMutation, { data, loading, error }] = useRequestBitwerxIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestBitwerxIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<RequestBitwerxIntegrationMutation, RequestBitwerxIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestBitwerxIntegrationMutation, RequestBitwerxIntegrationMutationVariables>(RequestBitwerxIntegrationDocument, options);
      }
export type RequestBitwerxIntegrationMutationHookResult = ReturnType<typeof useRequestBitwerxIntegrationMutation>;
export type RequestBitwerxIntegrationMutationResult = Apollo.MutationResult<RequestBitwerxIntegrationMutation>;
export type RequestBitwerxIntegrationMutationOptions = Apollo.BaseMutationOptions<RequestBitwerxIntegrationMutation, RequestBitwerxIntegrationMutationVariables>;
export const UpsertPetPortalSettingsDocument = gql`
    mutation upsertPetPortalSettings($where: PetPortalSettingWhereUniqueInput!, $create: PetPortalSettingCreateInput!, $update: PetPortalSettingUpdateInput!) {
  upsertOnePetPortalSetting(where: $where, create: $create, update: $update) {
    id
    clinicSettingId
    showAppointments
  }
}
    `;
export type UpsertPetPortalSettingsMutationFn = Apollo.MutationFunction<UpsertPetPortalSettingsMutation, UpsertPetPortalSettingsMutationVariables>;

/**
 * __useUpsertPetPortalSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertPetPortalSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPetPortalSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPetPortalSettingsMutation, { data, loading, error }] = useUpsertPetPortalSettingsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertPetPortalSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPetPortalSettingsMutation, UpsertPetPortalSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPetPortalSettingsMutation, UpsertPetPortalSettingsMutationVariables>(UpsertPetPortalSettingsDocument, options);
      }
export type UpsertPetPortalSettingsMutationHookResult = ReturnType<typeof useUpsertPetPortalSettingsMutation>;
export type UpsertPetPortalSettingsMutationResult = Apollo.MutationResult<UpsertPetPortalSettingsMutation>;
export type UpsertPetPortalSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertPetPortalSettingsMutation, UpsertPetPortalSettingsMutationVariables>;
export const JobChainCompletedDocument = gql`
    subscription jobChainCompleted($jobChainId: String!) {
  jobChainCompleted(where: {id: {equals: $jobChainId}}) {
    jobChainId
    payload
    error
    success
  }
}
    `;

/**
 * __useJobChainCompletedSubscription__
 *
 * To run a query within a React component, call `useJobChainCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJobChainCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobChainCompletedSubscription({
 *   variables: {
 *      jobChainId: // value for 'jobChainId'
 *   },
 * });
 */
export function useJobChainCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<JobChainCompletedSubscription, JobChainCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JobChainCompletedSubscription, JobChainCompletedSubscriptionVariables>(JobChainCompletedDocument, options);
      }
export type JobChainCompletedSubscriptionHookResult = ReturnType<typeof useJobChainCompletedSubscription>;
export type JobChainCompletedSubscriptionResult = Apollo.SubscriptionResult<JobChainCompletedSubscription>;
export const JobChainStatusDocument = gql`
    query jobChainStatus($jobChainId: String!) {
  jobChainStatus(where: {jobChainId: $jobChainId}) {
    status
    payload
    error
    queuedAt
    completedAt
    erroredAt
  }
}
    `;

/**
 * __useJobChainStatusQuery__
 *
 * To run a query within a React component, call `useJobChainStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobChainStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobChainStatusQuery({
 *   variables: {
 *      jobChainId: // value for 'jobChainId'
 *   },
 * });
 */
export function useJobChainStatusQuery(baseOptions: Apollo.QueryHookOptions<JobChainStatusQuery, JobChainStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobChainStatusQuery, JobChainStatusQueryVariables>(JobChainStatusDocument, options);
      }
export function useJobChainStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobChainStatusQuery, JobChainStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobChainStatusQuery, JobChainStatusQueryVariables>(JobChainStatusDocument, options);
        }
export type JobChainStatusQueryHookResult = ReturnType<typeof useJobChainStatusQuery>;
export type JobChainStatusLazyQueryHookResult = ReturnType<typeof useJobChainStatusLazyQuery>;
export type JobChainStatusQueryResult = Apollo.QueryResult<JobChainStatusQuery, JobChainStatusQueryVariables>;
export function refetchJobChainStatusQuery(variables?: JobChainStatusQueryVariables) {
      return { query: JobChainStatusDocument, variables: variables }
    }
export const StripeCreateCustomerPortalDocument = gql`
    query stripeCreateCustomerPortal($data: StripeCustomerPortalInput) {
  stripeCreateCustomerPortal(data: $data) {
    id
    url
  }
}
    `;

/**
 * __useStripeCreateCustomerPortalQuery__
 *
 * To run a query within a React component, call `useStripeCreateCustomerPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateCustomerPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeCreateCustomerPortalQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStripeCreateCustomerPortalQuery(baseOptions?: Apollo.QueryHookOptions<StripeCreateCustomerPortalQuery, StripeCreateCustomerPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeCreateCustomerPortalQuery, StripeCreateCustomerPortalQueryVariables>(StripeCreateCustomerPortalDocument, options);
      }
export function useStripeCreateCustomerPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeCreateCustomerPortalQuery, StripeCreateCustomerPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeCreateCustomerPortalQuery, StripeCreateCustomerPortalQueryVariables>(StripeCreateCustomerPortalDocument, options);
        }
export type StripeCreateCustomerPortalQueryHookResult = ReturnType<typeof useStripeCreateCustomerPortalQuery>;
export type StripeCreateCustomerPortalLazyQueryHookResult = ReturnType<typeof useStripeCreateCustomerPortalLazyQuery>;
export type StripeCreateCustomerPortalQueryResult = Apollo.QueryResult<StripeCreateCustomerPortalQuery, StripeCreateCustomerPortalQueryVariables>;
export function refetchStripeCreateCustomerPortalQuery(variables?: StripeCreateCustomerPortalQueryVariables) {
      return { query: StripeCreateCustomerPortalDocument, variables: variables }
    }
export const TriggerMessageEventDocument = gql`
    mutation triggerMessageEvent($useSubscription: Boolean, $event: String!, $queueName: SqsQueueNames, $data: Json) {
  triggerMessageEvent(
    data: {data: $data, event: $event, queueName: $queueName, useSubscription: $useSubscription}
  ) {
    success
    jobChainId
  }
}
    `;
export type TriggerMessageEventMutationFn = Apollo.MutationFunction<TriggerMessageEventMutation, TriggerMessageEventMutationVariables>;

/**
 * __useTriggerMessageEventMutation__
 *
 * To run a mutation, you first call `useTriggerMessageEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerMessageEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerMessageEventMutation, { data, loading, error }] = useTriggerMessageEventMutation({
 *   variables: {
 *      useSubscription: // value for 'useSubscription'
 *      event: // value for 'event'
 *      queueName: // value for 'queueName'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTriggerMessageEventMutation(baseOptions?: Apollo.MutationHookOptions<TriggerMessageEventMutation, TriggerMessageEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerMessageEventMutation, TriggerMessageEventMutationVariables>(TriggerMessageEventDocument, options);
      }
export type TriggerMessageEventMutationHookResult = ReturnType<typeof useTriggerMessageEventMutation>;
export type TriggerMessageEventMutationResult = Apollo.MutationResult<TriggerMessageEventMutation>;
export type TriggerMessageEventMutationOptions = Apollo.BaseMutationOptions<TriggerMessageEventMutation, TriggerMessageEventMutationVariables>;
export const DeleteDirectBookingExclusionRuleDocument = gql`
    mutation deleteDirectBookingExclusionRule($ruleId: String!) {
  deleteOneDirectBookingExclusionRule(where: {id: $ruleId}) {
    id
  }
}
    `;
export type DeleteDirectBookingExclusionRuleMutationFn = Apollo.MutationFunction<DeleteDirectBookingExclusionRuleMutation, DeleteDirectBookingExclusionRuleMutationVariables>;

/**
 * __useDeleteDirectBookingExclusionRuleMutation__
 *
 * To run a mutation, you first call `useDeleteDirectBookingExclusionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirectBookingExclusionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirectBookingExclusionRuleMutation, { data, loading, error }] = useDeleteDirectBookingExclusionRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useDeleteDirectBookingExclusionRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDirectBookingExclusionRuleMutation, DeleteDirectBookingExclusionRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDirectBookingExclusionRuleMutation, DeleteDirectBookingExclusionRuleMutationVariables>(DeleteDirectBookingExclusionRuleDocument, options);
      }
export type DeleteDirectBookingExclusionRuleMutationHookResult = ReturnType<typeof useDeleteDirectBookingExclusionRuleMutation>;
export type DeleteDirectBookingExclusionRuleMutationResult = Apollo.MutationResult<DeleteDirectBookingExclusionRuleMutation>;
export type DeleteDirectBookingExclusionRuleMutationOptions = Apollo.BaseMutationOptions<DeleteDirectBookingExclusionRuleMutation, DeleteDirectBookingExclusionRuleMutationVariables>;
export const UpdateDirectBookingExclusionRuleDocument = gql`
    mutation updateDirectBookingExclusionRule($ruleId: String!, $data: DirectBookingExclusionRuleUpdateInput!) {
  updateOneDirectBookingExclusionRule(where: {id: $ruleId}, data: $data) {
    ...ExclusionRuleSelection
  }
}
    ${ExclusionRuleSelectionFragmentDoc}`;
export type UpdateDirectBookingExclusionRuleMutationFn = Apollo.MutationFunction<UpdateDirectBookingExclusionRuleMutation, UpdateDirectBookingExclusionRuleMutationVariables>;

/**
 * __useUpdateDirectBookingExclusionRuleMutation__
 *
 * To run a mutation, you first call `useUpdateDirectBookingExclusionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectBookingExclusionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectBookingExclusionRuleMutation, { data, loading, error }] = useUpdateDirectBookingExclusionRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDirectBookingExclusionRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDirectBookingExclusionRuleMutation, UpdateDirectBookingExclusionRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDirectBookingExclusionRuleMutation, UpdateDirectBookingExclusionRuleMutationVariables>(UpdateDirectBookingExclusionRuleDocument, options);
      }
export type UpdateDirectBookingExclusionRuleMutationHookResult = ReturnType<typeof useUpdateDirectBookingExclusionRuleMutation>;
export type UpdateDirectBookingExclusionRuleMutationResult = Apollo.MutationResult<UpdateDirectBookingExclusionRuleMutation>;
export type UpdateDirectBookingExclusionRuleMutationOptions = Apollo.BaseMutationOptions<UpdateDirectBookingExclusionRuleMutation, UpdateDirectBookingExclusionRuleMutationVariables>;
export const UpsertDirectBookingSettingsDocument = gql`
    mutation upsertDirectBookingSettings($where: ClinicDirectBookingSettingWhereUniqueInput!, $create: ClinicDirectBookingSettingCreateInput!, $update: ClinicDirectBookingSettingUpdateInput!) {
  upsertOneClinicDirectBookingSetting(
    where: $where
    create: $create
    update: $update
  ) {
    id
    exclusionRulesEnabled
    appointmentIntervalInMinutesDisplay
  }
}
    `;
export type UpsertDirectBookingSettingsMutationFn = Apollo.MutationFunction<UpsertDirectBookingSettingsMutation, UpsertDirectBookingSettingsMutationVariables>;

/**
 * __useUpsertDirectBookingSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertDirectBookingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDirectBookingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDirectBookingSettingsMutation, { data, loading, error }] = useUpsertDirectBookingSettingsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertDirectBookingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDirectBookingSettingsMutation, UpsertDirectBookingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDirectBookingSettingsMutation, UpsertDirectBookingSettingsMutationVariables>(UpsertDirectBookingSettingsDocument, options);
      }
export type UpsertDirectBookingSettingsMutationHookResult = ReturnType<typeof useUpsertDirectBookingSettingsMutation>;
export type UpsertDirectBookingSettingsMutationResult = Apollo.MutationResult<UpsertDirectBookingSettingsMutation>;
export type UpsertDirectBookingSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertDirectBookingSettingsMutation, UpsertDirectBookingSettingsMutationVariables>;
export const UpsertDirectBookingExclusionRuleDocument = gql`
    mutation upsertDirectBookingExclusionRule($where: DirectBookingExclusionRuleWhereUniqueInput!, $create: DirectBookingExclusionRuleCreateInput!, $update: DirectBookingExclusionRuleUpdateInput!) {
  upsertOneDirectBookingExclusionRule(
    where: $where
    create: $create
    update: $update
  ) {
    ...ExclusionRuleSelection
  }
}
    ${ExclusionRuleSelectionFragmentDoc}`;
export type UpsertDirectBookingExclusionRuleMutationFn = Apollo.MutationFunction<UpsertDirectBookingExclusionRuleMutation, UpsertDirectBookingExclusionRuleMutationVariables>;

/**
 * __useUpsertDirectBookingExclusionRuleMutation__
 *
 * To run a mutation, you first call `useUpsertDirectBookingExclusionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDirectBookingExclusionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDirectBookingExclusionRuleMutation, { data, loading, error }] = useUpsertDirectBookingExclusionRuleMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertDirectBookingExclusionRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDirectBookingExclusionRuleMutation, UpsertDirectBookingExclusionRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDirectBookingExclusionRuleMutation, UpsertDirectBookingExclusionRuleMutationVariables>(UpsertDirectBookingExclusionRuleDocument, options);
      }
export type UpsertDirectBookingExclusionRuleMutationHookResult = ReturnType<typeof useUpsertDirectBookingExclusionRuleMutation>;
export type UpsertDirectBookingExclusionRuleMutationResult = Apollo.MutationResult<UpsertDirectBookingExclusionRuleMutation>;
export type UpsertDirectBookingExclusionRuleMutationOptions = Apollo.BaseMutationOptions<UpsertDirectBookingExclusionRuleMutation, UpsertDirectBookingExclusionRuleMutationVariables>;
export const GetClinicTimezoneNameDocument = gql`
    query getClinicTimezoneName($clinicId: String!) {
  findUniqueClinic(where: {id: $clinicId}) {
    id
    timezoneName
  }
}
    `;

/**
 * __useGetClinicTimezoneNameQuery__
 *
 * To run a query within a React component, call `useGetClinicTimezoneNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicTimezoneNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicTimezoneNameQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicTimezoneNameQuery(baseOptions: Apollo.QueryHookOptions<GetClinicTimezoneNameQuery, GetClinicTimezoneNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicTimezoneNameQuery, GetClinicTimezoneNameQueryVariables>(GetClinicTimezoneNameDocument, options);
      }
export function useGetClinicTimezoneNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicTimezoneNameQuery, GetClinicTimezoneNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicTimezoneNameQuery, GetClinicTimezoneNameQueryVariables>(GetClinicTimezoneNameDocument, options);
        }
export type GetClinicTimezoneNameQueryHookResult = ReturnType<typeof useGetClinicTimezoneNameQuery>;
export type GetClinicTimezoneNameLazyQueryHookResult = ReturnType<typeof useGetClinicTimezoneNameLazyQuery>;
export type GetClinicTimezoneNameQueryResult = Apollo.QueryResult<GetClinicTimezoneNameQuery, GetClinicTimezoneNameQueryVariables>;
export function refetchGetClinicTimezoneNameQuery(variables?: GetClinicTimezoneNameQueryVariables) {
      return { query: GetClinicTimezoneNameDocument, variables: variables }
    }
export const GetDirectBookingAppointmentTypesDocument = gql`
    query getDirectBookingAppointmentTypes($clinicId: String!) {
  findManyAppointmentType(
    where: {clinicId: {equals: $clinicId}, isVisible: {equals: true}, name: {not: {equals: ""}}}
    orderBy: {name: asc}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetDirectBookingAppointmentTypesQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingAppointmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingAppointmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingAppointmentTypesQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetDirectBookingAppointmentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>(GetDirectBookingAppointmentTypesDocument, options);
      }
export function useGetDirectBookingAppointmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>(GetDirectBookingAppointmentTypesDocument, options);
        }
export type GetDirectBookingAppointmentTypesQueryHookResult = ReturnType<typeof useGetDirectBookingAppointmentTypesQuery>;
export type GetDirectBookingAppointmentTypesLazyQueryHookResult = ReturnType<typeof useGetDirectBookingAppointmentTypesLazyQuery>;
export type GetDirectBookingAppointmentTypesQueryResult = Apollo.QueryResult<GetDirectBookingAppointmentTypesQuery, GetDirectBookingAppointmentTypesQueryVariables>;
export function refetchGetDirectBookingAppointmentTypesQuery(variables?: GetDirectBookingAppointmentTypesQueryVariables) {
      return { query: GetDirectBookingAppointmentTypesDocument, variables: variables }
    }
export const GetDirectBookingExclusionsDocument = gql`
    query getDirectBookingExclusions($clinicId: String!) {
  findManyDirectBookingExclusionRule(
    where: {clinicId: {equals: $clinicId}}
    orderBy: {createdAt: asc}
  ) {
    ...ExclusionRuleSelection
  }
}
    ${ExclusionRuleSelectionFragmentDoc}`;

/**
 * __useGetDirectBookingExclusionsQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingExclusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingExclusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingExclusionsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetDirectBookingExclusionsQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingExclusionsQuery, GetDirectBookingExclusionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingExclusionsQuery, GetDirectBookingExclusionsQueryVariables>(GetDirectBookingExclusionsDocument, options);
      }
export function useGetDirectBookingExclusionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingExclusionsQuery, GetDirectBookingExclusionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingExclusionsQuery, GetDirectBookingExclusionsQueryVariables>(GetDirectBookingExclusionsDocument, options);
        }
export type GetDirectBookingExclusionsQueryHookResult = ReturnType<typeof useGetDirectBookingExclusionsQuery>;
export type GetDirectBookingExclusionsLazyQueryHookResult = ReturnType<typeof useGetDirectBookingExclusionsLazyQuery>;
export type GetDirectBookingExclusionsQueryResult = Apollo.QueryResult<GetDirectBookingExclusionsQuery, GetDirectBookingExclusionsQueryVariables>;
export function refetchGetDirectBookingExclusionsQuery(variables?: GetDirectBookingExclusionsQueryVariables) {
      return { query: GetDirectBookingExclusionsDocument, variables: variables }
    }
export const GetDirectBookingProvidersDocument = gql`
    query getDirectBookingProviders($clinicId: String!) {
  findManyClinicEmployee(
    where: {isDeleted: {equals: false}, isActive: {equals: true}, isVisible: {equals: true}, clinic: {id: {equals: $clinicId}}}
    orderBy: {firstName: asc}
  ) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useGetDirectBookingProvidersQuery__
 *
 * To run a query within a React component, call `useGetDirectBookingProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectBookingProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectBookingProvidersQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetDirectBookingProvidersQuery(baseOptions: Apollo.QueryHookOptions<GetDirectBookingProvidersQuery, GetDirectBookingProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectBookingProvidersQuery, GetDirectBookingProvidersQueryVariables>(GetDirectBookingProvidersDocument, options);
      }
export function useGetDirectBookingProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectBookingProvidersQuery, GetDirectBookingProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectBookingProvidersQuery, GetDirectBookingProvidersQueryVariables>(GetDirectBookingProvidersDocument, options);
        }
export type GetDirectBookingProvidersQueryHookResult = ReturnType<typeof useGetDirectBookingProvidersQuery>;
export type GetDirectBookingProvidersLazyQueryHookResult = ReturnType<typeof useGetDirectBookingProvidersLazyQuery>;
export type GetDirectBookingProvidersQueryResult = Apollo.QueryResult<GetDirectBookingProvidersQuery, GetDirectBookingProvidersQueryVariables>;
export function refetchGetDirectBookingProvidersQuery(variables?: GetDirectBookingProvidersQueryVariables) {
      return { query: GetDirectBookingProvidersDocument, variables: variables }
    }
export const GetClinicDirectBookingSettingsDocument = gql`
    query getClinicDirectBookingSettings($where: ClinicDirectBookingSettingWhereInput!) {
  findFirstClinicDirectBookingSetting(where: $where) {
    id
    exclusionRulesEnabled
    appointmentIntervalInMinutesDisplay
  }
}
    `;

/**
 * __useGetClinicDirectBookingSettingsQuery__
 *
 * To run a query within a React component, call `useGetClinicDirectBookingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicDirectBookingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicDirectBookingSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicDirectBookingSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>(GetClinicDirectBookingSettingsDocument, options);
      }
export function useGetClinicDirectBookingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>(GetClinicDirectBookingSettingsDocument, options);
        }
export type GetClinicDirectBookingSettingsQueryHookResult = ReturnType<typeof useGetClinicDirectBookingSettingsQuery>;
export type GetClinicDirectBookingSettingsLazyQueryHookResult = ReturnType<typeof useGetClinicDirectBookingSettingsLazyQuery>;
export type GetClinicDirectBookingSettingsQueryResult = Apollo.QueryResult<GetClinicDirectBookingSettingsQuery, GetClinicDirectBookingSettingsQueryVariables>;
export function refetchGetClinicDirectBookingSettingsQuery(variables?: GetClinicDirectBookingSettingsQueryVariables) {
      return { query: GetClinicDirectBookingSettingsDocument, variables: variables }
    }
export const UpdateAppointmentTypeOrderDocument = gql`
    mutation updateAppointmentTypeOrder($data: [AppointmentTypeWithOrder!]) {
  updateManyAppointmentTypeSortOrder(data: $data) {
    success
    error
  }
}
    `;
export type UpdateAppointmentTypeOrderMutationFn = Apollo.MutationFunction<UpdateAppointmentTypeOrderMutation, UpdateAppointmentTypeOrderMutationVariables>;

/**
 * __useUpdateAppointmentTypeOrderMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentTypeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentTypeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentTypeOrderMutation, { data, loading, error }] = useUpdateAppointmentTypeOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppointmentTypeOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentTypeOrderMutation, UpdateAppointmentTypeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentTypeOrderMutation, UpdateAppointmentTypeOrderMutationVariables>(UpdateAppointmentTypeOrderDocument, options);
      }
export type UpdateAppointmentTypeOrderMutationHookResult = ReturnType<typeof useUpdateAppointmentTypeOrderMutation>;
export type UpdateAppointmentTypeOrderMutationResult = Apollo.MutationResult<UpdateAppointmentTypeOrderMutation>;
export type UpdateAppointmentTypeOrderMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentTypeOrderMutation, UpdateAppointmentTypeOrderMutationVariables>;
export const UpdateAppointmentTypeProviderRoomMapsDocument = gql`
    mutation updateAppointmentTypeProviderRoomMaps($data: UpdateAppointmentTypeProviderRoomMapsInput!) {
  updateAppointmentTypeProviderRoomMaps(data: $data) {
    success
    count
  }
}
    `;
export type UpdateAppointmentTypeProviderRoomMapsMutationFn = Apollo.MutationFunction<UpdateAppointmentTypeProviderRoomMapsMutation, UpdateAppointmentTypeProviderRoomMapsMutationVariables>;

/**
 * __useUpdateAppointmentTypeProviderRoomMapsMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentTypeProviderRoomMapsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentTypeProviderRoomMapsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentTypeProviderRoomMapsMutation, { data, loading, error }] = useUpdateAppointmentTypeProviderRoomMapsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppointmentTypeProviderRoomMapsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentTypeProviderRoomMapsMutation, UpdateAppointmentTypeProviderRoomMapsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentTypeProviderRoomMapsMutation, UpdateAppointmentTypeProviderRoomMapsMutationVariables>(UpdateAppointmentTypeProviderRoomMapsDocument, options);
      }
export type UpdateAppointmentTypeProviderRoomMapsMutationHookResult = ReturnType<typeof useUpdateAppointmentTypeProviderRoomMapsMutation>;
export type UpdateAppointmentTypeProviderRoomMapsMutationResult = Apollo.MutationResult<UpdateAppointmentTypeProviderRoomMapsMutation>;
export type UpdateAppointmentTypeProviderRoomMapsMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentTypeProviderRoomMapsMutation, UpdateAppointmentTypeProviderRoomMapsMutationVariables>;
export const UpdateOneAppointmentTypeDocument = gql`
    mutation updateOneAppointmentType($where: AppointmentTypeWhereUniqueInput!, $data: AppointmentTypeUpdateInput!) {
  updateOneAppointmentType(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateOneAppointmentTypeMutationFn = Apollo.MutationFunction<UpdateOneAppointmentTypeMutation, UpdateOneAppointmentTypeMutationVariables>;

/**
 * __useUpdateOneAppointmentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOneAppointmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAppointmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAppointmentTypeMutation, { data, loading, error }] = useUpdateOneAppointmentTypeMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneAppointmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAppointmentTypeMutation, UpdateOneAppointmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAppointmentTypeMutation, UpdateOneAppointmentTypeMutationVariables>(UpdateOneAppointmentTypeDocument, options);
      }
export type UpdateOneAppointmentTypeMutationHookResult = ReturnType<typeof useUpdateOneAppointmentTypeMutation>;
export type UpdateOneAppointmentTypeMutationResult = Apollo.MutationResult<UpdateOneAppointmentTypeMutation>;
export type UpdateOneAppointmentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateOneAppointmentTypeMutation, UpdateOneAppointmentTypeMutationVariables>;
export const GetAppointmentSettingsDocument = gql`
    query getAppointmentSettings($clinicSettingId: String!, $clinicId: String!) {
  findUniquePetPortalSetting(where: {clinicSettingId: $clinicSettingId}) {
    id
    clinicSettingId
    showAppointments
  }
  findManyAppointmentType(
    where: {clinicId: {equals: $clinicId}, isVisible: {equals: true}}
    orderBy: {displayOrder: asc}
  ) {
    ...AppointmentType
  }
  findManyClinicEmployee(
    where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isVisible: {equals: true}, isDeleted: {equals: false}}
  ) {
    ...Provider
  }
  findManyClinicRoom(
    where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isVisible: {equals: true}, isDeleted: {equals: false}, isSchedulable: {equals: true}}
  ) {
    ...Room
  }
}
    ${AppointmentTypeFragmentDoc}
${ProviderFragmentDoc}
${RoomFragmentDoc}`;

/**
 * __useGetAppointmentSettingsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentSettingsQuery({
 *   variables: {
 *      clinicSettingId: // value for 'clinicSettingId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAppointmentSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentSettingsQuery, GetAppointmentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentSettingsQuery, GetAppointmentSettingsQueryVariables>(GetAppointmentSettingsDocument, options);
      }
export function useGetAppointmentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentSettingsQuery, GetAppointmentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentSettingsQuery, GetAppointmentSettingsQueryVariables>(GetAppointmentSettingsDocument, options);
        }
export type GetAppointmentSettingsQueryHookResult = ReturnType<typeof useGetAppointmentSettingsQuery>;
export type GetAppointmentSettingsLazyQueryHookResult = ReturnType<typeof useGetAppointmentSettingsLazyQuery>;
export type GetAppointmentSettingsQueryResult = Apollo.QueryResult<GetAppointmentSettingsQuery, GetAppointmentSettingsQueryVariables>;
export function refetchGetAppointmentSettingsQuery(variables?: GetAppointmentSettingsQueryVariables) {
      return { query: GetAppointmentSettingsDocument, variables: variables }
    }
export const GetProviderAppointmentTypeRoomMapDocument = gql`
    query getProviderAppointmentTypeRoomMap($clinicId: String!) {
  findManyProviderAppointmentTypeRoomMap(
    where: {appointmentType: {clinicId: {equals: $clinicId}}}
  ) {
    id
    clinicEmployeeId
    appoinmentTypeId
    roomId
  }
}
    `;

/**
 * __useGetProviderAppointmentTypeRoomMapQuery__
 *
 * To run a query within a React component, call `useGetProviderAppointmentTypeRoomMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderAppointmentTypeRoomMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderAppointmentTypeRoomMapQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetProviderAppointmentTypeRoomMapQuery(baseOptions: Apollo.QueryHookOptions<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>(GetProviderAppointmentTypeRoomMapDocument, options);
      }
export function useGetProviderAppointmentTypeRoomMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>(GetProviderAppointmentTypeRoomMapDocument, options);
        }
export type GetProviderAppointmentTypeRoomMapQueryHookResult = ReturnType<typeof useGetProviderAppointmentTypeRoomMapQuery>;
export type GetProviderAppointmentTypeRoomMapLazyQueryHookResult = ReturnType<typeof useGetProviderAppointmentTypeRoomMapLazyQuery>;
export type GetProviderAppointmentTypeRoomMapQueryResult = Apollo.QueryResult<GetProviderAppointmentTypeRoomMapQuery, GetProviderAppointmentTypeRoomMapQueryVariables>;
export function refetchGetProviderAppointmentTypeRoomMapQuery(variables?: GetProviderAppointmentTypeRoomMapQueryVariables) {
      return { query: GetProviderAppointmentTypeRoomMapDocument, variables: variables }
    }
export const CreateClinicSettingsConversationsChannelStatusDocument = gql`
    mutation createClinicSettingsConversationsChannelStatus($clinicSettingId: String!, $channelStatusAction: ChannelStatusAction!, $color: String!, $name: String!, $order: Int) {
  createOneChannelStatus(
    data: {channelStatusAction: $channelStatusAction, clinicSetting: {connect: {id: $clinicSettingId}}, color: $color, name: $name, order: $order}
  ) {
    ...ClinicSettingsConversationsChannelStatus
  }
}
    ${ClinicSettingsConversationsChannelStatusFragmentDoc}`;
export type CreateClinicSettingsConversationsChannelStatusMutationFn = Apollo.MutationFunction<CreateClinicSettingsConversationsChannelStatusMutation, CreateClinicSettingsConversationsChannelStatusMutationVariables>;

/**
 * __useCreateClinicSettingsConversationsChannelStatusMutation__
 *
 * To run a mutation, you first call `useCreateClinicSettingsConversationsChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicSettingsConversationsChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicSettingsConversationsChannelStatusMutation, { data, loading, error }] = useCreateClinicSettingsConversationsChannelStatusMutation({
 *   variables: {
 *      clinicSettingId: // value for 'clinicSettingId'
 *      channelStatusAction: // value for 'channelStatusAction'
 *      color: // value for 'color'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateClinicSettingsConversationsChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateClinicSettingsConversationsChannelStatusMutation, CreateClinicSettingsConversationsChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClinicSettingsConversationsChannelStatusMutation, CreateClinicSettingsConversationsChannelStatusMutationVariables>(CreateClinicSettingsConversationsChannelStatusDocument, options);
      }
export type CreateClinicSettingsConversationsChannelStatusMutationHookResult = ReturnType<typeof useCreateClinicSettingsConversationsChannelStatusMutation>;
export type CreateClinicSettingsConversationsChannelStatusMutationResult = Apollo.MutationResult<CreateClinicSettingsConversationsChannelStatusMutation>;
export type CreateClinicSettingsConversationsChannelStatusMutationOptions = Apollo.BaseMutationOptions<CreateClinicSettingsConversationsChannelStatusMutation, CreateClinicSettingsConversationsChannelStatusMutationVariables>;
export const CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument = gql`
    mutation createClinicSettingsConversationsChannelStatusAutoUpdateRule($data: RulesCreateInput!) {
  createOneRules(data: $data) {
    ...ClinicSettingsConversationsRule
  }
}
    ${ClinicSettingsConversationsRuleFragmentDoc}`;
export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationFn = Apollo.MutationFunction<CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;

/**
 * __useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation__
 *
 * To run a mutation, you first call `useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, { data, loading, error }] = useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>(CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument, options);
      }
export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationHookResult = ReturnType<typeof useCreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationResult = Apollo.MutationResult<CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationOptions = Apollo.BaseMutationOptions<CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, CreateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;
export const CreateClinicSettingsConversationsChannelStatusChannelAssigneeDocument = gql`
    mutation createClinicSettingsConversationsChannelStatusChannelAssignee($data: ChannelStatusChannelAssigneeCreateInput!) {
  createOneChannelStatusChannelAssignee(data: $data) {
    ...ClinicSettingsConversationsChannelStatusChannelAssignee
  }
}
    ${ClinicSettingsConversationsChannelStatusChannelAssigneeFragmentDoc}`;
export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationFn = Apollo.MutationFunction<CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation, CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>;

/**
 * __useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation__
 *
 * To run a mutation, you first call `useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicSettingsConversationsChannelStatusChannelAssigneeMutation, { data, loading, error }] = useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation, CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation, CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>(CreateClinicSettingsConversationsChannelStatusChannelAssigneeDocument, options);
      }
export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationHookResult = ReturnType<typeof useCreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation>;
export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationResult = Apollo.MutationResult<CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation>;
export type CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationOptions = Apollo.BaseMutationOptions<CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutation, CreateClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>;
export const DeleteClinicSettingsConversationsChannelStatusDocument = gql`
    mutation deleteClinicSettingsConversationsChannelStatus($data: ChannelStatusUpdateInput!, $channelStatusId: String!) {
  updateOneChannelStatus(data: $data, where: {id: $channelStatusId}) {
    id
  }
}
    `;
export type DeleteClinicSettingsConversationsChannelStatusMutationFn = Apollo.MutationFunction<DeleteClinicSettingsConversationsChannelStatusMutation, DeleteClinicSettingsConversationsChannelStatusMutationVariables>;

/**
 * __useDeleteClinicSettingsConversationsChannelStatusMutation__
 *
 * To run a mutation, you first call `useDeleteClinicSettingsConversationsChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicSettingsConversationsChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicSettingsConversationsChannelStatusMutation, { data, loading, error }] = useDeleteClinicSettingsConversationsChannelStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *      channelStatusId: // value for 'channelStatusId'
 *   },
 * });
 */
export function useDeleteClinicSettingsConversationsChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClinicSettingsConversationsChannelStatusMutation, DeleteClinicSettingsConversationsChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClinicSettingsConversationsChannelStatusMutation, DeleteClinicSettingsConversationsChannelStatusMutationVariables>(DeleteClinicSettingsConversationsChannelStatusDocument, options);
      }
export type DeleteClinicSettingsConversationsChannelStatusMutationHookResult = ReturnType<typeof useDeleteClinicSettingsConversationsChannelStatusMutation>;
export type DeleteClinicSettingsConversationsChannelStatusMutationResult = Apollo.MutationResult<DeleteClinicSettingsConversationsChannelStatusMutation>;
export type DeleteClinicSettingsConversationsChannelStatusMutationOptions = Apollo.BaseMutationOptions<DeleteClinicSettingsConversationsChannelStatusMutation, DeleteClinicSettingsConversationsChannelStatusMutationVariables>;
export const DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument = gql`
    mutation deleteClinicSettingsConversationsChannelStatusAutoUpdateRule($ruleId: String!) {
  deleteOneRules(where: {id: $ruleId}) {
    id
  }
}
    `;
export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationFn = Apollo.MutationFunction<DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;

/**
 * __useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation__
 *
 * To run a mutation, you first call `useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, { data, loading, error }] = useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>(DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument, options);
      }
export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationHookResult = ReturnType<typeof useDeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationResult = Apollo.MutationResult<DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationOptions = Apollo.BaseMutationOptions<DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, DeleteClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;
export const DeleteClinicSettingsConversationsChannelStatusChannelAssigneeDocument = gql`
    mutation deleteClinicSettingsConversationsChannelStatusChannelAssignee($id: String) {
  deleteOneChannelStatusChannelAssignee(where: {id: $id}) {
    id
    ...ClinicSettingsConversationsChannelStatusChannelAssignee
  }
}
    ${ClinicSettingsConversationsChannelStatusChannelAssigneeFragmentDoc}`;
export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationFn = Apollo.MutationFunction<DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation, DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>;

/**
 * __useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation__
 *
 * To run a mutation, you first call `useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation, { data, loading, error }] = useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation, DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation, DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>(DeleteClinicSettingsConversationsChannelStatusChannelAssigneeDocument, options);
      }
export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationHookResult = ReturnType<typeof useDeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation>;
export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationResult = Apollo.MutationResult<DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation>;
export type DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationOptions = Apollo.BaseMutationOptions<DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutation, DeleteClinicSettingsConversationsChannelStatusChannelAssigneeMutationVariables>;
export const OrderClinicSettingsConversationsChannelStatusDocument = gql`
    mutation orderClinicSettingsConversationsChannelStatus($data: ChannelStatusUpdateInput!, $channelStatusId: String!) {
  updateOneChannelStatus(data: $data, where: {id: $channelStatusId}) {
    id
    order
    name
  }
}
    `;
export type OrderClinicSettingsConversationsChannelStatusMutationFn = Apollo.MutationFunction<OrderClinicSettingsConversationsChannelStatusMutation, OrderClinicSettingsConversationsChannelStatusMutationVariables>;

/**
 * __useOrderClinicSettingsConversationsChannelStatusMutation__
 *
 * To run a mutation, you first call `useOrderClinicSettingsConversationsChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderClinicSettingsConversationsChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderClinicSettingsConversationsChannelStatusMutation, { data, loading, error }] = useOrderClinicSettingsConversationsChannelStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *      channelStatusId: // value for 'channelStatusId'
 *   },
 * });
 */
export function useOrderClinicSettingsConversationsChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<OrderClinicSettingsConversationsChannelStatusMutation, OrderClinicSettingsConversationsChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderClinicSettingsConversationsChannelStatusMutation, OrderClinicSettingsConversationsChannelStatusMutationVariables>(OrderClinicSettingsConversationsChannelStatusDocument, options);
      }
export type OrderClinicSettingsConversationsChannelStatusMutationHookResult = ReturnType<typeof useOrderClinicSettingsConversationsChannelStatusMutation>;
export type OrderClinicSettingsConversationsChannelStatusMutationResult = Apollo.MutationResult<OrderClinicSettingsConversationsChannelStatusMutation>;
export type OrderClinicSettingsConversationsChannelStatusMutationOptions = Apollo.BaseMutationOptions<OrderClinicSettingsConversationsChannelStatusMutation, OrderClinicSettingsConversationsChannelStatusMutationVariables>;
export const UpdateClinicSettingsConversationsChannelStatusDocument = gql`
    mutation updateClinicSettingsConversationsChannelStatus($data: ChannelStatusUpdateInput!, $channelStatusId: String!) {
  updateOneChannelStatus(data: $data, where: {id: $channelStatusId}) {
    ...ClinicSettingsConversationsChannelStatus
  }
}
    ${ClinicSettingsConversationsChannelStatusFragmentDoc}`;
export type UpdateClinicSettingsConversationsChannelStatusMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsChannelStatusMutation, UpdateClinicSettingsConversationsChannelStatusMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsChannelStatusMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsChannelStatusMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsChannelStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *      channelStatusId: // value for 'channelStatusId'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsChannelStatusMutation, UpdateClinicSettingsConversationsChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsChannelStatusMutation, UpdateClinicSettingsConversationsChannelStatusMutationVariables>(UpdateClinicSettingsConversationsChannelStatusDocument, options);
      }
export type UpdateClinicSettingsConversationsChannelStatusMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsChannelStatusMutation>;
export type UpdateClinicSettingsConversationsChannelStatusMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsChannelStatusMutation>;
export type UpdateClinicSettingsConversationsChannelStatusMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsChannelStatusMutation, UpdateClinicSettingsConversationsChannelStatusMutationVariables>;
export const UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument = gql`
    mutation updateClinicSettingsConversationsChannelStatusAutoUpdateRule($data: RulesUpdateInput!, $ruleId: String!) {
  updateOneRules(data: $data, where: {id: $ruleId}) {
    ...ClinicSettingsConversationsRule
  }
}
    ${ClinicSettingsConversationsRuleFragmentDoc}`;
export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>(UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleDocument, options);
      }
export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation>;
export type UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutation, UpdateClinicSettingsConversationsChannelStatusAutoUpdateRuleMutationVariables>;
export const UpdateClinicSettingsConversationsChannelStatusSortOrderDocument = gql`
    mutation updateClinicSettingsConversationsChannelStatusSortOrder($data: [ChannelStatusWithOrder!]) {
  updateManyChannelStatusSortOrder(data: $data) {
    success
    error
  }
}
    `;
export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsChannelStatusSortOrderMutation, UpdateClinicSettingsConversationsChannelStatusSortOrderMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsChannelStatusSortOrderMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsChannelStatusSortOrderMutation, UpdateClinicSettingsConversationsChannelStatusSortOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsChannelStatusSortOrderMutation, UpdateClinicSettingsConversationsChannelStatusSortOrderMutationVariables>(UpdateClinicSettingsConversationsChannelStatusSortOrderDocument, options);
      }
export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsChannelStatusSortOrderMutation>;
export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsChannelStatusSortOrderMutation>;
export type UpdateClinicSettingsConversationsChannelStatusSortOrderMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsChannelStatusSortOrderMutation, UpdateClinicSettingsConversationsChannelStatusSortOrderMutationVariables>;
export const GetClinicSettingsClinicSettingIdDocument = gql`
    query getClinicSettingsClinicSettingId($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    clinicSetting {
      id
    }
  }
}
    `;

/**
 * __useGetClinicSettingsClinicSettingIdQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsClinicSettingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsClinicSettingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsClinicSettingIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsClinicSettingIdQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsClinicSettingIdQuery, GetClinicSettingsClinicSettingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsClinicSettingIdQuery, GetClinicSettingsClinicSettingIdQueryVariables>(GetClinicSettingsClinicSettingIdDocument, options);
      }
export function useGetClinicSettingsClinicSettingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsClinicSettingIdQuery, GetClinicSettingsClinicSettingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsClinicSettingIdQuery, GetClinicSettingsClinicSettingIdQueryVariables>(GetClinicSettingsClinicSettingIdDocument, options);
        }
export type GetClinicSettingsClinicSettingIdQueryHookResult = ReturnType<typeof useGetClinicSettingsClinicSettingIdQuery>;
export type GetClinicSettingsClinicSettingIdLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsClinicSettingIdLazyQuery>;
export type GetClinicSettingsClinicSettingIdQueryResult = Apollo.QueryResult<GetClinicSettingsClinicSettingIdQuery, GetClinicSettingsClinicSettingIdQueryVariables>;
export function refetchGetClinicSettingsClinicSettingIdQuery(variables?: GetClinicSettingsClinicSettingIdQueryVariables) {
      return { query: GetClinicSettingsClinicSettingIdDocument, variables: variables }
    }
export const GetClinicSettingsConversationsChannelStatusAutoUpdateRulesDocument = gql`
    query getClinicSettingsConversationsChannelStatusAutoUpdateRules($clinicId: String!) {
  findManyRules(
    where: {clinicId: {equals: $clinicId}, action: {equals: UpdateInactiveChannelStatus}}
    orderBy: {updatedAt: asc}
  ) {
    ...ClinicSettingsConversationsRule
  }
}
    ${ClinicSettingsConversationsRuleFragmentDoc}`;

/**
 * __useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery, GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery, GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables>(GetClinicSettingsConversationsChannelStatusAutoUpdateRulesDocument, options);
      }
export function useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery, GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery, GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables>(GetClinicSettingsConversationsChannelStatusAutoUpdateRulesDocument, options);
        }
export type GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery>;
export type GetClinicSettingsConversationsChannelStatusAutoUpdateRulesLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelStatusAutoUpdateRulesLazyQuery>;
export type GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryResult = Apollo.QueryResult<GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery, GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables>;
export function refetchGetClinicSettingsConversationsChannelStatusAutoUpdateRulesQuery(variables?: GetClinicSettingsConversationsChannelStatusAutoUpdateRulesQueryVariables) {
      return { query: GetClinicSettingsConversationsChannelStatusAutoUpdateRulesDocument, variables: variables }
    }
export const GetClinicSettingsConversationsChannelStatusesDocument = gql`
    query getClinicSettingsConversationsChannelStatuses($clinicId: String!) {
  findManyChannelStatus(
    where: {clinicSetting: {clinic: {id: {equals: $clinicId}}}, deletedOn: {equals: null}}
    orderBy: {order: asc}
  ) {
    ...ClinicSettingsConversationsChannelStatus
  }
}
    ${ClinicSettingsConversationsChannelStatusFragmentDoc}`;

/**
 * __useGetClinicSettingsConversationsChannelStatusesQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConversationsChannelStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConversationsChannelStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConversationsChannelStatusesQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicSettingsConversationsChannelStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsConversationsChannelStatusesQuery, GetClinicSettingsConversationsChannelStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConversationsChannelStatusesQuery, GetClinicSettingsConversationsChannelStatusesQueryVariables>(GetClinicSettingsConversationsChannelStatusesDocument, options);
      }
export function useGetClinicSettingsConversationsChannelStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConversationsChannelStatusesQuery, GetClinicSettingsConversationsChannelStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConversationsChannelStatusesQuery, GetClinicSettingsConversationsChannelStatusesQueryVariables>(GetClinicSettingsConversationsChannelStatusesDocument, options);
        }
export type GetClinicSettingsConversationsChannelStatusesQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelStatusesQuery>;
export type GetClinicSettingsConversationsChannelStatusesLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelStatusesLazyQuery>;
export type GetClinicSettingsConversationsChannelStatusesQueryResult = Apollo.QueryResult<GetClinicSettingsConversationsChannelStatusesQuery, GetClinicSettingsConversationsChannelStatusesQueryVariables>;
export function refetchGetClinicSettingsConversationsChannelStatusesQuery(variables?: GetClinicSettingsConversationsChannelStatusesQueryVariables) {
      return { query: GetClinicSettingsConversationsChannelStatusesDocument, variables: variables }
    }
export const GetClinicSettingsConversationsChannelsWithStatusesDocument = gql`
    query getClinicSettingsConversationsChannelsWithStatuses($where: ChannelWhereInput) {
  findManyChannel(where: $where) {
    id
    lastMessage {
      createdAt
    }
    updatedAt
  }
}
    `;

/**
 * __useGetClinicSettingsConversationsChannelsWithStatusesQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConversationsChannelsWithStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConversationsChannelsWithStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConversationsChannelsWithStatusesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsConversationsChannelsWithStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicSettingsConversationsChannelsWithStatusesQuery, GetClinicSettingsConversationsChannelsWithStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConversationsChannelsWithStatusesQuery, GetClinicSettingsConversationsChannelsWithStatusesQueryVariables>(GetClinicSettingsConversationsChannelsWithStatusesDocument, options);
      }
export function useGetClinicSettingsConversationsChannelsWithStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConversationsChannelsWithStatusesQuery, GetClinicSettingsConversationsChannelsWithStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConversationsChannelsWithStatusesQuery, GetClinicSettingsConversationsChannelsWithStatusesQueryVariables>(GetClinicSettingsConversationsChannelsWithStatusesDocument, options);
        }
export type GetClinicSettingsConversationsChannelsWithStatusesQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelsWithStatusesQuery>;
export type GetClinicSettingsConversationsChannelsWithStatusesLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsChannelsWithStatusesLazyQuery>;
export type GetClinicSettingsConversationsChannelsWithStatusesQueryResult = Apollo.QueryResult<GetClinicSettingsConversationsChannelsWithStatusesQuery, GetClinicSettingsConversationsChannelsWithStatusesQueryVariables>;
export function refetchGetClinicSettingsConversationsChannelsWithStatusesQuery(variables?: GetClinicSettingsConversationsChannelsWithStatusesQueryVariables) {
      return { query: GetClinicSettingsConversationsChannelsWithStatusesDocument, variables: variables }
    }
export const GetAutomationOverviewDocument = gql`
    query getAutomationOverview($clinicId: String) {
  findManyWorkflowEventSetting(
    where: {clinicId: {equals: $clinicId}, deletedAt: null}
    orderBy: {name: asc}
  ) {
    id
    name
    isPublished
    event
    visitType
    automatedTriggerType
    automatedTriggerInMinutes
    appointmentTypes {
      id
      name
    }
    clinicEmployees {
      clinicEmployee {
        id
        firstName
        lastName
      }
    }
    clinicRooms {
      clinicRoom {
        id
        name
      }
    }
    conditionSets {
      conditions {
        id
        attribute
        operand
      }
    }
    automatedTriggerInMinutes
    sendingHours
    notificationBody
    actions {
      id
      order
      promptType
      config
      channelStatusChange {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAutomationOverviewQuery__
 *
 * To run a query within a React component, call `useGetAutomationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationOverviewQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAutomationOverviewQuery(baseOptions?: Apollo.QueryHookOptions<GetAutomationOverviewQuery, GetAutomationOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationOverviewQuery, GetAutomationOverviewQueryVariables>(GetAutomationOverviewDocument, options);
      }
export function useGetAutomationOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationOverviewQuery, GetAutomationOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationOverviewQuery, GetAutomationOverviewQueryVariables>(GetAutomationOverviewDocument, options);
        }
export type GetAutomationOverviewQueryHookResult = ReturnType<typeof useGetAutomationOverviewQuery>;
export type GetAutomationOverviewLazyQueryHookResult = ReturnType<typeof useGetAutomationOverviewLazyQuery>;
export type GetAutomationOverviewQueryResult = Apollo.QueryResult<GetAutomationOverviewQuery, GetAutomationOverviewQueryVariables>;
export function refetchGetAutomationOverviewQuery(variables?: GetAutomationOverviewQueryVariables) {
      return { query: GetAutomationOverviewDocument, variables: variables }
    }
export const FindAutomationsForVisualizerDocument = gql`
    query findAutomationsForVisualizer($where: WorkflowEventSettingWhereInput) {
  findManyWorkflowEventSetting(where: $where, orderBy: {name: asc}) {
    ...VisualizerAutomation
  }
}
    ${VisualizerAutomationFragmentDoc}`;

/**
 * __useFindAutomationsForVisualizerQuery__
 *
 * To run a query within a React component, call `useFindAutomationsForVisualizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAutomationsForVisualizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAutomationsForVisualizerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindAutomationsForVisualizerQuery(baseOptions?: Apollo.QueryHookOptions<FindAutomationsForVisualizerQuery, FindAutomationsForVisualizerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAutomationsForVisualizerQuery, FindAutomationsForVisualizerQueryVariables>(FindAutomationsForVisualizerDocument, options);
      }
export function useFindAutomationsForVisualizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAutomationsForVisualizerQuery, FindAutomationsForVisualizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAutomationsForVisualizerQuery, FindAutomationsForVisualizerQueryVariables>(FindAutomationsForVisualizerDocument, options);
        }
export type FindAutomationsForVisualizerQueryHookResult = ReturnType<typeof useFindAutomationsForVisualizerQuery>;
export type FindAutomationsForVisualizerLazyQueryHookResult = ReturnType<typeof useFindAutomationsForVisualizerLazyQuery>;
export type FindAutomationsForVisualizerQueryResult = Apollo.QueryResult<FindAutomationsForVisualizerQuery, FindAutomationsForVisualizerQueryVariables>;
export function refetchFindAutomationsForVisualizerQuery(variables?: FindAutomationsForVisualizerQueryVariables) {
      return { query: FindAutomationsForVisualizerDocument, variables: variables }
    }
export const FindFormTemplatesForVisualizerDocument = gql`
    query findFormTemplatesForVisualizer($where: FormTemplateWhereInput) {
  findManyFormTemplate(where: $where) {
    id
    title
  }
}
    `;

/**
 * __useFindFormTemplatesForVisualizerQuery__
 *
 * To run a query within a React component, call `useFindFormTemplatesForVisualizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFormTemplatesForVisualizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFormTemplatesForVisualizerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFormTemplatesForVisualizerQuery(baseOptions?: Apollo.QueryHookOptions<FindFormTemplatesForVisualizerQuery, FindFormTemplatesForVisualizerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFormTemplatesForVisualizerQuery, FindFormTemplatesForVisualizerQueryVariables>(FindFormTemplatesForVisualizerDocument, options);
      }
export function useFindFormTemplatesForVisualizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFormTemplatesForVisualizerQuery, FindFormTemplatesForVisualizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFormTemplatesForVisualizerQuery, FindFormTemplatesForVisualizerQueryVariables>(FindFormTemplatesForVisualizerDocument, options);
        }
export type FindFormTemplatesForVisualizerQueryHookResult = ReturnType<typeof useFindFormTemplatesForVisualizerQuery>;
export type FindFormTemplatesForVisualizerLazyQueryHookResult = ReturnType<typeof useFindFormTemplatesForVisualizerLazyQuery>;
export type FindFormTemplatesForVisualizerQueryResult = Apollo.QueryResult<FindFormTemplatesForVisualizerQuery, FindFormTemplatesForVisualizerQueryVariables>;
export function refetchFindFormTemplatesForVisualizerQuery(variables?: FindFormTemplatesForVisualizerQueryVariables) {
      return { query: FindFormTemplatesForVisualizerDocument, variables: variables }
    }
export const FindChannelStatusForVisualizerDocument = gql`
    query findChannelStatusForVisualizer($where: ChannelStatusWhereInput) {
  findManyChannelStatus(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useFindChannelStatusForVisualizerQuery__
 *
 * To run a query within a React component, call `useFindChannelStatusForVisualizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChannelStatusForVisualizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChannelStatusForVisualizerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindChannelStatusForVisualizerQuery(baseOptions?: Apollo.QueryHookOptions<FindChannelStatusForVisualizerQuery, FindChannelStatusForVisualizerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindChannelStatusForVisualizerQuery, FindChannelStatusForVisualizerQueryVariables>(FindChannelStatusForVisualizerDocument, options);
      }
export function useFindChannelStatusForVisualizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindChannelStatusForVisualizerQuery, FindChannelStatusForVisualizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindChannelStatusForVisualizerQuery, FindChannelStatusForVisualizerQueryVariables>(FindChannelStatusForVisualizerDocument, options);
        }
export type FindChannelStatusForVisualizerQueryHookResult = ReturnType<typeof useFindChannelStatusForVisualizerQuery>;
export type FindChannelStatusForVisualizerLazyQueryHookResult = ReturnType<typeof useFindChannelStatusForVisualizerLazyQuery>;
export type FindChannelStatusForVisualizerQueryResult = Apollo.QueryResult<FindChannelStatusForVisualizerQuery, FindChannelStatusForVisualizerQueryVariables>;
export function refetchFindChannelStatusForVisualizerQuery(variables?: FindChannelStatusForVisualizerQueryVariables) {
      return { query: FindChannelStatusForVisualizerDocument, variables: variables }
    }
export const CreateEmergencyClinicDocument = gql`
    mutation createEmergencyClinic($data: EmergencyClinicsCreateInput!) {
  createOneEmergencyClinics(data: $data) {
    ...EmergencyClinics
  }
}
    ${EmergencyClinicsFragmentDoc}`;
export type CreateEmergencyClinicMutationFn = Apollo.MutationFunction<CreateEmergencyClinicMutation, CreateEmergencyClinicMutationVariables>;

/**
 * __useCreateEmergencyClinicMutation__
 *
 * To run a mutation, you first call `useCreateEmergencyClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmergencyClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmergencyClinicMutation, { data, loading, error }] = useCreateEmergencyClinicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEmergencyClinicMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmergencyClinicMutation, CreateEmergencyClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmergencyClinicMutation, CreateEmergencyClinicMutationVariables>(CreateEmergencyClinicDocument, options);
      }
export type CreateEmergencyClinicMutationHookResult = ReturnType<typeof useCreateEmergencyClinicMutation>;
export type CreateEmergencyClinicMutationResult = Apollo.MutationResult<CreateEmergencyClinicMutation>;
export type CreateEmergencyClinicMutationOptions = Apollo.BaseMutationOptions<CreateEmergencyClinicMutation, CreateEmergencyClinicMutationVariables>;
export const SoftDeleteEmergencyClinicDocument = gql`
    mutation softDeleteEmergencyClinic($where: EmergencyClinicsWhereUniqueInput!) {
  updateOneEmergencyClinics(where: $where, data: {isDeleted: true}) {
    id
  }
}
    `;
export type SoftDeleteEmergencyClinicMutationFn = Apollo.MutationFunction<SoftDeleteEmergencyClinicMutation, SoftDeleteEmergencyClinicMutationVariables>;

/**
 * __useSoftDeleteEmergencyClinicMutation__
 *
 * To run a mutation, you first call `useSoftDeleteEmergencyClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteEmergencyClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteEmergencyClinicMutation, { data, loading, error }] = useSoftDeleteEmergencyClinicMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSoftDeleteEmergencyClinicMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteEmergencyClinicMutation, SoftDeleteEmergencyClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteEmergencyClinicMutation, SoftDeleteEmergencyClinicMutationVariables>(SoftDeleteEmergencyClinicDocument, options);
      }
export type SoftDeleteEmergencyClinicMutationHookResult = ReturnType<typeof useSoftDeleteEmergencyClinicMutation>;
export type SoftDeleteEmergencyClinicMutationResult = Apollo.MutationResult<SoftDeleteEmergencyClinicMutation>;
export type SoftDeleteEmergencyClinicMutationOptions = Apollo.BaseMutationOptions<SoftDeleteEmergencyClinicMutation, SoftDeleteEmergencyClinicMutationVariables>;
export const UpdateClinicSettingsClinicDocument = gql`
    mutation updateClinicSettingsClinic($data: ClinicUpdateInput!, $id: String!) {
  updateOneClinic(data: $data, where: {id: $id}) {
    id
    timezoneName
    name
    logo
    phone
    fullAddress
    websiteUrl
    addressLine1
    addressLine2
    postalCode
    country
    city
    state
  }
}
    `;
export type UpdateClinicSettingsClinicMutationFn = Apollo.MutationFunction<UpdateClinicSettingsClinicMutation, UpdateClinicSettingsClinicMutationVariables>;

/**
 * __useUpdateClinicSettingsClinicMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsClinicMutation, { data, loading, error }] = useUpdateClinicSettingsClinicMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateClinicSettingsClinicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsClinicMutation, UpdateClinicSettingsClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsClinicMutation, UpdateClinicSettingsClinicMutationVariables>(UpdateClinicSettingsClinicDocument, options);
      }
export type UpdateClinicSettingsClinicMutationHookResult = ReturnType<typeof useUpdateClinicSettingsClinicMutation>;
export type UpdateClinicSettingsClinicMutationResult = Apollo.MutationResult<UpdateClinicSettingsClinicMutation>;
export type UpdateClinicSettingsClinicMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsClinicMutation, UpdateClinicSettingsClinicMutationVariables>;
export const UpdateClinicSettingsOutOfOfficeInformationDocument = gql`
    mutation updateClinicSettingsOutOfOfficeInformation($data: ClinicSettingUpdateInput!, $id: String) {
  updateOneClinicSetting(data: $data, where: {id: $id}) {
    id
    outOfOfficeHeadline
    outOfOfficeEnabled
    outOfOfficeShowScheduleEnabled
    emergencyContactsEnabled
  }
}
    `;
export type UpdateClinicSettingsOutOfOfficeInformationMutationFn = Apollo.MutationFunction<UpdateClinicSettingsOutOfOfficeInformationMutation, UpdateClinicSettingsOutOfOfficeInformationMutationVariables>;

/**
 * __useUpdateClinicSettingsOutOfOfficeInformationMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsOutOfOfficeInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsOutOfOfficeInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsOutOfOfficeInformationMutation, { data, loading, error }] = useUpdateClinicSettingsOutOfOfficeInformationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateClinicSettingsOutOfOfficeInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsOutOfOfficeInformationMutation, UpdateClinicSettingsOutOfOfficeInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsOutOfOfficeInformationMutation, UpdateClinicSettingsOutOfOfficeInformationMutationVariables>(UpdateClinicSettingsOutOfOfficeInformationDocument, options);
      }
export type UpdateClinicSettingsOutOfOfficeInformationMutationHookResult = ReturnType<typeof useUpdateClinicSettingsOutOfOfficeInformationMutation>;
export type UpdateClinicSettingsOutOfOfficeInformationMutationResult = Apollo.MutationResult<UpdateClinicSettingsOutOfOfficeInformationMutation>;
export type UpdateClinicSettingsOutOfOfficeInformationMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsOutOfOfficeInformationMutation, UpdateClinicSettingsOutOfOfficeInformationMutationVariables>;
export const UpdateClinicSettingsGeneralVerifyTwilioCallerIdDocument = gql`
    mutation updateClinicSettingsGeneralVerifyTwilioCallerId($data: TwilioVerifyCallerIdInput!) {
  twilioVerifyCallerId(data: $data) {
    callSid
    validationCode
  }
}
    `;
export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationFn = Apollo.MutationFunction<UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation, UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationVariables>;

/**
 * __useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsGeneralVerifyTwilioCallerIdMutation, { data, loading, error }] = useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation, UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation, UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationVariables>(UpdateClinicSettingsGeneralVerifyTwilioCallerIdDocument, options);
      }
export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationHookResult = ReturnType<typeof useUpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation>;
export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationResult = Apollo.MutationResult<UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation>;
export type UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutation, UpdateClinicSettingsGeneralVerifyTwilioCallerIdMutationVariables>;
export const UpdateEmergencyClinicDocument = gql`
    mutation updateEmergencyClinic($where: EmergencyClinicsWhereUniqueInput!, $data: EmergencyClinicsUpdateInput!) {
  updateOneEmergencyClinics(where: $where, data: $data) {
    ...EmergencyClinics
  }
}
    ${EmergencyClinicsFragmentDoc}`;
export type UpdateEmergencyClinicMutationFn = Apollo.MutationFunction<UpdateEmergencyClinicMutation, UpdateEmergencyClinicMutationVariables>;

/**
 * __useUpdateEmergencyClinicMutation__
 *
 * To run a mutation, you first call `useUpdateEmergencyClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmergencyClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmergencyClinicMutation, { data, loading, error }] = useUpdateEmergencyClinicMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmergencyClinicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmergencyClinicMutation, UpdateEmergencyClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmergencyClinicMutation, UpdateEmergencyClinicMutationVariables>(UpdateEmergencyClinicDocument, options);
      }
export type UpdateEmergencyClinicMutationHookResult = ReturnType<typeof useUpdateEmergencyClinicMutation>;
export type UpdateEmergencyClinicMutationResult = Apollo.MutationResult<UpdateEmergencyClinicMutation>;
export type UpdateEmergencyClinicMutationOptions = Apollo.BaseMutationOptions<UpdateEmergencyClinicMutation, UpdateEmergencyClinicMutationVariables>;
export const UpdateEmergencyClinicDisplayOrderDocument = gql`
    mutation updateEmergencyClinicDisplayOrder($where: EmergencyClinicsWhereUniqueInput!, $data: Int!) {
  updateOneEmergencyClinics(where: $where, data: {displayOrder: $data}) {
    id
  }
}
    `;
export type UpdateEmergencyClinicDisplayOrderMutationFn = Apollo.MutationFunction<UpdateEmergencyClinicDisplayOrderMutation, UpdateEmergencyClinicDisplayOrderMutationVariables>;

/**
 * __useUpdateEmergencyClinicDisplayOrderMutation__
 *
 * To run a mutation, you first call `useUpdateEmergencyClinicDisplayOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmergencyClinicDisplayOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmergencyClinicDisplayOrderMutation, { data, loading, error }] = useUpdateEmergencyClinicDisplayOrderMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmergencyClinicDisplayOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmergencyClinicDisplayOrderMutation, UpdateEmergencyClinicDisplayOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmergencyClinicDisplayOrderMutation, UpdateEmergencyClinicDisplayOrderMutationVariables>(UpdateEmergencyClinicDisplayOrderDocument, options);
      }
export type UpdateEmergencyClinicDisplayOrderMutationHookResult = ReturnType<typeof useUpdateEmergencyClinicDisplayOrderMutation>;
export type UpdateEmergencyClinicDisplayOrderMutationResult = Apollo.MutationResult<UpdateEmergencyClinicDisplayOrderMutation>;
export type UpdateEmergencyClinicDisplayOrderMutationOptions = Apollo.BaseMutationOptions<UpdateEmergencyClinicDisplayOrderMutation, UpdateEmergencyClinicDisplayOrderMutationVariables>;
export const FindEmployerIdentificationNumberDocument = gql`
    query FindEmployerIdentificationNumber($data: FindEmployerIdentificationNumberInput!) {
  findEmployerIdentificationNumber(data: $data) {
    ein
    companyName
    address
    companyPhone
    url
    businessName
    error
  }
}
    `;

/**
 * __useFindEmployerIdentificationNumberQuery__
 *
 * To run a query within a React component, call `useFindEmployerIdentificationNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmployerIdentificationNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmployerIdentificationNumberQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFindEmployerIdentificationNumberQuery(baseOptions: Apollo.QueryHookOptions<FindEmployerIdentificationNumberQuery, FindEmployerIdentificationNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEmployerIdentificationNumberQuery, FindEmployerIdentificationNumberQueryVariables>(FindEmployerIdentificationNumberDocument, options);
      }
export function useFindEmployerIdentificationNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEmployerIdentificationNumberQuery, FindEmployerIdentificationNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEmployerIdentificationNumberQuery, FindEmployerIdentificationNumberQueryVariables>(FindEmployerIdentificationNumberDocument, options);
        }
export type FindEmployerIdentificationNumberQueryHookResult = ReturnType<typeof useFindEmployerIdentificationNumberQuery>;
export type FindEmployerIdentificationNumberLazyQueryHookResult = ReturnType<typeof useFindEmployerIdentificationNumberLazyQuery>;
export type FindEmployerIdentificationNumberQueryResult = Apollo.QueryResult<FindEmployerIdentificationNumberQuery, FindEmployerIdentificationNumberQueryVariables>;
export function refetchFindEmployerIdentificationNumberQuery(variables?: FindEmployerIdentificationNumberQueryVariables) {
      return { query: FindEmployerIdentificationNumberDocument, variables: variables }
    }
export const GetClinicSettingsGeneralDocument = gql`
    query getClinicSettingsGeneral($id: String) {
  findUniqueClinic(where: {id: $id}) {
    id
    addressLine1
    addressLine2
    city
    clinicOfficeHours(orderBy: {dayOfWeek: asc}) {
      ...ClinicOfficeHourSelection
    }
    clinicSetting {
      id
      outOfOfficeEnabled
      outOfOfficeHeadline
      outOfOfficeShowScheduleEnabled
      emergencyContactsEnabled
      emergencyClinics {
        ...EmergencyClinics
      }
    }
    country
    fullAddress
    logo
    name
    phone
    clinicEmail
    postalCode
    state
    timezoneName
    websiteUrl
    isCallerIdVerified
    twilioVerifications {
      id
      phoneNumber
      status
    }
  }
}
    ${ClinicOfficeHourSelectionFragmentDoc}
${EmergencyClinicsFragmentDoc}`;

/**
 * __useGetClinicSettingsGeneralQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsGeneralQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsGeneralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsGeneralQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClinicSettingsGeneralQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicSettingsGeneralQuery, GetClinicSettingsGeneralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsGeneralQuery, GetClinicSettingsGeneralQueryVariables>(GetClinicSettingsGeneralDocument, options);
      }
export function useGetClinicSettingsGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsGeneralQuery, GetClinicSettingsGeneralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsGeneralQuery, GetClinicSettingsGeneralQueryVariables>(GetClinicSettingsGeneralDocument, options);
        }
export type GetClinicSettingsGeneralQueryHookResult = ReturnType<typeof useGetClinicSettingsGeneralQuery>;
export type GetClinicSettingsGeneralLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsGeneralLazyQuery>;
export type GetClinicSettingsGeneralQueryResult = Apollo.QueryResult<GetClinicSettingsGeneralQuery, GetClinicSettingsGeneralQueryVariables>;
export function refetchGetClinicSettingsGeneralQuery(variables?: GetClinicSettingsGeneralQueryVariables) {
      return { query: GetClinicSettingsGeneralDocument, variables: variables }
    }
export const FindClinicLegalEntityDocument = gql`
    query findClinicLegalEntity($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    legalEntity {
      ...LegalEntity
    }
  }
}
    ${LegalEntityFragmentDoc}`;

/**
 * __useFindClinicLegalEntityQuery__
 *
 * To run a query within a React component, call `useFindClinicLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClinicLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClinicLegalEntityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindClinicLegalEntityQuery(baseOptions: Apollo.QueryHookOptions<FindClinicLegalEntityQuery, FindClinicLegalEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindClinicLegalEntityQuery, FindClinicLegalEntityQueryVariables>(FindClinicLegalEntityDocument, options);
      }
export function useFindClinicLegalEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindClinicLegalEntityQuery, FindClinicLegalEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindClinicLegalEntityQuery, FindClinicLegalEntityQueryVariables>(FindClinicLegalEntityDocument, options);
        }
export type FindClinicLegalEntityQueryHookResult = ReturnType<typeof useFindClinicLegalEntityQuery>;
export type FindClinicLegalEntityLazyQueryHookResult = ReturnType<typeof useFindClinicLegalEntityLazyQuery>;
export type FindClinicLegalEntityQueryResult = Apollo.QueryResult<FindClinicLegalEntityQuery, FindClinicLegalEntityQueryVariables>;
export function refetchFindClinicLegalEntityQuery(variables?: FindClinicLegalEntityQueryVariables) {
      return { query: FindClinicLegalEntityDocument, variables: variables }
    }
export const UpsertOneLegalEntityDocument = gql`
    mutation upsertOneLegalEntity($where: LegalEntityWhereUniqueInput!, $create: LegalEntityCreateInput!, $update: LegalEntityUpdateInput!) {
  upsertOneLegalEntity(where: $where, create: $create, update: $update) {
    ...LegalEntity
  }
}
    ${LegalEntityFragmentDoc}`;
export type UpsertOneLegalEntityMutationFn = Apollo.MutationFunction<UpsertOneLegalEntityMutation, UpsertOneLegalEntityMutationVariables>;

/**
 * __useUpsertOneLegalEntityMutation__
 *
 * To run a mutation, you first call `useUpsertOneLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOneLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOneLegalEntityMutation, { data, loading, error }] = useUpsertOneLegalEntityMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertOneLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOneLegalEntityMutation, UpsertOneLegalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOneLegalEntityMutation, UpsertOneLegalEntityMutationVariables>(UpsertOneLegalEntityDocument, options);
      }
export type UpsertOneLegalEntityMutationHookResult = ReturnType<typeof useUpsertOneLegalEntityMutation>;
export type UpsertOneLegalEntityMutationResult = Apollo.MutationResult<UpsertOneLegalEntityMutation>;
export type UpsertOneLegalEntityMutationOptions = Apollo.BaseMutationOptions<UpsertOneLegalEntityMutation, UpsertOneLegalEntityMutationVariables>;
export const SubscribeToClinicSettingsGeneralTwilioVerificationDocument = gql`
    subscription subscribeToClinicSettingsGeneralTwilioVerification($where: SubscriptionTwilioVerificationWhereInput!) {
  twilioVerificationChanged(where: $where) {
    node {
      status
    }
  }
}
    `;

/**
 * __useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToClinicSettingsGeneralTwilioVerificationSubscription, SubscribeToClinicSettingsGeneralTwilioVerificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToClinicSettingsGeneralTwilioVerificationSubscription, SubscribeToClinicSettingsGeneralTwilioVerificationSubscriptionVariables>(SubscribeToClinicSettingsGeneralTwilioVerificationDocument, options);
      }
export type SubscribeToClinicSettingsGeneralTwilioVerificationSubscriptionHookResult = ReturnType<typeof useSubscribeToClinicSettingsGeneralTwilioVerificationSubscription>;
export type SubscribeToClinicSettingsGeneralTwilioVerificationSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClinicSettingsGeneralTwilioVerificationSubscription>;
export const UpdateClinicSettingsNotificationsUserSettingDocument = gql`
    mutation updateClinicSettingsNotificationsUserSetting($data: UserSettingUpdateInput!, $where: UserSettingWhereUniqueInput!) {
  updateOneUserSetting(data: $data, where: $where) {
    id
    browserNotificationFilter {
      id
      assignees {
        id
      }
      includeUnassigned
    }
    snoozeNotificationsUntil
    notificationSettings {
      id
      emailEnabled
      smsEnabled
      soundNotificationUrl
      browserNotificationEnabled
      pushNotificationEnabled
    }
  }
}
    `;
export type UpdateClinicSettingsNotificationsUserSettingMutationFn = Apollo.MutationFunction<UpdateClinicSettingsNotificationsUserSettingMutation, UpdateClinicSettingsNotificationsUserSettingMutationVariables>;

/**
 * __useUpdateClinicSettingsNotificationsUserSettingMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsNotificationsUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsNotificationsUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsNotificationsUserSettingMutation, { data, loading, error }] = useUpdateClinicSettingsNotificationsUserSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClinicSettingsNotificationsUserSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsNotificationsUserSettingMutation, UpdateClinicSettingsNotificationsUserSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsNotificationsUserSettingMutation, UpdateClinicSettingsNotificationsUserSettingMutationVariables>(UpdateClinicSettingsNotificationsUserSettingDocument, options);
      }
export type UpdateClinicSettingsNotificationsUserSettingMutationHookResult = ReturnType<typeof useUpdateClinicSettingsNotificationsUserSettingMutation>;
export type UpdateClinicSettingsNotificationsUserSettingMutationResult = Apollo.MutationResult<UpdateClinicSettingsNotificationsUserSettingMutation>;
export type UpdateClinicSettingsNotificationsUserSettingMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsNotificationsUserSettingMutation, UpdateClinicSettingsNotificationsUserSettingMutationVariables>;
export const GetClinicSettingsNotificationsUserSettingsDocument = gql`
    query getClinicSettingsNotificationsUserSettings($where: UserWhereUniqueInput!) {
  findUniqueUser(where: $where) {
    id
    firebaseId
    firstName
    lastName
    profilePic
    email
    userSetting {
      id
      emailNotifications
      smsNotifications
      browserNotificationFilter {
        id
        assignees {
          id
        }
        includeUnassigned
      }
      snoozeNotificationsUntil
      notificationSettings {
        id
        settingType
        emailEnabled
        smsEnabled
        pushNotificationEnabled
        soundNotificationUrl
        browserNotificationEnabled
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetClinicSettingsNotificationsUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsNotificationsUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsNotificationsUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsNotificationsUserSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsNotificationsUserSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsNotificationsUserSettingsQuery, GetClinicSettingsNotificationsUserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsNotificationsUserSettingsQuery, GetClinicSettingsNotificationsUserSettingsQueryVariables>(GetClinicSettingsNotificationsUserSettingsDocument, options);
      }
export function useGetClinicSettingsNotificationsUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsNotificationsUserSettingsQuery, GetClinicSettingsNotificationsUserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsNotificationsUserSettingsQuery, GetClinicSettingsNotificationsUserSettingsQueryVariables>(GetClinicSettingsNotificationsUserSettingsDocument, options);
        }
export type GetClinicSettingsNotificationsUserSettingsQueryHookResult = ReturnType<typeof useGetClinicSettingsNotificationsUserSettingsQuery>;
export type GetClinicSettingsNotificationsUserSettingsLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsNotificationsUserSettingsLazyQuery>;
export type GetClinicSettingsNotificationsUserSettingsQueryResult = Apollo.QueryResult<GetClinicSettingsNotificationsUserSettingsQuery, GetClinicSettingsNotificationsUserSettingsQueryVariables>;
export function refetchGetClinicSettingsNotificationsUserSettingsQuery(variables?: GetClinicSettingsNotificationsUserSettingsQueryVariables) {
      return { query: GetClinicSettingsNotificationsUserSettingsDocument, variables: variables }
    }
export const UpdateUserPreferencesDocument = gql`
    mutation updateUserPreferences($data: UserSettingUpdateInput!, $where: UserSettingWhereUniqueInput!) {
  updateOneUserSetting(data: $data, where: $where) {
    id
    enterAddsNewLine
    skipLoadingAnimation
  }
}
    `;
export type UpdateUserPreferencesMutationFn = Apollo.MutationFunction<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument, options);
      }
export type UpdateUserPreferencesMutationHookResult = ReturnType<typeof useUpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationResult = Apollo.MutationResult<UpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const UpdateChangePasswordModalUserDocument = gql`
    mutation updateChangePasswordModalUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateOneUser(where: $where, data: $data) {
    id
    firstName
    lastName
    namePrefix
    nameSuffix
    email
    phone
    isNewPasswordRequired
  }
}
    `;
export type UpdateChangePasswordModalUserMutationFn = Apollo.MutationFunction<UpdateChangePasswordModalUserMutation, UpdateChangePasswordModalUserMutationVariables>;

/**
 * __useUpdateChangePasswordModalUserMutation__
 *
 * To run a mutation, you first call `useUpdateChangePasswordModalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangePasswordModalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangePasswordModalUserMutation, { data, loading, error }] = useUpdateChangePasswordModalUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChangePasswordModalUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChangePasswordModalUserMutation, UpdateChangePasswordModalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChangePasswordModalUserMutation, UpdateChangePasswordModalUserMutationVariables>(UpdateChangePasswordModalUserDocument, options);
      }
export type UpdateChangePasswordModalUserMutationHookResult = ReturnType<typeof useUpdateChangePasswordModalUserMutation>;
export type UpdateChangePasswordModalUserMutationResult = Apollo.MutationResult<UpdateChangePasswordModalUserMutation>;
export type UpdateChangePasswordModalUserMutationOptions = Apollo.BaseMutationOptions<UpdateChangePasswordModalUserMutation, UpdateChangePasswordModalUserMutationVariables>;
export const UpdateClinicSettingsUserDocument = gql`
    mutation updateClinicSettingsUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateOneUser(where: $where, data: $data) {
    id
    firstName
    lastName
    email
    phone
    profilePic
  }
}
    `;
export type UpdateClinicSettingsUserMutationFn = Apollo.MutationFunction<UpdateClinicSettingsUserMutation, UpdateClinicSettingsUserMutationVariables>;

/**
 * __useUpdateClinicSettingsUserMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsUserMutation, { data, loading, error }] = useUpdateClinicSettingsUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsUserMutation, UpdateClinicSettingsUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsUserMutation, UpdateClinicSettingsUserMutationVariables>(UpdateClinicSettingsUserDocument, options);
      }
export type UpdateClinicSettingsUserMutationHookResult = ReturnType<typeof useUpdateClinicSettingsUserMutation>;
export type UpdateClinicSettingsUserMutationResult = Apollo.MutationResult<UpdateClinicSettingsUserMutation>;
export type UpdateClinicSettingsUserMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsUserMutation, UpdateClinicSettingsUserMutationVariables>;
export const GetClinicSettingsUserDocument = gql`
    query getClinicSettingsUser($where: UserWhereUniqueInput!) {
  findUniqueUser(where: $where) {
    ...UserSettingsData
  }
}
    ${UserSettingsDataFragmentDoc}`;

/**
 * __useGetClinicSettingsUserQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsUserQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsUserQuery, GetClinicSettingsUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsUserQuery, GetClinicSettingsUserQueryVariables>(GetClinicSettingsUserDocument, options);
      }
export function useGetClinicSettingsUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsUserQuery, GetClinicSettingsUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsUserQuery, GetClinicSettingsUserQueryVariables>(GetClinicSettingsUserDocument, options);
        }
export type GetClinicSettingsUserQueryHookResult = ReturnType<typeof useGetClinicSettingsUserQuery>;
export type GetClinicSettingsUserLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsUserLazyQuery>;
export type GetClinicSettingsUserQueryResult = Apollo.QueryResult<GetClinicSettingsUserQuery, GetClinicSettingsUserQueryVariables>;
export function refetchGetClinicSettingsUserQuery(variables?: GetClinicSettingsUserQueryVariables) {
      return { query: GetClinicSettingsUserDocument, variables: variables }
    }
export const EncryptAndUploadDataDocument = gql`
    mutation encryptAndUploadData($body: String!, $purpose: EncryptedSignedUploadPurpose!, $extension: String!) {
  encryptAndUploadData(body: $body, purpose: $purpose, extension: $extension) {
    success
  }
}
    `;
export type EncryptAndUploadDataMutationFn = Apollo.MutationFunction<EncryptAndUploadDataMutation, EncryptAndUploadDataMutationVariables>;

/**
 * __useEncryptAndUploadDataMutation__
 *
 * To run a mutation, you first call `useEncryptAndUploadDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEncryptAndUploadDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [encryptAndUploadDataMutation, { data, loading, error }] = useEncryptAndUploadDataMutation({
 *   variables: {
 *      body: // value for 'body'
 *      purpose: // value for 'purpose'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useEncryptAndUploadDataMutation(baseOptions?: Apollo.MutationHookOptions<EncryptAndUploadDataMutation, EncryptAndUploadDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EncryptAndUploadDataMutation, EncryptAndUploadDataMutationVariables>(EncryptAndUploadDataDocument, options);
      }
export type EncryptAndUploadDataMutationHookResult = ReturnType<typeof useEncryptAndUploadDataMutation>;
export type EncryptAndUploadDataMutationResult = Apollo.MutationResult<EncryptAndUploadDataMutation>;
export type EncryptAndUploadDataMutationOptions = Apollo.BaseMutationOptions<EncryptAndUploadDataMutation, EncryptAndUploadDataMutationVariables>;
export const GenerateTerminalSetupPinDocument = gql`
    mutation generateTerminalSetupPin($clinicId: String!) {
  generateTerminalSetupPin(clinicId: $clinicId) {
    pin
  }
}
    `;
export type GenerateTerminalSetupPinMutationFn = Apollo.MutationFunction<GenerateTerminalSetupPinMutation, GenerateTerminalSetupPinMutationVariables>;

/**
 * __useGenerateTerminalSetupPinMutation__
 *
 * To run a mutation, you first call `useGenerateTerminalSetupPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTerminalSetupPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTerminalSetupPinMutation, { data, loading, error }] = useGenerateTerminalSetupPinMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGenerateTerminalSetupPinMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTerminalSetupPinMutation, GenerateTerminalSetupPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTerminalSetupPinMutation, GenerateTerminalSetupPinMutationVariables>(GenerateTerminalSetupPinDocument, options);
      }
export type GenerateTerminalSetupPinMutationHookResult = ReturnType<typeof useGenerateTerminalSetupPinMutation>;
export type GenerateTerminalSetupPinMutationResult = Apollo.MutationResult<GenerateTerminalSetupPinMutation>;
export type GenerateTerminalSetupPinMutationOptions = Apollo.BaseMutationOptions<GenerateTerminalSetupPinMutation, GenerateTerminalSetupPinMutationVariables>;
export const UpsertOneBitwerxPaymentWritebackConfigurationDocument = gql`
    mutation upsertOneBitwerxPaymentWritebackConfiguration($where: BitwerxPaymentWritebackConfigurationWhereUniqueInput!, $create: BitwerxPaymentWritebackConfigurationCreateInput!, $update: BitwerxPaymentWritebackConfigurationUpdateInput!) {
  upsertOneBitwerxPaymentWritebackConfiguration(
    where: $where
    create: $create
    update: $update
  ) {
    id
    integrationId
    enteredById
    defaultPimsPaymentTypeId
    visaPimsPaymentTypeId
    mastercardPimsPaymentTypeId
    amexPimsPaymentTypeId
    discoverPimsPaymentTypeId
    writebackManualPayment
  }
}
    `;
export type UpsertOneBitwerxPaymentWritebackConfigurationMutationFn = Apollo.MutationFunction<UpsertOneBitwerxPaymentWritebackConfigurationMutation, UpsertOneBitwerxPaymentWritebackConfigurationMutationVariables>;

/**
 * __useUpsertOneBitwerxPaymentWritebackConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertOneBitwerxPaymentWritebackConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOneBitwerxPaymentWritebackConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOneBitwerxPaymentWritebackConfigurationMutation, { data, loading, error }] = useUpsertOneBitwerxPaymentWritebackConfigurationMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertOneBitwerxPaymentWritebackConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOneBitwerxPaymentWritebackConfigurationMutation, UpsertOneBitwerxPaymentWritebackConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOneBitwerxPaymentWritebackConfigurationMutation, UpsertOneBitwerxPaymentWritebackConfigurationMutationVariables>(UpsertOneBitwerxPaymentWritebackConfigurationDocument, options);
      }
export type UpsertOneBitwerxPaymentWritebackConfigurationMutationHookResult = ReturnType<typeof useUpsertOneBitwerxPaymentWritebackConfigurationMutation>;
export type UpsertOneBitwerxPaymentWritebackConfigurationMutationResult = Apollo.MutationResult<UpsertOneBitwerxPaymentWritebackConfigurationMutation>;
export type UpsertOneBitwerxPaymentWritebackConfigurationMutationOptions = Apollo.BaseMutationOptions<UpsertOneBitwerxPaymentWritebackConfigurationMutation, UpsertOneBitwerxPaymentWritebackConfigurationMutationVariables>;
export const GetBitwerxPaymentWritebackConfigDocument = gql`
    query getBitwerxPaymentWritebackConfig($integrationId: String!) {
  findUniqueBitwerxPaymentWritebackConfiguration(
    where: {integrationId: $integrationId}
  ) {
    id
    integrationId
    enteredById
    defaultPimsPaymentTypeId
    visaPimsPaymentTypeId
    mastercardPimsPaymentTypeId
    amexPimsPaymentTypeId
    discoverPimsPaymentTypeId
    writebackManualPayment
  }
}
    `;

/**
 * __useGetBitwerxPaymentWritebackConfigQuery__
 *
 * To run a query within a React component, call `useGetBitwerxPaymentWritebackConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBitwerxPaymentWritebackConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBitwerxPaymentWritebackConfigQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetBitwerxPaymentWritebackConfigQuery(baseOptions: Apollo.QueryHookOptions<GetBitwerxPaymentWritebackConfigQuery, GetBitwerxPaymentWritebackConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBitwerxPaymentWritebackConfigQuery, GetBitwerxPaymentWritebackConfigQueryVariables>(GetBitwerxPaymentWritebackConfigDocument, options);
      }
export function useGetBitwerxPaymentWritebackConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBitwerxPaymentWritebackConfigQuery, GetBitwerxPaymentWritebackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBitwerxPaymentWritebackConfigQuery, GetBitwerxPaymentWritebackConfigQueryVariables>(GetBitwerxPaymentWritebackConfigDocument, options);
        }
export type GetBitwerxPaymentWritebackConfigQueryHookResult = ReturnType<typeof useGetBitwerxPaymentWritebackConfigQuery>;
export type GetBitwerxPaymentWritebackConfigLazyQueryHookResult = ReturnType<typeof useGetBitwerxPaymentWritebackConfigLazyQuery>;
export type GetBitwerxPaymentWritebackConfigQueryResult = Apollo.QueryResult<GetBitwerxPaymentWritebackConfigQuery, GetBitwerxPaymentWritebackConfigQueryVariables>;
export function refetchGetBitwerxPaymentWritebackConfigQuery(variables?: GetBitwerxPaymentWritebackConfigQueryVariables) {
      return { query: GetBitwerxPaymentWritebackConfigDocument, variables: variables }
    }
export const GetClinicEmployeesForPaymentSettingsDocument = gql`
    query getClinicEmployeesForPaymentSettings($clinicId: String!) {
  findManyClinicEmployee(
    where: {isDeleted: {equals: false}, isActive: {equals: true}, isVisible: {equals: true}, clinic: {id: {equals: $clinicId}}}
  ) {
    ...ClinicEmployeeBaseSelection
    pimsId
    isDeleted
  }
}
    ${ClinicEmployeeBaseSelectionFragmentDoc}`;

/**
 * __useGetClinicEmployeesForPaymentSettingsQuery__
 *
 * To run a query within a React component, call `useGetClinicEmployeesForPaymentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicEmployeesForPaymentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicEmployeesForPaymentSettingsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicEmployeesForPaymentSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicEmployeesForPaymentSettingsQuery, GetClinicEmployeesForPaymentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicEmployeesForPaymentSettingsQuery, GetClinicEmployeesForPaymentSettingsQueryVariables>(GetClinicEmployeesForPaymentSettingsDocument, options);
      }
export function useGetClinicEmployeesForPaymentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicEmployeesForPaymentSettingsQuery, GetClinicEmployeesForPaymentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicEmployeesForPaymentSettingsQuery, GetClinicEmployeesForPaymentSettingsQueryVariables>(GetClinicEmployeesForPaymentSettingsDocument, options);
        }
export type GetClinicEmployeesForPaymentSettingsQueryHookResult = ReturnType<typeof useGetClinicEmployeesForPaymentSettingsQuery>;
export type GetClinicEmployeesForPaymentSettingsLazyQueryHookResult = ReturnType<typeof useGetClinicEmployeesForPaymentSettingsLazyQuery>;
export type GetClinicEmployeesForPaymentSettingsQueryResult = Apollo.QueryResult<GetClinicEmployeesForPaymentSettingsQuery, GetClinicEmployeesForPaymentSettingsQueryVariables>;
export function refetchGetClinicEmployeesForPaymentSettingsQuery(variables?: GetClinicEmployeesForPaymentSettingsQueryVariables) {
      return { query: GetClinicEmployeesForPaymentSettingsDocument, variables: variables }
    }
export const GetPimsPaymentTypesDocument = gql`
    query getPimsPaymentTypes($integrationId: String!) {
  findManyPimsPaymentType(where: {integrationId: {equals: $integrationId}}) {
    ...PimsPaymentTypeSelection
  }
}
    ${PimsPaymentTypeSelectionFragmentDoc}`;

/**
 * __useGetPimsPaymentTypesQuery__
 *
 * To run a query within a React component, call `useGetPimsPaymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPimsPaymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPimsPaymentTypesQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetPimsPaymentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetPimsPaymentTypesQuery, GetPimsPaymentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPimsPaymentTypesQuery, GetPimsPaymentTypesQueryVariables>(GetPimsPaymentTypesDocument, options);
      }
export function useGetPimsPaymentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPimsPaymentTypesQuery, GetPimsPaymentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPimsPaymentTypesQuery, GetPimsPaymentTypesQueryVariables>(GetPimsPaymentTypesDocument, options);
        }
export type GetPimsPaymentTypesQueryHookResult = ReturnType<typeof useGetPimsPaymentTypesQuery>;
export type GetPimsPaymentTypesLazyQueryHookResult = ReturnType<typeof useGetPimsPaymentTypesLazyQuery>;
export type GetPimsPaymentTypesQueryResult = Apollo.QueryResult<GetPimsPaymentTypesQuery, GetPimsPaymentTypesQueryVariables>;
export function refetchGetPimsPaymentTypesQuery(variables?: GetPimsPaymentTypesQueryVariables) {
      return { query: GetPimsPaymentTypesDocument, variables: variables }
    }
export const GetTerminalLocationCountDocument = gql`
    query getTerminalLocationCount($clinicId: String!) {
  findManyStripeTerminalLocationCount(where: {clinicId: {equals: $clinicId}})
}
    `;

/**
 * __useGetTerminalLocationCountQuery__
 *
 * To run a query within a React component, call `useGetTerminalLocationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalLocationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalLocationCountQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetTerminalLocationCountQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalLocationCountQuery, GetTerminalLocationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTerminalLocationCountQuery, GetTerminalLocationCountQueryVariables>(GetTerminalLocationCountDocument, options);
      }
export function useGetTerminalLocationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalLocationCountQuery, GetTerminalLocationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTerminalLocationCountQuery, GetTerminalLocationCountQueryVariables>(GetTerminalLocationCountDocument, options);
        }
export type GetTerminalLocationCountQueryHookResult = ReturnType<typeof useGetTerminalLocationCountQuery>;
export type GetTerminalLocationCountLazyQueryHookResult = ReturnType<typeof useGetTerminalLocationCountLazyQuery>;
export type GetTerminalLocationCountQueryResult = Apollo.QueryResult<GetTerminalLocationCountQuery, GetTerminalLocationCountQueryVariables>;
export function refetchGetTerminalLocationCountQuery(variables?: GetTerminalLocationCountQueryVariables) {
      return { query: GetTerminalLocationCountDocument, variables: variables }
    }
export const ToggleClinicIsServiceRemindersActiveDocument = gql`
    mutation toggleClinicIsServiceRemindersActive($clinicId: String!, $isServiceRemindersActive: Boolean!) {
  updateOneClinic(
    where: {id: $clinicId}
    data: {isServiceRemindersActive: $isServiceRemindersActive}
  ) {
    id
    isServiceRemindersActive
  }
}
    `;
export type ToggleClinicIsServiceRemindersActiveMutationFn = Apollo.MutationFunction<ToggleClinicIsServiceRemindersActiveMutation, ToggleClinicIsServiceRemindersActiveMutationVariables>;

/**
 * __useToggleClinicIsServiceRemindersActiveMutation__
 *
 * To run a mutation, you first call `useToggleClinicIsServiceRemindersActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleClinicIsServiceRemindersActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleClinicIsServiceRemindersActiveMutation, { data, loading, error }] = useToggleClinicIsServiceRemindersActiveMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      isServiceRemindersActive: // value for 'isServiceRemindersActive'
 *   },
 * });
 */
export function useToggleClinicIsServiceRemindersActiveMutation(baseOptions?: Apollo.MutationHookOptions<ToggleClinicIsServiceRemindersActiveMutation, ToggleClinicIsServiceRemindersActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleClinicIsServiceRemindersActiveMutation, ToggleClinicIsServiceRemindersActiveMutationVariables>(ToggleClinicIsServiceRemindersActiveDocument, options);
      }
export type ToggleClinicIsServiceRemindersActiveMutationHookResult = ReturnType<typeof useToggleClinicIsServiceRemindersActiveMutation>;
export type ToggleClinicIsServiceRemindersActiveMutationResult = Apollo.MutationResult<ToggleClinicIsServiceRemindersActiveMutation>;
export type ToggleClinicIsServiceRemindersActiveMutationOptions = Apollo.BaseMutationOptions<ToggleClinicIsServiceRemindersActiveMutation, ToggleClinicIsServiceRemindersActiveMutationVariables>;
export const UpdateClinicPostcardSettingDocument = gql`
    mutation updateClinicPostcardSetting($where: UpdateClinicPostcardSettingWhereInput, $data: UpdateClinicPostcardSettingDataInput!) {
  updateClinicPostcardSetting(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateClinicPostcardSettingMutationFn = Apollo.MutationFunction<UpdateClinicPostcardSettingMutation, UpdateClinicPostcardSettingMutationVariables>;

/**
 * __useUpdateClinicPostcardSettingMutation__
 *
 * To run a mutation, you first call `useUpdateClinicPostcardSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicPostcardSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicPostcardSettingMutation, { data, loading, error }] = useUpdateClinicPostcardSettingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicPostcardSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicPostcardSettingMutation, UpdateClinicPostcardSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicPostcardSettingMutation, UpdateClinicPostcardSettingMutationVariables>(UpdateClinicPostcardSettingDocument, options);
      }
export type UpdateClinicPostcardSettingMutationHookResult = ReturnType<typeof useUpdateClinicPostcardSettingMutation>;
export type UpdateClinicPostcardSettingMutationResult = Apollo.MutationResult<UpdateClinicPostcardSettingMutation>;
export type UpdateClinicPostcardSettingMutationOptions = Apollo.BaseMutationOptions<UpdateClinicPostcardSettingMutation, UpdateClinicPostcardSettingMutationVariables>;
export const UpdateReminderSettingsServiceDocument = gql`
    mutation updateReminderSettingsService($where: ServiceWhereInput, $data: ServiceUpdateManyMutationInput!) {
  updateManyService(where: $where, data: $data) {
    count
  }
}
    `;
export type UpdateReminderSettingsServiceMutationFn = Apollo.MutationFunction<UpdateReminderSettingsServiceMutation, UpdateReminderSettingsServiceMutationVariables>;

/**
 * __useUpdateReminderSettingsServiceMutation__
 *
 * To run a mutation, you first call `useUpdateReminderSettingsServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderSettingsServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderSettingsServiceMutation, { data, loading, error }] = useUpdateReminderSettingsServiceMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReminderSettingsServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderSettingsServiceMutation, UpdateReminderSettingsServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderSettingsServiceMutation, UpdateReminderSettingsServiceMutationVariables>(UpdateReminderSettingsServiceDocument, options);
      }
export type UpdateReminderSettingsServiceMutationHookResult = ReturnType<typeof useUpdateReminderSettingsServiceMutation>;
export type UpdateReminderSettingsServiceMutationResult = Apollo.MutationResult<UpdateReminderSettingsServiceMutation>;
export type UpdateReminderSettingsServiceMutationOptions = Apollo.BaseMutationOptions<UpdateReminderSettingsServiceMutation, UpdateReminderSettingsServiceMutationVariables>;
export const UpdateReminderSettingsWorkflowEventSettingDocument = gql`
    mutation updateReminderSettingsWorkflowEventSetting($where: WorkflowEventSettingWhereUniqueInput!, $data: WorkflowEventSettingUpdateInput!) {
  updateOneWorkflowEventSetting(where: $where, data: $data) {
    ...ReminderSettingsWorkflowEventSetting
  }
}
    ${ReminderSettingsWorkflowEventSettingFragmentDoc}`;
export type UpdateReminderSettingsWorkflowEventSettingMutationFn = Apollo.MutationFunction<UpdateReminderSettingsWorkflowEventSettingMutation, UpdateReminderSettingsWorkflowEventSettingMutationVariables>;

/**
 * __useUpdateReminderSettingsWorkflowEventSettingMutation__
 *
 * To run a mutation, you first call `useUpdateReminderSettingsWorkflowEventSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderSettingsWorkflowEventSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderSettingsWorkflowEventSettingMutation, { data, loading, error }] = useUpdateReminderSettingsWorkflowEventSettingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReminderSettingsWorkflowEventSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderSettingsWorkflowEventSettingMutation, UpdateReminderSettingsWorkflowEventSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderSettingsWorkflowEventSettingMutation, UpdateReminderSettingsWorkflowEventSettingMutationVariables>(UpdateReminderSettingsWorkflowEventSettingDocument, options);
      }
export type UpdateReminderSettingsWorkflowEventSettingMutationHookResult = ReturnType<typeof useUpdateReminderSettingsWorkflowEventSettingMutation>;
export type UpdateReminderSettingsWorkflowEventSettingMutationResult = Apollo.MutationResult<UpdateReminderSettingsWorkflowEventSettingMutation>;
export type UpdateReminderSettingsWorkflowEventSettingMutationOptions = Apollo.BaseMutationOptions<UpdateReminderSettingsWorkflowEventSettingMutation, UpdateReminderSettingsWorkflowEventSettingMutationVariables>;
export const UpdateServicesTimingsDocument = gql`
    mutation updateServicesTimings($where: ServiceWhereInput!, $data: [ServiceReminderTimingInput!]!) {
  updateManyServicesAndTimings(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateServicesTimingsMutationFn = Apollo.MutationFunction<UpdateServicesTimingsMutation, UpdateServicesTimingsMutationVariables>;

/**
 * __useUpdateServicesTimingsMutation__
 *
 * To run a mutation, you first call `useUpdateServicesTimingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServicesTimingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServicesTimingsMutation, { data, loading, error }] = useUpdateServicesTimingsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateServicesTimingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServicesTimingsMutation, UpdateServicesTimingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServicesTimingsMutation, UpdateServicesTimingsMutationVariables>(UpdateServicesTimingsDocument, options);
      }
export type UpdateServicesTimingsMutationHookResult = ReturnType<typeof useUpdateServicesTimingsMutation>;
export type UpdateServicesTimingsMutationResult = Apollo.MutationResult<UpdateServicesTimingsMutation>;
export type UpdateServicesTimingsMutationOptions = Apollo.BaseMutationOptions<UpdateServicesTimingsMutation, UpdateServicesTimingsMutationVariables>;
export const ToggleServicesTimingsDeliveryOptionsDocument = gql`
    mutation toggleServicesTimingsDeliveryOptions($where: ServiceWhereInput!, $data: ServiceReminderTimingDeliveryOptionInput!) {
  updateManyTimingsDeliveryOptions(where: $where, data: $data) {
    id
  }
}
    `;
export type ToggleServicesTimingsDeliveryOptionsMutationFn = Apollo.MutationFunction<ToggleServicesTimingsDeliveryOptionsMutation, ToggleServicesTimingsDeliveryOptionsMutationVariables>;

/**
 * __useToggleServicesTimingsDeliveryOptionsMutation__
 *
 * To run a mutation, you first call `useToggleServicesTimingsDeliveryOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleServicesTimingsDeliveryOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleServicesTimingsDeliveryOptionsMutation, { data, loading, error }] = useToggleServicesTimingsDeliveryOptionsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useToggleServicesTimingsDeliveryOptionsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleServicesTimingsDeliveryOptionsMutation, ToggleServicesTimingsDeliveryOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleServicesTimingsDeliveryOptionsMutation, ToggleServicesTimingsDeliveryOptionsMutationVariables>(ToggleServicesTimingsDeliveryOptionsDocument, options);
      }
export type ToggleServicesTimingsDeliveryOptionsMutationHookResult = ReturnType<typeof useToggleServicesTimingsDeliveryOptionsMutation>;
export type ToggleServicesTimingsDeliveryOptionsMutationResult = Apollo.MutationResult<ToggleServicesTimingsDeliveryOptionsMutation>;
export type ToggleServicesTimingsDeliveryOptionsMutationOptions = Apollo.BaseMutationOptions<ToggleServicesTimingsDeliveryOptionsMutation, ToggleServicesTimingsDeliveryOptionsMutationVariables>;
export const GetClinicIsServiceRemindersActiveDocument = gql`
    query getClinicIsServiceRemindersActive($clinicId: String!) {
  findUniqueClinic(where: {id: $clinicId}) {
    id
    isServiceRemindersActive
  }
}
    `;

/**
 * __useGetClinicIsServiceRemindersActiveQuery__
 *
 * To run a query within a React component, call `useGetClinicIsServiceRemindersActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicIsServiceRemindersActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicIsServiceRemindersActiveQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicIsServiceRemindersActiveQuery(baseOptions: Apollo.QueryHookOptions<GetClinicIsServiceRemindersActiveQuery, GetClinicIsServiceRemindersActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicIsServiceRemindersActiveQuery, GetClinicIsServiceRemindersActiveQueryVariables>(GetClinicIsServiceRemindersActiveDocument, options);
      }
export function useGetClinicIsServiceRemindersActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicIsServiceRemindersActiveQuery, GetClinicIsServiceRemindersActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicIsServiceRemindersActiveQuery, GetClinicIsServiceRemindersActiveQueryVariables>(GetClinicIsServiceRemindersActiveDocument, options);
        }
export type GetClinicIsServiceRemindersActiveQueryHookResult = ReturnType<typeof useGetClinicIsServiceRemindersActiveQuery>;
export type GetClinicIsServiceRemindersActiveLazyQueryHookResult = ReturnType<typeof useGetClinicIsServiceRemindersActiveLazyQuery>;
export type GetClinicIsServiceRemindersActiveQueryResult = Apollo.QueryResult<GetClinicIsServiceRemindersActiveQuery, GetClinicIsServiceRemindersActiveQueryVariables>;
export function refetchGetClinicIsServiceRemindersActiveQuery(variables?: GetClinicIsServiceRemindersActiveQueryVariables) {
      return { query: GetClinicIsServiceRemindersActiveDocument, variables: variables }
    }
export const GetClinicPostcardsSettingsDocument = gql`
    query getClinicPostcardsSettings($where: ClinicPostcardSettingWhereUniqueInput!) {
  findUniqueClinicPostcardSetting(where: $where) {
    id
    isRemindersEnabled
    apiKey
    apiSecret
  }
}
    `;

/**
 * __useGetClinicPostcardsSettingsQuery__
 *
 * To run a query within a React component, call `useGetClinicPostcardsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPostcardsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPostcardsSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicPostcardsSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicPostcardsSettingsQuery, GetClinicPostcardsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPostcardsSettingsQuery, GetClinicPostcardsSettingsQueryVariables>(GetClinicPostcardsSettingsDocument, options);
      }
export function useGetClinicPostcardsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPostcardsSettingsQuery, GetClinicPostcardsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPostcardsSettingsQuery, GetClinicPostcardsSettingsQueryVariables>(GetClinicPostcardsSettingsDocument, options);
        }
export type GetClinicPostcardsSettingsQueryHookResult = ReturnType<typeof useGetClinicPostcardsSettingsQuery>;
export type GetClinicPostcardsSettingsLazyQueryHookResult = ReturnType<typeof useGetClinicPostcardsSettingsLazyQuery>;
export type GetClinicPostcardsSettingsQueryResult = Apollo.QueryResult<GetClinicPostcardsSettingsQuery, GetClinicPostcardsSettingsQueryVariables>;
export function refetchGetClinicPostcardsSettingsQuery(variables?: GetClinicPostcardsSettingsQueryVariables) {
      return { query: GetClinicPostcardsSettingsDocument, variables: variables }
    }
export const GetClinicStripeInfoDocument = gql`
    query getClinicStripeInfo($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    id
    stripeCustomerId
    stripeSubscriptionId
  }
}
    `;

/**
 * __useGetClinicStripeInfoQuery__
 *
 * To run a query within a React component, call `useGetClinicStripeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicStripeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicStripeInfoQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicStripeInfoQuery(baseOptions: Apollo.QueryHookOptions<GetClinicStripeInfoQuery, GetClinicStripeInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicStripeInfoQuery, GetClinicStripeInfoQueryVariables>(GetClinicStripeInfoDocument, options);
      }
export function useGetClinicStripeInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicStripeInfoQuery, GetClinicStripeInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicStripeInfoQuery, GetClinicStripeInfoQueryVariables>(GetClinicStripeInfoDocument, options);
        }
export type GetClinicStripeInfoQueryHookResult = ReturnType<typeof useGetClinicStripeInfoQuery>;
export type GetClinicStripeInfoLazyQueryHookResult = ReturnType<typeof useGetClinicStripeInfoLazyQuery>;
export type GetClinicStripeInfoQueryResult = Apollo.QueryResult<GetClinicStripeInfoQuery, GetClinicStripeInfoQueryVariables>;
export function refetchGetClinicStripeInfoQuery(variables?: GetClinicStripeInfoQueryVariables) {
      return { query: GetClinicStripeInfoDocument, variables: variables }
    }
export const GetReminderSettingsClinicServicesDocument = gql`
    query getReminderSettingsClinicServices($where: ServiceWhereInput, $orderBy: [ServiceOrderByWithRelationAndSearchRelevanceInput!], $take: Int, $skip: Int) {
  findManyService(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    ...ReminderSettingsService
  }
  findManyServiceCount(where: $where)
}
    ${ReminderSettingsServiceFragmentDoc}`;

/**
 * __useGetReminderSettingsClinicServicesQuery__
 *
 * To run a query within a React component, call `useGetReminderSettingsClinicServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReminderSettingsClinicServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReminderSettingsClinicServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetReminderSettingsClinicServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetReminderSettingsClinicServicesQuery, GetReminderSettingsClinicServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReminderSettingsClinicServicesQuery, GetReminderSettingsClinicServicesQueryVariables>(GetReminderSettingsClinicServicesDocument, options);
      }
export function useGetReminderSettingsClinicServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReminderSettingsClinicServicesQuery, GetReminderSettingsClinicServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReminderSettingsClinicServicesQuery, GetReminderSettingsClinicServicesQueryVariables>(GetReminderSettingsClinicServicesDocument, options);
        }
export type GetReminderSettingsClinicServicesQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesQuery>;
export type GetReminderSettingsClinicServicesLazyQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesLazyQuery>;
export type GetReminderSettingsClinicServicesQueryResult = Apollo.QueryResult<GetReminderSettingsClinicServicesQuery, GetReminderSettingsClinicServicesQueryVariables>;
export function refetchGetReminderSettingsClinicServicesQuery(variables?: GetReminderSettingsClinicServicesQueryVariables) {
      return { query: GetReminderSettingsClinicServicesDocument, variables: variables }
    }
export const GetReminderSettingsClinicServicesFormTemplateIdDocument = gql`
    query getReminderSettingsClinicServicesFormTemplateId($clinicId: String) {
  findFirstFormTemplate(
    where: {clinicId: {equals: $clinicId}, formTemplateType: {equals: ServiceReminder}}
  ) {
    id
    isDeleted
    isActive
  }
}
    `;

/**
 * __useGetReminderSettingsClinicServicesFormTemplateIdQuery__
 *
 * To run a query within a React component, call `useGetReminderSettingsClinicServicesFormTemplateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReminderSettingsClinicServicesFormTemplateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReminderSettingsClinicServicesFormTemplateIdQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetReminderSettingsClinicServicesFormTemplateIdQuery(baseOptions?: Apollo.QueryHookOptions<GetReminderSettingsClinicServicesFormTemplateIdQuery, GetReminderSettingsClinicServicesFormTemplateIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReminderSettingsClinicServicesFormTemplateIdQuery, GetReminderSettingsClinicServicesFormTemplateIdQueryVariables>(GetReminderSettingsClinicServicesFormTemplateIdDocument, options);
      }
export function useGetReminderSettingsClinicServicesFormTemplateIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReminderSettingsClinicServicesFormTemplateIdQuery, GetReminderSettingsClinicServicesFormTemplateIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReminderSettingsClinicServicesFormTemplateIdQuery, GetReminderSettingsClinicServicesFormTemplateIdQueryVariables>(GetReminderSettingsClinicServicesFormTemplateIdDocument, options);
        }
export type GetReminderSettingsClinicServicesFormTemplateIdQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesFormTemplateIdQuery>;
export type GetReminderSettingsClinicServicesFormTemplateIdLazyQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesFormTemplateIdLazyQuery>;
export type GetReminderSettingsClinicServicesFormTemplateIdQueryResult = Apollo.QueryResult<GetReminderSettingsClinicServicesFormTemplateIdQuery, GetReminderSettingsClinicServicesFormTemplateIdQueryVariables>;
export function refetchGetReminderSettingsClinicServicesFormTemplateIdQuery(variables?: GetReminderSettingsClinicServicesFormTemplateIdQueryVariables) {
      return { query: GetReminderSettingsClinicServicesFormTemplateIdDocument, variables: variables }
    }
export const GetReminderSettingsClinicServicesTimingsDocument = gql`
    query getReminderSettingsClinicServicesTimings($where: ServiceReminderTimingWhereInput) {
  findManyServiceReminderTiming(where: $where) {
    ...ReminderSettingsServiceTiming
  }
}
    ${ReminderSettingsServiceTimingFragmentDoc}`;

/**
 * __useGetReminderSettingsClinicServicesTimingsQuery__
 *
 * To run a query within a React component, call `useGetReminderSettingsClinicServicesTimingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReminderSettingsClinicServicesTimingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReminderSettingsClinicServicesTimingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReminderSettingsClinicServicesTimingsQuery(baseOptions?: Apollo.QueryHookOptions<GetReminderSettingsClinicServicesTimingsQuery, GetReminderSettingsClinicServicesTimingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReminderSettingsClinicServicesTimingsQuery, GetReminderSettingsClinicServicesTimingsQueryVariables>(GetReminderSettingsClinicServicesTimingsDocument, options);
      }
export function useGetReminderSettingsClinicServicesTimingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReminderSettingsClinicServicesTimingsQuery, GetReminderSettingsClinicServicesTimingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReminderSettingsClinicServicesTimingsQuery, GetReminderSettingsClinicServicesTimingsQueryVariables>(GetReminderSettingsClinicServicesTimingsDocument, options);
        }
export type GetReminderSettingsClinicServicesTimingsQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesTimingsQuery>;
export type GetReminderSettingsClinicServicesTimingsLazyQueryHookResult = ReturnType<typeof useGetReminderSettingsClinicServicesTimingsLazyQuery>;
export type GetReminderSettingsClinicServicesTimingsQueryResult = Apollo.QueryResult<GetReminderSettingsClinicServicesTimingsQuery, GetReminderSettingsClinicServicesTimingsQueryVariables>;
export function refetchGetReminderSettingsClinicServicesTimingsQuery(variables?: GetReminderSettingsClinicServicesTimingsQueryVariables) {
      return { query: GetReminderSettingsClinicServicesTimingsDocument, variables: variables }
    }
export const GetReminderSettingsWorkflowEventSettingDocument = gql`
    query getReminderSettingsWorkflowEventSetting($where: WorkflowEventSettingWhereInput!) {
  findManyWorkflowEventSetting(where: $where) {
    ...ReminderSettingsWorkflowEventSetting
  }
}
    ${ReminderSettingsWorkflowEventSettingFragmentDoc}`;

/**
 * __useGetReminderSettingsWorkflowEventSettingQuery__
 *
 * To run a query within a React component, call `useGetReminderSettingsWorkflowEventSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReminderSettingsWorkflowEventSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReminderSettingsWorkflowEventSettingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReminderSettingsWorkflowEventSettingQuery(baseOptions: Apollo.QueryHookOptions<GetReminderSettingsWorkflowEventSettingQuery, GetReminderSettingsWorkflowEventSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReminderSettingsWorkflowEventSettingQuery, GetReminderSettingsWorkflowEventSettingQueryVariables>(GetReminderSettingsWorkflowEventSettingDocument, options);
      }
export function useGetReminderSettingsWorkflowEventSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReminderSettingsWorkflowEventSettingQuery, GetReminderSettingsWorkflowEventSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReminderSettingsWorkflowEventSettingQuery, GetReminderSettingsWorkflowEventSettingQueryVariables>(GetReminderSettingsWorkflowEventSettingDocument, options);
        }
export type GetReminderSettingsWorkflowEventSettingQueryHookResult = ReturnType<typeof useGetReminderSettingsWorkflowEventSettingQuery>;
export type GetReminderSettingsWorkflowEventSettingLazyQueryHookResult = ReturnType<typeof useGetReminderSettingsWorkflowEventSettingLazyQuery>;
export type GetReminderSettingsWorkflowEventSettingQueryResult = Apollo.QueryResult<GetReminderSettingsWorkflowEventSettingQuery, GetReminderSettingsWorkflowEventSettingQueryVariables>;
export function refetchGetReminderSettingsWorkflowEventSettingQuery(variables?: GetReminderSettingsWorkflowEventSettingQueryVariables) {
      return { query: GetReminderSettingsWorkflowEventSettingDocument, variables: variables }
    }
export const DeleteClinicSettingsTeamUserDocument = gql`
    mutation deleteClinicSettingsTeamUser($data: RemoveUserFromClinicInput!) {
  removeUserFromClinic(data: $data) {
    id
  }
}
    `;
export type DeleteClinicSettingsTeamUserMutationFn = Apollo.MutationFunction<DeleteClinicSettingsTeamUserMutation, DeleteClinicSettingsTeamUserMutationVariables>;

/**
 * __useDeleteClinicSettingsTeamUserMutation__
 *
 * To run a mutation, you first call `useDeleteClinicSettingsTeamUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicSettingsTeamUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicSettingsTeamUserMutation, { data, loading, error }] = useDeleteClinicSettingsTeamUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteClinicSettingsTeamUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClinicSettingsTeamUserMutation, DeleteClinicSettingsTeamUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClinicSettingsTeamUserMutation, DeleteClinicSettingsTeamUserMutationVariables>(DeleteClinicSettingsTeamUserDocument, options);
      }
export type DeleteClinicSettingsTeamUserMutationHookResult = ReturnType<typeof useDeleteClinicSettingsTeamUserMutation>;
export type DeleteClinicSettingsTeamUserMutationResult = Apollo.MutationResult<DeleteClinicSettingsTeamUserMutation>;
export type DeleteClinicSettingsTeamUserMutationOptions = Apollo.BaseMutationOptions<DeleteClinicSettingsTeamUserMutation, DeleteClinicSettingsTeamUserMutationVariables>;
export const ResendUserInvitationDocument = gql`
    mutation resendUserInvitation($email: String!, $clinicId: String!) {
  resendUserInvitation(data: {email: $email}) {
    ...ClinicSettingsTeamUser
  }
}
    ${ClinicSettingsTeamUserFragmentDoc}`;
export type ResendUserInvitationMutationFn = Apollo.MutationFunction<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>;

/**
 * __useResendUserInvitationMutation__
 *
 * To run a mutation, you first call `useResendUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInvitationMutation, { data, loading, error }] = useResendUserInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useResendUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>(ResendUserInvitationDocument, options);
      }
export type ResendUserInvitationMutationHookResult = ReturnType<typeof useResendUserInvitationMutation>;
export type ResendUserInvitationMutationResult = Apollo.MutationResult<ResendUserInvitationMutation>;
export type ResendUserInvitationMutationOptions = Apollo.BaseMutationOptions<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>;
export const UpdateClinicSettingsTeamUserDocument = gql`
    mutation updateClinicSettingsTeamUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateOneUser(where: $where, data: $data) {
    id
    firstName
    lastName
    namePrefix
    nameSuffix
    email
    phone
    isNewPasswordRequired
  }
}
    `;
export type UpdateClinicSettingsTeamUserMutationFn = Apollo.MutationFunction<UpdateClinicSettingsTeamUserMutation, UpdateClinicSettingsTeamUserMutationVariables>;

/**
 * __useUpdateClinicSettingsTeamUserMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsTeamUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsTeamUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsTeamUserMutation, { data, loading, error }] = useUpdateClinicSettingsTeamUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsTeamUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsTeamUserMutation, UpdateClinicSettingsTeamUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsTeamUserMutation, UpdateClinicSettingsTeamUserMutationVariables>(UpdateClinicSettingsTeamUserDocument, options);
      }
export type UpdateClinicSettingsTeamUserMutationHookResult = ReturnType<typeof useUpdateClinicSettingsTeamUserMutation>;
export type UpdateClinicSettingsTeamUserMutationResult = Apollo.MutationResult<UpdateClinicSettingsTeamUserMutation>;
export type UpdateClinicSettingsTeamUserMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsTeamUserMutation, UpdateClinicSettingsTeamUserMutationVariables>;
export const UpsertUserToClinicDocument = gql`
    mutation upsertUserToClinic($data: UpsertUserToClinicInput!) {
  upsertUserToClinic(data: $data) {
    id
  }
}
    `;
export type UpsertUserToClinicMutationFn = Apollo.MutationFunction<UpsertUserToClinicMutation, UpsertUserToClinicMutationVariables>;

/**
 * __useUpsertUserToClinicMutation__
 *
 * To run a mutation, you first call `useUpsertUserToClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserToClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserToClinicMutation, { data, loading, error }] = useUpsertUserToClinicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertUserToClinicMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserToClinicMutation, UpsertUserToClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserToClinicMutation, UpsertUserToClinicMutationVariables>(UpsertUserToClinicDocument, options);
      }
export type UpsertUserToClinicMutationHookResult = ReturnType<typeof useUpsertUserToClinicMutation>;
export type UpsertUserToClinicMutationResult = Apollo.MutationResult<UpsertUserToClinicMutation>;
export type UpsertUserToClinicMutationOptions = Apollo.BaseMutationOptions<UpsertUserToClinicMutation, UpsertUserToClinicMutationVariables>;
export const GetClinicSettingsTeamUsersDocument = gql`
    query getClinicSettingsTeamUsers($where: UserWhereInput!, $clinicId: String!) {
  findManyUser(where: $where, orderBy: {createdAt: asc}) {
    ...ClinicSettingsTeamUser
  }
}
    ${ClinicSettingsTeamUserFragmentDoc}`;

/**
 * __useGetClinicSettingsTeamUsersQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsTeamUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicSettingsTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsTeamUsersQuery, GetClinicSettingsTeamUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsTeamUsersQuery, GetClinicSettingsTeamUsersQueryVariables>(GetClinicSettingsTeamUsersDocument, options);
      }
export function useGetClinicSettingsTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsTeamUsersQuery, GetClinicSettingsTeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsTeamUsersQuery, GetClinicSettingsTeamUsersQueryVariables>(GetClinicSettingsTeamUsersDocument, options);
        }
export type GetClinicSettingsTeamUsersQueryHookResult = ReturnType<typeof useGetClinicSettingsTeamUsersQuery>;
export type GetClinicSettingsTeamUsersLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsTeamUsersLazyQuery>;
export type GetClinicSettingsTeamUsersQueryResult = Apollo.QueryResult<GetClinicSettingsTeamUsersQuery, GetClinicSettingsTeamUsersQueryVariables>;
export function refetchGetClinicSettingsTeamUsersQuery(variables?: GetClinicSettingsTeamUsersQueryVariables) {
      return { query: GetClinicSettingsTeamUsersDocument, variables: variables }
    }
export const CreateOneClinicBlockedPhoneNumberDocument = gql`
    mutation createOneClinicBlockedPhoneNumber($data: ClinicBlockedPhoneNumberCreateInput!) {
  createOneClinicBlockedPhoneNumber(data: $data) {
    ...ClinicBlockedPhoneNumber
  }
}
    ${ClinicBlockedPhoneNumberFragmentDoc}`;
export type CreateOneClinicBlockedPhoneNumberMutationFn = Apollo.MutationFunction<CreateOneClinicBlockedPhoneNumberMutation, CreateOneClinicBlockedPhoneNumberMutationVariables>;

/**
 * __useCreateOneClinicBlockedPhoneNumberMutation__
 *
 * To run a mutation, you first call `useCreateOneClinicBlockedPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClinicBlockedPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClinicBlockedPhoneNumberMutation, { data, loading, error }] = useCreateOneClinicBlockedPhoneNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneClinicBlockedPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClinicBlockedPhoneNumberMutation, CreateOneClinicBlockedPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneClinicBlockedPhoneNumberMutation, CreateOneClinicBlockedPhoneNumberMutationVariables>(CreateOneClinicBlockedPhoneNumberDocument, options);
      }
export type CreateOneClinicBlockedPhoneNumberMutationHookResult = ReturnType<typeof useCreateOneClinicBlockedPhoneNumberMutation>;
export type CreateOneClinicBlockedPhoneNumberMutationResult = Apollo.MutationResult<CreateOneClinicBlockedPhoneNumberMutation>;
export type CreateOneClinicBlockedPhoneNumberMutationOptions = Apollo.BaseMutationOptions<CreateOneClinicBlockedPhoneNumberMutation, CreateOneClinicBlockedPhoneNumberMutationVariables>;
export const FindManyClinicBlockedPhoneNumberDocument = gql`
    query findManyClinicBlockedPhoneNumber($where: ClinicBlockedPhoneNumberWhereInput) {
  findManyClinicBlockedPhoneNumber(where: $where) {
    ...ClinicBlockedPhoneNumber
  }
}
    ${ClinicBlockedPhoneNumberFragmentDoc}`;

/**
 * __useFindManyClinicBlockedPhoneNumberQuery__
 *
 * To run a query within a React component, call `useFindManyClinicBlockedPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyClinicBlockedPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyClinicBlockedPhoneNumberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyClinicBlockedPhoneNumberQuery(baseOptions?: Apollo.QueryHookOptions<FindManyClinicBlockedPhoneNumberQuery, FindManyClinicBlockedPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyClinicBlockedPhoneNumberQuery, FindManyClinicBlockedPhoneNumberQueryVariables>(FindManyClinicBlockedPhoneNumberDocument, options);
      }
export function useFindManyClinicBlockedPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyClinicBlockedPhoneNumberQuery, FindManyClinicBlockedPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyClinicBlockedPhoneNumberQuery, FindManyClinicBlockedPhoneNumberQueryVariables>(FindManyClinicBlockedPhoneNumberDocument, options);
        }
export type FindManyClinicBlockedPhoneNumberQueryHookResult = ReturnType<typeof useFindManyClinicBlockedPhoneNumberQuery>;
export type FindManyClinicBlockedPhoneNumberLazyQueryHookResult = ReturnType<typeof useFindManyClinicBlockedPhoneNumberLazyQuery>;
export type FindManyClinicBlockedPhoneNumberQueryResult = Apollo.QueryResult<FindManyClinicBlockedPhoneNumberQuery, FindManyClinicBlockedPhoneNumberQueryVariables>;
export function refetchFindManyClinicBlockedPhoneNumberQuery(variables?: FindManyClinicBlockedPhoneNumberQueryVariables) {
      return { query: FindManyClinicBlockedPhoneNumberDocument, variables: variables }
    }
export const UpdateOneClinicBlockedPhoneNumberDocument = gql`
    mutation updateOneClinicBlockedPhoneNumber($data: ClinicBlockedPhoneNumberUpdateInput!, $where: ClinicBlockedPhoneNumberWhereUniqueInput!) {
  updateOneClinicBlockedPhoneNumber(data: $data, where: $where) {
    ...ClinicBlockedPhoneNumber
  }
}
    ${ClinicBlockedPhoneNumberFragmentDoc}`;
export type UpdateOneClinicBlockedPhoneNumberMutationFn = Apollo.MutationFunction<UpdateOneClinicBlockedPhoneNumberMutation, UpdateOneClinicBlockedPhoneNumberMutationVariables>;

/**
 * __useUpdateOneClinicBlockedPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateOneClinicBlockedPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClinicBlockedPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClinicBlockedPhoneNumberMutation, { data, loading, error }] = useUpdateOneClinicBlockedPhoneNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneClinicBlockedPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClinicBlockedPhoneNumberMutation, UpdateOneClinicBlockedPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClinicBlockedPhoneNumberMutation, UpdateOneClinicBlockedPhoneNumberMutationVariables>(UpdateOneClinicBlockedPhoneNumberDocument, options);
      }
export type UpdateOneClinicBlockedPhoneNumberMutationHookResult = ReturnType<typeof useUpdateOneClinicBlockedPhoneNumberMutation>;
export type UpdateOneClinicBlockedPhoneNumberMutationResult = Apollo.MutationResult<UpdateOneClinicBlockedPhoneNumberMutation>;
export type UpdateOneClinicBlockedPhoneNumberMutationOptions = Apollo.BaseMutationOptions<UpdateOneClinicBlockedPhoneNumberMutation, UpdateOneClinicBlockedPhoneNumberMutationVariables>;
export const CreateOneClinicPhoneNumberDocument = gql`
    mutation createOneClinicPhoneNumber($data: ClinicPhoneNumberCreateInput!) {
  createOneClinicPhoneNumber(data: $data) {
    ...ClinicPhoneNumber
  }
}
    ${ClinicPhoneNumberFragmentDoc}`;
export type CreateOneClinicPhoneNumberMutationFn = Apollo.MutationFunction<CreateOneClinicPhoneNumberMutation, CreateOneClinicPhoneNumberMutationVariables>;

/**
 * __useCreateOneClinicPhoneNumberMutation__
 *
 * To run a mutation, you first call `useCreateOneClinicPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClinicPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClinicPhoneNumberMutation, { data, loading, error }] = useCreateOneClinicPhoneNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneClinicPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClinicPhoneNumberMutation, CreateOneClinicPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneClinicPhoneNumberMutation, CreateOneClinicPhoneNumberMutationVariables>(CreateOneClinicPhoneNumberDocument, options);
      }
export type CreateOneClinicPhoneNumberMutationHookResult = ReturnType<typeof useCreateOneClinicPhoneNumberMutation>;
export type CreateOneClinicPhoneNumberMutationResult = Apollo.MutationResult<CreateOneClinicPhoneNumberMutation>;
export type CreateOneClinicPhoneNumberMutationOptions = Apollo.BaseMutationOptions<CreateOneClinicPhoneNumberMutation, CreateOneClinicPhoneNumberMutationVariables>;
export const OrderBandwidthPhoneNumberDocument = gql`
    mutation orderBandwidthPhoneNumber($data: OrderPhoneNumberInput!) {
  orderPhoneNumber(data: $data) {
    ...ClinicPhoneNumber
  }
}
    ${ClinicPhoneNumberFragmentDoc}`;
export type OrderBandwidthPhoneNumberMutationFn = Apollo.MutationFunction<OrderBandwidthPhoneNumberMutation, OrderBandwidthPhoneNumberMutationVariables>;

/**
 * __useOrderBandwidthPhoneNumberMutation__
 *
 * To run a mutation, you first call `useOrderBandwidthPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderBandwidthPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderBandwidthPhoneNumberMutation, { data, loading, error }] = useOrderBandwidthPhoneNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderBandwidthPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<OrderBandwidthPhoneNumberMutation, OrderBandwidthPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderBandwidthPhoneNumberMutation, OrderBandwidthPhoneNumberMutationVariables>(OrderBandwidthPhoneNumberDocument, options);
      }
export type OrderBandwidthPhoneNumberMutationHookResult = ReturnType<typeof useOrderBandwidthPhoneNumberMutation>;
export type OrderBandwidthPhoneNumberMutationResult = Apollo.MutationResult<OrderBandwidthPhoneNumberMutation>;
export type OrderBandwidthPhoneNumberMutationOptions = Apollo.BaseMutationOptions<OrderBandwidthPhoneNumberMutation, OrderBandwidthPhoneNumberMutationVariables>;
export const UpdateClinicSettingsConversationsSmsDocument = gql`
    mutation updateClinicSettingsConversationsSms($where: ClinicWhereUniqueInput!, $data: ClinicUpdateInput!) {
  updateOneClinic(where: $where, data: $data) {
    id
    hasSmsConversations
  }
}
    `;
export type UpdateClinicSettingsConversationsSmsMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsSmsMutation, UpdateClinicSettingsConversationsSmsMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsSmsMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsSmsMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsSmsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsSmsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsSmsMutation, UpdateClinicSettingsConversationsSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsSmsMutation, UpdateClinicSettingsConversationsSmsMutationVariables>(UpdateClinicSettingsConversationsSmsDocument, options);
      }
export type UpdateClinicSettingsConversationsSmsMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsSmsMutation>;
export type UpdateClinicSettingsConversationsSmsMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsSmsMutation>;
export type UpdateClinicSettingsConversationsSmsMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsSmsMutation, UpdateClinicSettingsConversationsSmsMutationVariables>;
export const UpdateClinicSettingsConversationsSmsAutoResponseDocument = gql`
    mutation updateClinicSettingsConversationsSmsAutoResponse($where: ClinicSettingWhereUniqueInput!, $data: ClinicSettingUpdateInput!) {
  updateOneClinicSetting(where: $where, data: $data) {
    id
    smsAutoResponseWhenOpen
    smsAutoResponseWhenClosed
  }
}
    `;
export type UpdateClinicSettingsConversationsSmsAutoResponseMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsSmsAutoResponseMutation, UpdateClinicSettingsConversationsSmsAutoResponseMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsSmsAutoResponseMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsSmsAutoResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsSmsAutoResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsSmsAutoResponseMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsSmsAutoResponseMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsSmsAutoResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsSmsAutoResponseMutation, UpdateClinicSettingsConversationsSmsAutoResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsSmsAutoResponseMutation, UpdateClinicSettingsConversationsSmsAutoResponseMutationVariables>(UpdateClinicSettingsConversationsSmsAutoResponseDocument, options);
      }
export type UpdateClinicSettingsConversationsSmsAutoResponseMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsSmsAutoResponseMutation>;
export type UpdateClinicSettingsConversationsSmsAutoResponseMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsSmsAutoResponseMutation>;
export type UpdateClinicSettingsConversationsSmsAutoResponseMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsSmsAutoResponseMutation, UpdateClinicSettingsConversationsSmsAutoResponseMutationVariables>;
export const UpdateClinicSettingsConversationsTransferTwilioNumberDocument = gql`
    mutation updateClinicSettingsConversationsTransferTwilioNumber($phoneNumberSid: String!, $fromSubaccountSid: String) {
  transferTwilioNumber(
    data: {phoneNumberSid: $phoneNumberSid, fromSubaccountSid: $fromSubaccountSid}
  ) {
    success
    payload
  }
}
    `;
export type UpdateClinicSettingsConversationsTransferTwilioNumberMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConversationsTransferTwilioNumberMutation, UpdateClinicSettingsConversationsTransferTwilioNumberMutationVariables>;

/**
 * __useUpdateClinicSettingsConversationsTransferTwilioNumberMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConversationsTransferTwilioNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConversationsTransferTwilioNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConversationsTransferTwilioNumberMutation, { data, loading, error }] = useUpdateClinicSettingsConversationsTransferTwilioNumberMutation({
 *   variables: {
 *      phoneNumberSid: // value for 'phoneNumberSid'
 *      fromSubaccountSid: // value for 'fromSubaccountSid'
 *   },
 * });
 */
export function useUpdateClinicSettingsConversationsTransferTwilioNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConversationsTransferTwilioNumberMutation, UpdateClinicSettingsConversationsTransferTwilioNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConversationsTransferTwilioNumberMutation, UpdateClinicSettingsConversationsTransferTwilioNumberMutationVariables>(UpdateClinicSettingsConversationsTransferTwilioNumberDocument, options);
      }
export type UpdateClinicSettingsConversationsTransferTwilioNumberMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConversationsTransferTwilioNumberMutation>;
export type UpdateClinicSettingsConversationsTransferTwilioNumberMutationResult = Apollo.MutationResult<UpdateClinicSettingsConversationsTransferTwilioNumberMutation>;
export type UpdateClinicSettingsConversationsTransferTwilioNumberMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConversationsTransferTwilioNumberMutation, UpdateClinicSettingsConversationsTransferTwilioNumberMutationVariables>;
export const GetClinicPhoneNumbersDocument = gql`
    query getClinicPhoneNumbers($where: ClinicPhoneNumberWhereInput) {
  findManyClinicPhoneNumber(where: $where, orderBy: {createdAt: desc}) {
    ...ClinicPhoneNumber
  }
}
    ${ClinicPhoneNumberFragmentDoc}`;

/**
 * __useGetClinicPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useGetClinicPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPhoneNumbersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicPhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicPhoneNumbersQuery, GetClinicPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPhoneNumbersQuery, GetClinicPhoneNumbersQueryVariables>(GetClinicPhoneNumbersDocument, options);
      }
export function useGetClinicPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPhoneNumbersQuery, GetClinicPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPhoneNumbersQuery, GetClinicPhoneNumbersQueryVariables>(GetClinicPhoneNumbersDocument, options);
        }
export type GetClinicPhoneNumbersQueryHookResult = ReturnType<typeof useGetClinicPhoneNumbersQuery>;
export type GetClinicPhoneNumbersLazyQueryHookResult = ReturnType<typeof useGetClinicPhoneNumbersLazyQuery>;
export type GetClinicPhoneNumbersQueryResult = Apollo.QueryResult<GetClinicPhoneNumbersQuery, GetClinicPhoneNumbersQueryVariables>;
export function refetchGetClinicPhoneNumbersQuery(variables?: GetClinicPhoneNumbersQueryVariables) {
      return { query: GetClinicPhoneNumbersDocument, variables: variables }
    }
export const GetClinicSettingsConversationsAllActiveTwilioNumbersDocument = gql`
    query getClinicSettingsConversationsAllActiveTwilioNumbers {
  getTwilioNumbers {
    success
    payload
  }
}
    `;

/**
 * __useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicSettingsConversationsAllActiveTwilioNumbersQuery, GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConversationsAllActiveTwilioNumbersQuery, GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables>(GetClinicSettingsConversationsAllActiveTwilioNumbersDocument, options);
      }
export function useGetClinicSettingsConversationsAllActiveTwilioNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConversationsAllActiveTwilioNumbersQuery, GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConversationsAllActiveTwilioNumbersQuery, GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables>(GetClinicSettingsConversationsAllActiveTwilioNumbersDocument, options);
        }
export type GetClinicSettingsConversationsAllActiveTwilioNumbersQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsAllActiveTwilioNumbersQuery>;
export type GetClinicSettingsConversationsAllActiveTwilioNumbersLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsAllActiveTwilioNumbersLazyQuery>;
export type GetClinicSettingsConversationsAllActiveTwilioNumbersQueryResult = Apollo.QueryResult<GetClinicSettingsConversationsAllActiveTwilioNumbersQuery, GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables>;
export function refetchGetClinicSettingsConversationsAllActiveTwilioNumbersQuery(variables?: GetClinicSettingsConversationsAllActiveTwilioNumbersQueryVariables) {
      return { query: GetClinicSettingsConversationsAllActiveTwilioNumbersDocument, variables: variables }
    }
export const GetClinicSettingsConversationsSmsDocument = gql`
    query getClinicSettingsConversationsSms($id: String, $clinicId: ID!) {
  findUniqueClinic(where: {id: $id}) {
    id
    hasSmsConversations
    twilioConfiguration {
      id
      subaccountSid
      messagingServiceSid
    }
    clinicSetting {
      id
      smsAutoResponseWhenOpen
      smsAutoResponseWhenClosed
    }
  }
  getTwilioNumbers(data: {clinicId: $clinicId}) {
    success
    payload
  }
}
    `;

/**
 * __useGetClinicSettingsConversationsSmsQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConversationsSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConversationsSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConversationsSmsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicSettingsConversationsSmsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsConversationsSmsQuery, GetClinicSettingsConversationsSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConversationsSmsQuery, GetClinicSettingsConversationsSmsQueryVariables>(GetClinicSettingsConversationsSmsDocument, options);
      }
export function useGetClinicSettingsConversationsSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConversationsSmsQuery, GetClinicSettingsConversationsSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConversationsSmsQuery, GetClinicSettingsConversationsSmsQueryVariables>(GetClinicSettingsConversationsSmsDocument, options);
        }
export type GetClinicSettingsConversationsSmsQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsSmsQuery>;
export type GetClinicSettingsConversationsSmsLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConversationsSmsLazyQuery>;
export type GetClinicSettingsConversationsSmsQueryResult = Apollo.QueryResult<GetClinicSettingsConversationsSmsQuery, GetClinicSettingsConversationsSmsQueryVariables>;
export function refetchGetClinicSettingsConversationsSmsQuery(variables?: GetClinicSettingsConversationsSmsQueryVariables) {
      return { query: GetClinicSettingsConversationsSmsDocument, variables: variables }
    }
export const SearchAvailableBandwidthNumbersDocument = gql`
    query searchAvailableBandwidthNumbers($data: SearchPhoneNumberInput) {
  searchPhoneNumber(data: $data) {
    total
    phoneNumbers
  }
}
    `;

/**
 * __useSearchAvailableBandwidthNumbersQuery__
 *
 * To run a query within a React component, call `useSearchAvailableBandwidthNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAvailableBandwidthNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAvailableBandwidthNumbersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchAvailableBandwidthNumbersQuery(baseOptions?: Apollo.QueryHookOptions<SearchAvailableBandwidthNumbersQuery, SearchAvailableBandwidthNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAvailableBandwidthNumbersQuery, SearchAvailableBandwidthNumbersQueryVariables>(SearchAvailableBandwidthNumbersDocument, options);
      }
export function useSearchAvailableBandwidthNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAvailableBandwidthNumbersQuery, SearchAvailableBandwidthNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAvailableBandwidthNumbersQuery, SearchAvailableBandwidthNumbersQueryVariables>(SearchAvailableBandwidthNumbersDocument, options);
        }
export type SearchAvailableBandwidthNumbersQueryHookResult = ReturnType<typeof useSearchAvailableBandwidthNumbersQuery>;
export type SearchAvailableBandwidthNumbersLazyQueryHookResult = ReturnType<typeof useSearchAvailableBandwidthNumbersLazyQuery>;
export type SearchAvailableBandwidthNumbersQueryResult = Apollo.QueryResult<SearchAvailableBandwidthNumbersQuery, SearchAvailableBandwidthNumbersQueryVariables>;
export function refetchSearchAvailableBandwidthNumbersQuery(variables?: SearchAvailableBandwidthNumbersQueryVariables) {
      return { query: SearchAvailableBandwidthNumbersDocument, variables: variables }
    }
export const UpdateOneClinicPhoneNumberDocument = gql`
    mutation updateOneClinicPhoneNumber($where: ClinicPhoneNumberWhereUniqueInput!, $data: ClinicPhoneNumberUpdateInput!) {
  updateOneClinicPhoneNumber(where: $where, data: $data) {
    ...ClinicPhoneNumber
  }
}
    ${ClinicPhoneNumberFragmentDoc}`;
export type UpdateOneClinicPhoneNumberMutationFn = Apollo.MutationFunction<UpdateOneClinicPhoneNumberMutation, UpdateOneClinicPhoneNumberMutationVariables>;

/**
 * __useUpdateOneClinicPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateOneClinicPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClinicPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClinicPhoneNumberMutation, { data, loading, error }] = useUpdateOneClinicPhoneNumberMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneClinicPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClinicPhoneNumberMutation, UpdateOneClinicPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClinicPhoneNumberMutation, UpdateOneClinicPhoneNumberMutationVariables>(UpdateOneClinicPhoneNumberDocument, options);
      }
export type UpdateOneClinicPhoneNumberMutationHookResult = ReturnType<typeof useUpdateOneClinicPhoneNumberMutation>;
export type UpdateOneClinicPhoneNumberMutationResult = Apollo.MutationResult<UpdateOneClinicPhoneNumberMutation>;
export type UpdateOneClinicPhoneNumberMutationOptions = Apollo.BaseMutationOptions<UpdateOneClinicPhoneNumberMutation, UpdateOneClinicPhoneNumberMutationVariables>;
export const SubscribeToClinicPhoneNumbersDocument = gql`
    subscription subscribeToClinicPhoneNumbers($where: ClinicPhoneNumberWhereInput!) {
  clinicPhoneNumberChanged(where: $where) {
    node {
      ...ClinicPhoneNumber
    }
    mutation
  }
}
    ${ClinicPhoneNumberFragmentDoc}`;

/**
 * __useSubscribeToClinicPhoneNumbersSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClinicPhoneNumbersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClinicPhoneNumbersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClinicPhoneNumbersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToClinicPhoneNumbersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToClinicPhoneNumbersSubscription, SubscribeToClinicPhoneNumbersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToClinicPhoneNumbersSubscription, SubscribeToClinicPhoneNumbersSubscriptionVariables>(SubscribeToClinicPhoneNumbersDocument, options);
      }
export type SubscribeToClinicPhoneNumbersSubscriptionHookResult = ReturnType<typeof useSubscribeToClinicPhoneNumbersSubscription>;
export type SubscribeToClinicPhoneNumbersSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClinicPhoneNumbersSubscription>;
export const SendWidgetCodeToDeveloperDocument = gql`
    mutation sendWidgetCodeToDeveloper($data: SendWidgetCodeToDeveloperInput!) {
  sendWidgetCodeToDeveloper(data: $data) {
    success
  }
}
    `;
export type SendWidgetCodeToDeveloperMutationFn = Apollo.MutationFunction<SendWidgetCodeToDeveloperMutation, SendWidgetCodeToDeveloperMutationVariables>;

/**
 * __useSendWidgetCodeToDeveloperMutation__
 *
 * To run a mutation, you first call `useSendWidgetCodeToDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWidgetCodeToDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWidgetCodeToDeveloperMutation, { data, loading, error }] = useSendWidgetCodeToDeveloperMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendWidgetCodeToDeveloperMutation(baseOptions?: Apollo.MutationHookOptions<SendWidgetCodeToDeveloperMutation, SendWidgetCodeToDeveloperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWidgetCodeToDeveloperMutation, SendWidgetCodeToDeveloperMutationVariables>(SendWidgetCodeToDeveloperDocument, options);
      }
export type SendWidgetCodeToDeveloperMutationHookResult = ReturnType<typeof useSendWidgetCodeToDeveloperMutation>;
export type SendWidgetCodeToDeveloperMutationResult = Apollo.MutationResult<SendWidgetCodeToDeveloperMutation>;
export type SendWidgetCodeToDeveloperMutationOptions = Apollo.BaseMutationOptions<SendWidgetCodeToDeveloperMutation, SendWidgetCodeToDeveloperMutationVariables>;
export const SoftDeleteClinicSettingsConnectWidgetFormTemplateDocument = gql`
    mutation softDeleteClinicSettingsConnectWidgetFormTemplate($where: FormTemplateWhereUniqueInput!) {
  updateOneFormTemplate(where: $where, data: {isDeleted: true, isActive: false}) {
    id
  }
}
    `;
export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationFn = Apollo.MutationFunction<SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation, SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationVariables>;

/**
 * __useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation__
 *
 * To run a mutation, you first call `useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteClinicSettingsConnectWidgetFormTemplateMutation, { data, loading, error }] = useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation, SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation, SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationVariables>(SoftDeleteClinicSettingsConnectWidgetFormTemplateDocument, options);
      }
export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationHookResult = ReturnType<typeof useSoftDeleteClinicSettingsConnectWidgetFormTemplateMutation>;
export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationResult = Apollo.MutationResult<SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation>;
export type SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationOptions = Apollo.BaseMutationOptions<SoftDeleteClinicSettingsConnectWidgetFormTemplateMutation, SoftDeleteClinicSettingsConnectWidgetFormTemplateMutationVariables>;
export const UpdateClinicSettingsConnectWidgetDocument = gql`
    mutation updateClinicSettingsConnectWidget($where: ClinicWhereUniqueInput!, $data: ClinicUpdateInput!) {
  updateOneClinic(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateClinicSettingsConnectWidgetMutationFn = Apollo.MutationFunction<UpdateClinicSettingsConnectWidgetMutation, UpdateClinicSettingsConnectWidgetMutationVariables>;

/**
 * __useUpdateClinicSettingsConnectWidgetMutation__
 *
 * To run a mutation, you first call `useUpdateClinicSettingsConnectWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicSettingsConnectWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicSettingsConnectWidgetMutation, { data, loading, error }] = useUpdateClinicSettingsConnectWidgetMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicSettingsConnectWidgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicSettingsConnectWidgetMutation, UpdateClinicSettingsConnectWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicSettingsConnectWidgetMutation, UpdateClinicSettingsConnectWidgetMutationVariables>(UpdateClinicSettingsConnectWidgetDocument, options);
      }
export type UpdateClinicSettingsConnectWidgetMutationHookResult = ReturnType<typeof useUpdateClinicSettingsConnectWidgetMutation>;
export type UpdateClinicSettingsConnectWidgetMutationResult = Apollo.MutationResult<UpdateClinicSettingsConnectWidgetMutation>;
export type UpdateClinicSettingsConnectWidgetMutationOptions = Apollo.BaseMutationOptions<UpdateClinicSettingsConnectWidgetMutation, UpdateClinicSettingsConnectWidgetMutationVariables>;
export const GetClinicSettingsConnectWidgetDocument = gql`
    query getClinicSettingsConnectWidget($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    clinicWidgetSetting {
      id
      isEnabled
      welcomeMessage
      primaryColor
      secondaryColor
      whiteLabelUrls
      ...ConnectSettingsClinicWidgetSetting
    }
  }
}
    ${ConnectSettingsClinicWidgetSettingFragmentDoc}`;

/**
 * __useGetClinicSettingsConnectWidgetQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConnectWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConnectWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConnectWidgetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsConnectWidgetQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsConnectWidgetQuery, GetClinicSettingsConnectWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConnectWidgetQuery, GetClinicSettingsConnectWidgetQueryVariables>(GetClinicSettingsConnectWidgetDocument, options);
      }
export function useGetClinicSettingsConnectWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConnectWidgetQuery, GetClinicSettingsConnectWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConnectWidgetQuery, GetClinicSettingsConnectWidgetQueryVariables>(GetClinicSettingsConnectWidgetDocument, options);
        }
export type GetClinicSettingsConnectWidgetQueryHookResult = ReturnType<typeof useGetClinicSettingsConnectWidgetQuery>;
export type GetClinicSettingsConnectWidgetLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConnectWidgetLazyQuery>;
export type GetClinicSettingsConnectWidgetQueryResult = Apollo.QueryResult<GetClinicSettingsConnectWidgetQuery, GetClinicSettingsConnectWidgetQueryVariables>;
export function refetchGetClinicSettingsConnectWidgetQuery(variables?: GetClinicSettingsConnectWidgetQueryVariables) {
      return { query: GetClinicSettingsConnectWidgetDocument, variables: variables }
    }
export const GetClinicSettingsConnectWidgetRequestOptionsDocument = gql`
    query getClinicSettingsConnectWidgetRequestOptions($where: ClinicWhereUniqueInput!) {
  findUniqueClinic(where: $where) {
    clinicWidgetSetting {
      id
      ...ConnectSettingsClinicWidgetSetting
    }
  }
}
    ${ConnectSettingsClinicWidgetSettingFragmentDoc}`;

/**
 * __useGetClinicSettingsConnectWidgetRequestOptionsQuery__
 *
 * To run a query within a React component, call `useGetClinicSettingsConnectWidgetRequestOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicSettingsConnectWidgetRequestOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicSettingsConnectWidgetRequestOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicSettingsConnectWidgetRequestOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicSettingsConnectWidgetRequestOptionsQuery, GetClinicSettingsConnectWidgetRequestOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicSettingsConnectWidgetRequestOptionsQuery, GetClinicSettingsConnectWidgetRequestOptionsQueryVariables>(GetClinicSettingsConnectWidgetRequestOptionsDocument, options);
      }
export function useGetClinicSettingsConnectWidgetRequestOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicSettingsConnectWidgetRequestOptionsQuery, GetClinicSettingsConnectWidgetRequestOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicSettingsConnectWidgetRequestOptionsQuery, GetClinicSettingsConnectWidgetRequestOptionsQueryVariables>(GetClinicSettingsConnectWidgetRequestOptionsDocument, options);
        }
export type GetClinicSettingsConnectWidgetRequestOptionsQueryHookResult = ReturnType<typeof useGetClinicSettingsConnectWidgetRequestOptionsQuery>;
export type GetClinicSettingsConnectWidgetRequestOptionsLazyQueryHookResult = ReturnType<typeof useGetClinicSettingsConnectWidgetRequestOptionsLazyQuery>;
export type GetClinicSettingsConnectWidgetRequestOptionsQueryResult = Apollo.QueryResult<GetClinicSettingsConnectWidgetRequestOptionsQuery, GetClinicSettingsConnectWidgetRequestOptionsQueryVariables>;
export function refetchGetClinicSettingsConnectWidgetRequestOptionsQuery(variables?: GetClinicSettingsConnectWidgetRequestOptionsQueryVariables) {
      return { query: GetClinicSettingsConnectWidgetRequestOptionsDocument, variables: variables }
    }
export const GetFormTemplateContentDocument = gql`
    query getFormTemplateContent($formTemplateId: String!) {
  findUniqueFormTemplate(where: {id: $formTemplateId}) {
    id
    content
  }
}
    `;

/**
 * __useGetFormTemplateContentQuery__
 *
 * To run a query within a React component, call `useGetFormTemplateContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormTemplateContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormTemplateContentQuery({
 *   variables: {
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useGetFormTemplateContentQuery(baseOptions: Apollo.QueryHookOptions<GetFormTemplateContentQuery, GetFormTemplateContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormTemplateContentQuery, GetFormTemplateContentQueryVariables>(GetFormTemplateContentDocument, options);
      }
export function useGetFormTemplateContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormTemplateContentQuery, GetFormTemplateContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormTemplateContentQuery, GetFormTemplateContentQueryVariables>(GetFormTemplateContentDocument, options);
        }
export type GetFormTemplateContentQueryHookResult = ReturnType<typeof useGetFormTemplateContentQuery>;
export type GetFormTemplateContentLazyQueryHookResult = ReturnType<typeof useGetFormTemplateContentLazyQuery>;
export type GetFormTemplateContentQueryResult = Apollo.QueryResult<GetFormTemplateContentQuery, GetFormTemplateContentQueryVariables>;
export function refetchGetFormTemplateContentQuery(variables?: GetFormTemplateContentQueryVariables) {
      return { query: GetFormTemplateContentDocument, variables: variables }
    }
export const RetrieveAuthTokenForContactDocument = gql`
    query retrieveAuthTokenForContact {
  retrieveAuthTokenForContact {
    authToken
  }
}
    `;

/**
 * __useRetrieveAuthTokenForContactQuery__
 *
 * To run a query within a React component, call `useRetrieveAuthTokenForContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAuthTokenForContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveAuthTokenForContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetrieveAuthTokenForContactQuery(baseOptions?: Apollo.QueryHookOptions<RetrieveAuthTokenForContactQuery, RetrieveAuthTokenForContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveAuthTokenForContactQuery, RetrieveAuthTokenForContactQueryVariables>(RetrieveAuthTokenForContactDocument, options);
      }
export function useRetrieveAuthTokenForContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveAuthTokenForContactQuery, RetrieveAuthTokenForContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveAuthTokenForContactQuery, RetrieveAuthTokenForContactQueryVariables>(RetrieveAuthTokenForContactDocument, options);
        }
export type RetrieveAuthTokenForContactQueryHookResult = ReturnType<typeof useRetrieveAuthTokenForContactQuery>;
export type RetrieveAuthTokenForContactLazyQueryHookResult = ReturnType<typeof useRetrieveAuthTokenForContactLazyQuery>;
export type RetrieveAuthTokenForContactQueryResult = Apollo.QueryResult<RetrieveAuthTokenForContactQuery, RetrieveAuthTokenForContactQueryVariables>;
export function refetchRetrieveAuthTokenForContactQuery(variables?: RetrieveAuthTokenForContactQueryVariables) {
      return { query: RetrieveAuthTokenForContactDocument, variables: variables }
    }
export const CreateSurveyChannelStatusDocument = gql`
    mutation createSurveyChannelStatus($data: ChannelStatusCreateInput!) {
  createOneChannelStatus(data: $data) {
    ...ClinicSettingsConversationsChannelStatus
  }
}
    ${ClinicSettingsConversationsChannelStatusFragmentDoc}`;
export type CreateSurveyChannelStatusMutationFn = Apollo.MutationFunction<CreateSurveyChannelStatusMutation, CreateSurveyChannelStatusMutationVariables>;

/**
 * __useCreateSurveyChannelStatusMutation__
 *
 * To run a mutation, you first call `useCreateSurveyChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyChannelStatusMutation, { data, loading, error }] = useCreateSurveyChannelStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSurveyChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyChannelStatusMutation, CreateSurveyChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyChannelStatusMutation, CreateSurveyChannelStatusMutationVariables>(CreateSurveyChannelStatusDocument, options);
      }
export type CreateSurveyChannelStatusMutationHookResult = ReturnType<typeof useCreateSurveyChannelStatusMutation>;
export type CreateSurveyChannelStatusMutationResult = Apollo.MutationResult<CreateSurveyChannelStatusMutation>;
export type CreateSurveyChannelStatusMutationOptions = Apollo.BaseMutationOptions<CreateSurveyChannelStatusMutation, CreateSurveyChannelStatusMutationVariables>;
export const InitSurveySettingDocument = gql`
    mutation initSurveySetting($clinicId: String!) {
  initSurveySetting(clinicId: $clinicId) {
    id
  }
}
    `;
export type InitSurveySettingMutationFn = Apollo.MutationFunction<InitSurveySettingMutation, InitSurveySettingMutationVariables>;

/**
 * __useInitSurveySettingMutation__
 *
 * To run a mutation, you first call `useInitSurveySettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitSurveySettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initSurveySettingMutation, { data, loading, error }] = useInitSurveySettingMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useInitSurveySettingMutation(baseOptions?: Apollo.MutationHookOptions<InitSurveySettingMutation, InitSurveySettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitSurveySettingMutation, InitSurveySettingMutationVariables>(InitSurveySettingDocument, options);
      }
export type InitSurveySettingMutationHookResult = ReturnType<typeof useInitSurveySettingMutation>;
export type InitSurveySettingMutationResult = Apollo.MutationResult<InitSurveySettingMutation>;
export type InitSurveySettingMutationOptions = Apollo.BaseMutationOptions<InitSurveySettingMutation, InitSurveySettingMutationVariables>;
export const UpdateSurveyGeneralSettingsDocument = gql`
    mutation updateSurveyGeneralSettings($where: SurveySettingWhereUniqueInput!, $data: SurveySettingUpdateInput!) {
  updateOneSurveySetting(data: $data, where: $where) {
    id
    isAutomatedTriggerForAllAppointmentTypes
    frequencyInDays
    showLinkToLeaveReviewNeutral
    showLinkToLeaveReviewSatisfied
    linkToLeaveReview
  }
}
    `;
export type UpdateSurveyGeneralSettingsMutationFn = Apollo.MutationFunction<UpdateSurveyGeneralSettingsMutation, UpdateSurveyGeneralSettingsMutationVariables>;

/**
 * __useUpdateSurveyGeneralSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyGeneralSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyGeneralSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyGeneralSettingsMutation, { data, loading, error }] = useUpdateSurveyGeneralSettingsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSurveyGeneralSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyGeneralSettingsMutation, UpdateSurveyGeneralSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyGeneralSettingsMutation, UpdateSurveyGeneralSettingsMutationVariables>(UpdateSurveyGeneralSettingsDocument, options);
      }
export type UpdateSurveyGeneralSettingsMutationHookResult = ReturnType<typeof useUpdateSurveyGeneralSettingsMutation>;
export type UpdateSurveyGeneralSettingsMutationResult = Apollo.MutationResult<UpdateSurveyGeneralSettingsMutation>;
export type UpdateSurveyGeneralSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyGeneralSettingsMutation, UpdateSurveyGeneralSettingsMutationVariables>;
export const UpsertSurveyAppointmentTypeSettingsDocument = gql`
    mutation upsertSurveyAppointmentTypeSettings($where: SurveyAppointmentTypeSettingWhereUniqueInput!, $create: SurveyAppointmentTypeSettingCreateInput!, $update: SurveyAppointmentTypeSettingUpdateInput!) {
  upsertOneSurveyAppointmentTypeSetting(
    create: $create
    update: $update
    where: $where
  ) {
    id
    isActive
    automatedTriggerInMinutes
    surveySettingId
  }
}
    `;
export type UpsertSurveyAppointmentTypeSettingsMutationFn = Apollo.MutationFunction<UpsertSurveyAppointmentTypeSettingsMutation, UpsertSurveyAppointmentTypeSettingsMutationVariables>;

/**
 * __useUpsertSurveyAppointmentTypeSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertSurveyAppointmentTypeSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSurveyAppointmentTypeSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSurveyAppointmentTypeSettingsMutation, { data, loading, error }] = useUpsertSurveyAppointmentTypeSettingsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      create: // value for 'create'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpsertSurveyAppointmentTypeSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSurveyAppointmentTypeSettingsMutation, UpsertSurveyAppointmentTypeSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSurveyAppointmentTypeSettingsMutation, UpsertSurveyAppointmentTypeSettingsMutationVariables>(UpsertSurveyAppointmentTypeSettingsDocument, options);
      }
export type UpsertSurveyAppointmentTypeSettingsMutationHookResult = ReturnType<typeof useUpsertSurveyAppointmentTypeSettingsMutation>;
export type UpsertSurveyAppointmentTypeSettingsMutationResult = Apollo.MutationResult<UpsertSurveyAppointmentTypeSettingsMutation>;
export type UpsertSurveyAppointmentTypeSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertSurveyAppointmentTypeSettingsMutation, UpsertSurveyAppointmentTypeSettingsMutationVariables>;
export const GetDefaultSurveyStatusesDocument = gql`
    query getDefaultSurveyStatuses($clinicSettingId: String!, $positiveStatusNames: [String!], $negativeStatusNames: [String!], $sentStatusNames: [String!]) {
  positiveStatus: findFirstChannelStatus(
    where: {name: {in: $positiveStatusNames}, clinicSettingId: {equals: $clinicSettingId}}
  ) {
    id
    name
  }
  negativeStatus: findFirstChannelStatus(
    where: {name: {in: $negativeStatusNames}, clinicSettingId: {equals: $clinicSettingId}}
  ) {
    id
    name
  }
  sentStatus: findFirstChannelStatus(
    where: {name: {in: $sentStatusNames}, clinicSettingId: {equals: $clinicSettingId}}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetDefaultSurveyStatusesQuery__
 *
 * To run a query within a React component, call `useGetDefaultSurveyStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultSurveyStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultSurveyStatusesQuery({
 *   variables: {
 *      clinicSettingId: // value for 'clinicSettingId'
 *      positiveStatusNames: // value for 'positiveStatusNames'
 *      negativeStatusNames: // value for 'negativeStatusNames'
 *      sentStatusNames: // value for 'sentStatusNames'
 *   },
 * });
 */
export function useGetDefaultSurveyStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetDefaultSurveyStatusesQuery, GetDefaultSurveyStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultSurveyStatusesQuery, GetDefaultSurveyStatusesQueryVariables>(GetDefaultSurveyStatusesDocument, options);
      }
export function useGetDefaultSurveyStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultSurveyStatusesQuery, GetDefaultSurveyStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultSurveyStatusesQuery, GetDefaultSurveyStatusesQueryVariables>(GetDefaultSurveyStatusesDocument, options);
        }
export type GetDefaultSurveyStatusesQueryHookResult = ReturnType<typeof useGetDefaultSurveyStatusesQuery>;
export type GetDefaultSurveyStatusesLazyQueryHookResult = ReturnType<typeof useGetDefaultSurveyStatusesLazyQuery>;
export type GetDefaultSurveyStatusesQueryResult = Apollo.QueryResult<GetDefaultSurveyStatusesQuery, GetDefaultSurveyStatusesQueryVariables>;
export function refetchGetDefaultSurveyStatusesQuery(variables?: GetDefaultSurveyStatusesQueryVariables) {
      return { query: GetDefaultSurveyStatusesDocument, variables: variables }
    }
export const GetSurveyAppointmentTypeSettingsDocument = gql`
    query getSurveyAppointmentTypeSettings($clinicId: String!) {
  findManyAppointmentType(
    where: {clinicId: {equals: $clinicId}, isVisible: {equals: true}}
  ) {
    id
    createdAt
    updatedAt
    name
    displayName
    description
    surveyAppointmentTypeSettings {
      id
      createdAt
      updatedAt
      isActive
      automatedTriggerInMinutes
      surveySettingId
    }
  }
}
    `;

/**
 * __useGetSurveyAppointmentTypeSettingsQuery__
 *
 * To run a query within a React component, call `useGetSurveyAppointmentTypeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyAppointmentTypeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyAppointmentTypeSettingsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetSurveyAppointmentTypeSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyAppointmentTypeSettingsQuery, GetSurveyAppointmentTypeSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyAppointmentTypeSettingsQuery, GetSurveyAppointmentTypeSettingsQueryVariables>(GetSurveyAppointmentTypeSettingsDocument, options);
      }
export function useGetSurveyAppointmentTypeSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyAppointmentTypeSettingsQuery, GetSurveyAppointmentTypeSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyAppointmentTypeSettingsQuery, GetSurveyAppointmentTypeSettingsQueryVariables>(GetSurveyAppointmentTypeSettingsDocument, options);
        }
export type GetSurveyAppointmentTypeSettingsQueryHookResult = ReturnType<typeof useGetSurveyAppointmentTypeSettingsQuery>;
export type GetSurveyAppointmentTypeSettingsLazyQueryHookResult = ReturnType<typeof useGetSurveyAppointmentTypeSettingsLazyQuery>;
export type GetSurveyAppointmentTypeSettingsQueryResult = Apollo.QueryResult<GetSurveyAppointmentTypeSettingsQuery, GetSurveyAppointmentTypeSettingsQueryVariables>;
export function refetchGetSurveyAppointmentTypeSettingsQuery(variables?: GetSurveyAppointmentTypeSettingsQueryVariables) {
      return { query: GetSurveyAppointmentTypeSettingsDocument, variables: variables }
    }
export const GetSurveyGeneralSettingsDocument = gql`
    query getSurveyGeneralSettings($settingId: String!) {
  findUniqueSurveySetting(where: {id: $settingId}) {
    id
    createdAt
    updatedAt
    isAutomatedTriggerForAllAppointmentTypes
    frequencyInDays
    showLinkToLeaveReviewNeutral
    showLinkToLeaveReviewSatisfied
    linkToLeaveReview
  }
}
    `;

/**
 * __useGetSurveyGeneralSettingsQuery__
 *
 * To run a query within a React component, call `useGetSurveyGeneralSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyGeneralSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyGeneralSettingsQuery({
 *   variables: {
 *      settingId: // value for 'settingId'
 *   },
 * });
 */
export function useGetSurveyGeneralSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyGeneralSettingsQuery, GetSurveyGeneralSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyGeneralSettingsQuery, GetSurveyGeneralSettingsQueryVariables>(GetSurveyGeneralSettingsDocument, options);
      }
export function useGetSurveyGeneralSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyGeneralSettingsQuery, GetSurveyGeneralSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyGeneralSettingsQuery, GetSurveyGeneralSettingsQueryVariables>(GetSurveyGeneralSettingsDocument, options);
        }
export type GetSurveyGeneralSettingsQueryHookResult = ReturnType<typeof useGetSurveyGeneralSettingsQuery>;
export type GetSurveyGeneralSettingsLazyQueryHookResult = ReturnType<typeof useGetSurveyGeneralSettingsLazyQuery>;
export type GetSurveyGeneralSettingsQueryResult = Apollo.QueryResult<GetSurveyGeneralSettingsQuery, GetSurveyGeneralSettingsQueryVariables>;
export function refetchGetSurveyGeneralSettingsQuery(variables?: GetSurveyGeneralSettingsQueryVariables) {
      return { query: GetSurveyGeneralSettingsDocument, variables: variables }
    }
export const GetSurveyWorkflowEventSettingsDocument = gql`
    query getSurveyWorkflowEventSettings($clinicId: String!) {
  findManyWorkflowEventSetting(
    where: {surveySettingId: {not: {equals: null}}, clinicId: {equals: $clinicId}, deletedAt: null}
  ) {
    id
    name
    surveySettingId
    surveyStep
    surveyType
    isPublished
    sendingHours
    actions {
      id
      actionType
      order
      config
      channelStatusChangeId
      channelStatusChange {
        id
        name
      }
    }
    notificationBody
    event
  }
}
    `;

/**
 * __useGetSurveyWorkflowEventSettingsQuery__
 *
 * To run a query within a React component, call `useGetSurveyWorkflowEventSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyWorkflowEventSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyWorkflowEventSettingsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetSurveyWorkflowEventSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyWorkflowEventSettingsQuery, GetSurveyWorkflowEventSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyWorkflowEventSettingsQuery, GetSurveyWorkflowEventSettingsQueryVariables>(GetSurveyWorkflowEventSettingsDocument, options);
      }
export function useGetSurveyWorkflowEventSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyWorkflowEventSettingsQuery, GetSurveyWorkflowEventSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyWorkflowEventSettingsQuery, GetSurveyWorkflowEventSettingsQueryVariables>(GetSurveyWorkflowEventSettingsDocument, options);
        }
export type GetSurveyWorkflowEventSettingsQueryHookResult = ReturnType<typeof useGetSurveyWorkflowEventSettingsQuery>;
export type GetSurveyWorkflowEventSettingsLazyQueryHookResult = ReturnType<typeof useGetSurveyWorkflowEventSettingsLazyQuery>;
export type GetSurveyWorkflowEventSettingsQueryResult = Apollo.QueryResult<GetSurveyWorkflowEventSettingsQuery, GetSurveyWorkflowEventSettingsQueryVariables>;
export function refetchGetSurveyWorkflowEventSettingsQuery(variables?: GetSurveyWorkflowEventSettingsQueryVariables) {
      return { query: GetSurveyWorkflowEventSettingsDocument, variables: variables }
    }
export const CreateTeamChannelDocument = gql`
    mutation createTeamChannel($data: CreateTeamChannelInput!) {
  createTeamChannel2(data: $data) {
    id
    friendlyName
    channelVisibility
  }
}
    `;
export type CreateTeamChannelMutationFn = Apollo.MutationFunction<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>;

/**
 * __useCreateTeamChannelMutation__
 *
 * To run a mutation, you first call `useCreateTeamChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamChannelMutation, { data, loading, error }] = useCreateTeamChannelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTeamChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>(CreateTeamChannelDocument, options);
      }
export type CreateTeamChannelMutationHookResult = ReturnType<typeof useCreateTeamChannelMutation>;
export type CreateTeamChannelMutationResult = Apollo.MutationResult<CreateTeamChannelMutation>;
export type CreateTeamChannelMutationOptions = Apollo.BaseMutationOptions<CreateTeamChannelMutation, CreateTeamChannelMutationVariables>;
export const GetTeamChannelsDocument = gql`
    query getTeamChannels($wherePublic: ChannelWhereInput!, $orderBy: [ChannelOrderByWithRelationAndSearchRelevanceInput!], $wherePrivate: ChannelWhereInput!, $whereDirectMessages: ChannelWhereInput!) {
  publicChannels: findManyChannel(where: $wherePublic, orderBy: $orderBy) {
    id
    isActive
    friendlyName
    channelVisibility
  }
  privateChannels: findManyChannel(where: $wherePrivate, orderBy: $orderBy) {
    id
    isActive
    friendlyName
    channelVisibility
  }
  directMessages: findManyChannel(where: $whereDirectMessages, orderBy: $orderBy) {
    id
    isActive
    friendlyName
    channelVisibility
    assignees {
      ...ClinicUserAvatar
    }
  }
}
    ${ClinicUserAvatarFragmentDoc}`;

/**
 * __useGetTeamChannelsQuery__
 *
 * To run a query within a React component, call `useGetTeamChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamChannelsQuery({
 *   variables: {
 *      wherePublic: // value for 'wherePublic'
 *      orderBy: // value for 'orderBy'
 *      wherePrivate: // value for 'wherePrivate'
 *      whereDirectMessages: // value for 'whereDirectMessages'
 *   },
 * });
 */
export function useGetTeamChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamChannelsQuery, GetTeamChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamChannelsQuery, GetTeamChannelsQueryVariables>(GetTeamChannelsDocument, options);
      }
export function useGetTeamChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamChannelsQuery, GetTeamChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamChannelsQuery, GetTeamChannelsQueryVariables>(GetTeamChannelsDocument, options);
        }
export type GetTeamChannelsQueryHookResult = ReturnType<typeof useGetTeamChannelsQuery>;
export type GetTeamChannelsLazyQueryHookResult = ReturnType<typeof useGetTeamChannelsLazyQuery>;
export type GetTeamChannelsQueryResult = Apollo.QueryResult<GetTeamChannelsQuery, GetTeamChannelsQueryVariables>;
export function refetchGetTeamChannelsQuery(variables?: GetTeamChannelsQueryVariables) {
      return { query: GetTeamChannelsDocument, variables: variables }
    }
export const SubscribeToTeamChannelsDocument = gql`
    subscription subscribeToTeamChannels($where: SubscriptionChannelWhereInput!) {
  channelChanged(where: $where) {
    mutation
    node {
      id
      isActive
      friendlyName
      channelType
      channelVisibility
    }
  }
}
    `;

/**
 * __useSubscribeToTeamChannelsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTeamChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTeamChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTeamChannelsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToTeamChannelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToTeamChannelsSubscription, SubscribeToTeamChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToTeamChannelsSubscription, SubscribeToTeamChannelsSubscriptionVariables>(SubscribeToTeamChannelsDocument, options);
      }
export type SubscribeToTeamChannelsSubscriptionHookResult = ReturnType<typeof useSubscribeToTeamChannelsSubscription>;
export type SubscribeToTeamChannelsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToTeamChannelsSubscription>;
export const FindManyAppointmentAutomationsDocument = gql`
    query findManyAppointmentAutomations($where: AppointmentWhereInput) {
  findManyAppointment(where: $where) {
    id
    description
    startAt
    appointmentType {
      displayName
      name
    }
    clinicPet {
      name
    }
    workflows {
      id
      name
      event
      surveySettingId
      surveyStep
      surveyType
      isPublished
    }
  }
}
    `;

/**
 * __useFindManyAppointmentAutomationsQuery__
 *
 * To run a query within a React component, call `useFindManyAppointmentAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAppointmentAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAppointmentAutomationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyAppointmentAutomationsQuery(baseOptions?: Apollo.QueryHookOptions<FindManyAppointmentAutomationsQuery, FindManyAppointmentAutomationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyAppointmentAutomationsQuery, FindManyAppointmentAutomationsQueryVariables>(FindManyAppointmentAutomationsDocument, options);
      }
export function useFindManyAppointmentAutomationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyAppointmentAutomationsQuery, FindManyAppointmentAutomationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyAppointmentAutomationsQuery, FindManyAppointmentAutomationsQueryVariables>(FindManyAppointmentAutomationsDocument, options);
        }
export type FindManyAppointmentAutomationsQueryHookResult = ReturnType<typeof useFindManyAppointmentAutomationsQuery>;
export type FindManyAppointmentAutomationsLazyQueryHookResult = ReturnType<typeof useFindManyAppointmentAutomationsLazyQuery>;
export type FindManyAppointmentAutomationsQueryResult = Apollo.QueryResult<FindManyAppointmentAutomationsQuery, FindManyAppointmentAutomationsQueryVariables>;
export function refetchFindManyAppointmentAutomationsQuery(variables?: FindManyAppointmentAutomationsQueryVariables) {
      return { query: FindManyAppointmentAutomationsDocument, variables: variables }
    }
export const GetAutomationRunDocument = gql`
    query getAutomationRun($where: AutomationRunWhereUniqueInput!) {
  findUniqueAutomationRun(where: $where) {
    ...AutomationRun
  }
}
    ${AutomationRunFragmentDoc}`;

/**
 * __useGetAutomationRunQuery__
 *
 * To run a query within a React component, call `useGetAutomationRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationRunQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAutomationRunQuery(baseOptions: Apollo.QueryHookOptions<GetAutomationRunQuery, GetAutomationRunQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationRunQuery, GetAutomationRunQueryVariables>(GetAutomationRunDocument, options);
      }
export function useGetAutomationRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationRunQuery, GetAutomationRunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationRunQuery, GetAutomationRunQueryVariables>(GetAutomationRunDocument, options);
        }
export type GetAutomationRunQueryHookResult = ReturnType<typeof useGetAutomationRunQuery>;
export type GetAutomationRunLazyQueryHookResult = ReturnType<typeof useGetAutomationRunLazyQuery>;
export type GetAutomationRunQueryResult = Apollo.QueryResult<GetAutomationRunQuery, GetAutomationRunQueryVariables>;
export function refetchGetAutomationRunQuery(variables?: GetAutomationRunQueryVariables) {
      return { query: GetAutomationRunDocument, variables: variables }
    }
export const GetManyAutomationRunsDocument = gql`
    query getManyAutomationRuns($where: AutomationRunWhereInput!) {
  findManyAutomationRun(where: $where) {
    ...AutomationRun
  }
}
    ${AutomationRunFragmentDoc}`;

/**
 * __useGetManyAutomationRunsQuery__
 *
 * To run a query within a React component, call `useGetManyAutomationRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyAutomationRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyAutomationRunsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetManyAutomationRunsQuery(baseOptions: Apollo.QueryHookOptions<GetManyAutomationRunsQuery, GetManyAutomationRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyAutomationRunsQuery, GetManyAutomationRunsQueryVariables>(GetManyAutomationRunsDocument, options);
      }
export function useGetManyAutomationRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyAutomationRunsQuery, GetManyAutomationRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyAutomationRunsQuery, GetManyAutomationRunsQueryVariables>(GetManyAutomationRunsDocument, options);
        }
export type GetManyAutomationRunsQueryHookResult = ReturnType<typeof useGetManyAutomationRunsQuery>;
export type GetManyAutomationRunsLazyQueryHookResult = ReturnType<typeof useGetManyAutomationRunsLazyQuery>;
export type GetManyAutomationRunsQueryResult = Apollo.QueryResult<GetManyAutomationRunsQuery, GetManyAutomationRunsQueryVariables>;
export function refetchGetManyAutomationRunsQuery(variables?: GetManyAutomationRunsQueryVariables) {
      return { query: GetManyAutomationRunsDocument, variables: variables }
    }
export const GetAutomationRunActionDocument = gql`
    query getAutomationRunAction($where: AutomationRunActionWhereInput, $orderBy: [AutomationRunActionOrderByWithRelationAndSearchRelevanceInput]) {
  findManyAutomationRunAction(where: $where, orderBy: $orderBy) {
    ...AutomationRunAction
  }
}
    ${AutomationRunActionFragmentDoc}`;

/**
 * __useGetAutomationRunActionQuery__
 *
 * To run a query within a React component, call `useGetAutomationRunActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomationRunActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomationRunActionQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAutomationRunActionQuery(baseOptions?: Apollo.QueryHookOptions<GetAutomationRunActionQuery, GetAutomationRunActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutomationRunActionQuery, GetAutomationRunActionQueryVariables>(GetAutomationRunActionDocument, options);
      }
export function useGetAutomationRunActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutomationRunActionQuery, GetAutomationRunActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutomationRunActionQuery, GetAutomationRunActionQueryVariables>(GetAutomationRunActionDocument, options);
        }
export type GetAutomationRunActionQueryHookResult = ReturnType<typeof useGetAutomationRunActionQuery>;
export type GetAutomationRunActionLazyQueryHookResult = ReturnType<typeof useGetAutomationRunActionLazyQuery>;
export type GetAutomationRunActionQueryResult = Apollo.QueryResult<GetAutomationRunActionQuery, GetAutomationRunActionQueryVariables>;
export function refetchGetAutomationRunActionQuery(variables?: GetAutomationRunActionQueryVariables) {
      return { query: GetAutomationRunActionDocument, variables: variables }
    }
export const DeleteManyAutomationRunActionDocument = gql`
    mutation deleteManyAutomationRunAction($where: AutomationRunActionWhereInput!) {
  deleteManyAutomationRunAction(where: $where) {
    count
  }
}
    `;
export type DeleteManyAutomationRunActionMutationFn = Apollo.MutationFunction<DeleteManyAutomationRunActionMutation, DeleteManyAutomationRunActionMutationVariables>;

/**
 * __useDeleteManyAutomationRunActionMutation__
 *
 * To run a mutation, you first call `useDeleteManyAutomationRunActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyAutomationRunActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyAutomationRunActionMutation, { data, loading, error }] = useDeleteManyAutomationRunActionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyAutomationRunActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyAutomationRunActionMutation, DeleteManyAutomationRunActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyAutomationRunActionMutation, DeleteManyAutomationRunActionMutationVariables>(DeleteManyAutomationRunActionDocument, options);
      }
export type DeleteManyAutomationRunActionMutationHookResult = ReturnType<typeof useDeleteManyAutomationRunActionMutation>;
export type DeleteManyAutomationRunActionMutationResult = Apollo.MutationResult<DeleteManyAutomationRunActionMutation>;
export type DeleteManyAutomationRunActionMutationOptions = Apollo.BaseMutationOptions<DeleteManyAutomationRunActionMutation, DeleteManyAutomationRunActionMutationVariables>;
export const UpdateAutomationRunActionDocument = gql`
    mutation updateAutomationRunAction($where: AutomationRunActionWhereUniqueInput!, $data: AutomationRunActionUpdateInput!) {
  updateOneAutomationRunAction(where: $where, data: $data) {
    ...AutomationRunAction
  }
}
    ${AutomationRunActionFragmentDoc}`;
export type UpdateAutomationRunActionMutationFn = Apollo.MutationFunction<UpdateAutomationRunActionMutation, UpdateAutomationRunActionMutationVariables>;

/**
 * __useUpdateAutomationRunActionMutation__
 *
 * To run a mutation, you first call `useUpdateAutomationRunActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutomationRunActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutomationRunActionMutation, { data, loading, error }] = useUpdateAutomationRunActionMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAutomationRunActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutomationRunActionMutation, UpdateAutomationRunActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutomationRunActionMutation, UpdateAutomationRunActionMutationVariables>(UpdateAutomationRunActionDocument, options);
      }
export type UpdateAutomationRunActionMutationHookResult = ReturnType<typeof useUpdateAutomationRunActionMutation>;
export type UpdateAutomationRunActionMutationResult = Apollo.MutationResult<UpdateAutomationRunActionMutation>;
export type UpdateAutomationRunActionMutationOptions = Apollo.BaseMutationOptions<UpdateAutomationRunActionMutation, UpdateAutomationRunActionMutationVariables>;
export const UpdateAutomationRunDocument = gql`
    mutation updateAutomationRun($where: AutomationRunWhereUniqueInput!, $data: AutomationRunUpdateInput!) {
  updateOneAutomationRun(where: $where, data: $data) {
    ...AutomationRun
  }
}
    ${AutomationRunFragmentDoc}`;
export type UpdateAutomationRunMutationFn = Apollo.MutationFunction<UpdateAutomationRunMutation, UpdateAutomationRunMutationVariables>;

/**
 * __useUpdateAutomationRunMutation__
 *
 * To run a mutation, you first call `useUpdateAutomationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutomationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutomationRunMutation, { data, loading, error }] = useUpdateAutomationRunMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAutomationRunMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutomationRunMutation, UpdateAutomationRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutomationRunMutation, UpdateAutomationRunMutationVariables>(UpdateAutomationRunDocument, options);
      }
export type UpdateAutomationRunMutationHookResult = ReturnType<typeof useUpdateAutomationRunMutation>;
export type UpdateAutomationRunMutationResult = Apollo.MutationResult<UpdateAutomationRunMutation>;
export type UpdateAutomationRunMutationOptions = Apollo.BaseMutationOptions<UpdateAutomationRunMutation, UpdateAutomationRunMutationVariables>;
export const ResetAutomationRunDocument = gql`
    mutation resetAutomationRun($where: AutomationRunWhereUniqueInput!) {
  resetAutomationRun(where: $where) {
    ...AutomationRun
  }
}
    ${AutomationRunFragmentDoc}`;
export type ResetAutomationRunMutationFn = Apollo.MutationFunction<ResetAutomationRunMutation, ResetAutomationRunMutationVariables>;

/**
 * __useResetAutomationRunMutation__
 *
 * To run a mutation, you first call `useResetAutomationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAutomationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAutomationRunMutation, { data, loading, error }] = useResetAutomationRunMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useResetAutomationRunMutation(baseOptions?: Apollo.MutationHookOptions<ResetAutomationRunMutation, ResetAutomationRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetAutomationRunMutation, ResetAutomationRunMutationVariables>(ResetAutomationRunDocument, options);
      }
export type ResetAutomationRunMutationHookResult = ReturnType<typeof useResetAutomationRunMutation>;
export type ResetAutomationRunMutationResult = Apollo.MutationResult<ResetAutomationRunMutation>;
export type ResetAutomationRunMutationOptions = Apollo.BaseMutationOptions<ResetAutomationRunMutation, ResetAutomationRunMutationVariables>;
export const CalculatePetBenefitUsageDocument = gql`
    query calculatePetBenefitUsage($where: CalculatePetBenefitUsageInput) {
  calculatePetBenefitUsage(where: $where) {
    usages {
      provided
      used
      exhausted
      displayOrder
      carryover
      costCovered
      benefit {
        id
        title
        description
        tangible
        exhaustible
        usageUnitOfMeasure
        providedDiscountPercentage
        type
        planBenefits {
          ...CarePlanBenefitUsage
        }
      }
      sources {
        addonIds
        planIds
      }
    }
  }
}
    ${CarePlanBenefitUsageFragmentDoc}`;

/**
 * __useCalculatePetBenefitUsageQuery__
 *
 * To run a query within a React component, call `useCalculatePetBenefitUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculatePetBenefitUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculatePetBenefitUsageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCalculatePetBenefitUsageQuery(baseOptions?: Apollo.QueryHookOptions<CalculatePetBenefitUsageQuery, CalculatePetBenefitUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculatePetBenefitUsageQuery, CalculatePetBenefitUsageQueryVariables>(CalculatePetBenefitUsageDocument, options);
      }
export function useCalculatePetBenefitUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculatePetBenefitUsageQuery, CalculatePetBenefitUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculatePetBenefitUsageQuery, CalculatePetBenefitUsageQueryVariables>(CalculatePetBenefitUsageDocument, options);
        }
export type CalculatePetBenefitUsageQueryHookResult = ReturnType<typeof useCalculatePetBenefitUsageQuery>;
export type CalculatePetBenefitUsageLazyQueryHookResult = ReturnType<typeof useCalculatePetBenefitUsageLazyQuery>;
export type CalculatePetBenefitUsageQueryResult = Apollo.QueryResult<CalculatePetBenefitUsageQuery, CalculatePetBenefitUsageQueryVariables>;
export function refetchCalculatePetBenefitUsageQuery(variables?: CalculatePetBenefitUsageQueryVariables) {
      return { query: CalculatePetBenefitUsageDocument, variables: variables }
    }
export const CreateOneClinicPetDocument = gql`
    mutation createOneClinicPet($data: ClinicPetCreateInput!) {
  createOneClinicPet(data: $data) {
    ...ClinicPetSelection
  }
}
    ${ClinicPetSelectionFragmentDoc}`;
export type CreateOneClinicPetMutationFn = Apollo.MutationFunction<CreateOneClinicPetMutation, CreateOneClinicPetMutationVariables>;

/**
 * __useCreateOneClinicPetMutation__
 *
 * To run a mutation, you first call `useCreateOneClinicPetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClinicPetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClinicPetMutation, { data, loading, error }] = useCreateOneClinicPetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneClinicPetMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClinicPetMutation, CreateOneClinicPetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneClinicPetMutation, CreateOneClinicPetMutationVariables>(CreateOneClinicPetDocument, options);
      }
export type CreateOneClinicPetMutationHookResult = ReturnType<typeof useCreateOneClinicPetMutation>;
export type CreateOneClinicPetMutationResult = Apollo.MutationResult<CreateOneClinicPetMutation>;
export type CreateOneClinicPetMutationOptions = Apollo.BaseMutationOptions<CreateOneClinicPetMutation, CreateOneClinicPetMutationVariables>;
export const CreateOneClinicPetParentDocument = gql`
    mutation createOneClinicPetParent($data: ClinicPetParentCreateInput!) {
  createOneClinicPetParent(data: $data) {
    ...ClinicPetParentSelection
  }
}
    ${ClinicPetParentSelectionFragmentDoc}`;
export type CreateOneClinicPetParentMutationFn = Apollo.MutationFunction<CreateOneClinicPetParentMutation, CreateOneClinicPetParentMutationVariables>;

/**
 * __useCreateOneClinicPetParentMutation__
 *
 * To run a mutation, you first call `useCreateOneClinicPetParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClinicPetParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClinicPetParentMutation, { data, loading, error }] = useCreateOneClinicPetParentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneClinicPetParentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClinicPetParentMutation, CreateOneClinicPetParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneClinicPetParentMutation, CreateOneClinicPetParentMutationVariables>(CreateOneClinicPetParentDocument, options);
      }
export type CreateOneClinicPetParentMutationHookResult = ReturnType<typeof useCreateOneClinicPetParentMutation>;
export type CreateOneClinicPetParentMutationResult = Apollo.MutationResult<CreateOneClinicPetParentMutation>;
export type CreateOneClinicPetParentMutationOptions = Apollo.BaseMutationOptions<CreateOneClinicPetParentMutation, CreateOneClinicPetParentMutationVariables>;
export const CreateCareBenefitUsageToInvoiceLineItemDocument = gql`
    mutation createCareBenefitUsageToInvoiceLineItem($data: CareBenefitUsageToInvoiceLineItemCreateInput!) {
  createOneCareBenefitUsageToInvoiceLineItem(data: $data) {
    id
  }
}
    `;
export type CreateCareBenefitUsageToInvoiceLineItemMutationFn = Apollo.MutationFunction<CreateCareBenefitUsageToInvoiceLineItemMutation, CreateCareBenefitUsageToInvoiceLineItemMutationVariables>;

/**
 * __useCreateCareBenefitUsageToInvoiceLineItemMutation__
 *
 * To run a mutation, you first call `useCreateCareBenefitUsageToInvoiceLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareBenefitUsageToInvoiceLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareBenefitUsageToInvoiceLineItemMutation, { data, loading, error }] = useCreateCareBenefitUsageToInvoiceLineItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCareBenefitUsageToInvoiceLineItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateCareBenefitUsageToInvoiceLineItemMutation, CreateCareBenefitUsageToInvoiceLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCareBenefitUsageToInvoiceLineItemMutation, CreateCareBenefitUsageToInvoiceLineItemMutationVariables>(CreateCareBenefitUsageToInvoiceLineItemDocument, options);
      }
export type CreateCareBenefitUsageToInvoiceLineItemMutationHookResult = ReturnType<typeof useCreateCareBenefitUsageToInvoiceLineItemMutation>;
export type CreateCareBenefitUsageToInvoiceLineItemMutationResult = Apollo.MutationResult<CreateCareBenefitUsageToInvoiceLineItemMutation>;
export type CreateCareBenefitUsageToInvoiceLineItemMutationOptions = Apollo.BaseMutationOptions<CreateCareBenefitUsageToInvoiceLineItemMutation, CreateCareBenefitUsageToInvoiceLineItemMutationVariables>;
export const GetEmailCampaignLoginUrlDocument = gql`
    query getEmailCampaignLoginUrl($data: ConfigureEmailCampaignsInput!) {
  getEmailCampaignLoginUrl(data: $data)
}
    `;

/**
 * __useGetEmailCampaignLoginUrlQuery__
 *
 * To run a query within a React component, call `useGetEmailCampaignLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCampaignLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCampaignLoginUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetEmailCampaignLoginUrlQuery(baseOptions: Apollo.QueryHookOptions<GetEmailCampaignLoginUrlQuery, GetEmailCampaignLoginUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailCampaignLoginUrlQuery, GetEmailCampaignLoginUrlQueryVariables>(GetEmailCampaignLoginUrlDocument, options);
      }
export function useGetEmailCampaignLoginUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailCampaignLoginUrlQuery, GetEmailCampaignLoginUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailCampaignLoginUrlQuery, GetEmailCampaignLoginUrlQueryVariables>(GetEmailCampaignLoginUrlDocument, options);
        }
export type GetEmailCampaignLoginUrlQueryHookResult = ReturnType<typeof useGetEmailCampaignLoginUrlQuery>;
export type GetEmailCampaignLoginUrlLazyQueryHookResult = ReturnType<typeof useGetEmailCampaignLoginUrlLazyQuery>;
export type GetEmailCampaignLoginUrlQueryResult = Apollo.QueryResult<GetEmailCampaignLoginUrlQuery, GetEmailCampaignLoginUrlQueryVariables>;
export function refetchGetEmailCampaignLoginUrlQuery(variables?: GetEmailCampaignLoginUrlQueryVariables) {
      return { query: GetEmailCampaignLoginUrlDocument, variables: variables }
    }
export const ConfigureEmailCampaignsForClinicDocument = gql`
    mutation configureEmailCampaignsForClinic($data: ConfigureEmailCampaignsInput!) {
  configureEmailCampaignsForClinic(data: $data)
}
    `;
export type ConfigureEmailCampaignsForClinicMutationFn = Apollo.MutationFunction<ConfigureEmailCampaignsForClinicMutation, ConfigureEmailCampaignsForClinicMutationVariables>;

/**
 * __useConfigureEmailCampaignsForClinicMutation__
 *
 * To run a mutation, you first call `useConfigureEmailCampaignsForClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureEmailCampaignsForClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureEmailCampaignsForClinicMutation, { data, loading, error }] = useConfigureEmailCampaignsForClinicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConfigureEmailCampaignsForClinicMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureEmailCampaignsForClinicMutation, ConfigureEmailCampaignsForClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureEmailCampaignsForClinicMutation, ConfigureEmailCampaignsForClinicMutationVariables>(ConfigureEmailCampaignsForClinicDocument, options);
      }
export type ConfigureEmailCampaignsForClinicMutationHookResult = ReturnType<typeof useConfigureEmailCampaignsForClinicMutation>;
export type ConfigureEmailCampaignsForClinicMutationResult = Apollo.MutationResult<ConfigureEmailCampaignsForClinicMutation>;
export type ConfigureEmailCampaignsForClinicMutationOptions = Apollo.BaseMutationOptions<ConfigureEmailCampaignsForClinicMutation, ConfigureEmailCampaignsForClinicMutationVariables>;
export const GetClinicChannelPetParentsDocument = gql`
    query getClinicChannelPetParents($where: ChannelWhereUniqueInput!) {
  findUniqueChannel(where: $where) {
    id
    channelType
    friendlyName
    channelMembers {
      id
      removedAt
      clinicPetParent {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetClinicChannelPetParentsQuery__
 *
 * To run a query within a React component, call `useGetClinicChannelPetParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicChannelPetParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicChannelPetParentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicChannelPetParentsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicChannelPetParentsQuery, GetClinicChannelPetParentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicChannelPetParentsQuery, GetClinicChannelPetParentsQueryVariables>(GetClinicChannelPetParentsDocument, options);
      }
export function useGetClinicChannelPetParentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicChannelPetParentsQuery, GetClinicChannelPetParentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicChannelPetParentsQuery, GetClinicChannelPetParentsQueryVariables>(GetClinicChannelPetParentsDocument, options);
        }
export type GetClinicChannelPetParentsQueryHookResult = ReturnType<typeof useGetClinicChannelPetParentsQuery>;
export type GetClinicChannelPetParentsLazyQueryHookResult = ReturnType<typeof useGetClinicChannelPetParentsLazyQuery>;
export type GetClinicChannelPetParentsQueryResult = Apollo.QueryResult<GetClinicChannelPetParentsQuery, GetClinicChannelPetParentsQueryVariables>;
export function refetchGetClinicChannelPetParentsQuery(variables?: GetClinicChannelPetParentsQueryVariables) {
      return { query: GetClinicChannelPetParentsDocument, variables: variables }
    }
export const GetClinicPetEnrollmentsByParentDocument = gql`
    query getClinicPetEnrollmentsByParent($where: ClinicPetParentWhereUniqueInput!, $clinicId: String!) {
  findUniqueClinicPetParent(where: $where) {
    id
    organizationPetParentId
    ...PetParentAvatar
    pets(where: {clinicId: {equals: $clinicId}, isDeleted: {equals: false}}) {
      ...SidePanelParentPetData
    }
  }
}
    ${PetParentAvatarFragmentDoc}
${SidePanelParentPetDataFragmentDoc}`;

/**
 * __useGetClinicPetEnrollmentsByParentQuery__
 *
 * To run a query within a React component, call `useGetClinicPetEnrollmentsByParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPetEnrollmentsByParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPetEnrollmentsByParentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicPetEnrollmentsByParentQuery(baseOptions: Apollo.QueryHookOptions<GetClinicPetEnrollmentsByParentQuery, GetClinicPetEnrollmentsByParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPetEnrollmentsByParentQuery, GetClinicPetEnrollmentsByParentQueryVariables>(GetClinicPetEnrollmentsByParentDocument, options);
      }
export function useGetClinicPetEnrollmentsByParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPetEnrollmentsByParentQuery, GetClinicPetEnrollmentsByParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPetEnrollmentsByParentQuery, GetClinicPetEnrollmentsByParentQueryVariables>(GetClinicPetEnrollmentsByParentDocument, options);
        }
export type GetClinicPetEnrollmentsByParentQueryHookResult = ReturnType<typeof useGetClinicPetEnrollmentsByParentQuery>;
export type GetClinicPetEnrollmentsByParentLazyQueryHookResult = ReturnType<typeof useGetClinicPetEnrollmentsByParentLazyQuery>;
export type GetClinicPetEnrollmentsByParentQueryResult = Apollo.QueryResult<GetClinicPetEnrollmentsByParentQuery, GetClinicPetEnrollmentsByParentQueryVariables>;
export function refetchGetClinicPetEnrollmentsByParentQuery(variables?: GetClinicPetEnrollmentsByParentQueryVariables) {
      return { query: GetClinicPetEnrollmentsByParentDocument, variables: variables }
    }
export const GetClinicTagsDocument = gql`
    query getClinicTags($clinicId: String!) {
  findUniqueClinic(where: {id: $clinicId}) {
    clinicSetting {
      tags
    }
  }
}
    `;

/**
 * __useGetClinicTagsQuery__
 *
 * To run a query within a React component, call `useGetClinicTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicTagsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicTagsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicTagsQuery, GetClinicTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicTagsQuery, GetClinicTagsQueryVariables>(GetClinicTagsDocument, options);
      }
export function useGetClinicTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicTagsQuery, GetClinicTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicTagsQuery, GetClinicTagsQueryVariables>(GetClinicTagsDocument, options);
        }
export type GetClinicTagsQueryHookResult = ReturnType<typeof useGetClinicTagsQuery>;
export type GetClinicTagsLazyQueryHookResult = ReturnType<typeof useGetClinicTagsLazyQuery>;
export type GetClinicTagsQueryResult = Apollo.QueryResult<GetClinicTagsQuery, GetClinicTagsQueryVariables>;
export function refetchGetClinicTagsQuery(variables?: GetClinicTagsQueryVariables) {
      return { query: GetClinicTagsDocument, variables: variables }
    }
export const GetDirectedCareLoginLinkDocument = gql`
    mutation getDirectedCareLoginLink($data: DirectedAuthLinkInput!) {
  getDirectedCareLoginLink(data: $data) {
    link
  }
}
    `;
export type GetDirectedCareLoginLinkMutationFn = Apollo.MutationFunction<GetDirectedCareLoginLinkMutation, GetDirectedCareLoginLinkMutationVariables>;

/**
 * __useGetDirectedCareLoginLinkMutation__
 *
 * To run a mutation, you first call `useGetDirectedCareLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDirectedCareLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDirectedCareLoginLinkMutation, { data, loading, error }] = useGetDirectedCareLoginLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDirectedCareLoginLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetDirectedCareLoginLinkMutation, GetDirectedCareLoginLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDirectedCareLoginLinkMutation, GetDirectedCareLoginLinkMutationVariables>(GetDirectedCareLoginLinkDocument, options);
      }
export type GetDirectedCareLoginLinkMutationHookResult = ReturnType<typeof useGetDirectedCareLoginLinkMutation>;
export type GetDirectedCareLoginLinkMutationResult = Apollo.MutationResult<GetDirectedCareLoginLinkMutation>;
export type GetDirectedCareLoginLinkMutationOptions = Apollo.BaseMutationOptions<GetDirectedCareLoginLinkMutation, GetDirectedCareLoginLinkMutationVariables>;
export const GetExistingChannelMembersDocument = gql`
    query getExistingChannelMembers($clinicPetParentIds: [String!]!, $channelStatusAction: EnumChannelStatusActionFilter) {
  findManyChannelMember(
    where: {clinicPetParent: {id: {in: $clinicPetParentIds}}, channel: {channelStatus: {channelStatusAction: $channelStatusAction}}, removedAt: {equals: null}}
  ) {
    id
    channelId
    channel {
      id
    }
  }
}
    `;

/**
 * __useGetExistingChannelMembersQuery__
 *
 * To run a query within a React component, call `useGetExistingChannelMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingChannelMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingChannelMembersQuery({
 *   variables: {
 *      clinicPetParentIds: // value for 'clinicPetParentIds'
 *      channelStatusAction: // value for 'channelStatusAction'
 *   },
 * });
 */
export function useGetExistingChannelMembersQuery(baseOptions: Apollo.QueryHookOptions<GetExistingChannelMembersQuery, GetExistingChannelMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingChannelMembersQuery, GetExistingChannelMembersQueryVariables>(GetExistingChannelMembersDocument, options);
      }
export function useGetExistingChannelMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingChannelMembersQuery, GetExistingChannelMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingChannelMembersQuery, GetExistingChannelMembersQueryVariables>(GetExistingChannelMembersDocument, options);
        }
export type GetExistingChannelMembersQueryHookResult = ReturnType<typeof useGetExistingChannelMembersQuery>;
export type GetExistingChannelMembersLazyQueryHookResult = ReturnType<typeof useGetExistingChannelMembersLazyQuery>;
export type GetExistingChannelMembersQueryResult = Apollo.QueryResult<GetExistingChannelMembersQuery, GetExistingChannelMembersQueryVariables>;
export function refetchGetExistingChannelMembersQuery(variables?: GetExistingChannelMembersQueryVariables) {
      return { query: GetExistingChannelMembersDocument, variables: variables }
    }
export const GetFormSubmissionDocument = gql`
    query getFormSubmission($formSubmissionId: String!, $clinicId: String!) {
  findUniqueFormSubmission(where: {id: $formSubmissionId}) {
    ...FormSubmission
  }
}
    ${FormSubmissionFragmentDoc}`;

/**
 * __useGetFormSubmissionQuery__
 *
 * To run a query within a React component, call `useGetFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormSubmissionQuery({
 *   variables: {
 *      formSubmissionId: // value for 'formSubmissionId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetFormSubmissionQuery(baseOptions: Apollo.QueryHookOptions<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>(GetFormSubmissionDocument, options);
      }
export function useGetFormSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>(GetFormSubmissionDocument, options);
        }
export type GetFormSubmissionQueryHookResult = ReturnType<typeof useGetFormSubmissionQuery>;
export type GetFormSubmissionLazyQueryHookResult = ReturnType<typeof useGetFormSubmissionLazyQuery>;
export type GetFormSubmissionQueryResult = Apollo.QueryResult<GetFormSubmissionQuery, GetFormSubmissionQueryVariables>;
export function refetchGetFormSubmissionQuery(variables?: GetFormSubmissionQueryVariables) {
      return { query: GetFormSubmissionDocument, variables: variables }
    }
export const GetSignedUploadUrlDocument = gql`
    mutation getSignedUploadUrl($data: GetSignedUploadInput!) {
  getSignedUploadUrl(data: $data) {
    key
    uploadUrl
    finalUrl
  }
}
    `;
export type GetSignedUploadUrlMutationFn = Apollo.MutationFunction<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>;

/**
 * __useGetSignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUploadUrlMutation, { data, loading, error }] = useGetSignedUploadUrlMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetSignedUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>(GetSignedUploadUrlDocument, options);
      }
export type GetSignedUploadUrlMutationHookResult = ReturnType<typeof useGetSignedUploadUrlMutation>;
export type GetSignedUploadUrlMutationResult = Apollo.MutationResult<GetSignedUploadUrlMutation>;
export type GetSignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedUploadUrlMutation, GetSignedUploadUrlMutationVariables>;
export const RemoveChannelTagDocument = gql`
    mutation removeChannelTag($channelId: String!, $tagName: String!) {
  removeChannelTag(channelId: $channelId, tagName: $tagName) {
    id
    tags
    clinicId
  }
}
    `;
export type RemoveChannelTagMutationFn = Apollo.MutationFunction<RemoveChannelTagMutation, RemoveChannelTagMutationVariables>;

/**
 * __useRemoveChannelTagMutation__
 *
 * To run a mutation, you first call `useRemoveChannelTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelTagMutation, { data, loading, error }] = useRemoveChannelTagMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      tagName: // value for 'tagName'
 *   },
 * });
 */
export function useRemoveChannelTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelTagMutation, RemoveChannelTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChannelTagMutation, RemoveChannelTagMutationVariables>(RemoveChannelTagDocument, options);
      }
export type RemoveChannelTagMutationHookResult = ReturnType<typeof useRemoveChannelTagMutation>;
export type RemoveChannelTagMutationResult = Apollo.MutationResult<RemoveChannelTagMutation>;
export type RemoveChannelTagMutationOptions = Apollo.BaseMutationOptions<RemoveChannelTagMutation, RemoveChannelTagMutationVariables>;
export const SaveWorkflowEventSettingsDocument = gql`
    mutation saveWorkflowEventSettings($data: WorkflowEventSettingUpdateInput!, $where: WorkflowEventSettingWhereUniqueInput!) {
  updateOneWorkflowEventSetting(data: $data, where: $where) {
    id
    name
    surveySettingId
    surveyStep
    surveyType
    actions {
      id
      actionType
      order
      config
      channelStatusChangeId
      channelStatusChange {
        id
        name
      }
    }
    notificationBody
    event
  }
}
    `;
export type SaveWorkflowEventSettingsMutationFn = Apollo.MutationFunction<SaveWorkflowEventSettingsMutation, SaveWorkflowEventSettingsMutationVariables>;

/**
 * __useSaveWorkflowEventSettingsMutation__
 *
 * To run a mutation, you first call `useSaveWorkflowEventSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkflowEventSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkflowEventSettingsMutation, { data, loading, error }] = useSaveWorkflowEventSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSaveWorkflowEventSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveWorkflowEventSettingsMutation, SaveWorkflowEventSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWorkflowEventSettingsMutation, SaveWorkflowEventSettingsMutationVariables>(SaveWorkflowEventSettingsDocument, options);
      }
export type SaveWorkflowEventSettingsMutationHookResult = ReturnType<typeof useSaveWorkflowEventSettingsMutation>;
export type SaveWorkflowEventSettingsMutationResult = Apollo.MutationResult<SaveWorkflowEventSettingsMutation>;
export type SaveWorkflowEventSettingsMutationOptions = Apollo.BaseMutationOptions<SaveWorkflowEventSettingsMutation, SaveWorkflowEventSettingsMutationVariables>;
export const UpdateChannelStatusDocument = gql`
    mutation updateChannelStatus($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
  updateOneChannel(where: $where, data: $data) {
    id
    clinicId
    channelStatusId
    channelStatus {
      ...ChannelListChannelStatus
    }
  }
}
    ${ChannelListChannelStatusFragmentDoc}`;
export type UpdateChannelStatusMutationFn = Apollo.MutationFunction<UpdateChannelStatusMutation, UpdateChannelStatusMutationVariables>;

/**
 * __useUpdateChannelStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelStatusMutation, { data, loading, error }] = useUpdateChannelStatusMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChannelStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelStatusMutation, UpdateChannelStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelStatusMutation, UpdateChannelStatusMutationVariables>(UpdateChannelStatusDocument, options);
      }
export type UpdateChannelStatusMutationHookResult = ReturnType<typeof useUpdateChannelStatusMutation>;
export type UpdateChannelStatusMutationResult = Apollo.MutationResult<UpdateChannelStatusMutation>;
export type UpdateChannelStatusMutationOptions = Apollo.BaseMutationOptions<UpdateChannelStatusMutation, UpdateChannelStatusMutationVariables>;
export const CreateOneUserAppointmentGroupingDocument = gql`
    mutation createOneUserAppointmentGrouping($data: UserAppointmentGroupingCreateInput!) {
  createOneUserAppointmentGrouping(data: $data) {
    ...UserAppointmentGroupingFullSelection
  }
}
    ${UserAppointmentGroupingFullSelectionFragmentDoc}`;
export type CreateOneUserAppointmentGroupingMutationFn = Apollo.MutationFunction<CreateOneUserAppointmentGroupingMutation, CreateOneUserAppointmentGroupingMutationVariables>;

/**
 * __useCreateOneUserAppointmentGroupingMutation__
 *
 * To run a mutation, you first call `useCreateOneUserAppointmentGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserAppointmentGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserAppointmentGroupingMutation, { data, loading, error }] = useCreateOneUserAppointmentGroupingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneUserAppointmentGroupingMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserAppointmentGroupingMutation, CreateOneUserAppointmentGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserAppointmentGroupingMutation, CreateOneUserAppointmentGroupingMutationVariables>(CreateOneUserAppointmentGroupingDocument, options);
      }
export type CreateOneUserAppointmentGroupingMutationHookResult = ReturnType<typeof useCreateOneUserAppointmentGroupingMutation>;
export type CreateOneUserAppointmentGroupingMutationResult = Apollo.MutationResult<CreateOneUserAppointmentGroupingMutation>;
export type CreateOneUserAppointmentGroupingMutationOptions = Apollo.BaseMutationOptions<CreateOneUserAppointmentGroupingMutation, CreateOneUserAppointmentGroupingMutationVariables>;
export const UpdateOneUserAppointmentGroupingDocument = gql`
    mutation updateOneUserAppointmentGrouping($data: UserAppointmentGroupingUpdateInput!, $where: UserAppointmentGroupingWhereUniqueInput!) {
  updateOneUserAppointmentGrouping(data: $data, where: $where) {
    ...UserAppointmentGroupingFullSelection
  }
}
    ${UserAppointmentGroupingFullSelectionFragmentDoc}`;
export type UpdateOneUserAppointmentGroupingMutationFn = Apollo.MutationFunction<UpdateOneUserAppointmentGroupingMutation, UpdateOneUserAppointmentGroupingMutationVariables>;

/**
 * __useUpdateOneUserAppointmentGroupingMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserAppointmentGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserAppointmentGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserAppointmentGroupingMutation, { data, loading, error }] = useUpdateOneUserAppointmentGroupingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneUserAppointmentGroupingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserAppointmentGroupingMutation, UpdateOneUserAppointmentGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneUserAppointmentGroupingMutation, UpdateOneUserAppointmentGroupingMutationVariables>(UpdateOneUserAppointmentGroupingDocument, options);
      }
export type UpdateOneUserAppointmentGroupingMutationHookResult = ReturnType<typeof useUpdateOneUserAppointmentGroupingMutation>;
export type UpdateOneUserAppointmentGroupingMutationResult = Apollo.MutationResult<UpdateOneUserAppointmentGroupingMutation>;
export type UpdateOneUserAppointmentGroupingMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserAppointmentGroupingMutation, UpdateOneUserAppointmentGroupingMutationVariables>;
export const DeleteOneUserAppointmentGroupingDocument = gql`
    mutation deleteOneUserAppointmentGrouping($where: UserAppointmentGroupingWhereUniqueInput!) {
  deleteOneUserAppointmentGrouping(where: $where) {
    ...UserAppointmentGroupingFullSelection
  }
}
    ${UserAppointmentGroupingFullSelectionFragmentDoc}`;
export type DeleteOneUserAppointmentGroupingMutationFn = Apollo.MutationFunction<DeleteOneUserAppointmentGroupingMutation, DeleteOneUserAppointmentGroupingMutationVariables>;

/**
 * __useDeleteOneUserAppointmentGroupingMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserAppointmentGroupingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserAppointmentGroupingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserAppointmentGroupingMutation, { data, loading, error }] = useDeleteOneUserAppointmentGroupingMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneUserAppointmentGroupingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneUserAppointmentGroupingMutation, DeleteOneUserAppointmentGroupingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneUserAppointmentGroupingMutation, DeleteOneUserAppointmentGroupingMutationVariables>(DeleteOneUserAppointmentGroupingDocument, options);
      }
export type DeleteOneUserAppointmentGroupingMutationHookResult = ReturnType<typeof useDeleteOneUserAppointmentGroupingMutation>;
export type DeleteOneUserAppointmentGroupingMutationResult = Apollo.MutationResult<DeleteOneUserAppointmentGroupingMutation>;
export type DeleteOneUserAppointmentGroupingMutationOptions = Apollo.BaseMutationOptions<DeleteOneUserAppointmentGroupingMutation, DeleteOneUserAppointmentGroupingMutationVariables>;
export const FindManyUserAppointmentGroupingDocument = gql`
    query findManyUserAppointmentGrouping($where: UserAppointmentGroupingWhereInput, $orderBy: [UserAppointmentGroupingOrderByWithRelationAndSearchRelevanceInput!], $skip: Int, $take: Int) {
  findManyUserAppointmentGrouping(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...UserAppointmentGroupingFullSelection
  }
}
    ${UserAppointmentGroupingFullSelectionFragmentDoc}`;

/**
 * __useFindManyUserAppointmentGroupingQuery__
 *
 * To run a query within a React component, call `useFindManyUserAppointmentGroupingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyUserAppointmentGroupingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyUserAppointmentGroupingQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyUserAppointmentGroupingQuery(baseOptions?: Apollo.QueryHookOptions<FindManyUserAppointmentGroupingQuery, FindManyUserAppointmentGroupingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyUserAppointmentGroupingQuery, FindManyUserAppointmentGroupingQueryVariables>(FindManyUserAppointmentGroupingDocument, options);
      }
export function useFindManyUserAppointmentGroupingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyUserAppointmentGroupingQuery, FindManyUserAppointmentGroupingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyUserAppointmentGroupingQuery, FindManyUserAppointmentGroupingQueryVariables>(FindManyUserAppointmentGroupingDocument, options);
        }
export type FindManyUserAppointmentGroupingQueryHookResult = ReturnType<typeof useFindManyUserAppointmentGroupingQuery>;
export type FindManyUserAppointmentGroupingLazyQueryHookResult = ReturnType<typeof useFindManyUserAppointmentGroupingLazyQuery>;
export type FindManyUserAppointmentGroupingQueryResult = Apollo.QueryResult<FindManyUserAppointmentGroupingQuery, FindManyUserAppointmentGroupingQueryVariables>;
export function refetchFindManyUserAppointmentGroupingQuery(variables?: FindManyUserAppointmentGroupingQueryVariables) {
      return { query: FindManyUserAppointmentGroupingDocument, variables: variables }
    }
export const GetClinicPetParentAvatarPhoneNumbersDocument = gql`
    query getClinicPetParentAvatarPhoneNumbers($clinicPetParentId: String!) {
  findUniqueClinicPetParent(where: {id: $clinicPetParentId}) {
    id
    phoneNumbers {
      id
      updatedAt
      number
      isPrimary
      isDeleted
    }
  }
}
    `;

/**
 * __useGetClinicPetParentAvatarPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useGetClinicPetParentAvatarPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPetParentAvatarPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPetParentAvatarPhoneNumbersQuery({
 *   variables: {
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *   },
 * });
 */
export function useGetClinicPetParentAvatarPhoneNumbersQuery(baseOptions: Apollo.QueryHookOptions<GetClinicPetParentAvatarPhoneNumbersQuery, GetClinicPetParentAvatarPhoneNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPetParentAvatarPhoneNumbersQuery, GetClinicPetParentAvatarPhoneNumbersQueryVariables>(GetClinicPetParentAvatarPhoneNumbersDocument, options);
      }
export function useGetClinicPetParentAvatarPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPetParentAvatarPhoneNumbersQuery, GetClinicPetParentAvatarPhoneNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPetParentAvatarPhoneNumbersQuery, GetClinicPetParentAvatarPhoneNumbersQueryVariables>(GetClinicPetParentAvatarPhoneNumbersDocument, options);
        }
export type GetClinicPetParentAvatarPhoneNumbersQueryHookResult = ReturnType<typeof useGetClinicPetParentAvatarPhoneNumbersQuery>;
export type GetClinicPetParentAvatarPhoneNumbersLazyQueryHookResult = ReturnType<typeof useGetClinicPetParentAvatarPhoneNumbersLazyQuery>;
export type GetClinicPetParentAvatarPhoneNumbersQueryResult = Apollo.QueryResult<GetClinicPetParentAvatarPhoneNumbersQuery, GetClinicPetParentAvatarPhoneNumbersQueryVariables>;
export function refetchGetClinicPetParentAvatarPhoneNumbersQuery(variables?: GetClinicPetParentAvatarPhoneNumbersQueryVariables) {
      return { query: GetClinicPetParentAvatarPhoneNumbersDocument, variables: variables }
    }
export const GetConversationStatusOptionsDocument = gql`
    query getConversationStatusOptions($clinicId: String!) {
  findManyChannelStatus(
    where: {clinicSetting: {clinic: {id: {equals: $clinicId}}}, deletedOn: {equals: null}}
    orderBy: {order: asc}
  ) {
    id
    color
    name
    channelStatusAction
  }
}
    `;

/**
 * __useGetConversationStatusOptionsQuery__
 *
 * To run a query within a React component, call `useGetConversationStatusOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationStatusOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationStatusOptionsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetConversationStatusOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetConversationStatusOptionsQuery, GetConversationStatusOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationStatusOptionsQuery, GetConversationStatusOptionsQueryVariables>(GetConversationStatusOptionsDocument, options);
      }
export function useGetConversationStatusOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationStatusOptionsQuery, GetConversationStatusOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationStatusOptionsQuery, GetConversationStatusOptionsQueryVariables>(GetConversationStatusOptionsDocument, options);
        }
export type GetConversationStatusOptionsQueryHookResult = ReturnType<typeof useGetConversationStatusOptionsQuery>;
export type GetConversationStatusOptionsLazyQueryHookResult = ReturnType<typeof useGetConversationStatusOptionsLazyQuery>;
export type GetConversationStatusOptionsQueryResult = Apollo.QueryResult<GetConversationStatusOptionsQuery, GetConversationStatusOptionsQueryVariables>;
export function refetchGetConversationStatusOptionsQuery(variables?: GetConversationStatusOptionsQueryVariables) {
      return { query: GetConversationStatusOptionsDocument, variables: variables }
    }
export const UpdateCreatePasswordModalUserDocument = gql`
    mutation updateCreatePasswordModalUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateOneUser(where: $where, data: $data) {
    id
    firstName
    lastName
    namePrefix
    nameSuffix
    email
    phone
    isNewPasswordRequired
  }
}
    `;
export type UpdateCreatePasswordModalUserMutationFn = Apollo.MutationFunction<UpdateCreatePasswordModalUserMutation, UpdateCreatePasswordModalUserMutationVariables>;

/**
 * __useUpdateCreatePasswordModalUserMutation__
 *
 * To run a mutation, you first call `useUpdateCreatePasswordModalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreatePasswordModalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreatePasswordModalUserMutation, { data, loading, error }] = useUpdateCreatePasswordModalUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCreatePasswordModalUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreatePasswordModalUserMutation, UpdateCreatePasswordModalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreatePasswordModalUserMutation, UpdateCreatePasswordModalUserMutationVariables>(UpdateCreatePasswordModalUserDocument, options);
      }
export type UpdateCreatePasswordModalUserMutationHookResult = ReturnType<typeof useUpdateCreatePasswordModalUserMutation>;
export type UpdateCreatePasswordModalUserMutationResult = Apollo.MutationResult<UpdateCreatePasswordModalUserMutation>;
export type UpdateCreatePasswordModalUserMutationOptions = Apollo.BaseMutationOptions<UpdateCreatePasswordModalUserMutation, UpdateCreatePasswordModalUserMutationVariables>;
export const CreateEmailTemplateVersionDocument = gql`
    mutation createEmailTemplateVersion($data: CreateEmailTemplateVersionInput!) {
  createEmailTemplateVersion(data: $data) {
    ...EmailTemplateVersion
  }
}
    ${EmailTemplateVersionFragmentDoc}`;
export type CreateEmailTemplateVersionMutationFn = Apollo.MutationFunction<CreateEmailTemplateVersionMutation, CreateEmailTemplateVersionMutationVariables>;

/**
 * __useCreateEmailTemplateVersionMutation__
 *
 * To run a mutation, you first call `useCreateEmailTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailTemplateVersionMutation, { data, loading, error }] = useCreateEmailTemplateVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEmailTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailTemplateVersionMutation, CreateEmailTemplateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailTemplateVersionMutation, CreateEmailTemplateVersionMutationVariables>(CreateEmailTemplateVersionDocument, options);
      }
export type CreateEmailTemplateVersionMutationHookResult = ReturnType<typeof useCreateEmailTemplateVersionMutation>;
export type CreateEmailTemplateVersionMutationResult = Apollo.MutationResult<CreateEmailTemplateVersionMutation>;
export type CreateEmailTemplateVersionMutationOptions = Apollo.BaseMutationOptions<CreateEmailTemplateVersionMutation, CreateEmailTemplateVersionMutationVariables>;
export const SaveEmailTemplateVersionDocument = gql`
    mutation saveEmailTemplateVersion($data: SaveEmailTemplateVersionInput!) {
  saveEmailTemplateVersion(data: $data) {
    ...EmailTemplateVersion
  }
}
    ${EmailTemplateVersionFragmentDoc}`;
export type SaveEmailTemplateVersionMutationFn = Apollo.MutationFunction<SaveEmailTemplateVersionMutation, SaveEmailTemplateVersionMutationVariables>;

/**
 * __useSaveEmailTemplateVersionMutation__
 *
 * To run a mutation, you first call `useSaveEmailTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmailTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmailTemplateVersionMutation, { data, loading, error }] = useSaveEmailTemplateVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveEmailTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmailTemplateVersionMutation, SaveEmailTemplateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmailTemplateVersionMutation, SaveEmailTemplateVersionMutationVariables>(SaveEmailTemplateVersionDocument, options);
      }
export type SaveEmailTemplateVersionMutationHookResult = ReturnType<typeof useSaveEmailTemplateVersionMutation>;
export type SaveEmailTemplateVersionMutationResult = Apollo.MutationResult<SaveEmailTemplateVersionMutation>;
export type SaveEmailTemplateVersionMutationOptions = Apollo.BaseMutationOptions<SaveEmailTemplateVersionMutation, SaveEmailTemplateVersionMutationVariables>;
export const UpdateOneEmailTemplateVersionDocument = gql`
    mutation updateOneEmailTemplateVersion($data: EmailTemplateVersionUpdateInput!, $where: EmailTemplateVersionWhereUniqueInput!) {
  updateOneEmailTemplateVersion(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneEmailTemplateVersionMutationFn = Apollo.MutationFunction<UpdateOneEmailTemplateVersionMutation, UpdateOneEmailTemplateVersionMutationVariables>;

/**
 * __useUpdateOneEmailTemplateVersionMutation__
 *
 * To run a mutation, you first call `useUpdateOneEmailTemplateVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEmailTemplateVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEmailTemplateVersionMutation, { data, loading, error }] = useUpdateOneEmailTemplateVersionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneEmailTemplateVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneEmailTemplateVersionMutation, UpdateOneEmailTemplateVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneEmailTemplateVersionMutation, UpdateOneEmailTemplateVersionMutationVariables>(UpdateOneEmailTemplateVersionDocument, options);
      }
export type UpdateOneEmailTemplateVersionMutationHookResult = ReturnType<typeof useUpdateOneEmailTemplateVersionMutation>;
export type UpdateOneEmailTemplateVersionMutationResult = Apollo.MutationResult<UpdateOneEmailTemplateVersionMutation>;
export type UpdateOneEmailTemplateVersionMutationOptions = Apollo.BaseMutationOptions<UpdateOneEmailTemplateVersionMutation, UpdateOneEmailTemplateVersionMutationVariables>;
export const FindCurrentEmailTemplateVersionsDocument = gql`
    query findCurrentEmailTemplateVersions($data: FindCurrentEmailTemplateVersionInput!) {
  findCurrentEmailTemplateVersions(data: $data) {
    unlayer {
      userId
      signature
    }
    draft {
      ...EmailTemplateVersion
    }
    published {
      ...EmailTemplateVersion
    }
  }
}
    ${EmailTemplateVersionFragmentDoc}`;

/**
 * __useFindCurrentEmailTemplateVersionsQuery__
 *
 * To run a query within a React component, call `useFindCurrentEmailTemplateVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentEmailTemplateVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentEmailTemplateVersionsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFindCurrentEmailTemplateVersionsQuery(baseOptions: Apollo.QueryHookOptions<FindCurrentEmailTemplateVersionsQuery, FindCurrentEmailTemplateVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCurrentEmailTemplateVersionsQuery, FindCurrentEmailTemplateVersionsQueryVariables>(FindCurrentEmailTemplateVersionsDocument, options);
      }
export function useFindCurrentEmailTemplateVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCurrentEmailTemplateVersionsQuery, FindCurrentEmailTemplateVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCurrentEmailTemplateVersionsQuery, FindCurrentEmailTemplateVersionsQueryVariables>(FindCurrentEmailTemplateVersionsDocument, options);
        }
export type FindCurrentEmailTemplateVersionsQueryHookResult = ReturnType<typeof useFindCurrentEmailTemplateVersionsQuery>;
export type FindCurrentEmailTemplateVersionsLazyQueryHookResult = ReturnType<typeof useFindCurrentEmailTemplateVersionsLazyQuery>;
export type FindCurrentEmailTemplateVersionsQueryResult = Apollo.QueryResult<FindCurrentEmailTemplateVersionsQuery, FindCurrentEmailTemplateVersionsQueryVariables>;
export function refetchFindCurrentEmailTemplateVersionsQuery(variables?: FindCurrentEmailTemplateVersionsQueryVariables) {
      return { query: FindCurrentEmailTemplateVersionsDocument, variables: variables }
    }
export const MergePetParentProfilesDocument = gql`
    mutation mergePetParentProfiles($data: MergePetParentInput) {
  mergePetParent(data: $data) {
    success
    messages
  }
}
    `;
export type MergePetParentProfilesMutationFn = Apollo.MutationFunction<MergePetParentProfilesMutation, MergePetParentProfilesMutationVariables>;

/**
 * __useMergePetParentProfilesMutation__
 *
 * To run a mutation, you first call `useMergePetParentProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePetParentProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePetParentProfilesMutation, { data, loading, error }] = useMergePetParentProfilesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMergePetParentProfilesMutation(baseOptions?: Apollo.MutationHookOptions<MergePetParentProfilesMutation, MergePetParentProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergePetParentProfilesMutation, MergePetParentProfilesMutationVariables>(MergePetParentProfilesDocument, options);
      }
export type MergePetParentProfilesMutationHookResult = ReturnType<typeof useMergePetParentProfilesMutation>;
export type MergePetParentProfilesMutationResult = Apollo.MutationResult<MergePetParentProfilesMutation>;
export type MergePetParentProfilesMutationOptions = Apollo.BaseMutationOptions<MergePetParentProfilesMutation, MergePetParentProfilesMutationVariables>;
export const MergeProfileClientSearchDocument = gql`
    query mergeProfileClientSearch($where: SearchClinicPetParentInput, $clinicId: String!, $take: Int) {
  clinicPetParentSearch(where: $where, take: $take) {
    id
    isTest
    pimsId
    firstName
    lastName
    pets(
      where: {clinicId: {equals: $clinicId}, isActive: {equals: true}, isDeceased: {equals: false}, isDeleted: {equals: false}}
    ) {
      id
      pimsId
      name
      organizationPet {
        id
        carePlanEnrollments(
          where: {status: {notIn: [Pending, Cancelling, Expired, PaymentFailed]}}
        ) {
          id
        }
      }
    }
    loyaltyAccountHolder {
      id
      account {
        pointBalance
        rewardRedemptions {
          id
        }
      }
    }
    channelMembers(where: {removedAt: {equals: null}}) {
      id
      channel {
        channelStatus {
          channelStatusAction
        }
      }
    }
  }
}
    `;

/**
 * __useMergeProfileClientSearchQuery__
 *
 * To run a query within a React component, call `useMergeProfileClientSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergeProfileClientSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergeProfileClientSearchQuery({
 *   variables: {
 *      where: // value for 'where'
 *      clinicId: // value for 'clinicId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useMergeProfileClientSearchQuery(baseOptions: Apollo.QueryHookOptions<MergeProfileClientSearchQuery, MergeProfileClientSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MergeProfileClientSearchQuery, MergeProfileClientSearchQueryVariables>(MergeProfileClientSearchDocument, options);
      }
export function useMergeProfileClientSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MergeProfileClientSearchQuery, MergeProfileClientSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MergeProfileClientSearchQuery, MergeProfileClientSearchQueryVariables>(MergeProfileClientSearchDocument, options);
        }
export type MergeProfileClientSearchQueryHookResult = ReturnType<typeof useMergeProfileClientSearchQuery>;
export type MergeProfileClientSearchLazyQueryHookResult = ReturnType<typeof useMergeProfileClientSearchLazyQuery>;
export type MergeProfileClientSearchQueryResult = Apollo.QueryResult<MergeProfileClientSearchQuery, MergeProfileClientSearchQueryVariables>;
export function refetchMergeProfileClientSearchQuery(variables?: MergeProfileClientSearchQueryVariables) {
      return { query: MergeProfileClientSearchDocument, variables: variables }
    }
export const GetNonItemizedReceiptPdfDocument = gql`
    query getNonItemizedReceiptPdf($intentId: String!) {
  exportNonItemizedReceiptAsPdf(data: {stripePaymentIntentId: $intentId}) {
    success
    base64PDF
  }
}
    `;

/**
 * __useGetNonItemizedReceiptPdfQuery__
 *
 * To run a query within a React component, call `useGetNonItemizedReceiptPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonItemizedReceiptPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonItemizedReceiptPdfQuery({
 *   variables: {
 *      intentId: // value for 'intentId'
 *   },
 * });
 */
export function useGetNonItemizedReceiptPdfQuery(baseOptions: Apollo.QueryHookOptions<GetNonItemizedReceiptPdfQuery, GetNonItemizedReceiptPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonItemizedReceiptPdfQuery, GetNonItemizedReceiptPdfQueryVariables>(GetNonItemizedReceiptPdfDocument, options);
      }
export function useGetNonItemizedReceiptPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonItemizedReceiptPdfQuery, GetNonItemizedReceiptPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonItemizedReceiptPdfQuery, GetNonItemizedReceiptPdfQueryVariables>(GetNonItemizedReceiptPdfDocument, options);
        }
export type GetNonItemizedReceiptPdfQueryHookResult = ReturnType<typeof useGetNonItemizedReceiptPdfQuery>;
export type GetNonItemizedReceiptPdfLazyQueryHookResult = ReturnType<typeof useGetNonItemizedReceiptPdfLazyQuery>;
export type GetNonItemizedReceiptPdfQueryResult = Apollo.QueryResult<GetNonItemizedReceiptPdfQuery, GetNonItemizedReceiptPdfQueryVariables>;
export function refetchGetNonItemizedReceiptPdfQuery(variables?: GetNonItemizedReceiptPdfQueryVariables) {
      return { query: GetNonItemizedReceiptPdfDocument, variables: variables }
    }
export const GetReceiptPetParentDocument = gql`
    query getReceiptPetParent($clinicPetParentId: String!) {
  findUniqueClinicPetParent(where: {id: $clinicPetParentId}) {
    id
    firstName
    lastName
    phoneNumbers(where: {isPrimary: {equals: true}}) {
      id
      number
    }
    email
  }
}
    `;

/**
 * __useGetReceiptPetParentQuery__
 *
 * To run a query within a React component, call `useGetReceiptPetParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptPetParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptPetParentQuery({
 *   variables: {
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *   },
 * });
 */
export function useGetReceiptPetParentQuery(baseOptions: Apollo.QueryHookOptions<GetReceiptPetParentQuery, GetReceiptPetParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceiptPetParentQuery, GetReceiptPetParentQueryVariables>(GetReceiptPetParentDocument, options);
      }
export function useGetReceiptPetParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceiptPetParentQuery, GetReceiptPetParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceiptPetParentQuery, GetReceiptPetParentQueryVariables>(GetReceiptPetParentDocument, options);
        }
export type GetReceiptPetParentQueryHookResult = ReturnType<typeof useGetReceiptPetParentQuery>;
export type GetReceiptPetParentLazyQueryHookResult = ReturnType<typeof useGetReceiptPetParentLazyQuery>;
export type GetReceiptPetParentQueryResult = Apollo.QueryResult<GetReceiptPetParentQuery, GetReceiptPetParentQueryVariables>;
export function refetchGetReceiptPetParentQuery(variables?: GetReceiptPetParentQueryVariables) {
      return { query: GetReceiptPetParentDocument, variables: variables }
    }
export const GetStripeReceiptDocument = gql`
    query getStripeReceipt($id: String!) {
  findUniqueStripeReceipt(where: {id: $id}) {
    id
    stripeId
    applicationName
    dedicatedFileName
    accountType
  }
}
    `;

/**
 * __useGetStripeReceiptQuery__
 *
 * To run a query within a React component, call `useGetStripeReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStripeReceiptQuery(baseOptions: Apollo.QueryHookOptions<GetStripeReceiptQuery, GetStripeReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeReceiptQuery, GetStripeReceiptQueryVariables>(GetStripeReceiptDocument, options);
      }
export function useGetStripeReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeReceiptQuery, GetStripeReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeReceiptQuery, GetStripeReceiptQueryVariables>(GetStripeReceiptDocument, options);
        }
export type GetStripeReceiptQueryHookResult = ReturnType<typeof useGetStripeReceiptQuery>;
export type GetStripeReceiptLazyQueryHookResult = ReturnType<typeof useGetStripeReceiptLazyQuery>;
export type GetStripeReceiptQueryResult = Apollo.QueryResult<GetStripeReceiptQuery, GetStripeReceiptQueryVariables>;
export function refetchGetStripeReceiptQuery(variables?: GetStripeReceiptQueryVariables) {
      return { query: GetStripeReceiptDocument, variables: variables }
    }
export const SyncPimsIntegrationModelDocument = gql`
    mutation syncPimsIntegrationModel($data: SyncManyPimsIntegrationModelInput!) {
  syncManyPimsIntegrationModel(data: $data) {
    lastSyncedAt
  }
}
    `;
export type SyncPimsIntegrationModelMutationFn = Apollo.MutationFunction<SyncPimsIntegrationModelMutation, SyncPimsIntegrationModelMutationVariables>;

/**
 * __useSyncPimsIntegrationModelMutation__
 *
 * To run a mutation, you first call `useSyncPimsIntegrationModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPimsIntegrationModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPimsIntegrationModelMutation, { data, loading, error }] = useSyncPimsIntegrationModelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSyncPimsIntegrationModelMutation(baseOptions?: Apollo.MutationHookOptions<SyncPimsIntegrationModelMutation, SyncPimsIntegrationModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncPimsIntegrationModelMutation, SyncPimsIntegrationModelMutationVariables>(SyncPimsIntegrationModelDocument, options);
      }
export type SyncPimsIntegrationModelMutationHookResult = ReturnType<typeof useSyncPimsIntegrationModelMutation>;
export type SyncPimsIntegrationModelMutationResult = Apollo.MutationResult<SyncPimsIntegrationModelMutation>;
export type SyncPimsIntegrationModelMutationOptions = Apollo.BaseMutationOptions<SyncPimsIntegrationModelMutation, SyncPimsIntegrationModelMutationVariables>;
export const GetClinicPimsIntegrationCapabilitySyncDocument = gql`
    query getClinicPimsIntegrationCapabilitySync($where: ClinicPimsIntegrationCapabilityWhereInput) {
  findManyClinicPimsIntegrationCapability(where: $where) {
    lastSyncedAt
  }
}
    `;

/**
 * __useGetClinicPimsIntegrationCapabilitySyncQuery__
 *
 * To run a query within a React component, call `useGetClinicPimsIntegrationCapabilitySyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPimsIntegrationCapabilitySyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPimsIntegrationCapabilitySyncQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicPimsIntegrationCapabilitySyncQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicPimsIntegrationCapabilitySyncQuery, GetClinicPimsIntegrationCapabilitySyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPimsIntegrationCapabilitySyncQuery, GetClinicPimsIntegrationCapabilitySyncQueryVariables>(GetClinicPimsIntegrationCapabilitySyncDocument, options);
      }
export function useGetClinicPimsIntegrationCapabilitySyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPimsIntegrationCapabilitySyncQuery, GetClinicPimsIntegrationCapabilitySyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPimsIntegrationCapabilitySyncQuery, GetClinicPimsIntegrationCapabilitySyncQueryVariables>(GetClinicPimsIntegrationCapabilitySyncDocument, options);
        }
export type GetClinicPimsIntegrationCapabilitySyncQueryHookResult = ReturnType<typeof useGetClinicPimsIntegrationCapabilitySyncQuery>;
export type GetClinicPimsIntegrationCapabilitySyncLazyQueryHookResult = ReturnType<typeof useGetClinicPimsIntegrationCapabilitySyncLazyQuery>;
export type GetClinicPimsIntegrationCapabilitySyncQueryResult = Apollo.QueryResult<GetClinicPimsIntegrationCapabilitySyncQuery, GetClinicPimsIntegrationCapabilitySyncQueryVariables>;
export function refetchGetClinicPimsIntegrationCapabilitySyncQuery(variables?: GetClinicPimsIntegrationCapabilitySyncQueryVariables) {
      return { query: GetClinicPimsIntegrationCapabilitySyncDocument, variables: variables }
    }
export const GetEnrollmentsCountDocument = gql`
    query getEnrollmentsCount($where: CarePlanEnrollmentWhereInput) {
  findManyCarePlanEnrollmentCount(where: $where)
}
    `;

/**
 * __useGetEnrollmentsCountQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentsCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEnrollmentsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetEnrollmentsCountQuery, GetEnrollmentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnrollmentsCountQuery, GetEnrollmentsCountQueryVariables>(GetEnrollmentsCountDocument, options);
      }
export function useGetEnrollmentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnrollmentsCountQuery, GetEnrollmentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnrollmentsCountQuery, GetEnrollmentsCountQueryVariables>(GetEnrollmentsCountDocument, options);
        }
export type GetEnrollmentsCountQueryHookResult = ReturnType<typeof useGetEnrollmentsCountQuery>;
export type GetEnrollmentsCountLazyQueryHookResult = ReturnType<typeof useGetEnrollmentsCountLazyQuery>;
export type GetEnrollmentsCountQueryResult = Apollo.QueryResult<GetEnrollmentsCountQuery, GetEnrollmentsCountQueryVariables>;
export function refetchGetEnrollmentsCountQuery(variables?: GetEnrollmentsCountQueryVariables) {
      return { query: GetEnrollmentsCountDocument, variables: variables }
    }
export const SubscribeToNewCareEnrollmentsDocument = gql`
    subscription subscribeToNewCareEnrollments($where: CareEnrollmentsByClinicWhereInput!) {
  checkPetEnrollmentsByClinic(where: $where) {
    dailyTotal
    monthlyTotal
    enrollmentData {
      clientName
      petName
      planName
      isRenewal
    }
  }
}
    `;

/**
 * __useSubscribeToNewCareEnrollmentsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToNewCareEnrollmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewCareEnrollmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToNewCareEnrollmentsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToNewCareEnrollmentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToNewCareEnrollmentsSubscription, SubscribeToNewCareEnrollmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToNewCareEnrollmentsSubscription, SubscribeToNewCareEnrollmentsSubscriptionVariables>(SubscribeToNewCareEnrollmentsDocument, options);
      }
export type SubscribeToNewCareEnrollmentsSubscriptionHookResult = ReturnType<typeof useSubscribeToNewCareEnrollmentsSubscription>;
export type SubscribeToNewCareEnrollmentsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToNewCareEnrollmentsSubscription>;
export const FindUniqueClinicPetParentDocument = gql`
    query findUniqueClinicPetParent($where: ClinicPetParentWhereUniqueInput!) {
  findUniqueClinicPetParent(where: $where) {
    firstName
  }
}
    `;

/**
 * __useFindUniqueClinicPetParentQuery__
 *
 * To run a query within a React component, call `useFindUniqueClinicPetParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueClinicPetParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueClinicPetParentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueClinicPetParentQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueClinicPetParentQuery, FindUniqueClinicPetParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueClinicPetParentQuery, FindUniqueClinicPetParentQueryVariables>(FindUniqueClinicPetParentDocument, options);
      }
export function useFindUniqueClinicPetParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueClinicPetParentQuery, FindUniqueClinicPetParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueClinicPetParentQuery, FindUniqueClinicPetParentQueryVariables>(FindUniqueClinicPetParentDocument, options);
        }
export type FindUniqueClinicPetParentQueryHookResult = ReturnType<typeof useFindUniqueClinicPetParentQuery>;
export type FindUniqueClinicPetParentLazyQueryHookResult = ReturnType<typeof useFindUniqueClinicPetParentLazyQuery>;
export type FindUniqueClinicPetParentQueryResult = Apollo.QueryResult<FindUniqueClinicPetParentQuery, FindUniqueClinicPetParentQueryVariables>;
export function refetchFindUniqueClinicPetParentQuery(variables?: FindUniqueClinicPetParentQueryVariables) {
      return { query: FindUniqueClinicPetParentDocument, variables: variables }
    }
export const PostCareInvoiceAccountCreditToPimsDocument = gql`
    mutation postCareInvoiceAccountCreditToPims($data: WritebackCareInvoiceAccountCreditToPimsInput!) {
  postCareInvoiceAccountCreditToPims(data: $data) {
    success
    error
  }
}
    `;
export type PostCareInvoiceAccountCreditToPimsMutationFn = Apollo.MutationFunction<PostCareInvoiceAccountCreditToPimsMutation, PostCareInvoiceAccountCreditToPimsMutationVariables>;

/**
 * __usePostCareInvoiceAccountCreditToPimsMutation__
 *
 * To run a mutation, you first call `usePostCareInvoiceAccountCreditToPimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCareInvoiceAccountCreditToPimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCareInvoiceAccountCreditToPimsMutation, { data, loading, error }] = usePostCareInvoiceAccountCreditToPimsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePostCareInvoiceAccountCreditToPimsMutation(baseOptions?: Apollo.MutationHookOptions<PostCareInvoiceAccountCreditToPimsMutation, PostCareInvoiceAccountCreditToPimsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCareInvoiceAccountCreditToPimsMutation, PostCareInvoiceAccountCreditToPimsMutationVariables>(PostCareInvoiceAccountCreditToPimsDocument, options);
      }
export type PostCareInvoiceAccountCreditToPimsMutationHookResult = ReturnType<typeof usePostCareInvoiceAccountCreditToPimsMutation>;
export type PostCareInvoiceAccountCreditToPimsMutationResult = Apollo.MutationResult<PostCareInvoiceAccountCreditToPimsMutation>;
export type PostCareInvoiceAccountCreditToPimsMutationOptions = Apollo.BaseMutationOptions<PostCareInvoiceAccountCreditToPimsMutation, PostCareInvoiceAccountCreditToPimsMutationVariables>;
export const IssueTrupanionCertificateDocument = gql`
    mutation issueTrupanionCertificate($where: TrupanionIssueCertificateWhereInput!, $data: TrupanionIssueCertificateDataInput!) {
  trupanionIssueCertificate(where: $where, data: $data) {
    referenceNumber
    errorMessages
  }
}
    `;
export type IssueTrupanionCertificateMutationFn = Apollo.MutationFunction<IssueTrupanionCertificateMutation, IssueTrupanionCertificateMutationVariables>;

/**
 * __useIssueTrupanionCertificateMutation__
 *
 * To run a mutation, you first call `useIssueTrupanionCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTrupanionCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTrupanionCertificateMutation, { data, loading, error }] = useIssueTrupanionCertificateMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIssueTrupanionCertificateMutation(baseOptions?: Apollo.MutationHookOptions<IssueTrupanionCertificateMutation, IssueTrupanionCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueTrupanionCertificateMutation, IssueTrupanionCertificateMutationVariables>(IssueTrupanionCertificateDocument, options);
      }
export type IssueTrupanionCertificateMutationHookResult = ReturnType<typeof useIssueTrupanionCertificateMutation>;
export type IssueTrupanionCertificateMutationResult = Apollo.MutationResult<IssueTrupanionCertificateMutation>;
export type IssueTrupanionCertificateMutationOptions = Apollo.BaseMutationOptions<IssueTrupanionCertificateMutation, IssueTrupanionCertificateMutationVariables>;
export const GetInvoicePetParentsDocument = gql`
    query getInvoicePetParents($whereInput: SearchClinicPetParentInput, $pageSize: Int, $petWhere: ClinicPetWhereInput) {
  clinicPetParentSearch(where: $whereInput, take: $pageSize) {
    ...InvoicePetParent
  }
}
    ${InvoicePetParentFragmentDoc}`;

/**
 * __useGetInvoicePetParentsQuery__
 *
 * To run a query within a React component, call `useGetInvoicePetParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePetParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePetParentsQuery({
 *   variables: {
 *      whereInput: // value for 'whereInput'
 *      pageSize: // value for 'pageSize'
 *      petWhere: // value for 'petWhere'
 *   },
 * });
 */
export function useGetInvoicePetParentsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicePetParentsQuery, GetInvoicePetParentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicePetParentsQuery, GetInvoicePetParentsQueryVariables>(GetInvoicePetParentsDocument, options);
      }
export function useGetInvoicePetParentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicePetParentsQuery, GetInvoicePetParentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicePetParentsQuery, GetInvoicePetParentsQueryVariables>(GetInvoicePetParentsDocument, options);
        }
export type GetInvoicePetParentsQueryHookResult = ReturnType<typeof useGetInvoicePetParentsQuery>;
export type GetInvoicePetParentsLazyQueryHookResult = ReturnType<typeof useGetInvoicePetParentsLazyQuery>;
export type GetInvoicePetParentsQueryResult = Apollo.QueryResult<GetInvoicePetParentsQuery, GetInvoicePetParentsQueryVariables>;
export function refetchGetInvoicePetParentsQuery(variables?: GetInvoicePetParentsQueryVariables) {
      return { query: GetInvoicePetParentsDocument, variables: variables }
    }
export const GetPaymentIntentStatusDocument = gql`
    query getPaymentIntentStatus($where: StripePaymentIntentWhereUniqueInput!) {
  findUniqueStripePaymentIntent(where: $where) {
    id
    status
    lastPaymentError
    paymentRelationship
  }
}
    `;

/**
 * __useGetPaymentIntentStatusQuery__
 *
 * To run a query within a React component, call `useGetPaymentIntentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentIntentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentIntentStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentIntentStatusQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentIntentStatusQuery, GetPaymentIntentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentIntentStatusQuery, GetPaymentIntentStatusQueryVariables>(GetPaymentIntentStatusDocument, options);
      }
export function useGetPaymentIntentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentIntentStatusQuery, GetPaymentIntentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentIntentStatusQuery, GetPaymentIntentStatusQueryVariables>(GetPaymentIntentStatusDocument, options);
        }
export type GetPaymentIntentStatusQueryHookResult = ReturnType<typeof useGetPaymentIntentStatusQuery>;
export type GetPaymentIntentStatusLazyQueryHookResult = ReturnType<typeof useGetPaymentIntentStatusLazyQuery>;
export type GetPaymentIntentStatusQueryResult = Apollo.QueryResult<GetPaymentIntentStatusQuery, GetPaymentIntentStatusQueryVariables>;
export function refetchGetPaymentIntentStatusQuery(variables?: GetPaymentIntentStatusQueryVariables) {
      return { query: GetPaymentIntentStatusDocument, variables: variables }
    }
export const TrupanionCertificateAvailabilityDocument = gql`
    query trupanionCertificateAvailability($where: TrupanionCertificateAvailabilityInput!) {
  trupanionCertificateAvailability(where: $where) {
    clinicPetId
    clinicPetName
    isAvailable
    trupanionClosestBreed
    pimsBreed
    speciesType
    unavailableReasons {
      IsPetAlreadyEnrolled
      IsPetPolicyCancelled
      IsActiveOrPendingCertificateFound
      IsExamDateOutsideOfActivationWindow
      IsAdoptionDateOutsideOfActivationWindow
      IsEmailAssociatedToManyPolicies
    }
    errorMessages
  }
}
    `;

/**
 * __useTrupanionCertificateAvailabilityQuery__
 *
 * To run a query within a React component, call `useTrupanionCertificateAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrupanionCertificateAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrupanionCertificateAvailabilityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTrupanionCertificateAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<TrupanionCertificateAvailabilityQuery, TrupanionCertificateAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrupanionCertificateAvailabilityQuery, TrupanionCertificateAvailabilityQueryVariables>(TrupanionCertificateAvailabilityDocument, options);
      }
export function useTrupanionCertificateAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrupanionCertificateAvailabilityQuery, TrupanionCertificateAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrupanionCertificateAvailabilityQuery, TrupanionCertificateAvailabilityQueryVariables>(TrupanionCertificateAvailabilityDocument, options);
        }
export type TrupanionCertificateAvailabilityQueryHookResult = ReturnType<typeof useTrupanionCertificateAvailabilityQuery>;
export type TrupanionCertificateAvailabilityLazyQueryHookResult = ReturnType<typeof useTrupanionCertificateAvailabilityLazyQuery>;
export type TrupanionCertificateAvailabilityQueryResult = Apollo.QueryResult<TrupanionCertificateAvailabilityQuery, TrupanionCertificateAvailabilityQueryVariables>;
export function refetchTrupanionCertificateAvailabilityQuery(variables?: TrupanionCertificateAvailabilityQueryVariables) {
      return { query: TrupanionCertificateAvailabilityDocument, variables: variables }
    }
export const CreateMassTextAlertDocument = gql`
    mutation createMassTextAlert($data: MassTextAlertCreateInput!) {
  createOneMassTextAlert(data: $data) {
    id
    appointmentDateTimeTo
    appointmentDateTimeFrom
    alertBody
  }
}
    `;
export type CreateMassTextAlertMutationFn = Apollo.MutationFunction<CreateMassTextAlertMutation, CreateMassTextAlertMutationVariables>;

/**
 * __useCreateMassTextAlertMutation__
 *
 * To run a mutation, you first call `useCreateMassTextAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMassTextAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMassTextAlertMutation, { data, loading, error }] = useCreateMassTextAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMassTextAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateMassTextAlertMutation, CreateMassTextAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMassTextAlertMutation, CreateMassTextAlertMutationVariables>(CreateMassTextAlertDocument, options);
      }
export type CreateMassTextAlertMutationHookResult = ReturnType<typeof useCreateMassTextAlertMutation>;
export type CreateMassTextAlertMutationResult = Apollo.MutationResult<CreateMassTextAlertMutation>;
export type CreateMassTextAlertMutationOptions = Apollo.BaseMutationOptions<CreateMassTextAlertMutation, CreateMassTextAlertMutationVariables>;
export const GetActivityLogForMassTextAlertsDocument = gql`
    query getActivityLogForMassTextAlerts($where: MassTextAlertWhereInput, $orderBy: [MassTextAlertOrderByWithRelationAndSearchRelevanceInput], $skip: Int, $take: Int) {
  findManyMassTextAlertCount(where: $where)
  findManyMassTextAlert(
    where: $where
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...MassTextAleryActivityLog
  }
}
    ${MassTextAleryActivityLogFragmentDoc}`;

/**
 * __useGetActivityLogForMassTextAlertsQuery__
 *
 * To run a query within a React component, call `useGetActivityLogForMassTextAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogForMassTextAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogForMassTextAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetActivityLogForMassTextAlertsQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityLogForMassTextAlertsQuery, GetActivityLogForMassTextAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogForMassTextAlertsQuery, GetActivityLogForMassTextAlertsQueryVariables>(GetActivityLogForMassTextAlertsDocument, options);
      }
export function useGetActivityLogForMassTextAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogForMassTextAlertsQuery, GetActivityLogForMassTextAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogForMassTextAlertsQuery, GetActivityLogForMassTextAlertsQueryVariables>(GetActivityLogForMassTextAlertsDocument, options);
        }
export type GetActivityLogForMassTextAlertsQueryHookResult = ReturnType<typeof useGetActivityLogForMassTextAlertsQuery>;
export type GetActivityLogForMassTextAlertsLazyQueryHookResult = ReturnType<typeof useGetActivityLogForMassTextAlertsLazyQuery>;
export type GetActivityLogForMassTextAlertsQueryResult = Apollo.QueryResult<GetActivityLogForMassTextAlertsQuery, GetActivityLogForMassTextAlertsQueryVariables>;
export function refetchGetActivityLogForMassTextAlertsQuery(variables?: GetActivityLogForMassTextAlertsQueryVariables) {
      return { query: GetActivityLogForMassTextAlertsDocument, variables: variables }
    }
export const GetDeliveryReportForMassTextAlertDocument = gql`
    query getDeliveryReportForMassTextAlert($massTextAlertId: String!) {
  massTextAlertReport(massTextAlertId: $massTextAlertId) {
    phoneNumber
    petParentFullName
    smsStatus
    errorDetails
  }
}
    `;

/**
 * __useGetDeliveryReportForMassTextAlertQuery__
 *
 * To run a query within a React component, call `useGetDeliveryReportForMassTextAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryReportForMassTextAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryReportForMassTextAlertQuery({
 *   variables: {
 *      massTextAlertId: // value for 'massTextAlertId'
 *   },
 * });
 */
export function useGetDeliveryReportForMassTextAlertQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryReportForMassTextAlertQuery, GetDeliveryReportForMassTextAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryReportForMassTextAlertQuery, GetDeliveryReportForMassTextAlertQueryVariables>(GetDeliveryReportForMassTextAlertDocument, options);
      }
export function useGetDeliveryReportForMassTextAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryReportForMassTextAlertQuery, GetDeliveryReportForMassTextAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryReportForMassTextAlertQuery, GetDeliveryReportForMassTextAlertQueryVariables>(GetDeliveryReportForMassTextAlertDocument, options);
        }
export type GetDeliveryReportForMassTextAlertQueryHookResult = ReturnType<typeof useGetDeliveryReportForMassTextAlertQuery>;
export type GetDeliveryReportForMassTextAlertLazyQueryHookResult = ReturnType<typeof useGetDeliveryReportForMassTextAlertLazyQuery>;
export type GetDeliveryReportForMassTextAlertQueryResult = Apollo.QueryResult<GetDeliveryReportForMassTextAlertQuery, GetDeliveryReportForMassTextAlertQueryVariables>;
export function refetchGetDeliveryReportForMassTextAlertQuery(variables?: GetDeliveryReportForMassTextAlertQueryVariables) {
      return { query: GetDeliveryReportForMassTextAlertDocument, variables: variables }
    }
export const GetEstimatedSendTimeForMassTextAlertDocument = gql`
    query getEstimatedSendTimeForMassTextAlert($clinicId: String!, $appointmentDateTimeFrom: String!, $appointmentDateTimeTo: String!, $alertBody: String!) {
  estimateNumberOfPetParentsReached(
    clinicId: $clinicId
    appointmentDateTimeTo: $appointmentDateTimeTo
    appointmentDateTimeFrom: $appointmentDateTimeFrom
    alertBody: $alertBody
  ) {
    numberOfPetPerents
    timeEstimateInSeconds
  }
}
    `;

/**
 * __useGetEstimatedSendTimeForMassTextAlertQuery__
 *
 * To run a query within a React component, call `useGetEstimatedSendTimeForMassTextAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstimatedSendTimeForMassTextAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstimatedSendTimeForMassTextAlertQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      appointmentDateTimeFrom: // value for 'appointmentDateTimeFrom'
 *      appointmentDateTimeTo: // value for 'appointmentDateTimeTo'
 *      alertBody: // value for 'alertBody'
 *   },
 * });
 */
export function useGetEstimatedSendTimeForMassTextAlertQuery(baseOptions: Apollo.QueryHookOptions<GetEstimatedSendTimeForMassTextAlertQuery, GetEstimatedSendTimeForMassTextAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEstimatedSendTimeForMassTextAlertQuery, GetEstimatedSendTimeForMassTextAlertQueryVariables>(GetEstimatedSendTimeForMassTextAlertDocument, options);
      }
export function useGetEstimatedSendTimeForMassTextAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstimatedSendTimeForMassTextAlertQuery, GetEstimatedSendTimeForMassTextAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEstimatedSendTimeForMassTextAlertQuery, GetEstimatedSendTimeForMassTextAlertQueryVariables>(GetEstimatedSendTimeForMassTextAlertDocument, options);
        }
export type GetEstimatedSendTimeForMassTextAlertQueryHookResult = ReturnType<typeof useGetEstimatedSendTimeForMassTextAlertQuery>;
export type GetEstimatedSendTimeForMassTextAlertLazyQueryHookResult = ReturnType<typeof useGetEstimatedSendTimeForMassTextAlertLazyQuery>;
export type GetEstimatedSendTimeForMassTextAlertQueryResult = Apollo.QueryResult<GetEstimatedSendTimeForMassTextAlertQuery, GetEstimatedSendTimeForMassTextAlertQueryVariables>;
export function refetchGetEstimatedSendTimeForMassTextAlertQuery(variables?: GetEstimatedSendTimeForMassTextAlertQueryVariables) {
      return { query: GetEstimatedSendTimeForMassTextAlertDocument, variables: variables }
    }
export const SubscribeToMassTextAlertStatusDocument = gql`
    subscription subscribeToMassTextAlertStatus($where: SubscriptionMassTextAlertWhereInput!) {
  massTextAlertChanged(where: $where) {
    node {
      id
      status
    }
  }
}
    `;

/**
 * __useSubscribeToMassTextAlertStatusSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMassTextAlertStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMassTextAlertStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMassTextAlertStatusSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToMassTextAlertStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToMassTextAlertStatusSubscription, SubscribeToMassTextAlertStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToMassTextAlertStatusSubscription, SubscribeToMassTextAlertStatusSubscriptionVariables>(SubscribeToMassTextAlertStatusDocument, options);
      }
export type SubscribeToMassTextAlertStatusSubscriptionHookResult = ReturnType<typeof useSubscribeToMassTextAlertStatusSubscription>;
export type SubscribeToMassTextAlertStatusSubscriptionResult = Apollo.SubscriptionResult<SubscribeToMassTextAlertStatusSubscription>;
export const GetAppointmentFiltersDocument = gql`
    query getAppointmentFilters {
  appointmentFilters @client {
    currentDay
    startDate
    endDate
    daysAheadInterval
    defaultAppointmentDurationInMinutes
  }
}
    `;

/**
 * __useGetAppointmentFiltersQuery__
 *
 * To run a query within a React component, call `useGetAppointmentFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentFiltersQuery, GetAppointmentFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentFiltersQuery, GetAppointmentFiltersQueryVariables>(GetAppointmentFiltersDocument, options);
      }
export function useGetAppointmentFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentFiltersQuery, GetAppointmentFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentFiltersQuery, GetAppointmentFiltersQueryVariables>(GetAppointmentFiltersDocument, options);
        }
export type GetAppointmentFiltersQueryHookResult = ReturnType<typeof useGetAppointmentFiltersQuery>;
export type GetAppointmentFiltersLazyQueryHookResult = ReturnType<typeof useGetAppointmentFiltersLazyQuery>;
export type GetAppointmentFiltersQueryResult = Apollo.QueryResult<GetAppointmentFiltersQuery, GetAppointmentFiltersQueryVariables>;
export function refetchGetAppointmentFiltersQuery(variables?: GetAppointmentFiltersQueryVariables) {
      return { query: GetAppointmentFiltersDocument, variables: variables }
    }
export const CompleteCareBenefitUsageFollowupDocument = gql`
    mutation completeCareBenefitUsageFollowup($followupId: String!, $completedAt: DateTime!) {
  updateOneCareBenefitUsageFollowup(
    where: {id: $followupId}
    data: {completed: true, completedAt: $completedAt}
  ) {
    id
    completed
  }
}
    `;
export type CompleteCareBenefitUsageFollowupMutationFn = Apollo.MutationFunction<CompleteCareBenefitUsageFollowupMutation, CompleteCareBenefitUsageFollowupMutationVariables>;

/**
 * __useCompleteCareBenefitUsageFollowupMutation__
 *
 * To run a mutation, you first call `useCompleteCareBenefitUsageFollowupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCareBenefitUsageFollowupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCareBenefitUsageFollowupMutation, { data, loading, error }] = useCompleteCareBenefitUsageFollowupMutation({
 *   variables: {
 *      followupId: // value for 'followupId'
 *      completedAt: // value for 'completedAt'
 *   },
 * });
 */
export function useCompleteCareBenefitUsageFollowupMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCareBenefitUsageFollowupMutation, CompleteCareBenefitUsageFollowupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCareBenefitUsageFollowupMutation, CompleteCareBenefitUsageFollowupMutationVariables>(CompleteCareBenefitUsageFollowupDocument, options);
      }
export type CompleteCareBenefitUsageFollowupMutationHookResult = ReturnType<typeof useCompleteCareBenefitUsageFollowupMutation>;
export type CompleteCareBenefitUsageFollowupMutationResult = Apollo.MutationResult<CompleteCareBenefitUsageFollowupMutation>;
export type CompleteCareBenefitUsageFollowupMutationOptions = Apollo.BaseMutationOptions<CompleteCareBenefitUsageFollowupMutation, CompleteCareBenefitUsageFollowupMutationVariables>;
export const CompleteCareBenefitUsageFollowupStepDocument = gql`
    mutation completeCareBenefitUsageFollowupStep($stepId: String!, $completedAt: DateTime!, $completedByUserId: String!) {
  updateOneCareBenefitUsageFollowupStep(
    where: {id: $stepId}
    data: {completed: true, completedAt: $completedAt, completedByUser: {connect: {id: $completedByUserId}}}
  ) {
    id
    completed
  }
}
    `;
export type CompleteCareBenefitUsageFollowupStepMutationFn = Apollo.MutationFunction<CompleteCareBenefitUsageFollowupStepMutation, CompleteCareBenefitUsageFollowupStepMutationVariables>;

/**
 * __useCompleteCareBenefitUsageFollowupStepMutation__
 *
 * To run a mutation, you first call `useCompleteCareBenefitUsageFollowupStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCareBenefitUsageFollowupStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCareBenefitUsageFollowupStepMutation, { data, loading, error }] = useCompleteCareBenefitUsageFollowupStepMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *      completedAt: // value for 'completedAt'
 *      completedByUserId: // value for 'completedByUserId'
 *   },
 * });
 */
export function useCompleteCareBenefitUsageFollowupStepMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCareBenefitUsageFollowupStepMutation, CompleteCareBenefitUsageFollowupStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCareBenefitUsageFollowupStepMutation, CompleteCareBenefitUsageFollowupStepMutationVariables>(CompleteCareBenefitUsageFollowupStepDocument, options);
      }
export type CompleteCareBenefitUsageFollowupStepMutationHookResult = ReturnType<typeof useCompleteCareBenefitUsageFollowupStepMutation>;
export type CompleteCareBenefitUsageFollowupStepMutationResult = Apollo.MutationResult<CompleteCareBenefitUsageFollowupStepMutation>;
export type CompleteCareBenefitUsageFollowupStepMutationOptions = Apollo.BaseMutationOptions<CompleteCareBenefitUsageFollowupStepMutation, CompleteCareBenefitUsageFollowupStepMutationVariables>;
export const GetCareBenefitUsageFollowupDocument = gql`
    query getCareBenefitUsageFollowup($organizationPetId: String!) {
  findManyCareBenefitUsageFollowup(
    where: {relatedOrganizationPetId: {equals: $organizationPetId}, completed: {equals: false}}
  ) {
    ...CareBenefitUsageFollowUp
  }
}
    ${CareBenefitUsageFollowUpFragmentDoc}`;

/**
 * __useGetCareBenefitUsageFollowupQuery__
 *
 * To run a query within a React component, call `useGetCareBenefitUsageFollowupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareBenefitUsageFollowupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareBenefitUsageFollowupQuery({
 *   variables: {
 *      organizationPetId: // value for 'organizationPetId'
 *   },
 * });
 */
export function useGetCareBenefitUsageFollowupQuery(baseOptions: Apollo.QueryHookOptions<GetCareBenefitUsageFollowupQuery, GetCareBenefitUsageFollowupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareBenefitUsageFollowupQuery, GetCareBenefitUsageFollowupQueryVariables>(GetCareBenefitUsageFollowupDocument, options);
      }
export function useGetCareBenefitUsageFollowupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareBenefitUsageFollowupQuery, GetCareBenefitUsageFollowupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareBenefitUsageFollowupQuery, GetCareBenefitUsageFollowupQueryVariables>(GetCareBenefitUsageFollowupDocument, options);
        }
export type GetCareBenefitUsageFollowupQueryHookResult = ReturnType<typeof useGetCareBenefitUsageFollowupQuery>;
export type GetCareBenefitUsageFollowupLazyQueryHookResult = ReturnType<typeof useGetCareBenefitUsageFollowupLazyQuery>;
export type GetCareBenefitUsageFollowupQueryResult = Apollo.QueryResult<GetCareBenefitUsageFollowupQuery, GetCareBenefitUsageFollowupQueryVariables>;
export function refetchGetCareBenefitUsageFollowupQuery(variables?: GetCareBenefitUsageFollowupQueryVariables) {
      return { query: GetCareBenefitUsageFollowupDocument, variables: variables }
    }
export const GetConnectedServicesDocument = gql`
    query getConnectedServices($where: OrganizationCareBenefitToClinicServiceWhereInput, $take: Int) {
  findManyOrganizationCareBenefitToClinicService(where: $where, take: $take) {
    service {
      id
      name
      integrationId
    }
  }
}
    `;

/**
 * __useGetConnectedServicesQuery__
 *
 * To run a query within a React component, call `useGetConnectedServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedServicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetConnectedServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetConnectedServicesQuery, GetConnectedServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectedServicesQuery, GetConnectedServicesQueryVariables>(GetConnectedServicesDocument, options);
      }
export function useGetConnectedServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedServicesQuery, GetConnectedServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectedServicesQuery, GetConnectedServicesQueryVariables>(GetConnectedServicesDocument, options);
        }
export type GetConnectedServicesQueryHookResult = ReturnType<typeof useGetConnectedServicesQuery>;
export type GetConnectedServicesLazyQueryHookResult = ReturnType<typeof useGetConnectedServicesLazyQuery>;
export type GetConnectedServicesQueryResult = Apollo.QueryResult<GetConnectedServicesQuery, GetConnectedServicesQueryVariables>;
export function refetchGetConnectedServicesQuery(variables?: GetConnectedServicesQueryVariables) {
      return { query: GetConnectedServicesDocument, variables: variables }
    }
export const CancelCareSubscriptionDocument = gql`
    mutation cancelCareSubscription($data: CancelCareSubscriptionInput) {
  cancelCareSubscription(data: $data) {
    success
    messages
  }
}
    `;
export type CancelCareSubscriptionMutationFn = Apollo.MutationFunction<CancelCareSubscriptionMutation, CancelCareSubscriptionMutationVariables>;

/**
 * __useCancelCareSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCareSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCareSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCareSubscriptionMutation, { data, loading, error }] = useCancelCareSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelCareSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelCareSubscriptionMutation, CancelCareSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCareSubscriptionMutation, CancelCareSubscriptionMutationVariables>(CancelCareSubscriptionDocument, options);
      }
export type CancelCareSubscriptionMutationHookResult = ReturnType<typeof useCancelCareSubscriptionMutation>;
export type CancelCareSubscriptionMutationResult = Apollo.MutationResult<CancelCareSubscriptionMutation>;
export type CancelCareSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelCareSubscriptionMutation, CancelCareSubscriptionMutationVariables>;
export const CreateCarePaymentMethodSetupDocument = gql`
    mutation createCarePaymentMethodSetup($data: CreateCarePaymentMethodSetupInput) {
  createCarePaymentMethodSetup(data: $data) {
    setupIntentSecret
  }
}
    `;
export type CreateCarePaymentMethodSetupMutationFn = Apollo.MutationFunction<CreateCarePaymentMethodSetupMutation, CreateCarePaymentMethodSetupMutationVariables>;

/**
 * __useCreateCarePaymentMethodSetupMutation__
 *
 * To run a mutation, you first call `useCreateCarePaymentMethodSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarePaymentMethodSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarePaymentMethodSetupMutation, { data, loading, error }] = useCreateCarePaymentMethodSetupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCarePaymentMethodSetupMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarePaymentMethodSetupMutation, CreateCarePaymentMethodSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCarePaymentMethodSetupMutation, CreateCarePaymentMethodSetupMutationVariables>(CreateCarePaymentMethodSetupDocument, options);
      }
export type CreateCarePaymentMethodSetupMutationHookResult = ReturnType<typeof useCreateCarePaymentMethodSetupMutation>;
export type CreateCarePaymentMethodSetupMutationResult = Apollo.MutationResult<CreateCarePaymentMethodSetupMutation>;
export type CreateCarePaymentMethodSetupMutationOptions = Apollo.BaseMutationOptions<CreateCarePaymentMethodSetupMutation, CreateCarePaymentMethodSetupMutationVariables>;
export const CreateEnrollmentSupportEventDocument = gql`
    mutation createEnrollmentSupportEvent($data: EnrollmentSupportEventCreateInput!) {
  createOneEnrollmentSupportEvent(data: $data) {
    id
    createdAt
    carePlanEnrollmentId
    requesterId
    pointOfContactId
    manageMembershipRequestType
    cancelReason
    manageMembershipDescription
    relatedInvoiceId
  }
}
    `;
export type CreateEnrollmentSupportEventMutationFn = Apollo.MutationFunction<CreateEnrollmentSupportEventMutation, CreateEnrollmentSupportEventMutationVariables>;

/**
 * __useCreateEnrollmentSupportEventMutation__
 *
 * To run a mutation, you first call `useCreateEnrollmentSupportEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrollmentSupportEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrollmentSupportEventMutation, { data, loading, error }] = useCreateEnrollmentSupportEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEnrollmentSupportEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnrollmentSupportEventMutation, CreateEnrollmentSupportEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnrollmentSupportEventMutation, CreateEnrollmentSupportEventMutationVariables>(CreateEnrollmentSupportEventDocument, options);
      }
export type CreateEnrollmentSupportEventMutationHookResult = ReturnType<typeof useCreateEnrollmentSupportEventMutation>;
export type CreateEnrollmentSupportEventMutationResult = Apollo.MutationResult<CreateEnrollmentSupportEventMutation>;
export type CreateEnrollmentSupportEventMutationOptions = Apollo.BaseMutationOptions<CreateEnrollmentSupportEventMutation, CreateEnrollmentSupportEventMutationVariables>;
export const EnsurePetsArePromotedDocument = gql`
    mutation ensurePetsArePromoted($data: EnsurePetsPromotedInput) {
  ensurePetsPromoted(data: $data) {
    success
  }
}
    `;
export type EnsurePetsArePromotedMutationFn = Apollo.MutationFunction<EnsurePetsArePromotedMutation, EnsurePetsArePromotedMutationVariables>;

/**
 * __useEnsurePetsArePromotedMutation__
 *
 * To run a mutation, you first call `useEnsurePetsArePromotedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnsurePetsArePromotedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ensurePetsArePromotedMutation, { data, loading, error }] = useEnsurePetsArePromotedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEnsurePetsArePromotedMutation(baseOptions?: Apollo.MutationHookOptions<EnsurePetsArePromotedMutation, EnsurePetsArePromotedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnsurePetsArePromotedMutation, EnsurePetsArePromotedMutationVariables>(EnsurePetsArePromotedDocument, options);
      }
export type EnsurePetsArePromotedMutationHookResult = ReturnType<typeof useEnsurePetsArePromotedMutation>;
export type EnsurePetsArePromotedMutationResult = Apollo.MutationResult<EnsurePetsArePromotedMutation>;
export type EnsurePetsArePromotedMutationOptions = Apollo.BaseMutationOptions<EnsurePetsArePromotedMutation, EnsurePetsArePromotedMutationVariables>;
export const PerformCarePlanEnrollmentUpgradeDocument = gql`
    mutation performCarePlanEnrollmentUpgrade($data: PerformCarePlanEnrollmentUpgradeInput!) {
  performCarePlanEnrollmentUpgrade(data: $data) {
    success
    message
    upgradeInvoiceId
  }
}
    `;
export type PerformCarePlanEnrollmentUpgradeMutationFn = Apollo.MutationFunction<PerformCarePlanEnrollmentUpgradeMutation, PerformCarePlanEnrollmentUpgradeMutationVariables>;

/**
 * __usePerformCarePlanEnrollmentUpgradeMutation__
 *
 * To run a mutation, you first call `usePerformCarePlanEnrollmentUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformCarePlanEnrollmentUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performCarePlanEnrollmentUpgradeMutation, { data, loading, error }] = usePerformCarePlanEnrollmentUpgradeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePerformCarePlanEnrollmentUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<PerformCarePlanEnrollmentUpgradeMutation, PerformCarePlanEnrollmentUpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PerformCarePlanEnrollmentUpgradeMutation, PerformCarePlanEnrollmentUpgradeMutationVariables>(PerformCarePlanEnrollmentUpgradeDocument, options);
      }
export type PerformCarePlanEnrollmentUpgradeMutationHookResult = ReturnType<typeof usePerformCarePlanEnrollmentUpgradeMutation>;
export type PerformCarePlanEnrollmentUpgradeMutationResult = Apollo.MutationResult<PerformCarePlanEnrollmentUpgradeMutation>;
export type PerformCarePlanEnrollmentUpgradeMutationOptions = Apollo.BaseMutationOptions<PerformCarePlanEnrollmentUpgradeMutation, PerformCarePlanEnrollmentUpgradeMutationVariables>;
export const TransferCareSubscriptionDocument = gql`
    mutation transferCareSubscription($data: TransferSubscriptionInput) {
  transferCareSubscription(data: $data) {
    success
  }
}
    `;
export type TransferCareSubscriptionMutationFn = Apollo.MutationFunction<TransferCareSubscriptionMutation, TransferCareSubscriptionMutationVariables>;

/**
 * __useTransferCareSubscriptionMutation__
 *
 * To run a mutation, you first call `useTransferCareSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferCareSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferCareSubscriptionMutation, { data, loading, error }] = useTransferCareSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTransferCareSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<TransferCareSubscriptionMutation, TransferCareSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferCareSubscriptionMutation, TransferCareSubscriptionMutationVariables>(TransferCareSubscriptionDocument, options);
      }
export type TransferCareSubscriptionMutationHookResult = ReturnType<typeof useTransferCareSubscriptionMutation>;
export type TransferCareSubscriptionMutationResult = Apollo.MutationResult<TransferCareSubscriptionMutation>;
export type TransferCareSubscriptionMutationOptions = Apollo.BaseMutationOptions<TransferCareSubscriptionMutation, TransferCareSubscriptionMutationVariables>;
export const UpdateCarePlanEnrollmentDocument = gql`
    mutation updateCarePlanEnrollment($where: CarePlanEnrollmentWhereUniqueInput!, $data: CarePlanEnrollmentUpdateInput!) {
  updateOneCarePlanEnrollment(where: $where, data: $data) {
    id
    autoRenews
  }
}
    `;
export type UpdateCarePlanEnrollmentMutationFn = Apollo.MutationFunction<UpdateCarePlanEnrollmentMutation, UpdateCarePlanEnrollmentMutationVariables>;

/**
 * __useUpdateCarePlanEnrollmentMutation__
 *
 * To run a mutation, you first call `useUpdateCarePlanEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarePlanEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarePlanEnrollmentMutation, { data, loading, error }] = useUpdateCarePlanEnrollmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCarePlanEnrollmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCarePlanEnrollmentMutation, UpdateCarePlanEnrollmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCarePlanEnrollmentMutation, UpdateCarePlanEnrollmentMutationVariables>(UpdateCarePlanEnrollmentDocument, options);
      }
export type UpdateCarePlanEnrollmentMutationHookResult = ReturnType<typeof useUpdateCarePlanEnrollmentMutation>;
export type UpdateCarePlanEnrollmentMutationResult = Apollo.MutationResult<UpdateCarePlanEnrollmentMutation>;
export type UpdateCarePlanEnrollmentMutationOptions = Apollo.BaseMutationOptions<UpdateCarePlanEnrollmentMutation, UpdateCarePlanEnrollmentMutationVariables>;
export const ExportCancellationSummaryAsPdfDocument = gql`
    query exportCancellationSummaryAsPdf($data: ExportCancellationSummaryAsPdfInput!) {
  exportCancellationSummaryAsPdf(data: $data) {
    success
    base64PDF
  }
}
    `;

/**
 * __useExportCancellationSummaryAsPdfQuery__
 *
 * To run a query within a React component, call `useExportCancellationSummaryAsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCancellationSummaryAsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCancellationSummaryAsPdfQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExportCancellationSummaryAsPdfQuery(baseOptions: Apollo.QueryHookOptions<ExportCancellationSummaryAsPdfQuery, ExportCancellationSummaryAsPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportCancellationSummaryAsPdfQuery, ExportCancellationSummaryAsPdfQueryVariables>(ExportCancellationSummaryAsPdfDocument, options);
      }
export function useExportCancellationSummaryAsPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportCancellationSummaryAsPdfQuery, ExportCancellationSummaryAsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportCancellationSummaryAsPdfQuery, ExportCancellationSummaryAsPdfQueryVariables>(ExportCancellationSummaryAsPdfDocument, options);
        }
export type ExportCancellationSummaryAsPdfQueryHookResult = ReturnType<typeof useExportCancellationSummaryAsPdfQuery>;
export type ExportCancellationSummaryAsPdfLazyQueryHookResult = ReturnType<typeof useExportCancellationSummaryAsPdfLazyQuery>;
export type ExportCancellationSummaryAsPdfQueryResult = Apollo.QueryResult<ExportCancellationSummaryAsPdfQuery, ExportCancellationSummaryAsPdfQueryVariables>;
export function refetchExportCancellationSummaryAsPdfQuery(variables?: ExportCancellationSummaryAsPdfQueryVariables) {
      return { query: ExportCancellationSummaryAsPdfDocument, variables: variables }
    }
export const FindLastUsageByBenefitDocument = gql`
    query findLastUsageByBenefit($usageClinicPetId: String, $benefitId: String) {
  findManyCareBenefitUsage(
    where: {usageClinicPetId: {equals: $usageClinicPetId}, benefitId: {equals: $benefitId}}
    take: 1
    orderBy: {usedAt: desc}
  ) {
    id
  }
}
    `;

/**
 * __useFindLastUsageByBenefitQuery__
 *
 * To run a query within a React component, call `useFindLastUsageByBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLastUsageByBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLastUsageByBenefitQuery({
 *   variables: {
 *      usageClinicPetId: // value for 'usageClinicPetId'
 *      benefitId: // value for 'benefitId'
 *   },
 * });
 */
export function useFindLastUsageByBenefitQuery(baseOptions?: Apollo.QueryHookOptions<FindLastUsageByBenefitQuery, FindLastUsageByBenefitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindLastUsageByBenefitQuery, FindLastUsageByBenefitQueryVariables>(FindLastUsageByBenefitDocument, options);
      }
export function useFindLastUsageByBenefitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindLastUsageByBenefitQuery, FindLastUsageByBenefitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindLastUsageByBenefitQuery, FindLastUsageByBenefitQueryVariables>(FindLastUsageByBenefitDocument, options);
        }
export type FindLastUsageByBenefitQueryHookResult = ReturnType<typeof useFindLastUsageByBenefitQuery>;
export type FindLastUsageByBenefitLazyQueryHookResult = ReturnType<typeof useFindLastUsageByBenefitLazyQuery>;
export type FindLastUsageByBenefitQueryResult = Apollo.QueryResult<FindLastUsageByBenefitQuery, FindLastUsageByBenefitQueryVariables>;
export function refetchFindLastUsageByBenefitQuery(variables?: FindLastUsageByBenefitQueryVariables) {
      return { query: FindLastUsageByBenefitDocument, variables: variables }
    }
export const FindManyCareBenefitUsageDocument = gql`
    query findManyCareBenefitUsage($orgPetId: String, $orgPetParentId: String, $take: Int, $orderBy: [CareBenefitUsageOrderByWithRelationAndSearchRelevanceInput!]) {
  findManyCareBenefitUsage(
    where: {usageClinicPet: {petParents: {some: {organizationPetParentId: {equals: $orgPetParentId}}}, organizationPetId: {equals: $orgPetId}}}
    take: $take
    orderBy: $orderBy
  ) {
    ...CareBenefitActivityUsage
  }
  findManyCareBenefitUsageCount(
    where: {usageClinicPet: {petParents: {some: {organizationPetParentId: {equals: $orgPetParentId}}}, organizationPetId: {equals: $orgPetId}}}
  )
}
    ${CareBenefitActivityUsageFragmentDoc}`;

/**
 * __useFindManyCareBenefitUsageQuery__
 *
 * To run a query within a React component, call `useFindManyCareBenefitUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCareBenefitUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCareBenefitUsageQuery({
 *   variables: {
 *      orgPetId: // value for 'orgPetId'
 *      orgPetParentId: // value for 'orgPetParentId'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindManyCareBenefitUsageQuery(baseOptions?: Apollo.QueryHookOptions<FindManyCareBenefitUsageQuery, FindManyCareBenefitUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyCareBenefitUsageQuery, FindManyCareBenefitUsageQueryVariables>(FindManyCareBenefitUsageDocument, options);
      }
export function useFindManyCareBenefitUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyCareBenefitUsageQuery, FindManyCareBenefitUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyCareBenefitUsageQuery, FindManyCareBenefitUsageQueryVariables>(FindManyCareBenefitUsageDocument, options);
        }
export type FindManyCareBenefitUsageQueryHookResult = ReturnType<typeof useFindManyCareBenefitUsageQuery>;
export type FindManyCareBenefitUsageLazyQueryHookResult = ReturnType<typeof useFindManyCareBenefitUsageLazyQuery>;
export type FindManyCareBenefitUsageQueryResult = Apollo.QueryResult<FindManyCareBenefitUsageQuery, FindManyCareBenefitUsageQueryVariables>;
export function refetchFindManyCareBenefitUsageQuery(variables?: FindManyCareBenefitUsageQueryVariables) {
      return { query: FindManyCareBenefitUsageDocument, variables: variables }
    }
export const GetCareActivityLogItemsDocument = gql`
    query getCareActivityLogItems($enrollmentWhere: CarePlanEnrollmentWhereInput, $supportEventWhere: EnrollmentSupportEventWhereInput, $benefitUsageWhere: CareBenefitUsageWhereInput, $take: Int) {
  findManyCarePlanEnrollment(where: $enrollmentWhere) {
    ...ActivityLogCareEnrollment
  }
  findManyEnrollmentSupportEvent(
    where: $supportEventWhere
    take: $take
    orderBy: {createdAt: desc}
  ) {
    ...ActivityLogEnrollmentSupportEvent
  }
  findManyEnrollmentSupportEventCount(where: $supportEventWhere)
  findManyCareBenefitUsage(
    where: $benefitUsageWhere
    take: $take
    orderBy: {usedAt: desc}
  ) {
    ...CareBenefitActivityUsage
  }
  findManyCareBenefitUsageCount(where: $benefitUsageWhere)
}
    ${ActivityLogCareEnrollmentFragmentDoc}
${ActivityLogEnrollmentSupportEventFragmentDoc}
${CareBenefitActivityUsageFragmentDoc}`;

/**
 * __useGetCareActivityLogItemsQuery__
 *
 * To run a query within a React component, call `useGetCareActivityLogItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareActivityLogItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareActivityLogItemsQuery({
 *   variables: {
 *      enrollmentWhere: // value for 'enrollmentWhere'
 *      supportEventWhere: // value for 'supportEventWhere'
 *      benefitUsageWhere: // value for 'benefitUsageWhere'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCareActivityLogItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetCareActivityLogItemsQuery, GetCareActivityLogItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareActivityLogItemsQuery, GetCareActivityLogItemsQueryVariables>(GetCareActivityLogItemsDocument, options);
      }
export function useGetCareActivityLogItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareActivityLogItemsQuery, GetCareActivityLogItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareActivityLogItemsQuery, GetCareActivityLogItemsQueryVariables>(GetCareActivityLogItemsDocument, options);
        }
export type GetCareActivityLogItemsQueryHookResult = ReturnType<typeof useGetCareActivityLogItemsQuery>;
export type GetCareActivityLogItemsLazyQueryHookResult = ReturnType<typeof useGetCareActivityLogItemsLazyQuery>;
export type GetCareActivityLogItemsQueryResult = Apollo.QueryResult<GetCareActivityLogItemsQuery, GetCareActivityLogItemsQueryVariables>;
export function refetchGetCareActivityLogItemsQuery(variables?: GetCareActivityLogItemsQueryVariables) {
      return { query: GetCareActivityLogItemsDocument, variables: variables }
    }
export const GetCareEnrollmentInfoDocument = gql`
    query getCareEnrollmentInfo($enrollmentId: String) {
  findUniqueCarePlanEnrollment(where: {id: $enrollmentId}) {
    id
    startDate
    plan {
      ...CarePlanInfo
    }
    enrollmentPayments(where: {paidAt: {not: null}}) {
      id
      amount
      applicationFeeAmount
      paidAt
    }
  }
}
    ${CarePlanInfoFragmentDoc}`;

/**
 * __useGetCareEnrollmentInfoQuery__
 *
 * To run a query within a React component, call `useGetCareEnrollmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareEnrollmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareEnrollmentInfoQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useGetCareEnrollmentInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCareEnrollmentInfoQuery, GetCareEnrollmentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareEnrollmentInfoQuery, GetCareEnrollmentInfoQueryVariables>(GetCareEnrollmentInfoDocument, options);
      }
export function useGetCareEnrollmentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareEnrollmentInfoQuery, GetCareEnrollmentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareEnrollmentInfoQuery, GetCareEnrollmentInfoQueryVariables>(GetCareEnrollmentInfoDocument, options);
        }
export type GetCareEnrollmentInfoQueryHookResult = ReturnType<typeof useGetCareEnrollmentInfoQuery>;
export type GetCareEnrollmentInfoLazyQueryHookResult = ReturnType<typeof useGetCareEnrollmentInfoLazyQuery>;
export type GetCareEnrollmentInfoQueryResult = Apollo.QueryResult<GetCareEnrollmentInfoQuery, GetCareEnrollmentInfoQueryVariables>;
export function refetchGetCareEnrollmentInfoQuery(variables?: GetCareEnrollmentInfoQueryVariables) {
      return { query: GetCareEnrollmentInfoDocument, variables: variables }
    }
export const GetCareEnrollmentTrueUpHistoryDocument = gql`
    query getCareEnrollmentTrueUpHistory($where: CareSubscriptionTrueUpWhereInput) {
  findManyCareSubscriptionTrueUp(where: $where, orderBy: {dueAt: asc}) {
    id
    dueAt
    completedAt
    retryAt
    amount
    shouldCancelEnrollment
  }
}
    `;

/**
 * __useGetCareEnrollmentTrueUpHistoryQuery__
 *
 * To run a query within a React component, call `useGetCareEnrollmentTrueUpHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareEnrollmentTrueUpHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareEnrollmentTrueUpHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCareEnrollmentTrueUpHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetCareEnrollmentTrueUpHistoryQuery, GetCareEnrollmentTrueUpHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareEnrollmentTrueUpHistoryQuery, GetCareEnrollmentTrueUpHistoryQueryVariables>(GetCareEnrollmentTrueUpHistoryDocument, options);
      }
export function useGetCareEnrollmentTrueUpHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareEnrollmentTrueUpHistoryQuery, GetCareEnrollmentTrueUpHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareEnrollmentTrueUpHistoryQuery, GetCareEnrollmentTrueUpHistoryQueryVariables>(GetCareEnrollmentTrueUpHistoryDocument, options);
        }
export type GetCareEnrollmentTrueUpHistoryQueryHookResult = ReturnType<typeof useGetCareEnrollmentTrueUpHistoryQuery>;
export type GetCareEnrollmentTrueUpHistoryLazyQueryHookResult = ReturnType<typeof useGetCareEnrollmentTrueUpHistoryLazyQuery>;
export type GetCareEnrollmentTrueUpHistoryQueryResult = Apollo.QueryResult<GetCareEnrollmentTrueUpHistoryQuery, GetCareEnrollmentTrueUpHistoryQueryVariables>;
export function refetchGetCareEnrollmentTrueUpHistoryQuery(variables?: GetCareEnrollmentTrueUpHistoryQueryVariables) {
      return { query: GetCareEnrollmentTrueUpHistoryDocument, variables: variables }
    }
export const GetCareSavedPaymentMethodsDocument = gql`
    query getCareSavedPaymentMethods($where: StripePaymentMethodWhereInput) {
  findManyStripePaymentMethod(where: $where) {
    id
    isDefault
    isExpired
    last4
    cardBrand
    expirationMonth
    expirationYear
    stripeId
  }
}
    `;

/**
 * __useGetCareSavedPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetCareSavedPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareSavedPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareSavedPaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCareSavedPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetCareSavedPaymentMethodsQuery, GetCareSavedPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareSavedPaymentMethodsQuery, GetCareSavedPaymentMethodsQueryVariables>(GetCareSavedPaymentMethodsDocument, options);
      }
export function useGetCareSavedPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareSavedPaymentMethodsQuery, GetCareSavedPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareSavedPaymentMethodsQuery, GetCareSavedPaymentMethodsQueryVariables>(GetCareSavedPaymentMethodsDocument, options);
        }
export type GetCareSavedPaymentMethodsQueryHookResult = ReturnType<typeof useGetCareSavedPaymentMethodsQuery>;
export type GetCareSavedPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetCareSavedPaymentMethodsLazyQuery>;
export type GetCareSavedPaymentMethodsQueryResult = Apollo.QueryResult<GetCareSavedPaymentMethodsQuery, GetCareSavedPaymentMethodsQueryVariables>;
export function refetchGetCareSavedPaymentMethodsQuery(variables?: GetCareSavedPaymentMethodsQueryVariables) {
      return { query: GetCareSavedPaymentMethodsDocument, variables: variables }
    }
export const GetBalanceForClientDocument = gql`
    query getBalanceForClient($where: GetBalanceForClientInput!) {
  getBalanceForClient(where: $where) {
    ...BalanceForClient
  }
}
    ${BalanceForClientFragmentDoc}`;

/**
 * __useGetBalanceForClientQuery__
 *
 * To run a query within a React component, call `useGetBalanceForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceForClientQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBalanceForClientQuery(baseOptions: Apollo.QueryHookOptions<GetBalanceForClientQuery, GetBalanceForClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBalanceForClientQuery, GetBalanceForClientQueryVariables>(GetBalanceForClientDocument, options);
      }
export function useGetBalanceForClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBalanceForClientQuery, GetBalanceForClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBalanceForClientQuery, GetBalanceForClientQueryVariables>(GetBalanceForClientDocument, options);
        }
export type GetBalanceForClientQueryHookResult = ReturnType<typeof useGetBalanceForClientQuery>;
export type GetBalanceForClientLazyQueryHookResult = ReturnType<typeof useGetBalanceForClientLazyQuery>;
export type GetBalanceForClientQueryResult = Apollo.QueryResult<GetBalanceForClientQuery, GetBalanceForClientQueryVariables>;
export function refetchGetBalanceForClientQuery(variables?: GetBalanceForClientQueryVariables) {
      return { query: GetBalanceForClientDocument, variables: variables }
    }
export const GetInvoicesForClientDocument = gql`
    query getInvoicesForClient($where: InvoiceWhereInput!) {
  getInvoicesForClient(where: $where, orderBy: {date: desc}) {
    ...InvoiceForClient
  }
}
    ${InvoiceForClientFragmentDoc}`;

/**
 * __useGetInvoicesForClientQuery__
 *
 * To run a query within a React component, call `useGetInvoicesForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesForClientQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvoicesForClientQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesForClientQuery, GetInvoicesForClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesForClientQuery, GetInvoicesForClientQueryVariables>(GetInvoicesForClientDocument, options);
      }
export function useGetInvoicesForClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesForClientQuery, GetInvoicesForClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesForClientQuery, GetInvoicesForClientQueryVariables>(GetInvoicesForClientDocument, options);
        }
export type GetInvoicesForClientQueryHookResult = ReturnType<typeof useGetInvoicesForClientQuery>;
export type GetInvoicesForClientLazyQueryHookResult = ReturnType<typeof useGetInvoicesForClientLazyQuery>;
export type GetInvoicesForClientQueryResult = Apollo.QueryResult<GetInvoicesForClientQuery, GetInvoicesForClientQueryVariables>;
export function refetchGetInvoicesForClientQuery(variables?: GetInvoicesForClientQueryVariables) {
      return { query: GetInvoicesForClientDocument, variables: variables }
    }
export const GetClinicCarePlansDocument = gql`
    query getClinicCarePlans($where: CarePlanProviderGroupWhereInput) {
  findFirstCarePlanProviderGroup(where: $where) {
    ...ClinicCarePlanProviderGroup
  }
}
    ${ClinicCarePlanProviderGroupFragmentDoc}`;

/**
 * __useGetClinicCarePlansQuery__
 *
 * To run a query within a React component, call `useGetClinicCarePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicCarePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicCarePlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicCarePlansQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicCarePlansQuery, GetClinicCarePlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicCarePlansQuery, GetClinicCarePlansQueryVariables>(GetClinicCarePlansDocument, options);
      }
export function useGetClinicCarePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicCarePlansQuery, GetClinicCarePlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicCarePlansQuery, GetClinicCarePlansQueryVariables>(GetClinicCarePlansDocument, options);
        }
export type GetClinicCarePlansQueryHookResult = ReturnType<typeof useGetClinicCarePlansQuery>;
export type GetClinicCarePlansLazyQueryHookResult = ReturnType<typeof useGetClinicCarePlansLazyQuery>;
export type GetClinicCarePlansQueryResult = Apollo.QueryResult<GetClinicCarePlansQuery, GetClinicCarePlansQueryVariables>;
export function refetchGetClinicCarePlansQuery(variables?: GetClinicCarePlansQueryVariables) {
      return { query: GetClinicCarePlansDocument, variables: variables }
    }
export const GetCustomerStripePortalUrlDocument = gql`
    query getCustomerStripePortalUrl($data: StripeCustomerPortalInput, $clinicPetParentId: String) {
  stripeRetrievePetParentPortalUrl(
    data: $data
    clinicPetParentId: $clinicPetParentId
  ) {
    id
    url
  }
}
    `;

/**
 * __useGetCustomerStripePortalUrlQuery__
 *
 * To run a query within a React component, call `useGetCustomerStripePortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerStripePortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerStripePortalUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *   },
 * });
 */
export function useGetCustomerStripePortalUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerStripePortalUrlQuery, GetCustomerStripePortalUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerStripePortalUrlQuery, GetCustomerStripePortalUrlQueryVariables>(GetCustomerStripePortalUrlDocument, options);
      }
export function useGetCustomerStripePortalUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerStripePortalUrlQuery, GetCustomerStripePortalUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerStripePortalUrlQuery, GetCustomerStripePortalUrlQueryVariables>(GetCustomerStripePortalUrlDocument, options);
        }
export type GetCustomerStripePortalUrlQueryHookResult = ReturnType<typeof useGetCustomerStripePortalUrlQuery>;
export type GetCustomerStripePortalUrlLazyQueryHookResult = ReturnType<typeof useGetCustomerStripePortalUrlLazyQuery>;
export type GetCustomerStripePortalUrlQueryResult = Apollo.QueryResult<GetCustomerStripePortalUrlQuery, GetCustomerStripePortalUrlQueryVariables>;
export function refetchGetCustomerStripePortalUrlQuery(variables?: GetCustomerStripePortalUrlQueryVariables) {
      return { query: GetCustomerStripePortalUrlDocument, variables: variables }
    }
export const GetSidePanelBenefitsClinicPetParentDocument = gql`
    query getSidePanelBenefitsClinicPetParent($clinicPetParentId: String!) {
  findUniqueClinicPetParent(where: {id: $clinicPetParentId}) {
    ...CareBenefitPetParent
  }
}
    ${CareBenefitPetParentFragmentDoc}`;

/**
 * __useGetSidePanelBenefitsClinicPetParentQuery__
 *
 * To run a query within a React component, call `useGetSidePanelBenefitsClinicPetParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSidePanelBenefitsClinicPetParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSidePanelBenefitsClinicPetParentQuery({
 *   variables: {
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *   },
 * });
 */
export function useGetSidePanelBenefitsClinicPetParentQuery(baseOptions: Apollo.QueryHookOptions<GetSidePanelBenefitsClinicPetParentQuery, GetSidePanelBenefitsClinicPetParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSidePanelBenefitsClinicPetParentQuery, GetSidePanelBenefitsClinicPetParentQueryVariables>(GetSidePanelBenefitsClinicPetParentDocument, options);
      }
export function useGetSidePanelBenefitsClinicPetParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSidePanelBenefitsClinicPetParentQuery, GetSidePanelBenefitsClinicPetParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSidePanelBenefitsClinicPetParentQuery, GetSidePanelBenefitsClinicPetParentQueryVariables>(GetSidePanelBenefitsClinicPetParentDocument, options);
        }
export type GetSidePanelBenefitsClinicPetParentQueryHookResult = ReturnType<typeof useGetSidePanelBenefitsClinicPetParentQuery>;
export type GetSidePanelBenefitsClinicPetParentLazyQueryHookResult = ReturnType<typeof useGetSidePanelBenefitsClinicPetParentLazyQuery>;
export type GetSidePanelBenefitsClinicPetParentQueryResult = Apollo.QueryResult<GetSidePanelBenefitsClinicPetParentQuery, GetSidePanelBenefitsClinicPetParentQueryVariables>;
export function refetchGetSidePanelBenefitsClinicPetParentQuery(variables?: GetSidePanelBenefitsClinicPetParentQueryVariables) {
      return { query: GetSidePanelBenefitsClinicPetParentDocument, variables: variables }
    }
export const ClaimRewardDocument = gql`
    mutation claimReward($data: RequestLoyaltyRewardInput!) {
  requestLoyaltyReward(data: $data) {
    id
  }
}
    `;
export type ClaimRewardMutationFn = Apollo.MutationFunction<ClaimRewardMutation, ClaimRewardMutationVariables>;

/**
 * __useClaimRewardMutation__
 *
 * To run a mutation, you first call `useClaimRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRewardMutation, { data, loading, error }] = useClaimRewardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useClaimRewardMutation(baseOptions?: Apollo.MutationHookOptions<ClaimRewardMutation, ClaimRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimRewardMutation, ClaimRewardMutationVariables>(ClaimRewardDocument, options);
      }
export type ClaimRewardMutationHookResult = ReturnType<typeof useClaimRewardMutation>;
export type ClaimRewardMutationResult = Apollo.MutationResult<ClaimRewardMutation>;
export type ClaimRewardMutationOptions = Apollo.BaseMutationOptions<ClaimRewardMutation, ClaimRewardMutationVariables>;
export const FindOrCreateLoyaltyAccountForClinicPetParentDocument = gql`
    mutation findOrCreateLoyaltyAccountForClinicPetParent($clinicPetParentId: String!) {
  findOrCreateLoyaltyAccountForClinicPetParent(
    clinicPetParentId: $clinicPetParentId
  ) {
    id
    program {
      id
      status
    }
    pointBalance
    rewardRedemptions(
      where: {status: {equals: PendingFulfillment}}
      orderBy: {createdAt: desc}
      take: 1
    ) {
      ...LoyaltyProgramRewardRedemption
    }
  }
}
    ${LoyaltyProgramRewardRedemptionFragmentDoc}`;
export type FindOrCreateLoyaltyAccountForClinicPetParentMutationFn = Apollo.MutationFunction<FindOrCreateLoyaltyAccountForClinicPetParentMutation, FindOrCreateLoyaltyAccountForClinicPetParentMutationVariables>;

/**
 * __useFindOrCreateLoyaltyAccountForClinicPetParentMutation__
 *
 * To run a mutation, you first call `useFindOrCreateLoyaltyAccountForClinicPetParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateLoyaltyAccountForClinicPetParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateLoyaltyAccountForClinicPetParentMutation, { data, loading, error }] = useFindOrCreateLoyaltyAccountForClinicPetParentMutation({
 *   variables: {
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *   },
 * });
 */
export function useFindOrCreateLoyaltyAccountForClinicPetParentMutation(baseOptions?: Apollo.MutationHookOptions<FindOrCreateLoyaltyAccountForClinicPetParentMutation, FindOrCreateLoyaltyAccountForClinicPetParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindOrCreateLoyaltyAccountForClinicPetParentMutation, FindOrCreateLoyaltyAccountForClinicPetParentMutationVariables>(FindOrCreateLoyaltyAccountForClinicPetParentDocument, options);
      }
export type FindOrCreateLoyaltyAccountForClinicPetParentMutationHookResult = ReturnType<typeof useFindOrCreateLoyaltyAccountForClinicPetParentMutation>;
export type FindOrCreateLoyaltyAccountForClinicPetParentMutationResult = Apollo.MutationResult<FindOrCreateLoyaltyAccountForClinicPetParentMutation>;
export type FindOrCreateLoyaltyAccountForClinicPetParentMutationOptions = Apollo.BaseMutationOptions<FindOrCreateLoyaltyAccountForClinicPetParentMutation, FindOrCreateLoyaltyAccountForClinicPetParentMutationVariables>;
export const FulfillRewardDocument = gql`
    mutation fulfillReward($data: LoyaltyRewardRedemptionInput!) {
  fulfillLoyaltyRewardRedemption(data: $data) {
    id
  }
}
    `;
export type FulfillRewardMutationFn = Apollo.MutationFunction<FulfillRewardMutation, FulfillRewardMutationVariables>;

/**
 * __useFulfillRewardMutation__
 *
 * To run a mutation, you first call `useFulfillRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfillRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfillRewardMutation, { data, loading, error }] = useFulfillRewardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFulfillRewardMutation(baseOptions?: Apollo.MutationHookOptions<FulfillRewardMutation, FulfillRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FulfillRewardMutation, FulfillRewardMutationVariables>(FulfillRewardDocument, options);
      }
export type FulfillRewardMutationHookResult = ReturnType<typeof useFulfillRewardMutation>;
export type FulfillRewardMutationResult = Apollo.MutationResult<FulfillRewardMutation>;
export type FulfillRewardMutationOptions = Apollo.BaseMutationOptions<FulfillRewardMutation, FulfillRewardMutationVariables>;
export const GrantPointsDocument = gql`
    mutation grantPoints($data: LoyaltyPointGrantByUser!) {
  submitLoyaltyPointGrantToAccountByUser(data: $data) {
    success
  }
}
    `;
export type GrantPointsMutationFn = Apollo.MutationFunction<GrantPointsMutation, GrantPointsMutationVariables>;

/**
 * __useGrantPointsMutation__
 *
 * To run a mutation, you first call `useGrantPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantPointsMutation, { data, loading, error }] = useGrantPointsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGrantPointsMutation(baseOptions?: Apollo.MutationHookOptions<GrantPointsMutation, GrantPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantPointsMutation, GrantPointsMutationVariables>(GrantPointsDocument, options);
      }
export type GrantPointsMutationHookResult = ReturnType<typeof useGrantPointsMutation>;
export type GrantPointsMutationResult = Apollo.MutationResult<GrantPointsMutation>;
export type GrantPointsMutationOptions = Apollo.BaseMutationOptions<GrantPointsMutation, GrantPointsMutationVariables>;
export const GetFirstLoyaltyRewardRedemptionDocument = gql`
    query getFirstLoyaltyRewardRedemption($where: LoyaltyRewardRedemptionWhereInput) {
  findFirstLoyaltyRewardRedemption(where: $where) {
    id
  }
}
    `;

/**
 * __useGetFirstLoyaltyRewardRedemptionQuery__
 *
 * To run a query within a React component, call `useGetFirstLoyaltyRewardRedemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstLoyaltyRewardRedemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstLoyaltyRewardRedemptionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFirstLoyaltyRewardRedemptionQuery(baseOptions?: Apollo.QueryHookOptions<GetFirstLoyaltyRewardRedemptionQuery, GetFirstLoyaltyRewardRedemptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirstLoyaltyRewardRedemptionQuery, GetFirstLoyaltyRewardRedemptionQueryVariables>(GetFirstLoyaltyRewardRedemptionDocument, options);
      }
export function useGetFirstLoyaltyRewardRedemptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstLoyaltyRewardRedemptionQuery, GetFirstLoyaltyRewardRedemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirstLoyaltyRewardRedemptionQuery, GetFirstLoyaltyRewardRedemptionQueryVariables>(GetFirstLoyaltyRewardRedemptionDocument, options);
        }
export type GetFirstLoyaltyRewardRedemptionQueryHookResult = ReturnType<typeof useGetFirstLoyaltyRewardRedemptionQuery>;
export type GetFirstLoyaltyRewardRedemptionLazyQueryHookResult = ReturnType<typeof useGetFirstLoyaltyRewardRedemptionLazyQuery>;
export type GetFirstLoyaltyRewardRedemptionQueryResult = Apollo.QueryResult<GetFirstLoyaltyRewardRedemptionQuery, GetFirstLoyaltyRewardRedemptionQueryVariables>;
export function refetchGetFirstLoyaltyRewardRedemptionQuery(variables?: GetFirstLoyaltyRewardRedemptionQueryVariables) {
      return { query: GetFirstLoyaltyRewardRedemptionDocument, variables: variables }
    }
export const GetPetParentLoyaltyAccountHolderDocument = gql`
    query getPetParentLoyaltyAccountHolder($where: LoyaltyAccountHolderWhereInput) {
  findFirstLoyaltyAccountHolder(where: $where) {
    id
    account {
      id
      pointBalance
      rewardRedemptions(
        where: {status: {equals: PendingFulfillment}}
        orderBy: {createdAt: desc}
      ) {
        ...LoyaltyProgramRewardRedemption
      }
    }
  }
}
    ${LoyaltyProgramRewardRedemptionFragmentDoc}`;

/**
 * __useGetPetParentLoyaltyAccountHolderQuery__
 *
 * To run a query within a React component, call `useGetPetParentLoyaltyAccountHolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetParentLoyaltyAccountHolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetParentLoyaltyAccountHolderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPetParentLoyaltyAccountHolderQuery(baseOptions?: Apollo.QueryHookOptions<GetPetParentLoyaltyAccountHolderQuery, GetPetParentLoyaltyAccountHolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetParentLoyaltyAccountHolderQuery, GetPetParentLoyaltyAccountHolderQueryVariables>(GetPetParentLoyaltyAccountHolderDocument, options);
      }
export function useGetPetParentLoyaltyAccountHolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetParentLoyaltyAccountHolderQuery, GetPetParentLoyaltyAccountHolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetParentLoyaltyAccountHolderQuery, GetPetParentLoyaltyAccountHolderQueryVariables>(GetPetParentLoyaltyAccountHolderDocument, options);
        }
export type GetPetParentLoyaltyAccountHolderQueryHookResult = ReturnType<typeof useGetPetParentLoyaltyAccountHolderQuery>;
export type GetPetParentLoyaltyAccountHolderLazyQueryHookResult = ReturnType<typeof useGetPetParentLoyaltyAccountHolderLazyQuery>;
export type GetPetParentLoyaltyAccountHolderQueryResult = Apollo.QueryResult<GetPetParentLoyaltyAccountHolderQuery, GetPetParentLoyaltyAccountHolderQueryVariables>;
export function refetchGetPetParentLoyaltyAccountHolderQuery(variables?: GetPetParentLoyaltyAccountHolderQueryVariables) {
      return { query: GetPetParentLoyaltyAccountHolderDocument, variables: variables }
    }
export const GetPetParentLoyaltyProgramDataDocument = gql`
    query getPetParentLoyaltyProgramData($where: ClinicPetParentWhereInput) {
  findFirstClinicPetParent(where: $where) {
    id
    firstName
    lastName
    loyaltyAccountHolder {
      id
      programId
      account {
        id
        pointBalance
      }
    }
  }
}
    `;

/**
 * __useGetPetParentLoyaltyProgramDataQuery__
 *
 * To run a query within a React component, call `useGetPetParentLoyaltyProgramDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPetParentLoyaltyProgramDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPetParentLoyaltyProgramDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPetParentLoyaltyProgramDataQuery(baseOptions?: Apollo.QueryHookOptions<GetPetParentLoyaltyProgramDataQuery, GetPetParentLoyaltyProgramDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPetParentLoyaltyProgramDataQuery, GetPetParentLoyaltyProgramDataQueryVariables>(GetPetParentLoyaltyProgramDataDocument, options);
      }
export function useGetPetParentLoyaltyProgramDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPetParentLoyaltyProgramDataQuery, GetPetParentLoyaltyProgramDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPetParentLoyaltyProgramDataQuery, GetPetParentLoyaltyProgramDataQueryVariables>(GetPetParentLoyaltyProgramDataDocument, options);
        }
export type GetPetParentLoyaltyProgramDataQueryHookResult = ReturnType<typeof useGetPetParentLoyaltyProgramDataQuery>;
export type GetPetParentLoyaltyProgramDataLazyQueryHookResult = ReturnType<typeof useGetPetParentLoyaltyProgramDataLazyQuery>;
export type GetPetParentLoyaltyProgramDataQueryResult = Apollo.QueryResult<GetPetParentLoyaltyProgramDataQuery, GetPetParentLoyaltyProgramDataQueryVariables>;
export function refetchGetPetParentLoyaltyProgramDataQuery(variables?: GetPetParentLoyaltyProgramDataQueryVariables) {
      return { query: GetPetParentLoyaltyProgramDataDocument, variables: variables }
    }
export const GetRewardsHistoryDocument = gql`
    query getRewardsHistory($where: LoyaltyPointDeltaWhereInput, $take: Int!) {
  findManyLoyaltyPointDelta(
    where: $where
    orderBy: {createdAt: desc}
    take: $take
  ) {
    ...RewardHistory
  }
  findManyLoyaltyPointDeltaCount(where: $where)
}
    ${RewardHistoryFragmentDoc}`;

/**
 * __useGetRewardsHistoryQuery__
 *
 * To run a query within a React component, call `useGetRewardsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRewardsHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>(GetRewardsHistoryDocument, options);
      }
export function useGetRewardsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>(GetRewardsHistoryDocument, options);
        }
export type GetRewardsHistoryQueryHookResult = ReturnType<typeof useGetRewardsHistoryQuery>;
export type GetRewardsHistoryLazyQueryHookResult = ReturnType<typeof useGetRewardsHistoryLazyQuery>;
export type GetRewardsHistoryQueryResult = Apollo.QueryResult<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>;
export function refetchGetRewardsHistoryQuery(variables?: GetRewardsHistoryQueryVariables) {
      return { query: GetRewardsHistoryDocument, variables: variables }
    }
export const CheckPetEnrollmentDocument = gql`
    subscription checkPetEnrollment($where: CareEnrollmentWhereInput!) {
  checkPetEnrollment(where: $where) {
    isEnrolled
  }
}
    `;

/**
 * __useCheckPetEnrollmentSubscription__
 *
 * To run a query within a React component, call `useCheckPetEnrollmentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCheckPetEnrollmentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPetEnrollmentSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheckPetEnrollmentSubscription(baseOptions: Apollo.SubscriptionHookOptions<CheckPetEnrollmentSubscription, CheckPetEnrollmentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CheckPetEnrollmentSubscription, CheckPetEnrollmentSubscriptionVariables>(CheckPetEnrollmentDocument, options);
      }
export type CheckPetEnrollmentSubscriptionHookResult = ReturnType<typeof useCheckPetEnrollmentSubscription>;
export type CheckPetEnrollmentSubscriptionResult = Apollo.SubscriptionResult<CheckPetEnrollmentSubscription>;
export const CheckPetParentHasEnrolledPetDocument = gql`
    subscription checkPetParentHasEnrolledPet($where: HasCarePetWithEnrollmentWhereInput!) {
  checkPetParentHasEnrolledPet(where: $where) {
    hasEnrolledPet
  }
}
    `;

/**
 * __useCheckPetParentHasEnrolledPetSubscription__
 *
 * To run a query within a React component, call `useCheckPetParentHasEnrolledPetSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCheckPetParentHasEnrolledPetSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPetParentHasEnrolledPetSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheckPetParentHasEnrolledPetSubscription(baseOptions: Apollo.SubscriptionHookOptions<CheckPetParentHasEnrolledPetSubscription, CheckPetParentHasEnrolledPetSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CheckPetParentHasEnrolledPetSubscription, CheckPetParentHasEnrolledPetSubscriptionVariables>(CheckPetParentHasEnrolledPetDocument, options);
      }
export type CheckPetParentHasEnrolledPetSubscriptionHookResult = ReturnType<typeof useCheckPetParentHasEnrolledPetSubscription>;
export type CheckPetParentHasEnrolledPetSubscriptionResult = Apollo.SubscriptionResult<CheckPetParentHasEnrolledPetSubscription>;
export const SubscribeToLoyaltyAccountDocument = gql`
    subscription subscribeToLoyaltyAccount($where: LoyaltyAccountWhereUniqueInput!) {
  loyaltyAccountUpdated(where: $where) {
    node {
      id
      pointBalance
    }
    mutation
  }
}
    `;

/**
 * __useSubscribeToLoyaltyAccountSubscription__
 *
 * To run a query within a React component, call `useSubscribeToLoyaltyAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToLoyaltyAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToLoyaltyAccountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToLoyaltyAccountSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToLoyaltyAccountSubscription, SubscribeToLoyaltyAccountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToLoyaltyAccountSubscription, SubscribeToLoyaltyAccountSubscriptionVariables>(SubscribeToLoyaltyAccountDocument, options);
      }
export type SubscribeToLoyaltyAccountSubscriptionHookResult = ReturnType<typeof useSubscribeToLoyaltyAccountSubscription>;
export type SubscribeToLoyaltyAccountSubscriptionResult = Apollo.SubscriptionResult<SubscribeToLoyaltyAccountSubscription>;
export const SubscribeToLoyaltyProgramUpdateDocument = gql`
    subscription subscribeToLoyaltyProgramUpdate($where: LoyaltyProgramWhereUniqueInput!) {
  loyaltyProgramUpdated(where: $where) {
    node {
      id
      status
    }
    mutation
  }
}
    `;

/**
 * __useSubscribeToLoyaltyProgramUpdateSubscription__
 *
 * To run a query within a React component, call `useSubscribeToLoyaltyProgramUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToLoyaltyProgramUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToLoyaltyProgramUpdateSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToLoyaltyProgramUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToLoyaltyProgramUpdateSubscription, SubscribeToLoyaltyProgramUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToLoyaltyProgramUpdateSubscription, SubscribeToLoyaltyProgramUpdateSubscriptionVariables>(SubscribeToLoyaltyProgramUpdateDocument, options);
      }
export type SubscribeToLoyaltyProgramUpdateSubscriptionHookResult = ReturnType<typeof useSubscribeToLoyaltyProgramUpdateSubscription>;
export type SubscribeToLoyaltyProgramUpdateSubscriptionResult = Apollo.SubscriptionResult<SubscribeToLoyaltyProgramUpdateSubscription>;
export const SubscribeToLoyaltyRewardRedemptionUpdatedDocument = gql`
    subscription subscribeToLoyaltyRewardRedemptionUpdated($where: LoyaltyRewardRedemptionWhereUniqueInput!) {
  loyaltyRewardRedemptionUpdated(where: $where) {
    node {
      id
      status
    }
    mutation
  }
}
    `;

/**
 * __useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToLoyaltyRewardRedemptionUpdatedSubscription, SubscribeToLoyaltyRewardRedemptionUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToLoyaltyRewardRedemptionUpdatedSubscription, SubscribeToLoyaltyRewardRedemptionUpdatedSubscriptionVariables>(SubscribeToLoyaltyRewardRedemptionUpdatedDocument, options);
      }
export type SubscribeToLoyaltyRewardRedemptionUpdatedSubscriptionHookResult = ReturnType<typeof useSubscribeToLoyaltyRewardRedemptionUpdatedSubscription>;
export type SubscribeToLoyaltyRewardRedemptionUpdatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeToLoyaltyRewardRedemptionUpdatedSubscription>;
export const UpdatePetParentSettingDocument = gql`
    mutation updatePetParentSetting($data: PetParentSettingUpdateInput!, $where: PetParentSettingWhereUniqueInput!) {
  updateOnePetParentSetting(data: $data, where: $where) {
    ...SidePanelProfilePetParentSetting
  }
}
    ${SidePanelProfilePetParentSettingFragmentDoc}`;
export type UpdatePetParentSettingMutationFn = Apollo.MutationFunction<UpdatePetParentSettingMutation, UpdatePetParentSettingMutationVariables>;

/**
 * __useUpdatePetParentSettingMutation__
 *
 * To run a mutation, you first call `useUpdatePetParentSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePetParentSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePetParentSettingMutation, { data, loading, error }] = useUpdatePetParentSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePetParentSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePetParentSettingMutation, UpdatePetParentSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePetParentSettingMutation, UpdatePetParentSettingMutationVariables>(UpdatePetParentSettingDocument, options);
      }
export type UpdatePetParentSettingMutationHookResult = ReturnType<typeof useUpdatePetParentSettingMutation>;
export type UpdatePetParentSettingMutationResult = Apollo.MutationResult<UpdatePetParentSettingMutation>;
export type UpdatePetParentSettingMutationOptions = Apollo.BaseMutationOptions<UpdatePetParentSettingMutation, UpdatePetParentSettingMutationVariables>;
export const HasDeviceThatCanRecievePushDocument = gql`
    query hasDeviceThatCanRecievePush($clinicPetParentIds: [String!]) {
  findFirstClinicPetParentDevice(
    where: {clinicPetParentId: {in: $clinicPetParentIds}, isDeleted: {equals: false}, pushNotificationStatus: {equals: GRANTED}, snsEndpointArn: {not: {equals: null}}}
  ) {
    id
    pushNotificationStatus
  }
}
    `;

/**
 * __useHasDeviceThatCanRecievePushQuery__
 *
 * To run a query within a React component, call `useHasDeviceThatCanRecievePushQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasDeviceThatCanRecievePushQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasDeviceThatCanRecievePushQuery({
 *   variables: {
 *      clinicPetParentIds: // value for 'clinicPetParentIds'
 *   },
 * });
 */
export function useHasDeviceThatCanRecievePushQuery(baseOptions?: Apollo.QueryHookOptions<HasDeviceThatCanRecievePushQuery, HasDeviceThatCanRecievePushQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasDeviceThatCanRecievePushQuery, HasDeviceThatCanRecievePushQueryVariables>(HasDeviceThatCanRecievePushDocument, options);
      }
export function useHasDeviceThatCanRecievePushLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasDeviceThatCanRecievePushQuery, HasDeviceThatCanRecievePushQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasDeviceThatCanRecievePushQuery, HasDeviceThatCanRecievePushQueryVariables>(HasDeviceThatCanRecievePushDocument, options);
        }
export type HasDeviceThatCanRecievePushQueryHookResult = ReturnType<typeof useHasDeviceThatCanRecievePushQuery>;
export type HasDeviceThatCanRecievePushLazyQueryHookResult = ReturnType<typeof useHasDeviceThatCanRecievePushLazyQuery>;
export type HasDeviceThatCanRecievePushQueryResult = Apollo.QueryResult<HasDeviceThatCanRecievePushQuery, HasDeviceThatCanRecievePushQueryVariables>;
export function refetchHasDeviceThatCanRecievePushQuery(variables?: HasDeviceThatCanRecievePushQueryVariables) {
      return { query: HasDeviceThatCanRecievePushDocument, variables: variables }
    }
export const GetSidePanelParentPetsDocument = gql`
    query getSidePanelParentPets($where: ClinicPetWhereInput, $take: Int!, $orderBy: [ClinicPetOrderByWithRelationAndSearchRelevanceInput!]) {
  findManyClinicPet(where: $where, take: $take, orderBy: $orderBy) {
    id
    ...SidePanelParentPetData
  }
  findManyClinicPetCount(where: $where)
}
    ${SidePanelParentPetDataFragmentDoc}`;

/**
 * __useGetSidePanelParentPetsQuery__
 *
 * To run a query within a React component, call `useGetSidePanelParentPetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSidePanelParentPetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSidePanelParentPetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSidePanelParentPetsQuery(baseOptions: Apollo.QueryHookOptions<GetSidePanelParentPetsQuery, GetSidePanelParentPetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSidePanelParentPetsQuery, GetSidePanelParentPetsQueryVariables>(GetSidePanelParentPetsDocument, options);
      }
export function useGetSidePanelParentPetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSidePanelParentPetsQuery, GetSidePanelParentPetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSidePanelParentPetsQuery, GetSidePanelParentPetsQueryVariables>(GetSidePanelParentPetsDocument, options);
        }
export type GetSidePanelParentPetsQueryHookResult = ReturnType<typeof useGetSidePanelParentPetsQuery>;
export type GetSidePanelParentPetsLazyQueryHookResult = ReturnType<typeof useGetSidePanelParentPetsLazyQuery>;
export type GetSidePanelParentPetsQueryResult = Apollo.QueryResult<GetSidePanelParentPetsQuery, GetSidePanelParentPetsQueryVariables>;
export function refetchGetSidePanelParentPetsQuery(variables?: GetSidePanelParentPetsQueryVariables) {
      return { query: GetSidePanelParentPetsDocument, variables: variables }
    }
export const GetSidePanelPetParentProfileDocument = gql`
    query getSidePanelPetParentProfile($clinicPetParentId: String!, $clinicId: String!) {
  clinicPetParent: findUniqueClinicPetParent(where: {id: $clinicPetParentId}) {
    ...SidePanelProfileClinicPetParent
  }
}
    ${SidePanelProfileClinicPetParentFragmentDoc}`;

/**
 * __useGetSidePanelPetParentProfileQuery__
 *
 * To run a query within a React component, call `useGetSidePanelPetParentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSidePanelPetParentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSidePanelPetParentProfileQuery({
 *   variables: {
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetSidePanelPetParentProfileQuery(baseOptions: Apollo.QueryHookOptions<GetSidePanelPetParentProfileQuery, GetSidePanelPetParentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSidePanelPetParentProfileQuery, GetSidePanelPetParentProfileQueryVariables>(GetSidePanelPetParentProfileDocument, options);
      }
export function useGetSidePanelPetParentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSidePanelPetParentProfileQuery, GetSidePanelPetParentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSidePanelPetParentProfileQuery, GetSidePanelPetParentProfileQueryVariables>(GetSidePanelPetParentProfileDocument, options);
        }
export type GetSidePanelPetParentProfileQueryHookResult = ReturnType<typeof useGetSidePanelPetParentProfileQuery>;
export type GetSidePanelPetParentProfileLazyQueryHookResult = ReturnType<typeof useGetSidePanelPetParentProfileLazyQuery>;
export type GetSidePanelPetParentProfileQueryResult = Apollo.QueryResult<GetSidePanelPetParentProfileQuery, GetSidePanelPetParentProfileQueryVariables>;
export function refetchGetSidePanelPetParentProfileQuery(variables?: GetSidePanelPetParentProfileQueryVariables) {
      return { query: GetSidePanelPetParentProfileDocument, variables: variables }
    }
export const UpdateClinicPetWithoutPimsDocument = gql`
    mutation updateClinicPetWithoutPims($where: ClinicPetWhereUniqueInput!, $data: ClinicPetUpdateInput!) {
  updateClinicPetWithoutPims(where: $where, data: $data) {
    id
    dateOfDeath
    ...PetAvatar
  }
}
    ${PetAvatarFragmentDoc}`;
export type UpdateClinicPetWithoutPimsMutationFn = Apollo.MutationFunction<UpdateClinicPetWithoutPimsMutation, UpdateClinicPetWithoutPimsMutationVariables>;

/**
 * __useUpdateClinicPetWithoutPimsMutation__
 *
 * To run a mutation, you first call `useUpdateClinicPetWithoutPimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicPetWithoutPimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicPetWithoutPimsMutation, { data, loading, error }] = useUpdateClinicPetWithoutPimsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClinicPetWithoutPimsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicPetWithoutPimsMutation, UpdateClinicPetWithoutPimsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicPetWithoutPimsMutation, UpdateClinicPetWithoutPimsMutationVariables>(UpdateClinicPetWithoutPimsDocument, options);
      }
export type UpdateClinicPetWithoutPimsMutationHookResult = ReturnType<typeof useUpdateClinicPetWithoutPimsMutation>;
export type UpdateClinicPetWithoutPimsMutationResult = Apollo.MutationResult<UpdateClinicPetWithoutPimsMutation>;
export type UpdateClinicPetWithoutPimsMutationOptions = Apollo.BaseMutationOptions<UpdateClinicPetWithoutPimsMutation, UpdateClinicPetWithoutPimsMutationVariables>;
export const UpdateOneClinicPetDocument = gql`
    mutation updateOneClinicPet($where: ClinicPetWhereUniqueInput!, $data: ClinicPetUpdateInput!) {
  updateOneClinicPet(where: $where, data: $data) {
    dateOfDeath
    ...PetAvatar
  }
}
    ${PetAvatarFragmentDoc}`;
export type UpdateOneClinicPetMutationFn = Apollo.MutationFunction<UpdateOneClinicPetMutation, UpdateOneClinicPetMutationVariables>;

/**
 * __useUpdateOneClinicPetMutation__
 *
 * To run a mutation, you first call `useUpdateOneClinicPetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClinicPetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClinicPetMutation, { data, loading, error }] = useUpdateOneClinicPetMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneClinicPetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClinicPetMutation, UpdateOneClinicPetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClinicPetMutation, UpdateOneClinicPetMutationVariables>(UpdateOneClinicPetDocument, options);
      }
export type UpdateOneClinicPetMutationHookResult = ReturnType<typeof useUpdateOneClinicPetMutation>;
export type UpdateOneClinicPetMutationResult = Apollo.MutationResult<UpdateOneClinicPetMutation>;
export type UpdateOneClinicPetMutationOptions = Apollo.BaseMutationOptions<UpdateOneClinicPetMutation, UpdateOneClinicPetMutationVariables>;
export const GetSidePanelPetProfileDocument = gql`
    query getSidePanelPetProfile($clinicPetId: String) {
  clinicPet: findUniqueClinicPet(where: {id: $clinicPetId}) {
    dateOfBirth
    dateOfDeath
    breed
    color
    gender
    weight
    weightUnit
    pimsId
    isDeleted
    ...PetAvatar
    alerts {
      value
      pimsDate
      pimsId
      isMedical
    }
    petParents(where: {isActive: {equals: true}, isDeleted: {equals: false}}) {
      id
      firstName
      lastName
      ...PetParentAvatar
    }
    serviceReminders(
      where: {AND: [{isDeleted: {equals: false}}, {service: {isDeleted: {equals: false}}}]}
    ) {
      ...SidePanelPetServiceReminders
    }
    ...OrganizationPetCareBadgePlanEnrollment
  }
}
    ${PetAvatarFragmentDoc}
${PetParentAvatarFragmentDoc}
${SidePanelPetServiceRemindersFragmentDoc}
${OrganizationPetCareBadgePlanEnrollmentFragmentDoc}`;

/**
 * __useGetSidePanelPetProfileQuery__
 *
 * To run a query within a React component, call `useGetSidePanelPetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSidePanelPetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSidePanelPetProfileQuery({
 *   variables: {
 *      clinicPetId: // value for 'clinicPetId'
 *   },
 * });
 */
export function useGetSidePanelPetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetSidePanelPetProfileQuery, GetSidePanelPetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSidePanelPetProfileQuery, GetSidePanelPetProfileQueryVariables>(GetSidePanelPetProfileDocument, options);
      }
export function useGetSidePanelPetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSidePanelPetProfileQuery, GetSidePanelPetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSidePanelPetProfileQuery, GetSidePanelPetProfileQueryVariables>(GetSidePanelPetProfileDocument, options);
        }
export type GetSidePanelPetProfileQueryHookResult = ReturnType<typeof useGetSidePanelPetProfileQuery>;
export type GetSidePanelPetProfileLazyQueryHookResult = ReturnType<typeof useGetSidePanelPetProfileLazyQuery>;
export type GetSidePanelPetProfileQueryResult = Apollo.QueryResult<GetSidePanelPetProfileQuery, GetSidePanelPetProfileQueryVariables>;
export function refetchGetSidePanelPetProfileQuery(variables?: GetSidePanelPetProfileQueryVariables) {
      return { query: GetSidePanelPetProfileDocument, variables: variables }
    }
export const SidePanelSearchDocument = gql`
    query sidePanelSearch($whereInput: SearchClinicPetParentInput, $pageSize: Int, $petWhere: ClinicPetWhereInput) {
  clinicPetParentSearch(where: $whereInput, take: $pageSize) {
    ...SidePanelSearchClinicPetParent
  }
}
    ${SidePanelSearchClinicPetParentFragmentDoc}`;

/**
 * __useSidePanelSearchQuery__
 *
 * To run a query within a React component, call `useSidePanelSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidePanelSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidePanelSearchQuery({
 *   variables: {
 *      whereInput: // value for 'whereInput'
 *      pageSize: // value for 'pageSize'
 *      petWhere: // value for 'petWhere'
 *   },
 * });
 */
export function useSidePanelSearchQuery(baseOptions?: Apollo.QueryHookOptions<SidePanelSearchQuery, SidePanelSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidePanelSearchQuery, SidePanelSearchQueryVariables>(SidePanelSearchDocument, options);
      }
export function useSidePanelSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidePanelSearchQuery, SidePanelSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidePanelSearchQuery, SidePanelSearchQueryVariables>(SidePanelSearchDocument, options);
        }
export type SidePanelSearchQueryHookResult = ReturnType<typeof useSidePanelSearchQuery>;
export type SidePanelSearchLazyQueryHookResult = ReturnType<typeof useSidePanelSearchLazyQuery>;
export type SidePanelSearchQueryResult = Apollo.QueryResult<SidePanelSearchQuery, SidePanelSearchQueryVariables>;
export function refetchSidePanelSearchQuery(variables?: SidePanelSearchQueryVariables) {
      return { query: SidePanelSearchDocument, variables: variables }
    }
export const CheckForExistingClinicPetParentDocument = gql`
    query checkForExistingClinicPetParent($where: ClinicPetParentWhereInput!) {
  findManyClinicPetParent(where: $where) {
    ...ExistingClinicPetParentMatch
  }
}
    ${ExistingClinicPetParentMatchFragmentDoc}`;

/**
 * __useCheckForExistingClinicPetParentQuery__
 *
 * To run a query within a React component, call `useCheckForExistingClinicPetParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForExistingClinicPetParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForExistingClinicPetParentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheckForExistingClinicPetParentQuery(baseOptions: Apollo.QueryHookOptions<CheckForExistingClinicPetParentQuery, CheckForExistingClinicPetParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForExistingClinicPetParentQuery, CheckForExistingClinicPetParentQueryVariables>(CheckForExistingClinicPetParentDocument, options);
      }
export function useCheckForExistingClinicPetParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForExistingClinicPetParentQuery, CheckForExistingClinicPetParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForExistingClinicPetParentQuery, CheckForExistingClinicPetParentQueryVariables>(CheckForExistingClinicPetParentDocument, options);
        }
export type CheckForExistingClinicPetParentQueryHookResult = ReturnType<typeof useCheckForExistingClinicPetParentQuery>;
export type CheckForExistingClinicPetParentLazyQueryHookResult = ReturnType<typeof useCheckForExistingClinicPetParentLazyQuery>;
export type CheckForExistingClinicPetParentQueryResult = Apollo.QueryResult<CheckForExistingClinicPetParentQuery, CheckForExistingClinicPetParentQueryVariables>;
export function refetchCheckForExistingClinicPetParentQuery(variables?: CheckForExistingClinicPetParentQueryVariables) {
      return { query: CheckForExistingClinicPetParentDocument, variables: variables }
    }
export const AddChannelMemberDocument = gql`
    mutation addChannelMember($channelId: String!, $clinicPetParentId: String, $userId: String) {
  addChannelMember: addChannelMember2(
    channelId: $channelId
    clinicPetParentId: $clinicPetParentId
    userId: $userId
  ) {
    id
    removedAt
    clinicPetParent {
      id
    }
    user {
      id
    }
  }
}
    `;
export type AddChannelMemberMutationFn = Apollo.MutationFunction<AddChannelMemberMutation, AddChannelMemberMutationVariables>;

/**
 * __useAddChannelMemberMutation__
 *
 * To run a mutation, you first call `useAddChannelMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChannelMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChannelMemberMutation, { data, loading, error }] = useAddChannelMemberMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddChannelMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddChannelMemberMutation, AddChannelMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChannelMemberMutation, AddChannelMemberMutationVariables>(AddChannelMemberDocument, options);
      }
export type AddChannelMemberMutationHookResult = ReturnType<typeof useAddChannelMemberMutation>;
export type AddChannelMemberMutationResult = Apollo.MutationResult<AddChannelMemberMutation>;
export type AddChannelMemberMutationOptions = Apollo.BaseMutationOptions<AddChannelMemberMutation, AddChannelMemberMutationVariables>;
export const AddClinicFeatureFlagDocument = gql`
    mutation addClinicFeatureFlag($clinicId: String!, $featureFlagKey: String!) {
  updateOneClinic(
    where: {id: $clinicId}
    data: {featureFlags: {connect: {key: $featureFlagKey}}}
  ) {
    id
    featureFlags {
      id
      key
    }
  }
}
    `;
export type AddClinicFeatureFlagMutationFn = Apollo.MutationFunction<AddClinicFeatureFlagMutation, AddClinicFeatureFlagMutationVariables>;

/**
 * __useAddClinicFeatureFlagMutation__
 *
 * To run a mutation, you first call `useAddClinicFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClinicFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClinicFeatureFlagMutation, { data, loading, error }] = useAddClinicFeatureFlagMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      featureFlagKey: // value for 'featureFlagKey'
 *   },
 * });
 */
export function useAddClinicFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<AddClinicFeatureFlagMutation, AddClinicFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClinicFeatureFlagMutation, AddClinicFeatureFlagMutationVariables>(AddClinicFeatureFlagDocument, options);
      }
export type AddClinicFeatureFlagMutationHookResult = ReturnType<typeof useAddClinicFeatureFlagMutation>;
export type AddClinicFeatureFlagMutationResult = Apollo.MutationResult<AddClinicFeatureFlagMutation>;
export type AddClinicFeatureFlagMutationOptions = Apollo.BaseMutationOptions<AddClinicFeatureFlagMutation, AddClinicFeatureFlagMutationVariables>;
export const CancelStripePaymentIntentDocument = gql`
    mutation cancelStripePaymentIntent($where: StripePaymentIntentWhereUniqueInput!) {
  cancelStripePaymentIntent2(where: $where) {
    id
    status
  }
}
    `;
export type CancelStripePaymentIntentMutationFn = Apollo.MutationFunction<CancelStripePaymentIntentMutation, CancelStripePaymentIntentMutationVariables>;

/**
 * __useCancelStripePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCancelStripePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripePaymentIntentMutation, { data, loading, error }] = useCancelStripePaymentIntentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCancelStripePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CancelStripePaymentIntentMutation, CancelStripePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelStripePaymentIntentMutation, CancelStripePaymentIntentMutationVariables>(CancelStripePaymentIntentDocument, options);
      }
export type CancelStripePaymentIntentMutationHookResult = ReturnType<typeof useCancelStripePaymentIntentMutation>;
export type CancelStripePaymentIntentMutationResult = Apollo.MutationResult<CancelStripePaymentIntentMutation>;
export type CancelStripePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CancelStripePaymentIntentMutation, CancelStripePaymentIntentMutationVariables>;
export const ClearTerminalDisplayDocument = gql`
    mutation clearTerminalDisplay($stripeTerminalId: String!) {
  clearTerminalDisplay(stripeTerminalId: $stripeTerminalId) {
    success
    error
  }
}
    `;
export type ClearTerminalDisplayMutationFn = Apollo.MutationFunction<ClearTerminalDisplayMutation, ClearTerminalDisplayMutationVariables>;

/**
 * __useClearTerminalDisplayMutation__
 *
 * To run a mutation, you first call `useClearTerminalDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTerminalDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTerminalDisplayMutation, { data, loading, error }] = useClearTerminalDisplayMutation({
 *   variables: {
 *      stripeTerminalId: // value for 'stripeTerminalId'
 *   },
 * });
 */
export function useClearTerminalDisplayMutation(baseOptions?: Apollo.MutationHookOptions<ClearTerminalDisplayMutation, ClearTerminalDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearTerminalDisplayMutation, ClearTerminalDisplayMutationVariables>(ClearTerminalDisplayDocument, options);
      }
export type ClearTerminalDisplayMutationHookResult = ReturnType<typeof useClearTerminalDisplayMutation>;
export type ClearTerminalDisplayMutationResult = Apollo.MutationResult<ClearTerminalDisplayMutation>;
export type ClearTerminalDisplayMutationOptions = Apollo.BaseMutationOptions<ClearTerminalDisplayMutation, ClearTerminalDisplayMutationVariables>;
export const ClearUserInvitationDocument = gql`
    mutation clearUserInvitation($userId: String!) {
  updateOneUser(where: {id: $userId}, data: {invitationCode: null}) {
    id
    invitationCode
  }
}
    `;
export type ClearUserInvitationMutationFn = Apollo.MutationFunction<ClearUserInvitationMutation, ClearUserInvitationMutationVariables>;

/**
 * __useClearUserInvitationMutation__
 *
 * To run a mutation, you first call `useClearUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUserInvitationMutation, { data, loading, error }] = useClearUserInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useClearUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ClearUserInvitationMutation, ClearUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearUserInvitationMutation, ClearUserInvitationMutationVariables>(ClearUserInvitationDocument, options);
      }
export type ClearUserInvitationMutationHookResult = ReturnType<typeof useClearUserInvitationMutation>;
export type ClearUserInvitationMutationResult = Apollo.MutationResult<ClearUserInvitationMutation>;
export type ClearUserInvitationMutationOptions = Apollo.BaseMutationOptions<ClearUserInvitationMutation, ClearUserInvitationMutationVariables>;
export const CreateFormSubmissionDocument = gql`
    mutation createFormSubmission($data: FormSubmissionCreateInput!) {
  createOneFormSubmission(data: $data) {
    id
  }
}
    `;
export type CreateFormSubmissionMutationFn = Apollo.MutationFunction<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>;

/**
 * __useCreateFormSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormSubmissionMutation, { data, loading, error }] = useCreateFormSubmissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFormSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>(CreateFormSubmissionDocument, options);
      }
export type CreateFormSubmissionMutationHookResult = ReturnType<typeof useCreateFormSubmissionMutation>;
export type CreateFormSubmissionMutationResult = Apollo.MutationResult<CreateFormSubmissionMutation>;
export type CreateFormSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateFormSubmissionMutation, CreateFormSubmissionMutationVariables>;
export const CreateStripePaymentIntentDocument = gql`
    mutation createStripePaymentIntent($data: StripeCreatePaymentInput!, $clinicPetParentId: String, $channelId: String, $paymentMedium: PaymentMedium, $stripeTerminalStripeId: String, $invoiceIds: [String!], $paymentRelationship: PaymentRelationship, $emailOverride: String, $careAccountCreditIntentId: String, $cardType: CardFunding) {
  createStripePaymentIntent2(
    data: $data
    clinicPetParentId: $clinicPetParentId
    channelId: $channelId
    paymentMedium: $paymentMedium
    invoiceIds: $invoiceIds
    paymentRelationship: $paymentRelationship
    stripeTerminalStripeId: $stripeTerminalStripeId
    emailOverride: $emailOverride
    careAccountCreditIntentId: $careAccountCreditIntentId
    cardType: $cardType
  ) {
    ...StripePaymentIntentSelection
  }
}
    ${StripePaymentIntentSelectionFragmentDoc}`;
export type CreateStripePaymentIntentMutationFn = Apollo.MutationFunction<CreateStripePaymentIntentMutation, CreateStripePaymentIntentMutationVariables>;

/**
 * __useCreateStripePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreateStripePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripePaymentIntentMutation, { data, loading, error }] = useCreateStripePaymentIntentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      clinicPetParentId: // value for 'clinicPetParentId'
 *      channelId: // value for 'channelId'
 *      paymentMedium: // value for 'paymentMedium'
 *      stripeTerminalStripeId: // value for 'stripeTerminalStripeId'
 *      invoiceIds: // value for 'invoiceIds'
 *      paymentRelationship: // value for 'paymentRelationship'
 *      emailOverride: // value for 'emailOverride'
 *      careAccountCreditIntentId: // value for 'careAccountCreditIntentId'
 *      cardType: // value for 'cardType'
 *   },
 * });
 */
export function useCreateStripePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripePaymentIntentMutation, CreateStripePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripePaymentIntentMutation, CreateStripePaymentIntentMutationVariables>(CreateStripePaymentIntentDocument, options);
      }
export type CreateStripePaymentIntentMutationHookResult = ReturnType<typeof useCreateStripePaymentIntentMutation>;
export type CreateStripePaymentIntentMutationResult = Apollo.MutationResult<CreateStripePaymentIntentMutation>;
export type CreateStripePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreateStripePaymentIntentMutation, CreateStripePaymentIntentMutationVariables>;
export const CreateSystemChannelMessageDocument = gql`
    mutation createSystemChannelMessage($data: SystemMessageCreateInput!) {
  createSystemChannelMessage: createSystemChannelMessage2(data: $data) {
    id
    createdAt
    updatedAt
    index
    body
    messageType
    attachments {
      id
      attachmentType
      entityType
      entityId
      filename
      url
    }
    author {
      id
      twilioIdentity
      user {
        id
        firstName
        lastName
        profilePic
      }
      clinicPetParent {
        id
        firstName
        lastName
      }
    }
    channel {
      id
    }
  }
}
    `;
export type CreateSystemChannelMessageMutationFn = Apollo.MutationFunction<CreateSystemChannelMessageMutation, CreateSystemChannelMessageMutationVariables>;

/**
 * __useCreateSystemChannelMessageMutation__
 *
 * To run a mutation, you first call `useCreateSystemChannelMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemChannelMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemChannelMessageMutation, { data, loading, error }] = useCreateSystemChannelMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemChannelMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemChannelMessageMutation, CreateSystemChannelMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSystemChannelMessageMutation, CreateSystemChannelMessageMutationVariables>(CreateSystemChannelMessageDocument, options);
      }
export type CreateSystemChannelMessageMutationHookResult = ReturnType<typeof useCreateSystemChannelMessageMutation>;
export type CreateSystemChannelMessageMutationResult = Apollo.MutationResult<CreateSystemChannelMessageMutation>;
export type CreateSystemChannelMessageMutationOptions = Apollo.BaseMutationOptions<CreateSystemChannelMessageMutation, CreateSystemChannelMessageMutationVariables>;
export const GetOrCreateActiveClientChannelDocument = gql`
    mutation getOrCreateActiveClientChannel($data: GetOrCreateActiveClientChannelInput!) {
  getOrCreateActiveClientChannel(data: $data) {
    id
  }
}
    `;
export type GetOrCreateActiveClientChannelMutationFn = Apollo.MutationFunction<GetOrCreateActiveClientChannelMutation, GetOrCreateActiveClientChannelMutationVariables>;

/**
 * __useGetOrCreateActiveClientChannelMutation__
 *
 * To run a mutation, you first call `useGetOrCreateActiveClientChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateActiveClientChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateActiveClientChannelMutation, { data, loading, error }] = useGetOrCreateActiveClientChannelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOrCreateActiveClientChannelMutation(baseOptions?: Apollo.MutationHookOptions<GetOrCreateActiveClientChannelMutation, GetOrCreateActiveClientChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetOrCreateActiveClientChannelMutation, GetOrCreateActiveClientChannelMutationVariables>(GetOrCreateActiveClientChannelDocument, options);
      }
export type GetOrCreateActiveClientChannelMutationHookResult = ReturnType<typeof useGetOrCreateActiveClientChannelMutation>;
export type GetOrCreateActiveClientChannelMutationResult = Apollo.MutationResult<GetOrCreateActiveClientChannelMutation>;
export type GetOrCreateActiveClientChannelMutationOptions = Apollo.BaseMutationOptions<GetOrCreateActiveClientChannelMutation, GetOrCreateActiveClientChannelMutationVariables>;
export const ProcessTerminalPaymentDocument = gql`
    mutation processTerminalPayment($stripeTerminalId: String!, $paymentIntent: PaymentIntentInput!) {
  processTerminalPayment(
    stripeTerminalId: $stripeTerminalId
    paymentIntent: $paymentIntent
  ) {
    success
    stripePaymentIntentId
    error
  }
}
    `;
export type ProcessTerminalPaymentMutationFn = Apollo.MutationFunction<ProcessTerminalPaymentMutation, ProcessTerminalPaymentMutationVariables>;

/**
 * __useProcessTerminalPaymentMutation__
 *
 * To run a mutation, you first call `useProcessTerminalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessTerminalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processTerminalPaymentMutation, { data, loading, error }] = useProcessTerminalPaymentMutation({
 *   variables: {
 *      stripeTerminalId: // value for 'stripeTerminalId'
 *      paymentIntent: // value for 'paymentIntent'
 *   },
 * });
 */
export function useProcessTerminalPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ProcessTerminalPaymentMutation, ProcessTerminalPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessTerminalPaymentMutation, ProcessTerminalPaymentMutationVariables>(ProcessTerminalPaymentDocument, options);
      }
export type ProcessTerminalPaymentMutationHookResult = ReturnType<typeof useProcessTerminalPaymentMutation>;
export type ProcessTerminalPaymentMutationResult = Apollo.MutationResult<ProcessTerminalPaymentMutation>;
export type ProcessTerminalPaymentMutationOptions = Apollo.BaseMutationOptions<ProcessTerminalPaymentMutation, ProcessTerminalPaymentMutationVariables>;
export const RefundStripePaymentIntentDocument = gql`
    mutation refundStripePaymentIntent($where: StripePaymentIntentWhereUniqueInput!, $data: EnhancedStripeRefundPaymentInput!) {
  refundStripePaymentIntent2(data: $data, where: $where) {
    id
  }
}
    `;
export type RefundStripePaymentIntentMutationFn = Apollo.MutationFunction<RefundStripePaymentIntentMutation, RefundStripePaymentIntentMutationVariables>;

/**
 * __useRefundStripePaymentIntentMutation__
 *
 * To run a mutation, you first call `useRefundStripePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundStripePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundStripePaymentIntentMutation, { data, loading, error }] = useRefundStripePaymentIntentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefundStripePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<RefundStripePaymentIntentMutation, RefundStripePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundStripePaymentIntentMutation, RefundStripePaymentIntentMutationVariables>(RefundStripePaymentIntentDocument, options);
      }
export type RefundStripePaymentIntentMutationHookResult = ReturnType<typeof useRefundStripePaymentIntentMutation>;
export type RefundStripePaymentIntentMutationResult = Apollo.MutationResult<RefundStripePaymentIntentMutation>;
export type RefundStripePaymentIntentMutationOptions = Apollo.BaseMutationOptions<RefundStripePaymentIntentMutation, RefundStripePaymentIntentMutationVariables>;
export const RemoveChannelMemberDocument = gql`
    mutation removeChannelMember($channelMemberId: String!) {
  removeChannelMember: removeChannelMember2(channelMemberId: $channelMemberId) {
    id
    removedAt
    clinicPetParent {
      id
    }
    user {
      id
    }
  }
}
    `;
export type RemoveChannelMemberMutationFn = Apollo.MutationFunction<RemoveChannelMemberMutation, RemoveChannelMemberMutationVariables>;

/**
 * __useRemoveChannelMemberMutation__
 *
 * To run a mutation, you first call `useRemoveChannelMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelMemberMutation, { data, loading, error }] = useRemoveChannelMemberMutation({
 *   variables: {
 *      channelMemberId: // value for 'channelMemberId'
 *   },
 * });
 */
export function useRemoveChannelMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelMemberMutation, RemoveChannelMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChannelMemberMutation, RemoveChannelMemberMutationVariables>(RemoveChannelMemberDocument, options);
      }
export type RemoveChannelMemberMutationHookResult = ReturnType<typeof useRemoveChannelMemberMutation>;
export type RemoveChannelMemberMutationResult = Apollo.MutationResult<RemoveChannelMemberMutation>;
export type RemoveChannelMemberMutationOptions = Apollo.BaseMutationOptions<RemoveChannelMemberMutation, RemoveChannelMemberMutationVariables>;
export const RemoveClinicFeatureFlagDocument = gql`
    mutation removeClinicFeatureFlag($clinicId: String!, $featureFlagKey: String!) {
  updateOneClinic(
    where: {id: $clinicId}
    data: {featureFlags: {disconnect: {key: $featureFlagKey}}}
  ) {
    id
    featureFlags {
      id
      key
    }
  }
}
    `;
export type RemoveClinicFeatureFlagMutationFn = Apollo.MutationFunction<RemoveClinicFeatureFlagMutation, RemoveClinicFeatureFlagMutationVariables>;

/**
 * __useRemoveClinicFeatureFlagMutation__
 *
 * To run a mutation, you first call `useRemoveClinicFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClinicFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClinicFeatureFlagMutation, { data, loading, error }] = useRemoveClinicFeatureFlagMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      featureFlagKey: // value for 'featureFlagKey'
 *   },
 * });
 */
export function useRemoveClinicFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClinicFeatureFlagMutation, RemoveClinicFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClinicFeatureFlagMutation, RemoveClinicFeatureFlagMutationVariables>(RemoveClinicFeatureFlagDocument, options);
      }
export type RemoveClinicFeatureFlagMutationHookResult = ReturnType<typeof useRemoveClinicFeatureFlagMutation>;
export type RemoveClinicFeatureFlagMutationResult = Apollo.MutationResult<RemoveClinicFeatureFlagMutation>;
export type RemoveClinicFeatureFlagMutationOptions = Apollo.BaseMutationOptions<RemoveClinicFeatureFlagMutation, RemoveClinicFeatureFlagMutationVariables>;
export const UpdateCurrentClinicDocument = gql`
    mutation updateCurrentClinic($vetInfoId: String!, $clinicId: String!) {
  updateOneVetInfo(
    where: {id: $vetInfoId}
    data: {currentClinic: {connect: {id: $clinicId}}}
  ) {
    ...ClinicUserVetInfo
  }
}
    ${ClinicUserVetInfoFragmentDoc}`;
export type UpdateCurrentClinicMutationFn = Apollo.MutationFunction<UpdateCurrentClinicMutation, UpdateCurrentClinicMutationVariables>;

/**
 * __useUpdateCurrentClinicMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentClinicMutation, { data, loading, error }] = useUpdateCurrentClinicMutation({
 *   variables: {
 *      vetInfoId: // value for 'vetInfoId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useUpdateCurrentClinicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentClinicMutation, UpdateCurrentClinicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentClinicMutation, UpdateCurrentClinicMutationVariables>(UpdateCurrentClinicDocument, options);
      }
export type UpdateCurrentClinicMutationHookResult = ReturnType<typeof useUpdateCurrentClinicMutation>;
export type UpdateCurrentClinicMutationResult = Apollo.MutationResult<UpdateCurrentClinicMutation>;
export type UpdateCurrentClinicMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentClinicMutation, UpdateCurrentClinicMutationVariables>;
export const UpdateLeftNavPanelToggleDocument = gql`
    mutation updateLeftNavPanelToggle($data: UserSettingUpdateInput!, $where: UserSettingWhereUniqueInput!) {
  updateOneUserSetting(data: $data, where: $where) {
    leftNavPanel
  }
}
    `;
export type UpdateLeftNavPanelToggleMutationFn = Apollo.MutationFunction<UpdateLeftNavPanelToggleMutation, UpdateLeftNavPanelToggleMutationVariables>;

/**
 * __useUpdateLeftNavPanelToggleMutation__
 *
 * To run a mutation, you first call `useUpdateLeftNavPanelToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeftNavPanelToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeftNavPanelToggleMutation, { data, loading, error }] = useUpdateLeftNavPanelToggleMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLeftNavPanelToggleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeftNavPanelToggleMutation, UpdateLeftNavPanelToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeftNavPanelToggleMutation, UpdateLeftNavPanelToggleMutationVariables>(UpdateLeftNavPanelToggleDocument, options);
      }
export type UpdateLeftNavPanelToggleMutationHookResult = ReturnType<typeof useUpdateLeftNavPanelToggleMutation>;
export type UpdateLeftNavPanelToggleMutationResult = Apollo.MutationResult<UpdateLeftNavPanelToggleMutation>;
export type UpdateLeftNavPanelToggleMutationOptions = Apollo.BaseMutationOptions<UpdateLeftNavPanelToggleMutation, UpdateLeftNavPanelToggleMutationVariables>;
export const UpdateOneClinicPetParentDocument = gql`
    mutation updateOneClinicPetParent($where: ClinicPetParentWhereUniqueInput!, $data: ClinicPetParentUpdateInput!) {
  updateOneClinicPetParent(where: $where, data: $data) {
    ...ClinicPetParentSelection
  }
}
    ${ClinicPetParentSelectionFragmentDoc}`;
export type UpdateOneClinicPetParentMutationFn = Apollo.MutationFunction<UpdateOneClinicPetParentMutation, UpdateOneClinicPetParentMutationVariables>;

/**
 * __useUpdateOneClinicPetParentMutation__
 *
 * To run a mutation, you first call `useUpdateOneClinicPetParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClinicPetParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClinicPetParentMutation, { data, loading, error }] = useUpdateOneClinicPetParentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneClinicPetParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClinicPetParentMutation, UpdateOneClinicPetParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClinicPetParentMutation, UpdateOneClinicPetParentMutationVariables>(UpdateOneClinicPetParentDocument, options);
      }
export type UpdateOneClinicPetParentMutationHookResult = ReturnType<typeof useUpdateOneClinicPetParentMutation>;
export type UpdateOneClinicPetParentMutationResult = Apollo.MutationResult<UpdateOneClinicPetParentMutation>;
export type UpdateOneClinicPetParentMutationOptions = Apollo.BaseMutationOptions<UpdateOneClinicPetParentMutation, UpdateOneClinicPetParentMutationVariables>;
export const UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupDocument = gql`
    mutation updateOneClinicPetParentAndEnqueuePhoneNumberLookup($where: ClinicPetParentWhereUniqueInput!, $data: ClinicPetParentUpdateInput!) {
  updateOneClinicPetParentAndEnqueuePhoneNumberLookup(where: $where, data: $data) {
    ...ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutation
  }
}
    ${ClinicPetParentSelectionWithEnqueuePhoneNumberLookupMutationFragmentDoc}`;
export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationFn = Apollo.MutationFunction<UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation, UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationVariables>;

/**
 * __useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation__
 *
 * To run a mutation, you first call `useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation, { data, loading, error }] = useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation, UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation, UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationVariables>(UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupDocument, options);
      }
export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationHookResult = ReturnType<typeof useUpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation>;
export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationResult = Apollo.MutationResult<UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation>;
export type UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationOptions = Apollo.BaseMutationOptions<UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutation, UpdateOneClinicPetParentAndEnqueuePhoneNumberLookupMutationVariables>;
export const GetAllClinicChannelStatusesDocument = gql`
    query getAllClinicChannelStatuses($clinicId: String!) {
  findManyChannelStatus(
    where: {clinicSetting: {clinic: {id: {equals: $clinicId}}}}
    orderBy: {order: asc}
  ) {
    ...FullChannelStatus
  }
}
    ${FullChannelStatusFragmentDoc}`;

/**
 * __useGetAllClinicChannelStatusesQuery__
 *
 * To run a query within a React component, call `useGetAllClinicChannelStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllClinicChannelStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllClinicChannelStatusesQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetAllClinicChannelStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetAllClinicChannelStatusesQuery, GetAllClinicChannelStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllClinicChannelStatusesQuery, GetAllClinicChannelStatusesQueryVariables>(GetAllClinicChannelStatusesDocument, options);
      }
export function useGetAllClinicChannelStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllClinicChannelStatusesQuery, GetAllClinicChannelStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllClinicChannelStatusesQuery, GetAllClinicChannelStatusesQueryVariables>(GetAllClinicChannelStatusesDocument, options);
        }
export type GetAllClinicChannelStatusesQueryHookResult = ReturnType<typeof useGetAllClinicChannelStatusesQuery>;
export type GetAllClinicChannelStatusesLazyQueryHookResult = ReturnType<typeof useGetAllClinicChannelStatusesLazyQuery>;
export type GetAllClinicChannelStatusesQueryResult = Apollo.QueryResult<GetAllClinicChannelStatusesQuery, GetAllClinicChannelStatusesQueryVariables>;
export function refetchGetAllClinicChannelStatusesQuery(variables?: GetAllClinicChannelStatusesQueryVariables) {
      return { query: GetAllClinicChannelStatusesDocument, variables: variables }
    }
export const GetClinicFormTemplatesDocument = gql`
    query getClinicFormTemplates($where: FormTemplateWhereInput!) {
  findManyFormTemplate(where: $where) {
    ...ClinicFormTemplate
  }
}
    ${ClinicFormTemplateFragmentDoc}`;

/**
 * __useGetClinicFormTemplatesQuery__
 *
 * To run a query within a React component, call `useGetClinicFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicFormTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClinicFormTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetClinicFormTemplatesQuery, GetClinicFormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicFormTemplatesQuery, GetClinicFormTemplatesQueryVariables>(GetClinicFormTemplatesDocument, options);
      }
export function useGetClinicFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicFormTemplatesQuery, GetClinicFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicFormTemplatesQuery, GetClinicFormTemplatesQueryVariables>(GetClinicFormTemplatesDocument, options);
        }
export type GetClinicFormTemplatesQueryHookResult = ReturnType<typeof useGetClinicFormTemplatesQuery>;
export type GetClinicFormTemplatesLazyQueryHookResult = ReturnType<typeof useGetClinicFormTemplatesLazyQuery>;
export type GetClinicFormTemplatesQueryResult = Apollo.QueryResult<GetClinicFormTemplatesQuery, GetClinicFormTemplatesQueryVariables>;
export function refetchGetClinicFormTemplatesQuery(variables?: GetClinicFormTemplatesQueryVariables) {
      return { query: GetClinicFormTemplatesDocument, variables: variables }
    }
export const GetClinicUserDocument = gql`
    query getClinicUser {
  clinicUser: getCurrentUser {
    ...ClinicUser
  }
}
    ${ClinicUserFragmentDoc}`;

/**
 * __useGetClinicUserQuery__
 *
 * To run a query within a React component, call `useGetClinicUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClinicUserQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicUserQuery, GetClinicUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicUserQuery, GetClinicUserQueryVariables>(GetClinicUserDocument, options);
      }
export function useGetClinicUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicUserQuery, GetClinicUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicUserQuery, GetClinicUserQueryVariables>(GetClinicUserDocument, options);
        }
export type GetClinicUserQueryHookResult = ReturnType<typeof useGetClinicUserQuery>;
export type GetClinicUserLazyQueryHookResult = ReturnType<typeof useGetClinicUserLazyQuery>;
export type GetClinicUserQueryResult = Apollo.QueryResult<GetClinicUserQuery, GetClinicUserQueryVariables>;
export function refetchGetClinicUserQuery(variables?: GetClinicUserQueryVariables) {
      return { query: GetClinicUserDocument, variables: variables }
    }
export const GetClinicUsersDocument = gql`
    query getClinicUsers($clinicId: String!) {
  users: findManyUser(
    where: {vetInfo: {clinics: {some: {id: {equals: $clinicId}}}}, isActive: {equals: true}}
  ) {
    firebaseId
    email
    ...ClinicUserAvatar
  }
}
    ${ClinicUserAvatarFragmentDoc}`;

/**
 * __useGetClinicUsersQuery__
 *
 * To run a query within a React component, call `useGetClinicUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicUsersQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicUsersQuery(baseOptions: Apollo.QueryHookOptions<GetClinicUsersQuery, GetClinicUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicUsersQuery, GetClinicUsersQueryVariables>(GetClinicUsersDocument, options);
      }
export function useGetClinicUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicUsersQuery, GetClinicUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicUsersQuery, GetClinicUsersQueryVariables>(GetClinicUsersDocument, options);
        }
export type GetClinicUsersQueryHookResult = ReturnType<typeof useGetClinicUsersQuery>;
export type GetClinicUsersLazyQueryHookResult = ReturnType<typeof useGetClinicUsersLazyQuery>;
export type GetClinicUsersQueryResult = Apollo.QueryResult<GetClinicUsersQuery, GetClinicUsersQueryVariables>;
export function refetchGetClinicUsersQuery(variables?: GetClinicUsersQueryVariables) {
      return { query: GetClinicUsersDocument, variables: variables }
    }
export const GetPaginatedAppointmentsDocument = gql`
    query getPaginatedAppointments($where: AppointmentWhereInput!, $take: Int!, $skip: Int!, $orderBy: [AppointmentOrderByWithRelationAndSearchRelevanceInput], $countOnly: Boolean!) {
  count: findManyAppointmentCount(where: $where)
  appointments: findManyAppointment(
    where: $where
    take: $take
    skip: $skip
    orderBy: $orderBy
  ) @skip(if: $countOnly) {
    id
    pimsId
    startAt
    description
    durationInMinutes
    title
    status
    pimsStatus
    channelId
    appointmentType {
      id
      name
      displayName
    }
    channelId
    channel {
      id
      lastMessage {
        id
        createdAt
      }
      updatedAt
      channelStatus {
        ...ChannelListChannelStatus
      }
    }
    clinicPet {
      id
      computed_rawPimsIsDeceased
      computed_rawPimsIsActive
      computedIsDeceased @client
      computedIsActive @client
      isDeceased
      isActive
      name
      breed
      species
      photoUrl
    }
    clinicPetParents {
      id
      firstName
      lastName
      phoneNumbers {
        id
        number
        updatedAt
        isPrimary
        isDeleted
      }
    }
  }
}
    ${ChannelListChannelStatusFragmentDoc}`;

/**
 * __useGetPaginatedAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      countOnly: // value for 'countOnly'
 *   },
 * });
 */
export function useGetPaginatedAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedAppointmentsQuery, GetPaginatedAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedAppointmentsQuery, GetPaginatedAppointmentsQueryVariables>(GetPaginatedAppointmentsDocument, options);
      }
export function useGetPaginatedAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedAppointmentsQuery, GetPaginatedAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedAppointmentsQuery, GetPaginatedAppointmentsQueryVariables>(GetPaginatedAppointmentsDocument, options);
        }
export type GetPaginatedAppointmentsQueryHookResult = ReturnType<typeof useGetPaginatedAppointmentsQuery>;
export type GetPaginatedAppointmentsLazyQueryHookResult = ReturnType<typeof useGetPaginatedAppointmentsLazyQuery>;
export type GetPaginatedAppointmentsQueryResult = Apollo.QueryResult<GetPaginatedAppointmentsQuery, GetPaginatedAppointmentsQueryVariables>;
export function refetchGetPaginatedAppointmentsQuery(variables?: GetPaginatedAppointmentsQueryVariables) {
      return { query: GetPaginatedAppointmentsDocument, variables: variables }
    }
export const GetStripePaymentIntentDocument = gql`
    query getStripePaymentIntent($where: StripePaymentIntentWhereUniqueInput!) {
  stripePaymentIntent: findUniqueStripePaymentIntent(where: $where) {
    ...StripePaymentIntent
  }
  stripePaymentOutput: getStripePaymentIntentData(where: $where) {
    ...StripePaymentOutput
  }
}
    ${StripePaymentIntentFragmentDoc}
${StripePaymentOutputFragmentDoc}`;

/**
 * __useGetStripePaymentIntentQuery__
 *
 * To run a query within a React component, call `useGetStripePaymentIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePaymentIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePaymentIntentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStripePaymentIntentQuery(baseOptions: Apollo.QueryHookOptions<GetStripePaymentIntentQuery, GetStripePaymentIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripePaymentIntentQuery, GetStripePaymentIntentQueryVariables>(GetStripePaymentIntentDocument, options);
      }
export function useGetStripePaymentIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripePaymentIntentQuery, GetStripePaymentIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripePaymentIntentQuery, GetStripePaymentIntentQueryVariables>(GetStripePaymentIntentDocument, options);
        }
export type GetStripePaymentIntentQueryHookResult = ReturnType<typeof useGetStripePaymentIntentQuery>;
export type GetStripePaymentIntentLazyQueryHookResult = ReturnType<typeof useGetStripePaymentIntentLazyQuery>;
export type GetStripePaymentIntentQueryResult = Apollo.QueryResult<GetStripePaymentIntentQuery, GetStripePaymentIntentQueryVariables>;
export function refetchGetStripePaymentIntentQuery(variables?: GetStripePaymentIntentQueryVariables) {
      return { query: GetStripePaymentIntentDocument, variables: variables }
    }
export const SubscribeToStripePaymentIntentChangedDocument = gql`
    subscription subscribeToStripePaymentIntentChanged($where: SubscriptionStripePaymentIntentWhereInput!) {
  stripePaymentIntentChanged(where: $where) {
    node {
      ...StripePaymentIntent
    }
  }
}
    ${StripePaymentIntentFragmentDoc}`;

/**
 * __useSubscribeToStripePaymentIntentChangedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToStripePaymentIntentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToStripePaymentIntentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToStripePaymentIntentChangedSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToStripePaymentIntentChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToStripePaymentIntentChangedSubscription, SubscribeToStripePaymentIntentChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToStripePaymentIntentChangedSubscription, SubscribeToStripePaymentIntentChangedSubscriptionVariables>(SubscribeToStripePaymentIntentChangedDocument, options);
      }
export type SubscribeToStripePaymentIntentChangedSubscriptionHookResult = ReturnType<typeof useSubscribeToStripePaymentIntentChangedSubscription>;
export type SubscribeToStripePaymentIntentChangedSubscriptionResult = Apollo.SubscriptionResult<SubscribeToStripePaymentIntentChangedSubscription>;
export const SubscribeToClinicUserChannelMessagesDocument = gql`
    subscription subscribeToClinicUserChannelMessages($clinicId: String!) {
  channelMessageChanged(
    where: {mutation: [create, delete], node: {channel: {clinicId: {equals: $clinicId}}}}
  ) {
    node {
      ...SubscriptionChannelMessage
    }
    mutation
  }
}
    ${SubscriptionChannelMessageFragmentDoc}`;

/**
 * __useSubscribeToClinicUserChannelMessagesSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClinicUserChannelMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClinicUserChannelMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClinicUserChannelMessagesSubscription({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSubscribeToClinicUserChannelMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToClinicUserChannelMessagesSubscription, SubscribeToClinicUserChannelMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToClinicUserChannelMessagesSubscription, SubscribeToClinicUserChannelMessagesSubscriptionVariables>(SubscribeToClinicUserChannelMessagesDocument, options);
      }
export type SubscribeToClinicUserChannelMessagesSubscriptionHookResult = ReturnType<typeof useSubscribeToClinicUserChannelMessagesSubscription>;
export type SubscribeToClinicUserChannelMessagesSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClinicUserChannelMessagesSubscription>;
export const SubscribeToClinicUserChannelsDocument = gql`
    subscription subscribeToClinicUserChannels($clinicId: String!) {
  channelChanged(where: {node: {clinicId: {equals: $clinicId}}}) {
    node {
      id
      friendlyName
      assignees {
        id
      }
      channelStatus {
        id
        color
        name
        channelStatusAction
      }
    }
  }
}
    `;

/**
 * __useSubscribeToClinicUserChannelsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClinicUserChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClinicUserChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClinicUserChannelsSubscription({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSubscribeToClinicUserChannelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToClinicUserChannelsSubscription, SubscribeToClinicUserChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToClinicUserChannelsSubscription, SubscribeToClinicUserChannelsSubscriptionVariables>(SubscribeToClinicUserChannelsDocument, options);
      }
export type SubscribeToClinicUserChannelsSubscriptionHookResult = ReturnType<typeof useSubscribeToClinicUserChannelsSubscription>;
export type SubscribeToClinicUserChannelsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClinicUserChannelsSubscription>;
export const SubscribeToFormSubmissionDocument = gql`
    subscription subscribeToFormSubmission($id: String!) {
  formSubmissionChanged(where: {node: {id: {equals: $id}}}) {
    node {
      id
      submittedAt
    }
  }
}
    `;

/**
 * __useSubscribeToFormSubmissionSubscription__
 *
 * To run a query within a React component, call `useSubscribeToFormSubmissionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToFormSubmissionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToFormSubmissionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToFormSubmissionSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToFormSubmissionSubscription, SubscribeToFormSubmissionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToFormSubmissionSubscription, SubscribeToFormSubmissionSubscriptionVariables>(SubscribeToFormSubmissionDocument, options);
      }
export type SubscribeToFormSubmissionSubscriptionHookResult = ReturnType<typeof useSubscribeToFormSubmissionSubscription>;
export type SubscribeToFormSubmissionSubscriptionResult = Apollo.SubscriptionResult<SubscribeToFormSubmissionSubscription>;
export const SubscribeToUiRefreshDocument = gql`
    subscription subscribeToUiRefresh {
  uiRefresh {
    refreshType
    message
    metadata
  }
}
    `;

/**
 * __useSubscribeToUiRefreshSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUiRefreshSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUiRefreshSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUiRefreshSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToUiRefreshSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeToUiRefreshSubscription, SubscribeToUiRefreshSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToUiRefreshSubscription, SubscribeToUiRefreshSubscriptionVariables>(SubscribeToUiRefreshDocument, options);
      }
export type SubscribeToUiRefreshSubscriptionHookResult = ReturnType<typeof useSubscribeToUiRefreshSubscription>;
export type SubscribeToUiRefreshSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUiRefreshSubscription>;
export const SubscribeToUnreadMessageCountChangedDocument = gql`
    subscription subscribeToUnreadMessageCountChanged($clinicId: String!) {
  unreadMessageCountChanged(where: {node: {clinicId: {equals: $clinicId}}}) {
    node {
      id
      assignees {
        id
      }
      channelStatus {
        id
        channelStatusAction
      }
    }
  }
}
    `;

/**
 * __useSubscribeToUnreadMessageCountChangedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUnreadMessageCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUnreadMessageCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUnreadMessageCountChangedSubscription({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSubscribeToUnreadMessageCountChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToUnreadMessageCountChangedSubscription, SubscribeToUnreadMessageCountChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToUnreadMessageCountChangedSubscription, SubscribeToUnreadMessageCountChangedSubscriptionVariables>(SubscribeToUnreadMessageCountChangedDocument, options);
      }
export type SubscribeToUnreadMessageCountChangedSubscriptionHookResult = ReturnType<typeof useSubscribeToUnreadMessageCountChangedSubscription>;
export type SubscribeToUnreadMessageCountChangedSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUnreadMessageCountChangedSubscription>;
export const SharedUpdateUserSettingDocument = gql`
    mutation sharedUpdateUserSetting($data: UserSettingUpdateInput!, $where: UserSettingWhereUniqueInput!) {
  updateOneUserSetting(data: $data, where: $where) {
    id
    browserNotificationFilter {
      id
      assignees {
        id
      }
      includeUnassigned
    }
    snoozeNotificationsUntil
    notificationSettings {
      id
      emailEnabled
      smsEnabled
      soundNotificationUrl
      browserNotificationEnabled
      pushNotificationEnabled
    }
  }
}
    `;
export type SharedUpdateUserSettingMutationFn = Apollo.MutationFunction<SharedUpdateUserSettingMutation, SharedUpdateUserSettingMutationVariables>;

/**
 * __useSharedUpdateUserSettingMutation__
 *
 * To run a mutation, you first call `useSharedUpdateUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharedUpdateUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharedUpdateUserSettingMutation, { data, loading, error }] = useSharedUpdateUserSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSharedUpdateUserSettingMutation(baseOptions?: Apollo.MutationHookOptions<SharedUpdateUserSettingMutation, SharedUpdateUserSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SharedUpdateUserSettingMutation, SharedUpdateUserSettingMutationVariables>(SharedUpdateUserSettingDocument, options);
      }
export type SharedUpdateUserSettingMutationHookResult = ReturnType<typeof useSharedUpdateUserSettingMutation>;
export type SharedUpdateUserSettingMutationResult = Apollo.MutationResult<SharedUpdateUserSettingMutation>;
export type SharedUpdateUserSettingMutationOptions = Apollo.BaseMutationOptions<SharedUpdateUserSettingMutation, SharedUpdateUserSettingMutationVariables>;
export const GetMostRecentSyncDataDocument = gql`
    query getMostRecentSyncData($where: IntegrationOutageHistoryEntryWhereInput) {
  findFirstIntegrationOutageHistoryEntry(
    where: $where
    orderBy: {createdAt: desc}
    take: 1
  ) {
    status
    id
    createdAt
  }
}
    `;

/**
 * __useGetMostRecentSyncDataQuery__
 *
 * To run a query within a React component, call `useGetMostRecentSyncDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentSyncDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentSyncDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMostRecentSyncDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMostRecentSyncDataQuery, GetMostRecentSyncDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostRecentSyncDataQuery, GetMostRecentSyncDataQueryVariables>(GetMostRecentSyncDataDocument, options);
      }
export function useGetMostRecentSyncDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostRecentSyncDataQuery, GetMostRecentSyncDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostRecentSyncDataQuery, GetMostRecentSyncDataQueryVariables>(GetMostRecentSyncDataDocument, options);
        }
export type GetMostRecentSyncDataQueryHookResult = ReturnType<typeof useGetMostRecentSyncDataQuery>;
export type GetMostRecentSyncDataLazyQueryHookResult = ReturnType<typeof useGetMostRecentSyncDataLazyQuery>;
export type GetMostRecentSyncDataQueryResult = Apollo.QueryResult<GetMostRecentSyncDataQuery, GetMostRecentSyncDataQueryVariables>;
export function refetchGetMostRecentSyncDataQuery(variables?: GetMostRecentSyncDataQueryVariables) {
      return { query: GetMostRecentSyncDataDocument, variables: variables }
    }
export const SubscribeToSyncOutagesDocument = gql`
    subscription subscribeToSyncOutages($where: IntegrationOutageHistoryEntryWhereInput!) {
  integrationOutageHistoryEntryChanged(where: $where) {
    node {
      id
      createdAt
      status
    }
  }
}
    `;

/**
 * __useSubscribeToSyncOutagesSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSyncOutagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSyncOutagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSyncOutagesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToSyncOutagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToSyncOutagesSubscription, SubscribeToSyncOutagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToSyncOutagesSubscription, SubscribeToSyncOutagesSubscriptionVariables>(SubscribeToSyncOutagesDocument, options);
      }
export type SubscribeToSyncOutagesSubscriptionHookResult = ReturnType<typeof useSubscribeToSyncOutagesSubscription>;
export type SubscribeToSyncOutagesSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSyncOutagesSubscription>;
export const GetTerminalTokenDocument = gql`
    query getTerminalToken($where: StripeTerminalLocationUniqueInput!) {
  getTerminalToken(where: $where) {
    secret
  }
}
    `;

/**
 * __useGetTerminalTokenQuery__
 *
 * To run a query within a React component, call `useGetTerminalTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalTokenQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTerminalTokenQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalTokenQuery, GetTerminalTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTerminalTokenQuery, GetTerminalTokenQueryVariables>(GetTerminalTokenDocument, options);
      }
export function useGetTerminalTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalTokenQuery, GetTerminalTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTerminalTokenQuery, GetTerminalTokenQueryVariables>(GetTerminalTokenDocument, options);
        }
export type GetTerminalTokenQueryHookResult = ReturnType<typeof useGetTerminalTokenQuery>;
export type GetTerminalTokenLazyQueryHookResult = ReturnType<typeof useGetTerminalTokenLazyQuery>;
export type GetTerminalTokenQueryResult = Apollo.QueryResult<GetTerminalTokenQuery, GetTerminalTokenQueryVariables>;
export function refetchGetTerminalTokenQuery(variables?: GetTerminalTokenQueryVariables) {
      return { query: GetTerminalTokenDocument, variables: variables }
    }
export const FindManyStripePayoutDocument = gql`
    query findManyStripePayout($where: StripePayoutWhereInput!) {
  findManyStripePayout(where: $where) {
    id
    createdAt
    updatedAt
    stripeId
    stripePaymentIntents {
      id
    }
    stripeRefunds {
      id
    }
    stripeDisputes {
      id
    }
    stripePayoutAccount {
      id
      last4
      bankName
    }
    amount
    arrivalDate
    initiatedAt
    status
    financialTransactions {
      id
    }
    payoutBatchingPeriod {
      id
      endsAt
    }
  }
}
    `;

/**
 * __useFindManyStripePayoutQuery__
 *
 * To run a query within a React component, call `useFindManyStripePayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyStripePayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyStripePayoutQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyStripePayoutQuery(baseOptions: Apollo.QueryHookOptions<FindManyStripePayoutQuery, FindManyStripePayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyStripePayoutQuery, FindManyStripePayoutQueryVariables>(FindManyStripePayoutDocument, options);
      }
export function useFindManyStripePayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyStripePayoutQuery, FindManyStripePayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyStripePayoutQuery, FindManyStripePayoutQueryVariables>(FindManyStripePayoutDocument, options);
        }
export type FindManyStripePayoutQueryHookResult = ReturnType<typeof useFindManyStripePayoutQuery>;
export type FindManyStripePayoutLazyQueryHookResult = ReturnType<typeof useFindManyStripePayoutLazyQuery>;
export type FindManyStripePayoutQueryResult = Apollo.QueryResult<FindManyStripePayoutQuery, FindManyStripePayoutQueryVariables>;
export function refetchFindManyStripePayoutQuery(variables?: FindManyStripePayoutQueryVariables) {
      return { query: FindManyStripePayoutDocument, variables: variables }
    }
export const GetClinicChannelStatusesDocument = gql`
    query getClinicChannelStatuses($clinicId: String!) {
  findManyChannelStatus(
    where: {clinicSetting: {clinic: {id: {equals: $clinicId}}}, deletedOn: {equals: null}}
    orderBy: {order: asc}
  ) {
    ...FullChannelStatus
  }
}
    ${FullChannelStatusFragmentDoc}`;

/**
 * __useGetClinicChannelStatusesQuery__
 *
 * To run a query within a React component, call `useGetClinicChannelStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicChannelStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicChannelStatusesQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetClinicChannelStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetClinicChannelStatusesQuery, GetClinicChannelStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicChannelStatusesQuery, GetClinicChannelStatusesQueryVariables>(GetClinicChannelStatusesDocument, options);
      }
export function useGetClinicChannelStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicChannelStatusesQuery, GetClinicChannelStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicChannelStatusesQuery, GetClinicChannelStatusesQueryVariables>(GetClinicChannelStatusesDocument, options);
        }
export type GetClinicChannelStatusesQueryHookResult = ReturnType<typeof useGetClinicChannelStatusesQuery>;
export type GetClinicChannelStatusesLazyQueryHookResult = ReturnType<typeof useGetClinicChannelStatusesLazyQuery>;
export type GetClinicChannelStatusesQueryResult = Apollo.QueryResult<GetClinicChannelStatusesQuery, GetClinicChannelStatusesQueryVariables>;
export function refetchGetClinicChannelStatusesQuery(variables?: GetClinicChannelStatusesQueryVariables) {
      return { query: GetClinicChannelStatusesDocument, variables: variables }
    }
export const GetUniqueChannelStatusDocument = gql`
    query getUniqueChannelStatus($where: ChannelStatusWhereUniqueInput!) {
  findUniqueChannelStatus(where: $where) {
    ...FullChannelStatus
  }
}
    ${FullChannelStatusFragmentDoc}`;

/**
 * __useGetUniqueChannelStatusQuery__
 *
 * To run a query within a React component, call `useGetUniqueChannelStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueChannelStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueChannelStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUniqueChannelStatusQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueChannelStatusQuery, GetUniqueChannelStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueChannelStatusQuery, GetUniqueChannelStatusQueryVariables>(GetUniqueChannelStatusDocument, options);
      }
export function useGetUniqueChannelStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueChannelStatusQuery, GetUniqueChannelStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueChannelStatusQuery, GetUniqueChannelStatusQueryVariables>(GetUniqueChannelStatusDocument, options);
        }
export type GetUniqueChannelStatusQueryHookResult = ReturnType<typeof useGetUniqueChannelStatusQuery>;
export type GetUniqueChannelStatusLazyQueryHookResult = ReturnType<typeof useGetUniqueChannelStatusLazyQuery>;
export type GetUniqueChannelStatusQueryResult = Apollo.QueryResult<GetUniqueChannelStatusQuery, GetUniqueChannelStatusQueryVariables>;
export function refetchGetUniqueChannelStatusQuery(variables?: GetUniqueChannelStatusQueryVariables) {
      return { query: GetUniqueChannelStatusDocument, variables: variables }
    }
export const GetClinicPaymentActivityDocument = gql`
    query getClinicPaymentActivity {
  clinicPaymentActivity {
    success
    error
    totalRecentTransactions
    totalRecentCentsProcessed
  }
}
    `;

/**
 * __useGetClinicPaymentActivityQuery__
 *
 * To run a query within a React component, call `useGetClinicPaymentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicPaymentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicPaymentActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClinicPaymentActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetClinicPaymentActivityQuery, GetClinicPaymentActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicPaymentActivityQuery, GetClinicPaymentActivityQueryVariables>(GetClinicPaymentActivityDocument, options);
      }
export function useGetClinicPaymentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicPaymentActivityQuery, GetClinicPaymentActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicPaymentActivityQuery, GetClinicPaymentActivityQueryVariables>(GetClinicPaymentActivityDocument, options);
        }
export type GetClinicPaymentActivityQueryHookResult = ReturnType<typeof useGetClinicPaymentActivityQuery>;
export type GetClinicPaymentActivityLazyQueryHookResult = ReturnType<typeof useGetClinicPaymentActivityLazyQuery>;
export type GetClinicPaymentActivityQueryResult = Apollo.QueryResult<GetClinicPaymentActivityQuery, GetClinicPaymentActivityQueryVariables>;
export function refetchGetClinicPaymentActivityQuery(variables?: GetClinicPaymentActivityQueryVariables) {
      return { query: GetClinicPaymentActivityDocument, variables: variables }
    }
export const GetUnreadMessageCountDocument = gql`
    query getUnreadMessageCount {
  channelUnreadMessageCount {
    total
    unreadChannels {
      channelId
      channelLastMessageIndex
      lastConsumedMessageIndex
      unreadMessageCount
      channelType
    }
    client {
      total
      unreadChannels {
        channelId
        channelLastMessageIndex
        lastConsumedMessageIndex
        unreadMessageCount
        channelType
      }
    }
    team {
      total
      unreadChannels {
        channelId
        channelLastMessageIndex
        lastConsumedMessageIndex
        unreadMessageCount
        channelType
      }
    }
  }
}
    `;

/**
 * __useGetUnreadMessageCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessageCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadMessageCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>(GetUnreadMessageCountDocument, options);
      }
export function useGetUnreadMessageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>(GetUnreadMessageCountDocument, options);
        }
export type GetUnreadMessageCountQueryHookResult = ReturnType<typeof useGetUnreadMessageCountQuery>;
export type GetUnreadMessageCountLazyQueryHookResult = ReturnType<typeof useGetUnreadMessageCountLazyQuery>;
export type GetUnreadMessageCountQueryResult = Apollo.QueryResult<GetUnreadMessageCountQuery, GetUnreadMessageCountQueryVariables>;
export function refetchGetUnreadMessageCountQuery(variables?: GetUnreadMessageCountQueryVariables) {
      return { query: GetUnreadMessageCountDocument, variables: variables }
    }